import {OrdersData, State,} from "./states";

export type Getters = {
    getOrders(state: State): OrdersData
}
export const getters: Getters = {
    getOrders(state: State): OrdersData {
        if (state.orderData) {
            return state.orderData
        } else {
            return {
                orders: [],
                metadata: {},
                loading: false,
            }
        }
    },

}