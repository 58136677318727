<template>
  <a-popover trigger="click" placement="right" v-model:visible="popoverVisible">
    <template #title>
      <div style="display: flex">
         <span class="header-postback-pop">
        {{$t('ATTACHMENT_SETTINGS')}}
      </span>
        <a-button type="text" @click="()=>popoverVisible=false">
          <close-outlined/>
        </a-button>
      </div>

    </template>
    <template #content>
      <a-form
          class="form-postback"
          ref="formRefPostBack"
          :model="formState"
          layout="vertical"
      >
<!--        <a-form-item :label="$t('camera_image')" v-bind="validateInfos.image_url">-->
<!--          <div class="ant-upload ant-upload-select ant-upload-select-picture-card" @click="showImageSingleSelectionModal">-->
<!--                  <span role="button" tabindex="0" class="ant-upload">-->
<!--                    <div  v-if="!formState.image_url">-->
<!--                      <plus-outlined/>-->
<!--                      <div class="ant-upload-text">{{ $t('UPLOAD') }}</div>-->
<!--                    </div>-->
<!--                    <div v-else>-->
<!--                      <img :src="formState.image_url" class="img-image-url"/>-->
<!--                    </div>-->
<!--                  </span>-->
<!--          </div>-->
<!--        </a-form-item>-->
<!--        <a-form-item :label="$t('FLOW_FACEBOOK_BUTTON_NAME')" v-bind="validateInfos.title">-->
<!--          <a-input :placeholder="$t('FLOW_FACEBOOK_BUTTON_NAME')" v-model:value="formState.title" :maxlength="100"  name="title"/>-->
<!--          <a-tag @click="messageFullName('title')" style="cursor: pointer">##full_name##</a-tag>-->
<!--        </a-form-item>-->
<!--        <a-form-item :label="$t('SUBTITLE')" v-bind="validateInfos.subtitle" v-if="elementIndex===0">-->
<!--          <a-input :placeholder="$t('SUBTITLE')" v-model:value="formState.subtitle" :maxlength="500" name="subtitle"/>-->
<!--          <a-tag @click="messageFullName('subtitle')" style="cursor: pointer">##full_name##</a-tag>-->
<!--        </a-form-item>-->
        <a-form-item :label="$t('FLOW_FACEBOOK_BUTTON_TYPE')" name="type">
          <a-select v-model:value="formState.type" @change="buttonTypeChange">
            <a-select-option v-for="item in zaloButtonTypes" :key="item" :value="item">{{ $t(item) }}</a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item :label="$t('BANNER')"
                     v-if="formState.type==='banner'">
          <div class="ant-upload ant-upload-select ant-upload-select-picture-card" @click="showImageSingleSelectionModal">
                             <span role="button" tabindex="0" class="ant-upload">
                               <div  v-if="!formState.image_url">
                                  <plus-outlined/>
                                 <div class="ant-upload-text">{{ $t('UPLOAD') }}</div>
                                </div>
                              <div v-else>
                                <img  :src="formState.image_url" class="img-image-url"/>
                               </div>
                             </span>
                     </div>
        </a-form-item>

          <div v-else-if="formState.type==='header'">
            <a-form-item :label="$t('HEADER_ATTACHMENT')" >
              <a-textarea ref="inputHeaderContent" @click="forcusMessagetext" @change="onchange" v-model:value="formState.content" show-count :maxlength="100" />
              <a-tag @click="messageFullName('header')" style="cursor: pointer">##full_name##</a-tag>
            </a-form-item>
            <a-form-item :label="$t('ALIGN')" >
              <a-row  style="justify-content: space-around">
                <a-tooltip placement="topLeft">
                  <template #title>
                    <span>left</span>
                  </template>
                  <align-left-outlined @click="changeAlign('left')" :style="{'color': formState.align === 'left' ? '#fab216' : '#000'}" />
                </a-tooltip>
                <a-tooltip placement="topLeft">
                  <template #title>
                    <span>center</span>
                  </template>
                  <align-center-outlined @click="changeAlign('center')" :style="{'color': formState.align === 'center' ? '#fab216' : '#000'}" />
                </a-tooltip>
                <a-tooltip placement="topLeft">
                  <template #title>
                    <span>right</span>
                  </template>
                  <align-right-outlined @click="changeAlign('right')" :style="{'color': formState.align === 'right' ? '#fab216' : '#000'}" />
                </a-tooltip>
              </a-row>
            </a-form-item>
          </div>
          <div v-else-if="formState.type==='text'">
            <a-form-item :label="$t('TEXT_ATTACHMENT')" >
              <a-textarea ref="inputTextContent" @click="forcusMessagetext" @change="onchange" v-model:value="formState.content" show-count :maxlength="order ? 250 :1000" />
              <a-tag @click="messageFullName('text')" style="cursor: pointer">##full_name##</a-tag>
            </a-form-item>
            <a-form-item :label="$t('ALIGN')">
              <a-row  style="justify-content: space-around">
                <a-tooltip placement="topLeft">
                  <template #title>
                    <span>left</span>
                  </template>
                  <align-left-outlined @click="changeAlign('left')" :style="{'color': formState.align === 'left' ? '#fab216' : '#000'}" />
                </a-tooltip>
                <a-tooltip placement="topLeft">
                  <template #title>
                    <span>center</span>
                  </template>
                  <align-center-outlined @click="changeAlign('center')" :style="{'color': formState.align === 'center' ? '#fab216' : '#000'}" />
                </a-tooltip>
                <a-tooltip placement="topLeft">
                  <template #title>
                    <span>right</span>
                  </template>
                  <align-right-outlined @click="changeAlign('right')" :style="{'color': formState.align === 'right' ? '#fab216' : '#000'}" />
                </a-tooltip>
              </a-row>
            </a-form-item>
          </div>
          <div v-else-if="formState.type==='table'" v-for="(i,index) in formState.content_table" :key="i">
            <a-form-item v-if="order" :label="$t('keywords')" >
              <a-textarea @change="onchange" show-count :maxlength="25"  v-model:value="i.key" />
              <a-select
                  v-model:value="i.key"
                  style="width: 100%;margin-top: 20px"
                  placeholder="You key word"

              >
                <a-select-option v-for="k in options" :key="k.value" :value="k.value">
                  {{k.value}}
                </a-select-option>
              </a-select>
            </a-form-item>
            <a-form-item v-else :label="$t('keywords')" >
              <a-textarea @change="onchange" show-count :maxlength="25"  v-model:value="i.key" />
            </a-form-item>
            <a-form-item :label="$t('value')"  >
              <a-textarea @click="forcusMessagetext" show-count @change="onchange" :maxlength="100"  v-model:value="i.value" />
              <a-tag @click="messageFullName('value',index)" style="cursor: pointer">##full_name##</a-tag>
            </a-form-item>
            <a-form-item v-if=" i.key === 'Trạng thái' || i.key === 'Status' " label="style">
              <a-select
                  showSearch
                  allowClear
                  :value="i.style"
                  @change="changeStyle"
              >
                <a-select-option
                    v-for="item in styles"
                    :key="item"
                    v-mode:value="item"
                >
                  {{ $t(item) }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </div >
          <a-button @click="addTable" v-if="formState.type==='table'">{{$t('ADD')}}</a-button>



<!--        <div v-else>-->
<!--          <a-form-item :label="$t('FLOW_FACEBOOK_BUTTON_POST_BACK_MESSAGE_CONTENT_LABEL')" >-->
<!--            <a-textarea name="reply_message"-->
<!--                        :placeholder="$t('FLOW_FACEBOOK_BUTTON_POST_BACK_MESSAGE_CONTENT_PLACEHOLDER')" :rows="3"-->
<!--                        v-model:value="formState.reply_message"/>-->
<!--            <a-tag @click="messageFullName" style="cursor: pointer">##full_name##</a-tag>-->
<!--          </a-form-item>-->
<!--          <a-form-item>-->
<!--            <span class="ant-upload-picture-card-wrapper">-->
<!--              <div class="ant-upload-list ant-upload-list-picture-card" v-for="item in fileList" :key="item">-->
<!--                <div class="ant-upload-list-picture-card-container">-->
<!--                  <span>-->
<!--                    <div-->
<!--                        class="ant-upload-list-item ant-upload-list-item-done ant-upload-list-item-list-type-picture-card">-->
<!--                      <div class="ant-upload-list-item-info">-->
<!--                        <span>-->
<!--                          <a class="ant-upload-list-item-thumbnail"-->
<!--                             :href="item.url"-->
<!--                             target="_blank" rel="noopener noreferrer">-->
<!--                            <img :src="item.url"-->
<!--                                 alt="image.png"-->
<!--                                 class="ant-upload-list-item-image">-->
<!--                          </a>-->
<!--                        </span>-->
<!--                      </div>-->
<!--                      <span class="ant-upload-list-item-actions">-->
<!--                        <a :href="item.url"-->
<!--                           target="_blank"-->
<!--                           rel="noopener noreferrer" title="Preview file">-->
<!--                          <eye-outlined/>-->
<!--                        </a>-->
<!--                        <delete-outlined @click="removeImage(item)"/>-->
<!--                      </span>-->
<!--                    </div>-->
<!--                  </span>-->
<!--                </div>-->
<!--              </div>-->
<!--              <div class="ant-upload ant-upload-select ant-upload-select-picture-card" @click="showImageSelectionModal">-->
<!--                  <span role="button" tabindex="0" class="ant-upload">-->
<!--                    <div>-->
<!--                      <plus-outlined/>-->
<!--                      <div class="ant-upload-text">{{ $t('UPLOAD') }}</div>-->
<!--                    </div>-->
<!--                  </span>-->
<!--              </div>-->
<!--            </span>-->
<!--          </a-form-item>-->

<!--          <a-form-item :label="$t('FLOW_CMR_ACTION')" name="action_crm">-->
<!--            <div class="action-crm" v-for="(item,index) in formState.flow_actions_context.flow_crm_actions"-->
<!--                 :key="'index_'+index">-->
<!--              <div class="crm-action-title-container">-->
<!--                <div class="title-selected-crm-action">-->
<!--                  {{ $t(item.type) }}-->
<!--                </div>-->
<!--                <a-tag color="#f50" class="crm-action-deletion" @click="deleteCRM_Action(item)">{{ $t('DELETE') }}</a-tag>-->
<!--              </div>-->

<!--              <a-select-->
<!--                  v-if="item.type==='add_tag'||item.type==='remove_tag'"-->
<!--                  mode="multiple"-->
<!--                  :placeholder="$t('TAG_SELECT')"-->
<!--                  v-model:value="item.tagArr"-->
<!--              >-->
<!--                <a-select-option v-for="tag in customerTags" :key="tag.id" :value="tag.id">-->
<!--                  &nbsp;&nbsp;{{ tag.name }}-->
<!--                </a-select-option>-->
<!--              </a-select>-->

<!--            </div>-->
<!--            <a-select-->
<!--                v-model:value="selectedCRM_Action"-->
<!--                :placeholder="$t('FLOW_ADD_CMR_ACTION')"-->
<!--                @change="selectCRM_ActionChange"-->
<!--            >-->
<!--              <a-select-option v-for="item in crmActions" :value="item" :key="item">{{ $t(item) }}</a-select-option>-->
<!--            </a-select>-->
<!--          </a-form-item>-->
<!--          <a-form-item :label="$t('SELECT_AVAILABLE_MESSAGE')" name="root_flow_id">-->

<!--            <a-select-->
<!--                v-model:value="formState.flow_actions_context.usage_id"-->
<!--                :placeholder="$t('SELECT_AVAILABLE_MESSAGE')"-->
<!--                allowClear-->
<!--                @change="changeFlowAvailable"-->
<!--                :options="getFlowNextActions()"-->
<!--            >-->
<!--            </a-select>-->
<!--          </a-form-item>-->
<!--          <a-form-item v-if="nextFlowContext&&nextFlowContext.name" :label="$t('FLOW_NEXT_CONTENT')">-->
<!--            <div class="right-button-content">-->
<!--              <a-button type="dashed" class="selected-flow-action" @click="changeCurrentFlowContext">-->
<!--                <facebook-outlined style="font-size: 16px"/>-->
<!--                <span>-->
<!--              {{ nextFlowContext.name }}-->
<!--            </span>-->
<!--              </a-button>-->
<!--              <a-tag color="#f50" style="cursor: pointer" @click="deleteNextFlowAction">-->
<!--                <delete-outlined/>-->
<!--              </a-tag>-->
<!--            </div>-->
<!--          </a-form-item>-->
<!--          <div v-else>-->
<!--            <a-form-item v-if="!isNextFlow">-->
<!--              <a-button type="dashed" block @click="isNextFlow=true">{{ $t('FLOW_NEXT_CONTENT') }}</a-button>-->
<!--            </a-form-item>-->
<!--            <div v-else>-->
<!--              <a-divider/>-->
<!--              &lt;!&ndash;            <a-form-item :label="$t('FLOW_SELECT_AVAILABLE')">&ndash;&gt;-->
<!--              &lt;!&ndash;              <a-select&ndash;&gt;-->
<!--              &lt;!&ndash;                  :placeholder="$t('FLOW_CONTEXT_PLACEHOLDER')"&ndash;&gt;-->
<!--              &lt;!&ndash;              >&ndash;&gt;-->
<!--              &lt;!&ndash;                <a-select-option value="jack">Jack</a-select-option>&ndash;&gt;-->
<!--              &lt;!&ndash;                <a-select-option value="lucy">Lucy</a-select-option>&ndash;&gt;-->
<!--              &lt;!&ndash;              </a-select>&ndash;&gt;-->
<!--              &lt;!&ndash;            </a-form-item>&ndash;&gt;-->
<!--              <a-form-item>-->
<!--                <a-button type="dashed" block @click="newFacebookFlow(1)">-->
<!--                  <facebook-outlined style="font-size: 16px"/>-->
<!--                  {{ $t('FLOW_NEW_FACEBOOK') }}-->
<!--                </a-button>-->
<!--              </a-form-item>-->
<!--              <a-form-item>-->
<!--                <a-button type="dashed" block @click="newFacebookFlow(2)">-->
<!--                  <img src="@/assets/icons/zalo-flat.svg" class="anticon" style="width: 16px;height: 16px;"/>-->
<!--                  {{ $t('FLOW_NEW_ZALO') }}-->
<!--                </a-button>-->
<!--              </a-form-item>-->
<!--            </div>-->
<!--          </div>-->

<!--        </div>-->

        <a-divider/>
        <a-form-item>
          <div class="footer-postback-init">
            <a-button type="primary" danger @click="deletePostBack">
              <delete-outlined/>
              {{ $t('DELETE') }}
            </a-button>
            <a-button type="primary" @click="onSubmit(true)">
              <check-outlined/>
              {{ $t('FLOW_COMPLETE') }}
            </a-button>
          </div>
        </a-form-item>
      </a-form>
    </template>
    <a-list-item  @click="()=>popoverVisible=!popoverVisible" class="item-zalo-element">
      <a-list-item-meta

      >
        <template #title>
          <div v-if="formState.type === 'table'">
            <a-typography-text style="display: block"  v-for="i in formState.content_table" :key="i"> {{`${i.key}`}} :<a-tag  style="border: none;color: black" :color="i.style ? i.style : 'withe'">
              {{i.value}}
            </a-tag> </a-typography-text>
          </div>
          <a-typography-text v-else>{{formState.content}}</a-typography-text>

        </template>
        <template #description>
          <span>
            {{formState.type}}
          </span>
        </template>
        <template #avatar>
          <a-avatar v-if="formState.image_url" shape="square" :src="formState.image_url" />
        </template>
      </a-list-item-meta>
    </a-list-item>
<!--    <a-button class="button-post-back" @click="()=>popoverVisible=true">{{ formState.title }}</a-button>-->
  </a-popover>
  <image-selection-modal ref="imageSelectionRef" :single="isImageSelectionSingle" :onSelect="onImageSelect"/>
</template>

<script lang="ts">
import {
  CheckOutlined,
  PlusOutlined,
  FacebookOutlined,
  DeleteOutlined,
  CloseOutlined,
  EyeOutlined,
    AlignLeftOutlined,
    AlignRightOutlined,
    AlignCenterOutlined
} from "@ant-design/icons-vue"
import {defineComponent, reactive, ref, UnwrapRef, watch} from "vue";
import {FlowContext, FlowCRM_Action, FlowZaloElement, PostbackContext} from "@/models/Flows/FlowContext";
import {FormFlowInformation} from "@/models/Flows/FlowForm";
import {ValidateErrorEntity} from "ant-design-vue/es/form/interface";
import {useStore} from "@/store";
import {S_EventBus} from "@/utils/EventBus";
import {AWS_DO_Model} from "@/models/AWS_DO/AWS_DO";
import ImageSelectionModal from "@/components/Image/ImageSelectionModal.vue";
import {useI18n} from "vue-i18n";
import {MixUtils} from "@/utils/Utils";
import {Form, message} from 'ant-design-vue';
import useTagStore from "../../store/Tags";

const useForm = Form.useForm;


export default defineComponent({
  name: "zalo-template-list",
  props: ['elementContexts', 'updateElement', 'flowContextData', 'deleteElement','elementIndex','messageContext','order'],
  components: {
    CheckOutlined,
    PlusOutlined,
    // FacebookOutlined,
    DeleteOutlined,
    ImageSelectionModal,
    CloseOutlined,
    // EyeOutlined,
    AlignLeftOutlined,
    AlignRightOutlined,
    AlignCenterOutlined
  },
  setup(props) {
    const store = useStore()
    const i18n = useI18n()
    const fileList = ref<AWS_DO_Model[]>([])
    const formRefPostBack = ref()
    const crmActions = ref()
    const customerTags = ref()
    const selectedCRM_Action = ref()
    const isNextFlow = ref(false)
    const popoverVisible = ref(false)
    const nextFlowContext = ref()
    const imageSelectionRef = ref()
    const isImageSelectionSingle = ref(false)
    const styles = ref(['green', 'blue', 'yellow', 'red', 'grey'])
    const options = ref([{value: 'Mã'},{value: 'Code'},{value: 'Tên Khách hàng'},{value: 'Customer Name'},{value: 'Trạng thái'},{value: 'Status'}])
    crmActions.value = store.getters.getFlowConstants?.flow_crm_action_types
    let model: FlowZaloElement = {
      flow_actions_context:{}
    }
    customerTags.value = useTagStore.getState().tagSales

    watch(() => props.elementContexts, () => {
      updateElementContext()
    })

    const updateElementContext = () => {
      if (!formState.flow_actions_context) {
        formState.flow_actions_context = {
          flow_crm_actions: []
        }
      }
      formState.flow_actions_context.flow_crm_actions = formState.flow_actions_context.flow_crm_actions?.map((item: FlowCRM_Action) => {
        if (item.tags) {
          item.tagArr = item.tags ? item.tags.split(",").map((str: string) => parseInt(str)) : []
        }
        return item;
      })
      formState.title = props.elementContexts.title
      formState.subtitle = props.elementContexts.subtitle
      formState.image_url = props.elementContexts.image_url
      formState.url = props.elementContexts.url
      formState.type = props.elementContexts.type ? props.elementContexts.type : store.getters.getFlowConstants?.zalo_element_types[0]
      if (props.elementContexts.reply_images) {
        if (typeof props.elementContexts.reply_images !== 'string'){
          fileList.value = props.elementContexts.reply_images.map((item: string) => {
            const image: AWS_DO_Model = {
              url: item,
              key: item
            }
            return image;
          })
        }
      }
    }
    watch(() => props.flowContextData, () => {
      updateFlowContextData();
    })


    if (props.elementContexts) {
      if (props.elementContexts.popoverVisible) {
        popoverVisible.value = true
      }
      model = {...props.elementContexts, ...model}
    }
    if (!model.flow_actions_context) {
      model.flow_actions_context = {
        flow_crm_actions: []
      }
    }
    const zaloButtonTypes = ref(['banner','header','text','table']);
  const banner = props.messageContext.find((item:any) => item.type === 'banner' )
  const header =  props.messageContext.find((item:any) => item.type === 'header' )
   const table =props.messageContext.find((item:any) => item.type === 'table' )
  const text = props.messageContext.filter((item:any) => item.type === 'text' )
    console.log(banner, header , table,'click')
    if(banner && header && table){
      zaloButtonTypes.value = ['text']
    }else if(banner && header){
        zaloButtonTypes.value = ['text','table']


    }
const changeStyle = (data:any)=>{
      console.log(data)
}
    const onchange = () =>{
      console.log('1')
      onSubmit()
    }
    if (!model.flow_actions_context.flow_crm_actions) {
      model.flow_actions_context.flow_crm_actions = []
    }
    model.flow_actions_context.flow_crm_actions = model.flow_actions_context.flow_crm_actions.map((item: FlowCRM_Action) => {
      if (item.tags) {
        item.tagArr = item.tags ? item.tags.split(",").map((str: string) => parseInt(str)) : []
      }
      return item;
    })
    const formState: UnwrapRef<PostbackContext> = reactive(props.elementContexts);
    const rules=reactive({
      // title: [
      //   {required: true, message: 'Please input element title', trigger: 'blur'},
      // ],
      // subtitle: [
      //   {required: true, message: 'Please input button subtitle', trigger: 'blur'},
      // ],
      // image_url: [
      //   {required: formState.type == 'banner' ? true : false, message: 'Please upload an image', trigger: 'blur'},
      // ],
      // phone_code: [
      //   {required: zaloButtonTypes.value&&(formState.type===zaloButtonTypes.value[3]||formState.type===zaloButtonTypes.value[4]), message: 'Please input phone number', trigger: 'blur'},
      // ],
      // url: [
      //   {required: zaloButtonTypes.value&&formState.type===zaloButtonTypes.value[0], message: 'Please input url', trigger: 'blur'},
      // ],
      // sms_content: [
      //   {required: zaloButtonTypes.value&&formState.type===zaloButtonTypes.value[3], message: 'Please input sms content', trigger: 'blur'},
      // ],
    })
    // const { resetFields, validate, validateInfos } = useForm(
    //     formState,
    //     rules,
    // );
    const changeAlign = (str:string) =>{

        formState.align = str
          onchange()
    }

   const addTable =  () => {
     // @ts-ignore
     if(formState.content_table[0].style){
       // @ts-ignore
       formState.content_table.push({
         key:'voucher',
         value:'content',
         style:'yellow'
       })
     }else {
       // @ts-ignore
       formState.content_table.push({
         key:'',
         value:''
       })
     }

   }
    const buttonTypeChange=(val:string)=>{
      // if(val == 'banner'){
      //   rules.image_url[0].required=zaloButtonTypes.value&&val===zaloButtonTypes.value[0]
      //   rules.image_url[0].required=zaloButtonTypes.value&&val===zaloButtonTypes.value[0]
      // }
      if(val === 'table'){
        // @ts-ignore
        formState.content_table = [{key:'voucher',value: 'sadasdaf'}]
      }
      // rules.phone_code[0].required=zaloButtonTypes.value&&(val===zaloButtonTypes.value[2]||val===zaloButtonTypes.value[3])
    }
    const selectCRM_ActionChange = (value: string) => {
      if (value) {
        if (!formState.flow_actions_context) {
          formState.flow_actions_context = {
            flow_crm_actions: []
          }
        }
        if (formState.flow_actions_context.flow_crm_actions) {
          const action = formState.flow_actions_context.flow_crm_actions.find((item: FlowCRM_Action) => item.type === value)
          if (!action) {
            formState.flow_actions_context.flow_crm_actions.push({type: value, tagArr: []})
          }
        } else {
          formState.flow_actions_context.flow_crm_actions = [{type: value, tagArr: []}]
        }
        selectedCRM_Action.value = undefined
      }
    }
    const getFlowNextActions = ()=>{
      let listSelected: string[] = [];
      props.flowContextData.context.flow_contexts.map((flow: FlowContext)=>{
        if(flow.action_default_context && flow.action_default_context.usage_id ){
          listSelected.push(flow.action_default_context.usage_id)
        }
        if(flow.action_send_failed_context && flow.action_send_failed_context.usage_id ){
          listSelected.push(flow.action_send_failed_context.usage_id)
        }
        if(flow.action_send_success_context && flow.action_send_success_context.usage_id ){
          listSelected.push(flow.action_send_success_context.usage_id)
        }
      })
      listSelected = listSelected.filter(x => !x.includes(props.elementContexts.flow_actions_context.usage_id))
      return props.flowContextData.context.flow_contexts.filter((flow: FlowContext, index: number)=>{
        return props.elementContexts.id!==flow.id && index !== 0 && !listSelected.includes(flow.id+'')
      }).map((flow: FlowContext) => {
        return {
          label: flow.name,
          value: flow.id
        }
      })
    }

    const deleteCRM_Action = (item: FlowCRM_Action) => {
      if (!formState.flow_actions_context) {
        formState.flow_actions_context = {
          flow_crm_actions: []
        }
      }
      formState.flow_actions_context.flow_crm_actions = formState.flow_actions_context.flow_crm_actions?.filter((it: FlowCRM_Action) => it.type !== item.type);
      selectedCRM_Action.value = undefined
    }
    const newFacebookFlow = async (type: number) => {
      let name = 1
      if (props.flowContextData && props.flowContextData.context && props.flowContextData.context.flow_contexts.length) {
        name = props.flowContextData.context.flow_contexts.length + 2
      }
      var resName = "";
      var resMess = "";
      if(type === 1)
      {
        resName = "#Facebook# " + i18n.t('MESS_NAME_CONTENT') + name;
        resMess = "#Facebook " + i18n.t('FLOW_MESSAGE_CONTENT') + name;
      }else if(type === 2)
      {
        resName = "#Zalo# " + i18n.t('MESS_NAME_CONTENT') + name;
        resMess = "#Zalo " + i18n.t('FLOW_MESSAGE_CONTENT') + name;
      }

      let flow: FlowContext= {
        "id": MixUtils.methods.getUUID(),
        "name": resName,
        "shop_id": props.flowContextData?.shop_id,
        "flow_type": type,
        "message": resMess,
        "attachment_images": [],
        "type": "",
        "messenger_tag": "",
        "template_id": "",
        "template_name": "",
        "template_status": "",
        "preview_url": "",
        "created_at": new Date().toISOString(),
        "updated_at": new Date().toISOString(),
        "postback_contexts": [],
        "action_send_success_context": {
          "flow_crm_actions": [],
          "flow_action_stack": {}
        },
        "action_send_failed_context": {
          "flow_crm_actions": [],
          "flow_action_stack": {}
        },
        "action_default_context": {
          "flow_crm_actions": [],
          "flow_action_stack": {}
        }
      }

      S_EventBus.instance().dispatchCreateFlow(flow)
      onSubmit()
      setTimeout(() => {
        changeCurrentFlowContext()
      }, 400)
    }
    const updateFlowContextData = () => {
      let context: FlowContext = {}
      if (formState.flow_actions_context && formState.flow_actions_context.flow_action_stack && formState.flow_actions_context.flow_action_stack.flow_id
          && props.flowContextData.context && props.flowContextData.context.flow_contexts.length > 0) {
        context = props.flowContextData.context.flow_contexts.find((flow: FlowContext) => flow.id === formState.flow_actions_context?.flow_action_stack?.flow_id)
      }
      nextFlowContext.value = context
    }
    const  cursorPositionMessageText= ref(0)
    const inputHeaderContent = ref()
    const inputTextContent = ref()
    const inputTableValue = ref()
    const forcusMessagetext = (e: any) =>{
      // if(e.target.value.lastIndexOf('#') !== -1){
      //   e.target.setSelectionRange(e.target.value.lastIndexOf('#')+1,e.target.value.lastIndexOf('#')+1)
      //   console.log(e.target.selectionStart)
      // }else {
      cursorPositionMessageText.value = e.target.selectionStart
      console.log(cursorPositionMessageText.value)



    }
    const messageFullName = (str:string,index:any) => {
      if(str === 'header' || str === 'text'){
        if(str === 'header'){
          inputHeaderContent.value.focus()
        }else if(str === 'text'){
          inputTextContent.value.focus()
        }
        // @ts-ignore
        const text = ref<any>(formState.content);
        const start = text.value.slice(0,cursorPositionMessageText.value)
        const end = text.value.slice(cursorPositionMessageText.value)
        text.value =

            start + (start ? " " : "") + "##full_name##" + (end ? " " : "") + end
        // @ts-ignores
        formState.content = text.value;
      }else {
        // @ts-ignore
        const text = ref<any>( formState.content_table[index].value);
        const start = text.value.slice(0,cursorPositionMessageText.value)
        const end = text.value.slice(cursorPositionMessageText.value)
        text.value =

            start + (start ? " " : "") + "##full_name##" + (end ? " " : "") + end

        // @ts-ignore
        formState.content_table[index].value =  text.value;
      }
      onSubmit()
    }
    updateFlowContextData()
    const deleteNextFlowAction = () => {
      console.log("formState",formState.flow_actions_context)
      if (formState.flow_actions_context?.flow_action_stack) {
        formState.flow_actions_context.flow_action_stack.flow_id = 0
      }
    }
    const changeCurrentFlowContext = () => {
      if (formState.flow_actions_context?.flow_action_stack && formState.flow_actions_context.flow_action_stack.flow_id) {
        S_EventBus.instance().execChangeCurrentFlow(formState.flow_actions_context.flow_action_stack.flow_id)
        popoverVisible.value = false
      }
    }
    const showImageSelectionModal = () => {
      isImageSelectionSingle.value=false
      imageSelectionRef.value.showModal()
    }
    const showImageSingleSelectionModal = () => {
      isImageSelectionSingle.value=true
      imageSelectionRef.value.showModal("image_url")
    }
    const onImageSelect = (images: AWS_DO_Model[],param:string) => {
      var img = new Image;

        img.src = images[0].url
        img.onload = function () {
          // @ts-ignore
          if (img.height > img.width ) {
            message.warning('Ảnh không hợp lệ,vui lòng chọn ảnh khác với tỉ lệ khoảng  1:5 đến 1:1')
          } else {
            popoverVisible.value=true
            if (param==='image_url'){
              formState.image_url=images[0].url
              onchange()
            }else {
              fileList.value = [...fileList.value, ...images]
              onchange()
            }
          }
        }
        console.log("Intrinsic size: " + img.width + "x" + img.height, img.height <= img.width);


    }
    const removeImage = (image: AWS_DO_Model) => {
      fileList.value = fileList.value.filter((item: AWS_DO_Model) => item.key !== image.key)
    }
    const deletePostBack = () => {
      props.deleteElement()
    }
    const containPop = () => {
      return document.getElementById('main-flow')
    }
    updateElementContext()
    const changeFlowAvailable = async (val:string) => {

      if(formState.flow_actions_context){
        formState.flow_actions_context.usage_id = val
      }
      // formState.usage_id = value

      // let name = 1
      // if (props.flowContextData && props.flowContextData.context && props.flowContextData.context.flow_contexts.length) {
      //   name = props.flowContextData.context.flow_contexts.length + 2
      // }
      // var resName = "";
      // var resMess = "";
      //
      // if(props.flowContextData.flow_context.flow_type === 1)
      // {
      //   resName = "#Facebook# " + i18n.t('MESS_NAME_CONTENT') + name;
      //   resMess = "#Facebook " + i18n.t('FLOW_MESSAGE_CONTENT') + name;
      // }else if(props.flowContextData.flow_context.flow_type === 2)
      // {
      //   resName = "#Zalo# " + i18n.t('MESS_NAME_CONTENT') + name;
      //   resMess = "#Zalo " + i18n.t('FLOW_MESSAGE_CONTENT') + name;
      // }
      // const request: FlowContext = {
      //   name: resName,
      //   flow_type: props.flowContextData.flow_context.flow_type,
      //   message: resMess,
      //   root_flow_id: value
      // }
      // const response = await APIs.init().createFlowContext(request);
      // if (response.status === 200 && response.data && response.data.data) {
      //   let flow = response.data.data;
      //   setTimeout(() => {
      //     changeCurrentFlowContext()
      //   }, 400)
      // }
    }
    const onSubmit = (isButton?: boolean) => {

            const postB = {...props.elementContexts}
            postB.title = formState.title
            postB.reply_message = formState.reply_message;
            postB.flow_actions_context = formState.flow_actions_context
            postB.type = formState.type
            postB.url = formState.url
            postB.subtitle = formState.subtitle
            postB.image_url = formState.image_url
            postB.phone_code = formState.phone_code
            postB.sms_content = formState.sms_content
            postB.content = formState.content
            postB.align = formState.align
            postB.content_table = formState.content_table
            if (!postB.flow_actions_context) {
              postB.flow_actions_context = {
                flow_action_stack: {},
                flow_crm_actions: []
              }
            }
            postB.popoverVisible = false
            if (formState.flow_actions_context && formState.flow_actions_context.flow_crm_actions) {
              postB.flow_actions_context.flow_crm_actions = formState.flow_actions_context.flow_crm_actions?.map((item: FlowCRM_Action) => {
                if (item.tagArr) {
                  item.tags = item.tagArr.join(',');
                }
                return item;
              })
            }
            if (fileList.value && fileList.value.length) {
              postB.reply_images = fileList.value.map((item: AWS_DO_Model) => {
                return item.url
              })
            }else {
              postB.reply_images=[]
            }
            props.updateElement(postB);
            if (isButton) {
              popoverVisible.value = false
            }


    };

    return {
      fileList,
      formState,
      formRefPostBack,
      onSubmit,
      isNextFlow,
      customerTags,
      crmActions,
      selectedCRM_Action,
      selectCRM_ActionChange,
      deleteCRM_Action,
      popoverVisible,
      nextFlowContext,
      deleteNextFlowAction,
      changeCurrentFlowContext,
      deletePostBack,
      newFacebookFlow,
      onImageSelect,
      showImageSelectionModal,
      imageSelectionRef,
      containPop,
      removeImage,
      zaloButtonTypes,
      changeFlowAvailable,
      getFlowNextActions,
      isImageSelectionSingle,
      showImageSingleSelectionModal,
      changeStyle,
      buttonTypeChange,
      messageFullName,
      inputHeaderContent,
      inputTextContent,
      addTable,
      onchange,
      changeAlign,
      forcusMessagetext,
      styles,
      options
    }
  }
})
</script>

<style lang="scss" scoped>


.button-post-back {
  margin: 5px 10px;
}

.action-crm {
  margin-bottom: 16px;

  .crm-action-title-container {
    display: flex;
    flex-direction: row;
    margin-bottom: 8px;
    justify-content: space-between;
    align-items: center;

    .title-selected-crm-action {
      font-weight: bold;
    }

    .crm-action-deletion {
      cursor: pointer;
    }
  }

}


.selected-flow-action {
  text-align: left;
  margin-right: 10px;
  flex: 1;
}

.footer-postback-init {
  justify-content: space-between;
  display: flex;
}

.right-button-content {
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;

  .center-button-content {
    display: flex;
    flex: 1;
    padding-bottom: 5px;
    margin-left: 10px;
  }
}

.header-postback-pop {
  width: 100%;
  margin: auto;
}

.form-postback {
}
.ant-list-item-meta-title{
  text-align: left !important;
}
.img-image-url{
  width: 100%;
  height: 100%;
}
.item-zalo-element{
  cursor: pointer;
}
</style>
