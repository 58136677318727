import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-75df725a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "button-add-stock" }
const _hoisted_2 = { class: "total" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_input = _resolveComponent("a-input")!
  const _component_a_input_number = _resolveComponent("a-input-number")!
  const _component_a_textarea = _resolveComponent("a-textarea")!
  const _component_delete_outlined = _resolveComponent("delete-outlined")!
  const _component_a_table = _resolveComponent("a-table")!
  const _component_plus_outlined = _resolveComponent("plus-outlined")!
  const _component_a_button = _resolveComponent("a-button")!
  const _component_a_modal = _resolveComponent("a-modal")!
  const _component_a_input_search = _resolveComponent("a-input-search")!
  const _component_smile_outlined = _resolveComponent("smile-outlined")!
  const _component_a_image = _resolveComponent("a-image")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_a_modal, {
      title: "Import product to stocks",
      visible: _ctx.visible,
      "onUpdate:visible": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.visible) = $event)),
      width: _ctx.screenWidth>=780?'1000px':'85%'
    }, {
      default: _withCtx(() => [
        _createVNode(_component_a_table, {
          dataSource: _ctx.data,
          columns: _ctx.columns,
          pagination: false
        }, {
          quantity: _withCtx(() => [
            _createVNode(_component_a_input, { type: "number" })
          ]),
          import_price: _withCtx(() => [
            _createVNode(_component_a_input_number, {
              style: {"width":"100%"},
              formatter: value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ','),
              parser: value => value.replace(/\$\s?|(,*)/g, '')
            }, null, 8, ["formatter", "parser"])
          ]),
          note: _withCtx(() => [
            _createVNode(_component_a_textarea, { placeholder: "note" })
          ]),
          delete: _withCtx(({record}) => [
            _createVNode(_component_delete_outlined, {
              onClick: ($event: any) => (_ctx.removeProduct(record.key))
            }, null, 8, ["onClick"])
          ]),
          _: 1
        }, 8, ["dataSource", "columns"]),
        _createElementVNode("div", _hoisted_1, [
          _createVNode(_component_a_button, {
            type: "dashed",
            onClick: _ctx.addProduct
          }, {
            default: _withCtx(() => [
              _createVNode(_component_plus_outlined),
              _createTextVNode(" Add stock ")
            ]),
            _: 1
          }, 8, ["onClick"])
        ])
      ]),
      _: 1
    }, 8, ["visible", "width"]),
    _createVNode(_component_a_modal, {
      visible: _ctx.productModalVisible,
      "onUpdate:visible": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.productModalVisible) = $event)),
      title: "Select product",
      width: "900px"
    }, {
      default: _withCtx(() => [
        _createVNode(_component_a_input_search, {
          class: "search-product-input",
          placeholder: "Search product"
        }),
        _createVNode(_component_a_table, {
          columns: _ctx.productSearchColumns,
          "data-source": _ctx.productData,
          "row-selection": _ctx.rowSelection
        }, {
          name: _withCtx(({ record }) => [
            _createElementVNode("a", null, _toDisplayString(record.name), 1),
            _createTextVNode(" " + _toDisplayString(record.key === '2' ? '(Selected)' : ''), 1)
          ]),
          customTitle: _withCtx(() => [
            _createElementVNode("span", null, [
              _createVNode(_component_smile_outlined),
              _createTextVNode(" Name ")
            ])
          ]),
          image: _withCtx(() => [
            _createElementVNode("span", null, [
              _createVNode(_component_a_image, {
                width: 100,
                src: "https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png"
              })
            ])
          ]),
          total: _withCtx(({text}) => [
            _createElementVNode("span", _hoisted_2, _toDisplayString(text), 1)
          ]),
          _: 1
        }, 8, ["columns", "data-source", "row-selection"])
      ]),
      _: 1
    }, 8, ["visible"])
  ], 64))
}