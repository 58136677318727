<template>
  <a-spin :spinning="spinning">
    <div class="container-title">
      <h3 class="title-header">{{ $t($route.name) }}</h3>
      <div>

      </div>
      <div>

      </div>
      <a-button @click="filterExpanded=!filterExpanded" type="link">{{ $t('CUSTOMER_FILTER') }}
        <DownOutlined/>
      </a-button>

    </div>
    <a-divider style="margin: 0"/>
    <a-radio-group class="radio-group-customer-care" button-style="solid" v-model:value="activeKey"
                   @change="onChangeStatus"
    >

      <a-radio-button value="999" class="radio-item-customer-care">
      <span>
          {{ $t('ALL') }}
          <a-tag class="radio-badge">
            {{ allBadge }}
          </a-tag>
       </span>
      </a-radio-button>
      <a-radio-button value="0" class="radio-item-customer-care">
      <span>
          {{ $t('NEW_ORDERS') }}
          <a-tag class="radio-badge" color="#cccccc">
            {{ newOrdersBadge }}
          </a-tag>
       </span>
      </a-radio-button>
      <a-radio-button value="1" class="radio-item-customer-care">
      <span>
          {{ $t('CONFIRMED_ORDERS') }}
          <a-tag class="radio-badge" color="#3c8dbc">
            {{ confirmedOrdersBadge }}
          </a-tag>
       </span>
      </a-radio-button>
      <a-radio-button value="2" class="radio-item-customer-care">
      <span>
          {{ $t('WAITING_PICKUP') }}
          <a-tag class="radio-badge" color="#f39c12">
            {{ waitingPickupBadge }}
          </a-tag>
       </span>
      </a-radio-button>
      <a-radio-button value="3" class="radio-item-customer-care">
      <span>
          {{ $t('PICKED_UP') }}
          <a-tag class="radio-badge" color="#00c0ef">
            {{ pickedUpBadge }}
          </a-tag>
       </span>
      </a-radio-button>
      <a-radio-button value="7" class="radio-item-customer-care">
      <span>
          {{ $t('SHIPPING') }}
          <a-tag class="radio-badge" color="#7fd3f2">
            {{ shippingBadge }}
          </a-tag>
       </span>
      </a-radio-button>
      <a-radio-button value="8" class="radio-item-customer-care">
      <span>
          {{ $t('REQUEST_RETURN') }}
          <a-tag class="radio-badge" color="#dd4b39">
            {{ requestReturnBadge }}
          </a-tag>
       </span>
      </a-radio-button>
      <a-radio-button value="6" class="radio-item-customer-care">
      <span>
          {{ $t('RETURNING') }}
          <a-tag class="radio-badge" color="#d77516">
            {{ returningBadge }}
          </a-tag>
       </span>
      </a-radio-button>
      <a-radio-button value="-1" class="radio-item-customer-care">
      <span>
          {{ $t('CANCELED') }}
          <a-tag class="radio-badge" color="#031f3f">
            {{ cancelOrdersBadge }}
          </a-tag>
       </span>
      </a-radio-button>
      <a-radio-button value="5" class="radio-item-customer-care">
       <span>
          {{ $t('SUCCESS_RETURNED') }}
          <a-tag class="radio-badge" color="#f9a757">
            {{ successReturnedBadge }}
          </a-tag>
       </span>
      </a-radio-button>
      <a-radio-button value="4" class="radio-item-customer-care">
      <span>
              {{ $t('SUCCESS_SHIPPED') }}
              <a-tag class="radio-badge" color="#01a65a">
                {{ successShippedBadge }}
              </a-tag>
            </span>
      </a-radio-button>
    </a-radio-group>

    <a-card v-if="filterExpanded" style="margin: 16px">
      <a-form class="form-filter" :label-col="labelCol"
              :wrapper-col="wrapperCol">
        <a-form-item :label="$t('ORDER_ID')">
          <a-input
              allowClear
              v-model:value="ordersRequest.search"
              :placeholder="$t('INPUT_PLACEHOLDER')"
          />
        </a-form-item>
        <a-form-item :label="$t('CUSTOMER')">
          <a-input :placeholder="$t('INPUT_PLACEHOLDER')"
                   allowClear
                   v-model:value="ordersRequest.customer"
          />
        </a-form-item>
        <a-form-item :label="$t('STOCK')">
          <a-select
              allowClear
              v-model:value="ordersRequest.stock_id"
              style="width: 100%"
              :placeholder="$t('INPUT_SELECT')"
              @change="handleOnChangeStock"
          >
            <a-select-option
                v-for="item in getStocks"
                :key="item.id"
                v-bind:value="item.id"
            >{{ item.shop_name }}
            </a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item :label="$t('PAGE')">
          <a-select
              allowClear
              v-model:value="ordersRequest.page_id"
              style="width: 100%"
              :placeholder="$t('INPUT_SELECT')"
              @change="handleOnChangePage"
          >
            <a-select-option
                v-for="item in getPages"
                :key="item.id"
                v-bind:value="item.page_id"
            >{{ item.page_name }}
            </a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item :label="$t('CREATED')">
          <a-range-picker
              allowClear
              v-model:value="created" :format="dateFormatList" @change="handleOnChangeCreated"/>
        </a-form-item>
        <a-form-item :label="$t('SHIPPING_METHOD')">
          <a-select
              allowClear
              v-model:value="ordersRequest.shipping_method"
              style="width: 100%"
              :placeholder="$t('INPUT_SELECT')"
              @change="handleOnChangeShippingMethod"
          >
            <a-select-option
                v-for="item in shippingMethodData"
                :key="item.id"
                v-bind:value="item.id"
            >{{ item.name }}
            </a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item :label="$t('PICKUP_DATE')">
          <a-range-picker
              allowClear
              v-model:value="pickup" :format="dateFormatList" @change="handleOnChangePickUp"/>
        </a-form-item>
        <a-form-item :label="$t('UPDATED')">
          <a-range-picker
              allowClear
              v-model:value="updated" :format="dateFormatList" @change="handleOnChangeUpdated"/>
        </a-form-item>
        <a-form-item :label="$t('EMPLOYEE')">
          <!--      <a-input :placeholder="$t('INPUT_PLACEHOLDER')"/>-->
          <a-select
              style="width: 100%"
              allowClear
              v-model:value="ordersRequest.employee_id"
              :placeholder="$t('INPUT_SELECT')"
              @change="handleOnChangeEmployee"
          >
            <a-select-option
                v-for="item in getListEmployee"
                :key="item.id"
                v-bind:value="item.cms_user_id"
            >{{ item.name }}
            </a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item :label="$t('PRODUCT')">
          <a-select
              style="width: 100%"
              allowClear
              v-model:value="ordersRequest.product_id"
              :placeholder="$t('INPUT_SELECT')"
              @change="handleOnChangeProduct"
          >
            <a-select-option
                v-for="item in products"
                :key="item.id"
                v-bind:value="item.id"
            >{{ item.product_name }}
            </a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item :label="$t('NOTE')">
          <a-input :placeholder="$t('INPUT_PLACEHOLDER')"/>
        </a-form-item>
        <!--    <a-form-item label="Tag">-->
        <!--      <a-input :placeholder="$t('INPUT_PLACEHOLDER')"/>-->
        <!--    </a-form-item>-->
        <a-form-item>
      <span style="margin-left: 32px; display: flex">
        <a-button type="primary" @click="search"><search-outlined/>{{ $t('SEARCH') }}</a-button>
        <a-button style="margin-left: 15px;" @click="resetFilter">{{ $t('RESET_FILTER') }}</a-button>
      </span>
        </a-form-item>
      </a-form>
    </a-card>
    <a-card class="card-table-orders" :bodyStyle="{padding: 0, marginLeft: '16px',  marginRight: '-16px'}">
      <!--    <template #title>-->
      <!--      <h4 style="font-weight: bold">{{$t('LIST')}}</h4>-->
      <!--      <a-dropdown>-->
      <!--        <Transition name="slide-fade">-->
      <!--          <a-button type="primary" class="button-more-action" v-if="isSelectAll">-->
      <!--            {{$t('ACTION')}}-->
      <!--            <down-outlined/>-->
      <!--          </a-button>-->
      <!--        </Transition>-->
      <!--        <template #overlay>-->
      <!--          <a-menu>-->
      <!--            <a-menu-item key="1">-->
      <!--              <printer-outlined/>-->
      <!--              {{$t('PRINT')}}-->
      <!--            </a-menu-item>-->
      <!--            <a-menu-item key="2">-->
      <!--              <rocket-outlined/>-->
      <!--              Send orders to carrier-->
      <!--            </a-menu-item>-->
      <!--          </a-menu>-->
    <!--        </template>-->
      <!--      </a-dropdown>-->
      <!--    </template>-->
      <template #extra>
        <a-popover :title="$t('IMPORT_EXCEL')" v-model:open="openUpload" trigger="click" >
          <template #content>
            <a-select
                style="width: 100%"
                allow-clear
                :placeholder="$t('CARE_STATUS_LIST')"
                v-model:value="last_care"
                @change="handleCare"

            >
              <template #clearIcon>
                <close-circle-outlined @click="handleCare" />

              </template>
              <a-select-option
                  v-for="item in customerCares"
                  :key="item.id"
                  v-bind:value="item.id"
              >
                <a-tag :color="item.color">
                  {{ item.name }}
                </a-tag>
              </a-select-option>
            </a-select>
            <div style="margin-top: 12px"/>
            <a-upload

                v-if="getMyRole(modules.orders)?.is_create"
                name="file"
                :multiple="false"
                :show-upload-list="false"
                :beforeUpload="importXLS"
            >
              <a-button>
                <template #icon>
                  <cloud-upload-outlined/>
                </template>
                Upload Excel
              </a-button>
            </a-upload>
          </template>
          <a-button>
            <template #icon>
              <cloud-upload-outlined/>
            </template>
            Upload Excel
          </a-button>
        </a-popover>

        <a-button
            @click="exportExcel"
            v-if="getMyRole(modules.orders)?.is_read"
            style="margin-left: 16px;margin-right: 16px;">
          <file-excel-outlined/>
          {{ $t('EXPORT_ORDER') }}
        </a-button>

        <router-link
            v-if="getMyRole(modules.orders)?.is_create"
            :to="'/order/0/edit'">
          <a-button type="primary">
            <plus-outlined/>
            {{ $t('CREATE_ORDER') }}
          </a-button>
        </router-link>
      </template>
      <a-table
          size="small"
          :columns="columns" :data-source="orderStore.orders" class="table-orders"
          :pagination="pagination"
          @change="handleTableChange"
          :loading="orderStore.getOrderLoading"
      >
        <template #order_number="{record}">
          <div class="order-id-item">
            <router-link
                v-if="getMyRole(modules.orders)?.is_read"
                :to="'/order/'+record.order_number">
              <a type="text" class="order-id-text-button order-id-text-bold">
                {{ record.order_number }}
              </a>
<!--              <div style="color: #0d2538; font-size: 12px">{{ record.id }}</div>-->
            </router-link>
            <!--          <router-link :to="'/shipping/'+record.shipping_code">-->
            <span type="text" class="">
             {{ record.shipping_code.split('.').pop() }}
           </span>
            <!--          </router-link>-->
          </div>
        </template>
        <template #warehouse="{record}">
          <div class="order-id-item">
          <span type="text" class="order-id-text-button order-id-text-bold">
            {{ record.shop_name }}
          </span>
<!--            <span type="text" class="">-->
<!--            {{ record.shop_address }}-->
<!--          </span>-->
          </div>
        </template>
        <template #customer="{record}">
          <div class="order-id-item">

            <a-row>
              <router-link class="order-id-text-button order-id-text-bold" style="color:#FAB216"
                           :to="`/customer-detail/${record.customer_id}`">{{ record.shipping_name }}
              </router-link>
            </a-row>
            <span type="text" class="">
            {{ record.shipping_phone }}
          </span>
          </div>
        </template>
        <template #created_by="{record}">
          <div class="order-id-item">
          <span type="text" class="order-id-text-button order-id-text-bold">
            {{ findEmployee(record.created_by).name }}
          </span>
          <span type="text" class="" style="font-size: 12px">
<!--            {{ moment(record.created_at).format('DD/MM/YYYY HH:mm') }}-->
            {{ dateTimeDisplayFormatFromShortString(record.created_at) }}
          </span>
          </div>
        </template>
        <template #ship_fee="{record}">
          <div class="order-id-item">
          <span type="text" class="order-id-text-button">
            {{ record.shipping_method?.split('-')[0] }}
          </span>
            <span type="text" class="order-id-text-bold">
            {{ formatMoney(record.ship_fee) }}
          </span>
          </div>
        </template>
        <template #cod_amount="{record}">
          <div class="order-id-item">
          <span type="text" class="order-id-text-button order-id-text-bold">
            {{ formatMoney(record.cod_amount) }}
          </span>
          </div>
        </template>
        <template #detail="{record}">
          <div class="order-id-item">
          <span type="text" class="order_detail">
            {{ record.detail }}
          </span>
          </div>
        </template>
        <template #status="{ record }">
          <span class="table-status-item">
                    <a-tag :color="getOrderStatusColor(record.status)">{{ convertOrderStatus(record.status) }}</a-tag>
                </span>
        <div>
<!--          {{ moment(record.updated_at).format('DD/MM/YYYY HH:mm') }}-->
          <span style="font-size: 12px">
          {{  dateTimeDisplayFormatFromShortString(record.updated_at)}}
        </span>
        </div>
      </template>
      <template #action="{record}">
        <a-dropdown-button>
          <template #overlay v-if="getNextOrderActions(record.status, record.shipping_method).length>0">
            <a-menu>
              <a :href="`https://biglead.live/crm/order_thermal?order_number=${record.order_number}&token=${record.order_token}&print=true`" target="_blank">
                <a-menu-item
                    v-if="getNextOrderActions(record.status, record.shipping_method).includes('edit_order')"
                >
                  <printer-outlined/>
                  {{ $t("PRINT") }}
                </a-menu-item>

                </a>
                <router-link :to="'/order/'+record.id+'/edit'">
                  <a-menu-item
                      v-if="getNextOrderActions(record.status, record.shipping_method).includes('edit_order')"
                  >
                    <edit-outlined/>
                    {{ $t("ORDER_EDIT") }}
                  </a-menu-item>

                </router-link>
                <router-link :to="'/order/'+record.order_number">
                  <a-menu-item
                      v-if="getNextOrderActions(record.status, record.shipping_method).includes('detail_order')"
                  >
                    <edit-outlined/>
                    {{ $t("action_detail_data") }}
                  </a-menu-item>
                </router-link>
                <a-popconfirm
                    :title="$t('CONFIRM_ORDER')"
                    :ok-text="$t('OK')" :cancel-text="$t('CANCEL')" @confirm="confirmOrder(record)">
                  <a-menu-item
                      v-if="getNextOrderActions(record.status, record.shipping_method).includes('confirm_order')"
                  >
                    <CheckOutlined/>
                    {{ $t("ORDER_CONFIRMED") }}
                  </a-menu-item>
                </a-popconfirm>
                <a-popconfirm
                    :title="$t('CONFIRM_ORDER')"
                    :ok-text="$t('OK')" :cancel-text="$t('CANCEL')" @confirm="successOrder(record)">
                  <a-menu-item
                      v-if="getNextOrderActions(record.status, record.shipping_method).includes('order_success')"
                  >
                    <SafetyCertificateOutlined/>
                    {{ $t("ORDER_SUCCESS") }}
                  </a-menu-item>
                </a-popconfirm>
                <a-popconfirm
                    :title="$t('CONFIRM_ORDER')"
                    :ok-text="$t('OK')" :cancel-text="$t('CANCEL')" @confirm="shippingOrder(record)">
                  <a-menu-item
                      v-if="getNextOrderActions(record.status, record.shipping_method).includes('order_shipping')"
                  >
                    <SafetyCertificateOutlined/>
                    {{ $t("ORDER_SHIPPING") }}
                  </a-menu-item>
                </a-popconfirm>
                <a-popconfirm
                    :title="$t('CONFIRM_ORDER')"
                    :ok-text="$t('OK')" :cancel-text="$t('CANCEL')" @confirm="returningOrder(record)">
                  <a-menu-item
                      v-if="getNextOrderActions(record.status, record.shipping_method).includes('order_returning')"
                  >
                    <SafetyCertificateOutlined/>
                    {{ $t("ORDER_RETURNING") }}
                  </a-menu-item>
                </a-popconfirm>
                <a-popconfirm
                    :title="$t('CONFIRM_ORDER')"
                    :ok-text="$t('OK')" :cancel-text="$t('CANCEL')" @confirm="sendOrder(record)">
                  <a-menu-item
                      v-if="getNextOrderActions(record.status, record.shipping_method).includes('send_order')"
                  >
                    <RocketOutlined/>
                    {{ $t("ORDER_SEND") }}
                  </a-menu-item>
                </a-popconfirm>
                <a-popconfirm
                    :title="$t('CANCEL_ORDER')"
                    :ok-text="$t('OK')" :cancel-text="$t('CANCEL')" @confirm="cancelOrder(record)">
                  <a-menu-item
                      v-if="getNextOrderActions(record.status, record.shipping_method).includes('cancel_order')"
                  >
                    <StopOutlined/>
                    {{ $t("ORDER_CANCELED") }}
                  </a-menu-item>
                </a-popconfirm>
              </a-menu>
            </template>

          </a-dropdown-button>
        </template>
      </a-table>
    </a-card>
  </a-spin>
</template>

<script lang="ts">
import {defineComponent, onMounted, reactive, ref, UnwrapRef} from "vue";
import {
  SearchOutlined,
  PlusOutlined,
  FileExcelOutlined,
  EditOutlined,
  RocketOutlined,
  StopOutlined,
  PrinterOutlined,
  DownOutlined,
  SafetyCertificateOutlined, CheckOutlined, CloudUploadOutlined, CloseCircleOutlined
} from "@ant-design/icons-vue";
import {computed} from "vue";
import {useStore} from "vuex";
import {useRoute} from "vue-router";
import {StockDetail, Stocks} from "@/models/Stocks/Stocks";
import {StockProductRequest} from "@/APIs/request/ProductRequest";
import {StockProductsData} from "@/store/modules/stocks/states";
import {message, notification, Popover} from "ant-design-vue";
import APIs from "@/APIs/APIs";
import {StockActionTypes} from "@/store/modules/stocks/action-types";
import {MixUtils} from "@/utils/Utils";
import router from "@/router";
import {Product, ProductGroup} from "@/models/Products/Products";
import {OrderActionTypes} from "@/store/modules/orders/action-types";
import {OrdersRequest} from "@/APIs/request/OrdersRequest";
import {OrdersData} from "@/store/modules/orders/states";
import {Employee, Page} from "@/models/Users";
import moment, {Moment} from "moment";
import {Orders} from "@/models/Orders/Orders";
import {useI18n} from "vue-i18n";
import I18n from "@/i18n";
import {Constant} from "@/utils/Constants";
import { saveAs } from '@progress/kendo-file-saver';
import {saveExcel} from "@progress/kendo-vue-excel-export";
import {CustomerCaresActionTypes} from "@/store/modules/customerCare/action-types";
import useOrderStore from "@/store/Orders";

interface DataItem {
  id: string;
  name: string;
}

const shippingMethodData: DataItem[] = [
  {
    id: 'default',
    name: 'Vận chuyển tại của hàng'
  },
  {
    id: 'VIETTEL',
    name: 'Viettel Post'
  },
  {
    id: 'BEST',
    name: 'Best Express'
  },
  {
    id: 'GHTK',
    name: 'Giao hàng tiết kiệm'
  },
  {
    id: 'GHN',
    name: 'Giao hàng nhanh'
  },
  {
    id: 'SuperShip',
    name: 'Super Ship'
  },
  {
    id: 'VNPOST',
    name: 'VN Post'
  },
];

export default defineComponent({
  name: "Orders",
  components: {
    CloseCircleOutlined,
    CloudUploadOutlined,
    SearchOutlined,
    PlusOutlined,
    FileExcelOutlined,
    EditOutlined,
    RocketOutlined,
    StopOutlined,
    DownOutlined,
    PrinterOutlined,
    CheckOutlined,
    SafetyCertificateOutlined
  },
  created() {
    this.$watch(
        () => this.$route.query,
        () => {
          console.log('params', this.$route.query)
          if (this.$route.query.page)
            this.ordersRequest.page = Number(this.$route.query.page)
          if (this.$route.query.page_size)
            this.ordersRequest.page_size = Number(this.$route.query.page_size)
          if (this.$route.query.status) {
            this.ordersRequest.status = Number(this.$route.query.status)
            this.onChangeStatusView(this.$route.query.status)
          } else {
            this.onChangeStatusView('999')
          }
          this.fetchOrders()
          this.getOrderStat()
        },
        // fetch the data when the view is created and the data is
        // already being observed
        {immediate: true}
    )
  },
  setup() {
    const orderStore = useOrderStore()

    const spinning = ref(false)
    const openUpload = ref(false)
    const last_care = ref(undefined)
    const i18n = useI18n()
    const activeKey = ref(999)
    const allBadge = ref(0)
    const newOrdersBadge = ref(0)
    const confirmedOrdersBadge = ref(0)
    const waitingPickupBadge = ref(0)
    const pickedUpBadge = ref(0)
    const shippingBadge = ref(0)
    const requestReturnBadge = ref(0)
    const returningBadge = ref(0)
    const cancelOrdersBadge = ref(0)
    const successReturnedBadge = ref(0)
    const successShippedBadge = ref(0)
    const isSelectAll = ref(false)
    const dateFormatList = ['DD/MM/YYYY', 'DD/MM/YYYY'];

    const updated = ref<Moment[]>([])
    const filterExpanded = ref<boolean>(false)
    const pickup = ref<Moment[]>([])
    const created = ref<Moment[]>([])
    const products = ref<Product[]>([])


    const rowSelection = {
      onChange: (selectedRowKeys: (string | number)[], selectedRows: DataItem[]) => {
        console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
      },
      onSelect: (record: DataItem, selected: boolean, selectedRows: DataItem[]) => {
        console.log(record, selected, selectedRows);
      },
      onSelectAll: (selected: boolean, selectedRows: DataItem[], changeRows: DataItem[]) => {
        console.log("onSelectAll", selected);
        isSelectAll.value = selected
      },
    };
    const columns = computed(() => {
      return [
        {
          title: i18n.t('ORDER_ID'),
          dataIndex: 'order_number',
          key: 'order_number',
          slots: {customRender: 'order_number'},
          width: 150
          // sorter: (a: DataItem, b: DataItem) => a.field1.length - b.field1.length,
        },
        {
          title: i18n.t('WAREHOUSE'),
          dataIndex: 'shop_address',
          key: 'shop_address',
          slots: {customRender: 'warehouse'},
          // sorter: (a: any, b: any) => a.shop_address.length - b.shop_address.length,
          width: 150
        },
        {
          title: i18n.t('CUSTOMER'),
          dataIndex: 'customer',
          key: 'customer',
          slots: {customRender: 'customer'},
          width: 150
          // sorter: (a: DataItem, b: DataItem) => a.field1.length - b.field1.length,
        },
        {
          title: i18n.t('EMPLOYEE'),
          dataIndex: 'created_by',
          key: 'created_by',
          slots: {customRender: 'created_by'},
          width:150
          // sorter: (a: DataItem, b: DataItem) => a.field1.length - b.field1.length,
        },
        {
          title: i18n.t('PRODUCT'),
          dataIndex: 'detail',
          slots: {customRender: 'detail'},
          key: 'detail',
          // sorter: (a: DataItem, b: DataItem) => a.field1.length - b.field1.length,

        },
        {
          title: i18n.t('COLLECTED_MONEY'),
          dataIndex: 'cod_amount',
          key: 'cod_amount',
          slots: {customRender: 'cod_amount'},
          width: 120
          // sorter: (a: DataItem, b: DataItem) => a.field1.length - b.field1.length,
        },
        // {
        //   title: i18n.t('DESTINATION'),
        //   dataIndex: 'shipping_district',
        //   key: 'shipping_district',
        //   // sorter: (a: DataItem, b: DataItem) => a.field1.length - b.field1.length,
        // },
        {
          title: i18n.t('SHIPPING_FEE'),
          dataIndex: 'ship_fee',
          key: 'ship_fee',
          slots: {customRender: 'ship_fee'},
          // sorter: (a: any, b: any) => a.ship_fee - b.ship_fee,
          width: 150
        },
        {
          title: i18n.t('NOTE'),
          dataIndex: 'note',
          key: 'note',
          // sorter: (a: DataItem, b: DataItem) => a.field1.length - b.field1.length,
        },
        {
          title: i18n.t('STATUS'),
          dataIndex: 'status',
          key: 'status',
          slots: {customRender: 'status'},
          width: 150
          // sorter: (a: DataItem, b: DataItem) => a.field1.length - b.field1.length,
        },
        {
          title: i18n.t('ACTION'),
          slots: {customRender: 'action'},
          width: 100
        },
      ];
    });

    const store = useStore()
    const route = useRoute()

    const ordersRequest: UnwrapRef<OrdersRequest> = reactive({page: 1, page_size: 20})

    const getOrders = computed((): OrdersData => store.getters.getOrders)
    const getListEmployee = computed((): Employee[] => store.getters.getListEmployee)
    const getPages = computed((): Page[] => store.getters.getPages)
    const getStocks = computed((): Stocks[] | undefined => store.getters.getStocks.stocks)

    const customerCares = computed(() => store.getters.getCustomerCares)

    const fetchOrders = () => {
      // store.dispatch(OrderActionTypes.FETCH_ORDERS, ordersRequest)
      useOrderStore.getState().getOrders(ordersRequest)

    }

    const formatMoney = (money: string) => {
      return MixUtils.methods.formatMoney(money)
    }

    const handleTableChange = (pagination: any) => {
      router.replace({query: {page: pagination?.current, page_size: pagination?.pageSize}})
    }
    const pagination = computed(() => {
      return {
        current: route.query.page ? parseInt(route.query.page + "") : 1,
        pageSize: route.query.page_size ? parseInt(route.query.page_size + "") : 20,
        defaultPageSize: 20,
        total: orderStore.metadata && orderStore.metadata.record_total ? orderStore.metadata.record_total : 0,
        pageSizeOptions: ['20', '40', '80', '120'],
        showSizeChanger: true
      }
    })

    const findEmployee = (staff_id: number) => {
      let employee = {}
      const employeeFound = getListEmployee.value.find(staff => staff.cms_user_id === staff_id)
      return employeeFound ? employeeFound : employee
    }

    const getAllProduct = async () => {
      const response = await APIs.init().getAllProduct()
      message.destroy()
      if (response.status === 200 && response.data?.status === 200) {
        products.value = response.data?.data
      }
    }


    const getOrderStat = async () => {
      const response = await APIs.init().getOrderStat(ordersRequest)
      try{
        if (response.status === 200 && response.data?.status === 200) {
          let all = 0;
          for (let i = 0; i < response.data?.data.length; i++) {
            all += response.data?.data[i].count
            switch (response.data.data[i].status) {
              case 0: //new_order
                newOrdersBadge.value = response.data.data[i].count;
                break
              case 1: //order_confirm
                confirmedOrdersBadge.value = response.data.data[i].count;
                break
              case 2: //waiting pick up
                waitingPickupBadge.value = response.data.data[i].count;
                break
              case 3: //picked_ship
                pickedUpBadge.value = response.data.data[i].count;
                break
              case 7: //shipping
                shippingBadge.value = response.data.data[i].count;
                break
              case 8: //return_offer
                requestReturnBadge.value = response.data.data[i].count;
                break
              case 6: //order_returning
                returningBadge.value = response.data.data[i].count;
                break
              case -1: //order_cancel
                cancelOrdersBadge.value = response.data.data[i].count;
                break
              case 5: //order_returned
                successReturnedBadge.value = response.data.data[i].count;
                break
              case 4: //sent_ship
                successShippedBadge.value = response.data.data[i].count;
                break
            }
          }
          allBadge.value = all

        }

      }catch (e) {
        console.log(e)
      }
    }
    const fetchCustomerCares = ()=>{
      store.dispatch(CustomerCaresActionTypes.FETCH_CUSTOMERS_CARES)
    }

    onMounted(() => {
      console.log(orderStore)
      // orderStore.getOrders()
      // getAllProduct()
      // getOrderStat()
      // fetchCustomerCares()
    })

    const convertOrderStatus = (status: number) => {
      return MixUtils.methods.convertOrderStatus(status)
    }
    const getOrderStatusColor = (status: number) => {
      return MixUtils.methods.getOrderStatusColor(status)
    }
    const handleOnChangeEmployee = (employee_id: number) => {
      console.log(employee_id)
    }

    const handleOnChangePickUp = (value: any) => {
      console.log(value)
    }

    const handleOnChangeUpdated = (value: any) => {
      console.log(value)
    }

    const handleOnChangeCreated = (value: any) => {
      console.log(value)
    }
    const handleOnChangePage = (page_id: string) => {
      console.log(page_id)
      // router.replace({ query: {...route.query, ...{page: 1, page_id: page_id} }})
    }

    const handleOnChangeProduct = (product_id: number) => {
      console.log(product_id)
      // router.replace({ query: {...route.query, ...{page: 1, product_id: product_id} }})
    }

    const handleOnChangeShippingMethod = (shipping_method: string) => {
      console.log(shipping_method)
      // router.replace({ query: {...route.query, ...{page: 1, shipping_method: shipping_method} }})
    }

    const search = () => {
      const param = {}
      console.log('search', param)
      if (created.value.length > 0) {
        ordersRequest.created = created.value.map(created => created.format('YYYY-MM-DD')).join(',')
      }
      if (updated.value.length > 0) {
        ordersRequest.updated = updated.value.map(created => created.format('YYYY-MM-DD')).join(',')
      }
      if (pickup.value.length > 0) {
        ordersRequest.pickupDate = pickup.value.map(created => created.format('YYYY-MM-DD')).join(',')
      }
      Object.assign(param, ordersRequest)

      router.replace({query: {...route.query, ...{page: 1}, ...param}})
    }

    const getNextOrderActions = (status: number, shipping_method: string) => {
      switch (status) {
        case 0:
          return ['edit_order', 'confirm_order', 'cancel_order']; // Đơn nháp
        case 1:
          return shipping_method == 'default' ? ['edit_order', 'order_success', 'cancel_order'] : ['edit_order', 'send_order', 'cancel_order']; // Đã xác nhận
        case 2:
          return ['cancel_order']; // Chờ lấy hàng
        case 3: // Đã lấy hàng
        case 4:  // Giao thành công
          return ['detail_order'];
        case 8:
          return ['order_shipping', 'order_returning']; // Đề nghị hoàn
        default:
          return ['detail_order']
      }
    }

    const resetFilter = () => {
      ordersRequest.search = undefined
      ordersRequest.page_id = undefined
      ordersRequest.customer = undefined
      ordersRequest.shipping_method = undefined
      ordersRequest.employee_id = undefined
      ordersRequest.status = undefined
      ordersRequest.product_id = undefined
      ordersRequest.stock_id = undefined
      updated.value = []
      pickup.value = []
      created.value = []
      router.replace({query: {page: 1, page_size: 20}})
    }

    const changeStatus = async (order: Orders, status: number) => {
      message.loading('')
      const response = await APIs.init().changeOrderStatus({
        id: order.id,
        status: status
      })
      message.destroy()
      if (response.status === 200 && response.data?.status === 200) {
        fetchOrders()
      }
    }

    const editOrder = (order: Orders) => {
      console.log(order)
    }

    const successOrder = (order: Orders) => {
      console.log(order)
      changeStatus(order, 4)
    }

    const sendOrder = async (order: Orders) => {
      console.log(order)
      const response = await APIs.init().shippingCreateOrder(order)

      if (response.status === 200 && response.data?.status === 200) {

        notification.success({
          message: i18n.t('NOTIFICATION'),
          description: response.data?.message,
        });
      } else {
        notification.error({
          message: i18n.t('NOTIFICATION'),
          description: response.data?.message,
        });
      }
      // changeStatus(order, 1)
    }

    const cancelOrder = (order: Orders) => {
      console.log(order)
      changeStatus(order, -1)
    }
    const shippingOrder = async (order: Orders) => {
      changeStatus(order, 1)
    }
    const returningOrder = (order: Orders) => {
      console.log(order)
      changeStatus(order, 6)
    }

    const confirmOrder = (order: Orders) => {
      console.log(order, 'order')
      if (order.stock_id && order.shipping_method) {
        changeStatus(order, 1)
      } else if (!order.stock_id || !order.shipping_method) {
        message.error(i18n.t('You_have_not_created_a_stock'))
        router.push('/order/' + order.id + '/edit')
      }

    }

    const onChangeStatus = (tab: any) => {
      console.log('tab', tab)
      ordersRequest.status = activeKey.value == 999 ? undefined : activeKey.value
      ordersRequest.page = 1
      const params = {}
      Object.assign(params, ordersRequest)
      router.replace({query: {...{page: 1}, ...params}})
      fetchOrders()
    }

    const onChangeStatusView = (tab: any) => {
      activeKey.value = tab
    }

    const handleOnChangeStock = (stock_id: number) => {
      console.log(stock_id)
      // ordersRequest.stock_id = stock_id
      // fetchProductGroups()
    }

    const getUserInfo = computed(() => store.getters.getUserInfo)

    const getMyRole = computed(() => {
      if (getUserInfo.value?.id_cms_privileges == null || getUserInfo.value?.id_cms_privileges < 11) {
        return () => ({
          is_visible: 1,
          is_create: 1,
          is_read: 1,
          is_edit: 1,
          is_delete: 1,
          id: 1
        })
      } else {
        return store.getters.getRole
      }
    });
    const modules = Constant.MODULES;

    const importXLS = (info: any) => {
      //console.log(info);
      const formData = new FormData();
      formData.append("files", info);
      if(last_care.value)
        formData.append("last_care", last_care.value);

      spinning.value = true

      APIs.init()
          .importOrderXLS(formData)
          .then((res) => {
            spinning.value = false
            if (res.status === 200 && res.data?.status === 200) {
              // this.replaceLoadCustomer()
              message.success({content: "Import success"});
            } else {
              message.destroy();
            }
          })
          .catch((e) => {
            message.destroy();
            console.error(e);
          });
    }

    const exportExcel = async ()=>{
      try{
        const response = await APIs.init().getOrders({...ordersRequest, ...{
          page: 1,
          page_size: 99999
        }})

        if(response.data?.status=== 200  ){
          saveExcel({
            data: (response.data?.data?response.data.data:[]).map(item=>{
              item.created_at = moment(item.created_at).format('DD/MM/YYYY')
              return item;
            }),
            fileName: "Đơn hàng",
            headerPaddingCellOptions: { background: '#41b883' },
            columns: [
              { field: 'order_number', title: 'Mã đơn hàng'},
              { field: 'status', title: 'Trạng thái' },
              { field: 'shipping_method', title: 'Vận chuyển' },
              { field: 'shipping_code', title: 'Mã vận chuyển' },
              { field: 'detail', title: 'Sản phẩm' },
              { field: 'customer_id', title: 'Mã khách hàng' },
              { field: 'stock_id', title: 'Mã kho hàng' },
              { field: 'shipping_name', title: 'Tên người nhận' },
              { field: 'shipping_phone', title: 'SĐT người nhận' },
              { field: 'shipping_address', title: 'Địa chỉ người nhận' },
              { field: 'shipping_province', title: 'Tỉnh/Thành phố' },
              { field: 'shipping_district', title: 'Quận/Huyện' },
              { field: 'shipping_ward', title: 'Xã/Phường' },
              { field: 'total', title: 'Tổng tiền' },
              { field: 'paid', title: 'Đã thanh toán' },
              { field: 'shipping_fee', title: 'Phí ship' },
              { field: 'note_bill', title: 'Ghi chú' },
              { field: 'created_by', title: 'Id nhân viên' },
              { field: 'created_at', title: 'Ngày tạo' },
            ]
          });
        }
      }catch (e) {
        console.log(e)
      }
    }

    const handleCare  = async (value:any) =>{
      console.log(value)

    }

    return {
      openUpload,
      orderStore,
      handleCare,
      spinning,
      importXLS,
      getMyRole,
      modules,
      filterExpanded,
      activeKey,
      allBadge,
      newOrdersBadge,
      confirmedOrdersBadge,
      waitingPickupBadge,
      pickedUpBadge,
      shippingBadge,
      requestReturnBadge,
      returningBadge,
      cancelOrdersBadge,
      successReturnedBadge,
      successShippedBadge,
      labelCol: {span: 10},
      wrapperCol: {span: 14},
      columns,
      rowSelection,
      isSelectAll,
      fetchOrders,
      getOrderStat,
      pagination,
      handleTableChange,
      formatMoney,
      ordersRequest,
      getOrders,
      getPages,
      products,
      shippingMethodData,
      convertOrderStatus,
      getOrderStatusColor,
      getListEmployee,
      handleOnChangeEmployee,
      dateFormatList,
      moment,
      updated,
      pickup,
      created,
      handleOnChangePickUp,
      handleOnChangeUpdated,
      handleOnChangeCreated,
      handleOnChangePage,
      handleOnChangeProduct,
      handleOnChangeShippingMethod,
      search,
      resetFilter,
      findEmployee,
      getNextOrderActions,
      editOrder,
      successOrder,
      confirmOrder,
      sendOrder,
      cancelOrder,
      shippingOrder,
      returningOrder,
      onChangeStatus,
      onChangeStatusView,
      handleOnChangeStock,
      getStocks,
      changeStatus,
      customerCares,
      last_care,
      exportExcel
    }
  }
})
</script>

<style scoped lang="scss">
.overview-tab {
  background-color: white;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;

  .span-title-tab {
    //padding-right: 15px;
  }
}

.card-table-orders {
  margin: 16px;

  .button-more-action {
    margin-left: 18px;
    background-color: yellowgreen;
    border-color: yellowgreen;
  }

  .table-orders {
    margin-left: -14px;
    margin-right: -14px;

    .order-id-item {
      display: flex;
      flex-direction: column;

      .order-id-text-button {
        text-align: left;
      }

      .order-id-text-bold {
        font-weight: bold;
      }
    }
  }
}


@media only screen and (min-width: 768px) {
  /* For desktop: */
  .form-filter {
    background-color: white;
    grid-gap: 5px;
    grid-template-columns: repeat(auto-fit, 350px);
    display: grid;
    border-bottom-right-radius: 5px;
    border-bottom-left-radius: 5px;
  }
}

@media only screen and (max-width: 500px) {
  .form-filter {
    background-color: white;
    border-bottom-right-radius: 5px;
    border-bottom-left-radius: 5px;
  }
}


/*
  Enter and leave animations can use different
  durations and timing functions.
*/
.slide-fade-enter-active {
  transition: all 0.3s ease-out;
}

.slide-fade-leave-active {
  transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-fade-enter-from,
.slide-fade-leave-to {
  transform: translateX(20px);
  opacity: 0;
}

.title-header {
  font-weight: bold;
  flex: 1;
}

.container-title {
  display: flex;
  flex-direction: row;
  padding: 16px 24px 16px 24px;
  margin-bottom: 0;
  background-color: white
}

.input-search {
  width: 250px;
  margin-right: 16px;
}

.radio-item-customer-care {
  margin-right: 6px;
  margin-top: 6px;
}

.radio-group-customer-care {
  margin: 16px 16px 0 16px;
}
.order_detail{
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
}
</style>
