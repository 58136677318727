<template>
  <div class="container-title">
    <h3 class="title-header">{{ $t($route.name) }}</h3>
    <a-button
        v-if="getMyRole(modules.role)?.is_create && getMyRole(modules.role)?.is_edit"
        type="primary" class="button-add-product" @click="syncRole">
      <plus-outlined/>
      {{$t('SYNC_ROLES')}}
    </a-button>
    <a-button
        v-if="getMyRole(modules.role)?.is_create"
        type="primary" class="button-add-product" @click="create">
      <plus-outlined/>
      {{$t('ADD')}}
    </a-button>
  </div>
  <div class="container-content">
    <a-table
        size="middle"
        :columns="columns"
        :data-source="roles"
        :pagination="false"
    >
      <template #name="{record}">
        <a @click="viewRole(record)">
          {{ record.name }}
        </a>
      </template>
      <template #action="{record}">
        <div style="display: flex; justify-content: center">
          <a
              v-if="getMyRole(modules.role)?.is_read"
              @click="viewRole(record)">
            <EyeOutlined
            />
          </a>
          <a-divider  type="vertical" />
          <a
              v-if="getMyRole(modules.role)?.is_edit"
              @click="editRole(record)">
            <EditOutlined
            />
          </a>
          <a-divider type="vertical" />
          <a-popconfirm
              v-if="getMyRole(modules.role)?.is_delete"
              :title="$t('CONFIRM_DELETE')+' ' +$t('ROLE')+'?'"
              :ok-text="$t('OK')"
              :cancel-text="$t('CANCEL')"
              @confirm="deleteRole(record)"
          >
          <a>
            <DeleteOutlined
            />
          </a>
          </a-popconfirm>
        </div>
      </template>
    </a-table>
    <div v-if="currentRole" style=" margin-left: 16px">
      <span style="font-weight: bold; font-size: 16px">{{ currentRole?.name }}</span>
      <a-table
          style="margin-top: 10px;"
          size="middle"
          :columns="columnsPrivilege"
          :data-source="currentPrivilegeRole"
          :footer="null"
          :pagination="false"
      >
        <template #name="{record}" >
          <a>
            {{ $t('role_' + record.name) }}
          </a>
        </template>
        <template #is_visible="{record}">
          <a-checkbox
              :disabled="!getMyRole(modules.role)?.is_edit"
              v-model:checked="record.is_visible" @change="updatePrivilege(record)"></a-checkbox>
        </template>
        <template #is_create="{record}">
          <a-checkbox
              :disabled="!getMyRole(modules.role)?.is_edit"
              v-model:checked="record.is_create" @change="updatePrivilege(record)"></a-checkbox>
        </template>
        <template #is_read="{record}">
          <a-checkbox
              :disabled="!getMyRole(modules.role)?.is_edit"
              v-model:checked="record.is_read" @change="updatePrivilege(record)"></a-checkbox>
        </template>
        <template #is_edit="{record}">
          <a-checkbox
              :disabled="!getMyRole(modules.role)?.is_edit"
              v-model:checked="record.is_edit" @change="updatePrivilege(record)"></a-checkbox>
        </template>
        <template #is_delete="{record}">
          <a-checkbox
              :disabled="!getMyRole(modules.role)?.is_edit"
              v-model:checked="record.is_delete" @change="updatePrivilege(record)"></a-checkbox>
        </template>
      </a-table>
    </div>
  </div>
  <a-modal v-model:visible="editVisible" :title="formState.id?$t('UPDATE_ROLE'):$t('ADD_ROLE')"
           :footer="null"
  >
    <a-form
        ref="formRef"
        :model="formState"
        :rules="rules"
        :label-col="labelCol"
        :wrapper-col="wrapperCol"
    >
      <a-form-item :label="$t('NAME')" name="name">
        <a-input v-model:value="formState.name"/>
      </a-form-item>
      <a-form-item :wrapper-col="{ offset: 8 }">
        <a-button type="primary" @click="onSubmit">{{formState.id?$t('UPDATE'):$t('CREATE')}}</a-button>
        <a-button style="margin-left: 10px"  @click="editVisible=!editVisible">{{ $t('CANCEL') }}</a-button>
      </a-form-item>
    </a-form>
  </a-modal>
</template>

<script lang="ts">
import {computed, defineComponent, onMounted, ref, reactive, UnwrapRef} from "vue";
import {useRoute} from "vue-router";
import {useStore} from "@/store";
import {useI18n} from "vue-i18n";
import APIs from "@/APIs/APIs";
import {message} from "ant-design-vue";
import {PrivilegeRole, Role} from "@/models/Role/Role";
import {DeleteOutlined, EditOutlined, EyeOutlined} from "@ant-design/icons-vue";
import {Constant} from "@/utils/Constants";


export default defineComponent({
  name: "Role",
  components: {
    EyeOutlined,
    DeleteOutlined,
    EditOutlined,
  },
  created() {
    console.log('role'
    )
  },
  setup(props) {
    const route = useRoute();
    const store = useStore();
    const i18n = useI18n();
    const formRef = ref();

    const editVisible=ref(false)
    const roles = ref<Role[]>([])
    const currentRole = ref<Role>()
    const currentPrivilegeRole = ref<PrivilegeRole[]>([])
    const formState: UnwrapRef<any> = reactive({
      id: undefined,
      name: '',
    });

    const columns = computed(() => [
      {
        title: i18n.t('NAME'),
        dataIndex: 'name',
        key: 'name',
        slots: {customRender: 'name'},
        width: 300
      },

      {
        title: i18n.t('ACTION'),
        key: "action",
        slots: {customRender: "action"},
        width: 70
      },
    ]);


    const columnsPrivilege = computed(() => [
      {
        title: i18n.t('NAME'),
        dataIndex: 'name',
        key: 'name',
        slots: {customRender: 'name'},
      },
      {
        title: i18n.t('is_visible'),
        dataIndex: 'is_visible',
        key: 'is_visible',
        slots: {customRender: 'is_visible'},
        width: 70,
        align: 'center'
      },
      {
        title: i18n.t('is_read'),
        dataIndex: 'is_read',
        key: 'is_read',
        slots: {customRender: 'is_read'},
        width: 70,
        align: 'center'
      },
      {
        title: i18n.t('is_create'),
        dataIndex: 'is_create',
        key: 'is_create',
        slots: {customRender: 'is_create'},
        width: 70,
        align: 'center'
      },
      {
        title: i18n.t('is_edit'),
        dataIndex: 'is_edit',
        key: 'is_edit',
        slots: {customRender: 'is_edit'},
        width: 70,
        align: 'center'
      },
      {
        title: i18n.t('is_delete'),
        dataIndex: 'is_delete',
        key: 'is_delete',
        slots: {customRender: 'is_delete'},
        width: 70,
        align: 'center'
      },
    ]);

    const rules = {
      name: [
        {required: true, message: i18n.t('PLACEHOLDER_INPUT_NAME'), trigger: 'blur'},
      ],
    };


    const getData = async () => {
      APIs.init().getRoles().then(res => {
        if (res.status === 200 && res.data?.status === 200) {
          roles.value = res.data.data
        } else {
          message.destroy()
        }
      })
    }

    const getPrivilegeRole = async () => {
      APIs.init().getPrivilegeRole({
        id: currentRole.value?.id
      }).then(res => {
        if (res.status === 200 && res.data?.status === 200) {
          currentPrivilegeRole.value = res.data.data
        } else {
          message.destroy()
        }
      })
    }

    const viewRole = (role: Role) => {
      if(getMyRole.value(modules.role)?.is_read){
        currentRole.value = role
        getPrivilegeRole()
      }
    }
    const updatePrivilege = async (role: PrivilegeRole) => {
      const res = await APIs.init().updatePrivilegeRole({...role, ...{
          is_visible: role.is_visible?1:0,
          is_create: role.is_create?1:0,
          is_read: role.is_read?1:0,
          is_edit: role.is_edit?1:0,
          is_delete: role.is_delete?1:0,
          id_cms_privileges: currentRole.value?.id,
        }})
      if (res.status === 200 && res.data?.status === 200) {
        console.log(res.data.data)
      } else {
        message.destroy()
      }
      getPrivilegeRole()
    }

    onMounted(() => {
      getData()
    })

    const create = () => {
      formState.id = undefined
      formState.name = ""
      editVisible.value = true
    };

    const editRole = (role: Role) => {
      formState.id = role.id
      formState.name = role.name
      editVisible.value = true
    };

    const createRole=()=>{
      message.loading('');
      APIs.init().createRole(formState).then(res => {
        if (res.status === 200 && res.data?.status === 200) {
          editVisible.value = false
          message.success({content: 'Created role: ' + res.data.data.name});
          getData()
        } else {
          message.destroy()
        }
      }).catch(e => {
        message.destroy()
        console.error(e)
      })
    }

    const deleteRole=(role: Role)=>{
      message.loading('');
      APIs.init().deleteRole(role).then(res => {
        if (res.status === 200 && res.data?.status === 200) {
          editVisible.value = false
          message.success({content: 'Deleted role: ' + res.data.data.name});
          getData()
        } else {
          message.destroy()
        }
      }).catch(e => {
        message.destroy()
        console.error(e)
      })
    }

    const updateRole=()=>{
      message.loading('');
      APIs.init().updateRole(formState).then(res => {
        if (res.status === 200 && res.data?.status === 200) {
          editVisible.value = false
          message.success({content: 'Updated role: ' + formState.name});
          getData()
        } else {
          message.destroy()
        }
      }).catch(e => {
        message.destroy()
        console.error(e)
      })
    }

    const syncRole=()=>{
      message.loading('');
      APIs.init().syncRoles().then(res => {
        if (res.status === 200 && res.data?.status === 200) {
          editVisible.value = false
          message.success({content: 'Sync roles success'});
          getData()
        } else {
          message.destroy()
        }
      }).catch(e => {
        message.destroy()
        console.error(e)
      })
    }

    const onSubmit = ()=>{
      console.log('submit')
      formRef.value
          .validate()
          .then(() => {
            if(formState.id){
              updateRole()
            }else{
              createRole()
            }
          })
          .catch((error: any) => {
            console.log('error', error);
          });
    }

    const getUserInfo = computed(() => store.getters.getUserInfo)

    const getMyRole = computed(() => {
      if(getUserInfo.value?.id_cms_privileges==null || getUserInfo.value?.id_cms_privileges<11){
        return (module: string)=>({
          is_visible: 1,
          is_create:1,
          is_read: 1,
          is_edit: 1,
          is_delete: 1,
          id: 1
        })
      }else{
        return store.getters.getRole
      }
    });
    const modules = Constant.MODULES;


    return {
      getMyRole,
      modules,
      formRef,
      getData,
      onSubmit,
      roles,
      columns,
      columnsPrivilege,
      viewRole,
      editRole,
      deleteRole,
      currentRole,
      currentPrivilegeRole,
      updatePrivilege,
      create,
      formState,
      editVisible,
      syncRole,
      rules,
      labelCol: { span: 6 },
      wrapperCol: { span: 16 },
    }
  }
})

</script>

<style scoped>
.container-content {
  display: flex;
  flex-direction: row;
  /*margin: 0 16px 16px 16px;*/
}
.table-row-name{
  min-width: 250px;
}
.button-add-product{
  margin-left: 16px
}
</style>