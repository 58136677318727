<template>
  <div class="container-content" id="container-content">
    <template v-for="(row, index) in chunkedArr" :key="index">
      <div  class="container-row">
      <template v-for="version in row" :key="version.id">
        <a-col :span="12" class="col">
          <div style="font-size: 12px">{{ $t('PRICE') }}: <span
              style="font-weight: bold">{{ formatMoney(version.unit_price) }}</span></div>
          <div style="margin-left: 24px; margin-right: 24px">
            <vue-barcode :value="version.bar_code"
                         :options="{ margin: 0, height: 50, fontSize: 12, marginLeft: 30, marginRight: 30,}"
            ></vue-barcode>
          </div>
        </a-col>
      </template>
      </div>
    </template>


  </div>
</template>

<script>
import APIs from "@/APIs/APIs";
import {message, Modal} from "ant-design-vue";
import {MixUtils} from "@/utils/Utils";


export default {
  name: "ProductPreview",
  created() {
    this.$watch(
        () => this.$route.params,
        () => {
          if (this.$route.params.id) {
            this.getData(this.$route.params.id)
          }
        },
        // fetch the data when the view is created and the data is
        // already being observed
        {immediate: true}
    )
    // this.print()
  },
  data() {
    return {
      versions: []

    }
  },
  computed: {
    chunkedArr() {
      const result = []
      for (let i = 0; i < this.versions.length; i += 2){
        result.push(this.versions.slice(i, i + 2))
      }
      if(result.length>0) {
        if(this.versions.length%2!==0){
          result[result.length-1].push({})
        }
      }
      return result
    }
  },
  methods: {
    async print() {
      // Pass the element id here
      setTimeout(() => {
        window.print()
      }, 500)
    },
    async getData(id) {
      message.loading("");
      const response = await APIs.init().getProductDetail({
        product_group_id: id,
      });
      console.log(response)
      message.destroy();
      if (response.status === 200 && response.data?.status === 200) {
        console.log(response.data.data)
        try {
          this.versions = response.data.data?.versions ? response.data.data?.versions : []
          setTimeout(() => {
            window.onafterprint = () => {
              window.close();
            }
            window.print()
          }, 500)
        } catch (e) {
          console.log(e)
        }
      }
    },
    formatMoney(money) {
      return MixUtils.methods.formatMoney(money)
    }
  }

}
</script>

<style scoped>
.container-content {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.container-row {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
}

.ant-modal-content {
  display: flex;
  flex-direction: column;
  height: calc(100vh);
}

.modal {
  box-shadow: 0 0;
  background-color: red;
}

.ant-modal-mask {
  background: transparent;
  pointer-events: none;
}

.ant-modal-wrap {
  pointer-events: none;
}

.ant-modal {
  pointer-events: all;
}

.col {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-color: white;
  overflow: hidden !important;
  display: flex;
  align-content: center;
  width: 4in;
  height: 1in;
  justify-content: center;
}
</style>
