import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withKeys as _withKeys } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_tag = _resolveComponent("a-tag")!
  const _component_a_tooltip = _resolveComponent("a-tooltip")!
  const _component_a_input = _resolveComponent("a-input")!
  const _component_plus_outlined = _resolveComponent("plus-outlined")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.tags, (tag, index) => {
      return (_openBlock(), _createElementBlock(_Fragment, { key: index }, [
        (tag.length > 20)
          ? (_openBlock(), _createBlock(_component_a_tooltip, {
              key: 0,
              title: tag
            }, {
              default: _withCtx(() => [
                _createVNode(_component_a_tag, {
                  closable: "",
                  onClose: ($event: any) => (_ctx.handleClose(tag)),
                  style: {"height":"28px","padding-top":"4px","margin":"4px 8px"}
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(`${tag.slice(0, 20)}...`), 1)
                  ]),
                  _: 2
                }, 1032, ["onClose"])
              ]),
              _: 2
            }, 1032, ["title"]))
          : (_openBlock(), _createBlock(_component_a_tag, {
              key: 1,
              closable: "",
              onClose: ($event: any) => (_ctx.handleClose(tag)),
              style: {"height":"28px","padding-top":"4px","margin":"4px 8px"}
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(tag), 1)
              ]),
              _: 2
            }, 1032, ["onClose"]))
      ], 64))
    }), 128)),
    (_ctx.inputVisible)
      ? (_openBlock(), _createBlock(_component_a_input, {
          key: 0,
          ref: "inputRef",
          value: _ctx.inputValue,
          "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.inputValue) = $event)),
          type: "text",
          size: "small",
          style: { width: '78px', height: '28px', marginTop: '4px', marginBottom: '4ppx', marginLeft: '8px', marginRight: '8px' },
          onBlur: _ctx.handleInputConfirm,
          onKeyup: _withKeys(_ctx.handleInputConfirm, ["enter"])
        }, null, 8, ["value", "onBlur", "onKeyup"]))
      : (_openBlock(), _createBlock(_component_a_tag, {
          key: 1,
          style: {"background":"#fff","height":"28px","padding-top":"4px","margin":"4px 8px","border-style":"dashed"},
          onClick: _ctx.showInput
        }, {
          default: _withCtx(() => [
            _createVNode(_component_plus_outlined),
            _createTextVNode(" " + _toDisplayString(_ctx.$t('NEW_ATTRIBUTE')), 1)
          ]),
          _: 1
        }, 8, ["onClick"]))
  ], 64))
}