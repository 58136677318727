import create from "zustand-vue";
import { persist } from "zustand/middleware";
import APIs from "@/APIs/APIs";
import { AWS_DO_Model } from "@/models/AWS_DO/AWS_DO";

interface AWS_DO_FileStorageState {
  awsDoFiles: AWS_DO_Model[];
  getAWS_DO_Files: () => void;
}

const initialState = {
  awsDoFiles: [],
};

const useAWS_DO_FileStorageStore = create<AWS_DO_FileStorageState>(
  // @ts-ignore
  persist(
    (set) => ({
      ...initialState,
      getAWS_DO_Files: async () => {
        const response = await APIs.init().getFiles();
        if (response.data && response.data.status === 200) {
          set(() => ({
            awsDoFiles: response.data?.data,
          }));
        }
      },
    }),
    {
      name: "AWS_DO_FileStorage",
    }
  )
);

export default useAWS_DO_FileStorageStore;
