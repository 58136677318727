import {ActionContext, ActionTree} from "vuex";
import {VoucherData, State} from "./states";
import {RootState} from "@/store";
import APIs from "@/APIs/APIs";
import {FlowsResponse, VouchersResponse} from "@/APIs/response/S_HttpResponse";
import {FlowsRequest} from "@/APIs/request/FlowRequest";
import {Mutations} from "@/store/modules/voucher/mutations";
import {VoucherActionTypes} from "@/store/modules/voucher/action-types";
import {VouchersRequest} from "@/APIs/request/VoucherRequest";
import {VoucherMutationTypes} from "@/store/modules/voucher/mutation-types";


type AugmentedActionContext = {
    commit<K extends keyof Mutations>(
        key: K,
        payload: Parameters<Mutations[K]>[1],
    ): ReturnType<Mutations[K]>;
} & Omit<ActionContext<State, RootState>, 'commit'>

export interface Actions {
    [VoucherActionTypes.FETCH_VOUCHER](
        {commit}: AugmentedActionContext,
        payload: VouchersRequest
    ): Promise<VouchersResponse | undefined>,
    [VoucherActionTypes.FETCH_VOUCHER_ACTIVE](
        {commit}: AugmentedActionContext,
        payload: any
    ): Promise<any | undefined>,
}

export const actions: ActionTree<State, RootState> & Actions = {
    async [VoucherActionTypes.FETCH_VOUCHER]({commit}, payload: VouchersRequest): Promise<VouchersResponse | undefined> {
        // console.log(payload);
        const response = await APIs.init().getVouchers(payload)
        if (response.status === 200) {
            const vouchers: VoucherData = {
                voucher: response.data?.data,
                metadata: response.data?.meta_data
            }
            commit(VoucherMutationTypes.SET_VOUCHER, vouchers)
        }
        return response.data;
    },
    async [VoucherActionTypes.FETCH_VOUCHER_ACTIVE]({commit}, payload: any): Promise<any | undefined> {
        // console.log(payload);
        const response = await APIs.init().getaActiveVoucher(payload)
        if (response.status === 200) {

            commit(VoucherMutationTypes.SET_VOUCHER_ACTIVE, response.data?.data)
        }
        return response.data;
    },
}
