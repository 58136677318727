import {State} from "./states";
import {ShopInfo, UpdateShopInfo} from "@/models/Shopinfo/ShopInfo";
import {Page} from "@/models/Users";
import useShopInfoStore from "@/store/ShopInfo";


export type Getters = {
    getShopinfo(state: State): ShopInfo|UpdateShopInfo
    getMailPage(state: State): Page[]


}
export const getters: Getters = {
    getShopinfo(state: State): ShopInfo|UpdateShopInfo {

        if (useShopInfoStore.getState().dataShopInfo) {
            // @ts-ignore
            return useShopInfoStore.getState().dataShopInfo
        } else {
            return {
                id: 0,
                cms_user_id: 0,
                shop_name: "",
                shop_email: "",
                shop_email_sell: "",
                shop_gpkd: "",
                shop_tel: "",
                shop_address: "",
                province_id: 0,
                district_id: 0,
                shop_address_full: "",
                ward_id: 0,
                shop_order_start: "",
                shop_order_end: "",
                shop_pay_type: "",
                shop_pay_desc: "",
                shop_invoice_ship: "",
                shop_invoice_unship: "",
                price_zone_1: 0,
                price_zone_2: 0,
                price_zone_3: 0,
                district_zone: "",
                shop_bank_owner: "",
                bank_id: 0,
                shop_bank_number: "",
                shop_bank_branch: "",
                balance_1: 0,
                balance_2: 0,
                system_phone: "",
                system_note: "",
                system_tag: "",
                auto_send_sms: 0,
                status: "",
                created_at: "",
                updated_at: "",
                ship_status: 0,
                customer_tag: "",
                employee_role_10: "",
                page_config_general: "",
                shop_services: {
                    id: 0,
                    code: "",
                    page_num: 0,
                    user_num: 0,
                    month_num: 0,
                    date_num: 0,
                    price: 0,
                    start_date: "",
                    end_date: "",
                    status: "",
                    shop_id: 0,
                    tariff_id: 0,
                    trans_detail: "",
                    real_money: 0,
                    desc_money: "",
                    user_phone: "",
                    vnp_transaction_no: "",
                    created_at: "",
                    updated_at: "",
                    coupon_code: "",
                    is_buy: 0
                }
            }
        }
    },
    getMailPage(state: State):Page[]{
        if (state.mailPages){
            return state.mailPages;
        }
        return []
    }


}
