<template>
  <a-popover :title="$t('SETTING') +' ' + buttonName" trigger="click" placement="right"
             v-model:visible="popoverVisible">
    <template #content>
      <a-form
          style="max-width: 300px"
          ref="formRef"
          :model="formState"
          layout="vertical"
      >
<!--        <a-form-item :label="$t('FLOW_CMR_ACTION')" name="action_crm">-->
<!--          <div class="action-crm" v-for="(item,index) in formState.flow_crm_actions" :key="'index_'+index">-->
<!--            <div class="crm-action-title-container">-->
<!--              <div class="title-selected-crm-action">-->
<!--                {{ $t(item.type) }}-->
<!--              </div>-->
<!--              <a-tag color="#f50" class="crm-action-deletion" @click="deleteCRM_Action(item)">{{ $t('DELETE') }}</a-tag>-->
<!--            </div>-->
<!--            <a-select-->
<!--                v-if="item.type==='add_tag'||item.type==='remove_tag'"-->
<!--                mode="multiple"-->
<!--                :placeholder="$t('TAG_SELECT')"-->
<!--                @search="searchTags"-->
<!--                v-model:value="item.tagArr"-->
<!--            >-->
<!--              <a-select-option v-for="tag in customerTags" :label="tag.name" :key="tag.id" :value="tag.id">-->
<!--                &nbsp;&nbsp;{{ tag.name }}-->
<!--              </a-select-option>-->
<!--            </a-select>-->

<!--          </div>-->
<!--          <a-select-->
<!--              v-model:value="selectedCRM_Action"-->
<!--              :placeholder="$t('FLOW_ADD_CMR_ACTION')"-->
<!--              @change="selectCRM_ActionChange"-->
<!--          >-->
<!--            <a-select-option v-for="item in crmActions" :value="item" :key="item">{{ $t(item) }}</a-select-option>-->
<!--          </a-select>-->
<!--        </a-form-item>-->
        <a-form-item>
          <a-popover :title="$t('FLOW_ADD_CMR_ACTION')">
            <template #content>
<!--              <a-select-->
<!--                  v-model:value="selectedCRM_Action"-->
<!--                  :placeholder="$t('FLOW_ADD_CMR_ACTION')"-->
<!--                  @change="selectCRM_ActionChange"-->
<!--              >-->
<!--                <a-select-option v-for="item in crmActions" :value="item" :key="item">{{ $t(item) }}</a-select-option>-->
<!--              </a-select>-->
              <a-tag style="display: block;cursor: pointer;margin-bottom: 10px"  @click="selectCRM_ActionChange(i)" v-for="i in crmActions" :key="i">{{$t(i)}}</a-tag>
            </template>
            <a-button  >{{$t('FLOW_CMR_ACTION')}}</a-button>
          </a-popover>
        </a-form-item>
        <a-form-item  name="action_crm">
          <div class="action-crm" v-for="(item,index) in formState.flow_crm_actions" :key="'index_'+index">
            <div class="crm-action-title-container">
              <div class="title-selected-crm-action">
                {{ $t(item.type) }}
              </div>
            </div>
           <a-row style="align-items: center">
             <a-col :span="20">
               <a-tree-select
                   v-if="item.type==='add_tag'||item.type==='remove_tag'"
                   show-search
                   style="width: 100%"
                   v-model:value="item.tagArr"
                   :filterTreeNode="false"
                   @search="searchTags"
                   :notFoundContent="null"
                   :dropdown-style="{ maxHeight: '300', overflow: 'auto' }"
                   allow-clear
                   multiple
                   :placeholder="$t('TAG_SELECT')"
               >
                 <a-tree-select-node v-for="item in tagSale.tagTree" :key="item.id" v-bind:value="item.id">
                   <template #title>
                     <a-tag :color="item.color">
                       {{ item.name }}
                     </a-tag>
                   </template>
                   <a-tree-select-node v-for="child in item.children" :key="child.id" v-bind:value="child.id">
                     <template #title>
                       <a-tag :color="child.color">
                         {{ child.name }}
                       </a-tag>
                     </template>
                   </a-tree-select-node>

                 </a-tree-select-node>
               </a-tree-select>

               <a-select
                   v-else-if="item.type==='update_last_care'"
                   style="width: 100%"
                   allowClear
                   placeholder="Please select"
                   v-model:value="item.last_care"
                   @change="handleCare"
                   @blur="handleCare"
               >
                 <a-select-option
                     v-for="item in customerCares"
                     :key="item.id"
                     v-bind:value="item.id"

                 >
                   <a-tag :color="item.color">
                     {{ item.name }}
                   </a-tag>
                 </a-select-option>

               </a-select>
               <a-tag v-if="item.type === 'unread_conversation' ">{{$t('unread_conversation')}}</a-tag>
               <a-tree-select
                   v-else
                   show-search
                   style="width: 100%"
                   v-model:value="item.coverstion_tag"
                   :filterTreeNode="false"
                   @search="searchTags"
                   :notFoundContent="null"
                   :dropdown-style="{ maxHeight: '300', overflow: 'auto' }"
                   allow-clear
                   multiple
                   :placeholder="$t('TAG_SELECT')"
               >
                 <a-tree-select-node disabled  v-for="item in converstionTag" :key="item.page_id" v-bind:value="item.page_id" >
                   <template #title>
                     <a-tag :color="item.color">
                       <img
                           v-if="item.page_type === 2"
                           draggable="false"
                           src="@/assets/icons/zalo-seeklogo.com.svg"
                           style="width: 20px;margin-right: 20px"
                       />
                       <img
                           v-else-if="item.page_type === 1"
                           draggable="false"
                           src="@/assets/icons/facebook-svgrepo-com.svg"
                           style="width: 20px;margin-right: 20px"
                       />
                       <img
                           v-else-if="item.page_type === 3"
                           draggable="false"
                           src="@/assets/icons/instagram-svgrepo-com.svg"
                           style="width: 20px;margin-right: 20px"
                       />
                       <img
                           v-else-if="item.page_type === 4"
                           draggable="false"
                           src="@/assets/icons/line-svgrepo-com.svg"
                           style="width: 20px;margin-right: 20px"
                       />
                       {{ item.page_name }}
                     </a-tag>
                   </template>
                   <a-tree-select-node v-for="treeData in item.tags" :key="item.page_id + '_'+treeData.tag_id" v-bind:value="item.page_id + '_'+treeData.tag_id">
                     <template #title>
                       <a-tag :color="treeData.tag_color">
                         {{ treeData.tag_name }}
                       </a-tag>
                     </template>
                   </a-tree-select-node>

                 </a-tree-select-node>
               </a-tree-select>
<!--               <div v-else>-->
<!--                 <a-select-->
<!--                     style="width: 100%;margin-bottom: 10px"-->
<!--                     allowClear-->
<!--                     placeholder="Please select"-->
<!--                     v-model:value="getCurrentPageId"-->
<!--                     @change="handlePage"-->
<!--                 >-->
<!--                   <a-select-option-->
<!--                       v-for="item in currentShop.pages"-->
<!--                       :key="item.page_id"-->
<!--                       v-bind:value="item.page_id"-->

<!--                   ><img-->
<!--                       v-if="item.page_type === 2"-->
<!--                       draggable="false"-->
<!--                       src="@/assets/icons/zalo-seeklogo.com.svg"-->
<!--                       style="width: 20px;margin-right: 20px"-->
<!--                   />-->
<!--                     <img-->
<!--                         v-else-if="item.page_type === 1"-->
<!--                         draggable="false"-->
<!--                         src="@/assets/icons/facebook-svgrepo-com.svg"-->
<!--                         style="width: 20px;margin-right: 20px"-->
<!--                     />-->
<!--                     <img-->
<!--                         v-else-if="item.page_type === 3"-->
<!--                         draggable="false"-->
<!--                         src="@/assets/icons/instagram-svgrepo-com.svg"-->
<!--                         style="width: 20px;margin-right: 20px"-->
<!--                     />-->
<!--                     <img-->
<!--                         v-else-if="item.page_type === 4"-->
<!--                         draggable="false"-->
<!--                         src="@/assets/icons/line-svgrepo-com.svg"-->
<!--                         style="width: 20px;margin-right: 20px"-->
<!--                     />-->

<!--                       {{ item.page_name }}-->
<!--                   </a-select-option>-->

<!--                 </a-select>-->
<!--                 <a-select-->
<!--                     style="width: 100%"-->
<!--                     allowClear-->
<!--                     placeholder="Please select"-->
<!--                     v-model:value="item.coverstion_tag"-->
<!--                     @change="handleCare"-->
<!--                     @blur="handleCare"-->
<!--                     mode="tags"-->
<!--                 >-->
<!--                   <a-select-option-->
<!--                       v-for="item in converstionTags"-->
<!--                       :key="item.tag_id"-->
<!--                       v-bind:value="item.tag_id"-->

<!--                   >-->
<!--                     <a-tag :color="item.tag_color">-->
<!--                       {{ item.tag_name }}-->
<!--                     </a-tag>-->
<!--                   </a-select-option>-->

<!--                 </a-select>-->
<!--               </div>-->
             </a-col>
             <a-col :span="1"></a-col>
             <a-col :span="3">
               <a-tag color="#f50" class="crm-action-deletion" @click="deleteCRM_Action(item)">{{ $t('DELETE') }}</a-tag>

             </a-col>
           </a-row>

          </div>
<!--          <a-select-->
<!--              v-model:value="selectedCRM_Action"-->
<!--              :placeholder="$t('FLOW_ADD_CMR_ACTION')"-->
<!--              @change="selectCRM_ActionChange"-->
<!--          >-->
<!--            <a-select-option v-for="item in crmActions" :value="item" :key="item">{{ $t(item) }}</a-select-option>-->
<!--          </a-select>-->
        </a-form-item>
        <a-form-item v-if="formState.type !== '0' || !formState.usage_id" :label="$t('SELECT_AVAILABLE_MESSAGE')" name="usage_id">

          <a-select
              v-model:value="formState.usage_id"
              :placeholder="$t('SELECT_AVAILABLE_MESSAGE')"
              @change="changeFlowAvailable"
              allowClear
              :options="getFlowNextActions()"
          >
          </a-select>
        </a-form-item>
        <a-form-item v-if="formState.type === '0' || !formState.usage_id"  :label="$t('SELECT_AVAILABLE_FLOW')" name="usage_id">

          <a-select
              v-model:value="formState.usage_id"
              :placeholder="$t('SELECT_AVAILABLE_FLOW')"
              @change="changeFlowAvailable2"
              allowClear

          >
            <a-select-option v-for="item in getFlowData.flows"  :key="item.id"
                             v-bind:value="item.id">
                {{item.name}}

            </a-select-option>
          </a-select>
        </a-form-item>

        <!--        <a-form-item>-->
        <!--          <a-button type="dashed" class="ant-btn ant-btn-dashed ant-btn-block" @click="modalFlow">Chọn Flow có sẵn</a-button>-->
        <!--        </a-form-item>-->
        <a-form-item v-if="nextFlowContext&&nextFlowContext.name" :label="$t('FLOW_NEXT_CONTENT')">
          <div class="right-button-content">
            <a-button type="dashed" class="selected-flow-action" @click="changeCurrentFlowContext">
              <facebook-outlined style="font-size: 16px"/>
              <span>
              {{ nextFlowContext.name }}
            </span>
            </a-button>
            <a-tag color="#f50" style="cursor: pointer" @click="deleteNextFlowAction">
              <delete-outlined/>
            </a-tag>
          </div>
        </a-form-item>
        <div v-else>
          <a-form-item v-if="!isNextFlow">
            <a-button type="dashed" block @click="isNextFlow=true">{{ $t('FLOW_NEXT_CONTENT') }}</a-button>
          </a-form-item>
          <div v-else>
            <a-divider/>
            <!--            <a-form-item :label="$t('FLOW_SELECT_AVAILABLE')">-->
            <!--              <a-select-->
            <!--                  :placeholder="$t('FLOW_CONTEXT_PLACEHOLDER')"-->
            <!--              >-->
            <!--                <a-select-option value="jack">Jack</a-select-option>-->
            <!--                <a-select-option value="lucy">Lucy</a-select-option>-->
            <!--              </a-select>-->
            <!--            </a-form-item>-->
            <a-form-item>
              <a-button type="dashed" block @click="newFacebookFlow(1)">
                <facebook-outlined style="font-size: 16px"/>
                {{ $t('FLOW_NEW_FACEBOOK') }}
              </a-button>
            </a-form-item>
            <a-form-item>
              <a-button type="dashed" block @click="newFacebookFlow(2)">
                <img src="@/assets/icons/zalo-flat.svg" class="anticon" style="width: 16px;height: 16px;"/>
                {{ $t('FLOW_NEW_ZALO') }}
              </a-button>
            </a-form-item>
            <a-form-item>
              <a-button type="dashed" block @click="newFacebookFlow(5)">
                <mail-outlined style="font-size: 16px"/>
                {{ $t('EMAIL_CONTEXT') }}
              </a-button>
            </a-form-item>
          </div>
        </div>
        <a-divider/>
        <a-form-item>
          <div class="footer-postback-init">
            <div>
            </div>
            <!--            <a-button type="primary" danger @click="deleteNextFlow">-->
            <!--              <delete-outlined/>-->
            <!--              {{ $t('DELETE') }}-->
            <!--            </a-button>-->
            <a-button type="primary" @click="onSubmit">
              <check-outlined/>
              {{ $t('FLOW_COMPLETE') }}
            </a-button>
          </div>
        </a-form-item>
      </a-form>
    </template>
    <a-button type="dashed" size="large" block class="row-button-action">{{ buttonName }}
    </a-button>
  </a-popover>

  <!--<FlowOptionModal ref="flowOptionModal" />-->
</template>

<script lang="ts">
import {CheckOutlined, DeleteOutlined, FacebookOutlined, MailOutlined} from "@ant-design/icons-vue"
import {computed, defineComponent, onMounted, reactive, ref, UnwrapRef, watch} from "vue";
import {FormActionContext, FormFlowInformation} from "@/models/Flows/FlowForm";
import {FlowContext, FlowCRM_Action} from "@/models/Flows/FlowContext";
import {ValidateErrorEntity} from "ant-design-vue/es/form/interface";
import APIs from "@/APIs/APIs";
import {S_EventBus} from "@/utils/EventBus";
import {useI18n} from "vue-i18n";
import {Tag} from "@/models/Tags/Tags";
import {TagsActionTypes} from "@/store/modules/tag/action-types";
import FlowOptionModal from "@/components/Flow/FlowOptionModal.vue";
import {MixUtils} from "@/utils/Utils";
import {resetTracking} from "@vue/reactivity";
import {useStore} from "vuex";
import {PageConfigMutationTypes} from "@/store/modules/PageConfig/mutation-types";
import useTagStore from "../../store/Tags";
import useCustomerStore from "../../store/Customers";

export default defineComponent({
  name: "flow-next-actions",
  components: {
    // FlowOptionModal,
    CheckOutlined,
    DeleteOutlined,
    FacebookOutlined,
    MailOutlined
  },
  props: ['buttonName', 'actionContext', 'updateAction', 'flowContextData', 'flowContext','index'],
  setup(props) {
    const store = useStore()
    const i18n = useI18n()
    const isNextFlow = ref(false)
    const formRef = ref()
    const crmActions = ref()
    const customerTags = computed<Tag[] | undefined>(() => useTagStore.getState().tagSales)
    const selectedCRM_Action = ref()
    const popoverVisible = ref(false)
    const nextFlowContext = ref()
    const tagSale = useTagStore()
    const customerCares = computed(() => useCustomerStore.getState().customerCares)
    const converstionTag = computed(() => useTagStore.getState().pageConverstionTag)
    const pageId = ref('')
    const getCurrentPageId = computed(()=> store.getters.getCurrentPageId)
    const getFlowData = computed(()=> store.getters.getFlowData)
    const currentShop = computed(()=> store.getters.getCurrentShop)
    console.log(currentShop.value)
    crmActions.value = store.getters.getFlowConstants?.flow_crm_action_types
    let model: FormActionContext = {}
    if (props.actionContext) {
      model = {...props.actionContext, ...model}
    }
    if (!model.flow_crm_actions) {
      model.flow_crm_actions = []
    }
    if(model)
      if(model.type === '0'){
        model.usage_id = Number(model.usage_id)
      }
    model.flow_crm_actions = model.flow_crm_actions.map((item: FlowCRM_Action) => {
      if (item.tags  && (item.type === 'add_tag' || item.type === 'remove_tag')) {
        item.tagArr = item.tags ? item.tags.split(",").map((str: string) => parseInt(str)) : []
      }else if( item.tags  && ( item.type === 'add_conversation_tag' || item.type === 'remove_conversation_tag')) {
        // @ts-ignore
        item.coverstion_tag = item.tags.split(",").map((converstionT) => {
        converstionTag.value.map((i:any) => {
          i.tags.map((tag:any) => {
            // @ts-ignore
         if(converstionT === tag.tag_id){
           converstionT = i.page_id + '_' + converstionT

         }

          })

        })
          return converstionT
      })




      }else {
        item.last_care = Number(item.tags)
      }

      return item;
    })
    watch(() =>  props.actionContext, () => {
      if (!formState.flow_crm_actions) {
        formState.flow_crm_actions = []
      }
      formState.flow_crm_actions = formState.flow_crm_actions.map((item: FlowCRM_Action) => {

        if (item.tags  && (item.type === 'add_tag' || item.type === 'remove_tag' )) {
          item.tagArr = item.tags ? item.tags.split(",").map((str: string) => parseInt(str)) : []
        }else if( item.tags  && ( item.type === 'add_conversation_tag' || item.type === 'remove_conversation_tag')) {
          item.coverstion_tag = item.tags.split(",").map((converstionT) => {
            converstionTag.value.map((i:any) => {
              i.tags.map((tag:any) => {
                // @ts-ignore
                if(converstionT === tag.tag_id){
                  converstionT = i.page_id + '_' + converstionT

                }

              })

            })
            return converstionT
          })

        }else {
          item.last_care = Number(item.tags)
        }
        return item;
      })
      if(props.actionContext && props.actionContext.id){
        formState.id = props.actionContext.id
      }
     if( props.actionContext && props.actionContext.type !== "0" ){
       formState.usage_id = props.actionContext.usage_id
     }
     if( props.actionContext && props.actionContext.type === "0"){
       formState.usage_id = Number(props.actionContext.usage_id)
     }
     if( props.actionContext && props.actionContext.flow_action_stack){
       formState.flow_action_stack = props.actionContext.flow_action_stack
     }
      updateFlowContextData()
    })
    watch(() => props.flowContextData, () => {
      updateFlowContextData();
    })
    const formState: UnwrapRef<FormActionContext> = reactive(model);
    const selectCRM_ActionChange = (value: string) => {
      if (value) {
        if (formState.flow_crm_actions) {
          const action = formState.flow_crm_actions.find((item: FlowCRM_Action) => item.type === value)
          if (!action) {
            formState.flow_crm_actions.push({type: value, tagArr: []})
          }
        } else {
          formState.flow_crm_actions = [{type: value, tagArr: []}]
        }
        selectedCRM_Action.value = undefined
      }
    }
    const converstionTags = ref<any[]>([])
const handlePage = (pageId:string) =>{
  store.commit(PageConfigMutationTypes.GET_PAGE_CONFIG,pageId)
      converstionTag.value.map((item:any)=>{
     if(pageId === item.page_id){
       converstionTags.value = item.tags.map((item:any) => item)
     }

   })

}
const idex = ref(0)
    const getFlowNextActions = () => {
      let listSelected: string[] = [];
      props.flowContextData.context.flow_contexts.map((flow: FlowContext) => {
        if (flow.action_default_context && flow.action_default_context.usage_id) {
          listSelected.push(flow.action_default_context.usage_id)
        }
        if (flow.action_send_failed_context && flow.action_send_failed_context.usage_id) {
          listSelected.push(flow.action_send_failed_context.usage_id)
        }
        if (flow.action_send_success_context && flow.action_send_success_context.usage_id) {
          listSelected.push(flow.action_send_success_context.usage_id)
        }
      })
      listSelected = listSelected.filter((x,i) => !x.includes(props.actionContext.usage_id))
      return props.flowContextData.context.flow_contexts.filter((flow: FlowContext, index: number) => {

       if(props.flowContext.id === flow.id ){
         idex.value = index
       }

        return props.flowContext.id !== flow.id && index !== 0 && !listSelected.includes(flow.id + '') && index > idex.value
      }).map((flow: FlowContext) => {
        return {
          label: flow.name,
          value: flow.id
        }
      })
    }

    const changeCurrentFlowContext = () => {
      if (formState.flow_action_stack && formState.flow_action_stack.flow_id) {
        S_EventBus.instance().execChangeCurrentFlow(formState.flow_action_stack.flow_id)
        popoverVisible.value = false
      }
    }
    const deleteCRM_Action = (item: FlowCRM_Action) => {
      formState.flow_crm_actions = formState.flow_crm_actions?.filter((it: FlowCRM_Action) => it.type !== item.type);
      selectedCRM_Action.value = undefined
    }
    const newFacebookFlow = async (type: number) => {
      let name = "1"
      if (props.flowContextData && props.flowContextData && props.flowContextData.context.flow_contexts.length) {
        name = props.flowContextData.context.flow_contexts.length + 1
      }

      var resName = "";
      var resMess = "";
      if (type === 1) {
        resName = "#Facebook# " + i18n.t('MESS_NAME_CONTENT') + name;
        resMess = "#Facebook " + i18n.t('FLOW_MESSAGE_CONTENT') + name;
      } else if (type === 2) {
        resName = "#Zalo# " + i18n.t('MESS_NAME_CONTENT') + name;
        resMess = "#Zalo " + i18n.t('FLOW_MESSAGE_CONTENT') + name;
      }else if (type === 5) {
        resName = "#Email# " + i18n.t('MESS_NAME_CONTENT') + name;
        resMess = "#Email " + i18n.t('FLOW_MESSAGE_CONTENT') + name;
      }

      let flow: FlowContext = {
        "id": MixUtils.methods.getUUID(),
        "name": resName,
        "shop_id": 0,
        "flow_type": type,
        "root_flow_id": 0,
        "message": resMess,
        "attachment_images": [],
        "type": "",
        "messenger_tag": "",
        "template_id": "",
        "template_name": "",
        "template_status": "",
        "preview_url": "",
        "created_at": new Date().toISOString(),
        "updated_at": new Date().toISOString(),
        "postback_contexts": [],
        "action_send_success_context": {
          "flow_crm_actions": [],
          "flow_action_stack": {}
        },
        "action_send_failed_context": {
          "flow_crm_actions": [],
          "flow_action_stack": {}
        },
        "action_default_context": {
          "flow_crm_actions": [],
          "flow_action_stack": {}
        }
      }

      S_EventBus.instance().dispatchCreateFlow(flow)
      onSubmit()
      setTimeout(() => {
        changeCurrentFlowContext()
      }, 400)

    }
    const updateFlowContextData = () => {
      // let context: FlowContext = {}
      // if (formState && formState.flow_action_stack && formState.flow_action_stack.flow_id
      //     && props.flowContextData.reference_flow_contexts && props.flowContextData.reference_flow_contexts.length > 0) {
      //   context = props.flowContextData.reference_flow_contexts.find((flow: FlowContext) => flow.id === formState.flow_action_stack?.flow_id)
      // }
      // nextFlowContext.value = context
    }
    const deleteNextFlowAction = () => {
      if (formState.flow_action_stack) {
        formState.flow_action_stack.flow_id = 0
        onSubmit()
      }
    }
    const searchTags = (value: string) => {
      store.dispatch(TagsActionTypes.FETCH_TAGS, {type: 'customer', search: value})
    }
    const changeFlowAvailable = async (id: string) => {
      if (props.flowContextData.context&&props.flowContextData.context.flow_contexts){
        const flow=props.flowContextData.context.flow_contexts.find((item:FlowContext)=>item.id===id)
        formState.usage_id = flow.id
        formState.type = flow.flow_type + ''
      }


      // let actionNext = {
      //   created_at: new Date().toISOString(),
      //   flow_crm_actions: [],
      //   id: 5513,
      //   type: "action_send_success",
      //   updated_at: new Date().toISOString(),
      //   usage_id: value,
      // }
      // console.log(actionNext)
      // let name = 1
      // if (props.flowContextData && props.flowContextData.reference_flow_contexts && props.flowContextData.reference_flow_contexts.length) {
      //   name = props.flowContextData.reference_flow_contexts.length + 2
      // }
      // var resName = "";
      // var resMess = "";
      //
      // if(props.flowContextData.flow_context.flow_type === 1)
      // {
      //   resName = "#Facebook# " + i18n.t('MESS_NAME_CONTENT') + name;
      //   resMess = "#Facebook " + i18n.t('FLOW_MESSAGE_CONTENT') + name;
      // }else if(props.flowContextData.flow_context.flow_type === 2)
      // {
      //   resName = "#Zalo# " + i18n.t('MESS_NAME_CONTENT') + name;
      //   resMess = "#Zalo " + i18n.t('FLOW_MESSAGE_CONTENT') + name;
      // }
      // const request: FlowContext = {
      //   name: resName,
      //   flow_type: props.flowContextData.flow_context.flow_type,
      //   message: resMess,
      //   root_flow_id: value
      // }
      // const response = await APIs.init().createFlowContext(request);
      // if (response.status === 200 && response.data && response.data.data) {
      //   let flow = response.data.data;
      //   setTimeout(() => {
      //     changeCurrentFlowContext()
      //   }, 400)
      // }
    }
    const changeFlowAvailable2 = async (id: any) => {
      if (props.flowContextData.context && props.flowContextData.context.flow_contexts) {

        formState.usage_id = id+''
        formState.type = '0'
      }
    }
    // const modalFlow = () => {
    //   alert('ok')
    //   this.$refs.flowOptionModal.showModal();
    // }

    const onSubmit = () => {
      formRef.value
          .validate()
          .then(() => {
            const action = {...formState}
            if (!formState.flow_crm_actions) {
              formState.flow_crm_actions = []
            }
            action.flow_crm_actions = formState.flow_crm_actions?.map((item: FlowCRM_Action) => {
              if (item.tagArr) {
                item.tags = item.tagArr.join(",")
              }
              if (item.last_care){
                item.tags = item.last_care + ''
              }
              if( item.coverstion_tag){

                item.tags = item.coverstion_tag.map((item) => {
                 item = item.split("_")[1]
                  return item
                }).join(",")
              }
              if(item.type === 'unread_conversation'){
                item.value = 'true'
              }else {
                item.value = ''
              }
              return item;
            })

            props.updateAction(action,props.index)
            popoverVisible.value = false
          })
          .catch((error: ValidateErrorEntity<FormFlowInformation>) => {
            console.log('error', error);
          });
    };
    onMounted(()=>{
      if(getCurrentPageId.value){
        handlePage(getCurrentPageId.value)
      }
    })
    return {
      isNextFlow,
      formRef,
      crmActions,
      customerTags,
      selectedCRM_Action,
      formState,
      selectCRM_ActionChange,
      deleteCRM_Action,
      onSubmit,
      popoverVisible,
      newFacebookFlow,
      nextFlowContext,
      changeCurrentFlowContext,
      deleteNextFlowAction,
      searchTags,
      changeFlowAvailable,
      changeFlowAvailable2,
      getFlowNextActions,
      tagSale,
      customerCares,
      converstionTag,currentShop,
      pageId,
      handlePage,
      converstionTags,getCurrentPageId,
      getFlowData
    }
  }
})
</script>

<style lang="scss" scoped>
.popover-setup-actions {
  min-width: 20%;
}

.row-button-action {
  margin-top: 20px;
}

.action-crm {
  margin-bottom: 16px;

  .crm-action-title-container {
    display: flex;
    flex-direction: row;
    margin-bottom: 8px;
    justify-content: space-between;
    align-items: center;

    .title-selected-crm-action {
      font-weight: bold;
    }

    .crm-action-deletion {
      cursor: pointer;
    }
  }
}

.footer-postback-init {
  justify-content: space-between;
  display: flex;
}

.right-button-content {
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;

  .center-button-content {
    display: flex;
    flex: 1;
    padding-bottom: 5px;
    margin-left: 10px;
  }
}

.selected-flow-action {
  text-align: left;
  margin-right: 10px;
  flex: 1;
}

</style>
