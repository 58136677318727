import {ActionContext, ActionTree} from "vuex";
import {FlowData, State} from "./states";
import {RootState} from "@/store";
import {FlowActionTypes} from "@/store/modules/viral/action-types";
import APIs from "@/APIs/APIs";
import {FlowsMutationTypes} from "@/store/modules/viral/mutation-types";
import {Mutations} from "@/store/modules/viral/mutations";
import {FlowsResponse} from "@/APIs/response/S_HttpResponse";
import {FlowsRequest} from "@/APIs/request/FlowRequest";

type AugmentedActionContext = {
    commit<K extends keyof Mutations>(
        key: K,
        payload: Parameters<Mutations[K]>[1],
    ): ReturnType<Mutations[K]>;
} & Omit<ActionContext<State, RootState>, 'commit'>

export interface Actions {
    [FlowActionTypes.FETCH_VIRAL](
        {commit}: AugmentedActionContext,
        payload: FlowsRequest
    ): Promise<FlowsResponse | undefined>,
}

export const actions: ActionTree<State, RootState> & Actions = {
    async [FlowActionTypes.FETCH_VIRAL]({commit}, payload: FlowsRequest): Promise<FlowsResponse | undefined> {
        // console.log(payload);
        const response = await APIs.init().getFlows(payload)
        if (response.status === 200) {
            const flowData: FlowData = {
                flows: response.data?.data,
                metadata: response.data?.meta_data
            }
            commit(FlowsMutationTypes.SET_VIRAL, flowData)
        }
        return response.data;
    },
}
