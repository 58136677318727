import {State} from "@/store/modules/Shopinfo/states";
import {ShopinfoMutationTypes} from "@/store/modules/Shopinfo/mutation-types";
import {ShopInfo, UpdateShopInfo} from "@/models/Shopinfo/ShopInfo";
import {Page} from "@/models/Users";

export type Mutations<S = State> = {
    [ShopinfoMutationTypes.GET_SHOPINFO](state : S,payload: ShopInfo|undefined):void,
    [ShopinfoMutationTypes.PUT_SHOPINFO](state: S,payload: UpdateShopInfo|undefined):void
    [ShopinfoMutationTypes.SET_MAIL_PAGES](state: S,payload: Page[]):void

}

export const mutations : Mutations ={
    [ShopinfoMutationTypes.GET_SHOPINFO](state : State,payload: ShopInfo|undefined): void{
        state.data = payload
    },
    [ShopinfoMutationTypes.PUT_SHOPINFO](state : State,payload: UpdateShopInfo|undefined): void{
        state.data = payload
    },
    [ShopinfoMutationTypes.SET_MAIL_PAGES](state : State,payload: Page[]): void{
        state.mailPages = payload
    },

}