import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-6164425f"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "container-title" }
const _hoisted_2 = { class: "title-header" }
const _hoisted_3 = { class: "input-search" }
const _hoisted_4 = { style: {"font-weight":"bold"} }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_typography_text = _resolveComponent("a-typography-text")!
  const _component_a_input_search = _resolveComponent("a-input-search")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_a_button = _resolveComponent("a-button")!
  const _component_EyeOutlined = _resolveComponent("EyeOutlined")!
  const _component_a_divider = _resolveComponent("a-divider")!
  const _component_edit_outlined = _resolveComponent("edit-outlined")!
  const _component_DeleteOutlined = _resolveComponent("DeleteOutlined")!
  const _component_a_popconfirm = _resolveComponent("a-popconfirm")!
  const _component_a_table = _resolveComponent("a-table")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("h3", _hoisted_2, [
        _createTextVNode(_toDisplayString(_ctx.$t(_ctx.$route.name)) + " ", 1),
        _createVNode(_component_a_typography_text, { strong: "" }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString($setup.employeeStore.employees.length + '/' + $setup.shopInfo), 1)
          ]),
          _: 1
        })
      ]),
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_a_input_search, {
          value: $setup.userName,
          "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => (($setup.userName) = $event)),
          placeholder: _ctx.$t('SEARCH'),
          style: {"width":"100%"},
          onChange: $setup.Search
        }, null, 8, ["value", "placeholder", "onChange"])
      ]),
      ($setup.getMyRole($setup.modules.employee)?.is_create)
        ? (_openBlock(), _createBlock(_component_a_button, {
            key: 0,
            type: "primary"
          }, {
            default: _withCtx(() => [
              ($setup.employeeStore.employees.length < $setup.shopInfo)
                ? (_openBlock(), _createBlock(_component_router_link, {
                    key: 0,
                    to: "/employee/add"
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.$t('ADD_EMPLOYEE')), 1)
                    ]),
                    _: 1
                  }))
                : (_openBlock(), _createElementBlock("span", {
                    key: 1,
                    onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => ($setup.showModal && $setup.showModal(...args)))
                  }, _toDisplayString(_ctx.$t('ADD_EMPLOYEE')), 1))
            ]),
            _: 1
          }))
        : _createCommentVNode("", true)
    ]),
    _createVNode(_component_a_table, {
      size: "middle",
      scroll: {  y: '80vh' },
      class: "table",
      columns: $setup.columns,
      "data-source": $setup.employeeStore.employees,
      footer: null,
      pagination: false
    }, {
      name: _withCtx(({ text }) => [
        _createElementVNode("span", _hoisted_4, _toDisplayString(text), 1)
      ]),
      customTitle: _withCtx(() => [
        _createElementVNode("span", null, _toDisplayString(_ctx.$t('FULL_NAME')), 1)
      ]),
      role: _withCtx(({ record }) => [
        _createElementVNode("span", null, _toDisplayString($setup.getRole(record)), 1)
      ]),
      customTitleRole: _withCtx(() => [
        _createElementVNode("span", null, _toDisplayString(_ctx.$t('ROLE')), 1)
      ]),
      action: _withCtx(({ record }) => [
        _createElementVNode("span", null, [
          (!record.is_owner && $setup.getMyRole($setup.modules.employee)?.is_read)
            ? (_openBlock(), _createBlock(_component_router_link, {
                key: 0,
                to: {
                name: 'EMPLOYEEDETAIL',
                params: { userid: record.cms_user_id },
              },
                style: {"color":"black"}
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_EyeOutlined),
                  (record.role_id!==2)
                    ? (_openBlock(), _createBlock(_component_a_divider, {
                        key: 0,
                        type: "vertical"
                      }))
                    : _createCommentVNode("", true)
                ]),
                _: 2
              }, 1032, ["to"]))
            : _createCommentVNode("", true),
          (!record.is_owner && $setup.getMyRole($setup.modules.employee)?.is_edit)
            ? (_openBlock(), _createBlock(_component_router_link, {
                key: 1,
                class: "ant-dropdown-link",
                to: {
                name: 'EMPLOYEEEDIT',
                params: { userid: record.cms_user_id },
              },
                style: {"color":"black"}
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_edit_outlined),
                  (!record.is_owner)
                    ? (_openBlock(), _createBlock(_component_a_divider, {
                        key: 0,
                        type: "vertical"
                      }))
                    : _createCommentVNode("", true)
                ]),
                _: 2
              }, 1032, ["to"]))
            : _createCommentVNode("", true),
          (!record.is_owner && $setup.getMyRole($setup.modules.employee)?.is_delete)
            ? (_openBlock(), _createBlock(_component_a_popconfirm, {
                key: 2,
                title: _ctx.$t('CONFIRM_DELETE')+' ' +_ctx.$t('EMPLOYEE')+'?',
                "ok-text": _ctx.$t('OK'),
                "cancel-text": _ctx.$t('CANCEL'),
                onConfirm: ($event: any) => ($setup.confirm(record))
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_DeleteOutlined)
                ]),
                _: 2
              }, 1032, ["title", "ok-text", "cancel-text", "onConfirm"]))
            : _createCommentVNode("", true)
        ])
      ]),
      _: 1
    }, 8, ["columns", "data-source"])
  ], 64))
}