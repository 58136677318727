<template>
  <div class="container" v-if="version!=='new'">
    <div style="margin:12px;">
      <h3>{{ $t($route.name) }}</h3>
    </div>
    <a-layout theme="light">
      <a-layout-sider theme="light">
        <a-menu
            mode="inline"
            theme="light"
            v-model:selectedKeys="selectedTrigger"
        >
          <a-menu-item key="all" @click="selectTriggerType('all')">
            <span>{{ $t('all') }}</span>
          </a-menu-item>
          <template v-if="getFlowConstants&&getFlowConstants.trigger_types">
            <a-menu-item v-for="key in getFlowConstants.trigger_types" :key="key" @click="selectTriggerType(key)">
              <a-tooltip :title="$t(key)">
                {{ $t(key) }}
              </a-tooltip>
            </a-menu-item>
          </template>

        </a-menu>
      </a-layout-sider>
      <a-layout theme="light">
        <a-layout-content>
          <div class="table-header">
            <!--            <h4>-->
            <!--              {{ selectedTrigger&&selectedTrigger.length>0?$t(selectedTrigger[0]):$t('all') }}-->
            <!--            </h4>-->
            <div class="table-title">

              <a-input-search
                  class="input-search"
                  :placeholder="$t('SEARCH')"
                  v-model:value="searchInput"
                  @change="onSearchChange"
                  style="width: 220px;margin-left: 12px;"
              />
              <a-select
                  showSearch
                  class="input-search"
                  allowClear
                  mode="multiple"
                  :v-mode:value="selectedTags"
                  :placeholder="$t('TAGS')"
                  style="min-width: 220px; margin-left: 12px;"
                  @change="handleChangeTags"
              >
                <a-select-option
                    v-for="item in getTriggerTags"
                    :key="item.id"
                    class="input-search"
                    v-bind:value="item.id"
                >
                  <a-tag :color="item.color">
                    {{ item.name }}
                  </a-tag>
                </a-select-option>
              </a-select>
              <a-select
                  showSearch
                  allowClear
                  class="input-search"
                  :placeholder="$t('status')"
                  style="width: 220px;margin-left: 12px;"
                  @change="handleChangeStatus"
              >
                <a-select-option
                    v-for="item in getFlowConstants.trigger_status"
                    :key="item"
                    v-bind:value="item"
                >
                  <a-typography-text>{{ $t(item) }}</a-typography-text>


                </a-select-option>
              </a-select>
            </div>

            <a-button
                v-if="getMyRole(MODULES.trigger)?.is_create"
                type="primary" @click="newTrigger">
              <plus-outlined/>
              {{ $t("TRIGGER_SETTING") }}
            </a-button>
          </div>
          <a-table
              size="middle"
              :rowKey="record => record.id"
              :columns="columns" :data-source="getTriggers"
              :pagination="pagination"
              @change="handleTableChange"
              class="flow-table">
            <template #name="{ record }">
              <a class="table-row-name" type="text" @click="customRowClick(record)">
                {{ record.name }}
              </a>
            </template>
            <template #created_at="{ text }">
                <span class="item-trigger-table-small">
                   {{ dateTimeDisplayFormatFromString(text) }}

                </span>
            </template>
            <template #creator="{ text }">
                <span>
                  {{ getEmployeeName(text) }}

                </span>
            </template>
            <template #type="{ text }">
               <span class="item-trigger-table-small">
                    {{ $t(text) }}
                </span>
            </template>
            <template #status="{ text }">
               <span>
                    <a-tag :color="getTriggerStatusColor(text)">{{ text }}</a-tag>
                </span>
            </template>
            <template #tags="{ record }">
                <span>
                    <a-tag v-for="tag in record.tags" :key="tag.id+'_'+tag.name"
                           :color="tag.color">{{ tag.name }}</a-tag>
                </span>
            </template>

            <template #action="{record}">
              <a-dropdown>
                <a-button type="text">
                  <eye-outlined/>
                </a-button>
                <template #overlay>
                  <a-menu>
                    <a-menu-item
                        v-if="getMyRole(MODULES.trigger)?.is_edit"
                        @click="customRowClick(record)">
                      <edit-outlined/>
                      {{ $t('EDIT') }}
                    </a-menu-item>
                    <a-menu-item
                        v-if="record.status==='running' && getMyRole(MODULES.trigger)?.is_edit"
                        @click="stopTriggerModal(record)" >
                      <stop-outlined/>
                      {{ $t('STOP_TRIGGER') }}
                    </a-menu-item>
                    <a-menu-item
                        v-if="record.status!=='running' && getMyRole(MODULES.trigger)?.is_edit"
                        @click="activateTriggerModal(record)" >
                      <aim-outlined/>
                      {{ $t('Activate_trigger') }}
                    </a-menu-item>
                    <a-menu-item
                        v-if="getMyRole(MODULES.trigger)?.is_edit"
                        @click="editFlow(record)">
                      <edit-outlined/>
                      {{ $t('EDIT_FLOW') }}
                    </a-menu-item>
                    <a-menu-item
                        v-if="getMyRole(MODULES.trigger_history)?.is_edit"
                        @click="goHistory(record)">
                      <history-outlined/>
                      {{ $t('HISTORY') }}
                    </a-menu-item>
                    <a-menu-item
                        v-if="getMyRole(MODULES.trigger)?.is_delete"
                    >
                      <a-popconfirm
                          :title="$t('TRIGGER_CONFIRM_DELETE_CONTEXT')"
                          :ok-text="$t('YES')"
                          :cancel-text="$t('NO')"
                          @confirm="deleteTrigger(record)"
                      >
                        <delete-outlined/>
                        {{ $t('DELETE') }}
                      </a-popconfirm>

                    </a-menu-item>
                  </a-menu>
                </template>
              </a-dropdown>
            </template>

          </a-table>
        </a-layout-content>
      </a-layout>
    </a-layout>
    <a-modal
        v-model:visible="modalCreateVisible" :title="$t('TRIGGER_SETTING')" width="90%">
      <a-tabs type="card" v-model:activeKey="tabModalEditKey">
        <a-tab-pane key="1" :tab="$t('SETTING')">
          <a-form :model="formState"
                  ref="formRef"
                  :rules="rules" :label-col="{span: 6,offset:0}" :wrapper-col="{ span: 16 }">
            <a-form-item :label="$t('TRIGGER_TITLE')" name="name">
              <a-input v-on:blur="titleBlur" ref="triggerTitle" v-model:value="formState.name" :placeholder="$t('TRIGGER_TITLE')"/>
            </a-form-item>
            <a-form-item name="type" :label="$t('TRIGGER_TYPE')">
             <a-row v-if="formState.type ==='BULK_MESSAGE' " >
               <a-col :span="12">
                 <a-select
                     ref="select"
                     v-model:value="formState.type"
                     :placeholder="$t('TRIGGER_TYPE')"
                 >
                   <a-select-option v-for="item in getFlowConstants.trigger_types" :key="item" :value="item">{{ $t(item) }}
                   </a-select-option>
                 </a-select>
               </a-col>
               <a-col :span="2">

               </a-col>
               <a-col :span="10">
                 <a-button  @click="getAmountSent(formState.id)">
                   {{$t('AMOUNT_SENT')}}
                   <a-tag color="pink" style="cursor: pointer;margin-left: 10px">{{amountSent}}</a-tag>
                 </a-button>

               </a-col>
             </a-row>
              <a-select
                  v-else
                  ref="triggerType"
                  v-model:value="formState.type"
                  @change="handleChangeType"
                  :placeholder="$t('TRIGGER_TYPE')"
              >
                <a-select-option v-for="item in getFlowConstants.trigger_types" :key="item" :value="item">{{ $t(item) }}
                </a-select-option>
              </a-select>
            </a-form-item>
            <a-form-item name="root_flow_id"
                         :label="formState.type==='AUTO_REPLY_COMMENT_AND_SEND_PRIVATE_MESSAGE'?$t('PRIVATE_REPLY_FLOW'):$t('TRIGGER_SELECT_FLOWS')">
              <a-select
                  ref="flows"
                  show-search
                  @search="flowHandleSearch"
                  :options="getFlowsSelectionAntWithoutAll"
                  :default-active-first-option="false"
                  :show-arrow="false"
                  @change="selectFlowChange"
                  :filter-option="false"
                  :not-found-content="null"
                  v-model:value="formState.root_flow_id"
                  :placeholder="$t('TRIGGER_SELECT_FLOWS')"
              />
            </a-form-item>
            <a-row class="horizontal-form">
              <a-col :span="12">
                <a-form-item name="tags" :label="$t('TRIGGER_TAG')">
                  <a-select
                      ref="triggerTag"
                      mode="tags"
                      v-model:value="formState.tags"
                      :placeholder="$t('TRIGGER_TAG')"
                      @change="triggerTag"
                  >
                    <a-select-option v-for="item in getTriggerTags" :key="item.id" :value="item.id">{{ item.name }}
                    </a-select-option>
                  </a-select>
                </a-form-item>
              </a-col>
              <a-col :span="12">
                <a-form-item name="tags" :label="$t('SELECT_WALLET')">
                  <a-select
                      mode="single"
                      ref="wallet"
                      @change="handleSelectWallet"
                      v-model:value="formState.user_wallet_id"
                      :loading="selectWalletLoading"
                      :placeholder="$t('SELECT_WALLET')"
                  >
                    <a-select-option v-for="item in getAdminAndShopOwner" :key="item.id" :value="item.id">{{
                        item.name
                      }}
                    </a-select-option>
                  </a-select>
                </a-form-item>
              </a-col>
            </a-row>
            <a-divider/>
            <a-row>
              <a-col :span="12">
                <a-form-item :name="['customer_condition','condition']" :rules="{
        required: true,
        message: 'Please select one condition type',
        trigger: 'change',
      }" :label="$t('TRIGGER_CONDITIONS')" :label-col="{span: 6,offset:0}"
                             :wrapper-col="{ span: 12 }">
                  <a-radio-group v-on:change="triggerCondition" v-model:value="formState.customer_condition.condition">
                    <a-radio v-for="item in getFlowConstants.trigger_condition.trigger_conditions" :key="item"
                             :value="item">
                      {{ $t(item) }}
                    </a-radio>
                  </a-radio-group>
                </a-form-item>
              </a-col>
              <a-col>
                <a-row style="width: 100%;justify-content: flex-end">

                  <a-tag style="margin: 10px;cursor: pointer" type="dashed" block @click="addParentCondition">
                    <plus-outlined/>
                    {{ $t("TRIGGER_CONDITIONS") }}
                  </a-tag>
                  <a-tag @click="addGroupCondition" style="margin: 10px;cursor: pointer" type="dashed">{{$t('CONDITION_GROUP')}}</a-tag>

                </a-row>
              </a-col>

            </a-row>

            <div
                v-if="formState.customer_condition.trigger_comparisons&&formState.customer_condition.trigger_comparisons.length">
              <a-timeline>
                <a-timeline-item style="padding-bottom: 0" v-for="(item,index) in formState.customer_condition.trigger_comparisons" :key="item.condition+'_'+index" >
                  <a-row v-if="item.condition" class="row-full-width"
                  >
                    <a-col :span="4" style="padding: 4px;">
                      <a-select
                          ref="select"
                          style="width: 100%;"
                          v-model:value="item.field_type"
                          :placeholder="$t('select_condition_field')"
                          @change="handleChangeFieldType"
                      >

                        <a-select-option v-for="it in getFlowConstants.trigger_condition.trigger_condition_field_types"
                                         :key="it" :value="it">{{ $t(it) }}
                        </a-select-option>
                        <a-select-option v-for="i in getCustomerConfig" :key="i.id" :value="i.identified_name">
                          {{i.name}}
                        </a-select-option>
                      </a-select>
                    </a-col>
                    <a-col :span="4" style="padding: 4px;">
                      <a-select
                          ref="select"
                          style="width: 100%;"
                          v-model:value="item.condition"
                          :placeholder="$t('select_comparison_condition')"

                      >
                        <a-select-option v-for="it in getCondition(getFlowConstants.trigger_condition.trigger_comparison_condition,item.field_type,index)"
                                         :key="it+'_2'+index" :value="it">
                          {{ $t(it) }}
                        </a-select-option>
                      </a-select>
                    </a-col>
                    <a-col :span="11" style="padding: 4px;">
                      <a-tree-select v-if="item.field_type==='tag'"
                                     @change="handleChangeTagModal"
                                     show-search
                                     style="width: 100%"
                                     @search="searchTag"
                                     :filterTreeNode="false"
                                     v-model:searchValue="searchTagValue"
                                     v-model:value="item.value"
                                     :notFoundContent="null"
                                     :dropdown-style="{ maxHeight: '400px', overflow: 'auto' }"
                                     allow-clear
                                     :placeholder="$t('TAG_SELECT')"
                      >
                        <a-tree-select-node v-for="item in getTreeTags" :key="item.id" :value="item.id+''">
                          <template #title>
                            <a-tag :color="item.color">
                              {{ item.name }}
                            </a-tag>
                          </template>
                          <a-tree-select-node v-for="child in item.children" :key="child.id" :value="child.id+''">
                            <template #title>
                              <a-tag :color="child.color">
                                {{ child.name }}
                              </a-tag>
                            </template>
                          </a-tree-select-node>

                        </a-tree-select-node>
                      </a-tree-select>
                   <a-input style="width: 100%;" v-if="item.field_type==='INT' || item.field_type === 'CURRENCY'"  v-model:value="item.value"  />
                      <a-input style="width: 100%;" v-if="item.field_type==='STRING' "  v-model:value="item.value"  />
                      <a-range-picker style="width: 100%;" v-if="item.field_type === 'DATE'" format="DD-MM-YYYY" :valueFormat="'YYYY-MM-DDTHH:mm:ssZ'"  v-model:value="item.value"  />
                      <a-select v-if="item.field_type==='page_id'"
                                style="width: 100%;"
                                show-search
                                :filter-option="filterOption2"
                                :placeholder="$t('SELECT_PAGE')"
                                v-model:value="item.value">
                        <a-select-option   v-for="it in   getPages"
                                         :key="it" :value="it.page_id+''">

                          <img
                              v-if="it.page_type === 2"
                              draggable="false"
                              src="@/assets/icons/zalo-seeklogo.com.svg"
                              style="width: 20px;margin-right: 20px"
                          />
                          <img
                              v-else-if="it.page_type === 1"
                              draggable="false"
                              src="@/assets/icons/facebook-svgrepo-com.svg"
                              style="width: 20px;margin-right: 20px"
                          />
                          <img
                              v-else-if="it.page_type === 3"
                              draggable="false"
                              src="@/assets/icons/instagram-svgrepo-com.svg"
                              style="width: 20px;margin-right: 20px"
                          />
                          <img
                              v-else-if="it.page_type === 4"
                              draggable="false"
                              src="@/assets/icons/line-svgrepo-com.svg"
                              style="width: 20px;margin-right: 20px"
                          /> <img
                            v-else-if="it.page_type === 6"
                            draggable="false"
                            src="@/assets/icons/whatsapp-svgrepo-com.svg"
                            style="width: 20px;margin-right: 20px"
                        />{{ it.page_name }}
                        </a-select-option>
                      </a-select>
                      <a-select v-if="item.field_type==='messenger_window_24h'"
                                style="width: 100%;"
                                :placeholder="$t('MESSAGE')"
                                v-model:value="item.value">
                        <a-select-option v-for="it in getFlowConstants.trigger_condition_messenger_window_24_h_field_types"
                                         :key="it" value="it">{{ it }}
                        </a-select-option>
                      </a-select>
<!--                      <a-select -->
<!--                                style="width: 100%;"-->
<!--                                :placeholder="$t('SELECT_TAG')"-->
<!--                                show-search-->
<!--                                :default-active-first-option="false"-->
<!--                                :show-arrow="false"-->
<!--                                :filter-option="false"-->
<!--                                :not-found-content="null"-->
<!--                                v-model:value="item.value">-->
<!--                        <a-select-option v-for="it in conversationTags()"-->
<!--                                         :key="it.tag_id+'_4'+index" :value="it.tag_id+''">{{ it.tag_name }}-->
<!--                        </a-select-option>-->
<!--                      </a-select>-->
                      <a-tree-select
                          v-if="item.field_type==='conversation_tag'"
                          show-search
                          style="width: 100%"
                          v-model:value="item.coverstion_tag"
                          :filterTreeNode="false"
                          @search="searchTags"
                          :notFoundContent="null"
                          :dropdown-style="{ maxHeight: '300', overflow: 'auto' }"
                          allow-clear
                          multiple
                          :placeholder="$t('TAG_SELECT')"
                      >
                        <a-tree-select-node disabled  v-for="converstion in getConverstionTag" :key="converstion.page_id" v-bind:value="converstion.page_id" >
                          <template #title>
                            <a-tag :color="converstion.color">
                              <img
                                  v-if="converstion.page_type === 2"
                                  draggable="false"
                                  src="@/assets/icons/zalo-seeklogo.com.svg"
                                  style="width: 20px;margin-right: 20px"
                              />
                              <img
                                  v-else-if="converstion.page_type === 1"
                                  draggable="false"
                                  src="@/assets/icons/facebook-svgrepo-com.svg"
                                  style="width: 20px;margin-right: 20px"
                              />
                              <img
                                  v-else-if="converstion.page_type === 3"
                                  draggable="false"
                                  src="@/assets/icons/instagram-svgrepo-com.svg"
                                  style="width: 20px;margin-right: 20px"
                              />
                              <img
                                  v-else-if="converstion.page_type === 4"
                                  draggable="false"
                                  src="@/assets/icons/line-svgrepo-com.svg"
                                  style="width: 20px;margin-right: 20px"
                              />
                              <img
                                  v-else-if="converstion.page_type === 6"
                                  draggable="false"
                                  src="@/assets/icons/whatsapp-svgrepo-com.svg"
                                  style="width: 20px;margin-right: 20px"
                              />
                              {{ converstion.page_name }}
                            </a-tag>
                          </template>
                          <a-tree-select-node :disabled="item.coverstion_tag?getConvertionTangId(item.coverstion_tag,converstion.page_id).includes(treeData.tag_id)  ? true :false:false " v-for="treeData in converstion.tags" :key="converstion.page_id + '_'+treeData.tag_id" v-bind:value="converstion.page_id + '_'+treeData.tag_id">
                            <template #title>
                              <a-tag :color="treeData.tag_color">
                                {{ treeData.tag_name }}
                              </a-tag>
                            </template>
                          </a-tree-select-node>

                        </a-tree-select-node>
                      </a-tree-select>

                      <a-select v-if="item.field_type==='post_id'"
                                style="width: 100%;"
                                placeholder="Select post"
                                show-search
                                @change="searchPost"
                                @search="searchPost"
                                :default-active-first-option="false"
                                :show-arrow="false"
                                :filter-option="false"
                                :not-found-content="null"
                                v-model:value="item.value">
                        <a-select-option v-for="post in posts" :key="post.id" :value="post.id">
                          <a-avatar shape="square" :src="getPostPicture(post)">
                          </a-avatar>
                          {{ getPostName(post) }}
                        </a-select-option>
                      </a-select>
                      <a-select v-if="item.field_type==='care_status'"
                                style="width: 100%;"
                                placeholder="Select status"
                                show-search
                                :default-active-first-option="false"
                                :show-arrow="false"
                                :filter-option="false"
                                :not-found-content="null"
                                v-model:value="item.value">
                        <a-select-option v-for="tag in tags" :key="tag.id" :value="tag.id+''">
                          <a-tag :color="tag.color">{{ tag.name }}</a-tag>
                        </a-select-option>
                      </a-select>
                      <a-select v-if="item.field_type==='order_status'"
                                style="width: 100%;"
                                placeholder="Select status"
                                show-search
                                :default-active-first-option="false"
                                :show-arrow="false"
                                :filter-option="false"
                                :not-found-content="null"
                                v-model:value="item.value">
                        <a-select-option v-for="i in orderStatus" :key="i.id" :value="i.id+''">
                          <a-tag :color="getOrderStatusColor(i.id)">{{ $t(i.name) }}</a-tag>
                        </a-select-option>
                      </a-select>
                      <a-input v-if="item.field_type==='order_price'" v-model:value="item.value" />
                      <a-input v-if="item.field_type==='total_order_money'" v-model:value="item.value" />
                      <a-select v-if="item.field_type==='product_category_id'"
                                style="width: 100%;"
                                placeholder="Select status"
                                show-search
                                :default-active-first-option="false"
                                :show-arrow="false"
                                :filter-option="false"
                                :not-found-content="null"
                                v-model:value="item.value">
                        <a-select-option v-for="i in getProductCategories" :key="i.id" :value="i.id+''">
                          {{ $t(i.category_name) }}
                        </a-select-option>
                      </a-select>
                      <a-range-picker v-if="item.field_type==='last_contact_time'" v-model:value="item.value" format="DD-MM-YYYY" :valueFormat="'YYYY-MM-DDTHH:mm:ssZ'" />
                      <a-range-picker v-if="item.field_type==='first_contact_time'" v-model:value="item.value" format="DD-MM-YYYY" :valueFormat="'YYYY-MM-DDTHH:mm:ssZ'"/>
                      <a-select v-if="item.field_type==='last_order_created'"
                                style="width: 100%;"
                                show-search
                                placeholder="Select Timeout"
                                :default-active-first-option="false"
                                :show-arrow="false"
                                :not-found-content="null"
                                :filter-option="filterOption"

                                v-model:value="item.value">
                        <a-select-option
                            v-for="time in timeoutSelections"
                            :key="time"
                            :value="time.value"
                        >{{ time.label }}
                        </a-select-option>
                      </a-select>
                      <a-select v-if="item.field_type==='timeout'"
                                style="width: 100%;"
                                show-search
                                placeholder="Select Timeout"
                                :default-active-first-option="false"
                                :show-arrow="false"
                                :not-found-content="null"
                                :filter-option="filterOption"

                                v-model:value="item.value">
                        <a-select-option
                            v-for="time in timeoutSelections"
                            :key="time"
                            :value="time.value"
                        >{{ time.label }}
                        </a-select-option>
                      </a-select>

<!--                      <a-input v-if="item.field_type==='key_word'"-->
<!--                               v-model:value="item.value"-->
<!--                               placeholder="You key word"-->
<!--                               style="width: 100%;"/>-->
                      <a-select
                          v-if="item.field_type==='key_word'"
                          v-model:value="item.value"
                          mode="tags"
                          style="width: 100%"
                          placeholder="You key word"

                      >
                        <a-select-option v-for="i in options" :key="i" :value="i.value">
                          {{$t(i.value)}}
                        </a-select-option>
                      </a-select>

                    </a-col>

                    <a-col :span="3" style="padding: 4px; text-align: center">
                      <delete-outlined @click="deleteCustomerConditionRow(index)"/>
                    </a-col>
                  </a-row>
                  <a-card v-else style="width: 90%;margin-top: 10px">
                    <a-row >
                      <a-col :span="11">
                        <a-form-item style="margin-bottom:10px"  :name="['customer_condition','condition']" :rules="{
        required: true,
        message: 'Please select one condition type',
        trigger: 'change',
      }"  :label-col="{span: 12,offset:0}"
                                     :wrapper-col="{ span: 12 }">
                          <a-radio-group v-model:value="item.trigger_condition.condition">
                            <a-radio v-for="item in getFlowConstants.trigger_condition.trigger_conditions" :key="item"
                                     :value="item">
                              {{ $t(item) }}
                            </a-radio>
                          </a-radio-group>

                        </a-form-item>
                      </a-col>


                      <a-col :span="11">
                        <a-row style="width: 100%;margin-bottom: 10px;justify-content: flex-end">
                        <a-tag style="margin-right:10px;cursor: pointer" type="dashed" block @click="addChildrenCondition(index)">
                          <plus-outlined/>
                          {{ $t("TRIGGER_CONDITIONS") }}
                        </a-tag>

                        </a-row>
                      </a-col>
                      <a-col :span="1">

                      </a-col>
                      <a-col :span="1">
                        <delete-outlined @click="deleteCustomerConditionRow(index)"/>
                      </a-col>
                    </a-row>



                    <div
                        v-if="formState.customer_condition.trigger_comparisons&&formState.customer_condition.trigger_comparisons.length">
                      <a-timeline style="margin-top: 10px">
                        <a-timeline-item style="padding-bottom: 0" v-for="(i,idex) in formState.customer_condition.trigger_comparisons[index].trigger_condition.trigger_comparisons" :key="i.condition+'_'+idex" >
                          <a-row  class="row-full-width"
                          >
                            <a-col :span="7" style="padding: 4px;">
                              <a-select
                                  ref="select"
                                  style="width: 100%;"
                                  v-model:value="i.field_type"
                                  :placeholder="$t('select_condition_field')"
                              >

                                <a-select-option v-for="it in getFlowConstants.trigger_condition.trigger_condition_field_types"
                                                 :key="it" :value="it">{{ $t(it) }}
                                </a-select-option>
                              </a-select>
                            </a-col>
                            <a-col :span="7" style="padding: 4px;">
                              <a-select
                                  ref="select"
                                  style="width: 100%;"
                                  v-model:value="i.condition"
                                  :placeholder="$t('select_comparison_condition')"
                              >
                                <a-select-option v-for="it in getCondition(getFlowConstants.trigger_condition.trigger_comparison_condition,i.field_type,index,idex)"
                                                 :key="it+'_2'+index" :value="it">{{ $t(it) }}
                                </a-select-option>
                              </a-select>
                            </a-col>
                            <a-col :span="7" style="padding: 4px;">
                              <a-tree-select v-if="i.field_type==='tag'"
                                             @change="handleChangeTagModal"
                                             show-search
                                             style="width: 100%"
                                             @search="searchTag"
                                             :filterTreeNode="false"
                                             v-model:searchValue="searchTagValue"
                                             v-model:value="i.value"
                                             :notFoundContent="null"
                                             :dropdown-style="{ maxHeight: '400px', overflow: 'auto' }"
                                             allow-clear
                                             :placeholder="$t('TAG_SELECT')"
                              >
                                <a-tree-select-node v-for="item in getTreeTags" :key="item.id" :value="item.id+''">
                                  <template #title>
                                    <a-tag :color="item.color">
                                      {{ item.name }}
                                    </a-tag>
                                  </template>
                                  <a-tree-select-node v-for="child in item.children" :key="child.id" :value="child.id+''">
                                    <template #title>
                                      <a-tag :color="child.color">
                                        {{ child.name }}
                                      </a-tag>
                                    </template>
                                  </a-tree-select-node>

                                </a-tree-select-node>
                              </a-tree-select>
                              <a-select v-if="i.field_type==='page_id'"
                                        style="width: 100%;"
                                        show-search
                                        :filter-option="filterOption2"
                                        :placeholder="$t('SELECT_PAGE')"
                                        v-model:value="i.value">
                                <a-select-option v-for="it in   getPages"
                                                 :key="it" :value="it.page_id+''">

                                  <img
                                      v-if="it.page_type === 2"
                                      draggable="false"
                                      src="@/assets/icons/zalo-seeklogo.com.svg"
                                      style="width: 20px;margin-right: 20px"
                                  />
                                  <img
                                      v-else-if="it.page_type === 1"
                                      draggable="false"
                                      src="@/assets/icons/facebook-svgrepo-com.svg"
                                      style="width: 20px;margin-right: 20px"
                                  />
                                  <img
                                      v-else-if="it.page_type === 3"
                                      draggable="false"
                                      src="@/assets/icons/instagram-svgrepo-com.svg"
                                      style="width: 20px;margin-right: 20px"
                                  />
                                  <img
                                      v-else-if="it.page_type === 4"
                                      draggable="false"
                                      src="@/assets/icons/line-svgrepo-com.svg"
                                      style="width: 20px;margin-right: 20px"
                                  /> <img
                                    v-else-if="it.page_type === 6"
                                    draggable="false"
                                    src="@/assets/icons/whatsapp-svgrepo-com.svg"
                                    style="width: 20px;margin-right: 20px"
                                />{{ it.page_name }}
                                </a-select-option>
                              </a-select>
                              <a-select v-if="i.field_type==='messenger_window_24h'"
                                        style="width: 100%;"
                                        :placeholder="$t('MESSAGE')"
                                        v-model:value="i.value">
                                <a-select-option v-for="it in getFlowConstants.trigger_condition_messenger_window_24_h_field_types"
                                                 :key="it" value="it">{{ it }}
                                </a-select-option>
                              </a-select>
<!--                              <a-select v-if="i.field_type==='conversation_tag'"-->
<!--                                        style="width: 100%;"-->
<!--                                        :placeholder="$t('SELECT_TAG')"-->
<!--                                        show-search-->
<!--                                        :default-active-first-option="false"-->
<!--                                        :show-arrow="false"-->
<!--                                        :filter-option="false"-->
<!--                                        :not-found-content="null"-->
<!--                                        v-model:value="i.value">-->
<!--                                <a-select-option v-for="it in conversationTagsGroup(index,idex)"-->
<!--                                                 :key="it.tag_id+'_4'+index" :value="it.tag_id+''">{{ it.tag_name }}-->
<!--                                </a-select-option>-->
<!--                              </a-select>-->
                              <a-tree-select
                                  v-if="i.field_type==='conversation_tag'"
                                  show-search
                                  style="width: 100%"
                                  v-model:value="i.coverstion_tag"
                                  :filterTreeNode="false"
                                  @search="searchTags"
                                  :notFoundContent="null"
                                  :dropdown-style="{ maxHeight: '300', overflow: 'auto' }"
                                  allow-clear
                                  multiple
                                  :placeholder="$t('TAG_SELECT')"
                              >
                                <a-tree-select-node disabled  v-for="converstion in getConverstionTag" :key="converstion.page_id" v-bind:value="converstion.page_id" >
                                  <template #title>
                                    <a-tag :color="converstion.color">
                                      <img
                                          v-if="converstion.page_type === 2"
                                          draggable="false"
                                          src="@/assets/icons/zalo-seeklogo.com.svg"
                                          style="width: 20px;margin-right: 20px"
                                      />
                                      <img
                                          v-else-if="converstion.page_type === 1"
                                          draggable="false"
                                          src="@/assets/icons/facebook-svgrepo-com.svg"
                                          style="width: 20px;margin-right: 20px"
                                      />
                                      <img
                                          v-else-if="converstion.page_type === 3"
                                          draggable="false"
                                          src="@/assets/icons/instagram-svgrepo-com.svg"
                                          style="width: 20px;margin-right: 20px"
                                      />
                                      <img
                                          v-else-if="converstion.page_type === 4"
                                          draggable="false"
                                          src="@/assets/icons/line-svgrepo-com.svg"
                                          style="width: 20px;margin-right: 20px"
                                      />
                                      <img
                                          v-else-if="converstion.page_type === 6"
                                          draggable="false"
                                          src="@/assets/icons/whatsapp-svgrepo-com.svg"
                                          style="width: 20px;margin-right: 20px"
                                      />
                                      {{ converstion.page_name }}
                                    </a-tag>
                                  </template>
                                  <a-tree-select-node :disabled="i.coverstion_tag?getConvertionTangId(i.coverstion_tag,converstion.page_id).includes(treeData.tag_id)  ? true :false:false " v-for="treeData in converstion.tags" :key="converstion.page_id + '_'+treeData.tag_id" v-bind:value="converstion.page_id + '_'+treeData.tag_id">
                                    <template #title>
                                      <a-tag :color="treeData.tag_color">
                                        {{ treeData.tag_name }}
                                      </a-tag>
                                    </template>
                                  </a-tree-select-node>

                                </a-tree-select-node>
                              </a-tree-select>

                              <a-select v-if="i.field_type==='post_id'"
                                        style="width: 100%;"
                                        placeholder="Select post"
                                        show-search
                                        @change="searchPost"
                                        @search="searchPost"
                                        :default-active-first-option="false"
                                        :show-arrow="false"
                                        :filter-option="false"
                                        :not-found-content="null"
                                        v-model:value="i.value">
                                <a-select-option v-for="post in posts" :key="post.id" :value="post.id">
                                  <a-avatar shape="square" :src="getPostPicture(post)">
                                  </a-avatar>
                                  {{ getPostName(post) }}
                                </a-select-option>
                              </a-select>
                              <a-select v-if="i.field_type==='care_status'"
                                        style="width: 100%;"
                                        placeholder="Select status"
                                        show-search
                                        :default-active-first-option="false"
                                        :show-arrow="false"
                                        :filter-option="false"
                                        :not-found-content="null"
                                        v-model:value="i.value">
                                <a-select-option v-for="tag in tags" :key="tag.id" :value="tag.id+''">
                                  <a-tag :color="tag.color">{{ tag.name }}</a-tag>
                                </a-select-option>
                              </a-select>
                              <a-select v-if="i.field_type==='order_status'"
                                        style="width: 100%;"
                                        placeholder="Select status"
                                        show-search
                                        :default-active-first-option="false"
                                        :show-arrow="false"
                                        :filter-option="false"
                                        :not-found-content="null"
                                        v-model:value="i.value">
                                <a-select-option v-for="i in orderStatus" :key="i.id" :value="i.id+''">
                                  <a-tag :color="getOrderStatusColor(i.id)">{{ $t(i.name) }}</a-tag>
                                </a-select-option>
                              </a-select>
                              <a-range-picker v-if="i.field_type==='last_contact_time'" v-model:value="i.value" format="DD-MM-YYYY" :valueFormat="'YYYY-MM-DDTHH:mm:ssZ'" />
                              <a-range-picker v-if="i.field_type==='first_contact_time'" v-model:value="i.value" format="DD-MM-YYYY" :valueFormat="'YYYY-MM-DDTHH:mm:ssZ'"/>
                              <a-select v-if="i.field_type==='timeout'"
                                        style="width: 100%;"
                                        show-search
                                        placeholder="Select Timeout"
                                        :default-active-first-option="false"
                                        :show-arrow="false"
                                        :not-found-content="null"
                                        :filter-option="filterOption"

                                        v-model:value="i.value">
                                <a-select-option
                                    v-for="time in timeoutSelections"
                                    :key="time"
                                    :value="time.value"
                                >{{ time.label }}
                                </a-select-option>
                              </a-select>

                              <a-select
                                  v-if="i.field_type==='key_word'"
                                  v-model:value="i.value"
                                  mode="tags"
                                  style="width: 100%"
                                  placeholder="You key word"

                              >
                                <a-select-option v-for="i in options2" :key="i" :value="i.value">
                                  {{$t(i.value)}}
                                </a-select-option>
                              </a-select>

                            </a-col>

                            <a-col :span="3" style="padding: 4px; text-align: center">
                              <delete-outlined @click="deleteCustomerConditionChidrenRow(idex,index)"/>
                            </a-col>
                          </a-row>
                        </a-timeline-item>
                      </a-timeline>
                    </div>
                  </a-card>
                </a-timeline-item>
              </a-timeline>
            </div>

            <span v-if="!rules.trigger_condition.isValid" class="condition-alert">{{
                rules.trigger_condition.message
              }}</span>

            <div class="row-horizontal" v-if="formState.type==='BULK_MESSAGE'">
              <a-form-item :label-col="{span: 0,offset:0}" :colon="false"
                           name="frequency_type"
                           class="column-fix-1"
                           :rules="{
        required: true,
        message: $t('TRIGGER_VALIDATE_FREQUENCY_TYPE'),
        trigger: 'change',
      }"
                           :wrapper-col="{ span: 24 }">
                <a-radio-group v-model:value="formState.frequency_type" button-style="solid">
                  <a-radio-button v-for="item in getFlowConstants.trigger_frequency_types" :key="item" :value="item">
                    {{ $t(item) }}
                  </a-radio-button>
                </a-radio-group>
              </a-form-item>

              <a-form-item v-if="formState.frequency_type !== 'now'" :label-col="{span: 0,offset:0}" :colon="false"
                           name="time_start_m"
                           :rules="{

        required: true,
        message: $t('TRIGGER_VALIDATE_START_TIME'),
        trigger: 'change',
      }"
                           :wrapper-col="{ span: 24 }">
                <a-date-picker  v-model:value="formState.time_start_m"   show-time placeholder="Select Date Time"
                               :format="'DD-MM-YYYY HH:mm'" />
              </a-form-item>

            </div>
            <!--        <a-form-item has-feedback :label="$t('TAG_SELECT')" name="tags">-->
            <!--          <a-select style="text-align: left" v-model:value="tagForm.saleTag" @change="tagSelectionChange" placeholder="tag1, tag2, ...">-->
            <!--            <a-select-option v-for="item in saleTags" :value="item.tag_id" :key="item.tag_id" :color="item.tag_color">-->
            <!--              <a-tag :color="item.tag_color">{{ item.tag_name }}</a-tag>-->
            <!--            </a-select-option>-->
            <!--          </a-select>-->
            <!--        </a-form-item>-->
          </a-form>
        </a-tab-pane>
        <a-tab-pane key="2" :tab="$t('CONFIGURATION')">
          <a-form :model="formConfig">
            <a-form-item name="type" :label="$t('CONFIG_SEND_MAIL')" v-if="formConfig.hasMail">
              <a-select
                  ref="select"
                  v-model:value="formConfig.selectedPage"
                  :placeholder="$t('SELECT_MAIL')"
              >
                <a-select-option v-for="item in getMailPage" :key="item.id" :value="item.id">{{ $t(item.page_name) }}
                </a-select-option>
              </a-select>
            </a-form-item>
<!--            <a-form-item>-->
<!--              <a-button>-->
<!--                <template #icon> <user-outlined /></template>-->
<!--                {{$t('ADD_INFO')}}-->
<!--              </a-button>-->

<!--            </a-form-item>-->
            <a-form-item name="type" :label="$t('URL_QR_CODE')" v-if="formConfig.viralUrl">
              <a-input
                  ref="select"
                  readonly
                  v-model:value="formConfig.viralUrl"
              >
              </a-input>
              <a-avatar :src="formConfig.viralQRURL" shape="square" style="width: 200px;height: 200px"/>
            </a-form-item>
            <a-form-item name="type" :label="$t('SMARTCALL_TRIGGER_ID')" v-if="formConfig.smartcall">
              <a-input
                  v-model:value="formConfig.smartcall_trigger_id"
              >
              </a-input>
            </a-form-item>
            <a-form-item name="type" :label="$t('TIMEOUT_EACH_MESSAGE')" >

                <a-select
                          style="width: 100%;"
                          show-search
                          placeholder="Select Timeout"
                          :default-active-first-option="false"
                          :show-arrow="false"
                          :not-found-content="null"
                          :filter-option="filterOption"

                          v-model:value="formConfig.timeout_each_message">
                  <a-select-option
                      v-for="time in timeoutSelections"
                      :key="time"
                      :value="time.value"
                  >{{ time.label }}
                  </a-select-option>
                </a-select>
            </a-form-item>
            <a-form-item name="type" :label="$t('smart_call_trigger_scenario_id')" v-if="formConfig.smartcall">
              <a-input
                  v-model:value="formConfig.smart_call_trigger_scenario_id"
              >
              </a-input>
            </a-form-item>
            <a-form-item name="type" :label="$t('SELECT_PAGE')" v-if="formConfig.smartcall">
              <a-select
                  ref="select"
                  :placeholder="$t('SELECT_MAIL')"
                  v-model:value="formConfig.pages_id"
                  mode="multiple"
                  @change="handleChangePage"
              >
                <a-select-option v-for="item in getPages" :key="item.id" :value="item.id">
                  <img
                      v-if="item.page_type === 2"
                      draggable="false"
                      src="@/assets/icons/zalo-seeklogo.com.svg"
                      style="width: 20px;margin-right: 20px"
                  />
                  <img
                      v-else-if="item.page_type === 1"
                      draggable="false"
                      src="@/assets/icons/facebook-svgrepo-com.svg"
                      style="width: 20px;margin-right: 20px"
                  />
                  <img
                      v-else-if="item.page_type === 3"
                      draggable="false"
                      src="@/assets/icons/instagram-svgrepo-com.svg"
                      style="width: 20px;margin-right: 20px"
                  />
                  <img
                      v-else-if="item.page_type === 4"
                      draggable="false"
                      src="@/assets/icons/line-svgrepo-com.svg"
                      style="width: 20px;margin-right: 20px"
                  /> <img
                    v-else-if="item.page_type === 6"
                    draggable="false"
                    src="@/assets/icons/whatsapp-svgrepo-com.svg"
                    style="width: 20px;margin-right: 20px"
                />
                  {{ $t(item.page_name) }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-form>
          <a-typography-text v-if="!formConfig.smartcall" style="cursor: pointer" @click="readMore = !readMore" strong>{{$t('READ_MORE')}}</a-typography-text>
          <a-form  v-if="readMore" :model="formConfig2">
            <a-form-item name="type" :label="$t('CONFIG_SEND_MAIL')" v-if="formConfig.hasMail">
              <a-select
                  ref="select"
                  v-model:value="formConfig2.selectedPage"
                  :placeholder="$t('SELECT_MAIL')"
              >
                <a-select-option v-for="item in getMailPage" :key="item.id" :value="item.id">{{ $t(item.page_name) }}
                </a-select-option>
              </a-select>
            </a-form-item>
            <!--            <a-form-item>-->
            <!--              <a-button>-->
            <!--                <template #icon> <user-outlined /></template>-->
            <!--                {{$t('ADD_INFO')}}-->
            <!--              </a-button>-->

            <!--            </a-form-item>-->
            <a-form-item name="type" :label="$t('URL_QR_CODE')" v-if="formConfig.viralUrl">
              <a-input
                  ref="select"
                  readonly
                  v-model:value="formConfig2.viralUrl"
              >
              </a-input>
              <a-avatar :src="formConfig2.viralQRURL" shape="square" style="width: 200px;height: 200px"/>
            </a-form-item>
<!--            <a-form-item name="type" :label="$t('SMARTCALL_TRIGGER_ID')" v-if="formConfig.smartcall">-->
<!--              <a-input-->
<!--                  v-model:value="formConfig2.smartcall_trigger_id"-->
<!--              >-->
<!--              </a-input>-->
<!--            </a-form-item>-->
          </a-form>
        </a-tab-pane>
      </a-tabs>
      <template #footer>
        <span class="modal-text-warning-running-status" v-if="formState.status==='running'">
          {{ $t('TRIGGER_WARNING_TRIGGER_IS_RUNNING') }}
        </span>
        <a-button @click="hideModal">Cancel</a-button>
        <a-button type="primary" @click="onSubmit" :loading="confirmTriggerLoading"
                  :disabled="formState.status==='running'">
          Ok
        </a-button>
      </template>
    </a-modal>
  </div>
</template>

<script lang="ts">
import {Options, Vue} from "vue-class-component";
import {
  DeleteOutlined,
  DownOutlined,
  EditOutlined,
  PlusOutlined,
  SmileOutlined,
  TagOutlined,
  PlusCircleFilled,
  CheckOutlined,
  SendOutlined,
  AimOutlined, ExclamationCircleOutlined,
  StopOutlined,
  HistoryOutlined,
  EyeOutlined,
    UserOutlined
} from "@ant-design/icons-vue"
import RouterEndpoint from "@/router/RouterEndpoint";
import {mapActions, mapGetters} from "vuex";
import {Employee, Page} from "@/models/Users";
import {TriggerActionTypes} from "@/store/modules/trigger/action-types";
import {TriggerRequest} from "@/APIs/request/TriggerRequest";
import {Tag} from "@/models/Tags/Tags";
import {TriggerForm} from "@/models/Trigger/TriggerForm";
import {Trigger, TriggerCustomerComparison} from "@/models/Trigger/Trigger";
import {FlowActionTypes} from "@/store/modules/flow/action-types";
import {TagsActionTypes} from "@/store/modules/tag/action-types";
import {ValidateErrorEntity} from "ant-design-vue/es/form/interface";
import moment from "moment";
import APIs from "@/APIs/APIs";
import {message, Modal} from "ant-design-vue";
import {computed, createVNode} from "vue";
import {FlowType} from "@/models/Flows/Flow";
import {Wallet} from "@/models/Wallet/Wallet";
import {UserActionTypes} from "@/store/modules/user/action-types";
import {FacebookPostData} from "@/models/facebook/FacebookPost";
import {CustomerCaresActionTypes} from "@/store/modules/customerCare/action-types";
import {FlowContext, FlowContexts} from "@/models/Flows/FlowContext";
import {ShopinfoActionTypes} from "@/store/modules/Shopinfo/action-types";
import {PaginationProps} from "ant-design-vue/es/pagination";
import dayjs from "dayjs";
import {MixUtils} from "@/utils/Utils";
import QRCode from "qrcode";
import {TreeTagsResponse} from "@/APIs/response/S_HttpResponse";
import {PageConfigActionTypes} from "@/store/modules/PageConfig/action-types";
import {OverviewActionTypes} from "@/store/modules/OverView/action-types";
import {Constant} from "@/utils/Constants";
import useTagStore from "../../../store/Tags";
import useUserStore from "../../../store/User";
let searchTreeTimeout: number;
const rules = {
  name: [
    {required: true, message: 'Please input display name', trigger: 'blur'},
  ],
  root_flow_id: [
    {
      type: 'number',
      required: true,
      message: 'Please select at least one flow',
      trigger: 'change',
    },
  ],
  type: [
    {
      type: 'string',
      required: true,
      message: 'Please select at least one type',
      trigger: 'change',
    },
  ],
  trigger_condition: {
    message: 'Please add at least one condition',
    isValid: true
  }
};


let inputChangeTimeout: number;
let flowHandleSearchTimeout: number;

const triggerRequest: TriggerRequest = {}
let formState: TriggerForm = {
  customer_condition: {
    condition: '',
    trigger_comparisons: [

    ]
  }
}


const dateTimeFormat = 'DD-MM-YYYY HH:mm';

@Options({
  name: 'triggers',
  components: {
    TagOutlined,
    PlusOutlined,
    SmileOutlined,
    DownOutlined,
    DeleteOutlined,
    EditOutlined,
    PlusCircleFilled,
    CheckOutlined,
    SendOutlined,
    EyeOutlined,
    AimOutlined,
    StopOutlined,
    ExclamationCircleOutlined,
    HistoryOutlined,
    UserOutlined
  },
  computed: {
    columns() {
      return [
        {
          title: this.$t('TRIGGER_TITLE'),
          dataIndex: 'name',
          key: 'name',
          slots: {customRender: 'name'},
        },
        {
          title: this.$t('Type'),
          dataIndex: 'type',
          key: 'type',
          slots: {customRender: 'type'},
        },
        {
          title: this.$t('TRIGGER_STATUS'),
          key: 'status',
          dataIndex: 'status',
          slots: {customRender: 'status'},
        },
        {
          title: this.$t('TRIGGER_CREATOR'),
          dataIndex: 'creator',
          key: 'creator',
          slots: {customRender: 'creator'},
        },
        {
          title: this.$t('TRIGGER_TAG'),
          key: 'tags',
          dataIndex: 'tags',
          slots: {customRender: 'tags'},
        },
        {
          title: this.$t('TRIGGER_DATE_CREATED'),
          dataIndex: 'created_at',
          key: 'created_at',
          slots: {customRender: 'created_at'},
        },

        {
          title: '',
          key: 'action',
          slots: {customRender: 'action'},
          width: 100
        },
      ]
    },
    ...mapGetters({
      getTriggers: 'getTriggers',
      getCurrentShop: 'getCurrentShop',
      // getTriggerTags: 'getTriggerTags',
      getCustomerCares: 'getCustomerCares',
      // getAdminAndShopOwner: 'getAdminAndShopOwner',
      getFlowConstants: 'getFlowConstants',
      getFlowData: 'getFlowData',
      getCustomerTags: 'getCustomerTags',
      getPages: 'getPages',
      getWallet: 'getWallet',
      getFlows: 'getFlows',
      getPostsSelection: 'getPostsSelection',
      getTriggerMetadata: 'getTriggerMetadata',
      getFlowsSelectionAntWithoutAll: 'getFlowsSelectionAntWithoutAll',
      getMailPage: 'getMailPage',
      getTreeTags: "getTreeTags",
      getCustomerConfig:"getCustomerConfig",
      userInfo: "getUserInfo",
      getOverview:"getOverview",
      getRole:"getRole",
      // getConverstionTag:"getConverstionTag",
      getProductCategories:"getProductCategories"
    }),
    rules() {
      let rules = {
        name: [
          {required: true, message: this.$t('VALIDATE_DISPLAY_NAME'), trigger: 'blur'},
        ],
        root_flow_id: [
          {
            type: 'string',
            required: true,
            message: this.$t('TRIGGER_VALIDATE_FLOW'),
            trigger: 'blur',
          },
        ],
        type: [
          {
            type: 'string',
            required: true,
            message: this.$t('VALIDATE_TYPE'),
            trigger: 'change',
          },
        ],
        trigger_condition: {
          message: this.$t('TRIGGER_VALIDATE_CONDITION'),
          isValid: true
        },

      }

      return rules;
    },
    getAdminAndShopOwner(){
      return useUserStore.getState().getAdminAndShopOwner()
    },
    getConverstionTag(){
      return useTagStore.getState().pageConverstionTag
    },
    getTriggerTags() {
      return useTagStore.getState().tagTriggers
    },
    pagination() {
      console.log("pagination", this.getTriggerMetadata)
      return {
        current: this.triggerRequest.page ? this.triggerRequest.page : 1,
        pageSize: this.triggerRequest.page_size ? this.triggerRequest.page_size : 20,
        defaultPageSize: 20,
        total: this.getTriggerMetadata && this.getTriggerMetadata.record_total ? this.getTriggerMetadata.record_total : 0,
        pageSizeOptions: ['20', '40', '80', '120'],
        showSizeChanger: true
      }
    }
  },
  data() {
    return {
      amountSent:undefined,
      RouterEndpoint: RouterEndpoint,
      searchInput: '',
      selectedTags: [],
      selectedType: '',
      searchPages:[],
      selectedstatus: '',
      readMore: false,
      triggerRequest,
      modalCreateVisible: false,
      formState,
      confirmTriggerLoading: false,
      dateFormat: 'DD-MM-YYYY HH:mm',
      currentPage: {},
      MODULES: Constant.MODULES,
      posts: [],
      tags: [],
      selectTags:[],
      conditions: [],
      trigger_id:0,
      status:'',
      orderStatus:[{
        id:-1,
        name: 'STATUS_ORDER_CANCEL'
      }, {
        id: 0,
        name: 'STATUS_ORDER_NEW'
      }, {
        id: 1,
        name: 'STATUS_ORDER_CONFIRM'
      }, {
        id: 2,
        name: 'STATUS_ORDER_WAITING_SHIP'
      }, {
        id: 3,
        name: 'STATUS_ORDER_PICKED_SHIP'
      }, {
        id: 4,
        name: 'ORDER_SUCCESS'
      }, {
        id: 5,
        name: 'STATUS_ORDER_RETURNED'
      }, {
        id: 6,
        name: 'STATUS_ORDER_RETURNING'
      }, {
        id: 7,
        name: 'STATUS_ORDER_SHIPPING'
      }, {
        id: 8,
        name: 'STATUS_ORDER_RETURN_OFFER'
      },

      ],
      timeoutSelections: [
        {
          label: '10 ' + this.$t('second'),
          value: "10"  //second
        },
        {
          label: '20 ' + this.$t('second'),
          value: "20"  //second
        },
        {
          label: '1 ' + this.$t('minutes'),
          value: "60"  //second
        },
        {
          label: '2 ' + this.$t('minutes'),
          value: "120"  //second
        },
        {
          label: '3 ' + this.$t('minutes'),
          value: "180"  //second
        },
        {
          label: '4 ' + this.$t('minutes'),
          value: "240"  //second
        },
        {
          label: '5 ' + this.$t('minutes'),
          value: "300"  //second
        },
        {
          label: '6 ' + this.$t('minutes'),
          value: "360"  //second
        },
        {
          label: '7 ' + this.$t('minutes'),
          value: "420"  //second
        },
        {
          label: '8 ' + this.$t('minutes'),
          value: "480"  //second
        },
        {
          label: '9 ' + this.$t('minutes'),
          value: "640"  //second
        },

        {
          label: '10 ' + this.$t('minutes'),
          value: "600"  //second
        },
        {
          label: '11 ' + this.$t('minutes'),
          value: "660"  //second
        },
        {
          label: '12 ' + this.$t('minutes'),
          value: "720"  //second
        },
        {
          label: '13 ' + this.$t('minutes'),
          value: "780"  //second
        },
        {
          label: '14 ' + this.$t('minutes'),
          value: "840"  //second
        },
        {
          label: '15 ' + this.$t('minutes'),
          value: "900"  //second
        },
        {
          label: '16 ' + this.$t('minutes'),
          value: "960"  //second
        },
        {
          label: '17 ' + this.$t('minutes'),
          value: "1020"  //second
        },
        {
          label: '18 ' + this.$t('minutes'),
          value: "1080"  //second
        },
        {
          label: '19 ' + this.$t('minutes'),
          value: "1140"  //second
        },
        {
          label: '20 ' + this.$t('minutes'),
          value: "1200"  //second
        },
        {
          label: '21 ' + this.$t('minutes'),
          value: "1260"  //second
        },
        {
          label: '22 ' + this.$t('minutes'),
          value: "1320"  //second
        },
        {
          label: '23 ' + this.$t('minutes'),
          value: "1380"  //second
        },
        {
          label: '24 ' + this.$t('minutes'),
          value: "1440"  //second
        },
        {
          label: '25 ' + this.$t('minutes'),
          value: "1500"  //second
        },
        {
          label: '26 ' + this.$t('minutes'),
          value: "1560"  //second
        },
        {
          label: '27 ' + this.$t('minutes'),
          value: "1620"  //second
        },
        {
          label: '28 ' + this.$t('minutes'),
          value: "1680"  //second
        },
        {
          label: '29 ' + this.$t('minutes'),
          value: "1740"  //second
        },
        {
          label: '30 ' + this.$t('minutes'),
          value: "1800"  //second
        },
        {
          label: '31 ' + this.$t('minutes'),
          value: "1860"  //second
        },
        {
          label: '32 ' + this.$t('minutes'),
          value: "1920"  //second
        },
        {
          label: '33 ' + this.$t('minutes'),
          value: "1980"  //second
        },
        {
          label: '34 ' + this.$t('minutes'),
          value: "2040"  //second
        },
        {
          label: '35 ' + this.$t('minutes'),
          value: "2100"  //second
        },
        {
          label: '36 ' + this.$t('minutes'),
          value: "2160"  //second
        },
        {
          label: '37 ' + this.$t('minutes'),
          value: "2220"  //second
        },
        {
          label: '38 ' + this.$t('minutes'),
          value: "2280"  //second
        },
        {
          label: '39 ' + this.$t('minutes'),
          value: "2340"  //second
        },
        {
          label: '40 ' + this.$t('minutes'),
          value: "2400"  //second
        },
        {
          label: '41 ' + this.$t('minutes'),
          value: "2460"  //second
        },
        {
          label: '42 ' + this.$t('minutes'),
          value: "2520"  //second
        },
        {
          label: '43 ' + this.$t('minutes'),
          value: "2580"  //second
        },
        {
          label: '44 ' + this.$t('minutes'),
          value: "2640"  //second
        },
        {
          label: '45 ' + this.$t('minutes'),
          value: "2700"  //second
        },
        {
          label: '46 ' + this.$t('minutes'),
          value: "2760"  //second
        },
        {
          label: '47 ' + this.$t('minutes'),
          value: "2820"  //second
        },
        {
          label: '48 ' + this.$t('minutes'),
          value: "2880"  //second
        },
        {
          label: '49 ' + this.$t('minutes'),
          value: "2940"  //second
        },
        {
          label: '50 ' + this.$t('minutes'),
          value: "3000"  //second
        },
        {
          label: '51 ' + this.$t('minutes'),
          value: "3060"  //second
        },
        {
          label: '52 ' + this.$t('minutes'),
          value: "3120"  //second
        },
        {
          label: '53 ' + this.$t('minutes'),
          value: "3180"  //second
        },
        {
          label: '54 ' + this.$t('minutes'),
          value: "3240"  //second
        },
        {
          label: '55 ' + this.$t('minutes'),
          value: "3300"  //second
        },
        {
          label: '56 ' + this.$t('minutes'),
          value: "3360"  //second
        },
        {
          label: '57 ' + this.$t('minutes'),
          value: "3420"  //second
        },
        {
          label: '58 ' + this.$t('minutes'),
          value: "3480"  //second
        },
        {
          label: '59 ' + this.$t('minutes'),
          value: "3540"  //second
        },
        {
          label: '1 ' + this.$t('HOURS'),
          value: "3600"  //second
        },
        {
          label: '2 ' + this.$t('HOURS'),
          value: "7200"  //second
        },
        {
          label: '3 ' + this.$t('HOURS'),
          value: "10800"  //second
        },
        {
          label: '4 ' + this.$t('HOURS'),
          value: "14400"  //second
        },
        {
          label: '5 ' + this.$t('HOURS'),
          value: "18000"  //second
        },
        {
          label: '6 ' + this.$t('HOURS'),
          value: "21600"  //second
        },
        {
          label: '7 ' + this.$t('HOURS'),
          value: "25200"  //second
        },
        {
          label: '8 ' + this.$t('HOURS'),
          value: "28800"  //second
        },
        {
          label: '9 ' + this.$t('HOURS'),
          value: "32400"  //second
        },
        {
          label: '10 ' + this.$t('HOURS'),
          value: "36000"  //second
        },
        {
          label: '11 ' + this.$t('HOURS'),
          value: "39600"  //second
        },
        {
          label: '12 ' + this.$t('HOURS'),
          value: "43200"  //second
        },
        {
          label: '13 ' + this.$t('HOURS'),
          value: "46800"  //second
        },
        {
          label: '14 ' + this.$t('HOURS'),
          value: "50400"  //second
        },
        {
          label: '15 ' + this.$t('HOURS'),
          value: "54000"  //second
        },
        {
          label: '16 ' + this.$t('HOURS'),
          value: "57000"  //second
        },
        {
          label: '17 ' + this.$t('HOURS'),
          value: "61200"  //second
        },
        {
          label: '18 ' + this.$t('HOURS'),
          value: "64800"  //second
        },
        {
          label: '19 ' + this.$t('HOURS'),
          value: "68400"  //second
        },
        {
          label: '20 ' + this.$t('HOURS'),
          value: "72000"  //second
        },
        {
          label: '21 ' + this.$t('HOURS'),
          value: "75600"  //second
        }, {
          label: '22 ' + this.$t('HOURS'),
          value: "79200"  //second
        }, {
          label: '23 ' + this.$t('HOURS'),
          value: "82800"  //second
        },
        {
          label: '24 ' + this.$t('HOURS'),
          value: "86400"  //second
        },
        {
          label: '2 ' + this.$t('days'),
          value: "172800"  //second
        },
        {
          label: '3 ' + this.$t('days'),
          value: "259200"  //second
        },
        {
          label: '4 ' + this.$t('days'),
          value: "345600"  //second
        },

        {
          label: '5 ' + this.$t('days'),
          value: "432000"  //second
        },
        {
          label: '6 ' + this.$t('days'),
          value: "518400"  //second
        },
        {
          label: '1 ' + this.$t('week'),
          value: "604800"
        },
        {
          label: '2 ' + this.$t('week'),
          value: "1209600"  //second
        },
        {
          label: '3 ' + this.$t('week'),
          value: "1814400"  //second
        },
        {
          label: '30 ' + this.$t('days'),
          value: "2592000"  //second
        },
        {
          label: '2 ' + this.$t('month'),
          value: "5184000"  //second
        },
        {
          label: '3 ' + this.$t('month'),
          value: "7776000"  //second
        },
        {
          label: '4 ' + this.$t('month'),
          value: "10368000"  //second
        },
        {
          label: '5 ' + this.$t('month'),
          value: "12960000"  //second
        },
        {
          label: '6 ' + this.$t('month'),
          value: "15552000"  //second
        },
        {
          label: '7 ' + this.$t('month'),
          value: "18144000"  //second
        },
        {
          label: '8 ' + this.$t('month'),
          value: "20736000"  //second
        },
        {
          label: '9 ' + this.$t('month'),
          value: "23328000"  //second
        },
        {
          label: '10 ' + this.$t('month'),
          value: "25920000"  //second
        },
        {
          label: '11 ' + this.$t('month'),
          value: "28512000"  //second
        },
        {
          label: '12 ' + this.$t('month'),
          value: "31104000"  //second
        },
        {
          label: '2 ' + this.$t('YEAR'),
          value: "62208000"  //second
        },

      ]
      ,
      selectedTrigger: ['all'],
      selectWalletLoading: false,
      tabModalEditKey: '1',
      searchTagLoading: false,
      searchTagValue: '',
      formConfig: {
        hasMail: false,
        selectedPage: 0,
        viralUrl: "",
        viralQRURL:"",
        smartcall: false,
        smartcall_trigger_id:"",
        pages:[],
        smart_call_trigger_scenario_id:"",
        pages_id:[],
        timeout_each_message:0
      },
      formConfig2: {
        hasMail: false,
        selectedPage: 0,
        viralUrl: "",
        viralQRURL:"",
        smartcall: false,
        smartcall_trigger_id:""
      },
      flowContext: {},
      typeCondition: '',
      options:[ {value:'contain_phone_number'}],
      options2:[ {value:'contain_phone_number'}]
    }
  },
  created() {
    console.log('version', this.version)
    this.fetchOverview()
    this.fetchFlows({page:1,page_size: 100})
    this.fetchTags({type: 'customer'})
    let pages:any = []
     this.getPages.map((item:any) => {
      if(item.page_type === 1){
        pages.push(item.page_id)

      }
    })
    this.fetchPostAllShop(pages)
    this.getPosts()
    this.getTags()
    this.fetchMailPages()
    this.fetchTreTags({payload: {type: "customer"}});

    if(this.version){
      return
    }
    this.loadData()

  },
  props: ['version'],
  methods: {
    ...mapActions({
      fetchTriggers: TriggerActionTypes.FETCH_TRIGGERS,
      fetchFlows: FlowActionTypes.FETCH_FLOWS,
      fetchTreTags: TagsActionTypes.FETCH_TREE_TAGS,
      fetchTags: TagsActionTypes.FETCH_TAGS,
      fetchPostAllShop: UserActionTypes.FETCH_POST_ALL_SHOP,
      fetchCustomerCare: CustomerCaresActionTypes.FETCH_CUSTOMERS_CARES,
      fetchMailPages: ShopinfoActionTypes.FETCH_MAIL_PAGE,
      fetchPages : PageConfigActionTypes.FETCH_PAGE_CONFIG,
      fetchOverview: OverviewActionTypes.FETCH_OVERVIEW
    }),
    handleChangeTagModal(value: number[]) {
      this.selectTags = value;
      console.log("selectTags", value)
    },
    searchTag(val: string) {
      this.searchTagLoading = true
      if (searchTreeTimeout) {
        clearTimeout(searchTreeTimeout)
      }
      searchTreeTimeout = window.setTimeout(() => {
        this.fetchTreTags({
          payload: {type: "customer", search: val}, callback: (res: TreeTagsResponse) => {
            this.searchTagLoading = false
          }
        })
      }, 1100)

    },
    isNumber(n:any) { return /^-?[\d.]+(?:e-?\d+)?$/.test(n); },
    filterOption2(input: string,option: any){
          console.log(input, option)
          if( this.isNumber(input)){
            return option.key.page_id.toLowerCase().indexOf(input.toLowerCase()) >= 0;
          }else {

            return option.key.page_name.toLowerCase().indexOf(input.toLowerCase()) >= 0;
          }




    },
    handleChangePage(id:any){
      this.formConfig.pages = this.getPages.filter((item:any) => id.includes(item.id))
    },
    conversationTags() {
      console.log('conversationTags')
      let page_id = ''
      let tags = []
      try {
        page_id = this.formState.customer_condition?.trigger_comparisons?.find((condition: any) => condition.field_type === 'page_id').value
        console.log('page_id', page_id)

        console.log(this.getPages.find((page: any) => page.page_id === page_id))
        tags = JSON.parse(this.getPages.find((page: any) => page.page_id === page_id).config_data).tags;
        console.log(tags)
      } catch (e) {
        console.log(e)
      }
      return tags
    },
    conversationTagsGroup(i:any,index:any) {
      console.log('conversationTags')
      let page_id = ''
      let tags = []
      try {
        page_id = this.formState.customer_condition?.trigger_comparisons[i].trigger_condition?.trigger_comparisons?.find((condition: any) => condition.field_type === 'page_id').value

        console.log(this.getPages.find((page: any) => page.page_id === page_id))
        tags = JSON.parse(this.getPages.find((page: any) => page.page_id === page_id).config_data).tags;
        console.log(tags)
      } catch (e) {
        console.log(e)
      }
      return tags
    },
    selectTriggerType(key: string) {
      this.selectedTrigger = [key]
      if (key === 'all') {
        this.triggerRequest.type = undefined;
      } else {
        this.triggerRequest.type = key;
      }
      this.loadData()
    },
    getPosts(search?: string) {
      this.posts = this.getPostsSelection(search);
    },
    getTags() {
      this.tags = this.getCustomerCares
    },
    fetchWallet() {
      if (this.formState.wallet_id) {
        this.selectWalletLoading = true
        APIs.init().getWalletId("vnd", this.formState.wallet_id).then((res) => {
          this.selectWalletLoading = false
          if (res.data?.status != 200) {
            message.error("Error API: " + res.data?.message)
          } else {
            this.formState.user_wallet_id = res.data.data.user_id
          }
        }).catch((e) => {
          this.selectWalletLoading = false
          console.error(e)
          message.error("Error API: " + JSON.stringify(e))
        })
      }

    },
    filterOption(input: string, option: any) {
      console.log(input, option)
      return option.key.label.toLowerCase().indexOf(input.toLowerCase()) >= 0;
    },
    searchPost(val: string) {
      this.getPosts(val)
    },
    selectFlowChange(val: string) {
      console.log("selectFlowChange", val)
      this.$refs.triggerTag.focus()
    },
    triggerCondition(){
      this.addParentCondition()
    },
    triggerTag(){
      this.$refs.wallet.focus()
    },
    flowHandleSearch(val: string) {
      if (flowHandleSearchTimeout) {
        clearTimeout(flowHandleSearchTimeout)
      }
      flowHandleSearchTimeout = window.setTimeout(() => {
        this.fetchFlows({search: val})
      }, 350)
    },
    deleteCustomerConditionRow(index: number) {
      if (this.formState.customer_condition && this.formState.customer_condition?.trigger_comparisons) {
        let conditions = this.formState.customer_condition?.trigger_comparisons
        delete conditions[index]
        this.formState.customer_condition.trigger_comparisons = this.formState.customer_condition?.trigger_comparisons?.filter((x: TriggerCustomerComparison) => x !== undefined)
      }
    },
    deleteCustomerConditionChidrenRow(idex:number,index:number) {
      if (this.formState.customer_condition && this.formState.customer_condition?.trigger_comparisons) {
        let conditions = this.formState.customer_condition.trigger_comparisons[index].trigger_condition.trigger_comparisons
        delete conditions[idex]
        this.formState.customer_condition.trigger_comparisons[index].trigger_condition.trigger_comparisons = this.formState.customer_condition?.trigger_comparisons[index]?.trigger_condition.trigger_comparisons.filter((x: TriggerCustomerComparison) => x !== undefined)
      }
    },

    getOrderStatusColor(status: number) {
      return MixUtils.methods.getOrderStatusColor(status)
    },
    getPostName(post: FacebookPostData) {
      if (post.message) {
        return post.message
      } else if (post.story) {
        return post.story
      } else if (post.attachments && post.attachments.length > 0 && post.attachments[0].title) {
        return post.attachments[0].title
      } else {
        return post.id
      }
    },
    getPostPicture(post: FacebookPostData) {
      if (post.full_picture) {
        return post.full_picture
      } else if (post.attachments && post.attachments.length > 0 && post.attachments[0].media && post.attachments[0].media.image && post.attachments[0].media.image.src) {
        return post.attachments[0].media.image.src
      } else {
        return "https://biglead.live/images/logo.png"
      }

    },
    mapTriggerCondition(trigger_id: string) {
      return {
        id: trigger_id,
        name: this.$t('TRIGGER_CONDITION_' + trigger_id.toLocaleUpperCase())
      }
    },
    getTriggerCondition(index: number) {
      try {
        let containPageId = false
        for (let i = 0; i < index; i++) {
          if (this.formState.customer_condition?.trigger_comparisons[i].field_type === 'page_id') {
            containPageId = true
            break
          }
        }

        if (containPageId) {
          return this.getFlowConstants?.trigger_condition.trigger_condition_field_types.map((id: string) => this.mapTriggerCondition(id))
        } else {
          return this.getFlowConstants?.trigger_condition.trigger_condition_field_types.filter((condition: any) => condition !== 'conversation_tag').map((id: string) => this.mapTriggerCondition(id))
        }
      } catch (e) {
        console.log(e)
        return this.getFlowConstants?.trigger_condition.trigger_condition_field_types.map((id: string) => this.mapTriggerCondition(id))
      }
    },
    getEmployeeName(payload: number): string {
      if (this.getCurrentShop && this.getCurrentShop.employees) {
        const employee = this.getCurrentShop.employees.find((x: Employee) => x.id === payload);
        return employee ? employee.name : "";
      }
      return "";
    },
    loadData() {
      if (this.searchInput) {
        this.triggerRequest.search = this.searchInput
      } else{
        this.triggerRequest.search = ''
      }

      //   if (this.selectedType) {
      //   this.triggerRequest.type = this.selectedType
      // } else{
      //   this.triggerRequest.type = ''
      // }
        if (this.selectedstatus) {
        this.triggerRequest.status = this.selectedstatus
      }else {
        this.triggerRequest.status = ''
      }

      if (this.selectedTags && this.selectedTags.length) {
        this.triggerRequest.tags = this.selectedTags.join(",")
      } else {
        this.triggerRequest.tags = undefined
      }
      this.fetchTriggers(this.triggerRequest).then();
    },
    newTrigger() {
      this.amountSent = undefined
      this.modalCreateVisible = true;
      setTimeout(()=>{
        const editButtonRef =  this.$refs.triggerTitle
        editButtonRef.focus()
      },500)

      this.formState = {

        customer_condition: {
          condition: '',
          trigger_comparisons: []
        }
      }

    },
    titleBlur() {
      this.$refs.triggerType.focus()
    },
    async fetchFlowContext(id: number) {

      const response = await APIs.init().getFlowV2Context({id: id})
      if (response.ok && response.data?.data) {
        this.flowContext = response.data.data
        if (this.flowContext.context && this.flowContext.context.flow_contexts) {
          const hasMail = this.flowContext.context.flow_contexts.find((x: FlowContext) => x.flow_type === 5)
          if (hasMail) {
            this.formConfig.hasMail = true
          }
          if (this.flowContext.context.flow_contexts.length > 0 && this.flowContext.context.flow_contexts[0].flow_viral
              && this.flowContext.context.flow_contexts[0].flow_viral.length
              && this.flowContext.context.flow_contexts[0].flow_type === 6) {
            const res = await APIs.init().getTriggerInviteLink({trigger_id: this.trigger_id,flow_id:this.flowContext.id,flow_context_id:this.flowContext.context.flow_contexts[0].id,flow_message_id:this.flowContext.context.flow_contexts[0].flow_viral[0].id})
            this.formConfig.viralUrl = res.data?.data
            QRCode.toDataURL(this.formConfig.viralUrl )
                .then(url => {
                  this.formConfig.viralQRURL = url
                })
                .catch(err => {
                  console.error(err)
                })
          }
          if(this.flowContext.context.flow_contexts.length > 0
              && this.flowContext.context.flow_contexts[0].flow_type === 7){
            this.formConfig.smartcall = true

          }
        }
      }
    },
    async fetchFlowContext2(id: number) {

      const response = await APIs.init().getFlowV2Context({id: id})
      if (response.ok && response.data?.data) {
        this.flowContext = response.data.data
        if (this.flowContext.context && this.flowContext.context.flow_contexts) {
          const hasMail = this.flowContext.context.flow_contexts.find((x: FlowContext) => x.flow_type === 5)
          if (hasMail) {
            this.formConfig2.hasMail = true
          }
          if (this.flowContext.context.flow_contexts.length > 0 && this.flowContext.context.flow_contexts[0].flow_viral
              && this.flowContext.context.flow_contexts[0].flow_viral.length
              && this.flowContext.context.flow_contexts[0].flow_type === 6) {
            const res = await APIs.init().getTriggerInviteLink({trigger_id: this.trigger_id,flow_id:this.flowContext.id,flow_context_id:this.flowContext.context.flow_contexts[0].id,flow_message_id:this.flowContext.context.flow_contexts[0].flow_viral[0].id})
            this.formConfig2.viralUrl = res.data?.data + `/?&info=${this.userInfo.current_shop_id}`
            QRCode.toDataURL(this.formConfig2.viralUrl)
                .then(url => {
                  this.formConfig2.viralQRURL = url
                })
                .catch(err => {
                  console.error(err)
                })
          }
          if(this.flowContext.context.flow_contexts.length > 0
              && this.flowContext.context.flow_contexts[0].flow_type === 7){
            this.formConfig2.smartcall = true
          }
        }
      }
    },
    getCondition(conditon:any,fieldType:any,index:number,idex:number) {
      const conditons:any[] = []
    if(fieldType === 'page_id' || fieldType === 'messenger_window_24h' || fieldType === 'has_phone_number'   || fieldType === 'last_contact_time' || fieldType === 'first_contact_time' || fieldType ==='care_status' || fieldType === 'post_id'){
       conditon.map((item:any) => {
        if (item === 'EQUAL' ){
          conditons.push(item)
        } else if (item === 'NOT_EQUAL'){
          conditons.push(item)
        }


      })


    }else if(fieldType === 'tag' || fieldType === 'conversation_tag' || fieldType == 'order_status' ||fieldType === 'key_word' || fieldType === 'product_category_id'  ){
      conditon.map((item:any) => {
        if (item === 'CONTAIN' ){
          conditons.push(item)
        } else if (item === 'NOT_CONTAIN'){
          conditons.push(item)
        }


      })


    } else if(fieldType === 'timeout'){
      conditon.map((item:any) => {
        if (item === 'EQUAL' ){
          conditons.push(item)
        }else if(item === 'GREATER_THAN'){
          conditons.push(item)
        }


      })

    } else {
      conditon.map((item:any) => {
    conditons.push(item)


      })

    }
    // else if( fieldType === 'key_word'){
    //     conditon.map((item:any) => {
    //       if (item === 'EQUAL' ){
    //         conditons.push(item)
    //       } else if (item === 'NOT_EQUAL'){
    //         conditons.push(item)
    //       } else if (item === 'CONTAIN' ){
    //         conditons.push(item)
    //       }
    //
    //
    //     })
    //   }
    // console.log(conditons)
    return conditons

    },
    getConvertionTangId(id:any,page:any){
     return  id.map((i:any) => {
       if(page !== i.split("_")[0]){
         return i.split("_")[1]
       }else {
         return []
       }

      })


    },
    customRowClick(trigger: Trigger) {
      console.log(trigger)
      this.amountSent = undefined
      this.trigger_id = trigger.id
      this.status = trigger.status
      const formObject: TriggerForm = {}
      let formState = {...trigger, ...formObject}
      this.fetchFlowContext(trigger.root_flow_id).then()
      this.fetchFlowContext2(trigger.root_flow_id).then()
      formState.root_flow_id = formState.root_flow_id + ''
      if (formState.root_flow_id === '0') {
        formState.root_flow_id = undefined
      }
      if (trigger.tags) {
        formState.tags = trigger.tags.map((item: Tag): number => {
          return item && item.id ? item.id : 0;
        }).filter((x: number) => x)
      } else {
        formState.tags = []
      }
      if (trigger.time_start) {
        formState.time_start_m = dayjs(trigger.time_start)
      }
      this.formState = formState;
      console.log(this.formState.customer_condition.trigger_comparisons,'456')
      this.formState.customer_condition.trigger_comparisons = this.formState.customer_condition?.trigger_comparisons.map((item:any) =>{
        if(item.value && (item.field_type === 'first_contact_time' || item.field_type === 'last_contact_time' || item.field_type === "DATE" || item.field_type === 'key_word') ){
          return{condition:item.condition,field_type: item.field_type,trigger_condition: item.trigger_condition,value: typeof item?.value === 'string' ? item?.value.split('|') : item?.value,coverstion_tag:[]}
        }else if(item.value && (item.field_type === 'conversation_tag')){
// @ts-ignore
          item.coverstion_tag = item.value.split(",").map((converstionT) => {
            // @ts-ignore
            this.getConverstionTag.map((i:any) => {
              i.tags.map((tag:any) => {
                // @ts-ignore
                if(converstionT === tag.tag_id){
                  converstionT = i.page_id + '_' + converstionT

                }

              })

            })
            return converstionT
          })
          return{condition:item.condition,field_type: item.field_type,trigger_condition: item.trigger_condition,value: item.value,coverstion_tag:item.coverstion_tag}

        }else if(item.trigger_condition && item.trigger_condition.trigger_comparisons) {
          item.trigger_condition.trigger_comparisons = item.trigger_condition.trigger_comparisons.map((i: any) => {
            if(i.field_type === 'first_contact_time' || i.field_type === 'last_contact_time' || i.field_type === "DATE" || i.field_type === 'key_word' ){
              return{condition:i?.condition,field_type: i?.field_type,value: typeof i.value === 'string' ? i?.value.split('|') :i?.value,coverstion_tag:[]}
            }else if(i.value && (i.field_type === 'conversation_tag')){
// @ts-ignore
              i.coverstion_tag = i.value.split(",").map((converstionT) => {
                // @ts-ignore
                this.getConverstionTag.map((tag:any) => {
                  tag.tags.map((t:any) => {
                    // @ts-ignore
                    if(converstionT === t.tag_id){
                      converstionT = tag.page_id + '_' + converstionT

                    }

                  })

                })
                return converstionT
              })
              return{condition:i.condition,field_type: i.field_type,trigger_condition: i.trigger_condition,value: i.value,coverstion_tag:i.coverstion_tag}

            } else {
              return i
            }

          })
          return item
        } else {
          return item
        }

      })
      console.log(this.formState.customer_condition.trigger_comparisons,'156')
      this.formConfig = trigger.config
      if (trigger.config && trigger.config.pages && trigger.config.pages.length) {
        this.formConfig.selectedPage = trigger.config.pages[0].id
      }
      if (trigger.config && trigger.config.smartcall_trigger_id ) {
        this.formConfig.smartcall_trigger_id = trigger.config.smartcall_trigger_id
      }
      if (trigger.config && trigger.config.smart_call_trigger_scenario_id ) {
        this.formConfig.smart_call_trigger_scenario_id = trigger.config.smart_call_trigger_scenario_id
      }
      if (trigger.config && trigger.config.pages ) {
        this.formConfig.pages_id = trigger.config.pages.map((item:any) => {
          return item.id
        })
      }
      this.modalCreateVisible = true
      this.fetchWallet()
    },
    editFlow(trigger: Trigger) {
      this.$router.push({name: RouterEndpoint.FLOW_CONTEXT.name, params: {id: trigger.root_flow_id}})
    },
    goHistory(trigger: Trigger) {
      this.$router.push({name: RouterEndpoint.TRIGGER_HISTORY.name, query: {trigger_id: trigger.id}})
    },
    onSearchChange() {
      if (inputChangeTimeout) {
        clearTimeout(inputChangeTimeout)
      }
      inputChangeTimeout = window.setTimeout(() => {

        this.loadData()
      }, 500)
    },
    handleTableChange(pagination: PaginationProps) {
      this.triggerRequest.page = pagination?.current
      this.triggerRequest.page_size = pagination?.pageSize
      this.loadData()
    },
    toggleTag(tag: Tag) {
      const tagFound = this.selectedTags.find((x: number) => x === tag.id)
      console.log("toggleTag", tagFound)
      if (tagFound) {
        this.selectedTags = this.selectedTags.filter((x: number) => x !== tag.id)
        console.log("found", this.selectedTags)
      } else {
        this.selectedTags.push(tag.id)
      }
      this.loadData()
    },
    handleSelectWallet(val: number) {
      this.selectWalletLoading = true
      APIs.init().getWalletUid("vnd", val).then((res) => {
        this.selectWalletLoading = false
        if (res.data?.status != 200) {
          message.error("Error API: " + res.data?.message)
        } else {

          this.formState.wallet_id = res.data.data._id
          if (res.data.data?.balance <= 0) {
            message.warning("The wallet is not enough! current balance: " + res.data.data?.balance)
          }

        }
      }).catch(e => {
        this.selectWalletLoading = false
        console.error(e)
        message.error("Error API: " + JSON.stringify(e))
      })

    },
    addParentCondition() {
      this.typeCondition = 'condition'
      if (!this.formState.customer_condition) {
        this.formState.customer_condition = {}
      }
      if (!this.formState.customer_condition.trigger_comparisons) {
        this.formState.customer_condition.trigger_comparisons = []
      }else {
        this.formState.customer_condition.trigger_comparisons.push({condition:" "})
      }
      console.log( this.formState.customer_condition.trigger_comparisons,'111')
    },
    addGroupCondition(){
      this.typeCondition = 'conditionGroup'
      if (!this.formState.customer_condition) {
        this.formState.customer_condition = {}
      }
      if (!this.formState.customer_condition.trigger_comparisons) {
        this.formState.customer_condition.trigger_comparisons = []
      }else {
        this.formState.customer_condition.trigger_comparisons.push({trigger_condition:{}})
      }

    },
    addChildrenCondition(index: any){
     if(!this.formState.customer_condition.trigger_comparisons[index].trigger_condition.trigger_comparisons){
       this.formState.customer_condition.trigger_comparisons[index].trigger_condition.trigger_comparisons = []
     }
      this.formState.customer_condition.trigger_comparisons[index].trigger_condition.trigger_comparisons.push({condition:" "})
      console.log(this.formState.customer_condition.trigger_comparisons[index].trigger_condition.trigger_comparisons)

    },
    onSubmit() {
  console.log(this.getAdminAndShopOwner)
      this.amountSent = undefined
      this.$refs.formRef.validate().then(() => {
        this.formState.customer_condition.trigger_comparisons = this.formState.customer_condition.trigger_comparisons.map((item: TriggerCustomerComparison)=>{
          if(item.field_type && item.condition){

            if(item.field_type === 'last_contact_time' || item.field_type === 'first_contact_time' || item.field_type === "DATE" || item.field_type === 'key_word'){
              if(item.value)
                  // @ts-ignore
                return {condition:item.condition,field_type: item.field_type,value: [...item.value].join('|')}
            }else if(item.field_type == 'has_phone_number'  ) {

              return item
            }else if(item.field_type == 'conversation_tag'  ) {
              // @ts-ignore
              item.value = item.coverstion_tag.map((tag) => {
                // @ts-ignore
                tag = tag.split("_")[1]
                return tag
              }).join(",")
              console.log(item.value,'11111')
            }else {
              return item
            }
            return item
          }else if(item.trigger_condition && item.trigger_condition.trigger_comparisons){
            item.trigger_condition.trigger_comparisons = item.trigger_condition.trigger_comparisons.map((i: any)=>{
              if(i.field_type && i.condition){

                if(i.field_type === 'last_contact_time' || i.field_type === 'first_contact_time' || i.field_type === "DATE" || i.field_type === 'key_word'){
                  if(i.value )
                    return {condition:i.condition,field_type: i.field_type,value: [...i.value].join('|')}
                }else if(i.field_type == 'has_phone_number'  ) {
                  return i
                }else if(i.field_type == 'conversation_tag') {

                  // @ts-ignore
                  i.value = i.coverstion_tag.map((tag) => {
                    // @ts-ignore
                    tag = tag.split("_")[1]
                   return tag
                  }).join(",")

                }else {
                  return i
                }
              }
              return i
            })

               return item
          }
               return item
        })
        // this.formState.customer_condition.trigger_comparisons =
        //     this.formState.customer_condition.trigger_comparisons.filter((item: TriggerCustomerComparison) => item.condition && item.field_type && (item.field_type == 'has_phone_number' || item.value))
        // // if (this.formState.customer_condition.trigger_comparisons.length > 0) {
        // // this.rules.trigger_condition.isValid = true;

        let formState = {...this.formState}
        formState.root_flow_id = parseInt(formState.root_flow_id)

        if (formState.tags) {
          formState.tags = formState.tags.map((item: number) => {
            return useTagStore.getState().tagTriggers.find((tag: Tag) => tag.id == item)
          }).filter((item: Tag) => item)
        }
        if (formState.time_start_m) {
          console.log(formState.time_start_m)
          formState.time_start = dayjs(formState.time_start_m).format()
        }
        this.confirmTriggerLoading = true
        if (!formState.wallet_id) {
          formState.wallet_id = this.getWallet?._id
        }
        if (this.formConfig.selectedPage) {
          formState.config = {
            pages: this.getMailPage.filter((x: Page) => x.id === this.formConfig.selectedPage)
          }
        }
        if (this.formConfig.smartcall_trigger_id) {
          formState.config = {
            smartcall_trigger_id: this.formConfig.smartcall_trigger_id,
            pages:this.formConfig.pages,
            smart_call_trigger_scenario_id:this.formConfig.smart_call_trigger_scenario_id

          }
        }
        if(this.formConfig.timeout_each_message){
          formState.config = {
            smartcall_trigger_id: this.formConfig.smartcall_trigger_id,
            pages:this.formConfig.pages,
            smart_call_trigger_scenario_id:this.formConfig.smart_call_trigger_scenario_id,
            timeout_each_message: Number(this.formConfig.timeout_each_message)
          }
        }

        if (formState.id) {
          APIs.init().updateTrigger(formState).then(res => {
            this.confirmTriggerLoading = false
            if (res.status === 200 && res.data?.status === 200) {
              this.modalCreateVisible = false
              this.loadData();
            }
          }).catch(e => {
            this.confirmTriggerLoading = false
            console.error(e)
          })
        } else {
          APIs.init().createTrigger(formState).then(res => {
            this.confirmTriggerLoading = false
            if (res.status === 200 && res.data?.status === 200) {
              this.modalCreateVisible = false
              this.loadData();
            }
          }).catch(e => {
            this.confirmTriggerLoading = false
            console.error(e)
          })
        }
        console.log("form", formState);
        // } else {
        //   // this.rules.trigger_condition.isValid = false
        // }
        // } else {
        //   // this.rules.trigger_condition.isValid = false
        // }
      }).catch((e: ValidateErrorEntity) => {
        console.error(e)
      })
    },
    deleteTrigger(trigger: Trigger) {
      message.loading({content: 'Deleting Trigger: ' + trigger.name, key: trigger.id});
      if (!trigger.id) {
        trigger.id = 0
      }
      APIs.init().deleteTrigger(trigger.id).then(res => {
        if (res.status === 200 && res.data?.status === 200) {
          message.success({content: 'Deleted Trigger: ' + trigger.name + '!', key: trigger.id, duration: 2});
          this.loadData()
        } else {
          message.destroy()
        }
      }).catch(e => {
        message.destroy()
        console.error(e)
      })
    },
    async activateTriggerModal(trigger: Trigger) {
      console.log(trigger)
      const response = await APIs.init().getFlowV2Context({id: Number(trigger.root_flow_id)})
      if (response.ok && response.data?.data) {
        this.flowContext = response.data.data
        if (this.flowContext.context && this.flowContext.context.flow_contexts) {
                if(this.flowContext.context.flow_contexts[0].flow_type === 5  ) {
                  if(trigger.config?.pages !== null){
                  const onOk = () => {
                    APIs.init().activateTrigger(trigger.id ? trigger.id : 0).then(res => {
                      if (res.status === 200) {
                        setTimeout(() => {
                          this.loadData()
                        }, 500)
                      }
                    }).catch(e => {
                      console.error(e)
                    })
                    console.log('OK');
                  }
                  Modal.confirm({
                    title: () => this.$t('TRIGGER_TITLE_CONFIRM_ACTIVATE'),
                    icon: () => createVNode(ExclamationCircleOutlined),
                    closable: true,
                    maskClosable: true,
                    content: () => createVNode('div', {style: 'color:red;'}, this.$t('TRIGGER_CONTENT_CONFIRM_ACTIVATE')),
                    onOk,
                    onCancel() {
                      console.log('Cancel');
                    },
                  });
                }else {
                  Modal.confirm({
                    title: () => this.$t('you have not selected email'),
                    icon: () => createVNode(ExclamationCircleOutlined),
                    closable: true,
                    maskClosable: true,
                    onOk(){
                      console.log('Cancel');
                    },
                    onCancel() {
                      console.log('Cancel');
                    },
                  })}
                }else {
                  const onOk = () => {
                    APIs.init().activateTrigger(trigger.id ? trigger.id : 0).then(res => {
                      if (res.status === 200) {
                        setTimeout(() => {
                          this.loadData()
                        }, 500)
                      }
                    }).catch(e => {
                      console.error(e)
                    })
                    console.log('OK');
                  }
                  Modal.confirm({
                    title: () => this.$t('TRIGGER_TITLE_CONFIRM_ACTIVATE'),
                    icon: () => createVNode(ExclamationCircleOutlined),
                    closable: true,
                    maskClosable: true,
                    content: () => createVNode('div', {style: 'color:red;'}, this.$t('TRIGGER_CONTENT_CONFIRM_ACTIVATE')),
                    onOk,
                    onCancel() {
                      console.log('Cancel');
                    },
                  });

                }
        }}


    },
    hideModal() {
      this.amountSent = undefined
      this.modalCreateVisible = false
    },
    stopTriggerModal(trigger: Trigger) {
      const onOk = () => {
        APIs.init().stopTrigger(trigger.id ? trigger.id : 0).then(res => {
          if (res.status === 200) {
            this.loadData()
          }
        }).catch(e => {
          console.error(e)
        })
        console.log('OK');
      }
      Modal.confirm({
        title: this.$t('TRIGGER_TITLE_CONFIRM_STOP'),
        closable: true,
        maskClosable: true,
        onOk,
        onCancel() {
          console.log('Cancel');
        },
      });
    },
    getAmountSent(id: number) {
      if(this.status !== "running" || !this.status){
        if(id){
          this.formState.root_flow_id = parseInt(this.formState.root_flow_id)
          this.formState.customer_condition.trigger_comparisons = this.formState.customer_condition.trigger_comparisons.map((item: TriggerCustomerComparison)=>{
            if(item.field_type && item.condition ){
              if(item.field_type === 'last_contact_time' || item.field_type === 'first_contact_time' || item.field_type === 'DATE'){
                if(item.value)
                  return {condition:item.condition,field_type: item.field_type,value: [...item.value].join('|')}
              }else if(item.field_type == 'has_phone_number' || item.value ) {
                return item
              }else if(item.field_type == 'conversation_tag'  ) {
                // @ts-ignore
                item.value = item.coverstion_tag.map((tag) => {
                  // @ts-ignore
                  tag = tag.split("_")[1]
                  return tag
                }).join(",")
                console.log(item.value,'11111')
              }else {
                return item
              }
              return item
            }else if(item.trigger_condition && item.trigger_condition.trigger_comparisons){
              item.trigger_condition.trigger_comparisons = item.trigger_condition.trigger_comparisons.map((i: any)=>{
                if(i.field_type && i.condition ){
                  if(i.field_type === 'last_contact_time' || i.field_type === 'first_contact_time' || i.field_type === 'DATE'){
                    if(i.value)
                      return {condition:i.condition,field_type: i.field_type,value: [...i.value].join('|')}
                  }else if(i.field_type == 'has_phone_number' ) {
                    return i
                  }else if(i.field_type == 'conversation_tag') {

                    // @ts-ignore
                    i.value = i.coverstion_tag.map((tag) => {
                      // @ts-ignore
                      tag = tag.split("_")[1]
                      return tag
                    }).join(",")

                  }else {
                    return i
                  }
                  return i
                }
              })
              return item
            }
            return item
          })
   console.log(this.formState.customer_condition,'customer_condition')

          APIs.init().updateTrigger(this.formState).then(res => {
            this.confirmTriggerLoading = false
            if (res.status === 200 && res.data?.status === 200) {
              APIs.init().getTriggerEstimate({trigger_id:id}).then(res => this.amountSent =res.data?.data).catch(e => console.log(e))
              this.customRowClick(res.data?.data)
              this.loadData();

            }
          }).catch(e => {
            this.confirmTriggerLoading = false
            console.error(e)
          })
        }else {
          this.formState.time_start_m = dayjs()
          if(this.formState.customer_condition.condition === ""){
            this.formState.customer_condition.condition = "AND"
          }

          this.formState.customer_condition.trigger_comparisons = this.formState.customer_condition.trigger_comparisons.map((item: TriggerCustomerComparison)=>{
            if(item.field_type && item.condition ){
              if(item.field_type === 'last_contact_time' || item.field_type === 'first_contact_time' || item.field_type === 'DATE'){
                if(item.value)
                  return {condition:item.condition,field_type: item.field_type,value: [...item.value].join('|')}
              }else if(item.field_type == 'has_phone_number' || item.value ) {
                return item
              }else if(item.field_type == 'conversation_tag'  ) {
                // @ts-ignore
                item.value = item.coverstion_tag.map((tag) => {
                  // @ts-ignore
                  tag = tag.split("_")[1]
                  return tag
                }).join(",")
                console.log(item.value,'11111')
              }else {
                return item
              }
              return item
            }else if(item.trigger_condition && item.trigger_condition.trigger_comparisons){
              item.trigger_condition.trigger_comparisons = item.trigger_condition.trigger_comparisons.map((i: any)=>{
                if(i.field_type && i.condition ){
                  if(i.field_type === 'last_contact_time' || i.field_type === 'first_contact_time' || i.field_type === 'DATE'){
                    if(i.value)
                      return {condition:i.condition,field_type: i.field_type,value: [...i.value].join('|')}
                  }else if(i.field_type == 'has_phone_number' ) {
                    return i
                  }else if(i.field_type == 'conversation_tag') {

                    // @ts-ignore
                    i.value = i.coverstion_tag.map((tag) => {
                      // @ts-ignore
                      tag = tag.split("_")[1]
                      return tag
                    }).join(",")

                  }else {
                    return i
                  }
                  return i
                }
              })
              return item
            }
            return item
          })
          // if (this.formState.customer_condition.trigger_comparisons.length > 0) {
          // this.rules.trigger_condition.isValid = true;
          let formState = {...this.formState}
          formState.root_flow_id = parseInt(formState.root_flow_id)
          APIs.init().createTrigger(formState).then(res => {
            this.confirmTriggerLoading = false
            if (res.status === 200 && res.data?.status === 200) {
              APIs.init().getTriggerEstimate({trigger_id:res.data?.data.id}).then(res => this.amountSent =res.data?.data).catch(e => console.log(e))
              this.customRowClick(res.data?.data)
              this.loadData();
            }
          }).catch(e => {
            this.confirmTriggerLoading = false
            console.error(e)
          })
        }

      }else {
        APIs.init().getTriggerEstimate({trigger_id:id}).then(res => this.amountSent =res.data?.data).catch(e => console.log(e))

      }

    },
    getPages() {
      console.log(this.getUserInfo.shops.find((shop: any) => shop.id === this.getUserInfo.current_shop_id))
      return this.getUserInfo.shops.find((shop: any) => shop.id === this.getUserInfo.current_shop_id)?.pages
    },
    saleTags() {
      let tags = []
      try {
        tags = JSON.parse(this.currentPage.config_data)?.tags;
        console.log(tags)
      } catch (e) {
        console.log(e)
      }
      return tags
    },
    handleChangePages(value: any) {
      this.currentPage = this.getPages.find((page: any) => page.page_id === value)
      console.log(this.currentPage)
    },
    handleChangeTags(tags: number[]) {
      console.log('tags', tags)
      this.selectedTags = tags
      this.loadData()
    },
    handleChangeType(type: string) {

      if(type === "BULK_MESSAGE"){
        this.formState.frequency_type ='now'
      }
      this.$refs.flows.focus()
    },
    handleChangeStatus(status: string) {
      console.log(status)
      this.selectedstatus = status ? status : ''
      console.log(this.selectedstatus)
      this.loadData()
    },
    getMyRole(key: string) {
      if(this.userInfo.id_cms_privileges<11){
        return {
          is_visible: 1,
          is_create:1,
          is_read: 1,
          is_edit: 1,
          is_delete: 1,
          id: 1
        }
      }else{
        return this.getRole(key);
      }
    },
  }
})
export default class Triggers extends Vue {
}

</script>

<style lang="scss" scoped>
.container {
  margin: 10px 15px;
}

.left-layout {
  padding-right: 14px;

  .tag-group {
    padding: 10px 4px;
  }

  .button-align-left {
    text-align: left;
  }

}

.row-horizontal {
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;


}

.input-search {
  margin-right: 12px;
  margin-bottom: 8px;
}

.table-row-name {
  font-weight: bold;
}

.flow-table {
  background-color: white;
  padding-left: 12px;
}

.row-full-width {
  width: 100%;
  margin-bottom: 10px;
}

.row-horizontal {
  display: flex;
  flex-direction: row;

  .column-fix-1 {
    flex: 1;
  }
}

.condition-alert {
  margin-top: 4px;
  color: red;
}

.item-trigger-table-small {
  font-size: 13px;
}

.modal-text-warning-running-status {
  margin-right: 10px;
  color: red;
}

.table-header {
  display: flex;
  align-items: center;
  width: 100%;
  padding-left: 10px;
  background-color: white;
  padding-right: 10px;
  padding-top: 12px;
}

.container-filter {
  display: flex;
  gap: 16px;
}

.table-title {
  align-items: center;
  padding-right: 14px;
  flex: 1;
}

.horizontal-form {
  width: 100%;
}

.header-trigger-filter {
  background-color: white;
}
</style>
