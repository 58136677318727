import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode } from "vue"
import _imports_0 from '@/assets/icons/zalo-seeklogo.com.svg'
import _imports_1 from '@/assets/icons/facebook-svgrepo-com.svg'
import _imports_2 from '@/assets/icons/instagram-svgrepo-com.svg'
import _imports_3 from '@/assets/icons/line-svgrepo-com.svg'


const _hoisted_1 = {
  key: 0,
  draggable: "false",
  src: _imports_0,
  style: {"width":"20px","margin-right":"20px"}
}
const _hoisted_2 = {
  key: 1,
  draggable: "false",
  src: _imports_1,
  style: {"width":"20px","margin-right":"20px"}
}
const _hoisted_3 = {
  key: 2,
  draggable: "false",
  src: _imports_2,
  style: {"width":"20px","margin-right":"20px"}
}
const _hoisted_4 = {
  key: 3,
  draggable: "false",
  src: _imports_3,
  style: {"width":"20px","margin-right":"20px"}
}
const _hoisted_5 = {
  key: 0,
  style: {"margin-top":"20px"}
}
const _hoisted_6 = { style: {"margin-left":"30px"} }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_typography_title = _resolveComponent("a-typography-title")!
  const _component_a_typography_text = _resolveComponent("a-typography-text")!
  const _component_a_col = _resolveComponent("a-col")!
  const _component_a_button = _resolveComponent("a-button")!
  const _component_a_row = _resolveComponent("a-row")!
  const _component_a_select_option = _resolveComponent("a-select-option")!
  const _component_a_select = _resolveComponent("a-select")!
  const _component_TagsOutlined = _resolveComponent("TagsOutlined")!
  const _component_PlusOutlined = _resolveComponent("PlusOutlined")!
  const _component_a_switch = _resolveComponent("a-switch")!
  const _component_a_form_item = _resolveComponent("a-form-item")!
  const _component_a_form = _resolveComponent("a-form")!
  const _component_a_card = _resolveComponent("a-card")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_a_row, null, {
      default: _withCtx(() => [
        _createVNode(_component_a_col, { span: 8 }, {
          default: _withCtx(() => [
            _createVNode(_component_a_typography_title, { level: 2 }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t('employee_assignment')), 1)
              ]),
              _: 1
            }),
            _createVNode(_component_a_typography_text, { type: "secondary" }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t('assigning_employees_for_pages')), 1)
              ]),
              _: 1
            })
          ]),
          _: 1
        }),
        _createVNode(_component_a_col, { span: 6 }),
        ($setup.isPage)
          ? (_openBlock(), _createBlock(_component_a_col, {
              key: 0,
              span: 10
            }, {
              default: _withCtx(() => [
                _createVNode(_component_a_row, { style: {"justify-content":"flex-end"} }, {
                  default: _withCtx(() => [
                    _createVNode(_component_a_button, {
                      onClick: $setup.save,
                      type: "primary"
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.$t('SAVE')), 1)
                      ]),
                      _: 1
                    }, 8, ["onClick"])
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }),
    _createVNode(_component_a_row, { style: {"margin-top":"20px"} }, {
      default: _withCtx(() => [
        _createVNode(_component_a_col, { span: 11 }, {
          default: _withCtx(() => [
            _createVNode(_component_a_typography_title, { level: 5 }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t('change_shop')), 1)
              ]),
              _: 1
            }),
            _createVNode(_component_a_select, {
              allowClea: "",
              value: $setup.currentShop.currentShop.id,
              style: {"width":"100%"},
              placeholder: "Please select",
              onChange: $setup.changeShop
            }, {
              default: _withCtx(() => [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.userInfo.shops, (item) => {
                  return (_openBlock(), _createBlock(_component_a_select_option, {
                    key: item.id,
                    value: item.id
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(item.shop_name), 1)
                    ]),
                    _: 2
                  }, 1032, ["value"]))
                }), 128))
              ]),
              _: 1
            }, 8, ["value", "onChange"])
          ]),
          _: 1
        }),
        _createVNode(_component_a_col, { span: 2 }),
        _createVNode(_component_a_col, { span: 11 }, {
          default: _withCtx(() => [
            _createVNode(_component_a_typography_title, { level: 5 }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t('select_page')), 1)
              ]),
              _: 1
            }),
            _createVNode(_component_a_select, {
              allowClea: "",
              "show-search": "",
              style: {"width":"100%"},
              placeholder: "Please select",
              onChange: $setup.changePage,
              value: $setup.getCurrentPageId,
              "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => (($setup.getCurrentPageId) = $event))
            }, {
              default: _withCtx(() => [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.configPage.pageConfig, (item) => {
                  return (_openBlock(), _createBlock(_component_a_select_option, {
                    key: item.id,
                    value: item.page_id
                  }, {
                    default: _withCtx(() => [
                      (item.page_type === 2)
                        ? (_openBlock(), _createElementBlock("img", _hoisted_1))
                        : (item.page_type === 1)
                          ? (_openBlock(), _createElementBlock("img", _hoisted_2))
                          : (item.page_type === 3)
                            ? (_openBlock(), _createElementBlock("img", _hoisted_3))
                            : (item.page_type === 4)
                              ? (_openBlock(), _createElementBlock("img", _hoisted_4))
                              : _createCommentVNode("", true),
                      _createTextVNode(_toDisplayString(item.page_name), 1)
                    ]),
                    _: 2
                  }, 1032, ["value"]))
                }), 128))
              ]),
              _: 1
            }, 8, ["onChange", "value"])
          ]),
          _: 1
        }),
        (!$setup.isPage)
          ? (_openBlock(), _createBlock(_component_a_row, {
              key: 0,
              style: {"margin-top":"30px","width":"100%"},
              justify: "center"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_a_typography_title, { level: 3 }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.$t('PLEASE_CHOOSE_SHOP_AND_FANPAGE')), 1)
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }),
    ($setup.isPage)
      ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
          _createVNode(_component_a_card, {
            style: {"margin-top":"20px","border-radius":"10px"},
            span: 24
          }, {
            title: _withCtx(() => [
              _createVNode(_component_a_row, null, {
                default: _withCtx(() => [
                  _createVNode(_component_a_col, { span: 8 }, {
                    default: _withCtx(() => [
                      _createVNode(_component_TagsOutlined),
                      _createTextVNode(" " + _toDisplayString(_ctx.$t('functions')), 1)
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_a_col, { span: 14 }),
                  _createVNode(_component_a_col, { span: 2 }, {
                    default: _withCtx(() => [
                      _createVNode(_component_a_button, {
                        onClick: _ctx.pushTags,
                        style: {"border-radius":"50%"}
                      }, {
                        icon: _withCtx(() => [
                          _createVNode(_component_PlusOutlined)
                        ]),
                        _: 1
                      }, 8, ["onClick"])
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            default: _withCtx(() => [
              _createVNode(_component_a_row, { style: {"margin-bottom":"20px"} }, {
                default: _withCtx(() => [
                  _createVNode(_component_a_switch, {
                    checked: $setup.configData.role_user_page.enabled,
                    "onUpdate:checked": _cache[1] || (_cache[1] = ($event: any) => (($setup.configData.role_user_page.enabled) = $event))
                  }, null, 8, ["checked"]),
                  _createVNode(_component_a_typography_text, { style: {"margin-left":"10px"} }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.$t('assign_employees_able_access')), 1)
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }),
              ($setup.configData.role_user_page.enabled)
                ? (_openBlock(), _createBlock(_component_a_row, {
                    key: 0,
                    style: {"margin":"0 20px 20px"}
                  }, {
                    default: _withCtx(() => [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.dataEmployee.employees, (i) => {
                        return (_openBlock(), _createElementBlock("div", {
                          style: {"margin-right":"20px"},
                          key: i.cms_user_id
                        }, [
                          _createVNode(_component_a_switch, {
                            checkedValue: $setup.configData.role_user_page.employee.includes(i.cms_user_id) ? false : true,
                            onChange: ($event: any) => ($setup.selectEmployee(i.cms_user_id)),
                            size: "small"
                          }, null, 8, ["checkedValue", "onChange"]),
                          _createVNode(_component_a_typography_text, { style: {"margin-left":"10px"} }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(_ctx.$t(i.name)), 1)
                            ]),
                            _: 2
                          }, 1024)
                        ]))
                      }), 128))
                    ]),
                    _: 1
                  }))
                : _createCommentVNode("", true),
              _createVNode(_component_a_row, null, {
                default: _withCtx(() => [
                  _createVNode(_component_a_switch, {
                    checked: $setup.configData.conversation_share.state,
                    "onUpdate:checked": _cache[2] || (_cache[2] = ($event: any) => (($setup.configData.conversation_share.state) = $event))
                  }, null, 8, ["checked"]),
                  _createVNode(_component_a_typography_text, { style: {"margin-left":"10px"} }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.$t('assign_conversations_to_employee')), 1)
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }),
              ($setup.configData.conversation_share.state)
                ? (_openBlock(), _createBlock(_component_a_form, { key: 1 }, {
                    default: _withCtx(() => [
                      _createVNode(_component_a_form_item, {
                        label: _ctx.$t('messenger_windows')
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_a_select, {
                            value: $setup.conversation_share_inbox,
                            "onUpdate:value": _cache[3] || (_cache[3] = ($event: any) => (($setup.conversation_share_inbox) = $event)),
                            mode: "tags",
                            style: {"width":"100%"},
                            placeholder: "Tags Mode",
                            onChange: $setup.handleChangeConversationShareInbox
                          }, {
                            default: _withCtx(() => [
                              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.dataEmployee.employees, (employee) => {
                                return (_openBlock(), _createBlock(_component_a_select_option, {
                                  key: employee.cms_user_id,
                                  value: employee.cms_user_id
                                }, {
                                  default: _withCtx(() => [
                                    _createTextVNode(_toDisplayString(employee.name), 1)
                                  ]),
                                  _: 2
                                }, 1032, ["value"]))
                              }), 128))
                            ]),
                            _: 1
                          }, 8, ["value", "onChange"])
                        ]),
                        _: 1
                      }, 8, ["label"]),
                      _createVNode(_component_a_form_item, {
                        label: _ctx.$t('comment')
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_a_select, {
                            value: $setup.conversation_share_comment,
                            "onUpdate:value": _cache[4] || (_cache[4] = ($event: any) => (($setup.conversation_share_comment) = $event)),
                            mode: "tags",
                            style: {"width":"100%"},
                            placeholder: "Tags Mode",
                            onChange: $setup.handleChangeConversationShareComment
                          }, {
                            default: _withCtx(() => [
                              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.dataEmployee.employees, (employee) => {
                                return (_openBlock(), _createBlock(_component_a_select_option, {
                                  key: employee.cms_user_id,
                                  value: employee.cms_user_id
                                }, {
                                  default: _withCtx(() => [
                                    _createTextVNode(_toDisplayString(employee.name), 1)
                                  ]),
                                  _: 2
                                }, 1032, ["value"]))
                              }), 128))
                            ]),
                            _: 1
                          }, 8, ["value", "onChange"])
                        ]),
                        _: 1
                      }, 8, ["label"]),
                      _createVNode(_component_a_row, null, {
                        default: _withCtx(() => [
                          _createVNode(_component_a_switch, {
                            checked: $setup.configData.hide_conversation_employee,
                            "onUpdate:checked": _cache[5] || (_cache[5] = ($event: any) => (($setup.configData.hide_conversation_employee) = $event)),
                            size: "small"
                          }, null, 8, ["checked"]),
                          _createVNode(_component_a_typography_text, { style: {"margin-left":"10px"} }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(_ctx.$t('hide_conversations_employee')), 1)
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      }),
                      _createElementVNode("div", _hoisted_6, [
                        ($setup.configData.hide_conversation_employee)
                          ? (_openBlock(), _createBlock(_component_a_row, {
                              key: 0,
                              style: {"margin-bottom":"20px","margin-top":"20px"}
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_a_switch, {
                                  checked: $setup.configData.hide_conversation,
                                  "onUpdate:checked": _cache[6] || (_cache[6] = ($event: any) => (($setup.configData.hide_conversation) = $event)),
                                  size: "small"
                                }, null, 8, ["checked"]),
                                _createVNode(_component_a_typography_text, { style: {"margin-left":"10px"} }, {
                                  default: _withCtx(() => [
                                    _createTextVNode(_toDisplayString(_ctx.$t('hide_conversations_with_unassign')), 1)
                                  ]),
                                  _: 1
                                })
                              ]),
                              _: 1
                            }))
                          : _createCommentVNode("", true)
                      ]),
                      _createVNode(_component_a_row, null, {
                        default: _withCtx(() => [
                          _createVNode(_component_a_switch, {
                            checked: $setup.configData.first_reply_assignee,
                            "onUpdate:checked": _cache[7] || (_cache[7] = ($event: any) => (($setup.configData.first_reply_assignee) = $event)),
                            size: "small"
                          }, null, 8, ["checked"]),
                          _createVNode(_component_a_typography_text, { style: {"margin-left":"10px"} }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(_ctx.$t('first_reply_assignee')), 1)
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }))
                : _createCommentVNode("", true)
            ]),
            _: 1
          })
        ]))
      : _createCommentVNode("", true)
  ], 64))
}