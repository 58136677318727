<template>
  <div class="container-title">
    <h3 class="title-header">{{$t($route.name)}}</h3>

<!--      <a-button @click="rollback" style="margin-right: 20px" >-->
<!--        <template #icon><rollback-outlined /> </template>-->
<!--      </a-button>-->

<router-link to="/ticket/edit-ticket/:id">

</router-link>

    <a-popconfirm  placement="leftTop"  :title="$t('datamodal_select')" >
     <template #cancelButton>
       <router-link to="/mail_editor/default">
         <a-button  >{{$t('TEMPLATE_DEFAULT')}}</a-button>
       </router-link>

     </template>
      <template #okButton>
        <router-link to="/mail_editor/:id">
          <a-button  >{{$t('TEMPLATE_NEW')}}</a-button>
        </router-link>
      </template>
      <a-button  type="primary">{{$t('ADD')}}</a-button>
    </a-popconfirm>

  </div>
  <div style="display: flex;flex-direction: column" class="container-content">
    <a-row>
          <a-card v-for="i in templates.templates" :key="i" hoverable style="width:580px;margin:20px;height: 400px ">
            <template #actions>
              <a-popconfirm
                  :title="$t('TEMPLATE_CONFIRM_DELETE_CONTEXT')"
                  :ok-text="$t('YES')"
                  :cancel-text="$t('NO')"
                  @confirm="deleteTemplate(i)"
              >
                <delete-outlined key="delete"/>
              </a-popconfirm>
<!--              <edit-outlined @click="editEvent(i)"  key="edit" />-->
<!--              <ellipsis-outlined @click="openNotification" key="ellipsis" />-->
            </template>
            <a-card-meta @click="editTemplate(i.id)"  >
              <template #title>
                {{i.name}}
              </template>
              <template #description>
                <div style="height:300px;overflow-y:scroll"   v-html="formatBase64(i.content)" ref="template">
                </div>
              </template>
            </a-card-meta>
          </a-card>
    </a-row>
    <a-row style="justify-content: flex-end">
      <a-pagination @change="changePagination" style="margin: 20px" v-model:current="page" :total="templates.metadata.record_total" show-less-items />
    </a-row>
  </div>
</template>
<script lang="ts">
import {computed, onMounted, ref} from "vue";
import APIs from "@/APIs/APIs";
import {useStore} from "vuex";
import {TicketActionTypes} from "@/store/modules/Ticket/action-types";
import {DeleteOutlined, EditOutlined, EllipsisOutlined,RollbackOutlined} from "@ant-design/icons-vue";
import ImageSelectionModal from "@/components/Image/ImageSelectionModal.vue";
import {message} from "ant-design-vue";
import {useRouter} from "vue-router";
// @ts-ignore
import { Base64 } from 'js-base64';
import {useI18n} from "vue-i18n";
export default {
  components: {
    DeleteOutlined,
    // RollbackOutlined
    // EditOutlined,
    // EllipsisOutlined,
  },
  setup(){
    const store = useStore()
    const templates = computed(()=> store.getters.getTicketTemplate)
    const router = useRouter()
    const page = ref(1)
    const getUserInfo =computed(()=> store.getters.getUserInfo)
    const template = ref()
    const i18n = useI18n()
    const fetchTemplates =  () => {
      store.dispatch(TicketActionTypes.FETCH_TICKET_TEMPLATE,{page:page.value,page_size:20,search:'',shop_id:getUserInfo.value.current_shop_id})
    }
    const changePagination = (data:any) => {
      page.value = data
      fetchTemplates()
    }
    const rollback = ( )=>{
      router.back()
    }
    const formatBase64 = (str:any) => {
      return Base64.decode(str)
    }
    const deleteTemplate = async (data:any) =>{
      const res = await  APIs.init().deleteTicketTemplate({id:data.id})
      if(res.data?.status === 200){
        message.success(i18n.t('alert_delete_data_success'))
        fetchTemplates()
      }
    }
    const getHtml = (html:string) => {
       console.log(html)
    }
   const editTemplate =  (id:number) => {
      router.replace('/mail_editor/'+id)
   }
    onMounted(() => {
      fetchTemplates()

    })
    return{
      templates,
      page,
      changePagination,
      deleteTemplate,
      rollback,
      template,
      getHtml,
      formatBase64,
      editTemplate
    }
  }
}
</script>
<style scoped>

</style>
