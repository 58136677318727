import {ActionContext, ActionTree} from "vuex";
import {State} from "./states";
import {CallBackPayload, RootState} from "@/store";
import {TriggerActionTypes} from "./action-types";
import APIs from "@/APIs/APIs";
import {TriggerMutationTypes} from "./mutation-types";
import {Mutations} from "./mutations";
import {
    TriggersResponse,
    TriggerStatisticErrorLogResponse,
    TriggerStatisticResponse, ViralResponse
} from "@/APIs/response/S_HttpResponse";
import {InnerTriggerStatisticRequest, TriggerRequest, TriggerStatisticRequest} from "@/APIs/request/TriggerRequest";
import {Trigger} from "@/models/Trigger/Trigger";
import {TriggerStatistic, TriggerStatisticErrorLog} from "@/models/Trigger/TriggerHistory";

type AugmentedActionContext = {
    commit<K extends keyof Mutations>(
        key: K,
        payload: Parameters<Mutations[K]>[1],
    ): ReturnType<Mutations[K]>;
} & Omit<ActionContext<State, RootState>, 'commit'>

export interface Actions {
    [TriggerActionTypes.FETCH_TRIGGERS](
        {commit}: AugmentedActionContext,
        payload: TriggerRequest,
    ): Promise<TriggersResponse | undefined>;
    [TriggerActionTypes.FETCH_TRIGGER_STATISTICS](
        {commit}: AugmentedActionContext,
        payload: CallBackPayload<TriggerStatisticRequest, TriggerStatistic[]>,
    ): Promise<TriggerStatisticResponse | undefined>;
    [TriggerActionTypes.FETCH_INNER_TRIGGER_STATISTICS](
        {commit}: AugmentedActionContext,
        payload: CallBackPayload<InnerTriggerStatisticRequest, TriggerStatistic[]>,
    ): Promise<TriggerStatisticResponse | undefined>;
    [TriggerActionTypes.FETCH_TRIGGER_QUICK_MESSAGES](
        {commit}: AugmentedActionContext,
        payload: CallBackPayload<TriggerRequest, Trigger[]>,
    ): Promise<TriggersResponse | undefined>;
    [TriggerActionTypes.FETCH_TRIGGER_STATISTIC_LOGS](
        {commit}: AugmentedActionContext,
        payload: CallBackPayload<InnerTriggerStatisticRequest, TriggerStatisticErrorLog[]>,
    ): Promise<TriggerStatisticErrorLogResponse | undefined>;
    [TriggerActionTypes.FETCH_Viral](
        {commit}: AugmentedActionContext,
        payload: TriggerStatisticRequest,
    ): Promise<ViralResponse | undefined>;
}

export const actions: ActionTree<State, RootState> & Actions = {
    async [TriggerActionTypes.FETCH_TRIGGERS]({commit}, payload: TriggerRequest): Promise<TriggersResponse | undefined> {
        const response = await APIs.init().getTriggers(payload)
        if (response.status === 200) {
            commit(TriggerMutationTypes.SET_Triggers, response.data?.data)
            commit(TriggerMutationTypes.SET_TRIGGER_METADATA, response.data?.meta_data)
        }
        return response.data;
    },
    async [TriggerActionTypes.FETCH_TRIGGER_STATISTICS]({commit}, payload: CallBackPayload<TriggerStatisticRequest, TriggerStatistic[]>): Promise<TriggerStatisticResponse | undefined> {
        let isCalled=false
        if (payload.payload){
            const response = await APIs.init().getTriggerStatistics(payload.payload)
            if (response.status === 200) {
                commit(TriggerMutationTypes.SET_TRIGGER_STATISTICS, response.data?.data)
                commit(TriggerMutationTypes.SET_TRIGGER_STATISTIC_METADATA, response.data?.meta_data)
                if (response.data&&response.data.data){
                    if (payload.callback){
                        isCalled=true
                        payload.callback(response.data.data)
                    }
                }
            }
        }

        if (payload.callback){
            if (isCalled){
                payload.callback([])
            }
        }
        return undefined;
    },
    async [TriggerActionTypes.FETCH_INNER_TRIGGER_STATISTICS]({commit}, payload: CallBackPayload<InnerTriggerStatisticRequest, TriggerStatistic[]>): Promise<TriggerStatisticResponse | undefined> {
        let isCalled=false
        if (payload.payload){
            const response = await APIs.init().getInnerTriggerStatistics(payload.payload)
            if (response.status === 200) {
                commit(TriggerMutationTypes.SET_INNER_TRIGGER_STATISTICS, response.data?.data)
                commit(TriggerMutationTypes.SET_INNER_TRIGGER_STATISTIC_METADATA, response.data?.meta_data)
                if (payload.callback&&response.data&&response.data.data)
                {
                    isCalled=true
                    payload.callback(response.data.data)
                }
            }
            return response.data;
        }
        if (payload.callback){
            if (!isCalled){
                payload.callback([])
            }
        }
        return undefined

    },
    async [TriggerActionTypes.FETCH_TRIGGER_QUICK_MESSAGES]({commit}, payload: CallBackPayload<TriggerRequest, Trigger[]>): Promise<TriggersResponse | undefined> {
        let isCalled = false
        if (payload.payload) {
            const response = await APIs.init().getTriggersQuickMessages(payload.payload)
            if (response.status === 200 && response.data && response.data.data) {
                commit(TriggerMutationTypes.SET_TRIGGER_QUICK_MESSAGES, response.data.data)
                if (payload.callback){
                    {
                        isCalled=true
                        payload.callback(response.data.data)
                    }
                }

                    }
            return response.data;
        }
        if (payload.callback){
            if (!isCalled){
                payload.callback([])
            }
        }
        return undefined
    },

    async [TriggerActionTypes.FETCH_TRIGGER_STATISTIC_LOGS]({commit}, payload: CallBackPayload<InnerTriggerStatisticRequest, TriggerStatisticErrorLog[]>): Promise<TriggerStatisticErrorLogResponse | undefined> {
        let isCalled=false
        if (payload.payload){
            const response = await APIs.init().getTriggerStatisticLogs(payload.payload)
            if (response.status === 200&&response.data) {
                commit(TriggerMutationTypes.SET_TRIGGER_STATISTICS_LOG, response.data.data)
                if (payload.callback&&response.data&&response.data)
                {
                    isCalled=true
                    payload.callback(response.data.data)
                }
            }
            return response.data;
        }
        if (payload.callback){
            if (!isCalled){
                payload.callback([])
            }
        }
        return undefined

    },
    async [TriggerActionTypes.FETCH_Viral]({commit}, payload: TriggerStatisticRequest): Promise<ViralResponse | undefined> {
        const response = await APIs.init().getViral(payload)
        if (response.status === 200) {
            commit(TriggerMutationTypes.SET_VIRAL, response.data?.data)
            commit(TriggerMutationTypes.SET_TRIGGER_METADATA, response.data?.meta_data)
        }
        return response.data;
    },
}
