<template>
  <div class="container-title">
    <h3 class="title-header">{{$t('TRANSPORTERS')}}</h3>
  </div>

  <div class="container-content">
  <a-card style="width: 100%">
      <a-tabs
        style="margin-top: 25px"
        v-model:activeKey="activeKey"
        :tab-position="tabPosition"
        :style="{ backgroundColor: '#fff' }"
        animated
      >
        <a-tab-pane key="1">
          <template #tab>
            <div class="shipping-left">
              <a-row class="shipping-left-content">
                <img
                    src="@/assets/icons/viettelpost.png"
                    class="logo-viettel-icon"
                />
                <span class="shipping-left-title">Viettel post</span>
              </a-row>
            </div>
          </template>

          <a-card class="card-ghtk-vt">
            <template #title>
              <span>
                <img
                  src="@/assets/icons/viettelpost.png"
                  class="logo-viettel-icon"
                />
                Viettel post
              </span>
            </template>
            <template #extra>
              <a-button
                  v-if="getMyRole(modules.transporter)?.is_edit"
                  type="primary" @click="updateViettel">
                <save-outlined />
                {{$t('SAVE')}}
              </a-button>
            </template>
            <a-form
                :labelCol="{style: {width: '150px'}}"
                :wrapper-col="{ span: 16 }"
            >
                <a-form-item
                    :label="$t('INPUT_USER_NAME')">
                  <a-input
                    v-model:value="viettelPost.username"
                    :placeholder="$t('INPUT_USER_NAME')"
                  />
                </a-form-item>
                <a-form-item
                    :label="$t('INPUT_PASSWORD')">
                  <a-input
                    v-model:value="viettelPost.password"
                    :placeholder="$t('INPUT_PASSWORD')"
                  />
                </a-form-item>
            </a-form>
          </a-card>
        </a-tab-pane>
        <a-tab-pane key="2">
          <template #tab>
            <a-row class="shipping-left-content">
              <span class="icon">
                               <img src="@/assets/icons/ghtk.png" class="logo-icon" />

              </span>
              <span class="shipping-left-title">Giao hàng tiết kiệm</span>
            </a-row>
          </template>
          <a-card class="card-ghtk-vt">
            <template #title>
              <span>
                <img src="@/assets/icons/ghtk.png" class="logo-icon" />
                Giao hàng tiết kiệm
              </span>
            </template>
            <template #extra>
              <a-button
                  v-if="getMyRole(modules.transporter)?.is_edit"
                  type="primary" @click="updateGhtk">
                <save-outlined />
                {{$t('SAVE')}}
              </a-button>
            </template>
            <a-form
                :labelCol="{style: {width: '150px'}}"
                :wrapper-col="{ span: 16 }"
            >
                <a-form-item :label="$t('INPUT_USER_NAME')">
                  <a-input
                    v-model:value="ghtk.username"
                    :placeholder="$t('INPUT_USER_NAME')"
                  />
                </a-form-item>
                <a-form-item :label="$t('INPUT_PASSWORD')">
                  <a-input
                    v-model:value="ghtk.password"
                    :placeholder="$t('INPUT_PASSWORD')"
                  />
                </a-form-item>
            </a-form>
          </a-card>
        </a-tab-pane>
        <a-tab-pane key="3">
          <template #tab>
            <a-row class="shipping-left-content">
              <span class="icon">
                              <img src="@/assets/icons/ghn.png" class="logo-ghn-icon" />

              </span>

              <span class="shipping-left-title">Giao hàng nhanh</span>
            </a-row>
          </template>
          <a-card class="card-ghn">
            <template #title>
              <span>
                <img src="@/assets/icons/ghn.png" class="logo-ghn-icon" />
                Giao hàng nhanh (GHN)
              </span>
            </template>
            <template #extra>
              <a-button
                  v-if="getMyRole(modules.transporter)?.is_edit"
                  type="primary" @click="updateGhn">
                <save-outlined />
                {{$t('SAVE')}}
              </a-button>
            </template>
            <a-form
                :labelCol="{style: {width: '150px'}}"
                :wrapper-col="{ span: 16 }"
            >
                  <a-form-item :label="$t('phone_number') ">
                    <a-input
                      v-model:value="ghn.tel"
                      :placeholder="$t('phone_number')"
                    />
                  </a-form-item>
                  <a-form-item label="Token">
                    <a-input v-model:value="ghn.token" placeholder="Token" />
                  </a-form-item>
                  <a-form-item :label="$t('id_shop')">
                    <a-input v-model:value="ghn.shopid" :placeholder="$t('id_shop')" />
                  </a-form-item>
                  <a-form-item label="User Id">
                    <a-input v-model:value="ghn.userid" placeholder="User Id" />
                  </a-form-item>
            </a-form>
          </a-card>
        </a-tab-pane>
        <a-tab-pane key="4">
          <template #tab>
            <a-row class="shipping-left-content">
              <span class="icon">
                              <img src="@/assets/icons/best.png" class="logo-best-icon" />

              </span>

              <span class="shipping-left-title">Best Express</span>
            </a-row>
          </template>
          <a-card class="card-best">
            <template #title>
              <span>
                <img src="@/assets/icons/best.png" class="logo-best-icon" />
                Best Express
              </span>
            </template>
            <template #extra>
              <a-button
                  v-if="getMyRole(modules.transporter)?.is_edit"
                  type="primary" @click="updateBestExpress">
                <save-outlined />
                {{$t('SAVE')}}
              </a-button>
            </template>
            <a-form
                :labelCol="{style: {width: '150px'}}"
                :wrapper-col="{ span: 16 }"
            >
              <a-form-item :label="$t('username') ">
                <a-input
                    v-model:value="best.username"
                    :placeholder="$t('username')"
                />
              </a-form-item>
              <a-form-item :label="$t('password')">
                <a-input v-model:value="best.password"/>
              </a-form-item>
            </a-form>
          </a-card>
        </a-tab-pane>
      </a-tabs>
    </a-card>
  </div>
</template>

<script lang="ts">
import { computed, onMounted, reactive, ref } from "vue";
import { SaveOutlined } from "@ant-design/icons-vue";

import { useStore } from "vuex";
import { Transporter } from "@/models/Transporter/Transporter";
import { TransporterActionTypes } from "@/store/modules/transporter/action-types";
import APIs from "@/APIs/APIs";
import {message} from "ant-design-vue";
import {Constant} from "@/utils/Constants";
import {useI18n} from "vue-i18n";

export default {
  components: {
    SaveOutlined,
  },

  setup: function () {
    const fetchData = () => {
      store.dispatch(TransporterActionTypes.FETCH_TRANSPORTER);
    };
    const i18n = useI18n()
    const store = useStore();
    const shopid = computed(() => store.getters.getCurrentShop.id)
    const viettelPost = reactive({
      username: "",
      password: "",
    });
    const ghtk = reactive({
      username: "",
      password: "",
    });
    const ghn = reactive({
      tel: "",
      token: "",
      userid: 0,
      shopid: shopid.value,
    });
    const best = reactive({
      username: "",
      password: "",
    });


    const data = computed(() => store.getters.getTransporter);

    const getAll = () => {
      for (let i = 0; i < data.value.length; i++) {
        if (data.value[i].transporter_name === "viettel") {
          viettelPost.username = data.value[i].username;
          viettelPost.password = data.value[i].password;
        } else if (data.value[i].transporter_name === "ghtk") {
          ghtk.username = data.value[i].username;
          ghtk.password = data.value[i].password;
        } else if (data.value[i].transporter_name === "ghn") {
          ghn.tel = data.value[i].tel;
          ghn.token = data.value[i].token;
          ghn.userid = data.value[i].id;
          ghn.shopid = data.value[i].shop_id;
        } else if (data.value[i].transporter_name === "best") {
          best.username = data.value[i].username;
          best.password = data.value[i].password;
        }
      }
    };

    const updateViettel = async () => {

      try {
        const connect = await APIs.init().shippingConnect({
          shop_id: shopid.value,
          username: viettelPost.username,
          password: viettelPost.password,
          provider: "VIETTEL"
        })
        if(connect.data?.status === 200){
          if(connect.data.message === "OK"){
            const res = await APIs.init().updateTransporter({
              transporters: [{
                id:0,
                transporter_name: "viettel",
                shop_id: shopid.value,
                username: viettelPost.username,
                password: viettelPost.password,
                tel: '' ,
                email: '',
                token: '',
                shop_name: '',
                code: '',
                updated_at: '',
              }],
            });
            if (res.status === 200) {
              fetchData();
              message.success(i18n.t('SUCCESS'))
            }
          }else {
            message.error("Tài khoản và mật khẩu không đúng.Vui lòng nhập lại TK hoặc mật khẩu")
          }

        }else {
          message.error("Vui lòng nhập lại TK hoặc mật khẩu")
        }

      } catch (e) {
        console.log(e);
      }
    };

    const updateBestExpress = async () => {

      try {
        const connect = await APIs.init().shippingConnect({
          shop_id: shopid.value,
          username: best.username,
          password: best.password,
          provider: "BEST"
        })
        if(connect.data?.status === 200){
            const res = await APIs.init().updateTransporter({
              transporters: [{
                id:0,
                transporter_name: "viettel",
                shop_id: shopid.value,
                username: viettelPost.username,
                password: viettelPost.password,
                tel: '' ,
                email: '',
                token: '',
                shop_name: '',
                code: '',
                updated_at: '',
              }],
            });
            if (res.status === 200) {
              fetchData();
              message.success(i18n.t('SUCCESS'))
            }
        }else {
          message.error("Vui lòng nhập lại TK hoặc mật khẩu")
        }

      } catch (e) {
        console.log(e);
      }
    };
    const updateGhtk = async () => {
      try {
        const res = await APIs.init().updateTransporter({
          transporters: [{
            id: 0,
            transporter_name: "ghtk",
            shop_id: shopid.value,
            username: ghtk.username,
            password: ghtk.password,
            tel: '',
            email: '',
            token: '',
            shop_name: '',
            code: '',
            updated_at: '',
          }],
        });
        if (res.status === 200) {
          fetchData();
          message.success(i18n.t('SUCCESS'))
        }
      } catch (e) {
        console.log(e);
      }
    };
    const updateGhn = async () => {

      try {
        const res = await APIs.init().updateTransporter({
          transporters: [{
            id:ghn.userid,
            transporter_name: "ghn",
            shop_id: ghn.shopid,
            username: ghtk.username,
            password: ghtk.password,
            tel: ghn.tel,
            email: '',
            token: ghn.token,
            shop_name: '',
            code: '',
            updated_at: '',
          }],
        });
        if (res.status === 200) {
          fetchData()
          message.success(i18n.t('SUCCESS'))
        }
      } catch (e) {
        console.log(e);
      }
    };

    onMounted(() => {
      fetchData()
      getAll();


    });

    const getUserInfo = computed(() => store.getters.getUserInfo)

    const getMyRole = computed(() => {
      if(getUserInfo.value?.id_cms_privileges==null ||  getUserInfo.value?.id_cms_privileges<11){
        return ()=>({
          is_visible: 1,
          is_create:1,
          is_read: 1,
          is_edit: 1,
          is_delete: 1,
          id: 1
        })
      }else{
        return store.getters.getRole
      }
    });
    const modules = Constant.MODULES;

    const tabPosition = ref("left");
    const activeKey = ref("1");
    return {
      getMyRole,
      modules,
      tabPosition,
      activeKey,
      SaveOutlined,
      viettelPost,
      ghtk,
      ghn,
      best,
      data,
      getAll,
      updateViettel,
      updateGhtk,
      updateGhn,
      fetchData,
      updateBestExpress,
    };
  },
};
</script>

<style>
.title {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 22px;
  /* identical to box height, or 122% */
  margin-left: 19px;
  margin-top: 23px;
  text-align: center;

  color: #000000;
}

.shipping-left {
  width: 256px;
}

.shipping-left-content {
  height: 40px;
  align-items: center;
}

.icon {
  margin-right: 11.25px;
  margin-left: 16px;
}

.shipping-left-title {
  font-weight: bold;
  font-size: 14px;
}

/*.*/
.logo-viettel-icon {
  width: 38px;
}

/*@media only screen and (min-width: 768px) {*/
/*  !* For desktop: *!*/
/*  .card-ghtk-vt {*/
/*    width: 48%;*/
/*  }*/
/*}*/

/*@media only screen and (max-width: 500px) {*/
/*  .card-ghtk-vt {*/
/*    width: 100%;*/
/*  }*/
/*}*/

.card-ghtk-vt {
  /*width: 692px;*/
}

.card-ghn {
  /*width: 692px;*/
  margin-top: 18px;
}

.logo-icon {
  width: 20px;
  height: 20px;
}

.logo-ghn-icon {
  width: 28px;
}
.logo-best-icon {
  width: 28px;
}

.title-header {
  font-weight: bold;
  flex: 1;
}
.container-title{
  display: flex;
  flex-direction: row;
  padding: 16px 24px 16px 24px;
  margin-bottom: 25px;
  background-color: white
}
.input-search{
  width: 250px;
  margin-right: 16px;
}
.container-content{
  margin-left: 16px;
  margin-right: 16px;
}
.item{
  width: 250px;
}
</style>
