<template>
  <a-modal
      v-model:visible="visible"
      :footer="null"
      width="98%"
      :closable="true"
      class="modal"
  >
    <slot name="header">
      {{ $t("NEW_CAMPAIGN") }}
    </slot>
    <slot name="closeIcon" @click="visible = false">
      <div>
        <a-icon type="close-circle"/>
      </div>
    </slot>
    <div style="background-color: #F0F2F5"
    >

      <div class="container">
        <div class="row-horizontal">
          <a-button
              @click="onSubmit"
              type="primary">
            <save-outlined/>
            {{ $t("SAVE") }}
          </a-button>
        </div>
        <a-form
            ref="formRef"
            :model="formState"
            :rules="rules"
        >
          <a-row>
            <a-col :span="12">
              <a-card :bordered="false">
                <template #title>
                  <info-circle-outlined/>
                  {{ $t("INFO") }}
                </template>
                <a-form-item has-feedback :label="$t('CAMPAIGN_NAME')" name="inbox_name" :label-col="{ span: 5}"
                             :wrapper-col="{ span: 15,offset:2}">
                  <a-input v-model:value="formState.inbox_name" type="text"/>
                </a-form-item>
                <a-form-item :label="$t('SEND_BY_POST')" name="post" :label-col="{ span: 5}"
                             :wrapper-col="{ span: 15,offset:2}">
                  <a-select
                      v-model:value="value"
                      show-search
                      allowClear
                      label-in-value
                      :placeholder="$t('SELECT_POST')"
                      style="width: 100%"
                      :filter-option="false"
                      :not-found-content="fetching ? undefined : null"
                      :options="data"
                      @search="fetchPosts"
                      @change="handleChangePost"
                  >
                    <template v-if="fetching" #notFoundContent>
                      <a-spin size="small"/>
                    </template>
                  </a-select>
                </a-form-item>
              </a-card>
              <a-card class="expect-number-customer">
                <a-button
                    @click="expectNumberCustomer"
                    type="primary">
                  <template #icon>
                    <search-outlined/>
                  </template>
                  {{ $t("EXPECTED_OF_THE_NUMBER_OF_CUSTOMER") }}
                </a-button>
                <a-tag color="#87d068" style="margin-left: 30px">{{ formState.customer_filter_count }}
                  {{ $t("CUSTOMERS") }}
                </a-tag>
              </a-card>
            </a-col>
            <a-col :span="12">
              <a-card :bordered="false" style="margin-left: 10px;">
                <template #title>
                  <user-outlined/>
                  {{ $t("CUSTOMER_INFO") }}
                </template>
                <div class="customer-horizontal">
                  <div class="customer-info">
                    <a-form-item has-feedback :label="$t('PURCHASED_STATUS')" name="status" :label-col="{ span: 5}"
                                 :wrapper-col="{ span: 15,offset:2}">
                      <a-radio-group v-model:value="formState.filter_type">
                        <a-radio value="all">{{ $t('ALL') }}</a-radio>
                        <a-radio value="yes">{{ $t('PURCHASED') }}</a-radio>
                        <a-radio value="no">{{ $t('NOT_PURCHASED') }}</a-radio>
                      </a-radio-group>
                    </a-form-item>
                    <a-form-item has-feedback :label="$t('HAS_PHONE_NUMBER')" name="has_phone_number"
                                 :label-col="{ span: 5}"
                                 :wrapper-col="{ span: 15,offset:2}">
                      <a-radio-group v-model:value="formState.has_phone">
                        <a-radio value="all">{{ $t('ALL') }}</a-radio>
                        <a-radio value="true">{{ $t('YES') }}</a-radio>
                        <a-radio value="false">{{ $t('NO') }}</a-radio>
                      </a-radio-group>
                    </a-form-item>
                    <a-form-item :label="$t('INCLUDE_TAGS')" name="include_tag" :label-col="{ span: 5}"
                                 :wrapper-col="{ span: 15,offset:2}">
                      <a-select
                          mode="multiple"
                          ref="select"
                          v-model:value="formState.inbox_tag_list"
                      >
                        <a-select-option v-for="it in conversationTags()" :key="it.tag_id" :value="it.tag_id+''">
                          <a-tag :key="it.tag_id"
                                 :color="it.tag_color">{{ it.tag_name }}
                          </a-tag>
                        </a-select-option>
                      </a-select>
                    </a-form-item>
                    <a-form-item :label="$t('EXCLUDE_TAGS')" name="exclude_tag" :label-col="{ span: 5}"
                                 :wrapper-col="{ span: 15,offset:2}">
                      <a-select
                          mode="multiple"
                          ref="select"
                          v-model:value="formState.inbox_tag_blacklist_list"
                      >
                        <a-select-option v-for="it in conversationTags()" :key="it.tag_id" :value="it.tag_id+''">
                          <a-tag :key="it.tag_id"
                                 :color="it.tag_color">{{ it.tag_name }}
                          </a-tag>
                        </a-select-option>
                      </a-select>
                    </a-form-item>

                  </div>
                  <!--                    <div class="customer-info">-->
                  <!--                      <a-form layout="vertical">-->
                  <!--                        <a-form-item label="When the customer sends" class="form-vertical" name="when_customer_send">-->
                  <!--                          <a-select-->
                  <!--                              ref="select"-->
                  <!--                          >-->
                  <!--                            <a-select-option value="jack">Jack</a-select-option>-->
                  <!--                            <a-select-option value="lucy">Lucy</a-select-option>-->
                  <!--                            <a-select-option value="disabled" disabled>Disabled</a-select-option>-->
                  <!--                            <a-select-option value="Yiminghe">yiminghe</a-select-option>-->
                  <!--                          </a-select>-->
                  <!--                        </a-form-item>-->
                  <!--                        <a-form-item class="form-vertical" label="The last time the customer was sent" name="last_time_sent">-->
                  <!--                          <a-select-->
                  <!--                              ref="select"-->
                  <!--                          >-->
                  <!--                            <a-select-option value="jack">Jack</a-select-option>-->
                  <!--                            <a-select-option value="lucy">Lucy</a-select-option>-->
                  <!--                            <a-select-option value="disabled" disabled>Disabled</a-select-option>-->
                  <!--                            <a-select-option value="Yiminghe">yiminghe</a-select-option>-->
                  <!--                          </a-select>-->
                  <!--                        </a-form-item>-->
                  <!--                      </a-form>-->
                  <!--                    </div>-->

                </div>
              </a-card>

            </a-col>
          </a-row>
        </a-form>
        <a-form layout="vertical">
          <a-row>
            <a-col :span="18">
              <h3 class="title-new-auto-inbox">
                {{ $t('CREATE_NEW_CAMPAIGN') }}
              </h3>
              <a-card :bordered="false">
                <template #title>
                  <message-outlined/>
                  {{ $t('AUTO_INBOX_MESSAGE_TITLE') }}
                </template>
                <a-row>
                  <a-col :span="12" class="left-auto-message">
                    <a-form layout="vertical">
                      <a-form-item label="Message">
                        <a-textarea v-model:value="formState.inbox_content[0].content"
                                    :placeholder="$t('FLOW_VALIDATE_FACEBOOK_MESSAGE_CONTENT')"/>
                      </a-form-item>
                      <span class="ant-upload-picture-card-wrapper">
              <div class="ant-upload-list ant-upload-list-picture-card">
                <div class="ant-upload-list-picture-card-container"
                     v-if="formState.inbox_content.length>0 && formState.inbox_content[0].content_image"
                >
                  <span>
                    <div
                        class="ant-upload-list-item ant-upload-list-item-done ant-upload-list-item-list-type-picture-card">
                      <div class="ant-upload-list-item-info">
                        <span>
                          <a class="ant-upload-list-item-thumbnail"
                             target="_blank" rel="noopener noreferrer">
                            <img
                                :src="formState.inbox_content[0].content_image"
                                alt="image.png"
                                class="ant-upload-list-item-image">
                          </a>
                        </span>
                      </div>
                      <!--                      <span class="ant-upload-list-item-actions">-->
                      <!--                        <a-->
                      <!--                           target="_blank"-->
                      <!--                           rel="noopener noreferrer" title="Preview file">-->
                      <!--                          <eye- outlined/>-->
                      <!--                        </a>-->
                      <!--                        <delete-outlined />-->
                      <!--                      </span>-->
                    </div>
                  </span>
                </div>
              </div>

              <div class="ant-upload ant-upload-select ant-upload-select-picture-card">
                  <span role="button" tabindex="0" class="ant-upload">
                    <div
                        @click="showImageSelectionModal(1)"
                    >
                      <plus-outlined/>
                      <div class="ant-upload-text">{{ $t('UPLOAD') }}</div>
                    </div>
                  </span>
              </div>
            </span>
                      <a-form-item :label="$t('MESSAGE_TAG')">
                        <a-select
                            ref="select"
                            v-model:value="formState.message_tag"
                        >
                          <template v-for="tag in getAutoInboxMessageTag" :key="tag" :value="tag">
                            <a-select-option :value="tag">{{ tag }}</a-select-option>
                          </template>
                        </a-select>
                      </a-form-item>
                      <a-form-item>
                        <div class="button-share">
                          <a-input v-model:value="formState.postback_button" type="text" class="edit-text"
                                   :placeholder="$t('INPUT_BUTTON_NAME')" name="shareButton1"/>
                        </div>
                      </a-form-item>
                    </a-form>
                  </a-col>
                  <a-col :span="12" class="warning-right-auto-container">
                    <div class="warning-right-auto">
                      <warning-outlined class="warning-icon-auto"/>
                      <p>
                        {{ $t('AUTO_INBOX_WARNING_LABEL') }}
                      </p>
                    </div>
                    <p>
                      {{ $t('AUTO_INBOX_WARNING_MESSAGE_LABEL') }}
                      <a href="https://developers.facebook.com/policy#messengerplatform" target="_blank">
                        https://developers.facebook.com/policy#messengerplatform
                      </a>
                    </p>
                    <p>
                      {{ $t('AUTO_INBOX_WARNING_MESSAGE_TAG_LABEL') }}
                      <a href="https://developers.facebook.com/docs/messenger-platform/send-messages/message-tags/"
                         target="_blank">
                        https://developers.facebook.com/docs/messenger-platform/send-messages/message-tags/
                      </a>
                    </p>
                    <p>
                      {{ $t('AUTO_INBOX_WARNING_BUTTON_LABEL') }}
                    </p>
                  </a-col>
                </a-row>
              </a-card>
              <span class="next-message-container">
            <h3 class="title-new-auto-inbox title-next-message">
             {{ $t('NEXT_MESSAGES') }}
          </h3>
             <div class="note-next-message">{{ $t('RANDOM_1_IN_2') }}</div>
          </span>
              <a-row>
                <a-col :span="12" style="padding-right: 10px">
                  <a-card :bordered="false">
                    <template #title>
                      <message-outlined/>
                      {{ $t('AUTO_INBOX_NEXT_MESSAGE_GUIDE_LABEL') }}
                    </template>
                    <a-form layout="vertical">
                      <a-form-item :label="$t('MESSAGE')">
                        <a-textarea v-model:value="formState.postback_message[0].content"
                                    :placeholder="$t('INPUT_MESSAGE')"/>
                      </a-form-item>
                      <span class="ant-upload-picture-card-wrapper">
                                      <div class="ant-upload ant-upload-select ant-upload-select-picture-card">
                  <span role="button" tabindex="0" class="ant-upload">
                    <div
                        @click="showImageSelectionModal(2)"
                    >
                      <plus-outlined/>
                      <div class="ant-upload-text">{{ $t('UPLOAD') }}</div>
                    </div>
                  </span>

              </div>
                        <span>
                           <a-upload
                               list-type="picture"
                               v-model:file-list="fileList"
                               class="upload-list-inline"
                           >

                          </a-upload>
                        </span>

            </span>
                    </a-form>
                  </a-card>
                </a-col>
                <a-col :span="12" style="padding-left: 10px">
                  <a-card :bordered="false">
                    <template #title>
                      <message-outlined/>
                      {{ $t('AUTO_INBOX_NEXT_MESSAGE_GUIDE_LABEL') }}
                    </template>
                    <a-form layout="vertical">
                      <a-form-item :label="$t('MESSAGE')">
                        <a-textarea v-model:value="formState.postback_message[1].content"
                                    :placeholder="$t('INPUT_MESSAGE')"/>
                      </a-form-item>
                      <span class="ant-upload-picture-card-wrapper">
              <div class="ant-upload ant-upload-select ant-upload-select-picture-card">
                  <span role="button" tabindex="0" class="ant-upload">
                    <div
                        @click="showImageSelectionModal(3)"
                    >
                      <plus-outlined/>
                      <div class="ant-upload-text">{{ $t('UPLOAD') }}</div>
                    </div>
                  </span>
              </div>
                                                <span>
                           <a-upload
                               list-type="picture"
                               v-model:file-list="fileList1"
                               class="upload-list-inline"
                           >

                          </a-upload>
                        </span>

            </span>
                    </a-form>
                  </a-card>
                </a-col>
              </a-row>
            </a-col>
            <a-col :span="6" class="preview-col">
              <div class="preview-contain">
                <h3 class="title-flow-context">
                  {{ $t('PREVIEW') }}
                </h3>
                <div class="preview-container-body">
                  <div class="content-message">
                    <p style="white-space: pre-line;">{{ formState.inbox_content.length > 0 && formState.inbox_content[0].content }}</p>

                    <a-image
                        v-if="formState.inbox_content.length>0 && formState.inbox_content[0].content_image"
                        :src="formState.inbox_content[0].content_image"
                        width="100%"
                        style="object-fit: cover;"
                        height="180px"
                    />
                    <a-button>
                      {{ formState.postback_button }}
                    </a-button>
                  </div>

                </div>
              </div>
            </a-col>
          </a-row>
        </a-form>

      </div>
    </div>
    <image-selection-modal ref="imageSelectionRef" :onSelect="onImageSelect"/>
  </a-modal>
</template>

<script>
import {defineComponent, reactive, ref, toRefs} from "vue";
import ImageSelectionModal from "@/components/Image/ImageSelectionModal.vue";

import {
  InfoCircleOutlined,
  MessageOutlined,
  PlusOutlined,
  SaveOutlined,
  SearchOutlined,
  UserOutlined,
  WarningOutlined,
} from "@ant-design/icons-vue"
import APIs from "@/APIs/APIs";
import {UnwrapRef, watch} from "vue";
import {Ref} from "vue";
import {debounce} from "lodash-es";
import {ORDER_ENUM} from "@/APIs/request/AutoInboxRequest";
import {mapGetters, useStore} from "vuex";
import {MixUtils} from "@/utils/Utils";
import {AWS_DO_Model} from "@/models/AWS_DO/AWS_DO";
import {notification} from "ant-design-vue";
import {ValidateErrorEntity} from "ant-design-vue/lib/form/interface";

const formStateDefault = {
  id: '',
  page_id: '',
  "inbox_name": "",
  "customer_filter": {
    "status": "",
    "obj": {
      "total": 0,
      "totalBot": 0,
      "totalRemain": 0
    }
  },
  "customer_filter_type": 1,
  "customer_filter_desc": "Tất cả khách hàng",
  "customer_filter_count": 0,
  "inbox_schedule": "",
  "inbox_schedule_desc": "Now",
  "inbox_content": [
    {
      "content": "##full_name##",
      "content_image": ""
    }
  ],
  "status": 1,
  "inbox_schedule_type": 1,
  "description": "",
  "inbox_tag_list": [],
  "inbox_tag_blacklist_list": [],
  "inbox_tag": "",
  "inbox_tag_blacklist": "",
  "inbox_post": "",
  "file_url": "",
  "file_type": 0,
  "import_uids": "",
  "import_type": 0,
  "conversation_type": "",
  "from_date": "",
  "to_date": "",
  "limit": 0,
  "template_id": "",
  "postback_button": "",
  "postback_value": "",
  "postback_message": [
    {
      "content": "##full_name##",
      "content_image": "",
      "id": "",
      "postback_value": ""
    },
    {
      "content": "##full_name##",
      "content_image": "",
      "id": "eun42h44",
      "postback_value": ""
    },
    {
      "content": "##full_name##",
      "content_image": "",
      "id": "",
      "postback_value": ""
    }
  ],
  "message_tag": "CONFIRMED_EVENT_UPDATE",
  "has_phone": "all",
  "filter_type": "all"
}



export default defineComponent({
  name: "AutoInboxEdit",
  components: {
    InfoCircleOutlined,
    UserOutlined,
    SearchOutlined,
    MessageOutlined,
    WarningOutlined,
    PlusOutlined,
    SaveOutlined,
    ImageSelectionModal
  },
  computed: {
    ...mapGetters({
      getPages: 'getPages',
    }),
    getAutoInboxMessageTag() {
      return MixUtils.getAutoInboxMessageTag()
    }
  },
  props: ['onAutoInboxUpdated'],
  setup(props) {
    const store = useStore();
    const visible = ref(false)
    const modeView = ref(false)
    const formRef = ref();
    const currentPage = ref('')
    const expectCustomer = ref(0)
    const imageSelectionRef = ref()
    const formState = reactive({...formStateDefault});

    const rules = {
      inbox_name: [
        {required: true, message: 'Please input auto inbox name', trigger: 'blur'},
      ],
    };

    const fileList = ref([
    ]);
    const fileList1 = ref([
    ]);
    const onSubmit = () => {
      formRef.value
          .validate()
          .then(() => {
            formState.inbox_tag = formState.inbox_tag_list.join(',')
            formState.inbox_tag_blacklist = formState.inbox_tag_blacklist_list.join(',')
            formState.page_id = currentPage.value
            formState.postback_message[0].content_image = fileList.value.map(i => i.url).join(',')
            formState.postback_message[1].content_image = fileList1.value.map(i => i.url).join(',')
            console.log('onSubmit', formState)
            if (formState.id) {
              APIs.init().updateAutoInbox(formState).then(res => {
                console.log(res)
                if (res.status === 200 && res.data?.data) {
                  visible.value = false
                  notification.success({
                    duration: 2,
                    message: 'Information',
                    description:
                        `Auto Inbox ${res.data?.data.inbox_name} updated.`
                  });
                  props.onAutoInboxUpdated()
                }
              })
            } else {
              APIs.init().createAutoInbox(formState).then(res => {
                console.log(res)
                if (res.status === 200 && res.data?.data) {
                  visible.value = false
                  notification.success({
                    duration: 2,
                    message: 'Information',
                    description:
                        `Auto Inbox ${res.data?.data.inbox_name} created.`
                  });
                  props.onAutoInboxUpdated()
                }
              })
            }
          })
          .catch((error) => {
            console.log('error', error);
          });

    }

    const handleChangePost = (post) => {
      formState.inbox_post = post ? post.value : ''
    }
    const showModal = (autoInbox, pageId, view) => {
      console.log('autoInbox', autoInbox)
      currentPage.value = pageId
      modeView.value = view
      visible.value = true
      if (autoInbox !== 0) {
        APIs.init().getAutoInbox({id: autoInbox.id}).then(res => {
          if (res.status === 200 && res.data?.data) {
            Object.assign(formState, res.data?.data);
            try {
              formState.inbox_tag_list = res.data?.data.inbox_tag !== '' ? res.data?.data.inbox_tag?.split(',') : []
            } catch (e) {
              console.log(e)
            }
            try {
              formState.inbox_tag_blacklist_list = res.data?.data.inbox_tag_blacklist !== '' ? res.data?.data.inbox_tag_blacklist?.split(',') : []
            } catch (e) {
              console.log(e)
            }
            if (formState.filter_type === '')
              formState.filter_type = 'all'
          }
        })
      } else {
        Object.assign(formState, {...formStateDefault})
      }
    }
    const expectNumberCustomer = () => {
      APIs.init().getExpectNumberCustomer({
        page_id: currentPage.value,
        order: (formState.conversation_type === '' || formState.conversation_type === 'all') ?
            ORDER_ENUM.all :
            (formState.conversation_type === 'yes' ? ORDER_ENUM.yes : ORDER_ENUM.no),
        filter_tag: formState.inbox_tag_list.length > 0 ? formState.inbox_tag_list.join(',') : 'null',
        filter_tag_bl: formState.inbox_tag_blacklist_list.length > 0 ? formState.inbox_tag_blacklist_list.join(',') : 'null',
        filter_post: formState.inbox_post ? formState.inbox_post : 'null',
        has_phone: formState.has_phone ? formState.has_phone : 'null'
      }).then(res => {
        console.log(res)
        if (res.status === 200 && res.data?.data) {
          try {
            formState.customer_filter_count = res.data?.data?.obj?.total
          } catch (e) {
            console.log(e)
          }
        }
      })
    }

    const state = reactive({
      data: [],
      value: [],
      fetching: false,
    });

    const fetchPosts = debounce(value => {
      console.log('fetching user', value);
      state.data = [];
      state.fetching = true;
      APIs.init().getFacebookPost({page_id: currentPage.value, search: value}).then((res) => {
        console.log(res)
        const data = res.data.data.map((post) => ({
          label: post.message,
          value: post.id,
        }));
        state.data = data;
        state.fetching = false;
      })
    }, 300);

    watch(state.value, () => {
      state.data = [];
      state.fetching = false;
    });

    const conversationTags = () => {
      let tags = []
      try {
        tags = JSON.parse(store.getters.getPages.find(page => page.page_id === currentPage.value).config_data)?.tags;
      } catch (e) {
        console.log(e)
      }
      return tags
    }

    const showImageSelectionModal = (param) => {
      imageSelectionRef.value.showModal(param)
    }

    const onImageSelect = (images, param) => {
      console.log(param, images[0].url)
      switch (param) {
        case 1:
          formState.inbox_content[0].content_image = images[0].url
          break
        case 2:
          images.map(i =>{
            fileList.value.push({
              uid: MixUtils.methods.getUUID(),
              name: 'xxx.png',
              status: 'done',
              url: i.url,
              thumbUrl: i.url,
            })
          } )
          formState.postback_message[0].content_image = fileList.value.map(i => i.url).join(',')
            console.log(formState.postback_message[0])
          break
        case 3:
          images.map(i =>{
            fileList1.value.push({
              uid: MixUtils.methods.getUUID(),
              name: 'xxx.png',
              status: 'done',
              url: i.url,
              thumbUrl: i.url,
            })
          } )
          formState.postback_message[1].content_image = fileList1.value.map(i => i.url).join(',')
          console.log(formState.postback_message[1])
          break
      }
    }

    return {
      fileList,
      fileList1,
      visible,
      onSubmit,
      showModal,
      formState,
      rules,
      fetchPosts,
      handleChangePost,
      expectNumberCustomer,
      conversationTags,
      showImageSelectionModal,
      imageSelectionRef,
      expectCustomer,
      onImageSelect,
      modeView,
      formRef,
      ...toRefs(state),
    }
  }
})
</script>

<style lang="scss" scoped>
.ant-upload-list-picture-card-container {
  display: flex;
  flex-direction: row;
}

.container {
  padding: 10px 10px;
}

.modal {
  background-color: #F0F2F5;
}

.customer-horizontal {
  flex: 1;
  display: flex;
  flex-direction: row;

.customer-info {
  flex: 1;

.form-vertical {
  height: 55px;
}

}
}

.schedule-auto {
  margin-top: 10px;
}

.next-message-container {
  display: flex;
  align-items: center;
}

.note-next-message {
  font-size: 12px;
  font-style: italic;
  font-weight: normal;
  margin-left: 10px;
}

.title-new-auto-inbox {
  font-weight: bold;
  margin-top: 5px;
}

.preview-col2 {
  text-align: center;
}

.expect-number-customer {
  margin-top: 20px;
}

.row-2 {
  margin-top: 18px;
}

.left-auto-message {
  padding-right: 15px;
  border-right: darkgrey solid 1px;
}

.warning-right-auto-container {
  padding-left: 10px;

.warning-right-auto {
  display: flex;
  flex-direction: row;

.warning-icon-auto {
  font-size: 18px;
  color: red;
  margin-right: 10px;
}

}


}
.row-horizontal {
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;

.input-search {
  display: flex;
  flex: 0.2
}

}

.button-share {
  display: flex;
  justify-content: center;

.edit-text {
  padding: 6px 10px;
  text-align: center;
  border: 1px dashed #e5e7eb;
  width: 40%;
}

}
.title-next-message {
  margin-top: 20px;
}

.preview-col {

  padding-left: 15px;

.btn-publish-container {
  display: flex;
  margin-top: 10px;
}

.preview-contain {

.title-flow-context {
  text-align: center;
}

.preview-container-body {
  background-color: white;
  min-height: 480px;
  border-radius: 10px;
  padding: 15px;
}

.content-message {
  margin-right: 20px;
  padding: 15px;
  background-color: #E1E1E1;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  border-bottom-right-radius: 15px;
  display: flex;
  flex-direction: column;

.cover-btn {
  display: flex;
  justify-content: center;

.button-share {
  width: 150px;
}

}

}
}

}

/* tile uploaded pictures */
.upload-list-inline :deep(.ant-upload-list-item) {
  float: left;
  width: 200px;
  margin-right: 8px;
}
.upload-list-inline :deep(.ant-upload-animate-enter) {
  animation-name: uploadAnimateInlineIn;
}
.upload-list-inline :deep(.ant-upload-animate-leave) {
  animation-name: uploadAnimateInlineOut;
}

</style>