export type Tag = {
    id?: number;
    name?: string;
    text?: string;
    value?: number;
    description?: string;
    creator?: number;
    parent_id?: number;
    uses?: number;
    type?: string;
    shop_id?: number;
    color?: string;
    created_at?: string;
    updated_at?: string;
}
export type TreeTag=Tag&{
    children:Tag[]
}

export type TagForm = {
    id?: number;
    name?: string;
    type?: string;
    color?: string;
    description?: string;
    saleTag?: [];
}

export enum TypeTag {
    CUSTOMER_TAGS = "customer",
    FLOW_TAGS = "flow",
    TRIGGER_TAGS = "trigger",
    VIRAL_TAGS = "viral",
    AUTO_INBOX_TAGS = "auto_inbox",
    PRODUCT_TAGS = "product"
}
