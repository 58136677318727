import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-32eb0344"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "container-title" }
const _hoisted_2 = { class: "title-header" }
const _hoisted_3 = {
  style: {"display":"flex","flex-direction":"column"},
  class: "container-content"
}
const _hoisted_4 = ["src"]
const _hoisted_5 = { class: "inline-form-customer" }
const _hoisted_6 = {
  key: 0,
  style: {"text-align":"center"}
}
const _hoisted_7 = ["src"]
const _hoisted_8 = {
  key: 1,
  class: "upload-center"
}
const _hoisted_9 = {
  role: "button",
  tabindex: "0",
  class: "ant-upload"
}
const _hoisted_10 = { class: "ant-upload-text" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_button = _resolveComponent("a-button")!
  const _component_a_typography_text = _resolveComponent("a-typography-text")!
  const _component_a_row = _resolveComponent("a-row")!
  const _component_RouterLink = _resolveComponent("RouterLink")!
  const _component_delete_outlined = _resolveComponent("delete-outlined")!
  const _component_a_popconfirm = _resolveComponent("a-popconfirm")!
  const _component_edit_outlined = _resolveComponent("edit-outlined")!
  const _component_arrow_right_outlined = _resolveComponent("arrow-right-outlined")!
  const _component_BarChartOutlined = _resolveComponent("BarChartOutlined")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_a_card_meta = _resolveComponent("a-card-meta")!
  const _component_a_card = _resolveComponent("a-card")!
  const _component_a_popover = _resolveComponent("a-popover")!
  const _component_a_pagination = _resolveComponent("a-pagination")!
  const _component_plus_outlined = _resolveComponent("plus-outlined")!
  const _component_a_input = _resolveComponent("a-input")!
  const _component_a_form_item = _resolveComponent("a-form-item")!
  const _component_a_radio = _resolveComponent("a-radio")!
  const _component_a_radio_group = _resolveComponent("a-radio-group")!
  const _component_a_select_option = _resolveComponent("a-select-option")!
  const _component_a_select = _resolveComponent("a-select")!
  const _component_a_textarea = _resolveComponent("a-textarea")!
  const _component_a_range_picker = _resolveComponent("a-range-picker")!
  const _component_a_form = _resolveComponent("a-form")!
  const _component_a_modal = _resolveComponent("a-modal")!
  const _component_image_selection_modal = _resolveComponent("image-selection-modal")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("h3", _hoisted_2, _toDisplayString(_ctx.$t(_ctx.$route.name)), 1),
      _createVNode(_component_a_button, {
        onClick: _cache[0] || (_cache[0] = ($event: any) => ($setup.visible =  true)),
        type: "primary"
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.$t('ADD')), 1)
        ]),
        _: 1
      })
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createVNode(_component_a_row, null, {
        default: _withCtx(() => [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.events.events, (i) => {
            return (_openBlock(), _createBlock(_component_a_popover, {
              key: i,
              placement: "topRight"
            }, {
              content: _withCtx(() => [
                _createVNode(_component_a_row, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_a_typography_text, null, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.$t('start_time')) + " : " + _toDisplayString($setup.formatDate(i.start_time)), 1)
                      ]),
                      _: 2
                    }, 1024)
                  ]),
                  _: 2
                }, 1024),
                _createVNode(_component_a_row, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_a_typography_text, null, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.$t('end_date')) + " : " + _toDisplayString($setup.formatDate(i.end_time)), 1)
                      ]),
                      _: 2
                    }, 1024)
                  ]),
                  _: 2
                }, 1024)
              ]),
              title: _withCtx(() => [
                _createElementVNode("span", null, _toDisplayString(_ctx.$t('time_visit')), 1)
              ]),
              default: _withCtx(() => [
                _createVNode(_component_a_card, {
                  hoverable: "",
                  style: {"width":"400px","margin":"20px"}
                }, {
                  cover: _withCtx(() => [
                    _createVNode(_component_RouterLink, {
                      to: `/ticket/list-ticket/`+i.id
                    }, {
                      default: _withCtx(() => [
                        _createElementVNode("img", {
                          style: {"width":"400px","height":"400px"},
                          alt: "example",
                          src: i.image_cover
                        }, null, 8, _hoisted_4)
                      ]),
                      _: 2
                    }, 1032, ["to"])
                  ]),
                  actions: _withCtx(() => [
                    _createVNode(_component_a_popconfirm, {
                      title: _ctx.$t('EVENT_CONFIRM_DELETE_CONTEXT'),
                      "ok-text": _ctx.$t('YES'),
                      "cancel-text": _ctx.$t('NO'),
                      onConfirm: ($event: any) => ($setup.deleteEvent(i))
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_delete_outlined, { key: "delete" })
                      ]),
                      _: 2
                    }, 1032, ["title", "ok-text", "cancel-text", "onConfirm"]),
                    _createVNode(_component_edit_outlined, {
                      onClick: ($event: any) => ($setup.editEvent(i)),
                      key: "edit"
                    }, null, 8, ["onClick"]),
                    _createVNode(_component_RouterLink, {
                      to: `/ticket/transaction/`+i.id
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_arrow_right_outlined)
                      ]),
                      _: 2
                    }, 1032, ["to"]),
                    _createVNode(_component_router_link, {
                      to: '/ticket/report/'+i.id
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_BarChartOutlined)
                      ]),
                      _: 2
                    }, 1032, ["to"])
                  ]),
                  default: _withCtx(() => [
                    _createVNode(_component_RouterLink, {
                      to: `/ticket/list-ticket/`+i.id
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_a_card_meta, { style: {"text-align":"center","min-height":"161.11px"} }, {
                          title: _withCtx(() => [
                            _createTextVNode(_toDisplayString(i.name), 1)
                          ]),
                          description: _withCtx(() => [
                            _createTextVNode(_toDisplayString(i.description), 1)
                          ]),
                          default: _withCtx(() => [
                            _createTextVNode("> ")
                          ]),
                          _: 2
                        }, 1024)
                      ]),
                      _: 2
                    }, 1032, ["to"])
                  ]),
                  _: 2
                }, 1024)
              ]),
              _: 2
            }, 1024))
          }), 128))
        ]),
        _: 1
      }),
      _createVNode(_component_a_row, { style: {"justify-content":"flex-end"} }, {
        default: _withCtx(() => [
          _createVNode(_component_a_pagination, {
            onChange: $setup.changePagination,
            style: {"margin":"20px"},
            current: _ctx.page,
            "onUpdate:current": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.page) = $event)),
            total: $setup.events.metadata.record_total,
            "show-less-items": ""
          }, null, 8, ["onChange", "current", "total"])
        ]),
        _: 1
      })
    ]),
    _createVNode(_component_a_modal, {
      visible: $setup.visible,
      "onUpdate:visible": _cache[11] || (_cache[11] = ($event: any) => (($setup.visible) = $event)),
      title: $setup.checkEdit ? _ctx.$t('EDIT_EVENT'): _ctx.$t('CREATED_EVENT'),
      onOk: $setup.handleOk
    }, {
      default: _withCtx(() => [
        _createVNode(_component_a_form, {
          model: $setup.event,
          ref: "formCustomerRef",
          rules: _ctx.rules,
          layout: "vertical"
        }, {
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_5, [
              _createVNode(_component_a_row, { style: {"justify-content":"center"} }, {
                default: _withCtx(() => [
                  ($setup.event.image_cover)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                        _createElementVNode("img", {
                          src: $setup.event.image_cover,
                          class: "photo-flow-img",
                          onClick: _cache[2] || (_cache[2] = ($event: any) => ($setup.showImageSingleSelectionModal(_ctx.msIndex + '_' + 909)))
                        }, null, 8, _hoisted_7)
                      ]))
                    : (_openBlock(), _createElementBlock("span", _hoisted_8, [
                        _createElementVNode("div", {
                          class: "ant-upload ant-upload-select ant-upload-select-picture-card",
                          onClick: _cache[3] || (_cache[3] = ($event: any) => ($setup.showImageSingleSelectionModal(_ctx.msIndex + '_' + 909)))
                        }, [
                          _createElementVNode("span", _hoisted_9, [
                            _createElementVNode("div", null, [
                              _createVNode(_component_plus_outlined),
                              _createElementVNode("div", _hoisted_10, _toDisplayString(_ctx.$t("UPLOAD")), 1)
                            ])
                          ])
                        ])
                      ]))
                ]),
                _: 1
              }),
              _createVNode(_component_a_form_item, {
                label: _ctx.$t('EVENT_NAME'),
                name: "event_name"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_a_input, {
                    value: $setup.event.name,
                    "onUpdate:value": _cache[4] || (_cache[4] = ($event: any) => (($setup.event.name) = $event)),
                    placeholder: _ctx.$t('EVENT_NAME')
                  }, null, 8, ["value", "placeholder"])
                ]),
                _: 1
              }, 8, ["label"]),
              _createVNode(_component_a_form_item, {
                style: {"margin-right":"20px"},
                label: _ctx.$t('SELL_TICKET_TYPE')
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_a_radio_group, {
                    name: "radioGroup",
                    value: $setup.event.sell_ticket_type,
                    "onUpdate:value": _cache[5] || (_cache[5] = ($event: any) => (($setup.event.sell_ticket_type) = $event))
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_a_radio, { value: 0 }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.$t('ONE_TICKET')), 1)
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_a_radio, { value: 1 }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.$t('GROUP_TICKET')), 1)
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }, 8, ["value"])
                ]),
                _: 1
              }, 8, ["label"]),
              _createVNode(_component_a_form_item, {
                label: _ctx.$t('TEMPLATE'),
                name: "template_id"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_a_select, {
                    allowClear: "",
                    value: $setup.event.template_id,
                    "onUpdate:value": _cache[6] || (_cache[6] = ($event: any) => (($setup.event.template_id) = $event)),
                    placeholder: _ctx.$t('TEMPLATE')
                  }, {
                    default: _withCtx(() => [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.templates.templates, (item) => {
                        return (_openBlock(), _createBlock(_component_a_select_option, {
                          key: item.id,
                          value: item.id
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(item.name), 1)
                          ]),
                          _: 2
                        }, 1032, ["value"]))
                      }), 128))
                    ]),
                    _: 1
                  }, 8, ["value", "placeholder"])
                ]),
                _: 1
              }, 8, ["label"]),
              _createVNode(_component_a_form_item, {
                label: _ctx.$t('link'),
                name: "event_name"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_a_input, {
                    value: $setup.event.link,
                    "onUpdate:value": _cache[7] || (_cache[7] = ($event: any) => (($setup.event.link) = $event)),
                    placeholder: _ctx.$t('link')
                  }, null, 8, ["value", "placeholder"])
                ]),
                _: 1
              }, 8, ["label"]),
              _createVNode(_component_a_form_item, {
                label: _ctx.$t('payment_callback'),
                name: "event_name"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_a_input, {
                    value: $setup.event.payment_callback,
                    "onUpdate:value": _cache[8] || (_cache[8] = ($event: any) => (($setup.event.payment_callback) = $event)),
                    placeholder: _ctx.$t('payment_callback')
                  }, null, 8, ["value", "placeholder"])
                ]),
                _: 1
              }, 8, ["label"]),
              _createVNode(_component_a_form_item, {
                label: _ctx.$t('DESCRIPTION'),
                name: "phone"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_a_textarea, {
                    value: $setup.event.description,
                    "onUpdate:value": _cache[9] || (_cache[9] = ($event: any) => (($setup.event.description) = $event)),
                    placeholder: _ctx.$t('DESCRIPTION'),
                    "show-count": "",
                    maxlength: 1200
                  }, null, 8, ["value", "placeholder"])
                ]),
                _: 1
              }, 8, ["label"]),
              _createVNode(_component_a_form_item, {
                label: _ctx.$t('time_visit'),
                name: "birth"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_a_range_picker, {
                    value: $setup.value1,
                    "onUpdate:value": _cache[10] || (_cache[10] = ($event: any) => (($setup.value1) = $event)),
                    format: "DD-MM-YYYY HH:mm"
                  }, null, 8, ["value"])
                ]),
                _: 1
              }, 8, ["label"])
            ])
          ]),
          _: 1
        }, 8, ["model", "rules"])
      ]),
      _: 1
    }, 8, ["visible", "title", "onOk"]),
    _createVNode(_component_image_selection_modal, {
      ref: "imageSelectionRef",
      single: $setup.isImageSelectionSingle,
      onSelect: $setup.onImageSelect
    }, null, 8, ["single", "onSelect"])
  ], 64))
}