import { ActionContext, ActionTree, useStore } from "vuex";
import { State } from "./states";
import { RootState } from "@/store";
import APIs from "@/APIs/APIs";
import { ReportPAGEActionTypes } from "./action-types";
import { ReportPageMutationType } from "./mutation-types";
import { Mutations } from "./mutations";
import { ReportPageCommentResponse } from "@/APIs/response/S_HttpResponse";
import { ReportPageCommentRequest } from "@/APIs/request/Report_pageRequest";
import dayjs from "dayjs";

type AugmentedActionContext = {
  commit<K extends keyof Mutations>(
    key: K,
    payload: Parameters<Mutations[K]>[1]
  ): ReturnType<Mutations[K]>;
} & Omit<ActionContext<State, RootState>, "commit">;

export interface Actions {
  [ReportPAGEActionTypes.FETCH_REPORT_PAGE_COMMENT](
    { commit }: AugmentedActionContext,
    payload?: ReportPageCommentRequest
  ): Promise<ReportPageCommentResponse | undefined>;

  [ReportPAGEActionTypes.FETCH_REPORT_PAGE_MESSAGE](
    { commit }: AugmentedActionContext,
    payload?: ReportPageCommentRequest
  ): Promise<ReportPageCommentResponse | undefined>;

  [ReportPAGEActionTypes.FETCH_REPORT_PAGE_NEW_CONVERSATION](
    { commit }: AugmentedActionContext,
    payload?: ReportPageCommentRequest
  ): Promise<ReportPageCommentResponse | undefined>;
  [ReportPAGEActionTypes.FETCH_CUSTOMER_REPORT](
      { commit }: AugmentedActionContext,
      payload?: any
  ): Promise<any | undefined>;
  [ReportPAGEActionTypes.CUSTOMER_ORDER](
      { commit }: AugmentedActionContext,
      payload?: any
  ): Promise<any | undefined>;
  [ReportPAGEActionTypes.REVENUE](
      { commit }: AugmentedActionContext,
      payload?: any
  ): Promise<any | undefined>;
  [ReportPAGEActionTypes.EMPLOYEE](
      { commit }: AugmentedActionContext,
      payload?: any
  ): Promise<any | undefined>;
}
export const actions: ActionTree<State, RootState> & Actions = {
  async [ReportPAGEActionTypes.FETCH_REPORT_PAGE_COMMENT](
    { commit },
    payload: ReportPageRequest
  ): Promise<ReportPageCommentResponse | undefined> {
    const response = await APIs.init().getReportComment(payload);
    if (response.status === 200) {
      // @ts-ignore
      commit(ReportPageMutationType.GET_REPORT_PAGE_COMMENT, response.data);
    }
    return response.data;
  },
  async [ReportPAGEActionTypes.FETCH_REPORT_PAGE_MESSAGE](
    { commit },
    payload: ReportPageRequest
  ): Promise<ReportPageCommentResponse | undefined> {
    const response = await APIs.init().getReportMessage(payload);
    if (response.status === 200) {
      // @ts-ignore
      commit(ReportPageMutationType.GET_REPORT_PAGE_MESSAGE, response.data);
    }
    return response.data;
  },
  async [ReportPAGEActionTypes.FETCH_REPORT_PAGE_NEW_CONVERSATION](
    { commit },
    payload: ReportPageRequest
  ): Promise<ReportPageCommentResponse | undefined> {
    const response = await APIs.init().getReportNewConversation(payload);
    if (response.status === 200) {
      // @ts-ignore
      commit(ReportPageMutationType.GET_REPORT_PAGE_NEW_CONVERSATION, response.data);
    }
    return response.data;
  },
  async [ReportPAGEActionTypes.FETCH_CUSTOMER_REPORT](
      { commit },
      payload: any
  ): Promise<any | undefined> {
    const customerData:any =[]

    const getReportCustomerStat = await APIs.init().getReportCustomerStatDay(payload);
    // const getReportOrderConverSion = await APIs.init().getReportOderConverSion({...payload,interval:'day',previous:true,phone_agg:true,conversation_agg:true});
    // @ts-ignore
    getReportCustomerStat.data?.aggregations?.message?.conversation?.customers.buckets.map(b=> {
      customerData.push({
        time:dayjs.unix(b.key/1000).format('DD/MM/YYYY'),
        messages:0,
        comments:0,
        allMessage:0,
        customerMessages:0,
        totalMessage:0,
        totalComment:0,
        total:0
      })

    })

    // @ts-ignore
    getReportCustomerStat.data?.aggregations?.message?.conversation_message_new?.customers.buckets.map(b=> {
      for (let i= 0 ;i<customerData.length;i++){
        if(customerData[i].time===dayjs.unix(b.key/1000).format('DD/MM/YYYY')){
          customerData[i].conversationMessageNew = b.doc_count
          customerData[i].customerNew = b.customers.value
          customerData[i].order_from_new_customer = b.order_count?.value

          try{
            // @ts-ignore
            const orderFromCustomerNew =  getReportCustomerStat.data?.aggregations?.message.order.order.buckets.find(item=>item.key== b.key)
            // console.log('orderFromCustomerNew', orderFromCustomerNew)
            customerData[i].order_from_new_customer = orderFromCustomerNew?orderFromCustomerNew.doc_count:0
          }catch (e) {
            customerData[i].order_from_new_customer = 0
            console.log(e)
          }

        }
      }
    })
    // @ts-ignore
    getReportCustomerStat.data?.aggregations?.message?.message?.message?.buckets.map(b=> {
      for (let i= 0 ;i<customerData.length;i++){
        if(customerData[i].time===dayjs.unix(b.key/1000).format('DD/MM/YYYY')){
          customerData[i].messages = b.doc_count-b.comment.doc_count
              customerData[i].comments = b.comment.doc_count
              customerData[i].allMessage = b.doc_count
              customerData[i].customerMessages = b.customer_message?.customers_sum?.value
          customerData[i].customers=b.customer_message?.customers_sum?.value

          customerData[i].totalMessage = b.doc_count-b.comment.doc_count
          customerData[i].totalComment = b.comment.doc_count
          customerData[i].total = b.doc_count

          customerData[i].order_from_old_customer= b.order?.doc_count - customerData[i].order_from_new_customer


        }
      }
      // customerData.push({
      //   messages:b.doc_count-b.comment.doc_count,
      //   comments:b.doc_count-b.comment.doc_count,
      //   allMessage:b.doc_count,
      //   customerMessages:b.customer_message?.customers_sum?.value,
      //   totalMessage:b.doc_count-b.comment.doc_count,
      //   totalComment:b.comment.doc_count,
      //   total:b.doc_count
      //
      // })
    })
    // @ts-ignore
    getReportCustomerStat.data?.aggregations?.message?.conversation?.customers.buckets.map(b=> {

      for (let i = 0; i < customerData.length; i++) {
        if(customerData[i].time===dayjs.unix(b.key/1000).format('DD/MM/YYYY')){

          customerData[i].newPhones = b.phone.value
          customerData[i].customerOld = customerData[i].customers - customerData[i].customerNew

          customerData[i].order_from_old_customer_percent = customerData[i].order_from_old_customer  ? Math.round(( customerData[i].order_from_old_customer/customerData[i].customers)*100) :0
          customerData[i].order_from_new_customer_percent =customerData[i].order_from_new_customer  ?Math.round( ( customerData[i].order_from_new_customer/customerData[i].customers)*100) :0
        }
      }
    })
    // console.log(customerData)
    // @ts-ignore
    // console.log(getReportOrderConverSion.data.aggregations.order_time.buckets)
    // @ts-ignore
    // getReportOrderConverSion.data?.aggregations?.order_time?.buckets.map(b=> {
    //
    //   for (let i = 0; i < customerData.length; i++) {
    //     if(customerData[i].time===dayjs.unix(b.key/1000).format('DD/MM/YYYY')){
    //
    //       customerData[i].order_from_ads= b.order_from_ads.doc_count
    //       customerData[i].order_from_ads_percent = b.order_from_ads.doc_count && customerData[i].totalMessage ?  Math.floor(100/(customerData[i].totalMessage/b.order_not_ads.doc_count)) : 0
    //       customerData[i].order_not_ads= b.order_not_ads.doc_count
    //       customerData[i].order_not_ads_percent = b.order_not_ads.doc_count && customerData[i].totalMessage ? Math.floor(100/(customerData[i].totalMessage/b.order_not_ads.doc_count)) : 0
    //     }
    //   }
    // })

    // @ts-ignore
    if (getReportCustomerStat.status === 200) {
      // @ts-ignore
      commit(ReportPageMutationType.GET_REPORT_CUSTOMER, customerData);
    }
    // @ts-ignore
    return customerData;
  },
  async [ReportPAGEActionTypes.CUSTOMER_ORDER](
      { commit },
      payload: ReportPageRequest
  ): Promise<ReportPageCommentResponse | undefined> {
    const response = await APIs.init().reportCustomerOrder(payload);
    if (response.status === 200) {
      // @ts-ignore
      commit(ReportPageMutationType.GET_CUSTOMER_ORDER, response.data.data);
    }
    // @ts-ignore
    return response.data;
  },
  async [ReportPAGEActionTypes.REVENUE](
      { commit },
      payload: ReportPageRequest
  ): Promise<ReportPageCommentResponse | undefined> {
    const response = await APIs.init().reportRevenue(payload);
    if (response.status === 200) {
      const total:any ={
        count:0,
        count_return:0,
        count_success:0,
        ship_fee_collected:0,
        total_amount:0,
        total_amount_sale:0,
        total_goods_cost:0,
        total_shipfee:0,
        profit:0
      }
      // @ts-ignore
       response.data?.data.map(item => {

         total.count += item.count
         total.count_return += item.count_return
         total.count_success += item.count_success
         total.ship_fee_collected += item.ship_fee_collected
         total.total_amount += item.total_amount
         total.total_amount_sale += item.total_amount_sale
         total.total_goods_cost += item.total_goods_cost
         total.total_shipfee += item.total_shipfee
         total.profit += item.total_amount_sale-item.total_goods_cost
       })
      // @ts-ignore
      commit(ReportPageMutationType.GET_REVENUE, response.data.data);
      commit(ReportPageMutationType.GET_REVENUE_TOTAL, total);
    }
    // @ts-ignore
    return response.data;
  },
  async [ReportPAGEActionTypes.EMPLOYEE](
      { commit },
      payload: ReportPageRequest
  ): Promise<ReportPageCommentResponse | undefined> {
    const response = await APIs.init().reportEmployee(payload);
    if (response.status === 200) {
      const total:any ={
        count:0,
        total_discount:0,
        count_return:0,
        count_success:0,
        ship_fee_collected:0,
        total_amount:0,
        total_amount_sale:0,
        total_goods_cost:0,
        total_shipfee:0,
        profit:0,
        total_amount_success:0,
        total_amount_return:0,
        count_confirm:0,
        total_amount_confirm:0,
        count_cancel:0,
        total_amount_cancel:0
      }
      // @ts-ignore
      response.data?.data.map(item => {

        total.count += item.count
        total.total_discount+=item.total_discount
        total.count_return += item.count_return
        total.count_success += item.count_success
        total.ship_fee_collected += item.ship_fee_collected
        total.total_amount += item.total_amount
        total.total_amount_sale += item.total_amount_sale
        total.total_goods_cost += item.total_goods_cost
        total.total_shipfee += item.total_shipfee
        total.total_amount_success+= item.total_amount_success
            total.total_amount_return+= item.total_amount_return
            total.count_confirm+= item.count_confirm
            total.total_amount_confirm+= item.total_amount_confirm
            total.count_cancel+= item.count_cancel
            total.total_amount_cancel+= item.total_amount_cancel
        // total.profit += item.total_amount_sale-item.total_goods_cost
      })
      // @ts-ignore
      commit(ReportPageMutationType.GET_EMPLOYEE_REPORT, response.data.data);
      commit(ReportPageMutationType.GET_EMPLOYEE_TOTAL, total);
    }
    // @ts-ignore
    return response.data;
  },
};
