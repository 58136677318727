import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createVNode as _createVNode, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-ef1b6908"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "container-title" }
const _hoisted_2 = { class: "title-header" }
const _hoisted_3 = { class: "title-new-auto-inbox" }
const _hoisted_4 = ["onClick"]
const _hoisted_5 = { class: "title-new-auto-inbox" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_select_option = _resolveComponent("a-select-option")!
  const _component_a_select = _resolveComponent("a-select")!
  const _component_plus_outlined = _resolveComponent("plus-outlined")!
  const _component_a_button = _resolveComponent("a-button")!
  const _component_a_tag = _resolveComponent("a-tag")!
  const _component_eye_outlined = _resolveComponent("eye-outlined")!
  const _component_PauseCircleOutlined = _resolveComponent("PauseCircleOutlined")!
  const _component_PlayCircleOutlined = _resolveComponent("PlayCircleOutlined")!
  const _component_a_menu_item = _resolveComponent("a-menu-item")!
  const _component_edit_outlined = _resolveComponent("edit-outlined")!
  const _component_delete_outlined = _resolveComponent("delete-outlined")!
  const _component_a_menu = _resolveComponent("a-menu")!
  const _component_a_dropdown = _resolveComponent("a-dropdown")!
  const _component_a_table = _resolveComponent("a-table")!
  const _component_AutoInboxModal = _resolveComponent("AutoInboxModal")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("h3", _hoisted_2, _toDisplayString(_ctx.$t(_ctx.$route.name)), 1),
      _createVNode(_component_a_select, {
        style: {"margin-right":"16px"},
        value: _ctx.getCurrentPageId,
        "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.getCurrentPageId) = $event)),
        onChange: _ctx.changePage
      }, {
        default: _withCtx(() => [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.getPages, (item) => {
            return (_openBlock(), _createBlock(_component_a_select_option, {
              key: item.page_id,
              value: item.page_id
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(item.page_name), 1)
              ]),
              _: 2
            }, 1032, ["value"]))
          }), 128))
        ]),
        _: 1
      }, 8, ["value", "onChange"]),
      _createVNode(_component_a_button, {
        onClick: _ctx.createAutoInbox,
        type: "primary"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_plus_outlined),
          _createTextVNode(" " + _toDisplayString(_ctx.$t("NEW_AUTO_INBOX")), 1)
        ]),
        _: 1
      }, 8, ["onClick"])
    ]),
    _createVNode(_component_a_table, {
      size: "middle",
      scroll: { y: '40vh' },
      rowKey: (record) => record.id,
      columns: _ctx.columns,
      "data-source": _ctx.getAutoInboxData.autoInboxes,
      pagination: _ctx.pagination,
      onChange: _ctx.handleTableChange,
      loading: _ctx.getAutoInboxData.loading,
      class: "flow-table"
    }, {
      title: _withCtx(() => [
        _createElementVNode("h3", _hoisted_3, _toDisplayString(_ctx.$t("CAMPAIGN_LIST")), 1)
      ]),
      inbox_schedule_desc: _withCtx(({ record }) => [
        _createTextVNode(_toDisplayString(record.inbox_schedule_desc), 1)
      ]),
      inbox_name: _withCtx(({ record }) => [
        _createElementVNode("span", null, [
          _createElementVNode("a", {
            class: "table-row-name",
            type: "text",
            onClick: ($event: any) => (_ctx.customRowClick(record, false))
          }, _toDisplayString(record.inbox_name), 9, _hoisted_4)
        ])
      ]),
      customer_filter_count: _withCtx(({ text }) => [
        _createVNode(_component_a_tag, { color: "cyan" }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(text), 1)
          ]),
          _: 2
        }, 1024)
      ]),
      limit: _withCtx(({ text }) => [
        _createVNode(_component_a_tag, { color: "#f50" }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(text == 99999 ? _ctx.$t("UNLIMITED") : text), 1)
          ]),
          _: 2
        }, 1024)
      ]),
      status: _withCtx(({ text }) => [
        _createVNode(_component_a_tag, {
          color: _ctx.getViralStatusColor(text)
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.getViralStatusName(text)), 1)
          ]),
          _: 2
        }, 1032, ["color"])
      ]),
      created_at: _withCtx(({ text }) => [
        _createElementVNode("span", null, _toDisplayString(_ctx.dateTimeDisplayFormatFromString(text)), 1)
      ]),
      action: _withCtx(({ record }) => [
        _createVNode(_component_a_dropdown, null, {
          overlay: _withCtx(() => [
            _createVNode(_component_a_menu, null, {
              default: _withCtx(() => [
                _createVNode(_component_a_menu_item, {
                  onClick: ($event: any) => (_ctx.changeStatus(record))
                }, {
                  default: _withCtx(() => [
                    (record.status === 2)
                      ? (_openBlock(), _createBlock(_component_PauseCircleOutlined, { key: 0 }))
                      : _createCommentVNode("", true),
                    (record.status !== 2)
                      ? (_openBlock(), _createBlock(_component_PlayCircleOutlined, { key: 1 }))
                      : _createCommentVNode("", true),
                    _createTextVNode(" " + _toDisplayString(record.status === 2 ? "Stop" : "Start"), 1)
                  ]),
                  _: 2
                }, 1032, ["onClick"]),
                _createVNode(_component_a_menu_item, {
                  onClick: ($event: any) => (_ctx.edit(record))
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_edit_outlined),
                    _createTextVNode(" " + _toDisplayString(_ctx.$t("EDIT")), 1)
                  ]),
                  _: 2
                }, 1032, ["onClick"]),
                _createVNode(_component_a_menu_item, {
                  onClick: ($event: any) => (delete record.id)
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_delete_outlined),
                    _createTextVNode(" " + _toDisplayString(_ctx.$t("DELETE")), 1)
                  ]),
                  _: 2
                }, 1032, ["onClick"])
              ]),
              _: 2
            }, 1024)
          ]),
          default: _withCtx(() => [
            _createVNode(_component_a_button, { type: "text" }, {
              default: _withCtx(() => [
                _createVNode(_component_eye_outlined)
              ]),
              _: 1
            })
          ]),
          _: 2
        }, 1024)
      ]),
      _: 1
    }, 8, ["rowKey", "columns", "data-source", "pagination", "onChange", "loading"]),
    _createVNode(_component_a_table, {
      size: "middle",
      scroll: { y: '40vh' },
      rowKey: (record) => record.id,
      columns: _ctx.historyColumns,
      "data-source": _ctx.getAutoInboxHistoryData.autoInboxes,
      pagination: _ctx.paginationHistory,
      onChange: _ctx.handleTableChangeHistory,
      loading: _ctx.getAutoInboxHistoryData.loading,
      class: "flow-table"
    }, {
      title: _withCtx(() => [
        _createElementVNode("h3", _hoisted_5, _toDisplayString(_ctx.$t("HISTORY")), 1)
      ]),
      inbox_name_his: _withCtx(({ record }) => [
        _createElementVNode("span", null, [
          _createVNode(_component_a_button, {
            class: "table-row-name",
            type: "text",
            onClick: ($event: any) => (_ctx.customRowClick(record, true))
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(record.inbox_name), 1)
            ]),
            _: 2
          }, 1032, ["onClick"])
        ])
      ]),
      created_at: _withCtx(({ text }) => [
        _createElementVNode("span", null, _toDisplayString(_ctx.dateTimeDisplayFormatFromString(text)), 1)
      ]),
      total_send: _withCtx(({ text }) => [
        _createVNode(_component_a_tag, { color: "cyan" }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(text), 1)
          ]),
          _: 2
        }, 1024)
      ]),
      total_succeed: _withCtx(({ text }) => [
        _createVNode(_component_a_tag, { color: "#87d068" }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(text), 1)
          ]),
          _: 2
        }, 1024)
      ]),
      total_seen: _withCtx(({ text }) => [
        _createVNode(_component_a_tag, { color: "#2db7f5" }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(text), 1)
          ]),
          _: 2
        }, 1024)
      ]),
      total_clicked: _withCtx(({ text }) => [
        _createVNode(_component_a_tag, { color: "#108ee9" }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(text), 1)
          ]),
          _: 2
        }, 1024)
      ]),
      limit: _withCtx(({ text }) => [
        _createVNode(_component_a_tag, { color: "orange" }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(text == 99999 ? _ctx.$t("UNLIMITED") : text), 1)
          ]),
          _: 2
        }, 1024)
      ]),
      status: _withCtx(({ text }) => [
        _createVNode(_component_a_tag, {
          color: _ctx.getViralStatusColor(text)
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.getViralStatusName(text)), 1)
          ]),
          _: 2
        }, 1032, ["color"])
      ]),
      _: 1
    }, 8, ["rowKey", "columns", "data-source", "pagination", "onChange", "loading"]),
    _createVNode(_component_AutoInboxModal, {
      ref: "autoInboxModal",
      currentPage: _ctx.currentPage,
      onAutoInboxUpdated: _ctx.onAutoInboxUpdated
    }, null, 8, ["currentPage", "onAutoInboxUpdated"])
  ], 64))
}