export type EmployeeSearch = {
  id: number;
  name: string;
  photo: string;
  email: string;
  mobile: string;
  type: number;
  social_id: string;
  password: string;
  id_cms_privileges: number;
  ctv: string;
  status: string;
  current_shop_id: number;
  created_at: string;
  updated_at: string;
  access_token: string;
  language: string;
};
export type EmployeeEdit = {
  id: number;
  shop_id: number;
  cms_user_id: number;
  is_owner: number;
  role_id: number;
  role_type: number;
  created_at: string;
  updated_at: string;
};

export const ROLES  ={
  SUPER_ADMIN : 1,
  SHOP_OWNER : 2,
  ADMIN : 3,
  SALE_CARE : 4,
  ACCOUNTANT : 5,
  SALE_MANGER : 6,
  SALE : 10,
  CUSTOMER_CARE : 11
}
