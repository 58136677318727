import { resolveComponent as _resolveComponent, createVNode as _createVNode, mergeProps as _mergeProps, withCtx as _withCtx, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-12350128"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  class: "main-flow",
  id: "main-flow"
}
const _hoisted_2 = {
  class: "row-button-action",
  style: {"text-align":"right"}
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_input = _resolveComponent("a-input")!
  const _component_a_form_item = _resolveComponent("a-form-item")!
  const _component_a_button = _resolveComponent("a-button")!
  const _component_a_select_option = _resolveComponent("a-select-option")!
  const _component_a_select = _resolveComponent("a-select")!
  const _component_close_circle_outlined = _resolveComponent("close-circle-outlined")!
  const _component_a_table = _resolveComponent("a-table")!
  const _component_a_card = _resolveComponent("a-card")!
  const _component_flow_next_actions = _resolveComponent("flow-next-actions")!
  const _component_a_row = _resolveComponent("a-row")!
  const _component_a_form = _resolveComponent("a-form")!
  const _component_a_modal = _resolveComponent("a-modal")!
  const _component_a_divider = _resolveComponent("a-divider")!
  const _component_delete_outlined = _resolveComponent("delete-outlined")!
  const _component_a_popconfirm = _resolveComponent("a-popconfirm")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_a_form, {
      ref: "formRef",
      model: _ctx.formState,
      layout: "vertical"
    }, {
      default: _withCtx(() => [
        _createVNode(_component_a_form_item, _mergeProps({
          ref: "name",
          label: _ctx.$t('FLOW_MESSAGE_NAME'),
          name: "name"
        }, _ctx.validateInfos.name), {
          default: _withCtx(() => [
            _createVNode(_component_a_input, {
              placeholder: _ctx.$t('FLOW_MESSAGE_NAME'),
              onChange: _ctx.onTextChange,
              value: _ctx.formState.name,
              "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.formState.name) = $event))
            }, null, 8, ["placeholder", "onChange", "value"])
          ]),
          _: 1
        }, 16, ["label"]),
        _createVNode(_component_a_card, { class: "card-message" }, {
          title: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.$t('MESSAGE')) + " ", 1),
            _createVNode(_component_a_button, {
              onClick: _ctx.addValue,
              type: "primary"
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t('ADD')), 1)
              ]),
              _: 1
            }, 8, ["onClick"])
          ]),
          default: _withCtx(() => [
            _createVNode(_component_a_table, {
              dataSource: _ctx.formState.flow_smart_call[0].key_pairs,
              columns: _ctx.columnConfig,
              pagination: false,
              class: "flow-table"
            }, {
              key: _withCtx(({record}) => [
                _createVNode(_component_a_select, {
                  value: record.key,
                  "onUpdate:value": ($event: any) => ((record.key) = $event),
                  style: {"width":"150px"},
                  placeholder: "keys Mode",
                  onChange: _ctx.saveConfig
                }, {
                  default: _withCtx(() => [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.getColumnsConfig, (it) => {
                      return (_openBlock(), _createBlock(_component_a_select_option, {
                        key: it.id,
                        value: it.identified_name
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(it.identified_name), 1)
                        ]),
                        _: 2
                      }, 1032, ["value"]))
                    }), 128)),
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.getColumn, (i) => {
                      return (_openBlock(), _createBlock(_component_a_select_option, {
                        key: i.id,
                        value: i.dataIndex
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(i.dataIndex), 1)
                        ]),
                        _: 2
                      }, 1032, ["value"]))
                    }), 128))
                  ]),
                  _: 2
                }, 1032, ["value", "onUpdate:value", "onChange"])
              ]),
              value: _withCtx(({record}) => [
                _createVNode(_component_a_input, {
                  onChange: _ctx.saveConfig,
                  value: record.value,
                  "onUpdate:value": ($event: any) => ((record.value) = $event)
                }, null, 8, ["onChange", "value", "onUpdate:value"])
              ]),
              action: _withCtx(({index}) => [
                _createVNode(_component_close_circle_outlined, {
                  onClick: ($event: any) => (_ctx.deleteCall(index))
                }, null, 8, ["onClick"])
              ]),
              _: 1
            }, 8, ["dataSource", "columns"])
          ]),
          _: 1
        }),
        _createVNode(_component_a_card, { class: "card-message" }, {
          title: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.$t('MESSAGE')) + " ", 1),
            _createVNode(_component_a_button, {
              onClick: _ctx.addValue2,
              type: "primary"
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t('ADD')), 1)
              ]),
              _: 1
            }, 8, ["onClick"])
          ]),
          default: _withCtx(() => [
            _createVNode(_component_a_table, {
              dataSource: _ctx.formState.flow_smart_call[0].key_pair_report,
              columns: _ctx.columnConfig2,
              pagination: false,
              class: "flow-table"
            }, {
              key: _withCtx(({record}) => [
                _createVNode(_component_a_input, {
                  style: {"margin-top":"20px"},
                  onChange: _ctx.saveConfig,
                  value: record.key,
                  "onUpdate:value": ($event: any) => ((record.key) = $event)
                }, null, 8, ["onChange", "value", "onUpdate:value"])
              ]),
              value: _withCtx(({record}) => [
                _createVNode(_component_a_input, {
                  style: {"margin-top":"20px"},
                  onChange: _ctx.saveConfig,
                  value: record.value,
                  "onUpdate:value": ($event: any) => ((record.value) = $event)
                }, null, 8, ["onChange", "value", "onUpdate:value"])
              ]),
              action: _withCtx(({index}) => [
                _createVNode(_component_a_row, { style: {"flex-direction":"column"} }, {
                  default: _withCtx(() => [
                    (!_ctx.isShow)
                      ? (_openBlock(), _createBlock(_component_flow_next_actions, {
                          key: 0,
                          buttonName: _ctx.$t('next'),
                          updateAction: _ctx.updateSuccessActionCall,
                          flowContextData: _ctx.flowContextData,
                          index: index,
                          flowContext: _ctx.flowContext,
                          actionContext: _ctx.formState.flow_smart_call[0].key_pair_report[index].next_flow_action_context
                        }, null, 8, ["buttonName", "updateAction", "flowContextData", "index", "flowContext", "actionContext"]))
                      : _createCommentVNode("", true),
                    _createVNode(_component_close_circle_outlined, {
                      style: {"margin-top":"10px"},
                      onClick: ($event: any) => (_ctx.deleteCall2(index))
                    }, null, 8, ["onClick"])
                  ]),
                  _: 2
                }, 1024)
              ]),
              _: 1
            }, 8, ["dataSource", "columns"])
          ]),
          _: 1
        }),
        _createVNode(_component_a_modal, {
          width: 500,
          visible: _ctx.visibleCreateConfig,
          "onUpdate:visible": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.visibleCreateConfig) = $event)),
          title: _ctx.$t('ADD'),
          onOk: _ctx.addConfig
        }, {
          default: _withCtx(() => [
            _createVNode(_component_a_form, {
              ref: "formRef",
              model: _ctx.config,
              rules: _ctx.rules
            }, {
              default: _withCtx(() => [
                _createVNode(_component_a_form_item, {
                  name: "key",
                  label: _ctx.$t('keywords')
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_a_select, {
                      value: _ctx.config.key,
                      "onUpdate:value": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.config.key) = $event)),
                      placeholder: "keys Mode"
                    }, {
                      default: _withCtx(() => [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.getKeyCall(_ctx.column), (it) => {
                          return (_openBlock(), _createBlock(_component_a_select_option, {
                            key: it,
                            value: it
                          }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(it), 1)
                            ]),
                            _: 2
                          }, 1032, ["value"]))
                        }), 128))
                      ]),
                      _: 1
                    }, 8, ["value"])
                  ]),
                  _: 1
                }, 8, ["label"]),
                _createVNode(_component_a_form_item, {
                  label: _ctx.$t('value')
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_a_input, {
                      value: _ctx.config.value,
                      "onUpdate:value": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.config.value) = $event))
                    }, null, 8, ["value"])
                  ]),
                  _: 1
                }, 8, ["label"])
              ]),
              _: 1
            }, 8, ["model", "rules"])
          ]),
          _: 1
        }, 8, ["visible", "title", "onOk"]),
        _createVNode(_component_a_modal, {
          width: 500,
          visible: _ctx.visibleCreateConfig2,
          "onUpdate:visible": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.visibleCreateConfig2) = $event)),
          title: _ctx.$t('ADD'),
          onOk: _ctx.addConfig2
        }, {
          default: _withCtx(() => [
            _createVNode(_component_a_form, {
              ref: "formRef",
              model: _ctx.config,
              rules: _ctx.rules
            }, {
              default: _withCtx(() => [
                _createVNode(_component_a_form_item, {
                  label: _ctx.$t('keywords')
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_a_input, {
                      value: _ctx.config.key,
                      "onUpdate:value": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.config.key) = $event))
                    }, null, 8, ["value"])
                  ]),
                  _: 1
                }, 8, ["label"]),
                _createVNode(_component_a_form_item, {
                  label: _ctx.$t('value')
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_a_input, {
                      value: _ctx.config.value,
                      "onUpdate:value": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.config.value) = $event))
                    }, null, 8, ["value"])
                  ]),
                  _: 1
                }, 8, ["label"])
              ]),
              _: 1
            }, 8, ["model", "rules"])
          ]),
          _: 1
        }, 8, ["visible", "title", "onOk"]),
        _createVNode(_component_a_divider),
        (!_ctx.isShow)
          ? (_openBlock(), _createBlock(_component_flow_next_actions, {
              key: 0,
              buttonName: _ctx.$t('FLOW_SUCCESS_DELIVERY'),
              updateAction: _ctx.updateSuccessAction,
              flowContextData: _ctx.flowContextData,
              flowContext: _ctx.flowContext,
              actionContext: _ctx.flowContext.action_send_success_context
            }, null, 8, ["buttonName", "updateAction", "flowContextData", "flowContext", "actionContext"]))
          : _createCommentVNode("", true),
        (!_ctx.isShow)
          ? (_openBlock(), _createBlock(_component_flow_next_actions, {
              key: 1,
              buttonName: _ctx.$t('FLOW_FAILED_DELIVERY'),
              updateAction: _ctx.updateFailedAction,
              flowContextData: _ctx.flowContextData,
              flowContext: _ctx.flowContext,
              actionContext: _ctx.flowContext.action_send_failed_context
            }, null, 8, ["buttonName", "updateAction", "flowContextData", "flowContext", "actionContext"]))
          : _createCommentVNode("", true),
        (!_ctx.isShow)
          ? (_openBlock(), _createBlock(_component_flow_next_actions, {
              key: 2,
              buttonName: _ctx.$t('FLOW_DEFAULT_DELIVERY'),
              updateAction: _ctx.updateDefaultAction,
              flowContextData: _ctx.flowContextData,
              flowContext: _ctx.flowContext,
              actionContext: _ctx.flowContext.action_default_context
            }, null, 8, ["buttonName", "updateAction", "flowContextData", "flowContext", "actionContext"]))
          : _createCommentVNode("", true),
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_a_popconfirm, {
            onConfirm: _ctx.deleteCurrentFlowContext,
            title: _ctx.$t('FLOW_CONFIRM_DELETE_CONTEXT'),
            "ok-text": _ctx.$t('YES'),
            "cancel-text": _ctx.$t('NO')
          }, {
            default: _withCtx(() => [
              _createVNode(_component_a_button, {
                danger: "",
                type: "primary"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_delete_outlined),
                  _createTextVNode(" " + _toDisplayString(_ctx.$t("FLOW_DELETE")), 1)
                ]),
                _: 1
              })
            ]),
            _: 1
          }, 8, ["onConfirm", "title", "ok-text", "cancel-text"])
        ])
      ]),
      _: 1
    }, 8, ["model"])
  ]))
}