import {Shop, SuperUserInfo} from '@/models/Users';
import RouterEndpoint from "@/router/RouterEndpoint";
import {Locale} from "@/models/locales/Locales";
import {SelectionAnt} from "@/models/ShopPages/ConfigData";
import {FacebookPostData} from "@/models/facebook/FacebookPost";
import {Notification} from "@/models/notification/Notification";
import {Schedule} from "@/models/notification/Schedule";
import {Metadata} from "@/APIs/response/S_HttpResponse";

export type State = {
    userInfo: SuperUserInfo | null;
    isLogin: boolean;
    lang: string;
    shops:Shop[],
    pageAccessLatest: string;
    isUserInfoFetching: boolean;
    locales: Locale[];
    selectedPosts: FacebookPostData[];
    notifications: Notification[]
    countUnreadNotification: number
    schedules: Schedule[]
    scheduleMetadata: Metadata,
    menuCollapsed:boolean,
    code_verifier: string,

}

export const state: State = {
    userInfo: null,
    isLogin: false,
    isUserInfoFetching: false,
    lang: 'en',
    pageAccessLatest: RouterEndpoint.CUSTOMER_MANAGEMENT.name,
    locales:[],
    shops:[],
    selectedPosts:[],
    notifications:[],
    countUnreadNotification:0,
    schedules:[],
    scheduleMetadata:{
        record_total:0,
        page_size:0,
        page:0
    },
    menuCollapsed:false,
    code_verifier:''
};
