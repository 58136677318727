<template>
  <div class="main-flow" id="main-flow">
    <a-form ref="formRef" :model="formState" layout="vertical">
      <a-form-item ref="name" :label="$t('FLOW_MESSAGE_NAME')" name="name" v-bind="validateInfos.name">
        <a-input
            :placeholder="$t('FLOW_MESSAGE_NAME')"
            @change="onTextChange"
            v-model:value="formState.name"
        />
      </a-form-item>
      <a-card class="card-message" >
        <template #title>
          {{$t('MESSAGE')}}
          <a-button @click="addValue" type="primary">{{$t('ADD')}}</a-button>
        </template>
<!--        <template #extra>-->
<!--          <a-button type="text">-->
<!--            <close-outlined/>-->
<!--          </a-button>-->
<!--        </template>-->
<!--        <a-form-item name="message_content" v-bind="validateInfos.message">-->
<!--          <a-textarea-->
<!--              :auto-size="{ minRows: 3, maxRows: 8 }"-->
<!--              v-model:value="formState.message"-->
<!--              @change="onTextChange"-->
<!--              :placeholder="$t('FLOW_CALL_MESSAGE_PLACEHOLDER')"-->
<!--          />-->
<!--        </a-form-item>-->
<!--        <span>-->
<!--                    <a-tag color="#2db7f5" @click="addTitle">{{$t("FLOW_TAG_TITLE")}}</a-tag>-->
<!--          <a-tag color="#2db7f5" @click="addFullName">{{$t("FLOW_TAG_FULL_NAME")}}</a-tag>-->
<!--          <a-tag color="#2db7f5" @click="addScheduledTime">{{$t("FLOW_TAG_SCHEDULED_TIME")}}</a-tag>-->
<!--        </span>-->
        <a-table
            :dataSource="formState.flow_smart_call[0].key_pairs"
            :columns="columnConfig"
            :pagination="false"
            class="flow-table">
          <template #key="{record}">

                <a-select
                    v-model:value="record.key"
                    style="width: 150px"
                    placeholder="keys Mode"
                   @change="saveConfig"
                >
                  <a-select-option v-for="it in getColumnsConfig" :key="it.id" v-bind:value="it.identified_name" >
                    {{it.identified_name}}
                  </a-select-option>
                  <a-select-option v-for="i in getColumn" :key="i.id" v-bind:value="i.dataIndex" >
                    {{i.dataIndex}}
                  </a-select-option>
                </a-select>

          </template>
          <template #value="{record}">

         <a-input @change="saveConfig" v-model:value="record.value"  />
          </template>
          <template #action="{index}">
            <close-circle-outlined @click="deleteCall(index)" />
          </template>
<!--          <template #action="{ record }">-->
<!--            <div class="editable-row-operations">-->
<!--        <span v-if="editableData[record.key]">-->
<!--          <a @click="saveConfig(record.value,record.key)">Save</a>-->
<!--            <a @click="cancel(record.key)" style="margin-left: 8px;">Cancel</a>-->
<!--        </span>-->
<!--              <span v-else>-->
<!--          <a v-on:click="edit(record.key)" >Edit</a>-->
<!--             <a-popconfirm-->
<!--                 title="Sure to delete?"-->
<!--                 @confirm="remove(record)"-->
<!--             >-->
<!--        <a style="margin-left: 8px;">Delete</a>-->
<!--      </a-popconfirm>-->
<!--        </span>-->
<!--            </div>-->
<!--          </template>-->
        </a-table>
      </a-card>
      <a-card class="card-message" >
        <template #title>
          {{$t('MESSAGE')}}
          <a-button @click="addValue2" type="primary">{{$t('ADD')}}</a-button>
        </template>
        <!--        <template #extra>-->
        <!--          <a-button type="text">-->
        <!--            <close-outlined/>-->
        <!--          </a-button>-->
        <!--        </template>-->
        <!--        <a-form-item name="message_content" v-bind="validateInfos.message">-->
        <!--          <a-textarea-->
        <!--              :auto-size="{ minRows: 3, maxRows: 8 }"-->
        <!--              v-model:value="formState.message"-->
        <!--              @change="onTextChange"-->
        <!--              :placeholder="$t('FLOW_CALL_MESSAGE_PLACEHOLDER')"-->
        <!--          />-->
        <!--        </a-form-item>-->
        <!--        <span>-->
        <!--                    <a-tag color="#2db7f5" @click="addTitle">{{$t("FLOW_TAG_TITLE")}}</a-tag>-->
        <!--          <a-tag color="#2db7f5" @click="addFullName">{{$t("FLOW_TAG_FULL_NAME")}}</a-tag>-->
        <!--          <a-tag color="#2db7f5" @click="addScheduledTime">{{$t("FLOW_TAG_SCHEDULED_TIME")}}</a-tag>-->
        <!--        </span>-->
        <a-table
            :dataSource="formState.flow_smart_call[0].key_pair_report"
            :columns="columnConfig2"
            :pagination="false"
            class="flow-table">
          <template #key="{record}">

            <a-input style="margin-top: 20px" @change="saveConfig" v-model:value="record.key"  />
          </template>
          <template #value="{record}">

            <a-input style="margin-top: 20px" @change="saveConfig" v-model:value="record.value"  />
          </template>
          <template #action="{index}">
           <a-row style="flex-direction: column">
             <flow-next-actions
                 v-if="!isShow"
                 :buttonName="$t('next')"
                 :updateAction="updateSuccessActionCall"
                 :flowContextData="flowContextData"
                 :index="index"
                 :flowContext="flowContext"
                 :actionContext="formState.flow_smart_call[0].key_pair_report[index].next_flow_action_context"
             />
             <close-circle-outlined style="margin-top: 10px" @click="deleteCall2(index)" />
           </a-row>
          </template>
          <!--          <template #action="{ record }">-->
          <!--            <div class="editable-row-operations">-->
          <!--        <span v-if="editableData[record.key]">-->
          <!--          <a @click="saveConfig(record.value,record.key)">Save</a>-->
          <!--            <a @click="cancel(record.key)" style="margin-left: 8px;">Cancel</a>-->
          <!--        </span>-->
          <!--              <span v-else>-->
          <!--          <a v-on:click="edit(record.key)" >Edit</a>-->
          <!--             <a-popconfirm-->
          <!--                 title="Sure to delete?"-->
          <!--                 @confirm="remove(record)"-->
          <!--             >-->
          <!--        <a style="margin-left: 8px;">Delete</a>-->
          <!--      </a-popconfirm>-->
          <!--        </span>-->
          <!--            </div>-->
          <!--          </template>-->
        </a-table>
      </a-card>
      <a-modal :width="500"  v-model:visible="visibleCreateConfig" :title="$t('ADD')"  @ok="addConfig">
        <a-form ref="formRef" :model="config"  :rules="rules"  >
          <a-form-item name="key"
                       :label="$t('keywords')">
            <a-select
                v-model:value="config.key"
                placeholder="keys Mode"
            >
              <a-select-option v-for="it in getKeyCall(column)" :key="it" v-bind:value="it" >
                {{it}}
              </a-select-option>
              <!--              <a-select-option v-for="i in getColumn" :key="i.id" v-bind:value="i.dataIndex" >-->
              <!--                {{getKeyCall(i.dataIndex)}}-->
              <!--              </a-select-option>-->
            </a-select>
          </a-form-item >
          <a-form-item :label="$t('value')">
            <a-input v-model:value="config.value"/>
          </a-form-item >
          <!--          <a-form-item name="type"  :label="$t('FIELD_TYPE')">-->
          <!--            <a-radio-group  v-model:value="config.field_type">-->
          <!--              <a-radio value="STRING">{{$t('TEXT')}}</a-radio>-->
          <!--              <a-radio value="INT">{{$t('number')}}</a-radio>-->
          <!--              <a-radio value="DATE">{{$t('DATE')}}</a-radio>-->
          <!--              <a-radio value="CURRENCY">{{$t('CURRENCY')}}</a-radio>-->
          <!--            </a-radio-group>-->
          <!--          </a-form-item>-->
          <!--      <div v-if="config.field_type">-->
          <!--        <a-form-item v-if="config.field_type === 'STRING'" :label="$t('value')">-->
          <!--          <a-input v-model:value="config.customer_column_value"/>-->
          <!--        </a-form-item>-->
          <!--        <a-form-item v-else-if="config.field_type === 'DATE'"  :label="$t('value')">-->
          <!--          <a-date-picker style="width: 100%" v-model:value="config.customer_column_value" format="DD-MM-YYYY" valueFormat="DD-MM-YYYY" />-->
          <!--        </a-form-item>-->
          <!--        <a-form-item v-else  :label="$t('value')">-->
          <!--          <a-input-number style="width: 100%" v-model:value="config.customer_column_value" :min="1" :max="100000" />-->
          <!--        </a-form-item>-->
          <!--      </div>-->
        </a-form>
      </a-modal>

      <a-modal :width="500"  v-model:visible="visibleCreateConfig2" :title="$t('ADD')"  @ok="addConfig2">
        <a-form ref="formRef" :model="config"  :rules="rules"  >
          <a-form-item :label="$t('keywords')">
            <a-input v-model:value="config.key"/>
          </a-form-item >
          <a-form-item :label="$t('value')">
            <a-input v-model:value="config.value"/>
          </a-form-item >
<!--          <a-form-item name="type"  :label="$t('FIELD_TYPE')">-->
<!--            <a-radio-group  v-model:value="config.field_type">-->
<!--              <a-radio value="STRING">{{$t('TEXT')}}</a-radio>-->
<!--              <a-radio value="INT">{{$t('number')}}</a-radio>-->
<!--              <a-radio value="DATE">{{$t('DATE')}}</a-radio>-->
<!--              <a-radio value="CURRENCY">{{$t('CURRENCY')}}</a-radio>-->
<!--            </a-radio-group>-->
<!--          </a-form-item>-->
          <!--      <div v-if="config.field_type">-->
          <!--        <a-form-item v-if="config.field_type === 'STRING'" :label="$t('value')">-->
          <!--          <a-input v-model:value="config.customer_column_value"/>-->
          <!--        </a-form-item>-->
          <!--        <a-form-item v-else-if="config.field_type === 'DATE'"  :label="$t('value')">-->
          <!--          <a-date-picker style="width: 100%" v-model:value="config.customer_column_value" format="DD-MM-YYYY" valueFormat="DD-MM-YYYY" />-->
          <!--        </a-form-item>-->
          <!--        <a-form-item v-else  :label="$t('value')">-->
          <!--          <a-input-number style="width: 100%" v-model:value="config.customer_column_value" :min="1" :max="100000" />-->
          <!--        </a-form-item>-->
          <!--      </div>-->
        </a-form>
      </a-modal>

      <a-divider/>
      <flow-next-actions
          v-if="!isShow"
          :buttonName="$t('FLOW_SUCCESS_DELIVERY')"
          :updateAction="updateSuccessAction"
          :flowContextData="flowContextData"
          :flowContext="flowContext"
          :actionContext="flowContext.action_send_success_context"
      />
      <flow-next-actions
          v-if="!isShow"
          :buttonName="$t('FLOW_FAILED_DELIVERY')"
          :updateAction="updateFailedAction"
          :flowContextData="flowContextData"
          :flowContext="flowContext"
          :actionContext="flowContext.action_send_failed_context"
      />
      <flow-next-actions
          v-if="!isShow"
          :buttonName="$t('FLOW_DEFAULT_DELIVERY')"
          :updateAction="updateDefaultAction"
          :flowContextData="flowContextData"
          :flowContext="flowContext"
          :actionContext="flowContext.action_default_context"
      />
      <div class="row-button-action" style="text-align: right">
        <a-popconfirm
            @confirm="deleteCurrentFlowContext"
            :title="$t('FLOW_CONFIRM_DELETE_CONTEXT')"
            :ok-text="$t('YES')"
            :cancel-text="$t('NO')"
        >
          <a-button danger type="primary">
            <delete-outlined/>
            {{ $t("FLOW_DELETE") }}
          </a-button>
        </a-popconfirm>
      </div>
    </a-form>
  </div>
</template>

<script lang="ts">
import {
  computed,
  defineComponent,
  onBeforeMount,
  reactive,
  ref,
  UnwrapRef,
  watch,
} from "vue";
import {
  CloseCircleOutlined,
  CloseOutlined,
  DeleteOutlined,
  DragOutlined,
  NotificationOutlined,
  PictureOutlined,
  FileDoneOutlined,
  FileAddOutlined,
} from "@ant-design/icons-vue";
import FlowNextActions from "@/components/Flow/FlowNextActions.vue";
import {useStore} from "@/store";
import {S_EventBus, ValidateFlowContext} from "@/utils/EventBus";
import {useI18n} from "vue-i18n";
import {MixUtils} from "@/utils/Utils";
import ImageSelectionModal from "@/components/Image/ImageSelectionModal.vue";
import "vue3-carousel/dist/carousel.css";
import {AWS_DO_Model} from "@/models/AWS_DO/AWS_DO";
import {
  ActionContext, FlowContext,
  MessageContext,
  PostbackContext,
  QuickReply,
} from "@/models/Flows/FlowContext";
import {
  FormEmailContext,
  FormFlowInformation,
} from "@/models/Flows/FlowForm";
import {ValidateErrorEntity} from "ant-design-vue/es/form/interface";
import {Form, message} from 'ant-design-vue';
import APIs from "@/APIs/APIs";

const useForm = Form.useForm;

export default defineComponent({
  name: "flow-voice-text",
  props: [
    "flowContext",
    "updateCurrentFlowContext",
    "flowContextData",
    "deleteFlowContext",
      "isShow"
  ],
  components: {
    DeleteOutlined,
    FlowNextActions,
    CloseCircleOutlined
  },
  setup(props) {
    const formRef = ref();
    const messengerTags = ref([
      {
        label: "NONE",
        value: "",
      },
      {
        label: "CONFIRMED_EVENT_UPDATE",
        value: "CONFIRMED_EVENT_UPDATE",
      },
      {
        label: "POST_PURCHASE_UPDATE",
        value: "POST_PURCHASE_UPDATE",
      },
      {
        label: "ACCOUNT_UPDATE",
        value: "ACCOUNT_UPDATE",
      },
    ]);

    const store = useStore();
    const i18n = useI18n();
    const formState: UnwrapRef<FlowContext> = reactive({});
    const messageContexts = ref<MessageContext[]>([]);
    const visibleCreateConfig = ref(false)
    const visibleCreateConfig2 = ref(false)
    const getColumnsConfig = computed(()=> store.getters.getCustomerConfig)
    const getColumn = computed(()=> store.getters.getDefaultColumns)
    // @ts-ignore
    const column = ref([...getColumn.value,...getColumnsConfig.value].map((item:any) => {
      if(item.dataIndex &&  typeof item.dataIndex !== 'number'){
        return item.dataIndex
      }else if(item.identified_name){
        return  item.identified_name
      }
    }))
    const deleteCall = (index:any)=>{
      // @ts-ignore
      formState.flow_smart_call[0].key_pairs = formState.flow_smart_call[0].key_pairs.filter((item:any,i:any) => index !== i)
      onSubmit()
    }
    const deleteCall2 = (index:any)=>{
      // @ts-ignore
      formState.flow_smart_call[0].key_pair_report = formState.flow_smart_call[0].key_pair_report.filter((item:any,i:any) => index !== i)
      onSubmit()
    }
    column.value = column.value.filter((item:any) => item !== undefined)
    console.log(column,'0')
    const   editableData =  ref({})
    const {resetFields, validate, validateInfos} = useForm(
        formRef,
        reactive({
          name: [
            {
              required: true,
              message: i18n.t("FLOW_VALIDATE_FLOW_NAME"),
              trigger: "blur",
            },
          ],
          message: [
            {
              required: true,
              message: i18n.t("FLOW_VALIDATE_FACEBOOK_MESSAGE_CONTENT"),
              trigger: "blur",
            },
          ],
        }),
    );

    const listConfig = ref([])
    const config = ref({key:"",value:""})
    const columnConfig =[
      {
        title: i18n.t('keywords'),
        dataIndex: 'key',
        key: 'key',
        slots: {customRender: 'key'},
      },
      {
        title: i18n.t('value'),
        dataIndex: 'value',
        key: 'value',
        slots: {customRender: 'value'},
      },
      {
        title: i18n.t(''),
        key: 'action',
        slots: {customRender: 'action'},
      },
    ]
    const columnConfig2 =[
      {
        title: i18n.t('keywords'),
        dataIndex: 'key',
        key: 'key',
        slots: {customRender: 'key'},
      },
      {
        title: i18n.t('value'),
        dataIndex: 'value',
        key: 'value',
        slots: {customRender: 'value'},
      },
      {
        title: i18n.t(''),
        key: 'action',
        slots: {customRender: 'action'},
      },
    ]
    const addTitle=()=>{
      formState.message+=" {{title}}"
      onSubmit()
    }
    const addFullName=()=>{
      formState.message+=" {{full_name}}"
      onSubmit()
    }
    const addScheduledTime=()=>{
      formState.message+=" {{scheduled_time}}"
      onSubmit()
    }
   const  saveConfig =  () =>  {
    onSubmit()
    }
const addValue = () => {
  // @ts-ignore
  visibleCreateConfig.value = true
}
    const addValue2 = () => {
      // @ts-ignore
      visibleCreateConfig2.value = true
    }
    const updateProps = () => {
      if (props.flowContext) {
        console.log(props.flowContext,'111')
        formState.name = props.flowContext.name;
        formState.message = props.flowContext.message;

        // @ts-ignore
        formState.flow_smart_call = props.flowContext.flow_smart_call !== null ? props.flowContext.flow_smart_call : [{key_pairs:[]}]

        // @ts-ignore
        if(formState.flow_smart_call[0].key_pairs === null){
          // @ts-ignore
          formState.flow_smart_call[0].key_pairs = []
        }
        // @ts-ignore
        formState.flow_smart_call[0].key_pairs.map((item) => {
          edit(item.key)
        })
      }
    };
    watch(
        () => props.flowContext,
        () => {
          updateProps();


        }
    );
    const getKeyCall = (str:any) => {
      let k:any = []
      // @ts-ignore
      formState.flow_smart_call[0].key_pairs.map((item)=>{
       str.map((i:any) => {
         if(i !== item.key){
           k.push(i)
         }
       })
      })
      // @ts-ignore
      if(k.length === 0){
        return str
      }else {
        return k
      }


    }
    const flowFormValidate: ValidateFlowContext = {
      showValidateForm() {
        onSubmit();
      },
    };
    const edit  = (key:any) =>  {
// @ts-ignore
      editableData.value[key] = {...formState.flow_smart_call[0].key_pairs.filter((item) => key === item.key)[0]}


    }
    onBeforeMount(() => {
      S_EventBus.instance().setValidateFlowContextListener(flowFormValidate);
    });

    const onTextChange = () => {
      onSubmit();
    };


    const updateOpenEmailAction = (action: ActionContext) => {
      const flow = {...props.flowContext};
      flow.action_open_mail_context = action;
      props.updateCurrentFlowContext(flow);
    };

    const updateClickToLinkAction = (action: ActionContext) => {
      const flow = {...props.flowContext};
      flow.action_click_to_link_context = action;
      props.updateCurrentFlowContext(flow);
    };
    console.log(formState)
const addConfig = () => {

  // @ts-ignore
  formState.flow_smart_call[0].key_pairs.push(config.value)
  visibleCreateConfig.value = false
  onSubmit()
}
    const addConfig2 = () => {

      // @ts-ignore
      formState.flow_smart_call[0].key_pair_report.push(config.value)
      visibleCreateConfig2.value = false
      onSubmit()
    }
    const updateSuccessAction = (action: ActionContext) => {
      const flow = {...props.flowContext};
      flow.action_send_success_context = action;
      props.updateCurrentFlowContext(flow);
    };
    const updateSuccessActionCall = (action: ActionContext,index:any) => {
      console.log(action,index)
      const flow = {...props.flowContext};
      flow.flow_smart_call[0].key_pair_report[index].next_flow_action_context = action;
      props.updateCurrentFlowContext(flow);
    };
    const updateFailedAction = (action: ActionContext) => {
      const flow = {...props.flowContext};
      flow.action_send_failed_context = action;
      props.updateCurrentFlowContext(flow);
    };
    const updateDefaultAction = (action: ActionContext) => {
      const flow = {...props.flowContext};
      flow.action_default_context = action;
      props.updateCurrentFlowContext(flow);
    };

    const deleteCurrentFlowContext = () => {
      props.deleteFlowContext(props.flowContext.id);
    };

    const onSubmit = () => {
      validate().then(() => {
        const flow = {...props.flowContext};
        flow.message = formState.message;
        // @ts-ignore
        formState.flow_smart_call = formState.flow_smart_call.filter((item) => item.key_pairs !== null|| item.key_pair_report !==null)
        flow.flow_smart_call = formState.flow_smart_call
        flow.name = formState.name;
        props.updateCurrentFlowContext(flow);
      }).catch((error: ValidateErrorEntity<FormFlowInformation>) => {
        console.log("error", error);
      });
    };
    updateProps();
    return {
      formState,
      formRef,
      onSubmit,
      onTextChange,
      messengerTags,
      updateSuccessAction,
      updateFailedAction,
      updateDefaultAction,
      deleteCurrentFlowContext,
      messageContexts,
      validateInfos,
      updateOpenEmailAction,
      updateClickToLinkAction,
      addTitle,
      addFullName,
      addScheduledTime,
      columnConfig,
      listConfig,
      addValue,
      visibleCreateConfig,
      visibleCreateConfig2,
      config,
      getColumn,
      deleteCall2,
      getColumnsConfig,
      addConfig,
      editableData,
      edit,saveConfig,
      getKeyCall,
      column,
      columnConfig2,
      addConfig2,
      updateSuccessActionCall,
      addValue2,
      deleteCall
    };
  },
});
</script>

<style lang="scss" scoped>
.main-flow {
  position: relative;
  padding: 40px 18% 60px;
  text-align: left;

  .icon-brand-sticker {
    position: absolute;
    font-size: 28px;
    top: 0;
    margin-right: 25px;
    right: 0;
  }

  .row-button-action {
    margin-top: 20px;
  }

  .card-message {
    margin-top: 10px;
    border-radius: 10px;
  }
}

.post-back-button {
  margin-top: 18px;
  text-align: center;
}

.button-post-back {
  margin: 5px 10px;
}

.slick-dot-facebook-template {
  display: inline-block;
  cursor: pointer;
  margin: 7px;

  .slick-dot-facebook-template-content {
    padding: 2px;
  }
}

.card-facebook-template {
  width: 95%;
}

.card-facebook-template-active {
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.img-cover-facebook-template {
  width: 100%;
  height: 200px;
  object-fit: cover;
}

.upload-center {
  display: flex;
  justify-content: center;
  height: 200px;
  align-items: center;
}


.attach-file-container {
  margin-top: 18px;
}

.photo-flow-img {
  max-height: 200px;
  object-fit: cover;
  cursor: pointer;
}

.icon-img-svg {
  width: 28px;
  height: 28px;
}

.button-quick-reply {
  border-radius: 15px;
}
</style>
<style>
.carousel__prev--in-active {
  display: none;
}

.carousel__next--in-active {
  display: none;
}
</style>
