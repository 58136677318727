import {VoucherData, State} from "./states";

export type Getters = {
    getVoucherData(state: State): VoucherData
    getVoucherActiveData(state: State): any
}
export const getters: Getters = {
    getVoucherData(state: State): VoucherData {
        if (state.vouchers) {
            return state.vouchers
        } else {
            return {
                voucher: [],
                metadata: {}
            }
        }
    },
    getVoucherActiveData(state: State): any {
        if (state.voucherActive) {
            return state.voucherActive
        } else {
            return []
        }
    },
}
