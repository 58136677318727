
import { TicketMutationTypes} from "./mutation-types";
import {
    TicketData,
    State,
    TicketPriceData,
    TicketTypeData,
    TicketTemplateData,
    TicketTransactionData
} from "@/store/modules/Ticket/states";



export type Mutations<S = State> = {
    [TicketMutationTypes.GET_TICKET](state : S,payload: TicketData|undefined):void,
    [TicketMutationTypes.GET_TICKET_TYPE](state : S,payload: TicketTypeData|undefined):void,
    [TicketMutationTypes.GET_TICKET_PRICE](state : S,payload: TicketPriceData|undefined):void,
    [TicketMutationTypes.GET_TICKET_TEMPLATE](state : S,payload: TicketTemplateData|undefined):void,
    [TicketMutationTypes.GET_TICKET_TRANSACTION](state : S,payload: TicketTransactionData|undefined):void,




}

export const mutations : Mutations ={
    [TicketMutationTypes.GET_TICKET](state : State,payload: TicketData|undefined): void{
        state.ticketData = payload
    },
    [TicketMutationTypes.GET_TICKET_TYPE](state : State,payload: TicketTypeData|undefined): void{
        state.ticketTypeData = payload
    },
    [TicketMutationTypes.GET_TICKET_PRICE](state : State,payload: TicketPriceData|undefined): void{
        state.ticketPriceData = payload
    },

    [TicketMutationTypes.GET_TICKET_TEMPLATE](state : State,payload: TicketTemplateData|undefined): void{
        state.ticketTemplateData = payload
    },
    [TicketMutationTypes.GET_TICKET_TRANSACTION](state : State,payload: TicketTransactionData|undefined): void{
        state.ticketTransactionData = payload
    },
}
