<template>
  <div class="main-login">
    <a-card :bordered="false" class="login-card">
      <a-card-meta title="Login">
        <template #avatar>
          <a-avatar
            src="https://biglead.live/images/logo.png"
          />
        </template>
        <template #description>
          <p>please press to login button to associate with Biglead system</p>
          <p v-if="loginMessageError" style="color: darkred">
            <close-circle-outlined :style="{ color: 'red' }" />
            {{ loginMessageError }}
          </p>
        </template>
      </a-card-meta>
      <p style="margin-top: 20px">
        <a-button
          type="primary"
          block
          :href="onLoginClick()"
          :loading="isLoginLoading"
          >Sign in</a-button>


      </p>
    </a-card>
  </div>
</template>

<script>
import { Options, Vue } from "vue-class-component";
import { mapActions } from "vuex";
import { UserActionTypes } from "@/store/modules/user/action-types";
import RouterEndpoint from "@/router/RouterEndpoint";
import { CloseCircleOutlined } from "@ant-design/icons-vue";
import * as Facebook from 'fb-sdk-v15';
import { Constant } from "@/utils/Constants";

@Options({
  components: {
    CloseCircleOutlined,
  },
  data() {
    return {
      isLoginLoading: false,
      loginMessageError: "",
    };
  },
  created() {
    if (this.$route.query.user_id) {
      this.isLoginLoading = true;
      console.log("user_id", this.$route.query.user_id);
      this.login({ user_id: this.$route.query.user_id })
        .then((res) => {
          console.log("res", res);
          this.isLoginLoading = false;
          if (res.status === 200) {
            this.$router.push({
              name: RouterEndpoint.CUSTOMER_MANAGEMENT.name,
            });
          } else {
            this.loginMessageError = res.message;
          }
        })
        .catch((e) => {
          this.isLoginLoading = false;
          this.loginMessageError = "An error occurring";
          console.error(e);
        });
    }
  },

  methods: {
    ...mapActions({
      login: UserActionTypes.LOGIN,
    }),
    onLoginClick() {
      return this.getWebUrl() + "/login-outsite?callback=" + location.href.replace("/login","");
    },

  },
})
export default class Login extends Vue {}
</script>

<style lang="scss" scoped>
.main-login {
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
}

.login-card {
  display: flex;
  flex: 0.4;
  flex-direction: column;
}
</style>
