<template>
  <a-row>
    <a-col :span="8">
      <a-typography-title :level="2">{{$t('page_settings')}}</a-typography-title>
      <a-typography-text type="secondary">{{$t('page_settings')}}</a-typography-text>
    </a-col>
    <a-col :span="6"></a-col>
    <a-col v-if="isPage" :span="10">
      <a-row justify="space-between">
        <a-button style="width: 75%"  @click="showModal">{{$t('COPY_SETTINGS_FROM_ANOTHER_PAGE')}}</a-button>
        <a-button @click="save"  type="primary">{{$t('SAVE')}}</a-button>
      </a-row>

    </a-col>
  </a-row>
<a-row  style="margin-top: 20px;">
  <a-col :span="11">
    <a-typography-title :level="5">{{$t('change_shop')}}</a-typography-title>
    <a-select
       allowClea
       :value="currentShop.currentShop.id"
       style="width: 100%"

       placeholder="Please select"
       @change="changeShop"
      >
       <a-select-option
          v-for="item in getShops.shops"
             :key="item.id"
              v-bind:value="item.id"
          >{{ item.shop_name }}
           </a-select-option>
        </a-select>
  </a-col>
  <a-col :span="2"></a-col>
  <a-col :span="11">
    <a-typography-title :level="5">{{$t('select_page')}}</a-typography-title>
    <a-select
        allowClea
        show-search
        style="width: 100%"
        placeholder="Please select"
        @change="changePage"
        v-model:value="getCurrentPageId"
    >
      <a-select-option
          v-for="item in configPage.pageConfig"
          :key="item.id"
          v-bind:value="item.page_id"
      > <img
          v-if="item.page_type === 2"
          draggable="false"
          src="@/assets/icons/zalo-seeklogo.com.svg"
          style="width: 20px;margin-right: 20px"
      />
        <img
            v-else-if="item.page_type === 1"
            draggable="false"
            src="@/assets/icons/facebook-svgrepo-com.svg"
            style="width: 20px;margin-right: 20px"
        />
        <img
            v-else-if="item.page_type === 3"
            draggable="false"
            src="@/assets/icons/instagram-svgrepo-com.svg"
            style="width: 20px;margin-right: 20px"
        />
        <img
            v-else-if="item.page_type === 4"
            draggable="false"
            src="@/assets/icons/line-svgrepo-com.svg"
            style="width: 20px;margin-right: 20px"
        />{{ item.page_name }}
      </a-select-option>
    </a-select>
  </a-col>
  <a-row v-if="!isPage" style="margin-top: 30px;width: 100%" justify="center">
    <a-typography-title  :level="3">{{$t('PLEASE_CHOOSE_SHOP_AND_FANPAGE')}}</a-typography-title>
  </a-row>
</a-row>
  <a-row v-if="isPage" style="margin-top: 20px;">
    <a-col :span="11">
      <a-card  style="width: 100%;margin-top: 20px;border-radius: 10px">
        <template #title>
          <AppstoreOutlined />
          {{$t('CONFIGURATION')}}
        </template>

        <a-row style="margin-top: 20px">
          <a-col>
            <a-row style="margin-bottom: 20px" >
              <a-switch v-model:checked="configData.message_inbox" />
              <a-typography-text style="margin-left: 20px">{{$t('new_message_notification')}}</a-typography-text>
            </a-row>
            <a-row style="margin-bottom: 20px" >
              <a-switch v-model:checked="configData.allow_mobile_notification" />
              <a-typography-text style="margin-left: 20px">{{$t('message_notification_on_mobile')}}</a-typography-text>
            </a-row>
            <a-row style="margin-bottom: 20px" >
              <a-switch v-model:checked="configData.sound_inbox" />
              <a-typography-text style="margin-left: 20px">{{$t('notification_sound')}}</a-typography-text>
            </a-row>
            <a-row style="margin-bottom: 20px" >
              <a-switch v-model:checked="configData.auto_like" />
              <a-typography-text style="margin-left: 20px">{{$t('auto_like_comment')}}</a-typography-text>
            </a-row>
            <a-row style="margin-bottom: 20px" >
              <a-switch v-model:checked="configData.auto_hide_comment" />
              <a-typography-text style="margin-left: 20px">{{$t('auto_hide_comment')}}</a-typography-text>
            </a-row>
            <div v-if="configData.auto_hide_comment" style="margin-left: 30px">
              <a-row style="margin-bottom: 20px" >
                <a-switch size="small" @change="changeAll"  v-model:checked="configData.auto_hide_comment_all" />
                <a-typography-text style="margin-left: 20px">{{$t('all')}}</a-typography-text>
              </a-row>
              <a-row style="margin-bottom: 20px" >
                <a-switch size="small"   v-model:checked="configData.auto_hide_comment_phone" />
                <a-typography-text style="margin-left: 20px">{{$t('auto_hide_comment_with_phone_number')}}</a-typography-text>
              </a-row>
              <a-row style="margin-bottom: 20px" >
                <a-switch size="small" v-model:checked="configData.auto_hide_keyword" />
                <a-typography-text style="margin-left: 20px">{{$t('auto_hide_comment_with_keywords')}}</a-typography-text>
              </a-row>
              <a-select
                  v-if="configData.auto_hide_keyword"
                  v-model:value="configData.hide_keyword_list"
                  mode="tags"
                  style="width: 100%"
                  placeholder="Tags Mode"
                  @change="handleChange"
              ></a-select>
            </div>
            <a-row style="margin-bottom: 20px" >
              <a-switch v-model:checked="configData.auto_unhide_comment" />
              <a-typography-text style="margin-left: 20px">{{$t('AUTO_UNHIDE_BEHIND')}}</a-typography-text>
              <a-col :span="1"></a-col>
              <a-col :span="8" v-if="configData.auto_unhide_comment">
                  <a-row>
                    <a-col :span="16">
                      <a-input  v-model:value="configData.auto_unhide_comment_hour" />
                    </a-col>
                    <a-col :span="2"></a-col>
                    <a-col  :span="6">
                    <a-typography-text>{{$t('day')}}</a-typography-text>
                    </a-col>
                  </a-row>
              </a-col>
            </a-row>



          </a-col>
        </a-row>


      </a-card>
    </a-col>
    <a-col :span="2"></a-col>
    <a-col :span="11">
      <a-card  style="width: 100%;margin-top: 20px;border-radius: 10px">
        <template #title>
          <AppstoreOutlined />
          {{$t('CONFIGURATION')}}
        </template>

        <a-row style="margin-top: 20px">
          <a-col>
            <a-typography-text style="margin-left: 20px;display: inline-block">
              {{$t('MISSED_MESSAGE_LATER')}}
              <a-input v-model:value="configData.missing_time" style="width: 20%;margin-left: 10px;margin-right: 10px" />
              {{$t('minutes')}}
            </a-typography-text>
            <a-typography-text style="margin-left: 20px;display: inline-block;margin-top: 20px">
              {{$t('message_font_size')}}
              <a-input v-model:value="configData.font_size_message" style="width: 20%;margin-left: 10px;margin-right: 10px" />

            </a-typography-text>
           <a-row >

            <a-col :span="8">
              <a-typography-text style="margin-left: 20px;display: inline-block;margin-top: 20px">
                {{$t('color_of_chatbox')}}

              </a-typography-text>
            </a-col>

               <a-col :span="16"  style="margin-top: 15px" >
                 <a-avatar v-for="i in colors" :key="i" @click="configData.color_message =  i" style="margin-right: 10px;margin-top: 5px;cursor: pointer"  :style="{'background-color': i}" size="small">
                   <check-outlined v-if=" configData.color_message === i" />
                 </a-avatar>
               </a-col>


           </a-row>



          </a-col>
        </a-row>


      </a-card>

        <a-row style="margin-top: 20px">
          <a-typography-text strong>{{$t('SELECT_WALLET')}}</a-typography-text>
          <a-select
              style="width: 100%"
              mode="single"
              @change="handleSelectWallet"
              v-model:value="configData.user_wallet_id"
              :placeholder="$t('SELECT_WALLET')"
          >
            <a-select-option v-for="item in getAdminAndShopOwner" :key="item.id" :value="item.id">{{
                item.name
              }}
            </a-select-option>
          </a-select>
        </a-row>

    </a-col>
  </a-row>
  <a-modal v-model:visible="visible" :title="$t('SELECT_A_PAGE_TO_COPY_THE_CONFIGURATION')" @ok="save">
    <a-select
        v-if="visible"
        allowClean
        style="width: 100%"
        placeholder="Please select"
        @change="copySettingPage"

    >
      <a-select-option
          v-for="item in configPage.pageConfig"
          :key="item.id"
          v-bind:value="item.id"
      >
        <img
            v-if="item.page_type === 2"
            draggable="false"
            src="@/assets/icons/zalo-seeklogo.com.svg"
            style="width: 20px;margin-right: 20px"
        />
        <img
            v-else-if="item.page_type === 1"
            draggable="false"
            src="@/assets/icons/facebook-svgrepo-com.svg"
            style="width: 20px;margin-right: 20px"
        />
        <img
            v-else-if="item.page_type === 3"
            draggable="false"
            src="@/assets/icons/instagram-svgrepo-com.svg"
            style="width: 20px;margin-right: 20px"
        />
        <img
            v-else-if="item.page_type === 4"
            draggable="false"
            src="@/assets/icons/line-svgrepo-com.svg"
            style="width: 20px;margin-right: 20px"
        />{{ item.page_name }}
      </a-select-option>
    </a-select>
  </a-modal>



</template>
<script  >

import { AppstoreOutlined,CheckOutlined} from '@ant-design/icons-vue';
import {computed, onMounted, ref, watch} from "vue";
import {useStore} from "@/store";
import {useRouter} from "vue-router";
import {CustomersActionTypes} from "@/store/modules/customer/action-types";
import {UsersMutationTypes} from "@/store/modules/user/mutation-types";
import APIs from "@/APIs/APIs";
import {message} from "ant-design-vue";
import {useI18n} from "vue-i18n"
import {PageConfigActionTypes} from "@/store/modules/PageConfig/action-types";
import {PageConfigMutationTypes} from "@/store/modules/PageConfig/mutation-types";
import {MixUtils} from "@/utils/Utils";
import usePageConfigStorageStore from "../../store/PageConfig";
import useUserStore from "../../store/User";
export default {
  components: {
    AppstoreOutlined,
    CheckOutlined
  },
  setup(){
    const store = useStore()
    const router = useRouter()
    const colors = ref(['#0084ff',"#44bec7","#ffc300","#fa3c4c","#d696bb","#13cf13","#ff7e29","#e68585","#7646ff","#20cef5","#ff5ca1"])
    const userInfo = computed(()=> store.getters.getUserInfo)
    const currentShop =  useUserStore()
    const i18n = useI18n()
    const getShops = useUserStore()
    const tabkey = computed(() => store.getters.getCrrentTab)

    const isPage = ref(false)
    const visible = ref(false);
    const minutes = ref(10);
    const fontSize = ref(16)
    const selectColor = ref("")
    const configPage = usePageConfigStorageStore()
    const getCurrentPageId = computed(()=> store.getters.getCurrentPageId)
     const getAdminAndShopOwner = computed(() => store.getters.getAdminAndShopOwner)
    const configCurrentPage = ref({})
const changeAll = (value)=>{
      if(value){
        configData.value.auto_hide_comment_phone = false
        configData.value.auto_hide_keyword = false
      }else {
        configData.value.auto_hide_comment_phone = true
      }
}

    const showModal = () => {
      visible.value = true;
    };


    const changeShop = async (shopId) => {
      const response = await APIs.init().changeShop({
        shop_id: shopId,
        token: userInfo.value.token
      })
      if(response.status == 200 && response.data?.data){

        store.commit(UsersMutationTypes.SET_UserInfo,{...userInfo.value, ...{token: response.data?.data}})
        store.commit(PageConfigMutationTypes.GET_PAGE_CONFIG,'')
        store.commit(UsersMutationTypes.SET_CurrentShop,shopId);

        router.go()
      }
    }
    watch(tabkey, async (newQuestion, oldQuestion) => {
      if(newQuestion !== oldQuestion){
        if( configPage.pageConfig){
          isPage.value=true
          configPage.pageConfig.map(item => {
            // @ts-ignore
            if(item.page_id === getCurrentPageId.value){
              configCurrentPage.value = item
              configData.value = JSON.parse(item.config_data)
              console.log(configData.value.tags,'tags')

            }

          })

        }
      }
    })
    const configData = ref({})
    const changePage = (pageId) => {
      if(pageId){
        store.commit(PageConfigMutationTypes.GET_PAGE_CONFIG,pageId)
      }
      if( configPage.pageConfig){
        isPage.value=true
        configPage.pageConfig.map(item => {
          if(item.page_id === pageId){
            configCurrentPage.value = item
            console.log(configCurrentPage.value,'configCurrentPage')
            configData.value = JSON.parse(item.config_data)
               console.log(configData.value,'1')
          }

        })

      }



    }
    const fetchApi = async () => {
      usePageConfigStorageStore.getState().getPageConfig({shop_id: useUserStore.getState().currentShop.id})
    }
    const save = async () =>{
      if(typeof configData.value.missing_time !== "number"){
        configData.value.missing_time = Number(configData.value.missing_time)
      }
      if(typeof configData.value.font_size_message !== "number") {
        configData.value.font_size_message = Number(configData.value.missing_time)
      }

      if(  isNaN(configData.value.missing_time) ){
        configData.value.missing_time = 1
      }
      if( isNaN(configData.value.font_size_message) ) {
        configData.value.font_size_message = 16
      }
      console.log(
          configData.value.missing_time,
          {
              config_data: JSON.stringify(configData.value) ,
              page_id: configCurrentPage.value.page_id,
              shop_id: useUserStore.getState().currentShop.id,
              page_type:configCurrentPage.value.page_type ,
              page_name: configCurrentPage.value.page_name,
              status:configCurrentPage.value.status
            }
            ,"pageUpdate"
      )

      try{
        const res = await APIs.init().updatePageConfig({
          config_data: JSON.stringify({...configData.value,
          }) ,
          page_id: configCurrentPage.value.page_id,
          shop_id: useUserStore.getState().currentShop.id,
          page_type:configCurrentPage.value.page_type ,
          page_name: configCurrentPage.value.page_name,
          status:configCurrentPage.value.status,
          page_token:configCurrentPage.value.page_token


        })



        if(res.data?.status === 200){
          message.success(i18n.t(res.data?.message))
          await fetchApi()
          visible.value =false

        }
      }catch (err){
        console.log(err)
      }

    }
    const copySettingPage = (data)=>{

      configData.value = JSON.parse(configPage.pageConfig.find((item)=>item.id === data).config_data)

    }

    const checked = ref(false)
    onMounted(() => {
      if(getCurrentPageId.value && configPage.pageConfig){
        isPage.value=true
        configPage.pageConfig.map(item => {
          if(item.page_id === getCurrentPageId.value){
            configCurrentPage.value = item
            configData.value = JSON.parse(item.config_data)

          }

        })

      }

    })
    return{

      currentShop,
      checked,
      copySettingPage,
      configPage,
      changeShop,
      changePage,
      configData,
      isPage,
      showModal,
      visible,
      minutes,
      fontSize,
      colors,
      selectColor,save,configCurrentPage,userInfo,getShops,getCurrentPageId,changeAll,
      getAdminAndShopOwner

    }
  }
}
</script>
