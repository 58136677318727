import {GetterTree} from 'vuex';

import {RootState} from '@/store';

import {BookingTag, ConfigData, ConversationTag, Employee, Page, Shop, SuperUserInfo} from '@/models/Users';

import {State} from './states';
import {Locale} from "@/models/locales/Locales";
import {SelectionAnt} from "@/models/ShopPages/ConfigData";
import {FacebookPostData} from "@/models/facebook/FacebookPost";
import {MixUtils} from "@/utils/Utils";
import {Notification} from "@/models/notification/Notification";
import {Schedule} from "@/models/notification/Schedule";
import {Metadata} from "@/APIs/response/S_HttpResponse";
import useUserStore from "@/store/User";

export type Getters = {
    getUserInfo(state: State): SuperUserInfo | null;
    getIsAdminOrShopOwner(state: State): boolean;
    getAdminAndShopOwner(state: State): Employee[];
    getIsLogin(state: State): boolean;
    getUserInfoFetching(state: State): boolean;
    getLang(state: State): string;
    getPageAccessLatest(state: State): string;
    getCurrentShop(state: State): Shop | undefined;
    getShops(state: State): Shop[] | undefined;
    getPages(state: State): Page[];
    getLocales(state: State): Locale[];
    getListEmployeeAntSelection(state: State): SelectionAnt[];
    getPagesSelection(state: State): SelectionAnt[];
    getOneEmployeeById(state: State): (id: number) => Employee;
    getConversationTagById(state: State): (pageId?: string, tagId?: string) => ConversationTag;
    getNotifications(state: State): Notification[];
    getCountUnreadNotification(state: State): number;
    getSchedules(state: State): Schedule[];
    getScheduleMetaData(state: State): Metadata;
    getBookingTags(state: State): BookingTag[];
    getMenuCollapsed(state: State): boolean;
    getCodeVerifier(state:State): string
    getRole(state:State): any
    getConverstionTag(state:State):any
    getPostsSelection(state:State):any
}

export const getters: GetterTree<State, RootState> & Getters = {
    getLocales(state: State): Locale[] {
        return state.locales;
    },
    getUserInfo: (state) => useUserStore.getState().userInfo,
    getCodeVerifier: (state) => state.code_verifier,
    getIsLogin: (state) => state.isLogin,
    getUserInfoFetching: (state) => state.isUserInfoFetching,
    getLang: (state) => state.lang,
    getPageAccessLatest: (state) => state.pageAccessLatest,
    getCurrentShop: (state) => {
        if (state.userInfo?.current_shop_id) {
            const shop = state.shops.find(x => x.id === state.userInfo?.current_shop_id)
            if (shop) {
                return shop;
            } else {
                return state.shops ? state.shops[0] : undefined
            }
        } else {
            return state.shops ? state.shops[0] : undefined
        }
    },
    getShops: (state) => {
        return state.shops
    },
    getPages: (state) => {
        if (useUserStore.getState().currentShop.id) {
            const shop = useUserStore.getState().shops?.find(x => x.id === useUserStore.getState().currentShop.id)
            if (shop && shop.pages) {
                return shop.pages
            }
        }
        return []
    },
    getPagesSelection: (state): SelectionAnt[] => {
        if (state.userInfo?.current_shop_id) {
            const shop = state.shops?.find(x => x.id === state.userInfo?.current_shop_id)
            if (shop && shop.pages) {
                const data = shop.pages?.map((item) => {
                    const data: SelectionAnt = {
                        label: item.page_name,
                        value: item.page_id
                    }
                    return data;
                })
                const itemAll: SelectionAnt = {
                    label: 'ALL',
                    value: '0',
                }
                return [itemAll, ...data]
            }
        }
        return []
    },
    getListEmployeeAntSelection: (state): SelectionAnt[] => {
        if (state.userInfo?.current_shop_id) {
            const shop = state.userInfo?.shops?.find(x => x.id === state.userInfo?.current_shop_id)
            if (shop && shop.employees) {
                return shop.employees.map((item: Employee): SelectionAnt => {
                    return {
                        label: item.name,
                        value: item.id
                    }
                })
            }
        }
        return []
    },
    getOneEmployeeById: (state: State) => (id: number): Employee => {
        if (state.userInfo?.current_shop_id) {
            const shop = state.shops?.find(x => x.id === state.userInfo?.current_shop_id)
            if (shop && shop.employees) {
                const employee = shop.employees.find(x => x.id === id)
                if (employee) {
                    return employee
                }
            }
        }
        return {}
    },
    getPostsSelection: (state) => (str: string): FacebookPostData[] => {
        if (str) {
            const search = MixUtils.methods.removeVietnamese2(str)
            console.log("search", search)
            return state.selectedPosts.filter(x => x.id.includes(str) || (x.message && MixUtils.methods.removeVietnamese2(x.message).includes(search))
                || (x.story && x.story.includes(search)) ||
                (x.attachments && x.attachments.length > 0 && x.attachments[0].title && MixUtils.methods.removeVietnamese2(x.attachments[0].title).includes(search)))
        } else {
            return state.selectedPosts
        }
    },
    getConversationTagById: (state) => (pageId?: string, tagId?: string): ConversationTag => {
        if (state.userInfo?.current_shop_id) {
            const shop = state.shops?.find(x => x.id === state.userInfo?.current_shop_id)
            if (shop && shop.pages) {
                const page = shop.pages.find(p => p.page_id === pageId);
                if (page && page.config_data) {
                    const config = JSON.parse(page.config_data)
                    if (config.tags) {
                        console.log(config.tags)
                        const tag = config.tags.find((x: ConversationTag) => x.tag_id === tagId)
                        if (tag) {
                            return tag;
                        }
                    }
                }
            }
        }
        return {}
    },
    getNotifications: (state): Notification[] => {
        return state.notifications
    },
    getCountUnreadNotification: (state): number => {
        return state.countUnreadNotification
    },
    getIsAdminOrShopOwner: (state): boolean => {
        let employees: Employee[] = []
        if (state.userInfo?.current_shop_id) {
            const shop = state.shops?.find(x => x.id === state.userInfo?.current_shop_id)
            console.log(shop,state.userInfo?.current_shop_id)
            if (shop && shop.employees) {
                employees = shop.employees
            }
        }
        console.log(employees,'eeee')
        if (employees.length) {
            const user = employees.find(x => state.userInfo && (x.id === state.userInfo.id))
            if (user && (user.is_owner || user.id_cms_privileges === 15 || user.id_cms_privileges === 1)) {
                return true
            }
        }
        return false
    },
    getSchedules: (state): Schedule[] => {
        let employees: Employee[] = []
        if (state.userInfo?.current_shop_id) {
            const shop = state.userInfo?.shops?.find(x => x.id === state.userInfo?.current_shop_id)
            if (shop && shop.employees) {
                employees = shop.employees
            }
        }
        const schedules = state.schedules
        schedules.map((item: Schedule) => {
            item.toEmployee = employees.filter(x =>x.id&& item.to.includes(x.id))
            const creator = employees.find(x => item.creator === x.id)
            if (creator&&creator.name) {
                item.creator_name = creator.name
            }
            return item
        })
        return schedules
    },
    getScheduleMetaData(state: State): Metadata {
        return state.scheduleMetadata
    },
    getBookingTags(state: State): BookingTag[] {
        let tags: BookingTag[] = [];
        const getTagInside=(tags2:BookingTag[])=>{
            if (tags2){
                tags=[...tags,...tags2]
                tags2.map((tags3)=>{
                    if (tags3.tags){
                        getTagInside(tags3.tags)
                    }
                })
            }
        }
        if (state.userInfo?.current_shop_id) {
            const shop = state.userInfo?.shops?.find(x => x.id === state.userInfo?.current_shop_id)
            if (shop && shop.pages) {
                 shop.pages?.map((page)=>{
                     const configData = JSON.parse(page.config_data) as ConfigData;
                     if (configData&&configData.booking_tags){
                         getTagInside(configData.booking_tags)
                     }
                 })
            }
        }
        return tags;
    },
    getMenuCollapsed(state:State):boolean{
        return state.menuCollapsed
    },
    getAdminAndShopOwner(state:State):Employee[]{
        if (state.userInfo?.current_shop_id) {
            const shop = state.shops?.find(x => x.id === state.userInfo?.current_shop_id)
            if (shop?.employees) {
                let employees:Employee[]=[]
                for (let i=0;i<shop.employees.length;i++){
                    if (shop.employees[i].is_owner||shop.employees[i])
                        employees=[...employees,shop.employees[i]]
                }
                return employees
            }
        }
        return []
    },
    getRole: (state) =>  (role_name: string) => {
        return state.userInfo?.roles?.find(role => role.name === role_name)
    },
    // @ts-ignore
    getConverstionTag(){
        const pages: any[] = []
        if (useUserStore.getState().userInfo.current_shop_id) {
            // @ts-ignore
            const shop = useUserStore.getState().shops.find(x => x.id === useUserStore.getState().userInfo?.current_shop_id)
            if (shop) {
                shop.pages?.map((item: any) => {

                    pages.push({page_id: item.page_id,page_type:item.page_type,page_name: item.page_name, tags: JSON.parse(item.config_data).tags})

                })

            }
            console.log(pages,shop)
            // config.map((item:any) => {
            //     item.tags.map((tag:any)=>{
            //
            //         const check = tags.find((x:any) => x.tag_id === tag.tag_id)
            //         if(!check){
            //             tags.push(tag)
            //         }
            //
            //     })
            // })
            //     return tags
            // }
            return pages
        }
    },
    // @ts-ignore
    getConversationTags(state:State):any[] {
        let tags: any[] = []
        if (state.userInfo?.current_shop_id) {
            const shop = state.shops.find(x => x.id === state.userInfo?.current_shop_id)
            if (shop) {
                shop.pages?.map((item: any) => {

                    tags = [...tags, ...JSON.parse(item.config_data).tags]

                })

            }
            return tags
        }
    }
};
