import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-eebbf334"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "container-title" }
const _hoisted_2 = { class: "title-header" }
const _hoisted_3 = { class: "container-content" }
const _hoisted_4 = ["onClick"]
const _hoisted_5 = { style: {"display":"flex","justify-content":"center"} }
const _hoisted_6 = ["onClick"]
const _hoisted_7 = ["onClick"]
const _hoisted_8 = {
  key: 0,
  style: {"margin-left":"16px"}
}
const _hoisted_9 = { style: {"font-weight":"bold","font-size":"16px"} }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_plus_outlined = _resolveComponent("plus-outlined")!
  const _component_a_button = _resolveComponent("a-button")!
  const _component_EyeOutlined = _resolveComponent("EyeOutlined")!
  const _component_a_divider = _resolveComponent("a-divider")!
  const _component_EditOutlined = _resolveComponent("EditOutlined")!
  const _component_DeleteOutlined = _resolveComponent("DeleteOutlined")!
  const _component_a_popconfirm = _resolveComponent("a-popconfirm")!
  const _component_a_table = _resolveComponent("a-table")!
  const _component_a_checkbox = _resolveComponent("a-checkbox")!
  const _component_a_input = _resolveComponent("a-input")!
  const _component_a_form_item = _resolveComponent("a-form-item")!
  const _component_a_form = _resolveComponent("a-form")!
  const _component_a_modal = _resolveComponent("a-modal")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("h3", _hoisted_2, _toDisplayString(_ctx.$t(_ctx.$route.name)), 1),
      (_ctx.getMyRole(_ctx.modules.role)?.is_create && _ctx.getMyRole(_ctx.modules.role)?.is_edit)
        ? (_openBlock(), _createBlock(_component_a_button, {
            key: 0,
            type: "primary",
            class: "button-add-product",
            onClick: _ctx.syncRole
          }, {
            default: _withCtx(() => [
              _createVNode(_component_plus_outlined),
              _createTextVNode(" " + _toDisplayString(_ctx.$t('SYNC_ROLES')), 1)
            ]),
            _: 1
          }, 8, ["onClick"]))
        : _createCommentVNode("", true),
      (_ctx.getMyRole(_ctx.modules.role)?.is_create)
        ? (_openBlock(), _createBlock(_component_a_button, {
            key: 1,
            type: "primary",
            class: "button-add-product",
            onClick: _ctx.create
          }, {
            default: _withCtx(() => [
              _createVNode(_component_plus_outlined),
              _createTextVNode(" " + _toDisplayString(_ctx.$t('ADD')), 1)
            ]),
            _: 1
          }, 8, ["onClick"]))
        : _createCommentVNode("", true)
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createVNode(_component_a_table, {
        size: "middle",
        columns: _ctx.columns,
        "data-source": _ctx.roles,
        pagination: false
      }, {
        name: _withCtx(({record}) => [
          _createElementVNode("a", {
            onClick: ($event: any) => (_ctx.viewRole(record))
          }, _toDisplayString(record.name), 9, _hoisted_4)
        ]),
        action: _withCtx(({record}) => [
          _createElementVNode("div", _hoisted_5, [
            (_ctx.getMyRole(_ctx.modules.role)?.is_read)
              ? (_openBlock(), _createElementBlock("a", {
                  key: 0,
                  onClick: ($event: any) => (_ctx.viewRole(record))
                }, [
                  _createVNode(_component_EyeOutlined)
                ], 8, _hoisted_6))
              : _createCommentVNode("", true),
            _createVNode(_component_a_divider, { type: "vertical" }),
            (_ctx.getMyRole(_ctx.modules.role)?.is_edit)
              ? (_openBlock(), _createElementBlock("a", {
                  key: 1,
                  onClick: ($event: any) => (_ctx.editRole(record))
                }, [
                  _createVNode(_component_EditOutlined)
                ], 8, _hoisted_7))
              : _createCommentVNode("", true),
            _createVNode(_component_a_divider, { type: "vertical" }),
            (_ctx.getMyRole(_ctx.modules.role)?.is_delete)
              ? (_openBlock(), _createBlock(_component_a_popconfirm, {
                  key: 2,
                  title: _ctx.$t('CONFIRM_DELETE')+' ' +_ctx.$t('ROLE')+'?',
                  "ok-text": _ctx.$t('OK'),
                  "cancel-text": _ctx.$t('CANCEL'),
                  onConfirm: ($event: any) => (_ctx.deleteRole(record))
                }, {
                  default: _withCtx(() => [
                    _createElementVNode("a", null, [
                      _createVNode(_component_DeleteOutlined)
                    ])
                  ]),
                  _: 2
                }, 1032, ["title", "ok-text", "cancel-text", "onConfirm"]))
              : _createCommentVNode("", true)
          ])
        ]),
        _: 1
      }, 8, ["columns", "data-source"]),
      (_ctx.currentRole)
        ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
            _createElementVNode("span", _hoisted_9, _toDisplayString(_ctx.currentRole?.name), 1),
            _createVNode(_component_a_table, {
              style: {"margin-top":"10px"},
              size: "middle",
              columns: _ctx.columnsPrivilege,
              "data-source": _ctx.currentPrivilegeRole,
              footer: null,
              pagination: false
            }, {
              name: _withCtx(({record}) => [
                _createElementVNode("a", null, _toDisplayString(_ctx.$t('role_' + record.name)), 1)
              ]),
              is_visible: _withCtx(({record}) => [
                _createVNode(_component_a_checkbox, {
                  disabled: !_ctx.getMyRole(_ctx.modules.role)?.is_edit,
                  checked: record.is_visible,
                  "onUpdate:checked": ($event: any) => ((record.is_visible) = $event),
                  onChange: ($event: any) => (_ctx.updatePrivilege(record))
                }, null, 8, ["disabled", "checked", "onUpdate:checked", "onChange"])
              ]),
              is_create: _withCtx(({record}) => [
                _createVNode(_component_a_checkbox, {
                  disabled: !_ctx.getMyRole(_ctx.modules.role)?.is_edit,
                  checked: record.is_create,
                  "onUpdate:checked": ($event: any) => ((record.is_create) = $event),
                  onChange: ($event: any) => (_ctx.updatePrivilege(record))
                }, null, 8, ["disabled", "checked", "onUpdate:checked", "onChange"])
              ]),
              is_read: _withCtx(({record}) => [
                _createVNode(_component_a_checkbox, {
                  disabled: !_ctx.getMyRole(_ctx.modules.role)?.is_edit,
                  checked: record.is_read,
                  "onUpdate:checked": ($event: any) => ((record.is_read) = $event),
                  onChange: ($event: any) => (_ctx.updatePrivilege(record))
                }, null, 8, ["disabled", "checked", "onUpdate:checked", "onChange"])
              ]),
              is_edit: _withCtx(({record}) => [
                _createVNode(_component_a_checkbox, {
                  disabled: !_ctx.getMyRole(_ctx.modules.role)?.is_edit,
                  checked: record.is_edit,
                  "onUpdate:checked": ($event: any) => ((record.is_edit) = $event),
                  onChange: ($event: any) => (_ctx.updatePrivilege(record))
                }, null, 8, ["disabled", "checked", "onUpdate:checked", "onChange"])
              ]),
              is_delete: _withCtx(({record}) => [
                _createVNode(_component_a_checkbox, {
                  disabled: !_ctx.getMyRole(_ctx.modules.role)?.is_edit,
                  checked: record.is_delete,
                  "onUpdate:checked": ($event: any) => ((record.is_delete) = $event),
                  onChange: ($event: any) => (_ctx.updatePrivilege(record))
                }, null, 8, ["disabled", "checked", "onUpdate:checked", "onChange"])
              ]),
              _: 1
            }, 8, ["columns", "data-source"])
          ]))
        : _createCommentVNode("", true)
    ]),
    _createVNode(_component_a_modal, {
      visible: _ctx.editVisible,
      "onUpdate:visible": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.editVisible) = $event)),
      title: _ctx.formState.id?_ctx.$t('UPDATE_ROLE'):_ctx.$t('ADD_ROLE'),
      footer: null
    }, {
      default: _withCtx(() => [
        _createVNode(_component_a_form, {
          ref: "formRef",
          model: _ctx.formState,
          rules: _ctx.rules,
          "label-col": _ctx.labelCol,
          "wrapper-col": _ctx.wrapperCol
        }, {
          default: _withCtx(() => [
            _createVNode(_component_a_form_item, {
              label: _ctx.$t('NAME'),
              name: "name"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_a_input, {
                  value: _ctx.formState.name,
                  "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.formState.name) = $event))
                }, null, 8, ["value"])
              ]),
              _: 1
            }, 8, ["label"]),
            _createVNode(_component_a_form_item, { "wrapper-col": { offset: 8 } }, {
              default: _withCtx(() => [
                _createVNode(_component_a_button, {
                  type: "primary",
                  onClick: _ctx.onSubmit
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.formState.id?_ctx.$t('UPDATE'):_ctx.$t('CREATE')), 1)
                  ]),
                  _: 1
                }, 8, ["onClick"]),
                _createVNode(_component_a_button, {
                  style: {"margin-left":"10px"},
                  onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.editVisible=!_ctx.editVisible))
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.$t('CANCEL')), 1)
                  ]),
                  _: 1
                })
              ]),
              _: 1
            })
          ]),
          _: 1
        }, 8, ["model", "rules", "label-col", "wrapper-col"])
      ]),
      _: 1
    }, 8, ["visible", "title"])
  ], 64))
}