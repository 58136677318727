
import { EventData, State} from "./states";

export type Getters = {

    getEvent(state: State):  EventData

}
export const getters: Getters = {

    getEvent(state: State):  EventData {

        if (state.eventData) {
            return state.eventData
        } else {
            // @ts-ignore
          return {data: [], metadata: {}}
        }
    },


}
