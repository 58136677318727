import { Metadata } from "@/APIs/response/S_HttpResponse"
import {Events} from "@/models/Event/Event";

export type EventData  ={
    events: Events[]| undefined
    metadata: Metadata | undefined
}
export type State = {
    // dataSearch: EmployeeSearch[]| undefined
    eventData: EventData | undefined
}

export const state: State = {
    eventData: {events: [], metadata: {}}
}
