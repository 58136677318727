import {Tag} from "@/models/Tags/Tags";
import {FlowContexts} from "@/models/Flows/FlowContext";

export type Flow = {
    id: number;
    name: string;
    status: string;
    creator: number;
    description: string;
    shop_id: number;
    tags: Tag[];
    created_at: string;
    updated_at: string;
}

export type ZnsTemp = {
    templateId: number;
    templateName: string;
    createdTime: string;
    status: string;
    templateQuality: string;
}

export enum FlowType {
    Flow = 'FLOW',
    Viral = 'VIRAL',
    AutoInbox = 'AUTOINBOX',
}

export enum FlowContextType {
    FACEBOOK = 1,
    ZALO = 2,
}

export enum FlowStatus {
    Draft = 'draft',
    Publish = 'publish',
}
