<template>

  <div class="container-title" >
    <h3 class="title-header">{{$t($route.name)}}</h3>
    <a-row>
     <a-col :span="6">

        <a-input  style="width: 100%;margin-bottom: 16px"  :placeholder="$t('ORDER')">
          <template #suffix>
            <a-tooltip title="Extra information">
              <SearchOutlined style="color: rgba(0, 0, 0, 0.45)" />
            </a-tooltip>
          </template>
        </a-input>
        <a-input    style="width: 100%;margin-bottom: 16px" :placeholder="$t('CUSTOMER')">
          <template #suffix>
            <a-tooltip title="Extra information">
              <SearchOutlined style="color: rgba(0, 0, 0, 0.45)" />
            </a-tooltip>
          </template>
        </a-input>



      </a-col>
      <a-col :span="1">

      </a-col>
      <a-col :span="6">
        <a-select
            v-model:value="selectedPage"
            @change="handleEmployeeChange"
            :allowClear="true"
            :dropdownMatchSelectWidth="true"
            style="width: 100%; margin-bottom: 16px"
            :placeholder="$t('EMPLOYEE')"
        >
          <a-select-option
              v-for="item in employeeStore.employees"
              :key="item.id"
              v-bind:value="item.id"
          >{{ item.name }}
          </a-select-option>
        </a-select>
        <a-select
            v-model:value="selectedPage"
            @change="handlePageChange"
            :allowClear="true"
            :dropdownMatchSelectWidth="true"
            style="width: 100%; margin-bottom: 16px"
            :placeholder="$t('ALL')"
        >
          <a-select-option
              v-for="item in getPages"
              :key="item.id"
              v-bind:value="item.page_id"
          >{{ item.page_name }}
          </a-select-option>
        </a-select>

      </a-col>

    <a-col :span="1">

    </a-col>
    <a-col :span="6">
      <a-input   style="width: 100%;margin-bottom: 16px"  :placeholder="$t('PRODUCT')">
        <template #suffix>
          <a-tooltip title="Extra information">
            <SearchOutlined style="color: rgba(0, 0, 0, 0.45)" />
          </a-tooltip>
        </template>
      </a-input>
      <a-range-picker
          :allowClear="false"
          v-model:value="rangeTime"
          :show-time="{ format: 'HH:mm' }"
          format="DD/MM/YYYY HH:mm"
          :placeholder="[$t('START_TIME_PLACEHOLDER'), $t('END_TIME_PLACEHOLDER')]"
          @change="onChangeDateTime"
          @ok="confirmDateTime"
      >
        <template #suffixIcon>
          <CalendarOutlined/>
        </template>
      </a-range-picker>
    </a-col>
    </a-row>
  </div>
  <div style="padding: 16px">


    <a-card class="card_view" style="border-radius: 10px; margin: 16px;">
      <keep-alive>
        <highcharts
            class="chart" :options="{
      chart: {
        type: 'column',
      },
      title: {
        text: $t('Đơn hàng lọc theo thời gian')
      },
      xAxis: {
        categories: chartDataReportCareAll.label
      },
      yAxis: {
        min: 0,
        title: {
          text: null
        },
      },
      legend: {
                enabled:false,
        align: 'right',
        verticalAlign: 'top',
        borderColor: '#CCC',
        borderWidth: 1,
        shadow: false
      },
      tooltip: {
        headerFormat: '<b>{point.x}</b><br/>',
        enable: false
      },
      plotOptions: {
        column: {
          colors: chartDataReportCareAll.color,
          stacking: 'normal',
          dataLabels: {
            enabled: true
          }
        }
      },
      series: chartDataReportCareAll.total ? [
          {
            data: chartDataReportCareAll.total&&chartDataReportCareAll.total.map((total, index)=>({
        y: chartDataReportCareAll.total[index],
        color: chartDataReportCareAll.color[index],
      }))
          }
      ]:[]
    }"></highcharts>

      </keep-alive>
    </a-card>
  </div>
  <div style="padding: 16px">


    <a-table
        :columns="columns"
        :data-source="fixedData"
        :pagination="false"
        :scroll="{ x: 2000, y: 500 }"
        bordered
    >
      <template #summary>
        <a-table-summary fixed>
          <a-table-summary-row>
            <a-table-summary-cell :index="0">{{$t('TOTAL')}}</a-table-summary-cell>
            <a-table-summary-cell :index="1">This is a summary content</a-table-summary-cell>
            <a-table-summary-cell :index="1">This is a summary content</a-table-summary-cell>
            <a-table-summary-cell :index="1">This is a summary content</a-table-summary-cell>
            <a-table-summary-cell :index="1">This is a summary content</a-table-summary-cell>
            <a-table-summary-cell :index="1">This is a summary content</a-table-summary-cell>
            <a-table-summary-cell :index="1">This is a summary content</a-table-summary-cell>
            <a-table-summary-cell :index="1">This is a summary content</a-table-summary-cell>
            <a-table-summary-cell :index="1">This is a summary content</a-table-summary-cell>

          </a-table-summary-row>
        </a-table-summary>
      </template>
    </a-table>
  </div>
</template>

<script>

import {useStore} from "vuex";
import {ShopinfoActionTypes} from "@/store/modules/Shopinfo/action-types";
import {computed, onMounted, ref} from "vue";
import moment, {Moment} from "moment";
import {useI18n} from "vue-i18n";
import APIs from "@/APIs/APIs";
import {MixUtils} from "@/utils/Utils";
import {CalendarOutlined,SearchOutlined} from "@ant-design/icons-vue";
import dayjs from "dayjs";
import {ReportPAGEActionTypes} from "../../../store/modules/ReportPage/action-types";
import useEmployeeStore from "../../../store/Employee";




export default {
  components: {
    CalendarOutlined,
    SearchOutlined
  },
  setup() {

    const i18n = useI18n()
    const store = useStore();
    const rangeTime = ref([dayjs().add(-1, 'months').startOf('day'), dayjs().endOf('day')])

    const fetchShopInfo = () => {
      store.dispatch(ShopinfoActionTypes.FETCH_SHOPINFO);
    };
    const fetchCusomer = () => {
      store.dispatch(ReportPAGEActionTypes.FETCH_CUSTOMER_REPORT,{ shop_id: dataShopInfo.value?.id,
        page_id: selectedPage.value,
        from_date: rangeTime.value[0].format('YYYY-MM-DD HH:mm'),
        to_date: rangeTime.value[1].format('YYYY-MM-DD HH:mm'),});
    };
    const dataShopInfo = computed(() => store.getters.getShopinfo);
    const pagesSelection = computed(() => store.getters.getPagesSelection);
    const getPages = computed(() => store.getters.getPages);
    const customerData =computed(()=> store.getters.getReportCusTomer)
    const selectedPage = ref();
    const chartDataReportCareAll = ref({
      total: [],
      label: [],
      color: [],
    })
    const columns = [
      {
        title: i18n.t('day'),
        dataIndex: 'customer_name',
        key: 'customer_name',
        width: 150,
        fixed: 'left',
      },
      {
        title: i18n.t('NEW_ORDERS'),
        dataIndex: 'status',
        key: 'status',
        slots: {customRender: "status"},
        width: 100,
      },
      {
        title: i18n.t('STATUS_ORDER_CANCEL'),
        dataIndex: 'assigee',
        key: 'assigee',
        slots: {customRender: "assigee"},
        width: 100,
      },
      {
        title: i18n.t('SUCCESS_SHIPPED'),
        dataIndex: 'birth',
        key: 'birth',
        slots: {customRender: "birth"},
        width: 100,
      },
      {
        title: i18n.t('RETURNING'),
        dataIndex: 'order_from_new_customer_percent',
        key: 'order_from_new_customer_percent',
        slots: {customRender: "order_not_ads_percent"},
        width: 100,
      },
      {
        title: i18n.t('Tỉ lệ hoàn'),
        dataIndex: 'order_from_new_customer_percent',
        key: 'order_from_new_customer_percent',
        slots: {customRender: "order_not_ads_percent"},
        width: 100,
      },

      {
        title: i18n.t('Tỉ lệ chốt'),
        dataIndex: 'order_from_new_customer_percent',
        key: 'order_from_new_customer_percent',
        slots: {customRender: "order_not_ads_percent"},
        width: 100,
      },
      {
        title: i18n.t('Hàng bán ra'),
        dataIndex: 'order_from_new_customer_percent',
        key: 'order_from_new_customer_percent',
        slots: {customRender: "order_not_ads_percent"},
        width: 100,
      },
      {
        title: i18n.t('REVENUE'),
        dataIndex: 'order_from_new_customer_percent',
        key: 'order_from_new_customer_percent',
        slots: {customRender: "order_not_ads_percent"},
        width: 100,
      },
      {
        title: i18n.t('Lợi nhuận'),
        dataIndex: 'order_from_new_customer_percent',
        key: 'order_from_new_customer_percent',
        slots: {customRender: "order_not_ads_percent"},
        width: 100,
      },
    ];






    const splineChart = ref()
    const employeeStore = useEmployeeStore()

    const handleReport = () => {
      // fetchReportComment();
      // fetchReportMessage();
      // fetchReportNewConversation();
    };

    onMounted(() => {

      fetchShopInfo();
      fetchCusomer()

    });

    const formatMoney = (val) => {
      return MixUtils.methods.formatMoney(val)
    }

    const calculatorPercent = (a, total) => {
      return Math.round(a / total * 10000) / 100
    }


    const handlePageChange = (val) => {
      selectedPage.value = val;
      console.log(val)
      fetchCusomer()
    };


    const onChangeDateTime = (value, dateString) => {
      console.log('Selected Time: ', value);
      console.log('Formatted Selected Time: ', dateString);
      if (value[0].format('HH-mm') === value[1].format('HH-mm')) {
        rangeTime.value = [value[0].startOf('day'), value[1].endOf('day')]

      } else {
        rangeTime.value = value
      }
      fetchCusomer()
    };

    const confirmDateTime = (value) => {
      console.log('onOk: ', value);
    };


    return {
      dataShopInfo,
      pagesSelection,
      handlePageChange,
      handleReport,
      selectedPage,
      splineChart,
      formatMoney,
      calculatorPercent,
      getPages,
      onChangeDateTime,
      confirmDateTime,
      rangeTime,
      columns,
      customerData,
      chartDataReportCareAll,
      employeeStore
    };
  },
};
</script>

<style>
.card_view {
  border-radius: 10px;
  margin: 4px;
  overflow: hidden;
}

.count-elements {
  -webkit-tap-highlight-color: transparent;
  -webkit-text-size-adjust: 100%;
  font-family: Roboto, Helvetica, Arial, sans-serif;
  font-variant: tabular-nums;
  line-height: 1.5;
  font-feature-settings: 'tnum';
  font-size: 14px;
  color: #000;
  box-sizing: border-box;
  height: 76px;
  padding: 10px;
  border-top: 1px solid #e0e0e0;
  border-bottom: 1px solid #e0e0e0;
  border-radius: 10px 0px 0px 10px;
  cursor: pointer;
  box-shadow: 0px 2px 10px rgba(51, 51, 51, 0.1);
  border-left: 10px solid rgb(43, 216, 196);
  background-color: rgba(196, 255, 248, 0.23);
  width: 100%;
}

.title {
  font-family: "Roboto" !important;
  font-style: normal !important;
  font-weight: 700 !important;
  font-size: 12px !important;
  line-height: 22px !important;
  color: #000000 !important;
  margin-left: 0px !important;
  margin-top: 0px !important;
  text-align: left !important;
  width: 100% !important;
}

.count {
  text-align: right;
  font-weight: 100;
  font-family: Montserrat-Light;
  font-size: 25px;
  line-height: 30px;
  color: #263C8F;
  float: right;
}

.highcharts-credits {
  display: none;
}
</style>
