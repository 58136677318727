import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_input = _resolveComponent("a-input")!
  const _component_a_form_item = _resolveComponent("a-form-item")!
  const _component_a_tag = _resolveComponent("a-tag")!
  const _component_a_select_option = _resolveComponent("a-select-option")!
  const _component_a_select = _resolveComponent("a-select")!
  const _component_a_textarea = _resolveComponent("a-textarea")!
  const _component_arrow_right_outlined = _resolveComponent("arrow-right-outlined")!
  const _component_a_button = _resolveComponent("a-button")!
  const _component_a_form = _resolveComponent("a-form")!

  return (_openBlock(), _createBlock(_component_a_form, {
    ref: "formRef",
    model: _ctx.formState,
    rules: _ctx.rules,
    class: "main-flow",
    "label-col": _ctx.labelCol,
    "wrapper-col": _ctx.wrapperCol
  }, {
    default: _withCtx(() => [
      _createVNode(_component_a_form_item, {
        label: _ctx.$t('FLOW_FLOW_NAME'),
        name: "name"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_a_input, {
            showCount: "",
            maxlength: 99,
            value: _ctx.formState.name,
            "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.formState.name) = $event)),
            type: "text",
            placeholder: "Aaa..."
          }, null, 8, ["value"])
        ]),
        _: 1
      }, 8, ["label"]),
      _createVNode(_component_a_form_item, {
        label: _ctx.$t('FLOW_TAGS'),
        name: "tags"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_a_select, {
            style: {"text-align":"left"},
            mode: "multiple",
            value: _ctx.formState.tags,
            "onUpdate:value": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.formState.tags) = $event)),
            onChange: _ctx.tagSelectionChange,
            placeholder: "tag1, tag2, ..."
          }, {
            default: _withCtx(() => [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.flowTags, (item) => {
                return (_openBlock(), _createBlock(_component_a_select_option, {
                  value: item.id,
                  key: item.id,
                  color: item.color
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_a_tag, {
                      color: item.color
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(item.name), 1)
                      ]),
                      _: 2
                    }, 1032, ["color"])
                  ]),
                  _: 2
                }, 1032, ["value", "color"]))
              }), 128))
            ]),
            _: 1
          }, 8, ["value", "onChange"])
        ]),
        _: 1
      }, 8, ["label"]),
      _createVNode(_component_a_form_item, {
        label: _ctx.$t('FLOW_DESCRIPTION'),
        name: "description"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_a_textarea, {
            value: _ctx.formState.description,
            "onUpdate:value": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.formState.description) = $event)),
            placeholder: "Aaa..."
          }, null, 8, ["value"])
        ]),
        _: 1
      }, 8, ["label"]),
      _createVNode(_component_a_form_item, {
        "wrapper-col": { span: 14, offset: 4 },
        style: {"text-align":"center"}
      }, {
        default: _withCtx(() => [
          _createVNode(_component_a_button, {
            type: "primary",
            onClick: _ctx.onSubmit,
            loading: _ctx.nextLoading
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t('SETUP_CONTEXT')) + " ", 1),
              _createVNode(_component_arrow_right_outlined)
            ]),
            _: 1
          }, 8, ["onClick", "loading"])
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["model", "rules", "label-col", "wrapper-col"]))
}