import {ActionContext, ActionTree} from "vuex";
import {State} from "./states";
import {RootState} from "@/store";
import APIs from "@/APIs/APIs";
import {Mutations} from "./mutations";
import {CustomersCaresResponse} from "@/APIs/response/S_HttpResponse";
import {CustomerCaresActionTypes} from "@/store/modules/customerCare/action-types";
import {CustomerCaresMutationTypes} from "@/store/modules/customerCare/mutation-types";

type AugmentedActionContext = {
    commit<K extends keyof Mutations>(
        key: K,
        payload: Parameters<Mutations[K]>[1],
    ): ReturnType<Mutations[K]>;
} & Omit<ActionContext<State, RootState>, 'commit'>

export interface Actions {
    [CustomerCaresActionTypes.FETCH_CUSTOMERS_CARES](
        {commit}: AugmentedActionContext,
    ): Promise<CustomersCaresResponse | undefined>;
    [CustomerCaresActionTypes.FETCH_CUSTOMERS_DETAIL_CARES](
        {commit}: AugmentedActionContext,
    ): Promise<CustomersCaresResponse | undefined>;
}

export const actions: ActionTree<State, RootState> & Actions = {
    async [CustomerCaresActionTypes.FETCH_CUSTOMERS_CARES]({commit}): Promise<CustomersCaresResponse | undefined> {
        const response = await APIs.init().getCustomerCares()
        if (response.status === 200) {
            commit(CustomerCaresMutationTypes.SET_CustomersCares, response.data?.data)
        }
        return response.data;
    },
    async [CustomerCaresActionTypes.FETCH_CUSTOMERS_DETAIL_CARES]({commit}): Promise<CustomersCaresResponse | undefined> {
        const response = await APIs.init().getDetailCustomerCares()
        if (response.status === 200) {
            commit(CustomerCaresMutationTypes.SET_CUSTOMERS_DETAIL_CARES, response.data?.data)
        }
        return response.data;
    }
}
