import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "container-title" }
const _hoisted_2 = { class: "title-header" }
const _hoisted_3 = {
  style: {"display":"flex","flex-direction":"column"},
  class: "container-content"
}
const _hoisted_4 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!
  const _component_a_button = _resolveComponent("a-button")!
  const _component_a_popconfirm = _resolveComponent("a-popconfirm")!
  const _component_delete_outlined = _resolveComponent("delete-outlined")!
  const _component_a_card_meta = _resolveComponent("a-card-meta")!
  const _component_a_card = _resolveComponent("a-card")!
  const _component_a_row = _resolveComponent("a-row")!
  const _component_a_pagination = _resolveComponent("a-pagination")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("h3", _hoisted_2, _toDisplayString(_ctx.$t(_ctx.$route.name)), 1),
      _createVNode(_component_router_link, { to: "/ticket/edit-ticket/:id" }),
      _createVNode(_component_a_popconfirm, {
        placement: "leftTop",
        title: _ctx.$t('datamodal_select')
      }, {
        cancelButton: _withCtx(() => [
          _createVNode(_component_router_link, { to: "/mail_editor/default" }, {
            default: _withCtx(() => [
              _createVNode(_component_a_button, null, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t('TEMPLATE_DEFAULT')), 1)
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        okButton: _withCtx(() => [
          _createVNode(_component_router_link, { to: "/mail_editor/:id" }, {
            default: _withCtx(() => [
              _createVNode(_component_a_button, null, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t('TEMPLATE_NEW')), 1)
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        default: _withCtx(() => [
          _createVNode(_component_a_button, { type: "primary" }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t('ADD')), 1)
            ]),
            _: 1
          })
        ]),
        _: 1
      }, 8, ["title"])
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createVNode(_component_a_row, null, {
        default: _withCtx(() => [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.templates.templates, (i) => {
            return (_openBlock(), _createBlock(_component_a_card, {
              key: i,
              hoverable: "",
              style: {"width":"580px","margin":"20px","height":"400px"}
            }, {
              actions: _withCtx(() => [
                _createVNode(_component_a_popconfirm, {
                  title: _ctx.$t('TEMPLATE_CONFIRM_DELETE_CONTEXT'),
                  "ok-text": _ctx.$t('YES'),
                  "cancel-text": _ctx.$t('NO'),
                  onConfirm: ($event: any) => ($setup.deleteTemplate(i))
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_delete_outlined, { key: "delete" })
                  ]),
                  _: 2
                }, 1032, ["title", "ok-text", "cancel-text", "onConfirm"])
              ]),
              default: _withCtx(() => [
                _createVNode(_component_a_card_meta, {
                  onClick: ($event: any) => ($setup.editTemplate(i.id))
                }, {
                  title: _withCtx(() => [
                    _createTextVNode(_toDisplayString(i.name), 1)
                  ]),
                  description: _withCtx(() => [
                    _createElementVNode("div", {
                      style: {"height":"300px","overflow-y":"scroll"},
                      innerHTML: $setup.formatBase64(i.content),
                      ref_for: true,
                      ref: "template"
                    }, null, 8, _hoisted_4)
                  ]),
                  _: 2
                }, 1032, ["onClick"])
              ]),
              _: 2
            }, 1024))
          }), 128))
        ]),
        _: 1
      }),
      _createVNode(_component_a_row, { style: {"justify-content":"flex-end"} }, {
        default: _withCtx(() => [
          _createVNode(_component_a_pagination, {
            onChange: $setup.changePagination,
            style: {"margin":"20px"},
            current: $setup.page,
            "onUpdate:current": _cache[0] || (_cache[0] = ($event: any) => (($setup.page) = $event)),
            total: $setup.templates.metadata.record_total,
            "show-less-items": ""
          }, null, 8, ["onChange", "current", "total"])
        ]),
        _: 1
      })
    ])
  ], 64))
}