import create from "zustand-vue";
import APIs from "@/APIs/APIs";
import {Tag, TreeTag, TypeTag} from "@/models/Tags/Tags";
import {persist, createJSONStorage} from 'zustand/middleware'
import {TagsMutationTypes} from "@/store/modules/tag/mutation-types";
import useUserStore from "@/store/User";
interface TagState {
    getTagLoading: boolean
    tagSales: Tag[];
    tagFlows: Tag[];
    tagTriggers: Tag[];
    tagVirals: Tag[];
    tagProducs: Tag[];
    tagTree: TreeTag[]
    pageConverstionTag:any[],
    metadata:any;
    // eslint-disable-next-line @typescript-eslint/ban-types
    GetTag:  (params: any,onSuccess?:Function)=>void;
    // eslint-disable-next-line @typescript-eslint/ban-types
    GetTreeTag:  (params: any,onSuccess?:Function)=>void;
    // eslint-disable-next-line @typescript-eslint/ban-types
    getConverstionTag:()=>void;
    // eslint-disable-next-line @typescript-eslint/ban-types
    GetRootTag: (params: any,onSuccess?:Function)=>void;
}

const initialState = {
    getTagLoading: true,
    tagSales: [],
    tagTree:[],
    tagProducs:[],
    tagFlows:[],
    tagVirals:[],
    tagTriggers:[],
    metadata:{},
    pageConverstionTag:[],
}


// @ts-ignore
const useTagStore = create<TagState>(persist((set) => ({
    ...initialState,

    GetTag: async (params,onSuccess) =>{
        set(()=>({getTagLoading: true}))

        const tags = await APIs.init().getTags(params)

        if(tags.data?.status===200){
            switch (params.type) {
                case TypeTag.FLOW_TAGS:
                    set(()=>({getTagLoading: false, tagFlows: tags.data?.data ? tags.data?.data  :[] }))
                    break
                case TypeTag.CUSTOMER_TAGS:
                    set(()=>({ getTagLoading: false,tagSales: tags.data?.data ? tags.data?.data  :[] }))
                    break
                case TypeTag.TRIGGER_TAGS:
                    set(()=>({getTagLoading: false, tagTriggers: tags.data?.data ? tags.data?.data  :[] }))
                    break
                case TypeTag.VIRAL_TAGS:
                    set(()=>({getTagLoading: false, tagVirals: tags.data?.data ? tags.data?.data  :[] }))
                    break
                case TypeTag.PRODUCT_TAGS:
                    set(()=>({getTagLoading: false, tagProducs: tags.data?.data ? tags.data?.data  :[] }))
                    break
            }
            console.log(tags.data.data)

            // @ts-ignore

            // onSuccess()
        }else{
            set(()=>({ tagSales:[]}))
        }


    },
        GetRootTag: async (params,onSuccess) =>{
            set(()=>({getTagLoading: true}))

            const tags = await APIs.init().getRootTags(params)

            if(tags.data?.status===200){
                switch (params.type) {
                    case TypeTag.FLOW_TAGS:
                        set(()=>({getTagLoading: false, tagFlows: tags.data?.data ? tags.data?.data  :[] }))
                        break
                    case TypeTag.CUSTOMER_TAGS:
                        set(()=>({ getTagLoading: false,tagSales: tags.data?.data ? tags.data?.data  :[] }))
                        break
                    case TypeTag.TRIGGER_TAGS:
                        set(()=>({getTagLoading: false, tagTriggers: tags.data?.data ? tags.data?.data  :[] }))
                        break
                    case TypeTag.VIRAL_TAGS:
                        set(()=>({getTagLoading: false, tagVirals: tags.data?.data ? tags.data?.data  :[] }))
                        break
                    case TypeTag.PRODUCT_TAGS:
                        set(()=>({getTagLoading: false, tagProducs: tags.data?.data ? tags.data?.data  :[] }))
                        break
                }
                console.log(tags.data.data)

                // @ts-ignore

                // onSuccess()
            }else{
                set(()=>({ tagSales:[]}))
            }


        },
        GetTreeTag: async (params,onSuccess) =>{
            // set(()=>({getCustomerLoading: true}))

            const Treetags = await APIs.init().getTreeTags(params)

            if(Treetags.data?.status===200){
                set(()=>({ tagTree: Treetags.data?.data ? Treetags.data?.data  :[] }))
                console.log(Treetags.data.data)

                // @ts-ignore

                // onSuccess()
            }else{
                set(()=>({ tagTree:[]}))
            }


        },
        getConverstionTag(){
            const pages: any[] = []
            if (useUserStore.getState().currentShop.id) {
                // @ts-ignore
                const shop = useUserStore.getState().shops.find(x => x.id === useUserStore.getState().currentShop.id)
                if (shop) {
                    shop.pages?.map((item: any) => {

                        pages.push({page_id: item.page_id,page_type:item.page_type,page_name: item.page_name, tags: JSON.parse(item.config_data).tags})

                    })

                }
                console.log(pages,shop)
                // config.map((item:any) => {
                //     item.tags.map((tag:any)=>{
                //
                //         const check = tags.find((x:any) => x.tag_id === tag.tag_id)
                //         if(!check){
                //             tags.push(tag)
                //         }
                //
                //     })
                // })
                //     return tags
                // }
                set(()=>({ pageConverstionTag: pages ? pages  :[] }))
            }
        },

}  )
    ,
    {
        name: 'tag'
    }
))

export default useTagStore
