import {State} from "./states";
import {DictionaryMutationTypes} from "./mutation-types";
import {Province} from "@/models/Dictionary/Dictionary";
import {MixUtils} from "@/utils/Utils";

export type Mutations<S = State> = {
    [DictionaryMutationTypes.SET_PROVINCES](state: S, payload: Province[]|undefined): void;
    [DictionaryMutationTypes.SET_DISTRICTS](state: S, payload: Province[]|undefined): void;
    [DictionaryMutationTypes.SET_WARDS](state: S, payload: Province[]|undefined): void;
    [DictionaryMutationTypes.SEARCH_PROVINCES](state: S, payload: string): void;
    [DictionaryMutationTypes.SEARCH_DISTRICTS](state: S, payload:string): void;
    [DictionaryMutationTypes.SEARCH_WARDS](state: S, payload: string): void;
}

export const mutations: Mutations = {
    [DictionaryMutationTypes.SET_PROVINCES](state: State, payload: Province[]|undefined): void {
        state.provinces = payload
        state.searchProvinces = payload?payload:[]
        state.districts = []
        state.wards = []
        state.searchDistricts = []
        state.searchWards = []
    },
    [DictionaryMutationTypes.SET_DISTRICTS](state: State, payload: Province[]|undefined): void {
        state.districts = payload
        state.districts = payload?payload:[]
        state.wards = []
        state.searchWards = []
    },
    [DictionaryMutationTypes.SET_WARDS](state: State, payload: Province[]|undefined): void {
        state.wards = payload
    },
    [DictionaryMutationTypes.SEARCH_PROVINCES](state: State, payload: string): void {
        if (state.provinces){
            state.searchProvinces = state.provinces.filter(x=>MixUtils.methods.removeVietnamese2(x.name).includes(MixUtils.methods.removeVietnamese2(payload)))
        }
    },
    [DictionaryMutationTypes.SEARCH_DISTRICTS](state: State, payload: string): void {
        if (state.districts){
            state.searchDistricts = state.districts.filter(x=>MixUtils.methods.removeVietnamese2(x.name).includes(MixUtils.methods.removeVietnamese2(payload)))
        }
    },
    [DictionaryMutationTypes.SEARCH_WARDS](state: State, payload: string): void {
        if (state.wards){
            state.searchWards = state.wards.filter(x=>MixUtils.methods.removeVietnamese2(x.name).includes(MixUtils.methods.removeVietnamese2(payload)))
        }
    }



}