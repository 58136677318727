
import {State, MemberRankData} from "@/store/modules/memberRank/states";
import {MemberRankMutationTypes} from "@/store/modules/memberRank/mutation-types";



export type Mutations<S = State> = {
    [MemberRankMutationTypes.SET_MEMBER_RANK](state: S, payload: MemberRankData | undefined): void;
}

export const mutations: Mutations = {
    [MemberRankMutationTypes.SET_MEMBER_RANK](state: State, payload: MemberRankData | undefined): void {
        state.membersRank = payload
    },
}
