<template>

  <div class="container-title">
    <h3 class="title-header">{{$t($route.name)}}</h3>
    <a-select
        v-model:value="selectedPage"
        @change="handlePageChange"
        :allowClear="true"
        :dropdownMatchSelectWidth="true"
        style="min-width: 350px; margin-right: 16px"
        :placeholder="$t('ALL')"
    >
      <a-select-option
          v-for="item in getPages.currentShop.pages"
          :key="item.id"
          v-bind:value="item.page_id"
      >{{ item.page_name }}
      </a-select-option>
    </a-select>
    <a-range-picker
        :allowClear="false"
        picker="month"
        v-model:value="rangeTime"
        format="MM/YYYY"
        :placeholder="[$t('START_TIME_PLACEHOLDER'), $t('END_TIME_PLACEHOLDER')]"
        @change="onChangeDateTime"
        @ok="confirmDateTime"
    >
      <template #suffixIcon>
        <CalendarOutlined/>
      </template>
    </a-range-picker>

  </div>
  <div style="padding: 16px">
    <a-card
        v-if="!isLoading"
        class="card_view" style="border-radius: 10px; margin: 4px;">
      <a-row>
        <div style="width: 80%">
          <keep-alive>
            <highcharts
                class="chart" :options="{
      chart: {
        type: 'column'
      },
      title: {
        text: $t('REPORT_MESSAGE')
      },
      xAxis: {
        categories: messageData.labels
      },
      yAxis: {
        min: 0,
        title: {
          text: null
        },
        stackLabels: {
          enabled: true,
          style: {
            fontWeight: 'bold',
            textOutline: 'none'
          }
        }
      },
      legend: {
        align: 'right',
        x: -30,
        verticalAlign: 'top',
        y: 25,
        floating: true,
        borderColor: '#CCC',
        borderWidth: 1,
        shadow: false
      },
      tooltip: {
        headerFormat: '<b>{point.x}</b><br/>',
        pointFormat: '{series.name}: {point.y}<br/>'+$t('TOTAL')+': {point.stackTotal}'
      },
      plotOptions: {
        column: {
          stacking: 'normal',
          dataLabels: {
            enabled: false
          }
        }
      },
      series: [{
        name: $t('COMMENT'),
        data: messageData.comments,
         color: '#5767c4'
      },
      {
        name: $t('MESSAGE'),
        data: messageData.messages,
        color: '#45cbe0'
      }]
    }"></highcharts>
          </keep-alive>
        </div>
        <div style="width: 20%">
          <keep-alive>
            <highcharts class="pie" :options=" {
      chart: {
        plotBackgroundColor: null,
        plotBorderWidth: null,
        plotShadow: false,
        type: 'pie'
      },
      title: {
        text: null
      },
      tooltip: {
        pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>'
      },
      accessibility: {
        point: {
          valueSuffix: '%'
        }
      },
      plotOptions: {
        pie: {
          allowPointSelect: true,
          cursor: 'pointer',
          dataLabels: {
            enabled: true,
            format: '<b>{point.name}</b>: {point.percentage:.1f} %'
          }
        }
      },
      series: [{
        name: 'Brands',
        colorByPoint: true,
        data: [{
          name: $t('COMMENT'),
          y: messageData.commentPercent,
          color: '#5767c4'
        },{
          name: $t('MESSAGE'),
          y: 100 - messageData.commentPercent,
           color: '#45cbe0'
        },]
      }]
    }"></highcharts>
          </keep-alive>
        </div>
      </a-row>
    </a-card>

    <a-card
        v-if="!isLoading"
        class="card_view" style="border-radius: 10px; margin: 4px;">
      <keep-alive>
        <highcharts class="chart" :options="{
      title: {
        text: null
      },

      yAxis: {
        title: {
          text: null
        }
      },

      xAxis: {
        categories: messageData.labels
      },
      tooltip: {
        pointFormat: '{series.name}: <b>{point.y}</b>'
      },
       plotOptions: {
        line: {
            dataLabels: {
                enabled: true
            },
            enableMouseTracking: false
        }
    },
      legend: {
        align: 'left',
        verticalAlign: 'top',
        layout: 'vertical',
        symbolPadding: 0,
        symbolHeight: 0.1,
        symbolRadius: 0,
        useHTML: true,
        symbolWidth: 0,
        labelFormatter:   function() {
          let total = 0
          for (let i = 0; i < this.yData?.length; i++) {
            total += this.yData[i]
          }
          return '<div class=\'count-elements\'  style=\'border-left: 10px solid' + (this.visible ? this.color : '#d8d8d8') + '; background-color: ' + (this.visible ? this.color : '#d8d8d8') + '3b;\'><div class=\'title\'><div style=\'overflow: hidden; vertical-align: middle; text-overflow: ellipsis; max-width: 100%; font-weight: 400; white-space: nowrap; text-align: center; display: inline-block;\'>' + this.name.toUpperCase() + '</div></div><div class=\'count\'>' + formatMoney(this.yData?.reduce((previousValue, currentValue) => previousValue + currentValue,
  0)) + '</div></div>';
        },
        },

        series: [{
        color: '#2bd8c4',
        name: $t('NEW_PHONE'),
        data:  messageData.newPhones
        },
        {
        color: '#3097ff',
        name:  $t('NEW_CUSTOMER'),
        data: messageData.customerNew
        },
        {
        color: '#806af7',
        name: $t('NEW_MESSAGE_CONVERSATION'),
        data: messageData.conversationMessageNew
        },
        {
        color: '#ff9103',
        name: $t('CUSTOMER_INTERACT_DAILY'),
        data: messageData.customers
        },
        {
        color: '#ffd600',
        name: $t('CUSTOMER_MESSAGE_DAILY'),
        data: messageData.customerMessages
        },
        ],

        responsive: {
        rules: [{
        condition: {
        maxWidth: 500
        },
        // chartOptions: {
        // legend: {
        // layout: 'horizontal',
        // align: 'center',
        // verticalAlign: 'bottom'
        // }
        // }
        }]
        }

        }"></highcharts>
      </keep-alive>
    </a-card>
    <a-spin         v-if="isLoading"/>


    <!--    <a-row>-->
    <!--      <a-card class="card_view" style="border-radius: 10px; margin: 4px; width: 58%">-->
    <!--        <a-col>-->
    <!--          <keep-alive>-->
    <!--            <highcharts class="chart" :options="pieChart"></highcharts>-->
    <!--          </keep-alive>-->
    <!--        </a-col>-->

    <!--      </a-card>-->
    <!--      <a-card class="card_view" style="border-radius: 10px; margin: 4px;width: 40%">-->
    <!--        <a-col>-->
    <!--        </a-col>-->

    <!--      </a-card>-->

    <!--    </a-row>-->
  </div>
</template>

<script>

import {useStore} from "vuex";
import {ShopinfoActionTypes} from "@/store/modules/Shopinfo/action-types";
import {computed, onMounted, ref} from "vue";
import {useI18n} from "vue-i18n";
import APIs from "@/APIs/APIs";
import {MixUtils} from "@/utils/Utils";
import {CalendarOutlined} from "@ant-design/icons-vue";
import dayjs from "dayjs";
import useShopInfoStore from "@/store/ShopInfo";
import useUserStore from "../../../store/User";

export default {
  components: {
    CalendarOutlined
  },
  setup() {
    const i18n = useI18n()
    const store = useStore();
    const rangeTime = ref([dayjs().add(-3, 'months').startOf('day'), dayjs().endOf('day')])
    const isLoading = ref(false)

    const fetchShopInfo = () => {
      // store.dispatch(ShopinfoActionTypes.FETCH_SHOPINFO);
      useShopInfoStore.getState().getShopinfo()
    };
    // const dataShopInfo = computed(() => store.getters.getShopinfo);
    const {dataShopInfo} = useShopInfoStore()
    const pagesSelection = computed(() => store.getters.getPagesSelection);
    const getPages = useUserStore();

    const selectedPage = ref(useUserStore.getState().currentShop.pages[0].page_id);


    const messageData = ref({
      messages: [],
      comments: [],
      allMessage: [],
      newPhones: [],
      labels: [],
      customers: [],
      conversations: [],
      customerMessages: [],
      conversationMessageNew: [],
      customerNew: [],
      messagePercent: 0,
      commentPercent: 0,
      totalComment: 0,
      totalMessage: 0,
    })


    const fetchMessage = async () => {
      isLoading.value = true
      const messages = []
      const comments = []
      const newPhones = []
      const allMessage = []
      const conversations = []
      const conversationMessageNew = []
      const customerMessages = []
      const customerNew = []
      const labels = []
      const customers = []

      let totalMessage = 0
      let totalComment = 0
      let total = 0


      const getReportCustomerStat = await APIs.init().getReportCustomerStatMonth({
        shop_id: dataShopInfo.value?.id,
        page_id: selectedPage.value,
        from_date: rangeTime.value[0].format('YYYY-MM-DD'),
        to_date: rangeTime.value[1].format('YYYY-MM-DD'),
      });


      getReportCustomerStat.data?.aggregations?.message?.message?.message?.buckets.map(b=> {
        labels.push(dayjs.unix(b.key/1000).format('MM/YYYY'))
        messages.push(b.doc_count-b.comment.doc_count)
        comments.push(b.comment.doc_count)
        allMessage.push(b.doc_count)
        customers.push(b.customers_sum?.value)
        conversationMessageNew.push(0)
        customerMessages.push(b.customer_message?.customers_sum?.value)
        totalMessage += b.doc_count-b.comment.doc_count
        totalComment += b.comment.doc_count
        total+= b.doc_count
      })

      getReportCustomerStat.data?.aggregations?.message?.conversation_message_new?.customers.buckets.map(b=> {
        for (let i = 0; i < labels.length; i++) {
          if(labels[i]===dayjs.unix(b.key/1000).format('MM/YYYY')){
            conversationMessageNew[i] = b.doc_count
          }
        }
      })
      getReportCustomerStat.data?.aggregations?.message?.conversation?.customers.buckets.map(b=> {
        customerNew.push(b.customers.value)
        newPhones.push(b.phone.value)
      })


      messageData.value = {
        messages: messages,
        comments: comments,
        newPhones: newPhones,
        allMessage: allMessage,
        labels: labels,
        customerMessages: customerMessages,
        customerNew: customerNew,
        customers: customers,
        conversationMessageNew: conversationMessageNew,
        messagePercent: Math.round(totalMessage / total * 10000) / 100,
        commentPercent: Math.round(totalComment / total * 10000) / 100,
      }
      isLoading.value = false
    }

    // const fetchUser = async () => {
    //   const response = await APIs.init().getReportPage({
    //     shop_id: dataShopInfo.value?.id,
    //     interval: "day",
    //     previous: true,
    //     phone_agg: false,
    //     user: true,
    //     user_agg: true,
    //     conversation_agg: false,
    //     from_date: moment().startOf('month').subtract(2, 'months').format("YYYY-MM-DD"),
    //     to_date: moment().format("YYYY-MM-DD")
    //   });
    //
    //   console.log(response)
    // }


    const splineChart = ref()


    const handleReport = () => {
      // fetchReportComment();
      // fetchReportMessage();
      // fetchReportNewConversation();
    };

    onMounted(() => {
      // if(getPages.value.length>0){
      //   selectedPage.value = getPages.value[0].page_id
      // }
      fetchShopInfo();
      fetchMessage();
      // fetchUser()
      // fetchReportComment();
      // fetchReportMessage();
      // fetchReportNewConversation();
    });

    const formatMoney = (val) => {
      return MixUtils.methods.formatMoney(val)
    }

    const calculatorPercent = (a, total) => {
      return Math.round(a / total * 10000) / 100
    }

    // const testChart = ref({
    //
    //   title: {
    //     text: null
    //   },
    //
    //   yAxis: {
    //     title: {
    //       text: null
    //     }
    //   },
    //
    //   xAxis: {
    //     accessibility: {
    //       rangeDescription: null
    //     }
    //   },
    //   plotOptions: {
    //     series: {
    //       label: {
    //         connectorAllowed: false
    //       },
    //       pointStart: 2010
    //     }
    //   },
    //   tooltip: {
    //     pointFormat: '{series.name}: <b>{point.y}</b>'
    //   },
    //   legend: {
    //     align: 'left',
    //     verticalAlign: 'top',
    //     layout: 'vertical',
    //     symbolPadding: 0,
    //     symbolHeight: 0.1,
    //     symbolRadius: 0,
    //     useHTML: true,
    //     symbolWidth: 0,
    //     labelFormatter: function () {
    //       let total = 0
    //       for (let i = 0; i < this.yData?.length; i++) {
    //         total += this.yData[i]
    //       }
    //
    //       return `<div class='count-elements'  style='border-left: 10px solid ` + (this.visible ? this.color : "#d8d8d8") + `; background-color: ` + (this.visible ? this.color : "#d8d8d8") + `3b;'><div class='title'><div style='overflow: hidden; vertical-align: middle; text-overflow: ellipsis; max-width: 100%; font-weight: 400; white-space: nowrap; text-align: center; display: inline-block;'>` + this.name.toUpperCase() + `</div></div><div class='count'>` + formatMoney(total) + `</div></div>`;
    //     },
    //   },
    //
    //   series: [{
    //     color: '#2bd8c4',
    //     name: i18n.t('NEW_PHONE'),
    //     data: [43934, 52503, 57177]
    //   }, {
    //     color: '#3097ff',
    //     name: i18n.t('NEW_MESSAGE'),
    //     data: [messagesByMonth.value.month_3, messagesByMonth.value.month_2, messagesByMonth.value.month_1]
    //   },
    //     {
    //       color: '#806af7',
    //       name: i18n.t('RE_INTERACTIVE'),
    //       data: [11744, 17722, 16005]
    //     },
    //     {
    //       color: '#ff9103',
    //       name: i18n.t('NEW_CUSTOMER'),
    //       data: [userByMonth.value.month_3, userByMonth.value.month_2, userByMonth.value.month_1]
    //     }],
    //
    //   responsive: {
    //     rules: [{
    //       condition: {
    //         maxWidth: 500
    //       },
    //       chartOptions: {
    //         legend: {
    //           layout: 'horizontal',
    //           align: 'center',
    //           verticalAlign: 'bottom'
    //         }
    //       }
    //     }]
    //   }
    //
    //
    // })

    const handlePageChange = (val) => {
      selectedPage.value = val;
      console.log(val)
      fetchMessage()
    };


    const onChangeDateTime = (value, dateString) => {
      console.log('Selected Time: ', value);
      console.log('Formatted Selected Time: ', dateString);
      // if (value[0].format('HH-mm') === value[1].format('HH-mm')) {
      //   rangeTime.value = [value[0].startOf('day'), value[1].endOf('day')]
      //
      // } else {
        rangeTime.value = value
      // }
      fetchMessage()
    };

    const confirmDateTime = (value) => {
      console.log('onOk: ', value);
    };


    return {
      dataShopInfo,
      isLoading,
      pagesSelection,
      handlePageChange,
      handleReport,
      selectedPage,
      splineChart,
      messageData,
      formatMoney,
      calculatorPercent,
      getPages,
      onChangeDateTime,
      confirmDateTime,
      rangeTime,
    };
  },
};
</script>

<style>
.card_view {
  border-radius: 10px;
  margin: 4px;
  overflow: hidden;
}

.count-elements {
  -webkit-tap-highlight-color: transparent;
  -webkit-text-size-adjust: 100%;
  font-family: Roboto, Helvetica, Arial, sans-serif;
  font-variant: tabular-nums;
  line-height: 1.5;
  font-feature-settings: 'tnum';
  font-size: 14px;
  color: #000;
  box-sizing: border-box;
  height: 76px;
  padding: 10px;
  border-top: 1px solid #e0e0e0;
  border-bottom: 1px solid #e0e0e0;
  border-radius: 10px 0px 0px 10px;
  cursor: pointer;
  box-shadow: 0px 2px 10px rgba(51, 51, 51, 0.1);
  border-left: 10px solid rgb(43, 216, 196);
  background-color: rgba(196, 255, 248, 0.23);
  width: 100%;
}

.title {
  font-family: "Roboto" !important;
  font-style: normal !important;
  font-weight: 700 !important;
  font-size: 12px !important;
  line-height: 22px !important;
  color: #000000 !important;
  margin-left: 0px !important;
  margin-top: 0px !important;
  text-align: left !important;
  width: 100% !important;
}

.count {
  text-align: right;
  font-weight: 100;
  font-family: Montserrat-Light;
  font-size: 25px;
  line-height: 30px;
  color: #263C8F;
  float: right;
}

.highcharts-credits {
  display: none;
}
</style>
