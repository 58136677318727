import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "container-title" }
const _hoisted_2 = { class: "title-header" }
const _hoisted_3 = {
  style: {"margin":"20px"},
  class: "container"
}
const _hoisted_4 = ["onClick"]
const _hoisted_5 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_input = _resolveComponent("a-input")!
  const _component_rollback_outlined = _resolveComponent("rollback-outlined")!
  const _component_a_button = _resolveComponent("a-button")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_cloud_upload_outlined = _resolveComponent("cloud-upload-outlined")!
  const _component_a_upload = _resolveComponent("a-upload")!
  const _component_a_tag = _resolveComponent("a-tag")!
  const _component_a_popover = _resolveComponent("a-popover")!
  const _component_eye_outlined = _resolveComponent("eye-outlined")!
  const _component_a_menu_item = _resolveComponent("a-menu-item")!
  const _component_edit_outlined = _resolveComponent("edit-outlined")!
  const _component_delete_outlined = _resolveComponent("delete-outlined")!
  const _component_a_popconfirm = _resolveComponent("a-popconfirm")!
  const _component_a_menu = _resolveComponent("a-menu")!
  const _component_a_dropdown = _resolveComponent("a-dropdown")!
  const _component_a_table = _resolveComponent("a-table")!
  const _component_a_row = _resolveComponent("a-row")!
  const _component_a_modal = _resolveComponent("a-modal")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("h3", _hoisted_2, _toDisplayString($setup.getEvent(Number(_ctx.$route.params.id))), 1),
      _createVNode(_component_a_input, {
        style: {"width":"200px","margin-right":"20px"},
        onChange: $setup.handleChange,
        value: $setup.search,
        "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => (($setup.search) = $event)),
        placeholder: "Basic usage"
      }, null, 8, ["onChange", "value"]),
      _createVNode(_component_a_button, {
        style: {"margin-right":"20px"},
        onClick: $setup.rollBack
      }, {
        icon: _withCtx(() => [
          _createVNode(_component_rollback_outlined)
        ]),
        _: 1
      }, 8, ["onClick"]),
      _createVNode(_component_router_link, {
        style: {"margin-right":"16px"},
        to: `/ticket/add-ticket/:id?event_id=${_ctx.$route.params.id}`
      }, {
        default: _withCtx(() => [
          _createVNode(_component_a_button, { type: "primary" }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t('ADD')), 1)
            ]),
            _: 1
          })
        ]),
        _: 1
      }, 8, ["to"]),
      _createVNode(_component_a_upload, {
        name: "file",
        multiple: false,
        "show-upload-list": false,
        beforeUpload: $setup.importXLS
      }, {
        default: _withCtx(() => [
          _createVNode(_component_a_button, null, {
            icon: _withCtx(() => [
              _createVNode(_component_cloud_upload_outlined)
            ]),
            default: _withCtx(() => [
              _createTextVNode(" Upload Excel ")
            ]),
            _: 1
          })
        ]),
        _: 1
      }, 8, ["beforeUpload"])
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createVNode(_component_a_table, {
        rowKey: record => record.id,
        columns: $setup.columns,
        "data-source": $setup.getTicket.tickets,
        pagination: $setup.pagination,
        onChange: $setup.handleTableChange
      }, {
        status: _withCtx(({record}) => [
          (record.status !== 2)
            ? (_openBlock(), _createBlock(_component_a_popover, {
                key: 0,
                trigger: "click"
              }, {
                content: _withCtx(() => [
                  (record.status === 1)
                    ? (_openBlock(), _createBlock(_component_a_button, {
                        key: 0,
                        onClick: ($event: any) => ($setup.checkin(record))
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString('Check in'))
                        ]),
                        _: 2
                      }, 1032, ["onClick"]))
                    : (_openBlock(), _createBlock(_component_a_button, {
                        key: 1,
                        onClick: ($event: any) => ($setup.checkin(record,'payment'))
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.$t('PAYMENT')), 1)
                        ]),
                        _: 2
                      }, 1032, ["onClick"]))
                ]),
                default: _withCtx(() => [
                  _createVNode(_component_a_tag, {
                    style: {"cursor":"pointer"},
                    color: record.status === 0 ? 'orange' : record.status === 1 ? 'blue' : 'green'
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.$t($setup.getStatus(record.status))), 1)
                    ]),
                    _: 2
                  }, 1032, ["color"])
                ]),
                _: 2
              }, 1024))
            : (_openBlock(), _createBlock(_component_a_tag, {
                key: 1,
                color: record.status === 0 ? 'orange' : record.status === 1 ? 'blue' : 'green'
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t($setup.getStatus(record.status))), 1)
                ]),
                _: 2
              }, 1032, ["color"]))
        ]),
        action: _withCtx(({record}) => [
          _createVNode(_component_a_dropdown, null, {
            overlay: _withCtx(() => [
              _createVNode(_component_a_menu, null, {
                default: _withCtx(() => [
                  _createVNode(_component_a_menu_item, null, {
                    default: _withCtx(() => [
                      _createElementVNode("div", {
                        onClick: ($event: any) => ($setup.showModal(record.ticket_id))
                      }, [
                        _createVNode(_component_eye_outlined),
                        _createTextVNode(" " + _toDisplayString(_ctx.$t('PREVIEW_QR')), 1)
                      ], 8, _hoisted_4)
                    ]),
                    _: 2
                  }, 1024),
                  _createVNode(_component_router_link, {
                    to: `/ticket/add-ticket/${record.id}?event_id=${_ctx.$route.params.id}`
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_a_menu_item, null, {
                        default: _withCtx(() => [
                          _createVNode(_component_edit_outlined),
                          _createTextVNode(" " + _toDisplayString(_ctx.$t('EDIT')), 1)
                        ]),
                        _: 1
                      })
                    ]),
                    _: 2
                  }, 1032, ["to"]),
                  _createVNode(_component_a_menu_item, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_a_popconfirm, {
                        title: _ctx.$t('TICKET_CONFIRM_SEND_CONTEXT'),
                        "ok-text": _ctx.$t('YES'),
                        "cancel-text": _ctx.$t('NO'),
                        onConfirm: ($event: any) => ($setup.sendTicket(record.ticket_id))
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_delete_outlined),
                          _createTextVNode(" " + _toDisplayString(_ctx.$t('SEND_TICKET')), 1)
                        ]),
                        _: 2
                      }, 1032, ["title", "ok-text", "cancel-text", "onConfirm"])
                    ]),
                    _: 2
                  }, 1024),
                  _createVNode(_component_a_menu_item, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_a_popconfirm, {
                        title: _ctx.$t('TRIGGER_CONFIRM_DELETE_CONTEXT'),
                        "ok-text": _ctx.$t('YES'),
                        "cancel-text": _ctx.$t('NO'),
                        onConfirm: ($event: any) => ($setup.deleteTicket(record.id))
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_delete_outlined),
                          _createTextVNode(" " + _toDisplayString(_ctx.$t('DELETE')), 1)
                        ]),
                        _: 2
                      }, 1032, ["title", "ok-text", "cancel-text", "onConfirm"])
                    ]),
                    _: 2
                  }, 1024)
                ]),
                _: 2
              }, 1024)
            ]),
            default: _withCtx(() => [
              _createVNode(_component_a_button, { type: "text" }, {
                default: _withCtx(() => [
                  _createVNode(_component_eye_outlined)
                ]),
                _: 1
              })
            ]),
            _: 2
          }, 1024)
        ]),
        _: 1
      }, 8, ["rowKey", "columns", "data-source", "pagination", "onChange"])
    ]),
    _createVNode(_component_a_modal, {
      visible: $setup.visible,
      "onUpdate:visible": _cache[1] || (_cache[1] = ($event: any) => (($setup.visible) = $event)),
      title: _ctx.$t('PREVIEW_QR'),
      onOk: $setup.handleOk
    }, {
      default: _withCtx(() => [
        _createVNode(_component_a_row, { justify: "center" }, {
          default: _withCtx(() => [
            _createElementVNode("img", {
              src: `https://quickchart.io/qr?text=${$setup.ticketId}&size=300`
            }, null, 8, _hoisted_5)
          ]),
          _: 1
        })
      ]),
      _: 1
    }, 8, ["visible", "title", "onOk"])
  ], 64))
}