<template>
  <div class="container-title">
    <h3 class="title-header">{{$t($route.name)}}</h3>
<!--    <div class="input-search">-->
<!--      <a-input-search-->

<!--          v-model:value="search"-->
<!--          :placeholder="$t('SEARCH')"-->
<!--          style="width: 100%"-->
<!--      />-->
<!--    </div>-->
    <div style="margin-right: 10px">
      <a-button
          v-if="getMyRole()?.is_create"
          @click="visible2 = true">
        {{ $t("change_position") }}
      </a-button>


    </div>
    <div class="add-tag">
      <a-button
          v-if="getMyRole()?.is_create"
          type="primary" @click="newCustomerCares">
        <template #icon>
          <plus-outlined/>
        </template>
        {{ $t("ADD_CARE_STATUS") }}
      </a-button>


    </div>
  </div>

        <a-table
            size="middle"
            :scroll="{  y: '80vh' }"
            class="table"
            :pagination="false"
            :rowKey="record => record.id" :columns="columns" :data-source="sortCares(getCustomerCares)">
          <template #name="{ record }">
            <a-tag :color="record.color">{{ record.name }}</a-tag>
          </template>

          <template #created_at="{text}">
            <span>{{ dateTimeDisplayFormatFromString(text) }}</span>
          </template>
          <template #action="{ record }">
            <a-dropdown>
              <a-button type="text">
                <eye-outlined />
              </a-button>
              <template #overlay>
                <a-menu>
                  <a-menu-item
                      v-if="getMyRole()?.is_edit"
                      @click="editClick(record)">
                    <edit-outlined/>
                    {{ $t("CUSTOMER_DETAIL_EDIT") }}
                  </a-menu-item>
                  <a-menu-item
                      v-if="getMyRole()?.is_edit"
                      @click="addQuickMessage(record)">
                    <edit-outlined/>
                    {{ $t("SET_TRIGGER") }}
                  </a-menu-item>
                  <a-menu-item
                      v-if="getMyRole()?.is_edit"
                      @click="removeQuickMessage(record)">
                    <edit-outlined/>
                    {{ $t("REMOVE_TRIGGER") }}
                  </a-menu-item>
                  <a-menu-item
                      v-if="getMyRole()?.is_delete"
                  >
                    <a-popconfirm
                        title="Are you sure delete this status care?"
                        ok-text="Yes"
                        cancel-text="No"
                        @confirm="deleteCustomerCare(record)"
                    >
                      <delete-outlined/>
                      {{ $t("CUSTOMER_DELETE") }}
                    </a-popconfirm>

                  </a-menu-item>
                </a-menu>
              </template>
            </a-dropdown>
          </template>
        </a-table>

    <a-modal
        :title="customerCaresForm.id?$t('UPDATE'):$t('ADD_NEW')"
        v-model:visible="visible"
        :confirm-loading="confirmLoading"
        width="40%"
        @ok="save"
    >
      <a-form :model="customerCaresForm" ref="formRef" :rules="rules" :label-col="{span: 6,offset:0}"
              :wrapper-col="{ span: 16 }">
        <a-form-item :label="$t('TAG_TAG_NAME')" name="name">
          <a-input v-model:value="customerCaresForm.name"/>
        </a-form-item>
        <a-form-item :label="$t('TAG_COLOR')" name="color">
          <div style="display: flex;">
            <a-button
                @click="togglePicker"
                :style="{'background-color':customerCaresForm.color?customerCaresForm.color:'white',color:customerCaresForm.color?'white':'black','margin-right':'15px'}">
              Click
            </a-button>
            <ColorPicker theme="light" :color="customerCaresForm.color" :sucker-hide="false" v-if="colorPickerVisible"
                         :sucker-canvas="suckerCanvas" :sucker-area="suckerArea" @changeColor="changeColor"
                         @openSucker="openSucker"
            />
          </div>
        </a-form-item>
        <a-form-item name="description" :label="$t('TAG_DESCRIPTION')">
          <a-textarea v-model:value="customerCaresForm.description" placeholder="Description" :rows="4"/>
        </a-form-item>
      </a-form>

    </a-modal>
  <a-modal
      v-model:visible="visible2"
      :title="$t('change_display_position')"
      @ok="visible2 = false"
  >
    <div class="form-modal form-selection" style="text-align: center">

      <a-row>
        <a-col :span="4" style="padding-right: 20px">
          <a-button
              v-for="(count,index) in getCustomerCares"
              :key="count"
              style="margin-top: 10px; border-radius: 50px"
              size="large"
              block
              class="button-flow-context right-button-content"
          >
            <span class="center-button-content">
              {{ index+1 }}
            </span>
          </a-button>
        </a-col>
        <a-col :span="20">
          <Container
              orientation="vertical"
              group-name="col-items"
              :drop-placeholder="{
              className: `drop-placeholder`,
              animationDuration: '200',
              showOnTop: true,
            }"
              drag-class="
            transition duration-100 ease-in z-50
            transform rotate-6 scale-110"
              drop-class="transition duration-100
            ease-in z-50 transform
            -rotate-2 scale-90"
              @drop="(e) => onCardDrop(e)"
          >
            <Draggable v-for="item in sortCares(getCustomerCares)" :key="item.id">
              <a-button
                  style="margin-top: 10px; border-radius: 50px"
                  :style="{'background-color':item.color}"
                  size="large"
                  block
                  class="button-flow-context right-button-content"
              >
                <span :style="{'color': item.color ? 'white':'black'}"  class="center-button-content">
                  {{ $t(item.name) }}
                </span>
              </a-button>
            </Draggable>
          </Container>
        </a-col>
      </a-row>
    </div>
  </a-modal>
    <trigger-quick-message-modal :onlySelectTrigger="true" :triggerSelectCallback="triggerSelectCallback"
                                 ref="triggerCustomerRef"/>
</template>
<script lang="ts">
import './vue-color-kit.css'
import {reactive, ref} from 'vue';
import {DownOutlined, PlusOutlined,EyeOutlined} from "@ant-design/icons-vue";
import {mapActions, mapGetters} from "vuex";
import {Options, Vue} from "vue-class-component";
import APIs from "@/APIs/APIs";
import {ValidateErrorEntity} from "ant-design-vue/es/form/interface";
import {message} from "ant-design-vue";
import {CustomerCaresActionTypes} from "@/store/modules/customerCare/action-types";
import {CustomerCare, CustomerCaresForm} from "@/models/CustomerCares/CustomerCares";
import {ColorPicker} from 'vue-color-kit'
import TriggerQuickMessageModal from "@/components/customer/TriggerQuickMessageModal.vue";
import {Trigger} from "@/models/Trigger/Trigger";
import RouterEndpoint from "@/router/RouterEndpoint";
import {Constant} from "@/utils/Constants";
// @ts-ignore
import { Container, Draggable } from "vue3-smooth-dnd";
import useCustomerStore from "../../store/Customers";
const rules = {
  name: [
    {required: true, message: 'Please input name', trigger: 'blur'},
  ]
};
// Modal
const visible = ref<boolean>(false);
const confirmLoading = ref<boolean>(false);


@Options({
  name: 'customerCares',
  components: {
    PlusOutlined,
    DownOutlined,
    ColorPicker,
    TriggerQuickMessageModal,
    EyeOutlined,Container,Draggable
  },
  computed: {
    columns() {
      return [
        {
          title: "Id",
          dataIndex: 'id',
          key: 'id',
        },
        {
          title: this.$t('STATUS'),
          dataIndex: 'name',
          slots: {customRender: 'name'},
        },
        {
          title: this.$t('DATE_CREATED'),
          dataIndex: 'created_at',
          key: 'created_at',
          slots: {customRender: 'created_at'},
        },
        {
          title: this.$t('TAG_DESCRIPTION'),
          dataIndex: 'description',
          key: 'description',
          // slots: {customRender: 'created_at'},
        },
        {
          title: this.$t('TRIGGER'),
          dataIndex: 'trigger_name',
          key: 'trigger_name',
          // slots: {customRender: 'created_at'},
        },
        {
          title: this.$t('TAG_ACTION'),
          key: 'action',
          slots: {customRender: 'action'},
        },
      ]
    },
    getCustomerCares(){
      return this.customerCare.customerCares
    },
    ...mapGetters({
      // getCustomerCares: 'getCustomerCares',
      userInfo: "getUserInfo",
      getRole: "getRole",
    })

  },
  data() {
    const value = ref<string>('');
    const customerCaresForm: CustomerCaresForm = reactive({

      name: '',
      description: '',
      trigger_id: 0
    })
    const onSearch = (searchValue: string) => {
      console.log('use value', searchValue);
      console.log('or use this.value', value.value);
    };

    return {
      value,
      onSearch,
      visible,
      visible2: false,
      confirmLoading,
      rules,
      customerCaresForm,
      colorPickerVisible: false,
      color: "#59c7f9",
      suckerCanvas: null,
      suckerArea: [],
      isSucking: false,
      colNumber:9,
      customerCare:useCustomerStore()
    }
  },
  created() {

    this.fetchCustomerCares();
    this.shortCustomer()
  },
  methods: {
    // ...mapActions({
    //   fetchCustomerCares: CustomerCaresActionTypes.FETCH_CUSTOMERS_CARES
    // }),
    fetchCustomerCares(){
      useCustomerStore.getState().GetCustomerCare()
    },
    editClick(customerCare: CustomerCare) {
      const formObject: CustomerCaresForm = {}
      let customerCaresForm = {...customerCare, ...formObject}
      this.customerCaresForm = customerCaresForm;
      visible.value = true;
    },
    newCustomerCares() {
      this.customerCaresForm.id = undefined
      this.customerCaresForm.name = '';
      this.customerCaresForm.description = '';
      visible.value = true;
    },
    sortCares(data:any){
      if(data !== null){
        return data.sort((a:any,b:any)=>a.sort-b.sort)
      }else {
        return []
      }

    },
    onCardDrop(e:any) {
      if (
          e.removedIndex != null &&
          e.addedIndex != null &&
          e.removedIndex !== e.addedIndex
      ) {
        const rmCol = useCustomerStore.getState().customerCares[e.removedIndex];
        useCustomerStore.getState().customerCares.splice(e.removedIndex, 1);
        useCustomerStore.getState().customerCares.splice(e.addedIndex, 0, rmCol);
        console.log("onCardDrop", e, useCustomerStore.getState().customerCares);
         this.shortCustomer('sort')
        this.fetchCustomerCares()
      }
      console.log("onCardDrop", e);
    },
    save() {
      this.$refs.formRef.validate().then(() => {

        this.confirmLoading = true;
        if (this.customerCaresForm.id) {
          APIs.init().updateCustomerCares(this.customerCaresForm).then(res => {
            this.confirmLoading = false
            if (res.status === 200 && res.data?.status === 200) {
              this.visible = false
              message.success({content: 'Edit status cares success'});
              this.fetchCustomerCares();
            } else {
              message.destroy()
            }
          }).catch(e => {
            this.confirmLoading = false
            message.destroy()
            console.error(e)
          })
        } else {
          APIs.init().createCustomerCares(this.customerCaresForm).then(res => {
            this.confirmLoading = false
            if (res.status === 200 && res.data?.status === 200) {
              this.visible = false
              message.success({content: 'Add status cares success'});
              this.fetchCustomerCares();
            } else {
              message.destroy()
            }
          }).catch(e => {
            this.confirmLoading = false
            message.destroy()
            console.error(e)
          })
        }
      }).catch((e: ValidateErrorEntity) => {
        console.error(e)
      })
    },
    addQuickMessage(customerCare: CustomerCare) {
      const formObject: CustomerCaresForm = {}
      let customerCaresForm = {...customerCare, ...formObject}
      this.customerCaresForm = customerCaresForm;
      this.$refs.triggerCustomerRef.openModal()
    },
    removeQuickMessage(customerCare: CustomerCare){
      const formObject: CustomerCaresForm = {}
      this.customerCaresForm = {...customerCare, ...formObject};
      this.customerCaresForm.trigger_id=0
      APIs.init().updateCustomerCares(this.customerCaresForm).then(res => {
        if (res.status === 200 && res.data?.status === 200) {
          message.success({content: `Remove trigger( ${customerCare.trigger_name} ) success`});
          this.fetchCustomerCares();
        } else {
          message.destroy()
        }
      }).catch(e => {
        message.destroy()
        console.error(e)
      })
    },
     shortCustomer(str:any){
      if(useCustomerStore.getState().customerCares){
        if(str){
          useCustomerStore.getState().customerCares.map((item:any,index:any) => {
            console.log(item.sort,index+1)
            if(item.sort !== index+1){
              APIs.init().sortCustomerCares({...item,sort:index+1}).then((res:any) => {
                    if(res.data.status === 200){
                      console.log(res)
                    }
                  }

              )
            }

          })
          this.fetchCustomerCares()
        }else {
          useCustomerStore.getState().customerCares.map((item:any,index:any) => {
            console.log(item.sort,index+1)
            if(item.sort === 0){
              APIs.init().sortCustomerCares({...item,sort:index+1}).then((res:any) => {
                    if(res.data.status === 200){
                      console.log(res)
                    }
                  }

              )
            }

          })
        }
        this.fetchCustomerCares()
      }


    },
    deleteCustomerCare(customerCare: CustomerCare) {
      message.loading({content: 'Deleting Status Cares: ' + customerCare.name, key: customerCare.id});
      if (!customerCare.id) {
        customerCare.id = 0
      }
      APIs.init().deleteCustomerCares(customerCare.id).then(res => {
        if (res.status === 200 && res.data?.status === 200) {
          message.success({
            content: 'Deleted Status Cares: ' + customerCare.name + '!',
            key: customerCare.id,
            duration: 2
          });
          this.fetchCustomerCares();
        } else {
          message.destroy()
        }
      }).catch(e => {
        message.destroy()
        console.error(e)
      })
    },
    togglePicker() {
      this.colorPickerVisible = !this.colorPickerVisible;
    },
    changeColor(color: any) {
      const {r, g, b, a} = color.rgba
      this.customerCaresForm.color = color.hex;
      // this.colorPickerVisible = false
    },
    triggerSelectCallback(trigger: Trigger) {
      this.customerCaresForm.trigger_id = trigger.id
      APIs.init().updateCustomerCares(this.customerCaresForm).then(res => {
        this.confirmLoading = false
        if (res.status === 200 && res.data?.status === 200) {
          this.visible = false
          message.success({content: `Add trigger( ${trigger.name} ) success`});
          this.fetchCustomerCares();
        } else {
          message.destroy()
        }
      }).catch(e => {
        this.confirmLoading = false
        message.destroy()
        console.error(e)
      })
    },
    openSucker(isOpen: boolean) {
      if (isOpen) {
        // ... canvas be created
        // this.suckerCanvas = canvas
        // this.suckerArea = [x1, y1, x2, y2]
      } else {
        // this.suckerCanvas && this.suckerCanvas.remove
      }
    },
    getMyRole() {
      if(this.userInfo.id_cms_privileges<11){
        return {
          is_visible: 1,
          is_create:1,
          is_read: 1,
          is_edit: 1,
          is_delete: 1,
          id: 1
        }
      }else{
        return this.getRole(Constant.MODULES.customer_care);
      }

    },
  }

})
export default class CustomerCares extends Vue {
}

</script>
<style scoped>
.add-tag {
  text-align: right;
}

.form-modal {
  padding: 0px 15px;
}
.table {
  margin: 16px
}
.title-header {
  font-weight: bold;
  flex: 1;
}
.container-title{
  display: flex;
  flex-direction: row;
  padding: 16px 24px 16px 24px;
  margin-bottom: 25px;
  background-color: white
}
.input-search{
  width: 250px;
  margin-right: 16px;
}
</style>
