import {ProductCategory, ProductDetail, ProductProvider, ProductGroup} from "@/models/Products/Products";
import {Customer} from "@/models/Customers/Customers";
import {Metadata, MetadataProduct} from "@/APIs/response/S_HttpResponse";

export type ProductGroupData = {
    productGroups: ProductGroup[] | undefined;
    metadata: MetadataProduct | undefined
    loading: boolean
}

export type State = {
    productGroupData: ProductGroupData | undefined;
    productDetail: ProductDetail | undefined;
    productCategories: ProductCategory[] | undefined;
    productProviders: ProductProvider[] | undefined;
}

export const state: State = {
    productGroupData: {productGroups: [], metadata: {}, loading: false },
    productDetail: {versions: []},
    productCategories: [],
    productProviders: [],
}
