<template>

  <div class="container-title">
    <h3 class="title-header">{{$t($route.name)}}</h3>

  </div>
  <a-card class="card_view" style="border-radius: 10px; margin: 16px;">
    <a-typography-text strong>{{report.organization.name}}</a-typography-text>

<!--      <a-row>-->
<!--      <a-typography-text strong>{{$t('RECENCY')}} : </a-typography-text>-->
<!--        <a-typography-text >{{report.organization.rfm_config.r_quantile.join(',')}}</a-typography-text>-->
<!--      </a-row>-->
<!--      <a-row>-->
<!--      <a-typography-text strong>{{$t('FREQUENCY')}} : </a-typography-text>-->
<!--        <a-typography-text >{{report.organization.computed_rfm.f_quantile.join(',')}}</a-typography-text>-->
<!--      </a-row>-->
<!--      <a-row>-->
<!--        <a-typography-text strong>{{$t('MONETARY')}} : </a-typography-text>-->
<!--        <a-typography-text >{{report.organization.computed_rfm.m_quantile.join(',')}}</a-typography-text>-->
<!--      </a-row>-->


  </a-card>
  <a-collapse v-model:activeKey="activeKey">
    <a-collapse-panel key="1" :header="$t('Report_Customer_Range')">
      <a-row style="margin-bottom: 10px">
        <a-select
            v-model:value="selectedType"
            @change="handleFilterChange"
            :allowClear="true"
            :dropdownMatchSelectWidth="true"
            style="width: 250px; margin-right: 16px;margin-bottom: 10px"
            :placeholder="$t('EMPLOYEE')"
        >
          <a-select-option


              v-bind:value="'frequency'"
          >{{ $t('FREQUENCY') }}
          </a-select-option>
          <a-select-option


              v-bind:value="'monetary'"
          >{{ $t('MONETARY') }}
          </a-select-option>
        </a-select>
        <a-input-group compact>
          <a-input-number
              v-model:value="min"
              style="width: 100px; text-align: center"
              placeholder="Minimum"
              @change="handleFilterChange"
          />
          <a-input
              v-model:value="value13"
              class="site-input-split"
              style="width: 30px; border-left: 0; pointer-events: none"
              placeholder="~"
              disabled
          />
          <a-input-number
              v-model:value="max"
              class="site-input-right"
              style="width: 100px; text-align: center"
              placeholder="Maximum"
              @change="handleFilterChange"
          />
        </a-input-group>
      </a-row>
      <a-table
          :columns="columns"
          :data-source="report.range"
          :pagination="false"
          bordered
      >
        <template  #segment="{record}">
          {{$t(record.segment)}}
        </template>
      </a-table>
    </a-collapse-panel>
    <a-collapse-panel key="2" :header="$t('Report_Customer_Segments')">
      <a-select
          v-model:value="phone"
          mode="tags"
          style="width: 300px;margin-bottom: 10px"
          :token-separators="[',']"
          :placeholder="$t('PLACEHOLDER_INPUT_PHONE')"
          :options="options"
          @change="handleFilterChange2"
      ></a-select>
      <a-table
          :columns="columns2"
          :data-source="report.segments.customer_tag"
          :pagination="false"
          bordered
      >
        <template  #segment="{record}">
          {{$t(record.segment)}}
        </template>


      </a-table>
    </a-collapse-panel>
    <a-collapse-panel key="3" :header="$t('Report_Customer_Segments_Count')" >
      <a-row>
        <div style="width: 60%">
          <keep-alive>
            <highcharts
                class="chart" :options="{
      chart: {
        type: 'treemap',
      },
      title: {
        text: ''
      },
      series: chartDataReportCareAll.total ? [
          {
            data: chartDataReportCareAll.total
          }
      ]:[]
    }"></highcharts>
          </keep-alive>
        </div>
        <div style="width: 40%">
          <keep-alive>
            <highcharts class="pie" :options=" {
      chart: {
        plotBackgroundColor: null,
        plotBorderWidth: null,
        plotShadow: false,
        type: 'pie'
      },
      title: {
        text: null
      },
      tooltip: {
        pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>'
      },
      accessibility: {
        point: {
          valueSuffix: '%'
        }
      },
      plotOptions: {
        pie: {
          allowPointSelect: true,
          cursor: 'pointer',
          dataLabels: {
            enabled: true,
            format: '<b>{point.name}</b>: {point.percentage:.1f} %'
          }
        }
      },
      series: [{
        name: 'Brands',
        colorByPoint: true,
        data: [{
          name: $t('champions'),
          y: chartDataReportCareAll.percent[0],
          color: '#6c7ee1'
        },
        {
          name: $t('potential_loyalists'),
          y: 100 - chartDataReportCareAll.percent[1],
           color: '#ffc4a4'
        },
        {
          name: $t('new_customer'),
          y: chartDataReportCareAll.percent[2],
          color: '#fba2d0'
        },
        {
          name: $t('promising'),
          y: chartDataReportCareAll.percent[3],
          color: '#c688eb'
        },
        {
          name: $t('loyal_customer'),
          y: chartDataReportCareAll.percent[4],
          color: '#5d7b6f'
        },{
          name: $t('need_attention'),
          y: chartDataReportCareAll.percent[5],
          color: '#a4c3a2'
        },{
          name: $t('about_to_sleep'),
          y: chartDataReportCareAll.percent[6],
          color: '#b0d4b8'
        },{
          name: $t('cant_loose'),
          y: chartDataReportCareAll.percent[7],
          color: '#eae7d6'
        },
        {
          name: $t('at_risk'),
          y: chartDataReportCareAll.percent[8],
          color: '#d7f9fa'
        },{
          name: $t('hibernating'),
          y: chartDataReportCareAll.percent[9],
          color: '#fea837'
        }
        ]
      }]
    }"></highcharts>
          </keep-alive>
        </div>
      </a-row>
    </a-collapse-panel>
  </a-collapse>
</template>

<script>

import {useStore} from "vuex";
import {ShopinfoActionTypes} from "@/store/modules/Shopinfo/action-types";
import {computed, onMounted, ref} from "vue";
import moment, {Moment} from "moment";
import {useI18n} from "vue-i18n";
import APIs from "@/APIs/APIs";
import {MixUtils} from "@/utils/Utils";
import {CalendarOutlined,SearchOutlined} from "@ant-design/icons-vue";
import dayjs from "dayjs";
import {ReportPAGEActionTypes} from "../../../store/modules/ReportPage/action-types";
import useEmployeeStore from "../../../store/Employee";
import useReportPageStore from "../../../store/ReportPage";




export default {
  components: {
    // CalendarOutlined,
    // SearchOutlined
  },
  setup() {
    const employeeStore = useEmployeeStore()
    const report = useReportPageStore()
    const i18n = useI18n()
    const store = useStore();
    const activeKey = ref([]);
    const rangeTime = ref([dayjs().add(-1, 'months').startOf('day'), dayjs().endOf('day')])
    const selectedType = ref('monetary');
    const phone = ref([])
    const fetchCusomer = () => {
      useEmployeeStore.getState().GetEmployee({
        page: 1,
        page_size: 999,
      })
      fetchCustomerRange()
      fetchCustomerSegments()
      useReportPageStore.getState().getReportOrganizations({
      })
      useReportPageStore.getState().getReportOrganizationsSegmntsCount({
      })
      // store.dispatch(ReportPAGEActionTypes.REVENUE,{
      //   from_date: rangeTime.value[0].format('YYYY-MM-DDT00:00:00Z'),
      //   to_date: rangeTime.value[1].format('YYYY-MM-DDT00:00:00Z'),
      //   type:selectedType.value
      // });
    };
    const min = ref(0)
    const max = ref(1000.5)
    const fetchCustomerSegments = () => {
      useReportPageStore.getState().getReportOrganizationsSegments({
        phone_number : phone.value,
      })
    }
    const fetchCustomerRange = () => {
      useReportPageStore.getState().getReportOrganizationsRange({
        indicator : selectedType.value,
        min:min.value,
        max:max.value,
      })
    }
    const revenue =computed(()=> store.getters.getReportRevenue)
    const total =computed(()=> store.getters.getTotalRevenue)

    const selectedPage = ref();

    const chartDataReportCareAll = ref({
      total: [],
      label: [],
      color: [],
      percent:[]
    })

    const fetchChart = async () => {
      // chartDataReportCareAll.value = {
      //   total: [],
      //   label: [],
      //   color: [],
      // }
      // labelsCareEmployee.value = []
      const getReportCount = await APIs.init().reportCustomerSegmentsCount({
      });
      const colors = [
        '#6c7ee1',
        '#ffc4a4',
        '#fba2d0',
        '#c688eb',
        '#5d7b6f',
        '#a4c3a2',
        '#b0d4b8',
        '#eae7d6',
        '#d7f9fa',
        '#fea837'
      ]
      if(getReportCount.data.status === 200){
        console.log(getReportCount.data)
        chartDataReportCareAll.value = {
          total: getReportCount.data.data.map((t,index) => {return {
            name:i18n.t(t.segment_name),
            value:t.count,
            color: colors[index]
          }}),
          label: getReportCount.data.data.map(t => i18n.t(t.segment_name)),
          color: getReportCount.data.data.map(t => t.color),
          percent:[]
        }
        let total = 0
        getReportCount.data.data.map((item) => {
          total +=item.count
        })
        console.log(total,'chartDataReportCareAll.value')
        getReportCount.data.data.map((item) => {
          chartDataReportCareAll.value.percent.push(total/100 *item.count)
        })

        console.log(chartDataReportCareAll.value,'chartDataReportCareAll.value')
      }


    }
    const formatMoney = (val) => {
      return MixUtils.methods.formatMoney(val)
    }
    const columns = [
      {
        title: i18n.t('phone_number'),
        dataIndex: 'phone_number',
        key: 'phone_number',
        slots: {customRender: "phone_number"},
        width: 150,
      },
      {
        title: i18n.t('camera_name'),
        dataIndex: 'client',
        key: 'client',
        slots: {customRender: "client"},
        width: 100,
      },
      {
        title: i18n.t('RECENCY'),
        dataIndex: 'recency',
        key: 'recency',
        slots: {customRender: "recency"},
        width: 100,
      },
      {
        title: i18n.t('FREQUENCY'),
        dataIndex: 'frequency',
        key: 'frequency',
        slots: {customRender: "frequency"},
        width: 100,
      },
      {
        title: i18n.t('MONETARY'),
        dataIndex: 'monetary',
        key: 'monetary',
        slots: {customRender: "monetary"},
        width: 100,
      },
      {
        title: i18n.t('segment'),
        dataIndex: 'segment',
        key: 'segment',
        slots: {customRender: "segment"},
        width: 100,
      },
    ];
    const columns2 = [
      {
        title: i18n.t('phone_number'),
        dataIndex: 'phone_number',
        key: 'phone_number',
        slots: {customRender: "phone_number"},
        width: 150,
      },
      {
        title: i18n.t('camera_name'),
        dataIndex: 'client',
        key: 'client',
        slots: {customRender: "client"},
        width: 100,
      },
      {
        title: i18n.t('segment'),
        dataIndex: 'segment',
        key: 'segment',
        slots: {customRender: "segment"},
        width: 100,
      },
    ];






    const splineChart = ref()


    const handleReport = () => {
      // fetchReportComment();
      // fetchReportMessage();
      // fetchReportNewConversation();
    };

    onMounted(() => {

      fetchCusomer()
      fetchChart()

    });



    const calculatorPercent = (a, total) => {
      return Math.round(a / total * 10000) / 100
    }


    const handlePageChange = (val) => {
      selectedPage.value = val;
      console.log(val)
      fetchCusomer()
      fetchChart()
    };

    const getEmployee = (data) => {

      if(data){
        console.log(useEmployeeStore.getState().employees.find( item => item.cms_user_id === data),'sadas')
        let user = useEmployeeStore.getState().employees.find( item => item.cms_user_id === Number(data))
        if(user){
          return user.name
        }else {
          return data
        }

      }else {
        return ''
      }

    }
    const onChangeDateTime = (value, dateString) => {
      console.log('Selected Time: ', value);
      console.log('Formatted Selected Time: ', dateString);
      if (value[0].format('HH-mm') === value[1].format('HH-mm')) {
        rangeTime.value = [value[0].startOf('day'), value[1].endOf('day')]

      } else {
        rangeTime.value = value
      }
      fetchCusomer()
      fetchChart()
    };
    const  handleFilterChange = () => {

     fetchCustomerRange()
    }
    const handleFilterChange2 = () => {
      fetchCustomerSegments()
    }
    const confirmDateTime = (value) => {
      console.log('onOk: ', value);
    };


    return {
      handlePageChange,
      handleReport,
      selectedPage,
      splineChart,
      formatMoney,
      calculatorPercent,
      onChangeDateTime,
      confirmDateTime,
      rangeTime,
      columns,
      columns2,
      revenue,
      report,
      chartDataReportCareAll,
      employeeStore,
      total,
      selectedType,
      handleFilterChange,getEmployee,activeKey,min,
      max,
      phone,
      handleFilterChange2

    };
  },
};
</script>

<style>
.card_view {
  border-radius: 10px;
  margin: 4px;
  overflow: hidden;
}

.count-elements {
  -webkit-tap-highlight-color: transparent;
  -webkit-text-size-adjust: 100%;
  font-family: Roboto, Helvetica, Arial, sans-serif;
  font-variant: tabular-nums;
  line-height: 1.5;
  font-feature-settings: 'tnum';
  font-size: 14px;
  color: #000;
  box-sizing: border-box;
  height: 76px;
  padding: 10px;
  border-top: 1px solid #e0e0e0;
  border-bottom: 1px solid #e0e0e0;
  border-radius: 10px 0px 0px 10px;
  cursor: pointer;
  box-shadow: 0px 2px 10px rgba(51, 51, 51, 0.1);
  border-left: 10px solid rgb(43, 216, 196);
  background-color: rgba(196, 255, 248, 0.23);
  width: 100%;
}

.title {
  font-family: "Roboto" !important;
  font-style: normal !important;
  font-weight: 700 !important;
  font-size: 12px !important;
  line-height: 22px !important;
  color: #000000 !important;
  margin-left: 0px !important;
  margin-top: 0px !important;
  text-align: left !important;
  width: 100% !important;
}

.count {
  text-align: right;
  font-weight: 100;
  font-family: Montserrat-Light;
  font-size: 25px;
  line-height: 30px;
  color: #263C8F;
  float: right;
}

.highcharts-credits {
  display: none;
}
</style>
