<template>
  <div class="container-title">
    <h3 class="title-header">{{$t($route.name)}}</h3>

     <a-button @click="rollBack" >
       <template #icon><rollback-outlined /> </template>
     </a-button>

<!--    <router-link style="margin-left: 20px" to="/ticket/template">-->
<!--      <a-button  type="dashed">Template</a-button>-->
<!--    </router-link>-->
      <a-button style="margin-left: 20px" @click="addTicketType" type="primary">{{$t('ADD')}}</a-button>



  </div>
  <div style="margin: 20px" class="container">
    <a-table

        :rowKey="record => record.id"
        :columns="columns" :data-source="getTicketType.ticketTypes"
        :pagination="pagination"
        @change="handleTableChange"
    >

      <template #event_id="{record}">
        {{getEventName(record.event_id)}}
      </template>

     <template #price="{record}">
       <div v-for="i in getPrice(record.id)" :key="i">
         <a-row>
           {{formatMoney(i.price)}} : {{getDate(i.start_time)}}-{{getDate(i.end_time)}}
         </a-row>
       </div>



     </template>
      <template #template_id="{record}">
        {{getTemplateName(record.template_id)}}
      </template>
      <template #action="{record}">
        <a-dropdown>
          <a-button type="text">
            <eye-outlined/>
          </a-button>
          <template #overlay>
            <a-menu>
              <a-menu-item
                  @click="editTicketType(record)">
                <edit-outlined/>
                {{ $t('EDIT') }}
              </a-menu-item>
              <a-menu-item
              >
                <a-popconfirm
                    :title="$t('TICKET_TYPE_CONFIRM_DELETE_CONTEXT')"
                    :ok-text="$t('YES')"
                    :cancel-text="$t('NO')"
                    @confirm="deleteTicketType(record)"
                >
                  <delete-outlined/>
                  {{ $t('DELETE') }}
                </a-popconfirm>

              </a-menu-item>
            </a-menu>
          </template>
        </a-dropdown>
      </template>
    </a-table>
  </div>
  <a-modal style="width: 60%" v-model:visible="visible" :title="checkEdit ? $t('EDIT_EVENT'): $t('CREATED_EVENT')" @ok="handleOk">




        <a-row>

          <a-col :span=10>



        <a-form
            :model="ticketType"
            ref="formCustomerRef"
            :rules="rules"
            layout="vertical"

        >
        <a-form-item :label="$t('TICKET_TYPE_NAME')" name="name">
          <a-input
              v-model:value="ticketType.name"
              :placeholder="$t('TICKET_TYPE_NAME')"


          />
        </a-form-item>

        <a-form-item :label="$t('DESCRIPTION')" name="phone">

          <a-textarea v-model:value="ticketType.description"
                      :placeholder="$t('DESCRIPTION')" show-count :maxlength="1200" />
        </a-form-item>
          <a-form-item :label="$t('TICKET_TYPE_START_BIB')" name="start_bib">

            <a-input-number
                v-model:value="ticketType.start_bib"
                :placeholder="$t('TICKET_TYPE_START_BIB')"
                style="width: 100%"

            />
          </a-form-item>
          <a-form-item :label="$t('TEMPLATE')" name="template_id">
            <a-select

                allowClear
                v-model:value="ticketType.template_id"
                :placeholder="$t('TEMPLATE')"
                @change="onChangeEmployee"
            >
              <a-select-option
                  v-for="item in templates.templates"
                  :key="item.id"
                  v-bind:value="item.id"
              >{{ item.name }}
              </a-select-option>
            </a-select>
          </a-form-item>
        </a-form>
          </a-col>
          <a-col :span="1">

          </a-col>
          <a-col :span="10">
            <a-form
                :model="ticketPrice"
                layout="vertical"

            >
            <div v-for="(i,index) in  ticketPrice" :key="i">
              <a-form-item :label="$t('sale_price') +' '+  (index+1)" >
                <a-input-number
                    v-model:value="i.price"
                    style="width: 100%"
                    :formatter="value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')"
                    :parser="value => value.replace(/\$\s?|(,*)/g, '')"
                    string-mode

                />
              </a-form-item>
              <a-form-item :label="$t('time_visit')" name="birth">
                <a-range-picker style="width: 100%"  v-model:value="i.time_visit" format="DD-MM-YYYY HH:mm"  />
              </a-form-item>


            </div>
              <a-tooltip placement="topLeft">
                <template #title>
                  <span>{{$t('ADD')}} {{$t('sale_price')}}</span>
                </template>
                <a-button @click="ticketPricePush"  shape="circle">
                  <template #icon><plus-outlined /></template>
                </a-button>
              </a-tooltip>
            </a-form>
          </a-col>
        </a-row>

  </a-modal>
</template>
<script lang="ts">
import {useI18n} from "vue-i18n";
import {useStore} from "vuex";
import {computed, onMounted, ref} from "vue";
import APIs from "@/APIs/APIs";
import {useRoute, useRouter} from "vue-router";
import {TicketActionTypes} from "@/store/modules/Ticket/action-types";
import {EventResponse} from "@/APIs/response/S_HttpResponse";
import {MixUtils} from "@/utils/Utils";
import dayjs from "dayjs";
import {
  DeleteOutlined,
  DownOutlined,
  EditOutlined,
  PlusOutlined,
  SmileOutlined,
  TagOutlined,
  PlusCircleFilled,
  CheckOutlined,
  SendOutlined,
  AimOutlined, ExclamationCircleOutlined,
  StopOutlined,
  HistoryOutlined,
  EyeOutlined,
    RollbackOutlined
} from "@ant-design/icons-vue"
import {message} from "ant-design-vue";
import {PaginationProps} from "ant-design-vue/es/pagination";
export default {
  components:{
    DeleteOutlined,
    EditOutlined,
    EyeOutlined,
    PlusOutlined,
    RollbackOutlined
  },
  setup(){
    const i18n = useI18n()
    const store = useStore()
    const route = useRoute()
    const visible = ref(false)
    const checkEdit = ref(false)
    const ticketType= ref({
      name:'',
      price:'',
      time_visit:[]
    })
    const ticketPrice = ref([])
    const timeAdvance = ref()
    const timeLater = ref()
    const priceLater = ref(0)
    const priceAdvance = ref(0)
    const getUserInfo =computed(()=> store.getters.getUserInfo)
    const getTicketType =computed(()=> store.getters.getTicketType)
    const getTicketPrice =computed(()=> store.getters.getTicketPrice)
    const templates = computed(()=> store.getters.getTicketTemplate)
    const router = useRouter()
    const formCustomerRef = ref()
    const events = computed<EventResponse>(() => store.getters.getEvent)
    const columns = [
      {
        title: i18n.t('EVENT_NAME'),
        dataIndex: 'event_id',
        key: 'event_id',
        slots: {customRender: 'event_id'},
      },
      {
        title: i18n.t('TICKET_TYPE_NAME'),
        dataIndex: 'name',
        key: 'name',
        slots: {customRender: 'name'},
      },
      {
        title: i18n.t('DESCRIPTION'),
        dataIndex: 'description',
        key: 'description',
        slots: {customRender: 'description'},
      },
      {
        title: i18n.t('start_bib'),
        dataIndex: 'start_bib',
        key: 'start_bib',
        slots: {customRender: 'start_bib'},
      },
      {
        title: i18n.t('Template'),
        dataIndex: 'template_id',
        key: 'template_id',
        slots: {customRender: 'template_id'},
      },
      {
        title: i18n.t('PRICE'),
        key: 'price',
        slots: {customRender: 'price'},
      },
      // {
      //   title: i18n.t('created_at'),
      //   dataIndex: 'created_at',
      //   key: 'created_at',
      //   slots: {customRender: 'created_at'},
      // },
      // {
      //   title: i18n.t('updated_at'),
      //   dataIndex: 'updated_at',
      //   key: 'updated_at',
      //   slots: {customRender: 'updated_at'},
      // },
      {
        title: '',
        key: 'action',
        slots: {customRender: 'action'},
        width: 100
      },
    ]
    const rules = {
      name: [
        {required: true, message: i18n.t("Please input event name"), trigger: 'blur'},
      ],
      // price_in_advance:[
      //   {required: true, message: i18n.t("Please input price in advance"), trigger: 'blur'},
      // ],
      }
      const addTicketType = () => {
        // @ts-ignore
      ticketPrice.value =[{price:'', time_visit:[]}]
        // @ts-ignore
        ticketType.value = {}
        visible.value = true
      }
      const getDate = (data:any) => {
           return dayjs(data).format("DD/MM/YYYY HH:MM")
      }
      const getTemplateName = (data:any) => {

        return  templates.value.templates.find((item:any) => item.id === data) ? templates.value.templates.find((item:any) => item.id === data).name : data


      }
     const ticketTypeRequest = ref({})
    const handleTableChange = (pagination: any) => {
      // @ts-ignore
          ticketTypeRequest.value.page = pagination?.current
      // @ts-ignore
          ticketTypeRequest.value.page_size = pagination?.pageSize
      fetchData()

    }
    const pagination =computed(() => ({
        current: getTicketType.value.metadata.page ? getTicketType.value.metadata.page : 1,
        pageSize: getTicketType.value.metadata.page_size ? getTicketType.value.metadata.page_size : 20,
        defaultPageSize: 20,
        total: getTicketType.value.metadata && getTicketType.value.metadata.record_total ? getTicketType.value.metadata.record_total : 0,
        pageSizeOptions: ['20', '40', '80', '120'],
        showSizeChanger: true

    }))
    const fetchTemplates =  () => {
      store.dispatch(TicketActionTypes.FETCH_TICKET_TEMPLATE,{shop_id:getUserInfo.value.current_shop_id})
    }
     const addTicketPrice = async  (id:any) => {
      ticketPrice.value.map(async(item:any) => {
        if(item.id){
          item.start_time =dayjs(item.time_visit[0])
          item.end_time = dayjs(item.time_visit[1])
          item.price = Number(item.price)
          const res = await APIs.init().updateTicketPrice(item)
          if(res.data?.status === 200) {

            message.success(i18n.t('SUCCESS'))
            // @ts-ignore
            fetchData()
          }
        }else {
          item.shop_id = getUserInfo.value.current_shop_id
          item.event_id = Number(route.params.id)
          item.ticket_type_id = id
          item.price = Number(item.price)
          item.start_time =dayjs(item.time_visit[0])
          item.end_time = dayjs(item.time_visit[1])
          const res = await APIs.init().addTicketPrice(item)
          if(res.data?.status === 200){
            message.success(i18n.t('SUCCESS'))
            fetchData()
          }
        }
        visible.value = false
        checkEdit.value = false
      })


    }
    const ticketPricePush = () => {
      // @ts-ignore
      ticketPrice.value.push({price:'', time_visit:[]})
    }
    const deleteTicketType = async  (data:any) => {
        const res = await APIs.init().deleteTicketType(data)

      if(res.data?.status === 200) {
        const prices = getTicketPrice.value.ticketPrices ? getTicketPrice.value.ticketPrices.filter(  (item:any) => item.ticket_type_id === data.id) : []
        if(prices.length > 0 ){
          prices.map(async (item:any) => {
            const response = await APIs.init().deleteTicketPrice(item)


          })
        }
        fetchData()
        message.success('delete success')
      }
    }
    const formatMoney = (money:any) => {
      return MixUtils.methods.formatMoney(money)
    }
    const getEventName = (id:any) => {
      // @ts-ignorec
const name =  events.value.events.find((item:any) => id === item.id)
   return name ? name.name : id
    }
    const getPrice = (id:any) => {
        const pices = getTicketPrice.value.ticketPrices ? getTicketPrice.value.ticketPrices.filter((item:any) => item.ticket_type_id === id).reverse()  : []

       return pices.length > 0 ? pices : []
    }
    const editTicketType= async (data:any) => {
      checkEdit.value = true
      ticketType.value = data
      ticketPrice.value = getTicketPrice.value.ticketPrices ? getTicketPrice.value.ticketPrices.filter((item:any) => item.ticket_type_id === data.id).reverse() :[]

        ticketPrice.value = ticketPrice.value ? ticketPrice.value.map((item) => {
          // @ts-ignore
          item.time_visit = [dayjs(item.start_time),dayjs(item.end_time)]
          // @ts-ignore
          item.price = item.price + ""
          return item
        }) : []
        console.log(ticketPrice.value)


      visible.value = true
    }
    const handleOk =  () => {
      formCustomerRef.value.validate()
          .then(async () => {
            if(checkEdit.value){
              // @ts-ignore
              const res = await APIs.init().updateTicketType(ticketType.value)
              if(res.data?.status === 200){
                console.log('2')
                // @ts-ignore
                addTicketPrice(ticketType.value.id)
              }
            }else {
              // @ts-ignore
              ticketType.value.shop_id = getUserInfo.value.current_shop_id
              // @ts-ignore
              ticketType.value.event_id = Number(route.params.id)
              // @ts-ignore

              const res = await APIs.init().addTicketType(ticketType.value)
              if(res.data?.status === 200) {
                // @ts-ignore
                addTicketPrice(res.data.data.id)
              }
            }
          })

    }
    const rollBack = () => {
      router.back()
    }
    const fetchData = () => {
      // @ts-ignore
      store.dispatch(TicketActionTypes.FETCH_TICKET_TYPE,{page:ticketTypeRequest.value.page,page_size:ticketTypeRequest.value.page_size,event_id: route.params.id})
      store.dispatch(TicketActionTypes.FETCH_TICKET_PRICE,{event_id: route.params.id})
    }
    onMounted(() =>{
      fetchData()
      fetchTemplates()
    })
    return{
      columns,
      visible,
      checkEdit,
      ticketType,
      timeAdvance,
      ticketPrice,
      handleOk,
      rollBack,
      priceLater,priceAdvance,
      timeLater,
      getTicketType,
      getEventName,
      getPrice,
      editTicketType,
      deleteTicketType,
      rules,
      addTicketType,
      ticketPricePush,
      getDate,
      formatMoney,
      templates,
      getTemplateName,
      pagination,
      handleTableChange,
      formCustomerRef

    }
  }
}
</script>
