<template>
  <div class="container-title">
    <h3 class="title-header">{{ $t($route.name) }}</h3>
    <a-row>
      <a-statistic title="Thành viên đăng ký" :value="data[0]?.list?.length" style="margin-right: 50px"/>
      <!--        <a-statistic title="Đã checkin" :value="112893" :value-style="{ color: '#3f8600' }" />-->
    </a-row>
  </div>
  <div style="display: flex;flex-direction: column" class="container-content">
    <a-card class="card_view" style="border-radius: 10px; margin: 16px;">
      <template #title>
        <a-button
            @click="exportExcel">
          <template #icon>
            <file-excel-outlined/>
          </template>
          Xuất excel
        </a-button>
      </template>
      <keep-alive>
        <highcharts
            class="chart" :options="{
     chart: {
        type: 'bar'
    },
    title: {
        text: 'Báo cáo checkin',
        align: 'left'
    },
    subtitle: {
        text: 'CHƯƠNG TRÌNH HUẤN LUYỆN SỨ MỆNH LÃNH ĐẠO LẦN XVII',
        align: 'left'
    },
    xAxis: {
        categories: data.map((time, index)=>'Lần '+(index+1)),
        title: {
            text: null
        },
        gridLineWidth: 1,
        lineWidth: 0
    },
    yAxis: {
        min: 0,
        title: {
            text: 'Thành viên',
            align: 'high'
        },
        labels: {
            overflow: 'justify'
        },
        gridLineWidth: 0
    },
    tooltip: {
        valueSuffix: ' thành viên'
    },
    plotOptions: {
        bar: {
            borderTopRightRadius: 10,
            borderBottomRightRadius: 10,
            dataLabels: {
                enabled: true
            },
            groupPadding: 0.1
        }
    },
    legend: {
        layout: 'vertical',
        align: 'right',
        verticalAlign: 'top',
        floating: true,
        borderWidth: 1,
        backgroundColor: '#FFFFFF',
        shadow: true
    },
    credits: {
        enabled: false
    },
    series: [
        {
        name: 'Đúng giờ',
        color: '#00e273',
        data: data.map((i)=>i.list?.filter(j=>j.is_later===0&&(moment(j.checkin_by_time).year()===moment().year())).length)
    }, {
        name: 'Muộn giờ',
        color: '#ff6a35',
        data: data.map((i)=>i.list?.filter(j=>j.is_later===1).length)
    },
    {
        name: 'Không tham gia',
        color: '#2db0fe',
        data: data.map((i)=>i.list?.filter(j=>j.is_later===0 && moment(j.checkin_by_time).year()===1).length)
    }]
    }"></highcharts>

      </keep-alive>
    </a-card>
  </div>
</template>
<script>
import {computed, onMounted, ref} from "vue";
import APIs from "@/APIs/APIs";
import {useRoute} from "vue-router";
import moment from "moment";
import {FileExcelOutlined} from  "@ant-design/icons-vue";
import {MixUtils} from "@/utils/Utils";

export default {
  components: {
    FileExcelOutlined
  },
  setup() {
    const route = useRoute();
    const data = ref([])

    const getData = async () => {
      try {
        const response = await APIs.init().checkinReport({
          id: route.params.id
        })
        console.log('data', response.data.data)
        data.value = response.data.data
      } catch (e) {
        console.log(e)
      }

    }
    const exportExcel = async () => {
      try {
        const response = await APIs.init().checkinReportXLS({
          id: route.params.id
        })

        const href = window.URL.createObjectURL(new Blob([response.data]));

        // create "a" HTML element with href to file & click
        const link = document.createElement('a');
        link.href = href;
        link.setAttribute('download', "Báo_cáo_checkin_"+MixUtils.methods.getUUID()+'.xlsx');
        document.body.appendChild(link);
        link.click();

        // clean up "a" element & remove ObjectURL
        document.body.removeChild(link);
        window.URL.revokeObjectURL(href);


      } catch (e) {
        console.log(e)
      }

    }

    onMounted(() => {
      getData()
    })


    return {
      data,
      exportExcel,
      moment
    }
  }
}
</script>
<style scoped>

</style>
