import {OrdersData, State, } from "./states";
import {OrdersMutationTypes} from "@/store/modules/orders/mutation-types";

export type Mutations<S = State> = {
    [OrdersMutationTypes.SET_ORDERS](state: S, payload: OrdersData | undefined): void;
}
export const mutations: Mutations = {
    [OrdersMutationTypes.SET_ORDERS](state: State, payload: OrdersData | undefined): void {
        state.orderData = payload
    },

}
