<template>
  <div>
    <a-modal
      :confirmLoading="isLoading"
      v-model:visible="visible"
      :title="$t('CREATE_SCHEDULE')"
      @ok="onSubmit"
      @cancel="resetFields"
    >
      <a-form
        name="custom-validation"
        ref="formRef"
        :label-col="{ span: 6 }"
        :wrapper-col="{ span: 16 }"
      >
        <a-form-item :label="$t('remind_time_before')">
          <a-select
            style="width: 100%"
            show-search
            placeholder="Select Timeout"
            :default-active-first-option="false"
            :show-arrow="false"
            :not-found-content="null"
            :filter-option="filterOption"
            v-model:value="formState.remind_time_before"
          >
            <a-select-option
              v-for="time in timeoutSelections"
              :key="time"
              :value="time.value"
              >{{ time.label }}
            </a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item
          has-feedback
          :label="$t('SCHEDULE_TIME')"
          name="schedule_time"
          v-bind="validateInfos.schedule_time"
        >
          <a-date-picker
            v-model:value="formState.schedule_time"
            show-time
            :placeholder="$t('SELECT_TIME_TO_REMIND')"
          />
        </a-form-item>
        <a-form-item
          has-feedback
          :label="$t('MESSAGE_NOTE')"
          name="message_note"
          v-bind="validateInfos.message_note"
        >
          <a-textarea
            v-model:value="formState.message_note"
            :placeholder="$t('MESSAGE_NOTE')"
            :auto-size="{ minRows: 2, maxRows: 5 }"
          />
        </a-form-item>
        <a-form-item :label="$t('SELECT_TYPE')">
          <a-radio-group
            v-model:value="formState.schedule_type"
            :options="plainOptions"
          />
        </a-form-item>
        <a-form-item
          v-if="formState.schedule_type === 'reminder_send_trigger'"
          name="type"
          :label="$t('TRIGGER_TYPE')"
        >
          <a-select
            ref="select"
            v-model:value="formState.trigger_id"
            :placeholder="$t('TRIGGER_TYPE')"
          >
            <a-select-option
              v-for="item in list"
              :key="item.id"
              :value="item.id"
              >{{ $t(item.name) }}
            </a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item
          has-feedback
          :label="$t('STAFF_RECEIVE_NOTIFICATIONS')"
          name="employee_ids"
          v-bind="validateInfos.employee_ids"
          v-if="isAdminOrShopOwner"
        >
          <a-select
            v-model:value="formState.employee_ids"
            mode="tags"
            style="width: 100%"
            @change="handleChange"
            :placeholder="$t('PLEASE_SELECT')"
          >
            <a-select-option
              v-for="item in employeeSelection"
              :key="item.cms_user_id"
              v-bind:value="item.cms_user_id"
              >{{ item.name }}</a-select-option
            >
          </a-select>
        </a-form-item>
        <a-form-item :wrapper-col="{ span: 14, offset: 4 }"> </a-form-item>
      </a-form>
    </a-modal>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, onMounted, reactive, ref } from "vue";
import { FormSubmitSchedule } from "@/models/notification/Notification";
import { Customer } from "@/models/Customers/Customers";
import { useStore } from "@/store";
import { SuperUserInfo } from "@/models/Users";
import { useForm } from "ant-design-vue/es/form";
import { SelectionAnt } from "@/models/ShopPages/ConfigData";

import APIs from "@/APIs/APIs";
import { message } from "ant-design-vue";
import { useI18n } from "vue-i18n";
import { Trigger } from "@/models/Trigger/Trigger";
import dayjs from "dayjs";
import { TriggerActionTypes } from "@/store/modules/trigger/action-types";

export default defineComponent({
  name: "create-schedule-modal",
  setup: function () {
    const visible = ref(false);
    const isLoading = ref(false);
    const store = useStore();
    const i18n = useI18n();
    const timeoutSelections = [
      {
        label: "1 " + i18n.t("minutes"),
        value: 60, //second
      },
      {
        label: "2 " + i18n.t("minutes"),
        value: 120, //second
      },
      {
        label: "3 " + i18n.t("minutes"),
        value: 180, //second
      },
      {
        label: "4 " + i18n.t("minutes"),
        value: 240, //second
      },
      {
        label: "5 " + i18n.t("minutes"),
        value: 300, //second
      },
      {
        label: "6 " + i18n.t("minutes"),
        value: 360, //second
      },
      {
        label: "7 " + i18n.t("minutes"),
        value: 420, //second
      },
      {
        label: "8 " + i18n.t("minutes"),
        value: 480, //second
      },
      {
        label: "9 " + i18n.t("minutes"),
        value: 640, //second
      },

      {
        label: "10 " + i18n.t("minutes"),
        value: 600, //second
      },
      {
        label: "11 " + i18n.t("minutes"),
        value: 660, //second
      },
      {
        label: "12 " + i18n.t("minutes"),
        value: 720, //second
      },
      {
        label: "13 " + i18n.t("minutes"),
        value: 780, //second
      },
      {
        label: "14 " + i18n.t("minutes"),
        value: 840, //second
      },
      {
        label: "15 " + i18n.t("minutes"),
        value: 900, //second
      },
      {
        label: "16 " + i18n.t("minutes"),
        value: 960, //second
      },
      {
        label: "17 " + i18n.t("minutes"),
        value: 1020, //second
      },
      {
        label: "18 " + i18n.t("minutes"),
        value: 1080, //second
      },
      {
        label: "19 " + i18n.t("minutes"),
        value: 1140, //second
      },
      {
        label: "20 " + i18n.t("minutes"),
        value: 1200, //second
      },
      {
        label: "21 " + i18n.t("minutes"),
        value: 1260, //second
      },
      {
        label: "22 " + i18n.t("minutes"),
        value: 1320, //second
      },
      {
        label: "23 " + i18n.t("minutes"),
        value: 1380, //second
      },
      {
        label: "24 " + i18n.t("minutes"),
        value: 1440, //second
      },
      {
        label: "25 " + i18n.t("minutes"),
        value: 1500, //second
      },
      {
        label: "26 " + i18n.t("minutes"),
        value: 1560, //second
      },
      {
        label: "27 " + i18n.t("minutes"),
        value: 1620, //second
      },
      {
        label: "28 " + i18n.t("minutes"),
        value: 1680, //second
      },
      {
        label: "29 " + i18n.t("minutes"),
        value: 1740, //second
      },
      {
        label: "30 " + i18n.t("minutes"),
        value: 1800, //second
      },
      {
        label: "31 " + i18n.t("minutes"),
        value: 1860, //second
      },
      {
        label: "32 " + i18n.t("minutes"),
        value: 1920, //second
      },
      {
        label: "33 " + i18n.t("minutes"),
        value: 1980, //second
      },
      {
        label: "34 " + i18n.t("minutes"),
        value: 2040, //second
      },
      {
        label: "35 " + i18n.t("minutes"),
        value: 2100, //second
      },
      {
        label: "36 " + i18n.t("minutes"),
        value: 2160, //second
      },
      {
        label: "37 " + i18n.t("minutes"),
        value: 2220, //second
      },
      {
        label: "38 " + i18n.t("minutes"),
        value: 2280, //second
      },
      {
        label: "39 " + i18n.t("minutes"),
        value: 2340, //second
      },
      {
        label: "40 " + i18n.t("minutes"),
        value: 2400, //second
      },
      {
        label: "41 " + i18n.t("minutes"),
        value: 2460, //second
      },
      {
        label: "42 " + i18n.t("minutes"),
        value: 2520, //second
      },
      {
        label: "43 " + i18n.t("minutes"),
        value: 2580, //second
      },
      {
        label: "44 " + i18n.t("minutes"),
        value: 2640, //second
      },
      {
        label: "45 " + i18n.t("minutes"),
        value: 2700, //second
      },
      {
        label: "46 " + i18n.t("minutes"),
        value: 2760, //second
      },
      {
        label: "47 " + i18n.t("minutes"),
        value: 2820, //second
      },
      {
        label: "48 " + i18n.t("minutes"),
        value: 2880, //second
      },
      {
        label: "49 " + i18n.t("minutes"),
        value: 2940, //second
      },
      {
        label: "50 " + i18n.t("minutes"),
        value: 3000, //second
      },
      {
        label: "51 " + i18n.t("minutes"),
        value: 3060, //second
      },
      {
        label: "52 " + i18n.t("minutes"),
        value: 3120, //second
      },
      {
        label: "53 " + i18n.t("minutes"),
        value: 3180, //second
      },
      {
        label: "54 " + i18n.t("minutes"),
        value: 3240, //second
      },
      {
        label: "55 " + i18n.t("minutes"),
        value: 3300, //second
      },
      {
        label: "56 " + i18n.t("minutes"),
        value: 3360, //second
      },
      {
        label: "57 " + i18n.t("minutes"),
        value: 3420, //second
      },
      {
        label: "58 " + i18n.t("minutes"),
        value: 3480, //second
      },
      {
        label: "59 " + i18n.t("minutes"),
        value: 3540, //second
      },
      {
        label: "1 " + i18n.t("HOURS"),
        value: "3600", //second
      },
      {
        label: "2 " + i18n.t("HOURS"),
        value: 7200, //second
      },
      {
        label: "3 " + i18n.t("HOURS"),
        value: 10800, //second
      },
      {
        label: "4 " + i18n.t("HOURS"),
        value: 14400, //second
      },
      {
        label: "5 " + i18n.t("HOURS"),
        value: 18000, //second
      },
      {
        label: "6 " + i18n.t("HOURS"),
        value: 21600, //second
      },
      {
        label: "7 " + i18n.t("HOURS"),
        value: 25200, //second
      },
      {
        label: "8 " + i18n.t("HOURS"),
        value: 28800, //second
      },
      {
        label: "9 " + i18n.t("HOURS"),
        value: 32400, //second
      },
      {
        label: "10 " + i18n.t("HOURS"),
        value: 36000, //second
      },
      {
        label: "11 " + i18n.t("HOURS"),
        value: 39600, //second
      },
      {
        label: "12 " + i18n.t("HOURS"),
        value: 43200, //second
      },
      {
        label: "13 " + i18n.t("HOURS"),
        value: 46800, //second
      },
      {
        label: "14 " + i18n.t("HOURS"),
        value: 50400, //second
      },
      {
        label: "15 " + i18n.t("HOURS"),
        value: 54000, //second
      },
      {
        label: "16 " + i18n.t("HOURS"),
        value: 57000, //second
      },
      {
        label: "17 " + i18n.t("HOURS"),
        value: 61200, //second
      },
      {
        label: "18 " + i18n.t("HOURS"),
        value: 64800, //second
      },
      {
        label: "19 " + i18n.t("HOURS"),
        value: 68400, //second
      },
      {
        label: "20 " + i18n.t("HOURS"),
        value: 72000, //second
      },
      {
        label: "21 " + i18n.t("HOURS"),
        value: 75600, //second
      },
      {
        label: "22 " + i18n.t("HOURS"),
        value: 79200, //second
      },
      {
        label: "23 " + i18n.t("HOURS"),
        value: 82800, //second
      },
      {
        label: "24 " + i18n.t("HOURS"),
        value: 86400, //second
      },
      {
        label: "2 " + i18n.t("days"),
        value: 172800, //second
      },
      {
        label: "3 " + i18n.t("days"),
        value: 259200, //second
      },
      {
        label: "4 " + i18n.t("days"),
        value: 345600, //second
      },

      {
        label: "5 " + i18n.t("days"),
        value: 432000, //second
      },
      {
        label: "6 " + i18n.t("days"),
        value: 518400, //second
      },
      {
        label: "1 " + i18n.t("week"),
        value: 604800,
      },
      {
        label: "2 " + i18n.t("week"),
        value: 1209600, //second
      },
      {
        label: "3 " + i18n.t("week"),
        value: 1814400, //second
      },
      {
        label: "30 " + i18n.t("days"),
        value: 2592000, //second
      },
    ];
    const handleOk = () => {
      console.log("ok");
    };

    const currentCustomer = computed(
      (): Customer | undefined => store.getters.getCurrentCustomer
    );
    const userInfo = computed(
      (): SuperUserInfo | null => store.getters.getUserInfo
    );
    const isAdminOrShopOwner = computed(
      (): boolean => store.getters.getIsAdminOrShopOwner
    );
    const employeeSelection = computed(() => store.getters.getListEmployee);
    const plainOptions = [
      { label: i18n.t("reminder"), value: "reminder" },
      {
        label: i18n.t("reminder_send_trigger"),
        value: "reminder_send_trigger",
      },
    ];
    const getTriggers = computed(() => store.getters.getTriggers);
    const list = computed<Trigger[]>(
      () => store.getters.getTriggersQuickMessages
    );
    const openModal = () => {
      visible.value = true;
      store.dispatch(TriggerActionTypes.FETCH_TRIGGER_QUICK_MESSAGES, {
        payload: { page: 1, page_size: 20 },
      });
    };
    const handleChange = (value: number[]) => {
      console.log(`selected `, value);
    };
    const formData: FormSubmitSchedule = {
      customer_ids: [],
      page_id: "",
      creator: 0,
      employee_ids: [],
      shop_id: 0,
      schedule_time: dayjs(),
      schedule_type: "",
      trigger_id: 0,
      remind_time_before: 0,
    };

    const rulesRef = reactive({
      message_note: [
        {
          required: true,
          message: i18n.t("PLEASE_INPUT_NOTE"),
        },
      ],
      schedule_time: [
        {
          required: true,
          message: i18n.t("SCHEDULE_TIME_IS_REQUIRED"),
        },
      ],
      employee_ids: [
        {
          required: false,
          message: "Please select type",
          type: "array",
        },
      ],
    });
    const formState = reactive<FormSubmitSchedule>(formData);
    const { resetFields, validate, validateInfos } = useForm(
      formState,
      rulesRef
    );
    const filterOption = (input: string, option: any) => {
      console.log(input, option);
      return option.key.label.toLowerCase().indexOf(input.toLowerCase()) >= 0;
    };
    const onSubmit = () => {
      // console.log(formState.schedule_time2)
      validate()
        .then(() => {
          // formState.schedule_time = dayjs(formState.schedule_time2).toDate()
          if (formState.employee_ids && formState.employee_ids.length === 0) {
            // @ts-ignore
            formState.employee_ids = [userInfo.value.id];
          }
          if (currentCustomer.value && currentCustomer.value?.id) {
            formData.customer_ids = [currentCustomer.value.id];
            if (currentCustomer.value.page_id) {
              formData.page_id = currentCustomer.value.page_id;
            }
            if (currentCustomer.value.shop_id) {
              formData.shop_id = currentCustomer.value.shop_id;
            }
            if (userInfo.value && userInfo.value.id) {
              formData.creator = userInfo.value.id;
            }
            // if (userInfo.value.roles)
          }
          isLoading.value = true;
          APIs.init()
            .scheduleEmployee(formState)
            .then((res) => {
              isLoading.value = false;
              if (res.ok && res.data) {
                resetFields();
                visible.value = false;
                message.success(i18n.t("schedule_success"));
              } else {
                message.error("Error " + res.data?.message);
              }
            })
            .catch((e) => {
              isLoading.value = false;
              console.error(e);
              message.error("Error " + JSON.stringify(e));
            });
        })
        .catch((err) => {
          console.log("error", err);
        });
    };

    return {
      visible,
      handleOk,
      openModal,
      isAdminOrShopOwner,
      formState,
      rulesRef,
      validateInfos,
      resetFields,
      onSubmit,
      employeeSelection,
      handleChange,
      isLoading,
      plainOptions,
      getTriggers,
      list,
      timeoutSelections,
      filterOption,
    };
  },
});
</script>

<style scoped></style>
