import { State, MemberRankData} from "./states";

export type Getters = {
    getMemberRankData(state: State): MemberRankData
}
export const getters: Getters = {
    getMemberRankData(state: State): MemberRankData {
        if (state.membersRank) {
            return state.membersRank
        } else {
            return {
                memberRank: [],
                metadata: {}
            }
        }
    },
}
