<template>
  <div class="container-title">
    <div class="title-header">
      <h3>{{ $t($route.name) }}</h3>
      <a-button
        v-if="getRole(modules.schedules)?.is_create"
        type="primary"
        @click="showModal"
        >{{ $t("ADD") }}</a-button
      >
    </div>
    <div style="margin-right: 10px; margin-top: 30px">
      <a-upload
        name="file"
        :multiple="false"
        :show-upload-list="false"
        :beforeUpload="importSchedule"
      >
        <a-button>
          <template #icon>
            <cloud-upload-outlined />
          </template>
          Upload file
        </a-button>
      </a-upload>
    </div>

    <a-form-item
      :label="$t('SCHEDULE_TIME')"
      class="filter-form-item-inline"
      style="margin-right: 16px"
    >
      <a-range-picker
        v-model:value="filterForm.dateRange"
        @change="dateRangeChange"
        format="DD-MM-YYYY"
      />
    </a-form-item>
    <a-form-item :label="$t('EMPLOYEE')" class="filter-form-item-inline">
      <a-select
        v-model:value="filterForm.employee"
        mode="multiple"
        style="width: 300px"
        placeholder="Please select"
        @change="employeeHandleChange"
      >
        <a-select-option
          v-for="item in employeeSelection"
          :key="item.cms_user_id"
          v-bind:value="item.cms_user_id"
          >{{ item.name }}</a-select-option
        >
      </a-select>
    </a-form-item>
  </div>

  <!--    <a-table-->
  <!--        class="table"-->
  <!--        :dataSource="schedules"-->
  <!--             :pagination="pagination"-->
  <!--             @change="handleTableChange"-->
  <!--             :columns="columns" :rowKey="record => record.key">-->
  <!--      <template #CUSTOMER>-->
  <!--        {{ $t('CUSTOMER') }}-->
  <!--      </template>-->
  <!--      <template #EMPLOYEE>-->
  <!--        {{ $t('EMPLOYEE') }}-->
  <!--      </template>-->

  <!--      <template #MESSAGE>-->
  <!--        {{ $t('MESSAGE') }}-->
  <!--      </template>-->

  <!--      <template #SCHEDULE_TIME>-->
  <!--        {{ $t('SCHEDULE_TIME') }}-->
  <!--      </template>-->
  <!--      <template #REMIND_TIME_BEFORE>-->
  <!--        {{ $t('remind_time_before') }}-->
  <!--      </template>-->
  <!--      <template #CREATOR>-->
  <!--        {{ $t('CREATOR') }}-->
  <!--      </template>-->
  <!--      <template #STATUS>-->
  <!--        {{ $t('STATUS') }}-->
  <!--      </template>-->
  <!--      <template #CREATED>-->
  <!--        {{ $t('CREATED') }}-->
  <!--      </template>-->
  <!--      <template #ACTION>-->
  <!--        {{ $t('ACTION') }}-->
  <!--      </template>-->

  <!--      <template #customers="{record}">-->
  <!--        <div v-if="record.customers">-->
  <!--          <a class="table-row-name" type="text" v-for="customer in record.customers" :key="customer.customer_id">-->
  <!--            <router-link :to="getRole(modules.customers)?.is_edit?('/customer/'+customer.customer_id):''" >-->
  <!--              {{ customer.customer_name }}-->
  <!--            </router-link>-->
  <!--          </a>-->
  <!--        </div>-->

  <!--      </template>-->
  <!--      <template #employee="{record}">-->
  <!--        <div v-if="record.toEmployee">-->
  <!--          <a-button type="text" v-for="employee in record.toEmployee" :key="employee.id">-->
  <!--            {{ employee.name}}-->
  <!--          </a-button>-->
  <!--        </div>-->

  <!--      </template>-->
  <!--      <template #remind_time_before="{record}">-->
  <!--        {{`${record.remind_time_before/60}  ${$t('minutes')}` }}-->
  <!--      </template>-->
  <!--      <template #schedule_time="{record}">-->
  <!--        <span>{{ dateTimeDisplayFormatFromShortString(record.scheduled_time) }}</span>-->
  <!--      </template>-->
  <!--      <template #status="{record}">-->
  <!--       <span>-->
  <!--          <a-tag v-if="record.is_sent" color="#87d068">{{ $t('SEND') }}</a-tag>-->
  <!--        <a-tag color="cyan" v-else>{{ $t('NOT_SENT') }}</a-tag>-->
  <!--       </span>-->
  <!--      </template>-->
  <!--      <template #created_at="{record}">-->
  <!--        <span>{{ dateTimeDisplayFormatFromShortString(record.created_at) }}</span>-->
  <!--      </template>-->
  <!--      <template #action="{record}" >-->
  <!--        <a-popconfirm-->
  <!--            v-if="getRole(modules.schedules)?.is_delete"-->
  <!--            title="Are you sure delete this schedule?"-->
  <!--            ok-text="Yes"-->
  <!--            cancel-text="No"-->
  <!--            @confirm="deleteSchedule(record)"-->
  <!--        >-->
  <!--          <delete-outlined/>-->
  <!--        </a-popconfirm>-->
  <!--      </template>-->
  <!--    </a-table>-->
  <a-calendar
    :fullscreen="true"
    :valueFormat="'YYYY-MM-DDTHH:mm:ssZ'"
    @select="selectDay"
    @panelChange="panelChange"
    @change="changeSchedule"
    v-model:value="value"
  >
    <template #dateCellRender="{ current }">
      <ul class="events">
        <li v-for="item in getListData(current)" :key="item.message">
          <a-tag
            @click="detailSchedule(item)"
            style="margin-bottom: 5px; width: 90%"
            >{{ item.message }}</a-tag
          >
        </li>
      </ul>
    </template>
    <template #monthCellRender="{ current }">
      <ul class="events">
        <li v-for="item in getMonthData(current)" :key="item.message">
          <a-tag
            @click="detailSchedule(item)"
            style="margin-bottom: 5px; width: 90%"
            >{{ item.message }}</a-tag
          >
        </li>
      </ul>
    </template>
  </a-calendar>
  <a-modal
    width="700px"
    v-model:visible="visible"
    :title="
      checkDetailSchedule ? scheduleDetail.message : $t('CREATE_SCHEDULE')
    "
    @ok="onSubmit"
  >
    <div style="margin-bottom: 5px" v-if="checkDetailSchedule">
      <a-popconfirm
        v-if="getRole(modules.schedules)?.is_delete"
        title="Are you sure delete this schedule?"
        ok-text="Yes"
        cancel-text="No"
        @confirm="deleteSchedule(scheduleDetail)"
      >
        <delete-outlined />
      </a-popconfirm>

      <div style="display: flex">
        <div>
          <div v-if="scheduleDetail.customers">
            {{ $t("CUSTOMER") }} :
            <a
              class="table-row-name"
              type="text"
              v-for="customer in scheduleDetail.customers"
              :key="customer.customer_id"
            >
              <router-link
                :to="
                  getRole(modules.customers)?.is_edit
                    ? '/customerDetail/' + customer.customer_id
                    : ''
                "
              >
                {{ customer.customer_name }}
              </router-link>
            </a>
          </div>
          <div style="margin-bottom: 5px">
            {{ $t("status") }} :
            <a-tag v-if="scheduleDetail.is_sent" color="#87d068">{{
              $t("SEND")
            }}</a-tag>
            <a-tag color="cyan" v-else>{{ $t("NOT_SENT") }}</a-tag>
          </div>

          <div style="margin-bottom: 5px">
            {{ $t("employee") }} :
            <a-tag
              v-for="employee in getListEmployee(scheduleDetail.to)"
              :key="employee.id"
            >
              {{ employee.name }}
            </a-tag>
          </div>
        </div>
        <div style="width: 20px"></div>
        <div>
          <div style="margin-bottom: 5px">
            {{ $t("remind_time_before") }} :
            <strong>{{
              `${scheduleDetail.remind_time_before / 60}  ${$t("minutes")}`
            }}</strong>
          </div>
          <div style="margin-bottom: 5px">
            {{ $t("SCHEDULE_TIME") }} :
            <strong>{{
              dateTimeDisplayFormatFromShortString(
                scheduleDetail.scheduled_time
              )
            }}</strong>
          </div>
          <div style="margin-bottom: 5px">
            {{ $t("CREATED") }} :
            <strong>{{
              dateTimeDisplayFormatFromShortString(scheduleDetail.created_at)
            }}</strong>
          </div>
          <div />
        </div>
      </div>
    </div>

    <a-form
      v-else
      name="custom-validation"
      ref="formRef"
      :label-col="{ span: 6 }"
      :wrapper-col="{ span: 16 }"
    >
      <a-form-item has-feedback :label="$t('FORM_FULL_NAME')" name="phone">
        <a-auto-complete
          class="certain-category-search"
          dropdown-class-name="certain-category-search-dropdown"
          showSearch
          :options="customers"
          v-model:value="customerName"
          @select="selectCustomer"
          :loading="customerSearchLoading"
          @change="handleSearch"
        >
          <template #option="{ value: val }">
            <div class="product_select_item">
              {{ val }}
            </div>
          </template>
        </a-auto-complete>
      </a-form-item>
      <a-form-item :label="$t('remind_time_before')">
        <a-select
          style="width: 100%"
          show-search
          placeholder="Select Timeout"
          :default-active-first-option="false"
          :show-arrow="false"
          :not-found-content="null"
          :filter-option="filterOption"
          v-model:value="formState.remind_time_before"
        >
          <a-select-option
            v-for="time in timeoutSelections"
            :key="time"
            :value="time.value"
            >{{ time.label }}
          </a-select-option>
        </a-select>
      </a-form-item>
      <a-form-item
        has-feedback
        :label="$t('SCHEDULE_TIME')"
        name="schedule_time"
        v-bind="validateInfos.schedule_time"
      >
        <a-date-picker
          v-model:value="formState.schedule_time"
          show-time
          :placeholder="$t('SELECT_TIME_TO_REMIND')"
        />
      </a-form-item>
      <a-form-item
        has-feedback
        :label="$t('MESSAGE_NOTE')"
        name="message_note"
        v-bind="validateInfos.message_note"
      >
        <a-textarea
          v-model:value="formState.message_note"
          :placeholder="$t('MESSAGE_NOTE')"
          :auto-size="{ minRows: 2, maxRows: 5 }"
        />
      </a-form-item>
      <a-form-item :label="$t('SELECT_TYPE')">
        <a-radio-group
          v-model:value="formState.schedule_type"
          :options="plainOptions"
        />
      </a-form-item>
      <a-form-item
        v-if="formState.schedule_type === 'reminder_send_trigger'"
        name="type"
        :label="$t('SLIDING_SUB_MENU_TRIGGER')"
      >
        <a-select
          ref="select"
          v-model:value="formState.trigger_id"
          :placeholder="$t('TRIGGER_TYPE')"
        >
          <a-select-option v-for="item in list" :key="item.id" :value="item.id"
            >{{ $t(item.name) }}
          </a-select-option>
        </a-select>
      </a-form-item>
      <a-form-item
        has-feedback
        :label="$t('STAFF_RECEIVE_NOTIFICATIONS')"
        name="employee_ids"
        v-bind="validateInfos.employee_ids"
        v-if="isAdminOrShopOwner"
      >
        <a-select
          v-model:value="formState.employee_ids"
          mode="tags"
          style="width: 100%"
          @change="handleChange"
          :placeholder="$t('PLEASE_SELECT')"
        >
          <a-select-option
            v-for="item in employeeSelection"
            :key="item.cms_user_id"
            v-bind:value="item.cms_user_id"
            >{{ item.name }}</a-select-option
          >
        </a-select>
      </a-form-item>
      <a-form-item :wrapper-col="{ span: 14, offset: 4 }"> </a-form-item>
    </a-form>
  </a-modal>
</template>

<script lang="ts">
import { computed, defineComponent, onMounted, reactive, ref } from "vue";
import { useStore } from "vuex";
import { UserActionTypes } from "@/store/modules/user/action-types";
import { Schedule } from "@/models/notification/Schedule";
import { SelectionAnt } from "@/models/ShopPages/ConfigData";
import { SchedulesReq } from "@/APIs/request/Notification";
import { DeleteOutlined } from "@ant-design/icons-vue";
import APIs from "@/APIs/APIs";
import { message } from "ant-design-vue";
import { UsersMutationTypes } from "@/store/modules/user/mutation-types";
import { PaginationProps } from "ant-design-vue/es/pagination";
import dayjs, { Dayjs } from "dayjs";
import { useI18n } from "vue-i18n";
import { Customer } from "@/models/Customers/Customers";
import { SuperUserInfo } from "@/models/Users";
import { FormSubmitSchedule } from "@/models/notification/Notification";
import moment from "moment/moment";
import { useForm } from "ant-design-vue/es/form";
import { Trigger } from "@/models/Trigger/Trigger";
import { TriggerActionTypes } from "@/store/modules/trigger/action-types";
import "dayjs/locale/vi";
// @ts-ignore
import updateLocale from "dayjs/plugin/updateLocale";
// @ts-ignore
dayjs.locale("vi");
dayjs.extend(updateLocale);

import { Constant } from "@/utils/Constants";

const columns = [
  {
    dataIndex: "customers",
    key: "customers",
    slots: { customRender: "customers", title: "CUSTOMER" },
  },
  {
    dataIndex: "EMPLOYEE",
    key: "EMPLOYEE",
    slots: { customRender: "employee", title: "EMPLOYEE" },
  },
  {
    dataIndex: "message",
    key: "message",
    slots: { title: "MESSAGE" },
  },
  {
    dataIndex: "schedule_time",
    key: "schedule_time",
    slots: { customRender: "schedule_time", title: "SCHEDULE_TIME" },
  },
  {
    dataIndex: "remind_time_before",
    key: "remind_time_before",
    slots: { customRender: "remind_time_before", title: "REMIND_TIME_BEFORE" },
  },
  {
    dataIndex: "creator_name",
    key: "creator_name",
    slots: { title: "CREATOR" },
  },
  {
    dataIndex: "status",
    key: "status",
    slots: { customRender: "status", title: "STATUS" },
  },
  {
    dataIndex: "created_at",
    key: "created_at",
    slots: { customRender: "created_at", title: "CREATED" },
  },
  {
    key: "action",
    slots: { customRender: "action", title: "ACTION" },
  },
];

interface Filter {
  dateRange: Dayjs[];
  employee: number[];
  page: number;
  page_size: number;
}

export default defineComponent({
  name: "Scheduler",
  components: {
    DeleteOutlined,
  },

  setup() {
    const store = useStore();
    const i18n = useI18n();
    const timeoutSelections = [
      {
        label: "1 " + i18n.t("minutes"),
        value: 60, //second
      },
      {
        label: "2 " + i18n.t("minutes"),
        value: 120, //second
      },
      {
        label: "3 " + i18n.t("minutes"),
        value: 180, //second
      },
      {
        label: "4 " + i18n.t("minutes"),
        value: 240, //second
      },
      {
        label: "5 " + i18n.t("minutes"),
        value: 300, //second
      },
      {
        label: "6 " + i18n.t("minutes"),
        value: 360, //second
      },
      {
        label: "7 " + i18n.t("minutes"),
        value: 420, //second
      },
      {
        label: "8 " + i18n.t("minutes"),
        value: 480, //second
      },
      {
        label: "9 " + i18n.t("minutes"),
        value: 640, //second
      },

      {
        label: "10 " + i18n.t("minutes"),
        value: 600, //second
      },
      {
        label: "11 " + i18n.t("minutes"),
        value: 660, //second
      },
      {
        label: "12 " + i18n.t("minutes"),
        value: 720, //second
      },
      {
        label: "13 " + i18n.t("minutes"),
        value: 780, //second
      },
      {
        label: "14 " + i18n.t("minutes"),
        value: 840, //second
      },
      {
        label: "15 " + i18n.t("minutes"),
        value: 900, //second
      },
      {
        label: "16 " + i18n.t("minutes"),
        value: 960, //second
      },
      {
        label: "17 " + i18n.t("minutes"),
        value: 1020, //second
      },
      {
        label: "18 " + i18n.t("minutes"),
        value: 1080, //second
      },
      {
        label: "19 " + i18n.t("minutes"),
        value: 1140, //second
      },
      {
        label: "20 " + i18n.t("minutes"),
        value: 1200, //second
      },
      {
        label: "21 " + i18n.t("minutes"),
        value: 1260, //second
      },
      {
        label: "22 " + i18n.t("minutes"),
        value: 1320, //second
      },
      {
        label: "23 " + i18n.t("minutes"),
        value: 1380, //second
      },
      {
        label: "24 " + i18n.t("minutes"),
        value: 1440, //second
      },
      {
        label: "25 " + i18n.t("minutes"),
        value: 1500, //second
      },
      {
        label: "26 " + i18n.t("minutes"),
        value: 1560, //second
      },
      {
        label: "27 " + i18n.t("minutes"),
        value: 1620, //second
      },
      {
        label: "28 " + i18n.t("minutes"),
        value: 1680, //second
      },
      {
        label: "29 " + i18n.t("minutes"),
        value: 1740, //second
      },
      {
        label: "30 " + i18n.t("minutes"),
        value: 1800, //second
      },
      {
        label: "31 " + i18n.t("minutes"),
        value: 1860, //second
      },
      {
        label: "32 " + i18n.t("minutes"),
        value: 1920, //second
      },
      {
        label: "33 " + i18n.t("minutes"),
        value: 1980, //second
      },
      {
        label: "34 " + i18n.t("minutes"),
        value: 2040, //second
      },
      {
        label: "35 " + i18n.t("minutes"),
        value: 2100, //second
      },
      {
        label: "36 " + i18n.t("minutes"),
        value: 2160, //second
      },
      {
        label: "37 " + i18n.t("minutes"),
        value: 2220, //second
      },
      {
        label: "38 " + i18n.t("minutes"),
        value: 2280, //second
      },
      {
        label: "39 " + i18n.t("minutes"),
        value: 2340, //second
      },
      {
        label: "40 " + i18n.t("minutes"),
        value: 2400, //second
      },
      {
        label: "41 " + i18n.t("minutes"),
        value: 2460, //second
      },
      {
        label: "42 " + i18n.t("minutes"),
        value: 2520, //second
      },
      {
        label: "43 " + i18n.t("minutes"),
        value: 2580, //second
      },
      {
        label: "44 " + i18n.t("minutes"),
        value: 2640, //second
      },
      {
        label: "45 " + i18n.t("minutes"),
        value: 2700, //second
      },
      {
        label: "46 " + i18n.t("minutes"),
        value: 2760, //second
      },
      {
        label: "47 " + i18n.t("minutes"),
        value: 2820, //second
      },
      {
        label: "48 " + i18n.t("minutes"),
        value: 2880, //second
      },
      {
        label: "49 " + i18n.t("minutes"),
        value: 2940, //second
      },
      {
        label: "50 " + i18n.t("minutes"),
        value: 3000, //second
      },
      {
        label: "51 " + i18n.t("minutes"),
        value: 3060, //second
      },
      {
        label: "52 " + i18n.t("minutes"),
        value: 3120, //second
      },
      {
        label: "53 " + i18n.t("minutes"),
        value: 3180, //second
      },
      {
        label: "54 " + i18n.t("minutes"),
        value: 3240, //second
      },
      {
        label: "55 " + i18n.t("minutes"),
        value: 3300, //second
      },
      {
        label: "56 " + i18n.t("minutes"),
        value: 3360, //second
      },
      {
        label: "57 " + i18n.t("minutes"),
        value: 3420, //second
      },
      {
        label: "58 " + i18n.t("minutes"),
        value: 3480, //second
      },
      {
        label: "59 " + i18n.t("minutes"),
        value: 3540, //second
      },
      {
        label: "1 " + i18n.t("HOURS"),
        value: "3600", //second
      },
      {
        label: "2 " + i18n.t("HOURS"),
        value: 7200, //second
      },
      {
        label: "3 " + i18n.t("HOURS"),
        value: 10800, //second
      },
      {
        label: "4 " + i18n.t("HOURS"),
        value: 14400, //second
      },
      {
        label: "5 " + i18n.t("HOURS"),
        value: 18000, //second
      },
      {
        label: "6 " + i18n.t("HOURS"),
        value: 21600, //second
      },
      {
        label: "7 " + i18n.t("HOURS"),
        value: 25200, //second
      },
      {
        label: "8 " + i18n.t("HOURS"),
        value: 28800, //second
      },
      {
        label: "9 " + i18n.t("HOURS"),
        value: 32400, //second
      },
      {
        label: "10 " + i18n.t("HOURS"),
        value: 36000, //second
      },
      {
        label: "11 " + i18n.t("HOURS"),
        value: 39600, //second
      },
      {
        label: "12 " + i18n.t("HOURS"),
        value: 43200, //second
      },
      {
        label: "13 " + i18n.t("HOURS"),
        value: 46800, //second
      },
      {
        label: "14 " + i18n.t("HOURS"),
        value: 50400, //second
      },
      {
        label: "15 " + i18n.t("HOURS"),
        value: 54000, //second
      },
      {
        label: "16 " + i18n.t("HOURS"),
        value: 57000, //second
      },
      {
        label: "17 " + i18n.t("HOURS"),
        value: 61200, //second
      },
      {
        label: "18 " + i18n.t("HOURS"),
        value: 64800, //second
      },
      {
        label: "19 " + i18n.t("HOURS"),
        value: 68400, //second
      },
      {
        label: "20 " + i18n.t("HOURS"),
        value: 72000, //second
      },
      {
        label: "21 " + i18n.t("HOURS"),
        value: 75600, //second
      },
      {
        label: "22 " + i18n.t("HOURS"),
        value: 79200, //second
      },
      {
        label: "23 " + i18n.t("HOURS"),
        value: 82800, //second
      },
      {
        label: "24 " + i18n.t("HOURS"),
        value: 86400, //second
      },
      {
        label: "2 " + i18n.t("days"),
        value: 172800, //second
      },
      {
        label: "3 " + i18n.t("days"),
        value: 259200, //second
      },
      {
        label: "4 " + i18n.t("days"),
        value: 345600, //second
      },

      {
        label: "5 " + i18n.t("days"),
        value: 432000, //second
      },
      {
        label: "6 " + i18n.t("days"),
        value: 518400, //second
      },
      {
        label: "1 " + i18n.t("week"),
        value: 604800,
      },
      {
        label: "2 " + i18n.t("week"),
        value: 1209600, //second
      },
      {
        label: "3 " + i18n.t("week"),
        value: 1814400, //second
      },
      {
        label: "30 " + i18n.t("days"),
        value: 2592000, //second
      },
    ];
    const scheduleDetail = ref({});

    console.log(dayjs.locale(), "vi", dayjs().toDate());

    const filterForm = reactive<Filter>({
      employee: [],
      dateRange: [dayjs().add(-1, "day"), dayjs().add(30, "day")],
      page: 1,
      page_size: 20,
    });
    // @ts-ignore
    dayjs.updateLocale("en", {
      months: [
        i18n.t("January"),
        i18n.t("February"),
        i18n.t("March"),
        i18n.t("April"),
        i18n.t("May"),
        i18n.t("June"),
        i18n.t("July"),
        i18n.t("August"),
        i18n.t("September"),
        i18n.t("October"),
        i18n.t("November"),
        i18n.t("December"),
      ],
      weekdays: [
        i18n.t("sunday"),
        i18n.t("monday"),
        i18n.t("tuesday"),
        i18n.t("wednesday"),
        i18n.t("thursday"),
        i18n.t("friday"),
        i18n.t("saturday"),
      ],
    });
    const sendType = ref("");
    const plainOptions = [
      { label: i18n.t("reminder"), value: "reminder" },
      {
        label: i18n.t("reminder_send_trigger"),
        value: "reminder_send_trigger",
      },
    ];
    const customerName = ref("");
    const customerSearchLoading = ref(false);
    const currentCustomer = computed(
      (): Customer | undefined => store.getters.getCurrentCustomer
    );
    const userInfo = computed(() => store.getters.getUserInfo);
    const isAdminOrShopOwner = computed(
      (): boolean => store.getters.getIsAdminOrShopOwner
    );
    const schedules = computed((): Schedule[] => store.getters.getSchedules);
    const currentShop = computed(() => store.getters.getCurrentShop);
    const getTriggers = computed(() => store.getters.getTriggers);
    const list = computed<Trigger[]>(
      () => store.getters.getTriggersQuickMessages
    );
    const employeeSelection = computed(() => store.getters.getListEmployee);
    const customers = ref<Customer[]>([]);
    const customerId = ref();
    const visible = ref(false);
    const isLoading = ref(false);
    const checkDetailSchedule = ref(false);
    const pagination = computed(() => {
      return {
        current: store.getters.getScheduleMetaData.page
          ? store.getters.getScheduleMetaData.page
          : 1,
        pageSize: store.getters.getScheduleMetaData.page_size
          ? store.getters.getScheduleMetaData.page_size
          : 20,
        defaultPageSize: 20,
        total: store.getters.getScheduleMetaData.record_total
          ? store.getters.getScheduleMetaData.record_total
          : 0,
        pageSizeOptions: ["20", "40", "80", "120"],
        showSizeChanger: true,
      };
    });
    const filterOption = (input: string, option: any) => {
      console.log(input, option);
      return option.key.label.toLowerCase().indexOf(input.toLowerCase()) >= 0;
    };
    const deleteSchedule = (schedule: Schedule) => {
      APIs.init()
        .deleteSchedules(schedule._id)
        .then((res) => {
          if (res.ok && res.data?.status === 200) {
            message.success(i18n.t("delete_success"));
            store.commit(UsersMutationTypes.DELETE_SCHEDULE, schedule._id);
            visible.value = false;
            checkDetailSchedule.value = false;
          } else {
            message.error("Error: " + res.data?.message);
          }
        })
        .catch((e: any) => {
          console.error(e);
          message.error("Error: " + JSON.stringify(e));
        });
    };

    const importSchedule = async (info: any) => {
      const formData = new FormData();
      formData.append("files", info);
      console.log(formData, "formData");
      APIs.init()
        .importSchedule(formData)
        .then((res) => {
          if (res.status === 200 && res.data?.status === 200) {
            message.success({ content: "Import customer success" });
          } else {
            message.destroy();
          }
        })
        .catch((e) => {
          message.destroy();
          console.error(e);
        });
    };

    const fetchData = () => {
      const scheduleReq: SchedulesReq = {};
      scheduleReq.to = filterForm.employee;
      if (filterForm.dateRange.length > 1) {
        scheduleReq.schedule_time_from = filterForm.dateRange[0].toDate();
        scheduleReq.schedule_time_to = filterForm.dateRange[1].toDate();
      }
      scheduleReq.page = filterForm.page;
      scheduleReq.page_size = filterForm.page_size;
      store.dispatch(UserActionTypes.FETCH_SHOP_SCHEDULES, scheduleReq);
    };
    const getListEmployee = (list: any) => {
      console.log(list, "5");
      return employeeSelection.value.filter((item: any) => {
        console.log(item, "5");
        if (list.includes(item.cms_user_id)) {
          return item;
        }
      });
    };
    const handleTableChange = (pagination: PaginationProps) => {
      if (pagination?.current) {
        filterForm.page = pagination?.current;
      }
      if (pagination?.pageSize) {
        filterForm.page_size = pagination?.pageSize;
      }
      fetchData();
    };
    const employeeHandleChange = () => {
      console.log(employeeSelection);
      fetchData();
    };
    const dateRangeChange = (v: any, t: any, o: any, o2: any, e: any) => {
      console.log("111", v, t, o, o2, e);
      fetchData();
    };
    const panelChange = (value: any, mode: any) => {
      console.log(value, mode, "v4");
    };
    const changeSchedule = (value: any, mode: any) => {
      console.log(value, mode, "v3");
    };
    const selectDay = (value: any, mode: any) => {
      console.log(value, mode, "v5");
    };
    fetchData();

    const showModal = () => {
      visible.value = true;
      checkDetailSchedule.value = false;
      store.dispatch(TriggerActionTypes.FETCH_TRIGGER_QUICK_MESSAGES, {
        payload: { page: 1, page_size: 20 },
      });
    };

    const handleOk = (e: MouseEvent) => {
      console.log(e);
      visible.value = false;
      checkDetailSchedule.value = false;
    };
    const handleChange = (value: number[]) => {
      console.log(`selected `, value);
    };
    const formData: FormSubmitSchedule = {
      customer_ids: [],
      page_id: "",
      creator: 0,
      employee_ids: [],
      shop_id: 0,
      schedule_time: dayjs(),
      schedule_type: "",
      trigger_id: 0,
      remind_time_before: 0,
    };

    const rulesRef = reactive({
      message_note: [
        {
          required: true,
          message: i18n.t("PLEASE_INPUT_NOTE"),
        },
      ],
      schedule_time: [
        {
          required: true,
          message: i18n.t("SCHEDULE_TIME_IS_REQUIRED"),
        },
      ],
      employee_ids: [
        {
          required: false,
          message: "Please select type",
          type: "array",
        },
      ],
    });
    const formState = reactive<FormSubmitSchedule>(formData);
    const { resetFields, validate, validateInfos } = useForm(
      formState,
      rulesRef
    );
    const handleSearch = async (value: string) => {
      if (value.length > 2) {
        customerSearchLoading.value = true;
        APIs.init()
          .getCustomerSearch({ search: value })
          .then((response) => {
            customerSearchLoading.value = false;
            if (response.status === 200 && response.data?.status === 200) {
              if (response.data.data !== null) {
                console.log(response.data.data);

                customers.value = response.data.data.map((item) => {
                  formData.page_id = String(item.page_id);

                  item.value =
                    item.full_name +
                    " - " +
                    item.phone +
                    " (" +
                    item.id +
                    ")\n" +
                    item.address;
                  return item;
                });
              }
            }
          })
          .catch((e) => {
            customerSearchLoading.value = false;
            console.error(e);
          });
      }
    };

    const selectCustomer = (customer_id: string) => {
      console.log(customer_id, "1111");
      const userIds = customer_id.match(/\((.*?)\)/g);
      console.log(userIds, "2222");
      if (userIds && userIds?.length > 0) {
        const userId = Number(userIds[0].replace(/\(/g, "").replace(/\)/g, ""));
        formData.customer_ids = [userId];
        formData.shop_id = currentShop.value.id;
        formData.creator = userInfo.value.id;
      }
    };

    const detailSchedule = (schedule: any) => {
      console.log(schedule, "schedule");
      scheduleDetail.value = schedule;
      visible.value = true;
      checkDetailSchedule.value = true;
    };
    const getListData = (value: Dayjs) => {
      let listData: any = [];
      schedules.value.map((item) => {
        switch (dayjs(value).format("YYYY-MM-DD")) {
          case dayjs(item.scheduled_time).format("YYYY-MM-DD"):
            listData.push(item);

            break;
          default:
        }
      });
      return listData || [];
    };
    const getMonthData = (value: any) => {
      let listData: any = [];
      console.log(dayjs(value).format("DD/MM/YYYY"), "âsass");
      schedules.value.map((item: any) => {
        if (value.month() === dayjs(item.scheduled_time).month()) {
          listData.push(item);
        }
      });
      return listData || [];
    };
    const onSubmit = () => {
      if (checkDetailSchedule.value) {
        visible.value = false;
      } else {
        validate()
          .then(() => {
            // formState.schedule_time=formState.schedule_time2.toDate()
            if (
              !(formState.employee_ids && formState.employee_ids.length > 0)
            ) {
              formState.employee_ids = [formState.creator];
            }
            isLoading.value = true;
            APIs.init()
              .scheduleEmployee(formState)
              .then((res) => {
                isLoading.value = false;
                if (res.ok && res.data) {
                  resetFields();
                  visible.value = false;
                  message.success(i18n.t("schedule_success"));
                  fetchData();
                } else {
                  message.error("Error " + res.data?.message);
                }
              })
              .catch((e) => {
                isLoading.value = false;
                console.error(e);
                message.error("Error " + JSON.stringify(e));
              });
          })
          .catch((err) => {
            console.log("error", err);
          });
      }
    };

    const getUserInfo = computed(() => store.getters.getUserInfo);

    const getRole = computed(() => {
      if (getUserInfo.value.id_cms_privileges < 11) {
        return () => ({
          is_visible: 1,
          is_create: 1,
          is_read: 1,
          is_edit: 1,
          is_delete: 1,
          id: 1,
        });
      } else {
        return store.getters.getRole;
      }
    });
    const modules = Constant.MODULES;

    onMounted(() => {
      fetchData();
    });
    return {
      getRole,
      modules,
      columns,
      customerSearchLoading,
      schedules,
      filterForm,
      employeeSelection,
      employeeHandleChange,
      dateRangeChange,
      pagination,
      customerName,
      handleTableChange,
      deleteSchedule,
      visible,
      showModal,
      handleOk,
      currentCustomer,
      userInfo,
      isAdminOrShopOwner,
      resetFields,
      validate,
      validateInfos,
      rulesRef,
      formState,
      filterOption,
      handleChange,
      customers,
      handleSearch,
      selectCustomer,
      onSubmit,
      plainOptions,
      sendType,
      getTriggers,
      list,
      timeoutSelections,
      getListData,
      getMonthData,
      detailSchedule,
      checkDetailSchedule,
      panelChange,
      selectDay,
      changeSchedule,
      scheduleDetail,
      getListEmployee,
      importSchedule,
    };
  },
});
</script>

<style scoped lang="scss">
.schedule-container {
  margin-top: 14px;
  //padding-left: 4%;
  //padding-right: 4%;

  .header-horizontal {
    display: flex;
    margin-bottom: 14px;
  }
  .table {
    margin-top: 16px;
  }
}
.filter-form-item-inline {
  display: inline-block;
  margin-bottom: 0;
  padding-bottom: 0;
}
.table {
  margin: 16px;
}
.table-row-name {
  font-weight: bold;
}

.events {
  list-style: none;
  margin: 0;
  padding: 0;
}
.events .ant-badge-status {
  overflow: hidden;
  white-space: nowrap;
  width: 100%;
  text-overflow: ellipsis;
  font-size: 12px;
}
.notes-month {
  text-align: center;
  font-size: 28px;
}
.notes-month section {
  font-size: 28px;
}
</style>
