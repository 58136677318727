import { ReportPageComment } from "@/models/ReportPage/ReportPage";

export type State = {
  dataComment: ReportPageComment | undefined;
  dataMessage: ReportPageComment | undefined;
  dataNewConversation: ReportPageComment | undefined;
  dataCustomer : any | undefined
  dataCustomerOrder:any|undefined
  dataRevenue:any|undefined
  dataRevenueTotal:any|undefined
  dataReportEmployee:any|undefined
  dataEmployeeTotal:any|undefined
};

export const state: State = {
    dataComment: {

        took: 0,
        timed_out: false,
        _shards: { total: 0, successful: 0, failed: 0, },
        hits: { total: 0, max_score: 0, hits: [] },
        aggregations: {
          current: {
            doc_count: 0,
            date_time: {
              buckets: [
                {
                  key: 0,
                  doc_count: 0,
                  comment_count: { value: 0 },
                  conversation_count: { value: 0 },
                },
                {
                  key: 0,
                  doc_count: 0,
                  comment_count: { value: 0 },
                  conversation_count: { value: 0 },
                },
                {
                  key: 0,
                  doc_count: 0,
                  comment_count: { value: 0 },
                  conversation_count: { value: 0 },
                },
                {
                  key: 0,
                  doc_count: 0,
                  comment_count: { value: 0 },
                  conversation_count: { value: 0 },
                },
                {
                  key: 0,
                  doc_count: 0,
                  comment_count: { value: 0 },
                  conversation_count: { value: 0 },
                },
                {
                  key: 0,
                  doc_count: 0,
                  comment_count: { value: 0 },
                  conversation_count: { value: 0 },
                },
                {
                  key: 0,
                  doc_count: 0,
                  comment_count: { value: 0 },
                  conversation_count: { value: 0 },
                },
                {
                  key: 0,
                  doc_count: 0,
                  comment_count: { value: 0 },
                  conversation_count: { value: 0 },
                },
                {
                  key: 0,
                  doc_count: 0,
                  comment_count: { value: 0 },
                  conversation_count: { value: 0 },
                },
                {
                  key: 0,
                  doc_count: 0,
                  comment_count: { value: 0 },
                  conversation_count: { value: 0 },
                },
                {
                  key: 0,
                  doc_count: 0,
                  comment_count: { value: 0 },
                  conversation_count: { value: 0 },
                },
                {
                  key: 0,
                  doc_count: 0,
                  comment_count: { value: 0 },
                  conversation_count: { value: 0 },
                },
                {
                  key: 0,
                  doc_count: 0,
                  comment_count: { value: 0 },
                  conversation_count: { value: 0 },
                },
                {
                  key: 0,
                  doc_count: 0,
                  comment_count: { value: 0 },
                  conversation_count: { value: 0 },
                },
                {
                  key: 0,
                  doc_count: 0,
                  comment_count: { value: 0 },
                  conversation_count: { value: 0 },
                },
                {
                  key: 0,
                  doc_count: 0,
                  comment_count: { value: 0 },
                  conversation_count: { value: 0 },
                }
              ],
            },
            sum_conversation: { value: 0 },
            sum_comment: { value: 0 },
          },
          previous: {
            doc_count: 0,
            sum_phone: {
              doc_count: 0,
              sum_conversation: { value: 0 },
              sum_comment: { value: 0 },
            },
            sum_conversation: { value: 0 },
            sum_comment: { value: 0 },
          },
          current_by_phone: {
            doc_count: 0,
            has_phone: {
              doc_count: 0,
              date_time: {
                buckets: [
                  {
                    key: 0,
                    doc_count: 0,
                    comment_count: { value: 0 },
                    conversation_count: { value: 0 },
                  },
                  {
                    key: 0,
                    doc_count: 0,
                    comment_count: { value: 0 },
                    conversation_count: { value: 0 },
                  },
                  {
                    key: 0,
                    doc_count: 0,
                    comment_count: { value: 0 },
                    conversation_count: { value: 0 },
                  },
                  {
                    key: 0,
                    doc_count: 0,
                    comment_count: { value: 0 },
                    conversation_count: { value: 0 },
                  },
                  {
                    key: 0,
                    doc_count: 0,
                    comment_count: { value: 0 },
                    conversation_count: { value: 0 },
                  },
                  {
                    key: 0,
                    doc_count: 0,
                    comment_count: { value: 0 },
                    conversation_count: { value: 0 },
                  },
                  {
                    key: 0,
                    doc_count: 0,
                    comment_count: { value: 0 },
                    conversation_count: { value: 0 },
                  },
                  {
                    key: 0,
                    doc_count: 0,
                    comment_count: { value: 0 },
                    conversation_count: { value: 0 },
                  },
                  {
                    key: 0,
                    doc_count: 0,
                    comment_count: { value: 0 },
                    conversation_count: { value: 0 },
                  },
                  {
                    key: 0,
                    doc_count: 0,
                    comment_count: { value: 0 },
                    conversation_count: { value: 0 },
                  },
                  {
                    key: 0,
                    doc_count: 0,
                    comment_count: { value: 0 },
                    conversation_count: { value: 0 },
                  },
                  {
                    key: 0,
                    doc_count: 0,
                    comment_count: { value: 0 },
                    conversation_count: { value: 0 },
                  },
                  {
                    key: 0,
                    doc_count: 0,
                    comment_count: { value: 0 },
                    conversation_count: { value: 0 },
                  },
                  {
                    key: 0,
                    doc_count: 0,
                    comment_count: { value: 0 },
                    conversation_count: { value: 0 },
                  },
                  {
                    key: 0,
                    doc_count: 0,
                    comment_count: { value: 0 },
                    conversation_count: { value: 0 },
                  },
                  {
                    key: 0,
                    doc_count: 0,
                    comment_count: { value: 0 },
                    conversation_count: { value: 0 },
                  }
                ],
              },
              sum_conversation: { value: 0 },
              sum_comment: { value: 0 },
            },
          },
        },


    },
  dataMessage: {

    took: 0,
    timed_out: false,
    _shards: { total: 0, successful: 0, failed: 0, },
    hits: { total: 0, max_score: 0, hits: [] },
    aggregations: {
      current: {
        doc_count: 0,
        date_time: {
          buckets: [
            {
              key: 0,
              doc_count: 0,
              comment_count: { value: 0 },
              conversation_count: { value: 0 },
            },
            {
              key: 0,
              doc_count: 0,
              comment_count: { value: 0 },
              conversation_count: { value: 0 },
            },
            {
              key: 0,
              doc_count: 0,
              comment_count: { value: 0 },
              conversation_count: { value: 0 },
            },
            {
              key: 0,
              doc_count: 0,
              comment_count: { value: 0 },
              conversation_count: { value: 0 },
            },
            {
              key: 0,
              doc_count: 0,
              comment_count: { value: 0 },
              conversation_count: { value: 0 },
            },
            {
              key: 0,
              doc_count: 0,
              comment_count: { value: 0 },
              conversation_count: { value: 0 },
            },
            {
              key: 0,
              doc_count: 0,
              comment_count: { value: 0 },
              conversation_count: { value: 0 },
            },
            {
              key: 0,
              doc_count: 0,
              comment_count: { value: 0 },
              conversation_count: { value: 0 },
            },
            {
              key: 0,
              doc_count: 0,
              comment_count: { value: 0 },
              conversation_count: { value: 0 },
            },
            {
              key: 0,
              doc_count: 0,
              comment_count: { value: 0 },
              conversation_count: { value: 0 },
            },
            {
              key: 0,
              doc_count: 0,
              comment_count: { value: 0 },
              conversation_count: { value: 0 },
            },
            {
              key: 0,
              doc_count: 0,
              comment_count: { value: 0 },
              conversation_count: { value: 0 },
            },
            {
              key: 0,
              doc_count: 0,
              comment_count: { value: 0 },
              conversation_count: { value: 0 },
            },
            {
              key: 0,
              doc_count: 0,
              comment_count: { value: 0 },
              conversation_count: { value: 0 },
            },
            {
              key: 0,
              doc_count: 0,
              comment_count: { value: 0 },
              conversation_count: { value: 0 },
            },
            {
              key: 0,
              doc_count: 0,
              comment_count: { value: 0 },
              conversation_count: { value: 0 },
            }
          ],
        },
        sum_conversation: { value: 0 },
        sum_comment: { value: 0 },
      },
      previous: {
        doc_count: 0,
        sum_phone: {
          doc_count: 0,
          sum_conversation: { value: 0 },
          sum_comment: { value: 0 },
        },
        sum_conversation: { value: 0 },
        sum_comment: { value: 0 },
      },
      current_by_phone: {
        doc_count: 0,
        has_phone: {
          doc_count: 0,
          date_time: {
            buckets: [
              {
                key: 0,
                doc_count: 0,
                comment_count: { value: 0 },
                conversation_count: { value: 0 },
              },
              {
                key: 0,
                doc_count: 0,
                comment_count: { value: 0 },
                conversation_count: { value: 0 },
              },
              {
                key: 0,
                doc_count: 0,
                comment_count: { value: 0 },
                conversation_count: { value: 0 },
              },
              {
                key: 0,
                doc_count: 0,
                comment_count: { value: 0 },
                conversation_count: { value: 0 },
              },
              {
                key: 0,
                doc_count: 0,
                comment_count: { value: 0 },
                conversation_count: { value: 0 },
              },
              {
                key: 0,
                doc_count: 0,
                comment_count: { value: 0 },
                conversation_count: { value: 0 },
              },
              {
                key: 0,
                doc_count: 0,
                comment_count: { value: 0 },
                conversation_count: { value: 0 },
              },
              {
                key: 0,
                doc_count: 0,
                comment_count: { value: 0 },
                conversation_count: { value: 0 },
              },
              {
                key: 0,
                doc_count: 0,
                comment_count: { value: 0 },
                conversation_count: { value: 0 },
              },
              {
                key: 0,
                doc_count: 0,
                comment_count: { value: 0 },
                conversation_count: { value: 0 },
              },
              {
                key: 0,
                doc_count: 0,
                comment_count: { value: 0 },
                conversation_count: { value: 0 },
              },
              {
                key: 0,
                doc_count: 0,
                comment_count: { value: 0 },
                conversation_count: { value: 0 },
              },
              {
                key: 0,
                doc_count: 0,
                comment_count: { value: 0 },
                conversation_count: { value: 0 },
              },
              {
                key: 0,
                doc_count: 0,
                comment_count: { value: 0 },
                conversation_count: { value: 0 },
              },
              {
                key: 0,
                doc_count: 0,
                comment_count: { value: 0 },
                conversation_count: { value: 0 },
              },
              {
                key: 0,
                doc_count: 0,
                comment_count: { value: 0 },
                conversation_count: { value: 0 },
              }
            ],
          },
          sum_conversation: { value: 0 },
          sum_comment: { value: 0 },
        },
      },
    },


},
  dataNewConversation:{

    took: 0,
    timed_out: false,
    _shards: { total: 0, successful: 0, failed: 0, },
    hits: { total: 0, max_score: 0, hits: [] },
    aggregations: {
      current: {
        doc_count: 0,
        date_time: {
          buckets: [
            {
              key: 0,
              doc_count: 0,
              comment_count: { value: 0 },
              conversation_count: { value: 0 },
            },
            {
              key: 0,
              doc_count: 0,
              comment_count: { value: 0 },
              conversation_count: { value: 0 },
            },
            {
              key: 0,
              doc_count: 0,
              comment_count: { value: 0 },
              conversation_count: { value: 0 },
            },
            {
              key: 0,
              doc_count: 0,
              comment_count: { value: 0 },
              conversation_count: { value: 0 },
            },
            {
              key: 0,
              doc_count: 0,
              comment_count: { value: 0 },
              conversation_count: { value: 0 },
            },
            {
              key: 0,
              doc_count: 0,
              comment_count: { value: 0 },
              conversation_count: { value: 0 },
            },
            {
              key: 0,
              doc_count: 0,
              comment_count: { value: 0 },
              conversation_count: { value: 0 },
            },
            {
              key: 0,
              doc_count: 0,
              comment_count: { value: 0 },
              conversation_count: { value: 0 },
            },
            {
              key: 0,
              doc_count: 0,
              comment_count: { value: 0 },
              conversation_count: { value: 0 },
            },
            {
              key: 0,
              doc_count: 0,
              comment_count: { value: 0 },
              conversation_count: { value: 0 },
            },
            {
              key: 0,
              doc_count: 0,
              comment_count: { value: 0 },
              conversation_count: { value: 0 },
            },
            {
              key: 0,
              doc_count: 0,
              comment_count: { value: 0 },
              conversation_count: { value: 0 },
            },
            {
              key: 0,
              doc_count: 0,
              comment_count: { value: 0 },
              conversation_count: { value: 0 },
            },
            {
              key: 0,
              doc_count: 0,
              comment_count: { value: 0 },
              conversation_count: { value: 0 },
            },
            {
              key: 0,
              doc_count: 0,
              comment_count: { value: 0 },
              conversation_count: { value: 0 },
            },
            {
              key: 0,
              doc_count: 0,
              comment_count: { value: 0 },
              conversation_count: { value: 0 },
            }
          ],
        },
        sum_conversation: { value: 0 },
        sum_comment: { value: 0 },
      },
      previous: {
        doc_count: 0,
        sum_phone: {
          doc_count: 0,
          sum_conversation: { value: 0 },
          sum_comment: { value: 0 },
        },
        sum_conversation: { value: 0 },
        sum_comment: { value: 0 },
      },
      current_by_phone: {
        doc_count: 0,
        has_phone: {
          doc_count: 0,
          date_time: {
            buckets: [
              {
                key: 0,
                doc_count: 0,
                comment_count: { value: 0 },
                conversation_count: { value: 0 },
              },
              {
                key: 0,
                doc_count: 0,
                comment_count: { value: 0 },
                conversation_count: { value: 0 },
              },
              {
                key: 0,
                doc_count: 0,
                comment_count: { value: 0 },
                conversation_count: { value: 0 },
              },
              {
                key: 0,
                doc_count: 0,
                comment_count: { value: 0 },
                conversation_count: { value: 0 },
              },
              {
                key: 0,
                doc_count: 0,
                comment_count: { value: 0 },
                conversation_count: { value: 0 },
              },
              {
                key: 0,
                doc_count: 0,
                comment_count: { value: 0 },
                conversation_count: { value: 0 },
              },
              {
                key: 0,
                doc_count: 0,
                comment_count: { value: 0 },
                conversation_count: { value: 0 },
              },
              {
                key: 0,
                doc_count: 0,
                comment_count: { value: 0 },
                conversation_count: { value: 0 },
              },
              {
                key: 0,
                doc_count: 0,
                comment_count: { value: 0 },
                conversation_count: { value: 0 },
              },
              {
                key: 0,
                doc_count: 0,
                comment_count: { value: 0 },
                conversation_count: { value: 0 },
              },
              {
                key: 0,
                doc_count: 0,
                comment_count: { value: 0 },
                conversation_count: { value: 0 },
              },
              {
                key: 0,
                doc_count: 0,
                comment_count: { value: 0 },
                conversation_count: { value: 0 },
              },
              {
                key: 0,
                doc_count: 0,
                comment_count: { value: 0 },
                conversation_count: { value: 0 },
              },
              {
                key: 0,
                doc_count: 0,
                comment_count: { value: 0 },
                conversation_count: { value: 0 },
              },
              {
                key: 0,
                doc_count: 0,
                comment_count: { value: 0 },
                conversation_count: { value: 0 },
              },
              {
                key: 0,
                doc_count: 0,
                comment_count: { value: 0 },
                conversation_count: { value: 0 },
              }
            ],
          },
          sum_conversation: { value: 0 },
          sum_comment: { value: 0 },
        },
      },
    },


},
  dataCustomer:[],
  dataCustomerOrder:[],
  dataRevenue:[],
  dataRevenueTotal:{},
  dataReportEmployee:[],
  dataEmployeeTotal:{}
};
