<template>
  <div class="main-flow" id="main-flow">
    <a-form ref="formRef" :model="formState" layout="vertical">
      <a-form-item ref="name" :label="$t('FLOW_MESSAGE_NAME')" name="name" v-bind="validateInfos.name">
        <a-input
            :placeholder="$t('FLOW_MESSAGE_NAME')"
            @change="onTextChange"
            v-model:value="viralName"
        />
      </a-form-item>
      <a-form-item :label="$t('FACEBOOK_MESSENGER_TAG')" name="message_tag">
        <a-select
            ref="select"
            v-model:value="messageTag"
            @change="onTextChange"
            :placeholder="$t('FACEBOOK_MESSENGER_TAG')"
        >
          <a-select-option
              v-for="mess in messengerTags"
              :key="mess"
              :value="mess.label"
          >{{ $t(mess.value) }}
          </a-select-option>
        </a-select>
      </a-form-item>
<!--      <a-form-item :label="$t('VIRAL_TYPE')" name="message_tag">-->
<!--        <a-select-->
<!--            v-model:value="formState.type"-->
<!--            @change="onTypeChange"-->
<!--            :placeholder="$t('VIRAL_TYPE')"-->
<!--        >-->
<!--          <a-select-option-->
<!--              v-for="mess in viralTypes"-->
<!--              :key="mess"-->
<!--              :value="mes"-->
<!--          >{{ $t(mess) }}-->
<!--          </a-select-option>-->
<!--        </a-select>-->
<!--      </a-form-item>-->
<!--      <div class="contain-card-lucky-wheel" v-if="formState.type==='lucky_wheel'">-->
<!--        <div class="contain-lucky-wheel-logo">-->
<!--          <div>-->
<!--            <h3>-->
<!--              {{ $t('GIFT_SETTINGS') }}-->
<!--            </h3>-->
<!--            <img src="@/assets/icons/lucky-wheel-icon.svg" class="lucky-wheel-logo"/>-->
<!--          </div>-->
<!--          <div class="container-viral-settings">-->
<!--            <a-form-item :label="$t('NUMBER_OF_PLAY')" :rules="{-->
<!--        required: true,-->
<!--        message: $t('THIS_FIELD_IS_REQUIRED'),-->
<!--      }">-->
<!--              <a-input-number-->
<!--                  placeholder="3"-->
<!--                  @change="onTextChange"-->
<!--                  v-model:value="formState.num_play"-->
<!--              />-->
<!--            </a-form-item>-->
<!--            <div style="width: 10px;"></div>-->
<!--            <a-form-item :label="$t('PLAYS_PER_INVITATION')" :rules="{-->
<!--        required: true,-->
<!--        message: $t('THIS_FIELD_IS_REQUIRED'),-->
<!--      }">-->
<!--              <a-input-number-->
<!--                  placeholder="1"-->
<!--                  @change="onTextChange"-->
<!--                  v-model:value="formState.invite_get_num_play"-->
<!--              />-->
<!--            </a-form-item>-->
<!--            <div style="width: 10px;"></div>-->

<!--          </div>-->
<!--        </div>-->
<!--        <div v-if="formState.gifts&&formState.gifts.length">-->
<!--          <a-row style="align-items: center" v-for="(item,index) in formState.gifts" :key="item.id">-->
<!--           <a-col :span="20">-->
<!--             <a-button :disabled="item.id === 'good_luck' ? true : false" @click="showModalCreateGift(item,index)" style="min-height: 60px;width:100%;margin-bottom: 10px" >-->
<!--               <a-row style="align-items: center" v-if="item.image_url || item.name " justify="space-between">-->
<!--                 <img v-if="item.image_url"-->
<!--                      :src="item.image_url"-->
<!--                      class="ant-upload-list-item-image"-->
<!--                      style="width: 50px">-->
<!--                 <a-typography-text strong>{{item.name}}</a-typography-text>-->
<!--                 <a-typography-text  strong>{{item.id == 'good_luck' ? '' : item.win_rate + '%'}}</a-typography-text>-->
<!--               </a-row>-->
<!--               <a-typography-text v-else strong>{{$t('Create_GIFT')}}</a-typography-text>-->
<!--             </a-button >-->
<!--           </a-col>-->
<!--            <a-col :span="4">-->
<!--              <a-button v-if="item.id !=='good_luck'" type="text" @click="deleteGift(item.id)">-->
<!--               xóa-->
<!--              </a-button>-->
<!--            </a-col>-->

<!--          </a-row>-->

<!--        </div>-->
<!--&lt;!&ndash;        <div class="card-lucky-wheel-control" v-if="formState.gifts&&formState.gifts.length">&ndash;&gt;-->
<!--&lt;!&ndash;          <a-card class="card-card-lucky-wheel-main" v-for="(item,index) in formState.gifts" :key="item.id">&ndash;&gt;-->
<!--&lt;!&ndash;            <div class="lucky-wheel-form-control">&ndash;&gt;-->
<!--&lt;!&ndash;              <div class="ant-upload-list ant-upload-list-picture-card">&ndash;&gt;-->
<!--&lt;!&ndash;                <div class="ant-upload-list-picture-card-container" v-if="item.image_url">&ndash;&gt;-->
<!--&lt;!&ndash;                  <div&ndash;&gt;-->
<!--&lt;!&ndash;                      class="ant-upload-list-item ant-upload-list-item-done ant-upload-list-item-list-type-picture-card">&ndash;&gt;-->
<!--&lt;!&ndash;                    <div class="ant-upload-list-item-info">&ndash;&gt;-->
<!--&lt;!&ndash;                          <span class="ant-upload-span">&ndash;&gt;-->
<!--&lt;!&ndash;                            <a&ndash;&gt;-->
<!--&lt;!&ndash;                                class="ant-upload-list-item-thumbnail"><img&ndash;&gt;-->
<!--&lt;!&ndash;                                :src="item.image_url"&ndash;&gt;-->
<!--&lt;!&ndash;                                class="ant-upload-list-item-image">&ndash;&gt;-->
<!--&lt;!&ndash;                          </a>&ndash;&gt;-->
<!--&lt;!&ndash;                            &lt;!&ndash;&ndash;&gt;</span></div>&ndash;&gt;-->
<!--&lt;!&ndash;                    <span class="ant-upload-list-item-actions">&ndash;&gt;-->
<!--&lt;!&ndash;                          <a&ndash;&gt;-->
<!--&lt;!&ndash;                              :href="item.image_url"&ndash;&gt;-->
<!--&lt;!&ndash;                              target="_blank" rel="noopener noreferrer" title="Preview file">&ndash;&gt;-->
<!--&lt;!&ndash;                            <eye-outlined/>&ndash;&gt;-->
<!--&lt;!&ndash;                          </a>&ndash;&gt;-->
<!--&lt;!&ndash;                          <button&ndash;&gt;-->
<!--&lt;!&ndash;                              @click="deleteGiftImage(index)"&ndash;&gt;-->
<!--&lt;!&ndash;                              class="ant-btn ant-btn-text ant-btn-sm ant-upload-list-item-card-actions-btn ant-btn-icon-only"&ndash;&gt;-->
<!--&lt;!&ndash;                              title="Remove file" type="button">&ndash;&gt;-->
<!--&lt;!&ndash;                          <delete-outlined/>&ndash;&gt;-->
<!--&lt;!&ndash;                        </button>&ndash;&gt;-->
<!--&lt;!&ndash;                        </span>&ndash;&gt;-->
<!--&lt;!&ndash;                    <div class="ant-upload-list-item-progress" style="display: none;">&lt;!&ndash;&ndash;&gt;</div>&ndash;&gt;-->
<!--&lt;!&ndash;                  </div>&ndash;&gt;-->
<!--&lt;!&ndash;                </div>&ndash;&gt;-->
<!--&lt;!&ndash;                <div @click="showImageSingleSelectionModal(index)"&ndash;&gt;-->
<!--&lt;!&ndash;                     class="ant-upload ant-upload-select ant-upload-select-picture-card" v-else>&ndash;&gt;-->
<!--&lt;!&ndash;                  <span tabindex="0"&ndash;&gt;-->
<!--&lt;!&ndash;                        class="ant-upload"&ndash;&gt;-->
<!--&lt;!&ndash;                        role="button"><input&ndash;&gt;-->
<!--&lt;!&ndash;                      type="file" accept="" capture="false" style="display: none;"><div>&ndash;&gt;-->
<!--&lt;!&ndash;                      <plus-outlined/>&ndash;&gt;-->
<!--&lt;!&ndash;                      <div style="margin-top: 8px;">{{ $t('UPLOAD') }}</div></div></span>&ndash;&gt;-->
<!--&lt;!&ndash;                </div>&ndash;&gt;-->
<!--&lt;!&ndash;              </div>&ndash;&gt;-->
<!--&lt;!&ndash;              <span style="color: red" v-if="!item.image_url">{{ $t('IMAGE_IS_REQUIRED') }}</span>&ndash;&gt;-->
<!--&lt;!&ndash;              <a-form-item :label="$t('GIFT_NAME')" :rules="{&ndash;&gt;-->
<!--&lt;!&ndash;        required: true,&ndash;&gt;-->
<!--&lt;!&ndash;        message: $t('THIS_FIELD_IS_REQUIRED'),&ndash;&gt;-->
<!--&lt;!&ndash;      }">&ndash;&gt;-->
<!--&lt;!&ndash;                <a-input&ndash;&gt;-->
<!--&lt;!&ndash;                    @change="onTextChange"&ndash;&gt;-->
<!--&lt;!&ndash;                    v-model:value="item.name"&ndash;&gt;-->
<!--&lt;!&ndash;                    :placeholder="$t('SNOWFLAKE')"&ndash;&gt;-->
<!--&lt;!&ndash;                />&ndash;&gt;-->
<!--&lt;!&ndash;              </a-form-item>&ndash;&gt;-->
<!--&lt;!&ndash;              <a-form-item :label="$t('WIN_RATE')"&ndash;&gt;-->
<!--&lt;!&ndash;                           :rules="{&ndash;&gt;-->
<!--&lt;!&ndash;        required: true,&ndash;&gt;-->
<!--&lt;!&ndash;        message: $t('THIS_FIELD_IS_REQUIRED'),&ndash;&gt;-->
<!--&lt;!&ndash;      }">&ndash;&gt;-->
<!--&lt;!&ndash;                <a-input-number&ndash;&gt;-->
<!--&lt;!&ndash;                    style="width: 200px;"&ndash;&gt;-->
<!--&lt;!&ndash;                    v-model:value="item.win_rate"&ndash;&gt;-->
<!--&lt;!&ndash;                    @change="onTextChange"&ndash;&gt;-->
<!--&lt;!&ndash;                    :placeholder="$t('WIN_RATE_PERCENT')"&ndash;&gt;-->
<!--&lt;!&ndash;                />&ndash;&gt;-->
<!--&lt;!&ndash;              </a-form-item>&ndash;&gt;-->

<!--&lt;!&ndash;            </div>&ndash;&gt;-->
<!--&lt;!&ndash;            <div style="position: absolute; top:0;right: 0;padding: 2px;">&ndash;&gt;-->
<!--&lt;!&ndash;              <a-button type="text" @click="deleteGift(item.id)">&ndash;&gt;-->
<!--&lt;!&ndash;                <delete-outlined/>&ndash;&gt;-->
<!--&lt;!&ndash;              </a-button>&ndash;&gt;-->
<!--&lt;!&ndash;            </div>&ndash;&gt;-->
<!--&lt;!&ndash;          </a-card>&ndash;&gt;-->
<!--&lt;!&ndash;        </div>&ndash;&gt;-->
<!--        <div class="add-more-gift">-->
<!--          <a-button type="dashed" @click="addMoreGift">-->
<!--            <plus-outlined/>-->
<!--            {{ $t('ADD_MORE') }}-->
<!--          </a-button>-->
<!--        </div>-->
<!--        <a-divider/>-->

<!--      </div>-->

      <div>
        <a-form-item :label="$t('SHARES_ON_GIFT')" :rules="{
        required: true,
        message: $t('THIS_FIELD_IS_REQUIRED'),
      }">
          <a-input-number
              placeholder="3"
              @change="onTextChange"
              v-model:value="formState.viral_must_share"
          />
        </a-form-item>

       <a-row>
         <a-card
             class="card-message"
             style="margin-right: 5px"
         >
           <template #title>
             <div>
              <span>
              {{$t('MESSAGE_CONTENT') + " " + 1}}
            </span>
               <a-avatar class="logo-url" shape="square" @click="showImageSingleSelectionModal('logo')" :src="logoUrl">
                 {{$t('UPLOAD')}}
               </a-avatar>
             </div>
           </template>
           <template #cover>
             <div>
               <img
                   class="img-cover-facebook-template"
                   alt="example"
                   v-if="formState.image_url"
                   :src="formState.image_url"
                   @click="showImageSingleSelectionModal('')"
               />
               <span
                   class="ant-upload-picture-card-wrapper upload-center"
                   v-else
               >
                          <div
                              class="ant-upload ant-upload-select ant-upload-select-picture-card"
                              @click="showImageSingleSelectionModal('')"
                          >
                            <span role="button" tabindex="0" class="ant-upload">
                              <div>
                                <plus-outlined/>
                                <div class="ant-upload-text">
                                  {{ $t("UPLOAD") }}
                                </div>
                              </div>
                            </span>
                          </div>
                        </span>
             </div>
           </template>
           <a-card-meta>
             <template #title>
               <a-form-item name="title" v-bind="validateInfos.title">
                 <a-input
                     showCount
                     :maxlength="40"
                     :placeholder="$t('FLOW_FACEBOOK_BUTTON_NAME')"
                     v-model:value="formState.title"
                     @change="onTextChange"
                 />
               </a-form-item>
             </template>
             <template #description>
               <a-form-item name="description" v-bind="validateInfos.description">
                 <a-textarea
                     showCount
                     :maxlength="58"
                     :placeholder="$t('SUBTITLE')"
                     v-model:value="formState.description"
                     @change="onTextChange"
                     @click="forcusMessagetext"
                     ref="subtitle"
                 />
                 <a-tag
                     @click="templateMessageFullName(1)"
                     style="cursor: pointer"
                 >##full_name##
                 </a-tag
                 >
               </a-form-item>
               <a-form-item style="text-align: center" name="button_title" v-bind="validateInfos.button_title">
                 <a-input
                     :placeholder="$t('BUTTON_TITLE')"
                     v-model:value="formState.button_title"
                     style="width: 150px;"
                     @change="onTextChange"
                 />
               </a-form-item>

             </template>
           </a-card-meta>
           <!--          <flow-next-actions-->
           <!--              :buttonName="$t('ACTION_SUBMIT_CLICK')"-->
           <!--              :updateAction="updateSubmitAction"-->
           <!--              :flowContextData="flowContextData"-->
           <!--              :flowContext="flowContext"-->
           <!--              :actionContext="formState.action_submit"-->
           <!--          />-->
         </a-card>
         <a-card
             class="card-message"
         >
           <template #title>
             <div>
              <span>
              {{$t('MESSAGE_CONTENT') + " " + 2}}
            </span>
               <a-avatar class="logo-url" shape="square" @click="showImageSingleSelectionModal2('logo')" :src="logoUrl2">
                 {{$t('UPLOAD')}}
               </a-avatar>
             </div>
           </template>
           <template #cover>
             <div>
               <img
                   class="img-cover-facebook-template"
                   alt="example"
                   v-if="formState2.image_url"
                   :src="formState2.image_url"
                   @click="showImageSingleSelectionModal2('')"
               />
               <span
                   class="ant-upload-picture-card-wrapper upload-center"
                   v-else
               >
                          <div
                              class="ant-upload ant-upload-select ant-upload-select-picture-card"
                              @click="showImageSingleSelectionModal2('')"
                          >
                            <span role="button" tabindex="0" class="ant-upload">
                              <div>
                                <plus-outlined/>
                                <div class="ant-upload-text">
                                  {{ $t("UPLOAD") }}
                                </div>
                              </div>
                            </span>
                          </div>
                        </span>
             </div>
           </template>
           <a-card-meta>
             <template #title>
               <a-form-item name="title" v-bind="validateInfos.title">
                 <a-input
                     showCount
                     :maxlength="40"
                     :placeholder="$t('FLOW_FACEBOOK_BUTTON_NAME')"
                     v-model:value="formState2.title"
                     @change="onTextChange"
                 />
               </a-form-item>
             </template>
             <template #description>
               <a-form-item name="description" v-bind="validateInfos.description">
                 <a-textarea
                     showCount
                     :maxlength="58"
                     :placeholder="$t('SUBTITLE')"
                     v-model:value="formState2.description"
                     @change="onTextChange"
                     @click="forcusMessagetext2"
                     ref="subtitle"
                 />
                 <a-tag
                     @click="templateMessageFullName(2)"
                     style="cursor: pointer"
                 >##full_name##
                 </a-tag
                 >
               </a-form-item>
               <a-form-item style="text-align: center" name="button_title" v-bind="validateInfos.button_title">
                 <a-input
                     :placeholder="$t('BUTTON_TITLE')"
                     v-model:value="formState2.button_title"
                     style="width: 150px;"
                     @change="onTextChange"
                 />
               </a-form-item>

             </template>
           </a-card-meta>
           <!--          <flow-next-actions-->
           <!--              :buttonName="$t('ACTION_SUBMIT_CLICK')"-->
           <!--              :updateAction="updateSubmitAction"-->
           <!--              :flowContextData="flowContextData"-->
           <!--              :flowContext="flowContext"-->
           <!--              :actionContext="formState.action_submit"-->
           <!--          />-->
         </a-card>
       </a-row>
        <a-card style="width: 100%" v-for="(message,msIndex) in messageContexts" :key="message" class="card-message">
          <template #title>
            <drag-outlined style="cursor: grab; margin-right: 15px"/>
            <span>{{ $t("MESSAGES_INVITING_FRIENDS") }}</span>
          </template>
<!--          <template #extra>-->
<!--            <a-button type="text" @click="deleteMessage(msIndex)">-->
<!--              <close-outlined/>-->
<!--            </a-button>-->
<!--          </template>-->
          <a-form-item name="message_content">
            <a-textarea

                :placeholder="
                  $t(
                    'FLOW_FACEBOOK_BUTTON_POST_BACK_MESSAGE_CONTENT_PLACEHOLDER'
                  )
                "
                class="drag-disabled "
                ref="inputMessageContent"
                showCount
                :maxlength="1200"
                :auto-size="{ minRows: 2, maxRows: 8 }"
                :draggable="false"
                v-model:value="message.text"
                @change="onTextChange"
                @click="forcusMessagetext"
            />
            <a-tag @click="messageFullName(msIndex,'messageContent')" style="cursor: pointer"
            >##full_name##
            </a-tag
            >
<!--            <a-tag @click="messageNeedToshare(msIndex,'messageContent')" style="cursor: pointer"-->
<!--            >##need_to_share##-->
<!--            </a-tag-->
<!--            >-->
          </a-form-item>
<!--          <div class="post-back-button">-->
<!--            <template v-if="message.buttons">-->
<!--              <template-->
<!--                  v-for="(button, btnIndex) in message.buttons"-->
<!--                  :key="button.id"-->
<!--              >-->
<!--                <facebook-postback-button-->
<!--                    :postbackContext="button"-->
<!--                    :flowContextData="flowContextData"-->
<!--                    :deletePostBackButton="-->
<!--                      () => deletePostbackButton(msIndex, btnIndex)-->
<!--                    "-->
<!--                    :updatePostBackButton="-->
<!--                      (data) => updatePostBackButton(data, msIndex, btnIndex)-->
<!--                    "-->
<!--                />-->
<!--              </template>-->
<!--            </template>-->

<!--            <a-button-->
<!--                type="primary"-->
<!--                class="button-post-back"-->
<!--                v-if="message.buttons.length < 3"-->
<!--                @click="addPostBackButton(msIndex)"-->
<!--            >-->
<!--              <plus-outlined/>-->
<!--              {{ $t("FLOW_ADD_BUTTONS") }}-->
<!--            </a-button>-->
<!--          </div>-->
        </a-card>
        <flow-next-actions
            v-if="!isShow"
            :buttonName="$t('FLOW_FINISH_VIRAL')"
            :updateAction="updateSuccessActionFinishViral"
            :flowContextData="flowContextData"
            :flowContext="flowContext"
            :actionContext="flowContext.action_finish_viral"
        />

      </div>


      <a-divider/>
      <flow-next-actions
          v-if="!isShow"
          :buttonName="$t('FLOW_SUCCESS_DELIVERY')"
          :updateAction="updateSuccessAction"
          :flowContextData="flowContextData"
          :flowContext="flowContext"
          :actionContext="flowContext.action_send_success_context"
      />
      <flow-next-actions
          v-if="!isShow"
          :buttonName="$t('FLOW_FAILED_DELIVERY')"
          :updateAction="updateFailedAction"
          :flowContextData="flowContextData"
          :flowContext="flowContext"
          :actionContext="flowContext.action_send_failed_context"
      />
      <flow-next-actions
          v-if="!isShow"
          :buttonName="$t('FLOW_DEFAULT_DELIVERY')"
          :updateAction="updateDefaultAction"
          :flowContextData="flowContextData"
          :flowContext="flowContext"
          :actionContext="flowContext.action_default_context"
      />
      <div class="row-button-action" style="text-align: right">
        <a-popconfirm
            @confirm="deleteCurrentFlowContext"
            :title="$t('FLOW_CONFIRM_DELETE_CONTEXT')"
            :ok-text="$t('YES')"
            :cancel-text="$t('NO')"
        >
          <a-button danger type="primary">
            <delete-outlined/>
            {{ $t("FLOW_DELETE") }}
          </a-button>
        </a-popconfirm>
      </div>
    </a-form>
    <image-selection-modal
        :single="true"
        ref="imageSelectionRef"
        :onSelect="onImageSelect"
    />
    <image-selection-modal
        :single="true"
        ref="imageSelectionRef2"
        :onSelect="onImageSelect2"
    />
    <a-modal v-model:visible="showCreateGift" :title="$t('CREATE_GIFT')" @ok="handleOk">
      <div class="lucky-wheel-form-control">
        <div class="ant-upload-list ant-upload-list-picture-card">
          <div style="display: flex;justify-content: center;width: 100%" class="ant-upload-list-picture-card-container" v-if="giftData.image_url">
            <div
                class="ant-upload-list-item ant-upload-list-item-done ant-upload-list-item-list-type-picture-card">
              <div class="ant-upload-list-item-info">
                          <span class="ant-upload-span">
                            <a
                                class="ant-upload-list-item-thumbnail"><img
                                :src="giftData.image_url"
                                class="ant-upload-list-item-image">
                          </a>
                            <!----></span></div>
              <span class="ant-upload-list-item-actions">
                          <a
                              :href="giftData.image_url"
                              target="_blank" rel="noopener noreferrer" title="Preview file">
                            <eye-outlined/>
                          </a>
                          <button
                              @click="deleteGiftImage(giftIndex)"
                              class="ant-btn ant-btn-text ant-btn-sm ant-upload-list-item-card-actions-btn ant-btn-icon-only"
                              title="Remove file" type="button">
                          <delete-outlined/>
                        </button>
                        </span>
              <div class="ant-upload-list-item-progress" style="display: none;"><!----></div>
            </div>
          </div>
          <div @click="showImageSingleSelectionModal(giftIndex)"
               class="ant-upload ant-upload-select ant-upload-select-picture-card" v-else>
                  <span tabindex="0"
                        class="ant-upload"
                        role="button"><input
                      type="file" accept="" capture="false" style="display: none;"><div>
                      <plus-outlined/>
                      <div style="margin-top: 8px;">{{ $t('UPLOAD') }}</div></div></span>
          </div>
        </div>
        <span style="color: red" v-if="!giftData.image_url">{{ $t('IMAGE_IS_REQUIRED') }}</span>
        <a-form-item :label="$t('GIFT_NAME')" :rules="{
        required: true,
        message: $t('THIS_FIELD_IS_REQUIRED'),
      }">
          <a-input
              @change="onTextChange"
              v-model:value="formState.gifts[giftIndex].name"
              :placeholder="$t('SNOWFLAKE')"
          />
        </a-form-item>
        <a-form-item v-if="formState.gifts[giftIndex].id !== 'good_luck'" :label="$t('WIN_RATE')"
                     :rules="{
        required: true,
        message: $t('THIS_FIELD_IS_REQUIRED'),
      }">
          <a-input-number

              style="width: 100%;"
              v-model:value="formState.gifts[giftIndex].win_rate"
              @change="onTextChange"
              :placeholder="$t('WIN_RATE_PERCENT')"
          />
        </a-form-item>
        <a-form-item :label="$t('GIFT_LITMIT')" :rules="{
        required: true,
        message: $t('THIS_FIELD_IS_REQUIRED'),
      }">
          <a-input-number
              placeholder="1"
              @change="onTextChange"
              v-model:value="formState.gifts[giftIndex].limit"
          />
        </a-form-item>
        <flow-next-actions
            :buttonName="$t('FLOW_SUCCESS_DELIVERY')"
            :updateAction="updateSubmitActionGift"
            :flowContextData="flowContextData"
            :flowContext="flowContext"
            :actionContext="formState.gifts[giftIndex].flow_action_context"
        />
      </div>
    </a-modal>
  </div>
</template>

<script lang="ts">
import {
  computed,
  defineComponent,
  onBeforeMount, onMounted,
  reactive,
  ref,
  UnwrapRef,
  watch,
} from "vue";
import {
  CloseCircleOutlined,
  CloseOutlined,
  DeleteOutlined,
  DragOutlined,
  EyeOutlined,
  PictureOutlined,
  FileDoneOutlined,
  PlusOutlined,
} from "@ant-design/icons-vue";
import FlowNextActions from "@/components/Flow/FlowNextActions.vue";
import {useStore} from "@/store";
import {S_EventBus, ValidateFlowContext} from "@/utils/EventBus";
import {useI18n} from "vue-i18n";
import {MixUtils} from "@/utils/Utils";
import ImageSelectionModal from "@/components/Image/ImageSelectionModal.vue";
import "vue3-carousel/dist/carousel.css";
import {AWS_DO_Model} from "@/models/AWS_DO/AWS_DO";
import {
  ActionContext, FlowContext, FlowViral, FlowViralGift,
  MessageContext,
  PostbackContext,
  QuickReply,
} from "@/models/Flows/FlowContext";
import {
  FormFlowInformation,
} from "@/models/Flows/FlowForm";
import {ValidateErrorEntity} from "ant-design-vue/es/form/interface";
import {Form} from 'ant-design-vue';
import {compute} from "googleapis/build/src/apis/compute";

const useForm = Form.useForm;

export default defineComponent({
  name: "flow-viral",
  props: [
    "flowContext",
    "updateCurrentFlowContext",
    "flowContextData",
    "deleteFlowContext",
      "isShow"
  ],
  components: {
    DeleteOutlined,
    FlowNextActions,
    ImageSelectionModal,
    PlusOutlined,
    EyeOutlined
  },
  setup(props) {
    const formRef = ref();
    const subtitle = ref()

    const messengerTags = ref([
      {
        label: "NONE",
        value: "",
      },
      {
        label: "CONFIRMED_EVENT_UPDATE",
        value: "CONFIRMED_EVENT_UPDATE",
      },
      {
        label: "POST_PURCHASE_UPDATE",
        value: "POST_PURCHASE_UPDATE",
      },
      {
        label: "ACCOUNT_UPDATE",
        value: "ACCOUNT_UPDATE",
      },
    ]);
    const store = useStore();
    const i18n = useI18n();
    // @ts-ignore
    const formState: UnwrapRef<FlowViral> = reactive({
      gifts:[
        {
          id:'good_luck',
          name:'good_luck',
          image_url: 'https://newsocial.sgp1.digitaloceanspaces.com/121212/1710815146_good-luck.jpg',

        }
      ],
    });const formState2: UnwrapRef<FlowViral> = reactive({
      gifts:[
        {
          id:'good_luck',
          name:'good_luck',
          image_url: 'https://newsocial.sgp1.digitaloceanspaces.com/121212/1710815146_good-luck.jpg',

        }
      ],
    });

    const messageTag = ref('');
    const logoUrl = ref('');
const logoUrl2 = ref('')
    const viralName = ref('');
    const messageContexts = ref<MessageContext[]>([
      {
        id: MixUtils.methods.getUUID(),
        type: 'text',
        text: '##full_name## đã tham gia chương trình, bạn cần chia sẻ thêm ##need_to_share## người nữa để được nhận quà'

      }
    ]);
    const imageSelectionRef = ref();
    const imageSelectionRef2 = ref();
    const viralTypes = computed((): string[] => store.getters.getFlowViralTypes)
    const  cursorPositionMessageText= ref(0)
    const showCreateGift = ref(false)
    const giftData= ref({})
    const {resetFields, validate, validateInfos} = useForm(
        formRef,
        reactive({
          name: [
            {
              required: true,
              message: i18n.t("FLOW_VALIDATE_FLOW_NAME"),
              trigger: "blur",
            },
          ],
          button_title: [
            {
              required: true,
              message: i18n.t("THIS_FIELD_IS_REQUIRED"),
              trigger: "blur",
            },
          ],
          title: [
            {
              required: true,
              message: i18n.t("THIS_FIELD_IS_REQUIRED"),
              trigger: "blur",
            },
          ],
          description: [
            {
              required: true,
              message: i18n.t("THIS_FIELD_IS_REQUIRED"),
              trigger: "blur",
            },
          ],
        }),
    );

    const templateMessageFullName = (i:number) => {
    if(i === 1){
      subtitle.value.focus()
      const text = ref<any>( formState.description);
      const start = text.value.slice(0,cursorPositionMessageText.value)
      const end = text.value.slice(cursorPositionMessageText.value)
      text.value =
          start +
          (start ? " " : "") + "##full_name##" +  (end ? " " : "") + end;
      formState.description = text.value
      onSubmit();
    }else {
      subtitle.value.focus()
      const text = ref<any>( formState2.description);
      const start = text.value.slice(0,cursorPositionMessageText.value)
      const end = text.value.slice(cursorPositionMessageText.value)
      text.value =
          start +
          (start ? " " : "") + "##full_name##" +  (end ? " " : "") + end;
      formState2.description = text.value
      onSubmit();
    }
    }


    const updateProps = () => {

      if (props.flowContext && props.flowContext && props.flowContext.flow_viral && props.flowContext.flow_viral.length > 0) {
        viralName.value=props.flowContext.name
        messageTag.value=props.flowContext.messenger_tag
        formState.url = props.flowContext.flow_viral[0].url;
        formState.type = props.flowContext.flow_viral[0].type;
        formState.id = props.flowContext.flow_viral[0].id;
        formState.button_title = props.flowContext.flow_viral[0].button_title;
        formState.image_url = props.flowContext.flow_viral[0].image_url;
        formState.description = props.flowContext.flow_viral[0].description;
        formState.title = props.flowContext.flow_viral[0].title;
        formState.invite_get_num_play = props.flowContext.flow_viral[0].invite_get_num_play;
        formState.num_play = props.flowContext.flow_viral[0].num_play;
        formState.viral_must_share = props.flowContext.viral_must_share
        if(props.flowContext.flow_viral[0].gifts){
          formState.gifts =  props.flowContext.flow_viral[0].gifts ;
        }
        formState.action_submit = props.flowContext.flow_viral[0].action_submit;
        if (props.flowContext.attachment_images&&props.flowContext.attachment_images.length>0){
          logoUrl.value=props.flowContext.attachment_images[0]
        }
        if(props.flowContext.flow_viral[1]){
          formState2.url = props.flowContext.flow_viral[1].url;
          formState2.type = props.flowContext.flow_viral[1].type;
          formState2.id = props.flowContext.flow_viral[1].id;
          formState2.button_title = props.flowContext.flow_viral[1].button_title;
          formState2.image_url = props.flowContext.flow_viral[1].image_url;
          formState2.description = props.flowContext.flow_viral[1].description;
          formState2.title = props.flowContext.flow_viral[1].title;
          formState2.invite_get_num_play = props.flowContext.flow_viral[1].invite_get_num_play;
          formState2.num_play = props.flowContext.flow_viral[1].num_play;
          if(props.flowContext.flow_viral[1].gifts){
            formState.gifts =  props.flowContext.flow_viral[1].gifts ;
          }
          formState.action_submit = props.flowContext.flow_viral[1].action_submit;
        }

      } else {
        formState.id = MixUtils.methods.getUUID();
        formState.gifts = []
      }
    };
    watch(
        () => props.flowContext,
        () => {
          updateProps();
        }
    );
    const flowFormValidate: ValidateFlowContext = {
      showValidateForm() {
        onSubmit();
      },
    };
    onBeforeMount(() => {
      S_EventBus.instance().setValidateFlowContextListener(flowFormValidate);
    });
    const forcusMessagetext = (e: any) =>{
      // if(e.target.value.lastIndexOf('#') !== -1){
      //   e.target.setSelectionRange(e.target.value.lastIndexOf('#')+1,e.target.value.lastIndexOf('#')+1)
      //   console.log(e.target.selectionStart)
      // }else {
      cursorPositionMessageText.value = e.target.selectionStart




    }
    const forcusMessagetext2 = (e: any) =>{
      // if(e.target.value.lastIndexOf('#') !== -1){
      //   e.target.setSelectionRange(e.target.value.lastIndexOf('#')+1,e.target.value.lastIndexOf('#')+1)
      //   console.log(e.target.selectionStart)
      // }else {
      cursorPositionMessageText.value = e.target.selectionStart




    }
    const onTextChange = (e: any) => {
      if(e && e.target && e.target.selectionStart){
        cursorPositionMessageText.value = e.target.selectionStart
        onSubmit();
      }else {
        onSubmit()
      }

    };
  const giftIndex = ref(0)
    const showModalCreateGift = (data:any,index: number) => {
      showCreateGift.value = true
      giftData.value = data
      giftIndex.value = index


    }
    const handleOk = () =>{
      showCreateGift.value = false


    }
    const onTypeChange = (val: string) => {
      formState.type = val
      if (!formState.gifts || !formState.gifts.length) {
        formState.gifts = [
          {
            id: MixUtils.methods.getUUID(),
            win_rate: 10,
          }
        ]
      }
      onSubmit()
    };

    const attachFile = () => {
      imageSelectionRef.value.showModal()
    }
    const attachFile2 = () => {
      imageSelectionRef2.value.showModal()
    }

    const addMoreGift = () => {
      const gift: FlowViralGift = {
        id: MixUtils.methods.getUUID(),
        win_rate: 10,
      }
      if (formState.gifts) {
        formState.gifts = [ gift,...formState.gifts]
      } else {
        formState.gifts = [gift]
      }
    }

    const deleteGift = (id: string) => {

      if (formState.gifts) {
        formState.gifts = formState.gifts.filter(x => x.id !== id)
      }
      onSubmit()
    }
    const deleteGiftImage = (index: number) => {
      if (formState.gifts) {
        formState.gifts[index].image_url = ""
      }
    }
    const inputMessageContent = ref()
    const messageFullName = (msIndex: number,t:string) => {
      if(t === 'messageContent'){
        inputMessageContent.value[0].focus()
      }

      // @ts-ignore
      const text = ref<any>(messageContexts.value[msIndex].text);
      const start = text.value.slice(0,cursorPositionMessageText.value)
      const end = text.value.slice(cursorPositionMessageText.value)
      text.value =

          start + (start ? " " : "") + "##full_name##" + (end ? " " : "") + end
      // @ts-ignores
      messageContexts.value[msIndex].text = text.value;

      onSubmit();

    };
    const onImageSelect = (list: AWS_DO_Model[], param: string) => {
      if (list.length > 0) {
        console.log("foonImageSelectrmState", list, param)
        if (param) {
          if (param==='logo'){
            logoUrl.value=list[0].url
          }else {
            const position = parseInt(param)
            if (formState.gifts) {
              formState.gifts[position].image_url = list[0].url
            }
          }
        } else {
          formState.image_url = list[0].url
          console.log("formState", formState)
        }
      onSubmit()
      }

    }
    const onImageSelect2 = (list: AWS_DO_Model[], param: string) => {
      console.log(2)
      if (list.length > 0) {
        console.log("foonImageSelectrmState", list, param)
        if (param) {
          if (param==='logo'){
            // @ts-ignore
            logoUrl2.value=list[0].url
          }else {
            const position = parseInt(param)
            if (formState.gifts) {
              // @ts-ignore
              formState2.gifts[position].image_url = list[0].url
            }
          }
        } else {
          formState2.image_url = list[0].url
          console.log("formState", formState)
        }
        onSubmit()
      }

    }



    const updateSuccessAction = (action: ActionContext) => {
      const flow = {...props.flowContext};
      flow.action_send_success_context = action;
      props.updateCurrentFlowContext(flow);
    };
    const updateSuccessActionFinishViral =(action: ActionContext) => {
  const flow = {...props.flowContext};
  flow.action_finish_viral = action;
  props.updateCurrentFlowContext(flow);
};
    const updateFailedAction = (action: ActionContext) => {
      const flow = {...props.flowContext};
      flow.action_send_failed_context = action;
      props.updateCurrentFlowContext(flow);
    };
    const updateDefaultAction = (action: ActionContext) => {
      const flow = {...props.flowContext};
      flow.action_default_context = action;
      props.updateCurrentFlowContext(flow);
    };
    const updateSubmitAction = (action: ActionContext) => {
      formState.action_submit = action;
      onSubmit()
    };
    const updateSubmitActionGift = (action: ActionContext) => {
      // @ts-ignore
      formState.gifts[giftIndex.value].flow_action_context =action
      onSubmit()
    };
    const showImageSingleSelectionModal = (param: string) => {
      imageSelectionRef.value.showModal(param + "");
    };
    const showImageSingleSelectionModal2 = (param: string) => {
      imageSelectionRef2.value.showModal(param + "");
    };

    const deleteCurrentFlowContext = () => {
      props.deleteFlowContext(props.flowContext.id);
    };

    const onSubmit = () => {
       console.log(formState.gifts)
      validate().then(() => {
        const flow = {...props.flowContext} as FlowContext;
        flow.message_contexts = messageContexts.value;
        if (!flow.flow_viral){
          flow.flow_viral=[{}]
        }
        flow.name=viralName.value
        flow.messenger_tag=messageTag.value
        flow.flow_viral[0].type=formState.type
        flow.flow_viral[0].title=formState.title
        flow.flow_viral[0].button_title=formState.button_title
        flow.flow_viral[0].num_play=formState.num_play
        flow.flow_viral[0].invite_get_num_play=formState.invite_get_num_play
        flow.flow_viral[0].description=formState.description
        flow.flow_viral[0].image_url=formState.image_url
        flow.flow_viral[0].gifts=formState.gifts
        flow.flow_viral[0].action_submit=formState.action_submit
        flow.flow_viral[1] = formState2
        flow.viral_must_share = formState.viral_must_share
        if (logoUrl.value){
          flow.attachment_images=[logoUrl.value]
        }
        props.updateCurrentFlowContext(flow);
      }).catch((error: ValidateErrorEntity<FormFlowInformation>) => {
        console.log("error", error);
      });
    };
    updateProps();
onMounted(() => {
  console.log(formState)
})


    return {
      formState,
      formRef,
      onSubmit,
      onTextChange,
      messengerTags,
      updateSuccessAction,
      updateFailedAction,
      updateDefaultAction,
      deleteCurrentFlowContext,
      messageContexts,
      showImageSingleSelectionModal,
      showImageSingleSelectionModal2,
      validateInfos,
      attachFile,
      attachFile2,
      imageSelectionRef,
      imageSelectionRef2,
      messageTag,
      viralTypes, onTypeChange,
      addMoreGift,
      deleteGift,
      viralName,
      templateMessageFullName,
      subtitle,
      forcusMessagetext,
      onImageSelect,
      deleteGiftImage,
      updateSubmitAction,
      logoUrl,
      logoUrl2,
      showCreateGift,
      showModalCreateGift,
      giftData,
      giftIndex,
      handleOk,
      updateSubmitActionGift,
      formState2,
      forcusMessagetext2,
      onImageSelect2,
      messageFullName,
      inputMessageContent,
      updateSuccessActionFinishViral
    };
  },
});
</script>

<style lang="scss" scoped>
.main-flow {
  position: relative;
  padding: 40px 14% 60px;
  text-align: left;

  .icon-brand-sticker {
    position: absolute;
    font-size: 28px;
    top: 0;
    margin-right: 25px;
    right: 0;
  }

  .row-button-action {
    margin-top: 20px;
  }

  .card-message {
    border-radius: 10px;
    width: 49%;
  }
}

@media only screen and (min-width: 768px) {
  /* For desktop: */
  .card-lucky-wheel-control {
    display: grid;
    gap: 10px;
    width: 100%;
    grid-template-columns: auto auto;
  }
  .card-card-lucky-wheel-main {
    width: 260px;
  }
}

@media only screen and (max-width: 480px) and (min-width: 320px) {
  .card-lucky-wheel-control {
  }
}

.contain-card-lucky-wheel {
  width: 100%;
}

.post-back-button {
  margin-top: 18px;
  text-align: center;
}

.add-more-gift {
  text-align: center;
  margin-top: 20px;
}

.button-post-back {
  margin: 5px 10px;
}

.logo-url{
   cursor: pointer;

}

.contain-card-message {
  display: flex;
}


.slick-dot-facebook-template {
  display: inline-block;
  cursor: pointer;
  margin: 7px;

  .slick-dot-facebook-template-content {
    padding: 2px;
  }
}

.contain-lucky-wheel-logo {
  display: flex;

  .container-viral-settings {
    flex: 1;
    padding-left: 12px;
    display: flex;
  }

  .lucky-wheel-logo {
    width: 72px;
    height: 72px;
  }
}


.card-facebook-template {
  width: 95%;
}

.card-facebook-template-active {
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.img-cover-facebook-template {
  width: 100%;
  height: 200px;
  object-fit: cover;
}

.upload-center {
  display: flex;
  justify-content: center;
  height: 200px;
  align-items: center;
}


.attach-file-container {
  margin-top: 18px;
}

.photo-flow-img {
  max-height: 200px;
  object-fit: cover;
  cursor: pointer;
}

.icon-img-svg {
  width: 28px;
  height: 28px;
}

.button-quick-reply {
  border-radius: 15px;
}
</style>
<style>
.carousel__prev--in-active {
  display: none;
}

.carousel__next--in-active {
  display: none;
}
</style>
