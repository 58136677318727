<template>
    <div class="container-title">
      <h3 class="title-header">{{$t($route.name)}}</h3>
      <div>
        <a-button style="margin-right: 20px" @click="Back">{{$t('BACK')}}</a-button>
        <a-button type="primary">
          <router-link
              v-if="!employee.is_owner"
              :to="{
                name: 'EMPLOYEEEDIT',
                params: { userid: employee.cms_user_id },
              }"
              style="color: white"
          >
            {{$t('EDIT')}}
          </router-link>
        </a-button>
      </div>
    </div>
    <a-card style="
        margin: 16px;
      ">
      <template #title>
        <UserOutlined  style="margin-left: 10px" />
        <a-typography-text strong style="margin-left: 10px">{{employee.name}}</a-typography-text>
      </template>


      <a-form  :labelCol="{ span: 4 }">
        <a-form-item label="ID">
          <a-input disabled :value="employee.cms_user_id" style="color: #000" />
        </a-form-item>
        <a-form-item :label="$t('NAME')">
          <a-input disabled :value="employee.name" style="color: #000" />
        </a-form-item>
        <a-form-item :label="'Email'">
          <a-input disabled :value="employee.email" style="color: #000" />
        </a-form-item>
        <a-form-item :label="$t('ROLE')">
          <a-input disabled :value="getRole(employee)" style="color: #000" />
        </a-form-item>
        <a-form-item :label="$t('DEPARTMENT')">
          <a-input disabled :value="employee.department_name" style="color: #000" />
        </a-form-item>
        <a-form-item :label="$t('MANAGER')">
          <a-select
              disabled
              :placeholder="$t('MANAGER')"
              :options="employees"
              v-model:value="employee.manager_id"
          >
          </a-select>
        </a-form-item>
      </a-form>
    </a-card>
</template>

<script>
import {useStore} from '@/store'
import { computed, ref } from 'vue'
import {useRoute} from 'vue-router'
import{UserOutlined} from '@ant-design/icons-vue'
import {useI18n} from "vue-i18n";
import APIs from "@/APIs/APIs";

export default {
  components:{
    UserOutlined
  },
  setup(){
    const store = useStore()
    const router = useRoute()

    const roles = ref([

    ]);
    const i18n = useI18n();
    const fetchRole = async  () => {
      const res = await APIs.init().getRole()
      if (res.status=== 200){
        roles.value = res.data?.data?res.data?.data:[]
      }
    }
    fetchRole()

    const Back = () =>{
      history.back()
    }
    const employee = computed(() =>{
      const user = store.getters.getEmployees.data?.find(
          (item) => item.cms_user_id === Number(router.params.userid)
      )
      return user?user:{}
    });

    const getRole = (employee)=>{
      if(employee.is_owner){
        return i18n.t('SHOP_OWNER')
      }else{
        const role = roles.value.find(r=>r.id === employee.role_id)
        return role?role.name:''
      }
    };
    const employees = computed(() =>store.getters.getEmployees.data.map(e=>({...e, ...{label: e.name, value: e.cms_user_id}})));


    return {
      employee,
      employees,
      Back,
      getRole
    }
  }
}
</script>

<style>

</style>
