import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "container-title" }
const _hoisted_2 = { class: "title-header" }
const _hoisted_3 = { style: {"height":"80vh"} }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_plus_outlined = _resolveComponent("plus-outlined")!
  const _component_a_button = _resolveComponent("a-button")!
  const _component_Controls = _resolveComponent("Controls")!
  const _component_VueFlow = _resolveComponent("VueFlow")!
  const _component_a_input = _resolveComponent("a-input")!
  const _component_a_form_item = _resolveComponent("a-form-item")!
  const _component_a_select = _resolveComponent("a-select")!
  const _component_a_popconfirm = _resolveComponent("a-popconfirm")!
  const _component_a_form = _resolveComponent("a-form")!
  const _component_a_modal = _resolveComponent("a-modal")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("h3", _hoisted_2, _toDisplayString(_ctx.$t(_ctx.$route.name)), 1),
      (_ctx.getMyRole?.is_create)
        ? (_openBlock(), _createBlock(_component_a_button, {
            key: 0,
            type: "primary",
            class: "button-add-product",
            onClick: _ctx.create
          }, {
            default: _withCtx(() => [
              _createVNode(_component_plus_outlined),
              _createTextVNode(" " + _toDisplayString(_ctx.$t('ADD')), 1)
            ]),
            _: 1
          }, 8, ["onClick"]))
        : _createCommentVNode("", true)
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createVNode(_component_VueFlow, {
        modelValue: _ctx.elements,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.elements) = $event))
      }, {
        default: _withCtx(() => [
          _createVNode(_component_Controls)
        ]),
        _: 1
      }, 8, ["modelValue"])
    ]),
    _createVNode(_component_a_modal, {
      visible: _ctx.modalVisible,
      "onUpdate:visible": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.modalVisible) = $event)),
      title: _ctx.formState.id?_ctx.$t('UPDATE_DEPARTMENT'):_ctx.$t('ADD_DEPARTMENT'),
      footer: null,
      style: {"width":"40%"}
    }, {
      default: _withCtx(() => [
        _createVNode(_component_a_form, {
          ref: "formRef",
          model: _ctx.formState,
          rules: _ctx.rules,
          "label-col": _ctx.labelCol,
          "wrapper-col": _ctx.wrapperCol
        }, {
          default: _withCtx(() => [
            _createVNode(_component_a_form_item, {
              label: _ctx.$t('NAME'),
              name: "name"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_a_input, {
                  value: _ctx.formState.name,
                  "onUpdate:value": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.formState.name) = $event))
                }, null, 8, ["value"])
              ]),
              _: 1
            }, 8, ["label"]),
            _createVNode(_component_a_form_item, {
              label: _ctx.$t('DESCRIPTION'),
              name: "description"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_a_input, {
                  value: _ctx.formState.description,
                  "onUpdate:value": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.formState.description) = $event))
                }, null, 8, ["value"])
              ]),
              _: 1
            }, 8, ["label"]),
            _createVNode(_component_a_form_item, {
              label: _ctx.$t('DEPARTMENT'),
              name: "department"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_a_select, {
                  placeholder: _ctx.$t('DEPARTMENT'),
                  options: _ctx.departments,
                  value: _ctx.formState.parent_id,
                  "onUpdate:value": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.formState.parent_id) = $event)),
                  onChange: _ctx.handleChange
                }, null, 8, ["placeholder", "options", "value", "onChange"])
              ]),
              _: 1
            }, 8, ["label"]),
            _createVNode(_component_a_form_item, { "wrapper-col": { offset: 8 } }, {
              default: _withCtx(() => [
                _createVNode(_component_a_button, {
                  type: "primary",
                  onClick: _ctx.onSubmit
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.formState.id?_ctx.$t('UPDATE'):_ctx.$t('CREATE')), 1)
                  ]),
                  _: 1
                }, 8, ["onClick"]),
                (_ctx.getMyRole?.is_delete && _ctx.formState.id)
                  ? (_openBlock(), _createBlock(_component_a_popconfirm, {
                      key: 0,
                      placement: "bottom",
                      "ok-text": _ctx.$t('OK'),
                      "cancel-text": _ctx.$t('CANCEL'),
                      onConfirm: _ctx.confirmDelete
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_a_button, {
                          danger: "",
                          style: {"margin-left":"10px"}
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_ctx.$t('DELETE')), 1)
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    }, 8, ["ok-text", "cancel-text", "onConfirm"]))
                  : _createCommentVNode("", true)
              ]),
              _: 1
            })
          ]),
          _: 1
        }, 8, ["model", "rules", "label-col", "wrapper-col"])
      ]),
      _: 1
    }, 8, ["visible", "title"])
  ], 64))
}