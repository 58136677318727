import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import Home from "../views/Home.vue";
import Login from "@/views/Auth/Login.vue";
import RouterEndpoint from "@/router/RouterEndpoint";
import Customers from "@/views/CustomerManagment/Customers.vue";
import Triggers from "@/views/Marketing/Trigger/Triggers.vue";
import TriggerNew from "@/views/Marketing/Trigger/TriggersNew.vue";
import TriggerHistory from "@/views/Marketing/Trigger/TriggerHistory.vue";
import PageNotFound404 from "@/views/Result/PageNotFound404.vue";
import Flows from "@/views/Marketing/Flow/Flows.vue";
import Flow from "@/views/Marketing/Flow/Index.vue";
import FlowContextComponent from "@/views/Marketing/Flow/FlowContextComponent.vue";
import IntegrationPlatforms from "@/views/IntegrationPlatform/IntegrationPlatforms.vue";
import Tags from "@/views/Tags/Tags.vue";
import Locales from "@/views/Locales/Locales.vue";
import CustomerCares from "@/views/CustomerCares/CustomerCares.vue";
import Virals from "@/views/Marketing/viral/Virals.vue";
import CMS from "@/views/CMS/CMS.vue";
import Campaign from "@/views/report/Campaign/Campaign.vue"
import Advertisement from "@/views/report/Advertisement/Advertisement.vue"
import ReportInteraction from "@/views/report/Interaction/Interaction.vue"
import ReportSale from "@/views/report/Sale/Sale.vue"
import Revenue from "@/views/report/Revenue/Revenue.vue"
import CustomersByOrder from "@/views/report/CustomersByOrder/CustomersByOrder.vue"
import EmployeeReport from "@/views/report/Employee/Employee.vue"
import OrderReport from "@/views/report/Order/Order.vue"
import ScanPage from "@/views/ScanPage/ScanPage.vue"
import Vourchers from "@/views/Vourcher/Vourchers.vue";
import RFM from "@/views/report/RFM/RFM.vue"
// @ts-ignore
import LoginFacebook from "@/views/Auth/LoginFacebook"
import   AddTicket        from "@/views/ticket/AddTicket.vue"
import   Ticket        from "@/views/ticket/TicketTransaction.vue"
import   ListTicket        from "@/views/ticket/ListTicket.vue"

import   ListTicketType        from "@/views/ticket/ListTicketType.vue"
import   ListEvent        from "@/views/ticket/ListEvent.vue"
import    Template       from "@/views/ticket/Templates.vue"
import     Integrated    from "@/views/Integrated/Integrated.vue"
import UpsertViral from "@/views/Marketing/viral/UpsertViral.vue";
import AutoInbox from "@/views/Marketing/AutoInbox/AutoInbox.vue";
import AutoInboxEdit from "@/views/Marketing/AutoInbox/Edit.vue";
import Overview from "@/views/Overview/Overview.vue";
import LoginOutsite from "@/views/LoginOutsite/LoginOutsite.vue"
import Orders from "@/views/Orders/Orders.vue";
import Order from "@/views/Orders/Order.vue";
import OrderEdit from "@/views/Orders/OrderEdit.vue";
import WalletTransaction from "@/views/Wallet/WalletTransaction.vue";
import Products from "@/views/Product/Products.vue";
import Product from "@/views/Product/Product.vue";
import ZNSTransaction from "@/views/report/ZNS_Transaction/ZNSTransaction.vue";
import Stocks from "@/views/Stock/Stocks.vue";
import Stock from "@/views/Stock/Stock.vue";
import ServicePack from "@/views/ServicePack/ServicePack.vue";
import Transporter from "@/views/Transporter/Transporter.vue";
import Categories from "@/views/Category/Categories.vue";
import Providers from "@/views/Providers/Providers.vue";
import Careers from "@/views/Careers/Careers.vue";
import Category from "@/views/Category/Category.vue";
import Shopinfo from "@/views/ShopInfo/ShopInfo.vue";
import Employee from "@/views/Employee/Employee.vue";
import Dashboard from "@/views/Dashboard/Dashboard.vue";
import AccountLogin from "@/views/Auth/AccountLogin.vue";
import ProductPreview from "@/views/Product/Preview.vue";
import OrderPreview from "@/views/Orders/Preview.vue";
import  PreviewOrder from "@/views/Orders/PreviewOrder.vue"
import Departments from "@/views/Departments/Departments.vue";
import DepartmentsFlow from "@/views/Departments/DepartmentsFlow.vue";
import ReportOverview from "@/views/report/OverView/OverView.vue";
import ReportPage from "@/views/report/ReportPage/Page.vue";
import ReportCustomer from "@/views/report/Customer/Customer.vue";
import Role from "@/views/Role/Role.vue";
import Payment from "@/views/Payment/Payment.vue";
import MailEditor from "@/views/MailEditor/MailEditor.vue";
import AutoInboxDownload from "@/views/AutoInbox/AutoInbox.vue";
import LandingPage from "@/views/test/LandingPage.vue";
import ThermalLayout from "@/views/Orders/ThermalLayout.vue";
import TicketReport from "@/views/ticket/Report.vue";

// @ts-ignore
import Staff from "@/views/report/Staff/Staff.vue";
import Customer from "@/views/CustomerManagment/Customer.vue";
import EmployeeEdit from "@/views/Employee/EmployeeEdit.vue";
import EmployeeAdd from "@/views/Employee/EmployeeAdd.vue";
import EmployeeDetail from "@/views/Employee/EmployeeDetail.vue";
import Scheduler from "@/views/CustomerManagment/Scheduler.vue";
import SettingPage from "@/views/SettingPage/SettingPages.vue";

import HelpCenter from "@/views/HelpCenter/HelpCenter.vue";

import ViralHistory from "@/views/Marketing/Trigger/ViralHistory.vue";
import FlowDetail from "@/views/Marketing/Automation/FlowDetail.vue";
import CustomerDetail from "@/views/CustomerManagment/CustomerDetail.vue";


import CreateVoucher from "@/views/Voucher/CreateVoucher.vue";
import MemberRanks from "@/views/Loyalty/MemberRanks.vue";
import CreateMemberRank from "@/views/Loyalty/CreateMemberRank.vue";


import Account from "@/views/Account/Account.vue";


import EvoucherCodeList from "@/views/Voucher/EvoucherCodeList.vue";



import {store} from "@/store";
import {computed} from "vue";
import dayjs from "dayjs";

import ExtraColumn from "@/views/ExtraColumn/ExtraColumn.vue";
import Vouchers from "@/views/Voucher/Vouchers.vue";
// import CustomerConfig from "@/views/CustomerConfig/CustomerConfig.vue";



const routes: Array<RouteRecordRaw> = [
  {
    path: RouterEndpoint.HOME.path,
    name: RouterEndpoint.HOME.name,
    component: Home,
  },
  {
    path: RouterEndpoint.AUTOMATION_TRIGGER_DETAIL.path,
    name: RouterEndpoint.AUTOMATION_TRIGGER_DETAIL.name,
    component: FlowDetail,
  },
  {
    path: RouterEndpoint.SCAN_PAGE.path,
    name: RouterEndpoint.SCAN_PAGE.name,
    component: ScanPage,
  },
  {
    path: RouterEndpoint.RFM.path,
    name: RouterEndpoint.RFM.name,
    component: RFM,
  },
  {
    path: RouterEndpoint.CMS.path,
    name: RouterEndpoint.CMS.name,
    component: CMS,
  },
  {
    path: RouterEndpoint.AUTOMATION_TRIGGER.path,
    name: RouterEndpoint.AUTOMATION_TRIGGER.name,
    component: TriggerNew,
  },
  {
    path: "",
    redirect: RouterEndpoint.OVERVIEW.path,
  },
  {
    path: RouterEndpoint.CUSTOMER_MANAGEMENT.path,
    name: RouterEndpoint.CUSTOMER_MANAGEMENT.name,
    component: Customers,
  },
  {
    path: RouterEndpoint.FLOWS.path,
    name: RouterEndpoint.FLOWS.name,
    component: Flows,
  },
  {
    path: RouterEndpoint.ACCOUNT.path,
    name: RouterEndpoint.ACCOUNT.name,
    component: Account,
  },
  {
    path: RouterEndpoint.FLOW.path,
    name: RouterEndpoint.FLOW.name,
    component: Flow,
    meta:{layout: 'blank-layout'}
  },
  {
    path: RouterEndpoint.FLOW_CONTEXT.path,
    name: RouterEndpoint.FLOW_CONTEXT.name,
    component: FlowContextComponent,
  },
  {
    path: RouterEndpoint.TRIGGERS.path,
    name: RouterEndpoint.TRIGGERS.name,
    component: Triggers,
  },
  {
    path: RouterEndpoint.TRIGGER_HISTORY.path,
    name: RouterEndpoint.TRIGGER_HISTORY.name,
    component: TriggerHistory,
  },
  {
    path: RouterEndpoint.VIRAL_HISTORY.path,
    name: RouterEndpoint.VIRAL_HISTORY.name,
    component: ViralHistory,
  },
  {
    path: RouterEndpoint.TRIGGER_TAGS.path,
    name: RouterEndpoint.TRIGGER_TAGS.name,
    component: Tags,
  },
  {
    path: RouterEndpoint.TAGS.path,
    name: RouterEndpoint.TAGS.name,
    component: Tags,
  },
  {
    path: RouterEndpoint.VIRAL_TAGS.path,
    name: RouterEndpoint.VIRAL_TAGS.name,
    component: Tags,
  },
  {
    path: RouterEndpoint.CUSTOMER_TAGS.path,
    name: RouterEndpoint.CUSTOMER_TAGS.name,
    component: Tags,
  },
  {
    path: RouterEndpoint.LOGIN_OUTSITE.path,
    name: RouterEndpoint.LOGIN_OUTSITE.name,
    component: LoginOutsite,
  },
  {
    path: RouterEndpoint.FLOW_TAGS.path,
    name: RouterEndpoint.FLOW_TAGS.name,
    component: Tags,
  },
  {
    path: RouterEndpoint.PRODUCT_TAGS.path,
    name: RouterEndpoint.PRODUCT_TAGS.name,
    component: Tags,
  },
  {
    path: RouterEndpoint.VIRAL_LIST.path,
    name: RouterEndpoint.VIRAL_LIST.name,
    component: Virals,
  },
  {
    path: RouterEndpoint.VOUCHER.path,
    name: RouterEndpoint.VOUCHER.name,
    component: Vouchers,
  },
  {
    path: RouterEndpoint.LOGIN_FACEBOOK.path,
    name: RouterEndpoint.LOGIN_FACEBOOK.name,
    meta: {
      layout: "blank-layout",
    },
    component: LoginFacebook,
  },
  {
    path: RouterEndpoint.EVOUCHER_CODE_LIST.path,
    name: RouterEndpoint.EVOUCHER_CODE_LIST.name,
    component: EvoucherCodeList,
  },
  {
    path: RouterEndpoint.MEMBER_RANK.path,
    name: RouterEndpoint.MEMBER_RANK.name,
    component: MemberRanks,
  },
  {
    path: RouterEndpoint.CREATE_VOUCHER.path,
    name: RouterEndpoint.CREATE_VOUCHER.name,
    component: CreateVoucher,
  },
  {
    path: RouterEndpoint.CREATE_MEMBER_RANK.path,
    name: RouterEndpoint.CREATE_MEMBER_RANK.name,
    component: CreateMemberRank,
  },
   {
    path: RouterEndpoint.ADD_TICKET.path,
    name: RouterEndpoint.ADD_TICKET.name,
    component: AddTicket ,
  },

  {
    path: RouterEndpoint.LIST_TICKET.path,
    name: RouterEndpoint.LIST_TICKET.name,
    component:ListTicket  ,
  },
  {
    path: RouterEndpoint.LIST_TICKET_TYPE.path,
    name: RouterEndpoint.LIST_TICKET_TYPE.name,
    component:ListTicketType  ,
  },
  {
    path: RouterEndpoint.LIST_TEMPLATE.path,
    name: RouterEndpoint.LIST_TEMPLATE.name,
    component:Template  ,
  },
  {
    path: RouterEndpoint.LIST_EVENT.path,
    name: RouterEndpoint.LIST_EVENT.name,
    component: ListEvent ,
  },
  {
    path: RouterEndpoint.TICKET.path,
    name: RouterEndpoint.TICKET.name,
    component: Ticket ,
  },
  // {
  //   path: RouterEndpoint.CONFIG_CUSTOMER.path,
  //   name: RouterEndpoint.CONFIG_CUSTOMER.name,
  //   component: CustomerConfig,
  // },

  {
    path: RouterEndpoint.UPDATE_VIRAL.path,
    name: RouterEndpoint.UPDATE_VIRAL.name,
    component: UpsertViral,
  },
  {
    path: RouterEndpoint.HELP_CENTER.path,
    name: RouterEndpoint.HELP_CENTER.name,
    component: HelpCenter,
  },
  {
    path: RouterEndpoint.INTEGRATION_PLATFORMS.path,
    name: RouterEndpoint.INTEGRATION_PLATFORMS.name,
    component: IntegrationPlatforms,
  },
  {
    path: RouterEndpoint.LOCALES.path,
    name: RouterEndpoint.LOCALES.name,
    component: Locales,
  },
  {
    path: RouterEndpoint.OVERVIEW.path,
    name: RouterEndpoint.OVERVIEW.name,
    component: Overview,
  },
  {
    path: RouterEndpoint.INTEGRATED.path,
    name: RouterEndpoint.INTEGRATED.name,
    component: Integrated,
  },
  {
    path: RouterEndpoint.ORDERS.path,
    name: RouterEndpoint.ORDERS.name,
    component: Orders,
  },
  {
    path: RouterEndpoint.EXTRA_COLUMN.path,
    name: RouterEndpoint.EXTRA_COLUMN.name,
    component:ExtraColumn,
  },
  {
    path: RouterEndpoint.REPORT_ZNS_TRANSACTION.path,
    name: RouterEndpoint.REPORT_ZNS_TRANSACTION.name,
    component: ZNSTransaction,
  },
  {
    path: RouterEndpoint.ORDER.path,
    name: RouterEndpoint.ORDER.name,
    component: Order,
  },
  {
    path: RouterEndpoint.EDIT_ORDER.path,
    name: RouterEndpoint.EDIT_ORDER.name,
    component: OrderEdit,
  },
  {
    path: RouterEndpoint.CUSTOMER_CARES.path,
    name: RouterEndpoint.CUSTOMER_CARES.name,
    component: CustomerCares,
  },
  {
    path: RouterEndpoint.CUSTOMER_CARES.path,
    name: RouterEndpoint.CUSTOMER_CARES.name,
    component: CustomerCares,
  },
  {
    path: RouterEndpoint.AUTO_INBOX.path,
    name: RouterEndpoint.AUTO_INBOX.name,
    component: AutoInbox,
  },
  {
    path: RouterEndpoint.AUTO_INBOX_EDiT.path,
    name: RouterEndpoint.AUTO_INBOX_EDiT.name,
    component: AutoInboxEdit,
  },
  {
    path: RouterEndpoint.WALLET_TRANSACTION.path,
    name: RouterEndpoint.WALLET_TRANSACTION.name,
    component: WalletTransaction,
  },
  {
    path: RouterEndpoint.PRODUCTS.path,
    name: RouterEndpoint.PRODUCTS.name,
    component: Products,
  },
  {
    path: RouterEndpoint.PRODUCT.path,
    name: RouterEndpoint.PRODUCT.name,
    component: Product,
  },
  {
    path: RouterEndpoint.CREATE_PRODUCT.path,
    name: RouterEndpoint.CREATE_PRODUCT.name,
    component: Product,
  },
  {
    path: RouterEndpoint.STOCKS.path,
    name: RouterEndpoint.STOCKS.name,
    component: Stocks,
  },
  {
    path: RouterEndpoint.STOCK.path,
    name: RouterEndpoint.STOCK.name,
    component: Stock,
  },
  {
    path: RouterEndpoint.SERVICE_PACK.path,
    name: RouterEndpoint.SERVICE_PACK.name,
    component: ServicePack,
  },
  {
    path: RouterEndpoint.TRANSPORTER.path,
    name: RouterEndpoint.TRANSPORTER.name,
    component: Transporter,
  },
  {
    path: RouterEndpoint.CATEGORIES.path,
    name: RouterEndpoint.CATEGORIES.name,
    component: Categories,
  },
  {
    path: RouterEndpoint.CATEGORY.path,
    name: RouterEndpoint.CATEGORY.name,
    component: Category,
  },
  {
    path: RouterEndpoint.PROVIDERS.path,
    name: RouterEndpoint.PROVIDERS.name,
    component: Providers,
  },
  {
    path: RouterEndpoint.SHOPINFO.path,
    name: RouterEndpoint.SHOPINFO.name,
    component: Shopinfo,
  },
  {
    path: RouterEndpoint.EMPLOYEE.path,
    name: RouterEndpoint.EMPLOYEE.name,
    component: Employee,
  },
  {
    path: RouterEndpoint.EMPLOYEEDETAIL.path,
    name: RouterEndpoint.EMPLOYEEDETAIL.name,
    component: EmployeeDetail,
  },
  {
    path: RouterEndpoint.EMPLOYEEEDIT.path,
    name: RouterEndpoint.EMPLOYEEEDIT.name,
    component: EmployeeEdit,
  },
  {
    path: RouterEndpoint.DASHBOARD.path,
    name: RouterEndpoint.DASHBOARD.name,
    component: Dashboard,
  },
  {
    path: RouterEndpoint.CUSTOMER_DETAIL_EDIT.path,
    name: RouterEndpoint.CUSTOMER_DETAIL_EDIT.name,
    component: Customer,
  },
  {
    path: RouterEndpoint.CUSTOMER_EDIT_DETAIL.path,
    name: RouterEndpoint.CUSTOMER_EDIT_DETAIL.name,
    component: CustomerDetail,
  },
  {
    path: RouterEndpoint.REPORT_OVERVIEW.path,
    name: RouterEndpoint.REPORT_OVERVIEW.name,
    component: ReportOverview,
  },
  {
    path: RouterEndpoint.CUSTOMER_BY_ORDER.path,
    name: RouterEndpoint.CUSTOMER_BY_ORDER.name,
    component: CustomersByOrder,
  },
  {
    path: RouterEndpoint.REPORT_SALE.path,
    name: RouterEndpoint.REPORT_SALE.name,
    component: ReportSale,
  },
  {
    path: RouterEndpoint.REVENUE.path,
    name: RouterEndpoint.REVENUE.name,
    component: Revenue,
  },
  {
    path: RouterEndpoint.REPORT_EMPLOYEE.path,
    name: RouterEndpoint.REPORT_EMPLOYEE.name,
    component: EmployeeReport,
  },
  {
    path: RouterEndpoint.REPORT_ORDER.path,
    name: RouterEndpoint.REPORT_ORDER.name,
    component: OrderReport,
  },
  {
    path: RouterEndpoint.REPORT_PAGE.path,
    name: RouterEndpoint.REPORT_PAGE.name,
    component: ReportPage,
  },
  {
    path: RouterEndpoint.REPORT_STAFF.path,
    name: RouterEndpoint.REPORT_STAFF.name,
    component: Staff,
  },
  {
    path: RouterEndpoint.REPORT_CAMPAIGN.path,
    name: RouterEndpoint.REPORT_CAMPAIGN.name,
    component: Campaign,
  },
  {
    path: RouterEndpoint.REPORT_ADVERTISEMENT.path,
    name: RouterEndpoint.REPORT_ADVERTISEMENT.name,
    component: Advertisement,
  },
  {
    path: RouterEndpoint.REPORT_INTERACTION.path,
    name: RouterEndpoint.REPORT_INTERACTION.name,
    component: ReportInteraction,
  },
  {
    path: RouterEndpoint.REPORT_CUSTOMER.path,
    name: RouterEndpoint.REPORT_CUSTOMER.name,
    component: ReportCustomer,
  },
  {
    path: RouterEndpoint.CUSTOMER_DETAIL_EDIT.path,
    name: RouterEndpoint.CUSTOMER_DETAIL_EDIT.name,
    component: Customer,
  },
  {
    path: RouterEndpoint.CUSTOMER_SCHEDULER.path,
    name: RouterEndpoint.CUSTOMER_SCHEDULER.name,
    component: Scheduler,
  },
  {
    path: RouterEndpoint.SETTING_PAGE.path,
    name: RouterEndpoint.SETTING_PAGE.name,
    component: SettingPage,
  },
  {
    path: RouterEndpoint.EMPLOYEE_ADD.path,
    name: RouterEndpoint.EMPLOYEE_ADD.name,
    component: EmployeeAdd,
  },
  {
    path: RouterEndpoint.DEPARTMENT.path,
    name: RouterEndpoint.DEPARTMENT.name,
    component: DepartmentsFlow,
  },
  {
    path: RouterEndpoint.CUSTOMER_CAREER.path,
    name: RouterEndpoint.CUSTOMER_CAREER.name,
    component: Careers,
  },
  {
    path: RouterEndpoint.ROLE.path,
    name: RouterEndpoint.ROLE.name,
    component: Role,
  },
  {
    path: RouterEndpoint.MAIL_EDITOR.path,
    name: RouterEndpoint.MAIL_EDITOR.name,
    component: MailEditor,
  },
  {
    path: RouterEndpoint.TICKET_REPORT.path,
    name: RouterEndpoint.TICKET_REPORT.name,
    component: TicketReport,
  },
  {
    path: RouterEndpoint.AUTO_INBOX_DOWNLOAD.path,
    name: RouterEndpoint.AUTO_INBOX_DOWNLOAD.name,
    component: AutoInboxDownload,
  },
  {
    path: RouterEndpoint.TEST_VIEW.path,
    name: RouterEndpoint.TEST_VIEW.name,
    component: () =>
        import("../views/Marketing/test/Drag.vue"),
  },
  {
    path: "/about",
    name: "About",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/About.vue"),
  },
  {
    path: RouterEndpoint.LOGIN.path,
    name: RouterEndpoint.LOGIN.name,
    meta: {
      layout: "blank-layout",
    },
    component: Login,
  },
  {
    path: RouterEndpoint.LANDING_PAGE.path,
    name: RouterEndpoint.LANDING_PAGE.name,
    meta: {
      layout: "blank-layout",
    },
    component: LandingPage,
  },
  {
    path: RouterEndpoint.ACCOUNTLOGIN.path,
    name: RouterEndpoint.ACCOUNTLOGIN.name,
    meta: {
      layout: "blank-layout",
    },
    component: AccountLogin,
  },
  {
    path: RouterEndpoint.PRODUCT_PREVIEW.path,
    name: RouterEndpoint.PRODUCT_PREVIEW.name,
    component: ProductPreview,
    meta: {
      layout: "blank-layout",
    },
  },
  {
    path: RouterEndpoint.ORDER_PREVIEW.path,
    name: RouterEndpoint.ORDER_PREVIEW.name,
    component: OrderPreview,
    meta: {
      layout: "blank-layout",
    },
  },
  {
    path: RouterEndpoint.PREVIEW_ORDER.path,
    name: RouterEndpoint.PREVIEW_ORDER.name,
    component: PreviewOrder,
    meta: {
      layout: "blank-layout",
    },
  },
  {
    path: RouterEndpoint.PAYMENT.path,
    name: RouterEndpoint.PAYMENT.name,
    component: Payment,
    meta: {
      layout: "blank-layout",
    },
  },
  {
    path: RouterEndpoint.ORDER_THERMAL.path,
    name: RouterEndpoint.ORDER_THERMAL.name,
    component: ThermalLayout,
    meta: {
      layout: "blank-layout",
    },
  },

  {
    path: "/404",
    name: "404",
    component: PageNotFound404,
  },
  { path: "/:catchAll(.*)", redirect: "/404" },
];

const router = createRouter({
  history: createWebHistory(
    process.env.NODE_ENV === "production" ? "/crm" : "/"
  ),
  routes: routes,
});
//
// router.beforeEach(async (to, from, next) => {
//
//   const getShopInfo = computed(()=>store.getters.getShopinfo)
//
//   // next-line: check if route ("to" object) needs authenticated
//   if (to.matched.some((record) => record.meta.layout==="blank-layout") && (getShopInfo.value.shop_services.status == 'Kích hoạt' && dayjs(getShopInfo.value.shop_services.end_date).diff(dayjs(), 'seconds')>0)) {
//     next(RouterEndpoint.SERVICE_PACK.name);
//   } else next();
// });
//


export default router;
