<template>
  <div class="main-flow" id="main-flow">
    <a-form
        ref="formRef"
        :rules="rules"
        :model="formState"
        layout="vertical"
    >
      <a-form-item ref="name" :label="$t('FLOW_MESSAGE_NAME')" name="name">
        <a-input :placeholder="$t('FLOW_MESSAGE_NAME')" @blur="onTextChange" v-model:value="formState.name"/>
      </a-form-item>

      <Container
          orientation="vertical"
          group-name="col-items"
          :get-child-payload="getPayload1"
          :drop-placeholder="{
          className: `drop-placeholder`,
          animationDuration: '200',
          showOnTop: true,
        }"
          :non-drag-area-selector="'.drag-disabled'"
          drag-class="
            transition duration-100 ease-in z-50
            transform rotate-6 scale-110"
          drop-class="transition duration-100
            ease-in z-50 transform
            -rotate-2 scale-90"
          @drop="(e) => onCardDrop1(e)"
      >
        <Draggable
            v-for="(message,msgIndex) in messageContexts"
            :key="message.id"
        >
          <a-card class="card-message" v-if="message.type === 'text'">
            <template #title>
              <drag-outlined style="cursor: grab; margin-right: 15px"/>
              <span>{{ $t("MESSAGE_TEXT") }}</span>
            </template>
            <template #extra>
              <a-button type="text" @click="deleteMessage(message.id)">
                <close-outlined />
              </a-button>
            </template>
            <a-form-item name="message_content">
              <a-textarea :placeholder="$t('FLOW_FACEBOOK_BUTTON_POST_BACK_MESSAGE_CONTENT_PLACEHOLDER')"
                          class="drag-disabled"
                          ref="inputMessageCotent"
                          :auto-size="{ minRows: 2, maxRows: 8 }"
                          :draggable="false"
                          v-model:value="message.text" @blur="onTextChange" @click="forcusMessagetext"/>
              <a-tag @click="messageFullName(msgIndex,'content')" style="cursor: pointer">##full_name##</a-tag>
              <a-tag
                  @click="messagePageName(msgIndex, 'content')"
                  style="cursor: pointer;margin-top: 20px"
              >##page_name##
              </a-tag

              >
              <a-dropdown>
                <a-tag class="ant-dropdown-link" @click.prevent>
                  {{$t('READ_MORE')}}
                  <DownOutlined />
                </a-tag>
                <template #overlay>
                  <a-menu>
                    <a-menu-item @click="messageColumn(it.identified_name,msgIndex,'content')" v-for="it in getColumnsConfig" :key="it.id" >
                      {{it.name}}
                    </a-menu-item>
                    <a-menu-item  @click="messageColumn(i.id,msgIndex,'content')"  v-for="i in getColumn" :key="i.id" >
                      {{$t(i.text)}}
                    </a-menu-item>
                  </a-menu>
                </template>
              </a-dropdown>
              <a-dropdown>
                <a-tag class="ant-dropdown-link" @click.prevent>
                  {{$t('ORDER')}}
                  <DownOutlined />
                </a-tag>
                <template #overlay>
                  <a-menu>
                    <a-menu-item @click="messageColumn(i.id,msgIndex,'content')" v-for="i in getOrderColumn" :key="i.id" >
                      {{$t(i.text)}}
                    </a-menu-item>

                  </a-menu>
                </template>
              </a-dropdown>
            </a-form-item>
            <div class="post-back-button">
              <template v-if="message.buttons">
                <template v-for="(item,index) in message.buttons" :key="item?item.id:'1'+'_'+index">
                  <zalo-button
                      v-if="item"
                      :zaloButton="item"
                      :flowContextData="flowContextData"
                      :deletePostBackButton="()=>deleteButton(msgIndex,item.id)"
                      :updatePostBackButton="(data)=>updateButton(msgIndex,index,data)"/>
                </template>
              </template>
              <a-button type="dashed" class="button-post-back" v-if="message.buttons.length<5" @click="addButton(msgIndex)">
                <plus-outlined/>
                {{ $t("FLOW_ADD_BUTTONS") }}
              </a-button>
            </div>
          </a-card>
          <a-card class="card-message" v-else-if="message.type === 'text_attachment'">
            <template #title>
              <drag-outlined style="cursor: grab; margin-right: 15px"/>
              <span>Message {{ message.type }}</span>
            </template>
            <template #extra>
              <a-button type="text" @click="deleteMessage(message.id)">
                <close-outlined />
              </a-button>
            </template>
            <a-form-item>
            <span class="ant-upload-picture-card-wrapper">
               <div class="ant-upload-list ant-upload-list-picture-card" v-if="message.images&&message.images.length>0">
                <div class="ant-upload-list-picture-card-container">
                  <span>
                    <div
                        class="ant-upload-list-item ant-upload-list-item-done ant-upload-list-item-list-type-picture-card">
                      <div class="ant-upload-list-item-info">
                        <span>
                          <a class="ant-upload-list-item-thumbnail"
                             :href="message.images[0]"
                             target="_blank" rel="noopener noreferrer">
                            <img :src="message.images[0]"
                                 alt="image.png"
                                 class="ant-upload-list-item-image">
                          </a>
                        </span>
                      </div>
                      <span class="ant-upload-list-item-actions">
                        <a :href="message.images[0]"
                           target="_blank"
                           rel="noopener noreferrer" title="Preview file">
                          <eye-outlined/>
                        </a>
                        <upload-outlined @click="showImageSelectionModal(msgIndex)" title="Upload" class="anticon-delete"/>
                      </span>
                    </div>
                  </span>
                </div>
              </div>
              <div class="ant-upload ant-upload-select ant-upload-select-picture-card" v-else
                   @click="showImageSelectionModal(msgIndex)">
                  <span role="button" tabindex="0" class="ant-upload">
                    <div>
                      <plus-outlined/>
                      <div class="ant-upload-text">{{ $t('UPLOAD') }}</div>
                    </div>
                  </span>
              </div>
            </span>
            </a-form-item>
            <a-form-item name="message_content">
              <a-textarea :placeholder="$t('FLOW_FACEBOOK_BUTTON_POST_BACK_MESSAGE_CONTENT_PLACEHOLDER')"
                          class="drag-disabled"
                          ref="inputContent"
                          :auto-size="{ minRows: 2, maxRows: 8 }"
                          :draggable="false"
                          v-model:value="message.text" @blur="onTextChange"  @click="forcusMessagetext"/>
              <a-tag @click="messageFullName(msgIndex,'notContnet')" style="cursor: pointer">##full_name##</a-tag>
              <a-tag
                  @click="messagePageName(msgIndex, 'notContnet')"
                  style="cursor: pointer;margin-top: 20px"
              >##page_name##
              </a-tag

              >
              <a-dropdown>
                <a-tag class="ant-dropdown-link" @click.prevent>
                  {{$t('READ_MORE')}}
                  <DownOutlined />
                </a-tag>
                <template #overlay>
                  <a-menu>
                    <a-menu-item @click="messageColumn(it.identified_name,msgIndex,'notContnet')" v-for="it in getColumnsConfig" :key="it.id" >
                      {{it.name}}
                    </a-menu-item>
                    <a-menu-item  @click="messageColumn(i.id,msgIndex,'notContnet')"  v-for="i in getColumn" :key="i.id" >
                      {{$t(i.text)}}
                    </a-menu-item>
                  </a-menu>
                </template>
              </a-dropdown>
              <a-dropdown>
                <a-tag class="ant-dropdown-link" @click.prevent>
                  {{$t('ORDER')}}
                  <DownOutlined />
                </a-tag>
                <template #overlay>
                  <a-menu>
                    <a-menu-item @click="messageColumn(i.id,msgIndex,'notContnet')" v-for="i in getOrderColumn" :key="i.id" >
                      {{$t(i.text)}}
                    </a-menu-item>

                  </a-menu>
                </template>
              </a-dropdown>
            </a-form-item>
          </a-card>
          <a-card class="card-message" v-if="message.type === 'promotion'">
            <template #title>
              <drag-outlined style="cursor: grab; margin-right: 15px"/>
              <span>{{$t('PERSONAL_MEDIA_MESSAGES')}}</span>
            </template>
            <template #extra>
              <a-button type="text" @click="deleteMessage(message.id)">
                <close-outlined />
              </a-button>
            </template>
            <div class="zalo-element">
              <template v-if="message.elements&&message.elements.length">
                <a-list item-layout="horizontal" :data-source="message.elements">
                  <template #renderItem="{ item,index }">
                    <zalo-template-list
                        v-if="item"
                        :elementContexts="item"
                        :elementIndex="index"
                        :messageContext="messageContexts[msgIndex].elements"
                        :flowContextData="flowContextData"
                        :deleteElement="()=>deleteElement(msgIndex,item.id)"
                        :updateElement="(data)=>updateElement(msgIndex,index,data)"/>
                  </template>
                </a-list>
              </template> <div class="button-post-back-container">
              <a-button type="dashed" class="button-post-back" v-if="message.elements.length<5"
                        @click="addAnElement(msgIndex)">
                <plus-outlined/>
                {{ $t("ADD_ELEMENT") }}
              </a-button>
            </div>


            </div>
            <a-divider/>
            <div class="post-back-button">
              <template v-if="message.buttons">
                <template v-for="(item,index) in message.buttons" :key="item?item.id:'1'+'_'+index">
                  <zalo-button
                      v-if="item"
                      :zaloButton="item"
                      :flowContextData="flowContextData"
                      :deletePostBackButton="()=>deleteButton(msgIndex,item.id)"
                      :updatePostBackButton="(data)=>updateButton(msgIndex,index,data)"/>
                </template>
              </template>
              <a-button type="dashed" class="button-post-back" v-if="message.buttons.length<5" @click="addButton(msgIndex,'icon')">
                <plus-outlined/>
                {{ $t("FLOW_ADD_BUTTONS") }}
              </a-button>
            </div>
          </a-card>
          <a-card class="card-message" v-if="message.type !== 'promotion' && message.type !== 'request_user_info' && message.type !== 'text_attachment' && message.type !== 'text' ">
            <template #title>
              <drag-outlined style="cursor: grab; margin-right: 15px"/>
              <span>{{$t('TRANSACTION_MESSAGE')}}</span>
              <a-select
                  showSearch
                  allowClear
                  :value="message.type"
                  style="min-width: 200px; margin-left: 16px"
                  @change="(e)=>changeMessageType(e,msgIndex)"
              >
                <a-select-option
                    v-for="item in transactions"
                    :key="item"
                    v-mode:value="item"
                >
                    {{ $t(item) }}
                </a-select-option>
              </a-select>
            </template>
            <template #extra>
              <a-button type="text" @click="deleteMessage(message.id)">
                <close-outlined />
              </a-button>
            </template>
            <div class="zalo-element">
              <template v-if="message.elements&&message.elements.length">
                <a-list item-layout="horizontal" :data-source="message.elements">
                  <template #renderItem="{ item,index }">
                    <zalo-template-list
                        v-if="item"
                        :elementContexts="item"
                        :elementIndex="index"
                        :messageContext="messageContexts[msgIndex].elements"
                        :flowContextData="flowContextData"
                        :deleteElement="()=>deleteElement(msgIndex,item.id)"
                        :updateElement="(data)=>updateElement(msgIndex,index,data)"
                         :order="'order'"
                    />
                  </template>
                </a-list>
              </template> <div class="button-post-back-container">
              <a-button type="dashed" class="button-post-back" v-if="message.elements.length<5"
                        @click="addAnElement2(msgIndex)">
                <plus-outlined/>
                {{ $t("ADD_ELEMENT") }}
              </a-button>
            </div>


            </div>
            <a-divider/>
            <div class="post-back-button">
              <template v-if="message.buttons">
                <template v-for="(item,index) in message.buttons" :key="item?item.id:'1'+'_'+index">
                  <zalo-button
                      v-if="item"
                      :zaloButton="item"
                      :flowContextData="flowContextData"
                      :deletePostBackButton="()=>deleteButton(msgIndex,item.id)"
                      :updatePostBackButton="(data)=>updateButton(msgIndex,index,data)"/>
                </template>
              </template>
              <a-button type="dashed" class="button-post-back" v-if="message.buttons.length<5" @click="addButton(msgIndex,'icon')">
                <plus-outlined/>
                {{ $t("FLOW_ADD_BUTTONS") }}
              </a-button>
            </div>
          </a-card>
          <a-card class="card-message" v-if="message.type === 'request_user_info'">
            <template #title>
              <drag-outlined style="cursor: grab; margin-right: 15px"/>
              <span>{{$t('REQUEST_USER_INFO')}}</span>
            </template>
            <template #extra>
              <a-button type="text" @click="deleteMessage(message.id)">
                <close-outlined />
              </a-button>
            </template>
            <div class="zalo-element">
              <template v-if="message.elements&&message.elements.length">
                <a-card hoverable>
                  <template #cover>
                    <a-row style="margin: 10px" justify="center">
                      <div class="ant-upload-list ant-upload-list-picture-card" v-if="message.elements[0].image_url&&message.elements[0].image_url">
                        <div class="ant-upload-list-picture-card-container">
                  <span>
                    <div
                        class="ant-upload-list-item ant-upload-list-item-done ant-upload-list-item-list-type-picture-card">
                      <div class="ant-upload-list-item-info">
                        <span>
                          <a class="ant-upload-list-item-thumbnail"
                             :href="message.elements[0].image_url"
                             target="_blank" rel="noopener noreferrer">
                            <img style="width: 100%;height: 100%" :src="message.elements[0].image_url"
                                 alt="image.png"
                                 class="ant-upload-list-item-image">
                          </a>
                        </span>
                      </div>
                      <span class="ant-upload-list-item-actions">
                        <a :href="message.elements[0].image_url"
                           target="_blank"
                           rel="noopener noreferrer" title="Preview file">
                          <eye-outlined/>
                        </a>
                        <upload-outlined @click="showImageSelectionModal(msgIndex,'info')" title="Upload" class="anticon-delete"/>
                      </span>
                    </div>
                  </span>
                        </div>
                      </div>

                      <div v-else class="ant-upload ant-upload-select ant-upload-select-picture-card"
                           @click="showImageSelectionModal(msgIndex,'info')">
                  <span role="button" tabindex="0" class="ant-upload">
                    <div>
                      <plus-outlined/>
                      <div class="ant-upload-text">{{ $t('UPLOAD') }}</div>
                    </div>
                  </span>
                      </div>
                    </a-row>
                  </template>
                  <a-card-meta>
                    <template #title>
                      <a-input :placeholder="$t('FLOW_FACEBOOK_BUTTON_POST_BACK_MESSAGE_CONTENT_PLACEHOLDER')"
                               class="drag-disabled"
                               :auto-size="{ minRows: 2, maxRows: 8 }"
                               :draggable="false"
                               :maxlength="100"
                               showCount
                               v-model:value="message.elements[0].title" @blur="onTextChange"  @click="forcusMessagetext"/>
                    </template>
                    <template #description>
                      <a-textarea :placeholder="$t('FLOW_FACEBOOK_BUTTON_POST_BACK_MESSAGE_CONTENT_PLACEHOLDER')"
                                  class="drag-disabled"
                                  ref="inputRequest"
                                  :auto-size="{ minRows: 2, maxRows: 4 }"
                                  :draggable="false"
                                  showCount
                                  :maxlength="500"
                                  v-model:value="message.elements[0].subtitle" @blur="onTextChange"  @click="forcusMessagetext"/>
                      <a-tag @click="messageFullName(msgIndex,'request' )" style="cursor: pointer">##full_name##</a-tag>
                      <a-tag
                          @click="messagePageName(msgIndex, 'request')"
                          style="cursor: pointer;margin-top: 20px"
                      >##page_name##
                      </a-tag

                      >

                      <a-dropdown>
                        <a-tag class="ant-dropdown-link" @click.prevent>
                          {{$t('READ_MORE')}}
                          <DownOutlined />
                        </a-tag>
                        <template #overlay>
                          <a-menu>
                            <a-menu-item @click="messageColumn(it.identified_name,msgIndex,'request')" v-for="it in getColumnsConfig" :key="it.id" >
                              {{it.name}}
                            </a-menu-item>
                            <a-menu-item  @click="messageColumn(i.id,msgIndex,'request')"  v-for="i in getColumn" :key="i.id" >
                              {{$t(i.text)}}
                            </a-menu-item>
                          </a-menu>
                        </template>
                      </a-dropdown>
                      <a-dropdown>
                        <a-tag class="ant-dropdown-link" @click.prevent>
                          {{$t('ORDER')}}
                          <DownOutlined />
                        </a-tag>
                        <template #overlay>
                          <a-menu>
                            <a-menu-item @click="messageColumn(i.id,msgIndex,'request')" v-for="i in getOrderColumn" :key="i.id" >
                              {{$t(i.text)}}
                            </a-menu-item>

                          </a-menu>
                        </template>
                      </a-dropdown>
                    </template>
                  </a-card-meta>
                  <a-list item-layout="horizontal" :data-source="message.elements">
                    <template #renderItem="{ item,index }">
                      <zalo-template-list
                          v-if="item && index !== 0"
                          :elementContexts="item"
                          :elementIndex="index"
                          :flowContextData="flowContextData"
                          :deleteElement="()=>deleteElement(msgIndex,item.id)"
                          :updateElement="(data)=>updateElement(msgIndex,index,data)"/>
                    </template>
                  </a-list>
                  <div class="button-post-back-container">
                    <a-button type="dashed" class="button-post-back" v-if="message.elements.length<5"
                              @click="addAnElement(msgIndex)">
                      <plus-outlined/>
                      {{ $t("ADD_ELEMENT") }}
                    </a-button>
                  </div>
                </a-card>
              </template>

            </div>
<!--            <flow-next-actions :buttonName="$t('AFTER_USER_SUBMIT')"-->
<!--                               :updateAction="updateActionRequestUserInfo"-->
<!--                               :flowContextData="flowContextData"-->
<!--                               :flowContext="flowContext"-->
<!--                               :actionContext="message.elements[0].flow_actions_context"/>-->
          </a-card>
        </Draggable>
      </Container>

      <a-divider/>
      <div>
        <div
            class="ant-upload ant-upload-select ant-upload-select-picture-card"
            @click="addText"
        >
          <span role="button" tabindex="0" class="ant-upload">
            <div>
              <img
                  src="@/assets/icons/text-icon.svg"
                  class="anticon icon-img-svg"
              />
              <div class="ant-upload-text">
                {{ $t("TEXT") }}
              </div>
            </div>
          </span>
        </div>
        <div
            class="ant-upload ant-upload-select ant-upload-select-picture-card"
            @click="addPhoto"
        >
          <span role="button" tabindex="0" class="ant-upload">
            <div>
              <img
                  src="@/assets/icons/photo-flow.svg"
                  class="anticon icon-img-svg"
              />
              <div class="ant-upload-text">
                {{ $t("PHOTO") }}
              </div>
            </div>
          </span>
        </div>
        <div
            class="ant-upload ant-upload-select ant-upload-select-picture-card"
            @click="addElement"
        >
          <span role="button" tabindex="0" class="ant-upload">
            <div>
              <img
                  src="@/assets/icons/element-zalo.svg"
                  class="anticon icon-img-svg"
              />
              <div class="ant-upload-text">
                {{ $t("PERSONAL_MEDIA_MESSAGES") }}
              </div>
            </div>
          </span>
        </div>
        <div
            class="ant-upload ant-upload-select ant-upload-select-picture-card"
            @click="addElement2"
        >
          <span role="button" tabindex="0" class="ant-upload">
            <div>
              <img
                  src="@/assets/icons/element-zalo.svg"
                  class="anticon icon-img-svg"
              />
              <div class="ant-upload-text">
                {{ $t("TRANSACTION_MESSAGE") }}
              </div>
            </div>
          </span>
        </div>
        <div
            class="ant-upload ant-upload-select ant-upload-select-picture-card"
            @click="addFromUserInfoRequest"
        >
          <span role="button" tabindex="0" class="ant-upload">
            <div>
              <user-outlined style="font-size: 28px;"/>
              <div class="ant-upload-text">
                {{ $t("REQUEST_USER") }}
              </div>
            </div>
          </span>
        </div>
      </div>
      <flow-next-actions  v-if="!isShow" :buttonName="$t('FLOW_SUCCESS_DELIVERY')"
                         :updateAction="updateSuccessAction"
                         :flowContextData="flowContextData"
                         :flowContext="flowContext"
                         :actionContext="flowContext.action_send_success_context"/>
      <flow-next-actions  v-if="!isShow" :buttonName="$t('FLOW_FAILED_DELIVERY')"
                         :updateAction="updateFailedAction"
                         :flowContextData="flowContextData"
                         :flowContext="flowContext"
                         :actionContext="flowContext.action_send_failed_context"/>
      <flow-next-actions  v-if="!isShow" :buttonName="$t('REQUEST_USER')"
                         :updateAction="updateDefaultAction"
                         :flowContextData="flowContextData"
                         :flowContext="flowContext"
                         :actionContext="flowContext.action_default_context"/>
      <div class="row-button-action" style="text-align: right">
        <a-popconfirm
            @confirm="deleteCurrentFlowContext"
            :title="$t('FLOW_CONFIRM_DELETE_CONTEXT')"
            :ok-text="$t('YES')"
            :cancel-text="$t('NO')"
        >
          <a-button danger type="primary">
            <delete-outlined/>
            {{ $t("FLOW_DELETE") }}
          </a-button>
        </a-popconfirm>
      </div>
    </a-form>
  </div>
  <image-selection-modal :single="true" ref="imageSelectionRef" :onSelect="onImageSelect"/>
</template>

<script lang="ts">
import {computed, defineComponent, onBeforeMount, reactive, ref, UnwrapRef, watch} from 'vue';
import {UserOutlined, DeleteOutlined, PlusOutlined, EyeOutlined, DragOutlined,CloseOutlined,UploadOutlined} from '@ant-design/icons-vue'
import FacebookPostbackButton from "@/components/Flow/FacebookPostbackButton.vue";
import FlowNextActions from "@/components/Flow/FlowNextActions.vue";
import {FormFlowContextFacebook, FormFlowContextZalo, FormFlowInformation} from "@/models/Flows/FlowForm";
import {ValidateErrorEntity} from "ant-design-vue/es/form/interface";
import {useStore} from "@/store";
import {ActionContext, FlowContext, FlowZaloElement, MessageContext, PostbackContext} from "@/models/Flows/FlowContext";
import {S_EventBus, ValidateFlowContext} from "@/utils/EventBus";
import {useI18n} from "vue-i18n";
import {MixUtils} from "@/utils/Utils";
import {SelectionAnt} from "@/models/ShopPages/ConfigData";
import {AWS_DO_Model} from "@/models/AWS_DO/AWS_DO";
import ImageSelectionModal from "@/components/Image/ImageSelectionModal.vue";
import ZaloTemplateList from "@/components/Flow/ZaloTemplateList.vue";
import ZaloButton from "@/components/Flow/ZaloButton.vue";
// @ts-ignore
import {Container, Draggable} from "vue3-smooth-dnd";
import "vue3-carousel/dist/carousel.css";
import { message } from 'ant-design-vue';


export default defineComponent({
  name: "flow-zalo-oa",
  props: [
    'flowContext',
    'updateCurrentFlowContext',
    'flowContextData',
    'deleteFlowContext',
      'isShow'
  ],
  components: {
    DeleteOutlined,
    ZaloTemplateList,
    FlowNextActions, PlusOutlined,
    ImageSelectionModal,
    EyeOutlined,
    ZaloButton,
    DragOutlined,
    Container,
    Draggable,
    CloseOutlined,
    UploadOutlined,
    UserOutlined
  },
  setup: function (props) {
    const cursorPositionMessageText = ref(0)
    const inputMessageCotent = ref()
    const inputRequest = ref()
    const inputContent = ref()
    const formRef = ref();
    const store = useStore();
    const formState: UnwrapRef<FormFlowContextZalo> = reactive({});
    const i18n = useI18n();
    const imageSelectionRef = ref()
    const messageContexts = ref<MessageContext[]>([]);
    const getColumnsConfig = computed(()=> store.getters.getCustomerConfig)
    const getColumn =[
      {id:'employees',text:'SALE_STAFF'},
      {id:'phone',text:'CUSTOMER_PHONE'},
      {id:'address',text:'CUSTOMER_ADDRESS'},
      {id:'birth',text:'CUSTOMER_BIRTHDAY'},
      {id:'sex',text:'SEX'},

    ]
    const getOrderColumn =[
      {id:'shipping_code',text:'ORDER_SHIPPING_UNIT_ID'},
      {id:'shipping_address',text:'CUSTOMER_ADDRESS'},
      {id:'phone',text:'CUSTOMER_PHONE'},
      {id:'detail',text:'NAME'},
      {id:'total',text:'TOTAL_AMOUNT'},
      {id:'prepaid',text:'PAID'},
      {id:'cod_amount',text:'COD'},
      {id:'created_at',text:'ORDER_DATE'},
      {id:'status',text:'STATUS'},
      {id:'order_number',text: 'ORDER_ID'}

    ]
    const updateProps = () => {
      if (props.flowContext) {
        formState.name = props.flowContext.name;
      }
      if (props.flowContext.message_contexts) {
        formState.message_content = props.flowContext.message;
        messageContexts.value = props.flowContext.message_contexts;
      } else {
        messageContexts.value = [];
      }
    }
    watch(() => props.flowContext, () => {
      updateProps()
    })
    const flowFormValidate: ValidateFlowContext = {
      showValidateForm(): void {
        onSubmit()
      }
    }
    onBeforeMount(() => {
      S_EventBus.instance().setValidateFlowContextListener(flowFormValidate)
    })
    const rules = {
      name: [
        {required: true, message: i18n.t('FLOW_VALIDATE_FLOW_NAME'), trigger: 'blur'},
      ],
      tags: [
        {
          type: 'array',
          required: true,
          message: i18n.t('FLOW_VALIDATE_FACEBOOK_MESSENGER_TAG'),
          trigger: 'change',
        },
      ],
      message_content: [
        {required: false, message: i18n.t('FLOW_VALIDATE_FACEBOOK_MESSAGE_CONTENT'), trigger: 'blur'},
      ],
    };
   const transactions = ref(['transaction_billing',
       'transaction_order',
       'transaction_reward',
       'transaction_contract',
       'transaction_booking',
       'transaction_membership',
       'transaction_event',
       'transaction_transation',
       'transaction_account',
       'transaction_internal',
       'transaction_partnership',
       'transaction_education',
       'transaction_rating',
   ])
    const forcusMessagetext = (e: any) => {
      // if(e.target.value.lastIndexOf('#') !== -1){
      //   e.target.setSelectionRange(e.target.value.lastIndexOf('#')+1,e.target.value.lastIndexOf('#')+1)
      //   console.log(e.target.selectionStart)
      // }else {
      cursorPositionMessageText.value = e.target.selectionStart


    }
    const onTextChange = (e: any) => {
      cursorPositionMessageText.value = e.target.selectionStart
      onSubmit()
    }
    const messageFullName = (msgIndex: number, t: string) => {
      if (t === 'content') {
        inputMessageCotent.value[0].focus()
      } else if (t === 'request') {
        inputRequest.value[0].focus()
      } else {
        inputContent.value[0].focus()
      }
      if (t === 'request') {
        // @ts-ignore
        const text = ref<any>(messageContexts.value[msgIndex].elements[0].subtitle)
        const start = text.value.slice(0, cursorPositionMessageText.value)
        const end = text.value.slice(cursorPositionMessageText.value)
        text.value =

            start + (start ? " " : "") + "##full_name##" + (end ? " " : "") + end
        // @ts-ignores
        messageContexts.value[msgIndex].elements[0].subtitle = text.value

      }
      // @ts-ignore
      const text = ref<any>(messageContexts.value[msgIndex].text);
      const start = text.value.slice(0, cursorPositionMessageText.value)
      const end = text.value.slice(cursorPositionMessageText.value)
      text.value =

          start + (start ? " " : "") + "##full_name##" + (end ? " " : "") + end
      // @ts-ignores
      messageContexts.value[msgIndex].text = text.value;

    }
    const messagePageName = (msgIndex: number, t: string) => {
      if (t === 'content') {
        inputMessageCotent.value[0].focus()
      } else if (t === 'request') {
        inputRequest.value[0].focus()
      } else {
        inputContent.value[0].focus()
      }
      if (t === 'request') {
        // @ts-ignore
        const text = ref<any>(messageContexts.value[msgIndex].elements[0].subtitle)
        const start = text.value.slice(0, cursorPositionMessageText.value)
        const end = text.value.slice(cursorPositionMessageText.value)
        text.value =

            start + (start ? " " : "") + "##page_name##" + (end ? " " : "") + end
        // @ts-ignores
        messageContexts.value[msgIndex].elements[0].subtitle = text.value

      }
      // @ts-ignore
      const text = ref<any>(messageContexts.value[msgIndex].text);
      const start = text.value.slice(0, cursorPositionMessageText.value)
      const end = text.value.slice(cursorPositionMessageText.value)
      text.value =

          start + (start ? " " : "") + "##page_name##" + (end ? " " : "") + end
      // @ts-ignores
      messageContexts.value[msgIndex].text = text.value;

    }
    const changeMessageType = (data:any,index:any) => {
     console.log(index,data,messageContexts.value)
      messageContexts.value[index].type = data
      onSubmit()
    }
    const messageColumn = (column:string,msgIndex: number,t: string) =>{
      console.log(column)
      if (t === 'content') {
        inputMessageCotent.value[0].focus()
      } else if (t === 'request') {
        inputRequest.value[0].focus()
      } else {
        inputContent.value[0].focus()
      }
      if (t === 'request') {
        // @ts-ignore
        const text = ref<any>(messageContexts.value[msgIndex].elements[0].subtitle)
        const start = text.value.slice(0, cursorPositionMessageText.value)
        const end = text.value.slice(cursorPositionMessageText.value)
        text.value =

            start + (start ? " " : "") + `##${column}##` + (end ? " " : "") + end
        // @ts-ignores
        messageContexts.value[msgIndex].elements[0].subtitle = text.value

      }
      // @ts-ignore
      const text = ref<any>(messageContexts.value[msgIndex].text);
      const start = text.value.slice(0, cursorPositionMessageText.value)
      const end = text.value.slice(cursorPositionMessageText.value)
      text.value =

          start + (start ? " " : "") + `##${column}##` + (end ? " " : "") + end
      // @ts-ignores
      messageContexts.value[msgIndex].text = text.value;

    }
    const updateElement = (msIndex: number, elementIndex: number, postBack: PostbackContext) => {
      // @ts-ignore
      messageContexts.value[msIndex].elements[elementIndex] = postBack
      onSubmit()
    }

    const updateButton = (msIndex: number, btnIndex: number, postBack: PostbackContext) => {
      // @ts-ignore
      messageContexts.value[msIndex].buttons[btnIndex] = postBack
      // props.updateCurrentFlowContext(flow)
      onSubmit()
    }
    const addFromUserInfoRequest = () => {
      const element: PostbackContext = {
        id: MixUtils.methods.getUUID(),
        title: i18n.t('TITLE'),
        subtitle: i18n.t('Subtitle'),
        flow_actions_context: {
          flow_crm_actions: []
        },
        type: store.getters.getFlowConstants?.zalo_element_types[0],
      }
      const context: MessageContext = {
        id: MixUtils.methods.getUUID(),
        elements: [element],
        buttons: [],
        type: "request_user_info",
      }
      messageContexts.value = [...messageContexts.value, context]
      onSubmit()
    }
    const changeColumn = (e:any) => {
      console.log(e.target.value)
    }
    const addElement = () => {
      const element: PostbackContext = {
        id: MixUtils.methods.getUUID(),
        flow_actions_context: {
          flow_crm_actions: []
        },
        type: 'banner',
      }
      const context: MessageContext = {
        id: MixUtils.methods.getUUID(),
        elements: [element],
        buttons: [],
        type: "promotion",
      }
      messageContexts.value = [...messageContexts.value, context]
      onSubmit()
    }
    const addElement2 = () => {
      const element: PostbackContext = {
        id: MixUtils.methods.getUUID(),
        flow_actions_context: {
          flow_crm_actions: []
        },
        type: 'banner',
      }
      const context: MessageContext = {
        id: MixUtils.methods.getUUID(),
        elements: [element],
        buttons: [],
        type: 'transaction_billing',
      }
      messageContexts.value = [...messageContexts.value, context]
      onSubmit()
    }
    const addAnElement = (msgIndex: number) => {
      console.log(messageContexts.value[msgIndex].elements)
      // @ts-ignore
    const check = messageContexts.value[msgIndex].elements.find((item:any) => item.type === 'header')
      // @ts-ignore
      const check2 = messageContexts.value[msgIndex].elements.find((item:any) => item.type === 'table')
      if(check && !check2){
        const btn: PostbackContext = {
          id: MixUtils.methods.getUUID(),
          content: '',
          align:'',
          content_table:[{key:'voucher', value:'11AAAFS'}],
          flow_actions_context: {
            flow_crm_actions: []
          },
          type: 'table',
          popoverVisible: true
        }
        if (!messageContexts.value[msgIndex].elements) {
          // @ts-ignore
          messageContexts.value[msgIndex].elements = []
        }
        // @ts-ignore
        messageContexts.value[msgIndex].elements.push(btn)
        onSubmit()
        // eslint-disable-next-line no-dupe-else-if
      }else if(check && check2){
        const btn: PostbackContext = {
          id: MixUtils.methods.getUUID(),
          content: '',
          align:'left',
          flow_actions_context: {
            flow_crm_actions: []
          },
          type: 'text',
          popoverVisible: true
        }
        if (!messageContexts.value[msgIndex].elements) {
          // @ts-ignore
          messageContexts.value[msgIndex].elements = []
        }
        // @ts-ignore
        messageContexts.value[msgIndex].elements.push(btn)
        onSubmit()
      }else {
        const btn: PostbackContext = {
          id: MixUtils.methods.getUUID(),
          content: '',
          align:'center',
          flow_actions_context: {
            flow_crm_actions: []
          },
          type: 'header',
          popoverVisible: true
        }
        if (!messageContexts.value[msgIndex].elements) {
          // @ts-ignore
          messageContexts.value[msgIndex].elements = []
        }
        // @ts-ignore
        messageContexts.value[msgIndex].elements.push(btn)
        onSubmit()
      }

    }
    const addAnElement2 = (msgIndex: number) => {
      console.log(messageContexts.value[msgIndex].elements)
      // @ts-ignore
      const check = messageContexts.value[msgIndex].elements.find((item:any) => item.type === 'header')
      // @ts-ignore
      const check2 = messageContexts.value[msgIndex].elements.find((item:any) => item.type === 'table')
      if(check && !check2){
        const btn: PostbackContext = {
          id: MixUtils.methods.getUUID(),
          content: '',
          align:'',
          content_table:[{key:'Mã', value:'11AAAFS',style: ""}],
          flow_actions_context: {
            flow_crm_actions: []
          },
          type: 'table',
          popoverVisible: true
        }
        if (!messageContexts.value[msgIndex].elements) {
          // @ts-ignore
          messageContexts.value[msgIndex].elements = []
        }
        // @ts-ignore
        messageContexts.value[msgIndex].elements.push(btn)
        onSubmit()
        // eslint-disable-next-line no-dupe-else-if
      }else if(check && check2){
        const btn: PostbackContext = {
          id: MixUtils.methods.getUUID(),
          content: '',
          align:'left',
          flow_actions_context: {
            flow_crm_actions: []
          },
          type: 'text',
          popoverVisible: true
        }
        if (!messageContexts.value[msgIndex].elements) {
          // @ts-ignore
          messageContexts.value[msgIndex].elements = []
        }
        // @ts-ignore
        messageContexts.value[msgIndex].elements.push(btn)
        onSubmit()
      }else {
        const btn: PostbackContext = {
          id: MixUtils.methods.getUUID(),
          content: '',
          align:'center',
          flow_actions_context: {
            flow_crm_actions: []
          },
          type: 'header',
          popoverVisible: true
        }
        if (!messageContexts.value[msgIndex].elements) {
          // @ts-ignore
          messageContexts.value[msgIndex].elements = []
        }
        // @ts-ignore
        messageContexts.value[msgIndex].elements.push(btn)
        onSubmit()
      }

    }

    const addButton = (msgIndex: number,str:string) => {
if(str){
  const btn: PostbackContext = {
    id: MixUtils.methods.getUUID(),
    title: i18n.t('Title_button'),
    image_icon:"https://social.sgp1.digitaloceanspaces.com/121212/1691221306_heart.png",
    flow_actions_context: {
      flow_crm_actions: []
    },
    type: store.getters.getFlowConstants?.zalo_element_types[0],
    popoverVisible: true
  }

  if (!messageContexts.value[msgIndex].buttons) {
    // @ts-ignore
    messageContexts.value[msgIndex].buttons = []
  }
  console.log(msgIndex,btn, messageContexts.value[msgIndex].buttons)
  // @ts-ignore
  messageContexts.value[msgIndex].buttons.push(btn)
  console.log(messageContexts.value[msgIndex].buttons)
  onSubmit()
}else {
  const btn: PostbackContext = {
    id: MixUtils.methods.getUUID(),
    title: i18n.t('Title_button'),
    flow_actions_context: {
      flow_crm_actions: []
    },
    type: store.getters.getFlowConstants?.zalo_element_types[0],
    popoverVisible: true
  }

  if (!messageContexts.value[msgIndex].buttons) {
    // @ts-ignore
    messageContexts.value[msgIndex].buttons = []
  }
  console.log(msgIndex,btn, messageContexts.value[msgIndex].buttons)
  // @ts-ignore
  messageContexts.value[msgIndex].buttons.push(btn)
  console.log(messageContexts.value[msgIndex].buttons)
  onSubmit()
}

    }
    const deleteElement = (msgIndex: number, flowId: string) => {
      // @ts-ignore
      messageContexts.value[msgIndex].elements = messageContexts.value[msgIndex].elements.filter(x => x.id !== flowId)
      onSubmit()
    }

    const deleteButton = (msgIndex: number, flowId: string) => {
      if (messageContexts.value[msgIndex].buttons) {
        // @ts-ignore
        messageContexts.value[msgIndex].buttons = messageContexts.value[msgIndex].buttons.filter(x => x.id !== flowId)
        onSubmit()
      }
      // props.updateCurrentFlowContext(flow)
    }

    const updateSuccessAction = (action: ActionContext) => {
      const flow = {...props.flowContext}
      flow.action_send_success_context = action
      props.updateCurrentFlowContext(flow)
    }
    const updateActionRequestUserInfo = (action: ActionContext) => {
      const flow = {...props.flowContext}
      flow.message_contexts[0].elements[0].flow_actions_context = action
      props.updateCurrentFlowContext(flow)
    }

    const updateFailedAction = (action: ActionContext) => {
      const flow = {...props.flowContext}
      flow.action_send_failed_context = action
      props.updateCurrentFlowContext(flow)
    }
    const updateDefaultAction = (action: ActionContext) => {
      const flow = {...props.flowContext}
      flow.action_default_context = action
      props.updateCurrentFlowContext(flow)
    }
const useInfo = ref('')
    const showImageSelectionModal = (msgIndex: string,Info: string) => {
      imageSelectionRef.value.showModal(msgIndex + "")
      // @ts-ignore

      useInfo.value = Info
    }

    const getPayload1 = () => {
      // @ts-ignore
      return (index) => {
        console.log("getPayload1", getPayload1);
        return messageContexts.value[index];
      };
    };

    const addText = () => {
      const messageContext = {
        id: MixUtils.methods.getUUID(),
        type: "text",
        carouselIndex: 0,
        facebook_templates: [],
        buttons: [],
        text: "",
      };
      messageContexts.value = [...messageContexts.value, messageContext];
      onSubmit()
    };
    const onCardDrop1 = (dropResult: any) => {
      console.log("dropResult", dropResult);
      if (dropResult.addedIndex === null || dropResult.removedIndex === null) {
        return;
      }
      if (dropResult.removedIndex !== dropResult.addedIndex) {
        const tmpRemove = messageContexts.value[dropResult.removedIndex];
        messageContexts.value[dropResult.removedIndex] =
            messageContexts.value[dropResult.addedIndex];
        messageContexts.value[dropResult.addedIndex] = tmpRemove;
      }
      onSubmit()
    };

    const onImageSelect = (images: AWS_DO_Model[], param: string) => {
          if (param) {
            if(useInfo.value){
              messageContexts.value =  messageContexts.value.map((i:any) => {
                if(i.type === 'request_user_info'){
                  images.map((item) => {
                    i.elements[0].image_url = item.url
                  })
                }

                return i

              })
            }else {
              messageContexts.value[parseInt(param)].images = images.map((item) => {
                return item.url
              })
            }


          }
          onSubmit()

    }

    const addPhoto = () => {
      const messageContext: MessageContext = {
        id: MixUtils.methods.getUUID(),
        type: "text_attachment",
        carouselIndex: 0,
        facebook_templates: [],
        buttons: [],
        images: [],
        text: ''
      };
      messageContexts.value = [...messageContexts.value, messageContext];
      onSubmit()
    }
    const deleteMessage = (id: string) => {
      messageContexts.value = messageContexts.value.filter(x => x.id !== id)
      console.log("deleteMessage", id)
      console.log("messageContexts", messageContexts.value)
      onSubmit()
    }

    const deleteCurrentFlowContext = () => {
      props.deleteFlowContext(props.flowContext.id)
    }

    const onSubmit = () => {
      formRef.value
          .validate()
          .then(() => {
            const flow = {...props.flowContext} as FlowContext
            flow.message = formState.message_content
            flow.name = formState.name
            flow.message_contexts = messageContexts.value
            props.updateCurrentFlowContext(flow)
          })
          .catch((error: ValidateErrorEntity<FormFlowInformation>) => {
            console.log('error', error);
          });
    };
    updateProps()
    return {
      rules,
      formState,
      formRef,
      onSubmit,
      changeMessageType,
      onTextChange,
      forcusMessagetext,
      updateElement,
      updateSuccessAction,
      updateFailedAction,
      updateDefaultAction,
      updateActionRequestUserInfo,
      addElement,
      addFromUserInfoRequest,
      deleteElement,
      deleteCurrentFlowContext,
      imageSelectionRef,
      showImageSelectionModal,
      onImageSelect,
      deleteButton,
      updateButton,
      addButton,
      messageFullName,
      getPayload1, inputMessageCotent, inputContent, inputRequest,
      onCardDrop1,
      messageContexts,
      addText,
      addAnElement,
      addPhoto,
      deleteMessage,
      getColumnsConfig,
      getColumn,
      changeColumn,
      messageColumn,
      addAnElement2,
      addElement2,
      transactions,
      messagePageName,
      getOrderColumn

    }
  }
})
</script>

<style lang="scss" scoped>
.main-flow {
  position: relative;
  padding: 40px 18% 60px;
  text-align: left;


  .icon-brand-sticker {
    position: absolute;
    font-size: 28px;
    top: 0;
    margin-right: 25px;
    right: 0;
  }

  .icon-img-svg {
    width: 28px;
    height: 28px;
  }

  .row-button-action {
    margin-top: 20px;
  }

  .card-message {
    border-radius: 10px;
    margin-top: 10px;

  }
}

.zalo-element {
  margin-top: 18px;

  .button-post-back-container {
    text-align: center;
  }
  .request-user-info-cover-img{
    max-height: 280px;
    object-fit: cover;
  }
}

.post-back-button {
  margin-top: 18px;
  text-align: center;
}


.button-post-back {
  margin: 5px 10px;


}

.img-attachment {
  width: 100%;
  height: 100%;
}


</style>
