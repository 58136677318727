import {Tag} from "@/models/Tags/Tags";
import {Moment} from "moment";
// @ts-ignore
import {Dayjs} from "dayjs";
export type Customer = {
	province_id?: string;
	district_id?: string;
	id?: number;
	key?: string;
	shop_id?: number;
	social_type?: string;
	page_id?: string;
	social_id?: string;
	facebook_uid?: string;
	link_get_uid?: string;
	full_name?: string;
	nick_name?: string;
	email?: string;
	birth?: string|Dayjs;
	sex?: string;
	is_email_receive?: string;
	address?: string;
	address_full?: string;
	phone?: string;
	all_phones?: string;
	fb_url?: string;
	note?: string;
	tag?: string;
	tag_process?: string;
	employees?: string;
	created_by?: number;
	created_at?: string|Dayjs;
	updated_at?: string;
	first_contact_time?: string;
	last_contact_time?: string;
	last_care?: number;
	conversation_tags?: string;
	conversation_staff?: string;
	ward_id?: number;
	ward_name?: string;
	last_care_name?: number;
	district_name?: string;
	province_name?: string;
	hotel_tags?: string;
	career?: number;
	field?: string;
	value?: string;
	label?: string;
	tags?: Tag[];
	history_comments?: CustomerHistoryComment[];
	avatar?:string
	zalo_appuid?:string
	fb_appuid?:string
	fb_page_id?:string
	zalo_page_id?:string
}

export type CustomersForm = {
	id?: number;
	shop_id?: number;
	social_type?: string;
	page_id?: string;
	social_id?: string;
	facebook_uid?: string;
	link_get_uid?: string;
	full_name?: string;
	nick_name?: string;
	email?: string;
	birth?: string;
	sex?: string;
	is_email_receive?: string;
	address?: string;
	address_full?: string;
	phone?: string;
	all_phones?: string;
	fb_url?: string;
	province_id?: number;
	district_id?: number;
	note?: string;
	note_tag?: string;
	tag?: string;
	tag_process?: string;
	employees?: string;
	selectedEmployees?: number[];
	created_by?: number;
	created_at?: string;
	updated_at?: string;
	first_contact_time?: string;
	last_contact_time?: string;
	last_care?: number;
	conversation_tags?: string;
	conversation_staff?: string;
	selectedConversation_staff?: number[];
	ward_id?: number;
	ward_name?: string;
	last_care_name?: number;
	district_name?: string;
	province_name?: string;
	tags?: number[]|Tag[];
	selectedTags?: number[];
	image?: File;
	imageFile?: File;
	key?: string;
	field?: string;
	cxview_token?: string;
}


export type CustomerHistory ={
  id: number;
  shop_id: number;
  shop_customer_id: number;
	content_history:CustomerHistoryComment[]
}

export type CustomerHistoryComment ={
  id: string;
  type: number;
  tag_id: number;
  content: string;
  cms_user_id: number;
  created_at: string;
}

export type CustomerForm = Customer&{
	selectedConversationStaff?: number;
	conversationTags?: string[];
	selectedEmployee?: number;
	selectedTags?: number[];
	image?: File;
	imageFile?: File;
	key?: string;

}

export type UpdateLastCare = {
	user_id?: number;
	last_care_id?: number;
}

export enum CustomerActivityType{
	NOTE='note',
	ADD_TAG='add_tag',
	UPDATE_TAG='update_tag',
	REMOVE_TAG='remove_tag',
	SCHEDULE='schedule',
	UPDATE_LAST_CARE='change_last_care',


}
export type CustomerActivity = {
	_id?: string;
	shop_id?: number;
	tag_ids?: number[];
	creator?: number;
	content?:string,
	type?:CustomerActivityType,
	customer_id?:number;
	created_at?: string;
}


export type ShopCustomerHistoriesRequest = {
	shop_customer_id?: number|string|string[]
}


export type AddShopCustomerHistoryRequest = {
	shop_customer_id?: number
	type?: number
	content?: string
	tag_id?: number
}

export type Careers = {
	id?: number
	shop_id?: number
	name?: string
	description?: string
	created_at?: string
	updated_at?: string
}

export type GetRequest={
	page?: number,
	page_size?: number,
	search?: string,
}


export type CreateCareerRequest = {
	id?: number;
	shop_id?: number;
	name?: string;
	description?: string;
}



export type ContactForm = {
	id?: number;
	shop_id?: number;
	shop_customer_id?: number;
	prefix?: string;
	full_name?: string;
	position?: string;
	phone?: string;
	birthday?: string|Dayjs;
	gender?: number;
	note?: string;
	created_at?: string;
	updated_at?: string;
}

