<template>
  <div class="container-title">
    <h3 class="title-header">{{$t($route.name)}}</h3>
  </div>
  <a-row class="container-content">
    <a-col :span="11">
    <a-card :title="$t('EMPLOYEE_FROM_SYSTEM')">
      <a-form
          ref="formRef"
          :model="employeeForm"
          :rules="rules"
          labelAlign="left" :label-col="{style: {width: '100px'}}" >
        <a-form-item :label="$t('ROLE')" name="role">
          <a-select
              :placeholder="$t('PLACEHOLDER_INPUT_ROLE')"
              :options="roles"
              v-model:value="employeeForm.role"
              @change="handleRoleChange"
          ></a-select>
        </a-form-item>

        <a-form-item :label="$t('USER')" name="user">
          <a-auto-complete
              v-model:value="employeeForm.user"
              :placeholder="$t('SEARCH')"
              :options="result"
              @search="handleSearch"
              @change="handleUserChange"
          >
            <template #option="{value:val}">
                {{ val }}
            </template>
          </a-auto-complete>
        </a-form-item>
        <a-form-item>
          <a-button
              @click="save"
              type="primary">
            {{$t('SAVE')}}
          </a-button>
        </a-form-item>
      </a-form>
    </a-card>
    </a-col>
    <a-col :span="11">
      <a-card :title="$t('CREATE_LINK_INVITE_TO_SHOP')">
        <a-form
            ref="formInviteRef"
            :model="employeeFormInvite"
            :rules="rulesInvite"
            labelAlign="left" :label-col="{style: {width: '100px'}}">
          <a-form-item :label="$t('ROLE')" name="role_invite">
            <a-select
                :placeholder="$t('PLACEHOLDER_INPUT_ROLE')"
                :options="roles"
                v-model:value="employeeFormInvite.role_invite"
                @change="handleRoleInviteChange"
            ></a-select>
          </a-form-item>
          <a-form-item>
            <a @click="copy" >{{linkInvite}}</a>
          </a-form-item>
          <a-form-item>
            <a-button
                @click="createLinkInvite"
                type="primary">
              {{$t('CREATE_LINK_INVITE')}}
            </a-button>
          </a-form-item>
        </a-form>
      </a-card>
    </a-col>

  </a-row>
</template>

<script>
import { useStore } from "@/store";
import {computed, reactive, ref} from "vue";
import { useRoute } from "vue-router";
import { UserOutlined } from "@ant-design/icons-vue";
import APIs from "@/APIs/APIs";
import { EmployeeActionTypes } from "@/store/modules/employee/action-types";
import {message, notification} from "ant-design-vue";
import {useI18n} from "vue-i18n";
import {MixUtils} from "@/utils/Utils";
import router from "@/router";


export default {
  name: 'EmployeeAdd',
  components: {
  },
  setup() {
    const employeeForm = reactive({
      user: undefined,
      role: undefined
    })
    const employeeFormInvite = reactive({
      role_invite: undefined
    })
    const formRef = ref();
    const formInviteRef = ref();
    const store = useStore();
    const result = ref([]);
    const user = ref();
    const userSelected = ref();
    const linkInvite = ref('');

    const roles = ref([]);
    const selectedRole = ref();
    const selectedRoleInvite = ref();
    const i18n = useI18n();

    const rules = {
      user: [
        {required: true, message: 'Please input user', trigger: 'blur'},
      ],
      role: [
        {required: true, message: 'Please input Role', trigger: 'blur'},
      ],
    };
    const rulesInvite = {
      role_invite: [
        {required: true, message: 'Please input Role', trigger: 'blur'},
      ],
    };

    const fetchRole = async  () => {
      const res = await APIs.init().getRole()
      if (res.status=== 200){
        roles.value = res.data?.data.filter(item=>item.id!==2).map(item => ({label: item.name, value: item.id}))
        console.log(roles)
      }
    }
    fetchRole()

const copy = async () => {
await  navigator.clipboard.writeText(linkInvite.value)
  window.location = linkInvite.value
}

    const save = async () => {
          formRef.value
              .validate()
              .then(async () => {
                try {
                  const userId = Number(employeeForm.user.match(/\((.*?)\)/g)[0].replace(/\(/g, '').replace(/\)/g, ''))
                  const res = await APIs.init().addEmployee({
                    role_id: Number(employeeForm.role),
                    user_id: userId,
                  });
                  console.log(res)
                  if (res.data.status === 200) {
                    store.dispatch(EmployeeActionTypes.FETCH_EMPLOYEE, {
                      page: 1,
                      page_size: 20,

                    });
                    notification.success({
                      duration: 2,
                      message: i18n.t('INFO'),
                      description: "Invite success",
                    });
                  }else if(res.data.status === 400 ){
                    notification.error({
                      duration: 2,
                      message: i18n.t('INFO'),
                      description: res.data?.message,
                    });
                  }
                } catch (error) {
                  console.log(error);
                }

              }).catch((error) => {
            console.error('error', error);
          });
    };
    const handleRoleChange = (value) => {
      employeeForm.role = value
      console.log('employeeForm', employeeForm)
    };


    const handleRoleInviteChange = (value) => {
      selectedRoleInvite.value = value
    };


    const handleSearch = async (val) => {
      if (val.length < 3) return;
      const res = await APIs.init().getEmployeeSearch(val)
      if(res.status === 200) {
        result.value = res.data?.data?res.data?.data.map(user=>{
          user.value = '('+user.id+') '+user.name+' - '+user.email+' - '+user.mobile
          return user
        }):[]
      }

    };
    const handleUserChange = (val) =>{
      employeeForm.user = val
    }
    const createLinkInvite = async () =>{
        formInviteRef.value.validate()
            .then(async () => {
              try {
                const res = await APIs.init().getLinkInvite({
                  role_id: employeeFormInvite.role_invite,
                });
                if (res.data.status === 200) {
                  linkInvite.value = MixUtils.getCRMUrl() + '?invite=' + res.data.data

                } else {
                  notification.error({
                    duration: 2,
                    message: i18n.t('INFO'),
                    description: res.data?.message,
                  });
                }

              } catch (error) {
                console.log(error);
              }

            }).catch((error) => {
          console.error('error', error);
        });
    }

    return {
      formRef,
      employeeForm,
      employeeFormInvite,
      rules,
      rulesInvite,
      handleSearch,
      handleRoleChange,
      handleRoleInviteChange,
      result,
      userSelected,
      user,
      handleUserChange,
      roles,
      selectedRole,
      save,
      selectedRoleInvite,
      createLinkInvite,
      formInviteRef,
      linkInvite,
      copy,
    };
  },
};
</script>

<style>
.container-content{
  display: flex;
  gap: 16px
}
</style>
