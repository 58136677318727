<template>
  <div class="container" v-if="version!=='new'">
    <div style="margin:12px;">
      <h3>{{ $t($route.name) }}</h3>
    </div>
    <a-layout theme="light">
      <a-layout-sider theme="light">
        <a-menu
            mode="inline"
            theme="light"
            v-model:selectedKeys="selectedTrigger"
        >
          <a-menu-item key="all" @click="selectTriggerType('all')">
            <span>{{ $t('all') }}</span>
          </a-menu-item>
          <template v-if="getFlowConstants&&getFlowConstants.trigger_types">
            <a-menu-item v-for="key in getFlowConstants.trigger_types" :key="key" @click="selectTriggerType(key)">
              <a-tooltip :title="$t(key)">
                {{ $t(key) }}
              </a-tooltip>
            </a-menu-item>
          </template>

        </a-menu>
      </a-layout-sider>
      <a-layout theme="light">
        <a-layout-content>
          <div class="table-header">
            <!--            <h4>-->
            <!--              {{ selectedTrigger&&selectedTrigger.length>0?$t(selectedTrigger[0]):$t('all') }}-->
            <!--            </h4>-->
            <div class="table-title">

              <a-input-search
                  class="input-search"
                  :placeholder="$t('SEARCH')"
                  v-model:value="searchInput"
                  @change="onSearchChange"
                  style="width: 220px;margin-left: 12px;"
              />
              <a-select
                  showSearch
                  class="input-search"
                  allowClear
                  mode="multiple"
                  :v-mode:value="selectedTags"
                  :placeholder="$t('TAGS')"
                  style="min-width: 220px; margin-left: 12px;"
                  @change="handleChangeTags"
              >
                <a-select-option
                    v-for="item in getTriggerTags"
                    :key="item.id"
                    class="input-search"
                    v-bind:value="item.id"
                >
                  <a-tag :color="item.color">
                    {{ item.name }}
                  </a-tag>
                </a-select-option>
              </a-select>
              <a-select
                  showSearch
                  allowClear
                  class="input-search"
                  :placeholder="$t('status')"
                  style="width: 220px;margin-left: 12px;"
                  @change="handleChangeStatus"
              >
                <a-select-option
                    v-for="item in getFlowConstants.trigger_status"
                    :key="item"
                    v-bind:value="item"
                >
                  <a-typography-text>{{ $t(item) }}</a-typography-text>


                </a-select-option>
              </a-select>
            </div>

            <a-button
                v-if="getMyRole(MODULES.trigger)?.is_create"
                type="primary" @click="newTrigger">
              <plus-outlined/>
              {{ $t("TRIGGER_SETTING") }}
            </a-button>
          </div>
          <a-table
              size="middle"
              :rowKey="record => record.id"
              :columns="columns" :data-source="getTriggers"
              :pagination="pagination"
              @change="handleTableChange"
              class="flow-table">
            <template #name="{ record }">
              <a class="table-row-name" type="text" @click="customRowClick(record)">
                {{ record.name }}
              </a>
            </template>
            <template #created_at="{ text }">
                <span class="item-trigger-table-small">
                   {{ dateTimeDisplayFormatFromString(text) }}

                </span>
            </template>
            <template #creator="{ text }">
                <span>
                  {{ getEmployeeName(text) }}

                </span>
            </template>
            <template #type="{ text }">
               <span class="item-trigger-table-small">
                    {{ $t(text) }}
                </span>
            </template>
            <template #status="{ text }">
               <span>
                    <a-tag :color="getTriggerStatusColor(text)">{{ text }}</a-tag>
                </span>
            </template>
            <template #tags="{ record }">
                <span>
                    <a-tag v-for="tag in record.tags" :key="tag.id+'_'+tag.name"
                           :color="tag.color">{{ tag.name }}</a-tag>
                </span>
            </template>

            <template #action="{record}">
              <a-dropdown>
                <a-button type="text">
                  <eye-outlined/>
                </a-button>
                <template #overlay>
                  <a-menu>
                    <a-menu-item
                        v-if="getMyRole(MODULES.trigger)?.is_edit"
                        @click="customRowClick(record)">
                      <edit-outlined/>
                      {{ $t('EDIT') }}
                    </a-menu-item>
                    <a-menu-item
                        v-if="record.status==='running' && getMyRole(MODULES.trigger)?.is_edit"
                        @click="stopTriggerModal(record)" >
                      <stop-outlined/>
                      {{ $t('STOP_TRIGGER') }}
                    </a-menu-item>
                    <a-menu-item
                        v-if="record.status!=='running' && getMyRole(MODULES.trigger)?.is_edit"
                        @click="activateTriggerModal(record)" >
                      <aim-outlined/>
                      {{ $t('Activate_trigger') }}
                    </a-menu-item>
                    <a-menu-item
                        v-if="getMyRole(MODULES.trigger)?.is_edit"
                        @click="editFlow(record)">
                      <edit-outlined/>
                      {{ $t('EDIT_FLOW') }}
                    </a-menu-item>
                    <a-menu-item
                        v-if="getMyRole(MODULES.trigger_history)?.is_edit"
                        @click="goHistory(record)">
                      <history-outlined/>
                      {{ $t('HISTORY') }}
                    </a-menu-item>
                    <a-menu-item
                        v-if="getMyRole(MODULES.trigger)?.is_delete"
                    >
                      <a-popconfirm
                          :title="$t('TRIGGER_CONFIRM_DELETE_CONTEXT')"
                          :ok-text="$t('YES')"
                          :cancel-text="$t('NO')"
                          @confirm="deleteTrigger(record)"
                      >
                        <delete-outlined/>
                        {{ $t('DELETE') }}
                      </a-popconfirm>

                    </a-menu-item>
                  </a-menu>
                </template>
              </a-dropdown>
            </template>

          </a-table>
        </a-layout-content>
      </a-layout>
    </a-layout>

  </div>
</template>

<script lang="ts">
import {Options, Vue} from "vue-class-component";
import {
  DeleteOutlined,
  DownOutlined,
  EditOutlined,
  PlusOutlined,
  SmileOutlined,
  TagOutlined,
  PlusCircleFilled,
  CheckOutlined,
  SendOutlined,
  AimOutlined, ExclamationCircleOutlined,
  StopOutlined,
  HistoryOutlined,
  EyeOutlined,
} from "@ant-design/icons-vue"
import RouterEndpoint from "@/router/RouterEndpoint";
import {mapActions, mapGetters} from "vuex";
import {Employee, Page} from "@/models/Users";
import {TriggerActionTypes} from "@/store/modules/trigger/action-types";
import {TriggerRequest} from "@/APIs/request/TriggerRequest";
import {Tag} from "@/models/Tags/Tags";
import {TriggerForm} from "@/models/Trigger/TriggerForm";
import {Trigger, TriggerCustomerComparison} from "@/models/Trigger/Trigger";
import {FlowActionTypes} from "@/store/modules/flow/action-types";
import {TagsActionTypes} from "@/store/modules/tag/action-types";
import {ValidateErrorEntity} from "ant-design-vue/es/form/interface";
import moment from "moment";
import APIs from "@/APIs/APIs";
import {message, Modal} from "ant-design-vue";
import {computed, createVNode} from "vue";
import {FlowType} from "@/models/Flows/Flow";
import {Wallet} from "@/models/Wallet/Wallet";
import {UserActionTypes} from "@/store/modules/user/action-types";
import {FacebookPostData} from "@/models/facebook/FacebookPost";
import {CustomerCaresActionTypes} from "@/store/modules/customerCare/action-types";
import {FlowContext, FlowContexts} from "@/models/Flows/FlowContext";
import {ShopinfoActionTypes} from "@/store/modules/Shopinfo/action-types";
import {PaginationProps} from "ant-design-vue/es/pagination";
import dayjs from "dayjs";
import {MixUtils} from "@/utils/Utils";
import QRCode from "qrcode";
import {TreeTagsResponse} from "@/APIs/response/S_HttpResponse";
import {PageConfigActionTypes} from "@/store/modules/PageConfig/action-types";
import {OverviewActionTypes} from "@/store/modules/OverView/action-types";
import {Constant} from "@/utils/Constants";
import router from "@/router";
import useTagStore from "../../../store/Tags";
let searchTreeTimeout: number;
const rules = {
  name: [
    {required: true, message: 'Please input display name', trigger: 'blur'},
  ],
  root_flow_id: [
    {
      type: 'number',
      required: true,
      message: 'Please select at least one flow',
      trigger: 'change',
    },
  ],
  type: [
    {
      type: 'string',
      required: true,
      message: 'Please select at least one type',
      trigger: 'change',
    },
  ],
  trigger_condition: {
    message: 'Please add at least one condition',
    isValid: true
  }
};


let inputChangeTimeout: number;
let flowHandleSearchTimeout: number;

const triggerRequest: TriggerRequest = {}
let formState: TriggerForm = {
  customer_condition: {
    condition: '',
    trigger_comparisons: [

    ]
  }
}

const dateTimeFormat = 'DD-MM-YYYY HH:mm';

@Options({
  name: 'triggers',
  components: {
    TagOutlined,
    PlusOutlined,
    SmileOutlined,
    DownOutlined,
    DeleteOutlined,
    EditOutlined,
    PlusCircleFilled,
    CheckOutlined,
    SendOutlined,
    EyeOutlined,
    AimOutlined,
    StopOutlined,
    ExclamationCircleOutlined,
    HistoryOutlined
  },
  computed: {
    columns() {
      return [
        {
          title: this.$t('TRIGGER_TITLE'),
          dataIndex: 'name',
          key: 'name',
          slots: {customRender: 'name'},
        },
        {
          title: this.$t('Type'),
          dataIndex: 'type',
          key: 'type',
          slots: {customRender: 'type'},
        },
        {
          title: this.$t('TRIGGER_STATUS'),
          key: 'status',
          dataIndex: 'status',
          slots: {customRender: 'status'},
        },
        {
          title: this.$t('TRIGGER_CREATOR'),
          dataIndex: 'creator',
          key: 'creator',
          slots: {customRender: 'creator'},
        },
        {
          title: this.$t('TRIGGER_TAG'),
          key: 'tags',
          dataIndex: 'tags',
          slots: {customRender: 'tags'},
        },
        {
          title: this.$t('TRIGGER_DATE_CREATED'),
          dataIndex: 'created_at',
          key: 'created_at',
          slots: {customRender: 'created_at'},
        },

        {
          title: '',
          key: 'action',
          slots: {customRender: 'action'},
          width: 100
        },
      ]
    },
    ...mapGetters({
      getTriggers: 'getTriggers',
      getCurrentShop: 'getCurrentShop',
      getTriggerTags: 'getTriggerTags',
      getCustomerCares: 'getCustomerCares',
      getAdminAndShopOwner: 'getAdminAndShopOwner',
      getFlowConstants: 'getFlowConstants',
      getFlowData: 'getFlowData',
      getCustomerTags: 'getCustomerTags',
      getPages: 'getPages',
      getWallet: 'getWallet',
      getFlows: 'getFlows',
      getPostsSelection: 'getPostsSelection',
      getTriggerMetadata: 'getTriggerMetadata',
      getFlowsSelectionAntWithoutAll: 'getFlowsSelectionAntWithoutAll',
      getMailPage: 'getMailPage',
      getTreeTags: "getTreeTags",
      getCustomerConfig:"getCustomerConfig",
      userInfo: "getUserInfo",
      getOverview:"getOverview",
      getRole:"getRole",
      // getConverstionTag:"getConverstionTag"
    }),
    rules() {
      let rules = {
        name: [
          {required: true, message: this.$t('VALIDATE_DISPLAY_NAME'), trigger: 'blur'},
        ],
        root_flow_id: [
          {
            type: 'string',
            required: true,
            message: this.$t('TRIGGER_VALIDATE_FLOW'),
            trigger: 'blur',
          },
        ],
        type: [
          {
            type: 'string',
            required: true,
            message: this.$t('VALIDATE_TYPE'),
            trigger: 'change',
          },
        ],
        trigger_condition: {
          message: this.$t('TRIGGER_VALIDATE_CONDITION'),
          isValid: true
        },

      }

      return rules;
    },
    getConverstionTag(){
    return   useTagStore.getState().pageConverstionTag
    },

    pagination() {
      console.log("pagination", this.getTriggerMetadata)
      return {
        current: this.triggerRequest.page ? this.triggerRequest.page : 1,
        pageSize: this.triggerRequest.page_size ? this.triggerRequest.page_size : 20,
        defaultPageSize: 20,
        total: this.getTriggerMetadata && this.getTriggerMetadata.record_total ? this.getTriggerMetadata.record_total : 0,
        pageSizeOptions: ['20', '40', '80', '120'],
        showSizeChanger: true
      }
    }
  },
  data() {
    return {
      amountSent:undefined,
      RouterEndpoint: RouterEndpoint,
      searchInput: '',
      selectedTags: [],
      selectedType: '',
      searchPages:[],
      selectedstatus: '',
      triggerRequest,
      modalCreateVisible: false,
      formState,
      confirmTriggerLoading: false,
      dateFormat: 'DD-MM-YYYY HH:mm',
      currentPage: {},
      MODULES: Constant.MODULES,
      posts: [],
      tags: [],
      selectTags:[],
      conditions: [],
      trigger_id:0,
      status:'',
      orderStatus:[{
        id:-1,
        name: 'STATUS_ORDER_CANCEL'
      }, {
        id: 0,
        name: 'STATUS_ORDER_NEW'
      }, {
        id: 1,
        name: 'STATUS_ORDER_CONFIRM'
      }, {
        id: 2,
        name: 'STATUS_ORDER_WAITING_SHIP'
      }, {
        id: 3,
        name: 'STATUS_ORDER_PICKED_SHIP'
      }, {
        id: 4,
        name: 'ORDER_SUCCESS'
      }, {
        id: 5,
        name: 'STATUS_ORDER_RETURNED'
      }, {
        id: 6,
        name: 'STATUS_ORDER_RETURNING'
      }, {
        id: 7,
        name: 'STATUS_ORDER_SHIPPING'
      }, {
        id: 8,
        name: 'STATUS_ORDER_RETURN_OFFER'
      },

      ],
      timeoutSelections: [
        {
          label: '10 ' + this.$t('second'),
          value: "10"  //second
        },
        {
          label: '20 ' + this.$t('second'),
          value: "20"  //second
        },
        {
          label: '1 ' + this.$t('minutes'),
          value: "60"  //second
        },
        {
          label: '2 ' + this.$t('minutes'),
          value: "120"  //second
        },
        {
          label: '3 ' + this.$t('minutes'),
          value: "180"  //second
        },
        {
          label: '4 ' + this.$t('minutes'),
          value: "240"  //second
        },
        {
          label: '5 ' + this.$t('minutes'),
          value: "300"  //second
        },
        {
          label: '6 ' + this.$t('minutes'),
          value: "360"  //second
        },
        {
          label: '7 ' + this.$t('minutes'),
          value: "420"  //second
        },
        {
          label: '8 ' + this.$t('minutes'),
          value: "480"  //second
        },
        {
          label: '9 ' + this.$t('minutes'),
          value: "640"  //second
        },

        {
          label: '10 ' + this.$t('minutes'),
          value: "600"  //second
        },
        {
          label: '11 ' + this.$t('minutes'),
          value: "660"  //second
        },
        {
          label: '12 ' + this.$t('minutes'),
          value: "720"  //second
        },
        {
          label: '13 ' + this.$t('minutes'),
          value: "780"  //second
        },
        {
          label: '14 ' + this.$t('minutes'),
          value: "840"  //second
        },
        {
          label: '15 ' + this.$t('minutes'),
          value: "900"  //second
        },
        {
          label: '16 ' + this.$t('minutes'),
          value: "960"  //second
        },
        {
          label: '17 ' + this.$t('minutes'),
          value: "1020"  //second
        },
        {
          label: '18 ' + this.$t('minutes'),
          value: "1080"  //second
        },
        {
          label: '19 ' + this.$t('minutes'),
          value: "1140"  //second
        },
        {
          label: '20 ' + this.$t('minutes'),
          value: "1200"  //second
        },
        {
          label: '21 ' + this.$t('minutes'),
          value: "1260"  //second
        },
        {
          label: '22 ' + this.$t('minutes'),
          value: "1320"  //second
        },
        {
          label: '23 ' + this.$t('minutes'),
          value: "1380"  //second
        },
        {
          label: '24 ' + this.$t('minutes'),
          value: "1440"  //second
        },
        {
          label: '25 ' + this.$t('minutes'),
          value: "1500"  //second
        },
        {
          label: '26 ' + this.$t('minutes'),
          value: "1560"  //second
        },
        {
          label: '27 ' + this.$t('minutes'),
          value: "1620"  //second
        },
        {
          label: '28 ' + this.$t('minutes'),
          value: "1680"  //second
        },
        {
          label: '29 ' + this.$t('minutes'),
          value: "1740"  //second
        },
        {
          label: '30 ' + this.$t('minutes'),
          value: "1800"  //second
        },
        {
          label: '31 ' + this.$t('minutes'),
          value: "1860"  //second
        },
        {
          label: '32 ' + this.$t('minutes'),
          value: "1920"  //second
        },
        {
          label: '33 ' + this.$t('minutes'),
          value: "1980"  //second
        },
        {
          label: '34 ' + this.$t('minutes'),
          value: "2040"  //second
        },
        {
          label: '35 ' + this.$t('minutes'),
          value: "2100"  //second
        },
        {
          label: '36 ' + this.$t('minutes'),
          value: "2160"  //second
        },
        {
          label: '37 ' + this.$t('minutes'),
          value: "2220"  //second
        },
        {
          label: '38 ' + this.$t('minutes'),
          value: "2280"  //second
        },
        {
          label: '39 ' + this.$t('minutes'),
          value: "2340"  //second
        },
        {
          label: '40 ' + this.$t('minutes'),
          value: "2400"  //second
        },
        {
          label: '41 ' + this.$t('minutes'),
          value: "2460"  //second
        },
        {
          label: '42 ' + this.$t('minutes'),
          value: "2520"  //second
        },
        {
          label: '43 ' + this.$t('minutes'),
          value: "2580"  //second
        },
        {
          label: '44 ' + this.$t('minutes'),
          value: "2640"  //second
        },
        {
          label: '45 ' + this.$t('minutes'),
          value: "2700"  //second
        },
        {
          label: '46 ' + this.$t('minutes'),
          value: "2760"  //second
        },
        {
          label: '47 ' + this.$t('minutes'),
          value: "2820"  //second
        },
        {
          label: '48 ' + this.$t('minutes'),
          value: "2880"  //second
        },
        {
          label: '49 ' + this.$t('minutes'),
          value: "2940"  //second
        },
        {
          label: '50 ' + this.$t('minutes'),
          value: "3000"  //second
        },
        {
          label: '51 ' + this.$t('minutes'),
          value: "3060"  //second
        },
        {
          label: '52 ' + this.$t('minutes'),
          value: "3120"  //second
        },
        {
          label: '53 ' + this.$t('minutes'),
          value: "3180"  //second
        },
        {
          label: '54 ' + this.$t('minutes'),
          value: "3240"  //second
        },
        {
          label: '55 ' + this.$t('minutes'),
          value: "3300"  //second
        },
        {
          label: '56 ' + this.$t('minutes'),
          value: "3360"  //second
        },
        {
          label: '57 ' + this.$t('minutes'),
          value: "3420"  //second
        },
        {
          label: '58 ' + this.$t('minutes'),
          value: "3480"  //second
        },
        {
          label: '59 ' + this.$t('minutes'),
          value: "3540"  //second
        },
        {
          label: '1 ' + this.$t('HOURS'),
          value: "3600"  //second
        },
        {
          label: '2 ' + this.$t('HOURS'),
          value: "7200"  //second
        },
        {
          label: '3 ' + this.$t('HOURS'),
          value: "10800"  //second
        },
        {
          label: '4 ' + this.$t('HOURS'),
          value: "14400"  //second
        },
        {
          label: '5 ' + this.$t('HOURS'),
          value: "18000"  //second
        },
        {
          label: '6 ' + this.$t('HOURS'),
          value: "21600"  //second
        },
        {
          label: '7 ' + this.$t('HOURS'),
          value: "25200"  //second
        },
        {
          label: '8 ' + this.$t('HOURS'),
          value: "28800"  //second
        },
        {
          label: '9 ' + this.$t('HOURS'),
          value: "32400"  //second
        },
        {
          label: '10 ' + this.$t('HOURS'),
          value: "36000"  //second
        },
        {
          label: '11 ' + this.$t('HOURS'),
          value: "39600"  //second
        },
        {
          label: '12 ' + this.$t('HOURS'),
          value: "43200"  //second
        },
        {
          label: '13 ' + this.$t('HOURS'),
          value: "46800"  //second
        },
        {
          label: '14 ' + this.$t('HOURS'),
          value: "50400"  //second
        },
        {
          label: '15 ' + this.$t('HOURS'),
          value: "54000"  //second
        },
        {
          label: '16 ' + this.$t('HOURS'),
          value: "57000"  //second
        },
        {
          label: '17 ' + this.$t('HOURS'),
          value: "61200"  //second
        },
        {
          label: '18 ' + this.$t('HOURS'),
          value: "64800"  //second
        },
        {
          label: '19 ' + this.$t('HOURS'),
          value: "68400"  //second
        },
        {
          label: '20 ' + this.$t('HOURS'),
          value: "72000"  //second
        },
        {
          label: '21 ' + this.$t('HOURS'),
          value: "75600"  //second
        }, {
          label: '22 ' + this.$t('HOURS'),
          value: "79200"  //second
        }, {
          label: '23 ' + this.$t('HOURS'),
          value: "82800"  //second
        },
        {
          label: '24 ' + this.$t('HOURS'),
          value: "86400"  //second
        },
        {
          label: '2 ' + this.$t('days'),
          value: "172800"  //second
        },
        {
          label: '3 ' + this.$t('days'),
          value: "259200"  //second
        },
        {
          label: '4 ' + this.$t('days'),
          value: "345600"  //second
        },

        {
          label: '5 ' + this.$t('days'),
          value: "432000"  //second
        },
        {
          label: '6 ' + this.$t('days'),
          value: "518400"  //second
        },
        {
          label: '1 ' + this.$t('week'),
          value: "604800"
        },
        {
          label: '2 ' + this.$t('week'),
          value: "1209600"  //second
        },
        {
          label: '3 ' + this.$t('week'),
          value: "1814400"  //second
        },
        {
          label: '30 ' + this.$t('days'),
          value: "2592000"  //second
        },
        {
          label: '2 ' + this.$t('month'),
          value: "5184000"  //second
        },
        {
          label: '3 ' + this.$t('month'),
          value: "7776000"  //second
        },
        {
          label: '4 ' + this.$t('month'),
          value: "10368000"  //second
        },
        {
          label: '5 ' + this.$t('month'),
          value: "12960000"  //second
        },
        {
          label: '6 ' + this.$t('month'),
          value: "15552000"  //second
        },
        {
          label: '7 ' + this.$t('month'),
          value: "18144000"  //second
        },
        {
          label: '8 ' + this.$t('month'),
          value: "20736000"  //second
        },
        {
          label: '9 ' + this.$t('month'),
          value: "23328000"  //second
        },
        {
          label: '10 ' + this.$t('month'),
          value: "25920000"  //second
        },
        {
          label: '11 ' + this.$t('month'),
          value: "28512000"  //second
        },
        {
          label: '12 ' + this.$t('month'),
          value: "31104000"  //second
        },
        {
          label: '2 ' + this.$t('YEAR'),
          value: "62208000"  //second
        },

      ]
      ,
      selectedTrigger: ['all'],
      selectWalletLoading: false,
      tabModalEditKey: '1',
      searchTagLoading: false,
      searchTagValue: '',
      formConfig: {
        hasMail: false,
        selectedPage: 0,
        viralUrl: "",
        viralQRURL:"",
        smartcall: false,
        smartcall_trigger_id:""
      },
      flowContext: {},
      typeCondition: '',
      options:[ {value:'contain_phone_number'}],
      options2:[ {value:'contain_phone_number'}]
    }
  },
  created() {
    this.fetchFlows({page:1,page_size: 100})
    this.fetchTags({type: 'customer'})
    this.fetchCustomerCare()
    const pages = this.getPages.map((item: Page): string => {
      return item.page_id
    })
    const pageType = this.getPages.map((item: Page): number => {
      return item.page_type
    })
    this.fetchPostAllShop(pages,pageType)
    this.getPosts()
    this.getTags()
    this.fetchMailPages()
    this.fetchTreTags({payload: {type: "customer"}});
    if(this.version){
      return
    }
    this.loadData()



  },
  props: ['version'],
  methods: {
    ...mapActions({
      fetchTriggers: TriggerActionTypes.FETCH_TRIGGERS,
      fetchFlows: FlowActionTypes.FETCH_FLOWS,
      fetchTreTags: TagsActionTypes.FETCH_TREE_TAGS,
      fetchTags: TagsActionTypes.FETCH_TAGS,
      fetchPostAllShop: UserActionTypes.FETCH_POST_ALL_SHOP,
      fetchCustomerCare: CustomerCaresActionTypes.FETCH_CUSTOMERS_CARES,
      fetchMailPages: ShopinfoActionTypes.FETCH_MAIL_PAGE,
      fetchPages : PageConfigActionTypes.FETCH_PAGE_CONFIG,


    }),
    handleChangeTagModal(value: number[]) {
      this.selectTags = value;
      console.log("selectTags", value)
    },
    searchTag(val: string) {
      this.searchTagLoading = true
      if (searchTreeTimeout) {
        clearTimeout(searchTreeTimeout)
      }
      searchTreeTimeout = window.setTimeout(() => {
        this.fetchTreTags({
          payload: {type: "customer", search: val}, callback: (res: TreeTagsResponse) => {
            this.searchTagLoading = false
          }
        })
      }, 1100)

    },
    isNumber(n:any) { return /^-?[\d.]+(?:e-?\d+)?$/.test(n); },
    filterOption2(input: string,option: any){
          console.log(input, option)
          if( this.isNumber(input)){
            return option.key.page_id.toLowerCase().indexOf(input.toLowerCase()) >= 0;
          }else {

            return option.key.page_name.toLowerCase().indexOf(input.toLowerCase()) >= 0;
          }




    },
    conversationTags() {
      console.log('conversationTags')
      let page_id = ''
      let tags = []
      try {
        page_id = this.formState.customer_condition?.trigger_comparisons?.find((condition: any) => condition.field_type === 'page_id').value
        console.log('page_id', page_id)

        console.log(this.getPages.find((page: any) => page.page_id === page_id))
        tags = JSON.parse(this.getPages.find((page: any) => page.page_id === page_id).config_data).tags;
        console.log(tags)
      } catch (e) {
        console.log(e)
      }
      return tags
    },
    conversationTagsGroup(i:any,index:any) {
      console.log('conversationTags')
      let page_id = ''
      let tags = []
      try {
        page_id = this.formState.customer_condition?.trigger_comparisons[i].trigger_condition?.trigger_comparisons?.find((condition: any) => condition.field_type === 'page_id').value

        console.log(this.getPages.find((page: any) => page.page_id === page_id))
        tags = JSON.parse(this.getPages.find((page: any) => page.page_id === page_id).config_data).tags;
        console.log(tags)
      } catch (e) {
        console.log(e)
      }
      return tags
    },
    selectTriggerType(key: string) {
      this.selectedTrigger = [key]
      if (key === 'all') {
        this.triggerRequest.type = undefined;
      } else {
        this.triggerRequest.type = key;
      }
      this.loadData()
    },
    getPosts(search?: string) {
      this.posts = this.getPostsSelection(search);
    },
    getTags() {
      this.tags = this.getCustomerCares
    },
    fetchWallet() {
      if (this.formState.wallet_id) {
        this.selectWalletLoading = true
        APIs.init().getWalletId("vnd", this.formState.wallet_id).then((res) => {
          this.selectWalletLoading = false
          if (res.data?.status != 200) {
            message.error("Error API: " + res.data?.message)
          } else {
            this.formState.user_wallet_id = res.data.data.user_id
          }
        }).catch((e) => {
          this.selectWalletLoading = false
          console.error(e)
          message.error("Error API: " + JSON.stringify(e))
        })
      }

    },
    filterOption(input: string, option: any) {
      console.log(input, option)
      return option.key.label.toLowerCase().indexOf(input.toLowerCase()) >= 0;
    },
    searchPost(val: string) {
      this.getPosts(val)
    },
    selectFlowChange(val: string) {
      console.log("selectFlowChange", val)
    },
    flowHandleSearch(val: string) {
      if (flowHandleSearchTimeout) {
        clearTimeout(flowHandleSearchTimeout)
      }
      flowHandleSearchTimeout = window.setTimeout(() => {
        this.fetchFlows({search: val})
      }, 350)
    },
    deleteCustomerConditionRow(index: number) {
      if (this.formState.customer_condition && this.formState.customer_condition?.trigger_comparisons) {
        let conditions = this.formState.customer_condition?.trigger_comparisons
        delete conditions[index]
        this.formState.customer_condition.trigger_comparisons = this.formState.customer_condition?.trigger_comparisons?.filter((x: TriggerCustomerComparison) => x !== undefined)
      }
    },
    deleteCustomerConditionChidrenRow(idex:number,index:number) {
      if (this.formState.customer_condition && this.formState.customer_condition?.trigger_comparisons) {
        let conditions = this.formState.customer_condition.trigger_comparisons[index].trigger_condition.trigger_comparisons
        delete conditions[idex]
        this.formState.customer_condition.trigger_comparisons[index].trigger_condition.trigger_comparisons = this.formState.customer_condition?.trigger_comparisons[index]?.trigger_condition.trigger_comparisons.filter((x: TriggerCustomerComparison) => x !== undefined)
      }
    },

    getOrderStatusColor(status: number) {
      return MixUtils.methods.getOrderStatusColor(status)
    },
    getPostName(post: FacebookPostData) {
      if (post.message) {
        return post.message
      } else if (post.story) {
        return post.story
      } else if (post.attachments && post.attachments.length > 0 && post.attachments[0].title) {
        return post.attachments[0].title
      } else {
        return post.id
      }
    },
    getPostPicture(post: FacebookPostData) {
      if (post.full_picture) {
        return post.full_picture
      } else if (post.attachments && post.attachments.length > 0 && post.attachments[0].media && post.attachments[0].media.image && post.attachments[0].media.image.src) {
        return post.attachments[0].media.image.src
      } else {
        return "https://biglead.live/images/logo.png"
      }

    },
    mapTriggerCondition(trigger_id: string) {
      return {
        id: trigger_id,
        name: this.$t('TRIGGER_CONDITION_' + trigger_id.toLocaleUpperCase())
      }
    },
    getTriggerCondition(index: number) {
      try {
        let containPageId = false
        for (let i = 0; i < index; i++) {
          if (this.formState.customer_condition?.trigger_comparisons[i].field_type === 'page_id') {
            containPageId = true
            break
          }
        }

        if (containPageId) {
          return this.getFlowConstants?.trigger_condition.trigger_condition_field_types.map((id: string) => this.mapTriggerCondition(id))
        } else {
          return this.getFlowConstants?.trigger_condition.trigger_condition_field_types.filter((condition: any) => condition !== 'conversation_tag').map((id: string) => this.mapTriggerCondition(id))
        }
      } catch (e) {
        console.log(e)
        return this.getFlowConstants?.trigger_condition.trigger_condition_field_types.map((id: string) => this.mapTriggerCondition(id))
      }
    },
    getEmployeeName(payload: number): string {
      if (this.getCurrentShop && this.getCurrentShop.employees) {
        const employee = this.getCurrentShop.employees.find((x: Employee) => x.id === payload);
        return employee ? employee.name : "";
      }
      return "";
    },
    loadData() {
      if (this.searchInput) {
        this.triggerRequest.search = this.searchInput
      } else if (this.selectedType) {
        this.triggerRequest.type = this.selectedType
      } else if (this.selectedstatus) {
        this.triggerRequest.status = this.selectedstatus
      }

      if (this.selectedTags && this.selectedTags.length) {
        this.triggerRequest.tags = this.selectedTags.join(",")
      } else {
        this.triggerRequest.tags = undefined
      }
      this.fetchTriggers(this.triggerRequest).then();
    },
    newTrigger() {
      this.amountSent = undefined
      this.modalCreateVisible = true;
      this.formState = {
        customer_condition: {
          condition: '',
          trigger_comparisons: []
        }
      }
    },
    async fetchFlowContext(id: number) {

      const response = await APIs.init().getFlowV2Context({id: id})
      if (response.ok && response.data?.data) {
        this.flowContext = response.data.data
        if (this.flowContext.context && this.flowContext.context.flow_contexts) {
          const hasMail = this.flowContext.context.flow_contexts.find((x: FlowContext) => x.flow_type === 5)
          if (hasMail) {
            this.formConfig.hasMail = true
          }
          if (this.flowContext.context.flow_contexts.length > 0 && this.flowContext.context.flow_contexts[0].flow_viral
              && this.flowContext.context.flow_contexts[0].flow_viral.length
              && this.flowContext.context.flow_contexts[0].flow_type === 6) {
            const res = await APIs.init().getTriggerInviteLink({trigger_id: this.trigger_id,flow_id:this.flowContext.id,flow_context_id:this.flowContext.context.flow_contexts[0].id,flow_message_id:this.flowContext.context.flow_contexts[0].flow_viral[0].id})
            this.formConfig.viralUrl = res.data?.data
            QRCode.toDataURL(this.formConfig.viralUrl )
                .then(url => {
                  this.formConfig.viralQRURL = url
                })
                .catch(err => {
                  console.error(err)
                })
          }
          if(this.flowContext.context.flow_contexts.length > 0
              && this.flowContext.context.flow_contexts[0].flow_type === 7){
            this.formConfig.smartcall = true
          }
        }
      }
    },
    getCondition(conditon:any,fieldType:any,index:number,idex:number) {
      const conditons:any[] = []
    if(fieldType === 'page_id' || fieldType === 'messenger_window_24h' || fieldType === 'has_phone_number'   || fieldType === 'last_contact_time' || fieldType === 'first_contact_time' || fieldType ==='care_status' || fieldType === 'post_id'){
       conditon.map((item:any) => {
        if (item === 'EQUAL' ){
          conditons.push(item)
        } else if (item === 'NOT_EQUAL'){
          conditons.push(item)
        }


      })


    }else if(fieldType === 'tag' || fieldType === 'conversation_tag' || fieldType == 'order_status' ||fieldType === 'key_word'  ){
      conditon.map((item:any) => {
        if (item === 'CONTAIN' ){
          conditons.push(item)
        } else if (item === 'NOT_CONTAIN'){
          conditons.push(item)
        }


      })


    } else if(fieldType === 'timeout'){
      conditon.map((item:any) => {
        if (item === 'EQUAL' ){
          conditons.push(item)
        }else if(item === 'GREATER_THAN'){
          conditons.push(item)
        }


      })

    } else {
      conditon.map((item:any) => {
    conditons.push(item)


      })

    }
    // else if( fieldType === 'key_word'){
    //     conditon.map((item:any) => {
    //       if (item === 'EQUAL' ){
    //         conditons.push(item)
    //       } else if (item === 'NOT_EQUAL'){
    //         conditons.push(item)
    //       } else if (item === 'CONTAIN' ){
    //         conditons.push(item)
    //       }
    //
    //
    //     })
    //   }
    // console.log(conditons)
    return conditons

    },
    customRowClick(trigger: Trigger) {
      this.$router.push({name: RouterEndpoint.AUTOMATION_TRIGGER_DETAIL.name, params: {id: trigger.id}})
    },
    editFlow(trigger: Trigger) {
      this.$router.push({name: RouterEndpoint.FLOW_CONTEXT.name, params: {id: trigger.root_flow_id}})
    },
    goHistory(trigger: Trigger) {
      this.$router.push({name: RouterEndpoint.TRIGGER_HISTORY.name, query: {trigger_id: trigger.id}})
    },
    onSearchChange() {
      if (inputChangeTimeout) {
        clearTimeout(inputChangeTimeout)
      }
      inputChangeTimeout = window.setTimeout(() => {

        this.loadData()
      }, 500)
    },
    handleTableChange(pagination: PaginationProps) {
      this.triggerRequest.page = pagination?.current
      this.triggerRequest.page_size = pagination?.pageSize
      this.loadData()
    },
    toggleTag(tag: Tag) {
      const tagFound = this.selectedTags.find((x: number) => x === tag.id)
      console.log("toggleTag", tagFound)
      if (tagFound) {
        this.selectedTags = this.selectedTags.filter((x: number) => x !== tag.id)
        console.log("found", this.selectedTags)
      } else {
        this.selectedTags.push(tag.id)
      }
      this.loadData()
    },
    handleSelectWallet(val: number) {
      this.selectWalletLoading = true
      APIs.init().getWalletUid("vnd", val).then((res) => {
        this.selectWalletLoading = false
        if (res.data?.status != 200) {
          message.error("Error API: " + res.data?.message)
        } else {
          this.formState.wallet_id = res.data.data._id
          if (res.data.data?.balance <= 0) {
            message.warning("The wallet is not enough! current balance: " + res.data.data?.balance)
          }
        }
      }).catch(e => {
        this.selectWalletLoading = false
        console.error(e)
        message.error("Error API: " + JSON.stringify(e))
      })
    },
    addParentCondition() {
      this.typeCondition = 'condition'
      if (!this.formState.customer_condition) {
        this.formState.customer_condition = {}
      }
      if (!this.formState.customer_condition.trigger_comparisons) {
        this.formState.customer_condition.trigger_comparisons = []
      }else {
        this.formState.customer_condition.trigger_comparisons.push({condition:" "})
      }
      console.log( this.formState.customer_condition.trigger_comparisons,'111')
    },
    addGroupCondition(){
      this.typeCondition = 'conditionGroup'
      if (!this.formState.customer_condition) {
        this.formState.customer_condition = {}
      }
      if (!this.formState.customer_condition.trigger_comparisons) {
        this.formState.customer_condition.trigger_comparisons = []
      }else {
        this.formState.customer_condition.trigger_comparisons.push({trigger_condition:{}})
      }

    },
    addChildrenCondition(index: any){
     if(!this.formState.customer_condition.trigger_comparisons[index].trigger_condition.trigger_comparisons){
       this.formState.customer_condition.trigger_comparisons[index].trigger_condition.trigger_comparisons = []
     }
      this.formState.customer_condition.trigger_comparisons[index].trigger_condition.trigger_comparisons.push({condition:" "})
      console.log(this.formState.customer_condition.trigger_comparisons[index].trigger_condition.trigger_comparisons)

    },
    onSubmit() {
  console.log(this.getAdminAndShopOwner)
      this.amountSent = undefined
      this.$refs.formRef.validate().then(() => {
        this.formState.customer_condition.trigger_comparisons = this.formState.customer_condition.trigger_comparisons.map((item: TriggerCustomerComparison)=>{


          if(item.field_type && item.condition){

            if(item.field_type === 'last_contact_time' || item.field_type === 'first_contact_time' || item.field_type === "DATE" || item.field_type === 'key_word'){
              if(item.value)
                return {condition:item.condition,field_type: item.field_type,value: [...item.value].join('|')}
            }else if(item.field_type == 'has_phone_number'  ) {

              return item
            }else if(item.field_type == 'conversation_tag'  ) {
              // @ts-ignore
              item.value = item.coverstion_tag.map((tag) => {
                // @ts-ignore
                tag = tag.split("_")[1]
                return tag
              }).join(",")
              console.log(item.value,'11111')
            }else {
              return item
            }
            return item
          }else if(item.trigger_condition && item.trigger_condition.trigger_comparisons){
            item.trigger_condition.trigger_comparisons = item.trigger_condition.trigger_comparisons.map((i: any)=>{
              if(i.field_type && i.condition){

                if(i.field_type === 'last_contact_time' || i.field_type === 'first_contact_time' || i.field_type === "DATE" || i.field_type === 'key_word'){
                  if(i.value )
                    console.log({condition:i.condition,field_type: i.field_type,value: [...i.value].join('|')})
                    return {condition:i.condition,field_type: i.field_type,value: [...i.value].join('|')}
                }else if(i.field_type == 'has_phone_number'  ) {
                  return i
                }else if(i.field_type == 'conversation_tag') {

                  // @ts-ignore
                  i.value = i.coverstion_tag.map((tag) => {
                    // @ts-ignore
                    tag = tag.split("_")[1]
                   return tag
                  }).join(",")

                }else {
                  return i
                }
              }
              return i
            })
               return item
          }
               return item
        })
        // this.formState.customer_condition.trigger_comparisons =
        //     this.formState.customer_condition.trigger_comparisons.filter((item: TriggerCustomerComparison) => item.condition && item.field_type && (item.field_type == 'has_phone_number' || item.value))
        // // if (this.formState.customer_condition.trigger_comparisons.length > 0) {
        // // this.rules.trigger_condition.isValid = true;

        let formState = {...this.formState}
        formState.root_flow_id = parseInt(formState.root_flow_id)

        if (formState.tags) {
          formState.tags = formState.tags.map((item: number) => {
            return this.getTriggerTags?.find((tag: Tag) => tag.id == item)
          }).filter((item: Tag) => item)
        }
        if (formState.time_start_m) {
          formState.time_start = formState.time_start_m
        }
        this.confirmTriggerLoading = true
        if (!formState.wallet_id) {
          formState.wallet_id = this.getWallet?._id
        }
        if (this.formConfig.selectedPage) {
          formState.config = {
            pages: this.getMailPage.filter((x: Page) => x.id === this.formConfig.selectedPage)
          }
        }
        if (this.formConfig.smartcall_trigger_id) {
          formState.config = {
            smartcall_trigger_id: this.formConfig.smartcall_trigger_id
          }
        }
        if (formState.id) {
          APIs.init().updateTrigger(formState).then(res => {
            this.confirmTriggerLoading = false
            if (res.status === 200 && res.data?.status === 200) {
              this.modalCreateVisible = false
              this.loadData();
            }
          }).catch(e => {
            this.confirmTriggerLoading = false
            console.error(e)
          })
        } else {
          APIs.init().createTrigger(formState).then(res => {
            this.confirmTriggerLoading = false
            if (res.status === 200 && res.data?.status === 200) {
              this.modalCreateVisible = false
              this.loadData();
            }
          }).catch(e => {
            this.confirmTriggerLoading = false
            console.error(e)
          })
        }
        console.log("form", formState);
        // } else {
        //   // this.rules.trigger_condition.isValid = false
        // }
        // } else {
        //   // this.rules.trigger_condition.isValid = false
        // }
      }).catch((e: ValidateErrorEntity) => {
        console.error(e)
      })
    },
    deleteTrigger(trigger: Trigger) {
      message.loading({content: 'Deleting Trigger: ' + trigger.name, key: trigger.id});
      if (!trigger.id) {
        trigger.id = 0
      }
      APIs.init().deleteTrigger(trigger.id).then(res => {
        if (res.status === 200 && res.data?.status === 200) {
          message.success({content: 'Deleted Trigger: ' + trigger.name + '!', key: trigger.id, duration: 2});
          this.loadData()
        } else {
          message.destroy()
        }
      }).catch(e => {
        message.destroy()
        console.error(e)
      })
    },
    async activateTriggerModal(trigger: Trigger) {
      console.log(trigger)
      const response = await APIs.init().getFlowV2Context({id: Number(trigger.root_flow_id)})
      if (response.ok && response.data?.data) {
        this.flowContext = response.data.data
        if (this.flowContext.context && this.flowContext.context.flow_contexts) {
                if(this.flowContext.context.flow_contexts[0].flow_type === 5  ) {
                  if(trigger.config?.pages !== null){
                  const onOk = () => {
                    APIs.init().activateTrigger(trigger.id ? trigger.id : 0).then(res => {
                      if (res.status === 200) {
                        setTimeout(() => {
                          this.loadData()
                        }, 500)
                      }
                    }).catch(e => {
                      console.error(e)
                    })
                    console.log('OK');
                  }
                  Modal.confirm({
                    title: () => this.$t('TRIGGER_TITLE_CONFIRM_ACTIVATE'),
                    icon: () => createVNode(ExclamationCircleOutlined),
                    closable: true,
                    maskClosable: true,
                    content: () => createVNode('div', {style: 'color:red;'}, this.$t('TRIGGER_CONTENT_CONFIRM_ACTIVATE')),
                    onOk,
                    onCancel() {
                      console.log('Cancel');
                    },
                  });
                }else {
                  Modal.confirm({
                    title: () => this.$t('you have not selected email'),
                    icon: () => createVNode(ExclamationCircleOutlined),
                    closable: true,
                    maskClosable: true,
                    onOk(){
                      console.log('Cancel');
                    },
                    onCancel() {
                      console.log('Cancel');
                    },
                  })}
                }else {
                  const onOk = () => {
                    APIs.init().activateTrigger(trigger.id ? trigger.id : 0).then(res => {
                      if (res.status === 200) {
                        setTimeout(() => {
                          this.loadData()
                        }, 500)
                      }
                    }).catch(e => {
                      console.error(e)
                    })
                    console.log('OK');
                  }
                  Modal.confirm({
                    title: () => this.$t('TRIGGER_TITLE_CONFIRM_ACTIVATE'),
                    icon: () => createVNode(ExclamationCircleOutlined),
                    closable: true,
                    maskClosable: true,
                    content: () => createVNode('div', {style: 'color:red;'}, this.$t('TRIGGER_CONTENT_CONFIRM_ACTIVATE')),
                    onOk,
                    onCancel() {
                      console.log('Cancel');
                    },
                  });

                }
        }}


    },
    hideModal() {
      this.amountSent = undefined
      this.modalCreateVisible = false
    },
    stopTriggerModal(trigger: Trigger) {
      const onOk = () => {
        APIs.init().stopTrigger(trigger.id ? trigger.id : 0).then(res => {
          if (res.status === 200) {
            this.loadData()
          }
        }).catch(e => {
          console.error(e)
        })
        console.log('OK');
      }
      Modal.confirm({
        title: this.$t('TRIGGER_TITLE_CONFIRM_STOP'),
        closable: true,
        maskClosable: true,
        onOk,
        onCancel() {
          console.log('Cancel');
        },
      });
    },
    getAmountSent(id: number) {
      if(this.status !== "running" || !this.status){
        if(id){
          APIs.init().updateTrigger(this.formState).then(res => {
            this.confirmTriggerLoading = false
            if (res.status === 200 && res.data?.status === 200) {
              this.loadData();
              APIs.init().getTriggerEstimate({trigger_id:id}).then(res => this.amountSent =res.data?.data).catch(e => console.log(e))

            }
          }).catch(e => {
            this.confirmTriggerLoading = false
            console.error(e)
          })
        }else {
          this.formState.time_start_m = dayjs()
          if(this.formState.customer_condition.condition === ""){
            this.formState.customer_condition.condition = "AND"
          }

          this.formState.customer_condition.trigger_comparisons = this.formState.customer_condition.trigger_comparisons.map((item: TriggerCustomerComparison)=>{
            if(item.field_type && item.condition ){
              if(item.field_type === 'last_contact_time' || item.field_type === 'first_contact_time' || item.field_type === 'DATE'){
                if(item.value)
                  return {condition:item.condition,field_type: item.field_type,value: [...item.value].join('|')}
              }else if(item.field_type == 'has_phone_number' || item.value ) {
                return item
              }else if(item.field_type == 'conversation_tag'  ) {
                // @ts-ignore
                item.value = item.coverstion_tag.map((tag) => {
                  // @ts-ignore
                  tag = tag.split("_")[1]
                  return tag
                }).join(",")
                console.log(item.value,'11111')
              }else {
                return item
              }
              return item
            }else if(item.trigger_condition && item.trigger_condition.trigger_comparisons){
              item.trigger_condition.trigger_comparisons = item.trigger_condition.trigger_comparisons.map((i: any)=>{
                if(i.field_type && i.condition ){
                  if(i.field_type === 'last_contact_time' || i.field_type === 'first_contact_time' || i.field_type === 'DATE'){
                    if(i.value)
                      return {condition:i.condition,field_type: i.field_type,value: [...i.value].join('|')}
                  }else if(i.field_type == 'has_phone_number' ) {
                    return i
                  }else if(i.field_type == 'conversation_tag') {

                    // @ts-ignore
                    i.value = i.coverstion_tag.map((tag) => {
                      // @ts-ignore
                      tag = tag.split("_")[1]
                      return tag
                    }).join(",")

                  }else {
                    return i
                  }
                  return i
                }
              })
              return item
            }
            return item
          })
          // if (this.formState.customer_condition.trigger_comparisons.length > 0) {
          // this.rules.trigger_condition.isValid = true;
          let formState = {...this.formState}
          formState.root_flow_id = parseInt(formState.root_flow_id)
          APIs.init().createTrigger(formState).then(res => {
            this.confirmTriggerLoading = false
            if (res.status === 200 && res.data?.status === 200) {
              APIs.init().getTriggerEstimate({trigger_id:res.data?.data.id}).then(res => this.amountSent =res.data?.data).catch(e => console.log(e))
              this.customRowClick(res.data?.data)
              this.loadData();
            }
          }).catch(e => {
            this.confirmTriggerLoading = false
            console.error(e)
          })
        }

      }else {
        APIs.init().getTriggerEstimate({trigger_id:id}).then(res => this.amountSent =res.data?.data).catch(e => console.log(e))

      }

    },
    getPages() {
      console.log(this.getUserInfo.shops.find((shop: any) => shop.id === this.getUserInfo.current_shop_id))
      return this.getUserInfo.shops.find((shop: any) => shop.id === this.getUserInfo.current_shop_id)?.pages
    },
    saleTags() {
      let tags = []
      try {
        tags = JSON.parse(this.currentPage.config_data)?.tags;
        console.log(tags)
      } catch (e) {
        console.log(e)
      }
      return tags
    },
    handleChangePages(value: any) {
      this.currentPage = this.getPages.find((page: any) => page.page_id === value)
      console.log(this.currentPage)
    },
    handleChangeTags(tags: number[]) {
      console.log('tags', tags)
      this.selectedTags = tags
      this.loadData()
    },
    handleChangeType(type: string) {
      if(type === "BULK_MESSAGE"){
        this.formState.frequency_type ='now'
      }
    },
    handleChangeStatus(status: string) {
      this.selectedstatus = status
      this.loadData()
    },
    getMyRole(key: string) {
      if(this.userInfo.id_cms_privileges<11){
        return {
          is_visible: 1,
          is_create:1,
          is_read: 1,
          is_edit: 1,
          is_delete: 1,
          id: 1
        }
      }else{
        return this.getRole(key);
      }
    },
  }
})
export default class Triggers extends Vue {
}

</script>

<style lang="scss" scoped>
.container {
  margin: 10px 15px;
}

.left-layout {
  padding-right: 14px;

  .tag-group {
    padding: 10px 4px;
  }

  .button-align-left {
    text-align: left;
  }

}

.row-horizontal {
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;


}

.input-search {
  margin-right: 12px;
  margin-bottom: 8px;
}

.table-row-name {
  font-weight: bold;
}

.flow-table {
  background-color: white;
  padding-left: 12px;
}

.row-full-width {
  width: 100%;
  margin-bottom: 10px;
}

.row-horizontal {
  display: flex;
  flex-direction: row;

  .column-fix-1 {
    flex: 1;
  }
}

.condition-alert {
  margin-top: 4px;
  color: red;
}

.item-trigger-table-small {
  font-size: 13px;
}

.modal-text-warning-running-status {
  margin-right: 10px;
  color: red;
}

.table-header {
  display: flex;
  align-items: center;
  width: 100%;
  padding-left: 10px;
  background-color: white;
  padding-right: 10px;
  padding-top: 12px;
}

.container-filter {
  display: flex;
  gap: 16px;
}

.table-title {
  align-items: center;
  padding-right: 14px;
  flex: 1;
}

.horizontal-form {
  width: 100%;
}

.header-trigger-filter {
  background-color: white;
}
</style>
