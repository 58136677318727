<template>
  <a-modal
    v-model:visible="visible"
    :title="$t('SELECT_COLUMN_SHOW')"
    @ok="save"
  >
    <div class="form-modal form-selection" style="text-align: center">
      <h5>
        {{ $t("DISPLAY_COLUMN_LIMIT") }} {{ colNumber }}
        {{ $t("TIP_SELECT_COLUMN") }}
      </h5>
      <a-select
        @change="optionChange"
        style="width: 50%"
        v-model:value="colNumber"
        :placeholder="$t('SELECT_COLUMN_SHOW')"
      >
        <a-select-option v-for="item in 10" :key="item" :value="item"
          >{{ $t("DISPLAY") }} {{ item }} {{ $t("COLUMN") }}</a-select-option
        >
      </a-select>
      <a-button style="margin-left: 10px" @click="showExtraColumn">{{
        $t("EXTRA_COLUMN")
      }}</a-button>
      <a-row>
        <a-col :span="4" style="padding-right: 20px">
          <a-button
            v-for="count in colNumber"
            :key="count"
            style="margin-top: 10px; border-radius: 50px"
            size="large"
            block
            class="button-flow-context right-button-content"
          >
            <span class="center-button-content">
              {{ count }}
            </span>
          </a-button>
        </a-col>
        <a-col :span="20">
          <Container
            orientation="vertical"
            group-name="col-items"
            :drop-placeholder="{
              className: `drop-placeholder`,
              animationDuration: '200',
              showOnTop: true,
            }"
            drag-class="
            transition duration-100 ease-in z-50
            transform rotate-6 scale-110"
            drop-class="transition duration-100
            ease-in z-50 transform
            -rotate-2 scale-90"
            @drop="(e) => onCardDrop(e)"
          >
            <Draggable v-for="item in columns" :key="item.id">
              <a-button
                style="margin-top: 10px; border-radius: 50px"
                size="large"
                block
                class="button-flow-context right-button-content"
              >
                <span class="center-button-content">
                  {{ $t(item.myTitle) }}
                </span>
              </a-button>
            </Draggable>
          </Container>
        </a-col>
      </a-row>
    </div>
  </a-modal>
  <a-modal :width="1000" v-model:visible="visibleCreateConfig" @ok="addConfig">
    <template #title>
      <a-button @click="handleAddConfig">{{ $t("ADD") }}</a-button>
    </template>
    <a-form ref="formRef" :model="config" :rules="rules" v-if="CreateConfig">
      <a-form-item name="name" :label="$t('NAME')">
        <a-input @change="changeName" v-model:value="config.name" />
      </a-form-item>
      <a-form-item :label="$t('identified_name')">
        <a-input v-model:value="config.identified_name" />
      </a-form-item>
      <a-form-item name="type" :label="$t('FIELD_TYPE')">
        <a-radio-group v-model:value="config.field_type">
          <a-radio value="STRING">{{ $t("TEXT") }}</a-radio>
          <a-radio value="INT">{{ $t("number") }}</a-radio>
          <a-radio value="DATE">{{ $t("DATE") }}</a-radio>
          <a-radio value="CURRENCY">{{ $t("CURRENCY") }}</a-radio>
        </a-radio-group>
      </a-form-item>
      <!--      <div v-if="config.field_type">-->
      <!--        <a-form-item v-if="config.field_type === 'STRING'" :label="$t('value')">-->
      <!--          <a-input v-model:value="config.customer_column_value"/>-->
      <!--        </a-form-item>-->
      <!--        <a-form-item v-else-if="config.field_type === 'DATE'"  :label="$t('value')">-->
      <!--          <a-date-picker style="width: 100%" v-model:value="config.customer_column_value" format="DD-MM-YYYY" valueFormat="DD-MM-YYYY" />-->
      <!--        </a-form-item>-->
      <!--        <a-form-item v-else  :label="$t('value')">-->
      <!--          <a-input-number style="width: 100%" v-model:value="config.customer_column_value" :min="1" :max="100000" />-->
      <!--        </a-form-item>-->
      <!--      </div>-->
    </a-form>
    <a-table
      v-else
      :dataSource="listConfig"
      :columns="columnConfig"
      class="flow-table"
    >
      <template
        v-for="col in ['name', 'identified_name']"
        #[col]="{ text, record }"
        :key="col"
      >
        <div>
          <a-input
            v-if="editableData[record.id]"
            v-model:value="editableData[record.id][col]"
            style="margin: -5px 0"
          />
          <template v-else>
            {{ text }}
          </template>
        </div>
      </template>
      <template #field_type="{ record }">
        <a-typography-text v-if="record.field_type === 'STRING'">{{
          $t("TEXT")
        }}</a-typography-text>
        <a-typography-text v-if="record.field_type === 'INT'">{{
          $t("number")
        }}</a-typography-text>
        <a-typography-text v-if="record.field_type === 'DATE'">{{
          $t("DATE")
        }}</a-typography-text>
        <a-typography-text v-if="record.field_type === 'CURRENCY'">{{
          $t("CURRENCY")
        }}</a-typography-text>
      </template>
      <template #action="{ record }">
        <div
          v-if="
            record.identified_name !== 'points' &&
            record.identified_name !== 'rank'
          "
          class="editable-row-operations"
        >
          <span v-if="editableData[record.id]">
            <a @click="saveConfig(record.name, record.id)">Save</a>
            <a @click="cancel(record.id)" style="margin-left: 8px">Cancel</a>
          </span>
          <span v-else>
            <a v-on:click="edit(record.id)">Edit</a>
            <a-popconfirm title="Sure to delete?" @confirm="remove(record)">
              <a style="margin-left: 8px">Delete</a>
            </a-popconfirm>
          </span>
        </div>
      </template>
    </a-table>
  </a-modal>
</template>

<script>
import { Options, Vue } from "vue-class-component";
import { Container, Draggable } from "vue3-smooth-dnd";
import { mapActions, mapGetters, mapMutations } from "vuex";
import { CustomersMutationTypes } from "@/store/modules/customer/mutation-types";
import APIs from "@/APIs/APIs";
import { message } from "ant-design-vue";
import { MixUtils } from "@/utils/Utils";
import { CustomersActionTypes } from "@/store/modules/customer/action-types";

@Options({
  name: "columns-visibility-option-modal",
  data() {
    return {
      colNumber: 9,
      visible: false,
      columns: [],
      visibleCreateConfig: false,
      editableData: {},
      config: {
        id: 0,
        name: "",
        field_type: "STRING",
        identified_name: "",
      },
      listConfig: [],
      CreateConfig: false,
      checkEtraColumn: false,

      columnConfig: [
        {
          title: this.$t("NAME"),
          dataIndex: "name",
          key: "name",
          slots: { customRender: "name" },
        },
        {
          title: this.$t("identified_name"),
          dataIndex: "identified_name",
          key: "identified_name",
          slots: { customRender: "identified_name" },
        },
        {
          title: this.$t("FIELD_TYPE"),
          dataIndex: "field_type",
          key: "field_type",
          slots: { customRender: "field_type" },
        },
        {
          title: this.$t("TAG_ACTION"),
          key: "action",
          slots: { customRender: "action" },
        },
      ],
    };
  },
  created() {
    this.listConfig = this.getCustomerConfig;
  },
  components: {
    Container,
    Draggable,
  },
  computed: {
    ...mapGetters({
      getColumns: "getColumns",
      getDefaultColumns: "getDefaultColumns",
      getVisibleColumns: "getVisibleColumns",
      getVisibleColumnsCount: "getVisibleColumnsCount",
      getCurrentShop: "getCurrentShop",
      getCustomerConfig: "getCustomerConfig",
    }),
    rules() {
      let rules = {
        name: [
          {
            required: true,
            message: this.$t("VALIDATE_DISPLAY_NAME"),
            trigger: "blur",
          },
        ],
      };

      return rules;
    },
  },

  methods: {
    ...mapMutations({
      setColumns: CustomersMutationTypes.SET_Columns,
      setCustomerColumns: CustomersMutationTypes.SET_CUSTOMER_CONFIG,
      reformatColumn: CustomersMutationTypes.REFORMAT_COLUMNS,
    }),
    ...mapActions({
      fetchCustomerConfig: CustomersActionTypes.FETCH_CUSTOMER_CONFIG,
    }),
    save() {
      this.columns = this.columns.map((item, index) => {
        const col = this.getDefaultColumns.find((x) => x.id === item.id);
        if (col) {
          item = col;
        }
        item.visible = index < this.colNumber;
        return item;
      });
      console.log("columns", this.columns);

      for (let i = 0; i < this.columns.length; i++) {
        if (this.columns[i].id === 99 && this.columns[i].visible === true) {
          this.$parent.customerRequest.hotel_tag = true;
          break;
        } else {
          this.$parent.customerRequest.hotel_tag = false;
        }
      }
      for (let i = 0; i < this.getColumns.length; i++) {
        if (!this.checkEtraColumn) {
          for (let j = 0; j < this.getCustomerConfig?.length; j++) {
            if (
              this.getColumns[i].dataIndex === this.getCustomerConfig[j].id &&
              this.getColumns[i].visible === true
            ) {
              console.log(true);
              this.checkEtraColumn = true;
              this.$parent.customerRequest.extra_column = true;
              this.$parent.loadCustomer();
              break;
            } else {
              console.log(false);
              this.$parent.customerRequest.extra_column = false;
            }
          }
        } else {
          break;
        }
      }
      this.setColumns([
        ...this.columns,
        {
          id: 10,
          slots: { title: "customerTitle", customRender: "action" },
          visible: true,
        },
      ]);
      this.visible = false;
    },
    changeName(e) {
      this.config.identified_name = MixUtils.methods.removeVietnamese2(
        e.target.value
      );
    },
    showExtraColumn() {
      this.listConfig = this.getCustomerConfig;
      this.visibleCreateConfig = true;
    },
    onCardDrop(e) {
      if (
        e.removedIndex != null &&
        e.addedIndex != null &&
        e.removedIndex !== e.addedIndex
      ) {
        const rmCol = this.columns[e.removedIndex];
        this.columns.splice(e.removedIndex, 1);
        this.columns.splice(e.addedIndex, 0, rmCol);
        console.log(this.columns, "2", rmCol);
      }
      console.log("onCardDrop", e);
    },
    optionChange(e) {
      console.log("optionChange", e);
    },
    showModal() {
      const columns = this.getColumns;
      const defaultColumns = this.getDefaultColumns;
      const extraColumn = this.getCustomerConfig;
      if (columns.length !== defaultColumns.length + extraColumn.length) {
        this.setColumns(defaultColumns);
        this.setCustomerColumns(extraColumn);
        this.reformatColumn();
      }

      this.colNumber = this.getVisibleColumnsCount;
      this.columns = this.getColumns;
      this.visible = true;
    },
    handleCustomerConfig(title, data) {
      this.visibleCreateConfig = true;
      this.config = data
        ? data
        : { field_type: "", name: "", customer_column_value: "" };
      this.titleConfig = title;
    },

    addConfig() {
      if (this.CreateConfig) {
        this.$refs.formRef.validate().then(() => {
          this.visibleCreateConfig = false;
          this.createCustomerConfig();
          this.CreateConfigcreateCustomerConfig = false;
          this.columns = [
            ...this.columns,
            {
              dataIndex: this.config.name,
              id: this.config.id,
              myTitle: this.config.name,
              title: this.config.name,
              slots: { customRender: this.config.name },
              visible: false,
              width: "12%",
            },
          ];
          this.config = {
            id: 0,
            name: "",
            field_type: "",
            customer_column_value: "",
            identified_name: "",
          };
          this.CreateConfig = false;
        });
      } else {
        this.visibleCreateConfig = false;
      }
    },
    async createCustomerConfig() {
      if (
        this.config.field_type === "INT" &&
        this.config.field_type === "CURRENCY"
      ) {
        this.config.customer_column_value =
          this.config.customer_column_value + "";
      }
      const request = {
        shop_id: this.getCurrentShop.id,
        customer_column_value: this.config.customer_column_value,
        name: this.config.name,
        field_type: this.config.field_type,
        id: this.config.id,
        identified_name: this.config.identified_name,
      };
      const res = await APIs.init().addCustomerConfig(request);
      if (res.status === 200) {
        message.success("create customer config success");
        this.fetchCustomerConfig({ shop_id: this.getCurrentShop.id });
        this.showConfig = false;
        // showCustomerConfigValue.value = 0
        // onSubmit()
      } else {
        message.error("error");
      }
    },
    handleAddConfig() {
      this.CreateConfig = true;
      this.config.field_type = "STRING";
    },
    async saveConfig(key, id) {
      console.log(key);
      // console.log("save", key)
      let copyConfig = [...this.listConfig];
      Object.assign(
        copyConfig.filter((item) => id === item.id)[0],
        this.editableData[id]
      );
      const config = copyConfig.find((x) => x.id === id);
      if (config) {
        const request = {
          shop_id: this.getCurrentShop.id,
          name: config.name,
          id: config.id,
          identified_name: config.identified_name,
        };
        const res = await APIs.init().UpdateCustomerConfig(request);
        if (res.status === 200) {
          message.success("create customer config success");
          this.fetchCustomerConfig({ shop_id: this.getCurrentShop.id });
        } else {
          message.error("error");
        }
      }
      console.log(this.config);
      this.columns = this.columns.map((item) => {
        if (item.dataIndex === id) {
          item.title = config.name;
          item.myTitle = config.name;
        }
        return item;
      });
      delete this.editableData[id];
    },
    edit(key) {
      this.editableData[key] = {
        ...this.listConfig.filter((item) => key === item.id)[0],
      };
      console.log(this.editableData[key]);
    },
    cancel(key) {
      delete this.editableData[key];
    },
    remove(key) {
      APIs.init()
        .DeleteCustomerConfig(key)
        .then((res) => {
          if (res.data.status === 200) {
            message.success("delete success");
            this.listConfig = this.listConfig.filter(
              (item) => item.id !== key.id
            );
            this.columns = this.columns.filter((item) => item.id !== key.id);
            this.fetchCustomerConfig({ shop_id: this.getCurrentShop.id });
          }
        });
    },
  },
})
export default class ColumnsVisibilityOptionModal extends Vue {}
</script>

<style scoped lang="scss">
.right-button-content {
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;
  margin-top: 10px;
  border-radius: 50px;

  .center-button-content {
    display: flex;
    flex: 1;
    padding-bottom: 5px;
    margin-left: 10px;
  }
}

.button-flow-context {
  text-align: left;
  margin-top: 10px;
  border-radius: 50px;
}
</style>
