<template>
  <a-layout-content>
    <div class="container-title">
      <h3 class="title-header">{{$t($route.name)}}</h3>
      <a-select
          v-model:value="selectedPage"
          @change="handlePageChange"
          :allowClear="true"
          style="width: 350px; margin-right: 16px"
          :placeholder="$t('ALL')"
      >
        <a-select-option
            v-for="item in getPages.overView"
            :key="item.id"
            v-bind:value="item.page_id"
        >{{ item.page_name }}
        </a-select-option>
      </a-select>
      <a-range-picker
          v-model:value="currentRangeDate"
          format="DD-MM-YYYY"
          @change="dateRangeChange"
      />

    </div>
    <div style="width: 100%">
    <a-card class="card_view" style="border-radius: 10px; margin: 4px;">
      <a-row>
        <div style="width: 50%">
          <keep-alive>
            <highcharts class="chart" :options="{
      chart: {
        type: 'column',
          inverted: true
      },
      title: {
        text: $t('NUMBER_CONVERSATION_HANDLED')
      },
      xAxis: {
        categories: chartData.labelCurrent
      },
      yAxis: {
        min: 0,
        title: {
          text: null
        },
      },
      legend: {
        align: 'right',
        x: -30,
        verticalAlign: 'top',
        y: 25,
        borderColor: '#CCC',
        borderWidth: 1,
        shadow: false,
        enabled:false
      },
      tooltip: {
        headerFormat: '<b>{point.x}</b><br/>',
        enable: false
      },
      plotOptions: {
        column: {
          stacking: 'normal',
          dataLabels: {
            enabled: true
          }
        }
      },
      series: [
          {
        name: $t('CONVERSATIONS')+` (${$t('MESSAGE')})`,
        data: chartData.currentConversationData,
         color: '#1E88E5'
      }, {
        name: $t('CONVERSATIONS')+` (${$t('COMMENT')})`,
        data: chartData.commentData,
         color: '#00897B'
      },
      ]
    }"></highcharts>
          </keep-alive>
        </div>
        <div style="width: 50%;">
          <keep-alive>
            <highcharts class="chart" :options="{
      chart: {
        type: 'column',
          inverted: true
      },
      title: {
        text: $t('NUMBER_CUSTOMER')
      },
      xAxis: {
        categories: chartData.labelCurrent
      },
      yAxis: {
        min: 0,
        title: {
          text: null
        },
      },
      legend: {
        align: 'right',
        x: -30,
        verticalAlign: 'top',
        y: 25,
        borderColor: '#CCC',
        borderWidth: 1,
        shadow: false,
                enabled:false
      },
      tooltip: {
        headerFormat: '<b>{point.x}</b><br/>',
      },
      plotOptions: {
        column: {
          stacking: 'normal',
          dataLabels: {
            enabled: true
          }
        }
      },
      series: [{
        name: $t('CUSTOMERS'),
        data: chartData.currentUserData,
         color: '#5767c4'
      }]
    }"></highcharts>
          </keep-alive>
        </div>

      </a-row>
    </a-card>
    </div>
      <Customer/>
  </a-layout-content>
</template>

<script>

import {useStore} from "vuex";
import {ShopinfoActionTypes} from "@/store/modules/Shopinfo/action-types";
import {computed, onMounted, ref} from "vue";
import moment, {Moment} from "moment";
import {useI18n} from "vue-i18n";
import APIs from "@/APIs/APIs";
import {MixUtils} from "@/utils/Utils";
import {message} from "ant-design-vue";
import dayjs from "dayjs";
import Customer from "./Customer";
import useOverviewStore from "../../../store/Overview";

export default {
  components: {
    Customer
  },
  setup() {
    const i18n = useI18n()
    const store = useStore();
    const currentRangeDate = ref([dayjs().subtract(30, 'days'), dayjs()])

    const fetchShopInfo = () => {
      store.dispatch(ShopinfoActionTypes.FETCH_SHOPINFO);
    };
    const dataShopInfo = computed(() => store.getters.getShopinfo);
    const pagesSelection = computed(() => store.getters.getPagesSelection);
    const listEmployee = computed(() => store.getters.getListEmployee);
    const getPages = useOverviewStore();

    const selectedPage = ref(undefined);


    const chartData = ref({
      currentUserData: [],
      currentConversationData: [],
      labelCurrent: [],
      commentData: [],
    })




    const fetchData = async () => {
      chartData.value = {
        currentUserData: [],
        currentConversationData: [],
        labelCurrent: [],
        commentData: [],
      }

      const responseInteractive = await APIs.init().getReportStaff({
        shop_id: dataShopInfo.value?.id,
        page_id: selectedPage.value,
        conversation: true,
        user: true,
        comment: true,
        from_date: currentRangeDate.value[0].format("YYYY-MM-DD"),
        to_date: currentRangeDate.value[1].format("YYYY-MM-DD")
      })
      responseInteractive.data.aggregations.current.group_by_staff.buckets.map(b=>{
        if(b.key==='0'){
          chartData.value.labelCurrent.push(i18n.t("BOT"))
        }
        const staff = listEmployee.value.find(s=>(s.cms_user_id+'')===b.key)
        console.log(listEmployee.value)
        console.log(staff)
        if(staff)
          chartData.value.labelCurrent.push(staff.name)
        chartData.value.currentConversationData.push(b.sum_conversation.value-b.sum_comment.value)
        chartData.value.currentUserData.push(b.sum_user.value)
        chartData.value.commentData.push(b.sum_comment.value)
      })
      message.destroy()
    }

    const dateRangeChange = (val) => {
      selectedPage.value = val
      message.loading('')
      fetchData()
    }

    const formatMoney = (val) => {
      return MixUtils.methods.formatMoney(val)
    }

    const handlePageChange = (val) => {
      selectedPage.value = val;
      console.log(val)
      fetchData()
    };

    onMounted(() => {
      fetchShopInfo();
      fetchData();
    });

    return {
      dataShopInfo,
      pagesSelection,
      selectedPage,
      chartData,
      formatMoney,
      currentRangeDate,
      dateRangeChange,
      getPages,
      handlePageChange,
    };
  },
};
</script>

<style>
.card_view {
  border-radius: 10px;
  margin: 4px;
  overflow: hidden;
}

.count-elements {
  -webkit-tap-highlight-color: transparent;
  -webkit-text-size-adjust: 100%;
  font-family: Roboto, Helvetica, Arial, sans-serif;
  font-variant: tabular-nums;
  line-height: 1.5;
  font-feature-settings: 'tnum';
  font-size: 14px;
  color: #000;
  box-sizing: border-box;
  height: 76px;
  padding: 10px;
  border-top: 1px solid #e0e0e0;
  border-bottom: 1px solid #e0e0e0;
  border-radius: 10px 0px 0px 10px;
  cursor: pointer;
  box-shadow: 0px 2px 10px rgba(51, 51, 51, 0.1);
  border-left: 10px solid rgb(43, 216, 196);
  background-color: rgba(196, 255, 248, 0.23);
  width: 100%;
}

.title {
  font-family: "Roboto" !important;
  font-style: normal !important;
  font-weight: 700 !important;
  font-size: 12px !important;
  line-height: 22px !important;
  color: #000000 !important;
  margin-left: 0px !important;
  margin-top: 0px !important;
  text-align: left !important;
  width: 100% !important;
}

.count {
  text-align: right;
  font-weight: 100;
  font-family: Montserrat-Light;
  font-size: 25px;
  line-height: 30px;
  color: #263C8F;
  float: right;
}

.highcharts-credits {
  display: none;
}
</style>
