import { Metadata } from "@/APIs/response/S_HttpResponse"
import {Events} from "@/models/Event/Event";
import {Ticket, TicketPrice, TicketTemplate, TicketTransaction, TicketType} from "@/models/Ticket/Ticket";

export type TicketData  ={
    tickets: Ticket[]| undefined
    metadata: Metadata | undefined
}
export type TicketTransactionData  = {
    ticketTransaction: TicketTransaction[]| undefined
    metadata: Metadata | undefined
}
export type TicketTypeData  ={
    tickeTypes: TicketType[]| undefined
    metadata: Metadata | undefined
}
export type TicketPriceData  ={
    tickePrices: TicketPrice[]| undefined
    metadata: Metadata | undefined
}
export type TicketTemplateData  ={
    templates: TicketTemplate[]| undefined
    metadata: Metadata | undefined
}
export type State = {
    // dataSearch: EmployeeSearch[]| undefined
    ticketData: TicketData | undefined
    ticketTypeData: TicketTypeData | undefined
    ticketPriceData: TicketPriceData | undefined
    ticketTemplateData: TicketTemplateData | undefined
    ticketTransactionData:TicketTransactionData| undefined
}

export const state: State = {
    ticketData: {tickets: [], metadata: {}},
    ticketTypeData:{tickeTypes:[], metadata: {}},
    ticketPriceData:{tickePrices:[],metadata: {}},
    ticketTemplateData:{templates:[],metadata: {}},
    ticketTransactionData:{ticketTransaction:[],metadata:{}}
}
