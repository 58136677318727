import {CustomerData, State} from "./states";
import {useI18n} from "vue-i18n";
import {CXViewGroup, CXViewLogin} from "@/models/CXView/CXViewModels";
import {Constant} from "@/utils/Constants";
import {Careers, Customer} from "@/models/Customers/Customers";
import {CustomerConfig, CustomerConfigColumn} from "@/models/CustomerConfig/CustomerConfig";
import {MixUtils} from "@/utils/Utils";
import {fcmdata} from "googleapis/build/src/apis/fcmdata";

export type Getters = {
    getCustomerData(state: State): CustomerData
    getVisibleColumns(state: State): any[]
    getInvisibleColumns(state: State): any[]
    getColumns(state: State): any[]
    getDefaultColumns(state: State): any[]
    getVisibleColumnsCount(state: State): number
    getCXView(state: State): CXViewLogin
    getCXViewGroup(state: State): CXViewGroup[]
    getCXViewGroupCurrent(state: State): string
    getCurrentCustomer(state: State): Customer|undefined
    getCareers(state: State): Careers[]|undefined
    getCustomerConfig(state: State): CustomerConfigColumn[] | undefined
    getCustomerChange(state: State): any[] | undefined
}
export const getters: Getters = {
    getCustomerData(state: State): CustomerData {

        if (state.customerData) {
            return state.customerData
        } else {
            return {
                customers: [],
                metadata: {},
                customersLoading: false,
                version: Constant.VERSION
            }
        }
    },
    // @ts-ignore
    getColumns(state: State): any[] {
        const columns = [...state.columns]
        const cols1 = columns.filter(x => x.visible)
        const cols2 = columns.filter(x => !x.visible)
        return [...cols1, ...cols2].filter(x => x.myTitle)
    },
    getDefaultColumns(state: State): any[] {
        const columns = [
            {
                id: 1,
                myTitle: 'CUSTOMER_NAME',
                dataIndex: 'full_name',
                visible: true,
                slots: {
                    customRender: 'full_name',
                    title: 'customerNameTitle',
                },
                width: '12%',
            },

            {
                id: 55,
                myTitle: 'CUSTOMER_SOURCES',
                dataIndex: 'social_type',
                visible: true,
                slots: {
                    customRender: 'sources',
                    title: 'customerSourceTitle',
                },
                width: '12%',
            },
            {
                id: 9,
                myTitle: 'REACTION_TIME',
                dataIndex: 'last_contact_time',
                key: 'last_contact_time',
                slots: {
                    customRender: 'last_contact_time',
                    title: 'customerLastTimeTitle',
                },
                visible: true,
                width: '12%'
            },
            {
                id: 7,
                myTitle: 'CHAT_TAGS',
                dataIndex: 'conversation_tags',
                key: 'conversation_tags',
                slots: {
                    customRender: 'conversation_tags',
                    title: 'customerSaleTagTitle',
                },
                visible: true,
                width: '12%'
            },
            {
                id: 6,
                myTitle: 'SALE_TAGS',
                dataIndex: 'tags',
                key: 'tags',
                slots: {
                    customRender: 'tags',
                    title: 'customerTagsTitle',
                },
                visible: true,
                width: '12%'
            },
            {
                id: 8,
                myTitle: 'CARE_STATUS',
                slots: {
                    customRender: 'last_care',
                    title: 'customerCareStatusTitle',
                },
                dataIndex: 'last_care',
                visible: !location.host.includes("alfreddesk"),
                width: '12%',
            },
            {
                id: 17,
                myTitle: "EMPLOYEE",
                dataIndex: "employees",
                key: "employees",
                slots: {
                    customRender: 'EMPLOYEE',
                    title: 'TITLE_EMPLOYEE',
                },
                visible: true,
                width: '10%'
            },
            {
                id: 5,
                myTitle: 'CUSTOMER_ADDRESS',
                dataIndex: 'address',
                visible: false,
                slots: {
                    customRender: 'address',
                    title: 'customerAddressTitle',
                },
                width: '12%'
            },

            {
                id: 3,
                myTitle: 'CUSTOMER_BIRTHDAY',
                dataIndex: 'birth',
                key: 'birth',
                visible: false,
                slots: {
                    customRender: 'birth',
                    title: 'customerBirthTitle',
                },
            },
            {
                id: 4,
                title: 'Email',
                myTitle: 'Email',
                dataIndex: 'email',
                visible: false,
            },
            {
                id: 99,
                myTitle: "BOOKING_TAGS",
                dataIndex: "hotel_tags",
                key: "hotel_tags",
                slots: {
                    customRender: "hotel_tags",
                    title: "customerSaleBookingTitle",
                },
                visible: location.host.includes("alfreddesk"),
            },
            {
                id: 11,
                myTitle: "PROVINCE",
                dataIndex: "province_name",
                key: "province_name",
                visible: false,
            },
            {
                id: 12,
                myTitle: "DISTRICT",
                dataIndex: "district_name",
                key: "district_name",
                visible: false,
            },
            {
                id: 13,
                myTitle: "WARD",
                dataIndex: "ward_name",
                key: "ward_name",
                visible: false,
            },
            {
                id: 15,
                myTitle: "GENDER",
                dataIndex: "sex",
                key: "sex",
                visible: false,
                slots: {
                    customRender: 'GENDER',
                    title: 'GENDER_TITLE',
                },
            },
            {
                id: 16,
                myTitle: "CONVERSATION_STAFF",
                dataIndex: "conversation_staff",
                key: "conversation_staff",
                slots: {
                    customRender: 'conversation_staff',
                    title: 'conversationStaffTitle',
                },
                visible: false,
            },
            {
                id: 20,
                myTitle: "MEMBER_RANK",
                dataIndex: "member_rank",
                key: "member_rank",
                slots: {
                    customRender: 'member_rank',
                    title: 'rank_title',
                },
                visible: false,
            },
            {
                id: 18,
                myTitle: "RATE",
                dataIndex: "accumulate_points",
                key: "accumulate_points",
                slots: {
                    customRender: 'accumulate_points',
                    title: 'accumulate_points_title',
                },
                visible: false,
            },
            {
                id: 19,
                myTitle: "ACCUMULATE_MONEY",
                dataIndex: "accumulated_money",
                key: "accumulated_money",
                slots: {
                    customRender: 'accumulated_money',
                    title: 'accumulated_money_title',
                },
                visible: false,
            },
            {
                id: 2,
                myTitle: 'ALL_PHONES',
                dataIndex: 'all_phones',
                visible: false,
                slots: {
                    customRender: 'phone',
                    title: 'customerPhoneTitle',
                },
                width: '10%'
            },
            {
                id: 119,
                myTitle: 'FIELD',
                dataIndex: 'field',
                visible: false,
                slots: {
                    customRender: 'field',
                    title: 'customerField',
                },
                width: '12%'
            },

            {
                id: 10,
                slots: { title: 'customerTitle', customRender: 'action', },
                visible: true,
                width: 80
            }
        ]

        const cols1 = columns.filter(x => x.visible)
        const cols2 = columns.filter(x => !x.visible)
        return [...cols1, ...cols2].filter(x => x.myTitle)
    },
    // @ts-ignore
    getInvisibleColumns(state:State):any[]{
        return state.columns.filter(x => !x.visible)
    },
    // @ts-ignore
    getVisibleColumns(state: State): any[] {
        const i18n = useI18n();
        const cols = [...state.columns.filter(x => x.visible)]
        const columns: any[] = []
        for (let i = 0; i < cols.length; i++) {
            const col = {...cols[i]}
            if (col.title) {
                col.title = i18n.t(cols[i].title);
            }
            columns.push(col)
        }
        if (!columns.find(x=>x.id===10)){
            columns.push( {
                id:10,
                slots: {title: 'customerTitle', customRender: 'action',},
                visible: true,
            })
        }
        return columns;
    },
    getVisibleColumnsCount(state: State): number {
        return state.columns.filter(x => x.visible && x.myTitle).length
    },
    getCXView(state: State): CXViewLogin {
        if( state.cxView){
            return state.cxView
        }else{
            return {
                token: '',
                product: '',
                message: '',
                role: ''
            }
        }
    },
    getCXViewGroup(state: State): CXViewGroup[]{
        return state.cxViewGroup
    },
    getCXViewGroupCurrent(state: State): string{
        return state.cxViewGroupCurrent
    },
    getCurrentCustomer(state: State): Customer|undefined{
        return state.customer
    },
    getCareers(state: State): Careers[]|undefined{
        return state.careers
    },
    getCustomerConfig(state: State): CustomerConfigColumn[] | undefined {
        return state.customerConfig;
    },
    getCustomerChange(state: State): any[] | undefined {
        return state.customerChange;
    },
}
