import create from "zustand-vue";
import { persist } from "zustand/middleware";
import APIs from "@/APIs/APIs";
import { Employee } from "@/models/Users";

interface EmployeeState {
  getEmployeeLoading: boolean;
  employees: Employee[];

  metadata: any;
  GetEmployee: (params: any) => void;
}

const initialState = {
  getEmployeeLoading: true,
  employees: [],
};

const useEmployeeStore = create<EmployeeState>(
  // @ts-ignore
  persist(
    // @ts-ignore
    (set) => ({
      ...initialState,

      GetEmployee: async (params) => {
        set(() => ({ getEmployeeLoading: true }));

        const response = await APIs.init().getEmployee(params);
        if (response.data?.status === 200) {
          console.log(response.data.data);
          set(() => ({
            getEmployeeLoading: false,
            employees: response.data?.data ? response.data?.data : [],
          }));
        } else {
          set(() => ({ getEmployeeLoading: false, employees: [] }));
        }
      },
    }),
    {
      name: "employee",
    }
  )
);

export default useEmployeeStore;
