import { Metadata } from "@/APIs/response/S_HttpResponse";
import {Stocks} from "@/models/Stocks/Stocks";
import {StockProduct} from "@/models/Products/Products";

export type StocksData = {
    stocks: Stocks[] | undefined;
    metadata: Metadata | undefined
    loading: boolean
}

export type StockProductsData = {
    products: StockProduct[] | undefined;
    metadata: Metadata | undefined
    loading: boolean
}

export type State = {
    stockData: StocksData | undefined;
    stockProductsData: StockProductsData | undefined;
}

export const state: State = {
    stockData: { stocks: [], metadata: {}, loading: false },
    stockProductsData: { products: [], metadata: {}, loading: false },
}
