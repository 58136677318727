import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, createBlock as _createBlock, normalizeStyle as _normalizeStyle, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-d8163d66"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "title-flow-context"
}
const _hoisted_2 = { class: "preview-container" }
const _hoisted_3 = { key: 0 }
const _hoisted_4 = { class: "content-message" }
const _hoisted_5 = ["src"]
const _hoisted_6 = {
  key: 0,
  class: "post-back-button"
}
const _hoisted_7 = { style: {"display":"flex","width":"30px","height":"40px","position":"absolute","right":"-16px","top":"10px","align-items":"center","justify-content":"center"} }
const _hoisted_8 = { key: 1 }
const _hoisted_9 = { class: "content-message" }
const _hoisted_10 = ["src"]
const _hoisted_11 = { key: 2 }
const _hoisted_12 = { key: 0 }
const _hoisted_13 = ["src"]
const _hoisted_14 = { class: "title-zalo-element" }
const _hoisted_15 = {
  key: 2,
  style: {"margin-top":"18px"}
}
const _hoisted_16 = ["src"]
const _hoisted_17 = { style: {"display":"flex","width":"30px","height":"40px","position":"absolute","right":"-16px","top":"10px","align-items":"center","justify-content":"center"} }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Handle = _resolveComponent("Handle")!
  const _component_a_button = _resolveComponent("a-button")!
  const _component_a_avatar = _resolveComponent("a-avatar")!
  const _component_a_typography_text = _resolveComponent("a-typography-text")!
  const _component_a_tag = _resolveComponent("a-tag")!
  const _component_a_row = _resolveComponent("a-row")!
  const _component_a_list_item_meta = _resolveComponent("a-list-item-meta")!
  const _component_a_list_item = _resolveComponent("a-list-item")!
  const _component_a_list = _resolveComponent("a-list")!
  const _component_right_outlined = _resolveComponent("right-outlined")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.type!=='flow-new')
      ? (_openBlock(), _createElementBlock("h3", _hoisted_1, _toDisplayString(_ctx.$t('FLOW_PREVIEW')), 1))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_2, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.messageContexts, (message) => {
        return (_openBlock(), _createElementBlock("div", {
          key: message.id
        }, [
          (message.type==='text')
            ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                _createElementVNode("div", _hoisted_4, _toDisplayString(message.text), 1),
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(message.reply_images, (image) => {
                  return (_openBlock(), _createElementBlock("div", {
                    class: "content-message",
                    key: image
                  }, [
                    _createElementVNode("img", {
                      src: image,
                      class: "img-message"
                    }, null, 8, _hoisted_5)
                  ]))
                }), 128)),
                (message.buttons)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(message.buttons, (button) => {
                        return (_openBlock(), _createBlock(_component_a_button, {
                          class: "button-post-back",
                          key: button.id
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(button.title) + " ", 1),
                            _createElementVNode("div", _hoisted_7, [
                              _createVNode(_component_Handle, {
                                id: button.id,
                                type: "source",
                                class: "handle"
                              }, null, 8, ["id"])
                            ])
                          ]),
                          _: 2
                        }, 1024))
                      }), 128))
                    ]))
                  : _createCommentVNode("", true)
              ]))
            : (message.type==='text_attachment')
              ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
                  _createElementVNode("div", _hoisted_9, [
                    (message.images&&message.images.length>0)
                      ? (_openBlock(), _createElementBlock("img", {
                          key: 0,
                          src: message.images[0],
                          class: "img-message"
                        }, null, 8, _hoisted_10))
                      : _createCommentVNode("", true),
                    _createTextVNode(" " + _toDisplayString(message.text), 1)
                  ])
                ]))
              : ( message.type !=='text_attachment' && message.type !=='text' )
                ? (_openBlock(), _createElementBlock("div", _hoisted_11, [
                    (message.elements&&message.elements.length>0)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_12, [
                          _createElementVNode("img", {
                            style: {"width":"100%"},
                            src: message.elements[0].image_url,
                            class: "img-message"
                          }, null, 8, _hoisted_13),
                          _createElementVNode("p", _hoisted_14, _toDisplayString(message.elements[0].title), 1),
                          _createTextVNode(" " + _toDisplayString(message.elements[0].subtitle), 1)
                        ]))
                      : _createCommentVNode("", true),
                    (message.elements&&message.elements.length>1)
                      ? (_openBlock(), _createBlock(_component_a_list, {
                          key: 1,
                          "item-layout": "horizontal",
                          "data-source": message.elements
                        }, {
                          renderItem: _withCtx(({ item,index }) => [
                            (index!==0)
                              ? (_openBlock(), _createBlock(_component_a_list_item, {
                                  key: 0,
                                  class: "item-zalo-element"
                                }, {
                                  default: _withCtx(() => [
                                    _createVNode(_component_a_list_item_meta, null, {
                                      avatar: _withCtx(() => [
                                        (item.image_url)
                                          ? (_openBlock(), _createBlock(_component_a_avatar, {
                                              key: 0,
                                              shape: "square",
                                              src: item.image_url
                                            }, null, 8, ["src"]))
                                          : _createCommentVNode("", true)
                                      ]),
                                      description: _withCtx(() => [
                                        (item.type !== 'table')
                                          ? (_openBlock(), _createElementBlock("div", {
                                              key: 0,
                                              style: _normalizeStyle({'text-align':item.align})
                                            }, [
                                              _createVNode(_component_a_typography_text, {
                                                style: _normalizeStyle({'fontWeight':item.type=== 'header' ? '600' : '4000'})
                                              }, {
                                                default: _withCtx(() => [
                                                  _createTextVNode(_toDisplayString(item.content), 1)
                                                ]),
                                                _: 2
                                              }, 1032, ["style"])
                                            ], 4))
                                          : (_openBlock(), _createBlock(_component_a_row, {
                                              key: 1,
                                              style: {"flex-direction":"column"}
                                            }, {
                                              default: _withCtx(() => [
                                                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(item.content_table, (i) => {
                                                  return (_openBlock(), _createBlock(_component_a_typography_text, {
                                                    style: {"display":"block"},
                                                    key: i
                                                  }, {
                                                    default: _withCtx(() => [
                                                      _createTextVNode(_toDisplayString(`${i.key}`) + " :", 1),
                                                      _createVNode(_component_a_tag, {
                                                        style: {"color":"black"},
                                                        color: i.style ? i.style : 'transparent'
                                                      }, {
                                                        default: _withCtx(() => [
                                                          _createTextVNode(_toDisplayString(i.value), 1)
                                                        ]),
                                                        _: 2
                                                      }, 1032, ["color"])
                                                    ]),
                                                    _: 2
                                                  }, 1024))
                                                }), 128))
                                              ]),
                                              _: 2
                                            }, 1024))
                                      ]),
                                      _: 2
                                    }, 1024)
                                  ]),
                                  _: 2
                                }, 1024))
                              : _createCommentVNode("", true)
                          ]),
                          _: 2
                        }, 1032, ["data-source"]))
                      : _createCommentVNode("", true),
                    (message.buttons)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_15, [
                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(message.buttons, (button) => {
                            return (_openBlock(), _createBlock(_component_a_button, {
                              style: {"border-top":"none","border-left":"none","border-right":"none","align-items":"center","justify-content":"space-between","width":"100%","display":"flex","height":"50px"},
                              class: "button-post-back",
                              key: button.id
                            }, {
                              default: _withCtx(() => [
                                (button.image_icon)
                                  ? (_openBlock(), _createElementBlock("img", {
                                      key: 0,
                                      src: button.image_icon,
                                      style: {"width":"25px","height":"25px"}
                                    }, null, 8, _hoisted_16))
                                  : _createCommentVNode("", true),
                                _createTextVNode(" " + _toDisplayString(button.title) + " ", 1),
                                _createVNode(_component_right_outlined),
                                _createElementVNode("div", _hoisted_17, [
                                  _createVNode(_component_Handle, {
                                    id: button.id,
                                    type: "source",
                                    class: "handle"
                                  }, null, 8, ["id"])
                                ])
                              ]),
                              _: 2
                            }, 1024))
                          }), 128))
                        ]))
                      : _createCommentVNode("", true)
                  ]))
                : _createCommentVNode("", true)
        ]))
      }), 128))
    ])
  ], 64))
}