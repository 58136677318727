import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, normalizeStyle as _normalizeStyle, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-6f2cc4ae"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "container-title" }
const _hoisted_2 = { class: "title-header" }
const _hoisted_3 = { style: {"margin-right":"10px"} }
const _hoisted_4 = { class: "add-tag" }
const _hoisted_5 = { style: {"display":"flex"} }
const _hoisted_6 = {
  class: "form-modal form-selection",
  style: {"text-align":"center"}
}
const _hoisted_7 = { class: "center-button-content" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_button = _resolveComponent("a-button")!
  const _component_plus_outlined = _resolveComponent("plus-outlined")!
  const _component_a_tag = _resolveComponent("a-tag")!
  const _component_eye_outlined = _resolveComponent("eye-outlined")!
  const _component_edit_outlined = _resolveComponent("edit-outlined")!
  const _component_a_menu_item = _resolveComponent("a-menu-item")!
  const _component_delete_outlined = _resolveComponent("delete-outlined")!
  const _component_a_popconfirm = _resolveComponent("a-popconfirm")!
  const _component_a_menu = _resolveComponent("a-menu")!
  const _component_a_dropdown = _resolveComponent("a-dropdown")!
  const _component_a_table = _resolveComponent("a-table")!
  const _component_a_input = _resolveComponent("a-input")!
  const _component_a_form_item = _resolveComponent("a-form-item")!
  const _component_ColorPicker = _resolveComponent("ColorPicker")!
  const _component_a_textarea = _resolveComponent("a-textarea")!
  const _component_a_form = _resolveComponent("a-form")!
  const _component_a_modal = _resolveComponent("a-modal")!
  const _component_a_col = _resolveComponent("a-col")!
  const _component_Draggable = _resolveComponent("Draggable")!
  const _component_Container = _resolveComponent("Container")!
  const _component_a_row = _resolveComponent("a-row")!
  const _component_trigger_quick_message_modal = _resolveComponent("trigger-quick-message-modal")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("h3", _hoisted_2, _toDisplayString(_ctx.$t(_ctx.$route.name)), 1),
      _createElementVNode("div", _hoisted_3, [
        (_ctx.getMyRole()?.is_create)
          ? (_openBlock(), _createBlock(_component_a_button, {
              key: 0,
              onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.visible2 = true))
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t("change_position")), 1)
              ]),
              _: 1
            }))
          : _createCommentVNode("", true)
      ]),
      _createElementVNode("div", _hoisted_4, [
        (_ctx.getMyRole()?.is_create)
          ? (_openBlock(), _createBlock(_component_a_button, {
              key: 0,
              type: "primary",
              onClick: _ctx.newCustomerCares
            }, {
              icon: _withCtx(() => [
                _createVNode(_component_plus_outlined)
              ]),
              default: _withCtx(() => [
                _createTextVNode(" " + _toDisplayString(_ctx.$t("ADD_CARE_STATUS")), 1)
              ]),
              _: 1
            }, 8, ["onClick"]))
          : _createCommentVNode("", true)
      ])
    ]),
    _createVNode(_component_a_table, {
      size: "middle",
      scroll: {  y: '80vh' },
      class: "table",
      pagination: false,
      rowKey: record => record.id,
      columns: _ctx.columns,
      "data-source": _ctx.sortCares(_ctx.getCustomerCares)
    }, {
      name: _withCtx(({ record }) => [
        _createVNode(_component_a_tag, {
          color: record.color
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(record.name), 1)
          ]),
          _: 2
        }, 1032, ["color"])
      ]),
      created_at: _withCtx(({text}) => [
        _createElementVNode("span", null, _toDisplayString(_ctx.dateTimeDisplayFormatFromString(text)), 1)
      ]),
      action: _withCtx(({ record }) => [
        _createVNode(_component_a_dropdown, null, {
          overlay: _withCtx(() => [
            _createVNode(_component_a_menu, null, {
              default: _withCtx(() => [
                (_ctx.getMyRole()?.is_edit)
                  ? (_openBlock(), _createBlock(_component_a_menu_item, {
                      key: 0,
                      onClick: ($event: any) => (_ctx.editClick(record))
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_edit_outlined),
                        _createTextVNode(" " + _toDisplayString(_ctx.$t("CUSTOMER_DETAIL_EDIT")), 1)
                      ]),
                      _: 2
                    }, 1032, ["onClick"]))
                  : _createCommentVNode("", true),
                (_ctx.getMyRole()?.is_edit)
                  ? (_openBlock(), _createBlock(_component_a_menu_item, {
                      key: 1,
                      onClick: ($event: any) => (_ctx.addQuickMessage(record))
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_edit_outlined),
                        _createTextVNode(" " + _toDisplayString(_ctx.$t("SET_TRIGGER")), 1)
                      ]),
                      _: 2
                    }, 1032, ["onClick"]))
                  : _createCommentVNode("", true),
                (_ctx.getMyRole()?.is_edit)
                  ? (_openBlock(), _createBlock(_component_a_menu_item, {
                      key: 2,
                      onClick: ($event: any) => (_ctx.removeQuickMessage(record))
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_edit_outlined),
                        _createTextVNode(" " + _toDisplayString(_ctx.$t("REMOVE_TRIGGER")), 1)
                      ]),
                      _: 2
                    }, 1032, ["onClick"]))
                  : _createCommentVNode("", true),
                (_ctx.getMyRole()?.is_delete)
                  ? (_openBlock(), _createBlock(_component_a_menu_item, { key: 3 }, {
                      default: _withCtx(() => [
                        _createVNode(_component_a_popconfirm, {
                          title: "Are you sure delete this status care?",
                          "ok-text": "Yes",
                          "cancel-text": "No",
                          onConfirm: ($event: any) => (_ctx.deleteCustomerCare(record))
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_delete_outlined),
                            _createTextVNode(" " + _toDisplayString(_ctx.$t("CUSTOMER_DELETE")), 1)
                          ]),
                          _: 2
                        }, 1032, ["onConfirm"])
                      ]),
                      _: 2
                    }, 1024))
                  : _createCommentVNode("", true)
              ]),
              _: 2
            }, 1024)
          ]),
          default: _withCtx(() => [
            _createVNode(_component_a_button, { type: "text" }, {
              default: _withCtx(() => [
                _createVNode(_component_eye_outlined)
              ]),
              _: 1
            })
          ]),
          _: 2
        }, 1024)
      ]),
      _: 1
    }, 8, ["rowKey", "columns", "data-source"]),
    _createVNode(_component_a_modal, {
      title: _ctx.customerCaresForm.id?_ctx.$t('UPDATE'):_ctx.$t('ADD_NEW'),
      visible: _ctx.visible,
      "onUpdate:visible": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.visible) = $event)),
      "confirm-loading": _ctx.confirmLoading,
      width: "40%",
      onOk: _ctx.save
    }, {
      default: _withCtx(() => [
        _createVNode(_component_a_form, {
          model: _ctx.customerCaresForm,
          ref: "formRef",
          rules: _ctx.rules,
          "label-col": {span: 6,offset:0},
          "wrapper-col": { span: 16 }
        }, {
          default: _withCtx(() => [
            _createVNode(_component_a_form_item, {
              label: _ctx.$t('TAG_TAG_NAME'),
              name: "name"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_a_input, {
                  value: _ctx.customerCaresForm.name,
                  "onUpdate:value": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.customerCaresForm.name) = $event))
                }, null, 8, ["value"])
              ]),
              _: 1
            }, 8, ["label"]),
            _createVNode(_component_a_form_item, {
              label: _ctx.$t('TAG_COLOR'),
              name: "color"
            }, {
              default: _withCtx(() => [
                _createElementVNode("div", _hoisted_5, [
                  _createVNode(_component_a_button, {
                    onClick: _ctx.togglePicker,
                    style: _normalizeStyle({'background-color':_ctx.customerCaresForm.color?_ctx.customerCaresForm.color:'white',color:_ctx.customerCaresForm.color?'white':'black','margin-right':'15px'})
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(" Click ")
                    ]),
                    _: 1
                  }, 8, ["onClick", "style"]),
                  (_ctx.colorPickerVisible)
                    ? (_openBlock(), _createBlock(_component_ColorPicker, {
                        key: 0,
                        theme: "light",
                        color: _ctx.customerCaresForm.color,
                        "sucker-hide": false,
                        "sucker-canvas": _ctx.suckerCanvas,
                        "sucker-area": _ctx.suckerArea,
                        onChangeColor: _ctx.changeColor,
                        onOpenSucker: _ctx.openSucker
                      }, null, 8, ["color", "sucker-canvas", "sucker-area", "onChangeColor", "onOpenSucker"]))
                    : _createCommentVNode("", true)
                ])
              ]),
              _: 1
            }, 8, ["label"]),
            _createVNode(_component_a_form_item, {
              name: "description",
              label: _ctx.$t('TAG_DESCRIPTION')
            }, {
              default: _withCtx(() => [
                _createVNode(_component_a_textarea, {
                  value: _ctx.customerCaresForm.description,
                  "onUpdate:value": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.customerCaresForm.description) = $event)),
                  placeholder: "Description",
                  rows: 4
                }, null, 8, ["value"])
              ]),
              _: 1
            }, 8, ["label"])
          ]),
          _: 1
        }, 8, ["model", "rules"])
      ]),
      _: 1
    }, 8, ["title", "visible", "confirm-loading", "onOk"]),
    _createVNode(_component_a_modal, {
      visible: _ctx.visible2,
      "onUpdate:visible": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.visible2) = $event)),
      title: _ctx.$t('change_display_position'),
      onOk: _cache[6] || (_cache[6] = ($event: any) => (_ctx.visible2 = false))
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_6, [
          _createVNode(_component_a_row, null, {
            default: _withCtx(() => [
              _createVNode(_component_a_col, {
                span: 4,
                style: {"padding-right":"20px"}
              }, {
                default: _withCtx(() => [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.getCustomerCares, (count, index) => {
                    return (_openBlock(), _createBlock(_component_a_button, {
                      key: count,
                      style: {"margin-top":"10px","border-radius":"50px"},
                      size: "large",
                      block: "",
                      class: "button-flow-context right-button-content"
                    }, {
                      default: _withCtx(() => [
                        _createElementVNode("span", _hoisted_7, _toDisplayString(index+1), 1)
                      ]),
                      _: 2
                    }, 1024))
                  }), 128))
                ]),
                _: 1
              }),
              _createVNode(_component_a_col, { span: 20 }, {
                default: _withCtx(() => [
                  _createVNode(_component_Container, {
                    orientation: "vertical",
                    "group-name": "col-items",
                    "drop-placeholder": {
              className: `drop-placeholder`,
              animationDuration: '200',
              showOnTop: true,
            },
                    "drag-class": "\n            transition duration-100 ease-in z-50\n            transform rotate-6 scale-110",
                    "drop-class": "transition duration-100\n            ease-in z-50 transform\n            -rotate-2 scale-90",
                    onDrop: _cache[4] || (_cache[4] = (e) => _ctx.onCardDrop(e))
                  }, {
                    default: _withCtx(() => [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.sortCares(_ctx.getCustomerCares), (item) => {
                        return (_openBlock(), _createBlock(_component_Draggable, {
                          key: item.id
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_a_button, {
                              style: _normalizeStyle([{"margin-top":"10px","border-radius":"50px"}, {'background-color':item.color}]),
                              size: "large",
                              block: "",
                              class: "button-flow-context right-button-content"
                            }, {
                              default: _withCtx(() => [
                                _createElementVNode("span", {
                                  style: _normalizeStyle({'color': item.color ? 'white':'black'}),
                                  class: "center-button-content"
                                }, _toDisplayString(_ctx.$t(item.name)), 5)
                              ]),
                              _: 2
                            }, 1032, ["style"])
                          ]),
                          _: 2
                        }, 1024))
                      }), 128))
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ])
      ]),
      _: 1
    }, 8, ["visible", "title"]),
    _createVNode(_component_trigger_quick_message_modal, {
      onlySelectTrigger: true,
      triggerSelectCallback: _ctx.triggerSelectCallback,
      ref: "triggerCustomerRef"
    }, null, 8, ["triggerSelectCallback"])
  ], 64))
}