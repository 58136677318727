import {ActionContext, ActionTree} from "vuex";
import {StocksData, state, State, StockProductsData} from "./states";
import {RootState} from "@/store";
import APIs from "@/APIs/APIs";
import {Mutations} from "./mutations";
import {StockProductResponse, StocksResponse} from "@/APIs/response/S_HttpResponse";
import {StockActionTypes} from "@/store/modules/stocks/action-types";
import {StocksRequest} from "@/APIs/request/StocksRequest";
import {StocksMutationTypes} from "@/store/modules/stocks/mutation-types";
import {StockProductRequest} from "@/APIs/request/ProductRequest";


type AugmentedActionContext = {
    commit<K extends keyof Mutations>(
        key: K,
        payload: Parameters<Mutations[K]>[1],
    ): ReturnType<Mutations[K]>;
} & Omit<ActionContext<State, RootState>, 'commit'>

export interface Actions {
    [StockActionTypes.FETCH_STOCKS](
        {commit}: AugmentedActionContext,
         payload:StocksRequest
    ): Promise<StocksResponse | undefined>;

    [StockActionTypes.FETCH_STOCK_PRODUCTS](
        {commit}: AugmentedActionContext,
        payload: StockProductRequest
    ): Promise<StocksResponse | undefined>;

}

export const actions: ActionTree<State, RootState> & Actions = {
    async [StockActionTypes.FETCH_STOCKS]({commit}, payload: StocksRequest): Promise<StocksResponse | undefined> {
        commit(StocksMutationTypes.SET_STOCKS,  {
            stocks: state.stockData?.stocks,
            metadata: state.stockData?.metadata,
            loading: true,
        })
        const response = await APIs.init().getStocks(payload)
        commit(StocksMutationTypes.SET_STOCKS,  {
            stocks: state.stockData?.stocks,
            metadata: state.stockData?.metadata,
            loading: false,
        })
        if (response.status === 200) {
            const stocksData: StocksData = {
                stocks: response.data?.data,
                metadata: response.data?.meta_data,
                loading: false,
            }
            if(payload.callback){
                payload.callback(response.data?.data)
            }
            commit(StocksMutationTypes.SET_STOCKS, stocksData)
        }
        return response.data;
    },

    async [StockActionTypes.FETCH_STOCK_PRODUCTS]({commit}, payload: StocksRequest): Promise<StockProductResponse | undefined> {
        commit(StocksMutationTypes.SET_STOCK_PRODUCTS,  {
            products: state.stockProductsData?.products,
            metadata: state.stockProductsData?.metadata,
            loading: true,
        })
        const response = await APIs.init().getStockProducts(payload)
        commit(StocksMutationTypes.SET_STOCK_PRODUCTS,  {
            products: state.stockProductsData?.products,
            metadata: state.stockProductsData?.metadata,
            loading: false,
        })
        console.log('store', response.data?.data)
        if (response.status === 200) {
            const stockProductData: StockProductsData = {
                products: response.data?.data,
                metadata: response.data?.meta_data,
                loading: false,
            }
            commit(StocksMutationTypes.SET_STOCK_PRODUCTS, stockProductData)
        }
        return response.data;
    },
}
