<template>
  <div class="container-title">
    <h3 class="title-header">{{$t($route.name)

      }}      <a-typography-text strong>{{employeeStore.employees.length + '/' + shopInfo}}</a-typography-text>
    </h3>
    <div class="input-search">
      <a-input-search
          v-model:value="userName"
          :placeholder="$t('SEARCH')"
          style="width: 100%"
          @change="Search"
      />
    </div>
    <a-button
        v-if="getMyRole(modules.employee)?.is_create"
        type="primary">
      <router-link v-if="employeeStore.employees.length < shopInfo"  to="/employee/add">
        {{$t('ADD_EMPLOYEE')}}
      </router-link>
      <span @click="showModal" v-else >
        {{$t('ADD_EMPLOYEE')}}
      </span>
    </a-button>
  </div>

  <a-table
      size="middle"
      :scroll="{  y: '80vh' }"
      class="table"
      :columns="columns"
      :data-source="employeeStore.employees"
      :footer="null"
      :pagination="false"
  >
    <template #name="{ text }">
      <span style="font-weight: bold">{{ text }}</span>
    </template>
    <template #customTitle>
      <span> {{$t('FULL_NAME')}} </span>
    </template>
    <template #role="{ record }">
      <span>
        {{getRole(record)}}
      </span>
    </template>
    <template #customTitleRole>
      <span>{{$t('ROLE')}} </span>
    </template>
    <template #action="{ record }">
          <span>
            <router-link
                v-if="!record.is_owner && getMyRole(modules.employee)?.is_read"
                :to="{
                name: 'EMPLOYEEDETAIL',
                params: { userid: record.cms_user_id },
              }"
                style="color: black"
            ><EyeOutlined
            />
                        <a-divider type="vertical" v-if="record.role_id!==2" />

            </router-link>

            <router-link
                v-if="!record.is_owner && getMyRole(modules.employee)?.is_edit"
                class="ant-dropdown-link"
                :to="{
                name: 'EMPLOYEEEDIT',
                params: { userid: record.cms_user_id },
              }"
                style="color: black"
            >
              <edit-outlined />
                          <a-divider v-if="!record.is_owner" type="vertical" />
            </router-link>
            <a-popconfirm
                v-if="!record.is_owner && getMyRole(modules.employee)?.is_delete"
                :title="$t('CONFIRM_DELETE')+' ' +$t('EMPLOYEE')+'?'"
                :ok-text="$t('OK')"
                :cancel-text="$t('CANCEL')"
                @confirm="confirm(record)"
            >
              <DeleteOutlined />
            </a-popconfirm>
          </span>
    </template>
  </a-table>

</template>

<script lang="ts">
import { computed, onMounted, ref } from "vue";

import { Employee } from "@/models/Users";

import { useStore } from "vuex";
import { EmployeeActionTypes } from "@/store/modules/employee/action-types";

import {
  EditOutlined,
  PhoneOutlined,
  EyeOutlined,
  EditFilled,
  DeleteFilled, DeleteOutlined,
} from "@ant-design/icons-vue";
import APIs from "@/APIs/APIs";
import { message } from "ant-design-vue";

import { useI18n } from "vue-i18n";
import { Role } from "@/models/Role/Role";
import {Constant} from "@/utils/Constants";
import useEmployeeStore from "../../store/Employee";
export default {
  components: {
    // PhoneOutlined,
    EyeOutlined,
    EditOutlined,
    DeleteOutlined,
  },
  setup() {
    const i18n = useI18n();
    const roles = ref<Role[]>([]);

    const columns = [
      {
        title: "CMS ID",
        dataIndex: "cms_user_id",
        key: "cms_user_id",
        width: 80
      },
      {
        dataIndex: "name",
        title: i18n.t("name"),
        key: "name",
        slots: { title: "customTitle", customRender: "name" },
      },
      {
        title: "Email",
        dataIndex: "email",
        key: "email",
      },
      {
        title: i18n.t("mobile"),
        dataIndex: "mobile",
        key: "mobile",
        width: 120
      },
      {
        title: i18n.t("ROLE"),
        key: "role_id",
        dataIndex: "role_id",
        slots: { title: "customTitleRole", customRender: "role" },
      },
      {
        title: i18n.t("DEPARTMENT"),
        key: "department_name",
        dataIndex: "department_name",
        // slots: { title: "customTitleRole", customRender: "department_name" },
      },
      {
        title: i18n.t("ACTION"),
        key: "action",
        slots: { customRender: "action" },
        width: 100
      },
    ];

    const selectedRole = ref(0);
    const fetchRole = async  () => {
      const res = await APIs.init().getRole()
      if (res.status=== 200){
        roles.value = res.data?.data? res.data?.data:[]
      }
    }

    const store = useStore();
    const showUser = ref<Employee[]>([]);
    const userName = ref("");

    const visible = ref<boolean>(false);

    const showModal = () => {
      message.error('không thể thêm nhân viên,vui lòng liên hệ Biglead để được hỗ trợ')
      // visible.value = true;
    };

    const handleOk = () => {
      visible.value = false;
    };

    const Search =  () => {
      fetchEmployee()
    };
   const shopInfo = computed(()=>store.getters.getShopinfo.shop_services.user_num)
    console.log(shopInfo.value)
    const fetchEmployee = () => {
      // store.dispatch(EmployeeActionTypes.FETCH_EMPLOYEE, {
      //   page: 1,
      //   page_size: 999,
      //   search: userName.value,
      // }
      // );
      useEmployeeStore.getState().GetEmployee({
        page: 1,
        page_size: 999,
        search: userName.value,
      })
    };
   const employeeStore = useEmployeeStore()
    // const employeeStore.employees = computed(() => useEmployeeStore.getState().employees);


    const confirm = async (value: any) => {
      try {
        const res = await APIs.init().deleteEmployee({
          role_id: value.role_id,
          user_id: value.cms_user_id,
        });
        if (res.status === 200) {
          visible.value = false;
          try {
            fetchEmployee();
            message.success(i18n.t('delete_success'))
          } catch (error) {
            console.log(error);
          }
        }
      } catch (error) {
        console.log(error);
      }
    };
    const getRole = (employee: Employee)=>{
      if(employee.is_owner){
        return i18n.t('SHOP_OWNER')
      }else{
        const role = roles.value.find(r=>r.id === employee.role_id)
        return role?role.name:''
      }
    };

    const getUserInfo = computed(() => store.getters.getUserInfo)

    const getMyRole = computed(() => {
      if(getUserInfo.value.id_cms_privileges<11){
        return ()=>({
          is_visible: 1,
          is_create:1,
          is_read: 1,
          is_edit: 1,
          is_delete: 1,
          id: 1
        })
      }else{
        return store.getters.getRole
      }
    });
    const modules = Constant.MODULES;

    onMounted(() => {
      fetchEmployee();
      fetchRole()
    });
    return {
      getMyRole,
      modules,
      selectedRole,
      visible,
      showModal,
      handleOk,
      columns,
      userName,
      Search,
      showUser,
      shopInfo,
      getRole,
      confirm,
      // employeeStore.employees,
      employeeStore
    };
  },
};
</script>

<style scoped lang="scss">

.table {
  margin: 16px
}
.title-header {
  font-weight: bold;
  flex: 1;
}
.container-title{
  display: flex;
  flex-direction: row;
  padding: 16px 24px 16px 24px;
  margin-bottom: 25px;
  background-color: white
}
.input-search{
  width: 250px;
  margin-right: 16px;
}
</style>
