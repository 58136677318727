<template>
  <div class="container-title">
    <h3 class="title-header">{{$t($route.name)}}</h3>
<!--    <a-form layout="inline" :model="filterForm">-->
<!--      <a-form-item label="Schedule time">-->
<!--        <a-range-picker v-model:value="filterForm.dateRange" @change="dateRangeChange"/>-->
<!--      </a-form-item>-->
<!--    </a-form>-->

  </div>

  <a-table
      class="table"
      @expand="tableExpand"
      :data-source="list.viral"
      :expandedRowKeys="expandedRowKeys"
      :columns="columns"
      :rowKey="(record) => record.id"
  >
    <template #trigger_name="{ record }">
      <a-button class="table-row-name" type="text">
        {{ record.trigger_name }}
      </a-button>
    </template>
    <template #creator="{text}" >
      <a-row>
        <a-typography-text  strong>{{getEmployeeName(text)}}</a-typography-text>
      </a-row>



    </template>
<!--<template #action>-->
<!--  <a-button @click="syncCustomerWithCrm" v-if="viralUser.length > 0" type="primary">{{$t('SYNC_CUSTOMER_WITH_CRM')}}</a-button>-->
<!--</template>-->
<!--<template #participants="{ record }">-->
<!--  <a-typography-text>{{record.vrial.length}}</a-typography-text>-->
<!--</template>-->
    <template #expandedRowRender>
      <div style="margin-bottom: 16px">
        <a-button type="primary" :disabled="!hasSelected" :loading="loading"  @click="showModal">
          {{$t('SYNC_CUSTOMER_WITH_CRM')}}
        </a-button>
        <a-button type="primary"   @click="exportExcel">
          {{$t('EXPORT_STOCK')}}
        </a-button>
        <span style="margin-left: 8px">
        <template v-if="hasSelected">
          {{ `${$t('datamodal_select')} ${selectedRowKeys.length} ${$t('CUSTOMER')}` }}
        </template>
<!--           <a-select-->
<!--               v-model:value="giftName"-->
<!--               :size="size"-->
<!--               style="width: 200px"-->
<!--               placeholder=""-->

<!--           >-->
<!--        <a-select-option >-->
<!--          {{i.name}}-->
<!--        </a-select-option>-->
<!--           -->
<!--      </a-select>-->
            <a-radio-group @change="giftChange"  v-model:value="giftName">
               <a-radio-button  style="margin-right: 10px;margin-top: 10px" value="Tất cả"  >
      {{$t('ALL')}}
                 <!--      <a-tag>{{getGift(i.name)}}</a-tag>-->
    </a-radio-button>
    <a-radio-button  style="margin-right: 10px;margin-top: 10px" v-for="i in gifts" :key="i.id" :value="i.id" >
      {{i.name}}
<!--      <a-tag>{{getGift(i.name)}}</a-tag>-->
    </a-radio-button>

  </a-radio-group>
      </span>
      </div>

      <a-spin v-if="expandLoading"/>
      <a-table
          class="table"
          v-else
          :data-source="viralUser"
          :expandedRowKeys="expandedRowKeys"
          :columns="columnsViral"
          :loading="expandLoading"
          :rowKey="(record) => record.id"
          :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }"
          :pagination="false"

      >
        <template #customer_name="{record}" >
          <a-row>
            <router-link style="color:#FAB216" :to="`/customer-detail/${record.customer_id}`">{{record.full_name}}</router-link>
          </a-row>

          <a-typography-text>{{record.phone}}</a-typography-text>

        </template>
        <template #gift="{record}">
          <a-row>
            <div v-for="i in record.extra_data.gifts" :key="i.id">
              <a-tooltip >
                <template #title>
                  <span>{{i.name}}</span>
                </template>
              <img v-if="i.id !== 'good_luck'" style="margin-right: 10px" width="50" height="50"  :src="i.image_url" />
              </a-tooltip>
            </div>

          </a-row>
        </template>
        <template #good_luck="{record}">
          <a-row>
              <a-typography-text >{{getGoodLuck(record.extra_data.gifts)}}</a-typography-text>
          </a-row>
        </template>
        <template #num_play="{record}">
          <a-row>
            <a-typography-text >{{record.extra_data.num_play}}</a-typography-text>
          </a-row>
        </template>

      </a-table>
    </template>
  </a-table>
<!--  <a-modal v-model:visible="errorLogVisible" :title="$t('ERROR_LOG_VIEWER')" :footer="null">-->
<!--    <a-spin v-if="errorLogsLoading"/>-->
<!--    <VirtualList v-else-->
<!--                 class="scroller"-->
<!--                 :size="30" :remain="20"-->
<!--    >-->
<!--      <a-timeline>-->

<!--        <a-timeline-item v-for="item in errorLogs" :key="item.created">{{item.content}}-->
<!--          {{ dateTimeDisplayFormatFromString(item.created) }}-->
<!--        </a-timeline-item>-->
<!--      </a-timeline>-->
<!--    </VirtualList>-->

<!--  </a-modal>-->
  <a-modal v-model:visible="visible" :title="$t('SYNC_CUSTOMER_WITH_CRM')" @ok="handleOk">
    <a-form>
      <a-form-item :label="$t('CARE_STATUS')" name="last_care">
        <a-select
            style="width: 100%"
            allowClear
            placeholder="Please select"
            v-model:value="careId"
        >
          <a-select-option
              v-for="item in customerCares.customerCares"
              :key="item.id"
              v-bind:value="item.id"

          >
            <a-tag :color="item.color">
              {{ item.name }}
            </a-tag>
          </a-select-option>
        </a-select>
      </a-form-item>
      <a-form-item :label="$t('TAG')" name="tag">
        <a-tree-select
            @change="handleChangeTag"
            show-search
            style="width: 100%"
            @search="searchTag"
            v-model:value="tagIds"
            :filterTreeNode="false"
            v-model:searchValue="searchTagValue"
            :notFoundContent="null"
            :dropdown-style="{ maxHeight: '300', overflow: 'auto' }"
            allow-clear
            multiple
            :placeholder="$t('TAG_SELECT')"
        >
          <a-tree-select-node v-for="item in treeTags.tagTree" :key="item.id" v-bind:value="item.id">
            <template #title>
              <a-tag :color="item.color">
                {{ item.name }}
              </a-tag>
            </template>
            <a-tree-select-node v-for="child in item.children" :key="child.id" v-bind:value="child.id">
              <template #title>
                <a-tag :color="child.color">
                  {{ child.name }}
                </a-tag>
              </template>
            </a-tree-select-node>

          </a-tree-select-node>
        </a-tree-select>
      </a-form-item>
    </a-form>

  </a-modal>
</template>

<script lang="ts">
import {computed, defineComponent, onMounted, reactive, ref, toRefs} from "vue";
import {useI18n} from "vue-i18n";
import moment, {Moment} from "moment";
import {useStore} from "vuex";
import {TriggerMutationTypes} from "@/store/modules/trigger/mutation-types";
import {TriggerActionTypes} from "@/store/modules/trigger/action-types";
import {TriggerStatistic, TriggerStatisticErrorLog} from "@/models/Trigger/TriggerHistory";
import {MixUtils} from "@/utils/Utils";
import {useRoute} from "vue-router";
import VirtualList from "@supertiger/vue-3-virtual-scroll-list";

import {
  WarningOutlined
} from "@ant-design/icons-vue"
import {PaginationProps} from "ant-design-vue/es/pagination";
import dayjs, {Dayjs} from "dayjs";
import {Employee} from "@/models/Users";
import {CustomersActionTypes} from "@/store/modules/customer/action-types";
import APIs from "@/APIs/APIs";
import {TagsActionTypes} from "@/store/modules/tag/action-types";
import {Tag} from "@/models/Tags/Tags";
import {CustomerForm} from "@/models/Customers/Customers";
import {message} from "ant-design-vue";
import {FlowActionTypes} from "@/store/modules/flow/action-types";
import useTagStore from "../../../store/Tags";
import useCustomerStore from "../../../store/Customers";
import useUserStore from "../../../store/User";
import useTriggerStore from "../../../store/Trigger";

let fetchTimeout: number;
interface Filter {
  dateRange: Dayjs[],
  trigger_id: number,
  page: number,
  page_size: number
}
let searchTreeTimeout=0

export default defineComponent({
  name: "TriggerHistory",
  components: {
    // WarningOutlined,
    // VirtualList
  },
  setup() {
    const i18n = useI18n()
    const store = useStore()
    const route = useRoute()
    const customerViral = ref<any>([])
    const visible = ref(false)
    const tagIds = ref<any[]>([])
    const careId = ref<any>([])
    const searchTagValue = ref('')
    const giftName =ref('')
    const state = reactive<{
      selectedRowKeys: any[];
      loading: boolean;
    }>({
      selectedRowKeys: [], // Check here to configure the default column
      loading: false,
    });
    const hasSelected = computed(() => state.selectedRowKeys.length > 0);
    const customerCares = useCustomerStore()
    const treeTags = useTagStore()
    // const start = () => {
    //   state.loading = true;
    //   // ajax request after empty completing
    //   setTimeout(() => {
    //     state.loading = false;
    //     state.selectedRowKeys = [];
    //   }, 1000);
    // };

    const searchTag=(val:string)=>{

      if (searchTreeTimeout){
        clearTimeout(searchTreeTimeout)
      }
      searchTreeTimeout=window.setTimeout(()=>{
        useTagStore.getState().GetTreeTag({type:'customer',search:val})
        // store.dispatch(TagsActionTypes.FETCH_TREE_TAGS,{payload:{type:'customer',search:val}})
      },1100)
    }
    const onSelectChange = (selectedRowKeys: any[],selectedRows: any[]) => {
      console.log('selectedRowKeys changed: ', selectedRowKeys,selectedRows);
      state.selectedRowKeys = selectedRowKeys;
      customerViral.value = selectedRows
    };
    const columns = [
      {
        title: i18n.t('TRIGGER_TITLE'),
        dataIndex: 'name',
        slots: {customRender: 'name'},
      },
      {
        title: i18n.t('Participants'),
        dataIndex: 'participants',
        slots: {customRender: 'participants'},
      },
      {
        title: i18n.t('CREATOR'),
        dataIndex: 'creator',
        slots: {customRender: 'creator'},
      },
      // {
      //   title: i18n.t('ACTION'),
      //
      //   slots: {customRender: 'action'},
      // },
    ]
    const columnsViral =[
      {
        title: i18n.t('CUSTOMER_NAME_FROM'),
        dataIndex: 'full_name',
        slots: {customRender: 'customer_name'},
      },
      {
        title: i18n.t('SHARE'),
        dataIndex: 'parent_name',
        slots: {customRender: 'parent_name'},
      },
      {
        title: i18n.t('CUSTOMER_NAME'),
        dataIndex: 'customer_name',
        slots: {customRender: 'full_name'},
      },
      {
        title: i18n.t('NUMBER_OF_PLAY'),
        slots: {customRender: 'num_play'},
      },
      {
        title: i18n.t('shared'),
        dataIndex: 'shared',
        slots: {customRender: 'shared'},
      },
      {
        title: i18n.t('GIFT'),
        slots: {customRender: 'gift'},
      },
      {
        title: i18n.t('GOOD_LUCK'),
        slots: {customRender: 'good_luck'},
      },

    ]

    const currentShop =  computed(()=> store.getters.getCurrentShop)
    // const errorLogVisible = ref(false)
    // const errorLogsLoading = ref(false)
    // const viewErrorLog = (history: TriggerStatistic) => {
    //   errorLogVisible.value = true;
    //   errorLogsLoading.value=true
    //   store.dispatch(TriggerActionTypes.FETCH_TRIGGER_STATISTIC_LOGS, {
    //     payload: {
    //       trigger_id: history.trigger_id,
    //       created_from: filterForm.dateRange[0].toDate(),
    //       created_to: filterForm.dateRange[1].toDate(),
    //       page: 1,
    //       page_size: 50
    //     },callback:(errorLogs:TriggerStatisticErrorLog[])=>{
    //       errorLogsLoading.value=false
    //     }
    //   })
    // }
    const expandedRowKeys = ref<number[]>([])
    const expandLoading = ref<boolean>(false)
    const triggerId = ref<any>(undefined)
        const   getEmployeeName = (payload: number)=> {
          if (useUserStore.getState().currentShop && useUserStore.getState().currentShop.employees) {
            const employee = useUserStore.getState().currentShop.employees?.find((x: Employee) => x.id === payload);
            return employee ? employee.name : "";
          }
          return "";
        }
    const getGoodLuck = (data: any) => {
        let number = 0
      if(data === null)return
      for(let i = 0;i < data.length;i++)
        if(data[i].id === "good_luck"){
          number +=1
        }
        return number
    }
    const showModal = () => {
      visible.value = true;
    };
    const root_id = ref(0)
    const exportExcel =async () => {
      try {
        //@ts-ignore
        const response =  await APIs.init().exportViral({id:root_id.value})
        //@ts-ignore
        const href = window.URL.createObjectURL(new Blob([response.data]));

        // create "a" HTML element with href to file & click

        const link = document.createElement('a');
        console.log(link)
        link.href = href;
        link.setAttribute('download', "Khách_Hàng_"+MixUtils.methods.getUUID()+'.xlsx');
        document.body.appendChild(link);
        link.click();

        // clean up "a" element & remove ObjectURL
        document.body.removeChild(link);
        window.URL.revokeObjectURL(href);


      } catch (e) {
        console.log(e)
      }

    }

    const handleOk =  () => {
      message.loading(i18n.t('Syncing'))
      console.log(customerViral,'customerViral')
      customerViral.value.map( async (item:any) =>{
        if(item.full_name && item.phone){

         const res = await APIs.init().createCustomer({conversationTags:[],email: item.email,full_name:item.full_name,last_care: careId.value,phone:item.phone,selectedTags:[],tags: customerForm.value.tags})
          if(res.data?.status == 200){
            message.success(i18n.t('SUCCESS') + res.data.data.full_name)
          }else {
            message.error(String(res.data?.message))
          }
        }
      })
      visible.value = false;
    };

    let form: CustomerForm = {selectedTags: [], conversationTags: []}
    const customerForm = ref<CustomerForm>({...form})
    const handleChangeTag=()=>{

      if (tagIds.value){
        customerForm.value.tags=tagIds.value.map((tagId):Tag=>{
          return {
            id:tagId,
            name:''
          }
        })
      }
    }
      const fetchViral = ()=>{
      useTriggerStore.getState().getViral({page:1,page_size:100,shop_id: useUserStore.getState().currentShop.id})
        // store.dispatch(TriggerActionTypes.FETCH_Viral,{page:1,page_size:100,shop_id: useUserStore.getState().currentShop.id})
    }
    const list = useTriggerStore()
    const metaData = ref<any>(undefined)
    const viralUser = ref<any>([])
    const fetchViralUser = async (data:any) =>{
          const res = await  APIs.init().getViralUser(data)
      if(res.status === 200){
          if(res.data?.data !== null){
            viralUser.value = res.data?.data.filter((item:any)=>
              item.full_name
            )
            console.log(viralUser.value)
            metaData.value = res.data?.meta_data.record_total === 0 ? metaData.value : res.data?.meta_data.record_total
            expandLoading.value = false
          }else {
            viralUser.value = []
          }

          }else {
            viralUser.value = []
            expandLoading.value = false
          }
      }

      const gifts = ref<any>([])
      const fetchFlowContext = async (id: any)=>{
        const res = await APIs.init().getFlowV2Context({id:id})

        if(res.data?.status === 200){
          let form = ref<any>([])
          if(res.data.data.context.flow_contexts){
            form.value = res.data?.data.context.flow_contexts.filter((item:any)=>
              item.flow_viral[0].type === "form")
            console.log(form.value,'form')
            res.data.data.context.flow_contexts.map((item:any)=>{
              if(item.flow_viral[0].type !=="form" && form.value.length >0 &&  item.id === form.value[0].flow_viral[0].action_submit.usage_id){
                gifts.value = item.flow_viral[0].gifts
                console.log(gifts.value,'111')

              }else if(item.flow_viral[0].type !=="form") {
                gifts.value = item.flow_viral[0].gifts
                console.log(gifts.value,'111')
              }else {
                gifts.value = []
              }
            })
          }


        }
      }
    const giftChange = () => {
      if(giftName.value === 'Tất cả'){
        fetchViralUser({page:1,page_size:99999,trigger_id: triggerId.value,gift_id:''})
      }else {
        fetchViralUser({page:1,page_size:1000,trigger_id: triggerId.value,gift_id:giftName.value})
      }

    }

    const pagination = computed(() => {
      return {
        current: filterForm.page ? filterForm.page : 1,
        pageSize: filterForm.page_size ? filterForm.page_size : 20,
        defaultPageSize: 20,
        total: metaData.value ? metaData.value : 0,
        pageSizeOptions: ['20', '40', '80', '120'],
        showSizeChanger: true
      }
    })

    const handleTableChange = (pagination: any) => {
      expandLoading.value = true
      if (pagination?.current) {
        filterForm.page = pagination?.current
      }
      if (pagination?.pageSize) {
        filterForm.page_size = pagination?.pageSize
      }
      loadCustomer()
    }

    const tableExpand = (visible: boolean, record: any) => {
      triggerId.value = record.id
      filterForm.page = 1
      filterForm.page_size= record.participants
      root_id.value = record.root_flow_id
      if (visible) {
        expandedRowKeys.value = [record.id]
        expandLoading.value = true
        // store.dispatch(TriggerActionTypes.FETCH_INNER_TRIGGER_STATISTICS, {
        //   payload: {
        //     trigger_id: record.trigger_id,
        //     page: filterForm.page,
        //     page_size: 99,
        //     created_from: filterForm.dateRange[0].toDate(),
        //     created_to: filterForm.dateRange[1].toDate()
        //   },
        //
        // })
        fetchViralUser(
            {page:filterForm.page,page_size:99999,trigger_id: record.id,gift_name:giftName.value}
        )
        fetchFlowContext(record.root_flow_id)
      } else {
        expandedRowKeys.value = []
      }

    }
    const syncCustomerWithCrm = async () => {
      viralUser.value.map((item:any)=>{
        APIs.init().createCustomer({full_name: item.full_name,})
      })
    }
    const loadCustomer = () => {
      if (fetchTimeout) {
        clearTimeout(fetchTimeout);
      }
      fetchTimeout = window.setTimeout(() => {

        fetchViralUser(
            {page:filterForm.page,page_size:99999,trigger_id: triggerId.value}
        )
      }, 800);
    }
    const filterForm = reactive<Filter>({
      trigger_id: 0,
      dateRange: [
        dayjs().subtract(1, 'months'),
        dayjs()
      ], page: 1, page_size: 99999
    })
    // const dateRangeChange = () => {
    //   // fetchData()
    // }
    onMounted(()=>{
      fetchViral()
    })
    return {
      columns,
      filterForm,
      // dateRangeChange,
      // // pagination,
      // handleTableChange,
      list,
      loadCustomer,
      getEmployeeName,
      columnsViral,
      tableExpand,
      expandedRowKeys,
      expandLoading,
      // viewErrorLog,
      // errorLogVisible,
      getGoodLuck,
      handleTableChange,
      viralUser,
      pagination,
      syncCustomerWithCrm,
      // errorLogs,errorLogsLoading
      hasSelected,
      ...toRefs(state),

      // func
      // start,
      onSelectChange,
      showModal,
      handleOk,
      visible,
      customerCares,
      treeTags,
      searchTag,
      tagIds,
      searchTagValue,
      careId,
      handleChangeTag,
      gifts,
      giftName,
      giftChange,
      // getGift,
      exportExcel

    }
  }
})
</script>

<style scoped lang="scss">
.trigger-history-container {
  margin-top: 14px;
  //padding-left: 10%;
  //padding-right: 10%;

  .header-horizontal {
    display: flex;
    margin-bottom: 14px;
  }
}
.radio-group-customer-care {
  //margin-top: 8px;

  .radio-item-customer-care {
    margin-right: 6px;

    .radio-badge {
      font-weight: 13px;
    }
  }
}
.table-row-name {
  font-weight: bold;
}

.item-trigger-table-small {
  font-size: 13px;
}
.table {
  margin: 16px
}
.title-header {
  font-weight: bold;
  flex: 1;
}
.container-title{
  display: flex;
  flex-direction: row;
  padding: 16px 24px 16px 24px;
  margin-bottom: 25px;
  background-color: white
}
.input-search{
  width: 250px;
  margin-right: 16px;
}


</style>

