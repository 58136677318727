import {Flow, ZnsTemp} from "@/models/Flows/Flow";
import {FlowData, State} from "./states";
import {FlowContextData} from "@/models/Flows/FlowContext";
import {FlowConstants} from "@/models/Flows/FlowConstants";
import {Province} from "@/models/Dictionary/Dictionary";
import {SelectionAnt} from "@/models/ShopPages/ConfigData";

export type Getters = {
    getFlowData(state: State): FlowData
    getFlowContextData(state: State): FlowContextData | undefined
    getFlowConstants(state: State): FlowConstants | undefined
    getFlowViralTypes(state: State): string[]
    getZnsTemp(state: State): ZnsTemp[]
    getFlowsSelectionAnt(state: State): SelectionAnt[]
    getFlowContextSelectionAnt(state: State): SelectionAnt[]
    getFlowsSelectionAntWithoutAll(state: State): SelectionAnt[]
    getFlows(state: State): Flow[]
}
export const getters: Getters = {
    getFlowData(state: State): FlowData {
        if (state.flowData) {
            return state.flowData
        } else {
            return {
                flows: [],
                metadata: {}
            }
        }
    },
    getFlowContextData(state: State): FlowContextData | undefined {
        return state.flowContext;
    },
    getFlowConstants(state: State): FlowConstants | undefined {
        return state.flowConstants
    },
    getFlowViralTypes(state: State):string[]{
        if (state.flowConstants&&state.flowConstants.flow_viral_types){
            return state.flowConstants.flow_viral_types
        }
        return []
    },
    getZnsTemp(state: State): ZnsTemp[] {
        return state.znsTemp? state.znsTemp:[];
    },
    getFlowsSelectionAnt(state: State): SelectionAnt[] {
        if (state.flows) {
            const data = state.flows.map((item) => {
                const data: SelectionAnt = {
                    label: item.name,
                    value: item.id+''
                }
                return data;
            })
            const itemAll: SelectionAnt = {
                label: 'ALL',
                value: '0',
            }
            return [itemAll, ...data]
        } else {
            return []
        }
    },
    getFlowsSelectionAntWithoutAll(state: State): SelectionAnt[] {
        if (state.flows) {
            return state.flows.map((item) => {
                const data: SelectionAnt = {
                    label: item.name,
                    value: item.id+''
                }
                return data;
            })
        } else {
            return []
        }
    },
    getFlowContextSelectionAnt(state: State): SelectionAnt[] {
        if (state.flowContext?.context.flow_contexts) {
            const data = state.flowContext?.context.flow_contexts.map((item) => {
                const data: SelectionAnt = {
                    label: item.name,
                    value: item.id+''
                }
                return data;
            })
            const itemAll: SelectionAnt = {
                label: 'ALL',
                value: '0',
            }
            return [itemAll, ...data]
        } else {
            return []
        }
    },
    getFlows(state: State): Flow[] {
        if (state.flowData&&state.flowData.flows){
            return state.flowData.flows
        }else {
            return []
        }
    },
}