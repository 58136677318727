<template>
  <a-row>
    <a-col :span="8">
      <a-typography-title :level="2">{{$t('SETTING_SHOP')}}</a-typography-title>
      <a-typography-text type="secondary">{{$t('SETTING_SHOP')}}</a-typography-text>
    </a-col>
    <a-col :span="8"></a-col>
    <a-col :span="8">
      <a-row style="justify-content: flex-end">
        <a-button @click="save"  type="primary">{{$t('SAVE')}}</a-button>
      </a-row>

    </a-col>

  </a-row>

    <a-card  style="width: 55%;margin-top: 20px;border-radius: 10px">
     <template #title>
       <AppstoreOutlined />
       {{$t('CONFIGURATION')}}
     </template>
      <a-select
          allowClear
          :value="currentShop.currentShop.id"
          style="width: 100%"
          placeholder="Please select"
          @change="changeShop"
      >
        <a-select-option
            v-for="item in getShops.shops"
            :key="item.id"
            v-bind:value="item.id"
        >{{ item.shop_name }}
        </a-select-option>
      </a-select>
     <a-row style="margin-top: 20px">
       <a-col>
         <a-row style="margin-bottom: 20px" >
           <a-switch :checked="configShop.filter_post_unread_to_top === 'on'" @change="toggleFilterPostUnreadToTop"  />
           <a-typography-text style="margin-left: 20px">{{$t('UNREAD-DIALOGAGUE_TO_THE_TOP')}}</a-typography-text>
         </a-row>
         <a-row style="margin-bottom: 20px" >
           <a-switch :checked="configShop.show_full_tags === 'on'" @change="toggleShowFullTags" />
           <a-typography-text style="margin-left: 20px">{{$t('FULL_CARD_DISPLAY_MODE')}}</a-typography-text>
         </a-row>
<!--         <a-row style="margin-bottom: 20px" >-->
<!--           <a-switch :checked="auto_send_sms  === 1" @change="toggleAutoSendSms" />-->
<!--           <a-typography-text style="margin-left: 20px">{{$t('ORDER_TRACKING_MESSAGE')}}</a-typography-text>-->
<!--         </a-row>-->
         <a-row style="margin-bottom: 20px" >
           <a-switch :checked="configShop.order_employee_privilege_handle === 'on'" @change="toggleOrderPrivilege" />
           <a-typography-text style="margin-left: 20px">{{$t('RESTRICT_EMPLOYEE_ORDER_PRIVILEGES')}}</a-typography-text>
         </a-row>
         <a-row style="margin-bottom: 20px" >
           <a-switch :checked="configShop.merge_customer === 'on'" @change="toggleMergeCustomer" />
           <a-typography-text style="margin-left: 20px">{{$t('merge_customer')}}</a-typography-text>
         </a-row>
         <a-row style="margin-bottom: 20px" >
           <a-switch :checked="configShop.tag_operator !== ''" @change="toggleTagOperator" />
           <a-typography-text style="margin-left: 20px">{{$t('SIMULTANEOUS_MULTI-TAG_FILTERING_MODE')}}</a-typography-text>
         </a-row>
         <div style="margin-left: 30px">
           <a-row style="margin-bottom: 20px" >
             <a-switch size="small" :checked="configShop.tag_operator === 'OR'" @click="setTagOperator('AND')" />
             <a-typography-text style="margin-left: 20px">{{$t('filter_by_or_condition')}}</a-typography-text>
           </a-row>
           <a-row style="margin-bottom: 20px" >
             <a-switch size="small" :checked="configShop.tag_operator === 'AND' " @click="setTagOperator('OR')" />
             <a-typography-text style="margin-left: 20px">{{$t('filter_by_and_condition')}}</a-typography-text>
           </a-row>
         </div>


       </a-col>
     </a-row>


    </a-card>



</template>
<script >
import { AppstoreOutlined} from '@ant-design/icons-vue';
import {computed, onMounted, ref} from "vue";
import {useStore} from "@/store";
import {UsersMutationTypes} from "@/store/modules/user/mutation-types";
import {CustomersActionTypes} from "@/store/modules/customer/action-types";
import {useRouter} from "vue-router";
import APIs from "@/APIs/APIs";
import {ShopinfoActionTypes} from "@/store/modules/Shopinfo/action-types";
import {message} from "ant-design-vue";
import {useI18n} from "vue-i18n"
import {TagsActionTypes} from "@/store/modules/tag/action-types";
import {PageConfigMutationTypes} from "@/store/modules/PageConfig/mutation-types";
import useUserStore from "../../store/User";
import useOverviewStore from "../../store/Overview";
export default {
  components: {
    AppstoreOutlined,
  },
  setup(){
    const store = useStore()
    const router= useRouter()
    const i18n = useI18n()
    const userInfo = computed(()=> store.getters.getUserInfo)
    const shopInfo = ref({})
    const configShop = ref( {})
    const checked = ref(false)
    const currentShop =  useUserStore()
    const auto_send_sms = ref(0)
    const getShops = useUserStore()
    const fetchShopInfo = async () => {
      try{
        const res = await APIs.init().getShopInfo()
        if(res.data.status === 200) {
          shopInfo.value = res.data?.data ? res.data?.data : {}
          configShop.value = JSON.parse(shopInfo.value.page_config_general)

          // delete configShop.value["auto_send_sms"]
          // // configShop.value.auto_send_sms = shopInfo.value.auto_send_sms
          // auto_send_sms.value = shopInfo.value.auto_send_sms
          // console.log(configShop.value,'222')
        }else {
          shopInfo.value = {}
        }
      }catch (e){
        console.log(e)
      }

    };
    const changeShop = async (shopId) => {

      // if(shopId){
      //   shops.value.map(item => {
      //     if(item.id === shopId){
      //       configShop.value = JSON.parse(item.page_config_general)
      //     }
      //   })
      // }
      const response = await APIs.init().changeShop({
        shop_id: shopId,
        token: userInfo.value.token
      })
      if(response.status == 200 && response.data?.data){

        store.commit(UsersMutationTypes.SET_UserInfo,{...userInfo.value, ...{token: response.data?.data}})
        store.commit(PageConfigMutationTypes.GET_PAGE_CONFIG,'')
        store.commit(UsersMutationTypes.SET_CurrentShop,shopId);

        router.go()
      }


     }
const fetchTags = () =>{
  store.dispatch(TagsActionTypes.FETCH_TREE_TAGS, {payload: {type: "customer", search: ''}});

}
    const toggleFilterPostUnreadToTop =() => {
      configShop.value.filter_post_unread_to_top =  configShop.value.filter_post_unread_to_top == 'on' ? '' : 'on';
    }
    const toggleOrderPrivilege = () => {
          configShop.value.order_employee_privilege_handle =  configShop.value.order_employee_privilege_handle === 'on' ? 'off' : 'on';
    }
    const toggleMergeCustomer = () => {
      configShop.value.merge_customer =  configShop.value.merge_customer === 'on' ? 'off' : 'on';
    }
   const toggleShowFullTags = () => {
         configShop.value.show_full_tags = configShop.value.show_full_tags == 'on' ? '' : 'on';
    }
   const toggleTagOperator = () => {
         configShop.value.tag_operator = configShop.value.tag_operator == "" ? "OR" : "";
    }
    // const toggleAutoSendSms = () => {
    //   auto_send_sms.value = auto_send_sms.value === 0 ? 1 : 0;
    // }
    const setTagOperator =(operator) => {
      if (operator == 'AND') {
        configShop.value.tag_operator = configShop.value.tag_operator == 'AND' ? 'OR' : 'AND';
      }
      if (operator == 'OR') {
        configShop.value.tag_operator = configShop.value.tag_operator == 'AND' ? 'OR' : 'AND';
      }
    }

    const save = async () =>{

      try{
        const res = await APIs.init().updateShopInfo({
          // auto_send_sms: auto_send_sms.value,
          id: shopInfo.value.id,
          shop_name: shopInfo.value.shop_name,
          shop_email: shopInfo.value.shop_email,
          shop_tel: shopInfo.value.shop_tel,
          page_config_general: JSON.stringify(configShop.value)


        })
        if(res.data.status === 200){
           message.success(i18n.t('SUCCESS'))
          await fetchShopInfo()
        }else {
          message.success(i18n.t(res.data.message + ''))
        }
      }catch (e){
        console.log(e)
      }




    }
    onMounted(() =>{
      fetchShopInfo()
      fetchTags()
    })
    return{
      userInfo,
      save,
      shopInfo,
      checked,
      changeShop,
      configShop,
      // toggleAutoSendSms,
      toggleOrderPrivilege,
      toggleTagOperator,
      toggleShowFullTags,
      toggleFilterPostUnreadToTop,
      setTagOperator,
      currentShop,
      auto_send_sms,
      getShops,
      toggleMergeCustomer
    }
  }
}
</script>
