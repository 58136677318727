import {FlowData, State} from "./states";
import {FlowsMutationTypes} from "./mutation-types";

export type Mutations<S = State> = {
    [FlowsMutationTypes.SET_VIRAL](state: S, payload: FlowData | undefined): void;
}

export const mutations: Mutations = {
    [FlowsMutationTypes.SET_VIRAL](state: State, payload: FlowData | undefined): void {
        state.flowData = payload
    },
}
