import {State} from "./states";
import {Transporter} from "@/models/Transporter/Transporter";

export type Getters = {
    getTransporter(state: State): Transporter[]

}
export const getters: Getters = {
    getTransporter(state: State): Transporter[] {
        if (state.data) {
            return state.data
        } else {
            return []
        }
    },

}