import {State} from "@/store/modules/PageConfig";


import {PageConfig} from "@/models/PageConfig/PageConfig";

export type Getters = {
    getPageConfig(state: State):  PageConfig[]
    getCurrentPageId(state:State):   number
    getCrrentTab(state:State):string

}
export const getters: Getters = {

    getPageConfig(state: State):  PageConfig[] {

        if (state.pageConfig) {
            return state.pageConfig
        } else {
            return []
        }
    },
    getCurrentPageId(state: State): number {
        if(state.pageId){
            return state.pageId
        }else {
            return 0
        }
    },
    getCrrentTab(state: State): string {
        if(state.tabkey){
            return state.tabkey
        }else {
            return ''
        }
    }


}
