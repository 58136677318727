import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-7c4a7e03"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "container-title" }
const _hoisted_2 = { class: "title-header" }
const _hoisted_3 = { class: "input-search" }
const _hoisted_4 = ["onClick"]
const _hoisted_5 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_input_search = _resolveComponent("a-input-search")!
  const _component_plus_outlined = _resolveComponent("plus-outlined")!
  const _component_a_button = _resolveComponent("a-button")!
  const _component_eye_outlined = _resolveComponent("eye-outlined")!
  const _component_edit_outlined = _resolveComponent("edit-outlined")!
  const _component_a_menu_item = _resolveComponent("a-menu-item")!
  const _component_delete_outlined = _resolveComponent("delete-outlined")!
  const _component_a_popconfirm = _resolveComponent("a-popconfirm")!
  const _component_a_menu = _resolveComponent("a-menu")!
  const _component_a_dropdown = _resolveComponent("a-dropdown")!
  const _component_a_table = _resolveComponent("a-table")!
  const _component_a_input = _resolveComponent("a-input")!
  const _component_a_form_item = _resolveComponent("a-form-item")!
  const _component_a_form = _resolveComponent("a-form")!
  const _component_a_modal = _resolveComponent("a-modal")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("h3", _hoisted_2, _toDisplayString(_ctx.$t(_ctx.$route.name)), 1),
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_a_input_search, {
          value: _ctx.categoryRequest.search,
          "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.categoryRequest.search) = $event)),
          placeholder: "Search",
          onChange: _ctx.handleOnChange
        }, null, 8, ["value", "onChange"])
      ]),
      (_ctx.getMyRole?.is_create)
        ? (_openBlock(), _createBlock(_component_a_button, {
            key: 0,
            type: "primary",
            class: "button-add-product",
            onClick: _ctx.create
          }, {
            default: _withCtx(() => [
              _createVNode(_component_plus_outlined),
              _createTextVNode(" " + _toDisplayString(_ctx.$t('ADD')), 1)
            ]),
            _: 1
          }, 8, ["onClick"]))
        : _createCommentVNode("", true)
    ]),
    _createVNode(_component_a_table, {
      size: "middle",
      scroll: {  y: '80vh' },
      columns: _ctx.columns,
      "data-source": _ctx.getCareers.career,
      class: "table"
    }, {
      name: _withCtx(({record}) => [
        _createElementVNode("a", {
          onClick: ($event: any) => (_ctx.edit(record)),
          class: "table-row-name"
        }, _toDisplayString(record.name), 9, _hoisted_4)
      ]),
      description: _withCtx(({record}) => [
        _createElementVNode("span", null, _toDisplayString(record.description), 1)
      ]),
      updated_at: _withCtx(({record}) => [
        _createElementVNode("span", null, _toDisplayString(_ctx.dateTime(record.updated_at)), 1)
      ]),
      action: _withCtx(({record}) => [
        _createVNode(_component_a_dropdown, null, {
          overlay: _withCtx(() => [
            _createVNode(_component_a_menu, null, {
              default: _withCtx(() => [
                _createElementVNode("a", {
                  onClick: ($event: any) => (_ctx.edit(record))
                }, [
                  (_ctx.getMyRole?.is_edit)
                    ? (_openBlock(), _createBlock(_component_a_menu_item, {
                        key: 0,
                        class: "router-product-edit"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_edit_outlined),
                          _createTextVNode(" " + _toDisplayString(_ctx.$t('EDIT')), 1)
                        ]),
                        _: 1
                      }))
                    : _createCommentVNode("", true)
                ], 8, _hoisted_5),
                (_ctx.getMyRole?.is_delete)
                  ? (_openBlock(), _createBlock(_component_a_menu_item, { key: 0 }, {
                      default: _withCtx(() => [
                        _createVNode(_component_a_popconfirm, {
                          placement: "bottom",
                          "ok-text": _ctx.$t('OK'),
                          "cancel-text": _ctx.$t('CANCEL'),
                          onConfirm: ($event: any) => (_ctx.confirmDelete(record))
                        }, {
                          title: _withCtx(() => [
                            _createElementVNode("p", null, _toDisplayString(_ctx.$t('CONFIRM_DELETE') + +' '+record.category_name + '?'), 1)
                          ]),
                          default: _withCtx(() => [
                            _createVNode(_component_delete_outlined),
                            _createTextVNode(" " + _toDisplayString(_ctx.$t('DELETE')), 1)
                          ]),
                          _: 2
                        }, 1032, ["ok-text", "cancel-text", "onConfirm"])
                      ]),
                      _: 2
                    }, 1024))
                  : _createCommentVNode("", true)
              ]),
              _: 2
            }, 1024)
          ]),
          default: _withCtx(() => [
            _createVNode(_component_a_button, { type: "text" }, {
              default: _withCtx(() => [
                _createVNode(_component_eye_outlined)
              ]),
              _: 1
            })
          ]),
          _: 2
        }, 1024)
      ]),
      _: 1
    }, 8, ["columns", "data-source"]),
    _createVNode(_component_a_modal, {
      visible: _ctx.editVisible,
      "onUpdate:visible": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.editVisible) = $event)),
      title: _ctx.formState.id?_ctx.$t('UPDATE_CATEGORY'):_ctx.$t('ADD_CATEGORY'),
      footer: null,
      style: {"width":"40%"}
    }, {
      default: _withCtx(() => [
        _createVNode(_component_a_form, {
          ref: "formRef",
          model: _ctx.formState,
          rules: _ctx.rules,
          "label-col": _ctx.labelCol,
          "wrapper-col": _ctx.wrapperCol
        }, {
          default: _withCtx(() => [
            _createVNode(_component_a_form_item, {
              label: _ctx.$t('NAME'),
              name: "name"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_a_input, {
                  value: _ctx.formState.name,
                  "onUpdate:value": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.formState.name) = $event))
                }, null, 8, ["value"])
              ]),
              _: 1
            }, 8, ["label"]),
            _createVNode(_component_a_form_item, {
              label: _ctx.$t('DESCRIPTION'),
              name: "description"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_a_input, {
                  value: _ctx.formState.description,
                  "onUpdate:value": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.formState.description) = $event))
                }, null, 8, ["value"])
              ]),
              _: 1
            }, 8, ["label"]),
            _createVNode(_component_a_form_item, { "wrapper-col": { offset: 8 } }, {
              default: _withCtx(() => [
                _createVNode(_component_a_button, {
                  type: "primary",
                  onClick: _ctx.onSubmit
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.formState.id?_ctx.$t('UPDATE'):_ctx.$t('CREATE')), 1)
                  ]),
                  _: 1
                }, 8, ["onClick"]),
                _createVNode(_component_a_button, {
                  style: {"margin-left":"10px"},
                  onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.editVisible=!_ctx.editVisible))
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.$t('CANCEL')), 1)
                  ]),
                  _: 1
                })
              ]),
              _: 1
            })
          ]),
          _: 1
        }, 8, ["model", "rules", "label-col", "wrapper-col"])
      ]),
      _: 1
    }, 8, ["visible", "title"])
  ], 64))
}