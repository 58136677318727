<template>
  <a-row>
    <a-col :span="14">
      <a-typography-title :level="2">{{$t('auto_reply')}}</a-typography-title>
      <a-typography-text type="secondary">{{$t('auto_reply_conversations')}}</a-typography-text>
    </a-col>
    <a-col :span="2"></a-col>
    <a-col v-if="isPage" :span="8">
      <a-row style="justify-content: flex-end">
        <a-button @click="save"  type="primary">{{$t('SAVE')}}</a-button>
      </a-row>

    </a-col>
  </a-row>
  <a-row  style="margin-top: 20px;">
    <a-col :span="11">
      <a-typography-title :level="5">{{$t('change_shop')}}</a-typography-title>
      <a-select
          allowClea
          :value="currentShop.currentShop.id"
          style="width: 100%"
          placeholder="Please select"
          @change="changeShop"
      >
        <a-select-option
            v-for="item in getShops.shops"
            :key="item.id"
            v-bind:value="item.id"
        >{{ item.shop_name }}
        </a-select-option>
      </a-select>
    </a-col>
    <a-col :span="2"></a-col>
    <a-col :span="11">
      <a-typography-title :level="5">{{$t('select_page')}}</a-typography-title>
      <a-select
          allowClea
          show-search
          style="width: 100%"
          placeholder="Please select"
          @change="changePage"
          v-model:value="getCurrentPageId"
      >
        <a-select-option
            v-for="item in configPage.pageConfig"
            :key="item.id"
            v-bind:value="item.page_id"
        > <img
            v-if="item.page_type === 2"
            draggable="false"
            src="@/assets/icons/zalo-seeklogo.com.svg"
            style="width: 20px;margin-right: 20px"
        />
          <img
              v-else-if="item.page_type === 1"
              draggable="false"
              src="@/assets/icons/facebook-svgrepo-com.svg"
              style="width: 20px;margin-right: 20px"
          />
          <img
              v-else-if="item.page_type === 3"
              draggable="false"
              src="@/assets/icons/instagram-svgrepo-com.svg"
              style="width: 20px;margin-right: 20px"
          />
          <img
              v-else-if="item.page_type === 4"
              draggable="false"
              src="@/assets/icons/line-svgrepo-com.svg"
              style="width: 20px;margin-right: 20px"
          />{{ item.page_name }}
        </a-select-option>
      </a-select>
    </a-col>
    <a-row v-if="!isPage" style="margin-top: 30px;width: 100%" justify="center">
      <a-typography-title  :level="3">{{$t('PLEASE_CHOOSE_SHOP_AND_FANPAGE')}}</a-typography-title>
    </a-row>
  </a-row>
  <a-row v-if="isPage" style="margin-top: 20px;">
    <a-col :span="24">
      <a-card  style="width: 100%;margin-top: 20px;border-radius: 10px">
        <template #title>
          <a-row>
            <a-col :span="8">
              <MenuFoldOutlined />
              {{$t('auto_reply_conversations')}}
            </a-col>
            <a-col :span="15">
            </a-col>
            <a-col :span="1">

            </a-col>
          </a-row>

        </template>

        <a-row>
          <a-col :span="12">
             <a-row justify="center">
               <a-switch style="margin-right: 10px" v-model:checked="configData.default_inbox_reply" />
               <a-typography-text strong>{{$t('MESSENGER_WINDOW')}}</a-typography-text>
             </a-row>
          </a-col>
          <a-col :span="12">
            <a-row justify="center">
              <a-switch style="margin-right: 10px" v-model:checked="configData.default_comment_reply" />
              <a-typography-text strong>{{$t('comment')}}</a-typography-text>
            </a-row>
          </a-col>
        </a-row>
        <a-row style="margin-top: 20px">
          <a-col :span="12">
            <div  v-if="configData.default_inbox_reply">
              <span style="margin-right: 20px">{{ $t('reply_time') }}</span>
              <a-radio-group v-model:value="configData.default_inbox_reply_value" button-style="solid">

                <a-radio-button value="now">{{ $t('immediately') }}</a-radio-button>
                <a-radio-button value="miss">{{ $t('missed_conversation') }}</a-radio-button>
              </a-radio-group>
            </div>
          </a-col>
          <a-col v-if="configData.default_comment_reply" :span="12">
            <div>
              <span style="margin-right: 20px">{{ $t('reply_time') }}</span>
              <a-radio-group v-model:value="configData.default_comment_reply_value" button-style="solid">

                <a-radio-button value="now">{{ $t('immediately') }}</a-radio-button>
                <a-radio-button value="miss">{{ $t('missed_conversation') }}</a-radio-button>
              </a-radio-group>
            </div>
            <div style="margin-top: 20px">
              <span style="margin-right: 20px">{{ $t('reply_type') }}</span>
              <a-radio-group v-model:value="configData.default_comment_reply_method" button-style="solid">

                <a-radio-button value="comment">{{ $t('comment') }}</a-radio-button>
                <a-radio-button value="message">{{ $t('MESSAGE') }}</a-radio-button>
              </a-radio-group>
            </div>
          </a-col>

        </a-row>

        <a-row  style="margin-top: 20px">
          <a-col :span="24">
            <a-collapse v-model:activeKey="activeKey">
              <a-collapse-panel key="1" >
                <template #header>
                  <a-row style="width: 100%">
                    <a-col :span="12">
                      <strong>{{ $t('message_content') }}</strong>
                    </a-col>
                    <a-col :span="6"></a-col>
                    <a-col :span="6">
                      <strong>{{
                          $t('comments_with_phone_number')
                        }}</strong>
                    </a-col>
                  </a-row>
                </template>
                <a-row style="margin-top: 20px" v-for="(item,index) in configData.default_answer" :key="index">
                  <a-col :span="17">
                    <div v-if="contentAutoReplyEditSelected === index">
                      <div v-if="change === 'content'">

                        <a-textarea style="margin-top: 10px"  :rows="4"  v-model:value="item.content" placeholder="##full_name##...." allow-clear />
                        <div style="position: absolute; bottom:20px;right: 20px;z-index: 999">
                          <a-popover  >
                            <template #content>
                              <Picker :data="emojiIndex" set="twitter" @select="(e) => item.content+=e.native " />

                            </template>
                            <div style="cursor: pointer;font-size: 16px">
                              😃
                            </div>
                          </a-popover>
                        </div>
                      </div>

                    </div>
                    <span v-else>
                           <strong>{{ index + 1 }}. </strong>
                             <span>
                                                                {{ item.content }}
                                                            </span>
                    </span>

                  </a-col>
                  <a-col :span="1"></a-col>
                  <a-col :span="6">
                    <a-row>
                      <a-col :span="12">
                        <a-tooltip placement="topLeft" :title="$t('comments_with_phone_number')" arrow-point-at-center>
                          <a-switch v-model:checked="item.mobile">
                            <template #checkedChildren><PhoneOutlined /></template>
                            <template #unCheckedChildren><PhoneOutlined /></template>
                          </a-switch>
                        </a-tooltip>
                        <a-button v-if="contentAutoReplyEditSelected === index"  @click="()=>item.content=item.content+' ##full_name##'" style="margin-top: 20px" type="primary">{{$t('full_name')}}</a-button>
                      </a-col>
                      <a-col :span="4">

                      </a-col>
                      <a-col :span="8">
                       <a-row>
                         <a-button @click="editContentAutoReply(index)" type="primary">
                           <template #icon>
                             <CheckOutlined v-if="contentAutoReplyEditSelected === index" />
                             <EditOutlined v-else />
                           </template>

                         </a-button>
                       </a-row>
                        <a-row style="margin-top: 20px">
                          <a-button @click="deleteAutoContent(index)"  type="primary">
                            <template #icon><DeleteOutlined /></template>

                          </a-button>
                        </a-row>

                      </a-col>

                    </a-row>
                  </a-col>
                </a-row>
                <a-row style="margin-top: 20px" justify="center">
                  <a-button @click="addAutoContent"  style="width: 50%" type="dashed">
                    <PlusOutlined style="margin-right: 10px" />
                    {{$t('new_reply')}}
                  </a-button>
                </a-row>

              </a-collapse-panel>
            </a-collapse>
          </a-col>
        </a-row>


      </a-card>
      <a-card  style="width: 100%;margin-top: 20px;border-radius: 10px">
        <template #title>
          <a-row>
            <a-col :span="8">
              <MenuFoldOutlined />
              {{$t('title_auto_reply')}}
            </a-col>
            <a-col :span="15">
            </a-col>
            <a-col :span="1">

            </a-col>
          </a-row>

        </template>

        <a-row>
          <a-switch v-model:checked="configData.status_reply_comment_and_inbox" style="margin-right: 10px" />

          <a-typography-text v-if="configData.status_reply_comment_and_inbox" strong>{{$t('on')}}</a-typography-text>
          <a-typography-text v-else  strong>{{$t('off')}}</a-typography-text>
        </a-row>

        <a-row v-if="configData.status_reply_comment_and_inbox">

          <a-row style="margin-top: 20px;width: 100%">
            <a-col :span="5">
              <a-row justify="center">
                <a-typography-text strong>{{$t('condition_type')}}</a-typography-text>
              </a-row>

            </a-col>
            <a-col :span="1"></a-col>
            <a-col :span="4">
              <a-row justify="center">
                <a-typography-text strong>{{$t('keywords_content')}}</a-typography-text>
              </a-row>
            </a-col>
            <a-col :span="1"></a-col>
            <a-col :span="6">
              <a-row justify="center">
                <a-typography-text strong>{{$t('reply_comment')}}</a-typography-text>
              </a-row>
            </a-col>
            <a-col :span="1"></a-col>
            <a-col :span="6">
              <a-row justify="center">
                <a-typography-text strong>{{$t('private_message_content')}}</a-typography-text>
              </a-row>
            </a-col>
          </a-row>

          <a-row v-for="(item,index) in configData.reply_comment_and_inbox" :key="index" style="margin-top: 20px;width: 100%">
            <a-row style="width: 100%">
              <a-col :span="5">
                <KeyOutlined />
                <a-select
                    allowClea

                    style="width: 100%;margin-top: 5px"
                    placeholder="Please select"
                    @change="(value) => item.content_comment =value ==='comment'?'':value"
                    :value="(item.content_comment==='comment_in_orther_condition')? 'comment_in_orther_condition': (item.content_comment==='comment_is_phone'||item.content_comment==='comment_in_orther_condition'?item.content_comment:'comment')"
                >
                  <a-select-option value="comment_is_phone">
                    {{ $t('comments_with_phone_number') }}
                  </a-select-option>
                  <a-select-option
                      value="comment_in_orther_condition">
                    {{ $t('other_comment_type') }}
                  </a-select-option>
                  <a-select-option value="comment">
                    {{ $t('other_with_keywords') }}
                  </a-select-option>
                </a-select>
                <a-button @click="deleteAutoCommentInbox(index)" style="margin-top: 20px" type="primary" danger>{{$t('CUSTOMER_DELETE')}}</a-button>
              </a-col>
              <a-col :span="1"></a-col>
              <a-col :span="4">
                <a-row justify="space-between">
                  <MessageOutlined />
                  <a-button @click="item.content_comment+=' ##full_name##'" size="small" type="primary">{{$t('full_name')}}</a-button>
                </a-row>
                <a-textarea style="margin-top: 5px" :rows="4" v-model:value="item.content_comment" :disabled="item.content_comment === 'comment_in_orther_condition' ? true : false" placeholder="##full_name##...." allow-clear />
              </a-col>
              <a-col :span="1"></a-col>
              <a-col :span="6">
                <a-row  justify="space-between">
                  <MessageOutlined />
                  <a-button @click="item.content_reply_comment+=' ##full_name##'"  size="small" type="primary">{{$t('full_name')}}</a-button>
                  <a-textarea style="margin-top: 5px"  :rows="4"  v-model:value="item.content_reply_comment" placeholder="##full_name##...." allow-clear />
                  <div style="position: absolute; bottom:20px;right: 20px;z-index: 999">
                    <a-popover   >
                      <template #content>
                        <Picker :data="emojiIndex" set="twitter" @select="(e) => item.content_reply_comment+=e.native " />

                      </template>
                      <div style="cursor: pointer;font-size: 16px">
                        😃
                      </div>
                    </a-popover>
                  </div>
                </a-row>
              </a-col>
              <a-col :span="1"></a-col>
              <a-col :span="6">
                <div v-if="change === 'content'">
                  <a-row justify="space-between">
                    <a-button @click="item.content_inbox+=' ##full_name##'"  size="small" type="primary">{{$t('full_name')}}</a-button>
                  </a-row>
                  <a-textarea style="margin-top: 5px"  :rows="4"  v-model:value="item.content_inbox" placeholder="##full_name##...." allow-clear />
                  <div style="position: absolute; bottom:20px;right: 20px;z-index: 999">
                    <a-popover    >
                      <template #content>
                        <Picker :data="emojiIndex" set="twitter" @select="(e) =>item.content_inbox+=e.native " />

                      </template>
                      <div style="cursor: pointer;font-size: 16px">
                        😃
                      </div>
                    </a-popover>
                  </div>
                </div>

              </a-col>
            </a-row>
            <a-row style="width: 100%;margin-top: 20px">
              <a-col :span="5">

              </a-col>
              <a-col :span="1"></a-col>
              <a-col :span="18">
                <a-row style="margin-bottom: 5px">
                  <KeyOutlined />
                  <a-typography-text style="margin-left: 10px">{{$t('keywords_posts')}}</a-typography-text>
                </a-row>
                <a-select
                    v-model:value="item.post_id"

                    mode="multiple"
                    style="width: 100%"
                    placeholder="Please select"

                    @popupScroll="popupScroll"
                >
                  <a-select-option v-for="i in item.posts" :key="i.post_id" :value="i.post_id">
                    {{i.post_id}}
                  </a-select-option>
                </a-select>
              </a-col>
            </a-row>

          </a-row>


          <a-row style="margin-top: 30px;width: 100%" justify="center">
            <a-button @click="addAutoCommentInbox"  style="width: 50%" type="dashed">
              <PlusOutlined style="margin-right: 10px" />
              {{$t('new_reply')}}
            </a-button>
          </a-row>
        </a-row>




      </a-card>
    </a-col>

  </a-row>



</template>
<script  >
import { PhoneOutlined,MessageOutlined,KeyOutlined,EditOutlined,PlusOutlined,DeleteOutlined,FileImageFilled,CheckOutlined,MenuFoldOutlined,RightOutlined,LeftOutlined} from '@ant-design/icons-vue';
import {computed, onMounted, ref,watch} from "vue";
import {useStore} from "@/store";
import {useRouter} from "vue-router";
import {CustomersActionTypes} from "@/store/modules/customer/action-types";
import {UsersMutationTypes} from "@/store/modules/user/mutation-types";
import {MixUtils} from "@/utils/Utils";
import data from 'emoji-mart-vue-fast/data/all.json'
import { Picker, EmojiIndex } from "emoji-mart-vue-fast/src";
import 'emoji-mart-vue-fast/css/emoji-mart.css'
import {PageConfigActionTypes} from "@/store/modules/PageConfig/action-types";
import APIs from "@/APIs/APIs";
import {message} from "ant-design-vue";
import {useI18n} from "vue-i18n";
import {PageConfigMutationTypes} from "@/store/modules/PageConfig/mutation-types";
import usePageConfigStorageStore from "../../store/PageConfig";
import useUserStore from "../../store/User";
import useOverviewStore from "../../store/Overview";






export default {
  components: {
    Picker,
    PhoneOutlined,
    MessageOutlined,
    KeyOutlined,
    EditOutlined,
    CheckOutlined,
    DeleteOutlined,
    PlusOutlined,
    MenuFoldOutlined,


  },

  setup(){
    const i18n = useI18n()
    const store = useStore()
    const router = useRouter()
    const userInfo = computed(()=> store.getters.getUserInfo)
    const getShops = useUserStore()
    const currentShop =  useUserStore()
    const pages = computed(() => store.getters.getCurrentShop)
    const configPage = usePageConfigStorageStore()
    const getCurrentPageId = computed(()=> store.getters.getCurrentPageId)
    const isPage = ref(false)
    const tagList = ref([])
    const contentAutoReplyEditSelected = ref(-1)
    const inboxReply = ref('now')
    const commentReply = ref('now')
    const commentReplyMethod = ref('comment')
    const emojisOutput = ref("")
    const emojiIndex = ref(new EmojiIndex(data))
    const emoji = ref(false)
    const contentIbox=ref('')
    const change = ref('content')
    const activeKey = ref(['1']);
    const configCurrentPage = ref({})
    const configData = ref({})
    const tabkey = computed(() => store.getters.getCrrentTab)

    watch(tabkey, async (newQuestion, oldQuestion) => {
      if(newQuestion !== oldQuestion){
        if( configPage.pageConfig){
          isPage.value=true
          configPage.pageConfig.map(item => {
            // @ts-ignore
            if(item.page_id === getCurrentPageId.value){
              configCurrentPage.value = item
              configData.value = JSON.parse(item.config_data)
              console.log(configData.value.tags,'tags')

            }

          })

        }
      }
    })


    const editContentAutoReply = (index) =>{
      console.log(index,contentAutoReplyEditSelected.value)
      if(index === contentAutoReplyEditSelected.value){
        contentAutoReplyEditSelected.value =-1
      }else {
        contentAutoReplyEditSelected.value =index
      }

    }
    const deleteAutoContent = (i) =>{
      configData.value.default_answer = configData.value.default_answer.filter((item,index) => index !== i)
    }
    const changeShop = async (shopId) => {
      const response = await APIs.init().changeShop({
        shop_id: shopId,

        token: userInfo.value.token
      })
      if(response.status == 200 && response.data?.data){

        store.commit(UsersMutationTypes.SET_UserInfo,{...userInfo.value, ...{token: response.data?.data}})

        store.commit(PageConfigMutationTypes.GET_PAGE_CONFIG,'')
        store.commit(UsersMutationTypes.SET_CurrentShop,shopId);

        router.go()
      }
    }
    const changePage = (pageId) => {
      if(pageId){
        store.commit(PageConfigMutationTypes.GET_PAGE_CONFIG,pageId)
      }
      if( configPage.pageConfig){
        isPage.value=true
        configPage.pageConfig.map(item => {
          if(item.page_id === pageId ){
            configCurrentPage.value = item
            configData.value = JSON.parse(item.config_data)

          }

        })

      }



    }

    const fetchApi = async () => {
      usePageConfigStorageStore.getState().getPageConfig({shop_id: useUserStore.getState().currentShop.id})

      // store.dispatch(PageConfigActionTypes.FETCH_PAGE_CONFIG,{shop_id: currentShop.value.id})
    }
    const save = async () =>{
      if(typeof configData.value.missing_time !== "number"){
        configData.value.missing_time = Number(configData.value.missing_time)
      }
      if(typeof configData.value.font_size_message !== "number") {
        configData.value.font_size_message = Number(configData.value.missing_time)
      }
      if(  isNaN(configData.value.missing_time) ){
        configData.value.missing_time = 1
      }
      if( isNaN(configData.value.font_size_message) ) {
        configData.value.font_size_message = 16
      }
      console.log({
        config_data: JSON.stringify(configData.value) ,
        page_id: configCurrentPage.value.page_id,
        shop_id: useUserStore.getState().currentShop.id,
        page_type:configCurrentPage.value.page_type ,
        page_name: configCurrentPage.value.page_name,
        status:configCurrentPage.value.status
      },"autoReplyUpdate")
      try{
        const res = await APIs.init().updatePageConfig({
          config_data: JSON.stringify(configData.value) ,
          page_id: configCurrentPage.value.page_id,
          shop_id: useUserStore.getState().currentShop.id,
          page_type:configCurrentPage.value.page_type ,
          page_name: configCurrentPage.value.page_name,
          status:configCurrentPage.value.status,
          page_token:configCurrentPage.value.page_token
        })

        if(res.data?.status === 200){
          message.success(i18n.t(res.data?.message))
         await fetchApi()

        }
      }catch (err){
        console.log(err)
      }

    }
    const addAutoContent = () => {
      configData.value.default_answer.push({
        content: '',
        mobile: false

      })
      console.log( configData.value.default_answer.length - 1)
      contentAutoReplyEditSelected.value = configData.value.default_answer.length - 1
    }
    const addAutoCommentInbox = () =>{
      configData.value.reply_comment_and_inbox.push({
        id:MixUtils.methods.getUUID(),
        content_comment: 'comment_in_orther_condition',
        content_reply_comment: '',
        content_inbox: '',
        type: 'message',
        posts: [],
      })
    }
    const deleteAutoCommentInbox = (i) => {
      configData.value.reply_comment_and_inbox = configData.value.reply_comment_and_inbox.filter((item,index) => index !== i)
    }
    const checkedMessenger = ref(false)
    const checkedComment = ref(false)
    const handleChange = (value) => {
      console.log(`selected ${value}`);
    };
    onMounted(() => {
      if(getCurrentPageId.value && configPage.pageConfig){
        isPage.value=true
        configPage.pageConfig.map(item => {
          if(item.page_id === getCurrentPageId.value){
            configCurrentPage.value = item
            configData.value = JSON.parse(item.config_data)

          }

        })

      }
    })
    return{
      userInfo,
      getShops,
      save,
      currentShop,
      checkedMessenger,
      checkedComment,
      pages,
      changeShop,
      changePage,
      inboxReply,
      commentReply,
      emoji,
      addAutoCommentInbox,

      configData,
      emojiIndex,
      emojisOutput,
      contentIbox,
      isPage,
      change,

      contentAutoReplyEditSelected,
      editContentAutoReply,

      activeKey,
      deleteAutoContent,

      configPage,
      tagList,

      handleChange,
      commentReplyMethod,

      addAutoContent,
      deleteAutoCommentInbox,
      getCurrentPageId






    }
  }
}
</script>
