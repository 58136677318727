<template>
  <div class="container-title">
    <h3 class="title-header">{{$t($route.name)}}</h3>

    <div class="input-search">
      <a-input-search
          class="input-search"
          :placeholder="$t('SEARCH')"
          v-model:value="searchInput"
          @change="onSearchChange"
      />
    </div>
  </div>
  <div style="display: flex;justify-content: flex-end;margin-right: 20px">
    <a-dropdown >
      <template #overlay>
        <a-menu @click="menuConnectionListener">
          <a-menu-item key="1" class="menu-item" style="justify-content: center">
            <img
                src="@/assets/icons/Gmail_icon.svg"
                class="anticon fix-icon-menu"
            />
            Gmail
          </a-menu-item>
        </a-menu>
      </template>
      <a-button type="primary"><plus-outlined/>{{ $t("CONNECT_PLATFORM") }}</a-button>
    </a-dropdown>
  </div>
  <a-modal :footer="false"  v-model:visible="connectPlatformVisible"   width="600px"
             >
    <template #title>
      <div style="display: flex; justify-content: center;align-items: center">
        <span >
          <img
              src="@/assets/icons/Gmail_icon.svg"
              class="anticon"
              style="height: 20px; width: 20px;margin-right: 10px"
          />
          SMTP
        </span>

      </div>
    </template>
    <a-form
        ref="formRef"
        labelAlign="left"
        :rules="rules"
        :model="formState"
        :label-col="{ span: 6  }"
        :wrapper-col="{ span: 20 }"  >
      <div style="margin-bottom: 20px">
        <a-form-item name="email" >
          <template  #label>
            <a-typography-text  strong>Email</a-typography-text>
          </template>
          <a-input  v-model:value="formState.email" placeholder="Email"   />
        </a-form-item>
      </div>
      <div style="margin-bottom: 20px">
      <a-form-item name="password" >
        <template #label>
          <a-typography-text strong> {{$t('INPUT_PASSWORD')}}</a-typography-text>
        </template>
        <a-input-password v-model:value="formState.password" :placeholder="$t('INPUT_PASSWORD')"  />

      </a-form-item>
      </div>
      <div style="margin-bottom: 20px">
      <a-form-item name="host" >
        <template #label>
          <a-typography-text strong> SMTP Host</a-typography-text>
        </template>
        <a-input v-model:value="formState.smtp_host" placeholder="Smtp Host" />
      </a-form-item>
      </div>
      <div style="margin-bottom: 20px">
        <a-form-item name="post" >
          <template #label>
            <a-typography-text strong> SMTP Port</a-typography-text>
          </template>
          <a-input-number  style="width: 100%"  v-model:value="formState.smtp_port" placeholder="Smtp Post"  />

        </a-form-item>
      </div>
      <div style="margin-bottom: 20px">
        <a-form-item  name="to">
          <template #label>
            <a-typography-text strong> {{$t('MAIL_TO')}}</a-typography-text>
          </template>
          <a-input v-model:value="formState.mail_to" :placeholder="$t('MAIL_TO')"  />
        </a-form-item>
      </div>
      <div style="margin-bottom: 20px">
        <a-form-item  name="content">
          <template  #label>
            <a-typography-text  strong> {{$t('test_content')}}</a-typography-text>
          </template>
          <a-textarea v-model:value="formState.content" :placeholder="$t('test_content')" allow-clear  />

        </a-form-item>

      </div>
      <!-- <div style="margin-bottom: 20px">
        <a-form-item >
          <template  #label>
            <a-typography-text   strong> {{$t('Attachments')}}</a-typography-text>
          </template>
          <a-input  v-model:value="formState.attachments" :placeholder="$t('Attachments')" />
        </a-form-item>

      </div> -->
      <a-form-item>
        <div style="display: flex; justify-content: center;align-items: center">
          <a-button style="width: 30%"  type="primary" @click="onSubmit">{{$t('send_test_&_save')}}</a-button>
        </div>
      </a-form-item>
    </a-form>

  </a-modal>


    <a-table
        size="middle"
        :scroll="{  y: '80vh' }"
        :rowKey="record => record.id"
        :columns="columns" :data-source="pageList"
        :pagination="false"
        class="table">
      <template #page_name="{ text }">
        <a class="table-row-name" type="text">
          {{ text }}
        </a>
      </template>
      <template #page_type="{ record }">
        <span>
<!--          <img src="@/assets/icons/zalo-flat.svg" class="anticon" style="width: 16px;height: 16px;" v-if="record.page_type===2"/>-->
<!--          <instagram-outlined v-else-if="record.page_type===3"/>-->
<!--          <line-outlined v-else-if="record.page_type===4"/>-->
<!--          <facebook-outlined v-else/>-->
         {{ getPageTypeName(record.page_type) }}
        </span>
      </template>
      <template #created_at="{ text }">
        <span>
          {{ dateTimeDisplayFormatFromString(text) }}
        </span>
      </template>
      <template #action="{record}">
        <a-dropdown v-if="record.page_type === 5" >
          <a-button type="text">
            <eye-outlined />
          </a-button>
          <template #overlay>
            <a-menu>
              <a-menu-item
                  @click="editEmail(record)">
                <edit-outlined/>
                {{$t('EDIT')}}
              </a-menu-item>
              <a-menu-item>
                <a-popconfirm
                    :title="$t('GMAIL_CONFIRM_DELETE')"
                    :ok-text="$t('YES')"
                    :cancel-text="$t('NO')"
                    @confirm="deleteMailTest(record.page_id)"
                >
                  <delete-outlined/>
                  {{$t('DELETE')}}
                </a-popconfirm>

              </a-menu-item>
            </a-menu>
          </template>
        </a-dropdown>

      </template>
    </a-table>

</template>

<script lang="ts">
import {Options, Vue} from "vue-class-component";
import {PlusOutlined,EditOutlined,DeleteOutlined,EyeOutlined} from "@ant-design/icons-vue"
import {mapGetters} from "vuex";
import {MixUtils} from "@/utils/Utils";
import APIs from "@/APIs/APIs";

import {message} from "ant-design-vue";
import i18n from "@/i18n";
import {ref} from "vue";
@Options({
  components: {
    PlusOutlined,
    EditOutlined,
    DeleteOutlined,
    EyeOutlined
  },
  data() {
    return {

      searchInput: '',
      menuKey: '',
      connectPlatformVisible: false,
      pageList:[],
      formState: {
        email: '',
        password: '',
        smtp_host: '',
        smtp_port: undefined,
        mail_to: '',
        content: '',
        attachments: [],



      },
      checkAdd: 'add',
      rules: {
        email: [
          {required: true, message: this.$t('INPUT_EMAIL'), trigger: 'blur'},

        ],
        password: [{required: true, message: this.$t('INPUT_PASSWORD'), trigger: 'blur'}],
        // host: [{required: true, message: this.$t('INPUT_SMTP_HOST'), trigger: 'blur'}],
        // post: [{required: true, message: this.$t('INPUT_SMTP_POST'), trigger: 'blur'}],
        // content: [{required: true, message: this.$t('INPUT_CONTENT'), trigger: 'blur'}],
        // to: [{required: true, message: this.$t('INPUT_MAIL_TEST_TO'), trigger: 'blur'}],
      },



    }
  },
  computed: {
    ...mapGetters({getCurrentShop: 'getCurrentShop'}),
    columns(){
      return [
        {
          title: this.$t('PAGE_NAME'),
          dataIndex: 'page_name',
          key: 'page_name',
          slots: {customRender: 'page_name'},
        },
        {
          title: this.$t('PAGE_TYPE'),
          dataIndex: 'page_type',
          key: 'page_type',
          slots: {customRender: 'page_type'},
        },
        {
          title: this.$t('CREATED'),
          key: 'created_at',
          dataIndex: 'created_at',
          slots: {customRender: 'created_at'},
        },
        {
          title: this.$t('action'),
          key: 'action',
          slots: {customRender: 'action'},
        },
      ]
    }
  },
  created() {
    console.log(this.getCurrentShop);
    this.fetchData()
  },
  methods: {
    onSearchChange() {
      console.log("onSearchChange")
    },
   menuConnectionListener(menu: any){
      this.menuKey = menu.key
      switch (menu.key) {
        case "1":
          console.log('1')
          this.connectPlatformVisible = true
          break

      }
    },
    async fetchData(){
      try{
        const res = await APIs.init().getPageList()
        if (res.data?.status === 200){
          console.log(res.data?.data)
            this.pageList = res.data?.data
          }

      }catch (e) {
        console.log(e)
      }

    },
    editEmail(data:any){
      this.connectPlatformVisible = true
      this.formState = {
        email: data.page_name,
            password: data.password,
            smtp_host: data.smtp_host,
            smtp_port: data.smtp_port ,
      }
      this.checkAdd = 'Edit'
    },
    onSubmit() {
      console.log(this.formRef)
     this.$refs.formRef
         .validate()
         .then(() => {
           console.log('values', this.formState );
           this.sendMailTest()
         })
         .catch((error: any) => {
           console.log('error', error);
         });

    },
  async sendMailTest() {
      console.log(this.formState)
    try{
      const res = await APIs.init().integrationPlatformMail({
        ...this.formState,
        attachments: []
      })
      console.log(res.data)
      if(res.data?.status === 200 || res.data?.status === 201){
        this.fetchData()
        this.connectPlatformVisible = false
        message.success(String(res.data?.message))
      }else{

        message.error(String(res.data?.message))
      }
    }catch(error){
      console.log(error)
    }
    },
    async deleteMailTest(id: string) {
      const res = await APIs.init().deleteIntegrationPlatformMail({page_id: id})
      if(res.data?.status === 200){
        message.success(this.$t('delete_success'))
        this.fetchData()
      }else {
        message.error(res.data?.message + '')
      }
    }

  }
})
export default class IntegrationPlatforms extends Vue {

}
</script>

<style lang="scss" scoped>

  .flow-table {
    margin-top: 15px;
    background-color: white;
  }


.table-row-name {
  font-weight: bold;
}
  .table {
    margin: 16px
  }
  .title-header {
    font-weight: bold;
    flex: 1;
  }
  .container-title{
    display: flex;
    flex-direction: row;
    padding: 16px 24px 16px 24px;
    margin-bottom: 25px;
    background-color: white
  }
  .input-search{
    width: 250px;
  }
  .fix-icon-menu {
    width: 20px;
    height: 20px;
  }
</style>
