<template>
  <div class="container-title">
    <h3 class="title-header">{{$t($route.name)}}</h3>

    <a-button
        v-if="getMyRole(modules.stocks)?.is_create"
        type="primary" class="button-add-product" @click="create">
      <plus-outlined/>
      {{$t('ADD')}}
    </a-button>
  </div>

  <a-table
      size="middle"
      :scroll="{  y: '80vh' }"
      :columns="columns"
      :data-source="getStocks.stocks"
      class="table"
      :pagination="pagination"
      @change="handleTableChange"
      :loading="getStocks.loading"
  >
    <template #shop_name="{record}">
      <router-link :to="'/stock/'+record.id" class="table-row-name">
        {{record.shop_name}}
      </router-link>
    </template>
    <template #action="{record}">
      <a-dropdown>
        <a-button type="text">
          <eye-outlined />
        </a-button>
        <template #overlay>
          <a-menu>
            <a
                v-if="getMyRole(modules.stocks)?.is_edit"
                @click="edit(record)">
              <a-menu-item class="router-product-edit">
                <edit-outlined/>
                {{ $t('EDIT') }}
              </a-menu-item>
            </a>

            <a-menu-item
                v-if="getMyRole(modules.stocks)?.is_delete"
            >
              <a-popconfirm placement="bottom" :ok-text="$t('OK')" :cancel-text="$t('CANCEL')" @confirm="confirmDelete(record)">
                <template #title>
                  <p>{{ $t('CONFIRM_DELETE') + record.shop_name + '?'}}</p>
                </template>
                <delete-outlined/>
                {{ $t('DELETE') }}
              </a-popconfirm>
            </a-menu-item>
          </a-menu>
        </template>
      </a-dropdown>
    </template>
  </a-table>
  <a-modal
      :footer="null"
      v-model:visible="editVisible" :title="formState.id?$t('UPDATE_STOCK'):$t('ADD_NEW_STOCK')">
    <a-form
        ref="formRef"
        :model="formState"
        :rules="rules"
        :label-col="labelCol"
        :wrapper-col="wrapperCol"
    >
      <a-form-item :label="$t('NAME')" name="shop_name">
        <a-input v-model:value="formState.shop_name"/>
      </a-form-item>
<!--      <a-form-item label="National" name="national">-->
<!--        <a-select-->
<!--            placeholder="National"-->
<!--            style="width: 100%"-->
<!--            :options="options"-->
<!--        >-->
<!--        </a-select>-->
<!--      </a-form-item>-->
      <a-form-item :label="$t('phone_number')" name="shop_tel">
        <a-input v-model:value="formState.shop_tel"/>
      </a-form-item>
      <a-form-item label="Email" name="shop_email">
        <a-input v-model:value="formState.shop_email"/>
      </a-form-item>
      <a-form-item :label="$t('PROVINCE')" name="province_id">
        <a-select
            v-model:value="formState.province_id"
            showSearch
            style="width: 100%"
            :filter-option="false"
            :not-found-content="null"
            :placeholder="$t('PROVINCE')"
            @change="provinceChange"
            @search="provinceSearch"
        >
          <a-select-option
              v-for="item in getProvinces"
              :key="item.id"
              :value="item.id"
          >{{ item.name }}
          </a-select-option>
        </a-select>
      </a-form-item>
      <a-form-item :label="$t('DISTRICT')" name="district_id">
        <a-select
            v-model:value="formState.district_id"
            showSearch
            :placeholder="$t('DISTRICT')"
            style="width: 100%"
            :filter-option="false"
            :not-found-content="null"
            @change="districtChange"
            @search="districtSearch"
        >
          <a-select-option
              v-for="item in getDistricts"
              :key="item.id"
              :value="item.id"
          >{{ item.name }}
          </a-select-option>
        </a-select>
      </a-form-item>
      <a-form-item :label="$t('WARD')" name="ward_id">
        <a-select
            v-model:value="formState.ward_id"
            showSearch
            :placeholder="$t('WARD')"
            :filter-option="false"
            :not-found-content="null"
            @search="wardSearch"
            style="width: 100%"
        >
          <a-select-option
              v-for="item in getWards"
              :key="item.id"
              :value="item.id"
          >{{ item.name }}
          </a-select-option>
        </a-select>
      </a-form-item>
      <a-form-item :label="$t('ADDRESS')" name="address">
        <a-input   v-model:value="formState.shop_address"/>
      </a-form-item>
      <a-form-item :wrapper-col="{ offset: 8 }">
        <a-button type="primary" @click="onSubmit">{{$t('CREATE')}}</a-button>
        <a-button style="margin-left: 10px"  @click="editVisible=!editVisible">{{$t('CANCEL')}}</a-button>
      </a-form-item>
    </a-form>
  </a-modal>
</template>

<script lang="ts">
import {computed, defineComponent, onBeforeMount, reactive, ref, UnwrapRef} from "vue";
import RouterEndpoint from "@/router/RouterEndpoint";
import {DeleteOutlined,EyeOutlined, EditOutlined, EllipsisOutlined, PlusOutlined} from "@ant-design/icons-vue";
import {DictionaryActionTypes} from "@/store/modules/dictionary/action-types";
import {Province} from "@/models/Dictionary/Dictionary";
import {DistrictRequest, WardRequest} from "@/APIs/request/DictionaryRequest";
import {StocksRequest} from "@/APIs/request/StocksRequest";
import {useStore} from "@/store";
import {StocksData} from "@/store/modules/stocks/states";
import {useRoute} from "vue-router";
import {Stocks} from "@/models/Stocks/Stocks";
import {StockActionTypes} from "@/store/modules/stocks/action-types";
import APIs from "@/APIs/APIs";
import {message} from "ant-design-vue";
import {useI18n} from "vue-i18n";
import {MixUtils} from "@/utils/Utils";
import {Constant} from "@/utils/Constants";



export default defineComponent({
  components:{
    EyeOutlined,
    EditOutlined,
    DeleteOutlined,
    PlusOutlined
  },
  setup(){
    const editVisible=ref(false)
    const store=useStore()
    const route = useRoute()
    const i18n = useI18n()
    const formRef = ref();
    const provinceSearchText = ref('')
    const districtSearchText = ref('')
    const wardSearchText = ref('')
    const columns = computed(()=>[
      {
        title: i18n.t('NAME'),
        dataIndex: 'shop_name',
        key: 'shop_name',
        slots:{customRender:'shop_name'}
      },
      {
        title: i18n.t('phone_number'),
        key: 'shop_tel',
        dataIndex: 'shop_tel',
      },
      {
        title: 'Email',
        key: 'shop_email',
        dataIndex: 'shop_email',
      },
      {
        title: i18n.t('PROVINCE'),
        key: 'province_name',
        dataIndex: 'province_name',
      },
      {
        title: i18n.t('DISTRICT'),
        key: 'district_name',
        dataIndex: 'district_name',
      },
      {
        title: i18n.t('WARD'),
        key: 'ward_name',
        dataIndex: 'ward_name',
      },
      {
        title: i18n.t('ADDRESS'),
        key: 'shop_address',
        dataIndex: 'shop_address',
      },
      {
        title: i18n.t('DEFAULT'),
        key: 'is_default',
        dataIndex: 'is_default',
      },
      {
        title: i18n.t('ACTION'),
        key: 'action',
        slots:{customRender:'action'}
      },
    ]);

    const formState: UnwrapRef<Stocks> =  reactive({
      id: undefined,
      shop_name: '',
      shop_email: '',
      shop_tel: '',
      province_id: undefined,
      district_id: undefined,
      ward_id: undefined,
    });

    const rules = {
      shop_name: [
        {required: true, message: i18n.t('PLACEHOLDER_INPUT_NAME'), trigger: 'blur'},
      ],
      shop_tel: [
        {required: true, message: i18n.t('PLACEHOLDER_INPUT_PHONE'), trigger: 'blur'},
      ],
      province_id: [
        {required: true, transform: (value: any) => value+'', message: i18n.t('PLACEHOLDER_INPUT_PROVINCE'), trigger: 'blur'},
      ],
      district_id: [
        {required: true, transform: (value: any) => value+'', message: i18n.t('PLACEHOLDER_INPUT_DISTRICT'), trigger: 'blur'},
      ],
      ward_id: [
        {required: true, transform: (value: any) => value+'', message: i18n.t('PLACEHOLDER_INPUT_WARD'), trigger: 'blur'},
      ],
    };

    const districtRequest: DistrictRequest = { province_id: undefined }
    const wardRequest: WardRequest = { district_id: undefined }
    const stocksRequest: StocksRequest = {
      page: 1,
      page_size: 20,
    }
    const getProvinces = computed((): Province[]| undefined => {
      if(provinceSearchText.value){
        return store.getters.getProvinces?.filter((x: any) => MixUtils.methods.removeVietnamese2(x.name).includes(MixUtils.methods.removeVietnamese2(provinceSearchText.value)))
      }else{
        return store.getters.getProvinces
      }
    })
    const getDistricts = computed((): Province[]| undefined =>{
      if(districtSearchText.value){
        return  store.getters.getDistricts?.filter((x: any) => MixUtils.methods.removeVietnamese2(x.name).includes(MixUtils.methods.removeVietnamese2(districtSearchText.value)))
      }else{
        return  store.getters.getDistricts
      }
    })
    const getWards = computed((): Province[]| undefined => {
      if(wardSearchText.value){
        return  store.getters.getWards?.filter((x: any) => MixUtils.methods.removeVietnamese2(x.name).includes(MixUtils.methods.removeVietnamese2(wardSearchText.value)))
      }else{
        return  store.getters.getWards
      }
    })
    const getStocks = computed((): StocksData => store.getters.getStocks)
    const pagination = computed(() => ({
          current: route.query.page ? route.query.page : 1,
          pageSize: route.query.page_size
          ? route.query.page_size
          : 20,
          defaultPageSize: 20,
          total:
              getStocks.value.metadata &&
              getStocks.value.metadata.record_total
                  ? getStocks.value.metadata.record_total
                  : 0,
          pageSizeOptions: ["20", "40", "80", "120"],
          showSizeChanger: true,
    }))


    const fetchStocks=()=>{
      store.dispatch(StockActionTypes.FETCH_STOCKS, stocksRequest)
    }
    const provinceChange=(province_id: number)=>{
      store.dispatch<DictionaryActionTypes>(DictionaryActionTypes.FETCH_DISTRICTS,{payload:{province_id: province_id }})
      formState.district_id = undefined
      formState.ward_id = undefined
    }

    const fetchProvince=()=>{
      store.dispatch<DictionaryActionTypes>(DictionaryActionTypes.FETCH_PROVINCES, undefined)
    }
    const districtChange=(district_id: number)=>{
      store.dispatch<DictionaryActionTypes>(DictionaryActionTypes.FETCH_WARDS, {payload:{district_id: district_id }})
      formState.ward_id = undefined
    }

    const createStock = ()=>{
      message.loading('');
      const createStocksRequest: Stocks = {
        shop_name: formState.shop_name,
        shop_email: formState.shop_email,
        shop_tel: formState.shop_tel,
        province_id: Number(formState.province_id),
        district_id: Number(formState.district_id),
        ward_id: Number(formState.ward_id),
        shop_address: formState.shop_address,
      }
      APIs.init().createStock(createStocksRequest).then(res => {
        if (res.status === 200 && res.data?.status === 200) {
          editVisible.value = false
          message.success({content: 'Created stocks: ' + res.data.data.shop_name});
          stocksRequest.page = 1
          fetchStocks()
        } else {
          message.destroy()
        }
      }).catch(e => {
        message.destroy()
        console.error(e)
      })
    }

    const updateStock = ()=>{
      message.loading('');
      const createStocksRequest: Stocks = formState
      APIs.init().updateStock(createStocksRequest).then(res => {
        if (res.status === 200 && res.data?.status === 200) {
          editVisible.value = false
          message.success({content: 'Updated stocks: ' + res.data.data.shop_name});
          stocksRequest.page = 1
          fetchStocks()
        } else {
          message.destroy()
        }
      }).catch(e => {
        message.destroy()
        console.error(e)
      })
    }

    const edit = (stock: Stocks)=>{
      editVisible.value = true
      Object.assign(formState, stock)
      if(stock.province_id){
        store.dispatch<DictionaryActionTypes>(DictionaryActionTypes.FETCH_DISTRICTS,{payload:{province_id: stock.province_id }})
      }
      if(stock.district_id){
        store.dispatch<DictionaryActionTypes>(DictionaryActionTypes.FETCH_WARDS, {payload:{district_id: stock.district_id }})
      }
    }
    const create = ()=>{
      editVisible.value = true
      formState.id= undefined
      formState.shop_name= ''
      formState.shop_email= ''
      formState.shop_tel= ''
      formState.province_id= undefined
      formState.district_id= undefined
      formState.ward_id= undefined
      formState.shop_address= ''
    }
const optionsProvinces = ref(getProvinces.value)
    console.log(optionsProvinces.value)
    const handleTableChange = (pagination: any) =>{
      stocksRequest.page = pagination?.current;
      stocksRequest.page_size = pagination?.pageSize;
      // this.replaceQuery();
      // this.loadCustomer();
    }

    const confirmDelete = (stock: Stocks)=>{
      APIs.init().deleteStock(stock).then(res => {
        if (res.status === 200 && res.data?.status === 200) {
          editVisible.value = false
          message.success({content: 'Deleted stock: ' + stock.shop_name});
          stocksRequest.page = 1
          fetchStocks()
        } else {
          message.destroy()
        }
      }).catch(e => {
        message.destroy()
        console.error(e)
      })
    }

    const onSubmit = ()=>{
      console.log('submit')
      console.log(formRef.value)
      formRef.value
          .validate()
          .then(() => {
            if(formState.id){
              updateStock()
            }else{
              createStock()
            }
          })
          .catch((error: any) => {
            console.log('error', error);
          });
    }

    const provinceSearch = (search: string) => {
      provinceSearchText.value  = search
    }
    const districtSearch = (search: string) => {
      districtSearchText.value  = search
    }
    const wardSearch = (search: string) => {
      wardSearchText.value  = search
    }
    onBeforeMount(()=>{
      fetchStocks()
      fetchProvince()
    })

    const getUserInfo = computed(() => store.getters.getUserInfo)

    const getMyRole = computed(() => {
      if(getUserInfo.value?.id_cms_privileges==null ||  getUserInfo.value?.id_cms_privileges<11){
        return ()=>({
          is_visible: 1,
          is_create:1,
          is_read: 1,
          is_edit: 1,
          is_delete: 1,
          id: 1
        })
      }else{
        return store.getters.getRole
      }
    });
    const modules = Constant.MODULES;

    return{
      getMyRole,
      modules,
      columns,
      provinceSearch,
      districtSearch,
      wardSearch,
      data: [],
      RouterEndpoint:RouterEndpoint,
      create,
      edit,
      confirmDelete,
      onSubmit,
      formState,
      rules,
      formRef,
      handleTableChange,
      districtRequest,
      wardRequest,
      editVisible,
      getProvinces,
      getDistricts,
      getWards,
      getStocks,
      pagination,
      labelCol: { span: 6 },
      wrapperCol: { span: 16 },
      options: [...Array(25)].map((_, i) => ({value: (i + 10).toString(36) + (i + 1)})),
      provinceChange,
      districtChange,
      optionsProvinces
    }
  },
})
</script>

<style scoped lang="scss">
.filter-bar {
  display: flex;
  flex-wrap: wrap;

  .filter-form-label {
    margin-bottom: 4px;
  }

  .button-function {
    display: flex;
    flex: 1;
    justify-content: flex-end;
    align-self: flex-end;

    .button-filter-bar-right {
      margin-left: 16px;
    }

    .button-add-product {
      margin-left: 32px;
    }
  }
}

.table {
  margin: 16px
}
.title-header {
  font-weight: bold;
  flex: 1;
}
.container-title{
  display: flex;
  flex-direction: row;
  padding: 16px 24px 16px 24px;
  margin-bottom: 25px;
  background-color: white
}
.input-search{
  width: 250px;
  margin-right: 16px;
}
.table-row-name{
  font-weight: bold;
}
</style>
