import {State} from "./states";
import {Province} from "@/models/Dictionary/Dictionary";
import {SelectionAnt} from "@/models/ShopPages/ConfigData";

export type Getters = {
    getProvinces(state: State): Province[] | undefined
    getDistricts(state: State): Province[] | undefined
    getWards(state: State): Province[] | undefined
    getSearchProvinces(state: State): SelectionAnt[]
    getSearchDistricts(state: State): SelectionAnt[]
    getSearchWards(state: State): SelectionAnt[]
}
export const getters: Getters = {
    getProvinces(state: State): Province[] | undefined {
        return state.provinces;
    },
    getDistricts(state: State): Province[] | undefined {
        return state.districts;
    },
    getWards(state: State): Province[] | undefined {
        return state.wards;
    },
    getSearchProvinces(state: State): SelectionAnt[]  {
        return state.searchProvinces.map((item):SelectionAnt=>{
            return {value:item.id,label:item.name}
        });
    },
    getSearchDistricts(state: State): SelectionAnt[] {
        return state.searchDistricts.map((item):SelectionAnt=>{
            return {value:item.id,label:item.name}
        });
    },
    getSearchWards(state: State): SelectionAnt[] {
        return state.searchWards.map((item):SelectionAnt=>{
            return {value:item.id,label:item.name}
        });
    }
}