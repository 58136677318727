<template>
  <div class="container-title">
    <h3 class="title-header">{{ $t('Reload_All_Message') }}</h3>
            <a-select
                v-model:value="selectedPage"
                mode="tags"
                :allowClear="true"
                :dropdownMatchSelectWidth="true"
                style="width: 300px; margin-bottom: 16px"
                :placeholder="$t('ALL')"
            >
              <a-select-option
                  v-for="item in getPages.overView"
                  :key="item.id"
                  v-bind:value="item.page_id"
              >{{ item.page_name }}
              </a-select-option>
            </a-select>
    <a-select
        v-model:value="selectedTime"


        :allowClear="true"
        :dropdownMatchSelectWidth="true"
        style="width: 100px; margin-bottom: 16px"
        :placeholder="$t('ALL')"
    >
      <a-select-option
          v-for="item in time"
          :key="item.value"
          v-bind:value="item.value"
      >{{ item.label }}
      </a-select-option>
    </a-select>
    <a-button
        @click="reloadAllMessage"
        type="primary" class="button-add-product">
      <plus-outlined/>
      {{$t('Reload_All_Message')}}
    </a-button>
<!--    <a-button-->

<!--        type="primary" class="button-add-product" @click="create">-->
<!--      <plus-outlined/>-->
<!--      {{$t('ADD')}}-->
<!--    </a-button>-->
  </div>

</template>

<script lang="ts">
import {computed, defineComponent, onMounted, ref, reactive, UnwrapRef} from "vue";
import {useRoute} from "vue-router";
import {useStore} from "@/store";
import {useI18n} from "vue-i18n";
import APIs from "@/APIs/APIs";
import {message} from "ant-design-vue";
import {PrivilegeRole, Role} from "@/models/Role/Role";
import {DeleteOutlined, EditOutlined, EyeOutlined} from "@ant-design/icons-vue";
import {Constant} from "@/utils/Constants";
import useOverviewStore from "../../store/Overview";


export default defineComponent({
  name: "Role",
  components: {
    // EyeOutlined,
    // DeleteOutlined,
    // EditOutlined,
  },
  created() {
    console.log('role'
    )
  },
  setup(props) {
    const route = useRoute();
    const store = useStore();
    const i18n = useI18n();
    const formRef = ref();
const selectedPage = ref([])
    const selectedTime = ref([])
    const getPages = useOverviewStore()

const time = [

  {
    label: '1 ' + i18n.t('days'),
    value: 86400  //second
  },

  {
    label: '1 ' + i18n.t('month'),
    value: 2592000  //second
  },

  {
    label: '3 ' + i18n.t('month'),
    value: 7776000  //second
  },

  {
    label: '6 ' + i18n.t('month'),
    value: 15552000  //second
  },

  {
    label: '1 ' + i18n.t('YEAR'),
    value: 31104000  //second
  },
  {
    label: '3 ' + i18n.t('YEAR'),
    value: 93312000  //second
  },

]



const reloadAllMessage = async () => {
   const pageInfo:any = []
  let socialType = ''
  useOverviewStore.getState().overView.map(item => {
    // @ts-ignore
    if(selectedPage.value.includes(item.page_id)){
      if (item.page_type===1){
        socialType="facebook";
      }else if (item.page_type===2){
        socialType="zalo";
      }else if (item.page_type===3){
        socialType="instagram";
      }else if (item.page_type===4){
        socialType="line";
      }
      pageInfo.push({
          page_type: item.page_type,
          page_id:item.page_id,
          page_name:item.page_name,
          avatar:item.avatar,
          page_token:item.page_token,
          shop_id:item.shop_id,
          config_data:item.config_data,
          created_by:item.created_by,
          verb: "update",
          social_type:socialType,
          action : "all",
          crawl_time:selectedTime.value
      })
    }

  })
  const rawResponse = await fetch('http://167.71.218.35:9998/fbpage/pages', {
    method: 'POST',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({data:pageInfo})
  });
  console.log(rawResponse)
}


















    return {
      getPages,
      selectedPage,
      reloadAllMessage,
      labelCol: { span: 6 },
      wrapperCol: { span: 16 },
      time,
      selectedTime
    }
  }
})

</script>

<style scoped>
.container-content {
  display: flex;
  flex-direction: row;
  /*margin: 0 16px 16px 16px;*/
}
.table-row-name{
  min-width: 250px;
}
.button-add-product{
  margin-left: 16px
}
</style>
