import {ActionContext, ActionTree} from "vuex";
import {FlowData, State} from "./states";
import {RootState} from "@/store";
import {FlowActionTypes} from "@/store/modules/flow/action-types";
import APIs from "@/APIs/APIs";
import {FlowsMutationTypes} from "@/store/modules/flow/mutation-types";
import {Mutations} from "@/store/modules/flow/mutations";
import {
    FlowConstantsResponse,
    FlowsContextResponse,
    FlowsResponse,
    ProvinceResponse, ZnsTempResponse
} from "@/APIs/response/S_HttpResponse";
import {FlowContextRequest, FlowsRequest, ZnsTempRequest} from "@/APIs/request/FlowRequest";
import {DictionaryActionTypes} from "@/store/modules/dictionary/action-types";
import {WardRequest} from "@/APIs/request/DictionaryRequest";
import {DictionaryMutationTypes} from "@/store/modules/dictionary/mutation-types";

type AugmentedActionContext = {
    commit<K extends keyof Mutations>(
        key: K,
        payload: Parameters<Mutations[K]>[1],
    ): ReturnType<Mutations[K]>;
} & Omit<ActionContext<State, RootState>, 'commit'>

export interface Actions {
    [FlowActionTypes.FETCH_FLOWS](
        {commit}: AugmentedActionContext,
        payload: FlowsRequest
    ): Promise<FlowsResponse | undefined>,

    [FlowActionTypes.FETCH_FLOW_CONTEXT](
        {commit}: AugmentedActionContext,
        payload: FlowContextRequest,
    ): Promise<FlowsContextResponse | undefined>,

    [FlowActionTypes.FETCH_FlowConstants](
        {commit}: AugmentedActionContext,
    ): Promise<FlowConstantsResponse | undefined>,

    [FlowActionTypes.FETCH_ZnsTemp](
        {commit}: AugmentedActionContext,
        payload: ZnsTempRequest
    ): Promise<ZnsTempResponse | undefined>,
}

export const actions: ActionTree<State, RootState> & Actions = {
    async [FlowActionTypes.FETCH_FLOWS]({commit}, payload: FlowsRequest): Promise<FlowsResponse | undefined> {
        // console.log(payload);
        const response = await APIs.init().getFlows(payload)
        if (response.status === 200) {
            const flowData: FlowData = {
                flows: response.data?.data,
                metadata: response.data?.meta_data
            }
            commit(FlowsMutationTypes.SET_FLOWS, flowData)
            commit(FlowsMutationTypes.SET_FLOWS_2, flowData.flows ? flowData.flows : [])
        }
        return response.data;
    },
    async [FlowActionTypes.FETCH_FLOW_CONTEXT]({commit}, payload: FlowContextRequest): Promise<FlowsContextResponse | undefined> {
        const response = await APIs.init().getFlowV2Context(payload)
        if (response.status === 200) {
            commit(FlowsMutationTypes.SET_FLOW_CONTEXT, response.data?.data)
            commit(FlowsMutationTypes.UPSERT_FLOW_CONTEXT_LIST, response.data?.data)
        }
        return response.data;
    },
    async [FlowActionTypes.FETCH_FlowConstants]({commit}): Promise<FlowConstantsResponse | undefined> {
        const response = await APIs.init().getFlowConstants()
        if (response.status === 200) {
            commit(FlowsMutationTypes.SET_FlowConstants, response.data?.data)
        }
        return response.data;
    },
    async [FlowActionTypes.FETCH_ZnsTemp]({commit}, payload: ZnsTempRequest): Promise<ZnsTempResponse | undefined> {
        const response = await APIs.init().getZnsTemp(payload)
        if (response.status === 200) {
            commit(FlowsMutationTypes.SET_ZNS_TEMP, response.data?.data)
        }
        return response.data;
    }
}
