import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode, createBlock as _createBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"
import _imports_0 from '@/assets/icons/zalo-seeklogo.com.svg'
import _imports_1 from '@/assets/icons/facebook-svgrepo-com.svg'
import _imports_2 from '@/assets/icons/instagram-svgrepo-com.svg'
import _imports_3 from '@/assets/icons/line-svgrepo-com.svg'


const _withScopeId = n => (_pushScopeId("data-v-c251ef48"),n=n(),_popScopeId(),n)
const _hoisted_1 = { style: {"display":"flex"} }
const _hoisted_2 = { class: "header-postback-pop" }
const _hoisted_3 = { key: 1 }
const _hoisted_4 = { class: "ant-upload-picture-card-wrapper" }
const _hoisted_5 = { class: "ant-upload-list-picture-card-container" }
const _hoisted_6 = { class: "ant-upload-list-item ant-upload-list-item-done ant-upload-list-item-list-type-picture-card" }
const _hoisted_7 = { class: "ant-upload-list-item-info" }
const _hoisted_8 = ["href"]
const _hoisted_9 = ["src"]
const _hoisted_10 = { class: "ant-upload-list-item-actions" }
const _hoisted_11 = ["href"]
const _hoisted_12 = {
  role: "button",
  tabindex: "0",
  class: "ant-upload"
}
const _hoisted_13 = { class: "ant-upload-text" }
const _hoisted_14 = { class: "crm-action-title-container" }
const _hoisted_15 = { class: "title-selected-crm-action" }
const _hoisted_16 = {
  key: 0,
  draggable: "false",
  src: _imports_0,
  style: {"width":"20px","margin-right":"20px"}
}
const _hoisted_17 = {
  key: 1,
  draggable: "false",
  src: _imports_1,
  style: {"width":"20px","margin-right":"20px"}
}
const _hoisted_18 = {
  key: 2,
  draggable: "false",
  src: _imports_2,
  style: {"width":"20px","margin-right":"20px"}
}
const _hoisted_19 = {
  key: 3,
  draggable: "false",
  src: _imports_3,
  style: {"width":"20px","margin-right":"20px"}
}
const _hoisted_20 = { class: "right-button-content" }
const _hoisted_21 = { class: "footer-postback-init" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_close_outlined = _resolveComponent("close-outlined")!
  const _component_a_button = _resolveComponent("a-button")!
  const _component_a_input = _resolveComponent("a-input")!
  const _component_a_form_item = _resolveComponent("a-form-item")!
  const _component_a_select_option = _resolveComponent("a-select-option")!
  const _component_a_select = _resolveComponent("a-select")!
  const _component_a_textarea = _resolveComponent("a-textarea")!
  const _component_a_tag = _resolveComponent("a-tag")!
  const _component_eye_outlined = _resolveComponent("eye-outlined")!
  const _component_delete_outlined = _resolveComponent("delete-outlined")!
  const _component_plus_outlined = _resolveComponent("plus-outlined")!
  const _component_a_popover = _resolveComponent("a-popover")!
  const _component_a_tree_select_node = _resolveComponent("a-tree-select-node")!
  const _component_a_tree_select = _resolveComponent("a-tree-select")!
  const _component_a_col = _resolveComponent("a-col")!
  const _component_a_row = _resolveComponent("a-row")!
  const _component_facebook_outlined = _resolveComponent("facebook-outlined")!
  const _component_a_divider = _resolveComponent("a-divider")!
  const _component_check_outlined = _resolveComponent("check-outlined")!
  const _component_a_form = _resolveComponent("a-form")!
  const _component_image_selection_modal = _resolveComponent("image-selection-modal")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_a_popover, {
      trigger: "click",
      placement: "right",
      visible: _ctx.popoverVisible,
      "onUpdate:visible": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.popoverVisible) = $event))
    }, {
      title: _withCtx(() => [
        _createElementVNode("div", _hoisted_1, [
          _createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.$t('FLOW_BUTTON_SETTING')), 1),
          _createVNode(_component_a_button, {
            type: "text",
            onClick: _cache[0] || (_cache[0] = ()=>_ctx.popoverVisible=false)
          }, {
            default: _withCtx(() => [
              _createVNode(_component_close_outlined)
            ]),
            _: 1
          })
        ])
      ]),
      content: _withCtx(() => [
        _createVNode(_component_a_form, {
          style: {"max-width":"300px"},
          class: "form-postback",
          ref: "formRefPostBack",
          rules: _ctx.rules,
          model: _ctx.formState,
          layout: "vertical"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_a_form_item, {
              label: _ctx.$t('FLOW_FACEBOOK_BUTTON_NAME')
            }, {
              default: _withCtx(() => [
                _createVNode(_component_a_input, {
                  placeholder: _ctx.$t('FLOW_POSTBACK_BUTTON_NAME_CONTENT'),
                  value: _ctx.$t(_ctx.formState.title),
                  name: "title",
                  disabled: ""
                }, null, 8, ["placeholder", "value"])
              ]),
              _: 1
            }, 8, ["label"]),
            _createVNode(_component_a_form_item, {
              label: _ctx.$t('FLOW_FACEBOOK_BUTTON_TYPE'),
              name: "type"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_a_select, {
                  value: _ctx.formState.type,
                  "onUpdate:value": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.formState.type) = $event)),
                  disabled: ""
                }, {
                  default: _withCtx(() => [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.facebookButtonTypes, (item) => {
                      return (_openBlock(), _createBlock(_component_a_select_option, {
                        key: item,
                        value: item
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(item), 1)
                        ]),
                        _: 2
                      }, 1032, ["value"]))
                    }), 128))
                  ]),
                  _: 1
                }, 8, ["value"])
              ]),
              _: 1
            }, 8, ["label"]),
            (_ctx.formState.type===_ctx.facebookButtonTypes[1])
              ? (_openBlock(), _createBlock(_component_a_form_item, {
                  key: 0,
                  label: _ctx.$t('FLOW_FACEBOOK_BUTTON_WEB_URL_LABEL')
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_a_input, {
                      placeholder: "https://shopf1.net",
                      value: _ctx.formState.url,
                      "onUpdate:value": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.formState.url) = $event)),
                      name: "url"
                    }, null, 8, ["value"])
                  ]),
                  _: 1
                }, 8, ["label"]))
              : (_openBlock(), _createElementBlock("div", _hoisted_3, [
                  _createVNode(_component_a_form_item, {
                    label: _ctx.$t('FLOW_FACEBOOK_BUTTON_POST_BACK_MESSAGE_CONTENT_LABEL')
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_a_textarea, {
                        onClick: _ctx.forcusMessagetext,
                        name: "reply_message",
                        placeholder: _ctx.$t('FLOW_FACEBOOK_BUTTON_POST_BACK_MESSAGE_CONTENT_PLACEHOLDER'),
                        rows: 3,
                        value: _ctx.formState.reply_message,
                        "onUpdate:value": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.formState.reply_message) = $event))
                      }, null, 8, ["onClick", "placeholder", "value"]),
                      _createVNode(_component_a_tag, {
                        onClick: _ctx.messageFullName,
                        style: {"cursor":"pointer"}
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode("##full_name##")
                        ]),
                        _: 1
                      }, 8, ["onClick"])
                    ]),
                    _: 1
                  }, 8, ["label"]),
                  _createVNode(_component_a_form_item, null, {
                    default: _withCtx(() => [
                      _createElementVNode("span", _hoisted_4, [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.fileList, (item) => {
                          return (_openBlock(), _createElementBlock("div", {
                            class: "ant-upload-list ant-upload-list-picture-card",
                            key: item
                          }, [
                            _createElementVNode("div", _hoisted_5, [
                              _createElementVNode("span", null, [
                                _createElementVNode("div", _hoisted_6, [
                                  _createElementVNode("div", _hoisted_7, [
                                    _createElementVNode("span", null, [
                                      _createElementVNode("a", {
                                        class: "ant-upload-list-item-thumbnail",
                                        href: item.url,
                                        target: "_blank",
                                        rel: "noopener noreferrer"
                                      }, [
                                        _createElementVNode("img", {
                                          src: item.url,
                                          alt: "image.png",
                                          class: "ant-upload-list-item-image"
                                        }, null, 8, _hoisted_9)
                                      ], 8, _hoisted_8)
                                    ])
                                  ]),
                                  _createElementVNode("span", _hoisted_10, [
                                    _createElementVNode("a", {
                                      href: item.url,
                                      target: "_blank",
                                      rel: "noopener noreferrer",
                                      title: "Preview file"
                                    }, [
                                      _createVNode(_component_eye_outlined)
                                    ], 8, _hoisted_11),
                                    _createVNode(_component_delete_outlined, {
                                      onClick: ($event: any) => (_ctx.removeImage(item))
                                    }, null, 8, ["onClick"])
                                  ])
                                ])
                              ])
                            ])
                          ]))
                        }), 128)),
                        _createElementVNode("div", {
                          class: "ant-upload ant-upload-select ant-upload-select-picture-card",
                          onClick: _cache[4] || (_cache[4] = 
//@ts-ignore
(...args) => (_ctx.showImageSelectionModal && _ctx.showImageSelectionModal(...args)))
                        }, [
                          _createElementVNode("span", _hoisted_12, [
                            _createElementVNode("div", null, [
                              _createVNode(_component_plus_outlined),
                              _createElementVNode("div", _hoisted_13, _toDisplayString(_ctx.$t('UPLOAD')), 1)
                            ])
                          ])
                        ])
                      ])
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_a_form_item, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_a_popover, {
                        title: _ctx.$t('FLOW_ADD_CMR_ACTION')
                      }, {
                        content: _withCtx(() => [
                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.crmActions, (i) => {
                            return (_openBlock(), _createBlock(_component_a_tag, {
                              style: {"display":"block","cursor":"pointer","margin-bottom":"10px"},
                              onClick: ($event: any) => (_ctx.selectCRM_ActionChange(i)),
                              key: i
                            }, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(_ctx.$t(i)), 1)
                              ]),
                              _: 2
                            }, 1032, ["onClick"]))
                          }), 128))
                        ]),
                        default: _withCtx(() => [
                          _createVNode(_component_a_button, null, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(_ctx.$t('FLOW_CMR_ACTION')), 1)
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      }, 8, ["title"])
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_a_form_item, { name: "action_crm" }, {
                    default: _withCtx(() => [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.formState.flow_actions_context.flow_crm_actions, (item, index) => {
                        return (_openBlock(), _createElementBlock("div", {
                          class: "action-crm",
                          key: 'index_'+index
                        }, [
                          _createElementVNode("div", _hoisted_14, [
                            _createElementVNode("div", _hoisted_15, _toDisplayString(_ctx.$t(item.type)), 1)
                          ]),
                          _createVNode(_component_a_row, { style: {"align-items":"center"} }, {
                            default: _withCtx(() => [
                              _createVNode(_component_a_col, { span: 20 }, {
                                default: _withCtx(() => [
                                  (item.type==='add_tag'||item.type==='remove_tag')
                                    ? (_openBlock(), _createBlock(_component_a_tree_select, {
                                        key: 0,
                                        "show-search": "",
                                        style: {"width":"100%"},
                                        value: item.tagArr,
                                        "onUpdate:value": ($event: any) => ((item.tagArr) = $event),
                                        filterTreeNode: false,
                                        onSearch: _ctx.searchTags,
                                        notFoundContent: null,
                                        "dropdown-style": { maxHeight: '300', overflow: 'auto' },
                                        "allow-clear": "",
                                        multiple: "",
                                        placeholder: _ctx.$t('TAG_SELECT')
                                      }, {
                                        default: _withCtx(() => [
                                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.treeTags, (item) => {
                                            return (_openBlock(), _createBlock(_component_a_tree_select_node, {
                                              key: item.id,
                                              value: item.id
                                            }, {
                                              title: _withCtx(() => [
                                                _createVNode(_component_a_tag, {
                                                  color: item.color
                                                }, {
                                                  default: _withCtx(() => [
                                                    _createTextVNode(_toDisplayString(item.name), 1)
                                                  ]),
                                                  _: 2
                                                }, 1032, ["color"])
                                              ]),
                                              default: _withCtx(() => [
                                                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(item.children, (child) => {
                                                  return (_openBlock(), _createBlock(_component_a_tree_select_node, {
                                                    key: child.id,
                                                    value: child.id
                                                  }, {
                                                    title: _withCtx(() => [
                                                      _createVNode(_component_a_tag, {
                                                        color: child.color
                                                      }, {
                                                        default: _withCtx(() => [
                                                          _createTextVNode(_toDisplayString(child.name), 1)
                                                        ]),
                                                        _: 2
                                                      }, 1032, ["color"])
                                                    ]),
                                                    _: 2
                                                  }, 1032, ["value"]))
                                                }), 128))
                                              ]),
                                              _: 2
                                            }, 1032, ["value"]))
                                          }), 128))
                                        ]),
                                        _: 2
                                      }, 1032, ["value", "onUpdate:value", "onSearch", "placeholder"]))
                                    : _createCommentVNode("", true),
                                  (item.type === 'unread_conversation' )
                                    ? (_openBlock(), _createBlock(_component_a_tag, { key: 1 }, {
                                        default: _withCtx(() => [
                                          _createTextVNode(_toDisplayString(_ctx.$t('unread_conversation')), 1)
                                        ]),
                                        _: 1
                                      }))
                                    : ( item.type === 'update_last_care')
                                      ? (_openBlock(), _createBlock(_component_a_select, {
                                          key: 2,
                                          style: {"width":"100%"},
                                          allowClear: "",
                                          placeholder: "Please select",
                                          value: item.last_care,
                                          "onUpdate:value": ($event: any) => ((item.last_care) = $event),
                                          onChange: _ctx.handleCare,
                                          onBlur: _ctx.handleCare
                                        }, {
                                          default: _withCtx(() => [
                                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.customerCares, (item) => {
                                              return (_openBlock(), _createBlock(_component_a_select_option, {
                                                key: item.id,
                                                value: item.id
                                              }, {
                                                default: _withCtx(() => [
                                                  _createVNode(_component_a_tag, {
                                                    color: item.color
                                                  }, {
                                                    default: _withCtx(() => [
                                                      _createTextVNode(_toDisplayString(item.name), 1)
                                                    ]),
                                                    _: 2
                                                  }, 1032, ["color"])
                                                ]),
                                                _: 2
                                              }, 1032, ["value"]))
                                            }), 128))
                                          ]),
                                          _: 2
                                        }, 1032, ["value", "onUpdate:value", "onChange", "onBlur"]))
                                      : (_openBlock(), _createBlock(_component_a_tree_select, {
                                          key: 3,
                                          "show-search": "",
                                          style: {"width":"100%"},
                                          value: item.coverstion_tag,
                                          "onUpdate:value": ($event: any) => ((item.coverstion_tag) = $event),
                                          filterTreeNode: false,
                                          onSearch: _ctx.searchTags,
                                          notFoundContent: null,
                                          "dropdown-style": { maxHeight: '300', overflow: 'auto' },
                                          "allow-clear": "",
                                          multiple: "",
                                          placeholder: _ctx.$t('TAG_SELECT')
                                        }, {
                                          default: _withCtx(() => [
                                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.converstionTag, (item) => {
                                              return (_openBlock(), _createBlock(_component_a_tree_select_node, {
                                                disabled: "",
                                                key: item.page_id,
                                                value: item.page_id
                                              }, {
                                                title: _withCtx(() => [
                                                  _createVNode(_component_a_tag, {
                                                    color: item.color
                                                  }, {
                                                    default: _withCtx(() => [
                                                      (item.page_type === 2)
                                                        ? (_openBlock(), _createElementBlock("img", _hoisted_16))
                                                        : (item.page_type === 1)
                                                          ? (_openBlock(), _createElementBlock("img", _hoisted_17))
                                                          : (item.page_type === 3)
                                                            ? (_openBlock(), _createElementBlock("img", _hoisted_18))
                                                            : (item.page_type === 4)
                                                              ? (_openBlock(), _createElementBlock("img", _hoisted_19))
                                                              : _createCommentVNode("", true),
                                                      _createTextVNode(" " + _toDisplayString(item.page_name), 1)
                                                    ]),
                                                    _: 2
                                                  }, 1032, ["color"])
                                                ]),
                                                default: _withCtx(() => [
                                                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(item.tags, (treeData) => {
                                                    return (_openBlock(), _createBlock(_component_a_tree_select_node, {
                                                      key: item.page_id + '_'+treeData.tag_id,
                                                      value: item.page_id + '_'+treeData.tag_id
                                                    }, {
                                                      title: _withCtx(() => [
                                                        _createVNode(_component_a_tag, {
                                                          color: treeData.tag_color
                                                        }, {
                                                          default: _withCtx(() => [
                                                            _createTextVNode(_toDisplayString(treeData.tag_name), 1)
                                                          ]),
                                                          _: 2
                                                        }, 1032, ["color"])
                                                      ]),
                                                      _: 2
                                                    }, 1032, ["value"]))
                                                  }), 128))
                                                ]),
                                                _: 2
                                              }, 1032, ["value"]))
                                            }), 128))
                                          ]),
                                          _: 2
                                        }, 1032, ["value", "onUpdate:value", "onSearch", "placeholder"]))
                                ]),
                                _: 2
                              }, 1024),
                              _createVNode(_component_a_col, { span: 1 }),
                              _createVNode(_component_a_col, { span: 3 }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_a_tag, {
                                    color: "#f50",
                                    class: "crm-action-deletion",
                                    onClick: ($event: any) => (_ctx.deleteCRM_Action(item))
                                  }, {
                                    default: _withCtx(() => [
                                      _createTextVNode(_toDisplayString(_ctx.$t('DELETE')), 1)
                                    ]),
                                    _: 2
                                  }, 1032, ["onClick"])
                                ]),
                                _: 2
                              }, 1024)
                            ]),
                            _: 2
                          }, 1024)
                        ]))
                      }), 128))
                    ]),
                    _: 1
                  }),
                  (_ctx.formState.flow_actions_context.type !== '0' || !_ctx.formState.flow_actions_context.usage_id)
                    ? (_openBlock(), _createBlock(_component_a_form_item, {
                        key: 0,
                        label: _ctx.$t('SELECT_AVAILABLE_MESSAGE'),
                        name: "root_flow_id"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_a_select, {
                            allowClear: "",
                            value: _ctx.formState.flow_actions_context.usage_id,
                            "onUpdate:value": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.formState.flow_actions_context.usage_id) = $event)),
                            placeholder: _ctx.$t('SELECT_AVAILABLE_MESSAGE'),
                            onChange: _ctx.changeFlowAvailable,
                            options: _ctx.getFlowNextActions()
                          }, null, 8, ["value", "placeholder", "onChange", "options"])
                        ]),
                        _: 1
                      }, 8, ["label"]))
                    : _createCommentVNode("", true),
                  (_ctx.formState.flow_actions_context.type === '0' || !_ctx.formState.flow_actions_context.usage_id)
                    ? (_openBlock(), _createBlock(_component_a_form_item, {
                        key: 1,
                        label: _ctx.$t('SELECT_AVAILABLE_FLOW'),
                        name: "usage_id"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_a_select, {
                            value: _ctx.formState.flow_actions_context.usage_id,
                            "onUpdate:value": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.formState.flow_actions_context.usage_id) = $event)),
                            placeholder: _ctx.$t('SELECT_AVAILABLE_FLOW'),
                            onChange: _ctx.changeFlowAvailable2,
                            allowClear: ""
                          }, {
                            default: _withCtx(() => [
                              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.getFlowData, (item) => {
                                return (_openBlock(), _createBlock(_component_a_select_option, {
                                  key: item.id,
                                  value: item.id
                                }, {
                                  default: _withCtx(() => [
                                    _createTextVNode(_toDisplayString(item.name), 1)
                                  ]),
                                  _: 2
                                }, 1032, ["value"]))
                              }), 128))
                            ]),
                            _: 1
                          }, 8, ["value", "placeholder", "onChange"])
                        ]),
                        _: 1
                      }, 8, ["label"]))
                    : _createCommentVNode("", true),
                  (_ctx.nextFlowContext&&_ctx.nextFlowContext.name)
                    ? (_openBlock(), _createBlock(_component_a_form_item, {
                        key: 2,
                        label: _ctx.$t('FLOW_NEXT_CONTENT')
                      }, {
                        default: _withCtx(() => [
                          _createElementVNode("div", _hoisted_20, [
                            _createVNode(_component_a_button, {
                              type: "dashed",
                              class: "selected-flow-action",
                              onClick: _ctx.changeCurrentFlowContext
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_facebook_outlined, { style: {"font-size":"16px"} }),
                                _createElementVNode("span", null, _toDisplayString(_ctx.nextFlowContext.name), 1)
                              ]),
                              _: 1
                            }, 8, ["onClick"]),
                            _createVNode(_component_a_tag, {
                              color: "#f50",
                              style: {"cursor":"pointer"},
                              onClick: _ctx.deleteNextFlowAction
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_delete_outlined)
                              ]),
                              _: 1
                            }, 8, ["onClick"])
                          ])
                        ]),
                        _: 1
                      }, 8, ["label"]))
                    : _createCommentVNode("", true)
                ])),
            _createVNode(_component_a_divider),
            _createVNode(_component_a_form_item, null, {
              default: _withCtx(() => [
                _createElementVNode("div", _hoisted_21, [
                  _createVNode(_component_a_button, {
                    type: "primary",
                    onClick: _cache[7] || (_cache[7] = ($event: any) => (_ctx.onSubmit(true)))
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_check_outlined),
                      _createTextVNode(" " + _toDisplayString(_ctx.$t('FLOW_COMPLETE')), 1)
                    ]),
                    _: 1
                  })
                ])
              ]),
              _: 1
            })
          ]),
          _: 1
        }, 8, ["rules", "model"])
      ]),
      default: _withCtx(() => [
        _createVNode(_component_a_button, {
          class: "button-post-back",
          onClick: _cache[8] || (_cache[8] = ()=>_ctx.popoverVisible=true)
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.$t(_ctx.formState.title)), 1)
          ]),
          _: 1
        })
      ]),
      _: 1
    }, 8, ["visible"]),
    _createVNode(_component_image_selection_modal, {
      ref: "imageSelectionRef",
      onSelect: _ctx.onImageSelect
    }, null, 8, ["onSelect"])
  ], 64))
}