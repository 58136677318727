<template>
  <div class="container-title">
    <h3 class="title-header">{{$t($route.name)}}</h3>
    <div class="input-search">
      <a-input-search
          class="input-search"
          :placeholder="$t('SEARCH')"
          v-model:value="searchInput"
          @change="onSearchChange"
      />

    </div>
    <a-select
        showSearch
        allowClear
        mode="multiple"
        :v-mode:value="selectedTags"
        :placeholder="$t('TAGS')"
        style="min-width: 220px; margin-right: 16px"
        @change="handleChangeTags"
    >
      <a-select-option
          v-for="item in getViralTags"
          :key="item.id"
          v-bind:value="item.id"
      >
        <a-tag :color="item.color">
          {{ item.name }}
        </a-tag>
      </a-select-option>
    </a-select>


    <a-button type="primary" @click="createViral">
      <plus-outlined/>
      {{$t('NEW_VIRAL')}}
    </a-button>


  </div>

        <a-table
            size="middle"
            :scroll="{  y: '80vh' }"
            :rowKey="record => record.id"
            :columns="columns" :data-source="getFlowData.flows"
            :pagination="pagination"
            @change="handleTableChange"
            class="flow-table">
          <template #name="{ record }">
            <a class="table-row-name" type="text" @click="customRowClick(record)">
              {{ record.name }}
            </a>
          </template>
          <template #created_at="{ text }">
                <span>
                   {{ dateTimeDisplayFormatFromString(text) }}

                </span>
          </template>
          <template #creator="{ text }">
                <span>
                  {{ getEmployeeName(text) }}

                </span>
          </template>
          <template #tags="{ record }">
                <span>
                    <a-tag v-for="tag in record.tags" :key="tag.id+'_'+tag.name"
                           :color="tag.color">{{ tag.name }}</a-tag>
                </span>
          </template>
          <template #action="{record}">
            <a-dropdown>
              <a-button type="text">
                <ellipsis-outlined/>
              </a-button>
              <template #overlay>
                <a-menu>
                  <a-menu-item @click="edit(record.id)">
                    <edit-outlined/>
                    {{ $t('EDIT') }}

                  </a-menu-item>
                  <a-menu-item @click="showViralResult(record.id)">
                    <line-chart-outlined/>
                    Result

                  </a-menu-item>
                </a-menu>
              </template>
            </a-dropdown>
          </template>

        </a-table>

    <ViralModal ref="viralModal" :viralUpdated="viralUpdated"></ViralModal>
    <ViralResultModal ref="viralResultModal"></ViralResultModal>

</template>

<script lang="ts">
import {Options, Vue} from "vue-class-component";
import {
  DownOutlined,
  PlusOutlined,
  SmileOutlined,
  TagOutlined,
  CheckOutlined,
  EllipsisOutlined,
  DeleteOutlined,
  EditOutlined,
  LineChartOutlined
} from "@ant-design/icons-vue"
import RouterEndpoint from "@/router/RouterEndpoint";
import {mapActions, mapGetters} from "vuex";
import {FlowActionTypes} from "@/store/modules/viral/action-types"
import {Flow, FlowType} from "@/models/Flows/Flow";
import {Employee} from "@/models/Users";
import {TagsActionTypes} from "@/store/modules/tag/action-types";
import {Tag} from "@/models/Tags/Tags";
import {FlowsRequest} from "@/APIs/request/FlowRequest";
import APIs from "@/APIs/APIs";
import {message} from "ant-design-vue";
import {PaginationProps} from "ant-design-vue/es/pagination";

const flowsRequest: FlowsRequest = {}
let inputChangeTimeout: number;
import ViralModal from "./UpsertViral.vue";
import ViralResultModal from "./ViralResults.vue";

@Options({
  name: 'flows',
  components: {
    TagOutlined,
    PlusOutlined,
    SmileOutlined,
    DownOutlined,
    CheckOutlined,
    EllipsisOutlined,
    DeleteOutlined,
    EditOutlined,
    ViralModal,
    ViralResultModal,
    LineChartOutlined
  },
  computed: {
    columns() {
      return [
        {
          title: this.$t('FLOW_FLOW_NAME'),
          dataIndex: 'name',
          key: 'name',
          slots: {customRender: 'name'},
        },
        {
          title: this.$t('TRIGGER_CREATOR'),
          dataIndex: 'creator',
          key: 'creator',
          slots: {customRender: 'creator'},
        },
        {
          title: this.$t('TAGS'),
          key: 'tags',
          dataIndex: 'tags',
          slots: {customRender: 'tags'},
        },
        {
          title: this.$t('TRIGGER_STATUS'),
          key: 'status',
          dataIndex: 'status',
        },
        {
          title: this.$t('DATE_CREATED'),
          dataIndex: 'created_at',
          key: 'created_at',
          slots: {customRender: 'created_at'},
        },
        {
          title: '',
          key: 'action',
          slots: {customRender: 'action'},
          width: 100
        },
      ]
    },
    ...mapGetters({
      getFlowData: 'getViralData',
      getCurrentShop: 'getCurrentShop',
      getViralTags: 'getViralTags',
    }),
    pagination() {
      return {
        current: this.$route.query.page ? parseInt(this.$route.query.page) : 1,
        pageSize: this.$route.query.page_size ? parseInt(this.$route.query.page_size) : 20,
        defaultPageSize: 20,
        total: this.getFlowData.metadata && this.getFlowData.metadata.record_total ? this.getFlowData.metadata.record_total : 0,
        pageSizeOptions: ['20', '40', '80', '120'],
        showSizeChanger: true
      }
    }
  },
  data() {
    return {
      RouterEndpoint: RouterEndpoint,
      selectedTags: [],
      flowsRequest,
      searchInput: '',
    }
  },
  created() {
    //Keep query when refresh the page
    this.flowsRequest = {
      search: this.$route.query.search,
      tags: this.$route.query.tags,
      page: this.$route.query.page ? parseInt(this.$route.query.page) : 1,
      page_size: this.$route.query.page_size ? parseInt(this.$route.query.page_size) : 20,
      flow_type: FlowType.Viral
    }
    this.loadAutoInbox()
    this.fetchTags({type: 'flow'})
  },
  methods: {
    ...mapActions({
      fetchFlow: FlowActionTypes.FETCH_VIRAL,
      fetchTags: TagsActionTypes.FETCH_TAGS,
    }),
    loadAutoInbox() {
      this.fetchFlow(this.flowsRequest).then();
    },
    edit(id: number){
      this.$refs.viralModal.openModal(id)
    },
    createViral(){
      this.$refs.viralModal.openModal(0)
    },
    replaceQuery() {
      if (this.selectedTags && this.selectedTags.length) {
        this.flowsRequest.tags = this.selectedTags.join(",")
      } else {
        this.flowsRequest.tags = undefined
      }
      //replace url with query
      this.$router.replace({name: RouterEndpoint.VIRAL_LIST.name, query: this.flowsRequest})
      this.loadAutoInbox()
    },
    customRowClick(flow: Flow) {
      this.$refs.viralModal.openModal(flow.id)
    },
    getEmployeeName(payload: number): string {
      if (this.getCurrentShop && this.getCurrentShop.employees) {
        const employee = this.getCurrentShop.employees.find((x: Employee) => x.id === payload);
        return employee ? employee.name : "";
      }
      return "";
    },
    toggleTag(tag: Tag) {
      const tagFound = this.selectedTags.find((x: number) => x === tag.id)
      console.log("toggleTag", tagFound)
      if (tagFound) {
        this.selectedTags = this.selectedTags.filter((x: number) => x !== tag.id)
        console.log("found", this.selectedTags)
      } else {
        this.selectedTags.push(tag.id)
      }
      this.replaceQuery()
    },
    onSearchChange() {
      if (inputChangeTimeout) {
        clearTimeout(inputChangeTimeout)
      }
      inputChangeTimeout = window.setTimeout(() => {
        this.flowsRequest.search = this.searchInput
        this.replaceQuery()
      }, 500)
    },
    deleteFlow(flow: Flow) {
      message.loading({content: 'Deleting Flow: ' + flow.name, key: flow.id});
      APIs.init().deleteFlow({id: flow.id}).then(res => {
        if (res.status === 200 && res.data?.status === 200) {
          message.success({content: 'Deleted Flow: ' + flow.name + '!', key: flow.id, duration: 2});
          this.loadAutoInbox()
        } else {
          message.destroy()
        }
      }).catch(e => {
        message.destroy()
        console.error(e)
      })
    },
    handleTableChange(pagination: PaginationProps) {
      this.flowsRequest.page = pagination?.current
      this.flowsRequest.page_size = pagination?.pageSize
      this.replaceQuery()
    },
    viralUpdated(){
      this.flowsRequest = {
        search: this.$route.query.search,
        tags: this.$route.query.tags,
        page: this.$route.query.page ? parseInt(this.$route.query.page) : 1,
        page_size: this.$route.query.page_size ? parseInt(this.$route.query.page_size) : 20,
        flow_type: FlowType.Viral
      }
      this.loadAutoInbox()
    },
    showViralResult(Id: number){
      this.$refs.viralResultModal.openModal(Id)
    },
    handleChangeTags(tags: number[]) {
      console.log('tags', tags)
      this.selectedTags = tags
      this.replaceQuery()
    }

  }
})
export default class Virals extends Vue {
}

</script>

<style lang="scss" scoped>
.container {
  margin: 10px 15px;
}

.left-layout {
  padding-right: 14px;

  .tag-group {
    padding: 10px 4px;
  }

  .button-align-left {
    text-align: left;
  }

}

.row-horizontal {
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  .input-search {
    display: flex;
    min-width: 250px;
    height: 32px;
    margin-right: 16px;
  }
}

.table-row-name {
  font-weight: bold;
}


.flow-tag {
  opacity: 0.7;
  cursor: pointer;
  margin-top: 8px;
}

.flow-tag-active {
  opacity: 1;
  cursor: pointer;
  margin-top: 8px;
}

.flow-table {
  margin: 16px
}
.title-header {
  font-weight: bold;
  flex: 1;
}
.container-title{
  display: flex;
  flex-direction: row;
  padding: 16px 24px 16px 24px;
  margin-bottom: 25px;
  background-color: white
}
.input-search{
  width: 250px;
  margin-right: 16px;
}

</style>
