<template>
    <a-form ref="formRef" :model="formState" layout="vertical">
      <div class="contain-card-lucky-wheel">
        <div>
          <h3>
            {{ $t('GIFT_SETTINGS') }}
          </h3>
          <img src="@/assets/icons/lucky-wheel-icon.svg" class="lucky-wheel-logo"/>
        </div>
        <div class="contain-lucky-wheel-logo">

          <div class="container-viral-settings">
            <a-form-item :label="$t('NUMBER_OF_PLAY')" :rules="{
        required: true,
        message: $t('THIS_FIELD_IS_REQUIRED'),
      }">
             {{formState.num_play}}
            </a-form-item>
            <div style="width: 10px;"></div>
            <a-form-item :label="$t('PLAYS_PER_INVITATION')" :rules="{
        required: true,
        message: $t('THIS_FIELD_IS_REQUIRED'),
      }">
              {{formState.invite_get_num_play}}
            </a-form-item>
            <div style="width: 10px;"></div>

          </div>
        </div>
        <div v-if="formState.gifts&&formState.gifts.length">
          <a-row style="align-items: center" v-for="(item,index) in formState.gifts" :key="item.id">
             <a-button :disabled="item.id === 'good_luck' ? true : false" @click="showModalCreateGift(item,index)" style="min-height: 60px;width:100%;margin-bottom: 10px" >
               <a-row style="align-items: center" v-if="item.image_url || item.name " justify="space-between">
                 <img v-if="item.image_url"
                      :src="item.image_url"
                      class="ant-upload-list-item-image"
                      style="width: 50px">
                 <a-typography-text strong>{{item.name}}</a-typography-text>
                 <a-typography-text  strong>{{item.id == 'good_luck' ? '' : item.win_rate + '%'}}</a-typography-text>
               </a-row>
               <a-typography-text v-else strong>{{$t('Create_GIFT')}}</a-typography-text>
             </a-button >
          </a-row>

        </div>

        <a-divider/>
        <a-card
            class="card-message"
        >
          <template #title>
            <div>
              <span>
              {{$t('MESSAGE_CONTENT')}}
            </span>
              <a-avatar class="logo-url" shape="square" @click="showImageSingleSelectionModal('logo')" :src="logoUrl">
                {{$t('UPLOAD')}}
              </a-avatar>
            </div>
          </template>
          <template #cover>
            <div>
              <img
                  class="img-cover-facebook-template"
                  alt="example"
                  v-if="formState.image_url"
                  :src="formState.image_url"
                  @click="showImageSingleSelectionModal('')"
              />
              <span
                  class="ant-upload-picture-card-wrapper upload-center"
                  v-else
              >
                          <div
                              class="ant-upload ant-upload-select ant-upload-select-picture-card"
                              @click="showImageSingleSelectionModal('')"
                          >
                            <span role="button" tabindex="0" class="ant-upload">
                              <div>
                                <plus-outlined/>
                                <div class="ant-upload-text">
                                  {{ $t("UPLOAD") }}
                                </div>
                              </div>
                            </span>
                          </div>
                        </span>
            </div>
          </template>
          <a-card-meta>
            <template #title>
              <a-form-item name="title" v-bind="validateInfos.title">
                <div class="content-message"
                >{{formState.title}}</div>
              </a-form-item>
            </template>
            <template #description>
              <a-form-item name="description" v-bind="validateInfos.description">
                <div class="content-message"
                >
                  {{formState.description}}
                </div>
              </a-form-item>
              <a-form-item style="text-align: center" name="button_title" v-bind="validateInfos.button_title">
                <a-button class="button-post-back"
                    style="width: 200px;"
                >
                  {{formState.button_title}}
                </a-button>
              </a-form-item>

            </template>
          </a-card-meta>
        </a-card>
      </div>
    </a-form>
</template>

<script lang="ts">
import {
  computed,
  defineComponent,
  onBeforeMount, onMounted,
  reactive,
  ref,
  UnwrapRef,
  watch,
} from "vue";
import {
  CloseCircleOutlined,
  CloseOutlined,
  DragOutlined,
  PictureOutlined,
  FileDoneOutlined,
} from "@ant-design/icons-vue";
import FlowNextActions from "@/components/Flow/FlowNextActions.vue";
import {useStore} from "@/store";
import {S_EventBus, ValidateFlowContext} from "@/utils/EventBus";
import {useI18n} from "vue-i18n";
import {MixUtils} from "@/utils/Utils";
import ImageSelectionModal from "@/components/Image/ImageSelectionModal.vue";
import "vue3-carousel/dist/carousel.css";
import {AWS_DO_Model} from "@/models/AWS_DO/AWS_DO";
import {
  ActionContext, FlowContext, FlowViral, FlowViralGift,
  MessageContext,
  PostbackContext,
  QuickReply,
} from "@/models/Flows/FlowContext";
import {
  FormFlowInformation,
} from "@/models/Flows/FlowForm";
import {ValidateErrorEntity} from "ant-design-vue/es/form/interface";
import {Form} from 'ant-design-vue';
import {compute} from "googleapis/build/src/apis/compute";

const useForm = Form.useForm;

export default defineComponent({
  name: "flow-viral",
  props: [
    "flowContext",
    "updateCurrentFlowContext",
    "flowContextData",
    "deleteFlowContext",
  ],
  components: {
  },
  setup(props) {
    const formRef = ref();
    const subtitle = ref()
    const messengerTags = ref([
      {
        label: "NONE",
        value: "",
      },
      {
        label: "CONFIRMED_EVENT_UPDATE",
        value: "CONFIRMED_EVENT_UPDATE",
      },
      {
        label: "POST_PURCHASE_UPDATE",
        value: "POST_PURCHASE_UPDATE",
      },
      {
        label: "ACCOUNT_UPDATE",
        value: "ACCOUNT_UPDATE",
      },
    ]);
    const store = useStore();
    const i18n = useI18n();
    // @ts-ignore
    const formState: UnwrapRef<FlowViral> = reactive({
      gifts:[
        {
          id:'good_luck',
          name:'good_luck',
          image_url: 'https://newsocial.sgp1.digitaloceanspaces.com/121212/1710815146_good-luck.jpg',

        }
      ],
    })

    const messageTag = ref('');
    const logoUrl = ref('');

    const viralName = ref('');
    const messageContexts = ref<MessageContext[]>([]);
    const imageSelectionRef = ref();
    const viralTypes = computed((): string[] => store.getters.getFlowViralTypes)
    const  cursorPositionMessageText= ref(0)
    const showCreateGift = ref(false)
    const giftData= ref({})
    const {resetFields, validate, validateInfos} = useForm(
        formRef,
        reactive({
          name: [
            {
              required: true,
              message: i18n.t("FLOW_VALIDATE_FLOW_NAME"),
              trigger: "blur",
            },
          ],
          button_title: [
            {
              required: true,
              message: i18n.t("THIS_FIELD_IS_REQUIRED"),
              trigger: "blur",
            },
          ],
          title: [
            {
              required: true,
              message: i18n.t("THIS_FIELD_IS_REQUIRED"),
              trigger: "blur",
            },
          ],
          description: [
            {
              required: true,
              message: i18n.t("THIS_FIELD_IS_REQUIRED"),
              trigger: "blur",
            },
          ],
        }),
    );

    const templateMessageFullName = (i:number) => {

      subtitle.value.focus()
      const text = ref<any>( formState.description);
      const start = text.value.slice(0,cursorPositionMessageText.value)
      const end = text.value.slice(cursorPositionMessageText.value)
      text.value =
          start +
          (start ? " " : "") + "##full_name##" +  (end ? " " : "") + end;
      formState.description = text.value
      onSubmit();

    }


    const updateProps = () => {

      if (props.flowContext && props.flowContext && props.flowContext.flow_viral && props.flowContext.flow_viral.length > 0) {
        viralName.value=props.flowContext.name
        messageTag.value=props.flowContext.messenger_tag
        formState.url = props.flowContext.flow_viral[0].url;
        formState.type = props.flowContext.flow_viral[0].type;
        formState.id = props.flowContext.flow_viral[0].id;
        formState.button_title = props.flowContext.flow_viral[0].button_title;
        formState.image_url = props.flowContext.flow_viral[0].image_url;
        formState.description = props.flowContext.flow_viral[0].description;
        formState.title = props.flowContext.flow_viral[0].title;
        formState.invite_get_num_play = props.flowContext.flow_viral[0].invite_get_num_play;
        formState.num_play = props.flowContext.flow_viral[0].num_play;
        if(props.flowContext.flow_viral[0].gifts){
          formState.gifts =  props.flowContext.flow_viral[0].gifts ;
        }
        formState.action_submit = props.flowContext.flow_viral[0].action_submit;
        if (props.flowContext.attachment_images&&props.flowContext.attachment_images.length>0){
          logoUrl.value=props.flowContext.attachment_images[0]
        }

      } else {
        formState.id = MixUtils.methods.getUUID();
        formState.gifts = []
      }
    };
    watch(
        () => props.flowContext,
        () => {
          updateProps();
        }
    );
    const flowFormValidate: ValidateFlowContext = {
      showValidateForm() {
        onSubmit();
      },
    };
    onBeforeMount(() => {
      S_EventBus.instance().setValidateFlowContextListener(flowFormValidate);
    });
    const forcusMessagetext = (e: any) =>{
      // if(e.target.value.lastIndexOf('#') !== -1){
      //   e.target.setSelectionRange(e.target.value.lastIndexOf('#')+1,e.target.value.lastIndexOf('#')+1)
      //   console.log(e.target.selectionStart)
      // }else {
      cursorPositionMessageText.value = e.target.selectionStart




    }
    const forcusMessagetext2 = (e: any) =>{
      // if(e.target.value.lastIndexOf('#') !== -1){
      //   e.target.setSelectionRange(e.target.value.lastIndexOf('#')+1,e.target.value.lastIndexOf('#')+1)
      //   console.log(e.target.selectionStart)
      // }else {
      cursorPositionMessageText.value = e.target.selectionStart




    }
    const onTextChange = (e: any) => {
      if(e && e.target && e.target.selectionStart){
        cursorPositionMessageText.value = e.target.selectionStart
        onSubmit();
      }else {
        onSubmit()
      }

    };
  const giftIndex = ref(0)
    const showModalCreateGift = (data:any,index: number) => {
      showCreateGift.value = true
      giftData.value = data
      giftIndex.value = index


    }
    const handleOk = () =>{
      showCreateGift.value = false


    }
    const onTypeChange = (val: string) => {
      formState.type = val
      if (!formState.gifts || !formState.gifts.length) {
        formState.gifts = [
          {
            id: MixUtils.methods.getUUID(),
            win_rate: 10,
          }
        ]
      }
      onSubmit()
    };

    const attachFile = () => {
      imageSelectionRef.value.showModal()
    }

    const addMoreGift = () => {
      const gift: FlowViralGift = {
        id: MixUtils.methods.getUUID(),
        win_rate: 10,
      }
      if (formState.gifts) {

        formState.gifts = [gift,...formState.gifts]

      } else {
        formState.gifts = [gift]
      }
    }

    const deleteGift = (id: string) => {

      if (formState.gifts) {
        formState.gifts = formState.gifts.filter(x => x.id !== id)
      }
      onSubmit()
    }
    const deleteGiftImage = (index: number) => {
      if (formState.gifts) {
        formState.gifts[index].image_url = ""
      }
    }

    const onImageSelect = (list: AWS_DO_Model[], param: string) => {
      if (list.length > 0) {
        console.log("foonImageSelectrmState", list, param)
        if (param) {
          if (param==='logo'){
            logoUrl.value=list[0].url
          }else {
            const position = parseInt(param)
            if (formState.gifts) {
              formState.gifts[position].image_url = list[0].url
            }
          }
        } else {
          formState.image_url = list[0].url
          console.log("formState", formState)
        }
      onSubmit()
      }

    }



    const updateSuccessAction = (action: ActionContext) => {
      const flow = {...props.flowContext};
      flow.action_send_success_context = action;
      props.updateCurrentFlowContext(flow);
    };
    const updateFailedAction = (action: ActionContext) => {
      const flow = {...props.flowContext};
      flow.action_send_failed_context = action;
      props.updateCurrentFlowContext(flow);
    };
    const updateDefaultAction = (action: ActionContext) => {
      const flow = {...props.flowContext};
      flow.action_default_context = action;
      props.updateCurrentFlowContext(flow);
    };
    const updateSubmitAction = (action: ActionContext) => {
      formState.action_submit = action;
      onSubmit()
    };
    const updateSubmitActionGift = (action: ActionContext) => {
      // @ts-ignore
      formState.gifts[giftIndex.value].flow_action_context =action
      onSubmit()
    };
    const showImageSingleSelectionModal = (param: string) => {
      imageSelectionRef.value.showModal(param + "");
    };

    const deleteCurrentFlowContext = () => {
      props.deleteFlowContext(props.flowContext.id);
    };

    const onSubmit = () => {
       console.log(formState.gifts)
      validate().then(() => {
        const flow = {...props.flowContext} as FlowContext;
        if (!flow.flow_viral){
          flow.flow_viral=[{}]
        }
        flow.name=viralName.value
        flow.messenger_tag=messageTag.value
        flow.flow_viral[0].type=formState.type
        flow.flow_viral[0].title=formState.title
        flow.flow_viral[0].button_title=formState.button_title
        flow.flow_viral[0].num_play=formState.num_play
        flow.flow_viral[0].invite_get_num_play=formState.invite_get_num_play
        flow.flow_viral[0].description=formState.description
        flow.flow_viral[0].image_url=formState.image_url
        flow.flow_viral[0].gifts=formState.gifts
        flow.flow_viral[0].action_submit=formState.action_submit
        if (logoUrl.value){
          flow.attachment_images=[logoUrl.value]
        }
        props.updateCurrentFlowContext(flow);
      }).catch((error: ValidateErrorEntity<FormFlowInformation>) => {
        console.log("error", error);
      });
    };
    updateProps();
onMounted(() => {
  console.log(formState)
})


    return {
      formState,
      formRef,
      onSubmit,
      onTextChange,
      messengerTags,
      updateSuccessAction,
      updateFailedAction,
      updateDefaultAction,
      deleteCurrentFlowContext,
      messageContexts,
      showImageSingleSelectionModal,
      validateInfos,
      attachFile,
      imageSelectionRef,
      messageTag,
      viralTypes, onTypeChange,
      addMoreGift,
      deleteGift,
      viralName,
      templateMessageFullName,
      subtitle,
      forcusMessagetext,
      onImageSelect,
      deleteGiftImage,
      updateSubmitAction,
      logoUrl,
      showCreateGift,
      showModalCreateGift,
      giftData,
      giftIndex,
      handleOk,
      updateSubmitActionGift,
    };
  },
});
</script>

<style lang="scss" scoped>
.main-flow {
  position: relative;
  padding: 40px 18% 60px;
  text-align: left;

  .icon-brand-sticker {
    position: absolute;
    font-size: 28px;
    top: 0;
    margin-right: 25px;
    right: 0;
  }

  .row-button-action {
    margin-top: 20px;
  }

  .card-message {
    border-radius: 10px;
    flex: 1;
    margin-right: 18%;
    margin-left: 18%;
  }
}

@media only screen and (min-width: 768px) {
  /* For desktop: */
  .card-lucky-wheel-control {
    display: grid;
    gap: 10px;
    width: 100%;
    grid-template-columns: auto auto;
  }
  .card-card-lucky-wheel-main {
    width: 260px;
  }
}

@media only screen and (max-width: 480px) and (min-width: 320px) {
  .card-lucky-wheel-control {
  }
}

.contain-card-lucky-wheel {
  width: 100%;
}

.post-back-button {
  margin-top: 18px;
  text-align: center;
}

.add-more-gift {
  text-align: center;
  margin-top: 20px;
}

.button-post-back {
  margin: 5px 10px;
}

.logo-url{
   margin-left:10px;cursor: pointer;

}

.contain-card-message {
  display: flex;
}


.slick-dot-facebook-template {
  display: inline-block;
  cursor: pointer;
  margin: 7px;

  .slick-dot-facebook-template-content {
    padding: 2px;
  }
}

.contain-lucky-wheel-logo {
  display: flex;

  .container-viral-settings {
    flex: 1;
    padding-left: 12px;
    display: flex;
  }


}
.lucky-wheel-logo {
  width: 80%;
  height: 80%;
}

.card-facebook-template {
  width: 95%;
}

.card-facebook-template-active {
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.img-cover-facebook-template {
  width: 100%;
  height: 200px;
  object-fit: cover;
}

.upload-center {
  display: flex;
  justify-content: center;
  height: 200px;
  align-items: center;
}


.attach-file-container {
  margin-top: 18px;
}

.photo-flow-img {
  max-height: 200px;
  object-fit: cover;
  cursor: pointer;
}

.icon-img-svg {
  width: 28px;
  height: 28px;
}

.button-quick-reply {
  border-radius: 15px;
}
</style>
<style>
.carousel__prev--in-active {
  display: none;
}

.carousel__next--in-active {
  display: none;
}
.preview-container {
  /*background-color: white;*/
  border-radius: 10px;
  padding: 15px;
}

.content-message {
  margin-right: 20px;
  padding: 15px;
  margin-top: 12px;
  /*background-color: #E1E1E1;*/
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  border-bottom-right-radius: 15px;
}
.title-msg-onetime-notification{
  font-weight: bold;
}

.post-back-button {
  margin-top: 10px;
  text-align: center;

  .button-post-back {
    margin: 5px 10px;
  }
}

.title-flow-context {
  font-weight: bold;
}

.img-cover-facebook-template{
  max-height: 200px;
  width: 100%;
  object-fit: cover;
}
.card-facebook-template{
  text-align: left;
  width: 250px;
}

.img-message {
  width: 100%;
  object-fit: cover;
}
.quick-reply-button{
  border-radius: 25px;
  border-color: #1890ff;
  color: #1890ff;
}
</style>
