import {ActionContext, ActionTree} from "vuex";
import {State} from "./states";
import {RootState} from "@/store";
import APIs from "@/APIs/APIs";
import { Mutations } from "./mutations";
import {
    TicketPriceResponse,
    TicketResponse,
    TicketTemplateResponse,
    TicketTypeResponse
} from "@/APIs/response/S_HttpResponse";
import { TicketMutationTypes} from "./mutation-types";
import {TicketRequest, TicketTypeRequest} from "@/APIs/request/TicketRequest";
import {TicketActionTypes} from "@/store/modules/Ticket/action-types";
import {EventRequest} from "@/APIs/request/EventRequest";

type AugmentedActionContext = {
    commit<K extends keyof Mutations>(
        key: K,
        payload: Parameters<Mutations[K]>[1],
    ): ReturnType<Mutations[K]>;
} & Omit<ActionContext<State, RootState>, 'commit'>

export interface Actions {
    [TicketActionTypes.FETCH_TICKET](
        {commit}: AugmentedActionContext,
        payload?: TicketRequest,
    ): Promise<TicketResponse | undefined>;
    [TicketActionTypes.FETCH_TICKET_TYPE](
        {commit}: AugmentedActionContext,
        payload?: TicketTypeRequest,
    ): Promise<TicketTypeResponse | undefined>;
    [TicketActionTypes.FETCH_TICKET_PRICE](
        {commit}: AugmentedActionContext,
        payload?: TicketTypeRequest,
    ): Promise<TicketPriceResponse | undefined>;
    [TicketActionTypes.FETCH_TICKET_TEMPLATE](
        {commit}: AugmentedActionContext,
        payload?: EventRequest,
    ): Promise<TicketTemplateResponse | undefined>;
    [TicketActionTypes.FETCH_TICKET_TRANSACTION](
        {commit}: AugmentedActionContext,
        payload?: any,
    ): Promise<any | undefined>;

}

export const actions: ActionTree<State, RootState> & Actions = {
    async [TicketActionTypes.FETCH_TICKET]({commit }, payload: TicketRequest): Promise<TicketResponse | undefined> {
        const response = await APIs.init().getTickets(payload)
        if (response.status === 200) {
            commit(TicketMutationTypes.GET_TICKET,
                // @ts-ignore
                 { tickets: response.data?.data,
                metadata: response.data?.meta_data,})
        }
        return response.data;
    },
    async [TicketActionTypes.FETCH_TICKET_TYPE]({commit }, payload: TicketTypeRequest): Promise<TicketTypeResponse | undefined> {
        const response = await APIs.init().getTicketTypes(payload)
        if (response.status === 200) {
            commit(TicketMutationTypes.GET_TICKET_TYPE,
                // @ts-ignore
                { ticketTypes: response.data?.data,
                    metadata: response.data?.meta_data,})
        }
        return response.data;
    },
    async [TicketActionTypes.FETCH_TICKET_PRICE]({commit }, payload: TicketTypeRequest): Promise<TicketPriceResponse | undefined> {
        const response = await APIs.init().getTicketPrices(payload)
        if (response.status === 200) {
            commit(TicketMutationTypes.GET_TICKET_PRICE,
                // @ts-ignore
                { ticketPrices: response.data?.data,
                    metadata: response.data?.meta_data,})
        }
        return response.data;
    },
    async [TicketActionTypes.FETCH_TICKET_TEMPLATE]({commit }, payload: EventRequest): Promise<TicketTemplateResponse | undefined> {
        const response = await APIs.init().getTicketTemplate(payload)
        if (response.status === 200) {
            commit(TicketMutationTypes.GET_TICKET_TEMPLATE,
                // @ts-ignore
                { templates: response.data?.data,
                    metadata: response.data?.meta_data,})
        }
        return response.data;
    },
    async [TicketActionTypes.FETCH_TICKET_TRANSACTION]({commit }, payload: any): Promise<any | undefined> {
        const response = await APIs.init().getTransactions(payload)
        if (response.status === 200) {
            commit(TicketMutationTypes.GET_TICKET_TRANSACTION,
                // @ts-ignore
                { ticketTransaction: response.data?.data,
                    metadata: response.data?.meta_data,})
        }
        return response.data;
    },


}


