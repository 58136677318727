<template>
  <div class="container-title">
    <h3 class="title-header">{{$t($route.name)}}</h3>
  </div>
  <a-card style="
        margin: 16px;
      ">
    <a-row justify="center">
      <a-col :span="12">
        <a-form
            v-if="changePassword === false"
            :model="formState"
            :label-col="{ span: 8 }"
            :wrapper-col="{ span: 16 }"
            labelAlign="left"
        >
          <a-form-item
              :label="$t('NAME')"


          >
            <a-input v-model:value="formState.name" />
          </a-form-item>

          <a-form-item
              label="Email"

          >
            <a-input v-model:value="formState.email" />
          </a-form-item>
          <a-form-item
              :label="$t('phone_number')"

          >
            <a-input v-model:value="formState.mobile" />
          </a-form-item>
        </a-form>
        <a-form
            v-else
            :model="formState"
            :label-col="{ span: 8 }"
            :wrapper-col="{ span: 16 }"
            labelAlign="left"
        >
          <a-form-item
                :label="$t('OLD_PASSWORD')"
              name="oldPassword"
          >
            <a-input-password  v-model:value="formState.old_password" />
          </a-form-item>

          <a-form-item
              :label="$t('NEW_PASSWORD')"

          >
            <a-input-password v-model:value="formState.new_password" />
          </a-form-item>
          <a-form-item
              :label="$t('ENTER_THE_NEW_PASSWORD')"

          >
            <a-input-password  v-model:value="formState.password" />
          </a-form-item>
        </a-form>
        <a-row justify="center">
          <a-button @click="update" style="margin-right: 10px" type="primary">{{$t('SAVE')}}</a-button>
          <a-button v-if="changePassword === false" style="margin-right: 10px" @click="changePassword = true">{{$t('CHANGE_PASSWORD')}}</a-button>
          <a-button v-else  @click="changePassword = false" style="margin-right: 10px" type="primary">{{$t('ACCOUNT')}}</a-button>
        </a-row>
      </a-col>

    </a-row>

  </a-card>
</template>
<script lang="ts">
import {computed, ref} from "vue";
import {useStore} from "vuex";
import APIs from "@/APIs/APIs";
import {message} from "ant-design-vue";
import {UserActionTypes} from "@/store/modules/user/action-types";
import i18n from "@/i18n";
import {useI18n} from "vue-i18n";
import useUserStore from "../../store/User";

export default {

  setup(){
    const changePassword = ref(false)
    const store = useStore()
    const formState = ref<any>({})
    const i18n = useI18n()

    const userInfo = computed(()=> useUserStore.getState().userInfo)
    formState.value = {
      id:userInfo.value.id,
      name:userInfo.value.name,
      email:userInfo.value.email,
      mobile:userInfo.value.mobile,
      old_password: '',
      new_password: '',
      password:''
    }

    const fetchUserinfo = () =>{
      store.dispatch(UserActionTypes.FETCH_USER_INFO)
    }

    const update = async ()=>{


          const res = await APIs.init().updateUserInfo({name:formState.value.name,id:formState.value.id,email:formState.value.email,mobile:formState.value.mobile,password:formState.value.password})
          if(res.data?.status === 200){
            message.success(i18n.t('SUCCESS'))
            fetchUserinfo()
          }else {
            message.success(res.data?.message + "")
          }



    }
    return{
      formState,
      changePassword,
      update,
    }
  }
}
</script>
