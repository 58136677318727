import {StocksData, State, StockProductsData} from "./states";

export type Getters = {
    getStocks(state: State): StocksData
    getStockProducts(state: State): StockProductsData
}
export const getters: Getters = {
    getStocks(state: State): StocksData {
        if (state.stockData) {
            return state.stockData
        } else {
            return {
                stocks: [],
                metadata: {},
                loading: false,
            }
        }
    },
    getStockProducts(state: State): StockProductsData {
        if (state.stockProductsData) {
            return state.stockProductsData
        } else {
            return {
                products: [],
                metadata: {},
                loading: false,
            }
        }
    },
}