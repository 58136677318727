export enum CustomersActionTypes {
    FETCH_CUSTOMERS = 'FETCH_CUSTOMERS',
    EXPORT_CUSTOMERS = 'EXPORT_CUSTOMERS',
    FETCH_CXVIEW = 'FETCH_CXVIEW',
    FETCH_CUSTOMER = 'FETCH_CUSTOMER',
    FETCH_CXVIEW_GROUP = 'FETCH_CXVIEW_GROUP',
    FETCH_CAREER = 'FETCH_CAREER',
    FETCH_CUSTOMER_CONFIG = 'FETCH_CUSTOMER_CONFIG',
    FETCH_HISTORY_CUSTOMER_CHANGE = 'FETCH_HISTORY_CUSTOMER_CHANGE',
}
