<template>
  <a-layout-content>
    <div class="container-title">
      <h3 class="title-header">{{ $t($route.name) }}</h3>
      <a-form-item class="filter-form-item-inline">

        <a-range-picker
            showTime
            v-model:value="currentRangeDate"
            format="DD-MM-YYYY HH:mm"
            @change="dateRangeChange"
        />
      </a-form-item>

    </div>
    <a-card class="card_view" style="border-radius: 10px; margin: 16px;">
      <a-select
          :value="currentTagParent"
          style="width: 100%"
          placeholder="Please select"
          @change="changeTagParent"
      >
        <a-select-option
            v-for="item in tagParents"
            :key="item.id"
            v-bind:value="item.id"
        >
          <a-tag :color="item.color">{{ item.name }}</a-tag>
        </a-select-option>
      </a-select>

      <keep-alive>
        <highcharts class="chart" :options="{
      chart: {
        type: 'column',
      },
      title: {
        text: $t('REPORT_CUSTOMER_BY_TAGS')
      },
      xAxis: {
        categories: currentTagParentChild.map(tag=>tag.tag_name)
      },
      yAxis: {
        min: 0,
        title: {
          text: null
        },
      },
      legend: {
        align: 'right',
        verticalAlign: 'top',
        borderColor: '#CCC',
        borderWidth: 1,
        shadow: false,
        enabled: false

      },
      tooltip: {
        headerFormat: '<b>{point.x}</b><br/>',
        formatter: '<b>{this.y}</b><br/>',
                    enabled: false
      },
      plotOptions: {
        column: {
          stacking: 'normal',
          dataLabels: {
            enabled: true
          }
        }
      },
      series: [{
        name: '',
        data: currentTagParentChild.map(tag=>({
          y: tag.total,
          color: tag.color,
        }))
      }],
    }"></highcharts>
      </keep-alive>
    </a-card>
    <a-card class="card_view" style="border-radius: 10px; margin: 16px;">
      <keep-alive>
        <highcharts
            class="chart" :options="{
      chart: {
        type: 'column',
      },
      title: {
        text: $t('REPORT_CUSTOMER_BY_CARE')
      },
      xAxis: {
        categories: chartDataReportCareAll.label
      },
      yAxis: {
        min: 0,
        title: {
          text: null
        },
      },
      legend: {
                enabled:false,
        align: 'right',
        verticalAlign: 'top',
        borderColor: '#CCC',
        borderWidth: 1,
        shadow: false
      },
      tooltip: {
        headerFormat: '<b>{point.x}</b><br/>',
        enable: false
      },
      plotOptions: {
        column: {
          colors: chartDataReportCareAll.color,
          stacking: 'normal',
          dataLabels: {
            enabled: true
          }
        }
      },
      series: chartDataReportCareAll.total ? [
          {
            data: chartDataReportCareAll.total&&chartDataReportCareAll.total.map((total, index)=>({
        y: chartDataReportCareAll.total[index],
        color: chartDataReportCareAll.color[index],
      }))
          }
      ]:[]
    }"></highcharts>

      </keep-alive>
    </a-card>
  </a-layout-content>
</template>

<script>

import {useStore} from "vuex";
import {ShopinfoActionTypes} from "@/store/modules/Shopinfo/action-types";
import {computed, onMounted, ref} from "vue";
import {useI18n} from "vue-i18n";
import APIs from "@/APIs/APIs";
import {MixUtils} from "@/utils/Utils";
import dayjs from "dayjs";
import {TagsActionTypes} from "@/store/modules/tag/action-types";
import useTagStore from "../../../store/Tags";



export default {
  components: {},
  setup() {
    const i18n = useI18n()
    const store = useStore();
    const currentRangeDate = ref([dayjs().subtract(30, 'days'), dayjs()])
    const requestState = ref({})
    const currentTagParent = ref(0)
    const currentTagParentChild = ref([])
    const fetchShopInfo = () => {
      store.dispatch(ShopinfoActionTypes.FETCH_SHOPINFO);
      useTagStore.getState().GetTreeTag({type: "customer", search: ''})
      useTagStore.getState().GetRootTag({type: "customer", search: ''})
      // store.dispatch(TagsActionTypes.FETCH_ROOT_TAGS, {type: "customer", search: ''});
      // store.dispatch(TagsActionTypes.FETCH_TREE_TAGS, {payload: {type: "customer", search: ''}});
    };

    const getListEmployee = computed(() => store.getters.getListEmployee);
    // const getCustomerTags = computed(() => useTagStore.getState().tagSales);


    const chartData = ref({
      currentUserData: [],
      currentConversationData: [],
      labelCurrent: [],
    })

    const chartDataReportTag = ref({
      total: [],
      label: [],
    })
    const chartDataReportCare = ref({
      total: [],
      label: [],
    })

    const chartDataReportCareAll = ref({})
    const tagParents = ref([])
    const labels = ref([])
    const labelsTag = ref([])
    const labelsTagEmployee = ref([])
    const labelsCareEmployee = ref([])

    const getReportCustomerTag = async () => {

      const response = await APIs.init().reportCustomerTag({
        ...{
          from_date: currentRangeDate.value[0].format("YYYY-MM-DDTHH:mm:ssZ") ,
          to_date: currentRangeDate.value[1].format("YYYY-MM-DDTHH:mm:ssZ")
        }, ...requestState.value
      })
      tagParents.value = []
      labelsTagEmployee.value = []
      if (response.status === 200 && response.data?.status === 200) {

        if(response.data.data==null){
          response.data.data = []
        }

        const parents = []
        for (let i = 0; i < useTagStore.getState().tagSales.length; i++) {
          if (useTagStore.getState().tagSales[i].parent_id === 0) {
            let temp = useTagStore.getState().tagSales[i]
            temp.child = []
            for (let j = 0; j < response.data?.data?.length; j++) {
              if (temp.id === response.data?.data[j].tag_id) {
                temp.total = response.data?.data[j].total
              }
              if (temp.id === response.data?.data[j].parent_id) {
                  temp.child.push(response.data?.data[j])
              }
            }
            parents.push(temp)
          }
        }
        tagParents.value = parents

        if (parents.length > 0) {
          currentTagParent.value = parents[0].id
          updateListChild(parents[0].id)
        }else{
          console.log(parents.length)
        }
      }

    }

    const getReportCustomerCare = async () => {



      const response = await APIs.init().reportCustomerCare({
        ...{
          from_date: currentRangeDate.value[0].format("YYYY-MM-DD") + "T00:00:00Z",
          to_date: currentRangeDate.value[1].format("YYYY-MM-DD") + "T00:00:00Z"
        }, ...requestState.value
      })
      chartDataReportCareAll.value = {
        total: [],
        label: [],
        color: [],
      }
      labelsCareEmployee.value = []
      if (response.status === 200 && response.data?.status === 200) {

        if(response.data.data==null){
          response.data.data = []
        }
        chartDataReportCareAll.value = {
          total: response.data?.data.map(t => t.total),
          label: response.data?.data.map(t => t.care_name),
          color: response.data?.data.map(t => t.color),
        }
      } else {
        chartDataReportCareAll.value = {
          total: [],
          label: [],
          color: [],
        }
      }

    }

    const dateRangeChange = (val) => {
      getReportCustomerTag();
    }
    const updateListChild = (parent_id) => {
      const tag = tagParents.value.find(item => item.id === parent_id)
      if (tag.child.length>0) {
        currentTagParentChild.value = tag.child
      } else {
        const tag = tagParents.value.find(item => item.id === parent_id)
        currentTagParentChild.value = [tag]
      }
    }

    const formatMoney = (val) => {
      return MixUtils.methods.formatMoney(val)
    }

    const onChangeEmployee = () => {
      getReportCustomerTag();
      getReportCustomerCare();
    }
    const changeTagParent = (tag) => {
      currentTagParent.value = tag
      updateListChild(tag)
    }

    onMounted(() => {
      fetchShopInfo();
      getReportCustomerTag();
      getReportCustomerCare();
    });

    return {
      currentTagParentChild,
      requestState,
      currentTagParent,
      changeTagParent,
      getListEmployee,
      chartData,
      chartDataReportTag,
      chartDataReportCare,
      formatMoney,
      currentRangeDate,
      dateRangeChange,
      onChangeEmployee,
      labels,
      labelsTag,
      chartDataReportCareAll,
      tagParents,
      labelsTagEmployee,
      labelsCareEmployee,
    };
  },
};
</script>

<style>
.card_view {
  border-radius: 10px;
  margin: 4px;
  overflow: hidden;
}

.count-elements {
  -webkit-tap-highlight-color: transparent;
  -webkit-text-size-adjust: 100%;
  font-family: Roboto, Helvetica, Arial, sans-serif;
  font-variant: tabular-nums;
  line-height: 1.5;
  font-feature-settings: 'tnum';
  font-size: 14px;
  color: #000;
  box-sizing: border-box;
  height: 76px;
  padding: 10px;
  border-top: 1px solid #e0e0e0;
  border-bottom: 1px solid #e0e0e0;
  border-radius: 10px 0px 0px 10px;
  cursor: pointer;
  box-shadow: 0px 2px 10px rgba(51, 51, 51, 0.1);
  border-left: 10px solid rgb(43, 216, 196);
  background-color: rgba(196, 255, 248, 0.23);
  width: 100%;
}

.title {
  font-family: "Roboto" !important;
  font-style: normal !important;
  font-weight: 700 !important;
  font-size: 12px !important;
  line-height: 22px !important;
  color: #000000 !important;
  margin-left: 0px !important;
  margin-top: 0px !important;
  text-align: left !important;
  width: 100% !important;
}

.count {
  text-align: right;
  font-weight: 100;
  font-family: Montserrat-Light;
  font-size: 25px;
  line-height: 30px;
  color: #263C8F;
  float: right;
}

.highcharts-credits {
  display: none;
}

.filter-form-item-inline {
  display: inline-block;
  margin-bottom: 0;
  padding-bottom: 0;
}

.count-elements {
  -webkit-tap-highlight-color: transparent;
  -webkit-text-size-adjust: 100%;
  font-family: Roboto, Helvetica, Arial, sans-serif;
  font-variant: tabular-nums;
  line-height: 1.5;
  font-feature-settings: 'tnum';
  font-size: 14px;
  color: #000;
  box-sizing: border-box;
  height: 76px;
  padding: 10px;
  border-top: 1px solid #e0e0e0;
  border-bottom: 1px solid #e0e0e0;
  border-radius: 10px 0px 0px 10px;
  cursor: pointer;
  box-shadow: 0px 2px 10px rgba(51, 51, 51, 0.1);
  border-left: 10px solid rgb(43, 216, 196);
  background-color: rgba(196, 255, 248, 0.23);
  width: 100%;
}

.title {
  font-family: "Roboto" !important;
  font-style: normal !important;
  font-weight: 700 !important;
  font-size: 12px !important;
  line-height: 22px !important;
  color: #000000 !important;
  margin-left: 0px !important;
  margin-top: 0px !important;
  text-align: left !important;
  width: 100% !important;
}

.count {
  text-align: right;
  font-weight: 100;
  font-family: Montserrat-Light;
  font-size: 25px;
  line-height: 30px;
  color: #263C8F;
  float: right;
}

</style>
