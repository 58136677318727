<template>
  <a-row>
    <a-col :span="8">
      <a-typography-title :level="2">{{$t('employee_assignment')}}</a-typography-title>
      <a-typography-text type="secondary">{{$t('assigning_employees_for_pages')}}</a-typography-text>
    </a-col>
    <a-col :span="6"></a-col>
    <a-col v-if="isPage" :span="10">
      <a-row style="justify-content: flex-end">
        <a-button @click="save"  type="primary">{{$t('SAVE')}}</a-button>
      </a-row>

    </a-col>
  </a-row>
  <a-row  style="margin-top: 20px;">
    <a-col :span="11">
      <a-typography-title :level="5">{{$t('change_shop')}}</a-typography-title>
      <a-select
          allowClea
          :value="currentShop.currentShop.id"
          style="width: 100%"
          placeholder="Please select"
          @change="changeShop"
      >
        <a-select-option
            v-for="item in userInfo.shops"
            :key="item.id"
            v-bind:value="item.id"
        >{{ item.shop_name }}
        </a-select-option>
      </a-select>
    </a-col>
    <a-col :span="2"></a-col>
    <a-col :span="11">
      <a-typography-title :level="5">{{$t('select_page')}}</a-typography-title>
      <a-select
          allowClea
          show-search
          style="width: 100%"
          placeholder="Please select"
          @change="changePage"
          v-model:value="getCurrentPageId"
      >
        <a-select-option
            v-for="item in configPage.pageConfig"
            :key="item.id"
            v-bind:value="item.page_id"
        > <img
            v-if="item.page_type === 2"
            draggable="false"
            src="@/assets/icons/zalo-seeklogo.com.svg"
            style="width: 20px;margin-right: 20px"
        />
          <img
              v-else-if="item.page_type === 1"
              draggable="false"
              src="@/assets/icons/facebook-svgrepo-com.svg"
              style="width: 20px;margin-right: 20px"
          />
          <img
              v-else-if="item.page_type === 3"
              draggable="false"
              src="@/assets/icons/instagram-svgrepo-com.svg"
              style="width: 20px;margin-right: 20px"
          />
          <img
              v-else-if="item.page_type === 4"
              draggable="false"
              src="@/assets/icons/line-svgrepo-com.svg"
              style="width: 20px;margin-right: 20px"
          />{{ item.page_name }}
        </a-select-option>
      </a-select>
    </a-col>
    <a-row v-if="!isPage" style="margin-top: 30px;width: 100%" justify="center">
      <a-typography-title  :level="3">{{$t('PLEASE_CHOOSE_SHOP_AND_FANPAGE')}}</a-typography-title>
    </a-row>
  </a-row>

  <div v-if="isPage" style="margin-top: 20px;">
    <a-card  style="margin-top: 20px;border-radius: 10px" :span="24">
      <template #title>
        <a-row>
          <a-col :span="8">
            <TagsOutlined />
            {{$t('functions')}}
          </a-col>
          <a-col :span="14">
          </a-col>
          <a-col :span="2">
            <a-button @click="pushTags"  style="border-radius: 50%">
              <template #icon><PlusOutlined/></template>
            </a-button>
          </a-col>
        </a-row>

      </template>
      <a-row style="margin-bottom: 20px">
        <a-switch v-model:checked="configData.role_user_page.enabled" />
        <a-typography-text style="margin-left: 10px">{{$t('assign_employees_able_access')}}</a-typography-text>
      </a-row>
      <a-row style="margin: 0 20px 20px" v-if="configData.role_user_page.enabled">
        <div style="margin-right: 20px" v-for="i in dataEmployee.employees" :key="i.cms_user_id">
          <a-switch :checkedValue="configData.role_user_page.employee.includes(i.cms_user_id) ? false : true" @change="selectEmployee(i.cms_user_id)" size="small" />
          <a-typography-text style="margin-left: 10px">{{$t(i.name)}}</a-typography-text>
        </div>
      </a-row>
      <a-row>
        <a-switch v-model:checked="configData.conversation_share.state" />
        <a-typography-text style="margin-left: 10px">{{$t('assign_conversations_to_employee')}}</a-typography-text>
      </a-row>
      <a-form v-if="configData.conversation_share.state">
        <a-form-item :label="$t('messenger_windows')">
          <a-select
              v-model:value="conversation_share_inbox"
              mode="tags"
              style="width: 100%"
              placeholder="Tags Mode"
              @change="handleChangeConversationShareInbox"
          >
            <a-select-option
                v-for="employee in dataEmployee.employees"
                :key="employee.cms_user_id" :value="employee.cms_user_id">
              {{employee.name}}
            </a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item :label="$t('comment')">
          <a-select
              v-model:value="conversation_share_comment"
              mode="tags"
              style="width: 100%"
              placeholder="Tags Mode"

              @change="handleChangeConversationShareComment"
          >
            <a-select-option
                v-for="employee in dataEmployee.employees"
                :key="employee.cms_user_id" :value="employee.cms_user_id">
              {{employee.name}}
            </a-select-option>
          </a-select>
        </a-form-item>
        <a-row>
          <a-switch v-model:checked="configData.hide_conversation_employee" size="small" />

          <a-typography-text style="margin-left: 10px">{{$t('hide_conversations_employee')}}</a-typography-text>

        </a-row>

        <div style="margin-left: 30px">
          <a-row style="margin-bottom: 20px;margin-top: 20px"  v-if="configData.hide_conversation_employee">
            <a-switch v-model:checked="configData.hide_conversation" size="small" />

            <a-typography-text style="margin-left: 10px">{{$t('hide_conversations_with_unassign')}}</a-typography-text>
          </a-row>
        </div>
        <a-row>
          <a-switch v-model:checked="configData.first_reply_assignee" size="small" />

          <a-typography-text style="margin-left: 10px">{{$t('first_reply_assignee')}}</a-typography-text>
        </a-row>
      </a-form>

    </a-card>

  </div>



</template>
<script lang="ts"  >

import { TagsOutlined,PlusOutlined,MinusOutlined} from '@ant-design/icons-vue';
import {computed, onMounted, ref,watch} from "vue";
import {useStore} from "@/store";
import {useRouter} from "vue-router";
import {CustomersActionTypes} from "@/store/modules/customer/action-types";
import {UsersMutationTypes} from "@/store/modules/user/mutation-types";
import './vue-color-kit.css';
import {ColorPicker} from 'vue-color-kit';

import {MixUtils} from "@/utils/Utils";
import {PageConfigActionTypes} from "@/store/modules/PageConfig/action-types";
import APIs from "@/APIs/APIs";
import {message} from "ant-design-vue";
import {useI18n} from "vue-i18n";
import {PageConfigMutationTypes} from "@/store/modules/PageConfig/mutation-types";
import useEmployeeStore from "../../store/Employee";
import useUserStore from "../../store/User";
import usePageConfigStorageStore from "../../store/PageConfig";
import useOverviewStore from "../../store/Overview";
export default {
  components: {
    TagsOutlined,
    PlusOutlined,
    // ColorPicker,
    // MinusOutlined
  },
  setup(){
    const store = useStore()
    const router = useRouter()
    const userInfo = useUserStore()
    const currentShop =  useUserStore()
    const getShops = useUserStore()
    const configPage = usePageConfigStorageStore()
    const getCurrentPageId = computed(()=> store.getters.getCurrentPageId)
    const dataEmployee = useEmployeeStore()
    const isPage = ref(false)
    const tabkey = computed(() => store.getters.getCrrentTab)

    const color = ref("#59c7f9")
    const tagColor = ref('')
    const suckerCanvas = ref(null)
    const suckerArea = ref([])
    const tagList = ref([])
    const i18n = useI18n()
    const configCurrentPage = ref({})
    const configData = ref({
      role_user_page:{
        employee:[],
        enabled:false
      }
    })
    const conversation_share_inbox = ref([])
    const conversation_share_comment = ref([])

    const changeShop = async (shopId:any) => {
      const response = await APIs.init().changeShop({
        shop_id: shopId,
        // @ts-ignore
        token: useUserStore.getState().userInfo.token
      })
      if(response.status == 200 && response.data?.data){

        store.commit(UsersMutationTypes.SET_UserInfo,{...useUserStore.getState().userInfo, ...{token: response.data?.data}})
        // @ts-ignore
        store.commit(PageConfigMutationTypes.GET_PAGE_CONFIG,'')
        store.commit(UsersMutationTypes.SET_CurrentShop,shopId);
// @ts-ignore
        router.go()
      }
    }
    watch(tabkey, async (newQuestion, oldQuestion) => {
      if(newQuestion !== oldQuestion){
        if( configPage.pageConfig){
          isPage.value=true
          configPage.pageConfig.map(item => {
            // @ts-ignore
            if(item.page_id === getCurrentPageId.value){
              configCurrentPage.value = item
              configData.value = JSON.parse(item.config_data)
              if(!configData.value.role_user_page){
                configData.value.role_user_page = {employee:[],enabled:false}
              }


              // @ts-ignore
              if(configData.value.conversation_share) {

                // @ts-ignore
                conversation_share_inbox.value = configData.value.conversation_share.inbox.map((item: any) => Number(item.id))
                // @ts-ignore
                conversation_share_comment.value = configData.value.conversation_share.comment.map((item: any) => Number(item.id))
              }
            }

          })

        }
      }
    })
const handleChangeConversationShareInbox = (value:any) =>{

  // @ts-ignore
  configData.value.conversation_share.inbox =  useEmployeeStore.getState().employees.map((item:any) => {
    if(value.includes(item.cms_user_id))
      return{
        selected: true,
        disabled: false,
        text: item.name,
        id: item.cms_user_id+"",
        social_id: item.social_id,
      }

  })

  // @ts-ignore
  configData.value.conversation_share.inbox = configData.value.conversation_share.inbox.filter((item:any) => item !== undefined)
  // @ts-ignore
  console.log(configData.value.conversation_share.inbox)
    }
    const handleChangeConversationShareComment = (value:any) =>{
      console.log(value)
      // @ts-ignore
      configData.value.conversation_share.comment =  useEmployeeStore.getState().employees.map((item:any) => {
        console.log(item)
        if(value.includes(item.cms_user_id))
          return{
            selected: true,
            disabled: false,
            text: item.name,
            id: item.cms_user_id+"",
            social_id: item.social_id,
          }

      })

      // @ts-ignore
      configData.value.conversation_share.comment = configData.value.conversation_share.comment.filter((item:any) => item !== undefined)
      // @ts-ignore
      console.log(configData.value.conversation_share.comment)
    }
    const changePage = (pageId:any) => {
      if(pageId){
        store.commit(PageConfigMutationTypes.GET_PAGE_CONFIG,pageId)
      }
      if(configPage.pageConfig){
        isPage.value=true
        configPage.pageConfig.map(item => {
          // @ts-ignore
          if(item.page_id === pageId){
            configCurrentPage.value = item
            configData.value = JSON.parse(item.config_data)
            if(!configData.value.role_user_page){
              configData.value.role_user_page = {employee:[],enabled:false}
            }
            console.log(configData.value,'098')
            // @ts-ignore
            if(configData.value.conversation_share) {

              // @ts-ignore
              conversation_share_inbox.value = configData.value.conversation_share.inbox.map((item: any) => Number(item.id))
              // @ts-ignore
              conversation_share_comment.value = configData.value.conversation_share.comment.map((item: any) => Number(item.id))
            }
          }

        })

      }



    }
    const selectEmployee = (id:number) => {

      // @ts-ignore
      if(configData.value.role_user_page.employee.includes(id)){
        // @ts-ignore
        configData.value.role_user_page.employee = configData.value.role_user_page.employee.filter((item:any) => item !== id)
      }else {
        console.log(id)
        // @ts-ignore
        configData.value.role_user_page.employee.push(id)
      }
      console.log(configData.value.role_user_page)
    }
    const fetchApi = async () => {
      usePageConfigStorageStore.getState().getPageConfig({shop_id: useUserStore.getState().currentShop.id})
      // @ts-ignore
      // store.dispatch(PageConfigActionTypes.FETCH_PAGE_CONFIG,{shop_id: useUserStore.getState().currentShop.id})
    }
    const save = async () =>{
      // @ts-ignore
      // configData.value.role_user_page = {
      //   employee: [],
      //   enabled: false
      // }
      // @ts-ignore
      if(configData.value.role_user_page.enabled){
        // @ts-ignore
        useEmployeeStore.getState().employees.map((item:any)=>{
          // @ts-ignore

          if(item.is_owner&& !configData.value.role_user_page.employee.includes(item.cms_user_id)){
            // @ts-ignore
            configData.value.role_user_page.employee.push(item.cms_user_id)
          }
        })
      }else {
        // @ts-ignore
        configData.value.role_user_page.employee = []
      }

      // @ts-ignore
      console.log(configData.value.conversation_share.state,'sdsd')
      // @ts-ignore
      if(!configData.value.conversation_share.state){
        // @ts-ignore
        configData.value.hide_conversation_employee = configData.value.conversation_share.state
        // @ts-ignore
        configData.value.first_reply_assignee = configData.value.conversation_share.state
      }
      // @ts-ignore
      if(!configData.value.hide_conversation_employee){
        // @ts-ignore
        configData.value.hide_conversation = configData.value.hide_conversation_employee
      }
      // @ts-ignore
      if(typeof configData.value.missing_time !== "number"){
        // @ts-ignore
        configData.value.missing_time = Number(configData.value.missing_time)
      }
      // @ts-ignore
      if(typeof configData.value.font_size_message !== "number") {
        // @ts-ignore
        configData.value.font_size_message = Number(configData.value.missing_time)
      }
      // @ts-ignore
      if(  isNaN(configData.value.missing_time) ){
        // @ts-ignore
        configData.value.missing_time = 1
      }
      // @ts-ignore
      if( isNaN(configData.value.font_size_message) ) {
        // @ts-ignore
        configData.value.font_size_message = 16
      }
      console.log({
        config_data: JSON.stringify(configData.value) ,
        // @ts-ignore
        page_id: configCurrentPage.value.page_id,
        // @ts-ignore
        shop_id:  useUserStore.getState().currentShop.id,
        // @ts-ignore
        page_type:configCurrentPage.value.page_type ,
        // @ts-ignore
        page_name: configCurrentPage.value.page_name,
        // @ts-ignore
        status:configCurrentPage.value.status
      },"conversationTagUpdate")
      try{
        const res = await APIs.init().updatePageConfig({
          // @ts-ignore
          config_data: JSON.stringify(configData.value) ,
          // @ts-ignore
          page_id: configCurrentPage.value.page_id,
          // @ts-ignore
          shop_id:  useUserStore.getState().currentShop.id,
          // @ts-ignore
          page_type:configCurrentPage.value.page_type ,
          // @ts-ignore
          page_name: configCurrentPage.value.page_name,
          // @ts-ignore
          status:configCurrentPage.value.status,
          // @ts-ignore
          page_token:configCurrentPage.value.page_token
        })

        if(res.data?.status === 200){
          message.success(i18n.t(res.data?.message))
         await fetchApi()

        }
      }catch (err){
        console.log(err)
      }

    }
    onMounted(() => {
      if(getCurrentPageId.value && configPage.pageConfig){
        isPage.value=true
        configPage.pageConfig.map(item => {
          // @ts-ignore
          if(item.page_id === getCurrentPageId.value){
            configCurrentPage.value = item
            configData.value = {role_user_page:{employee:[],enabled:false},...JSON.parse(item.config_data)}



           // @ts-ignore

            if(configData.value.role_user_page)
            // @ts-ignore
            conversation_share_inbox.value = configData.value.conversation_share.inbox.map((item:any)=> Number(item.id))
            // @ts-ignore
            conversation_share_comment.value = configData.value.conversation_share.inbox.map((item:any)=> Number(item.id))
          }

        })

      }
    })
    const treeTags = computed(() => store.getters.getTreeTags)

    const checked = ref(false)
    return{
      configData ,
      currentShop,
      checked,
      configPage,
      changeShop,
      changePage,
      isPage,
      color,
      save,
      getShops,
      userInfo,
      getCurrentPageId,
      treeTags,
      dataEmployee,
      // changTagname,
      selectEmployee,
      conversation_share_inbox,
      handleChangeConversationShareInbox,conversation_share_comment,handleChangeConversationShareComment



    }
  }
}
</script>
