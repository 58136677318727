<template>
  <div>

    <div style="display: flex;justify-content: space-between; padding-left: 16px; padding-right: 16px; padding-top: 16px; background-color: white">

      <a-row separator="" style="flex: 1">
<!--        <a-breadcrumb-item href="/customers" @click="goback">Customer list</a-breadcrumb-item>-->
        <a-col :span="4">
          <a-row>
           <a-col :span="8">
<!--             <a-avatar>-->
<!--               {{ firstCharacterName(customerForm.full_name) }}-->
<!--             </a-avatar>-->
             <a-avatar v-if="customerForm.social_type === 'facebook'" style="width: 50px;height: 50px;cursor: pointer"    :src="`https://graph.facebook.com/${customerForm.social_id}/picture?access_token=${getTokenPage(customerForm.page_id)}`">
             </a-avatar>
             <a-avatar v-else style="width: 50px;height: 50px;cursor: pointer"   @click="showImageSingleSelectionModal" :src="customerForm.avatar">
            <a-row style="align-items: center;height: 50px">
              <a-typography-text style="color: white">{{firstCharacterName(customerForm.full_name)}}</a-typography-text>
            </a-row>

             </a-avatar>
             <CameraOutlined @click="showImageSingleSelectionModal"  style="position: absolute;z-index: 999;bottom: -5px;left: 23%;font-size: 18px;cursor: pointer " />
           </a-col>
            <a-col :span="14">
              <a-input v-if="showName"
                       v-model:value="customerForm.full_name"
                       :placeholder="$t('CUSTOMER_NAME')"
                       @blur="handleChangeName"
              />
<a-row v-else>
  <a-typography-text style="color: #b17ad6;font-weight: bold" v-if="customerForm.full_name"  @click="showName = true">{{ customerForm.full_name }}</a-typography-text>
  <a-button v-else @click="showName = true" type="primary">{{$t('ADD')}}</a-button>

</a-row>
<!--              <a-row>-->
<!--                <a-select-->
<!--                    v-if="showNone"-->
<!--                    style="width: 100%"-->
<!--                    placeholder="Please select"-->
<!--                    allowClear-->
<!--                    @change="handleChangeSex"-->

<!--                >-->
<!--&lt;!&ndash;                  <a-select-option&ndash;&gt;-->

<!--&lt;!&ndash;                      value="male"&ndash;&gt;-->
<!--&lt;!&ndash;                  >{{ $t('MALE') }}&ndash;&gt;-->
<!--&lt;!&ndash;                  </a-select-option>&ndash;&gt;-->
<!--&lt;!&ndash;                  <a-select-option&ndash;&gt;-->

<!--&lt;!&ndash;                      value="female"&ndash;&gt;-->
<!--&lt;!&ndash;                  >{{ $t('FEMALE') }}&ndash;&gt;-->
<!--&lt;!&ndash;                  </a-select-option>&ndash;&gt;-->
<!--&lt;!&ndash;                  <a-select-option&ndash;&gt;-->

<!--&lt;!&ndash;                      value="other"&ndash;&gt;-->
<!--&lt;!&ndash;                  >{{ $t('OTHER') }}&ndash;&gt;-->
<!--&lt;!&ndash;                  </a-select-option>&ndash;&gt;-->
<!--                </a-select>-->
<!--                <div v-else>-->
<!--                  <a-typography-text v-if="false"  style="cursor: pointer"  @click="showNone = true">{{$t(customerForm.sex) }}</a-typography-text>-->
<!--                  <a-typography-text v-else  style="cursor: pointer"  @click="showNone = true">{{$t('NONE') }}<down-outlined style="margin-left: 10px" /></a-typography-text>-->

<!--                </div>-->
<!--              </a-row>-->
            </a-col>
          </a-row>
        </a-col>

          <a-divider type="vertical" style="height: 60px;width: 3px" />

        <a-col :span="5">

            <a-row>
              <a-col :span="12">
                <a-typography-text type="secondary">{{$t('CREATOR')}}</a-typography-text>
                <br/>
                <a-typography-text>{{getEmployee(customerForm.created_by)}}</a-typography-text>
              </a-col>
              <a-col :span="12">
                <a-typography-text style="margin-bottom: 5px" type="secondary">{{$t('Assigee')}}</a-typography-text>
                <br/>
                <a-select
                    v-if="showAssigee"
                    style="width: 100%"
                    placeholder="Please select"
                    allowClear
                    v-model:value="customerForm.employees"
                    @change="handleChangeEmplpyee"
                >
                  <a-select-option
                      v-for="item in employees.data"

                      :key="item.cms_user_id"

                      v-bind:value="item.cms_user_id+''"
                  >{{ item.name }}
                  </a-select-option>
                </a-select>
<!--                  <a-select-->
<!--                      style="width: 100%"-->
<!--                      placeholder="Please select"-->
<!--                      allowClear-->
<!--                      @change="handleChangeEmplpyee"-->
<!--                      v-model:value="customerForm.conversation_staff"-->
<!--                  >-->
<!--                    <a-select-option-->
<!--                        v-for="item in employees"-->
<!--                        :key="item.id"-->
<!--                        v-bind:value="item.id+''"-->
<!--                    >{{ item.name }}-->
<!--                    </a-select-option>-->
<!--                  </a-select>-->
<!--                  <a-typography-text style="cursor: pointer" v-else @click="showAssigee = true">{{getEmployee(customerForm.conversation_staff)}}<down-outlined style="margin-left: 10px" /></a-typography-text>-->
                <a-typography-text style="cursor: pointer" v-else @click="showAssigee = true">{{getEmployee(customerForm.employees)}}<down-outlined style="margin-left: 10px" /></a-typography-text>



              </a-col>

            </a-row>



        </a-col>
        <a-breadcrumb-separator>
          <a-divider type="vertical" style="height: 100%;width: 3px;margin-right: 0;margin-left: 0" />
        </a-breadcrumb-separator>

          <a-button type="primary" @click="goback" size="small" class="button-goback">
            <arrow-left-outlined/>
            {{$t('button_back')}}
          </a-button>
          <a-button @click="openChat" size="small">
            <message-outlined/>
            {{ $t("CUSTOMER_CONVERSATION") }}
          </a-button>
          <a-button @click="openSchedule" size="small" class="schedule-employee">
            <schedule-outlined/>
            {{ $t("CREATE_SCHEDULE") }}
          </a-button>
          <a-button  @click="quickMessage" size="small" class="schedule-employee">
            <message-outlined/>
            {{ $t("quick_message") }}
          </a-button>


      </a-row>


    </div>
    <div class="container">
      <a-card style="width: 100%" >
        <a-tabs v-model:activeKey="activeKey2">
          <a-tab-pane key="1" :tab="$t('CUSTOMER_INFO')" force-render>
            <a-card style="width: 100%" >
        <template #title>
          <a-row>
            <a-col :span="4">
              <user-outlined style="margin-right: 10px" />
              {{$t('CUSTOMER_INFO')}}
            </a-col>
            <a-col :span="1">
              <a-breadcrumb-separator>
                <a-divider type="vertical" style="height: 100%;width: 3px" />
              </a-breadcrumb-separator>
            </a-col>
            <a-col :span="16">
         <a-row style="width: 100%;justify-content: flex-end">
           <a-col :span="2">
             <environment-outlined />
             {{$t('CUSTOMER_ADDRESS')}}
           </a-col>
           <a-col :span="1">

           </a-col>
           <a-typography-text style="display: block">
             {{customerForm.address}},{{customerForm.ward_name}},{{customerForm.district_name}},{{customerForm.province_name}}</a-typography-text>
         </a-row>

            </a-col>
            <a-col :span="1">

            </a-col>
            <a-col :span="2">
              <a-button v-if="customerForm.address" @click="showEditAddress = true" type="dashed">{{$t('EDIT')}}</a-button>
              <a-button v-else @click="showEditAddress = true" type="dashed">{{$t('ADD')}}</a-button>
            </a-col>
          </a-row>

        </template>
        <a-form
            :model="customerForm"
            layout="line"
            :label-col="{ span: 10 }"
            :wrapper-col="{ span: 14 }"
            labelAlign="left"

        >
         <a-row>
           <a-col :span="7">
             <a-form-item class="info-customer"  :label="$t('CUSTOMER_BIRTHDAY')" name="birth">

               <a-date-picker
                   v-if="showBirth"
                   v-model:value="customerForm.birth"
                   :placeholder="$t('CUSTOMER_BIRTHDAY')"
                   format="DD-MM-YYYY"
                   @change="handleChangeBirth"
                   ref="birthDay"
               />
               <a-typography-text style="cursor: pointer" v-else @click="handleBirthDay">{{getBirth(customerForm.birth) }}</a-typography-text>
             </a-form-item>
             <a-form-item class="info-customer" :label="$t('SEX')" name="sex">
               <a-select
                   v-if="showSex"
                   style="width: 100%"
                   placeholder="Please select"
                   allowClear
                   @change="handleChangeSex"
                   v-model:value="customerForm.sex"
               >
                 <a-select-option

                     value="male"
                 >{{ $t('MALE') }}
                 </a-select-option>
                 <a-select-option

                     value="female"
                 >{{ $t('FEMALE') }}
                 </a-select-option>
                 <a-select-option

                     value="other"
                 >{{ $t('OTHER') }}
                 </a-select-option>
               </a-select>
               <div v-else>
                 <a-typography-text v-if="customerForm.sex"  style="cursor: pointer"  @click="showSex = true">{{$t(customerForm.sex) }}</a-typography-text>
                 <a-typography-text v-else  style="cursor: pointer"  @click="showSex = true">{{$t('no_customer_information') }}</a-typography-text>

               </div>


             </a-form-item>
             <a-form-item class="info-customer" :label="$t('CUSTOMER_PHONE')" name="phone">
               <a-dropdown :trigger="['click']">
                 <a-typography-text  style="cursor: pointer; color: #b17ad6"    >{{customerForm.phone }} <phone-outlined /></a-typography-text>
                 <template #overlay>
                   <a-menu >
                     <a-menu-item v-for="(i) in allPhones" :key="i">
                       <a-row justify="space-between">
                         <a-typography-text >{{ i }}  <phone-outlined /></a-typography-text>
                         <close-circle-outlined v-if="allPhones.length >= 2" @click="deletePhone(i)" />
                       </a-row>
                     </a-menu-item>

                       <a-input
                           style="margin: 10px;width: 80%"
                           @change="handlePhone"
                           :placeholder="$t('CUSTOMER_PHONE')"
                       />
                     <a-button @click="handleAllphone" style="margin: 10px;" type="primary">{{ $t('ADD') }}</a-button>
                   </a-menu>
                 </template>
               </a-dropdown>
             </a-form-item>
             <a-form-item class="info-customer" label="Email" name="email">
               <div v-if="showEmail">
                 <a-input ref="email" @blur="changeEmail"  v-model:value="customerForm.email" placeholder="Email"/>
<!--                 <a-button @click="onSubmit"  type="primary">{{ $t('ADD') }}</a-button>-->

               </div>

<div v-else>
  <a-typography-text v-if="customerForm.email" style="cursor: pointer;color: #b17ad6" @click="handleEmail"    >{{customerForm.email }} </a-typography-text>
  <a-typography-text  v-else style="cursor: pointer"  @click="handleEmail"   >{{$t('no_customer_information') }} </a-typography-text>

</div>
             </a-form-item>
             <a-form-item :label="$t('CAREER')" name="career">
               <a-select
                   v-if="showCareer"
                   style="width: 100%"
                   placeholder="Please select"
                   allowClear
                   @change="changeCareer"
                   v-model:value="customerForm.career"
               >
                 <a-select-option
                     v-for="item in careers"
                     :key="item.id"
                     v-bind:value="item.id"
                 >{{ item.name }}
                 </a-select-option>
               </a-select>
               <div v-else>
                 <a-typography-text v-if="customerForm.career"  style="cursor: pointer" @click="showCareer = true"    >{{getCareer(customerForm.career) }} </a-typography-text>
                 <a-typography-text  v-else style="cursor: pointer"  @click="showCareer = true"   >{{$t('no_customer_information') }} </a-typography-text>
               </div>
             </a-form-item>
           </a-col>
           <a-divider type="vertical" style="height: 200px" dashed />
           <a-col style="margin-left: 15px" :span="7">
             <a-form-item class="info-customer" :label="$t('bought')" >
               <a-typography-text style="cursor: pointer" >{{orders.length}} {{$t('count')}}</a-typography-text>
             </a-form-item>
             <a-form-item class="info-customer" :label="$t('DILIVERED_ORDER')" >
               <a-typography-text style="cursor: pointer"  >{{getDiliveredOrder(orders)}} {{$t('count')}}</a-typography-text>
             </a-form-item>
             <a-form-item class="info-customer" :label="$t('SPENT_AMOUNT')" >
               <a-typography-text style="cursor: pointer">{{getSpentAmuont(orders)}} đ</a-typography-text>
             </a-form-item>
             <a-form-item class="info-customer" :label="$t('CONVERSATION_TAG')" name="conversation_tags">
               <template v-for="tag in splitString(customerForm.conversation_tags)" v-bind:key="tag">
                 <a-tag v-if="getConversationTagById(tag).tag_id" :color="getConversationTagById(tag).tag_color">
                   {{ getConversationTagById(tag).tag_name }}
                 </a-tag>
               </template>
             </a-form-item>
             <div v-for="i in listConfig " :key="i.id">
               <a-form-item v-if="i.identified_name === 'rank'" class="info-customer" :label="i.identified_name === 'rank'&& $t('MEMBER_RANK')">
                 {{getMemberRANK(i.customer_column_value)}}
               </a-form-item>

               <a-form-item v-if="i.identified_name === 'points'"  class="info-customer" :label="i.identified_name === 'points'&& $t('points')"  >
                 {{i.customer_column_value !==' ' ? formatMoney(i.customer_column_value) : '0'}} điểm
               </a-form-item>
             </div>


           </a-col>
           <a-divider type="vertical" style="height: 200px" dashed />
           <a-col style="margin-left: 15px" :span="8">

             <a-form-item class="info-customer" :label="$t('FROM')" >
               <a-tooltip placement="topLeft">
                 <template #title>
                   <span>{{$t("CUSTOMER_CONVERSATION")}}</span>
                 </template>
                 <img
                     v-if="customerForm.social_type === 'facebook' || customerForm.fb_appuid"
                     draggable="false"
                     src="@/assets/icons/facebook-svgrepo-com.svg"
                     :style="{width: customerForm.social_type === 'facebook' ? '25px' : '20px','margin-right': '20px', cursor: 'pointer'}"
                     @click="openChat(customerForm.page_id ? customerForm.page_id :customerForm.fb_page_id, customerForm.social_id ?customerForm.social_id:customerForm.fb_appuid, customerForm.shop_id)"
                 />
               </a-tooltip>
               <a-tooltip placement="topLeft">
                 <template #title>
                   <span>{{$t("CUSTOMER_CONVERSATION")}}</span>
                 </template>
                 <img
                     v-if="customerForm.social_type === 'zalo' || customerForm.zalo_appuid  "
                     draggable="false"
                     src="@/assets/icons/zalo-seeklogo.com.svg"
                     :style="{width: customerForm.social_type === 'zalo' ? '25px' : '20px','margin-right': '20px', cursor: 'pointer'}"
                     @click="openChat(customerForm.page_id ? customerForm.page_id :customerForm.zalo_page_id, customerForm.social_id ?customerForm.social_id:customerForm.zalo_appuid, customerForm.shop_id)"

                 />
               </a-tooltip>
               <!--            <img-->
               <!--                v-else-if=""-->
               <!--                draggable="false"-->
               <!--                src="@/assets/icons/facebook-svgrepo-com.svg"-->
               <!--                style="width: 20px;margin-right: 20px"-->
               <!--            />-->


               <img
                   v-if="customerForm.social_type === 'instagram'"
                   draggable="false"
                   src="@/assets/icons/instagram-svgrepo-com.svg"
                   style="width: 20px;margin-right: 20px"
               />
               <img
                   v-if="customerForm.social_type === 'line' "
                   draggable="false"
                   src="@/assets/icons/line-svgrepo-com.svg"
                   style="width: 20px;margin-right: 20px"
               />
               <img
                   v-if="customerForm.social_type === 'whatsapp'"
                   draggable="false"
                   src="@/assets/icons/whatsapp-svgrepo-com.svg"
                   style="width: 20px;margin-right: 20px"
               />
               <img
                   v-if="customerForm.social_type === 'cms'"
                   draggable="false"
                   src="@/assets/icons/logo-one.png"
                   style="width: 20px;margin-right: 20px"
               />
               <tag-outlined style="width: 20px;margin-right: 20px"  v-if="customerForm.social_type === 'ticket'" />
               <img
                   v-if="customerForm.social_type === 'kiotviet'"
                   draggable="false"
                   src="@/assets/icons/KiotViet-Logo.svg"
                   style="width: 60px;margin-right: 20px"
               />
               <img
                   v-if="customerForm.social_type === 'miniapp'"
                   draggable="false"
                   src="@/assets/icons/mini-app.svg"
                   style="width: 60px;margin-right: 20px"
               />
             </a-form-item>
             <a-form-item class="info-customer" :label="$t('PAGE_ID')" >
               {{getPageId(customerForm.page_id)}}
             </a-form-item>
             <a-form-item class="info-customer" :label="$t('CARE_STATUS')" name="last_care">
              <a-row v-if="showCare">
                <a-col :span="17">
                  <a-select
                      style="width: 100%"
                      allow-clear
                      placeholder="Please select"
                      v-model:value="customerForm.last_care"
                      @change="handleCare"

                  >
                    <template #clearIcon>
                      <close-circle-outlined @click="handleCare" />

                    </template>
                    <a-select-option
                        v-for="item in customerCares"
                        :key="item.id"
                        v-bind:value="item.id"
                    >
                      <a-tag :color="item.color">
                        {{ item.name }}
                      </a-tag>
                    </a-select-option>
                  </a-select>
                </a-col>
                <a-col :span="1">

                </a-col>
                <a-col :span="6">
                <a-button size="small"  @click="handleQuickCreate('customer-care')"  type="primary">{{ $t('quick_create') }}</a-button>
                </a-col>
              </a-row>
               <div style="cursor: pointer" v-else  @click="showCare = true">
                 <a-tag  :color="getCareName(customerForm.last_care).color">{{getCareName(customerForm.last_care).name}}</a-tag>

               </div>
             </a-form-item>
             <a-form-item
                 class="info-customer"
                 :label="$t('CUSTOMER_CARE_STAFF')"
                 name="conversation_staff"
             >
               <a-select
                   v-if="showCareStaff"
                  style="width: 100%"
                  placeholder="Please select"
                  allowClear
                  @change="handleChangeCareStaff"
                  v-model:value="customerForm.conversation_staff">
                       <a-select-option
                         v-for="item in employees.data"

                         :key="item.cms_user_id"
                          v-bind:value="item.cms_user_id+''"

                                     >{{ item.name }}
                       </a-select-option>


               </a-select>

               <a-typography-text style="cursor: pointer" v-else @click="showCareStaff = true">{{getEmployee(customerForm.conversation_staff)}}<down-outlined style="margin-left: 10px" /></a-typography-text>
             </a-form-item>
             <a-form-item class="info-customer" :label="$t('TAG')" name="tag">
                <a-row v-if="showTagSale">
                  <a-col :span="17">
                    <a-tree-select
                        @change="handleChangeTag"
                        show-search
                        style="width: 100%"
                        @search="searchTag"
                        v-model:value="tagIds"
                        :filterTreeNode="false"
                        v-model:searchValue="searchTagValue"
                        :notFoundContent="null"
                        :dropdown-style="{ maxHeight: '300', overflow: 'auto' }"
                        allow-clear
                        multiple
                        :placeholder="$t('TAG_SELECT')"
                    >
                      <a-tree-select-node v-for="item in treeTags" :key="item.id" v-bind:value="item.id">
                        <template #title>
                          <a-tag @click="tagCheck = item.id + '' " :color="item.color">
                            {{ item.name }}
                          </a-tag>
                        </template>
                        <a-tree-select-node v-for="child in item.children" :key="child.id" v-bind:value="child.id">
                          <template #title>
                            <a-tag @click="tagCheck = child.id + '' "  :color="child.color">
                              {{ child.name }}
                            </a-tag>
                          </template>
                        </a-tree-select-node>

                      </a-tree-select-node>

                    </a-tree-select>
                  </a-col>
                  <a-col :span="1">

                  </a-col>
                  <a-col :span="6">
                    <a-button size="small" @click="handleQuickCreate('customer')"  type="primary">{{ $t('quick_create') }}</a-button>
                  </a-col>
                </a-row>
               <div style="cursor: pointer" v-else  @click="showTagSale = true">
                 <a-tag v-for="i in getTagSale(tagIds)" :key="i.id"  :color="i.color">{{i.name}}</a-tag>
                 <a-button v-if="tagIds.length === 0" size="small" @click="handleQuickCreate('customer')"  type="primary">{{ $t('ADD') }}</a-button>

               </div>
             </a-form-item>
           </a-col>
         </a-row>
         <a-row>
           <a-typography-text style="cursor: pointer;color: rgb(250, 178, 22)" @click="handleConfig" >{{$t('EXTRA_COLUMN')}}</a-typography-text>
           <caret-down-outlined style="color: rgb(250, 178, 22)" v-if="showConfig"  />
           <caret-up-outlined  style="color: rgb(250, 178, 22)" v-else />
           <a-button @click="handleCustomerConfig('add',undefined)" style="margin-left: 10px"  v-if="showConfig" >{{$t('EDIT')}}</a-button>
         </a-row>


        </a-form>
            </a-card>
          </a-tab-pane>
          <a-tab-pane key="3" :tab="$t('CONTACT_INFO')" force-render>
            <a-card style="width: 100%" class="card-customer"
                    :title="$t('CONTACT_INFO')">
              <template #extra>
                <delete-outlined @click="onDeleteContact"/>
              </template>
              <a-form
                  :model="contactForm"
                  ref="contactFormRef"
                  layout="inline"
                  labelAlign="left"
                  :labelCol="{span: 12}"
              >

                <a-form-item style="margin-bottom: 10px;width: 267px" :label="$t('PREFIX')" name="prefix">
                  <a-input @blur="() => {(contactForm.full_name && contactForm.phone) && onSubmit()}"  :placeholder="$t('PREFIX')"  v-model:value="contactForm.prefix"/>
                </a-form-item>
                <a-form-item style="margin-bottom: 10px;width: 267px" :label="$t('FULL_NAME')" name="full_name">
                  <a-input @blur="() => {(contactForm.full_name && contactForm.phone) && onSubmit()}" :placeholder="$t('FULL_NAME')"  v-model:value="contactForm.full_name"/>
                </a-form-item>
                <a-form-item style="margin-bottom: 10px;width: 267px" :label="$t('POSITION')" name="position">
                  <a-input @blur="() => {(contactForm.full_name && contactForm.phone) && onSubmit()}"  :placeholder="$t('POSITION')"  v-model:value="contactForm.position"/>
                </a-form-item>
                <a-form-item style="margin-bottom: 10px;width: 267px" :label="$t('PHONE')" name="phone">
                  <a-input @blur="() => {(contactForm.full_name && contactForm.phone) && onSubmit()}"  :placeholder="$t('PHONE')"  v-model:value="contactForm.phone"/>
                </a-form-item>
                <a-form-item style="margin-bottom: 10px;width: 267px" :label="$t('BIRTHDAY')" name="birthday">
                  <a-date-picker
                      style="width: 187px"
                      v-model:value="contactForm.birthday"
                      :placeholder="$t('CUSTOMER_BIRTHDAY')"
                      format="DD-MM-YYYY"
                      @change="() => {(contactForm.full_name && contactForm.phone) && onSubmit()}"
                  />
                </a-form-item>

                <a-form-item style="margin-bottom: 10px;width: 267px" :label="$t('GENDER')" name="gender">
                  <a-radio-group @change="() => {(contactForm.full_name && contactForm.phone) && onSubmit()}"  style="width: 187px" name="radioGroup" v-model:value="contactForm.gender">
                    <a-radio :value="1">{{ $t('MALE') }}</a-radio>
                    <a-radio :value="2">{{ $t('FEMALE') }}</a-radio>
                  </a-radio-group>
                </a-form-item>
                <a-form-item  style="margin-bottom: 10px;width: 551px" :label="$t('NOTE')" name="note">
                  <a-textarea
                      v-model:value="contactForm.note"
                      :placeholder="$t('NOTE')"
                      :rows="3"
                      @blur="() => {(contactForm.full_name && contactForm.phone) && onSubmit()}"
                  />
                </a-form-item>
              </a-form>
            </a-card>
          </a-tab-pane>
        </a-tabs>
      </a-card>

      <a-card style="width: 100%">
        <a-form labelAlign="left" :label-col="{ span: 10 }"
                :wrapper-col="{ span: 14 }" v-if="CustomerConfig && showConfig" layout="inline">
          <div v-for="i in listConfig " :key="i.id" >
            <a-form-item v-if="i.identified_name !== 'rank' && i.identified_name !== 'points'"    class="info-customer">
              <template #label>
                <a-typography-text  style="width: 300px;display:block;cursor: pointer" @click="showCustomerConfigValue = i.id" >{{i.name}}</a-typography-text>
              </template>
              <div v-if="showCustomerConfigValue === i.id">
                <a-input style="width: 250px;" v-if="i.field_type !== 'DATE'"  @blur="updateCustomerConfig(i)"  v-model:value="i.customer_column_value" :placeholder="$t('CUSTOMER_CONFIG_NAME')"/>
                <a-date-picker show-time v-else style="width: 250px;"  @blur="updateCustomerConfig(i,'schedule')"  v-model:value="i.customer_column_value" format="DD/MM/YYYY HH:mm" valueFormat="DD/MM/YYYY HH:mm" :placeholder="$t('CUSTOMER_CONFIG_NAME')"/>
                <!--                 <a-button @click="onSubmit"  type="primary">{{ $t('ADD') }}</a-button>-->


              </div>

              <div v-else >
                <div v-if="i.customer_column_value ">
                  <a-typography-text v-if="i.field_type === 'CURRENCY'" style="display:block;width: 250px;cursor: pointer" @click="showCustomerConfigValue = i.id"    >{{i.customer_column_value }} VND </a-typography-text>
                  <a-typography-text v-else style="display:block;width: 250px;cursor: pointer" @click="showCustomerConfigValue = i.id"    >{{i.customer_column_value }} </a-typography-text>
                </div>
                <a-typography-text v-else style="display:block;width: 250px;cursor: pointer" @click="showCustomerConfigValue = i.id"    >{{$t('no_customer_information') }} </a-typography-text>


                <!--                  <a-typography-text  v-else style="cursor: pointer;color: #FAB216"  @click="handleCustomerConfigName"   >{{$t('no_customer_information') }} </a-typography-text>-->

              </div>
            </a-form-item>
          </div>


        </a-form>
      </a-card>
      <a-row style="width: 100%">
        <a-col :span="16">
      <a-card  >
        <a-tabs v-model:activeKey="activeKey">

          <a-tab-pane key="1" :tab="$t('ORDERS')">
            <a-button size="small" style="margin: 10px" target="_blank"  @click="createOrder(customerForm.id)" >
              <sisternode-outlined/>
              {{ $t("ORDER_UP") }}
            </a-button>
            <a-table
                class="table"

                :data-source="orders"


                :columns="columns"

                :rowKey="(record) => record.id"

            >
              <template #order_number="{record}">
                <div class="order-id-item">
                  <router-link :to="'/order/'+record.order_number">
                    <a type="text" class="order-id-text-button order-id-text-bold">
                      {{ record.order_number }}
                    </a>
                  </router-link>
                  <!--          <router-link :to="'/shipping/'+record.shipping_code">-->

                  <!--          </router-link>-->
                </div>
              </template>
              <template #total="{record}">
                <div class="order-id-item">
                <span type="text" class="order-id-text-button order-id-text-bold">
                  {{ formatMoney(record.total) }}
                </span>
                </div>
              </template>
              <template #status="{ record }">
                <span class="table-status-item">
                          <a-tag :color="getOrderStatusColor(record.status)">{{ convertOrderStatus(record.status) }}</a-tag>
                      </span>
                <div>
                  {{ moment(record.status_date).format('DD/MM/YYYY HH:mm') }}
                </div>
              </template>
      <template #buy_at="{record}">
        <span class="time-comment">{{ dateTimeDisplayFormatFromString(record.created_at) }}</span>
      </template>
            </a-table>
          </a-tab-pane>
          <a-tab-pane key="2" :tab="$t('NOTE')" force-render>
      <!--      <a-table-->
      <!--        class="table"-->

      <!--        :data-source="call"-->


      <!--        :columns="columnsCall"-->

      <!--        :rowKey="(record) => record.id"-->

      <!--    >-->

      <!--    </a-table>-->
            <a-card  >
              <template #title>
                <form-outlined />
                {{$t('NOTE')}}
              </template>
              <a-list v-if="customerForm.social_type !== 'landingPage'" item-layout="horizontal" :data-source="getNote(customerForm.note)">
                <template #renderItem="{ item }">
                  <a-list-item>
                    <a-list-item-meta
                        :description="item"
                    >
<!--                      <template #title>-->
<!--                        <a-typography-text>{{ item.created_by }}</a-typography-text>-->
<!--                        {{dateTimeDisplayFormatFromString(item.created_at)}}-->
<!--                      </template>-->
                      <!--                    <template #avatar>-->
                      <!--                      <a-avatar src="https://joeschmoe.io/api/v1/random" />-->
                      <!--                    </template>-->
                    </a-list-item-meta>

                  </a-list-item>
                </template>
              </a-list>
              <p v-else>{{customerForm.note}}</p>

      <!--        <a-textarea-->
      <!--            :placeholder="$t('NOTE')"-->
      <!--            :rows="3"-->
      <!--            @change="handleNote"-->
      <!--        />-->
      <!--        <a-button @click="addNote" style="margin-top: 10px" type="primary">{{ $t('ADD') }}</a-button>-->
            </a-card>
          </a-tab-pane>

          <a-tab-pane key="3" :tab="$t('HISTORY_USE_POINT')" force-render>
            <a-table
                class="table"

                :data-source="usePoint"


                :columns="columns2"

                :rowKey="(record) => record.id"

            >
              <template #order_number="{record}">
                <div class="order-id-item">
                  <router-link :to="'/order/'+record.order_number">
                    <a type="text" class="order-id-text-button order-id-text-bold">
                      {{ record.order_number }}
                    </a>
                  </router-link>
                  <!--          <router-link :to="'/shipping/'+record.shipping_code">-->

                  <!--          </router-link>-->
                </div>
              </template>
              <template #point="{record}">
                <div class="order-id-item">
                <span type="text" class="order-id-text-button order-id-text-bold">
                  {{ formatMoney(record.point) }}
                </span>
                </div>
              </template>
              <template #status="{ record }">
                <span class="table-status-item">
                          <a-tag :color="getOrderUsePointColor(record.status)">{{ convertUsePointStatus(record.status) }}</a-tag>
                      </span>
                <div>
                  {{ moment(record.created_at).format('DD/MM/YYYY HH:mm') }}
                </div>
              </template>
<!--              <template #buy_at="{record}">-->
<!--                <span class="time-comment">{{ dateTimeDisplayFormatFromString(record.created_at) }}</span>-->
<!--              </template>-->
            </a-table>
          </a-tab-pane>

        </a-tabs>
      </a-card>

        </a-col>
        <a-col :span="8">
          <a-card style="height: 500px;overflow: scroll;" size="small">
            <template #title>
              <a-tag color="#87d068">{{ commentHistory.length }}</a-tag>
              {{$t('ADD_NOTE')}}
            </template>
            <template #extra>
              <a-popover title="Add your comment" trigger="click" v-model:visible="addCommentVisible"
                         v-if="$route.params.id&&$route.params.id!==':id'">
                <template #content>
                  <a-form
                      :rules="rulesFormNote"
                      :model="commentForm"
                      :label-col="{ span: 8 }"
                      :wrapper-col="{ span: 16 }"
                  >
                    <a-form-item :label="$t('TAG')" name="tagIds">
                      <a-tree-select
                          show-search
                          style="width: 100%"
                          v-model:value="commentForm.tagId"
                          :filterTreeNode="false"
                          v-model:searchValue="searchTagValue"
                          :notFoundContent="null"
                          :dropdown-style="{ maxHeight: '300', overflow: 'auto' }"
                          allow-clear
                          :placeholder="$t('TAG_SELECT')"
                      >
                        <a-tree-select-node v-for="item in treeTags" :key="item.id" v-bind:value="item.id">
                          <template #title>
                            <a-tag :color="item.color">
                              {{ item.name }}
                            </a-tag>
                          </template>
                          <a-tree-select-node v-for="child in item.children" :key="child.id" v-bind:value="child.id">
                            <template #title>
                              <a-tag :color="child.color">
                                {{ child.name }}
                              </a-tag>
                            </template>
                          </a-tree-select-node>

                        </a-tree-select-node>

                      </a-tree-select>
                    </a-form-item>
                    <a-form-item :label="$t('comment')" name="comment">
                      <a-textarea
                          v-model:value="commentForm.comment"
                          :placeholder="$t('INPUT_YOUR_COMMENT')"
                          :auto-size="{ minRows: 2, maxRows: 5 }"
                      />
                    </a-form-item>
                    <a-alert
                        v-if="showWarningAlertAddComment"
                        message="Fill at least one field"
                        type="warning"
                        closable
                        @close="closeWarningAlertAddComment"
                    />
                    <a-form-item :wrapper-col="{span:16,offset:8}">
                      <a-button type="primary" @click="addComment" :loading="buttonSubmitCommentLoading">Submit</a-button>
                    </a-form-item>
                  </a-form>
                </template>
                <a-button type="dashed">
                  <plus-outlined/>
                  Add comment
                </a-button>
              </a-popover>

            </template>
            <a-timeline mode="left" class="timeline-history-comment" reverse v-if="commentHistory&&commentHistory.length">
              <a-timeline-item v-for="comment in commentHistory" :key="comment.id">
                <a-row style="align-items: center;justify-content: space-between">
                  <div>
                    <div><span><user-outlined/> <span style="margin-right: 10px"
                                                      class="user-comment">{{ getOneEmployeeById(comment.creator).name }}</span>
                    <span class="time-comment">{{ dateTimeDisplayFormatFromString(comment.created_at) }}</span></span>
                    </div>
                    <div v-if="comment.tag_ids">
                      <tag-outlined/> <span class="type-tag">
                        {{ $t(comment.content) }}
                      </span>
                      <span  v-for="tagId in comment.tag_ids" :key="tagId">

                    <a-tag
                        :color="getOneByCustomerTagId(tagId).color">{{
                        getOneByCustomerTagId(tagId).name
                      }}</a-tag>
                    </span>
                    </div>

                    <div v-if="comment.type === 'change_last_care'">
                    <span >
                      <tag-outlined/> <span class="type-tag">
                        {{ $t(comment.type) }}
                      </span>
                      <a-tag
                          :color="getOneByCustomerLastCare(comment.last_care_id).color">{{
                          getOneByCustomerLastCare(comment.last_care_id).name
                        }}</a-tag>
                    </span>
                    </div>
                    <div v-if="comment.type==='schedule'">
                <span>
                      <schedule-outlined/>
                  <span  style="margin-left: 10px;margin-right: 10px" class="comment-reminder">{{ $t('CUSTOMER_SCHEDULE_AT') }}</span>
                  <a-tag color="cyan">{{ dateTimeDisplayFormatFromString(comment.schedule_time) }}</a-tag>
                    </span>
                    </div>
                    <div v-if="comment.content && !comment.tag_ids">

                      <div v-if="getCoversationTag(comment.content).type === 'COVERSATION_TAG' ">
                        <message-outlined/> {{ `${$t(getCoversationTag(comment.content).type)}:` }}
                        <span v-for="tag in getCoversationTag(comment.content).tag" :key="tag">
                       <a-tag v-if="getConversationTagById(tag).tag_id" :color="getConversationTagById(tag).tag_color">
                    {{ getConversationTagById(tag).tag_name }}
                  </a-tag>
                    </span>

                      </div>
                      <span v-else>
                      <message-outlined/> {{ comment.content }}
                    </span>
                    </div>
                  </div>
                  <DeleteOutlined @click="() =>delteteComment(comment)"/>
                </a-row>

              </a-timeline-item>

            </a-timeline>
            <a-empty v-else/>
          </a-card>

<!--          <a-card  >-->
<!--            <template #title>-->
<!--              <a-row>-->
<!--                <a-col :span="20">-->
<!--                  <environment-outlined />-->
<!--                  {{$t('CUSTOMER_ADDRESS')}}-->
<!--                </a-col>-->
<!--                <a-col :span="44">-->
<!--                  <a-button @click="showEditAddress = true" type="dashed">{{$t('EDIT')}}</a-button>-->
<!--                </a-col>-->
<!--              </a-row>-->
<!--            </template>-->
<!--            <a-typography-text><home-outlined />  {{customerForm.address}},{{customerForm.ward_name}},{{customerForm.district_name}},{{customerForm.province_name}}</a-typography-text>-->



<!--          </a-card>-->
        </a-col>
      </a-row>
    </div>
    <a-modal v-model:visible="showEditAddress" :title="$t('EDIT')" @ok="onSubmit">
   <a-form>
     <a-form-item :label="$t('CITY')" name="province_id">
       <a-select
           style="width: 100%"
           placeholder="Please select"
           show-search
           v-model:value="customerForm.province_id"
           :loading="!provinceLoaded"
           :options="provinces"
           :filter-option="false"
           :not-found-content="null"
           @search="provinceSearch"
           @change="provinceChange(customerForm.province_id)"
       >
       </a-select>
     </a-form-item>


     <a-form-item :label="$t('DISTRICT')" name="district_id">
       <a-select
           style="width: 100%"
           placeholder="Please select"
           v-model:value="customerForm.district_id"
           show-search
           :loading="!districtLoaded"
           :options="districts"
           :filter-option="false"
           :not-found-content="null"
           @search="districtSearch"
           @change="districtChange()"
       >
       </a-select>
     </a-form-item>

     <a-form-item :label="$t('WARD')" name="ward_id">
       <a-select
           style="width: 100%"
           placeholder="Please select"
           show-search
           :options="wards"
           :loading="!wardLoaded"
           :filter-option="false"
           :not-found-content="null"
           @search="wardSearch"
           v-model:value="customerForm.ward_id"
       >
       </a-select>
     </a-form-item>
     <a-form-item :label="$t('CUSTOMER_ADDRESS')" name="address">
       <a-textarea
           v-model:value="customerForm.address"
           :placeholder="$t('CUSTOMER_ADDRESS')"
           :rows="3"
       />
     </a-form-item>
   </a-form>
  </a-modal>

<!--    <div class="container">-->
<!--      <a-card class="card-customer"-->
<!--              :title="$t('CUSTOMER_INFO')">-->
<!--        &lt;!&ndash;              :title="$route.params.id&&$route.params.id!==':id'?$t('UPDATE_CUSTOMER'):$t('CREATE_CUSTOMER')">&ndash;&gt;-->

<!--        <a-form-->
<!--            :model="customerForm"-->
<!--            ref="formRef"-->
<!--            :rules="rules"-->
<!--            layout="vertical"-->
<!--        >-->
<!--          <div class="inline-form-customer">-->
<!--            <a-form-item :label="$t('CUSTOMER_NAME')" name="full_name">-->
<!--              <a-input-->
<!--                  v-model:value="customerForm.full_name"-->
<!--                  :placeholder="$t('CUSTOMER_NAME')"-->
<!--              />-->
<!--            </a-form-item>-->

<!--            <a-form-item :label="$t('CUSTOMER_PHONE')" name="phone">-->
<!--              <a-input-->
<!--                  v-model:value="customerForm.phone"-->
<!--                  :placeholder="$t('CUSTOMER_PHONE')"-->
<!--              />-->
<!--            </a-form-item>-->

<!--          </div>-->
<!--          <div class="inline-form-customer">-->
<!--            <a-form-item :label="$t('ALL_PHONE _NUMBERS')" name="all_phones">-->
<!--              <a-select-->
<!--                  style="width: 100%"-->
<!--                  :placeholder="$t('ALL_PHONE _NUMBERS')"-->
<!--                  mode="tags"-->
<!--                  v-model:value="allPhones"-->
<!--              ></a-select>-->
<!--            </a-form-item>-->

<!--            <div class="inline-form-customer">-->
<!--              <a-form-item label="Email" name="email">-->
<!--                <a-input v-model:value="customerForm.email" placeholder="Email"/>-->
<!--              </a-form-item>-->
<!--            </div>-->



<!--            <a-form-item :label="$t('CUSTOMER_BIRTHDAY')" name="birth">-->
<!--              <a-date-picker-->
<!--                  v-model:value="customerForm.birth"-->
<!--                  :placeholder="$t('CUSTOMER_BIRTHDAY')"-->
<!--                  format="DD-MM-YYYY"-->
<!--              />-->
<!--            </a-form-item>-->

<!--            <a-form-item :label="$t('SEX')" name="sex">-->
<!--              <a-radio-group name="radioGroup" v-model:value="customerForm.sex">-->
<!--                <a-radio value="male">{{ $t('MALE') }}</a-radio>-->
<!--                <a-radio value="female">{{ $t('FEMALE') }}</a-radio>-->
<!--                <a-radio value="other">{{ $t('OTHER') }}</a-radio>-->
<!--              </a-radio-group>-->
<!--            </a-form-item>-->
<!--            <a-form-item label="Email" name="email">-->
<!--              <a-input v-model:value="customerForm.email" placeholder="Email"/>-->
<!--            </a-form-item>-->
<!--            <a-form-item :label="$t('CITY')" name="province_id">-->
<!--              <a-select-->
<!--                  style="width: 100%"-->
<!--                  placeholder="Please select"-->
<!--                  show-search-->
<!--                  v-model:value="customerForm.province_id"-->
<!--                  :loading="!provinceLoaded"-->
<!--                  :options="provinces"-->
<!--                  :filter-option="false"-->
<!--                  :not-found-content="null"-->
<!--                  @search="provinceSearch"-->
<!--                  @change="provinceChange(customerForm.province_id)"-->
<!--              >-->
<!--              </a-select>-->
<!--            </a-form-item>-->


<!--            <a-form-item :label="$t('DISTRICT')" name="district_id">-->
<!--              <a-select-->
<!--                  style="width: 100%"-->
<!--                  placeholder="Please select"-->
<!--                  v-model:value="customerForm.district_id"-->
<!--                  show-search-->
<!--                  :loading="!districtLoaded"-->
<!--                  :options="districts"-->
<!--                  :filter-option="false"-->
<!--                  :not-found-content="null"-->
<!--                  @search="districtSearch"-->
<!--                  @change="districtChange()"-->
<!--              >-->
<!--              </a-select>-->
<!--            </a-form-item>-->

<!--            <a-form-item :label="$t('WARD')" name="ward_id">-->
<!--              <a-select-->
<!--                  style="width: 100%"-->
<!--                  placeholder="Please select"-->
<!--                  show-search-->
<!--                  :options="wards"-->
<!--                  :loading="!wardLoaded"-->
<!--                  :filter-option="false"-->
<!--                  :not-found-content="null"-->
<!--                  @search="wardSearch"-->
<!--                  v-model:value="customerForm.ward_id"-->
<!--              >-->
<!--              </a-select>-->
<!--            </a-form-item>-->
<!--            <a-form-item :label="$t('CUSTOMER_ADDRESS')" name="address">-->
<!--              <a-textarea-->
<!--                  v-model:value="customerForm.address"-->
<!--                  :placeholder="$t('CUSTOMER_ADDRESS')"-->
<!--                  :rows="3"-->
<!--              />-->
<!--            </a-form-item>-->
<!--            <a-form-item :label="$t('NOTE')" name="note">-->
<!--              <a-textarea-->
<!--                  :value="getNote(customerForm.note)"-->
<!--                  :placeholder="$t('NOTE')"-->
<!--                  :rows="3"-->
<!--              />-->
<!--            </a-form-item>-->

<!--            <a-form-item :label="$t('DATE_CREATED')">-->
<!--              <a-date-picker-->
<!--                  v-model:value="customerForm.created_at"-->
<!--                  fomat="DD-MM-YYYY"-->
<!--                  disabled="true"-->
<!--              />-->
<!--            </a-form-item>-->
<!--          </div>-->
<!--        </a-form>-->
<!--      </a-card>-->
<!--      <a-card class="card-customer"-->
<!--              :title="$t('CUSTOMER_STATUS_CARE')">-->
<!--        <a-form-->
<!--            class="inline-form-customer"-->
<!--            layout="vertical"-->
<!--        >-->

<!--          <a-form-item :label="$t('FROM')">-->
<!--            <a-input :placeholder="$t('FROM')" :value="customerForm.social_type" disabled/>-->
<!--          </a-form-item>-->


<!--          <a-form-item :label="$t('PAGE_ID')" name="page_id">-->
<!--            <a-select-->
<!--                style="width: 100%"-->
<!--                disabled-->
<!--                placeholder="Please select"-->
<!--                v-model:value="customerForm.page_id"-->
<!--            >-->
<!--              <a-select-option-->
<!--                  v-for="item in pages"-->
<!--                  :key="item.id"-->
<!--                  v-bind:value="item.page_id"-->
<!--              >{{ item.page_name }}-->
<!--              </a-select-option>-->
<!--            </a-select>-->
<!--          </a-form-item>-->

<!--          <a-form-item :label="$t('CARE_STATUS')" name="last_care">-->
<!--            <a-select-->
<!--                style="width: 100%"-->
<!--                allowClear-->
<!--                placeholder="Please select"-->
<!--                v-model:value="customerForm.last_care"-->
<!--            >-->
<!--              <a-select-option-->
<!--                  v-for="item in customerCares"-->
<!--                  :key="item.id"-->
<!--                  v-bind:value="item.id"-->

<!--              >-->
<!--                <a-tag :color="item.color">-->
<!--                  {{ item.name }}-->
<!--                </a-tag>-->
<!--              </a-select-option>-->
<!--            </a-select>-->
<!--          </a-form-item>-->

<!--          <a-form-item label="Conversation tag" name="conversation_tags">-->
<!--            <template v-for="tag in splitString(customerForm.conversation_tags)" v-bind:key="tag">-->
<!--              <a-tag :color="getConversationTagById(tag).tag_color">-->
<!--                {{ getConversationTagById(tag).tag_name }}-->
<!--              </a-tag>-->
<!--            </template>-->
<!--          </a-form-item>-->

<!--          <a-config-provider :renderEmpty="null">-->
<!--            <a-form-item :label="$t('TAG')" name="tag">-->
<!--              <a-tree-select-->
<!--                  @change="handleChangeeTag"-->
<!--                  show-search-->
<!--                  style="width: 100%"-->
<!--                  @search="searchTag"-->
<!--                  v-model:value="tagIds"-->
<!--                  :filterTreeNode="false"-->
<!--                  v-model:searchValue="searchTagValue"-->
<!--                  :notFoundContent="null"-->
<!--                  :dropdown-style="{ maxHeight: '300', overflow: 'auto' }"-->
<!--                  allow-clear-->
<!--                  multiple-->
<!--                  :placeholder="$t('TAG_SELECT')"-->
<!--              >-->
<!--                <a-tree-select-node v-for="item in treeTags" :key="item.id" v-bind:value="item.id">-->
<!--                  <template #title>-->
<!--                    <a-tag :color="item.color">-->
<!--                      {{ item.name }}-->
<!--                    </a-tag>-->
<!--                  </template>-->
<!--                  <a-tree-select-node v-for="child in item.children" :key="child.id" v-bind:value="child.id">-->
<!--                    <template #title>-->
<!--                      <a-tag :color="child.color">-->
<!--                        {{ child.name }}-->
<!--                      </a-tag>-->
<!--                    </template>-->
<!--                  </a-tree-select-node>-->

<!--                </a-tree-select-node>-->
<!--              </a-tree-select>-->
<!--            </a-form-item>-->
<!--          </a-config-provider>-->

<!--          <a-form-item-->
<!--              :label="$t('CUSTOMER_CARE_STAFF')"-->
<!--              name="conversation_staff"-->
<!--          >-->
<!--            <a-select-->
<!--                style="width: 100%"-->
<!--                placeholder="Please select"-->
<!--                allowClear-->
<!--                v-model:value="customerForm.selectedConversationStaff"-->
<!--            >-->
<!--              <a-select-option-->
<!--                  v-for="item in employees"-->
<!--                  :key="item.id"-->
<!--                  v-bind:value="item.id+''"-->
<!--              >{{ item.name }}-->
<!--              </a-select-option>-->
<!--            </a-select>-->
<!--          </a-form-item>-->


<!--          <a-form-item :label="$t('SALE_STAFF')" name="employees">-->
<!--            <a-select-->
<!--                style="width: 100%"-->
<!--                placeholder="Please select"-->
<!--                allowClear-->
<!--                v-model:value="customerForm.employees"-->
<!--            >-->
<!--              <a-select-option-->
<!--                  v-for="item in employees"-->
<!--                  :key="item.id"-->
<!--                  v-bind:value="item.id+''"-->
<!--              >{{ item.name }}-->
<!--              </a-select-option>-->
<!--            </a-select>-->
<!--          </a-form-item>-->
<!--          <a-form-item :label="$t('CAREER')" name="career">-->
<!--            <a-select-->
<!--                style="width: 100%"-->
<!--                placeholder="Please select"-->
<!--                allowClear-->
<!--                v-model:value="customerForm.career"-->
<!--            >-->
<!--              <a-select-option-->
<!--                  v-for="item in careers"-->
<!--                  :key="item.id"-->
<!--                  v-bind:value="item.id"-->
<!--              >{{ item.name }}-->
<!--              </a-select-option>-->
<!--            </a-select>-->
<!--          </a-form-item>-->
<!--        </a-form>-->
<!--      </a-card>-->
<!--      <a-card class="card-customer"-->
<!--              :title="$t('CONTACT_INFO')">-->
<!--        <template #extra>-->
<!--          <delete-outlined @click="onDeleteContact"/>-->
<!--        </template>-->
<!--        <a-form-->
<!--            :model="contactForm"-->
<!--            ref="contactFormRef"-->
<!--            class="inline-form-customer"-->
<!--            layout="vertical"-->
<!--        >-->

<!--          <a-form-item :label="$t('PREFIX')" name="prefix">-->
<!--            <a-input :placeholder="$t('PREFIX')"  v-model:value="contactForm.prefix"/>-->
<!--          </a-form-item>-->
<!--          <a-form-item :label="$t('FULL_NAME')" name="full_name">-->
<!--            <a-input :placeholder="$t('FULL_NAME')"  v-model:value="contactForm.full_name"/>-->
<!--          </a-form-item>-->
<!--          <a-form-item :label="$t('POSITION')" name="position">-->
<!--            <a-input :placeholder="$t('POSITION')"  v-model:value="contactForm.position"/>-->
<!--          </a-form-item>-->
<!--          <a-form-item :label="$t('PHONE')" name="phone">-->
<!--            <a-input :placeholder="$t('PHONE')"  v-model:value="contactForm.phone"/>-->
<!--          </a-form-item>-->
<!--          <a-form-item :label="$t('BIRTHDAY')" name="birthday">-->
<!--            <a-date-picker-->
<!--                style="width: 300px"-->
<!--                v-model:value="contactForm.birthday"-->
<!--                :placeholder="$t('CUSTOMER_BIRTHDAY')"-->
<!--                format="DD-MM-YYYY"-->
<!--            />-->
<!--          </a-form-item>-->
<!--          <a-form-item :label="$t('GENDER')" name="gender">-->
<!--            <a-radio-group name="radioGroup" v-model:value="contactForm.gender">-->
<!--              <a-radio :value="1">{{ $t('MALE') }}</a-radio>-->
<!--              <a-radio :value="2">{{ $t('FEMALE') }}</a-radio>-->
<!--            </a-radio-group>-->
<!--          </a-form-item>-->
<!--          <a-form-item :label="$t('NOTE')" name="note">-->
<!--            <a-textarea-->
<!--                v-model:value="contactForm.note"-->
<!--                :placeholder="$t('NOTE')"-->
<!--                :rows="3"-->
<!--            />-->
<!--          </a-form-item>-->
<!--        </a-form>-->
<!--      </a-card>-->

<!--      <div class="history-comment">-->
<!--        <div class="header-comment"><h3>Customer activity</h3></div>-->
<!--        <a-card class="card-history-comment" size="small">-->
<!--          <template #title>-->
<!--            <a-tag color="#87d068">{{ commentHistory.length }}</a-tag>-->
<!--            comments-->
<!--          </template>-->
<!--          <template #extra>-->
<!--            <a-popover title="Add your comment" trigger="click" v-model:visible="addCommentVisible"-->
<!--                       v-if="$route.params.id&&$route.params.id!==':id'">-->
<!--              <template #content>-->
<!--                <a-form-->
<!--                    :rules="rulesFormNote"-->
<!--                    :model="commentForm"-->
<!--                    :label-col="{ span: 8 }"-->
<!--                    :wrapper-col="{ span: 16 }"-->
<!--                >-->
<!--                  <a-form-item :label="$t('TAG')" name="tagIds">-->
<!--                    <a-tree-select-->
<!--                        show-search-->
<!--                        style="width: 100%"-->
<!--                        v-model:value="commentForm.tagId"-->
<!--                        :filterTreeNode="false"-->
<!--                        v-model:searchValue="searchTagValue"-->
<!--                        :notFoundContent="null"-->
<!--                        :dropdown-style="{ maxHeight: '300', overflow: 'auto' }"-->
<!--                        allow-clear-->
<!--                        :placeholder="$t('TAG_SELECT')"-->
<!--                    >-->
<!--                      <a-tree-select-node v-for="item in treeTags" :key="item.id" v-bind:value="item.id">-->
<!--                        <template #title>-->
<!--                          <a-tag :color="item.color">-->
<!--                            {{ item.name }}-->
<!--                          </a-tag>-->
<!--                        </template>-->
<!--                        <a-tree-select-node v-for="child in item.children" :key="child.id" v-bind:value="child.id">-->
<!--                          <template #title>-->
<!--                            <a-tag :color="child.color">-->
<!--                              {{ child.name }}-->
<!--                            </a-tag>-->
<!--                          </template>-->
<!--                        </a-tree-select-node>-->

<!--                      </a-tree-select-node>-->
<!--                    </a-tree-select>-->
<!--                  </a-form-item>-->
<!--                  <a-form-item :label="$t('comment')" name="comment">-->
<!--                    <a-textarea-->
<!--                        v-model:value="commentForm.comment"-->
<!--                        :placeholder="$t('INPUT_YOUR_COMMENT')"-->
<!--                        :auto-size="{ minRows: 2, maxRows: 5 }"-->
<!--                    />-->
<!--                  </a-form-item>-->
<!--                  <a-alert-->
<!--                      v-if="showWarningAlertAddComment"-->
<!--                      message="Fill at least one field"-->
<!--                      type="warning"-->
<!--                      closable-->
<!--                      @close="closeWarningAlertAddComment"-->
<!--                  />-->
<!--                  <a-form-item :wrapper-col="{span:16,offset:8}">-->
<!--                    <a-button type="primary" @click="addComment" :loading="buttonSubmitCommentLoading">Submit</a-button>-->
<!--                  </a-form-item>-->
<!--                </a-form>-->
<!--              </template>-->
<!--              <a-button type="dashed">-->
<!--                <plus-outlined/>-->
<!--                Add comment-->
<!--              </a-button>-->
<!--            </a-popover>-->

<!--          </template>-->
<!--          <a-timeline mode="left" class="timeline-history-comment" reverse v-if="commentHistory&&commentHistory.length">-->
<!--            <a-timeline-item v-for="comment in commentHistory" :key="comment.id">-->
<!--              <div><span><user-outlined/> <span-->
<!--                  class="user-comment">{{ getOneEmployeeById(comment.creator).name }}</span>-->
<!--                    <span class="time-comment">{{ dateTimeDisplayFormatFromString(comment.created_at) }}</span></span>-->
<!--              </div>-->
<!--              <div v-if="comment.tag_ids">-->
<!--                    <span v-for="tagId in comment.tag_ids" :key="tagId">-->
<!--                      <tag-outlined/> <span class="type-tag">-->
<!--                        {{ $t(comment.type) }}-->
<!--                      </span><a-tag-->
<!--                        :color="getOneByCustomerTagId(tagId).color">{{-->
<!--                        getOneByCustomerTagId(tagId).name-->
<!--                      }}</a-tag>-->
<!--                    </span>-->
<!--              </div>-->
<!--              <div v-if="comment.type==='schedule'">-->
<!--                <span>-->
<!--                      <schedule-outlined/>-->
<!--                  <span class="comment-reminder">{{ $t('CUSTOMER_SCHEDULE_AT') }}</span>-->
<!--                  <a-tag color="cyan">{{ dateTimeDisplayFormatFromString(comment.schedule_time) }}</a-tag>-->
<!--                    </span>-->
<!--              </div>-->
<!--              <div v-if="comment.content">-->
<!--                    <span>-->
<!--                      <message-outlined/> {{ comment.content }}-->
<!--                    </span>-->
<!--              </div>-->
<!--            </a-timeline-item>-->
<!--          </a-timeline>-->
<!--          <a-empty v-else/>-->
<!--        </a-card>-->

<!--        <div class="header-order"><h3>Orders</h3></div>-->
<!--        <a-card class="card-history-comment" size="small">-->
<!--          <template #title>-->
<!--            <a-tag color="#87d068">{{ orders.length }}</a-tag> {{$t('ORDERS')}}-->
<!--          </template>-->
<!--          <a-timeline mode="left" class="timeline-history-comment" reverse v-if="orders&&orders.length">-->
<!--            <a-timeline-item v-for="order in orders" :key="order.id">-->
<!--              <div><router-link :to="'/order/'+order.order_number "><a class="user-comment">-->
<!--                {{ order.order_number }}</a ></router-link>-->
<!--                <span class="time-comment">{{ dateTimeDisplayFormatFromString(order.created_at) }}</span>-->
<!--              </div>-->
<!--                            <div v-if="comment.tag_ids">-->
<!--                                  <span v-for="tagId in comment.tag_ids" :key="tagId">-->
<!--                                    <tag-outlined/> <span class="type-tag">-->
<!--                                      {{ $t(comment.type) }}-->
<!--                                    </span><a-tag-->
<!--                                      :color="getOneByCustomerTagId(tagId).color">{{-->
<!--                                      getOneByCustomerTagId(tagId).name-->
<!--                                    }}</a-tag>-->
<!--                                  </span>-->
<!--                            </div>-->
<!--              <div v-if="order.detail">-->
<!--                    <span>-->
<!--                      <ShoppingCartOutlined /> {{ order.detail }}-->
<!--                    </span>-->
<!--              </div>-->
<!--              <div v-if="order.note_bill">-->
<!--                    <span>-->
<!--                      <SnippetsOutlined /> {{ order.note_bill }}-->
<!--                    </span>-->
<!--              </div>-->
<!--            </a-timeline-item>-->
<!--          </a-timeline>-->
<!--          <a-empty v-else/>-->
<!--        </a-card>-->
<!--      </div>-->

<!--    </div>-->
    <create-schedule-modal ref="createScheduleRef"/>
    <trigger-quick-message-modal ref="triggerCustomerRef"/>
    <image-selection-modal ref="imageSelectionRef" :onSelect="onImageSelect"/>
    <a-modal
        :title="$t('ADD_NEW')"
        v-model:visible="visible2"
        :confirm-loading="confirmLoading"
        width="40%"
        @ok="saveTag"
    >
      <a-form :model="tagForm" ref="formRef" :rules="rules" :label-col="{span: 6,offset:0}" :wrapper-col="{ span: 16 }">
        <a-form-item :label="$t('TAG_TAG_NAME')" name="name">
          <a-input v-model:value="tagForm.name"/>
        </a-form-item>
        <a-form-item :label="$t('TAG_COLOR')" name="color">
          <div style="display: flex;">
            <a-button
                @click="togglePicker"
                :style="{'background-color':tagForm.color?tagForm.color:'white',color:tagForm.color?'white':'black','margin-right':'15px'}">
              Click
            </a-button>
            <ColorPicker theme="light" :color="tagForm.color" :sucker-hide="false" v-if="colorPickerVisible"
                         :sucker-canvas="suckerCanvas" :sucker-area="suckerArea" @changeColor="changeColor"
                         @openSucker="openSucker"
            />
          </div>
        </a-form-item>
        <a-form-item name="description" :label="$t('TAG_DESCRIPTION')">
          <a-textarea v-model:value="tagForm.description" placeholder="Description" :rows="4"/>
        </a-form-item>
      </a-form>

    </a-modal>
    <a-modal :width="1000" v-model:visible="visibleCreateConfig" @ok="addConfig"  >
     <template #title>
       <a-button @click="handleAddConfig">{{$t('ADD')}}</a-button>
     </template>
      <a-form ref="formRef3" :model="config"  :rules="rules2" v-if="CreateConfig">
        <a-form-item name="name" :label="$t('NAME')">
          <a-input @change="changeName" v-model:value="config.name"/>
        </a-form-item>
          <a-form-item :label="$t('identified_name')">
            <a-input v-model:value="config.identified_name"/>
          </a-form-item >
        <a-form-item :label="$t('FIELD_TYPE')">
          <a-radio-group v-model:value="config.field_type">
            <a-radio value="STRING">{{$t('TEXT')}}</a-radio>
            <a-radio value="INT">{{$t('number')}}</a-radio>
            <a-radio value="DATE">{{$t('DATE')}}</a-radio>
            <a-radio value="CURRENCY">{{$t('CURRENCY')}}</a-radio>
          </a-radio-group>
        </a-form-item>
<!--        <div v-if="config.field_type">-->
<!--          <a-form-item v-if="config.field_type === 'STRING'" :label="$t('value')">-->
<!--            <a-input v-model:value="config.customer_column_value"/>-->
<!--          </a-form-item>-->
<!--          <a-form-item v-else-if="config.field_type === 'DATE'"  :label="$t('value')">-->
<!--            <a-date-picker style="width: 100%" v-model:value="config.customer_column_value" format="DD-MM-YYYY" valueFormat="DD-MM-YYYY" />-->
<!--          </a-form-item>-->
<!--          <a-form-item v-else  :label="$t('value')">-->
<!--            <a-input-number style="width: 100%" v-model:value="config.customer_column_value" :min="1" :max="100000" />-->
<!--          </a-form-item>-->
<!--        </div>-->
      </a-form>
      <a-table
          v-else
          :dataSource="listConfig"
          :columns="columnConfig"
          class="flow-table">
        <template v-for="col in ['name','identified_name']" #[col]="{ text, record }" :key="col">
          <div>
            <a-input
                v-if="editableData[record.id]"
                v-model:value="editableData[record.id][col]"
                style="margin: -5px 0"
            />
            <template v-else>
              {{ text }}
            </template>
          </div>
        </template>
        <template #field_type="{record}">
          <a-typography-text v-if="record.field_type === 'STRING'">{{$t('TEXT')}}</a-typography-text>
          <a-typography-text v-if="record.field_type === 'INT'">{{$t('number')}}</a-typography-text>
          <a-typography-text v-if="record.field_type === 'DATE'">{{$t('DATE')}}</a-typography-text>
          <a-typography-text v-if="record.field_type === 'CURRENCY'">{{$t('CURRENCY')}}</a-typography-text>

        </template>
        <template  #action="{ record }">
          <div v-if="record.identified_name !== 'points' && record.identified_name !== 'rank'" class="editable-row-operations">
        <span v-if="editableData[record.id] ">
          <a @click="save(record.name,record.id)">Save</a>
            <a @click="cancel(record.id)" style="margin-left: 8px;">Cancel</a>
        </span>
            <span v-else>
          <a @click="edit(record.id)">Edit</a>
             <a-popconfirm
                 title="Sure to delete?"
                 @confirm="remove(record)"
             >
        <a style="margin-left: 8px;">Delete</a>
      </a-popconfirm>
        </span>
          </div>
        </template>
      </a-table>
    </a-modal>
    <a-modal  style="overflow-x: hidden" :width="1000" v-model:visible="visibleScheduleTrigger" title="Bạn có muốn chạy kịch bản không?" @ok="handleOk">
      <a-row justify="space-between">
        <a-typography-text strong>Chọn một loại kích hoạt</a-typography-text>
       <a-row style="width: 50%">
         <p>{{$t('remind_time_before')}}:</p>
         <a-select
             style="width: 30%;margin-left: 10px"
             show-search
             placeholder="Select Timeout"
             :default-active-first-option="false"
             :show-arrow="false"
             :not-found-content="null"
             v-model:value="remindTimeBefore">
           <a-select-option
               v-for="time in timeoutSelections"
               :key="time"
               :value="time.value"
           >{{ time.label }}
           </a-select-option>
         </a-select>
       </a-row>

      </a-row>
      <a-table style="height: 500px;overflow-x: scroll" :columns="columnsQuick" :data-source="list.length  ? list :[]"
               :rowSelection="{type:'radio',selectedRowKeys:selectedRowKeys,onChange:onRowSelect}"
               :pagination="false"
               :rowKey="record => record.id">
        <template #name="{ record }">
          <a-button type="text" class="table-row-name" @click="onRowSelect([record.id])">
            {{ record.name }}
          </a-button>
        </template>
        <template #type="{ text }">
               <span class="item-trigger-table-small">
                    {{ $t(text) }}
                </span>
        </template>
        <template #creator="{ text }">
                <span>
                  {{ getEmployeeName(text) }}

                </span>
        </template>
        <template #tags="{ record }">
                <span>
                    <a-tag v-for="tag in record.tags" :key="tag.id+'_'+tag.name"
                           :color="tag.color">{{ tag.name }}</a-tag>
                </span>
        </template>
      </a-table>

    </a-modal>
  </div>
</template>

<script lang="ts">
import {computed, defineComponent, h, onMounted, onUpdated, reactive, ref, UnwrapRef} from "vue";
import ImageSelectionModal from "@/components/Image/ImageSelectionModal.vue";
import {ColorPicker} from 'vue-color-kit'
import './vue-color-kit.css'
import {ContactForm, Customer, CustomerActivity, CustomerActivityType, CustomerForm} from "@/models/Customers/Customers";
import {
  MessageOutlined,
  SaveOutlined,
  TagOutlined,
  UserOutlined,
  PlusOutlined,
  ArrowLeftOutlined,
  ShoppingCartOutlined,
  SnippetsOutlined,
  DeleteOutlined,
  ScheduleOutlined,
    DownOutlined,
    PhoneOutlined,
    CloseCircleOutlined,
    FormOutlined,
  EnvironmentOutlined,
    HomeOutlined,
  CameraOutlined,
    SisternodeOutlined,
CaretUpOutlined,
    CaretDownOutlined,

} from "@ant-design/icons-vue";
import {useRoute} from "vue-router";
import {Dayjs} from "dayjs";
import {useForm} from "ant-design-vue/es/form";
import {ValidateErrorEntity} from "ant-design-vue/es/form/interface";
import {DictionaryActionTypes} from "@/store/modules/dictionary/action-types";
import {DictionaryMutationTypes} from "@/store/modules/dictionary/mutation-types";
import APIs from "@/APIs/APIs";
import {message, Modal} from "ant-design-vue";
import {CustomersMutationTypes} from "@/store/modules/customer/mutation-types";
import {CustomerResponse, ProvinceResponse, TreeTagsResponse} from "@/APIs/response/S_HttpResponse";
import {SelectionAnt} from "@/models/ShopPages/ConfigData";
import {MixUtils} from "@/utils/Utils";
import router from "@/router";
import RouterEndpoint from "@/router/RouterEndpoint";
import {Constant} from "@/utils/Constants";
import CreateScheduleModal from "@/components/customer/CreateScheduleModal.vue";
import {CustomersActionTypes} from "@/store/modules/customer/action-types";
import {useStore} from "vuex";
import {useI18n} from "vue-i18n";
import {TriggerMutationTypes} from "@/store/modules/trigger/mutation-types";
import TriggerQuickMessageModal from "@/components/customer/TriggerQuickMessageModal.vue";
import {TriggerActionTypes} from "@/store/modules/trigger/action-types";
import {TagsActionTypes} from "@/store/modules/tag/action-types";
import {Tag, TypeTag} from "@/models/Tags/Tags";
import {Orders} from "@/models/Orders/Orders";
import dayjs from "dayjs";
import {AWS_DO_Model} from "@/models/AWS_DO/AWS_DO";
import {CustomerCaresActionTypes} from "@/store/modules/customerCare/action-types";

import {Locale} from "@/models/locales/Locales";
import {CustomerConfig} from "@/models/CustomerConfig/CustomerConfig";
import {Trigger} from "@/models/Trigger/Trigger";
import {Employee} from "@/models/Users";
import utc from 'dayjs/plugin/utc'
const rules = {
  full_name: [
    {required: true, message: 'Please input full name', trigger: 'blur'},
  ],
};
const rules2 = {
  name: [
    {required: true, message: 'Please input full name', trigger: 'blur'},
  ],
};
let searchTreeTimeout=0
interface AddCommentModel {
  tagId?: number,
  comment?: string
}
interface config {
  id: string,
  name: string,
  value: string,
}

export default defineComponent({
  name: "Customer",
  components: {
    TriggerQuickMessageModal,
    CaretUpOutlined,
    CaretDownOutlined,
    // SaveOutlined,
    CloseCircleOutlined,
    PhoneOutlined,
    MessageOutlined,
    UserOutlined,
    TagOutlined,
    PlusOutlined,
    ArrowLeftOutlined,
    ScheduleOutlined,
    // ShoppingCartOutlined,
    // SnippetsOutlined,
    DeleteOutlined,
    CreateScheduleModal,
    DownOutlined,
    FormOutlined,
    EnvironmentOutlined,
    // HomeOutlined,
    ImageSelectionModal,
    ColorPicker,
    CameraOutlined,
    SisternodeOutlined


  },
  setup: function () {

    const route = useRoute()
    const store = useStore()
    const i18n = useI18n()
    const formRef = ref();
    const formRef2 = ref();
    const formRef3 = ref();
    const showEditAddress = ref(false)
    const contactFormRef = ref();
    const createScheduleRef = ref();
    const triggerCustomerRef = ref();
    const allPhones = ref<string[]>([]);
    const userInfo = computed(()=> store.getters.getUserInfo)
    const singleImg = ref(false)
    const currentCustomer = computed((): Customer | undefined => store.getters.getCurrentCustomer)
    const list = computed<Trigger[]>(() => store.getters.getTriggersQuickMessages)
const selectedRowKeys = ref<number[]>([])
    const customerChangeHistory = computed(() => store.getters.getCustomerChange)
    const pages = computed(() => store.getters.getPages)
    const customerCares = computed(() => store.getters.getCustomerCares)
    const employees = computed(() => store.getters.getEmployees)
    const careers = computed(() => store.getters.getCareers)
    const expandedKeys = ref<string[]>([]);
    const selectedKeys = ref<string[]>([]);
    const provinces = ref<SelectionAnt[]>([])
    let originalProvinces: SelectionAnt[] = []
    const districts = ref<SelectionAnt[]>([])
    let originalDistricts: SelectionAnt[] = []
    const wards = ref<SelectionAnt[]>([])
    let originalWars: SelectionAnt[] = []
    const commentForm = reactive<AddCommentModel>({comment: ''})
    const confirmLoading = ref(false)
    let childrenLoading=  ref(false)
    const childrenTags = ref<any>([])
    const visible2 = ref(false)
    const type = ref('')
    const tagForm = ref<Tag>({})
    const colorPickerVisible = ref(false)
    const suckerCanvas = ref(null)
    const suckerArea = ref([])
    const showTagSale = ref(false)
    const email = ref<any>()
    const showConfig= ref(false)
    const visibleCreateConfig = ref(false)
    const visibleScheduleTrigger = ref(false)
    const valueType = ref('')
    const editableData: UnwrapRef<Record<string, CustomerConfig>> = reactive({});
    const config = ref({
      id:0,
      name:'',
      field_type: 'STRING',
      identified_name:'',
      customer_column_value:'',

    })
    const remindTimeBefore = ref(0)
    const timeoutSelections = [
      {
        label: '1 ' + i18n.t('minutes'),
        value: 60  //second
      },
      {
        label: '2 ' + i18n.t('minutes'),
        value: 120  //second
      },
      {
        label: '3 ' + i18n.t('minutes'),
        value: 180  //second
      },
      {
        label: '4 ' + i18n.t('minutes'),
        value: 240  //second
      },
      {
        label: '5 ' + i18n.t('minutes'),
        value: 300  //second
      },
      {
        label: '6 ' + i18n.t('minutes'),
        value: 360  //second
      },
      {
        label: '7 ' + i18n.t('minutes'),
        value: 420  //second
      },
      {
        label: '8 ' + i18n.t('minutes'),
        value: 480  //second
      },
      {
        label: '9 ' + i18n.t('minutes'),
        value: 640  //second
      },

      {
        label: '10 ' + i18n.t('minutes'),
        value: 600  //second
      },
      {
        label: '11 ' + i18n.t('minutes'),
        value: 660  //second
      },
      {
        label: '12 ' + i18n.t('minutes'),
        value: 720  //second
      },
      {
        label: '13 ' + i18n.t('minutes'),
        value: 780  //second
      },
      {
        label: '14 ' + i18n.t('minutes'),
        value: 840  //second
      },
      {
        label: '15 ' + i18n.t('minutes'),
        value: 900  //second
      },
      {
        label: '16 ' + i18n.t('minutes'),
        value: 960  //second
      },
      {
        label: '17 ' + i18n.t('minutes'),
        value: 1020  //second
      },
      {
        label: '18 ' + i18n.t('minutes'),
        value: 1080  //second
      },
      {
        label: '19 ' + i18n.t('minutes'),
        value: 1140  //second
      },
      {
        label: '20 ' + i18n.t('minutes'),
        value: 1200  //second
      },
      {
        label: '21 ' + i18n.t('minutes'),
        value: 1260  //second
      },
      {
        label: '22 ' + i18n.t('minutes'),
        value: 1320  //second
      },
      {
        label: '23 ' + i18n.t('minutes'),
        value: 1380  //second
      },
      {
        label: '24 ' + i18n.t('minutes'),
        value: 1440  //second
      },
      {
        label: '25 ' + i18n.t('minutes'),
        value: 1500  //second
      },
      {
        label: '26 ' + i18n.t('minutes'),
        value: 1560  //second
      },
      {
        label: '27 ' + i18n.t('minutes'),
        value: 1620  //second
      },
      {
        label: '28 ' + i18n.t('minutes'),
        value: 1680  //second
      },
      {
        label: '29 ' + i18n.t('minutes'),
        value: 1740  //second
      },
      {
        label: '30 ' + i18n.t('minutes'),
        value: 1800  //second
      },
      {
        label: '31 ' + i18n.t('minutes'),
        value: 1860  //second
      },
      {
        label: '32 ' + i18n.t('minutes'),
        value: 1920  //second
      },
      {
        label: '33 ' + i18n.t('minutes'),
        value: 1980  //second
      },
      {
        label: '34 ' + i18n.t('minutes'),
        value: 2040  //second
      },
      {
        label: '35 ' + i18n.t('minutes'),
        value: 2100  //second
      },
      {
        label: '36 ' + i18n.t('minutes'),
        value: 2160  //second
      },
      {
        label: '37 ' + i18n.t('minutes'),
        value: 2220  //second
      },
      {
        label: '38 ' + i18n.t('minutes'),
        value: 2280  //second
      },
      {
        label: '39 ' + i18n.t('minutes'),
        value: 2340  //second
      },
      {
        label: '40 ' + i18n.t('minutes'),
        value: 2400  //second
      },
      {
        label: '41 ' + i18n.t('minutes'),
        value: 2460  //second
      },
      {
        label: '42 ' + i18n.t('minutes'),
        value: 2520  //second
      },
      {
        label: '43 ' + i18n.t('minutes'),
        value: 2580  //second
      },
      {
        label: '44 ' + i18n.t('minutes'),
        value: 2640  //second
      },
      {
        label: '45 ' + i18n.t('minutes'),
        value: 2700  //second
      },
      {
        label: '46 ' + i18n.t('minutes'),
        value: 2760  //second
      },
      {
        label: '47 ' + i18n.t('minutes'),
        value: 2820  //second
      },
      {
        label: '48 ' + i18n.t('minutes'),
        value: 2880  //second
      },
      {
        label: '49 ' + i18n.t('minutes'),
        value: 2940  //second
      },
      {
        label: '50 ' + i18n.t('minutes'),
        value: 3000  //second
      },
      {
        label: '51 ' + i18n.t('minutes'),
        value: 3060  //second
      },
      {
        label: '52 ' + i18n.t('minutes'),
        value: 3120  //second
      },
      {
        label: '53 ' + i18n.t('minutes'),
        value: 3180  //second
      },
      {
        label: '54 ' + i18n.t('minutes'),
        value: 3240  //second
      },
      {
        label: '55 ' + i18n.t('minutes'),
        value: 3300  //second
      },
      {
        label: '56 ' + i18n.t('minutes'),
        value: 3360  //second
      },
      {
        label: '57 ' + i18n.t('minutes'),
        value: 3420  //second
      },
      {
        label: '58 ' + i18n.t('minutes'),
        value: 3480  //second
      },
      {
        label: '59 ' + i18n.t('minutes'),
        value: 3540  //second
      },
      {
        label: '1 ' + i18n.t('HOURS'),
        value: "3600"  //second
      },
      {
        label: '2 ' + i18n.t('HOURS'),
        value: 7200  //second
      },
      {
        label: '3 ' + i18n.t('HOURS'),
        value: 10800  //second
      },
      {
        label: '4 ' + i18n.t('HOURS'),
        value: 14400  //second
      },
      {
        label: '5 ' + i18n.t('HOURS'),
        value: 18000  //second
      },
      {
        label: '6 ' + i18n.t('HOURS'),
        value: 21600  //second
      },
      {
        label: '7 ' + i18n.t('HOURS'),
        value: 25200  //second
      },
      {
        label: '8 ' + i18n.t('HOURS'),
        value: 28800  //second
      },
      {
        label: '9 ' + i18n.t('HOURS'),
        value: 32400  //second
      },
      {
        label: '10 ' + i18n.t('HOURS'),
        value: 36000  //second
      },
      {
        label: '11 ' + i18n.t('HOURS'),
        value: 39600  //second
      },
      {
        label: '12 ' + i18n.t('HOURS'),
        value: 43200  //second
      },
      {
        label: '13 ' + i18n.t('HOURS'),
        value: 46800  //second
      },
      {
        label: '14 ' + i18n.t('HOURS'),
        value: 50400  //second
      },
      {
        label: '15 ' + i18n.t('HOURS'),
        value: 54000  //second
      },
      {
        label: '16 ' + i18n.t('HOURS'),
        value: 57000  //second
      },
      {
        label: '17 ' + i18n.t('HOURS'),
        value: 61200  //second
      },
      {
        label: '18 ' + i18n.t('HOURS'),
        value: 64800  //second
      },
      {
        label: '19 ' + i18n.t('HOURS'),
        value: 68400  //second
      },
      {
        label: '20 ' + i18n.t('HOURS'),
        value: 72000  //second
      },
      {
        label: '21 ' + i18n.t('HOURS'),
        value: 75600  //second
      }, {
        label: '22 ' + i18n.t('HOURS'),
        value: 79200  //second
      }, {
        label: '23 ' + i18n.t('HOURS'),
        value: 82800  //second
      },
      {
        label: '24 ' + i18n.t('HOURS'),
        value: 86400  //second
      },
      {
        label: '2 ' + i18n.t('days'),
        value: 172800  //second
      },
      {
        label: '3 ' + i18n.t('days'),
        value: 259200  //second
      },
      {
        label: '4 ' + i18n.t('days'),
        value: 345600  //second
      },

      {
        label: '5 ' + i18n.t('days'),
        value: 432000  //second
      },
      {
        label: '6 ' + i18n.t('days'),
        value: 518400  //second
      },
      {
        label: '1 ' + i18n.t('week'),
        value: 604800
      },
      {
        label: '2 ' + i18n.t('week'),
        value: 1209600  //second
      },
      {
        label: '3 ' + i18n.t('week'),
        value: 1814400  //second
      },
      {
        label: '30 ' + i18n.t('days'),
        value: 2592000  //second
      },


    ]
    const delteteComment = async (data:any) => {
      const res = await APIs.init().deleteShopCustomerNote(data)
      if(res.status === 200){
        message.success('Xóa lịch sử thành công')
        fetchHistoryComment()
      }
    }
    const getEmployeeName = (payload: number): string => {
      if (store.getters.getCurrentShop && store.getters.getCurrentShop.employees) {
        const employee = store.getters.getCurrentShop.employees.find((x: Employee) => x.id === payload);
        return employee ? employee.name : "";
      }
      return "";
    }
    const onRowSelect = (selectIds: number[]) => {
      selectedRowKeys.value = selectIds
    }
    const listConfig = ref<any>([])
    const columnsQuick = [
      {
        title: i18n.t('TRIGGER_TITLE'),
        dataIndex: 'name',
        key: 'name',
        slots: {customRender: 'name'},
      },
      {
        title: i18n.t('Type'),
        dataIndex: 'type',
        key: 'type',
        slots: {customRender: 'type'},
      },
      {
        title: i18n.t('TRIGGER_CREATOR'),
        dataIndex: 'creator',
        key: 'creator',
        slots: {customRender: 'creator'},
      },
      {
        title: i18n.t('TAG'),
        key: 'tags',
        dataIndex: 'tags',
        slots: {customRender: 'tags'},
      },
    ]
    const tagReq = ref({page: 1,
      page_size: 20,
      search: "",})
    const columns =[
      {
        title: i18n.t('ORDER_ID'),
        dataIndex: 'order_number',
        slots: {customRender: 'order_number'},
      },
      {
        title: i18n.t('PRODUCT'),
        dataIndex: 'detail',
        slots: {customRender: 'detail'},
      },
      {
        title: i18n.t('TOTAL_AMOUNT'),
        dataIndex: 'total',
        slots: {customRender: 'total'},
      },
      {
        title: i18n.t('BUY_AT'),
        slots: {customRender: 'buy_at'},
      },
      {
        title: i18n.t('status'),
        dataIndex: 'status',
        slots: {customRender: 'status'},
      },


    ]
    const columns2 =[
      {
        title: i18n.t('ORDER_ID'),
        dataIndex: 'order_number',
        slots: {customRender: 'order_number'},
      },
      {
        title: i18n.t('point'),
        dataIndex: 'point',
        slots: {customRender: 'point'},
      },
      {
        title: i18n.t('status'),
        dataIndex: 'status',
        slots: {customRender: 'status'},
      },
      // {
      //   title: i18n.t('status'),
      //   dataIndex: 'status',
      //   slots: {customRender: 'status'},
      // },


    ]
    const columnsCall =[
      {
        title: i18n.t('CATEGORY'),
        dataIndex: 'order_number',
        slots: {customRender: 'order_number'},
      },
      {
        title: i18n.t('CUSTOMER_PHONE'),
        dataIndex: 'detail',
        slots: {customRender: 'detail'},
      },
      {
        title: i18n.t('CALL_FROM'),
        dataIndex: 'total',
        slots: {customRender: 'total'},
      },
      {
        title: i18n.t('ATTACH_FILES'),
        slots: {customRender: 'buy_at'},
      },
      {
        title: i18n.t('time_visit'),
        dataIndex: 'status',
        slots: {customRender: 'status'},
      },
      {
        title: i18n.t('CALL_DURATION'),
        dataIndex: 'status',
        slots: {customRender: 'status'},
      },
      {
        title: i18n.t('STAFF_CALL'),
        dataIndex: 'status',
        slots: {customRender: 'status'},
      },


    ]
    const columnConfig = [
      {
        title: i18n.t('NAME'),
        dataIndex: 'name',
        key: 'name',
        slots: {customRender: 'name'},
      },
      {
        title: i18n.t('identified_name'),
        dataIndex: 'identified_name',
        key: 'identified_name',
        slots: {customRender: 'identified_name'},
      },
      {
        title: i18n.t('FIELD_TYPE'),
        dataIndex: 'field_type',
        key: 'field_type',
        slots: {customRender: 'field_type'},
      },
      {
        title: i18n.t('TAG_ACTION'),
        key: 'action',
        slots: {customRender: 'action'},
      },
    ]
    const treeTags = computed(() => store.getters.getTreeTags)
    const getOneEmployeeById = (id: number) => {
      const emp = store.getters.getOneEmployeeById(id)
      return emp
    }
    const getOneByCustomerTagId = (id: number) => {
      let tags:any = []
      console.log( treeTags.value,' treeTags.value',id)
      treeTags.value.filter((item:any) => {
        if(item.id === id){
          tags.push(item)
        }else {
          item.children.filter((i:any) => {
            if(i.id === id){
              tags.push(item)
            }
          })
        }

      })
      return tags[0] ? tags[0] : {color:'',name:''}
    }
    const getCoversationTag = (str:string) => {
      let type  =str.split(',')[0]
      let tag = str.split(',').filter((i:any,index:number) => index !== 0)
      return {type:type,tag:tag}
    }
    const getOneByCustomerLastCare =(id:number) => {
     let care = customerCares.value.find((item:any) => item.id === id)
      return care ? care : {name:'Xóa trạng thái chăm sóc',color:''}
    }
    const usePoint = ref([])
    const tagIds = ref<number[]>([])
    const addCommentVisible = ref(false)
    const buttonSubmitCommentLoading = ref(false)
    const showWarningAlertAddComment = ref(false)
    const provinceLoaded = ref(true)
    const districtLoaded = ref(true)
    const wardLoaded = ref(true)
    const isFetching = ref<boolean>(false)
    const submitLoading = ref(false)
    const searchTagValue = ref('')
    const commentHistory = ref<CustomerActivity[]>([])
    const orders = ref<Orders[]>([])
    const showAssigee =ref(false)
    const showEmail = ref(false)
    const showCare = ref(false)
    const showCareer = ref(false)
    const showBirth = ref(false)
    const showSex = ref(false)
    const showNone = ref(false)
    const showCareStaff = ref(false)
    const birthDay = ref()
    const showCustomerConfigName = ref(false)
    const showCustomerConfigValue = ref(0)
    const titleConfig = ref('')
    const showName = ref(false)
    const CreateConfig = ref(false)
    const lisCustomerColumnValue = ref<any>([])
const schedule = ref()
    const listMemberRank = computed(()=>store.getters.getMemberRankData)

    const tagCheck = ref("")

    const getTags = () => {
      store.dispatch(TagsActionTypes.FETCH_TREE_TAGS,{ payload: {type: "customer", search: '',page_size:1000}})
    }

    const getUsePoint = async () => {
      const res = await APIs.init().getUsePoints({id:route.params.id})
      if(res.data?.status === 200){
        usePoint.value = res.data.data && res.data.data.length && res.data.data.reverse()
      }
    }

    const rulesFormNote = {
      comment: [
        {required: true, message: i18n.t('INPUT_YOUR_COMMENT'), trigger: 'blur'},
      ],
    };

    const CustomerConfig = computed(() => store.getters.getCustomerConfig)

    const getCustomerConfig =  () => {
      const request = {shop_id:currentCustomer.value ? currentCustomer.value.shop_id : undefined}
      store.dispatch(CustomersActionTypes.FETCH_CUSTOMER_CONFIG,request)

    }
    const getCustomerConfigValue = async () => {
      const res = await APIs.init().getCustomerConfigValue({id: Number(route.params.id)})
     if(res.data?.data){
       lisCustomerColumnValue.value = res.data?.data
       listConfig.value = CustomerConfig.value.map((item:any) =>{
         if(lisCustomerColumnValue.value.length >0){
           lisCustomerColumnValue.value.map((i:any) => {
             if(item.id === i.customer_column_id){
               if(item.field_type === 'INT'){
                 item.customer_column_value = Number(i.customer_column_value)
               }
               if(item.field_type === 'CURRENCY'){
                 item.customer_column_value = MixUtils.methods.formatMoney(Number(i.customer_column_value))
               }
               item.customer_column_value = i.customer_column_value
               item.customer_id = i.customer_id
               return item
             }
             return item
           })
         }

         return item
       })
     }
    }


    // const remove = (key: string) => {
    //   APIs.init().deleteLocales(key).then(() => {
    //     fetchLocales()
    //   })
    // };
    const createCustomerConfig = async (sch?:string) => {
      const request = {shop_id:currentCustomer.value ? currentCustomer.value.shop_id : undefined,customer_id: Number(route.params.id) ,identified_name: config.value.identified_name,name: config.value.name,field_type: config.value.field_type ,id: config.value.id,customer_column_value:config.value.customer_column_value}
      const res = await APIs.init().addCustomerConfig(request)
      if(res.status === 200){

        message.success(i18n.t('SUCCESS'))
        await  getCustomerConfig()
        await getCustomerConfigValue()
        showCustomerConfigValue.value = 0
        if(sch === 'schedule'){
          visibleScheduleTrigger.value = true
          schedule.value = new Date(config.value.customer_column_value)
          console.log(schedule.value,'SUCCESS')
        }
      }else {
        message.error('error')
      }
    }

    const updateCustomerConfig = async (data: any,sh?:string) => {
       console.log(data,listConfig.value,'data')
    await  createHistoryChange(data.name,data.customer_column_value)
      if(data.customer_id && data.shop_id ){
        const request = {shop_id:currentCustomer.value ? currentCustomer.value.shop_id : undefined,customer_id: Number(route.params.id) ,customer_column_value: data.customer_column_value,id: data.id }

        const res = await APIs.init().UpdateCustomerConfig(request)
        if(res.status === 200){
          if(sh === 'schedule'){
            visibleScheduleTrigger.value = true
            // @ts-ignore
            schedule.value = dayjs(res.data?.data.customer_column_value,"DD/MM/YYYY HH:mm").unix()
            // @ts-ignore
            console.log(schedule.value,dayjs(res.data?.data.customer_column_value,"DD/MM/YYYY HH:mm"),'SUCCESS')
          }
          message.success(i18n.t('SUCCESS'))
          getCustomerConfig()
         await getCustomerConfigValue()
          showCustomerConfigValue.value = 0



        }else {
          message.error('error')
        }
      }else {
        config.value = data
        await createCustomerConfig(sh)
      }
    }

    const getConversationTagById = (tagId: string) => {
      console.log(store.getters.getConversationTagById(currentCustomer.value ? currentCustomer.value.page_id : '',tagId),'1111')
      if(store.getters.getConversationTagById(currentCustomer.value ? currentCustomer.value.page_id : '', tagId) !== {}){
        return store.getters.getConversationTagById(currentCustomer.value ? currentCustomer.value.page_id : '', tagId)
      }
    }
    let form: CustomerForm = {selectedTags: [], conversationTags: []}

    const splitString = (str: string): string[] => {

      if (str) {

        return str.split(",")
      }
      return []
    }
    const getBirth = (b: any) =>{
      const birth =dayjs(b).format("DD/MM/YYYY")
      return birth
    }
    const getSpentAmuont = (data:any) =>{
      let total = 0
      data.map((i:any) =>{
        if(i.status === 4)total += i.total
      })
      return MixUtils.methods.formatMoney(total)
    }
    const getMemberRANK = (id:string)=>{
      let rankNAME =''

      listMemberRank.value.memberRank.map((item:any)=>{
        if(item.id === id){
          rankNAME = item.rank_name
        }
      })
      console.log(listMemberRank.value,rankNAME,'rankName')
      return rankNAME
    }
    const getDiliveredOrder = (data:any) => {
      let total = []
      total = data.filter((i:any) => i.status === 4)
      return total.length
    }
    const textNote = ref<any>('')
    const handleNote = (e: any) => {
      textNote.value = e.target.value

    }

    dayjs.extend(utc)
    const handleOk = async () => {
      // @ts-ignore

      let time= schedule.value - remindTimeBefore.value
      console.log()
      // @ts-ignore
      const res = await APIs.init().ScheduleTrigger({schedule_time:dayjs.unix(time).utc().format(),page_id:customerForm.value.page_id,shop_id:customerForm.value.shop_id,trigger_id:selectedRowKeys.value[0],social_id: customerForm.value.social_id,employee_ids:[userInfo.value.id],message_note:"123",customer_id:customerForm.value.id})
      if(res.data?.status === 200) {
        message.success('Thành công')
        visibleScheduleTrigger.value =false
      }
    }
    const handleConfig = () => {
      showConfig.value = !showConfig.value
      listConfig.value = CustomerConfig.value.map((item:any) =>{
        if(lisCustomerColumnValue.value.length >0){
          lisCustomerColumnValue.value.map((i:any) => {
          if(item.id === i.customer_column_id){
            if(item.field_type === 'INT'){
              item.customer_column_value = Number(i.customer_column_value)
              return item
            }
            if(item.field_type === 'CURRENCY'){
              item.customer_column_value = MixUtils.methods.formatMoney(Number(i.customer_column_value))
              return item
            }
            item.customer_column_value = i.customer_column_value
            item.customer_id = i.customer_id
            return item
          }
          return item
          })
        }

        return item
      })


      console.log(listConfig.value)


    }
    const edit = (key: string) => {

      editableData[key] = {...listConfig.value.filter((item:any) => key === item.id)[0]}

    };
    const save = async (key: string,id:number) => {
      console.log(key)
      // console.log("save", key)
      let copyConfig = [...listConfig.value]
      Object.assign(copyConfig.filter(item => id === item.id)[0], editableData[id]);
      const config =copyConfig.find(x=>x.id===id)
      console.log(config,copyConfig)
      if (config){
        const request = {shop_id:currentCustomer.value ? currentCustomer.value.shop_id : undefined,customer_id: Number(route.params.id),name: config.name,id: config.id,identified_name:config.identified_name }
        const res = await APIs.init().UpdateCustomerConfig(request)
        if(res.status === 200){
          message.success(i18n.t('SUCCESS'))
          getCustomerConfig()

        }else {
          message.error('error')
        }
      }
      delete editableData[id];
    };
    const remove = (key: any) => {
      APIs.init().DeleteCustomerConfig(key).then((res:any) => {
        if(res.data.status === 200){
          message.success(i18n.t('alert_delete_data_success'))
          listConfig.value = listConfig.value.filter((item:any) => item.id !== key.id)
          getCustomerConfig()
        }

      })
    };
    const cancel = (key: string) => {
      delete editableData[key];
    };
    // const handleCustomerConfigName = () => {
    //   showCustomerConfigName.value = 1
    // }
    // const handleCustomerConfigValue = () => {
    //   showCustomerConfigValue.value = 1
    // }
    const handleCustomerConfig = (title: string,data: any) => {

      visibleCreateConfig.value = true
      config.value = data ? data : {field_type: '',name: '',customer_column_value: '' }
      titleConfig.value = title
    }
    // const addNote = () =>{
    //   const currentNote = getNote(customerForm.value.note)
    //
    //   const newNote = {id: MixUtils.methods.getUUID(),note: textNote.value,created_by: userInfo.value.name,created_at: dayjs(new Date()).format('YYYY-MM-DD HH:mm:ss')}
    //   customerForm.value.note = JSON.stringify([...currentNote,newNote])
    //
    //   onSubmit()
    // }
    const handleAllphone = () => {
      // @ts-ignore

      // @ts-ignore
      if(!allPhones.value.includes(customerForm.value?.phone)){
        // @ts-ignore
        allPhones.value.push(customerForm.value?.phone)
      }
      createHistoryChange('CUSTOMER_PHONE',customerForm.value.phone)

    }
    const changeEmail = (value:any) => {
      createHistoryChange('email',value.target.value)
    }
    const changeCareer = (value:any) => {
     let career = careers.value.find((item:any) => item.id === value)
      createHistoryChange('CAREER',career.name)
    }
    const init = (): boolean => {
      getContact()
      getTags()
      getCustomerConfigValue()
      getUsePoint()

      fetchCustomerCares()
      if (route.params.id && route.params.id !== ':id') {
        if (isFetching.value) {
          return false
        }
        isFetching.value = true
        store.dispatch(CustomersActionTypes.FETCH_CUSTOMER, {
          payload: {id: route.params.id}, callback: (res: CustomerResponse) => {
            isFetching.value = false
            if (currentCustomer.value?.tags) {
              tagIds.value = currentCustomer.value?.tags.map((tag): number => {
                return tag.id ? tag.id : 0
              })
            }
            if (currentCustomer.value?.all_phones) {
              allPhones.value = currentCustomer.value?.all_phones.split(",")
            }

            if (route.params.id && route.params.id !== ':id' && currentCustomer) {

              form.selectedEmployee = 0
              if (currentCustomer.value?.employees) {
                const tmpEmpl = currentCustomer.value?.employees.split(",")
                if (tmpEmpl && tmpEmpl.length > 0) {
                  form.selectedEmployee = parseInt(tmpEmpl[0])
                }
              }
              form.selectedTags = []
              if (currentCustomer.value?.tags) {
                form.selectedTags = currentCustomer.value?.tags.map((tag) => tag && tag.id ? tag.id : 0)
              }
              form = {...form, ...currentCustomer.value}
              form.province_id = form.province_id + ""
              form.district_id = form.district_id + ""
              if (typeof form.birth ==='string'){
                form.birth=dayjs(form.birth)
              }
              if (typeof form.created_at ==='string'){
                form.created_at=dayjs(form.created_at)
              }
              customerForm.value = form
            }

            if (currentCustomer.value?.province_id) {
              fetchDistricts(currentCustomer.value?.province_id)

              if (currentCustomer.value?.district_id) {
                fetchWards(currentCustomer.value?.district_id)
              }
            }
          }
        })
      }
      fetchProvinces()
      fetchHistoryComment()
      fetchOrders()
      getCustomerConfig()
      return true
    }
    const customerForm = ref<CustomerForm>({...form})
    const contactForm = ref<ContactForm>({})
    onMounted(() => {
      init()
      handleConfig()

    })
    // onUpdated(() => {
    //   init()
    // })
    const handleCheck = (e:any) => {
          console.log(e)
    }
    const handleAddConfig = () =>{
      CreateConfig.value = true
      config.value.field_type = 'STRING'
    }
    const fetchOrders = () => {
      if (route.params.id && route.params.id !== ':id') {
        APIs.init().getOrders({
          customer_id: route.params.id+'',
          page_size: 9999,
        }).then(response => {

          if (response.status === 200 && response.data?.data && response.data.data) {
            orders.value = response.data.data
          }
        }).catch((e) => {
          console.error("e", e)
        })
      }
    }
    const imageSelectionRef = ref();
    const handleQuickCreate = (str: string) => {
      type.value = str
      visible2.value = true
    }
    const getPageId = (pageId: string) =>{
      let pageName = []
      if(pageId){
        pageName = pages.value.filter((item: any) => item.page_id === pageId)
        if( pageName && pageName.length > 0){
          return pageName[0].page_name
        }else {
          return ''
        }
      }


    }


    const addConfig = () => {
      if(CreateConfig.value){
      formRef3.value
          .validate()
          .then(() => {
              visibleCreateConfig.value = false
              createCustomerConfig()
              CreateConfig.value = false
              config.value = {
                id:0,
                name:'',
                field_type: '',
                identified_name: '',
                customer_column_value:'',
              }
              CreateConfig.value = false


          })

      }else {
        visibleCreateConfig.value = false
      }

    }
    const getTagSale = (tagId: any) =>{
        let tags = []
      tags = treeTags.value.filter((item:any)=> tagId.includes(item.id))
      treeTags.value.map((item:any) => {
          item.children.map((i:any)=>{
            if(tagId.includes(i.id)){
              tags.push(i)
            }
          })
      })

      return tags
    }
    const getCareName = (id:any)=>{
          if(!id)return {color: '',name:''}
          const data = customerCares.value.filter((i:any)=> i.id === id)
      return data[0]
    }
    // const attachFile = () => {
    //   imageSelectionRef.value.showModal()
    // }
    const showImageSingleSelectionModal = () => {
      imageSelectionRef.value.showModal();
    };
    const reverseArr = (data: any) =>{
      return data.reverse()
    }
    const getContact = () => {
      if (route.params.id && route.params.id !== ':id') {
        APIs.init().getContact({
          id: route.params.id,
        }).then(response => {
          // if (response.status === 200 && response.data?.data && response.data.data) {
          contactForm.value = response.data?.data?response.data?.data:{}
          if (typeof contactForm.value.birthday==="string"){
            contactForm.value.birthday=dayjs(contactForm.value.birthday)
          }
          // }
        }).catch((e) => {
          console.error("e", e)
        })
      }
    }
    const onDeleteContact = () => {
      if (route.params.id && route.params.id !== ':id') {
        APIs.init().deleteContact({
          id: contactForm.value.id,
        }).then(response => {
          if (response.status === 200) {
            contactForm.value = {}
          }
        }).catch((e) => {
          console.error("e", e)
        })
      }
    }
    const updateContact = () => {
      if (route.params.id && route.params.id !== ':id') {
        if(contactForm.value.birthday==='0001-01-01T00:00:00Z'){
          contactForm.value.birthday = undefined
        }
        contactForm.value.created_at = undefined
        contactForm.value.updated_at = undefined
        APIs.init().updateContact(contactForm.value).then(response => {
          if (response.status === 200) {
            contactForm.value = {}
            getContact()
          }
        }).catch((e) => {
          console.error("e", e)
        })
      }
    }
    const convertOrderStatus = (status: number) => {
      return MixUtils.methods.convertOrderStatus(status)
    }
    const convertUsePointStatus = (status: number) => {
      return status === 1 ? 'Đã dùng' : 'Đã hủy'
    }
    const getOrderStatusColor = (status: number) => {
      return MixUtils.methods.getOrderStatusColor(status)
    }
    const getOrderUsePointColor = (status:number) => {
       return status === 1 ? "#3c8dbc" :  "#031f3f"
    }
    const formatMoney = (money:any) => {
      return MixUtils.methods.formatMoney(money)
    }
    const createContact = () => {
      if (route.params.id && route.params.id !== ':id') {
        if(contactForm.value.birthday==='0001-01-01T00:00:00Z'){
          contactForm.value.birthday = undefined

        }
        contactForm.value.created_at = undefined
        contactForm.value.updated_at = undefined
        // @ts-ignore
        contactForm.value.shop_customer_id = Number(route.params.id)
        APIs.init().createContact(contactForm.value).then(response => {
          if (response.status === 200) {
            contactForm.value = {}
            getContact()
          }
        }).catch((e) => {
          console.error("e", e)
        })
      }
    }
    const getNote = (data: any)=>{
      const note = ref<any>([])
      if(data){
       data.split('/n').map((i: any)=>{
         note.value.push(i)
       })
      }
      console.log(data)
      return note.value


   }

    const fetchProvinces = () => {
      provinceLoaded.value = false
      store.dispatch(DictionaryActionTypes.FETCH_PROVINCES, {
        callback: (result?: ProvinceResponse) => {
          if (result && result.data) {
            originalProvinces = result.data.map((item): SelectionAnt => {
              return {
                value: item.id+"",
                label: item.name
              }
            })
            provinceSearch('');
          }
          provinceLoaded.value = true
        }
      })
    }
    const handleChangeName =  (value:any)=>{
      console.log(value.target.value)
    createHistoryChange('CUSTOMER_NAME',value.target.value)

    }
    const createHistoryChange = async (str:string,value:any,tagid?:any)=>{
      APIs.init()
          .addShopCustomerNote({
            content: tagid ? 'thay đổi thẻ sale' : `Thay đổi trường ${i18n.t(str)} thành ${value}`,
            type: tagid ? CustomerActivityType.UPDATE_TAG : CustomerActivityType.NOTE ,
            customer_id:currentCustomer.value?.id,
            tag_ids: tagid ? tagid : []
          })
          .then((res) => {
            if (res.status === 200 && res.data?.status === 200) {
              fetchHistoryComment()
              onSubmit()
            }
            delete commentForm.comment
            delete commentForm.tagId
            buttonSubmitCommentLoading.value = false
            addCommentVisible.value = false
          })
          .catch((e) => {
            console.error(e);
            buttonSubmitCommentLoading.value = false
            addCommentVisible.value = false
          });
    }
    const getTokenPage = (id:any) =>{
      let token = []
      token = store.getters.getOverview.filter((item:any) => item.page_id === id + '')

      return token && token[0] && token[0].page_token
    }
    const onImageSelect = (list: AWS_DO_Model[]) => {

        customerForm.value.avatar=list[0].url

    onSubmit()

    }
    const handleChangeTag=(value:any)=>{
      console.log(tagIds.value)
      if(tagIds.value.includes(Number(tagCheck.value))){
        // @ts-ignore
        APIs.init().triggerSendUpdateToOneBySaleTag({tag_id: tagCheck.value ,social_id:currentCustomer.value.social_id ,customer_id:!currentCustomer.value.social_id ? currentCustomer.value.id : 0})

      }
      if (tagIds.value){
        customerForm.value.tags=tagIds.value.map((tagId):Tag=>{
          return {
            id:tagId,
            name:''
          }
        })
      let Tag =  treeTags.value.map((item:any) => {
        if(value.includes(item.id)){
          return item.name
        }
        let n = []
     n = item.children.map((i:any) => {
          if(value.includes(i.id)){
            return i.name
          }
        })
        n = n.filter((item:any) => item !== undefined)
        return n

      })

          Tag = Tag.filter((item:any) => item !== undefined && item.length > 0)
        console.log(Tag,'tag')
        createHistoryChange('TAG',Tag,tagIds.value)
      }
    }
    const handleChangeEmplpyee =  (value:any) => {
      createHistoryChange('Assigee',getEmployee(value))

    }
    const handleChangeCareStaff = (value:any) =>{
      createHistoryChange('CUSTOMER_CARE_STAFF',getEmployee(value))

    }
    const handleChangeBirth = (value:any) => {
      console.log(value,'bir')
      createHistoryChange('CUSTOMER_BIRTHDAY',getBirth(value))


    }
    const handleChangeSex = (value:any) => {
      createHistoryChange('SEX',value)

    }
    const handlePhone = (e: any) => {
      customerForm.value.phone = e.target.value




    }
    const getCareer = (id: any) =>{
      const career = ref<any>([])
      if(id){
         careers.value.filter((i:any)=>{
           if(i.id == id){
             career.value.push(i)
           }
         })
      }
      return career.value[0].name
    }
    const handleCare  = async (value:any) =>{
      let cusmorCare = customerCares.value.find((item:any) => item.id === value)
     const  res = await APIs.init().updateCustomerLastCare({last_care_id: cusmorCare ? cusmorCare.id : 0,user_id:Number(route.params.id)})
      if(res.data?.status === 200){
        // createHistoryChange('CARE_STATUS',cusmorCare ? cusmorCare.name : 'rỗng')
        onSubmit()

      }

    }

    const deletePhone = (phone: any) =>{
      allPhones.value = allPhones.value.filter((i:any) => i!== phone)

      onSubmit()
    }
    const searchTag=(val:string)=>{

      if (searchTreeTimeout){
        clearTimeout(searchTreeTimeout)
      }
      searchTreeTimeout=window.setTimeout(()=>{
        store.dispatch(TagsActionTypes.FETCH_TREE_TAGS,{payload:{type:'customer',search:val}})
      },1100)
    }

    const handleBirthDay = () => {
      // birthDay.value[0].focus()
      showBirth.value = true

    }

    const handleEmail = () => {
      showEmail.value = true
      // if(showEmail.value){
      //   email.value.forcus()
      // }
    }
    const fetchDistricts = (provinceId: string) => {
      districtLoaded.value = false
      store.dispatch(DictionaryActionTypes.FETCH_DISTRICTS, {
        payload: {province_id: provinceId},
        callback: (result?: ProvinceResponse) => {
          if (result && result.data) {
            originalDistricts = result.data.map((item): SelectionAnt => {
              return {
                value: item.id+"",
                label: item.name
              }
            })
            districtSearch('');
          }
          districtLoaded.value = true
        }
      })
    }
    const fetchWards = (districtId: string) => {
      wardLoaded.value = false
      store.dispatch(DictionaryActionTypes.FETCH_WARDS, {
        payload: {district_id: districtId},
        callback: (result?: ProvinceResponse) => {
          if (result && result.data) {
            originalWars = result.data.map((item): SelectionAnt => {
              return {
                value: item.id,
                label: item.name
              }
            })
            wardSearch('');

          }
          wardLoaded.value = true
        }
      })
    }
   const changeName = (e:any) =>{
      config.value.identified_name = MixUtils.methods.removeVietnamese2(e.target.value)
    }
    const provinceSearch = (search: string) => {
      if (search) {
        provinces.value = originalProvinces.filter(x => MixUtils.methods.removeVietnamese2(x.label).includes(MixUtils.methods.removeVietnamese2(search)))
      } else {
        provinces.value = originalProvinces
      }
    }
    const districtSearch = (search: string) => {
      if (search) {
        districts.value = originalDistricts.filter(x => MixUtils.methods.removeVietnamese2(x.label).includes(MixUtils.methods.removeVietnamese2(search)))
      } else {
        districts.value = originalDistricts
      }

    }
    const wardSearch = (search: string) => {
      if (search) {
        wards.value = originalWars.filter(x => MixUtils.methods.removeVietnamese2(x.label).includes(MixUtils.methods.removeVietnamese2(search)))
      } else {
        wards.value = originalWars
      }

    }
    const fetchHistoryComment = () => {
      if (route.params.id && route.params.id !== ':id') {
        APIs.init().getShopCustomerNote({shop_customer_id: route.params.id}).then(response => {
          if (response.status === 200 && response.data?.data && response.data.data) {
            commentHistory.value = response.data.data.reverse()
          }
        }).catch((e) => {
          console.error("e", e)
        })
      }
    }
    const fetchChildrenTags = (id: number) => {
      if(id){
        APIs.init().getChildrenTags({parent_id: id, type: TypeTag.CUSTOMER_TAGS, page: 1, page_size: 60})
            .then(res => {
              childrenLoading.value = false
              if (res.ok && res.data && res.data.data) {
                childrenTags.value = res.data.data
              }
            }).catch((e) => {
          childrenLoading.value = false
          console.error("getChildrenTags", e)
        })
      }

    }
   const fetchRootTag = (isRefresh?: boolean)=> {
      if (isRefresh) {
        tagReq.value.page = 1
        tagReq.value.page_size = 20
      }
      store.dispatch(TagsActionTypes.FETCH_ROOT_TAGS,{
        type: type.value,
        page: tagReq.value.page,
        page_size: tagReq.value.page_size,
        search: tagReq.value.search
      })

    }
   const changeColor = (color: any) => {
      const {r, g, b, a} = color.rgba
      tagForm.value.color = color.hex;
      colorPickerVisible.value = false
    }
    const openSucker = (isOpen: boolean) => {
      if (isOpen) {
        // ... canvas be created
        // this.suckerCanvas = canvas
        // this.suckerArea = [x1, y1, x2, y2]
      } else {
        // this.suckerCanvas && this.suckerCanvas.remove
      }
    }
    const fetchCustomerCares = ()=>{
      store.dispatch(CustomerCaresActionTypes.FETCH_CUSTOMERS_CARES)
    }

    const togglePicker = () => {
      colorPickerVisible.value = !colorPickerVisible.value;
    }
    const saveTag =  () => {
      tagForm.value.type = type.value
      if(tagForm.value.type === 'customer'){
        APIs.init().createTag(tagForm.value).then(res => {
          confirmLoading.value = false
          if (res.status === 200 && res.data?.status === 200) {
            visible2.value = false
            getTags()
            message.success({content: 'Add tags success'});
            if (tagForm.value.parent_id) {
              fetchChildrenTags(tagForm.value.parent_id)
            } else {
              fetchRootTag()
            }
          } else {
            message.destroy()
          }
        }).catch(e => {
          confirmLoading.value = false
          message.destroy()
          console.error(e)
        })
      }else {
        APIs.init().createCustomerCares(tagForm.value).then(res => {
          confirmLoading.value = false
          if (res.status === 200 && res.data?.status === 200) {
           visible2.value= false
            message.success({content: 'Add status cares success'});
            fetchCustomerCares();
          } else {
            message.destroy()
          }
        }).catch(e => {
        confirmLoading.value = false
          message.destroy()
          console.error(e)
        })
      }



    }
    const provinceChange = () => {
      fetchDistricts(customerForm.value.province_id ? customerForm.value.province_id : '')
    }
    const districtChange = () => {
      fetchWards(customerForm.value.district_id ? customerForm.value.district_id : '')
    }
    const addComment = async () => {

      if (commentForm.comment || commentForm.tagId) {
        showWarningAlertAddComment.value = false
        buttonSubmitCommentLoading.value = true
        if (commentForm.tagId && currentCustomer.value?.id) {
          await APIs.init().addCustomerTags({
            tags: [commentForm.tagId],
            customers: [currentCustomer.value?.id],
          });
          if (!tagIds.value.includes(commentForm.tagId)) {
            tagIds.value.push(commentForm.tagId)
          }
        }
        APIs.init()
            .addShopCustomerNote({
              content: commentForm.comment,
              type:CustomerActivityType.NOTE,
              customer_id:currentCustomer.value?.id
            })
            .then((res) => {
              if (res.status === 200 && res.data?.status === 200) {
                fetchHistoryComment()
              }
              delete commentForm.comment
              delete commentForm.tagId
              buttonSubmitCommentLoading.value = false

              addCommentVisible.value = false
            })
            .catch((e) => {
              console.error(e);
              buttonSubmitCommentLoading.value = false
              addCommentVisible.value = false
            });
      } else {
        showWarningAlertAddComment.value = true
      }
    }
    const    createOrder = (customerId: number) => {
      router.push({name: RouterEndpoint.EDIT_ORDER.name, params: {id: 0}, query: {customer_id: customerId}})
    }
    const handleAddtags = (data: any) => {
      console.log(data)
    }
   const firstCharacterName  = (s: string) => {
      let str = []
      if (!s) {
        return "S"
      }
     str = s.split(' ').map(item => {
      return  item.charAt(0)
     })
     console.log(str)
      return str.join('');
    }

    const goback = () => {
      router.push({name: RouterEndpoint.CUSTOMER_MANAGEMENT.name})
    }
    const openChat = () => {
      var url =
          Constant.LINK_CHAT_NEW + "&pages="+ customerForm.value.page_id + "&social_id=" +customerForm.value.social_id + '&shop_id=' + userInfo.value.current_shop_id + "&token_web=" + userInfo.value.token_web + '&token_login=' + userInfo.value.token_login + "&user_id=" + userInfo.value.id;
      window.open(url, "_blank");

    }
    const openSchedule = () => {
      createScheduleRef.value.openModal()
    }
    const quickMessage = () => {
      triggerCustomerRef.value.openModal()
    }
    const closeWarningAlertAddComment = () => {
      showWarningAlertAddComment.value = false
    }
    const getEmployee = (id: any) =>{
      let employeeName = ''
     if(employees.value.data !== null){
       employees.value.data.map((item : any)=>{
         if(item.cms_user_id === Number(id)){
           employeeName = item.name

         }


       })
     }

      console.log(employeeName)
      return employeeName
    }

    const onSubmit = () => {
      getCustomerConfigValue()
      // formRef2.value
      //     .validate()
      //     .then(() => {
      // if(CustomerConfig.value.id !== 0){
      //   updateCustomerConfig()
      // }else {
      //   createCustomerConfig()
      // }
      if(contactForm.value.id){
        updateContact()
      }else{
        createContact()
      }
            if (allPhones.value) {
              console.log(allPhones.value,'phone')
              const phones = allPhones.value.join(",");
              customerForm.value.phone = allPhones.value[0]
              if (phones) {
                console.log(phones,'phone')
                customerForm.value.all_phones = phones;
              }
            }

            // if (customerForm.value.selectedConversationStaff) {
            //   customerForm.value.conversation_staff = customerForm.value.selectedConversationStaff + ""
            // }
            // if (customerForm.value.selectedEmployee) {
            //   customerForm.value.employees = customerForm.value.selectedEmployee + ""
            // }
            submitLoading.value = true
            if (route.params.id && route.params.id !== ':id') {
              APIs.init()
                  .updateCustomer(customerForm.value)
                  .then((res) => {
                    submitLoading.value = false;
                    if (res.status === 200 && res.data?.status === 200) {

                      message.success({content: "Edit customer success"});
                      store.commit(CustomersMutationTypes.SET_CUSTOMER, customerForm)
                      getTags()

                      showEditAddress.value = false
                      showEmail.value = false
                      showCareer.value = false
                      showTagSale.value = false
                      showAssigee.value=false
                      showCareStaff.value = false
                      showCare.value = false
                      showBirth.value = false
                      showSex.value = false
                      showName.value = false


                    }else {
                      message.error({content: res.data?.message});
                    }
                  })
                  .catch((e) => {
                    submitLoading.value = false;
                    // message.error();
                    console.error("error",e);
                  });
            } else {
              APIs.init()
                  .createCustomer(customerForm.value)
                  .then((res) => {
                    submitLoading.value = false;
                    if (res.status === 200 && res.data?.status === 200) {
                      message.success({content: "Create customer success"});
                      store.commit(CustomersMutationTypes.SET_CUSTOMER, res.data.data)
                      router.replace({name: RouterEndpoint.CUSTOMER_DETAIL_EDIT.name, params: {id: res.data.data.id}})
                      getTags()
                      showEditAddress.value = false
                      showEmail.value = false
                      showCareer.value = false
                      showTagSale.value = false
                      showAssigee.value=false
                      showCareStaff.value = false
                      showCare.value = false
                      showBirth.value = false
                      showSex.value = false
                    }else {
                      message.error({content: res.data?.message});
                      if (res.data&&res.data.data){
                        router.push({name:RouterEndpoint.CUSTOMER_DETAIL_EDIT.name,params:{id:res.data.data+''}})
                      }
                    }
                  })
                  .catch((e) => {
                    submitLoading.value = false;
                    message.destroy();
                    console.error("error",e);
                  });

            }

          // })
          // .catch((error: ValidateErrorEntity<CustomerForm>) => {
          //   console.error('error', error);
          // });
          //   contactFormRef.value
          // .validate()
          // .then(() => {
          //   if(contactForm.value.id!==0&&contactForm.value.id!==undefined){
          //     updateContact()
          //   }else{
          //     createContact()
          //   }
          // })
    };
    return {
      customerForm,
      pages,
      rules,
      formRef,
      formRef2,
      onSubmit,
      getConversationTagById,
      splitString,
      employees,
      careers,
      customerCares,
      allPhones,
      provinces,
      districts,
      wards,
      provinceChange,
      districtChange,
      provinceSearch,
      districtSearch,
      wardSearch,
      tagIds,
      treeTags,
      commentHistory,
      getOneEmployeeById,
      getOneByCustomerTagId,
      getOneByCustomerLastCare,
      commentForm,
      addComment,
      showWarningAlertAddComment,
      closeWarningAlertAddComment,
      addCommentVisible,
      showEmail,
      buttonSubmitCommentLoading,
      submitLoading,
      districtLoaded,
      wardLoaded,
      provinceLoaded,
      goback,
      getCareer,
      formatMoney,
      openChat,
      openSchedule,
      getBirth,
      deletePhone,
      showBirth,
      showSex,
      createScheduleRef,
      rulesFormNote,
      triggerCustomerRef,
      quickMessage,
      showCareer,
      handleChangeTag,
      getCareName,
      searchTag,
      getNote,
      orders,
      searchTagValue,
      contactForm,
      contactFormRef,
      onDeleteContact,
      firstCharacterName,
      getEmployee,
      showAssigee,
      handleCheck,
      handleChangeEmplpyee,
      handleChangeSex,
      handleChangeBirth,
      handlePhone,
      expandedKeys,
      selectedKeys,
      handleCare,
      handleAddtags,
      columns,
      columns2,
      showCare,
      convertOrderStatus,
      convertUsePointStatus,
      getOrderStatusColor,
      getOrderUsePointColor,
      showEditAddress,
      activeKey: ref('1'),
      activeKey2:ref('1'),
      handleNote,
      // addNote,
      columnsCall,
      showNone,
      showCareStaff,
      handleChangeCareStaff,
      getSpentAmuont,
      getDiliveredOrder,
      showImageSingleSelectionModal,
      imageSelectionRef,
      singleImg,
      // attachFile,
      onImageSelect,
      saveTag,
      confirmLoading,
      childrenLoading,
      childrenTags,
      visible2,
      tagForm,
      tagReq,
      togglePicker,
      colorPickerVisible,
      suckerCanvas,
      suckerArea,
      changeColor,
      openSucker,
      type,
      handleQuickCreate,
      getPageId,
      reverseArr,
      showTagSale,
      getTagSale,
      getTokenPage,
      birthDay,
      handleBirthDay,
      handleEmail,
      email,
      createOrder,
      showConfig,
      changeCareer,
      // handleCustomerConfigName,
      // handleCustomerConfigValue,
      showCustomerConfigName,
      getCoversationTag,
      showCustomerConfigValue,
      CustomerConfig,
      config,
      edit,
      save,
      remove,
      cancel,
      visibleCreateConfig,
      visibleScheduleTrigger,
      CreateConfig,
      changeName,
      handleAllphone,
      formRef3,
      rules2,
      handleAddConfig,
      getMemberRANK,
      customerChangeHistory,
      tagCheck,
      handleChangeName,
      changeEmail,
      valueType,
      listConfig,
      addConfig,
      handleConfig,
      handleCustomerConfig,
      titleConfig,
      showName,
      columnConfig,
      editableData,
      updateCustomerConfig,
      usePoint,
      list,
      columnsQuick,
      getEmployeeName,
      delteteComment,
      selectedRowKeys,onRowSelect,handleOk,timeoutSelections,remindTimeBefore

    }

  }
})
</script>

<style scoped lang="scss">
.button-goback {
  background-color: #87d068;
  border-color: #87d068;
  margin-right: 8px;
}

.schedule-employee {
  margin-left: 8px;
}

.container {
  padding-top: 36px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  flex-wrap: wrap;

  .card-customer {
    //flex: 1;
    border-radius: 10px;
    margin-right: 16px;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;

    .inline-form-customer {
      width:300px;
    }
  }
.info-customer{
  margin-bottom: 0;
}
  .history-comment {
    flex: 1;
    padding-right: 15px;
    padding-left: 15px;

    .header-comment {
      text-align: center;
      margin-bottom: 40px;
    }
    .header-order {
      text-align: center;
      margin-top: 40px;
      margin-bottom: 40px;
    }

    .card-history-comment {
      border-radius: 10px;
      box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
      margin-left: 10px;

      .timeline-history-comment {
        margin-left: 10px;
      }
    }

    .user-comment {
      font-weight: bold;
      font-size: 16px;
    }


    .time-comment {
      font-style: italic;
      margin-left: 10px;
      font-size: 12px;
    }

    .comment-reminder {
      margin-right: 12px;
      margin-left: 12px;
    }
  }

}

.type-tag {
  margin-left: 3px;
  margin-right: 7px;
}
.container{
  padding: 8px
}

</style>
