import { resolveComponent as _resolveComponent, createVNode as _createVNode, mergeProps as _mergeProps, withCtx as _withCtx, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-6e800a3c"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  class: "main-flow",
  id: "main-flow"
}
const _hoisted_2 = { class: "attach-file-container" }
const _hoisted_3 = {
  class: "row-button-action",
  style: {"text-align":"right"}
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_input = _resolveComponent("a-input")!
  const _component_a_form_item = _resolveComponent("a-form-item")!
  const _component_close_outlined = _resolveComponent("close-outlined")!
  const _component_a_button = _resolveComponent("a-button")!
  const _component_vue_editor = _resolveComponent("vue-editor")!
  const _component_a_card = _resolveComponent("a-card")!
  const _component_file_add_outlined = _resolveComponent("file-add-outlined")!
  const _component_a_image = _resolveComponent("a-image")!
  const _component_file_done_outlined = _resolveComponent("file-done-outlined")!
  const _component_a_list_item_meta = _resolveComponent("a-list-item-meta")!
  const _component_delete_outlined = _resolveComponent("delete-outlined")!
  const _component_a_list_item = _resolveComponent("a-list-item")!
  const _component_a_list = _resolveComponent("a-list")!
  const _component_flow_next_actions = _resolveComponent("flow-next-actions")!
  const _component_a_divider = _resolveComponent("a-divider")!
  const _component_a_popconfirm = _resolveComponent("a-popconfirm")!
  const _component_a_form = _resolveComponent("a-form")!
  const _component_image_selection_modal = _resolveComponent("image-selection-modal")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_a_form, {
      ref: "formRef",
      model: _ctx.formState,
      layout: "vertical"
    }, {
      default: _withCtx(() => [
        _createVNode(_component_a_form_item, _mergeProps({
          ref: "name",
          label: _ctx.$t('FLOW_MESSAGE_NAME'),
          name: "name"
        }, _ctx.validateInfos.name), {
          default: _withCtx(() => [
            _createVNode(_component_a_input, {
              placeholder: _ctx.$t('FLOW_MESSAGE_NAME'),
              onChange: _ctx.onTextChange,
              value: _ctx.formState.name,
              "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.formState.name) = $event))
            }, null, 8, ["placeholder", "onChange", "value"])
          ]),
          _: 1
        }, 16, ["label"]),
        _createVNode(_component_a_card, {
          class: "card-message",
          title: _ctx.$t('MAIL_CONTENT')
        }, {
          extra: _withCtx(() => [
            _createVNode(_component_a_button, { type: "text" }, {
              default: _withCtx(() => [
                _createVNode(_component_close_outlined)
              ]),
              _: 1
            })
          ]),
          default: _withCtx(() => [
            _createVNode(_component_a_form_item, _mergeProps({ name: "message_content" }, _ctx.validateInfos.message_content), {
              default: _withCtx(() => [
                _createVNode(_component_vue_editor, {
                  id: "editor",
                  modelValue: _ctx.formState.message_content,
                  "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.formState.message_content) = $event)),
                  placeholder: _ctx.$t('EMAIL_PLACEHOLDER_EDITOR')
                }, null, 8, ["modelValue", "placeholder"])
              ]),
              _: 1
            }, 16),
            _createVNode(_component_a_form_item, _mergeProps({
              ref: "name",
              label: _ctx.$t('FLOW_FOOTER_TEXT'),
              name: "name"
            }, _ctx.validateInfos.footer_text), {
              default: _withCtx(() => [
                _createVNode(_component_a_input, {
                  placeholder: _ctx.$t('FLOW_FOOTER_TEXT'),
                  onChange: _ctx.onTextChange,
                  value: _ctx.formState.footer_text,
                  "onUpdate:value": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.formState.footer_text) = $event))
                }, null, 8, ["placeholder", "onChange", "value"])
              ]),
              _: 1
            }, 16, ["label"])
          ]),
          _: 1
        }, 8, ["title"]),
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_a_button, {
            type: "dashed",
            onClick: _ctx.attachFile
          }, {
            default: _withCtx(() => [
              _createVNode(_component_file_add_outlined),
              _createTextVNode(" " + _toDisplayString(_ctx.$t('ATTACH_FILES')), 1)
            ]),
            _: 1
          }, 8, ["onClick"]),
          (_ctx.formState.attachments &&_ctx.formState.attachments.length)
            ? (_openBlock(), _createBlock(_component_a_list, {
                key: 0,
                "item-layout": "horizontal",
                "data-source": _ctx.formState.attachments
              }, {
                renderItem: _withCtx(({ item }) => [
                  _createVNode(_component_a_list_item, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_a_list_item_meta, {
                        description: item.split('/').pop()
                      }, {
                        avatar: _withCtx(() => [
                          (_ctx.checkUrlIsImage(item))
                            ? (_openBlock(), _createBlock(_component_a_image, {
                                key: 0,
                                size: "large",
                                shape: "square",
                                style: {"max-width":"250px"},
                                src: item
                              }, null, 8, ["src"]))
                            : (_openBlock(), _createBlock(_component_file_done_outlined, {
                                key: 1,
                                size: "large"
                              }))
                        ]),
                        _: 2
                      }, 1032, ["description"]),
                      _createVNode(_component_a_button, {
                        type: "text",
                        onClick: ($event: any) => (_ctx.deleteAttachment(item))
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_delete_outlined)
                        ]),
                        _: 2
                      }, 1032, ["onClick"])
                    ]),
                    _: 2
                  }, 1024)
                ]),
                _: 1
              }, 8, ["data-source"]))
            : _createCommentVNode("", true)
        ]),
        (!_ctx.isShow)
          ? (_openBlock(), _createBlock(_component_flow_next_actions, {
              key: 0,
              buttonName: _ctx.$t('FLOW_ACTION_EMAIL_OPEN'),
              updateAction: _ctx.updateOpenEmailAction,
              flowContextData: _ctx.flowContextData,
              flowContext: _ctx.flowContext,
              actionContext: _ctx.flowContext.action_open_mail_context
            }, null, 8, ["buttonName", "updateAction", "flowContextData", "flowContext", "actionContext"]))
          : _createCommentVNode("", true),
        (!_ctx.isShow)
          ? (_openBlock(), _createBlock(_component_flow_next_actions, {
              key: 1,
              buttonName: _ctx.$t('FLOW_ACTION_EMAIL_OPEN_LINK'),
              updateAction: _ctx.updateClickToLinkAction,
              flowContextData: _ctx.flowContextData,
              flowContext: _ctx.flowContext,
              actionContext: _ctx.flowContext.action_click_to_link_context
            }, null, 8, ["buttonName", "updateAction", "flowContextData", "flowContext", "actionContext"]))
          : _createCommentVNode("", true),
        _createVNode(_component_a_divider),
        (!_ctx.isShow)
          ? (_openBlock(), _createBlock(_component_flow_next_actions, {
              key: 2,
              buttonName: _ctx.$t('FLOW_SUCCESS_DELIVERY'),
              updateAction: _ctx.updateSuccessAction,
              flowContextData: _ctx.flowContextData,
              flowContext: _ctx.flowContext,
              actionContext: _ctx.flowContext.action_send_success_context
            }, null, 8, ["buttonName", "updateAction", "flowContextData", "flowContext", "actionContext"]))
          : _createCommentVNode("", true),
        (!_ctx.isShow)
          ? (_openBlock(), _createBlock(_component_flow_next_actions, {
              key: 3,
              buttonName: _ctx.$t('FLOW_FAILED_DELIVERY'),
              updateAction: _ctx.updateFailedAction,
              flowContextData: _ctx.flowContextData,
              flowContext: _ctx.flowContext,
              actionContext: _ctx.flowContext.action_send_failed_context
            }, null, 8, ["buttonName", "updateAction", "flowContextData", "flowContext", "actionContext"]))
          : _createCommentVNode("", true),
        (!_ctx.isShow)
          ? (_openBlock(), _createBlock(_component_flow_next_actions, {
              key: 4,
              buttonName: _ctx.$t('FLOW_DEFAULT_DELIVERY'),
              updateAction: _ctx.updateDefaultAction,
              flowContextData: _ctx.flowContextData,
              flowContext: _ctx.flowContext,
              actionContext: _ctx.flowContext.action_default_context
            }, null, 8, ["buttonName", "updateAction", "flowContextData", "flowContext", "actionContext"]))
          : _createCommentVNode("", true),
        _createElementVNode("div", _hoisted_3, [
          _createVNode(_component_a_popconfirm, {
            onConfirm: _ctx.deleteCurrentFlowContext,
            title: _ctx.$t('FLOW_CONFIRM_DELETE_CONTEXT'),
            "ok-text": _ctx.$t('YES'),
            "cancel-text": _ctx.$t('NO')
          }, {
            default: _withCtx(() => [
              _createVNode(_component_a_button, {
                danger: "",
                type: "primary"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_delete_outlined),
                  _createTextVNode(" " + _toDisplayString(_ctx.$t("FLOW_DELETE")), 1)
                ]),
                _: 1
              })
            ]),
            _: 1
          }, 8, ["onConfirm", "title", "ok-text", "cancel-text"])
        ])
      ]),
      _: 1
    }, 8, ["model"]),
    _createVNode(_component_image_selection_modal, {
      ref: "imageSelectionRef",
      onSelect: _ctx.onImageSelect
    }, null, 8, ["onSelect"])
  ]))
}