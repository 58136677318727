<template>
  <div class="container-title">
    <h3 class="title-header">{{ $t('SHOP_INFO') }}</h3>

    <a-button
        v-if="getMyRole(modules.shop_info)?.is_edit"
        type="primary" @click="update">{{ $t('SAVE') }}
    </a-button>
  </div>

  <div class="container-content">
    <a-row style="display: flex; gap: 16px">
      <a-col

          style="flex: 1; padding: 16px; background-color: white; border-radius: 5px"
      >
        <span>{{ $t('Shop_Information') }}</span>
        <a-form
            :labelCol="{ span: 8 }"
            labelAlign="left"
            style="margin-top: 14px; background-color: white"
        >
          <a-form-item :label="$t('SHOP')">
            <a-input v-model:value="shopInformation.shop" :placeholder="$t('SHOP')"/>
          </a-form-item>
          <a-form-item :label="$t('phone_number')">
            <a-input
                v-model:value="shopInformation.phone_number"
                :placeholder="$t('phone_number')"
            />
          </a-form-item>
          <a-form-item :label="$t('Shop_owner')">
            <a-input
                v-model:value="shopInformation.shop_owner"
                :placeholder="$t('Shop_owner')"
            />
          </a-form-item>
          <a-form-item label="Email">
            <a-input v-model:value="shopInformation.email" placeholder="Email"/>
          </a-form-item>
          <!--          <a-form-item-->
          <!--              label="National"-->
          <!--          >-->
          <!--            <a-input v-model:value="shopInfomation.national" placeholder="example" />-->
          <!--          </a-form-item>-->
          <a-form-item :label="$t('PROVINCE')" name="province_id">
            <a-select
                v-model:value="shopInformation.province_id"
                showSearch
                style="width: 100%"
                :filter-option="false"
                :not-found-content="null"
                :placeholder="$t('PROVINCE')"
                @change="provinceChange"
                @search="provinceSearch"
            >
              <a-select-option
                  v-for="item in getProvinces"
                  :key="item.id"
                  :value="item.id"
              >{{ item.name }}
              </a-select-option>
            </a-select>
          </a-form-item>
          <a-form-item :label="$t('DISTRICT')" name="district_id">
            <a-select
                v-model:value="shopInformation.district_id"
                showSearch
                :placeholder="$t('DISTRICT')"
                style="width: 100%"
                :filter-option="false"
                :not-found-content="null"
                @change="districtChange"
                @search="districtSearch"
            >
              <a-select-option
                  v-for="item in getDistricts"
                  :key="item.id"
                  :value="item.id"
              >{{ item.name }}
              </a-select-option>
            </a-select>
          </a-form-item>
          <a-form-item :label="$t('WARD')" name="ward_id">
            <a-select
                v-model:value="shopInformation.ward_id"
                showSearch
                :placeholder="$t('WARD')"
                :filter-option="false"
                :not-found-content="null"
                @search="wardSearch"
                style="width: 100%"
            >
              <a-select-option
                  v-for="item in getWards"
                  :key="item.id"
                  :value="item.id"
              >{{ item.name }}
              </a-select-option>
            </a-select>
          </a-form-item>
          <a-form-item :label="$t('ADDRESS')">
            <a-input
                v-model:value="shopInformation.address"
                :placeholder="$t('ADDRESS')"
            />
          </a-form-item>
        </a-form>
      </a-col>
      <a-col
          style="flex: 1;
          padding: 16px;
          background-color: white;
          border-radius: 5px;
        "
      >
        <span>{{ $t('Service_pack_Banking') }}</span>
        <a-row style="width: 100%; margin-top: 21px; padding-left: 16px">
          <a-card
              hoverable
              style=" width: 100%; display: flex; padding-top: 16px; padding-bottom: 16px"
              :bodyStyle="{ padding: 0, marginLeft: '20px' }"
          >
            <div>
              <a-typography-text class="card-body-title">{{
                  shopServices.trans_detail
                }}
              </a-typography-text>
            </div>
            <div class="card-body-barnch">
              <PhoneOutlined/>
              <a-typography-text class="card-body-title">{{
                  `${formatMoney(phoneNumber)} SĐT/${$t('day')}`
                }}
              </a-typography-text>
            </div>
            <div class="card-body-barnch">
              <MailOutlined/>
              <a-typography-text class="card-body-title">{{
                  `${formatMoney(autoInbox)} Auto Inbox/${$t('day')}`
                }}
              </a-typography-text>
            </div>
            <div class="card-body-barnch">
              <NodeExpandOutlined/>
              <a-typography-text class="card-body-title">{{
                  ` ${formatMoney(viral)} Viral Chat/${$t('day')}`
                }}
              </a-typography-text>
            </div>
            <div class="card-body-barnch">
              <DollarCircleOutlined/>
              <a-typography-text class="card-body-title" type="danger"

              >{{ price }} VNĐ
              </a-typography-text

              >
            </div>
          </a-card>
        </a-row>

        <a-row style="width: 100%; justify-content: flex-end">
          <a-form
              :model="shopInformation"
              :labelCol="{ span: 6 }"
              labelAlign="left"
              style="margin-top: 14px; width: 100%; padding-left: 16px"
          >
            <a-form-item :label="$t('BANK_INFO')">
              <a-select
                  :placeholder="$t('BANK_INFO')"
                  :options="BankSelection"
                  v-model:value="selectedBank"
                  @change="handleBankChange"
              >
              </a-select>
            </a-form-item>
            <a-form-item :label="$t('Branch')" name="shop_bank_branch">
              <a-input
                  :placeholder="$t('Branch')"
                  v-model:value="shopInformation.shop_bank_branch"
              />
            </a-form-item>
            <a-form-item
                :label="$t('Account_number')"
            >
              <a-input :placeholder="$t('Account_number')" name="accountBank"
                       v-model:value="shopInformation.shop_bank_number"
              />
            </a-form-item>
            <a-form-item :label="$t('Account_holder')" name="accountBank">
              <a-input
                  :placeholder="$t('Account_holder')"
                  v-model:value="shopInformation.shop_bank_owner"
              />
            </a-form-item>
          </a-form>

        </a-row>

      </a-col>
      <a-row>
        <a-form
            :model="formState"
            name="basic"

            autocomplete="off">
          <a-form-item
              label="link"

          >
            <a-input
                :value="'https://viral.shopf1.net/customer/?shop_id='+ data.id +'&user_id='+userInfo.id+'&token='+userInfo.token"/>

          </a-form-item>
          <a-form-item
              label="QR"

          >

            <img :src="QrUrl"/>
          </a-form-item>
        </a-form>
      </a-row>
    </a-row>

  </div>
</template>
<script lang="ts">
import {computed, onMounted, reactive, ref, watch, watchEffect} from "vue";
import {
  DollarCircleOutlined,
  NodeExpandOutlined,
  MailOutlined,
  PhoneOutlined,
  SmileOutlined,
  DownOutlined,
} from "@ant-design/icons-vue";
import {useStore} from "vuex";
import {DictionaryActionTypes} from "@/store/modules/dictionary/action-types";
import {ShopinfoActionTypes} from "@/store/modules/Shopinfo/action-types";
import {ShopInfo, ShopServices} from "@/models/Shopinfo/ShopInfo";

import {DistrictRequest, WardRequest} from "@/APIs/request/DictionaryRequest";
import {Province} from "@/models/Dictionary/Dictionary";
import APIs from "@/APIs/APIs";
import {ShopInfoRequest} from "@/APIs/request/ShopInfoRequest";
import {message} from "ant-design-vue";
import {ShopinfoResponse} from "@/APIs/response/S_HttpResponse";
import {Bank} from "@/models/Bank/Bank";
import {TariffCategory} from "@/models/Tariffs/Tariffs";
import {MixUtils} from "@/utils/Utils";

import {StocksRequest} from "@/APIs/request/StocksRequest";

import QRCode from 'qrcode'
import {Constant} from "@/utils/Constants";
import {useI18n} from "vue-i18n";


export default {
  components: {
    PhoneOutlined,
    DollarCircleOutlined,
    NodeExpandOutlined,
    MailOutlined,
  },
  setup: function () {
    const store = useStore();
    const shopInformation = reactive({
      shop: "",
      phone_number: "",
      shop_owner: "",
      email: "",
      province_id: undefined,
      district_id: undefined,
      ward_id: undefined,
      address: "",
      accountBank: "",
      shop_bank_branch: "",
      shop_bank_number: "",
      shop_bank_owner: "",
      auto_send_sms: 0,
    });
    const shopServices: ShopServices = reactive({
      trans_detail: "",
      user_phone: "",
      price: 0,
      account_holder: "",

    });
    const provinceSearchText = ref('')
    const districtSearchText = ref('')
    const wardSearchText = ref('')
    const districtRequest: DistrictRequest = {province_id: undefined}
    const wardRequest: WardRequest = {district_id: undefined}

    const i18n = useI18n()

    const userInfo = computed(() => store.getters.getUserInfo)
    console.log(userInfo.value)

    const getProvinces = computed((): Province[] | undefined => {
      if (provinceSearchText.value) {
        return store.getters.getProvinces?.filter((x: any) => MixUtils.methods.removeVietnamese2(x.name).includes(MixUtils.methods.removeVietnamese2(provinceSearchText.value)))
      } else {
        return store.getters.getProvinces
      }
    })
    const getDistricts = computed((): Province[] | undefined => {
      if (districtSearchText.value) {
        return store.getters.getDistricts?.filter((x: any) => MixUtils.methods.removeVietnamese2(x.name).includes(MixUtils.methods.removeVietnamese2(districtSearchText.value)))
      } else {
        return store.getters.getDistricts
      }
    })
    const getWards = computed((): Province[] | undefined => {
      if (wardSearchText.value) {
        return store.getters.getWards?.filter((x: any) => MixUtils.methods.removeVietnamese2(x.name).includes(MixUtils.methods.removeVietnamese2(wardSearchText.value)))
      } else {
        return store.getters.getWards
      }
    })
    const provinceChange = (province_id: number) => {
      store.dispatch(DictionaryActionTypes.FETCH_DISTRICTS, {payload: {province_id: province_id}})
      shopInformation.district_id = undefined
      shopInformation.ward_id = undefined
    }

    const fetchProvince = () => {
      store.dispatch(DictionaryActionTypes.FETCH_PROVINCES, undefined)
    }
    const fetchDistricts = () => {
      store.dispatch(DictionaryActionTypes.FETCH_DISTRICTS, {payload: {province_id: shopInformation.province_id}})
    }
    const fetchWards = () => {
      store.dispatch(DictionaryActionTypes.FETCH_WARDS, {payload: {district_id: shopInformation.district_id}})
    }
    const districtChange = (district_id: number) => {
      store.dispatch(DictionaryActionTypes.FETCH_WARDS, {payload: {district_id: district_id}})
      shopInformation.ward_id = undefined
    }
    const provinceSearch = (search: string) => {
      provinceSearchText.value = search
    }
    const districtSearch = (search: string) => {
      districtSearchText.value = search
    }
    const wardSearch = (search: string) => {
      wardSearchText.value = search
    }
    const formatMoney = (value: number) => {
      return MixUtils.methods.formatMoney(value)
    }
    const tariffCategory = ref<TariffCategory>({month: [], six_month: [], year: []})
    const fetchShopInfo = () => {
      store.dispatch(ShopinfoActionTypes.FETCH_SHOPINFO);
    };


    // const  fetchDistricts  = () => {
    //       store.dispatch()
    // }


    const BankSelection = ref([{label: "", value: 0}]);
    const selectedBank = ref(0);
    const data = computed(() => store.getters.getShopinfo);

    const QrUrl = ref('')
    const fetchQr = () => {
      QRCode.toDataURL('https://viral.shopf1.net/customer/?shop_id=' + data.value.id + '&user_id=' + userInfo.value.id + '&token=' + userInfo.value.token)
          .then((url: string) => {
            QrUrl.value = url
          })
          .catch((err: any) => {
            console.error(err)
          })
    }

    const dataAll = ref<ShopInfo>({...data.value});

    const bankAll = ref<Bank[]>([]);
    const phoneNumber = ref()
    const autoInbox = ref()
    const viral = ref()

    const fetchTariffs = async () => {
      const response = await APIs.init().getTariffs()
      if (response.status === 200 && response.data?.status === 200) {
        tariffCategory.value = response.data.data
        if (data?.value?.shop_services?.tariff_id >= 18 && data?.value?.shop_services?.tariff_id <= 21) {
          tariffCategory.value.month.map(item => {
            if (item.id === data.value.shop_services?.tariff_id) {
              phoneNumber.value = item.daily_free_phone
              autoInbox.value = item.max_auto_inbox
              viral.value = item.max_viral
            }
          })
        } else if (data?.value?.shop_services?.tariff_id >= 22 && data?.value?.shop_services?.tariff_id <= 25) {
          tariffCategory.value.six_month.map(item => {
            if (item.id === data.value.shop_services?.tariff_id) {
              phoneNumber.value = item.daily_free_phone
              autoInbox.value = item.max_auto_inbox
              viral.value = item.max_viral
            }
          })
        } else if (data?.value?.shop_services?.tariff_id >= 26 && data?.value?.shop_services?.tariff_id <= 29) {
          tariffCategory.value.year.map(item => {
            if (item.id === data.value.shop_services?.tariff_id) {
              phoneNumber.value = item.daily_free_phone
              autoInbox.value = item.max_auto_inbox
              viral.value = item.max_viral
            }
          })
        }
      }

    }
    const fetchBank = async () => {
      try {
        const res = await APIs.init().getBank();
        if (res.status === 200) {
          const data = computed((): any => res.data?.data);
          bankAll.value = [...data.value];
          BankSelection.value = bankAll.value.map((item) => ({
            label: item.name,
            value: item.id,
          }));
          console.log(10, BankSelection.value);
          bankAll.value = bankAll.value.filter(
              (item) => item.id === dataAll.value.bank_id
          );
          console.log(bankAll.value);
          selectedBank.value = dataAll.value.bank_id ? dataAll.value.bank_id : 0;
        }
      } catch (error) {
        console.log(error);
      }
    };

    const handleBankChange = (value: number) => {
      selectedBank.value = value;
    };

    watch(data, () => {
      if (dataAll.value.province_id) {
        store.dispatch(DictionaryActionTypes.FETCH_DISTRICTS, {
          province_id: dataAll.value.province_id,
        });
      }
      if (dataAll.value.district_id) {
        store.dispatch(DictionaryActionTypes.FETCH_WARDS, {
          district_id: dataAll.value.district_id,
        });
      }
    });


    const price = ref("");
    const showData = (dataAll: any) => {
      shopInformation.shop = dataAll.shop_name;
      shopInformation.phone_number = dataAll.shop_tel;
      shopInformation.shop_owner = dataAll.shop_bank_owner;
      shopInformation.accountBank = dataAll.shop_bank_owner;
      shopInformation.email = dataAll.shop_email;
      shopInformation.address = dataAll.shop_address;
      shopInformation.province_id = dataAll.province_id;
      shopInformation.district_id = dataAll.district_id;
      shopInformation.ward_id = dataAll.ward_id;
      shopServices.trans_detail = dataAll.shop_services?.trans_detail;
      shopServices.price = dataAll.shop_services?.price;
      shopInformation.shop_bank_branch = dataAll.shop_bank_branch;
      shopInformation.shop_bank_number = dataAll.shop_bank_number;
      shopInformation.shop_bank_owner = dataAll.shop_bank_owner;
      shopInformation.auto_send_sms = dataAll.auto_send_sms;
      price.value = formatMoney(shopServices.price ? shopServices.price : 0);
    };

    const update = async () => {
      const updateData: ShopInfoRequest = {
        id: dataAll.value.id,
        cms_user_id: dataAll.value.cms_user_id,
        shop_name: shopInformation.shop,
        shop_email_sell: dataAll.value.shop_email_sell,
        shop_gpkd: dataAll.value.shop_gpkd,
        shop_tel: shopInformation.phone_number,
        shop_bank_number: shopInformation.shop_bank_number,
        shop_bank_branch: shopInformation.shop_bank_branch,
        shop_bank_owner: shopInformation.shop_bank_owner,
        shop_email: shopInformation.email,
        shop_address: shopInformation.address,
        province_id: shopInformation.province_id,
        district_id: shopInformation.district_id,
        ward_id: shopInformation.ward_id,
        shop_address_full: dataAll.value.shop_address_full,
        shop_order_start: dataAll.value.shop_order_start,
        shop_order_end: dataAll.value.shop_order_end,
        shop_pay_type: dataAll.value.shop_pay_type,
        shop_pay_desc: dataAll.value.shop_pay_desc,
        shop_invoice_ship: dataAll.value.shop_invoice_ship,
        shop_invoice_unship: dataAll.value.shop_invoice_unship,
        price_zone_1: dataAll.value.price_zone_1,
        price_zone_2: dataAll.value.price_zone_2,
        price_zone_3: dataAll.value.price_zone_3,
        bank_id: selectedBank.value,
        system_phone: dataAll.value.system_phone,
        system_note: dataAll.value.system_note,
        system_tag: dataAll.value.system_tag,
        auto_send_sms: dataAll.value.auto_send_sms,
        status: dataAll.value.status,
        created_at: dataAll.value.created_at,
        updated_at: dataAll.value.updated_at,
        ship_status: dataAll.value.ship_status,
        customer_tag: dataAll.value.customer_tag,
      };

      try {
        const res = await APIs.init().updateShopInfo(updateData);
        if (res.status === 200) {

          const response = await APIs.init().getShopInfo();
          if (response.data?.status === 200) {
            const dataUpdate = computed<any>(() => response.data?.data);
            dataAll.value = {...dataUpdate.value};
            console.log(dataAll.value);
            showData(dataAll.value);
            fetchShopInfo();
            message.success(i18n.t('SUCCESS'))
          }
        } else {
          if (res.data?.message === 'shop_tel: cannot be blank.') {
            message.error(i18n.t('MISSING_PHONE_NUMBER'))
          } else {
            message.error(res.data?.message)
          }

        }
      } catch (err) {
        message.destroy();
        console.log(err);
      }
    };

    onMounted(() => {
      fetchShopInfo();
      showData(data.value);
      fetchProvince();
      fetchBank();
      fetchTariffs();
      fetchDistricts();
      fetchWards()

      fetchQr()

    });

    const getUserInfo = computed(() => store.getters.getUserInfo)

    const getMyRole = computed(() => {
      if (getUserInfo.value?.id_cms_privileges == null || getUserInfo.value?.id_cms_privileges < 11) {
        return () => ({
          is_visible: 1,
          is_create: 1,
          is_read: 1,
          is_edit: 1,
          is_delete: 1,
          id: 1
        })
      } else {
        return store.getters.getRole
      }
    });
    const modules = Constant.MODULES;

    return {
      getMyRole,
      modules,
      activeKey: ref("1"),
      shopInformation: shopInformation,
      fetchShopInfo: fetchShopInfo,
      showData,
      update,
      phoneNumber,
      autoInbox,
      viral,
      shopServices: shopServices,
      getProvinces,
      getWards,
      getDistricts,
      provinceChange,
      districtChange,
      price,
      BankSelection,
      selectedBank,
      handleBankChange,
      data, formatMoney,
      provinceSearch,
      districtSearch,
      wardSearch,

      userInfo,
      QrUrl

    };
  },
};
</script>

<style scoped>
.card-body-barnch {
  margin-top: 5px;
}

.card-body-title {
  margin-left: 18px;
}
</style>
