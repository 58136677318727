<template>
  <div class="container-title">
    <h3 class="title-header">{{ $t($route.name) }}</h3>
    <div class="input-search-customer">
      <a-input-search
          class="input-search"
          v-model:value="customerRequest.search"
          :placeholder="$t('SEARCH')"
          style="width: 100%"
          @change="onSearchChange"
      />
    </div>
    <a-button @click="filterExpanded=!filterExpanded" type="link">{{ $t('CUSTOMER_FILTER') }}
      <DownOutlined/>
    </a-button>

  </div>
  <div class="container">

    <div class="left-layout">
      <a-card v-if="filterExpanded" class="card-filter">
        <a-form layout="vertical" class="card-filter-content-layout" :model="customerRequest">
          <div>
            <!--            <a-form-item class="filter-form-item-inline" :label="$t('CUSTOMER_SOURCES')">-->
            <!--              <a-select-->
            <!--                  style="width: 100%"-->
            <!--                  allowClear-->
            <!--                  placeholder="Please select"-->
            <!--                  v-model:value="customerRequest.social_type"-->
            <!--                  @change="replaceLoadCustomer"-->
            <!--              >-->
            <!--                <a-select-option value="">-->
            <!--                  {{ $t("ALL") }}-->
            <!--                </a-select-option>-->
            <!--                <a-select-option value="cms">cms</a-select-option>-->
            <!--                <a-select-option value="zalo">zalo</a-select-option>-->
            <!--                <a-select-option value="instagram">instagram</a-select-option>-->
            <!--                <a-select-option value="facebook">facebook</a-select-option>-->
            <!--                <a-select-option value="line">line</a-select-option>-->
            <!--              </a-select>-->
            <!--            </a-form-item>-->
            <!--            <div class="space-horizontal"></div>-->
            <a-form-item class="filter-form-item-inline" label="Page">
              <a-select
                  allowClear
                  v-model:value="customerRequest.page_id"
                  style="width: 100%"
                  placeholder="Please select"
                  @change="replaceLoadCustomer"
              >
                <a-select-option value="">
                  {{ $t("ALL") }}
                </a-select-option>
                <a-select-option
                    v-for="item in getPages"
                    :key="item.id"
                    v-bind:value="item.page_id"
                >{{ item.page_name }}
                </a-select-option>
              </a-select>
            </a-form-item>
            <div class="filter-form-item-inline space-horizontal"></div>
            <a-form-item class="filter-form-item-inline" :label="$t('SALE_STAFF')">
              <a-select
                  style="width: 100%"
                  allowClear
                  v-model:value="customerRequest.staff_id"
                  placeholder="Please select"
                  @change="replaceLoadCustomer"
              >
                <a-select-option value="">{{ $t("ALL") }}</a-select-option>
                <a-select-option
                    v-for="item in getListEmployee"
                    :key="item.cms_user_id"
                    v-bind:value="item.cms_user_id"
                >{{ item.name }}
                </a-select-option>
              </a-select>
            </a-form-item>

            <div class="space-horizontal"></div>
            <a-form-item class="filter-form-item-inline" :label="$t('CUSTOMER_FILTER_BY_SALE_TAG')">
              <a-config-provider :renderEmpty="null">
                <a-tree-select
                    @change="handleChangeTag"
                    show-search
                    style="width: 100%"
                    @search="searchTag"
                    :loading="searchTagLoading"
                    v-model:value="selectedTags"
                    :filterTreeNode="false"
                    v-model:searchValue="searchTagValue"
                    :notFoundContent="null"
                    :dropdown-style="{ maxHeight: '400px', overflow: 'auto' }"
                    allow-clear
                    multiple
                    :placeholder="$t('TAG_SELECT')"
                >
                  <a-tree-select-node v-for="item in getTreeTags" :key="item.id" v-bind:value="item.id">
                    <template #title>
                      <a-tag :color="item.color">
                        {{ item.name }}
                      </a-tag>
                    </template>
                    <a-tree-select-node v-for="child in item.children" :key="child.id" v-bind:value="child.id">
                      <template #title>
                        <a-tag :color="child.color">
                          {{ child.name }}
                        </a-tag>
                      </template>
                    </a-tree-select-node>

                  </a-tree-select-node>
                </a-tree-select>
              </a-config-provider>
            </a-form-item>
            <!--            <div class="space-horizontal"></div>-->
            <!--            <a-form-item class="filter-form-item-inline" :label="$t('CUSTOMER_PHONE')">-->
            <!--              <a-select-->
            <!--                  style="width: 100%"-->
            <!--                  allowClear-->
            <!--                  placeholder="Please select"-->
            <!--                  v-model:value="customerRequest.with_phone"-->
            <!--                  @change="replaceLoadCustomer"-->
            <!--              >-->
            <!--                <a-select-option value="">{{ $t("ALL") }}</a-select-option>-->
            <!--                <a-select-option value="1">{{-->
            <!--                    $t("TELEPHONE_ NUMBER_ AVAILABILITY")-->
            <!--                  }}-->
            <!--                </a-select-option>-->
            <!--                <a-select-option value="-1">{{-->
            <!--                    $t("TELEPHONE_ NUMBER_ UNAVAILABILITY")-->
            <!--                  }}-->
            <!--                </a-select-option>-->
            <!--              </a-select>-->
            <!--            </a-form-item>-->
            <div class="space-horizontal"></div>
            <!--            <a-form-item class="filter-form-item-inline"-->
            <!--                         :label="isExpandFilter?'Click here to collapse the filter':'Click here to expand the filter'">-->
            <!--              <a-switch v-model:checked="isExpandFilter"/>-->
            <!--            </a-form-item>-->
            <!--              <div >-->
            <a-form-item class="filter-form-item-inline" :label="$t('CUSTOMER_CARE_STAFF')">
              <a-select
                  style="width: 100%"
                  allowClear
                  placeholder="Please select"
                  v-model:value="customerRequest.conversation_staff"
                  @change="replaceLoadCustomer"
              >
                <a-select-option value="">{{ $t("ALL") }}</a-select-option>
                <a-select-option
                    v-for="item in getListEmployee"
                    :key="item.cms_user_id"
                    v-bind:value="item.cms_user_id"
                >{{ item.name }}
                </a-select-option>
              </a-select>
            </a-form-item>
            <div class="space-horizontal"></div>
            <a-form-item class="filter-form-item-inline"
                         :label="$t('CUSTOMER_FILTER_BY_CHAT_TAG')">
              <a-select
                  mode="multiple"
                  style="width: 100%"
                  allowClear
                  v-model:value="chatTags"
                  placeholder="Please select"
                  @change="handleChangeChatTag"
              >
                <a-select-option
                    v-for="item in getChatTag()"
                    :key="item.tag_id"
                    v-bind:value="item.tag_id"
                >
                  <a-tag :color="item.tag_color">
                    {{ item.tag_name }}
                  </a-tag>
                </a-select-option>
              </a-select>
            </a-form-item>
            <div class="space-horizontal"></div>
            <a-form-item class="filter-form-item-inline"
                         :label="$t('CUSTOMER_FILTER_BY_BOOKING_TAG')">
              <a-select
                  mode="multiple"
                  style="width: 100%"
                  v-model:value="bookingTag"
                  placeholder="Please select"
                  @change="handleChangeBookingTag"
              >
                <a-select-option
                    v-for="item in getBookingTags"
                    :key="item.id"
                    v-bind:value="item.id"
                >
                  <a-tag color="#2C2C2C">
                    {{ item.name }}
                  </a-tag>
                </a-select-option>
              </a-select>
            </a-form-item>
            <div class="space-horizontal"></div>
            <a-form-item class="filter-form-item-inline"
                         :label="$t('CUSTOMER_FILTER_BY_NOTE_TAG')">
              <a-input
                  :placeholder="$t('Search note tag')"
                  v-model:value="customerRequest.note_tags"
                  @change="replaceLoadCustomer"
                  style="width: 100%"
              />
            </a-form-item>
            <div class="space-horizontal"></div>
            <!--                <a-form-item class="filter-form-item-inline" :label="$t('ORDER') + '>='">-->
            <!--                  <a-input-->
            <!--                      :placeholder="$t('ORDER') + '>='"-->
            <!--                      v-model:value="customerRequest.orders_gt"-->
            <!--                      @change="replaceLoadCustomer"-->
            <!--                      type="number"-->
            <!--                      style="width: 100%"-->
            <!--                  />-->
            <!--                </a-form-item>-->
            <!--                <div class="space-horizontal"></div>-->
            <!--                <a-form-item class="filter-form-item-inline" :label="$t('ORDER') + '<='">-->
            <!--                  <a-input-->
            <!--                      v-model:value="customerRequest.orders_lt"-->
            <!--                      @change="replaceLoadCustomer"-->
            <!--                      :placeholder="$t('ORDER') + '<='"-->
            <!--                      type="number"-->
            <!--                      style="width: 100%"-->
            <!--                  />-->
            <!--                </a-form-item>-->
            <!--                <div class="space-horizontal"></div>-->
            <!--                <a-form-item class="filter-form-item-inline"-->
            <!--                             :label="$t('ORDERS_SUCCESSFUL') + '>='">-->
            <!--                  <a-input-->
            <!--                      v-model:value="customerRequest.success_order_gt"-->
            <!--                      @change="replaceLoadCustomer"-->
            <!--                      :placeholder="$t('ORDERS_SUCCESSFUL') + '>='"-->
            <!--                      type="number"-->
            <!--                      style="width: 100%"-->
            <!--                  />-->
            <!--                </a-form-item>-->
            <!--                <div class="space-horizontal"></div>-->
            <!--                <a-form-item class="filter-form-item-inline"-->
            <!--                             :label="$t('ORDERS_SUCCESSFUL') + '<='">-->
            <!--                  <a-input-->
            <!--                      v-model:value="customerRequest.success_order_lt"-->
            <!--                      @change="replaceLoadCustomer"-->
            <!--                      :placeholder="$t('ORDERS_SUCCESSFUL') + '<='"-->
            <!--                      type="number"-->
            <!--                      style="width: 100%"-->
            <!--                  />-->
            <!--                </a-form-item>-->
            <!--                <div class="space-horizontal"></div>-->
            <!--                <a-form-item class="filter-form-item-inline" :label="$t('ORDERS_SHIPPED') + '>='">-->
            <!--                  <a-input-->
            <!--                      v-model:value="customerRequest.sent_order_gt"-->
            <!--                      @change="replaceLoadCustomer"-->
            <!--                      :placeholder="$t('ORDERS_SHIPPED') + '>='"-->
            <!--                      type="number"-->
            <!--                      style="width: 100%"-->
            <!--                  />-->
            <!--                </a-form-item>-->
            <!--                <div class="space-horizontal"></div>-->
            <!--                <a-form-item class="filter-form-item-inline" :label="$t('ORDERS_SHIPPED') + '<='">-->
            <!--                  <a-input-->
            <!--                      v-model:value="customerRequest.sent_order_lt"-->
            <!--                      @change="replaceLoadCustomer"-->
            <!--                      :placeholder="$t('ORDERS_SHIPPED') + '<='"-->
            <!--                      type="number"-->
            <!--                      style="width: 100%"-->
            <!--                  />-->
            <!--                </a-form-item>-->
            <!--                <div class="space-horizontal"></div>-->
            <!--                <a-form-item class="filter-form-item-inline" :label="$t('TOTAL_PAYMENT') + '>='">-->
            <!--                  <a-input-->
            <!--                      v-model:value="customerRequest.spend_money_gt"-->
            <!--                      @change="replaceLoadCustomer"-->
            <!--                      :placeholder="$t('TOTAL_PAYMENT') + '>='"-->
            <!--                      type="number"-->
            <!--                      style="width: 100%"-->
            <!--                  />-->
            <!--                </a-form-item>-->
            <!--                <div class="space-horizontal"></div>-->

            <!--                <a-form-item class="filter-form-item-inline" :label="$t('TOTAL_PAYMENT') + '<='">-->
            <!--                  <a-input-->
            <!--                      v-model:value="customerRequest.spend_money_lt"-->
            <!--                      @change="replaceLoadCustomer"-->
            <!--                      :placeholder="$t('TOTAL_PAYMENT') + '<='"-->
            <!--                      type="number"-->
            <!--                      style="width: 100%"-->
            <!--                  />-->
            <!--                </a-form-item>-->
            <!--                <div class="space-horizontal"></div>-->

            <a-form-item class="filter-form-item-inline" :label="$t('CITY')">
              <a-select
                  style="width: 100%"
                  placeholder="Please select"
                  @change="provinceChange(1)"
                  v-model:value="customerRequest.province_id"
              >
                <a-select-option
                    v-for="item in getProvinces"
                    :key="item.id"
                    v-bind:value="item.id"
                >{{ item.name }}
                </a-select-option>
              </a-select>
            </a-form-item>
            <div class="space-horizontal"></div>
            <a-form-item class="filter-form-item-inline" :label="$t('DISTRICT')">
              <a-select
                  style="width: 100%"
                  placeholder="Please select"
                  v-model:value="customerRequest.district_id"
                  @change="districtChange(1)"
              >
                <a-select-option
                    v-for="item in getDistricts"
                    :key="item.id"
                    v-bind:value="item.id"
                >{{ item.name }}
                </a-select-option>
              </a-select>
            </a-form-item>
            <div class="space-horizontal"></div>
            <a-form-item class="filter-form-item-inline" :label="$t('PROVINCE')">
              <a-select
                  style="width: 100%"
                  placeholder="Please select"
                  v-model:value="customerRequest.ward_id"
                  @change="replaceLoadCustomer"
              >
                <a-select-option
                    v-for="item in getWards"
                    :key="item.id"
                    v-bind:value="item.id"
                >{{ item.name }}
                </a-select-option>
              </a-select>
            </a-form-item>
            <div class="space-horizontal"></div>
            <a-form-item class="filter-form-item-inline" :label="$t('FIRST_INTERACTION')">
              <a-range-picker
                  v-model:value="dateRange"
                  format="DD-MM-YYYY"
                  @change="dateRangeChange"
              />
            </a-form-item>
            <div class="space-horizontal"></div>
            <a-form-item class="filter-form-item-inline" :label="$t('LAST_INTERACTION')">
              <a-range-picker
                  v-model:value="dateLastRange"
                  format="DD-MM-YYYY"
                  @change="dateLastRangeChange"
              />
            </a-form-item>
            <div class="space-horizontal"></div>
            <a-form-item class="filter-form-item-inline" :label="$t('BOOKING_DATE')">
              <a-range-picker
                  v-model:value="schedule"
                  format="DD-MM-YYYY"
                  @change="dateRangeBookingChange"
              />
            </a-form-item>

          </div>

          <!--          </div>-->


        </a-form>
      </a-card>
      <a-radio-group class="radio-group-customer-care" button-style="solid" v-model:value="customerRequest.last_care"
                     @change="replaceLoadCustomer">
        <a-radio-button  v-for="item in getCustomerDetailCares" :key="item.id" :value="item.id+''"
                        class="radio-item-customer-care">
          <span>
              {{ item.name }}
              <a-tag :color="item.color" class="radio-badge">
                {{ item.customer_count && item.customer_count > 0 ?  item.customer_count : 0 }}
              </a-tag>
            </span>
        </a-radio-button>
        <a-radio-button class="radio-item-customer-care" v-if="customerRequest.last_care" key="99"
                        @click="clearLastCare">
          {{ $t('CLEAR') }}
          <close-outlined/>
        </a-radio-button>
      </a-radio-group>

    </div>
    <div class="right-layout">

      <!--Data-->
      <a-table
          size="middle"
          :row-selection="{ onChange: onChangeKey,onSelectAll:onSelectAll }"
          :rowKey="(record) => record.id"
          :columns="getColumnsFilters"
          :data-source="getCustomerDataSource"
          :pagination="pagination"
          :expandedRowKeys="expandedRowKeys"
          @expand="handlerExpand"
          @change="handleTableChange"
          :scroll="{ y: 600  }"
          :showHeader="true"
          :customRow="customerRenderRow"
          :loading="getCustomerData.customersLoading"
          :locale="{
            filterConfirm: $t('OK'),
            filterReset: $t('RESET_FILTER'),
            emptyText: $t('no_data')
          }"
      >
        <template #customerNameTitle>
          <span>
           {{ $t("CUSTOMER_NAME") }}
          </span>
        </template>
        <template #customerField>
          <span>
           {{ $t("FIELD") }}
          </span>
        </template>

        <template #title>
          <div class="header-functions">
            <div class="button-left">
              <!--          <div class="input-search-customer">-->
              <!--            <a-input-search-->
              <!--                class="input-search"-->
              <!--                v-model:value="search"-->
              <!--                :placeholder="$t('SEARCH')"-->
              <!--                style="width: 100%"-->
              <!--                @change="onSearchChange"-->
              <!--            />-->
              <!--          </div>-->
              <div>
                <a-badge
                    v-if="getMyRole(this.MODULES.customers)?.is_edit"
                    :count="selectedNumber">
                  <a-button
                      :class="[checked?'button-function':'button-function-disabled']" size="small"
                            :disabled="!checked" @click="showModal(1)">
                    <tag-outlined/>
                    {{ $t("ADD_TAG") }}
                  </a-button>
                </a-badge>
                <a-badge
                    v-if="getMyRole(this.MODULES.customers)?.is_edit"
                    :count="selectedNumber">
                  <a-button :class="[checked?'button-function':'button-function-disabled']" size="small"
                            :disabled="!checked" @click="showModal(2)">
                    <tag-outlined/>
                    {{ $t("REMOVE_TAG") }}
                  </a-button>
                </a-badge>


                <a-dropdown v-if="checked" :trigger="['click']">
                  <template #overlay>
                    <a-menu>
                      <a-menu-item
                          v-if="getMyRole(this.MODULES.customers)?.is_edit"
                          key="1" @click="newAssignEmp()">
                        <usergroup-add-outlined/>
                        {{ $t("ASSIGN_CUSTOMERS_EMPLOYEES") }}
                      </a-menu-item>
                      <a-menu-item
                          v-if="getMyRole(this.MODULES.customers)?.is_edit"
                          key="2" @click="updateStatusAll()">
                        <retweet-outlined/>
                        {{ $t("UPDATE_STATUS") }}
                      </a-menu-item>
                      <a-menu-item
                          v-if="getMyRole(this.MODULES.customers)?.is_delete"
                          key="3" @click="deleteCustomerAll()">
                        <delete-outlined/>
                        {{ $t("DELETE") }}
                      </a-menu-item>
                    </a-menu>
                  </template>
                  <a-badge :count="selectedNumber">
                    <a-button size="small" style="margin-left: 22px;">
                      {{ $t("OTHER") }}
                      <down-outlined/>
                    </a-button>
                  </a-badge>

                </a-dropdown>
                <a-typography-text v-if="selectedNumber" style="margin-left: 20px;color: #fab216" >{{`${selectedNumber} ${$t('CUSTOMER')}`}}</a-typography-text>

              </div>
            </div>
            <div class="button-right">
              <a-button
                  v-if="userInfo.id === 1000214 || userInfo.id === 1008884  " @click="MergeCustomer">
                <template #icon>
                  <retweet-outlined />
                </template>
                {{ $t("MERGE_CUSTOMER") }}
              </a-button>
              <a-button @click="columnSelection()">
                <template #icon>
                  <table-outlined style=" padding-left: 5px;padding-right: 5px;"/>
                </template>
                <span>
                  {{ $t('DISPLAY_COLUMN') }}
                </span>
              </a-button>
              <a-button @click="clearFilter" danger>
                <template #icon>
                  <filter-outlined/>
                </template>
                <span>
              {{ $t('CLEAR_FILTER') }}
              <a-tag style="margin-left: 4px;" v-if="countQuery">
              {{ countQuery }}
            </a-tag>
            </span>

              </a-button>
              <a-button @click="showCXVIEW">
                <template #icon>
                  <camera-outlined/>
                </template>
                Camera AI
              </a-button>
              <a-popover :title="$t('IMPORT_EXCEL')"
                         trigger="click"
                         v-model:visile="visibleImport">
                <template #content>
                  <p>{{ $t('DOWNLOAD_EXAMPLE_EXCEL_FILE') }}</p>
                 <a-row>
                   <a-button @click="downloadSampleFile">
                     <cloud-download-outlined/>
                     {{ $t('download') }}
                   </a-button>
                   <a-upload
                       name="file"
                       :multiple="false"
                       :show-upload-list="false"
                       :beforeUpload="importCustomer"
                   >
                     <a-button :loading="importLoading">
                       <template #icon>
                         <cloud-upload-outlined/>
                       </template>
                       Upload file
                     </a-button>
                   </a-upload>
                 </a-row>
                  <a-divider />
                  <p>{{ $t('UPDATE_EXCEL_FILE') }}</p>
                  <a-row>


                    <a-upload
                        v-if="extraColumId"
                        name="file"
                        :multiple="false"
                        :show-upload-list="false"
                        :beforeUpload="importCustomerUpdate"
                    >
                      <a-button :loading="importLoading">
                        <template #icon>
                          <cloud-upload-outlined/>
                        </template>
                        Upload file
                      </a-button>
                    </a-upload>
                    <a-popover v-model:visible="visible3" trigger="click">
                      <template #title>
                        {{$t('CUSTOMER_SOURCES')}}
                      </template>
                      <template #content>
                        <a-select
                            v-model:value="extraColumType"
                            style="width: 100%"
                            placeholder="Tags Mode"


                        >
                          <a-select-option value="facebook" >
                            {{'Facebook'}}
                          </a-select-option>
                          <a-select-option value="zalo" >
                            {{'Zalo'}}
                          </a-select-option>
                          <a-select-option value="cms" >
                            {{'cms'}}
                          </a-select-option>
                        </a-select>

                      </template>
                      <a-button @click="()=>visible3 = true" >

                        {{extraColumType ? extraColumType : $t('CUSTOMER_SOURCES') }}
                      </a-button>
                    </a-popover>
                    <a-popover v-model:visible="visible1" trigger="click">
                      <template #title>
                        {{$t('CHOOSE_AN_IDENTIFIER_NAME')}}
                      </template>
                      <template #content>
                        <a-select
                            v-model:value="extraColumId"
                            style="width: 100%"
                            placeholder="Tags Mode"
                            @change="ChangIdentified"

                        >
                          <a-select-option v-for="i in getCustomerConfig" :key="i.id" :value="i.id" >
                            {{i.identified_name}}
                          </a-select-option>
                          <a-select-option value="phone" >
                            {{ $t('CUSTOMER_PHONE')}}
                          </a-select-option>
                        </a-select>

                      </template>
                      <a-button>
                        {{extraColumId ? extraColumId :$t('CHOOSE_AN_IDENTIFIER_NAME')}}
                      </a-button>
                    </a-popover>
                  </a-row>
                </template>
                <a-button
                    v-if="getMyRole(this.MODULES.customers)?.is_create"
                >
                  <template #icon>
                    <file-excel-outlined/>
                  </template>
                  Import
                </a-button>

              </a-popover>
              <a-popover
                  v-model:visible="visibleExport"
                  :title="$t('EXPORT_CUSTOMER')"
                  trigger="click"
              >
                <a-button
                    v-if="getMyRole(this.MODULES.customers)?.is_read"
                    @click="exportCustomer">
                  <template #icon>
                    <file-excel-outlined/>
                  </template>
                  Export
                </a-button>
                <template #content>
                  <div class="form-modal">
                    <h5>{{ $t("CHOOSE_PAGE_EXPORT") }}</h5>
                    <a-select
                        allowClear
                        @change="changePageExport"
                        style="width: 100%"
                        :placeholder="$t('CHOOSE_PAGE_EXPORT')"
                    >
                      <a-select-option
                          v-for="item in paginationExport"
                          :key="item"
                          v-bind:value="item"
                      >
                        {{ $t("PAGE") }} {{ item }}
                      </a-select-option>
                    </a-select>
                  </div>
                  <div style="margin-top: 20px">
                    <a-button style="margin-right: 30px" @click="hideExportModal"
                    >Cancel
                    </a-button
                    >
                    <a-button
                        type="primary"
                        @click="onExport"
                        :loading="confirmExport"
                    >
                      Export
                    </a-button>
                  </div>
                </template>
              </a-popover>
              <a-button
                  v-if="getMyRole(this.MODULES.customers)?.is_create"
                  type="primary" @click="visible2 = true">
                <template #icon>
                  <plus-outlined/>
                </template>
                {{ $t("CREATE") }}
              </a-button>
            </div>
          </div>

        </template>
        <template #rank_title>
          <span>
           {{ $t("MEMBER_RANK") }}
          </span>
        </template>
        <template #accumulate_points_title>
          <span>
           {{ $t("RATE") }}
          </span>
        </template>
        <template #accumulated_money_title>
          <span>
           {{ $t("ACCUMULATE_MONEY") }}
          </span>
        </template>
        <template #customerPhoneTitle>
          <span>
            {{ $t("ALL_PHONES") }}
          </span>
        </template>
        <template #source="{text}">
          <span>{{ $t(text) }}</span>
        </template>
        <template #customerBirthTitle>
          <span>
            {{ $t("CUSTOMER_BIRTHDAY") }}
          </span>
        </template>
        <template #conversationStaffTitle>
          <span>
            {{ $t("CONVERSATION_STAFF") }}
          </span>
        </template>
        <template #customerSourceTitle>
          <span>
            {{ $t("CUSTOMER_SOURCES") }}
          </span>
        </template>
        <template #customerAddressTitle>
          <span>
            {{ $t("CUSTOMER_ADDRESS") }}
          </span>
        </template>
        <template #customerTagsTitle>
          <span>
            {{ $t("SALE_TAGS") }}
          </span>
        </template>
        <template #customerSaleTagTitle>
          <span>
            {{ $t("CHAT_TAGS") }}
          </span>
        </template>

        <template #customerCareStatusTitle>
          <span>
            {{ $t("CARE_STATUS") }}
          </span>
        </template>
        <template #TITLE_EMPLOYEE>
          <span>
            {{ $t("SALE_STAFF") }}
          </span>
        </template>
        <template #customerLastTimeTitle>
          <span>
            {{ $t("REACTION_TIME") }}
          </span>
        </template>
        <template #customerSaleBookingTitle>
          <span>
            {{ $t("BOOKING_TAGS") }}
          </span>
        </template>
        <template #GENDER_TITLE>
          <span>
            {{ $t("GENDER") }}
          </span>
        </template>

        <template #full_name="{ record }">
         <a-row>
           <a-col :span="6">
             <a-avatar v-if="record.social_type === 'facebook'" style="width: 30px;height: 30px;cursor: pointer"    :src="`https://graph.facebook.com/${record.social_id}/picture?access_token=${getTokenPage(record.page_id)}`">
             </a-avatar>
             <a-avatar v-else style="width: 30px;height: 30px;cursor: pointer"    :src="record.avatar">
             </a-avatar>

           </a-col >
           <a-col :span="18">
             <a
                 class="table-row-name"
                 type="text"
                 @click="expandRow(record.id)"
             >
               <span>{{ record.full_name }}</span>
             </a>
             <div @click="copy(record.phone)" style="cursor: pointer" class="phone-number">{{ record.phone }}</div>
           </a-col>
         </a-row>
        </template>
        <template #field="{ record }">
         {{getCareer(record.career)}}
        </template>
        <template #sources="{ record }">

          <a-tooltip placement="topLeft">
            <template #title>
              <span>{{$t("CUSTOMER_CONVERSATION")}}</span>
            </template>
          <img
              v-if="record.social_type === 'facebook' || record.fb_appuid"
              draggable="false"
              src="@/assets/icons/facebook-svgrepo-com.svg"
              :style="{width: record.social_type === 'facebook' ? '25px' : '20px','margin-right': '20px',cursor: 'pointer'}"
              @click="openChat(record.page_id ? record.page_id :record.fb_page_id, record.social_id ?record.social_id:record.fb_appuid, record.shop_id)"
          />
          </a-tooltip>
          <a-tooltip placement="topLeft">
            <template #title>
              <span>{{$t("CUSTOMER_CONVERSATION")}}</span>
            </template>
            <img
                v-if="record.social_type === 'zalo' || record.zalo_appuid  "
                draggable="false"
                src="@/assets/icons/zalo-seeklogo.com.svg"
                :style="{width: record.social_type === 'zalo' ? '25px' : '20px','margin-right': '20px',cursor: 'pointer'}"
                @click="openChat(record.page_id ? record.page_id :record.zalo_page_id, record.social_id ?record.social_id:record.zalo_appuid, record.shop_id)"

            />
          </a-tooltip>


          <img
              v-if="record.social_type === 'instagram'"
              draggable="false"
              src="@/assets/icons/instagram-svgrepo-com.svg"
              style="width: 20px;margin-right: 20px"
          />
          <img
              v-if="record.social_type === 'line' "
              draggable="false"
              src="@/assets/icons/line-svgrepo-com.svg"
              style="width: 20px;margin-right: 20px"
          />
          <img
              v-if="record.social_type === 'whatsapp'"
              draggable="false"
              src="@/assets/icons/whatsapp-svgrepo-com.svg"
              style="width: 20px;margin-right: 20px"
          />
          <a-tooltip placement="topLeft" title="cms">
          <img
              v-if="record.social_type === 'cms'"
              draggable="false"
              src="@/assets/icons/logo-one.png"
              style="width: 20px;margin-right: 20px"
          />
          </a-tooltip>
          <a-tooltip placement="topLeft" title="landingPage">
            <img
                v-if="record.social_type === 'landingPage'"
                draggable="false"
                src="@/assets/icons/web.png"
                style="width: 20px;margin-right: 20px"
            />
          </a-tooltip>
          <a-tooltip placement="topLeft" title="ticket">
          <img
              v-if="record.social_type === 'ticket'"
              draggable="false"
              src="@/assets/icons/ticket.png"
              style="width: 20px;margin-right: 20px"
          />
          </a-tooltip>
          <img
              v-if="record.social_type === 'kiotviet'"
              draggable="false"
              src="@/assets/icons/KiotViet-Logo.svg"
              style="width: 60px;margin-right: 20px"
          />
          <img
              v-if="record.social_type === 'miniapp'"
              draggable="false"
              src="@/assets/icons/mini-app.svg"
              style="width: 60px;margin-right: 20px"
          />
        </template>
        <template #birth="{ text }">
          <span>{{ dateDisplayFormatFromString(text) }}</span>
        </template>
        <template #last_contact_time="{ record }">
          <div style="font-size: 13px">{{ dateTimeDisplayFormatFromShortString(record.created_at) }}</div>
          <div style="font-size: 13px">{{ dateTimeDisplayFormatFromShortString(record.last_contact_time) }}</div>
        </template>
        <template #conversation_staff="{record}">
          <a type="text" class="button-employee-detail" @click="goToEmployeeDetail(record.conversation_staff)">
            {{ getOneEmployeeNameByStringId(record.conversation_staff) }}
          </a>
        </template>
        <template #GENDER="{text}">
          <span>{{ $t(text) }}</span>
        </template>
        <template #EMPLOYEE="{record}">
          <a type="text" class="button-employee-detail" @click="goToEmployeeDetail(record.employees)">
            {{ getOneEmployeeNameByStringId(record.employees) }}
          </a>
        </template>
        <template #conversation_tags="{ record }">
        <span v-if="record.conversation_tags">
            <a-tag
                v-for="tag in record.conversation_tags"
                :key="tag"
                :color="tag.tag_color"
            >{{ tag.tag_name }}
          </a-tag>
        </span>
        </template>
        <template #member_rank="{record}">
          {{getMemberRANK(record.member_rank)}}
        </template>
        <template #tags="{record, index}">
          <a-popover trigger="hover"
                     v-if="getMyRole(this.MODULES.customers)?.is_edit"
          >
            <template #title>
              <a-button
                  v-if="getMyRole(this.MODULES.customer_tag)?.is_create"
                  size="small" @click="handleQuickCreate('customer',record,index)"  type="primary">{{ $t('quick_create') }}</a-button>

            </template>
            <template #content>
              <a-config-provider :renderEmpty="null">
                <a-tree
                    @select="(value)=>handleChangeTagCustomer(value, record, index)"
                    show-search
                    style="width: 100%"
                    :filterTreeNode="false"
                    v-model:selectedKeys="getCustomerDataSource[index].tags_ids"
                    :dropdown-style="{ maxHeight: '300', overflow: 'auto' }"
                    allow-clear
                    multiple
                    :placeholder="$t('TAG_SELECT')"
                >
                  <a-tree-node v-for="item in getTreeTags" :key="item.id" v-bind:value="item.id">
                    <template #title>
                      <a-tag @click="tagCheck = item.id + '' " :color="item.color">
                        {{ item.name }}
                      </a-tag>
                    </template>
                    <a-tree-node v-for="child in item.children" :key="child.id" v-bind:value="child.id">
                      <template #title>
                        <a-tag @click="tagCheck = child.id + '' "  :color="child.color">
                          {{ child.name }}
                        </a-tag>
                      </template>
                    </a-tree-node>

                  </a-tree-node>
                </a-tree>
              </a-config-provider>

            </template>
            <span v-if="getCustomerDataSource[index].tags?.length>0">
                <a-tag
                    v-for="tag in getCustomerDataSource[index].tags"
                    :key="tag"
                    :color="tag.color"
                >{{ tag.name }}
              </a-tag>
            </span>
            <a v-else>
              {{ $t("SALE_TAGS") }}
              <down-outlined/>
            </a>
          </a-popover>
        </template>
        <template #last_care="{ record }">
          <span class="table-operation">
            <a-dropdown>

              <template #overlay
                        v-if="getMyRole(this.MODULES.customers)?.is_edit"
              >
                <a-menu>
                  <a-menu-item
                      v-for="care in getCustomerDetailCares"
                      :key="care.id"
                      @click="changeStatusCare([record.id], [care.id],care.color,care.name)"
                  >
                    <a-tag :color="care.color">
                      {{ care.name }}
                    </a-tag>
                  </a-menu-item
                  >
                  <a-menu-item
                      v-if="record.last_care!==0"
                      @click="changeStatusCare([record.id], [0])"
                  >
                    <delete-outlined/>
                    {{ $t('REMOVE') }}</a-menu-item
                  >
                  <a-menu-item v-if="true">
                  <a-button size="small" @click="handleQuickCreate('',[record.id],[0])"  type="primary">{{ $t('quick_create') }}</a-button>

                </a-menu-item>
                </a-menu>


              </template>
              <a-tag :color="findStatusCare(record.last_care).color" v-if="record.last_care_name != ''">
                {{ record.last_care_name }}
                <down-outlined/>
              </a-tag>
              <a v-else>
                {{ $t("SELECT_STATUS") }}
                <down-outlined/>
              </a>
            </a-dropdown>
          </span>
        </template>
        <template #hotel_tags="{ record }">
          <a-tag v-for="tag in record.hotel_tags" :key="tag.id">
            {{ tag.tag_name }}
            <span v-if="moment(tag.scheduled_at).isAfter('0001-01-01', 'year')">
              {{ moment(tag.scheduled_at).format("DD/MM/YYYY HH:mm") }}
            </span>
          </a-tag>
        </template>
        <template #action="{ record }">
          <a-dropdown>
            <eye-outlined/>
            <template #overlay>
              <a-menu>
                <a-menu-item
                    v-if="getMyRole(this.MODULES.customers)?.is_edit"
                >

                  <router-link

                      :to="'/customer-detail/'+record.id">
                    <edit-outlined/>
                    {{ $t("CUSTOMER_DETAIL_EDIT") }}
                  </router-link>
                </a-menu-item>
                <!--                  <a-menu-item>-->
                <!--                    <a href="javascript:;">{{ $t("CUSTOMER_SCHEDULE") }}</a>-->
                <!--                  </a-menu-item>-->
                <a-menu-item
                    v-if="getMyRole(this.MODULES.customers)?.is_delete"
                >
                  <a-popconfirm
                      title="Are you sure delete this customer?"
                      :ok-text="$t('YES')"
                      :cancel-text="$t('NO')"
                      @confirm="deleteCustomer(record)"
                  >
                    <delete-outlined/>
                    {{ $t("CUSTOMER_DELETE") }}
                  </a-popconfirm>
                </a-menu-item>
                <a-menu-item
                    v-if="getMyRole(this.MODULES.live_chat)?.is_visible"
                >
                  <message-outlined/>
                  <a
                      target="_blank"
                      @click="openChat(record.page_id, record.social_id, record.shop_id)"
                  >{{ $t("CUSTOMER_CONVERSATION") }}</a
                  >
                </a-menu-item>
                <a-menu-item
                    v-if="getMyRole(this.MODULES.orders)?.is_create"
                    @click="createOrder(record.id)">
                  <sisternode-outlined/>
                  <span>
                      {{ $t("ORDER_UP") }}
                    </span>
                </a-menu-item>
              </a-menu>
            </template>
          </a-dropdown>
        </template>

        <template #customerTitle>
          <span>
            {{ $t("ACTION") }}
          </span>
        </template>

        <template #expandedRowRender="{record}">
          <div>
            <div v-if="rowExpandLoading" class="spin-inside-container">
              <a-spin/>
            </div>
            <div class="extra-item-container" v-for="col in getInvisibleColumns" :key="col.id+'_'">
              <div class="extra-item">
                <span class="extra-item-label">{{ $t(col.myTitle) }}</span>
                <div class="extra-item-value extra-item-value-note" v-if="col.dataIndex==='full_name'">
                  {{ record.full_name }}
                </div>
                <div  class="extra-item-value extra-item-value-note" v-else-if="col.dataIndex==='phone'">
                  {{ record.all_phones }}
                </div>
                <div class="extra-item-value extra-item-value-note" v-else-if="col.dataIndex==='last_contact_time'">
                  {{ record.last_contact_time }}
                </div>
                <div class="extra-item-value extra-item-value-note" v-else-if="col.dataIndex==='conversation_tags'">
                  {{ record.conversation_tags }}
                </div>
                <div class="extra-item-value extra-item-value-note" v-else-if="col.dataIndex==='tags'">
                  <a-tag v-for="tag in  record.tags" :key="tag.id">
                    {{ tag.tag_name }}
                  </a-tag>
                </div>
                <div class="extra-item-value extra-item-value-note" v-else-if="col.dataIndex==='address'">
                  {{ record.address }}
                </div>
                <div class="extra-item-value extra-item-value-note" v-else-if="col.dataIndex==='birth'">
                  {{ record.birth }}
                </div>
                <div class="extra-item-value extra-item-value-note" v-else-if="col.dataIndex==='email'">
                  {{ record.email }}
                </div>
                <div class="extra-item-value extra-item-value-note" v-else-if="col.dataIndex==='hotel_tags'">
                  {{ record.hotel_tags }}
                </div>
                <div class="extra-item-value extra-item-value-note" v-else-if="col.dataIndex==='province_name'">
                  {{ record.province_name }}
                </div>
                <div class="extra-item-value extra-item-value-note" v-else-if="col.dataIndex==='district_name'">
                  {{ record.district_name }}
                </div>
                <div class="extra-item-value extra-item-value-note" v-else-if="col.dataIndex==='ward_name'">
                  {{ record.ward_name }}
                </div>
                <div class="extra-item-value extra-item-value-note" v-else-if="col.dataIndex==='last_care'">
                  {{ getCustomerCareNameById(record.last_care) }}
                </div>
                <div class="extra-item-value extra-item-value-note" v-else-if="col.dataIndex==='sex'">
                  {{ record.sex }}
                </div>
                <div class="extra-item-value extra-item-value-note" v-else-if="col.dataIndex==='conversation_staff'">
                  <a-button type="text" class="button-employee-detail" @click="goToEmployeeDetail(record.conversation_staff)">
                    {{ getOneEmployeeNameByStringId(record.conversation_staff) }}
                  </a-button>
                </div>
                <div class="extra-item-value extra-item-value-note" v-else-if="col.dataIndex==='employee'">
                  <a-button type="text" class="button-employee-detail" @click="goToEmployeeDetail(record.employees)">
                    {{ getOneEmployeeNameByStringId(record.employees) }}
                  </a-button>
                </div>
                <div v-if="record.extra_column">
                  <div class="extra-item-value extra-item-value-note" v-if="col.dataIndex=== getExtraDetail(col.dataIndex,record.extra_column).customer_column_id">
                    {{ getExtraDetail(col.dataIndex,record.extra_column).customer_column_value }}
                  </div>
                </div>

              </div>
            </div>
            <div class="action-buttons">
              <a-button @click="openChat(record.page_id,record.social_id,record.shop_id)" size="small">
                <message-outlined/>
                {{ $t("CUSTOMER_CONVERSATION") }}
              </a-button>
              <a-button  size="small" type="dashed" class="action-button-margin-left">
                <router-link
                    v-if="getMyRole(this.MODULES.customers)?.is_edit"
                    :to="'/customer-detail/'+record.id">
                  <edit-outlined/>
                  {{ $t("CUSTOMER_DETAIL_EDIT") }}
                </router-link>

              </a-button>
              <a-popconfirm
                  title="Are you sure delete this customer?"
                  :ok-text="$t('YES')"
                  :cancel-text="$t('NO')"
                  @confirm="deleteCustomer(record)"
              >
                <a-button size="small" type="danger" class="action-button-margin-left">
                  <delete-outlined/>
                  {{ $t("CUSTOMER_DELETE") }}
                </a-button>
              </a-popconfirm>

              <a-button size="small" class="action-button-margin-left" target="_blank" @click="createOrder(record.id)">
                <sisternode-outlined/>
                {{ $t("ORDER_UP") }}
              </a-button>
            </div>
            <div v-if="record.history_comments" class="timeline-comment">
              <a-timeline mode="alternate">
                <a-timeline-item v-for="comment in record.history_comments" :key="comment.id">
                  <div><span><user-outlined/> <span
                      class="user-comment">{{ getOneEmployeeById(comment.cms_user_id).name }}</span>
                    <span class="time-comment">{{ dateTimeDisplayFormatFromString(comment.created_at) }}</span></span>
                  </div>
                  <div v-if="comment.tag_id">
                    <span>
                      <tag-outlined/> <a-tag
                        :color="getOneByCustomerTagId(comment.tag_id).color">{{
                        getOneByCustomerTagId(comment.tag_id).name
                      }}</a-tag>
                    </span>
                  </div>
                  <div>
                    <span>
                      <message-outlined/> {{ comment.content }}
                    </span>
                  </div>
                </a-timeline-item>
              </a-timeline>
            </div>
          </div>
        </template>
      </a-table>
    </div>
  </div>

  <a-modal
      :title="(typeActionTag==1?$t('ADD_TAG'):$t('REMOVE_TAG'))+' '+$t('CUSTOMER')"
      v-model:visible="visible"
      :confirm-loading="confirmLoading"
      @ok="actionTags"
  >
    {{ $t('SELECTED') }}
    <a-tag color="#87d068">
      <template #icon>
        <usergroup-add-outlined/>
      </template>
      {{ selectedNumber }}
    </a-tag>
    {{ $t('CUSTOMER') }}
    <div class="form-modal">
      <h4>{{ $t("TAG_SELECT") }}</h4>
      <a-tree-select
          @change="handleChangeTagModal"
          show-search
          style="width: 100%"
          @search="searchTag"
          :filterTreeNode="false"
          v-model:searchValue="searchTagValue"
          :notFoundContent="null"
          :dropdown-style="{ maxHeight: '400px', overflow: 'auto' }"
          allow-clear
          multiple
          :placeholder="$t('TAG_SELECT')"
      >
        <a-tree-select-node v-for="item in getTreeTags" :key="item.id" v-bind:value="item.id">
          <template #title>
            <a-tag :color="item.color">
              {{ item.name }}
            </a-tag>
          </template>
          <a-tree-select-node v-for="child in item.children" :key="child.id" v-bind:value="child.id">
            <template #title>
              <a-tag :color="child.color">
                {{ child.name }}
              </a-tag>
            </template>
          </a-tree-select-node>

        </a-tree-select-node>
      </a-tree-select>
    </div>
  </a-modal>
  <a-modal
      :title="$t('ASSIGN_CUSTOMERS_EMPLOYEES')"
      v-model:visible="visibleAssignEmp"
      @ok="actionAssignEmp()"
  >
    {{ $t('SELECTED') }}
    <a-tag color="#87d068">
      <template #icon>
        <usergroup-add-outlined/>
      </template>
      {{ selectedNumber }}
    </a-tag>
    {{ $t('CUSTOMER') }}
    <div class="form-modal">
      <h4>{{ $t("STAFF_LIST") }}</h4>
      <a-select
          @change="changeEmpModal"
          mode="multiple"
          allowClear
          style="width: 100%"
          :placeholder="$t('SELECT_STAFF')"
      >
        <a-select-option
            v-for="item in getEmployee.data"
            :key="item.cms_user_id"
            v-bind:value="item.cms_user_id"
        >{{ item.name }}
        </a-select-option>
      </a-select>
    </div>
  </a-modal>
  <a-modal
      :title="$t('UPDATE_STATUS_FOR_SELECTED')"
      v-model:visible="visibleUpdateStatusAll"
      @ok="actionUpdateStatus()"
  >
    {{ $t('SELECTED') }}
    <a-tag color="#87d068">
      <template #icon>
        <usergroup-add-outlined/>
      </template>
      {{ selectedNumber }}
    </a-tag>
    {{ $t('CUSTOMER') }}
    <div class="form-modal">
      <h4>{{ $t("STATUS") }}</h4>
      <a-select
          v-model:value="selectedPrepareStatusUpdate"
          mode="single"
          allowClear
          style="width: 100%"
          :placeholder="$t('SELECT_STAFF')"
      >
        <a-select-option
            v-for="item in getCustomerDetailCares"
            :key="item.id"
            v-bind:value="item.id"
        >{{ item.name }}
        </a-select-option>
      </a-select>
    </div>
  </a-modal>
  <a-modal
      v-if="getCurrentShop?.id===121212 || getCurrentShop?.id===133059"
      v-model:visible="visibleCreateCustomer"
      :zIndex="20"
      :title="customerForm.id ? $t('CUSTOMER_UPDATE') : $t('CUSTOMER_CREATE')"
      width="98%"
      @ok="saveCustomer"
  >
    <a-form
        :model="customerForm"
        ref="formRef"
        :rules="rules"
        :label-col="{ span: 6, offset: 1 }"
        :wrapper-col="{ span: 24 }"
    >
      <a-row>
        <a-col :span="22">

          <a-row>
            <a-col :span="12">
              <a-form-item :label="$t('CUSTOMER_NAME')" name="full_name">
                <a-input
                    v-model:value="customerForm.full_name"
                    :placeholder="$t('CUSTOMER_NAME')"
                />
              </a-form-item>
            </a-col>
            <a-col :span="12">
              <a-form-item :label="$t('CUSTOMER_BIRTHDAY')" name="birth">
                <a-input
                    v-model:value="customerForm.birth"
                    :placeholder="$t('CUSTOMER_BIRTHDAY')"

                />
              </a-form-item>
            </a-col>

          </a-row>
          <a-row>
            <a-col :span="12">
              <a-form-item :label="$t('SEX')" name="sex">
                <a-radio-group name="radioGroup" v-model:value="customerForm.sex">
                  <a-radio value="male">Nam</a-radio>
                  <a-radio value="female">Nữ</a-radio>
                  <a-radio value="other">Other</a-radio>
                </a-radio-group>
              </a-form-item>
            </a-col>
            <a-col :span="12">
              <a-form-item :label="$t('CUSTOMER_PHONE')" name="phone">
                <a-input
                    v-model:value="customerForm.phone"
                    :placeholder="$t('CUSTOMER_PHONE')"
                />
              </a-form-item>
            </a-col>
          </a-row>
          <a-row>
            <a-col :span="12">
              <a-form-item label="Email" name="email">
                <a-input v-model:value="customerForm.email" placeholder="Email"/>
              </a-form-item>
            </a-col>
            <a-col :span="12">
              <a-form-item label="Social Id" name="social_id">
                <a-input
                    v-model:value="customerForm.social_id"
                    placeholder="Social Id"
                />
              </a-form-item>
            </a-col>
          </a-row>
          <a-row>
            <a-col :span="12">
              <a-form-item :label="$t('CITY')" name="province_id">
                <a-select
                    style="width: 100%"
                    allowClear
                    placeholder="Please select"
                    v-model:value="customerForm.province_id"
                    @change="provinceChange(2)"
                >
                  <a-select-option
                      v-for="item in getProvinces"
                      :key="item.id"
                      v-bind:value="item.id"
                  >{{ item.name }}
                  </a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
            <a-col :span="12">
              <a-form-item :label="$t('DISTRICT')" name="district_id">
                <a-select
                    style="width: 100%"
                    allowClear
                    placeholder="Please select"
                    v-model:value="customerForm.district_id"
                    @change="districtChange(2)"
                >
                  <a-select-option
                      v-for="item in getDistricts"
                      :key="item.id"
                  >{{ item.name }}
                  </a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
          </a-row>
          <a-row>
            <a-col :span="12">
              <a-form-item :label="$t('PROVINCE')" name="ward_id">
                <a-select
                    style="width: 100%"
                    allowClear
                    placeholder="Please select"
                    v-model:value="customerForm.ward_id"
                >
                  <a-select-option
                      v-for="item in getWards"
                      :key="item.id"
                      v-bind:value="item.id"
                  >{{ item.name }}
                  </a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
            <a-col :span="12">
              <a-form-item :label="$t('CUSTOMER_ADDRESS')" name="address">
                <a-textarea
                    v-model:value="customerForm.address"
                    :placeholder="$t('CUSTOMER_ADDRESS')"
                    :rows="3"
                />
              </a-form-item>
            </a-col>
          </a-row>
          <a-row v-if="this.getCXViewGroupCurrent">
            <a-col :span="12">
              <a-form-item :label="$t('camera_image')" name="address">
                <input type="file" ref="image" @change="uploadFile"/>
                <div v-if="customerForm.image">
                  <a-avatar :src="getURLFromFile(customerForm.image)" :size="64"/>
                </div>
              </a-form-item>
            </a-col>
          </a-row>

        </a-col>
      </a-row>
    </a-form>
  </a-modal>

  <a-modal
      v-if="getCurrentShop?.id===123971"
      v-model:visible="visibleCreateCustomer"
      :zIndex="20"
      :title="customerForm.id ? $t('CUSTOMER_UPDATE') : $t('CUSTOMER_CREATE')"
      width="98%"
      @ok="saveCustomer"
  >
    <a-form
        :model="customerForm"
        ref="formRef"
        :rules="rules"
        :label-col="{ span: 6, offset: 1 }"
        :wrapper-col="{ span: 24 }"
    >
      <a-row>
        <a-col :span="getCurrentShop?.id===123971?14:22">

          <a-row>
            <a-col :span="12">
              <a-form-item :label="$t('CUSTOMER_NAME')" name="full_name">
                <a-input
                    v-model:value="customerForm.full_name"
                    :placeholder="$t('CUSTOMER_NAME')"
                />
              </a-form-item>
            </a-col>
            <a-col :span="12">
              <a-form-item :label="$t('CUSTOMER_BIRTHDAY')" name="birth">
                <a-input
                    v-model:value="customerForm.birth"
                    :placeholder="$t('CUSTOMER_BIRTHDAY')"
                />
              </a-form-item>
            </a-col>

          </a-row>
          <a-row>
            <a-col :span="12">
              <a-form-item :label="$t('SEX')" name="sex">
                <a-radio-group name="radioGroup" v-model:value="customerForm.sex">
                  <a-radio value="male">Nam</a-radio>
                  <a-radio value="female">Nữ</a-radio>
                  <a-radio value="other">Other</a-radio>
                </a-radio-group>
              </a-form-item>
            </a-col>
            <a-col :span="12">
              <a-form-item :label="$t('CUSTOMER_PHONE')" name="phone">
                <a-input
                    v-model:value="customerForm.phone"
                    :placeholder="$t('CUSTOMER_PHONE')"
                />
              </a-form-item>
            </a-col>
          </a-row>
          <a-row>
            <a-col :span="12">
              <a-form-item label="Email" name="email">
                <a-input v-model:value="customerForm.email" placeholder="Email"/>
              </a-form-item>
            </a-col>
            <a-col :span="12">
              <a-form-item label="Social Id" name="social_id">
                <a-input
                    v-model:value="customerForm.social_id"
                    placeholder="Social Id"
                />
              </a-form-item>
            </a-col>
          </a-row>
          <a-row>
            <a-col :span="12">
              <a-form-item :label="$t('CITY')" name="province_id">
                <a-select
                    style="width: 100%"
                    allowClear
                    placeholder="Please select"
                    v-model:value="customerForm.province_id"
                    @change="provinceChange(2)"
                >
                  <a-select-option
                      v-for="item in getProvinces"
                      :key="item.id"
                      v-bind:value="item.id"
                  >{{ item.name }}
                  </a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
            <a-col :span="12">
              <a-form-item :label="$t('DISTRICT')" name="district_id">
                <a-select
                    style="width: 100%"
                    allowClear
                    placeholder="Please select"
                    v-model:value="customerForm.district_id"
                    @change="districtChange(2)"
                >
                  <a-select-option
                      v-for="item in getDistricts"
                      :key="item.id"
                  >{{ item.name }}
                  </a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
          </a-row>
          <a-row>
            <a-col :span="12">
              <a-form-item :label="$t('PROVINCE')" name="ward_id">
                <a-select
                    style="width: 100%"
                    allowClear
                    placeholder="Please select"
                    v-model:value="customerForm.ward_id"
                >
                  <a-select-option
                      v-for="item in getWards"
                      :key="item.id"
                      v-bind:value="item.id"
                  >{{ item.name }}
                  </a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
            <a-col :span="12">
              <a-form-item :label="$t('CUSTOMER_ADDRESS')" name="address">
                <a-textarea
                    v-model:value="customerForm.address"
                    :placeholder="$t('CUSTOMER_ADDRESS')"
                    :rows="3"
                />
              </a-form-item>
            </a-col>
          </a-row>
          <a-row v-if="this.getCXViewGroupCurrent">
            <a-col :span="12">
              <a-form-item :label="$t('camera_image')" name="address">
                <input type="file" ref="image" @change="uploadFile"/>
                <div v-if="customerForm.image">
                  <a-avatar :src="getURLFromFile(customerForm.image)" :size="64"/>
                </div>
              </a-form-item>
            </a-col>
          </a-row>

        </a-col>
        <a-col
            v-if="getCurrentShop?.id===123971"
            :span="4">
          <a-col>
            <iframe
                :src="
'https://erp.dominhduong.com/api2/lead?name='+ (customerForm.full_name?customerForm.full_name:'')+ '&phone='+( customerForm.phone?customerForm.phone:'')+'&email='+ (customerForm.email?customerForm.email:'')+'&x_gender='+ (customerForm.sex?customerForm.sex:'')+'&street='+ (customerForm.address?customerForm.address:'')+'&x_year_of_birth='+ customerForm.birth"
                width="100%"
                height="600"
                frameborder="0"
            >
            </iframe>
          </a-col>
        </a-col>
        <a-col
            v-if="getCurrentShop?.id===123971"
            :span="6">
          <a-col>
            <iframe
                :src="'https://erp.dominhduong.com/api2/partner/get-data/'+customerForm.phone"
                width="100%"
                height="600"
                frameborder="0"
            >
            </iframe>
          </a-col>
        </a-col>
      </a-row>
    </a-form>
  </a-modal>
  <a-modal  v-model:visible="visible2" :title="$t('CREATE')" @ok="onSubmit">
    <a-form
        :model="customer"
        ref="formCustomerRef"
        :rules="rules"
        layout="vertical"
    >
      <div class="inline-form-customer">
        <a-form-item :label="$t('CUSTOMER_NAME')" name="full_name">
          <a-input
              v-model:value="customer.full_name"
              :placeholder="$t('CUSTOMER_NAME')"


          />
        </a-form-item>

        <a-form-item :label="$t('CUSTOMER_PHONE')" name="phone">
          <a-input
              v-model:value="customer.phone"
              :placeholder="$t('CUSTOMER_PHONE')"
          />
        </a-form-item>
        <a-form-item :label="$t('CUSTOMER_BIRTHDAY')" name="birth">
          <a-date-picker
              v-model:value="customer.birth"
              :placeholder="$t('CUSTOMER_BIRTHDAY')"
              format="DD-MM-YYYY"
              style="width: 100%"
          />
        </a-form-item>
        <a-form-item :label="$t('Assigee')">
          <a-select
              style="width: 100%"
              placeholder="Please select"
              allowClear
              v-model:value="customer.employees"

          >
            <a-select-option
                v-for="item in (getEmployee.data?getEmployee.data:[])"
                :key="item.cms_user_id"
                v-bind:value="item.cms_user_id+''"
            >{{ item.name }}
            </a-select-option>
          </a-select>
        </a-form-item>
<!--        <a-form-item :label="$t('CITY')" name="province_id">-->
<!--          <a-select-->
<!--              style="width: 100%"-->
<!--              placeholder="Please select"-->
<!--              show-search-->
<!--              v-model:value="customer.province_id"-->
<!--              :loading="!provinceLoaded"-->
<!--              :options="provinces"-->
<!--              :filter-option="false"-->
<!--              :not-found-content="null"-->
<!--              @search="provinceSearch"-->
<!--              @change="provinceChange(customer.province_id)"-->
<!--          >-->
<!--          </a-select>-->
<!--        </a-form-item>-->


<!--        <a-form-item :label="$t('DISTRICT')" name="district_id">-->
<!--          <a-select-->
<!--              style="width: 100%"-->
<!--              placeholder="Please select"-->
<!--              v-model:value="customer.district_id"-->
<!--              show-search-->
<!--              :loading="!districtLoaded"-->
<!--              :options="districts"-->
<!--              :filter-option="false"-->
<!--              :not-found-content="null"-->
<!--              @search="districtSearch"-->
<!--              @change="districtChange()"-->
<!--          >-->
<!--          </a-select>-->
<!--        </a-form-item>-->

<!--        <a-form-item :label="$t('WARD')" name="ward_id">-->
<!--          <a-select-->
<!--              style="width: 100%"-->
<!--              placeholder="Please select"-->
<!--              show-search-->
<!--              :options="wards"-->
<!--              :loading="!wardLoaded"-->
<!--              :filter-option="false"-->
<!--              :not-found-content="null"-->
<!--              @search="wardSearch"-->
<!--              v-model:value="customer.ward_id"-->
<!--          >-->
<!--          </a-select>-->
<!--        </a-form-item>-->
        <a-form-item :label="$t('CUSTOMER_ADDRESS')" name="address">
          <a-textarea
              v-model:value="customer.address"
              :placeholder="$t('CUSTOMER_ADDRESS')"
              :rows="3"
          />
        </a-form-item>
        <a-form-item :label="$t('CARE_STATUS')" name="last_care">
          <a-select
              style="width: 100%"
              allowClear
              placeholder="Please select"
              v-model:value="customer.last_care"
          >
            <a-select-option
                v-for="item in getCustomerCares"
                :key="item.id"
                v-bind:value="item.id"

            >
              <a-tag :color="item.color">
                {{ item.name }}
              </a-tag>
            </a-select-option>
          </a-select>
        </a-form-item>
        <a-config-provider :renderEmpty="null">
          <a-form-item :label="$t('TAG')" name="tag">
            <a-tree-select
                @change="handleChangeTag2"
                show-search
                style="width: 100%"
                v-model:value="tagIds"
                :filterTreeNode="false"
                :notFoundContent="null"
                :dropdown-style="{ maxHeight: '300', overflow: 'auto' }"
                allow-clear
                multiple
                :placeholder="$t('TAG_SELECT')"
            >
              <a-tree-select-node v-for="item in getTreeTags" :key="item.id" v-bind:value="item.id">
                <template #title>
                  <a-tag :color="item.color">
                    {{ item.name }}
                  </a-tag>
                </template>
                <a-tree-select-node v-for="child in item.children" :key="child.id" v-bind:value="child.id">
                  <template #title>
                    <a-tag :color="child.color">
                      {{ child.name }}
                    </a-tag>
                  </template>
                </a-tree-select-node>

              </a-tree-select-node>
            </a-tree-select>
          </a-form-item>
        </a-config-provider>
      </div>
    </a-form>
  </a-modal>

  <add-face-selection
      ref="addFaceModal"
      :customers="listCustomerDetected"
      :createCustomer="newCustomerCXView"
      :subscribeCXView="subscribeCXView"
      :showCustomerDetail="showCustomerDetail"
  >
  </add-face-selection>

  <ColumnsVisibilityOptionModal ref="columnsVisibilityModal"/>
  <a-modal
      :title="$t('ADD_NEW')"
      v-model:visible="showQuickTag"
      :confirm-loading="confirmLoading"
      width="40%"
      @ok="saveTag"
  >
    <a-form :model="tagForm" ref="formRef" :rules="rules" :label-col="{span: 6,offset:0}" :wrapper-col="{ span: 16 }">
      <a-form-item :label="$t('TAG_TAG_NAME')" name="name">
        <a-input v-model:value="tagForm.name"/>
      </a-form-item>
      <a-form-item :label="$t('TAG_COLOR')" name="color">
        <div style="display: flex;">
          <a-button
              @click="togglePicker"
              :style="{'background-color':tagForm.color?tagForm.color:'white',color:tagForm.color?'white':'black','margin-right':'15px'}">
            Click
          </a-button>
          <ColorPicker theme="light" :color="tagForm.color" :sucker-hide="false" v-if="colorPickerVisible"
                       :sucker-canvas="suckerCanvas" :sucker-area="suckerArea" @changeColor="changeColor"
                       @openSucker="openSucker"
          />
        </div>
      </a-form-item>
      <a-form-item name="description" :label="$t('TAG_DESCRIPTION')">
        <a-textarea v-model:value="tagForm.description" placeholder="Description" :rows="4"/>
      </a-form-item>
    </a-form>

  </a-modal>
</template>

<script lang="ts">
import {Options, Vue} from "vue-class-component";
import {computed, ref} from "vue";
import {ColumnProps} from "ant-design-vue/es/table";
import {ColorPicker} from 'vue-color-kit'
import './vue-color-kit.css'
import {
  DeleteOutlined,
  DownOutlined,
  EditOutlined,
  EllipsisOutlined,
  EyeOutlined,
  FileExcelOutlined,
  FilterOutlined,
  PlusOutlined,
  MessageOutlined,
  SisternodeOutlined,
  TableOutlined,
  CameraOutlined, UserOutlined, TagOutlined,
  UsergroupAddOutlined,
  CloseOutlined,
  CloudDownloadOutlined,
  CloudUploadOutlined,
  RetweetOutlined,

} from "@ant-design/icons-vue";
import {mapActions, mapGetters, mapState, mapMutations} from "vuex";
import {CustomersActionTypes} from "@/store/modules/customer/action-types";
import {TagsActionTypes} from "@/store/modules/tag/action-types";
import {CustomerRequest} from "@/APIs/request/CustomerRequest";
import APIs from "@/APIs/APIs";
import {message, Modal} from "ant-design-vue";
import RouterEndpoint from "@/router/RouterEndpoint";
import {Moment} from "moment";
import moment from "moment";
import {DictionaryActionTypes} from "@/store/modules/dictionary/action-types";
import {
  Customer, CustomerActivityType,
  CustomerForm, CustomersForm,
  UpdateLastCare,
} from "@/models/Customers/Customers";
import {ValidateErrorEntity} from "ant-design-vue/es/form/interface";
import {Tag, TypeTag} from "@/models/Tags/Tags";
import {CustomerCaresActionTypes} from "@/store/modules/customerCare/action-types";
import {Constant} from "@/utils/Constants";
import ColumnsVisibilityOptionModal from "@/components/customer/ColumnsVisibilityOptionModal.vue";
import {MixUtils, MSocketIo} from "@/utils/Utils";
import AddFaceComponent from "@/components/camera/AddFaceComponent.vue";
import FaceDetectComponent from "@/components/camera/FaceDetectComponent.vue";
import {CXViewCreateCustomerRes} from "@/models/CXView/CXViewModels";
import {ApiResponse} from "apisauce";
import {CustomerResponse, S_HttpResponse, TreeTagsResponse} from "@/APIs/response/S_HttpResponse";
import {CustomersMutationTypes} from "@/store/modules/customer/mutation-types";
import _ from 'lodash'
import {useI18n} from "vue-i18n";
import {PaginationProps} from "ant-design-vue/es/pagination";
import dayjs from 'dayjs';
import type {Dayjs} from 'dayjs';
import router from "@/router";
import i18n from "@/i18n";
import {EmployeeActionTypes} from "@/store/modules/employee/action-types";
import {SelectionAnt} from "@/models/ShopPages/ConfigData";
import Templates from "@/views/ticket/Templates.vue";

type RangeValue = [Dayjs, Dayjs];

type Key = ColumnProps["key"];

let fetchTimeout: number;
let searchTreeTimeout: number;

//Modal

const rules = {
  full_name: [
    {required: true, message: "Please input customer name", trigger: "blur"},
  ],
};
const customerForm: CustomersForm = {
  selectedEmployees: [],
  selectedConversation_staff: [],
  selectedTags: [],
  tags: [],
  image: undefined,
  note: undefined,
  cxview_token: undefined,
};
const updateLastCare: UpdateLastCare = {};
const customerRequest: CustomerRequest = {page: 1, with_phone: 1};
let form: CustomerForm = {selectedTags: [], conversationTags: []}

@Options({
  name: "customers",
  components: {
    Templates,
    FileExcelOutlined,
    PlusOutlined,
    FilterOutlined,
    DownOutlined,
    EllipsisOutlined,
    EyeOutlined,
    DeleteOutlined,
    EditOutlined,
    MessageOutlined,
    SisternodeOutlined,
    TableOutlined,
    UserOutlined,
    UsergroupAddOutlined,
    TagOutlined,
    CameraOutlined,
    CloseOutlined,
    CloudDownloadOutlined,
    ColumnsVisibilityOptionModal,
    CloudUploadOutlined,
    RetweetOutlined,
    "add-face-selection": AddFaceComponent,
    "face-detect-selection": FaceDetectComponent,
    ColorPicker
  },
  computed: {
    ...mapGetters({
      getCustomerData: "getCustomerData",
      getMemberRankData:"getMemberRankData",
      getCustomers: "getCustomers",
      getTreeTags: "getTreeTags",
      getPages: "getPages",
      getCustomerDetailCares: "getCustomerDetailCares",
      getListEmployee: "getListEmployee",
      getProvinces: "getProvinces",
      getDistricts: "getDistricts",
      getWards: "getWards",
      getVisibleColumns: "getVisibleColumns",
      getCXView: "getCXView",
      getCXViewGroupCurrent: "getCXViewGroupCurrent",
      userInfo: "getUserInfo",
      lang: "getLang",
      getCustomerCares:"getCustomerCares",
      getColumns: "getColumns",
      getCurrentShop: "getCurrentShop",
      getEmployee: "getEmployees",
      getDefaultColumns: "getDefaultColumns",
      getInvisibleColumns: "getInvisibleColumns",
      getOneEmployeeById: "getOneEmployeeById",
      getOneByCustomerTagId: "getOneByCustomerTagId",
      getConversationTagById: "getConversationTagById",
      getCustomerCareNameById: "getCustomerCareNameById",
      getCustomerCareById: "getCustomerCareById",
      getBookingTags: "getBookingTags",
      getRole: "getRole",
      getOverview:"getOverview",
      getCustomerConfig:"getCustomerConfig",
      careers:"getCareers",
    }),
    getColumnsFilters() {
      const columns = this.getVisibleColumns;
      // console.log(columns,'CUSTOMER_SOURCES')
      for (let i = 0; i < columns.length; i++) {
        if (columns[i].dataIndex === 'full_name') {
          columns[i].filters = [
            {
              text: this.i18n.t('HAS_PHONE_NUMBER'),
              value: '1',
            },
            {
              text: this.i18n.t('none_phone'),
              value: '-1',
            },
          ]
          // columns[i].defaultFilteredValue = ['1']
          columns[i].filterMultiple = false
        }
        if (columns[i].dataIndex === 'social_type') {
         if(columns[i].slots.customRender !== "sources"){
           columns[i].slots.customRender = "sources"
         }
          columns[i].filters = [
            {
              text: 'Facebook',
              value: 'facebook',
            },
            {
              text: 'Instagram',
              value: 'instagram',
            },
            {
              text: 'Zalo',
              value: 'zalo',
            },
            {
              text: 'Line',
              value: 'line',
            },
            {
              text: 'CMS',
              value: 'cms',
            },
            {
              text: 'WhatsApp',
              value: 'whatsapp',
            },
            {
              text: 'Ticket',
              value: 'ticket',
            },
            {
              text: 'Kiot Viet',
              value: 'kiotviet',
            },
            {
              text: 'LandingPage',
              value: 'landingPage',
            },
            {
              text: 'Mini App',
              value: 'miniapp',
            },
          ]
          columns[i].filterMultiple = true
        }
      }
      return columns
    },


    getCustomerDataSource() {
      if (!this.getCustomerData.customers) {
        return [];
      }
      // return this.getCustomerData.customers;
      return this.getCustomerData.customers.map((customer: any) => {
        // console.log(customer,'cus')
        try {
          if (customer && customer.conversation_tags) {
            const tags = customer.conversation_tags

                ? customer.conversation_tags.split(",")
                : [];
            customer.conversation_tags = tags.map((tag_id: any) => {
              try {
                const page = this.getCurrentShop
                    .pages.find((page: any) => page.page_id === customer.page_id);
                if (page && page.config_data) {
                  const configData = JSON.parse(page.config_data);
                  if (configData && configData.tags) {
                    return JSON.parse(page.config_data)?.tags.find(
                        (tag: any) => tag.tag_id == tag_id
                    );
                  }
                }
              } catch (e) {
                console.log('');
              }
            });
            customer.conversation_tags = customer.conversation_tags.filter(
                (t: any) => t !== undefined
            );
          }
          // if(customer && customer.extra_column){
          //  customer.extra_column.map((item:any)=>{
          //   const check = this.getCustomerConfig?.find((i:any)=>item.id === i.customer_column_id)
          //    if(check){
          //      customer.customer_column_value = item.customer_column_value
          //      console.log( customer.customer_column_value,'101')
          //    }
          //
          // })}
        } catch (e) {
          console.log('');
        }
        try{
          customer.tags_ids =  customer.tags?customer.tags.map((t: any)=> {
            if(t.id){
              return t.id
            }
            return t
          }):[];
        }catch (e) {
          console.log('');
        }
        try{
         if( customer.extra_column !== null){
           if(customer.extra_column.length > 0){
             customer.extra_column.map((item:any)=>{
                   customer[item.customer_column_id] = item.customer_column_value
                 }
             )
           }

         }
        }catch (e){
          console.log(e)
        }

        // const newCustomer = this.getCustomerDetailCares?.map((i:any)=> {
        //   if(customer.last_care === ''){
        //     return customer
        //   } else if(customer.last_care === i.id){
        //     customer.color = i.color
        //   }
        //   return customer
        // })
        // console.log(newCustomer,'10')
        //
        // return newCustomer[0];

        return customer;
      });
    },

    pagination() {
      return {
        current: this.$route.query.page ? parseInt(this.$route.query.page) : 1,
        pageSize: this.$route.query.page_size
            ? parseInt(this.$route.query.page_size)
            : 20,
        defaultPageSize: 20,
        total:
            this.getCustomerData.metadata &&
            this.getCustomerData.metadata.record_total
                ? this.getCustomerData.metadata.record_total
                : 0,
        pageSizeOptions: ["20", "40", "80", "120"],
        showSizeChanger: true,
      };
    },
    paginationExport() {
      if (
          this.getCustomerData.metadata &&
          this.getCustomerData.metadata.record_total
      ) {
        const options = [];
        for (
            let i = 0;
            i < this.getCustomerData.metadata.record_total;
            i += 1000
        ) {
          options.push(options.length + 1);
        }
        return options;
      } else {
        return [];
      }
    },
  },
  data() {

    return {
      colorTag: '',
      filterExpanded: false,
      location: location,
      customerForm,
      isExpandFilter: false,
      selectedKeys: ref<string[]>(["1"]),
      dateRange: ref<Moment[]>(),
      dateLastRange: ref<Moment[]>(),
      visible: false,
      typeActionTag: 0,
      confirmLoading: ref<boolean>(false),
      rowExpandLoading: false,
      checked: ref<boolean>(false),
      arrCustomer: [],
      selectedNumber: 0,
      selectTags: [],
      selectEmps: [],
      customerRequest,
      visibleCreateCustomer: false,
      confirmCustomerLoading: false,
      visibleAssignEmp: false,
      visibleUpdateStatusAll: false,
      selectedPrepareStatusUpdate: 0,
      confirmAssignEmp: false,
      rules,
      updateLastCare,
      visibleExport: false,
      visibleImport: false,
      importLoading: false,
      confirmExport: false,
      visibleSelectionCol: false,
      confirmSelectionCol: false,
      sale_staff: [],
      selectedTags: [],
      customerTags: [],
      noteHistories: [],
      chatTags: [],
      bookingTags: [],
      customer_care_staff: [],
      numberCol: 8,
      showQuickTag: false,
      type: '',
      headers: {
        authorization: "authorization-text",
      },
      positionEnable: -1,
      pageExport: 1,
      listCustomerDetected: [],
      schedule: ref<Moment[]>([]),
      expandedRowKeys: [],
      countQuery: 0,
      searchTagLoading: false,
      searchTagValue: '',
      customer: {...form},
      visible2:false,
      submitLoading:false,
      MODULES: Constant.MODULES,
      tagForm: {},
      childrenLoading: false,
      childrenTags: [],
      tagReq: {
        page: 1,
        page_size: 20,
        search: "",
      },
      colorPickerVisible: false,
      suckerCanvas: null,
      suckerArea: [],
      data: {},
      index: 0,
      checkEtraColumn: false,
      visible1:false,
      visible3:false,
      extraColumId: undefined,
      extraColumType:'',
      tagCheck:"",
       provinces:[],
     originalProvinces:  [],
     districts: [],
    originalDistricts: [],
    wards: [],
     originalWars: [],
      tagIds:[]
    };
  },
  created() {
    this.customerRequest = this.$route.query;
    this.selectedTags = this.customerRequest.tags
        ? this.customerRequest.tags.split(",")
        : [];
    this.chatTags = this.customerRequest.chat_tags
        ? this.customerRequest.chat_tags.split(",")
        : [];
    if (this.selectedTags && this.selectedTags.length > 0) {
      this.selectedTags = this.selectedTags.map((x: string) => {
        return x + "";
      });
    }
    // this.customerRequest.with_phone = 1
    this.i18n = useI18n();
    // if (this.$route.query.staff_id){
    //   customerRequest.staff_id=customerRequest.staff_id+''
    // }
    // if (typeof customerRequest.conversation_staff ==='string'){
    //   customerRequest.conversation_staff=parseInt(customerRequest.conversation_staff)
    // }
    for (let i = 0; i < this.getColumns.length; i++) {
      if (this.getColumns[i].id === 99 && this.getColumns[i].visible === true) {
        this.customerRequest.hotel_tag = true;
        break;
      } else {
        this.customerRequest.hotel_tag = false;
      }
    }
    for (let i = 0; i < this.getColumns.length; i++) {
      if( !this.checkEtraColumn){
        for (let j = 0; j < this.getCustomerConfig?.length; j++){
          if (this.getColumns[i].dataIndex === this.getCustomerConfig[j].id && this.getColumns[i].visible === true) {
            this.checkEtraColumn = true
            this.customerRequest.extra_column = true;
            break
          } else {
            this.customerRequest.extra_column = true;
          }

        }

      }else {
        break
      }

    }
    this.countQueryEx()
    this.getCareers({})
    this.fetchEmployee({ page: 1,
      page_size: 999,})
    this.loadCustomer();
    this.fetchTreTags({payload: {type: "customer"}});
    this.fetchCustomerDetailCares();
    this.fetchProvinces();
    if (this.getCXViewGroupCurrent) {
      this.subscribeCXView(this.getCXViewGroupCurrent);
    }
    const columns = this.getColumns;
    const defaultColumns = this.getDefaultColumns;
    const extraColumn = this.getCustomerConfig
    if (columns.length !== (defaultColumns.length +extraColumn?.length)) {
      this.setColumns(defaultColumns)
      this.setCustomerColumns(extraColumn)
      this.reformatColumn()
    }


  },
  methods: {
    ...mapMutations({
      setColumns: CustomersMutationTypes.SET_Columns,
      setCustomerHistory: CustomersMutationTypes.SET_CUSTOMER_HISTORY_COMMENT,
      setCustomer: CustomersMutationTypes.SET_CUSTOMER,
      setCustomerColumns: CustomersMutationTypes.SET_CUSTOMER_CONFIG,
      reformatColumn: CustomersMutationTypes.REFORMAT_COLUMNS,
    }),
    ...mapActions({
      fetchCustomer: CustomersActionTypes.FETCH_CUSTOMERS,
      fetchTreTags: TagsActionTypes.FETCH_TREE_TAGS,
      fetchCustomerDetailCares: CustomerCaresActionTypes.FETCH_CUSTOMERS_DETAIL_CARES,
      fetchProvinces: DictionaryActionTypes.FETCH_PROVINCES,
      fetchDistricts: DictionaryActionTypes.FETCH_DISTRICTS,
      fetchWards: DictionaryActionTypes.FETCH_WARDS,
      getCareers: CustomersActionTypes.FETCH_CAREER,
      fetchEmployee: EmployeeActionTypes.FETCH_EMPLOYEE,
      fetchTag: TagsActionTypes.FETCH_ROOT_TAGS,
      fetchCustomerCares:CustomerCaresActionTypes.FETCH_CUSTOMERS_CARES,
    }),
    // async  fetchCustomers ()  {
    // const res =  await  APIs.init().getCustomers({hotel_tag:false})
    //   console.log(res,'6666')
    // },
   getCareer  (id: any) {
      console.log(this.careers,'dấds')
      const career = ref<any>([])
      if(id){
        this.careers.filter((i:any)=>{
          if(i.id == id){
            career.value.push(i)
          }
        })
      }
      return career.value.length ? career.value[0].name :''
    },
    getURLFromFile(file: File) {
      return URL.createObjectURL(file)
    },
    showCXVIEW() {
      this.$refs.addFaceModal.showModal();
    },
    getMemberRANK (id:any){
      let rankNAME =''
      this.getMemberRankData.memberRank.map((item:any)=>{
        if(item.id === id){
          rankNAME = item.rank_name
        }
      })
      return rankNAME
    },
    getMyRole(key: string) {
      if(this.userInfo.id_cms_privileges<11){
        return {
          is_visible: 1,
          is_create:1,
          is_read: 1,
          is_edit: 1,
          is_delete: 1,
          id: 1
        }
      }else{
        return this.getRole(key);
      }
    },
     handleChangeTag2(){

      if (this.tagIds){
        this.customer.tags=this.tagIds.map((tagId:any):Tag=>{
          return {
            id:tagId,
            name:''
          }
        })
      }
    },
    clearFilter() {
      this.customerRequest = {
        page: 1,
        page_size: 20,
        extra_column:true,
      }
      this.replaceQuery(true)
      this.replaceLoadCustomer()
    },
    FormatDate(str:any){
      return  dayjs(str).format("DD/MM/YYYY HH:mm")
    },
    error (data: any,str:string) {
      const string= this.$t('BIG7')
          Modal.error({
        title: str === 'The phone number is existed' ? this.$t('THE_PHONE_NUMBER_IS_EXISTED') : str,
        okText(){

          return string
        },
        onOk() {
          router.push({name:RouterEndpoint.CUSTOMER_EDIT_DETAIL.name,params:{id:data+''}})
        },
            okCancel :true

      });
    },

     saveTag ()  {
      this.tagForm.type = this.type
      if(this.tagForm.type === 'customer'){
        APIs.init().createTag(this.tagForm).then(res => {
          this.confirmLoading = false
          if (res.status === 200 && res.data?.status === 200) {
            this.handleChangeTagCustomer([...this.data.tags_ids, res.data.data.id],this.data,this.index)
            this.showQuickTag = false
            this.fetchTreTags({
              payload: {type: "customer", search: ''}, callback: (res: TreeTagsResponse) => {
                this.searchTagLoading = false
              }
            })
            message.success({content: 'Add tags success'});
               this.loadCustomer();
            if (this.tagForm.parent_id) {
              this.fetchChildrenTags(this.tagForm.parent_id)
            } else {
              this.fetchRootTag()
            }
          } else {
            message.destroy()
          }
        }).catch(e => {
          this.confirmLoading = false
          message.destroy()
          console.error(e)
        })
      }else {
        APIs.init().createCustomerCares(this.tagForm).then(res => {
          this.confirmLoading = false
          if (res.status === 200 && res.data?.status === 200) {

            this.showQuickTag= false
            // @ts-ignore
            this.changeStatusCare(this.data,[res.data?.data.id],res.data.data.color)
            this.fetchCustomerCares();
            this.fetchCustomerDetailCares();
            message.success({content: 'Add status cares success'});
            this.loadCustomer();
          } else {
            message.destroy()
          }
        }).catch(e => {
          this.confirmLoading = false
          message.destroy()
          console.error(e)
        })
      }
    },
     fetchChildrenTags (id: number)  {
      if(id){
        APIs.init().getChildrenTags({parent_id: id, type: TypeTag.CUSTOMER_TAGS, page: 1, page_size: 60})
            .then(res => {
              this.childrenLoading = false
              if (res.ok && res.data && res.data.data) {
                this.childrenTags = res.data.data
              }
            }).catch((e) => {
          this.childrenLoading = false
          console.error("getChildrenTags", e)
        })
      }

    },
     fetchRootTag  (isRefresh?: boolean) {
      if (isRefresh) {
        this.tagReq.page = 1
        this.tagReq.page_size = 20
      }
      this.fetchTag({
        type: this.type,
        page: this.tagReq.page,
        page_size: this.tagReq.page_size,
        search: this.tagReq.search
      })


    },
    togglePicker()  {
      this.colorPickerVisible = !this.colorPickerVisible;
    },
    openSucker (isOpen: boolean)  {
      if (isOpen) {
        // ... canvas be created
        // this.suckerCanvas = canvas
        // this.suckerArea = [x1, y1, x2, y2]
      } else {
        // this.suckerCanvas && this.suckerCanvas.remove
      }
    },
    changeColor (color: any)  {
      const {r, g, b, a} = color.rgba
      this.tagForm.color = color.hex;
      this.colorPickerVisible = false
    },
    async  MergeCustomer  () {
      const res = await APIs.init().MergeCustomer({shop_id:this.getCurrentShop.id})
      if(res.data?.status === 200){
        message.success(this.$t('SUCCESS'))
      }
    },
    showCustomerDetail(customer: Customer) {
      customer.province_id = String(customer.province_id)
      customer.district_id = String(customer.district_id)
      this.customerForm = {...customer};
      this.customerForm.birth = dayjs(customer.birth).format('YYYY')
      this.customerForm.image = undefined;
      this.visibleCreateCustomer = true;
      if (customer.province_id !== '') {
        this.fetchDistricts({payload: {province_id: customer.province_id}});
      }
      if (customer.district_id !== '') {
        this.fetchWards({payload: {district_id: customer.district_id}});
      }
    },
    handlerExpand(expanded: boolean, record: Customer) {
      if (expanded) {
        this.expandedRowKeys = [record.id]
        this.rowExpandLoading = true;
        APIs.init().getShopCustomerNote({shop_customer_id: record.id}).then(response => {
          this.rowExpandLoading = false;
          if (response.status === 200 && response.data?.data) {
            this.setCustomerHistory(response.data?.data)
          }
        }).catch((e) => {
          console.error("e", e)
          this.rowExpandLoading = false;
        })
      } else {
        this.expandedRowKeys = []
      }
    },
    searchTag(val: string) {
      this.searchTagLoading = true
      if (searchTreeTimeout) {
        clearTimeout(searchTreeTimeout)
      }
      searchTreeTimeout = window.setTimeout(() => {
        this.fetchTreTags({
          payload: {type: "customer", search: val}, callback: (res: TreeTagsResponse) => {
            this.searchTagLoading = false
          }
        })
      }, 1100)

    },
    splitString(str: string): string[] {
      try {
        if (str) {
          return str.split(",")
        }
      } catch (e) {
        return []
      }
      return []
    },
    showModal(type: number) {
      this.typeActionTag = type;
      this.visible = true;
    },
    uploadFile(e: any) {
      var files = e.target.files || e.dataTransfer.files;
      if (!files.length) return;
      this.customerForm.image = this.$refs.image.files[0];
      //this.createImage(files[0]);
    },
    async testView() {
      const data = {
        "id": "547f5703-104b-4bee-bc43-1b25b35b7013",
        "timestamp": 1659060289,
        "emotion": "",
        "gender": "male",
        "age": 26,
        "image": "https://s3.hcm01.cxview.ai/product-customer-image/8c16fed8-bed0-4209-9edd-498a4a6a6e7f.jpg?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=9027013B821C4614B4B65608762D89B7%2F20220729%2Fus-east-1%2Fs3%2Faws4_request&X-Amz-Date=20220729T020451Z&X-Amz-Expires=43200&X-Amz-SignedHeaders=host&X-Amz-Signature=ab241e6792db2a442cdb32a31ca03162339343369c82764bd871f1f3b2ed581f",
        "groupID": "a621e6b5-24d6-4260-b64b-c6b44981cbcc",
        "groupName": "shopF1 - 142B Đê La Thành Nhỏ"
      }
      if (data.id) {
        const res = await APIs.init().getCXViewCustomer({cxview_id: data.id});
        if (res.status == 200 && res.data) {
          this.listCustomerDetected.unshift({...data, ...res.data.data});
        } else {
          this.listCustomerDetected.unshift(data);
        }
      } else {
        this.listCustomerDetected.unshift(data);
      }
      this.$refs.addFaceModal.showModal();
    },
    async subscribeCXView(id: string) {
      MSocketIo.getInstance().socket.on(id, async (data) => {
        // if(!this.$refs.addFaceModal.visible)
        //   this.$refs.addFaceModal.visible = true;
        if (data.id) {
          const res = await APIs.init().getCXViewCustomer({cxview_id: data.id});
          if (res.status == 200 && res.data) {
            this.listCustomerDetected.unshift({...data, ...res.data.data});
          } else {
            this.listCustomerDetected.unshift(data);
          }
        } else {
          this.listCustomerDetected.unshift(data);
        }
        this.$refs.addFaceModal.showModal();
      });
    },
    getOneEmployeeNameByStringId(str: string) {
      if (str) {
        str = str.replaceAll("[", "")
        str = str.replaceAll("]", "")
        const arr = str.split(",")
        if (arr.length > 0) {
          if (arr[0]) {
            return this.getOneEmployeeById(parseInt(arr[0])).name
          }
        }
      }
      return ""

    },
    loadCustomer() {
      // if (fetchTimeout) {
      //   clearTimeout(fetchTimeout);
      // }
      // fetchTimeout = window.setTimeout(() => {
      setTimeout(()=> {
        this.fetchCustomer(this.$route.query);
      },500)
      // }, 0);
    },
    countQueryEx() {
      let count = 0
      if (this.customerRequest.chat_tags) {
        count++

      }
      if (this.customerRequest.conversation_staff) {
        count++

      }
      if (this.customerRequest.district_id) {
        count++

      }
      if (this.customerRequest.province_id) {
        count++

      }
      if (this.customerRequest.ward_id) {
        count++

      }
      if (this.customerRequest.first_interact_end_date && this.customerRequest.first_interact_start_date) {
        count++

      }
      if (this.customerRequest.last_interact_start_date && this.customerRequest.last_interact_end_date) {
        count++

      }
      if (this.customerRequest.last_care && this.customerRequest.last_care) {
        count++

      }
      if (this.customerRequest.hotel_tag_ids) {

        count++
      }
      if (this.customerRequest.hotel_tag_schedule_from && this.customerRequest.hotel_tag_schedule_to) {
        count++

      }
      if (this.customerRequest.with_phone) {
        count++

      }
      if (this.customerRequest.orders_gt) {
        count++

      }
      if (this.customerRequest.orders_lt) {
        count++

      }
      if (this.customerRequest.sent_order_gt) {
        count++

      }
      if (this.customerRequest.sent_order_lt) {
        count++

      }
      if (this.customerRequest.success_order_gt) {
        count++

      }
      if (this.customerRequest.success_order_lt) {
        count++

      }
      if (this.customerRequest.search) {
        count++

      }
      if (this.customerRequest.staff_id) {
        count++

      }
      if (this.customerRequest.social_type) {
        count++

      }
      this.countQuery = count

    },
    replaceQuery(isRefresh?: boolean) {
      if (isRefresh) {
        this.customerRequest.page = 1;
      }
      this.countQueryEx()
      this.$router.replace({
        replace: true,
        force: true,
        name: RouterEndpoint.CUSTOMER_MANAGEMENT.name,
        query: this.customerRequest,
      });
    },
     handleQuickCreate (str: string,data:any,index:number)  {
      this.type = str
       this.data = data
       this.index = index
      this.showQuickTag = true
    },
    replaceLoadCustomer() {
      this.replaceQuery(true);

        this.loadCustomer();


    },
    handleChangeTag(value: string[]) {
      // console.log(`${value}`);
      this.customerRequest.tags = `${value.join(",")}`;
      this.replaceQuery(true);
      this.loadCustomer();
    },
    handleChangeTagCustomer(value: number[], record: any, index: number) {
      let Tag =  this.getTreeTags.map((item:any) => {
        if(value.includes(item.id)){
          return item.name
        }
        let n = []
        n = item.children.map((i:any) => {
          if(value.includes(i.id)){
            return i.name
          }
        })
        n = n.filter((item:any) => item !== undefined)
        return n

      })

      Tag = Tag.filter((item:any) => item !== undefined && item.length > 0)
      this.createHistoryChange('TAG',Tag,record.id,value)

      if(this.getCustomerDataSource[index].tags_ids.includes(Number(this.tagCheck))){
       try{
         // APIs.init().triggerSendUpdateToOneBySaleTag({tag_id: this.tagCheck ,social_id:record.social_id,customer_id:!record.social_id ? record.id : 0})
         APIs.init().triggerSendUpdateToOneBySaleTag2({tag_id: this.tagCheck ,social_id:record.social_id,customer_id:!record.social_id ? record.id : 0})

       }catch (err){
         console.log(err)
       }

     }

      APIs.init()
          .updateCustomer({
            id: record.id,
            full_name: record.full_name,
            tags: value.map(t=>({id: t})),
            shop_id: this.getCurrentShop.id
          })
          .then((res) => {
            if (res.status === 200 && res.data?.status === 200) {
              let tags: any[] = []
              this.getTreeTags.map((parent: any)=>{
                if(this.getCustomerDataSource[index].tags_ids.includes(parent.id)){
                  tags.push(parent)
                }
                parent.children.map((child: any)=>{
                  if(this.getCustomerDataSource[index].tags_ids.includes(child.id)){
                    tags.push(child)
                  }
                })
              })
              this.getCustomerDataSource[index].tags = tags

              message.success({content: "Edit customer success"});
            } else {
              message.destroy();
            }
          })
          .catch((e) => {
            message.destroy();
            console.error(e);
          });
    },
    handleChangeChatTag(value: string[]) {
      this.customerRequest.chat_tags = `${value.join(",")}`;
      this.replaceQuery(true);
      this.loadCustomer();
    },
    copy(phone:any){
      // console.log(phone)
      // const shopId = String(this.getCurrentShop.id)
      // const shopName = String(this.getCurrentShop.shop_name)
      navigator.clipboard.writeText(phone)
      message.success(phone + ' ' + this.$t('SUCCESS'))
    },
   createHistoryChange  (str:string,value:any,currentCustomer:any,tagid?:any){
      APIs.init()
          .addShopCustomerNote({
            content: tagid ? 'thay đổi thẻ sale' : `Thay đổi trường ${this.$t(str)} thành ${value}`,
            type:CustomerActivityType.NOTE,
            customer_id:currentCustomer,
            tag_ids: tagid ? tagid :[]
          })
          .then((res) => {
            if (res.status === 200 && res.data?.status === 200) {
             console.log(res)
            }
            // delete commentForm.comment
            // delete commentForm.tagId
            // buttonSubmitCommentLoading.value = false
            //
            // addCommentVisible.value = false
          })
          .catch((e) => {
            console.error(e);
            // buttonSubmitCommentLoading.value = false
            // addCommentVisible.value = false
          });
    },
    goToEmployeeDetail(value: string) {
      this.$router.push({name: RouterEndpoint.EMPLOYEEDETAIL.name, params: {userid: value}})
    },
    handleChangeBookingTag(value: string[]) {
      this.customerRequest.hotel_tag_ids = `${value.join(",")}`;
      this.replaceQuery(true);
      this.loadCustomer();
    },
    onChangeKey(selectedRowKeys: Key[]) {
      //console.log(`selectedRowKeys: ${selectedRowKeys}`);
      this.arrCustomer = selectedRowKeys;
      this.selectedNumber = selectedRowKeys.length
      // console.log(this.selectedNumber,'selectedNumber')
      //console.log(this.arrCustomer);
      this.checked = selectedRowKeys && selectedRowKeys.length > 0;
    },
    onSelectAll(selected: boolean) {
      if (selected && this.getCustomerData && this.getCustomerData.metadata) {
        this.selectedNumber = this.getCustomerData.metadata.record_total
      }
    },
    getTokenPage(id:any){
      let token = []
     token = this.getOverview.filter((item:any) => item.page_id === id + '')

   return token && token[0] && token[0].page_token
    },
    handleChangeTagModal(value: number[]) {
      this.selectTags = value;
    },
    changeEmpModal(value: number[]) {
      this.selectEmps = value;
    },
    actionTags() {
      //console.log(this.selectTags);
      this.confirmLoading = true;
      if (this.typeActionTag == 1) {
        if (this.selectedNumber && this.getCustomerData && this.getCustomerData.metadata && this.getCustomerData.metadata.page_size && this.selectedNumber > this.getCustomerData.metadata.page_size) {
          let query = {...this.$route.query}
          query.add_tags = this.selectTags
          APIs.init()
              .updateTagCustomerFilter(query)
              .then((res) => {
                this.confirmLoading = false;
                if (res.status === 200 && res.data?.status === 200) {
                  message.success({content: "Add tags customer success"});
                  this.loadCustomer();
                  this.visible = false;
                } else {
                  message.destroy();
                }
              })
              .catch((e) => {
                this.confirmLoading = false;
                message.destroy();
                console.error(e);
              });
        } else {
          APIs.init()
              .addCustomerTags({
                tags: this.selectTags,
                customers: [...this.arrCustomer]
              })
              .then((res) => {
                this.confirmLoading = false;
                if (res.status === 200 && res.data?.status === 200) {
                  message.success({content: "Add tags customer success"});
                  this.loadCustomer();
                  this.visible = false;
                } else {
                  message.destroy();
                }
              })
              .catch((e) => {
                this.confirmLoading = false;
                message.destroy();
                console.error(e);
              });
        }

      } else {
        if (this.selectedNumber && this.getCustomerData && this.getCustomerData.metadata && this.getCustomerData.metadata.page_size && this.selectedNumber > this.getCustomerData.metadata.page_size) {
          let query = {...this.$route.query}
          query.remove_tags = this.selectTags
          APIs.init()
              .updateTagCustomerFilter(query)
              .then((res) => {
                this.confirmLoading = false;
                if (res.status === 200 && res.data?.status === 200) {
                  message.success({content: "Remove tags customer success"});
                  this.loadCustomer();
                  this.visible = false;
                } else {
                  message.destroy();
                }
              })
              .catch((e) => {
                this.confirmLoading = false;
                message.destroy();
                console.error(e);
              });
        } else {
          APIs.init()
              .removeCustomerTags({
                tags: this.selectTags,
                customers: this.arrCustomer,
              })
              .then((res) => {
                this.confirmLoading = false;
                if (res.status === 200 && res.data?.status === 200) {
                  message.success({content: "Remove tags customer success"});
                  this.loadCustomer();
                  this.visible = false;
                } else {
                  message.destroy();
                }
              })
              .catch((e) => {
                this.confirmLoading = false;
                message.destroy();
                console.error(e);
              });
        }

      }
      this.onChangeKey([])
    },
    handleTableChange(pagination: PaginationProps, filters: any) {
      if (filters.full_name) {
        this.customerRequest.with_phone = filters.full_name[0]
      } else {
        this.customerRequest.with_phone = undefined
      }
      if (filters.social_type) {
        this.customerRequest.social_type = filters.social_type.join(',')
      } else {
        this.customerRequest.social_type = undefined
      }
      this.customerRequest.page = pagination?.current;
      this.customerRequest.page_size = pagination?.pageSize;
      this.replaceQuery();
      this.loadCustomer();
    },
    onSearchChange() {

       if (fetchTimeout) {
        clearTimeout(fetchTimeout);
      }
       fetchTimeout = window.setTimeout(() => {
      this.fetchCustomer(this.$route.query);
      }, 1500);

        // this.replaceQuery(true);
        // this.loadCustomer()



    },
    provinceChange(type: number) {
      // this.customerRequest = undefined;
      if (type == 1) {
        this.customerRequest.district_id = undefined;
        this.customerRequest.ward_id = undefined;
        this.fetchDistricts({payload: {province_id: this.customerRequest.province_id}});
        this.replaceLoadCustomer();
      } else {
        this.customerForm.district_id = undefined;
        this.customerForm.ward_id = undefined;
        this.fetchDistricts({payload: {province_id: this.customerForm.province_id}});
      }
    },
    districtChange(type: number) {
      if (type == 1) {
        this.customerRequest.ward_id = undefined;
        this.fetchWards({payload: {district_id: this.customerRequest.district_id}});
        this.replaceLoadCustomer();
      } else {
        this.customerForm.ward_id = undefined;
        this.fetchWards({payload: {district_id: this.customerForm.district_id}});
      }
    },
    dateRangeChange() {
      this.customerRequest.first_interact_start_date = MixUtils.methods
          .moment(this.dateRange[0].format("YYYY-MM-DDT00:00:00.000"))
          .toISOString(true);
      this.customerRequest.first_interact_end_date = MixUtils.methods
          .moment(this.dateRange[1].format("YYYY-MM-DDT23:59:59.999"))
          .toISOString(true);
      this.replaceLoadCustomer();
    },
    dateLastRangeChange() {
      this.customerRequest.last_interact_start_date = MixUtils.methods
          .moment(this.dateLastRange[0].format("YYYY-MM-DDT00:00:00.000"))
          .toISOString(true);
      this.customerRequest.last_interact_end_date = MixUtils.methods
          .moment(this.dateLastRange[1].format("YYYY-MM-DDT23:59:59.999"))
          .toISOString(true);
      this.replaceLoadCustomer();
    },
    dateRangeBookingChange() {
      this.customerRequest.hotel_tag_schedule_from = MixUtils.methods
          .moment(this.schedule[0].format("YYYY-MM-DDT00:00:00.000"))
          .toISOString(true);
      this.customerRequest.hotel_tag_schedule_to = MixUtils.methods
          .moment(this.schedule[1].format("YYYY-MM-DDT23:59:59.999"))
          .toISOString(true);
      this.replaceLoadCustomer();
    },
    newCustomer() {
      this.setCustomer(undefined)
      // this.$router.push({path: RouterEndpoint.CUSTOMER_DETAIL_EDIT.path})
    },
    newCustomerCXView(item: any) {
      if (item?.image) {
        this.toDataUrl(item.image, (base64: any) => {
          this.visibleCreateCustomer = true;
          this.customerForm = {
            selectedEmployees: [],
            selectedConversation_staff: [],
            selectedTags: [],
            image: this.DataURIToBlob(base64),
          };
        });
      } else {
        this.visibleCreateCustomer = true;
        this.customerForm = {
          selectedEmployees: [],
          selectedConversation_staff: [],
          selectedTags: [],
          image: undefined,
        };
      }
    },
    exportCustomer() {
      this.visibleExport = true;
    },
    hideExportModal() {
      this.visibleExport = false;
    },
    newAssignEmp() {
      this.visibleAssignEmp = true;
    },
    updateStatusAll() {
      this.visibleUpdateStatusAll = true;
    },
    deleteCustomerExecute() {

      message.loading({content: this.$t('DELETE')})
      if (this.selectedNumber && this.getCustomerData && this.getCustomerData.metadata && this.getCustomerData.metadata.page_size && this.selectedNumber > this.getCustomerData.metadata.page_size) {
        APIs.init().deleteAllCustomer(this.$route.query).then(res => {
          message.destroy()
          if (res.ok && res.data?.status === 200) {
            this.loadCustomer()
            message.success({content: this.$t('DELETE_SUCCESS')});
          } else {
            message.error({content: res.data?.message});
          }

          Modal.destroyAll()
        }).catch(e => {
          message.destroy()
          Modal.destroyAll()
          message.error({content: JSON.stringify(e)});
        })
      } else {
        let selectedCustomers: Customer[] = []
        const customers = this.getCustomerData.customers
        if (customers && customers.length) {
          for (let i = 0; i < this.arrCustomer.length; i++) {
            const customer = customers.find((x: Customer) => x.id === this.arrCustomer[i]);
            if (customer) {
              selectedCustomers = [...selectedCustomers, customer]
            }
          }
        }
        selectedCustomers.map((customer) => {
          this.deleteCustomer(customer)
        })
      }

    },
    deleteCustomerAll() {
      Modal.confirm({
        content: () => this.$t('delete_title_confirm'),
        onOk: () => this.deleteCustomerExecute(),
        onCancel() {
          Modal.destroyAll();
        },
      })
    },
    ChangIdentified(value:number){
       console.log(value)
    },
    onSubmit(){
      this.customer.all_phones = this.customer.phone
      APIs.init()
          .createCustomer(this.customer)
          .then((res) => {
            this.submitLoading = false;
            if (res.status === 200 && res.data?.status === 200) {
              message.success({content: "Create customer success"});
              this.setCustomer(res.data.data)
              router.push({name: RouterEndpoint.CUSTOMER_EDIT_DETAIL.name, params: {id: res.data.data.id}})
            }else {
              message.error({content: res.data?.message});
              if (res.data&&res.data.data){
                this.error( res.data.data,res.data?.message);

              }
            }
          })
          .catch((e) => {
            this.submitLoading = false;
            message.destroy();
            console.error("error",e);
          });
    },

    getCustomerNoteTag() {
      if (this.customerForm.id) {
        APIs.init().getShopCustomerNote({shop_customer_id: this.customerForm.id}).then(response => {
          if (response.status === 200 && response.data?.data) {
            this.noteHistories = response.data.data
          }
        })
      }
    },
    editCustomerCXView(customer: Customer) {

      if (customer.province_id) {
        this.fetchDistricts({payload: {province_id: customer.province_id}});
      }
      if (customer.district_id) {
        this.fetchWards({payload: {district_id: customer.district_id}});
      }
      const formObject: CustomersForm = {};
      if (customer.tags !== null) {
        formObject.selectedTags = customer.tags?.map((item) => {
          return Number(item.id);
        });
      }
      if (customer.employees) {
        formObject.selectedEmployees = customer.employees
            .split(",")
            .map((item: string): number => {
              try {
                return parseInt(item);
              } catch (e) {
                console.error(e);
                return -1;
              }
            })
            .filter((item: number) => item !== -1);
      }
      if (customer.conversation_staff) {
        formObject.selectedConversation_staff = customer.conversation_staff
            .split(",")
            .map((item: string): number => {
              try {
                return parseInt(item);
              } catch (e) {
                console.error(e);
                return -1;
              }
            })
            .filter((item: number) => item !== -1);
      }

      this.customerForm = {...customer, ...formObject};
      this.getCustomerNoteTag()
      if (customer.tags) {
        this.customerTags = customer.tags.map((t) => t.id) ? customer.tags.map((t) => t.id) : []
      }
      this.visibleCreateCustomer = true;
    },
    async saveCustomer() {
      try {
        await this.$refs.formRef.validate();
        let customerForm = {...this.customerForm};

        if (
            customerForm.selectedEmployees &&
            customerForm.selectedEmployees.length
        ) {
          customerForm.employees = customerForm.selectedEmployees.join(",");
        }
        if (
            customerForm.selectedConversation_staff &&
            customerForm.selectedConversation_staff.length
        ) {
          customerForm.conversation_staff =
              customerForm.selectedConversation_staff.join(",");
        }
        // if (customerForm.conversation_staff)
        //   customerForm.conversation_staff = this.customerForm.conversation_staff.join(",")
        if (customerForm.birth)
          customerForm.birth = moment(this.customerForm.birth, 'YYYY-06-06').toISOString();
        if (customerForm.selectedTags) {
          customerForm.tags = customerForm.selectedTags
              .map((item: number) => {
                return this.getTreeTags?.find((tag: Tag) => tag.id == item);
              })
              .filter((item: Tag) => item);
        }

        this.confirmCustomerLoading = true;

        let cxViewCreateCustomer: ApiResponse<CXViewCreateCustomerRes>;
        if (customerForm.image) {
          try {
            if (!customerForm.sex) {
              message.error({content: this.$t("gender_require")});
              return;
            }
            message.loading('Loading...')


            const formData = new FormData();
            formData.append("name", customerForm.full_name);
            formData.append("birthDate", moment(customerForm.birth, 'YYYY').add(1, 'year').format('DD/MM/YYYY'));
            formData.append("gender", customerForm.sex);
            formData.append("telephone", customerForm.phone ? customerForm.phone : '');
            formData.append("image", customerForm.image);
            cxViewCreateCustomer = await APIs.init().cxViewCreateCustomer(
                formData
            );
            // if (cxViewCreateCustomer.status === 400) {
            //   console.log('error create customer cxview')
            // }else{
            if (cxViewCreateCustomer.status !== 200) {
              message.error({content: "Vui lòng chọn ảnh khách hàng khác!"});
              return;
            }
            message.destroy()
            // }
          } catch (e) {
            message.error({content: e + ''});
            return;
          }
        }
        if (customerForm.id) {
          APIs.init()
              .updateCustomer(customerForm)
              .then((res) => {
                this.confirmCustomerLoadingading = false;
                if (res.status === 200 && res.data?.status === 200) {
                  this.visibleCreateCustomer = false;
                  message.success({content: "Edit customer success"});
                  this.loadCustomer();
                } else {
                  message.destroy();
                }
              })
              .catch((e) => {
                this.confirmCustomerLoading = false;
                message.destroy();
                console.error(e);
              });
        } else {
          customerForm.cxview_token = this.getCXView.token
          APIs.init()
              .createCustomer(customerForm)
              .then(async (res: ApiResponse<CustomerResponse>) => {
                this.confirmCustomerLoading = false;
                if (res.status === 200 && res.data?.status === 200) {
                  if (customerForm.image) {
                    try {
                      const cxViewCustomer = await APIs.init().cxViewCustomer({
                        shop_id: res.data.data.shop_id,
                        cxview_id: cxViewCreateCustomer.data?.listResponse.id,
                        shop_customer_id: res.data.data.id,
                      });
                    } catch (e) {
                      console.log(e);
                      return;
                    }
                  }

                  this.visibleCreateCustomer = false;
                  message.success({content: "Add customer success"});
                  this.loadCustomer();
                } else {
                  message.destroy();
                }
              })
              .catch((e) => {
                this.confirmCustomerLoading = false;
                message.destroy();
                console.error(e);
              });
        }
      } catch (e) {
        console.error(e);
      }
    },
    editCustomerClick(customer: Customer) {
      // this.setCustomer(customer)
      this.$router.push({name: RouterEndpoint.CUSTOMER_DETAIL_EDIT.name, params: {id: customer.id}})
    },
    deleteCustomer(customer: Customer) {
      message.loading({
        content: "Deleting Customer: " + customer.full_name,
        key: customer.id,
      });
      if (!customer.id) {
        customer.id = 0;
      }
      APIs.init()
          .deleteCustomer(customer.id)
          .then((res) => {
            if (res.status === 200 && res.data?.status === 200) {
              message.success({
                content: "Deleted Customer: " + customer.full_name + "!",
                key: customer.id,
                duration: 2,
              });
              this.getCustomerData.customers =   this.getCustomerData.customers?.filter((item:any) => item.id !== customer.id)
            } else {
              message.destroy();
            }
          })
          .catch((e) => {
            message.destroy();
            console.error(e);
          });
    },

    changeStatusCare(cusId: number[], careId: number[], color: string,name:string) {
      this.colorTag = color
      if (careId && cusId) {
        // let cusmorCare = this.getCustomerDetailCares.find((item:any) => item.id === careId[0])
        // this.createHistoryChange('CARE_STATUS',cusmorCare?cusmorCare.name:"rỗng",cusId[0])
        careId.map((care: number) => {
          cusId.map((customerId: number) => {
            let request = this.updateLastCare;
            request.user_id = customerId;
            request.last_care_id = care;
            APIs.init()
                .updateCustomerLastCare(request)
                .then((res) => {
                  if (res.status === 200 && res.data?.status === 200) {
                    message.success( this.$t('update_last_care') + ' ' +this.$t('SUCCESS'))
                    this.getCustomerData.customers =   this.getCustomerData.customers.map((item:any) => {
                      if(item.id === customerId){
                        item.last_care = care
                        item.last_care_name =name
                      }
                      return item

                    })
                    this.setCustomer(this.getCustomerData)

                    // console.log(this.getCustomerData.customers)
                  }
                });

            // let customerCare = this.getCustomerCareById(care)
            // if (customerCare && customerCare.trigger_id) {
            //   APIs.init().triggerSendToOne({trigger_id: customerCare.trigger_id, customer_id: customerId}).then()
            // }
          })
        })
      }
    },
    openChat(page: string[], social: string[], shop_id: number) {
      var url =
          Constant.LINK_CHAT_NEW + "&pages="+page + "&social_id=" + social + '&shop_id=' + shop_id + "&token_web=" + this.userInfo.token_web + '&token_login=' + this.userInfo.token_login + "&user_id=" + this.userInfo.id;
      window.open(url, "_blank");
    },
    createOrder(customerId: number) {
      this.$router.push({name: RouterEndpoint.EDIT_ORDER.name, params: {id: 0}, query: {customer_id: customerId}})
    },
    actionUpdateStatus() {
      this.changeStatusCare(this.arrCustomer, [this.selectedPrepareStatusUpdate])
    },
    actionAssignEmp() {
      this.confirmAssignEmp = true;
      if (this.selectedNumber && this.getCustomerData && this.getCustomerData.metadata && this.getCustomerData.metadata.page_size && this.selectedNumber > this.getCustomerData.metadata.page_size) {
        const query = this.$route.query
        query.add_employees = this.selectEmps
        APIs.init()
            .assignCustomerAllEmp(query)
            .then((res) => {
              this.confirmAssignEmp = false;
              if (res.status === 200 && res.data?.status === 200) {
                message.success({content: "Assign employee success"});
                this.loadCustomer();
                this.visibleAssignEmp = false;
              } else {
                message.destroy();
              }
            })
            .catch((e) => {
              this.confirmAssignEmp = false;
              message.destroy();
              console.error(e);
            });
      } else {
        APIs.init()
            .assignCustomerEmp({
              employee_ids: this.selectEmps,
              customer_ids: this.arrCustomer,
            })
            .then((res) => {
              this.confirmAssignEmp = false;
              if (res.status === 200 && res.data?.status === 200) {
                message.success({content: "Assign employee success"});
                this.loadCustomer();
                this.visibleAssignEmp = false;
              } else {
                message.destroy();
              }
            })
            .catch((e) => {
              this.confirmAssignEmp = false;
              message.destroy();
              console.error(e);
            });
      }
      this.selectEmps = []

    },
    changePageExport(page: number) {
      this.pageExport = page;
    },
    getExtraDetail(dataIndex:any,extracolumn:any){
      if(dataIndex && extracolumn){
        let column = extracolumn.filter((i:any) => i.customer_column_id === dataIndex)
        return column.length > 0 ? column[0] :""
      }


    },
    onExport() {
      this.confirmExport = true;
      const customerRequestExport = {
        ...this.customerRequest,
        ...{
          page: this.pageExport,
          page_size: 1000,
          lang: this.lang,
        },
      };
      APIs.init()
          .exportCustomer(customerRequestExport)
          .then((res) => {
            this.confirmExport = false;
            if (res.status === 200 && res.data?.status === 200) {
              this.visibleExport = false;
              message.success({content: "Export customer success"});
              var url = Constant.LINK_EXPORT + res.data?.data;
              window.open(url);
            } else {
              message.destroy();
            }
          })
          .catch((e) => {
            this.confirmExport = false;
            message.destroy();
            console.error(e);
          });
    },
    getBaseUrl(): string  {
      if (location.host.includes("crm.shopf1")) {
        return "https://api.shopf1.net/";
      } else if (location.host.includes("crm.alfreddesk")) {
        return "https://api.shopf1.net/";
      } else if (location.host.includes("crmtest")) {
        return "https://test.shopf1.net/";
      } else if (location.host.includes("localhost")) {
        return "https://api.shopf1.net/";
      }
      return "https://api.shopf1.net/";
    },
    downloadSampleFile() {
      const url =  this.getBaseUrl()+'flow-api/download?path=/assets/customer_import_example.xlsx&shop_id=' + this.getCurrentShop.id;
      this.visibleImport = false
      window.open(url);
    },
    importCustomer(info: any) {
      //console.log(info);
      const formData = new FormData();
      formData.append("files", info);
      this.importLoading = true
      APIs.init()
          .importCustomer(formData)
          .then((res) => {
            this.importLoading = false
            if (res.status === 200 && res.data?.status === 200) {
              this.replaceLoadCustomer()
              message.success({content: "Import customer success"});
            } else {
              message.destroy();
            }
          })
          .catch((e) => {
            this.importLoading = false
            message.destroy();
            console.error(e);
          });
    },
    importCustomerUpdate(info: any) {
      const formData = new FormData();
      formData.append("files", info);
      formData.append("identified_id",this.extraColumId)
      formData.append("identified_type",this.extraColumType)
      this.importLoading = true
      APIs.init()
          .importCustomerUpdate(formData)
          .then((res) => {
            this.importLoading = false
            if (res.status === 200 && res.data?.status === 200) {
              this.replaceLoadCustomer()
              message.success({content: "Import customer success"});
            } else {
              message.destroy();
            }
          })
          .catch((e) => {
            this.importLoading = false
            message.destroy();
            console.error(e);
          });
    },
    columnSelection() {
      this.$refs.columnsVisibilityModal.showModal();
    },
    onCardDrop(dropResult: any) {
      console.log("dropResult", dropResult);
    },
    toDataUrl(url: any, callback: any) {
      var xhr = new XMLHttpRequest();
      xhr.onload = function () {
        var reader = new FileReader();
        reader.onloadend = function () {
          callback(reader.result);
        };
        reader.readAsDataURL(xhr.response);
      };
      xhr.open("GET", url);
      xhr.responseType = "blob";
      xhr.send();
    },
    clearLastCare() {
      this.customerRequest.last_care = undefined;
      this.replaceLoadCustomer()
    },
    DataURIToBlob(dataURI: string) {
      const splitDataURI = dataURI.split(",");
      const byteString =
          splitDataURI[0].indexOf("base64") >= 0
              ? atob(splitDataURI[1])
              : decodeURI(splitDataURI[1]);
      const mimeString = splitDataURI[0].split(":")[1].split(";")[0];

      const ia = new Uint8Array(byteString.length);
      for (let i = 0; i < byteString.length; i++)
        ia[i] = byteString.charCodeAt(i);

      return new File(
          [
            new Blob([ia], {
              type: mimeString,
            }),
          ],
          "my_image.jpg",
          {type: "image/jpg", lastModified: new Date().getTime()}
      );
    },
    customerRenderRow(record: any) {
      return {
        onClick: (event: any) => {
          console.log("click");
        }, // click row
        onDblclick: (event: any) => {
          if(this.getMyRole(this.MODULES.customers)?.is_edit){
            this.$router.push('/customer-detail/'+ record.id);
          }
        }, // double click row
      };
    },
    getChatTag() {
      let tags: any[] = [];
      try {
        this.getPages.map((page: any) => {
          try {
            const page_tags = JSON.parse(page.config_data).tags;
            for (let i = 0; i < page_tags.length; i++) {
              let contain = false;
              for (let j = 0; j < tags.length; j++) {
                if (page_tags[i].tag_id === tags[j].tag_id) {
                  contain = true;
                  break;
                }
              }
              if (!contain) tags.push(page_tags[i]);
            }
          } catch (e) {
            console.log("chat tags", e);
          }
        });
      } catch (e) {
        console.log(e);
      }
      return tags;
    },

    findTagById(id: number) {
      if (this.getTreeTags) {
        const tag = this.getTreeTags.find((t: any) => t.id == id)
        if (tag !== undefined)
          return tag
        else {
          return {}
        }
      }
      return {}
    },
    findStatusCare(id: number) {
      if (this.getCustomerDetailCares) {
        const statusCare = this.getCustomerDetailCares.find((t: any) => t.id == id)
        if (statusCare !== undefined)
          return statusCare
        else {
          return {}
        }
      }
      return {}
    },
    expandRow(id: number) {

      if (this.expandedRowKeys.includes(id)) {
        this.expandedRowKeys = []
      } else {
        this.expandedRowKeys = [id]
      }
    }
  },
})
export default class Customers extends Vue {
}
</script>

<style lang="scss" scoped>
@import "../../assets/styles/customize-dnd.css";

.container {
  display: flex;
  padding-left: 16px;
  padding-right: 16px;
  flex-direction: column;

  .left-layout {
    //flex: 0.2;
    //padding-right: 10px;
    margin-bottom: 10px;

    .card-filter {
      border-radius: 10px;

      .card-filter-content-layout {
        margin-top: -10px;
        margin-bottom: -20px;
      }
    }

    .filter-form-item-inline {
      display: inline-block;
      width: 220px;
    }

    .space-horizontal {
      display: inline-block;
      width: 20px;
    }
  }

  .header-functions {
    display: flex;
    margin: 0px;
    padding: 0px 16px;

    .button-left {
      flex: 1;
      display: flex;
      align-items: center;

      .input-search-customer {
        width: 150px
      }

      .button-function {
        margin-left: 22px;
        background-color: #55acee;
        border-radius: 3px;
        border-color: #55acee;
        color: white;
      }

      .button-function-disabled {
        background-color: #8bc8f5;
        border-color: #8bc8f5;
        border-radius: 3px;
        margin-left: 8px;
        color: white;
      }
    }
  }

  .right-layout {
    flex: 1;


    .user-comment {
      font-weight: bold;
    }

    .timeline-comment {
      margin-top: 15px;
    }

    .time-comment {
      font-size: 12px;
      font-style: italic;
      margin-left: 10px;
    }

    .extra-item-container {
      display: inline-block;
      width: 320px;
      margin-top: 7px;
      margin-left: 7px;
      margin-right: 7px;

      .extra-item {
        display: flex;
        width: 100%;
      }

      .extra-item-value-note {
        background-color: #f6f6f6;
        padding: 6px 16px;
        border-radius: 4px;
        min-height: 32px;
        border: #e0e0e0 solid 1px;
        width: 200px;
      }

      .extra-item-label {
        text-align: right;
        flex: 1;
        margin-right: 10px;
      }


    }

  }
}

.row-horizontal {
  display: flex;
  flex: 2;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
}

.input-search {
  display: flex;
  flex: 0.2;
}

.button-left {
  width: 40%;
  float: left;
  margin-bottom: 10px;
}

.button-right {
  //width: 60%;
  //float: left;
  text-align: right;
  margin-bottom: 10px;
  justify-content: end;
}

.button-right button {
  margin-left: 10px;
}

.action-buttons {
  display: flex;
  justify-content: center;
  margin-top: 14px;

  .action-button-margin-left {
    margin-left: 12px;
  }
}

.menu-filter .ant-form-item {
  /*padding-left: 10px;*/
  margin-bottom: 18px !important;
}

.icon-filter {
  text-align: right;
  font-size: 19px;
}

.div-filter h5 {
  padding: 10px 0px 10px 0px;
}

.form-selection input {
  border-radius: 20px;
}

.form-body {
  margin-top: 30px;
  padding: 0px 30px;
}

.table-row-name {
  font-weight: bold;
}

.phone-number {
  font-size: 12px;
}

.employee_name {
  font-weight: bold;
}

.slide-up-enter-active,
.slide-up-leave-active {
  transition: all 0.25s ease-out;
}

.slide-up-enter-from {
  opacity: 0;
  transform: translateY(30px);
}

.slide-up-leave-to {
  opacity: 0;
  transform: translateY(-30px);
}

.radio-group-customer-care {
  //margin-top: 8px;

  .radio-item-customer-care {
    margin-right: 6px;
    .radio-badge {
      font-weight: 13px;
    }
  }

}

.button-employee-detail {
  font-weight: bold;
}

.title-header {
  font-weight: bold;
  flex: 1;
}

.container-title {
  display: flex;
  flex-direction: row;
  padding: 16px 24px 16px 24px;
  //margin-bottom: 25px;
  background-color: white
}

</style>
