<template>
  <div class="container-content">
    <a-card  style="width: 100%">
      <a-tabs  v-model:activeKey="activeKey" :tab-position="tabPosition"  >
        <a-tab-pane key="1">
          <template #tab>
            <a-row justify="flex-start">
          <span>
          <HomeOutlined v-if="activeKey === '1'" />
          {{$t('Facebook uid')}}
        </span>
            </a-row>

          </template>
          <facebook-uid />
        </a-tab-pane>
<!--        <a-tab-pane key="2">-->
<!--          <template #tab>-->
<!--            <a-row justify="flex-start">-->
<!--        <span>-->
<!--          <FileSyncOutlined v-if="activeKey === '2'" />-->
<!--           {{$t('page_settings')}}-->
<!--        </span>-->
<!--            </a-row>-->
<!--          </template>-->
<!--          <setting-page />-->
<!--        </a-tab-pane>-->
<!--        <a-tab-pane key="3">-->
<!--          <template #tab>-->
<!--            <a-row justify="flex-start">-->
<!--        <span>-->
<!--         <user-outlined v-if="activeKey === '3'" />-->
<!--           {{$t('employee_assignment')}}-->
<!--        </span>-->
<!--            </a-row>-->
<!--          </template>-->
<!--          <role-employee />-->
<!--        </a-tab-pane>-->
<!--        <a-tab-pane key="4">-->
<!--          <template #tab>-->
<!--            <a-row justify="flex-start">-->
<!--        <span>-->
<!--          <TagsOutlined v-if="activeKey === '4'" />-->
<!--           {{$t('conversation_tags')}}-->
<!--        </span>-->
<!--            </a-row>-->
<!--          </template>-->
<!--          <conversation-tag />-->
<!--        </a-tab-pane>-->
<!--        <a-tab-pane key="5">-->
<!--          <template #tab>-->
<!--            <a-row justify="flex-start">-->
<!--        <span>-->
<!--          <TagsOutlined v-if="activeKey === '5'" />-->
<!--           {{$t('BOOKING_TAGS')}}-->
<!--        </span>-->
<!--            </a-row>-->
<!--          </template>-->
<!--          <booking-tags />-->
<!--        </a-tab-pane>-->
<!--        <a-tab-pane key="6">-->
<!--          <template #tab>-->
<!--            <a-row justify="flex-start">-->
<!--        <span>-->
<!--          <MailOutlined v-if="activeKey === '6'" />-->
<!--           {{$t('quick_message')}}-->
<!--        </span>-->
<!--            </a-row>-->
<!--          </template>-->
<!--          <quick-message/>-->
<!--        </a-tab-pane>-->
<!--        <a-tab-pane key="7">-->
<!--          <template #tab>-->
<!--            <a-row justify="flex-start">-->
<!--        <span>-->
<!--          <FieldTimeOutlined v-if="activeKey === '7'" />-->
<!--           {{$t('auto_reply')}}-->
<!--        </span>-->
<!--            </a-row>-->
<!--          </template>-->
<!--          <auto-reply />-->
<!--        </a-tab-pane>-->
        <!--    <a-tab-pane key="8">-->
        <!--      <template #tab>-->
        <!--        <a-row justify="flex-start">-->
        <!--        <span>-->
        <!--          <ScheduleOutlined />-->
        <!--           {{$t('schedules')}}-->
        <!--        </span>-->
        <!--        </a-row>-->
        <!--      </template>-->
        <!--      <schedule/>-->
        <!--    </a-tab-pane>-->
<!--        <a-tab-pane key="9">-->
<!--          <template #tab>-->
<!--            <a-row justify="flex-start">-->
<!--        <span>-->
<!--          <MenuFoldOutlined v-if="activeKey === '9'" />-->
<!--           {{$t('LINE_ACCOUNT')}}-->
<!--        </span>-->
<!--            </a-row>-->
<!--          </template>-->
<!--          <LineAccount />-->
<!--        </a-tab-pane>-->



      </a-tabs>
    </a-card>
  </div>
</template>
<script lang="ts">
import { FileSyncOutlined,MenuFoldOutlined,UserOutlined,ScheduleOutlined, CodeFilled ,HomeOutlined,TagsOutlined,MailOutlined,FieldTimeOutlined} from '@ant-design/icons-vue';
import {computed, onMounted, ref,watch} from 'vue';
import SettingShop from "@/views/SettingPage/SettingShop.vue";
import SettingPage from "@/views/SettingPage/SettingPage.vue";
import GetToken from "@/views/SettingPage/GetToken.vue";
import RoleEmployee from "@/views/SettingPage/RoleEmployee.vue";
import ConversationTag from "@/views/SettingPage/ConversationTag.vue";
import BookingTags from "@/views/SettingPage/BookingTags.vue";
import QuickMessage from "@/views/SettingPage/QuickMessage.vue";
import AutoReply from "@/views/SettingPage/AutoReply.vue";
import Schedule from "@/views/SettingPage/Schedule.vue";
import LineAccount from "@/views/SettingPage/LineAccount.vue";
import FacebookUid from "@/views/CMS/FacebookUid.vue"

import {useStore} from "vuex";
import {PageConfigActionTypes} from "@/store/modules/PageConfig/action-types";
export default {

  setup() {
    const store = useStore()

    const currentShop = computed(()=> store.getters.getCurrentShop)
    const token = computed(()=>store.getters.getUserInfo)
    console.log(token.value,'111')
    const fetchApi = async () => {
      await store.dispatch(PageConfigActionTypes.FETCH_PAGE_CONFIG, {shop_id: currentShop.value.id})
    }
    onMounted(()=>{
      fetchApi()
    })

    return {


      activeKey: ref('1'),
      tabPosition: ref("left")
    };
  },
  components: {
    // LineAccount,
    // Schedule,
    // AutoReply,
    // MenuFoldOutlined,
    // QuickMessage,
    // BookingTags,
    // ConversationTag,
    // // GetToken,
    // SettingPage,
    // SettingShop,
    // MailOutlined,
    // FieldTimeOutlined,
    // RoleEmployee,
    // ScheduleOutlined,
    FacebookUid
    // HomeOutlined,
    // FileSyncOutlined,
    // CodeFilled,
    // TagsOutlined,
    // UserOutlined
  },
}
</script>
