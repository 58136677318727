{
  "99": "$20",
  "199": "$24",
  "399": "$24",
  "449": "$28",
  "499": "$32",
  "599": "$32",
  "649": "$36",
  "699": "$40",
  "message": "Message",
  "SETTING_SHOP": "Setting shop",
  "function": "function",
  "UNREAD-DIALOGAGUE_TO_THE_TOP": "Unread dialogue to the top",
  "FULL_CARD_DISPLAY_MODE": "Full card display mode",
  "ORDER_TRACKING_MESSAGE": "Order tracking message",
  "RESTRICT_EMPLOYEE_ORDER_PRIVILEGES": "Restrict employee order privileges",
  "SIMULTANEOUS_MULTI-TAG_FILTERING_MODE": "Simultaneous multi-tag filtering mode",
  "CARE_STATUS_LIST": "Care status",
  "CUSTOMER_DETAIL_EDIT": "Detail/ Edit",
  "DESCRIPTION": "Description",
  "SALE_TAGS": "Sale tags",
  "id_shop": "Shop id",
  "UNIT": "Unit",
  "Token_error_message": "Your token has expired on this page, please login again",
  "ten_dich_vu": "Service name",
  "PLACEHOLDER_INPUT_PROVINCE": "Please choose province or city",
  "PLACEHOLDER_INPUT_DISTRICT": "Please select district ",
  "PLACEHOLDER_INPUT_WARD": "please choose ward",
  "FLOW_COMMENT_REPLY_PREVIEW_PLACEHOLDER": "awesome, I had been using this one for years. Could you send me the promotion?",
  "RANGED_DATE": "Ranged date",
  "YOUR_CURRENT_SERVICE_PACK": "Your current service pack",
  "SERVICE_PACK": "Service pack",
  "CLICK_TO_START": "Click to start",
  "SYNC_CUSTOMER_WITH_CRM": "Sync customer with CRM",
  "CALL_DURATION": "Call duration",
  "CALL_FROM": "Call from",
  "STAFF_CALL": "Staff call",
  "THIS_IS_THE_DESCRIPTION": "This is the description",
  "CONNECT_PLATFORM": "Connect platform",
  "NO_PAGE": "You don't have any pages. Click the button to connect a platform (Facebook, Instagram, Zalo, Line)",
  "STOP_TRIGGER": "Stop trigger",
  "Participants": "Participants",
  "shared": "shared",
  "GOOD_LUCK": "Good luck",
  "DILIVERED_ORDER": "Dilivered order",
  "SPENT_AMOUNT": "Spent amount",
  "CALL_HISTORY": "Call history",
  "count": "count",
  "BUY_AT": "Buy at",
  "Assigee": "Assigee",
  "CUSTOMER_NAME_FROM": "Customer name (fill out the form)",
  "TRIGGER_VALIDATE_FLOW": "Trigger validate flow",
  "PRIVATE_REPLY_FLOW": "Private reply flow",
  "AUTO_REPLY_COMMENT_AND_SEND_PRIVATE_MESSAGE": "Auto reply comment & send private message",
  "VALIDATE_DISPLAY_NAME": "Validate display name",
  "EXAMPLE": "Awesome, I had been using this one for years. Could you send me the promotion? ",
  "REPLY_COMMENT_DETAIL": "Reply comment detail",
  "REPLY_COMMENT_PRIVATE_MESSAGE": "Facebook Comment & Messenger",
  "SETUP_CONTEXT": "Setup Context",
  "MY_WALLET": "My wallet",
  "DEPOSIT": "Deposit",
  "HISTORY_TRANSACTION": "History transaction",
  "ZNS_CHARGED": "ZNS charged",
  "TOTAL_AMOUNT": "Total amount",
  "TRANSACTIONS": "Transactions",
  "EXTRA_INFO": "Extra info",
  "TRANSACTION_TYPE": "Transaction Type",
  "MONEY_CHANGE": "Money change",
  "REPORT_ZNS_TRANSACTION": "ZNS transaction report",
  "SEND_TO_CARRIER": "Send to carrier",
  "ACTION": "Action",
  "STATUS": "Status",
  "SHIPPING_FEE": "Shipping fee",
  "DESTINATION": "Destination",
  "COLLECTED_MONEY": "Collected money",
  "WAREHOUSE": "Warehouse",
  "CREATE_ORDER": "Create order",
  "RESET_FILTER": "Reset filter",
  "SEARCH": "Search",
  "NOTE": "Note",
  "PRODUCT": "Product",
  "EMPLOYEE": "Employee",
  "UPDATED": "Updated",
  "PICKUP_DATE": "Pickup date",
  "SHIPPING_UNIT": "Shipping unit",
  "CREATED": "Created Date",
  "PAGE": "Page",
  "CUSTOMER": "Customer",
  "ORDER_ID": "Order Id",
  "SUCCESS_SHIPPED": "Successfully shipped",
  "SUCCESS_RETURNED": "Successfully returned",
  "CANCELED": "Canceled",
  "RETURNING": "Returning",
  "REQUEST_RETURN": "Request return",
  "SHIPPING": "Shipping",
  "PICKED_UP": "Picked up",
  "WAITING_PICKUP": "Waiting pickup",
  "CONFIRMED_ORDERS": "Confirmed orders",
  "NEW_ORDERS": "New orders",
  "ALL": "All",
  "ORDERS": "Orders",
  "cx_view_select_group": "Choose shop",
  "EXPECTED_SENDING_NUMBER": "Expected sending number",
  "ERROR": "Error",
  "ENDED": "Ended",
  "RUNNING": "Running",
  "READY": "Ready",
  "NEW": "New",
  "STOPPED": "Stopped",
  "CLICKED": "Clicked",
  "SEEN": "Seen",
  "SUCCESS": "Success",
  "SENT": "Sent",
  "LIMITED": "Limited",
  "NAME": "Name",
  "SCHEDULE": "Schedule",
  "auto_inbox_status_deleted": "Deleted",
  "viral_ref_name": "Joiner's name",
  "viral_description": "Viral description",
  "viral_name": "Viral name",
  "viral_message_after_done_placeholder": "Congratulations ##ref_name## on completing the task! Please wait for inbox from the shop",
  "ZALO_EDIT_SETTINGS": "Zalo edit settings",
  "CLICK_BUTTON_LINK_ZALO": "Click button link zalo",
  "ZALO_CONNECTION": "zalo connection",
  "quick_create": "quick create",
  "Stocking": " Stocking",
  "Out_of_stock": "Out of stock",
  "viral_message_after_success_placeholder": "You have ##ref_count## people referred successfully, you need to refer enough ##ref_number## people to receive the gift",
  "viral_message_after_phone_placeholder": "##ref_name## joined the event and need to share more ##ref_count## people",
  "viral_status": "Viral status",
  "viral_message_after_done": "Message sent to customer after completing the request",
  "viral_message_after_success": "Messages to guests after referring a successful person",
  "viral_message_after_phone": "Message sent to guests, after entering the phone number",
  "viral_share_count": "Viral share count",
  "viral_share_button": "Join",
  "viral_level_title": "Name of campaign",
  "viral_level_2": "Receiver level 2",
  "viral_level_1": "Receiver level 1",
  "VIRAL_TAGS": "Viral tags",
  "gender_require": "Gender is required",
  "Viral_Result": "Viral Result",
  "time_visit": "Time visit",
  "AI_identify": "Camera AI - customer identification",
  "camera_image": "Image",
  "camera_sex_nu": "Female",
  "camera_sex_nam": "Male",
  "camera_sex": "Gender",
  "camera_age": "Age",
  "camera_name": "Name",
  "camera_sent": "Sent",
  "list_camera_name": "Identification list",
  "camera_title": "Identification",
  "ADD_CAMERA_NAME": "Add identity",
  "AUTO_INBOX_NEXT_MESSAGE_GUIDE_LABEL": "When the button is clicked by customers then the second message will be sent to them",
  "RANDOM_1_IN_2": "(Customer will receive random 1 in 2)",
  "NEXT_MESSAGES": "Next messages",
  "AUTO_INBOX_WARNING_BUTTON_LABEL": "Button: (This button is used so that when a customer clicks it, it will send a next message containing advertising content)",
  "AUTO_INBOX_WARNING_MESSAGE_TAG_LABEL": "Message tag: (Messages are only sent when attaching the message tag specified by facebook. You can leave it as default)",
  "AUTO_INBOX_WARNING_MESSAGE_LABEL": "Message: The content of the message does not contain advertising and promotional information. For example: \"Gift, Discount, Promotion, %, Buy Now...\" We do not encourage customers to use this feature continuously, it will cause customer SPAM, leading to PAGE warning from Facebook. (Note: the page is not locked by this feature). Policy information",
  "AUTO_INBOX_WARNING_LABEL": "You must follow the message content setup for campaigns, once you violate rules in Facebook guidelines then Facebook will restrict this functionality for this page",
  "PREVIEW": "Preview",
  "SHARE": "Share",
  "MESSAGE_TAG": "Message tag",
  "AUTO_INBOX_MESSAGE_TITLE": "The message should not contain the ads contents",
  "CREATE_NEW_CAMPAIGN": "Create new campaign",
  "EXCLUDE_TAGS": "Exclude tags",
  "INCLUDE_TAGS": "Include tags",
  "NOT_PURCHASED": "Not purchased",
  "PURCHASED": "Purchased",
  "has_phone_number": "Has phone number",
  "HAS_PHONE_NUMBER": "Has phone number",
  "key_word": "Key Word",
  "PURCHASE_STATUS": "Purchasing status",
  "SEND_BY_POST": "Send by post",
  "CUSTOMER_INFO": "Customer info",
  "INFO": "Info",
  "CAMPAIGN_NAME": "Campaign name",
  "SAVE": "Save",
  "NEW_CAMPAIGN": "New Campaign",
  "NEW_AUTO_INBOX": "Add new campaign",
  "HISTORY": "History",
  "CAMPAIGN_LIST": "Campaign list",
  "CREATED_TITLE": "Created Date",
  "ADD_NEW_MESSAGE_ZALO": "Add new message Zalo ZNS",
  "CHOOSE_ZALO_ACCOUNT": "Please choose a Zalo ZNS account",
  "SELECT_TEMPLATE_SEND_ZALO": "Select template to send Zalo ZNS",
  "SELECT_AVAILABLE_MESSAGE": "Select an available message",
  "FLOW_NEW_LINE": "Add New Line Message",
  "GREATER_THAN": "Greater than",
  "LESS_THAN": "Less than",
  "messenger_windows": "Messenger",
  "SETTING": "Setting",
  "MESSENGER_WINDOW": "Messenger",
  "CUSTOMER_CARES": "Customer care ",
  "MESSAGE": "Message",
  "PROVINCE": "Province",
  "SELECT_PAGE": "Select page",
  "TELEPHONE_ NUMBER_ UNAVAILABILITY": "telephone number unavailable",
  "SHOP_INFO": "Shop info",
  "Account_holder": "Account holder",
  "Branch": "Branch",
  "Account_number": "Account number",
  "Shop_owner": "Shop owner",
  "Shop_Information": "Shop Information",
  "TELEPHONE_ NUMBER_ AVAILABILITY": "telephone number available",
  "EXPORT_CUSTOMER": "Export customer",
  "Choose the amount of export": "select export amount",
  "ORDER_UP": "Create order",
  "SELECT_STAFF": "Staff selection",
  "STAFF_LIST": "Staff list ",
  "SELECT_STATUS": "Select status ",
  "TAG_CONSIDER": "Interested",
  "TAG_CONSIDERING": "Concerned",
  "TAG_MEETING": "Arranging appointment",
  "TAG_MET": "Appointment completed ",
  "TAG_SIGNED_CONTRACT": "Contract signed",
  "ADD_CARE_STATUS": "Add care status ",
  "ASSIGN_CUSTOMERS_EMPLOYEES": "Label customer to staff",
  "CUSTOMER_CONVERSATION": "Conversation",
  "DATE_CREATED": "Created date",
  "FROM": "From ",
  "FEMALE": "Female",
  "MALE": "Male ",
  "post_id": "Post ID",
  "SEX": "Sex",
  "ALL_PHONE _NUMBERS": "Phone number",
  "CARE_STATUS": "Care status",
  "care_status": "Care status",
  "REACTION_TIME": "Interaction time ",
  "FILTER_BY_TIME": "Filter by time",
  "CITY": "City ",
  "DISTRICT": "District",
  "FILTER_BY_REGION": "Filter by region",
  "TOTAL_PAYMENT": "Total payment",
  "ORDERS_SHIPPED": "Orders shipped ",
  "ORDERS_SUCCESSFUL": "Successful order",
  "ORDER": "Order",
  "FILTER_BY_ORDER": "Filter by order",
  "CUSTOMER_CARE_STAFF": "Customer care staff",
  "SALE_STAFF": "Sale staff",
  "FLOW_POSTBACK_BUTTON_NAME_CONTENT": "button 1 (default by button orders)",
  "FLOW_NEW_ZALO": "Add New Zalo ZNS Message",
  "FLOW_NEW_FACEBOOK": "Add New Facebook Message",
  "FLOW_DELETE": "Delete Content Flow ",
  "FLOW_CONTEXT": "Send Message Flow",
  "FLOW_MESSAGE_CONTENT": "Type a content of message ",
  "FLOW_DESCRIPTION": "Description",
  "MESS_NAME_CONTENT": "Type a name of mess",
  "ACCOUNT_UPDATE": "Account Update",
  "ADD": "Add",
  "ADD_NEW": "Add New",
  "AND": "And",
  "BULK_MESSAGE": "Bulk Message",
  "CHANGE_SHOP": "Change Shop",
  "CONFIRMED_EVENT_UPDATE": "Confirm event update",
  "CONTAIN": "CONTAIN",
  "CUSTOMERS": "Customers",
  "CUSTOMER_ADDRESS": "Address",
  "CUSTOMER_BIRTHDAY": "Date of birth",
  "CUSTOMER_CREATE": "Create customers",
  "CUSTOMER_DELETE": "Delete",
  "CUSTOMER_DETAIL": "Details/edits",
  "CUSTOMER_FILTER": "Filter",
  "CUSTOMER_FILTER_BY_TAG": "Filter by tag",
  "CUSTOMER_FUNCTION": "Function",
  "CUSTOMER_GESTURES": "Gestures",
  "CUSTOMER_MANAGEMENT": "Customer Management",
  "CUSTOMER_NAME": "Customer name",
  "CUSTOMER_PHONE": "Phone",
  "CUSTOMER_SCHEDULE": "Select an appointment",
  "CUSTOMER_SEARCH": "Search",
  "CUSTOMER_SOURCES": "Sources",
  "CUSTOMER_TAGS": "Customer Tags",
  "CUSTOMER_UNTAG": "Untag",
  "DELETE": "Delete",
  "DETAIL_FLOW": "Flow Detail: {0}",
  "EDIT": "Edit",
  "EQUAL": "Equal",
  "FLOWS": "Flows",
  "FIRST_INTERACTION": "First interaction",
  "FLOW_ACTION_DELIVERED": "Action delivered",
  "LAST_INTERACTION": "Last interaction",
  "FLOW_ACTION_NEXT_DEFAULT": "Next default actions",
  "FLOW_ADD_BUTTONS": "Add buttons",
  "FLOW_ADD_CMR_ACTION": "Add CRM action",
  "FLOW_ADD_MESSAGES": "Add messages",
  "FLOW_AFTER_PURCHASE_CARE": "After-purchase care",
  "FLOW_ANSWER_AFTER_CUS_PRESS_BUTTON": "What would be your answer after customers press this button?",
  "FLOW_BUTTON_1": "Button 1 (default by button orders)",
  "FLOW_BUTTON_SETTING": "Button Setting",
  "FLOW_CHANGE_INTO_ZALO": "Change into Line",
  "FLOW_CHANGE_MESSAGE": "Change message forms",
  "FLOW_CHANGE_TYPE": "Change message forms",
  "FLOW_CHANGE_ZALO_CONTEXT": "Change into Line",
  "FLOW_CMR_ACTION": "CRM action",
  "FLOW_COMPLETE": "Complete",
  "FLOW_CONFIRM_DELETE_CONTEXT": "Confirm Delete Flow ",
  "FLOW_CONTEXT_PLACEHOLDER": "\bMessage 1",
  "FLOW_CREATE_NEW": "Create New Flow",
  "FLOW_DEFAULT_DELIVERY": "Next Default Actions ",
  "MESS_DELETE": "Delete",
  "MESS_DESCRIPTION": "Description",
  "MESS_FACEBOOK_ADD_BUTTON": "Add Buttons",
  "FLOW_FACEBOOK_BUTTON_NAME": "Title",
  "FLOW_FACEBOOK_BUTTON_POST_BACK_MESSAGE_CONTENT_LABEL": "what would be your answer after customers press this button?",
  "FLOW_FACEBOOK_BUTTON_POST_BACK_MESSAGE_CONTENT_PLACEHOLDER": "Content message should be showed here...",
  "FLOW_FACEBOOK_BUTTON_TYPE": "Type of Button",
  "FLOW_FACEBOOK_BUTTON_WEB_URL_LABEL": "Link to URL",
  "FLOW_FAILED_DELIVERY": "Action failed in delivery",
  "FLOW_FIRST_STEP": "First step",
  "FLOW_FIRST_STEP_2": "(drag and drop to change the Flow)",
  "FLOW_FLOW_NAME": "Flow name",
  "FLOW_INFORMATION": "Information",
  "FLOW_MESSAGE_DETAILS": "Message details",
  "FLOW_MESSAGE_NAME": "Message name",
  "FLOW_MESSAGE_TYPE": "Message type",
  "MESS_NEW_FACEBOOK": "Create New Facebook Message",
  "MESS_NEW_ZALO": "Create New Line Message",
  "FLOW_NEXT_CONTENT": "Add New Message ",
  "FLOW_PREVIEW": "The message content will be showed here",
  "FLOW_PUBLISH": "Publish",
  "FLOW_PUBLISHED": "Published",
  "FLOW_SCRIPT_FACEBOOK": "Facebook Messenger",
  "FLOW_SCRIPT_ZALO": "Flow setting with Zalo",
  "FLOW_SELECT_AVAILABLE": "Select available Flows",
  "FLOW_SETTING": "Flow setting",
  "FLOW_SETUP_CONTEXT": "Setup Flow",
  "FLOW_STEPS_USED": "Steps used (drag and drop to change orders)",
  "FLOW_SUCCESS_DELIVERY": "Action If Message Sent Successfully",
  "FLOW_TAGS": "Flow Tags",
  "FLOW_UPDATE_ACCOUNTS": "Update accounts",
  "FLOW_UPDATE_EVENTS": "Update events",
  "FLOW_VALIDATE_FACEBOOK_MESSAGE_CONTENT": "Please input message content",
  "FLOW_VALIDATE_FACEBOOK_MESSENGER_TAG": "Please select at least one message tag",
  "FLOW_VALIDATE_FLOW_NAME": "Please type a name of Flow ",
  "HUMAN_AGENT": "Human Agent",
  "INTEGRATION_PLATFORMS": "Integration Platforms ",
  "NO": "NO",
  "NOT_CONTAIN": "Not contain",
  "NOT_EQUAL": "Not equal",
  "OR": "OR",
  "title_tags": "Title tags",
  "page_id": "Page",
  "messenger_window_24h": "Messenger  Window 24h",
  "PAGE_TYPE": "Page Type",
  "POST_PURCHASE_UPDATE": "Post Purchase Update",
  "REDIRECT_TO_WEB_SHOPF1": "Redirect to Web Biglead",
  "move": "Move",
  "order_status": "Order status",
  "ORDER_STATUS_CHANGE": "Order status change",
  "FIELD_UPDATE": "Customer info update",
  "VIRAL_HISTORY": "Viral history",
  "SIGN_OUT": "SIGN OUT",
  "SLIDING_MENU_FLOW_CONTEXT": "Multi-channel Flow ",
  "SLIDING_MENU_TRIGGER": "Message Trigger",
  "SLIDING_SUB_MENU_FLOW_CONTEXT": "Flow",
  "GIFT": "Gift",
  "SLIDING_SUB_MENU_TRIGGER": "Trigger",
  "TAGS": "Tags",
  "TAG_ACTION": "Action",
  "TAG_CANCEL": "Cancel",
  "TAG_COLOR": "Tag color",
  "TAG_CREATE": "Create tag",
  "TAG_CREATE_2": "Create",
  "TAG_CREATOR": "Creator",
  "TAG_DATE_CREATED": "Date created",
  "TAG_DESCRIPTION": "Description",
  "TAG_MANAGEMENT": "Tag Management",
  "TAG_NUMBER_OF_USES": "Number of uses",
  "TAG_SELECT": "Select tag",
  "TAG_TAG_NAME": "Tag name ",
  "TRIGGERS": "Trigger Condition",
  "TAG_EDITABLE": "Tag (editable)",
  "TRIGGER_AND": "And",
  "TRIGGER_CONDITIONS": "Conditions",
  "TRIGGER_CONDITIONS_CUSTOMER": "Attachment conditions for customers",
  "TRIGGER_CONFIRM_DELETE_CONTEXT": "Are you sure to delete this trigger",
  "TRIGGER_CONTAINED": "Contained",
  "TRIGGER_CREATOR": "Creator",
  "TRIGGER_DATE_CREATED": "Date created",
  "TRIGGER_MESSAGE_BULK": "Bulk message",
  "TRIGGER_MUST_BE": "Must be",
  "TRIGGER_MUST_NOT_BE": "Must not be",
  "TRIGGER_NOT_CONTAINED": "Not contained",
  "Display": "Display",
  "COLUMN": "column",
  "month": "Month",
  "week": "Week",
  "NOTE_BILL": " Shipping note",
  "TRIGGER_OR": "Or",
  "TRIGGER_SELECT_FLOWS": "Select Flow",
  "TRIGGER_SETTING": "Trigger Setting",
  "TRIGGER_STATUS": "Status",
  "TRIGGER_TAG": "Trigger Tags",
  "TRIGGER_TAGS": "Sale Tags",
  "TRIGGER_TAG_NAME": "Tag name ",
  "TRIGGER_TITLE": "Trigger Title",
  "TRIGGER_TYPE": "Type of Trigger",
  "UPLOAD": "Upload",
  "YES": "Yes",
  "daily": "Daily",
  "monthly": "Monthly",
  "once": "once",
  "weekly": "weekly",
  "AUTO_REPLY_MESSAGE": "Auto reply message",
  "SUB_MENU_VIRAL": "Viral",
  "VIRAL_LIST": "Viral list",
  "viral_level_description": "Viral level description",
  "CREATE_VIRAL": "Create Viral",
  "viral_level_description_placeholder": "Detail",
  "Please input sub-title": "Please input sub-title",
  "SUB_MENU_BROADCAST": "Broadcast",
  "AUTO_INBOX": "Broadcast",
  "PURCHASED_STATUS": "Purchased status",
  "SELECT_POST": "Select post",
  "EXPECTED_OF_THE_NUMBER_OF_CUSTOMER": "Expected of the number of customer",
  "INPUT_BUTTON_NAME": "Button name",
  "UNLIMITED": "Unlimited",
  "CONFIG_ACTIVE_MESSAGE": "Trigger active message",
  "CUSTOMER_FILTER_BY_SALE_TAG": "Sale tags",
  "CUSTOMER_FILTER_BY_CHAT_TAG": "Chat tags",
  "CUSTOMER_FILTER_BY_NOTE_TAG": "Note tags",
  "CHAT_TAGS": "Chat tags",
  "CUSTOMER_NEW": "Customer new",
  "PLEASE_SELECT": "Please select",
  "SELECT_COLUMN_SHOW": "Select column show",
  "DISPLAY_COLUMN_LIMIT 9 TIP_SELECT_COLUMN": "Display column limit 9 tip select column",
  "DISPLAY_2_COLUMN": "Display 2 column",
  "DISPLAY_3_COLUMN": "Display 3 column",
  "DISPLAY_4_COLUMN": "Display 4 column",
  "DISPLAY_5_COLUMN": "Display 5 column",
  "DISPLAY_6_COLUMN": "Display 6 column",
  "DISPLAY_7_COLUMN": "Display 7 column",
  "DISPLAY_8_COLUMN": "Display 8 column",
  "DISPLAY_9_COLUMN": "Display 9 column",
  "CHOOSE_PAGE_EXPORT": "Choose page export",
  "conversation_tag": "Conversation tag",
  "title_auto_reply": "Auto Reply by both message and comment",
  "keywords_posts": "Select a post for auto reply",
  "email_footer": "Do not reply any email to this email address. This email sent automatically by our system. ",
  "login_message": "Please login to start your session",
  "text_forgot_password": "Forgot the password ?",
  "page_title_login": "Login Panel",
  "page_title_forgot": "Forgot Password",
  "forgot_message": "Enter your email address, to request password",
  "forgot_text_try_again": "Try login again ?",
  "text_no_notification": "You have 0 notifications",
  "text_view_all_notification": "View All",
  "label_button_profile": "Profile",
  "filter_dialog_title": "Advanced Sort & Filter",
  "there_is_no_option": "There is no any options setting for this inputType",
  "no": "No.",
  "text_enter_the_password": "Enter your password to retrieve your session",
  "text_or_sign_in": "Or sign in as a different user",
  "text_default_add_new_module": "Add New :module",
  "text_default_list_module": "List :module",
  "text_default_help_password": "Minimum 5 characters. Please leave empty if you did not change the password.",
  "text_default_help_upload": "File types support : JPG, JPEG, PNG, GIF, BMP",
  "text_default_help_number": "You can only enter the number only",
  "text_default_help_email": "Please enter a valid email address",
  "text_default_help_text": "You can only enter the letter only",
  "text_default_help_url": "Please enter a valid URL",
  "text_dashboard": "Dashboard",
  "text_delete": "Delete",
  "text_prefix_option": "** Please select a",
  "text_loading": "Please wait loading...",
  "message_after_logout": "Thank You, See You Later !",
  "message_forgot_password": "We have sent new password to your email, check inbox or spambox !",
  "page_not_found": "Oops! Page Not Found",
  "page_not_found_text": "We are unable search the page that you are looking for.",
  "page_not_found_tips": "* Please make sure the URL is correct<br/>                 * Please make sure the font capitalizing is correct because case sensitive<br/>                 * Maybe the page that you are looking for has been deleted by Admin<br/>                 * You can go back by click Back Button at browser<br/>",
  "log_try_view": "Try view the data :name at :module",
  "log_try_edit": "Try edit the data :name at :module",
  "log_try_add": "Try add data at :module",
  "log_try_add_save": "Try add the data :name data at :module",
  "log_try_delete": "Try delete the :name data at :module",
  "log_try_delete_selected": "Try delete selected at :module",
  "log_try_delete_image": "Try delete the image of :name data at :module",
  "log_try_update": "Try update the :name data at :module",
  "log_add": "Add New Data :name at :module",
  "log_update": "Update data :name at :module",
  "log_delete": "Delete data :name at :module",
  "log_delete_image": "Delete the image of :name at :module",
  "log_login": ":email login with IP Address :ip",
  "log_forgot": "Someone with IP :ip request a password for :email",
  "log_logout": ":email logout",
  "log_change_shop": "",
  "alert_delete_selected": "Are you sure want to delete all selected data ?",
  "alert_delete_selected_success": "Delete selected success !",
  "alert_bulk_action_button": "Are you sure want to",
  "alert_action": "You have :action successfully !",
  "alert_add_data_success": "The data has been added !",
  "alert_add_data_failed": "Failed to save the data !",
  "alert_update_data_success": "The data has been updated !",
  "alert_update_data_failed": "Failed to update the data",
  "alert_delete_data_success": "Delete the data success !",
  "alert_want_to_logout": "Do you want to logout ?",
  "alert_warning": "Oops",
  "alert_danger": "Something went wrong ....",
  "alert_success": "Wow, good job...",
  "alert_info": "Information",
  "alert_primary": "Information",
  "alert_session_expired": "Your session was expired, please login again !",
  "alert_password_wrong": "Sorry your password is wrong !",
  "alert_required": "Please make sure to fill out all the required fields correctly",
  "alert_error_ajax": "Okay, It is embarasing, something went wrong, we will fix it soon !",
  "alert_validation_error": "Please fill out the form correctly : :error",
  "add_data_page_title": "Add :module",
  "edit_data_page_title": "Edit :module",
  "detail_data_page_title": "Detail :module",
  "default_module_description": "Data List",
  "delete_description_confirm": "You will not be able to recover this record data!",
  "delete_title_confirm": "Are you sure ?",
  "CONFIGURATION": "Configuration",
  "action_show_data": "Show Data",
  "action_add_data": "Add Data",
  "action_delete_selected": "Delete Selected",
  "action_label": "Action",
  "action_detail_data": "Detail Data",
  "action_edit_data": "Edit Data",
  "action_delete_data": "Delete",
  "button_cancel": "Cancel",
  "button_add": "Add Data",
  "button_edit": "Edit Data",
  "button_back": "Back",
  "button_save": "Save",
  "button_save_more": "Save & Add More",
  "button_import": "Import Data",
  "button_export": "Export Data",
  "button_filter": "Sort & Filter",
  "button_close": "Close",
  "button_submit": "Submit",
  "button_logout": "Logout",
  "button_sign_in": "Sign In",
  "button_selected_action": "Bulk Actions",
  "button_download_file": "Download file",
  "button_reset": "Reset",
  "export_dialog_title": "Export Data",
  "export_dialog_filename": "File Name",
  "export_dialog_help_filename": "You can rename the filename according to your whises",
  "export_dialog_maxdata": "Max Data",
  "export_dialog_help_maxdata": "Minimum 1 and maximum 100,000 rows per export session",
  "export_dialog_columns": "Columns ",
  "export_dialog_format_export": "Format Export",
  "export_dialog_show_advanced": "Show Advanced Export",
  "export_dialog_page_size": "Page Size",
  "export_dialog_set_default": "Set As Default Paper Size",
  "export_dialog_page_orientation": "Page Orientation",
  "import_page_title": "Import Data :module",
  "dashboard_default_text": "Welcome to the dashboard of :appname",
  "form_back_to_list": "Back To List Data :module",
  "not_logged_in": "You are not logged in !",
  "menu_navigation": "Navigation",
  "table_data_not_found": "No Data Avaliable",
  "notice_delete_file_upload": "* If you want to upload other file, please first delete the file.",
  "file_broken": "Oops looks like File was Broken !. Click Delete and Re-Upload.",
  "denied_access": "Sorry you do not have privilege to access this area !",
  "denied_update": "Sorry you do not have privilege to update the data !",
  "denied_create": "Sorry you do not have privilege to create the data !",
  "denied_delete": "Sorry you do not have privilege to delete the data !",
  "user_image": "User Image",
  "online": "Online",
  "controller_route_404": "Controller / Route Not Found",
  "home": "Home",
  "Privileges_Roles": "Privileges Roles",
  "Add_New_Privilege": "Add New Privilege",
  "List_Privilege": "List Privilege",
  "add_user": "Add User",
  "List_users": "List Users",
  "Menu_Management": "Menu Management",
  "Add_New_Setting": "Add New Setting",
  "Add_New_Module": "Add New Module",
  "List_Module": "List Module",
  "Add_New_Statistic": "Add New Statistic",
  "List_Statistic": "List Statistic",
  "Add_New_API": "Add New API",
  "API_Generator": "API Generator",
  "Statistic_Builder": "Statistic Builder",
  "Module_Generator": "Module Generator",
  "settings": "Settings",
  "Users_Management": "Users Management",
  "list_API": "List API (Documentation)",
  "Generate_Screet_Key": "Generate Secret Key",
  "Add_New_Email": "Add New Email",
  "List_Email_Template": "List Email Template",
  "Email_Templates": "Email Templates",
  "Log_User_Access": "Log User Access",
  "Reload_All_Message": "Reload All Message",
  "SUPERADMIN": "SUPERADMIN",
  "click_here": "Click here",
  "right": "Right",
  "left": "Left",
  "all_rights_reserved": "All Rights Reserved",
  "copyright": "Copyright",
  "powered_by": "Powered by",
  "notification_you_have": "You Have",
  "notification_notification": "Notifications",
  "notification_new": "You have a new notification !",
  "login_register_style": "Login Register Style",
  "email_setting": "Email Setting",
  "application_setting": "Application Setting",
  "filter_select_operator_type": "Select Operator Type",
  "filter_ascending": "ASCENDING",
  "filter_descending": "DESCENDING",
  "filter_equal_to": "=",
  "filter_like": "LIKE",
  "filter_not_like": "NOT LIKE",
  "filter_greater_than_or_equal": ">=",
  "filter_less_than_or_equal": "<=",
  "filter_less_than": "<",
  "filter_greater_than": ">",
  "filter_not_equal_to": "!= (Not Equal to)",
  "filter_in": "IN",
  "filter_not_in": "NOT IN",
  "filter_between": "BETWEEN",
  "filter_sort_ascending": "Sort ascending",
  "filter_sort_descending": "Sort Descending",
  "filter_eg": "e.g",
  "filter_lorem_ipsum": "Lorem ipsum",
  "filter_lorem_ipsum_dolor_sit": "Lorem,Ipsum,Dolor Sit",
  "filter_search": "Search",
  "filter_from": "from",
  "filter_to": "to",
  "confirmation_yes": "Yes!",
  "confirmation_no": "No",
  "chose_an_image": "Choose an image",
  "chose_an_file": "Choose an file",
  "confirmation_title": "Confirmation",
  "confirmation_text": "Are you sure want to do this action?",
  "confirmButtonText": "Yes",
  "privileges_name": "Privilege Name",
  "privileges_super_admin": "Super Admin",
  "privileges_standart_user": "Standart user",
  "privileges_ID": "ID",
  "set_as_superadmin": "Set as Superadmin",
  "chose_theme_color_select": "** Choose Backend Theme Color",
  "chose_theme_color": "Theme Color",
  "privileges_configuration": "Privileges Configuration",
  "privileges_module_list_no": "No.",
  "privileges_module_list_mod_names": "Module\\'s Name",
  "privileges_module_list_view": "View",
  "privileges_module_list_create": "Create",
  "privileges_module_list_read": "Read",
  "privileges_module_list_update": "Update",
  "privileges_module_list_delete": "Delete",
  "not_admin": "",
  "Admin_Shops": "",
  "title_code_free_trial": "Please enter your free trial code in here",
  "can_not_active_free_trial_because_your_current_service_pack_is_being_activated": "can not active free trial because your current service pack is being activated",
  "wrong_free_trial_code": "Wrong free trial code",
  "active_free_trial_success": "Activate the free trial code success",
  "activate_free_trial_code": "Activate the free trial code",
  "account": "Account",
  "alert_pages_had_been_suspended": "had been suspended, Please add them again to ShopF1 or contact your administrator.",
  "select_page": "Select page",
  "conversation": "Conversation",
  "posts": "Posts",
  "alert_promotion_for_referral": "<p><strong class=\"text-yellow\">(HOT)</strong> Get <strong style=\"color: yellow\">1 month</strong> using free and a<strong style=\"color: yellow\">10%</strong> discount voucher when sending referral code -> <a href=\"https://web.shopf1.net/affiliate\">Detail.</a></p>",
  "title_guide_click_to_chat": "Pages (Click on 1 page to start)",
  "datamodal_select": "Select",
  "datamodal_search_and_enter": "Search and enter...",
  "datamodal_enter_to_search": "Enter to search",
  "datamodal_browse_data": "Browse Data",
  "add_facebook_page": "Add Facebook page",
  "add_zalo_page": "Add Zalo page",
  "add_instargram_account": "Add Instargram account",
  "start": "Start",
  "title_guide_click_to_activate": "Facebook pages haven't been activated (select a page to activate)",
  "no_pages_have_been_activated": "No pages have been activated",
  "activate": "Activate",
  "conversations": "Conversations",
  "conversations_contain_the_phone_number": "Conversations with phone number",
  "sales": "Sales",
  "sent_orders": "Sent orders",
  "select_page_to_merge": "Select page (Merged)",
  "merging_page": "Merge pages",
  "page_config": "Page Config",
  "token_management": "Token management",
  "general_settings": "General",
  "shop_general_settings": "General settings",
  "filter": "Filter",
  "sync_messages_with_fb": "Sync messages with Facebook",
  "1_day": "1 day",
  "filter_by_message": "Filter by message",
  "comment": "Comment",
  "filter_by_comment": "Filter by comment",
  "contain_phone": "Phone",
  "filter_with_phone": "Filter with phone number",
  "none_phone": "None phone",
  "filter_without_phone": "Filter without phone number",
  "start_time": "Start time",
  "filter_start_time": "Filter by start time",
  "end_time": "End time",
  "filter_by_end_time": "Filter by end time",
  "unread": "Unread",
  "filter_by_unread": "Filter by unread conversation",
  "employee": "Employee",
  "filter_by_employee": "Filter by employee",
  "tags": "Tags",
  "filter_by_tags": "Filter by Tags",
  "unanswered": "Unanswered",
  "filter_by_unanswered": "Filter by unanswered conversastion",
  "clear_filter": "Clear filter",
  "all": "All",
  "report_error": "Report",
  "mark_all_as_read": "Mark all as read",
  "let_select_a_conversation_to_start": "Let select a conversation to start",
  "mark_as_unread": "Mark as unread",
  "being_processed_by": "Being processed by",
  "being_replied_by": "Being replied by",
  "phone_number_is_copied": "The phone number is copied.",
  "phone_number": "Phone number",
  "get_phone_number": "Get phone number",
  "today": "Today",
  "page": "Page",
  "token": "Token",
  "warning_token_has_expired_1": "The Facebook token is expired",
  "warning_token_has_expired_2": "Please go to the Homepage to deactivate, then add it again",
  "can_not_get_page_cause_token_is_expired": "Cannot retrieve the Fanpage list due to valid user token. Please log out then log in again",
  "logout": "Log out",
  "keep_using": "No, Keep using",
  "select_pages_to_activate": "Select the pages below to activate",
  "alert_service_pack_has_expired": "Your service pack has expired, please buy it to use",
  "alert_service_pack_is_free_trial_1": "You are using",
  "alert_service_pack_is_free_trial_2": "upgrade the service pack",
  "alert_service_pack_is_free_trial_3": "here",
  "alert_service_pack_is_about_expired_1": "You are using",
  "alert_service_pack_is_about_expired_2": "has",
  "alert_service_pack_is_about_expired_3": "days left to use. Upgrade the service pack",
  "alert_service_pack_is_about_expired_4": "here",
  "sync_messages_with_fb_in_one_day": "Sync messages with Facebook in one day",
  "change_shop": "Change shop",
  "shop_schedule": "Shop's schedule",
  "open_on_facebook": "Open on Facebook",
  "open_on_facebook_messenger": "Open on Facebook messenger",
  "assign_the_conversation_for_an_employee": "Assign this conversation for an employee",
  "sync_this_conversation_with_facebook": "Sync this conversation with Facebook",
  "schedule": "Schedule a message to the customer",
  "seen_by": "Seen by",
  "mark_as": "Mark as",
  "enter_your_message": "Enter your message/ Type quick answer:  + Title",
  "send_image": "Send image",
  "quick_message": "Quick message",
  "quick_messages": "Quick messages",
  "guide_enter_to_send": "Press Enter to send",
  "guide_enter_to_make_a_new_line": "Press Shift + Enter to make a new line",
  "information": "Information",
  "not_notes_yet": "Not notes yet",
  "enter_your_note": "Enter your note",
  "assign_tags_to_the_customer": "Assign tags to the customer",
  "enter_your_tags": "Enter your tags",
  "orders": "Orders",
  "unable_to_get_uid": "Unable to get UID",
  "select_a_conversation_on_left_to_start": "Select a conversation on left to start",
  "change_like_status": "Change the like status on Facebook",
  "send_private_message": "Send private message",
  "private_message_content": "Private message content",
  "change_hidden_status": "Change the hidden status on Facebook",
  "open_this_comment_on_facebook": "Open this comment on Facebook",
  "reply_comment_instagram": "Reply this comment",
  "select_reply": "Select reply",
  "this_message_type_is_not_supported": "This message type is not supported",
  "alert_comment_will_be_deleted": "This comment will be deleted and you can't undo, do you want to continue?",
  "delete_comment": "Delete comment",
  "alert_time_schedule": "The scheduled time must be greater than the current time",
  "copied_to_clip_tray": "Copied the phone number to clip tray",
  "the_phone_number_is_found": "Đã tìm thấy",
  "scanning_the_phone_number": "Scanning the phone number...",
  "alert_prerequisite_to_scanning_the_phone_number": "Using GetPhone and showing customer profile feature, \\n please install or reinstall Shopf1 extension on chrome",
  "alert_prerequisite_to_scanning_the_phone_number_html": "Watching instruction ( <a href=\"https://bit.ly/2IElEaW\" target=\"_blank\">Video</a>, <a href=\"https://chrome.google.com/webstore/detail/shopf1-getphone-profile/mddnnphkdocecagmhegjnaehabjecjlg\" target=\"_blank\">install now</a> )<br /><a href=\"https://www.facebook.com/messages/t/ShopF1.net\" style=\"display: block; margin-top: 20px\" target=\"_blank\"><strong>Contact to support</strong></a>",
  "alert_prerequisite_to_scanning_the_phone_number_2": "Using GetPhone and showing customer profile feature. Please log in to Facebook",
  "success": "Success",
  "marked_as_unread": "Marked as unread",
  "schedule_success": "Schedule success",
  "click_here_to_view_scheduled": "Click here to view scheduled",
  "error_from_server": "Server error, please try again",
  "error_information": "Information error, please try again",
  "error_user_can_not_find_uid": "User error: unable to find UID or you don't have some permissions of this Facebook page",
  "choose_image": "Choose image",
  "delete_image": "Delete image",
  "upload_image": "Upload image",
  "done": "Done",
  "documentation": "Documentation",
  "video_tutorial": "Video tutorial",
  "Subtitle": "Subtitle",
  "Title_button": "Title button",
  "overview": "Overview",
  "unread_conversations_to_top": "Unread conversations to the top",
  "campaigns_list": "Campaigns",
  "TITLE": "Title",
  "ELEMENT_TITLE": "Element title",
  "not_found": "Not found",
  "go_to_instruction": "Go to instruction for use",
  "add_campaign": "New campaign",
  "sending_schedule": "Sending schedule",
  "campaign_title": "Title",
  "expected_number": "Expected number",
  "limited": "Limited",
  "status": "Status",
  "date_created": "Date created",
  "run": "Run",
  "edit": "Edit",
  "delete": "Delete",
  "history_of_campaign": "History",
  "start_date": "Start date",
  "number_of_messages_sent": "Sent",
  "create_a_new_campaign": "Create a new campaign",
  "campaign_information": "Campaign information",
  "send_by_post": "Send by post",
  "send_now": "Send now",
  "manual": "Manual",
  "loop_mode": "Loop mode",
  "yes": "Yes",
  "bought": "Bought",
  "time_customer_sent": "Time customer sent",
  "time_customer_sent_1": "Including before and after activating the page on Shopf1 (slow speed - large number of customers)",
  "time_customer_sent_2": "After activating the page on Shopf1 (high speed - small number of customers)",
  "time_customer_sent_3": "By time period ( active date of page: )",
  "conversation_with_tags": "Conversation with tags",
  "conversation_without_tags": "Conversation without tags",
  "including": "Including",
  "excluding": "Excluding",
  "guiding_for_upload_uid_file": "Upload files in text format (.txt) and one Uid per line, limited to 1000 UID",
  "message_content": "Message content",
  "add": "Add",
  "content": " content",
  "campaign_image_column": "Photos (Multiple pictures are separated by \";\")",
  "action": "Action",
  "send": "Send",
  "hide": "Hide",
  "edit_campaign": "Edit campaign",
  "select_post": "Select post",
  "guide_auto_normal_inbox": "Normal message (must have at least three content)",
  "business_day": "Business day",
  "weekend": "Weekend",
  "mon": "Mon",
  "tue": "Tue",
  "wed": "Wed",
  "thu": "Thu",
  "fri": "Fri",
  "sat": "Sat",
  "sun": "Sun",
  "sending_time_in_month": "Sending time in the month",
  "sending_time": "Sending time",
  "download": "Download",
  "successful_order": "Successful order",
  "to": "To",
  "money_spent": "Spent money",
  "last_bought": "Last bough",
  "your_browser_do_not_support_html5": "Your browser does not support HTML5",
  "message_content_can_not_empty": "Message content cannot be empty",
  "message_content_must_have_full_name": "Message content must have ##full_name##",
  "message_content_must_be_less_than_1200_characters": "Message content must be less than 1200 characters",
  "you_have_to_choose_the_start_date": "You have to choose the start date",
  "you_have_to_choose_the_end_date": "You have to choose the end date",
  "pls_enter_the_title": "Please fill the title",
  "pls_enter_sending_time": "Please fill the sending time",
  "uid_number_cannot_exceed_100": "the number of UID cannot exceed 100",
  "pls_choose_a_viral_chat_script": "Please choose a Viral Chat script",
  "no_uid": "No UID",
  "emoji": "Emoji",
  "full_name": "NAME TAG",
  "insert_image": "INSET IMAGE",
  "day": "Day",
  "number": "Number",
  "image_link": "Image link",
  "confirm": "Confirm",
  "cancel": "Cancel",
  "error": "Error",
  "end_date": "End date",
  "instructions_for_use": "Instructions for use",
  "processing": "Processing",
  "view_record_menu": "View record _MENU_",
  "no_data": "No data",
  "watching_from_start_to_end": "Watching from _START_ to _END_ in _TOTAL_ records",
  "watching_empty": "Watching from 0 to 0 in 0 records",
  "filtered_from_records": "(Filtered from _MAX_ records)",
  "search:": "Search:",
  "first": "First",
  "previous": "Previous",
  "next": "Next",
  "last": "Last",
  "no_script_on_your_fanpage": "Your fanpage have no Viral Chat script",
  "employee_name": "Employee name",
  "last_modified": "Last modified",
  "add_update_token": "Add/Update your tokens",
  "are_you_sure_delete_token": "Are you sure to delete this token?",
  "cannot_find_your_token": "Cannot find your token. You have to login with Facebook to perform this action",
  "your_token_has_been_expired": "Your token has been expired. Please re-login to ShopF1 then try again",
  "not_found_this_fanpage": "Not found this fanpage. Please check that you have the right to manage this fanpage on Facebook?",
  "delete_success": "Deleted success",
  "copy_configuration_from_another_page": "Copy configuration from another page",
  "new_message_notification": "New message notification",
  "message_notification_on_mobile": "Message notification on mobile",
  "notification_sound": "Notification sound",
  "auto_like_comment": "Auto like comments",
  "auto_hide_comment": "Auto hide comments",
  "auto_hide_comment_with_phone_number": "Auto hide comments with phone number",
  "auto_hide_comment_with_keywords": "Auto hide comments with keywords",
  "auto_unhide_comment_after": "Auto unhide comments after",
  "message_font_size": "Message font size",
  "auto_delete_comment_after": "Auto delete comments after",
  "color_of_chatbox": "Color of chatbox",
  "conversation_tags": "Conversation tags",
  "assign_conversations_to_employee": "Assign conversations to employees",
  "reassign_conversations_to_employee": "Reassign conversations to employees (if assigned employees are removed, conversations will be assigned to other employees)",
  "hide_conversations_with_unassign": "Hide conversations with unassign employees",
  "hide_conversations_employee": "Hide conversations employees",
  "copy": "Copy",
  "keywords_cannot_be_empty": "Keywords cannot be empty",
  "cannot_enable_two_functions": "You cannot enable (auto-reply conversation) & (auto-reply comments & private message) at the same time. Please disable one of two functions",
  "guide_number_of_days_auto_unhide": "Number of days that auto-unhide comments must be greater than 0",
  "guide_missed_conversation_time": "Missed conversation time must be greater than 0",
  "conversation_tags_cannot_exceed_20": "Conversation tags cannot exceed 20",
  "pls_select_a_page_to_copy": "Please select a page to copy",
  "minutes": "Minutes",
  "no_phone_number": "No phone number",
  "add_quick_message": "New quick message",
  "configure": "Configure",
  "new_tag": "New tag",
  "name_color_of_tag": "Name - color of tag",
  "automatically_tag_by_keywords": "Automatically tag by keywords",
  "tag_name_cannot_be_empty": "Tag name cannot be empty",
  "auto_reply_conversations": "Auto reply conversation",
  "new_reply": "New reply content",
  "reply_message": "Reply messages",
  "reply_time": "Reply time",
  "immediately": "Immediately",
  "missed_conversation": "Missed conversation",
  "reply_comment": "Reply comments",
  "reply_type": "Reply type",
  "guide_config_auto_reply": "You need to configure automatic reply for both customers with and without phone number",
  "enable": "Enable",
  "disable": "Disable",
  "title_auto_reply_and_send_message": "Auto reply comments and send the private message",
  "on": "On",
  "off": "Off",
  "condition_type": "Condition type",
  "comments_with_phone_number": "Comments with phone number",
  "unidentified": "Unidentified",
  "other_comment_type": "Other comment types",
  "other_with_keywords": "Comments with keywords",
  "select_condition_type": "Select a condition type",
  "pls_select_a_condition": "Please select a condition type first",
  "reply_content": "Reply content",
  "condition_content": "Condition content",
  "condition_content_cannot_be_empty": "The condition content cannot be empty",
  "reply_content_cannot_be_empty": "The reply content cannot be empty",
  "condition_contents_cannot_be_duplicated": "The condition contents cannot be duplicated",
  "enter_keywords": "Enter keywords",
  "update_configuration_successful": "Update configuration successful",
  "processing_login": "Processing login",
  "you_not_login": "You are not logged in. Please log in to using",
  "get_start": "Get Start",
  "login_failed": "Login failed",
  "redirect_after": "Redirect after",
  "redirect_now": "Redirect now",
  "select_shop": "Select shop",
  "next_to_chat": "Next",
  "loading_fanpages": "Loading fanpages",
  "please_select_at_least_one_page": "Please select at least one page to start chatting",
  "no_customer_information": "No customer information",
  "synchronized_successful": "Successful synchronized",
  "synchronize_failed": "Synchronize failed",
  "shop_settings": "Shop settings",
  "page_settings": "Page settings",
  "get_token": "Get token",
  "auto_reply": "Auto reply",
  "functions": "Functions",
  "show_all_tags": "Show all tags",
  "filter_by_multiple_tags": "Filter by multiple tags",
  "filter_by_or_condition": "Filter by OR condition (A or B)",
  "filter_by_and_condition": "Filter by AND condition (A AND B)",
  "saving_data": "Saving settings",
  "Successfully_saved": "Successfully saved",
  "pls_select_a_shop_and_a_page": "Please select a shop and fanpage",
  "days": "Days",
  "get_user_token_user_for_personal": "Get user Token, use for personal Facebook functions",
  "you_have_not_installed_extension": "You have not installed the Extension of ShopF1",
  "pls_install_extension": "Please install the extension to use more functions",
  "checking_fb_login_status": "Checking the status of Facebook login",
  "you_are_not_login_to_fb": "You are not logged in to Facebook",
  "pls_login_to_fb": "Please login to Facebook to use the function",
  "getting_token": "Getting token",
  "your_token": "YOUR TOKEN: ",
  "copied_to_clipboard": "Copied to clipboard",
  "get_token_success": "Get token success",
  "get_token_failed": "Get token failed",
  "install": "Install",
  "employee_assignment": "Employee assignment",
  "assigning_employees_for_pages": "Assigning employees for pages",
  "assign_employees_able_access": "Assign employees able to access this page",
  "update_your_tags": "Update your tags",
  "tag": "Tag",
  "customize_quick_message": "Customize quick messages",
  "no_images": "No images",
  "update_quick_messages": "Update quick messages",
  "click_to_delete_this_image": "Click to delete this image",
  "inset_customer_full_name": "Insert customer full name",
  "pls_enter_your_message_or_choose_image": "Please enter your message or choose an image",
  "keywords_content": "Keywords/content",
  "keywords": "Keywords",
  "messages": "Messages",
  "comments": "Comments",
  "customer_reminder_message": "Customer reminder messages",
  "configuration": "Configuration!",
  "saving_settings": "Saving settings",
  "message_content_reminds_customers": "Message content reminds customers",
  "this_field_cannot_be_empty": "This filed cannot be empty",
  "placeholder_message_content_reminds_customers": "Hello ##full_name##! today ##full_name## you have an appointment",
  "message_will_be_sent_now": "The message will be sent immediately",
  "placeholder_message_will_be_sent_now": "Hello ## full_name ##, hh day dd / mm / yyyy ## full_name ## you have an appointment with shop. ## full_name ## remember.",
  "more": "More",
  "interactive_history": "Interactive history",
  "you_must_enable_start_button_to_use_this_function": "You must enable the \"Start\" button to use this function.",
  "enable_start": "Enable start",
  "template_list": "Template list",
  "disable_start": "Disable start",
  "new_template": "New template",
  "template_title": "Template title",
  "last_update": "Last update",
  "postback": "Postback",
  "view_statistics": "View statistics",
  "update": "Update",
  "service_pack_not_include_viral_chat": "Service pack doesn't include ViralChat",
  "add_edit_template": "Add/ Edit template",
  "require_reveiver_to_leave_a_contact": "Require receiver to leave a contact",
  "receiver_level_1": "Receiver level 1",
  "receiver_level_2": "Receiver level 2",
  "name_of_campaign": "Name of campaign",
  "name_of_campaign_placeholder": "Get a free gift a value of ... USD",
  "describe_campaign": "Describe campaign",
  "describe_campaign_placeholder": "Content",
  "share_button": "Share button",
  "share": "Share",
  "receive_gift": "Receive a gift",
  "receive_gift_button": "Receive gift button",
  "a_total_number_of_people": "A total number of people",
  "number_of_people_to_share": "Number of people to share",
  "description_is_the_sent_to_the_first_receiver_after_giving_a_contact": "Message is the sent to the first receiver after giving a contact",
  "the_number_of_sender": "The number of sender",
  "the_number_receiver": "The number of receiver",
  "the_number_of_sender_the_number_receiver_example": "You need to share ##ref_number## people to receive the gift",
  "the_message_is_to_gift_receiver_after_introducing_one_person_successfully": "The message is to gift receiver after introducing one person successfully",
  "the_number_of_sender_the_total_number_of_people_required_to_send": "",
  "the_number_of_sender_the_total_number_of_people_required_to_send_placeholder": "",
  "the_message_sent_to_gift_receriver_fater_finishing_requirement": "The message sent to gift receriver afater finishing requirement",
  "the_message_sent_to_gift_receriver_fater_finishing_requirement_placeholder": "Congratulations!",
  "template_is_not_found": "Template is not found",
  "template_name_cannot_be_empty": "Template name cannot be empty",
  "add_template_successful": "Add template successful",
  "please_select_a_sharing_image": "Please select a sharing image",
  "please_fill_sharing_title": "Please fill sharing title",
  "please_fill_sharing_desciption": "Please fill sharing desciption",
  "please_select_an_image_of_gift": "Please select an image of gift",
  "please_fill_title_of_gift": "Please fill title of gift",
  "please_fill_description_of_gift": "Please fill description of gift",
  "please_fill_title_of_receive_gift_button": "Please fill title of receive gift button",
  "please_fill_number_of_successful_referrals": "Please fill number of successful referrals",
  "please_fill_the_number_of_sender_the_total_number_of_people_required_to_send": "Please fill the number of sender the total number of people required to send",
  "alert_viral_chat_10": "",
  "dashboard": "dashboard",
  "list_orders": "List orders",
  "add_orders": "Add orders",
  "soat_orders": "Soat orders",
  "product": "Product",
  "list_product": "List product",
  "add_product": "Add product",
  "kho": "Warehouse",
  "customer": "Customer",
  "list_customer": "List customer",
  "add_customer": "Add customer",
  "care": "",
  "auto": "",
  "viral": "",
  "marketing": "Maketing",
  "BIG": "Facebook\t",
  "BIG1": "Auto reply to comments",
  "BIG2": "Pages can connect up to 02 Pages",
  "BIG3": "Assign staff permissions to the page that needs to be taken care of",
  "BIG4": "Private message for comments",
  "BIG5": "Basic Monthly Plan",
  "BIG6": "Year",
  "BIG7": "View details",
  "BIG8": "Select",
  "BIG9": "Messenger",
  "BIG10": "Chat messages",
  "BIG11": "Distributing messages to employees",
  "BIG12": "Auto reply with 1 message",
  "BIG13": "Conversation cards: 1 different type",
  "BIG14": "Instagram",
  "BIG15": "Reply to direct messages",
  "BIG16": "Reply to comment",
  "BIG17": "Zalo",
  "BIG18": "Marketing and customer care",
  "BIG19": "Sales Manager",
  "BIG20": "Resources used",
  "BIG21": "02 pages ",
  "BIG22": "02 users",
  "BIG23": "Big LEAD is a funnel that collects all customers from social networks to take care of one place. It will help you from messaging to introduce products, sales, to scheduled care on many channels",
  "BIG24": "Introduce",
  "BIG25": "Feature",
  "BIG26": "",
  "BIG27": "Solution",
  "BIG28": "Blog",
  "BIG29": "Contact Us",
  "BIG30": "",
  "BIG31": "Price",
  "BIG32": "Payment",
  "BIG33": "Term",
  "BIG34": "",
  "BIG35": "",
  "BIG36": "Floor 9, TH office tower  No. 142B, De La Thang Street, Tho Quan, Dong Da district, Hanoi, Vietnam",
  "BIG37": "Phone: (+84) 939 55 6336 ",
  "BIG38": "Email: contactshoptool.vn",
  "BIG39": "IDO E-COMMERCE SERVICES COMPANY LIMITED",
  "BIG40": "Certificate of business registration, enterprise code: 0107871803, first registered on 05/06/2017, registered for the first time on 12/26/2018, issued by Hanoi Department of Planning and Investment.",
  "BIG41": "© 2022 Biglead All Rights Reserved.",
  "BIG42": "",
  "BIG43": "",
  "BIG44": "",
  "BIG45": "Mr. Nguyen Xuan Hop",
  "BIG46": "Biglead helps to take care of regular customers according to an established periodic schedule",
  "BIG47": "",
  "BIG48": "Contact Details",
  "BIG49": "Login",
  "BIG50": "You need know",
  "BIG51": "Omni channels customer care",
  "BIG52": "In 2022",
  "BIG53": "Chat directly with guests from Fb, Zalo, Ins, Line",
  "BIG54": "Marketing messages in multiple channels, chat bot ",
  "BIG55": "Advertising and customer service tracking ",
  "BIG56": "Free Trial",
  "BIG57": "Watch video ",
  "BIG58": "What can BigLead do? ",
  "BIG59": "Live chat 1 place guests from Fb, Za, Line, Ins",
  "BIG60": "Re-marketing tools integration, mass messages via Fb, zalo, email, callbot",
  "BIG61": "Customer management with Chat tags and Sale tags ",
  "BIG62": "Live chat in all platforms ",
  "BIG63": "Extended customer service features integration offline such as wifi marketing, camera AI/POS",
  "BIG64": "Customer interaction analytics report in all social platforms",
  "BIG65": "Why you should choose BigLead?",
  "BIG66": "Multiple customer service channels: Fb/ Zalo/ Line/ Voice/ Email ",
  "BIG67": "Chat to combine channels on 1 App",
  "BIG68": "Integrate with CRM/ERP",
  "BIG69": "Integrated mass messaging tool Facebook / Zalo / Callbot / Email / SMS",
  "BIG70": "Take care of customers without being missed: review the care history and book a follow-up care appointment",
  "BIG71": "Our solutions",
  "BIG72": "Customers are provided with tools to optimize customer servce and increase sales",
  "BIG73": "Learn More ",
  "BIG74": "Deputy director - Joint stock company  Bo Cau - Dove express",
  "BIG75": "Annual",
  "BIG76": "Monthly",
  "BIG77": "BASIC",
  "BIG78": "PRO",
  "BIG79": "BUSINESS",
  "BIG80": "Contact us to get free set up when subscribing to annual plan",
  "BIG81": "Customer service",
  "BIG82": "Payment Guide",
  "BIG83": "Online payment or bank transfer. Details ",
  "BIG84": "User manual",
  "BIG85": "Video guide for all functions. Details ",
  "BIG86": "Social Messaging CRM",
  "BIG87": "Social Messaging CRM Open",
  "BIG88": "Enter your name*",
  "BIG89": "Enter your email address*",
  "BIG90": "Write your message",
  "BIG91": "Send Message",
  "BIG92": "Office Address",
  "BIG93": "Contact Info",
  "BIG94": "Opps! Something Wrong",
  "BIG95": "We’re sorry, the page you have looked for does not exist in our database! Maybe go to our home page or try to use a search?",
  "BIG96": "Go Back to Home",
  "BIG97": "Page can connect up to 10 pages",
  "BIG99": "Export all customers from Page to Excel",
  "BIG100": "Interactive Report on Page",
  "BIG101": "Unlimited maximum connection",
  "BIG102": "Automatically reply by sending product catalog, with confirmation of receipt information",
  "BIG103": "Send scheduled messages",
  "BIG104": "Automatically reply with multiple messages according to Sales scenario",
  "BIG105": "Unlimited keyword settings",
  "BIG106": "Conversation cards: 3 different types",
  "BIG107": "Mass messaging via Facebook Pages (5000 guests)",
  "BIG108": "Mass messaging via Zalo OA (2000 guests)",
  "BIG109": "Mass messaging for customers via Zalo ZNS",
  "BIG110": "Viral messaging for product marketing",
  "BIG111": "Take care of customers by card, save care history",
  "BIG112": "Mass messaging via Facebook Pages (Unlimited)",
  "BIG114": "Mass messaging via Zalo OA (Unlimited)",
  "BIG115": "Mass messaging with a series of multiple messages via Fb, Za, Email",
  "BIG116": "Export customer list to Excel file from multi-channel",
  "BIG117": "Connect shipping units, make orders - update shipping prices, track order status",
  "BIG118": "Manage export/import/storage",
  "BIG119": "Report the number of guests the staff took care of",
  "BIG120": "Report the number of orders closed by employees",
  "BIG121": "Report on advertising effectiveness",
  "BIG122": "Report sales, revenue, freight, returns",
  "BIG123": "05 pages",
  "BIG124": "05 user",
  "BIG125": "Terms of use",
  "BIG126": "Category",
  "BIG127": "",
  "BIG128": "",
  "BIG129": "Privacy Terms",
  "BIG130": "",
  "BIG131": "Type to search...",
  "BIG132": "",
  "BIG133": "",
  "BIG134": "Messaging Flow Automation ",
  "BIG135": "Camera AI",
  "BIG136": "Multi live-chat with customers from Fb, Zalo, Ins, Line, Web ",
  "BIG137": "",
  "BIG138": "",
  "BIG139": "Marketing messages in multiple channels, chat bot ",
  "BIG140": "",
  "BIG141": "",
  "BIG142": "",
  "BIG143": "",
  "BIG144": "",
  "BIG145": "",
  "BIG146": "Direct chat from Fa, Zalo, Line, Ins, Web, Call",
  "BIG147": "Advantages",
  "BIG149": "Immediate response",
  "BIG150": "Customer service content sync",
  "BIG151": "Gather all customer data in one CRM",
  "BIG152": "What businesses need Biglead? ",
  "BIG153": "businesses with sales and customer service in various channels",
  "BIG154": "Businesses with customer service on social media platforms: Fb, zalo, web ",
  "BIG155": "Functions",
  "BIG156": "Gather comments, messages in one theme",
  "BIG157": "Gather in multiple pages",
  "BIG158": "Equal division of  customers for customer service operators",
  "BIG159": "Provide customer service tags",
  "BIG160": "Auto reply, direct marketing messages via Fb, Za, Line",
  "BIG161": "No need for customer service operators",
  "BIG162": "businesses with sales and customer service on fb zalo, instagram, live chat on web",
  "BIG163": "Businesses with product and service catalogues ",
  "BIG164": "Businesses with customer service available on holidays",
  "BIG165": "Automatic responses to comments and messages with comments/responses",
  "BIG166": " Automatic comments with private message",
  "BIG167": " Automatic responses to comments, messages based on \"key words\"",
  "BIG168": "Automatic responses with product catalouge and dynamic scripted advise",
  "BIG169": "Automatic responses with mass messages via multiple customer service channels",
  "BIG170": "Information management with chat tags, sale tags",
  "BIG171": "Customer types differentiation",
  "BIG172": "Storage of customer operator for each customer",
  "BIG173": "Easy evaluation of customer service operators",
  "BIG174": "03 group customer tags from chat to customer service",
  "BIG175": "Exclusive tags for Sales",
  "BIG176": "Automatic tags by shop owners ",
  "BIG177": "CRM and ERP integration ",
  "BIG178": "Possesses procedures: introduction -> advise -> purchase -> re-purchase -> introduction (viral) to other customer types",
  "BIG179": "Guarantees 100% frequent customer service with scheduled time",
  "BIG180": "Save human power",
  "BIG181": "Provides channels with frequent interaction with customers, help brands to improve customer retention via Facebook zalo email, or call",
  "BIG182": "Results in sales increase up to 60%, reduce marketing expenses for new customer scouting",
  "BIG183": "Businesses that deal with high rate of customers concern about services but have low interaction",
  "BIG184": "Businesses with low sales rates",
  "BIG185": "Businesses with old customer files but cannot retain customers, or spread the network of new customers",
  "BIG186": "Contact transfer from customers to potential customers in your OA, hence promoting free customer service messages",
  "BIG187": "Integrate extended features to support offline customer care; Wifi mkt, Camera AI, POS",
  "BIG188": " Improve customer care experience from online to offline ",
  "BIG189": "Effective evaluation in transferring customer from online to offline",
  "BIG190": "sync and gather customer information both offline and online",
  "BIG191": "Businesses that work in both offline and online",
  "BIG192": "Businesses services of: coffee spa beauty, care, real estate,… ",
  "BIG193": "Recognize customers that received customer care online service and shift to offline service",
  "BIG194": "Collect data when customers use services in stores",
  "BIG195": "Integrate interactive customer reports on each platform ",
  "BIG196": "Measure where customers come from, hence giving effective evaluation marketing campaign on various channels",
  "BIG197": " Measure customer groups via customer care",
  "BIG198": "Businesses that take care and conduct marketing in various channels ",
  "BIG199": "Report customer interaction (messages, comments, guests) on different channels",
  "BIG200": " Report customer with tags",
  "BLOG_1": "How to Sell on Facebook Increase Revenue 200%",
  "BLOG_2": "So is there an effective way to sell on facebook? What is the most complete process of selling on facebook? How to sell on facebook to reach the right audience? How to sell products on facebook effectively to achieve maximum revenue of 200%. Then don't ignore the set of tips and quick ways to order on facebook below.",
  "BLOG_3": "Compare ZNS and SMS – Which service takes care of customers more professionally?",
  "BLOG_4": "ZNS and SMS are both automated messaging services to help businesses interact well with customers. However, these two services still have many differences. So what is the difference and which one should be used?",
  "READ_MORE": "Readmore",
  "BLOG_5": "8 most important steps in marketing planning steps",
  "BLOG_6": "The construction of marketing planning steps is extremely important to help businesses build an image and promote products and brands. So what are the steps of marketing planning, let's find out through the following article. In today's article, Shopf1 will introduce to you the 8 most important steps in the most complete marketing planning steps.",
  "BIG201": "Contact Us",
  "BIG202": "Description",
  "BIG205": "Live chat with customers from all multi-channel customers (Facebook/ Zalo/ Instgram/ Line/ livechat on the web), avoid missing customers and take prompt care",
  "BIG206": "Can divide staff into groups by guest, page, or channel",
  "BIG207": "CRM gathers all potential customers from interest to purchase from all channels into one place",
  "BIG208": "Easy customer tracking based on customer status / or sales",
  "BIG209": "Sending mass messages to customers: announcements, product service updates",
  "BIG210": "A message includes: images, clips, sounds, or a product catalogue for customers to consider",
  "BIG211": "Advanced messages: sent to multiple channels based on customer behaviors and direct them to the appropriate channel automatically",
  "BIG212": "Message channels: Facebook/ Zalo/ Line/ Email/ Sms",
  "BIG213": "Available in app mobile and desktop",
  "BIG204": "Service businesses or businesses with online customer service activities ",
  "SOLUTION_1": "Messaging Flow Automation",
  "SOLUTION_2": "Email Marketing Automation",
  "SOLUTION_3": "Chatbot AI",
  "SOLUTION_4": "Camera AI",
  "SOLUTION_5": "Voice IP",
  "BIG220": "Focus chat for all customers from Fb, zalo, ins, line, web ",
  "BIG221": "Target: Service businesses or businesses with online customer service activities ",
  "BIG223": "Marketing package ",
  "BIG224": "Target: Service stores that need customers recognition (identify whether they have registered for the service or they are old customers to increase the quality of customer service)",
  "BIG256": "What does BigLead do?",
  "BIG227": "Automatic responses based on keywords",
  "BIG258": "Response-sync",
  "BIG300": "Allow creating mass messaging campaigns via social networking channels (Facebook/ Zalo/ Line/ Insta/ Email/ Sms) simultaneously",
  "BIG301": " Drive customers through multiple social media platforms for convenience and personalization of customer service",
  "BIG302": "Allow sending multiple emails with different contents sequently to customers as their behavior (opening, link clicking, scrolling)",
  "BIG304": "Camera AI",
  "BIG305": "Allow customers to automatically check-in counter and be confirmed, which creates a superior service experience",
  "BIG306": "Chatbot AI",
  "BIG307": "Auto-reply with chatting script via social networking channels   (Facebook/ Instgram/ Zalo/ Line)",
  "BIG308": "Voice IP",
  "BIG309": "Allows multiple employees to co- receive calls or make outgoing calls through a switchboard number. Save call history with each customer",
  "BIG310": "Brand SMS",
  "BIG311": "Send bulk messages with corporate trademark to customers via SMS",
  "MONTH": "month",
  "BIG228": "Buy month",
  "BIG229": "Buy the year",
  "BIG98": "Merge multiple Pages into chat in 1 page",
  "BIG263": "Click  package > Online payment > Select payment method With VNPAY code > scanning QR code",
  "BIG264": "Automatically reply by sending multiple messages according to sales scenario",
  "SOLUTION_6": "Brand SMS",
  "BIG400": "Multi-channel messaging",
  "BIG401": "Broad cast Message",
  "HDSD": "Tutorial",
  "BIG500": "LINE OA",
  "BIG501": "Webhook Setting",
  "BIG502": "Channel access token LINE",
  "BIG503": "Enter LINE Page manager.line.biz",
  "BIG504": "After sign up completed. Enter account management page ->Settting.",
  "BIG505": "Choose Messaging API click Enable message API.",
  "BIG506": "Fill the information, skip Privacy Policy & Term of use. Click Ok.",
  "BIG507": "Copy https://webhook.shopf1.net/line and paste into Webhook URL. Click Save",
  "BIG508": "Choose Response Setting -> Click Response mode: Bot & Webhooks: Enabled",
  "BIG509": "Instructions to get Line Channel access token",
  "BIG510": "Enter Page Line developers.line.biz",
  "BIG511": "1. Login your Line account",
  "BIG512": "2. Enter link https://developers.line.biz/console/",
  "BIG513": "3. Choose Providers (created in Webhook setting)",
  "BIG514": "4. Enter Message API.",
  "BIG515": "Scroll down to the bottom and click issue token. You will get the channel token",
  "BIG516": "GET STARTED",
  "BIG517": "Fill in the business information",
  "BIG518": "Add new product",
  "BIG519": "Add new staff",
  "BIG520": "CONNECT PAGE",
  "BIG521": "Connect Facebook Page",
  "BIG522": "Connect Line OA",
  "BIG523": "Connect Instagram",
  "BIG524": "CHAT",
  "BIG525": "Setting",
  "BIG526": "Auto Reply",
  "BIG527": "Make Appointment",
  "BIG528": "Chat Guide",
  "BIG529": "MARKETING",
  "BIG530": "Auto inbox",
  "BIG531": "Viral chat",
  "BIG532": "Message Flow",
  "BIG533": "Message Trigger",
  "BIG534": "Send Catalogue",
  "BIG535": "REPORT",
  "BIG536": "Page Report",
  "BIG537": "Sale Report",
  "BIG538": "Staff Report",
  "BIG539": "Broad cast Message",
  "BIG540": "Send message to old customers on Facebook",
  "BIG541": "Send a message to the list of customers with phone numbers on Zalo",
  "BIG542": "Send a message to the list of customers with phone numbers on Line",
  "BIG543": "Texting Viral",
  "BIG544": "Create mini games to share information about products/promotions",
  "BIG203": "IDO E-commerce Service Co., Ltd",
  "BIG545": "Office Address",
  "BIG546": "Contact Info",
  "BIG547": "142B De La Thanh street, Tho Quan Ward, Dong Da District, Vietnam ",
  "BIG257": "Immediate response to customer-related problems",
  "BIG1111": "Sales care marketing",
  "BIG270422": "Customer service management in all channels - call recordings and call operators",
  "BIGH": "Mass marketing messages integrated via FB, zalo, callbot, email, sms",
  "BIGA": "BigLead helps us to gather customers in different channels in one place ",
  "BIGB": "Mrs. Nguyen Quynh Anh ",
  "BIG214": "CEO of commercial service company Tienichpro",
  "BIG215": "Merge multiple Pages into 1 chat",
  "BIG216": "Private message from comment",
  "BIG217": "Auto reply to messages",
  "BIG218": "Connect shipping units, make orders - update shipping prices, track order status",
  "BIG219": "Report the number of guests taking care of the staff",
  "BIG230": "Re-marketing tool integration, mass messages via different platforms ",
  "BIG231": "Customers are not skipped",
  "BIG232": "Automatic responses with catalouge",
  "BIG233": "Mass messages to old customers by Tags via different platforms: Facebook/ Zalo/ Line ",
  "BIG234": "Send customer care messages to customers through many channels from: Fb <-> Za/ Line -> Email ",
  "BIG235": "Marketing messages from customers to another potential customers",
  "BIG236": "Send ZNS messages for marketing or authenticating OTP code based on phone numbers",
  "BIG237": "Solution:",
  "BIG238": "Identify loyal customers",
  "BIG239": "Identify customers who have registered for online services",
  "BIG240": "Identify which online marketing channels that direct shoppers coming from",
  "BIG241": "Count the number of customers by day/month",
  "BIG242": "Wifi Marketing",
  "BIG243": "Target: Chains of stores/services to save customer information and increase image recognition to customers",
  "BIG244": "Save customers information for stores",
  "BIG245": "Count the number of customers, which is convenient for report gathering ",
  "BIG246": "Review purchase experience, earn membership points",
  "BIG248": "Synchronize with Facebook ads to find new customers",
  "BIG247": "Synchronize with Biglead CRM, evaluate the effectiveness of Online Marketing via direct shoppers ",
  "BIG249": "Message back to customers to survey services, and take care of them via Zalo OA, Facebook, Sms, Email",
  "BIG250": "Big LEAD has a customer service team that is always available for your requests and information. As soon as we get customer requests for consultations or information on online service registration, we will be in touch with you on the phone or email for further advice.",
  "BIG251": "After deciding to use our service, you can choose a payment method from two options listed below that works best for you.",
  "BIG252": "After payment is completed, you only need to take a receipt or a credential and send it to Big LEAD Facebook page, contactshoptool.vn or via Zalo of our support staff. Big LEAD will immediately activate your account when we receive your inbox message or email, confirming that the billing is accurate without waiting for the transaction to be made.",
  "BIG253": "There are two forms of payment supported:",
  "BIG254": "Form 1: Payment via bank transfer or Internet Banking",
  "BIG255": "You can go to any bank or ATM or use the Internet Banking feature to transfer money into one of the following accounts:",
  "BIG259": " 1.  Vietcombank - Vietnam Joint stock Commercial Bank for Foreign Trade Bank of Vietnam   Account name: Cong ty TNHH Dich Vu Thuong Mai Dien Tu IDO   Account number: 0011004350688   Branch: Doi Can.   2.  Vietcombank - Vietnam Joint stock Commercial Bank for Foreign Trade Bank of Vietnam   Account name: Le Duy Thang   Number of accounts: 0611005554   Branches: CN Thang Long",
  "BIG260": " Note:",
  "BIG261": "You would be pleased to contact Hotline: 0939,556,336 for advice on VAT rates depending on service. You pay the deposit fee to your company account, please include 10% VAT",
  "BIG262": "Form 2: Online payment via mobile wallet app with VNPAY",
  "BIG265": " Duration of service delivery: Immediately",
  "BIG266": "Please contact customer support center: 0982.442.109 - Email: contactshoptool.vn",
  "BIG600": "BUY 6 MONTHS",
  "BIG2022": "Customers are provided with tools to optimize customer servce and increase sales",
  "Shopf1": "Biglead is a funnel that collects all customers from social networks to take care of one place. It will help you from messaging to introduce products, sales, to scheduled care on many channels",
  "HDSD2": "User manual",
  "BIG601": "Get the phone number the customer did not leave: 5 phone numbers/day",
  "BIG602": "Get the phone number the customer did not leave: 300 phone numbers/day",
  "BIG603": "Get the phone number the customer did not leave: 500 phone numbers/day",
  "BIG604": "Get the phone number the customer did not leave: 20 phone numbers/day",
  "BIG605": "Get the phone number the customer did not leave: Unlimited",
  "BIG606": "Mass messaging via Facebook Pages (10000 guests)",
  "BIG607": "Mass messaging via Facebook Pages (15000 guests)",
  "PRBASEYEAR": "$16",
  "2PAGE": "Page can connect up to 02 pages",
  "5PAGE": "Page can connect up to 05 pages",
  "SOLUTIONS_1": "Solutions:",
  "BIGC": "BigLead helps us to gather customers in different channels in one place",
  "BIG608": "Mass messaging via Facebook Pages (10,000 guests)",
  "US1 ": "Established since 07/2017 upon business registration: 0107871803 (issued on 05/06/2017). The main field of activity is building, providing and consulting customer care and management solutions, especially online customers.",
  "US2": "Our strength is consulting, building and providing industry-intensive customer management (CRM) solutions such as CRM for the pharmaceutical industry, healthcare, CRM for real estate, CRM for fashion, especially CRM for online businesses, etc.",
  "US3": "Website: Biglead.live ",
  "US4": "Hotline: 0982.442.109",
  "US5": "Zalo: 0369861203",
  "US6": "Biglead support team is always ready to help you",
  "US7": "Head office",
  "US8": "Business Name ",
  "US9": "Full name ",
  "US10": "Phone number ",
  "US11": "Email ",
  "US12": "Description ",
  "US13": "Send ",
  "NON": "N/A",
  "15PAGE": "Pages can connect up to 15 Pages",
  "20PAGE": "Page can connect up to 20 pages",
  "BIG222": "<div><h5>Solution:</h5>             <ul>               <li>                 Live chat with customers from all multi-channel customers                 (Facebook/ Zalo/ Instgram/ Line/ livechat on the web), avoid                 missing customers and take prompt care               </li>               <li>Can divide staff into groups by guest, page, or channel</li>               <li>                 CRM gathers all potential customers from interest to purchase                 from all channels into one place               </li>               <li>                 Easy customer tracking based on customer status / or sales               </li>               <li>                 Sending mass messages to customers: announcements, product                 service updates               </li>               <li>                 Advanced messages: sent to multiple channels based on customer                 behaviors and direct them to the appropriate channel                 automatically               </li>               <li>                 A message includes: images, clips, sounds, or a product                 catalogue for customers to consider               </li>               <li>Message channels: Facebook/ Zalo/ Line/ Email/ Sms</li>             </ul>           </div>",
  "BIG225": "<div>             <h5>Camera AI</h5>             <br />             <div>               <h5>Target: </h5>               <span                 >Chains of stores/services to save customer information and increase image recognition to customers</span               >             </div>             <br />             <div>               <h5>Solution:</h5>               <ul>                 <li>Identify loyal customers</li>                 <li>Identify customers who have registered for online services</li>                 <li>                   Identify which online marketing channels that direct shoppers coming from                 </li>                 <li>Count the number of customers by day/month</li>               </ul>             </div>             <br />             <h5>Wifi Marketing</h5>             <br />             <div>               <h5>Target:</h5>               <span                 >Chains of stores/services to save customer information and increase image recognition to customers</span               >             </div>             <br />             <div>               <h5>Solution:</h5>               <ul>                 <li>Save customers information for stores</li>                 <li>Count the number of customers, which is convenient for report gathering</li>                 <li>Review purchase experience, earn membership points</li>                 <li>                   Synchronize with Biglead CRM, evaluate the effectiveness of Online Marketing via direct shoppers                 </li>                 <li>Synchronize with Facebook ads to find new customers</li>                 <li>                   Message back to customers to survey services, and take care of them via Zalo OA, Facebook, Sms, Email                 </li>               </ul>             </div>           </div>",
  "PAGE_NAME": "Page name",
  "NO_FILE_SELECT": "",
  "url": "Open website",
  "query_hide_button": "",
  "sms_button": "SMS",
  "phone_button": "Call",
  "CUSTOMER_UPDATE": "Customer update",
  "FLOW_ZALO_ZNS": "Zalo Notification (ZNS)",
  "FLOW_ZALO": "Zalo OA Message ",
  "MESSAGE_TEXT": "Message text",
  "MESSAGE_IMAGE": "Message image",
  "web_url": "Link to web",
  "message_genetic_template": "Message catalogue",
  "message_quick_replies": "Message quick replies",
  "URL_WHEN_USER_CLICK": "Url when user click (Optional)",
  "CONTENT_MESSAGE_QUICK_REPLY": "Content message for quick reply",
  "QUICK_REPLY": "Quick reply",
  "customer_title": "Customer title",
  "customer_name": "Customer name",
  "product_name": "Product name",
  "customer_name_pleaceorther": "This field will be replaced with the customer name",
  "Element": "Element",
  "ADD_ELEMENT": "Add element ",
  "ELEMENT_SETTINGS": "Element settings",
  "SUBTITLE": "Subtitle",
  "PHONE_NUMBER_BUTTON": "Phone number",
  "SMS_CONTENT_BUTTON": "Sms content ",
  "BUTTON_SETTING": "Button settings",
  "DRAFT": "Draft",
  "NEW_VIRAL": "New viral",
  "select_condition_field": "Select a condition field",
  "select_comparison_condition": "Select comparison condition",
  "timeout": "Trigger condition timeout",
  "TRIGGER_CONDITION_TIMEOUT": "Trigger condition timeout",
  "SELECT_TIMEOUT": "Select timeout",
  "USER_NOT_REPLY": "Customer does not reply to the message",
  "TEXT": "Text",
  "PHOTO": "Photo",
  "GENRIC_TEMPLATE": "Genric template",
  "QUICK_REPLIES": "Quick replies",
  "NONE": "None",
  "BUTTON": "Button",
  "COMMENT_REPLY_CONTENT": "Comment reply content should be showed here",
  "CHANGE_TO_FACEBOOK_MESSAGE": "Change to Facebook message",
  "EXAMPLE_INPUT": "Hello, how are you?",
  "customer_number": "Customer Number",
  "delivery_time": "Delivery time",
  "total_cost": "Total cost",
  "REPLY": "Reply",
  "ONETIME_NOTIFICATION": "Onetime notification",
  "GENERIC_TEMPLATE": "Generic template",
  "INPUT_USER_NAME": "Input user name",
  "INPUT_PASSWORD": "Input Password",
  "MESSAGE_ONETIME_NOTIFICATION": "Onetime notification",
  "GET_NOTIFICATION": "Notify me",
  "ONETIME_NOTIFICATION_PLACEHOLDER": "Title of message (Get notified: Follow us to receive gifts)",
  "GET_NOTIFIED": "Get notified:",
  "ONE_TIME_NOTIF": "By clicking Notify me, you may receive one message from ##page_name## in the future about this topic.",
  "PRODUCTS": "Products",
  "CATEGORIES": "Categories",
  "STOCKS": "Stocks",
  "PROVIDERS": "Providers",
  "oa.open.phone": "Open a call",
  "oa.open.sms": "Send SMS",
  "oa.query.hide": "Send the next Flow",
  "oa.open.url": "Open website",
  "TEXT_ATTACHMENT": "Text attachment",
  "GENDER": "Gender",
  "CONVERSATION_STAFF": "Conversation Staff",
  "WARD": "Ward",
  "EDIT_ORDER": "Order",
  "REMOVE_TAG": "Remove tag",
  "ADD_TAG": "Add tag",
  "SELECTED": "Selected",
  "NOTIFICATION": "Notification",
  "ZALO_ADD_PAGE_SUCCESS": "Added zalo page success",
  "ZALO_ADD_PAGE_EXIST": "Zalo page existed",
  "OVERVIEW": "Overview",
  "SHOP": "Shop",
  "FREQUENCY": "Frequency",
  "DATE": "Date",
  "VIEW_REPORT": " View Report",
  "DAY_REPORT": "Day report",
  "NEW_CONVERSATION": "New conversation",
  "NEW_CONVERSATION_WITH_PHONE_NUMBER": "New conversation with phone number",
  "COMMENT_WITH_PHONE_NUMBER": "Comment with phone number",
  "MESSAGE_WITH_PHONE_NUMBER": "Message with phone numbers",
  "HOURS": "Hours",
  "SIGN_OUT_CONFIRM": "Sign out confirm",
  "DONT_HAVE_ANY_PAGES": "You don't have any pages. Click the button to connect a platform           (Facebook, Instagram, Zalo, Line)",
  "FACEBOOK_CONNECTION": "Facebook connection",
  "CLICK_BUTTON_LINK_FACEBOOK": "Click the button to link to Facebook",
  "DONT_HAVE_FACEBOOK_PAGE": " You don't have any facebook pages. Click the button to link to Facebook",
  "FACEBOOK_EDIT_SETTINGS": "Facebook edit settings",
  "INSTAGRAM_CONNECTION": "Instagram connection",
  "CLICK_BUTTON_LINK_INSTAGRAM": "Click the button to link to Instagram",
  "DONT_HAVE_INSTAGRAM_PAGES": "You don't have any Instagram pages. Click the button to link to Instagram",
  "INSTAGRAM_EDIT_SETTINGS": "Instagram edit settings",
  "CONVERSATIONS": "Conversations",
  "PHONE": "Phone",
  "SALE": "Sale",
  "NOTIFY_SIGN_OUT_CONFIRM": "Are you sure you want to sign out?",
  "GROSS_PRODUCTS": "Gross products",
  "PLACEHOLDER_SEARCH_PRODUCT": "Search product: name, code, barcode",
  "CATEGORY": "Category",
  "STOCK": "Stock",
  "IMPORT_PRODUCT_TO_STOCK": "Import products to this stock",
  "IMPORT_STOCK": "Import excel",
  "EXPORT_STOCK": "Export",
  "ADD_PRODUCT": "Add Products",
  "EDIT_PRODUCT": "Edit product",
  "REPORT": "Report",
  "REPORT_PAGE": "Page Report",
  "REPORT_SALE": "Sale Report",
  "REPORT_PROFIT": "Profit Report",
  "REPORT_EMPLOYEE": "Employee Report",
  "REPORT_PRODUCT": "Product Report",
  " HOURS": "Hours",
  "crudbooster.tariffs.basic": "Basic",
  "crudbooster.tariffs.personal": "Personal",
  "crudbooster.tariffs.pro": "Pro",
  "crudbooster.tariffs.business": "Business",
  "USER": "Users",
  "GET_PHONE": "Get Phone",
  "AUTO_INBOX_FAN_PAGE": "Auto Inbox Fanpage",
  "AUTO_INBOX_ZALO_OA": "Auto Inbox Zalo OA ",
  "VIRAL_CHAT": "Viral Chat",
  "CURRENT_SERVICES_PACK": "Your current service pack",
  "EXPIRED_AT": "Expired at",
  "DAY": "Day",
  "BY_MONTH": "Buy Month",
  "BY_SEX_MONTH": "Buy 6 month",
  "BY_YEAR": "Buy year",
  "PAGE_NUMBER": "Page number",
  "USER_NUMBER": "User number",
  "MONTH_NUMBER": "Month number",
  "YEAR_NUMBER": "Year number",
  "COUPON": "Coupon",
  "YEAR": "Year",
  "CREATE_SCHEDULE": "Create schedule",
  "SCHEDULE_TIME": "Schedule time",
  "MESSAGE_NOTE": "Message note",
  "STAFF_RECEIVE_NOTIFICATIONS": "Receiver",
  "SELECT_TIME_TO_REMIND": "Select time to remind",
  "PLEASE_INPUT_NOTE": "Please input note",
  "SCHEDULE_TIME_IS_REQUIRED": "Schedule time is required",
  "message_service1": "You still have the package being processed, please cancel the package or wait for processing",
  "DETAIL_OF_PAYMENT": "Detail of payment ",
  "CANCEL_SERVICES": "Cancel",
  "CONFIRM_CANCEL": "Are you sure confirm cancel?",
  "CREATOR": "Creator",
  "NOT_SENT": "Not sent",
  "SENT_STATUS": "Sent",
  "UPGRADE_YOUR_PLAN": "Upgrade your plan",
  "INFORMATION": "Information",
  "BILLING": "Billing",
  "CONVERTED_DAYS": "Converted days",
  "PRICE": "Price",
  "PROMOTION": "Promotion",
  "TRANFER_PAYMENT": "Transfer payment",
  "BANK_INFO": "Bank information",
  "TRANSFER_PAYMENT": "Transfer payment",
  "CUSTOMER_SCHEDULER": "Customer scheduler",
  "Versions": "Versions",
  "Price": "Price",
  "Quantity": "Quantity",
  "Code": "Code",
  "sale_price": "Sale price",
  "wholesale_price": "Wholesale Price",
  "promotional_price": "Promotional Price",
  "operation": "Operation",
  "create_a_new_product": "Create a new product",
  "common_information": " Common information",
  "add_product_version": "Add product version",
  "product_version": "Product version",
  "unit_price": "Unit price",
  "avatar_url": "Avatar url",
  "product_code": "Product code",
  "import_price": "Import price",
  "Provider": "Provider",
  "label": "label",
  "value": "Value",
  "add_attribute": "Add attribute",
  "import_stock": "Import stock",
  "update_product": "Update product",
  "delete_product_version": "Are you sure delete this version?",
  "CONFIRM_DELETE_PAGE": "Delete page",
  "Barcode": "Barcode",
  "Weight": "Weight",
  "CREATE_CUSTOMER": "Create customer",
  "UPDATE_CUSTOMER": "Update customer",
  "ORDERS_SUCCESS": "You have successfully ordered!",
  "BUY_SUCCESS": "You have successfully purchased the package!",
  "my_image_storage": "My image storage",
  "delete_selected_images": "Delete selected images",
  "switch_to_selection_mode": "Switch to selection mode",
  "switch_to_preview_mode": "Switch to preview mode",
  "REMINDER_NOTIFICATION": "Reminder notification",
  "REMINDER_NOTIFICATION_CONTENT": "Reminder message ( ##message## ) to a customer",
  "REMINDER_NOTIFICATION_FROM": "Reminder notification from",
  "CUSTOMER_SCHEDULE_AT": "Scheduled at",
  "CUSTOMER_FILTER_BY_BOOKING_TAG": "Booking tag",
  "INPUT_YOUR_COMMENT": "Input your comment",
  "TODAY": "Today",
  "YESTERDAY": "Yesterday",
  "CURRENT_WEEK": "Current week",
  "BEFORE_WEEK": "Before week",
  "CURRENT_MONTH": "Current month",
  "BEFORE_MONTH": "Before month",
  "REPORT_3_MONTH": "Statistics of customer messages in the last 3 months",
  "COMMENT": "Comment",
  "TOTAL": "Total",
  "REPORT_BY_HOUR": "Statistics by time of day",
  "TOP_STAFF_INTERACT": "Top most interacted employees of the day",
  "NEW_PHONE": "New phone number",
  "NEW_MESSAGE": "New message",
  "RE_INTERACTIVE": "Old customers message and interact again",
  "NEW_CUSTOMER": "New customer",
  "CLICK_HERE_EXPAND_FILTER": "Click here to expand the filter",
  "CATEGORY_NAME": "Category Name",
  "UPDATE_AT": "Updated At",
  "IMAGE": "Image",
  "TRIGGER_TITLE_CONFIRM_ACTIVATE": "Are you sure you want to activate this trigger",
  "TRIGGER_CONTENT_CONFIRM_ACTIVATE": "This trigger will be activated",
  "TRIGGER_WARNING_TRIGGER_IS_RUNNING": "Trigger cannot edit while running",
  "TAG": "Tag",
  "UPDATE": "Update",
  "FAILED": "Failed",
  "ERROR_LOG": "Error log",
  "ERROR_LOG_VIEWER": "Error log",
  "QUICK_MESSAGE": "Quick message",
  "RETURN": "Return",
  "QUICK_MESSAGE_FIRST_MESSAGE_NOTE": "Edit only the first message in flow",
  "THIS_FIELD_IS_REQUIRED": "This field is required",
  "FIRST_TEXT": "First text",
  "Next": "Next",
  "NEXT": "Next",
  "SEND_MESSAGE_ERROR": "Send message error",
  "TRIGGER_HISTORY": "Trigger history",
  "crudbooster.report_employee_calendar_reminder": "Notice to employee",
  "REPORT_STAFF": "Staff Report",
  "NUMBER_CONVERSATION_HANDLED": "Number of conversations handled",
  "BOT": "Bot",
  "NUMBER_CUSTOMER": "Customers number",
  "INTERVAL": "Interval",
  "BOOKING_TAGS": "Booking tags",
  "CLEAR": "Clear",
  "CLEAR_FILTER": "Clear filter",
  "IMPORT_EXCEL": "Import excel",
  "DOWNLOAD_EXAMPLE_EXCEL_FILE": "If you do not have the sample file just click the download button",
  "ALL_PHONES": "All phones",
  "male": "Male",
  "female": "Female",
  "other": "other",
  "ADD_CHILDREN": "Add children",
  "SUPER_ADMIN": "Super Administrator",
  "ADMIN": "Shop Admin",
  "CUSTOMER_CARE_SALE": "Customer care sale",
  "ACCOUNTANT": "Accountant",
  "OPERATOR": "Operator BigLead",
  "CUSTOMER_CARE_BIGLEAD": "Customer care BeigLead",
  "ACCOUNTANT_BIGLEAD": "Accountant BeigLead",
  "SALE_MANAGER": "Sale manager",
  "CUSTOMER_CARE": "Customer care ",
  "GENERAL_INFORMATION": "General Information",
  "ADD_EMPLOYEE": "Add employee",
  "TITLE_ADD_EMPLOYEE": "Add an available employee from BigLead users",
  "PLACEHOLDER_INPUT_ROLE": "Please select role",
  "EMPLOYEE_EXISTED_SHOP": "Employees already exist in the shop",
  "CONFIRM_DELETE": "Are you sure delete this",
  "STATUS_ORDER_CANCEL": "Cancellation",
  "SHIPPING_METHOD": "Shipping Method",
  "IMPORT_ORDER": "Import orders",
  "EXPORT_ORDER": "Export orders",
  "ORDER_FROM": "Order from",
  "CONFIRM_ORDER": "Are you sure confirm this order?",
  "CANCEL_ORDER": "Are you sure cancel this order?",
  "You_have_not_created_a_stock": "you have not created a stock or not configured shipping",
  "ORDER_TO": "Order to",
  "MAIN_INFO": "Main info",
  "PRODUCT_TAGS": "Product Tags",
  "ORDER_SHIPPING_UNIT_ID": "Order Shipping unit id",
  "ADDRESS": "Address",
  "PRINT": "Print",
  "ROLE": "Role",
  "ORDER_DATE": "Order date",
  "COD": "Cod",
  "DISCOUNT": "Discount",
  "PAID": "Paid",
  "SHIPPING_COST": "Shipping cost",
  "SHIPPING_COST_COLLECTED": "Shipping fee collected",
  "UPDATED_STATUS": "Updated status",
  "FORM_FULL_NAME": "Full name",
  "SELECT_PRODUCT": "Select product",
  "MESSAGE_UPDATED": "updated",
  "UPDATE_ORDER": "Update order ",
  "CREATE": "Create",
  "UPDATE_CATEGORY": "Update Category",
  "PLACEHOLDER_INPUT_NAME": "Please input name",
  "ADD_NEW_STOCK": "Add a new branch/stock",
  "DEFAULT": "Default",
  "PLACEHOLDER_INPUT_PHONE": "Please input phone",
  "UPDATE_STOCK": "Update stock",
  "ADD_PROVIDER": "Add provider",
  "UPDATE_PROVIDER": "Update provider",
  "PLACEHOLDER_INPUT_ADDRESS": "Please input address",
  "STATUS_ORDER_NEW": "New",
  "SET_QUICK_MESSAGE": "Set quick message",
  "UPDATE_STATUS": "Update status",
  "UPDATE_STATUS_FOR_SELECTED": "Update status for selected",
  "DISPLAY_COLUMN_LIMIT 10 TIP_SELECT_COLUMN": "Display column limit 10 tip select column",
  "DISPLAY_COLUMN_LIMIT 8 TIP_SELECT_COLUMN": "Display column limit 8 tip select column",
  "DISPLAY_COLUMN_LIMIT 7 TIP_SELECT_COLUMN": "Display column limit 7 tip select column",
  "DISPLAY_COLUMN_LIMIT 6 TIP_SELECT_COLUMN": "Display column limit 6 tip select column",
  "DISPLAY_COLUMN_LIMIT 5 TIP_SELECT_COLUMN": "Display column limit 5 tip select column",
  "DISPLAY_COLUMN_LIMIT 4 TIP_SELECT_COLUMN": "Display column limit 4 tip select column",
  "DISPLAY_COLUMN_LIMIT 3 TIP_SELECT_COLUMN": "Display column limit 3 tip select column",
  "DISPLAY_COLUMN_LIMIT 2 TIP_SELECT_COLUMN": "Display column limit 2 tip select column",
  "DISPLAY_COLUMN_LIMIT 1 TIP_SELECT_COLUMN": "Display column limit 1 tip select column",
  "DISPLAY_10_COLUMN": "Display 10 column",
  "CUSTOMER_ACTIVITY": "Customer activity",
  "SELECT_WALLET": "Select wallet",
  "TRIGGER": "Trigger",
  "SET_TRIGGER": "Set trigger",
  "REMOVE_TRIGGER": "Remove trigger",
  "EDIT_TRIGGER": "Edit",
  "EDIT_FLOW": "Edit flow",
  "PRICING ": "Pricing & your plan",
  "TRANSPORTERS": "Transporters",
  "PAYMENT_MENTHOD": "Payment Method",
  "INPUT_PLACEHOLDER": "Input",
  "INPUT_SELECT": "Please Select",
  "PAYMENT": "Payment",
  "Activate_trigger": "Activate trigger",
  "OTHER": "Other",
  "ENABLE": "Enable",
  "REJECT": "Reject",
  "PRICING": "Pricing & your plan",
  "TRIGGER_TITLE_CONFIRM_STOP": "Trigger title comfirm stop",
  "REMOVE": "remove",
  "REPORT_OVERVIEW": "Overview Report",
  "START_TIME_PLACEHOLDER": "Start Time",
  "END_TIME_PLACEHOLDER": "End time",
  "REPORT_MESSAGE": "Statistics of customer messages",
  "PLEASE_INPUT_PRODUCT_NAME": "Please input product name",
  "PLEASE_INPUT_PRODUCT_PRICE": "Please input product price",
  "SALES_MANAGER": "Sales Manager",
  "CRM_MANAGER": "CRM manager",
  "LIST": "List",
  "FIELD": "Career",
  "EMPLOYEE_FROM_SYSTEM": "Users from the system",
  "CREATE_LINK_INVITE_TO_SHOP": "Create a link to invite employees to the Shop",
  "CREATE_LINK_INVITE": "Create link invite",
  "EMPLOYEE_ADD": "Add employee",
  " Stocking": " Stocking",
  "quic_ create": "quick create",
  "CONFIG": "Config",
  "quick_ create": "quick create",
  " ZALO_EDIT_SETTINGS": "Zalo edit settings",
  "CREATE_PRODUCT": "Create product",
  "PRINT_LABEL": "Print label",
  "SEND_MESSAGE_SUCCESS": "Send message success",
  "Service_pack_Banking": "Service pack & Banking",
  "CUSTOMER_INTERACT_DAILY": "Customer interact",
  "NEW_MESSAGE_CONVERSATION": "New Message Conversation",
  "CUSTOMER_MESSAGE_DAILY": "Customer Inbox Interactive",
  "AUTO_UNHIDE_BEHIND": "Auto unhide behind",
  "MISSED_MESSAGE_LATER": "Missed message later",
  "CLICK_TO_VIEW_ERROR": "Click to view error",
  "PLEASE_CHOOSE_SHOP_AND_FANPAGE": "Please choose Shop and Fanpage",
  "COPY_SETTINGS_FROM_ANOTHER_PAGE": "Copy settings from another page",
  "SELECT_A_PAGE_TO_COPY_THE_CONFIGURATION": "Select a page to copy the configuration",
  "MISSING_PERMISSION": "This page is missed permissions, please try to connect facebook to allow these permissions: ",
  "Ordered": "Ordered",
  "Available": "Available",
  "EMAIL_CONTEXT": "E-mail",
  "test_content": "Test content",
  "send_test_&_save": "Send test & save",
  "INPUT_SMTP_HOST": "Please select Activity SMTP host",
  "INPUT_SMTP_POST": "Please select Activity SMTP post",
  "INPUT_CONTENT": "Please select Activity content",
  "INPUT_MAIL_TEST_TO": "Please select Activity mail test to",
  "INPUT_EMAIL": "Please input Activity email",
  "MAIL_CONTENT": "Mail content",
  "MAIL_TO": "Mail test to",
  "EMAIL_PLACEHOLDER_EDITOR": "You can paste the html in here",
  "ATTACH_FILES": "Attach files",
  "FLOW_ACTION_EMAIL_OPEN": "When email is opened",
  "FLOW_ACTION_EMAIL_OPEN_LINK": "When customer click to link",
  "DISPLAY_COLUMN": "Display column",
  "DISPLAY_COLUMN_LIMIT": "Display limit ",
  "TIP_SELECT_COLUMN": " column selected ",
  "CONFIG_SEND_MAIL": "Config send mail",
  "SEND_TO_SHIPPING": "Send to shipping",
  "ORDER_SEND": "Send to shipping",
  "ORDER_CANCEL": "Order cancel",
  "ORDER_CANCELED": "Order cancel",
  "CANCEL": "Cancel",
  "OK": "Ok",
  "ORDER_CONFIRMED": "Confirm order",
  "ORDER_SUCCESS": "Order success",
  "CAREER": "Career",
  "CUSTOMER_STATUS_CARE": "Customer care",
  "UNREAD-DIALAGUE_TO_THE_TOP": "Unread dialogue to the top",
  "ENTER_LINE_TOKEN_HERE": "Enter LINE token here",
  "FILTER_BY_OR_CONDITION": "Filter by OR condition (A or B)",
  "FILTER_BY_AND_CONDITION": "Filter by AND condition (A and B)",
  "schedules": "Schedule",
  "LINE_ACCOUNT": "Line account",
  "OA_CODE_LINE": "OA code LINE",
  "SELECT_MAIL": "Select mail",
  "CUSTOMER_CAREER": "Career",
  "FLOW_FOOTER_TEXT_REQUIRED": "Footer text is required",
  "FLOW_FOOTER_TEXT": "Footer text",
  "CONTACT_INFO": "Contact Info",
  "PREFIX": "Prefix",
  "FULL_NAME": "Full name",
  "POSITION": "Position",
  "BIRTHDAY": "Birthday",
  "PAGE_ID": "Page",
  "CONFIGURE_LINE": "Configure LINE",
  "CONVERSATION_TAG": "Conversation tag",
  "MAIL_T0": "Mail test to",
  "DONT_HAVE_ZALO_PAGE": "Don't have Zalo page!",
  "TRIGGER_VALIDATE_START_TIME": "Choose a sending time",
  "NOTIFY_CONTENT_CREATE_STOCK": "Please create a warehouse to continue creating products",
  "NOTIFY_DONT_HAVE_STOCK": "You don't have any stock yet.",
  "FACEBOOK_MESSENGER_TAG": "Facebook messenger tag",
  "VIRAL_TYPE": "Viral type",
  "BUTTON_TITLE": "Button title",
  "MESSAGE_CONTENT": "Message content",
  "GIFT_NAME": "Gift name",
  "WIN_RATE": "Win rate (%)",
  "WIN_RATE_PERCENT": "Win rate (%)",
  "SNOWFLAKE": "Snowflake",
  "GIFT_SETTINGS": "Gift settings",
  "ADD_MORE": "Add more",
  "NUMBER_OF_PLAY": "Number of play",
  "PLAYS_PER_INVITATION": "Plays/invitation",
  "HELP_CENTER": "Help center",
  "IMAGE_IS_REQUIRED": "Image is required",
  "GET_IN_TOUCH": "Get in touch",
  "HAVE_ANY_QUESTION_OR_COMMENT": "Have any question or comment?",
  "SUPPORT_CHANNEL": "Support Channel",
  "HOTLINE": "Hotline",
  "MONDAY_SATURDAY": "Monday-Saturday(except holidays)",
  "total_visit": "Total visit",
  "DISPLAY": "Display",
  "SERVICE": "Service",
  "FEE": "Fee",
  "CREATE_AND_CONFIRM_ORDER": "Create and confirm order",
  "STAFF_CREATE": "Staff create",
  "SHOP_OWNER": "Shop owner",
  "ATTRIBUTE_1": "Attribute 1",
  "ATTRIBUTE": "Attribute",
  "Syncing": "Syncing",
  "COST_INFO": "Cost information",
  "attribute": "Atrribute",
  "NEW_ATTRIBUTE": "New Atrribute",
  "FLOW_CALL_MESSAGE_PLACEHOLDER": "Hello, ##title####full_name##, you have a flight at ##scheduled_time##, please prepare to be at the airport an hour before check-in.",
  "FLOW_TAG_TITLE": "title",
  "FLOW_TAG_FULL_NAME": "full_name",
  "FLOW_TAG_SCHEDULED_TIME": "scheduled_time",
  "SELECT_TYPE": "Select type",
  "reminder": "reminder",
  "reminder_send_trigger": "Reminder send trigger",
  "YOUR_WALLET_BALANCE_IS": "Your wallet balance is :",
  "AMOUNT_SENT": "Amount sent",
  "last_contact_time": "Last contact time",
  "first_contact_time": "First contact time",
  "SUBSCRIPTION_EXPIRED": "Your subscription has expired.",
  "NO_SUBSCRIPTION": "No subscriptions active",
  "STATUS_ORDER_CONFIRM": "Confirmed",
  "TIMEOUT": "Timeout",
  "TIMER_TYPE": "Timer type",
  "remind_time_before": "Remind time before",
  "CONDITION_GROUP": "Condition group",
  "THE_PHONE_NUMBER_IS_EXISTED": "The phone number is existed",
  "is_visible": "Visible",
  "is_read": "Read",
  "is_create": "Create",
  "is_edit": "Edit",
  "is_delete": "Delete",
  "role_customers": "Customers",
  "role_customer_tag": "Tag sale",
  "role_customer_care": "Customer care",
  "role_career": "Careers",
  "role_schedules": "Schedules",
  "role_overview": "Overview",
  "role_employee": "Employees",
  "role_flows": "Flows",
  "role_flow_tag": "Flow tag",
  "role_trigger": "Triggers",
  "role_trigger_tag": "Trigger Tag",
  "role_trigger_history": "Trigger History",
  "role_report_overview": "Overview Report",
  "role_report_page": "Page Report ",
  "role_report_staff": "Staff Report",
  "role_report_zns": "ZNS Report",
  "role_products": "Products",
  "role_categories": "Product Category",
  "role_stocks": "Stocks",
  "role_product_tag": "Product Tag",
  "role_providers": "Providers",
  "role_orders": "Orders",
  "role_service_package": "Service Package",
  "role_transporter": "Transporters",
  "role_shop_info": "Shop Info",
  "role_live_chat": "Live Chat",
  "UPDATE_ROLE": "Update role",
  "ADD_ROLE": "Add role",
  "BACK": "Back",
  "EMPLOYEEEDIT": "Edit employee",
  "EMPLOYEEDETAIL": "Employee Detail",
  "VOUCHER": "Voucher",
  "CAMPAIGN_NAME_ID": "Campaign Name/ID",
  "PROMOTION_TYPE": "Promotion type",
  "Purpose": "Purpose",
  "FIND_VOUCHER": "Find voucher",
  "USED_VOUCHER": "Used Voucher",
  "REVENUE": "Revenue",
  "Discount": "Discount",
  "Time": "Time",
  "WHAT_IS_PURPOSE_OF_THIS_CAMPAIGN": "What is purpose of this campaign?",
  "SYNC_ROLES": "Sync Roles",
  "REQUEST_USER": "Request user",
  "REQUEST_USER_INFO": "Request user info",
  "role_setting_page": "Setting Page",
  "SEND": "Sent",
  "role_role": "Role",
  "AFTER_USER_SUBMIT": "After user submit",
  "AFTER_USER_SUMIT": "After user submit",
  "role_viral_history": "Viral History",
  "SETUP_DISCOUNT_CONDITION": "Setup discount condition",
  "ENTER_CAMPAIGN_NAME": "Enter campaign name",
  "ENTER_CODE": "Enter code",
  "UPDATING": "Updating",
  "UPDATE_ERROR": "Update error",
  "UPDATE_VERSION_SUCCESS": "Update product version success",
  "CREATE_VERSION_SUCCESS": "Create product version success",
  "DEPARTMENT": "Department",
  "role_ departments": "Departments",
  "UPDATE_DEPARTMENT": "Update department",
  "ADD_DEPARTMENT": "Add department",
  "role_wallet_zalo": "Wallet Zalo",
  "GIFT_LITMIT": "Gift limit",
  "CONFIG_CUSTOMER": "Config customer",
  "CUSTOMER_CONFIG_NAME": "Customer config name",
  "CUSTOMER_CONFIG_VALUE": "Customer config value",
  "FIELD_TYPE": "Type",
  "VIRAL_LUCKY_WHEEL": "Viral lucky wheel",
  "VIRAL_SHARE_GIFT": "Viral share gift",
  "COPY_FLOW": "Copy flow",
  "ONE_CODE_USE_MANY_TIME": "One voucher can be used more than once",
  "ON_CODE_USE_MANY_TIME_SUBTITLE": "When you run a campaign for any customers, you can create a voucher code with fix characters to promote on various channel. This type of campaign can be used flexibly. It will help you to manage discount amount and measure the effectiveness of each advertising channel",
  "A_SINGLE_USE_VOUCHER": "One voucher can only be used once",
  "A_SINGLE_USE_VOUCHER_SUBTITLE": " When offering offers that apply to all customers, you need to create a fixed, easy-to-remember voucher to communicate across channels. This is a one time use coupon code, you can have one time to apply it.",
  "ONE_CODE_USE_MANY_TIME_SUBTITLE": "When you run a campaign for any customers, you can create a voucher code with fix characters to promote on various channel. This type of campaign can be used flexibly. It will help you to manage discount amount and measure the effectiveness of each advertising channel",
  "DISCOUNT_FOR_TOTAL_BILL": "Discount for total bill",
  "DISCOUNT_FOR_PRODUCT": "Discount for product",
  "BUY_A_DISCOUNT_B": "Buy A discount B",
  "BUY_X_GIVE_AWAY_Y": "Buy X give away Y",
  "SAME_PRICE": "Same price",
  "OTHER_OFFERS": "Other offers",
  "PAGE_OF_USE": "Page of use",
  "DISCOUNT_VN_%": "Discount vnd or %",
  "MAXIMUM_DISCOUNT_VALUE": "Maximum discount ",
  "ONLY_APPLY_FOR_BILL_FROM": "Only apply for bill from",
  "VOUCHER_CAN_BE_APPLIED_FOR_COMBO": "Voucher can be applied for combo",
  "APPLY_ON_NOT_APPLY": "Apply on",
  "apply_on": "Apply on",
  "not_apply": "Not apply",
  "group": "Group",
  "PLEASE_CHOOSE_A_PRODUCT": "Please choose a product",
  "SEE_MORE_ADVANCED_CONDITIONS": "See more advanced conditions",
  "DISCOUNT_FROM_PRODUCT": "Discount from product",
  "GROUP_LIST_TO_BUY": "Group list to buy",
  "DISCOUNTED_GROUP_LIST": "Discounted group list",
  "THE_NUMBER_OF_PRODUCT": "The number of product",
  "NUMBER_OF_PRODUCTS_TO_BUY": "Number of products to buy",
  "QUANTITY_OF_PRODUCT_IS_REDUCED": "Quantity of product is reduced",
  "DISCOUNT_%": "Discount %",
  "THE_GIVEN_GROUP_IS_THE_SAME_AS_THE_GROUP_TO_BUY": "The given group is the same the group to buy",
  "GIVE_THE_HIGHEST_PRICE": "Give the highest price",
  "CUSTOMER_BY_TAGS": "Report Customer by sale tag",
  "REPORT_CUSTOMER_BY_CARE": "Report customer by status care",
  "REPORT_CUSTOMER_BY_TAGS": "Report Customer by sale tag",
  "REPORT_CUSTOMER": "Customer Report",
  "EXTRA_COLUMN": "Extra column",
  "duplicate": "duplicate",
  "PROGRAM_VALIDITY_PERIOD": "Program validity period",
  "ADVANCED_TIME_CONDITIONS": "Advanced time conditions",
  "APPLIED_TIME_FRAME": "Applied time frame",
  "DAY_OF_WEEK": "Day of week",
  "HOUR_OF_THE_DAY": "Hour of the day",
  "sunday": "Sunday",
  "monday": "Monday",
  "tuesday": "Tuesday",
  "wednesday": "Wednesday",
  "thursday": "Thursday",
  "friday": "Friday",
  "saturday": "Saturday",
  "ZALO_FOLLOW_OA": "Zalo follow OA",
  "ZALO_UNFOLLOW_OA": "Zalo unfollow OA",
  "ZALO_USER_CLICK_CHAT_NOW": "Zalo user click chat now",
  "ZALO_USER_SUBMIT_INFO": "Zalo user submit info",
  "OLD_PASSWORD": "Old password",
  "ACCOUNT": "Account",
  "NEW_PASSWORD": "New  password",
  "ENTER_THE_NEW_PASSWORD": "Enter the new password",
  "CHANGE_PASSWORD": "Change password",
  "identified_name": "identified name",
  "ACCOUNTING_TYPE": "Accounting type",
  "APPLY_WITH_OTHER_OFFERS_INSTALLED_ON_BIGLEAD": "Apply the same other offer installed on Biglead",
  "CHANNEL_OF_DISTRIBUTE_VOUCHER": "Channel of distribute voucher",
  "VOUCHERS_ARE_ONLY_APPLICABLE_ON_CHANNELS": "Vouchers are only applicable on channels",
  "VOUCHER_IS_ONLY_APPLED_FOR_MEMBER": "Voucher is only applied for member",
  "EACH_MEMBER_CAN_USE_VOUCHER_01_TIME_MULTIPLE_TIMES": "Each member can use voucher 01 time/many time",
  "NUMBER_OF_TIMES_TO_APPLY_THE_CODE_PER_DAY": "Number of application times per day ",
  "THE_NUMBER_OF_APPLICATION_TIMES": "The number of application times",
  "VOUCHER_CODE_OF_CAMPAIGN": "Voucher code of campaign",
  "FIND_VOUCHER": "Find voucher",
  "MANAGER": "Manager",
  "CURRENCY": "Currency",
  "remove_conversation_tag": "Remove conversation tag",
  "remove_tag": "Remove tag",
  "add_conversation_tag": "Add conversation tag",
  "SET_UP_VOUCHER": "Set up voucher",
  "SET_UP_VOUCHER_CONDITION": "Set up voucher condition",
  "VOUCHER_NAME": "Voucher name",
  "VOUCHER_DISCRIPTION": "Voucher discription",
  "update_last_care": "Update last care",
  "contain_phone_number": "Contain phone number",
  "CREATE_VOUCHER": "Create voucher",
  "MEMBER_RANK": "Member rank",
  "Qualifying_Conditions": "Qualifying conditions",
  "Periodic_rank_review": "Periodic rank review",
  "CREATE_MEMBER_RANK": "Create member rank",
  "BILL_INVOICE": "bill invoice",
  "QUALIFICATIONS_FOR_RANKING": "Qualifications for ranking",
  "EXCESS_ACCUMULATED_MONEY": "Excess accumulated money",
  "MAINTAIN_ACCUMULATED_MONEY": "Maintain accumulated money",
  "MINUS_ACCUMULATED_MONEY": "Minus accumulated money",
  "RESET_ACCUMULATED_MONEY_TO": "Reset accumulated money to",
  "CYCLICAL_TIER_REVIEW": "Cyclical tier review",
  "REVIEW_SPENDING_NORMS": "Review spending norms",
  "IF_THE_SPENDING_LIMIT_IS_NOT_MET_RE-EVALUATE_THE_RANK": "If the spending limit is not met re-evaluate the rank",
  "MEMBER_RANK_CHANGE": "Member rank change",
  "CHANGE_ACCUMULATE_MONEY": "Change accumulate money",
  "SHARES_ON_GIFT": "Shares on gift",
  "HIGHEST_PRICE": "Highest price",
  "LOWEST_PRICE": "Lowest price",
  "FLOW_FINISH_VIRAL": "Action after sending viral successfully",
  "MESSAGES_INVITING_FRIENDS": "Messages inviting friends",
  "PUBLISH_VOUCHER": "Publish voucher",
  "VOUCHER_CODE" : "Voucher code",
  "YOU_HAVE_NOT_SELECTED_EMAIL": "you have not selected email",
  "TIME_APPLICATION_VOUCHER": "Time application voucher",
  "MANY": "Many",
  "ONLY": "Only",
  "NUMBER_OF_GROUPS_TO_BUY": "Number of groups to buy",
  "QUANTITY_OF_GROUPS_IS_REDUCED": "Quantity of groups is reduced",
  "PRODUCT_LIST_TO_BUY": "Product list to buy",
  "DISCOUNTED_PRODUCT_LIST": "Discounted product list",
  "GIVEN_AWAY_GROUP_LIST": "Given away group list",
  "QUANTITY_OF_GROUPS_IS_GIVEN_AWAY": "Quantity of groups is given away",
  "GIVEN_AWAY_PRODUCT_LIST": "Given away product list",
  "QUANTITY_OF_PRODUCT_IS_GIVEN_AWAY": "Quantity of product is given away",
  "YOU_HAVE_NOT_SELECTED_EMAIL": "you have not selected email",
  "role_departments": "Departments",
  "STOP_VOUCHER": "Stop voucher",
  "Activate_voucher": "Activate voucher",
  "STOP_MEMBER_RANK": "Stop member rank",
  "Activate_MEMBER_RANK": "Activate member rank",
  "LOWER_IN_RANK": "Lower in rank",
  "RANK_ID": "Rank id",
  "RATE": "Rate",
  "VOUCHER_TITLE_CONFIRM_STOP": "Voucher title confirm stop",
  "VOUCHER_TITLE_CONFIRM_ACTIVITY": "Voucher title confirm activity",
  "MEMBER_RANK_TITLE_CONFIRM_STOP": "Member rank title confirm stop",
  "MEMBER_RANK_TITLE_CONFIRM_ACTIVITY": "Member rank title confirm activity",
  "DELETE_VOUCHER": "Delete voucher",
  "role_departments": "Departments",
  "UPDATE_EXCEL_FILE": "update excel file",
  "SEND_IT": "Send it",
  "now": "Now",
  "WHATSAPP_EDIT_SETTINGS": "Whatsapp edit settings",
  "CLICK_BUTTON_LINK_WHATSAPP": "Click button whatsapp",
  "WHATSAPP_CONNECTION": "Whatsapp connection",
  "CHOOSE_AN_IDENTIFIER_NAME": "Choose an identifier name",
  "WHATSAPP_ADD": "WhatsApp add",
  "FLOW_FACEBOOK_BUTTON_NAME_2": "Title",
  "DESCRIPTION_2": "Description",
  "DELETED_ASK_DEVICE_TO_RECONNECT": "deleted, ask device to reconnect",
  "EVENT": "Event",
  "ALIAS_NAME": "Alias name",
  "TICKET_TYPE": "Ticket type",
  "medical_history": "Medical history",
  "ORGANIZATION": "Organization",
  "BLOOD_GROUP": "Blood group",
  "NATIONAL_CARD_ID": "National card id",
  "NATIONAL_ID": "National id",
  "reference_name": "Reference name",
  "reference_phone": "Reference phone",
  "EVENT_NAME": "Event name",
  "CREATED_EVENT": "Created event",
  "TICKET_TYPE_NAME": "Ticket type name",
  "TICKET_TYPE_START_BIB": "Ticket type start BIB",
  "TICKET": "Ticket",
  "LIST_TICKET": "List ticket",
  "LIST_EVENT": "List event",
  "LIST_TICKET_TYPE": "List ticket type",
  "start_bib": "Start bib",
  "LIST_TEMPLATE": "List template",
  "ADD_TICKET": "Add ticket",
  "CONVERSATIONS_TANG_NOTE": "When tagging a conversation, it will automatically tag the corresponding sale",
  "EDIT_TICKET": "Edit template",
  "TEMPLATE_NAME": "Template name",
  "TEMPLATE_CONFIRM_DELETE_CONTEXT": "Delete template",
  "EVENT_CONFIRM_DELETE_CONTEXT": "Delete event",
  "TICKET_TYPE_CONFIRM_DELETE_CONTEXT": "Delete ticket type",
  "EDIT_EVENT": "Edit event",
  "MAIL_EDITOR": "Mail editor",
  "TEMPLATE_DEFAULT": "Template default",
  "TEMPLATE_NEW": "Template new",
  "SEND_TICKET": "Send ticket",
  "TICKET_CONFIRM_SEND_CONTEXT": "Ticket confirm send context",
  "PREVIEW_QR": "Preview QR",
  "CHECKED": "Checked",
  "NEW_STATUS": "New status",
  "add_tag": "Add tag",
  "SET_UP_VOUCHER": "Set up voucher",
  "SET_UP_VOUCHER_CONDITION": "Set up voucher condition",
  "VOUCHER_NAME": "Voucher name",
  "VOUCHER_DISCRIPTION": "Voucher discription",
  "CREATE_VOUCHER": "Create voucher",
  "EACH_MEMBER_CAN_USE_VOUCHER_01_TIME_MULTIPLE_TIMES": "Each member can use voucher 01 time/many time",
  "MEMBER_RANK": "Member rank",
  "Qualifying_Conditions": "Qualifying conditions",
  "Periodic_rank_review": "Periodic rank review",
  "CREATE_MEMBER_RANK": "Create member rank",
  "%BILL_INVOICE": "% bill invoice",
  "QUALIFICATIONS_FOR_RANKING": "Qualifications for ranking",
  "EXCESS_ACCUMULATED_MONEY": "Excess accumulated money",
  "MAINTAIN_ACCUMULATED_MONEY": "Maintain accumulated money",
  "MINUS_ACCUMULATED_MONEY": "Minus accumulated money",
  "RESET_ACCUMULATED_MONEY_TO": "Reset accumulated money to",
  "CYCLICAL_TIER_REVIEW": "Cyclical tier review",
  "REVIEW_SPENDING_NORMS": "Review spending norms",
  "IF_THE_SPENDING_LIMIT_IS_NOT_MET_RE-EVALUATE_THE_RANK": "If the spending limit is not met re-evaluate the rank",
  "MEMBER_RANK_CHANGE": "Member rank change",
  "CHANGE_ACCUMULATE_MONEY": "Change accumulate money",
  "HIGHEST_PRICE": "Highest price",
  "LOWEST_PRICE": "Lowest price",
  "PUBLISH_VOUCHER": "Publish voucher",
  "VOUCHER_CODE": "Voucher code",
  "STARTING_STEP": "Starting Step",
  "ADD_TRIGGER": "Add trigger",
  "from": "from",
  "THE_FIRST_STEP": "The first step",
  "NEXT_STEP": "Next step",
  "TIME_APPLICATION_VOUCHER": "Time application voucher",
  "MANY": "Many",
  "ONLY": "Only",
  "QUANTITY_OF_GROUPS_IS_REDUCED": "Quantity of groups is reduced",
  "NUMBER_OF_GROUPS_TO_BUY": "Number of groups to buy",
  "PRODUCT_LIST_TO_BUY": "Product list to buy",
  "DISCOUNTED_PRODUCT_LIST": "Discounted product list",
  "GIVEN_AWAY_GROUP_LIST": "Given away group list",
  "QUANTITY_OF_GROUPS_IS_GIVEN_AWAY": "Quantity of groups is given away",
  "GIVEN_AWAY_PRODUCT_LIST": "Given away product list",
  "QUANTITY_OF_PRODUCT_IS_GIVEN_AWAY": "Quantity of product is given away",
  "STOP_VOUCHER": "Stop voucher",
  "Activate_voucher": "Activate voucher",
  "STOP_MEMBER_RANK": "Stop member rank",
  "Activate_MEMBER_RANK": "Activate member rank",
  "LOWER_IN_RANK": "Lower in rank",
  "RANK_ID": "Rank id",
  "RATE": "Rate",
  "VOUCHER_TITLE_CONFIRM_STOP": "Voucher title confirm stop",
  "VOUCHER_TITLE_CONFIRM_ACTIVITY": "Voucher title confirm activity",
  "MEMBER_RANK_TITLE_CONFIRM_STOP": "Member rank title confirm stop",
  "MEMBER_RANK_TITLE_CONFIRM_ACTIVITY": "Member rank title confirm activity",
  "DELETE_VOUCHER": "Delete voucher",
  "STRING": "String",
  "ACCUMULATE_MONEY": "Accumulated money",
  "DELETAE_ASK_DEVICE_TO_RECONNECT": "deleted, ask device to reconnect",
  "THEN": "Then",
  "second": "second",
  "ACTION_SUBMIT_CLICK": "Submit",
  "LIST-TICKET": "List ticket",
  " PREVIEW_QR": "Preview QR",
  "NEXT_ACTION": "Next action",
  "ACTION_SEND_SUCCESS": "Action send success",
  "ACTION_SEND_FALSE": "Action send false",
  "ACTION_DEFAULT": "Action default",
  "ACTION_REQUEST_USER_INFO": "Request user info",
  "AUTO_INBOX_DOWNLOAD": "Auto Inbox Download",
  "PERSONAL_MEDIA_MESSAGES": "personal media messages",
  "ALIGN": "Align",
  "HEADER_ATTACHMENT": "Header attachment",
  "payment_callback": "Payment callback",
  "ATTACHMENT_SETTINGS": "Attachment settings",
  "SELL_TICKET_TYPE": "Sell ticket type",
  "ONE_TICKET": "One ticket",
  "GROUP_TICKET": "Group ticket",
  "transaction_billing": "Billing",
  "transaction_order": "Order",
  "transaction_reward": "Reward",
  "transaction_contract": "Contract",
  "transaction_booking": "Booking",
  "transaction_membership": "transaction_membership",
  "transaction_event": "Event",
  "transaction_transation": "Transaction",
  "transaction_account": "Account",
  "transaction_internal": "Internal",
  "transaction_partnership": "Partnership",
  "STATUS_ORDER_WAITING_SHIP": "To ship",
  "transaction_education": "Education",
  "transaction_rating": "Rating",
  "INTEGRATED": "Integrated",
  "TRANSACTION_MESSAGE": "Transaction message",
  "change_display_position": "change display position",
  "change_position": "change position",
  "change_last_care": "change last care",
  "points": "points",
  "merge_customer": "Automatically group customers by phone number",
  "MISSING_PHONE_NUMBER": "Missing phone number",
  "USE_POINT": "Use point",
  "HISTORY_USE_POINT": "History use point",
  "point" : "Point",
  "January": "January",
  "February": "February",
  "March": "March",
  "April": "April",
  "May": "May",
  "June": "June",
  "July": "July",
  "August": "August",
  "September": "September",
  "October": "October",
  "November": "November",
  "December": "December",
  "DEPOSIT_MONEY_TO_ACCOUNT": "Deposit money to account",
  "CHARGED": "Charged",
  "AMOUNT_OF_MONEY": "Amount of money",
  "USER_ID": "User id",
  "MERGE_CUSTOMER": "Merge Customer",
  "FLOW_SCRIPT_INSTAGRAM": "Flow script Instagram",
  "FLOW_NEW_INSTAGRAM": "Flow new Instagram",
  "SELECT_AVAILABLE_FLOW": "Select an available flow",
  "SYNC_AFFILIATE": "Sync affiliate",
  "COVERSATION_TAG" : "Change conversation tag",
  "Reload": "Reload",
  "COVERSATION_NOTE": "Do you want to use Conversion API to increase your efficiency in reaching customers?",
  "ADS_MONEY": "Ads money",
  "REPORT_CAMPAIGN": "Campaign Report",
  "TOTAL_VALUE_OF_GOODS" : "Total value of goods",
  "CONNECT_CONVERSION_API": "Connect Conversion API",
  "position": "Position",
  "CUSTOMER_INTERACTION": "Customer interaction",
  "ORDER_SUCCESS" : "Order success",
  "OLD_CUSTOMER": "Old Customer",
  "order_from_old_customer": "Order from old customer",
  "order_from_old_customer_percent": "Rate of closing orders from old customers",
  "order_new_customer": "Order new customer",
  "order_new_customer_percent": "Rate of closing orders from new customers",
  "TOTAL_CUSTOMER" : "Total customer",
  "REPORT_INTERACTION" : "Interaction Report",
  "Budget" : "Budget",
  "Balance" : "Balance",
  "REPORT_SALE": "Sale Report",
  "impressions" : "impressions",
  "approach": "approach",
  "number_of_clicks_on_the_link": "Number of clicks on the link",
  "new_message_connection": "New message connection",
  "The_message_conversation_has_begun": "The message conversation has begun",
  "Purchases": "Purchases",
  "conversion_value_per_purchase": "Conversion value per purchase",
  "ROAS": "ROAS",
  "Lead": "Lead",
  "REPORT_ADVERTISEMENT": "Advertisement Report",
  "cost_per_unique_click" : "Cost per unique click",
  "read_insights": "read insights",
  "pages_manage_metadata": "pages manage metadata",
  "pages_messaging" : "pages messaging",
  "product_category_id": "Product category",
  "order_price": "Order price",
  "total_order_money": "Total order money",
  "last_order_created": "Last order created",
  "CUSTOMER_BY_ORDER": "Customer by order",
  "first_reply_assignee": "First reply assignee(not chatbot)",
  "SHIPPING_FEE_PAID_BY_SHOP": "Shipping fee paid by shop",
"COST_OF_GOOD_SOLD": "Cost of good sold",
  "DELIVERED": "Delivered",
  "NUMBER_OF_ORDERS": "Number of orders",
  "PRODUCT_SOLD": "Product sold",
  "PRICE": "Price",
  "SHIPPING_FEE_PAID_BY_CUSTOMER": "Shipping fee paid by customer",
  "SURCHARGE": "Surcharge",
  "MARKETPLACE_FEE": "MarketPlace fee",
  "PROCESSING": "Processing",
  "RETURNED_ORDER": "Returned order",
  "CANCELED_OR_DELETED": "Canceled / Deleted",
  "unread_conversation": "Unread conversation",
  "PROFIT" : "Profit",
  "Revenue_over_time": "Revenue over time",
  "Revenue_by_employee": "Revenue by employee",
  "TIMEOUT_EACH_MESSAGE": "Timeout each message",
  "Business": "Business",
  "REPORT_EMPLOYEE": "Report employee",
  "champions": "Champions",
  "potential_loyalists": "Potential loyalists",
  "new_customer" : "New customer",
  "promising": "Promising",
  "loyal_customer": "Loyal customer",
  "need_attention": "Need attention",
  "about_to_sleep": "About to sleep",
  "cant_loose": "Cant loose",
  "at_risk": "At risk",
  "hibernating": "Hibernating",
  "RECENCY" : "Recency",
  "MONETARY": "Monetary",
  "segment": "segment",
  "Report_Customer_Range": "Range",
  "Report_Customer_Segments": "Segments",
  "Report_Customer_Segments_Count": "Segments Count"
}
