import create from "zustand-vue";
import { persist } from "zustand/middleware";
import APIs from "@/APIs/APIs";
import { Events } from "@/models/Event/Event";
import { Metadata } from "@/APIs/response/S_HttpResponse";

export type EventData = {
  events: Events[] | undefined;
  metadata: Metadata | undefined;
};

interface EventState {
  eventData: EventData | undefined;
  getEvent: (params: any) => void;
}

const initialState = {
  eventData: { events: [], metadata: {} },
};

const useEventStore = create<EventState>(
  // @ts-ignore
  persist(
    (set) => ({
      ...initialState,
      getEvent: async (params) => {
        const response = await APIs.init().getEvent(params);
        if (response.data && response.data.status === 200) {
          set(() => ({
            eventData: {
              events: response.data?.data,
              metadata: response.data?.meta_data,
            },
          }));
        }
      },
    }),
    {
      name: "event",
    }
  )
);

export default useEventStore;
