<template>
  <a-layout-content>
    <div class="container-title">
      <a-form-item class="filter-form-item-inline" :label="$t('SALE_STAFF')">
        <a-select
            style="width: 250px"
            allowClear
            v-model:value="requestState.employee_id"
            placeholder="Please select"
            @change="onChangeEmployee"
        >
          <a-select-option value="">{{ $t("ALL") }}</a-select-option>
          <a-select-option
              v-for="item in getListEmployee"
              :key="item.cms_user_id"
              v-bind:value="item.cms_user_id"
          >{{ item.name }}
          </a-select-option>
        </a-select>
      </a-form-item>
      <a-form-item class="filter-form-item-inline" >

      <a-range-picker
          v-model:value="currentRangeDate"
          format="DD-MM-YYYY"
          @change="dateRangeChange"
      />
      </a-form-item>

    </div>
    <a-card class="card_view" style="border-radius: 10px; margin: 16px;">
      <keep-alive>
        <highcharts class="chart" :options="{
      chart: {
        type: 'column',
      },
      title: {
        text: $t('REPORT_CUSTOMER_BY_TAGS')
      },
      xAxis: {
        categories: labelsTag
      },
      yAxis: {
        min: 0,
        title: {
          text: null
        },
      },
      legend: {
        align: 'right',
        verticalAlign: 'top',
        borderColor: '#CCC',
        borderWidth: 1,
        shadow: false
      },
      tooltip: {
        headerFormat: '<b>{point.x}</b><br/>',
      },
      plotOptions: {
        column: {
          stacking: 'normal',
          dataLabels: {
            enabled: true
          }
        }
      },
      series: chartDataReportTagAll.map((a, index)=>({
        name: labelsTagEmployee[index],
        data: a,
      })),
    }"></highcharts>
      </keep-alive>
    </a-card>
    <a-card class="card_view" style="border-radius: 10px; margin: 16px;">
      <keep-alive>
        <highcharts
            class="chart" :options="{
      chart: {
        type: 'column',
      },
      title: {
        text: $t('REPORT_CUSTOMER_BY_CARE')
      },
      xAxis: {
        categories: labels
      },
      yAxis: {
        min: 0,
        title: {
          text: null
        },
      },
      legend: {
        align: 'right',
        verticalAlign: 'top',
        borderColor: '#CCC',
        borderWidth: 1,
        shadow: false
      },
      tooltip: {
        headerFormat: '<b>{point.x}</b><br/>',
      },
      plotOptions: {
        column: {
          stacking: 'normal',
          dataLabels: {
            enabled: true
          }
        }
      },
      series: chartDataReportCareAll.map((a, index)=>({
        name: labelsCareEmployee[index],
        data: a,
      }))
    }"></highcharts>

      </keep-alive>
    </a-card>
  </a-layout-content>
</template>

<script>

import {useStore} from "vuex";
import {ShopinfoActionTypes} from "@/store/modules/Shopinfo/action-types";
import {computed, onMounted, ref} from "vue";
import moment, {Moment} from "moment";
import {useI18n} from "vue-i18n";
import APIs from "@/APIs/APIs";
import {MixUtils} from "@/utils/Utils";
import {message} from "ant-design-vue";
import dayjs from "dayjs";
import _ from "lodash";

export default {
  components: {
  },
  setup() {
    const i18n = useI18n()
    const store = useStore();
    const currentRangeDate = ref([dayjs().subtract(30, 'days'), dayjs()])
    const requestState = ref({})
    const fetchShopInfo = () => {
      store.dispatch(ShopinfoActionTypes.FETCH_SHOPINFO);
    };
    const getListEmployee = computed(() => store.getters.getListEmployee);


    const chartData = ref({
      currentUserData: [],
      currentConversationData: [],
      labelCurrent: [],
    })

    const chartDataReportTag = ref({
      total: [],
      label: [],
    })
    const chartDataReportCare = ref({
      total: [],
      label: [],
    })

    const chartDataReportCareAll = ref([])
    const chartDataReportTagAll = ref([])
    const labels = ref([])
    const labelsTag = ref([])
    const labelsTagEmployee = ref([])
    const labelsCareEmployee = ref([])

    const getReportCustomerTag = async () => {

      const response = await APIs.init().reportCustomerTagByStaff({...{
        from_date: currentRangeDate.value[0].format("YYYY-MM-DD")+"T00:00:00Z",
        to_date: currentRangeDate.value[1].format("YYYY-MM-DD")+"T00:00:00Z"
      }, ...requestState.value})
      chartDataReportTagAll.value= []
      labelsTagEmployee.value= []
      if (response.status === 200 && response.data?.status === 200) {

            var careAll = _(response.data?.data)
                .groupBy(x => x.tag_id)
                .map((value, key) => ({id: key, name: value[0].tag_name, value: value}))
                .value();
            var employee = _(response.data?.data)
                .groupBy(x => x.employee_id)
                .map((value, key) => ({id: key, name: value[0].employee_name, value: value}))
                .value();


          labelsTag.value = careAll.map(e=>e.name)
          for (let i = 0; i < employee.length; i++) {
            const a = []
            for (let j = 0; j < careAll.length; j++) {
              const care = careAll[j].value.find(care=>care.employee_id== employee[i].id)
              if(care){
                a.push(care.total)
              }else{
                a.push(0)

              }
            }
            chartDataReportTagAll.value.push(a)
            labelsTagEmployee.value.push(employee[i].name)
        }
      }

    }

    const getReportCustomerCare = async () => {

      const response = await APIs.init().reportCustomerCareByStaff({...{
        from_date: currentRangeDate.value[0].format("YYYY-MM-DD")+"T00:00:00Z",
        to_date: currentRangeDate.value[1].format("YYYY-MM-DD")+"T00:00:00Z"
      }, ...requestState.value})
      chartDataReportCareAll.value = []
      labelsCareEmployee.value = []
      if (response.status === 200 && response.data?.status === 200) {
        if(response.data?.data){
          var careAll = _(response.data?.data)
              .groupBy(x => x.care_id)
              .map((value, key) => ({id: key, name: value[0].care_name, value: value}))
              .value();
          var employee = _(response.data?.data)
              .groupBy(x => x.employee_id)
              .map((value, key) => ({id: key, name: value[0].employee_name, value: value}))
              .value();

        console.log(careAll)
          labels.value = careAll.map(e=>e.name)
          for (let i = 0; i < employee.length; i++) {
            const a = []
            for (let j = 0; j < careAll.length; j++) {
              const care = careAll[j].value.find(care=>care.employee_id== employee[i].id)
              if(care){
                a.push(care.total)
              }else{
                a.push(0)

              }
            }
            chartDataReportCareAll.value.push(a)
            labelsCareEmployee.value.push(employee[i].name)
          }

          chartDataReportCare.value = {
            total: response.data?.data.map(t=>t.total),
            label: careAll.map(t=>t.name),
            color: response.data?.data.map(t=>t.color),
          }
        }else{
          chartDataReportCare.value = {
            total: [],
            label: [],
            color: [],
          }
        }
      }

    }

    const dateRangeChange = (val) => {
      getReportCustomerTag();
    }

    const formatMoney = (val) => {
      return MixUtils.methods.formatMoney(val)
    }

    const onChangeEmployee = ()=>{
      getReportCustomerTag();
      getReportCustomerCare();
    }

    onMounted(() => {
      fetchShopInfo();
      getReportCustomerTag();
      getReportCustomerCare();
    });

    return {
      requestState,
      getListEmployee,
      chartData,
      chartDataReportTag,
      chartDataReportCare,
      formatMoney,
      currentRangeDate,
      dateRangeChange,
      onChangeEmployee,
      labels,
      labelsTag,
      chartDataReportCareAll,
      chartDataReportTagAll,
      labelsTagEmployee,
      labelsCareEmployee,
    };
  },
};
</script>

<style>
.card_view {
  border-radius: 10px;
  margin: 4px;
  overflow: hidden;
}

.count-elements {
  -webkit-tap-highlight-color: transparent;
  -webkit-text-size-adjust: 100%;
  font-family: Roboto, Helvetica, Arial, sans-serif;
  font-variant: tabular-nums;
  line-height: 1.5;
  font-feature-settings: 'tnum';
  font-size: 14px;
  color: #000;
  box-sizing: border-box;
  height: 76px;
  padding: 10px;
  border-top: 1px solid #e0e0e0;
  border-bottom: 1px solid #e0e0e0;
  border-radius: 10px 0px 0px 10px;
  cursor: pointer;
  box-shadow: 0px 2px 10px rgba(51, 51, 51, 0.1);
  border-left: 10px solid rgb(43, 216, 196);
  background-color: rgba(196, 255, 248, 0.23);
  width: 100%;
}

.title {
  font-family: "Roboto" !important;
  font-style: normal !important;
  font-weight: 700 !important;
  font-size: 12px !important;
  line-height: 22px !important;
  color: #000000 !important;
  margin-left: 0px !important;
  margin-top: 0px !important;
  text-align: left !important;
  width: 100% !important;
}

.count {
  text-align: right;
  font-weight: 100;
  font-family: Montserrat-Light;
  font-size: 25px;
  line-height: 30px;
  color: #263C8F;
  float: right;
}

.highcharts-credits {
  display: none;
}
.filter-form-item-inline {
  display: inline-block;
  margin-bottom: 0;
  padding-bottom: 0;
}
.count-elements {
  -webkit-tap-highlight-color: transparent;
  -webkit-text-size-adjust: 100%;
  font-family: Roboto, Helvetica, Arial, sans-serif;
  font-variant: tabular-nums;
  line-height: 1.5;
  font-feature-settings: 'tnum';
  font-size: 14px;
  color: #000;
  box-sizing: border-box;
  height: 76px;
  padding: 10px;
  border-top: 1px solid #e0e0e0;
  border-bottom: 1px solid #e0e0e0;
  border-radius: 10px 0px 0px 10px;
  cursor: pointer;
  box-shadow: 0px 2px 10px rgba(51, 51, 51, 0.1);
  border-left: 10px solid rgb(43, 216, 196);
  background-color: rgba(196, 255, 248, 0.23);
  width: 100%;
}

.title {
  font-family: "Roboto" !important;
  font-style: normal !important;
  font-weight: 700 !important;
  font-size: 12px !important;
  line-height: 22px !important;
  color: #000000 !important;
  margin-left: 0px !important;
  margin-top: 0px !important;
  text-align: left !important;
  width: 100% !important;
}

.count {
  text-align: right;
  font-weight: 100;
  font-family: Montserrat-Light;
  font-size: 25px;
  line-height: 30px;
  color: #263C8F;
  float: right;
}

</style>
