<template>
  <div class="container-title">
    <h3 class="title-header">{{ formState.id ? $t("update_product") : $t("create_a_new_product") }}</h3>
  </div>

  <div style="padding: 16px">
    <div class="product-edit-container">
      <a-card :bordered="false" class="product-edit-card">
        <template #title>
            <span>
              <info-circle-outlined/>
              <span class="common-info-title"
              >{{ $t("GENERAL_INFORMATION") }}
              </span>
            </span>
        </template>
        <template #extra>
          <div class="header-product-buttons">
<!--            <a v-if="formState.id" :href="'/crm/product_preview/'+formState.id" target="_blank">-->
<!--              <a-button>-->
<!--                <PrinterOutlined />-->
<!--                {{ $t("PRINT_LABEL") }}-->
<!--              </a-button>-->
<!--            </a>-->
<!--            <a-button class="button-add-version" @click="addVersion">-->
<!--              <skin-outlined/>-->
<!--              {{ $t("add_product_version") }}-->
<!--            </a-button>-->
            <a-button @click="saveProduct" type="primary">
              <save-outlined/>
              {{ $t("SAVE") }}
            </a-button>
          </div>
        </template>
        <a-form
            ref="formProductGroupRef"
            :model="formState"
            :label-col="{ span: 8 }"
            :wrapper-col="{ span: 16 }"
            :rules="rules"
        >
          <div class="inline-form-customer">
              <a-form-item has-feedback :label="$t('product_name')" name="group_name">
                <a-input
                    type="text"
                    autocomplete="off"
                    v-model:value="formState.group_name"
                    @change="productGroupChange"
                />
              </a-form-item>
          </div>

          <div class="inline-form-customer">
              <a-form-item
                  has-feedback
                  :label="$t('BIG126')"
                  name="categories_id"
              >
                <a-select
                    showSearch
                    allowClear
                    v-model:value="formState.categories_id"
                    :placeholder="$t('BIG126')"
                    style="width: 100%"
                >
                  <a-select-option
                      v-for="item in dataCategory"
                      :key="item.id"
                      v-bind:value="item.id"
                  >{{ item.category_name }}
                  </a-select-option>
                  <a-select-option style="justify-content: center" @click="handleAddCategory">
                    <a-typography-text style="color: #fab216;margin-left: 60px">{{ $t('quick_create') }}
                    </a-typography-text>
                  </a-select-option>

                </a-select>
              </a-form-item>
          </div>
          <div class="inline-form-customer">
              <a-form-item has-feedback :label="$t('STATUS')" name="status">
                <a-select
                    v-model:value="formState.status"
                    :placeholder="$t('STATUS')"
                    style="width: 100%"
                >
                  <a-select-option :value="1">Hoạt động</a-select-option>
                  <a-select-option :value="0">Mới</a-select-option>
                  <a-select-option :value="2">Ngưng bán</a-select-option>
                </a-select>
              </a-form-item>
          </div>

          <div class="inline-form-customer">
            <a-form-item has-feedback :label="$t('unit_price')" name="group_name" >
              <a-input-number
                  v-model:value="formState.price"
                  style="width: 300px"
                  :formatter="value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')"
                  :parser="value => value.replace(/\$\s?|(,*)/g, '')"
              />
            </a-form-item>
          </div>


              <a-form-item>
                <div
                    class="ant-upload ant-upload-select ant-upload-select-picture-card upload-image"
                >
                    <span role="button" tabindex="0" class="ant-upload">
                      <div @click="showImageSelectionModal(1)">
                        <plus-outlined/>
                        <div class="ant-upload-text">{{ $t("UPLOAD") }}</div>
                      </div>
                    </span>
                </div>
                <span>
                    <a-upload
                        list-type="picture"
                        v-model:file-list="fileList"
                        class="upload-list-inline"
                    >
                    </a-upload>
                  </span>

                <span class="ant-upload-picture-card-wrapper"> </span>
              </a-form-item>
        </a-form>
        <div>
          <a-form-item
              has-feedback
              :label="$t('DESCRIPTION')"
              name="group_desc"
          >
            <!--                <a-textarea v-model:value="formState.group_desc"/>-->
            <ckeditor
                v-model="formState.group_desc"
                tag-name="textarea"
                :editor="ClassicEditor"
                :config="config"
                @ready="onReady"
                @focus="onFocus"
                @blur="onBlur"
                @input="onInput"
                @destroy="onDestroy"
            />

          </a-form-item>
        </div>
      </a-card>
    </div>
    <div>
      <template v-if="true">
        <a-card :bordered="false" class="product-new-version">
          <template #extra>
            <a-button
                @click="addVersion" type="dashed">
              <plus-outlined/>
              {{ $t("add_product_version") }}
            </a-button>
          </template>
          <template #title>
            <skin-outlined/>
            {{ $t("product_version") }}
          </template>
          <template v-for="(attribute, index) in listAttribute" v-bind:key="attribute">
            <div>
            <div>
              <span>
                {{$t('attribute') + ' '+(index+1)}}
              </span>
              <a @click="deleteAttribute(index)" style="margin-left: 16px"><DeleteOutlined/></a>
            </div>
              <attribute :change="attribute=>changeAttribute(index, attribute)"/>
            </div>
          </template>
          <a-table
              :columns="versionColumns"
              :data-source="versions"
              :pagination="false"
              :locale="{emptyText: 'No Data'}"
          >
            <template #product_name="{ index }">
              <a-input
                  :disabled="formState.id && !versions[index].is_edit"
                  v-model:value="versions[index].product_name" />
            </template>
            <template #customTitle>
                <span>
                  <smile-outlined/>
                  {{ $t("NAME") }}

                </span>

            </template>
            <template #avatar_url="{ record }">
                <span>
                  <a-image
                      width="50px"
                      max-height="100px"
                      max-width="100px"
                      :src="record.avatar_url"
                  />
                </span>
            </template>
            <template #total="{ text }">
                <span class="total">
                  {{ text }}
                </span>
            </template>
            <template #avg_import_price="{ text }">
                <span class="avg_import_price">
                  {{ formatMoney(text) }}
                </span>
            </template>
            <template #max_import_price="{ text }">
                <span class="max_import_price">
                  {{ formatMoney(text) }}
                </span>
            </template>
            <template #min_import_price="{ text }">
                <span class="min_import_price">
                  {{ formatMoney(text) }}
                </span>
            </template>
            <template #unit_price="{ index }">
              <a-input-number
                  :disabled="formState.id && !versions[index].is_edit"
                  v-model:value="versions[index].unit_price" style="width: 100px"
                              :formatter="value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')"
                              :parser="value => value.replace(/\$\s?|(,*)/g, '')"
              />
            </template>
            <template #unit_name="{ index }">
              <a-input
                  :disabled="formState.id && !versions[index].is_edit"
                  v-model:value="versions[index].unit_name" style="width: 100px"
              />
            </template>
            <template #promotional_price="{ index }">
              <a-input-number
                  :disabled="formState.id && !versions[index].is_edit"
                  v-model:value="versions[index].promotional_price" style="width: 100px"
                              :formatter="value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')"
                              :parser="value => value.replace(/\$\s?|(,*)/g, '')"
              />
            </template>
            <template #wholesale_price="{ index }">
              <a-input-number
                  :disabled="formState.id && !versions[index].is_edit"
                  v-model:value="versions[index].wholesale_price" style="width: 100px"
                              :formatter="value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')"
                              :parser="value => value.replace(/\$\s?|(,*)/g, '')"
              />
            </template>
            <template #quantity="{ index }">
              <a-input-number v-model:value="versions[index].quantity" style="width: 100px"
                              :formatter="value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')"
                              :parser="value => value.replace(/\$\s?|(,*)/g, '')"
                              :disabled="formState.id && !versions[index].is_edit"
              />
            </template>
            <template #weight="{ index }">
              <a-input-number
                  :disabled="formState.id && !versions[index].is_edit"
                  v-model:value="versions[index].weight" style="width: 100px"
                              :formatter="value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')"
                              :parser="value => value.replace(/\$\s?|(,*)/g, '')"
              />
            </template>
            <template #operation="{ record, index }">
              <div class="editable-row-operations">
                  <span>
                    <a-button
                        v-if="formState.id && !versions[index].is_edit" @click="editVersion(index)" type="text">
                      <edit-outlined/>
                    </a-button>
                    <a-button
                        v-if="formState.id && versions[index].is_edit" @click="saveVersion(index)" type="text">
                      <SaveOutlined/>
                    </a-button>
                    <a-popconfirm
                        :title="$t('delete_product_version')"
                        :ok-text="$t('OK')"
                        :cancel-text="$t('CANCEL')"
                        @confirm="confirmDeleteVersion(record, index)"
                    >
                      <a-button type="text">
                        <delete-outlined/>
                      </a-button>
                    </a-popconfirm>
                  </span>
              </div>
            </template>
          </a-table>
        </a-card>
      </template>
    </div>
  </div>
  <a-modal v-model:visible="editVisible" :title="formState.id?$t('UPDATE_CATEGORY'):$t('ADD_CATEGORY')"
           :closable="false"
           :footer="null"
           style="width: 40%"
  >
    <a-form
        ref="formRef"
        :model="formState"
        :rules="rules"
        :label-col="labelCol"
        :wrapper-col="wrapperCol"
    >
      <a-form-item :label="$t('NAME')" name="category_name">
        <a-input v-model:value="formStateCategories.category_name"/>
      </a-form-item>
      <a-form-item :label="$t('DESCRIPTION')" name="description">
        <a-input v-model:value="formStateCategories.description"/>
      </a-form-item>
      <a-form-item :wrapper-col="{ offset: 8 }">
        <a-button type="primary" @click="createProductCategory"> {{ $t('CREATE') }}</a-button>
        <a-button style="margin-left: 10px" @click="handleCancel"> {{ $t('CANCEL') }}
        </a-button>
      </a-form-item>
    </a-form>
  </a-modal>
  <a-modal
      v-model:visible="versionEditModalVisible"
      :width="screenWidth >= 780 ? '1200px' : '95%'"
      @ok="saveProductVersion"
  >
    <template #title>
          <span>
            <skin-outlined />
            {{ $t("add_product_version") }}
          </span>
    </template>
    <a-form
        ref="formProductVersionRef"
        :rules="rulesVersion"
        name="custom-validation"
        :model="formStateVersion"
        :label-col="labelCol"
        :wrapper-col="wrapperCol"
    >
      <a-row>
        <a-col :span="12">
          <a-form-item has-feedback :label="$t('NAME')" name="product_name">
            <a-input
                v-model:value="formStateVersion.product_name"
                type="text"
                autocomplete="off"
            />
          </a-form-item>
          <a-form-item
              has-feedback
              :label="$t('product_code')"
              name="product_code"
          >
            <a-input v-model:value="formStateVersion.product_code" />
          </a-form-item>
          <a-form-item
              has-feedback
              :label="$t('DESCRIPTION')"
              name="product_desc"
          >
            <a-textarea v-model:value="formStateVersion.product_desc" />
          </a-form-item>
          <a-form-item has-feedback :label="$t('Barcode')" name="bar_code">
            <a-input v-model:value="formStateVersion.bar_code" />
          </a-form-item>
        </a-col>
        <a-col :span="12">
          <a-form-item has-feedback :label="$t('Weight')" name="weight">
            <a-input-number
                class="input-number"
                v-model:value="formStateVersion.weight"
                :formatter="
                    (value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                  "
                :parser="(value) => value.replace(/\$\s?|(,*)/g, '')"
            />
          </a-form-item>
          <a-form-item
              has-feedback
              :label="$t('sale_price')"
              name="unit_price"
          >
            <a-input-number
                class="input-number"
                v-model:value="formStateVersion.unit_price"
                :formatter="value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')"
                :parser="value => value.replace(/\$\s?|(,*)/g, '')"
            />
          </a-form-item>
                        <a-form-item has-feedback :label="$t('promotional_price')" name="promotional_price">
                          <a-input-number
                              class="input-number"
                              v-model:value="formStateVersion.promotional_price"
                              :formatter="value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')"
                              :parser="value => value.replace(/\$\s?|(,*)/g, '')"
                          />
                        </a-form-item>
                        <a-form-item has-feedback :label="$t('wholesale_price')" name="wholesale_price">
                          <a-input-number
                              class="input-number"
                              v-model:value="formStateVersion.wholesale_price"
                              :formatter="value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')"
                              :parser="value => value.replace(/\$\s?|(,*)/g, '')"
                          />
                        </a-form-item>
          <a-form-item has-feedback :label="$t('UNIT')" name="unit">
            <a-input
                class="input-number"
                v-model:value="formStateVersion.unit_name"
                :formatter="value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')"
                :parser="value => value.replace(/\$\s?|(,*)/g, '')"
            />
          </a-form-item>

          <a-form-item>
            <div
                class="ant-upload ant-upload-select ant-upload-select-picture-card"
            >
                  <span role="button" tabindex="0" class="ant-upload">
                    <div @click="showImageSelectionModal(2)">
                      <plus-outlined />
                      <div class="ant-upload-text">{{ $t("UPLOAD") }}</div>
                    </div>
                  </span>
            </div>
            <span>
                  <a-upload
                      list-type="picture"
                      v-model:file-list="fileListVersion"
                      class="upload-list-inline"
                  >
                  </a-upload>
                </span>

            <span class="ant-upload-picture-card-wrapper"> </span>
          </a-form-item>
        </a-col>
      </a-row>
    </a-form>
  </a-modal>


  <image-selection-modal ref="imageSelectionRef" :onSelect="onImageSelect"/>

</template>

<script lang="ts">
import {computed, defineComponent, nextTick, onMounted, reactive, ref, toRefs, UnwrapRef,} from "vue";
import {useRoute} from "vue-router";
import {
  Product, Attribute,
  ProductAttribute,
  ProductCategory,
  ProductDetail,
  ProductProvider,
  ProductStock,
} from "@/models/Products/Products";
import {useStore} from "@/store";
import ImageSelectionModal from "@/components/Image/ImageSelectionModal.vue";
import {
  DeleteOutlined,
  EditOutlined,
  InfoCircleOutlined,
  PlusOutlined,
  SaveOutlined,
  SkinOutlined,
  // PrinterOutlined,
} from "@ant-design/icons-vue";
import APIs from "@/APIs/APIs";
import {message, Modal} from "ant-design-vue";
import {Tag} from "@/models/Tags/Tags";
import {Stocks} from "@/models/Stocks/Stocks";
import {MixUtils} from "@/utils/Utils";
import {ProductGroupCreateResponse} from "@/APIs/response/S_HttpResponse";
import {useI18n} from "vue-i18n";
import {CreateProductCategoryRequest, ProductCategoryRequest} from "@/APIs/request/ProductRequest";
import {SuperUserInfo} from "@/models/Users";
import attribute from "@/components/Attribute.vue";
import router from "@/router";
// @ts-ignore
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
// @ts-ignore
import type { EventInfo } from '@ckeditor/ckeditor5-utils';

const formStateVersionDefault = {
  id_temp: undefined,
  id: undefined,
  shop_id: undefined,
  product_group_id: undefined,
  product_code: undefined,
  product_name: undefined,
  product_desc: undefined,
  bar_code: undefined,
  avatar_url: undefined,
  purchase_price: undefined,
  market_price: undefined,
  unit_price: undefined,
  unit_name: undefined,
  weight: undefined,
  is_delivery: undefined,
  is_stock: undefined,
  is_allow_order: undefined,
  is_allow_ship: undefined,
  ship_zone_1: undefined,
  ship_zone_2: undefined,
  ship_zone_3: undefined,
  created_by: undefined,
  created_at: undefined,
  updated_at: undefined,
  attributes: undefined,
  quantity: 0,
  price: undefined,
  status: 0,
  stocks_obj: [],
  attributes_obj: [],
};
export default defineComponent({
  name: "ProductModal",
  computed: {
    ClassicEditor() {
      return ClassicEditor
    }
  },
  components: {
    attribute,
    InfoCircleOutlined,
    PlusOutlined,
    DeleteOutlined,
    SaveOutlined,
    SkinOutlined,
    EditOutlined,
    // PrinterOutlined,
    ImageSelectionModal,
  },
  created() {
    this.$watch(
        () => this.$route.params,
        () => {
          if(this.$route.params.id){
            this.fetchProductDetail(this.$route.params.id)
          }
        },
        // fetch the data when the view is created and the data is
        // already being observed
        { immediate: true }
    )
  },
  setup(props) {
    const route = useRoute();
    const store = useStore();
    const i18n = useI18n();
    const dataCategory = ref<any>([])
    const formState: UnwrapRef<ProductDetail> = reactive({versions: []});
    const categoryRequest: ProductCategoryRequest = {
      page: 1,
      page_size: 1000,
    }

    const versions = ref<Product[]>([])
    const listAttribute = ref<Attribute[]>([])

    const genVersion = ()=>{
      if(!formState.id)
      if(listAttribute.value.length==3){
        versions.value = [];
        if(listAttribute.value[0].tags && listAttribute.value[1].tags && listAttribute.value[2].tags ){
          for (let i = 0; i < listAttribute.value[0].tags?.length; i++) {
            for (let j = 0; j < listAttribute.value[1].tags?.length; j++) {
              for (let k = 0; k < listAttribute.value[2].tags?.length; k++) {
                versions.value.push({
                  product_name: (formState.group_name?formState.group_name:'') + '/ '+ listAttribute.value[0].tags[i] + '/ '+ listAttribute.value[1].tags[j] + '/ '+ listAttribute.value[2].tags[k],
                  weight: 100
                })
              }
            }
          }
        }
      }else if(listAttribute.value.length==2){
        versions.value = []
        if(listAttribute.value[1].tags && listAttribute.value[0].tags){
          for (let i = 0; i < listAttribute.value[0].tags?.length; i++) {
            for (let j = 0; j < listAttribute.value[1].tags?.length; j++) {
                versions.value.push({
                  product_name: (formState.group_name?formState.group_name:'') + '/ '+ listAttribute.value[0].tags[i] + '/ '+ listAttribute.value[1].tags[j],
                  weight: 100
                })
            }
          }
        }
      } else if(listAttribute.value.length==1){
        versions.value = []
        if(listAttribute.value[0].tags){
          for (let i = 0; i < listAttribute.value[0].tags?.length; i++) {
              versions.value.push({
                product_name: (formState.group_name?formState.group_name:'') + '/ '+ listAttribute.value[0].tags[i],
                weight: 100

              })
          }
        }
      }else{
        versions.value= [{
          product_name: formState.group_name,
          weight: 100
        }]
      }
      console.log('version', versions.value)
    }

    const getUserInfo = computed((): SuperUserInfo | null => store.getters.getUserInfo)
    const createProductCategoryRequest: CreateProductCategoryRequest = {
      shop_id: getUserInfo.value?.current_shop_id,
      category_name: '',
      description: '',
      creator: getUserInfo.value?.id
    }
    const formStateCategories: UnwrapRef<ProductCategory> = reactive({
      id: undefined,
      category_name: '',
      description: '',
    });
    const editVisible = ref(false)
    const createCategories = () => {
      formStateCategories.id = 0
      formStateCategories.category_name = ''
      formStateCategories.description = ''
      editVisible.value = true
    };
    const createProductCategory = () => {

      message.loading('');
      createProductCategoryRequest.category_name = formStateCategories.category_name
      createProductCategoryRequest.description = formStateCategories.description
      APIs.init().createProductCategory(createProductCategoryRequest).then(res => {
        if (res.status === 200 && res.data?.status === 200) {
          editVisible.value = false
          formState.categories_id = res.data.data ? res.data.data?.id:0
          message.success({content: 'Created category: ' + res.data.data.category_name});
          APIs.init().getProductCategories(categoryRequest).then(res => {
            dataCategory.value = res.data?.data
          })

        } else {
          message.destroy()
        }
      }).catch(e => {
        message.destroy()
        console.error(e)
      })
    }
    const attributeColumns = [
      {
        title: i18n.t("label"),
        dataIndex: "label",
        slots: {customRender: "label"},
      },
      {
        title: i18n.t("value"),
        dataIndex: "value",
        slots: {customRender: "value"},
      },
      {
        title: i18n.t("operation"),
        slots: {customRender: "operation"},
      },
    ];

    const stocksColumns = [
      {
        title: i18n.t("STOCK"),
        dataIndex: "stock",
        slots: {customRender: "stock"},
      },
      {
        title: i18n.t("Provider"),
        dataIndex: "provider_id",
        slots: {customRender: "provider"},
      },
      {
        title: i18n.t("Quantity"),
        dataIndex: "quantity",
        slots: {customRender: "quantity"},
      },
      {
        title: i18n.t("import_price"),
        dataIndex: "import_price",
        slots: {customRender: "import_price"},
      },
      // {
      //   title: 'Sale price',
      //   dataIndex: 'sale_price',
      //   slots: {customRender: 'sale_price'},
      // },
      {
        title: i18n.t("operation"),
        slots: {customRender: "operation"},
      },
    ];

    const versionColumns = [
      // {
      //   title: i18n.t("Code"),
      //   dataIndex: "product_code",
      //   key: "product_code",
      // },
      {
        title: i18n.t("product_name"),
        dataIndex: "product_name",
        key: "product_name",
        slots: {customRender: "product_name"},
      },
      // {
      //   title: i18n.t("camera_image"),
      //   dataIndex: "avatar_url",
      //   key: "avatar_url",
      //   slots: {customRender: "avatar_url"},
      // },
      {
        title: i18n.t("sale_price"),
        key: "unit_price",
        dataIndex: "unit_price",
        slots: {customRender: "unit_price"},
      },
      {
        title: i18n.t("wholesale_price"),
        key: "wholesale_price",
        dataIndex: "wholesale_price",
        slots: {customRender: "wholesale_price"},
      },
      {
        title: i18n.t("promotional_price"),
        key: "promotional_price",
        dataIndex: "promotional_price",
        slots: {customRender: "promotional_price"},
      },
      // {
      //   title: 'Avg Purchase Price',
      //   key: 'avg_import_price',
      //   dataIndex: 'avg_import_price',
      //   slots: {customRender: 'avg_import_price'},
      // },
      {
        title: 'Unit',
        key: 'unit_name',
        dataIndex: 'unit_name',
        slots: {customRender: 'unit_name'},
      },
      {
        title: i18n.t("weight"),
        key: 'weight',
        dataIndex: 'weight',
        slots: {customRender: 'weight'},
      },
      {
        title: i18n.t("Quantity"),
        key: "quantity",
        dataIndex: "quantity",
        slots: {customRender: "quantity"},
      },
      {
        key: i18n.t("operation"),
        slots: {customRender: "operation"},
      },
    ];

    interface FileItem {
      uid: string;
      name?: string;
      status?: string;
      response?: string;
      percent?: number;
      url?: string;
      preview?: string;
      originFileObj?: any;
    }


    const visible = ref(false);
    const productStockLoading = ref(false);
    const imageSelectionRef = ref();
    const formProductGroupRef = ref();
    const formProductVersionRef = ref();

    const fileList = ref<FileItem[]>([]);
    const fileListVersion = ref<FileItem[]>([]);


    const formStateVersion: UnwrapRef<Product> = reactive(
        formStateVersionDefault
    );
    const rules = {
      group_name: [
        {
          required: true,
          message: i18n.t("PLEASE_INPUT_PRODUCT_NAME"),
          trigger: "blur",
        },
      ],
    };


    const rulesVersion = {
      product_name: [
        {
          required: true,
          message: i18n.t("PLEASE_INPUT_PRODUCT_NAME"),
          trigger: "blur",
        },
      ],
      // unit_price: [
      //   {
      //     required: true,
      //     message: i18n.t("PLEASE_INPUT_PRODUCT_PRICE"),
      //     trigger: "blur",
      //
      //   },
      // ],
    };
    const attributeData = ref<ProductAttribute[]>([]);
    const editableData: UnwrapRef<ProductAttribute[]> = reactive([]);
    const attributes = ref<any>([])
    const fetchProductDetail = async (id: any) => {
      message.loading("");
      const response = await APIs.init().getProductDetail({
        product_group_id: id,
      });
      message.destroy();
      if (response.status === 200 && response.data?.status === 200) {
        // store.commit(ProductMutationTypes.SET_PRODUCTS_DETAIL, response.data?.data)
        Object.assign(formState, response.data?.data);
        // formState.tags_ids = response.data?.data.tags_id
        //     ? response.data?.data.tags_id
        //         ?.replace(/_/g, "")
        //         .split(",")
        //         .map((item) => Number(item))
        //     : [];

        console.log('categories_ids',response.data?.data.categories_ids)
        // // @ts-ignore
        // formState.categories_id = response.data?.data.categories_ids
        //     ? response.data?.data.categories_ids
        //
        //     : [];

        formState.versions = response.data?.data.versions
            ? response.data?.data.versions
            : [];
        versions.value =  response.data?.data.versions
            ? response.data?.data.versions
            : [];
        fileList.value = [];
        if (response.data?.data.image_url) {
          response.data?.data.image_url.split(",").map((image) => {
            fileList.value.slice(0, 0);
            fileList.value.push({
              uid: MixUtils.methods.getUUID(),
              name: "xxx.png",
              status: "done",
              url: image,
            });
          });
        }
      }
    };

    const getProductTags = computed(
        (): Tag[] | undefined => store.getters.getProductTags
    );
    const getProductCategories = computed(
        (): ProductCategory[] | undefined => store.getters.getProductCategories
    );
    dataCategory.value = getProductCategories.value
    const getStocks = computed(
        (): Stocks[] | undefined => store.getters.getStocks.stocks
    );
    const getCurrentShop = computed(() => store.getters.getCurrentShop);
    const getProductProviders = computed(
        (): ProductProvider[] | undefined => store.getters.getProductProviders
    );


    const stockData = ref<ProductStock[]>([]);

    const products = ref<number[]>([]);
    const versionEditModalVisible = ref(false);
    const screenWidth = computed(() => window.innerWidth);

    const editableStockData: UnwrapRef<ProductStock[]> = reactive([]);
    const editAttribute = (index: number) => {
      attributeData.value[index].editable = true;
    };
    const saveAttribute = (index: number) => {
      if (attributeData.value[index].label === '' || attributeData.value[index].value === '') {
        message.error('input is not emty')
        return
      }
      attributeData.value[index].editable = false;
    };
    const cancelAttribute = (index: number) => {
      attributeData.value = attributeData.value.filter(
          (item, indexFilter) => indexFilter !== index
      );
      // editableData = editableData.filter(
      //     (item, indexFilter) => indexFilter !== index
      // );

      // Object.assign(
      //     editableData,
      //     editableData.filter((item, indexFilter) => indexFilter !== index)
      // );
    };
    const addAttribute = () => {
      console.log('add attribute')
      if(listAttribute.value.length<3){
        listAttribute.value.push({
          id: MixUtils.methods.getUUID(),
          tags: []
        });
      }
    };

    const deleteAttribute = (index: number) => {
      if(formState.id){
        APIs.init()
            .deleteProduct(versions.value[index])
            .then((versionResponse) => {
              if (versionResponse.status === 200) {
                fetchProductDetail(formState.id);
              } else {
                message.error('Delete error')
              }
            });
      }else{
        listAttribute.value.splice(index, 1)
        genVersion()
      }
    };

    const editStock = (index: number) => {
      stockData.value[index].editable = true;
      if (formState.id) {
        editableStockData[index].product_group_id = formState.id;
        editableStockData[index].id_temp = "0";
      }
    };
    const saveStock = (index: number) => {
      console.log(editableStockData)
      if (editableStockData[index].quantity === 0 || editableStockData[index].import_price === 0) {
        message.error('Quantity  and import price are not empty')
        return
      }
      stockData.value[index].editable = false;
    };
    const cancelStock = (index: number) => {
      stockData.value = stockData.value.filter(
          (item, indexFilter) => indexFilter !== index
      );
      Object.assign(
          editableStockData,
          editableStockData.filter((item, indexFilter) => indexFilter !== index)
      );
    };
    const addStock = () => {
      const id = MixUtils.methods.getUUID();
      editableStockData.push({
        id_temp: id,
        shop_id: getCurrentShop.value?.id,
        stock_id:
            getStocks.value && getStocks.value?.length > 0
                ? getStocks.value[0].id
                : 0,
        product_id: formStateVersion.id,
        import_price: 0,
        promotional_price: 0,
        sale_price: 0,
        quantity_no_sale: 0,
        quantity: 0,
        wholesale_price: 0,
        editable: true,
      });
      stockData.value.push({
        id_temp: id,
        shop_id: getCurrentShop.value?.id,
        stock_id:
            getStocks.value && getStocks.value?.length > 0
                ? getStocks.value[0].id
                : 0,
        product_id: formStateVersion.id,
        import_price: 0,
        promotional_price: 0,
        sale_price: 0,
        quantity_no_sale: 0,
        quantity: 0,
        wholesale_price: 0,
        editable: true,
      });
      // editStock(key)
    };

    // editStock(stockExample.key)
    // editAttribute(attributeExample.key)

    const showImageSelectionModal = (param: any) => {
      imageSelectionRef.value.showModal(param);
    };

    const deleteStock = (index: number) => {
      stockData.value = stockData.value.filter(
          (item, indexFilter) => indexFilter !== index
      );
      editableStockData.splice(index, 1)
    };
    const handleAddCategory = () => {
      formState.categories_ids = []
      editVisible.value = true
    }
    const onImageSelect = (images: FileItem[], param: number) => {
      switch (param) {
        case 1:
          images.map((image) => {
            fileList.value.push({
              uid: MixUtils.methods.getUUID(),
              name: "xxx.png",
              status: "done",
              url: image.url,
            });
          });
          break;
        case 2:
          fileListVersion.value = [
            {
              uid: MixUtils.methods.getUUID(),
              name: "xxx.png",
              status: "done",
              url: images[0].url,
            },
          ];
          formStateVersion.avatar_url = images[0].url
          break;
      }
    };
    const handleCancel = () => {
      editVisible.value = !editVisible.value
      formState.categories_ids = []
    }
    const addVersion = () => {
      versionEditModalVisible.value = true;
      Object.assign(formStateVersion, formStateVersionDefault);
      setTimeout(() => {
        formProductVersionRef.value.resetFields();
        fileListVersion.value = [];
        formStateVersion.id = undefined;
        formStateVersion.attributes = undefined;
        formStateVersion.unit_price = undefined;
        formStateVersion.attributes_obj = [];
        formStateVersion.product_name = formState.group_name;
        formStateVersion.bar_code = undefined;
        formStateVersion.quantity = 0;

        const myCode = formState.group_name
            ? MixUtils.methods
                .removeVietnamese(formState.group_name + "")
                .match(/\b[a-zA-Z]/g)
                ?.join("")
            : "";
        if (myCode)
          formStateVersion.product_code =
              myCode.toUpperCase() + "_" + (formState.versions.length + 1);
        formStateVersion.weight = 100;
        formStateVersion.unit_price = undefined;

      }, 200);
    };

    const fetchProductStock = async (
        product_group_id: number | undefined,
        product_id: number | undefined
    ) => {
      productStockLoading.value = true;
      const response = await APIs.init().getProductStocks({
        product_group_id: product_group_id,
        product_id: product_id,
      });
      if (response.status === 200) {
        editableStockData.splice(0, editableStockData.length);
        stockData.value = [];
        response.data?.data?.map((productStock) => {
          editableStockData.push(productStock);
          stockData.value.push(productStock);
        });
      }
      productStockLoading.value = false;
    };

    const productCheck = async (callback: any) => {
      message.loading("");
      const response = await APIs.init().productCheck({
        product_code: formStateVersion.product_code,
        bar_code: formStateVersion.bar_code,
        product_id: formStateVersion.id,
      });
      message.destroy()
      if (response.status === 200) {
        callback(
            response.data?.data.product_code,
            response.data?.data.bar_code
        );
      }
      // message.destroy()
    };

    const editVersion = (index: number) => {
      versionEditModalVisible.value = true;
      console.log("editVersion", versions.value[index]);
      try {
        setTimeout(() => {
          formProductVersionRef.value.resetFields();
          if (versions.value[index].id) {
            formStateVersion.id = versions.value[index].id;
            formStateVersion.id_temp = "0";
          } else {
            Object.assign(formStateVersion, versions.value[index]);
          }
          Object.assign(formStateVersion, versions.value[index]);
          fileListVersion.value = versions.value[index].avatar_url
              ? [
                {
                  uid: MixUtils.methods.getUUID(),
                  name: "xxx.png",
                  status: "done",
                  url: versions.value[index].avatar_url,
                },
              ]
              : [];
        }, 300);
      } catch (e) {
        console.log(e);
      }

    };
    const saveVersion = async (index: number) => {
      message.loading(i18n.t('UPDATING'));
      const versionResponse = await APIs.init()
          .updateProduct(versions.value[index])
      if (versionResponse.status === 200) {
        formState.versions[index].is_edit = false
      } else {
        message.error(i18n.t('UPDATE_ERROR'));
      }
      message.destroy()
    };

    const createProductGroup = async () => {
      formState.versions = versions.value
      message.loading("");
      const response = await APIs.init().createProductGroup({
        ...formState,
        ...{product_group: formState},
      });
      if (response.status === 200) {
          message.info("Created product " + formState.group_name);
          await router.push(`products`);
      } else {
        message.info("Created product error");
      }
    };

    const updateProductStock = (stock: ProductStock) => {
      return new Promise((resolve, reject) => {
        // handle version deleted
        if (stock.id_temp === "-1" && stock.id) {
          APIs.init()
              .deleteProductStock(stock)
              .then((versionResponse) => {
                if (versionResponse.status === 200) {
                  resolve(true);
                } else {
                  reject(false);
                }
              });
          // handle new version
        } else if (stock.id_temp?.length === 36) {
          stock.product_group_id = formState.id;
          stock.creator = getCurrentShop.value?.cms_user_id;
          APIs.init()
              .createProductStock(stock)
              .then((versionResponse) => {
                if (versionResponse.status === 200) {
                  resolve(true);
                } else {
                  reject(false);
                }
              });
          // handle update version
        } else if (stock.id_temp === "0" && stock.id) {
          stock.creator = getCurrentShop.value?.cms_user_id;
          APIs.init()
              .updateProductStock(stock)
              .then((versionResponse) => {
                if (versionResponse.status === 200) {
                  resolve(true);
                } else {
                  reject(false);
                }
              });
        }
      });
    };

    const updateProductGroup = async () => {
      message.loading("");
      const response = await APIs.init().updateProductGroup(formState);
      if (response.status === 200) {
        try {
          // const handlerAll = await Promise.all([
          //   ...formState.versions.map(
          //       (version) =>
          //           new Promise((resolve, reject) => {
          //             // handle version deleted
          //           })
          //   ),
          //   ...formState.versions.map((version) => {
          //     return version.stocks_obj?.map((stock) => {
          //       return updateProductStock(stock);
          //     });
          //   }),
          // ])
          message.info("Updated product " + formState.group_name);
          fetchProductDetail(formState.id);
          visible.value = false;

        } catch (e) {
          console.log(e);
        }
      } else {
        message.info("Updated product error");
      }
    };

    const saveProductVersion = async () => {
      if(!formState.id){
        formState.versions = versions.value
        message.loading("");
        const response = await APIs.init().createProductGroup({
          ...formState,
          ...{product_group: formState},
        });
        if (response.status === 200) {
          formState.id = response.data?.data?.product_group?.id
          await router.replace(`product/${response.data?.data?.product_group?.id}`);
        } else {
          message.info("Created product error");
        }

      }

      if(fileListVersion.value.length>0){
        formStateVersion.avatar_url = fileListVersion.value[0].url;
      }
      console.log(formStateVersion)
      if (formStateVersion.id) {
        formStateVersion.created_by = getCurrentShop.value?.cms_user_id;
        const versionResponse = await APIs.init()
            .updateProduct(formStateVersion)
        if (versionResponse.status === 200) {
          message.success(i18n.t('UPDATE_VERSION_SUCCESS'))
        }
      }else{
        formStateVersion.product_group_id = formState.id;
        formStateVersion.created_by = getCurrentShop.value?.cms_user_id;
        const versionResponse = await  APIs.init()
            .createProduct(formStateVersion)
        if (versionResponse.status === 200) {
          message.success(i18n.t('CREATE_VERSION_SUCCESS'))
        }
      }

      fileListVersion.value = []
      attributeData.value = []
      versionEditModalVisible.value = false;
      setTimeout(()=>{
        editableData.splice(0, editableData.length)
        editableStockData.splice(0, editableData.length)
        fetchProductDetail(formState.id)
      }, 500)

    };

    const saveProduct = () => {
      formProductGroupRef.value.validate().then(() => {
        // formState.tags_id = formState.tags_ids
        //     ?.map((tag_id) => "_" + tag_id)
        //     .join(",");
        if (fileList.value.length > 0) {
          formState.image_url = fileList.value.map(f=>f.url).join(',');
        }
        // formState.price = formState.versions
        //     .map((version) => version.unit_price)
        //     .join(",");
        if (formState.id) {
          updateProductGroup();
        } else {
          createProductGroup();
        }
      });
    };


    const confirmDeleteVersion = async (product: Product, index: number) => {
      if(formState.id){
        const res = await APIs.init().deleteProduct(product)
        if(res.data?.status === 200){
          formState.versions.splice(index, 1)
          versions.value.splice(index, 1)
        }
      }else{
        versions.value.splice(index, 1)
      }


    };
    const formatMoney = (money: string) => {
      return MixUtils.methods.formatMoney(money);
    };

    const attributeChange = (value: string) => {
      console.log('attributeChange', value)
      let temp = formState.group_name
      attributeData.value.map(a=>{
        if(a.value)
        temp += "/ "+a.value
      })
      formStateVersion.product_name = temp
    };

    const changeAttribute = (index: number, attribute: string[]) =>{
      listAttribute.value[index].tags = attribute;
      console.log(listAttribute.value);
      genVersion()
    }

    const productGroupChange = () =>{
      genVersion()
    }

    onMounted(()=>{
      genVersion()
    })

    const data = ref( '<p>Hello world!</p>' );

    const disabled = ref( false );

    const isTwoWayDataBindingDisabled = ref( false );

    const config = reactive( {
      toolbar: [ 'heading', '|', 'bold', 'italic' ]
    } );

    // Methods
    const  setEditorData = () => {
      // @ts-ignore
      data.value = window.editor.getData();
    }

    const toggleTwoWayBinding = ()=> {
      isTwoWayDataBindingDisabled.value = !isTwoWayDataBindingDisabled.value;
    }

    const toggleEditorDisabled = ()=> {
      disabled.value = !disabled.value;
    }

    const onReady = ( editor: ClassicEditor ) =>{
      // @ts-ignore
      window.editor = editor;

      console.log( 'Editor is ready.', { editor } );
    }

    const onFocus = ( event: EventInfo, editor: ClassicEditor )=> {
      console.log( 'Editor focused.', { event, editor } );
    }

    const onBlur = ( event: EventInfo, editor: ClassicEditor )=> {
      console.log( 'Editor blurred.', { event, editor } );
    }

    const onInput = ( data: string, event: EventInfo, editor: ClassicEditor ) =>{
      console.log( 'Editor data input.', { event, editor, data } );
    }

    const onDestroy = ( editor: ClassicEditor ) => {
      console.log( 'Editor destroyed.', { editor } );
    }

    return {
      data,
      config,
      setEditorData,
      toggleEditorDisabled,
      toggleTwoWayBinding,
      onReady,
      onFocus,
      onBlur,
      onInput,
      onDestroy,
      labelCol: {style: { width: '150px' }},
      wrapperCol: {span: 14},
      addStock,
      getProductTags,
      getProductCategories,
      getStocks,
      fetchProductDetail,
      formState,
      attributeData,
      attributeColumns,
      stocksColumns,
      products,
      addAttribute,
      editAttribute,
      saveAttribute,
      editableData,
      cancelAttribute,
      deleteAttribute,
      stockData,
      editStock,
      saveStock,
      cancelStock,
      deleteStock,
      editableStockData,
      addVersion,
      editVersion,
      versionColumns,
      screenWidth,
      visible,
      // showModal,
      saveProduct,
      formStateVersion,
      imageSelectionRef,
      onImageSelect,
      showImageSelectionModal,
      fileList,
      fileListVersion,
      confirmDeleteVersion,
      rules,
      rulesVersion,
      formProductGroupRef,
      saveProductVersion,
      formProductVersionRef,
      formatMoney,
      getProductProviders,
      versionEditModalVisible,
      createCategories,
      formStateCategories,
      editVisible,
      handleAddCategory,
      createProductCategory,
      categoryRequest,
      dataCategory,
      handleCancel,
      attributeChange,
      listAttribute,
      changeAttribute,
      productGroupChange,
      saveVersion,
      versions
    };
  },
});
</script>

<style scoped lang="scss">
@media only screen and (min-width: 768px) {
  /* For desktop: */
  .product-edit-container {
    display: flex;
    width: 100%;
    flex-direction: row;

  .card-comon-info {
    flex: 0.45;
  }
}

}
@media only screen and (max-width: 500px) {
  .product-edit-container {
    display: flex;
    width: 100%;
    flex-direction: column;
  }
}

.product-edit-card {
  flex: 1;

.common-info-title {
  margin-left: 8px;
}

.parameter-icon {
  width: 16px;
  height: 16px;
}

}
.header-product-edit {
  display: flex;
  justify-content: space-between;
  margin-bottom: 12px;
  margin-top: 12px;
}

.header-product-buttons {
  display: flex;
  flex-direction: row;
  gap: 16px;
}

.button-add-version {
  /*margin-right: 12px;*/
}

.import-to-stocks-title {
  margin-top: 12px;
}

.product-new-version {
  margin-top: 12px;
}

.table-import-stock {
  margin-top: 12px;
}

.button-add-attribute {
  text-align: center;
  margin-top: 10px;
}

/* tile uploaded pictures */
.upload-list-inline :deep(.ant-upload-list-item) {
  float: left;
  width: 200px;
  margin-right: 8px;
}

.upload-list-inline :deep(.ant-upload-animate-enter) {
  animation-name: uploadAnimateInlineIn;
}

.upload-list-inline :deep(.ant-upload-animate-leave) {
  animation-name: uploadAnimateInlineOut;
}

.input-number {
  width: 100%;
}

.title-header {
  font-weight: bold;
  flex: 1;
}

.container-title {
  display: flex;
  flex-direction: row;
  padding: 16px 24px 16px 24px;
  background-color: white
}

.input-search {
  width: 250px;
  margin-right: 16px;
}

.inline-form-customer {
  display: inline-block;
  width: 440px;
}
.upload-image {
  margin-left: 12px
}
</style>
