import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"
import _imports_0 from '@/assets/icons/zalo-flat.svg'


const _withScopeId = n => (_pushScopeId("data-v-2bb42036"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "item-trigger-table-small" }
const _hoisted_2 = { key: 1 }
const _hoisted_3 = { class: "note-first-msg" }
const _hoisted_4 = {
  key: 0,
  src: _imports_0,
  class: "icon-zns"
}
const _hoisted_5 = { key: 0 }
const _hoisted_6 = { key: 1 }
const _hoisted_7 = { key: 2 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_button = _resolveComponent("a-button")!
  const _component_a_tag = _resolveComponent("a-tag")!
  const _component_a_table = _resolveComponent("a-table")!
  const _component_facebook_outlined = _resolveComponent("facebook-outlined")!
  const _component_GoogleOutlined = _resolveComponent("GoogleOutlined")!
  const _component_a_input = _resolveComponent("a-input")!
  const _component_a_date_picker = _resolveComponent("a-date-picker")!
  const _component_a_form_item = _resolveComponent("a-form-item")!
  const _component_vue_editor = _resolveComponent("vue-editor")!
  const _component_a_textarea = _resolveComponent("a-textarea")!
  const _component_a_form = _resolveComponent("a-form")!
  const _component_a_modal = _resolveComponent("a-modal")!

  return (_openBlock(), _createBlock(_component_a_modal, {
    visible: _ctx.visible,
    "onUpdate:visible": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.visible) = $event)),
    title: _ctx.$t('QUICK_MESSAGE'),
    width: "50%"
  }, {
    footer: _withCtx(() => [
      _createVNode(_component_a_button, {
        key: "back",
        onClick: _ctx.returnSelect
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.$t('RETURN')), 1)
        ]),
        _: 1
      }, 8, ["onClick"]),
      _createVNode(_component_a_button, {
        key: "submit",
        type: "primary",
        loading: _ctx.buttonLoading,
        disabled: !(_ctx.selectedRowKeys&&_ctx.selectedRowKeys.length>0),
        onClick: _ctx.handleOk
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.$t(_ctx.buttonSubmitType)), 1)
        ]),
        _: 1
      }, 8, ["loading", "disabled", "onClick"])
    ]),
    default: _withCtx(() => [
      (_ctx.buttonSubmitType==='NEXT')
        ? (_openBlock(), _createBlock(_component_a_table, {
            key: 0,
            columns: _ctx.columns,
            "data-source": _ctx.listNull=== undefined ? [] :_ctx.list,
            rowSelection: {type:'radio',selectedRowKeys:_ctx.selectedRowKeys,onChange:_ctx.onRowSelect},
            pagination: false,
            loading: _ctx.listLoading,
            rowKey: record => record.id
          }, {
            name: _withCtx(({ record }) => [
              _createVNode(_component_a_button, {
                type: "text",
                class: "table-row-name",
                onClick: ($event: any) => (_ctx.onRowSelect([record.id]))
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(record.name), 1)
                ]),
                _: 2
              }, 1032, ["onClick"])
            ]),
            type: _withCtx(({ text }) => [
              _createElementVNode("span", _hoisted_1, _toDisplayString(_ctx.$t(text)), 1)
            ]),
            creator: _withCtx(({ text }) => [
              _createElementVNode("span", null, _toDisplayString(_ctx.getEmployeeName(text)), 1)
            ]),
            tags: _withCtx(({ record }) => [
              _createElementVNode("span", null, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(record.tags, (tag) => {
                  return (_openBlock(), _createBlock(_component_a_tag, {
                    key: tag.id+'_'+tag.name,
                    color: tag.color
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(tag.name), 1)
                    ]),
                    _: 2
                  }, 1032, ["color"]))
                }), 128))
              ])
            ]),
            _: 1
          }, 8, ["columns", "data-source", "rowSelection", "loading", "rowKey"]))
        : (_ctx.buttonSubmitType==='OK')
          ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
              _createElementVNode("div", _hoisted_3, [
                _createElementVNode("span", null, [
                  (_ctx.flowContext&&_ctx.flowContext.flow_type===2||_ctx.flowContext&&_ctx.flowContext.flow_type===4)
                    ? (_openBlock(), _createElementBlock("img", _hoisted_4))
                    : (_ctx.flowContext&&_ctx.flowContext.flow_type===1||_ctx.flowContext&&_ctx.flowContext.flow_type===3)
                      ? (_openBlock(), _createBlock(_component_facebook_outlined, { key: 1 }))
                      : (_ctx.flowContext&&_ctx.flowContext.flow_type===5)
                        ? (_openBlock(), _createBlock(_component_GoogleOutlined, {
                            key: 2,
                            class: "icon-zns"
                          }))
                        : _createCommentVNode("", true),
                  _createTextVNode(" " + _toDisplayString(_ctx.$t('QUICK_MESSAGE_FIRST_MESSAGE_NOTE')), 1)
                ])
              ]),
              _createVNode(_component_a_form, {
                "label-col": {span:6},
                "wrapper-col": {span:16}
              }, {
                default: _withCtx(() => [
                  (_ctx.flowContext.flow_type===2)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.flowContext.zns_attributes, (item) => {
                          return (_openBlock(), _createBlock(_component_a_form_item, {
                            name: item.name,
                            key: item.name,
                            label: _ctx.$t(item.name)
                          }, {
                            default: _withCtx(() => [
                              (item.type !== 'DATE')
                                ? (_openBlock(), _createBlock(_component_a_input, {
                                    key: 0,
                                    value: item.value,
                                    "onUpdate:value": ($event: any) => ((item.value) = $event),
                                    maxlength: item.max_length,
                                    disabled: item.name==='customer_name'
                                  }, null, 8, ["value", "onUpdate:value", "maxlength", "disabled"]))
                                : (item.type ==='DATE')
                                  ? (_openBlock(), _createBlock(_component_a_date_picker, {
                                      key: 1,
                                      valueFormat: 'DD/MM/YYYY',
                                      onChange: _ctx.onChangeDatePicker,
                                      maxlength: item.max_length,
                                      format: "DD/MM/YYYY",
                                      value: item.value,
                                      "onUpdate:value": ($event: any) => ((item.value) = $event),
                                      style: {"min-width":"100%"}
                                    }, null, 8, ["onChange", "maxlength", "value", "onUpdate:value"]))
                                  : _createCommentVNode("", true)
                            ]),
                            _: 2
                          }, 1032, ["name", "label"]))
                        }), 128))
                      ]))
                    : (_ctx.flowContext.flow_type===5)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                          _createVNode(_component_a_form_item, {
                            label: _ctx.$t('MAIL_CONTENT')
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_vue_editor, {
                                id: "editor",
                                modelValue: _ctx.flowContext.message,
                                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.flowContext.message) = $event)),
                                placeholder: _ctx.$t('EMAIL_PLACEHOLDER_EDITOR')
                              }, null, 8, ["modelValue", "placeholder"])
                            ]),
                            _: 1
                          }, 8, ["label"]),
                          _createVNode(_component_a_form_item, {
                            ref: "name",
                            label: _ctx.$t('FLOW_FOOTER_TEXT'),
                            name: "name"
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_a_input, {
                                placeholder: _ctx.$t('FLOW_FOOTER_TEXT'),
                                value: _ctx.flowContext.footer_text,
                                "onUpdate:value": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.flowContext.footer_text) = $event))
                              }, null, 8, ["placeholder", "value"])
                            ]),
                            _: 1
                          }, 8, ["label"])
                        ]))
                      : (_openBlock(), _createElementBlock("div", _hoisted_7, [
                          _createVNode(_component_a_form_item, {
                            label: _ctx.$t('FIRST_TEXT')
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_a_textarea, {
                                placeholder: "Aaa...",
                                "auto-size": { minRows: 2, maxRows: 5 },
                                value: _ctx.formState.text,
                                "onUpdate:value": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.formState.text) = $event))
                              }, null, 8, ["value"])
                            ]),
                            _: 1
                          }, 8, ["label"])
                        ]))
                ]),
                _: 1
              })
            ]))
          : _createCommentVNode("", true)
    ]),
    _: 1
  }, 8, ["visible", "title"]))
}