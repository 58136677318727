import { Metadata } from "@/APIs/response/S_HttpResponse";
import {Orders} from "@/models/Orders/Orders";

export type OrdersData = {
    orders: Orders[] | undefined;
    metadata: Metadata | undefined
    loading: boolean
}


export type State = {
    orderData: OrdersData | undefined;
}

export const state: State = {
    orderData: { orders: [], metadata: {}, loading: false },
}
