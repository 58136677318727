import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "container-title" }
const _hoisted_2 = { class: "title-header" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_input = _resolveComponent("a-input")!
  const _component_a_form_item = _resolveComponent("a-form-item")!
  const _component_a_form = _resolveComponent("a-form")!
  const _component_a_input_password = _resolveComponent("a-input-password")!
  const _component_a_button = _resolveComponent("a-button")!
  const _component_a_row = _resolveComponent("a-row")!
  const _component_a_col = _resolveComponent("a-col")!
  const _component_a_card = _resolveComponent("a-card")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("h3", _hoisted_2, _toDisplayString(_ctx.$t(_ctx.$route.name)), 1)
    ]),
    _createVNode(_component_a_card, { style: {"margin":"16px"} }, {
      default: _withCtx(() => [
        _createVNode(_component_a_row, { justify: "center" }, {
          default: _withCtx(() => [
            _createVNode(_component_a_col, { span: 12 }, {
              default: _withCtx(() => [
                ($setup.changePassword === false)
                  ? (_openBlock(), _createBlock(_component_a_form, {
                      key: 0,
                      model: $setup.formState,
                      "label-col": { span: 8 },
                      "wrapper-col": { span: 16 },
                      labelAlign: "left"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_a_form_item, {
                          label: _ctx.$t('NAME')
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_a_input, {
                              value: $setup.formState.name,
                              "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => (($setup.formState.name) = $event))
                            }, null, 8, ["value"])
                          ]),
                          _: 1
                        }, 8, ["label"]),
                        _createVNode(_component_a_form_item, { label: "Email" }, {
                          default: _withCtx(() => [
                            _createVNode(_component_a_input, {
                              value: $setup.formState.email,
                              "onUpdate:value": _cache[1] || (_cache[1] = ($event: any) => (($setup.formState.email) = $event))
                            }, null, 8, ["value"])
                          ]),
                          _: 1
                        }),
                        _createVNode(_component_a_form_item, {
                          label: _ctx.$t('phone_number')
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_a_input, {
                              value: $setup.formState.mobile,
                              "onUpdate:value": _cache[2] || (_cache[2] = ($event: any) => (($setup.formState.mobile) = $event))
                            }, null, 8, ["value"])
                          ]),
                          _: 1
                        }, 8, ["label"])
                      ]),
                      _: 1
                    }, 8, ["model"]))
                  : (_openBlock(), _createBlock(_component_a_form, {
                      key: 1,
                      model: $setup.formState,
                      "label-col": { span: 8 },
                      "wrapper-col": { span: 16 },
                      labelAlign: "left"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_a_form_item, {
                          label: _ctx.$t('OLD_PASSWORD'),
                          name: "oldPassword"
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_a_input_password, {
                              value: $setup.formState.old_password,
                              "onUpdate:value": _cache[3] || (_cache[3] = ($event: any) => (($setup.formState.old_password) = $event))
                            }, null, 8, ["value"])
                          ]),
                          _: 1
                        }, 8, ["label"]),
                        _createVNode(_component_a_form_item, {
                          label: _ctx.$t('NEW_PASSWORD')
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_a_input_password, {
                              value: $setup.formState.new_password,
                              "onUpdate:value": _cache[4] || (_cache[4] = ($event: any) => (($setup.formState.new_password) = $event))
                            }, null, 8, ["value"])
                          ]),
                          _: 1
                        }, 8, ["label"]),
                        _createVNode(_component_a_form_item, {
                          label: _ctx.$t('ENTER_THE_NEW_PASSWORD')
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_a_input_password, {
                              value: $setup.formState.password,
                              "onUpdate:value": _cache[5] || (_cache[5] = ($event: any) => (($setup.formState.password) = $event))
                            }, null, 8, ["value"])
                          ]),
                          _: 1
                        }, 8, ["label"])
                      ]),
                      _: 1
                    }, 8, ["model"])),
                _createVNode(_component_a_row, { justify: "center" }, {
                  default: _withCtx(() => [
                    _createVNode(_component_a_button, {
                      onClick: $setup.update,
                      style: {"margin-right":"10px"},
                      type: "primary"
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.$t('SAVE')), 1)
                      ]),
                      _: 1
                    }, 8, ["onClick"]),
                    ($setup.changePassword === false)
                      ? (_openBlock(), _createBlock(_component_a_button, {
                          key: 0,
                          style: {"margin-right":"10px"},
                          onClick: _cache[6] || (_cache[6] = ($event: any) => ($setup.changePassword = true))
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_ctx.$t('CHANGE_PASSWORD')), 1)
                          ]),
                          _: 1
                        }))
                      : (_openBlock(), _createBlock(_component_a_button, {
                          key: 1,
                          onClick: _cache[7] || (_cache[7] = ($event: any) => ($setup.changePassword = false)),
                          style: {"margin-right":"10px"},
                          type: "primary"
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_ctx.$t('ACCOUNT')), 1)
                          ]),
                          _: 1
                        }))
                  ]),
                  _: 1
                })
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ]),
      _: 1
    })
  ], 64))
}