import {FlowData, State} from "@/store/modules/flow/states";
import {FlowsMutationTypes} from "@/store/modules/flow/mutation-types";
import {FlowContext, FlowContextData} from "@/models/Flows/FlowContext";
import {FlowConstants} from "@/models/Flows/FlowConstants";
import {DictionaryMutationTypes} from "@/store/modules/dictionary/mutation-types";
import {Province} from "@/models/Dictionary/Dictionary";
import {Flow, ZnsTemp} from "@/models/Flows/Flow";

export type Mutations<S = State> = {
    [FlowsMutationTypes.SET_FLOWS](state: S, payload: FlowData | undefined): void;
    [FlowsMutationTypes.SET_FLOW_CONTEXT](state: S, payload: FlowContextData | undefined): void;
    [FlowsMutationTypes.SET_FlowConstants](state: S, payload: FlowConstants | undefined): void;
    [FlowsMutationTypes.UPDATE_FlowContext](state: S, payload: FlowContext): void;
    [FlowsMutationTypes.SET_ZNS_TEMP](state: S, payload: ZnsTemp[]|undefined): void;
    [FlowsMutationTypes.SET_FLOWS_2](state: S, payload: Flow[]): void;
    [FlowsMutationTypes.UPSERT_FLOW_CONTEXT_LIST](state: S, payload: FlowContextData|undefined): void;
}

export const mutations: Mutations = {
    [FlowsMutationTypes.SET_FLOWS](state: State, payload: FlowData | undefined): void {
        state.flowData = payload
    },
    [FlowsMutationTypes.SET_FLOW_CONTEXT](state: State, payload: FlowContextData | undefined): void {
        state.flowContext = payload
    },
    [FlowsMutationTypes.SET_FlowConstants](state: State, payload: FlowConstants | undefined): void {
        state.flowConstants = payload
    },
    [FlowsMutationTypes.UPDATE_FlowContext](state: State, payload: FlowContext): void {
        // if (payload.id === state.flowContext?.flow_context?.id) {
        //     if (state.flowContext) {
        //         state.flowContext.flow_context = payload
        //     }
        // } else if (state.flowContext?.reference_flow_contexts) {
        //     for (let i = 0; i < state.flowContext.context.flow_contexts.length; i++) {
        //         if (payload.id === state.flowContext.context.flow_contexts[i].id) {
        //             state.flowContext.context.flow_contexts[i] = payload;
        //         }
        //     }
        // }
    },
    [FlowsMutationTypes.SET_ZNS_TEMP](state: State, payload: ZnsTemp[]|undefined): void {
        if(payload&&payload.length){
            state.znsTemp = payload.filter(x=>x.status==='ENABLE')
        }
    },
    [FlowsMutationTypes.SET_FLOWS_2](state: State, payload: Flow[]): void {
        state.flows = payload
    },
    [FlowsMutationTypes.UPSERT_FLOW_CONTEXT_LIST](state: State, payload: FlowContextData | undefined): void {
        if (payload&&payload.id){
            const existFlow=state.flows.find(x=>x.id===payload?.id)
            if (!existFlow){
                const flow:Flow={
                    id:payload.id,
                    name:payload.name?payload.name:'',
                    status:payload.status?payload.status:'',
                    tags:[],
                    creator:payload.creator?payload.creator:0,
                    description:'',
                    created_at:'',
                    shop_id:payload.shop_id?payload.shop_id:0,
                    updated_at:''
                }
                state.flows=[...state.flows,flow]
            }
        }

    },
}