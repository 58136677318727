import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "right-contain" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_notification_dropdown = _resolveComponent("notification-dropdown")!
  const _component_wallet_dropdown = _resolveComponent("wallet-dropdown")!
  const _component_avatar_dropdown = _resolveComponent("avatar-dropdown")!
  const _component_lang_dropdown = _resolveComponent("lang-dropdown")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_notification_dropdown),
    _createVNode(_component_wallet_dropdown),
    _createVNode(_component_avatar_dropdown),
    _createVNode(_component_lang_dropdown)
  ]))
}