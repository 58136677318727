<template>
  <div class="container-title">
    <h3 class="title-header">{{ $t($route.name) }}</h3>

  </div>
  <div class="container">
    <a-card style="border-radius: 10px;margin: auto;width: 95%">
      <a-row style="justify-content: flex-end">
        <a-button @click="visible = true" type="primary ">
          {{$t('ADD')}}
        </a-button>
      </a-row>

      <a-table
          :dataSource="extraColumn.extraColumn"
          :columns="columnConfig"
          class="flow-table">
        <template v-for="col in ['name', 'identified_name']" #[col]="{ text, record }" :key="col">
          <div>
            <a-input
                v-if="editableData[record.id]"
                v-model:value="editableData[record.id][col]"
                style="margin: -5px 0"
            />
            <template v-else>
              {{ text }}
            </template>
          </div>
        </template>
        <template #field_type="{record}">
          <a-typography-text v-if="record.field_type === 'STRING'">{{$t('TEXT')}}</a-typography-text>
          <a-typography-text v-if="record.field_type === 'INT'">{{$t('number')}}</a-typography-text>
          <a-typography-text v-if="record.field_type === 'DATE'">{{$t('DATE')}}</a-typography-text>
          <a-typography-text v-if="record.field_type === 'CURRENCY'">{{$t('CURRENCY')}}</a-typography-text>

        </template>
        <template #action="{ record }">
<!--          v-if="record.identified_name !== 'points' && record.identified_name !== 'rank'"-->
          <div  v-if="record.identified_name !== 'points' && record.identified_name !== 'rank'"  class="editable-row-operations">
        <span v-if="editableData[record.id]">
          <a @click="saveConfig(record.name,record.id)">Save</a>
            <a @click="cancel(record.id)" style="margin-left: 8px;">Cancel</a>
        </span>
            <span v-else>
          <a v-on:click="edit(record.id)" >Edit</a>
             <a-popconfirm
                 title="Sure to delete?"
                 @confirm="remove(record)"
             >
        <a style="margin-left: 8px;">Delete</a>
      </a-popconfirm>
        </span>
          </div>
        </template>
      </a-table>
    </a-card>
  </div>
  <a-modal v-model:visible="visible" :title="$t('ADD')" @ok="addConfig">
    <a-form ref="formRef" :model="config"  :rules="rules"  >
      <a-form-item name="name"
                   :label="$t('NAME')">
        <a-input   @change="changeName" v-model:value="config.name"/>
      </a-form-item >
      <a-form-item :label="$t('identified_name')">
        <a-input v-model:value="config.identified_name"/>
      </a-form-item >
      <a-form-item name="type"  :label="$t('FIELD_TYPE')">
        <a-radio-group  v-model:value="config.field_type">
          <a-radio value="STRING">{{$t('TEXT')}}</a-radio>
          <a-radio value="INT">{{$t('number')}}</a-radio>
          <a-radio value="DATE">{{$t('DATE')}}</a-radio>
          <a-radio value="CURRENCY">{{$t('CURRENCY')}}</a-radio>
        </a-radio-group>
      </a-form-item>
    </a-form>

  </a-modal>

</template>
<script lang="ts">
import {computed, onMounted, ref} from "vue";
import {useStore} from "vuex";
import {useI18n} from "vue-i18n";
import APIs from "@/APIs/APIs";
import {message} from "ant-design-vue";
import {CustomersActionTypes} from "@/store/modules/customer/action-types";
import {MixUtils} from "@/utils/Utils";
import useCustomerStore from "../../store/Customers";

export default {
  setup(){
    const store= useStore()
    const i18n = useI18n()
    const extraColumn = useCustomerStore()
    // const listConfig = computed(()=> useCustomerStore.getState().extraColumn )
    const getCurrentShop = computed(() => store.getters.getCurrentShop)
    const editableData = ref({})
    const visible = ref(false)
    const formRef = ref();
    const config = ref({
      id:0,
      name:'',
      field_type: 'STRING',
      identified_name:'',
      customer_column_value: '',
    })
    // @ts-ignore
    const rules = {
      name: [
        {required: true, message: i18n.t('VALIDATE_DISPLAY_NAME'), trigger: 'blur'},
      ],
    }


    const columnConfig = [
      {
        title: i18n.t('NAME'),
        dataIndex: 'name',
        key: 'name',
        slots: {customRender: 'name'},
      },
      {
        title: i18n.t('identified_name'),
        dataIndex: 'identified_name',
        key: 'identified_name',
        slots: {customRender: 'identified_name'},
      },
      {
        title: i18n.t('FIELD_TYPE'),
        dataIndex: 'field_type',
        key: 'field_type',
        slots: {customRender: 'field_type'},
      },
      {
        title: i18n.t('TAG_ACTION'),
        key: 'action',
        slots: {customRender: 'action'},
      },
    ]
    const saveConfig = async (key:any,id:any) =>  {
      let copyConfig = [...useCustomerStore.getState().extraColumn]
      // @ts-ignore
      Object.assign(copyConfig.filter(item => id === item.id)[0], editableData.value[id]);
      const config =copyConfig.find(x=>x.id===id)
      if (config){
        const request = {shop_id:getCurrentShop.value.id,name: config.name,id: config.id,identified_name:config.identified_name }
        const res = await APIs.init().UpdateCustomerConfig(request)
        if(res.status === 200){
          message.success(i18n.t('SUCCESS'))
          fetchCustomerConfig()
        }else {
          message.error('error')
        }
      }
      // @ts-ignore
      delete editableData.value[id];
    }
    const edit = (key:any) => {
// @ts-ignore
      editableData.value[key] = {...useCustomerStore.getState().extraColumn.filter((item) => key === item.id)[0]}

    }
    const cancel = (key:any) =>  {
      // @ts-ignore
      delete editableData.value[key];
    }
    const remove = (key:any) =>  {
      // @ts-ignore
      APIs.init().DeleteCustomerConfig(key).then((res) => {
        // @ts-ignore
        if(res.data.status === 200){
          message.success(i18n.t('delete_success'))
          fetchCustomerConfig()
        }

      })
    }
    const fetchCustomerConfig = () => {
      useCustomerStore.getState().GetExtraColumn({shop_id:getCurrentShop.value.id})
      // store.dispatch(CustomersActionTypes.FETCH_CUSTOMER_CONFIG,{shop_id:getCurrentShop.value.id})
    }
    const addConfig = () => {
      formRef.value.validate().then(() => {
        createCustomerConfig()
        config.value = {
          id:0,
          name:'',
          field_type: 'STRING',
          customer_column_value: '',
          identified_name: ''
        }


      })
    }
    const createCustomerConfig   =async () => {
      // @ts-ignore
      if(config.value.field_type === 'INT' && config.value.field_type === 'CURRENCY'){
        config.value.customer_column_value = config.value.customer_column_value + ''
      }
      const request = {shop_id:getCurrentShop.value.id ,customer_column_value: config.value.customer_column_value,name: config.value.name,field_type: config.value.field_type ,id: config.value.id,identified_name: config.value.identified_name}
      const res = await APIs.init().addCustomerConfig(request)
      if(res.status === 200){
        message.success(i18n.t('SUCCESS'))
        fetchCustomerConfig()
        visible.value=false
        // showCustomerConfigValue.value = 0
        // onSubmit()
      }else {
        message.error('error')
      }
    }
    const changeName =(e:any) =>{
      config.value.identified_name = MixUtils.methods.removeVietnamese2(e.target.value)
    }

    onMounted(()=>{
      fetchCustomerConfig()
    })
    return{
      saveConfig,
      edit,
      cancel,
      visible,
      formRef,
      addConfig,
      remove,
      // listConfig,
      columnConfig,
      editableData,
      config,
      rules,
      changeName,
      extraColumn
    }
  }
}
</script>
