import {Tag, TreeTag} from "@/models/Tags/Tags";
import {Metadata} from "@/APIs/response/S_HttpResponse";

export type State = {
    flowTags: Tag[] | undefined;
    customerTags: Tag[] | undefined;
    triggerTags: Tag[] | undefined;
    viralTags: Tag[] | undefined;
    autoInboxTags: Tag[] | undefined;
    productTags: Tag[] | undefined;
    rootTagMetadata: Metadata;
    treeTags: TreeTag[];
}

export const state: State = {
    flowTags: [],
    customerTags: [],
    triggerTags: [],
    viralTags: [],
    autoInboxTags: [],
    productTags: [],
    rootTagMetadata:{},
    treeTags:[]
}
