<template>
  <a-popover trigger="click" placement="right" v-model:visible="popoverVisible">
    <template #title>
      <div style="display: flex">
         <span class="header-postback-pop">
        {{ $t('FLOW_BUTTON_SETTING') }}
      </span>
        <a-button type="text" @click="()=>popoverVisible=false">
          <close-outlined/>
        </a-button>
      </div>

    </template>
    <template #content>
      <a-form
          style="max-width: 300px"
          class="form-postback"
          ref="formRefPostBack"
          :rules="rules"
          :model="formState"
          layout="vertical"
      >
        <a-form-item :label="$t('FLOW_FACEBOOK_BUTTON_NAME')">
          <a-input :placeholder="$t('FLOW_POSTBACK_BUTTON_NAME_CONTENT')" :value="$t(formState.title)" name="title" disabled/>
        </a-form-item>
        <a-form-item :label="$t('FLOW_FACEBOOK_BUTTON_TYPE')" name="type">
          <a-select v-model:value="formState.type" disabled>
            <a-select-option v-for="item in facebookButtonTypes" :key="item" :value="item">{{ item }}</a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item :label="$t('FLOW_FACEBOOK_BUTTON_WEB_URL_LABEL')"
                     v-if="formState.type===facebookButtonTypes[1]">
          <a-input placeholder="https://shopf1.net" v-model:value="formState.url" name="url"/>
        </a-form-item>
        <div v-else>
          <a-form-item :label="$t('FLOW_FACEBOOK_BUTTON_POST_BACK_MESSAGE_CONTENT_LABEL')">
            <a-textarea @click="forcusMessagetext" name="reply_message"
                        :placeholder="$t('FLOW_FACEBOOK_BUTTON_POST_BACK_MESSAGE_CONTENT_PLACEHOLDER')" :rows="3"
                        v-model:value="formState.reply_message"/>
            <a-tag @click="messageFullName" style="cursor: pointer">##full_name##</a-tag>
          </a-form-item>
          <a-form-item>
            <span class="ant-upload-picture-card-wrapper">
              <div class="ant-upload-list ant-upload-list-picture-card" v-for="item in fileList" :key="item">
                <div class="ant-upload-list-picture-card-container">
                  <span>
                    <div
                        class="ant-upload-list-item ant-upload-list-item-done ant-upload-list-item-list-type-picture-card">
                      <div class="ant-upload-list-item-info">
                        <span>
                          <a class="ant-upload-list-item-thumbnail"
                             :href="item.url"
                             target="_blank" rel="noopener noreferrer">
                            <img :src="item.url"
                                 alt="image.png"
                                 class="ant-upload-list-item-image">
                          </a>
                        </span>
                      </div>
                      <span class="ant-upload-list-item-actions">
                        <a :href="item.url"
                           target="_blank"
                           rel="noopener noreferrer" title="Preview file">
                          <eye-outlined/>
                        </a>
                        <delete-outlined @click="removeImage(item)"/>
                      </span>
                    </div>
                  </span>
                </div>
              </div>
              <div class="ant-upload ant-upload-select ant-upload-select-picture-card" @click="showImageSelectionModal">
                  <span role="button" tabindex="0" class="ant-upload">
                    <div>
                      <plus-outlined/>
                      <div class="ant-upload-text">{{ $t('UPLOAD') }}</div>
                    </div>
                  </span>
              </div>
            </span>
          </a-form-item>

          <a-form-item>
            <a-popover :title="$t('FLOW_ADD_CMR_ACTION')">
              <template #content>
                <!--              <a-select-->
                <!--                  v-model:value="selectedCRM_Action"-->
                <!--                  :placeholder="$t('FLOW_ADD_CMR_ACTION')"-->
                <!--                  @change="selectCRM_ActionChange"-->
                <!--              >-->
                <!--                <a-select-option v-for="item in crmActions" :value="item" :key="item">{{ $t(item) }}</a-select-option>-->
                <!--              </a-select>-->
                <a-tag style="display: block;cursor: pointer;margin-bottom: 10px"  @click="selectCRM_ActionChange(i)" v-for="i in crmActions" :key="i">{{$t(i)}}</a-tag>
              </template>
              <a-button  >{{$t('FLOW_CMR_ACTION')}}</a-button>
            </a-popover>
          </a-form-item>
          <a-form-item  name="action_crm">
            <div class="action-crm" v-for="(item,index) in formState.flow_actions_context.flow_crm_actions" :key="'index_'+index">
              <div class="crm-action-title-container">
                <div class="title-selected-crm-action">
                  {{ $t(item.type) }}
                </div>
              </div>
              <a-row style="align-items: center">
                <a-col :span="20">
                  <a-tree-select
                      v-if="item.type==='add_tag'||item.type==='remove_tag'"
                      show-search
                      style="width: 100%"
                      v-model:value="item.tagArr"
                      :filterTreeNode="false"
                      @search="searchTags"
                      :notFoundContent="null"
                      :dropdown-style="{ maxHeight: '300', overflow: 'auto' }"
                      allow-clear
                      multiple
                      :placeholder="$t('TAG_SELECT')"
                  >
                    <a-tree-select-node v-for="item in treeTags" :key="item.id" v-bind:value="item.id">
                      <template #title>
                        <a-tag :color="item.color">
                          {{ item.name }}
                        </a-tag>
                      </template>
                      <a-tree-select-node v-for="child in item.children" :key="child.id" v-bind:value="child.id">
                        <template #title>
                          <a-tag :color="child.color">
                            {{ child.name }}
                          </a-tag>
                        </template>
                      </a-tree-select-node>

                    </a-tree-select-node>
                  </a-tree-select>
                  <a-tag v-if="item.type === 'unread_conversation' ">{{$t('unread_conversation')}}</a-tag>

                  <a-select
                      v-else-if=" item.type === 'update_last_care'"
                      style="width: 100%"
                      allowClear
                      placeholder="Please select"
                      v-model:value="item.last_care"
                      @change="handleCare"
                      @blur="handleCare"
                  >
                    <a-select-option
                        v-for="item in customerCares"
                        :key="item.id"
                        v-bind:value="item.id"

                    >
                      <a-tag :color="item.color">
                        {{ item.name }}
                      </a-tag>
                    </a-select-option>

                  </a-select>
                  <a-tree-select
                      v-else
                      show-search
                      style="width: 100%"
                      v-model:value="item.coverstion_tag"
                      :filterTreeNode="false"
                      @search="searchTags"
                      :notFoundContent="null"
                      :dropdown-style="{ maxHeight: '300', overflow: 'auto' }"
                      allow-clear
                      multiple
                      :placeholder="$t('TAG_SELECT')"
                  >
                    <a-tree-select-node disabled  v-for="item in converstionTag" :key="item.page_id" v-bind:value="item.page_id" >
                      <template #title>
                        <a-tag :color="item.color">
                          <img
                              v-if="item.page_type === 2"
                              draggable="false"
                              src="@/assets/icons/zalo-seeklogo.com.svg"
                              style="width: 20px;margin-right: 20px"
                          />
                          <img
                              v-else-if="item.page_type === 1"
                              draggable="false"
                              src="@/assets/icons/facebook-svgrepo-com.svg"
                              style="width: 20px;margin-right: 20px"
                          />
                          <img
                              v-else-if="item.page_type === 3"
                              draggable="false"
                              src="@/assets/icons/instagram-svgrepo-com.svg"
                              style="width: 20px;margin-right: 20px"
                          />
                          <img
                              v-else-if="item.page_type === 4"
                              draggable="false"
                              src="@/assets/icons/line-svgrepo-com.svg"
                              style="width: 20px;margin-right: 20px"
                          />
                          {{ item.page_name }}
                        </a-tag>
                      </template>
                      <a-tree-select-node v-for="treeData in item.tags" :key="item.page_id + '_'+treeData.tag_id" v-bind:value="item.page_id + '_'+treeData.tag_id">
                        <template #title>
                          <a-tag :color="treeData.tag_color">
                            {{ treeData.tag_name }}
                          </a-tag>
                        </template>
                      </a-tree-select-node>

                    </a-tree-select-node>
                  </a-tree-select>
                </a-col>
                <a-col :span="1"></a-col>
                <a-col :span="3">
                  <a-tag color="#f50" class="crm-action-deletion" @click="deleteCRM_Action(item)">{{ $t('DELETE') }}</a-tag>

                </a-col>
              </a-row>

            </div>
            <!--          <a-select-->
            <!--              v-model:value="selectedCRM_Action"-->
            <!--              :placeholder="$t('FLOW_ADD_CMR_ACTION')"-->
            <!--              @change="selectCRM_ActionChange"-->
            <!--          >-->
            <!--            <a-select-option v-for="item in crmActions" :value="item" :key="item">{{ $t(item) }}</a-select-option>-->
            <!--          </a-select>-->
          </a-form-item>
          <a-form-item v-if="formState.flow_actions_context.type !== '0' || !formState.flow_actions_context.usage_id" :label="$t('SELECT_AVAILABLE_MESSAGE')" name="root_flow_id">

            <a-select
                allowClear
                v-model:value="formState.flow_actions_context.usage_id"
                :placeholder="$t('SELECT_AVAILABLE_MESSAGE')"
                @change="changeFlowAvailable"
                :options="getFlowNextActions()"
            >
            </a-select>
          </a-form-item>
          <a-form-item v-if="formState.flow_actions_context.type === '0' || !formState.flow_actions_context.usage_id"  :label="$t('SELECT_AVAILABLE_FLOW')" name="usage_id">

            <a-select
                v-model:value="formState.flow_actions_context.usage_id"
                :placeholder="$t('SELECT_AVAILABLE_FLOW')"
                @change="changeFlowAvailable2"
                allowClear

            >
              <a-select-option v-for="item in getFlowData"  :key="item.id"
                               v-bind:value="item.id">
                {{item.name}}

              </a-select-option>
            </a-select>
          </a-form-item>
          <a-form-item v-if="nextFlowContext&&nextFlowContext.name" :label="$t('FLOW_NEXT_CONTENT')">
            <div class="right-button-content">
              <a-button type="dashed" class="selected-flow-action" @click="changeCurrentFlowContext">
                <facebook-outlined style="font-size: 16px"/>
                <span>
              {{ nextFlowContext.name }}
            </span>
              </a-button>
              <a-tag color="#f50" style="cursor: pointer" @click="deleteNextFlowAction">
                <delete-outlined/>
              </a-tag>
            </div>
          </a-form-item>
<!--          <div v-else>-->
<!--            <a-form-item v-if="!isNextFlow">-->
<!--              <a-button type="dashed" block @click="isNextFlow=true">{{ $t('FLOW_NEXT_CONTENT') }}</a-button>-->
<!--            </a-form-item>-->
<!--            <div v-else>-->
<!--              <a-divider/>-->
<!--              &lt;!&ndash;            <a-form-item :label="$t('FLOW_SELECT_AVAILABLE')">&ndash;&gt;-->
<!--              &lt;!&ndash;              <a-select&ndash;&gt;-->
<!--              &lt;!&ndash;                  :placeholder="$t('FLOW_CONTEXT_PLACEHOLDER')"&ndash;&gt;-->
<!--              &lt;!&ndash;              >&ndash;&gt;-->
<!--              &lt;!&ndash;                <a-select-option value="jack">Jack</a-select-option>&ndash;&gt;-->
<!--              &lt;!&ndash;                <a-select-option value="lucy">Lucy</a-select-option>&ndash;&gt;-->
<!--              &lt;!&ndash;              </a-select>&ndash;&gt;-->
<!--              &lt;!&ndash;            </a-form-item>&ndash;&gt;-->
<!--              <a-form-item>-->
<!--                <a-button type="dashed" block @click="newFacebookFlow(1)">-->
<!--                  <facebook-outlined style="font-size: 16px"/>-->
<!--                  {{ $t('FLOW_NEW_FACEBOOK') }}-->
<!--                </a-button>-->
<!--              </a-form-item>-->
<!--              <a-form-item>-->
<!--                <a-button type="dashed" block @click="newFacebookFlow(2)">-->
<!--                  <img src="@/assets/icons/zalo-flat.svg" class="anticon" style="width: 16px;height: 16px;"/>-->
<!--                  {{ $t('FLOW_NEW_ZALO') }}-->
<!--                </a-button>-->
<!--              </a-form-item>-->
<!--            </div>-->
<!--          </div>-->
<!--          <a-tree-select-->
<!--              v-else-->
<!--              show-search-->
<!--              style="width: 100%"-->
<!--              v-model:value="item.coverstion_tag"-->
<!--              :filterTreeNode="false"-->
<!--              @search="searchTags"-->
<!--              :notFoundContent="null"-->
<!--              :dropdown-style="{ maxHeight: '300', overflow: 'auto' }"-->
<!--              allow-clear-->
<!--              multiple-->
<!--              :placeholder="$t('TAG_SELECT')"-->
<!--          >-->
<!--            <a-tree-select-node v-for="item in converstionTag" :key="item.page_id" v-bind:value="item.page_id" >-->
<!--              <template #title>-->
<!--                <a-tag :color="item.color">-->
<!--                  <img-->
<!--                      v-if="item.page_type === 2"-->
<!--                      draggable="false"-->
<!--                      src="@/assets/icons/zalo-seeklogo.com.svg"-->
<!--                      style="width: 20px;margin-right: 20px"-->
<!--                  />-->
<!--                  <img-->
<!--                      v-else-if="item.page_type === 1"-->
<!--                      draggable="false"-->
<!--                      src="@/assets/icons/facebook-svgrepo-com.svg"-->
<!--                      style="width: 20px;margin-right: 20px"-->
<!--                  />-->
<!--                  <img-->
<!--                      v-else-if="item.page_type === 3"-->
<!--                      draggable="false"-->
<!--                      src="@/assets/icons/instagram-svgrepo-com.svg"-->
<!--                      style="width: 20px;margin-right: 20px"-->
<!--                  />-->
<!--                  <img-->
<!--                      v-else-if="item.page_type === 4"-->
<!--                      draggable="false"-->
<!--                      src="@/assets/icons/line-svgrepo-com.svg"-->
<!--                      style="width: 20px;margin-right: 20px"-->
<!--                  />-->
<!--                  {{ item.page_name }}-->
<!--                </a-tag>-->
<!--              </template>-->
<!--              <a-tree-select-node v-for="child in item.tags" :key="child.tag_id" v-bind:value="child.tag_id">-->
<!--                <template #title>-->
<!--                  <a-tag :color="child.tag_color">-->
<!--                    {{ child.tag_name }}-->
<!--                  </a-tag>-->
<!--                </template>-->
<!--              </a-tree-select-node>-->

<!--            </a-tree-select-node>-->
<!--          </a-tree-select>-->
        </div>

        <a-divider/>
        <a-form-item>
          <div class="footer-postback-init">
            <a-button type="primary" @click="onSubmit(true)">
              <check-outlined/>
              {{ $t('FLOW_COMPLETE') }}
            </a-button>
          </div>
        </a-form-item>
      </a-form>
    </template>
    <a-button class="button-post-back" @click="()=>popoverVisible=true">{{ $t(formState.title) }}</a-button>
  </a-popover>
  <image-selection-modal ref="imageSelectionRef" :onSelect="onImageSelect"/>
</template>

<script lang="ts">
import {
  CheckOutlined,
  PlusOutlined,
  FacebookOutlined,
  DeleteOutlined,
  CloseOutlined,
  EyeOutlined
} from "@ant-design/icons-vue"
import {computed, defineComponent, onMounted, reactive, ref, UnwrapRef, watch} from "vue";
import {FlowContext, FlowCRM_Action} from "@/models/Flows/FlowContext";
import {FormFlowInformation, FormPostbackContext} from "@/models/Flows/FlowForm";
import {ValidateErrorEntity} from "ant-design-vue/es/form/interface";
import {useStore} from "@/store";
import {S_EventBus} from "@/utils/EventBus";
import APIs from "@/APIs/APIs";
import {AWS_DO_Model} from "@/models/AWS_DO/AWS_DO";
import ImageSelectionModal from "@/components/Image/ImageSelectionModal.vue";
import {useI18n} from "vue-i18n";
import {MixUtils} from "@/utils/Utils";
import {PageConfigMutationTypes} from "@/store/modules/PageConfig/mutation-types";
import useTagStore from "../../store/Tags";


export default defineComponent({
  name: "facebook-onetime-notification",
  props: ['postbackContext', 'updatePostBackButton', 'addPostBackButton', 'flowContextData', 'deletePostBackButton'],
  components: {
    CheckOutlined,
    PlusOutlined,
    FacebookOutlined,
    DeleteOutlined,
    ImageSelectionModal,
    CloseOutlined,
    EyeOutlined
  },
  setup(props) {
    const store = useStore()
    const i18n = useI18n()
    const fileList = ref<AWS_DO_Model[]>([])
    const formRefPostBack = ref()
    const crmActions = ref()
    const customerTags = ref()
    const selectedCRM_Action = ref()
    const isNextFlow = ref(false)
    const popoverVisible = ref(false)
    const nextFlowContext = ref()
    const imageSelectionRef = ref()
    const treeTags = computed(() => store.getters.getTreeTags)
    const converstionTag = computed(() => useTagStore.getState().pageConverstionTag)
    const getCurrentPageId = computed(()=> store.getters.getCurrentPageId)
    const currentShop = computed(()=> store.getters.getCurrentShop)
    const getFlowData = computed(()=> store.getters.getFlowData.flows?.map((item:any)=>{
      item.id = item.id + ""
      return  item
    }))
    console.log(converstionTag)
    crmActions.value = store.getters.getFlowConstants?.flow_crm_action_types
    const rules = {
      title: [
        {required: true, message: 'Please input title button', trigger: 'blur'},
      ]
    };
    let model: FormPostbackContext = {}
    customerTags.value = useTagStore.getState().tagSales

    watch(() => props.postbackContext, () => {
      updatePostbackContext()
    })

    const updatePostbackContext = () => {
      if (!formState.flow_actions_context) {
        formState.flow_actions_context = {
          flow_crm_actions: []
        }
      }
      console.log(formState.flow_actions_context.flow_crm_actions,'1111')
      formState.flow_actions_context.flow_crm_actions = formState.flow_actions_context.flow_crm_actions?.map((item: FlowCRM_Action) => {
        if (item.tags  && (item.type === 'add_tag' || item.type === 'remove_tag' )) {
          item.tagArr = item.tags ? item.tags.split(",").map((str: string) => parseInt(str)) : []
        }else if( item.tags  && ( item.type === 'add_conversation_tag' || item.type === 'remove_conversation_tag')) {
          item.coverstion_tag = item.tags.split(",").map((converstionT) => {
            converstionTag.value.map((i:any) => {
              i.tags.map((tag:any) => {
                // @ts-ignore
                if(converstionT === tag.tag_id){
                  converstionT = i.page_id + '_' + converstionT

                }

              })

            })
            return converstionT
          })

        }else {
          item.last_care = Number(item.tags)
        }
        return item;
      })

      formState.title = props.postbackContext.title
      formState.url = props.postbackContext.url
      formState.type = props.postbackContext.type ? props.postbackContext.type : store.getters.getFlowConstants?.facebook_button_types[0]
      if (props.postbackContext.reply_images) {
        if (typeof props.postbackContext.reply_images !== 'string')
          fileList.value = props.postbackContext.reply_images.map((item: string) => {
            const image: AWS_DO_Model = {
              url: item,
              key: item
            }
            return image;
          })
      }
    }


    if (props.postbackContext) {
      model = {...props.postbackContext, ...model}
    }
    if (!model.flow_actions_context) {
      model.flow_actions_context = {
        flow_crm_actions: []
      }
    }
    const facebookButtonTypes = store.getters.getFlowConstants?.facebook_button_types
    if (!model.flow_actions_context.flow_crm_actions) {
      model.flow_actions_context.flow_crm_actions = []
    }
    model.flow_actions_context.flow_crm_actions = model.flow_actions_context.flow_crm_actions.map((item: FlowCRM_Action) => {
      if (item.tags  && (item.type === 'add_tag' || item.type === 'remove_tag' )) {
        item.tagArr = item.tags ? item.tags.split(",").map((str: string) => parseInt(str)) : []
      }else if( item.tags  && ( item.type === 'add_conversation_tag' || item.type === 'remove_conversation_tag')) {
        item.coverstion_tag = item.tags.split(",").map((converstionT) => {
          converstionTag.value.map((i:any) => {
            i.tags.map((tag:any) => {
              // @ts-ignore
              if(converstionT === tag.tag_id){
                converstionT = i.page_id + '_' + converstionT

              }

            })

          })
          return converstionT
        })

      }else {
        item.last_care = Number(item.tags)
      }
      return item;
    })
    const formState: UnwrapRef<FormPostbackContext> = reactive(props.postbackContext);
    const selectCRM_ActionChange = (value: string) => {
      if (value) {
        if (!formState.flow_actions_context) {
          formState.flow_actions_context = {
            flow_crm_actions: []
          }
        }
        if (formState.flow_actions_context.flow_crm_actions) {
          const action = formState.flow_actions_context.flow_crm_actions.find((item: FlowCRM_Action) => item.type === value)
          if (!action) {
            formState.flow_actions_context.flow_crm_actions.push({type: value, tagArr: []})
          }
        } else {
          formState.flow_actions_context.flow_crm_actions = [{type: value, tagArr: []}]
        }
        selectedCRM_Action.value = undefined
      }
    }
    const getFlowNextActions = () => {
      let listSelected: string[] = [];
      props.flowContextData.context.flow_contexts.map((flow: FlowContext) => {
        if (flow.action_default_context && flow.action_default_context.usage_id) {
          listSelected.push(flow.action_default_context.usage_id)
        }
        if (flow.action_send_failed_context && flow.action_send_failed_context.usage_id) {
          listSelected.push(flow.action_send_failed_context.usage_id)
        }
        if (flow.action_send_success_context && flow.action_send_success_context.usage_id) {
          listSelected.push(flow.action_send_success_context.usage_id)
        }
      })
      listSelected = listSelected.filter(x => !x.includes(props.postbackContext.flow_actions_context.usage_id))
      return props.flowContextData.context.flow_contexts.filter((flow: FlowContext, index: number) => {
        return props.postbackContext.id !== flow.id && index !== 0 && !listSelected.includes(flow.id + '')
      }).map((flow: FlowContext) => {
        return {
          label: flow.name,
          value: flow.id
        }
      })
    }
    const customerCares = computed(() => store.getters.getCustomerCares)
    const deleteCRM_Action = (item: FlowCRM_Action) => {
      if (!formState.flow_actions_context) {
        formState.flow_actions_context = {
          flow_crm_actions: []
        }
      }
      formState.flow_actions_context.flow_crm_actions = formState.flow_actions_context.flow_crm_actions?.filter((it: FlowCRM_Action) => it.type !== item.type);
      selectedCRM_Action.value = undefined
    }
    const newFacebookFlow = async (type: number) => {
      let name = 1
      if (props.flowContextData && props.flowContextData.context && props.flowContextData.context.flow_contexts.length) {
        name = props.flowContextData.context.flow_contexts.length + 2
      }
      var resName = "";
      var resMess = "";
      if (type === 1) {
        resName = "#Facebook# " + i18n.t('MESS_NAME_CONTENT') + name;
        resMess = "#Facebook " + i18n.t('FLOW_MESSAGE_CONTENT') + name;
      } else if (type === 2) {
        resName = "#Zalo# " + i18n.t('MESS_NAME_CONTENT') + name;
        resMess = "#Zalo " + i18n.t('FLOW_MESSAGE_CONTENT') + name;
      }

      let flow: FlowContext = {
        "id": MixUtils.methods.getUUID(),
        "name": resName,
        "shop_id": props.flowContextData?.shop_id,
        "flow_type": type,
        "message": resMess,
        "attachment_images": [],
        "type": "",
        "messenger_tag": "",
        "template_id": "",
        "template_name": "",
        "template_status": "",
        "preview_url": "",
        "created_at": new Date().toISOString(),
        "updated_at": new Date().toISOString(),
        "postback_contexts": [],
        "action_send_success_context": {
          "flow_crm_actions": [],
          "flow_action_stack": {}
        },
        "action_send_failed_context": {
          "flow_crm_actions": [],
          "flow_action_stack": {}
        },
        "action_default_context": {
          "flow_crm_actions": [],
          "flow_action_stack": {}
        }
      }

      S_EventBus.instance().dispatchCreateFlow(flow)
      onSubmit()
      setTimeout(() => {
        changeCurrentFlowContext()
      }, 400)
    }
    const deleteNextFlowAction = () => {
      console.log("formState", formState.flow_actions_context)
      if (formState.flow_actions_context?.flow_action_stack) {
        formState.flow_actions_context.flow_action_stack.flow_id = 0
      }
    }
    const changeCurrentFlowContext = () => {
      if (formState.flow_actions_context?.flow_action_stack && formState.flow_actions_context.flow_action_stack.flow_id) {
        S_EventBus.instance().execChangeCurrentFlow(formState.flow_actions_context.flow_action_stack.flow_id)
        popoverVisible.value = false
      }
    }
    const showImageSelectionModal = () => {
      imageSelectionRef.value.showModal()
    }
    const onImageSelect = (images: AWS_DO_Model[]) => {
      popoverVisible.value = true
      fileList.value = [...fileList.value, ...images]
    }
    const removeImage = (image: AWS_DO_Model) => {
      fileList.value = fileList.value.filter((item: AWS_DO_Model) => item.key !== image.key)
    }
    const deletePostBack = () => {
      props.deletePostBackButton()
    }
    const cursorPositionMessageText = ref(0)
    const forcusMessagetext = (e:any) =>{
      // if(e.target.value.lastIndexOf('#') !== -1){
      //   e.target.setSelectionRange(e.target.value.lastIndexOf('#')+1,e.target.value.lastIndexOf('#')+1)
      //   console.log(e.target.selectionStart)
      // }else {
      cursorPositionMessageText.value = e.target.selectionStart
      console.log(cursorPositionMessageText.value)



    }
    const messageFullName = () => {
      const text = ref<any>( formState.reply_message);
      const start = text.value.slice(0,cursorPositionMessageText.value)
      const end = text.value.slice(cursorPositionMessageText.value)
      text.value =

          start + (start ? " " : "") + '##full_name##' + (end ? " " : "") + end
      formState.reply_message = text.value
      onSubmit()
    }

    const containPop = () => {
      return document.getElementById('main-flow')
    }
    updatePostbackContext()
    const changeFlowAvailable2 =  async (id: any) => {
      if (formState.flow_actions_context) {

        formState.flow_actions_context.usage_id = id+''
        formState.flow_actions_context.type = '0'
      }
    }
    const changeFlowAvailable = async (val: string) => {

      if (formState.flow_actions_context) {
        const flow=props.flowContextData.context.flow_contexts.find((item:FlowContext)=>item.id===val)

        formState.flow_actions_context.usage_id =flow.id
        formState.flow_actions_context.type = flow.flow_type + ''
      }
      // formState.usage_id = value

      // let name = 1
      // if (props.flowContextData && props.flowContextData.context && props.flowContextData.context.flow_contexts.length) {
      //   name = props.flowContextData.context.flow_contexts.length + 2
      // }
      // var resName = "";
      // var resMess = "";
      //
      // if(props.flowContextData.flow_context.flow_type === 1)
      // {
      //   resName = "#Facebook# " + i18n.t('MESS_NAME_CONTENT') + name;
      //   resMess = "#Facebook " + i18n.t('FLOW_MESSAGE_CONTENT') + name;
      // }else if(props.flowContextData.flow_context.flow_type === 2)
      // {
      //   resName = "#Zalo# " + i18n.t('MESS_NAME_CONTENT') + name;
      //   resMess = "#Zalo " + i18n.t('FLOW_MESSAGE_CONTENT') + name;
      // }
      // const request: FlowContext = {
      //   name: resName,
      //   flow_type: props.flowContextData.flow_context.flow_type,
      //   message: resMess,
      //   root_flow_id: value
      // }
      // const response = await APIs.init().createFlowContext(request);
      // if (response.status === 200 && response.data && response.data.data) {
      //   let flow = response.data.data;
      //   setTimeout(() => {
      //     changeCurrentFlowContext()
      //   }, 400)
      // }
    }
    const onSubmit = (isButton?: boolean) => {
      formRefPostBack.value
          .validate()
          .then(() => {
            const postB = {...props.postbackContext}
            postB.title = formState.title
            postB.reply_message = formState.reply_message;
            postB.flow_actions_context = formState.flow_actions_context
            postB.type = formState.type
            postB.url = formState.url
            if (!postB.flow_actions_context) {
              postB.flow_actions_context = {
                flow_action_stack: {},
                flow_crm_actions: []
              }
            }
            postB.popoverVisible = false
            if (formState.flow_actions_context && formState.flow_actions_context.flow_crm_actions) {
              postB.flow_actions_context.flow_crm_actions = formState.flow_actions_context.flow_crm_actions?.map((item: FlowCRM_Action) => {
                if (item.tagArr) {
                  item.tags = item.tagArr.join(',');
                }
                if (item.last_care){
                  item.tags = item.last_care + ''
                }
                if( item.coverstion_tag){


                  item.tags = item.coverstion_tag.map((item) => {
                    item = item.split("_")[1]
                    return item
                  }).join(",")
                }
                if(item.type === 'unread_conversation'){
                  item.value = 'true'
                }else {
                  item.value = ''
                }
                return item;
              })
            }
            if (fileList.value && fileList.value.length) {
              postB.reply_images = fileList.value.map((item: AWS_DO_Model) => {
                return item.url
              })
            } else {
              postB.reply_images = []
            }
            props.updatePostBackButton(postB);
            if (isButton) {
              popoverVisible.value = false
            }
          })
          .catch((error: ValidateErrorEntity<FormFlowInformation>) => {
            console.log('error', error);
          });
    };
    const converstionTags = ref<any[]>([])
    const handlePage = (pageId:number) =>{
      store.commit(PageConfigMutationTypes.GET_PAGE_CONFIG,pageId)
      converstionTag.value.map((item:any)=>{
        if(pageId === item.page_id){
          converstionTags.value = item.tags.map((item:any) => item)
        }

      })

    }
    onMounted(()=>{
      if(getCurrentPageId.value){
        handlePage(getCurrentPageId.value)
      }
    })
    return {
      fileList,
      rules,
      formState,
      formRefPostBack,
      onSubmit,
      isNextFlow,
      customerTags,
      crmActions,
      selectedCRM_Action,
      selectCRM_ActionChange,
      deleteCRM_Action,
      popoverVisible,
      nextFlowContext,
      deleteNextFlowAction,
      changeCurrentFlowContext,
      deletePostBack,
      newFacebookFlow,
      onImageSelect,
      showImageSelectionModal,
      imageSelectionRef,
      containPop,
      removeImage,
      facebookButtonTypes,
      changeFlowAvailable,
      changeFlowAvailable2,
      getFlowNextActions,
      messageFullName,
      forcusMessagetext,
      customerCares,
      treeTags,
      converstionTag,
      handlePage,
      converstionTags,getCurrentPageId,currentShop,getFlowData
    }
  }
})
</script>

<style lang="scss" scoped>


.button-post-back {
  margin: 5px 10px;
}

.action-crm {
  margin-bottom: 16px;

  .crm-action-title-container {
    display: flex;
    flex-direction: row;
    margin-bottom: 8px;
    justify-content: space-between;
    align-items: center;

    .title-selected-crm-action {
      font-weight: bold;
    }

    .crm-action-deletion {
      cursor: pointer;
    }
  }

}

.selected-flow-action {
  text-align: left;
  margin-right: 10px;
  flex: 1;
}

.footer-postback-init {
  justify-content: center;
  display: flex;
}

.right-button-content {
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;

  .center-button-content {
    display: flex;
    flex: 1;
    padding-bottom: 5px;
    margin-left: 10px;
  }
}

.header-postback-pop {
  width: 100%;
  margin: auto;
}

.form-postback {
}
</style>
