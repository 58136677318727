<template>
  <a-dropdown>
    <span class="ant-pro-account-avatar s-avatar">
      <img :src="`/crm/img/icons/${getLang}.svg`" class="anticon icon-img-svg"/>
      {{getLang.toUpperCase()}}
    </span>
    <template v-slot:overlay>
      <a-menu class="ant-pro-drop-down menu" @click="onClick">
        <a-menu-item key="en">
          <img src="@/assets/locales/en.svg" class="anticon icon-img-svg"/>
          English
        </a-menu-item>
        <a-menu-item key="vi">
          <img src="@/assets/locales/vn.svg" class="anticon icon-img-svg"/>
          Vietnamese
        </a-menu-item>
        <a-menu-item key="th">
          <img src="@/assets/locales/thai.svg" class="anticon icon-img-svg"/>
          Thai
        </a-menu-item>
      </a-menu>
    </template>
  </a-dropdown>
</template>

<script lang="ts">
import {Options, Vue} from "vue-class-component";
import Icon, {
  GlobalOutlined,
  UserOutlined
} from '@ant-design/icons-vue';
import {VNodeChild} from "vue";
import {mapGetters, mapMutations} from "vuex";
import {UsersMutationTypes} from "@/store/modules/user/mutation-types";
import APIs from "@/APIs/APIs";
import dayjs from "dayjs";
import 'dayjs/locale/vi.js'
import 'dayjs/locale/en.js'
interface MenuInfo {
  key: string;
  keyPath: string[];
  item: VNodeChild;
  domEvent: MouseEvent;
}


@Options({
  name: "lang-dropdown",
  components: {
    GlobalOutlined,
    UserOutlined,
    Icon,
  },
  data() {
    return {}
  },
  computed: {
    ...mapGetters({
      getLang: 'getLang',
      getUserInfo: 'getUserInfo'
    }),
  },
  methods: {
    ...mapMutations({
      setLang: UsersMutationTypes.SET_Lang
    }),
    onClick({key}: MenuInfo) {
      this.setLang(key)
      this.$i18n.locale = key;
      dayjs.locale(key)

        APIs.init().updateCMSUser({
          id: this.getUserInfo.id,
          language: key
        }).then(response => {
          console.log(response)
        }).catch((e) => {
          console.error("e", e)
        })
    }
  }
})
export default class LangDropdown extends Vue {
}

</script>

<style lang="css" scoped>
.s-avatar {
  padding-left: 14px;
  padding-right: 14px;
  cursor: pointer;
  color: white;
}


.s-avatar:hover {
  background-color: rgba(0, 0, 0, .025);
}
</style>
