<template>
  <div class="stock-header-container">
    <a-avatar :size="64" src="https://biglead.live/images/logo.png">
    </a-avatar>
    <div class="stock-info">
      <h3>
        Branch 12 New york
      </h3>
      <div>
        The New York Times Building, 620 Eighth Avenue; New York City, U.S
      </div>
    </div>
    <div>
      <a-statistic :value="112893" style="margin-right: 9px" >
        <template #title>
           <span>
              <a-badge status="processing" />
              Gross product
           </span>
        </template>
      </a-statistic>
    </div>
  </div>
  <products :stockId="1213"/>
</template>

<script lang="ts">
import {defineComponent} from "vue";
import Products from "@/views/Product/Products.vue";

export default defineComponent({
  components: {Products}
})
</script>

<style scoped lang="scss">
.stock-header-container {
  display: flex;
  padding: 12px 18px;
  background-color: white;
  border-radius: 5px;
  box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
  margin-bottom: 28px;

  .stock-info {
    margin-left: 12px;
    flex: 1;
  }

  .stock-count-product {
    margin-left: 24px;
    margin-right: 24px;
  }
}
</style>
