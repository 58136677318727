<template>
  <h3 class="title-flow-context">
    {{$t('FLOW_PREVIEW')}}
  </h3>
  <div class="preview-container">
    <div v-html="htmlTemp" style=" zoom: 0.5;
    -moz-transform: scale(0.5);">

    </div>
  </div>
</template>

<script lang="ts">
import {computed, defineComponent, ref, watch} from "vue";
import {PostbackContext} from "@/models/Flows/FlowContext";
import APIs from "@/APIs/APIs";
import {useStore} from "vuex";

export default defineComponent({
  name: "email-preview",
  props: ['flowContext'],
  setup(props) {
    watch(() => props.flowContext, () => {
      updateProps()
    })
    const htmlTemp=ref()
    const store=useStore()
    const currentShop=computed(()=>store.getters.getCurrentShop)
    APIs.init().getFlowTemplateViewer().then(res=>{
      if (res.ok){
        htmlTemp.value=res.data
        setTimeout(updateProps,400)
      }
    })
    const updateProps = () => {
      if (currentShop.value){
        const domShopName=document.getElementById("biglead_shop_name")
        if (domShopName){
          if (props.flowContext.footer_text){
            domShopName.innerHTML=props.flowContext.footer_text
          }else {
            domShopName.innerHTML=currentShop.value.shop_name
          }
        }
      }
      if (props.flowContext.message) {
        const domMailContent=document.getElementById("mail_content")
        if (domMailContent){
          domMailContent.innerHTML=props.flowContext.message
        }
      }
    }
    updateProps()
    return {
      htmlTemp
    }
  }
})
</script>

<style lang="scss" scoped>
.preview-container {
  background-color: white;
  min-height: 480px;
  border-radius: 10px;
  padding: 15px;
}


.content-message {
  margin-right: 20px;
  padding: 15px;
  background-color: #E1E1E1;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  border-bottom-right-radius: 15px;
}

.post-back-button {
  margin-top: 18px;
  text-align: center;

  .button-post-back {
    margin: 5px 10px;
  }
}

.title-flow-context {
  text-align: center;
  font-weight: bold;
}
</style>
