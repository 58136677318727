import {ActionContext, ActionTree} from "vuex";
import {State} from "./states";
import {RootState} from "@/store";
import {AWS_DO_FileStorageActionTypes} from "./action-types";
import {AWS_FilesResponse} from "@/APIs/response/S_HttpResponse";
import APIs from "@/APIs/APIs";
import {AWS_DO_FileStorageMutationTypes} from "./mutation-types";
import {Mutations} from "./mutations";

type AugmentedActionContext = {
    commit<K extends keyof Mutations>(
        key: K,
        payload: Parameters<Mutations[K]>[1],
    ): ReturnType<Mutations[K]>;
} & Omit<ActionContext<State, RootState>, 'commit'>

export interface Actions {
    [AWS_DO_FileStorageActionTypes.FETCH_FILES](
        {commit}: AugmentedActionContext,
    ): Promise<AWS_FilesResponse | undefined>;
}

export const actions: ActionTree<State, RootState> & Actions = {
    async [AWS_DO_FileStorageActionTypes.FETCH_FILES]({commit}): Promise<AWS_FilesResponse | undefined> {
        const response = await APIs.init().getFiles();
        if (response.status === 200 && response.data?.data) {
            commit(AWS_DO_FileStorageMutationTypes.SET_FILES, response.data.data)
            return response.data
        }
        return undefined
    }
}