<template>
    <div class="stock-header-container">
      <div class="stock-info">
        <h3>
          {{ stockDetail.shop_name }}
        </h3>
        <div>
          {{ stockDetail.shop_address }}
        </div>
      </div>
      <a-form layout="inline">
        <a-form-item>
          <div class="filter-form-label">
            {{ $t("SEARCH") }}
          </div>
          <a-input-search
              allow-clear
              :placeholder="$t('PLACEHOLDER_SEARCH_PRODUCT')"
              @search="handleChangeSearch"
          />
        </a-form-item>
        <a-form-item>
          <div class="filter-form-label">
            {{ $t("status") }}
          </div>
          <a-select
              showSearch
              allowClear
              :placeholder="$t('status')"
              style="width: 220px"
              @change="handleChangStatus"
          >
            <a-select-option
                v-for="item in statusData"
                :key="item.value"
                v-bind:value="item.value"
            >
              <a-typography-text> {{ item.title }}</a-typography-text>

            </a-select-option>
          </a-select>
        </a-form-item>
      </a-form>
     <a-row>
       <div>
         <a-statistic :value="stockDetail.gross_product" style="margin-right: 9px" >
           <template #title>
             <span>
                <a-badge status="processing" />
                Gross product
             </span>
           </template>
         </a-statistic>
       </div>
       <div>
         <a-statistic :value="stockDetail.total_value" style="margin-right: 9px" >
           <template #title>
             <span>
                <a-badge status="processing" />
                Total value
             </span>
           </template>
         </a-statistic>
       </div>
     </a-row>
    </div>
    <a-table
        size="middle"
        :scroll="{  y: '80vh' }"
        class="table"
        :rowKey="(record) => record.id"
        :columns="columns"
        :data-source="sort(getStockProducts.products)"
        :pagination="pagination"
        @change="handleTableChange"
        :loading="getStockProducts.loading"
    >
      <template #group_name="{ record }">
        <a>{{ record.group_name }}</a>
      </template>
      <template #status="{ record }">
        <a v-if="record.available">{{$t('Stocking')}}</a>
        <a v-else>{{$t('Out_of_stock')}}</a>

      </template>
      <template #product_name="{ record }">
        <a @click="editVersion(record)">{{ record.product_name }}<br/>{{record.attributes?JSON.parse(record.attributes).map(a=>a.value).join('/'): ''}}</a>
      </template>
      <template #avatar_url="{record}">
                <span>
                   <a-image
                       width="50px"
                       max-height="100px"
                       max-width="100px"
                       :src="record.avatar_url"
                   />
                </span>
      </template>
      <template #total="{text}">
                <span class="total">
                  {{ text }}
                </span>
      </template>
      <template #quantity="{text}">
          <span class="quantity">
            {{ formatMoney(text) }}
          </span>
      </template>
      <template #ordered="{text}">
          <span class="ordered">
            {{ formatMoney(text) }}
          </span>
      </template>
      <template #available="{text}">
          <span class="available" :style="{color: text===0?'red': 'green' }">
            {{ formatMoney(text) }}
          </span>
      </template>
      <template #avg_import_price="{text}">
          <span class="avg_import_price">
            {{ formatMoney(text) }}
          </span>
      </template>
      <template #max_import_price="{text}">
          <span class="max_import_price">
            {{ formatMoney(text) }}
          </span>
      </template>
      <template #min_import_price="{text}">
          <span class="min_import_price">
            {{ formatMoney(text) }}
          </span>
      </template>
      <template #unit_price="{record}">
            <span class="unit_price">
              {{ formatMoney(record.unit_price) }}
            </span>
      </template>
      <template #promotional_price="{record}">
            <span class="promotional_price">
              {{ formatMoney(record.promotional_price) }}
            </span>
      </template>
      <template #wholesale_price="{record}">
            <span class="wholesale_price">
              {{ formatMoney(record.wholesale_price) }}
            </span>
      </template>
      <template #operation="{record}">
        <span class="table-operation">
          <a-button
              @click="importStock(record)"
              type="text">
            <img src="@/assets/icons/pallet-export-svgrepo-com.svg" class="import-stock-icon anticon">
          </a-button>
        </span>
      </template>
    </a-table>
  <stock-import-modal ref="stockImportModalRef" :callbackImport="callbackImport"/>

</template>

<script lang="ts">
import {computed, defineComponent, onBeforeMount, onMounted, reactive, ref, UnwrapRef} from "vue";
import {useStore} from "vuex";
import {Product, ProductDetail, ProductGroup} from "@/models/Products/Products";
import {message} from "ant-design-vue";
import APIs from "@/APIs/APIs";
import {ProductMutationTypes} from "@/store/modules/product/mutation-types";
import {ProductGroupRequest, StockProductRequest} from "@/APIs/request/ProductRequest";
import {ProductsActionTypes} from "@/store/modules/product/action-types";
import {useRoute} from "vue-router";
import {StockActionTypes} from "@/store/modules/stocks/action-types";
import {MixUtils} from "@/utils/Utils";
import {ProductGroupData} from "@/store/modules/product/states";
import {StockProductsData} from "@/store/modules/stocks/states";
import router from "@/router";
import {StockDetail, Stocks} from "@/models/Stocks/Stocks";
import {DeleteOutlined, EditOutlined, PlusOutlined} from "@ant-design/icons-vue";
import StockImportModal from "@/components/products/StockImportModal.vue";
import {useI18n} from "vue-i18n";
// import Products from "@/views/Product/Products.vue";



export default defineComponent({
  name: "Stock",
  components: {
    StockImportModal
  },
  created() {
    this.$watch(
        () => this.$route.query,
        () => {
          console.log('params', this.$route.query)
          this.stockProductRequest.page = Number(this.$route.query.page)
          this.stockProductRequest.page_size = Number(this.$route.query.page_size)
          this.fetchProducts()
        },
        // fetch the data when the view is created and the data is
        // already being observed
        { immediate: true }
    )
  },
  setup(){

    const store = useStore()
    const route = useRoute()
    const stockDetail: UnwrapRef<StockDetail> = reactive({});
    const stockImportModalRef=ref();
    const i18n = useI18n()
    const statusData = ref([{title: i18n.t('Stocking'),value: 1},{title:i18n.t('Out_of_stock')  ,value: -1}])
    const columns = [
      {
        title: i18n.t('Code'),
        dataIndex: 'product_code',
        key: 'product_code',
      },
      {
        title: i18n.t('product_name'),
        dataIndex: 'group_name',
        key: 'group_name',
        slots: {customRender: 'group_name'},
      },
      {
        title: i18n.t('product_version'),
        dataIndex: 'product_name',
        key: 'product_name',
        slots: {customRender: 'product_name'},
        width: '16%'
      },
      // {
      //   title: i18n.t('status'),
      //   dataIndex: 'status',
      //   key: 'status',
      //   slots: {customRender: 'status'},
      // },
      {
        title: i18n.t('camera_image'),
        dataIndex: 'avatar_url',
        key: 'avatar_url',
        slots: {customRender: 'avatar_url'},
        width: 100
      },
      {
        title: i18n.t('sale_price'),
        key: 'unit_price',
        dataIndex: 'unit_price',
        slots: {customRender: 'unit_price'},
      },
      // {
      //   title: i18n.t('wholesale_price'),
      //   key: 'wholesale_price',
      //   dataIndex: 'wholesale_price',
      //   slots: {customRender: 'wholesale_price'},
      // },
      // {
      //   title: i18n.t('promotional_price'),
      //   key: 'promotional_price',
      //   dataIndex: 'promotional_price',
      //   slots: {customRender: 'promotional_price'},
      // },
      // {
      //   title: 'Avg Purchase Price',
      //   key: 'avg_import_price',
      //   dataIndex: 'avg_import_price',
      //   slots: {customRender: 'avg_import_price'},
      // },
      // {
      //   title: 'Min Purchase Price',
      //   key: 'min_import_price',
      //   dataIndex: 'min_import_price',
      //   slots: {customRender: 'min_import_price'},
      // },
      // {
      //   title: 'Max Purchase Price',
      //   key: 'max_import_price',
      //   dataIndex: 'max_import_price',
      //   slots: {customRender: 'max_import_price'},
      // },
      {
        title: i18n.t('Quantity'),
        key: 'quantity',
        dataIndex: 'quantity',
        slots: {customRender: 'quantity'},
      },
      {
        title: i18n.t('Ordered'),
        key: 'ordered',
        dataIndex: 'ordered',
        slots: {customRender: 'ordered'},
      },
      {
        title: i18n.t('Available'),
        key: 'available',
        dataIndex: 'available',
        slots: {customRender: 'available'},
      },
      {
        key: 'operation',
        slots: {customRender: 'operation'},
        width: 80
      },
    ];


    const stockProductRequest: StockProductRequest = { page: 1, page_size: 20, stock: Number(route.params.id) }
    const compareValues = (key: any, order = 'asc') => {
      return function(a:any, b:any) {
        // eslint-disable-next-line no-prototype-builtins
        if(!a.hasOwnProperty(key) || !b.hasOwnProperty(key)) {
          // nếu không tồn tại
          return 0;
        }

        const varA = (typeof a[key] === 'string') ?
            a[key].toUpperCase() : a[key];
        const varB = (typeof b[key] === 'string') ?
            b[key].toUpperCase() : b[key];

        let comparison = 0;
        if (varA > varB) {
          comparison = 1;
        } else if (varA < varB) {
          comparison = -1;
        }
        return (
            (order == 'desc') ? (comparison * -1) : comparison
        );
      };
    }
    const sort = (arr: [])=>{
     return  arr.sort(compareValues('quantity','desc'))
    }
    // console.log(arr.sort(compareValues('quantity','desc')),'1111')
    const getCurrentShop=computed(()=>store.getters.getCurrentShop)
    const getStockProducts=computed(() =>store.getters.getStockProducts)


    const fetchStockDetail = async () => {
      message.loading('')
      const response = await APIs.init().getStockDetail({ id: stockProductRequest.stock})
      message.destroy()
      if (response.status === 200 && response.data?.status === 200) {
        Object.assign(stockDetail, response.data.data)
        console.log('stockDetail', stockDetail)
      }
    }

    const fetchProducts=()=>{
      store.dispatch(StockActionTypes.FETCH_STOCK_PRODUCTS, stockProductRequest)
    }
    const handleChangeSearch = (value:string) =>{
      stockProductRequest.search = value
      fetchProducts()
    }
    const handleChangStatus =(value:number) => {
      stockProductRequest.in_stock = value
      fetchProducts()
    }
    const formatMoney = (money: string)=>{
      return MixUtils.methods.formatMoney(money)
    }

    const handleTableChange = (pagination: any) =>{
      router.replace({ query: {page: pagination?.current, page_size: pagination?.pageSize} })
    }
    const pagination = computed(() =>{
      return {
        current: route.query.page ? parseInt(route.query.page+"") : 1,
        pageSize: route.query.page_size ? parseInt(route.query.page_size+"") : 20,
        defaultPageSize: 20,
        total: getStockProducts.value.metadata && getStockProducts.value.metadata.record_total ? getStockProducts.value.metadata.record_total : 0,
        pageSizeOptions: ['20', '40', '80', '120'],
        showSizeChanger: true
      }
    })

    onMounted(()=>{
      fetchStockDetail()
    })

    const importStock=(product: Product)=>{
      console.log(product)
      stockImportModalRef.value.showModal(product)
    }

    const callbackImport = (products: ProductGroup)=>{
      fetchStockDetail()
      fetchProducts()
    }

    return {
      formatMoney,
      getStockProducts,
      handleTableChange,
      pagination,
      fetchProducts,
      stockProductRequest,
      stockDetail,
      columns,
      importStock,
      stockImportModalRef,
      callbackImport,
      handleChangeSearch,
      statusData,
      handleChangStatus,
      sort

    }
  }
})
</script>

<style scoped lang="scss">
.stock-header-container {
  display: flex;
  padding: 12px 18px;
  background-color: white;
  border-radius: 5px;
  box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
  margin-bottom: 28px;
  justify-content: space-between;

  .stock-info {
    margin-left: 12px;

  }

  .stock-count-product {
    margin-left: 24px;
    margin-right: 24px;
  }


}

.import-stock-icon {
  width: 16px;
  height: 16px;
}

.anticon{
  width: 16px!important;
  height: 16px!important;
}
.table {
  margin: 16px
}
</style>
