import {Vouchers} from "@/models/Voucher/Voucher";
import {Metadata} from "@/APIs/response/S_HttpResponse";


export type VoucherData = {
    voucher: Vouchers[]|undefined
    metadata: Metadata|undefined
}
export type State = {
    vouchers: VoucherData| undefined
    voucherActive: any|undefined
}
export const state: State = {
    vouchers:{voucher:[],metadata:{}},
    voucherActive:[]
}
