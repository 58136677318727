<template>
  <div class="main-flow" id="main-flow">
    <a-form ref="formRef" :rules="rules" :model="formState" layout="vertical">
      <a-form-item ref="name" :label="$t('FLOW_MESSAGE_NAME')" name="name">
        <a-input
          v-model:value="formState.name"
          :placeholder="$t('FLOW_MESSAGE_NAME')"
          @blur="onTextChange"
        />
      </a-form-item>

      <a-form-item :label="$t('SELECT_TEMPLATE_SEND_ZALO')">
        <a-button style="width: 100%" type="primary" @click="showTemp">{{
          $t("SELECT_TEMPLATE_SEND_ZALO")
        }}</a-button>
        <!--        <a-button type="primary" @click="showTemp">Chọn mẫu gửi Zalo</a-button>-->
      </a-form-item>

      <a-card
        v-if="flowContext.zns_attributes && flowContext.zns_attributes.length"
        style="
          background-color: #e9e9e9;
          border-radius: 15px;
          margin-bottom: 10px;
        "
      >
        <a-form-item
          v-for="item in flowContext.zns_attributes"
          :key="item.id"
          :label="$t(item.name)"
        >
          <a-input
            v-model:value="item.value"
            @blur="onTextChange"
            v-if="item.name === 'customer_name'"
            readonly
            :placeholder="$t('customer_name_pleaceorther')"
          />
          <!--          <a-date-picker  :valueFormat="'DD/MM/YYYY'" v-else-if="item.type === 'DATE'" @change="onChangeDatePicker" :maxlength="item.max_length" format="DD/MM/YYYY" v-model:value="item.value" style="min-width:100% " />-->
          <a-input
            v-model:value="item.value"
            :maxlength="item.max_length"
            v-else-if="
              item.name === 'so_tien' ||
              item.name === 'cost' ||
              item.name === 'money'
            "
            @change="onTextChange"
          />

          <a-input
            v-else
            v-model:value="item.value"
            :maxlength="item.max_length"
            @blur="onTextChange"
            style="width: 100%"
          >
            <template #suffix>
              <a-popover trigger="click" title="Title">
                <template #content>
                  <a-tree
                    @select="onTextChange"
                    allow-clear
                    show-search
                    v-model:selectedKeys="item.value"
                    :maxlength="item.max_length"
                    style="width: 100%"
                  >
                    <a-tree-node
                      :disabled="true"
                      v-for="item in getKeyTag"
                      :key="item.key"
                      :value="item.key"
                    >
                      <template #title>
                        <a-tag>
                          {{ $t(item.key) }}
                        </a-tag>
                      </template>

                      <a-tree-node
                        v-for="data in item.treeData"
                        :key="
                           data.id
                        "
                        :value="
                         data.id
                        "
                      >
                        <template #title>
                          <span >
                            {{ $t(data.text) }}
                          </span>
                        </template>
                      </a-tree-node>
                    </a-tree-node>
                  </a-tree>
                </template>
                <DownOutlined />
              </a-popover>
            </template>
          </a-input>
        </a-form-item>
      </a-card>

      <flow-next-actions
        v-if="!isShow"
        :buttonName="$t('FLOW_SUCCESS_DELIVERY')"
        :updateAction="updateSuccessAction"
        :flowContextData="flowContextData"
        :flowContext="flowContext"
        :actionContext="flowContext.action_send_success_context"
      />
      <flow-next-actions
        v-if="!isShow"
        :buttonName="$t('FLOW_FAILED_DELIVERY')"
        :updateAction="updateFailedAction"
        :flowContextData="flowContextData"
        :flowContext="flowContext"
        :actionContext="flowContext.action_send_failed_context"
      />
      <flow-next-actions
        v-if="!isShow"
        :buttonName="$t('FLOW_DEFAULT_DELIVERY')"
        :updateAction="updateDefaultAction"
        :flowContextData="flowContextData"
        :flowContext="flowContext"
        :actionContext="flowContext.action_default_context"
      />
      <div class="row-button-action" style="text-align: right">
        <a-popconfirm
          @confirm="deleteCurrentFlowContext"
          :title="$t('FLOW_CONFIRM_DELETE_CONTEXT')"
          :ok-text="$t('YES')"
          :cancel-text="$t('NO')"
        >
          <a-button danger type="primary">
            <delete-outlined />
            {{ $t("FLOW_DELETE") }}
          </a-button>
        </a-popconfirm>
      </div>
    </a-form>
    <a-popover
      :title="$t('FLOW_CHANGE_TYPE')"
      class="icon-brand-sticker"
      placement="leftTop"
    >
      <template #content>
        <p>
          <a-button type="dashed" size="large" class="button-2-margin-top">
            <facebook-outlined />
            {{ $t("CHANGE_TO_FACEBOOK_MESSAGE") }}
          </a-button>
        </p>
      </template>
      <img
        src="@/assets/icons/zalo-flat.svg"
        class="anticon"
        style="width: 28px; height: 28px"
      />
    </a-popover>
  </div>

  <a-modal
    :title="$t('SELECT_TEMPLATE_SEND_ZALO')"
    v-model:visible="visibleTemp"
    @ok="updateTemp()"
  >
    <div class="form-modal">
      <h4>{{ $t("CHOOSE_ZALO_ACCOUNT") }}</h4>
      <a-select
        @change="fetchZNS_Template"
        style="width: 100%"
        :placeholder="$t('SELECT_PAGE')"
      >
        <a-select-option
          v-for="item in zaloPages"
          :key="item.id"
          v-bind:value="item.page_id"
          >{{ item.page_name }}
        </a-select-option>
      </a-select>
      <template v-if="znsTemplates.length > 0">
        <a-table
          :columns="columns"
          :row-selection="rowSelection"
          :data-source="znsTemplates"
          :rowKey="(record) => record.templateId"
        />
      </template>
    </div>
  </a-modal>
</template>

<script lang="ts">
import {
  computed,
  defineComponent,
  onBeforeMount,
  reactive,
  ref,
  UnwrapRef,
  watch,
} from "vue";
import {
  ActionContext,
  FlowContext,
  PostbackContext,
} from "@/models/Flows/FlowContext";
import {
  ChangeCurrentFlow,
  S_EventBus,
  ValidateFlowContext,
} from "@/utils/EventBus";
import { mapActions, mapGetters } from "vuex";
import { Employee, Page } from "@/models/Users";
import {
  DeleteOutlined,
  FacebookOutlined,
  PlusOutlined,
  DownOutlined,
} from "@ant-design/icons-vue";
import FacebookPostbackButton from "@/components/Flow/FacebookPostbackButton.vue";
import FlowNextActions from "@/components/Flow/FlowNextActions.vue";
import { useStore } from "@/store";
import {
  FormFlowContextZalo,
  FormFlowInformation,
} from "@/models/Flows/FlowForm";
import { useI18n } from "vue-i18n";
import { ValidateErrorEntity } from "ant-design-vue/es/form/interface";
import APIs from "@/APIs/APIs";
import { message } from "ant-design-vue";
import { CustomersActionTypes } from "@/store/modules/customer/action-types";
import { TagsActionTypes } from "@/store/modules/tag/action-types";
import { CustomerCaresActionTypes } from "@/store/modules/customerCare/action-types";
import { DictionaryActionTypes } from "@/store/modules/dictionary/action-types";
import { FlowActionTypes } from "@/store/modules/flow/action-types";
import { Options, Vue } from "vue-class-component";
import { ZnsTemp } from "@/models/Flows/Flow";
import { AWS_DO_Model } from "@/models/AWS_DO/AWS_DO";
import { ColumnProps } from "ant-design-vue/es/table";
import { FlowsMutationTypes } from "@/store/modules/flow/mutation-types";
import moment from "moment";
type Key = ColumnProps["key"];

export default defineComponent({
  name: "flow-zalo-zns",
  props: [
    "flowContext",
    "updateCurrentFlowContext",
    "flowContextData",
    "deleteFlowContext",
    "isShow",
  ],
  components: {
    FacebookOutlined,
    DeleteOutlined,
    //FacebookPostbackButton,
    FlowNextActions,
    DownOutlined,
    //PlusOutlined
  },
  setup(props) {
    const formRef = ref();
    const messengerTags = ref();
    const visibleTemp = ref(false);
    const store = useStore();
    const formState: UnwrapRef<FormFlowContextZalo> = reactive({});
    const i18n = useI18n();
    const confirmTemp = ref(false);
    const templateId = ref();
    const templatePageId = ref();
    const getColumnsConfig = computed(() => store.getters.getCustomerConfig);
    const getColumn = computed(() => store.getters.getDefaultColumns);
    const getKeyTag = ref([
      // @ts-ignore
      {
        key: "Customer",
        // @ts-ignore
        treeData: [{id:'employees',text:'SALE_STAFF'},
          {id:'phone',text:'CUSTOMER_PHONE'},
          {id:'address',text:'CUSTOMER_ADDRESS'},
          {id:'birth',text:'CUSTOMER_BIRTHDAY'},

          {id:'sex',text:'SEX'},],
      },
      {
        key: "order",
        treeData: [
          {id:'shipping_code',text:'ORDER_SHIPPING_UNIT_ID'},
          {id:'shipping_address',text:'CUSTOMER_ADDRESS'},
          {id:'phone',text:'CUSTOMER_PHONE'},
          {id:'detail',text:'NAME'},
          {id:'total',text:'TOTAL_AMOUNT'},
          {id:'prepaid',text:'PAID'},
          {id:'cod_amount',text:'COD'},
          {id:'created_at',text:'ORDER_DATE'},
          {id:'status',text:'STATUS'},
          {id:'order_number',text: 'ORDER_ID'}
        ],
      },
    ]);
    const datePicker = ref();
    const updateProps = () => {
      formState.name = props.flowContext.name;
      formState.message_content = props.flowContext.message;
      if (props.flowContext.postback_contexts) {
        postbackContexts.value = props.flowContext.postback_contexts;
      } else {
        postbackContexts.value = [];
      }
    };
    watch(
      () => props.flowContext,
      () => {
        updateProps();
      }
    );
    const flowFormValidate: ValidateFlowContext = {
      showValidateForm(): void {
        onSubmit();
      },
    };
    const onChangeDatePicker = (value: string) => {
      datePicker.value = moment(value).format("DD/MM/YY");
      console.log(datePicker.value);
    };
    onBeforeMount(() => {
      S_EventBus.instance().setValidateFlowContextListener(flowFormValidate);
      store.commit(FlowsMutationTypes.SET_ZNS_TEMP, []);
    });
    messengerTags.value = store.getters.getFlowConstants?.messenger_tag;
    const postbackContexts = ref<PostbackContext[]>([]);
    const rules = {
      name: [
        {
          required: true,
          message: i18n.t("FLOW_VALIDATE_FLOW_NAME"),
          trigger: "blur",
        },
      ],
      tags: [
        {
          type: "array",
          required: true,
          message: i18n.t("FLOW_VALIDATE_FACEBOOK_MESSENGER_TAG"),
          trigger: "change",
        },
      ],
      message_content: [
        {
          required: true,
          message: i18n.t("FLOW_VALIDATE_FACEBOOK_MESSAGE_CONTENT"),
          trigger: "blur",
        },
      ],
    };
    const onTextChange = () => {
      console.log("1111");
      onSubmit();
    };
    const updatePostBackButton = (postBack: PostbackContext, index: number) => {
      postbackContexts.value[index] = postBack;
      const flow = { ...props.flowContext };
      flow.postback_contexts = postbackContexts.value;
      props.updateCurrentFlowContext(flow);
    };
    const searchkeys = (value: string) => {
      console.log(value);
    };
    const addPostBackButton = () => {
      postbackContexts.value.push({
        title: "#button #" + (postbackContexts.value.length + 1),
        flow_actions_context: {
          flow_crm_actions: [],
        },
        type: store.getters.getFlowConstants?.facebook_button_types[0],
        popoverVisible: true,
      });
      const flow = { ...props.flowContext };
      flow.postback_contexts = postbackContexts.value;
      props.updateCurrentFlowContext(flow);
    };
    const updateSuccessAction = (action: ActionContext) => {
      const flow = { ...props.flowContext };
      flow.action_send_success_context = action;
      props.updateCurrentFlowContext(flow);
    };
    const updateFailedAction = (action: ActionContext) => {
      const flow = { ...props.flowContext };
      flow.action_send_failed_context = action;
      props.updateCurrentFlowContext(flow);
    };
    const updateDefaultAction = (action: ActionContext) => {
      const flow = { ...props.flowContext };
      flow.action_default_context = action;
      props.updateCurrentFlowContext(flow);
    };

    const deleteCurrentFlowContext = () => {
      props.deleteFlowContext(props.flowContext.id);
    };
    const showTemp = () => {
      visibleTemp.value = true;
    };
    const updateTemp = () => {
      confirmTemp.value = true;
      const flow = { ...props.flowContext };
      if (templateId.value) {
        flow.template_id = templateId.value.join();
      }
      flow.name = formState.name;
      flow.page_id = templatePageId.value;
      console.log("updateCurrentFlowContext", flow);
      props.updateCurrentFlowContext(flow);
      confirmTemp.value = false;
      visibleTemp.value = false;
    };

    //day la ham` onChange luon
    const fetchZNS_Template = (page_id: string) => {
      templatePageId.value = page_id;
      store.dispatch(FlowActionTypes.FETCH_ZnsTemp, { page_id: page_id });
    };
    //day la` kieu watch xem khi nao state trong store thay doi thi` const znsTemplates cung thay doi theo
    const znsTemplates = computed<ZnsTemp[]>(() => store.getters.getZnsTemp);

    //day la` config san thi co the viet kieu nay
    const zaloPages = ref<Page[]>(
      store.getters.getPages.filter((item) => item.page_type === 2)
    );

    //flexible way. day la` bat cu khi nao page thay doi
    // const zaloPages = computed<Page[]>(() => store.getters.getPages.filter((item)=>item.page_type===2))

    //
    // const onRadioChange = (value:string) => {
    // alert(value)
    // }
    const rowSelection = {
      onChange: (selectedRowKeys: Key[], selectedRows: ZnsTemp[]) => {
        templateId.value = selectedRowKeys;
      },
      // getCheckboxProps: (record: ZnsTemp) => ({
      //   disabled: record.templateName === 'Disabled User', // Column configuration not to be checked
      //   name: record.templateName,
      // }),
      type: "radio",
    };
    const onSubmit = () => {
      formRef.value
        .validate()
        .then(() => {
          const flow = { ...props.flowContext };
          console.log("submit:", flow);
          flow.zns_attributes = flow.zns_attributes.map((item: any) => {
            if (
              item.name === "so_tien" ||
              item.name === "cost" ||
              item.name === "money"
            ) {
              item.value = item.value.replace(",", "");
              item.value = item.value.replace(".", "");
            }

            if (Array.isArray(item.value)) {
              item.value = item.value[0];
            }
            return item;
          });

          flow.message = formState.message_content;
          flow.name = formState.name;
          props.updateCurrentFlowContext(flow);
        })
        .catch((error: ValidateErrorEntity<FormFlowInformation>) => {
          console.log("error", error);
        });
    };
    updateProps();

    return {
      rules,
      formState,
      formRef,
      onSubmit,
      onTextChange,
      postbackContexts,
      messengerTags,
      updatePostBackButton,
      updateSuccessAction,
      updateFailedAction,
      updateDefaultAction,
      addPostBackButton,
      deleteCurrentFlowContext,
      visibleTemp,
      confirmTemp,
      onChangeDatePicker,
      fetchZNS_Template,
      znsTemplates,
      showTemp,
      updateTemp,
      zaloPages,
      rowSelection,
      templateId,
      datePicker,
      getKeyTag,
      searchkeys,
      columns: [
        {
          title: "Id",
          dataIndex: "templateId",
          key: "templateId",
        },
        {
          title: "Tên mẫu",
          dataIndex: "templateName",
          key: "templateName",
        },
      ],
    };
  },
});
</script>

<style lang="scss" scoped>
.main-flow {
  position: relative;
  padding: 40px 18% 60px;
  text-align: left;

  .icon-brand-sticker {
    position: absolute;
    font-size: 28px;
    top: 0;
    margin-right: 25px;
    right: 0;
  }

  .row-button-action {
    margin-top: 20px;
  }

  .card-message {
    border-radius: 10px;
  }
}

.post-back-button {
  margin-top: 18px;
  text-align: center;
}

.button-post-back {
  margin: 5px 10px;
}
</style>
