    export const Constant = {
    phoneFormats:[
        {
            key:'016966',
            value:'03966'
        },
        {
            key:'0169',
            value:'039'
        },
        {
            key:'0168',
            value:'038'
        }, {
            key:'0167',
            value:'037'
        }, {
            key:'0166',
            value:'036'
        }, {
            key:'0165',
            value:'035'
        }, {
            key:'0164',
            value:'034'
        }, {
            key:'0163',
            value:'033'
        }, {
            key:'0162',
            value:'032'
        }, {
            key:'0120',
            value:'070'
        }, {
            key:'0121',
            value:'079'
        }, {
            key:'0122',
            value:'077'
        }, {
            key:'0126',
            value:'076'
        }, {
            key:'0128',
            value:'078'
        }, {
            key:'0123',
            value:'083'
        },{
            key:'0124',
            value:'084'
        },{
            key:'0125',
            value:'085'
        },{
            key:'0127',
            value:'081'
        },{
            key:'0129',
            value:'082'
        },{
            key:'01992',
            value:'059'
        },{
            key:'01993',
            value:'059'
        },{
            key:'01998',
            value:'059'
        },{
            key:'01999',
            value:'059'
        },{
            key:'0186',
            value:'056'
        },
        {
            key:'0188',
            value:'058'
        },
    ],
    WEB_SHOPF1_URL_LOGIN_CALLBACK:"https://web.shopf1.net/login-outsite?callback=",
    LINK_CHAT:"https://chat.biglead.live/login?&pages=",
    LINK_CHAT_NEW:"https://chatnew.biglead.live/login?",
    LINK_EXPORT:"https://api.shopf1.net/flow-api/download?path=",
        LINK_EXPORT_FLOW:"https://api.shopf1.net/transaction-export/flow/zns?path=",
    // LINK_EXPORT:"https://test.shopf1.net/flow-api/download?path=",
    CXVIEW_USERNAME: 'demo_shopf1@cxview.ai',
    CXVIEW_PASSWORD: 'demo@CXView2021',
    AUTO_INBOX_MESSAGE_TAG: [
        'CONFIRMED_EVENT_UPDATE',
        'POST_PURCHASE_UPDATE',
        'ACCOUNT_UPDATE',
    ],
    VERSION: 2,
    MODULES : {
        overview: 'overview',
        customers: 'customers',
        customer_tag: 'customer_tag',
        customer_care: 'customer_care',
        career: 'career',
        departments: 'departments',
        schedules: 'schedules',
        employee: 'employee',
        role: 'role',
        flows: 'flows',
        flow_tag: 'flow_tag',
        trigger: 'trigger',
        trigger_tag: 'trigger_tag',
        trigger_history: 'trigger_history',
        report_overview: 'report_overview',
        report_page: 'report_page',
        report_staff: 'report_staff',
        report_zns: 'report_zns',
        products: 'products',
        categories: 'categories',
        stocks: 'stocks',
        product_tag: 'product_tag',
        providers: 'providers',
        orders: 'orders',
        service_package: 'service_package',
        transporter: 'transporter',
        shop_info: 'shop_info',
        live_chat: 'live_chat',
        setting_page: 'setting_page',
        extra_column:'extra_column',
        integrated:'integrated'
    }
}
