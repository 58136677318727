import create from "zustand-vue";
import {Careers, Customer} from "@/models/Customers/Customers";
import {persist, createJSONStorage} from 'zustand/middleware'
import APIs from "@/APIs/APIs";
import {Tag} from "@/models/Tags/Tags";
import {CustomerCare} from "@/models/CustomerCares/CustomerCares";
import {CustomerConfigColumn} from "@/models/CustomerConfig/CustomerConfig";

interface CustomerState {
    getCustomerLoading: boolean
    customers: Customer[];
    career: Careers[];
    customerCares: CustomerCare[] ;
    extraColumn: CustomerConfigColumn[];
    metadata:any;
    // eslint-disable-next-line @typescript-eslint/ban-types
    GetCustomer:  (params: any,onSuccess?:Function)=>void;
    // eslint-disable-next-line @typescript-eslint/ban-types
    GetCareer: (params: any,onSuccess?:Function)=>void;
    // eslint-disable-next-line @typescript-eslint/ban-types
    GetCustomerCare: (onSuccess?:Function)=>void;
    // eslint-disable-next-line @typescript-eslint/ban-types
    GetExtraColumn: (params: any,onSuccess?:Function)=>void;
}

const initialState = {
    getCustomerLoading: true,
    customers: [],
    customerCares:[],
    metadata:{},
    career:[],
    extraColumn:[]
}


// @ts-ignore
const useCustomerStore = create<CustomerState>(persist((set) => ({
    ...initialState,

    GetCustomer: async (params,onSuccess) =>{
        set(()=>({getCustomerLoading: true}))

            const customers = await APIs.init().getCustomers(params)
            if(customers.data?.status===200){
                console.log(customers.data.data)
                set(()=>({getCustomerLoading: false, customers: customers.data?.data ? customers.data?.data  :[] }))
                // @ts-ignore

                onSuccess()
            }else{
                set(()=>({getCustomerLoading: false, customers:[]}))
            }


    },
    GetCareer: async (params, onSuccess) => {
        // set(() => ({getCareerLoading: true}))
        const response = await APIs.init().getCareers(params)

        if (response.data?.status === 200) {
            set(() => ( {career: response.data?.data ? response.data.data : []}))

        } else {
            set(() => ({ career: []}))
        }
        console.log('LoginEmail', response)
    },

        GetCustomerCare: async () => {

            const response = await APIs.init().getCustomerCares()

            if (response.data?.status === 200) {
                set(() => ({ customerCares: response.data?.data ? response.data.data : []}))

            } else {
                set(() => ({ customerCares: []}))
            }
            console.log('LoginEmail', response)
        },
    GetExtraColumn:async (params) => {
        const response = await APIs.init().getCustomerConfig(params)
        if (response.data?.status === 200) {
            set(() => ({ extraColumn: response.data?.data ? response.data.data : []}))

        } else {
            set(() => ({ extraColumn: []}))
        }
        console.log('LoginEmail', response)
    }

}),{
        name: 'customer'
    }
    )
)

export default useCustomerStore
