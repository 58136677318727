import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-80332036"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "title-flow-context"
}
const _hoisted_2 = ["src"]
const _hoisted_3 = { class: "preview-container" }
const _hoisted_4 = { class: "post-back-button" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_button = _resolveComponent("a-button")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (!_ctx.type)
      ? (_openBlock(), _createElementBlock("h3", _hoisted_1, _toDisplayString(_ctx.$t('FLOW_PREVIEW')), 1))
      : _createCommentVNode("", true),
    _createElementVNode("iframe", {
      style: {"width":"100%","min-height":"480px"},
      src: _ctx.flowContext.preview_url,
      frameborder: "0"
    }, null, 8, _hoisted_2),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("div", _hoisted_4, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.postBacks, (item, index) => {
          return (_openBlock(), _createBlock(_component_a_button, {
            class: "button-post-back",
            key: index+'_'
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(item.title), 1)
            ]),
            _: 2
          }, 1024))
        }), 128))
      ])
    ])
  ], 64))
}