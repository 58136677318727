<template>
  <div class="container">
    <div class="main-header">
      <div class="live-chat-button">
        <a class="btn active"> <ToolOutlined style="font-size: 22px"/> {{$t('CRM_MANAGER')}}
          </a>
      </div>
      <a-badge v-if="shopActive && roleLiveChat">
        <a :href="goChatBeta()" class="btn"> <MessageOutlined  style="font-size: 22px"/> Live Chat
        </a>
      </a-badge>
<!--      <a-badge v-if="shopActive && roleLiveChat">-->
<!--        <template #count>-->
<!--          <span style="color: white; margin-top: 16px; background-color: #FAB216; border-radius: 8px; font-size: 12px; padding: 4px " >Cũ</span>-->
<!--        </template>-->
<!--        <a :href="goChat()" class="btn"><message-outlined  style="font-size: 22px"/>Live chat</a>-->
<!--      </a-badge>-->
<!--      <a-badge>-->
<!--        <a :href="goMiniAppZalo()" class="btn"> <ExperimentOutlined  style="font-size: 22px"/>MiniApp Zalo-->
<!--        </a>-->
<!--      </a-badge>-->
    </div>
    <right-content class="right-content"/>
  </div>
</template>

<script lang="ts">
import RightContent from "./RightContent.vue";
import {MixUtils, MSocketIo} from "@/utils/Utils";
import {Notification} from "@/models/notification/Notification"
import {notification} from "ant-design-vue";
import {computed, defineComponent, onBeforeMount, ref, watch} from "vue";
import {useI18n} from "vue-i18n";
import {useStore} from "vuex";
import APIs from "@/APIs/APIs";
import {UsersMutationTypes} from "@/store/modules/user/mutation-types";
import RouterEndpoint from "@/router/RouterEndpoint";
import {useRouter} from "vue-router";
import {MessageOutlined, BarcodeOutlined, ToolOutlined, ExperimentOutlined} from '@ant-design/icons-vue';
import dayjs from "dayjs";
import { Constant } from "@/utils/Constants";
import {EmployeeActionTypes} from "@/store/modules/employee/action-types";
import useUserStore from "../../store/User";
export default defineComponent({
  name:"shop-header",
  components:{
    RightContent,
    MessageOutlined,
    ToolOutlined,
    // ExperimentOutlined
  },
  setup(){
    const i18n=useI18n()
    const store=useStore()
    const router=useRouter()
    const getOneEmployeeNameById = (id:number):string=>{
      const user=store.getters.getOneEmployeeById(id);
      if (user){
        return user.name
      }
      return ""
    }
    const fetchEmployee = () => {
      store.dispatch(EmployeeActionTypes.FETCH_EMPLOYEE, {
        page: 1,
        page_size: 999,
      });
    };
    const messageContent=(msg:string)=>{
      return i18n.t("REMINDER_NOTIFICATION_CONTENT").replace("##message##",msg)
    }
    const goChat = () => {
      let href = ''
      try{
        if (store.getters.getUserInfo){
         href = MixUtils.getChatUrl() + "?user_id=" + store.getters.getUserInfo?.id + "&token_login=" +store.getters.getUserInfo?.token_login + "&token_web=" + store.getters.getUserInfo?.token_web + "&shop_id=" + store.getters.getCurrentShop?.id;
          if (store.getters.getCurrentShop&&store.getters.getCurrentShop.pages&&store.getters.getCurrentShop.pages.length){
            href+="&page_id=" + store.getters.getCurrentShop.pages[0].page_id
          }
        }
      }catch (e) {
        console.log(e)
      }
      return href
    };
    const goChatBeta = () => {
      let href = ''

      try{
        if (useUserStore.getState().userInfo){
          href = "https://chatnew.biglead.live/login?user_id=" + useUserStore.getState().userInfo?.id + "&token_login=" +useUserStore.getState().userInfo?.token_login + "&token_web=" + useUserStore.getState().userInfo?.token + "&shop_id=" + useUserStore.getState().currentShop?.id;
          // @ts-ignore
          if (useUserStore.getState().currentShop&&useUserStore.getState().currentShop.pages&&useUserStore.getState().currentShop.pages.length){
            // @ts-ignore
            href+="&page_id=" + useUserStore.getState().currentShop.pages[0].page_id
          }
        }
      }catch (e) {
        console.log(e)
      }
      return href
    };
    const goMiniAppZalo = () => {
      let href = ''

      try{
        if (store.getters.getUserInfo){
          href = "https://miniapp.biglead.live/login?user_id=" + store.getters.getUserInfo?.id + "&token_login=" +store.getters.getUserInfo?.token_login + "&token_web=" + store.getters.getUserInfo?.token_web + "&shop_id=" + store.getters.getCurrentShop?.id;
          if (store.getters.getCurrentShop&&store.getters.getCurrentShop.pages&&store.getters.getCurrentShop.pages.length){
            href+="&page_id=" + store.getters.getCurrentShop.pages[0].page_id
          }
        }
      }catch (e) {
        console.log(e)
      }
      return href
    };


    const shopActive = ref(true)
    const shopInfo = computed(() => store.getters.getShopinfo);
    const userInfo = computed(()=> store.getters.getUserInfo)
    const roleLiveChat = computed(() => {
      if(userInfo.value?.id_cms_privileges<11){
        return ()=>({
          is_visible: 1,
          is_create:1,
          is_read: 1,
          is_edit: 1,
          is_delete: 1,
          id: 1
        })
      }else{
        return store.getters.getRole(Constant.MODULES.live_chat)
      }
    });

    watch(shopInfo, (shopInfo) => {
      shopActive.value = shopInfo.shop_services.status == 'Kích hoạt' && dayjs(shopInfo.shop_services.end_date).diff(dayjs(), 'seconds')>0
    })

    const goPOS = () => {
      let href = ''
      if (store.getters.getUserInfo){
         href = MixUtils.getPOSUrl() + "?email=" + store.getters.getUserInfo.email + "&token_pos=" +store.getters.getUserInfo.token_pos + "&token_web=" + store.getters.getUserInfo.token_web;

      }
      return href
    };



    onBeforeMount(()=>{
      MSocketIo.getInstance().socket.on("connect", () => {
        MSocketIo.getInstance().socket.on("NOTIFICATION",(data:Notification)=>{
          console.log("NOTIFICATION",data)
          notification.open({
            message: i18n.t('REMINDER_NOTIFICATION_FROM') +" "+getOneEmployeeNameById(data.creator),
            description:
            messageContent(data.message),
            onClick: () => {
              APIs.init().readNotification(data._id).then()
              store.commit(UsersMutationTypes.READ_NOTIFICATION,data._id)
              if (data.customer_ids&&data.customer_ids.length){
                router.push({name:RouterEndpoint.CUSTOMER_DETAIL_EDIT.name,params:{id:data.customer_ids[0]}})
              }
            },
          });
        })
      });
      fetchEmployee()
    })
    return{
      goMiniAppZalo,
      goChatBeta,
      goChat,
      shopActive,
      roleLiveChat,
      goPOS
    }
  }
})

</script>
<style scoped lang="scss">
  .container{
    display: flex;
    flex: 1;
    height: 100%;
    flex-direction: row;
    background: #424242;
  }
  .main-header{
    flex: 1;
    flex-direction: row;
    display: flex;
    justify-content: center;
  }
  .right-content{
    margin-right: 20px;
  }
  .title-header{
    font-weight: bold;
    color: white;
  }
  .btn {
    font-weight: bold;
    height: 100%;
    padding-left: 16px;
    padding-right: 16px;
    color: white;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
  }
  .active {
    color: var(--primary-color);
    background-color: #333333
  }
  .btn:hover{
    font-weight: bold;
    height: 100%;
    padding-left: 16px;
    padding-right: 16px;
    color: var(--primary-color);
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
  }
</style>
