<template>
  <div class="container-title">
    <h3 class="title-header">{{$t($route.name)}}</h3>
  </div>

  <a-card class="container-content">
    <h3 class="title-header">Tải Auto Inbox</h3>
    <div style="display: flex; align-items: center">
      <span>Đây là công cụ extension được sử dụng để mở rộng một số tính năng đặc biệt của Biglead như gửi tin nhắn không giới hạn cho khách hàng, quét số điện thoại, mở facebook của khách hàng ngay trên Biglead ...</span>


    </div>
    <br/>
    <span>Bạn nhấn vào<a target="_blank" href="https://chromewebstore.google.com/detail/support-send-message-bigl/llfmbflhihmggckamnagglnbchdkolbe?hl=vi">(Đây: https://chromewebstore.google.com/detail/support-send-message-bigl/llfmbflhihmggckamnagglnbchdkolbe?hl=vi )</a> để cài đặt extension.</span>

    <a-typography-text strong> Lưu ý: Extension chỉ hỗ trợ trên trình duyệt Google Chrome và Cốc Cốc.</a-typography-text>

  </a-card>
</template>

<script lang="ts">
import {computed, defineComponent, inject, onMounted, onUnmounted, reactive, ref, watch} from "vue";
import {useStore} from "@/store";

import {create, all} from 'mathjs'
import {useRoute, useRouter} from "vue-router";
import {useI18n} from "vue-i18n";

const config = {}
const math = create(all, config)

export default defineComponent({
  name: "AutoInbox",
  components: {
  },

  setup() {
    const store = useStore()
    const i18n = useI18n()
    const route = useRoute()




    return {

    }
  }
})
</script>

<style scoped lang="scss">
.body-upgrade-service-pack {
  margin-top: 16px;

}
.container-content{
  margin: 16px;
}

</style>
