<template>
  <div class="container-title">
    <h3 class="title-header">{{$t($route.name)}}</h3>
    <div class="statistic-zns">
      <a-statistic title="Transactions" :value="walletStore.znsTransCount" class="statistic-bar-right"/>
      <a-statistic title="Total amount" :value="walletStore.znsTransTotalAmount" class="statistic-bar-right"/>
    </div>
    <a-button @click="filterExpanded=!filterExpanded" type="link">{{$t('CUSTOMER_FILTER')}}<DownOutlined /></a-button>

  </div>


  <a-card style="margin-left: 16px; margin-right: 16px" v-if="filterExpanded">
  <div class="filter-bar">
    <a-form
        layout="inline"
    >
      <a-form-item>
        <div class="filter-form-label">
          Page
        </div>
<!--        <a-select-->
<!--            placeholder="Select your page"-->
<!--            :options="pagesSelection.currentShop.pages"-->
<!--            v-model:value="selectedPage"-->
<!--            @change="handlePageChange"-->
<!--            style="width: 220px"-->
<!--        >-->
<!--        </a-select>-->
        <a-select
            allowClea
            show-search
            style="width: 220px"
            placeholder="Please select"
            @change="handlePageChange"
            v-model:value="selectedPage"
        >
          <a-select-option
              v-for="item in pagesSelection.overView"
              :key="item.page_id"
              v-bind:value="item.page_id"
          > <img
              v-if="item.page_type === 2"
              draggable="false"
              src="@/assets/icons/zalo-seeklogo.com.svg"
              style="width: 20px;margin-right: 20px"
          />
            <img
                v-else-if="item.page_type === 1"
                draggable="false"
                src="@/assets/icons/facebook-svgrepo-com.svg"
                style="width: 20px;margin-right: 20px"
            />
            <img
                v-else-if="item.page_type === 3"
                draggable="false"
                src="@/assets/icons/instagram-svgrepo-com.svg"
                style="width: 20px;margin-right: 20px"
            />
            <img
                v-else-if="item.page_type === 4"
                draggable="false"
                src="@/assets/icons/line-svgrepo-com.svg"
                style="width: 20px;margin-right: 20px"
            />{{ item.page_name }}
          </a-select-option>
        </a-select>
      </a-form-item>
      <a-form-item>
        <div class="filter-form-label">
          Trigger
        </div>
        <a-select
            placeholder="Select trigger"
            show-search
            :options="triggersSelection"
            v-model:value="selectedTrigger"
            @change="handleTriggerChange"
            @search="handleTriggerSearchChange"
            style="width: 220px"
        >
        </a-select>
      </a-form-item>
      <a-form-item>
        <div class="filter-form-label">
          Flow
        </div>
        <a-select
            placeholder="Select flow"
            :options="flowsSelection"
            v-model:value="selectedFlow"
            show-search
            @change="handleFlowChange"
            @search="handleFlowSearchChange"
            style="width: 220px"
        >
        </a-select>
      </a-form-item>
      <a-form-item>
        <div class="filter-form-label">
          Status
        </div>
        <a-select
            placeholder="Tags Mode"
            v-model:value="selectedIsSuccess"
            @change="handleContextFlowChange"
            style="width: 220px"
            :allowClear="true"
        >
          <a-select-option v-for="i in statusSelection" :key="i" :value="i.value">
            {{i.title}}
          </a-select-option>
        </a-select>
      </a-form-item>
      <a-form-item>
        <div class="filter-form-label">
          {{$t('RANGED_DATE')}}
        </div>
        <a-range-picker
            style="width: 400px"
            v-model:value="rangeDate"
            :ranges="ranges"
            :format="dateFormat"
            show-time
            @change="rangeDateChange"
        />
      </a-form-item>
     <a-form-item>
       <a-button
           style="margin-top: 30px"
           @click="downloadSampleFile">
         <template #icon>
           <file-excel-outlined/>
         </template>
         Export
       </a-button>
     </a-form-item>
    </a-form>

  </div>
  </a-card>
  <a-table @change="handleTableChange" :columns="columns" :data-source="walletStore.znsTransactions" class="table-transaction" :pagination="pagination">

    <template #customer_name="{record}" >
      <a-row>
        <router-link style="color:#FAB216" :to="`/customer/${record.customer_id}`">{{record.customer_name}}</router-link>
      </a-row>

      <a-typography-text>{{record.phone_number}}</a-typography-text>

    </template>
    <template #result="{record}" >
      {{record.is_success  ? 'success' : 'error' }}
    </template>
    <template #action="{record}">
      <a-tooltip :title="$t('ERROR_LOG')">
        <warning-outlined @click="viewErrorLog(record)"/>
      </a-tooltip>
    </template>
  </a-table>
  <a-modal v-model:visible="errorLogVisible" :title="$t('ERROR_LOG_VIEWER')" :footer="null">
    <a-spin v-if="errorLogsLoading"/>
    <VirtualList v-else
                 class="scroller"
                 :size="30" :remain="20"
    >
      <a-timeline>

        <a-timeline-item>
          {{errorLog}}

        </a-timeline-item>
      </a-timeline>
    </VirtualList>

  </a-modal>

</template>

<script lang="ts">
import {computed, defineComponent, onBeforeMount, ref} from "vue";
import RouterEndpoint from "@/router/RouterEndpoint";
import {FileExcelOutlined} from "@ant-design/icons-vue";
import {useStore} from "@/store";
import {FlowZNS_TransactionRequest} from "@/APIs/request/FlowZNS_TransactionRequest";
import {WalletActionTypes} from "@/store/modules/wallet/action-types";
import {TriggerActionTypes} from "@/store/modules/trigger/action-types";
import {FlowActionTypes} from "@/store/modules/flow/action-types";
import {useRoute, useRouter} from "vue-router";
import moment, {Moment} from "moment";
import {useI18n} from "vue-i18n";
import dayjs, {Dayjs} from "dayjs";
import {TriggerStatistic, TriggerStatisticErrorLog} from "@/models/Trigger/TriggerHistory";
import {WarningOutlined} from "@ant-design/icons-vue";
import APIs from "@/APIs/APIs";
import {ApiResponse} from "apisauce";
import {WalletTransactionsResponse} from "@/APIs/response/S_HttpResponse";
import {message} from "ant-design-vue";
import {Constant} from "@/utils/Constants";
import useReportPageStore from "../../../store/ReportPage";
import useWalletStore from "../../../store/Wallet";
import useUserStore from "../../../store/User";
import useOverviewStore from "@/store/Overview";

const data = [
  {
    key: '1',
    _id: '13123231',
    money_change: '300',
    transaction_type: 'ZNS_CHARGED',
    note: 'ZNS paid 300 vnd for template "1234" was sent to Thu Ky Lee(13244)',
    extra_info: "",
    weight: 100,
    created_at: '24-02-2022',
  },
  {
    key: '2',
    _id: '13123231',
    money_change: '300',
    transaction_type: 'ZNS_CHARGED',
    note: 'ZNS paid 300 vnd for template "1234" was sent to Thu Ky Lee(13244)',
    extra_info: "",
    weight: 100,
    created_at: '24-02-2022',
  },
  {
    key: '3',
    _id: '13123231',
    money_change: '300',
    transaction_type: 'ZNS_CHARGED',
    note: 'ZNS paid 300 vnd for template "1234" was sent to Thu Ky Lee(13244)',
    extra_info: "",
    weight: 100,
    created_at: '24-02-2022',
  },
  {
    key: '4',
    _id: '13123231',
    money_change: '300',
    transaction_type: 'ZNS_CHARGED',
    note: 'ZNS paid 300 vnd for template "1234" was sent to Thu Ky Lee(13244)',
    extra_info: "",
    weight: 100,
    created_at: '24-02-2022',
  },
]
let triggerSearchTimeout: number;
let flowsSearchTimeout: number;
let searchTimeout: number;
const dateFormat = 'DD-MM-YYYY HH:mm:ss';
export default defineComponent({
  components: {
    WarningOutlined
    // FileExcelOutlined
  },
  setup() {
    const store = useStore()
    const router = useRouter()
    const route = useRoute()
    const i18n = useI18n()
    const walletStore = useWalletStore()
    console.log(walletStore,'111')
    const totalMount = computed(() => store.getters.getFlowZNS_TransactionTotalAmount)
    const transCount = computed(() => store.getters.getFlowZNS_TransactionCount)
    const pagesSelection = useOverviewStore()
    const triggersSelection = computed(() => store.getters.getTriggersSelection)
    const flowsSelection = computed(() => store.getters.getFlowsSelectionAnt)
    const flowContextsSelection = computed(() => store.getters.getFlowContextSelectionAnt)
    const userInfo = computed(()=>store.getters.getUserInfo)
    const errorLogVisible = ref(false)
    const errorLogsLoading = ref(false)
    const errorLog = ref('')
   const viewErrorLog = (history: any) => {
      errorLogVisible.value = true;
      errorLogsLoading.value=true
     if(!history.is_success){
       errorLog.value = history.result
       errorLogsLoading.value=false
     }else {
       errorLogsLoading.value=false
     }


    }
    const statusSelection = ref([{
      title: 'success',
      value: 1
    },
      {
        title: 'error',
        value: -1
      }])
    const isFlowContextSelection = ref(true)
    const selectedPage = ref('0')
    const selectedTrigger = ref('0')
    const selectedFlow = ref('0')
    const selectedFlowContext = ref('0')
    const selectedIsSuccess = ref<boolean>()
    const rangeDate = ref<Dayjs[]>([])
    let page=1;
    const columns=[
      {
        title: i18n.t('MONEY_CHANGE'),
        dataIndex: 'amount',
        key: 'amount',
      },
      {
        title: i18n.t('CUSTOMER'),
        key: 'customer_name',
        dataIndex: 'customer_name',
        slots: {customRender:'customer_name'}
      },
      {
        title: 'Trigger',
        key: 'trigger_name',
        dataIndex: 'trigger_name',
      },
      {
        title: 'Flow',
        key: 'flow_name',
        dataIndex: 'flow_name',
      },
      {
        title: 'Template',
        key: 'template_name',
        dataIndex: 'template_name',
      },
      {
        title: 'result',
        key: 'result',
        dataIndex: 'result',
        slots: {customRender:'result'}
      },
      {
        title: i18n.t('CREATED_TITLE'),
        key: 'created_at_str',
        dataIndex: 'created_at_str',

      },
      {
        slots: {customRender: 'action'},
      },
    ]
    const request = ref<FlowZNS_TransactionRequest>({
      page: page,
      page_size: 20
    })
    const fetchData = () => {
      useWalletStore.getState().getFlowZNS_Transactions(request.value)
      // store.dispatch(WalletActionTypes.GET_FLOW_ZNS_TRANSACTIONS, request.value)
    }
    const replaceQuery = () => {
      router.replace({
        name: RouterEndpoint.REPORT_ZNS_TRANSACTION.name, query: {
          page_id: selectedPage.value ? selectedPage.value : '',
          trigger_id: selectedTrigger.value ? selectedTrigger.value : '',
          flow_id: selectedFlow.value ? selectedFlow.value : '',
          flow_context_id: selectedFlowContext.value ? selectedFlowContext.value : '',
          from: rangeDate.value && rangeDate.value.length > 1 ? rangeDate.value[0].toISOString() : '',
          to: rangeDate.value && rangeDate.value.length > 1 ? rangeDate.value[1].toISOString() : '',
        }
      })
    }
    const downloadSampleFile = async () => {
      const mess= message.loading('Export in progress..', 0)
      APIs.init().getflowExportTransactions({page:1,page_size:9999,from:request.value.from,to:request.value.to}).then((response:ApiResponse<WalletTransactionsResponse>)=>{
        mess()
        if (response.ok){
          message.error('Success');
          window.open(Constant.LINK_EXPORT+ response.data?.data);
        }else {
          message.error('This is an error message: '+response.data?.message);
        }
      }).catch((e:any)=>{
        mess()
        message.error('This is an error message: '+JSON.stringify(e));
        console.error("getWalletExportTransactions err",e)
      })

    }
    const pagination = computed(() => {
      return {
        current: request.value.page ? request.value.page : 1,
        pageSize: request.value.page_size ? request.value.page_size : 20,
        defaultPageSize: 20,
        total: transCount.value ? transCount.value : 0,
        pageSizeOptions: ['20', '40', '80', '120'],
        showSizeChanger: true
      }
    })
    const handlePageChange = (val: string) => {
      selectedPage.value = val === '0' ? '' : val
      replaceQuery()
      handleSearch()
      console.log(val)
    }

    const handleTriggerSearchChange = (val: string) => {
      if (triggerSearchTimeout) {
        clearTimeout(triggerSearchTimeout)
      }
      triggerSearchTimeout = window.setTimeout(() => {
        store.dispatch(TriggerActionTypes.FETCH_TRIGGERS, {search: val})
      }, 500)
    };
    const handleTableChange = (pagination: any) => {
      // expandLoading.value = true
      if (pagination?.current) {
        request.value.page = pagination?.current
      }
      if (pagination?.pageSize) {
        request.value.page_size = pagination?.pageSize
      }
      fetchData()
    }
    const handleTriggerChange = (val: string) => {
      console.log(val)
      selectedTrigger.value = val === '0' ? '' : val
      replaceQuery()
      handleSearch()
    }
    const handleFlowSearchChange = (val: string) => {
      if (flowsSearchTimeout) {
        clearTimeout(flowsSearchTimeout)
      }
      flowsSearchTimeout = window.setTimeout(() => {
        store.dispatch(FlowActionTypes.FETCH_FLOWS, {search: val})
      }, 500)
    };
    const handleContextFlowChange = (val: string) => {
      console.log(val)
      replaceQuery()
      handleSearch()
    };
    const handleFlowChange = (val: string) => {
      console.log('handleFlowChange',val)
      isFlowContextSelection.value = val === '0';
      selectedFlow.value = val === '0' ? '' : val
      if (val !== '0') {
        store.dispatch(FlowActionTypes.FETCH_FLOW_CONTEXT, {id: parseInt(val)})
      }
      replaceQuery()
      handleSearch()
    }
    const rangeDateChange = (value: Dayjs[], dateString: string[]) => {
      console.log('Selected Time: ', value);
      console.log('Formatted Selected Time: ', dateString);
      replaceQuery()
      handleSearch()
    }

    const handleSearch = () => {
      if (searchTimeout) {
        clearTimeout(searchTimeout)
      }
      searchTimeout = window.setTimeout(() => {
        request.value.page_id = selectedPage.value !== '0' && selectedPage.value !== '' ? selectedPage.value : ''
        request.value.flow_id = selectedFlow.value !== '0' && selectedFlow.value !== '' ? selectedFlow.value : ''
        request.value.trigger_id = selectedTrigger.value !== '0' && selectedTrigger.value !== '' ? selectedTrigger.value : ''
        request.value.is_success = selectedIsSuccess.value
        if (rangeDate.value.length > 1) {
          request.value.from = rangeDate.value[0].toDate()
          request.value.to = rangeDate.value[1].toDate()
        } else {
          delete request.value.from
          delete request.value.to
        }
        fetchData()
      }, 500)
    }

    onBeforeMount(() => {
      selectedPage.value = route.query.page_id as string;
      selectedTrigger.value = route.query.trigger_id as string;
      selectedFlow.value = route.query.flow_id as string;
      selectedFlowContext.value = route.query.flow_context_id as string;
      store.dispatch(TriggerActionTypes.FETCH_TRIGGERS, {})
      store.dispatch(FlowActionTypes.FETCH_FLOWS, {})
      if (selectedFlow.value !== '' && selectedFlow.value !== '0') {
        isFlowContextSelection.value = false
        store.dispatch(FlowActionTypes.FETCH_FLOW_CONTEXT, {id: parseInt(selectedFlow.value)})
      } else {
        isFlowContextSelection.value = true
      }
      if (route.query.from !== '' && route.query.to !== '') {
        rangeDate.value = [dayjs(route.query.from as string).subtract(1,'months'), dayjs(route.query.to as string)]
        console.log("rangeDate", rangeDate.value)
      }else {
        rangeDate.value=[]
      }
      handleSearch()
    })

    const filterExpanded = ref(false)
    return {
      walletStore,
      filterExpanded,
      columns,
      data,
      totalMount,
      pagesSelection,
      pagination,
      transCount,
      triggersSelection,
      RouterEndpoint: RouterEndpoint,
      options: [...Array(25)].map((_, i) => ({value: (i + 10).toString(36) + (i + 1)})),
      fetchData,
      flowsSelection,
      handleTriggerSearchChange,
      handleFlowSearchChange,
      handleFlowChange,
      flowContextsSelection,
      isFlowContextSelection,
      handlePageChange,
      handleTriggerChange,
      selectedPage,
      selectedTrigger,
      selectedFlow,
      selectedFlowContext,
      handleContextFlowChange,
      ranges: {'Today': [moment(), moment()], 'This Month': [moment(), moment().endOf('month')]},
      handleTableChange,
      rangeDate,
      dateFormat,
      rangeDateChange,
      statusSelection,
      viewErrorLog,
      errorLogVisible,
      errorLogsLoading,errorLog,
      selectedIsSuccess,
      downloadSampleFile
    }
  }
})
</script>

<style scoped lang="scss">
.header-container {
  display: flex;
  flex-wrap: wrap;

  .item-filter {
    display: flex;
    flex-direction: column;

    .filter-by-date-label {
      margin-bottom: 4px;
    }
  }

}

.filter-bar {
  display: flex;
  flex-wrap: wrap;

  .filter-form-label {
    margin-top: 8px;
  }


}
.statistic-zns {
  display: flex;
  flex-direction: row;
  gap: 16px

}

.table-transaction {
  margin: 16px;
}

.title-header {
  font-weight: bold;
  flex: 1;
}
.container-title{
  display: flex;
  flex-direction: row;
  padding: 16px 24px 16px 24px;
  margin-bottom: 25px;
  background-color: white
}
.input-search{
  width: 250px;
  margin-right: 16px;
}

</style>
