<template>
  <h3 class="title-flow-context">
    {{ $t('FLOW_PREVIEW') }}
  </h3>
  <div class="preview-container">
    <div v-for="message in messageContexts" :key="message.id">
      <div v-if="message.type==='text'">
        <div class="content-message">
          {{ message.text }}
        </div>
        <div class="post-back-button">
          <a-button class="button-post-back" v-for="button in message.buttons" :key="button.id">{{ button.title }}
            <div style="display: flex; width: 30px; height: 40px; position: absolute; right: -16px; top: 10px; align-items: center; justify-content: center">
<!--              <Handle :id="button.id" type="source" class="handle"   />-->
            </div>

          </a-button>

        </div>
      </div>
      <div v-else-if="message.type==='image'&&message.images&&message.images.length>0">
        <div class="content-message">
          <img :src="message.images[0]" class="img-message"/>
        </div>
      </div>
      <div v-else-if="message.type==='generic_template'">
        <div class="content-message">
          <Carousel>
            <Slide v-for="temp in message.facebook_templates" :key="temp.id">
              <a-card :hoverable="true" size="small" class="card-facebook-template">
                <template #cover>
                  <div>
                    <img class="img-cover-facebook-template" alt="example" v-if="temp.image_url"
                         :src="temp.image_url" />

                  </div>
                </template>
                <a-card-meta :title="temp.title" :description="temp.subtitle">
                </a-card-meta>
                <div class="post-back-button">
                  <template  v-for="button in temp.buttons" :key="button.id">
                  <a-button class="button-post-back">{{
                      button.title }}
<!--                    <HandleCustom :data="button" type="123123"  />-->
                  </a-button>
                  </template>
                </div>
              </a-card>
            </Slide>

            <template #addons>
              <Navigation/>
              <Pagination/>
            </template>
          </Carousel>
        </div>
      </div>
      <div v-else-if="message.type==='quick_replies'">
        <div class="content-message">
          {{ message.text }}
        </div>
        <div class="button-quick">
          <div class="button-quick-reply" v-for="btn in message.quick_replies" :key="btn.id">
            <img v-if="btn.image_url" class="button-quick-reply-img" :src="btn.image_url"/>
            {{ btn.title }}
          </div>
        </div>
      </div>

      <div v-if="message.type==='onetime_notification'">
        <div class="content-message">
          <div class="title-msg-onetime-notification">{{$t('GET_NOTIFIED')}}: {{ message.text }}</div>
          <p>
           {{$t('ONE_TIME_NOTIF')}}
          </p>
        </div>
        <div class="post-back-button">
          <a-button class="button-post-back" v-for="button in message.buttons" :key="button.id">{{$t(button.title) }}
          </a-button>
        </div>
      </div>

    </div>
  </div>
</template>

<script lang="ts">
import {defineComponent, ref, watch} from "vue";
import {MessageContext, PostbackContext} from "@/models/Flows/FlowContext";
import 'vue3-carousel/dist/carousel.css';
// @ts-ignore
import {Carousel, Slide, Pagination, Navigation} from 'vue3-carousel';
import HandleCustom from "@/views/Marketing/Automation/HandleCustom.vue";
import {Position, VueFlow, useVueFlow, Handle, MarkerType} from '@vue-flow/core'

export default defineComponent({
  name: "facebook-post-back-preview",
  props: ['flowContext'],
  components:{
    Carousel,
    Slide,
    Navigation,
    // HandleCustom,
    // Handle,
    Pagination
  },
  setup(props) {
    const messageContexts = ref<MessageContext[]>([])
    watch(() => props.flowContext, () => {
      updateProps()
    })
    const updateProps = () => {
      messageContexts.value = props.flowContext.message_contexts ? props.flowContext.message_contexts : []
    }
    const getStyleButton=(url:string)=>{
      if (url){
        return {backgroundImage:'url('+url+')',backgroundSize:'cover'}
      }
      return{

      }
    }
    updateProps()
    return {
      messageContexts,
      Position,
      getStyleButton
    }
  }
})
</script>

<style lang="scss" scoped>
.preview-container {
  //background-color: white;
  border-radius: 10px;
  padding: 15px;
}

.content-message {
  margin-right: 20px;
  padding: 15px;
  margin-top: 12px;
  background-color: #E1E1E1;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  border-bottom-right-radius: 15px;
  white-space: pre-line;
}
.title-msg-onetime-notification{
  font-weight: bold;
}

.post-back-button {
  margin-top: 10px;
  text-align: center;

  .button-post-back {
    margin: 5px 10px;
  }
}

.title-flow-context {
  font-weight: bold;
}

.img-cover-facebook-template{
  max-height: 200px;
  width: 100%;
  object-fit: cover;
}
.card-facebook-template{
  text-align: left;
  width: 250px;
}

.img-message {
  width: 100%;
  object-fit: cover;
}
.quick-reply-button{
  border-radius: 25px;
  border-color: #1890ff;
  color: #1890ff;
}
.button-quick{
  text-align: center;
  .button-quick-reply {
    margin: 5px 10px;
    border-radius: 25px;
    color: #1890ff;
    border: 1px solid #1890ff;
    display: inline-block;
    padding: 4px 7px;
    cursor: pointer;
    .button-quick-reply-img{
      width: 24px;
      height: 24px;
      border-radius: 20px;
    }
  }
}


</style>
