<template>
  <div class="container-title">
    <h3 class="title-header">{{ $t($route.name) }}</h3>

  </div>
  <div class="container">
    <a-card style="border-radius: 10px;margin: auto;width: 95%">
    <a-row justify="space-between">
      <a-typography-text style="display: block;margin-bottom: 20px">{{$t('MEMBER_RANK')}}</a-typography-text>
      <a-button @click="onSubmit" type="primary ">
        {{$t('SAVE')}}
      </a-button>
    </a-row>
      <a-form  :label-col="{ span: 8 }"
               :wrapper-col="{ span: 16 }"
               layout="vertical"
               :model="formState"
               :rules="rules"
               ref="formRef"
      >
        <a-row>
        <a-col :span="12">
          <a-form-item
              :label="$t('RANK_ID')"
              name="rank_id"
          >
            <a-input :disabled="checkEdit ? true : false" @change="changeRankId" v-model:value="formState.rank_id" />
          </a-form-item>
        </a-col>
          <a-col :span="12">
            <a-form-item
                :label="$t('MEMBER_RANK')"
                name="rank_name"
            >
              <a-input v-model:value="formState.rank_name" />
          </a-form-item>
          </a-col>
        </a-row>
        <a-row>
          <a-col :span="12">
            <a-form-item
                :label="$t('RATE')"
                name="accumulate_points"
            >
              <a-input-number   v-model:value="formState.accumulate_points"  style="width: 100%">
                <template #addonAfter>
                  {{'%'+$t('BILL_INVOICE')}}
                </template>
              </a-input-number>
              <a-typography-text v-if="formState.accumulate_points">{{`Hóa đơn 100,000đ khách hàng tích được ${getPoint(formState.accumulate_points)} điểm`}}</a-typography-text>


            </a-form-item>
            <a-row>
<!--              <a-button type="primary">-->
<!--                <router-link to="/voucher/:id">-->
<!--                  {{$t('CREATE_VOUCHER')}}-->
<!--                </router-link>-->
<!--              </a-button>-->
            </a-row>
          </a-col>
        </a-row>
        <a-typography-text style="display: block;margin-bottom: 20px;margin-top: 20px">{{$t('QUALIFICATIONS_FOR_RANKING')}}</a-typography-text>
        <a-row>
          <a-col :span="12">
            <a-form-item
                :label="$t('EXCESS_ACCUMULATED_MONEY')"
                name="accumulated_money"
            >
              <a-input-number :formatter="value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')" v-model:value="formState.accumulated_money">
                <template #addonAfter>
                  {{'VNĐ'}}
                </template>
              </a-input-number>
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-form-item>
              <a-radio-group style="display: flex;flex-direction: column" v-model:value="formState.config">
                <a-radio style="margin-bottom: 10px" :value="'MAINTAIN'">{{$t('MAINTAIN_ACCUMULATED_MONEY')}}</a-radio>
                <a-radio :value="'MINUS'">
                  <a-input-number :formatter="value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')" style="margin-bottom: 10px" v-model:value="formState.minus" :addon-before="$t('MINUS_ACCUMULATED_MONEY')" addon-after="VNĐ" />
                </a-radio>
                <a-radio :value="'RESET'">
                  <a-input-number :formatter="value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')" v-model:value="formState.reset" :addon-before="$t('RESET_ACCUMULATED_MONEY_TO')" addon-after="VNĐ" />

                </a-radio>
              </a-radio-group>
            </a-form-item>
          </a-col>
        </a-row>
<!--        <a-row style="cursor: pointer" @click="showCyclical  =!showCyclical">-->
<!--          <a-typography-text strongs style="color: #FAB216" >{{$t('CYCLICAL_TIER_REVIEW')}}</a-typography-text>-->
<!--          <caret-down-outlined style="color: #FAB216" v-if="showCyclical === false" />-->
<!--          <caret-up-outlined  style="color: #FAB216" v-else/>-->
<!--        </a-row>-->
        <div v-if="showCyclical">
          <a-form-item :label="$t('REVIEW_SPENDING_NORMS')">
            <a-input-number v-model:value="config.check_spending_limit" :formatter="value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')" style="width: 50%"   addon-after="VNĐ" />
          </a-form-item>
          <a-typography-title :level="5">{{$t('IF_THE_SPENDING_LIMIT_IS_NOT_MET_RE-EVALUATE_THE_RANK')}}</a-typography-title>
          <a-row>
           <a-col :span="12">
             <a-form-item :label="$t('MEMBER_RANK_CHANGE')">
               <a-radio-group style="display: flex;flex-direction: column"  v-model:value="config.achieved.config_rank">
                 <a-radio style="margin-bottom: 10px" :value="'maintain'">{{$t('MAINTAIN_ACCUMULATED_MONEY')}}</a-radio>
                 <a-radio style="width: 100%" :value="'not_maintain'">
                   <a-input   disabled="true" :value="$t('LOWER_IN_RANK')">
                     <template #addonAfter>
                       <a-select v-model:value="config.achieved.downgrade" style="width: 250px">
                         <a-select-option v-for="i in ranks.memberRank" :key="i" :value="i">{{$t(i.rank_name)}}</a-select-option>

                       </a-select>
                     </template>
                   </a-input>


                 </a-radio>

               </a-radio-group>
             </a-form-item>
           </a-col>
            <a-col :span="12">
              <a-form-item :label="$t('CHANGE_ACCUMULATE_MONEY')">
                <a-radio-group style="display: flex;flex-direction: column" v-model:value="config.achieved.config_money">
                  <a-radio style="margin-bottom: 10px" :value="'MAINTAIN'">{{$t('MAINTAIN_ACCUMULATED_MONEY')}}</a-radio>
                  <a-radio :value="'MINUS'">
                    <a-input-number :formatter="value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')" style="margin-bottom: 10px" v-model:value="config.achieved.minus" :addon-before="$t('MINUS_ACCUMULATED_MONEY')" addon-after="VNĐ" />
                  </a-radio>
                  <a-radio :value="'RESET'">
                    <a-input-number :formatter="value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')" v-model:value="config.achieved.reset" :addon-before="$t('RESET_ACCUMULATED_MONEY_TO')" addon-after="VNĐ" />

                  </a-radio>
                </a-radio-group>
              </a-form-item>
            </a-col>
          </a-row>
          <a-typography-title :level="5">{{$t('IF_THE_SPENDING_LIMIT_IS_NOT_MET_RE-EVALUATE_THE_RANK')}}</a-typography-title>
          <a-form-item >
            <a-radio-group style="display: flex;flex-direction: column" v-model:value="config.not_achieved.config_money">
              <a-radio style="margin-bottom: 10px" :value="'MAINTAIN'">{{$t('MAINTAIN_ACCUMULATED_MONEY')}}</a-radio>
              <a-radio :value="'MINUS'">
                <a-input-number :formatter="value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')" style="margin-bottom: 10px" v-model:value="config.not_achieved.minus" :addon-before="$t('MINUS_ACCUMULATED_MONEY')" addon-after="VNĐ" />
              </a-radio>
              <a-radio :value="'RESET'">
                <a-input-number :formatter="value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')" v-model:value="config.not_achieved.reset" :addon-before="$t('RESET_ACCUMULATED_MONEY_TO')" addon-after="VNĐ" />

              </a-radio>
            </a-radio-group>
          </a-form-item>
        </div>
<!--        <a-form-item :label="$t('status')">-->
<!--          <a-switch v-model:checked="formState.status" />-->
<!--        </a-form-item>-->
      </a-form>

    </a-card>

  </div>
</template>
<script lang="ts">
import {computed, onMounted, ref} from "vue";
import {CaretUpOutlined,CaretDownOutlined} from "@ant-design/icons-vue";
import APIs from "@/APIs/APIs";
import {message} from "ant-design-vue";
import {MixUtils} from "@/utils/Utils";
import {useStore} from "vuex";
import {useRoute, useRouter} from "vue-router";
import i18n from "@/i18n";
import {useI18n} from "vue-i18n";

export default {
  components:{
    // CaretDownOutlined,
    // CaretUpOutlined
  },
setup(){
    const store = useStore()
  const formState = ref({})
  const showCyclical = ref(false)
  const currentShop = computed(()=> store.getters.getCurrentShop)
  const userInfo = computed(() => store.getters.getUserInfo)
  const route = useRoute()
  const router = useRouter()
  const checkEdit = ref(false)
  const i18n = useI18n()
  const formRef = ref({
    id:'',
    shop_id:0,
    creator:0,
    rank_id:'',
    rank_name:'',
    accumulate_points:0,
    accumulated_money:0,
    config:'',
    minus:0,
    reset:0,
  })
  const config=ref({
    check_spending_limit:0,
    achieved:{
      config_rank:'',
      config_money:'',
    },
    not_achieved:{
      config_money:'',
    },
    shop_id:0
  })
  const rules = {
    rank_name: [
      {required: true, message: 'Please input name', trigger: 'blur'},
    ],
    rank_id: [
      {required: true, message: 'Please input id', trigger: 'blur'},
    ],
    accumulate_points: [
      {required: true, message: 'Please input accumulate points', trigger: 'blur'},
    ],
    accumulated_money: [
      {required: true, message: 'Please input accumulated money', trigger: 'blur'},
    ],

  };
  const changeRankId = (e:any) =>{
    // @ts-ignore
    formState.value.rank_id = MixUtils.methods.removeVietnamese3(e.target.value).toUpperCase()
  }
  const ranks = computed(()=> store.getters.getMemberRankData)
  const createMemberRank = () => {
    // @ts-ignore
    formRef.value.validate()
        .then( async () => {
          const res = await APIs.init().createdMemberRank(formState.value)
          if(res.data?.status === 200){
            if(!showCyclical.value){
              router.push("/member-rank")
            }

            message.success(i18n.t('SUCCESS'))
            if(showCyclical.value){
              // @ts-ignore
              const response = await  APIs.init().createdMemberRankConfig({achieved:config.value.achieved,check_spending_limit:config.value.check_spending_limit,not_achieved:config.value.not_achieved,shop_id:config.value.shop_id,member_rank_id:res.data?.data.id})
              if(response.data?.status === 200){
                message.success(i18n.t('SUCCESS'))
                router.push("/member-rank")
              }
            }
          }else {
            message.error(res.data?.message+"")
          }
        })
  }
  const updateMemberRank = async () => {
    try{
      // @ts-ignore
      const res = await APIs.init().updateMemberRank(formState.value)
      if(res.data?.status === 200) {
        message.success(i18n.t('SUCCESS'))
        router.push("/member-rank")
        if(showCyclical.value){
          // @ts-ignore
         if(config.value.id){
           // @ts-ignore
           const response = await  APIs.init().updateMemberRankConfig({...config.value})
           if(response.data?.status === 200){
             message.success(i18n.t('SUCCESS'))
             router.push("/member-rank")
           }
         }else {
           // @ts-ignore
           const response = await  APIs.init().createdMemberRankConfig({achieved:config.value.achieved,check_spending_limit:config.value.check_spending_limit,not_achieved:config.value.not_achieved,shop_id:config.value.shop_id,member_rank_id:formState.value.id})
           if(response.data?.status === 200){
             message.success(i18n.t('SUCCESS'))
             router.push("/member-rank")
           }
         }
        }
      }else {
        message.error(res.data?.message+'')
      }
    }catch (e) {
      console.log(e)
    }
  }
  const getMemberRankDetail = async () => {
    try{
      // @ts-ignore
      const res = await APIs.init().getMemberRankDetail({id: route.params.id })
      if(res.data?.status === 200) {
        formState.value = res.data.data
        checkEdit.value = true
      }
    }catch (e){
      console.log(e)
    }
  }
  const getMemberConfigRank = async () => {
    try{
      // @ts-ignore
      const res = await APIs.init().getMemberRankConfig({id: route.params.id })
      if(res.data?.status === 200) {
       if(res.data.data !== null){
         // @ts-ignore
         if(res.data.data.id === "000000000000000000000000"){
           config.value = {
             check_spending_limit:0,
             achieved:{
               config_rank:'',
               config_money:'',
             },
             not_achieved:{
               config_money:'',
             },
             shop_id:currentShop.value.id
           }
         }else {
           // @ts-ignore
           config.value = res.data.data
           showCyclical.value = true
         }

       }
      }
    }catch (e){
      console.log(e)
    }
  }


  const getPoint = (data:number) => {
    return 100000/100 * data
  }
  const onSubmit = () =>{
    // @ts-ignore
    if(formState.value.id){
      updateMemberRank()
    }else {
      createMemberRank()
    }
  }
  onMounted(()=>{
    if(route.params.id && route.params.id !== ':id'){
      getMemberRankDetail()
      getMemberConfigRank()
    }

    if(currentShop.value && userInfo.value){
      // @ts-ignore
      formState.value.shop_id = currentShop.value.id
      // @ts-ignore
      config.value.shop_id =  currentShop.value.id
      // @ts-ignore
      formState.value.creator = userInfo.value.id
    }
  })
  return{
    formState,
    showCyclical,
    ranks,
    onSubmit,
    formRef,
    rules,
    changeRankId,
    config,
    checkEdit,
    getPoint
  }
}
}
</script>
