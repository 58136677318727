import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createVNode as _createVNode, mergeProps as _mergeProps, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_select_option = _resolveComponent("a-select-option")!
  const _component_a_select = _resolveComponent("a-select")!
  const _component_a_form_item = _resolveComponent("a-form-item")!
  const _component_a_date_picker = _resolveComponent("a-date-picker")!
  const _component_a_textarea = _resolveComponent("a-textarea")!
  const _component_a_radio_group = _resolveComponent("a-radio-group")!
  const _component_a_form = _resolveComponent("a-form")!
  const _component_a_modal = _resolveComponent("a-modal")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_a_modal, {
      confirmLoading: _ctx.isLoading,
      visible: _ctx.visible,
      "onUpdate:visible": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.visible) = $event)),
      title: _ctx.$t('CREATE_SCHEDULE'),
      onOk: _ctx.onSubmit,
      onCancel: _ctx.resetFields
    }, {
      default: _withCtx(() => [
        _createVNode(_component_a_form, {
          name: "custom-validation",
          ref: "formRef",
          "label-col": { span: 6 },
          "wrapper-col": { span: 16 }
        }, {
          default: _withCtx(() => [
            _createVNode(_component_a_form_item, {
              label: _ctx.$t('remind_time_before')
            }, {
              default: _withCtx(() => [
                _createVNode(_component_a_select, {
                  style: {"width":"100%"},
                  "show-search": "",
                  placeholder: "Select Timeout",
                  "default-active-first-option": false,
                  "show-arrow": false,
                  "not-found-content": null,
                  "filter-option": _ctx.filterOption,
                  value: _ctx.formState.remind_time_before,
                  "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.formState.remind_time_before) = $event))
                }, {
                  default: _withCtx(() => [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.timeoutSelections, (time) => {
                      return (_openBlock(), _createBlock(_component_a_select_option, {
                        key: time,
                        value: time.value
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(time.label), 1)
                        ]),
                        _: 2
                      }, 1032, ["value"]))
                    }), 128))
                  ]),
                  _: 1
                }, 8, ["filter-option", "value"])
              ]),
              _: 1
            }, 8, ["label"]),
            _createVNode(_component_a_form_item, _mergeProps({
              "has-feedback": "",
              label: _ctx.$t('SCHEDULE_TIME'),
              name: "schedule_time"
            }, _ctx.validateInfos.schedule_time), {
              default: _withCtx(() => [
                _createVNode(_component_a_date_picker, {
                  value: _ctx.formState.schedule_time,
                  "onUpdate:value": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.formState.schedule_time) = $event)),
                  "show-time": "",
                  placeholder: _ctx.$t('SELECT_TIME_TO_REMIND')
                }, null, 8, ["value", "placeholder"])
              ]),
              _: 1
            }, 16, ["label"]),
            _createVNode(_component_a_form_item, _mergeProps({
              "has-feedback": "",
              label: _ctx.$t('MESSAGE_NOTE'),
              name: "message_note"
            }, _ctx.validateInfos.message_note), {
              default: _withCtx(() => [
                _createVNode(_component_a_textarea, {
                  value: _ctx.formState.message_note,
                  "onUpdate:value": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.formState.message_note) = $event)),
                  placeholder: _ctx.$t('MESSAGE_NOTE'),
                  "auto-size": { minRows: 2, maxRows: 5 }
                }, null, 8, ["value", "placeholder"])
              ]),
              _: 1
            }, 16, ["label"]),
            _createVNode(_component_a_form_item, {
              label: _ctx.$t('SELECT_TYPE')
            }, {
              default: _withCtx(() => [
                _createVNode(_component_a_radio_group, {
                  value: _ctx.formState.schedule_type,
                  "onUpdate:value": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.formState.schedule_type) = $event)),
                  options: _ctx.plainOptions
                }, null, 8, ["value", "options"])
              ]),
              _: 1
            }, 8, ["label"]),
            (_ctx.formState.schedule_type === 'reminder_send_trigger')
              ? (_openBlock(), _createBlock(_component_a_form_item, {
                  key: 0,
                  name: "type",
                  label: _ctx.$t('TRIGGER_TYPE')
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_a_select, {
                      ref: "select",
                      value: _ctx.formState.trigger_id,
                      "onUpdate:value": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.formState.trigger_id) = $event)),
                      placeholder: _ctx.$t('TRIGGER_TYPE')
                    }, {
                      default: _withCtx(() => [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.list, (item) => {
                          return (_openBlock(), _createBlock(_component_a_select_option, {
                            key: item.id,
                            value: item.id
                          }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(_ctx.$t(item.name)), 1)
                            ]),
                            _: 2
                          }, 1032, ["value"]))
                        }), 128))
                      ]),
                      _: 1
                    }, 8, ["value", "placeholder"])
                  ]),
                  _: 1
                }, 8, ["label"]))
              : _createCommentVNode("", true),
            (_ctx.isAdminOrShopOwner)
              ? (_openBlock(), _createBlock(_component_a_form_item, _mergeProps({
                  key: 1,
                  "has-feedback": "",
                  label: _ctx.$t('STAFF_RECEIVE_NOTIFICATIONS'),
                  name: "employee_ids"
                }, _ctx.validateInfos.employee_ids), {
                  default: _withCtx(() => [
                    _createVNode(_component_a_select, {
                      value: _ctx.formState.employee_ids,
                      "onUpdate:value": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.formState.employee_ids) = $event)),
                      mode: "tags",
                      style: {"width":"100%"},
                      onChange: _ctx.handleChange,
                      placeholder: _ctx.$t('PLEASE_SELECT')
                    }, {
                      default: _withCtx(() => [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.employeeSelection, (item) => {
                          return (_openBlock(), _createBlock(_component_a_select_option, {
                            key: item.cms_user_id,
                            value: item.cms_user_id
                          }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(item.name), 1)
                            ]),
                            _: 2
                          }, 1032, ["value"]))
                        }), 128))
                      ]),
                      _: 1
                    }, 8, ["value", "onChange", "placeholder"])
                  ]),
                  _: 1
                }, 16, ["label"]))
              : _createCommentVNode("", true),
            _createVNode(_component_a_form_item, { "wrapper-col": { span: 14, offset: 4 } })
          ]),
          _: 1
        }, 512)
      ]),
      _: 1
    }, 8, ["confirmLoading", "visible", "title", "onOk", "onCancel"])
  ]))
}