import {Flow, ZnsTemp} from "@/models/Flows/Flow";
import {FlowContextData} from "@/models/Flows/FlowContext";
import {FlowConstants} from "@/models/Flows/FlowConstants";
import {Metadata} from "@/APIs/response/S_HttpResponse";
import {SelectionAnt} from "@/models/ShopPages/ConfigData";

export type FlowData = {
    flows: Flow[]|undefined,
    metadata: Metadata|undefined
}

export type State = {
    flowData: FlowData | undefined;
    flows: Flow[],
    flowContext: FlowContextData | undefined;
    flowConstants: FlowConstants | undefined;
    znsTemp: ZnsTemp[] | undefined;
}

export const state: State = {
    flowData: {flows: [], metadata: {}},
    flowContext: undefined,
    flowConstants: undefined,
    znsTemp: [],
    flows:[]
}
