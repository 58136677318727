<template>
  <a-row  style="margin-top: 20px;">
    <a-col :span="11">
      <a-typography-title :level="2">Facebook Uid</a-typography-title>

      <a-input-group compact>
        <a-input v-model:value="value"  placeholder="Nhập url" style="width: calc(100% - 200px)" />
        <a-button @click="handleGetUrl" type="primary">{{$t('Reload')}}</a-button>
      </a-input-group>

    </a-col>
  </a-row>
</template>


<script>
import {useStore} from "@/store";
import {computed, onMounted, ref, watch} from "vue";
import APIs from "../../APIs/APIs";
import {message} from "ant-design-vue";

export default {

  setup(){
    const store = useStore()
    const value = ref("")
    const currentShop = computed(()=> store.getters.getCurrentShop)
    const configPage = computed(()=> store.getters.getPageConfig)
    const getShops = computed(()=> store.getters.getShops)

    const handleGetUrl = async ()=>{
      const res = await APIs.init().getUrlFacebook({cookie:value.value})
      if(res.data.status === 200){
        message.success('quét thành công')
      }
    }

    return{
      configPage,
      currentShop,
      value,
      handleGetUrl,
      getShops
    }
  }
}
</script>
