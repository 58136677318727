<template>
  <div class="container-title">
    <h3 class="title-header">{{$t($route.name)}}</h3>
    <div class="input-search">
      <a-input-search
          v-model:value="categoryRequest.search"
          placeholder="Search"
          @change="handleOnChange"
      />
    </div>

    <a-button
        v-if="getMyRole?.is_create"
        type="primary" class="button-add-product" @click="create">
      <plus-outlined/>
      {{$t('ADD')}}
    </a-button>
  </div>

  <a-table
      size="middle"
      :scroll="{  y: '80vh' }"
      :columns="columns"
      :data-source="getCareers.career"
      class="table"
  >
    <template #name="{record}">
      <a @click="edit(record)" class="table-row-name">
        {{record.name}}
      </a>
    </template>
    <template #description="{record}">
      <span>
        {{record.description}}
      </span>
    </template>
    <template #updated_at="{record}">
      <span>
              {{dateTime(record.updated_at)}}
      </span>
    </template>
    <template #action="{record}">
      <a-dropdown>
        <a-button type="text">
          <eye-outlined />
        </a-button>
        <template #overlay>
          <a-menu>
            <a @click="edit(record)">
              <a-menu-item
                  v-if="getMyRole?.is_edit"
                  class="router-product-edit">
                <edit-outlined/>
                {{ $t('EDIT') }}
              </a-menu-item>
            </a>
            <a-menu-item
                v-if="getMyRole?.is_delete"
            >
              <a-popconfirm placement="bottom" :ok-text="$t('OK')" :cancel-text="$t('CANCEL')" @confirm="confirmDelete(record)">
                <template #title>
                  <p>{{ $t('CONFIRM_DELETE') + +' '+record.category_name + '?'}}</p>
                </template>
              <delete-outlined/>
              {{ $t('DELETE') }}
              </a-popconfirm>
            </a-menu-item>
          </a-menu>
        </template>
      </a-dropdown>
    </template>
  </a-table>
  <a-modal v-model:visible="editVisible" :title="formState.id?$t('UPDATE_CATEGORY'):$t('ADD_CATEGORY')"
           :footer="null"
           style="width: 40%"
  >
    <a-form
        ref="formRef"
        :model="formState"
        :rules="rules"
        :label-col="labelCol"
        :wrapper-col="wrapperCol"
    >
      <a-form-item :label="$t('NAME')" name="name">
        <a-input v-model:value="formState.name"/>
      </a-form-item>
      <a-form-item  :label="$t('DESCRIPTION')" name="description">
        <a-input v-model:value="formState.description"/>
      </a-form-item>
      <a-form-item :wrapper-col="{ offset: 8 }">
        <a-button type="primary" @click="onSubmit">        {{formState.id?$t('UPDATE'):$t('CREATE')        }}</a-button>
        <a-button style="margin-left: 10px"  @click="editVisible=!editVisible">        {{$t('CANCEL')}}
        </a-button>
      </a-form-item>
    </a-form>
  </a-modal>
</template>

<script lang="ts">
import {computed, defineComponent, onBeforeMount, reactive, ref, UnwrapRef} from "vue";
import RouterEndpoint from "@/router/RouterEndpoint";
import {EyeOutlined,EllipsisOutlined,EditOutlined,DeleteOutlined,PlusOutlined} from "@ant-design/icons-vue";
import {useStore} from "@/store";
import {useRoute} from "vue-router";
import {CreateProductCategoryRequest, ProductCategoryRequest} from "@/APIs/request/ProductRequest";
import {ProductCategory} from "@/models/Products/Products";
import {ProductsActionTypes} from "@/store/modules/product/action-types";
import {SuperUserInfo} from "@/models/Users";
import {message} from "ant-design-vue";
import APIs from "@/APIs/APIs";
import {FormFlowInformation} from "@/models/Flows/FlowForm";
import {MixUtils} from "@/utils/Utils";
import {useI18n} from "vue-i18n";
import routers from "@/router";
import {Careers, CreateCareerRequest} from "@/models/Customers/Customers";
import {CustomersActionTypes} from "@/store/modules/customer/action-types";
import {Constant} from "@/utils/Constants";
import useCustomerStore from "../../store/Customers";

export default defineComponent({
  name: "Careers",
  components:{
    // EllipsisOutlined,
    EyeOutlined,
    EditOutlined,
    DeleteOutlined,
    PlusOutlined
  },
  setup(){
    const router = useRoute()
    const editVisible=ref( false)
    const formRef = ref();
    const store=useStore()

    const i18n=useI18n()

    const formState: UnwrapRef<Careers> = reactive({
      id: undefined,
      name: '',
      description: '',
    });

    const rules = {
      name: [
        {required: true, message: i18n.t('PLACEHOLDER_INPUT_NAME'), trigger: 'blur'},
      ],
    };

    const columns =computed(()=>[
      {
        title: "ID",
        dataIndex: 'id',
        key: 'id',
      },
      {
        title: i18n.t('NAME'),
        dataIndex: 'name',
        key: 'name',
        slots:{customRender:'name'}
      },
      {
        title:  i18n.t('DESCRIPTION'),
        key: 'description',
        dataIndex: 'description',
      },
      {
        title:  i18n.t('UPDATE_AT'),
        key: 'updated_at',
        dataIndex: 'updated_at',
        slots:{customRender:'updated_at'}

      },
      {
        title:  i18n.t('ACTION'),
        key: 'action',
        slots:{customRender:'action'}
      },
    ]);

    const categoryRequest: ProductCategoryRequest = {
      page: 1,
      page_size: 1000,
    }

    const getCareers =useCustomerStore()
    const getUserInfo = computed(() => store.getters.getUserInfo)

    const createCareerRequest: CreateCareerRequest = {
      shop_id: getUserInfo.value?.current_shop_id,
      name: '',
      description: '',
    }



    const fetchCareers=()=>{
      // store.dispatch(CustomersActionTypes.FETCH_CAREER, categoryRequest)
      useCustomerStore.getState().GetCareer(categoryRequest)
    }
    const createCareer=()=>{

      message.loading('');
      createCareerRequest.name = formState.name
      createCareerRequest.description = formState.description
      APIs.init().createCareer(createCareerRequest).then(res => {
        if (res.status === 200 && res.data?.status === 200) {
          editVisible.value = false
          message.success({content: 'Created career: ' + res.data.data.name});
          fetchCareers()
        } else {
          message.destroy()
        }
      }).catch(e => {
        message.destroy()
        console.error(e)
      })
    }
    const updateCareer=()=>{
      message.loading('');
      createCareerRequest.name = formState.name
      createCareerRequest.description = formState.description
      APIs.init().updateCareer(createCareerRequest).then(res => {
        if (res.status === 200 && res.data?.status === 200) {
          editVisible.value = false
          message.success({content: 'Updated career : ' + res.data.data.name});
          fetchCareers()
        } else {
          message.destroy()
        }
      }).catch(e => {
        message.destroy()
        console.error(e)
      })
    }

    const confirmDelete=(career: Careers)=>{
      message.loading('');
      const deleteRequest: CreateCareerRequest = {id: career.id}
      APIs.init().deleteCareer(deleteRequest).then(res => {
        if (res.status === 200 && res.data?.status === 200) {
          editVisible.value = false
          message.success({content: 'Deleted category: ' + career.name});
          fetchCareers()
        } else {
          message.destroy()
        }
      }).catch(e => {
        message.destroy()
        console.error(e)
      })
    }


    onBeforeMount(()=>{
      fetchCareers()
    })

    const onSubmit = ()=>{
      console.log('submit')
      formRef.value
          .validate()
          .then(() => {
            if(formState.id){
              updateCareer()
            }else{
              createCareer()

            }
          })
          .catch((error: any) => {
            console.log('error', error);
          });


    }

    const dateTime = (dateString: string)=>{
      return MixUtils.methods.dateTimeDisplayFormatFromShortString(dateString)
    }

    const edit = (career: Careers)=>{
      createCareerRequest.id = career.id
      formState.id = career.id
      formState.name = career.name
      formState.description = career.description
      editVisible.value = true
    }


    const create = () => {
      formState.id = 0
      formState.name = ''
      formState.description = ''
      editVisible.value = true
    };

    const handleOnChange  = ()=>{
      fetchCareers()
    }

    const getMyRole = computed(()=>{
      if(!getUserInfo.value?.id_cms_privileges || getUserInfo.value?.id_cms_privileges<11){
        return {
          is_visible: 1,
          is_create:1,
          is_read: 1,
          is_edit: 1,
          is_delete: 1,
          id: 1
        }
      }else{
        return store.getters.getRole(Constant.MODULES.career);
      }

    })


    return{
      formRef,
      editVisible,
      formState,
      rules,
      onSubmit,
      create,
      edit,
      confirmDelete,
      dateTime,
      columns,
      data: [],
      RouterEndpoint:RouterEndpoint,
      labelCol: { span: 6 },
      wrapperCol: { span: 16 },
      getCareers,
      categoryRequest,
      handleOnChange,
      createProductCategory: createCareer,
      getMyRole
    }
  },
})
</script>

<style scoped lang="scss">
.filter-bar {
  display: flex;
  flex-wrap: wrap;

  .filter-form-label {
    margin-bottom: 4px;
  }

  .button-function {
    display: flex;
    flex: 1;
    justify-content: flex-end;
    align-self: flex-end;

    .button-filter-bar-right {
      margin-left: 16px;
    }

    .button-add-product {
    }
  }
}

.table {
  margin: 16px
}
.title-header {
  font-weight: bold;
  flex: 1;
}
.container-title{
  display: flex;
  flex-direction: row;
  padding: 16px 24px 16px 24px;
  margin-bottom: 25px;
  background-color: white
}
.input-search{
  width: 250px;
  margin-right: 16px;
}
.table-row-name{
  font-weight: bold;
}
</style>
