  <template>
  <a-modal title="Import product to stocks" v-model:visible="visible" :width="screenWidth>=780?'1200px':'95%'" @ok="saveProductStock">
    <a-table :dataSource="stockData" :columns="columns" :pagination="false"
    >
      <template #stock="{record, index}">
        <a-select
            showSearch
            v-model:value="editableStockData[index].stock_id"
            :disabled="!record.editable"
            placeholder="Stock"
            style="width: 220px"
        >
          <a-select-option
              v-for="item in getStocks"
              :key="item.id"
              v-bind:value="item.id"
          >{{ item.shop_name }}
          </a-select-option>
        </a-select>
      </template>
      <template #provider="{ record, index }">
        <a-select
            showSearch
            v-model:value="editableStockData[index].provider_id"
            :disabled="!record.editable"
            placeholder="Provider"
            style="width: 180px"
        >
          <a-select-option
              v-for="item in getProductProviders"
              :key="item.id"
              v-bind:value="item.id"
          >{{ item.name }}
          </a-select-option>
        </a-select>
      </template>
      <template #quantity="{ record, index }">
        <a-input-number
            :disabled="!record.editable"
            v-model:value="editableStockData[index].quantity"
            style="width: 100%"
            :formatter="value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')"
            :parser="value => value.replace(/\$\s?|(,*)/g, '')"
        />
      </template>
      <template #ordered="{  index }">
        <a-input-number
            :disabled="true"
            v-model:value="editableStockData[index].ordered"
            style="width: 100%"
            :formatter="value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')"
            :parser="value => value.replace(/\$\s?|(,*)/g, '')"
        />
      </template>
      <template #available="{ index }">
        <a-input-number
            :disabled="true"
            v-model:value="editableStockData[index].available"
            style="width: 100%"
            :formatter="value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')"
            :parser="value => value.replace(/\$\s?|(,*)/g, '')"
        />
      </template>
      <template #import_price="{ record, index }">
        <a-input-number
            :disabled="!record.editable"
            v-model:value="editableStockData[index].import_price"
            style="width: 100%"
            :formatter="value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')"
            :parser="value => value.replace(/\$\s?|(,*)/g, '')"
        />
      </template>

      <template #sale_price="{ record, index }">
        <a-input-number
            :disabled="!record.editable"
            v-model:value="editableStockData[index].sale_price"
            style="width: 100%"
            :formatter="value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')"
            :parser="value => value.replace(/\$\s?|(,*)/g, '')"
        />
      </template>
      <template #note>
        <a-textarea placeholder="note"/>
      </template>
      <template #operation="{ record, index }">
        <div class="editable-row-operations">
        <span v-if="record.editable">
          <a @click="saveStock(index)" style="padding-right: 5px">Save</a>
            <a @click="cancelStock(index)" style="padding-left: 5px">Cancel</a>
        </span>
          <span v-else>
          <a @click="editStock(index)" style="padding-right: 5px">Edit</a>
          <a @click="deleteStock(index)" style="padding-left: 5px">Delete</a>
        </span>
        </div>
      </template>


      <template #expandedRowRender="{record, index}">
        <a-form
            style="margin: 0"
            name="custom-validation"
            :label-col="{span:12}"
            :wrapper-col="{span:14}"
        >
          <a-row>
<!--            <a-col :span="8">-->
<!--              <a-form-item has-feedback label="Wholesale price" name="wholesale">-->
<!--                <a-input-number-->
<!--                    style="width: 100%"-->
<!--                    placeholder="Wholesale price"-->
<!--                    :disabled="!record.editable"-->
<!--                    v-model:value="editableStockData[index].wholesale_price"-->
<!--                />-->
<!--              </a-form-item>-->
<!--            </a-col>-->
<!--            <a-col :span="8">-->
<!--              <a-form-item has-feedback label="Promotional price" name="promotional_price">-->
<!--                <a-input-number-->
<!--                    style="width: 100%"-->
<!--                    placeholder="Promotional price"-->
<!--                    :disabled="!record.editable"-->
<!--                    v-model:value="editableStockData[index].promotional_price"-->
<!--                />-->
<!--              </a-form-item>-->
<!--            </a-col>-->

            <a-col :span="8">
              <a-form-item has-feedback label="Quantity no sale" name="quantity_no_sale">
                <a-input-number
                    style="width: 100%"
                    placeholder="Quantity no sale"
                    :disabled="!record.editable"
                    v-model:value="editableStockData[index].quantity_no_sale"
                />
              </a-form-item>
            </a-col>

          </a-row>
        </a-form>
      </template>
    </a-table>
    <div class="button-add-stock" >
      <a-button type="dashed" @click="addStock">
        <plus-outlined/>
        Add stock
      </a-button>
    </div>
  </a-modal>
</template>

<script lang="ts">
import {Options, Vue} from "vue-class-component";
import {PlusOutlined, DeleteOutlined} from "@ant-design/icons-vue";
import {computed, defineComponent, reactive, ref, UnwrapRef} from "vue";
import {Stocks} from "@/models/Stocks/Stocks";
import {useStore} from "@/store";
import {Product, ProductProvider, ProductStock} from "@/models/Products/Products";
import {MixUtils} from "@/utils/Utils";
import APIs from "@/APIs/APIs";
import {message} from "ant-design-vue";
import {useI18n} from "vue-i18n";


const data:Fake[] = [
  {
    key: '1',
    stock: "a",
    quantity: 123,
    import_price: 30000,
    note: "note"
  }
]
interface ColumnData{
  title?: string,
  key: string,
  dataIndex?: string,
  slots?: {customRender: string},
}

interface Fake {
  key: string
  stock?: string
  quantity?: number
  import_price?: number
  note?: string
}

export default defineComponent({
  name: "stock-import-modal",
  components: {
    PlusOutlined,
  },
  props: ['callbackImport'],
  computed: {
    screenWidth() {
      return window.innerWidth
    },
  },
  setup(props) {
    const store = useStore()
    const i18n = useI18n()
    const getStocks = computed((): Stocks[] | undefined => store.getters.getStocks.stocks)
    const stockData = ref<ProductStock[]>([]);
    const visible = ref();
    const productStockLoading = ref(false);
    const productImport = ref();
    const editableStockData: UnwrapRef<ProductStock[]> = reactive([]);
    const getCurrentShop=computed(()=>store.getters.getCurrentShop)
    const getProductProviders=computed((): ProductProvider[] | undefined=>store.getters.getProductProviders)

    const columns = computed(()=>[
      {
        title: i18n.t('STOCK'),
        key: 'stock',
        dataIndex: 'stock',
        slots: {customRender: 'stock'},
      },
      {
        title: i18n.t('PROVIDERS'),
        dataIndex: 'provider_id',
        slots: {customRender: 'provider'},
      },
      {
        title: i18n.t('Quantity'),
        key: 'quantity',
        dataIndex: 'quantity',
        slots: {customRender: 'quantity'},
      },
      {
        title: i18n.t('Ordered'),
        key: 'ordered',
        dataIndex: 'ordered',
        slots: {customRender: 'ordered'},
      },
      {
        title: i18n.t('import_price'),
        key: 'import_price',
        dataIndex: 'import_price',
        slots: {customRender: 'import_price'},
      },
      {
        title: i18n.t('NOTE'),
        key: 'note',
        dataIndex: 'note',
        slots: {customRender: 'note'},
      },
      {
        title: i18n.t('ACTION'),
        slots: {customRender: 'operation'}
      }
    ])

    const fetchProductStock  = async (product_group_id: number| undefined, product_id: number| undefined) => {
      productStockLoading.value  = true
      const response = await APIs.init().getProductStocks({product_group_id: product_group_id, product_id: product_id});
      if(response.status===200){
        editableStockData.splice(0, editableStockData.length)
        stockData.value = []
        response.data?.data.map(productStock=>{
          editableStockData.push(productStock)
          productStock.product_id = product_id
          stockData.value.push(productStock)
        })
      }
      productStockLoading.value  = false
    }

    const updateProductStock = (stock: ProductStock)=>{
      new Promise( (resolve, reject)=>{
        // handle version deleted
        if(stock.id_temp==='-1' && stock.id){
          stock.shop_id = getCurrentShop.value?.id
          stock.product_id = productImport.value.id
          stock.product_group_id = productImport.value.product_group_id
          APIs.init().deleteProductStock(stock).then(versionResponse=>{
            if(versionResponse.status===200){
              resolve(true)
            }else{
              reject(false)
            }
          });
          // handle new version
        }else if(stock.id_temp?.length===36){
          stock.product_group_id = productImport.value.product_group_id
          stock.product_id = productImport.value.id
          stock.creator = getCurrentShop.value?.cms_user_id
          APIs.init().createProductStock(stock).then(versionResponse=>{
            if(versionResponse.status===200){
              resolve(true)
            }else{
              reject(false)
            }
          });
          // handle update version
        }else if(stock.id_temp==='0' && stock.id){
          stock.product_group_id = productImport.value.product_group_id
          stock.product_id = productImport.value.id
          stock.creator = getCurrentShop.value?.cms_user_id
          stock.shop_id = getCurrentShop.value?.id
          APIs.init().updateProductStock(stock).then(versionResponse=>{
            console.log(versionResponse)
            if(versionResponse.status===200){
              resolve(true)
            }else{
              reject(false)
            }
          });
        }
      })
    }



    const editStock = (index: number) => {
      stockData.value[index].editable = true
      if(productImport.value.id){
        editableStockData[index].product_group_id = productImport.value.id
        editableStockData[index].id_temp = '0'
      }
    };
    const saveStock = (index: number) => {
      stockData.value[index].editable = false
    };
    const cancelStock = (index: number) => {
      stockData.value = stockData.value.filter((item, indexFilter)=>indexFilter!==index);
      Object.assign(editableStockData, editableStockData.filter((item, indexFilter)=>indexFilter!==index));
    };
    const addStock = () => {
      const id = MixUtils.methods.getUUID()
      editableStockData.unshift({
        id_temp: id,
        stock_id: getStocks.value && getStocks.value?.length>0?getStocks.value[0].id: 0,
        product_id: productImport.value.id,
        import_price: 0,
        promotional_price: 0,
        sale_price: 0,
        quantity_no_sale: 0,
        quantity: 0,
        wholesale_price: 0,
        editable: true
      })
      stockData.value.unshift({
        id_temp: id,
        stock_id: getStocks.value && getStocks.value?.length>0?getStocks.value[0].id: 0,
        product_id: productImport.value.id,
        import_price: 0,
        promotional_price: 0,
        sale_price: 0,
        quantity_no_sale: 0,
        quantity: 0,
        wholesale_price: 0,
        editable: true
      })
      // editStock(key)
    }

    const showModal = (product: Product) =>{
      productImport.value = product
      visible.value = true
      if(product.id){
        console.log('product', product)
        fetchProductStock(product.product_group_id, product.id)
      }
    }

    const saveProductStock = async ()=>{
      message.loading('loading...')
      await Promise.all(editableStockData.map(stock=>{
        return updateProductStock(stock)
      }))
        message.destroy()
        message.info('Import stock success')
      setTimeout(()=>{
        if(props.callbackImport){
          props.callbackImport({id: productImport.value.product_group_id})
        }
      }, 500)
        visible.value = false
    }

    const deleteStock = (index: number) => {
      stockData.value = stockData.value.filter((item, indexFilter)=>indexFilter!==index);
      Object.assign(editableStockData, editableStockData.filter((item, indexFilter)=>indexFilter!==index));
    }

    return {
      addStock,
      cancelStock,
      saveStock,
      editStock,
      showModal,
      visible,
      getStocks,
      stockData,
      editableStockData,
      saveProductStock,
      getProductProviders,
      deleteStock,
      columns,
    }
  },
})
</script>

<style scoped lang="scss">
.import-stock-modal {
  width: 1000px;
}

.button-add-stock {
  margin-top: 12px;
  text-align: center;
}


</style>