import {ActionContext, ActionTree} from "vuex";
import {CustomerData, state, State} from "./states";
import {CallBackPayload, RootState} from "@/store";
import APIs from "@/APIs/APIs";
import {Mutations} from "./mutations";
import {
    CareersResponse, CustomerConfigColumnResponse, CustomerConfigResponse,
    CustomerResponse,
    CustomersCaresResponse,
    CustomersResponse, ExportResponse,
    FlowConstantsResponse, Metadata, NotificationsResponse,
    UserInfoResponse
} from "@/APIs/response/S_HttpResponse";
import {CustomersActionTypes} from "@/store/modules/customer/action-types";
import {CustomersMutationTypes} from "@/store/modules/customer/mutation-types";
import {CustomerRequest, GetCustomerReq} from "@/APIs/request/CustomerRequest";
import {CXViewLogin, CXViewLoginRequest} from "@/models/CXView/CXViewModels";
import {Constant} from "@/utils/Constants";
import {GetRequest} from "@/models/Customers/Customers";

import {CustomerConfigRequest} from "@/APIs/request/CustomerConfigRequest";
import {CustomerConfigColumn} from "@/models/CustomerConfig/CustomerConfig";



type AugmentedActionContext = {
    commit<K extends keyof Mutations>(
        key: K,
        payload: Parameters<Mutations[K]>[1],
    ): ReturnType<Mutations[K]>;
} & Omit<ActionContext<State, RootState>, 'commit'>

export interface Actions {
    [CustomersActionTypes.FETCH_CUSTOMERS](
        {commit}: AugmentedActionContext,
         payload:CustomerRequest
    ): Promise<CustomersResponse | undefined>;

    [CustomersActionTypes.FETCH_CXVIEW](
        {commit}: AugmentedActionContext,
         payload: CXViewLoginRequest
    ): Promise<CXViewLogin | undefined>;

    [CustomersActionTypes.FETCH_CUSTOMER](
        {commit}: AugmentedActionContext,
        payload:CallBackPayload<GetCustomerReq, CustomerResponse>
    ): Promise<CustomerResponse | undefined>;

    [CustomersActionTypes.FETCH_CAREER](
        {commit}: AugmentedActionContext,
        payload: GetRequest
    ): Promise<CareersResponse | undefined>;

    [CustomersActionTypes.FETCH_CUSTOMER_CONFIG](
        {commit}: AugmentedActionContext,
        payload?: CustomerConfigRequest
    ): Promise<CustomerConfigColumnResponse | undefined>;
    [CustomersActionTypes.FETCH_HISTORY_CUSTOMER_CHANGE](
        {commit}: AugmentedActionContext,
        payload?: any
    ): Promise<any | undefined>;
}

export const actions: ActionTree<State, RootState> & Actions = {
    async [CustomersActionTypes.FETCH_CUSTOMERS]({commit}, payload: CustomerRequest): Promise<CustomersResponse | undefined> {
        commit(CustomersMutationTypes.SET_Customers,  {
            customers: state.customerData?.customers,
            metadata: state.customerData?.metadata,
            customersLoading: true,
            version: Constant.VERSION
        })
        const response = await APIs.init().getCustomers(payload)
        commit(CustomersMutationTypes.SET_Customers,  {
            customers: state.customerData?.customers,
            metadata: state.customerData?.metadata,
            customersLoading: false,
            version: Constant.VERSION
        })
        if (response.status === 200) {
            const customerData: CustomerData = {
                customers: response.data?.data,
                metadata: response.data?.meta_data,
                customersLoading: false,
                version: Constant.VERSION
            }
            commit(CustomersMutationTypes.SET_Customers, customerData)
        }
        return response.data;
    },
    async [CustomersActionTypes.FETCH_CXVIEW]({commit}, payload: CXViewLoginRequest): Promise<CXViewLogin | undefined> {
        const response = await APIs.init().cxViewLogin(payload)
        if (response.status === 200) {
            commit(CustomersMutationTypes.SET_CXView, response.data)
        }
        const groupResponse = await APIs.init().cxViewCustomerGroup(1, 10000)

        if(groupResponse.status === 200){
            commit(CustomersMutationTypes.SET_CXView_GROUP, groupResponse.data?.listResponse)

        }
        // await socket.disconnect()
        // socket.on("customer", async (data) => {
        //     console.log("customer", data)
        //     // if(!this.$refs.addFaceModal.visible)
        //     //   this.$refs.addFaceModal.visible = true;
        //     const res = await APIs.init().getCXViewCustomer({cxview_id: data.id});
        //     console.log(res)
        //     if (res.status == 200 && res.data) {
        //         this.listCustomerDetected.unshift({...data, ...res.data.data})
        //     }else{
        //         this.listCustomerDetected.unshift(data)
        //     }
        //     console.log(this.listCustomerDetected)
        //     this.$refs.addFaceModal.showModal();
        //     // const res = await APIs.init().getCXViewCustomer({ cxview_id: data.id });
        //     // const formObject: CustomerForm = {}
        //     // if(res.status == 200 && res.data){
        //     //   this.customerForm = {...res.data.data, ...formObject};
        //     //   this.visibleCreateCustomer = true;
        //     // }
        // })

        return response.data;
    },
    async [CustomersActionTypes.FETCH_CUSTOMER]({commit}, payload: CallBackPayload<GetCustomerReq, CustomerResponse>): Promise<CustomerResponse | undefined> {
        if (payload.payload){
            const response = await APIs.init().getCustomer(payload.payload)
            if (response.status === 200) {
                if (response.data&&response.data.data){
                    commit(CustomersMutationTypes.SET_CUSTOMER, response.data.data)
                }
                if (payload.callback){
                    payload.callback(response.data)
                }
                return response.data
            }
        }

        return undefined
    },
    async [CustomersActionTypes.FETCH_CAREER]({commit}, payload: GetRequest): Promise<CareersResponse | undefined> {
        if (payload){
            const response = await APIs.init().getCareers(payload)
            if (response.status === 200) {
                if (response.data&&response.data){
                    commit(CustomersMutationTypes.SET_CAREER, response.data.data)
                }
                return response.data
            }
        }

        return undefined
    },
    async [CustomersActionTypes.FETCH_CUSTOMER_CONFIG]({commit},payload: CustomerConfigRequest): Promise<CustomerConfigColumnResponse | undefined> {
        const response = await APIs.init().getCustomerConfig({...payload})
        if (response.status === 200 && response.data?.data !== null) {

            commit(CustomersMutationTypes.SET_CUSTOMER_CONFIG, response.data?.data)
            commit(CustomersMutationTypes.REFORMAT_COLUMNS,undefined)
        }else {
            commit(CustomersMutationTypes.SET_CUSTOMER_CONFIG,[])
        }
        return undefined
    },
    async [CustomersActionTypes.FETCH_HISTORY_CUSTOMER_CHANGE]({commit},payload: any): Promise<any | undefined> {
        const response = await APIs.init().getHistoryCustomerChange({...payload})
        if (response.status === 200 && response.data?.data !== null) {

            commit(CustomersMutationTypes.SET_CUSTOMER_CHANGE, response.data?.data)
        }else {
            commit(CustomersMutationTypes.SET_CUSTOMER_CHANGE,[])
        }
        return undefined
    },

}
