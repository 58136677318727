import {Metadata} from "@/APIs/response/S_HttpResponse";
import {MemberRank} from "@/models/MemberRank/MemberRank";


export type MemberRankData = {
    memberRank: MemberRank[]|undefined
    metadata: Metadata|undefined
}
export type State = {
    membersRank: MemberRankData| undefined
}
export const state: State = {
    membersRank:{memberRank:[],metadata:{}}
}
