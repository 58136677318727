import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_typography_title = _resolveComponent("a-typography-title")!
  const _component_a_button = _resolveComponent("a-button")!
  const _component_a_range_picker = _resolveComponent("a-range-picker")!
  const _component_a_space = _resolveComponent("a-space")!
  const _component_a_row = _resolveComponent("a-row")!
  const _component_a_typography_text = _resolveComponent("a-typography-text")!
  const _component_InfoCircleOutlined = _resolveComponent("InfoCircleOutlined")!
  const _component_a_tooltip = _resolveComponent("a-tooltip")!
  const _component_CaretUpOutlined = _resolveComponent("CaretUpOutlined")!
  const _component_a_col = _resolveComponent("a-col")!
  const _component_TinyAreaChart = _resolveComponent("TinyAreaChart")!
  const _component_TinyChart = _resolveComponent("TinyChart")!
  const _component_a_progress = _resolveComponent("a-progress")!
  const _component_ColumnPlot = _resolveComponent("ColumnPlot")!
  const _component_a_table = _resolveComponent("a-table")!
  const _component_a_pagination = _resolveComponent("a-pagination")!
  const _component_EllipsisOutlined = _resolveComponent("EllipsisOutlined")!
  const _component_PieChart = _resolveComponent("PieChart")!
  const _component_a_layout_content = _resolveComponent("a-layout-content")!

  return (_openBlock(), _createBlock(_component_a_layout_content, null, {
    default: _withCtx(() => [
      _createVNode(_component_a_row, { style: {"justify-content":"space-between","height":"56px","align-items":"center","margin":"0 19px","background-color":"white"} }, {
        default: _withCtx(() => [
          _createVNode(_component_a_typography_title, {
            style: {"padding-left":"24px"},
            level: 4
          }, {
            default: _withCtx(() => [
              _createTextVNode("Sales")
            ]),
            _: 1
          }),
          _createVNode(_component_a_row, null, {
            default: _withCtx(() => [
              _createVNode(_component_a_button, { class: "btn" }, {
                default: _withCtx(() => [
                  _createTextVNode("All day")
                ]),
                _: 1
              }),
              _createVNode(_component_a_button, { class: "btn" }, {
                default: _withCtx(() => [
                  _createTextVNode("All week")
                ]),
                _: 1
              }),
              _createVNode(_component_a_button, { class: "btn" }, {
                default: _withCtx(() => [
                  _createTextVNode("All month")
                ]),
                _: 1
              }),
              _createVNode(_component_a_button, { class: "btn" }, {
                default: _withCtx(() => [
                  _createTextVNode("All year")
                ]),
                _: 1
              }),
              _createVNode(_component_a_space, {
                direction: "vertical",
                style: {"width":"256px","padding-right":"24px"}
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_a_range_picker, {
                    value: $setup.value3,
                    "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => (($setup.value3) = $event))
                  }, null, 8, ["value"])
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      _createVNode(_component_a_row, { style: {"margin":"20px 19px"} }, {
        default: _withCtx(() => [
          _createVNode(_component_a_col, {
            span: 5,
            style: {"background-color":"white","min-width":"278px","height":"182px","margin-right":"24px"}
          }, {
            default: _withCtx(() => [
              _createVNode(_component_a_row, { style: {"padding":"24px"} }, {
                default: _withCtx(() => [
                  _createVNode(_component_a_row, { style: {"justify-content":"space-between","align-items":"center","width":"100%"} }, {
                    default: _withCtx(() => [
                      _createVNode(_component_a_typography_text, { type: "secondary" }, {
                        default: _withCtx(() => [
                          _createTextVNode("Total Sales")
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_a_tooltip, {
                        placement: "topLeft",
                        title: "Giới thiệu"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_InfoCircleOutlined)
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_a_row, { style: {"justify-content":"space-between","align-items":"center","width":"100%"} }, {
                    default: _withCtx(() => [
                      _createVNode(_component_a_typography_title, {
                        style: {"margin":"0"},
                        level: 3
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode("5 tỷ")
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_a_row, { style: {"align-items":"center","margin-bottom":"18px","height":"44px","width":"100%"} }, {
                    default: _withCtx(() => [
                      _createVNode(_component_a_typography_text, null, {
                        default: _withCtx(() => [
                          _createTextVNode("Tăng 10%")
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_CaretUpOutlined, { style: {"color":"#52c41a","margin-left":"6px"} })
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_a_row, { style: {"align-items":"center","width":"100%"} }, {
                    default: _withCtx(() => [
                      _createVNode(_component_a_typography_text, null, {
                        default: _withCtx(() => [
                          _createTextVNode("Daily Sales")
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_a_typography_text, { style: {"margin-left":"8px"} }, {
                        default: _withCtx(() => [
                          _createTextVNode("12,423")
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            _: 1
          }),
          _createVNode(_component_a_col, {
            span: 5,
            style: {"background-color":"white","min-width":"278px","height":"182px","margin-right":"24px"}
          }, {
            default: _withCtx(() => [
              _createVNode(_component_a_row, { style: {"padding":"24px"} }, {
                default: _withCtx(() => [
                  _createVNode(_component_a_row, { style: {"justify-content":"space-between","align-items":"center","width":"100%"} }, {
                    default: _withCtx(() => [
                      _createVNode(_component_a_typography_text, { type: "secondary" }, {
                        default: _withCtx(() => [
                          _createTextVNode("Tổng đơn hàng")
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_a_tooltip, {
                        placement: "topLeft",
                        title: "Giới thiệu"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_InfoCircleOutlined)
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_a_row, { style: {"justify-content":"space-between","align-items":"center","width":"100%"} }, {
                    default: _withCtx(() => [
                      _createVNode(_component_a_typography_title, {
                        style: {"margin":"0"},
                        level: 3
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode("8,846")
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_a_row, { style: {"align-items":"center","width":"100%","margin-bottom":"18px","height":"44px"} }, {
                    default: _withCtx(() => [
                      _createVNode(_component_TinyAreaChart)
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_a_row, { style: {"align-items":"center","width":"100%"} }, {
                    default: _withCtx(() => [
                      _createVNode(_component_a_typography_text, null, {
                        default: _withCtx(() => [
                          _createTextVNode("Đơn hàng ngày")
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_a_typography_text, { style: {"margin-left":"8px"} }, {
                        default: _withCtx(() => [
                          _createTextVNode("1,234")
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            _: 1
          }),
          _createVNode(_component_a_col, {
            span: 5,
            style: {"background-color":"white","min-width":"278px","height":"182px","margin-right":"24px"}
          }, {
            default: _withCtx(() => [
              _createVNode(_component_a_row, { style: {"padding":"24px"} }, {
                default: _withCtx(() => [
                  _createVNode(_component_a_row, { style: {"justify-content":"space-between","align-items":"center","width":"100%"} }, {
                    default: _withCtx(() => [
                      _createVNode(_component_a_typography_text, { type: "secondary" }, {
                        default: _withCtx(() => [
                          _createTextVNode("Payments")
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_a_tooltip, {
                        placement: "topLeft",
                        title: "Giới thiệu"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_InfoCircleOutlined)
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_a_row, { style: {"justify-content":"space-between","align-items":"center","width":"100%"} }, {
                    default: _withCtx(() => [
                      _createVNode(_component_a_typography_title, {
                        style: {"margin":"0"},
                        level: 3
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode("6,560")
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_a_row, { style: {"align-items":"center","margin-bottom":"18px","width":"100%"} }, {
                    default: _withCtx(() => [
                      _createVNode(_component_TinyChart)
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_a_row, { style: {"align-items":"center","width":"100%"} }, {
                    default: _withCtx(() => [
                      _createVNode(_component_a_typography_text, null, {
                        default: _withCtx(() => [
                          _createTextVNode("Conversion Rate")
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_a_typography_text, { style: {"margin-left":"8px"} }, {
                        default: _withCtx(() => [
                          _createTextVNode("60%")
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            _: 1
          }),
          _createVNode(_component_a_col, {
            span: 5,
            style: {"background-color":"white","min-width":"278px","height":"182px"}
          }, {
            default: _withCtx(() => [
              _createVNode(_component_a_row, { style: {"padding":"24px"} }, {
                default: _withCtx(() => [
                  _createVNode(_component_a_row, { style: {"justify-content":"space-between","align-items":"center","width":"100%"} }, {
                    default: _withCtx(() => [
                      _createVNode(_component_a_typography_text, { type: "secondary" }, {
                        default: _withCtx(() => [
                          _createTextVNode("Operational Effect")
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_a_tooltip, {
                        placement: "topLeft",
                        title: "Giới thiệu"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_InfoCircleOutlined)
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_a_row, { style: {"justify-content":"space-between","align-items":"center","width":"100%"} }, {
                    default: _withCtx(() => [
                      _createVNode(_component_a_typography_title, {
                        style: {"margin":"0"},
                        level: 3
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode("78% ")
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_a_row, { style: {"align-items":"center","width":"100%","margin-bottom":"18px","height":"44px"} }, {
                    default: _withCtx(() => [
                      _createVNode(_component_a_progress, {
                        "stroke-linecap": "square",
                        percent: 78,
                        showInfo: false,
                        strokeColor: "#13c2c2"
                      })
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_a_row, { style: {"align-items":"center","width":"100%"} }, {
                    default: _withCtx(() => [
                      _createVNode(_component_a_typography_text, null, {
                        default: _withCtx(() => [
                          _createTextVNode("WoW Change")
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_a_typography_text, { style: {"padding-left":"6px"} }, {
                        default: _withCtx(() => [
                          _createTextVNode(" 12%")
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_CaretUpOutlined, { style: {"color":"#52c41a","margin-left":"3px"} }),
                      _createVNode(_component_a_typography_text, { style: {"margin-left":"16px"} }, {
                        default: _withCtx(() => [
                          _createTextVNode("DoD Change")
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      _createVNode(_component_a_row, { style: {"margin":"24px 19px","justify-content":"center","background-color":"white"} }, {
        default: _withCtx(() => [
          _createVNode(_component_a_row, { style: {"padding":"24px","flex-direction":"column","width":"757px"} }, {
            default: _withCtx(() => [
              _createVNode(_component_a_typography_text, { style: {"margin-bottom":"20px"} }, {
                default: _withCtx(() => [
                  _createTextVNode("Stores Sales Trend")
                ]),
                _: 1
              }),
              _createVNode(_component_ColumnPlot)
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      _createVNode(_component_a_row, { style: {"margin":"20px 19px"} }, {
        default: _withCtx(() => [
          _createVNode(_component_a_col, {
            span: 11,
            style: {"background-color":"white"}
          }, {
            default: _withCtx(() => [
              _createVNode(_component_a_row, { style: {"padding":"24px"} }, {
                default: _withCtx(() => [
                  _createVNode(_component_a_row, { style: {"padding":"0 24px","justify-content":"space-between","align-items":"center"} }, {
                    default: _withCtx(() => [
                      _createVNode(_component_a_typography_text, { strong: "" }, {
                        default: _withCtx(() => [
                          _createTextVNode("Sales Ranking")
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_a_row, { style: {"margin-top":"24px","justify-content":"flex-end"} }, {
                    default: _withCtx(() => [
                      _createVNode(_component_a_table, {
                        columns: $setup.columns,
                        "data-source": $setup.data,
                        style: {"width":"100%"},
                        pagination: false
                      }, {
                        seller: _withCtx(({ record }) => [
                          _createElementVNode("a", null, _toDisplayString(record.seller), 1)
                        ]),
                        _: 1
                      }, 8, ["columns", "data-source"]),
                      _createVNode(_component_a_pagination, {
                        current: $setup.current,
                        "onUpdate:current": _cache[1] || (_cache[1] = ($event: any) => (($setup.current) = $event)),
                        total: 50,
                        "show-less-items": ""
                      }, null, 8, ["current"])
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            _: 1
          }),
          _createVNode(_component_a_col, {
            span: 12,
            style: {"margin-left":"13px","background-color":"white"}
          }, {
            default: _withCtx(() => [
              _createVNode(_component_a_row, { style: {"padding":"24px"} }, {
                default: _withCtx(() => [
                  _createVNode(_component_a_row, { style: {"justify-content":"space-between","width":"100%"} }, {
                    default: _withCtx(() => [
                      _createVNode(_component_a_typography_text, { strong: "" }, {
                        default: _withCtx(() => [
                          _createTextVNode("The Proportion Of Sales")
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_a_row, { style: {"align-items":"center"} }, {
                        default: _withCtx(() => [
                          _createVNode(_component_a_button, null, {
                            default: _withCtx(() => [
                              _createTextVNode("All")
                            ]),
                            _: 1
                          }),
                          _createVNode(_component_a_button, null, {
                            default: _withCtx(() => [
                              _createTextVNode("Online")
                            ]),
                            _: 1
                          }),
                          _createVNode(_component_a_button, null, {
                            default: _withCtx(() => [
                              _createTextVNode("Stores")
                            ]),
                            _: 1
                          }),
                          _createVNode(_component_EllipsisOutlined, { style: {"margin-left":"18px"} })
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_a_row, { style: {"padding":"36px 0"} }, {
                    default: _withCtx(() => [
                      _createVNode(_component_a_typography_title, { level: 5 }, {
                        default: _withCtx(() => [
                          _createTextVNode("Sales")
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_a_row, { style: {"width":"100%"} }, {
                    default: _withCtx(() => [
                      _createVNode(_component_PieChart)
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}