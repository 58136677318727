<template>
  <h3 class="title-flow-context" v-if="type!=='flow-new'">
    {{ $t('FLOW_PREVIEW') }}
  </h3>
  <div class="preview-container">
    <div v-for="message in messageContexts" :key="message.id">
      <div v-if="message.type==='text'">
        <div class="content-message">
          {{ message.text }}
        </div>
        <div class="content-message" v-for="image in message.reply_images" :key="image">
          <img :src="image" class="img-message"/>
        </div>
        <div class="post-back-button" v-if="message.buttons">
          <a-button class="button-post-back" v-for="button in message.buttons" :key="button.id">{{
              button.title }}
            <div style="display: flex; width: 30px; height: 40px; position: absolute; right: -16px; top: 10px; align-items: center; justify-content: center">
              <Handle :id="button.id" type="source" class="handle"   />
            </div>
          </a-button>
        </div>
      </div>
      <div v-else-if="message.type==='text_attachment'">
        <div class="content-message">
          <img v-if="message.images&&message.images.length>0" :src="message.images[0]" class="img-message"/>
          {{ message.text }}
        </div>
      </div>
      <div v-else-if=" message.type !=='text_attachment' && message.type !=='text' ">
        <div  v-if="message.elements&&message.elements.length>0">
          <img style="width: 100%" :src="message.elements[0].image_url" class="img-message"/>
          <p class="title-zalo-element">
            {{ message.elements[0].title }}
          </p>
          {{ message.elements[0].subtitle }}


        </div>
        <a-list item-layout="horizontal" v-if="message.elements&&message.elements.length>1" :data-source="message.elements">

          <template #renderItem="{ item,index }">
            <a-list-item class="item-zalo-element" v-if="index!==0">
              <a-list-item-meta

              >
                <template #avatar>
                  <a-avatar v-if="item.image_url" shape="square" :src="item.image_url"/>
                </template>
                <template  #description>
                  <div v-if="item.type !== 'table'" :style="{'text-align':item.align}"  >
                    <a-typography-text :style="{'fontWeight':item.type=== 'header' ? '600' : '4000'}"    > {{item.content}}</a-typography-text>

                  </div>
                  <a-row  v-else style="flex-direction: column" >
                    <a-typography-text style="display: block"  v-for="i in item.content_table" :key="i"> {{`${i.key}`}} :<a-tag style="color: black"  :color="i.style ? i.style : 'transparent'">
                      {{i.value}}
                    </a-tag> </a-typography-text>

                  </a-row>
                </template>
              </a-list-item-meta>
            </a-list-item>
          </template>
        </a-list>
        <div style="margin-top: 18px" v-if="message.buttons">
          <a-button  style="border-top: none;border-left:none;border-right:none;align-items: center;justify-content: space-between;width: 100%;display: flex;height: 50px" class="button-post-back" v-for="button in message.buttons" :key="button.id">
            <img v-if="button.image_icon" :src="button.image_icon" style="width: 25px;height: 25px">
            {{
              button.title }}
            <right-outlined />
              <div style="display: flex; width: 30px; height: 40px; position: absolute; right: -16px; top: 10px; align-items: center; justify-content: center">
              <Handle  :id="button.id" type="source" class="handle"   />
            </div>
          </a-button>
        </div>

      </div>


    </div>
  </div>
</template>

<script lang="ts">
import {defineComponent, onMounted, ref, watch} from "vue";
import {RightOutlined} from "@ant-design/icons-vue";
import {FlowZaloElement, MessageContext, PostbackContext} from "@/models/Flows/FlowContext";
import {Position, VueFlow, useVueFlow, Handle, MarkerType} from '@vue-flow/core'

export default defineComponent({
  name: "zalo-oa-preview",
  components: {
    Handle,RightOutlined
  },
  props: ['flowContext', 'type'],
  setup(props) {
    const postBacks = ref<FlowZaloElement[]>([])
    const elements = ref<FlowZaloElement[]>([])
    const element1 = ref<FlowZaloElement | undefined>(undefined)
    const messageContexts = ref<MessageContext[]>([])
    const image = ref('')


    watch(() => props.flowContext, () => {
      updateProps()
    })
    const updateProps = () => {
      messageContexts.value = props.flowContext.message_contexts ? props.flowContext.message_contexts : []
    }
    updateProps()
    return {
      postBacks,
      image,
      elements,
      element1,
      messageContexts,

    }
  }
})
</script>

<style lang="scss" scoped>
.preview-container {
  background-color: #ffffff;
  height: auto;
  min-height: 480px;
  border-radius: 10px;
  padding: 15px;
}

.content-message {
  margin-right: 20px;
  padding: 15px;
  background-color: #eef1f4;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  border-bottom-right-radius: 15px;
  white-space: pre-line;
}

.post-back-button {
  margin-top: 18px;
  //text-align: center;

  .button-post-back {
    width: 100%;
    margin: 5px 0
  }
}

.title-flow-context {
  text-align: center;
  font-weight: bold;
}

.title-zalo-element {
  font-weight: bold;
  margin-top: 10px;
}

.img-message {
  width: 100%;
}

.content-element {
  margin-top: 15px;
}
</style>
