export const Endpoint = {
    /* Auth Service*/
    LOGIN_TOKEN: "cms-user-id",
    OVERVIEW: "pages/pages-active-by-shop",
    ACTIVE_PAGE: "pages/active-pages-by-shop",
    WHATSAPP_DISABLED:"pages/whatsapp-disabled-oa-by-shop",
    OVERVIEWFB: "pages/fb-pages-by-shop",
    OVERVIEWITG: "pages/instagram-pages-by-shop",
    OVERVIEWZALO:"/pages/zalo-oa-by-shop",


    /* Flow Service*/
    GET_SUPER_USER_INFO: "user/super-info",
    FLOWS: "flows",
    FLOW: "flow",
    PUBLISH_FLOW: "/flow/v2/contexts/publish",
    FLOW_CONSTANTS: "flows/constants",
    TAGS: "tags",
    TAG_ROOT: "tags/root",
    TAG_TREE: "tags/tree",
    TAG_CHILDREN: "tags/children",
    TAG: "tag",
    FLOW_CONTEXT: "flow/context",
    FLOW_CONTEXTS: "flow/contexts",
    FLOW_V2_CONTEXTS: "/flow/v2/contexts",
    FLOW_TAGS_CONTEXTS: "/flow/v2/contexts-tags",

    VIRAL_RESULT: "/flow/v2/viral-result",

    TRIGGERS: "triggers",
    TRIGGER_QUICK_MESSAGES: "triggers/quick_message",
    TRIGGER: "trigger",
    TRIGGER_ACTIVATE: "trigger/activate",
    TRIGGER_SEND_UPDATE_TO_ONE: "trigger/send-update-to-one",
    TRIGGER_SEND_UPDATE_TO_ONE_BY_SALE_TAG: "/triggers/send-to-one-by-tag",
    TRIGGER_SEND_UPDATE_TO_ONE_BY_SALE_TAG2: "/triggers/send-to-one-by-sale-tag",
    TRIGGER_SEND_TO_ONE: "trigger/send-to-one",
    TRIGGER_STATISTICS: "trigger/statistics",
    TRIGGER_STATISTICS_LOGS: "trigger/statistics-logs",
    TRIGGER_INNER_STATISTICS: "trigger/inner-statistics",
    TRIGGER_STOP: "trigger/stop",
    CUSTOMERS: "customers",
    DELETE_ALL_CUSTOMER: "customers/delete",
    CUSTOMER: "customer",
    CUSTOMER_LASTCARE: "customer/last-care",
    CUSTOMERS_ADDTAGS: "customers/tags-add",
    CUSTOMERS_ADD_ALL_TAGS: "customers/tags",
    CUSTOMERS_ADD_TAG: "customers/tag-add",
    CUSTOMERS_REMOVETAGS: "customers/tags-remove",
    CUSTOMERS_REMOVE_TAG: "customers/tag-remove",
    CUSTOMERS_CARES: "customer/customer-cares",
    CUSTOMERS_DETAIL_CARES: "customer/customer-detail-cares",
    CUSTOMERS_CARE: "customer/customer-care",
    SORT_CUSTOMERS_CARE: "customer/sort-customer-care",
    CUSTOMERS_EMPLOYEES: "customers/employees",
    CUSTOMERS_EMPLOYEES_ALL_BY_FILTER: "customers/employees-all-by-filter",
    CUSTOMERS_EXPORT: "customers/excel",
    CUSTOMERS_IMPORT: "customers/excel",
    WALLET: "wallet",
    WALLET_BY_UID: "wallet/uid",
    WALLET_BY_ID: "wallet/id",
    WALLET_TRANSACTIONS: "wallet/transactions",
    WALLET_EXPORT_TRANSACTIONS: "wallet/transactions/export",
    FLOW_EXPORT_TRANSACTIONS: "transaction-export/flow/zns",
    FLOW_ZNS_TRANSACTIONS: "transaction/flow/zns",
    TRANSPORTER: 'transporters',
    SHOP_INFO: 'shop_info',


    /* AWS Digital Ocean File Storage Service*/

    AWS_DO_GET_FILES: "aws-do/get-files",
    AWS_DO_DELETE_FILES: "aws-do/delete-files",
    AWS_DO_UPLOAD_FILES: "aws-do/upload-files",

    /* Locales i18n Service*/

    LOCALES_LIST: "locales/list",
    LOCALES_SYNC: "locales/sync",
    LOCALES_EDIT: "locales/edit",
    LOCALES_DELETE: "locales/delete",

    PROVINCES: "location/provinces",
    DISTRICTS: "location/districts",
    WARDS: "location/wards",

    ZNS_TEMP: "zns/templates",

    /* CXView Service*/
    CXVIEW_LOGIN: "prod/api/v1/user-management/login",
    CXVIEW_LIST_CUSTOMER: "prod/api/v1/customer-management/list-customer",
    CXVIEW_DETAIL_CUSTOMER: (id: string) => `prod/api/v1/customer-management/get-detail-customer/${id}`,
    CXVIEW_ADD_CUSTOMER: "prod/api/v1/customer-management/add-customer",
    CXVIEW_DELETE_CUSTOMER: (id: string) => `prod/api/v1/customer-management/delete-customer/${id}`,
    CXVIEW_UPDATE_CUSTOMER: (id: string) => `prod/api/v1/customer-management/update-customer/${id}`,
    CXVIEW_GROUP_CUSTOMER: `prod/api/v1/group-management/list-group`,

    CXVIEW_CUSTOMER: '/cx-view/customer',

    AUTO_INBOX: '/auto-inbox',
    AUTO_INBOXES: '/auto-inboxes',
    AUTO_INBOX_HISTORY: '/auto-inbox/history',

    FACEBOOK_POSTS: '/facebook/posts',
    FACEBOOK_EVENT_API: '/facebook/event-api-product',

    AUTO_INBOX_EXPECT_CUSTOMER: '/auto-inbox/customer-filter',
    AUTO_INBOX_CHANGE_STATUS: '/auto-inbox/change-status',

    STOCKS: '/stocks',

    PRODUCT_GROUPS: "products",
    PRODUCT_CHECK: "products/checks",
    PRODUCT_VERSION: "products/versions",
    PRODUCT_STOCKS: "stocks/imports",
    STOCK_PRODUCTS: "stocks/products",
    PRODUCT_DETAIL: "products/detail",
    PRODUCT_ALL: "products/all",
    ORDER_STAT: "orders/stat",
    ORDERS: "orders",
    ORDER_STATUS: "orders/status",
    STOCK_DETAIL: "stocks/detail",
    ORDER_DETAIL: "orders/detail",
    CATEGORIES: "categories",
    PROVIDERS: "providers",
    CUSTOMER_SEARCH: "customers/search",
    SHIPPING: "shipping",
    SHIPPING_FEE: "shipping/fee",
    SHOP_CUSTOMER_HISTORIES: "customers/histories",

    EMPLOYEE_SEARCH: (name: string) => `cms_users?search=${name}`,
    EMPLOYEE: "employee",
    LOGIN_EMAIL: "auth/email",
    LOGIN_FACEBOOK: "auth/facebook",
    LOGIN_GOOGLE: "auth/google",
    LOGIN_WEB_TOKEN: "auth/web_token",

    OVERVIEW_DELETE: "pages/deactivate-page",
    OVERVIEW_ADD: "pages/activate-page",
    INSTAGRAM_ACTIVE_PAGE: "ig/activate-page",

    REPORT_PAGE_COMMENT: 'page/comment/conversation',
    REPORT_CONVERSION_ORDER: 'page/conversion/order',
    REPORT_PAGE_MESSAGE: 'page/message/conversation',
    REPORT_PAGE: 'page',
    REPORT_STAFF: 'staff',
    REPORT_PAGE_NEW_CONVERSATION: 'page/new_conversation',

    BANK: "banks",
    ZALO_ACTIVE_PAGE:"zalo/Subscribe",
    ADD_PAGE_FB:"pages/user_fb",
    ADD_PAGE_FB_V2:"pages/user_fb_v2",
    PAGE_USER:"pages/user",
    TARIFFS:"tariffs",
    COUPON:"coupon",
    NOTIFICATION_BY_UID:"notification/by-uid",
    UNREAD_NOTIFICATION_BY_UID:"notification/unread-uid",
    NOTIFICATION_SCHEDULE_EMPLOYEE:"notification/schedule-employee",
    READ_NOTIFICATION:"notification/read",
    NOTIFICATION_SCHEDULES:"notification/schedules",
    NOTIFICATION_SCHEDULES_DELETE:"notification/schedules/delete",
    SHOP_SERVICES:"shop_services",
    SHOP_SERVICES_CONFIRM_VNPAY:"shop_services/confirm_vnpay",
    REPORT_CUSTOMER:"customer",
    REPORT_CUSTOMER_STAT:"customer/stat",
    REPORT_CUSTOMER_STAT_DAY:"customer/stat-day",
    REPORT_CUSTOMER_STAT_MONTH:"customer/stat-month",
    ROLE:"cms_user/cms_privileges",
    TOKEN_LONG_ACCESS:"/v19.0/oauth/access_token",

    ZALO_CONFIG_AUTH:"auth/zalo-config-auth",
    ZALO_LOGIN: "pages/zalo-login",
    ZALO_REFRESH_TOKEN: "/pages/zalo-refresh-token",

    EMPLOYEE_LINK_INVITE:"/employee/link_invite",
    EMPLOYEE_INVITE:"/employee/invite",
    PAGE_MAIL_LIST:"/page/mail/list",






    FLOW_TEMPLATE_EMAIL_VIEWER:"/flow/template-email-viewer",
    SHIPPING_CREATE_ORDER:"/shipping/createOrder",

    INTEGRATION_PLATFORMS_MAIL: "/integration/mail",
    CAREERS: "/careers",
    PAGE_LIST:"/page/list",
    Shop_PAGE: "/shop/pages",
    CONTACTS:"/contacts",
    LINE_INFO: "/line/bot/info",
    VIRAL: "/viral",
    VIRAL_USER: "/viral-user",

    TRIGGER_ESTIMATE:"/trigger/estimate",
    CMS_USERS:"/cms_users",
    SHOPS:"/shops",
    ROLES:"/roles",
    SYNC_ROLES:"/roles/merge",
    DEPARTMENTS:"/departments",
    PRIVILEGE_ROLE:"/roles/privileges",

    REPORT_CUSTOMER_TAG:"/report/customer_tag",
    REPORT_CUSTOMER_CARE:"/report/customer_care",
    REPORT_CUSTOMER_TAG_BY_STAFF:"/report/customer_tag_staff",
    REPORT_CUSTOMER_CARE_BY_STAFF:"/report/customer_care_staff",

    CUSTOME_CONFIG:"/customer-config",
    CUSTOME_CONFIG_value:"/customer-config-value",

    TRIGGER_INVITE_LINK: "/trigger/lucky-wheel/invite-link",


    VOUCHER:"/voucher",
    VOUCHER_TIME:"/voucher_time",
    VOUCHER_CONFIG:"/voucher_config",
    VOUCHER_Detail:"/voucher_detail",

    VOUCHER_STOP:"/voucher_stop",
    VOUCHER_ACTIVE:"/voucher_active",

    MEMBER_RANK:"/member_rank",
    MEMBER_RANK_STOP:"/member_rank_stop",
    MEMBER_RANK_ACTIVE:"/member_rank_active",
    MEMBER_RANK_DETAIL:"/member_rank_detail",
    MEMBER_CONFIG_RANK:"/member_config_rank",

    CXVIEW_HISTORY: "/cx-view/history",
    ZNS_EXPORT:"/wallet/transactions/export",

    NEW_CLIENT: "/new-client",
    PIPE_ASSET:"/pipe-asset",
    STATE :"/state",


    TICKETS:"/tickets",
    EVENTS:"/events",
    LANDING_PAGE:"/landing-page",
    LANDING_PAGE_SINGLE:"/landing-page/single",

    TICKET_TYPE:"/ticket_types",
    TICKET_PRICES:"/ticket_prices",
    TICKET_TEMPLATE:"/ticket_template",


    CONFIRM_VNPAY:"confirm_vnpay",
    Ticket_CHECKIN:"/tickets/checkin",
    TICKET_TRANSACTIONS:"/ticket_transactions",
    PAYMENT:"/payments",
    HISTORY_CUSTOMER_CHANGE:"/history_change_customer",
    USE_VOUCHER:"/use_voucher",
    CUSTOMER_USE_VOUCHER:"/customer_use_voucher",

    SHIPPING_CONNECT:"/shipping/connect",
    CHANGE_SHOP:"/auth/change-shop",
    CHECKIN_REPORT:"/tickets/checkin_report",
    CHECKIN_REPORT_XLS:"/tickets/checkin_report_xls",


    PRODUCT_IMPORT:"/products/import",
    PRODUCT_EXPORT:"/products/export",

    IMPORT_TICKET_XLS:"/tickets/import_xls",
    IMPORT_ORDER_XLS:"/orders/import",

    USE_POINT: "/use_point",
    USE_POINTS: "/use_points",
    Cancel_USE_POINT:"/cancel_use_point",

    PREVIEW_ORDER:"/flow-api/preview_order",

    MORE_MONEY:"/more_money",

    MERGE_CUSTOMER:"/merge_customer",

    SCHEDULE_TRIGGER:"/schedules/trigger",

   AFFILIATE_SYNC_PRODUCT :"/affiliate/sync_product",

    SCHEDULE_IMPORT: "/schedule/excel",

    FACEBOOK_UID: "/facebook/fbuid-cookie",

    CONVERSION_REPORT:"/facebook/conversion/report",

    REPORT_ORDER:"/report/order",

    FB_CHECK_TOKEN:"/pages/fb-check-token",
   REPROT_CUSTOMER_ORDER :"/report/customer_order",
    REPORT_REVENUE:"/report/revenue",
    REPORT_SALE:"/report/sale",
    REPORT_EMPLOYEE:"/report/employee",
    REPORT_CUSTOMER_RANGE:"/report/organizations/bbdda295-23c0-46c9-861e-f0c4825f6b73/customers",
    REPORT_CUSTOMER_SEGMENTS:"/report/organizations/bbdda295-23c0-46c9-861e-f0c4825f6b73/segments",
    REPORT_CUSTOMER_SEGMENTS_COUNT:"/report/organizations/bbdda295-23c0-46c9-861e-f0c4825f6b73/segments/count",
    REPORT_CUSTOMER_ORGANIZATIONS:"/report/organizations/bbdda295-23c0-46c9-861e-f0c4825f6b73",
    EXPORT_VIRAL:"/viral/history/export"
}
