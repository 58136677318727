import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode } from "vue"
import _imports_0 from '@/assets/icons/zalo-seeklogo.com.svg'
import _imports_1 from '@/assets/icons/facebook-svgrepo-com.svg'
import _imports_2 from '@/assets/icons/instagram-svgrepo-com.svg'
import _imports_3 from '@/assets/icons/line-svgrepo-com.svg'


const _hoisted_1 = {
  key: 0,
  draggable: "false",
  src: _imports_0,
  style: {"width":"20px","margin-right":"20px"}
}
const _hoisted_2 = {
  key: 1,
  draggable: "false",
  src: _imports_1,
  style: {"width":"20px","margin-right":"20px"}
}
const _hoisted_3 = {
  key: 2,
  draggable: "false",
  src: _imports_2,
  style: {"width":"20px","margin-right":"20px"}
}
const _hoisted_4 = {
  key: 3,
  draggable: "false",
  src: _imports_3,
  style: {"width":"20px","margin-right":"20px"}
}
const _hoisted_5 = {
  key: 0,
  style: {"margin-top":"20px"}
}
const _hoisted_6 = { style: {"display":"flex"} }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_typography_title = _resolveComponent("a-typography-title")!
  const _component_question_circle_outlined = _resolveComponent("question-circle-outlined")!
  const _component_a_tooltip = _resolveComponent("a-tooltip")!
  const _component_a_row = _resolveComponent("a-row")!
  const _component_a_typography_text = _resolveComponent("a-typography-text")!
  const _component_a_col = _resolveComponent("a-col")!
  const _component_a_button = _resolveComponent("a-button")!
  const _component_a_select_option = _resolveComponent("a-select-option")!
  const _component_a_select = _resolveComponent("a-select")!
  const _component_TagsOutlined = _resolveComponent("TagsOutlined")!
  const _component_PlusOutlined = _resolveComponent("PlusOutlined")!
  const _component_a_input = _resolveComponent("a-input")!
  const _component_ColorPicker = _resolveComponent("ColorPicker")!
  const _component_a_popover = _resolveComponent("a-popover")!
  const _component_a_tag = _resolveComponent("a-tag")!
  const _component_a_tree_select_node = _resolveComponent("a-tree-select-node")!
  const _component_a_tree_select = _resolveComponent("a-tree-select")!
  const _component_MinusOutlined = _resolveComponent("MinusOutlined")!
  const _component_a_card = _resolveComponent("a-card")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_a_row, null, {
      default: _withCtx(() => [
        _createVNode(_component_a_col, { span: 8 }, {
          default: _withCtx(() => [
            _createVNode(_component_a_row, { style: {"align-items":"center"} }, {
              default: _withCtx(() => [
                _createVNode(_component_a_typography_title, { level: 2 }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.$t('conversation_tags')), 1)
                  ]),
                  _: 1
                }),
                _createVNode(_component_a_tooltip, null, {
                  title: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.$t('CONVERSATIONS_TANG_NOTE')), 1)
                  ]),
                  default: _withCtx(() => [
                    _createVNode(_component_question_circle_outlined, { style: {"font-size":"25px","margin-left":"10px","cursor":"pointer"} })
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }),
            _createVNode(_component_a_typography_text, { type: "secondary" }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t('update_your_tags')), 1)
              ]),
              _: 1
            })
          ]),
          _: 1
        }),
        _createVNode(_component_a_col, { span: 6 }),
        ($setup.isPage)
          ? (_openBlock(), _createBlock(_component_a_col, {
              key: 0,
              span: 10
            }, {
              default: _withCtx(() => [
                _createVNode(_component_a_row, { style: {"justify-content":"flex-end"} }, {
                  default: _withCtx(() => [
                    _createVNode(_component_a_button, {
                      onClick: $setup.save,
                      type: "primary"
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.$t('SAVE')), 1)
                      ]),
                      _: 1
                    }, 8, ["onClick"])
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }),
    _createVNode(_component_a_row, { style: {"margin-top":"20px"} }, {
      default: _withCtx(() => [
        _createVNode(_component_a_col, { span: 11 }, {
          default: _withCtx(() => [
            _createVNode(_component_a_typography_title, { level: 5 }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t('change_shop')), 1)
              ]),
              _: 1
            }),
            _createVNode(_component_a_select, {
              allowClea: "",
              value: $setup.currentShop.currentShop.id,
              style: {"width":"100%"},
              placeholder: "Please select",
              onChange: $setup.changeShop
            }, {
              default: _withCtx(() => [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.getShops.shops, (item) => {
                  return (_openBlock(), _createBlock(_component_a_select_option, {
                    key: item.id,
                    value: item.id
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(item.shop_name), 1)
                    ]),
                    _: 2
                  }, 1032, ["value"]))
                }), 128))
              ]),
              _: 1
            }, 8, ["value", "onChange"])
          ]),
          _: 1
        }),
        _createVNode(_component_a_col, { span: 2 }),
        _createVNode(_component_a_col, { span: 11 }, {
          default: _withCtx(() => [
            _createVNode(_component_a_typography_title, { level: 5 }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t('select_page')), 1)
              ]),
              _: 1
            }),
            _createVNode(_component_a_select, {
              allowClea: "",
              "show-search": "",
              style: {"width":"100%"},
              placeholder: "Please select",
              onChange: $setup.changePage,
              value: $setup.getCurrentPageId,
              "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => (($setup.getCurrentPageId) = $event))
            }, {
              default: _withCtx(() => [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.configPage.pageConfig, (item) => {
                  return (_openBlock(), _createBlock(_component_a_select_option, {
                    key: item.id,
                    value: item.page_id
                  }, {
                    default: _withCtx(() => [
                      (item.page_type === 2)
                        ? (_openBlock(), _createElementBlock("img", _hoisted_1))
                        : (item.page_type === 1)
                          ? (_openBlock(), _createElementBlock("img", _hoisted_2))
                          : (item.page_type === 3)
                            ? (_openBlock(), _createElementBlock("img", _hoisted_3))
                            : (item.page_type === 4)
                              ? (_openBlock(), _createElementBlock("img", _hoisted_4))
                              : _createCommentVNode("", true),
                      _createTextVNode(_toDisplayString(item.page_name), 1)
                    ]),
                    _: 2
                  }, 1032, ["value"]))
                }), 128))
              ]),
              _: 1
            }, 8, ["onChange", "value"])
          ]),
          _: 1
        }),
        (!$setup.isPage)
          ? (_openBlock(), _createBlock(_component_a_row, {
              key: 0,
              style: {"margin-top":"30px","width":"100%"},
              justify: "center"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_a_typography_title, { level: 3 }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.$t('PLEASE_CHOOSE_SHOP_AND_FANPAGE')), 1)
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }),
    ($setup.isPage)
      ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
          _createVNode(_component_a_card, {
            style: {"margin-top":"20px","border-radius":"10px"},
            span: 24
          }, {
            title: _withCtx(() => [
              _createVNode(_component_a_row, null, {
                default: _withCtx(() => [
                  _createVNode(_component_a_col, { span: 8 }, {
                    default: _withCtx(() => [
                      _createVNode(_component_TagsOutlined),
                      _createTextVNode(" " + _toDisplayString(_ctx.$t('conversation_tags')), 1)
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_a_col, { span: 14 }),
                  _createVNode(_component_a_col, { span: 2 }, {
                    default: _withCtx(() => [
                      _createVNode(_component_a_button, {
                        onClick: $setup.pushTags,
                        style: {"border-radius":"50%"}
                      }, {
                        icon: _withCtx(() => [
                          _createVNode(_component_PlusOutlined)
                        ]),
                        _: 1
                      }, 8, ["onClick"])
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            default: _withCtx(() => [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.configData.tags, (i, msIndex) => {
                return (_openBlock(), _createBlock(_component_a_row, {
                  key: i.tag_id,
                  style: {"margin-bottom":"20px","width":"100%"}
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_a_col, { span: 10 }, {
                      default: _withCtx(() => [
                        _createVNode(_component_a_input, {
                          onChange: (e) => _ctx.changTagname(msIndex,e),
                          value: i.tag_name,
                          "onUpdate:value": ($event: any) => ((i.tag_name) = $event)
                        }, null, 8, ["onChange", "value", "onUpdate:value"])
                      ]),
                      _: 2
                    }, 1024),
                    _createVNode(_component_a_col, { span: 4 }, {
                      default: _withCtx(() => [
                        _createElementVNode("div", _hoisted_6, [
                          _createVNode(_component_a_popover, { trigger: "click" }, {
                            content: _withCtx(() => [
                              _createVNode(_component_ColorPicker, {
                                theme: "light",
                                color: i.tag_color,
                                "sucker-hide": false,
                                "sucker-canvas": $setup.suckerCanvas,
                                "sucker-area": $setup.suckerArea,
                                onChangeColor: (color) =>$setup.changeColor(color,i.tag_id),
                                onOpenSucker: $setup.openSucker
                              }, null, 8, ["color", "sucker-canvas", "sucker-area", "onChangeColor", "onOpenSucker"])
                            ]),
                            default: _withCtx(() => [
                              _createVNode(_component_a_button, {
                                style: _normalizeStyle({'background-color':i.tag_color?i.tag_color:'white',color:i.tag_color?'white':'black','margin-right':'15px'}),
                                onClick: ($event: any) => (_ctx.colorPickerVisible = i.tag_id)
                              }, {
                                default: _withCtx(() => [
                                  _createTextVNode("Click me")
                                ]),
                                _: 2
                              }, 1032, ["style", "onClick"])
                            ]),
                            _: 2
                          }, 1024)
                        ]),
                        _createTextVNode(" " + _toDisplayString(i.tag_id), 1)
                      ]),
                      _: 2
                    }, 1024),
                    _createVNode(_component_a_col, { span: 6 }, {
                      default: _withCtx(() => [
                        _createVNode(_component_a_tree_select, {
                          "show-search": "",
                          style: {"width":"100%"},
                          value: i.sale_tag,
                          "onUpdate:value": ($event: any) => ((i.sale_tag) = $event),
                          filterTreeNode: false,
                          notFoundContent: null,
                          "dropdown-style": { maxHeight: '300', overflow: 'auto' },
                          "allow-clear": "",
                          placeholder: _ctx.$t('TAG_SELECT')
                        }, {
                          default: _withCtx(() => [
                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.treeTags, (item) => {
                              return (_openBlock(), _createBlock(_component_a_tree_select_node, {
                                key: item.id,
                                value: item.id
                              }, {
                                title: _withCtx(() => [
                                  _createVNode(_component_a_tag, {
                                    color: item.color
                                  }, {
                                    default: _withCtx(() => [
                                      _createTextVNode(_toDisplayString(item.name), 1)
                                    ]),
                                    _: 2
                                  }, 1032, ["color"])
                                ]),
                                default: _withCtx(() => [
                                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(item.children, (child) => {
                                    return (_openBlock(), _createBlock(_component_a_tree_select_node, {
                                      key: child.id,
                                      value: child.id
                                    }, {
                                      title: _withCtx(() => [
                                        _createVNode(_component_a_tag, {
                                          color: child.color
                                        }, {
                                          default: _withCtx(() => [
                                            _createTextVNode(_toDisplayString(child.name), 1)
                                          ]),
                                          _: 2
                                        }, 1032, ["color"])
                                      ]),
                                      _: 2
                                    }, 1032, ["value"]))
                                  }), 128))
                                ]),
                                _: 2
                              }, 1032, ["value"]))
                            }), 128))
                          ]),
                          _: 2
                        }, 1032, ["value", "onUpdate:value", "placeholder"])
                      ]),
                      _: 2
                    }, 1024),
                    _createVNode(_component_a_col, { span: 4 }, {
                      default: _withCtx(() => [
                        _createVNode(_component_a_button, {
                          onClick: ($event: any) => ($setup.removeTag(i.tag_id)),
                          style: {"border-radius":"50%","margin-left":"16px"}
                        }, {
                          icon: _withCtx(() => [
                            _createVNode(_component_MinusOutlined)
                          ]),
                          _: 2
                        }, 1032, ["onClick"])
                      ]),
                      _: 2
                    }, 1024)
                  ]),
                  _: 2
                }, 1024))
              }), 128))
            ]),
            _: 1
          })
        ]))
      : _createCommentVNode("", true)
  ], 64))
}