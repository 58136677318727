import {Flow, ZnsTemp} from "@/models/Flows/Flow";
import {FlowContextData} from "@/models/Flows/FlowContext";
import {FlowConstants} from "@/models/Flows/FlowConstants";
import {Metadata} from "@/APIs/response/S_HttpResponse";
import {Wallet} from "@/models/Wallet/Wallet";
import {FlowZNSTransaction} from "@/models/Wallet/FlowTransaction";
import {WalletTransaction} from "@/models/Wallet/WalletTransaction";


export type State = {
    wallet: Wallet | undefined;
    isWalletFetching: boolean;
    znsTransactions: FlowZNSTransaction[];
    znsTransCount: number;
    znsTransTotalAmount: number;
    walletTransactions: WalletTransaction[];
    walletTransactionCount: number;
}

export const state: State = {
    wallet : undefined,
    isWalletFetching : true,
    znsTransactions : [],
    znsTransCount : 0,
    znsTransTotalAmount : 0,
    walletTransactions:[],
    walletTransactionCount:0
}
