<template>
  <div class="flow-type-container">
    <a-button type="dashed" size="large" block :loading="isLoadingButton" @click="newFacebookFlow">
      <facebook-outlined style="font-size: 18px"/>
      {{ $t("FLOW_SCRIPT_FACEBOOK") }}
    </a-button>

      <a-button  class="button-2-margin-top" type="dashed" size="large" block :loading="isLoadingButton" @click="newInstagramFlow">
        <instagram-outlined style="font-size: 18px" />
        {{ $t("FLOW_SCRIPT_INSTAGRAM") }}
      </a-button>

    <a-button type="dashed" size="large" class="button-2-margin-top" block :loading="isLoadingButton" @click="newReplyAndPrivateMessage">
      <facebook-outlined style="font-size: 18px"/>
      {{$t('REPLY_COMMENT_PRIVATE_MESSAGE')}}
    </a-button>

    <a-button type="dashed" size="large" block class="button-2-margin-top" :loading="isLoadingButton" @click="newZaloZNSFlow">
      <img src="@/assets/icons/zalo-flat.svg" class="anticon" style="width: 18px;height: 18px;"/>
     {{$t('FLOW_ZALO_ZNS')}}
    </a-button>
    <a-button type="dashed" size="large" block class="button-2-margin-top" :loading="isLoadingButton" @click="newZaloFlow">
      <img src="@/assets/icons/zalo-flat.svg" class="anticon" style="width: 18px;height: 18px;"/>
     {{$t('FLOW_ZALO')}}
    </a-button>
    <a-button type="dashed" size="large" block class="button-2-margin-top" :loading="isLoadingButton" @click="newMailFlow">
      <MailOutlined style="font-size: 18px"/>
      {{$t('EMAIL_CONTEXT')}}
    </a-button>

    <a-button type="dashed" size="large" block class="button-2-margin-top" :loading="isLoadingButton" @click="newViralLuckyWheelFlow">
      <img src="@/assets/icons/flow-viral-icon.png" class="anticon" style="width: 18px;height: 18px;"/>
      {{$t('VIRAL_LUCKY_WHEEL')}}
    </a-button>
    <a-button type="dashed" size="large" block class="button-2-margin-top" :loading="isLoadingButton" @click="newViralShareGiftFlow">
      <img src="@/assets/icons/flow-viral-icon.png" class="anticon" style="width: 18px;height: 18px;"/>
      {{$t('VIRAL_SHARE_GIFT')}}
    </a-button>
    <a-button type="dashed" size="large" block class="button-2-margin-top" :loading="isLoadingButton" @click="newVoiceText">
      <phone-outlined style="font-size: 18px"/>
      {{$t('VOICE_TEXT')}}
    </a-button>
    <a-button type="dashed" size="large" block class="button-2-margin-top" :loading="isLoadingButton" @click="newTriggerCondition">
      <phone-outlined style="font-size: 18px"/>
      {{$t('TRIGGERS')}}
    </a-button>
    <a-button type="dashed" size="large" block class="button-2-margin-top" :loading="isLoadingButton" @click="newTriggerOrder">
      <phone-outlined style="font-size: 18px"/>
      {{$t('ORDERS')}}
    </a-button>
  </div>
</template>

<script lang="ts">
import {defineComponent} from 'vue';
import {FacebookOutlined,MailOutlined,PhoneOutlined,InstagramOutlined} from '@ant-design/icons-vue'
import {FlowContext} from "@/models/Flows/FlowContext";
import APIs from "@/APIs/APIs";
import {S_EventBus} from "@/utils/EventBus";
import {MixUtils} from "@/utils/Utils";

export default defineComponent({
  name: "flow-setup-type",
  props: ['flowContextData'],
  components: {
    FacebookOutlined,
    MailOutlined,
    PhoneOutlined,
    InstagramOutlined
  },
  data() {
    return {
      isLoadingButton: false
    }
  },
  methods: {
    newFacebookFlow() {
      const type = 1;
      let name = "1"
      if (this.flowContextData && this.flowContextData.context.flow_contexts && this.flowContextData.context.flow_contexts.length) {
        name = this.flowContextData.context.flow_contexts.length + 1
      }
      var resName = "";
      var resMess = "";
      if (type === 1) {
        resName = "#Facebook# " + this.$t('MESS_NAME_CONTENT') + name;
        resMess = "#Facebook " + this.$t('FLOW_MESSAGE_CONTENT') + name;
      } else if (type === 2) {
        resName = "#Zalo# " + this.$t('MESS_NAME_CONTENT') + name;
        resMess = "#Zalo " + this.$t('FLOW_MESSAGE_CONTENT') + name;
      }

      let flow: FlowContext = {
        "id": MixUtils.methods.getUUID(),
        "name": resName,
        "shop_id": this.flowContextData.shop_id,
        "flow_type": type,
        "message": resMess,
        "attachment_images": [],
        "type": "",
        "messenger_tag": "",
        "template_id": "",
        "template_name": "",
        "template_status": "",
        "preview_url": "",
        "created_at": new Date().toISOString(),
        "updated_at": new Date().toISOString(),
        "postback_contexts": [],
        "action_send_success_context": {
          "flow_crm_actions": [],
          "flow_action_stack": {}
        },
        "action_send_failed_context": {
          "flow_crm_actions": [],
          "flow_action_stack": {}
        },
        "action_default_context": {
          "flow_crm_actions": [],
          "flow_action_stack": {}
        }
      }

      S_EventBus.instance().dispatchCreateRootFlowContext(flow)
    },
    newInstagramFlow() {
      const type = 11;
      let name = "11"
      if (this.flowContextData && this.flowContextData.context.flow_contexts && this.flowContextData.context.flow_contexts.length) {
        name = this.flowContextData.context.flow_contexts.length + 1
      }
      var resName = "";
      var resMess = "";
      if (type === 11) {
        resName = "#Instagram# " + this.$t('MESS_NAME_CONTENT') + name;
        resMess = "#Instagram " + this.$t('FLOW_MESSAGE_CONTENT') + name;
      } else if (type === 2) {
        resName = "#Zalo# " + this.$t('MESS_NAME_CONTENT') + name;
        resMess = "#Zalo " + this.$t('FLOW_MESSAGE_CONTENT') + name;
      }

      let flow: FlowContext = {
        "id": MixUtils.methods.getUUID(),
        "name": resName,
        "shop_id": this.flowContextData.shop_id,
        "flow_type": type,
        "message": resMess,
        "attachment_images": [],
        "type": "",
        "messenger_tag": "",
        "template_id": "",
        "template_name": "",
        "template_status": "",
        "preview_url": "",
        "created_at": new Date().toISOString(),
        "updated_at": new Date().toISOString(),
        "postback_contexts": [],
        "action_send_success_context": {
          "flow_crm_actions": [],
          "flow_action_stack": {}
        },
        "action_send_failed_context": {
          "flow_crm_actions": [],
          "flow_action_stack": {}
        },
        "action_default_context": {
          "flow_crm_actions": [],
          "flow_action_stack": {}
        }
      }

      S_EventBus.instance().dispatchCreateRootFlowContext(flow)
    },
    newReplyAndPrivateMessage() {
      const type = 3;
      let name = "3"
      if (this.flowContextData && this.flowContextData.context.flow_contexts && this.flowContextData.context.flow_contexts.length) {
        name = this.flowContextData.context.flow_contexts.length + 1
      }
      var resName = "";
      var resMess = "";
      resName = "#Facebook Reply Comment# " + this.$t('MESS_NAME_CONTENT') + name;
      resMess = "#Facebook Reply Comment" + this.$t('FLOW_MESSAGE_CONTENT') + name;

      let flow: FlowContext = {
        "id": MixUtils.methods.getUUID(),
        "name": resName,
        "shop_id": this.flowContextData.shop_id,
        "flow_type": type,
        "message": resMess,
        "attachment_images": [],
        "type": "",
        "messenger_tag": "",
        "template_id": "",
        "template_name": "",
        "template_status": "",
        "preview_url": "",
        "created_at": new Date().toISOString(),
        "updated_at": new Date().toISOString(),
        "postback_contexts": [],
        "action_send_success_context": {
          "flow_crm_actions": [],
          "flow_action_stack": {}
        },
        "action_send_failed_context": {
          "flow_crm_actions": [],
          "flow_action_stack": {}
        },
        "action_default_context": {
          "flow_crm_actions": [],
          "flow_action_stack": {}
        }
      }

      S_EventBus.instance().dispatchCreateRootFlowContext(flow)
    },
    newZaloZNSFlow() {
      const type = 2;
      let name = "1"
      if (this.flowContextData && this.flowContextData.context.flow_contexts && this.flowContextData.context.flow_contexts.length) {
        name = this.flowContextData.context.flow_contexts.length + 1
      }
      var resName = "";
      var resMess = "";

      resName = "#Zalo# " + this.$t('MESS_NAME_CONTENT') + name;
      resMess = "#Zalo " + this.$t('FLOW_MESSAGE_CONTENT') + name;

      let flow: FlowContext = {
        "id": MixUtils.methods.getUUID(),
        "name": resName,
        "shop_id": this.flowContextData.shop_id,
        "flow_type": type,
        "message": resMess,
        "attachment_images": [],
        "type": "",
        "messenger_tag": "",
        "template_id": "",
        "template_name": "",
        "template_status": "",
        "preview_url": "",
        "created_at": new Date().toISOString(),
        "updated_at": new Date().toISOString(),
        "postback_contexts": [],
        "action_send_success_context": {
          "flow_crm_actions": [],
          "flow_action_stack": {}
        },
        "action_send_failed_context": {
          "flow_crm_actions": [],
          "flow_action_stack": {}
        },
        "action_default_context": {
          "flow_crm_actions": [],
          "flow_action_stack": {}
        }
      }
      S_EventBus.instance().dispatchCreateRootFlowContext(flow)
    },
    newZaloFlow() {
      const type = 4;
      let name = "1"
      if (this.flowContextData && this.flowContextData.context.flow_contexts && this.flowContextData.context.flow_contexts.length) {
        name = this.flowContextData.context.flow_contexts.length + 1
      }
      var resName = "";
      var resMess = "";

      resName = "#Zalo# " + this.$t('MESS_NAME_CONTENT') + name;
      resMess = "#Zalo " + this.$t('FLOW_MESSAGE_CONTENT') + name;

      let flow: FlowContext = {
        "id": MixUtils.methods.getUUID(),
        "name": resName,
        "shop_id": this.flowContextData.shop_id,
        "flow_type": type,
        "message": resMess,
        "attachment_images": [],
        "type": "",
        "messenger_tag": "",
        "template_id": "",
        "template_name": "",
        "template_status": "",
        "preview_url": "",
        "created_at": new Date().toISOString(),
        "updated_at": new Date().toISOString(),
        "postback_contexts": [],
        "action_send_success_context": {
          "flow_crm_actions": [],
          "flow_action_stack": {}
        },
        "action_send_failed_context": {
          "flow_crm_actions": [],
          "flow_action_stack": {}
        },
        "action_default_context": {
          "flow_crm_actions": [],
          "flow_action_stack": {}
        }
      }
      S_EventBus.instance().dispatchCreateRootFlowContext(flow)
    },
    newViralLuckyWheelFlow() {
      const type = 6;
      let name = "1"
      if (this.flowContextData && this.flowContextData.context.flow_contexts && this.flowContextData.context.flow_contexts.length) {
        name = this.flowContextData.context.flow_contexts.length + 1
      }
      var resName = "";
      var resMess = "";

      resName = "#Viral# " + this.$t('MESS_NAME_CONTENT') + name;
      resMess = "#Viral " + this.$t('FLOW_MESSAGE_CONTENT') + name;

      let flow: FlowContext = {
        "id": MixUtils.methods.getUUID(),
        "name": resName,
        "shop_id": this.flowContextData.shop_id,
        "flow_type": type,
        "message": resMess,
        "attachment_images": [],
        "type": "",
        "messenger_tag": "",
        "template_id": "",
        "template_name": "",
        "template_status": "",
        "preview_url": "",
        "created_at": new Date().toISOString(),
        "updated_at": new Date().toISOString(),
        flow_viral:[{
          id: MixUtils.methods.getUUID(),
          num_play:3,
          invite_get_num_play:1,
          type:'lucky_wheel',
        }],
        "postback_contexts": [],
        "action_send_success_context": {
          "flow_crm_actions": [],
          "flow_action_stack": {}
        },
        "action_send_failed_context": {
          "flow_crm_actions": [],
          "flow_action_stack": {}
        },
        "action_default_context": {
          "flow_crm_actions": [],
          "flow_action_stack": {}
        }
      }
      S_EventBus.instance().dispatchCreateRootFlowContext(flow)
    },
    newViralShareGiftFlow() {
      const type = 9;
      let name = "1"
      if (this.flowContextData && this.flowContextData.context.flow_contexts && this.flowContextData.context.flow_contexts.length) {
        name = this.flowContextData.context.flow_contexts.length + 1
      }
      var resName = "";
      var resMess = "";

      resName = "#Viral# " + this.$t('MESS_NAME_CONTENT') + name;
      resMess = "#Viral " + this.$t('FLOW_MESSAGE_CONTENT') + name;

      let flow: FlowContext = {
        "id": MixUtils.methods.getUUID(),
        "name": resName,
        "shop_id": this.flowContextData.shop_id,
        "flow_type": type,
        "message": resMess,
        "attachment_images": [],
        "type": "",
        "messenger_tag": "",
        "template_id": "",
        "template_name": "",
        "template_status": "",
        "preview_url": "",
        "created_at": new Date().toISOString(),
        "updated_at": new Date().toISOString(),
        "viral_must_share":3,
        flow_viral:[{
          id: MixUtils.methods.getUUID(),
          num_play:3,
          invite_get_num_play:1,
          type:'form'
        },{
          id: MixUtils.methods.getUUID(),
          num_play:3,
          invite_get_num_play:1,
          type:'form'
        }
        ],
        "postback_contexts": [],
        "action_send_success_context": {
          "flow_crm_actions": [],
          "flow_action_stack": {}
        },
        "action_finish_viral": {
          "flow_crm_actions": [],
          "flow_action_stack": {}
        },
        "action_send_failed_context": {
          "flow_crm_actions": [],
          "flow_action_stack": {}
        },
        "action_default_context": {
          "flow_crm_actions": [],
          "flow_action_stack": {}
        }
      }
      S_EventBus.instance().dispatchCreateRootFlowContext(flow)
    },

    newMailFlow() {
      const type = 5;
      let name = "1"
      if (this.flowContextData && this.flowContextData.context.flow_contexts && this.flowContextData.context.flow_contexts.length) {
        name = this.flowContextData.context.flow_contexts.length + 1
      }
      var resName = "";
      var resMess = "";

      resName = "#Mail# " + this.$t('MESS_NAME_CONTENT') + name;
      resMess = "#Mail " + this.$t('FLOW_MESSAGE_CONTENT') + name;

      let flow: FlowContext = {
        "id": MixUtils.methods.getUUID(),
        "name": resName,
        "shop_id": this.flowContextData.shop_id,
        "flow_type": type,
        "message": resMess,
        "attachment_images": [],
        "type": "",
        "messenger_tag": "",
        "template_id": "",
        "template_name": "",
        "template_status": "",
        "preview_url": "",
        "created_at": new Date().toISOString(),
        "updated_at": new Date().toISOString(),
        "postback_contexts": [],
        "action_open_mail_context": {
          "flow_crm_actions": [],
          "flow_action_stack": {}
        },
        action_click_to_link_context: {
          "flow_crm_actions": [],
          "flow_action_stack": {}
        },
        "action_send_success_context": {
          "flow_crm_actions": [],
          "flow_action_stack": {}
        },
        "action_send_failed_context": {
          "flow_crm_actions": [],
          "flow_action_stack": {}
        },
        "action_default_context": {
          "flow_crm_actions": [],
          "flow_action_stack": {}
        }
      }
      S_EventBus.instance().dispatchCreateRootFlowContext(flow)
    },
    newVoiceText() {
      const type = 7;
      let name = "1"
      if (this.flowContextData && this.flowContextData.context.flow_contexts && this.flowContextData.context.flow_contexts.length) {
        name = this.flowContextData.context.flow_contexts.length + 1
      }
      var resName = "";
      var resMess = "";

      resName = "#Voice text# " + this.$t('MESS_NAME_CONTENT') + name;
      resMess = "#Voice text " + this.$t('FLOW_MESSAGE_CONTENT') + name;

      let flow: FlowContext = {
        "id": MixUtils.methods.getUUID(),
        "name": resName,
        "shop_id": this.flowContextData.shop_id,
        "flow_type": type,
        "message": resMess,
        "flow_smart_call":[{
          "key_pairs":[
          ],
          "key_pair_report":[]
        }],
        "attachment_images": [],
        "type": "",
        "messenger_tag": "",
        "template_id": "",
        "template_name": "",
        "template_status": "",
        "preview_url": "",
        "created_at": new Date().toISOString(),
        "updated_at": new Date().toISOString(),
        "postback_contexts": [],
        "action_open_mail_context": {
          "flow_crm_actions": [],
          "flow_action_stack": {}
        },
        action_click_to_link_context: {
          "flow_crm_actions": [],
          "flow_action_stack": {}
        },
        "action_send_success_context": {
          "flow_crm_actions": [],
          "flow_action_stack": {}
        },
        "action_send_failed_context": {
          "flow_crm_actions": [],
          "flow_action_stack": {}
        },
        "action_default_context": {
          "flow_crm_actions": [],
          "flow_action_stack": {}
        }
      }
      S_EventBus.instance().dispatchCreateRootFlowContext(flow)
    },
    newTriggerCondition() {
      const type = 10;
      let name = "1"
      if (this.flowContextData && this.flowContextData.context.flow_contexts && this.flowContextData.context.flow_contexts.length) {
        name = this.flowContextData.context.flow_contexts.length + 1
      }
      var resName = "";
      var resMess = "";

      resName = "#Trigger condition# " + this.$t('MESS_NAME_CONTENT') + name;
      resMess = "#Trigger condition " + this.$t('FLOW_MESSAGE_CONTENT') + name;

      let flow: FlowContext = {
        "id": MixUtils.methods.getUUID(),
        "name": resName,
        "shop_id": this.flowContextData.shop_id,
        "flow_type": type,
        "message": resMess,
        "trigger_condition":{
          "condition":"",
          "trigger_comparisons":[]
        },
        "attachment_images": [],
        "type": "",
        "messenger_tag": "",
        "template_id": "",
        "template_name": "",
        "template_status": "",
        "preview_url": "",
        "created_at": new Date().toISOString(),
        "updated_at": new Date().toISOString(),
        "postback_contexts": [],
        "action_open_mail_context": {
          "flow_crm_actions": [],
          "flow_action_stack": {}
        },
        action_click_to_link_context: {
          "flow_crm_actions": [],
          "flow_action_stack": {}
        },
        "action_send_success_context": {
          "flow_crm_actions": [],
          "flow_action_stack": {}
        },
        "action_send_failed_context": {
          "flow_crm_actions": [],
          "flow_action_stack": {}
        },
        "action_default_context": {
          "flow_crm_actions": [],
          "flow_action_stack": {}
        }
      }
      S_EventBus.instance().dispatchCreateRootFlowContext(flow)
    },
    newTriggerOrder() {
      const type = 1212;
      let name = "1"
      if (this.flowContextData && this.flowContextData.context.flow_contexts && this.flowContextData.context.flow_contexts.length) {
        name = this.flowContextData.context.flow_contexts.length + 1
      }
      var resName = "";
      var resMess = "";

      resName = "#Trigger condition# " + this.$t('MESS_NAME_CONTENT') + name;
      resMess = "#Trigger condition " + this.$t('FLOW_MESSAGE_CONTENT') + name;

      let flow: FlowContext = {
        "id": MixUtils.methods.getUUID(),
        "name": resName,
        "shop_id": this.flowContextData.shop_id,
        "flow_type": type,
        "message": resMess,
        "trigger_condition":{
          "condition":"",
          "trigger_comparisons":[]
        },
        "attachment_images": [],
        "type": "",
        "messenger_tag": "",
        "template_id": "",
        "template_name": "",
        "template_status": "",
        "preview_url": "",
        "created_at": new Date().toISOString(),
        "updated_at": new Date().toISOString(),
        "postback_contexts": [],
        "action_open_mail_context": {
          "flow_crm_actions": [],
          "flow_action_stack": {}
        },
        action_click_to_link_context: {
          "flow_crm_actions": [],
          "flow_action_stack": {}
        },
        "action_send_success_context": {
          "flow_crm_actions": [],
          "flow_action_stack": {}
        },
        "action_send_failed_context": {
          "flow_crm_actions": [],
          "flow_action_stack": {}
        },
        "action_default_context": {
          "flow_crm_actions": [],
          "flow_action_stack": {}
        }
      }
      S_EventBus.instance().dispatchCreateRootFlowContext(flow)
    }
  }
})
</script>

<style lang="scss" scoped>
.flow-type-container {
  padding-bottom: 80px;
  padding-left: 18%;
  padding-right: 18%;
}

.button-2-margin-top {
  margin-top: 14px;
}
</style>
