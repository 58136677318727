import create from "zustand-vue";
import { persist } from "zustand/middleware";
import APIs from "@/APIs/APIs";
import { ShopInfo, UpdateShopInfo } from "@/models/Shopinfo/ShopInfo";
import { Page } from "@/models/Users";

interface ShopInfoState {
  dataShopInfo: ShopInfo | UpdateShopInfo | undefined;
  mailPages: Page[];
  getShopinfo: () => void;
  getMailPage: () => void;
}

const initialState = {
  dataShopInfo: {},
  mailPages: [],
};

const useShopInfoStore = create<ShopInfoState>(
  // @ts-ignore
  persist(
    (set) => ({
      ...initialState,
      getShopinfo: async () => {
        const response = await APIs.init().getShopInfo();
        if (response.data && response.data.status === 200) {
          // @ts-ignore
          set(() => ({
            // @ts-ignore
            dataShopInfo: response.data.data,
          }));
        }
      },
      getMailPage: async () => {
        const response = await APIs.init().getMailList();
        if (response.data && response.data.status === 200) {
          // @ts-ignore
          set(() => ({
            // @ts-ignore
            mailPages: response.data.data,
          }));
        }
      },
    }),
    {
      name: "shop-info",
    }
  )
);

export default useShopInfoStore;
