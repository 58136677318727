import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, createVNode as _createVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "container-content" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_HomeOutlined = _resolveComponent("HomeOutlined")!
  const _component_a_row = _resolveComponent("a-row")!
  const _component_facebook_uid = _resolveComponent("facebook-uid")!
  const _component_a_tab_pane = _resolveComponent("a-tab-pane")!
  const _component_a_tabs = _resolveComponent("a-tabs")!
  const _component_a_card = _resolveComponent("a-card")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_a_card, { style: {"width":"100%"} }, {
      default: _withCtx(() => [
        _createVNode(_component_a_tabs, {
          activeKey: $setup.activeKey,
          "onUpdate:activeKey": _cache[0] || (_cache[0] = ($event: any) => (($setup.activeKey) = $event)),
          "tab-position": $setup.tabPosition
        }, {
          default: _withCtx(() => [
            _createVNode(_component_a_tab_pane, { key: "1" }, {
              tab: _withCtx(() => [
                _createVNode(_component_a_row, { justify: "flex-start" }, {
                  default: _withCtx(() => [
                    _createElementVNode("span", null, [
                      ($setup.activeKey === '1')
                        ? (_openBlock(), _createBlock(_component_HomeOutlined, { key: 0 }))
                        : _createCommentVNode("", true),
                      _createTextVNode(" " + _toDisplayString(_ctx.$t('Facebook uid')), 1)
                    ])
                  ]),
                  _: 1
                })
              ]),
              default: _withCtx(() => [
                _createVNode(_component_facebook_uid)
              ]),
              _: 1
            })
          ]),
          _: 1
        }, 8, ["activeKey", "tab-position"])
      ]),
      _: 1
    })
  ]))
}