import create from "zustand-vue";
import {Orders} from "@/models/Orders/Orders";
import APIs from "@/APIs/APIs";

interface OrderState {
    getOrderLoading: boolean
    orders: Orders[];
    metadata:any
    getOrders:  (params: any)=>void;
}

const initialState = {
    getOrderLoading: true,
    orders: [],
    metadata:{}
}


const useOrderStore = create<OrderState>((set) => ({
    ...initialState,
    getOrders: async (params) =>{
        set(()=>({getOrderLoading: true}))
        const response = await APIs.init().getOrders(params)
        if(response.data?.status===200){
            set(()=>({getOrderLoading: false, orders: response.data?.data?response.data?.data:[],metadata: response.data?.meta_data }))
        }else{
            set(()=>({getOrderLoading: false, orders: []}))
        }
        console.log('getOrder', response)
    },
}))

export default useOrderStore
