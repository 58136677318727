<template>

  <div class="container-title">
    <h3 class="title-header">{{$t($route.name)}}</h3>
    <router-link :to="`/ticket/list-ticket-type/${$route.query.event_id}`">
    <a-button >
     {{$t('LIST_TICKET_TYPE')}}
    </a-button>
  </router-link>


      <a-button style="margin-left: 20px"    @click="rollBack" >
        <template #icon><rollback-outlined /> </template>
      </a-button>

    <a-button style="margin-left: 20px" @click="onSubmit" type="primary">{{$t('SAVE')}}</a-button>
  </div>
  <div style="margin: 20px" class="container">
    <a-row>
     <a-col :span="16">
       <a-card style="width: 100%">
         <a-form
             :model="formState"
             name="basic"
             ref="formRefTicket"
             :label-col="{ span: 10 }"
             :wrapper-col="{ span: 14 }"
             autocomplete="off"
             :rules="rules"
             layout="horizontal"
             labelAlign="left"
         >

           <a-row>
             <a-col :span="12">
               <a-form-item
                   style="margin-right: 20px;"
                   :label="$t('FULL_NAME')"
                   name="full_name"

               >
                 <a-input v-model:value="formState.full_name" />
               </a-form-item>
               <a-form-item
                   style="margin-right: 20px;"
                   :label="$t('BIB')"

               >
                 <a-input v-model:value="formState.bib" />
               </a-form-item>
               <a-form-item
                   style="margin-right: 20px;"
                   :label="$t('ALIAS_NAME')"

               >
                 <a-input v-model:value="formState.alias_name" />
               </a-form-item>
               <a-form-item
                   style="margin-right: 20px;"
                   :label="$t('TICKET_TYPE')"
                   name="ticket_type_id"

               >
                 <a-row style="align-items: center" >
                   <a-select
                       allowClear
                       v-model:value="formState.ticket_type_id"
                       placeholder="Please select"
                       style="width: 60%"

                   >
                     <a-select-option
                         v-for="item in getTicketType.ticketTypes"
                         :key="item.id"
                         v-bind:value="item.id"

                     >{{ item.name }}
                     </a-select-option>

                   </a-select>
                   <a-button style="width:35%;margin-left: 5px" size="small"  @click="addTicketType"  type="primary">{{ $t('quick_create') }}</a-button>
                 </a-row>

               </a-form-item>
               <a-form-item
                   style="margin-right: 20px;"
                   :label="$t('CUSTOMER_PHONE')"
                   name="phone"

               >
                 <a-input v-model:value="formState.phone" />
               </a-form-item>
               <a-form-item
                   style="margin-right: 20px;"
                   :label="$t('Email')"
                   name="email"

               >
                 <a-input v-model:value="formState.email" />
               </a-form-item>
               <a-form-item
                   style="margin-right: 20px;"
                   :label="$t('medical_history')"
               >
                 <a-textarea v-model:value="formState.medical_history"  />
               </a-form-item>
             </a-col>
             <a-col :span="12">

               <a-form-item
                   style="margin-right: 20px;"
                   :label="$t('ORGANIZATION')"

               >
                 <a-input v-model:value="formState.organization" />
               </a-form-item>
               <a-form-item
                   style="margin-right: 20px;"
                   :label="$t('BIRTHDAY')"

               >
                 <a-date-picker
                     v-model:value="formState.birthday"
                     :placeholder="$t('CUSTOMER_BIRTHDAY')"
                     format="DD-MM-YYYY"
                     style="width: 100%"
                 />

               </a-form-item>
               <a-form-item
                   style="margin-right: 20px;"
                   :label="$t('SEX')"

               >
                 <a-radio-group name="radioGroup" v-model:value="formState.gender">
                   <a-radio :value="0">{{ $t('MALE') }}</a-radio>
                   <a-radio :value="1">{{ $t('FEMALE') }}</a-radio>
                   <a-radio :value="2">{{ $t('OTHER') }}</a-radio>
                 </a-radio-group>

               </a-form-item>
               <a-form-item
                   style="margin-right: 20px;"
                   :label="$t('BLOOD_GROUP')"
               >
                 <a-input v-model:value="formState.blood_group" />
               </a-form-item>
               <a-form-item
                   style="margin-right: 20px;"
                   :label="$t('NATIONAL_CARD_ID')"

               >
                 <a-input v-model:value="formState.national_card_id" />
               </a-form-item>
               <a-form-item
                   style="margin-right: 20px;"
                   :label="$t('NATIONAL_ID')"

               >
                 <a-input v-model:value="formState.national_id" />
               </a-form-item>
               <a-form-item
                   style="margin-right: 20px;"
                   :label="$t('PROVINCE')"

               >
                 <a-select
                     v-model:value="formState.province_id"
                     showSearch
                     style="width: 100%"
                     :filter-option="false"
                     :not-found-content="null"
                     :placeholder="$t('PROVINCE')"
                     @change="provinceChange"
                     @search="provinceSearch"
                 >
                   <a-select-option
                       v-for="item in getProvinces"
                       :key="item.id"
                       :value="item.id"
                   >{{ item.name }}
                   </a-select-option>
                 </a-select>

               </a-form-item>
             </a-col>
           </a-row>







         </a-form>
       </a-card>
     </a-col>
      <a-col :span="1"></a-col>
     <a-col :span="7">
       <a-card style="width: 100%">
         <a-form
             :model="formState"
             name="basic"

             autocomplete="off"
             layout="vertical"
             labelAlign="left"
             ref="formRefReference"
             :rules="rules2"
         >


<!--           <a-form-item-->
<!--               style="margin-right: 20px;"-->
<!--               :label="$t('reference_id')"-->
<!--               name="reference_id"-->

<!--           >-->
<!--             <a-input v-model:value="formState.reference_id" />-->
<!--           </a-form-item>-->
           <a-form-item
               style="margin-right: 20px;"
               :label="$t('reference_name')"
               name="reference_name"

           >
             <a-input v-model:value="formState.reference_name" />
           </a-form-item>
           <a-form-item
               style="margin-right: 20px;"
               :label="$t('reference_phone')"
               name="reference_phone"

           >
             <a-input v-model:value="formState.reference_phone" />
           </a-form-item>
           <a-form-item
               style="margin-right: 20px;"
               :label="$t('Option 1')"
               name="reference_phone"

           >
             <a-input v-model:value="formState.option_1" />
           </a-form-item>
           <a-form-item
               style="margin-right: 20px;"
               :label="$t('Option 2')"
               name="reference_phone"

           >
             <a-input v-model:value="formState.option_2" />
           </a-form-item>
           <a-form-item
               style="margin-right: 20px;"
               :label="$t('Option 3')"
               name="reference_phone"

           >
             <a-input v-model:value="formState.option_3" />
           </a-form-item>









         </a-form>
       </a-card>
     </a-col>
    </a-row>
  </div>
  <a-modal style="width: 60%" v-model:visible="visible" :title="$t('CREATED_EVENT')" @ok="handleOk">




    <a-row>

      <a-col :span=10>



        <a-form
            :model="ticketType"
            ref="formCustomerRef"
            :rules="rules"
            layout="vertical"

        >
          <a-form-item :label="$t('TICKET_TYPE_NAME')" name="event_name">
            <a-input
                v-model:value="ticketType.name"
                :placeholder="$t('TICKET_TYPE_NAME')"


            />
          </a-form-item>

          <a-form-item :label="$t('DESCRIPTION')">

            <a-textarea v-model:value="ticketType.description"
                        :placeholder="$t('DESCRIPTION')" show-count :maxlength="1200" />
          </a-form-item>
          <a-form-item :label="$t('TICKET_TYPE_START_BIB')" name="start_bib">

            <a-input-number
                v-model:value="ticketType.start_bib"
                :placeholder="$t('TICKET_TYPE_START_BIB')"
                style="width: 100%"

            />
          </a-form-item>
          <a-form-item :label="$t('TEMPLATE')" name="template_id">
            <a-select

                allowClear
                v-model:value="ticketType.template_id"
                :placeholder="$t('TEMPLATE')"
                @change="onChangeEmployee"
            >
              <a-select-option
                  v-for="item in templates.templates"
                  :key="item.id"
                  v-bind:value="item.id"
              >{{ item.name }}
              </a-select-option>
            </a-select>
          </a-form-item>
        </a-form>
      </a-col>
      <a-col :span="1">

      </a-col>
      <a-col :span="10">
        <a-form
            :model="ticketPrice"
            ref="formCustomerRef"
            :rules="rules"
            layout="vertical"

        >
          <div v-for="(i,index) in  ticketPrice" :key="i">
            <a-form-item :label="$t('sale_price') +' '+  (index+1)" name="price_in_advance">
              <a-input-number
                  v-model:value="i.price"
                  style="width: 100%"
                  :formatter="value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')"
                  :parser="value => value.replace(/\$\s?|(,*)/g, '')"
                  string-mode

              />
            </a-form-item>
            <a-form-item :label="$t('time_visit')" name="birth">
              <a-range-picker style="width: 100%"  v-model:value="i.time_visit" format="DD-MM-YYYY HH:mm"  />
            </a-form-item>


          </div>
          <a-tooltip placement="topLeft">
            <template #title>
              <span>{{$t('ADD')}} {{$t('sale_price')}}</span>
            </template>
            <a-button @click="ticketPricePush"  shape="circle">
              <template #icon><plus-outlined /></template>
            </a-button>
          </a-tooltip>
        </a-form>
      </a-col>
    </a-row>

  </a-modal>
</template>

<script lang="ts">
import {computed, onMounted, ref} from "vue";
import {Ticket} from "@/models/Ticket/Ticket";
import {useStore} from "vuex";
import {Province} from "@/models/Dictionary/Dictionary";
import {MixUtils} from "@/utils/Utils";
import {useI18n} from "vue-i18n";
import APIs from "@/APIs/APIs";
import {message} from "ant-design-vue";
import {DeleteOutlined, EditOutlined, EllipsisOutlined,RollbackOutlined,PlusOutlined} from "@ant-design/icons-vue";
import {useRoute, useRouter} from "vue-router";
import {Ticketcreated, TicketRequest} from "@/APIs/request/TicketRequest";
import dayjs from "dayjs";
import {TicketActionTypes} from "@/store/modules/Ticket/action-types";
import useUserStore from "@/store/User";

export default {
components:{
  RollbackOutlined,
  PlusOutlined
},
  setup(){
    const i18n = useI18n()
    const formState = ref<Ticketcreated>({});
    const store = useStore()
    const route = useRoute()
    const router = useRouter()
    const formRefTicket = ref()
    const formRefReference = ref()
    const getTicketType =computed(()=> store.getters.getTicketType)
    const getTicketPrice =computed(()=> store.getters.getTicketPrice)
    const getTicket = computed(() => store.getters.getTicket)
    const visible = ref(false)
    const getUserInfo =computed(()=> store.getters.getUserInfo)
    const events = computed(() => store.getters.getEvent)
    const rules = {
      full_name: [
        {required: true, message: i18n.t("Please input userName"), trigger: 'blur'},
      ],
      event_id:[
        {required: true, message: i18n.t("Please select event"), trigger: 'blur'},
      ],
      bib:[
        {required: true, message: i18n.t("Please input bib"), trigger: 'blur'},
      ],
      alias_name: [
        {required: true, message: i18n.t("Please input alias name"), trigger: 'blur'},
      ],
      phone: [
        {required: true, message: i18n.t("Please input phone"), trigger: 'blur'},
      ],
      ticket_type_id: [
        {required: getTicketType.value.ticketTypes && getTicketType.value.ticketTypes.length > 0 ? true :false, message: i18n.t("Please select ticket"), trigger: 'blur'},
      ],
      email: [
        {required: true, message: i18n.t("Please input email"), trigger: 'blur'},
      ],
      organization: [
        {required: true, message: i18n.t("Please input organization"), trigger: 'blur'},
      ],
      birthday: [
        {required: true, message: i18n.t("Please select birthday"), trigger: 'blur'},
      ],
      gender: [
        {required: true, message: i18n.t("Please select gender"), trigger: 'blur'},
      ],
      national_card_id: [
        {required: true, message: i18n.t("Please input national card id"), trigger: 'blur'},
      ],
      national_id: [
        {required: true, message: i18n.t("Please input national id"), trigger: 'blur'},
      ],
      province_id: [
        {required: true, message: i18n.t("Please input province"), trigger: 'blur'},
      ],

    };
    const templates = computed(()=> store.getters.getTicketTemplate)
    // const rules2 = {
    const ticketType= ref({
      price:'',
      time_visit:[]
    })
    const ticketPrice = ref([])
    //   reference_id: [
    //     {required: true, message: i18n.t("Please input reference id"), trigger: 'blur'},
    //   ],
    //   reference_name:[
    //     {required: true, message: i18n.t("Please select reference name"), trigger: 'blur'},
    //   ],
    //   reference_phone:[
    //     {required: true, message: i18n.t("Please input reference phone"), trigger: 'blur'},
    //   ],}
    const addTicketType = () => {
      // @ts-ignore
      ticketPrice.value =[{price:'', time_visit:[]}]
      // @ts-ignore
      ticketType.value = {}
      visible.value = true
    }
    const provinceSearchText = ref('')
    const getProvinces = computed((): Province[]| undefined => {
      if(provinceSearchText.value){
        return store.getters.getProvinces?.filter((x: any) => MixUtils.methods.removeVietnamese2(x.name).includes(MixUtils.methods.removeVietnamese2(provinceSearchText.value)))
      }else{
        return store.getters.getProvinces
      }
    })
const rollBack = () => {
      router.back()
}
    const onSubmit =  ( ) => {

    formRefTicket.value
        .validate()
        .then( async () => {
                // @ts-ignore
                formState.value.shop_id = useUserStore(state=>state.currentShop.id)
                // @ts-ignore
                formState.value.reference_id = Number(formState.value.reference_id)
                // @ts-ignore
                formState.value.checkin_time = new Date()

                 if(route.params.id === ':id'){
                   formState.value.event_id = Number(route.query.event_id)
                   const res = await APIs.init().addTickets(formState.value)
                  if(res.data?.status === 200){
                    // @ts-ignore
                    console.log(res.data.data.id)
                    // @ts-ignore
                    // router.push(`/ticket/add-ticket/${res.data.data.id}?event_id=${route.query.event_id}`)
                    router.back()
                    message.success(i18n.t('SUCCESS'))
                  }
                }else {
                   const res = await APIs.init().updateTickets(formState.value)
                   if(res.data?.status === 200){
                     message.success(i18n.t('SUCCESS'))
                   }
                 }

        })
    }
    const addTicketPrice = async  (id:any) => {
      ticketPrice.value.map(async(item:any) => {
          item.shop_id = getUserInfo.value.current_shop_id
          item.event_id = Number(route.query.event_id)
          item.ticket_type_id = id
          item.price = Number(item.price)
          item.start_time =dayjs(item.time_visit[0])
          item.end_time = dayjs(item.time_visit[1])
          const res = await APIs.init().addTicketPrice(item)
          if(res.data?.status === 200){
            message.success(i18n.t('SUCCESS'))
            fetchDataType()
          }

        visible.value = false

      })


    }
    const fetchDataType = () => {
      // @ts-ignore
      store.dispatch(TicketActionTypes.FETCH_TICKET_TYPE,{page:1,page_size:20,event_id: route.query.event_id})

    }
    const ticketPricePush = () => {
      // @ts-ignore
      ticketPrice.value.push({price:'', time_visit:[]})
    }
    const handleOk = async () => {
      // @ts-ignore
      ticketType.value.shop_id =getUserInfo.value.current_shop_id
          // @ts-ignore
      ticketType.value.event_id = Number(route.query.event_id)
        // @ts-ignore
        const res = await APIs.init().addTicketType(ticketType.value)
        if(res.data?.status === 200){
          console.log('2')
          // @ts-ignore
          addTicketPrice(res.data?.data.id)
        }
    }
    onMounted(() => {
      fetchDataType()
      if(route.params.id !== ':id'){
        try {
          console.log(formState.value,getTicket.value.tickets,'value')
          // @ts-ignore
          formState.value = getTicket.value.tickets.find((item:any) => item.id === Number(route.params.id))
          // @ts-ignore
          formState.value.birthday = dayjs(formState.value.birthday)
        }catch (e){
          console.log(e)
        }


      }
    })
    return{
      formState,
      events,
      getProvinces,
      onSubmit,
      rules
      ,formRefTicket,
      formRefReference,
      getTicketType,
      rollBack,
      ticketType,
      ticketPrice,
      templates,
      visible,
      addTicketType,addTicketPrice,ticketPricePush,
      getTicketPrice,
      handleOk
    }
  }
}
</script>
