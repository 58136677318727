import { resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_arrow_left_outlined = _resolveComponent("arrow-left-outlined")!
  const _component_a_button = _resolveComponent("a-button")!
  const _component_home_outlined = _resolveComponent("home-outlined")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_a_result = _resolveComponent("a-result")!

  return (_openBlock(), _createBlock(_component_a_result, {
    status: "404",
    title: "404",
    "sub-title": "xin lỗi, page bạn tìm hiện đã không còn hoạt động vui lòng quay trở lại sau hihi\n  .",
    style: {"background-color":"white","min-height":"80vh"}
  }, {
    extra: _withCtx(() => [
      _createVNode(_component_a_button, {
        type: "primary",
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$router.go(-1)))
      }, {
        default: _withCtx(() => [
          _createVNode(_component_arrow_left_outlined),
          _createTextVNode(" Back Previous Page ")
        ]),
        _: 1
      }),
      _createVNode(_component_a_button, { type: "primary" }, {
        default: _withCtx(() => [
          _createVNode(_component_router_link, {
            to: _ctx.RouterEndpoint.CUSTOMER_MANAGEMENT.path
          }, {
            default: _withCtx(() => [
              _createVNode(_component_home_outlined),
              _createTextVNode(" Back Home ")
            ]),
            _: 1
          }, 8, ["to"])
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}