import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-eccc12b4"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "title-flow-context" }
const _hoisted_2 = { class: "preview-container" }
const _hoisted_3 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("h3", _hoisted_1, _toDisplayString(_ctx.$t('FLOW_PREVIEW')), 1),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", {
        innerHTML: _ctx.htmlTemp,
        style: {"zoom":"0.5","-moz-transform":"scale(0.5)"}
      }, null, 8, _hoisted_3)
    ])
  ], 64))
}