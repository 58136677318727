import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-4c50331e"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "container-title" }
const _hoisted_2 = { class: "title-header" }
const _hoisted_3 = { class: "input-search" }
const _hoisted_4 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_input_search = _resolveComponent("a-input-search")!
  const _component_a_tag = _resolveComponent("a-tag")!
  const _component_a_select_option = _resolveComponent("a-select-option")!
  const _component_a_select = _resolveComponent("a-select")!
  const _component_plus_outlined = _resolveComponent("plus-outlined")!
  const _component_a_button = _resolveComponent("a-button")!
  const _component_ellipsis_outlined = _resolveComponent("ellipsis-outlined")!
  const _component_edit_outlined = _resolveComponent("edit-outlined")!
  const _component_a_menu_item = _resolveComponent("a-menu-item")!
  const _component_line_chart_outlined = _resolveComponent("line-chart-outlined")!
  const _component_a_menu = _resolveComponent("a-menu")!
  const _component_a_dropdown = _resolveComponent("a-dropdown")!
  const _component_a_table = _resolveComponent("a-table")!
  const _component_ViralModal = _resolveComponent("ViralModal")!
  const _component_ViralResultModal = _resolveComponent("ViralResultModal")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("h3", _hoisted_2, _toDisplayString(_ctx.$t(_ctx.$route.name)), 1),
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_a_input_search, {
          class: "input-search",
          placeholder: _ctx.$t('SEARCH'),
          value: _ctx.searchInput,
          "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.searchInput) = $event)),
          onChange: _ctx.onSearchChange
        }, null, 8, ["placeholder", "value", "onChange"])
      ]),
      _createVNode(_component_a_select, {
        showSearch: "",
        allowClear: "",
        mode: "multiple",
        "v-mode:value": _ctx.selectedTags,
        placeholder: _ctx.$t('TAGS'),
        style: {"min-width":"220px","margin-right":"16px"},
        onChange: _ctx.handleChangeTags
      }, {
        default: _withCtx(() => [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.getViralTags, (item) => {
            return (_openBlock(), _createBlock(_component_a_select_option, {
              key: item.id,
              value: item.id
            }, {
              default: _withCtx(() => [
                _createVNode(_component_a_tag, {
                  color: item.color
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(item.name), 1)
                  ]),
                  _: 2
                }, 1032, ["color"])
              ]),
              _: 2
            }, 1032, ["value"]))
          }), 128))
        ]),
        _: 1
      }, 8, ["v-mode:value", "placeholder", "onChange"]),
      _createVNode(_component_a_button, {
        type: "primary",
        onClick: _ctx.createViral
      }, {
        default: _withCtx(() => [
          _createVNode(_component_plus_outlined),
          _createTextVNode(" " + _toDisplayString(_ctx.$t('NEW_VIRAL')), 1)
        ]),
        _: 1
      }, 8, ["onClick"])
    ]),
    _createVNode(_component_a_table, {
      size: "middle",
      scroll: {  y: '80vh' },
      rowKey: record => record.id,
      columns: _ctx.columns,
      "data-source": _ctx.getFlowData.flows,
      pagination: _ctx.pagination,
      onChange: _ctx.handleTableChange,
      class: "flow-table"
    }, {
      name: _withCtx(({ record }) => [
        _createElementVNode("a", {
          class: "table-row-name",
          type: "text",
          onClick: ($event: any) => (_ctx.customRowClick(record))
        }, _toDisplayString(record.name), 9, _hoisted_4)
      ]),
      created_at: _withCtx(({ text }) => [
        _createElementVNode("span", null, _toDisplayString(_ctx.dateTimeDisplayFormatFromString(text)), 1)
      ]),
      creator: _withCtx(({ text }) => [
        _createElementVNode("span", null, _toDisplayString(_ctx.getEmployeeName(text)), 1)
      ]),
      tags: _withCtx(({ record }) => [
        _createElementVNode("span", null, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(record.tags, (tag) => {
            return (_openBlock(), _createBlock(_component_a_tag, {
              key: tag.id+'_'+tag.name,
              color: tag.color
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(tag.name), 1)
              ]),
              _: 2
            }, 1032, ["color"]))
          }), 128))
        ])
      ]),
      action: _withCtx(({record}) => [
        _createVNode(_component_a_dropdown, null, {
          overlay: _withCtx(() => [
            _createVNode(_component_a_menu, null, {
              default: _withCtx(() => [
                _createVNode(_component_a_menu_item, {
                  onClick: ($event: any) => (_ctx.edit(record.id))
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_edit_outlined),
                    _createTextVNode(" " + _toDisplayString(_ctx.$t('EDIT')), 1)
                  ]),
                  _: 2
                }, 1032, ["onClick"]),
                _createVNode(_component_a_menu_item, {
                  onClick: ($event: any) => (_ctx.showViralResult(record.id))
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_line_chart_outlined),
                    _createTextVNode(" Result ")
                  ]),
                  _: 2
                }, 1032, ["onClick"])
              ]),
              _: 2
            }, 1024)
          ]),
          default: _withCtx(() => [
            _createVNode(_component_a_button, { type: "text" }, {
              default: _withCtx(() => [
                _createVNode(_component_ellipsis_outlined)
              ]),
              _: 1
            })
          ]),
          _: 2
        }, 1024)
      ]),
      _: 1
    }, 8, ["rowKey", "columns", "data-source", "pagination", "onChange"]),
    _createVNode(_component_ViralModal, {
      ref: "viralModal",
      viralUpdated: _ctx.viralUpdated
    }, null, 8, ["viralUpdated"]),
    _createVNode(_component_ViralResultModal, { ref: "viralResultModal" }, null, 512)
  ], 64))
}