<template>
  <div class="main-flow" id="main-flow">
    <a-form ref="formRef" :model="formState" layout="vertical">
      <a-form-item ref="name" :label="$t('FLOW_MESSAGE_NAME')" name="name" v-bind="validateInfos.name">
        <a-input
            :placeholder="$t('FLOW_MESSAGE_NAME')"
            @change="onTextChange"
            v-model:value="formState.name"
        />
      </a-form-item>
      <a-card :bodyStyle="{'padding':'10px'}" class="card-message" >
        <template #title>
          {{$t('TRIGGERS')}}

        </template>
        <a-row >
          <a-col :span="12">
            <a-form-item style="flex-direction: row" :name="['trigger_condition','condition']" :rules="{
        required: true,
        message: 'Please select one condition type',
        trigger: 'change',
      }" :label="$t('TRIGGER_CONDITIONS')" :label-col="{span: 6,offset:0}"
                         :wrapper-col="{ span: 12 }">
              <a-radio-group @change="onSubmit" v-model:value="formState.trigger_condition.condition">
                <a-radio style="align-items: normal" v-for="item in getFlowConstants.trigger_condition.trigger_conditions" :key="item"
                         :value="item">
                  {{ $t(item) }}
                </a-radio>
              </a-radio-group>
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-row style="width: 100%;justify-content: flex-end">

              <a-tag style="margin: 10px;cursor: pointer" type="dashed" block @click="addParentCondition">
                <plus-outlined/>
                {{ $t("TRIGGER_CONDITIONS") }}
              </a-tag>
              <a-tag @click="addGroupCondition" style="margin: 10px;cursor: pointer" type="dashed">{{$t('CONDITION_GROUP')}}</a-tag>

            </a-row>
          </a-col>

        </a-row>

        <div
            v-if="formState.trigger_condition.trigger_comparisons&&formState.trigger_condition.trigger_comparisons.length">
          <a-timeline>
            <a-timeline-item style="padding-bottom: 0" v-for="(item,index) in formState.trigger_condition.trigger_comparisons" :key="item.condition+'_'+index" >
              <a-row v-if="item.condition" class="row-full-width"
              >
                <a-col :span="7" style="padding: 4px;">
                  <a-select
                      ref="select"
                      style="width: 100%;"
                      v-model:value="item.field_type"
                      :placeholder="$t('select_condition_field')"
                      @change="onSubmit"

                  >

                    <a-select-option v-for="it in getFlowConstants.trigger_condition.trigger_condition_field_types"
                                     :key="it" :value="it">{{ $t(it) }}
                    </a-select-option>
                    <a-select-option v-for="i in getCustomerConfig" :key="i.id" :value="i.identified_name">
                      {{i.name}}
                    </a-select-option>
                  </a-select>
                </a-col>
                <a-col :span="7" style="padding: 4px;">
                  <a-select
                      ref="select"
                      style="width: 100%;"
                      v-model:value="item.condition"
                      :placeholder="$t('select_comparison_condition')"
                      @change="onSubmit"

                  >
                    <a-select-option v-for="it in getCondition(getFlowConstants.trigger_condition.trigger_comparison_condition,item.field_type,index)"
                                     :key="it+'_2'+index" :value="it">
                      {{ $t(it) }}
                    </a-select-option>
                  </a-select>
                </a-col>
                <a-col :span="9" style="padding: 4px;">
                  <a-tree-select v-if="item.field_type==='tag'"
                                 @change="onSubmit"
                                 show-search
                                 style="width: 100%"
                                 @search="searchTag"
                                 :filterTreeNode="false"
                                 v-model:searchValue="searchTagValue"
                                 v-model:value="item.value"
                                 :notFoundContent="null"
                                 :dropdown-style="{ maxHeight: '400px', overflow: 'auto' }"
                                 allow-clear
                                 :placeholder="$t('TAG_SELECT')"
                  >
                    <a-tree-select-node v-for="item in getTreeTags" :key="item.id" :value="item.id+''">
                      <template #title>
                        <a-tag :color="item.color">
                          {{ item.name }}
                        </a-tag>
                      </template>
                      <a-tree-select-node v-for="child in item.children" :key="child.id" :value="child.id+''">
                        <template #title>
                          <a-tag :color="child.color">
                            {{ child.name }}
                          </a-tag>
                        </template>
                      </a-tree-select-node>

                    </a-tree-select-node>
                  </a-tree-select>
                  <a-input  @change="onSubmit" style="width: 100%;" v-if="item.field_type==='INT' || item.field_type === 'CURRENCY'"  v-model:value="item.value"  />
                  <a-input  @change="onSubmit" style="width: 100%;" v-if="item.field_type==='STRING' "  v-model:value="item.value"  />
                  <a-range-picker   @change="onSubmit" style="width: 100%;" v-if="item.field_type === 'DATE'" format="DD-MM-YYYY" :valueFormat="'YYYY-MM-DDTHH:mm:ssZ'"  v-model:value="item.value"  />
                  <a-select v-if="item.field_type==='page_id'"
                            style="width: 100%;"
                            show-search
                            :filter-option="filterOption2"
                            :placeholder="$t('SELECT_PAGE')"
                            @change="onSubmit"
                            v-model:value="item.value">
                    <a-select-option v-for="it in   getPages"
                                     :key="it" :value="it.page_id+''">

                      <img
                          v-if="it.page_type === 2"
                          draggable="false"
                          src="@/assets/icons/zalo-seeklogo.com.svg"
                          style="width: 20px;margin-right: 20px"
                      />
                      <img
                          v-else-if="it.page_type === 1"
                          draggable="false"
                          src="@/assets/icons/facebook-svgrepo-com.svg"
                          style="width: 20px;margin-right: 20px"
                      />
                      <img
                          v-else-if="it.page_type === 3"
                          draggable="false"
                          src="@/assets/icons/instagram-svgrepo-com.svg"
                          style="width: 20px;margin-right: 20px"
                      />
                      <img
                          v-else-if="it.page_type === 4"
                          draggable="false"
                          src="@/assets/icons/line-svgrepo-com.svg"
                          style="width: 20px;margin-right: 20px"
                      /> <img
                        v-else-if="it.page_type === 6"
                        draggable="false"
                        src="@/assets/icons/whatsapp-svgrepo-com.svg"
                        style="width: 20px;margin-right: 20px"
                    />{{ it.page_name }}
                    </a-select-option>
                  </a-select>
                  <a-select v-if="item.field_type==='messenger_window_24h'"
                            style="width: 100%;"
                            :placeholder="$t('MESSAGE')"
                            @change="onSubmit"
                            v-model:value="item.value">
                    <a-select-option v-for="it in getFlowConstants.trigger_condition_messenger_window_24_h_field_types"
                                     :key="it" value="it">{{ it }}
                    </a-select-option>
                  </a-select>
                  <!--                      <a-select -->
                  <!--                                style="width: 100%;"-->
                  <!--                                :placeholder="$t('SELECT_TAG')"-->
                  <!--                                show-search-->
                  <!--                                :default-active-first-option="false"-->
                  <!--                                :show-arrow="false"-->
                  <!--                                :filter-option="false"-->
                  <!--                                :not-found-content="null"-->
                  <!--                                v-model:value="item.value">-->
                  <!--                        <a-select-option v-for="it in conversationTags()"-->
                  <!--                                         :key="it.tag_id+'_4'+index" :value="it.tag_id+''">{{ it.tag_name }}-->
                  <!--                        </a-select-option>-->
                  <!--                      </a-select>-->
                  <a-tree-select
                      v-if="item.field_type==='conversation_tag'"
                      show-search
                      style="width: 100%"
                      v-model:value="item.value1"
                      :filterTreeNode="false"
                      @search="searchTags"
                      @change="onSubmit"
                      :notFoundContent="null"
                      :dropdown-style="{ maxHeight: '300', overflow: 'auto' }"
                      allow-clear
                      multiple
                      :placeholder="$t('TAG_SELECT')"
                  >
                    <a-tree-select-node disabled  v-for="converstion in getConverstionTag" :key="converstion.page_id" v-bind:value="converstion.page_id" >
                      <template #title>
                        <a-tag :color="converstion.color">
                          <img
                              v-if="converstion.page_type === 2"
                              draggable="false"
                              src="@/assets/icons/zalo-seeklogo.com.svg"
                              style="width: 20px;margin-right: 20px"
                          />
                          <img
                              v-else-if="converstion.page_type === 1"
                              draggable="false"
                              src="@/assets/icons/facebook-svgrepo-com.svg"
                              style="width: 20px;margin-right: 20px"
                          />
                          <img
                              v-else-if="converstion.page_type === 3"
                              draggable="false"
                              src="@/assets/icons/instagram-svgrepo-com.svg"
                              style="width: 20px;margin-right: 20px"
                          />
                          <img
                              v-else-if="converstion.page_type === 4"
                              draggable="false"
                              src="@/assets/icons/line-svgrepo-com.svg"
                              style="width: 20px;margin-right: 20px"
                          />
                          <img
                              v-else-if="converstion.page_type === 6"
                              draggable="false"
                              src="@/assets/icons/whatsapp-svgrepo-com.svg"
                              style="width: 20px;margin-right: 20px"
                          />
                          {{ converstion.page_name }}
                        </a-tag>
                      </template>
                      <a-tree-select-node :disabled="item.coverstion_tag?getConvertionTangId(item.coverstion_tag,converstion.page_id).includes(treeData.tag_id)  ? true :false:false " v-for="treeData in converstion.tags" :key="converstion.page_id + '_'+treeData.tag_id" v-bind:value="converstion.page_id + '_'+treeData.tag_id">
                        <template #title>
                          <a-tag :color="treeData.tag_color">
                            {{ treeData.tag_name }}
                          </a-tag>
                        </template>
                      </a-tree-select-node>

                    </a-tree-select-node>
                  </a-tree-select>

                  <a-select v-if="item.field_type==='post_id'"
                            style="width: 100%;"
                            placeholder="Select post"
                            show-search
                            @change="onSubmit"
                            @search="searchPost"
                            :default-active-first-option="false"
                            :show-arrow="false"
                            :filter-option="false"
                            :not-found-content="null"
                            v-model:value="item.value">
                    <a-select-option v-for="post in posts" :key="post.id" :value="post.id">
                      <a-avatar shape="square" :src="getPostPicture(post)">
                      </a-avatar>
                      {{ getPostName(post) }}
                    </a-select-option>
                  </a-select>
                  <a-select v-if="item.field_type==='care_status'"
                            style="width: 100%;"
                            placeholder="Select status"
                            show-search
                            @change="onSubmit"
                            :default-active-first-option="false"
                            :show-arrow="false"
                            :filter-option="false"
                            :not-found-content="null"
                            v-model:value="item.value">
                    <a-select-option v-for="tag in tags" :key="tag.id" :value="tag.id+''">
                      <a-tag :color="tag.color">{{ tag.name }}</a-tag>
                    </a-select-option>
                  </a-select>
                  <a-select v-if="item.field_type==='order_status'"
                            style="width: 100%;"
                            placeholder="Select status"
                            show-search
                            :default-active-first-option="false"
                            :show-arrow="false"
                            @change="onSubmit"
                            :filter-option="false"
                            :not-found-content="null"
                            v-model:value="item.value">
                    <a-select-option v-for="i in orderStatus" :key="i.id" :value="i.id+''">
                      <a-tag :color="getOrderStatusColor(i.id)">{{ $t(i.name) }}</a-tag>
                    </a-select-option>
                  </a-select>
                  <a-range-picker  @change="onSubmit" v-if="item.field_type==='last_contact_time'" v-model:value="item.value1" format="DD-MM-YYYY" :valueFormat="'YYYY-MM-DDTHH:mm:ssZ'" />
                  <a-range-picker  @change="onSubmit" v-if="item.field_type==='first_contact_time'" v-model:value="item.value1" format="DD-MM-YYYY" :valueFormat="'YYYY-MM-DDTHH:mm:ssZ'"/>
                  <a-select v-if="item.field_type==='timeout'"
                            style="width: 100%;"
                            show-search
                            placeholder="Select Timeout"
                            :default-active-first-option="false"
                            :show-arrow="false"
                            :not-found-content="null"
                            :filter-option="filterOption"
                            @change="onSubmit"

                            v-model:value="item.value">
                    <a-select-option
                        v-for="time in timeoutSelections"
                        :key="time"
                        :value="time.value"
                    >{{ time.label }}
                    </a-select-option>
                  </a-select>

                  <!--                      <a-input v-if="item.field_type==='key_word'"-->
                  <!--                               v-model:value="item.value"-->
                  <!--                               placeholder="You key word"-->
                  <!--                               style="width: 100%;"/>-->
                  <a-select
                      v-if="item.field_type==='key_word'"
                      v-model:value="item.value1"
                      mode="tags"
                      style="width: 100%"
                      placeholder="You key word"
                      @change="onSubmit"

                  >
                    <a-select-option v-for="i in options" :key="i" :value="i.value">
                      {{$t(i.value)}}
                    </a-select-option>
                  </a-select>

                </a-col>
                <a-col :span="1" style="padding: 4px; text-align: center">
                  <delete-outlined @click="deleteCustomerConditionRow(index)"/>
                </a-col>
              </a-row>
              <a-card :bodyStyle="{'padding':'10px'}" v-else style="width: 100%;margin-top: 10px;margin-bottom: 10px">
                <a-row >
                  <a-col :span="11">
                    <a-form-item style="margin-bottom:10px"  :name="['trigger_condition','condition']" :rules="{
        required: false,
        message: 'Please select one condition type',
        trigger: 'change',
      }"  :label-col="{span: 0,offset:0}"
                                 :wrapper-col="{ span: 15 }">
                      <a-radio-group v-model:value="item.trigger_condition.condition">
                        <a-radio  @change="onSubmit" v-for="item in getFlowConstants.trigger_condition.trigger_conditions" :key="item"
                                 :value="item">
                          {{ $t(item) }}
                        </a-radio>
                      </a-radio-group>

                    </a-form-item>
                  </a-col>


                  <a-col :span="11">
                    <a-row style="width: 100%;margin-bottom: 10px;justify-content: flex-end">
                      <a-tag style="margin-right:10px;cursor: pointer" type="dashed" block @click="addChildrenCondition(index)">
                        <plus-outlined/>
                        {{ $t("TRIGGER_CONDITIONS") }}
                      </a-tag>

                    </a-row>
                  </a-col>
                  <a-col :span="1">

                  </a-col>
                  <a-col :span="1">
                    <delete-outlined @click="deleteCustomerConditionRow(index)"/>
                  </a-col>
                </a-row>



                <div
                    v-if="formState.trigger_condition.trigger_comparisons&&formState.trigger_condition.trigger_comparisons.length">
                  <a-timeline style="margin-top: 10px">
                    <a-timeline-item style="padding-bottom: 0" v-for="(i,idex) in formState.trigger_condition.trigger_comparisons[index].trigger_condition.trigger_comparisons" :key="i.condition+'_'+idex" >
                      <a-row  class="row-full-width"
                      >
                        <a-col :span="7" style="padding: 4px;">
                          <a-select
                              ref="select"
                              style="width: 100%;"
                              v-model:value="i.field_type"
                              @change="onSubmit"
                              :placeholder="$t('select_condition_field')"
                          >

                            <a-select-option v-for="it in getFlowConstants.trigger_condition.trigger_condition_field_types"
                                             :key="it" :value="it">{{ $t(it) }}
                            </a-select-option>
                          </a-select>
                        </a-col>
                        <a-col :span="7" style="padding: 4px;">
                          <a-select
                              ref="select"
                              style="width: 100%;"
                              v-model:value="i.condition"
                              @change="onSubmit"
                              :placeholder="$t('select_comparison_condition')"
                          >
                            <a-select-option v-for="it in getCondition(getFlowConstants.trigger_condition.trigger_comparison_condition,i.field_type,index,idex)"
                                             :key="it+'_2'+index" :value="it">{{ $t(it) }}
                            </a-select-option>
                          </a-select>
                        </a-col>
                        <a-col :span="9" style="padding: 4px;">
                          <a-tree-select v-if="i.field_type==='tag'"
                                         @change="onSubmit"
                                         style="width: 100%"
                                         @search="searchTag"
                                         :filterTreeNode="false"
                                         v-model:searchValue="searchTagValue"
                                         v-model:value="i.value"
                                         :notFoundContent="null"
                                         :dropdown-style="{ maxHeight: '400px', overflow: 'auto' }"
                                         allow-clear
                                         :placeholder="$t('TAG_SELECT')"
                          >
                            <a-tree-select-node v-for="item in getTreeTags" :key="item.id" :value="item.id+''">
                              <template #title>
                                <a-tag :color="item.color">
                                  {{ item.name }}
                                </a-tag>
                              </template>
                              <a-tree-select-node v-for="child in item.children" :key="child.id" :value="child.id+''">
                                <template #title>
                                  <a-tag :color="child.color">
                                    {{ child.name }}
                                  </a-tag>
                                </template>
                              </a-tree-select-node>

                            </a-tree-select-node>
                          </a-tree-select>
                          <a-select v-if="i.field_type==='page_id'"
                                    style="width: 100%;"
                                    show-search
                                    @change="onSubmit"
                                    :filter-option="filterOption2"
                                    :placeholder="$t('SELECT_PAGE')"
                                    v-model:value="i.value">
                            <a-select-option v-for="it in   getPages"
                                             :key="it" :value="it.page_id+''">

                              <img
                                  v-if="it.page_type === 2"
                                  draggable="false"
                                  src="@/assets/icons/zalo-seeklogo.com.svg"
                                  style="width: 20px;margin-right: 20px"
                              />
                              <img
                                  v-else-if="it.page_type === 1"
                                  draggable="false"
                                  src="@/assets/icons/facebook-svgrepo-com.svg"
                                  style="width: 20px;margin-right: 20px"
                              />
                              <img
                                  v-else-if="it.page_type === 3"
                                  draggable="false"
                                  src="@/assets/icons/instagram-svgrepo-com.svg"
                                  style="width: 20px;margin-right: 20px"
                              />
                              <img
                                  v-else-if="it.page_type === 4"
                                  draggable="false"
                                  src="@/assets/icons/line-svgrepo-com.svg"
                                  style="width: 20px;margin-right: 20px"
                              /> <img
                                v-else-if="it.page_type === 6"
                                draggable="false"
                                src="@/assets/icons/whatsapp-svgrepo-com.svg"
                                style="width: 20px;margin-right: 20px"
                            />{{ it.page_name }}
                            </a-select-option>
                          </a-select>
                          <a-select v-if="i.field_type==='messenger_window_24h'"
                                    style="width: 100%;"
                                    :placeholder="$t('MESSAGE')"
                                    @change="onSubmit"
                                    v-model:value="i.value">
                            <a-select-option v-for="it in getFlowConstants.trigger_condition_messenger_window_24_h_field_types"
                                             :key="it" value="it">{{ it }}
                            </a-select-option>
                          </a-select>
                          <!--                              <a-select v-if="i.field_type==='conversation_tag'"-->
                          <!--                                        style="width: 100%;"-->
                          <!--                                        :placeholder="$t('SELECT_TAG')"-->
                          <!--                                        show-search-->
                          <!--                                        :default-active-first-option="false"-->
                          <!--                                        :show-arrow="false"-->
                          <!--                                        :filter-option="false"-->
                          <!--                                        :not-found-content="null"-->
                          <!--                                        v-model:value="i.value">-->
                          <!--                                <a-select-option v-for="it in conversationTagsGroup(index,idex)"-->
                          <!--                                                 :key="it.tag_id+'_4'+index" :value="it.tag_id+''">{{ it.tag_name }}-->
                          <!--                                </a-select-option>-->
                          <!--                              </a-select>-->
                          <a-tree-select
                              v-if="i.field_type==='conversation_tag'"
                              show-search
                              style="width: 100%"
                              v-model:value="i.value1"
                              :filterTreeNode="false"
                              @search="searchTags"
                              :notFoundContent="null"
                              :dropdown-style="{ maxHeight: '300', overflow: 'auto' }"
                              allow-clear
                              multiple
                              @change="onSubmit"
                              :placeholder="$t('TAG_SELECT')"
                          >
                            <a-tree-select-node disabled  v-for="converstion in getConverstionTag" :key="converstion.page_id" v-bind:value="converstion.page_id" >
                              <template #title>
                                <a-tag :color="converstion.color">
                                  <img
                                      v-if="converstion.page_type === 2"
                                      draggable="false"
                                      src="@/assets/icons/zalo-seeklogo.com.svg"
                                      style="width: 20px;margin-right: 20px"
                                  />
                                  <img
                                      v-else-if="converstion.page_type === 1"
                                      draggable="false"
                                      src="@/assets/icons/facebook-svgrepo-com.svg"
                                      style="width: 20px;margin-right: 20px"
                                  />
                                  <img
                                      v-else-if="converstion.page_type === 3"
                                      draggable="false"
                                      src="@/assets/icons/instagram-svgrepo-com.svg"
                                      style="width: 20px;margin-right: 20px"
                                  />
                                  <img
                                      v-else-if="converstion.page_type === 4"
                                      draggable="false"
                                      src="@/assets/icons/line-svgrepo-com.svg"
                                      style="width: 20px;margin-right: 20px"
                                  />
                                  <img
                                      v-else-if="converstion.page_type === 6"
                                      draggable="false"
                                      src="@/assets/icons/whatsapp-svgrepo-com.svg"
                                      style="width: 20px;margin-right: 20px"
                                  />
                                  {{ converstion.page_name }}
                                </a-tag>
                              </template>
                              <a-tree-select-node :disabled="i.coverstion_tag?getConvertionTangId(i.coverstion_tag,converstion.page_id).includes(treeData.tag_id)  ? true :false:false " v-for="treeData in converstion.tags" :key="converstion.page_id + '_'+treeData.tag_id" v-bind:value="converstion.page_id + '_'+treeData.tag_id">
                                <template #title>
                                  <a-tag :color="treeData.tag_color">
                                    {{ treeData.tag_name }}
                                  </a-tag>
                                </template>
                              </a-tree-select-node>

                            </a-tree-select-node>
                          </a-tree-select>

                          <a-select v-if="i.field_type==='post_id'"
                                    style="width: 100%;"
                                    placeholder="Select post"
                                    show-search
                                    @change="onSubmit"
                                    @search="searchPost"
                                    :default-active-first-option="false"
                                    :show-arrow="false"
                                    :filter-option="false"
                                    :not-found-content="null"
                                    v-model:value="i.value">
                            <a-select-option v-for="post in posts" :key="post.id" :value="post.id">
                              <a-avatar shape="square" :src="getPostPicture(post)">
                              </a-avatar>
                              {{ getPostName(post) }}
                            </a-select-option>
                          </a-select>
                          <a-select v-if="i.field_type==='care_status'"
                                    style="width: 100%;"
                                    placeholder="Select status"
                                    show-search
                                    @change="onSubmit"
                                    :default-active-first-option="false"
                                    :show-arrow="false"
                                    :filter-option="false"
                                    :not-found-content="null"
                                    v-model:value="i.value">
                            <a-select-option v-for="tag in tags" :key="tag.id" :value="tag.id+''">
                              <a-tag :color="tag.color">{{ tag.name }}</a-tag>
                            </a-select-option>
                          </a-select>
                          <a-select v-if="i.field_type==='order_status'"
                                    style="width: 100%;"
                                    placeholder="Select status"
                                    show-search
                                    @change="onSubmit"
                                    :default-active-first-option="false"
                                    :show-arrow="false"
                                    :filter-option="false"
                                    :not-found-content="null"
                                    v-model:value="i.value">
                            <a-select-option v-for="i in orderStatus" :key="i.id" :value="i.id+''">
                              <a-tag :color="getOrderStatusColor(i.id)">{{ $t(i.name) }}</a-tag>
                            </a-select-option>
                          </a-select>
                          <a-range-picker @change="onSubmit" v-if="i.field_type==='last_contact_time'" v-model:value="i.value1" format="DD-MM-YYYY" :valueFormat="'YYYY-MM-DDTHH:mm:ssZ'" />
                          <a-range-picker @change="onSubmit" v-if="i.field_type==='first_contact_time'" v-model:value="i.value1" format="DD-MM-YYYY" :valueFormat="'YYYY-MM-DDTHH:mm:ssZ'"/>
                          <a-select v-if="i.field_type==='timeout'"
                                    style="width: 100%;"
                                    show-search
                                    @change="onSubmit"
                                    placeholder="Select Timeout"
                                    :default-active-first-option="false"
                                    :show-arrow="false"
                                    :not-found-content="null"
                                    :filter-option="filterOption"

                                    v-model:value="i.value">
                            <a-select-option
                                v-for="time in timeoutSelections"
                                :key="time"
                                :value="time.value"
                            >{{ time.label }}
                            </a-select-option>
                          </a-select>

                          <a-select
                              v-if="i.field_type==='key_word'"
                              v-model:value="i.value1"
                              mode="tags"
                              @change="onSubmit"
                              style="width: 100%"
                              placeholder="You key word"

                          >
                            <a-select-option v-for="i in options2" :key="i" :value="i.value">
                              {{$t(i.value)}}
                            </a-select-option>
                          </a-select>

                        </a-col>

                        <a-col :span="1" style="padding: 4px; text-align: center">
                          <delete-outlined @click="deleteCustomerConditionChidrenRow(idex,index)"/>
                        </a-col>
                      </a-row>
                    </a-timeline-item>
                  </a-timeline>
                </div>
              </a-card>
            </a-timeline-item>
          </a-timeline>
        </div>

<!--        <span v-if="!rules.trigger_condition.isValid" class="condition-alert">{{-->
<!--            rules.trigger_condition.message-->
<!--          }}</span>-->
      </a-card>


      <a-divider/>
      <flow-next-actions
          v-if="!isShow"
          :buttonName="$t('FLOW_SUCCESS_DELIVERY')"
          :updateAction="updateSuccessAction"
          :flowContextData="flowContextData"
          :flowContext="flowContext"
          :actionContext="flowContext.action_send_success_context"
      />
      <flow-next-actions
          v-if="!isShow"
          :buttonName="$t('FLOW_FAILED_DELIVERY')"
          :updateAction="updateFailedAction"
          :flowContextData="flowContextData"
          :flowContext="flowContext"
          :actionContext="flowContext.action_send_failed_context"
      />
      <flow-next-actions
          v-if="!isShow"
          :buttonName="$t('FLOW_DEFAULT_DELIVERY')"
          :updateAction="updateDefaultAction"
          :flowContextData="flowContextData"
          :flowContext="flowContext"
          :actionContext="flowContext.action_default_context"
      />
      <div class="row-button-action" style="text-align: right">
        <a-popconfirm
            @confirm="deleteCurrentFlowContext"
            :title="$t('FLOW_CONFIRM_DELETE_CONTEXT')"
            :ok-text="$t('YES')"
            :cancel-text="$t('NO')"
        >
          <a-button danger type="primary">
            <delete-outlined/>
            {{ $t("FLOW_DELETE") }}
          </a-button>
        </a-popconfirm>
      </div>
    </a-form>
  </div>
</template>

<script lang="ts">
import {
  computed,
  defineComponent,
  onBeforeMount,
  reactive,
  ref,
  UnwrapRef,
  watch,
} from "vue";
import {
  CloseCircleOutlined,
  CloseOutlined,
  DeleteOutlined,
  DragOutlined,
  NotificationOutlined,
  PictureOutlined,
  FileDoneOutlined,
  FileAddOutlined,
} from "@ant-design/icons-vue";
import FlowNextActions from "@/components/Flow/FlowNextActions.vue";
import {useStore} from "@/store";
import {S_EventBus, ValidateFlowContext} from "@/utils/EventBus";
import {useI18n} from "vue-i18n";
import {MixUtils} from "@/utils/Utils";
import ImageSelectionModal from "@/components/Image/ImageSelectionModal.vue";
import "vue3-carousel/dist/carousel.css";
import {AWS_DO_Model} from "@/models/AWS_DO/AWS_DO";
import {
  ActionContext, FlowContext,
  MessageContext,
  PostbackContext,
  QuickReply,
} from "@/models/Flows/FlowContext";
import {
  FormEmailContext,
  FormFlowInformation,
} from "@/models/Flows/FlowForm";
import {ValidateErrorEntity} from "ant-design-vue/es/form/interface";
import {Form, message} from 'ant-design-vue';
import APIs from "@/APIs/APIs";
import {State} from "@/store/modules/flow";
import {FlowConstants} from "@/models/Flows/FlowConstants";
import {FacebookPostData} from "@/models/facebook/FacebookPost";
import {TriggerCustomerComparison} from "@/models/Trigger/Trigger";
import useTagStore from "../../store/Tags";

const useForm = Form.useForm;

export default defineComponent({
  name: "flow-trigger-condition",
  props: [
    "flowContext",
    "updateCurrentFlowContext",
    "flowContextData",
    "deleteFlowContext",
      "isShow"
  ],
  components: {
    DeleteOutlined,
    FlowNextActions,
    // CloseCircleOutlined
  },
  setup(props) {
    const formRef = ref();
    const messengerTags = ref([
      {
        label: "NONE",
        value: "",
      },
      {
        label: "CONFIRMED_EVENT_UPDATE",
        value: "CONFIRMED_EVENT_UPDATE",
      },
      {
        label: "POST_PURCHASE_UPDATE",
        value: "POST_PURCHASE_UPDATE",
      },
      {
        label: "ACCOUNT_UPDATE",
        value: "ACCOUNT_UPDATE",
      },
    ]);

    const store = useStore();
    const i18n = useI18n();
    const formState: UnwrapRef<FlowContext> = reactive({
    trigger_condition:{
      condition: '',
      trigger_comparisons: [

    ]
    }
    });
    const  orderStatus=ref([{
          id:-1,
          name: 'STATUS_ORDER_CANCEL'
        }, {
          id: 0,
          name: 'STATUS_ORDER_NEW'
        }, {
          id: 1,
          name: 'STATUS_ORDER_CONFIRM'
        }, {
          id: 2,
          name: 'STATUS_ORDER_WAITING_SHIP'
        }, {
          id: 3,
          name: 'STATUS_ORDER_PICKED_SHIP'
        }, {
          id: 4,
          name: 'ORDER_SUCCESS'
        }, {
          id: 5,
          name: 'STATUS_ORDER_RETURNED'
        }, {
          id: 6,
          name: 'STATUS_ORDER_RETURNING'
        }, {
          id: 7,
          name: 'STATUS_ORDER_SHIPPING'
        }, {
          id: 8,
          name: 'STATUS_ORDER_RETURN_OFFER'
        },

        ])
    const timeoutSelections = ref( [
          {
            label: '10 ' + i18n.t('second'),
            value: "10"  //second
          },
        {
          label: '20 ' + i18n.t('second'),
        value: "20"  //second
  },
    {
      label: '1 ' + i18n.t('minutes'),
          value: "60"  //second
    },
    {
      label: '2 ' + i18n.t('minutes'),
          value: "120"  //second
    },
    {
      label: '3 ' + i18n.t('minutes'),
          value: "180"  //second
    },
    {
      label: '4 ' + i18n.t('minutes'),
          value: "240"  //second
    },
    {
      label: '5 ' + i18n.t('minutes'),
          value: "300"  //second
    },
    {
      label: '6 ' + i18n.t('minutes'),
          value: "360"  //second
    },
    {
      label: '7 ' + i18n.t('minutes'),
          value: "420"  //second
    },
    {
      label: '8 ' + i18n.t('minutes'),
          value: "480"  //second
    },
    {
      label: '9 ' + i18n.t('minutes'),
          value: "640"  //second
    },

    {
      label: '10 ' + i18n.t('minutes'),
          value: "600"  //second
    },
    {
      label: '11 ' + i18n.t('minutes'),
          value: "660"  //second
    },
    {
      label: '12 ' + i18n.t('minutes'),
          value: "720"  //second
    },
    {
      label: '13 ' + i18n.t('minutes'),
          value: "780"  //second
    },
    {
      label: '14 ' + i18n.t('minutes'),
          value: "840"  //second
    },
    {
      label: '15 ' + i18n.t('minutes'),
          value: "900"  //second
    },
    {
      label: '16 ' + i18n.t('minutes'),
          value: "960"  //second
    },
    {
      label: '17 ' + i18n.t('minutes'),
          value: "1020"  //second
    },
    {
      label: '18 ' + i18n.t('minutes'),
          value: "1080"  //second
    },
    {
      label: '19 ' + i18n.t('minutes'),
          value: "1140"  //second
    },
    {
      label: '20 ' + i18n.t('minutes'),
          value: "1200"  //second
    },
    {
      label: '21 ' + i18n.t('minutes'),
          value: "1260"  //second
    },
    {
      label: '22 ' + i18n.t('minutes'),
          value: "1320"  //second
    },
    {
      label: '23 ' + i18n.t('minutes'),
          value: "1380"  //second
    },
    {
      label: '24 ' + i18n.t('minutes'),
          value: "1440"  //second
    },
    {
      label: '25 ' + i18n.t('minutes'),
          value: "1500"  //second
    },
    {
      label: '26 ' + i18n.t('minutes'),
          value: "1560"  //second
    },
    {
      label: '27 ' + i18n.t('minutes'),
          value: "1620"  //second
    },
    {
      label: '28 ' + i18n.t('minutes'),
          value: "1680"  //second
    },
    {
      label: '29 ' + i18n.t('minutes'),
          value: "1740"  //second
    },
    {
      label: '30 ' + i18n.t('minutes'),
          value: "1800"  //second
    },
    {
      label: '31 ' + i18n.t('minutes'),
          value: "1860"  //second
    },
    {
      label: '32 ' + i18n.t('minutes'),
          value: "1920"  //second
    },
    {
      label: '33 ' + i18n.t('minutes'),
          value: "1980"  //second
    },
    {
      label: '34 ' + i18n.t('minutes'),
          value: "2040"  //second
    },
    {
      label: '35 ' + i18n.t('minutes'),
          value: "2100"  //second
    },
    {
      label: '36 ' + i18n.t('minutes'),
          value: "2160"  //second
    },
    {
      label: '37 ' + i18n.t('minutes'),
          value: "2220"  //second
    },
    {
      label: '38 ' + i18n.t('minutes'),
          value: "2280"  //second
    },
    {
      label: '39 ' + i18n.t('minutes'),
          value: "2340"  //second
    },
    {
      label: '40 ' + i18n.t('minutes'),
          value: "2400"  //second
    },
    {
      label: '41 ' + i18n.t('minutes'),
          value: "2460"  //second
    },
    {
      label: '42 ' + i18n.t('minutes'),
          value: "2520"  //second
    },
    {
      label: '43 ' + i18n.t('minutes'),
          value: "2580"  //second
    },
    {
      label: '44 ' + i18n.t('minutes'),
          value: "2640"  //second
    },
    {
      label: '45 ' + i18n.t('minutes'),
          value: "2700"  //second
    },
    {
      label: '46 ' + i18n.t('minutes'),
          value: "2760"  //second
    },
    {
      label: '47 ' + i18n.t('minutes'),
          value: "2820"  //second
    },
    {
      label: '48 ' + i18n.t('minutes'),
          value: "2880"  //second
    },
    {
      label: '49 ' + i18n.t('minutes'),
          value: "2940"  //second
    },
    {
      label: '50 ' + i18n.t('minutes'),
          value: "3000"  //second
    },
    {
      label: '51 ' + i18n.t('minutes'),
          value: "3060"  //second
    },
    {
      label: '52 ' + i18n.t('minutes'),
          value: "3120"  //second
    },
    {
      label: '53 ' + i18n.t('minutes'),
          value: "3180"  //second
    },
    {
      label: '54 ' + i18n.t('minutes'),
          value: "3240"  //second
    },
    {
      label: '55 ' + i18n.t('minutes'),
          value: "3300"  //second
    },
    {
      label: '56 ' + i18n.t('minutes'),
          value: "3360"  //second
    },
    {
      label: '57 ' + i18n.t('minutes'),
          value: "3420"  //second
    },
    {
      label: '58 ' + i18n.t('minutes'),
          value: "3480"  //second
    },
    {
      label: '59 ' + i18n.t('minutes'),
          value: "3540"  //second
    },
    {
      label: '1 ' + i18n.t('HOURS'),
          value: "3600"  //second
    },
    {
      label: '2 ' + i18n.t('HOURS'),
          value: "7200"  //second
    },
    {
      label: '3 ' + i18n.t('HOURS'),
          value: "10800"  //second
    },
    {
      label: '4 ' + i18n.t('HOURS'),
          value: "14400"  //second
    },
    {
      label: '5 ' + i18n.t('HOURS'),
          value: "18000"  //second
    },
    {
      label: '6 ' + i18n.t('HOURS'),
          value: "21600"  //second
    },
    {
      label: '7 ' + i18n.t('HOURS'),
          value: "25200"  //second
    },
    {
      label: '8 ' + i18n.t('HOURS'),
          value: "28800"  //second
    },
    {
      label: '9 ' + i18n.t('HOURS'),
          value: "32400"  //second
    },
    {
      label: '10 ' + i18n.t('HOURS'),
          value: "36000"  //second
    },
    {
      label: '11 ' + i18n.t('HOURS'),
          value: "39600"  //second
    },
    {
      label: '12 ' + i18n.t('HOURS'),
          value: "43200"  //second
    },
    {
      label: '13 ' + i18n.t('HOURS'),
          value: "46800"  //second
    },
    {
      label: '14 ' + i18n.t('HOURS'),
          value: "50400"  //second
    },
    {
      label: '15 ' + i18n.t('HOURS'),
          value: "54000"  //second
    },
    {
      label: '16 ' + i18n.t('HOURS'),
          value: "57000"  //second
    },
    {
      label: '17 ' + i18n.t('HOURS'),
          value: "61200"  //second
    },
    {
      label: '18 ' + i18n.t('HOURS'),
          value: "64800"  //second
    },
    {
      label: '19 ' + i18n.t('HOURS'),
          value: "68400"  //second
    },
    {
      label: '20 ' + i18n.t('HOURS'),
          value: "72000"  //second
    },
    {
      label: '21 ' + i18n.t('HOURS'),
          value: "75600"  //second
    }, {
      label: '22 ' + i18n.t('HOURS'),
          value: "79200"  //second
    }, {
      label: '23 ' + i18n.t('HOURS'),
          value: "82800"  //second
    },
    {
      label: '24 ' + i18n.t('HOURS'),
          value: "86400"  //second
    },
    {
      label: '2 ' + i18n.t('days'),
          value: "172800"  //second
    },
    {
      label: '3 ' + i18n.t('days'),
          value: "259200"  //second
    },
    {
      label: '4 ' + i18n.t('days'),
          value: "345600"  //second
    },

    {
      label: '5 ' + i18n.t('days'),
          value: "432000"  //second
    },
    {
      label: '6 ' + i18n.t('days'),
          value: "518400"  //second
    },
    {
      label: '1 ' + i18n.t('week'),
          value: "604800"
    },
    {
      label: '2 ' + i18n.t('week'),
          value: "1209600"  //second
    },
    {
      label: '3 ' + i18n.t('week'),
          value: "1814400"  //second
    },
    {
      label: '30 ' + i18n.t('days'),
          value: "2592000"  //second
    },
    {
      label: '2 ' + i18n.t('month'),
          value: "5184000"  //second
    },
    {
      label: '3 ' + i18n.t('month'),
          value: "7776000"  //second
    },
    {
      label: '4 ' + i18n.t('month'),
          value: "10368000"  //second
    },
    {
      label: '5 ' + i18n.t('month'),
          value: "12960000"  //second
    },
    {
      label: '6 ' + i18n.t('month'),
          value: "15552000"  //second
    },
    {
      label: '7 ' + i18n.t('month'),
          value: "18144000"  //second
    },
    {
      label: '8 ' + i18n.t('month'),
          value: "20736000"  //second
    },
    {
      label: '9 ' + i18n.t('month'),
          value: "23328000"  //second
    },
    {
      label: '10 ' + i18n.t('month'),
          value: "25920000"  //second
    },
    {
      label: '11 ' + i18n.t('month'),
          value: "28512000"  //second
    },
    {
      label: '12 ' + i18n.t('month'),
          value: "31104000"  //second
    },
    {
      label: '2 ' + i18n.t('YEAR'),
          value: "62208000"  //second
    },

  ])
    const messageContexts = ref<MessageContext[]>([]);
    const visibleCreateConfig = ref(false)
    const visibleCreateConfig2 = ref(false)
    const  getFlowConstants  = computed(()=>store.getters.getFlowConstants)
    const getColumnsConfig = computed(()=> store.getters.getCustomerConfig)
    const getColumn = computed(()=> store.getters.getDefaultColumns)
    const getTreeTags = computed(()=> store.getters.getTreeTags)
    const getPages = computed(()=>store.getters.getPages)
    const getConverstionTag = computed(()=>useTagStore.getState().pageConverstionTag)
    const getPostsSelection =computed(()=> store.getters.getPostsSelection())
    const posts = computed(()=> store.getters.getPostsSelection())
    const tags = computed(()=>store.getters.getCustomerCares)
    // @ts-ignore
    const column = ref([...getColumn.value,...getColumnsConfig.value].map((item:any) => {
      if(item.dataIndex &&  typeof item.dataIndex !== 'number'){
        return item.dataIndex
      }else if(item.identified_name){
        return  item.identified_name
      }
    }))



    const deleteCall = (index:any)=>{
      // @ts-ignore
      formState.flow_smart_call[0].key_pairs = formState.flow_smart_call[0].key_pairs.filter((item:any,i:any) => index !== i)
      onSubmit()
    }
    const deleteCall2 = (index:any)=>{
      // @ts-ignore
      formState.flow_smart_call[0].key_pair_report = formState.flow_smart_call[0].key_pair_report.filter((item:any,i:any) => index !== i)
      onSubmit()
    }
    column.value = column.value.filter((item:any) => item !== undefined)
    console.log(column,'0')
    const   editableData =  ref({})
    const {resetFields, validate, validateInfos} = useForm(
        formRef,
        reactive({
          name: [
            {
              required: true,
              message: i18n.t("FLOW_VALIDATE_FLOW_NAME"),
              trigger: "blur",
            },
          ],
          message: [
            {
              required: true,
              message: i18n.t("FLOW_VALIDATE_FACEBOOK_MESSAGE_CONTENT"),
              trigger: "blur",
            },
          ],
        }),
    );
 const    deleteCustomerConditionRow = (index: any) => {
      if (formState.trigger_condition && formState.trigger_condition.trigger_comparisons) {
        let conditions = formState.trigger_condition.trigger_comparisons
        delete conditions[index]
        formState.trigger_condition.trigger_comparisons = formState.trigger_condition.trigger_comparisons.filter((x: any) => x !== undefined)
      }
   onSubmit()
    }
    const addChildrenCondition = (index: any) =>{
      // @ts-ignore
      if(!formState.trigger_condition.trigger_comparisons[index].trigger_condition.trigger_comparisons){
        // @ts-ignore
        formState.trigger_condition.trigger_comparisons[index].trigger_condition.trigger_comparisons = []
      }
      // @ts-ignore
      formState.trigger_condition.trigger_comparisons[index].trigger_condition.trigger_comparisons.push({condition:" "})
      // @ts-ignore
      console.log(formState.trigger_condition.trigger_comparisons[index].trigger_condition.trigger_comparisons)
onSubmit()
    }
    const listConfig = ref([])
    const config = ref({key:"",value:""})
    const columnConfig =[
      {
        title: i18n.t('keywords'),
        dataIndex: 'key',
        key: 'key',
        slots: {customRender: 'key'},
      },
      {
        title: i18n.t('value'),
        dataIndex: 'value',
        key: 'value',
        slots: {customRender: 'value'},
      },
      {
        title: i18n.t(''),
        key: 'action',
        slots: {customRender: 'action'},
      },
    ]
    const columnConfig2 =[
      {
        title: i18n.t('keywords'),
        dataIndex: 'key',
        key: 'key',
        slots: {customRender: 'key'},
      },
      {
        title: i18n.t('value'),
        dataIndex: 'value',
        key: 'value',
        slots: {customRender: 'value'},
      },
      {
        title: i18n.t(''),
        key: 'action',
        slots: {customRender: 'action'},
      },
    ]
    const addTitle=()=>{
      formState.message+=" {{title}}"
      onSubmit()
    }
    const addFullName=()=>{
      formState.message+=" {{full_name}}"
      onSubmit()
    }
    const addScheduledTime=()=>{
      formState.message+=" {{scheduled_time}}"
      onSubmit()
    }
    const  saveConfig =  () =>  {
      onSubmit()
    }
    const addValue = () => {
      // @ts-ignore
      visibleCreateConfig.value = true
    }
    const addValue2 = () => {
      // @ts-ignore
      visibleCreateConfig2.value = true
    }
    const    getPostPicture = (post: FacebookPostData) => {
      if (post.full_picture) {
        return post.full_picture
      } else if (post.attachments && post.attachments.length > 0 && post.attachments[0].media && post.attachments[0].media.image && post.attachments[0].media.image.src) {
        return post.attachments[0].media.image.src
      } else {
        return "https://biglead.live/images/logo.png"
      }

    }
    const   getPostName = (post: FacebookPostData) => {
      if (post.message) {
        return post.message
      } else if (post.story) {
        return post.story
      } else if (post.attachments && post.attachments.length > 0 && post.attachments[0].title) {
        return post.attachments[0].title
      } else {
        return post.id
      }
    }
    const     addParentCondition = () => {
      // this.typeCondition = 'condition'
      if (!formState.trigger_condition) {
        formState.trigger_condition = {}
      }
      if (!formState.trigger_condition.trigger_comparisons) {
        formState.trigger_condition.trigger_comparisons = []
      }else {
        // @ts-ignore
        formState.trigger_condition.trigger_comparisons.push({condition:" "})
      }
      console.log( formState.trigger_condition.trigger_comparisons,'111')
      onSubmit()
    }
    const     addGroupCondition =()=>{
      // this.typeCondition = 'conditionGroup'
      if (!formState.trigger_condition) {
        formState.trigger_condition = {}
      }
      if (!formState.trigger_condition.trigger_comparisons) {
        formState.trigger_condition.trigger_comparisons = []
      }else {
        // @ts-ignore
        formState.trigger_condition.trigger_comparisons.push({trigger_condition:{}})
      }
       onSubmit()
    }
    const    deleteCustomerConditionChidrenRow = (idex:number,index:number) => {
      // @ts-ignore
      if (formState.trigger_condition && formState.trigger_condition.trigger_comparisons) {
        // @ts-ignore
        let conditions = formState.trigger_condition.trigger_comparisons[index].trigger_condition.trigger_comparisons
        delete conditions[idex]
        // @ts-ignore
        formState.trigger_condition.trigger_comparisons[index].trigger_condition.trigger_comparisons = formState.trigger_condition.trigger_comparisons[index]?.trigger_condition.trigger_comparisons.filter((x: TriggerCustomerComparison) => x !== undefined)
      }
      onSubmit()
    }
    const updateProps = () => {
      if (props.flowContext) {
        console.log(props.flowContext,'111')
        formState.name = props.flowContext.name;
        formState.message = props.flowContext.message;

        // @ts-ignore
        formState.flow_smart_call = props.flowContext.flow_smart_call !== null ? props.flowContext.flow_smart_call : [{key_pairs:[]}]

        formState.trigger_condition = props.flowContext.trigger_condition
        // @ts-ignore
        formState.trigger_condition.trigger_comparisons = formState.trigger_condition.trigger_comparisons.map((item:any)=>{
          if(item.field_type === 'last_contact_time' || item.field_type === 'first_contact_time' || item.field_type === 'key_word'  ){
            item.value1 = item.value.split("|")
          }else if(item.field_type === 'conversation_tag'){
            item.value1 = item.value.split(",").map((converstionT:any) => {
              // @ts-ignore
              getConverstionTag.value.map((i:any) => {
                i.tags.map((tag:any) => {
                  // @ts-ignore
                  if(converstionT === tag.tag_id){
                    converstionT = i.page_id + '_' + converstionT

                  }

                })

              })
              return converstionT
            })
          }
          if(!item.condition){
            item.trigger_condition.trigger_comparisons = item.trigger_condition.trigger_comparisons.map((i:any)=>{
              if(i.field_type === 'last_contact_time' || i.field_type === 'first_contact_time' || i.field_type === 'key_word'){
                i.value1 = i.value.split("|")
              }else if(i.field_type === 'conversation_tag'){
                i.value1 = i.value.split(",").map((converstionT:any) => {
                  // @ts-ignore
                  getConverstionTag.value.map((i:any) => {
                    i.tags.map((tag:any) => {
                      // @ts-ignore
                      if(converstionT === tag.tag_id){
                        converstionT = i.page_id + '_' + converstionT

                      }

                    })

                  })
                  return converstionT
                })
              }
              return i
            })
          }
          return item
        })
        // @ts-ignore
        if(formState.flow_smart_call[0].key_pairs === null){
          // @ts-ignore
          formState.flow_smart_call[0].key_pairs = []
        }
        // @ts-ignore
        formState.flow_smart_call[0].key_pairs.map((item) => {
          edit(item.key)
        })
      }
    };
    watch(
        () => props.flowContext,
        () => {
          updateProps();


        }
    );

    const getKeyCall = (str:any) => {
      let k:any = []
      // @ts-ignore
      formState.flow_smart_call[0].key_pairs.map((item)=>{
        str.map((i:any) => {
          if(i !== item.key){
            k.push(i)
          }
        })
      })
      // @ts-ignore
      if(k.length === 0){
        return str
      }else {
        return k
      }


    }
    const flowFormValidate: ValidateFlowContext = {
      showValidateForm() {
        onSubmit();
      },
    };
    const edit  = (key:any) =>  {
// @ts-ignore
      editableData.value[key] = {...formState.flow_smart_call[0].key_pairs.filter((item) => key === item.key)[0]}


    }
    onBeforeMount(() => {
      S_EventBus.instance().setValidateFlowContextListener(flowFormValidate);
    });

    const onTextChange = () => {
      onSubmit();
    };
    const getConvertionTangId = (id:any,page:any) =>{
      console.log(id,page,'tag')
      return  id.map((i:any) => {
        console.log(page , i.split("_")[0])
        if(page !== i.split("_")[0]){
          return i.split("_")[1]
        }else {
          return []
        }

      })


    }
   const getCondition =(conditon:any,fieldType:any,index:number,idex:number) => {
      const conditons:any[] = []
      if(fieldType === 'page_id' || fieldType === 'messenger_window_24h' || fieldType === 'has_phone_number'   || fieldType === 'last_contact_time' || fieldType === 'first_contact_time' || fieldType ==='care_status' || fieldType === 'post_id'){
        conditon.map((item:any) => {
          if (item === 'EQUAL' ){
            conditons.push(item)
          } else if (item === 'NOT_EQUAL'){
            conditons.push(item)
          }
        })
      }else if(fieldType === 'tag' || fieldType === 'conversation_tag' || fieldType == 'order_status' ||fieldType === 'key_word'  ){
        conditon.map((item:any) => {
          if (item === 'CONTAIN' ){
            conditons.push(item)
          } else if (item === 'NOT_CONTAIN'){
            conditons.push(item)
          }
        })


      } else if(fieldType === 'timeout'){
        conditon.map((item:any) => {
          if (item === 'EQUAL' ){
            conditons.push(item)
          }else if(item === 'GREATER_THAN'){
            conditons.push(item)
          }
        })

      } else {
        conditon.map((item:any) => {
          conditons.push(item)
        })

      }
      return conditons

    }
    const updateOpenEmailAction = (action: ActionContext) => {
      const flow = {...props.flowContext};
      flow.action_open_mail_context = action;
      props.updateCurrentFlowContext(flow);
    };

    const updateClickToLinkAction = (action: ActionContext) => {
      const flow = {...props.flowContext};
      flow.action_click_to_link_context = action;
      props.updateCurrentFlowContext(flow);
    };

    const addConfig = () => {
      // @ts-ignore
      formState.flow_smart_call[0].key_pairs.push(config.value)
      visibleCreateConfig.value = false
      onSubmit()
    }
    const addConfig2 = () => {
      // @ts-ignore
      formState.flow_smart_call[0].key_pair_report.push(config.value)
      visibleCreateConfig2.value = false
      onSubmit()
    }
    const updateSuccessAction = (action: ActionContext) => {
      const flow = {...props.flowContext};
      flow.action_send_success_context = action;
      props.updateCurrentFlowContext(flow);
    };
    const updateSuccessActionCall = (action: ActionContext,index:any) => {
      console.log(action,index)
      const flow = {...props.flowContext};
      flow.flow_smart_call[0].key_pair_report[index].next_flow_action_context = action;
      props.updateCurrentFlowContext(flow);
    };
    const updateFailedAction = (action: ActionContext) => {
      const flow = {...props.flowContext};
      flow.action_send_failed_context = action;
      props.updateCurrentFlowContext(flow);
    };
    const updateDefaultAction = (action: ActionContext) => {
      const flow = {...props.flowContext};
      flow.action_default_context = action;
      props.updateCurrentFlowContext(flow);
    };

    const deleteCurrentFlowContext = () => {
      props.deleteFlowContext(props.flowContext.id);
    };

    const onSubmit = () => {
      validate().then(() => {
        const flow = {...props.flowContext};
        // @ts-ignore
        flow.message = formState.message;
        // @ts-ignore
        formState.flow_smart_call = formState.flow_smart_call.filter((item) => item.key_pairs !== null|| item.key_pair_report !==null)
        flow.flow_smart_call = formState.flow_smart_call
        flow.trigger_condition = formState.trigger_condition
        // @ts-ignore
        formState.trigger_condition.trigger_comparisons = formState.trigger_condition.trigger_comparisons.map((item:any)=>{
          // @ts-ignore
          if(item.field_type === 'last_contact_time' || item.field_type === 'first_contact_time'|| item.field_type === 'key_word' ){
            // @ts-ignore
            item.value = item.value1.join('|')

          }else if (item.field_type === 'conversation_tag') {
            item.value = item.value1.map((tag:any) => {
              // @ts-ignore
              tag = tag.split("_")[1]
              return tag
            }).join(",")
          }
          if(!item.condition){
            item.trigger_condition.trigger_comparisons = item.trigger_condition.trigger_comparisons.map((i:any)=>{
              // @ts-ignore
              if(i.field_type === 'last_contact_time' || i.field_type === 'first_contact_time'|| i.field_type === 'key_word' ){
                // @ts-ignore
                i.value = i.value1.join('|')
              }else if (i.field_type === 'conversation_tag') {
                i.value = i.value1.map((tag:any) => {
                  // @ts-ignore
                  tag = tag.split("_")[1]
                  return tag
                }).join(",")
              }
              return i
            })
          }
          return item
        })
        flow.name = formState.name;
        props.updateCurrentFlowContext(flow);
      }).catch((error: ValidateErrorEntity<FormFlowInformation>) => {
        console.log("error", error);
      });
    };
    updateProps();
    return {
      formState,
      getCondition,
      formRef,
      onSubmit,
      onTextChange,
      messengerTags,
      updateSuccessAction,
      updateFailedAction,
      updateDefaultAction,
      deleteCurrentFlowContext,
      messageContexts,
      validateInfos,
      updateOpenEmailAction,
      updateClickToLinkAction,
      addTitle,
      addFullName,
      addScheduledTime,
      columnConfig,
      listConfig,
      addValue,
      visibleCreateConfig,
      visibleCreateConfig2,
      config,
      getColumn,
      deleteCall2,
      getColumnsConfig,
      addConfig,
      editableData,
      edit,saveConfig,
      getKeyCall,
      column,
      columnConfig2,
      addConfig2,
      updateSuccessActionCall,
      addValue2,
      deleteCall,
      getFlowConstants,
      addParentCondition,
      addGroupCondition,
      getTreeTags,
      getPages,
      getConverstionTag,
      posts,
      getPostPicture,
      getPostName,
      timeoutSelections,
      tags,
      orderStatus,
      getConvertionTangId,
      deleteCustomerConditionRow,
      addChildrenCondition,
      deleteCustomerConditionChidrenRow,
      options:[ {value:'contain_phone_number'}],
      options2:[ {value:'contain_phone_number'}]
    };
  },
});
</script>

<style lang="scss" scoped>
.main-flow {
  position: relative;
  padding: 40px 1% 60px;
  text-align: left;

  .icon-brand-sticker {
    position: absolute;
    font-size: 28px;
    top: 0;
    margin-right: 25px;
    right: 0;
  }

  .row-button-action {
    margin-top: 20px;
  }

  .card-message {
    margin-top: 10px;
    border-radius: 10px;
  }
}

.post-back-button {
  margin-top: 18px;
  text-align: center;
}

.button-post-back {
  margin: 5px 10px;
}

.slick-dot-facebook-template {
  display: inline-block;
  cursor: pointer;
  margin: 7px;

  .slick-dot-facebook-template-content {
    padding: 2px;
  }
}

.card-facebook-template {
  width: 95%;
}

.card-facebook-template-active {
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.img-cover-facebook-template {
  width: 100%;
  height: 200px;
  object-fit: cover;
}

.upload-center {
  display: flex;
  justify-content: center;
  height: 200px;
  align-items: center;
}


.attach-file-container {
  margin-top: 18px;
}

.photo-flow-img {
  max-height: 200px;
  object-fit: cover;
  cursor: pointer;
}

.icon-img-svg {
  width: 28px;
  height: 28px;
}

.button-quick-reply {
  border-radius: 15px;
}
</style>
<style>
.carousel__prev--in-active {
  display: none;
}

.carousel__next--in-active {
  display: none;
}
</style>
