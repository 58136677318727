<template>
  <div style="font-size: 8px!important;">
  <a-card style="box-shadow: 0 0">
    <template #title>
     <div>
            <span class="content-info-value content-info-value-special">
              {{order.order_number}}
            </span>
      <div><span class="order-date">{{moment(order.created_at).format('DD/MM/YYYY')}}</span></div>

     </div>
    </template>
    <template #extra>
      <span class="header-order-container-extra">
       <vue-barcode :value="order.shipping_code?.split('.').pop()"
                    :options="{ margin: 0, height: 40, fontSize: 12, }"
       ></vue-barcode>
      </span>
    </template>
    <ul class="card-container-order">
      <li class="card-container-order-left">
        <div class="contain-info">
           <span class="title-order-common-info">
              <shop-outlined/>
           {{$t('ORDER_FROM')}}:
          </span>
          <span>
            {{$t('STOCK')}}:
            <span>
              <span type="link" class="button-branch">
               {{order.shop_name}}
              </span>
            </span>
          </span>
          <span>
             {{$t('ADDRESS')}}:
            <span class="content-info-value">
              {{order.shop_address}}
            </span>
          </span>
          <span>
             {{$t('phone_number')}}:
            <span class="content-info-value button-branch">
             {{order.shop_tel}}
            </span>
          </span>
          <span>
            Email:
            <span class="content-info-value">
            {{order.shop_email}}
            </span>
          </span>
        </div>
      </li>
      <li class="card-container-order-mid">
        <div class="contain-info">
          <span class="title-order-common-info">
           {{$t('ORDER_TO')}}:
          </span>
          <span>
             <span :to="'/customer/'+order.customer_id">
       <span class="button-branch">
          {{order.shipping_name}}
       </span>
        </span>
          </span>
          <span>
             {{$t('ADDRESS')}}:
            <span class="content-info-value">
             {{order.shipping_address}}
            </span>
          </span>
          <span>
             {{$t('phone_number')}}:
            <span class="content-info-value button-branch">
             {{order.phone}}
            </span>
          </span>
          <span>
            Email:
            <span class="content-info-value">
            {{order.email}}
            </span>
          </span>
        </div>
      </li>
<!--      <li class="card-container-order-right">-->
<!--        <div class="contain-info">-->
<!--          <span class="title-order-common-info">-->
<!--            <info-circle-outlined/>-->
<!--           {{$t('MAIN_INFO')}}-->
<!--          </span>-->
<!--          <span>-->
<!--            {{$t('ORDER_ID')}}:-->
<!--            <span class="content-info-value content-info-value-special">-->
<!--              {{order.order_number}}-->
<!--            </span>-->
<!--          </span>-->
<!--          <span>-->
<!--            {{$t('SHIPPING_METHOD')}}:-->
<!--            <span class="content-info-value content-info-value-special">-->
<!--              {{order.shipping_method}}-->
<!--            </span>-->
<!--          </span>-->
<!--          <span>-->
<!--            {{$t('ORDER_SHIPPING_UNIT_ID')}}:-->
<!--            <span class="content-info-value content-info-value-special">-->
<!--              {{order.shipping_code}}-->
<!--            </span>-->
<!--          </span>-->
<!--          <span>-->
<!--            {{$t('CREATOR')}}:-->
<!--             <span :to="'/customer/'+order.created_by">-->
<!--       <span class="button-branch">-->
<!--            {{ findEmployee(order.created_by).name }}-->
<!--       </span>-->
<!--        </span>-->
<!--          </span>-->
<!--        </div>-->
<!--      </li>-->
    </ul>

    <a-table :columns="productColumns" :data-source="order.products" class="table-product" :pagination="false">
      <template #product_name="{ record }">
        <span>{{ record.product_name }}<br/>{{record.attributes?JSON.parse(record.attributes).map(a=>a.value).join('/'): ''}}</span>
      </template>
      <template #unit_price="{record}">
        {{formatMoney(record.unit_price)}}
      </template>
      <template #quantity="{record}">
        {{formatMoney(record.quantity)}}
      </template>
      <template #total="{record}">
        <span class="total">
          {{ formatMoney(record.quantity*record.unit_price) }}
        </span>
      </template>
    </a-table>
    <a-row>
      <a-col :span="6">
        <p>
          {{$t('NOTE')}}:
        </p>
      </a-col>
      <a-col :span="6" class="shopf1-note-contain">
        <p class="shopf1-note-value">
          {{order.note_bill}}
        </p>
      </a-col>
      <a-col :span="6">
        <p>
          {{$t('DISCOUNT')}}
        </p>
        <p>
          {{$t('SHIPPING_COST')}}
        </p>
        <p>
          {{$t('PAID')}}
        </p>
        <p>
          {{$t('TOTAL_AMOUNT')}}
        </p>
        <p>
          {{$t('COD')}}
        </p>
      </a-col>
      <a-col :span="6" class="order-price-value">
        <p>
          {{formatMoney(order.discount)}}
        </p>
        <p>
          {{formatMoney(order.ship_revenue)}}
        </p>
        <p>
          {{formatMoney(order.prepaid)}}
        </p>
        <p>
          {{formatMoney(order.total)}}
        </p>
        <p>
          {{formatMoney(order.cod_amount)}}
        </p>
      </a-col>
    </a-row>
  </a-card>
  </div>
</template>

<script lang="ts">
import {computed, defineComponent, ref} from "vue";
import {
  EnvironmentOutlined, InfoCircleOutlined,
  PrinterFilled, ShopOutlined, EditOutlined, RocketOutlined, StopOutlined
} from "@ant-design/icons-vue";
import APIs from "@/APIs/APIs";
import {Orders} from "@/models/Orders/Orders";
import moment from "moment";
import {Employee} from "@/models/Users";
import {store} from "@/store";
import {useStore} from "vuex";
import {MixUtils} from "@/utils/Utils";
import {message} from "ant-design-vue";
import {useI18n} from "vue-i18n";

export default defineComponent({
  name: "OrderPreview",
  components: {

    // EnvironmentOutlined,
    // InfoCircleOutlined,
  },
  created() {
    this.$watch(
        () => this.$route.params,
        () => {
          if(this.$route.params.id){
            console.log('params', this.$route.params.id)
            this.fetchOrder(this.$route.params.id)
          }
        },
        // fetch the data when the view is created and the data is
        // already being observed
        { immediate: true }
    )
  },
  setup() {
    const i18n = useI18n()
    const store = useStore()
    const order = ref<Orders>({})

    const getListEmployee=computed(():Employee[] =>store.getters.getListEmployee)

    const findEmployee = (staff_id: number)=>{
      let employee = {}
      const employeeFound = getListEmployee.value.find(staff=>staff.id+'' === staff_id+'')
      return employeeFound? employeeFound : employee
    }

    const fetchOrder = async (order_number:  any)=>{
      const response = await APIs.init().getOrderDetail({order_number: order_number})
      if (response.status === 200 && response.data?.status === 200) {
        if(response.data.data){
          order.value = response.data.data
          setTimeout(()=>{
            window.print()
          }, 1000)
        }
      }
    }

    const formatMoney = (money: string)=>{
      return MixUtils.methods.formatMoney(money)
    }

    const getNextOrderActions = (status: number, shipping_method: string) =>{
      switch (status) {
        case 0:
          return ['edit_order', 'confirm_order', 'cancel_order']; // Đơn nháp
        case 1:
          return shipping_method == 'default' ? ['edit_order', 'order_success', 'cancel_order'] : ['edit_order', 'send_order', 'cancel_order']; // Đã xác nhận
        case 2:
          return ['cancel_order']; // Chờ lấy hàng
        case 3: // Đã lấy hàng
        case 4:  // Giao thành công
          return [];
        case 8:
          return ['order_shipping', 'order_returning']; // Đề nghị hoàn
        default:
          return []
      }
    }

    const changeStatus = async (status: number) => {
      message.loading('')
      const response = await APIs.init().changeOrderStatus({
        id: order.value.id,
        status: status
      })
      message.destroy()
      if (response.status === 200 && response.data?.status === 200) {
        fetchOrder(order.value.order_number)
      }
    }

    const successOrder = ()=>{

      changeStatus(4)
    }

    const sendOrder = ()=>{

      changeStatus( 1)
    }

    const cancelOrder = ()=>{

      changeStatus(-1)
    }
    const shippingOrder = ()=>{

      changeStatus(2)
    }
    const returningOrder = ()=>{
      changeStatus(6)
    }

    const confirmOrder = ()=>{

      changeStatus( 1)
    }

    const productColumns = computed(()=>[
      {
        title: i18n.t('Code'),
        dataIndex: 'product_code',
        key: 'product_code',
        // slots: {customRender: 'product_code'},
      },
      {
        title: i18n.t('NAME'),
        dataIndex: 'product_name',
        key: 'product_name',
        slots: {customRender: 'product_name'},
      },
      {
        title: i18n.t('sale_price'),
        key: 'unit_price',
        dataIndex: 'unit_price',
        slots: {customRender: 'unit_price'},
      },
      {
        title: i18n.t('Quantity'),
        key: 'quantity_order',
        dataIndex: 'quantity_order',
        slots: {customRender: 'quantity'},
      },
      {
        title: i18n.t('Weight'),
        key: 'weight',
        dataIndex: 'weight',
      },
      {
        title: i18n.t('TOTAL'),
        key: 'total',
        dataIndex: 'total',
        slots: {customRender: 'total'},
      },
    ]);

    const statusColumns= [
      {
        title: i18n.t('NAME'),
        dataIndex: 'change_by',
        key: 'change_by',
      },
      {
        title: i18n.t('INFORMATION'),
        dataIndex: 'change_info',
        key: 'change_info',
        slots: {customRender: 'change_info'},
      },
      {
        title: i18n.t('DATE'),
        dataIndex: 'change_time',
        key: 'change_time',
        slots: {customRender: 'change_time'},
      },
      {
        title: i18n.t('STATUS'),
        dataIndex: 'status',
        key: 'status',
        slots: {customRender: 'status'},
      },
    ]



    return {
      productColumns,
      statusColumns,
      fetchOrder,
      order,
      moment,
      formatMoney,
      findEmployee,
      getNextOrderActions,
      successOrder,
      confirmOrder,
      sendOrder,
      cancelOrder,
      shippingOrder,
      returningOrder,
    }
  }
})
</script>

<style scoped lang="scss">
.button-print-order {
  margin-left: 18px;
}

.button-edit-order {
  margin-left: 12px;
}

.header-order-container-extra {
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    width: 24px;
    height: 24px;
  }

  .shipping-unit-title {
    margin-left: 8px;
    font-weight: bold;
  }


}

.order-date {
  font-size: 13px
}

/*@media only screen and (min-width: 768px) {*/
  /* For desktop: */
  .card-container-order {
    margin-left: -22px;
    margin-right: -22px;
    list-style-type:none;
    li {
      display: inline-block;
      width: 45%;
    }
  }
  .card-container-order-left {
    padding-right: 10px;
  }
  .card-container-order-right {
    padding-left: 10px;
  }
  .card-container-order-mid {
    padding-left: 10px;
    border-left: #dddddd solid 1px;
    //border-right: #dddddd solid 1px;
  }
/*}*/

/*@media only screen and (max-width: 500px) {*/
/*  .card-container-order {*/
/*    list-style-type:none;*/
/*    margin-left: -20px;*/
/*    margin-right: -20px;*/

/*    li {*/
/*      margin-top: 10px;*/
/*    }*/
/*  }*/
/*}*/

.contain-info {
  display: flex;
  flex-direction: column;

  .button-branch {
    font-weight: bold;
  }

  .content-info-value {
    margin-left: 10px;
  }

  .content-info-value-special {
    font-weight: bold;
  }

  .title-order-common-info {
    font-weight: bold;
  }
}

.content-info-value-special {
  font-weight: bold;
}


.table-product {
  margin-left: -14px;
  margin-right: -14px;
  margin-bottom: 10px;

  .total {
    font-weight: bold;
  }
}

.shopf1-note-contain{
  padding-right: 25px;
  .shopf1-note-value {
    background-color: #eeeeee;
    padding: 10px 20px;
    border-radius: 8px;
    width: 100%;
  }
}



.shopf1-shipping-note-label{
  /*padding-top: 10px;*/
}
.order-price-value{
  text-align: right;
  font-weight: bold;
  padding-right: 100px;
}

.title-updated-status{
  margin-top: 20px;
  margin-bottom: 15px;
}
</style>
