<template>
  <div class="container-content">
    <a-row style="width: 100%" justify="center">
      <a-col :span="12" >
       <a-row    justify="center">

           <a-typography-title :level="2" style="color: #fab216">{{$t('GET_IN_TOUCH')}}</a-typography-title>


       </a-row>
        <a-row justify="center">
          <a-typography-title :level="3" style="color: rgba(0, 0, 0, 0.45)">{{$t('HAVE_ANY_QUESTION_OR_COMMENT')}}</a-typography-title>
        </a-row>

         <a-row style="width: 100%;background-color: white;border-radius: 10px ; box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;"  >
           <a-col :span="12">
            <a-form style="margin: 20px" :model="formState" layout="vertical">
              <a-form-item
                  label="Email"

              >
                <a-input :bordered="false" style="border-bottom: 2px solid #fab21642"  v-model:value="formState.email" />
              </a-form-item>
              <a-form-item
                  :label="$t('NAME')"

              >
                <a-input :bordered="false" style="border-bottom: 2px solid #fab21642"   v-model:value="formState.name" />
              </a-form-item>
              <a-form-item
                  :label="$t('CUSTOMER_PHONE')"

              >
                <a-input :bordered="false" style="border-bottom: 2px solid #fab21642"   v-model:value="formState.phone_number" />
              </a-form-item>
              <a-form-item


              >
                <a-textarea :bordered="false"  v-model:value="formState.phone_number" :placeholder="$t('BIG90')" allow-clear />
              </a-form-item>


            </a-form>
             <a-divider style="height: 3px;background-color: #fab216"  />
             <a-row style="width: 100%;justify-content: center" >
               <a-button type="primary">{{ $t('send') }}</a-button>
             </a-row>
           </a-col>
           <a-col style="background-color: #fab21642" :span="12">
            <a-row style="margin-top: 10px"   justify="space-around">
              <a-typography-title :level="3" style="color: #fab216">{{$t('SUPPORT_CHANNEL')}}</a-typography-title>

            </a-row>
             <a-row justify="center" style="margin-bottom: 10px">
               <img height="48" :src="logo" />
             </a-row>
             <a-row >
               <a-col :span="2"></a-col>
               <a-col  :span="4">
                 <img   width="48" height="48" src="@/assets/icons/apple.png" />
               </a-col>
               <a-col :span="16">
                 <a-row>
                   <a-typography-text strong style="color: #fab216;font-size: 18px">{{$t('HOTLINE')}}</a-typography-text>
                 </a-row>

                 <a-typography-text style="font-size: 16px"  type="secondary">0982.442.109</a-typography-text>

               </a-col>
             </a-row>
             <a-row justify="center">
               <a-typography-text type="secondary" style="color: #fab216">8:30AM-16:30PM</a-typography-text>
             </a-row>
             <a-row justify="center">
               <a-typography-text type="secondary" style="color: #fab216">{{$t('MONDAY_SATURDAY')}}</a-typography-text>
             </a-row>
             <a-row justify="center" style="margin-left: 10px">
               <img height="250" src="@/assets/icons/support.png" />
             </a-row>
           </a-col>
         </a-row>

      </a-col>






    </a-row>

  </div>
</template>
<script >
import logo from "@/assets/icons/logoBiglead.png"
import Vue from 'vue'
import VueFbCustomerChat from 'vue-fb-customer-chat'


export default {

  setup(){
    // Vue.use(VueFbCustomerChat, {
    //   page_id: '291207261415821', //  change 'null' to your Facebook Page ID,
    //   theme_color: '#333333', // theme color in HEX
    //   locale: 'en_US', // default 'en_US'
    // })
const formState = {
  email:'',
  name: '',
  phone_number:undefined,
  description: ''
}
    return{
      logo,
      formState
    }
  }
}
</script>
<style scoped>


</style>
