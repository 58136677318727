import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createSlots as _createSlots, normalizeStyle as _normalizeStyle, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-4992cede"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "container-title" }
const _hoisted_2 = { class: "title-header" }
const _hoisted_3 = { class: "input-search" }
const _hoisted_4 = { style: {"display":"flex"} }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_input_search = _resolveComponent("a-input-search")!
  const _component_plus_outlined = _resolveComponent("plus-outlined")!
  const _component_a_button = _resolveComponent("a-button")!
  const _component_a_tag = _resolveComponent("a-tag")!
  const _component_eye_outlined = _resolveComponent("eye-outlined")!
  const _component_a_menu_item = _resolveComponent("a-menu-item")!
  const _component_edit_outlined = _resolveComponent("edit-outlined")!
  const _component_swap_outlined = _resolveComponent("swap-outlined")!
  const _component_delete_outlined = _resolveComponent("delete-outlined")!
  const _component_a_popconfirm = _resolveComponent("a-popconfirm")!
  const _component_a_menu = _resolveComponent("a-menu")!
  const _component_a_dropdown = _resolveComponent("a-dropdown")!
  const _component_a_table = _resolveComponent("a-table")!
  const _component_a_input = _resolveComponent("a-input")!
  const _component_a_form_item = _resolveComponent("a-form-item")!
  const _component_ColorPicker = _resolveComponent("ColorPicker")!
  const _component_a_textarea = _resolveComponent("a-textarea")!
  const _component_a_form = _resolveComponent("a-form")!
  const _component_a_modal = _resolveComponent("a-modal")!
  const _component_a_tree_select_node = _resolveComponent("a-tree-select-node")!
  const _component_a_tree_select = _resolveComponent("a-tree-select")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("h3", _hoisted_2, _toDisplayString(_ctx.$t(_ctx.$route.name)), 1),
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_a_input_search, {
          value: _ctx.search,
          "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.search) = $event)),
          placeholder: _ctx.$t('SEARCH'),
          style: {"width":"100%"}
        }, null, 8, ["value", "placeholder"])
      ]),
      (_ctx.getMyRole()?.is_create)
        ? (_openBlock(), _createBlock(_component_a_button, {
            key: 0,
            type: "primary",
            onClick: _ctx.newTag
          }, {
            icon: _withCtx(() => [
              _createVNode(_component_plus_outlined)
            ]),
            default: _withCtx(() => [
              _createTextVNode(" " + _toDisplayString(_ctx.$t("TAG_CREATE")), 1)
            ]),
            _: 1
          }, 8, ["onClick"]))
        : _createCommentVNode("", true)
    ]),
    _createVNode(_component_a_table, {
      size: "middle",
      scroll: {  y: '80vh' },
      class: "table",
      rowKey: record => record.id,
      columns: _ctx.columns,
      pagination: false,
      onChange: _ctx.handleTableChange,
      "data-source": _ctx.getTags,
      expandedRowKeys: _ctx.expandedRowKeys,
      onExpand: _ctx.tableExpand
    }, _createSlots({
      name: _withCtx(({ record }) => [
        _createVNode(_component_a_tag, {
          color: record.color
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(record.name), 1)
          ]),
          _: 2
        }, 1032, ["color"])
      ]),
      created_at: _withCtx(({text}) => [
        _createElementVNode("span", null, _toDisplayString(_ctx.dateTimeDisplayFormatFromString(text)), 1)
      ]),
      action: _withCtx(({ record }) => [
        _createVNode(_component_a_dropdown, null, {
          overlay: _withCtx(() => [
            _createVNode(_component_a_menu, null, {
              default: _withCtx(() => [
                (_ctx.getMyRole()?.is_create)
                  ? (_openBlock(), _createBlock(_component_a_menu_item, {
                      key: 0,
                      onClick: ($event: any) => (_ctx.newChildren(record.id))
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_plus_outlined),
                        _createTextVNode(" " + _toDisplayString(_ctx.$t("ADD_CHILDREN")), 1)
                      ]),
                      _: 2
                    }, 1032, ["onClick"]))
                  : _createCommentVNode("", true),
                (_ctx.getMyRole()?.is_edit)
                  ? (_openBlock(), _createBlock(_component_a_menu_item, {
                      key: 1,
                      onClick: ($event: any) => (_ctx.editClick(record))
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_edit_outlined),
                        _createTextVNode(" " + _toDisplayString(_ctx.$t("CUSTOMER_DETAIL_EDIT")), 1)
                      ]),
                      _: 2
                    }, 1032, ["onClick"]))
                  : _createCommentVNode("", true),
                (_ctx.getMyRole()?.is_edit)
                  ? (_openBlock(), _createBlock(_component_a_menu_item, {
                      key: 2,
                      onClick: ($event: any) => (_ctx.moveClick(record,'parents'))
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_swap_outlined),
                        _createTextVNode(" " + _toDisplayString(_ctx.$t("move")), 1)
                      ]),
                      _: 2
                    }, 1032, ["onClick"]))
                  : _createCommentVNode("", true),
                (_ctx.getMyRole()?.is_delete)
                  ? (_openBlock(), _createBlock(_component_a_menu_item, { key: 3 }, {
                      default: _withCtx(() => [
                        _createVNode(_component_a_popconfirm, {
                          title: "Are you sure delete this tag?",
                          "ok-text": "Yes",
                          "cancel-text": "No",
                          onConfirm: ($event: any) => (_ctx.deleteTag(record))
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_delete_outlined),
                            _createTextVNode(" " + _toDisplayString(_ctx.$t("CUSTOMER_DELETE")), 1)
                          ]),
                          _: 2
                        }, 1032, ["onConfirm"])
                      ]),
                      _: 2
                    }, 1024))
                  : _createCommentVNode("", true)
              ]),
              _: 2
            }, 1024)
          ]),
          default: _withCtx(() => [
            _createVNode(_component_a_button, { type: "text" }, {
              default: _withCtx(() => [
                _createVNode(_component_eye_outlined)
              ]),
              _: 1
            })
          ]),
          _: 2
        }, 1024)
      ]),
      _: 2
    }, [
      (_ctx.getType==='customer')
        ? {
            name: "expandedRowRender",
            fn: _withCtx(({record}) => [
              _createElementVNode("div", null, [
                (_ctx.getMyRole()?.is_create)
                  ? (_openBlock(), _createBlock(_component_a_button, {
                      key: 0,
                      type: "primary",
                      onClick: ($event: any) => (_ctx.newChildren(record.id))
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_plus_outlined),
                        _createTextVNode(" " + _toDisplayString(_ctx.$t("ADD_CHILDREN")), 1)
                      ]),
                      _: 2
                    }, 1032, ["onClick"]))
                  : _createCommentVNode("", true),
                _createVNode(_component_a_table, {
                  rowKey: record2 => record2.id,
                  columns: _ctx.columns,
                  loading: _ctx.childrenLoading,
                  "data-source": _ctx.childrenTags
                }, {
                  name: _withCtx(({ record }) => [
                    _createVNode(_component_a_tag, {
                      color: record.color
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(record.name), 1)
                      ]),
                      _: 2
                    }, 1032, ["color"])
                  ]),
                  created_at: _withCtx(({text}) => [
                    _createElementVNode("span", null, _toDisplayString(_ctx.dateTimeDisplayFormatFromString(text)), 1)
                  ]),
                  action: _withCtx(({ record }) => [
                    _createVNode(_component_a_dropdown, null, {
                      overlay: _withCtx(() => [
                        _createVNode(_component_a_menu, null, {
                          default: _withCtx(() => [
                            (_ctx.getMyRole()?.is_edit)
                              ? (_openBlock(), _createBlock(_component_a_menu_item, {
                                  key: 0,
                                  onClick: ($event: any) => (_ctx.moveClick(record,'children'))
                                }, {
                                  default: _withCtx(() => [
                                    _createVNode(_component_swap_outlined),
                                    _createTextVNode(" " + _toDisplayString(_ctx.$t("move")), 1)
                                  ]),
                                  _: 2
                                }, 1032, ["onClick"]))
                              : _createCommentVNode("", true),
                            (_ctx.getMyRole()?.is_edit)
                              ? (_openBlock(), _createBlock(_component_a_menu_item, {
                                  key: 1,
                                  onClick: ($event: any) => (_ctx.editClick(record))
                                }, {
                                  default: _withCtx(() => [
                                    _createVNode(_component_edit_outlined),
                                    _createTextVNode(" " + _toDisplayString(_ctx.$t("CUSTOMER_DETAIL_EDIT")), 1)
                                  ]),
                                  _: 2
                                }, 1032, ["onClick"]))
                              : _createCommentVNode("", true),
                            (_ctx.getMyRole()?.is_delete)
                              ? (_openBlock(), _createBlock(_component_a_menu_item, { key: 2 }, {
                                  default: _withCtx(() => [
                                    _createVNode(_component_a_popconfirm, {
                                      title: "Are you sure delete this tag?",
                                      "ok-text": "Yes",
                                      "cancel-text": "No",
                                      onConfirm: ($event: any) => (_ctx.deleteTag(record))
                                    }, {
                                      default: _withCtx(() => [
                                        _createVNode(_component_delete_outlined),
                                        _createTextVNode(" " + _toDisplayString(_ctx.$t("CUSTOMER_DELETE")), 1)
                                      ]),
                                      _: 2
                                    }, 1032, ["onConfirm"])
                                  ]),
                                  _: 2
                                }, 1024))
                              : _createCommentVNode("", true)
                          ]),
                          _: 2
                        }, 1024)
                      ]),
                      default: _withCtx(() => [
                        _createVNode(_component_a_button, { type: "text" }, {
                          default: _withCtx(() => [
                            _createVNode(_component_eye_outlined)
                          ]),
                          _: 1
                        })
                      ]),
                      _: 2
                    }, 1024)
                  ]),
                  _: 2
                }, 1032, ["rowKey", "columns", "loading", "data-source"])
              ])
            ]),
            key: "0"
          }
        : undefined
    ]), 1032, ["rowKey", "columns", "onChange", "data-source", "expandedRowKeys", "onExpand"]),
    _createVNode(_component_a_modal, {
      title: _ctx.tagForm.id?_ctx.$t('EDIT'):_ctx.$t('ADD_NEW'),
      visible: _ctx.visible,
      "onUpdate:visible": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.visible) = $event)),
      "confirm-loading": _ctx.confirmLoading,
      width: "40%",
      onOk: _ctx.saveTag
    }, {
      default: _withCtx(() => [
        _createVNode(_component_a_form, {
          model: _ctx.tagForm,
          ref: "formRef",
          rules: _ctx.rules,
          "label-col": {span: 6,offset:0},
          "wrapper-col": { span: 16 }
        }, {
          default: _withCtx(() => [
            _createVNode(_component_a_form_item, {
              label: _ctx.$t('TAG_TAG_NAME'),
              name: "name"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_a_input, {
                  value: _ctx.tagForm.name,
                  "onUpdate:value": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.tagForm.name) = $event))
                }, null, 8, ["value"])
              ]),
              _: 1
            }, 8, ["label"]),
            _createVNode(_component_a_form_item, {
              label: _ctx.$t('TAG_COLOR'),
              name: "color"
            }, {
              default: _withCtx(() => [
                _createElementVNode("div", _hoisted_4, [
                  _createVNode(_component_a_button, {
                    onClick: _ctx.togglePicker,
                    style: _normalizeStyle({'background-color':_ctx.tagForm.color?_ctx.tagForm.color:'white',color:_ctx.tagForm.color?'white':'black','margin-right':'15px'})
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(" Click ")
                    ]),
                    _: 1
                  }, 8, ["onClick", "style"]),
                  (_ctx.colorPickerVisible)
                    ? (_openBlock(), _createBlock(_component_ColorPicker, {
                        key: 0,
                        theme: "light",
                        color: _ctx.tagForm.color,
                        "sucker-hide": false,
                        "sucker-canvas": _ctx.suckerCanvas,
                        "sucker-area": _ctx.suckerArea,
                        onChangeColor: _ctx.changeColor,
                        onOpenSucker: _ctx.openSucker
                      }, null, 8, ["color", "sucker-canvas", "sucker-area", "onChangeColor", "onOpenSucker"]))
                    : _createCommentVNode("", true)
                ])
              ]),
              _: 1
            }, 8, ["label"]),
            _createVNode(_component_a_form_item, {
              name: "description",
              label: _ctx.$t('TAG_DESCRIPTION')
            }, {
              default: _withCtx(() => [
                _createVNode(_component_a_textarea, {
                  value: _ctx.tagForm.description,
                  "onUpdate:value": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.tagForm.description) = $event)),
                  placeholder: "Description",
                  rows: 4
                }, null, 8, ["value"])
              ]),
              _: 1
            }, 8, ["label"])
          ]),
          _: 1
        }, 8, ["model", "rules"])
      ]),
      _: 1
    }, 8, ["title", "visible", "confirm-loading", "onOk"]),
    _createVNode(_component_a_modal, {
      visible: _ctx.visible2,
      "onUpdate:visible": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.visible2) = $event)),
      title: "Basic Modal",
      onOk: _ctx.handleOk
    }, {
      default: _withCtx(() => [
        _createVNode(_component_a_form, null, {
          default: _withCtx(() => [
            _createVNode(_component_a_form_item, {
              label: _ctx.$t('TAG'),
              name: "tag"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_a_tree_select, {
                  onChange: _ctx.handleChangeTag,
                  "show-search": "",
                  style: {"width":"100%"},
                  onSearch: _ctx.searchTag,
                  value: _ctx.tagIds.parent_id,
                  "onUpdate:value": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.tagIds.parent_id) = $event)),
                  filterTreeNode: false,
                  searchValue: _ctx.searchTagValue,
                  "onUpdate:searchValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.searchTagValue) = $event)),
                  notFoundContent: null,
                  "dropdown-style": { maxHeight: '300', overflow: 'auto' },
                  "allow-clear": "",
                  placeholder: _ctx.$t('TAG_SELECT')
                }, {
                  default: _withCtx(() => [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.getCustomerTags, (item) => {
                      return (_openBlock(), _createBlock(_component_a_tree_select_node, {
                        key: item.id,
                        value: item.id
                      }, {
                        title: _withCtx(() => [
                          _createVNode(_component_a_tag, {
                            color: item.color
                          }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(item.name), 1)
                            ]),
                            _: 2
                          }, 1032, ["color"])
                        ]),
                        default: _withCtx(() => [
                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(item.children, (child) => {
                            return (_openBlock(), _createBlock(_component_a_tree_select_node, {
                              key: child.id,
                              value: child.id
                            }, {
                              title: _withCtx(() => [
                                _createVNode(_component_a_tag, {
                                  color: child.color
                                }, {
                                  default: _withCtx(() => [
                                    _createTextVNode(_toDisplayString(child.name), 1)
                                  ]),
                                  _: 2
                                }, 1032, ["color"])
                              ]),
                              _: 2
                            }, 1032, ["value"]))
                          }), 128))
                        ]),
                        _: 2
                      }, 1032, ["value"]))
                    }), 128))
                  ]),
                  _: 1
                }, 8, ["onChange", "onSearch", "value", "searchValue", "placeholder"])
              ]),
              _: 1
            }, 8, ["label"])
          ]),
          _: 1
        })
      ]),
      _: 1
    }, 8, ["visible", "onOk"])
  ], 64))
}