import {ActionContext, ActionTree} from "vuex";
import {State} from "./states";
import {RootState} from "@/store";
import {ShopinfoActionTypes} from "@/store/modules/Shopinfo/action-types";
import APIs from "@/APIs/APIs";
import {ShopinfoMutationTypes} from "@/store/modules/Shopinfo/mutation-types";
import {Mutations} from "@/store/modules/Shopinfo/mutations";
import {S_HttpMailPagesResponse, ShopinfoResponse, ShopinfoUpdateResponse} from "@/APIs/response/S_HttpResponse";


import {ShopInfoRequest} from "@/APIs/request/ShopInfoRequest";


type AugmentedActionContext = {
    commit<K extends keyof Mutations>(
        key: K,
        payload: Parameters<Mutations[K]>[1],
    ): ReturnType<Mutations[K]>;
} & Omit<ActionContext<State, RootState>, 'commit'>

export interface Actions {
    [ShopinfoActionTypes.FETCH_SHOPINFO](
        {commit}: AugmentedActionContext,
        payload?: ShopInfoRequest,
    ): Promise<ShopinfoResponse | undefined>;
    [ShopinfoActionTypes.FETCH_SHOPINFO_UPDATE](
        {commit}: AugmentedActionContext,
        payload: ShopInfoRequest,
    ): Promise<ShopinfoUpdateResponse | undefined>;
    [ShopinfoActionTypes.FETCH_MAIL_PAGE](
        {commit}: AugmentedActionContext,
    ): Promise<S_HttpMailPagesResponse | undefined>;
}

export const actions: ActionTree<State, RootState> & Actions = {
    async [ShopinfoActionTypes.FETCH_SHOPINFO]({commit}): Promise<ShopinfoResponse | undefined> {
        const response = await APIs.init().getShopInfo()
        if (response.status === 200) {
            commit(ShopinfoMutationTypes.GET_SHOPINFO, response.data?.data)
        }
        return response.data;
    },
    async [ShopinfoActionTypes.FETCH_SHOPINFO_UPDATE]({commit } , payload:ShopInfoRequest): Promise<ShopinfoUpdateResponse | undefined> {
        const response = await APIs.init().updateShopInfo(payload)
        if (response.status === 200) {
            commit(ShopinfoMutationTypes.PUT_SHOPINFO, response.data?.data)
        }
        return response.data;
    },
    async [ShopinfoActionTypes.FETCH_MAIL_PAGE]({commit }): Promise<S_HttpMailPagesResponse | undefined> {
        const response = await APIs.init().getMailList()
        if (response.status === 200&&response.data&&response.data.data) {
            commit(ShopinfoMutationTypes.SET_MAIL_PAGES, response.data.data)
        }
        return response.data;
    },

}
