import {Careers, Customer} from "@/models/Customers/Customers";
import { Metadata } from "@/APIs/response/S_HttpResponse";
import { CXViewGroup, CXViewLogin } from "@/models/CXView/CXViewModels";
import { Constant } from "@/utils/Constants";
import {Notification} from "@/models/notification/Notification";
import careers from "@/views/Careers/Careers.vue";
import {CustomerConfig, CustomerConfigColumn} from "@/models/CustomerConfig/CustomerConfig";

export type CustomerData = {
    customers: Customer[] | undefined;
    metadata: Metadata | undefined
    customersLoading: boolean
    version: number
}
export type State = {
    customerData: CustomerData | undefined;
    exPort: string | undefined;
    columns: any[]
    cxView: CXViewLogin | undefined
    cxViewGroup: CXViewGroup[]
    cxViewGroupCurrent: string,
    customer: Customer|undefined
    careers: Careers[]|undefined
    customerConfig: CustomerConfigColumn[] | undefined;
    customerChange:any[]|undefined

}


export const state: State = {
    customerData: { customers: [], metadata: {}, customersLoading: false, version: Constant.VERSION },
    cxView: {
        token: '',
        product: '',
        message: '',
        role: ''
    },
    cxViewGroup: [],
    cxViewGroupCurrent: '',
    exPort: "",
    columns: [

    ],
    customer:undefined,
    careers:[],
    customerConfig:[],
    customerChange:[]

}
