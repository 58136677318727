<template>
  <div class="main-container">
    <a-dropdown trigger="click" title="Choose shop you want change" :overlayClassName="'avatar-sticker'"
                v-model:visible="isDropdownChangeShopVisible">
      <template #overlay>
        <a-menu @select="changeShop">
          <a-menu-item v-for="shop in shops" :key="shop.id" @click="changeShop(shop.id,shop.shop_name)">
            <a-avatar size="small" src="https://gw.alipayobjects.com/zos/antfincdn/XAosXuNZyF/BiazfanxmamNRoxxVxka.png"
                      class="antd-pro-global-header-index-avatar"/>
            {{ shop.shop_name }}
          </a-menu-item>
        </a-menu>
      </template>
    </a-dropdown>
    <a-dropdown v-if="!getUserLoading &&currentShop">
    <span class="ant-pro-account-avatar s-avatar avatar-sticker" @click.prevent>
      <a-avatar size="small" src="https://gw.alipayobjects.com/zos/antfincdn/XAosXuNZyF/BiazfanxmamNRoxxVxka.png"
                class="antd-pro-global-header-index-avatar"/>
      <span style="margin-left: 10px; color: white">{{ currentShop.shop_name }}</span>
    </span>
      <template #overlay>
        <a-card hoverable style="width: 100%">
          <a-menu>
            <div>
              <a-row>
                <a-tooltip>
                  <template #title>{{ $t('copy') }}</template>
                  <a-menu-item style="font-weight: bold" @click="copy">
                    {{ currentShop.id }}
                  </a-menu-item>
                </a-tooltip>
                <a-menu-item style="font-weight: bold">
                  <router-link to="/shop_info">
                    {{ currentShop.shop_name }}
                  </router-link>
                </a-menu-item>
              </a-row>
            </div>
            <a-menu-divider/>
            <a-row justify="space-between" style="margin: 10px">
              <a-button>
                <template #icon>
                  <user-outlined/>
                </template>
                <router-link style="margin-left: 5px" to="/account">
                  {{ $t('ACCOUNT') }}
                </router-link>
              </a-button>
              <a-button @click="handleLogout" type="primary" shape="circle" danger>
                <template #icon>
                  <logout-outlined/>
                </template>
              </a-button>

            </a-row>
            <a-menu-divider/>

            <a-menu-item v-for="shop in shops" :key="shop.id" @click="changeShop(shop.id,shop.shop_name)">
              <a-avatar size="small"
                        src="https://gw.alipayobjects.com/zos/antfincdn/XAosXuNZyF/BiazfanxmamNRoxxVxka.png"
                        class="antd-pro-global-header-index-avatar"/>
              {{ shop.shop_name }}

              <a-menu-item @click="shopInfo" key="shopInfo">
                <exclamation-circle-outlined class="menu-icon"/>

                {{ $t('SHOP_INFO') }}
              </a-menu-item>
              <a-menu-item @click="settingPage" key="settingPage">
                <SettingOutlined class="menu-icon"/>
                {{ $t('page_settings') }}
              </a-menu-item>
              <a-menu-item key="center" @click.prevent @click="isDropdownChangeShopVisible=true">
                <swap-outlined class="menu-icon"/>
                {{ $t('CHANGE_SHOP') }}

              </a-menu-item>

              <a-menu-item key="settings">
                <a-avatar size="small" shape="square" :src="sf1Logo"
                          style="background-color: #3b5999; margin-right: 8px"/>
                <a :href="getWebUrl()">{{ $t('REDIRECT_TO_WEB_SHOPF1') }}</a>
              </a-menu-item>
              <a-menu-divider/>
              <a-menu-item @click="shopInfo" key="shopInfo">
                <info-circle-outlined class="menu-icon"/>

                {{ $t('SHOP_INFO') }}
              </a-menu-item>
              <a-menu-item @click="settingPage" key="settingPage">
                <SettingOutlined class="menu-icon"/>
                {{ $t('page_settings') }}
              </a-menu-item>
            </a-menu-item>
          </a-menu>
        </a-card>
      </template>
    </a-dropdown>
    <span v-else>
      <a-spin size="small" :style="{ marginLeft: 8, marginRight: 8 }"/>
    </span>
  </div>
</template>

<script lang="ts">
import {Modal} from 'ant-design-vue'
import {Options, Vue} from "vue-class-component";
import {
  SwapOutlined,
  LoginOutlined,
  InfoCircleOutlined,
  SettingOutlined,
  ArrowLeftOutlined,
  ExclamationCircleOutlined,
  UserOutlined,
  LogoutOutlined, MessageOutlined, ToolOutlined

} from '@ant-design/icons-vue'
import sf1Logo from "../../../assets/icons/sf1.png"
import RouterEndpoint from "@/router/RouterEndpoint";
import {mapActions, mapGetters, mapMutations, useStore} from "vuex";
import {UsersMutationTypes} from "@/store/modules/user/mutation-types";
import {CustomersActionTypes} from "@/store/modules/customer/action-types";
import APIs from "@/APIs/APIs";
import {computed, inject, onMounted} from "vue";
import useUserStore from '@/store/User';
import RightContent from "@/components/Header/RightContent.vue";
import {defineComponent, ref} from 'vue';
import {useI18n} from 'vue-i18n';
import router from "@/router";
import useOverviewStore from "../../../store/Overview";
import useEmployeeStore from "../../../store/Employee";
import {PageConfigMutationTypes} from "../../../store/modules/PageConfig/mutation-types";
import useShopInfoStore from "../../../store/ShopInfo";

export default defineComponent({
  name: "AvatarDropdown",
  components: {
    SwapOutlined,
    InfoCircleOutlined,
    SettingOutlined,
    ExclamationCircleOutlined,
    UserOutlined,
    LogoutOutlined
  },
  setup() {
    const { getShop } = useUserStore()
    const currentShop = computed(()=>useUserStore.getState().currentShop)
    const getUserLoading = computed(()=>useUserStore.getState().getUserLoading)
    const shops = computed(()=>useUserStore.getState().shops)
    const store = useStore()
    const isDropdownChangeShopVisible = ref(false)
    const i18n = useI18n()

    console.log(currentShop.value,getUserLoading.value,shops.value,'shops')
    onMounted(() => {
      getShop()
    })

    const handleLogout = () => {

      Modal.confirm({
        title: () => i18n.t("SIGN_OUT_CONFIRM"),
        content: () => i18n.t("NOTIFY_SIGN_OUT_CONFIRM"),
        onOk: async () => {
          console.log('logout')
          // this.$cookies?.set("login", '')
          localStorage.setItem("login", '')
          localStorage.clear();
          // this.setLogin(false)
          // await this.setUserInfo({token: "", shop_id: 0});
          router.replace({name: RouterEndpoint.ACCOUNTLOGIN.name})
          // return new Promise((resolve, reject) => {
          //   setTimeout(Math.random() > 0.5 ? resolve : reject, 1500)
          // }).catch(() => console.log('Oops errors!'))
        },
        onCancel() {
          console.log("cancel")
        }
      })
    }

    const changeShop = async (shopId: number, shopname: string) => {
      isDropdownChangeShopVisible.value = false;

      useUserStore.setState({
        currentShop: {id: shopId, shop_name: shopname}
      })
      const response = await APIs.init().changeShop({
        shop_id: shopId,
        token: useUserStore.getState().userInfo.token
      })
      if (response.status == 200 && response.data?.data) {
        // await this.setUserInfo({...this.getUserInfo, ...{token: response.data?.data}});
        useUserStore.setState({
          currentShop: {id: shopId, shop_name: shopname},
          userInfo: {...useUserStore.getState().userInfo, ...{token: response.data?.data}}
        })
        useShopInfoStore.getState().getShopinfo()
        const overview = await APIs.init().getOverView({})
        useEmployeeStore.getState().GetEmployee({
          page: 1,
          page_size: 999,
          search: '',
        })

        store.commit(PageConfigMutationTypes.GET_PAGE_CONFIG,'')
        if(overview.data?.status===200){
          useOverviewStore.setState({getOverViewLoading: false, overView: overview.data?.data?overview.data?.data:[] })
        }else{
          useOverviewStore.setState({getOverViewLoading: false, overView:[]})
        }

        setTimeout(() => {
          // @ts-ignore
          router.go(router.currentRoute)
        },500)


      }
      // this.setCurrentShop(shopId);

    }

    const copy = () => {
      const shopId = String(useUserStore.getState().currentShop?.id)
      navigator.clipboard.writeText(shopId)
    }

    const shopInfo = () => {
      router.push('/shop_info')
    }

    const settingPage = () => {
      router.push('/setting_page')
    }

    return {
      sf1Logo,
      getUserLoading,
      currentShop,
      shops,
      isDropdownChangeShopVisible,
      handleLogout,
      changeShop,
      useUserStore,
      copy,
      shopInfo,
      settingPage,
    }
  }
})


function useRoute() {
  throw new Error('Function not implemented.');
}

// @Options({
//   components: {
//
//   },
//   data() {
//     return {
//       sf1Logo,
//       currentShop: {},
//       isDropdownChangeShopVisible: false
//     }
//   },
//   created() {
//     const $cookies = inject('$cookies');
//   },
//   computed: {
//     ...mapGetters({
//       getCurrentShop: 'getCurrentShop',
//       getUserInfoFetching: 'getUserInfoFetching',
//       getUserInfo: 'getUserInfo',
//       getShops: 'getShops'
//     }),
//   },
//   methods: {
//     ...mapActions({
//       fetchCXView: CustomersActionTypes.FETCH_CXVIEW,
//     }),
//     ...mapMutations({
//       setLogin: UsersMutationTypes.SET_IsLogin,
//       setCurrentShop: UsersMutationTypes.SET_CurrentShop,
//       setUserInfo: UsersMutationTypes.SET_UserInfo,
//     }),
//     copy(){
//       console.log(this.getCurrentShop)
//       const shopId = String(useUserStore.getState().currentShop?.id)
//       navigator.clipboard.writeText(shopId)
//     },
//     shopInfo(){
//       this.$router.push('/shop_info')
//     },
//     settingPage(){
//       this.$router.push('/setting_page')
//     },
//     async changeShop(shopId: number,shopname: string) {
//       this.isDropdownChangeShopVisible = false;
//
//       useUserStore.setState({
//         currentShop: {id: shopId, shop_name: shopname}
//       })
//       this.currentShop = {shopid: shopId,shopname: shopname}
//       const response = await APIs.init().changeShop({
//         shop_id: shopId,
//         token: this.getUserInfo.token
//       })
//       if(response.status == 200 && response.data?.data){
//         await this.setUserInfo({...this.getUserInfo, ...{token: response.data?.data}});
//         useUserStore.setState({
//           currentShop: {  id: shopId, shop_name: shopname },
//           userInfo: {...useUserStore.getState().userInfo, ...{token: response.data?.data}}
//         })
//       }
//       this.setCurrentShop(shopId);
//       // this.fetchCXView({
//       //   email: this.getCurrentShop.cxview_email,
//       //   password: this.getCurrentShop.cxview_password
//       // })
//       this.$router.go();
//     },
//     handleShop(shops : any) {
//
//     const listshop =shops.filter((i:any) => i.id !== this.getCurrentShop.id)
//
//       return listshop
//   },
//     handleLogout() {
//
//       Modal.confirm({
//         title:()=> this.$t("SIGN_OUT_CONFIRM"),
//         content:()=> this.$t("NOTIFY_SIGN_OUT_CONFIRM"),
//         onOk: async() => {
//           console.log('logout')
//           this.$cookies?.set("login", '')
//           localStorage.setItem("login",'')
//           localStorage.clear();
//           // this.setLogin(false)
//           // await this.setUserInfo({token: "", shop_id: 0});
//           this.$router.replace({name: RouterEndpoint.ACCOUNTLOGIN.name})
//           // return new Promise((resolve, reject) => {
//           //   setTimeout(Math.random() > 0.5 ? resolve : reject, 1500)
//           // }).catch(() => console.log('Oops errors!'))
//         },
//         onCancel() {
//           console.log("cancel")
//         }
//       })
//     },
//     firstCharacterName(s: string): string {
//       if (!s) {
//         return "S"
//       }
//       return s.charAt(0).toUpperCase();
//     }
//   },
//   mounted() {
//     if(this.getCurrentShop&&this.getCurrentShop.cxview_email){
//       this.fetchCXView({
//         email: this.getCurrentShop.cxview_email,
//         password: this.getCurrentShop.cxview_password
//       })
//     }
//   },
// })
// export default class AvatarDropdown extends Vue {
// }
</script>

<style lang="scss" scoped>
.main-container {
  display: flex;
}

.s-user-info {
  padding: 14px;
}

.s-avatar {
  padding-left: 14px;
  padding-right: 14px;
  cursor: pointer;
}

.s-avatar:hover {
  background-color: rgba(0, 0, 0, .025);
}

.menu-icon {
  margin-right: 10px;
}
</style>
