import {State} from "./states";
import {CustomerCaresMutationTypes} from "./mutation-types";
import {CustomerCare} from "@/models/CustomerCares/CustomerCares";

export type Mutations<S = State> = {
    [CustomerCaresMutationTypes.SET_CustomersCares](state: S, payload: CustomerCare[] |undefined): void;
    [CustomerCaresMutationTypes.SET_CUSTOMERS_DETAIL_CARES](state: S, payload: CustomerCare[] |undefined): void;
}

export const mutations: Mutations = {
    [CustomerCaresMutationTypes.SET_CustomersCares](state: State, payload: CustomerCare[] |undefined): void {
        state.customerCares = payload
    },
    [CustomerCaresMutationTypes.SET_CUSTOMERS_DETAIL_CARES](state: State, payload: CustomerCare[] |undefined): void {
        state.customerDetailCares = payload
    }

}