import {AutoInboxData, State} from "./states";

export type Getters = {
    getAutoInboxData(state: State): AutoInboxData
    getAutoInboxHistoryData(state: State): AutoInboxData
}
export const getters: Getters = {
    getAutoInboxData(state: State): AutoInboxData {
        if (state.autoInboxData) {
            return state.autoInboxData
        } else {
            return {
                autoInboxes: [],
                metadata: {},
                loading: false
            }
        }
    },
    getAutoInboxHistoryData(state: State): AutoInboxData {
        if (state.autoInboxHistoryData) {
            return state.autoInboxHistoryData
        } else {
            return {
                autoInboxes: [],
                metadata: {},
                loading: false
            }
        }
    },
}
