<template>
  <div class="container">
    <div style="margin: 12px">
      <h3>{{ $t($route.name) }}</h3>
    </div>
    <a-layout theme="light">
      <a-layout-sider theme="light">
        <a-menu mode="inline" theme="light" v-model:selectedKeys="selectedTag">
          <a-menu-item :key="0" @click="setSelectTag(0)">
            <span>{{ $t("all") }}</span>
          </a-menu-item>
          <template v-if="getFlowTags && getFlowTags.length">
            <a-menu-item
              v-for="item in getFlowTags"
              :key="item.id"
              @click="setSelectTag(item.id)"
            >
              <a-tooltip :title="item.name">
                {{ item.name }}
              </a-tooltip>
            </a-menu-item>
          </template>
          <a-menu-item
            v-if="getMyRole(MODULES.flows)?.is_create"
            @click="newTag"
          >
            <span>
              <plus-outlined />
              {{ $t("NEW") }}
            </span>
          </a-menu-item>
        </a-menu>
      </a-layout-sider>
      <a-layout theme="light">
        <a-layout-header theme="light" class="header-trigger-filter">
          <div class="table-header">
            <div class="table-title">
              <h4 style="flex: 1">
                {{ this.getSelectedTagName }}
              </h4>
              <a-input-search
                class="input-search"
                :placeholder="$t('SEARCH')"
                v-model:value="searchInput"
                @change="onSearchChange"
                style="width: 220px; margin-left: 12px"
              />
            </div>

            <router-link :to="RouterEndpoint.FLOW_CONTEXT.path">
              <a-button
                v-if="getMyRole(MODULES.flows)?.is_create"
                type="primary"
              >
                <plus-outlined />
                {{ $t("FLOW_CREATE_NEW") }}
              </a-button>
            </router-link>
          </div>
        </a-layout-header>
        <a-layout-content>
          <a-table
            size="middle"
            :rowKey="(record) => record.id"
            :columns="columns"
            :data-source="getFlowData.flows"
            :pagination="pagination"
            @change="handleTableChange"
            class="flow-table"
          >
            <template #name="{ record }">
              <a
                class="table-row-name"
                type="text"
                @click="customRowClick(record)"
              >
                {{ record.name }}
              </a>
            </template>
            <template #created_at="{ text }">
              <span>
                {{ dateTimeDisplayFormatFromString(text) }}
              </span>
            </template>
            <template #creator="{ text }">
              <span>
                {{ getEmployeeName(text) }}
              </span>
            </template>
            <template #tags="{ record }">
              <span>
                <a-tag
                  style="cursor: pointer"
                  @click="editClick(record)"
                  v-for="tag in record.tags"
                  :key="tag.id + '_' + tag.name"
                  :color="tag.color"
                  >{{ tag.name }}</a-tag
                >
                <a-tag
                  v-if="record.tags === null"
                  style="cursor: pointer"
                  @click="editClick(record)"
                  >{{ $t("ADD") }}</a-tag
                >
              </span>
            </template>
            <template #action="{ record }">
              <a-dropdown>
                <a-button type="text">
                  <eye-outlined />
                </a-button>
                <template #overlay>
                  <a-menu>
                    <a-menu-item
                      v-if="getMyRole(MODULES.flows)?.is_edit"
                      @click="customRowClick(record)"
                    >
                      <edit-outlined />
                      {{ $t("EDIT") }}
                    </a-menu-item>
                    <a-menu-item>
                      <a-popconfirm
                        v-if="getMyRole(MODULES.flows)?.is_delete"
                        :title="$t('FLOW_CONFIRM_DELETE_CONTEXT')"
                        :ok-text="$t('YES')"
                        :cancel-text="$t('NO')"
                        @confirm="deleteFlow(record)"
                      >
                        <delete-outlined />
                        {{ $t("DELETE") }}
                      </a-popconfirm>
                    </a-menu-item>
                    <a-menu-item
                      v-if="getMyRole(MODULES.flows)?.is_edit"
                      @click="duplicated(record)"
                    >
                      <copy-outlined />
                      {{ $t("duplicate") }}
                    </a-menu-item>
                  </a-menu>
                </template>
              </a-dropdown>
            </template>
          </a-table>
        </a-layout-content>
      </a-layout>
    </a-layout>
    <a-modal
      :title="title === 'quick_create' ? $t('quick_create') : $t('ADD_NEW')"
      v-model:visible="visible"
      :confirm-loading="confirmLoading"
      width="40%"
      @ok="saveTag"
      zIndex="1000"
    >
      <a-form
        :model="tagForm"
        ref="formRef"
        :rules="rules"
        :label-col="{ span: 6, offset: 0 }"
        :wrapper-col="{ span: 16 }"
      >
        <a-form-item :label="$t('TAG_TAG_NAME')" name="name">
          <a-input v-model:value="tagForm.name" />
        </a-form-item>
        <a-form-item :label="$t('TAG_COLOR')" name="color">
          <div style="display: flex">
            <a-button
              @click="togglePicker"
              :style="{
                'background-color': tagForm.color ? tagForm.color : 'white',
                color: tagForm.color ? 'white' : 'black',
                'margin-right': '15px',
              }"
            >
              Click
            </a-button>
            <ColorPicker
              theme="light"
              :color="tagForm.color"
              :sucker-hide="false"
              v-if="colorPickerVisible"
              :sucker-canvas="suckerCanvas"
              :sucker-area="suckerArea"
              @changeColor="changeColor"
              @openSucker="openSucker"
            />
          </div>
        </a-form-item>
        <a-form-item name="description" :label="$t('TAG_DESCRIPTION')">
          <a-textarea
            v-model:value="tagForm.description"
            placeholder="Description"
            :rows="4"
          />
        </a-form-item>
      </a-form>
    </a-modal>
    <a-modal
      zIndex="999"
      v-model:visible="visible2"
      :title="$t('EDIT')"
      @ok="handleOk"
    >
      <a-form>
        <a-form-item :label="$t('FLOW_TAGS')" name="tags">
          <a-select
            style="text-align: left"
            v-model:value="formState[0]"
            placeholder="tag1, tag2, ..."
          >
            <a-select-option
              v-for="item in getFlowTags"
              :value="item.id"
              :key="item.id"
              :color="item.color"
            >
              <a-tag :color="item.color">{{ item.name }}</a-tag>
            </a-select-option>
            <a-select-option
              style="justify-content: center"
              @click="handleAddTag"
            >
              <a-typography-text style="color: #fab216; margin-left: 60px"
                >{{ $t("quick_create") }}
              </a-typography-text>
            </a-select-option>
          </a-select>
        </a-form-item>
      </a-form>
    </a-modal>
  </div>
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component";
import { ColorPicker } from "vue-color-kit";
import "./vue-color-kit.css";
import {
  DownOutlined,
  PlusOutlined,
  SmileOutlined,
  TagOutlined,
  CheckOutlined,
  EllipsisOutlined,
  CopyOutlined,
  DeleteOutlined,
  EditOutlined,
  EyeOutlined,
} from "@ant-design/icons-vue";
import RouterEndpoint from "@/router/RouterEndpoint";
import { mapActions, mapGetters } from "vuex";
import { FlowActionTypes } from "@/store/modules/flow/action-types";
import { Flow, FlowType } from "@/models/Flows/Flow";
import { Employee } from "@/models/Users";
import { TagsActionTypes } from "@/store/modules/tag/action-types";
import { Tag } from "@/models/Tags/Tags";
import { FlowsRequest } from "@/APIs/request/FlowRequest";
import APIs from "@/APIs/APIs";
import { message } from "ant-design-vue";
import { PaginationProps } from "ant-design-vue/es/pagination";
import { ValidateErrorEntity } from "ant-design-vue/es/form/interface";
import { FlowContextData } from "@/models/Flows/FlowContext";
import { Constant } from "@/utils/Constants";
import _default from "ant-design-vue/es/color-picker";
import data = _default.data;
import { MixUtils } from "@/utils/Utils";
import { CustomerCaresActionTypes } from "@/store/modules/customerCare/action-types";
import useFlowStore from "../../../store/Flow";
import useUserStore from "../../../store/User";
import useTagStore from "../../../store/Tags";
import useCustomerStore from "../../../store/Customers";

const flowsRequest: FlowsRequest = {};
const tagForm: Tag = {};
const flowContextData: FlowContextData = { context: { flow_contexts: [] } };
let inputChangeTimeout: number;

@Options({
  name: "flows",
  components: {
    TagOutlined,
    PlusOutlined,
    SmileOutlined,
    DownOutlined,
    CheckOutlined,
    EllipsisOutlined,
    EyeOutlined,
    DeleteOutlined,
    EditOutlined,
    ColorPicker,
    CopyOutlined,
  },
  computed: {
    columns() {
      return [
        {
          title: this.$t("FLOW_FLOW_NAME"),
          dataIndex: "name",
          key: "name",
          slots: { customRender: "name" },
          width: "30%",
        },
        {
          title: this.$t("TRIGGER_CREATOR"),
          dataIndex: "creator",
          key: "creator",
          slots: { customRender: "creator" },
          width: "12%",
        },
        {
          title: this.$t("TAG_EDITABLE"),
          key: "tags",
          dataIndex: "tags",
          slots: { customRender: "tags" },
          width: "12%",
        },
        {
          title: this.$t("TRIGGER_STATUS"),
          key: "status",
          dataIndex: "status",
          width: "12%",
        },
        {
          title: this.$t("DATE_CREATED"),
          dataIndex: "created_at",
          key: "created_at",
          slots: { customRender: "created_at" },
          width: "20%",
        },
        {
          title: this.$t("ACTION"),
          key: "action",
          slots: { customRender: "action" },
          width: 100,
        },
      ];
    },
    getFlowData() {
     return  this.flows.flowData
    },
    getCurrentShop() {
      return this.currentShop.currentShop
    },
    getFlowTags() {
      return this.flowTags.tagFlows
    },
    ...mapGetters({
     /* getFlowData: "getFlowData",*/
      // getCurrentShop: "getCurrentShop",
      // getFlowTags: "getFlowTags",
      getRole: "getRole",
      userInfo: "getUserInfo",
      getFlowContextData: "getFlowContextData",
    }),
    getSelectedTagName() {
      if (this.selectedTag && this.selectedTag.length > 0 && this.getFlowTags) {
        const tag = this.getFlowTags?.find(
          (x: Tag) => x.id === this.selectedTag[0]
        );
        if (tag) {
          return tag.name;
        }
      }
      return this.$t("all");
    },

    pagination() {
      return {
        current: this.$route.query.page ? parseInt(this.$route.query.page) : 1,
        pageSize: this.$route.query.page_size
          ? parseInt(this.$route.query.page_size)
          : 20,
        defaultPageSize: 20,
        total:
          this.getFlowData.metadata && this.getFlowData.metadata.record_total
            ? this.getFlowData.metadata.record_total
            : 0,
        pageSizeOptions: ["20", "40", "80", "120"],
        showSizeChanger: true,
      };
    },
  },
  data() {
    return {
      RouterEndpoint: RouterEndpoint,
      flowsRequest,
      searchInput: "",
      selectedTag: [0],
      tagForm,
      color: "#59c7f9",
      colorPickerVisible: false,
      suckerCanvas: null,
      suckerArea: [],
      visible: false,
      visible2: false,
      formState: [],
      MODULES: Constant.MODULES,
      title: "",
      flowContextData,
      tags: [],
      flows:useFlowStore(),
      currentShop: useUserStore(),
      flowTags:useTagStore()

    };
  },
  created() {
    //Keep query when refresh the page
    this.flowsRequest = {
      search: this.$route.query.search,
      tags: this.$route.query.tags,
      page: this.$route.query.page ? parseInt(this.$route.query.page) : 1,
      page_size: this.$route.query.page_size
        ? parseInt(this.$route.query.page_size)
        : 20,
      flow_type: FlowType.Flow,
    };
    this.loadAutoInbox();
    this.fetchTags({ type: "flow" });
    this.fetchTreeTags( { type: "customer", search: "" } );
    this.fetchCustomerCares();
  },
  methods: {
    ...mapActions({
      // fetchFlow: FlowActionTypes.FETCH_FLOWS,
      // fetchTags: TagsActionTypes.FETCH_TAGS,
      // fetchFlowContext: FlowActionTypes.FETCH_FLOW_CONTEXT,
      // fetchTreeTags: TagsActionTypes.FETCH_TREE_TAGS,
      // fetchCustomerCares: CustomerCaresActionTypes.FETCH_CUSTOMERS_CARES,
    }),
    loadAutoInbox() {

      this.fetchFlow(this.flowsRequest);
    },
    fetchCustomerCares(){
      useCustomerStore.getState().GetCustomerCare()
    },
    fetchTreeTags(params:any){
      useTagStore.getState().GetTreeTag(params)
    },
    fetchFlowContext(data:any) {
      useFlowStore.getState().getFlowContextData(data)
    },
    fetchTags(type:any){
      useTagStore.getState().GetTag(type)
    },
    fetchFlow(req:any) {
      useFlowStore.getState().getFlowData(req)
    },
    changeColor(color: any) {
      const { r, g, b, a } = color.rgba;
      this.tagForm.color = color.hex;
      // this.colorPickerVisible = false
    },
    togglePicker() {
      this.colorPickerVisible = !this.colorPickerVisible;
    },
    openSucker(isOpen: boolean) {
      if (isOpen) {
        // ... canvas be created
        // this.suckerCanvas = canvas
        // this.suckerArea = [x1, y1, x2, y2]
      } else {
        // this.suckerCanvas && this.suckerCanvas.remove
      }
    },
    newTag() {
      this.visible = true;
      this.title = "add";
      this.tagForm.name = "";
      this.tagForm.color = "";
      this.tagForm.description = "";
      this.tagForm.parent_id = 0;
      this.tagForm.type = "flow";
    },
    editClick(flow: any) {
      this.flowContextData = flow;
      if (flow.tags === null) {
        this.visible2 = true;
        this.formState = [];
      } else {
        this.formState = flow.tags.map((item: any) => item.id);
        this.visible2 = true;
      }
    },
    handleOk() {
      const data = {
        flow_id: this.flowContextData.id,
        tag_ids: this.formState,
      };

      APIs.init()
        .updateFlowTagsContexts(data)
        .then((res) => {
          if (res.status === 200 && res.data?.data) {
            this.fetchFlow(this.flowsRequest).then();
            message.loading({ content: "updateTags", key: data.tag_ids });
            message.success(this.$t("SUCCESS"));
            this.visible = false;
            this.visible2 = false;
          } else {
            message.error(String(res.data?.message));
          }
        })
        .catch((e) => {
          console.log(e);
        });
    },
    handleAddTag() {
      this.visible = true;
      this.title = "quick_create";
      this.formState = this.formState.filter((item: any) => item !== null);
      this.tagForm.name = "";
      this.tagForm.color = "";
      this.tagForm.description = "";
      this.tagForm.parent_id = 0;
      this.tagForm.type = "flow";
    },
    saveTag() {
      APIs.init()
        .createTag(this.tagForm)
        .then((res) => {
          this.confirmLoading = false;
          if (res.status === 200 && res.data?.status === 200) {
            this.visible = false;
            message.success({ content: "Add tags success" });
            this.fetchTags({ type: "flow" });
            message.loading({ content: "createTag" });
            if (this.title === "quick_create") {
              this.formState.push(res.data.data.id);
            }
          } else {
            message.destroy();
          }
        })
        .catch((e) => {
          this.confirmLoading = false;
          message.destroy();
          console.error(e);
        });
    },
    replaceQuery() {
      //replace url with query
      this.$router.replace({
        name: RouterEndpoint.FLOWS.name,
        query: this.flowsRequest,
      });
      this.loadAutoInbox();
    },
    customRowClick(flow: Flow) {
      this.$router.push({
        name: RouterEndpoint.FLOW_CONTEXT.name,
        params: { id: flow.id },
      });
    },
    async duplicated(flow: Flow) {
      await this.fetchFlowContext({ id: flow.id });

      const res = await APIs.init().createFlow({
        name: this.getFlowContextData.name + " " + "(copy)",
        flow_type: this.getFlowContextData.flow_type,
        description: this.getFlowContextData.description,
      });
      if (res.data?.status === 200) {
        const flowContext = {
          ...res.data.data,
          context: {
            flow_contexts: this.getFlowContextData?.context.flow_contexts.map(
              (item: any) => {
                item.id = MixUtils.methods.getUUID();
                if (item.message_contexts !== null) {
                  item.message_contexts = item.message_contexts.map(
                    (i: any) => {
                      i.id = MixUtils.methods.getUUID();
                      if (i.facebook_templates) {
                        if (i.facebook_templates.length > 0) {
                          i.facebook_templates = i.facebook_templates.map(
                            (fb_template: any) => {
                              fb_template.id = MixUtils.methods.getUUID();
                              if (fb_template.buttons) {
                                if (fb_template.buttons.length > 0) {
                                  fb_template.buttons = fb_template.buttons.map(
                                    (button: any) => {
                                      button.id = MixUtils.methods.getUUID();
                                      if (button.flow_actions_context) {
                                        if (
                                          button.flow_actions_context.length > 0
                                        ) {
                                          button.flow_actions_context =
                                            button.flow_actions_context.map(
                                              (flow_ac_context: any) => {
                                                flow_ac_context.id =
                                                  MixUtils.methods.getUUID();
                                                return flow_ac_context;
                                              }
                                            );
                                        }
                                      }
                                      if (
                                        button.flow_action_stack &&
                                        button.flow_action_stack.id
                                      ) {
                                        button.flow_action_stack.id =
                                          MixUtils.methods.getUUID();
                                      }
                                      return button;
                                    }
                                  );
                                }
                              }
                              return fb_template;
                            }
                          );
                        }
                      }
                      if (i.elements) {
                        if (i.elements.length > 0) {
                          i.elements = i.elements.map((element: any) => {
                            element.id = MixUtils.methods.getUUID();
                            if (
                              element.flow_actions_context.flow_action_stack
                            ) {
                              element.flow_actions_context.flow_action_stack.id =
                                MixUtils.methods.getUUID();
                            }
                            if (element.flow_actions_context.flow_crm_actions) {
                              if (
                                element.flow_actions_context.flow_crm_actions
                                  .length > 0
                              ) {
                                element.flow_actions_context.flow_crm_actions =
                                  element.flow_actions_context.flow_crm_actions.map(
                                    (flow_crm_action: any) => {
                                      flow_crm_action.id =
                                        MixUtils.methods.getUUID();
                                      return flow_crm_action;
                                    }
                                  );
                              }
                            }
                            return element;
                          });
                        }
                      }
                      return i;
                    }
                  );
                }
                if (item.postback_contexts) {
                  if (item.postback_contexts.length > 0) {
                    item.postback_contexts = item.postback_contexts.map(
                      (postback_context: any) => {
                        postback_context.id = MixUtils.methods.getUUID();
                        return postback_context;
                      }
                    );
                  }
                }
                if (item.flow_viral) {
                  if (item.flow_viral.length > 0) {
                    item.flow_viral = item.flow_viral.map((flow_v: any) => {
                      flow_v.id = MixUtils.methods.getUUID();
                      if (flow_v.gifts) {
                        if (flow_v.gifts.length > 0) {
                          flow_v.gifts = flow_v.gifts.map((gift: any) => {
                            if (gift.id !== "good_luck") {
                              gift.id = MixUtils.methods.getUUID();
                              return gift;
                            } else {
                              return gift;
                            }
                          });
                        }
                      }
                      return flow_v;
                    });
                  }
                }
                return item;
              }
            ),
          },
        };
        APIs.init()
          .publishFlow(flowContext)
          .then((res: any) => {
            if (res.data.status === 200) {
              this.loadAutoInbox();
            }
          });
      }
    },
    setSelectTag(id: number) {
      if (id != 0) {
        this.selectedTag = [id];
        this.flowsRequest.tags = id + "";
      } else {
        delete this.flowsRequest.tags;
      }
      this.$router.replace({
        name: RouterEndpoint.FLOWS.name,
        query: this.flowsRequest,
      });
      this.loadAutoInbox();
    },
    getEmployeeName(payload: number): string {
      if (this.getCurrentShop && this.getCurrentShop.employees) {
        const employee = this.getCurrentShop.employees.find(
          (x: Employee) => x.id === payload
        );
        return employee ? employee.name : "";
      }
      return "";
    },
    toggleTag(tag: Tag) {
      const tagFound = this.selectedTags.find((x: number) => x === tag.id);
      console.log("toggleTag", tagFound);
      if (tagFound) {
        this.selectedTags = this.selectedTags.filter(
          (x: number) => x !== tag.id
        );
        console.log("found", this.selectedTags);
      } else {
        this.selectedTags.push(tag.id);
      }
      this.replaceQuery();
    },
    onSearchChange() {
      if (inputChangeTimeout) {
        clearTimeout(inputChangeTimeout);
      }
      inputChangeTimeout = window.setTimeout(() => {
        this.flowsRequest.search = this.searchInput;
        this.replaceQuery();
      }, 500);
    },
    deleteFlow(flow: Flow) {
      message.loading({ content: "Deleting Flow: " + flow.name, key: flow.id });
      APIs.init()
        .deleteFlow({ id: flow.id })
        .then((res) => {
          if (res.status === 200 && res.data?.status === 200) {
            message.success({
              content: "Deleted Flow: " + flow.name + "!",
              key: flow.id,
              duration: 2,
            });
            this.loadAutoInbox();
          } else {
            message.destroy();
          }
        })
        .catch((e) => {
          message.destroy();
          console.error(e);
        });
    },
    handleTableChange(pagination: PaginationProps) {
      this.flowsRequest.page = pagination?.current;
      this.flowsRequest.page_size = pagination?.pageSize;
      this.replaceQuery();
    },
    handleChangeTags(tags: number[]) {
      console.log("tags", tags);
      this.selectedTags = tags;
      this.replaceQuery();
    },
    getMyRole(key: string) {
      if (this.userInfo.id_cms_privileges < 11) {
        return {
          is_visible: 1,
          is_create: 1,
          is_read: 1,
          is_edit: 1,
          is_delete: 1,
          id: 1,
        };
      } else {
        return this.getRole(key);
      }
    },
  },
})
export default class Flows extends Vue {}
</script>

<style lang="scss" scoped>
.container {
  margin: 10px 15px;
}

.left-layout {
  padding-right: 14px;

  .tag-group {
    padding: 10px 4px;
  }

  .button-align-left {
    text-align: left;
  }
}

.row-horizontal {
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  .input-search {
    min-width: 250px;
    margin-right: 16px;
    height: 32px;
  }
}

.table-row-name {
  font-weight: bold;
}

.flow-tag {
  opacity: 0.7;
  cursor: pointer;
  margin-top: 8px;
}

.flow-tag-active {
  opacity: 1;
  cursor: pointer;
  margin-top: 8px;
}

.row-horizontal {
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.flow-table {
  padding-left: 12px;
}
.title-header {
  font-weight: bold;
  flex: 1;
}
.table-header {
  display: flex;
  align-items: center;
}
.table-title {
  flex: 1;
  display: flex;
  align-items: center;
  padding-right: 14px;
}
.header-trigger-filter {
  background-color: white;
}
.container-title {
  display: flex;
  flex-direction: row;
  padding: 16px 24px 16px 24px;
  margin-bottom: 25px;
  background-color: white;
}
.input-search {
  width: 250px;
  margin-right: 16px;
}
</style>
