import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode, createVNode as _createVNode, createCommentVNode as _createCommentVNode, normalizeStyle as _normalizeStyle, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-2c5d7d65"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "container" }
const _hoisted_2 = { style: {"margin":"12px"} }
const _hoisted_3 = { class: "table-header" }
const _hoisted_4 = { class: "table-title" }
const _hoisted_5 = { style: {"flex":"1"} }
const _hoisted_6 = ["onClick"]
const _hoisted_7 = { style: {"display":"flex"} }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_menu_item = _resolveComponent("a-menu-item")!
  const _component_a_tooltip = _resolveComponent("a-tooltip")!
  const _component_plus_outlined = _resolveComponent("plus-outlined")!
  const _component_a_menu = _resolveComponent("a-menu")!
  const _component_a_layout_sider = _resolveComponent("a-layout-sider")!
  const _component_a_input_search = _resolveComponent("a-input-search")!
  const _component_a_button = _resolveComponent("a-button")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_a_layout_header = _resolveComponent("a-layout-header")!
  const _component_a_tag = _resolveComponent("a-tag")!
  const _component_eye_outlined = _resolveComponent("eye-outlined")!
  const _component_edit_outlined = _resolveComponent("edit-outlined")!
  const _component_delete_outlined = _resolveComponent("delete-outlined")!
  const _component_a_popconfirm = _resolveComponent("a-popconfirm")!
  const _component_copy_outlined = _resolveComponent("copy-outlined")!
  const _component_a_dropdown = _resolveComponent("a-dropdown")!
  const _component_a_table = _resolveComponent("a-table")!
  const _component_a_layout_content = _resolveComponent("a-layout-content")!
  const _component_a_layout = _resolveComponent("a-layout")!
  const _component_a_input = _resolveComponent("a-input")!
  const _component_a_form_item = _resolveComponent("a-form-item")!
  const _component_ColorPicker = _resolveComponent("ColorPicker")!
  const _component_a_textarea = _resolveComponent("a-textarea")!
  const _component_a_form = _resolveComponent("a-form")!
  const _component_a_modal = _resolveComponent("a-modal")!
  const _component_a_select_option = _resolveComponent("a-select-option")!
  const _component_a_typography_text = _resolveComponent("a-typography-text")!
  const _component_a_select = _resolveComponent("a-select")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("h3", null, _toDisplayString(_ctx.$t(_ctx.$route.name)), 1)
    ]),
    _createVNode(_component_a_layout, { theme: "light" }, {
      default: _withCtx(() => [
        _createVNode(_component_a_layout_sider, { theme: "light" }, {
          default: _withCtx(() => [
            _createVNode(_component_a_menu, {
              mode: "inline",
              theme: "light",
              selectedKeys: _ctx.selectedTag,
              "onUpdate:selectedKeys": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.selectedTag) = $event))
            }, {
              default: _withCtx(() => [
                (_openBlock(), _createBlock(_component_a_menu_item, {
                  key: 0,
                  onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.setSelectTag(0)))
                }, {
                  default: _withCtx(() => [
                    _createElementVNode("span", null, _toDisplayString(_ctx.$t("all")), 1)
                  ]),
                  _: 1
                })),
                (_ctx.getFlowTags && _ctx.getFlowTags.length)
                  ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(_ctx.getFlowTags, (item) => {
                      return (_openBlock(), _createBlock(_component_a_menu_item, {
                        key: item.id,
                        onClick: ($event: any) => (_ctx.setSelectTag(item.id))
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_a_tooltip, {
                            title: item.name
                          }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(item.name), 1)
                            ]),
                            _: 2
                          }, 1032, ["title"])
                        ]),
                        _: 2
                      }, 1032, ["onClick"]))
                    }), 128))
                  : _createCommentVNode("", true),
                (_ctx.getMyRole(_ctx.MODULES.flows)?.is_create)
                  ? (_openBlock(), _createBlock(_component_a_menu_item, {
                      key: 1,
                      onClick: _ctx.newTag
                    }, {
                      default: _withCtx(() => [
                        _createElementVNode("span", null, [
                          _createVNode(_component_plus_outlined),
                          _createTextVNode(" " + _toDisplayString(_ctx.$t("NEW")), 1)
                        ])
                      ]),
                      _: 1
                    }, 8, ["onClick"]))
                  : _createCommentVNode("", true)
              ]),
              _: 1
            }, 8, ["selectedKeys"])
          ]),
          _: 1
        }),
        _createVNode(_component_a_layout, { theme: "light" }, {
          default: _withCtx(() => [
            _createVNode(_component_a_layout_header, {
              theme: "light",
              class: "header-trigger-filter"
            }, {
              default: _withCtx(() => [
                _createElementVNode("div", _hoisted_3, [
                  _createElementVNode("div", _hoisted_4, [
                    _createElementVNode("h4", _hoisted_5, _toDisplayString(this.getSelectedTagName), 1),
                    _createVNode(_component_a_input_search, {
                      class: "input-search",
                      placeholder: _ctx.$t('SEARCH'),
                      value: _ctx.searchInput,
                      "onUpdate:value": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.searchInput) = $event)),
                      onChange: _ctx.onSearchChange,
                      style: {"width":"220px","margin-left":"12px"}
                    }, null, 8, ["placeholder", "value", "onChange"])
                  ]),
                  _createVNode(_component_router_link, {
                    to: _ctx.RouterEndpoint.FLOW_CONTEXT.path
                  }, {
                    default: _withCtx(() => [
                      (_ctx.getMyRole(_ctx.MODULES.flows)?.is_create)
                        ? (_openBlock(), _createBlock(_component_a_button, {
                            key: 0,
                            type: "primary"
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_plus_outlined),
                              _createTextVNode(" " + _toDisplayString(_ctx.$t("FLOW_CREATE_NEW")), 1)
                            ]),
                            _: 1
                          }))
                        : _createCommentVNode("", true)
                    ]),
                    _: 1
                  }, 8, ["to"])
                ])
              ]),
              _: 1
            }),
            _createVNode(_component_a_layout_content, null, {
              default: _withCtx(() => [
                _createVNode(_component_a_table, {
                  size: "middle",
                  rowKey: (record) => record.id,
                  columns: _ctx.columns,
                  "data-source": _ctx.getFlowData.flows,
                  pagination: _ctx.pagination,
                  onChange: _ctx.handleTableChange,
                  class: "flow-table"
                }, {
                  name: _withCtx(({ record }) => [
                    _createElementVNode("a", {
                      class: "table-row-name",
                      type: "text",
                      onClick: ($event: any) => (_ctx.customRowClick(record))
                    }, _toDisplayString(record.name), 9, _hoisted_6)
                  ]),
                  created_at: _withCtx(({ text }) => [
                    _createElementVNode("span", null, _toDisplayString(_ctx.dateTimeDisplayFormatFromString(text)), 1)
                  ]),
                  creator: _withCtx(({ text }) => [
                    _createElementVNode("span", null, _toDisplayString(_ctx.getEmployeeName(text)), 1)
                  ]),
                  tags: _withCtx(({ record }) => [
                    _createElementVNode("span", null, [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(record.tags, (tag) => {
                        return (_openBlock(), _createBlock(_component_a_tag, {
                          style: {"cursor":"pointer"},
                          onClick: ($event: any) => (_ctx.editClick(record)),
                          key: tag.id + '_' + tag.name,
                          color: tag.color
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(tag.name), 1)
                          ]),
                          _: 2
                        }, 1032, ["onClick", "color"]))
                      }), 128)),
                      (record.tags === null)
                        ? (_openBlock(), _createBlock(_component_a_tag, {
                            key: 0,
                            style: {"cursor":"pointer"},
                            onClick: ($event: any) => (_ctx.editClick(record))
                          }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(_ctx.$t("ADD")), 1)
                            ]),
                            _: 2
                          }, 1032, ["onClick"]))
                        : _createCommentVNode("", true)
                    ])
                  ]),
                  action: _withCtx(({ record }) => [
                    _createVNode(_component_a_dropdown, null, {
                      overlay: _withCtx(() => [
                        _createVNode(_component_a_menu, null, {
                          default: _withCtx(() => [
                            (_ctx.getMyRole(_ctx.MODULES.flows)?.is_edit)
                              ? (_openBlock(), _createBlock(_component_a_menu_item, {
                                  key: 0,
                                  onClick: ($event: any) => (_ctx.customRowClick(record))
                                }, {
                                  default: _withCtx(() => [
                                    _createVNode(_component_edit_outlined),
                                    _createTextVNode(" " + _toDisplayString(_ctx.$t("EDIT")), 1)
                                  ]),
                                  _: 2
                                }, 1032, ["onClick"]))
                              : _createCommentVNode("", true),
                            _createVNode(_component_a_menu_item, null, {
                              default: _withCtx(() => [
                                (_ctx.getMyRole(_ctx.MODULES.flows)?.is_delete)
                                  ? (_openBlock(), _createBlock(_component_a_popconfirm, {
                                      key: 0,
                                      title: _ctx.$t('FLOW_CONFIRM_DELETE_CONTEXT'),
                                      "ok-text": _ctx.$t('YES'),
                                      "cancel-text": _ctx.$t('NO'),
                                      onConfirm: ($event: any) => (_ctx.deleteFlow(record))
                                    }, {
                                      default: _withCtx(() => [
                                        _createVNode(_component_delete_outlined),
                                        _createTextVNode(" " + _toDisplayString(_ctx.$t("DELETE")), 1)
                                      ]),
                                      _: 2
                                    }, 1032, ["title", "ok-text", "cancel-text", "onConfirm"]))
                                  : _createCommentVNode("", true)
                              ]),
                              _: 2
                            }, 1024),
                            (_ctx.getMyRole(_ctx.MODULES.flows)?.is_edit)
                              ? (_openBlock(), _createBlock(_component_a_menu_item, {
                                  key: 1,
                                  onClick: ($event: any) => (_ctx.duplicated(record))
                                }, {
                                  default: _withCtx(() => [
                                    _createVNode(_component_copy_outlined),
                                    _createTextVNode(" " + _toDisplayString(_ctx.$t("duplicate")), 1)
                                  ]),
                                  _: 2
                                }, 1032, ["onClick"]))
                              : _createCommentVNode("", true)
                          ]),
                          _: 2
                        }, 1024)
                      ]),
                      default: _withCtx(() => [
                        _createVNode(_component_a_button, { type: "text" }, {
                          default: _withCtx(() => [
                            _createVNode(_component_eye_outlined)
                          ]),
                          _: 1
                        })
                      ]),
                      _: 2
                    }, 1024)
                  ]),
                  _: 1
                }, 8, ["rowKey", "columns", "data-source", "pagination", "onChange"])
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ]),
      _: 1
    }),
    _createVNode(_component_a_modal, {
      title: _ctx.title === 'quick_create' ? _ctx.$t('quick_create') : _ctx.$t('ADD_NEW'),
      visible: _ctx.visible,
      "onUpdate:visible": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.visible) = $event)),
      "confirm-loading": _ctx.confirmLoading,
      width: "40%",
      onOk: _ctx.saveTag,
      zIndex: "1000"
    }, {
      default: _withCtx(() => [
        _createVNode(_component_a_form, {
          model: _ctx.tagForm,
          ref: "formRef",
          rules: _ctx.rules,
          "label-col": { span: 6, offset: 0 },
          "wrapper-col": { span: 16 }
        }, {
          default: _withCtx(() => [
            _createVNode(_component_a_form_item, {
              label: _ctx.$t('TAG_TAG_NAME'),
              name: "name"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_a_input, {
                  value: _ctx.tagForm.name,
                  "onUpdate:value": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.tagForm.name) = $event))
                }, null, 8, ["value"])
              ]),
              _: 1
            }, 8, ["label"]),
            _createVNode(_component_a_form_item, {
              label: _ctx.$t('TAG_COLOR'),
              name: "color"
            }, {
              default: _withCtx(() => [
                _createElementVNode("div", _hoisted_7, [
                  _createVNode(_component_a_button, {
                    onClick: _ctx.togglePicker,
                    style: _normalizeStyle({
                'background-color': _ctx.tagForm.color ? _ctx.tagForm.color : 'white',
                color: _ctx.tagForm.color ? 'white' : 'black',
                'margin-right': '15px',
              })
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(" Click ")
                    ]),
                    _: 1
                  }, 8, ["onClick", "style"]),
                  (_ctx.colorPickerVisible)
                    ? (_openBlock(), _createBlock(_component_ColorPicker, {
                        key: 0,
                        theme: "light",
                        color: _ctx.tagForm.color,
                        "sucker-hide": false,
                        "sucker-canvas": _ctx.suckerCanvas,
                        "sucker-area": _ctx.suckerArea,
                        onChangeColor: _ctx.changeColor,
                        onOpenSucker: _ctx.openSucker
                      }, null, 8, ["color", "sucker-canvas", "sucker-area", "onChangeColor", "onOpenSucker"]))
                    : _createCommentVNode("", true)
                ])
              ]),
              _: 1
            }, 8, ["label"]),
            _createVNode(_component_a_form_item, {
              name: "description",
              label: _ctx.$t('TAG_DESCRIPTION')
            }, {
              default: _withCtx(() => [
                _createVNode(_component_a_textarea, {
                  value: _ctx.tagForm.description,
                  "onUpdate:value": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.tagForm.description) = $event)),
                  placeholder: "Description",
                  rows: 4
                }, null, 8, ["value"])
              ]),
              _: 1
            }, 8, ["label"])
          ]),
          _: 1
        }, 8, ["model", "rules"])
      ]),
      _: 1
    }, 8, ["title", "visible", "confirm-loading", "onOk"]),
    _createVNode(_component_a_modal, {
      zIndex: "999",
      visible: _ctx.visible2,
      "onUpdate:visible": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.visible2) = $event)),
      title: _ctx.$t('EDIT'),
      onOk: _ctx.handleOk
    }, {
      default: _withCtx(() => [
        _createVNode(_component_a_form, null, {
          default: _withCtx(() => [
            _createVNode(_component_a_form_item, {
              label: _ctx.$t('FLOW_TAGS'),
              name: "tags"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_a_select, {
                  style: {"text-align":"left"},
                  value: _ctx.formState[0],
                  "onUpdate:value": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.formState[0]) = $event)),
                  placeholder: "tag1, tag2, ..."
                }, {
                  default: _withCtx(() => [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.getFlowTags, (item) => {
                      return (_openBlock(), _createBlock(_component_a_select_option, {
                        value: item.id,
                        key: item.id,
                        color: item.color
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_a_tag, {
                            color: item.color
                          }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(item.name), 1)
                            ]),
                            _: 2
                          }, 1032, ["color"])
                        ]),
                        _: 2
                      }, 1032, ["value", "color"]))
                    }), 128)),
                    _createVNode(_component_a_select_option, {
                      style: {"justify-content":"center"},
                      onClick: _ctx.handleAddTag
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_a_typography_text, { style: {"color":"#fab216","margin-left":"60px"} }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_ctx.$t("quick_create")), 1)
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    }, 8, ["onClick"])
                  ]),
                  _: 1
                }, 8, ["value"])
              ]),
              _: 1
            }, 8, ["label"])
          ]),
          _: 1
        })
      ]),
      _: 1
    }, 8, ["visible", "title", "onOk"])
  ]))
}