import create from "zustand-vue";
import {persist, createJSONStorage} from 'zustand/middleware'
import {Employee, Shop, SuperUserInfo} from "@/models/Users/user";
import APIs from "@/APIs/APIs";
import useTagStore from "@/store/Tags";
import {State} from "@/store/modules/user";


interface AuthState {

    getUserLoading: boolean
    getShopLoading: boolean
    userInfo: SuperUserInfo;
    metadata: any

    getUser: () => void;
    getShop: () => void;

    // eslint-disable-next-line @typescript-eslint/ban-types
    LoginEmail: (params: any, onSuccess: Function) => void;
    getAdminAndShopOwner:() => Employee[];
    currentShop: Shop
    shops: Array<Shop>
}

const initialState = {
    getUserLoading: true,
    getShopLoading: false,
    userInfo: {},
    currentShop:{},
    metadata: {},
    shops: []
}


// @ts-ignore

// @ts-ignore
const useUserStore = create<AuthState>(persist((set) => ({
            ...initialState,
            getUser: async () =>{
                set(()=>({getUserLoading: true}))
                const response = await APIs.init().getSuperUserInfo()
                if(response.data?.status===200){
                    set(()=>({getUserLoading: false, userInfo: response.data?.data?response.data?.data:{} }))
                }else{
                    set(()=>({getUserLoading: false, userInfo:{}}))
                }
            },
            getShop: async () =>{
                set(()=>({getShopLoading: true}))
                const response = await APIs.init().getShops()
                if(response.data?.status===200){
                    let currentShop = useUserStore.getState().currentShop

                    if(currentShop.id && response.data?.data){
                        const shopFound = response.data?.data?response.data?.data.find(shop=>shop.id===currentShop.id): {}
                        if(shopFound){
                            currentShop = shopFound
                        }
                    }else{
                        currentShop = response.data?.data?response.data?.data[0]: {}
                    }

                    useUserStore.setState({getShopLoading: false, shops: response.data?.data?response.data?.data:[], currentShop })
                    useUserStore.setState({currentShop })
                    useTagStore.getState().getConverstionTag()
                }else{
                    set(()=>({getShopLoading: false, shops:[]}))
                }

            },
            getAdminAndShopOwner():Employee[]{
            if (this.userInfo.current_shop_id) {
                const shop =this.shops?.find(x => x.id ===this.currentShop.id)
                if (shop?.employees) {
                    let employees:Employee[]=[]
                    for (let i=0;i<shop.employees.length;i++){
                        if (shop.employees[i].is_owner||shop.employees[i])
                            employees=[...employees,shop.employees[i]]
                    }
                    return employees
                }
            }
            return []
        },

            LoginEmail: async (params, onSuccess) => {
                set(() => ({getUserLoading: true}))
                const response = await APIs.init().loginEmail(params)


            if (response.data?.status === 200) {
                set(() => ({getUserLoading: false, userInfo: {token: response.data?.data, shop_id: 0}}))
                const user = await APIs.init().getSuperUserInfo()
                if (user.data?.status === 200) {
                    set(() => ({
                        getUserLoading: false,
                        userInfo: user.data?.data ? {...user.data?.data, token: response.data?.data} : {}
                    }))
                    onSuccess()
                } else {
                    set(() => ({getUserLoading: false, userInfo: {}}))
                }
            } else {
                set(() => ({getUserLoading: false, userInfo: {}}))
            }
            console.log('LoginEmail', response)
        },
    })
    ,
    {
        name: 'user'
    }
    )
)

export default useUserStore
