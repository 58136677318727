<template>
  <div class="container-title">
    <h3 class="title-header">{{ $t($route.name) }}</h3>
    <a-select
      style="margin-right: 16px"
      v-model:value="getCurrentPageId"
      @change="changePage"
    >
      <a-select-option
        v-for="item in getPages"
        :key="item.page_id"
        :value="item.page_id"
        >{{ item.page_name }}
      </a-select-option>
    </a-select>
    <a-button @click="createAutoInbox" type="primary">
      <plus-outlined />
      {{ $t("NEW_AUTO_INBOX") }}
    </a-button>
  </div>
  <a-table
    size="middle"
    :scroll="{ y: '40vh' }"
    :rowKey="(record) => record.id"
    :columns="columns"
    :data-source="getAutoInboxData.autoInboxes"
    :pagination="pagination"
    @change="handleTableChange"
    :loading="getAutoInboxData.loading"
    class="flow-table"
  >
    <template #title>
      <h3 class="title-new-auto-inbox">
        {{ $t("CAMPAIGN_LIST") }}
      </h3>
    </template>
    <template #inbox_schedule_desc="{ record }">
      {{ record.inbox_schedule_desc }}
    </template>
    <template #inbox_name="{ record }">
      <span>
        <a
          class="table-row-name"
          type="text"
          @click="customRowClick(record, false)"
        >
          {{ record.inbox_name }}
        </a>
      </span>
    </template>
    <template #customer_filter_count="{ text }">
      <a-tag color="cyan">{{ text }}</a-tag>
    </template>
    <template #limit="{ text }">
      <a-tag color="#f50">{{ text == 99999 ? $t("UNLIMITED") : text }}</a-tag>
    </template>
    <template #status="{ text }">
      <a-tag :color="getViralStatusColor(text)">{{
        getViralStatusName(text)
      }}</a-tag>
    </template>
    <template #created_at="{ text }">
      <span>
        {{ dateTimeDisplayFormatFromString(text) }}
      </span>
    </template>
    <template #action="{ record }">
      <a-dropdown>
        <a-button type="text">
          <eye-outlined />
        </a-button>
        <template #overlay>
          <a-menu>
            <a-menu-item @click="changeStatus(record)">
              <PauseCircleOutlined v-if="record.status === 2" />
              <PlayCircleOutlined v-if="record.status !== 2" />
              {{ record.status === 2 ? "Stop" : "Start" }}
            </a-menu-item>
            <a-menu-item @click="edit(record)">
              <edit-outlined />
              {{ $t("EDIT") }}
            </a-menu-item>
            <a-menu-item @click="delete record.id">
              <delete-outlined />
              {{ $t("DELETE") }}
            </a-menu-item>
          </a-menu>
        </template>
      </a-dropdown>
    </template>
  </a-table>
  <a-table
    size="middle"
    :scroll="{ y: '40vh' }"
    :rowKey="(record) => record.id"
    :columns="historyColumns"
    :data-source="getAutoInboxHistoryData.autoInboxes"
    :pagination="paginationHistory"
    @change="handleTableChangeHistory"
    :loading="getAutoInboxHistoryData.loading"
    class="flow-table"
  >
    <template #title>
      <h3 class="title-new-auto-inbox">
        {{ $t("HISTORY") }}
      </h3>
    </template>
    <template #inbox_name_his="{ record }">
      <span>
        <a-button
          class="table-row-name"
          type="text"
          @click="customRowClick(record, true)"
        >
          {{ record.inbox_name }}
        </a-button>
      </span>
    </template>
    <template #created_at="{ text }">
      <span>
        {{ dateTimeDisplayFormatFromString(text) }}
      </span>
    </template>
    <template #total_send="{ text }">
      <a-tag color="cyan">{{ text }}</a-tag>
    </template>
    <template #total_succeed="{ text }">
      <a-tag color="#87d068">{{ text }}</a-tag>
    </template>
    <template #total_seen="{ text }">
      <a-tag color="#2db7f5">{{ text }}</a-tag>
    </template>
    <template #total_clicked="{ text }">
      <a-tag color="#108ee9">{{ text }}</a-tag>
    </template>
    <template #limit="{ text }">
      <a-tag color="orange">{{ text == 99999 ? $t("UNLIMITED") : text }}</a-tag>
    </template>
    <template #status="{ text }">
      <a-tag :color="getViralStatusColor(text)">{{
        getViralStatusName(text)
      }}</a-tag>
    </template>
    <!--      <template #action="{record}">-->
    <!--        <a-dropdown>-->
    <!--          <a-button type="text">-->
    <!--            <ellipsis-outlined/>-->
    <!--          </a-button>-->
    <!--          <template #overlay>-->
    <!--            <a-menu>-->
    <!--              <a-menu-item @click="edit(record)">-->
    <!--                <edit-outlined/>-->
    <!--                {{ $t('EDIT') }}-->

    <!--              </a-menu-item>-->
    <!--              <a-menu-item>-->
    <!--                <line-chart-outlined/>-->
    <!--                Result-->
    <!--              </a-menu-item>-->
    <!--            </a-menu>-->
    <!--          </template>-->
    <!--        </a-dropdown>-->
    <!--      </template>-->
  </a-table>
  <AutoInboxModal
    ref="autoInboxModal"
    :currentPage="currentPage"
    :onAutoInboxUpdated="onAutoInboxUpdated"
  ></AutoInboxModal>
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component";
import {
  CheckOutlined,
  DeleteOutlined,
  DownOutlined,
  EditOutlined,
  EllipsisOutlined,
  EyeOutlined,
  LineChartOutlined,
  PlusOutlined,
  SmileOutlined,
  TagOutlined,
  PauseCircleOutlined,
  PlayCircleOutlined,
} from "@ant-design/icons-vue";
import RouterEndpoint from "@/router/RouterEndpoint";
import { mapActions, mapGetters } from "vuex";
import { FlowActionTypes } from "@/store/modules/viral/action-types";
import { AutoInboxActionTypes } from "@/store/modules/autoInbox/action-types";
import { Flow } from "@/models/Flows/Flow";
import { Employee } from "@/models/Users";
import { TagsActionTypes } from "@/store/modules/tag/action-types";
import { Tag } from "@/models/Tags/Tags";
import { AutoInboxesRequest } from "@/APIs/request/AutoInboxRequest";
import APIs from "@/APIs/APIs";
import { message, notification } from "ant-design-vue";
import AutoInboxModal from "./Edit.vue";
import { PaginationProps } from "ant-design-vue/es/pagination";
import useAutoinboxStore from "@/store/Autoinbox";

useAutoinboxStore.getState().getAutoInboxData({});
useAutoinboxStore.getState().getAutoInboxHistoryData({});

const autoInboxRequest: AutoInboxesRequest = {};
const autoInboxHistoryRequest: AutoInboxesRequest = {};

@Options({
  name: "AutoInbox",
  components: {
    TagOutlined,
    PlusOutlined,
    SmileOutlined,
    DownOutlined,
    CheckOutlined,
    EllipsisOutlined,
    DeleteOutlined,
    EditOutlined,
    AutoInboxModal,
    EyeOutlined,
    LineChartOutlined,
    PauseCircleOutlined,
    PlayCircleOutlined,
  },
  computed: {
    columns() {
      return [
        {
          title: this.$t("SCHEDULE"),
          dataIndex: "inbox_schedule_desc",
          key: "inbox_schedule_desc",
          slots: { customRender: "inbox_schedule_desc" },
        },
        {
          title: this.$t("NAME"),
          dataIndex: "inbox_name",
          key: "inbox_name",
          slots: { customRender: "inbox_name" },
        },
        {
          title: this.$t("CUSTOMER"),
          key: "customer_filter_desc",
          dataIndex: "customer_filter_desc",
          slots: { customRender: "customer_filter_desc" },
        },
        {
          title: this.$t("EXPECTED_SENDING_NUMBER"),
          key: "customer_filter_count",
          dataIndex: "customer_filter_count",
          slots: { customRender: "customer_filter_count" },
        },
        {
          title: this.$t("LIMITED"),
          dataIndex: "limit",
          key: "limit",
          slots: { customRender: "limit" },
        },
        {
          title: this.$t("STATUS"),
          dataIndex: "status",
          key: "status",
          slots: { customRender: "status" },
        },
        {
          title: this.$t("CREATED_TITLE"),
          dataIndex: "created_at",
          key: "created_at",
          slots: { customRender: "created_at" },
        },
        {
          title: "",
          key: "action",
          slots: { customRender: "action" },
          width: 100,
        },
      ];
    },
    historyColumns() {
      return [
        {
          title: "Id",
          dataIndex: "id",
          key: "id",
          slots: { customRender: "id" },
        },
        {
          title: this.$t("NAME"),
          dataIndex: "inbox_name",
          key: "inbox_name",
          slots: { customRender: "inbox_name" },
        },
        {
          title: this.$t("CUSTOMER"),
          key: "customer_filter_desc",
          dataIndex: "customer_filter_desc",
          slots: { customRender: "customer_filter_desc" },
        },
        {
          title: this.$t("STATUS"),
          key: "status",
          dataIndex: "status",
          slots: { customRender: "status" },
        },
        {
          title: this.$t("SENT"),
          key: "total_send",
          dataIndex: "total_send",
          slots: { customRender: "total_send" },
        },
        {
          title: this.$t("SUCCESS"),
          key: "total_succeed",
          dataIndex: "total_succeed",
          slots: { customRender: "total_succeed" },
        },
        {
          title: this.$t("SEEN"),
          key: "total_seen",
          dataIndex: "total_seen",
          slots: { customRender: "total_seen" },
        },
        {
          title: this.$t("CLICKED"),
          key: "total_clicked",
          dataIndex: "total_clicked",
          slots: { customRender: "total_clicked" },
        },
        {
          title: this.$t("LIMITED"),
          dataIndex: "limit",
          key: "limit",
          slots: { customRender: "limit" },
        },
        {
          title: this.$t("CREATED_TITLE"),
          dataIndex: "created_at",
          key: "created_at",
          slots: { customRender: "created_at" },
        },
        {
          title: "",
          key: "action",
          slots: { customRender: "action" },
          width: 100,
        },
      ];
    },
    ...mapGetters({
      getFlowData: "getViralData",
      getCurrentShop: "getCurrentShop",
      getFlowTags: "getFlowTags",
      getAutoInboxData: "getAutoInboxData",
      getAutoInboxHistoryData: "getAutoInboxHistoryData",
      getPages: "getPages",
    }),
    pagination() {
      return {
        current: this.getAutoInboxData.metadata?.page,
        pageSize: this.getAutoInboxData.metadata?.page_size,
        defaultPageSize: 10,
        total:
          this.getAutoInboxData.metadata &&
          this.getAutoInboxData.metadata.record_total
            ? this.getAutoInboxData.metadata.record_total
            : 0,
        pageSizeOptions: ["10", "20", "40", "80", "120"],
        showSizeChanger: true,
      };
    },
    paginationHistory() {
      return {
        current: this.getAutoInboxHistoryData.metadata?.page,
        pageSize: this.getAutoInboxHistoryData.metadata?.page_size,
        defaultPageSize: 10,
        total:
          this.getAutoInboxHistoryData.metadata &&
          this.getAutoInboxHistoryData.metadata.record_total
            ? this.getAutoInboxHistoryData.metadata.record_total
            : 0,
        pageSizeOptions: ["10", "20", "40", "80", "120"],
        showSizeChanger: true,
      };
    },
    getCurrentPageId() {
      if (!this.currentPage) {
        return this.getPages.length > 0 ? this.getPages[0].page_id : "";
      } else {
        return this.currentPage;
      }
    },
  },

  data() {
    return {
      RouterEndpoint: RouterEndpoint,
      selectedTags: [],
      autoInboxRequest,
      autoInboxHistoryRequest,
      searchInput: "",
      currentPage: undefined,
      popupConfirmVisible: false,
    };
  },
  created() {
    //Keep query when refresh the page
    this.autoInboxRequest = {
      page: this.getAutoInboxData.metadata?.page
        ? this.getAutoInboxData.metadata?.page
        : 1,
      page_size: this.getAutoInboxData.metadata?.page_size
        ? this.getAutoInboxData.metadata?.page_size
        : 10,
    };
    this.autoInboxHistoryRequest = {
      page: this.getAutoInboxHistoryData.metadata?.page
        ? this.getAutoInboxHistoryData.metadata?.page
        : 1,
      page_size: this.getAutoInboxHistoryData.metadata?.page_size
        ? this.getAutoInboxHistoryData.metadata?.page_size
        : 10,
    };
    this.loadAutoInbox();
    this.loadAutoInboxHistory();
  },
  methods: {
    ...mapActions({
      fetchFlow: FlowActionTypes.FETCH_VIRAL,
      fetchTags: TagsActionTypes.FETCH_TAGS,
      fetchAutoInbox: AutoInboxActionTypes.FETCH_AUTO_INBOX,
      fetchAutoInboxHistory: AutoInboxActionTypes.FETCH_AUTO_INBOX_HISTORY,
    }),
    loadAutoInbox() {
      this.autoInboxRequest.page_id = this.currentPage
        ? this.currentPage
        : this.getCurrentPageId;
      this.fetchAutoInbox(this.autoInboxRequest).then();
    },
    loadAutoInboxHistory() {
      this.autoInboxHistoryRequest.page_id = this.currentPage
        ? this.currentPage
        : this.getCurrentPageId;
      this.fetchAutoInboxHistory(this.autoInboxHistoryRequest).then();
    },
    customRowClick(autoInbox: AutoInbox, modeView: boolean) {
      this.$refs.autoInboxModal.showModal(
        autoInbox,
        this.getCurrentPageId,
        modeView
      );
    },
    getEmployeeName(payload: number): string {
      if (this.getCurrentShop && this.getCurrentShop.employees) {
        const employee = this.getCurrentShop.employees.find(
          (x: Employee) => x.id === payload
        );
        return employee ? employee.name : "";
      }
      return "";
    },
    toggleTag(tag: Tag) {
      const tagFound = this.selectedTags.find((x: number) => x === tag.id);
      console.log("toggleTag", tagFound);
      if (tagFound) {
        this.selectedTags = this.selectedTags.filter(
          (x: number) => x !== tag.id
        );
        console.log("found", this.selectedTags);
      } else {
        this.selectedTags.push(tag.id);
      }
    },
    onSearchChange() {
      console.log("");
    },
    getViralStatusName(status: number) {
      if (status === 0) {
        return this.$t("STOPPED");
      } else if (status === 1) {
        return this.$t("NEW");
      } else if (status === 2) {
        return this.$t("READY");
      } else if (status === 3) {
        return this.$t("RUNNING");
      } else if (status === 4) {
        return this.$t("ENDED");
      }
      return this.$t("ERROR");
    },
    getViralStatusColor(status: number) {
      if (status === 0) {
        return "#f50";
      } else if (status === 1) {
        return "#2db7f5";
      } else if (status === 2) {
        return "#2ab8ae";
      } else if (status === 3) {
        return "#87d068";
      } else if (status === 4) {
        return "#a11693";
      }
      return "#a11616";
    },
    deleteFlow(flow: Flow) {
      message.loading({ content: "Deleting Flow: " + flow.name, key: flow.id });
      APIs.init()
        .deleteFlow({ id: flow.id })
        .then((res) => {
          if (res.status === 200 && res.data?.status === 200) {
            message.success({
              content: "Deleted Flow: " + flow.name + "!",
              key: flow.id,
              duration: 2,
            });
            this.loadAutoInbox();
          } else {
            message.destroy();
          }
        })
        .catch((e) => {
          message.destroy();
          console.error(e);
        });
    },
    handleTableChange(pagination: PaginationProps) {
      this.autoInboxRequest.page = pagination?.current;
      this.autoInboxRequest.page_size = pagination?.pageSize;
      this.loadAutoInbox();
    },
    handleTableChangeHistory(pagination: PaginationProps) {
      this.autoInboxHistoryRequest.page = pagination?.current;
      this.autoInboxHistoryRequest.page_size = pagination?.pageSize;
      this.loadAutoInboxHistory();
    },
    changePage(page_id: any) {
      console.log("change page id", page_id);
      this.currentPage = page_id;
      this.autoInboxHistoryRequest.page_id = page_id;
      this.autoInboxRequest.page_id = page_id;
      this.autoInboxHistoryRequest.page = 1;
      this.autoInboxRequest.page = 1;
      this.loadAutoInbox();
      this.loadAutoInboxHistory();
    },
    createAutoInbox() {
      console.log("showMOdal");
      this.$refs.autoInboxModal.showModal(0, this.getCurrentPageId);
    },
    onAutoInboxUpdated() {
      this.autoInboxHistoryRequest.page = 1;
      this.autoInboxRequest.page = 1;
      this.loadAutoInbox();
      this.loadAutoInboxHistory();
    },
    changeStatus(autoInbox: any) {
      console.log("change status", autoInbox.id);
      APIs.init()
        .autoInboxChangeStatus({
          id: autoInbox.id,
          status: autoInbox.status !== 2 ? 2 : 1,
        })
        .then((res) => {
          if (res.status == 200) {
            notification.success({
              duration: 2,
              message: "Information",
              description: `Auto Inbox ${res.data?.data.inbox_name} changed status.`,
            });
            this.autoInboxRequest.page = 1;
            this.loadAutoInbox();
          }
        });
    },
    edit(autoInbox: any) {
      this.$refs.autoInboxModal.showModal(autoInbox, this.getCurrentPageId);
    },
    delete(autoInbox: any) {
      console.log("delete", autoInbox);
      APIs.init()
        .autoInboxDelete({
          id: autoInbox.id,
        })
        .then((res) => {
          if (res.status == 200) {
            notification.success({
              duration: 2,
              message: "Information",
              description: `Auto Inbox ${autoInbox.inbox_name} deleted.`,
            });
            this.autoInboxRequest.page = 1;
            this.loadAutoInbox();
          }
        });
    },
  },
})
export default class AutoInbox extends Vue {}
</script>

<style lang="scss" scoped>
.container {
  margin: 10px 15px;
}

.left-layout {
  padding-right: 14px;

  .tag-group {
    padding: 10px 4px;
  }

  .button-align-left {
    text-align: left;
  }
}

.row-horizontal {
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.title-new-auto-inbox {
  font-weight: bold;
  margin-left: 16px;
}
.table-row-name {
  font-weight: bold;
}

.flow-tag {
  opacity: 0.7;
  cursor: pointer;
  margin-top: 8px;
}

.flow-tag-active {
  opacity: 1;
  cursor: pointer;
  margin-top: 8px;
}

.auto-inbox-history {
  margin-top: 15px;
}

.table_action_icon {
  margin-right: 8px;
}

.flow-table {
  margin: 16px;
}
.title-header {
  font-weight: bold;
  flex: 1;
}
.container-title {
  display: flex;
  flex-direction: row;
  padding: 16px 24px 16px 24px;
  margin-bottom: 25px;
  background-color: white;
}
.input-search {
  width: 250px;
  margin-right: 16px;
}
</style>
