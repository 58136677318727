<template>
  <div class="container-title">
    <h3 class="title-header">{{$t($route.name)}}</h3>
      <a-form-item :label="$t('SEARCH')" style="margin: 0">
        <a-input-search
            allow-clear
            :placeholder="$t('PLACEHOLDER_SEARCH_PRODUCT')"
            @search="handleChangeSearch"
            style="width: 350px"
        />
      </a-form-item>

  </div>
  <a-card v-if="filterExpanded" style="margin-right: 16px; margin-left: 16px">
    <div class="filter-bar" >
      <a-form layout="inline">
        <a-form-item>
          <div class="filter-form-label">
            {{ $t("TAGS") }}
          </div>
          <a-select
              showSearch
              allowClear
              :v-mode:value="formState.tags"
              :placeholder="$t('TAGS')"
              style="width: 220px"
              @change="handleChangeTags"
          >
            <a-select-option
                v-for="item in getProductTags"
                :key="item.id"
                v-bind:value="item.id"
            >
              <a-tag :color="item.color">
                {{ item.name }}
              </a-tag>
            </a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item>
          <div class="filter-form-label">
            {{ $t("STATUS") }}
          </div>
          <a-select
              showSearch
              allowClear
              :v-mode:value="formState.tags"
              :placeholder="$t('STATUS')"
              style="width: 220px"
              @change="handleChangeStatus"
          >
            <a-select-option

                v-bind:value="0"
            >
              Mới

            </a-select-option>
            <a-select-option

                v-bind:value="1"
            >
              Hoạt Động

            </a-select-option>
            <a-select-option

                v-bind:value="2"
            >
              Ngừng bán
            </a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item>
          <div class="filter-form-label">
            {{ $t("BIG126") }}
          </div>
          <a-select
              showSearch
              allowClear
              :v-mode:value="formState.category"
              :placeholder="$t('BIG126')"
              style="width: 220px"
              @change="handleChangeCategory"
          >
            <a-select-option
                v-for="item in getProductCategories"
                :key="item.id"
                v-bind:value="item.id"
            >{{ item.category_name }}
            </a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item>
          <div class="filter-form-label">
            {{ $t("STOCK") }}
          </div>
          <a-select
              showSearch
              allowClear
              :v-mode:value="formState.stocks"
              :placeholder="$t('STOCK')"
              @change="handleChangeStock"
              style="width: 220px"
          >
            <a-select-option
                v-for="item in getStocks"
                :key="item.id"
                v-bind:value="item.id"
            >{{ item.shop_name }}
            </a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item v-if="!stockId">
          <a-statistic
              :value="getProductGroupData.metadata.gross_product"
              style="margin-right: 9px"
          >
            <template #title>
            <span>
              <a-badge status="processing" />
              {{ $t("GROSS_PRODUCTS") }}
            </span>
            </template>
          </a-statistic>
        </a-form-item>
      </a-form>
    </div>
  </a-card>
  <a-table
      size="small"
      tableLayout="auto"
      :scroll="{  y: '80vh' }"
      :columns="columns"
      :rowKey="(record) => record.id"
      :data-source="getProductGroupData.productGroups"
      class="table-product"
      :expandedRowKeys="expandedRowKeys"
      :pagination="pagination"
      @change="handleTableChange"
      :loading="getProductGroupData.loading"
      @expand="handlerExpand"
      expandRowByClick
      :locale="{
            filterConfirm: $t('OK'),
            filterReset: $t('RESET_FILTER'),
            emptyText: $t('no_data')
          }"
  >
    <template #title>
      <div class="table-header">
        <h3 class="table-title">{{$t('LIST')}}</h3>
        <a-button v-if="currentShop.id === 121212 || currentShop.id === 134129|| currentShop.id === 134557" @click="syncAffiliate" type="primary">{{$t("SYNC_AFFILIATE")}}</a-button>
        <a-button
            v-if="getMyRole(modules.products)?.is_create && stockId"
            type="primary"
            class="button-filter-bar-right"
            @click="productImportStock"
        >
          <img
              src="@/assets/icons/pallet-export-svgrepo-com-white.svg"
              class="import-stock-icon anticon"
          />
          {{ t("IMPORT_PRODUCT_TO_STOCK") }}}
        </a-button>
        <a-upload
            name="file"
            :multiple="false"
            :show-upload-list="false"
            :beforeUpload="importProduct"
        >
        <a-button

            v-if="getMyRole(modules.products)?.is_create"
            class="button-filter-bar-right">
          <file-excel-outlined />
          {{ $t("IMPORT_STOCK") }}
        </a-button>
        </a-upload>
        <a-button
            @click="exportExcel"
            v-if="getMyRole(modules.products)?.is_read"
            class="button-filter-bar-right">
          <file-excel-outlined />
          {{ $t("EXPORT_STOCK") }}
        </a-button>
        <a-button
            v-if="getMyRole(modules.products)?.is_create"
            type="primary" class="button-add-product">
          <router-link :to="{
                           name: 'CREATE_PRODUCT',
              params: { idcategorises: '0' },
              }">
            <plus-outlined />
            {{ $t("ADD_PRODUCT") }}
          </router-link>
        </a-button>
        <a-tooltip placement="topLeft" :title="$t('filter')" arrow-point-at-center>
            <a-button
                style="padding: 4px; width: 30px; height: 30px; border-radius: 2px; border-width: 1px; border-color: #D9D9D9"
                @click="filterExpanded=!filterExpanded" type="link"><ControlOutlined style="color:rgba(0, 0, 0, 0.85);"/></a-button>
        </a-tooltip>

      </div>
    </template>
    <template #group_name="{ record }">
      <a type="text" class="button-name" @click="expandRow(record.id)">
        {{ record.group_name }}
      </a>
    </template>
    <template #stock="{ text }">
      <router-link to="/stock/23">
        {{ text }}
      </router-link>
    </template>
    <template #customTitle>
      <span>
        <smile-outlined />
        {{ $t("NAME") }}
      </span>
    </template>
    <template #image_url="{ record }">
      <span>
        <a-image
            width="50px"
            max-height="100px"
            max-width="100px"
            :src="record.image_url?.split(',')[0]"
        />
      </span>
    </template>
    <template #price="{ record }">
      <span>
        {{
          record.price ? formatMoney(String(record.price)?.split(",")[0]) : "0"
        }}
      </span>
    </template>
    <template #quantity="{ record }">
      <span>
        {{ formatMoney(record.quantity) }}
      </span>
    </template>
    <template #total="{ text }">
      <span class="total">
        {{ text }}
      </span>
    </template>
    <template #version_count="{ record }">
      <span class="version_count">
        {{ formatMoney(record.version_count) }}
      </span>
    </template>
    <template #status="{ record }">
      <span class="status">
        {{ record.status==1?"Hoạt động":( record.status==0?"Mới": "Ngừng bán") }}
      </span>
    </template>
    <template #action="{ record }">
      <a-dropdown>
        <a-button type="text">
          <eye-outlined />
        </a-button>
        <template #overlay>
          <a-menu>
            <a @click="edit(record)"
               v-if="getMyRole(modules.products)?.is_edit"
            >
              <a-menu-item class="router-product-edit">
                <edit-outlined />
                {{ $t("EDIT") }}
              </a-menu-item>
            </a>
            <a :href="'/crm/product_preview/'+record.id" target="_blank">
              <a-menu-item>
                  <BarcodeOutlined />
                  {{ $t("PRINT_LABEL") }}
              </a-menu-item>
            </a>


            <!--            <a-menu-item class="router-product-edit" @click="importStock(record)">-->
            <!--              <img src="@/assets/icons/pallet-export-svgrepo-com.svg" class="import-stock-icon anticon">-->
            <!--              Import stock-->
            <!--            </a-menu-item>-->
            <a-menu-item
                v-if="getMyRole(modules.products)?.is_delete"
            >
              <a-popconfirm
                  :title="$t('CONFIRM_DELETE') + ' ' + record.group_name + '?'"
                  :ok-text="$t('OK')"
                  :cancel-text="$t('CANCEL')"
                  @confirm="confirmDelete(record)"
              >
                <delete-outlined />
                {{ $t("DELETE") }}
              </a-popconfirm>
            </a-menu-item>
          </a-menu>
        </template>
      </a-dropdown>
    </template>
    <template #tags_id="{ record }">
      <span v-if="record.tags_id">
        <template
            v-for="tag in record.tags_id?.replace(/_/g, '').split(',')"
            :key="tag"
        >
          <a-tag :color="findTagById(tag)?.color">{{
              findTagById(tag)?.name
            }}</a-tag>
        </template>
      </span>
    </template>
    <template #categories_id="{ record }">
      <span>
       {{findProductCategoryById(record.categories_id)?.category_name}}
      </span>
    </template>
    <template #expandedRowRender="{ record }">
      <div v-if="rowExpandLoading" class="spin-inside-container">
        <a-spin />
      </div>
      <div v-else>
        <div class="import-stock-row">
          <a-button
              v-if="getMyRole(modules.products)?.is_edit"
              @click="edit(record)"
              type="dashed"
              style="margin-left: 16px; margin-right: 16px"
          >
            <edit-outlined />
            {{ $t("EDIT") }}
          </a-button>
          <a-button
              v-if="getMyRole(modules.products)?.is_delete"
              type="danger">
            <a-popconfirm
                :title="$t('CONFIRM_DELETE') + ' ' + record.group_name + '?'"
                :ok-text="$t('OK')"
                :cancel-text="$t('CANCEL')"
                @confirm="confirmDelete(record)"
            >
              <delete-outlined />
              {{ $t("DELETE") }}
            </a-popconfirm>
          </a-button>
        </div>
<!--        <a-row class="row-2-detail">-->
<!--          <a-col :span="12" class="extra-item">-->
<!--            <span class="extra-item-label"> {{ $t("DESCRIPTION") }}: </span>-->
<!--            <div class="extra-item-value extra-item-value-note">-->
<!--              {{ record.group_desc }}-->
<!--            </div>-->
<!--          </a-col>-->
<!--          <a-col :span="12" class="extra-item">-->
<!--            <span class="extra-item-label"> {{ $t("BIG126") }}: </span>-->
<!--&lt;!&ndash;            <template&ndash;&gt;-->
<!--&lt;!&ndash;                v-for="category_id in record.categories_id&ndash;&gt;-->
<!--&lt;!&ndash;                ? record.categories_id.split(',')&ndash;&gt;-->
<!--&lt;!&ndash;                : []"&ndash;&gt;-->
<!--&lt;!&ndash;                :key="category_id"&ndash;&gt;-->
<!--&lt;!&ndash;            >&ndash;&gt;-->
<!--&lt;!&ndash;              <div class="extra-item-value">&ndash;&gt;-->
<!--&lt;!&ndash;                <a&ndash;&gt;-->
<!--&lt;!&ndash;                    @click="&ndash;&gt;-->
<!--&lt;!&ndash;                    formState.category =&ndash;&gt;-->
<!--&lt;!&ndash;                      Number(category_id) && handleChangeCategory(category_id)&ndash;&gt;-->
<!--&lt;!&ndash;                  "&ndash;&gt;-->
<!--&lt;!&ndash;                >&ndash;&gt;-->
<!--&lt;!&ndash;                  {{ findCategoryById(category_id).category_name }}&ndash;&gt;-->
<!--&lt;!&ndash;                </a>&ndash;&gt;-->
<!--&lt;!&ndash;              </div>&ndash;&gt;-->
<!--&lt;!&ndash;            </template>&ndash;&gt;-->
<!--          </a-col>-->
<!--        </a-row>-->
        <a-table
            :columns="versionColumns"
            :data-source="record.versions ? record.versions : []"
            :pagination="false"
        >
          <template #product_name="{ record }">
            <a
            >{{ record.product_name }}<br />{{
                record.attributes
                    ? JSON.parse(record.attributes)
                        .map((a) => a.value)
                        .join("/")
                    : ""
              }}</a
            >
          </template>
          <template #customTitle>
            <span>
              <smile-outlined />
              {{ $t("NAME") }}
            </span>
          </template>
          <template #avatar_url="{ record }">
            <span>
              <a-image
                  width="50px"
                  max-height="100px"
                  max-width="100px"
                  :src="record.avatar_url"
              />
            </span>
          </template>
          <template #total="{ text }">
            <span class="total">
              {{ text }}
            </span>
          </template>
          <template #quantity="{ text }">
            <span class="quantity">
              {{ formatMoney(text) }}
            </span>
          </template>
          <template #avg_import_price="{ text }">
            <span class="avg_import_price">
              {{ formatMoney(text) }}
            </span>
          </template>
          <template #max_import_price="{ text }">
            <span class="max_import_price">
              {{ formatMoney(text) }}
            </span>
          </template>
          <template #min_import_price="{ text }">
            <span class="min_import_price">
              {{ formatMoney(text) }}
            </span>
          </template>
          <template #unit_price="{ record }">
            <span class="unit_price">
              {{ formatMoney(record.unit_price) }}
            </span>
          </template>
          <template #promotional_price="{ record }">
            <span class="unit_price">
              {{ formatMoney(record.promotional_price) }}
            </span>
          </template>
          <template #wholesale_price="{ record }">
            <span class="unit_price">
              {{ formatMoney(record.wholesale_price) }}
            </span>
          </template>
          <template #operation="{ record }">
            <div class="editable-row-operations">
              <span>
                <a-button @click="importStock(record)" type="text">
                  <img
                      src="@/assets/icons/pallet-export-svgrepo-com.svg"
                      class="import-stock-icon anticon"
                  />
                </a-button>
              </span>
            </div>
          </template>
        </a-table>
      </div>
    </template>
  </a-table>
  <stock-import-modal
      ref="stockImportModalRef"
      :callbackImport="callbackImport"
  />
  <products-import-stock-modal ref="productImportStockRef" />
</template>

<script lang="ts">
import {
  computed,
  createVNode,
  defineComponent,
  onBeforeMount,
  reactive,
  ref,
  UnwrapRef, watch,
} from "vue";
import {
  FileExcelOutlined,
  PlusOutlined,
  DeleteOutlined,
  EditOutlined,
  BarcodeOutlined, ControlOutlined, ExclamationCircleOutlined, EyeOutlined,
} from "@ant-design/icons-vue";
import StockImportModal from "@/components/products/StockImportModal.vue";
import ProductsImportStockModal from "@/components/products/ProductsImportStockModal.vue";
import { ProductsActionTypes } from "@/store/modules/product/action-types";
import {
  FormProduct,
  Product,
  ProductCategory,
  ProductGroup,
} from "@/models/Products/Products";
import { Tag } from "@/models/Tags/Tags";
import { TagsActionTypes } from "@/store/modules/tag/action-types";
import { StockActionTypes } from "@/store/modules/stocks/action-types";
import { Stocks } from "@/models/Stocks/Stocks";
import { message, Modal, Pagination } from "ant-design-vue";
import APIs from "@/APIs/APIs";
import { useRoute } from "vue-router";
import { ProductGroupRequest } from "@/APIs/request/ProductRequest";
import { ProductGroupData } from "@/store/modules/product/states";
import { MixUtils } from "@/utils/Utils";
import { ProductMutationTypes } from "@/store/modules/product/mutation-types";
import router from "@/router";
import { useI18n } from "vue-i18n";
import {useStore} from "vuex";
import {TableProps,} from "ant-design-vue/es/table";
import RouterEndpoint from "@/router/RouterEndpoint";
import {Constant} from "@/utils/Constants";


export default defineComponent({
  props: ["stockId"],
  name: "Products",
  components: {
    ProductsImportStockModal,
    EyeOutlined,
    FileExcelOutlined,
    BarcodeOutlined,
    PlusOutlined,
    DeleteOutlined,
    EditOutlined,
    ControlOutlined,
    StockImportModal,
  },
  created() {
    // this.$watch(() => this.getProductTags || this.getProductCategories, () => {
    //   for (let i = 0; i < this.columns.length; i++) {
    //     if(this.columns[i].key === 'tags_id'){
    //       this.columns[i].filters = this.getProductTags
    //     }
    //     if(this.columns[i].key === 'categories_id'){
    //       this.columns[i].filters = this.getProductCategories
    //     }
    //   }
    //
    // }, { immediate: true });
    this.$watch(
        () => this.$route.query,
        () => {
          this.productGroupRequest.page = this.$route.query.page ?  Number(this.$route.query.page) :1;
          this.productGroupRequest.page_size = this.$route.query.page_size ? Number(
              this.$route.query.page_size
          ) :20;
          this.fetchProductGroups();
        },
        { immediate: true }
    );
  },
  setup: function () {
    const i18n = useI18n();
    const store = useStore();
    const route = useRoute();

    const columns = computed(() => [
      {
        title: i18n.t("Id"),
        dataIndex: "id",
        key: "id",
        slots: {customRender: "id"},
        width: '5%',
      },
      {
        title: i18n.t("NAME"),
        dataIndex: "group_name",
        key: "group_name",
        slots: {title: "customTitle", customRender: "group_name"},
      },
      {
        title: i18n.t("PRICE"),
        key: "price",
        slots: {customRender: "price"},
        width: '12%',
      },
      {
        title: i18n.t("IMAGE"),
        dataIndex: "image_url",
        key: "image_url",
        slots: {customRender: "image_url"},
        width: '10%',
      },
      {
        title: i18n.t("Versions"),
        key: "version_count",
        slots: {customRender: "version_count"},
        width: '12%',
      },
      // {
      //   title: i18n.t("TAGS"),
      //   dataIndex: "tags_id",
      //   key: "tags_id",
      //   slots: {customRender: "tags_id"},
      //   width: '12%',
      //   filters: [],
      //   filterMultiple: false
      // },
      //
      {
        title: i18n.t("CATEGORY"),
        dataIndex: "categories_id",
        key: "categories_id",
        slots: {customRender: "categories_id"},
        width: '12%',
        // filters: [],
        // filterMultiple: false
      },
      {
        title: i18n.t("STATUS"),
        key: "status",
        dataIndex: "status",
        slots: {customRender: "status"},
        width: '12%',
      },

      {
        title: i18n.t("ACTION"),
        key: "action",
        slots: {customRender: "action"},
        width: 80,
      }
    ]);


    const versionColumns = computed(() => [
      {
        title: i18n.t("Id"),
        dataIndex: "id",
        key: "id",
        slots: {customRender: "id"},
        width: '5%',
      },
      {
        title: i18n.t("Code"),
        dataIndex: "product_code",
        key: "product_code",
      },
      {
        title: i18n.t("product_name"),
        dataIndex: "product_name",
        key: "product_name",
        slots: {customRender: "product_name"},
      },
      {
        title: i18n.t("camera_image"),
        dataIndex: "avatar_url",
        key: "avatar_url",
        slots: {customRender: "avatar_url"},
      },
      {
        title: i18n.t("sale_price"),
        key: "unit_price",
        dataIndex: "unit_price",
        slots: {customRender: "unit_price"},
      },
      {
        title: i18n.t("wholesale_price"),
        key: "wholesale_price",
        dataIndex: "wholesale_price",
        slots: {customRender: "wholesale_price"},
      },
      {
        title: i18n.t("promotional_price"),
        key: "promotional_price",
        dataIndex: "promotional_price",
        slots: {customRender: "promotional_price"},
      },
      {
        title: i18n.t("Quantity"),
        key: "quantity",
        dataIndex: "quantity",
        slots: {customRender: "quantity"},
      },
      {
        key: i18n.t("operation"),
        slots: {customRender: "operation"},
      },
    ]);

    const stockImportModalRef = ref();
    const productImportStockRef = ref();
    const productModalRef = ref();
    const expandedRowKeys = ref<number[]>([]);
    const rowExpandLoading = ref<boolean>(false);
    const formatMoney = (money: number) => {
      return MixUtils.methods.formatMoney(money);
    };
    const currentShop = computed(()=> store.getters.getCurrentShop)
    const expandRow = (id: number) => {
      if (expandedRowKeys.value.includes(id)) {
        expandedRowKeys.value = expandedRowKeys.value.filter((x) => x !== id);
      } else {
        rowExpandLoading.value = true;
        setTimeout(() => {
          rowExpandLoading.value = false;
        }, 1200);

        expandedRowKeys.value.push(id);
      }
    };

    const syncAffiliate = async () =>{
        const res = await APIs.init().AffiliateSyncProduct({})
      if(res.data?.status === 200){
        message.success(i18n.t('synchronized_successful'))
      }
    }

    const importStock = (product: Product) => {
      stockImportModalRef.value.showModal(product);
    };
    const productImportStock = () => {
      productImportStockRef.value.showModal();
    };

    const productGroupRequest: ProductGroupRequest = {page: 1, page_size: 20};

    const fetchProductGroups = () => {

      store.dispatch(ProductsActionTypes.FETCH_PRODUCTS, productGroupRequest);
    };

    const fetchProductTags = () => {
      store.dispatch(TagsActionTypes.FETCH_TAGS, {
        type: "product",
        page: 1,
        page_size: 1000,
        search: "",
      });
    };
    const importProduct=  (products:any)=>{
      const formData = new FormData();
      formData.append("files", products);

      APIs.init()
          .productImport(formData)
          .then((res) => {

            if (res.status === 200 && res.data?.status === 200) {

              message.success({content: "Import product success"});
            } else {
              message.destroy();
            }
          })
          .catch((e) => {
            message.destroy();
            console.error(e);
          });
    }
    const exportExcel = async () => {
      try {
        //@ts-ignore
        const response = await APIs.init().productExport();
        //@ts-ignore
        const href = window.URL.createObjectURL(new Blob([response.data]));

        // create "a" HTML element with href to file & click
        console.log(href)
        const link = document.createElement('a');
        link.href = href;
        link.setAttribute('download', "Sản_Phẩm_"+MixUtils.methods.getUUID()+'.xlsx');
        document.body.appendChild(link);
        link.click();

        // clean up "a" element & remove ObjectURL
        document.body.removeChild(link);
        window.URL.revokeObjectURL(href);


      } catch (e) {
        console.log(e)
      }

    }
    const fetchProductCategories = () => {
      store.dispatch(ProductsActionTypes.FETCH_PRODUCT_CATEGORIES, {
        page: 1,
        page_size: 1000,
        search: "",
      });
    };

    const fetchStocks = () => {
      store.dispatch(StockActionTypes.FETCH_STOCKS, {
        page: 1,
        page_size: 1000,
        callback: (data: any)=>{
          console.log('fetchStocks', data)
          if(data==null || data?.length===0){
            Modal.confirm({
              title: i18n.t('NOTIFY_DONT_HAVE_STOCK'),
              icon: createVNode(ExclamationCircleOutlined),
              content: i18n.t('NOTIFY_CONTENT_CREATE_STOCK'),
              onOk() {
                 router.replace({name: RouterEndpoint.STOCKS.name})
              }
            });
          }
        }
      });
    };

    const fetchProviders = () => {
      store.dispatch(ProductsActionTypes.FETCH_PRODUCT_PROVIDERS, {
        page: 1,
        page_size: 1000,
      });
    };

    const getProductGroupData = computed(
        (): ProductGroupData => store.getters.getProductGroups
    );
    const getProductTags = computed((): never[] => {
          return store.getters.getProductTags?store.getters.getProductTags.map((tag: Tag)=>{
            tag.text = tag.name
            tag.value = tag.id
            return tag
          }):[]
        }
    );
    const getProductCategories = computed(
        (): never[] | undefined =>{
          return store.getters.getProductCategories?store.getters.getProductCategories.map((category: ProductCategory)=>{
            category.text = category.category_name
            category.value = category.id
            return category
          }):[]
        }
    );
    const getStocks = computed(
        (): Stocks[] | undefined => store.getters.getStocks.stocks
    );



    const pagination = computed(() => {
      return {
        current: route.query.page ? parseInt(route.query.page + "") : 1,
        pageSize: route.query.page_size
            ? parseInt(route.query.page_size + "")
            : 20,
        defaultPageSize: 20,
        total:
            getProductGroupData.value.metadata &&
            getProductGroupData.value.metadata.record_total
                ? getProductGroupData.value.metadata.record_total
                : 0,
        pageSizeOptions: ["20", "40", "80", "120"],
        showSizeChanger: true,
      };
    });

    const fetchProductDetail = async (products: ProductGroup) => {
      message.loading("");
      const response = await APIs.init().getProductDetail({
        product_group_id: products.id,
        stock_id: productGroupRequest.stock,
      });
      message.destroy();
      if (response.status === 200 && response.data?.status === 200) {
        store.commit(
            ProductMutationTypes.SET_PRODUCTS_DETAIL,
            response.data?.data
        );
      }
    };

    const callbackImport = (products: ProductGroup) => {
      fetchProductDetail(products);
    };

    const formState: UnwrapRef<FormProduct> = reactive({
      tags: undefined,
      category: undefined,
      stocks: undefined,
      status:undefined
    });

    const findTagById = (id: any) => {
      if (getProductTags.value) {
        try{
          const tags = getProductTags.value.filter(
              (item) => item['id'] + "" === id + ""
          );
          return tags[0];
        }catch (e) {
          return {}
        }

      } else {
        return {};
      }
    };
    const findProductCategoryById = (id: any) => {
      if (getProductCategories.value) {
        try{
          const category = getProductCategories.value.filter(
              (item) => item['id'] + "" === id + ""
          );
          return category[0];
        }catch (e) {
          return {}
        }

      } else {
        return {};
      }
    };

    const findCategoryById = (id: any) => {
      if (getProductCategories.value) {
        const categories = getProductCategories.value.filter(
            (item) => item['id'] + "" === id + ""
        );
        return categories[0];
      } else {
        return {};
      }
    };




    onBeforeMount(() => {
      fetchProductGroups();
      fetchProductTags();
      fetchProductCategories();
      fetchStocks();
      fetchProviders();
    });

    const showProductModal = (product: ProductGroup) => {
      router.push(`product/${product.id}`);
    };

    const create = () => {
      showProductModal({});
    };

    const edit = (product: ProductGroup) => {
      showProductModal(product);
    };

    const confirmDelete = async (product: ProductGroup) => {
      message.loading("");
      const response = await APIs.init().deleteProductGroup(product);
      if (response.status === 200) {
        message.info("Deleted product " + product.group_name);
        fetchProductGroups();
      } else {
        message.info("Deleted product error");
      }
    };

    const callbackUpdate = () => {
      fetchProductGroups();
    };

    const handleTableChange: TableProps<Product>['onChange'] = (pagination, filters, sorter) => {

      if(filters.tags_id&&filters.tags_id.length>0) {
        try {
          productGroupRequest.tag = parseInt(filters['tags_id'][0]+'');
        } catch (e) {
          console.log(e)
        }
      }else{
        productGroupRequest.tag = undefined
      }
      if(filters.categories_id&&filters.categories_id.length>0){
        try{
          productGroupRequest.category = parseInt(filters.categories_id[0]+'');
        }catch (e) {
          console.log(e)
        }
      }else{
        productGroupRequest.category = undefined
      }
      productGroupRequest.page = pagination?.current;
      productGroupRequest.page_size = pagination?.pageSize;
      router.replace({
        replace: true,
        force: true,
        name: RouterEndpoint.PRODUCTS.name,
        query: productGroupRequest,
      });
      fetchProductGroups()
    };

    const handleChangeTags = (tag_id: number) => {
      productGroupRequest.tag = tag_id;
      fetchProductGroups();
    };
    const handleChangeStatus = (status: number) => {
      productGroupRequest.status = status;
      fetchProductGroups();
    };
    const handleChangeCategory = (category_id: number) => {
      productGroupRequest.category = category_id ? Number(category_id) : 0;
      fetchProductGroups();
    };
    const handleChangeSearch = (search: string) => {
      productGroupRequest.search = search;
      fetchProductGroups();
    };
    const handleChangeStock = (stock_id: number) => {
      productGroupRequest.stock = stock_id;
      fetchProductGroups();
    };
    const handlerExpand = (expanded: boolean, record: any) => {

      if (expanded) {
        expandedRowKeys.value = [record.id]
        rowExpandLoading.value = true;
        fetchProductDetail(record);
        rowExpandLoading.value = false;
      } else {
        expandedRowKeys.value = []
      }


    };
    const filterExpanded= ref(false)

    const getUserInfo = computed(() => store.getters.getUserInfo)

    const getMyRole = computed(() => {
      if(getUserInfo.value.id_cms_privileges<11){
        return ()=>({
          is_visible: 1,
          is_create:1,
          is_read: 1,
          is_edit: 1,
          is_delete: 1,
          id: 1
        })
      }else{
        return store.getters.getRole
      }
    });
    const modules = Constant.MODULES;

    return {
      getMyRole,
      modules,
      productGroupRequest,
      filterExpanded,
      columns,
      expandedRowKeys,
      expandRow,
      rowExpandLoading,
      stockImportModalRef,
      importStock,
      productImportStockRef,
      productImportStock,
      getProductGroupData,
      fetchProductGroups,
      fetchProductTags,
      formState,
      getProductTags,
      getProductCategories,
      getStocks,
      productModalRef,
      showProductModal,
      create,
      edit,
      findTagById,
      confirmDelete,
      pagination,
      handleTableChange,
      callbackUpdate,
      formatMoney,
      findCategoryById,
      handleChangeTags,
      handleChangeCategory,
      handleChangeSearch,
      handleChangeStock,
      handlerExpand,
      versionColumns,
      callbackImport,
      findProductCategoryById,
      exportExcel,
      importProduct,
      syncAffiliate,
      currentShop,
      handleChangeStatus
    };
  },
});
</script>

<style scoped lang="scss">
.filter-bar {
  display: flex;
  flex-wrap: wrap;

  .filter-form-label {
    margin-bottom: 4px;
  }

  .button-function {
    display: flex;
    flex: 1;
    justify-content: flex-end;
    align-self: flex-end;

    .button-filter-bar-right {
      margin-left: 16px;
    }

    .button-add-product {
      margin-left: 32px;
    }
  }
}

.table-product {
  margin: 16px;

  .button-name {
    font-weight: bold;
  }

  .extra-item {
    display: flex;
    padding-right: 10px;
    padding-left: 10px;
    align-items: center;

    .extra-item-label {
    }

    .extra-item-value {
      margin-left: 12px;
      //background-color: #eeeeee;
      //padding: 10px 20px;
      //border-radius: 4px;
      flex: 1;
    }

    .extra-item-value-note {
      background-color: #eeeeee;
      padding: 10px 20px;
      border-radius: 4px;
    }
  }

  .row-2-detail {
    margin-top: 7px;
  }

  .import-stock-row {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 12px;
  }
  .router-product-edit {
    color: black;
  }

  .spin-inside-container {
    text-align: center;
    border-radius: 4px;
    padding: 30px 50px;
    margin: 20px 0;
  }
}

.import-stock-icon {
  width: 16px;
  height: 16px;
}
.table-header{
  display: flex;
  gap: 16px;
  padding-left: 16px;
  padding-right: 16px;
}
.container-filter{
  display: flex;
  gap: 16px;
}
.table-title{
  flex: 1
}
.container-title{
  display: flex;
  flex-direction: row;
  padding: 16px 24px 16px 24px;
  margin-bottom: 25px;
  background-color: white
}
</style>
