export enum ReportPAGEActionTypes {

    FETCH_REPORT_PAGE_COMMENT = 'FETCH_REPORT_PAGE_COMMENT',
    FETCH_REPORT_PAGE_MESSAGE = 'FETCH_REPORT_PAGE_MESSAGE',
    FETCH_REPORT_PAGE_NEW_CONVERSATION = 'FETCH_REPORT_PAGE_NEW_CONVERSATION',
    FETCH_CUSTOMER_REPORT = 'FETCH_CUSTOMER_REPORT',
    CUSTOMER_ORDER = 'CUSTOMER_ORDER',
    REVENUE = 'REVENUE',
        EMPLOYEE = 'EMPLOYEE'
}
