import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Transition as _Transition, withCtx as _withCtx, createVNode as _createVNode, resolveDynamicComponent as _resolveDynamicComponent, createTextVNode as _createTextVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-7f048b2e"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "title-flow-context" }
const _hoisted_2 = { class: "steps-content" }
const _hoisted_3 = { key: 0 }
const _hoisted_4 = { class: "btn-submit" }
const _hoisted_5 = {
  key: 1,
  class: "spin-container"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_flow_management_panel = _resolveComponent("flow-management-panel")!
  const _component_a_col = _resolveComponent("a-col")!
  const _component_a_step = _resolveComponent("a-step")!
  const _component_a_steps = _resolveComponent("a-steps")!
  const _component_send_outlined = _resolveComponent("send-outlined")!
  const _component_a_button = _resolveComponent("a-button")!
  const _component_facebook_post_back_preview = _resolveComponent("facebook-post-back-preview")!
  const _component_ZaloPostbackPreview = _resolveComponent("ZaloPostbackPreview")!
  const _component_facebook_reply_comment_preview = _resolveComponent("facebook-reply-comment-preview")!
  const _component_zalo_oa_preview = _resolveComponent("zalo-oa-preview")!
  const _component_email_preview = _resolveComponent("email-preview")!
  const _component_a_row = _resolveComponent("a-row")!
  const _component_a_spin = _resolveComponent("a-spin")!

  return (!_ctx.isLoading)
    ? (_openBlock(), _createElementBlock("div", {
        class: "container",
        key: _ctx.componentKey
      }, [
        _createVNode(_component_a_row, null, {
          default: _withCtx(() => [
            _createVNode(_component_a_col, {
              span: 6,
              class: "left-control"
            }, {
              default: _withCtx(() => [
                _createElementVNode("h3", _hoisted_1, _toDisplayString(_ctx.leftTitle), 1),
                _createVNode(_Transition, { name: "slide-fade" }, {
                  default: _withCtx(() => [
                    (_ctx.flowType>0&&_ctx.current===1)
                      ? (_openBlock(), _createBlock(_component_flow_management_panel, {
                          key: 0,
                          class: "root-flow-control",
                          flowContext: _ctx.currentFlowContext,
                          swapRootFlowContext: _ctx.swapRootFlowContext,
                          deleteFlowContext: _ctx.deleteFlowContext,
                          flowContextData: _ctx.flowContextData
                        }, null, 8, ["flowContext", "swapRootFlowContext", "deleteFlowContext", "flowContextData"]))
                      : _createCommentVNode("", true)
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }),
            _createVNode(_component_a_col, { span: 12 }, {
              default: _withCtx(() => [
                _createVNode(_component_a_steps, {
                  current: _ctx.current,
                  "onUpdate:current": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.current) = $event))
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_a_step, {
                      title: _ctx.$t('FLOW_INFORMATION')
                    }, null, 8, ["title"]),
                    _createVNode(_component_a_step, {
                      title: _ctx.$t('FLOW_SETUP_CONTEXT'),
                      disabled: !(_ctx.flowContextData.context.flow_contexts&&_ctx.flowContextData.context.flow_contexts.length>0)
                    }, null, 8, ["title", "disabled"]),
                    _createVNode(_component_a_step, {
                      title: _ctx.$t('FLOW_PUBLISHED'),
                      disabled: "",
                      status: _ctx.flowContextData.context.flow_contexts&&_ctx.flowContextData.context.flow_contexts.length>0&&_ctx.flowContextData.context.flow_contexts[0].status==='publish'?'finish':'wait'
                    }, null, 8, ["title", "status"])
                  ]),
                  _: 1
                }, 8, ["current"]),
                _createElementVNode("div", _hoisted_2, [
                  _createVNode(_Transition, { name: "slide-fade" }, {
                    default: _withCtx(() => [
                      (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.stepComponent), {
                        isShow: _ctx.isShow,
                        updateCurrentFlowContext: _ctx.updateCurrentFlowContext,
                        flowContext: _ctx.currentFlowContext,
                        flowContextData: _ctx.flowContextData,
                        deleteFlowContext: _ctx.deleteFlowContext,
                        updateRootFlow: _ctx.updateRootFlow,
                        nextStep: _ctx.nextStep,
                        createRootFlow: _ctx.createRootFlow
                      }, null, 8, ["isShow", "updateCurrentFlowContext", "flowContext", "flowContextData", "deleteFlowContext", "updateRootFlow", "nextStep", "createRootFlow"]))
                    ]),
                    _: 1
                  })
                ])
              ]),
              _: 1
            }),
            _createVNode(_component_a_col, {
              span: 6,
              class: "right-control"
            }, {
              default: _withCtx(() => [
                _createVNode(_Transition, { name: "slide-fade" }, {
                  default: _withCtx(() => [
                    (_ctx.flowType>0)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                          _createElementVNode("div", _hoisted_4, [
                            _createVNode(_component_a_button, {
                              type: "primary",
                              class: "btn-publish",
                              onClick: _ctx.onPublish
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_send_outlined),
                                _createTextVNode(" " + _toDisplayString(_ctx.$t("FLOW_PUBLISH")), 1)
                              ]),
                              _: 1
                            }, 8, ["onClick"])
                          ]),
                          (_ctx.flowType===1)
                            ? (_openBlock(), _createBlock(_component_facebook_post_back_preview, {
                                key: 0,
                                flowContext: _ctx.currentFlowContext
                              }, null, 8, ["flowContext"]))
                            : (_ctx.flowType===2)
                              ? (_openBlock(), _createBlock(_component_ZaloPostbackPreview, {
                                  key: 1,
                                  flowContext: _ctx.currentFlowContext
                                }, null, 8, ["flowContext"]))
                              : (_ctx.flowType===3)
                                ? (_openBlock(), _createBlock(_component_facebook_reply_comment_preview, {
                                    key: 2,
                                    flowContext: _ctx.currentFlowContext
                                  }, null, 8, ["flowContext"]))
                                : (_ctx.flowType===4)
                                  ? (_openBlock(), _createBlock(_component_zalo_oa_preview, {
                                      key: 3,
                                      flowContext: _ctx.currentFlowContext
                                    }, null, 8, ["flowContext"]))
                                  : (_ctx.flowType===5)
                                    ? (_openBlock(), _createBlock(_component_email_preview, {
                                        key: 4,
                                        flowContext: _ctx.currentFlowContext
                                      }, null, 8, ["flowContext"]))
                                    : _createCommentVNode("", true)
                        ]))
                      : _createCommentVNode("", true)
                  ]),
                  _: 1
                })
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ]))
    : (_openBlock(), _createElementBlock("div", _hoisted_5, [
        _createVNode(_component_a_spin)
      ]))
}