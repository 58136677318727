
import {VoucherMutationTypes} from "@/store/modules/voucher/mutation-types";
import {Vouchers} from "@/models/Voucher/Voucher";
import {State, VoucherData} from "@/store/modules/voucher/states";


export type Mutations<S = State> = {
    [VoucherMutationTypes.SET_VOUCHER](state: S, payload: VoucherData | undefined): void;
    [VoucherMutationTypes.SET_VOUCHER_ACTIVE](state: S, payload: any | undefined): void;
}

export const mutations: Mutations = {
    [VoucherMutationTypes.SET_VOUCHER](state: State, payload: VoucherData | undefined): void {
        state.vouchers = payload
    },
    [VoucherMutationTypes.SET_VOUCHER_ACTIVE](state: State, payload: any | undefined): void {
        state.voucherActive = payload
    },
}
