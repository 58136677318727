<template>
  <div class="container-title">
    <h3 class="title-header">{{$t($route.name)}}</h3>
    <a-form layout="inline" :model="filterForm">
      <a-form-item label="Schedule time">
        <a-range-picker v-model:value="filterForm.dateRange" @change="dateRangeChange"/>
      </a-form-item>
    </a-form>

  </div>

    <a-table
        class="table"
        @expand="tableExpand"
        :data-source="list.triggerStatistics"
        :pagination="pagination"
        :expandedRowKeys="expandedRowKeys"
        @change="handleTableChange"
        :columns="columns"
        :rowKey="(record) => record.id"
    >

      <template #trigger_name="{ record }">
        <a-button class="table-row-name" type="text">
          {{ record.trigger_name }}
        </a-button>
      </template>
      <template #created="{ text }">
                <span class="item-trigger-table-small">
                   {{ dateDisplayFormatFromString(text) }}

                </span>
      </template>
      <template #success="{ text }">
                <span>
                   <a-tag color="#87d068">{{ text }}</a-tag>

                </span>
      </template>
      <template #failed="{ text }">
                <span>
                   <a-tag color="#f50">{{ text }}</a-tag>

                </span>
      </template>
      <template #sent="{ text }">
                <span>
                   <a-tag color="#2db7f5">{{ text }}</a-tag>

                </span>
      </template>
      <template #clicked="{ text }">
                <span>
                   <a-tag color="#42dbc7">{{ text }}</a-tag>

                </span>
      </template>
      <template #action="{record}">
        <a-tooltip :title="$t('ERROR_LOG')">
          <warning-outlined @click="viewErrorLog(record)"/>
        </a-tooltip>
      </template>

      <template #expandedRowRender>
        <a-spin v-if="expandLoading"/>
        <highcharts v-else class="chart" :options="charts"></highcharts>
      </template>
    </a-table>
    <a-modal v-model:visible="errorLogVisible" :title="$t('ERROR_LOG_VIEWER')" :footer="null">
      <a-spin v-if="errorLogsLoading"/>
      <VirtualList v-else
          class="scroller"
          :size="30" :remain="20"
      >
        <a-timeline>

          <a-timeline-item v-for="item in errorLogs.triggerStatisticErrorLogs" :key="item.created">{{item.content}}
            {{ dateTimeDisplayFormatFromString(item.created) }}
          </a-timeline-item>
        </a-timeline>
      </VirtualList>

    </a-modal>

</template>

<script lang="ts">
import {computed, defineComponent, onMounted, reactive, ref} from "vue";
import {useI18n} from "vue-i18n";
import moment, {Moment} from "moment";
import {useStore} from "vuex";
import {TriggerMutationTypes} from "@/store/modules/trigger/mutation-types";
import {TriggerActionTypes} from "@/store/modules/trigger/action-types";
import {TriggerStatistic, TriggerStatisticErrorLog} from "@/models/Trigger/TriggerHistory";
import {MixUtils} from "@/utils/Utils";
import {useRoute} from "vue-router";
import VirtualList from "@supertiger/vue-3-virtual-scroll-list";

import {
  WarningOutlined
} from "@ant-design/icons-vue"
import {PaginationProps} from "ant-design-vue/es/pagination";
import dayjs, {Dayjs} from "dayjs";
import useTriggerStore from "../../../store/Trigger";


interface Filter {
  dateRange: Dayjs[],
  trigger_id: number,
  page: number,
  page_size: number
}

export default defineComponent({
  name: "TriggerHistory",
  components: {
    WarningOutlined,
    VirtualList
  },
  setup() {
    const i18n = useI18n()
    const store = useStore()
    const route = useRoute()
    const columns = [
      {
        title: i18n.t('TRIGGER_TITLE'),
        dataIndex: 'trigger_name',
        slots: {customRender: 'trigger_name'},
      },
      {
        title: i18n.t('SUCCESS'),
        dataIndex: 'success',
        slots: {customRender: 'success'},
      },
      {
        title: i18n.t('FAILED'),
        dataIndex: 'failed',
        slots: {customRender: 'failed'},
      },
      {
        title: i18n.t('SENT'),
        dataIndex: 'sent',
        slots: {customRender: 'sent'},
      },
      {
        title: i18n.t('CLICKED'),
        dataIndex: 'clicked',
        slots: {customRender: 'clicked'},
      },
      {
        title: i18n.t('CREATED'),
        dataIndex: 'created',
        slots: {customRender: 'created'},
      },
      {
        slots: {customRender: 'action'},
      },

    ]
    const errorLogVisible = ref(false)
    const errorLogsLoading = ref(false)
    const viewErrorLog = (history: TriggerStatistic) => {
      errorLogVisible.value = true;
      errorLogsLoading.value=true
      useTriggerStore.getState().getTriggerStatisticLogs({
          trigger_id: history.trigger_id,
          created_from: filterForm.dateRange[0].toDate(),
          created_to: filterForm.dateRange[1].toDate(),
          page: 1,
          page_size: 50
        })
      errorLogsLoading.value=false
      // store.dispatch(TriggerActionTypes.FETCH_TRIGGER_STATISTIC_LOGS, {
      //   payload: {
      //     trigger_id: history.trigger_id,
      //     created_from: filterForm.dateRange[0].toDate(),
      //     created_to: filterForm.dateRange[1].toDate(),
      //     page: 1,
      //     page_size: 50
      //   },callback:(errorLogs:TriggerStatisticErrorLog[])=>{
      //     errorLogsLoading.value=false
      //   }
      // })
    }
    const expandedRowKeys = ref<number[]>([])
    const expandLoading = ref<boolean>(false)

    const list = useTriggerStore()
    const errorLogs = useTriggerStore()
    const pagination = computed(() => {
      return {
        current: filterForm.page ? filterForm.page : 1,
        pageSize: filterForm.page_size ? filterForm.page_size : 20,
        defaultPageSize: 20,
        total: store.getters.getInnerTriggerStatisticMetadata && store.getters.getInnerTriggerStatisticMetadata.record_total ? store.getters.getInnerTriggerStatisticMetadata.record_total : 0,
        pageSizeOptions: ['20', '40', '80', '120'],
        showSizeChanger: true
      }
    })
    const fetchData = () => {
      useTriggerStore.getState().getTriggerStatistics({
        page: filterForm.page,
        page_size: filterForm.page_size,
        created_from: filterForm.dateRange[0].toDate(),
        created_to: filterForm.dateRange[1].toDate()
      })
      // store.dispatch(TriggerActionTypes.FETCH_TRIGGER_STATISTICS,
      //     {
      //       payload: {
      //         page: filterForm.page,
      //         page_size: filterForm.page_size,
      //         created_from: filterForm.dateRange[0].toDate(),
      //         created_to: filterForm.dateRange[1].toDate()
      //       },
      //       callback: (data: TriggerStatistic[]) => {
      //         const find = data.find(x => x.id + '' === route.query.trigger_id + '')
      //         if (route.query.trigger_id && find) {
      //           tableExpand(true, find)
      //         }
      //       }
      //     })
    }
    const charts = reactive({
      chart: {
        type: 'column'
      },
      title: {
        text: ''
      },
      subtitle: {
        text: ''
      },
      xAxis: {
        categories: [
          '2010',
          '2011',
          '2012',
          '2013',
          '2014',
          '2015',
          '2016',
          '2017',
          '2018',
          '2019',
          '2010',
          '2021'
        ],
        crosshair: true
      },
      yAxis: {
        title: {
          useHTML: true,
          text: i18n.t('Quantity')
        }
      },
      tooltip: {
        headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
        pointFormat: '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
            '<td style="padding:0"><b>{point.y}</b></td></tr>',
        footerFormat: '</table>',
        shared: true,
        useHTML: true
      },
      plotOptions: {
        column: {
          pointPadding: 0.2,
          borderWidth: 0,
          colors: ['#87d068', '#f50', '#2db7f5', '#42dbc7']
        }
      },
      series: [{
        color: '#87d068',
        name: i18n.t('SUCCESS'),
        data: [13.93, 13.63, 13.73, 13.67, 14.37, 14.89, 14.56,
          14.32, 14.13, 13.93, 13.21, 12.16]

      }, {
        color: '#f50',
        name: i18n.t('FAILED'),
        data: [12.24, 12.24, 11.95, 12.02, 11.65, 11.96, 11.59,
          11.94, 11.96, 11.59, 11.42, 11.76]

      }, {
        color: '#2db7f5',
        name: i18n.t('SENT'),
        data: [10.00, 9.93, 9.97, 10.01, 10.23, 10.26, 10.00,
          9.12, 9.36, 8.72, 8.38, 8.69]

      }, {
        color: '#42dbc7',
        name: i18n.t('CLICKED'),
        data: [4.35, 4.32, 4.34, 4.39, 4.46, 4.52, 4.58, 4.55,
          4.53, 4.51, 4.49, 4.57]

      }]
    })

    onMounted(() => {
      fetchData()
    })
    const handleTableChange = (pagination: PaginationProps) => {
      if (pagination?.current) {
        filterForm.page = pagination?.current
      }
      if (pagination?.pageSize) {
        filterForm.page_size = pagination?.pageSize
      }
      fetchData()
    }
    const tableExpand = (visible: boolean, record: TriggerStatistic) => {
      if (visible) {
        expandedRowKeys.value = [record.id]
        expandLoading.value = true
        store.dispatch(TriggerActionTypes.FETCH_INNER_TRIGGER_STATISTICS, {
          payload: {
            trigger_id: record.trigger_id,
            page: filterForm.page,
            page_size: 99,
            created_from: filterForm.dateRange[0].toDate(),
            created_to: filterForm.dateRange[1].toDate()
          },
          callback: (data: TriggerStatistic[]) => {

            expandLoading.value = false
            if (data.length > 0) {
              charts.xAxis.categories = data.map((item: TriggerStatistic): string => {
                return MixUtils.methods.dateDisplayFormatFromString(item.created)
              })
              charts.series[0].data = data.map((item: TriggerStatistic): number => {
                return item.success
              })
              charts.series[1].data = data.map((item: TriggerStatistic): number => {
                return item.failed
              })
              charts.series[2].data = data.map((item: TriggerStatistic): number => {
                return item.sent
              })
              charts.series[3].data = data.map((item: TriggerStatistic): number => {
                return item.clicked
              })
            } else {
              charts.xAxis.categories = []
              charts.series[0].data = []
              charts.series[1].data = []
              charts.series[2].data = []
              charts.series[3].data = []
            }

          }
        })
      } else {
        expandedRowKeys.value = []
      }
      setTimeout(() => {
        const elements = document.getElementsByClassName("highcharts-credits")
        if (elements && elements.length) {
          for (let i = 0; i < elements.length; i++) {
            if (elements[i]) {
              elements[i].innerHTML = ""
            }
          }
        }
      }, 1200)
    }
    const filterForm = reactive<Filter>({
      trigger_id: 0,
      dateRange: [
        dayjs().subtract(1, 'months'),
        dayjs()
      ], page: 1, page_size: 20
    })
    const dateRangeChange = () => {
      fetchData()
    }
    return {
      columns,
      filterForm,
      dateRangeChange,
      pagination,
      handleTableChange,
      list,
      charts,
      tableExpand,
      expandedRowKeys,
      expandLoading,
      viewErrorLog,
      errorLogVisible,
      errorLogs,errorLogsLoading
    }
  }
})
</script>

<style scoped lang="scss">
.trigger-history-container {
  margin-top: 14px;
  //padding-left: 10%;
  //padding-right: 10%;

  .header-horizontal {
    display: flex;
    margin-bottom: 14px;
  }
}

.table-row-name {
  font-weight: bold;
}

.item-trigger-table-small {
  font-size: 13px;
}
.table {
  margin: 16px
}
.title-header {
  font-weight: bold;
  flex: 1;
}
.container-title{
  display: flex;
  flex-direction: row;
  padding: 16px 24px 16px 24px;
  margin-bottom: 25px;
  background-color: white
}
.input-search{
  width: 250px;
  margin-right: 16px;
}


</style>
