import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode, createBlock as _createBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-1d0c9b52"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "container"
}
const _hoisted_2 = { style: {"margin":"12px"} }
const _hoisted_3 = { class: "table-header" }
const _hoisted_4 = { class: "table-title" }
const _hoisted_5 = ["onClick"]
const _hoisted_6 = { class: "item-trigger-table-small" }
const _hoisted_7 = { class: "item-trigger-table-small" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_menu_item = _resolveComponent("a-menu-item")!
  const _component_a_tooltip = _resolveComponent("a-tooltip")!
  const _component_a_menu = _resolveComponent("a-menu")!
  const _component_a_layout_sider = _resolveComponent("a-layout-sider")!
  const _component_a_input_search = _resolveComponent("a-input-search")!
  const _component_a_tag = _resolveComponent("a-tag")!
  const _component_a_select_option = _resolveComponent("a-select-option")!
  const _component_a_select = _resolveComponent("a-select")!
  const _component_a_typography_text = _resolveComponent("a-typography-text")!
  const _component_plus_outlined = _resolveComponent("plus-outlined")!
  const _component_a_button = _resolveComponent("a-button")!
  const _component_eye_outlined = _resolveComponent("eye-outlined")!
  const _component_edit_outlined = _resolveComponent("edit-outlined")!
  const _component_stop_outlined = _resolveComponent("stop-outlined")!
  const _component_aim_outlined = _resolveComponent("aim-outlined")!
  const _component_history_outlined = _resolveComponent("history-outlined")!
  const _component_delete_outlined = _resolveComponent("delete-outlined")!
  const _component_a_popconfirm = _resolveComponent("a-popconfirm")!
  const _component_a_dropdown = _resolveComponent("a-dropdown")!
  const _component_a_table = _resolveComponent("a-table")!
  const _component_a_layout_content = _resolveComponent("a-layout-content")!
  const _component_a_layout = _resolveComponent("a-layout")!

  return (_ctx.version!=='new')
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("h3", null, _toDisplayString(_ctx.$t(_ctx.$route.name)), 1)
        ]),
        _createVNode(_component_a_layout, { theme: "light" }, {
          default: _withCtx(() => [
            _createVNode(_component_a_layout_sider, { theme: "light" }, {
              default: _withCtx(() => [
                _createVNode(_component_a_menu, {
                  mode: "inline",
                  theme: "light",
                  selectedKeys: _ctx.selectedTrigger,
                  "onUpdate:selectedKeys": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.selectedTrigger) = $event))
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_a_menu_item, {
                      key: "all",
                      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.selectTriggerType('all')))
                    }, {
                      default: _withCtx(() => [
                        _createElementVNode("span", null, _toDisplayString(_ctx.$t('all')), 1)
                      ]),
                      _: 1
                    }),
                    (_ctx.getFlowConstants&&_ctx.getFlowConstants.trigger_types)
                      ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(_ctx.getFlowConstants.trigger_types, (key) => {
                          return (_openBlock(), _createBlock(_component_a_menu_item, {
                            key: key,
                            onClick: ($event: any) => (_ctx.selectTriggerType(key))
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_a_tooltip, {
                                title: _ctx.$t(key)
                              }, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(_ctx.$t(key)), 1)
                                ]),
                                _: 2
                              }, 1032, ["title"])
                            ]),
                            _: 2
                          }, 1032, ["onClick"]))
                        }), 128))
                      : _createCommentVNode("", true)
                  ]),
                  _: 1
                }, 8, ["selectedKeys"])
              ]),
              _: 1
            }),
            _createVNode(_component_a_layout, { theme: "light" }, {
              default: _withCtx(() => [
                _createVNode(_component_a_layout_content, null, {
                  default: _withCtx(() => [
                    _createElementVNode("div", _hoisted_3, [
                      _createElementVNode("div", _hoisted_4, [
                        _createVNode(_component_a_input_search, {
                          class: "input-search",
                          placeholder: _ctx.$t('SEARCH'),
                          value: _ctx.searchInput,
                          "onUpdate:value": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.searchInput) = $event)),
                          onChange: _ctx.onSearchChange,
                          style: {"width":"220px","margin-left":"12px"}
                        }, null, 8, ["placeholder", "value", "onChange"]),
                        _createVNode(_component_a_select, {
                          showSearch: "",
                          class: "input-search",
                          allowClear: "",
                          mode: "multiple",
                          "v-mode:value": _ctx.selectedTags,
                          placeholder: _ctx.$t('TAGS'),
                          style: {"min-width":"220px","margin-left":"12px"},
                          onChange: _ctx.handleChangeTags
                        }, {
                          default: _withCtx(() => [
                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.getTriggerTags, (item) => {
                              return (_openBlock(), _createBlock(_component_a_select_option, {
                                key: item.id,
                                class: "input-search",
                                value: item.id
                              }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_a_tag, {
                                    color: item.color
                                  }, {
                                    default: _withCtx(() => [
                                      _createTextVNode(_toDisplayString(item.name), 1)
                                    ]),
                                    _: 2
                                  }, 1032, ["color"])
                                ]),
                                _: 2
                              }, 1032, ["value"]))
                            }), 128))
                          ]),
                          _: 1
                        }, 8, ["v-mode:value", "placeholder", "onChange"]),
                        _createVNode(_component_a_select, {
                          showSearch: "",
                          allowClear: "",
                          class: "input-search",
                          placeholder: _ctx.$t('status'),
                          style: {"width":"220px","margin-left":"12px"},
                          onChange: _ctx.handleChangeStatus
                        }, {
                          default: _withCtx(() => [
                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.getFlowConstants.trigger_status, (item) => {
                              return (_openBlock(), _createBlock(_component_a_select_option, {
                                key: item,
                                value: item
                              }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_a_typography_text, null, {
                                    default: _withCtx(() => [
                                      _createTextVNode(_toDisplayString(_ctx.$t(item)), 1)
                                    ]),
                                    _: 2
                                  }, 1024)
                                ]),
                                _: 2
                              }, 1032, ["value"]))
                            }), 128))
                          ]),
                          _: 1
                        }, 8, ["placeholder", "onChange"])
                      ]),
                      (_ctx.getMyRole(_ctx.MODULES.trigger)?.is_create)
                        ? (_openBlock(), _createBlock(_component_a_button, {
                            key: 0,
                            type: "primary",
                            onClick: _ctx.newTrigger
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_plus_outlined),
                              _createTextVNode(" " + _toDisplayString(_ctx.$t("TRIGGER_SETTING")), 1)
                            ]),
                            _: 1
                          }, 8, ["onClick"]))
                        : _createCommentVNode("", true)
                    ]),
                    _createVNode(_component_a_table, {
                      size: "middle",
                      rowKey: record => record.id,
                      columns: _ctx.columns,
                      "data-source": _ctx.getTriggers,
                      pagination: _ctx.pagination,
                      onChange: _ctx.handleTableChange,
                      class: "flow-table"
                    }, {
                      name: _withCtx(({ record }) => [
                        _createElementVNode("a", {
                          class: "table-row-name",
                          type: "text",
                          onClick: ($event: any) => (_ctx.customRowClick(record))
                        }, _toDisplayString(record.name), 9, _hoisted_5)
                      ]),
                      created_at: _withCtx(({ text }) => [
                        _createElementVNode("span", _hoisted_6, _toDisplayString(_ctx.dateTimeDisplayFormatFromString(text)), 1)
                      ]),
                      creator: _withCtx(({ text }) => [
                        _createElementVNode("span", null, _toDisplayString(_ctx.getEmployeeName(text)), 1)
                      ]),
                      type: _withCtx(({ text }) => [
                        _createElementVNode("span", _hoisted_7, _toDisplayString(_ctx.$t(text)), 1)
                      ]),
                      status: _withCtx(({ text }) => [
                        _createElementVNode("span", null, [
                          _createVNode(_component_a_tag, {
                            color: _ctx.getTriggerStatusColor(text)
                          }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(text), 1)
                            ]),
                            _: 2
                          }, 1032, ["color"])
                        ])
                      ]),
                      tags: _withCtx(({ record }) => [
                        _createElementVNode("span", null, [
                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(record.tags, (tag) => {
                            return (_openBlock(), _createBlock(_component_a_tag, {
                              key: tag.id+'_'+tag.name,
                              color: tag.color
                            }, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(tag.name), 1)
                              ]),
                              _: 2
                            }, 1032, ["color"]))
                          }), 128))
                        ])
                      ]),
                      action: _withCtx(({record}) => [
                        _createVNode(_component_a_dropdown, null, {
                          overlay: _withCtx(() => [
                            _createVNode(_component_a_menu, null, {
                              default: _withCtx(() => [
                                (_ctx.getMyRole(_ctx.MODULES.trigger)?.is_edit)
                                  ? (_openBlock(), _createBlock(_component_a_menu_item, {
                                      key: 0,
                                      onClick: ($event: any) => (_ctx.customRowClick(record))
                                    }, {
                                      default: _withCtx(() => [
                                        _createVNode(_component_edit_outlined),
                                        _createTextVNode(" " + _toDisplayString(_ctx.$t('EDIT')), 1)
                                      ]),
                                      _: 2
                                    }, 1032, ["onClick"]))
                                  : _createCommentVNode("", true),
                                (record.status==='running' && _ctx.getMyRole(_ctx.MODULES.trigger)?.is_edit)
                                  ? (_openBlock(), _createBlock(_component_a_menu_item, {
                                      key: 1,
                                      onClick: ($event: any) => (_ctx.stopTriggerModal(record))
                                    }, {
                                      default: _withCtx(() => [
                                        _createVNode(_component_stop_outlined),
                                        _createTextVNode(" " + _toDisplayString(_ctx.$t('STOP_TRIGGER')), 1)
                                      ]),
                                      _: 2
                                    }, 1032, ["onClick"]))
                                  : _createCommentVNode("", true),
                                (record.status!=='running' && _ctx.getMyRole(_ctx.MODULES.trigger)?.is_edit)
                                  ? (_openBlock(), _createBlock(_component_a_menu_item, {
                                      key: 2,
                                      onClick: ($event: any) => (_ctx.activateTriggerModal(record))
                                    }, {
                                      default: _withCtx(() => [
                                        _createVNode(_component_aim_outlined),
                                        _createTextVNode(" " + _toDisplayString(_ctx.$t('Activate_trigger')), 1)
                                      ]),
                                      _: 2
                                    }, 1032, ["onClick"]))
                                  : _createCommentVNode("", true),
                                (_ctx.getMyRole(_ctx.MODULES.trigger)?.is_edit)
                                  ? (_openBlock(), _createBlock(_component_a_menu_item, {
                                      key: 3,
                                      onClick: ($event: any) => (_ctx.editFlow(record))
                                    }, {
                                      default: _withCtx(() => [
                                        _createVNode(_component_edit_outlined),
                                        _createTextVNode(" " + _toDisplayString(_ctx.$t('EDIT_FLOW')), 1)
                                      ]),
                                      _: 2
                                    }, 1032, ["onClick"]))
                                  : _createCommentVNode("", true),
                                (_ctx.getMyRole(_ctx.MODULES.trigger_history)?.is_edit)
                                  ? (_openBlock(), _createBlock(_component_a_menu_item, {
                                      key: 4,
                                      onClick: ($event: any) => (_ctx.goHistory(record))
                                    }, {
                                      default: _withCtx(() => [
                                        _createVNode(_component_history_outlined),
                                        _createTextVNode(" " + _toDisplayString(_ctx.$t('HISTORY')), 1)
                                      ]),
                                      _: 2
                                    }, 1032, ["onClick"]))
                                  : _createCommentVNode("", true),
                                (_ctx.getMyRole(_ctx.MODULES.trigger)?.is_delete)
                                  ? (_openBlock(), _createBlock(_component_a_menu_item, { key: 5 }, {
                                      default: _withCtx(() => [
                                        _createVNode(_component_a_popconfirm, {
                                          title: _ctx.$t('TRIGGER_CONFIRM_DELETE_CONTEXT'),
                                          "ok-text": _ctx.$t('YES'),
                                          "cancel-text": _ctx.$t('NO'),
                                          onConfirm: ($event: any) => (_ctx.deleteTrigger(record))
                                        }, {
                                          default: _withCtx(() => [
                                            _createVNode(_component_delete_outlined),
                                            _createTextVNode(" " + _toDisplayString(_ctx.$t('DELETE')), 1)
                                          ]),
                                          _: 2
                                        }, 1032, ["title", "ok-text", "cancel-text", "onConfirm"])
                                      ]),
                                      _: 2
                                    }, 1024))
                                  : _createCommentVNode("", true)
                              ]),
                              _: 2
                            }, 1024)
                          ]),
                          default: _withCtx(() => [
                            _createVNode(_component_a_button, { type: "text" }, {
                              default: _withCtx(() => [
                                _createVNode(_component_eye_outlined)
                              ]),
                              _: 1
                            })
                          ]),
                          _: 2
                        }, 1024)
                      ]),
                      _: 1
                    }, 8, ["rowKey", "columns", "data-source", "pagination", "onChange"])
                  ]),
                  _: 1
                })
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ]))
    : _createCommentVNode("", true)
}