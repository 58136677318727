<template>
  <a-card>
  <div class="current-service-pack-container">
    <h3>
      {{ $t('PAYMENT') }}
    </h3>
  </div>
  </a-card>
  <div class="body-upgrade-service-pack">

  </div>
</template>

<script lang="ts">
import {computed, defineComponent, inject, onMounted, onUnmounted, reactive, ref, watch} from "vue";
import FlagSVG from '@/assets/icons/flag-svgrepo-com.svg';
import {message, notification} from "ant-design-vue";
import APIs from "@/APIs/APIs";
import {useStore} from "@/store";

import {create, all} from 'mathjs'
import {useRoute, useRouter} from "vue-router";
import {useI18n} from "vue-i18n";

const config = {}
const math = create(all, config)

export default defineComponent({
  name: "Payment",
  components: {
  },
  created() {
    console.log('query', this.$route.query)
    try{
      if(this.$route.query.vnp_OrderInfo){
        console.log(this.$route.query.vnp_OrderInfo)
        this.confirmVNPay({
          vnp_Amount: Number(this.$route.query.vnp_Amount),
          vnp_BankCode: this.$route.query.vnp_BankCode,
          vnp_BankTranNo: Number(this.$route.query.vnp_BankTranNo),
          vnp_CardType: this.$route.query.vnp_CardType,
          vnp_OrderInfo: this.$route.query.vnp_OrderInfo,
          vnp_PayDate: this.$route.query.vnp_PayDate,
          vnp_ResponseCode: this.$route.query.vnp_ResponseCode,
          vnp_TmnCode: this.$route.query.vnp_TmnCode,
          vnp_TransactionNo: this.$route.query.vnp_TransactionNo,
          vnp_TxnRef: Number(this.$route.query.vnp_TxnRef),
          vnp_SecureHashType: this.$route.query.vnp_SecureHashType,
          vnp_SecureHash: this.$route.query.vnp_SecureHash,
        })
      }
    }catch (e) {
      console.log(e)
    }
  },
  setup() {
    const store = useStore()
    const i18n = useI18n()
    const route = useRoute()


    const confirmVNPay = async (params: any) => {
      message.loading('')
      const response = await APIs.init().confirmPaymentVNPay(params)
      message.destroy()
      if (response.status === 200 && response.data?.status === 200) {
        notification.warning({
          duration: 2,
          message: i18n.t('NOTIFICATION'),
          description: i18n.t('BUY_SUCCESS')
        });
        window.location.href = response.data.data

      } else {
        notification.warning({
          duration: 2,
          message: i18n.t('NOTIFICATION'),
          description: response.data?.message
        });
      }
    }


    return {
      confirmVNPay,
      FlagSVG: FlagSVG,
      labelCol: {span: 8},
      wrapperCol: {span: 15},
      wrapperCol2: {span: 15},
    }
  }
})
</script>

<style scoped lang="scss">
.body-upgrade-service-pack {
  margin-top: 16px;
  .header-title-upgrade-service-pack {
    h1 {
      text-align: center;
      font-size: 28px;
    }
  }

  /* taeb-switch styles */

  *,
  *:after,
  *:before {
    box-sizing: inherit;
  }

  * {
    outline: 0;
  }


  .wrapper {
    border-radius: 37px;
    //background-color: #f4f4f4;
    background-color: #ffffff;
    padding: 8px;
    width: 100%;
    max-width: 370px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 15px;

    .text-center {
      text-align: center;
    }

    .taeb-switch {
      position: relative;
    }

    .taeb-switch:after {
      content: "";
      position: absolute;
      width: 50%;
      top: 0;
      transition: left cubic-bezier(.88, -.35, .565, 1.35) .4s;
      border-radius: 27.5px;
      box-shadow: 0 2px 15px 0 rgba(0, 0, 0, .1);
      background-color: #FAB216;
      height: 100%;
      z-index: 0;
    }

    .taeb-switch.left:after {
      left: 0;
    }

    .taeb-switch.right:after {
      left: 50%;
    }

    .taeb-switch .taeb {
      display: inline-block;
      width: 50%;
      padding: 12px 0;
      z-index: 1;
      position: relative;
      cursor: pointer;
      transition: color 200ms;
      font-size: 16px;
      font-weight: bold;
      line-height: normal;
      user-select: none;
    }

    .taeb-switch .taeb.active {
      color: #ffffff;
    }

  }

  .card-pricing-container {
    display: flex;
    flex-wrap: wrap;
    margin-top: 20px;
    justify-content: center;

    .card-pricing {
      background-color: white;
      padding: 16px;
      margin: 16px;
      border-radius: 16px;
      max-width: 350px;
      box-shadow: 0 14px 26px rgba(0, 0, 0, 0.04);
      transition: 200ms linear;
      border-width: 0px;

      .price-show {
        font-size: 24px;
        font-weight: bold;
      }

      .month-show {
        color: #9c9c9c;
        font-size: 16px;
      }

      .title-price {
        font-size: 24px;
      }

      .line-function-list-item {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-top: 4px;

        .icon-checked {
          color: #FAB216;
        }

        .sub-list-item {
          padding-left: 10px;
        }
      }

      .upgrade-button {
        padding: 12px 20px;
        border: #FAB216 solid 1px;
        border-radius: 30px;
        margin: 16px 20px 10px;
        text-align: center;
        background-color: white;
        cursor: pointer;
        color: rgba(102, 102, 102, 0.99);
      }


    }

    .card-pricing-active {
      background-color: #FAB216;
      padding: 16px;
      border-radius: 16px;
      max-width: 350px;
      margin: 16px;
      box-shadow: 0 14px 26px rgba(0, 0, 0, 0.04);
      transition: 200ms linear;
      border-width: 0px;
      color: white;

      .price-show {
        font-size: 24px;
        font-weight: bold;
      }

      .month-show {
        color: white;
        font-size: 16px;

      }

      .title-price {
        font-size: 24px;
        color: white;
      }

      .line-function-list-item {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-top: 4px;

        .icon-checked {
          color: white;
        }

        .sub-list-item {
          padding-left: 10px;
        }
      }

      .upgrade-button {
        visibility: hidden;
        padding: 12px 20px;
        border: #FAB216 solid 1px;
        border-radius: 30px;
        margin: 16px 20px 10px;
        text-align: center;
        cursor: pointer;
        color: white;
      }

    }


    .card-pricing:hover {
      transform: translateY(-5px) scale(1.015) translateZ(0);
      box-shadow: 0 24px 36px rgba(0, 0, 0, 0.11),
      0 24px 46px var(--box-shadow-color);
      background-color: #FAB216;
      color: white;

      .month-show {
        color: white;
      }

      h1 {
        color: white;
      }

      .icon-checked {
        color: white;
      }

      .upgrade-button {
        font-weight: bold;
      }
    }
  }

}

.current-service-pack-container {
  .current-service-pack-header {
    .current-service-pack {
      padding: 20px;

      .icon-common {
        width: 18px;
        height: 18px;
      }
    }
  }
}


.build-service-pack {
  margin-left: 16px;
  margin-right: 16px;
  margin-top: 38px;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;

  @media only screen and (min-width: 768px) {
    /* For desktop: */
    .container-card-building-pack {
      width: 100%;
    }
    .card-building-pack {
      width: 50%;
    }
    .card-billing {
      width: 50%;
    }
    .payment-card {
      width: 100%;
      margin-top: 16px;
    }
  }

  @media only screen and (max-width: 500px) {
    .card-building-pack {
      width: 100%;
    }
    .card-billing {
      width: 100%;
    }
    .payment-card {
      width: 100%;
      margin-top: 16px;
    }
  }

  .icon-common-build-info {
    width: 16px;
    height: 16px;
  }

  .row-2-margin {
    margin-top: 14px;
  }

  .flex-col-billing {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .col-label {
      margin-left: 5px;
    }

    .col-value {
      font-weight: bold;
    }

    .promotion-value {
      color: #1890ff;
    }

    .total-amount {
      color: #f31717;
    }
  }

  .bank-information {

    h3 {
      text-align: center;
    }

    .detail-of-payment {
      font-style: italic;
    }
  }
}


</style>
