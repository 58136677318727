import {Overview, OverviewFB, OverviewITG, OverviewReport, OverviewZalo} from "@/models/Overview/Overview";

export type State = {
    data: Overview[]|undefined
    dataFb: OverviewFB[]|undefined
    dataItg: OverviewITG[]|undefined
    dataZalo: OverviewZalo[]|undefined
    dataReport: OverviewReport | undefined
    loading:boolean
}
export const state:State ={
    data: [],
    dataFb: [],
    dataItg: [],
    dataZalo: [],
    dataReport:{
        took: 0,
        timed_out: true,
        _shards: {
            total: 0,
            successful: 0,
            failed: 0
        },
        hits: {
            total: 0,
            max_score: 0,
            hits: []
        },
        aggregations: {
            current: {
                doc_count: 0,
                date_time: {
                    buckets: [
                        {
                            key: 0,
                            doc_count: 0,
                            conversation_count: {
                                value: 0
                            }
                        },
                        {
                            key: 0,
                            doc_count: 0,
                            conversation_count: {
                                value: 0
                            }
                        },
                        {
                            key: 0,
                            doc_count: 0,
                            conversation_count: {
                                value: 0
                            }
                        },
                        {
                            key: 0,
                            doc_count: 0,
                            conversation_count: {
                                value: 7
                            }
                        },
                        {
                            key: 0,
                            doc_count: 0,
                            conversation_count: {
                                value: 0
                            }
                        },
                        {
                            key: 0,
                            doc_count: 0,
                            conversation_count: {
                                value: 0
                            }
                        },
                        {
                            key: 0,
                            doc_count: 0,
                            conversation_count: {
                                value: 0
                            }
                        },
                        {
                            key: 0,
                            doc_count: 0,
                            conversation_count: {
                                value: 0
                            }
                        },
                        {
                            key: 0,
                            doc_count: 0,
                            conversation_count: {
                                value: 0
                            }
                        }
                    ]
                },
                sum_conversation: {
                    value: 0
                }
            },
            current_by_phone: {
                doc_count: 0,
                has_phone: {
                    doc_count: 0,
                    date_time: {
                        buckets: [
                            {
                                key: 0,
                                doc_count: 0,
                                conversation_count: {
                                    value: 0
                                }
                            },
                            {
                                key: 0,
                                doc_count: 0,
                                conversation_count: {
                                    value: 0
                                }
                            },
                            {
                                key: 0,
                                doc_count: 0,
                                conversation_count: {
                                    value: 0
                                }
                            },
                            {
                                key: 0,
                                doc_count: 0,
                                conversation_count: {
                                    value: 0
                                }
                            },
                            {
                                key: 0,
                                doc_count: 0,
                                conversation_count: {
                                    value: 0
                                }
                            },
                            {
                                key: 0,
                                doc_count: 0,
                                conversation_count: {
                                    value: 0
                                }
                            },
                            {
                                key: 0,
                                doc_count: 0,
                                conversation_count: {
                                    value: 0
                                }
                            },
                            {
                                key: 0,
                                doc_count: 0,
                                conversation_count: {
                                    value: 0
                                }
                            }
                        ]
                    },
                    sum_conversation: {
                        value: 0
                    }
                }
            }
        }
    },
    loading: true
}
