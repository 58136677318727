import {ActionContext, ActionTree} from "vuex";
import {State} from "./states";
import {CallBackPayload, RootState} from "@/store";
import APIs from "@/APIs/APIs";
import {Mutations} from "./mutations";
import {TagsResponse, TreeTagsResponse} from "@/APIs/response/S_HttpResponse";
import {TagsActionTypes} from "@/store/modules/tag/action-types";
import {TagsMutationTypes} from "@/store/modules/tag/mutation-types";
import {TagsRequest} from "@/APIs/request/TagRequest";
import {TypeTag} from "@/models/Tags/Tags";

type AugmentedActionContext = {
    commit<K extends keyof Mutations>(
        key: K,
        payload: Parameters<Mutations[K]>[1],
    ): ReturnType<Mutations[K]>;
} & Omit<ActionContext<State, RootState>, 'commit'>

export interface Actions {
    [TagsActionTypes.FETCH_TAGS](
        {commit}: AugmentedActionContext,
        payload: TagsRequest
    ): Promise<TagsResponse | undefined>;

    [TagsActionTypes.FETCH_ROOT_TAGS](
        {commit}: AugmentedActionContext,
        payload: TagsRequest
    ): Promise<TagsResponse | undefined>;

    [TagsActionTypes.FETCH_TREE_TAGS](
        {commit}: AugmentedActionContext,
        payload: CallBackPayload<TagsRequest, TreeTagsResponse>
    ): Promise<void>;
}

export const actions: ActionTree<State, RootState> & Actions = {
    async [TagsActionTypes.FETCH_TAGS]({commit}, payload: TagsRequest): Promise<TagsResponse | undefined> {
        const response = await APIs.init().getTags(payload)
        if (response.status === 200) {
            switch (payload.type) {
                case TypeTag.FLOW_TAGS:
                    commit(TagsMutationTypes.SET_flowTags, response.data?.data)
                    break
                case TypeTag.CUSTOMER_TAGS:
                    commit(TagsMutationTypes.SET_CustomerTags, response.data?.data)
                    break
                case TypeTag.TRIGGER_TAGS:
                    commit(TagsMutationTypes.SET_TriggerTags, response.data?.data)
                    break
                case TypeTag.VIRAL_TAGS:
                    commit(TagsMutationTypes.SET_ViralTags, response.data?.data)
                    break
                case TypeTag.PRODUCT_TAGS:
                    commit(TagsMutationTypes.SET_ProductTags, response.data?.data)
                    break
            }
        }
        return response.data;
    },
    async [TagsActionTypes.FETCH_ROOT_TAGS]({commit}, payload: TagsRequest): Promise<TagsResponse | undefined> {
        const response = await APIs.init().getRootTags(payload)
        if (response.status === 200) {
            switch (payload.type) {
                case TypeTag.FLOW_TAGS:
                    commit(TagsMutationTypes.SET_flowTags, response.data?.data)
                    break
                case TypeTag.CUSTOMER_TAGS:
                    commit(TagsMutationTypes.SET_CustomerTags, response.data?.data)
                    break
                case TypeTag.TRIGGER_TAGS:
                    commit(TagsMutationTypes.SET_TriggerTags, response.data?.data)
                    break
                case TypeTag.VIRAL_TAGS:
                    commit(TagsMutationTypes.SET_ViralTags, response.data?.data)
                    break
                case TypeTag.PRODUCT_TAGS:
                    commit(TagsMutationTypes.SET_ProductTags, response.data?.data)
                    break
            }
        }
        return response.data;
    },
    async [TagsActionTypes.FETCH_TREE_TAGS]({commit}, payload: CallBackPayload<TagsRequest, TreeTagsResponse>): Promise<void> {
        if (payload.payload) {
            const response = await APIs.init().getTreeTags(payload.payload)
            if (response.status === 200 && response.data && response.data.data) {
                switch (payload.payload.type) {
                    case TypeTag.FLOW_TAGS:
                        commit(TagsMutationTypes.SET_flowTags, response.data?.data)
                        break
                    case TypeTag.CUSTOMER_TAGS:
                        commit(TagsMutationTypes.SET_TREE_TAG, response.data?.data)
                        break
                    case TypeTag.TRIGGER_TAGS:
                        commit(TagsMutationTypes.SET_TriggerTags, response.data?.data)
                        break
                    case TypeTag.VIRAL_TAGS:
                        commit(TagsMutationTypes.SET_ViralTags, response.data?.data)
                        break
                    case TypeTag.PRODUCT_TAGS:
                        commit(TagsMutationTypes.SET_ProductTags, response.data?.data)
                        break
                }
            }else {
                commit(TagsMutationTypes.SET_TREE_TAG, [])
            }
            if (payload.callback) {
                payload.callback(response.data)
            }

        }

    }
}
