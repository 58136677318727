<template>
  <div class="container-title">
    <h3 class="title-header">{{$t($route.name)}}</h3>

    <a-button
        v-if="getMyRole?.is_create"
        type="primary" class="button-add-product" @click="create">
      <plus-outlined/>
      {{$t('ADD')}}
    </a-button>
  </div>
  <div style="height: 80vh;">
    <VueFlow v-model="elements">
      <Controls/>
    </VueFlow>
  </div>
  <a-modal v-model:visible="modalVisible" :title="formState.id?$t('UPDATE_DEPARTMENT'):$t('ADD_DEPARTMENT')"
           :footer="null"
           style="width: 40%"
  >
    <a-form
        ref="formRef"
        :model="formState"
        :rules="rules"
        :label-col="labelCol"
        :wrapper-col="wrapperCol"
    >
      <a-form-item :label="$t('NAME')" name="name">
        <a-input v-model:value="formState.name"/>
      </a-form-item>
      <a-form-item  :label="$t('DESCRIPTION')" name="description">
        <a-input v-model:value="formState.description"/>
      </a-form-item>
      <a-form-item :label="$t('DEPARTMENT')" name="department">
        <a-select
            :placeholder="$t('DEPARTMENT')"
            :options="departments"
            v-model:value="formState.parent_id"
            @change="handleChange"
        ></a-select>
      </a-form-item>
      <a-form-item :wrapper-col="{ offset: 8 }">
        <a-button type="primary" @click="onSubmit">        {{formState.id?$t('UPDATE'):$t('CREATE')}}</a-button>
        <a-popconfirm
            v-if="getMyRole?.is_delete && formState.id"
            placement="bottom" :ok-text="$t('OK')" :cancel-text="$t('CANCEL')" @confirm="confirmDelete">
          <a-button
              danger
              style="margin-left: 10px"  >        {{$t('DELETE')}}
          </a-button>
        </a-popconfirm>
      </a-form-item>
    </a-form>
  </a-modal>
</template>

<script lang="ts">
import { VueFlow, useVueFlow } from "@vue-flow/core";
import {computed, defineComponent, onBeforeMount, onMounted, reactive, ref, UnwrapRef} from "vue";

import {useRoute} from "vue-router";
import {Departments} from "@/models/Departments/Departments";
import {useI18n} from "vue-i18n";
import {Constant} from "@/utils/Constants";
import { useStore } from "@/store";
import {message} from "ant-design-vue";
import APIs from "@/APIs/APIs";
import {Careers} from "@/models/Customers/Customers";
import {MixUtils} from "@/utils/Utils";
import { MarkerType } from '@vue-flow/core'
import { Controls } from '@vue-flow/controls'


export default defineComponent({
  name: "DepartmentsFlow",
  components:{
    Controls,
    VueFlow,
  },
  setup(){
    const formRef = ref();

    const router = useRoute()
    const store = useStore()
    const i18n = useI18n()
    const formState: UnwrapRef<Departments> = reactive({
      id: undefined,
      name: '',
      description: '',
    });
    const elements = ref<any[]>([])
    const departments = ref<Departments[]>([]);

    const {
      nodesDraggable,
      nodesConnectable,
      elementsSelectable,
      zoomOnScroll,
      zoomOnDoubleClick,
      zoomOnPinch,
      panOnScroll,
      panOnScrollMode,
      panOnDrag,
      onConnect,
      onNodeDragStop,
      onPaneClick,
      onPaneScroll,
      onPaneContextMenu,
      onNodeDragStart,
      onMoveEnd,
      addEdges,
    } = useVueFlow()
    nodesDraggable.value = true

    onConnect((params) => addEdges([params]))
    onNodeDragStop((e: any) => {
      console.log('drag stop', e.node)
      if(getMyRole.value.is_edit){
        // modalVisible.value = true
        formState.id = parseInt(e.node.id)
        formState.parent_id = e.node.parent_id
        formState.name = e.node.name
        formState.description = e.node.description
        modalVisible.value = true

        APIs.init().updateDepartment({
          id: parseInt(e.node.id),
          pos_x: e.node.position.x,
          name: e.node.name,
          pos_y: e.node.position.y,
        }).then(res => {
          console.log(res)
        })
      }
    })

    const rules = {
      name: [
        {required: true, message: i18n.t('PLACEHOLDER_INPUT_NAME'), trigger: 'blur'},
      ],
    };


    const fetchDepartments=()=>{
      message.loading('');

      APIs.init().getDepartments().then(res => {
        if (res.status === 200 && res.data?.status === 200) {
          departments.value = res.data.data?res.data.data.map(item => ({...item,...{label: item.name, value: item.id}})):[]

          elements.value = departments.value.map(d=>{
            return {...d, ...{id: d.id+'', label: d.name, position: {x: d.pos_x, y: d.pos_y}}}
          })
          for (let i = 0; i < departments.value.length; i++) {
            if(departments.value[i].parent_id!==0){
              elements.value.push(
                  { id: MixUtils.methods.getUUID(), source: departments.value[i].parent_id+"", target: departments.value[i].id+"", markerEnd: MarkerType.ArrowClosed, type: 'smoothstep', },
              )
            }
          }
        } else {
          message.destroy()
        }
      })
      message.destroy()
    }




    //
    // // Nodes
    // // An input node, specified by using `type: 'input'`
    // { id: '1', type: 'input', label: 'Node 1', position: { x: 250, y: 5 } },
    //
    // // Default nodes, you can omit `type: 'default'`
    // { id: '2', label: 'Node 2', position: { x: 100, y: 100 }, },
    // { id: '3', label: 'Node 3', position: { x: 400, y: 100 } },
    //
    //
    // // Edges
    // // Most basic edge, only consists of an id, source-id and target-id
    // { id: 'e1-3', source: '1', target: '3' },
    //
    // // An animated edge
    // { id: 'e1-2', source: '1', target: '2'},


    const modalVisible = ref(false)



    const getUserInfo = computed(() => store.getters.getUserInfo)

    const getMyRole = computed(()=>{
      if(!getUserInfo.value?.id_cms_privileges || getUserInfo.value?.id_cms_privileges<11){
        return {
          is_visible: 1,
          is_create:1,
          is_read: 1,
          is_edit: 1,
          is_delete: 1,
          id: 1
        }
      }else{
        return store.getters.getRole(Constant.MODULES.departments);
      }

    })

    onMounted(()=>{
      fetchDepartments()
    })

    const edit = (career: Careers)=>{
      formState.id = career.id
      formState.name = career.name
      formState.description = career.description
      modalVisible.value = true
    }


    const create = () => {
      formState.id = 0
      formState.name = ''
      formState.description = ''
      formState.parent_id = undefined
      modalVisible.value = true
    };

    const handleChange = (value: any) => {
      console.log(value)
      // formState.parent_id = value
    };

    const createDepartment=()=>{

      message.loading('');

      APIs.init().createDepartment(formState).then(res => {
        if (res.status === 200 && res.data?.status === 200) {
          modalVisible.value = false
          message.success({content: 'Created department: ' + res.data.data.name});
          fetchDepartments()
        } else {
          message.destroy()
        }
      }).catch(e => {
        message.destroy()
        console.error(e)
      })
    }
    const updateDepartment=()=>{
      message.loading('');
      APIs.init().updateDepartment(formState).then(res => {
        if (res.status === 200 && res.data?.status === 200) {
          modalVisible.value = false
          message.success({content: 'Updated department : ' + res.data.data.name});
          fetchDepartments()
        } else {
          message.destroy()
        }
      }).catch(e => {
        message.destroy()
        console.error(e)
      })
    }

    const onSubmit = ()=>{
      console.log('submit')
      formRef.value
          .validate()
          .then(() => {
            if(formState.id){
              updateDepartment()
            }else{
              createDepartment()

            }
          })
          .catch((error: any) => {
            console.log('error', error);
          });

    }

    const confirmDelete = ()=>{
      message.loading('');
      APIs.init().deleteDepartment(formState).then(res => {

        if (res.status === 200 && res.data?.status === 200) {
          modalVisible.value = false
          message.success({content: 'Deleted department'});
          fetchDepartments()
        } else {
          message.destroy()
        }
      }).catch(e => {
        message.destroy()
        console.error(e)
      })
    }

    return{
      getMyRole,
      departments,
      onNodeDragStop,
      elements,
      modalVisible,
      formState,
      rules,
      onSubmit,
      handleChange,
      create,
      edit,
      formRef,
      confirmDelete,
      labelCol: { span: 6 },
      wrapperCol: { span: 16 },
    }
  },
})
</script>

<style>
.container-title{
  display: flex;
  flex-direction: row;
  padding: 16px 24px 16px 24px;
  margin-bottom: 25px;
  background-color: white
}
.basicflow.dark{background:#57534e;color:#fffffb}.basicflow.dark .vue-flow__node{background:#292524;color:#fffffb}.basicflow.dark .vue-flow__controls .vue-flow__controls-button{background:#292524;fill:#fffffb;border-color:#fffffb}.basicflow.dark .vue-flow__edge-textbg{fill:#292524}.basicflow.dark .vue-flow__edge-text{fill:#fffffb}.basicflow .controls{display:flex;flex-wrap:wrap;justify-content:center;gap:8px}.basicflow .controls button{padding:4px;border-radius:5px;font-weight:600;-webkit-box-shadow:0px 5px 10px 0px rgba(0,0,0,.3);box-shadow:0 5px 10px #0000004d;cursor:pointer;display:flex;justify-content:center;align-items:center}.basicflow .controls button:hover{transform:scale(102%);transition:.25s all ease}

</style>
