import {State} from "./states";
import {Wallet} from "@/models/Wallet/Wallet";
import {FlowZNSTransaction} from "@/models/Wallet/FlowTransaction";
import moment from "moment";
import {WalletTransaction} from "@/models/Wallet/WalletTransaction";

export type Getters = {
    getWallet(state: State): Wallet | undefined
    getWalletFetching(state: State): boolean
    getFlowZNS_Transactions(state: State): FlowZNSTransaction[]
    getFlowZNS_TransactionCount(state: State): number
    getFlowZNS_TransactionTotalAmount(state: State): number
    getWalletTransactions(state: State): WalletTransaction[]
    getWalletTransactionCount(state: State): number
}
export const getters: Getters = {
    getWallet(state: State): Wallet | undefined {
        return state.wallet
    },
    getWalletFetching(state: State): boolean {
        return state.isWalletFetching
    },
    getFlowZNS_Transactions(state: State): FlowZNSTransaction[] {
        return state.znsTransactions.map(x => {
            x.created_at_str = moment(x.created_at * 1000).format("DD-MM-YYYY HH:mm")
            return x;
        })
    },
    getFlowZNS_TransactionCount(state: State): number {
        return state.znsTransCount
    },
    getFlowZNS_TransactionTotalAmount(state: State): number {
        return state.znsTransTotalAmount
    },
    getWalletTransactions(state: State): WalletTransaction[] {
        return state.walletTransactions.map((item)=>{
            item.created_at=moment(item.created_at).utc(true).format("DD-MM-YYYY HH:mm")
            return item
        })
    },
    getWalletTransactionCount(state: State): number {
        return state.walletTransactionCount
    }

}
