<template>
  <div class="container">
    <a-row class="header"   justify="start">
      <img
          src="@/assets/icons/zalo.webp"
          style="width: 28px;"/>
      <a-col>
        <div class="description">Zalo OA</div>
        <span class="content">{{data.name}}</span>
      </a-col>

    </a-row>
    <ViralLuckyWheelPreview :flowContext="data"
                            type="flow-new"
    />


    <Handle :id="data.id+'_in'" type="target"  class="handle" :position="Position.Left" :style="sourceHandleStyleA" />
    <template v-for="(handle, index) in listHandle" :key="handle">
        <HandleCustom :data="handle" :index="index"  />
    </template>
  </div>


</template>

<script>
import {defineComponent, defineProps, defineEmits, onMounted, ref} from "vue";
import { computed } from 'vue'
import {Position, VueFlow, useVueFlow, Handle, MarkerType} from '@vue-flow/core'
import ViralLuckyWheelPreview from "@/components/Flow/ViralLuckyWheelPreview.vue";
import HandleCustom from "./HandleCustom.vue";
import {MixUtils} from "@/utils/Utils";

const props = defineProps({
  data: {
    type: Object,
    required: true,
  },
})


export default defineComponent({
  name: "TriggerComponent",
  components:{
    Handle,
    ViralLuckyWheelPreview,
    HandleCustom,
  },
  props: ['triggerElements', 'data'],
  setup(props, {emit}){

    const listHandle = ref([])

    const sourceHandleStyleA = computed(() => ({ top: '10px',
      bottom: 'auto', }))

    const sourceHandleStyleC = computed(() => ({  top: 'auto',
      bottom: '10px', }))

    onMounted(()=>{
      if(props.data.flow_viral){
        props.data.flow_viral.map(viral=>{
          viral.gifts.map(gift=>{
              listHandle.value.push(gift)
          })
        })
      }
    })


    return{
      sourceHandleStyleA,
      sourceHandleStyleC,
      Position,
      listHandle,
    }
  }
})
</script>

<style scoped>
.container{
  padding: 16px 16px 48px 16px;
  font-size: 14px;
}

.add_trigger{
  border-width: 0.5px;
  width: 100%;
  border-color: #acacac80;
  border-style: dotted;
}
.next_step{
  position: absolute;
  bottom: 8px;
  right: 8px;
  text-align: right;
}
.header{
  display: flex;
  flex-flow: row;
  align-items: center;
  gap: 8px;
  margin-bottom: 10px;
}
.element{
  display: flex;
  align-items: center;
  justify-content: start;
  padding: 8px 0 8px 0;
  border-radius: 10px;
  margin-bottom: 12px;
  background-color: #e1faec;
}
.content{
  font-size: 16px;
  font-weight: normal;
}
.content_mess{
  margin: 4px 0 4px;
  padding: 8px;
  border-radius: 10px;
  background-color: #B1B1B77E;
  font-size: 14px;
  font-weight: normal;
}
.description{
  color: #758697;
  font-size: 10px;
}
.add_trigger{
  height: 50px;
}
.icon{
}
/*.handle {*/
/*  border-width: 0;*/
/*  background-color: #8492a6;*/
/*  transition: transform .2s;*/
/*  width: 15px;*/
/*  height: 15px;*/
/*  margin: 0 auto;*/
/*}*/

/*.handle:hover {*/
/*  background-color: #0084ff;*/
/*}*/
</style>
