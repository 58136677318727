import { Metadata } from "@/APIs/response/S_HttpResponse"
import { EmployeeSearch } from "@/models/employee/Employee"
import { Employee } from "@/models/Users"

export type EmployeeData  ={
    data: Employee[]| undefined
    metadata: Metadata | undefined
}
export type State = {
    dataSearch: EmployeeSearch[]| undefined
    employeeData: EmployeeData | undefined
}

export const state: State = {
    dataSearch: [],
    employeeData: {data: [], metadata: {}}
}