import {Wallet} from "@/models/Wallet/Wallet";
import {State} from "@/store/modules/wallet/states";
import {WalletMutationTypes} from "@/store/modules/wallet/mutation-types";
import {FlowZNSTransaction} from "@/models/Wallet/FlowTransaction";
import {ElasticData} from "@/APIs/response/ElasticResponse";
import {WalletTransaction} from "@/models/Wallet/WalletTransaction";

export type Mutations<S = State> = {
    [WalletMutationTypes.SET_WALLET](state: S, payload: Wallet | undefined): void;
    [WalletMutationTypes.SET_WALLET_FETCHING](state: S, payload: boolean): void;
    [WalletMutationTypes.SET_FLOW_ZNS_TRANSACTIONS](state: S, payload: ElasticData | undefined): void;
    [WalletMutationTypes.SET_WALLET_TRANSACTIONS](state: S, payload: WalletTransaction[] | undefined): void;
    [WalletMutationTypes.SET_WALLET_TRANSACTION_COUNT](state: S, payload: number | undefined): void;
}

export const mutations: Mutations = {
    [WalletMutationTypes.SET_WALLET](state: State, payload: Wallet | undefined): void {
        state.wallet = payload
    },
    [WalletMutationTypes.SET_WALLET_FETCHING](state: State, payload: boolean): void {
        state.isWalletFetching = payload
    },
    [WalletMutationTypes.SET_FLOW_ZNS_TRANSACTIONS](state: State, payload: ElasticData | undefined): void {
        const znsTransTotalAmount = payload?.aggregations.total_amount.value
        state.znsTransTotalAmount = znsTransTotalAmount ? znsTransTotalAmount : 0;
        const znsTransCount = payload?.hits.total
        state.znsTransCount = znsTransCount ? znsTransCount : 0;
        const trans = payload?.hits.hits.map(x => x._source);
        state.znsTransactions = trans ? trans : []
    },
    [WalletMutationTypes.SET_WALLET_TRANSACTIONS](state: State, payload: WalletTransaction[] | undefined): void {
        state.walletTransactions = payload ? payload : []
    },
    [WalletMutationTypes.SET_WALLET_TRANSACTION_COUNT](state: State, payload: number | undefined): void {
        state.walletTransactionCount = payload ? payload : 0
    }
}
