<template>
  <a-modal
      v-model:visible="visible"
      :footer="null"
      width="80%"
      :closable="true"
  >
    <slot name="header">
      <div>
                <span style="font-size: 15px; color:#516CAD;margin-left: 20px;">
                {{$t('Viral_Result')}}
                </span>
      </div>
    </slot>
    <slot name="closeIcon" @click="visible = false">
      <div>
        <a-icon type="close-circle" />
      </div>
    </slot>
  <div class="container">

        <a-table
            :rowKey="(record, index) => index"
            :columns="columns" :data-source="viralResults"
            class="flow-table">
          <template #index="{index}">
            <a-button class="table-row-name" type="text" >
              {{ index+1 }}
            </a-button>
          </template>
          <template #viral_name="{ record }">
              <p>
                Name: <b>{{ record.full_name?record.full_name: record.nick_name }}</b>
                <br/>
                Phone Number: <b>{{ record.phone?record.phone: record.all_phones }}</b>
                <br/>
                Invited: <b>{{ record.count }}</b>
              </p>
          </template>
          <template #has_phone="{ record }">
               <template v-for="item in record.invited" :key="item">
                 <p>
                   Name: <b>{{ item.full_name?item.full_name: item.nick_name }}</b>
                   <br/>
                   Phone Number: <b>{{ item.phone?item.phone: item.all_phones }}</b>
<!--                   <br/>-->
<!--                   Invited: <b>{{ item.count }}</b>-->
                 </p>
               </template>
          </template>
          <template #status="{ record }">
                <span>{{ record.status==1?'Done': 'UnFinish' }}</span>
          </template>
        </a-table>
  </div>
  </a-modal>
</template>

<script lang="ts">
import {Options, Vue} from "vue-class-component";
import {
  DownOutlined,
  PlusOutlined,
  SmileOutlined,
  TagOutlined,
  CheckOutlined,
  EllipsisOutlined,
    EyeOutlined,
  DeleteOutlined,
  EditOutlined,
  LineChartOutlined
} from "@ant-design/icons-vue"
import RouterEndpoint from "@/router/RouterEndpoint";
import {mapActions, mapGetters} from "vuex";
import {FlowActionTypes} from "@/store/modules/viral/action-types"
import {TagsActionTypes} from "@/store/modules/tag/action-types";
import {FlowsRequest} from "@/APIs/request/FlowRequest";
import APIs from "@/APIs/APIs";
import {message} from "ant-design-vue";

const flowsRequest: FlowsRequest = {}
import { ViralResult } from "@/models/Viral/ViralResult";

@Options({
  name: 'ViralResultModal',
  components: {
    TagOutlined,
    PlusOutlined,
    SmileOutlined,
    DownOutlined,
    CheckOutlined,
    EllipsisOutlined,
    EyeOutlined,
    DeleteOutlined,
    EditOutlined,
    LineChartOutlined
  },
  computed: {
    columns() {
      return [
        {
          title: '#',
          dataIndex: 'index',
          key: 'index',
          slots: {customRender: 'index'},
        },
        {
          title: 'Viral Name',
          dataIndex: 'viral_name',
          key: 'viral_name',
          slots: {customRender: 'viral_name'},
        },
        {
          title: "Viral has phone",
          key: 'has_phone',
          dataIndex: 'has_phone',
          slots: {customRender: 'has_phone'},
        },
        {
          title: "Status",
          key: 'status',
          dataIndex: 'status',
          slots: {customRender: 'status'},
        },
      ]
    },
    ...mapGetters({
      getFlowData: 'getViralData',
      getCurrentShop: 'getCurrentShop',
      getFlowTags: 'getFlowTags',
    }),
  },
  data() {
    return {
      RouterEndpoint: RouterEndpoint,
      selectedTags: [],
      flowsRequest,
      searchInput: '',
      visible: false,
      viralId: 0,
      viralResults: []
    }
  },
  created() {
    // this.loadData()
  },
  methods: {
    ...mapActions({
      fetchFlow: FlowActionTypes.FETCH_VIRAL,
      fetchTags: TagsActionTypes.FETCH_TAGS,
    }),

    loadData() {
      message.loading({content: 'Loading...'});
      APIs.init().getViralResult({viral_id: this.viralId}).then(res => {
        this.viralResults = []
        if (res.status === 200 && res.data?.status === 200) {
          console.log(res)
          // this.viralResults = res.data?.data

          const listRoot: ViralResult[] = [];
          console.log('data',res.data?.data)

          res.data?.data.forEach(item => {
            if (item.parent_id == item.customer_id) {
              const listChild: ViralResult[] = []
              res.data?.data.forEach(itemChild=>{
                if(itemChild.parent_id == item.customer_id && itemChild.parent_id!==itemChild.customer_id){
                  listChild.push(itemChild)
                }
              })
              item.invited = listChild
              item.count = listChild.length
              listRoot.push(item)
            }
          })
          console.log(listRoot)
          this.viralResults = listRoot


          message.destroy()
        } else {
          message.destroy()
        }
      }).catch(e => {
        message.destroy()
        console.error(e)
      })
    },
    openModal(Id: number) {
      this.visible = true
      this.viralId = Id
      this.loadData()
    }

  }
})
export default class ViralResults extends Vue {
}

</script>

<style lang="scss" scoped>
.container {
  margin: 10px 15px;
}

.left-layout {
  padding-right: 14px;

  .tag-group {
    padding: 10px 4px;
  }

  .button-align-left {
    text-align: left;
  }

}

.row-horizontal {
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  .input-search {
    display: flex;
    flex: 0.2
  }
}

.table-row-name {
  font-weight: bold;
}

.flow-table {
  margin-top: 15px;
  background-color: white;
}

.flow-tag {
  opacity: 0.7;
  cursor: pointer;
  margin-top: 8px;
}

.flow-tag-active {
  opacity: 1;
  cursor: pointer;
  margin-top: 8px;
}

</style>
