import {FlowData, State} from "./states";

export type Getters = {
    getViralData(state: State): FlowData
}
export const getters: Getters = {
    getViralData(state: State): FlowData {
        if (state.flowData) {
            return state.flowData
        } else {
            return {
                flows: [],
                metadata: {}
            }
        }
    },
}
