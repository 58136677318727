<template>
  <div class="container-title">
    <h3 class="title-header">{{getEvent(Number($route.params.id))}}</h3>
<!--    <a-select-->

<!--        allowClear-->
<!--        v-model:value="formState.event_id"-->
<!--        placeholder="Please select"-->

<!--    >-->
<!--      <a-select-option-->
<!--          v-for="item in events.events"-->
<!--          :key="item.id"-->
<!--          v-bind:value="item.id"-->
<!--      >{{ item.name }}-->
<!--      </a-select-option>-->
<!--    </a-select>-->
<!--    <a-form>-->
<!--      <a-form-item :label="$t('EVENT')">-->
<!--        <a-select-->

<!--            allowClear-->
<!--            v-model:value="eventId"-->
<!--            placeholder="Please select"-->
<!--            @change="changeEvent"-->
<!--            style="margin-right: 20px;width: 200px"-->

<!--        >-->
<!--          <a-select-option-->
<!--              v-for="item in events.events"-->
<!--              :key="item.id"-->
<!--              v-bind:value="item.id"-->
<!--          >{{ item.name }}-->
<!--          </a-select-option>-->
<!--        </a-select>-->
<!--      </a-form-item>-->
<!--    </a-form>-->
    <a-input style="width: 200px;margin-right: 20px" @change="handleChange" v-model:value="search" placeholder="Basic usage" />

    <a-button style="margin-right: 20px" @click="rollBack" >
      <template #icon><rollback-outlined /> </template>
    </a-button>
    <router-link
        style="margin-right: 16px"
        :to="`/ticket/add-ticket/:id?event_id=${$route.params.id}`">
      <a-button type="primary">{{$t('ADD')}}</a-button>
    </router-link>
    <a-upload
        name="file"
        :multiple="false"
        :show-upload-list="false"
        :beforeUpload="importXLS"
    >
      <a-button>
        <template #icon>
          <cloud-upload-outlined/>
        </template>
        Upload Excel
      </a-button>
    </a-upload>
  </div>
  <div style="margin: 20px" class="container">
    <a-table

        :rowKey="record => record.id"
        :columns="columns" :data-source="getTicket.tickets"
        :pagination="pagination"

        @change="handleTableChange"
       >

      <template #status="{record}">
        <a-popover v-if="record.status !== 2" trigger="click">
          <template #content>
            <a-button v-if="record.status === 1" @click="checkin(record)">{{'Check in'}}</a-button>
            <a-button v-else @click="checkin(record,'payment')">{{$t('PAYMENT')}}</a-button>
          </template>
          <a-tag style="cursor: pointer" :color="record.status === 0 ? 'orange' : record.status === 1 ? 'blue' : 'green'">{{$t(getStatus(record.status))}}</a-tag>

        </a-popover>
        <a-tag v-else  :color="record.status === 0 ? 'orange' : record.status === 1 ? 'blue' : 'green'">{{$t(getStatus(record.status))}}</a-tag>

      </template>
      <template #action="{record}">
        <a-dropdown>
          <a-button type="text">
            <eye-outlined/>
          </a-button>
          <template #overlay>
            <a-menu>
              <a-menu-item
              >
                <div @click="showModal(record.ticket_id)">
                  <eye-outlined/> {{$t('PREVIEW_QR')}}

                </div>

              </a-menu-item>
              <router-link :to="`/ticket/add-ticket/${record.id}?event_id=${$route.params.id}`">
              <a-menu-item
                  >

                <edit-outlined/>
                {{ $t('EDIT') }}

              </a-menu-item>
              </router-link>
              <a-menu-item
              >
                <a-popconfirm
                    :title="$t('TICKET_CONFIRM_SEND_CONTEXT')"
                    :ok-text="$t('YES')"
                    :cancel-text="$t('NO')"
                    @confirm="sendTicket(record.ticket_id)"
                >
                  <delete-outlined/>
                  {{ $t('SEND_TICKET') }}
                </a-popconfirm>

              </a-menu-item>
              <a-menu-item
              >
                <a-popconfirm
                    :title="$t('TRIGGER_CONFIRM_DELETE_CONTEXT')"
                    :ok-text="$t('YES')"
                    :cancel-text="$t('NO')"
                    @confirm="deleteTicket(record.id)"
                >
                  <delete-outlined/>
                  {{ $t('DELETE') }}
                </a-popconfirm>

              </a-menu-item>

            </a-menu>
          </template>
        </a-dropdown>
      </template>

    </a-table>
  </div>

  <a-modal v-model:visible="visible" :title="$t('PREVIEW_QR')" @ok="handleOk">
    <a-row justify="center">
      <img :src="`https://quickchart.io/qr?text=${ticketId}&size=300`"/>
    </a-row>

  </a-modal>
</template>
<script lang="ts">
import {useI18n} from "vue-i18n";
import {useStore} from "vuex";
import {TicketActionTypes} from "@/store/modules/Ticket/action-types";
import {computed, onMounted, ref} from "vue";
import {EventResponse} from "@/APIs/response/S_HttpResponse";
import {
  DeleteOutlined,
  EditOutlined,
  EllipsisOutlined,
  RollbackOutlined,
  EyeOutlined,
  CloudUploadOutlined
} from "@ant-design/icons-vue";
import APIs from "@/APIs/APIs";
import {message} from "ant-design-vue";
import {PaginationProps} from "ant-design-vue/es/pagination";
import {EventActionTypes} from "@/store/modules/Event/action-types";
import {useRoute, useRouter} from "vue-router";

export default {
  components:{
    CloudUploadOutlined,
    EyeOutlined,
    DeleteOutlined,
    EditOutlined,
    RollbackOutlined
  },
  setup(){
    const i18n = useI18n()
    const store = useStore()
    const getUserInfo =computed(()=> store.getters.getUserInfo)
    const events = computed<EventResponse>(() => store.getters.getEvent)
    const getTicket = computed(() => store.getters.getTicket)
    const getTicketType =computed(()=> store.getters.getTicketType)

    const eventId = ref()
    const route = useRoute()
    const router = useRouter()
    const visible = ref(false)
    const ticketId = ref('')
    const columns = [
      {
        title: i18n.t('Ticket Id'),
        dataIndex: 'ticket_id',
        key: 'ticket_id',
        slots: {customRender: 'ticket_id'},
      },
      {
        title: i18n.t('FORM_FULL_NAME'),
        dataIndex: 'full_name',
        key: 'full_name',
        slots: {customRender: 'full_name'},
      },
      {
        title: i18n.t('ORGANIZATION'),
        dataIndex: 'organization',
        key: 'organization',
        slots: {customRender: 'organization'},
      },
      {
        title: i18n.t('Email'),
        dataIndex: 'email',
        key: 'email',
        slots: {customRender: 'email'},
      },
      {
        title: i18n.t('PHONE'),
        dataIndex: 'phone',
        key: 'phone',
        slots: {customRender: 'phone'},
      },
      {
        title: i18n.t('TRIGGER_STATUS'),
        dataIndex: 'status',
        key: 'status',
        slots: {customRender: 'status'},
      },
      {
        title: i18n.t('option 1'),
        dataIndex: 'option_1',
        key: 'option_1',
        slots: {customRender: 'option_1'},
      },
      {
        title: i18n.t('option 2'),
        dataIndex: 'option_2',
        key: 'option_2',
        slots: {customRender: 'option_2'},
      },
      {
        title: i18n.t('option 3'),
        dataIndex: 'option_3',
        key: 'option_3',
        slots: {customRender: 'option_3'},
      },
      {
        title: i18n.t('reference_name'),
        dataIndex: 'reference_name',
        key: 'reference_name',
        slots: {customRender: 'reference_name'},
      },
      {
        title: '',
        key: 'action',
        slots: {customRender: 'action'},
        width: 100
      },
    ]
    const search = ref('')
    const handleChange = () => {

        fetchTicket()

    }
    const showModal = (id :any)=>{
      visible.value = true
      ticketId.value = id
    }
    const checkin = async (data:any,str:string) => {
      // const res = await APIs.init().CheckinTicket(data)

      // if(res.data?.status === 200){
      //   fetchTicket()
      // }
      if(str === 'payment'){
        data.status = 1
      }else {
        data.status = 2
      }

      const res = await APIs.init().updateTickets(data)
      if(res.data?.status === 200){
        message.success('checkin success')
        fetchTicket()
      }
      console.log('checkin')
    }
   const handleOk = () =>{
      visible.value = false
   }
    const TicketRequest = ref({
      page:1,
      page_size:20
    })
    const fetchTicket = () => {
      store.dispatch(TicketActionTypes.FETCH_TICKET,{page:TicketRequest.value.page,page_size:TicketRequest.value.page_size,shop_id:getUserInfo.value.current_shop_id,search:search.value,event_id: Number(route.params.id)})
    }
    const fetchEvents =  () =>{

      store.dispatch(EventActionTypes.FETCH_EVENT,{shop_id:getUserInfo.value.current_shop_id})
    }
    const deleteTicket = async (id:number) => {
      const res = await APIs.init().deleteTickets({id:id})
      if( res.data?.status === 200){
        message.success('delete success')
        fetchTicket()
      }
    }

    const getStatus = (status:any) => {
      if(status === 0){
        return 'NEW_STATUS'
      }else if(status === 1) {
        return 'PAID'
      }else {
        return 'CHECKED'
      }
    }

    const sendTicket = async (id:any) => {
      try {
        const res = await APIs.init().sendTemplateEmail({ticket_id: id})
        if(res.status === 200){
          message.success('send mail success')
        }
      }catch (e:any){
        console.log(e)
      }
    }

    const getEvent = (id:any) => {
      // @ts-ignorec
      const name =  events.value.events.find((item:any) => id === item.id)
      return name ? name.name : id
    }
    const rollBack = () => {
      router.back()
    }

    const pagination = computed(() => ({
      current: TicketRequest.value.page ? TicketRequest.value.page : 1,

      pageSize: TicketRequest.value.page_size ? TicketRequest.value.page_size : 20,
      defaultPageSize: 20,
      total:getTicket.value&& getTicket.value.metadata ? getTicket.value.metadata.record_total : 0,
      pageSizeOptions: ['20', '40', '80', '120'],
      showSizeChanger: true
    }));

    const    handleTableChange =(pagination:PaginationProps) => {
      console.log(pagination)
      // @ts-ignore
          TicketRequest.value.page = pagination.current
      // @ts-ignore
          TicketRequest.value.page_size = pagination?.pageSize
     fetchTicket()
    }
    const changeEvent = () => {
      fetchTicket()
    }
    onMounted(() => {
      fetchTicket()
      fetchEvents()
      store.dispatch(TicketActionTypes.FETCH_TICKET_TYPE,{page:1,page_size:999,event_id: route.params.id})
    })
    const importLoading = ref(false)
    const importXLS = (info: any) =>{
      //console.log(info);
      const formData = new FormData();
      formData.append("files", info);
      formData.append("event_id", route.params.id+'');

      try{
        formData.append("ticket_type_id", getTicketType.value.ticketTypes[0].id);
      }catch (e) {
        console.log(e)
      }
      importLoading.value = true
      APIs.init()
          .importTicketXLS(formData)
          .then((res) => {
            importLoading.value= false
            if (res.status === 200 && res.data?.status === 200) {
              // this.replaceLoadCustomer()
              message.success({content: "Import success"});
            } else {
              message.destroy();
            }
          })
          .catch((e) => {
            importLoading.value = false
            message.destroy();
            console.error(e);
          });
    }
    return{
      importXLS,
      columns,
      events,
      eventId,
      changeEvent,
      getTicket,
      deleteTicket,pagination,
      handleTableChange,
      getEvent,
      rollBack,
      sendTicket,
      ticketId,
      visible,
      showModal,
      handleOk,
      getStatus,
      search,
      handleChange,
      checkin

    }
  }
}
</script>
