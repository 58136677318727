<template>
  <div class="container-title">
    <h3 class="title-header">{{$t($route.name)}}</h3>
    <div class="input-search">
      <a-input-search

          v-model:value="search"
          :placeholder="$t('SEARCH')"
          style="width: 100%"
      />
    </div>

    <a-button
        v-if="getMyRole()?.is_create"
        type="primary" @click="newTag">
      <template #icon>
        <plus-outlined/>
      </template>
      {{ $t("TAG_CREATE") }}
    </a-button>

  </div>

        <a-table
            size="middle"
            :scroll="{  y: '80vh' }"
            class="table"
            :rowKey="record => record.id" :columns="columns"
                 :pagination="false"
                 @change="handleTableChange"
                 :data-source="getTags"
                 :expandedRowKeys="expandedRowKeys" @expand="tableExpand">
          <template #name="{ record }">
            <a-tag :color="record.color">{{ record.name }}</a-tag>
          </template>

          <template #created_at="{text}">
            <span>{{ dateTimeDisplayFormatFromString(text) }}</span>
          </template>
          <template #action="{ record }">
            <a-dropdown>
              <a-button type="text">
                <eye-outlined />
              </a-button>
              <template #overlay>
                <a-menu>
                  <a-menu-item
                      v-if="getMyRole()?.is_create"
                      @click="newChildren(record.id)">
                    <plus-outlined/>
                    {{ $t("ADD_CHILDREN") }}
                  </a-menu-item>

                  <a-menu-item
                      v-if="getMyRole()?.is_edit"
                      @click="editClick(record)">
                    <edit-outlined/>
                    {{ $t("CUSTOMER_DETAIL_EDIT") }}
                  </a-menu-item>
                  <a-menu-item
                      v-if="getMyRole()?.is_edit"
                      @click="moveClick(record,'parents')">
                    <swap-outlined />
                    {{ $t("move") }}
                  </a-menu-item>
                  <a-menu-item
                      v-if="getMyRole()?.is_delete"
                  >
                    <a-popconfirm
                        title="Are you sure delete this tag?"
                        ok-text="Yes"
                        cancel-text="No"
                        @confirm="deleteTag(record)"
                    >
                      <delete-outlined/>
                      {{ $t("CUSTOMER_DELETE") }}
                    </a-popconfirm>

                  </a-menu-item>
                </a-menu>
              </template>
            </a-dropdown>
          </template>

          <template v-if="getType==='customer'" #expandedRowRender="{record}">
            <div>
              <a-button
                  v-if="getMyRole()?.is_create"
                  type="primary" @click="newChildren(record.id)">
                <plus-outlined/>
                {{ $t("ADD_CHILDREN") }}
              </a-button>
              <a-table :rowKey="record2 => record2.id" :columns="columns" :loading="childrenLoading"
                       :data-source="childrenTags">
                <template #name="{ record }">
                  <a-tag :color="record.color">{{ record.name }}</a-tag>
                </template>

                <template #created_at="{text}">
                  <span>{{ dateTimeDisplayFormatFromString(text) }}</span>
                </template>
                <template #action="{ record }">
                  <a-dropdown>
                    <a-button type="text">
                      <eye-outlined />
                    </a-button>
                    <template #overlay>
                      <a-menu>
                        <a-menu-item
                            v-if="getMyRole()?.is_edit"
                            @click="moveClick(record,'children')">
                          <swap-outlined />
                          {{ $t("move") }}
                        </a-menu-item>
                        <a-menu-item
                            v-if="getMyRole()?.is_edit"
                            @click="editClick(record)">
                          <edit-outlined/>
                          {{ $t("CUSTOMER_DETAIL_EDIT") }}
                        </a-menu-item>
                        <a-menu-item
                            v-if="getMyRole()?.is_delete"
                        >
                          <a-popconfirm
                              title="Are you sure delete this tag?"
                              ok-text="Yes"
                              cancel-text="No"
                              @confirm="deleteTag(record)"
                          >
                            <delete-outlined/>
                            {{ $t("CUSTOMER_DELETE") }}
                          </a-popconfirm>

                        </a-menu-item>
                      </a-menu>
                    </template>
                  </a-dropdown>
                </template>
              </a-table>
            </div>
          </template>
        </a-table>

    <a-modal
          :title="tagForm.id?$t('EDIT'):$t('ADD_NEW')"
        v-model:visible="visible"
        :confirm-loading="confirmLoading"
        width="40%"
        @ok="saveTag"
    >
      <a-form :model="tagForm" ref="formRef" :rules="rules" :label-col="{span: 6,offset:0}" :wrapper-col="{ span: 16 }">
        <a-form-item :label="$t('TAG_TAG_NAME')" name="name">
          <a-input v-model:value="tagForm.name"/>
        </a-form-item>
        <a-form-item :label="$t('TAG_COLOR')" name="color">
          <div style="display: flex;">
            <a-button
                @click="togglePicker"
                :style="{'background-color':tagForm.color?tagForm.color:'white',color:tagForm.color?'white':'black','margin-right':'15px'}">
              Click
            </a-button>
            <ColorPicker theme="light" :color="tagForm.color" :sucker-hide="false" v-if="colorPickerVisible"
                         :sucker-canvas="suckerCanvas" :sucker-area="suckerArea" @changeColor="changeColor"
                         @openSucker="openSucker"
            />
          </div>
        </a-form-item>
        <a-form-item name="description" :label="$t('TAG_DESCRIPTION')">
          <a-textarea v-model:value="tagForm.description" placeholder="Description" :rows="4"/>
        </a-form-item>
      </a-form>

    </a-modal>
  <a-modal v-model:visible="visible2" title="Basic Modal" @ok="handleOk">
    <a-form>
      <a-form-item :label="$t('TAG')" name="tag">
        <a-tree-select
            @change="handleChangeTag"
            show-search
            style="width: 100%"
            @search="searchTag"
            v-model:value="tagIds.parent_id"
            :filterTreeNode="false"
            v-model:searchValue="searchTagValue"
            :notFoundContent="null"
            :dropdown-style="{ maxHeight: '300', overflow: 'auto' }"
            allow-clear
            :placeholder="$t('TAG_SELECT')"
        >
          <a-tree-select-node v-for="item in getCustomerTags" :key="item.id" v-bind:value="item.id">
            <template #title>
              <a-tag :color="item.color">
                {{ item.name }}
              </a-tag>
            </template>
            <a-tree-select-node v-for="child in item.children" :key="child.id" v-bind:value="child.id">
              <template #title>
                <a-tag :color="child.color">
                  {{ child.name }}
                </a-tag>
              </template>
            </a-tree-select-node>

          </a-tree-select-node>
        </a-tree-select>
      </a-form-item>
    </a-form>

  </a-modal>
</template>
<script lang="ts">
import {DeleteOutlined, DownOutlined, EditOutlined, PlusOutlined,SwapOutlined,EyeOutlined} from "@ant-design/icons-vue";
import {ColorPicker} from 'vue-color-kit'
import './vue-color-kit.css'
import {mapActions, mapGetters} from "vuex";
import {TagsActionTypes} from "@/store/modules/tag/action-types";
import {Options, Vue} from "vue-class-component";
import RouterEndpoint from "@/router/RouterEndpoint";
import {Tag, TypeTag} from "@/models/Tags/Tags";
import APIs from "@/APIs/APIs";
import {ValidateErrorEntity} from "ant-design-vue/es/form/interface";
import {message} from "ant-design-vue";
import {PaginationProps} from "ant-design-vue/es/pagination"
import {ref} from "vue";
import {Constant} from "@/utils/Constants";
import useTagStore from "../../store/Tags";

const rules = {
  name: [
    {required: true, message: 'Please input tag name', trigger: 'blur'},
  ]
};
const tagForm: Tag = {}
// Modal

@Options({
  name: 'tags',
  components: {
    PlusOutlined,
    SwapOutlined,
    DownOutlined,
    ColorPicker,
    EditOutlined,
    DeleteOutlined,
    EyeOutlined
  },
  computed: {
    columns() {
      return [
        {
          title: "Id",
          dataIndex: 'id',
          key: 'id',
        },
        {
          title: this.$t('TAG_TAG_NAME'),
          dataIndex: 'name',
          key: 'name',
          slots: {title: 'customTitle', customRender: 'name'},
        },
        {
          title: this.$t('TAG_DATE_CREATED'),
          dataIndex: 'created_at',
          key: 'created_at',
          slots: {customRender: 'created_at'},
        },
        {
          title: this.$t('TAG_CREATOR'),
          dataIndex: 'creator_name',
          key: 'creator_name',
          slots: {customRender: 'creator'},
        },
        {
          title: this.$t('TAG_DESCRIPTION'),
          key: 'description',
          dataIndex: 'description',
          slots: {customRender: 'tags'},
        },
        {
          title: this.$t('TAG_NUMBER_OF_USES'),
          dataIndex: 'uses',
          key: 'uses',
          slots: {customRender: 'uses'},
        },
        {
          title: this.$t('TAG_ACTION'),
          key: 'action',
          slots: {customRender: 'action'},
        },
      ]
    },
    ...mapGetters({
      getFlowTags: 'getFlowTags',
      getCustomerTags: 'getCustomerTags',
      getViralTags: 'getViralTags',
      getAutoInboxTags: 'getAutoInboxTags',
      getTriggerTags: 'getTriggerTags',
      getProductTags: 'getProductTags',
      userInfo: "getUserInfo",
      getRole: "getRole",
      getRootTagMetadata: 'getRootTagMetadata'
    }),
    getTags() {
      switch (this.$route.name) {
        case RouterEndpoint.CUSTOMER_TAGS.name:
          return this.tags.tagSales
        case RouterEndpoint.FLOW_TAGS.name:
          return this.tags.tagFlows
        case RouterEndpoint.TRIGGER_TAGS.name:
          return this.tags.tagTriggers
        case RouterEndpoint.VIRAL_TAGS.name:
          return this.tags.tagVirals
        case RouterEndpoint.PRODUCT_TAGS.name:
          return this.tags.tagProducs
      }
    },
    getType() {
      switch (this.$route.name) {
        case RouterEndpoint.CUSTOMER_TAGS.name:
          return TypeTag.CUSTOMER_TAGS
        case RouterEndpoint.FLOW_TAGS.name:
          return TypeTag.FLOW_TAGS
        case RouterEndpoint.TRIGGER_TAGS.name:
          return TypeTag.TRIGGER_TAGS
        case RouterEndpoint.VIRAL_TAGS.name:
          return TypeTag.VIRAL_TAGS
        case RouterEndpoint.PRODUCT_TAGS.name:
          return TypeTag.PRODUCT_TAGS
        default:
          return TypeTag.FLOW_TAGS
      }
    },
    getCustomerTags() {
      return  useTagStore.getState().tagSales
    },

    pagination() {
      return {
        current: this.getRootTagMetadata.page ? this.getRootTagMetadata.page : 1,
        pageSize: this.getRootTagMetadata.page_size ? this.getRootTagMetadata.page_size : 20,
        defaultPageSize: 20,
        total: this.getRootTagMetadata && this.getRootTagMetadata.record_total ? this.getRootTagMetadata.record_total : 0,
        pageSizeOptions: ['20', '40', '80', '120'],
        showSizeChanger: true
      }
    }

  },
  data() {
    return {
      color: "#59c7f9",
      tagIds: {},
      suckerCanvas: null,
      suckerArea: [],
      isSucking: false,
      visible: false,
      visible2:false,
      confirmLoading: false,
      tagForm,
      rules,
      searchTagValue: '',
      strType: "",
      colorPickerVisible: false,
      expandedRowKeys: [],
      childrenTags: [],
      childrenLoading: false,
      tags: useTagStore(),
      tagReq: {
        page: 1,
        page_size: 20,
        search: "",
      },
      title: ''
    }
  },
  created() {
    this.fetchRootTag(true)

  },
  methods: {
    // ...mapActions({
    //   fetchTag: TagsActionTypes.FETCH_ROOT_TAGS,
    //   fetchTreeTags :TagsActionTypes.FETCH_TREE_TAGS,
    //
    // }),
    fetchTag(params:any) {
      useTagStore.getState().GetRootTag(
          params
      )
    },
    fetchTreeTags(params:any) {
      useTagStore.getState().GetTreeTag(params)
    },
    changeColor(color: any) {
      const {r, g, b, a} = color.rgba
      this.tagForm.color = color.hex;
      // this.colorPickerVisible = false
    },
    openSucker(isOpen: boolean) {
      if (isOpen) {
        // ... canvas be created
        // this.suckerCanvas = canvas
        // this.suckerArea = [x1, y1, x2, y2]
      } else {
        // this.suckerCanvas && this.suckerCanvas.remove
      }
    },
    tableExpand(visible: boolean, record: Tag) {
      if (visible) {
        this.expandedRowKeys = [record.id]
        this.fetchChildrenTags(record.id)
      } else {
        this.expandedRowKeys = []
      }
    },
    handleOk(){
if(this.title === 'children'){
  APIs.init().updateTag(this.tagIds).then(res => {
    this.confirmLoading = false
    if (res.status === 200 && res.data?.status === 200) {
      this.visible2 = false
      message.success({content: 'move tags success'});
      if (this.tagIds.parent_id) {

        this.tableExpand(true,this.tagIds)


      } else {
        this.fetchRootTag( true)
      }
    } else {
      message.destroy()
    }
  }).catch((e:any) => {
    this.confirmLoading = false
    message.destroy()
    console.error(e)
  })
}else {
  APIs.init().updateTag(this.tagIds).then(res => {
    this.confirmLoading = false
    if (res.status === 200 && res.data?.status === 200) {
      this.visible2 = false
      message.success({content: 'move tags success'});
      if (this.tagIds.parent_id) {
        let tagChildren = {}
        APIs.init().getChildrenTags({parent_id: this.tagIds.id, type: TypeTag.CUSTOMER_TAGS, page: 1, page_size: 60})
            .then(res => {
              this.childrenLoading = false
              if (res.ok && res.data && res.data.data) {
                tagChildren = {
                  color:res.data.data[0].color,
                  created_at:res.data.data[0].created_at,
                  creator:res.data.data[0].creator,
                  description:res.data.data[0].description,
                  id:res.data.data[0].id,
                  name:res.data.data[0].name,
                  shop_id:res.data.data[0].shop_id,
                  type:res.data.data[0].type,
                  updated_at:res.data.data[0].updated_at,
                  uses:res.data.data[0].uses,

                  parent_id:this.tagIds.parent_id
                }
                APIs.init().updateTag(tagChildren).then(res => {
                  this.confirmLoading = false
                  if (res.status === 200 && res.data?.status === 200) {
                    message.success({content: 'move tags success'});
                    this.fetchRootTag()

                  }
                })
              }
            }).catch((e) => {
          this.childrenLoading = false
          console.error("getChildrenTags", e)
        })



      } else {
        this.fetchRootTag( true)
      }
    } else {
      message.destroy()
    }
  }).catch(e => {
    this.confirmLoading = false
    message.destroy()
    console.error(e)
  })
}

    },
    fetchChildrenTags(id: number) {
      this.childrenLoading = true
      APIs.init().getChildrenTags({parent_id: id, type: TypeTag.CUSTOMER_TAGS, page: 1, page_size: 60})
          .then(res => {
            this.childrenLoading = false
            if (res.ok && res.data && res.data.data) {
              this.childrenTags = res.data.data
            }
          }).catch((e) => {
        this.childrenLoading = false
        console.error("getChildrenTags", e)
      })
    },
    editClick(tag: Tag) {
      this.tagForm = tag;
      this.visible = true;
    },
    moveClick(currentag: Tag,t:''){
      this.visible2 = true;
      this.tagIds = currentag
      this.title = t
      console.log(this.tagIds)

    },
    newTag() {
      this.visible = true;
      this.tagForm = tagForm
      this.tagForm.parent_id = 0
    },
    newChildren(parentId: number) {
      this.visible = true;
      this.tagForm = tagForm
      this.tagForm.parent_id = parentId
    },
    togglePicker() {
      this.colorPickerVisible = !this.colorPickerVisible;
    },
    fetchRootTag(isRefresh?: boolean) {
      if (isRefresh) {
        this.tagReq.page = 1
        this.tagReq.page_size = 1000
      }

      this.fetchTag({
        type: this.getType,
        page: this.tagReq.page,
        page_size: this.tagReq.page_size,
        search: this.tagReq.search
      });

      this.fetchTreeTags({ type: "customer", search: '',page_size:1000})
    },
    handleTableChange(pagination: PaginationProps) {
      this.tagReq.page = pagination?.current
      this.tagReq.page_size = pagination?.pageSize
      this.fetchRootTag()
    },
    saveTag() {
      this.$refs.formRef.validate().then(() => {
        let tagForm = {...this.tagForm}
        console.log(tagForm);
        this.confirmLoading = true;
        if (tagForm.id) {
          tagForm.type = this.getType;
          APIs.init().updateTag(tagForm).then(res => {
            this.confirmLoading = false
            if (res.status === 200 && res.data?.status === 200) {
              this.visible = false
              message.success({content: 'Edit tags success'});
              if (tagForm.parent_id) {
                this.fetchChildrenTags(tagForm.parent_id)
                this.fetchTreeTags({ payload: {type: "customer", search: ''}})
              } else {
                this.fetchRootTag()
              }
            } else {
              message.destroy()
            }
          }).catch(e => {
            this.confirmLoading = false
            message.destroy()
            console.error(e)
          })
        } else {
          tagForm.type = this.getType;
          APIs.init().createTag(tagForm).then(res => {
            this.confirmLoading = false
            if (res.status === 200 && res.data?.status === 200) {
              this.visible = false
              message.success({content: 'Add tags success'});
              if (tagForm.parent_id) {
                this.fetchChildrenTags(tagForm.parent_id)
                this.fetchTreeTags({ payload: {type: "customer", search: ''}})
              } else {
                this.fetchRootTag()
              }
            } else {
              message.destroy()
            }
          }).catch(e => {
            this.confirmLoading = false
            message.destroy()
            console.error(e)
          })
        }
      }).catch((e: ValidateErrorEntity) => {
        console.error(e)
      })
    },
    getMyRole() {
      if(this.userInfo.id_cms_privileges<11){
        return {
          is_visible: 1,
          is_create:1,
          is_read: 1,
          is_edit: 1,
          is_delete: 1,
          id: 1
        }
      }else{
        switch (this.$route.name) {
          case RouterEndpoint.CUSTOMER_TAGS.name:
              return this.getRole(Constant.MODULES.customer_tag);
          case RouterEndpoint.FLOW_TAGS.name:
            return this.getRole(Constant.MODULES.flow_tag);
          case RouterEndpoint.TRIGGER_TAGS.name:
            return this.getRole(Constant.MODULES.trigger_tag);
          case RouterEndpoint.PRODUCT_TAGS.name:
            return this.getRole(Constant.MODULES.product_tag);
          default:
            return this.getRole(Constant.MODULES.flow_tag);
        }
      }

    },
    deleteTag(tag: Tag) {
      message.loading({content: 'Deleting Tag: ' + tag.name, key: tag.id});
      if (!tag.id) {
        tag.id = 0
      }
      APIs.init().deleteTag(tag.id).then(res => {
        if (res.status === 200 && res.data?.status === 200) {
          message.success({content: 'Deleted Tag: ' + tag.name + '!', key: tag.id, duration: 2});
          if (tag.parent_id){
            this.fetchChildrenTags(tag.parent_id)
          }else {
            this.fetchRootTag()
          }
        } else {
          message.destroy()
        }
      }).catch(e => {
        message.destroy()
        console.error(e)
      })
    },
  }
})
export default class Tags extends Vue {
}

</script>
<style scoped>
.add-tag {
  text-align: right;
}

.form-modal {
  padding: 0px 15px;
}
.table {
 margin: 16px
}
.title-header {
  font-weight: bold;
  flex: 1;
}
.container-title{
  display: flex;
  flex-direction: row;
  padding: 16px 24px 16px 24px;
  margin-bottom: 25px;
  background-color: white
}
.input-search{
  width: 250px;
  margin-right: 16px;
}
</style>
