import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createBlock as _createBlock, mergeProps as _mergeProps, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-725e9452"),n=n(),_popScopeId(),n)
const _hoisted_1 = { style: {"margin-top":"10px","width":"95%","margin-bottom":"20px","margin-left":"10px"} }
const _hoisted_2 = { class: "title-header" }
const _hoisted_3 = { class: "container" }
const _hoisted_4 = { key: 0 }
const _hoisted_5 = { key: 1 }
const _hoisted_6 = { key: 2 }
const _hoisted_7 = { key: 1 }
const _hoisted_8 = { key: 2 }
const _hoisted_9 = { key: 0 }
const _hoisted_10 = { key: 3 }
const _hoisted_11 = { key: 0 }
const _hoisted_12 = { key: 1 }
const _hoisted_13 = { key: 4 }
const _hoisted_14 = { key: 0 }
const _hoisted_15 = { key: 1 }
const _hoisted_16 = { key: 5 }
const _hoisted_17 = { key: 6 }
const _hoisted_18 = { key: 2 }
const _hoisted_19 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_col = _resolveComponent("a-col")!
  const _component_a_step = _resolveComponent("a-step")!
  const _component_a_steps = _resolveComponent("a-steps")!
  const _component_a_row = _resolveComponent("a-row")!
  const _component_a_input = _resolveComponent("a-input")!
  const _component_a_form_item = _resolveComponent("a-form-item")!
  const _component_a_textarea = _resolveComponent("a-textarea")!
  const _component_a_form = _resolveComponent("a-form")!
  const _component_a_button = _resolveComponent("a-button")!
  const _component_a_card = _resolveComponent("a-card")!
  const _component_send_outlined = _resolveComponent("send-outlined")!
  const _component_a_typography_text = _resolveComponent("a-typography-text")!
  const _component_a_select_option = _resolveComponent("a-select-option")!
  const _component_a_select = _resolveComponent("a-select")!
  const _component_a_input_number = _resolveComponent("a-input-number")!
  const _component_a_typography_title = _resolveComponent("a-typography-title")!
  const _component_caret_down_outlined = _resolveComponent("caret-down-outlined")!
  const _component_caret_up_outlined = _resolveComponent("caret-up-outlined")!
  const _component_a_radio = _resolveComponent("a-radio")!
  const _component_a_radio_group = _resolveComponent("a-radio-group")!
  const _component_a_range_picker = _resolveComponent("a-range-picker")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_a_row, null, {
        default: _withCtx(() => [
          _createVNode(_component_a_col, { span: 2 }, {
            default: _withCtx(() => [
              _createElementVNode("h3", _hoisted_2, _toDisplayString(_ctx.$t(_ctx.$route.name)), 1)
            ]),
            _: 1
          }),
          _createVNode(_component_a_col, { span: 22 }, {
            default: _withCtx(() => [
              _createVNode(_component_a_steps, {
                style: {"width":"100%"},
                current: $setup.current
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_a_step, {
                    onClick: _cache[0] || (_cache[0] = ($event: any) => ($setup.current = 0)),
                    title: _ctx.$t('common_information')
                  }, null, 8, ["title"]),
                  _createVNode(_component_a_step, {
                    title: _ctx.$t('SET_UP_VOUCHER')
                  }, null, 8, ["title"]),
                  _createVNode(_component_a_step, {
                    title: _ctx.$t('TIME_APPLICATION_VOUCHER')
                  }, null, 8, ["title"]),
                  _createVNode(_component_a_step, {
                    title: _ctx.$t('CONFIG_VOUCHER')
                  }, null, 8, ["title"]),
                  _createVNode(_component_a_step, {
                    title: _ctx.$t('PUBLISH_VOUCHER')
                  }, null, 8, ["title"])
                ]),
                _: 1
              }, 8, ["current"])
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _createElementVNode("div", _hoisted_3, [
      ($setup.current === 0)
        ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
            _createVNode(_component_a_card, { class: "purpose-value" }, {
              default: _withCtx(() => [
                _createVNode(_component_a_row, { justify: "center" }, {
                  default: _withCtx(() => [
                    _createVNode(_component_a_form, {
                      style: {"width":"80%"},
                      name: "basic",
                      "label-wrap": {span:8},
                      "wrapper-col": { span: 16 },
                      autocomplete: "off",
                      labelAlign: "left",
                      ref: "formRef",
                      rules: $setup.rules,
                      model: $setup.formState
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_a_form_item, {
                          label: _ctx.$t('VOUCHER_NAME'),
                          name: "voucher_name"
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_a_input, {
                              onChange: $setup.changeVoucherName,
                              value: $setup.formState.voucher_name,
                              "onUpdate:value": _cache[1] || (_cache[1] = ($event: any) => (($setup.formState.voucher_name) = $event)),
                              placeholder: _ctx.$t('ENTER_CAMPAIGN_NAME')
                            }, null, 8, ["onChange", "value", "placeholder"])
                          ]),
                          _: 1
                        }, 8, ["label"]),
                        _createVNode(_component_a_form_item, {
                          label: _ctx.$t('VOUCHER_CODE'),
                          name: "voucher_code"
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_a_input, {
                              disabled: $setup.checkEdit ? true :false,
                              value: $setup.formState.voucher_code,
                              "onUpdate:value": _cache[2] || (_cache[2] = ($event: any) => (($setup.formState.voucher_code) = $event)),
                              placeholder: _ctx.$t('ENTER_CAMPAIGN_NAME')
                            }, null, 8, ["disabled", "value", "placeholder"])
                          ]),
                          _: 1
                        }, 8, ["label"]),
                        _createVNode(_component_a_form_item, {
                          label: _ctx.$t('VOUCHER_DISCRIPTION')
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_a_textarea, {
                              value: $setup.formState.description,
                              "onUpdate:value": _cache[3] || (_cache[3] = ($event: any) => (($setup.formState.description) = $event)),
                              placeholder: _ctx.$t('INPUT_CONTENT'),
                              "allow-clear": ""
                            }, null, 8, ["value", "placeholder"])
                          ]),
                          _: 1
                        }, 8, ["label"])
                      ]),
                      _: 1
                    }, 8, ["rules", "model"])
                  ]),
                  _: 1
                }),
                _createVNode(_component_a_row, { justify: "center" }, {
                  default: _withCtx(() => [
                    _createVNode(_component_a_button, {
                      onClick: $setup.createVoucher,
                      style: {"width":"40%"},
                      type: "primary"
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.$t('SETUP_DISCOUNT_CONDITION')), 1)
                      ]),
                      _: 1
                    }, 8, ["onClick"])
                  ]),
                  _: 1
                })
              ]),
              _: 1
            })
          ]))
        : _createCommentVNode("", true),
      ($setup.current === 1)
        ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
            (!$setup.formState.purpose )
              ? (_openBlock(), _createBlock(_component_a_card, {
                  key: 0,
                  style: {"border-radius":"10px","margin":"auto","width":"50%"}
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_a_row, {
                      justify: "center",
                      style: {"flex-direction":"column","align-items":"center"}
                    }, {
                      default: _withCtx(() => [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.purposes, (i) => {
                          return (_openBlock(), _createBlock(_component_a_button, {
                            onClick: ($event: any) => ($setup.handlePurpose(i.value)),
                            style: {"width":"50%","margin-bottom":"10px"},
                            key: i,
                            type: "dashed"
                          }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(_ctx.$t(i.title)), 1)
                            ]),
                            _: 2
                          }, 1032, ["onClick"]))
                        }), 128))
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }))
              : ($setup.formState.purpose && !$setup.formState.voucher_type)
                ? (_openBlock(), _createBlock(_component_a_card, {
                    key: 1,
                    style: {"border-radius":"10px","margin":"auto","width":"50%"}
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_a_row, {
                        justify: "center",
                        style: {"flex-direction":"column","align-items":"center"}
                      }, {
                        default: _withCtx(() => [
                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.promotionType, (i) => {
                            return (_openBlock(), _createBlock(_component_a_button, {
                              onClick: ($event: any) => ($setup.handlePromotionType(i.value)),
                              style: {"width":"50%","margin-bottom":"10px"},
                              key: i,
                              type: "dashed"
                            }, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(_ctx.$t(i.title)), 1)
                              ]),
                              _: 2
                            }, 1032, ["onClick"]))
                          }), 128))
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }))
                : ($setup.formState.voucher_type)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                      _createVNode(_component_a_row, { style: {"justify-content":"flex-end"} }, {
                        default: _withCtx(() => [
                          _createVNode(_component_a_button, {
                            type: "primary",
                            style: {"margin-right":"10px","margin-bottom":"20px"},
                            onClick: $setup.conditionVoucher
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_send_outlined),
                              _createTextVNode(" " + _toDisplayString(_ctx.$t("next")), 1)
                            ]),
                            _: 1
                          }, 8, ["onClick"])
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_a_row, { style: {"justify-content":"space-around"} }, {
                        default: _withCtx(() => [
                          _createVNode(_component_a_card, { style: {"border-radius":"10px","width":"52%"} }, {
                            default: _withCtx(() => [
                              _createVNode(_component_a_row, { justify: "center" }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_a_typography_text, {
                                    style: {"margin-bottom":"20px"},
                                    strong: ""
                                  }, {
                                    default: _withCtx(() => [
                                      _createTextVNode(_toDisplayString(_ctx.$t('Discount')), 1)
                                    ]),
                                    _: 1
                                  }),
                                  _createVNode(_component_a_form, {
                                    "label-col": { span: 8 },
                                    "wrapper-col": { span: 18 },
                                    style: {"width":"100%"},
                                    labelAlign: "left",
                                    ref: "formRef3",
                                    rules: $setup.rules2,
                                    model: $setup.formState.condition
                                  }, {
                                    default: _withCtx(() => [
                                      ($setup.formState.voucher_type !=='OTHER_OFFERS')
                                        ? (_openBlock(), _createBlock(_component_a_form_item, {
                                            key: 0,
                                            label: _ctx.$t('PAGE_OF_USE'),
                                            name: "page_application"
                                          }, {
                                            default: _withCtx(() => [
                                              _createVNode(_component_a_select, {
                                                allowClea: "",
                                                style: {"width":"100%"},
                                                placeholder: "Please select",
                                                value: $setup.formState.condition.page_application,
                                                "onUpdate:value": _cache[4] || (_cache[4] = ($event: any) => (($setup.formState.condition.page_application) = $event)),
                                                onChange: _ctx.changePage,
                                                mode: "tags"
                                              }, {
                                                default: _withCtx(() => [
                                                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.configPage, (item) => {
                                                    return (_openBlock(), _createBlock(_component_a_select_option, {
                                                      key: item.id,
                                                      value: item.page_id
                                                    }, {
                                                      default: _withCtx(() => [
                                                        _createTextVNode(_toDisplayString(item.page_name), 1)
                                                      ]),
                                                      _: 2
                                                    }, 1032, ["value"]))
                                                  }), 128)),
                                                  _createVNode(_component_a_select_option, { value: "0" }, {
                                                    default: _withCtx(() => [
                                                      _createTextVNode(_toDisplayString(_ctx.$t('ALL')), 1)
                                                    ]),
                                                    _: 1
                                                  })
                                                ]),
                                                _: 1
                                              }, 8, ["value", "onChange"])
                                            ]),
                                            _: 1
                                          }, 8, ["label"]))
                                        : _createCommentVNode("", true),
                                      ($setup.formState.voucher_type === 'DISCOUNT_FOR_TOTAL_BILL')
                                        ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                                            _createVNode(_component_a_form_item, {
                                              label: _ctx.$t('DISCOUNT_VN_%')
                                            }, {
                                              default: _withCtx(() => [
                                                _createVNode(_component_a_row, null, {
                                                  default: _withCtx(() => [
                                                    _createVNode(_component_a_col, { span: 18 }, {
                                                      default: _withCtx(() => [
                                                        _createVNode(_component_a_input_number, {
                                                          style: {"width":"100%"},
                                                          min: 0,
                                                          formatter: value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ','),
                                                          max: $setup.formState.condition.discount.type === 'percent' ? 100 : 20000000000000000,
                                                          value: $setup.formState.condition.discount.discount,
                                                          "onUpdate:value": _cache[5] || (_cache[5] = ($event: any) => (($setup.formState.condition.discount.discount) = $event))
                                                        }, null, 8, ["formatter", "max", "value"])
                                                      ]),
                                                      _: 1
                                                    }),
                                                    _createVNode(_component_a_col, { span: 6 }, {
                                                      default: _withCtx(() => [
                                                        _createVNode(_component_a_select, {
                                                          allowClea: "",
                                                          style: {"width":"100%"},
                                                          placeholder: "Please select",
                                                          value: $setup.formState.condition.discount.type,
                                                          "onUpdate:value": _cache[6] || (_cache[6] = ($event: any) => (($setup.formState.condition.discount.type) = $event)),
                                                          onChange: _ctx.changePage
                                                        }, {
                                                          default: _withCtx(() => [
                                                            _createVNode(_component_a_select_option, { value: 'percent' }, {
                                                              default: _withCtx(() => [
                                                                _createTextVNode(_toDisplayString('%'))
                                                              ]),
                                                              _: 1
                                                            }),
                                                            _createVNode(_component_a_select_option, { value: 'vnd' }, {
                                                              default: _withCtx(() => [
                                                                _createTextVNode(_toDisplayString('VNĐ '))
                                                              ]),
                                                              _: 1
                                                            })
                                                          ]),
                                                          _: 1
                                                        }, 8, ["value", "onChange"])
                                                      ]),
                                                      _: 1
                                                    })
                                                  ]),
                                                  _: 1
                                                })
                                              ]),
                                              _: 1
                                            }, 8, ["label"]),
                                            ($setup.formState.condition.discount.type === 'percent')
                                              ? (_openBlock(), _createBlock(_component_a_row, {
                                                  key: 0,
                                                  style: {"width":"100%"}
                                                }, {
                                                  default: _withCtx(() => [
                                                    _createVNode(_component_a_col, { span: 12 }, {
                                                      default: _withCtx(() => [
                                                        _createVNode(_component_a_typography_title, { level: 5 }, {
                                                          default: _withCtx(() => [
                                                            _createTextVNode(_toDisplayString(_ctx.$t('MAXIMUM_DISCOUNT_VALUE')), 1)
                                                          ]),
                                                          _: 1
                                                        }),
                                                        _createVNode(_component_a_form_item, null, {
                                                          default: _withCtx(() => [
                                                            _createVNode(_component_a_input_number, {
                                                              formatter: value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ','),
                                                              value: $setup.formState.condition.discount.discount_max,
                                                              "onUpdate:value": _cache[7] || (_cache[7] = ($event: any) => (($setup.formState.condition.discount.discount_max) = $event))
                                                            }, {
                                                              addonAfter: _withCtx(() => [
                                                                _createTextVNode(_toDisplayString('VNĐ'))
                                                              ]),
                                                              _: 1
                                                            }, 8, ["formatter", "value"])
                                                          ]),
                                                          _: 1
                                                        })
                                                      ]),
                                                      _: 1
                                                    }),
                                                    _createVNode(_component_a_col, { span: 12 }, {
                                                      default: _withCtx(() => [
                                                        _createVNode(_component_a_typography_title, { level: 5 }, {
                                                          default: _withCtx(() => [
                                                            _createTextVNode(_toDisplayString(_ctx.$t('ONLY_APPLY_FOR_BILL_FROM')), 1)
                                                          ]),
                                                          _: 1
                                                        }),
                                                        _createVNode(_component_a_form_item, null, {
                                                          default: _withCtx(() => [
                                                            _createVNode(_component_a_input_number, {
                                                              formatter: value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ','),
                                                              value: $setup.formState.condition.discount.apply_to_bill_from,
                                                              "onUpdate:value": _cache[8] || (_cache[8] = ($event: any) => (($setup.formState.condition.discount.apply_to_bill_from) = $event))
                                                            }, {
                                                              addonAfter: _withCtx(() => [
                                                                _createTextVNode(_toDisplayString('VNĐ'))
                                                              ]),
                                                              _: 1
                                                            }, 8, ["formatter", "value"])
                                                          ]),
                                                          _: 1
                                                        })
                                                      ]),
                                                      _: 1
                                                    })
                                                  ]),
                                                  _: 1
                                                }))
                                              : (_openBlock(), _createBlock(_component_a_form_item, {
                                                  key: 1,
                                                  label: _ctx.$t('ONLY_APPLY_FOR_BILL_FROM')
                                                }, {
                                                  default: _withCtx(() => [
                                                    _createVNode(_component_a_input_number, {
                                                      value: $setup.formState.condition.discount.apply_to_bill_from,
                                                      "onUpdate:value": _cache[9] || (_cache[9] = ($event: any) => (($setup.formState.condition.discount.apply_to_bill_from) = $event)),
                                                      formatter: value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                                                    }, {
                                                      addonAfter: _withCtx(() => [
                                                        _createTextVNode(_toDisplayString('VNĐ'))
                                                      ]),
                                                      _: 1
                                                    }, 8, ["value", "formatter"])
                                                  ]),
                                                  _: 1
                                                }, 8, ["label"]))
                                          ]))
                                        : ($setup.formState.voucher_type ==='DISCOUNT_FOR_PRODUCT')
                                          ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
                                              _createVNode(_component_a_form_item, {
                                                label: _ctx.$t('DISCOUNT_VN_%')
                                              }, {
                                                default: _withCtx(() => [
                                                  _createVNode(_component_a_row, null, {
                                                    default: _withCtx(() => [
                                                      _createVNode(_component_a_col, { span: 18 }, {
                                                        default: _withCtx(() => [
                                                          _createVNode(_component_a_input_number, {
                                                            style: {"width":"100%"},
                                                            min: 0,
                                                            formatter: value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ','),
                                                            max: $setup.formState.condition.discount.type === 'percent' ? 100 : 20000000000000000,
                                                            value: $setup.formState.condition.discount.discount,
                                                            "onUpdate:value": _cache[10] || (_cache[10] = ($event: any) => (($setup.formState.condition.discount.discount) = $event))
                                                          }, null, 8, ["formatter", "max", "value"])
                                                        ]),
                                                        _: 1
                                                      }),
                                                      _createVNode(_component_a_col, { span: 6 }, {
                                                        default: _withCtx(() => [
                                                          _createVNode(_component_a_select, {
                                                            allowClea: "",
                                                            style: {"width":"100%"},
                                                            placeholder: "Please select",
                                                            value: $setup.formState.condition.discount.type,
                                                            "onUpdate:value": _cache[11] || (_cache[11] = ($event: any) => (($setup.formState.condition.discount.type) = $event)),
                                                            onChange: _ctx.changePage
                                                          }, {
                                                            default: _withCtx(() => [
                                                              _createVNode(_component_a_select_option, { value: 'percent' }, {
                                                                default: _withCtx(() => [
                                                                  _createTextVNode(_toDisplayString('%'))
                                                                ]),
                                                                _: 1
                                                              }),
                                                              _createVNode(_component_a_select_option, { value: 'vnd' }, {
                                                                default: _withCtx(() => [
                                                                  _createTextVNode(_toDisplayString('VNĐ '))
                                                                ]),
                                                                _: 1
                                                              })
                                                            ]),
                                                            _: 1
                                                          }, 8, ["value", "onChange"])
                                                        ]),
                                                        _: 1
                                                      })
                                                    ]),
                                                    _: 1
                                                  })
                                                ]),
                                                _: 1
                                              }, 8, ["label"]),
                                              _createVNode(_component_a_form_item, {
                                                label: _ctx.$t('APPLY_ON_NOT_APPLY')
                                              }, {
                                                default: _withCtx(() => [
                                                  _createVNode(_component_a_row, { style: {"margin-bottom":"10px"} }, {
                                                    default: _withCtx(() => [
                                                      _createVNode(_component_a_col, { span: 6 }, {
                                                        default: _withCtx(() => [
                                                          _createVNode(_component_a_select, {
                                                            allowClea: "",
                                                            style: {"width":"100%"},
                                                            placeholder: "Please select",
                                                            value: $setup.formState.condition.apply.type,
                                                            "onUpdate:value": _cache[12] || (_cache[12] = ($event: any) => (($setup.formState.condition.apply.type) = $event)),
                                                            onChange: _ctx.changePage
                                                          }, {
                                                            default: _withCtx(() => [
                                                              _createVNode(_component_a_select_option, { value: 'apply_on' }, {
                                                                default: _withCtx(() => [
                                                                  _createTextVNode(_toDisplayString(_ctx.$t('apply_on')), 1)
                                                                ]),
                                                                _: 1
                                                              }),
                                                              _createVNode(_component_a_select_option, { value: 'not_apply' }, {
                                                                default: _withCtx(() => [
                                                                  _createTextVNode(_toDisplayString(_ctx.$t('not_apply')), 1)
                                                                ]),
                                                                _: 1
                                                              })
                                                            ]),
                                                            _: 1
                                                          }, 8, ["value", "onChange"])
                                                        ]),
                                                        _: 1
                                                      }),
                                                      _createVNode(_component_a_col, { span: 6 }, {
                                                        default: _withCtx(() => [
                                                          _createVNode(_component_a_select, {
                                                            allowClea: "",
                                                            style: {"width":"100%"},
                                                            placeholder: "Please select",
                                                            value: $setup.formState.condition.apply.condition,
                                                            "onUpdate:value": _cache[13] || (_cache[13] = ($event: any) => (($setup.formState.condition.apply.condition) = $event)),
                                                            onChange: _ctx.changePage
                                                          }, {
                                                            default: _withCtx(() => [
                                                              _createVNode(_component_a_select_option, { value: 'product' }, {
                                                                default: _withCtx(() => [
                                                                  _createTextVNode(_toDisplayString(_ctx.$t('product')), 1)
                                                                ]),
                                                                _: 1
                                                              })
                                                            ]),
                                                            _: 1
                                                          }, 8, ["value", "onChange"])
                                                        ]),
                                                        _: 1
                                                      })
                                                    ]),
                                                    _: 1
                                                  }),
                                                  ($setup.formState.condition.apply.condition === 'group')
                                                    ? (_openBlock(), _createBlock(_component_a_select, {
                                                        key: 0,
                                                        "show-search": "",
                                                        mode: "tags",
                                                        value: $setup.formState.condition.apply.group_products,
                                                        "onUpdate:value": _cache[14] || (_cache[14] = ($event: any) => (($setup.formState.condition.apply.group_products) = $event))
                                                      }, {
                                                        default: _withCtx(() => [
                                                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.getProductGroups.productGroups, (i) => {
                                                            return (_openBlock(), _createBlock(_component_a_select_option, {
                                                              key: i.id,
                                                              value: i.id
                                                            }, {
                                                              default: _withCtx(() => [
                                                                _createTextVNode(_toDisplayString(i.group_name) + " }} ", 1)
                                                              ]),
                                                              _: 2
                                                            }, 1032, ["value"]))
                                                          }), 128))
                                                        ]),
                                                        _: 1
                                                      }, 8, ["value"]))
                                                    : (_openBlock(), _createBlock(_component_a_select, {
                                                        key: 1,
                                                        "show-search": "",
                                                        mode: "tags",
                                                        value: $setup.formState.condition.apply.products,
                                                        "onUpdate:value": _cache[15] || (_cache[15] = ($event: any) => (($setup.formState.condition.apply.products) = $event))
                                                      }, {
                                                        default: _withCtx(() => [
                                                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.getStockProducts.products, (i) => {
                                                            return (_openBlock(), _createBlock(_component_a_select_option, {
                                                              key: i.id,
                                                              value: i.id
                                                            }, {
                                                              default: _withCtx(() => [
                                                                _createTextVNode(_toDisplayString(i.product_name), 1)
                                                              ]),
                                                              _: 2
                                                            }, 1032, ["value"]))
                                                          }), 128))
                                                        ]),
                                                        _: 1
                                                      }, 8, ["value"]))
                                                ]),
                                                _: 1
                                              }, 8, ["label"]),
                                              _createVNode(_component_a_row, {
                                                style: {"cursor":"pointer"},
                                                onClick: _cache[16] || (_cache[16] = ($event: any) => ($setup.conditon =!$setup.conditon))
                                              }, {
                                                default: _withCtx(() => [
                                                  _createVNode(_component_a_typography_text, {
                                                    style: {"color":"#FAB216"},
                                                    level: 4
                                                  }, {
                                                    default: _withCtx(() => [
                                                      _createTextVNode(_toDisplayString(_ctx.$t('SEE_MORE_ADVANCED_CONDITIONS')), 1)
                                                    ]),
                                                    _: 1
                                                  }),
                                                  ($setup.conditon === false)
                                                    ? (_openBlock(), _createBlock(_component_caret_down_outlined, {
                                                        key: 0,
                                                        style: {"color":"#FAB216"}
                                                      }))
                                                    : (_openBlock(), _createBlock(_component_caret_up_outlined, {
                                                        key: 1,
                                                        style: {"color":"#FAB216"}
                                                      }))
                                                ]),
                                                _: 1
                                              }),
                                              ($setup.conditon === true)
                                                ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
                                                    ($setup.formState.condition.apply.condition !== 'group')
                                                      ? (_openBlock(), _createBlock(_component_a_form_item, {
                                                          key: 0,
                                                          label: _ctx.$t('DISCOUNT')
                                                        }, {
                                                          default: _withCtx(() => [
                                                            _createVNode(_component_a_row, null, {
                                                              default: _withCtx(() => [
                                                                _createVNode(_component_a_col, { span: 14 }, {
                                                                  default: _withCtx(() => [
                                                                    _createVNode(_component_a_input, {
                                                                      disabled: $setup.formState.condition.discount.discount_form ? true : false,
                                                                      value: $setup.formState.condition.discount.product_num,
                                                                      "onUpdate:value": _cache[17] || (_cache[17] = ($event: any) => (($setup.formState.condition.discount.product_num) = $event))
                                                                    }, null, 8, ["disabled", "value"])
                                                                  ]),
                                                                  _: 1
                                                                }),
                                                                _createVNode(_component_a_col, { span: 4 }, {
                                                                  default: _withCtx(() => [
                                                                    _createTextVNode(_toDisplayString(_ctx.$t('PRODUCT')), 1)
                                                                  ]),
                                                                  _: 1
                                                                }),
                                                                _createVNode(_component_a_col, { span: 6 }, {
                                                                  default: _withCtx(() => [
                                                                    _createVNode(_component_a_select, {
                                                                      allowClea: "",
                                                                      style: {"width":"100%"},
                                                                      placeholder: "Please select",
                                                                      value: $setup.formState.condition.discount.price_type,
                                                                      "onUpdate:value": _cache[18] || (_cache[18] = ($event: any) => (($setup.formState.condition.discount.price_type) = $event)),
                                                                      disabled: $setup.formState.condition.discount.discount_form ? true : false,
                                                                      onChange: _ctx.changePage
                                                                    }, {
                                                                      default: _withCtx(() => [
                                                                        _createVNode(_component_a_select_option, { value: 'max' }, {
                                                                          default: _withCtx(() => [
                                                                            _createTextVNode(_toDisplayString(_ctx.$t('HIGHEST_PRICE')), 1)
                                                                          ]),
                                                                          _: 1
                                                                        }),
                                                                        _createVNode(_component_a_select_option, { value: 'min' }, {
                                                                          default: _withCtx(() => [
                                                                            _createTextVNode(_toDisplayString(_ctx.$t('LOWEST_PRICE')), 1)
                                                                          ]),
                                                                          _: 1
                                                                        })
                                                                      ]),
                                                                      _: 1
                                                                    }, 8, ["value", "disabled", "onChange"])
                                                                  ]),
                                                                  _: 1
                                                                })
                                                              ]),
                                                              _: 1
                                                            })
                                                          ]),
                                                          _: 1
                                                        }, 8, ["label"]))
                                                      : _createCommentVNode("", true),
                                                    ($setup.formState.condition.apply.condition !== 'group')
                                                      ? (_openBlock(), _createBlock(_component_a_form_item, {
                                                          key: 1,
                                                          label: _ctx.$t('DISCOUNT_FROM_PRODUCT')
                                                        }, {
                                                          default: _withCtx(() => [
                                                            _createVNode(_component_a_row, null, {
                                                              default: _withCtx(() => [
                                                                _createVNode(_component_a_col, { span: 16 }, {
                                                                  default: _withCtx(() => [
                                                                    _createVNode(_component_a_input, {
                                                                      disabled: $setup.formState.condition.discount.product_num ? true :false,
                                                                      value: $setup.formState.condition.discount.discount_form,
                                                                      "onUpdate:value": _cache[19] || (_cache[19] = ($event: any) => (($setup.formState.condition.discount.discount_form) = $event))
                                                                    }, null, 8, ["disabled", "value"])
                                                                  ]),
                                                                  _: 1
                                                                })
                                                              ]),
                                                              _: 1
                                                            })
                                                          ]),
                                                          _: 1
                                                        }, 8, ["label"]))
                                                      : _createCommentVNode("", true),
                                                    ($setup.formState.condition.discount.type === 'percent')
                                                      ? (_openBlock(), _createBlock(_component_a_form_item, {
                                                          key: 2,
                                                          label: _ctx.$t('LIMITED')
                                                        }, {
                                                          default: _withCtx(() => [
                                                            _createVNode(_component_a_row, { style: {"width":"100%"} }, {
                                                              default: _withCtx(() => [
                                                                _createVNode(_component_a_col, { span: 11 }, {
                                                                  default: _withCtx(() => [
                                                                    _createVNode(_component_a_typography_title, { level: 5 }, {
                                                                      default: _withCtx(() => [
                                                                        _createTextVNode(_toDisplayString(_ctx.$t('MAXIMUM_DISCOUNT_VALUE')), 1)
                                                                      ]),
                                                                      _: 1
                                                                    }),
                                                                    _createVNode(_component_a_form_item, null, {
                                                                      default: _withCtx(() => [
                                                                        _createVNode(_component_a_input_number, {
                                                                          formatter: value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ','),
                                                                          value: $setup.formState.condition.discount.discount_max,
                                                                          "onUpdate:value": _cache[20] || (_cache[20] = ($event: any) => (($setup.formState.condition.discount.discount_max) = $event))
                                                                        }, {
                                                                          addonAfter: _withCtx(() => [
                                                                            _createTextVNode(_toDisplayString('VNĐ'))
                                                                          ]),
                                                                          _: 1
                                                                        }, 8, ["formatter", "value"])
                                                                      ]),
                                                                      _: 1
                                                                    })
                                                                  ]),
                                                                  _: 1
                                                                }),
                                                                _createVNode(_component_a_col, { span: 1 }),
                                                                _createVNode(_component_a_col, { span: 12 }, {
                                                                  default: _withCtx(() => [
                                                                    _createVNode(_component_a_typography_title, { level: 5 }, {
                                                                      default: _withCtx(() => [
                                                                        _createTextVNode(_toDisplayString(_ctx.$t('ONLY_APPLY_FOR_BILL_FROM')), 1)
                                                                      ]),
                                                                      _: 1
                                                                    }),
                                                                    _createVNode(_component_a_form_item, null, {
                                                                      default: _withCtx(() => [
                                                                        _createVNode(_component_a_input_number, {
                                                                          formatter: value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ','),
                                                                          value: $setup.formState.condition.discount.apply_to_bill_from,
                                                                          "onUpdate:value": _cache[21] || (_cache[21] = ($event: any) => (($setup.formState.condition.discount.apply_to_bill_from) = $event))
                                                                        }, {
                                                                          addonAfter: _withCtx(() => [
                                                                            _createTextVNode(_toDisplayString('VNĐ'))
                                                                          ]),
                                                                          _: 1
                                                                        }, 8, ["formatter", "value"])
                                                                      ]),
                                                                      _: 1
                                                                    })
                                                                  ]),
                                                                  _: 1
                                                                })
                                                              ]),
                                                              _: 1
                                                            })
                                                          ]),
                                                          _: 1
                                                        }, 8, ["label"]))
                                                      : (_openBlock(), _createBlock(_component_a_form_item, {
                                                          key: 3,
                                                          label: _ctx.$t('ONLY_APPLY_FOR_BILL_FROM')
                                                        }, {
                                                          default: _withCtx(() => [
                                                            _createVNode(_component_a_input_number, {
                                                              style: {"width":"100%"},
                                                              formatter: value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ','),
                                                              value: $setup.formState.condition.discount.apply_to_bill_from,
                                                              "onUpdate:value": _cache[22] || (_cache[22] = ($event: any) => (($setup.formState.condition.discount.apply_to_bill_from) = $event))
                                                            }, {
                                                              addonAfter: _withCtx(() => [
                                                                _createTextVNode(_toDisplayString('VNĐ'))
                                                              ]),
                                                              _: 1
                                                            }, 8, ["formatter", "value"])
                                                          ]),
                                                          _: 1
                                                        }, 8, ["label"]))
                                                  ]))
                                                : _createCommentVNode("", true)
                                            ]))
                                          : ($setup.formState.voucher_type ==='BUY_A_DISCOUNT_B')
                                            ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
                                                _createVNode(_component_a_form_item, {
                                                  label: _ctx.$t('APPLY_ON_NOT_APPLY')
                                                }, {
                                                  default: _withCtx(() => [
                                                    _createVNode(_component_a_radio_group, {
                                                      value: $setup.formState.condition.apply.condition,
                                                      "onUpdate:value": _cache[23] || (_cache[23] = ($event: any) => (($setup.formState.condition.apply.condition) = $event))
                                                    }, {
                                                      default: _withCtx(() => [
                                                        _createVNode(_component_a_radio, { value: "product" }, {
                                                          default: _withCtx(() => [
                                                            _createTextVNode(_toDisplayString(_ctx.$t('product')), 1)
                                                          ]),
                                                          _: 1
                                                        })
                                                      ]),
                                                      _: 1
                                                    }, 8, ["value"])
                                                  ]),
                                                  _: 1
                                                }, 8, ["label"]),
                                                ($setup.formState.condition.apply.condition === 'group')
                                                  ? (_openBlock(), _createElementBlock("div", _hoisted_11, [
                                                      _createVNode(_component_a_form_item, {
                                                        label: _ctx.$t('GROUP_LIST_TO_BUY')
                                                      }, {
                                                        default: _withCtx(() => [
                                                          _createVNode(_component_a_select, {
                                                            "show-search": "",
                                                            mode: "tags",
                                                            value: $setup.formState.condition.apply.group_products,
                                                            "onUpdate:value": _cache[24] || (_cache[24] = ($event: any) => (($setup.formState.condition.apply.group_products) = $event))
                                                          }, {
                                                            default: _withCtx(() => [
                                                              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.getProductGroups.productGroups, (i) => {
                                                                return (_openBlock(), _createBlock(_component_a_select_option, {
                                                                  key: i.id,
                                                                  value: i.id
                                                                }, {
                                                                  default: _withCtx(() => [
                                                                    _createTextVNode(_toDisplayString(i.group_name), 1)
                                                                  ]),
                                                                  _: 2
                                                                }, 1032, ["value"]))
                                                              }), 128))
                                                            ]),
                                                            _: 1
                                                          }, 8, ["value"])
                                                        ]),
                                                        _: 1
                                                      }, 8, ["label"]),
                                                      _createVNode(_component_a_form_item, {
                                                        label: _ctx.$t('DISCOUNTED_GROUP_LIST')
                                                      }, {
                                                        default: _withCtx(() => [
                                                          _createVNode(_component_a_select, {
                                                            "show-search": "",
                                                            mode: "tags",
                                                            value: $setup.formState.condition.apply.group_product_promotions,
                                                            "onUpdate:value": _cache[25] || (_cache[25] = ($event: any) => (($setup.formState.condition.apply.group_product_promotions) = $event))
                                                          }, {
                                                            default: _withCtx(() => [
                                                              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.getProductGroups.productGroups, (i) => {
                                                                return (_openBlock(), _createBlock(_component_a_select_option, {
                                                                  key: i.id,
                                                                  value: i.id
                                                                }, {
                                                                  default: _withCtx(() => [
                                                                    _createTextVNode(_toDisplayString(i.group_name), 1)
                                                                  ]),
                                                                  _: 2
                                                                }, 1032, ["value"]))
                                                              }), 128))
                                                            ]),
                                                            _: 1
                                                          }, 8, ["value"])
                                                        ]),
                                                        _: 1
                                                      }, 8, ["label"])
                                                    ]))
                                                  : (_openBlock(), _createElementBlock("div", _hoisted_12, [
                                                      _createVNode(_component_a_form_item, {
                                                        label: _ctx.$t('PRODUCT_LIST_TO_BUY')
                                                      }, {
                                                        default: _withCtx(() => [
                                                          _createVNode(_component_a_select, {
                                                            "show-search": "",
                                                            mode: "tags",
                                                            value: $setup.formState.condition.apply.products,
                                                            "onUpdate:value": _cache[26] || (_cache[26] = ($event: any) => (($setup.formState.condition.apply.products) = $event))
                                                          }, {
                                                            default: _withCtx(() => [
                                                              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.getStockProducts.products, (i) => {
                                                                return (_openBlock(), _createBlock(_component_a_select_option, {
                                                                  key: i.id,
                                                                  value: i.id
                                                                }, {
                                                                  default: _withCtx(() => [
                                                                    _createTextVNode(_toDisplayString(i.group_name), 1)
                                                                  ]),
                                                                  _: 2
                                                                }, 1032, ["value"]))
                                                              }), 128))
                                                            ]),
                                                            _: 1
                                                          }, 8, ["value"])
                                                        ]),
                                                        _: 1
                                                      }, 8, ["label"]),
                                                      _createVNode(_component_a_form_item, {
                                                        label: _ctx.$t('DISCOUNTED_PRODUCT_LIST')
                                                      }, {
                                                        default: _withCtx(() => [
                                                          _createVNode(_component_a_select, {
                                                            "show-search": "",
                                                            mode: "tags",
                                                            value: $setup.formState.condition.apply.product_promotion,
                                                            "onUpdate:value": _cache[27] || (_cache[27] = ($event: any) => (($setup.formState.condition.apply.product_promotion) = $event))
                                                          }, {
                                                            default: _withCtx(() => [
                                                              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.getStockProducts.products, (i) => {
                                                                return (_openBlock(), _createBlock(_component_a_select_option, {
                                                                  key: i.id,
                                                                  value: i.id
                                                                }, {
                                                                  default: _withCtx(() => [
                                                                    _createTextVNode(_toDisplayString(i.group_name), 1)
                                                                  ]),
                                                                  _: 2
                                                                }, 1032, ["value"]))
                                                              }), 128))
                                                            ]),
                                                            _: 1
                                                          }, 8, ["value"])
                                                        ]),
                                                        _: 1
                                                      }, 8, ["label"])
                                                    ])),
                                                _createVNode(_component_a_form_item, {
                                                  label: _ctx.$t('THE_NUMBER_OF_PRODUCT')
                                                }, {
                                                  default: _withCtx(() => [
                                                    ($setup.formState.condition.apply.condition === 'group')
                                                      ? (_openBlock(), _createBlock(_component_a_row, {
                                                          key: 0,
                                                          style: {"width":"100%"}
                                                        }, {
                                                          default: _withCtx(() => [
                                                            _createVNode(_component_a_col, { span: 11 }, {
                                                              default: _withCtx(() => [
                                                                _createVNode(_component_a_typography_title, { level: 5 }, {
                                                                  default: _withCtx(() => [
                                                                    _createTextVNode(_toDisplayString(_ctx.$t('NUMBER_OF_GROUPS_TO_BUY')), 1)
                                                                  ]),
                                                                  _: 1
                                                                }),
                                                                _createVNode(_component_a_form_item, null, {
                                                                  default: _withCtx(() => [
                                                                    _createVNode(_component_a_input_number, {
                                                                      value: $setup.formState.condition.apply.group_product_num,
                                                                      "onUpdate:value": _cache[28] || (_cache[28] = ($event: any) => (($setup.formState.condition.apply.group_product_num) = $event))
                                                                    }, {
                                                                      addonAfter: _withCtx(() => [
                                                                        _createTextVNode(_toDisplayString(_ctx.$t('PRODUCT')), 1)
                                                                      ]),
                                                                      _: 1
                                                                    }, 8, ["value"])
                                                                  ]),
                                                                  _: 1
                                                                })
                                                              ]),
                                                              _: 1
                                                            }),
                                                            _createVNode(_component_a_col, { span: 1 }),
                                                            _createVNode(_component_a_col, { span: 12 }, {
                                                              default: _withCtx(() => [
                                                                _createVNode(_component_a_typography_title, { level: 5 }, {
                                                                  default: _withCtx(() => [
                                                                    _createTextVNode(_toDisplayString(_ctx.$t('QUANTITY_OF_GROUPS_IS_REDUCED')), 1)
                                                                  ]),
                                                                  _: 1
                                                                }),
                                                                _createVNode(_component_a_form_item, null, {
                                                                  default: _withCtx(() => [
                                                                    _createVNode(_component_a_input_number, {
                                                                      value: $setup.formState.condition.apply.group_product_promotion_num,
                                                                      "onUpdate:value": _cache[29] || (_cache[29] = ($event: any) => (($setup.formState.condition.apply.group_product_promotion_num) = $event))
                                                                    }, {
                                                                      addonAfter: _withCtx(() => [
                                                                        _createTextVNode(_toDisplayString(_ctx.$t('PRODUCT')), 1)
                                                                      ]),
                                                                      _: 1
                                                                    }, 8, ["value"])
                                                                  ]),
                                                                  _: 1
                                                                })
                                                              ]),
                                                              _: 1
                                                            })
                                                          ]),
                                                          _: 1
                                                        }))
                                                      : (_openBlock(), _createBlock(_component_a_row, {
                                                          key: 1,
                                                          style: {"width":"100%"}
                                                        }, {
                                                          default: _withCtx(() => [
                                                            _createVNode(_component_a_col, { span: 11 }, {
                                                              default: _withCtx(() => [
                                                                _createVNode(_component_a_typography_title, { level: 5 }, {
                                                                  default: _withCtx(() => [
                                                                    _createTextVNode(_toDisplayString(_ctx.$t('NUMBER_OF_PRODUCTS_TO_BUY')), 1)
                                                                  ]),
                                                                  _: 1
                                                                }),
                                                                _createVNode(_component_a_form_item, null, {
                                                                  default: _withCtx(() => [
                                                                    _createVNode(_component_a_input_number, {
                                                                      value: $setup.formState.condition.apply.product_num,
                                                                      "onUpdate:value": _cache[30] || (_cache[30] = ($event: any) => (($setup.formState.condition.apply.product_num) = $event))
                                                                    }, {
                                                                      addonAfter: _withCtx(() => [
                                                                        _createTextVNode(_toDisplayString(_ctx.$t('PRODUCT')), 1)
                                                                      ]),
                                                                      _: 1
                                                                    }, 8, ["value"])
                                                                  ]),
                                                                  _: 1
                                                                })
                                                              ]),
                                                              _: 1
                                                            }),
                                                            _createVNode(_component_a_col, { span: 1 }),
                                                            _createVNode(_component_a_col, { span: 12 }, {
                                                              default: _withCtx(() => [
                                                                _createVNode(_component_a_typography_title, { level: 5 }, {
                                                                  default: _withCtx(() => [
                                                                    _createTextVNode(_toDisplayString(_ctx.$t('QUANTITY_OF_PRODUCT_IS_REDUCED')), 1)
                                                                  ]),
                                                                  _: 1
                                                                }),
                                                                _createVNode(_component_a_form_item, null, {
                                                                  default: _withCtx(() => [
                                                                    _createVNode(_component_a_input_number, {
                                                                      value: $setup.formState.condition.apply.product_promotion_num,
                                                                      "onUpdate:value": _cache[31] || (_cache[31] = ($event: any) => (($setup.formState.condition.apply.product_promotion_num) = $event))
                                                                    }, {
                                                                      addonAfter: _withCtx(() => [
                                                                        _createTextVNode(_toDisplayString(_ctx.$t('PRODUCT')), 1)
                                                                      ]),
                                                                      _: 1
                                                                    }, 8, ["value"])
                                                                  ]),
                                                                  _: 1
                                                                })
                                                              ]),
                                                              _: 1
                                                            })
                                                          ]),
                                                          _: 1
                                                        }))
                                                  ]),
                                                  _: 1
                                                }, 8, ["label"]),
                                                _createVNode(_component_a_form_item, {
                                                  label: _ctx.$t('DISCOUNT_%')
                                                }, {
                                                  default: _withCtx(() => [
                                                    _createVNode(_component_a_input_number, {
                                                      style: {"width":"100%"},
                                                      max: 100,
                                                      value: $setup.formState.condition.discount.discount,
                                                      "onUpdate:value": _cache[32] || (_cache[32] = ($event: any) => (($setup.formState.condition.discount.discount) = $event))
                                                    }, {
                                                      addonAfter: _withCtx(() => [
                                                        _createTextVNode(_toDisplayString('%'))
                                                      ]),
                                                      _: 1
                                                    }, 8, ["value"])
                                                  ]),
                                                  _: 1
                                                }, 8, ["label"]),
                                                _createVNode(_component_a_form_item, {
                                                  label: _ctx.$t('ONLY_APPLY_FOR_BILL_FROM')
                                                }, {
                                                  default: _withCtx(() => [
                                                    _createVNode(_component_a_input_number, {
                                                      style: {"width":"100%"},
                                                      formatter: value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ','),
                                                      value: $setup.formState.condition.discount.apply_to_bill_from,
                                                      "onUpdate:value": _cache[33] || (_cache[33] = ($event: any) => (($setup.formState.condition.discount.apply_to_bill_from) = $event))
                                                    }, {
                                                      addonAfter: _withCtx(() => [
                                                        _createTextVNode(_toDisplayString('VNĐ'))
                                                      ]),
                                                      _: 1
                                                    }, 8, ["formatter", "value"])
                                                  ]),
                                                  _: 1
                                                }, 8, ["label"])
                                              ]))
                                            : ($setup.formState.voucher_type ==='BUY_X_GIVE_AWAY_Y')
                                              ? (_openBlock(), _createElementBlock("div", _hoisted_13, [
                                                  _createVNode(_component_a_form_item, {
                                                    label: _ctx.$t('APPLY_ON_NOT_APPLY')
                                                  }, {
                                                    default: _withCtx(() => [
                                                      _createVNode(_component_a_radio_group, {
                                                        value: $setup.formState.condition.apply.condition,
                                                        "onUpdate:value": _cache[34] || (_cache[34] = ($event: any) => (($setup.formState.condition.apply.condition) = $event))
                                                      }, {
                                                        default: _withCtx(() => [
                                                          _createVNode(_component_a_radio, { value: "product" }, {
                                                            default: _withCtx(() => [
                                                              _createTextVNode(_toDisplayString(_ctx.$t('product')), 1)
                                                            ]),
                                                            _: 1
                                                          })
                                                        ]),
                                                        _: 1
                                                      }, 8, ["value"])
                                                    ]),
                                                    _: 1
                                                  }, 8, ["label"]),
                                                  ($setup.formState.condition.apply.condition === 'group')
                                                    ? (_openBlock(), _createElementBlock("div", _hoisted_14, [
                                                        _createVNode(_component_a_form_item, {
                                                          label: _ctx.$t('GROUP_LIST_TO_BUY')
                                                        }, {
                                                          default: _withCtx(() => [
                                                            _createVNode(_component_a_select, {
                                                              "show-search": "",
                                                              mode: "tags",
                                                              value: $setup.formState.condition.apply.group_products,
                                                              "onUpdate:value": _cache[35] || (_cache[35] = ($event: any) => (($setup.formState.condition.apply.group_products) = $event))
                                                            }, {
                                                              default: _withCtx(() => [
                                                                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.getProductGroups.productGroups, (i) => {
                                                                  return (_openBlock(), _createBlock(_component_a_select_option, {
                                                                    key: i.id,
                                                                    value: i.id
                                                                  }, {
                                                                    default: _withCtx(() => [
                                                                      _createTextVNode(_toDisplayString(i.group_name), 1)
                                                                    ]),
                                                                    _: 2
                                                                  }, 1032, ["value"]))
                                                                }), 128))
                                                              ]),
                                                              _: 1
                                                            }, 8, ["value"])
                                                          ]),
                                                          _: 1
                                                        }, 8, ["label"]),
                                                        _createVNode(_component_a_form_item, {
                                                          label: _ctx.$t('GIVEN_AWAY_GROUP_LIST')
                                                        }, {
                                                          default: _withCtx(() => [
                                                            _createVNode(_component_a_select, {
                                                              "show-search": "",
                                                              mode: "tags",
                                                              value: $setup.formState.condition.apply.group_product_promotions,
                                                              "onUpdate:value": _cache[36] || (_cache[36] = ($event: any) => (($setup.formState.condition.apply.group_product_promotions) = $event))
                                                            }, {
                                                              default: _withCtx(() => [
                                                                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.getProductGroups.productGroups, (i) => {
                                                                  return (_openBlock(), _createBlock(_component_a_select_option, {
                                                                    key: i.id,
                                                                    value: i.id
                                                                  }, {
                                                                    default: _withCtx(() => [
                                                                      _createTextVNode(_toDisplayString(i.group_name), 1)
                                                                    ]),
                                                                    _: 2
                                                                  }, 1032, ["value"]))
                                                                }), 128))
                                                              ]),
                                                              _: 1
                                                            }, 8, ["value"])
                                                          ]),
                                                          _: 1
                                                        }, 8, ["label"])
                                                      ]))
                                                    : (_openBlock(), _createElementBlock("div", _hoisted_15, [
                                                        _createVNode(_component_a_form_item, {
                                                          label: _ctx.$t('PRODUCT_LIST_TO_BUY')
                                                        }, {
                                                          default: _withCtx(() => [
                                                            _createVNode(_component_a_select, {
                                                              "show-search": "",
                                                              mode: "tags",
                                                              value: $setup.formState.condition.apply.products,
                                                              "onUpdate:value": _cache[37] || (_cache[37] = ($event: any) => (($setup.formState.condition.apply.products) = $event))
                                                            }, {
                                                              default: _withCtx(() => [
                                                                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.getStockProducts.products, (i) => {
                                                                  return (_openBlock(), _createBlock(_component_a_select_option, {
                                                                    key: i.id,
                                                                    value: i.id
                                                                  }, {
                                                                    default: _withCtx(() => [
                                                                      _createTextVNode(_toDisplayString(i.product_name), 1)
                                                                    ]),
                                                                    _: 2
                                                                  }, 1032, ["value"]))
                                                                }), 128))
                                                              ]),
                                                              _: 1
                                                            }, 8, ["value"])
                                                          ]),
                                                          _: 1
                                                        }, 8, ["label"]),
                                                        _createVNode(_component_a_form_item, {
                                                          label: _ctx.$t('GIVEN_AWAY_PRODUCT_LIST')
                                                        }, {
                                                          default: _withCtx(() => [
                                                            _createVNode(_component_a_select, {
                                                              "show-search": "",
                                                              mode: "tags",
                                                              value: $setup.formState.condition.apply.product_promotion,
                                                              "onUpdate:value": _cache[38] || (_cache[38] = ($event: any) => (($setup.formState.condition.apply.product_promotion) = $event))
                                                            }, {
                                                              default: _withCtx(() => [
                                                                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.getStockProducts.products, (i) => {
                                                                  return (_openBlock(), _createBlock(_component_a_select_option, {
                                                                    key: i.id,
                                                                    value: i.id
                                                                  }, {
                                                                    default: _withCtx(() => [
                                                                      _createTextVNode(_toDisplayString(i.product_name), 1)
                                                                    ]),
                                                                    _: 2
                                                                  }, 1032, ["value"]))
                                                                }), 128))
                                                              ]),
                                                              _: 1
                                                            }, 8, ["value"])
                                                          ]),
                                                          _: 1
                                                        }, 8, ["label"])
                                                      ])),
                                                  _createVNode(_component_a_form_item, {
                                                    label: _ctx.$t('THE_NUMBER_OF_PRODUCT')
                                                  }, {
                                                    default: _withCtx(() => [
                                                      ($setup.formState.condition.apply.condition === 'group')
                                                        ? (_openBlock(), _createBlock(_component_a_row, {
                                                            key: 0,
                                                            style: {"width":"100%"}
                                                          }, {
                                                            default: _withCtx(() => [
                                                              _createVNode(_component_a_col, { span: 11 }, {
                                                                default: _withCtx(() => [
                                                                  _createVNode(_component_a_typography_title, { level: 5 }, {
                                                                    default: _withCtx(() => [
                                                                      _createTextVNode(_toDisplayString(_ctx.$t('NUMBER_OF_GROUPS_TO_BUY')), 1)
                                                                    ]),
                                                                    _: 1
                                                                  }),
                                                                  _createVNode(_component_a_form_item, null, {
                                                                    default: _withCtx(() => [
                                                                      _createVNode(_component_a_input, {
                                                                        value: $setup.formState.condition.apply.group_product_num,
                                                                        "onUpdate:value": _cache[39] || (_cache[39] = ($event: any) => (($setup.formState.condition.apply.group_product_num) = $event))
                                                                      }, {
                                                                        addonAfter: _withCtx(() => [
                                                                          _createTextVNode(_toDisplayString(_ctx.$t('PRODUCT')), 1)
                                                                        ]),
                                                                        _: 1
                                                                      }, 8, ["value"])
                                                                    ]),
                                                                    _: 1
                                                                  })
                                                                ]),
                                                                _: 1
                                                              }),
                                                              _createVNode(_component_a_col, { span: 1 }),
                                                              _createVNode(_component_a_col, { span: 12 }, {
                                                                default: _withCtx(() => [
                                                                  _createVNode(_component_a_typography_title, { level: 5 }, {
                                                                    default: _withCtx(() => [
                                                                      _createTextVNode(_toDisplayString(_ctx.$t('QUANTITY_OF_GROUPS_IS_GIVEN_AWAY')), 1)
                                                                    ]),
                                                                    _: 1
                                                                  }),
                                                                  _createVNode(_component_a_form_item, null, {
                                                                    default: _withCtx(() => [
                                                                      _createVNode(_component_a_input, {
                                                                        value: $setup.formState.condition.apply.group_product_promotion_num,
                                                                        "onUpdate:value": _cache[40] || (_cache[40] = ($event: any) => (($setup.formState.condition.apply.group_product_promotion_num) = $event))
                                                                      }, {
                                                                        addonAfter: _withCtx(() => [
                                                                          _createTextVNode(_toDisplayString(_ctx.$t('PRODUCT')), 1)
                                                                        ]),
                                                                        _: 1
                                                                      }, 8, ["value"])
                                                                    ]),
                                                                    _: 1
                                                                  })
                                                                ]),
                                                                _: 1
                                                              })
                                                            ]),
                                                            _: 1
                                                          }))
                                                        : (_openBlock(), _createBlock(_component_a_row, {
                                                            key: 1,
                                                            style: {"width":"100%"}
                                                          }, {
                                                            default: _withCtx(() => [
                                                              _createVNode(_component_a_col, { span: 11 }, {
                                                                default: _withCtx(() => [
                                                                  _createVNode(_component_a_typography_title, { level: 5 }, {
                                                                    default: _withCtx(() => [
                                                                      _createTextVNode(_toDisplayString(_ctx.$t('NUMBER_OF_PRODUCTS_TO_BUY')), 1)
                                                                    ]),
                                                                    _: 1
                                                                  }),
                                                                  _createVNode(_component_a_form_item, null, {
                                                                    default: _withCtx(() => [
                                                                      _createVNode(_component_a_input_number, {
                                                                        value: $setup.formState.condition.apply.product_num,
                                                                        "onUpdate:value": _cache[41] || (_cache[41] = ($event: any) => (($setup.formState.condition.apply.product_num) = $event))
                                                                      }, {
                                                                        addonAfter: _withCtx(() => [
                                                                          _createTextVNode(_toDisplayString(_ctx.$t('PRODUCT')), 1)
                                                                        ]),
                                                                        _: 1
                                                                      }, 8, ["value"])
                                                                    ]),
                                                                    _: 1
                                                                  })
                                                                ]),
                                                                _: 1
                                                              }),
                                                              _createVNode(_component_a_col, { span: 1 }),
                                                              _createVNode(_component_a_col, { span: 12 }, {
                                                                default: _withCtx(() => [
                                                                  _createVNode(_component_a_typography_title, { level: 5 }, {
                                                                    default: _withCtx(() => [
                                                                      _createTextVNode(_toDisplayString(_ctx.$t('QUANTITY_OF_PRODUCT_IS_GIVEN_AWAY')), 1)
                                                                    ]),
                                                                    _: 1
                                                                  }),
                                                                  _createVNode(_component_a_form_item, null, {
                                                                    default: _withCtx(() => [
                                                                      _createVNode(_component_a_input_number, {
                                                                        value: $setup.formState.condition.apply.product_promotion_num,
                                                                        "onUpdate:value": _cache[42] || (_cache[42] = ($event: any) => (($setup.formState.condition.apply.product_promotion_num) = $event))
                                                                      }, {
                                                                        addonAfter: _withCtx(() => [
                                                                          _createTextVNode(_toDisplayString(_ctx.$t('PRODUCT')), 1)
                                                                        ]),
                                                                        _: 1
                                                                      }, 8, ["value"])
                                                                    ]),
                                                                    _: 1
                                                                  })
                                                                ]),
                                                                _: 1
                                                              })
                                                            ]),
                                                            _: 1
                                                          }))
                                                    ]),
                                                    _: 1
                                                  }, 8, ["label"]),
                                                  _createVNode(_component_a_form_item, {
                                                    label: _ctx.$t('ONLY_APPLY_FOR_BILL_FROM')
                                                  }, {
                                                    default: _withCtx(() => [
                                                      _createVNode(_component_a_input_number, {
                                                        style: {"width":"100%"},
                                                        formatter: value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ','),
                                                        value: $setup.formState.condition.discount.apply_to_bill_from,
                                                        "onUpdate:value": _cache[43] || (_cache[43] = ($event: any) => (($setup.formState.condition.discount.apply_to_bill_from) = $event))
                                                      }, {
                                                        addonAfter: _withCtx(() => [
                                                          _createTextVNode(_toDisplayString('VNĐ'))
                                                        ]),
                                                        _: 1
                                                      }, 8, ["formatter", "value"])
                                                    ]),
                                                    _: 1
                                                  }, 8, ["label"])
                                                ]))
                                              : ($setup.formState.voucher_type ==='SAME_PRICE')
                                                ? (_openBlock(), _createElementBlock("div", _hoisted_16, [
                                                    _createVNode(_component_a_form_item, {
                                                      label: _ctx.$t('APPLY_ON_NOT_APPLY')
                                                    }, {
                                                      default: _withCtx(() => [
                                                        _createVNode(_component_a_row, { style: {"margin-bottom":"10px"} }, {
                                                          default: _withCtx(() => [
                                                            _createVNode(_component_a_col, { span: 6 }, {
                                                              default: _withCtx(() => [
                                                                _createVNode(_component_a_select, {
                                                                  allowClea: "",
                                                                  style: {"width":"100%"},
                                                                  placeholder: "Please select",
                                                                  value: $setup.formState.condition.apply.type,
                                                                  "onUpdate:value": _cache[44] || (_cache[44] = ($event: any) => (($setup.formState.condition.apply.type) = $event)),
                                                                  onChange: _ctx.changePage
                                                                }, {
                                                                  default: _withCtx(() => [
                                                                    _createVNode(_component_a_select_option, { value: 'apply_on' }, {
                                                                      default: _withCtx(() => [
                                                                        _createTextVNode(_toDisplayString(_ctx.$t('apply_on')), 1)
                                                                      ]),
                                                                      _: 1
                                                                    }),
                                                                    _createVNode(_component_a_select_option, { value: 'not_apply' }, {
                                                                      default: _withCtx(() => [
                                                                        _createTextVNode(_toDisplayString(_ctx.$t('not_apply')), 1)
                                                                      ]),
                                                                      _: 1
                                                                    })
                                                                  ]),
                                                                  _: 1
                                                                }, 8, ["value", "onChange"])
                                                              ]),
                                                              _: 1
                                                            }),
                                                            _createVNode(_component_a_col, { span: 6 }, {
                                                              default: _withCtx(() => [
                                                                _createVNode(_component_a_select, {
                                                                  allowClea: "",
                                                                  style: {"width":"100%"},
                                                                  placeholder: "Please select",
                                                                  value: $setup.formState.condition.apply.condition,
                                                                  "onUpdate:value": _cache[45] || (_cache[45] = ($event: any) => (($setup.formState.condition.apply.condition) = $event)),
                                                                  onChange: _ctx.changePage
                                                                }, {
                                                                  default: _withCtx(() => [
                                                                    _createVNode(_component_a_select_option, { value: 'product' }, {
                                                                      default: _withCtx(() => [
                                                                        _createTextVNode(_toDisplayString(_ctx.$t('product')), 1)
                                                                      ]),
                                                                      _: 1
                                                                    })
                                                                  ]),
                                                                  _: 1
                                                                }, 8, ["value", "onChange"])
                                                              ]),
                                                              _: 1
                                                            })
                                                          ]),
                                                          _: 1
                                                        }),
                                                        ($setup.formState.condition.apply.condition === 'group')
                                                          ? (_openBlock(), _createBlock(_component_a_select, {
                                                              key: 0,
                                                              "show-search": "",
                                                              mode: "tags",
                                                              value: $setup.formState.condition.apply.group_products,
                                                              "onUpdate:value": _cache[46] || (_cache[46] = ($event: any) => (($setup.formState.condition.apply.group_products) = $event))
                                                            }, {
                                                              default: _withCtx(() => [
                                                                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.getProductGroups.productGroups, (i) => {
                                                                  return (_openBlock(), _createBlock(_component_a_select_option, {
                                                                    key: i.id,
                                                                    value: i.id
                                                                  }, {
                                                                    default: _withCtx(() => [
                                                                      _createTextVNode(_toDisplayString(i.group_name), 1)
                                                                    ]),
                                                                    _: 2
                                                                  }, 1032, ["value"]))
                                                                }), 128))
                                                              ]),
                                                              _: 1
                                                            }, 8, ["value"]))
                                                          : (_openBlock(), _createBlock(_component_a_select, {
                                                              key: 1,
                                                              "show-search": "",
                                                              mode: "tags",
                                                              value: $setup.formState.condition.apply.products,
                                                              "onUpdate:value": _cache[47] || (_cache[47] = ($event: any) => (($setup.formState.condition.apply.products) = $event))
                                                            }, {
                                                              default: _withCtx(() => [
                                                                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.getStockProducts.products, (i) => {
                                                                  return (_openBlock(), _createBlock(_component_a_select_option, {
                                                                    key: i.id,
                                                                    value: i.id
                                                                  }, {
                                                                    default: _withCtx(() => [
                                                                      _createTextVNode(_toDisplayString(i.product_name), 1)
                                                                    ]),
                                                                    _: 2
                                                                  }, 1032, ["value"]))
                                                                }), 128))
                                                              ]),
                                                              _: 1
                                                            }, 8, ["value"]))
                                                      ]),
                                                      _: 1
                                                    }, 8, ["label"]),
                                                    _createVNode(_component_a_form_item, {
                                                      label: _ctx.$t('SAME_PRICE')
                                                    }, {
                                                      default: _withCtx(() => [
                                                        _createVNode(_component_a_input_number, {
                                                          value: $setup.formState.condition.discount.same_price,
                                                          "onUpdate:value": _cache[48] || (_cache[48] = ($event: any) => (($setup.formState.condition.discount.same_price) = $event)),
                                                          style: {"width":"100%"},
                                                          formatter: value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                                                        }, {
                                                          addonAfter: _withCtx(() => [
                                                            _createTextVNode(_toDisplayString('VNĐ'))
                                                          ]),
                                                          _: 1
                                                        }, 8, ["value", "formatter"])
                                                      ]),
                                                      _: 1
                                                    }, 8, ["label"])
                                                  ]))
                                                : ($setup.formState.voucher_type ==='OTHER_OFFERS')
                                                  ? (_openBlock(), _createElementBlock("div", _hoisted_17, [
                                                      _createVNode(_component_a_form_item, {
                                                        label: _ctx.$t('URL')
                                                      }, {
                                                        default: _withCtx(() => [
                                                          _createVNode(_component_a_input, {
                                                            placeholder: _ctx.$t('PLEASE_ENTER_LINK')
                                                          }, null, 8, ["placeholder"])
                                                        ]),
                                                        _: 1
                                                      }, 8, ["label"])
                                                    ]))
                                                  : _createCommentVNode("", true)
                                    ]),
                                    _: 1
                                  }, 8, ["rules", "model"])
                                ]),
                                _: 1
                              })
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      })
                    ]))
                  : _createCommentVNode("", true)
          ]))
        : _createCommentVNode("", true),
      ($setup.current === 2)
        ? (_openBlock(), _createElementBlock("div", _hoisted_18, [
            _createVNode(_component_a_row, { style: {"justify-content":"flex-end"} }, {
              default: _withCtx(() => [
                _createVNode(_component_a_button, {
                  type: "primary",
                  style: {"margin-right":"10px","margin-bottom":"20px"},
                  onClick: $setup.timeVoucher
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_send_outlined),
                    _createTextVNode(" " + _toDisplayString(_ctx.$t("next")), 1)
                  ]),
                  _: 1
                }, 8, ["onClick"])
              ]),
              _: 1
            }),
            _createVNode(_component_a_row, { justify: "center" }, {
              default: _withCtx(() => [
                _createVNode(_component_a_card, { style: {"border-radius":"10px","width":"47%"} }, {
                  default: _withCtx(() => [
                    _createVNode(_component_a_row, { justify: "center" }, {
                      default: _withCtx(() => [
                        _createVNode(_component_a_typography_text, {
                          style: {"margin-bottom":"20px"},
                          strong: ""
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_ctx.$t('Time')), 1)
                          ]),
                          _: 1
                        }),
                        _createVNode(_component_a_form, {
                          "label-col": { span: 10 },
                          "wrapper-col": { span: 14 },
                          style: {"width":"100%"},
                          labelAlign: "left",
                          ref: "formRef2",
                          rules: $setup.rangeConfig,
                          model: $setup.voucherTime
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_a_form_item, _mergeProps({
                              name: "time_application",
                              label: _ctx.$t('PROGRAM_VALIDITY_PERIOD')
                            }, $setup.rangeConfig), {
                              default: _withCtx(() => [
                                _createVNode(_component_a_range_picker, {
                                  style: {"width":"100%"},
                                  value: $setup.voucherTime.time_application,
                                  "onUpdate:value": _cache[49] || (_cache[49] = ($event: any) => (($setup.voucherTime.time_application) = $event)),
                                  format: "DD-MM-YYYY",
                                  valueFormat: 'YYYY-MM-DD'
                                }, null, 8, ["value"])
                              ]),
                              _: 1
                            }, 16, ["label"])
                          ]),
                          _: 1
                        }, 8, ["rules", "model"])
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                })
              ]),
              _: 1
            })
          ]))
        : _createCommentVNode("", true)
    ]),
    ($setup.current === 3)
      ? (_openBlock(), _createElementBlock("div", _hoisted_19, [
          _createVNode(_component_a_row, { style: {"justify-content":"flex-end"} }, {
            default: _withCtx(() => [
              _createVNode(_component_a_button, {
                disabled: $setup.voucherConfig.member_can_use_voucher && $setup.voucherConfig.number_of_uses_per_day && $setup.voucherConfig.number_of_times_to_apply_the_program ? false : true ,
                type: "primary",
                style: {"margin-right":"10px","margin-bottom":"20px"},
                onClick: $setup.publish
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_send_outlined),
                  _createTextVNode(" " + _toDisplayString(_ctx.$t("FLOW_PUBLISH")), 1)
                ]),
                _: 1
              }, 8, ["disabled", "onClick"])
            ]),
            _: 1
          }),
          _createVNode(_component_a_row, { justify: "center" }, {
            default: _withCtx(() => [
              _createVNode(_component_a_card, { style: {"border-radius":"10px","width":"50%","margin":"10px"} }, {
                default: _withCtx(() => [
                  _createVNode(_component_a_row, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_a_typography_text, {
                        style: {"margin-bottom":"20px"},
                        strong: ""
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.$t('OTHER')), 1)
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_a_form, {
                        "label-col": { span: 14 },
                        "wrapper-col": { span: 10 },
                        style: {"width":"100%"},
                        labelAlign: "left",
                        layout: ""
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_a_form_item, {
                            name: "area",
                            label: _ctx.$t('VOUCHER_IS_ONLY_APPLED_FOR_MEMBER')
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_a_select, {
                                value: $setup.voucherConfig.voucher_is_only_applied_for_member,
                                "onUpdate:value": _cache[50] || (_cache[50] = ($event: any) => (($setup.voucherConfig.voucher_is_only_applied_for_member) = $event))
                              }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_a_select_option, { value: true }, {
                                    default: _withCtx(() => [
                                      _createTextVNode(_toDisplayString(_ctx.$t('YES')), 1)
                                    ]),
                                    _: 1
                                  }),
                                  _createVNode(_component_a_select_option, { value: false }, {
                                    default: _withCtx(() => [
                                      _createTextVNode(_toDisplayString(_ctx.$t('NO')), 1)
                                    ]),
                                    _: 1
                                  })
                                ]),
                                _: 1
                              }, 8, ["value"])
                            ]),
                            _: 1
                          }, 8, ["label"]),
                          _createVNode(_component_a_form_item, {
                            name: "area",
                            label: _ctx.$t('EACH_MEMBER_CAN_USE_VOUCHER_01_TIME_MULTIPLE_TIMES')
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_a_select, {
                                value: $setup.voucherConfig.member_can_use_voucher,
                                "onUpdate:value": _cache[51] || (_cache[51] = ($event: any) => (($setup.voucherConfig.member_can_use_voucher) = $event))
                              }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_a_select_option, { value: 'MANY' }, {
                                    default: _withCtx(() => [
                                      _createTextVNode(_toDisplayString(_ctx.$t('MANY')), 1)
                                    ]),
                                    _: 1
                                  }),
                                  _createVNode(_component_a_select_option, { value: 'ONLY' }, {
                                    default: _withCtx(() => [
                                      _createTextVNode(_toDisplayString(_ctx.$t('ONLY')), 1)
                                    ]),
                                    _: 1
                                  })
                                ]),
                                _: 1
                              }, 8, ["value"])
                            ]),
                            _: 1
                          }, 8, ["label"]),
                          _createVNode(_component_a_form_item, {
                            name: "area",
                            label: _ctx.$t('NUMBER_OF_TIMES_TO_APPLY_THE_CODE_PER_DAY')
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_a_input_number, {
                                style: {"width":"100%"},
                                value: $setup.voucherConfig.number_of_uses_per_day,
                                "onUpdate:value": _cache[52] || (_cache[52] = ($event: any) => (($setup.voucherConfig.number_of_uses_per_day) = $event))
                              }, null, 8, ["value"])
                            ]),
                            _: 1
                          }, 8, ["label"]),
                          _createVNode(_component_a_form_item, {
                            name: "area",
                            label: _ctx.$t('THE_NUMBER_OF_APPLICATION_TIMES')
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_a_input_number, {
                                style: {"width":"100%"},
                                value: $setup.voucherConfig.number_of_times_to_apply_the_program,
                                "onUpdate:value": _cache[53] || (_cache[53] = ($event: any) => (($setup.voucherConfig.number_of_times_to_apply_the_program) = $event))
                              }, null, 8, ["value"])
                            ]),
                            _: 1
                          }, 8, ["label"])
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]))
      : _createCommentVNode("", true)
  ], 64))
}