import {State} from "./states";
import {Tag, TreeTag} from "@/models/Tags/Tags";
import {Employee} from "@/models/Users";
import {GetterTree} from "vuex";
import {RootState} from "@/store";
import {SelectionAnt} from "@/models/ShopPages/ConfigData";
import {Metadata} from "@/APIs/response/S_HttpResponse";

export type Getters = {
    getFlowTags(state: State): Tag[] | undefined
    getCustomerTags(state: State): Tag[] | undefined
    getTriggerTags(state: State): Tag[] | undefined
    getViralTags(state: State): Tag[] | undefined
    getAutoInboxTags(state: State): Tag[] | undefined
    getProductTags(state: State): Tag[] | undefined
    getOneByCustomerTagId(state: State): (id:number)=>Tag
    getRootTagMetadata(state: State): Metadata
    getTreeTags(state: State): TreeTag[]
}
export const getters: GetterTree<State, RootState> & Getters = {
    getFlowTags(state: State): Tag[] | undefined {
        return state.flowTags;
    },
    getCustomerTags(state: State): Tag[] | undefined {
        return state.customerTags;
    },
    getTriggerTags(state: State): Tag[] | undefined {
        return state.triggerTags;
    },
    getViralTags(state: State): Tag[] | undefined {
        return state.viralTags;
    },
    getAutoInboxTags(state: State): Tag[] | undefined {
        return state.autoInboxTags;
    },
    getProductTags(state: State): Tag[] | undefined {
        return state.productTags;
    },
    getOneByCustomerTagId: (state:State)=>(id:number):Tag => {
        const  tag= state.customerTags?.find(x=>x.id===id);
        if (tag){
            return tag
        }
        return {}
    },
    getRootTagMetadata(state: State): Metadata {
        return state.rootTagMetadata
    },
    getTreeTags(state: State): TreeTag[] {
        return state.treeTags
    }
}