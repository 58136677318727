<template>
  <a-row>
    <a-col :span="8">
      <a-row style="align-items: center">
        <a-typography-title :level="2">{{$t('conversation_tags')}}</a-typography-title>
        <a-tooltip>
          <template #title>{{$t('CONVERSATIONS_TANG_NOTE')}}</template>
          <question-circle-outlined style="font-size:  25px;margin-left: 10px;cursor: pointer"/>
        </a-tooltip>

      </a-row>
      <a-typography-text type="secondary">{{$t('update_your_tags')}}</a-typography-text>
    </a-col>
    <a-col :span="6"></a-col>
    <a-col v-if="isPage" :span="10">
      <a-row style="justify-content: flex-end">
        <a-button @click="save"  type="primary">{{$t('SAVE')}}</a-button>
      </a-row>

    </a-col>
  </a-row>
  <a-row  style="margin-top: 20px;">
    <a-col :span="11">
      <a-typography-title :level="5">{{$t('change_shop')}}</a-typography-title>
      <a-select
          allowClea
          :value="currentShop.currentShop.id"
          style="width: 100%"
          placeholder="Please select"
          @change="changeShop"
      >
        <a-select-option
            v-for="item in getShops.shops"
            :key="item.id"
            v-bind:value="item.id"
        >{{ item.shop_name }}
        </a-select-option>
      </a-select>
    </a-col>
    <a-col :span="2"></a-col>
    <a-col :span="11">
      <a-typography-title :level="5">{{$t('select_page')}}</a-typography-title>
      <a-select
          allowClea
          show-search
          style="width: 100%"
          placeholder="Please select"
          @change="changePage"
          v-model:value="getCurrentPageId"
      >
        <a-select-option
            v-for="item in configPage.pageConfig"
            :key="item.id"
            v-bind:value="item.page_id"
        > <img
            v-if="item.page_type === 2"
            draggable="false"
            src="@/assets/icons/zalo-seeklogo.com.svg"
            style="width: 20px;margin-right: 20px"
        />
          <img
              v-else-if="item.page_type === 1"
              draggable="false"
              src="@/assets/icons/facebook-svgrepo-com.svg"
              style="width: 20px;margin-right: 20px"
          />
          <img
              v-else-if="item.page_type === 3"
              draggable="false"
              src="@/assets/icons/instagram-svgrepo-com.svg"
              style="width: 20px;margin-right: 20px"
          />
          <img
              v-else-if="item.page_type === 4"
              draggable="false"
              src="@/assets/icons/line-svgrepo-com.svg"
              style="width: 20px;margin-right: 20px"
          />{{ item.page_name }}
        </a-select-option>
      </a-select>
    </a-col>
    <a-row v-if="!isPage" style="margin-top: 30px;width: 100%" justify="center">
      <a-typography-title  :level="3">{{$t('PLEASE_CHOOSE_SHOP_AND_FANPAGE')}}</a-typography-title>
    </a-row>
  </a-row>

  <div v-if="isPage" style="margin-top: 20px;">
      <a-card  style="margin-top: 20px;border-radius: 10px" :span="24">
        <template #title>
          <a-row>
            <a-col :span="8">
              <TagsOutlined />
              {{$t('conversation_tags')}}
            </a-col>
            <a-col :span="14">
            </a-col>
            <a-col :span="2">
              <a-button @click="pushTags"  style="border-radius: 50%">
                <template #icon><PlusOutlined/></template>
              </a-button>
            </a-col>
          </a-row>

        </template>

            <a-row v-for="(i,msIndex) in configData.tags" :key="i.tag_id" style="margin-bottom: 20px;width: 100%" >
              <a-col :span="10">
                <a-input @change="(e) => changTagname(msIndex,e)"   v-model:value="i.tag_name"/>
              </a-col>

              <a-col :span="4">
                <div style="display: flex">


                  <a-popover   trigger="click"

                      >
                    <template #content>
                      <ColorPicker theme="light" :color="i.tag_color" :sucker-hide="false"
                                   :sucker-canvas="suckerCanvas" :sucker-area="suckerArea"  @changeColor="(color) =>changeColor(color,i.tag_id)"
                                   @openSucker="openSucker"
                      />
                    </template>

                    <a-button :style="{'background-color':i.tag_color?i.tag_color:'white',color:i.tag_color?'white':'black','margin-right':'15px'}" @click="colorPickerVisible = i.tag_id">Click me</a-button>
                  </a-popover>

                </div>

                  {{i.tag_id}}

              </a-col>

              <a-col :span="6">
                <a-tree-select
                    show-search
                    style="width: 100%"
                    v-model:value="i.sale_tag"
                    :filterTreeNode="false"
                    :notFoundContent="null"
                    :dropdown-style="{ maxHeight: '300', overflow: 'auto' }"
                    allow-clear
                    :placeholder="$t('TAG_SELECT')"
                >
                  <a-tree-select-node v-for="item in treeTags" :key="item.id" v-bind:value="item.id">
                    <template #title>
                      <a-tag :color="item.color">
                        {{ item.name }}
                      </a-tag>
                    </template>
                    <a-tree-select-node v-for="child in item.children" :key="child.id" v-bind:value="child.id">
                      <template #title>
                        <a-tag :color="child.color">
                          {{ child.name }}
                        </a-tag>
                      </template>
                    </a-tree-select-node>

                  </a-tree-select-node>
                </a-tree-select>

              </a-col>

              <a-col :span="4">

              <a-button @click="removeTag(i.tag_id)"  style="border-radius: 50%; margin-left: 16px">
                <template #icon><MinusOutlined /></template>
              </a-button>
                </a-col >


            </a-row>
      </a-card>

  </div>



</template>
<script lang="ts"  >

import { TagsOutlined,PlusOutlined,MinusOutlined,QuestionCircleOutlined} from '@ant-design/icons-vue';
import {computed, onMounted, ref, watch} from "vue";
import {useStore} from "@/store";
import {useRouter} from "vue-router";
import {CustomersActionTypes} from "@/store/modules/customer/action-types";
import {UsersMutationTypes} from "@/store/modules/user/mutation-types";
import './vue-color-kit.css';
import {ColorPicker} from 'vue-color-kit';

import {MixUtils} from "@/utils/Utils";
import {PageConfigActionTypes} from "@/store/modules/PageConfig/action-types";
import APIs from "@/APIs/APIs";
import {message} from "ant-design-vue";
import {useI18n} from "vue-i18n";
import {PageConfigMutationTypes} from "@/store/modules/PageConfig/mutation-types";
import usePageConfigStorageStore from "../../store/PageConfig";
import useUserStore from "../../store/User";
export default {
  components: {
    TagsOutlined,
    PlusOutlined,
    ColorPicker,
    MinusOutlined,
    QuestionCircleOutlined
  },
  setup(){
    const store = useStore()
    const router = useRouter()
    const userInfo = computed(()=> store.getters.getUserInfo)
    const currentShop =  useUserStore()
    const getShops = useUserStore()
    const configPage = usePageConfigStorageStore()
    const getCurrentPageId = computed(()=> store.getters.getCurrentPageId)
const tabkey = computed(() => store.getters.getCrrentTab)


    const isPage = ref(false)

    const color = ref("#59c7f9")
    const tagColor = ref('')
    const suckerCanvas = ref(null)
    const suckerArea = ref([])
    const tagList = ref([])
    const i18n = useI18n()
    const configCurrentPage = ref({})
    const configData = ref({
      tags:[
        {

        }
      ]
    })



    const changeColor = (color:any,id:any) =>{
      configData.value.tags = configData.value.tags.map(item => {
        // @ts-ignore
        if(item.tag_id === id){
          // @ts-ignore
          item.tag_color = color.hex
        }
        return item
      })

    }
const checkAdd = ref(false)
   const pushTags = () =>{

     const randomColor = Math.floor(Math.random()*16777215).toString(16);

     configData.value.tags.push({
       tag_name: 'Thẻ' + ' ' + (configData.value.tags.length+1),
       tag_id: MixUtils.methods.makeStr(8),
       tag_color:'#' + randomColor,
       tag_keyword: null


     })
     checkAdd.value = true
     console.log(configData.value.tags)
   }
// const changTagname = (index:number,e:any)=>{
//   configData.value.tags = configData.value.tags.map((item:any,i)=>{
//     if(i === index){
//       return {
//         tag_name:item.tag_name,
//
//         tag_color: item.tag_color,
//         tag_keyword: item.tag_keyword
//       }
//     }
//     return item
//   })
//
//
// }
   const removeTag = (id:string) =>{
     // @ts-ignore
     configData.value.tags= configData.value.tags.filter(item => item.tag_id !== id)
   }
    const changeShop = async (shopId:any) => {
      const response = await APIs.init().changeShop({
        shop_id: shopId,
        // @ts-ignore
        token: userInfo.value.token
      })
      if(response.status == 200 && response.data?.data){

        store.commit(UsersMutationTypes.SET_UserInfo,{...userInfo.value, ...{token: response.data?.data}})
        // @ts-ignore
        store.commit(PageConfigMutationTypes.GET_PAGE_CONFIG,'')
        store.commit(UsersMutationTypes.SET_CurrentShop,shopId);
// @ts-ignore
        router.go()
      }
    }

   const openSucker =(isOpen:any) => {
     if (isOpen) {
       // ... canvas be created
       // this.suckerCanvas = canvas
       // this.suckerArea = [x1, y1, x2, y2]
     } else {
       // this.suckerCanvas && this.suckerCanvas.remove
     }
   }


    const changePage = (pageId:any) => {
      if(pageId){
        store.commit(PageConfigMutationTypes.GET_PAGE_CONFIG,pageId)
      }
      if( usePageConfigStorageStore.getState().pageConfig){
        isPage.value=true
        // @ts-ignore
        usePageConfigStorageStore.getState().pageConfig.map(item => {
          // @ts-ignore
          if(item.page_id === pageId ){
            configCurrentPage.value = item
            configData.value = JSON.parse(item.config_data)
            // configData.value.tags = configData.value.tags.length > 0 ? configData.value.tags : [
            //   {
            //     tag_name: "Gọi lại ",
            //     tag_id: "TqbOLjAU",
            //     tag_color: "#e80505",
            //     "tag_keyword": null
            //   },
            //   {
            //     tag_name: "Cần tư vấn",
            //     tag_id: "1tXmjYGK",
            //     tag_color: "#b2e61d",
            //     "tag_keyword": null
            //   },
            //   {
            //     tag_name: "Góp ý",
            //     tag_id: "mqll8Ad8",
            //     tag_color: "#ffaec9",
            //     "tag_keyword": null
            //   },
            //   {
            //     tag_name: "Đang gặp lỗi",
            //     tag_id: "gKrIecsc",
            //     tag_color: "#474545",
            //     "tag_keyword": null
            //   },
            //   {
            //     tag_name: "Đã mua",
            //     tag_id:"vI7haF8K",
            //     tag_color: "#22b14c",
            //     "tag_keyword": null
            //   },
            //   {
            //     tag_name: "Đã xử lý",
            //     tag_id: "AOxFWStx",
            //     tag_color: "#00a2e8",
            //     "tag_keyword": null
            //   },
            //   {
            //     tag_name: "IG",
            //     tag_id: "uQXVjIiE",
            //     tag_color: "#BA68C8"
            //   },
            //   {
            //     tag_name: "Test Viral Chat",
            //     tag_id:"NXTDRBWh",
            //     tag_color: "#1964b0"
            //   },
            //   {
            //     tag_name: "bích",
            //     tag_id: "t1muwcMY",
            //     tag_color: "#d60281",
            //     "tag_keyword": null
            //   },
            //   {
            //     tag_name: "Bị cấm vĩnh viễn autoinbox",
            //     tag_id: "IdmtR5c1",
            //     tag_color: "#64a2ff"
            //   },
            //   {
            //     tag_name: "Trang đã bị hạn chế Autoinbox",
            //     tag_id: "Dwl77a1G",
            //     tag_color: "#3dd0fe"
            //   },
            //   {
            //     tag_name: "Không có nhu cầu",
            //     tag_id: "i1iVAFW7",
            //     tag_color: "#FFAB00"
            //   },
            //   {
            //     tag_name: "Hợp tác",
            //     tag_id: "ukQh394Z",
            //     tag_color: "#00897B"
            //   },
            //   {
            //     tag_name: "Dùng thử ",
            //     tag_id: "LTqIDFqD",
            //     tag_color: "#651FFF"
            //   },
            //   {
            //     tag_name: "Gia hạn",
            //     tag_id: "0W3IA8Gv",
            //     tag_color: "#00695C"
            //   },
            //   {
            //     tag_name: "Tin nhắn bám đuổi",
            //     tag_id: "vTpJjqnu",
            //     tag_color: "#DD2C00"
            //   }
            // ]

          }

        })

      }



    }
    const fetchApi = async () => {
      // @ts-ignore
      usePageConfigStorageStore.getState().getPageConfig({shop_id: useUserStore.getState().currentShop.id})

      // store.dispatch(PageConfigActionTypes.FETCH_PAGE_CONFIG,{shop_id: currentShop.value.id})
    }
    const save = async () =>{
      // @ts-ignore
      if(typeof configData.value.missing_time !== "number"){
        // @ts-ignore
        configData.value.missing_time = Number(configData.value.missing_time)
      }
      // @ts-ignore
      if(typeof configData.value.font_size_message !== "number") {
        // @ts-ignore
        configData.value.font_size_message = Number(configData.value.missing_time)
      }
      // @ts-ignore
      if(  isNaN(configData.value.missing_time) ){
        // @ts-ignore
        configData.value.missing_time = 1
      }
      // @ts-ignore
      if( isNaN(configData.value.font_size_message) ) {
        // @ts-ignore
        configData.value.font_size_message = 16
      }
      console.log(configData.value.tags ,"conversationTagUpdate")
      try{
        const res = await APIs.init().updatePageConfig({
          // @ts-ignore
          config_data: JSON.stringify(configData.value) ,
          // @ts-ignore
          page_id: configCurrentPage.value.page_id,
          // @ts-ignore
          shop_id: useUserStore.getState().currentShop.id,
          // @ts-ignore
          page_type:configCurrentPage.value.page_type ,
          // @ts-ignore
          page_name: configCurrentPage.value.page_name,
          // @ts-ignore
          status:configCurrentPage.value.status,
          // @ts-ignore
          page_token:configCurrentPage.value.page_token
        })

        if(res.data?.status === 200){
          message.success(i18n.t(res.data?.message))
          fetchApi()

        }
      }catch (err){
        console.log(err)
      }

    }
    watch(tabkey, async (newQuestion, oldQuestion) => {
      console.log(newQuestion !== oldQuestion)
      if(newQuestion !== oldQuestion){
        if( usePageConfigStorageStore.getState().pageConfig){
          isPage.value=true
          // @ts-ignore
          usePageConfigStorageStore.getState().pageConfig.map(item => {
            // @ts-ignore
            if(item.page_id === getCurrentPageId.value){
              configCurrentPage.value = item
              configData.value = JSON.parse(item.config_data)
              console.log(configData.value.tags,'tags')

            }

          })

        }
      }
    })
    onMounted(() => {
      if(getCurrentPageId.value && usePageConfigStorageStore.getState().pageConfig){
        isPage.value=true
        // @ts-ignore
        usePageConfigStorageStore.getState().pageConfig.map(item => {
          // @ts-ignore
          if(item.page_id === getCurrentPageId.value){
            configCurrentPage.value = item
            configData.value = JSON.parse(item.config_data)
            console.log(configData.value.tags,'tags conver')
            // configData.value.tags = configData.value.tags.length >0 ? configData.value.tags : [
            //   {
            //     tag_name: "Gọi lại ",
            //     tag_id: "TqbOLjAU",
            //     tag_color: "#e80505",
            //     "tag_keyword": null
            //   },
            //   {
            //     tag_name: "Cần tư vấn",
            //     tag_id: "1tXmjYGK",
            //     tag_color: "#b2e61d",
            //     "tag_keyword": null
            //   },
            //   {
            //     tag_name: "Góp ý",
            //     tag_id: "mqll8Ad8",
            //     tag_color: "#ffaec9",
            //     "tag_keyword": null
            //   },
            //   {
            //     tag_name: "Đang gặp lỗi",
            //     tag_id: "gKrIecsc",
            //     tag_color: "#474545",
            //     "tag_keyword": null
            //   },
            //   {
            //     tag_name: "Đã mua",
            //     tag_id:"vI7haF8K",
            //     tag_color: "#22b14c",
            //     "tag_keyword": null
            //   },
            //   {
            //     tag_name: "Đã xử lý",
            //     tag_id: "AOxFWStx",
            //     tag_color: "#00a2e8",
            //     "tag_keyword": null
            //   },
            //   {
            //     tag_name: "IG",
            //     tag_id: "uQXVjIiE",
            //     tag_color: "#BA68C8"
            //   },
            //   {
            //     tag_name: "Test Viral Chat",
            //     tag_id:"NXTDRBWh",
            //     tag_color: "#1964b0"
            //   },
            //   {
            //     tag_name: "bích",
            //     tag_id: "t1muwcMY",
            //     tag_color: "#d60281",
            //     "tag_keyword": null
            //   },
            //   {
            //     tag_name: "Bị cấm vĩnh viễn autoinbox",
            //     tag_id: "IdmtR5c1",
            //     tag_color: "#64a2ff"
            //   },
            //   {
            //     tag_name: "Trang đã bị hạn chế Autoinbox",
            //     tag_id: "Dwl77a1G",
            //     tag_color: "#3dd0fe"
            //   },
            //   {
            //     tag_name: "Không có nhu cầu",
            //     tag_id: "i1iVAFW7",
            //     tag_color: "#FFAB00"
            //   },
            //   {
            //     tag_name: "Hợp tác",
            //     tag_id: "ukQh394Z",
            //     tag_color: "#00897B"
            //   },
            //   {
            //     tag_name: "Dùng thử ",
            //     tag_id: "LTqIDFqD",
            //     tag_color: "#651FFF"
            //   },
            //   {
            //     tag_name: "Gia hạn",
            //     tag_id: "0W3IA8Gv",
            //     tag_color: "#00695C"
            //   },
            //   {
            //     tag_name: "Tin nhắn bám đuổi",
            //     tag_id: "vTpJjqnu",
            //     tag_color: "#DD2C00"
            //   }
            // ]
          }

        })

      }
    })
    const treeTags = computed(() => store.getters.getTreeTags)

    const checked = ref(false)
    return{
      configData ,
      currentShop,
      checked,
      configPage,
      changeShop,
      changePage,
      isPage,
      color,
      save,
      changeColor,
      tagColor,
      suckerCanvas,
      suckerArea,
      openSucker,
      getShops,
      pushTags,
      tagList,
      removeTag,
      userInfo,
      getCurrentPageId,
      treeTags,
      // changTagname



    }
  }
}
</script>
