import create from "zustand-vue";
import { persist } from "zustand/middleware";
import APIs from "@/APIs/APIs";
import { CustomerCare } from "@/models/CustomerCares/CustomerCares";

interface CustomersCareState {
  customerCares: CustomerCare[] | undefined;
  customerDetailCares: CustomerCare[] | undefined;
  getCustomerCares: () => void;
  getCustomerDetailCares: () => void;
  getCustomerCareNameById: (params: any) => void;
  getCustomerCareById: (params: any) => void;
}

const initialState = {
  customerCares: [],
  customerDetailCares: [],
};

const useCustomersCareStore = create<CustomersCareState>(
  // @ts-ignore
  persist(
    (set) => ({
      ...initialState,
      getCustomerCares: async () => {
        const response = await APIs.init().getCustomerCares();
        if (response.data && response.data.status === 200) {
          set(() => ({
            customerCares: response.data?.data,
          }));
        }
      },
      getCustomerDetailCares: async () => {
        const response = await APIs.init().getDetailCustomerCares();
        if (response.data && response.data.status === 200) {
          set(() => ({
            customerDetailCares: response.data?.data,
          }));
        }
      },
      getCustomerCareNameById: async (params) => {
        const response = await APIs.init().sortCustomerCares(params);
        if (response.data && response.data.status === 200) {
          set(() => ({
            customerCares: response.data?.data,
          }));
        }
      },
      getCustomerCareById: async (params) => {
        const response = await APIs.init().sortCustomerCares(params);
        if (response.data && response.data.status === 200) {
          set(() => ({
            customerCares: response.data?.data,
          }));
        }
      },
    }),
    {
      name: "customers-care",
    }
  )
);

export default useCustomersCareStore;
