<template>
  <div class="container-title">
    <h3 class="title-header">{{$t($route.name)}}</h3>
    <a-button @click="visible =  true" type="primary">{{$t('ADD')}}</a-button>
  </div>
  <div style="display: flex;flex-direction: column" class="container-content">
    <a-row>
      <a-popover v-for="i in events.events" :key="i" placement="topRight">
        <template #content>
          <a-row>
            <a-typography-text>
              {{$t('start_time')}} : {{formatDate(i.start_time)}}
            </a-typography-text>
          </a-row>
          <a-row>
            <a-typography-text>
              {{$t('end_date')}} : {{formatDate(i.end_time)}}
            </a-typography-text>
          </a-row>
        </template>
        <template #title>
          <span>{{$t('time_visit')}}</span>
        </template>

         <a-card     hoverable style="width: 400px;margin:20px">

           <template #cover>
             <RouterLink :to="`/ticket/list-ticket/`+i.id">
             <img style="width: 400px;height: 400px"
                  alt="example"
                  :src="i.image_cover"
             />
             </RouterLink>
           </template>

           <template #actions>
             <a-popconfirm
                 :title="$t('EVENT_CONFIRM_DELETE_CONTEXT')"
                 :ok-text="$t('YES')"
                 :cancel-text="$t('NO')"
                 @confirm="deleteEvent(i)"
             >
               <delete-outlined key="delete"/>
             </a-popconfirm>

             <edit-outlined @click="editEvent(i)"  key="edit" />
             <RouterLink :to="`/ticket/transaction/`+i.id">
               <arrow-right-outlined />
             </RouterLink>
             <router-link :to="'/ticket/report/'+i.id">
               <BarChartOutlined />
             </router-link>
           </template>
           <RouterLink :to="`/ticket/list-ticket/`+i.id">
           <a-card-meta style="text-align: center;min-height: 161.11px"  >
             <template #title>
               {{i.name}}
             </template>
             <template #description>
               {{i.description}}

             </template>>
           </a-card-meta>
           </RouterLink>
         </a-card>

      </a-popover>



    </a-row>

     <a-row style="justify-content: flex-end">
       <a-pagination @change="changePagination" style="margin: 20px" v-model:current="page" :total="events.metadata.record_total" show-less-items />
     </a-row>

  </div>
  <a-modal v-model:visible="visible" :title="checkEdit ? $t('EDIT_EVENT'): $t('CREATED_EVENT')" @ok="handleOk">



       <a-form
           :model="event"
           ref="formCustomerRef"
           :rules="rules"
           layout="vertical"

       >

  <div class="inline-form-customer">
    <a-row style="justify-content: center">
      <div
          v-if="event.image_cover"
          style="text-align: center"
      >
        <img
            :src="event.image_cover"
            class="photo-flow-img"
            @click="showImageSingleSelectionModal(msIndex + '_' + 909)"
        />
      </div>
      <span class=" upload-center" v-else>
              <div
                  class="ant-upload ant-upload-select ant-upload-select-picture-card"
                  @click="showImageSingleSelectionModal(msIndex + '_' + 909)"
              >
                <span role="button" tabindex="0" class="ant-upload">
                  <div>
                    <plus-outlined/>
                    <div class="ant-upload-text">{{ $t("UPLOAD") }}</div>
                  </div>
                </span>
              </div>
            </span>
    </a-row>
    <a-form-item :label="$t('EVENT_NAME')" name="event_name">
      <a-input
          v-model:value="event.name"
          :placeholder="$t('EVENT_NAME')"


      />
    </a-form-item>
    <a-form-item
        style="margin-right: 20px;"
        :label="$t('SELL_TICKET_TYPE')"

    >
      <a-radio-group name="radioGroup" v-model:value="event.sell_ticket_type">
        <a-radio :value="0">{{ $t('ONE_TICKET') }}</a-radio>
        <a-radio :value="1">{{ $t('GROUP_TICKET') }}</a-radio>

      </a-radio-group>

    </a-form-item>
    <a-form-item :label="$t('TEMPLATE')" name="template_id">
      <a-select

          allowClear
          v-model:value="event.template_id"
          :placeholder="$t('TEMPLATE')"
      >
        <a-select-option
            v-for="item in templates.templates"
            :key="item.id"
            v-bind:value="item.id"
        >{{ item.name }}
        </a-select-option>
      </a-select>
    </a-form-item>
    <a-form-item :label="$t('link')" name="event_name">
      <a-input
          v-model:value="event.link"
          :placeholder="$t('link')"


      />
    </a-form-item>
    <a-form-item :label="$t('payment_callback')" name="event_name">
      <a-input
          v-model:value="event.payment_callback"
          :placeholder="$t('payment_callback')"


      />
    </a-form-item>

    <a-form-item :label="$t('DESCRIPTION')" name="phone">

      <a-textarea v-model:value="event.description"
                  :placeholder="$t('DESCRIPTION')" show-count :maxlength="1200" />
    </a-form-item>
    <a-form-item :label="$t('time_visit')" name="birth">
      <a-range-picker v-model:value="value1" format="DD-MM-YYYY HH:mm"  />
    </a-form-item>
  </div>
       </a-form>


<!--    <a-form-->
<!--        :model="event"-->
<!--        ref="formCustomerRef"-->
<!--        :rules="rules"-->
<!--        layout="vertical"-->

<!--    >-->
<!--    <a-form-item :label="$t('TICKET_TYPE_NAME')" name="event_name">-->
<!--      <a-input-->
<!--          v-model:value="ticketType.name"-->
<!--          :placeholder="$t('TICKET_TYPE_NAME')"-->


<!--      />-->
<!--    </a-form-item>-->

<!--    <a-form-item :label="$t('DESCRIPTION')" name="phone">-->

<!--      <a-textarea v-model:value="ticketType.description"-->
<!--                  :placeholder="$t('DESCRIPTION')" show-count :maxlength="1200" />-->
<!--    </a-form-item>-->
<!--      <a-form-item :label="$t('TICKET_TYPE_NAME')" name="event_name">-->
<!--        <a-input-->
<!--            v-model:value="ticketType.name"-->
<!--            :placeholder="$t('TICKET_TYPE_NAME')"-->


<!--        />-->
<!--      </a-form-item>-->
<!--      <a-form-item :label="$t('TICKET_TYPE_NAME')" name="event_name">-->
<!--        <a-input-->
<!--            v-model:value="ticketType.name"-->
<!--            :placeholder="$t('TICKET_TYPE_NAME')"-->


<!--        />-->
<!--      </a-form-item>-->
<!--    </a-form>-->


  </a-modal>
  <image-selection-modal
      ref="imageSelectionRef"
      :single="isImageSelectionSingle"
      :onSelect="onImageSelect"
  />
</template>
<script lang="ts">
import {message, notification} from 'ant-design-vue';

import {DeleteOutlined, SettingOutlined,BarChartOutlined, EditOutlined, EllipsisOutlined,ArrowRightOutlined } from '@ant-design/icons-vue';
import {useI18n} from "vue-i18n";
import {computed, onMounted, ref} from "vue";
import ImageSelectionModal from "@/components/Image/ImageSelectionModal.vue";
import dayjs from "dayjs";
import APIs from "@/APIs/APIs";
import {AWS_DO_Model} from "@/models/AWS_DO/AWS_DO";
import {useStore} from "vuex";
import {EventActionTypes} from "@/store/modules/Event/action-types";
import {EventResponse} from "@/APIs/response/S_HttpResponse";
import {TicketActionTypes} from "@/store/modules/Ticket/action-types";
export default {
  components: {
    DeleteOutlined,
    EditOutlined,
    // EllipsisOutlined,
    BarChartOutlined,
    ArrowRightOutlined,
    ImageSelectionModal
  },
  setup(){
    const i18n = useI18n()
    const store = useStore()
    const visible = ref(false)
    const checkEdit = ref(false)
    const event = ref({})
    const ticketType= ref({})
    const isImageSelectionSingle = ref(false)
    const imageSelectionRef = ref()
    const getUserInfo =computed(()=> store.getters.getUserInfo)
    const events = computed<EventResponse>(() => store.getters.getEvent)
    const templates = computed(()=> store.getters.getTicketTemplate)
    const page = ref(1)
    const showImageSingleSelectionModal = (param: string) => {
      isImageSelectionSingle.value = true;
      imageSelectionRef.value.showModal(param);
    };
    const onImageSelect = (images: AWS_DO_Model[], param: string) => {
      if (images && images.length && param) {
        let p = param.split("_");

        if (parseInt(p[1]) === 909) {
          // @ts-ignore
          event.value.image_cover = images[0].url;
        }
      }
    };
    const formatDate =  (date:any) => {
      return dayjs(date).format('DD-MM-YYYY HH:mm')
    }
    const value1 = ref()
const changePagination = (data:any) => {
      page.value = data
  fetchEvents()
}
const fetchEvents =  () =>{
      console.log(page.value)
  store.dispatch(EventActionTypes.FETCH_EVENT,{page:page.value,page_size:20,search:'',shop_id:getUserInfo.value.current_shop_id})
}

    const handleOk = async () => {
      if(checkEdit.value){
        const res = await APIs.init().updateEvent(event.value)
        if(res.data?.status === 200) {
          message.success(i18n.t('SUCCESS'))
          visible.value = false
          event.value = {}
          fetchEvents()
        }

      }else{
        // @ts-ignore
        event.value.start_time = value1.value[0]
        // @ts-ignore
        event.value.end_time = value1.value[1]
        // @ts-ignore
        event.value.shop_id = getUserInfo.value.current_shop_id
        const res = await APIs.init().addEvent(event.value)
        if(res.data?.status === 200) {
          message.success(i18n.t('SUCCESS'))
          visible.value = false
          event.value = {}
          fetchEvents()

        }
      }
    }

    const deleteEvent = async (data:any) => {
      const res = await APIs.init().deleteEvent(data)
      if(res.data?.status === 200) {
        message.success(i18n.t('alert_delete_data_success'))
        fetchEvents()
      }
    }
    const fetchTemplates =  () => {
      store.dispatch(TicketActionTypes.FETCH_TICKET_TEMPLATE,{page:page.value,page_size:20,search:'',shop_id:getUserInfo.value.current_shop_id})
    }
    const editEvent = (data:any) => {
      checkEdit.value = true
      visible.value = true
      event.value = data
      // @ts-ignore
      value1.value = [dayjs(event.value.start_time),dayjs(event.value.end_time)]
    }
    onMounted(()=>{
      fetchEvents()
      fetchTemplates()
    })
    return{
      visible,
      checkEdit,
      event,
      showImageSingleSelectionModal,
      isImageSelectionSingle,
      imageSelectionRef,value1,
      handleOk,
      onImageSelect,
      events,
      formatDate,
      deleteEvent,
      editEvent,
      fetchEvents,
      changePagination,
      ticketType,templates

    }
  }
}
</script>
<style scoped>
.photo-flow-img {
  max-height: 200px;
  object-fit: cover;
  cursor: pointer;
}
</style>
