<template>
  <a-row>
    <a-col :span="8">
      <a-typography-title :level="2">{{$t('BOOKING_TAGS')}}</a-typography-title>
      <a-typography-text type="secondary">{{$t('BOOKING_TAGS')}}</a-typography-text>
    </a-col>
    <a-col :span="6"></a-col>
    <a-col v-if="isPage" :span="10">
      <a-row style="justify-content: flex-end">
        <a-button @click="save"  type="primary">{{$t('SAVE')}}</a-button>
      </a-row>

    </a-col>
  </a-row>
  <a-row  style="margin-top: 20px;">

    <a-col :span="11">
      <a-typography-title :level="5">{{$t('change_shop')}}</a-typography-title>
      <a-select
          allowClea
          :value="currentShop.currentShop.id"
          style="width: 100%"
          placeholder="Please select"
          @change="changeShop"
      >
        <a-select-option
            v-for="item in getShops.shops"
            :key="item.id"
            v-bind:value="item.id"
        >{{ item.shop_name }}
        </a-select-option>
      </a-select>
    </a-col>
    <a-col :span="2"></a-col>
    <a-col :span="11">
      <a-typography-title :level="5">{{$t('select_page')}}</a-typography-title>
      <a-select
          allowClea
          show-search
          style="width: 100%"
          placeholder="Please select"
          @change="changePage"
          v-model:value="getCurrentPageId"
      >
        <a-select-option
            v-for="item in configPage.pageConfig"
            :key="item.id"
            v-bind:value="item.page_id"
        > <img
            v-if="item.page_type === 2"
            draggable="false"
            src="@/assets/icons/zalo-seeklogo.com.svg"
            style="width: 20px;margin-right: 20px"
        />
          <img
              v-else-if="item.page_type === 1"
              draggable="false"
              src="@/assets/icons/facebook-svgrepo-com.svg"
              style="width: 20px;margin-right: 20px"
          />
          <img
              v-else-if="item.page_type === 3"
              draggable="false"
              src="@/assets/icons/instagram-svgrepo-com.svg"
              style="width: 20px;margin-right: 20px"
          />
          <img
              v-else-if="item.page_type === 4"
              draggable="false"
              src="@/assets/icons/line-svgrepo-com.svg"
              style="width: 20px;margin-right: 20px"
          />{{ item.page_name }}
        </a-select-option>
      </a-select>
    </a-col>
    <a-row v-if="!isPage" style="margin-top: 30px;width: 100%" justify="center">
      <a-typography-title  :level="3">{{$t('PLEASE_CHOOSE_SHOP_AND_FANPAGE')}}</a-typography-title>
    </a-row>
  </a-row>

  <a-row v-if="isPage"  style="margin-top: 20px;">
    <a-button @click="pushBookingTag"  style="width: 60px;height: 40px;margin-right: 10px">
      <template #icon><plus-outlined/></template>
    </a-button>
    <a-col :span="14">
      <a-card v-for="i in configData.booking_tags" :key="i.id" style="width: 100%;margin-top: 20px;border-radius: 10px">
        <template #title>
          <a-row>
            <a-col :span="18">
              <a-input v-model:value="i.name"/>
            </a-col>
            <a-col :span="4">
            </a-col>
            <a-col :span="2">
              <a-button @click="removeBookingTag(i.id)"  style="border-radius: 50%">
                <template #icon><MinusOutlined/></template>
              </a-button>
            </a-col>
          </a-row>

        </template>

        <a-row  style="margin-top: 20px">
          <a-button @click="pushTags(i.id)"  style="border-radius: 50%">
            <template #icon><plus-outlined/></template>
          </a-button>
          <a-col style="width: 100%;margin-top: 20px">
            <a-row v-for="item in i.tags" :key="item.id" style="margin-bottom: 20px;width: 100%" >
              <a-col :span="16">
                <a-input  v-model:value="item.name"  />
              </a-col>
              <a-col :span="4"></a-col>

              <a-col :span="4">
                <a-button @click="removeTag(item.id,i.id)"  style="border-radius: 50%">
                  <template #icon><MinusOutlined /></template>
                </a-button>
              </a-col >
            </a-row>




          </a-col>
        </a-row>


      </a-card>
    </a-col>

  </a-row>



</template>
<script  >

import { TagsOutlined,PlusOutlined,MinusOutlined} from '@ant-design/icons-vue';
import {computed, onMounted, ref,watch} from "vue";
import {useStore} from "@/store";
import {useRouter} from "vue-router";
import {CustomersActionTypes} from "@/store/modules/customer/action-types";
import {UsersMutationTypes} from "@/store/modules/user/mutation-types";
import './vue-color-kit.css';

import {useI18n} from "vue-i18n";
import {MixUtils} from "@/utils/Utils";
import {PageConfigActionTypes} from "@/store/modules/PageConfig/action-types";
import APIs from "@/APIs/APIs";
import {message} from "ant-design-vue";
import {PageConfigMutationTypes} from "@/store/modules/PageConfig/mutation-types";
import usePageConfigStorageStore from "../../store/PageConfig";
import useUserStore from "../../store/User";
export default {
  components: {
    PlusOutlined,

    MinusOutlined
  },
  setup(){
    const i18n = useI18n()
    const store = useStore()
    const router = useRouter()
    const userInfo = computed(()=> store.getters.getUserInfo)
    const currentShop =  useUserStore()
    const getShops = useUserStore()
    const configPage = usePageConfigStorageStore()
    const getCurrentPageId = computed(()=> store.getters.getCurrentPageId)
    const isPage = ref(false)
    const tagList = ref([])
    const tagListBooking = ref([])
    const configCurrentPage = ref({})
    const configData = ref({})
    const tabkey = computed(() => store.getters.getCrrentTab)


    const pushBookingTag = () =>{

      try{
        if(configData.value.booking_tags){
          configData.value.booking_tags.push({
            id:MixUtils.methods.getUUID(),
            name:i18n.t('Branch') + ' ' + (configData.value.booking_tags.length+1),
            tags:[{
              id:MixUtils.methods.getUUID(),
              name:'Thẻ' + ' ' + (configData.value.booking_tags.length+1),
            }]

          })
        }else {
          configData.value.booking_tags = [{
            id:MixUtils.methods.getUUID(),
            name:i18n.t('Branch') + ' ' + 1,
            tags:[{
              id:MixUtils.methods.getUUID(),
              name:'Thẻ' + ' ' + 1,
            }]
          }]
        }
      }catch (err){
        console.log(err)
      }
      console.log(tagListBooking.value)
    }

    const pushTags = (id) =>{

      configData.value.booking_tags = configData.value.booking_tags.map(item =>{
        if(item.id === id){
          item.tags.push({
            id:MixUtils.methods.getUUID(),
            name:'Thẻ' + ' ' + (item.tags.length+1),
          })
        }
        return item
      })
      console.log(tagListBooking.value)
    }
    const  removeBookingTag = (id) => {
      configData.value.booking_tags = configData.value.booking_tags.filter(item => item.id !== id)
    }
    const removeTag = (tagId,id) =>{
      console.log(tagId,id)
      configData.value.booking_tags = configData.value.booking_tags.map(item =>{
        if(item.id === id){
        item.tags = item.tags.filter(i => i.id !==tagId)
        }
        return item
      })
    }
    const changeShop = async (shopId) => {
      const response = await APIs.init().changeShop({
        shop_id: shopId,

        token: userInfo.value.token
      })
      if(response.status == 200 && response.data?.data){

        store.commit(UsersMutationTypes.SET_UserInfo,{...userInfo.value, ...{token: response.data?.data}})

        store.commit(PageConfigMutationTypes.GET_PAGE_CONFIG,'')
        store.commit(UsersMutationTypes.SET_CurrentShop,shopId);

        router.go()
      }
    }


    const changePage = (pageId) => {
      if(pageId){
        store.commit(PageConfigMutationTypes.GET_PAGE_CONFIG,pageId)
      }
      if( configPage.pageConfig){
        isPage.value=true
        configPage.pageConfig.map(item => {
          if(item.page_id === pageId ){
            configCurrentPage.value = item
            configData.value = JSON.parse(item.config_data)

          }

        })

      }



    }

    const fetchApi = async () => {
      usePageConfigStorageStore.getState().getPageConfig({shop_id: useUserStore.getState().currentShop.id})

      // store.dispatch(PageConfigActionTypes.FETCH_PAGE_CONFIG,{shop_id: useUserStore.getState().currentShop.id})
    }
    const save = async () =>{
      if(typeof configData.value.missing_time !== "number"){
        configData.value.missing_time = Number(configData.value.missing_time)
      }
      if(typeof configData.value.font_size_message !== "number") {
        configData.value.font_size_message = Number(configData.value.missing_time)
      }
      if(  isNaN(configData.value.missing_time) ){
        configData.value.missing_time = 1
      }
      if( isNaN(configData.value.font_size_message) ) {
        configData.value.font_size_message = 16
      }

      console.log({
        config_data: JSON.stringify(configData.value) ,
        page_id: configCurrentPage.value.page_id,
        shop_id: useUserStore.getState().currentShop.id,
        page_type:configCurrentPage.value.page_type ,
        page_name: configCurrentPage.value.page_name,
        status:configCurrentPage.value.status
      },"bookingTagUpdate")
      try{
        const res = await APIs.init().updatePageConfig({
          config_data: JSON.stringify(configData.value) ,
          page_id: configCurrentPage.value.page_id,
          shop_id: useUserStore.getState().currentShop.id,
          page_type:configCurrentPage.value.page_type ,

          page_name: configCurrentPage.value.page_name,
          status:configCurrentPage.value.status,
          page_token:configCurrentPage.value.page_token
        })

        if(res.data?.status === 200){
          message.success(i18n.t(res.data?.message))
        await  fetchApi()

        }
      }catch (err){
        console.log(err)
      }

    }
    watch(tabkey, async (newQuestion, oldQuestion) => {
      if(newQuestion !== oldQuestion){
        if( configPage.pageConfig){
          isPage.value=true
          configPage.pageConfig.map(item => {
            // @ts-ignore
            if(item.page_id === getCurrentPageId.value){
              configCurrentPage.value = item
              configData.value = JSON.parse(item.config_data)
              console.log(configData.value.tags,'tags')

            }

          })

        }
      }
    })
onMounted(() => {
  if(getCurrentPageId.value && configPage.pageConfig){
    isPage.value=true
    configPage.pageConfig.map(item => {
      if(item.page_id === getCurrentPageId.value){
        configCurrentPage.value = item
        configData.value = JSON.parse(item.config_data)

      }

    })

  }
})
    const checked = ref(false)
    return{
      userInfo,
      getShops,
      currentShop,
      save,
      checked,
      configPage,
      configData,
      changeShop,
      changePage,
      isPage,
      removeBookingTag,
      pushTags,
      tagList,
      removeTag,
      pushBookingTag,
      tagListBooking,
      getCurrentPageId


    }
  }
}
</script>
