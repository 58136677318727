<template>
  <div class="header-container" v-if="!isWalletFetching||wallet">
    <div class="wallet-container-card">
      <div class="wallet-background-container">
        <img src="@/assets/images/wallet_background_group.svg" class="wallet-background-group">
        <div class="wallet-label-overlay">
          <h3>
           {{$t('MY_WALLET')}}
          </h3>
          <h1>
            {{ wallet?.balance }}
          </h1>
        </div>
      </div>
      <div class="button-deposit">
        <div class="button-deposit-content">
          <img src="@/assets/icons/Pluswallet_deposit.svg" class="icon-deposit">
          <div>
            {{$t('DEPOSIT')}}
          </div>
        </div>

      </div>
    </div>
    <div class="filter-transaction">
      <a-popconfirm
          title="Are you sure export this wallet"
          ok-text="Yes"
          cancel-text="No"
          @confirm="confirmExport"
      >
        <a-button class="button-export">
          <template #icon>
            <file-text-outlined />
          </template>
          Export
        </a-button>
      </a-popconfirm>
      <a-button v-if="currentShop.id === 121212 && (userInfo.id_cms_privileges === 1 || userInfo.id_cms_privileges === 3) " @click="visible = true" class="button-export">
        <template #icon>
          <dollar-circle-outlined />
        </template>
        {{$t('CHARGED')}}
      </a-button>

      <div>
        <div class="item-filter">
          <span class="filter-by-date-label">
            {{$t('FILTER_BY_TIME')}}
          </span>
          <a-range-picker
              v-model:value="rangeDate"
              :ranges="ranges"
              :format="dateFormat"
              show-time
              @change="rangeDateChange"
          />
        </div>
      </div>
    </div>
  </div>
  <span v-else>
      <a-spin size="small" :style="{ marginLeft: 8, marginRight: 8 }"/>
    </span>
  <a-table :columns="columns" :data-source="walletTransactions" class="table-transaction" :pagination="true">
    <template #transaction_type="{text}">
      <router-link v-if="text ==='ZNS_CHARGED'" :to="RouterEndpoint.REPORT_ZNS_TRANSACTION">
        {{ text }}
      </router-link>

      <span v-if="text ==='DEPOSIT_MONEY_TO_ACCOUNT'">
        {{$t(text)}}
      </span>

    </template>
    <template #note="{text}">
      <span >
        {{$t(text)}}
      </span>

    </template>
    <template #change_money="{record}">
      <span>
        <span v-if="record.change_type==='CHARGED' ">
          +
        </span>
        <span v-else>
          -
        </span>
        {{record.change_money}}
      </span>
    </template>
    <template #customer="{record}">
       <div v-if="record.type==='ZNS_CHARGED' ">
         <div v-for="i in record.extra_data" :key="i.key">
           <span v-if="i.key === 'customer_name'">{{i.value}}</span>
         </div>
       </div>
      <div v-if="record.type==='DEPOSIT_MONEY_TO_ACCOUNT' ">
      <div v-for="i in record.extra_data" :key="i.Key">
        <span v-if="i.Key === 'name'">{{i.Value}}</span>
      </div>
    </div>

    </template>
  </a-table>
  <a-modal v-model:visible="visible" :title="$t('CHARGED')" @ok="handleOk">
    <a-form
        :model="formState"
        name="basic"
        :label-col="{ span: 6 }"
        :wrapper-col="{ span: 18 }"
        autocomplete="off"
        @finish="onFinish"
        @finishFailed="onFinishFailed"
    >
      <a-form-item
          :label="$t('USER_ID')"

      >
        <a-input v-model:value="formState.user_id" />
      </a-form-item>
      <a-form-item
          :label="$t('AMOUNT_OF_MONEY')"

      >
        <a-input v-model:value="formState.money" />
      </a-form-item>
    </a-form>
  </a-modal>
</template>

<script lang="ts">
import {computed, defineComponent, onBeforeMount, ref} from "vue";
import RouterEndpoint from "@/router/RouterEndpoint";
import {useStore} from "@/store";
import {Wallet} from "@/models/Wallet/Wallet";
import moment, {Moment} from "moment";
import {WalletTransactionRequest} from "@/APIs/request/WalletRequest";
import {WalletActionTypes} from "@/store/modules/wallet/action-types";
import {WalletTransaction} from "@/models/Wallet/WalletTransaction";
import {useI18n} from "vue-i18n";
import {FileTextOutlined,DollarCircleOutlined} from "@ant-design/icons-vue";
import APIs from "@/APIs/APIs";
import {Constant} from "@/utils/Constants";
import {message} from "ant-design-vue";
import {ApiResponse} from "apisauce";
import {WalletTransactionsResponse} from "@/APIs/response/S_HttpResponse";
import type {Dayjs} from "dayjs";
import dayjs from "dayjs";

const data = [
  {
    key: '1',
    _id: '13123231',
    money_change: '300',
    transaction_type: 'ZNS_CHARGED',
    note: 'ZNS paid 300 vnd for template "1234" was sent to Thu Ky Lee(13244)',
    extra_info: "",
    weight: 100,
    created_at: '24-02-2022',
  },
  {
    key: '2',
    _id: '13123231',
    money_change: '300',
    transaction_type: 'ZNS_CHARGED',
    note: 'ZNS paid 300 vnd for template "1234" was sent to Thu Ky Lee(13244)',
    extra_info: "",
    weight: 100,
    created_at: '24-02-2022',
  },
  {
    key: '3',
    _id: '13123231',
    money_change: '300',
    transaction_type: 'ZNS_CHARGED',
    note: 'ZNS paid 300 vnd for template "1234" was sent to Thu Ky Lee(13244)',
    extra_info: "",
    weight: 100,
    created_at: '24-02-2022',
  },
  {
    key: '4',
    _id: '13123231',
    money_change: '300',
    transaction_type: 'ZNS_CHARGED',
    note: 'ZNS paid 300 vnd for template "1234" was sent to Thu Ky Lee(13244)',
    extra_info: "",
    weight: 100,
    created_at: '24-02-2022',
  },
]

const dateFormat = 'DD-MM-YYYY HH:mm:ss';
export default defineComponent({
  components:{
    FileTextOutlined,
    DollarCircleOutlined
  },
  setup() {
    const store = useStore();
    const i18n = useI18n();
    const isWalletFetching = computed((): boolean => store.getters.getWalletFetching)
    const wallet = computed((): Wallet | undefined => store.getters.getWallet)
    const walletTransactions = computed(() => store.getters.getWalletTransactions)
    console.log(walletTransactions.value,'sdas')
    const walletTransactionCount = computed((): number | undefined => store.getters.getWalletTransactionCount)
    const rangeDate = ref<Dayjs[]>([dayjs().subtract(7, 'days'),dayjs()])
    const currentShop = computed(() => store.getters.getCurrentShop)
    const rangeDateChange = (value: Dayjs[], dateString: string[]) => {
      console.log('Selected Time: ', value);
      console.log('Formatted Selected Time: ', dateString);
      fetchTransaction()
    }
    const userInfo = computed(() => store.getters.getUserInfo)
const visible = ref(false)
    const columns = [
      {
        title: i18n.t('MONEY_CHANGE'),
        dataIndex: 'change_money',
        key: 'change_money',
        slots: {customRender: 'change_money'},
      },
      {
        title: i18n.t('TRANSACTION_TYPE'),
        key: 'type',
        dataIndex: 'type',
        slots: {customRender: 'transaction_type'},
      },
      {
        title: i18n.t('CUSTOMER'),
        key: 'customer',
        slots: {customRender: 'customer'},
      },
      {
        title: i18n.t('NOTE'),
        key: 'note',
        dataIndex: 'note',
        slots: {customRender: 'note'},
      },
      {
        title: i18n.t('CREATED_TITLE'),
        key: 'created_at',
        dataIndex: 'created_at',
      },
    ];
    const request: WalletTransactionRequest = {
      page: 1,
      page_size: 300,
    }
    const formState = ref({
      user_id:0,
      money:0
    })
    const fetchTransaction = () => {
      if (rangeDate.value.length > 1) {
        request.from = rangeDate.value[0].toDate()
        request.to = rangeDate.value[1].toDate()
      }
      store.dispatch(WalletActionTypes.FETCH_WALLET_TRANSACTIONS, request)
    }
    onBeforeMount(() => {
      fetchTransaction()
    })
    const confirmExport=()=>{
     const mess= message.loading('Export in progress..', 0)
      APIs.init().getWalletExportTransactions(request).then((response:ApiResponse<WalletTransactionsResponse>)=>{
        mess()
        if (response.ok){
          message.error('Success');
          window.open(Constant.LINK_EXPORT + response.data?.data);
        }else {
          message.error('This is an error message: '+response.data?.message);
        }
      }).catch((e:any)=>{
        mess()
        message.error('This is an error message: '+JSON.stringify(e));
        console.error("getWalletExportTransactions err",e)
      })
    }

    const handleOk = async ()=>{
      const res = await APIs.init().moreMoney({user_id:Number(formState.value.user_id),money:Number(formState.value.money),user_shop_admin_id:userInfo.value?.id,user_shop_admin_name:userInfo.value?.name})
      if(res.data?.status === 200){
        fetchTransaction()
        visible.value = false
        message.success(i18n.t('SUCCESS'))

      }else {
        message.error(res.data?.message)
      }
    }
    return {
      handleOk,
      columns,
      data,
      RouterEndpoint: RouterEndpoint,
      isWalletFetching,
      wallet,
      ranges: {Today: [dayjs(), dayjs()], 'This Month': [dayjs(), dayjs().endOf('month')]},
      dateFormat,
      rangeDateChange,
      rangeDate,
      walletTransactions,
      walletTransactionCount,
      confirmExport,
      visible,
      currentShop,
      userInfo,
      formState
    }
  }
})
</script>

<style scoped lang="scss">
.header-container {
  display: flex;
  flex-wrap: wrap;

  .filter-transaction {
    flex: 1;
    display: flex;
    justify-content: flex-end;
    align-self: flex-end;

    .item-filter {
      display: flex;
      flex-direction: column;

      .filter-by-date-label {
        margin-bottom: 4px;
      }
    }
  }

  .wallet-container-card {
    position: relative;
    width: 420px;

    .wallet-background-container {
      .wallet-background-group {
        background-color: #5EDE99;
        border-radius: 12px;
        width: 360px;
      }

      .wallet-label-overlay {
        position: absolute;
        top: 20%;
        left: 18px;

        h3 {
          color: white;
        }

        h1 {
          color: white;
          font-size: 32px;
        }
      }
    }

    .button-deposit {
      padding: 16px 46px;
      background-color: white;
      position: absolute;
      border-radius: 28px;
      box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
      top: 28%;
      right: 0;
      cursor: pointer;

      .button-deposit-content {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;

        .icon-deposit {
          width: 24px;
          height: 24px;
        }
      }

    }
  }
}

.table-transaction {
  margin-top: 16px;
}

.button-export{
  align-self: flex-end;
  margin-right: 12px;
}
</style>
