import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "container-title" }
const _hoisted_2 = { class: "title-header" }
const _hoisted_3 = {
  style: {"margin":"20px"},
  class: "container"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_button = _resolveComponent("a-button")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_rollback_outlined = _resolveComponent("rollback-outlined")!
  const _component_a_input = _resolveComponent("a-input")!
  const _component_a_form_item = _resolveComponent("a-form-item")!
  const _component_a_select_option = _resolveComponent("a-select-option")!
  const _component_a_select = _resolveComponent("a-select")!
  const _component_a_row = _resolveComponent("a-row")!
  const _component_a_textarea = _resolveComponent("a-textarea")!
  const _component_a_col = _resolveComponent("a-col")!
  const _component_a_date_picker = _resolveComponent("a-date-picker")!
  const _component_a_radio = _resolveComponent("a-radio")!
  const _component_a_radio_group = _resolveComponent("a-radio-group")!
  const _component_a_form = _resolveComponent("a-form")!
  const _component_a_card = _resolveComponent("a-card")!
  const _component_a_input_number = _resolveComponent("a-input-number")!
  const _component_a_range_picker = _resolveComponent("a-range-picker")!
  const _component_plus_outlined = _resolveComponent("plus-outlined")!
  const _component_a_tooltip = _resolveComponent("a-tooltip")!
  const _component_a_modal = _resolveComponent("a-modal")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("h3", _hoisted_2, _toDisplayString(_ctx.$t(_ctx.$route.name)), 1),
      _createVNode(_component_router_link, {
        to: `/ticket/list-ticket-type/${_ctx.$route.query.event_id}`
      }, {
        default: _withCtx(() => [
          _createVNode(_component_a_button, null, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t('LIST_TICKET_TYPE')), 1)
            ]),
            _: 1
          })
        ]),
        _: 1
      }, 8, ["to"]),
      _createVNode(_component_a_button, {
        style: {"margin-left":"20px"},
        onClick: $setup.rollBack
      }, {
        icon: _withCtx(() => [
          _createVNode(_component_rollback_outlined)
        ]),
        _: 1
      }, 8, ["onClick"]),
      _createVNode(_component_a_button, {
        style: {"margin-left":"20px"},
        onClick: $setup.onSubmit,
        type: "primary"
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.$t('SAVE')), 1)
        ]),
        _: 1
      }, 8, ["onClick"])
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createVNode(_component_a_row, null, {
        default: _withCtx(() => [
          _createVNode(_component_a_col, { span: 16 }, {
            default: _withCtx(() => [
              _createVNode(_component_a_card, { style: {"width":"100%"} }, {
                default: _withCtx(() => [
                  _createVNode(_component_a_form, {
                    model: $setup.formState,
                    name: "basic",
                    ref: "formRefTicket",
                    "label-col": { span: 10 },
                    "wrapper-col": { span: 14 },
                    autocomplete: "off",
                    rules: $setup.rules,
                    layout: "horizontal",
                    labelAlign: "left"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_a_row, null, {
                        default: _withCtx(() => [
                          _createVNode(_component_a_col, { span: 12 }, {
                            default: _withCtx(() => [
                              _createVNode(_component_a_form_item, {
                                style: {"margin-right":"20px"},
                                label: _ctx.$t('FULL_NAME'),
                                name: "full_name"
                              }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_a_input, {
                                    value: $setup.formState.full_name,
                                    "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => (($setup.formState.full_name) = $event))
                                  }, null, 8, ["value"])
                                ]),
                                _: 1
                              }, 8, ["label"]),
                              _createVNode(_component_a_form_item, {
                                style: {"margin-right":"20px"},
                                label: _ctx.$t('BIB')
                              }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_a_input, {
                                    value: $setup.formState.bib,
                                    "onUpdate:value": _cache[1] || (_cache[1] = ($event: any) => (($setup.formState.bib) = $event))
                                  }, null, 8, ["value"])
                                ]),
                                _: 1
                              }, 8, ["label"]),
                              _createVNode(_component_a_form_item, {
                                style: {"margin-right":"20px"},
                                label: _ctx.$t('ALIAS_NAME')
                              }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_a_input, {
                                    value: $setup.formState.alias_name,
                                    "onUpdate:value": _cache[2] || (_cache[2] = ($event: any) => (($setup.formState.alias_name) = $event))
                                  }, null, 8, ["value"])
                                ]),
                                _: 1
                              }, 8, ["label"]),
                              _createVNode(_component_a_form_item, {
                                style: {"margin-right":"20px"},
                                label: _ctx.$t('TICKET_TYPE'),
                                name: "ticket_type_id"
                              }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_a_row, { style: {"align-items":"center"} }, {
                                    default: _withCtx(() => [
                                      _createVNode(_component_a_select, {
                                        allowClear: "",
                                        value: $setup.formState.ticket_type_id,
                                        "onUpdate:value": _cache[3] || (_cache[3] = ($event: any) => (($setup.formState.ticket_type_id) = $event)),
                                        placeholder: "Please select",
                                        style: {"width":"60%"}
                                      }, {
                                        default: _withCtx(() => [
                                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.getTicketType.ticketTypes, (item) => {
                                            return (_openBlock(), _createBlock(_component_a_select_option, {
                                              key: item.id,
                                              value: item.id
                                            }, {
                                              default: _withCtx(() => [
                                                _createTextVNode(_toDisplayString(item.name), 1)
                                              ]),
                                              _: 2
                                            }, 1032, ["value"]))
                                          }), 128))
                                        ]),
                                        _: 1
                                      }, 8, ["value"]),
                                      _createVNode(_component_a_button, {
                                        style: {"width":"35%","margin-left":"5px"},
                                        size: "small",
                                        onClick: $setup.addTicketType,
                                        type: "primary"
                                      }, {
                                        default: _withCtx(() => [
                                          _createTextVNode(_toDisplayString(_ctx.$t('quick_create')), 1)
                                        ]),
                                        _: 1
                                      }, 8, ["onClick"])
                                    ]),
                                    _: 1
                                  })
                                ]),
                                _: 1
                              }, 8, ["label"]),
                              _createVNode(_component_a_form_item, {
                                style: {"margin-right":"20px"},
                                label: _ctx.$t('CUSTOMER_PHONE'),
                                name: "phone"
                              }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_a_input, {
                                    value: $setup.formState.phone,
                                    "onUpdate:value": _cache[4] || (_cache[4] = ($event: any) => (($setup.formState.phone) = $event))
                                  }, null, 8, ["value"])
                                ]),
                                _: 1
                              }, 8, ["label"]),
                              _createVNode(_component_a_form_item, {
                                style: {"margin-right":"20px"},
                                label: _ctx.$t('Email'),
                                name: "email"
                              }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_a_input, {
                                    value: $setup.formState.email,
                                    "onUpdate:value": _cache[5] || (_cache[5] = ($event: any) => (($setup.formState.email) = $event))
                                  }, null, 8, ["value"])
                                ]),
                                _: 1
                              }, 8, ["label"]),
                              _createVNode(_component_a_form_item, {
                                style: {"margin-right":"20px"},
                                label: _ctx.$t('medical_history')
                              }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_a_textarea, {
                                    value: $setup.formState.medical_history,
                                    "onUpdate:value": _cache[6] || (_cache[6] = ($event: any) => (($setup.formState.medical_history) = $event))
                                  }, null, 8, ["value"])
                                ]),
                                _: 1
                              }, 8, ["label"])
                            ]),
                            _: 1
                          }),
                          _createVNode(_component_a_col, { span: 12 }, {
                            default: _withCtx(() => [
                              _createVNode(_component_a_form_item, {
                                style: {"margin-right":"20px"},
                                label: _ctx.$t('ORGANIZATION')
                              }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_a_input, {
                                    value: $setup.formState.organization,
                                    "onUpdate:value": _cache[7] || (_cache[7] = ($event: any) => (($setup.formState.organization) = $event))
                                  }, null, 8, ["value"])
                                ]),
                                _: 1
                              }, 8, ["label"]),
                              _createVNode(_component_a_form_item, {
                                style: {"margin-right":"20px"},
                                label: _ctx.$t('BIRTHDAY')
                              }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_a_date_picker, {
                                    value: $setup.formState.birthday,
                                    "onUpdate:value": _cache[8] || (_cache[8] = ($event: any) => (($setup.formState.birthday) = $event)),
                                    placeholder: _ctx.$t('CUSTOMER_BIRTHDAY'),
                                    format: "DD-MM-YYYY",
                                    style: {"width":"100%"}
                                  }, null, 8, ["value", "placeholder"])
                                ]),
                                _: 1
                              }, 8, ["label"]),
                              _createVNode(_component_a_form_item, {
                                style: {"margin-right":"20px"},
                                label: _ctx.$t('SEX')
                              }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_a_radio_group, {
                                    name: "radioGroup",
                                    value: $setup.formState.gender,
                                    "onUpdate:value": _cache[9] || (_cache[9] = ($event: any) => (($setup.formState.gender) = $event))
                                  }, {
                                    default: _withCtx(() => [
                                      _createVNode(_component_a_radio, { value: 0 }, {
                                        default: _withCtx(() => [
                                          _createTextVNode(_toDisplayString(_ctx.$t('MALE')), 1)
                                        ]),
                                        _: 1
                                      }),
                                      _createVNode(_component_a_radio, { value: 1 }, {
                                        default: _withCtx(() => [
                                          _createTextVNode(_toDisplayString(_ctx.$t('FEMALE')), 1)
                                        ]),
                                        _: 1
                                      }),
                                      _createVNode(_component_a_radio, { value: 2 }, {
                                        default: _withCtx(() => [
                                          _createTextVNode(_toDisplayString(_ctx.$t('OTHER')), 1)
                                        ]),
                                        _: 1
                                      })
                                    ]),
                                    _: 1
                                  }, 8, ["value"])
                                ]),
                                _: 1
                              }, 8, ["label"]),
                              _createVNode(_component_a_form_item, {
                                style: {"margin-right":"20px"},
                                label: _ctx.$t('BLOOD_GROUP')
                              }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_a_input, {
                                    value: $setup.formState.blood_group,
                                    "onUpdate:value": _cache[10] || (_cache[10] = ($event: any) => (($setup.formState.blood_group) = $event))
                                  }, null, 8, ["value"])
                                ]),
                                _: 1
                              }, 8, ["label"]),
                              _createVNode(_component_a_form_item, {
                                style: {"margin-right":"20px"},
                                label: _ctx.$t('NATIONAL_CARD_ID')
                              }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_a_input, {
                                    value: $setup.formState.national_card_id,
                                    "onUpdate:value": _cache[11] || (_cache[11] = ($event: any) => (($setup.formState.national_card_id) = $event))
                                  }, null, 8, ["value"])
                                ]),
                                _: 1
                              }, 8, ["label"]),
                              _createVNode(_component_a_form_item, {
                                style: {"margin-right":"20px"},
                                label: _ctx.$t('NATIONAL_ID')
                              }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_a_input, {
                                    value: $setup.formState.national_id,
                                    "onUpdate:value": _cache[12] || (_cache[12] = ($event: any) => (($setup.formState.national_id) = $event))
                                  }, null, 8, ["value"])
                                ]),
                                _: 1
                              }, 8, ["label"]),
                              _createVNode(_component_a_form_item, {
                                style: {"margin-right":"20px"},
                                label: _ctx.$t('PROVINCE')
                              }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_a_select, {
                                    value: $setup.formState.province_id,
                                    "onUpdate:value": _cache[13] || (_cache[13] = ($event: any) => (($setup.formState.province_id) = $event)),
                                    showSearch: "",
                                    style: {"width":"100%"},
                                    "filter-option": false,
                                    "not-found-content": null,
                                    placeholder: _ctx.$t('PROVINCE'),
                                    onChange: _ctx.provinceChange,
                                    onSearch: _ctx.provinceSearch
                                  }, {
                                    default: _withCtx(() => [
                                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.getProvinces, (item) => {
                                        return (_openBlock(), _createBlock(_component_a_select_option, {
                                          key: item.id,
                                          value: item.id
                                        }, {
                                          default: _withCtx(() => [
                                            _createTextVNode(_toDisplayString(item.name), 1)
                                          ]),
                                          _: 2
                                        }, 1032, ["value"]))
                                      }), 128))
                                    ]),
                                    _: 1
                                  }, 8, ["value", "placeholder", "onChange", "onSearch"])
                                ]),
                                _: 1
                              }, 8, ["label"])
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }, 8, ["model", "rules"])
                ]),
                _: 1
              })
            ]),
            _: 1
          }),
          _createVNode(_component_a_col, { span: 1 }),
          _createVNode(_component_a_col, { span: 7 }, {
            default: _withCtx(() => [
              _createVNode(_component_a_card, { style: {"width":"100%"} }, {
                default: _withCtx(() => [
                  _createVNode(_component_a_form, {
                    model: $setup.formState,
                    name: "basic",
                    autocomplete: "off",
                    layout: "vertical",
                    labelAlign: "left",
                    ref: "formRefReference",
                    rules: _ctx.rules2
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_a_form_item, {
                        style: {"margin-right":"20px"},
                        label: _ctx.$t('reference_name'),
                        name: "reference_name"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_a_input, {
                            value: $setup.formState.reference_name,
                            "onUpdate:value": _cache[14] || (_cache[14] = ($event: any) => (($setup.formState.reference_name) = $event))
                          }, null, 8, ["value"])
                        ]),
                        _: 1
                      }, 8, ["label"]),
                      _createVNode(_component_a_form_item, {
                        style: {"margin-right":"20px"},
                        label: _ctx.$t('reference_phone'),
                        name: "reference_phone"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_a_input, {
                            value: $setup.formState.reference_phone,
                            "onUpdate:value": _cache[15] || (_cache[15] = ($event: any) => (($setup.formState.reference_phone) = $event))
                          }, null, 8, ["value"])
                        ]),
                        _: 1
                      }, 8, ["label"]),
                      _createVNode(_component_a_form_item, {
                        style: {"margin-right":"20px"},
                        label: _ctx.$t('Option 1'),
                        name: "reference_phone"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_a_input, {
                            value: $setup.formState.option_1,
                            "onUpdate:value": _cache[16] || (_cache[16] = ($event: any) => (($setup.formState.option_1) = $event))
                          }, null, 8, ["value"])
                        ]),
                        _: 1
                      }, 8, ["label"]),
                      _createVNode(_component_a_form_item, {
                        style: {"margin-right":"20px"},
                        label: _ctx.$t('Option 2'),
                        name: "reference_phone"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_a_input, {
                            value: $setup.formState.option_2,
                            "onUpdate:value": _cache[17] || (_cache[17] = ($event: any) => (($setup.formState.option_2) = $event))
                          }, null, 8, ["value"])
                        ]),
                        _: 1
                      }, 8, ["label"]),
                      _createVNode(_component_a_form_item, {
                        style: {"margin-right":"20px"},
                        label: _ctx.$t('Option 3'),
                        name: "reference_phone"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_a_input, {
                            value: $setup.formState.option_3,
                            "onUpdate:value": _cache[18] || (_cache[18] = ($event: any) => (($setup.formState.option_3) = $event))
                          }, null, 8, ["value"])
                        ]),
                        _: 1
                      }, 8, ["label"])
                    ]),
                    _: 1
                  }, 8, ["model", "rules"])
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _createVNode(_component_a_modal, {
      style: {"width":"60%"},
      visible: $setup.visible,
      "onUpdate:visible": _cache[23] || (_cache[23] = ($event: any) => (($setup.visible) = $event)),
      title: _ctx.$t('CREATED_EVENT'),
      onOk: $setup.handleOk
    }, {
      default: _withCtx(() => [
        _createVNode(_component_a_row, null, {
          default: _withCtx(() => [
            _createVNode(_component_a_col, { span: 10 }, {
              default: _withCtx(() => [
                _createVNode(_component_a_form, {
                  model: $setup.ticketType,
                  ref: "formCustomerRef",
                  rules: $setup.rules,
                  layout: "vertical"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_a_form_item, {
                      label: _ctx.$t('TICKET_TYPE_NAME'),
                      name: "event_name"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_a_input, {
                          value: $setup.ticketType.name,
                          "onUpdate:value": _cache[19] || (_cache[19] = ($event: any) => (($setup.ticketType.name) = $event)),
                          placeholder: _ctx.$t('TICKET_TYPE_NAME')
                        }, null, 8, ["value", "placeholder"])
                      ]),
                      _: 1
                    }, 8, ["label"]),
                    _createVNode(_component_a_form_item, {
                      label: _ctx.$t('DESCRIPTION')
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_a_textarea, {
                          value: $setup.ticketType.description,
                          "onUpdate:value": _cache[20] || (_cache[20] = ($event: any) => (($setup.ticketType.description) = $event)),
                          placeholder: _ctx.$t('DESCRIPTION'),
                          "show-count": "",
                          maxlength: 1200
                        }, null, 8, ["value", "placeholder"])
                      ]),
                      _: 1
                    }, 8, ["label"]),
                    _createVNode(_component_a_form_item, {
                      label: _ctx.$t('TICKET_TYPE_START_BIB'),
                      name: "start_bib"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_a_input_number, {
                          value: $setup.ticketType.start_bib,
                          "onUpdate:value": _cache[21] || (_cache[21] = ($event: any) => (($setup.ticketType.start_bib) = $event)),
                          placeholder: _ctx.$t('TICKET_TYPE_START_BIB'),
                          style: {"width":"100%"}
                        }, null, 8, ["value", "placeholder"])
                      ]),
                      _: 1
                    }, 8, ["label"]),
                    _createVNode(_component_a_form_item, {
                      label: _ctx.$t('TEMPLATE'),
                      name: "template_id"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_a_select, {
                          allowClear: "",
                          value: $setup.ticketType.template_id,
                          "onUpdate:value": _cache[22] || (_cache[22] = ($event: any) => (($setup.ticketType.template_id) = $event)),
                          placeholder: _ctx.$t('TEMPLATE'),
                          onChange: _ctx.onChangeEmployee
                        }, {
                          default: _withCtx(() => [
                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.templates.templates, (item) => {
                              return (_openBlock(), _createBlock(_component_a_select_option, {
                                key: item.id,
                                value: item.id
                              }, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(item.name), 1)
                                ]),
                                _: 2
                              }, 1032, ["value"]))
                            }), 128))
                          ]),
                          _: 1
                        }, 8, ["value", "placeholder", "onChange"])
                      ]),
                      _: 1
                    }, 8, ["label"])
                  ]),
                  _: 1
                }, 8, ["model", "rules"])
              ]),
              _: 1
            }),
            _createVNode(_component_a_col, { span: 1 }),
            _createVNode(_component_a_col, { span: 10 }, {
              default: _withCtx(() => [
                _createVNode(_component_a_form, {
                  model: $setup.ticketPrice,
                  ref: "formCustomerRef",
                  rules: $setup.rules,
                  layout: "vertical"
                }, {
                  default: _withCtx(() => [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.ticketPrice, (i, index) => {
                      return (_openBlock(), _createElementBlock("div", { key: i }, [
                        _createVNode(_component_a_form_item, {
                          label: _ctx.$t('sale_price') +' '+  (index+1),
                          name: "price_in_advance"
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_a_input_number, {
                              value: i.price,
                              "onUpdate:value": ($event: any) => ((i.price) = $event),
                              style: {"width":"100%"},
                              formatter: value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ','),
                              parser: value => value.replace(/\$\s?|(,*)/g, ''),
                              "string-mode": ""
                            }, null, 8, ["value", "onUpdate:value", "formatter", "parser"])
                          ]),
                          _: 2
                        }, 1032, ["label"]),
                        _createVNode(_component_a_form_item, {
                          label: _ctx.$t('time_visit'),
                          name: "birth"
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_a_range_picker, {
                              style: {"width":"100%"},
                              value: i.time_visit,
                              "onUpdate:value": ($event: any) => ((i.time_visit) = $event),
                              format: "DD-MM-YYYY HH:mm"
                            }, null, 8, ["value", "onUpdate:value"])
                          ]),
                          _: 2
                        }, 1032, ["label"])
                      ]))
                    }), 128)),
                    _createVNode(_component_a_tooltip, { placement: "topLeft" }, {
                      title: _withCtx(() => [
                        _createElementVNode("span", null, _toDisplayString(_ctx.$t('ADD')) + " " + _toDisplayString(_ctx.$t('sale_price')), 1)
                      ]),
                      default: _withCtx(() => [
                        _createVNode(_component_a_button, {
                          onClick: $setup.ticketPricePush,
                          shape: "circle"
                        }, {
                          icon: _withCtx(() => [
                            _createVNode(_component_plus_outlined)
                          ]),
                          _: 1
                        }, 8, ["onClick"])
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }, 8, ["model", "rules"])
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ]),
      _: 1
    }, 8, ["visible", "title", "onOk"])
  ], 64))
}