import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "container-title" }
const _hoisted_2 = { class: "title-header" }
const _hoisted_3 = {
  style: {"margin":"20px"},
  class: "container"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_rollback_outlined = _resolveComponent("rollback-outlined")!
  const _component_a_button = _resolveComponent("a-button")!
  const _component_a_row = _resolveComponent("a-row")!
  const _component_eye_outlined = _resolveComponent("eye-outlined")!
  const _component_edit_outlined = _resolveComponent("edit-outlined")!
  const _component_a_menu_item = _resolveComponent("a-menu-item")!
  const _component_delete_outlined = _resolveComponent("delete-outlined")!
  const _component_a_popconfirm = _resolveComponent("a-popconfirm")!
  const _component_a_menu = _resolveComponent("a-menu")!
  const _component_a_dropdown = _resolveComponent("a-dropdown")!
  const _component_a_table = _resolveComponent("a-table")!
  const _component_a_input = _resolveComponent("a-input")!
  const _component_a_form_item = _resolveComponent("a-form-item")!
  const _component_a_textarea = _resolveComponent("a-textarea")!
  const _component_a_input_number = _resolveComponent("a-input-number")!
  const _component_a_select_option = _resolveComponent("a-select-option")!
  const _component_a_select = _resolveComponent("a-select")!
  const _component_a_form = _resolveComponent("a-form")!
  const _component_a_col = _resolveComponent("a-col")!
  const _component_a_range_picker = _resolveComponent("a-range-picker")!
  const _component_plus_outlined = _resolveComponent("plus-outlined")!
  const _component_a_tooltip = _resolveComponent("a-tooltip")!
  const _component_a_modal = _resolveComponent("a-modal")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("h3", _hoisted_2, _toDisplayString(_ctx.$t(_ctx.$route.name)), 1),
      _createVNode(_component_a_button, { onClick: $setup.rollBack }, {
        icon: _withCtx(() => [
          _createVNode(_component_rollback_outlined)
        ]),
        _: 1
      }, 8, ["onClick"]),
      _createVNode(_component_a_button, {
        style: {"margin-left":"20px"},
        onClick: $setup.addTicketType,
        type: "primary"
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.$t('ADD')), 1)
        ]),
        _: 1
      }, 8, ["onClick"])
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createVNode(_component_a_table, {
        rowKey: record => record.id,
        columns: $setup.columns,
        "data-source": $setup.getTicketType.ticketTypes,
        pagination: $setup.pagination,
        onChange: $setup.handleTableChange
      }, {
        event_id: _withCtx(({record}) => [
          _createTextVNode(_toDisplayString($setup.getEventName(record.event_id)), 1)
        ]),
        price: _withCtx(({record}) => [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.getPrice(record.id), (i) => {
            return (_openBlock(), _createElementBlock("div", { key: i }, [
              _createVNode(_component_a_row, null, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString($setup.formatMoney(i.price)) + " : " + _toDisplayString($setup.getDate(i.start_time)) + "-" + _toDisplayString($setup.getDate(i.end_time)), 1)
                ]),
                _: 2
              }, 1024)
            ]))
          }), 128))
        ]),
        template_id: _withCtx(({record}) => [
          _createTextVNode(_toDisplayString($setup.getTemplateName(record.template_id)), 1)
        ]),
        action: _withCtx(({record}) => [
          _createVNode(_component_a_dropdown, null, {
            overlay: _withCtx(() => [
              _createVNode(_component_a_menu, null, {
                default: _withCtx(() => [
                  _createVNode(_component_a_menu_item, {
                    onClick: ($event: any) => ($setup.editTicketType(record))
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_edit_outlined),
                      _createTextVNode(" " + _toDisplayString(_ctx.$t('EDIT')), 1)
                    ]),
                    _: 2
                  }, 1032, ["onClick"]),
                  _createVNode(_component_a_menu_item, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_a_popconfirm, {
                        title: _ctx.$t('TICKET_TYPE_CONFIRM_DELETE_CONTEXT'),
                        "ok-text": _ctx.$t('YES'),
                        "cancel-text": _ctx.$t('NO'),
                        onConfirm: ($event: any) => ($setup.deleteTicketType(record))
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_delete_outlined),
                          _createTextVNode(" " + _toDisplayString(_ctx.$t('DELETE')), 1)
                        ]),
                        _: 2
                      }, 1032, ["title", "ok-text", "cancel-text", "onConfirm"])
                    ]),
                    _: 2
                  }, 1024)
                ]),
                _: 2
              }, 1024)
            ]),
            default: _withCtx(() => [
              _createVNode(_component_a_button, { type: "text" }, {
                default: _withCtx(() => [
                  _createVNode(_component_eye_outlined)
                ]),
                _: 1
              })
            ]),
            _: 2
          }, 1024)
        ]),
        _: 1
      }, 8, ["rowKey", "columns", "data-source", "pagination", "onChange"])
    ]),
    _createVNode(_component_a_modal, {
      style: {"width":"60%"},
      visible: $setup.visible,
      "onUpdate:visible": _cache[4] || (_cache[4] = ($event: any) => (($setup.visible) = $event)),
      title: $setup.checkEdit ? _ctx.$t('EDIT_EVENT'): _ctx.$t('CREATED_EVENT'),
      onOk: $setup.handleOk
    }, {
      default: _withCtx(() => [
        _createVNode(_component_a_row, null, {
          default: _withCtx(() => [
            _createVNode(_component_a_col, { span: 10 }, {
              default: _withCtx(() => [
                _createVNode(_component_a_form, {
                  model: $setup.ticketType,
                  ref: "formCustomerRef",
                  rules: $setup.rules,
                  layout: "vertical"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_a_form_item, {
                      label: _ctx.$t('TICKET_TYPE_NAME'),
                      name: "name"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_a_input, {
                          value: $setup.ticketType.name,
                          "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => (($setup.ticketType.name) = $event)),
                          placeholder: _ctx.$t('TICKET_TYPE_NAME')
                        }, null, 8, ["value", "placeholder"])
                      ]),
                      _: 1
                    }, 8, ["label"]),
                    _createVNode(_component_a_form_item, {
                      label: _ctx.$t('DESCRIPTION'),
                      name: "phone"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_a_textarea, {
                          value: $setup.ticketType.description,
                          "onUpdate:value": _cache[1] || (_cache[1] = ($event: any) => (($setup.ticketType.description) = $event)),
                          placeholder: _ctx.$t('DESCRIPTION'),
                          "show-count": "",
                          maxlength: 1200
                        }, null, 8, ["value", "placeholder"])
                      ]),
                      _: 1
                    }, 8, ["label"]),
                    _createVNode(_component_a_form_item, {
                      label: _ctx.$t('TICKET_TYPE_START_BIB'),
                      name: "start_bib"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_a_input_number, {
                          value: $setup.ticketType.start_bib,
                          "onUpdate:value": _cache[2] || (_cache[2] = ($event: any) => (($setup.ticketType.start_bib) = $event)),
                          placeholder: _ctx.$t('TICKET_TYPE_START_BIB'),
                          style: {"width":"100%"}
                        }, null, 8, ["value", "placeholder"])
                      ]),
                      _: 1
                    }, 8, ["label"]),
                    _createVNode(_component_a_form_item, {
                      label: _ctx.$t('TEMPLATE'),
                      name: "template_id"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_a_select, {
                          allowClear: "",
                          value: $setup.ticketType.template_id,
                          "onUpdate:value": _cache[3] || (_cache[3] = ($event: any) => (($setup.ticketType.template_id) = $event)),
                          placeholder: _ctx.$t('TEMPLATE'),
                          onChange: _ctx.onChangeEmployee
                        }, {
                          default: _withCtx(() => [
                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.templates.templates, (item) => {
                              return (_openBlock(), _createBlock(_component_a_select_option, {
                                key: item.id,
                                value: item.id
                              }, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(item.name), 1)
                                ]),
                                _: 2
                              }, 1032, ["value"]))
                            }), 128))
                          ]),
                          _: 1
                        }, 8, ["value", "placeholder", "onChange"])
                      ]),
                      _: 1
                    }, 8, ["label"])
                  ]),
                  _: 1
                }, 8, ["model", "rules"])
              ]),
              _: 1
            }),
            _createVNode(_component_a_col, { span: 1 }),
            _createVNode(_component_a_col, { span: 10 }, {
              default: _withCtx(() => [
                _createVNode(_component_a_form, {
                  model: $setup.ticketPrice,
                  layout: "vertical"
                }, {
                  default: _withCtx(() => [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.ticketPrice, (i, index) => {
                      return (_openBlock(), _createElementBlock("div", { key: i }, [
                        _createVNode(_component_a_form_item, {
                          label: _ctx.$t('sale_price') +' '+  (index+1)
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_a_input_number, {
                              value: i.price,
                              "onUpdate:value": ($event: any) => ((i.price) = $event),
                              style: {"width":"100%"},
                              formatter: value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ','),
                              parser: value => value.replace(/\$\s?|(,*)/g, ''),
                              "string-mode": ""
                            }, null, 8, ["value", "onUpdate:value", "formatter", "parser"])
                          ]),
                          _: 2
                        }, 1032, ["label"]),
                        _createVNode(_component_a_form_item, {
                          label: _ctx.$t('time_visit'),
                          name: "birth"
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_a_range_picker, {
                              style: {"width":"100%"},
                              value: i.time_visit,
                              "onUpdate:value": ($event: any) => ((i.time_visit) = $event),
                              format: "DD-MM-YYYY HH:mm"
                            }, null, 8, ["value", "onUpdate:value"])
                          ]),
                          _: 2
                        }, 1032, ["label"])
                      ]))
                    }), 128)),
                    _createVNode(_component_a_tooltip, { placement: "topLeft" }, {
                      title: _withCtx(() => [
                        _createElementVNode("span", null, _toDisplayString(_ctx.$t('ADD')) + " " + _toDisplayString(_ctx.$t('sale_price')), 1)
                      ]),
                      default: _withCtx(() => [
                        _createVNode(_component_a_button, {
                          onClick: $setup.ticketPricePush,
                          shape: "circle"
                        }, {
                          icon: _withCtx(() => [
                            _createVNode(_component_plus_outlined)
                          ]),
                          _: 1
                        }, 8, ["onClick"])
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }, 8, ["model"])
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ]),
      _: 1
    }, 8, ["visible", "title", "onOk"])
  ], 64))
}