import {State} from "./states";
import {TagsMutationTypes} from "./mutation-types";
import {Tag, TreeTag} from "@/models/Tags/Tags";
import {Metadata} from "@/APIs/response/S_HttpResponse";

export type Mutations<S = State> = {
    [TagsMutationTypes.SET_flowTags](state: S, payload: Tag[]|undefined): void;
    [TagsMutationTypes.SET_CustomerTags](state: S, payload: Tag[]|undefined): void;
    [TagsMutationTypes.SET_TriggerTags](state: S, payload: Tag[]|undefined): void;
    [TagsMutationTypes.SET_ViralTags](state: S, payload: Tag[]|undefined): void;
    [TagsMutationTypes.SET_AutoInboxTags](state: S, payload: Tag[]|undefined): void;
    [TagsMutationTypes.SET_ProductTags](state: S, payload: Tag[]|undefined): void;
    [TagsMutationTypes.SET_MetadataRootTag](state: S, payload: Metadata): void;
    [TagsMutationTypes.SET_TREE_TAG](state: S, payload: TreeTag[]): void;
}

export const mutations: Mutations = {
    [TagsMutationTypes.SET_flowTags](state: State, payload: Tag[]|undefined): void {
        state.flowTags = payload
    },
    [TagsMutationTypes.SET_CustomerTags](state: State, payload: Tag[]|undefined): void {
        state.customerTags = payload
    },
    [TagsMutationTypes.SET_ViralTags](state: State, payload: Tag[]|undefined): void {
        state.viralTags = payload
    },
    [TagsMutationTypes.SET_AutoInboxTags](state: State, payload: Tag[]|undefined): void {
        state.autoInboxTags = payload
    },
    [TagsMutationTypes.SET_TriggerTags](state: State, payload: Tag[]|undefined): void {
        state.triggerTags = payload
    },
    [TagsMutationTypes.SET_ProductTags](state: State, payload: Tag[]|undefined): void {
        state.productTags = payload
    },
    [TagsMutationTypes.SET_MetadataRootTag](state: State, payload: Metadata): void {
        state.rootTagMetadata = payload
    },
    [TagsMutationTypes.SET_TREE_TAG](state: State, payload: TreeTag[]): void {
        state.treeTags = payload
    }

}