import create from "zustand-vue";
import {persist, createJSONStorage} from 'zustand/middleware'
import {Shop, SuperUserInfo} from "@/models/Users/user";
import APIs from "@/APIs/APIs";
import {Overview} from "@/models/Overview/Overview";


interface OverviewState {

    getOverViewLoading: boolean
    metadata: any
    overView: Overview[]
    getOverview: (params: any) => void;
    currentShop: Shop
    shops: Array<Shop>
}

const initialState = {
    getOverViewLoading: true,
    metadata: {},
    overView:[]
}


// @ts-ignore

const useOverviewStore = create<OverviewState>(persist((set) => ({
        ...initialState,
        getOverview: async () =>{

            set(()=>({getOverViewLoading: true}))
            const response = await APIs.init().getOverView({})
            console.log(response,'idadasd')
            if(response.data?.status===200){
                set(()=>({getOverViewLoading: false, overView: response.data?.data?response.data?.data:[] }))
            }else{
                set(()=>({getOverViewLoading: false, overView:[]}))
            }
        },
        // getShop: async () =>{
        //     set(()=>({getShopLoading: true}))
        //     const response = await APIs.init().getShops()
        //     if(response.data?.status===200){
        //         let currentShop = useUserStore.getState().currentShop
        //
        //         if(currentShop.id && response.data?.data){
        //             const shopFound = response.data?.data?response.data?.data.find(shop=>shop.id===currentShop.id): {}
        //             if(shopFound){
        //                 currentShop = shopFound
        //             }
        //         }else{
        //             currentShop = response.data?.data?response.data?.data[0]: {}
        //         }
        //
        //         useUserStore.setState({getShopLoading: false, shops: response.data?.data?response.data?.data:[], currentShop })
        //         useUserStore.setState({currentShop })
        //     }else{
        //         set(()=>({getShopLoading: false, shops:[]}))
        //     }
        //
        // },
        // LoginEmail: async (params, onSuccess) => {
        //     set(() => ({getUserLoading: true}))
        //     const response = await APIs.init().loginEmail(params)
        //
        //
        //     if (response.data?.status === 200) {
        //         set(() => ({getUserLoading: false, userInfo: {token: response.data?.data, shop_id: 0}}))
        //         const user = await APIs.init().getSuperUserInfo()
        //         if (user.data?.status === 200) {
        //             set(() => ({
        //                 getUserLoading: false,
        //                 userInfo: user.data?.data ? {...user.data?.data, token: response.data?.data} : {}
        //             }))
        //             onSuccess()
        //         } else {
        //             set(() => ({getUserLoading: false, userInfo: {}}))
        //         }
        //     } else {
        //         set(() => ({getUserLoading: false, userInfo: {}}))
        //     }
        //     console.log('LoginEmail', response)
        // },
    })
    ,
    {
        name: 'overview'
    }
    )
)

export default useOverviewStore
