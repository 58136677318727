<template>
  <a-modal v-model:visible="visible" :title="$t('QUICK_MESSAGE')" width="50%">
    <a-table :columns="columns" :data-source="listNull=== undefined ? [] :list"
             :rowSelection="{type:'radio',selectedRowKeys:selectedRowKeys,onChange:onRowSelect}"
             :pagination="false"
             :loading="listLoading"
             v-if="buttonSubmitType==='NEXT'"
             :rowKey="record => record.id">
      <template #name="{ record }">
        <a-button type="text" class="table-row-name" @click="onRowSelect([record.id])">
          {{ record.name }}
        </a-button>
      </template>
      <template #type="{ text }">
               <span class="item-trigger-table-small">
                    {{ $t(text) }}
                </span>
      </template>
      <template #creator="{ text }">
                <span>
                  {{ getEmployeeName(text) }}

                </span>
      </template>
      <template #tags="{ record }">
                <span>
                    <a-tag v-for="tag in record.tags" :key="tag.id+'_'+tag.name"
                           :color="tag.color">{{ tag.name }}</a-tag>
                </span>
      </template>
    </a-table>
    <div v-else-if="buttonSubmitType==='OK'">
      <div class="note-first-msg"><span><img
          src="@/assets/icons/zalo-flat.svg"
          class="icon-zns"
          v-if="flowContext&&flowContext.flow_type===2||flowContext&&flowContext.flow_type===4"
      />
        <facebook-outlined v-else-if="flowContext&&flowContext.flow_type===1||flowContext&&flowContext.flow_type===3"/>
        <GoogleOutlined class="icon-zns" v-else-if="flowContext&&flowContext.flow_type===5" />

        {{ $t('QUICK_MESSAGE_FIRST_MESSAGE_NOTE') }}</span></div>
      <a-form
          :label-col="{span:6}"
          :wrapper-col="{span:16}"
          >
        <div v-if="flowContext.flow_type===2">
          <a-form-item v-for="item in flowContext.zns_attributes" :name="item.name" :key="item.name" :label="$t(item.name)">
            <a-input v-if="item.type !== 'DATE'" v-model:value="item.value" :maxlength="item.max_length"
                     :disabled="item.name==='customer_name'"/>
            <a-date-picker :valueFormat="'DD/MM/YYYY'" v-else-if="item.type ==='DATE'" @change="onChangeDatePicker" :maxlength="item.max_length" format="DD/MM/YYYY" v-model:value="item.value" style="min-width:100% " />
          </a-form-item>
        </div>
        <div   v-else-if="flowContext.flow_type===5">
          <a-form-item :label="$t('MAIL_CONTENT')" >
            <vue-editor
                id="editor"
                v-model="flowContext.message"
                :placeholder="$t('EMAIL_PLACEHOLDER_EDITOR')"

            />
          </a-form-item>
          <a-form-item ref="name" :label="$t('FLOW_FOOTER_TEXT')" name="name">
            <a-input
                :placeholder="$t('FLOW_FOOTER_TEXT')"
                v-model:value="flowContext.footer_text"
            />
          </a-form-item>
        </div>
        <div v-else>
          <a-form-item :label="$t('FIRST_TEXT')">
            <a-textarea
                placeholder="Aaa..."
                :auto-size="{ minRows: 2, maxRows: 5 }"
                v-model:value="formState.text"/>
          </a-form-item>
        </div>
      </a-form>
    </div>

    <template #footer>
      <a-button key="back" @click="returnSelect">{{ $t('RETURN') }}</a-button>
      <a-button key="submit" type="primary" :loading="buttonLoading"
                :disabled="!(selectedRowKeys&&selectedRowKeys.length>0)"
                @click="handleOk">{{ $t(buttonSubmitType) }}
      </a-button>
    </template>
  </a-modal>
</template>

<script lang="ts">
import {computed, defineComponent, reactive, ref} from "vue";
import {useStore} from "vuex";
import {Trigger} from "@/models/Trigger/Trigger";
import {useI18n} from "vue-i18n";
import {Employee} from "@/models/Users";
import APIs from "@/APIs/APIs";
import {FlowsContextResponse, S_HttpResponse} from "@/APIs/response/S_HttpResponse";
import {ApiResponse} from "apisauce";
import {FlowContext} from "@/models/Flows/FlowContext";
import {FacebookOutlined,GoogleOutlined} from '@ant-design/icons-vue'
import {message} from "ant-design-vue";
import {Customer} from "@/models/Customers/Customers";
import {TriggerActionTypes} from "@/store/modules/trigger/action-types";
import moment from "moment";
// @ts-ignore
import { VueEditor } from "vue3-editor";
type formData = {
  text: string
  images?: string[]
}
export default defineComponent({
  name: "trigger-quick-message-modal",
  props: ['onlySelectTrigger', 'triggerSelectCallback'],
  components: {
    FacebookOutlined,
    GoogleOutlined,
    VueEditor
  },
  setup(props) {
    const store = useStore();
    const visible = ref(false)
    const i18n = useI18n()
    const list = computed<Trigger[]>(() => store.getters.getTriggersQuickMessages)
    const selectedRowKeys = ref<number[]>([])
    const currentCustomer = computed((): Customer | undefined => store.getters.getCurrentCustomer)
    const buttonSubmitType = ref('NEXT')
    const buttonLoading = ref(false)
    const listLoading = ref(false)
    const flowContext = ref<FlowContext>({})
    const listNull = ref()
    const rules = reactive<any>({})
    const formState = reactive<formData>({text: '', images: []})
    const columns = [
      {
        title: i18n.t('TRIGGER_TITLE'),
        dataIndex: 'name',
        key: 'name',
        slots: {customRender: 'name'},
      },
      {
        title: i18n.t('Type'),
        dataIndex: 'type',
        key: 'type',
        slots: {customRender: 'type'},
      },
      {
        title: i18n.t('TRIGGER_CREATOR'),
        dataIndex: 'creator',
        key: 'creator',
        slots: {customRender: 'creator'},
      },
      {
        title: i18n.t('TAG'),
        key: 'tags',
        dataIndex: 'tags',
        slots: {customRender: 'tags'},
      },
    ]
    const getEmployeeName = (payload: number): string => {
      if (store.getters.getCurrentShop && store.getters.getCurrentShop.employees) {
        const employee = store.getters.getCurrentShop.employees.find((x: Employee) => x.id === payload);
        return employee ? employee.name : "";
      }
      return "";
    }
    const openModal = () => {
      buttonSubmitType.value = "NEXT"
      visible.value = true
      formState.text = ''
      listLoading.value = true
      store.dispatch(TriggerActionTypes.FETCH_TRIGGER_QUICK_MESSAGES, {
        payload: {page: 1, page_size: 20}, callback: (triggers: Trigger[]) => {
          listLoading.value = false
          listNull.value = triggers

        }
      })
      listLoading.value = false


    }
    const onRowSelect = (selectIds: number[]) => {
      selectedRowKeys.value = selectIds
    }
    const returnSelect = () => {
      if (buttonSubmitType.value == 'NEXT') {
        visible.value = false
      }
      buttonSubmitType.value = "NEXT"
      selectedRowKeys.value = []
      formState.text = ''

    }
    const datePicker = ref();
    const onChangeDatePicker = (value: string,) => {
      datePicker.value =moment(value).format('DD/MM/YY')
      console.log(datePicker.value)

    }
    const handleNext = () => {
      if (props.onlySelectTrigger && props.triggerSelectCallback) {
        const trigger = list.value.find(x => selectedRowKeys.value[0] === x.id)
        props.triggerSelectCallback(trigger)
        visible.value = false
      } else {
        const trigger = list.value.find(x => selectedRowKeys.value[0] === x.id)
        if (trigger && trigger.root_flow_id) {
          buttonLoading.value = true
          APIs.init().getFlowV2Context({id: trigger.root_flow_id}).then((res: ApiResponse<FlowsContextResponse>) => {
            buttonLoading.value = false
            if (res.ok && res.data && res.data.data) {
              const flow = res.data.data
              if (flow.context && flow.context.flow_contexts && flow.context.flow_contexts.length) {
                flowContext.value = flow.context.flow_contexts[0]
                if (flowContext.value.flow_type == 2 && flowContext.value.zns_attributes && flowContext.value.zns_attributes.length) {
                  flowContext.value.zns_attributes.map((item) => {
                    if (item.name) {
                      rules[item.name] = [
                        {
                          required: true,
                          message: i18n.t('THIS_FIELD_IS_REQUIRED'),
                          trigger: 'blur'
                        }
                      ]
                    }
                    if (item.name === 'customer_name') {
                      if (currentCustomer.value && currentCustomer.value?.full_name) {
                        item.value = currentCustomer.value.full_name
                      }
                    }
                  })
                } else if (flowContext.value.flow_type === 1) {
                  if (flowContext.value.message_contexts && flowContext.value.message_contexts.length > 0) {
                    for (let i = 0; i < flowContext.value.message_contexts.length; i++) {
                      if (flowContext.value.message_contexts[i].type !== 'generic_template') {
                        if (!formState.text) {
                          formState.text = flowContext.value.message_contexts[i].text + ''
                        }
                        if (!formState.images || formState.images.length === 0 && flowContext.value.message_contexts[i].images) {
                          formState.images = flowContext.value.message_contexts[i].images
                        }
                      }
                    }
                  }
                }
                buttonSubmitType.value = 'OK'
              }
            }
          }).catch((e: any) => {
            console.error(e)
            buttonLoading.value = false
          })
        }
      }

    }
    const handleOk = () => {
      if (buttonSubmitType.value == 'NEXT') {
        handleNext()
      } else {
        if (flowContext.value.flow_type === 1) {
          if (flowContext.value.message_contexts && flowContext.value.message_contexts.length > 0) {
            for (let i = 0; i < flowContext.value.message_contexts.length; i++) {
              if (flowContext.value.message_contexts[i].type !== 'generic_template') {
                if (!formState.text) {
                  flowContext.value.message_contexts[i].text = formState.text
                  break
                }
              }
            }

          }
        }
        buttonLoading.value = true
        if (currentCustomer.value && currentCustomer.value.id) {

          APIs.init().triggerSendUpdateToOne({
            trigger_id: selectedRowKeys.value[0],
            customer_id: currentCustomer.value.id,
            flow_context: flowContext.value
          }).then((res: ApiResponse<S_HttpResponse>) => {
            buttonLoading.value = false
            if (res.ok && res.data && res.data.status === 200) {
              message.success(i18n.t('SEND_MESSAGE_SUCCESS'))
              visible.value = false
            } else {
              message.error(i18n.t('SEND_MESSAGE_ERROR') + ' : ' + res.data?.message)
            }
          }).then((e: any) => {
            buttonLoading.value = false
            if (e) {
              message.error(i18n.t('SEND_MESSAGE_ERROR') + ' : ' + JSON.stringify(e))
              console.error(e)
            }

          })
        }
      }
    }
    return {
      visible,
      list,
      openModal,
      columns,
      getEmployeeName,
      handleOk,
      selectedRowKeys,
      onRowSelect,
      buttonSubmitType,
      flowContext,
      rules,
      returnSelect,
      buttonLoading,
      formState,
      listLoading,
      listNull,
      onChangeDatePicker
    }
  }
})
</script>

<style scoped lang="scss">
.table-row-name {
  font-weight: bold;
}

.item-trigger-table-small {
  font-size: 13px;
}

.flow-type-note {
}

.icon-zns {
  width: 28px;
  height: 28px;
}

.note-first-msg {
  padding-bottom: 20px;
  color: orangered;
}
</style>
