<template>
  <h3 class="title-flow-context" v-if="!type">
    {{$t('FLOW_PREVIEW')}}
  </h3>
  <iframe style="width: 100%; min-height: 480px;" :src="flowContext.preview_url" frameborder="0"></iframe>

  <div class="preview-container">
    <div class="post-back-button">
      <a-button class="button-post-back" v-for="(item,index) in postBacks" :key="index+'_'">{{ item.title }}</a-button>
    </div>
  </div>
</template>

<script lang="ts">
import {defineComponent, ref, watch} from "vue";
import {PostbackContext} from "@/models/Flows/FlowContext";

export default defineComponent({
  name: "zalo-post-back-preview",
  props: ['flowContext', 'type'],
  setup(props) {
    console.log("zalo",props)
    let postBacks = ref<PostbackContext[]>([])
    watch(() => props.flowContext, () => {
      updateProps()
    })
    const updateProps = () => {
      if (props.flowContext.postback_contexts) {
        postBacks.value = [...props.flowContext.postback_contexts]
      }
    }
    updateProps()
    return {
      postBacks
    }
  }
})
</script>

<style lang="scss" scoped>
.preview-container {
  background-color: white;
  //min-height: 480px;
  border-radius: 10px;
  //padding: 15px;
}


.content-message {
  margin-right: 20px;
  padding: 15px;
  background-color: #E1E1E1;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  border-bottom-right-radius: 15px;
}

.post-back-button {
  margin-top: 18px;
  text-align: center;

  .button-post-back {
    margin: 5px 10px;
  }
}

.title-flow-context {
  text-align: center;
  font-weight: bold;
}
</style>
