<template>
  <a-modal title="Import product to stocks" v-model:visible="visible" :width="screenWidth>=780?'1000px':'85%'">
    <a-table :dataSource="data" :columns="columns" :pagination="false">
      <template #quantity>
        <a-input type="number">
        </a-input>
      </template>
      <template #import_price>
        <a-input-number
            style="width: 100%"
            :formatter="value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')"
            :parser="value => value.replace(/\$\s?|(,*)/g, '')"
        />
      </template>
      <template #note>
        <a-textarea placeholder="note"/>
      </template>
      <template #delete="{record}">
        <delete-outlined @click="removeProduct(record.key)"/>
      </template>
    </a-table>
    <div class="button-add-stock">
      <a-button type="dashed" @click="addProduct">
        <plus-outlined/>
        Add stock
      </a-button>
    </div>
  </a-modal>
  <a-modal v-model:visible="productModalVisible" title="Select product" width="900px">
    <a-input-search
        class="search-product-input"
        placeholder="Search product"
    />
    <a-table :columns="productSearchColumns" :data-source="productData" :row-selection="rowSelection">
      <template #name="{ record }">
        <a>{{ record.name }}</a>
        {{ record.key === '2' ? '(Selected)' : '' }}
      </template>
      <template #customTitle>
      <span>
        <smile-outlined/>
        Name
      </span>
      </template>
      <template #image>
      <span>
         <a-image
             :width="100"
             src="https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png"
         />
      </span>
      </template>
      <template #total="{text}">
        <span class="total">
          {{ text }}
        </span>
      </template>
    </a-table>
  </a-modal>

</template>

<script lang="ts">
import {Options, Vue} from "vue-class-component";
import {PlusOutlined, DeleteOutlined} from "@ant-design/icons-vue";
import {computed, defineComponent, ref} from "vue";
import {Tag} from "@/models/Tags/Tags";

const columns: ColumnData[] = [
  {
    title: 'Product',
    key: 'product',
    dataIndex: 'product',
  },
  {
    title: 'Quantity',
    key: 'quantity',
    dataIndex: 'quantity',
    slots: {customRender: 'quantity'},
  },
  {
    title: 'Import price',
    key: 'import_price',
    dataIndex: 'import_price',
    slots: {customRender: 'import_price'},
  },
  {
    title: 'Note',
    key: 'note',
    dataIndex: 'note',
    slots: {customRender: 'note'},
  },
  {
    key: 'delete',
    slots: {customRender: 'delete'},
  },
]
const data1: Fake[] = [
  {
    key: '1',
    product: "a",
    quantity: 123,
    import_price: 30000,
    note: "note"
  }
]

interface ColumnData {
  title?: string,
  key: string,
  dataIndex?: string,
  slots?: { customRender: string },
}

interface Fake {
  key: string
  product?: string
  quantity?: number
  import_price?: number
  note?: string
}

const productData = [
  {
    key: '1',
    name: 'John Brown',
    price: '400,000',
    address: 'New York No. 1 Lake Park',
    type: 'nice',
    quantity: 2,
    weight: 100,
    total: '400,000'
  },
  {
    key: '2',
    name: 'Jim Green',
    price: '400,000',
    address: 'London No. 1 Lake Park',
    type: 'nice',
    quantity: 2,
    weight: 100,
    total: '400,000'
  },
];
const productSearchColumns = [
  {
    dataIndex: 'name',
    key: 'name',
    slots: {title: 'customTitle', customRender: 'name'},
  },
  {
    title: 'Image',
    dataIndex: 'age',
    key: 'age',
    slots: {customRender: 'image'},
  },
  {
    title: 'Products info',
    dataIndex: 'address',
    key: 'address',
  },
  {
    title: 'Type',
    key: 'type',
    dataIndex: 'type',
  },
  {
    title: 'Price',
    key: 'price',
    dataIndex: 'price',
  },
  {
    title: 'Weight',
    key: 'weight',
    dataIndex: 'weight',
  },
  {
    title: 'Total',
    key: 'total',
    dataIndex: 'total',
    slots: {customRender: 'total'},
  },
];
interface FakeData {
  key:  string,
  name: string,
  price: string,
  address: string,
  type: string,
  weight: number,
  total: string
}
export default defineComponent({
  name: "stock-import-modal",
  components: {
    PlusOutlined,
    DeleteOutlined
  },
  setup() {
    const rowSelection = {

      onSelectAll: (selected: boolean) => {
        console.log(selected);
      },
      getCheckboxProps: (record: FakeData) => ({
        disabled: record.key === '2', // Column configuration not to be checked
        name: record.name +" Selected",
      }),
    };
    const visible=ref(false)
    const productModalVisible=ref(false)
    const data=ref(data1)
    const showModal=()=>{
      visible.value=true
    }
    const addProduct=()=>{
      productModalVisible.value=true
    }
    const removeProduct=(key:string)=>{
      data.value = data.value.filter((x: Fake) => x.key !== key)
    }
    const screenWidth = computed<number>(() =>  window.innerWidth)
    return {
      data,
      columns,
      visible,
      options: [...Array(25)].map((_, i) => ({value: (i + 10).toString(36) + (i + 1)})),
      productSearchColumns,
      productData,
      rowSelection,
      showModal,
      productModalVisible,
      addProduct,
      removeProduct,
      screenWidth
    }
  },
})
</script>

<style scoped lang="scss">
.import-stock-modal {
  width: 1000px;
}

.button-add-stock {
  margin-top: 12px;
  text-align: center;
}

.search-product-input{
  margin-bottom: 10px;
  width: 50%;
}


</style>