export enum TriggerMutationTypes {
    SET_Triggers = 'SET_TRIGGERS',
    SET_TRIGGER_METADATA = 'SET_TRIGGER_METADATA',
    SET_TRIGGER_STATISTICS = 'SET_TRIGGER_STATISTICS',
    SET_INNER_TRIGGER_STATISTICS = 'SET_INNER_TRIGGER_STATISTICS',
    SET_TRIGGER_STATISTIC_METADATA = 'SET_TRIGGER_STATISTIC_METADATA',
    SET_INNER_TRIGGER_STATISTIC_METADATA = 'SET_INNER_TRIGGER_STATISTIC_METADATA',
    SET_TRIGGER_QUICK_MESSAGES = 'SET_TRIGGER_QUICK_MESSAGES',
    SET_TRIGGER_STATISTICS_LOG = 'SET_TRIGGER_STATISTICS_LOG',
    SET_VIRAL = 'SET_VIRAL',
}

