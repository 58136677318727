<template>
  <div class="container" v-if="!isLoading" :key="componentKey">
    <a-row>
      <a-col :span="6" class="left-control">
        <h3 class="title-flow-context">
          {{ leftTitle }}
        </h3>
        <transition name="slide-fade">
          <flow-management-panel v-if="flowType>0&&current===1" class="root-flow-control"
                                 :flowContext="currentFlowContext"
                                 :swapRootFlowContext="swapRootFlowContext"
                                 :deleteFlowContext="deleteFlowContext"
                                 :flowContextData="flowContextData"/>
        </transition>

      </a-col>
      <a-col :span="12">
        <a-steps v-model:current="current">
          <a-step :title="$t('FLOW_INFORMATION')"/>
          <a-step :title="$t('FLOW_SETUP_CONTEXT')"
                  :disabled="!(flowContextData.context.flow_contexts&&flowContextData.context.flow_contexts.length>0)"/>
          <a-step :title="$t('FLOW_PUBLISHED')" disabled
                  :status="flowContextData.context.flow_contexts&&flowContextData.context.flow_contexts.length>0&&flowContextData.context.flow_contexts[0].status==='publish'?'finish':'wait'"/>
        </a-steps>
        <div class="steps-content">
          <transition name="slide-fade">
            <component  :isShow="isShow"  v-bind:is="stepComponent" :updateCurrentFlowContext="updateCurrentFlowContext"
                       :flowContext="currentFlowContext"
                       :flowContextData="flowContextData"
                       :deleteFlowContext="deleteFlowContext"
                       :updateRootFlow="updateRootFlow"
                       :nextStep="nextStep"
                       :createRootFlow="createRootFlow">
            </component>
          </transition>
        </div>

      </a-col>
      <a-col :span="6" class="right-control">
        <transition name="slide-fade">
          <div v-if="flowType>0">
            <div class="btn-submit">
              <a-button type="primary" class="btn-publish" @click="onPublish">
                <send-outlined/>
                {{ $t("FLOW_PUBLISH") }}
              </a-button>
            </div>
            <facebook-post-back-preview v-if="flowType===1" :flowContext="currentFlowContext"/>
            <ZaloPostbackPreview v-else-if="flowType===2" :flowContext="currentFlowContext"/>
            <facebook-reply-comment-preview v-else-if="flowType===3" :flowContext="currentFlowContext"/>
            <zalo-oa-preview v-else-if="flowType===4" :flowContext="currentFlowContext"/>
            <email-preview v-else-if="flowType===5" :flowContext="currentFlowContext"/>

          </div>
        </transition>
      </a-col>
    </a-row>
  </div>
  <div class="spin-container" v-else>
    <a-spin/>
  </div>
</template>

<script lang="ts">
import {Options, Vue} from "vue-class-component";
import FlowInformation from "@/components/Flow/FlowInformation.vue";
import FlowFacebook from "@/components/Flow/FlowFacebook.vue";
import FlowReplyCommentFacebook from "@/components/Flow/FlowReplyCommentFacebook.vue";
import FacebookReplyCommentPreview from "@/components/Flow/FacebookReplyCommentPreview.vue";
import FlowSetupType from "@/components/Flow/FlowSetupType.vue";
import FlowZalo_ZNS from "@/components/Flow/FlowZalo_ZNS.vue";
import FacebookPostbackPreview from "@/components/Flow/FacebookPostbackPreview.vue";
import FacebookPostBackPreview from "@/components/Flow/FacebookPostbackPreview.vue";
import FlowManagementPanel from "@/components/Flow/FlowManagementPanel.vue";
import FlowTriggerCondition from "@/components/Flow/FlowTriggerCondition.vue"
import {SendOutlined, CheckOutlined} from '@ant-design/icons-vue'
import {mapActions, mapGetters} from "vuex";
import {FlowActionTypes} from "@/store/modules/flow/action-types";
import {TagsActionTypes} from "@/store/modules/tag/action-types";
import {FormFlowInformation} from "@/models/Flows/FlowForm";
import {FlowContext, FlowContextData, FlowContexts} from "@/models/Flows/FlowContext";
import {ChangeCurrentFlow, S_EventBus} from "@/utils/EventBus";
import APIs from "@/APIs/APIs";
import RouterEndpoint from "@/router/RouterEndpoint";
import {Tag} from "@/models/Tags/Tags";
import ZaloPostbackPreview from "@/components/Flow/ZaloPostbackPreview.vue";
import {notification} from "ant-design-vue";
import FlowZaloOA from "@/components/Flow/FlowZaloOA.vue";
import ZaloOAPreview from "@/components/Flow/ZaloOAPreview.vue";
import ZaloOaPreview from "@/components/Flow/ZaloOAPreview.vue";
import FlowEmail from "@/components/Flow/FlowEmail.vue";
import EmailPreview from "@/components/Flow/EmailPreview.vue";
import FlowVoiceText from "@/components/Flow/FlowVoiceText.vue";
import FlowTimer from "@/components/Flow/FlowTimer.vue";
import FlowViralLuckyWheel from "@/components/Flow/FlowViralLuckyWheel.vue";
import FlowViralShareGift from "@/components/Flow/FlowViralShareGift.vue";
import FlowInstagram from "@/components/Flow/FlowInstagram.vue";
import FlowOrder from "../../../components/Flow/FlowOrder.vue";

let updateTimeout: number
const currentFlowContext: FlowContext = {}
const flowContextData: FlowContextData = {context: {flow_contexts: []}}

@Options({
  name: "flow-context",
  components: {
    ZaloOaPreview,
    FacebookPostBackPreview,
    'flow-information':FlowInformation,
    'flow-zalo-oa':FlowZaloOA,
    FlowFacebook,
    FlowInstagram,
    'flow-reply-comment-facebook':FlowReplyCommentFacebook,
    'flow-email':FlowEmail,
    'flow-order':FlowOrder,
    FlowSetupType,
    'flow-zalo-zns': FlowZalo_ZNS,
    'flow-viral-share-gift': FlowViralShareGift,
    'flow-viral-lucky-wheel': FlowViralLuckyWheel,
    'flow-voice-text': FlowVoiceText,
    'flow-timer': FlowTimer,
    'flow-trigger-condition':FlowTriggerCondition,
    FacebookPostbackPreview,
    SendOutlined,
    CheckOutlined,
    FlowManagementPanel,
    ZaloPostbackPreview,
    FacebookReplyCommentPreview,
    EmailPreview,
    ZaloOAPreview
  },
  data() {
    return {
      current: 0,
      flowType: 0,
      isLoading: false,
      currentFlowContext,
      leftTitle: this.$t('FLOW_CREATE_NEW'),
      flowContextData,
      componentKey: 0,
      isShow:false
    }
  },
  computed: {
    ...mapGetters({getFlowContextData: 'getFlowContextData', getFlowTags: 'getFlowTags'}),
    stepComponent() {
      if (this.current === 1) {
        if (this.flowType === 1) {
          return "flow-facebook"
        }else if (this.flowType === 2) {
          return "flow-zalo-zns"
        }else if (this.flowType === 3) {
          return "flow-reply-comment-facebook"
        }else if (this.flowType === 4) {
          return "flow-zalo-oa"
        }else if (this.flowType === 5) {
          return "flow-email"
        }else if (this.flowType===6){
          return "flow-viral-lucky-wheel"
        }else if (this.flowType===7){
          return "flow-voice-text"
        }else if (this.flowType===8){
          return "flow-timer"
        }else if (this.flowType===9){
          return "flow-viral-share-gift"
        }else if (this.flowType===10){
          return "flow-trigger-condition"
        }else if (this.flowType===11){
          return "flow-instagram"
        }else if (this.flowType===1212){
          return "flow-order"
        }
        return "flow-setup-type"
      }
      return 'flow-information'
    }
  },
  created() {
    this.fetchTags({type: 'customer'});
    this.fetchTags({type: 'flow'});
    if (this.$route.params.id && this.$route.params.id !== ":id") {
      this.leftTitle = this.$t('DETAIL_FLOW')
      this.isLoading = true;
      this.getFlowContext({id: this.$route.params.id}).then(() => {
        if (this.getFlowContextData?.context.flow_contexts.length > 0) {
          this.flowType = this.getFlowContextData?.context.flow_contexts[0].flow_type
          this.flowContextData = this.getFlowContextData
          this.currentFlowContext = this.getFlowContextData?.context?.flow_contexts[0]
          this.leftTitle = this.$t('DETAIL_FLOW', [this.getFlowContextData.name])
          this.current = 1;
        } else if (this.getFlowContextData?.context) {
          this.flowContextData = this.getFlowContextData
          this.current = 1;
        } else {
          this.current = 0;
        }
        this.isLoading = false;
      }).catch(() => {
        this.isLoading = false
      })
    }
    const createRootFlowContext = (flow: FlowContext): void => {
      let flowContextData = {...this.flowContextData}
      flowContextData.context.flow_contexts.unshift(flow)
      this.currentFlowContext = flow;
      this.flowContextData = flowContextData
      this.flowType = flow.flow_type
      this.syncFlowContext()
    }
    const createFlow = (flow: FlowContext): void => {
      let flowContextData = {...this.flowContextData}
      flowContextData.context.flow_contexts.push(flow)
      this.flowContextData = flowContextData
      this.syncFlowContext()
    }
    const flowChange = (flowId: number): void => {
      this.changeCurrentFlowContext(flowId)
    }
    const currentFlowListener: ChangeCurrentFlow = {
      flowChange,
      createFlow,
      createRootFlowContext
    }
    S_EventBus.instance().setCurrentFlowListener(currentFlowListener)
  },
  methods: {
    ...mapActions({
      getFlowContext: FlowActionTypes.FETCH_FLOW_CONTEXT,
      fetchTags: TagsActionTypes.FETCH_TAGS
    }),

    forceRerender() {
      // this.componentKey += 1;
    },
    updateRootFlow(data: FormFlowInformation) {
      if (this.flowContextData) {
        this.flowContextData.name = data.name
        this.flowContextData.description = data.description
        this.flowContextData.tags = this.getTagByIds(data.tags)
        this.syncFlowContext()
      }
    },
    nextStep(step: number) {
      this.current = step
    },
    createRootFlow(flowContextData: FlowContextData) {
      this.flowContextData = flowContextData;
      this.$router.replace({name: RouterEndpoint.FLOW_CONTEXT.name, params: {id: flowContextData.id}})
      this.current = 1
    },

    deleteFlowContext(flowId: string) {
      let flowContextData = {...this.flowContextData}
      flowContextData.context.flow_contexts = flowContextData.context.flow_contexts.filter((flow: FlowContext) => flow.id !== flowId);
      this.flowContextData = flowContextData;
      if (this.currentFlowContext.id === flowId) {
        this.currentFlowContext = flowContextData.context.flow_contexts[0]
      }
      this.syncFlowContext()
    },
    updateCurrentFlowContext(payload: FlowContext) {
      let flowContextData = {...this.flowContextData}
      this.currentFlowContext = payload;
      for (let i = 0; i < flowContextData.context.flow_contexts.length; i++) {
        if (payload.id === flowContextData.context.flow_contexts[i].id) {
          flowContextData.context.flow_contexts[i] = payload;
        }
      }
      this.flowContextData = flowContextData
      this.syncFlowContext()
    },
    syncFlowContext() {
      if (updateTimeout) {
        clearTimeout(updateTimeout)
      }
      updateTimeout = window.setTimeout(() => {
        APIs.init().updateFlowV2Contexts(this.flowContextData).then(res => {
          if (res.status === 200 && res.data?.data) {
            this.flowContextData = res.data?.data
            this.changeCurrentFlowContext(this.currentFlowContext.id)
            if (res.data?.data.context.flow_contexts.length === 1) {
              this.forceRerender();
            }
          }
        }).catch(e => {
          console.error("update flow context", e)
        })
      }, 1500)
    },
    changeCurrentFlowContext(flowId: number) {
      this.isShow = true
      for (let i = 0; i < this.flowContextData.context.flow_contexts.length; i++) {
        if (this.flowContextData.context.flow_contexts[i].id === flowId) {
          this.currentFlowContext = this.flowContextData.context.flow_contexts[i]
          this.flowType = this.currentFlowContext.flow_type

          this.$nextTick().then(()=> {

            this.isShow = false;
          })
          break
        }
      }
    },
    onPublish() {
      if (this.validateFlow()) {
        APIs.init().publishFlow(this.flowContextData).then(res => {
          if (res.status === 200 && res.data?.data) {
            this.flowContextData = res.data?.data;
            this.$router.replace({name: RouterEndpoint.FLOWS.name, query: this.flowsRequest})
            notification.success({
              duration: 2,
              message: 'Information',
              description:
                  `Flow ${res.data?.data.name} published.`
            });
          }
        })
      } else {
        setTimeout(() => {
          S_EventBus.instance().dispatchValidateFlowContext()
        }, 1000)
        console.log("failed validation")
      }
    },
    validateFlow(): boolean {
      let isValidated = true;
      // if (!this.currentFlowContext.name || !this.currentFlowContext.message) {
      //   isValidated = false
      // }
      // if (this.currentFlowContext.postback_contexts && this.currentFlowContext.postback_contexts.length) {
      //   for (const postBack of this.currentFlowContext.postback_contexts)
      //     if (!postBack.title) {
      //       isValidated = false
      //       break
      //     }
      // }
      // if (this.flowContextData.reference_flow_contexts) {
      //   for (const flow of this.flowContextData.reference_flow_contexts)
      //     if (!flow.name || !flow.message) {
      //       this.currentFlowContext = flow;
      //       isValidated = false
      //       break
      //     } else if (flow.postback_contexts && flow.postback_contexts.length) {
      //       for (const postBack of flow.postback_contexts)
      //         if (!postBack.title) {
      //           this.currentFlowContext = flow;
      //           isValidated = false
      //           break
      //         }
      //     }
      // }
      return isValidated;
    },
    getTagByIds(ids: number[]) {
      if (this.getFlowTags) {
        return this.getFlowTags.filter((tag: Tag) => tag&&tag.id&&ids.indexOf(tag.id) >= 0)
      }
      return []
    },
    swapRootFlowContext(flowId: string,index:number) {
      const flowContext = this.flowContextData.context.flow_contexts.find((flow: FlowContext) => flow.id === flowId);
      if(index){
        this.flowContextData.context.flow_contexts = this.flowContextData.context.flow_contexts.filter((flow: FlowContext) => flow.id !== flowId)
        this.flowContextData.context.flow_contexts.splice(index,0,flowContext)
        console.log(this.flowContextData.context.flow_contexts,flowContext)
      }else {
        this.flowContextData.context.flow_contexts = [...[flowContext], ...this.flowContextData.context.flow_contexts.filter((flow: FlowContext) => flow.id !== flowId)]

      }
      this.syncFlowContext()
    }
  },
})
export default class FlowContextComponent extends Vue {

}
</script>

<style lang="scss" scoped>
.container {
  margin: 10px 15px;

  .root-flow-control {
    margin-top: 35px;
  }

  .left-control {
    padding-right: 2%;
  }

  .right-control {
    padding-left: 2%;
  }

  .title-flow-context {
    text-align: center;
    font-weight: bold;
  }

  .btn-submit {
    text-align: right;
    margin-bottom: 10px;

    .btn-publish {
      margin-left: 12px;
    }
  }
}

.row-horizontal {
  display: flex;
}

.steps-content {
  margin-top: 25px;
  border: 1px dashed #e9e9e9;
  border-radius: 6px;
  background-color: #fafafa;
  min-height: 200px;
  padding-top: 20px;
  text-align: center;
}

.slide-fade-enter-active {
  transition: all 0.3s ease-out;
}

.slide-fade-leave-active {
  transition: all 0.1s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-fade-enter-from,
.slide-fade-leave-to {
  transform: translateX(20px);
  opacity: 0;
}

.spin-container {
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
}
</style>
