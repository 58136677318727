<template>
  <div class="main-flow" id="main-flow">
    <a-form
        ref="formRef"
        :rules="rules"
        :model="formState"
        layout="vertical"
    >
      <a-form-item ref="name" :label="$t('FLOW_MESSAGE_NAME')" name="name">
        <a-input :placeholder="$t('FLOW_MESSAGE_NAME')" @change="onTextChange" v-model:value="formState.name"/>
      </a-form-item>

      <a-card :title="$t('REPLY_COMMENT_DETAIL')" class="card-message">
        <a-form-item>
            <span class="ant-upload-picture-card-wrapper" style="display: flex">

              <div class="ant-upload ant-upload-select ant-upload-select-picture-card" @click="showImageSelectionModal">
                  <span role="button" tabindex="0" class="ant-upload">
                    <div>
                      <plus-outlined/>
                      <div class="ant-upload-text">{{ $t('UPLOAD') }}</div>
                    </div>
                  </span>
              </div>
               <div class="ant-upload-list ant-upload-list-picture-card" v-for="item in fileList" :key="item">
                <div class="ant-upload-list-picture-card-container">
                  <span>
                    <div
                        class="ant-upload-list-item ant-upload-list-item-done ant-upload-list-item-list-type-picture-card">
                      <div class="ant-upload-list-item-info">
                        <span>
                          <a class="ant-upload-list-item-thumbnail"
                             :href="item.url"
                             target="_blank" rel="noopener noreferrer">
                            <img :src="item.url"
                                 alt="image.png"
                                 class="ant-upload-list-item-image">
                          </a>
                        </span>
                      </div>
                      <span class="ant-upload-list-item-actions">
                        <a :href="item.url"
                           target="_blank"
                           rel="noopener noreferrer" title="Preview file">
                          <eye-outlined/>
                        </a>
                        <delete-outlined @click="removeImage(item)"/>
                      </span>
                    </div>
                  </span>
                </div>
              </div>
            </span>
        </a-form-item>
        <a-form-item name="Reply comment">
          <a-textarea   @click="forcusMessagetext" ref="inputMessageContent" :placeholder="$t('COMMENT_REPLY_CONTENT')" :rows="3"
                      v-model:value="formState.message_content" @change="onTextChange"/>
          <a-tag style="cursor: pointer" @click="messageFullName()"
          >##full_name##
          </a-tag
          >
          <a-dropdown>
            <a-tag class="ant-dropdown-link" @click.prevent>
              {{$t('READ_MORE')}}
              <DownOutlined />
            </a-tag>
            <template #overlay>
              <a-menu>
                <a-menu-item @click="messageColumn(it.identified_name,)" v-for="it in getColumnsConfig" :key="it.id" >
                  {{it.name}}
                </a-menu-item>
                <a-menu-item  @click="messageColumn(i.id)"  v-for="i in getColumn" :key="i.id" >
                  {{$t(i.text)}}
                </a-menu-item>
              </a-menu>
            </template>
          </a-dropdown>
          <a-dropdown>
            <a-tag class="ant-dropdown-link" @click.prevent>
              {{$t('ORDER')}}
              <DownOutlined />
            </a-tag>
            <template #overlay>
              <a-menu>
                <a-menu-item @click="messageColumn(i.id)" v-for="i in getOrderColumn" :key="i.id" >
                  {{$t(i.text)}}
                </a-menu-item>

              </a-menu>
            </template>
          </a-dropdown>
        </a-form-item>
      </a-card>
      <flow-next-actions  v-if="!isShow" :buttonName="$t('FLOW_SUCCESS_DELIVERY')"
                         :updateAction="updateSuccessAction"
                         :flowContextData="flowContextData"
                         :flowContext="flowContext"
                         :actionContext="flowContext.action_send_success_context"/>
      <flow-next-actions  v-if="!isShow" :buttonName="$t('FLOW_FAILED_DELIVERY')"
                         :updateAction="updateFailedAction"
                         :flowContextData="flowContextData"
                         :flowContext="flowContext"
                         :actionContext="flowContext.action_send_failed_context"/>
      <flow-next-actions  v-if="!isShow" :buttonName="$t('FLOW_DEFAULT_DELIVERY')"
                         :updateAction="updateDefaultAction"
                         :flowContextData="flowContextData"
                         :flowContext="flowContext"
                         :actionContext="flowContext.action_default_context"/>
      <div class="row-button-action" style="text-align: right">
        <a-popconfirm
            @confirm="deleteCurrentFlowContext"
            :title="$t('FLOW_CONFIRM_DELETE_CONTEXT')"
            :ok-text="$t('YES')"
            :cancel-text="$t('NO')"
        >
          <a-button danger type="primary">
            <delete-outlined/>
            {{ $t("FLOW_DELETE") }}
          </a-button>
        </a-popconfirm>
      </div>
    </a-form>
  </div>
  <image-selection-modal :single="true" ref="imageSelectionRef" :onSelect="onImageSelect"/>
</template>

<script lang="ts">
import {computed, defineComponent, onBeforeMount, reactive, ref, UnwrapRef, watch} from 'vue';
import {DeleteOutlined, PlusOutlined} from '@ant-design/icons-vue'
import FlowNextActions from "@/components/Flow/FlowNextActions.vue";
import {FormFlowContextFacebook, FormFlowInformation} from "@/models/Flows/FlowForm";
import {ValidateErrorEntity} from "ant-design-vue/es/form/interface";
import {useStore} from "@/store";
import {ActionContext, PostbackContext} from "@/models/Flows/FlowContext";
import {S_EventBus, ValidateFlowContext} from "@/utils/EventBus";
import {useI18n} from "vue-i18n";
import {MixUtils} from "@/utils/Utils";
import {AWS_DO_Model} from "@/models/AWS_DO/AWS_DO";
import ImageSelectionModal from "@/components/Image/ImageSelectionModal.vue";


export default defineComponent({
  name: "flow-reply-comment-facebook",
  props: [
    'flowContext',
    'updateCurrentFlowContext',
    'flowContextData',
    'deleteFlowContext',
      'isShow'
  ],
  components: {
    DeleteOutlined,
    FlowNextActions,
    PlusOutlined,
    ImageSelectionModal
  },
  setup(props) {
    const formRef = ref();
    const messengerTags = ref();
    const store = useStore();
    const formState: UnwrapRef<FormFlowContextFacebook> = reactive({});
    const fileList = ref<AWS_DO_Model[]>([])
    const i18n = useI18n();
    const imageSelectionRef = ref()
    const inputMessageContent = ref()
    const  cursorPositionMessageText= ref(0)
    const getColumnsConfig = computed(()=> store.getters.getCustomerConfig)
    const getColumn =[
      {id:'employees',text:'SALE_STAFF'},
      {id:'phone',text:'CUSTOMER_PHONE'},
      {id:'address',text:'CUSTOMER_ADDRESS'},
      {id:'birth',text:'CUSTOMER_BIRTHDAY'},
      {id:'sex',text:'SEX'},

    ]
    const getOrderColumn =[
      {id:'shipping_code',text:'ORDER_SHIPPING_UNIT_ID'},
      {id:'shipping_address',text:'CUSTOMER_ADDRESS'},
      {id:'phone',text:'CUSTOMER_PHONE'},
      {id:'detail',text:'NAME'},
      {id:'total',text:'TOTAL_AMOUNT'},
      {id:'prepaid',text:'PAID'},
      {id:'cod_amount',text:'COD'},
      {id:'created_at',text:'ORDER_DATE'},
      {id:'status',text:'STATUS'},
      {id:'order_number',text: 'ORDER_ID'}

    ]
    const updateProps = () => {
      formState.name = props.flowContext.name
      formState.message_content = props.flowContext.message
      formState.message_tag = props.flowContext.messenger_tag ? props.flowContext.messenger_tag : undefined
      if (props.flowContext.postback_contexts) {
        postbackContexts.value = props.flowContext.postback_contexts
      } else {
        postbackContexts.value = []
      }
      if (props.flowContext.attachment_images&&props.flowContext.attachment_images.length){
        fileList.value=[{key:props.flowContext.attachment_images[0],url:props.flowContext.attachment_images[0]}]
      }
    }
    watch(() => props.flowContext, () => {
      updateProps()
    })
    const flowFormValidate: ValidateFlowContext = {
      showValidateForm(): void {
        onSubmit()
      }
    }
    onBeforeMount(() => {
      S_EventBus.instance().setValidateFlowContextListener(flowFormValidate)
    })
    messengerTags.value = store.getters.getFlowConstants?.messenger_tag
    const postbackContexts = ref<PostbackContext[]>([])
    const rules = {
      name: [
        {required: true, message: i18n.t('FLOW_VALIDATE_FLOW_NAME'), trigger: 'blur'},
      ],
      tags: [
        {
          type: 'array',
          required: true,
          message: i18n.t('FLOW_VALIDATE_FACEBOOK_MESSENGER_TAG'),
          trigger: 'change',
        },
      ],
      message_content: [
        {required: true, message: i18n.t('FLOW_VALIDATE_FACEBOOK_MESSAGE_CONTENT'), trigger: 'blur'},
      ],
    };
    const forcusMessagetext = (e: any) =>{
      // if(e.target.value.lastIndexOf('#') !== -1){
      //   e.target.setSelectionRange(e.target.value.lastIndexOf('#')+1,e.target.value.lastIndexOf('#')+1)
      //   console.log(e.target.selectionStart)
      // }else {
      cursorPositionMessageText.value = e.target.selectionStart
      console.log(cursorPositionMessageText.value)



    }
    const messageFullName = () => {
      inputMessageContent.value.focus()
      const text = ref<any>(formState.message_content);
      const start = text.value.slice(0,cursorPositionMessageText.value)
      const end = text.value.slice(cursorPositionMessageText.value)
      text.value =
          start + (start ? " " : "") + "##full_name##" + (end ? " " : "") + end
      formState.message_content = text.value;
      onSubmit();

    };
    const messageColumn = (column:string) => {
      inputMessageContent.value.focus()
      const text = ref<any>(formState.message_content);
      const start = text.value.slice(0,cursorPositionMessageText.value)
      const end = text.value.slice(cursorPositionMessageText.value)
      text.value =
          start + (start ? " " : "") + `##${column}##` + (end ? " " : "") + end
      formState.message_content = text.value;
      onSubmit();







    };
    const onTextChange = (e: any) => {
      if(e && e.target && e.target.selectionStart){
        cursorPositionMessageText.value = e.target.selectionStart
        onSubmit();
      }else {
        onSubmit();
      }
    }

    const updateSuccessAction = (action: ActionContext) => {
      const flow = {...props.flowContext}
      flow.action_send_success_context = action
      props.updateCurrentFlowContext(flow)
    }
    const updateFailedAction = (action: ActionContext) => {
      const flow = {...props.flowContext}
      flow.action_send_failed_context = action
      props.updateCurrentFlowContext(flow)
    }
    const updateDefaultAction = (action: ActionContext) => {
      const flow = {...props.flowContext}
      flow.action_default_context = action
      props.updateCurrentFlowContext(flow)
    }

    const deleteCurrentFlowContext = () => {
      props.deleteFlowContext(props.flowContext.id)
    }
    const showImageSelectionModal = () => {
      imageSelectionRef.value.showModal()
    }
    const removeImage = (image: AWS_DO_Model) => {
      fileList.value = fileList.value.filter((item: AWS_DO_Model) => item.key !== image.key)
      onSubmit()
    }
    const onImageSelect = (images: AWS_DO_Model[]) => {
      fileList.value = images
      onSubmit()
    }

    const onSubmit = () => {
      formRef.value
          .validate()
          .then(() => {
            const flow = {...props.flowContext}
            flow.message = formState.message_content
            flow.name = formState.name
            flow.attachment_images = fileList.value && fileList.value.length ? [fileList.value[0].url] : []
            props.updateCurrentFlowContext(flow)
          })
          .catch((error: ValidateErrorEntity<FormFlowInformation>) => {
            console.log('error', error);
          });
    };
    updateProps()
    return {
      rules,
      formState,
      fileList,
      formRef,
      onSubmit,
      onTextChange,
      postbackContexts,
      messengerTags,
      updateSuccessAction,
      updateFailedAction,
      updateDefaultAction,
      deleteCurrentFlowContext,
      showImageSelectionModal,
      onImageSelect,
      imageSelectionRef,
      removeImage,
      inputMessageContent,
      cursorPositionMessageText,
      messageFullName,
      forcusMessagetext,
      messageColumn,
      getColumnsConfig,
      getColumn,
      getOrderColumn
    }
  }
})
</script>

<style lang="scss" scoped>
.main-flow {
  position: relative;
  padding: 40px 18% 60px;
  text-align: left;


  .icon-brand-sticker {
    position: absolute;
    font-size: 28px;
    top: 0;
    margin-right: 25px;
    right: 0;
  }

  .row-button-action {
    margin-top: 20px;
  }

  .card-message {
    border-radius: 10px;

  }
}

.post-back-button {
  margin-top: 18px;
  text-align: center;
}

.button-post-back {
  margin: 5px 10px;
}


</style>
