<template>
  <a-form
      ref="formRef"
      :model="formState"
      :rules="rules"
      class="main-flow"
      :label-col="labelCol"
      :wrapper-col="wrapperCol"
  >
    <a-form-item :label="$t('FLOW_FLOW_NAME')" name="name">
      <a-input showCount :maxlength="99" v-model:value="formState.name" type="text" placeholder="Aaa..."/>
    </a-form-item>
    <a-form-item :label="$t('FLOW_TAGS')" name="tags">
      <a-select style="text-align: left" mode="multiple" v-model:value="formState.tags" @change="tagSelectionChange" placeholder="tag1, tag2, ...">
        <a-select-option v-for="item in flowTags" :value="item.id" :key="item.id" :color="item.color">
          <a-tag :color="item.color">{{ item.name }}</a-tag>
        </a-select-option>
      </a-select>
    </a-form-item>
    <a-form-item :label="$t('FLOW_DESCRIPTION')" name="description">
      <a-textarea v-model:value="formState.description" placeholder="Aaa..."/>
    </a-form-item>
    <a-form-item :wrapper-col="{ span: 14, offset: 4 }" style="text-align: center">
      <a-button type="primary" @click="onSubmit" :loading="nextLoading">{{$t('SETUP_CONTEXT')}}
        <arrow-right-outlined/>
      </a-button>
    </a-form-item>
  </a-form>
</template>

<script lang="ts">
import {ValidateErrorEntity} from 'ant-design-vue/es/form/interface';
import {defineComponent, PropType, reactive, ref, UnwrapRef, watch} from 'vue';
import {FormFlowInformation, FormFlowInformationFunction} from "@/models/Flows/FlowForm";
import {ArrowRightOutlined} from '@ant-design/icons-vue'
import {useStore} from "@/store";
import {Tag} from "@/models/Tags/Tags";
import {FlowContextData} from "@/models/Flows/FlowContext";
import APIs from "@/APIs/APIs";
import { useI18n } from "vue-i18n";
import {FlowType} from "@/models/Flows/Flow";


export default defineComponent({
  name: 'flow-information',
  props: {
    updateRootFlow: {
      required: true,
      type: Function as PropType<FormFlowInformationFunction>
    },
    flowContextData: {
      required: true,
      type: Object as PropType<FlowContextData>
    },
    createRootFlow: {
      required: true,
      type: Function
    },
    nextStep: {
      required: true,
      type: Function
    },
  },
  components: {
    ArrowRightOutlined
  },
  setup(props) {
    console.log('flow information', props.flowContextData)
    const formRef = ref();
    const formState: UnwrapRef<FormFlowInformation> = reactive({
      name: '',
      tags: [],
      description: '',
    });

    const nextLoading = ref(false)
    const i18n = useI18n();
    const store = useStore()
    watch(() => props.flowContextData, () => {
      updateRootFlowContext()
    })
    const rules = {
      name: [
        {required: true, message: i18n.t("FLOW_VALIDATE_FLOW_NAME"), trigger: 'blur'},
      ],
      tags: [
        {
          type: 'array',
          required: false,
          message: i18n.t('FLOW_VALIDATE_FACEBOOK_MESSENGER_TAG'),
          trigger: 'change',
        },
      ],
      description: [{required: false, message: 'Please input activity form', trigger: 'blur'}],
    };
    const tagSelectionChange = () => {
      if (props.flowContextData && props.flowContextData.context.flow_contexts && props.flowContextData.context.flow_contexts.length>0) {
        props.updateRootFlow(formState)
      }
    }
    const onSubmit = () => {
      formRef.value
          .validate()
          .then(() => {
            if (props.flowContextData && props.flowContextData.context.flow_contexts && props.flowContextData.context.flow_contexts.length>0) {
              props.updateRootFlow(formState)
              props.nextStep(1)
            } else {
              nextLoading.value = true
              APIs.init().createFlow({name: formState.name, tags: formState.tags, description: formState.description, flow_type: FlowType.Flow})
                  .then(res => {
                    // nextLoading.value = false
                    if (res.status === 200 && res.data?.data) {
                      props.createRootFlow(res.data?.data)
                    }
                  }).catch(e => {
                nextLoading.value = false
                console.error(e)
              })
            }
          })
          .catch((error: ValidateErrorEntity<FormFlowInformation>) => {
            console.log('error', error);
          });
    };
    const updateRootFlowContext = () => {
      if (props.flowContextData) {
        if (props.flowContextData.name != null) {
          formState.name = props.flowContextData.name
        }
        if (props.flowContextData.description != null) {
          formState.description = props.flowContextData.description
        }
        if (props.flowContextData.tags) {
          props.flowContextData.tags.map((item: Tag) => {
            if (item&&item.id){
              formState.tags.push(item.id)
            }
          })
        }
      }
    }
    updateRootFlowContext()
    const flowTags = ref<Tag[]>([])
    const tags = store.getters.getFlowTags;
    if (tags) {
      flowTags.value = tags
    }
    return {
      formRef,
      labelCol: {span: 5},
      wrapperCol: {span: 15},
      other: '',
      formState,
      rules,
      onSubmit,
      flowTags,
      nextLoading,
      tagSelectionChange,
    };
  }
});
</script>
<style lang="scss" scoped>
.main-flow {
  margin-top: 50px;
  margin-bottom: 50px;
  text-align: left;
}
</style>
