import {Constant} from "@/utils/Constants";
import {ConfigData} from "@/models/ShopPages/ConfigData";
import moment from "moment";
import {v4 as uuidv4} from 'uuid';
import {io, Socket} from "socket.io-client";
import {useI18n} from "vue-i18n";

export class MSocketIo {

    static instance: MSocketIo;

    static getInstance(){
        if(this.instance){
            return this.instance
        }else{
            this.instance = new MSocketIo();
            return this.instance
        }

    }

    public socket: Socket;

    constructor() {
        this.socket = io(location.host.includes('biglead')||location.host.includes('alfreddesk')?'https://api.shopf1.net':'https://test.shopf1.net', {
            path:'/crm-socket/socket.io',
            'reconnection': true,
            'reconnectionDelay': 1000,
            'reconnectionDelayMax': 5000,
            'reconnectionAttempts': 5,
            autoConnect:true
        });
        this.socket.on("connect", () => {
            console.log("socket connected")
        });
        this.socket.on("disconnect", () => {
            console.log("socket disconnected")
        });
        this.socket.on("reconnect", (attempt) => {
            console.log("socket reconnected",attempt)
        });
        this.socket.on("reconnect_attempt", (attempt) => {
            console.log("socket reconnect_attempt",attempt)
        });
        this.socket.on("reconnect_error", (error) => {
            console.log("socket reconnect_error",error)
        });
        this.socket.on("reconnect_failed", (error) => {
            console.log("socket reconnect_failed",error)
        });
        this.socket.on("ping", () => {
            console.log("socket ping")
        });
    }

}

export const MixUtils = {
    methods: {
        updateColumsVersion():number{
            return 1
        },
        makeStr(length = 15) {
            let text = '';
            const possible = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
            for (let i = 0; i < length; i++) {
                text += possible.charAt(Math.floor(Math.random() * possible.length));
            }
            return text;
        },
        removeVietnamese(str: string): string {
            if (!str) {
                return ""
            }
            const from = "àáãảạăằắẳẵặâầấẩẫậèéẻẽẹêềếểễệđùúủũụưừứửữựòóỏõọôồốổỗộơờớởỡợìíỉĩịäëïîöüûñçýỳỹỵỷ",
                to = "aaaaaaaaaaaaaaaaaeeeeeeeeeeeduuuuuuuuuuuoooooooooooooooooiiiiiaeiiouuncyyyyy";
            for (let i = 0, l = from.length; i < l; i++) {
                str = str.replace(RegExp(from[i], "gi"), to[i]);
            }

            str = str.toLowerCase()
                .trim()
                .replace(/[^a-z0-9-]/g, '-')
                .replace(/-+/g, '-');
            return str;
        },
        removeVietnamese2(str?: string): string {
            if (!str) {
                return ""
            }
            const from = "àáãảạăằắẳẵặâầấẩẫậèéẻẽẹêềếểễệđùúủũụưừứửữựòóỏõọôồốổỗộơờớởỡợìíỉĩịäëïîöüûñçýỳỹỵỷ",
                to = "aaaaaaaaaaaaaaaaaeeeeeeeeeeeduuuuuuuuuuuoooooooooooooooooiiiiiaeiiouuncyyyyy";
            for (let i = 0, l = from.length; i < l; i++) {
                str = str.replace(RegExp(from[i], "gi"), to[i]);
            }

            str = str.toLowerCase()
                .trim()
                .replace(/[^a-z0-9-_]/g, '_')
                .replace(/-+/g, '_');
            return str;
        },
        removeVietnamese3(str: string): string {
            if (!str) {
                return ""
            }
            const from = "àáãảạăằắẳẵặâầấẩẫậèéẻẽẹêềếểễệđùúủũụưừứửữựòóỏõọôồốổỗộơờớởỡợìíỉĩịäëïîöüûñçýỳỹỵỷ",
                to = "aaaaaaaaaaaaaaaaaeeeeeeeeeeeduuuuuuuuuuuoooooooooooooooooiiiiiaeiiouuncyyyyy";
            for (let i = 0, l = from.length; i < l; i++) {
                str = str.replace(RegExp(from[i], "gi"), to[i]);
            }

            str = str.toLowerCase()
                .trim()
                .replace(/[^a-z0-9-]/g, '-')
                .replace(/-+/g, '');
            return str;
        },
        getWebUrl() {
            if (location.host.includes("alfreddesk")) {
                return "https://alfreddesk.com"
            }
            return "https://web.biglead.live"
        },
        getChatUrl() {
            if (location.host.includes("alfreddesk")) {
                return "https://chat.alfreddesk.com"
            }
            return "https://chat.biglead.live"
        },
        getLogo() {
            if (location.host.includes("alfreddesk")) {
                return "https://web.alfreddesk.com/medias/logo_white_mini.png"
            }
            return "https://biglead.live/images/logo.png"
        },
        getSubscribeUrl(callback_url = '') {
            return `https://oauth.zaloapp.com/v3/oa/permission?app_id=873839835098755128&redirect_uri=${callback_url}`;
        },
        formatMoney(str: string | number): string {
            if (str || str === '0') {
                try {
                    try {
                        // x = parseInt(x) + ''
                        str = str.toString().replace(/\./g, ',');
                        str = str.toString().replace(/^0+|-|\.| /g, '');
                        return str.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.');
                    } catch (e) {
                        console.error(e)
                        return '0';
                    }
                } catch (e) {
                    return '0';
                }
            } else {
                return '0'
            }
        },
        getStatusNameFromId(id: number): string {
            if (id === -1) {
                return 'Đã hủy'
            } else if (id === 0) {
                return 'Đơn mới'
            } else if (id === 1) {
                return 'Đã xác nhận'
            } else if (id === 2) {
                return 'Chờ lấy hàng'
            } else if (id === 3) {
                return 'Đã lấy hàng'
            } else if (id === 4) {
                return 'Giao thành công'
            } else if (id === 5) {
                return 'Hoàn thành công'
            } else if (id === 6) {
                return 'Đang hoàn hàng'
            } else if (id === 7) {
                return 'Đang giao hàng'
            } else if (id === 8) {
                return 'Đề nghị hoàn'
            }
            return ''
        },
        formatPhoneNumber(str: string): string {
            if (str && str.length >= 9) {
                str = str.replace(/.,+ ()-/g, '');
                if (str.substr(0, 2) === '84') {
                    str = '0' + str.substr(2, str.length);
                } else {
                    if (str.charAt(0) !== '0') {
                        str = '0' + str;
                    }
                }
                Constant.phoneFormats.map((item) => {
                    const strSplit = str.split(item.key);
                    if (strSplit[0] && strSplit[0].length) {
                        str = str.replace(item.key, item.value);
                    }
                });
            }

            return str;
        },
        convertConfigDataFromStr(str: string, page: string): ConfigData {
            let obj: ConfigData = {};
            try {
                obj = JSON.parse(str)
            } catch (e) {
                try {
                    str = str.replace(/\n/g, '\\n');
                    str = str.replace(/\t/g, '\\t');
                    str = str.replace(/\r/g, '\\r');
                    obj = JSON.parse(str);
                } catch (e) {
                    console.log('e', str);
                    console.log('page', page);
                }
            }
            return obj;
        },
        dateTimeDisplayFormatFromString(str: string): string {
            let format = moment(str).tz(moment.tz.guess()).format("DD-MM-YYYY HH:mm")
            if (format.includes('01-01-0001')) {
                format = ""
            }
            return format;
        },
        dateDisplayFormatFromString(str: string|Date): string {
            let format = moment(str).tz(moment.tz.guess()).format("DD/MM/YYYY")
            if (format.includes('01/01/0001')) {
                format = ""
            }
            return format;
        },
        dateTimeDisplayFormatFromShortString(str: string): string {
            let format = moment(str).tz(moment.tz.guess()).format("DD-MM-YY HH:mm")
            if (format.includes('01-01-0001')) {
                format = ""
            }
            return format;
        },
        dateTimeDisplayFormatFromUnix(unix: number): string {
            let format = moment.unix(unix).tz(moment.tz.guess()).format("DD-MM-YYYY HH:mm")
            if (format.includes('01-01-0001')) {
                format = ""
            }
            return format;
        },
        getPageTypeName(str: number): string {
            switch (str) {
                case 1:
                    return 'Facebook';
                case 2:
                    return 'Zalo';
                case 3:
                    return 'Instagram';
                case 4:
                    return 'Line';
                case 5:
                    return 'Gmail';
                default:
                    return ''
            }
        },
        getTriggerStatusColor(str: string): string {
            if (str === 'draft') {
                return "#EDDB3B"
            } else if (str === 'publish') {
                return "#2DB7F5"
            } else if (str === 'running') {
                return "#60C133"
            } else if (str === 'stop') {
                return "#FF5500"
            } else {
                return ""
            }
        },
        getUUID(): string {
            return uuidv4()
        },
        convertDescription(description: string) {
            const i18n = useI18n();

            switch (description) {
                case 'deleted':
                    return i18n.t('auto_inbox_status_deleted');
                case 'done':
                    return i18n.t('auto_inbox_status_done');
                case 'exceed_limit':
                    return 'Hoàn thành';
                case 'failed_multiple_times':
                    return 'Đã dừng, gửi tin nhắn thất bại nhiều lần';
                case 'have no customers':
                    return 'Không có khách hàng phù hợp';
                case 'nocontent':
                    return 'AutoInbox thiếu nội dung hoặc ViralChat';
                case 'overtime':
                    return 'Đã dừng, quá hạn';
                case 'Page can not send message right now':
                    return 'Đã dừng, gửi tin nhắn thất bại nhiều lần';
                case 'stop':
                    return 'Đã dừng';
                case 'token dead':
                    return 'Đã dừng, token không hợp lệ';
                case 'token_died':
                    return 'Đã dừng, token không hợp lệ';
                case '':
                    return '';
                default:
                    return 'Đã dừng';
            }
        },
        moment(dateString: string) {
            return moment(dateString)
        },
        convertOrderStatus(status: number) {
            const i18n = useI18n();

            switch (status) {
                case -1:
                    return i18n.t('STATUS_ORDER_CANCEL');
                case 0:
                    return i18n.t('STATUS_ORDER_NEW');
                case 1:
                    return i18n.t('STATUS_ORDER_CONFIRM');
                case 2:
                    return i18n.t('STATUS_ORDER_WAITING_SHIP');
                case 3:
                    return i18n.t('STATUS_ORDER_PICKED_SHIP');
                case 7:
                    return i18n.t('STATUS_ORDER_SHIPPING');
                case 8:
                    return i18n.t('STATUS_ORDER_RETURN_OFFER');
                case 6:
                    return i18n.t('STATUS_ORDER_RETURNING');
                case 5:
                    return i18n.t('STATUS_ORDER_RETURNED');
                case 4:
                    return i18n.t('ORDER_SUCCESS');
            }
        },
        getOrderStatusColor(str: number): string {
            switch (str) {
                case 0:
                    return "#cccccc"
                case 1:
                    return "#3c8dbc"
                case 2:
                    return "#f39c12"
                case 3:
                    return "#00c0ef"
                case 6:
                    return "#d77516"
                case 7:
                    return "#7fd3f2"
                case 5:
                    return "#f9a757"
                case 8:
                    return "#dd4b39"
                case 4:
                    return "#01a65a"
                case -1:
                    return "#031f3f"
                default:
                    return ""
            }
        },
        timeSince(date:Date|string){
            return moment(date).fromNow(true)
        }
    },
    getAutoInboxMessageTag() {
        return Constant.AUTO_INBOX_MESSAGE_TAG
    },
    getChatUrl() {
        if (location.host.includes("shopf1") || location.host.includes("biglead")) {
            return "https://chat.biglead.live/login";
        } else if (location.host.includes("alfreddesk")) {
            return "https://chat.alfreddesk.com/login/";
        } else if (location.host.includes("crmtest")) {
            return "https://chat.biglead.live/login";
        } else if (location.host.includes("localhost")) {
            return "https://chat.biglead.live/login";
        }
        return "https://chat.biglead.live/login";
    },
    getChatNewUrl() {
        if (location.host.includes("shopf1") || location.host.includes("biglead")) {
            return "https://chatnew.biglead.live/login";
        } else if (location.host.includes("alfreddesk")) {
            return "https://chat.alfreddesk.com/login/";
        } else if (location.host.includes("crmtest")) {
            return "https://chatnew.biglead.live/login";
        } else if (location.host.includes("localhost")) {
            return "https://chatnew.biglead.live/login";
        }
        return "https://chatnew.biglead.live/login";
    },

    getCRMUrl() {
        if (location.host.includes("shopf1") || location.host.includes("biglead")) {
            return "https://biglead.live/crm/overview";
        } else if (location.host.includes("alfreddesk")) {
            return "https://alfreddesk.com/crm/overview";
        } else if (location.host.includes("crmtest")) {
            return "https://crmtest.shopf1.net/crm/overview";
        } else if (location.host.includes("localhost")) {
            return "http://localhost:8080/overview";
        }
        return "https://biglead.live/crm/overview";
    },

    getPOSUrl() {
        return "https://posmain.anntech.one/login2";
    },



}

export class Utils{
    static  timeoutSelections = (t: (arg0: string) => string)=>{
        const timeoutSelections =          [ {
            label: '10 ' + t('second'),
            value: "10"  //second
        },
            {
                label: '20 ' + t('second'),
                value: "20"  //second
            }];
        return timeoutSelections
    }
}
