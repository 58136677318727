import {ActionTree, ActionContext} from 'vuex';

import {CallBackPayload, RootState} from '@/store';

import {State} from './states';
import {Mutations} from './mutations';
import {getters} from './getters';
import {UsersMutationTypes} from './mutation-types';
import {UserActionTypes} from './action-types';
import {LoginRequest} from "@/APIs/request/LoginRequest";
import APIs from "@/APIs/APIs";
import {
    LocalesResponse,
    NotificationsResponse, SchedulesResponse, UnreadNotificationResponse,
    UserInfoResponse
} from "@/APIs/response/S_HttpResponse";
import {FacebookPostData} from "@/models/facebook/FacebookPost";
import RouterEndpoint from "@/router/RouterEndpoint";
import router from '../../../router/index'
import {NotificationQuery, SchedulesReq} from "@/APIs/request/Notification";

type AugmentedActionContext = {
    commit<K extends keyof Mutations>(
        key: K,
        payload: Parameters<Mutations[K]>[1],
    ): ReturnType<Mutations[K]>;
} & Omit<ActionContext<State, RootState>, 'commit'>

export interface Actions {
    [UserActionTypes.FETCH_USER_INFO](
        {commit}: AugmentedActionContext,
    ): Promise<UserInfoResponse | undefined>;

    [UserActionTypes.LOGIN](
        {commit}: AugmentedActionContext,
        payload: LoginRequest, // Obsolete in here but left as an example
    ): Promise<UserInfoResponse | undefined>;

    [UserActionTypes.FETCH_POST_ALL_SHOP](
        {commit}: AugmentedActionContext,
        payload: any[], // Obsolete in here but left as an example
    ): Promise<FacebookPostData[]>;

    [UserActionTypes.FETCH_LOCALES](
        {commit}: AugmentedActionContext,
        payload: number,
    ): Promise<LocalesResponse | undefined>;

    [UserActionTypes.FETCH_NOTIFICATION](
        {commit}: AugmentedActionContext,
        payload: CallBackPayload<NotificationQuery, NotificationsResponse>
    ): Promise<NotificationsResponse | undefined>;

    [UserActionTypes.FETCH_COUNT_UNREAD_NOTIFICATION](
        {commit}: AugmentedActionContext,
    ): Promise<UnreadNotificationResponse | undefined>;

    [UserActionTypes.FETCH_SHOP_SCHEDULES](
        {commit}: AugmentedActionContext,
        payload: SchedulesReq
    ): Promise<SchedulesResponse | undefined>;
}

export const actions: ActionTree<State, RootState> & Actions = {

    async [UserActionTypes.FETCH_USER_INFO]({commit}): Promise<UserInfoResponse | undefined> {
        commit(UsersMutationTypes.SET_IsUserInfoFetching, true);
        const response = await APIs.init().getSuperUserInfo();
        if (response.data?.data) {
            commit(UsersMutationTypes.SET_UserInfo, response.data?.data);
        } else if (response.status === 401 || response.status === 403) {
            commit(UsersMutationTypes.SET_UserInfo, undefined);
            console.log('currentRoute', router.currentRoute.value.fullPath.split('?'))
            if(router.currentRoute.value.fullPath === "/login_facebook" ){
                await router.push({name: RouterEndpoint.LOGIN_FACEBOOK.name})
            }else if(router.currentRoute.value.fullPath.split('?')[0] === '/preview_order'){
                await router.push(router.currentRoute.value.fullPath)
            }else {
                await router.push({name: RouterEndpoint.ACCOUNTLOGIN.name})
            }

        }
        commit(UsersMutationTypes.SET_IsUserInfoFetching, false);
        APIs.init().getShops().then(response=>{
            if (response.data) {
                commit(UsersMutationTypes.SET_SHOPS, response.data.data?response.data.data:[]);
            }
        })
        return response.data
    },
    async [UserActionTypes.LOGIN]({commit}, request: LoginRequest): Promise<UserInfoResponse | undefined> {
        const loginRes = await APIs.init().login(request);
        if (loginRes.ok) {
            await commit(UsersMutationTypes.SET_UserInfo, {token: loginRes.data?.data, shop_id: 0, current_shop_id: 0});
            const response = await APIs.init().getSuperUserInfo();
            if (response.ok && response.data) {
                const user = response.data.data
                user.token = loginRes.data?.data;
                commit(UsersMutationTypes.SET_UserInfo, response.data?.data);
                // if(response.data?.data.shops && response.data?.data.shops.length>0){
                    // @ts-ignore
                // commit(UsersMutationTypes.SET_CurrentShop, response.data?.data.shops[0].id);
                // }
                // @ts-ignore
                commit(UsersMutationTypes.SET_IsLogin, true);
                await router.replace({name: RouterEndpoint.OVERVIEW.name})
            }
            return response.data
        }
        return undefined
    },
    async [UserActionTypes.FETCH_LOCALES]({commit}, payload: number): Promise<LocalesResponse | undefined> {
        console.log("payload", payload)
        const response = await APIs.init().getLocales();
        if (response.status === 200 || response.status === 201) {
            if (response.data && response.data.data) {
                await commit(UsersMutationTypes.SET_Locales, response.data.data);
            }
            return response.data;
        }
        return undefined
    },
    async [UserActionTypes.FETCH_POST_ALL_SHOP]({commit}, payload: any[]): Promise<FacebookPostData[]> {
        let data: FacebookPostData[] = []
        console.log(payload,'playload')
        for (let i = 0; i < payload.length; i++) {
            const response = await APIs.init().getPagePosts(payload[i],1);
            if (response.status === 200 || response.status === 201) {
                if (response.data && response.data.data) {
                    data = [...response.data.data, ...data]
                }
            }
        }
        commit(UsersMutationTypes.SET_SELECTED_POSTS, data)
        return data
    },
    async [UserActionTypes.FETCH_NOTIFICATION]({commit}, payload: CallBackPayload<NotificationQuery, NotificationsResponse>): Promise<NotificationsResponse | undefined> {
        if (payload.payload) {
            const response = await APIs.init().getNotificationByUid(payload.payload)
            if (response.status === 200 && response.data && response.data.data) {
                if (payload.payload.page>1){
                    commit(UsersMutationTypes.ADD_NOTIFICATIONS, response.data.data)

                }else {
                    commit(UsersMutationTypes.SET_NOTIFICATIONS, response.data.data)
                }
            }
            if (payload.callback) {
                payload.callback(response.data)
            }
            return response.data;
        }
        return undefined
    },
    async [UserActionTypes.FETCH_COUNT_UNREAD_NOTIFICATION]({commit}): Promise<UnreadNotificationResponse | undefined> {
        const response = await APIs.init().getUnreadNotificationByUid()
        if (response.status === 200 && response.data && response.data) {
            commit(UsersMutationTypes.SET_COUNT_UNREAD_NOTIFICATION, response.data.data)
        }
        return response.data;
    },
    async [UserActionTypes.FETCH_SHOP_SCHEDULES]({commit}, payload: SchedulesReq): Promise<SchedulesResponse | undefined> {
        const response = await APIs.init().getSchedules(payload)
        if (response.status === 200 && response.data) {
            if (response.data.meta_data) {
                commit(UsersMutationTypes.SET_SCHEDULE_METADATA, response.data.meta_data)
            }
            commit(UsersMutationTypes.SET_SCHEDULES, response.data.data ? response.data.data : [])

        }
        return response.data;
    },

};
