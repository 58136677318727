import Vue, { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import { store } from "@/store";
import Default from "@/layouts/Default.vue";
import Antd from "ant-design-vue";

import Blank from "@/layouts/Blank.vue";
import "@/assets/ant-customize.less";
import '@vue-flow/core/dist/style.css';
import '@vue-flow/core/dist/theme-default.css';
import '@vue-flow/controls/dist/style.css';
import '@vue-flow/minimap/dist/style.css';
import '@vue-flow/node-resizer/dist/style.css';
import "@/assets/flow.css";


import Icon from "@ant-design/icons-vue";
import i18n from "./i18n";
import {MixUtils, MSocketIo} from "@/utils/Utils";
import moment from "moment-timezone";
import PerfectScrollbar from "vue3-perfect-scrollbar"
import 'vue3-perfect-scrollbar/dist/vue3-perfect-scrollbar.css'
import vue3GoogleLogin from "vue3-google-login";
// @ts-ignore
// import './utils/pixel.js'
import VueCookies from 'vue-cookies'
// @ts-ignore
import VueBarcode from '@chenfengyuan/vue-barcode';
// @ts-ignore
import HighchartsVue from 'highcharts-vue'
// @ts-ignore
import CKEditorPlugin from '@ckeditor/ckeditor5-vue';

import Treemap from 'highcharts/modules/treemap'
import { EllipsisOutlined, DownOutlined } from "@ant-design/icons-vue";
import Highcharts from "highcharts";
const app = createApp(App).use(i18n).use(store).use(router).use(VueCookies).use(CKEditorPlugin);
// @ts-ignore
app.use(vue3GoogleLogin, { clientId: "428478910230-lnt2caspcaqkuc52tvc67f5r9k4trfoa.apps.googleusercontent.com",});
app.component(VueBarcode.name, VueBarcode);
// @ts-ignore
app.use(HighchartsVue,{
  highcharts: Treemap(Highcharts)
} )
app.mount("#app");
app.component("default-layout", Default);
app.use(Antd);
app.use(PerfectScrollbar)
app.component("a-icon", Icon);
app.mixin(MixUtils);

moment.tz.setDefault("Asia/Ho_Chi_Minh");
app.component("EllipsisOutlined", EllipsisOutlined);
app.component("DownOutlined", DownOutlined);
app.config.warnHandler = function (msg, vm, trace) {
  return null
}
const favicon= document.getElementById("favicon")
if (document.location.host.includes("alfreddesk")){
  document.title="Alfreddesk CRM"
  if (favicon){
    favicon.setAttribute("href","/favicon_alfreddesk.jpg")
  }
  // @ts-ignore
  import("@/assets/ant-customize_alfreddesk.less");
}else if (document.location.host.includes("wisell")){
  document.title="WISELL CRM"
  if (favicon){
    favicon.setAttribute("href","/favicon_wisell.png")
  }
  // @ts-ignore
  import("@/assets/ant-customize.less");
}else{
  document.title="Biglead CRM"
  if (favicon){
    favicon.setAttribute("href","/favicon.png")
  }
  // @ts-ignore
  import("@/assets/ant-customize.less");
}
new MSocketIo()

