<template>
  <div class="container-title">
    <h3 class="title-header">{{ $t($route.name) }}</h3>
    <div class="input-search-customer">

    </div>

<!--    <a-button @click="showFilter = !showFilter" size="small" type="primary">{{$t('filter')}}</a-button>-->
  </div>
  <div class="container">
<!--    <a-card v-if="showFilter" style="border-radius: 10px;margin: auto;margin-bottom: 20px;width: 95%">-->
<!--      <a-form layout="vertical">-->
<!--        <a-row style="margin-top: 10px"  justify="space-around">-->
<!--          <a-form-item-->
<!--              :label="$t('CAMPAIGN_NAME_ID')"-->


<!--          >-->
<!--            <a-input style="width: 186px" />-->
<!--          </a-form-item>-->
<!--          <a-form-item-->
<!--              :label="$t('PROMOTION_TYPE')"-->


<!--          >-->
<!--           <a-select style="width: 186px"/>-->
<!--          </a-form-item>-->
<!--          <a-form-item-->
<!--              :label="$t('Purpose')"-->


<!--          >-->
<!--            <a-select style="width: 186px" />-->
<!--          </a-form-item>-->
<!--          <a-form-item-->
<!--              :label="$t('CREATED')"-->


<!--          >-->
<!--            <a-date-picker style="width: 186px" v-model:value="value1" format="DD-MM-YYYY  " />-->
<!--          </a-form-item>-->
<!--          <a-form-item-->
<!--              :label="$t('status')"-->


<!--          >-->
<!--            <a-select style="width: 186px"/>-->
<!--          </a-form-item>-->
<!--        </a-row>-->
<!--      </a-form>-->
<!--    </a-card>-->
    <a-card style="border-radius: 10px;margin: auto;width: 95%" >
<template #title>
  <a-row justify="space-between">
    <a-button size="small" type="primary">
      <router-link to="/voucher/:id">
        {{$t('CREATE')}}
      </router-link>
    </a-button>
<!--    <a-input @change="changeVoucherCode" v-model:value="voucherCode" style="width: 186px" :placeholder="$t('FIND_VOUCHER')"/>-->
   <a-form layout="inline">
    <a-form-item :label="$t('PROMOTION_TYPE')"  >
      <a-select @change="changeVoucherCode" placeholder="select status" v-model:value="voucherCode" style="width: 186px">
        <a-select-option  v-for="i in promotionType" :key="i.value" :value="i.title !== 'All' ? i.value :'' ">
          <a-tag>
            {{$t(i.title)}}
          </a-tag>
        </a-select-option>
      </a-select>
    </a-form-item>
    <a-form-item label="status">
      <a-select @change="changeStatus" placeholder="select status" v-model:value="statusValue" style="width: 186px">
        <a-select-option  v-for="i in status" :key="i" :value="i === 'All' ? '' : i">
          <a-tag :color="i === 'All' ? '' :getStatus(i).color">
            {{i}}
          </a-tag>
        </a-select-option>
      </a-select>
    </a-form-item>
   </a-form>
  </a-row>


</template>
      <a-table :loading="loading" @change="handleTableChange" :pagination="pagination" style="width: 100%;padding: 0"  :columns="columns" :data-source="getVouchers.voucher !== null? getVouchers.voucher:[]"   >
        <template #voucher_type="{record}">
          {{$t(record.voucher_type)}}
        </template>
        <template #purpose="{record}">
          {{record.purpose === 'MANY' ? $t('ONE_CODE_USE_MANY_TIME'):  $t('A_SINGLE_USE_VOUCHER')}}
        </template>
        <template #time_visit="{record}">
          {{getTime(record)}}
        </template>
        <template #status="{record}">
          <a-tag :color="getStatus(record.status).color">
            {{getStatus(record.status).name}}
          </a-tag>

        </template>
        <template #used_voucher="{record}">
          {{record.used_voucher}}/{{record.number_of_times_to_apply_the_program
          }}
        </template>
        <template #voucher_code="{record}">
          <a-tag >
            <a-typography-text strong> {{record.voucher_code}}</a-typography-text>
          </a-tag>

        </template>
        <template #action="{record}">
          <a-dropdown>
            <a-button type="text">
              <eye-outlined />
            </a-button>
            <template #overlay>
              <a-menu>
                <a-menu-item>
                  <router-link :to="`/voucher/${record.id}`">
                    <edit-outlined/>
                    {{$t('EDIT')}}
                  </router-link>

                </a-menu-item>
                <a-menu-item>
                  <a-popconfirm
                      :title="$t('DELETE_VOUCHER')"
                      :ok-text="$t('YES')"
                      :cancel-text="$t('NO')"
                      @confirm="deleteFlow(record.id)"
                  >
                    <delete-outlined/>
                    {{$t('DELETE')}}
                  </a-popconfirm>

                </a-menu-item>
                <a-menu-item
                    v-if="record.status==='active'"
                    @click="stopVoucherModal(record.id,record.voucher_name)" >
                  <stop-outlined/>
                  {{ $t('STOP_VOUCHER') }}
                </a-menu-item>
                <a-menu-item
                    v-if="record.status ==='stop'"
                    @click="activateVoucherModal(record.id,record.voucher_name)" >
                  <aim-outlined/>
                  {{ $t('Activate_voucher') }}
                </a-menu-item>
<!--                <a-menu-item>-->
<!--                  <RouterLink to="/evoucher-code-list">-->
<!--                    <file-text-outlined />-->
<!--                    {{$t('VOUCHER_CODE_LIST')}}-->
<!--                  </RouterLink>-->
<!--                </a-menu-item>-->
<!--                <a-menu-item-->
<!--                    @click="duplicated(record)">-->
<!--                  <bar-chart-outlined />-->
<!--                  {{$t('RESULT')}}-->
<!--                </a-menu-item>-->
<!--                <a-menu-item-->
<!--                    @click="duplicated(record)">-->
<!--                  <plus-outlined />-->
<!--                  {{$t('CREATE_TRIGGER')}}-->
<!--                </a-menu-item>-->
              </a-menu>
            </template>
          </a-dropdown>
        </template>
      </a-table>
    </a-card>
  </div>
</template>
<script lang="ts">
import {computed, onMounted, reactive, ref} from "vue";
import {useI18n} from "vue-i18n";
import {EyeOutlined,StopOutlined,AimOutlined,EditOutlined,DeleteOutlined,FileTextOutlined,PlusOutlined,BarChartOutlined} from "@ant-design/icons-vue";
import {useStore} from "vuex";
import {VoucherActionTypes} from "@/store/modules/voucher/action-types";
import dayjs from "dayjs";
import APIs from "@/APIs/APIs";
import {message, Modal} from "ant-design-vue";


export default {
  components:{
    EyeOutlined,
    StopOutlined,
    AimOutlined,
    EditOutlined,
    DeleteOutlined,
    // FileTextOutlined,
    // PlusOutlined,
    // BarChartOutlined
  },
  setup(){
    const i18t = useI18n()
    const store = useStore()
    const loading =  ref(false)
    const columns = [
      {
        title: i18t.t('CAMPAIGN_NAME'),
        key: 'voucher_name',
        dataIndex: 'voucher_name',
        slots: {customRender: 'voucher_name'},
      },
    {
      title: i18t.t('VOUCHER_CODE'),
      key: 'voucher_code',
      dataIndex: 'voucher_code',
      slots: {customRender: 'voucher_code'},
    },

    {
      title: i18t.t('PROMOTION_TYPE'),
      key: 'voucher_type',
      dataIndex: 'voucher_type',
      slots: {customRender: 'voucher_type'},
    },
      {
        title: i18t.t('Purpose'),
        key: 'purpose',
        dataIndex: 'purpose',
        slots: {customRender: 'purpose'},
      },
      {
        title: i18t.t('time_visit'),
        key: 'time_visit',
        slots: {customRender: 'time_visit'},
      },
      {
        title: i18t.t('USED_VOUCHER'),
        dataIndex: 'used_voucher',
        slots: {customRender: 'used_voucher'},
      },
      // {
      //   title: i18t.t('Revenue'),
      //   key: 'revenue',
      //   dataIndex: 'revenue',
      //   slots: {customRender: 'revenue'},
      // },
      {
        title: i18t.t('status'),
        key: 'status',
        dataIndex: 'status',
        slots: {customRender: 'status'},
      },
      {
        title: i18t.t('action'),
        dataIndex: 'action',
        slots: {customRender: 'action'},
      },

  ]
    const statusValue = ref('')
    const voucherCode =ref('')
    const status = ref([
      'All',
      'coming',
      'activity',
      'expired',
      'stop',
      'draft'
    ])
    const metaData = ref(0)
    const fetchVouchers = () => {
      loading.value = false
      store.dispatch(VoucherActionTypes.FETCH_VOUCHER,{page:filterForm.page,page_size:filterForm.page_size,search:voucherCode.value,status:statusValue.value})

    }
    const getVouchers = computed(()=> store.getters.getVoucherData)

    const getTime =(data:any)=>{
      return `${i18t.t('filter_from')}: ${dayjs(data.date_start).format('DD/MM/YYYY')} ${i18t.t('to')}: ${dayjs(data.date_end).format('DD/MM/YYYY')}`
    }
    const getStatus =(status:string) => {
      if(status === 'coming'){
        return{name: status,color:'#faad14'}
      }else if(status === 'active'){
        return{name: status,color:'#52c41a'}
      }else if(status === 'expired'){
        return{name: status,color:'#6c757d'}
      }else if(status === 'stop'){
        return{name: status,color:'#ff4d4f'}
      }else if(status === 'draft'){
        return{name: status,color: 'rgb(237, 219, 59)'}
      }else if(status === 'over'){
        return{name: status,color: '#9aa0a6'}
      }else {
        return {name:'',color: ''}
      }
    }

const changeStatus = () => {
  loading.value = true
  fetchVouchers()
}
const getUseVoucher = async (data:any) => {
  const res = await APIs.init().getVoucherConfig({id: data.id})
  const config = ref({})
  // @ts-ignore
  if(res.data.status === 200){

    console.log(res.data?.data)
    // @ts-ignore
    config.value = res.data?.data
    // @ts-ignore
    console.log(config.value.number_of_times_to_apply_the_program)
  }
  // @ts-ignore
  return await config.value.number_of_times_to_apply_the_program
}
const changeVoucherCode = () => {
      loading.value = true
  fetchVouchers()
}
    const deleteFlow = async (id:string) => {
      loading.value = true
      // @ts-ignore
      const res = await APIs.init().deleteVoucher({id: id})
      if(res.data?.status === 200) {
        message.success(i18t.t('alert_delete_data_success'))
        fetchVouchers()
      }
    }

    const stopVoucherModal =  (id:string,name:string) => {
      const onOk = () => {
        loading.value = true
        APIs.init().stopVoucher({id:id,voucher_name: name}).then((res:any) => {
          if (res.status === 200) {
           message.success(i18t.t('STOP_VOUCHER') + i18t.t('SUCCESS'))
            fetchVouchers()
          }
        }).catch((e:any) => {
          console.error(e)
        })
        console.log('OK');
      }
      Modal.confirm({
        title: i18t.t('VOUCHER_TITLE_CONFIRM_STOP'),
        closable: true,
        maskClosable: true,
        onOk,
        onCancel() {
          console.log('Cancel');
        },
      });
    }
    const activateVoucherModal = (id:string,name:string)=>{
      const onOk = () => {
        loading.value = true
        APIs.init().activeVoucher({id:id,voucher_name:name}).then((res:any) => {
          if (res.status === 200) {
            message.success(i18t.t('STOP_VOUCHER') + i18t.t('SUCCESS'))
            fetchVouchers()
          }
        }).catch((e:any) => {
          console.error(e)
        })
        console.log('OK');
      }
      Modal.confirm({
        title: i18t.t('VOUCHER_TITLE_CONFIRM_ACTIVITY'),
        closable: true,
        maskClosable: true,
        onOk,
        onCancel() {
          console.log('Cancel');
        },
      });
    }
    const promotionType = ref([
      {
        title: 'All',
      },
      {
        value:'DISCOUNT_FOR_TOTAL_BILL',
        title: 'DISCOUNT_FOR_TOTAL_BILL',
      },
      {
        value:'DISCOUNT_FOR_PRODUCT',
        title: 'DISCOUNT_FOR_PRODUCT',
      },
      {
        value:'BUY_A_DISCOUNT_B',
        title:  'BUY_A_DISCOUNT_B',
      },
      {
        value:'BUY_X_GIVE_AWAY_Y',
        title:  'BUY_X_GIVE_AWAY_Y',
      },
      {
        value:'SAME_PRICE',
        title:  'SAME_PRICE',
      },
      {
        value:'OTHER_OFFERS',
        title: 'OTHER_OFFERS',
      },
    ])
    const pagination = computed(() => {
      return {
        current: filterForm.page ? filterForm.page : 1,
        pageSize: filterForm.page_size ? filterForm.page_size : 20,
        defaultPageSize: 20,
        total: metaData.value ? metaData.value : 0,
        pageSizeOptions: ['20', '40', '80', '120'],
        showSizeChanger: true
      }
    })
    const filterForm = reactive({
     page: 1, page_size: 20
    })
    const handleTableChange = (pagination: any) => {
      loading.value = true
      if (pagination?.current) {
        filterForm.page = pagination?.current
      }
      if (pagination?.pageSize) {
        filterForm.page_size = pagination?.pageSize
      }
      fetchVouchers()

    }
onMounted(()=>{
  fetchVouchers()
  metaData.value = getVouchers.value.metadata.record_total
})
const showFilter = ref(false)
    return{
      pagination,
      showFilter,
      columns,
      getVouchers,
      getTime,
      getStatus,
      deleteFlow,
      stopVoucherModal,
      activateVoucherModal,
      handleTableChange,
      status,
      statusValue,
      changeStatus,
      voucherCode,
      changeVoucherCode,
      promotionType,loading,getUseVoucher
    }
  }
}
</script>
