<template>
  <a-card>
    <template #title>
      <span>
        <router-link
            :to="getMyRole(modules.customers)?.is_edit?('/customer/'+order.customer_id):''">
        {{ order.full_name }}
        </router-link>
        <router-link
            v-if="getNextOrderActions(order.status, order.shipping_method).includes('edit_order') && order.shipping_code==='' && getMyRole(modules.orders)?.is_edit"
            :to="'/order/'+order.id+'/edit'">
           <a-button type="dashed" class="button-print-order">
          {{ $t('EDIT') }}
          <edit-outlined/>
        </a-button>
        </router-link>

         <a :href="order.shipping_code? (location.host.includes('localhost')?('/order_preview/'+order.order_number):('/crm/order_preview/'+order.order_number)):`/crm/order_thermal?order_number=${order.order_number}&token=${order.order_token}&print=true`" target="_blank">
          <a-button class="button-edit-order">
            {{ $t('PRINT') }}
            <printer-filled/>
          </a-button>
         </a>
         <a-popconfirm
             v-if="getMyRole(modules.providers)?.is_edit"
             :title="$t('CONFIRM_ORDER')"
             :ok-text="$t('OK')" :cancel-text="$t('CANCEL')" @confirm="confirmOrder()">
                  <a-button class="button-edit-order"
                            v-if="getNextOrderActions(order.status, order.shipping_method).includes('confirm_order')"
                  >
                    <CheckOutlined/>
                    {{ $t("ORDER_CONFIRMED") }}
                  </a-button>
                </a-popconfirm>
                <a-popconfirm
                    :title="$t('CONFIRM_ORDER')"
                    :ok-text="$t('OK')" :cancel-text="$t('CANCEL')" @confirm="successOrder()">
                <a-button class="button-edit-order"
                          v-if="getNextOrderActions(order.status, order.shipping_method).includes('order_success')"
                >
                  <SafetyCertificateOutlined/>
                  {{ $t("ORDER_SUCCESS") }}
                </a-button>
                </a-popconfirm>
                <a-popconfirm
                    :title="$t('CONFIRM_ORDER')"
                    :ok-text="$t('OK')" :cancel-text="$t('CANCEL')" @confirm="shippingOrder()">
                <a-button class="button-edit-order"
                          v-if="getNextOrderActions(order.status, order.shipping_method).includes('order_shipping')"
                >
                  <SafetyCertificateOutlined/>
                  {{ $t("ORDER_SHIPPING") }}
                </a-button>
                </a-popconfirm>
                <a-popconfirm
                    :title="$t('CONFIRM_ORDER')"
                    :ok-text="$t('OK')" :cancel-text="$t('CANCEL')" @confirm="returningOrder()">
                <a-button class="button-edit-order"
                          v-if="getNextOrderActions(order.status, order.shipping_method).includes('order_returning')"
                >
                  <SafetyCertificateOutlined/>
                  {{ $t("ORDER_RETURNING") }}
                </a-button>
                </a-popconfirm>
                <a-popconfirm
                    v-if="order.shipping_code===''"
                    :title="$t('SEND_TO_SHIPPING')"
                    :ok-text="$t('OK')" :cancel-text="$t('CANCEL')" @confirm="sendOrder()">
                <a-button class="button-edit-order"
                          v-if="getNextOrderActions(order.status, order.shipping_method).includes('send_order')"
                >
                  <RocketOutlined/>
                  {{ $t("ORDER_SEND") }}
                </a-button>
                </a-popconfirm>
                <a-popconfirm
                    :title="$t('CONFIRM_ORDER')"
                    :ok-text="$t('OK')" :cancel-text="$t('CANCEL')" @confirm="cancelOrder()">
                <a-button type="danger" class="button-edit-order"
                          v-if="getNextOrderActions(order.status, order.shipping_method).includes('cancel_order')"
                >
                  {{ $t("ORDER_CANCELED") }}
                  <StopOutlined/>
                </a-button>
                </a-popconfirm>
<!--         <a-popover v-if="order.status !== 4 && order.status !== -1" v-model:visible="visible2"  trigger="click">-->
<!--            <template #content>-->
<!--              <a-typography-text strong>{{order.full_name}} : </a-typography-text>-->
<!--              <a-typography-text type="success">{{formatPoint(customerPoint.customer_column_value)}} điểm</a-typography-text>-->
<!--              <a-input-number-->
<!--                  style="margin-top: 20px;width: 300px;display: block" v-model:value="usePoint.point" placeholder="nhập số điểm muốn sử dụng"-->
<!--                  :min="0"-->
<!--                  :max="Number(customerPoint.customer_column_value)"-->
<!--                  :formatter="value => ` ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')"-->
<!--                  :parser="value => value.replace(/\$\s?|(,*)/g, '')"-->
<!--                  string-mode-->
<!--              />-->
<!--              &lt;!&ndash;              <a-input  style="margin-top: 20px" v-model:value="usePoint.point" placeholder="nhập số điểm muốn sử dụng" />&ndash;&gt;-->
<!--              <a-button type="primary" @click="customerUsePoint"  style="margin-top: 10px"  >-->
<!--                 {{$t('USE_POINT')}}-->

<!--              </a-button>-->
<!--              <a-button  @click="customerUsePointAll"  style="margin-top: 10px;margin-left: 10px"  >-->
<!--                {{$t('ALL')}}-->

<!--              </a-button>-->
<!--            </template>-->
<!--            <a-button  style="margin-left: 20px"  >-->
<!--              <a-typography-text @click="getPoint" > {{$t('USE_POINT')}}</a-typography-text>-->

<!--            </a-button>-->
<!--          </a-popover>-->
      </span>
    </template>
    <template #extra>
      <span class="header-order-container-extra">
              <img src="@/assets/icons/ghtk_logo.png" class="anticon"/>
        <span class="shipping-unit-title">
          {{ order.shipping_method }}
        </span>
        <span class="order-date">{{ $t('ORDER_DATE') }}: {{ moment(order.created_at).format('DD/MM/YYYY') }}</span>
      </span>
    </template>
    <ul class="card-container-order">
      <li class="card-container-order-left">
        <div class="contain-info">
           <span class="title-order-common-info">
              <shop-outlined/>
           {{ $t('ORDER_FROM') }}
          </span>
          <span>
            {{ $t('STOCK') }}:
            <span>
              <a-button type="link" class="button-branch">
               {{ order.shop_name }}
              </a-button>
            </span>
          </span>
          <span>
             {{ $t('ADDRESS') }}:
            <span class="content-info-value">
              {{ order.stock_address }}
            </span>
          </span>
          <span>
             {{ $t('phone_number') }}:
            <span class="content-info-value">
             {{ order.shop_tel }}
            </span>
          </span>
          <span>
            Email:
            <span class="content-info-value">
            {{ order.shop_email }}
            </span>
          </span>
        </div>
      </li>
      <li class="card-container-order-mid">
        <div class="contain-info">
          <span class="title-order-common-info">
            <environment-outlined/>
           {{ $t('ORDER_TO') }}
          </span>
          <span>
             <router-link :to="'/customer/'+order.customer_id">
       <span class="button-branch">
          {{ order.shipping_name }}
       </span>
        </router-link>
          </span>
          <span>
             {{ $t('ADDRESS') }}:
            <span class="content-info-value">
             {{ [order.shipping_address, order.shipping_ward_name,  order.shipping_district_name,order.shipping_province_name ].join(', ') }}
            </span>
          </span>
          <span>
             {{ $t('phone_number') }}:
            <span class="content-info-value">
             {{ order.shipping_phone }}
            </span>
          </span>
          <span>
            Email:
            <span class="content-info-value">
            {{ order.email }}
            </span>
          </span>
        </div>
      </li>
      <li class="card-container-order-right">
        <div class="contain-info">
          <span class="title-order-common-info">
            <info-circle-outlined/>
           {{ $t('MAIN_INFO') }}
          </span>
          <span>
            {{ $t('ORDER_ID') }}:
            <span class="content-info-value content-info-value-special">
              {{ order.order_number }}
            </span>
          </span>
          <span>
            {{ $t('SHIPPING_METHOD') }}:
            <span class="content-info-value content-info-value-special">
              {{ order.shipping_method }}
            </span>
          </span>
          <span>
            {{ $t('ORDER_SHIPPING_UNIT_ID') }}:
            <span class="content-info-value content-info-value-special">
              {{ order.shipping_code }}
            </span>
          </span>
          <span>
            {{ $t('CREATOR') }}:
             <router-link :to="'/customer/'+order.created_by">
       <span class="button-branch">
            {{ findEmployee(order.created_by).name }}
       </span>
        </router-link>
          </span>
        </div>
      </li>
    </ul>

    <a-table :columns="productColumns" :data-source="order.products" class="table-product" :pagination="false">
      <template #product_name="{ record }">
        <a>{{
            record.product_name
          }} - {{ record.group_name }}</a>
      </template>
      <template #avatar_url="{record}">
                <span>
                   <a-image
                       width="50px"
                       max-height="100px"
                       max-width="100px"
                       :src="record.avatar_url"
                   />
                </span>
      </template>
      <template #unit_price="{record}">
        {{ formatMoney(record.unit_price) }}
      </template>
      <template #quantity="{record}">
        {{ formatMoney(record.quantity) }}
      </template>
      <template #total="{record}">
        <span class="total">
          {{ formatMoney(record.quantity * record.unit_price) }}
        </span>
      </template>
    </a-table>
    <a-row>
      <a-col :span="3">
        <p>
          {{ $t('ORDER_NOTE') }}:
        </p>
        <p class="shopf1-shipping-note-label">
          {{ $t('SHIP_NOTE') }}:
        </p>
      </a-col>
      <a-col :span="10" class="shopf1-note-contain">
        <p class="shopf1-note-value">
          {{ order.note }}
        </p>
        <p class="shopf1-note-value">
          {{ order.note_bill }}
        </p>
      </a-col>
      <a-col :span="11">
        <div class="flex">
          {{ $t('TOTAL_AMOUNT') }}
          <span class="text-right">{{ formatMoney(order.total) }}</span>
        </div>

        <div class="flex">
          {{ $t('SHIPPING_COST') }}
          <span class="text-right">{{ formatMoney(order.ship_fee) }}</span>
        </div>
        <div class="flex">
          {{ $t('SHIPPING_COST_COLLECTED') }}
          <span class="text-right">{{ formatMoney(order.ship_fee_collected) }}</span>
        </div>
        <div class="flex">
          {{ $t('DISCOUNT') }}
          <span class="text-right">{{ formatMoney(order.discount) }}</span>
        </div>
        <div class="flex">
          {{ $t('PAID') }}
          <span class="text-right">{{ formatMoney(order.prepaid) }}</span>
        </div>
        <div class="flex">
          {{ $t('COD') }}
          <span class="text-right">{{ formatMoney(order.cod_amount) }}</span>
        </div>
      </a-col>

    </a-row>
  </a-card>
  <h3 class="title-updated-status">
    {{ $t('UPDATED_STATUS') }}
  </h3>
  <a-table :dataSource="order.order_histories" :columns="statusColumns">
    <template #change_time="{record}">
      {{  dateTimeDisplayFormatFromShortString(record.change_time) }}
    </template>
    <template #change_info="{record}">
      {{ $t(record.change_info.toUpperCase()) }}
    </template>
  </a-table>
</template>

<script lang="ts">
import {computed, defineComponent, onMounted, ref} from "vue";
import {
  EnvironmentOutlined, InfoCircleOutlined,
  PrinterFilled, ShopOutlined, EditOutlined, RocketOutlined, StopOutlined
} from "@ant-design/icons-vue";
import APIs from "@/APIs/APIs";
import {Orders} from "@/models/Orders/Orders";
import moment from "moment";
import {Employee} from "@/models/Users";
import {store} from "@/store";
import {useStore} from "vuex";
import {MixUtils} from "@/utils/Utils";
import {message, notification} from "ant-design-vue";
import {useI18n} from "vue-i18n";
import {Constant} from "@/utils/Constants";
import {CustomersActionTypes} from "@/store/modules/customer/action-types";
import {Customer} from "@/models/Customers/Customers";

export default defineComponent({
  name: "Order",
  components: {
    PrinterFilled,
    ShopOutlined,
    EnvironmentOutlined,
    InfoCircleOutlined,
    // EditOutlined,
    RocketOutlined, StopOutlined
  },
  created() {
    this.$watch(
        () => this.$route.params,
        () => {
          if (this.$route.params.id) {
            console.log('params', this.$route.params.id)
            this.fetchOrder(this.$route.params.id)

          }
        },
        // fetch the data when the view is created and the data is
        // already being observed
        {immediate: true}
    )
  },
  setup() {
    const i18n = useI18n()
    const store = useStore()
    const order = ref<Orders>({})

    const getListEmployee = computed((): Employee[] => store.getters.getListEmployee)

    const findEmployee = (staff_id: number) => {
      let employee = {}
      const employeeFound = getListEmployee.value.find(staff => staff.id + '' === staff_id + '')
      return employeeFound ? employeeFound : employee
    }

    const fetchOrder = async (order_number: any) => {
      const response = await APIs.init().getOrderDetail({order_number: order_number})
      if (response.status === 200 && response.data?.status === 200) {
        if (response.data.data) {
          order.value = response.data.data
        }
      }
    }

    const formatMoney = (money: string) => {
      return MixUtils.methods.formatMoney(money)
    }

    const getNextOrderActions = (status: number, shipping_method: string) => {
      switch (status) {
        case 0:
          return ['edit_order', 'confirm_order', 'cancel_order']; // Đơn nháp
        case 1:
          return shipping_method == 'default' ? ['edit_order', 'order_success', 'cancel_order'] : ['edit_order', 'send_order', 'cancel_order']; // Đã xác nhận
        case 2:
          return ['cancel_order']; // Chờ lấy hàng
        case 3: // Đã lấy hàng
        case 4:  // Giao thành công
          return [];
        case 8:
          return ['order_shipping', 'order_returning']; // Đề nghị hoàn
        default:
          return []
      }
    }

    const changeStatus = async (status: number) => {
      message.loading('')
      const response = await APIs.init().changeOrderStatus({
        id: order.value.id,
        status: status
      })
      message.destroy()
      if (response.status === 200 && response.data?.status === 200) {
        fetchOrder(order.value.order_number)
      }
    }

    const successOrder = () => {

      changeStatus(4)
    }

    const sendOrder = async () => {
      message.loading('')
      const response = await APIs.init().shippingCreateOrder(order.value)
      console.log(response)
      message.destroy()
      if (response.status === 200 && response.data?.status === 200) {
        notification.success({
          message: i18n.t('NOTIFICATION'),
          description: response.data?.message,
        });
        fetchOrder(order.value.order_number)
      }else{
        notification.error({
          message: i18n.t('NOTIFICATION'),
          description: response.data?.message,
        });
      }
    }

    const cancelOrder = () => {

      changeStatus(-1)
    }
    const shippingOrder = async () => {
      // message.loading('')
      alert("shippingOrder")
      const response = await APIs.init().shippingCreateOrder(order.value)
      console.log(response)
      // message.destroy()
      // if (response.status === 200 && response.data?.status === 200) {
      //   changeStatus(7)
      // }

    }
    const returningOrder = () => {
      changeStatus(6)
    }

    const confirmOrder = () => {

      changeStatus(1)
    }

    const productColumns = computed(() => [
      {
        title: i18n.t('Code'),
        dataIndex: 'product_code',
        key: 'product_code',
        // slots: {customRender: 'product_code'},
      },
      {
        title: i18n.t('NAME'),
        dataIndex: 'product_name',
        key: 'product_name',
        slots: {customRender: 'product_name'},
      },
      {
        title: i18n.t('IMAGE'),
        dataIndex: 'avatar_url',
        key: 'avatar_url',
        slots: {customRender: 'avatar_url'},
      },
      {
        title: i18n.t('sale_price'),
        key: 'unit_price',
        dataIndex: 'unit_price',
        slots: {customRender: 'unit_price'},
      },
      {
        title: i18n.t('Quantity'),
        key: 'quantity_order',
        dataIndex: 'quantity_order',
        slots: {customRender: 'quantity'},
      },
      {
        title: i18n.t('Weight'),
        key: 'weight',
        dataIndex: 'weight',
      },
      {
        title: i18n.t('TOTAL'),
        key: 'total',
        dataIndex: 'total',
        slots: {customRender: 'total'},
      },
    ]);

    const statusColumns = [
      {
        title: i18n.t('NAME'),
        dataIndex: 'change_by',
        key: 'change_by',
      },
      {
        title: i18n.t('INFORMATION'),
        dataIndex: 'change_info',
        key: 'change_info',
        slots: {customRender: 'change_info'},
      },
      {
        title: i18n.t('DATE'),
        dataIndex: 'change_time',
        key: 'change_time',
        slots: {customRender: 'change_time'},
      },
      {
        title: i18n.t('STATUS'),
        dataIndex: 'status',
        key: 'status',
        slots: {customRender: 'status'},
      },
    ]

    const location= window.location;

    const getUserInfo = computed(() => store.getters.getUserInfo)

    const getMyRole = computed(() => {
      if(getUserInfo.value?.id_cms_privileges==null ||  getUserInfo.value?.id_cms_privileges<11){
        return ()=>({
          is_visible: 1,
          is_create:1,
          is_read: 1,
          is_edit: 1,
          is_delete: 1,
          id: 1
        })
      }else{
        return store.getters.getRole
      }
    });
    const modules = Constant.MODULES;
   // const visible2 = ref(false)
   //  // lấy điểm tích lũy của khách hàng
   //  const usePoint = ref({})
   //  const getUsePoint = async () => {
   //    const res = await  APIs.init().getUsePoint( {id:order.value.order_number})
   //    if(res.data?.status === 200){
   //      usePoint.value = res.data.data
   //    }
   //  }
   //  const customerPoint = ref({})
   //  const getCustomerConfig =  () => {
   //    const request = {shop_id:currentCustomer.value ? currentCustomer.value.shop_id : undefined}
   //    store.dispatch(CustomersActionTypes.FETCH_CUSTOMER_CONFIG,request)
   //
   //  }
   //  const currentCustomer = computed((): Customer | undefined => store.getters.getCurrentCustomer)
   //  const CustomerConfig = computed(() => store.getters.getCustomerConfig.filter((item:any)=> item.identified_name === 'points' ))
   //  const getPoint = async () => {
   //    const res = await APIs.init().getCustomerConfigValue({id: order.value.customer_id})
   //    res.data?.data.map((item:any)=> {
   //      if(CustomerConfig.value[0].id === item.customer_column_id){
   //        customerPoint.value = item
   //      }
   //    })
   //    console.log(CustomerConfig.value)
   //  }
   //
   //  const formatPoint = (value:any) => {
   //    // @ts-ignore
   //    if(usePoint.value.point){
   //      // @ts-ignore
   //      return  MixUtils.methods.formatMoney(value - usePoint.value.point)
   //    }else {
   //      return  MixUtils.methods.formatMoney(value)
   //    }
   //    //
   //  }
   //
   //  const customerUsePoint = async  () => {
   //    // @ts-ignore
   //    usePoint.value.customer_id = order.value.customer_id
   //    // @ts-ignore
   //
   //    // @ts-ignore
   //    order.value.discount = order.value.discount ? String(Number(order.value.discount) +  Number(usePoint.value.point)) : usePoint.value.point
   //    console.log(order.value.discount, usePoint.value ,'usePoint.value')
   //    // @ts-ignore
   //    usePoint.value.order_number = order.value.order_number
   //    // @ts-ignore
   //    usePoint.value.point = Number(usePoint.value.point)
   //    await APIs.init().createUsePoint( usePoint.value)
   //    await APIs.init().updateOrder({
   //      id: order.value.id,
   //      discount: Number(order.value.discount),
   //
   //    })
   //    visible2.value = false
   //  }
   //
   //  const customerUsePointAll = async () => {
   //    // @ts-ignore
   //    usePoint.value.point = customerPoint.value.customer_column_value
   //    // @ts-ignore
   //    order.value.discount = order.value.discount ? String(Number(order.value.discount) + Number(usePoint.value.point)) : usePoint.value.point
   //    // @ts-ignore
   //    usePoint.value.order_number = order.value.order_number
   //    // @ts-ignore
   //    usePoint.value.customer_id = order.value.customer_id
   //    // @ts-ignore
   //    usePoint.value.point = Number(usePoint.value.point)
   //    const res = await APIs.init().createUsePoint( usePoint.value)
   //    if(res.data?.status === 200) {
   //
   //      await APIs.init().updateOrder({
   //        id: order.value.id,
   //        customer_id: order.value.customer_id,
   //        stock_id: order.value.stock_id,
   //        discount: Number(order.value.discount),
   //        prepaid: Number(order.value.prepaid),
   //        created_by: order.value.created_by,
   //        quantity: order.value.quantity,
   //        weight: order.value.weight,
   //        total: order.value.total,
   //        shipping_name: order.value.shipping_name,
   //        shipping_phone:order.value.shipping_phone,
   //        shipping_address: order.value.shipping_address,
   //        shipping_district_id: order.value.shipping_district_id,
   //        shipping_ward_id: order.value.shipping_ward_id,
   //        shipping_province_id: order.value.shipping_province_id,
   //        shipping_district:order.value.shipping_district,
   //        stock_address: order.value.stock_address,
   //        products: order.value.products,
   //        cod_amount: order.value.cod_amount,
   //        shipping_method: order.value.shipping_method,
   //        ship_fee: order.value.ship_fee,
   //        ship_revenue: order.value.ship_revenue,
   //        note: order.value.note,
   //        note_bill: order.value.note_bill,
   //      })
   //      message.success('success')
   //    }
   //
   //    visible2.value = false
   //  }
   //  onMounted(() => {
   //    getUsePoint()
   //  })
    return {
      getMyRole,
      modules,
      productColumns,
      statusColumns,
      fetchOrder,
      order,
      moment,
      formatMoney,
      findEmployee,
      getNextOrderActions,
      successOrder,
      confirmOrder,
      sendOrder,
      cancelOrder,
      shippingOrder,
      returningOrder,
      location,
      // getPoint,
      // customerPoint,
      // usePoint,
      // customerUsePoint,
      // formatPoint,
      // customerUsePointAll,
      // visible2,
    }
  }
})
</script>

<style scoped lang="scss">
.button-print-order {
  margin-left: 18px;
}

.button-edit-order {
  margin-left: 12px;
}

.header-order-container-extra {
  display: flex;
  justify-content: center;

  img {
    width: 24px;
    height: 24px;
  }

  .shipping-unit-title {
    margin-left: 8px;
    font-weight: bold;
  }

  .order-date {
    margin-left: 40px;
  }
}


@media only screen and (min-width: 768px) {
  /* For desktop: */
  .card-container-order {
    margin-left: -22px;
    margin-right: -22px;
    list-style-type: none;

    li {
      display: inline-block;
      width: 33%;
    }
  }
  .card-container-order-left {
    padding-right: 10px;
  }
  .card-container-order-right {
    padding-left: 10px;
  }
  .card-container-order-mid {
    padding-left: 10px;
    border-left: #dddddd solid 1px;
    border-right: #dddddd solid 1px;
  }
}

@media only screen and (max-width: 500px) {
  .card-container-order {
    list-style-type: none;
    margin-left: -20px;
    margin-right: -20px;

    li {
      margin-top: 10px;
    }
  }
}

.contain-info {
  display: flex;
  flex-direction: column;

  .button-branch {
    font-weight: bold;
  }

  .content-info-value {
    margin-left: 10px;
  }

  .content-info-value-special {
    font-weight: bold;
  }

  .title-order-common-info {
    font-weight: bold;
  }
}

.table-product {
  margin-left: -14px;
  margin-right: -14px;
  margin-bottom: 10px;

  .total {
    color: #d90303;
    font-weight: bold;
  }
}

.shopf1-note-contain {
  padding-right: 25px;

  .shopf1-note-value {
    background-color: #eeeeee;
    padding: 10px 20px;
    border-radius: 8px;
    width: 100%;
  }
}


.shopf1-shipping-note-label {
  padding-top: 10px;
}

.order-price-value {
  text-align: right;
  color: #d90303;
  font-weight: bold;
  padding-right: 100px;
}

.title-updated-status {
  margin-top: 20px;
  margin-bottom: 15px;
}
.flex{
  display: flex;
  align-items: center;
}
.text-right{
  flex: 1;
  text-align: right;
  padding: 6px 0 6px;
  font-weight: bold
}
</style>
