import create from "zustand-vue";
import { persist } from "zustand/middleware";
import APIs from "@/APIs/APIs";

import { PageConfig } from "@/models/PageConfig/PageConfig";

interface PageConfigStorageState {
  pageConfig: PageConfig[] | undefined;
  pageId: number | undefined;
  pageIdCopy: string | undefined;
  getPageConfig: (params: any) => void;
  getCurrentPageId: (params: any) => void;
  tabkey:string| undefined
}

const initialState = {
  pageConfig: [],
  pageId: 0,
    pageIdCopy:"",
    tabkey:''
};

const usePageConfigStorageStore = create<PageConfigStorageState>(
  // @ts-ignore
  persist(
    (set) => ({
      ...initialState,
      getPageConfig: async (params) => {
        const response = await APIs.init().getShopPages(params);
        console.log(response.data?.data,'pages')
        // @ts-ignore
          if (response.status === 200) {
          set(() => ({
            pageConfig: response.data?.data,
          }));
        }
      },
      getCurrentPageId: async (params) => {
        const response = await APIs.init().getShopPages(params);
        if (response.status === 200) {
          set(() => ({
            // @ts-ignore
            pageId: pageConfig.find((item: any) => item.id === params),
          }));
        }
      },
    }),
    {
      name: "page-config",
    }
  )
);

export default usePageConfigStorageStore;
