import {ActionContext, ActionTree} from "vuex";
import {AutoInboxData, state, State} from "./states";
import {RootState} from "@/store";
import {AutoInboxActionTypes} from "@/store/modules/autoInbox/action-types";
import APIs from "@/APIs/APIs";
import {Mutations} from "@/store/modules/autoInbox/mutations";
import {AutoInboxesResponse} from "@/APIs/response/S_HttpResponse";
import {AutoInboxesRequest} from "@/APIs/request/AutoInboxRequest";
import {AutoInboxMutationTypes} from "@/store/modules/autoInbox/mutation-types";

type AugmentedActionContext = {
    commit<K extends keyof Mutations>(
        key: K,
        payload: Parameters<Mutations[K]>[1],
    ): ReturnType<Mutations[K]>;
} & Omit<ActionContext<State, RootState>, 'commit'>

export interface Actions {
    [AutoInboxActionTypes.FETCH_AUTO_INBOX](
        {commit}: AugmentedActionContext,
        payload: AutoInboxesRequest
    ): Promise<AutoInboxesResponse | undefined>,
    [AutoInboxActionTypes.FETCH_AUTO_INBOX_HISTORY](
        {commit}: AugmentedActionContext,
        payload: AutoInboxesRequest
    ): Promise<AutoInboxesResponse | undefined>,
}

export const actions: ActionTree<State, RootState> & Actions = {
    async [AutoInboxActionTypes.FETCH_AUTO_INBOX]({commit}, payload: AutoInboxesRequest): Promise<AutoInboxesResponse | undefined> {
        commit(AutoInboxMutationTypes.SET_AUTO_INBOX, {
            autoInboxes: state.autoInboxData?.autoInboxes,
            metadata: state.autoInboxData?.metadata,
            loading: true
        })
        const response = await APIs.init().getAutoInboxes(payload)
        commit(AutoInboxMutationTypes.SET_AUTO_INBOX, {
            autoInboxes: state.autoInboxData?.autoInboxes,
            metadata: state.autoInboxData?.metadata,
            loading: true
        })
        if (response.status === 200) {
            const autoInboxData: AutoInboxData = {
                autoInboxes: response.data?.data,
                metadata: response.data?.meta_data,
                loading: false
            }
            commit(AutoInboxMutationTypes.SET_AUTO_INBOX, autoInboxData)
        }

        return response.data;
    },
    async [AutoInboxActionTypes.FETCH_AUTO_INBOX_HISTORY]({commit}, payload: AutoInboxesRequest): Promise<AutoInboxesResponse | undefined> {
        commit(AutoInboxMutationTypes.SET_AUTO_INBOX_HISTORY, {
            autoInboxes: state.autoInboxHistoryData?.autoInboxes,
            metadata: state.autoInboxHistoryData?.metadata,
            loading: true
        })
        const response = await APIs.init().getAutoInboxHistory(payload)
        commit(AutoInboxMutationTypes.SET_AUTO_INBOX_HISTORY, {
            autoInboxes: state.autoInboxHistoryData?.autoInboxes,
            metadata: state.autoInboxHistoryData?.metadata,
            loading: false
        })
        if (response.status === 200) {
            const autoInboxData: AutoInboxData = {
                autoInboxes: response.data?.data,
                metadata: response.data?.meta_data,
                loading: false
            }
            commit(AutoInboxMutationTypes.SET_AUTO_INBOX_HISTORY, autoInboxData)
        }

        return response.data;
    },
}
