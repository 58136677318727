<script setup>
import { VueFlow, isNode, useVueFlow,MarkerType} from '@vue-flow/core'
import { Background, Controls, MiniMap, Panel, PanelPosition } from '@vue-flow/additional-components'
import { ref } from 'vue'
const initialElements = [
  { id: '1', type: 'custom', label: 'Node 1', data: { title: "test" }, position: { x: 250, y: 0 }, class: 'light' },
  { id: '2', type: 'custom', label: 'Node 2', data: { title: "test" }, position: { x: 250, y: 0 }, class: 'light' },
  {
    id: 'e1a-2',
    source: '1',
    sourceHandle: 'a',
    target: '2',
    updatable:true
  },
]

/**
 * useVueFlow provides all event handlers and store properties
 * You can pass the composable an object that has the same properties as the VueFlow component props
 */
const { onPaneReady, onNodeDragStop, onConnect, addEdges, setTransform, toObject,updateEdge } = useVueFlow()

/**
 * Our elements
 */
const elements = ref(initialElements)

/**
 * This is a Vue Flow event-hook which can be listened to from anywhere you call the composable, instead of only on the main component
 *
 * onPaneReady is called when viewpane & nodes have visible dimensions
 */
onPaneReady(({ fitView }) => {
  fitView()
})

onNodeDragStop((e) => console.log('drag stop', e))

/**
 * onConnect is called when a new connection is created.
 * You can add additional properties to your new edge (like a type or label) or block the creation altogether
 */
onConnect((params) => addEdges([params]))

const dark = ref(false)

/**
 * To update node properties you can simply use your elements v-model and mutate the elements directly
 * Changes should always be reflected on the graph reactively, without the need to overwrite the elements
 */
const updatePos = () =>
    elements.value.forEach((el) => {
      if (isNode(el)) {
        el.position = {
          x: Math.random() * 400,
          y: Math.random() * 400,
        }
      }
    })

/**
 * toObject transforms your current graph data to an easily persist-able object
 */
const logToObject = () => console.log(toObject())

/**
 * Resets the current viewpane transformation (zoom & pan)
 */
const resetTransform = () => setTransform({ x: 0, y: 0, zoom: 1 })

const toggleClass = () => (dark.value = !dark.value)
</script>

<template>
  <VueFlow v-model="elements" :class="{ dark }" style="min-height: 100vh" class="basicflow" :default-zoom="1.5" :min-zoom="0.2" :max-zoom="4">
    <Background :pattern-color="dark ? '#FFFFFB' : '#aaa'" gap="8" />
    <template #node-custom="{ label }">
     <div style="width: 100px; height: 100px; background-color: red">{{ label }}</div>
    </template>

  </VueFlow>
</template>

<style lang="scss" scoped>

</style>
