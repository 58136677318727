<template>

  <div class="container-title">
    <h3 class="title-header">{{ $t($route.name) }}</h3>
    <!--    <a-row>-->
    <!--      <a-typography-text style="font-size: 18px">{{$t('so sánh với')}}:</a-typography-text>-->

    <!--      <a-select-->
    <!--          v-model:value="selectedPage"-->
    <!--          @change="handlePageChange"-->
    <!--          :allowClear="true"-->
    <!--          :dropdownMatchSelectWidth="true"-->
    <!--          style="min-width: 200px; margin-right: 16px; margin-left: 10px"-->
    <!--          :placeholder="$t('ALL')"-->
    <!--      >-->
    <!--        <a-select-option-->
    <!--            v-for="item in date"-->
    <!--            :key="item"-->
    <!--            v-bind:value="item"-->
    <!--        >{{ item }}-->
    <!--        </a-select-option>-->
    <!--      </a-select>-->
    <!--    </a-row>-->
    <a-select
        v-model:value="selectedStock"
        @change="handlePageChange"
        :allowClear="true"
        :dropdownMatchSelectWidth="true"
        style="min-width: 200px; margin-right: 16px"
        :placeholder="$t('ALL')"
    >
      <a-select-option
          v-for="item in getStocks.stocks"
          :key="item.id"
          v-bind:value="item.id"
      >{{ item.shop_name }}
      </a-select-option>
    </a-select>
    <a-range-picker
        :allowClear="false"
        v-model:value="rangeTime"
        format="DD/MM/YYYY"
        :placeholder="[$t('START_TIME_PLACEHOLDER'), $t('END_TIME_PLACEHOLDER')]"
        @change="onChangeDateTime"
        @ok="confirmDateTime"
    >
      <template #suffixIcon>
        <CalendarOutlined/>
      </template>
    </a-range-picker>

  </div>
  <div style="padding: 16px">

    <a-row>
      <a-col  :span="16">
        <a-row :span="24">
          <a-col :span="12">
            <a-card class="card_view" style="border-radius: 10px; margin: 4px;">
              <CheckCircleOutlined style="font-size: 18px;color: #52c41a;margin-right: 10px" />
              <a-typography-text style="font-size: 18px" strong>Tổng hàng chốt</a-typography-text>
              <a-row style="margin-top: 10px">
                <a-col :span="12">
                  <a-typography-text style="font-size: 16px">{{ $t('TOTAL_AMOUNT') }}</a-typography-text>
                  <a-typography-text style="display: block;font-size: 16px" type="success">
                    {{ formatMoney(currentData.sale.total+currentData.return.total) }}
                  </a-typography-text>
                </a-col>
                <a-col :span="12">
                  <a-typography-text style="font-size: 16px">{{ $t('Quantity') }}</a-typography-text>
                  <a-typography-text style="display: block;font-size: 16px" strong>
                    {{ formatMoney(currentData.sale.count+currentData.return.count) }}
                  </a-typography-text>
                </a-col>
              </a-row>
            </a-card>
          </a-col>
          <a-col :span="12">
            <a-card class="card_view" style="border-radius: 10px; margin: 4px">
              <SwapOutlined style="font-size: 18px;color: #faad14;margin-right: 10px" />

              <a-typography-text style="font-size: 18px" strong>Tổng hàng hoàn</a-typography-text>
              <a-row style="margin-top: 10px">
                <a-col :span="12">
                  <a-typography-text style="font-size: 16px">{{ $t('TOTAL_AMOUNT') }}</a-typography-text>
                  <a-typography-text style="display: block;font-size: 16px" type="warning">
                    {{ formatMoney(currentData.return.total) }}
                  </a-typography-text>
                </a-col>
                <a-col :span="12">
                  <a-typography-text style="font-size: 16px">{{ $t('Quantity') }}</a-typography-text>
                  <a-typography-text style="display: block;font-size: 16px" strong>
                    {{ formatMoney(currentData.return.count) }}
                  </a-typography-text>
                </a-col>
              </a-row>
            </a-card>
          </a-col>
        </a-row>
        <a-col>
          <a-card class="card_view" style="border-radius: 10px; margin: 4px;">
            <a-row>
              <a-col :span="8">
                <div style="border-radius: 10px; margin: 4px;background-color:#f3f3f3">
                  <div style="padding: 10px">
                    <DollarCircleOutlined style="font-size: 18px;margin-right: 10px"  />

                    <a-typography-text style="font-size: 18px" strong>{{$t('TOTAL')}}</a-typography-text>
                    <a-row style="margin-top: 10px;justify-content: space-between">
                      <a-typography-text>{{ $t('REVENUE') }}:</a-typography-text>
                      <a-typography-text style="display: block;" strong>{{ formatMoney(currentData.sale.total) }}</a-typography-text>
                    </a-row>
                    <a-row style="justify-content: space-between">
                      <a-typography-text>{{ $t('successful_order') }}:</a-typography-text>
                      <a-typography-text style="display: block;" strong>{{formatMoney(currentData.sale.count)}}</a-typography-text>
                    </a-row>
                  </div>
                </div>
              </a-col>
              <a-col :span="8">
                <div style="border-radius: 10px; margin: 4px;background-color:#f3f3f3">
                  <div style="padding: 10px">
                    <GlobalOutlined style="font-size: 18px;margin-right: 10px"  />
                    <a-typography-text style="font-size: 18px" strong>Online</a-typography-text>
                    <a-row style="margin-top: 10px;justify-content: space-between">
                      <a-typography-text>{{ $t('REVENUE') }}:</a-typography-text>
                      <a-typography-text style="display: block" strong>{{ formatMoney(currentData.sale.total-currentData.counter.total) }}</a-typography-text>
                    </a-row>
                    <a-row style="justify-content: space-between">
                      <a-typography-text>{{ $t('successful_order') }}:</a-typography-text>
                      <a-typography-text style="display: block" strong>{{ formatMoney(currentData.sale.count-currentData.counter.count) }}</a-typography-text>
                    </a-row>
                  </div>
                </div>
              </a-col>
              <a-col :span="8">
                <div style="border-radius: 10px; margin: 4px;background-color:#f3f3f3">
                  <div style="padding: 10px">
                    <ShopOutlined style="font-size: 18px;margin-right: 10px" />
                    <a-typography-text style="font-size: 18px" strong>Tại quầy</a-typography-text>
                    <a-row style="margin-top: 10px;justify-content: space-between">
                      <a-typography-text>{{ $t('REVENUE') }}:</a-typography-text>
                      <a-typography-text style="display: block" strong>{{ formatMoney(currentData.counter.total) }}</a-typography-text>
                    </a-row>
                    <a-row style="justify-content: space-between">
                      <a-typography-text style="">{{ $t('successful_order') }}:</a-typography-text>
                      <a-typography-text style="display: block" strong>{{ formatMoney(currentData.counter.count) }}</a-typography-text>
                    </a-row>
                  </div>
                </div>
              </a-col>

            </a-row>
            <a-tabs v-model:activeKey="activeKey" type="card" style="margin-top: 20px">
              <a-tab-pane key="1" :tab="$t('overview')">
                <a-row justify="start">
                  <a-col :span="4">
                    <a-row
                        style="margin-top: 10px;border-right-width: 1px;border-right-style: solid;align-items: center;flex-direction: column;padding: 10px">

                      <a-typography-text style="display: block;">{{ $t('REVENUE') }}</a-typography-text>
                      <a-typography-text style="display: block;" strong>{{ formatMoney(currentData.sale.total) }}</a-typography-text>

                    </a-row>
                  </a-col>

                  <a-col :span="4">
                    <a-row
                        style="margin-top: 10px;border-right-width: 1px;border-right-style: solid;align-items: center;flex-direction: column;padding: 10px">

                      <a-typography-text style="display: block;">{{ $t('successful_order') }}</a-typography-text>
                      <a-typography-text style="display: block;" strong>{{ formatMoney(currentData.complete.total) }}</a-typography-text>

                    </a-row>
                  </a-col>
                  <a-col :span="4">
                    <a-row style="margin-top: 10px;border-right-width: 1px;border-right-style: solid;justify-content: space-between;padding: 10px">
                      <div></div>
                      <a-row
                          style="align-items: center;flex-direction: column">

                        <a-typography-text style="display: block;">{{ $t('GTTB') }}</a-typography-text>
                        <a-typography-text style="display: block;" strong>{{ formatMoney(Math.round(currentData.sale.total/currentData.sale.count)) }}</a-typography-text>

                      </a-row>
                      <a-tooltip  title="Giá trị trung bình của đơn hàng (Doanh thu không trừ hoàn / SL chốt)" trigger="click" color="orange"
                               >
                      <QuestionCircleOutlined />
                      </a-tooltip>

                    </a-row>

                  </a-col>
                  <a-col :span="6">
                    <a-row style="margin-top: 10px;border-right-width: 1px;border-right-style: solid;justify-content: space-between;padding: 10px">
                      <div></div>
                    <a-row
                        style="align-items: center;flex-direction: column">

                      <a-typography-text style="display: block;">{{ $t('THE_NUMBER_OF_PRODUCT') }}</a-typography-text>
                      <a-typography-text style="display: block;" strong>{{ formatMoney(currentData.sale.product_count) }}</a-typography-text>

                    </a-row>
                      <a-tooltip  title="Số lượng sản phẩm trung bình của đơn hàng (SL sản phẩm / SL đơn chốt)" trigger="click" color="orange"
                      >
                        <QuestionCircleOutlined />
                      </a-tooltip>

                    </a-row>
                  </a-col>
                  <a-col :span="4">
                    <a-row style="margin-top: 10px;border-right-width: 1px;border-right-style: solid;justify-content: space-between;padding: 10px">
                      <div></div>
                    <a-row
                        style="align-items: center;flex-direction: column">

                      <a-typography-text style="display: block;">{{ $t('SL.SPTB') }}</a-typography-text>
                      <a-typography-text style="display: block;" strong>{{ formatMoney(Math.round(currentData.sale.product_count/currentData.sale.count*100)/100) }}</a-typography-text>

                    </a-row>
                      <a-tooltip  title="Lợi nhuận trung bình (Lợi nhuận / (SL đơn chốt - SL đơn hoàn))" trigger="click" color="orange"
                      >
                        <QuestionCircleOutlined />
                      </a-tooltip>

                    </a-row>
                  </a-col>
                </a-row>
              </a-tab-pane>


            </a-tabs>
            <keep-alive>
              <highcharts
                  class="chart" :options="{
      chart: {
        type: 'column',
      },
      title: {
        text: $t(selectedType === 'day' ? 'Revenue_over_time' : 'Revenue_by_employee')
      },
      xAxis: {
        categories: chartDataReportCareAll.label
      },
      yAxis: {
        min: 0,
        title: {
          text: null
        },
      },
      legend: {
                enabled:false,
        align: 'right',
        verticalAlign: 'top',
        borderColor: '#CCC',
        borderWidth: 1,
        shadow: false
      },
      tooltip: {
        headerFormat: '<b>{point.x}</b><br/>',
        enable: false
      },
      plotOptions: {
        column: {
          colors: chartDataReportCareAll.color,
          stacking: 'normal',
          dataLabels: {
            enabled: true
          }
        }
      },
      series: chartDataReportCareAll.total ? [
          {
            data: chartDataReportCareAll.total&&chartDataReportCareAll.total?.map((total, index)=>({
        y: chartDataReportCareAll.total[index],
        color: chartDataReportCareAll.color[index],
      }))
          }
      ]:[]
    }"></highcharts>

            </keep-alive>
          </a-card>

        </a-col>

      </a-col>
      <a-col :span="8">
        <a-card class="card_view" style="border-radius: 10px; margin: 4px">
          <a-typography-text style="font-size: 18px" strong>Thông tin kinh doanh hôm nay</a-typography-text>
          <a-row>
            <a-col :span="12">
              <div style="border-radius: 10px; margin: 4px;background-color:#f3f3f3">
                <div style="padding: 10px">

                  <a-typography-text>{{ $t('REVENUE') }}</a-typography-text>
                  <a-typography-text style="display: block;" strong>{{ formatMoney(todayData.sale.total) }}</a-typography-text>


                </div>
              </div>
            </a-col>
            <a-col :span="12">
              <div style="border-radius: 10px; margin: 4px;background-color:#f3f3f3">
                <div style="padding: 10px">

                  <a-typography-text>{{ $t('successful_order') }}</a-typography-text>
                  <a-typography-text style="display: block;" strong>{{ formatMoney(todayData.complete.count) }}</a-typography-text>


                </div>
              </div>


            </a-col>
          </a-row>
          <a-row>
            <keep-alive>
              <highcharts
                  class="chart" :options="{
      chart: {
        type: 'column',
      },
      title: {
        text: $t(selectedType === 'day' ? 'Revenue_over_time' : 'Revenue_by_employee')
      },
      xAxis: {
        categories: chartDataReportCareAll2.label
      },
      yAxis: {
        min: 0,
        title: {
          text: null
        },
      },
      legend: {
                enabled:false,
        align: 'right',
        verticalAlign: 'top',
        borderColor: '#CCC',
        borderWidth: 1,
        shadow: false
      },
      tooltip: {
        headerFormat: '<b>{point.x}</b><br/>',
        enable: false
      },
      plotOptions: {
        column: {
          colors: chartDataReportCareAll2.color,
          stacking: 'normal',
          dataLabels: {
            enabled: true
          }
        }
      },
      series: chartDataReportCareAll2.total ? [
          {
            data: chartDataReportCareAll2.total&&chartDataReportCareAll2.total?.map((total, index)=>({
        y: chartDataReportCareAll2.total[index],
        color: chartDataReportCareAll2.color[index],
      }))
          }
      ]:[]
    }"></highcharts>

            </keep-alive>
          </a-row>
          <a-row>
            <a-col :span="12">
              <div style="border-radius: 10px; margin: 4px;background-color:#f3f3f3">
                <div style="padding: 10px">

                  <a-typography-text>{{ $t('REVENUE') }}</a-typography-text>
                  <a-typography-text style="display: block;" strong>{{ formatMoney(todayData.sale.total) }}</a-typography-text>


                </div>
              </div>


            </a-col>
            <a-col :span="12">
              <div style="border-radius: 10px; margin: 4px;background-color:#f3f3f3">
                <div style="padding: 10px">

                  <a-typography-text>{{ $t('successful_order') }}</a-typography-text>
                  <a-typography-text style="display: block;" strong>{{ formatMoney(todayData.complete.count) }}</a-typography-text>


                </div>
              </div>

            </a-col>
          </a-row>
          <a-row>
            <a-col :span="8">
              <div style="border-radius: 10px; margin: 4px;background-color:#f3f3f3">
                <div style="padding: 10px">

                  <a-typography-text>{{ $t('NEW_ORDERS') }}</a-typography-text>
                  <a-typography-text style="display: block;" strong>{{ formatMoney(todayData.sale?.count) }}</a-typography-text>


                </div>
              </div>

            </a-col>
            <a-col :span="8">
              <div style="border-radius: 10px; margin: 4px;background-color:#f3f3f3">
                <div style="padding: 10px">

                  <a-typography-text type="success">{{ $t('successful_order') }}</a-typography-text>
                  <a-typography-text type="success" style="display: block;" strong>{{ formatMoney(todayData.complete?.count) }}</a-typography-text>


                </div>
              </div>

            </a-col>
            <a-col :span="8">
              <div style="border-radius: 10px; margin: 4px;background-color:#f3f3f3">
                <div style="padding: 10px;color: red">

                  <a-typography-text type="danger">{{ $t('STATUS_ORDER_CANCEL') }}</a-typography-text>
                  <a-typography-text  type="danger" style="display: block;" strong>{{ formatMoney(todayData.cancel?.count) }}</a-typography-text>


                </div>
              </div>

            </a-col>
          </a-row>
        </a-card>
      </a-col>
    </a-row>
    <a-row>
      <a-col  :span="12">
        <a-card class="card_view" style="border-radius: 10px; margin: 4px">
          <a-typography-text style="font-size: 18px" strong>{{$t('product')}}</a-typography-text>
          <a-table
              :columns="columnsProduct"
              :data-source="fixedData"
              :pagination="false"
              :scroll="{ x: 2000, y: 500 }"
              bordered
          >

          </a-table>
        </a-card>

      </a-col>
      <a-col :span="12">
        <a-card class="card_view" style="border-radius: 10px; margin: 4px">
          <a-typography-text style="font-size: 18px" strong>{{$t('EMPLOYEE')}}</a-typography-text>
          <a-table
              :columns="columnsEmployee"
              :data-source="fixedData"
              :pagination="false"
              :scroll="{ x: 2000, y: 500 }"
              bordered
          >

          </a-table>
        </a-card>
      </a-col>
    </a-row>

    <!--    <a-row>-->
    <!--      <a-card class="card_view" style="border-radius: 10px; margin: 4px; width: 58%">-->
    <!--        <a-col>-->
    <!--          <keep-alive>-->
    <!--            <highcharts class="chart" :options="pieChart"></highcharts>-->
    <!--          </keep-alive>-->
    <!--        </a-col>-->

    <!--      </a-card>-->
    <!--      <a-card class="card_view" style="border-radius: 10px; margin: 4px;width: 40%">-->
    <!--        <a-col>-->
    <!--        </a-col>-->

    <!--      </a-card>-->

    <!--    </a-row>-->
  </div>


</template>

<script>

import {useStore} from "vuex";
import {ShopinfoActionTypes} from "@/store/modules/Shopinfo/action-types";
import {computed, onMounted, ref} from "vue";
import {useI18n} from "vue-i18n";
import APIs from "@/APIs/APIs";
import {MixUtils} from "@/utils/Utils";
import {CalendarOutlined,QuestionCircleOutlined,CheckCircleOutlined,SwapOutlined,DollarCircleOutlined,GlobalOutlined,ShopOutlined} from "@ant-design/icons-vue";
import dayjs from "dayjs";
import {ReportPAGEActionTypes} from "../../../store/modules/ReportPage/action-types";


export default {
  components: {
    CalendarOutlined,
    QuestionCircleOutlined,
    CheckCircleOutlined,
    SwapOutlined,
    DollarCircleOutlined,
    GlobalOutlined,
    ShopOutlined

  },
  setup() {

    const i18n = useI18n()
    const store = useStore();
    const rangeTime = ref([dayjs().add(-1, 'months').startOf('day'), dayjs().endOf('day')])
    const getStocks = computed(() => store.getters.getStocks)
    const date = ref(['trước đó 7 ngày'])
    const fetchShopInfo = () => {
      store.dispatch(ShopinfoActionTypes.FETCH_SHOPINFO);
    };
    const fetchCusomer = () => {
      store.dispatch(ReportPAGEActionTypes.FETCH_CUSTOMER_REPORT, {
        shop_id: dataShopInfo.value?.id,
        page_id: selectedPage.value,
        from_date: rangeTime.value[0].format('YYYY-MM-DD HH:mm'),
        to_date: rangeTime.value[1].format('YYYY-MM-DD HH:mm'),
      });
    };
    const chartDataReportCareAll = ref({
      total: [],
      label: [],
      color: [],
    })
    const chartDataReportCareAll2 = ref({
      total: [],
      label: [],
      color: [],
    })
    const fetchChart = async () => {
      // chartDataReportCareAll.value = {
      //   total: [],
      //   label: [],
      //   color: [],
      // }
      // labelsCareEmployee.value = []
      const getReportRevenue = await APIs.init().reportSale({
        from_date: rangeTime.value[0].format('YYYY-MM-DDT00:00:00Z'),
        to_date: rangeTime.value[1].format('YYYY-MM-DDT23:59:59Z'),
        type:'day'
      });

      if(getReportRevenue.data.status === 200 ){
        console.log(getReportRevenue.data)
        chartDataReportCareAll.value = {
          total: getReportRevenue.data.data?.map(t => t.total_amount),
          label: getReportRevenue.data.data?.map(t => MixUtils.methods.dateDisplayFormatFromString(t.order_day)),
          color: getReportRevenue.data.data?.map(t => t.color),
        }
      }
      const getReportRevenue2 = await APIs.init().reportSale({
        from_date: rangeTime.value[1].format('YYYY-MM-DDT00:00:00Z'),
        to_date: rangeTime.value[1].format('YYYY-MM-DDT23:59:59Z'),
        type:'hour'
      });

      if(getReportRevenue2.data.status === 200 ){
        console.log(getReportRevenue2.data)
        chartDataReportCareAll2.value = {
          total: getReportRevenue2.data.data?.map(t => t.total_amount),
          label: getReportRevenue2.data.data?.map(t => MixUtils.methods.dateDisplayFormatFromString(t.order_day)),
          color: getReportRevenue2.data.data?.map(t => t.color),
        }
      }


    }

    const columnsProduct = [
      {
        title: i18n.t('product'),
        dataIndex: 'customer_name',
        key: 'customer_name',
        width: 150,
        fixed: 'left',
      },
      {
        title: i18n.t('REVENUE'),
        dataIndex: 'status',
        key: 'status',
        slots: {customRender: "status"},
        width: 100,
      },
      {
        title: i18n.t('SL Hoàn hàng'),
        dataIndex: 'assigee',
        key: 'assigee',
        slots: {customRender: "assigee"},
        width: 100,
      },
      {
        title: i18n.t('SL Hàng chốt'),
        dataIndex: 'birth',
        key: 'birth',
        slots: {customRender: "birth"},
        width: 100,
      },
      {
        title: i18n.t('Lợi nhuận'),
        dataIndex: 'order_from_new_customer_percent',
        key: 'order_from_new_customer_percent',
        slots: {customRender: "order_not_ads_percent"},
        width: 100,
      },
    ];
    const columnsEmployee = [
      {
        title: i18n.t('EMPLOYEE'),
        dataIndex: 'customer_name',
        key: 'customer_name',
        width: 150,
        fixed: 'left',
      },
      {
        title: i18n.t('REVENUE'),
        dataIndex: 'status',
        key: 'status',
        slots: {customRender: "status"},
        width: 100,
      },
      {
        title: i18n.t('sales'),
        dataIndex: 'assigee',
        key: 'assigee',
        slots: {customRender: "assigee"},
        width: 100,
      },
      {
        title: i18n.t('Chiết khấu'),
        dataIndex: 'birth',
        key: 'birth',
        slots: {customRender: "birth"},
        width: 100,
      },
      {
        title: i18n.t('Đơn chốt'),
        dataIndex: 'order_from_new_customer_percent',
        key: 'order_from_new_customer_percent',
        slots: {customRender: "order_not_ads_percent"},
        width: 100,
      },
      {
        title: i18n.t('GTTB'),
        dataIndex: 'order_from_new_customer_percent',
        key: 'order_from_new_customer_percent',
        slots: {customRender: "order_not_ads_percent"},
        width: 100,
      },

      {
        title: i18n.t('THE_NUMBER_OF_PRODUCT'),
        dataIndex: 'order_from_new_customer_percent',
        key: 'order_from_new_customer_percent',
        slots: {customRender: "order_not_ads_percent"},
        width: 100,
      },
      {
        title: i18n.t('Gía vốn'),
        dataIndex: 'order_from_new_customer_percent',
        key: 'order_from_new_customer_percent',
        slots: {customRender: "order_not_ads_percent"},
        width: 100,
      },
      {
        title: i18n.t('REVENUE'),
        dataIndex: 'order_from_new_customer_percent',
        key: 'order_from_new_customer_percent',
        slots: {customRender: "order_not_ads_percent"},
        width: 100,
      },
      {
        title: i18n.t('Số đơn'),
        dataIndex: 'order_from_new_customer_percent',
        key: 'order_from_new_customer_percent',
        slots: {customRender: "order_not_ads_percent"},
        width: 100,
      },
      {
        title: i18n.t('Hàng bán ra'),
        dataIndex: 'order_from_new_customer_percent',
        key: 'order_from_new_customer_percent',
        slots: {customRender: "order_not_ads_percent"},
        width: 100,
      },
      {
        title: i18n.t('Doanh số'),
        dataIndex: 'order_from_new_customer_percent',
        key: 'order_from_new_customer_percent',
        slots: {customRender: "order_not_ads_percent"},
        width: 100,
      },
    ];
    const dataShopInfo = computed(() => store.getters.getShopinfo);
    const pagesSelection = computed(() => store.getters.getPagesSelection);
    const getPages = computed(() => store.getters.getPages);
    const customerData = computed(() => store.getters.getReportCusTomer)
    const selectedPage = ref();
    const selectedStock = ref();

    const splineChart = ref()

    const currentData = ref({
      "complete": {
        "count": 0,
        "total": 0,
      },
      "counter": {
        "count": 0,
        "total": 0
      },
      "return": {
        "count": 0,
        "total": 0
      },
      "sale": {
        "count": 0,
        "total": 0,
        "product_count": 0,
      },
      "cancel": {
        "count": 0,
        "total": 0
      },
    })
    const todayData = ref({
      "complete": {
        "count": 0,
        "total": 0
      },
      "counter": {
        "count": 0,
        "total": 0
      },
      "return": {
        "count": 0,
        "total": 0
      },
      "cancel": {
        "count": 0,
        "total": 0
      },
      "sale": {
        "count": 0,
        "total": 0,
        "product_count": 0,
      }
    })


    const getData = async () => {
      const response = await APIs.init().getReportOrder({
        from_date: rangeTime.value[0].format('YYYY-MM-DDT00:00:00.000+07:00').toString(),
        to_date: rangeTime.value[1].format('YYYY-MM-DDT23:59:59.999+07:00').toString(),
        stock_id: selectedStock.value,
      })
      console.log(response)
      if (response.data.status === 200) {
        currentData.value = response.data?.data
      }
    };


    const getDataToday = async () => {
      const response = await APIs.init().getReportOrder({
        from_date: dayjs().format('YYYY-MM-DDT00:00:00.000+07:00').toString(),
        to_date: dayjs().format('YYYY-MM-DDT23:59:59.999+07:00').toString(),
        stock_id: selectedStock.value,
      })
      console.log(response)
      if (response.data.status === 200) {
        todayData.value = response.data?.data
      }
    };

    onMounted(() => {
      getData()
      getDataToday()
      fetchChart()
    });

    const formatMoney = (val) => {
      return MixUtils.methods.formatMoney(val)
    }

    const calculatorPercent = (a, total) => {
      return Math.round(a / total * 10000) / 100
    }

    const messageData = ref({
      messages: [],
      comments: [],
      allMessage: [],
      newPhones: [],
      labels: ['10%', '20%', '30%', '40%'],
      customers: [],
      conversations: [],
      customerMessages: [],
      conversationMessageNew: [],
      customerNew: [],
      messagePercent: 0,
      commentPercent: 0,
      totalComment: 0,
      totalMessage: 0,

    })
    const handlePageChange = (val) => {
      selectedPage.value = val;
      console.log(val)
      // fetchCusomer()
      getDataToday()
      getData()
    };


    const onChangeDateTime = (value, dateString) => {
      console.log('Selected Time: ', value);
      console.log('Formatted Selected Time: ', dateString);
      if (value[0].format('HH-mm') === value[1].format('HH-mm')) {
        rangeTime.value = [value[0].startOf('day'), value[1].endOf('day')]
      } else {
        rangeTime.value = value
      }
      getData()
    };

    const confirmDateTime = (value) => {
      console.log('onOk: ', value);
    };


    return {
      dataShopInfo,
      pagesSelection,
      handlePageChange,
      selectedStock,
      selectedPage,
      splineChart,
      formatMoney,
      calculatorPercent,
      getPages,
      onChangeDateTime,
      confirmDateTime,
      rangeTime,
      customerData,
      getStocks,
      date,
      messageData,
      currentData,
      todayData,
      columnsProduct,
      columnsEmployee,
      chartDataReportCareAll,
      chartDataReportCareAll2
    };
  },
};
</script>

<style>
.card_view {
  border-radius: 10px;
  margin: 4px;
  overflow: hidden;
}

.count-elements {
  -webkit-tap-highlight-color: transparent;
  -webkit-text-size-adjust: 100%;
  font-family: Roboto, Helvetica, Arial, sans-serif;
  font-variant: tabular-nums;
  line-height: 1.5;
  font-feature-settings: 'tnum';
  font-size: 14px;
  color: #000;
  box-sizing: border-box;
  height: 76px;
  padding: 10px;
  border-top: 1px solid #e0e0e0;
  border-bottom: 1px solid #e0e0e0;
  border-radius: 10px 0px 0px 10px;
  cursor: pointer;
  box-shadow: 0px 2px 10px rgba(51, 51, 51, 0.1);
  border-left: 10px solid rgb(43, 216, 196);
  background-color: rgba(196, 255, 248, 0.23);
  width: 100%;
}

.title {
  font-family: "Roboto" !important;
  font-style: normal !important;
  font-weight: 700 !important;
  font-size: 12px !important;
  line-height: 22px !important;
  color: #000000 !important;
  margin-left: 0px !important;
  margin-top: 0px !important;
  text-align: left !important;
  width: 100% !important;
}

.count {
  text-align: right;
  font-weight: 100;
  font-family: Montserrat-Light;
  font-size: 25px;
  line-height: 30px;
  color: #263C8F;
  float: right;
}

.highcharts-credits {
  display: none;
}
</style>
