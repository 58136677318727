<template>
  <div class="container-title">
    <h3 class="title-header">{{$t($route.name)}}</h3>
    <div class="input-search">
      <a-input-search
          v-model:value="categoryRequest.search"
          placeholder="Search"
          @change="handleOnChange"
      />
    </div>

    <a-button
        v-if="getMyRole(modules.categories)?.is_create"
        type="primary" class="button-add-product" @click="create">
      <plus-outlined/>
      {{formState.id?$t('EDIT'):$t('ADD')}}
    </a-button>
  </div>

  <a-table
      size="middle"
      :scroll="{  y: '80vh' }"
      :columns="columns"
      :data-source="getProductCategories"
      class="table"
  >
    <template #category_name="{record}">
      <a
          @click="edit(record)" class="table-row-name">
        {{record.category_name}}
      </a>
    </template>
    <template #description="{record}">
      <span>
        {{record.description}}
      </span>
    </template>
    <template #avatar="{record}">
      <a-image
          width="50px"
          max-height="100px"
          max-width="100px"
          :src="record.avatar"
      />
    </template>
    <template #creator="{record}">
      <span>
        {{record.creator_name}}
      </span>
    </template>
    <template #updated_at="{record}">
      <span>
              {{dateTime(record.updated_at)}}
      </span>
    </template>
    <template #action="{record}">
      <a-dropdown>
        <a-button type="text">
          <eye-outlined />
        </a-button>
        <template #overlay>
          <a-menu>
            <a @click="edit(record)"
               v-if="getMyRole(modules.categories)?.is_edit"
            >
              <a-menu-item class="router-product-edit">
                <edit-outlined/>
                {{ $t('EDIT') }}
              </a-menu-item>
            </a>
            <a-menu-item
                v-if="getMyRole(modules.categories)?.is_delete"
            >
              <a-popconfirm placement="bottom" :ok-text="$t('OK')" :cancel-text="$t('CANCEL')" @confirm="confirmDelete(record)">
                <template #title>
                  <p>{{ $t('CONFIRM_DELETE') + +' '+record.category_name + '?'}}</p>
                </template>
              <delete-outlined/>
              {{ $t('DELETE') }}
              </a-popconfirm>
            </a-menu-item>
          </a-menu>
        </template>
      </a-dropdown>
    </template>
  </a-table>
  <a-modal v-model:visible="editVisible" :title="formState.id?$t('UPDATE_CATEGORY'):$t('ADD_CATEGORY')"
           :footer="null"
           style="width: 40%"
  >
    <a-form
        ref="formRef"
        :model="formState"
        :rules="rules"
        :label-col="labelCol"
        :wrapper-col="wrapperCol"
    >
      <a-form-item :label="$t('NAME')" name="category_name">
        <a-input v-model:value="formState.category_name"/>
      </a-form-item>
      <a-form-item  :label="$t('DESCRIPTION')" name="description">
        <a-input v-model:value="formState.description"/>
      </a-form-item>
      <a-form-item>
        <div
            class="ant-upload ant-upload-select ant-upload-select-picture-card upload-image"
        >
                    <span role="button" tabindex="0" class="ant-upload">
                      <div @click="showImageSelectionModal(1)">
                        <plus-outlined/>
                        <div class="ant-upload-text">{{ $t("UPLOAD") }}</div>
                      </div>
                    </span>
        </div>
        <span>
                    <a-upload
                        list-type="picture"
                        v-model:file-list="images"
                        class="upload-list-inline"
                    >
                    </a-upload>
                  </span>

        <span class="ant-upload-picture-card-wrapper"> </span>
      </a-form-item>
      <a-form-item :wrapper-col="{ offset: 8 }">
        <a-button type="primary" @click="onSubmit">        {{$t('CREATE')}}</a-button>
        <a-button style="margin-left: 10px"  @click="editVisible=!editVisible">        {{$t('CANCEL')}}
        </a-button>
      </a-form-item>
    </a-form>
  </a-modal>
  <image-selection-modal ref="imageSelectionRef" :onSelect="onImageSelect"/>
</template>

<script lang="ts">
import {computed, defineComponent, onBeforeMount, reactive, ref, UnwrapRef} from "vue";
import RouterEndpoint from "@/router/RouterEndpoint";

import {EyeOutlined,EllipsisOutlined,EditOutlined,DeleteOutlined,PlusOutlined} from "@ant-design/icons-vue";
import {useStore} from "@/store";
import {useRoute} from "vue-router";
import {CreateProductCategoryRequest, ProductCategoryRequest} from "@/APIs/request/ProductRequest";
import {ProductCategory} from "@/models/Products/Products";
import {ProductsActionTypes} from "@/store/modules/product/action-types";
import {SuperUserInfo} from "@/models/Users";
import {message} from "ant-design-vue";
import APIs from "@/APIs/APIs";
import {FormFlowInformation} from "@/models/Flows/FlowForm";
import {MixUtils} from "@/utils/Utils";
import {useI18n} from "vue-i18n";
import routers from "@/router";
import {Constant} from "@/utils/Constants";
import ImageSelectionModal from "@/components/Image/ImageSelectionModal.vue";
interface FileItem {
  uid: string;
  name?: string;
  status?: string;
  response?: string;
  percent?: number;
  url?: string;
  preview?: string;
  originFileObj?: any;
}
export default defineComponent({
  name: "Categories",
  components:{
    ImageSelectionModal,
    EyeOutlined,
    EditOutlined,
    DeleteOutlined,
    PlusOutlined,
  },
  setup(){
    const router = useRoute()
    const editVisible=ref( false)
    const formRef = ref();
    const store=useStore()

    const i18n=useI18n()

    const imageSelectionRef = ref();
    const images = ref<FileItem[]>([]);


    const formState: UnwrapRef<ProductCategory> = reactive({
      id: undefined,
      category_name: '',
      description: '',
    });

    const rules = {
      category_name: [
        {required: true, message: i18n.t('PLACEHOLDER_INPUT_NAME'), trigger: 'blur'},
      ],
    };

    const columns =computed(()=>[
      {
        title: i18n.t('NAME'),
        dataIndex: 'category_name',
        key: 'category_name',
        slots:{customRender:'category_name'}
      },
      {
        title:  i18n.t('DESCRIPTION'),
        key: 'description',
        dataIndex: 'description',
      },
      {
        title:  i18n.t('IMAGE'),
        key: 'avatar',
        slots:{customRender:'avatar'}
      },
      {
        title:  i18n.t('UPDATE_AT'),
        key: 'updated_at',
        dataIndex: 'updated_at',
        slots:{customRender:'updated_at'}

      },
      {
        title:  i18n.t('ACTION'),
        key: 'action',
        slots:{customRender:'action'}
      },
    ]);

    const categoryRequest: ProductCategoryRequest = {
      page: 1,
      page_size: 1000,
    }

    const getProductCategories = computed((): ProductCategory[]| undefined => store.getters.getProductCategories)
    const getUserInfo = computed((): SuperUserInfo| null => store.getters.getUserInfo)

    const createProductCategoryRequest: CreateProductCategoryRequest = {
      shop_id: getUserInfo.value?.current_shop_id,
      category_name: '',
      description: '',
      creator: getUserInfo.value?.id
    }



    const fetchProductCategories=()=>{
      store.dispatch(ProductsActionTypes.FETCH_PRODUCT_CATEGORIES, categoryRequest)
    }
    const createProductCategory=()=>{

      message.loading('');
      createProductCategoryRequest.category_name = formState.category_name
      createProductCategoryRequest.description = formState.description
      console.log('avatar: ', images.value[0].url)
      createProductCategoryRequest.avatar = images.value.length>0?images.value[0].url:''
      APIs.init().createProductCategory(createProductCategoryRequest).then(res => {
        if (res.status === 200 && res.data?.status === 200) {
          editVisible.value = false
          message.success({content: 'Created category: ' + res.data.data.category_name});
          fetchProductCategories()
        } else {
          message.destroy()
        }
      }).catch(e => {
        message.destroy()
        console.error(e)
      })
    }
    const updateProductCategory=()=>{
      message.loading('');
      createProductCategoryRequest.category_name = formState.category_name
      createProductCategoryRequest.description = formState.description
      createProductCategoryRequest.avatar = images.value.length>0?images.value[0].url:''
      console.log('avatar: ', images.value[0].url)
      console.log(createProductCategoryRequest)

      APIs.init().updateProductCategory(createProductCategoryRequest).then(res => {
        if (res.status === 200 && res.data?.status === 200) {
          editVisible.value = false
          message.success({content: 'Updated category: ' + res.data.data.category_name});
          fetchProductCategories()
        } else {
          message.destroy()
        }
      }).catch(e => {
        message.destroy()
        console.error(e)
      })
    }

    const confirmDelete=(category: ProductCategory)=>{
      message.loading('');
      const deleteProductCategoryRequest: CreateProductCategoryRequest = {id: category.id}
      APIs.init().deleteProductCategory(deleteProductCategoryRequest).then(res => {
        if (res.status === 200 && res.data?.status === 200) {
          editVisible.value = false
          message.success({content: 'Deleted category: ' + category.category_name});
          fetchProductCategories()
        } else {
          message.destroy()
        }
      }).catch(e => {
        message.destroy()
        console.error(e)
      })
    }


    onBeforeMount(()=>{
      fetchProductCategories()
    })

    const onSubmit = ()=>{
      console.log('submit')
      formRef.value
          .validate()
          .then(() => {
            if(formState.id){
              updateProductCategory()
            }else{
              createProductCategory()

            }
          })
          .catch((error: any) => {
            console.log('error', error);
          });


    }

    const dateTime = (dateString: string)=>{
      return MixUtils.methods.dateTimeDisplayFormatFromShortString(dateString)
    }

    const edit = (category: ProductCategory)=>{
        console.log('category:', category)
        createProductCategoryRequest.id = category.id
        formState.id = category.id
        formState.category_name = category.category_name
        formState.description = category.description
        editVisible.value = true
        images.value = []
        if(category.avatar){
          images.value = [ {
            uid: MixUtils.methods.getUUID(),
            name: "xxx.png",
            status: "done",
            url: category.avatar,
          }]
        }
    }


    const create = () => {
      formState.id = 0
      formState.category_name = ''
      formState.description = ''
      images.value = []
      editVisible.value = true
    };

    const handleOnChange  = ()=>{
      fetchProductCategories()
    }

    const getMyRole = computed(() => {
      if(getUserInfo.value?.id_cms_privileges==null ||  getUserInfo.value?.id_cms_privileges<11){
        return ()=>({
          is_visible: 1,
          is_create:1,
          is_read: 1,
          is_edit: 1,
          is_delete: 1,
          id: 1
        })
      }else{
        return store.getters.getRole
      }
    });
    const modules = Constant.MODULES;

    const showImageSelectionModal = (param: any) => {
      imageSelectionRef.value.showModal(param);
    };
    const onImageSelect = (imageSelected: FileItem[], param: number) => {
      images.value = [
        {
          uid: MixUtils.methods.getUUID(),
          name: "xxx.png",
          status: "done",
          url: imageSelected[0].url,
        },
      ];
      console.log('onImageSelect', images)

    };

    return{
      getMyRole,
      modules,
      formRef,
      editVisible,
      formState,
      rules,
      onSubmit,
      create,
      edit,
      confirmDelete,
      dateTime,
      columns,
      data: [],
      RouterEndpoint:RouterEndpoint,
      labelCol: { span: 6 },
      wrapperCol: { span: 16 },
      getProductCategories,
      categoryRequest,
      handleOnChange,
      showImageSelectionModal,
      imageSelectionRef,
      onImageSelect,
      images,
      createProductCategory
    }
  },
})
</script>

<style scoped lang="scss">
.filter-bar {
  display: flex;
  flex-wrap: wrap;

  .filter-form-label {
    margin-bottom: 4px;
  }

  .button-function {
    display: flex;
    flex: 1;
    justify-content: flex-end;
    align-self: flex-end;

    .button-filter-bar-right {
      margin-left: 16px;
    }

    .button-add-product {
    }
  }
}

.table {
  margin: 16px
}
.title-header {
  font-weight: bold;
  flex: 1;
}
.container-title{
  display: flex;
  flex-direction: row;
  padding: 16px 24px 16px 24px;
  margin-bottom: 25px;
  background-color: white
}
.input-search{
  width: 250px;
  margin-right: 16px;
}
.table-row-name{
  font-weight: bold;
}
</style>
