export enum FlowsMutationTypes {
    SET_FLOWS = 'SET_FLOWS',
    SET_FLOWS_2 = 'SET_FLOWS_2',
    SET_FLOW_CONTEXT = 'SET_FLOW_CONTEXT',
    SET_FlowConstants = 'SET_FLOW_CONSTANTS',
    UPDATE_FlowContext = 'UPDATE_FLOW_CONTEXT',
    UPSERT_FLOW_CONTEXT_LIST = 'UPSERT_FLOW_CONTEXT_LIST',
    SET_ZNS_TEMP = 'SET_ZNS_TEMP',
}

