<template>
  <a-layout-content>
    <div style="padding: 16px 24px 16px 24px;
  margin-bottom: 25px;
  background-color: white">
      <h3 class="title-header">{{ $t($route.name) }}</h3>
<!--      <a-row >-->
<!--        <a-checkbox-group  @change="changeColum"  v-model:value="listColum" :options="checkedList"   >-->
<!--          <template #label="{ key }">-->
<!--            <span style="color: red">{{ key }}</span>-->
<!--          </template>-->
<!--        </a-checkbox-group>-->
<!--      </a-row>-->
    </div>

  </a-layout-content>
  <div style="padding: 16px">
    <a-card class="card_view" style="border-radius: 10px; margin: 4px;">
      <a-table :pagination="false"   :scroll="{ x: 1500, y: 300 }" :columns="columns" :data-source="data" bordered>
        <template #id="{ record }">
          {{record.ads_info.hits.hits[0]._source.account_id }}
        </template>
        <template #name="{ record }">
          {{record.ads_info.hits.hits[0]._source.ads_name }}
        </template>
        <template #money_spent="{ record }">
          {{formatMoney(record.ads_info.hits.hits[0]._source.ads_account_amount_spend) }}đ
        </template>
<!--        <template #impressions="{ record }">-->
<!--          {{record.ads_info.hits.hits[0].ads_insight[0].impressions }}-->
<!--        </template>-->
<!--        <template #approach="{ record }">-->
<!--          {{record.ads_info.hits.hits[0].ads_insight[0].reach }}-->
<!--        </template>-->
<!--        <template #number_of_clicks_on_the_link="{ record }">-->
<!--          {{record.ads_info.hits.hits[0].ads_insight[0].inline_link_clicks }}-->
<!--        </template>-->
<!--        <template #CPM="{ record }">-->
<!--          {{record.ads_info.hits.hits[0].ads_insight[0].cpm }}-->
<!--        </template>-->
<!--        <template #CTR="{ record }">-->
<!--          {{record.ads_info.hits.hits[0].ads_insight[0].ctr }}-->
<!--        </template>-->
<!--        <template #CPC="{ record }">-->
<!--          {{record.ads_info.hits.hits[0].ads_insight[0].cpc }}-->
<!--        </template>-->
<!--        <template #CPP="{ record }">-->
<!--          {{record.ads_info.hits.hits[0].ads_insight[0].cpp }}-->
<!--        </template>-->
<!--        <template #new_message_connection="{ record }">-->
<!--          {{record.ads_info.hits.hits[0].ads_insight[0].cpc }}-->
<!--        </template>-->
<!--        <template #the_message_conversation_has_begun="{ record }">-->
<!--          {{record.ads_info.hits.hits[0].ads_insight[0].ctr }}-->
<!--        </template>-->
<!--        <template #Purchases="{ record }">-->
<!--          {{record.ads_info.hits.hits[0].ads_insight[0].ctr }}-->
<!--        </template>-->
<!--        <template #conversion_value_per_purchase="{ record }">-->
<!--          {{record.ads_info.hits.hits[0].ads_insight[0].ctr }}-->
<!--        </template>-->
<!--        <template #comment="{ record }">-->
<!--          {{record.ads_info.hits.hits[0].ads_insight[0].ctr }}-->
<!--        </template>-->
        <template #money="{ record }">
          {{formatMoney(record.ads_info.hits.hits[0]._source.campaign_lifetime_budget-record.ads_info.hits.hits[0]._source.ads_account_balance) }}
        </template>

        <template #orders="{ record }">
<!--          <a-button @click="() => showListOder(record.ads_info.hits.hits[0]._source.full_name)">-->
<!--            -->
<!--          </a-button>-->
          {{record.order_count.value }}
        </template>
        <template #total="{ record }">
          {{formatMoney(record.total_order_money.value)}}đ
        </template>
        <template #budget="{ record }">
          {{formatMoney(record.ads_info.hits.hits[0]._source.campaign_lifetime_budget)}}đ
        </template>
        <template #balance="{ record }">
          {{formatMoney(record.ads_info.hits.hits[0]._source.ads_account_balance)}}đ
        </template>
      </a-table>
    </a-card>




  </div>
</template>

<script>

import {useI18n} from "vue-i18n";
import {useStore} from "vuex";
import APIs from "../../../APIs/APIs";
import {onMounted,ref} from "vue";

export default {

  setup(){
    const i18n = useI18n()
    const store = useStore();
    const columns =ref([
      {
        title: i18n.t('id'),
        width: 200,
        fixed: 'left',
        slots: {customRender: 'id'},
      },
      {
        title: i18n.t('name'),
        width: 200,
        fixed: 'left',
        slots: {customRender: 'name'},
      },
      {
        title: i18n.t('ADS_MONEY'),

        slots: {customRender: 'money'},
      },
      {
        title: i18n.t('ORDERS'),

        slots: {customRender: 'orders'},
      },
      {
        title: i18n.t('Budget'),

        slots: {customRender: 'budget'},
      },
      {
        title: i18n.t('Balance'),

        slots: {customRender: 'balance'},
      },
      {
        title: i18n.t('TOTAL_VALUE_OF_GOODS'),

        slots: {customRender: 'total'},
      },
    ])
    const listColum=ref(['name','budget','money_spent','balance'])
    const checkedList = [
      {key:i18n.t('NAME'),value:'name'},
      {key:i18n.t('Budget'),value:'budget'},
      {key:i18n.t('money_spent'),value:'money_spent'},
      {key:i18n.t('Balance'),value:'balance'},
      // {key:i18n.t('impressions'),value:'impressions'},
      // {key:i18n.t('approach'),value:'approach'},
      // {key:i18n.t('number_of_clicks_on_the_link'),value:'number_of_clicks_on_the_link'},
      // {key:i18n.t('CPM'),value:'CPM'},
      // {key:i18n.t('CTR'),value:'CTR'},
      // {key:i18n.t('new_message_connection'),value:'new_message_connection'},
      // {key:i18n.t('The_message_conversation_has_begun'),value:'The_message_conversation_has_begun'},
      // {key:i18n.t('CPC'),value:'CPC'},
      // {key:i18n.t('Purchases'),value:'purchases'},
      // {key:i18n.t('conversion_value_per_purchase'),value:'conversion_value_per_purchase'},
      // {key:i18n.t('ROAS'),value:'ROAS'},
      // {key:i18n.t('comment'),value:'comment'},
      // {key:i18n.t('Lead'),value:'Lead'},
      // {key:i18n.t('cost_per_unique_click'),value:'cost_per_unique_click'},
    ]
    const data = ref([])
    const getReport = async () => {
      const res = await APIs.init().getConversion({})
      if(res.data.status === 200){
        data.value = res.data.data.aggregations.fb_campaign.buckets



      }
    }
    const changeColum = (value) => {

      if(columns.value.length > value.length){
        columns.value = columns.value.filter((col)=> value.includes(col.slots.customRender))
      }else {
        value.map((item) => {
          let check = false
          columns.value.map((col) => {
            if(item === col.slots.customRender){
              check = true
            }

          })
          if(!check){
            columns.value.push({
              title: i18n.t(item),
              slots: {customRender: item},
            })

          }

        })
      }

    }
    const showListOder = (name) => {
      window.open(`https://biglead.live/crm/orders?customer=${name}`)
    }
    onMounted(()=>{
      // listColum.value.map((item) => {
      //
      //     columns.value.push({
      //       title: i18n.t(item),
      //
      //       slots: {customRender: item},
      //     },)
      //
      //
      // })
      getReport()
    })
    return{
      columns,
      data,
      showListOder,
      checkedList,
      listColum,
      changeColum
    }
  }

}
</script>
<style>
.card_view {
  border-radius: 10px;
  margin: 4px;
  overflow: hidden;
}
</style>
