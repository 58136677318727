
import {State, TicketData, TicketPriceData, TicketTemplateData, TicketTransactionData, TicketTypeData} from "./states";

export type Getters = {

    getTicket(state: State):  TicketData
    getTicketType(state: State):  TicketTypeData
    getTicketPrice(state: State):  TicketPriceData
    getTicketTemplate(state: State):  TicketTemplateData
    getTicketTransaction(state: State):  TicketTransactionData
}
export const getters: Getters = {

    getTicket(state: State):  TicketData {

        if (state.ticketData) {
            return state.ticketData
        } else {
            // @ts-ignore
          return {tickets: [], metadata: {}}
        }
    },
    getTicketType(state: State):  TicketTypeData {

        if (state.ticketTypeData) {
            return state.ticketTypeData
        } else {
            // @ts-ignore
            return {tickeTypes: [], metadata: {}}
        }
    },
    getTicketPrice(state: State):  TicketPriceData {

        if (state.ticketPriceData) {
            return state.ticketPriceData
        } else {
            // @ts-ignore
            return {tickePrices: [], metadata: {}}
        }
    },
    getTicketTemplate(state: State):  TicketTemplateData {

        if (state.ticketTemplateData) {
            return state.ticketTemplateData
        } else {
            // @ts-ignore
            return {templates: [], metadata: {}}
        }
    },
    getTicketTransaction(state: State):  TicketTransactionData {

        if (state.ticketTransactionData) {
            return state.ticketTransactionData
        } else {
            // @ts-ignore
            return {templates: [], metadata: {}}
        }
    },

}
