import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "container-title" }
const _hoisted_2 = { class: "title-header" }
const _hoisted_3 = { class: "container" }
const _hoisted_4 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_typography_text = _resolveComponent("a-typography-text")!
  const _component_a_button = _resolveComponent("a-button")!
  const _component_a_row = _resolveComponent("a-row")!
  const _component_a_input = _resolveComponent("a-input")!
  const _component_a_form_item = _resolveComponent("a-form-item")!
  const _component_a_col = _resolveComponent("a-col")!
  const _component_a_input_number = _resolveComponent("a-input-number")!
  const _component_a_radio = _resolveComponent("a-radio")!
  const _component_a_radio_group = _resolveComponent("a-radio-group")!
  const _component_a_typography_title = _resolveComponent("a-typography-title")!
  const _component_a_select_option = _resolveComponent("a-select-option")!
  const _component_a_select = _resolveComponent("a-select")!
  const _component_a_form = _resolveComponent("a-form")!
  const _component_a_card = _resolveComponent("a-card")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("h3", _hoisted_2, _toDisplayString(_ctx.$t(_ctx.$route.name)), 1)
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createVNode(_component_a_card, { style: {"border-radius":"10px","margin":"auto","width":"95%"} }, {
        default: _withCtx(() => [
          _createVNode(_component_a_row, { justify: "space-between" }, {
            default: _withCtx(() => [
              _createVNode(_component_a_typography_text, { style: {"display":"block","margin-bottom":"20px"} }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t('MEMBER_RANK')), 1)
                ]),
                _: 1
              }),
              _createVNode(_component_a_button, {
                onClick: $setup.onSubmit,
                type: "primary "
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t('SAVE')), 1)
                ]),
                _: 1
              }, 8, ["onClick"])
            ]),
            _: 1
          }),
          _createVNode(_component_a_form, {
            "label-col": { span: 8 },
            "wrapper-col": { span: 16 },
            layout: "vertical",
            model: $setup.formState,
            rules: $setup.rules,
            ref: "formRef"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_a_row, null, {
                default: _withCtx(() => [
                  _createVNode(_component_a_col, { span: 12 }, {
                    default: _withCtx(() => [
                      _createVNode(_component_a_form_item, {
                        label: _ctx.$t('RANK_ID'),
                        name: "rank_id"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_a_input, {
                            disabled: $setup.checkEdit ? true : false,
                            onChange: $setup.changeRankId,
                            value: $setup.formState.rank_id,
                            "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => (($setup.formState.rank_id) = $event))
                          }, null, 8, ["disabled", "onChange", "value"])
                        ]),
                        _: 1
                      }, 8, ["label"])
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_a_col, { span: 12 }, {
                    default: _withCtx(() => [
                      _createVNode(_component_a_form_item, {
                        label: _ctx.$t('MEMBER_RANK'),
                        name: "rank_name"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_a_input, {
                            value: $setup.formState.rank_name,
                            "onUpdate:value": _cache[1] || (_cache[1] = ($event: any) => (($setup.formState.rank_name) = $event))
                          }, null, 8, ["value"])
                        ]),
                        _: 1
                      }, 8, ["label"])
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }),
              _createVNode(_component_a_row, null, {
                default: _withCtx(() => [
                  _createVNode(_component_a_col, { span: 12 }, {
                    default: _withCtx(() => [
                      _createVNode(_component_a_form_item, {
                        label: _ctx.$t('RATE'),
                        name: "accumulate_points"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_a_input_number, {
                            value: $setup.formState.accumulate_points,
                            "onUpdate:value": _cache[2] || (_cache[2] = ($event: any) => (($setup.formState.accumulate_points) = $event)),
                            style: {"width":"100%"}
                          }, {
                            addonAfter: _withCtx(() => [
                              _createTextVNode(_toDisplayString('%'+_ctx.$t('BILL_INVOICE')), 1)
                            ]),
                            _: 1
                          }, 8, ["value"]),
                          ($setup.formState.accumulate_points)
                            ? (_openBlock(), _createBlock(_component_a_typography_text, { key: 0 }, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(`Hóa đơn 100,000đ khách hàng tích được ${$setup.getPoint($setup.formState.accumulate_points)} điểm`), 1)
                                ]),
                                _: 1
                              }))
                            : _createCommentVNode("", true)
                        ]),
                        _: 1
                      }, 8, ["label"]),
                      _createVNode(_component_a_row)
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }),
              _createVNode(_component_a_typography_text, { style: {"display":"block","margin-bottom":"20px","margin-top":"20px"} }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t('QUALIFICATIONS_FOR_RANKING')), 1)
                ]),
                _: 1
              }),
              _createVNode(_component_a_row, null, {
                default: _withCtx(() => [
                  _createVNode(_component_a_col, { span: 12 }, {
                    default: _withCtx(() => [
                      _createVNode(_component_a_form_item, {
                        label: _ctx.$t('EXCESS_ACCUMULATED_MONEY'),
                        name: "accumulated_money"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_a_input_number, {
                            formatter: value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ','),
                            value: $setup.formState.accumulated_money,
                            "onUpdate:value": _cache[3] || (_cache[3] = ($event: any) => (($setup.formState.accumulated_money) = $event))
                          }, {
                            addonAfter: _withCtx(() => [
                              _createTextVNode(_toDisplayString('VNĐ'))
                            ]),
                            _: 1
                          }, 8, ["formatter", "value"])
                        ]),
                        _: 1
                      }, 8, ["label"])
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_a_col, { span: 12 }, {
                    default: _withCtx(() => [
                      _createVNode(_component_a_form_item, null, {
                        default: _withCtx(() => [
                          _createVNode(_component_a_radio_group, {
                            style: {"display":"flex","flex-direction":"column"},
                            value: $setup.formState.config,
                            "onUpdate:value": _cache[6] || (_cache[6] = ($event: any) => (($setup.formState.config) = $event))
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_a_radio, {
                                style: {"margin-bottom":"10px"},
                                value: 'MAINTAIN'
                              }, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(_ctx.$t('MAINTAIN_ACCUMULATED_MONEY')), 1)
                                ]),
                                _: 1
                              }),
                              _createVNode(_component_a_radio, { value: 'MINUS' }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_a_input_number, {
                                    formatter: value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ','),
                                    style: {"margin-bottom":"10px"},
                                    value: $setup.formState.minus,
                                    "onUpdate:value": _cache[4] || (_cache[4] = ($event: any) => (($setup.formState.minus) = $event)),
                                    "addon-before": _ctx.$t('MINUS_ACCUMULATED_MONEY'),
                                    "addon-after": "VNĐ"
                                  }, null, 8, ["formatter", "value", "addon-before"])
                                ]),
                                _: 1
                              }),
                              _createVNode(_component_a_radio, { value: 'RESET' }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_a_input_number, {
                                    formatter: value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ','),
                                    value: $setup.formState.reset,
                                    "onUpdate:value": _cache[5] || (_cache[5] = ($event: any) => (($setup.formState.reset) = $event)),
                                    "addon-before": _ctx.$t('RESET_ACCUMULATED_MONEY_TO'),
                                    "addon-after": "VNĐ"
                                  }, null, 8, ["formatter", "value", "addon-before"])
                                ]),
                                _: 1
                              })
                            ]),
                            _: 1
                          }, 8, ["value"])
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }),
              ($setup.showCyclical)
                ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                    _createVNode(_component_a_form_item, {
                      label: _ctx.$t('REVIEW_SPENDING_NORMS')
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_a_input_number, {
                          value: $setup.config.check_spending_limit,
                          "onUpdate:value": _cache[7] || (_cache[7] = ($event: any) => (($setup.config.check_spending_limit) = $event)),
                          formatter: value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ','),
                          style: {"width":"50%"},
                          "addon-after": "VNĐ"
                        }, null, 8, ["value", "formatter"])
                      ]),
                      _: 1
                    }, 8, ["label"]),
                    _createVNode(_component_a_typography_title, { level: 5 }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.$t('IF_THE_SPENDING_LIMIT_IS_NOT_MET_RE-EVALUATE_THE_RANK')), 1)
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_a_row, null, {
                      default: _withCtx(() => [
                        _createVNode(_component_a_col, { span: 12 }, {
                          default: _withCtx(() => [
                            _createVNode(_component_a_form_item, {
                              label: _ctx.$t('MEMBER_RANK_CHANGE')
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_a_radio_group, {
                                  style: {"display":"flex","flex-direction":"column"},
                                  value: $setup.config.achieved.config_rank,
                                  "onUpdate:value": _cache[9] || (_cache[9] = ($event: any) => (($setup.config.achieved.config_rank) = $event))
                                }, {
                                  default: _withCtx(() => [
                                    _createVNode(_component_a_radio, {
                                      style: {"margin-bottom":"10px"},
                                      value: 'maintain'
                                    }, {
                                      default: _withCtx(() => [
                                        _createTextVNode(_toDisplayString(_ctx.$t('MAINTAIN_ACCUMULATED_MONEY')), 1)
                                      ]),
                                      _: 1
                                    }),
                                    _createVNode(_component_a_radio, {
                                      style: {"width":"100%"},
                                      value: 'not_maintain'
                                    }, {
                                      default: _withCtx(() => [
                                        _createVNode(_component_a_input, {
                                          disabled: "true",
                                          value: _ctx.$t('LOWER_IN_RANK')
                                        }, {
                                          addonAfter: _withCtx(() => [
                                            _createVNode(_component_a_select, {
                                              value: $setup.config.achieved.downgrade,
                                              "onUpdate:value": _cache[8] || (_cache[8] = ($event: any) => (($setup.config.achieved.downgrade) = $event)),
                                              style: {"width":"250px"}
                                            }, {
                                              default: _withCtx(() => [
                                                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.ranks.memberRank, (i) => {
                                                  return (_openBlock(), _createBlock(_component_a_select_option, {
                                                    key: i,
                                                    value: i
                                                  }, {
                                                    default: _withCtx(() => [
                                                      _createTextVNode(_toDisplayString(_ctx.$t(i.rank_name)), 1)
                                                    ]),
                                                    _: 2
                                                  }, 1032, ["value"]))
                                                }), 128))
                                              ]),
                                              _: 1
                                            }, 8, ["value"])
                                          ]),
                                          _: 1
                                        }, 8, ["value"])
                                      ]),
                                      _: 1
                                    })
                                  ]),
                                  _: 1
                                }, 8, ["value"])
                              ]),
                              _: 1
                            }, 8, ["label"])
                          ]),
                          _: 1
                        }),
                        _createVNode(_component_a_col, { span: 12 }, {
                          default: _withCtx(() => [
                            _createVNode(_component_a_form_item, {
                              label: _ctx.$t('CHANGE_ACCUMULATE_MONEY')
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_a_radio_group, {
                                  style: {"display":"flex","flex-direction":"column"},
                                  value: $setup.config.achieved.config_money,
                                  "onUpdate:value": _cache[12] || (_cache[12] = ($event: any) => (($setup.config.achieved.config_money) = $event))
                                }, {
                                  default: _withCtx(() => [
                                    _createVNode(_component_a_radio, {
                                      style: {"margin-bottom":"10px"},
                                      value: 'MAINTAIN'
                                    }, {
                                      default: _withCtx(() => [
                                        _createTextVNode(_toDisplayString(_ctx.$t('MAINTAIN_ACCUMULATED_MONEY')), 1)
                                      ]),
                                      _: 1
                                    }),
                                    _createVNode(_component_a_radio, { value: 'MINUS' }, {
                                      default: _withCtx(() => [
                                        _createVNode(_component_a_input_number, {
                                          formatter: value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ','),
                                          style: {"margin-bottom":"10px"},
                                          value: $setup.config.achieved.minus,
                                          "onUpdate:value": _cache[10] || (_cache[10] = ($event: any) => (($setup.config.achieved.minus) = $event)),
                                          "addon-before": _ctx.$t('MINUS_ACCUMULATED_MONEY'),
                                          "addon-after": "VNĐ"
                                        }, null, 8, ["formatter", "value", "addon-before"])
                                      ]),
                                      _: 1
                                    }),
                                    _createVNode(_component_a_radio, { value: 'RESET' }, {
                                      default: _withCtx(() => [
                                        _createVNode(_component_a_input_number, {
                                          formatter: value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ','),
                                          value: $setup.config.achieved.reset,
                                          "onUpdate:value": _cache[11] || (_cache[11] = ($event: any) => (($setup.config.achieved.reset) = $event)),
                                          "addon-before": _ctx.$t('RESET_ACCUMULATED_MONEY_TO'),
                                          "addon-after": "VNĐ"
                                        }, null, 8, ["formatter", "value", "addon-before"])
                                      ]),
                                      _: 1
                                    })
                                  ]),
                                  _: 1
                                }, 8, ["value"])
                              ]),
                              _: 1
                            }, 8, ["label"])
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_a_typography_title, { level: 5 }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.$t('IF_THE_SPENDING_LIMIT_IS_NOT_MET_RE-EVALUATE_THE_RANK')), 1)
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_a_form_item, null, {
                      default: _withCtx(() => [
                        _createVNode(_component_a_radio_group, {
                          style: {"display":"flex","flex-direction":"column"},
                          value: $setup.config.not_achieved.config_money,
                          "onUpdate:value": _cache[15] || (_cache[15] = ($event: any) => (($setup.config.not_achieved.config_money) = $event))
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_a_radio, {
                              style: {"margin-bottom":"10px"},
                              value: 'MAINTAIN'
                            }, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(_ctx.$t('MAINTAIN_ACCUMULATED_MONEY')), 1)
                              ]),
                              _: 1
                            }),
                            _createVNode(_component_a_radio, { value: 'MINUS' }, {
                              default: _withCtx(() => [
                                _createVNode(_component_a_input_number, {
                                  formatter: value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ','),
                                  style: {"margin-bottom":"10px"},
                                  value: $setup.config.not_achieved.minus,
                                  "onUpdate:value": _cache[13] || (_cache[13] = ($event: any) => (($setup.config.not_achieved.minus) = $event)),
                                  "addon-before": _ctx.$t('MINUS_ACCUMULATED_MONEY'),
                                  "addon-after": "VNĐ"
                                }, null, 8, ["formatter", "value", "addon-before"])
                              ]),
                              _: 1
                            }),
                            _createVNode(_component_a_radio, { value: 'RESET' }, {
                              default: _withCtx(() => [
                                _createVNode(_component_a_input_number, {
                                  formatter: value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ','),
                                  value: $setup.config.not_achieved.reset,
                                  "onUpdate:value": _cache[14] || (_cache[14] = ($event: any) => (($setup.config.not_achieved.reset) = $event)),
                                  "addon-before": _ctx.$t('RESET_ACCUMULATED_MONEY_TO'),
                                  "addon-after": "VNĐ"
                                }, null, 8, ["formatter", "value", "addon-before"])
                              ]),
                              _: 1
                            })
                          ]),
                          _: 1
                        }, 8, ["value"])
                      ]),
                      _: 1
                    })
                  ]))
                : _createCommentVNode("", true)
            ]),
            _: 1
          }, 8, ["model", "rules"])
        ]),
        _: 1
      })
    ])
  ], 64))
}