<template>
  <a-modal
      v-model:visible="visible"
      :footer="null"
      width="98%"
      :closable="true"
  >
    <slot name="header">
      <div>
                <span style="font-size: 15px; color:#516CAD;margin-left: 20px;">
                  Create Viral
                </span>
      </div>
    </slot>
    <slot name="closeIcon" @click="visible = false">
      <div>
        <a-icon type="close-circle" />
      </div>
    </slot>
  <a-row class="container-viral">
    <a-col :span="18">
      <a-form
          ref="formRef"
          :model="formState"
          :rules="rules"
          :label-col="labelCol"
          :wrapper-col="wrapperCol"
      >
        <a-card class="border-radio-card">
            <a-form-item has-feedback :label="$t('viral_name')" name="name" class="row1-viral">
              <a-input v-model:value="formState.name" type="text" autocomplete="off"/>
            </a-form-item>
          <a-form-item has-feedback :label="$t('TAG_SELECT')" name="tags">
            <a-select style="text-align: left" mode="multiple" v-model:value="formState.tags" @change="tagSelectionChange" placeholder="tag1, tag2, ...">
              <a-select-option v-for="item in flowTags" :value="item.id" :key="item.id" :color="item.color">
                <a-tag :color="item.color">{{ item.name }}</a-tag>
              </a-select-option>
            </a-select>
          </a-form-item>

            <a-form-item has-feedback :label="$t('viral_description')" name="description" class="row2-viral">
              <a-textarea v-model:value="formState.description" type="text" autocomplete="off"/>
            </a-form-item>
        </a-card>
        <div class="row-viral">
          <a-card class="border-radio-card card-content-viral" :title="'1. '+ $t('viral_level_1')" head-style="text-align:center">
            <div class="content-center-upload">
              <div
                  @click="showImageSelectionModal('1')"
                  class="ant-upload ant-upload-select ant-upload-select-picture-card"
                  style="width: 60%;aspect-ratio: 4 / 3">
                <span role="button" tabindex="0" class="ant-upload">
                    <img
                        v-if="formState.imageFirst"
                        style="width: 100%; aspect-ratio: 4 / 3;object-fit: contain;"
                        :src="formState.imageFirst"
                    />
                <input type="file" accept="" style="display: none;">
                <div>
                 <plus-outlined/>
                  <div
                      class="ant-upload-text">Upload</div>
                </div>
              </span>

              </div>
              <div class="note-image">Products, banner, campaign image</div>
            </div>
            <div style="height: 16px;"></div>
            <a-form-item has-feedback :wrapper-col="{ span: 15 }" :label-col="{ span: 7 }" :label="$t('viral_level_title')" name="title1"
                         class="row1-viral">
              <a-input v-model:value="formState.title1" type="text" autocomplete="off" :placeholder="$t('viral_level_title_placeholder')"/>
            </a-form-item>
            <a-form-item has-feedback :wrapper-col="{ span: 15}" :label-col="{ span: 7 }" :label="$t('viral_level_description')"
                         name="subtitle1"
                         class="row1-viral">
              <a-textarea v-model:value="formState.subtitle1" type="text" autocomplete="off" :placeholder="$t('viral_level_description_placeholder')"/>
            </a-form-item>
            <a-form-item name="button_name">
              <div class="button-share">
                <a-input v-model:value="formState.shareButton1" type="text" class="edit-text" :placeholder="$t('viral_share_button_placeholder')" name="shareButton1"/>
              </div>
            </a-form-item>
          </a-card>
          <a-card class="border-radio-card card-content-viral row-2" :title="'1. '+ $t('viral_level_2')"
                  head-style="text-align:center">
            <div class="content-center-upload">
              <div
                  @click="showImageSelectionModal('2')"
                  class="ant-upload ant-upload-select ant-upload-select-picture-card"
                  style="width: 60%;aspect-ratio: 4 / 3">
              <span role="button" tabindex="0" class="ant-upload">
                 <img
                     v-if="formState.imageSecond"
                     style="width: 100%; aspect-ratio: 4 / 3;object-fit: contain;"
                     :src="formState.imageSecond"
                 />
                <input type="file" accept="" style="display: none;">
                <div>
                 <plus-outlined/>
                  <div
                      class="ant-upload-text">Upload</div>
                </div>
              </span>
              </div>
              <div class="note-image">Products, banner, campaign image</div>
            </div>
            <div style="height: 16px;"></div>
            <a-form-item has-feedback :wrapper-col="{ span: 15 }" :label-col="{ span: 7 }" :label="$t('viral_level_2')" name="title2"
                         class="row1-viral">
              <a-input  v-model:value="formState.title2" type="text" autocomplete="off" :placeholder="$t('viral_level_title_placeholder')"/>
            </a-form-item>
            <a-form-item has-feedback :wrapper-col="{ span: 15}" :label-col="{ span: 7 }" :label="$t('viral_level_description')"
                         name="subtitle2"
                         class="row1-viral">
              <a-textarea  v-model:value="formState.subtitle2" type="text" autocomplete="off" :placeholder="$t('viral_level_description_placeholder')"/>
            </a-form-item>
            <a-form-item name="button_name">
              <div class="button-share">
                <a-input v-model:value="formState.shareButton2" type="text" class="edit-text" :placeholder="$t('viral_share_button_placeholder')" name="shareButton2"/>
              </div>
            </a-form-item>
          </a-card>
        </div>
        <a-card class="border-radio-card card-content-viral">
          <a-form
              :label-col="labelCol2"
              :wrapper-col="wrapperCol2"
          >
<!--          <div class="row-viral">-->
            <a-form-item has-feedback :label="$t('viral_share_count')" name="shareNumber" class="row1-viral">
              <a-input v-model:value="formState.shareNumber" type="text" autocomplete="off"/>
            </a-form-item>
            <a-form-item has-feedback :label="$t('viral_message_after_phone')" name="messageAfterPhone" class="row2-viral">
              <a class="select_text" @click="phoneInsertRefNumber" >{{ $t('viral_ref_name') }}</a><span> - </span><a class="select_text" @click="phoneInsertRefCount">{{ $t('viral_ref_count') }}</a>
              <a-textarea v-model:value="formState.messageAfterPhone" type="text" autocomplete="off" :placeholder="$t('viral_message_after_phone_placeholder')"/>
            </a-form-item>
<!--            <a-form-item has-feedback :label="$t('viral_message_after_phone')" name="messageAfterInvite" class="row2-viral">-->
<!--              <a class="select_text" @click="successInsertRefNumber" >{{ $t('viral_message_after_success') }}</a><span> - </span><a class="select_text" @click="successInsertRefNumber">{{ $t('viral_ref_count') }}</a>-->
<!--              <a-textarea v-model:value="formState.messageAfterInvite" type="text" autocomplete="off" :placeholder="$t('viral_message_after_success_placeholder')"/>-->
<!--            </a-form-item>-->
            <a-form-item has-feedback :label="$t('viral_message_after_done')" name="messageAfterComplete" class="row2-viral">
              <a class="select_text" @click="successInsertRefNumber" >{{ $t('viral_ref_name') }}</a>
              <a-textarea v-model:value="formState.messageAfterComplete" type="text" autocomplete="off" :placeholder="$t('viral_message_after_done_placeholder')"/>
            </a-form-item>
            <a-form-item :label="$t('viral_status')">
              <a-switch v-model:checked="formState.turnON" />
            </a-form-item>
<!--          </div>-->
          </a-form>
        </a-card>
      </a-form>
    </a-col>
    <a-col :span="6" class="preview-col">
      <div class="btn-publish-container">
        <div style="flex:1"></div>
        <a-button type="primary" @click="onSubmit">
          <save-outlined/>
          Save
        </a-button>
      </div>
      <div class="preview-contain">
        <h3 class="title-flow-context">
          Preview
        </h3>
        <div class="preview-container-body">
          <div class="content-message">
            <a-image
                v-if="formState.imageFirst"
                width="100%"
                style="object-fit: cover;"
                height="180px"
                :src="formState.imageFirst"
            />
            <h2>{{formState.title1}}</h2>
            <p style="white-space: pre-line">{{formState.subtitle1}}</p>
            <div class="cover-btn">
              <a-button class="button-share">{{formState.shareButton1}}</a-button>
            </div>
          </div>
          <div class="content-message" style="margin-top: 20px">
            <a-image
                v-if="formState.imageSecond"
                width="100%"
                style="object-fit: cover;"
                height="180px"
                :src="formState.imageSecond"
            />
            <h2>{{ formState.title2 }}</h2>
            <p style="white-space: pre-line">{{ formState.subtitle2 }}</p>
            <div class="cover-btn">
              <a-button class="button-share">{{formState.shareButton2}}</a-button>
            </div>
          </div>

        </div>
      </div>
    </a-col>
  </a-row>
  <image-selection-modal ref="imageSelectionRef" :onSelect="onImageSelect"/>
  </a-modal>
</template>

<script lang="ts">
import {PlusOutlined, SaveOutlined} from "@ant-design/icons-vue";
import {defineComponent, onBeforeMount, reactive, Ref, ref, UnwrapRef} from "vue";
import {AWS_DO_Model} from "@/models/AWS_DO/AWS_DO";
import ImageSelectionModal from "@/components/Image/ImageSelectionModal.vue";
import {FormViral} from "@/models/Viral/ViralForm";
import {FlowContext, FlowContextData} from "@/models/Flows/FlowContext";
import {MixUtils} from "@/utils/Utils";
import APIs from "@/APIs/APIs";
import {CreateFlowRequest} from "@/APIs/request/FlowRequest";
import {FormFlowInformationFunction} from "@/models/Flows/FlowForm";
import {FlowContextType, FlowStatus, FlowType} from "@/models/Flows/Flow";
import {mapActions, mapGetters} from "vuex";
import {FlowActionTypes} from "@/store/modules/flow/action-types";
import {TagsActionTypes} from "@/store/modules/tag/action-types";
import {useStore} from "@/store";
import {S_EventBus} from "@/utils/EventBus";
import {notification} from "ant-design-vue";
import {Tag} from "@/models/Tags/Tags";
import i18n from '@/i18n'
import {useI18n} from "vue-i18n";

export default defineComponent({
  name: "ViralModal",
  components: {
    PlusOutlined,
    SaveOutlined,
    ImageSelectionModal
  },
  props: ['viralUpdated'],
  computed: {
    ...mapGetters({
      getCurrentShop: 'getCurrentShop',
      getFlowTags: 'getFlowTags',
    }),
  },
  setup(props) {
    const imageSelectionRef = ref()
    const flowContextData: Ref<UnwrapRef<FlowContextData>> = ref({context: {flow_contexts: []}})

    const visible = ref(false)
    const flowId = ref()
    const formRef = ref()
    const i18n = useI18n();


    const formState: UnwrapRef<FormViral> = reactive({
      name: '',
      description: '',
      imageFirst: '',
      title1: '',
      subtitle1: '',
      title2: '',
      subtitle2: '',
      shareButton1: '',
      shareButton2: '',
      imageSecond: '',
      shareNumber: 5,
      messageAfterPhone: i18n.t('viral_message_after_phone_placeholder'),
      messageAfterInvite: '',
      messageAfterComplete: i18n.t('viral_message_after_done_placeholder'),
      turnON: true,
      tags: []
    });
    const store = useStore()
    const flowTags = ref<Tag[]>([])
    const tags = store.getters.getViralTags;
    if (tags) {
      flowTags.value = tags
    }
    const rules = {
      name: [
        {required: true, message: 'Please input viral name', trigger: 'blur'},
      ],
      title1: [{required: true, message: 'Please input title', trigger: 'blur'}],
      subtitle1: [{required: true, message: 'Please input sub-title', trigger: 'blur',}],
      title2: [{required: true, message: 'Please input title', trigger: 'blur'}],
      subtitle2: [{required: true, message: 'Please input sub-title', trigger: 'blur',}],
      btn_name: [{required: true, message: 'Please input button name', trigger: 'blur',}],
    };
    const onImageSelect = (images: AWS_DO_Model[], param: string) => {
      if ((param + "") === "1") {
        console.log(images[0].url)
        formState.imageFirst = images[0].url
      }else {
        console.log("1")
        formState.imageSecond = images[0].url
      }
    }
    const showImageSelectionModal = (param: string) => {
      imageSelectionRef.value.showModal(param)
    }
    const onSubmit = async () => {
      formRef.value
          .validate()
          .then(() => {
            let flow2: FlowContext = {
              "id": MixUtils.methods.getUUID(),
              "name": formState.title2,
              "shop_id": 0,
              "flow_type": 1,
              "message": formState.subtitle2,
              "attachment_images": formState.imageSecond?[formState.imageSecond]:[],
              "type": "",
              "messenger_tag": "",
              "template_id": "",
              "template_name": "",
              "template_status": "",
              "preview_url": "",
              "created_at": new Date().toISOString(),
              "updated_at": new Date().toISOString(),
              "action_send_success_context": {
                "flow_crm_actions": [],
                "flow_action_stack": {}
              },
              "action_send_failed_context": {
                "flow_crm_actions": [],
                "flow_action_stack": {}
              },
              "action_default_context": {
                "flow_crm_actions": [],
                "flow_action_stack": {}
              },
              "postback_contexts": [
                {
                  "id": MixUtils.methods.getUUID(),
                  "type": "web_url",
                  "payload": "",
                  "title": formState.shareButton2,
                  "url": "",
                  "flow_id": 0,
                  "reply_message": "",
                  "reply_images": [],
                  "created_at": "0001-01-01T00:00:00Z",
                  "updated_at": "0001-01-01T00:00:00Z",
                  "flow_actions_context": {
                    "id": MixUtils.methods.getUUID(),
                    "type": FlowContextType.FACEBOOK,
                    "usage_id": "",
                    "created_at": "0001-01-01T00:00:00Z",
                    "updated_at": "0001-01-01T00:00:00Z",
                    "flow_crm_actions": [],
                    "flow_action_stack": {
                      "id": 0,
                      "flow_id": 0,
                      "flow_action_id": 0,
                      "created_at": "0001-01-01T00:00:00Z",
                      "updated_at": "0001-01-01T00:00:00Z"
                    }
                  }
                }
              ],
            }

            let flow1: FlowContext = {
              "id": MixUtils.methods.getUUID(),
              "name": formState.title1,
              "shop_id": 0,
              "flow_type": 1,
              "message": formState.subtitle1,
              "attachment_images": formState.imageFirst?[formState.imageFirst]:[],
              "type": "",
              "messenger_tag": "",
              "template_id": "",
              "template_name": "",
              "template_status": "",
              "preview_url": "",
              "created_at": new Date().toISOString(),
              "updated_at": new Date().toISOString(),
              "postback_contexts": [
                {
                  "id": MixUtils.methods.getUUID(),
                  "type": "web_url",
                  "payload": "",
                  "title": formState.shareButton1,
                  "url": "",
                  "flow_id": 0,
                  "reply_message": "",
                  "reply_images": [],
                  "created_at": "0001-01-01T00:00:00Z",
                  "updated_at": "0001-01-01T00:00:00Z",
                  "flow_actions_context": {
                    "id": MixUtils.methods.getUUID(),
                    "type": FlowContextType.FACEBOOK,
                    "usage_id": flow2.id,
                    "created_at": "0001-01-01T00:00:00Z",
                    "updated_at": "0001-01-01T00:00:00Z",
                    "flow_crm_actions": [],
                    "flow_action_stack": {
                      "id": 0,
                      "flow_id": 0,
                      "flow_action_id": 0,
                      "created_at": "0001-01-01T00:00:00Z",
                      "updated_at": "0001-01-01T00:00:00Z"
                    }
                  }
                }
              ],
              "action_send_success_context": {
                "flow_crm_actions": [],
                "flow_action_stack": {}
              },
              "action_send_failed_context": {
                "flow_crm_actions": [],
                "flow_action_stack": {}
              },
              "action_default_context": {
                "flow_crm_actions": [],
                "flow_action_stack": {}
              }
            }
            flowContextData.value.name = formState.name
            flowContextData.value.description = formState.description
            flowContextData.value.context.flow_contexts = [flow1, flow2]
            flowContextData.value.flow_type = FlowType.Viral
            flowContextData.value.status = formState.turnON ? FlowStatus.Publish : FlowStatus.Draft
            flowContextData.value.tags = flowTags.value.filter(tag=>formState.tags?.find(tagId=>tag.id===tagId))
            flowContextData.value.context.total_people = Number(formState.shareNumber),
            flowContextData.value.context.messenger_after_phone  = formState.messageAfterPhone
            flowContextData.value.context.messenger_after_success =  formState.messageAfterInvite
            flowContextData.value.context.messenger_after_complete = formState.messageAfterComplete

            if (flowId.value === 0 || flowId.value === null || flowId.value === undefined) {
              APIs.init().createFlow({
                name: formState.name,
                flow_type: flowContextData.value.flow_type,
                description: formState.description
              })
                  .then(res => {
                    console.log(res)
                    // nextLoading.value = false
                    if (res.status === 200 && res.data?.data) {
                      // props.createRootFlow(res.data?.data)
                      flowContextData.value.id = res.data?.data.id
                      APIs.init().updateFlowV2Contexts(flowContextData.value).then(res => {
                        if (res.status === 200 && res.data?.data) {
                          notification.success({
                            duration: 2,
                            message: 'Information',
                            description:
                                `Viral ${res.data?.data.name} created.`,
                            onClick: () => {
                              console.log('Notification Clicked!');
                            },
                          });
                          props.viralUpdated()
                          visible.value = false
                        }
                      }).catch(e => {
                        console.error("update flow context", e)
                      })
                    }
                  }).catch(e => {
                console.error(e)
              })
            } else {
              APIs.init().updateFlowV2Contexts(flowContextData.value).then(res => {
                if (res.status === 200 && res.data?.data) {
                  notification.success({
                    duration: 2,
                    message: 'Information',
                    description:
                        `Viral ${res.data?.data.name} updated.`,
                    onClick: () => {
                      console.log('Notification Clicked!');
                    },
                  });
                  props.viralUpdated()
                  visible.value = false
                }
              }).catch(e => {
                console.error("update flow context", e)
              })
            }
          })
    }
    const openModal = (Id: number) => {
      flowId.value = Id
      // resetForm
      formState.name = ""
      formState.description = ""
      formState.imageFirst = ""
      formState.title1 = ""
      formState.subtitle1 = ""
      formState.title2 = ""
      formState.subtitle2 = ""
      formState.shareButton1 = "Join"
      formState.shareButton2 = "Join"
      formState.imageSecond = ""
      formState.shareNumber = 3
      formState.messageAfterInvite = ""
      formState.messageAfterPhone=  i18n.t('viral_message_after_phone_placeholder')
      formState.messageAfterComplete= i18n.t('viral_message_after_done_placeholder')
      formState.turnON = true
      visible.value = true
      if(Id !==0 ){
        APIs.init().getFlowV2Context({id: Id}).then(res => {
          if (res.status === 200 && res.data?.data) {
            flowContextData.value = res.data?.data
            console.log(res)
            try{
              const flow1 = res.data?.data.context.flow_contexts[0]
              const flow2 = res.data?.data.context.flow_contexts[1]
              formState.name = res.data?.data.name
              formState.description = res.data?.data.description
              formState.imageFirst = flow1?.attachment_images?.length?flow1?.attachment_images[0]:''
              formState.title1 = flow1?.name
              formState.subtitle1 = flow1?.message
              formState.title2 = flow2?.name
              formState.subtitle2 = flow2?.message
              formState.shareButton1 = flow1?.postback_contexts?.[0].title
              formState.shareButton2 =  flow2?.postback_contexts?.[0]?.title
              formState.imageSecond = flow2?.attachment_images?.length?flow2?.attachment_images[0]:''
              formState.shareNumber = flowContextData.value.context.total_people
              formState.messageAfterPhone = flowContextData.value.context.messenger_after_phone
              formState.messageAfterInvite = flowContextData.value.context.messenger_after_success
              formState.messageAfterComplete = flowContextData.value.context.messenger_after_complete
              formState.turnON= res.data?.data.status === FlowStatus.Publish
              const tag= res.data?.data.tags?.map((tag:Tag):number=>{
                if (tag&&tag.id){
                  return tag.id
                }else {
                  return 0
                }
              })
              if (tag){
                formState.tags=tag
              }
              console.log('tags', res.data?.data.tags)
            }catch (e) {
              console.log(e)
            }
          }
        }).catch(e => {
          console.error("update flow context", e)
        })

      }
    }
    const tagSelectionChange = (value: Tag) => {
      // formState.tags = flowTags.
    }

    const labelCol = { style: { width: '150px' } }
    const wrapperCol = { span: 16 }
    const labelCol2 = { style: { width: '50%' } }
    const wrapperCol2 = { span: 16 }

    const phoneInsertRefNumber = ()=>{
      formState.messageAfterPhone+=" ##ref_name## "
    }
    const phoneInsertRefCount = ()=>{
      formState.messageAfterPhone+=" ##ref_count## "
    }

    const successInsertRefNumber = ()=>{
      formState.messageAfterComplete+=" ##ref_name## "
    }
    const successInsertRefCount = ()=>{
      formState.messageAfterInvite+=" ##ref_count## "
    }


    return {
      rules,
      onImageSelect,
      imageSelectionRef,
      visible,
      showImageSelectionModal,
      formState,
      onSubmit,
      openModal,
      formRef,
      flowContextData,
      flowTags,
      tagSelectionChange,
      labelCol,
      wrapperCol,
      labelCol2,
      wrapperCol2,
      phoneInsertRefNumber,
      phoneInsertRefCount,
      successInsertRefNumber,
      successInsertRefCount,
    }
  }
})

</script>

<style scoped lang="scss">
.container-viral {

}

.border-radio-card {
  border-radius: 10px;
}

.row-viral {
  display: flex;
  flex-direction: row;

  .row1-viral {
    flex: 0.3;
  }

  .row2-viral {
    flex: 0.7;
    margin-left: 20px;
  }

  .card-content-viral {
    margin-top: 20px;
    width: 100%;
    display: flex;
    flex-direction: column;

    .content-center-upload {
      display: flex;
      flex-direction: column;
      align-items: center;

      .note-image {
        font-size: 12px;
        font-style: italic;
      }
    }

    .button-share {
      display: flex;
      justify-content: center;

      .edit-text {
        padding: 6px 10px;
        text-align: center;
        border: 1px dashed #e5e7eb;
        width: 40%;
      }
    }
  }

  .row-2 {
    margin-left: 10px;
  }


}

.preview-col {

  padding-left: 15px;

  .btn-publish-container {
    display: flex;
    margin-top: 10px;
  }

  .preview-contain {
    .title-flow-context {
      text-align: center;
    }

    .preview-container-body {
      background-color: white;
      min-height: 480px;
      border-radius: 10px;
      padding: 15px;
    }

    .content-message {
      margin-right: 20px;
      padding: 15px;
      background-color: #eef1f4;
      border-top-left-radius: 15px;
      border-top-right-radius: 15px;
      border-bottom-right-radius: 15px;
      display: flex;
      flex-direction: column;

      .cover-btn {
        display: flex;
        justify-content: center;

        .button-share {
          width: 150px;
        }
      }

    }
  }

}


</style>
