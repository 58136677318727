<template>
  <div v-if="!(receiptData.id && receiptData.id!==0)" class="no-order">
    Thông tin đơn hàng không đúng
  </div>
  <div v-if="receiptData.id && receiptData.id!==0" class="receipt">
    <div class="orderNo">
      {{receiptData.shop_name}}
    </div>
    <div class="address">
      {{receiptData.stock_address}}
    </div>
    <div class="phone">
      {{receiptData.shop_tel}}
    </div>
    <div class="headerOrder">
      ĐƠN HÀNG
    </div>
    <div class="barcode">
<!--      <img class="img-barcode" :src="`https://barcode.tec-it.com/barcode.ashx?data=${receiptData.order_number}&code=Code128`"/>-->
      <vue-barcode class="img-barcode" :value="receiptData.order_number"
      ></vue-barcode>
    </div>
    <div class="created_date">
      Ngày đặt hàng: {{dateTimeDisplayFormatFromString(receiptData.created_at)}}
    </div>
    <!--    Thông tin khách hàng-->
    <hr class="dashed">
      <div class="customer-info">
        Khách hàng: <strong>{{receiptData.shipping_name}}</strong>
      </div>
      <div class="customer-info">
        Điện thoại: <strong>{{receiptData.shipping_phone}}</strong>
      </div>
      <div class="customer-info">
        Địa chỉ: <strong>{{receiptData.shipping_address}}</strong>
      </div>
    <hr class="dashed">
      <div class="customer-info">
        Phương thức thanh toán: <strong>{{receiptData.payment_method===1?'Thanh toán khi giao hàng (COD)':''}}</strong>
      </div>
      <div class="customer-info">
        Phương thức vận chuyển: <strong>{{receiptData.shipping_method}}</strong>
      </div>
    <!-- Items Purchased -->
    <hr class="dashed">
      <div class="row">
        <div class="product">
          <div class="text-normal"><strong>Sản phẩm</strong></div>
        </div>
        <div class="price"><strong>Thành tiền</strong></div>
      </div>
      <template v-for="product in (receiptData.products?receiptData.products:[])" :key="product.id+''">
        <div class="row" >
          <div class="product">
            <div class="text-normal">{{product.quantity}} x {{product.product_name}}</div>
            <div class="text-normal">{{formatMoney(product.unit_price)}}đ</div>
          </div>
          <div class="price">{{formatMoney(product.unit_price*product.quantity)}}đ</div>
        </div>
      </template>
    <hr class="dashed">
      <div class="row">
        <div class="label-total">
          <div class="text-normal">Tổng tiền hàng:</div>
        </div>
        <div class="price">{{formatMoney(receiptData.total_amount)}}đ</div>
      </div>
      <div class="row">
        <div class="label-total">
          <div class="text-normal">Phí vận chuyển:</div>
        </div>
        <div class="price">{{formatMoney(receiptData.ship_fee)}}đ</div>
      </div>
      <div v-if="receiptData.discount>0" class="row">
        <div class="label-total">
          <div class="text-normal">Giảm giá:</div>
        </div>
        <div class="price">{{formatMoney(receiptData.discount)}}đ</div>
      </div>
      <div class="row">
        <div class="label-total">
          <div class="text-normal"><strong>Tổng tiền:</strong></div>
        </div>
        <div class="price"><strong>{{formatMoney(receiptData.total_amount+receiptData.ship_fee-receiptData.discount)}}đ</strong></div>
      </div>
      <div class="row">
        <div class="label-total">
          <div class="text-normal"><strong>Số tiền đã trả:</strong></div>
        </div>
        <div class="price"><strong>{{formatMoney(receiptData.prepaid)}}đ</strong></div>
      </div>
      <div class="row">
        <div class="label-total">
          <div class="text-normal"><strong>Số tiền phải trả:</strong></div>
        </div>
        <div class="price"><strong>{{formatMoney(receiptData.total_amount+receiptData.ship_fee-receiptData.discount-receiptData.prepaid)}}đ</strong></div>
      </div>
    <hr class="dashed">
      <div class="footer">
        Nếu bạn có thắc mắc, vui lòng liên hệ với chúng tôi qua sđt {{receiptData.shop_tel}}
      </div>
  </div>
</template>

<script >
import {computed, defineComponent, ref} from "vue";
import apisauce from "apisauce";
import {MixUtils} from "@/utils/Utils";
import {useRoute} from "vue-router/dist/vue-router";

const api = apisauce.create({
    baseURL: "https://api.shopf1.net/flow-api/",
    timeout: 50000,
    headers: {
      "Content-Type": "application/json",
    },
  })

export default defineComponent({
  name: "OrderPreview",
  components: {
  },
  created() {
    try{
      if(this.$route.query.order_number){
        // console.log('params', this.$route.params.id)
        // this.fetchOrder(this.$route.params.id)

        this.loadData({
          order_number: this.$route.query.order_number,
          token: this.$route.query.token
        }, null)
      }
    }catch (e) {
      console.log(e)
    }
  },
  setup() {
    const receiptData = ref({})
    const route = useRoute()


    const loadData = async (params)=>{
      const response = await api.get("receipt", params, {headers: {
        }})
      console.log(response)
      if(response.data?.data){
        receiptData.value = response.data?.data
        receiptData.value.total_amount = response.data?.data?.products.map(product=>product.unit_price*product.quantity).reduce((accumulator, currentValue) => (accumulator + currentValue),0)
        window.onafterprint = () => {
          window.close();
        }
        if(route.query.print){
          setTimeout(()=>{
            window.print()
          }, 500)


        }
      }else{
        receiptData.value = {}
      }
    }

    const dateTimeDisplayFormatFromString = (time)=>{
      return MixUtils.methods.dateTimeDisplayFormatFromString(time)
    }
    const formatMoney = (money)=>{
      return MixUtils.methods.formatMoney(money)
    }



    return {
      dateTimeDisplayFormatFromString,
      formatMoney,
      loadData,
      receiptData,
    }
  }
})
</script>

<style scoped lang="css">

body {
  font-family: 'Roboto', sans-serif;
  width: 80mm;
  margin: 0;
  padding: 0;
  font-size: 8pt;
}

.receipt {
  padding: 3mm;
  width: 80mm;
  font-size: 9pt;
}
.orderNo {
  width: 100%;
  text-align: center;
  padding-bottom: 1mm;
  font-size: 14pt;
  font-weight: bold;
}

.orderNo:empty {
  display: none;
}

.itemRow > div {
  align-items: baseline;
}

.totals .row {
  display: flex;
  text-align: right;
}

.totals .section {
  padding-top: 2mm;
}

.totalRow > div, .total > div {
  text-align: right;
  align-items: baseline;
  font-size: 8pt;
}

.totals .col1 {
  text-align: right;
  flex-grow: 1;
}

.totals .col2 {
  width: 22mm;
}

.totals .col2:empty {
  display: none;
}

.totals .col3 {
  width: 15mm;
}

.arrow_box h3, ul {
  margin: 5px;
}

.about li {
  text-align: left;
}
.address{
  font-weight: normal;
  text-align: center;
}
.phone{
  font-weight: normal;
  text-align: center;
}
.headerOrder{
  font-size: 10pt;
  font-weight: bold;
  text-align: center;
}
.created_date{
  font-weight: normal;
  text-align: center;
}
.customer-info{
  font-weight: normal;
}
.footer{
  font-weight: normal;
  font-style: italic;
}
.barcode{
  display: flex;
  justify-content: center;
}
.img-barcode{
  height: 16mm;
}
.dashed{
  border-top: 1px dashed;
}
.row{
  display: flex;
  flex-direction: row;
  align-items: center;
}
.product {
  flex: 3;
  text-align: left;
  font-weight: normal;
}
.price {
  flex: 1;
  font-weight: normal;
  text-align: right;
}
.text-normal {
  font-weight: normal;
}
.label-total {
  flex: 3;
  text-align: left;
  font-weight: normal;
}
.no-order {
  padding: 16px 0 0 16px;
  text-align: center;
}

</style>
