<template>
  <div class="main-flow" id="main-flow">
    <a-form ref="formRef" :rules="rules" :model="formState" layout="vertical">
      <a-form-item ref="name" :label="$t('FLOW_MESSAGE_NAME')" name="name">
        <a-input
            :placeholder="$t('FLOW_MESSAGE_NAME')"
            @blur="onTextChange"
            v-model:value="formState.name"
        />
      </a-form-item>

      <a-form-item :label="$t('FLOW_MESSAGE_TYPE')" name="message_tag">
        <a-select
            ref="select"
            v-model:value="formState.message_tag"
            @change="onTextChange"
            :placeholder="$t('FLOW_MESSAGE_TYPE')"
        >
          <a-select-option
              v-for="mess in messengerTags"
              :key="mess"
              :value="mess.label"
          >{{ $t(mess.value) }}
          </a-select-option
          >
        </a-select>
      </a-form-item>
      <Container
          orientation="vertical"
          group-name="col-items"
          :get-child-payload="getPayload1"
          :drop-placeholder="{
          className: `drop-placeholder`,
          animationDuration: '200',
          showOnTop: true,
        }"
          :non-drag-area-selector="'.drag-disabled'"
          drag-class="
            transition duration-100 ease-in z-50
            transform rotate-6 scale-110"
          drop-class="transition duration-100
            ease-in z-50 transform
            -rotate-2 scale-90"
          @drop="(e) => onCardDrop1(e)"
      >
        <!-- Items -->
        <Draggable
            v-for="(message, msIndex) in messageContexts"
            :key="message.id"
        >
          <a-card class="card-message" v-if="message.type === 'generic_template'">
            <template #title>
              <drag-outlined style="cursor: grab; margin-right: 15px"/>
              <span>{{$t('message_genetic_template')}}</span>
            </template>
            <template #extra>
              <a-button type="text" @click="deleteMessage(msIndex)">
                <close-outlined/>
              </a-button>
            </template>
            <div>
              <Carousel
                  :settings="settings"
                  v-model="currentSlide"
                  :breakpoints="breakpoints"
                  class="drag-disabled"
                  :key="genericCountUpdate"


              >
                <Slide
                    v-for="(temp, tempIndex) in facebookTemplate"
                    :key="temp.id"
                >
                  <a-card
                      :hoverable="true"
                      @click.self="swipeCarousel(msIndex, tempIndex)"
                      size="small"
                      :class="[
                      'card-facebook-template',
                     tempIndex === currentSlide
                        ? 'card-facebook-template-active'
                        : '',
                    ]"
                  >
                    <template #cover>
                      <div>
                        <img
                            class="img-cover-facebook-template"
                            alt="example"
                            v-if="temp.image_url"
                            :src="temp.image_url"
                            @click="
                            showImageSingleSelectionModal(
                              msIndex + '_' + tempIndex
                            )
                          "
                        />
                        <span
                            class="ant-upload-picture-card-wrapper upload-center"
                            v-else
                        >
                          <div
                              class="ant-upload ant-upload-select ant-upload-select-picture-card"
                              @click="
                              showImageSingleSelectionModal(
                                msIndex + '_' + tempIndex
                              )
                            "
                          >
                            <span role="button" tabindex="0" class="ant-upload">
                              <div>
                                <plus-outlined/>
                                <div class="ant-upload-text">
                                  {{ $t("UPLOAD") }}
                                </div>
                              </div>
                            </span>
                          </div>
                        </span>
                      </div>
                    </template>
                    <a-card-meta>

                        <template #title>

                          <a-input
                              showCount
                              :maxlength="40"
                              :placeholder="$t('FLOW_FACEBOOK_BUTTON_NAME_2')"
                              v-model:value="temp.title"
                              :style="{'border-color': !temp.title ? 'red' : '#d9d9d9'}"

                              @blur="
                            (val) =>
                              templateTitleChange(msIndex, tempIndex, val)
                          "
                          />

                        </template>

                        <template #description>
                          <a-form-item>
<!--                            <span>-->
<!--                        {{message.facebook_templates}}-->
<!--                      </span>-->
                            <a-textarea
                                showCount
                                :maxlength="58"
                                :placeholder="$t('DESCRIPTION_2')"
                                v-model:value="temp.subtitle"
                                :class="[!temp.subtitle && 'error']"
                                @blur="
                              (val) =>
                                templateSubtitleChange(msIndex, tempIndex, val)
                            "
                                @click="forcusMessagetext"
                            />
                            <a-tag
                                @click="templateMessageFullName(msIndex, tempIndex)"
                                style="cursor: pointer;margin-top: 20px"
                            >##full_name##
                            </a-tag

                            >
                            <a-tag
                                @click="templateMessagePageName(msIndex, tempIndex)"
                                style="cursor: pointer;margin-top: 20px"
                            >##page_name##
                            </a-tag

                            >
                            <a-dropdown>
                              <a-tag class="ant-dropdown-link" @click.prevent>
                                {{$t('READ_MORE')}}
                                <DownOutlined />
                              </a-tag>
                              <template #overlay>
                                <a-menu>
                                  <a-menu-item @click="templatemessageColumn(it.identified_name,msIndex,tempIndex)" v-for="it in getColumnsConfig" :key="it.id" >
                                    {{it.name}}
                                  </a-menu-item>
                                  <a-menu-item  @click="templatemessageColumn(i.id,msIndex,tempIndex)"  v-for="i in getColumn" :key="i.id" >
                                    {{$t(i.text)}}
                                  </a-menu-item>
                                </a-menu>
                              </template>
                            </a-dropdown>
                            <a-dropdown>
                              <a-tag class="ant-dropdown-link" @click.prevent>
                                {{$t('ORDER')}}
                                <DownOutlined />
                              </a-tag>
                              <template #overlay>
                                <a-menu>
                                  <a-menu-item @click="messageColumn(i.id,msIndex,'notification')" v-for="i in getOrderColumn" :key="i.id" >
                                    {{$t(i.text)}}
                                  </a-menu-item>

                                </a-menu>
                              </template>
                            </a-dropdown>
                          </a-form-item>
                          <a-input
                              :placeholder="$t('URL_WHEN_USER_CLICK')"
                              v-model:value="temp.default_action.url"
                              @change="
                            (val) => templateUrlChange(msIndex, tempIndex, val)
                          "
                          />
                        </template>
                    </a-card-meta>
                    <div class="post-back-button" :key="genericCountUpdate">
                      <template v-if="temp.buttons">
                        <facebook-postback-button
                            v-for="(button, btnIndex) in temp.buttons"
                            :key="button.id"
                            :postbackContext="button"
                            :flowContextData="flowContextData"
                            :deletePostBackButton="
                              () =>
                                deleteTemplatePostbackButton(
                                  msIndex,
                                  tempIndex,
                                  btnIndex
                                )
                            "
                            :updatePostBackButton="
                              (data) =>
                                updateTemplatePostBackButton(
                                  data,
                                  msIndex,
                                  tempIndex,
                                  btnIndex
                                )
                            "
                        />
                      </template>

                      <a-button
                          type="dashed"
                          class="button-post-back"
                          v-if="!temp.buttons || temp.buttons.length < 3"
                          @click="addTemplatePostBackButton(msIndex, tempIndex)"
                      >
                        <plus-outlined/>
                        {{ $t("FLOW_ADD_BUTTONS") }}
                      </a-button>
                    </div>
                  </a-card>
                </Slide>

                <template #addons>
                  <Navigation/>
                  <div class="slick-dot-bar">
                    <div
                        v-for="(temp, temIndex) in message.facebook_templates"
                        :key="temp.id"
                        class="slick-dot-facebook-template"
                    >
                      <a-badge>
                        <template #count v-if="message.facebook_templates.length > 1">
                          <close-circle-outlined
                              @click="deleteFacebookTemplate(msIndex, temIndex)"
                              :class="['remove-icon-badge',temIndex === currentSlide  ? 'remove-icon-badge-block' : '']"

                          />
                        </template>
                        <div
                            :class="[
                      'slick-dot-facebook-template-content',
                      'slick-dot-facebook-ava',
                      temIndex === currentSlide
                        ? 'slick-dot-facebook-ava-active'
                        : '',
                    ]"
                            @click="swipeCarousel(msIndex, temIndex)"

                        >
                          <a-avatar
                              shape="square"
                              size="large"
                              v-if="temp.image_url"
                              :src="temp.image_url"
                          >
                          </a-avatar>
                          <a-avatar shape="square" size="large" v-else>
                            <template #icon>
                              <picture-outlined/>
                            </template>
                          </a-avatar>
                        </div>
                      </a-badge>
                    </div>
                    <div class="slick-dot-facebook-template button-add-button">
                      <a-button
                          type="dashed"
                          size="large"
                          @click="addTemplate(msIndex)"
                      >
                        <template #icon>
                          <plus-outlined/>
                        </template>
                      </a-button>
                    </div>
                  </div>
                </template>
              </Carousel>
            </div>

          </a-card>
          <a-card class="card-message" v-else-if="message.type === 'text'">
            <template #title>
<!--              <span>{{message.text}}</span>-->
              <drag-outlined style="cursor: grab; margin-right: 15px"/>
              <span>{{ $t("MESSAGE_TEXT") }}</span>
            </template>
            <template #extra>
              <a-button type="text" @click="deleteMessage(msIndex)">
                <close-outlined/>
              </a-button>
            </template>
            <a-form-item name="message_content">
              <a-textarea

                  :placeholder="
                  $t(
                    'FLOW_FACEBOOK_BUTTON_POST_BACK_MESSAGE_CONTENT_PLACEHOLDER'
                  )
                "
                  class="drag-disabled "
                  ref="inputMessageContent"
                  showCount
                  :maxlength="
                  message.buttons && message.buttons.length ? 640 : 1200
                "
                  :auto-size="{ minRows: 2, maxRows: 8 }"
                  :draggable="false"
                  v-model:value="message.text"
                  @blur="onTextChange"
                  @click="forcusMessagetext"
              />
              <a-tag @click="messageFullName(msIndex,'messageContent')" style="cursor: pointer"
              >##full_name##
              </a-tag
              >
              <a-tag
                  @click="messagePageName(msIndex, 'messageContent')"
                  style="cursor: pointer;margin-top: 20px"
              >##page_name##
              </a-tag

              >
              <a-dropdown>
                <a-tag class="ant-dropdown-link" @click.prevent>
                  {{$t('READ_MORE')}}
                  <DownOutlined />
                </a-tag>
                <template #overlay>
                  <a-menu>
                    <a-menu-item @click="messageColumn(it.identified_name,msIndex,'messageContent')" v-for="it in getColumnsConfig" :key="it.id" >
                      {{it.name}}
                    </a-menu-item>
                    <a-menu-item  @click="messageColumn(i,msIndex,'messageContent')"  v-for="i in getColumn" :key="i.id" >
                      {{$t(i.text)}}
                    </a-menu-item>
                  </a-menu>
                </template>
              </a-dropdown>
              <a-dropdown>
                <a-tag class="ant-dropdown-link" @click.prevent>
                  {{$t('ORDER')}}
                  <DownOutlined />
                </a-tag>
                <template #overlay>
                  <a-menu>
                    <a-menu-item @click="messageColumn(i.id,msIndex,'messageContent')" v-for="i in getOrderColumn" :key="i.id" >
                      {{$t(i.text)}}
                    </a-menu-item>

                  </a-menu>
                </template>
              </a-dropdown>
            </a-form-item>
            <div class="post-back-button">
              <template v-if="message.buttons">
                <template
                    v-for="(button, btnIndex) in message.buttons"
                    :key="button.id"
                >
                  <facebook-postback-button
                      :postbackContext="button"
                      :flowContextData="flowContextData"
                      :deletePostBackButton="
                      () => deletePostbackButton(msIndex, btnIndex)
                    "
                      :updatePostBackButton="
                      (data) => updatePostBackButton(data, msIndex, btnIndex)
                    "
                  />
                </template>
              </template>

              <a-button
                  type="primary"
                  class="button-post-back"
                  v-if="message.buttons.length < 3"
                  @click="addPostBackButton(msIndex)"
              >
                <plus-outlined/>
                {{ $t("FLOW_ADD_BUTTONS") }}
              </a-button>
            </div>
          </a-card>
          <a-card class="card-message" v-else-if="message.type === 'image'">
            <template #title>
              <drag-outlined style="cursor: grab; margin-right: 15px"/>
              <span>{{ $t("MESSAGE_IMAGE") }}</span>
            </template>
            <template #extra>
              <a-button type="text" @click="deleteMessage(msIndex)">
                <close-outlined/>
              </a-button>
            </template>
            <div
                v-if="message.images && message.images.length"
                style="text-align: center"
            >
              <img
                  :src="message.images[0]"
                  class="photo-flow-img"
                  @click="showImageSingleSelectionModal(msIndex + '_' + 909)"
              />
            </div>
            <span class="ant-upload-picture-card-wrapper upload-center" v-else>
              <div
                  class="ant-upload ant-upload-select ant-upload-select-picture-card"
                  @click="showImageSingleSelectionModal(msIndex + '_' + 909)"
              >
                <span role="button" tabindex="0" class="ant-upload">
                  <div>
                    <plus-outlined/>
                    <div class="ant-upload-text">{{ $t("UPLOAD") }}</div>
                  </div>
                </span>
              </div>
            </span>
          </a-card>
          <a-card
              class="card-message"
              v-else-if="message.type === 'quick_replies'"
          >
            <template #title>
              <drag-outlined style="cursor: grab; margin-right: 15px"/>
              <span>{{ $t("message_quick_replies") }}</span>
            </template>
            <template #extra>
              <a-button type="text" @click="deleteMessage(msIndex)">
                <close-outlined/>
              </a-button>
            </template>
            <a-form-item name="message_content">
              <a-input
                  ref="inputQuick"
                  :placeholder="$t('CONTENT_MESSAGE_QUICK_REPLY')"
                  :rows="3"
                  class="drag-disabled"
                  showCount
                  :maxlength="640"
                  v-model:value="message.text"
                  @blur="onTextChange"
                  @click="forcusMessagetext"
              />
              <a-tag style="cursor: pointer" @click="messageFullName(msIndex,'quickMessage')"
              >##full_name##
              </a-tag
              >
              <a-tag
                  @click="messagePageName(msIndex, 'quickMessage')"
                  style="cursor: pointer;margin-top: 20px"
              >##page_name##
              </a-tag

              >
              <a-dropdown>
                <a-tag class="ant-dropdown-link" @click.prevent>
                  {{$t('READ_MORE')}}
                  <DownOutlined />
                </a-tag>
                <template #overlay>
                  <a-menu>
                    <a-menu-item @click="messageColumn(it.identified_name,msIndex,'quickMessage')" v-for="it in getColumnsConfig" :key="it.id" >
                      {{it.name}}
                    </a-menu-item>
                    <a-menu-item  @click="messageColumn(i.id,msIndex,'quickMessage')"  v-for="i in getColumn" :key="i.id" >
                      {{$t(i.text)}}
                    </a-menu-item>

                  </a-menu>
                </template>
              </a-dropdown>
              <a-dropdown>
                <a-tag class="ant-dropdown-link" @click.prevent>
                  {{$t('ORDER')}}
                  <DownOutlined />
                </a-tag>
                <template #overlay>
                  <a-menu>
                    <a-menu-item @click="messageColumn(i.id,msIndex,'quickMessage')" v-for="i in getOrderColumn" :key="i.id" >
                      {{$t(i.text)}}
                    </a-menu-item>

                  </a-menu>
                </template>
              </a-dropdown>
            </a-form-item>
            <div   class="post-back-button">

                <template
                    v-if="message.quick_replies"

                >
                  <facebook-quick-reply-button
                      v-for="(button, btnIndex) in message.quick_replies"
                      :postbackContext="button"
                      :flowContextData="flowContextData"
                      :key="button.id"
                      :deletePostBackButton="
                      () => deleteQuickReplyButton(msIndex, btnIndex)
                    "
                      :updatePostBackButton="
                      (data) => updateQuickReplyButton(data, msIndex, btnIndex)
                    "
                  />
                </template>
              <a-button
                  type="dashed"
                  class="button-post-back button-quick-reply"
                  v-if="
                  !message.quick_replies || message.quick_replies.length < 14
                "
                  @click="addQuickReplyButton(msIndex)"
              >
                <plus-outlined/>
                {{ $t("FLOW_ADD_BUTTONS") }}
              </a-button>
            </div>
          </a-card>
          <a-card
              class="card-message"
              v-else-if="message.type === 'onetime_notification'"
          >
            <template #title>
              <drag-outlined style="cursor: grab; margin-right: 15px"/>
              <span>{{ $t("MESSAGE_ONETIME_NOTIFICATION") }}</span>
            </template>
            <template #extra>
              <a-button type="text" @click="deleteMessage(msIndex)">
                <close-outlined/>
              </a-button>
            </template>
            <a-form-item name="message_content">
              <a-input
                  ref="inputNotification"
                  :placeholder="$t('ONETIME_NOTIFICATION_PLACEHOLDER')"
                  :rows="3"
                  class="drag-disabled"
                  showCount
                  :maxlength="640"
                  v-model:value="message.text"
                  @blur="onTextChange"
                  @click="forcusMessagetext"
              />
              <a-tag  style="cursor: pointer" @click="messageFullName(msIndex,'notification')"
              >##full_name##
              </a-tag
              >
              <a-tag
                  @click="messagePageName(msIndex, 'notification')"
                  style="cursor: pointer;margin-top: 20px"
              >##page_name##
              </a-tag

              >
              <a-dropdown>
                <a-tag class="ant-dropdown-link" @click.prevent>
                  {{$t('READ_MORE')}}
                  <DownOutlined />
                </a-tag>
                <template #overlay>
                  <a-menu>
                    <a-menu-item @click="messageColumn(it.identified_name,msIndex,'notification')" v-for="it in getColumnsConfig" :key="it.id" >
                      {{it.name}}
                    </a-menu-item>
                    <a-menu-item  @click="messageColumn(i.id,msIndex,'notification')"  v-for="i in getColumn" :key="i.id" >
                      {{$t(i.text)}}
                    </a-menu-item>
                  </a-menu>
                </template>
              </a-dropdown>
              <a-dropdown>
                <a-tag class="ant-dropdown-link" @click.prevent>
                  {{$t('ORDER')}}
                  <DownOutlined />
                </a-tag>
                <template #overlay>
                  <a-menu>
                    <a-menu-item @click="messageColumn(i.id,msIndex,'notification')" v-for="i in getOrderColumn" :key="i.id" >
                      {{$t(i.text)}}
                    </a-menu-item>

                  </a-menu>
                </template>
              </a-dropdown>
            </a-form-item>
            <div  class="post-back-button">
                <template
                    v-if="message.buttons"
                >
                  <facebook-onetime-notification-button
                      v-for="(button, btnIndex) in message.buttons"
                      :postbackContext="button"
                      :flowContextData="flowContextData"
                      :key="button.id"
                      :updatePostBackButton="
                      (data) => updatePostBackButton(data, msIndex, btnIndex)
                    "
                  />
                </template>

            </div>
          </a-card>
        </Draggable>
      </Container>
      <a-divider/>
      <div>
        <div
            class="ant-upload ant-upload-select ant-upload-select-picture-card"
            @click="addText"
        >
          <span role="button" tabindex="0" class="ant-upload">
            <div>
              <img
                  src="@/assets/icons/text-icon.svg"
                  class="anticon icon-img-svg"
              />
              <div class="ant-upload-text">
                {{ $t("TEXT") }}
              </div>
            </div>
          </span>
        </div>
        <div
            class="ant-upload ant-upload-select ant-upload-select-picture-card"
            @click="addPhoto"
        >
          <span role="button" tabindex="0" class="ant-upload">
            <div>
              <img
                  src="@/assets/icons/photo-flow.svg"
                  class="anticon icon-img-svg"
              />
              <div class="ant-upload-text">
                {{ $t("PHOTO") }}
              </div>
            </div>
          </span>
        </div>
        <div
            class="ant-upload ant-upload-select ant-upload-select-picture-card"
            @click="addGenericTemplate"
        >
          <span role="button" tabindex="0" class="ant-upload">
            <div>
              <img
                  src="@/assets/icons/generic-template.svg"
                  class="anticon icon-img-svg"
              />
              <div class="ant-upload-text">
                {{ $t("GENERIC_TEMPLATE") }}
              </div>
            </div>
          </span>
        </div>
        <div
            class="ant-upload ant-upload-select ant-upload-select-picture-card"
            @click="addQuickReplies"
        >
          <span role="button" tabindex="0" class="ant-upload">
            <div>
              <img
                  src="@/assets/icons/button-flow.svg"
                  class="anticon icon-img-svg"
              />
              <div class="ant-upload-text">
                {{ $t("QUICK_REPLIES") }}
              </div>
            </div>
          </span>
        </div>
        <div
            class="ant-upload ant-upload-select ant-upload-select-picture-card"
            @click="addOnetimeNotification"
        >
          <span role="button" tabindex="0" class="ant-upload">
            <div>
              <notification-outlined class="icon-img-svg"/>
              <div class="ant-upload-text">
                {{ $t("ONETIME_NOTIFICATION") }}
              </div>
            </div>
          </span>
        </div>
      </div>
      <flow-next-actions
          v-if="!isShow"
          :buttonName="$t('FLOW_SUCCESS_DELIVERY')"
          :updateAction="updateSuccessAction"
          :flowContextData="flowContextData"
          :flowContext="flowContext"
          :actionContext="flowContext.action_send_success_context"
      />
      <flow-next-actions
          v-if="!isShow"
          :buttonName="$t('FLOW_FAILED_DELIVERY')"
          :updateAction="updateFailedAction"
          :flowContextData="flowContextData"
          :flowContext="flowContext"
          :actionContext="flowContext.action_send_failed_context"
      />
      <flow-next-actions
          v-if="!isShow"
          :buttonName="$t('FLOW_DEFAULT_DELIVERY')"
          :updateAction="updateDefaultAction"
          :flowContextData="flowContextData"
          :flowContext="flowContext"
          :actionContext="flowContext.action_default_context"
      />
      <div class="row-button-action" style="text-align: right">
        <a-popconfirm
            @confirm="deleteCurrentFlowContext"
            :title="$t('FLOW_CONFIRM_DELETE_CONTEXT')"
            :ok-text="$t('YES')"
            :cancel-text="$t('NO')"
        >
          <a-button danger type="primary">
            <delete-outlined/>
            {{ $t("FLOW_DELETE") }}
          </a-button>
        </a-popconfirm>
      </div>
    </a-form>
    <image-selection-modal
        ref="imageSelectionRef"
        :single="isImageSelectionSingle"
        :onSelect="onImageSelect"
    />
  </div>
</template>

<script lang="ts">
import {
  computed,
  defineComponent, nextTick,
  onBeforeMount, onMounted,
  reactive,
  ref,
  UnwrapRef,
  watch,
} from "vue";
import {
  CloseCircleOutlined,
  CloseOutlined,
  DeleteOutlined,
  DragOutlined,
  NotificationOutlined,
  PictureOutlined,
  PlusOutlined,
  RightCircleOutlined,
} from "@ant-design/icons-vue";
import FacebookPostbackButton from "@/components/Flow/FacebookPostbackButton.vue";
import FlowNextActions from "@/components/Flow/FlowNextActions.vue";
import {useStore} from "@/store";
import {S_EventBus, ValidateFlowContext} from "@/utils/EventBus";
import {useI18n} from "vue-i18n";
import {MixUtils} from "@/utils/Utils";
// @ts-ignore
import {Container, Draggable} from "vue3-smooth-dnd";
import ImageSelectionModal from "@/components/Image/ImageSelectionModal.vue";
import "vue3-carousel/dist/carousel.css";
// @ts-ignore
import {Carousel, Slide, Pagination, Navigation} from "vue3-carousel";
import {AWS_DO_Model} from "@/models/AWS_DO/AWS_DO";
import {
  ActionContext, FacebookTemplate,
  MessageContext,
  PostbackContext,
  QuickReply,
} from "@/models/Flows/FlowContext";
import {
  FormFlowContextFacebook,
  FormFlowInformation,
} from "@/models/Flows/FlowForm";
import {ValidateErrorEntity} from "ant-design-vue/es/form/interface";
import FacebookQuickReplyButton from "@/components/Flow/FacebookQuickReplyButton.vue";
import FacebookOnetimeNotificationButton from "@/components/Flow/FacebookOnetimeNotificationButton.vue";
import {TagsActionTypes} from "@/store/modules/tag/action-types";

export default defineComponent({
  name: "flow-facebook",
  props: [
    "flowContext",
    "updateCurrentFlowContext",
    "flowContextData",
    "deleteFlowContext",
      "isShow"
  ],
  components: {
    DeleteOutlined,
    FacebookPostbackButton,
    FlowNextActions,
    PlusOutlined,
    Container,
    Draggable,
    CloseOutlined,
    DragOutlined,
    PictureOutlined,
    ImageSelectionModal,
    CloseCircleOutlined,
    Carousel,
    Slide,
    Navigation,
    FacebookQuickReplyButton,
    NotificationOutlined,
    FacebookOnetimeNotificationButton
  },
  setup(props) {
    const  cursorPositionMessageText= ref(0)
     const inputQuick = ref()
    const inputMessageContent = ref()
    const inputNotification = ref()
    const currentSlide = ref(0)
    const forcusMessagetext = (e: any) =>{
      // if(e.target.value.lastIndexOf('#') !== -1){
      //   e.target.setSelectionRange(e.target.value.lastIndexOf('#')+1,e.target.value.lastIndexOf('#')+1)
      //   console.log(e.target.selectionStart)
      // }else {
        cursorPositionMessageText.value = e.target.selectionStart
      console.log(cursorPositionMessageText.value)



    }
console.log('111111')
    const formRef = ref();
    const messengerTags = ref([
      {
        label: "NONE",
        value: "",
      },
      {
        label: "CONFIRMED_EVENT_UPDATE",
        value: "CONFIRMED_EVENT_UPDATE",
      },
      {
        label: "POST_PURCHASE_UPDATE",
        value: "POST_PURCHASE_UPDATE",
      },
      {
        label: "ACCOUNT_UPDATE",
        value: "ACCOUNT_UPDATE",
      },
    ]);
    const store = useStore();
    const formState: UnwrapRef<FormFlowContextFacebook> = reactive({});
    const messageContexts = ref<MessageContext[]>([]);
    const facebookTemplate = ref<FacebookTemplate[]>([]);
    const carouselRef = ref([]);
    const i18n = useI18n();
    const imageSelectionRef = ref();
    const genericCountUpdate = ref(0);
    const isImageSelectionSingle = ref(false);
    const getColumnsConfig = computed(()=> store.getters.getCustomerConfig)
    const getColumn =[
      {id:'employees',text:'SALE_STAFF'},
      {id:'phone',text:'CUSTOMER_PHONE'},
      {id:'address',text:'CUSTOMER_ADDRESS'},
      {id:'birth',text:'CUSTOMER_BIRTHDAY'},
      {id:'sex',text:'SEX'},

    ]
    const getOrderColumn =[
      {id:'shipping_code',text:'ORDER_SHIPPING_UNIT_ID'},
      {id:'shipping_address',text:'CUSTOMER_ADDRESS'},
      {id:'phone',text:'CUSTOMER_PHONE'},
      {id:'detail',text:'NAME'},
      {id:'total',text:'TOTAL_AMOUNT'},
      {id:'prepaid',text:'PAID'},
      {id:'cod_amount',text:'COD'},
      {id:'created_at',text:'ORDER_DATE'},
      {id:'status',text:'STATUS'},
      {id:'order_number',text: 'ORDER_ID'}

    ]
    const settings = {
      itemsToShow: 1,
      snapAlign: "center",
    };
    // breakpoints are mobile first
    // any settings not specified will fallback to the carousel settings
    const breakpoints = {
      // 700px and up
      700: {
        itemsToShow: 1.5,
        snapAlign: "center",
      },
      // 1024 and up
      1024: {
        itemsToShow: 2,
        snapAlign: "start",
      },
    };

    const updateProps = () => {
      if (props.flowContext.message_contexts) {
        console.log(props.flowContext.message_contexts,'props.flowContext.message_contexts')
        formState.name = props.flowContext.name;
        formState.message_content = props.flowContext.message;
        formState.message_tag = props.flowContext.messenger_tag
            ? props.flowContext.messenger_tag
            : undefined;
        messageContexts.value = props.flowContext.message_contexts.map(
            (item: MessageContext) => {
              if (item.facebook_templates && item.facebook_templates.length) {
                item.facebook_templates.map((temp) => {
                  if (!temp.default_action) {
                    temp.default_action = {
                      type: "web_url",
                      url: "",
                    };
                  }
                  return temp;
                });
              }
              if (item.buttons === null) {
                item.buttons = []
              }
              console.log(item,'buttons')
              // @ts-ignore
              facebookTemplate.value = item.facebook_templates
              item.carouselIndex = 0
              return item;
            }
        );
       console.log(messageContexts,'messageContexts')
       } else {
        messageContexts.value = [];

      }
    };
    watch(
        () => props.flowContext,
        () => {
          updateProps();
        }
    );
    const flowFormValidate: ValidateFlowContext = {
      showValidateForm() {
        onSubmit();
      },
    };
    onBeforeMount(() => {
      S_EventBus.instance().setValidateFlowContextListener(flowFormValidate);
    });
    const swipeCarousel = (index: number, slideNumber: number) => {
      console.log(index,slideNumber, )
      currentSlide.value = slideNumber
      // @ts-ignore

      genericCountUpdate.value++
    };
    const rules = {
      name: [
        {
          required: true,
          message: i18n.t("FLOW_VALIDATE_FLOW_NAME"),
          trigger: "blur",
        },
      ],
      tags: [
        {
          type: "array",
          required: true,
          message: i18n.t("FLOW_VALIDATE_FACEBOOK_MESSENGER_TAG"),
          trigger: "change",
        },
      ],
      message_content: [
        {
          required: true,
          message: i18n.t("FLOW_VALIDATE_FACEBOOK_MESSAGE_CONTENT"),
          trigger: "blur",
        },
      ],
    };

    const messageFullName = (msIndex: number,t:string) => {
      if(t === 'messageContent'){
        inputMessageContent.value[0].focus()
      }else if(t === 'quickMessage'){
        inputQuick.value[0].focus()
      }else {
        inputNotification.value[0].focus()
      }

      // @ts-ignore
      const text = ref<any>(messageContexts.value[msIndex].text);
      const start = text.value.slice(0,cursorPositionMessageText.value)
      const end = text.value.slice(cursorPositionMessageText.value)
      text.value =

          start + (start ? " " : "") + "##full_name##" + (end ? " " : "") + end
      // @ts-ignores
      messageContexts.value[msIndex].text = text.value;









    };
    const messagePageName = (msIndex: number,t:string) => {
      if(t === 'messageContent'){
        inputMessageContent.value[0].focus()
      }else if(t === 'quickMessage'){
        inputQuick.value[0].focus()
      }else {
        inputNotification.value[0].focus()
      }

      // @ts-ignore
      const text = ref<any>(messageContexts.value[msIndex].text);
      const start = text.value.slice(0,cursorPositionMessageText.value)
      const end = text.value.slice(cursorPositionMessageText.value)
      text.value =

          start + (start ? " " : "") + "##page_name##" + (end ? " " : "") + end
      // @ts-ignores
      messageContexts.value[msIndex].text = text.value;









    };
    const messageColumn = (column:string,msIndex: number,t:string) => {
      console.log(column,msIndex,t,'ttstftsdft')
      if(t === 'messageContent'){
        inputMessageContent.value[0].focus()
      }else if(t === 'quickMessage'){
        inputQuick.value[0].focus()
      }else {
        inputNotification.value[0].focus()
      }

      // @ts-ignore
      const text = ref<any>(messageContexts.value[msIndex].text);
      const start = text.value.slice(0,cursorPositionMessageText.value)
      const end = text.value.slice(cursorPositionMessageText.value)
      text.value =

          start + (start ? " " : "") + `##${column}##` + (end ? " " : "") + end
      // @ts-ignores
      messageContexts.value[msIndex].text = text.value;









    };
    const templateMessageFullName = (msIndex: number, tempIndex: number) => {
      // @ts-ignore
      const text = ref<any>(messageContexts.value[msIndex].facebook_templates[tempIndex].subtitle);

      const start = text.value.slice(0,cursorPositionMessageText.value)
      const end = text.value.slice(cursorPositionMessageText.value)
      // @ts-ignore

      text.value =

          start + (start ? " " : "") + "##full_name##" + (end ? " " : "") + end
      // @ts-ignore
      messageContexts.value[msIndex].facebook_templates[tempIndex].subtitle =
          text.value;
      // @ts-ignore
      // @ts-ignore
      console.log(tempIndex,msIndex,messageContexts.value[msIndex].facebook_templates[tempIndex].subtitle,text.value,'text')

    };
    const templateMessagePageName = (msIndex: number, tempIndex: number) => {
      // @ts-ignore
      const text = ref<any>(messageContexts.value[msIndex].facebook_templates[tempIndex].subtitle);

      const start = text.value.slice(0,cursorPositionMessageText.value)
      const end = text.value.slice(cursorPositionMessageText.value)
      // @ts-ignore

      text.value =

          start + (start ? " " : "") + "##page_name##" + (end ? " " : "") + end
      // @ts-ignore
      messageContexts.value[msIndex].facebook_templates[tempIndex].subtitle =
          text.value;
      // @ts-ignore
      // @ts-ignore
      console.log(tempIndex,msIndex,messageContexts.value[msIndex].facebook_templates[tempIndex].subtitle,text.value,'text')

    };
    const onTextChange = (e:any) => {
      if(e && e.target && e.target.selectionStart){
        cursorPositionMessageText.value = e.target.selectionStart
        onSubmit();
      }else {
        onSubmit();
      }



    };
    const templatemessageColumn= (column:string,msIndex: number, tempIndex: number) => {
      // @ts-ignore
      const text = ref<any>(messageContexts.value[msIndex].facebook_templates[tempIndex].subtitle);
      const start = text.value.slice(0,cursorPositionMessageText.value)
      const end = text.value.slice(cursorPositionMessageText.value)
      // @ts-ignore

      text.value =

          start + (start ? " " : "") + `##${column}##` + (end ? " " : "") + end
      // @ts-ignore
      messageContexts.value[msIndex].facebook_templates[tempIndex].subtitle =
          text.value;

    };

    const templateSubtitleChange = (
        msgIndex: number,
        tempIndex: number,
        val: any
    ) => {
      if(val && val.target && val.target.selectionStart){
        cursorPositionMessageText.value = val.target.selectionStart


      }
      //   @ts-ignore
      // messageContexts.value[msgIndex].facebook_templates[tempIndex].subtitle =
      //  val.target.value;
      onSubmit();
    };
    const templateUrlChange = (
        msgIndex: number,
        tempIndex: number,
        val: any
    ) => {
      // @ts-ignore
      messageContexts.value[msgIndex].facebook_templates[
          tempIndex
          ].default_action.url = val.target.value;
      onSubmit();
    };
    const templateTitleChange = (
        msgIndex: number,
        tempIndex: number,
        val: any
    ) => {
      // @ts-ignore
      messageContexts.value[msgIndex].facebook_templates[tempIndex].title =
          val.target.value;
 onSubmit()
    };
    const deleteMessage = (index: number) => {
      messageContexts.value = messageContexts.value.filter(
          (x, i) => i !== index
      );
      const flow = {...props.flowContext};
      flow.message_contexts = messageContexts.value;
      props.updateCurrentFlowContext(flow);
    };
    const addGenericTemplate = () => {
      const messageContext = {
        id: MixUtils.methods.getUUID(),
        type: "generic_template",
        carouselIndex: 0,
        facebook_templates: [
          {
            id: MixUtils.methods.getUUID(),
            buttons: [],
            subtitle: "",
            image_url: "",
            title: "",
            default_action: {type: "web_url", url: ""},
          },
        ],
      };
      messageContexts.value = [...messageContexts.value, messageContext];
      const flow = {...props.flowContext};
      flow.message_contexts = messageContexts.value;
      props.updateCurrentFlowContext(flow);
    };

    const addOnetimeNotification = () => {
      const button = {
        id: MixUtils.methods.getUUID(),
        // @ts-ignore
        title: "GET_NOTIFICATION",
        flow_actions_context: {
          flow_crm_actions: [],
        },
        type: store.getters.getFlowConstants?.facebook_button_types[0],
      };
      const messageContext = {
        id: MixUtils.methods.getUUID(),
        type: "onetime_notification",
        carouselIndex: 0,
        facebook_templates: [],
        buttons: [button],
        text: "",
      };
      messageContexts.value = [...messageContexts.value, messageContext];
      const flow = {...props.flowContext};
      flow.message_contexts = messageContexts.value;
      props.updateCurrentFlowContext(flow);
    };

    const addQuickReplies = () => {
      const messageContext = {
        id: MixUtils.methods.getUUID(),
        type: "quick_replies",
        carouselIndex: 0,
        facebook_templates: [],
        buttons:[],
        quick_replies: [
          {
            id: MixUtils.methods.getUUID(),
            title: "Quick Reply 1",
            image_url: "",
            content_type: "text",
            flow_actions_context: {
              flow_crm_actions: [],
            },
          },
        ],
      };
      messageContexts.value = [...messageContexts.value, messageContext];
      const flow = {...props.flowContext};
      flow.message_contexts = messageContexts.value;
      props.updateCurrentFlowContext(flow);
    };
    const clickEventT = (val: string) => {
      console.log("da", val);
    };
    const addText = () => {
      const messageContext = {
        id: MixUtils.methods.getUUID(),
        type: "text",
        carouselIndex: 0,
        facebook_templates: [],
        buttons: [],
        text: "",
      };
      messageContexts.value = [...messageContexts.value, messageContext];
      const flow = {...props.flowContext};
      flow.message_contexts = messageContexts.value;
      props.updateCurrentFlowContext(flow);
    };
    const addPhoto = () => {
      const messageContext = {
        id: MixUtils.methods.getUUID(),
        type: "image",
        carouselIndex: 0,
        facebook_templates: [],
        buttons: [],
        images: [],
      };
      messageContexts.value = [...messageContexts.value, messageContext];
      const flow = {...props.flowContext};
      flow.message_contexts = messageContexts.value;
      props.updateCurrentFlowContext(flow);
    };
    const onCardDrop1 = (dropResult: any) => {
      console.log("dropResult", dropResult);
      if (dropResult.addedIndex === null || dropResult.removedIndex === null) {
        return;
      }
      if (dropResult.removedIndex !== dropResult.addedIndex) {
        const tmpRemove = messageContexts.value[dropResult.removedIndex];
        messageContexts.value[dropResult.removedIndex] =
            messageContexts.value[dropResult.addedIndex];
        messageContexts.value[dropResult.addedIndex] = tmpRemove;
      }
      const flow = {...props.flowContext};
      flow.message_contexts = messageContexts.value;
      props.updateCurrentFlowContext(flow);
    };
    const getPayload1 = () => {
      // @ts-ignore
      return (index) => {
        console.log("getPayload1", getPayload1);
        return messageContexts.value[index];
      };
    };
    const updateTemplatePostBackButton = (
        postBack: PostbackContext,
        msgIndex: number,
        tempIndex: number,
        btnIndex: number
    ) => {
      // @ts-ignore
      messageContexts.value[msgIndex].facebook_templates[tempIndex].buttons[
          btnIndex
          ] = postBack;
      const flow = {...props.flowContext};
      flow.message_contexts = messageContexts.value;
      props.updateCurrentFlowContext(flow);
    };
    const updatePostBackButton = (
        postBack: PostbackContext,
        msgIndex: number,
        btnIndex: number
    ) => {
      // @ts-ignore
      messageContexts.value[msgIndex].buttons[btnIndex] = postBack;
      const flow = {...props.flowContext};
      console.log(flow,'flow1')
      flow.message_contexts = messageContexts.value;
      props.updateCurrentFlowContext(flow);
    };


    const updateQuickReplyButton = (
        postBack: QuickReply,
        msgIndex: number,
        btnIndex: number
    ) => {
      // @ts-ignore
      messageContexts.value[msgIndex].quick_replies[btnIndex] = postBack;
      const flow = {...props.flowContext};
      flow.message_contexts = messageContexts.value;
      props.updateCurrentFlowContext(flow);
    };

    const addTemplate = (index: number) => {
      // @ts-ignore
      if (messageContexts.value[index].facebook_templates.length < 10) {
        // @ts-ignore
        messageContexts.value[index].facebook_templates.push({
          id: MixUtils.methods.getUUID(),
          buttons: [],
          subtitle: "",
          image_url: "",
          title: "",
        });
        genericCountUpdate.value++
      }

      const flow = {...props.flowContext};
      flow.message_contexts = messageContexts.value;
      props.updateCurrentFlowContext(flow);
    };
    const addTemplatePostBackButton = (index: number, tempIndex: number) => {
      const button = {
        id: MixUtils.methods.getUUID(),
        // @ts-ignore
        title: "#button #" + (messageContexts.value[index].facebook_templates[tempIndex].buttons.length + 1),
        flow_actions_context: {
          flow_crm_actions: [],
        },
        type: store.getters.getFlowConstants?.facebook_button_types[0],
      };
      if (messageContexts.value[index]) {
        // @ts-ignore
        if (messageContexts.value[index].facebook_templates[tempIndex].buttons.length < 3) {
          // @ts-ignore
          messageContexts.value[index].facebook_templates[tempIndex].buttons.push(button);
        }
      } else {
        // @ts-ignore
        messageContexts.value[index].facebook_templates[tempIndex].buttons = [
          button,
        ];
      }
      const flow = {...props.flowContext};
      flow.message_contexts = messageContexts.value;
      props.updateCurrentFlowContext(flow);
      genericCountUpdate.value++
    };

    const addPostBackButton = (index: number) => {

      // @ts-ignore
      messageContexts.value[index].text = messageContexts.value[index].text ? messageContexts.value[index].text.slice(0,640) : ''
      const button = {
        id: MixUtils.methods.getUUID(),
        // @ts-ignore
        title: "#button #" + (messageContexts.value[index].buttons.length + 1),
        flow_actions_context: {
          flow_crm_actions: [],
        },
        type: store.getters.getFlowConstants?.facebook_button_types[0],
      };
      // @ts-ignore
      if (messageContexts.value[index].buttons.length < 3) {
        // @ts-ignore
        messageContexts.value[index].buttons.push(button);
      }
      const flow = {...props.flowContext};
      flow.message_contexts = messageContexts.value;
      genericCountUpdate.value++
      props.updateCurrentFlowContext(flow);
    };

    const addQuickReplyButton = (index: number) => {
      const button: QuickReply = {
        id: MixUtils.methods.getUUID(),
        // @ts-ignore
        title: "#button #" + (messageContexts.value[index].quick_replies ? messageContexts.value[index].quick_replies.length + 1 : 1),
        flow_actions_context: {
          flow_crm_actions: [],
        },
        content_type: "text",
        image_url: "",
      };
      if (messageContexts.value[index].quick_replies) {
        // @ts-ignore
        messageContexts.value[index].quick_replies.push(button);
      } else {
        messageContexts.value[index].quick_replies = [button];
      }

      const flow = {...props.flowContext};
      flow.message_contexts = messageContexts.value;
      props.updateCurrentFlowContext(flow);
    };
    const deleteTemplatePostbackButton = (
        msgIndex: number,
        tempIndex: number,
        btnIndex: number
    ) => {
      // @ts-ignore
      messageContexts.value[msgIndex].facebook_templates[tempIndex].buttons = messageContexts.value[msgIndex].facebook_templates[tempIndex].buttons.filter((x, i) => i !== btnIndex);
      const flow = {...props.flowContext};
      flow.message_contexts = messageContexts.value;
      genericCountUpdate.value++
      props.updateCurrentFlowContext(flow);
    };

    const deleteQuickReplyButton = (msgIndex: number, btnIndex: number) => {
      // @ts-ignore
      messageContexts.value[msgIndex].quick_replies = messageContexts.value[
          msgIndex
          ].quick_replies.filter((x, i) => i !== btnIndex);
      const flow = {...props.flowContext};
      flow.message_contexts = messageContexts.value;
      props.updateCurrentFlowContext(flow);
    };

    const deletePostbackButton = (msgIndex: number, btnIndex: number) => {
      // @ts-ignore
      messageContexts.value[msgIndex].buttons = messageContexts.value[
          msgIndex
          ].buttons.filter((x, i) => i !== btnIndex);
      const flow = {...props.flowContext};
      flow.message_contexts = messageContexts.value;
      props.updateCurrentFlowContext(flow);
    };
    const deleteFacebookTemplate = (msIndex: number, tempIndex: number) => {
      // @ts-ignore
      messageContexts.value[msIndex].facebook_templates = messageContexts.value[
          msIndex
          ].facebook_templates.filter((x, i) => i !== tempIndex);
      const flow = {...props.flowContext};
      flow.message_contexts = messageContexts.value;
      props.updateCurrentFlowContext(flow);
      genericCountUpdate.value++
    };
    const updateSuccessAction = (action: ActionContext) => {
      const flow = {...props.flowContext};
      flow.action_send_success_context = action;
      props.updateCurrentFlowContext(flow);
    };
    const updateFailedAction = (action: ActionContext) => {
      const flow = {...props.flowContext};
      flow.action_send_failed_context = action;
      props.updateCurrentFlowContext(flow);
    };
    const updateDefaultAction = (action: ActionContext) => {
      const flow = {...props.flowContext};
      flow.action_default_context = action;
      props.updateCurrentFlowContext(flow);
    };
    const onImageSelect = (images: AWS_DO_Model[], param: string) => {
      if (images && images.length && param) {
        let p = param.split("_");
        if (parseInt(p[1]) === 909) {
          messageContexts.value[parseInt(p[0])].images = [images[0].url];
        } else {
          // @ts-ignore
          messageContexts.value[parseInt(p[0])].facebook_templates[
              parseInt(p[1])
              ].image_url = images[0].url;
          genericCountUpdate.value++
        }
      }
    };
    const showImageSingleSelectionModal = (param: string) => {
      isImageSelectionSingle.value = true;
      imageSelectionRef.value.showModal(param);
    };

    const deleteCurrentFlowContext = () => {
      props.deleteFlowContext(props.flowContext.id);
    };

    const onSubmit = () => {
      formRef.value
          .validate()
          .then(() => {
            const flow = {...props.flowContext};
            flow.messenger_tag = formState.message_tag;
            flow.name = formState.name;
            flow.message_contexts = messageContexts.value;
            console.log(flow,messageContexts.value)
            props.updateCurrentFlowContext(flow);
          })
          .catch((error: ValidateErrorEntity<FormFlowInformation>) => {
            console.log("error", error);
          });
    };
    updateProps();

    return {
      rules,
      formState,
      formRef,
      onSubmit,
      onTextChange,
      messengerTags,
      updateTemplatePostBackButton,
      updateSuccessAction,
      updateFailedAction,
      updateDefaultAction,
      addTemplatePostBackButton,
      deleteTemplatePostbackButton,
      deleteCurrentFlowContext,
      messageContexts,
      addGenericTemplate,
      addQuickReplies,
      addOnetimeNotification,
      getPayload1,
      onCardDrop1,
      addTemplate,
      imageSelectionRef,
      isImageSelectionSingle,
      showImageSingleSelectionModal,
      onImageSelect,
      carouselRef,
      swipeCarousel,
      deleteFacebookTemplate,
      settings,
      breakpoints,
      deleteMessage,
      addPhoto,
      addText,
      deletePostbackButton,
      updatePostBackButton,
      addPostBackButton,
      addQuickReplyButton,
      deleteQuickReplyButton,
      updateQuickReplyButton,
      clickEventT,
      templateTitleChange,
      templateSubtitleChange,
      templateUrlChange,
      messageFullName,
      messagePageName,
      templateMessageFullName,
      templateMessagePageName,
      genericCountUpdate,
      forcusMessagetext,
      facebookTemplate,
      inputQuick,
      inputMessageContent,
      inputNotification,
      getColumnsConfig,
      getOrderColumn,
      getColumn,
      messageColumn,
      templatemessageColumn,
      currentSlide
      // forcusMessageQuick,
      // forcusNotification,
      // forcusDescription
    };
  },
});
</script>

<style lang="scss" scoped>
.main-flow {
  position: relative;
  padding: 40px 18% 60px;
  text-align: left;

  .icon-brand-sticker {
    position: absolute;
    font-size: 28px;
    top: 0;
    margin-right: 25px;
    right: 0;
  }

  .row-button-action {
    margin-top: 20px;
  }

  .card-message {
    margin-top: 10px;
    border-radius: 10px;
  }
}

.post-back-button {
  margin-top: 18px;
  text-align: center;
}

.button-post-back {
  margin: 5px 10px;
}

.slick-dot-facebook-template {
  display: inline-block;
  cursor: pointer;
  margin: 7px;

  .slick-dot-facebook-template-content {
    padding: 2px;
  }
}

.card-facebook-template {
  width: 95%;
}

.card-facebook-template-active {
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  border-color:#fab216 ;
}

.img-cover-facebook-template {
  width: 100%;
  height: 200px;
  object-fit: cover;
}

.upload-center {
  display: flex;
  justify-content: center;
  height: 200px;
  align-items: center;
}

.slick-slide {
  width: 300px !important;
}

.slick-dot-facebook-ava {
  border-radius: 5px;
  border: 2px solid white;
}

.slick-dot-facebook-ava-active {
  border: 2px solid #1890ff;
  border-radius: 5px;
}

.slick-dot-facebook-ava:hover {
  border: 2px solid #1890ff;
  border-radius: 5px;
}

.slick-dot-facebook-ava:hover + .remove-icon-badge {
  display: block;
}

.slick-dot-bar {
  margin-top: 10px;
}

.remove-icon-badge {
  display: none;
}
.remove-icon-badge-block {
  display: block;
  cursor: pointer;
}
.button-add-button {
  margin-left: 12px;
}

.carousel-facebook {
  width: 60%;
}

.photo-flow-img {
  max-height: 200px;
  object-fit: cover;
  cursor: pointer;
}

.icon-img-svg {
  width: 28px;
  height: 28px;
}

.button-quick-reply {
  border-radius: 15px;
}
</style>
<style>
.carousel__prev--in-active {
  display: none;
}

.carousel__next--in-active {
  display: none;
}
.error > .ant-input {
  border-color: red !important;
}
</style>
