import {Mutations} from "@/store/modules/PageConfig/mutations";
import {ActionContext, ActionTree} from "vuex";
import {State} from "@/store/modules/PageConfig";
import {RootState} from "@/store";

import APIs from "@/APIs/APIs";
import {PageConfigRequest} from "@/APIs/request/PageConfigRequest";
import {PageConfigActionTypes} from "@/store/modules/PageConfig/action-types";
import {PageConfigMutationTypes} from "@/store/modules/PageConfig/mutation-types";
import {PageConfigResponse} from "@/APIs/response/S_HttpResponse";

type AugmentedActionContext = {
    commit<K extends keyof Mutations>(
        key: K,
        payload: Parameters<Mutations[K]>[1],
    ): ReturnType<Mutations[K]>;
} & Omit<ActionContext<State, RootState>, 'commit'>

export interface Actions {
    [PageConfigActionTypes.FETCH_PAGE_CONFIG](
        {commit}: AugmentedActionContext,
        payload: PageConfigRequest,
    ): Promise<PageConfigResponse | undefined>;

}

export const actions: ActionTree<State, RootState> & Actions = {

    async [PageConfigActionTypes.FETCH_PAGE_CONFIG]({commit }, payload: PageConfigRequest): Promise<PageConfigResponse | undefined> {
        const response = await APIs.init().getShopPages(payload)
        if (response.status === 200) {
            commit(PageConfigMutationTypes.GET_PAGES_CONFIG, response.data?.data)
        }
        return response.data;
    },


}
