import {State} from "./states";
import {TriggerMutationTypes} from "./mutation-types";
import {Trigger} from "@/models/Trigger/Trigger";
import {Metadata} from "@/APIs/response/S_HttpResponse";
import {TriggerStatistic, TriggerStatisticErrorLog, Viral} from "@/models/Trigger/TriggerHistory";

export type Mutations<S = State> = {
    [TriggerMutationTypes.SET_Triggers](state: S, payload: Trigger[]|undefined): void;
    [TriggerMutationTypes.SET_TRIGGER_METADATA](state: S, payload: Metadata|undefined): void;
    [TriggerMutationTypes.SET_TRIGGER_STATISTICS](state: S, payload: TriggerStatistic[]|undefined): void;
    [TriggerMutationTypes.SET_INNER_TRIGGER_STATISTICS](state: S, payload: TriggerStatistic[]|undefined): void;
    [TriggerMutationTypes.SET_TRIGGER_STATISTIC_METADATA](state: S, payload: Metadata|undefined): void;
    [TriggerMutationTypes.SET_INNER_TRIGGER_STATISTIC_METADATA](state: S, payload: Metadata|undefined): void;
    [TriggerMutationTypes.SET_TRIGGER_QUICK_MESSAGES](state: S, payload: Trigger[]): void;
    [TriggerMutationTypes.SET_TRIGGER_STATISTICS_LOG](state: S, payload: TriggerStatisticErrorLog[]): void;
    [TriggerMutationTypes.SET_VIRAL](state: S, payload: Viral[]|undefined): void;
}

export const mutations: Mutations = {
    [TriggerMutationTypes.SET_Triggers](state: State, payload: Trigger[]|undefined): void {
        state.triggers = payload
    },

    [TriggerMutationTypes.SET_TRIGGER_METADATA](state: State, payload: Metadata|undefined): void {
        state.triggerMetadata = payload
    },
    [TriggerMutationTypes.SET_TRIGGER_STATISTICS](state: State, payload: TriggerStatistic[]): void {
        state.triggerStatistics = payload
    },
    [TriggerMutationTypes.SET_INNER_TRIGGER_STATISTICS](state: State, payload: TriggerStatistic[]): void {
        state.triggerInnerStatistics = payload
    },
    [TriggerMutationTypes.SET_TRIGGER_STATISTIC_METADATA](state: State, payload: Metadata): void {
        state.triggerStatisticMetadata = payload
    },
    [TriggerMutationTypes.SET_INNER_TRIGGER_STATISTIC_METADATA](state: State, payload: Metadata): void {
        state.innerTriggerStatisticMetadata = payload
    },
    [TriggerMutationTypes.SET_TRIGGER_QUICK_MESSAGES](state: State, payload: Trigger[]): void {
        state.triggerQuickMessages = payload
    },
    [TriggerMutationTypes.SET_TRIGGER_STATISTICS_LOG](state: State, payload: TriggerStatisticErrorLog[]): void {
        state.triggerStatisticErrorLogs = payload
    },
    [TriggerMutationTypes.SET_VIRAL](state: State, payload: Viral[]|undefined): void {
        state.viral = payload
    },

}
