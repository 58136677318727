import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-4cb3ba0a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "container-title" }
const _hoisted_2 = { class: "title-header" }
const _hoisted_3 = { class: "item-trigger-table-small" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_range_picker = _resolveComponent("a-range-picker")!
  const _component_a_form_item = _resolveComponent("a-form-item")!
  const _component_a_form = _resolveComponent("a-form")!
  const _component_a_button = _resolveComponent("a-button")!
  const _component_a_tag = _resolveComponent("a-tag")!
  const _component_warning_outlined = _resolveComponent("warning-outlined")!
  const _component_a_tooltip = _resolveComponent("a-tooltip")!
  const _component_a_spin = _resolveComponent("a-spin")!
  const _component_highcharts = _resolveComponent("highcharts")!
  const _component_a_table = _resolveComponent("a-table")!
  const _component_a_timeline_item = _resolveComponent("a-timeline-item")!
  const _component_a_timeline = _resolveComponent("a-timeline")!
  const _component_VirtualList = _resolveComponent("VirtualList")!
  const _component_a_modal = _resolveComponent("a-modal")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("h3", _hoisted_2, _toDisplayString(_ctx.$t(_ctx.$route.name)), 1),
      _createVNode(_component_a_form, {
        layout: "inline",
        model: _ctx.filterForm
      }, {
        default: _withCtx(() => [
          _createVNode(_component_a_form_item, { label: "Schedule time" }, {
            default: _withCtx(() => [
              _createVNode(_component_a_range_picker, {
                value: _ctx.filterForm.dateRange,
                "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.filterForm.dateRange) = $event)),
                onChange: _ctx.dateRangeChange
              }, null, 8, ["value", "onChange"])
            ]),
            _: 1
          })
        ]),
        _: 1
      }, 8, ["model"])
    ]),
    _createVNode(_component_a_table, {
      class: "table",
      onExpand: _ctx.tableExpand,
      "data-source": _ctx.list.triggerStatistics,
      pagination: _ctx.pagination,
      expandedRowKeys: _ctx.expandedRowKeys,
      onChange: _ctx.handleTableChange,
      columns: _ctx.columns,
      rowKey: (record) => record.id
    }, {
      trigger_name: _withCtx(({ record }) => [
        _createVNode(_component_a_button, {
          class: "table-row-name",
          type: "text"
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(record.trigger_name), 1)
          ]),
          _: 2
        }, 1024)
      ]),
      created: _withCtx(({ text }) => [
        _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.dateDisplayFormatFromString(text)), 1)
      ]),
      success: _withCtx(({ text }) => [
        _createElementVNode("span", null, [
          _createVNode(_component_a_tag, { color: "#87d068" }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(text), 1)
            ]),
            _: 2
          }, 1024)
        ])
      ]),
      failed: _withCtx(({ text }) => [
        _createElementVNode("span", null, [
          _createVNode(_component_a_tag, { color: "#f50" }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(text), 1)
            ]),
            _: 2
          }, 1024)
        ])
      ]),
      sent: _withCtx(({ text }) => [
        _createElementVNode("span", null, [
          _createVNode(_component_a_tag, { color: "#2db7f5" }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(text), 1)
            ]),
            _: 2
          }, 1024)
        ])
      ]),
      clicked: _withCtx(({ text }) => [
        _createElementVNode("span", null, [
          _createVNode(_component_a_tag, { color: "#42dbc7" }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(text), 1)
            ]),
            _: 2
          }, 1024)
        ])
      ]),
      action: _withCtx(({record}) => [
        _createVNode(_component_a_tooltip, {
          title: _ctx.$t('ERROR_LOG')
        }, {
          default: _withCtx(() => [
            _createVNode(_component_warning_outlined, {
              onClick: ($event: any) => (_ctx.viewErrorLog(record))
            }, null, 8, ["onClick"])
          ]),
          _: 2
        }, 1032, ["title"])
      ]),
      expandedRowRender: _withCtx(() => [
        (_ctx.expandLoading)
          ? (_openBlock(), _createBlock(_component_a_spin, { key: 0 }))
          : (_openBlock(), _createBlock(_component_highcharts, {
              key: 1,
              class: "chart",
              options: _ctx.charts
            }, null, 8, ["options"]))
      ]),
      _: 1
    }, 8, ["onExpand", "data-source", "pagination", "expandedRowKeys", "onChange", "columns", "rowKey"]),
    _createVNode(_component_a_modal, {
      visible: _ctx.errorLogVisible,
      "onUpdate:visible": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.errorLogVisible) = $event)),
      title: _ctx.$t('ERROR_LOG_VIEWER'),
      footer: null
    }, {
      default: _withCtx(() => [
        (_ctx.errorLogsLoading)
          ? (_openBlock(), _createBlock(_component_a_spin, { key: 0 }))
          : (_openBlock(), _createBlock(_component_VirtualList, {
              key: 1,
              class: "scroller",
              size: 30,
              remain: 20
            }, {
              default: _withCtx(() => [
                _createVNode(_component_a_timeline, null, {
                  default: _withCtx(() => [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.errorLogs.triggerStatisticErrorLogs, (item) => {
                      return (_openBlock(), _createBlock(_component_a_timeline_item, {
                        key: item.created
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(item.content) + " " + _toDisplayString(_ctx.dateTimeDisplayFormatFromString(item.created)), 1)
                        ]),
                        _: 2
                      }, 1024))
                    }), 128))
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }))
      ]),
      _: 1
    }, 8, ["visible", "title"])
  ], 64))
}