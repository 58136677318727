import {ProductGroupData, State} from "./states";
import {ProductMutationTypes} from "./mutation-types";
import {Trigger} from "@/models/Trigger/Trigger";
import {ProductCategory, ProductDetail, ProductProvider, ProductGroup} from "@/models/Products/Products";

export type Mutations<S = State> = {
    [ProductMutationTypes.SET_PRODUCTS](state: S, payload: ProductGroupData|undefined): void;
    [ProductMutationTypes.SET_PRODUCTS_DETAIL](state: S, payload: ProductDetail|undefined): void;
    [ProductMutationTypes.SET_PRODUCTS_CATEGORIES](state: S, payload: ProductCategory[]|undefined): void;
    [ProductMutationTypes.SET_PRODUCTS_PROVIDERS](state: S, payload: ProductProvider[]|undefined): void;
}

export const mutations: Mutations = {
    [ProductMutationTypes.SET_PRODUCTS](state: State, payload: ProductGroupData| undefined): void {
        state.productGroupData = payload
    },
    [ProductMutationTypes.SET_PRODUCTS_DETAIL](state: State, payload: ProductDetail|undefined): void {
        state.productGroupData?.productGroups?.map(product=>{
            if(payload && product.id===payload.id){

                product.versions = payload.versions
            }
            return product
        })
        state.productDetail = payload
    },
    [ProductMutationTypes.SET_PRODUCTS_CATEGORIES](state: State, payload: ProductDetail[]|undefined): void {
        state.productCategories = payload
    },
    [ProductMutationTypes.SET_PRODUCTS_PROVIDERS](state: State, payload: ProductProvider[]|undefined): void {
        state.productProviders = payload
    },

}