<template>
  <a-layout-content>
    <div class="container-title">
      <h3 class="title-header">{{$t($route.name)}}</h3>
    </div>

    <a-card
      style="
        margin: 16px;
        /*background-color: #fff;*/
        /*border-radius: 10px;*/
        /*border: 1px solid #ccc;*/
        /*height: 230px;*/
        /*flex-direction: column;*/
      "
    >
      <template #title>
        <a-row
        >
          <UserOutlined style="margin-left: 10px" />
          <a-typography-text strong style="margin-left: 10px">
           {{ employee.name }}</a-typography-text
          >
        </a-row>
      </template>


        <a-form  :labelCol="{ span: 4 }">
          <a-form-item label="ID">
            <a-input disabled :value="employee.cms_user_id" style="color: #000" />
          </a-form-item>
          <a-form-item :label="$t('NAME')">
            <a-input disabled :value="employee.name" style="color: #000" />
          </a-form-item>
          <a-form-item :label="'Email'">
            <a-input disabled :value="employee.email" style="color: #000" />
          </a-form-item>
          <a-form-item :label="$t('ROLE')">
            <a-select
              :placeholder="$t('ROLE')"
              :options="RoleSelection"
              v-model:value="employee.role_id"
              @change="handleRoleChange"
            >
            </a-select>
          </a-form-item>
          <a-form-item :label="$t('DEPARTMENT')">
            <a-select
              :placeholder="$t('DEPARTMENT')"
              allowClear
              :options="departments"
              v-model:value="employee.department_id"
              @change="handleDepartmentChange"
            >
            </a-select>
          </a-form-item>
          <a-form-item :label="$t('MANAGER')">
            <a-select
                allowClear
                :placeholder="$t('MANAGER')"
                :options="employees"
                v-model:value="employee.manager_id"
                @change="handleManagerChange"
            >
            </a-select>
          </a-form-item>
        </a-form>
      <a-row style="height: 20%; justify-content: center; align-items: center">
        <a-button @click="Back">{{$t('BACK')}}</a-button>
        <a-button
          @click="Save"
          type="primary"
          style="margin-left: 24px"
          >{{$t('SAVE')}}</a-button
        >
      </a-row>
    </a-card>
  </a-layout-content>
</template>

<script>
import { useStore } from "@/store";
import { computed, ref } from "vue";
import { useRoute } from "vue-router";
import { UserOutlined } from "@ant-design/icons-vue";
import APIs from "@/APIs/APIs";
import { EmployeeActionTypes } from "@/store/modules/employee/action-types";
import { message } from "ant-design-vue";
import useEmployeeStore from "../../store/Employee";

export default {
  components: {
    UserOutlined,
  },
  setup() {
    const store = useStore();
    const router = useRoute();

    const departments = ref([]);
    const RoleSelection = ref([

    ]);
    const fetchRole = async  () => {
      const res = await APIs.init().getRole()
      if (res.status=== 200){
        RoleSelection.value = res.data?.data.map(item => ({label: item.name,value: item.id}))
      }
    }
    const fetchDepartment = async  () => {
      const res = await APIs.init().getDepartments()
      if (res.status=== 200){
        departments.value = res.data?.data?res.data?.data.map(item => ({label: item.name,value: item.id})):[]
        console.log('departments', departments)
      }
    }
    fetchRole()
    fetchDepartment()
    const Back = () => {
      history.back();
    };
    const employee = computed(() =>{
      const user = useEmployeeStore.getState().employees?.find(
          (item) => item.cms_user_id === Number(router.params.userid)
      )
      return user?user:{}
    });

    const employees = computed(() =>useEmployeeStore.getState().employees.map(e=>({...e, ...{label: e.name, value: e.cms_user_id}})));

    const Save = async () => {
      try {
        const res = await APIs.init().editEmployee({...employee.value, ...{
          user_id: employee.value.cms_user_id,
            department_id: employee.value.department_id?employee.value.department_id:0,
            manager_id: employee.value.manager_id?employee.value.manager_id:0,
        }});
        if (res.status === 200) {
         store.dispatch(EmployeeActionTypes.FETCH_EMPLOYEE, {
        page: 1,
        page_size: 20,

      });
          message.success(this.$t('SUCCESS'))
         setTimeout(() => {
           history.back()
         },1000)
        }
      } catch (error) {
        console.log(error);
      }
    };
    const handleRoleChange = (value) => {
      employee.value.role_id = value;
    };

    const handleDepartmentChange = (value) => {
      employee.value.department_id = value;
    };

    const handleManagerChange = (value) => {
      employee.value.manager_id = value;
    };

    return {
      employee,
      Back,
      handleRoleChange,
      handleDepartmentChange,
      handleManagerChange,
      RoleSelection,
      Save,
      departments,
      employees,
    };
  },
};
</script>

<style></style>
