<template>
  <component :is="this.$route.meta.layout || 'default-layout'">
    <router-view v-slot="{ Component }">
      <transition>
        <component :is="Component" />
      </transition>
    </router-view>
  </component>
</template>
<script lang="ts">
import { mapActions, mapGetters } from "vuex";
import RouterEndpoint from "@/router/RouterEndpoint";
import { defineComponent } from "vue";
import { UserActionTypes } from "@/store/modules/user/action-types";
import { FlowActionTypes } from "@/store/modules/flow/action-types";
import useFlowStore from "./store/Flow";
import useUserStore from "./store/User";


export default defineComponent({
  computed: {
    ...mapGetters({
      isLogin: "getIsLogin",
      lang: "getLang",
    }),
  },
  methods: {
    ...mapActions({
      fetchUserInfo: UserActionTypes.FETCH_USER_INFO,
      fetchFlowConstant: FlowActionTypes.FETCH_FlowConstants,
    }),
    // fetchUserInfo() {
    //   useUserStore.getState().getUser()
    // },
    // fetchFlowConstant() {
    //   useFlowStore.getState().getFlowConstants()
    // }
  },
  created() {
    this.$i18n.locale = this.lang;

    setTimeout(() => {
      if (
        this.$route.name !== RouterEndpoint.ACCOUNTLOGIN.name &&
        this.$route.name !== RouterEndpoint.LANDING_PAGE.name &&
        this.$route.name !== RouterEndpoint.PAYMENT.name &&
        this.$route.name !== RouterEndpoint.ORDER_THERMAL.name
      ) {
        this.fetchUserInfo();
        this.fetchFlowConstant();
        // try{
        //   MSocketIo.getInstance().socket?.disconnect()
        // }catch (e) {
        //   console.log(e)
        // }
      }
    }, 50);
  },
});
</script>
<style lang="scss">
.scale-enter-active,
.scale-leave-active {
  transition: all 0.1s ease;
}

.scale-enter-from,
.scale-leave-to {
  opacity: 0;
  transform: scale(0.9);
}
</style>
