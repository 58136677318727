<template>
  <div>
  <a-row class="header"  >
    <img
        src="https://biglead.live/images/favicon.png"
        style="width: 28px; height: auto"/>
    <a-col>
      <div class="description">Web</div>
      <span class="content">{{data.url}}</span>
    </a-col>
  </a-row>
  <div class="container">
    <div style="padding-bottom: 16px">
      <iframe :src="data.url" :title="data.url" frameborder="0" style="min-height: 300px" >
      </iframe>
    </div>
  </div>
  </div>
  <Handle :id="data.id" type="target"  class="handle" :position="Position.Left" :style="sourceHandleStyleA" />

</template>

<script>
import {defineComponent, defineProps, defineEmits, onMounted, ref} from "vue";
import { computed } from 'vue'
import {Position, VueFlow, useVueFlow, Handle, MarkerType} from '@vue-flow/core'
import HandleCustom from "./HandleCustom.vue";
import {useI18n} from "vue-i18n";

const props = defineProps({
  data: {
    type: Object,
    required: true,
  },
})


export default defineComponent({
  name: "WebNode",
  components:{
    Handle
  },
  props: [ 'data'],
  setup(props, {emit}){
    const i18n = useI18n()

    const sourceHandleStyleA = computed(() => ({ top: '10px',
      bottom: 'auto', }))


    return{
      Position,
      sourceHandleStyleA,
    }
  }
})
</script>

<style scoped>
.container{
  padding: 16px 16px 16px 16px;
  font-size: 14px;
}

.header{
  padding: 16px 16px 16px 16px;
  border-radius: 8px 8px 0 0;
  display: flex;
  flex-flow: row;
  align-items: center;
  gap: 8px;
  background-color: #ffeca7
}

.content{
  font-size: 16px;
  font-weight: normal;
}

.description{
  color: #758697;
  font-size: 10px;
}
</style>
