import {Tag} from "@/models/Tags/Tags";

export type AutoInboxRequest = {
    id: number
}
export type AutoInboxesRequest = {
    page?: number,
    page_size?: number,
    page_id?: string
}

export type FacebookPostRequest={
    page_id: string,
    search: string,
}

export enum ORDER_ENUM{
    all = 'all',
    yes = 'yes',
    no = 'no',

}
export enum HAS_PHONE_ENUM{
    all = 'null',
    yes = 'yes',
    no = 'yes',
}

export type ExpectNumberCustomerRequest={
    page_id: string
    order: ORDER_ENUM
    filter_tag: string
    filter_tag_bl: string
    filter_post: string
    has_phone: HAS_PHONE_ENUM
}

export type AutoInboxChangeStatusRequest={
    id: any
    status: any
}
