<template>
  <a-row>
    <a-col :span="12">
      <a-typography-title :level="2">{{$t('OA_CODE_LINE')}}</a-typography-title>
<!--      <a-input-search-->
<!--          v-model:value="value"-->
<!--          :placeholder="$t('SEARCH')"-->
<!--          style="width: 100%"-->
<!--          @search="onSearch"-->
<!--      />-->
    </a-col>
    <a-col :span="2"></a-col>
    <a-col :span="10">
      <a-typography-title style="Opacity:0 " :level="2">Line</a-typography-title>
      <a-row style="justify-content: flex-end">
        <a-button @click="visible = true"  type="primary">{{$t('ADD_NEW')}}</a-button>
      </a-row>

    </a-col>
  </a-row>

  <a-row  style="margin-top: 20px;">
    <a-col :span="24">
      <a-card  style="width: 100%;margin-top: 20px;border-radius: 10px">
        <template #title>
          <a-row>
            <a-col :span="8">
              <MenuUnfoldOutlined />
              {{$t('CONFIGURE_LINE')}}
            </a-col>
            <a-col :span="14">
            </a-col>
            <a-col :span="2">

            </a-col>
          </a-row>

        </template>

          <a-row  v-for="(item,index) in listData" :key="item.id">
            <a-col :span="4">
              <a-typography-text strong>{{index+1}}</a-typography-text>
            </a-col>
            <a-col :span="8">
              <img style="border-radius: 50%" width="40" height="40" :src="item.avatar" />
            </a-col>
            <a-col :span="8">
              <a-typography-text>{{item.page_name}}</a-typography-text>
            </a-col>
            <a-col :span="4">
              <div>
                <a-button @click="removeTag(item.page_id)"  style="border-radius: 50%">
                  <template #icon><MinusOutlined /></template>
                </a-button>
              </div>
            </a-col>
          </a-row>


        <a-row justify="center" v-if="listData.length === 0" >
          <a-typography-title :level="3">{{$t('no_data')}}</a-typography-title>
        </a-row>



      </a-card>
    </a-col>

  </a-row>
  <a-modal width="600px" :footer="false" v-model:visible="visible" :title="$t('ADD_NEW')" @ok="handleOk">
    <a-card style="width: 100%">
    <a-row style="margin-bottom: 10px">
      <a-typography-text strong>{{$t('ENTER_LINE_TOKEN_HERE')}}</a-typography-text>
      <a-typography-link style="margin-left: 10px" :href="'https://shopf1.net/' +
                                    (this.$i18n.locale == 'vn' ? 'vi' : this.$i18n.locale ) +
                                    '/connect'" target="_blank">{{$t('go_to_instruction')}}</a-typography-link>
    </a-row>
      <a-input v-model:value="access_token" placeholder="Access token line" />
      <a-button style="margin-top: 10px" @click="addLineInfo" type="primary">{{$t('confirm')}}</a-button>

    </a-card>
  </a-modal>


</template>
<script  >

import { MenuUnfoldOutlined,MinusOutlined,CloseOutlined} from '@ant-design/icons-vue';
import {computed,ref,reactive} from "vue";
import {useStore} from "@/store";
import {useRouter} from "vue-router";
import {CustomersActionTypes} from "@/store/modules/customer/action-types";
import {UsersMutationTypes} from "@/store/modules/user/mutation-types";
import {useI18n} from "vue-i18n";
import APIs from "@/APIs/APIs";
import {message} from "ant-design-vue";
import {OverviewActionTypes} from "@/store/modules/OverView/action-types";



export default {
  components: {
    MenuUnfoldOutlined,
    MinusOutlined

  },
  setup(){
    const store = useStore()
    const listData = computed(() => store.getters.getOverview.filter((item) => item.page_type === 4))
    const i18n = useI18n()
    const access_token = ref('')
    const visible = ref(false)

    const fetchData = () => {
      store.dispatch(OverviewActionTypes.FETCH_OVERVIEW);
    };
    const addLineInfo = async () => {
      const res = await APIs.init().addLineInfo({access_token:access_token.value})
      if (res.data?.status === 200 || res.data?.status === 201){
        message.success(String(res.data.message))
        visible.value = false
      }else {
        message.error(String(res.data.message))
      }
    }
    const removeTag = async (pageId) => {
      try {
        const res = await APIs.init().deleteOverview({page_id: pageId});
        if (res.status === 201 || res.status === 200) {
          fetchData();
        }
      } catch (error) {
        console.log(error);
      }
    }

    return{
      visible,
      access_token,
      addLineInfo,
      listData,
      removeTag



    }
  }
}
</script>
