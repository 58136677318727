export enum TagsMutationTypes {
    SET_flowTags = 'SET_FLOW_TAGS',
    SET_CustomerTags = 'SET_CUSTOMER_TAGS',
    SET_TriggerTags = 'SET_TRIGGER_TAGS',
    SET_ViralTags = 'SET_Viral_TAGS',
    SET_AutoInboxTags = 'SET_Auto_Inbox_TAGS',
    SET_ProductTags = 'SET_Product_TAGS',
    SET_MetadataRootTag = 'SET_MetadataRootTag',
    SET_TREE_TAG = 'SET_TREE_TAG',
}

