import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, createVNode as _createVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "container-content" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_HomeOutlined = _resolveComponent("HomeOutlined")!
  const _component_a_row = _resolveComponent("a-row")!
  const _component_setting_shop = _resolveComponent("setting-shop")!
  const _component_a_tab_pane = _resolveComponent("a-tab-pane")!
  const _component_FileSyncOutlined = _resolveComponent("FileSyncOutlined")!
  const _component_setting_page = _resolveComponent("setting-page")!
  const _component_user_outlined = _resolveComponent("user-outlined")!
  const _component_role_employee = _resolveComponent("role-employee")!
  const _component_TagsOutlined = _resolveComponent("TagsOutlined")!
  const _component_conversation_tag = _resolveComponent("conversation-tag")!
  const _component_booking_tags = _resolveComponent("booking-tags")!
  const _component_MailOutlined = _resolveComponent("MailOutlined")!
  const _component_quick_message = _resolveComponent("quick-message")!
  const _component_FieldTimeOutlined = _resolveComponent("FieldTimeOutlined")!
  const _component_auto_reply = _resolveComponent("auto-reply")!
  const _component_MenuFoldOutlined = _resolveComponent("MenuFoldOutlined")!
  const _component_LineAccount = _resolveComponent("LineAccount")!
  const _component_a_tabs = _resolveComponent("a-tabs")!
  const _component_a_card = _resolveComponent("a-card")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_a_card, { style: {"width":"100%"} }, {
      default: _withCtx(() => [
        _createVNode(_component_a_tabs, {
          onChange: $setup.changeTab,
          activeKey: $setup.activeKey,
          "onUpdate:activeKey": _cache[0] || (_cache[0] = ($event: any) => (($setup.activeKey) = $event)),
          "tab-position": $setup.tabPosition
        }, {
          default: _withCtx(() => [
            _createVNode(_component_a_tab_pane, { key: "1" }, {
              tab: _withCtx(() => [
                _createVNode(_component_a_row, { justify: "flex-start" }, {
                  default: _withCtx(() => [
                    _createElementVNode("span", null, [
                      ($setup.activeKey === '1')
                        ? (_openBlock(), _createBlock(_component_HomeOutlined, { key: 0 }))
                        : _createCommentVNode("", true),
                      _createTextVNode(" " + _toDisplayString(_ctx.$t('SETTING_SHOP')), 1)
                    ])
                  ]),
                  _: 1
                })
              ]),
              default: _withCtx(() => [
                _createVNode(_component_setting_shop)
              ]),
              _: 1
            }),
            _createVNode(_component_a_tab_pane, { key: "2" }, {
              tab: _withCtx(() => [
                _createVNode(_component_a_row, { justify: "flex-start" }, {
                  default: _withCtx(() => [
                    _createElementVNode("span", null, [
                      ($setup.activeKey === '2')
                        ? (_openBlock(), _createBlock(_component_FileSyncOutlined, { key: 0 }))
                        : _createCommentVNode("", true),
                      _createTextVNode(" " + _toDisplayString(_ctx.$t('page_settings')), 1)
                    ])
                  ]),
                  _: 1
                })
              ]),
              default: _withCtx(() => [
                _createVNode(_component_setting_page)
              ]),
              _: 1
            }),
            _createVNode(_component_a_tab_pane, { key: "3" }, {
              tab: _withCtx(() => [
                _createVNode(_component_a_row, { justify: "flex-start" }, {
                  default: _withCtx(() => [
                    _createElementVNode("span", null, [
                      ($setup.activeKey === '3')
                        ? (_openBlock(), _createBlock(_component_user_outlined, { key: 0 }))
                        : _createCommentVNode("", true),
                      _createTextVNode(" " + _toDisplayString(_ctx.$t('employee_assignment')), 1)
                    ])
                  ]),
                  _: 1
                })
              ]),
              default: _withCtx(() => [
                _createVNode(_component_role_employee)
              ]),
              _: 1
            }),
            _createVNode(_component_a_tab_pane, { key: "4" }, {
              tab: _withCtx(() => [
                _createVNode(_component_a_row, { justify: "flex-start" }, {
                  default: _withCtx(() => [
                    _createElementVNode("span", null, [
                      ($setup.activeKey === '4')
                        ? (_openBlock(), _createBlock(_component_TagsOutlined, { key: 0 }))
                        : _createCommentVNode("", true),
                      _createTextVNode(" " + _toDisplayString(_ctx.$t('conversation_tags')), 1)
                    ])
                  ]),
                  _: 1
                })
              ]),
              default: _withCtx(() => [
                _createVNode(_component_conversation_tag)
              ]),
              _: 1
            }),
            _createVNode(_component_a_tab_pane, { key: "5" }, {
              tab: _withCtx(() => [
                _createVNode(_component_a_row, { justify: "flex-start" }, {
                  default: _withCtx(() => [
                    _createElementVNode("span", null, [
                      ($setup.activeKey === '5')
                        ? (_openBlock(), _createBlock(_component_TagsOutlined, { key: 0 }))
                        : _createCommentVNode("", true),
                      _createTextVNode(" " + _toDisplayString(_ctx.$t('BOOKING_TAGS')), 1)
                    ])
                  ]),
                  _: 1
                })
              ]),
              default: _withCtx(() => [
                _createVNode(_component_booking_tags)
              ]),
              _: 1
            }),
            _createVNode(_component_a_tab_pane, { key: "6" }, {
              tab: _withCtx(() => [
                _createVNode(_component_a_row, { justify: "flex-start" }, {
                  default: _withCtx(() => [
                    _createElementVNode("span", null, [
                      ($setup.activeKey === '6')
                        ? (_openBlock(), _createBlock(_component_MailOutlined, { key: 0 }))
                        : _createCommentVNode("", true),
                      _createTextVNode(" " + _toDisplayString(_ctx.$t('quick_message')), 1)
                    ])
                  ]),
                  _: 1
                })
              ]),
              default: _withCtx(() => [
                _createVNode(_component_quick_message)
              ]),
              _: 1
            }),
            _createVNode(_component_a_tab_pane, { key: "7" }, {
              tab: _withCtx(() => [
                _createVNode(_component_a_row, { justify: "flex-start" }, {
                  default: _withCtx(() => [
                    _createElementVNode("span", null, [
                      ($setup.activeKey === '7')
                        ? (_openBlock(), _createBlock(_component_FieldTimeOutlined, { key: 0 }))
                        : _createCommentVNode("", true),
                      _createTextVNode(" " + _toDisplayString(_ctx.$t('auto_reply')), 1)
                    ])
                  ]),
                  _: 1
                })
              ]),
              default: _withCtx(() => [
                _createVNode(_component_auto_reply)
              ]),
              _: 1
            }),
            _createVNode(_component_a_tab_pane, { key: "9" }, {
              tab: _withCtx(() => [
                _createVNode(_component_a_row, { justify: "flex-start" }, {
                  default: _withCtx(() => [
                    _createElementVNode("span", null, [
                      ($setup.activeKey === '9')
                        ? (_openBlock(), _createBlock(_component_MenuFoldOutlined, { key: 0 }))
                        : _createCommentVNode("", true),
                      _createTextVNode(" " + _toDisplayString(_ctx.$t('LINE_ACCOUNT')), 1)
                    ])
                  ]),
                  _: 1
                })
              ]),
              default: _withCtx(() => [
                _createVNode(_component_LineAccount)
              ]),
              _: 1
            })
          ]),
          _: 1
        }, 8, ["onChange", "activeKey", "tab-position"])
      ]),
      _: 1
    })
  ]))
}