import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-1bcde570"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "container-title" }
const _hoisted_2 = { class: "title-header" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_select_option = _resolveComponent("a-select-option")!
  const _component_a_select = _resolveComponent("a-select")!
  const _component_plus_outlined = _resolveComponent("plus-outlined")!
  const _component_a_button = _resolveComponent("a-button")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("h3", _hoisted_2, _toDisplayString(_ctx.$t('Reload_All_Message')), 1),
    _createVNode(_component_a_select, {
      value: _ctx.selectedPage,
      "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.selectedPage) = $event)),
      mode: "tags",
      allowClear: true,
      dropdownMatchSelectWidth: true,
      style: {"width":"300px","margin-bottom":"16px"},
      placeholder: _ctx.$t('ALL')
    }, {
      default: _withCtx(() => [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.getPages.overView, (item) => {
          return (_openBlock(), _createBlock(_component_a_select_option, {
            key: item.id,
            value: item.page_id
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(item.page_name), 1)
            ]),
            _: 2
          }, 1032, ["value"]))
        }), 128))
      ]),
      _: 1
    }, 8, ["value", "placeholder"]),
    _createVNode(_component_a_select, {
      value: _ctx.selectedTime,
      "onUpdate:value": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.selectedTime) = $event)),
      allowClear: true,
      dropdownMatchSelectWidth: true,
      style: {"width":"100px","margin-bottom":"16px"},
      placeholder: _ctx.$t('ALL')
    }, {
      default: _withCtx(() => [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.time, (item) => {
          return (_openBlock(), _createBlock(_component_a_select_option, {
            key: item.value,
            value: item.value
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(item.label), 1)
            ]),
            _: 2
          }, 1032, ["value"]))
        }), 128))
      ]),
      _: 1
    }, 8, ["value", "placeholder"]),
    _createVNode(_component_a_button, {
      onClick: _ctx.reloadAllMessage,
      type: "primary",
      class: "button-add-product"
    }, {
      default: _withCtx(() => [
        _createVNode(_component_plus_outlined),
        _createTextVNode(" " + _toDisplayString(_ctx.$t('Reload_All_Message')), 1)
      ]),
      _: 1
    }, 8, ["onClick"])
  ]))
}