import {Flow, ZnsTemp} from "@/models/Flows/Flow";
import {FlowContextData} from "@/models/Flows/FlowContext";
import {FlowConstants} from "@/models/Flows/FlowConstants";
import {Metadata} from "@/APIs/response/S_HttpResponse";
import {AutoInbox} from "@/models/AutoInbox/AutoInbox";
import {FlowData} from "@/store/modules/viral/states";

export type AutoInboxData = {
    autoInboxes: AutoInbox[]|undefined,
    metadata: Metadata|undefined,
    loading: boolean
}


export type State = {
    autoInboxData: AutoInboxData | undefined;
    autoInboxHistoryData: AutoInboxData | undefined;
}

export const state: State = {
    autoInboxData: {autoInboxes: [], metadata: {}, loading: false},
    autoInboxHistoryData: {autoInboxes: [], metadata: {}, loading: false},
}



