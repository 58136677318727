import {ActionContext, ActionTree} from "vuex";
import { state, State, OrdersData} from "./states";
import {RootState} from "@/store";
import APIs from "@/APIs/APIs";
import {Mutations} from "./mutations";
import {OrdersResponse, StocksResponse} from "@/APIs/response/S_HttpResponse";
import {OrderActionTypes} from "@/store/modules/orders/action-types";
import {OrdersRequest} from "@/APIs/request/OrdersRequest";
import {OrdersMutationTypes} from "@/store/modules/orders/mutation-types";


type AugmentedActionContext = {
    commit<K extends keyof Mutations>(
        key: K,
        payload: Parameters<Mutations[K]>[1],
    ): ReturnType<Mutations[K]>;
} & Omit<ActionContext<State, RootState>, 'commit'>

export interface Actions {
    [OrderActionTypes.FETCH_ORDERS](
        {commit}: AugmentedActionContext,
         payload:OrdersRequest
    ): Promise<StocksResponse | undefined>;

}

export const actions: ActionTree<State, RootState> & Actions = {
    async [OrderActionTypes.FETCH_ORDERS]({commit}, payload: OrdersRequest): Promise<OrdersResponse | undefined> {
        commit(OrdersMutationTypes.SET_ORDERS,  {
            orders: state.orderData?.orders,
            metadata: state.orderData?.metadata,
            loading: true,
        })
        const response = await APIs.init().getOrders(payload)
        commit(OrdersMutationTypes.SET_ORDERS,  {
            orders: state.orderData?.orders,
            metadata: state.orderData?.metadata,
            loading: false,
        })
        if (response.status === 200) {
            const ordersData: OrdersData = {
                orders: response.data?.data,
                metadata: response.data?.meta_data,
                loading: false,
            }
            console.log('response', ordersData)

            commit(OrdersMutationTypes.SET_ORDERS, ordersData)
        }
        return response.data;
    },
}
