import {ActionContext, ActionTree} from "vuex";
import {State} from "./states";
import {RootState} from "@/store";
import {ProductsActionTypes} from "./action-types";
import APIs from "@/APIs/APIs";
import {ProductMutationTypes} from "./mutation-types";
import {Mutations} from "./mutations";
import {
    Metadata,
    ProductCategoriesResponse,
    ProductDetailResponse,
    ProductGroupsResponse, ProductProvidersResponse,
    TriggersResponse
} from "@/APIs/response/S_HttpResponse";
import {TriggerRequest} from "@/APIs/request/TriggerRequest";
import {
    ProductCategoryRequest,
    ProductDetailRequest,
    ProductGroupRequest,
    ProductProviderRequest
} from "@/APIs/request/ProductRequest";
import {CustomersMutationTypes} from "@/store/modules/customer/mutation-types";
import {state} from "@/store/modules/product/states";
import {Constant} from "@/utils/Constants";
import {ProductGroup} from "@/models/Products/Products";

type AugmentedActionContext = {
    commit<K extends keyof Mutations>(
        key: K,
        payload: Parameters<Mutations[K]>[1],
    ): ReturnType<Mutations[K]>;
} & Omit<ActionContext<State, RootState>, 'commit'>

export interface Actions {
    [ProductsActionTypes.FETCH_PRODUCTS](
        {commit}: AugmentedActionContext,
        payload: ProductGroupRequest,
    ): Promise<ProductGroupsResponse | undefined>;
}

export interface Actions {
    [ProductsActionTypes.FETCH_PRODUCT_DETAIL](
        {commit}: AugmentedActionContext,
        payload: ProductDetailRequest,
    ): Promise<ProductDetailResponse | undefined>;
}

export interface Actions {
    [ProductsActionTypes.FETCH_PRODUCT_CATEGORIES](
        {commit}: AugmentedActionContext,
        payload: ProductCategoryRequest,
    ): Promise<ProductCategoriesResponse | undefined>;
}

export interface Actions {
    [ProductsActionTypes.FETCH_PRODUCT_PROVIDERS](
        {commit}: AugmentedActionContext,
        payload: ProductProviderRequest,
    ): Promise<ProductProvidersResponse | undefined>;
}

export const actions: ActionTree<State, RootState> & Actions = {
    async [ProductsActionTypes.FETCH_PRODUCTS]({commit}, payload: ProductGroupRequest): Promise<ProductGroupsResponse | undefined> {
        commit(ProductMutationTypes.SET_PRODUCTS,  {
            productGroups: state.productGroupData?.productGroups,
            metadata: state.productGroupData?.metadata,
            loading: true
        })
        const response = await APIs.init().getProductGroups(payload)
        commit(ProductMutationTypes.SET_PRODUCTS,  {
            productGroups: state.productGroupData?.productGroups,
            metadata: state.productGroupData?.metadata,
            loading: false
        })
        if (response.status === 200) {
            commit(ProductMutationTypes.SET_PRODUCTS,  {
                productGroups: response.data?.data,
                metadata: response.data?.meta_data,
                loading: false
            })
        }
        return response.data;
    },
    async [ProductsActionTypes.FETCH_PRODUCT_DETAIL]({commit}, payload: ProductDetailRequest): Promise<ProductDetailResponse | undefined> {
        const response = await APIs.init().getProductDetail(payload)
        if (response.status === 200) {
            commit(ProductMutationTypes.SET_PRODUCTS_DETAIL, response.data?.data)
        }
        return response.data;
    },
    async [ProductsActionTypes.FETCH_PRODUCT_CATEGORIES]({commit}, payload: ProductCategoryRequest): Promise<ProductCategoriesResponse | undefined> {
        const response = await APIs.init().getProductCategories(payload)
        if (response.status === 200) {
            commit(ProductMutationTypes.SET_PRODUCTS_CATEGORIES, response.data?.data)
        }
        return response.data;
    },
    async [ProductsActionTypes.FETCH_PRODUCT_PROVIDERS]({commit}, payload: ProductProviderRequest): Promise<ProductProvidersResponse | undefined> {
        const response = await APIs.init().getProductProviders(payload)
        if (response.status === 200) {
            commit(ProductMutationTypes.SET_PRODUCTS_PROVIDERS, response.data?.data)
        }
        return response.data;
    },
}
