import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, renderList as _renderList, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-7601b07d"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "container-title" }
const _hoisted_2 = { class: "title-header" }
const _hoisted_3 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_plus_outlined = _resolveComponent("plus-outlined")!
  const _component_a_button = _resolveComponent("a-button")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_eye_outlined = _resolveComponent("eye-outlined")!
  const _component_edit_outlined = _resolveComponent("edit-outlined")!
  const _component_a_menu_item = _resolveComponent("a-menu-item")!
  const _component_delete_outlined = _resolveComponent("delete-outlined")!
  const _component_a_popconfirm = _resolveComponent("a-popconfirm")!
  const _component_a_menu = _resolveComponent("a-menu")!
  const _component_a_dropdown = _resolveComponent("a-dropdown")!
  const _component_a_table = _resolveComponent("a-table")!
  const _component_a_input = _resolveComponent("a-input")!
  const _component_a_form_item = _resolveComponent("a-form-item")!
  const _component_a_select_option = _resolveComponent("a-select-option")!
  const _component_a_select = _resolveComponent("a-select")!
  const _component_a_form = _resolveComponent("a-form")!
  const _component_a_modal = _resolveComponent("a-modal")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("h3", _hoisted_2, _toDisplayString(_ctx.$t(_ctx.$route.name)), 1),
      (_ctx.getMyRole(_ctx.modules.stocks)?.is_create)
        ? (_openBlock(), _createBlock(_component_a_button, {
            key: 0,
            type: "primary",
            class: "button-add-product",
            onClick: _ctx.create
          }, {
            default: _withCtx(() => [
              _createVNode(_component_plus_outlined),
              _createTextVNode(" " + _toDisplayString(_ctx.$t('ADD')), 1)
            ]),
            _: 1
          }, 8, ["onClick"]))
        : _createCommentVNode("", true)
    ]),
    _createVNode(_component_a_table, {
      size: "middle",
      scroll: {  y: '80vh' },
      columns: _ctx.columns,
      "data-source": _ctx.getStocks.stocks,
      class: "table",
      pagination: _ctx.pagination,
      onChange: _ctx.handleTableChange,
      loading: _ctx.getStocks.loading
    }, {
      shop_name: _withCtx(({record}) => [
        _createVNode(_component_router_link, {
          to: '/stock/'+record.id,
          class: "table-row-name"
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(record.shop_name), 1)
          ]),
          _: 2
        }, 1032, ["to"])
      ]),
      action: _withCtx(({record}) => [
        _createVNode(_component_a_dropdown, null, {
          overlay: _withCtx(() => [
            _createVNode(_component_a_menu, null, {
              default: _withCtx(() => [
                (_ctx.getMyRole(_ctx.modules.stocks)?.is_edit)
                  ? (_openBlock(), _createElementBlock("a", {
                      key: 0,
                      onClick: ($event: any) => (_ctx.edit(record))
                    }, [
                      _createVNode(_component_a_menu_item, { class: "router-product-edit" }, {
                        default: _withCtx(() => [
                          _createVNode(_component_edit_outlined),
                          _createTextVNode(" " + _toDisplayString(_ctx.$t('EDIT')), 1)
                        ]),
                        _: 1
                      })
                    ], 8, _hoisted_3))
                  : _createCommentVNode("", true),
                (_ctx.getMyRole(_ctx.modules.stocks)?.is_delete)
                  ? (_openBlock(), _createBlock(_component_a_menu_item, { key: 1 }, {
                      default: _withCtx(() => [
                        _createVNode(_component_a_popconfirm, {
                          placement: "bottom",
                          "ok-text": _ctx.$t('OK'),
                          "cancel-text": _ctx.$t('CANCEL'),
                          onConfirm: ($event: any) => (_ctx.confirmDelete(record))
                        }, {
                          title: _withCtx(() => [
                            _createElementVNode("p", null, _toDisplayString(_ctx.$t('CONFIRM_DELETE') + record.shop_name + '?'), 1)
                          ]),
                          default: _withCtx(() => [
                            _createVNode(_component_delete_outlined),
                            _createTextVNode(" " + _toDisplayString(_ctx.$t('DELETE')), 1)
                          ]),
                          _: 2
                        }, 1032, ["ok-text", "cancel-text", "onConfirm"])
                      ]),
                      _: 2
                    }, 1024))
                  : _createCommentVNode("", true)
              ]),
              _: 2
            }, 1024)
          ]),
          default: _withCtx(() => [
            _createVNode(_component_a_button, { type: "text" }, {
              default: _withCtx(() => [
                _createVNode(_component_eye_outlined)
              ]),
              _: 1
            })
          ]),
          _: 2
        }, 1024)
      ]),
      _: 1
    }, 8, ["columns", "data-source", "pagination", "onChange", "loading"]),
    _createVNode(_component_a_modal, {
      footer: null,
      visible: _ctx.editVisible,
      "onUpdate:visible": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.editVisible) = $event)),
      title: _ctx.formState.id?_ctx.$t('UPDATE_STOCK'):_ctx.$t('ADD_NEW_STOCK')
    }, {
      default: _withCtx(() => [
        _createVNode(_component_a_form, {
          ref: "formRef",
          model: _ctx.formState,
          rules: _ctx.rules,
          "label-col": _ctx.labelCol,
          "wrapper-col": _ctx.wrapperCol
        }, {
          default: _withCtx(() => [
            _createVNode(_component_a_form_item, {
              label: _ctx.$t('NAME'),
              name: "shop_name"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_a_input, {
                  value: _ctx.formState.shop_name,
                  "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.formState.shop_name) = $event))
                }, null, 8, ["value"])
              ]),
              _: 1
            }, 8, ["label"]),
            _createVNode(_component_a_form_item, {
              label: _ctx.$t('phone_number'),
              name: "shop_tel"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_a_input, {
                  value: _ctx.formState.shop_tel,
                  "onUpdate:value": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.formState.shop_tel) = $event))
                }, null, 8, ["value"])
              ]),
              _: 1
            }, 8, ["label"]),
            _createVNode(_component_a_form_item, {
              label: "Email",
              name: "shop_email"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_a_input, {
                  value: _ctx.formState.shop_email,
                  "onUpdate:value": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.formState.shop_email) = $event))
                }, null, 8, ["value"])
              ]),
              _: 1
            }),
            _createVNode(_component_a_form_item, {
              label: _ctx.$t('PROVINCE'),
              name: "province_id"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_a_select, {
                  value: _ctx.formState.province_id,
                  "onUpdate:value": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.formState.province_id) = $event)),
                  showSearch: "",
                  style: {"width":"100%"},
                  "filter-option": false,
                  "not-found-content": null,
                  placeholder: _ctx.$t('PROVINCE'),
                  onChange: _ctx.provinceChange,
                  onSearch: _ctx.provinceSearch
                }, {
                  default: _withCtx(() => [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.getProvinces, (item) => {
                      return (_openBlock(), _createBlock(_component_a_select_option, {
                        key: item.id,
                        value: item.id
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(item.name), 1)
                        ]),
                        _: 2
                      }, 1032, ["value"]))
                    }), 128))
                  ]),
                  _: 1
                }, 8, ["value", "placeholder", "onChange", "onSearch"])
              ]),
              _: 1
            }, 8, ["label"]),
            _createVNode(_component_a_form_item, {
              label: _ctx.$t('DISTRICT'),
              name: "district_id"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_a_select, {
                  value: _ctx.formState.district_id,
                  "onUpdate:value": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.formState.district_id) = $event)),
                  showSearch: "",
                  placeholder: _ctx.$t('DISTRICT'),
                  style: {"width":"100%"},
                  "filter-option": false,
                  "not-found-content": null,
                  onChange: _ctx.districtChange,
                  onSearch: _ctx.districtSearch
                }, {
                  default: _withCtx(() => [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.getDistricts, (item) => {
                      return (_openBlock(), _createBlock(_component_a_select_option, {
                        key: item.id,
                        value: item.id
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(item.name), 1)
                        ]),
                        _: 2
                      }, 1032, ["value"]))
                    }), 128))
                  ]),
                  _: 1
                }, 8, ["value", "placeholder", "onChange", "onSearch"])
              ]),
              _: 1
            }, 8, ["label"]),
            _createVNode(_component_a_form_item, {
              label: _ctx.$t('WARD'),
              name: "ward_id"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_a_select, {
                  value: _ctx.formState.ward_id,
                  "onUpdate:value": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.formState.ward_id) = $event)),
                  showSearch: "",
                  placeholder: _ctx.$t('WARD'),
                  "filter-option": false,
                  "not-found-content": null,
                  onSearch: _ctx.wardSearch,
                  style: {"width":"100%"}
                }, {
                  default: _withCtx(() => [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.getWards, (item) => {
                      return (_openBlock(), _createBlock(_component_a_select_option, {
                        key: item.id,
                        value: item.id
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(item.name), 1)
                        ]),
                        _: 2
                      }, 1032, ["value"]))
                    }), 128))
                  ]),
                  _: 1
                }, 8, ["value", "placeholder", "onSearch"])
              ]),
              _: 1
            }, 8, ["label"]),
            _createVNode(_component_a_form_item, {
              label: _ctx.$t('ADDRESS'),
              name: "address"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_a_input, {
                  value: _ctx.formState.shop_address,
                  "onUpdate:value": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.formState.shop_address) = $event))
                }, null, 8, ["value"])
              ]),
              _: 1
            }, 8, ["label"]),
            _createVNode(_component_a_form_item, { "wrapper-col": { offset: 8 } }, {
              default: _withCtx(() => [
                _createVNode(_component_a_button, {
                  type: "primary",
                  onClick: _ctx.onSubmit
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.$t('CREATE')), 1)
                  ]),
                  _: 1
                }, 8, ["onClick"]),
                _createVNode(_component_a_button, {
                  style: {"margin-left":"10px"},
                  onClick: _cache[7] || (_cache[7] = ($event: any) => (_ctx.editVisible=!_ctx.editVisible))
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.$t('CANCEL')), 1)
                  ]),
                  _: 1
                })
              ]),
              _: 1
            })
          ]),
          _: 1
        }, 8, ["model", "rules", "label-col", "wrapper-col"])
      ]),
      _: 1
    }, 8, ["visible", "title"])
  ], 64))
}