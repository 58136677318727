import {MutationTree} from 'vuex';

import {Shop, SuperUserInfo} from '@/models/Users';

import {State} from './states';
import {UsersMutationTypes} from './mutation-types';
import {Locale} from "@/models/locales/Locales";
import {SelectionAnt} from "@/models/ShopPages/ConfigData";
import {FacebookPostData} from "@/models/facebook/FacebookPost";
import {Notification} from "@/models/notification/Notification";
import {CustomersMutationTypes} from "@/store/modules/customer/mutation-types";
import {Schedule} from "@/models/notification/Schedule";
import {Metadata} from "@/APIs/response/S_HttpResponse";

export type Mutations<S = State> = {
    [UsersMutationTypes.SET_UserInfo](state: S, payload: SuperUserInfo | undefined): void;
    [UsersMutationTypes.SET_IsLogin](state: S, payload: boolean): void;
    [UsersMutationTypes.SET_Lang](state: S, payload: string): void;
    [UsersMutationTypes.SET_PageAccessLatest](state: S, payload: string): void;
    [UsersMutationTypes.SET_IsUserInfoFetching](state: S, payload: boolean): void;
    [UsersMutationTypes.SET_CurrentShop](state: S, payload: number): void;
    [UsersMutationTypes.SET_Locales](state: S, payload: Locale[]): void;
    [UsersMutationTypes.SET_SELECTED_POSTS](state: S, payload: FacebookPostData[]): void;
    [UsersMutationTypes.SET_NOTIFICATIONS](state: S, payload: Notification[]): void;
    [UsersMutationTypes.ADD_NOTIFICATIONS](state: S, payload: Notification[]): void;
    [UsersMutationTypes.READ_NOTIFICATION](state: S, payload: string): void;
    [UsersMutationTypes.SET_COUNT_UNREAD_NOTIFICATION](state: S, payload: number): void;
    [UsersMutationTypes.SET_SCHEDULES](state: S, payload: Schedule[]): void;
    [UsersMutationTypes.SET_SCHEDULE_METADATA](state: S, payload: Metadata): void;
    [UsersMutationTypes.DELETE_SCHEDULE](state: S, payload: string): void;
    [UsersMutationTypes.SET_MENU_COLLAPSED](state: S, payload: boolean): void;
    [UsersMutationTypes.SET_CODE_VERIFIER](state: S,payload: string) : void;
    [UsersMutationTypes.SET_SHOPS](state: S,payload: Shop[]) : void;
}

export const mutations: MutationTree<State> & Mutations = {
    [UsersMutationTypes.SET_UserInfo](state: State, payload: SuperUserInfo) {
        if (state.userInfo && state.userInfo?.current_shop_id && payload) {
            payload.current_shop_id = state.userInfo?.current_shop_id
        } else if (payload && payload.shops && !payload?.current_shop_id) {
            payload.current_shop_id = payload.shops[0].id
        }

        // if (payload && !payload.token && state.userInfo) {
        //     payload.token = state.userInfo.token
        // }
        state.userInfo = payload;
    },
    [UsersMutationTypes.SET_IsLogin](state: State, payload: boolean) {
        state.isLogin = payload;
    },
    [UsersMutationTypes.SET_CODE_VERIFIER](state: State, payload: string) {
        state.code_verifier = payload;
    },
    [UsersMutationTypes.SET_Lang](state: State, payload: string) {
        state.lang = payload;
    },
    [UsersMutationTypes.SET_PageAccessLatest](state: State, payload: string) {
        state.pageAccessLatest = payload;
    },
    [UsersMutationTypes.SET_IsUserInfoFetching](state: State, payload: boolean) {
        state.isUserInfoFetching = payload;
    },
    [UsersMutationTypes.SET_CurrentShop](state: State, payload: number) {
        if (state.userInfo) {
            if(state.userInfo)
            state.userInfo.current_shop_id = payload
        }
    },
    [UsersMutationTypes.SET_Locales](state: State, payload: Locale[]) {
        state.locales = payload;
    },
    [UsersMutationTypes.SET_SELECTED_POSTS](state: State, payload: FacebookPostData[]) {
        state.selectedPosts = payload;
    },

    [UsersMutationTypes.SET_NOTIFICATIONS](state: State, payload: Notification[]): void {
        state.notifications = payload
    },
    [UsersMutationTypes.ADD_NOTIFICATIONS](state: State, payload: Notification[]): void {
        state.notifications = [...state.notifications,...payload]
    },
    [UsersMutationTypes.READ_NOTIFICATION](state: State, payload: string): void {
        state.notifications = state.notifications.map((item)=>{
            if (item._id===payload){
                item.is_read=true
            }
            return item;
        })
    },
    [UsersMutationTypes.SET_COUNT_UNREAD_NOTIFICATION](state: State, payload: number): void {
        state.countUnreadNotification = payload
    },
    [UsersMutationTypes.SET_SCHEDULES](state: State, payload: Schedule[]): void {
        state.schedules = payload
    },
    [UsersMutationTypes.SET_SCHEDULE_METADATA](state: State, payload: Metadata): void {
        state.scheduleMetadata = payload
    },
    [UsersMutationTypes.DELETE_SCHEDULE](state: State, payload: string): void {
        state.schedules = state.schedules.filter(x=>x._id!==payload)
    },
    [UsersMutationTypes.SET_MENU_COLLAPSED](state: State, payload: boolean): void {
        state.menuCollapsed=payload
    },
    [UsersMutationTypes.SET_SHOPS](state: State, payload: Shop[]): void {
        state.shops=payload
    },

};
