import {ActionContext, ActionTree} from "vuex";
import {State} from "./states";
import {RootState} from "@/store";
import {TransporterActionTypes} from "@/store/modules/transporter/action-types";
import APIs from "@/APIs/APIs";
import {TransporterMutationTypes} from "@/store/modules/transporter/mutation-types";
import {Mutations} from "./mutations";
import {TransporterResponse} from "@/APIs/response/S_HttpResponse"
import {TransporterRequest} from "@/APIs/request/TransporterRequest";


type AugmentedActionContext = {
    commit<K extends keyof Mutations>(
        key: K,
        payload: Parameters<Mutations[K]>[1],
    ): ReturnType<Mutations[K]>;
} & Omit<ActionContext<State, RootState>, 'commit'>

export interface Actions {
    [TransporterActionTypes.FETCH_TRANSPORTER](
        {commit}: AugmentedActionContext,
        payload?: TransporterRequest,
    ): Promise<TransporterResponse | undefined>;
}

export const actions: ActionTree<State, RootState> & Actions = {
    async [TransporterActionTypes.FETCH_TRANSPORTER]({commit}): Promise<TransporterResponse | undefined> {
        const response = await APIs.init().getTransporter()
        if (response.status === 200) {
            commit(TransporterMutationTypes.GET_TRANSPORTER, response.data?.data)
        }
        return response.data;
    }

}