import {State} from './states';
import {SuperUserInfo} from "@/models/Users";
import {AWS_DO_FileStorageMutationTypes} from "./mutation-types";
import {AWS_DO_Model} from "@/models/AWS_DO/AWS_DO";
import {MutationTree} from "vuex";

export type Mutations<S = State> = {
    [AWS_DO_FileStorageMutationTypes.SET_FILES](state: S, payload: AWS_DO_Model[] | undefined): void;
}

export const mutations: MutationTree<State> & Mutations = {
    [AWS_DO_FileStorageMutationTypes.SET_FILES](state: State, payload: AWS_DO_Model[]) {
        state.awsDoFiles = payload
    }
}