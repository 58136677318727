import {CustomerData, State} from "./states";
import {CustomersMutationTypes} from "./mutation-types";
import {CXViewGroup, CXViewLogin} from "@/models/CXView/CXViewModels";
import {Careers, Customer, CustomerHistory, CustomerHistoryComment} from "@/models/Customers/Customers";
import {Notification} from "@/models/notification/Notification";
import {CustomerConfig, CustomerConfigColumn} from "@/models/CustomerConfig/CustomerConfig";


export type Mutations<S = State> = {
    [CustomersMutationTypes.SET_Customers](state: S, payload: CustomerData | undefined): void;
    [CustomersMutationTypes.SET_Columns](state: S, payload: any[]): void;
    [CustomersMutationTypes.SET_CXView](state: S, payload: CXViewLogin | undefined): void;
    [CustomersMutationTypes.SET_CXView](state: S, payload: CXViewLogin | undefined): void;
    [CustomersMutationTypes.SET_CXView_GROUP](state: S, payload: CXViewGroup[] | undefined): void;
    [CustomersMutationTypes.SET_CXView_GROUP_CURRENT](state: S, payload: string | undefined): void;
    [CustomersMutationTypes.SET_CUSTOMER_HISTORY_COMMENT](state: S, payload: CustomerHistory): void;
    [CustomersMutationTypes.SET_CUSTOMER](state: S, payload: Customer): void;
    [CustomersMutationTypes.SET_CAREER](state: S, payload: Careers[]): void;
    [CustomersMutationTypes.SET_CUSTOMER_CONFIG](state: S, payload: CustomerConfigColumn[] |undefined): void;
    [CustomersMutationTypes.REFORMAT_COLUMNS](state: S): void;
    [CustomersMutationTypes.SET_CUSTOMER_CHANGE](state: S, payload: any[] |undefined): void;

}

export const mutations: Mutations = {
    [CustomersMutationTypes.SET_Customers](state: State, payload: CustomerData | undefined): void {
        state.customerData = payload
    },


    [CustomersMutationTypes.SET_Columns](state: State, payload: any[]): void {
        state.columns = payload
    },
    [CustomersMutationTypes.SET_CXView](state: State, payload: CXViewLogin | undefined): void {
        state.cxView = payload
    },
    [CustomersMutationTypes.SET_CXView_GROUP](state: State, payload: CXViewGroup[]): void {
        state.cxViewGroup = payload
    },
    [CustomersMutationTypes.SET_CXView_GROUP_CURRENT](state: State, payload: string): void {
        state.cxViewGroupCurrent = payload
    },
    [CustomersMutationTypes.SET_CUSTOMER_HISTORY_COMMENT](state: State, payload: CustomerHistory): void {
        if (state.customerData && state.customerData.customers) {
            state.customerData.customers.map((item) => {
                if (item.id === payload.shop_customer_id) {
                    item.history_comments = payload.content_history;
                }
            })
        }
        // state.cxViewGroupCurrent = payload
    },
    [CustomersMutationTypes.SET_CUSTOMER](state: State, payload: Customer): void {
        state.customer = payload
    },
    [CustomersMutationTypes.SET_CAREER](state: State, payload: Careers[]): void {
        state.careers = payload
    },
    [CustomersMutationTypes.SET_CUSTOMER_CONFIG](state: State, payload: CustomerConfigColumn[] |undefined): void {
        if(payload){
            for (let i = 0 ; i<payload.length;i++){
              const check = state.columns.find((item) =>item.id === (payload[i].id+99))
                if(!check){
                    state.columns.push({
                        id:(payload[i].id+99),
                        key:payload[i].identified_name,
                        myTitle: payload[i].name,
                        dataIndex: payload[i].id,
                        title:payload[i].name,
                        visible:false,
                        width: "12%"
                    })
                }
            }
        }
        state.customerConfig = payload
    },
    [CustomersMutationTypes.REFORMAT_COLUMNS](state: State): void {
        let columns:any[]=[]
        for (let i = 0; i < state.columns.length; i++) {
            const existed=columns.find((x:any)=>x.id=== state.columns[i].id)
            if (!existed){
                columns=[...columns,state.columns[i]]
            }
        }
        state.columns=columns;
    },
    [CustomersMutationTypes.SET_CUSTOMER_CHANGE](state: State, payload: any[]): void {
        state.customerChange = payload
    },



    }
