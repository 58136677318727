<template>
<h3>{{'login'}}</h3>
</template>
<script>
import {useRoute, useRouter} from "vue-router";
import {computed, onMounted} from "vue";
import {useStore} from "vuex";
import {MixUtils} from "@/utils/Utils";

export default {
  setup(){
    const route = useRoute()
    const router = useRouter()
    const store = useStore()
    const userInfo = computed(()=> store.getters.getUserInfo)

    const fetch =() =>{
      if(userInfo.value === null){

        router.push(`/account_login?callback=${route.query.callback}`)

      }else {
        console.log(store.getters.getUserInfo)
        let href = ''
          href = route.query.callback +"/login" + "?user_id=" + store.getters.getUserInfo?.id + "&token_login=" +store.getters.getUserInfo?.token_login + "&token_web=" + store.getters.getUserInfo?.token_web + "&shop_id=" + store.getters.getUserInfo?.current_shop_id;
          if (store.getters.getCurrentShop&&store.getters.getCurrentShop.pages&&store.getters.getCurrentShop.pages.length){
            href+="&page_id=" + store.getters.getCurrentShop.pages[0].page_id
          }

        location.href = href
      }

    }
    onMounted(()=>{
      fetch()
    })
    return{}
  }
}
</script>
