<template>
  <div style="display: flex; justify-content: flex-end; align-items: flex-end; height: 40px">
    <span style="">{{data.name}}</span>
    <div style="display: flex; width: 30px; height: 40px; position: absolute; right: -12px; align-items: center; justify-content: center">
      <Handle :id="data.id" type="source" class="handle" :position="Position.Right" :style="sourceHandleStyleC" />
    </div>
  </div>

</template>

<script>
import {defineComponent, defineProps, defineEmits, onMounted, ref} from "vue";
import { computed } from 'vue'
import {Position, VueFlow, useVueFlow, Handle, MarkerType} from '@vue-flow/core'

const props = defineProps({
  data: {
    type: Object,
    required: true,
  },
})


export default defineComponent({
  name: "HandleCustom",
  components:{
    Handle,
  },
  props: ['type', 'data', 'index'],
  setup(props, {emit}){


    const sourceHandleStyleC = computed(() => ({
      position: 'relative',
      zIndex: 9999,
      textAlign: 'right',
      }))

    return{
      sourceHandleStyleC,
      Position,
    }
  }
})
</script>

<style scoped>
.next_step{
  position: absolute;
  bottom: 8px;
  right: 8px;
  text-align: right;
}
</style>
