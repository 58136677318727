


import {PageConfig} from "@/models/PageConfig/PageConfig";



export type State = {
    pageConfig: PageConfig[] | undefined
    pageId: number | undefined
    tabkey:string| undefined
}

export const state: State = {
   pageConfig: [],
    pageId: 0,
    tabkey: '',
}
