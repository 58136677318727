import {ActionContext, ActionTree} from "vuex";
import {State} from "./states";
import {RootState} from "@/store";
import APIs from "@/APIs/APIs";
import { Mutations } from "./mutations";
import { EmployeeActionTypes } from "./action-types";
import { EmployeeResponse, EmployeeResponseSearch } from "@/APIs/response/S_HttpResponse";
import { EmployeeMutationTypes } from "./mutation-types";
import { EmployeeRequest, EmployeeSearchRequest } from "@/APIs/request/EmployeeRequest";

type AugmentedActionContext = {
    commit<K extends keyof Mutations>(
        key: K,
        payload: Parameters<Mutations[K]>[1],
    ): ReturnType<Mutations[K]>;
} & Omit<ActionContext<State, RootState>, 'commit'>

export interface Actions {
    [EmployeeActionTypes.FETCH_EMPLOYEE_SEARCH](
        {commit}: AugmentedActionContext,
        payload?: EmployeeSearchRequest,
    ): Promise<EmployeeResponseSearch | undefined>;
    [EmployeeActionTypes.FETCH_EMPLOYEE](
        {commit}: AugmentedActionContext,
        payload: EmployeeRequest,
    ): Promise<EmployeeResponse | undefined>;
    
}

export const actions: ActionTree<State, RootState> & Actions = {
    async [EmployeeActionTypes.FETCH_EMPLOYEE_SEARCH]({commit }, payload: EmployeeSearchRequest): Promise<EmployeeResponseSearch | undefined> {
        const response = await APIs.init().getEmployeeSearch(payload.name)
        const data = response.data?.data
        
        if (response.status === 200) {
            commit(EmployeeMutationTypes.GET_EMPLOYEE_SEARCH, data)
        }
        return response.data;
    },
    async [EmployeeActionTypes.FETCH_EMPLOYEE]({commit }, payload: EmployeeRequest): Promise<EmployeeResponse | undefined> {
        const response = await APIs.init().getEmployee(payload)
        if (response.status === 200) {
            commit(EmployeeMutationTypes.GET_EMPLOYEE,
                 { data: response.data?.data,
                metadata: response.data?.meta_data,})
        }
        return response.data;
    },
    

}


