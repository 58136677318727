export enum UsersMutationTypes {
    SET_UserInfo = 'SET_USER_INFO',
    SET_IsLogin = 'SET_IS_LOGIN',
    SET_IsUserInfoFetching = 'SET_IS_USER_INFO_FETCHING',
    SET_CurrentShop = 'SET_CURRENT_SHOP',
    SET_Lang = 'SET_LANG',
    SET_PageAccessLatest = 'SET_PAGE_ACCESS_LATEST',
    SET_Locales = 'SET_LOCALES',
    SET_SELECTED_POSTS = 'SET_SELECTED_POSTS',
    SET_NOTIFICATIONS = 'SET_NOTIFICATIONS',
    ADD_NOTIFICATIONS = 'ADD_NOTIFICATIONS',
    SET_COUNT_UNREAD_NOTIFICATION = 'SET_COUNT_UNREAD_NOTIFICATION',
    READ_NOTIFICATION = 'READ_NOTIFICATION',
    SET_SCHEDULES = 'SET_SCHEDULES',
    SET_SCHEDULE_METADATA = 'SET_SCHEDULE_METADATA',
    DELETE_SCHEDULE = 'DELETE_SCHEDULE',
    SET_MENU_COLLAPSED = 'SET_MENU_COLLAPSED',
    SET_CODE_VERIFIER = 'SET_CODE_VERIFIER',
    SET_SHOPS = 'SET_SHOPS'
}
