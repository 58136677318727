<template>
  <a-popover trigger="click" placement="right" v-model:visible="popoverVisible">
    <template #title>
      <div style="display: flex">
         <span class="header-postback-pop">
        {{$t('BUTTON_SETTING')}}
      </span>
        <a-button type="text" @click="()=>popoverVisible=false">
          <close-outlined/>
        </a-button>
      </div>

    </template>

    <template #content>
      <a-form
          class="form-postback"
          ref="formRefPostBack"
          :rules="rules"
          :model="formState"
          layout="vertical"
      >
        <a-form-item :label="$t('FLOW_FACEBOOK_BUTTON_NAME')" v-bind="validateInfos.title">
          <a-textarea show-count :maxlength="35" placeholder="Title" v-model:value="formState.title"  name="title"/>
        </a-form-item>
        <a-form-item v-if="formState.image_icon"  :label="$t('icon')" >
             <span class="ant-upload-picture-card-wrapper">
              <div class="ant-upload-list ant-upload-list-picture-card" v-if="formState.image_icon">
                <div class="ant-upload-list-picture-card-container">
                  <span>
                    <div
                        class="ant-upload-list-item ant-upload-list-item-done ant-upload-list-item-list-type-picture-card">
                      <div class="ant-upload-list-item-info">
                        <span>
                          <a class="ant-upload-list-item-thumbnail"
                             :href="formState.image_icon"
                             target="_blank" rel="noopener noreferrer">
                            <img :src="formState.image_icon"
                                 alt="image.png"
                                 class="ant-upload-list-item-image">
                          </a>
                        </span>
                      </div>
                      <span class="ant-upload-list-item-actions">
                        <a :href="formState.image_icon"
                           target="_blank"
                           rel="noopener noreferrer" title="Preview file">
                          <eye-outlined/>
                        </a>
                        <upload-outlined @click="showImageSelectionModal('icon')" title="Upload" class="anticon-delete"/>
                      </span>
                    </div>
                  </span>
                </div>
              </div>
              <div v-else class="ant-upload ant-upload-select ant-upload-select-picture-card" @click="showImageSelectionModal('icon')">
                  <span role="button" tabindex="0" class="ant-upload">
                    <div>
                      <plus-outlined/>
                      <div class="ant-upload-text">{{ $t('UPLOAD') }}</div>
                    </div>
                  </span>
              </div>
            </span>

        </a-form-item>
        <a-form-item :label="$t('FLOW_FACEBOOK_BUTTON_TYPE')" name="type">
          <a-select v-model:value="formState.type" @change="buttonTypeChange">
            <a-select-option v-for="item in zaloButtonTypes" :key="item" :value="item">{{ $t(item) }}</a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item :label="$t('FLOW_FACEBOOK_BUTTON_WEB_URL_LABEL')"
                     v-if="formState.type===zaloButtonTypes[0]" v-bind="validateInfos.url">
          <a-input placeholder="https://biglead.live" v-model:value="formState.url" name="url"/>
        </a-form-item>
        <div v-else-if="formState.type===zaloButtonTypes[2]">
          <a-form-item :label="$t('phone_number')" v-bind="validateInfos.phone_code">
            <a-input placeholder="+84987654321" v-model:value="formState.phone_code" name="phone_code"/>
          </a-form-item>
          <a-form-item :label="$t('SMS_CONTENT_BUTTON')" v-bind="validateInfos.sms_content">
            <a-input :placeholder="$t('EXAMPLE_INPUT')" v-model:value="formState.sms_content" name="sms_content"/>
          </a-form-item>
        </div>
        <a-form-item :label="$t('phone_number')" v-else-if="formState.type===zaloButtonTypes[3]" v-bind="validateInfos.phone_code">
          <a-input placeholder="+84987654321" v-model:value="formState.phone_code" name="phone_code"/>
        </a-form-item>

        <div v-else>
          <a-form-item :label="$t('FLOW_FACEBOOK_BUTTON_POST_BACK_MESSAGE_CONTENT_LABEL')">
            <a-textarea name="reply_message" ref="inputMessageContent"
                        :placeholder="$t('FLOW_FACEBOOK_BUTTON_POST_BACK_MESSAGE_CONTENT_PLACEHOLDER')" :rows="3"
                        v-model:value="formState.reply_message" @change="onchangeText"  @click="forcusMessagetext"/>
            <a-tag @click="messageFullName" style="cursor: pointer">##full_name##</a-tag>
          </a-form-item>
          <a-form-item>
            <span class="ant-upload-picture-card-wrapper">
              <div class="ant-upload-list ant-upload-list-picture-card" v-for="item in fileList" :key="item">
                <div class="ant-upload-list-picture-card-container">
                  <span>
                    <div
                        class="ant-upload-list-item ant-upload-list-item-done ant-upload-list-item-list-type-picture-card">
                      <div class="ant-upload-list-item-info">
                        <span>
                          <a class="ant-upload-list-item-thumbnail"
                             :href="item.url"
                             target="_blank" rel="noopener noreferrer">
                            <img :src="item.url"
                                 alt="image.png"
                                 class="ant-upload-list-item-image">
                          </a>
                        </span>
                      </div>
                      <span class="ant-upload-list-item-actions">
                        <a :href="item.url"
                           target="_blank"
                           rel="noopener noreferrer" title="Preview file">
                          <eye-outlined/>
                        </a>
                        <delete-outlined @click="removeImage(item)"/>
                      </span>
                    </div>
                  </span>
                </div>
              </div>
              <div class="ant-upload ant-upload-select ant-upload-select-picture-card" @click="showImageSelectionModal">
                  <span role="button" tabindex="0" class="ant-upload">
                    <div>
                      <plus-outlined/>
                      <div class="ant-upload-text">{{ $t('UPLOAD') }}</div>
                    </div>
                  </span>
              </div>
            </span>
          </a-form-item>

          <a-form-item>
            <a-popover :title="$t('FLOW_ADD_CMR_ACTION')">
              <template #content>
                <!--              <a-select-->
                <!--                  v-model:value="selectedCRM_Action"-->
                <!--                  :placeholder="$t('FLOW_ADD_CMR_ACTION')"-->
                <!--                  @change="selectCRM_ActionChange"-->
                <!--              >-->
                <!--                <a-select-option v-for="item in crmActions" :value="item" :key="item">{{ $t(item) }}</a-select-option>-->
                <!--              </a-select>-->
                <a-tag style="display: block;cursor: pointer;margin-bottom: 10px"  @click="selectCRM_ActionChange(i)" v-for="i in crmActions" :key="i">{{$t(i)}}</a-tag>
              </template>
              <a-button  >{{$t('FLOW_CMR_ACTION')}}</a-button>
            </a-popover>
          </a-form-item>
          <a-form-item  name="action_crm">
            <div class="action-crm" v-for="(item,index) in formState.flow_actions_context.flow_crm_actions" :key="'index_'+index">
              <div class="crm-action-title-container">
                <div class="title-selected-crm-action">
                  {{ $t(item.type) }}
                </div>
              </div>
              <a-row style="align-items: center">
                <a-col :span="20">
                  <a-tree-select
                      v-if="item.type==='add_tag'||item.type==='remove_tag'"
                      show-search
                      style="width: 100%"
                      v-model:value="item.tagArr"
                      :filterTreeNode="false"
                      @search="searchTags"
                      :notFoundContent="null"
                      :dropdown-style="{ maxHeight: '300', overflow: 'auto' }"
                      allow-clear
                      multiple
                      :placeholder="$t('TAG_SELECT')"
                  >
                    <a-tree-select-node v-for="item in treeTags" :key="item.id" v-bind:value="item.id">
                      <template #title>
                        <a-tag :color="item.color">
                          {{ item.name }}
                        </a-tag>
                      </template>
                      <a-tree-select-node v-for="child in item.children" :key="child.id" v-bind:value="child.id">
                        <template #title>
                          <a-tag :color="child.color">
                            {{ child.name }}
                          </a-tag>
                        </template>
                      </a-tree-select-node>

                    </a-tree-select-node>
                  </a-tree-select>
                  <a-tag v-if="item.type === 'unread_conversation' ">{{$t('unread_conversation')}}</a-tag>

                  <a-select
                      v-else-if="item.type==='update_last_care'"
                      style="width: 100%"
                      allowClear
                      placeholder="Please select"
                      v-model:value="item.last_care"
                      @change="handleCare"
                      @blur="handleCare"
                  >
                    <a-select-option
                        v-for="item in customerCares"
                        :key="item.id"
                        v-bind:value="item.id"

                    >
                      <a-tag :color="item.color">
                        {{ item.name }}
                      </a-tag>
                    </a-select-option>

                  </a-select>
                  <a-tree-select
                      v-else
                      show-search
                      style="width: 100%"
                      v-model:value="item.coverstion_tag"
                      :filterTreeNode="false"
                      @search="searchTags"
                      :notFoundContent="null"
                      :dropdown-style="{ maxHeight: '300', overflow: 'auto' }"
                      allow-clear
                      multiple
                      :placeholder="$t('TAG_SELECT')"
                  >
                    <a-tree-select-node disabled  v-for="item in converstionTag" :key="item.page_id" v-bind:value="item.page_id" >
                      <template #title>
                        <a-tag :color="item.color">
                          <img
                              v-if="item.page_type === 2"
                              draggable="false"
                              src="@/assets/icons/zalo-seeklogo.com.svg"
                              style="width: 20px;margin-right: 20px"
                          />
                          <img
                              v-else-if="item.page_type === 1"
                              draggable="false"
                              src="@/assets/icons/facebook-svgrepo-com.svg"
                              style="width: 20px;margin-right: 20px"
                          />
                          <img
                              v-else-if="item.page_type === 3"
                              draggable="false"
                              src="@/assets/icons/instagram-svgrepo-com.svg"
                              style="width: 20px;margin-right: 20px"
                          />
                          <img
                              v-else-if="item.page_type === 4"
                              draggable="false"
                              src="@/assets/icons/line-svgrepo-com.svg"
                              style="width: 20px;margin-right: 20px"
                          />
                          {{ item.page_name }}
                        </a-tag>
                      </template>
                      <a-tree-select-node v-for="treeData in item.tags" :key="item.page_id + '_'+treeData.tag_id" v-bind:value="item.page_id + '_'+treeData.tag_id">
                        <template #title>
                          <a-tag :color="treeData.tag_color">
                            {{ treeData.tag_name }}
                          </a-tag>
                        </template>
                      </a-tree-select-node>

                    </a-tree-select-node>
                  </a-tree-select>
                  <!--               <div v-else>-->
                  <!--                 <a-select-->
                  <!--                     style="width: 100%;margin-bottom: 10px"-->
                  <!--                     allowClear-->
                  <!--                     placeholder="Please select"-->
                  <!--                     v-model:value="getCurrentPageId"-->
                  <!--                     @change="handlePage"-->
                  <!--                 >-->
                  <!--                   <a-select-option-->
                  <!--                       v-for="item in currentShop.pages"-->
                  <!--                       :key="item.page_id"-->
                  <!--                       v-bind:value="item.page_id"-->

                  <!--                   ><img-->
                  <!--                       v-if="item.page_type === 2"-->
                  <!--                       draggable="false"-->
                  <!--                       src="@/assets/icons/zalo-seeklogo.com.svg"-->
                  <!--                       style="width: 20px;margin-right: 20px"-->
                  <!--                   />-->
                  <!--                     <img-->
                  <!--                         v-else-if="item.page_type === 1"-->
                  <!--                         draggable="false"-->
                  <!--                         src="@/assets/icons/facebook-svgrepo-com.svg"-->
                  <!--                         style="width: 20px;margin-right: 20px"-->
                  <!--                     />-->
                  <!--                     <img-->
                  <!--                         v-else-if="item.page_type === 3"-->
                  <!--                         draggable="false"-->
                  <!--                         src="@/assets/icons/instagram-svgrepo-com.svg"-->
                  <!--                         style="width: 20px;margin-right: 20px"-->
                  <!--                     />-->
                  <!--                     <img-->
                  <!--                         v-else-if="item.page_type === 4"-->
                  <!--                         draggable="false"-->
                  <!--                         src="@/assets/icons/line-svgrepo-com.svg"-->
                  <!--                         style="width: 20px;margin-right: 20px"-->
                  <!--                     />-->

                  <!--                       {{ item.page_name }}-->
                  <!--                   </a-select-option>-->

                  <!--                 </a-select>-->
                  <!--                 <a-select-->
                  <!--                     style="width: 100%"-->
                  <!--                     allowClear-->
                  <!--                     placeholder="Please select"-->
                  <!--                     v-model:value="item.coverstion_tag"-->
                  <!--                     @change="handleCare"-->
                  <!--                     @blur="handleCare"-->
                  <!--                     mode="tags"-->
                  <!--                 >-->
                  <!--                   <a-select-option-->
                  <!--                       v-for="item in converstionTags"-->
                  <!--                       :key="item.tag_id"-->
                  <!--                       v-bind:value="item.tag_id"-->

                  <!--                   >-->
                  <!--                     <a-tag :color="item.tag_color">-->
                  <!--                       {{ item.tag_name }}-->
                  <!--                     </a-tag>-->
                  <!--                   </a-select-option>-->

                  <!--                 </a-select>-->
                  <!--               </div>-->
                </a-col>
                <a-col :span="1"></a-col>
                <a-col :span="3">
                  <a-tag color="#f50" class="crm-action-deletion" @click="deleteCRM_Action(item)">{{ $t('DELETE') }}</a-tag>

                </a-col>
              </a-row>

            </div>
            <!--          <a-select-->
            <!--              v-model:value="selectedCRM_Action"-->
            <!--              :placeholder="$t('FLOW_ADD_CMR_ACTION')"-->
            <!--              @change="selectCRM_ActionChange"-->
            <!--          >-->
            <!--            <a-select-option v-for="item in crmActions" :value="item" :key="item">{{ $t(item) }}</a-select-option>-->
            <!--          </a-select>-->
          </a-form-item>
          <a-form-item v-if="formState.flow_actions_context.type !== '0' || !formState.flow_actions_context.usage_id" :label="$t('SELECT_AVAILABLE_MESSAGE')" name="root_flow_id">

            <a-select
                allowClear
                v-model:value="formState.flow_actions_context.usage_id"
                :placeholder="$t('SELECT_AVAILABLE_MESSAGE')"
                @change="changeFlowAvailable"
                :options="getFlowNextActions()"
            >
            </a-select>
          </a-form-item>
          <a-form-item v-if="formState.flow_actions_context.type === '0' || !formState.flow_actions_context.usage_id"  :label="$t('SELECT_AVAILABLE_FLOW')" name="usage_id">

            <a-select
                v-model:value="formState.flow_actions_context.usage_id"
                :placeholder="$t('SELECT_AVAILABLE_FLOW')"
                @change="changeFlowAvailable2"
                allowClear

            >
              <a-select-option v-for="item in getFlowData"  :key="item.id"
                               v-bind:value="item.id">
                {{item.name}}

              </a-select-option>
            </a-select>
          </a-form-item>
          <a-form-item v-if="nextFlowContext&&nextFlowContext.name" :label="$t('FLOW_NEXT_CONTENT')">
            <div class="right-button-content">
              <a-button type="dashed" class="selected-flow-action" @click="changeCurrentFlowContext">
                <facebook-outlined style="font-size: 16px"/>
                <span>
              {{ nextFlowContext.name }}
            </span>
              </a-button>
              <a-tag color="#f50" style="cursor: pointer" @click="deleteNextFlowAction">
                <delete-outlined/>
              </a-tag>
            </div>
          </a-form-item>
          <div v-else>
            <a-form-item v-if="!isNextFlow">
              <a-button type="dashed" block @click="isNextFlow=true">{{ $t('FLOW_NEXT_CONTENT') }}</a-button>
            </a-form-item>
            <div v-else>
              <a-divider/>
              <!--            <a-form-item :label="$t('FLOW_SELECT_AVAILABLE')">-->
              <!--              <a-select-->
              <!--                  :placeholder="$t('FLOW_CONTEXT_PLACEHOLDER')"-->
              <!--              >-->
              <!--                <a-select-option value="jack">Jack</a-select-option>-->
              <!--                <a-select-option value="lucy">Lucy</a-select-option>-->
              <!--              </a-select>-->
              <!--            </a-form-item>-->
              <a-form-item>
                <a-button type="dashed" block @click="newFacebookFlow(1)">
                  <facebook-outlined style="font-size: 16px"/>
                  {{ $t('FLOW_NEW_FACEBOOK') }}
                </a-button>
              </a-form-item>
              <a-form-item>
                <a-button type="dashed" block @click="newFacebookFlow(2)">
                  <img src="@/assets/icons/zalo-flat.svg" class="anticon" style="width: 16px;height: 16px;"/>
                  {{ $t('FLOW_NEW_ZALO') }}
                </a-button>
              </a-form-item>
            </div>
          </div>

        </div>

        <a-divider/>
        <a-form-item>
          <div class="footer-postback-init">
            <a-button type="primary" danger @click="deletePostBack">
              <delete-outlined/>
              {{ $t('DELETE') }}
            </a-button>
            <a-button type="primary" @click="onSubmit(true)">
              <check-outlined/>
              {{ $t('FLOW_COMPLETE') }}
            </a-button>
          </div>
        </a-form-item>
      </a-form>
    </template>
    <a-button class="button-post-back" @click="()=>popoverVisible=true">{{ formState.title }}</a-button>
  </a-popover>
  <image-selection-modal ref="imageSelectionRef" :single="isImageSelectionSingle" :onSelect="onImageSelect"/>
</template>

<script lang="ts">
import {
  CheckOutlined,
  PlusOutlined,
  FacebookOutlined,
  DeleteOutlined,
  CloseOutlined,
  EyeOutlined,
    UploadOutlined
} from "@ant-design/icons-vue"
import {computed, defineComponent, reactive, ref, UnwrapRef, watch} from "vue";
import {FlowContext, FlowCRM_Action, FlowZaloElement} from "@/models/Flows/FlowContext";
import {FormFlowInformation} from "@/models/Flows/FlowForm";
import {ValidateErrorEntity} from "ant-design-vue/es/form/interface";
import {useStore} from "@/store";
import {S_EventBus} from "@/utils/EventBus";
import {AWS_DO_Model} from "@/models/AWS_DO/AWS_DO";
import ImageSelectionModal from "@/components/Image/ImageSelectionModal.vue";
import {useI18n} from "vue-i18n";
import {MixUtils} from "@/utils/Utils";
import {Form, message} from 'ant-design-vue';
import useTagStore from "../../store/Tags";

const useForm = Form.useForm;


export default defineComponent({
  name: "zalo-button",
  props: ['zaloButton', 'updatePostBackButton', 'flowContextData', 'deletePostBackButton'],
  components: {
    CheckOutlined,
    PlusOutlined,
    FacebookOutlined,
    DeleteOutlined,
    ImageSelectionModal,
    CloseOutlined,
    EyeOutlined,
    UploadOutlined
  },
  setup(props) {
    const store = useStore()
    const i18n = useI18n()
    const fileList = ref<AWS_DO_Model[]>([])
    const formRefPostBack = ref()
    const crmActions = ref()
    const inputMessageContent = ref()
    const customerTags = ref()
    const selectedCRM_Action = ref()
    const treeTags = computed(() => store.getters.getTreeTags)
    const customerCares = computed(() => store.getters.getCustomerCares)
    const converstionTag = computed(() => useTagStore.getState().pageConverstionTag)
    const getFlowData = computed(()=> store.getters.getFlowData.flows?.map((item:any)=>{
      item.id = item.id + ""
      return  item
    }))
    const isNextFlow = ref(false)
    const popoverVisible = ref(false)
    const nextFlowContext = ref()
    const imageSelectionRef = ref()
    const isImageSelectionSingle = ref(false)
    crmActions.value = store.getters.getFlowConstants?.flow_crm_action_types
    let model: FlowZaloElement = {
      flow_actions_context:{}
    }
    customerTags.value = useTagStore.getState().tagSales

    watch(() => props.zaloButton, () => {
      updateButtonContext()
    })

    const updateButtonContext = () => {
      console.log( formState.flow_actions_context.flow_crm_actions)
      if (!formState.flow_actions_context) {
        formState.flow_actions_context = {
          flow_crm_actions: []
        }
      }
      // @ts-ignore
      formState.flow_actions_context.flow_crm_actions =  formState.flow_actions_context.flow_crm_actions.map((item: FlowCRM_Action) => {

        if (item.tags  && (item.type === 'add_tag' || item.type === 'remove_tag' )) {
          item.tagArr = item.tags ? item.tags.split(",").map((str: string) => parseInt(str)) : []
        }else if( item.tags  && ( item.type === 'add_conversation_tag' || item.type === 'remove_conversation_tag')) {
          item.coverstion_tag = item.tags.split(",").map((converstionT) => {
            converstionTag.value.map((i:any) => {
              i.tags.map((tag:any) => {
                // @ts-ignore
                if(converstionT === tag.tag_id){
                  converstionT = i.page_id + '_' + converstionT

                }

              })

            })
            return converstionT
          })

        }else {
          item.last_care = Number(item.tags)
        }
        return item;
      })
      formState.title = props.zaloButton.title
      formState.image_icon = props.zaloButton.image_icon
      formState.url = props.zaloButton.url
      formState.type = props.zaloButton.type ? props.zaloButton.type : store.getters.getFlowConstants?.zalo_element_types[0]
      if (props.zaloButton.reply_images) {
        if (typeof props.zaloButton.reply_images !== 'string')
          fileList.value = props.zaloButton.reply_images.map((item: string) => {
            const image: AWS_DO_Model = {
              url: item,
              key: item
            }
            return image;
          })
      }
    }
    watch(() => props.flowContextData, () => {
      updateFlowContextData();
    })


    if (props.zaloButton) {
      model = {...props.zaloButton, ...model}
    }
    if (!model.flow_actions_context) {
      model.flow_actions_context = {
        flow_crm_actions: []
      }
    }
    const zaloButtonTypes = store.getters.getFlowConstants?.zalo_element_types;
    if (!model.flow_actions_context.flow_crm_actions) {
      model.flow_actions_context.flow_crm_actions = []
    }
    model.flow_actions_context.flow_crm_actions = model.flow_actions_context.flow_crm_actions.map((item: FlowCRM_Action) => {
      if (item.tags  && (item.type === 'add_tag' || item.type === 'remove_tag')) {
        item.tagArr = item.tags ? item.tags.split(",").map((str: string) => parseInt(str)) : []
      }else if( item.tags  && ( item.type === 'add_conversation_tag' || item.type === 'remove_conversation_tag')) {
        // @ts-ignore
        item.coverstion_tag = item.tags.split(",").map((converstionT:any) => {
          converstionTag.value.map((i:any) => {
            i.tags.map((tag:any) => {
              // @ts-ignore
              if(converstionT === tag.tag_id){
                converstionT = i.page_id + '_' + converstionT

              }

            })

          })
          return converstionT
        })




      }else {
        item.last_care = Number(item.tags)
      }

      return item;
    })
    const formState: UnwrapRef<FlowZaloElement> = reactive(props.zaloButton);
    const rules= reactive({
      title: [
        {required: true, message: 'Please input button title', trigger: 'blur'},
      ],
      phone_code: [
        {required: zaloButtonTypes&&(formState.type===zaloButtonTypes[2]||formState.type===zaloButtonTypes[3]), message: 'Please input phone number', trigger: 'blur'},
      ],
      url: [
        {required: zaloButtonTypes&&formState.type===zaloButtonTypes[0], message: 'Please input url', trigger: 'blur'},
      ],
      sms_content: [
        {required: zaloButtonTypes&&formState.type===zaloButtonTypes[2], message: 'Please input sms content', trigger: 'blur'},
      ],
    })
    const { resetFields, validate, validateInfos } = useForm(
        formState,
        rules,
    );
    const  cursorPositionMessageText= ref(0)
    const forcusMessagetext = (e: any) =>{
      // if(e.target.value.lastIndexOf('#') !== -1){
      //   e.target.setSelectionRange(e.target.value.lastIndexOf('#')+1,e.target.value.lastIndexOf('#')+1)
      //   console.log(e.target.selectionStart)
      // }else {
      cursorPositionMessageText.value = e.target.selectionStart
      console.log(cursorPositionMessageText.value)



    }
    const onchangeText=(e:any)=>{
      if(e && e.target && e.target.selectionStart){
        cursorPositionMessageText.value = e.target.selectionStart
        onSubmit();
      }else {
        onSubmit();
      }
    }

    const messageFullName = () => {
      inputMessageContent.value.focus()
      if(formState.reply_message) {
        const text = ref<any>(formState.reply_message);

        const start = text.value.slice(0, cursorPositionMessageText.value)
        const end = text.value.slice(cursorPositionMessageText.value)
        text.value =

            start + (start ? " " : "") + "##full_name##" + (end ? " " : "") + end

        formState.reply_message = text.value
        onSubmit()
      }else {
        const text = ref<any>(formState.reply_message);
        text.value =

             "##full_name##"

        formState.reply_message = text.value
      }

    }
    const buttonTypeChange=(val:string)=>{
      rules.url[0].required=zaloButtonTypes&&val===zaloButtonTypes[0]
      rules.url[0].required=zaloButtonTypes&&val===zaloButtonTypes[0]
      rules.phone_code[0].required=zaloButtonTypes&&(val===zaloButtonTypes[2]||val===zaloButtonTypes[3])
      onSubmit()
    }
    const selectCRM_ActionChange = (value: string) => {
      console.log(value)
      if (value) {
        if (!formState.flow_actions_context) {
          formState.flow_actions_context = {
            flow_crm_actions: []
          }
        }
        console.log(formState)
        if (formState.flow_actions_context.flow_crm_actions) {
          const action = formState.flow_actions_context.flow_crm_actions.find((item: FlowCRM_Action) => item.type === value)
          if (!action) {
            formState.flow_actions_context.flow_crm_actions.push({type: value, tagArr: []})
          }
        } else {
          formState.flow_actions_context.flow_crm_actions = [{type: value, tagArr: []}]
        }
        selectedCRM_Action.value = undefined
      }

    }
    const getFlowNextActions = ()=>{
      let listSelected: string[] = [];
      props.flowContextData.context.flow_contexts.map((flow: FlowContext)=>{
        if(flow.action_default_context && flow.action_default_context.usage_id ){
          listSelected.push(flow.action_default_context.usage_id)
        }
        if(flow.action_send_failed_context && flow.action_send_failed_context.usage_id ){
          listSelected.push(flow.action_send_failed_context.usage_id)
        }
        if(flow.action_send_success_context && flow.action_send_success_context.usage_id ){
          listSelected.push(flow.action_send_success_context.usage_id)
        }
      })
      listSelected = listSelected.filter(x => !x.includes(props.zaloButton.flow_actions_context.usage_id))
      return props.flowContextData.context.flow_contexts.filter((flow: FlowContext, index: number)=>{
        return props.zaloButton.id!==flow.id && index !== 0 && !listSelected.includes(flow.id+'')
      }).map((flow: FlowContext) => {
        return {
          label: flow.name,
          value: flow.id
        }
      })
    }

    const deleteCRM_Action = (item: FlowCRM_Action) => {
      if (!formState.flow_actions_context) {
        formState.flow_actions_context = {
          flow_crm_actions: []
        }
      }
      formState.flow_actions_context.flow_crm_actions = formState.flow_actions_context.flow_crm_actions?.filter((it: FlowCRM_Action) => it.type !== item.type);
      selectedCRM_Action.value = undefined
    }
    const newFacebookFlow = async (type: number) => {
      let name = 1
      if (props.flowContextData && props.flowContextData.context && props.flowContextData.context.flow_contexts.length) {
        name = props.flowContextData.context.flow_contexts.length + 2
      }
      var resName = "";
      var resMess = "";
      if(type === 1)
      {
        resName = "#Facebook# " + i18n.t('MESS_NAME_CONTENT') + name;
        resMess = "#Facebook " + i18n.t('FLOW_MESSAGE_CONTENT') + name;
      }else if(type === 2)
      {
        resName = "#Zalo# " + i18n.t('MESS_NAME_CONTENT') + name;
        resMess = "#Zalo " + i18n.t('FLOW_MESSAGE_CONTENT') + name;
      }

      let flow: FlowContext= {
        "id": MixUtils.methods.getUUID(),
        "name": resName,
        "shop_id": props.flowContextData?.shop_id,
        "flow_type": type,
        "message": resMess,
        "attachment_images": [],
        "type": "",
        "messenger_tag": "",
        "template_id": "",
        "template_name": "",
        "template_status": "",
        "preview_url": "",
        "created_at": new Date().toISOString(),
        "updated_at": new Date().toISOString(),
        "postback_contexts": [],
        "action_send_success_context": {
          "flow_crm_actions": [],
          "flow_action_stack": {}
        },
        "action_send_failed_context": {
          "flow_crm_actions": [],
          "flow_action_stack": {}
        },
        "action_default_context": {
          "flow_crm_actions": [],
          "flow_action_stack": {}
        }
      }

      S_EventBus.instance().dispatchCreateFlow(flow)
      onSubmit()
      setTimeout(() => {
        changeCurrentFlowContext()
      }, 400)
    }
    const updateFlowContextData = () => {
      let context: FlowContext = {}
      if (formState.flow_actions_context && formState.flow_actions_context.flow_action_stack && formState.flow_actions_context.flow_action_stack.flow_id
          && props.flowContextData.context && props.flowContextData.context.flow_contexts.length > 0) {
        context = props.flowContextData.context.flow_contexts.find((flow: FlowContext) => flow.id === formState.flow_actions_context?.flow_action_stack?.flow_id)
      }
      nextFlowContext.value = context
    }
    updateFlowContextData()
    const deleteNextFlowAction = () => {
      console.log("formState",formState.flow_actions_context)
      if (formState.flow_actions_context?.flow_action_stack) {
        formState.flow_actions_context.flow_action_stack.flow_id = 0
      }
    }
    const changeCurrentFlowContext = () => {
      if (formState.flow_actions_context?.flow_action_stack && formState.flow_actions_context.flow_action_stack.flow_id) {
        S_EventBus.instance().execChangeCurrentFlow(formState.flow_actions_context.flow_action_stack.flow_id)
        popoverVisible.value = false
      }
    }
    const showImageSelectionModal = () => {
      isImageSelectionSingle.value=false
      imageSelectionRef.value.showModal()
    }
    const onImageSelect = (images: AWS_DO_Model[],param:string) => {
      var img = new Image;
     if(formState.image_icon){
       img.src = images[0].url
       img.onload = function () {
         // @ts-ignore
         if (img.height > 100 && img.width>100) {

           message.warning('Ảnh không hợp lệ,vui lòng chọn ảnh khác với tỉ lệ khoảng  100 * 100')
         } else {
           popoverVisible.value = true
           formState.image_icon = images[0].url
           onSubmit()
         }
       }
       console.log("Intrinsic size: " + img.width + "x" + img.height, img.height <= img.width);
     }else {
       img.src = images[0].url
       img.onload = function () {

         let total = 1.7 <= img.width / img.height && img.width / img.height <= 1.8
         // @ts-ignore
         console.log(total,)
         if (img.height > img.width === true) {
           message.warning('Ảnh không hợp lệ,vui lòng chọn ảnh khác với tỉ lệ khoảng  16:9')


         } else if (!total) {
           message.warning('Ảnh không h ợp lệ,vui lòng chọn ảnh khác với tỉ lệ khoảng  16:9')
         } else {
           popoverVisible.value = true
           fileList.value = [...fileList.value, ...images]
           onSubmit()
         }
       }
       console.log("Intrinsic size: " + img.width + "x" + img.height, img.height <= img.width);
     }







    }
    const removeImage = (image: AWS_DO_Model) => {
      fileList.value = fileList.value.filter((item: AWS_DO_Model) => item.key !== image.key)
    }
    const deletePostBack = () => {
      props.deletePostBackButton()
    }
    const containPop = () => {
      return document.getElementById('main-flow')
    }
    updateButtonContext()
    const changeFlowAvailable = async (val:string) => {

      if(formState.flow_actions_context){
        formState.flow_actions_context.usage_id = val
      }
      // formState.usage_id = value

      // let name = 1
      // if (props.flowContextData && props.flowContextData.context && props.flowContextData.context.flow_contexts.length) {
      //   name = props.flowContextData.context.flow_contexts.length + 2
      // }
      // var resName = "";
      // var resMess = "";
      //
      // if(props.flowContextData.flow_context.flow_type === 1)
      // {
      //   resName = "#Facebook# " + i18n.t('MESS_NAME_CONTENT') + name;
      //   resMess = "#Facebook " + i18n.t('FLOW_MESSAGE_CONTENT') + name;
      // }else if(props.flowContextData.flow_context.flow_type === 2)
      // {
      //   resName = "#Zalo# " + i18n.t('MESS_NAME_CONTENT') + name;
      //   resMess = "#Zalo " + i18n.t('FLOW_MESSAGE_CONTENT') + name;
      // }
      // const request: FlowContext = {
      //   name: resName,
      //   flow_type: props.flowContextData.flow_context.flow_type,
      //   message: resMess,
      //   root_flow_id: value
      // }
      // const response = await APIs.init().createFlowContext(request);
      // if (response.status === 200 && response.data && response.data.data) {
      //   let flow = response.data.data;
      //   setTimeout(() => {
      //     changeCurrentFlowContext()
      //   }, 400)
      // }
    }
    const changeFlowAvailable2 =  async (id: any) => {
      if (formState.flow_actions_context) {

        formState.flow_actions_context.usage_id = id+''
        formState.flow_actions_context.type = '0'
      }
    }
    const onSubmit = (isButton?: boolean) => {
          validate()
          .then(() => {
            const postB = {...props.zaloButton}
            postB.title = formState.title
            postB.reply_message = formState.reply_message;
            postB.flow_actions_context = formState.flow_actions_context
            postB.type = formState.type
            postB.phone_code = formState.phone_code
            postB.sms_content = formState.sms_content
            postB.url = formState.url
            postB.image_icon = formState.image_icon
            if (!postB.flow_actions_context) {
              postB.flow_actions_context = {
                flow_action_stack: {},
                flow_crm_actions: []
              }
            }
            postB.popoverVisible = false
            if (formState.flow_actions_context && formState.flow_actions_context.flow_crm_actions) {
              console.log(formState.flow_actions_context.flow_crm_actions)
              postB.flow_actions_context.flow_crm_actions = formState.flow_actions_context.flow_crm_actions?.map((item: FlowCRM_Action) => {
                if (item.tagArr) {
                  item.tags = item.tagArr.join(",")
                }
                if (item.last_care){
                  console.log('2')
                  item.tags = item.last_care + ''
                }
                if( item.coverstion_tag){
                  console.log('3')
                  item.tags = item.coverstion_tag.map((item) => {
                    item = item.split("_")[1]
                    return item
                  }).join(",")
                }
                if(item.type === 'unread_conversation'){
                  item.value = 'true'
                }else {
                  item.value = ''
                }
                return item;
              })
            }
            if (fileList.value && fileList.value.length) {
              postB.reply_images = fileList.value.map((item: AWS_DO_Model) => {
                return item.url
              })
            }else {
              postB.reply_images=[]
            }
            props.updatePostBackButton(postB);
            if (isButton) {
              popoverVisible.value = false
            }
          })
          .catch((error: ValidateErrorEntity<FormFlowInformation>) => {
            console.log('error', error);
          });
    };

    return {
      fileList,
      formState,
      formRefPostBack,
      onSubmit,
      isNextFlow,
      customerTags,
      crmActions,
      selectedCRM_Action,
      selectCRM_ActionChange,
      deleteCRM_Action,
      popoverVisible,
      nextFlowContext,
      deleteNextFlowAction,
      changeCurrentFlowContext,
      deletePostBack,
      newFacebookFlow,
      onImageSelect,
      showImageSelectionModal,
      imageSelectionRef,
      containPop,
      removeImage,
      zaloButtonTypes,
      changeFlowAvailable,
      changeFlowAvailable2,
      getFlowNextActions,
      isImageSelectionSingle,
      validateInfos,
      buttonTypeChange,
      messageFullName,
      treeTags,
      customerCares,
      converstionTag,
      forcusMessagetext,
      onchangeText,
      inputMessageContent,
      getFlowData

    }
  }
})
</script>

<style lang="scss" scoped>


.button-post-back {
  margin: 5px 10px;
}



.action-crm {
  margin-bottom: 16px;

  .crm-action-title-container {
    display: flex;
    flex-direction: row;
    margin-bottom: 8px;
    justify-content: space-between;
    align-items: center;

    .title-selected-crm-action {
      font-weight: bold;
    }

    .crm-action-deletion {
      cursor: pointer;
    }
  }

}

.selected-flow-action {
  text-align: left;
  margin-right: 10px;
  flex: 1;
}

.footer-postback-init {
  justify-content: space-between;
  display: flex;
}

.right-button-content {
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;

  .center-button-content {
    display: flex;
    flex: 1;
    padding-bottom: 5px;
    margin-left: 10px;
  }
}

.header-postback-pop {
  width: 100%;
  margin: auto;
}

.form-postback {
}
.ant-list-item-meta-title{
  text-align: left !important;
}
.img-image-url{
  width: 100%;
  height: 100%;
}
.item-zalo-element{
  cursor: pointer;
}
</style>
