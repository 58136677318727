<template>
  <a-row>
    <a-col :span="14">
      <a-typography-title :level="2">{{$t('quick_message')}}</a-typography-title>
      <a-typography-text type="secondary">{{$t('customize_quick_message')}}</a-typography-text>
    </a-col>
    <a-col :span="2"></a-col>
    <a-col v-if="isPage" :span="8">
      <a-row style="justify-content: flex-end">
        <a-button @click="save"  type="primary">{{$t('SAVE')}}</a-button>
      </a-row>

    </a-col>
  </a-row>
  <a-row  style="margin-top: 20px;">
    <a-col :span="11">
      <a-typography-title :level="5">{{$t('change_shop')}}</a-typography-title>
      <a-select
          allowClea
          :value="currentShop.currentShop.id"
          style="width: 100%"
          placeholder="Please select"
          @change="changeShop"
      >
        <a-select-option
            v-for="item in getShops.shops"
            :key="item.id"
            v-bind:value="item.id"
        >{{ item.shop_name }}
        </a-select-option>
      </a-select>
    </a-col>
    <a-col :span="2"></a-col>
    <a-col :span="11">
      <a-typography-title :level="5">{{$t('select_page')}}</a-typography-title>
      <a-select
          allowClea
          show-search
          style="width: 100%"
          placeholder="Please select"
          @change="changePage"
          v-model:value="getCurrentPageId"
      >
        <a-select-option
            v-for="item in configPage.pageConfig"
            :key="item.id"
            v-bind:value="item.page_id"
        > <img
            v-if="item.page_type === 2"
            draggable="false"
            src="@/assets/icons/zalo-seeklogo.com.svg"
            style="width: 20px;margin-right: 20px"
        />
          <img
              v-else-if="item.page_type === 1"
              draggable="false"
              src="@/assets/icons/facebook-svgrepo-com.svg"
              style="width: 20px;margin-right: 20px"
          />
          <img
              v-else-if="item.page_type === 3"
              draggable="false"
              src="@/assets/icons/instagram-svgrepo-com.svg"
              style="width: 20px;margin-right: 20px"
          />
          <img
              v-else-if="item.page_type === 4"
              draggable="false"
              src="@/assets/icons/line-svgrepo-com.svg"
              style="width: 20px;margin-right: 20px"
          />{{ item.page_name }}
        </a-select-option>
      </a-select>
    </a-col>
    <a-row v-if="!isPage" style="margin-top: 30px;width: 100%" justify="center">
      <a-typography-title  :level="3">{{$t('PLEASE_CHOOSE_SHOP_AND_FANPAGE')}}</a-typography-title>
    </a-row>
  </a-row>
  <a-row v-if="isPage" style="margin-top: 20px;">
    <a-col :span="24">
      <a-card  style="width: 100%;margin-top: 20px;border-radius: 10px">
        <template #title>
          <a-row>
            <a-col :span="8">
              <EditOutlined />
              {{$t('quick_message')}}
            </a-col>
            <a-col :span="15">
            </a-col>
            <a-col :span="1">
              <a-button @click="showModal"  style="border-radius: 50%">
                <template #icon><PlusOutlined/></template>
              </a-button>
            </a-col>
          </a-row>

        </template>


        <a-table
            :pagination="false"
            size="middle"

            class="table"
            :row-selection="rowSelection"
            :columns="columns"
            :data-source="configData.quick_message"
            :scroll="{ x: false, y:false }"

        >

        <template #stt="{index}">
          {{index + 1}}
        </template>
          <template #content="{record}">
            <div style=" white-space: nowrap;overflow: hidden;text-overflow: ellipsis;">
              {{record.content}}
            </div>
          </template>
        <template #images="{ record }">
         <div  v-if="record.content_image" >
           <a-image v-for="i in record.content_image.split(';')"  :key="i"   :src="i"
                style="height: 50px;width: 50px;margin-right: 10px" />

         </div>
          <p v-else>{{$t('no_images')}}</p>
        </template>
          <template #action="{ index }">
          <span>


            <a-divider type="vertical" />
            <div @click="showModalEdit(index)"

                style="color: black;cursor: pointer;display: inline-block"
            >
              <edit-outlined style="font-size: 20px" />
            </div>
            <a-divider type="vertical" />
            <a-popconfirm
                :title="$t('CONFIRM_DELETE')+' ' +$t('quick_message')+'?'"
                :ok-text="$t('OK')"
                :cancel-text="$t('CANCEL')"
                @confirm="deleteQuickMessage(index)"
            >
              <MinusCircleOutlined style="font-size: 20px" />
            </a-popconfirm>
          </span>
          </template>
        </a-table>
      </a-card>
    </a-col>

  </a-row>

  <a-modal width="1000px" v-model:visible="visible" :title="$t('update_quick_messages')" @ok="handleOk">
    <a-row justify="center">
      <div
          class="ant-upload ant-upload-select ant-upload-select-picture-card"
      >
                  <span role="button" tabindex="0" class="ant-upload">
                    <div @click="showImageSelectionModal">
                      <plus-outlined/>
                      <div class="ant-upload-text">{{ $t("UPLOAD") }}</div>
                    </div>
                  </span>
      </div>
      <span>
                  <a-upload
                      list-type="picture"
                      v-model:file-list="fileListVersion"
                      class="upload-list-inline"

                  >
                  </a-upload>
                </span>
    </a-row>
    <a-select
        v-model:value="dataUpdate.tags"
        mode="tags"
        style="width: 100%"
        :placeholder="$t('title_tags')"

        @change="handleChange"
    >
      <a-select-option v-for="(i,index) in options" :key="index" v-bind:value="i">
        {{ i }}
      </a-select-option>

    </a-select>
    <a-textarea @click="forcusMessagetext"  style="margin-top: 10px"  :rows="4"  v-model:value="dataUpdate.content" :placeholder="$t('enter_your_message')" allow-clear />
    <div style="position: absolute; bottom:140px;right: 40px;z-index: 999">
      <a-popover  trigger="click">
        <template #content>
          <Picker :data="emojiIndex" set="twitter" @select="showEmoji" />

        </template>
        <div style="cursor: pointer">
          <SmileOutlined style="font-size: 24px" />
        </div>
      </a-popover>
    </div>
    <a-row style="margin-top: 10px">
      <a-col :span="8">
        <a-button @click="addText('##full_name##')" >##full_name##</a-button>

      </a-col>
      <a-col :span="16">
        <p>{{$t('inset_customer_full_name')}}</p>
      </a-col>

    </a-row>

  </a-modal>
  <image-selection-modal ref="imageSelectionRef" :onSelect="onImageSelect"/>

</template>
<script  >
import data from 'emoji-mart-vue-fast/data/all.json'
import { EditOutlined,PlusOutlined,MinusCircleOutlined,SmileOutlined} from '@ant-design/icons-vue';
import {computed, onMounted, ref,watch} from "vue";
import {useStore} from "@/store";
import {useRouter} from "vue-router";
import {CustomersActionTypes} from "@/store/modules/customer/action-types";
import {UsersMutationTypes} from "@/store/modules/user/mutation-types";
import ImageSelectionModal from "@/components/Image/ImageSelectionModal.vue";
import {MixUtils} from "@/utils/Utils";
import { Picker, EmojiIndex } from "emoji-mart-vue-fast/src";
import 'emoji-mart-vue-fast/css/emoji-mart.css'
import {PageConfigActionTypes} from "@/store/modules/PageConfig/action-types";
import APIs from "@/APIs/APIs";
import {message} from "ant-design-vue";
import {useI18n} from "vue-i18n";
import {PageConfigMutationTypes} from "@/store/modules/PageConfig/mutation-types";
import usePageConfigStorageStore from "../../store/PageConfig";
import useUserStore from "../../store/User";
const columns = [
  {
    title: "",
    width: 80,
    key: "stt",
    slots: { customRender: "stt" },
  },
  {
    dataIndex: "content",
    key: "content",
    title: "",
    slots: { customRender: "content" },
  },
  {
    title: "",
    dataIndex: "images",
    key: "images",
    slots: { customRender: "images" },
    width: 300,
  },
  {
    title: "",
    key: "action",
    slots: { customRender: "action" },
    width: 100
  },
];


export default {
  components: {
    EditOutlined,
    PlusOutlined,
    ImageSelectionModal,
    SmileOutlined,
    Picker,
    MinusCircleOutlined

  },

  setup(){

    const store = useStore()
    const router = useRouter()
    const i18n = useI18n()
    const userInfo = computed(()=> store.getters.getUserInfo)
    const currentShop =  useUserStore()
    const pages = computed(() => store.getters.getCurrentShop)
    const configPage = usePageConfigStorageStore()
    const getCurrentPageId = computed(()=> store.getters.getCurrentPageId)
    const tabkey = computed(() => store.getters.getCrrentTab)

    const getShops = useUserStore()
    const isPage = ref(false)
    const tagList = ref([])
    const visible = ref(false)
    const imageSelectionRef = ref();
    const emojisOutput = ref("")
    const emojiIndex = ref(new EmojiIndex(data))
    const emoji = ref(false)
    const content = ref('')
    const quickMessageList=ref([])
    const tags=ref('')
    const configCurrentPage = ref({})
    const fileListVersion = ref([]);
    const configData = ref({})
    const dataUpdate = ref({
      content: '',
      content_image: '',
      tags:''
    })
    const showModal = () => {
          visible.value = true
          dataUpdate.value = {
            isEdit: -1,
            content: '',
            content_image: [],
            tags:[],
          }
        }
    const showModalEdit = (index) => {
      visible.value = true
      dataUpdate.value = {
        isEdit: index,
        content:configData.value.quick_message[index].content ,
        tags:typeof configData.value.quick_message[index].tags === 'string' ? configData.value.quick_message[index].tags.split(',') :  configData.value.quick_message[index].tags ?  configData.value.quick_message[index].tags : []
      }

      fileListVersion.value = configData.value.quick_message[index].content_image.split(';').map(item => {
        return {
          uid: MixUtils.methods.getUUID(),
          name: "xxx.png",
          status: "done",
          url: item,
        }
      })
      console.log(dataUpdate.value)
    }
    watch(tabkey, async (newQuestion, oldQuestion) => {
      if(newQuestion !== oldQuestion){
        if( configPage.pageConfig){
          isPage.value=true
          configPage.pageConfig.map(item => {
            // @ts-ignore
            if(item.page_id === getCurrentPageId.value){
              configCurrentPage.value = item
              configData.value = JSON.parse(item.config_data)
              configData.value.quick_message = configData.value.quick_message ? configData.value.quick_message : []
              console.log(configData.value.tags,'tags')

            }

          })

        }
      }
    })
    const handleOk = () =>{


      if (!dataUpdate.value.content && fileListVersion.value.length === 0) {
        message.error(i18n.t('pls_enter_your_message_or_choose_image'));
        return;
      }

      if (dataUpdate.value.tags) {
        dataUpdate.value.tags = dataUpdate.value.tags.join(",");
      }
      const imgString = fileListVersion.value.map(item => item.url);
      dataUpdate.value.content_image = imgString.join(';')




      if (dataUpdate.value.isEdit > -1) {
        configData.value.quick_message[dataUpdate.value.isEdit] = dataUpdate.value;
      } else {
        configData.value.quick_message.push(dataUpdate.value)
      }
      delete dataUpdate.value.isEdit;

      visible.value = false

    }
    const showImageSelectionModal = ( ) => {

      imageSelectionRef.value.showModal();
    };


    const changeShop = async (shopId) => {
      const response = await APIs.init().changeShop({
        shop_id: shopId,

        token: userInfo.value.token
      })
      if(response.status == 200 && response.data?.data){

        store.commit(UsersMutationTypes.SET_UserInfo,{...userInfo.value, ...{token: response.data?.data}})

        store.commit(PageConfigMutationTypes.GET_PAGE_CONFIG,'')
        store.commit(UsersMutationTypes.SET_CurrentShop,shopId);

        router.go()
      }
    }
    const options  = ref([])
    const changePage = (pageId) => {
      if(pageId){
        store.commit(PageConfigMutationTypes.GET_PAGE_CONFIG,pageId)
      }
      if( configPage.pageConfig){
        isPage.value=true
        configPage.pageConfig.map(item => {
          if(item.page_id === pageId ){
            configCurrentPage.value = item
            configData.value = JSON.parse(item.config_data)
            console.log(configData.value)
            configData.value.quick_message = configData.value.quick_message ? configData.value.quick_message : []
            configData.value.quick_message.length &&  configData.value.quick_message.map((i) => {
              if (i.tags) {
                if (!options.value.includes(i.tags)) {
                  options.value.push(i.tags)

                }

              }
            })

          }

        })

      }



    }

    const fetchApi = async () => {
      usePageConfigStorageStore.getState().getPageConfig({shop_id: useUserStore.getState().currentShop.id})

      // store.dispatch(PageConfigActionTypes.FETCH_PAGE_CONFIG,{shop_id: currentShop.value.id})
    }
    const save = async () =>{
      if(typeof configData.value.missing_time !== "number"){
        configData.value.missing_time = Number(configData.value.missing_time)
      }
      if(typeof configData.value.font_size_message !== "number") {
        configData.value.font_size_message = Number(configData.value.missing_time)
      }
      if(  isNaN(configData.value.missing_time) ){
        configData.value.missing_time = 1
      }
      if( isNaN(configData.value.font_size_message) ) {
        configData.value.font_size_message = 16
      }
      configData.value.quick_message = configData.value.quick_message ? configData.value.quick_message.map(item => {
        console.log(item.tags)
        if(typeof item.tags !== 'string' && item.tags !== undefined ){

          item.tags = item.tags.join(',')
        }
        return item
      }):[]
      console.log(configData.value,"QuickMessageUpdate",)
      try{
        const res = await APIs.init().updatePageConfig({
          config_data: JSON.stringify(configData.value) ,
          page_id: configCurrentPage.value.page_id,
          shop_id: useUserStore.getState().currentShop.id,
          page_type:configCurrentPage.value.page_type ,

          page_name: configCurrentPage.value.page_name,
          status:configCurrentPage.value.status,
          page_token:configCurrentPage.value.page_token
        })

        if(res.data?.status === 200){
          message.success(i18n.t(res.data?.message))
         await fetchApi()

        }
      }catch (err){
        console.log(err)
      }

    }



    const onImageSelect = (images) => {

      images.map((image) => {
        fileListVersion.value.push({
          uid: MixUtils.methods.getUUID(),
          name: "xxx.png",
          status: "done",
          url: image.url,
        });
      });


    };


    const checked = ref(false)

    const handleChange = (value) => {
      console.log(`selected ${value}`);
    };


    const showEmoji = (e)=>{
      const text = ref(dataUpdate.value.content);
      const start = text.value.slice(0,cursorPositionMessageText.value)
      const end = text.value.slice(cursorPositionMessageText.value)
      text.value =

          start + (start ? " " : "") + e.native + (end ? " " : "") + end
      // @ts-ignores

     dataUpdate.value.content =  text.value
    }
    const cursorPositionMessageText = ref(0)
    const forcusMessagetext = (e) =>{
      // if(e.target.value.lastIndexOf('#') !== -1){
      //   e.target.setSelectionRange(e.target.value.lastIndexOf('#')+1,e.target.value.lastIndexOf('#')+1)
      //   console.log(e.target.selectionStart)
      // }else {
      cursorPositionMessageText.value = e.target.selectionStart
      console.log(cursorPositionMessageText.value)



    }
    const addText = (t) =>{
      // if(t === 'messageContent'){
      //   inputMessageContent.value[0].focus()
      // }else if(t === 'quickMessage'){
      //   inputQuick.value[0].focus()
      // }else {
      //   inputNotification.value[0].focus()
      // }

      // @ts-ignore
      const text = ref(dataUpdate.value.content);
      const start = text.value.slice(0,cursorPositionMessageText.value)
      const end = text.value.slice(cursorPositionMessageText.value)
      text.value =

          start + (start ? " " : "") + "##full_name##" + (end ? " " : "") + end
      // @ts-ignores

      dataUpdate.value.content = text.value
    }


    const deleteQuickMessage = (idex) =>{

      configData.value.quick_message = configData.value.quick_message.filter((item,index) => index !== idex )

    }
    onMounted(() => {
      if(getCurrentPageId.value && configPage.pageConfig){
        isPage.value=true
        configPage.pageConfig.map(item => {
          if(getCurrentPageId.value && configPage.pageConfig){
            isPage.value=true
            configPage.pageConfig.map(item => {
              if(item.page_id === getCurrentPageId.value){
                configCurrentPage.value = item
                configData.value = JSON.parse(item.config_data)
                console.log(configData.value)
                configData.value.quick_message =configData.value.quick_message ? configData.value.quick_message :[]
                configData.value.quick_message.length && configData.value.quick_message.map((i) => {
                  if (i.tags) {

                    if (!options.value.includes(i.tags)) {
                      options.value.push(i.tags)

                    }

                  }
                })

              }

            })

          }

        })

      }
    })
    return{
      userInfo,
      getShops,
      deleteQuickMessage,
      dataUpdate,
      addText,
      currentShop,
      configData,
      checked,
      pages,
      showModalEdit,
      changeShop,
      changePage,
      emojisOutput,
      isPage,
      showModal,
      save,
      options,
      visible,
      emoji,
      content,
      handleOk,
      showImageSelectionModal,
      imageSelectionRef,
      fileListVersion,
      tagList,
      onImageSelect,
      handleChange,
      showEmoji,
      emojiIndex,
      quickMessageList,
      columns,
      tags,
      configPage,
      getCurrentPageId,
      forcusMessagetext,
      cursorPositionMessageText





    }
  }
}
</script>
