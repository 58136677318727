<template>
  <div class="main-container">
    <a-dropdown trigger="click" @visibleChange="dropdownChange">
      <a-badge :count="countUnreadNotification">
        <notification-outlined class="notification-icon" style="color: white"/>
      </a-badge>
      <template #overlay>
        <div class="notification-list">
          <a-list item-layout="horizontal" :data-source="notifications" :loading="notificationLoading"
                  @scroll="scrollList" class="list-notification">
            <template #renderItem="{ item }">
              <a-list-item @click="notiClick(item)"
                           :style="{cursor: 'pointer',backgroundColor:!item.is_read?'#f5f3f3':'white'}">
                <a-list-item-meta
                    v-if="item.type==='reminder'"
                >
                  <template #description>
                    <a>{{ timeSince(item.updated_at) }}</a>
                  </template>
                  <template #title>
                    <div>
                      <span class="noti-title"><span
                          class="noti-creator">{{ getOneEmployeeNameById(item.creator) }}</span>{{
                          messageContent(item.message)
                        }}</span>
                    </div>
                  </template>
                  <template #avatar>
                    <img src="https://biglead.live/favicon.ico" style="width: 24px;"/>
                  </template>
                </a-list-item-meta>
              </a-list-item>
            </template>
          </a-list>
          <div class="spin-container" v-if="loadingMore">
            <a-spin />
          </div>
        </div>
      </template>
    </a-dropdown>
  </div>
</template>

<script lang="ts">
import {NotificationOutlined} from "@ant-design/icons-vue";
import {mapActions, mapGetters, useStore} from "vuex";
import {UserActionTypes} from "@/store/modules/user/action-types";
import {Notification} from "@/models/notification/Notification";
import RouterEndpoint from "@/router/RouterEndpoint";
import {computed, defineComponent, onMounted, ref} from "vue";
import {useRouter} from "vue-router";
import APIs from "@/APIs/APIs";
import {UsersMutationTypes} from "@/store/modules/user/mutation-types";
import {useI18n} from "vue-i18n";
import {NotificationsResponse} from "@/APIs/response/S_HttpResponse";

export default defineComponent({
  name: "notification-dropdown",
  components: {
    NotificationOutlined
  },
  setup() {
    const store = useStore()
    const router = useRouter()
    const i18n = useI18n()
    const notificationLoading = ref(false);
    const loadingMore = ref(false);
    const countUnreadNotification = computed((): number => store.getters.getCountUnreadNotification)
    const notifications = computed((): Notification[] => store.getters.getNotifications)
    let page=1;
    let pageSize=20;
    const dropdownChange = (visible: boolean) => {
      if (visible) {
        notificationLoading.value=true
        store.dispatch(UserActionTypes.FETCH_NOTIFICATION, {
          payload: {page: page, page_size: pageSize}, callback: (res:NotificationsResponse) => {
            notificationLoading.value=false
          }
        })
      }
    }
    const getOneEmployeeNameById = (id: number): string => {
      const user = store.getters.getOneEmployeeById(id);
      if (user) {
        return user.name
      }
      return ""
    }
    const scrollList = ({target: {scrollTop, clientHeight, scrollHeight}}: any) => {
      if (scrollTop + clientHeight >= scrollHeight) {
        if (notifications.value.length===20){
          page++
          loadingMore.value=true
          store.dispatch(UserActionTypes.FETCH_NOTIFICATION, {
            payload: {page: page, page_size: pageSize}, callback: (res:NotificationsResponse) => {
              loadingMore.value=false
            }
          })
        }
      }
    }
    const messageContent = (msg: string) => {
      return i18n.t("REMINDER_NOTIFICATION_CONTENT").replace("##message##", msg)
    }
    const notiClick = (item: Notification) => {
      APIs.init().readNotification(item._id).then()
      store.commit(UsersMutationTypes.READ_NOTIFICATION, item._id)
      if (item.customer_ids && item.customer_ids.length) {
        router.push({name: RouterEndpoint.CUSTOMER_EDIT_DETAIL.name, params: {id: item.customer_ids[0]}})
      }
    }
    const fetchNotification = () => {
      store.dispatch(UserActionTypes.FETCH_COUNT_UNREAD_NOTIFICATION)
    }
    fetchNotification()
    return {
      getOneEmployeeNameById,
      countUnreadNotification,
      notifications,
      notiClick,
      messageContent,
      dropdownChange,
      scrollList,
      notificationLoading,
      loadingMore
    }
  },
})
</script>

<style scoped lang="scss">
.main-container {

}

.notification-list {
  min-width: 460px;
  padding: 12px;
  border-radius: 4px 4px 4px 4px;
  background-color: white;
  box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
}

.noti-title {
  font-weight: normal;
}

.noti-creator {
  font-weight: bold;
}

.notification-icon {
  font-size: 18px;
  padding-left: 14px;
  padding-right: 14px;
  cursor: pointer;
}

.notification-icon:hover {
  background-color: rgba(0, 0, 0, .025);
}

.list-notification {
  overflow: scroll;
  max-height: 88vh;
}
.spin-container{
  text-align: center;
  background: rgba(0, 0, 0, 0.05);
  border-radius: 4px;
  margin-bottom: 20px;
  padding: 30px 50px;
  margin: 20px 0;
}
</style>
