<template>
  <div class="container">
    <div class="row-horizontal">
      <div class="left-content">
        <a-input-search
            placeholder="Search key & value you wish"
            v-model:value="searchInput"
            @change="onSearchChange"
            style="width: 350px"
        />
        <a-checkbox-group v-model:value="filterCheckbox" :options="filterOptions" style="margin-left: 15px;"
                          @change="filterCheckboxChange"/>
      </div>
      <a-button type="primary" @click="showModal">
        <plus-outlined/>
        New
      </a-button>
    </div>
    <a-table
        :pagination="pagination"
        :dataSource="locales"
        :columns="columns"
        class="flow-table">
      <template v-for="col in ['key', 'en_value', 'vn_value','thai_value']" #[col]="{ text, record }" :key="col">
        <div>
          <a-input
              v-if="editableData[record.key]"
              v-model:value="editableData[record.key][col]"
              style="margin: -5px 0"
          />
          <template v-else>
            {{ text }}
          </template>
        </div>
      </template>
      <template #operation="{ record }">
        <div class="editable-row-operations">
        <span v-if="editableData[record.key]">
          <a @click="save(record.key)">Save</a>
            <a @click="cancel(record.key)" style="margin-left: 8px;">Cancel</a>
        </span>
          <span v-else>
          <a @click="edit(record.key)">Edit</a>
             <a-popconfirm
                 title="Sure to delete?"
                 @confirm="remove(record.key)"
             >
        <a style="margin-left: 8px;">Delete</a>
      </a-popconfirm>
        </span>
        </div>
      </template>
    </a-table>
  </div>
  <a-modal v-model:visible="modalVisible" title="New Locale" @ok="handleOk" :confirm-loading="modalConfirmLoading">
    <a-form :model="formState"
            ref="formRef"
            :rules="rules" :label-col="{span: 6,offset:0}" :wrapper-col="{ span: 16 }">
      <a-form-item label="Key" name="key">
        <a-input v-model:value="formState.key" placeholder="KEY_INPUT"/>
      </a-form-item>
      <a-form-item label="English" name="en_value">
        <a-input v-model:value="formState.en_value" placeholder="English"/>
      </a-form-item>
      <a-form-item label="Vietnamese" name="vn_value">
        <a-input v-model:value="formState.vn_value" placeholder="Tiếng việt"/>
      </a-form-item>
      <a-form-item label="Thai" name="thai_value">
        <a-input v-model:value="formState.thai_value" placeholder="ไทย"/>
      </a-form-item>
    </a-form>
  </a-modal>
</template>

<script lang="ts">

import {computed, defineComponent, ref, onBeforeMount, UnwrapRef, reactive} from "vue";
import {useStore} from "@/store";
import {UserActionTypes} from "@/store/modules/user/action-types";
import {Locale} from "@/models/locales/Locales";
import APIs from "@/APIs/APIs";
import {PlusOutlined} from "@ant-design/icons-vue"
import {ValidateErrorEntity} from "ant-design-vue/es/form/interface";
import {UsersMutationTypes} from "@/store/modules/user/mutation-types";
import {MixUtils} from "@/utils/Utils";

const filterOptions = ['English is NULL', 'Vietnamese is NULL', 'Thai is NULL'];

const columns = [
  {
    title: 'Key',
    dataIndex: 'key',
    key: 'key',
    slots: {customRender: 'key'},
  },
  {
    title: 'English',
    dataIndex: 'en_value',
    key: 'en_value',
    slots: {customRender: 'en_value'},
  },
  {
    title: 'Vietnamese',
    dataIndex: 'vn_value',
    key: 'vn_value',
    slots: {customRender: 'vn_value'},
  },
  {
    title: 'Thai',
    dataIndex: 'thai_value',
    key: 'thai_value',
    slots: {customRender: 'thai_value'},
  },
  {
    title: 'Operation',
    dataIndex: 'operation',
    key: 'operation',
    slots: {customRender: 'operation'},
  },
]
let timeout=0
export default defineComponent({
  name: 'Locales',
  components: {
    PlusOutlined
  },
  setup() {
    onBeforeMount(() => {
      fetchLocales()
    })
    const store = useStore()
    const searchInput = ref('')

    let originalLocales:Locale[] = []
    const locales = ref<Locale[]>([])
    const editableData: UnwrapRef<Record<string, Locale>> = reactive({});
    const modalVisible = ref<boolean>(false);
    const modalConfirmLoading = ref<boolean>(false);
    const filterCheckbox = ref();
    const formState: UnwrapRef<Locale> = reactive({key: '', en_value: '', vn_value: '', thai_value: ''});
    const rules = {
      key: [
        {required: true, message: 'Please input key', trigger: 'blur'},
      ]
    };
    const formRef = ref();
    const pagination = ref({
      defaultPageSize: 40,
      total: originalLocales.length,
      pageSizeOptions: ['40', '80', '120', '200', '400', '800', '1000'],
      showSizeChanger: true
    })
    const onSearchChange = () => {
      if (timeout){
        window.clearTimeout(timeout)
      }
      timeout=window.setTimeout(()=>search(),1200)
    }
    const search = () => {
      locales.value = originalLocales
      if (searchInput.value !== '') {
        locales.value = locales.value.filter((locale: Locale) => (locale.key && MixUtils.methods.removeVietnamese(locale.key.toLocaleLowerCase()).includes(MixUtils.methods.removeVietnamese(searchInput.value.trim()))) || (locale.thai_value && MixUtils.methods.removeVietnamese(locale.thai_value.toLocaleLowerCase()).includes(MixUtils.methods.removeVietnamese(searchInput.value.trim()))) || (locale.en_value && MixUtils.methods.removeVietnamese(locale.en_value.toLocaleLowerCase()).includes(MixUtils.methods.removeVietnamese(searchInput.value.trim()))) || (locale.vn_value && MixUtils.methods.removeVietnamese(locale.vn_value.toLocaleLowerCase()).includes(MixUtils.methods.removeVietnamese(searchInput.value.trim()))))
      }
      if (filterCheckbox.value && filterCheckbox.value.length) {
        filterCheckbox.value.map((str: string) => {
          if (str.includes("English")) {
            locales.value = locales.value.filter(item => !item.en_value || !item.en_value.trim())
          } else if (str.includes("Vietnamese")) {
            locales.value = locales.value.filter(item => !item.vn_value || !item.vn_value.trim())
          } else if (str.includes("Thai")) {
            locales.value = locales.value.filter(item => !item.thai_value || !item.thai_value.trim())
          }
        })
      }
      pagination.value.total=locales.value.length
    }
    const edit = (key: string) => {
      editableData[key] = {...locales.value.filter(item => key === item.key)[0]}
    };
    const remove = (key: string) => {
      APIs.init().deleteLocales(key).then(() => {
        fetchLocales()
      })
    };
    const showModal = () => {
      modalVisible.value = true;
    };

    const filterCheckboxChange = () => {
      search()
    }

    const handleOk = (e: MouseEvent) => {
      console.log(e);
      formRef.value
          .validate()
          .then(() => {
            modalConfirmLoading.value = true
            let copyLocales = [...originalLocales]
            copyLocales = [formState, ...copyLocales]
            store.commit(UsersMutationTypes.SET_Locales, copyLocales)
            locales.value = originalLocales
            if (formState){
              APIs.init().editLocales(formState).then(() => {
                fetchLocales()
                modalConfirmLoading.value = false
                modalVisible.value = false;
                formState.key = ''
                formState.en_value = ''
                formState.vn_value = ''
                formState.thai_value = ''
              })
            }
          })
          .catch((error: ValidateErrorEntity<Locale>) => {
            console.log('error', error);
          });
    };
    const save = (key: string) => {
      console.log("save", key)
      let copyLocales = [...originalLocales]
      Object.assign(copyLocales.filter(item => key === item.key)[0], editableData[key]);
      const local=copyLocales.find(x=>x.key===key)
      searchInput.value=key
      if (local){
        APIs.init().editLocales(local).then(() => {
          console.log("synchronized");
          fetchLocales()
        })
      }
      Object.assign(locales.value.filter(item => key === item.key)[0], editableData[key]);
      delete editableData[key];
    };
    const cancel = (key: string) => {
      delete editableData[key];
    };
    const fetchLocales = () => {
      APIs.init().getLocales().then(res=>{
        if (res.status === 200 || res.status === 201) {
            if (res.data&&res.data.data){
              originalLocales=res.data.data
              locales.value = originalLocales
              onSearchChange()
              pagination.value.total = originalLocales.length
            }
        }
      }).catch((reason:any) => {
        console.error("locales err",reason)
      })

    }

    return {
      searchInput,
      onSearchChange,
      columns,
      locales,
      editableData,
      edit,
      save,
      cancel,
      pagination,
      showModal,
      modalVisible,
      handleOk,
      formState,
      formRef,
      rules,
      modalConfirmLoading,
      remove,
      filterCheckbox,
      filterOptions,
      filterCheckboxChange
    };
  },
})
</script>

<style scoped lang="scss">
.container {
  margin: 10px 15px;
}

.flow-table {
  margin-top: 15px;
  background-color: white;
}

.row-horizontal {
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  .left-content {
    display: flex;
  }
}
</style>