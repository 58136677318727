<template>
  <a-modal
    v-model:visible="visible"
    :width="previewMode ? '80%' : '40%'"
    :title="$t('my_image_storage')"
    :zIndex="3000"
  >
    <VirtualList :size="50" :remain="20" style="height: 60vh">
      <a-tabs v-model:activeKey="activeKey">
        <a-tab-pane key="1" :tab="$t('IMAGE')">
          <div v-for="(item, index) of getListFile.image" :key="index">
            <a-row>
               <template v-for="child in item" :key="child.key"  >

                   <a-col :span="6" @click="clickSelect(child.key)">
                     <div
                         :class="
                  !previewMode && isSelected(child.key)
                    ? 'item-image-selection'
                    : 'item-image-unselected'
                "
                     >

                       <a-image

                           class="img-selection"
                           :preview="previewMode"
                           :src="getUrlImage(child.url)"
                       />

                       <div>{{getFileName(child.url)}}</div>
                     </div>
                   </a-col>




               </template>
            </a-row>
          </div>
        </a-tab-pane>
        <a-tab-pane key="2" tab="Video" force-render>
          <div v-for="(item, index) of getListFile.video" :key="index">
            <a-row>
          <template v-for="child in item" :key="child.key"  >

            <a-col :span="6" @click="clickSelect(child.key)">
              <div
                  :class="
                  !previewMode && isSelected(child.key)
                    ? 'item-image-selection'
                    : 'item-image-unselected'
                "
              >
                <div  >

                  <!--                  <div  style="width: 100%;height: auto;background-color: black;position: absolute;display: block;opacity: 0.5">-->

                  <!--                  </div>-->
                  <!--                  <CaretRightOutlined style="color: white;z-index: 9999;font-size: 20px;position: absolute;bottom: 68%;-->
                  <!--    left: 35%;" />-->
                  <video    style="border-radius: 10px;width: 100%;height: auto"    >
                    <source :src="child.url" type="video/mp4"/>

                  </video>
                </div>
                <div>{{getFileName(child.url)}}</div>
              </div>
            </a-col>


          </template>
            </a-row>
          </div>
        </a-tab-pane>
      </a-tabs>

    </VirtualList>
    <template #footer>
      <a-button>{{ $t("button_cancel") }}</a-button>
      <a-button
        danger
        v-if="!previewMode"
        @click="deleteSelected"
        :loading="deleteLoading"
        :disabled="selectedList.length === 0"
      >
        <delete-outlined />
        {{ $t("delete_selected_images") }}
      </a-button>
      <a-button
        :type="previewMode ? 'primary' : 'dashed'"
        @click="switchPreviewMode"
      >
        <switcher-outlined />
        {{
          previewMode
            ? $t("switch_to_selection_mode")
            : $t("switch_to_preview_mode")
        }}
      </a-button>
      <a-button
        type="primary"
        v-if="!previewMode"
        @click="onButtonUploadFileClick"
        :loading="uploadLoading"
      >
        <cloud-upload-outlined />
        {{ $t("UPLOAD") }}
      </a-button>
      <a-button
        type="primary"
        @click="handleOk"
        v-if="!previewMode"
        :disabled="selectedList.length === 0"
      >
        <check-outlined />
        {{ $t("datamodal_select") }}
      </a-button>
    </template>
  </a-modal>
  <input
    type="file"
    ref="fileInput"
    @change="handleFileUpload"
    multiple
    hidden="true"
  />
</template>
<script lang="ts">
import {
  DeleteOutlined,
  CloudUploadOutlined,
  SwitcherOutlined,
  CheckOutlined,
  CaretRightOutlined
} from "@ant-design/icons-vue";
import { computed, defineComponent, onBeforeMount, ref } from "vue";
import { useStore } from "@/store";
import { AWS_DO_FileStorageActionTypes } from "@/store/modules/AWS_DO_FileStorage/action-types";
import { AWS_DO_Model } from "@/models/AWS_DO/AWS_DO";
import APIs from "@/APIs/APIs";
import { AWS_DO_FileStorageMutationTypes } from "@/store/modules/AWS_DO_FileStorage/mutation-types";
import { AWS_DO_Request } from "@/APIs/request/AWS_DO_Request";
import { message } from "ant-design-vue";
import ImageItem from "./ImageItem.vue";
import VirtualList from "@supertiger/vue-3-virtual-scroll-list";
import fileImage from "@/assets/icons/icons8-file-200.png";
import Compressor from 'compressorjs';
import imageCompression from 'browser-image-compression';
export default defineComponent({
  name: "image-selection-modal",
  props: ["onSelect", "single"],
  components: {
    DeleteOutlined,
    CloudUploadOutlined,
    SwitcherOutlined,
    CheckOutlined,
    VirtualList,
    // CaretRightOutlined,
  },
  computed: {
    getListFile() {
      const list: any = {
        image:[],
        video:[],
      };
      let listTemp: AWS_DO_Model[] = [];
      // @ts-ignore
      let listVideo = []
      let files = {
        video: [],
        image:[]
      }
      this.fileList.map((item, index) => {
        if(this.getTypeFile(item.url)){
          // @ts-ignore
          files.video.push(item)

        }else if (!this.getTypeFile(item.url) ) {
          // @ts-ignore
          files.image.push(item)
        }
      })
      if(files.image.length  >=4) {
        files.image.map((item, index) => {
          if ((index + 1) % 4 === 0) {

            // @ts-ignore
            if (!this.getTypeFile(item.url) ) {

              listTemp.push(item);
              // @ts-ignore
              list.image.push(listTemp);
              listTemp = [];
            }

          } else {
            // @ts-ignore
            if (!this.getTypeFile(item.url)) {
              listTemp.push(item);

            }

            // @ts-ignore


            // if(list.image.length > 0){
            //
            //   for (let i = 0 ; i < list.image.length; i++){
            //     if(list.image[i]){
            //       if( list.image[i].length < 4){
            //         list.image[i].push(...listTemp)
            //       }
            //     }else {
            //       list.image.push(listTemp);
            //     }
            //   }
            // }
          }
        });
        if(listTemp.length){
          list.image.push(listTemp);
          listTemp = [];
        }

      }else {
        console.log(list.image,'sda')
        files.image.map((item, index) => {
          // @ts-ignore
          if (!this.getTypeFile(item.url)) {
            listTemp.push(item);

          }

          if (list.image.length > 0) {
            for (let i = 0; i < list.image.length; i++) {
              if (list.image[i]) {
                if (list.image[i].length < 4) {
                  console.log(list.image[i],'sda')
                  list.image[i] = listTemp
                }
              } else {
                console.log(list.image[i],'sda')
                // @ts-ignore
                 if (!this.getTypeFile(item.url)) {
                  list.image.push(item);


              }


              }
            }
          }else {
            // @ts-ignore
             if (!this.getTypeFile(item.url)) {
              list.image.push(listTemp);


            }
          }
        })
      }
      if(files.video.length  >=4) {
        files.video.map((item, index) => {
          if ((index + 1) % 4 === 0) {
            // @ts-ignore
            console.log(item.url,this.getTypeFile(item.url),'list file')
            // @ts-ignore
            if(this.getTypeFile(item.url)){
              listVideo.push(item)
              // @ts-ignore
              list.video.push(listVideo)
              listVideo = []
              // @ts-ignore
            }

          } else {
            console.log(item,'item')
            // @ts-ignore
            if (this.getTypeFile(item.url)) {
              listVideo.push(item);

            }
            // @ts-ignore
            console.log(listVideo,listTemp,'2')
            // list.push(listTemp);
            // listTemp = [];
            // if(list.length > 0){
            //   for (let i = 0 ; i < list.length; i++){
            //     if(list[i]){
            //       if( list[i].length < 4){
            //         list[i].push(...listTemp)
            //       }
            //     }else {
            //       list.push(listTemp);
            //     }
            //   }
            // }
          }
        });
        if(listVideo.length){
          // @ts-ignore
          list.video.push(listVideo);
          listVideo = [];
        }
      }else {
        console.log('564')
        files.video.map((item, index) => {
          // @ts-ignore
          if (this.getTypeFile(item.url)) {
            listVideo.push(item);

          }

          if (list.video.length > 0) {
            for (let i = 0; i < list.video.length; i++) {
              if (list.video[i]) {

                if (list.video[i].length < 4) {
                  // @ts-ignore
                  list.video[i] = listVideo
                }
              } else {
                // @ts-ignore
                if (this.getTypeFile(item.url)) {
                  // @ts-ignore
                  list.video.push(listVideo);


                }


              }
            }
          }else {
            // @ts-ignore
            if (this.getTypeFile(item.url)) {
              // @ts-ignore
              list.video.push(listVideo);


            }
          }
        })
      }
      console.log(list,'list')
      return list;
    },
  },
  setup(props) {
    const visible = ref<boolean>(false);
    const previewMode = ref<boolean>(false);
    const selectedList = ref<string[]>([]);
    const store = useStore();
    const fileInput = ref();
    let param = "";
    const deleteLoading = ref(false);
    const uploadLoading = ref(false);
    const imageItem = ref(ImageItem);
    const fileList = computed<AWS_DO_Model[]>(
      () => store.getters.getAWS_DO_Files
    );
    const showModal = (p?: string) => {
      param = p ? p : "";
      store.dispatch(AWS_DO_FileStorageActionTypes.FETCH_FILES, undefined);
      selectedList.value = [];
      visible.value = true;
    };
    const switchPreviewMode = () => {
      previewMode.value = !previewMode.value;
    };

    const getUrlImage=(url:string):string=>{
      const fileSplit=url.split(".").pop()
      if (fileSplit&&(fileSplit.includes("png")||fileSplit.includes("svg")||fileSplit.includes("jpg")||fileSplit.includes("jpeg"))){
        return url;
      }
      return fileImage
    }
    const getFileName=(url:string):string=>{
      const fileSplit=url.split("/").pop()
      if (fileSplit){
        return fileSplit;
      }
      return url
    }
    const handleOk = () => {
      if (props.onSelect) {
        const list = fileList.value.filter((item: AWS_DO_Model) =>
          selectedList.value.includes(item.key)
        );
        console.log(list,'list')
        props.onSelect(list, param);
        visible.value = false;
      }

    };
    const clickSelect = (key: string) => {
      if (props.single) {
        selectedList.value = [key];
      } else {
        if (selectedList.value && selectedList.value.includes(key)) {
          selectedList.value = selectedList.value.filter(
            (x: string) => x !== key
          );
        } else {
          selectedList.value = [...selectedList.value, key];
        }
      }
    };
    const userInfo = computed(()=> store.getters.getUserInfo)
   const getTypeFile = (url:string) => {
      return url.includes('mp4') || url.includes('mov')

    }
    const isSelected = (key: string) => selectedList.value.includes(key);
    const handleFileUpload = (event: Event) => {
      const target = event.target as HTMLInputElement;
      const file = target.files
      console.log(file,'file')
      // @ts-ignore
      for (let i = 0 ;i < file.length; i++){
        console.log(i,'icc')
        // @ts-ignore
        if(file[i].type === "video/mp4"){
          const formData = new FormData();
          // @ts-ignore
          formData.append("files", file[i]);

          uploadLoading.value = true;
          APIs.init()
              .uploadFiles(formData)
              .then((res) => {
                uploadLoading.value = false;
                if (res.status === 201 || res.status === 200) {

                  if (res.data?.data) {
                    store.commit(AWS_DO_FileStorageMutationTypes.SET_FILES, [
                      ...res.data?.data,
                      ...fileList.value,
                    ]);
                  }
                } else {
                  message.error({ content: "An error occurring" }, 1500);
                }
              })
              .catch((e) => {
                message.error({ content: "An error occurring" }, 1500);

                uploadLoading.value = false;
                console.error(e);
              });
          // @ts-ignore
        }else {
          // @ts-ignore
          var imageFile = file[i];
          var controller = new AbortController();

          var options = {
            // other options here
            maxSizeMB: 1,
            maxWidthOrHeight: 1920,
            useWebWorker: true,
            signal: controller.signal,
          }
          imageCompression(imageFile, options)
              .then(function (files) {
                const formData = new FormData();
                formData.append("files", files,files.name);

                uploadLoading.value = true;
                APIs.init()
                    .uploadFiles(formData)
                    .then((res) => {
                      uploadLoading.value = false;
                      if (res.status === 201 || res.status === 200) {

                        if (res.data?.data) {
                          store.commit(AWS_DO_FileStorageMutationTypes.SET_FILES, [
                            ...res.data?.data,
                            ...fileList.value,
                          ]);
                        }
                      } else {
                        message.error({ content: "An error occurring" }, 1500);
                      }
                    })
                    .catch((e) => {
                      message.error({ content: "An error occurring" }, 1500);

                      uploadLoading.value = false;
                      console.error(e);
                    })
              })
              .catch(function (error) {
                console.log(error.message); // output: I just want to stop
              });
          //  new Promise((resolve) => {
          //   // @ts-ignore
          //   new Compressor(file[i], {
          //     quality: 0.6,
          //     mimeType: 'image/jpeg',
          //     // width: 1600,
          //     // height: 1600,
          //     success(result) {
          //       // console.log(result, 'result')
          //       resolve(result as any)
          //     },
          //     error(err) {
          //       console.log(err.message);
          //     },
          //   });
          //
          // }).then((files)=>{
          //
          //   // @ts-ignore
          //   const getSizeImage = files.size;
          //   // @ts-ignore
          //   // console.log(files,getSizeImage,'getSizeImage')
          //   // @ts-ignore
          //   if (getSizeImage < 1024 * 1024) {
          //     const formData = new FormData();
          //     // @ts-ignore
          //
          //     // @ts-ignore
          //     formData.append("files", files,files.name);
          //
          //     uploadLoading.value = true;
          //     APIs.init()
          //         .uploadFiles(formData)
          //         .then((res) => {
          //           uploadLoading.value = false;
          //           if (res.status === 201 || res.status === 200) {
          //
          //             if (res.data?.data) {
          //               store.commit(AWS_DO_FileStorageMutationTypes.SET_FILES, [
          //                 ...res.data?.data,
          //                 ...fileList.value,
          //               ]);
          //             }
          //           } else {
          //             message.error({ content: "An error occurring" }, 1500);
          //           }
          //         })
          //         .catch((e) => {
          //           message.error({ content: "An error occurring" }, 1500);
          //
          //           uploadLoading.value = false;
          //           console.error(e);
          //         });
          //   }else {
          //     message.warn({
          //       content: () => 'Ảnh của bạn phải dưới 1MB',
          //       style: {
          //         "font-size": "35px"
          //       },})
          //
          //   }
          // });

        }
        // else {
        //   // @ts-ignore
        //   console.log(file,'files765')
        //   const formData = new FormData();
        //   // @ts-ignore
        //
        //   // @ts-ignore
        //   formData.append("files", file[i]);
        //
        //   uploadLoading.value = true;
        //   APIs.init()
        //       .uploadFiles(formData)
        //       .then((res) => {
        //         uploadLoading.value = false;
        //         if (res.status === 201 || res.status === 200) {
        //
        //           if (res.data?.data) {
        //             store.commit(AWS_DO_FileStorageMutationTypes.SET_FILES, [
        //               ...res.data?.data,
        //               ...fileList.value,
        //             ]);
        //           }
        //         } else {
        //           message.error({ content: "An error occurring" }, 1500);
        //         }
        //       })
        //       .catch((e) => {
        //         message.error({ content: "An error occurring" }, 1500);
        //
        //         uploadLoading.value = false;
        //         console.error(e);
        //       });
        // }

      }


    };
    const deleteSelected = () => {
      const request: AWS_DO_Request = {
        keys: selectedList.value,
      };
      deleteLoading.value = true;
      APIs.init()
        .deleteFiles(request)
        .then((res) => {
          deleteLoading.value = false;
          if (res.status === 201 || res.status === 200) {
            const list = fileList.value.filter(
              (item: AWS_DO_Model) => !selectedList.value.includes(item.key)
            );
            store.commit(AWS_DO_FileStorageMutationTypes.SET_FILES, list);
            selectedList.value = [];
          } else {
            message.error({ content: "An error occurring" }, 1500);
          }
        })
        .catch((e) => {
          deleteLoading.value = false;
          message.info({ content: "An error occurring" }, 1500);
          console.error(e);
        });
    };

    const onButtonUploadFileClick = () => {
      fileInput.value.click();
    };
    return {
      visible,
      showModal,
      handleOk,
      previewMode,
      switchPreviewMode,
      fileList,
      clickSelect,
      isSelected,
      handleFileUpload,
      fileInput,
      onButtonUploadFileClick,
      deleteSelected,
      deleteLoading,
      uploadLoading,
      selectedList,
      imageItem,
      getUrlImage,
      getFileName,
      getTypeFile
    };
  },
});
</script>
<style lang="scss">
.item-image-selection {
  padding: 4px;
  border: 2px solid #1890ff;
  border-radius: 5px;
  width: 100%;

  .ant-image {
    width: 100%;
    aspect-ratio: 1 / 1 !important;
    object-fit: cover !important;
  }
  .ant-image-img {
    width: 100%;
    aspect-ratio: 1 / 1 !important;
    object-fit: cover !important;
  }
}

.item-image-unselected {
  padding: 4px;
  border-radius: 5px;
  width: 100%;
  aspect-ratio: 1 / 1;

  .ant-image {
    width: 100%;
    aspect-ratio: 1 / 1 !important;
    object-fit: cover !important;
  }
  .ant-image-img {
    width: 100%;
    aspect-ratio: 1 / 1 !important;
    object-fit: cover !important;
  }
}

.img-selection {
}
</style>
