import {State} from "./states";
import {Overview, OverviewFB, OverviewITG, OverviewReport, OverviewZalo} from "@/models/Overview/Overview";


export type Getters = {
    getOverview(state: State): Overview[]
    getOverviewLoading(state: State): boolean
    getOverviewFB(state: State): OverviewFB[]
    getOverviewITG(state: State): OverviewITG[]
    getOverviewZalo(state: State):OverviewZalo[]
    getOverviewReport(state: State): OverviewReport


}
export const getters: Getters = {
    getOverview(state: State):  Overview[] {

        if (state.data) {
            return state.data
        } else {
          return []
        }
    },
    getOverviewLoading(state: State):  boolean {
        console.log(state,'loading')
            return state.loading

    },
    getOverviewFB(state: State):  OverviewFB[] {

        if (state.dataFb) {
            return state.dataFb
        } else {
            return []
        }
    },
    getOverviewZalo(state: State):  OverviewZalo[] {
        if (state.dataZalo) {
            return state.dataZalo
        } else {
            return []
        }
    },
    getOverviewITG(state: State):  OverviewITG[] {

        if (state.dataItg) {
            return state.dataItg
        } else {
            return []
        }
    },
    getOverviewReport(state: State):  OverviewReport {

        if (state.dataReport) {
            return state.dataReport
        } else {
            return {
                took: 0,
                    timed_out: true,
                    _shards: {
                    total: 0,
                        successful: 0,
                        failed: 0
                },
                hits: {
                    total: 0,
                        max_score: 0,
                        hits: []
                },
                aggregations: {
                    current: {
                        doc_count: 0,
                            date_time: {
                            buckets: [
                                {
                                    key: 0,
                                    doc_count: 0,
                                    conversation_count: {
                                        value: 0
                                    }
                                },
                                {
                                    key: 0,
                                    doc_count: 0,
                                    conversation_count: {
                                        value: 0
                                    }
                                },
                                {
                                    key: 0,
                                    doc_count: 0,
                                    conversation_count: {
                                        value: 0
                                    }
                                },
                                {
                                    key: 0,
                                    doc_count: 0,
                                    conversation_count: {
                                        value: 7
                                    }
                                },
                                {
                                    key: 0,
                                    doc_count: 0,
                                    conversation_count: {
                                        value: 0
                                    }
                                },
                                {
                                    key: 0,
                                    doc_count: 0,
                                    conversation_count: {
                                        value: 0
                                    }
                                },
                                {
                                    key: 0,
                                    doc_count: 0,
                                    conversation_count: {
                                        value: 0
                                    }
                                },
                                {
                                    key: 0,
                                    doc_count: 0,
                                    conversation_count: {
                                        value: 0
                                    }
                                },
                                {
                                    key: 0,
                                    doc_count: 0,
                                    conversation_count: {
                                        value: 0
                                    }
                                }
                            ]
                        },
                        sum_conversation: {
                            value: 0
                        }
                    },
                    current_by_phone: {
                        doc_count: 0,
                            has_phone: {
                            doc_count: 0,
                                date_time: {
                                buckets: [
                                    {
                                        key: 0,
                                        doc_count: 0,
                                        conversation_count: {
                                            value: 0
                                        }
                                    },
                                    {
                                        key: 0,
                                        doc_count: 0,
                                        conversation_count: {
                                            value: 0
                                        }
                                    },
                                    {
                                        key: 0,
                                        doc_count: 0,
                                        conversation_count: {
                                            value: 0
                                        }
                                    },
                                    {
                                        key: 0,
                                        doc_count: 0,
                                        conversation_count: {
                                            value: 0
                                        }
                                    },
                                    {
                                        key: 0,
                                        doc_count: 0,
                                        conversation_count: {
                                            value: 0
                                        }
                                    },
                                    {
                                        key: 0,
                                        doc_count: 0,
                                        conversation_count: {
                                            value: 0
                                        }
                                    },
                                    {
                                        key: 0,
                                        doc_count: 0,
                                        conversation_count: {
                                            value: 0
                                        }
                                    },
                                    {
                                        key: 0,
                                        doc_count: 0,
                                        conversation_count: {
                                            value: 0
                                        }
                                    }
                                ]
                            },
                            sum_conversation: {
                                value: 0
                            }
                        }
                    }
                }
            }
        }
    },
}
