import create from "zustand-vue";
import { persist } from "zustand/middleware";
import APIs from "@/APIs/APIs";
import { Wallet } from "@/models/Wallet/Wallet";
import { FlowZNSTransaction } from "@/models/Wallet/FlowTransaction";
import { WalletTransaction } from "@/models/Wallet/WalletTransaction";
import moment from "moment";

interface WalletState {
  wallet: Wallet | undefined;
  isWalletFetching: boolean;
  znsTransactions: FlowZNSTransaction[];
  znsTransCount: number;
  znsTransTotalAmount: number;
  walletTransactions: WalletTransaction[];
  walletTransactionCount: number;
  // eslint-disable-next-line @typescript-eslint/ban-types
  getWallet: () => void;
  getWalletFetching: (params: any) => void;
  getFlowZNS_Transactions: (params: any) => void;

  getWalletTransactions: (params: any) => void;
  getWalletTransactionCount: (params: any) => void;
}

const initialState = {
  wallet: undefined,
  isWalletFetching: true,
  znsTransactions: [],
  znsTransCount: 0,
  znsTransTotalAmount: 0,
  walletTransactions: [],
  walletTransactionCount: 0,
};

const useWalletStore = create<WalletState>(
  // @ts-ignore
  persist(
    (set) => ({
      ...initialState,
      getWallet: async () => {
        const response = await APIs.init().getMyWallet("vnd");
        if (response.data && response.data.status === 200) {
          // @ts-ignore
          set(() => ({
            // @ts-ignore
            wallet: response.data.data,
          }));
        }
      },
      getWalletFetching: async (params) => {
        const response = await APIs.init().getFiles();
        if (response.data && response.data.status === 200) {
          // @ts-ignore
          set(() => ({
            // @ts-ignore
            isWalletFetching: true,
          }));
        }
      },
      getFlowZNS_Transactions: async (params) => {
        const response = await APIs.init().getFlowZNSTransactions(params);
        if (response.data && response.data.status === 200) {
          // @ts-ignore
          set(() => ({
            // @ts-ignore
            znsTransactions: response.data?.data.hits.hits.map(x => x._source).map(x => {
                    x.created_at_str = moment(x.created_at * 1000).format("DD-MM-YYYY HH:mm")
                    return x;
                }),
              // @ts-ignore
              znsTransCount: response.data.data.hits.total,
              // @ts-ignore
              znsTransTotalAmount: response.data.data.aggregations.total_amount.value

          }));
        }
      },
      getWalletTransactions: async (params) => {
        const response = await APIs.init().getWalletTransactions(params);
        if (response.data && response.data.status === 200) {
          // @ts-ignore
          set(() => ({
            // @ts-ignore
            walletTransactions: response.data.data,
          }));
        }
      },
      getWalletTransactionCount: async (params) => {
        const response = await APIs.init().getWalletTransactions(params);
        if (response.data && response.data.status === 200) {
          // @ts-ignore
          set(() => ({
            // @ts-ignore
            walletTransactionCount: response.data.data,
          }));
        }
      },
    }),
    {
      name: "wallet",
    }
  )
);

export default useWalletStore;
