<template>
  <div class="container">
    <a-row class="header"   justify="start">
      <img
          src="@/assets/icons/zalo.webp"
          style="width: 28px;"/>
      <a-col>
        <div class="description">Zalo OA</div>
        <span class="content">{{data.name}}</span>
      </a-col>

    </a-row>
    <ZaloOAPreview :flowContext="data"
                   type="flow-new"
    />

  </div>
  <Handle :id="data.id+'_in'" type="target"  class="handle" :position="Position.Left" :style="sourceHandleStyleA" />

  <div class="footer">
    <div  style="height: 2px; background-color: #f2f4f6;"/>

    <template v-for="(handle, index) in listHandle" :key="handle">
      <HandleCustom :data="handle" :index="index"  />
    </template>
  </div>


</template>

<script>
import {defineComponent, defineProps, defineEmits, onMounted, ref} from "vue";
import { computed } from 'vue'
import { Position, VueFlow, useVueFlow,Handle  } from '@vue-flow/core'
import ZaloOAPreview from "@/components/Flow/ZaloOAPreview.vue";
import HandleCustom from "./HandleCustom.vue";
import {useI18n} from "vue-i18n";

const props = defineProps({
  data: {
    type: Object,
    required: true,
  },
})


export default defineComponent({
  name: "TriggerComponent",
  components:{
    Handle,
    HandleCustom,
    ZaloOAPreview
  },
  props: [ 'data'],
  setup(props, {emit}){
    const i18n = useI18n()

    const listHandle = ref([
      {
        id: props.data.id+'action_send_success_context',
        name: i18n.t('ACTION_SEND_SUCCESS')
      },
      {
        id: props.data.id+'action_send_failed_context',
        name: i18n.t('ACTION_SEND_FALSE')
      },
      {
        id: props.data.id+'action_default_context',
        name: i18n.t('ACTION_REQUEST_USER_INFO')
      }
    ])

    const sourceHandleStyleA = computed(() => ({ top: '10px',
      bottom: 'auto', }))

    const sourceHandleStyleC = computed(() => ({  top: 'auto',
      bottom: '10px', }))

    return{
      sourceHandleStyleA,
      sourceHandleStyleC,
      Position,
      listHandle,
    }
  }
})
</script>

<style scoped>
.container{
  padding: 16px 16px 16px 16px;
  font-size: 14px;
}

.header{
  display: flex;
  flex-flow: row;
  align-items: center;
  gap: 8px;
  margin-bottom: 10px;
}

.content{
  font-size: 16px;
  font-weight: normal;
}

.description{
  color: #758697;
  font-size: 10px;
}
.footer{
  padding: 16px
}
</style>
