import {State} from "./states";
import {TransporterMutationTypes} from "@/store/modules/transporter/mutation-types";
import {Transporter} from "@/models/Transporter/Transporter";

export type Mutations<S = State> = {
    [TransporterMutationTypes.GET_TRANSPORTER](state: S, payload: Transporter[]|undefined): void;
}

export const mutations: Mutations = {
    [TransporterMutationTypes.GET_TRANSPORTER](state: State, payload: Transporter[]|undefined): void {
        state.data = payload
    }

}