import { EmployeeSearch } from "@/models/employee/Employee";
import {EmployeeData, State} from "@/store/modules/employee/states";
import { EmployeeMutationTypes } from "./mutation-types";



export type Mutations<S = State> = {
    [EmployeeMutationTypes.GET_EMPLOYEE_SEARCH](state : S,payload: EmployeeSearch[]|undefined):void,
    [EmployeeMutationTypes.GET_EMPLOYEE](state : S,payload: EmployeeData|undefined):void,
   


}

export const mutations : Mutations ={
    [EmployeeMutationTypes.GET_EMPLOYEE_SEARCH](state : State,payload: EmployeeSearch[]|undefined): void{
        
        state.dataSearch = payload
    },
    [EmployeeMutationTypes.GET_EMPLOYEE](state : State,payload: EmployeeData|undefined): void{
        state.employeeData = payload
    },
    
}
