import {Trigger} from "@/models/Trigger/Trigger";
import {Metadata} from "@/APIs/response/S_HttpResponse";
import {TriggerStatistic, TriggerStatisticErrorLog, Viral} from "@/models/Trigger/TriggerHistory";

export type State = {
    triggers: Trigger[] | undefined;
    triggerMetadata: Metadata | undefined;
    triggerStatistics: TriggerStatistic[];
    triggerInnerStatistics: TriggerStatistic[];
    triggerStatisticMetadata: Metadata;
    innerTriggerStatisticMetadata: Metadata;
    triggerQuickMessages: Trigger[];
    triggerStatisticErrorLogs: TriggerStatisticErrorLog[];
    viral: Viral[]| undefined
}

export const state: State = {
    triggerMetadata: {},
    triggers: [],
    triggerStatistics: [],
    triggerInnerStatistics: [],
    triggerStatisticMetadata: {},
    innerTriggerStatisticMetadata: {},
    triggerQuickMessages: [],
    triggerStatisticErrorLogs: [],
    viral:[]

}
