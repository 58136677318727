<template>

  <div class="container-title">
    <h3 class="title-header">{{$t($route.name)}}</h3>
    <a-range-picker
        :allowClear="false"
        v-model:value="rangeTime"
        format="DD/MM/YYYY"
        :placeholder="[$t('START_TIME_PLACEHOLDER'), $t('END_TIME_PLACEHOLDER')]"
        @change="onChangeDateTime"
        @ok="confirmDateTime"
    >
      <template #suffixIcon>
        <CalendarOutlined/>
      </template>
    </a-range-picker>
  </div>
  <div style="padding: 16px">


    <a-table
        :columns="columns"
        :data-source="customerData.reportEmployee"
        :pagination="false"
        :scroll="{ x: 2600, y: 500 }"
        style="min-height: 300px"
        bordered
    >
      <template #created_by="{record}">
        {{getEmployee(record.created_by)}}
      </template>
      <template #total_amount="{record}">
        {{formatMoney(record.total_amount)}}đ
      </template>
      <template #REVENUE="{record}">
        {{formatMoney(record.total_amount_success)}}đ
      </template>
      <template #ship_fee_collected="{record}">
        {{formatMoney(record.ship_fee_collected)}}đ
      </template>
      <template #total_amount_cancel="{record}">
        {{formatMoney(record.total_amount_cancel)}}đ
      </template>
      <template #total_amount_confirm="{record}">
        {{formatMoney(record.total_amount_confirm)}}đ
      </template><template #total_amount_return="{record}">
      {{formatMoney(record.total_amount_return)}}đ
    </template><template #total_amount_success="{record}">
      {{formatMoney(record.total_amount_success)}}đ
    </template>
      <template #total_discount="{record}">
        {{formatMoney(record.total_discount)}}đ
      </template>
      <template #total_goods_cost="{record}">
        {{formatMoney(record.total_goods_cost)}}đ
      </template>
      <template #total_shipfee="{record}">
        {{formatMoney(record.total_shipfee)}}đ
      </template>



      <template #summary>

          <a-table-summary-row>
            <a-table-summary-cell :index="0">{{$t('TOTAL')}}</a-table-summary-cell>
            <a-table-summary-cell :index="1">{{customerData.totalEmployee.count}}</a-table-summary-cell>
            <a-table-summary-cell :index="1">{{formatMoney(customerData.totalEmployee.total_amount)}}đ</a-table-summary-cell>
            <a-table-summary-cell :index="1">{{formatMoney(customerData.totalEmployee.total_discount)}}đ</a-table-summary-cell>
            <a-table-summary-cell :index="1">{{formatMoney(customerData.totalEmployee.total_shipfee)}}đ</a-table-summary-cell>
            <a-table-summary-cell :index="1">{{formatMoney(customerData.totalEmployee.total_goods_cost)}}đ</a-table-summary-cell>
            <a-table-summary-cell :index="1">{{formatMoney(customerData.totalEmployee.total_amount_success)}}đ</a-table-summary-cell>
            <a-table-summary-cell :index="1">{{customerData.totalEmployee.count_success}}</a-table-summary-cell>
            <a-table-summary-cell :index="1">{{formatMoney(customerData.totalEmployee.total_amount)}}đ</a-table-summary-cell>
            <a-table-summary-cell :index="1">{{formatMoney(customerData.totalEmployee.ship_fee_collected)}}đ</a-table-summary-cell>
            <a-table-summary-cell :index="1">{{formatMoney(customerData.totalEmployee.total_discount)}}đ</a-table-summary-cell>
            <a-table-summary-cell :index="1">{{formatMoney(customerData.totalEmployee.total_shipfee)}}đ</a-table-summary-cell>
            <a-table-summary-cell :index="1">{{formatMoney(customerData.totalEmployee.total_goods_cost)}}đ</a-table-summary-cell>
            <a-table-summary-cell :index="1">{{formatMoney(customerData.totalEmployee.total_amount_success)}}đ</a-table-summary-cell>
            <a-table-summary-cell :index="1">{{customerData.totalEmployee.count_confirm}}đ</a-table-summary-cell>
            <a-table-summary-cell :index="1">{{formatMoney(customerData.totalEmployee.total_amount_confirm)}}đ</a-table-summary-cell>
            <a-table-summary-cell :index="1">{{customerData.totalEmployee.count_return}}</a-table-summary-cell>
            <a-table-summary-cell :index="1">{{formatMoney(customerData.totalEmployee.total_amount_return)}}đ</a-table-summary-cell>
            <a-table-summary-cell :index="1">{{customerData.totalEmployee.count_cancel}}</a-table-summary-cell>
            <a-table-summary-cell :index="1">{{formatMoney(customerData.totalEmployee.total_amount_cancel)}}đ</a-table-summary-cell>
<!--            <a-table-summary-cell :index="1">{{customerData.totalEmployee.count}}</a-table-summary-cell>-->
<!--            <a-table-summary-cell :index="1">{{customerData.totalEmployee.count}}</a-table-summary-cell>-->
<!--            <a-table-summary-cell :index="1">{{customerData.totalEmployee.count}}</a-table-summary-cell>-->
<!--            <a-table-summary-cell :index="1">{{customerData.totalEmployee.count}}</a-table-summary-cell>-->
<!--            <a-table-summary-cell :index="1">{{customerData.totalEmployee.count}}</a-table-summary-cell>-->
<!--            <a-table-summary-cell :index="1">{{customerData.totalEmployee.count}}</a-table-summary-cell>-->

          </a-table-summary-row>

      </template>
    </a-table>
  </div>
</template>

<script>

import {useStore} from "vuex";
import {ShopinfoActionTypes} from "@/store/modules/Shopinfo/action-types";
import {computed, onMounted, ref} from "vue";
import moment, {Moment} from "moment";
import {useI18n} from "vue-i18n";
import APIs from "@/APIs/APIs";
import {MixUtils} from "@/utils/Utils";
import {CalendarOutlined} from "@ant-design/icons-vue";
import dayjs from "dayjs";
import {ReportPAGEActionTypes} from "../../../store/modules/ReportPage/action-types";
import useEmployeeStore from "../../../store/Employee";
import useReportPageStore from "../../../store/ReportPage";




export default {
  components: {
    CalendarOutlined
  },
  setup() {

    const i18n = useI18n()
    const store = useStore();
    const rangeTime = ref([dayjs().add(-1, 'months').startOf('day'), dayjs().endOf('day')])
    const employeeStore = useEmployeeStore()
    const fetchShopInfo = () => {
      store.dispatch(ShopinfoActionTypes.FETCH_SHOPINFO);
    };
    const fetchCusomer = () => {
      useEmployeeStore.getState().GetEmployee({
        page: 1,
        page_size: 999,
      })
      useReportPageStore.getState().getReportEmployee({
        from_date: rangeTime.value[0].format('YYYY-MM-DDT00:00:00Z'),
        to_date: rangeTime.value[1].format('YYYY-MM-DDT23:59:59Z'),
      })
      // store.dispatch(ReportPAGEActionTypes.EMPLOYEE,{
      //   from_date: rangeTime.value[0].format('YYYY-MM-DDT00:00:00Z'),
      //   to_date: rangeTime.value[1].format('YYYY-MM-DDT23:59:59Z'),
      // });
    };
    const dataShopInfo = computed(() => store.getters.getShopinfo);
    const pagesSelection = computed(() => store.getters.getPagesSelection);
    const getPages = computed(() => store.getters.getPages);
    const customerData =useReportPageStore()

    const selectedPage = ref();

    const columns = [
      {
        title: i18n.t('EMPLOYEE'),
        dataIndex: 'created_by',
        key: 'created_by',
        slots: {customRender: "created_by"},
        width: 150,
        fixed: 'left',
      },
      {
        title: i18n.t('NEW_ORDERS'),
        dataIndex: 'count',
        key: 'count',
        slots: {customRender: "count"},
        width: 100,
      },
      // {
      //   title: i18n.t('GROSS_PRODUCTS'),
      //   dataIndex: 'assigee',
      //   key: 'assigee',
      //   slots: {customRender: "assigee"},
      //   width: 100,
      // },
      {
        title: i18n.t('sales'),
        dataIndex: 'total_amount',
        key: 'total_amount',
        slots: {customRender: "total_amount"},
        width: 100,
      },
      // {
      //   title: i18n.t('GTTB'),
      //   dataIndex: 'order_from_new_customer_percent',
      //   key: 'order_from_new_customer_percent',
      //   slots: {customRender: "order_not_ads_percent"},
      //   width: 100,
      // },
      {
        title: i18n.t('DISCOUNT'),
        dataIndex: 'total_discount',
        key: 'total_discount',
        slots: {customRender: "total_discount"},
        width: 100,
      },

      {
        title: i18n.t('SHIPPING_FEE_PAID_BY_SHOP'),
        dataIndex: 'total_shipfee',
        key: 'total_shipfee',
        slots: {customRender: "total_shipfee"},
        width: 100,
      },
      {
        title: i18n.t('COST_OF_GOOD_SOLD'),
        dataIndex: 'total_goods_cost',
        key: 'total_goods_cost',
        slots: {customRender: "total_goods_cost"},
        width: 100,
      },
      {
        title: i18n.t('REVENUE'),
        dataIndex: 'REVENUE',
        key: 'REVENUE',
        slots: {customRender: "REVENUE"},
        width: 100,
      },

      {
        title: i18n.t('DELIVERED'),
        color:'black',
        children: [
          {
            title: i18n.t('NUMBER_OF_ORDERS'),
            dataIndex: 'count_success',
            key: 'count_success',
            slots: {customRender: "count_success"},
            width: 100,
          },
          // {
          //   title: i18n.t('PRODUCT_SOLD'),
          //   dataIndex: 'order_from_new_customer_percent',
          //   key: 'order_from_new_customer_percent',
          //   slots: {customRender: "order_not_ads_percent"},
          //   width: 100,
          // },
          {
            title: i18n.t('sales'),
            dataIndex: 'total_amount',
            key: 'total_amount',
            slots: {customRender: "total_amount"},
            width: 100,
          },
          // {
          //   title: i18n.t('PRICE'),
          //   dataIndex: 'total_goods_cost',
          //   key: 'total_goods_cost',
          //   slots: {customRender: "total_goods_cost"},
          //   width: 100,
          // },
          {
            title: i18n.t('SHIPPING_FEE_PAID_BY_CUSTOMER'),
            dataIndex: 'ship_fee_collected',
            key: 'ship_fee_collected',
            slots: {customRender: "ship_fee_collected"},
            width: 100,
          },
          // {
          //   title: i18n.t('SURCHARGE'),
          //   dataIndex: 'order_from_new_customer_percent',
          //   key: 'order_from_new_customer_percent',
          //   slots: {customRender: "order_not_ads_percent"},
          //   width: 100,
          // },
          {
            title: i18n.t('DISCOUNT'),
            dataIndex: 'total_discount',
            key: 'total_discount',
            slots: {customRender: "total_discount"},
            width: 100,
          },
          {
            title: i18n.t('SHIPPING_FEE_PAID_BY_SHOP'),
            dataIndex: 'total_shipfee',
            key: 'total_shipfee',
            slots: {customRender: "total_shipfee"},
            width: 100,
          },
          // {
          //   title: i18n.t('MARKETPLACE_FEE'),
          //   dataIndex: 'order_from_new_customer_percent',
          //   key: 'order_from_new_customer_percent',
          //   slots: {customRender: "order_not_ads_percent"},
          //   width: 100,
          // },
          {
            title: i18n.t('COST_OF_GOOD_SOLD'),
            dataIndex: 'total_goods_cost',
            key: 'total_goods_cost',
            slots: {customRender: "total_goods_cost"},
            width: 100,
          },
          {
            title: i18n.t('REVENUE'),
            dataIndex: 'REVENUE',
            key: 'REVENUE',
            slots: {customRender: "REVENUE"},
            width: 100,
          },
        ],
      },
      {
        title: i18n.t('PROCESSING'),
        color:'black',
        children: [
          {
            title: i18n.t('NUMBER_OF_ORDERS'),
            dataIndex: 'count_confirm',
            key: 'count_confirm',
            slots: {customRender: "count_confirm"},
            width: 100,
          },
          {
            title: i18n.t('sales'),
            dataIndex: 'total_amount_confirm',
            key: 'total_amount_confirm',
            slots: {customRender: "total_amount_confirm"},
            width: 100,
          },
        ],
      },
      {
        title: i18n.t('RETURNED_ORDER'),
        color:'black',
        children: [
          {
            title: i18n.t('NUMBER_OF_ORDERS'),
            dataIndex: 'count_return',
            key: 'count_return',
            slots: {customRender: "count_return"},
            width: 100,
          },
          {
            title: i18n.t('sales'),
            dataIndex: 'total_amount_return',
            key: 'total_amount_return',
            slots: {customRender: "total_amount_return"},
            width: 100,
          },
        ],
      },
      {
        title: i18n.t('CANCELED_OR_DELETED'),
        color:'black',
        children: [
          {
            title: i18n.t('NUMBER_OF_ORDERS'),
            dataIndex: 'count_cancel',
            key: 'count_cancel',
            slots: {customRender: "count_cancel"},
            width: 100,
          },
          {
            title: i18n.t('sales'),
            dataIndex: 'total_amount_cancel',
            key: 'total_amount_cancel',
            slots: {customRender: "total_amount_cancel"},
            width: 100,
          },
        ],
      },
    ];




    const getEmployee = (data) => {
      console.log(data,'idstaff')
      if(data){
        console.log(useEmployeeStore.getState().employees.find( item => item.cms_user_id === data),'sadas')
        let user = useEmployeeStore.getState().employees.find( item => item.cms_user_id === Number(data))
        if(user){
          return user.name
        }else {
          return data
        }

      }else {
        return ''
      }

    }

    const splineChart = ref()


    const handleReport = () => {
      // fetchReportComment();
      // fetchReportMessage();
      // fetchReportNewConversation();
    };

    onMounted(() => {

      fetchShopInfo();
      fetchCusomer()

    });

    const formatMoney = (val) => {
      return MixUtils.methods.formatMoney(val)
    }

    const calculatorPercent = (a, total) => {
      return Math.round(a / total * 10000) / 100
    }


    const handlePageChange = (val) => {
      selectedPage.value = val;
      console.log(val)
      fetchCusomer()
    };


    const onChangeDateTime = (value, dateString) => {
      console.log('Selected Time: ', value);
      console.log('Formatted Selected Time: ', dateString);
      if (value[0].format('HH-mm') === value[1].format('HH-mm')) {
        rangeTime.value = [value[0].startOf('day'), value[1].endOf('day')]

      } else {
        rangeTime.value = value
      }
      fetchCusomer()
    };

    const confirmDateTime = (value) => {
      console.log('onOk: ', value);
    };


    return {
      dataShopInfo,
      pagesSelection,
      handlePageChange,
      handleReport,
      selectedPage,
      splineChart,
      formatMoney,
      calculatorPercent,
      getPages,
      onChangeDateTime,
      confirmDateTime,
      rangeTime,
      columns,
      customerData,
      employeeStore,

      getEmployee
    };
  },
};
</script>

<style>
.card_view {
  border-radius: 10px;
  margin: 4px;
  overflow: hidden;
}

.count-elements {
  -webkit-tap-highlight-color: transparent;
  -webkit-text-size-adjust: 100%;
  font-family: Roboto, Helvetica, Arial, sans-serif;
  font-variant: tabular-nums;
  line-height: 1.5;
  font-feature-settings: 'tnum';
  font-size: 14px;
  color: #000;
  box-sizing: border-box;
  height: 76px;
  padding: 10px;
  border-top: 1px solid #e0e0e0;
  border-bottom: 1px solid #e0e0e0;
  border-radius: 10px 0px 0px 10px;
  cursor: pointer;
  box-shadow: 0px 2px 10px rgba(51, 51, 51, 0.1);
  border-left: 10px solid rgb(43, 216, 196);
  background-color: rgba(196, 255, 248, 0.23);
  width: 100%;
}

.title {
  font-family: "Roboto" !important;
  font-style: normal !important;
  font-weight: 700 !important;
  font-size: 12px !important;
  line-height: 22px !important;
  color: #000000 !important;
  margin-left: 0px !important;
  margin-top: 0px !important;
  text-align: left !important;
  width: 100% !important;
}

.count {
  text-align: right;
  font-weight: 100;
  font-family: Montserrat-Light;
  font-size: 25px;
  line-height: 30px;
  color: #263C8F;
  float: right;
}

.highcharts-credits {
  display: none;
}
</style>
