<template>

  <div class="container-title">
    <h3 class="title-header">{{$t($route.name)}}</h3>
<!--    <a-row>-->
<!--      <a-col :span="6">-->

<!--        <a-input  style="width: 100%;margin-bottom: 16px"  :placeholder="$t('ORDER')">-->
<!--          <template #suffix>-->
<!--            <a-tooltip title="Extra information">-->
<!--              <SearchOutlined style="color: rgba(0, 0, 0, 0.45)" />-->
<!--            </a-tooltip>-->
<!--          </template>-->
<!--        </a-input>-->
<!--        <a-input    style="width: 100%;margin-bottom: 16px" :placeholder="$t('CUSTOMER')">-->
<!--          <template #suffix>-->
<!--            <a-tooltip title="Extra information">-->
<!--              <SearchOutlined style="color: rgba(0, 0, 0, 0.45)" />-->
<!--            </a-tooltip>-->
<!--          </template>-->
<!--        </a-input>-->



<!--      </a-col>-->
<!--      <a-col :span="1">-->

<!--      </a-col>-->
<!--      <a-col :span="6">-->

<!--        <a-select-->
<!--            v-model:value="selectedPage"-->
<!--            @change="handlePageChange"-->
<!--            :allowClear="true"-->
<!--            :dropdownMatchSelectWidth="true"-->
<!--            style="width: 100%; margin-bottom: 16px"-->
<!--            :placeholder="$t('ALL')"-->
<!--        >-->
<!--          <a-select-option-->
<!--              v-for="item in getPages"-->
<!--              :key="item.id"-->
<!--              v-bind:value="item.page_id"-->
<!--          >{{ item.page_name }}-->
<!--          </a-select-option>-->
<!--        </a-select>-->

<!--      </a-col>-->

<!--      <a-col :span="1">-->

<!--      </a-col>-->
<!--      <a-col :span="6">-->
<!--        <a-input   style="width: 100%;margin-bottom: 16px"  :placeholder="$t('PRODUCT')">-->
<!--          <template #suffix>-->
<!--            <a-tooltip title="Extra information">-->
<!--              <SearchOutlined style="color: rgba(0, 0, 0, 0.45)" />-->
<!--            </a-tooltip>-->
<!--          </template>-->
<!--        </a-input>-->
<!--       -->
<!--        -->
<!--      </a-col>-->
<!--    </a-row>-->
            <a-select
                v-model:value="selectedType"
                @change="handleFilterChange"
                :allowClear="true"
                :dropdownMatchSelectWidth="true"
                style="width: 150px; margin-right: 16px"
                :placeholder="$t('EMPLOYEE')"
            >
              <a-select-option


                  v-bind:value="'staff'"
              >{{ $t('EMPLOYEE') }}
              </a-select-option>
              <a-select-option


                  v-bind:value="'day'"
              >{{ $t('time_visit') }}
              </a-select-option>
            </a-select>
    <a-range-picker
        :allowClear="false"
        v-model:value="rangeTime"

        format="DD/MM/YYYY"
        :placeholder="[$t('START_TIME_PLACEHOLDER'), $t('END_TIME_PLACEHOLDER')]"
        @change="onChangeDateTime"
        @ok="confirmDateTime"
    >
      <template #suffixIcon>
        <CalendarOutlined/>
      </template>
    </a-range-picker>
  </div>
  <div style="padding: 16px">


    <a-card class="card_view" style="border-radius: 10px; margin: 16px;">
      <keep-alive>
        <highcharts
            class="chart" :options="{
      chart: {
        type: 'column',
      },
      title: {
        text: $t(selectedType === 'day' ? 'Revenue_over_time' : 'Revenue_by_employee')
      },
      xAxis: {
        categories: chartDataReportCareAll.label
      },
      yAxis: {
        min: 0,
        title: {
          text: null
        },
      },
      legend: {
                enabled:false,
        align: 'right',
        verticalAlign: 'top',
        borderColor: '#CCC',
        borderWidth: 1,
        shadow: false
      },
      tooltip: {
        headerFormat: '<b>{point.x}</b><br/>',
        enable: false
      },
      plotOptions: {
        column: {
          colors: chartDataReportCareAll.color,
          stacking: 'normal',
          dataLabels: {
            enabled: true
          }
        }
      },
      series: chartDataReportCareAll.total ? [
          {
            data: chartDataReportCareAll.total&&chartDataReportCareAll.total?.map((total, index)=>({
        y: chartDataReportCareAll.total[index],
        color: chartDataReportCareAll.color[index],
      }))
          }
      ]:[]
    }"></highcharts>

      </keep-alive>
    </a-card>
  </div>
  <div style="padding: 16px">


   <a-table
        v-if="selectedType === 'day'"
        :columns="columns"
        :data-source="report.dataRevenue"
        :pagination="false"
        :scroll="{ x: 2000, y: 500 }"
        bordered
    >
      <template  #order_day="{record}">
        {{dateDisplayFormatFromString(record.order_day)}}
      </template>

      <template #total_amount="{record}">
        {{formatMoney(record.total_amount)}}đ
      </template>
      <template #ship_fee_collected="{record}">
        {{formatMoney(record.ship_fee_collected)}}đ
      </template>
      <template #total_amount_sale="{record}">
        {{formatMoney(record.total_amount_sale)}}đ
      </template>
      <template #total_goods_cost="{record}">
        {{formatMoney(record.total_goods_cost)}}đ
      </template>
      <template #profit="{record}">
        {{formatMoney(record.total_amount_sale -record.total_goods_cost)}}đ
      </template>
      <template #summary>
          <a-table-summary-row>
            <a-table-summary-cell :index="0">{{$t('TOTAL')}}</a-table-summary-cell>
            <a-table-summary-cell :index="1">{{report.totalRevenue.count}}</a-table-summary-cell>
            <a-table-summary-cell :index="2">{{report.totalRevenue.count_success}}</a-table-summary-cell>
            <a-table-summary-cell :index="3">{{report.totalRevenue.count_return}}</a-table-summary-cell>
            <a-table-summary-cell :index="4">{{formatMoney(report.totalRevenue.total_amount)}}đ</a-table-summary-cell>
            <a-table-summary-cell :index="5">{{report.totalRevenue.ship_fee_collected}}đ</a-table-summary-cell>
            <a-table-summary-cell :index="6">{{formatMoney(report.totalRevenue.total_amount_sale)}}đ</a-table-summary-cell>
            <a-table-summary-cell :index="7">{{formatMoney(report.totalRevenue.total_amount_sale)}}đ</a-table-summary-cell>
            <a-table-summary-cell :index="8">{{formatMoney(report.totalRevenue.total_goods_cost)}}đ</a-table-summary-cell>
            <a-table-summary-cell :index="9">{{formatMoney(report.totalRevenue.profit)}}đ</a-table-summary-cell>

          </a-table-summary-row>

      </template>
    </a-table>
    <a-table
        v-else
        :columns="columns2"
        :data-source="report.dataRevenue"
        :pagination="false"
        :scroll="{ x: 2000, y: 500 }"
        bordered
    >
      <template v-slot:created_by="{record}">
        {{getEmployee(record.created_by)}}
      </template>
<!--      <template #order_day="{record}">-->
<!--        {{dateDisplayFormatFromString(record.created_by)}}-->
<!--      </template>-->
      <template v-slot:total_amount="{record}">
        {{formatMoney(record.total_amount)}}đ
      </template>
      <template v-slot:ship_fee_collected="{record}">
        {{formatMoney(record.ship_fee_collected)}}đ
      </template>
      <template v-slot:total_amount_sale="{record}">
        {{formatMoney(record.total_amount_sale)}}đ
      </template>
      <template v-slot:total_goods_cost="{record}">
        {{formatMoney(record.total_goods_cost)}}đ
      </template>
      <template v-slot:profit="{record}">
        {{formatMoney(record.total_amount_sale -record.total_goods_cost)}}đ
      </template>

      <template #summary>
        <a-table-summary fixed>

            <a-table-summary-cell :index="0">{{$t('TOTAL')}}</a-table-summary-cell>
            <a-table-summary-cell :index="1">{{report.totalRevenue.count}}</a-table-summary-cell>
            <a-table-summary-cell :index="2">{{report.totalRevenue.count_success}}</a-table-summary-cell>
            <a-table-summary-cell :index="3">{{report.totalRevenue.count_return}}</a-table-summary-cell>
            <a-table-summary-cell :index="4">{{formatMoney(report.totalRevenue.total_amount)}}đ</a-table-summary-cell>
            <a-table-summary-cell :index="5">{{report.totalRevenue.ship_fee_collected}}đ</a-table-summary-cell>
            <a-table-summary-cell :index="6">{{formatMoney(report.totalRevenue.total_amount_sale)}}đ</a-table-summary-cell>
            <a-table-summary-cell :index="7">{{formatMoney(report.totalRevenue.total_amount_sale)}}đ</a-table-summary-cell>
            <a-table-summary-cell :index="8">{{formatMoney(report.totalRevenue.total_goods_cost)}}đ</a-table-summary-cell>
            <a-table-summary-cell :index="9">{{formatMoney(report.totalRevenue.profit)}}đ</a-table-summary-cell>


        </a-table-summary>
      </template>
    </a-table>
  </div>
</template>

<script>

import {useStore} from "vuex";
import {ShopinfoActionTypes} from "@/store/modules/Shopinfo/action-types";
import {computed, onMounted, ref} from "vue";
import moment, {Moment} from "moment";
import {useI18n} from "vue-i18n";
import APIs from "@/APIs/APIs";
import {MixUtils} from "@/utils/Utils";
import {CalendarOutlined,SearchOutlined} from "@ant-design/icons-vue";
import dayjs from "dayjs";
import {ReportPAGEActionTypes} from "../../../store/modules/ReportPage/action-types";
import useEmployeeStore from "../../../store/Employee";
import useReportPageStore from "../../../store/ReportPage";




export default {
  components: {
    CalendarOutlined,
    // SearchOutlined
  },
  setup() {
    const employeeStore = useEmployeeStore()
    const report = useReportPageStore()
    const i18n = useI18n()
    const store = useStore();
    const rangeTime = ref([dayjs().add(-1, 'months').startOf('day'), dayjs().endOf('day')])
    const selectedType = ref('day');
    const fetchCusomer = () => {
      useEmployeeStore.getState().GetEmployee({
        page: 1,
        page_size: 999,
      })

      useReportPageStore.getState().getReportRevenue({
          from_date: rangeTime.value[0].format('YYYY-MM-DDT00:00:00Z'),
        to_date: rangeTime.value[1].format('YYYY-MM-DDT23:59:59Z'),
          type:selectedType.value
        })
      // store.dispatch(ReportPAGEActionTypes.REVENUE,{
      //   from_date: rangeTime.value[0].format('YYYY-MM-DDT00:00:00Z'),
      //   to_date: rangeTime.value[1].format('YYYY-MM-DDT00:00:00Z'),
      //   type:selectedType.value
      // });
    };
    const revenue =computed(()=> store.getters.getReportRevenue)
    const total =computed(()=> store.getters.getTotalRevenue)

    const selectedPage = ref();

    const chartDataReportCareAll = ref({
      total: [],
      label: [],
      color: [],
    })
    const fetchChart = async () => {
      // chartDataReportCareAll.value = {
      //   total: [],
      //   label: [],
      //   color: [],
      // }
      // labelsCareEmployee.value = []
      const getReportRevenue = await APIs.init().reportRevenue({
        from_date: rangeTime.value[0].format('YYYY-MM-DDT00:00:00Z'),
        to_date: rangeTime.value[1].format('YYYY-MM-DDT23:59:59Z'),
        type:selectedType.value
      });

      if(getReportRevenue.data.status === 200 && selectedType.value === 'day'){
        console.log(getReportRevenue.data)
        chartDataReportCareAll.value = {
          total: getReportRevenue.data.data?.map(t => t.total_amount_sale),
          label: getReportRevenue.data.data?.map(t => MixUtils.methods.dateDisplayFormatFromString(t.order_day)),
          color: getReportRevenue.data.data?.map(t => t.color),
        }
      }
      if(getReportRevenue.data.status === 200 && selectedType.value !== 'day'){
        console.log(getReportRevenue.data)
        chartDataReportCareAll.value = {
          total: getReportRevenue.data.data?.map(t => t.total_amount_sale),
          label: getReportRevenue.data.data?.map(t => getEmployee(t.created_by)),
          color: getReportRevenue.data.data?.map(t => t.color),
        }
      }


    }
    const formatMoney = (val) => {
      return MixUtils.methods.formatMoney(val)
    }
    const columns = [
      {
        title: i18n.t('day'),
        dataIndex: 'order_day',
        key: 'order_day',
        slots: {customRender: "order_day"},
        width: 150,
        fixed: 'left',
      },
      {
        title: i18n.t('ORDER'),
        dataIndex: 'count',
        key: 'count',
        slots: {customRender: "count"},
        width: 100,
      },

      {
        title: i18n.t('ORDER_SUCCESS'),
        dataIndex: 'count_success',
        key: 'count_success',
        slots: {customRender: "count_success"},
        width: 100,
      },
      {
        title: i18n.t('RETURNED_ORDER'),
        dataIndex: 'count_return',
        key: 'count_return',
        slots: {customRender: "count_return"},
        width: 100,
      },
      {
        title: i18n.t('PRICE'),
        dataIndex: 'total_amount',
        key: 'total_amount',
        slots: {customRender: "total_amount"},
        width: 100,
      },
      // {
      //   title: i18n.t('chiết khấu'),
      //   dataIndex: 'order_from_new_customer_percent',
      //   key: 'order_from_new_customer_percent',
      //   slots: {customRender: "order_not_ads_percent"},
      //   width: 100,
      // },
      {
        title: i18n.t('SHIPPING_FEE_PAID_BY_CUSTOMER'),
        dataIndex: 'ship_fee_collected',
        key: 'ship_fee_collected',
        slots: {customRender: "ship_fee_collected"},
        width: 100,
      },

      // {
      //   title: i18n.t('Phí trả hàng VC'),
      //   dataIndex: 'order_from_new_customer_percent',
      //   key: 'order_from_new_customer_percent',
      //   slots: {customRender: "order_not_ads_percent"},
      //   width: 100,
      // },
      {
        title: i18n.t('sales'),
        dataIndex: 'total_amount_sale',
        key: 'total_amount_sale',
        slots: {customRender: "total_amount_sale"},
        width: 100,
      },
      {
        title: i18n.t('REVENUE'),
        dataIndex: 'total_amount_sale',
        key: 'total_amount_sale',
        slots: {customRender: "total_amount_sale"},
        width: 100,
      },
      {
        title: i18n.t('COST_OF_GOOD_SOLD'),
        dataIndex: 'total_goods_cost',
        key: 'total_goods_cost',
        slots: {customRender: "total_goods_cost"},
        width: 100,
      },
      {
        title: i18n.t('PROFIT'),
        key: 'profit',
        slots: {customRender: "profit"},
        width: 100,
      },

    ];
    const columns2 = [
      {
        title: i18n.t('EMPLOYEE'),
        dataIndex: 'created_by',
        key: 'created_by',
        slots: {customRender: "created_by"},
        width: 150,
        fixed: 'left',
      },
      {
        title: i18n.t('ORDER'),
        dataIndex: 'count',
        key: 'count',
        slots: {customRender: "count"},
        width: 100,
      },

      {
        title: i18n.t('ORDER_SUCCESS'),
        dataIndex: 'count_success',
        key: 'count_success',
        slots: {customRender: "count_success"},
        width: 100,
      },
      {
        title: i18n.t('RETURNED_ORDER'),
        dataIndex: 'count_return',
        key: 'count_return',
        slots: {customRender: "count_return"},
        width: 100,
      },
      {
        title: i18n.t('PRICE'),
        dataIndex: 'total_amount',
        key: 'total_amount',
        slots: {customRender: "total_amount"},
        width: 100,
      },
      // {
      //   title: i18n.t('chiết khấu'),
      //   dataIndex: 'order_from_new_customer_percent',
      //   key: 'order_from_new_customer_percent',
      //   slots: {customRender: "order_not_ads_percent"},
      //   width: 100,
      // },
      {
        title: i18n.t('SHIPPING_FEE_PAID_BY_CUSTOMER'),
        dataIndex: 'ship_fee_collected',
        key: 'ship_fee_collected',
        slots: {customRender: "ship_fee_collected"},
        width: 100,
      },

      // {
      //   title: i18n.t('Phí trả hàng VC'),
      //   dataIndex: 'order_from_new_customer_percent',
      //   key: 'order_from_new_customer_percent',
      //   slots: {customRender: "order_not_ads_percent"},
      //   width: 100,
      // },
      {
        title: i18n.t('sales'),
        dataIndex: 'total_amount_sale',
        key: 'total_amount_sale',
        slots: {customRender: "total_amount_sale"},
        width: 100,
      },
      {
        title: i18n.t('REVENUE'),
        dataIndex: 'total_amount_sale',
        key: 'total_amount_sale',
        slots: {customRender: "total_amount_sale"},
        width: 100,
      },
      {
        title: i18n.t('COST_OF_GOOD_SOLD'),
        dataIndex: 'total_goods_cost',
        key: 'total_goods_cost',
        slots: {customRender: "total_goods_cost"},
        width: 100,
      },
      {
        title: i18n.t('PROFIT'),
        key: 'profit',
        slots: {customRender: "profit"},
        width: 100,
      },
    ];






    const splineChart = ref()


    const handleReport = () => {
      // fetchReportComment();
      // fetchReportMessage();
      // fetchReportNewConversation();
    };

    onMounted(() => {

      fetchCusomer()
      fetchChart()

    });



    const calculatorPercent = (a, total) => {
      return Math.round(a / total * 10000) / 100
    }


    const handlePageChange = (val) => {
      selectedPage.value = val;
      console.log(val)
      fetchCusomer()
      fetchChart()
    };

    const getEmployee = (data) => {

      if(data){
        console.log(useEmployeeStore.getState().employees.find( item => item.cms_user_id === data),'sadas')
        let user = useEmployeeStore.getState().employees.find( item => item.cms_user_id === Number(data))
        if(user){
          return user.name
        }else {
          return data
        }

      }else {
        return ''
      }

    }
    const onChangeDateTime = (value, dateString) => {
      console.log('Selected Time: ', value);
      console.log('Formatted Selected Time: ', dateString);
      if (value[0].format('HH-mm') === value[1].format('HH-mm')) {
        rangeTime.value = [value[0].startOf('day'), value[1].endOf('day')]

      } else {
        rangeTime.value = value
      }
      fetchCusomer()
      fetchChart()
    };
  const  handleFilterChange = () => {

    fetchCusomer()
    fetchChart()
  }
    const confirmDateTime = (value) => {
      console.log('onOk: ', value);
    };


    return {
      handlePageChange,
      handleReport,
      selectedPage,
      splineChart,
      formatMoney,
      calculatorPercent,
      onChangeDateTime,
      confirmDateTime,
      rangeTime,
      columns,
      columns2,
      revenue,
      report,
      chartDataReportCareAll,
      employeeStore,
      total,
      selectedType,
      handleFilterChange,getEmployee

    };
  },
};
</script>

<style>
.card_view {
  border-radius: 10px;
  margin: 4px;
  overflow: hidden;
}

.count-elements {
  -webkit-tap-highlight-color: transparent;
  -webkit-text-size-adjust: 100%;
  font-family: Roboto, Helvetica, Arial, sans-serif;
  font-variant: tabular-nums;
  line-height: 1.5;
  font-feature-settings: 'tnum';
  font-size: 14px;
  color: #000;
  box-sizing: border-box;
  height: 76px;
  padding: 10px;
  border-top: 1px solid #e0e0e0;
  border-bottom: 1px solid #e0e0e0;
  border-radius: 10px 0px 0px 10px;
  cursor: pointer;
  box-shadow: 0px 2px 10px rgba(51, 51, 51, 0.1);
  border-left: 10px solid rgb(43, 216, 196);
  background-color: rgba(196, 255, 248, 0.23);
  width: 100%;
}

.title {
  font-family: "Roboto" !important;
  font-style: normal !important;
  font-weight: 700 !important;
  font-size: 12px !important;
  line-height: 22px !important;
  color: #000000 !important;
  margin-left: 0px !important;
  margin-top: 0px !important;
  text-align: left !important;
  width: 100% !important;
}

.count {
  text-align: right;
  font-weight: 100;
  font-family: Montserrat-Light;
  font-size: 25px;
  line-height: 30px;
  color: #263C8F;
  float: right;
}

.highcharts-credits {
  display: none;
}
</style>
