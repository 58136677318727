import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createVNode as _createVNode, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-5707d14c"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "editable-row-operations" }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = ["onClick"]
const _hoisted_4 = ["onClick"]
const _hoisted_5 = { key: 1 }
const _hoisted_6 = ["onClick"]
const _hoisted_7 = ["onClick"]
const _hoisted_8 = { class: "button-add-stock" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_select_option = _resolveComponent("a-select-option")!
  const _component_a_select = _resolveComponent("a-select")!
  const _component_a_input_number = _resolveComponent("a-input-number")!
  const _component_a_textarea = _resolveComponent("a-textarea")!
  const _component_a_form_item = _resolveComponent("a-form-item")!
  const _component_a_col = _resolveComponent("a-col")!
  const _component_a_row = _resolveComponent("a-row")!
  const _component_a_form = _resolveComponent("a-form")!
  const _component_a_table = _resolveComponent("a-table")!
  const _component_plus_outlined = _resolveComponent("plus-outlined")!
  const _component_a_button = _resolveComponent("a-button")!
  const _component_a_modal = _resolveComponent("a-modal")!

  return (_openBlock(), _createBlock(_component_a_modal, {
    title: "Import product to stocks",
    visible: _ctx.visible,
    "onUpdate:visible": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.visible) = $event)),
    width: _ctx.screenWidth>=780?'1200px':'95%',
    onOk: _ctx.saveProductStock
  }, {
    default: _withCtx(() => [
      _createVNode(_component_a_table, {
        dataSource: _ctx.stockData,
        columns: _ctx.columns,
        pagination: false
      }, {
        stock: _withCtx(({record, index}) => [
          _createVNode(_component_a_select, {
            showSearch: "",
            value: _ctx.editableStockData[index].stock_id,
            "onUpdate:value": ($event: any) => ((_ctx.editableStockData[index].stock_id) = $event),
            disabled: !record.editable,
            placeholder: "Stock",
            style: {"width":"220px"}
          }, {
            default: _withCtx(() => [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.getStocks, (item) => {
                return (_openBlock(), _createBlock(_component_a_select_option, {
                  key: item.id,
                  value: item.id
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(item.shop_name), 1)
                  ]),
                  _: 2
                }, 1032, ["value"]))
              }), 128))
            ]),
            _: 2
          }, 1032, ["value", "onUpdate:value", "disabled"])
        ]),
        provider: _withCtx(({ record, index }) => [
          _createVNode(_component_a_select, {
            showSearch: "",
            value: _ctx.editableStockData[index].provider_id,
            "onUpdate:value": ($event: any) => ((_ctx.editableStockData[index].provider_id) = $event),
            disabled: !record.editable,
            placeholder: "Provider",
            style: {"width":"180px"}
          }, {
            default: _withCtx(() => [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.getProductProviders, (item) => {
                return (_openBlock(), _createBlock(_component_a_select_option, {
                  key: item.id,
                  value: item.id
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(item.name), 1)
                  ]),
                  _: 2
                }, 1032, ["value"]))
              }), 128))
            ]),
            _: 2
          }, 1032, ["value", "onUpdate:value", "disabled"])
        ]),
        quantity: _withCtx(({ record, index }) => [
          _createVNode(_component_a_input_number, {
            disabled: !record.editable,
            value: _ctx.editableStockData[index].quantity,
            "onUpdate:value": ($event: any) => ((_ctx.editableStockData[index].quantity) = $event),
            style: {"width":"100%"},
            formatter: value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ','),
            parser: value => value.replace(/\$\s?|(,*)/g, '')
          }, null, 8, ["disabled", "value", "onUpdate:value", "formatter", "parser"])
        ]),
        ordered: _withCtx(({  index }) => [
          _createVNode(_component_a_input_number, {
            disabled: true,
            value: _ctx.editableStockData[index].ordered,
            "onUpdate:value": ($event: any) => ((_ctx.editableStockData[index].ordered) = $event),
            style: {"width":"100%"},
            formatter: value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ','),
            parser: value => value.replace(/\$\s?|(,*)/g, '')
          }, null, 8, ["value", "onUpdate:value", "formatter", "parser"])
        ]),
        available: _withCtx(({ index }) => [
          _createVNode(_component_a_input_number, {
            disabled: true,
            value: _ctx.editableStockData[index].available,
            "onUpdate:value": ($event: any) => ((_ctx.editableStockData[index].available) = $event),
            style: {"width":"100%"},
            formatter: value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ','),
            parser: value => value.replace(/\$\s?|(,*)/g, '')
          }, null, 8, ["value", "onUpdate:value", "formatter", "parser"])
        ]),
        import_price: _withCtx(({ record, index }) => [
          _createVNode(_component_a_input_number, {
            disabled: !record.editable,
            value: _ctx.editableStockData[index].import_price,
            "onUpdate:value": ($event: any) => ((_ctx.editableStockData[index].import_price) = $event),
            style: {"width":"100%"},
            formatter: value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ','),
            parser: value => value.replace(/\$\s?|(,*)/g, '')
          }, null, 8, ["disabled", "value", "onUpdate:value", "formatter", "parser"])
        ]),
        sale_price: _withCtx(({ record, index }) => [
          _createVNode(_component_a_input_number, {
            disabled: !record.editable,
            value: _ctx.editableStockData[index].sale_price,
            "onUpdate:value": ($event: any) => ((_ctx.editableStockData[index].sale_price) = $event),
            style: {"width":"100%"},
            formatter: value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ','),
            parser: value => value.replace(/\$\s?|(,*)/g, '')
          }, null, 8, ["disabled", "value", "onUpdate:value", "formatter", "parser"])
        ]),
        note: _withCtx(() => [
          _createVNode(_component_a_textarea, { placeholder: "note" })
        ]),
        operation: _withCtx(({ record, index }) => [
          _createElementVNode("div", _hoisted_1, [
            (record.editable)
              ? (_openBlock(), _createElementBlock("span", _hoisted_2, [
                  _createElementVNode("a", {
                    onClick: ($event: any) => (_ctx.saveStock(index)),
                    style: {"padding-right":"5px"}
                  }, "Save", 8, _hoisted_3),
                  _createElementVNode("a", {
                    onClick: ($event: any) => (_ctx.cancelStock(index)),
                    style: {"padding-left":"5px"}
                  }, "Cancel", 8, _hoisted_4)
                ]))
              : (_openBlock(), _createElementBlock("span", _hoisted_5, [
                  _createElementVNode("a", {
                    onClick: ($event: any) => (_ctx.editStock(index)),
                    style: {"padding-right":"5px"}
                  }, "Edit", 8, _hoisted_6),
                  _createElementVNode("a", {
                    onClick: ($event: any) => (_ctx.deleteStock(index)),
                    style: {"padding-left":"5px"}
                  }, "Delete", 8, _hoisted_7)
                ]))
          ])
        ]),
        expandedRowRender: _withCtx(({record, index}) => [
          _createVNode(_component_a_form, {
            style: {"margin":"0"},
            name: "custom-validation",
            "label-col": {span:12},
            "wrapper-col": {span:14}
          }, {
            default: _withCtx(() => [
              _createVNode(_component_a_row, null, {
                default: _withCtx(() => [
                  _createVNode(_component_a_col, { span: 8 }, {
                    default: _withCtx(() => [
                      _createVNode(_component_a_form_item, {
                        "has-feedback": "",
                        label: "Quantity no sale",
                        name: "quantity_no_sale"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_a_input_number, {
                            style: {"width":"100%"},
                            placeholder: "Quantity no sale",
                            disabled: !record.editable,
                            value: _ctx.editableStockData[index].quantity_no_sale,
                            "onUpdate:value": ($event: any) => ((_ctx.editableStockData[index].quantity_no_sale) = $event)
                          }, null, 8, ["disabled", "value", "onUpdate:value"])
                        ]),
                        _: 2
                      }, 1024)
                    ]),
                    _: 2
                  }, 1024)
                ]),
                _: 2
              }, 1024)
            ]),
            _: 2
          }, 1024)
        ]),
        _: 1
      }, 8, ["dataSource", "columns"]),
      _createElementVNode("div", _hoisted_8, [
        _createVNode(_component_a_button, {
          type: "dashed",
          onClick: _ctx.addStock
        }, {
          default: _withCtx(() => [
            _createVNode(_component_plus_outlined),
            _createTextVNode(" Add stock ")
          ]),
          _: 1
        }, 8, ["onClick"])
      ])
    ]),
    _: 1
  }, 8, ["visible", "width", "onOk"]))
}