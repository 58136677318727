export enum CustomersMutationTypes {
    SET_Customers = 'SET_CUSTOMERS',
    SET_Columns = 'SET_COLUMNS',
    SET_CXView = 'SET_CXView',
    SET_CXView_GROUP = 'SET_CXView_GROUP',
    SET_CXView_GROUP_CURRENT = 'SET_CXView_GROUP_CURRENT',
    SET_CUSTOMER_HISTORY_COMMENT = 'SET_CUSTOMER_HISTORY_COMMENT',
    SET_CUSTOMER = 'SET_CUSTOMER',
    SET_CAREER = 'SET_CAREER',
    SET_CUSTOMER_CONFIG = 'SET_CUSTOMER_CONFIG',
    REFORMAT_COLUMNS = 'REFORMAT_COLUMNS',
    SET_CUSTOMER_CHANGE = 'SET_CUSTOMER_CHANGE'
}

