import create from "zustand-vue";
import { persist } from "zustand/middleware";
import APIs from "@/APIs/APIs";
import { ReportPageComment } from "@/models/ReportPage/ReportPage";
import dayjs from "dayjs";
import {ReportPageMutationType} from "@/store/modules/ReportPage/mutation-types";

interface ReportPageState {
  dataComment: ReportPageComment;
  dataMessage: ReportPageComment;
  dataNewConversation: ReportPageComment;
  dataCustomer: any;
    dataRevenue:any;
    totalRevenue:any;
  getReportPageComment: (params: any) => void;
  getReportPageMessage: (params: any) => void;
  getReportPageNewConversation: (params: any) => void;
  getReportCusTomer: (params: any) => void;
  getReportRevenue: (params:any) => void;
  getReportOrganizations:(params:any) => void;
    getReportOrganizationsRange:(params:any) => void;
    getReportOrganizationsSegments:(params:any) => void;
    getReportOrganizationsSegmntsCount:(params:any) => void;
    getReportOrder:(params:any) => void;
    getReportEmployee:(params:any) => void;
    organization:any,
    range:any,
    segments:any,
    segmntsCount:any,
    customerOrder:any,
    reportEmployee:any,
    totalEmployee:any

}

const initialState = {
  dataComment: {} as ReportPageComment,
  dataMessage: {} as ReportPageComment,
  dataNewConversation: {} as ReportPageComment,
  dataCustomer: [],
    dataRevenue:[],
    totalRevenue:{},
    organization:{},
    range:[],
    segments:[],
    segmntsCount:[],
    customerOrder:[],
    reportEmployee:[],
    totalEmployee:{}
};

const useReportPageStore = create<ReportPageState>(
  // @ts-ignore
  persist(
    (set) => ({
      ...initialState,
      getReportPageComment: async (params) => {
        const response = await APIs.init().getReportComment(params);
        if (response.data && response.data.status === 200) {
          // @ts-ignore
          set(() => ({
            dataComment: response.data?.data,
          }));
        }
      },
      getReportPageMessage: async (params) => {
        const response = await APIs.init().getReportMessage(params);
        if (response.data && response.data.status === 200) {
          // @ts-ignore
          set(() => ({
            // @ts-ignore
            dataMessage: response.data?.data,
          }));
        }
      },
      getReportPageNewConversation: async (params) => {
        const response = await APIs.init().getReportNewConversation(params);
        if (response.data && response.data.status === 200) {
          // @ts-ignore
          set(() => ({
            // @ts-ignore
            dataNewConversation: response.data?.data,
          }));
        }
      },
      getReportCusTomer: async (params) => {
          const customerData:any =[]

          const getReportCustomerStat = await APIs.init().getReportCustomerStatDay(params);
          // @ts-ignore
          getReportCustomerStat.data?.aggregations?.message?.conversation?.customers.buckets.map(b=> {
              customerData.push({
                  time:dayjs.unix(b.key/1000).format('DD/MM/YYYY'),
                  messages:0,
                  comments:0,
                  allMessage:0,
                  customerMessages:0,
                  totalMessage:0,
                  totalComment:0,
                  total:0
              })

          })

          // @ts-ignore
          getReportCustomerStat.data?.aggregations?.message?.conversation_message_new?.customers.buckets.map(b=> {
              for (let i= 0 ;i<customerData.length;i++){
                  if(customerData[i].time===dayjs.unix(b.key/1000).format('DD/MM/YYYY')){
                      customerData[i].conversationMessageNew = b.doc_count
                      customerData[i].customerNew = b.customers.value
                      customerData[i].order_from_new_customer = b.order_count?.value

                      try{
                          // @ts-ignore
                          const orderFromCustomerNew =  getReportCustomerStat.data?.aggregations?.message.order.order.buckets.find(item=>item.key== b.key)
                          // console.log('orderFromCustomerNew', orderFromCustomerNew)
                          customerData[i].order_from_new_customer = orderFromCustomerNew?orderFromCustomerNew.doc_count:0
                      }catch (e) {
                          customerData[i].order_from_new_customer = 0
                          console.log(e)
                      }

                  }
              }
          })
          // @ts-ignore
          getReportCustomerStat.data?.aggregations?.message?.message?.message?.buckets.map(b=> {
              for (let i= 0 ;i<customerData.length;i++){
                  if(customerData[i].time===dayjs.unix(b.key/1000).format('DD/MM/YYYY')){
                      customerData[i].messages = b.doc_count-b.comment.doc_count
                      customerData[i].comments = b.comment.doc_count
                      customerData[i].allMessage = b.doc_count
                      customerData[i].customerMessages = b.customer_message?.customers_sum?.value
                      customerData[i].customers=b.customer_message?.customers_sum?.value

                      customerData[i].totalMessage = b.doc_count-b.comment.doc_count
                      customerData[i].totalComment = b.comment.doc_count
                      customerData[i].total = b.doc_count

                      customerData[i].order_from_old_customer= b.order?.doc_count - customerData[i].order_from_new_customer


                  }
              }
              // customerData.push({
              //   messages:b.doc_count-b.comment.doc_count,
              //   comments:b.doc_count-b.comment.doc_count,
              //   allMessage:b.doc_count,
              //   customerMessages:b.customer_message?.customers_sum?.value,
              //   totalMessage:b.doc_count-b.comment.doc_count,
              //   totalComment:b.comment.doc_count,
              //   total:b.doc_count
              //
              // })
          })
          // @ts-ignore
          getReportCustomerStat.data?.aggregations?.message?.conversation?.customers.buckets.map(b=> {

              for (let i = 0; i < customerData.length; i++) {
                  if(customerData[i].time===dayjs.unix(b.key/1000).format('DD/MM/YYYY')){

                      customerData[i].newPhones = b.phone.value
                      customerData[i].customerOld = customerData[i].customers - customerData[i].customerNew

                      customerData[i].order_from_old_customer_percent = customerData[i].order_from_old_customer  ? Math.round(( customerData[i].order_from_old_customer/customerData[i].customers)*100) :0
                      customerData[i].order_from_new_customer_percent =customerData[i].order_from_new_customer  ?Math.round( ( customerData[i].order_from_new_customer/customerData[i].customers)*100) :0
                  }
              }
          })
          // console.log(customerData)
          // @ts-ignore
          // console.log(getReportOrderConverSion.data.aggregations.order_time.buckets)
          // @ts-ignore
          // getReportOrderConverSion.data?.aggregations?.order_time?.buckets.map(b=> {
          //
          //   for (let i = 0; i < customerData.length; i++) {
          //     if(customerData[i].time===dayjs.unix(b.key/1000).format('DD/MM/YYYY')){
          //
          //       customerData[i].order_from_ads= b.order_from_ads.doc_count
          //       customerData[i].order_from_ads_percent = b.order_from_ads.doc_count && customerData[i].totalMessage ?  Math.floor(100/(customerData[i].totalMessage/b.order_not_ads.doc_count)) : 0
          //       customerData[i].order_not_ads= b.order_not_ads.doc_count
          //       customerData[i].order_not_ads_percent = b.order_not_ads.doc_count && customerData[i].totalMessage ? Math.floor(100/(customerData[i].totalMessage/b.order_not_ads.doc_count)) : 0
          //     }
          //   }
          // })

          // @ts-ignore
          // if (getReportCustomerStat.status === 200) {
          //     // @ts-ignore
          //     commit(ReportPageMutationType.GET_REPORT_CUSTOMER, customerData);
          // }
          // @ts-ignore
        if (getReportCustomerStat.status === 200) {
          // @ts-ignore
          set(() => ({
            // @ts-ignore
            dataCustomer: customerData,
          }));
        }
      },
        getReportRevenue:async (params)=>{
            const response = await APIs.init().reportRevenue(params);
            if (response.status === 200) {
                const total:any ={
                    count:0,
                    count_return:0,
                    count_success:0,
                    ship_fee_collected:0,
                    total_amount:0,
                    total_amount_sale:0,
                    total_goods_cost:0,
                    total_shipfee:0,
                    profit:0
                }
                // @ts-ignore
                response.data?.data.map(item => {

                    total.count += item.count
                    total.count_return += item.count_return
                    total.count_success += item.count_success
                    total.ship_fee_collected += item.ship_fee_collected
                    total.total_amount += item.total_amount
                    total.total_amount_sale += item.total_amount_sale
                    total.total_goods_cost += item.total_goods_cost
                    total.total_shipfee += item.total_shipfee
                    total.profit += item.total_amount_sale-item.total_goods_cost
                })
                // @ts-ignore
                // commit(ReportPageMutationType.GET_REVENUE, response.data.data);
                // commit(ReportPageMutationType.GET_REVENUE_TOTAL, total);
                set(({
                    // @ts-ignore
                    dataRevenue: response.data.data,
                    totalRevenue:total
                }))
            }
        },
        getReportOrganizations: async (params) => {
            // @ts-ignore
            const response1 = await APIs.init().reportCustomerOrganizations();

            let organizations = {}

            if (response1.data && response1.data.status === 200) {
                // @ts-ignore
               organizations = response1.data.data
            }

            set(() => ({
                // @ts-ignore
                organization: organizations,
            }));
        },
        getReportOrganizationsRange: async (params) => {
            // @ts-ignore
            const response = await APIs.init().reportCustomerRange({indicator:params.indicator,min:params.min,max:params.max});
            let range: any[]=[]

            if (response.data && response.data.status === 200) {
                // @ts-ignore
                range = response.data.data
            }

            set(() => ({

                // @ts-ignore
                range:range,

            }));
        },
        getReportOrganizationsSegments: async (params) => {
            // @ts-ignore
            let string = '?'
               params.phone_number.map((item:any,index:number) => {
                   string +=`phone_number=${item}` + (index +1 < params.phone_number.length ? '&' : '')
               })
            const response2 = await APIs.init().reportCustomerSegments(string);

            let segments: any[]=[]


            if (response2.data && response2.data.status === 200) {
                // @ts-ignore
                segments =response2.data.data
            }
            set(() => ({

                segments:segments,

            }));
        },
        getReportOrganizationsSegmntsCount: async (params) => {
            // @ts-ignore

            const response3 = await APIs.init().reportCustomerSegmentsCount();

            let count: any[] = []


            if (response3.data && response3.data.status === 200) {
                // @ts-ignore
                count= response3.data.data
            }
            // @ts-ignore
            set(() => ({
                // @ts-ignore
                segmentsCont:count,
            }));
        },
        getReportOrder:async (params) => {
            const response = await APIs.init().reportCustomerOrder(params);
            if (response.status === 200) {

                set(() => ({
                    // @ts-ignore
                    customerOrder:response.data.data,
                }));
            }
        },
        getReportEmployee:async (params) => {
            const response = await APIs.init().reportEmployee(params);
            if (response.status === 200) {
                const total: any = {
                    count: 0,
                    total_discount: 0,
                    count_return: 0,
                    count_success: 0,
                    ship_fee_collected: 0,
                    total_amount: 0,
                    total_amount_sale: 0,
                    total_goods_cost: 0,
                    total_shipfee: 0,
                    profit: 0,
                    total_amount_success: 0,
                    total_amount_return: 0,
                    count_confirm: 0,
                    total_amount_confirm: 0,
                    count_cancel: 0,
                    total_amount_cancel: 0
                }
                // @ts-ignore
                response.data?.data.map(item => {

                    total.count += item.count
                    total.total_discount += item.total_discount
                    total.count_return += item.count_return
                    total.count_success += item.count_success
                    total.ship_fee_collected += item.ship_fee_collected
                    total.total_amount += item.total_amount
                    total.total_amount_sale += item.total_amount_sale
                    total.total_goods_cost += item.total_goods_cost
                    total.total_shipfee += item.total_shipfee
                    total.total_amount_success += item.total_amount_success
                    total.total_amount_return += item.total_amount_return
                    total.count_confirm += item.count_confirm
                    total.total_amount_confirm += item.total_amount_confirm
                    total.count_cancel += item.count_cancel
                    total.total_amount_cancel += item.total_amount_cancel
                    // total.profit += item.total_amount_sale-item.total_goods_cost
                })
                // @ts-ignore
                set(() => ({
                    // @ts-ignore
                    reportEmployee: response.data?.data,
                    totalEmployee:total
                }));
            }
        }
    }),
    {
      name: "report-page",
    }
  )
);

export default useReportPageStore;
