import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode, withModifiers as _withModifiers, createBlock as _createBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-ab030f2e"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  class: "main-flow",
  id: "main-flow"
}
const _hoisted_2 = {
  class: "ant-upload-picture-card-wrapper",
  style: {"display":"flex"}
}
const _hoisted_3 = {
  role: "button",
  tabindex: "0",
  class: "ant-upload"
}
const _hoisted_4 = { class: "ant-upload-text" }
const _hoisted_5 = { class: "ant-upload-list-picture-card-container" }
const _hoisted_6 = { class: "ant-upload-list-item ant-upload-list-item-done ant-upload-list-item-list-type-picture-card" }
const _hoisted_7 = { class: "ant-upload-list-item-info" }
const _hoisted_8 = ["href"]
const _hoisted_9 = ["src"]
const _hoisted_10 = { class: "ant-upload-list-item-actions" }
const _hoisted_11 = ["href"]
const _hoisted_12 = {
  class: "row-button-action",
  style: {"text-align":"right"}
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_input = _resolveComponent("a-input")!
  const _component_a_form_item = _resolveComponent("a-form-item")!
  const _component_plus_outlined = _resolveComponent("plus-outlined")!
  const _component_eye_outlined = _resolveComponent("eye-outlined")!
  const _component_delete_outlined = _resolveComponent("delete-outlined")!
  const _component_a_textarea = _resolveComponent("a-textarea")!
  const _component_a_tag = _resolveComponent("a-tag")!
  const _component_DownOutlined = _resolveComponent("DownOutlined")!
  const _component_a_menu_item = _resolveComponent("a-menu-item")!
  const _component_a_menu = _resolveComponent("a-menu")!
  const _component_a_dropdown = _resolveComponent("a-dropdown")!
  const _component_a_card = _resolveComponent("a-card")!
  const _component_flow_next_actions = _resolveComponent("flow-next-actions")!
  const _component_a_button = _resolveComponent("a-button")!
  const _component_a_popconfirm = _resolveComponent("a-popconfirm")!
  const _component_a_form = _resolveComponent("a-form")!
  const _component_image_selection_modal = _resolveComponent("image-selection-modal")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_a_form, {
        ref: "formRef",
        rules: _ctx.rules,
        model: _ctx.formState,
        layout: "vertical"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_a_form_item, {
            ref: "name",
            label: _ctx.$t('FLOW_MESSAGE_NAME'),
            name: "name"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_a_input, {
                placeholder: _ctx.$t('FLOW_MESSAGE_NAME'),
                onChange: _ctx.onTextChange,
                value: _ctx.formState.name,
                "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.formState.name) = $event))
              }, null, 8, ["placeholder", "onChange", "value"])
            ]),
            _: 1
          }, 8, ["label"]),
          _createVNode(_component_a_card, {
            title: _ctx.$t('REPLY_COMMENT_DETAIL'),
            class: "card-message"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_a_form_item, null, {
                default: _withCtx(() => [
                  _createElementVNode("span", _hoisted_2, [
                    _createElementVNode("div", {
                      class: "ant-upload ant-upload-select ant-upload-select-picture-card",
                      onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.showImageSelectionModal && _ctx.showImageSelectionModal(...args)))
                    }, [
                      _createElementVNode("span", _hoisted_3, [
                        _createElementVNode("div", null, [
                          _createVNode(_component_plus_outlined),
                          _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.$t('UPLOAD')), 1)
                        ])
                      ])
                    ]),
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.fileList, (item) => {
                      return (_openBlock(), _createElementBlock("div", {
                        class: "ant-upload-list ant-upload-list-picture-card",
                        key: item
                      }, [
                        _createElementVNode("div", _hoisted_5, [
                          _createElementVNode("span", null, [
                            _createElementVNode("div", _hoisted_6, [
                              _createElementVNode("div", _hoisted_7, [
                                _createElementVNode("span", null, [
                                  _createElementVNode("a", {
                                    class: "ant-upload-list-item-thumbnail",
                                    href: item.url,
                                    target: "_blank",
                                    rel: "noopener noreferrer"
                                  }, [
                                    _createElementVNode("img", {
                                      src: item.url,
                                      alt: "image.png",
                                      class: "ant-upload-list-item-image"
                                    }, null, 8, _hoisted_9)
                                  ], 8, _hoisted_8)
                                ])
                              ]),
                              _createElementVNode("span", _hoisted_10, [
                                _createElementVNode("a", {
                                  href: item.url,
                                  target: "_blank",
                                  rel: "noopener noreferrer",
                                  title: "Preview file"
                                }, [
                                  _createVNode(_component_eye_outlined)
                                ], 8, _hoisted_11),
                                _createVNode(_component_delete_outlined, {
                                  onClick: ($event: any) => (_ctx.removeImage(item))
                                }, null, 8, ["onClick"])
                              ])
                            ])
                          ])
                        ])
                      ]))
                    }), 128))
                  ])
                ]),
                _: 1
              }),
              _createVNode(_component_a_form_item, { name: "Reply comment" }, {
                default: _withCtx(() => [
                  _createVNode(_component_a_textarea, {
                    onClick: _ctx.forcusMessagetext,
                    ref: "inputMessageContent",
                    placeholder: _ctx.$t('COMMENT_REPLY_CONTENT'),
                    rows: 3,
                    value: _ctx.formState.message_content,
                    "onUpdate:value": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.formState.message_content) = $event)),
                    onChange: _ctx.onTextChange
                  }, null, 8, ["onClick", "placeholder", "value", "onChange"]),
                  _createVNode(_component_a_tag, {
                    style: {"cursor":"pointer"},
                    onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.messageFullName()))
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode("##full_name## ")
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_a_dropdown, null, {
                    overlay: _withCtx(() => [
                      _createVNode(_component_a_menu, null, {
                        default: _withCtx(() => [
                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.getColumnsConfig, (it) => {
                            return (_openBlock(), _createBlock(_component_a_menu_item, {
                              onClick: ($event: any) => (_ctx.messageColumn(it.identified_name,)),
                              key: it.id
                            }, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(it.name), 1)
                              ]),
                              _: 2
                            }, 1032, ["onClick"]))
                          }), 128)),
                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.getColumn, (i) => {
                            return (_openBlock(), _createBlock(_component_a_menu_item, {
                              onClick: ($event: any) => (_ctx.messageColumn(i.id)),
                              key: i.id
                            }, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(_ctx.$t(i.text)), 1)
                              ]),
                              _: 2
                            }, 1032, ["onClick"]))
                          }), 128))
                        ]),
                        _: 1
                      })
                    ]),
                    default: _withCtx(() => [
                      _createVNode(_component_a_tag, {
                        class: "ant-dropdown-link",
                        onClick: _cache[4] || (_cache[4] = _withModifiers(() => {}, ["prevent"]))
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.$t('READ_MORE')) + " ", 1),
                          _createVNode(_component_DownOutlined)
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_a_dropdown, null, {
                    overlay: _withCtx(() => [
                      _createVNode(_component_a_menu, null, {
                        default: _withCtx(() => [
                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.getOrderColumn, (i) => {
                            return (_openBlock(), _createBlock(_component_a_menu_item, {
                              onClick: ($event: any) => (_ctx.messageColumn(i.id)),
                              key: i.id
                            }, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(_ctx.$t(i.text)), 1)
                              ]),
                              _: 2
                            }, 1032, ["onClick"]))
                          }), 128))
                        ]),
                        _: 1
                      })
                    ]),
                    default: _withCtx(() => [
                      _createVNode(_component_a_tag, {
                        class: "ant-dropdown-link",
                        onClick: _cache[5] || (_cache[5] = _withModifiers(() => {}, ["prevent"]))
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.$t('ORDER')) + " ", 1),
                          _createVNode(_component_DownOutlined)
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            _: 1
          }, 8, ["title"]),
          (!_ctx.isShow)
            ? (_openBlock(), _createBlock(_component_flow_next_actions, {
                key: 0,
                buttonName: _ctx.$t('FLOW_SUCCESS_DELIVERY'),
                updateAction: _ctx.updateSuccessAction,
                flowContextData: _ctx.flowContextData,
                flowContext: _ctx.flowContext,
                actionContext: _ctx.flowContext.action_send_success_context
              }, null, 8, ["buttonName", "updateAction", "flowContextData", "flowContext", "actionContext"]))
            : _createCommentVNode("", true),
          (!_ctx.isShow)
            ? (_openBlock(), _createBlock(_component_flow_next_actions, {
                key: 1,
                buttonName: _ctx.$t('FLOW_FAILED_DELIVERY'),
                updateAction: _ctx.updateFailedAction,
                flowContextData: _ctx.flowContextData,
                flowContext: _ctx.flowContext,
                actionContext: _ctx.flowContext.action_send_failed_context
              }, null, 8, ["buttonName", "updateAction", "flowContextData", "flowContext", "actionContext"]))
            : _createCommentVNode("", true),
          (!_ctx.isShow)
            ? (_openBlock(), _createBlock(_component_flow_next_actions, {
                key: 2,
                buttonName: _ctx.$t('FLOW_DEFAULT_DELIVERY'),
                updateAction: _ctx.updateDefaultAction,
                flowContextData: _ctx.flowContextData,
                flowContext: _ctx.flowContext,
                actionContext: _ctx.flowContext.action_default_context
              }, null, 8, ["buttonName", "updateAction", "flowContextData", "flowContext", "actionContext"]))
            : _createCommentVNode("", true),
          _createElementVNode("div", _hoisted_12, [
            _createVNode(_component_a_popconfirm, {
              onConfirm: _ctx.deleteCurrentFlowContext,
              title: _ctx.$t('FLOW_CONFIRM_DELETE_CONTEXT'),
              "ok-text": _ctx.$t('YES'),
              "cancel-text": _ctx.$t('NO')
            }, {
              default: _withCtx(() => [
                _createVNode(_component_a_button, {
                  danger: "",
                  type: "primary"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_delete_outlined),
                    _createTextVNode(" " + _toDisplayString(_ctx.$t("FLOW_DELETE")), 1)
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }, 8, ["onConfirm", "title", "ok-text", "cancel-text"])
          ])
        ]),
        _: 1
      }, 8, ["rules", "model"])
    ]),
    _createVNode(_component_image_selection_modal, {
      single: true,
      ref: "imageSelectionRef",
      onSelect: _ctx.onImageSelect
    }, null, 8, ["onSelect"])
  ], 64))
}