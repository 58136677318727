<template>

  <div  class="receipt">
    <div class="orderNo">
      {{order.shop_name}}
    </div>
    <div class="address">
      {{order.shop_address}}
    </div>
    <div class="phone">
      {{order.shop_tel}}
    </div>
    <div class="headerOrder">
      ĐƠN HÀNG
    </div>
    <div class="barcode">
      <!--      <img class="img-barcode" :src="`https://barcode.tec-it.com/barcode.ashx?data=${receiptData.order_number}&code=Code128`"/>-->
      <vue-barcode class="img-barcode" :value="order.order_number"
      ></vue-barcode>
    </div>
    <div class="created_date">
      Ngày đặt hàng: {{dateTimeDisplayFormatFromString(order.created_at)}}
    </div>
    <!--    Thông tin khách hàng-->
    <hr class="dashed">
    <div class="customer-info">
      Khách hàng: <strong>{{order.shipping_name}}</strong>
    </div>
    <div class="customer-info">
      Điện thoại: <strong>{{order.shipping_phone}}</strong>
    </div>
    <div class="customer-info">
      Email: <strong>{{order.email}}</strong>
    </div>
    <div class="customer-info">
      Địa chỉ: <strong>{{order.shipping_address}}</strong>
    </div>
    <hr class="dashed">
    <div class="customer-info">
      Phương thức thanh toán: <strong>{{order.payment_method===1?'Thanh toán khi giao hàng (COD)':''}}</strong>
    </div>
    <div class="customer-info">
      Phương thức vận chuyển: <strong>{{order.shipping_method}}</strong>
    </div>
    <!-- Items Purchased -->
    <hr class="dashed">
    <div class="row">
      <div class="product">
        <div class="text-normal"><strong>Sản phẩm</strong></div>
      </div>
      <div class="price"><strong>Thành tiền</strong></div>
    </div>
    <template v-for="product in (order.products?order.products:[])" :key="product.id+''">
      <div class="row" style="margin-top: 5px" >
        <a-image
            v-if="product.avatar_url"
           style="width: 40px;height: 40px;border-radius: 10px;margin-right: 5px"
            :src="product.avatar_url"
        />
        <div class="product">


          <div class="text-normal">{{product.quantity}} x {{product.product_name}}</div>
          <div class="text-normal">{{formatMoney(product.unit_price)}}đ</div>
        </div>
        <div class="price">{{formatMoney(product.unit_price*product.quantity)}}đ</div>
      </div>
    </template>
    <hr class="dashed">
    <div class="row">
      <div class="label-total">
        <div class="text-normal">Ghi chú: <strong>{{order.note}}</strong></div>
        <div class="text-normal">Ghi chú Vận chuyển: <strong>{{order.note_bill}}</strong></div>

      </div>
    </div>
    <hr class="dashed">
    <div class="row">
      <div class="label-total">
        <div class="text-normal">Tổng tiền hàng:</div>
      </div>
      <div class="price">{{formatMoney(order.total_amount)}}đ</div>
    </div>
    <div class="row">
      <div class="label-total">
        <div class="text-normal">Phí vận chuyển:</div>
      </div>
      <div class="price">{{formatMoney(order.ship_fee)}}đ</div>
    </div>
    <div v-if="order.discount>0" class="row">
      <div class="label-total">
        <div class="text-normal">Giảm giá:</div>
      </div>
      <div class="price">{{formatMoney(order.discount)}}đ</div>
    </div>
    <div class="row">
      <div class="label-total">
        <div class="text-normal"><strong>Tổng tiền:</strong></div>
      </div>
      <div class="price"><strong>{{formatMoney(order.total_amount+order.ship_fee-order.discount)}}đ</strong></div>
    </div>
    <div class="row">
      <div class="label-total">
        <div class="text-normal"><strong>Số tiền đã trả:</strong></div>
      </div>
      <div class="price"><strong>{{formatMoney(order.prepaid)}}đ</strong></div>
    </div>
    <div class="row">
      <div class="label-total">
        <div class="text-normal"><strong>Số tiền phải trả:</strong></div>
      </div>
      <div class="price"><strong>{{formatMoney(order.total_amount+order.ship_fee-order.discount-order.prepaid)}}đ</strong></div>
    </div>
    <hr class="dashed">
    <div class="footer">
      Nếu bạn có thắc mắc, vui lòng liên hệ với chúng tôi qua sđt <strong>{{order.shop_tel}}</strong>
    </div>
  </div>
<!--  <h3 class="title-updated-status">-->
<!--    {{ $t('UPDATED_STATUS') }}-->
<!--  </h3>-->
<!--  <a-table :dataSource="order.order_histories" :columns="statusColumns">-->
<!--    <template #change_time="{record}">-->
<!--      {{  dateTimeDisplayFormatFromShortString(record.change_time) }}-->
<!--    </template>-->
<!--    <template #change_info="{record}">-->
<!--      {{ $t(record.change_info.toUpperCase()) }}-->
<!--    </template>-->
<!--  </a-table>-->
</template>
<script lang="ts">
import {useRoute} from "vue-router";
import {computed, onMounted, ref} from "vue";
import {useI18n} from "vue-i18n";
import {useStore} from "vuex";
import {Orders} from "@/models/Orders/Orders";
import {Employee} from "@/models/Users";
import APIs from "@/APIs/APIs";
import {MixUtils} from "@/utils/Utils";
import {message, notification} from "ant-design-vue";
import {Constant} from "@/utils/Constants";
import moment from "moment";
import {
  EnvironmentOutlined,
  InfoCircleOutlined,
  PrinterFilled,
  RocketOutlined,
  ShopOutlined, StopOutlined
} from "@ant-design/icons-vue";


export default {
  name: "PreviewOrder",
  components: {
    // PrinterFilled,
    // ShopOutlined,
    // EnvironmentOutlined,
    // InfoCircleOutlined,

  },
  setup(){
    const route = useRoute()
    const i18n = useI18n()
    const store = useStore()
    const order = ref({})

    const getListEmployee = computed((): Employee[] => store.getters.getListEmployee)

    const findEmployee = (staff_id: number) => {
      let employee = {}
      const employeeFound = getListEmployee.value.find(staff => staff.id + '' === staff_id + '')
      return employeeFound ? employeeFound : employee
    }

    const fetchOrder = async (order_number: any,shop_id:any,token:any) => {
      const response = await APIs.init().getPreviewOrder({order_number: order_number,token:token,shop_id:shop_id})
      if (response.status === 200 && response.data?.status === 200) {
        if (response.data.data) {
          order.value = response.data.data
          // @ts-ignore
           order.value.total_amount = response.data?.data?.products.map((product:any)=>product.unit_price*product.quantity).reduce((accumulator:any, currentValue:any) => (accumulator + currentValue),0)
        }
      }
    }

    const formatMoney = (money: string) => {
      return MixUtils.methods.formatMoney(money)
    }




onMounted(()=>{
  // @ts-ignore
  fetchOrder(route.query.order_link.split('_')[0], route.query.order_link.split('_')[1], route.query.order_link.split('_')[2])
})

    return {

      fetchOrder,
      order,
      moment,
      formatMoney,
      findEmployee,
      location,
    }
  }
}
</script>
<style scoped lang="css">

body {
  font-family: 'Roboto', sans-serif;
  width: 80mm;
  margin: 0;
  padding: 0;
  font-size: 8pt;
}

.receipt {

  padding: 3mm;
  width: 100%;
  font-size: 9pt;
}
.orderNo {
  width: 100%;
  text-align: center;
  padding-bottom: 1mm;
  font-size: 14pt;
  font-weight: bold;
}

.orderNo:empty {
  display: none;
}

.itemRow > div {
  align-items: baseline;
}

.totals .row {
  display: flex;
  text-align: right;
}

.totals .section {
  padding-top: 2mm;
}

.totalRow > div, .total > div {
  text-align: right;
  align-items: baseline;
  font-size: 8pt;
}

.totals .col1 {
  text-align: right;
  flex-grow: 1;
}

.totals .col2 {
  width: 22mm;
}

.totals .col2:empty {
  display: none;
}

.totals .col3 {
  width: 15mm;
}

.arrow_box h3, ul {
  margin: 5px;
}

.about li {
  text-align: left;
}
.address{
  font-weight: normal;
  text-align: center;
}
.phone{
  font-weight: normal;
  text-align: center;
}
.headerOrder{
  font-size: 10pt;
  font-weight: bold;
  text-align: center;
}
.created_date{
  font-weight: normal;
  text-align: center;
}
.customer-info{
  font-weight: normal;
}
.footer{
  font-weight: normal;
  font-style: italic;
}
.barcode{
  display: flex;
  justify-content: center;
}
.img-barcode{
  height: 16mm;
}
.dashed{
  border-top: 1px dashed;
}
.row{
  display: flex;
  flex-direction: row;
  align-items: center;
}
.product {
  flex: 3;
  text-align: left;
  font-weight: normal;
}
.price {
  flex: 1;
  font-weight: normal;
  text-align: right;
}
.text-normal {
  font-weight: normal;
}
.label-total {
  flex: 3;
  text-align: left;
  font-weight: normal;
}
.no-order {
  padding: 16px 0 0 16px;
  text-align: center;
}

</style>

