<template>
  <div class="right-contain">
    <notification-dropdown/>
    <wallet-dropdown/>
    <avatar-dropdown/>
    <lang-dropdown/>
  </div>
</template>

<script lang="ts">
import AvatarDropdown from './GlobalHeader/AvatarDropdown.vue'
import LangDropdown from './SelectLang/LangDropdown.vue'
import {Options, Vue} from "vue-class-component";
import WalletDropdown from "@/components/Header/wallet/WalletDropdown.vue";
import NotificationDropdown from "@/components/Header/notification/NotificationDropdown.vue";


@Options({
  name:'right-content',
  components: {
    WalletDropdown,
    LangDropdown,
    AvatarDropdown,
    NotificationDropdown
  },
  props: {

  },
  data() {
    return {
      showMenu: true,
      currentUser: {}
    }
  },
  computed: {
    wrpCls() {
      return {
        'ant-pro-global-header-index-right': true,
      }
    }
  },
  mounted() {
    setTimeout(() => {
      this.currentUser = {
        name: 'Serati Ma'
      }
    }, 1500)
  }
})
export default class RightContent extends Vue {
}
</script>
<style lang="scss">
.right-contain{
  display: flex;
}
</style>
