import create from "zustand-vue";
import { persist } from "zustand/middleware";
import APIs from "@/APIs/APIs";
import { AutoInbox } from "@/models/AutoInbox/AutoInbox";
import { Metadata } from "@/APIs/response/S_HttpResponse";

interface AutoinboxState {
  autoInboxes: AutoInbox[] | undefined;
  metadata: Metadata | undefined;
  loading: boolean;
  getAutoInboxData: (params: any) => void;
  getAutoInboxHistoryData: (params: any) => void;
}

const initialState = {
  autoInboxes: [],
  metadata: {},
  loading: false,
};

const useAutoinboxStore = create<AutoinboxState>(
  // @ts-ignore
  persist(
    (set) => ({
      ...initialState,
      getAutoInboxData: async (params) => {
        const response = await APIs.init().getAutoInboxes(params);
        if (response.data && response.data.status === 200) {
          set(() => ({
            autoInboxes: response.data?.data,
            metadata: response.data?.meta_data,
            loading: true,
          }));
        }
      },
      getAutoInboxHistoryData: async (params) => {
        const response = await APIs.init().getAutoInboxHistory(params);
        if (response.data && response.data.status === 200) {
          set(() => ({
            autoInboxes: response.data?.data,
            metadata: response.data?.meta_data,
            loading: true,
          }));
        }
      },
    }),
    {
      name: "autoinbox",
    }
  )
);

export default useAutoinboxStore;
