import {EventData,State} from "@/store/modules/Event/states";
import { EventMutationTypes} from "./mutation-types";



export type Mutations<S = State> = {
    [EventMutationTypes.GET_EVENT](state : S,payload: EventData|undefined):void,



}

export const mutations : Mutations ={
    [EventMutationTypes.GET_EVENT](state : State,payload: EventData|undefined): void{
        state.eventData = payload
    },

}
