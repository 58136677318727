<template>
  <div>
  <a-row class="header"  >
    <img
        src="https://biglead.live/images/favicon.png"
        style="width: 28px; height: auto"/>
    <a-col>
      <div class="description">{{$t('TIMER')}}</div>
      <span class="content">{{data.name}}</span>
    </a-col>
  </a-row>
  <div class="container">
    <div style="padding-bottom: 16px">
      <div>{{data.timer_type==='timeout'?$t('TIMEOUT'): $t('CUSTOMER_SCHEDULE_AT')}}: </div>
      <div v-if="data.timer_type==='timeout'" style="font-weight: bold; font-size: 18px">{{moment.duration(data.timeout, 'seconds').locale('vi').humanize()}}</div>
      <div v-else style="font-weight: bold; font-size: 18px">{{moment(data.scheduled_at).format('DD/MM/YYYY HH:mm')}}</div>
<!--      <a-form-item name="timer_type" v-bind="validateInfos.timer_type" :label="$t('TIMER_TYPE')">-->
<!--        <a-radio-group v-model:value="formState.timer_type" @change="handleTimerType" name="timer_type">-->
<!--          <a-radio value="timeout">{{ $t('TIMEOUT') }}</a-radio>-->
<!--          <a-radio value="schedule_at">{{ $t('CUSTOMER_SCHEDULE_AT') }}</a-radio>-->
<!--        </a-radio-group>-->
<!--      </a-form-item>-->
<!--      <a-form-item name="timeout" v-if="formState.timer_type === 'timeout'" :label="$t('TIMEOUT')">-->
<!--        <div style="display: flex">-->
<!--          <a-input-number v-model:value="formState.timeout" @change="onTextChange" style="flex: 1"/>-->
<!--          <div style="width: 10px;"></div>-->
<!--          <a-select v-model:value="formState.timer_format_type" @change="onTextChange" style="flex: 0.5;">-->
<!--            <a-option value="DAY">-->
<!--              {{ $t('DAY') }}-->
<!--            </a-option>-->
<!--            <a-option value="HOUR">-->
<!--              {{ $t('HOUR') }}-->
<!--            </a-option>-->
<!--            <a-option value="MINUTE">-->
<!--              {{ $t('MINUTE') }}-->
<!--            </a-option>-->
<!--            <a-option value="SECOND">-->
<!--              {{ $t('SECOND') }}-->
<!--            </a-option>-->
<!--          </a-select>-->
<!--        </div>-->
<!--      </a-form-item>-->
<!--      <a-form-item name="scheduled_at" :label="$t('CUSTOMER_SCHEDULE_AT')" v-else v-bind="config">-->
<!--        <a-date-picker show-time style="width: 100%"  @change="onTextChange" v-model:value="formState.scheduled_at" format="DD-MM-YYYY HH:mm"-->
<!--        />-->

<!--      </a-form-item>-->
    </div>
    <div  style="height: 2px; background-color: #f2f4f6"/>
    <Handle :id="data.id" type="target"  class="handle" :position="Position.Left" :style="sourceHandleStyleA" />
    <template v-for="(handle, index) in listHandle" :key="handle">
        <HandleCustom :data="handle" :index="index"  />
    </template>
  </div>
  </div>

</template>

<script>
import {defineComponent, defineProps, defineEmits, onMounted, ref} from "vue";
import { computed } from 'vue'
import {Position, VueFlow, useVueFlow, Handle, MarkerType} from '@vue-flow/core'
import ViralLuckyWheelPreview from "@/components/Flow/ViralLuckyWheelPreview.vue";
import HandleCustom from "./HandleCustom.vue";
import {MixUtils} from "@/utils/Utils";
import {useI18n} from "vue-i18n";
import moment from "moment";

const props = defineProps({
  data: {
    type: Object,
    required: true,
  },
})


export default defineComponent({
  name: "TimerNode",
  components:{
    Handle,
    // ViralLuckyWheelPreview,
    HandleCustom,
  },
  props: [ 'data'],
  setup(props, {emit}){

    onMounted(()=>{
      console.log('timer', props)
    }, [])
    const i18n = useI18n()

    const listHandle = ref([
      // {
      //   id: props.data.id+'action_send_success_context',
      //   name: i18n.t('ACTION_SEND_SUCCESS')
      // },
      // {
      //   id: props.data.id+'action_send_failed_context',
      //   name: i18n.t('ACTION_SEND_FALSE')
      // },
      {
        id: props.data.id+'action_default_context',
        name: i18n.t('ACTION_DEFAULT')
      }
    ])

    const sourceHandleStyleA = computed(() => ({ top: '10px',
      bottom: 'auto', }))

    const sourceHandleStyleC = computed(() => ({  top: 'auto',
      bottom: '10px', }))


    return{
      sourceHandleStyleA,
      sourceHandleStyleC,
      Position,
      moment,
      listHandle,
    }
  }
})
</script>

<style scoped>
.container{
  padding: 16px 16px 16px 16px;
  font-size: 14px;
}

.add_trigger{
  border-width: 0.5px;
  width: 100%;
  border-color: #acacac80;
  border-style: dotted;
}
.next_step{
  position: absolute;
  bottom: 8px;
  right: 8px;
  text-align: right;
}
.header{
  padding: 16px 16px 16px 16px;
  border-radius: 8px 8px 0 0;
  display: flex;
  flex-flow: row;
  align-items: center;
  gap: 8px;
  background-color: #ffeca7
}
.element{
  display: flex;
  align-items: center;
  justify-content: start;
  padding: 8px 0 8px 0;
  border-radius: 10px;
  margin-bottom: 12px;
  background-color: #e1faec;
}
.content{
  font-size: 16px;
  font-weight: normal;
}
.content_mess{
  margin: 4px 0 4px;
  padding: 8px;
  border-radius: 10px;
  background-color: #B1B1B77E;
  font-size: 14px;
  font-weight: normal;
}
.description{
  color: #758697;
  font-size: 10px;
}
.add_trigger{
  height: 50px;
}
.icon{
}
/*.handle {*/
/*  border-width: 0;*/
/*  background-color: #8492a6;*/
/*  transition: transform .2s;*/
/*  width: 15px;*/
/*  height: 15px;*/
/*  margin: 0 auto;*/
/*}*/

/*.handle:hover {*/
/*  background-color: #0084ff;*/
/*}*/
</style>
