export enum ReportPageMutationType {
    GET_REPORT_PAGE_COMMENT = 'GET_REPORT_PAGE_COMMENT',
    GET_REPORT_PAGE_MESSAGE = 'GET_REPORT_PAGE_MESSAGE',
    GET_REPORT_PAGE_NEW_CONVERSATION = 'GET_REPORT_PAGE_NEW_CONVERSATION',
    GET_REPORT_CUSTOMER = 'GET_REPORT_CUSTOMER',
    GET_CUSTOMER_ORDER = 'GET_CUSTOMER_ORDER',
    GET_REVENUE  = 'GET_REVENUE',
GET_REVENUE_TOTAL  = 'GET_REVENUE_TOTAL',
    GET_EMPLOYEE_REPORT  = 'GET_EMPLOYEE_REPORT',
    GET_EMPLOYEE_TOTAL  = 'GET_EMPLOYEE_TOTAL',
}
