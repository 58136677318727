<template>
  <div>
    <a-card :bordered="false">
      <template #title>
        <span>{{ $t("FLOW_FIRST_STEP") }} <drag-outlined/><span class="root-flow-card-title-suggest">
          {{ $t("FLOW_FIRST_STEP_2") }}</span></span>
      </template>
      <Container
          orientation="vertical"
          group-name="col-items"
          :get-child-payload="getPayload1()"
          :drop-placeholder="{ className:
            `drop-placeholder`,
          animationDuration: '200',
          showOnTop: true }"
          drag-class="
            transition duration-100 ease-in z-50
            transform rotate-6 scale-110"
          drop-class="transition duration-100
            ease-in z-50 transform
            -rotate-2 scale-90"
          @drop="(e) => onCardDrop1(e)">

        <!-- Items -->
        <Draggable v-for="item in list1" :key="item.id">
          <a-button
              block size="large"
              :class="positionEnable===-1?'button-flow-context right-button-content flow-active':'button-flow-context right-button-content'"
              @click="changeCurrentFlowContext(item.id)"
              @mouseover="item.isDeleteIconShow=true" @mouseleave="hideFlowItem(item)">

            <facebook-outlined v-if="item.data.flow_type === 1||item.data.flow_type===3" style="font-size: 18px"/>
            <instagram-outlined v-else-if="item.data.flow_type === 11" style="font-size: 18px"/>
            <img v-else-if="item.data.flow_type === 2||item.data.flow_type === 4" src="@/assets/icons/zalo-flat.svg" class="anticon"
                 style="width: 20px;height: 20px;"/>
            <mail-outlined v-else-if="item.data.flow_type === 5" style="font-size: 18px"/>
            <img v-else-if="item.data.flow_type === 6" src="@/assets/icons/flow-viral-icon.png" class="anticon"
                           style="width: 20px;height: 20px;"/>
            <phone-outlined v-else-if="item.data.flow_type === 7" style="font-size: 18px"/>
            <clock-circle-outlined v-else-if="item.data.flow_type === 8" style="font-size: 18px"/>
            <span class="center-button-content">
                {{ item.data.name }}
              </span>
            <a-popconfirm
                :title="$t('FLOW_CONFIRM_DELETE_CONTEXT')"
                :ok-text="$t('YES')"
                :cancel-text="$t('NO')"
            >
              <delete-outlined v-if="item.isDeleteIconShow"/>
            </a-popconfirm>
          </a-button>
        </Draggable>
      </Container>
    </a-card>
    <a-popover :title="$t('ADD_NEW')" placement="right">
      <template #content>
        <p>
          <a-button type="dashed" class="selected-flow-action" @click="newFacebookFlow(1)">
            <facebook-outlined style="font-size: 16px"/>
            <span>
            {{ $t('FLOW_NEW_FACEBOOK') }}
            </span>
          </a-button>
        </p>
        <p>
          <a-button type="dashed" class="selected-flow-action" @click="newFacebookFlow(11)">
            <instagram-outlined style="font-size: 16px" />
            <span>
            {{ $t('FLOW_NEW_INSTAGRAM') }}
            </span>
          </a-button>
        </p>
        <p>
          <a-button type="dashed" class="selected-flow-action" @click="newFacebookFlow(4)">
            <img src="@/assets/icons/zalo-flat.svg" class="anticon"
                 style="width: 15px;height: 15px;"/>
            <span>
                Zalo OA Message
            </span>
          </a-button>
        </p>
        <p>
          <a-button type="dashed" class="selected-flow-action" @click="newFacebookFlow(2)">
            <img src="@/assets/icons/zalo-flat.svg" class="anticon"
                 style="width: 15px;height: 15px;"/>
            <span>
                {{$t('FLOW_ZALO_ZNS')}}
            </span>
          </a-button>
        </p>
        <p>
          <a-button type="dashed" class="selected-flow-action" @click="newFacebookFlow(3)">
            <facebook-outlined style="font-size: 16px"/>
            <span>
                {{ $t('AUTO_REPLY_COMMENT_AND_SEND_PRIVATE_MESSAGE') }}
            </span>
          </a-button>
        </p>
        <p>
          <a-button type="dashed" class="selected-flow-action" @click="newFacebookFlow(5)">
            <mail-outlined style="font-size: 16px"/>
            <span>
                {{ $t('EMAIL_CONTEXT') }}
            </span>
          </a-button>
        </p>
        <p>
          <a-button type="dashed" class="selected-flow-action" @click="newFacebookFlow(6)">
            <img src="@/assets/icons/flow-viral-icon.png" class="anticon"
                 style="width: 20px;height: 20px;"/>
            <span>
                {{ $t('VIRAL_LUCKY_WHEEL') }}
            </span>
          </a-button>
        </p>
        <p>
          <a-button type="dashed" class="selected-flow-action" @click="newFacebookFlow(9)">
            <img src="@/assets/icons/flow-viral-icon.png" class="anticon"
                 style="width: 20px;height: 20px;"/>
            <span>
                {{ $t('VIRAL_SHARE_GIFT') }}
            </span>
          </a-button>
        </p>
        <p>
          <a-button type="dashed" class="selected-flow-action" @click="newFacebookFlow(8)">
            <clock-circle-outlined
                 style="font-size: 17px;"/>
            <span>
                {{ $t('TIMER') }}
            </span>
          </a-button>
        </p>
        <p>
          <a-button type="dashed" class="selected-flow-action" @click="newFacebookFlow(7)">
            <phone-outlined style="font-size: 18px"/>
            <span>
                {{ $t('VOICE_TEXT') }}
            </span>
          </a-button>
        </p>
        <p>
          <a-button type="dashed" class="selected-flow-action" @click="newFacebookFlow(10)">
            <phone-outlined style="font-size: 18px"/>
            <span>
                {{ $t('TRIGGERS') }}
            </span>
          </a-button>
        </p>
      </template>
      <a-button size="large" type="dashed" block class="flow-control" style="margin-top: 30px;">
        <plus-outlined/>
        {{ $t("ADD_NEW") }}
      </a-button>
    </a-popover>
    <a-card :bordered="false" class="flow-control">
      <template #title>
        <span>{{ $t('FLOW_STEPS_USED') }}<drag-outlined/><span class="root-flow-card-title-suggest">
          {{ $t('FLOW_FIRST_STEP_2') }}</span></span>
      </template>
      <Container
          orientation="vertical"
          group-name="col-items"
          :get-child-payload="getPayload2()"
          :drop-placeholder="{ className:
            `drop-placeholder`,
          animationDuration: '200',
          showOnTop: true }"
          drag-class="
            transition duration-100 ease-in z-50
            transform rotate-6 scale-110"
          drop-class="transition duration-100
            ease-in z-50 transform
            -rotate-2 scale-90"
          style="overflow-y: scroll;max-height: 400px "
          @drop="(e) => onCardDrop2(e)">

        <!-- Items -->
        <Draggable v-for="(item,index) in list2" :key="item.id">
          <a-button size="large" block
                    :class="positionEnable===index?'button-flow-context right-button-content flow-active':'button-flow-context right-button-content'"
                    @click="changeCurrentFlowContext(item.id)"
                    @mouseover="item.isDeleteIconShow=true" @mouseleave="hideFlowItem(item)">
            <facebook-outlined v-if="item.data.flow_type === 1||item.data.flow_type===3" style="font-size: 18px"/>
            <instagram-outlined v-else-if="item.data.flow_type === 11" style="font-size: 18px"/>
            <img v-else-if="item.data.flow_type === 2||item.data.flow_type === 4" src="@/assets/icons/zalo-flat.svg" class="anticon"
                 style="width: 18px;height: 18px;"/>
            <mail-outlined v-else-if="item.data.flow_type === 5" style="font-size: 18px"/>
            <img v-else-if="item.data.flow_type === 6 || item.data.flow_type === 9" src="@/assets/icons/flow-viral-icon.png" class="anticon"
                 style="width: 18px;height: 18px;"/>
            <phone-outlined v-else-if="item.data.flow_type === 7" style="font-size: 18px"/>
            <clock-circle-outlined v-else-if="item.data.flow_type === 8" style="font-size: 18px"/>
            <span class="center-button-content">
                {{ item.data.name }}
              </span>
            <a-popconfirm
                :title="$t('FLOW_CONFIRM_DELETE_CONTEXT')"
                :ok-text="$t('YES')"
                :cancel-text="$t('NO')"
                @confirm="confirmDelete(item.id)"
            >
              <delete-outlined v-if="item.isDeleteIconShow"/>
            </a-popconfirm>
          </a-button>
        </Draggable>
      </Container>
    </a-card>
  </div>
</template>


<script>
import {defineComponent} from 'vue';
import {Container, Draggable} from "vue3-smooth-dnd";
import {FacebookOutlined, DragOutlined, PlusOutlined, DeleteOutlined,MailOutlined,PhoneOutlined,ClockCircleOutlined,InstagramOutlined} from '@ant-design/icons-vue'
import {mapActions} from "vuex";
import {TagsActionTypes} from "@/store/modules/tag/action-types";
import {S_EventBus} from "@/utils/EventBus";
import APIs from "@/APIs/APIs";
import {getUUID} from "ant-design-vue/es/vc-select/utils/commonUtil";
import {MixUtils} from "@/utils/Utils";
import {FlowContext} from "@/models/Flows/FlowContext";

export default defineComponent({
  name: "flow-management-panel",
  components: {Container, Draggable, FacebookOutlined, DragOutlined, PlusOutlined, DeleteOutlined,MailOutlined,InstagramOutlined,PhoneOutlined,ClockCircleOutlined},
  props: ['flowContextData', 'swapRootFlowContext', 'flowContext', 'deleteFlowContext'],
  data() {
    return {
      list1: [
        {
          type: 'draggable',
          id: "1",
          data: 'test1',
          isDeleteIconShow: false
        }
      ],
      list2: [
        {
          id: "4",
          type: 'draggable',
          data: 'test4',
          isDeleteIconShow: false
        },
        {
          id: "5",
          type: 'draggable',
          data: 'test5',
          isDeleteIconShow: false
        },
        {
          id: "6",
          type: 'draggable',
          data: 'test6',
          isDeleteIconShow: false
        }
      ],
      positionEnable: -1
    }
  },
  watch: {
    flowContextData: function () {
      this.initFlowManagement();
    },
    flowContext: function () {

      this.currentFlowContextChange()
    }
  },
  created() {
    this.initFlowManagement()
  },
  methods: {
    ...mapActions({
      fetchTags: TagsActionTypes.FETCH_TAGS
    }),
    onCardDrop1(dropResult) {
      if (dropResult.removedIndex !== dropResult.addedIndex) {
        if (dropResult.addedIndex !== null) {
          this.list2 = [...this.list1, ...this.list2]
          this.list1 = [dropResult.payload]
          this.swapRootFlowContext(dropResult.payload.id)
        }
      }
    },
    onCardDrop2(dropResult) {
      console.log(dropResult,'111')
      if (dropResult.removedIndex !== dropResult.addedIndex) {
        if (dropResult.removedIndex !== null) {
          this.list2 = this.list2.filter(x => x.id !== dropResult.payload.id)
        }
        if (dropResult.addedIndex !== null) {
          const itemList1 = this.list1.find(x => x.id === dropResult.payload.id)
          if (!itemList1) {
            if (this.list2.length > 0) {
              this.list2.splice(dropResult.addedIndex, 0, dropResult.payload)
            } else {
              this.list2 = [dropResult.payload]
            }
          }
          this.swapRootFlowContext(dropResult.payload.id,dropResult.addedIndex+1)
        }

      }

    },
    currentFlowContextChange() {

      if (this.list2) {
        const index = this.list2.findIndex(item => item.id === this.flowContext.id)
        this.positionEnable = index
      }
    },
    confirmDelete(flowId) {
      this.deleteFlowContext(flowId)
    },
    changeCurrentFlowContext(flowId) {
      S_EventBus.instance().execChangeCurrentFlow(flowId)
    },
    getPayload1() {
      return index => {
        return this.list1[index]
      }
    },
    getPayload2() {
      return index => {
        return this.list2[index]
      }
    },
    hideFlowItem(item) {
      setTimeout(() => item.isDeleteIconShow = false, 1000)
    },
    initFlowManagement() {
      this.list1 = [{
        id: this.flowContextData.context.flow_contexts[0].id,
        type: 'draggable',
        isDeleteIconShow: false,
        data: this.flowContextData.context.flow_contexts[0]
      }]
      this.list2 = []
      if (this.flowContextData && this.flowContextData.context.flow_contexts) {
        this.flowContextData.context.flow_contexts.map((item, index) => {
          if (index !== 0)
            this.list2.push({
              id: item.id,
              type: 'draggable',
              isDeleteIconShow: false,
              data: item
            })
        });
      }
    },
    async newFacebookFlow(type) {
      let name = "1"
      if (this.flowContextData && this.flowContextData.context.flow_contexts && this.flowContextData.context.flow_contexts.length) {
        name = this.flowContextData.context.flow_contexts.length + 1
      }
      var resName = "";
      var resMess = "";
      if (type === 1) {
        resName = "#Facebook# " + this.$t('MESS_NAME_CONTENT') + name;
        resMess = "#Facebook " + this.$t('FLOW_MESSAGE_CONTENT') + name;
      } else if (type === 2||type===4) {
        resName = "#Zalo# " + this.$t('MESS_NAME_CONTENT') + name;
        resMess = "#Zalo " + this.$t('FLOW_MESSAGE_CONTENT') + name;
      }else if (type === 3) {
        resName = "#Facebook# " + this.$t('MESS_NAME_CONTENT') + name;
        resMess = "#Facebook " + this.$t('FLOW_MESSAGE_CONTENT') + name;
      }
      else if (type === 5) {
        resName = "#Email# " + this.$t('MESS_NAME_CONTENT') + name;
        resMess = "#Email " + this.$t('FLOW_MESSAGE_CONTENT') + name;
      }
      else if (type === 6) {
        resName = "#Viral# " + this.$t('MESS_NAME_CONTENT') + name;
        resMess = "#Viral " + this.$t('FLOW_MESSAGE_CONTENT') + name;
      }
      else if (type === 8) {
        resName = "#Timer# " + this.$t('MESS_NAME_CONTENT') + name;
        resMess = "#Timer " + this.$t('FLOW_MESSAGE_CONTENT') + name;
      } else if (type === 9) {
        resName = "#Viral# " + this.$t('MESS_NAME_CONTENT') + name;
        resMess = "#Viral " + this.$t('FLOW_MESSAGE_CONTENT') + name;
      }else if (type === 7) {
        resName = "#Voice Text# " + this.$t('MESS_NAME_CONTENT') + name;
        resMess = "#Voice Text# " + this.$t('FLOW_MESSAGE_CONTENT') + name;
      }else if (type === 10) {
        resName = "#Trigger Condition# " + this.$t('MESS_NAME_CONTENT') + name;
        resMess = "#Trigger Condition# " + this.$t('FLOW_MESSAGE_CONTENT') + name;
      }else if (type === 11) {
        resName = "#Instagram Condition# " + this.$t('MESS_NAME_CONTENT') + name;
        resMess = "#Instagram Condition# " + this.$t('FLOW_MESSAGE_CONTENT') + name;
      }

      let flow = {
        "id": MixUtils.methods.getUUID(),
        "name": resName,
        "shop_id": this.flowContextData.shop_id,
        "page_id": "",
        "flow_type": type,
        "root_flow_id": "",
        "message": resMess,
        "attachment_images": [],
        "type": "",
        "messenger_tag": "",
        "template_id": "",
        "template_name": "",
        "template_status": "",
        "preview_url": "",
        "created_at": new Date().toISOString(),
        "updated_at": new Date().toISOString(),
        "postback_contexts": [],
        "action_open_mail_context": {
          "flow_crm_actions": [],
          "flow_action_stack": {}
        },
        "action_click_to_link_context": {
          "flow_crm_actions": [],
          "flow_action_stack": {}
        },
        "action_send_success_context": {
          "flow_crm_actions": [],
          "flow_action_stack": {}
        },
        "action_send_failed_context": {
          "flow_crm_actions": [],
          "flow_action_stack": {}
        },
        "action_default_context": {
          "flow_crm_actions": [],
          "flow_action_stack": {}
        }
      }
      if (type===6){
        flow.flow_viral=[{
          id: MixUtils.methods.getUUID(),
          num_play:3,
          invite_get_num_play:1,
          type:'form'
        }]
      }
      if (type===10){
        flow.trigger_condition={
          condition:'OR',
          trigger_comparisons:[]
        }
      }


      S_EventBus.instance().dispatchCreateFlow(flow)
      // this.changeCurrentFlowContext(flow.id)
    }
  }
})
</script>

<style lang="scss" scoped>
@import "../../assets/styles/customize-dnd.css";

.button-flow-context {
  text-align: left;
  margin-top: 10px;

}

.right-button-content {
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;

  .center-button-content {
    display: flex;
    flex: 1;
    padding-bottom: 5px;
    margin-left: 10px;
  }
}

.flow-control {
  margin-top: 10px;
}

.drop-zone {
  background-color: #eee;
  margin-bottom: 10px;
  padding: 10px;
}

.drag-el {
  background-color: #fff;
  margin-bottom: 10px;
  padding: 5px;
  height: 40px;
  cursor: grab;
}

.root-flow-card-title-suggest {
  font-size: 11px;
  font-style: italic;
}

.flow-active {
  border-color: #1890ff;
}

</style>
