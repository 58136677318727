import {ActionContext, ActionTree} from "vuex";
import {State} from "./states";
import {RootState} from "@/store";
import APIs from "@/APIs/APIs";
import {Mutations} from "@/store/modules/wallet/mutations";
import {
    FlowZNS_TransactionResponse,
    MyWalletResponse,
    WalletTransactionsResponse
} from "@/APIs/response/S_HttpResponse";
import {WalletActionTypes} from "@/store/modules/wallet/action-types";
import {WalletMutationTypes} from "@/store/modules/wallet/mutation-types";
import {FlowZNS_TransactionRequest} from "@/APIs/request/FlowZNS_TransactionRequest";
import {WalletTransactionRequest} from "@/APIs/request/WalletRequest";

type AugmentedActionContext = {
    commit<K extends keyof Mutations>(
        key: K,
        payload: Parameters<Mutations[K]>[1],
    ): ReturnType<Mutations[K]>;
} & Omit<ActionContext<State, RootState>, 'commit'>

export interface Actions {
    [WalletActionTypes.GET_WALLET](
        {commit}: AugmentedActionContext
    ): Promise<MyWalletResponse | undefined>,

    [WalletActionTypes.GET_FLOW_ZNS_TRANSACTIONS](
        {commit}: AugmentedActionContext,
        request: FlowZNS_TransactionRequest
    ): Promise<FlowZNS_TransactionResponse | undefined>,
    [WalletActionTypes.FETCH_WALLET_TRANSACTIONS](
        {commit}: AugmentedActionContext,
        request: WalletTransactionRequest
    ): Promise<WalletTransactionsResponse | undefined>,
}

export const actions: ActionTree<State, RootState> & Actions = {
    async [WalletActionTypes.GET_WALLET]({commit}): Promise<MyWalletResponse | undefined> {
        // console.log(payload);
        commit(WalletMutationTypes.SET_WALLET_FETCHING, true)
        const response = await APIs.init().getMyWallet("vnd")
        if (response.status === 200) {
            commit(WalletMutationTypes.SET_WALLET, response.data?.data)
        }
        commit(WalletMutationTypes.SET_WALLET_FETCHING, false)
        return response.data;
    },
    async [WalletActionTypes.GET_FLOW_ZNS_TRANSACTIONS]({commit}, request: FlowZNS_TransactionRequest): Promise<FlowZNS_TransactionResponse | undefined> {
        // console.log(payload);
        const response = await APIs.init().getFlowZNSTransactions(request)
        if (response.status === 200) {
            commit(WalletMutationTypes.SET_FLOW_ZNS_TRANSACTIONS, response.data?.data)
        }
        return response.data;
    },
    async [WalletActionTypes.FETCH_WALLET_TRANSACTIONS]({commit}, request: WalletTransactionRequest): Promise<WalletTransactionsResponse | undefined> {
        // console.log(payload);
        const response = await APIs.init().getWalletTransactions(request)
        if (response.status === 200) {
            commit(WalletMutationTypes.SET_WALLET_TRANSACTIONS, response.data?.data)
            commit(WalletMutationTypes.SET_WALLET_TRANSACTION_COUNT, response.data?.meta_data.record_total)
        }
        return response.data;
    },
}
