<template>
  <div class="container-title">
    <h3 class="title-header">{{$t($route.name)}}</h3>
  </div>

  <div class="container-content">
    <a-card style="width: 100%">
      <a-tabs
          style="margin-top: 25px"
          v-model:activeKey="activeKey"
          :tab-position="tabPosition"
          :style="{ backgroundColor: '#fff' }"
          animated
      >

        <a-tab-pane key="1">

          <template #tab>

            <div class="shipping-left">
              <a-tooltip placement="topLeft">
                <template #title>
                  <span v-if="false">{{$t('EDIT_CONNECT')}}</span>
                  <span>{{$t('CLICK_CONNECT')}}</span>
                </template>
              <a-button @click="connectType='cukcuk'" style="height: 48px;width: 100%">

              <a-row style="justify-content: space-between" class="shipping-left-content">
                <img
                    src="@/assets/icons/CukCuk.png"
                    style="width: 100px;height: 30px"
                />


                <!--                <span class="shipping-left-title">CukCuk</span>-->
                <div>

                    <check-circle-outlined v-if="false" style="font-size: 20px;color: #0ccb6b" />


                  <close-circle-outlined style="font-size: 20px;color: red" />

                </div>
              </a-row>
              </a-button>
              </a-tooltip>
            </div>
          </template>

          <a-card v-if="connectType === 'cukcuk'" class="card-ghtk-vt">
            <template #title>
              <span>
                <img
                    src="@/assets/icons/CukCuk.png"
                    style="width: 100px;height: 30px"
                />

              </span>
            </template>
            <template #extra>
              <a-button

                  type="primary" @click="updateCukcuk">
                <save-outlined />
                {{$t('CONNECT')}}
              </a-button>
            </template>
            <a-form
                :labelCol="{style: {width: '150px'}}"
                :wrapper-col="{ span: 16 }"
            >
              <a-form-item
                  :label="$t('app_id')">
                <a-input
                    v-model:value="cukcuk.app_id"

                />
              </a-form-item>
              <a-form-item
                  :label="$t('domain')">
                <a-input
                    v-model:value="cukcuk.domain"

                />
              </a-form-item>
              <a-form-item
                  :label="$t('secret')">
                <a-input
                    v-model:value="cukcuk.secret"

                />
              </a-form-item>
              <a-form-item
                  :label="$t('CARE_STATUS_LIST')">
                <a-select
                    style="width: 100%"
                    allowClear
                    placeholder="Please select"
                    v-model:value="cukcuk.last_care"
                >
                  <a-select-option
                      v-for="item in customerCares"
                      :key="item.id"
                      v-bind:value="item.id"

                  >
                    <a-tag :color="item.color">
                      {{ item.name }}
                    </a-tag>
                  </a-select-option>

                </a-select>
              </a-form-item>
            </a-form>
          </a-card>
        </a-tab-pane>
        <a-tab-pane key="2">
          <template #tab>
            <div class="shipping-left">
              <a-tooltip placement="topLeft">
                <template #title>
                  <span v-if="false">{{$t('EDIT_CONNECT')}}</span>
                  <span>{{$t('CLICK_CONNECT')}}</span>
                </template>
              <a-button @click="connectType='kiotviet'"   style="height: 48px;width: 100%">
            <a-row style="justify-content: space-between;align-items: center" class="shipping-left-content">

              <img
                  src="@/assets/icons/KiotViet-Logo.svg"
                  style="width: 100px;height: 30px"
              />

<!--              <span class="shipping-left-title">KiotViet</span>-->
              <div>

                  <check-circle-outlined v-if="false" style="font-size: 20px;color: #0ccb6b" />

                  <close-circle-outlined style="font-size: 20px;color: red" />

              </div>


            </a-row>
              </a-button>
              </a-tooltip>
            </div>
          </template>
          <a-card v-if="connectType === 'kiotviet'" class="card-ghtk-vt">
            <template #title>
              <span>
<!--                <img src="@/assets/icons/ghtk.png" class="logo-icon" />-->
               <img
                   src="@/assets/icons/KiotViet-Logo.svg"
                   style="width: 100px;height: 30px"
               />
              </span>
            </template>
            <template #extra>
              <a-button

                  type="primary" @click="updateKiotviet">
                <save-outlined />
                {{$t('CONNECT')}}
              </a-button>
            </template>
            <a-form
                :labelCol="{style: {width: '150px'}}"
                :wrapper-col="{ span: 16 }"
            >
              <a-form-item
                  :label="$t('app_id')">
                <a-input
                    v-model:value="cukcuk.app_id"

                />
              </a-form-item>
              <a-form-item
                  :label="$t('domain')">
                <a-input
                    v-model:value="cukcuk.domain"

                />
              </a-form-item>
              <a-form-item
                  :label="$t('secret')">
                <a-input
                    v-model:value="cukcuk.secret"

                />
              </a-form-item>
              <a-form-item
                  :label="$t('CARE_STATUS_LIST')">
                <a-select
                    style="width: 100%"
                    allowClear
                    placeholder="Please select"
                    v-model:value="cukcuk.last_care"
                >
                  <a-select-option
                      v-for="item in customerCares"
                      :key="item.id"
                      v-bind:value="item.id"

                  >
                    <a-tag :color="item.color">
                      {{ item.name }}
                    </a-tag>
                  </a-select-option>

                </a-select>
              </a-form-item>
            </a-form>
          </a-card>
        </a-tab-pane>
        <a-tab-pane key="3">
          <template #tab>
            <div class="shipping-left">
              <a-tooltip placement="topLeft">
                <template #title>
                  <span v-if="false">{{$t('EDIT_CONNECT')}}</span>
                  <span>{{$t('CLICK_CONNECT')}}</span>
                </template>
                <a-button @click="connectType='google'"   style="height: 48px;width: 100%">
                  <a-row style="justify-content: space-between;align-items: center" class="shipping-left-content">

                  <div>
                    <img
                        src="@/assets/icons/google-sheets.png"

                    />

                    <span class="shipping-left-title">Google sheets</span>
                  </div>
                    <div>

                      <check-circle-outlined v-if="false" style="font-size: 20px;color: #0ccb6b" />

                      <close-circle-outlined style="font-size: 20px;color: red" />

                    </div>


                  </a-row>
                </a-button>
              </a-tooltip>
            </div>
          </template>
          <a-card v-if="connectType === 'google'" class="card-ghtk-vt">
            <template #title>
              <span>
<!--                <img src="@/assets/icons/ghtk.png" class="logo-icon" />-->
               <img
                   src="@/assets/icons/google-sheets.png"

               />
              </span>
              <span class="shipping-left-title">Google sheets</span>
            </template>
            <template #extra>
              <a-button

                  type="primary" @click="updateKiotviet">
                <save-outlined />
                {{$t('CONNECT')}}
              </a-button>
            </template>
            <a-form
                :labelCol="{style: {width: '150px'}}"
                :wrapper-col="{ span: 16 }"
            >
              <a-form-item
                  :label="$t('app_id')">
                <a-input
                    v-model:value="cukcuk.app_id"

                />
              </a-form-item>
              <a-form-item
                  :label="$t('domain')">
                <a-input
                    v-model:value="cukcuk.domain"

                />
              </a-form-item>
              <a-form-item
                  :label="$t('secret')">
                <a-input
                    v-model:value="cukcuk.secret"

                />
              </a-form-item>
              <a-form-item
                  :label="$t('CARE_STATUS_LIST')">
                <a-select
                    style="width: 100%"
                    allowClear
                    placeholder="Please select"
                    v-model:value="cukcuk.last_care"
                >
                  <a-select-option
                      v-for="item in customerCares"
                      :key="item.id"
                      v-bind:value="item.id"

                  >
                    <a-tag :color="item.color">
                      {{ item.name }}
                    </a-tag>
                  </a-select-option>

                </a-select>
              </a-form-item>
              <a-form-item :label="$t('SALE_TAGS')">
                <a-tree-select
                    show-search
                    style="width: 100%"

                    :filterTreeNode="false"
                    @search="searchTags"
                    :notFoundContent="null"
                    :dropdown-style="{ maxHeight: '300', overflow: 'auto' }"
                    allow-clear
                    multiple
                    :placeholder="$t('TAG_SELECT')"
                >
                  <a-tree-select-node v-for="item in treeTags" :key="item.id" v-bind:value="item.id">
                    <template #title>
                      <a-tag :color="item.color">
                        {{ item.name }}
                      </a-tag>
                    </template>
                    <a-tree-select-node v-for="child in item.children" :key="child.id" v-bind:value="child.id">
                      <template #title>
                        <a-tag :color="child.color">
                          {{ child.name }}
                        </a-tag>
                      </template>
                    </a-tree-select-node>

                  </a-tree-select-node>
                </a-tree-select>
              </a-form-item>
            </a-form>
            <a-button @click="() => visible= true"><SettingOutlined />{{$t('CONFIG_CUSTOMER')}}</a-button>
          </a-card>
        </a-tab-pane>
      </a-tabs>
    </a-card>
    <a-modal :width="1000"  v-model:visible="visible" @ok="addConfig"  >
     <a-row>
       <a-col :span="12">
         <a-table
             :pagination="false"
             :dataSource="configCustomer"
             :columns="columnsQuick"
             class="flow-table">

           <template #name="{record}">
             <a-typography-text >{{$t(record.name)}}</a-typography-text>


           </template>
           <template #position="{record}">
             <a-input
                 @change="(e) => record.position = Number(e.target.value)"
                 :value="record.position"
                 style="margin: -5px 0"
             />


           </template>

         </a-table>
       </a-col>
       <a-col :span="12">
         <a-table
             :pagination="false"
             :dataSource="configCustomerExtra"
             :columns="columnsQuick"
             class="flow-table">

           <template #position="{record}">
             <a-input
                 @change="(e) => record.position = Number(e.target.value)"
                 :value="record.position"
                 style="margin: -5px 0"
             />


           </template>

         </a-table>
       </a-col>

     </a-row>
    </a-modal>


  </div>
</template>

<script lang="ts">
import { computed, onMounted, reactive, ref } from "vue";
import { SaveOutlined,CheckCircleOutlined,CloseCircleOutlined,SettingOutlined } from "@ant-design/icons-vue";

import { useStore } from "vuex";
import { Transporter } from "@/models/Transporter/Transporter";
import { TransporterActionTypes } from "@/store/modules/transporter/action-types";
import APIs from "@/APIs/APIs";
import {message} from "ant-design-vue";
import {Constant} from "@/utils/Constants";
import {useI18n} from "vue-i18n";
import {adexperiencereport_v1} from "googleapis";

export default {
  components: {
    SaveOutlined,
    CheckCircleOutlined,
    CloseCircleOutlined,
    SettingOutlined
  },

  setup: function () {
    const i18n = useI18n()
    const fetchData = () => {
      store.dispatch(TransporterActionTypes.FETCH_TRANSPORTER);
    };
    const getColumnsConfig = computed(()=> store.getters.getCustomerConfig)
    const getColumn = computed(()=> store.getters.getDefaultColumns)
    const treeTags = computed(() => store.getters.getTreeTags)
    const configCustomer =ref<any[]>([])
    const configCustomerExtra =ref<any[]>([])
    const config = ref({
      customer:[],
      extra:[]
    })
    const visible = ref(false)
    const columnsQuick = [
      {
        title: i18n.t('name'),
        dataIndex: 'name',
        key: 'name',
        slots: {customRender: 'name'},
      },
      {
        title: i18n.t('position'),
        dataIndex: 'position',
        key: 'position',
        slots: {customRender: 'position'},
      },
    ]
    const store = useStore();
    const shopid = computed(() => store.getters.getCurrentShop.id)
    const cukcuk = reactive({
      app_id: "",
      domain: "",
      secret:"",
      last_care:0
    });
    const kiotviet = reactive({
      app_id: "",
      domain: "",
      secret:"",
      last_care:0
    });
    const connectType = ref('')
    const customerCares = computed(() => store.getters.getCustomerCares)
    const selectedRowKeys = ref<number[]>([])

    const data = computed(() => store.getters.getTransporter);

  const addConfig = () => {
    configCustomer.value.map((item:any) => {
      // @ts-ignore
      config.value.customer.push({key:item.key,position:item.position})
    })

    configCustomerExtra.value.map((item:any) => {
      // @ts-ignore
      config.value.extra.push({id:item.id,position:item.position})
    })

    console.log(config.value)
    visible.value = false
  }

    const updateCukcuk = async () => {

      try {
        const connect = await APIs.init().shippingConnect({
          ...cukcuk,type:"cukcuk"
        })
      } catch (e) {
        console.log(e);
      }
    };
    const updateKiotviet = async () => {
      try {
        const res = await APIs.init().shippingConnect({
          ...kiotviet,type:'kiotviet'
        });
        if (res.status === 200) {
          message.success(i18n.t('SUCCESS'))
        }
      } catch (e) {
        console.log(e);
      }
    };
    onMounted(() => {
      fetchData()



    });

    const getUserInfo = computed(() => store.getters.getUserInfo)

    const getMyRole = computed(() => {
      if(getUserInfo.value?.id_cms_privileges==null ||  getUserInfo.value?.id_cms_privileges<11){
        return ()=>({
          is_visible: 1,
          is_create:1,
          is_read: 1,
          is_edit: 1,
          is_delete: 1,
          id: 1
        })
      }else{
        return store.getters.getRole
      }
    });
    const modules = Constant.MODULES;

    const tabPosition = ref("left");
    const activeKey = ref("1");
    onMounted(() => {
      getColumn.value.map((item:any) => {

        configCustomer.value.push({name:item.myTitle,key:item.dataIndex,position:0})
      })
      getColumnsConfig.value.map((item:any) => {

        configCustomerExtra.value.push({id:item.id,name:item.name,position:0})
      })
    })
    return {
      getMyRole,
      modules,
      tabPosition,
      activeKey,
      SaveOutlined,
      cukcuk,
      kiotviet,
      updateCukcuk,
      updateKiotviet,
      data,
      connectType,
      fetchData,
      customerCares,
      visible,
      columnsQuick,
      selectedRowKeys,
      configCustomer,
      addConfig,
      configCustomerExtra,
      treeTags

    };
  },
};
</script>

<style>
.title {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 22px;
  /* identical to box height, or 122% */
  margin-left: 19px;
  margin-top: 23px;
  text-align: center;

  color: #000000;
}

.shipping-left {
  width: 256px;
}

.shipping-left-content {
  height: 40px;
  align-items: center;
}

.icon {
  margin-right: 11.25px;
  margin-left: 16px;
}

.shipping-left-title {
  font-weight: bold;
  font-size: 14px;
}

/*.*/
.logo-viettel-icon {
  width: 38px;
}

/*@media only screen and (min-width: 768px) {*/
/*  !* For desktop: *!*/
/*  .card-ghtk-vt {*/
/*    width: 48%;*/
/*  }*/
/*}*/

/*@media only screen and (max-width: 500px) {*/
/*  .card-ghtk-vt {*/
/*    width: 100%;*/
/*  }*/
/*}*/

.card-ghtk-vt {
  /*width: 692px;*/
}

.card-ghn {
  /*width: 692px;*/
  margin-top: 18px;
}

.logo-icon {
  width: 20px;
  height: 20px;
}

.logo-ghn-icon {
  width: 28px;
}

.title-header {
  font-weight: bold;
  flex: 1;
}
.container-title{
  display: flex;
  flex-direction: row;
  padding: 16px 24px 16px 24px;
  margin-bottom: 25px;
  background-color: white
}
.input-search{
  width: 250px;
  margin-right: 16px;
}
.container-content{
  margin-left: 16px;
  margin-right: 16px;
}
.item{
  width: 250px;
}
</style>
