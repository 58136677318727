<template>
  <div class="main-flow" id="main-flow">
    <a-form ref="formRef" :model="formState" layout="vertical">
      <a-form-item ref="name" :label="$t('FLOW_MESSAGE_NAME')" name="name" v-bind="validateInfos.name">
        <a-input
            :placeholder="$t('FLOW_MESSAGE_NAME')"
            @change="onTextChange"
            v-model:value="formState.name"
        />
      </a-form-item>
      <a-card class="card-message" :title="$t('TIMER')">
        <template #extra>
          <a-button type="text">
            <close-outlined/>
          </a-button>
        </template>
        <a-form-item name="timer_type" v-bind="validateInfos.timer_type" :label="$t('TIMER_TYPE')">
          <a-radio-group v-model:value="formState.timer_type" @change="handleTimerType" name="timer_type">
            <a-radio value="timeout">{{ $t('TIMEOUT') }}</a-radio>
            <a-radio value="schedule_at">{{ $t('CUSTOMER_SCHEDULE_AT') }}</a-radio>
          </a-radio-group>
        </a-form-item>
        <a-form-item name="timeout" v-if="formState.timer_type === 'timeout'" :label="$t('TIMEOUT')">
          <div style="display: flex">
            <a-input-number :min="0" v-model:value="formState.timeout" @blur="onTextChange" style="flex: 1"/>
            <div style="width: 10px;"></div>
            <a-select v-model:value="formState.timer_format_type" @change="onTextChange" style="flex: 0.5;">
              <a-option value="DAY">
                {{ $t('DAY') }}
              </a-option>
              <a-option value="HOUR">
                {{ $t('HOUR') }}
              </a-option>
              <a-option value="MINUTE">
                {{ $t('MINUTE') }}
              </a-option>
              <a-option value="SECOND">
                {{ $t('SECOND') }}
              </a-option>
            </a-select>
          </div>
        </a-form-item>
        <a-form-item name="scheduled_at" :label="$t('CUSTOMER_SCHEDULE_AT')" v-else v-bind="config">
          <a-date-picker show-time style="width: 100%"  @change="onTextChange" v-model:value="formState.scheduled_at" format="DD-MM-YYYY HH:mm"
                         />

        </a-form-item>
      </a-card>


      <a-divider/>
<!--      <flow-next-actions-->
<!--          :buttonName="$t('FLOW_SUCCESS_DELIVERY')"-->
<!--          :updateAction="updateSuccessAction"-->
<!--          :flowContextData="flowContextData"-->
<!--          :flowContext="flowContext"-->
<!--          :actionContext="flowContext.action_send_success_context"-->
<!--      />-->
<!--      <flow-next-actions-->
<!--          :buttonName="$t('FLOW_FAILED_DELIVERY')"-->
<!--          :updateAction="updateFailedAction"-->
<!--          :flowContextData="flowContextData"-->
<!--          :flowContext="flowContext"-->
<!--          :actionContext="flowContext.action_send_failed_context"-->
<!--      />-->
      <flow-next-actions
          v-if="!isShow"
          :buttonName="$t('FLOW_DEFAULT_DELIVERY')"
          :updateAction="updateDefaultAction"
          :flowContextData="flowContextData"
          :flowContext="flowContext"
          :actionContext="flowContext.action_default_context"
      />
      <div class="row-button-action" style="text-align: right">
        <a-popconfirm
            @confirm="deleteCurrentFlowContext"
            :title="$t('FLOW_CONFIRM_DELETE_CONTEXT')"
            :ok-text="$t('YES')"
            :cancel-text="$t('NO')"
        >
          <a-button danger type="primary">
            <delete-outlined/>
            {{ $t("FLOW_DELETE") }}
          </a-button>
        </a-popconfirm>
      </div>
    </a-form>
    <image-selection-modal
        ref="imageSelectionRef"
        :onSelect="onImageSelect"
    />
  </div>
</template>

<script lang="ts">
import {
  defineComponent,
  onBeforeMount,
  reactive,
  ref,
  UnwrapRef,
  watch,
} from "vue";
import {
  CloseCircleOutlined,
  CloseOutlined,
  DeleteOutlined,
  DragOutlined,
  NotificationOutlined,
  PictureOutlined,
  FileDoneOutlined,
  FileAddOutlined,
} from "@ant-design/icons-vue";
import FlowNextActions from "@/components/Flow/FlowNextActions.vue";
import {useStore} from "@/store";
import {S_EventBus, ValidateFlowContext} from "@/utils/EventBus";
import {useI18n} from "vue-i18n";
import {MixUtils} from "@/utils/Utils";
import ImageSelectionModal from "@/components/Image/ImageSelectionModal.vue";
import "vue3-carousel/dist/carousel.css";
import {AWS_DO_Model} from "@/models/AWS_DO/AWS_DO";
import {
  ActionContext,
  MessageContext,
  PostbackContext,
  QuickReply,
} from "@/models/Flows/FlowContext";
import {
  FormEmailContext,
  FormFlowInformation, FormTimerContext,
} from "@/models/Flows/FlowForm";
import {ValidateErrorEntity} from "ant-design-vue/es/form/interface";
// @ts-ignore
import {VueEditor} from "vue3-editor";
import {Form} from 'ant-design-vue';
import dayjs, {Dayjs} from "dayjs";

const useForm = Form.useForm;

export default defineComponent({
  name: "flow-timer",
  props: [
    "flowContext",
    "updateCurrentFlowContext",
    "flowContextData",
    "deleteFlowContext",
      "isShow"
  ],
  components: {
    DeleteOutlined,
    FlowNextActions,
    ImageSelectionModal,
  },
  setup(props) {
    const formRef = ref();
    const store = useStore();
    const i18n = useI18n();
    const formState: UnwrapRef<FormTimerContext> = reactive({timer_type: 'timeout'});
    const messageContexts = ref<MessageContext[]>([]);
    const imageSelectionRef = ref();
    const {resetFields, validate, validateInfos} = useForm(
        formRef,
        reactive({
          name: [
            {
              required: true,
              message: i18n.t("FLOW_VALIDATE_FLOW_NAME"),
              trigger: "blur",
            },
          ],
          timer_type: [
            {
              required: true,
              message: i18n.t("FLOW_VALIDATE_FLOW_NAME"),
              trigger: "blur",
            },
          ],
          scheduled_at: [
            {
              required: true,
              message: i18n.t("FLOW_VALIDATE_FLOW_NAME"),
              trigger: "blur",
            },
          ],
          timeout: [
            {
              required: true,
              message: i18n.t("FLOW_VALIDATE_FLOW_NAME"),
              trigger: "blur",
            },
          ],
        }),
    );
    const config = {
      rules: [{type: 'string' as const, required: true, message: 'Please select time!'}],
    };

    const updateProps = () => {
      if (props.flowContext) {
        formState.name = props.flowContext.name;
        formState.timer_type = props.flowContext.timer_type ? props.flowContext.timer_type : 'timeout';
        if (props.flowContext.timeout&&formState.timer_type==='timeout') {
          if (props.flowContext.timeout >= 86400) {
            formState.timeout = Math.round(props.flowContext.timeout / 86400)
            formState.timer_format_type = "DAY"
          } else if (props.flowContext.timeout >= 3600) {
            formState.timer_format_type = "HOUR"
            formState.timeout = Math.round(props.flowContext.timeout / 3600)
          } else if (props.flowContext.timeout >= 60) {
            formState.timer_format_type = "MINUTE"
            formState.timeout = Math.round(props.flowContext.timeout / 60)
          } else if (props.flowContext.timeout === 0) {
            formState.timer_format_type = "DAY"
            // formState.timeout = 1
          } else {
            formState.timer_format_type = "SECOND"
            formState.timeout = props.flowContext.timeout
          }
        }else if (formState.timer_type==='schedule_at'){
          formState.scheduled_at = props.flowContext.scheduled_at ? dayjs(props.flowContext.scheduled_at) : dayjs().add(1, 'days')
        }else {
          formState.timer_format_type = "DAY"
          // formState.timeout = 1
        }
      }
    };
    const handleTimerType = () => {
      if (formState.timer_type==='timeout'){
        // @ts-ignore
        if (formState.timeout <=0){
          formState.timer_format_type = "DAY"
          // formState.timeout= 1
        }
      }else {
        if (!formState.scheduled_at){
          formState.scheduled_at=dayjs().add(1, 'days')
        }
      }
      onSubmit()
    }
    watch(
        () => props.flowContext,
        () => {
          updateProps();
        }
    );
    const flowFormValidate: ValidateFlowContext = {
      showValidateForm() {
        onSubmit();
      },
    };
    onBeforeMount(() => {
      S_EventBus.instance().setValidateFlowContextListener(flowFormValidate);
    });

    const onTextChange = () => {
      onSubmit();
    };

    const attachFile = () => {
      imageSelectionRef.value.showModal()
    }



    const updateSuccessAction = (action: ActionContext) => {
      const flow = {...props.flowContext};
      flow.action_send_success_context = action;
      props.updateCurrentFlowContext(flow);
    };
    const updateFailedAction = (action: ActionContext) => {
      const flow = {...props.flowContext};
      flow.action_send_failed_context = action;
      props.updateCurrentFlowContext(flow);
    };
    const updateDefaultAction = (action: ActionContext) => {
      const flow = {...props.flowContext};
      flow.action_default_context = action;
      props.updateCurrentFlowContext(flow);
    };
    const showImageSingleSelectionModal = (param: string) => {
      imageSelectionRef.value.showModal(param);
    };

    const deleteCurrentFlowContext = () => {
      props.deleteFlowContext(props.flowContext.id);
    };

    const onSubmit = () => {
      validate().then(() => {
        const flow = {...props.flowContext};
        flow.name = formState.name;
        flow.timer_type = formState.timer_type;
        if (formState.timer_type === 'timeout'&&formState.timeout) {
          if (formState.timer_format_type==="DAY"){
            flow.timeout=formState.timeout*86400
          }else if (formState.timer_format_type==="HOUR"){
            flow.timeout=formState.timeout*3600
          }else if (formState.timer_format_type==="MINUTE"){
            flow.timeout=formState.timeout*60
          }else if (formState.timer_format_type==="SECOND"){
            flow.timeout=formState.timeout
          }
        } else {
          flow.scheduled_at = formState.scheduled_at
        }
        props.updateCurrentFlowContext(flow);
      }).catch((error: ValidateErrorEntity<FormFlowInformation>) => {
        console.log("error", error);
      });
    };
    updateProps();
    return {
      formState,
      formRef,
      onSubmit,
      onTextChange,
      updateSuccessAction,
      updateFailedAction,
      updateDefaultAction,
      deleteCurrentFlowContext,
      messageContexts,
      showImageSingleSelectionModal,
      validateInfos,
      attachFile,
      imageSelectionRef,
      config,
      handleTimerType
    };
  },
});
</script>

<style lang="scss" scoped>
.main-flow {
  position: relative;
  padding: 40px 18% 60px;
  text-align: left;

  .icon-brand-sticker {
    position: absolute;
    font-size: 28px;
    top: 0;
    margin-right: 25px;
    right: 0;
  }

  .row-button-action {
    display: flex;
    margin-top: 20px;
  }

  .card-message {
    margin-top: 10px;
    border-radius: 10px;
  }
}

.post-back-button {
  margin-top: 18px;
  text-align: center;
}

.button-post-back {
  margin: 5px 10px;
}

.slick-dot-facebook-template {
  display: inline-block;
  cursor: pointer;
  margin: 7px;

  .slick-dot-facebook-template-content {
    padding: 2px;
  }
}

.card-facebook-template {
  width: 95%;
}

.card-facebook-template-active {
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.img-cover-facebook-template {
  width: 100%;
  height: 200px;
  object-fit: cover;
}

.upload-center {
  display: flex;
  justify-content: center;
  height: 200px;
  align-items: center;
}


.attach-file-container {
  margin-top: 18px;
}

.photo-flow-img {
  max-height: 200px;
  object-fit: cover;
  cursor: pointer;
}

.icon-img-svg {
  width: 28px;
  height: 28px;
}

.button-quick-reply {
  border-radius: 15px;
}
</style>
<style>
.carousel__prev--in-active {
  display: none;
}

.carousel__next--in-active {
  display: none;
}
</style>
