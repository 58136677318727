import {CustomerCare} from "@/models/CustomerCares/CustomerCares";

export type State = {
    customerCares: CustomerCare[] | undefined;
    customerDetailCares: CustomerCare[] | undefined;
}

export const state: State = {
    customerCares:[],
    customerDetailCares:[],
}
