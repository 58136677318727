import {ActionContext, ActionTree, useStore} from "vuex";
import {State} from "./states";
import {RootState} from "@/store";
import {OverviewActionTypes} from "@/store/modules/OverView/action-types";
import APIs from "@/APIs/APIs";
import {OverviewMutationtypes} from "@/store/modules/OverView/mutation-types";
import {Mutations} from "@/store/modules/OverView/mutations";
import {Overview} from "@/models/Overview/Overview";
import {
    OverviewResponse,
    OverviewResponseFB,
    OverviewResponseITG,
    OverviewResponseReport, OverviewResponseZalo
} from "@/APIs/response/S_HttpResponse";


type AugmentedActionContext = {
    commit<K extends keyof Mutations>(
        key: K,
        payload: Parameters<Mutations[K]>[1],
    ): ReturnType<Mutations[K]>;
} & Omit<ActionContext<State, RootState>, 'commit'>

export interface Actions {
    [OverviewActionTypes.FETCH_OVERVIEW](
        {commit}: AugmentedActionContext,
        payload?: OverviewResponse,
    ): Promise<OverviewResponse | undefined>;
}

export const actions: ActionTree<State, RootState> & Actions = {
    async [OverviewActionTypes.FETCH_OVERVIEW]({commit},playload): Promise<OverviewResponse | undefined> {
        commit(OverviewMutationtypes.GET_OVERVIEW, [])

        const response = await APIs.init().getOverView({})
        // @ts-ignore
        if (response.data.status === 200) {
            commit(OverviewMutationtypes.GET_OVERVIEW, response.data?.data)

        }
        return response.data;
    },
    async [OverviewActionTypes.FETCH_OVERVIEW_FB]({commit}): Promise<OverviewResponseFB | undefined> {
        const response = await APIs.init().getOverViewFB()
        if (response.status === 200) {
            commit(OverviewMutationtypes.GET_OVERVIEW_FB, response.data?.data)
        }
        return response.data;
    },
    async [OverviewActionTypes.FETCH_OVERVIEW_ITG]({commit}): Promise<OverviewResponseITG | undefined> {
        const response = await APIs.init().getOverViewITG()
        if (response.status === 200) {
            commit(OverviewMutationtypes.GET_OVERVIEW_ITG, response.data?.data)
        }
        return response.data;
    },
    async [OverviewActionTypes.FETCH_OVERVIEW_ZALO]({commit}): Promise<OverviewResponseZalo | undefined> {
        const response = await APIs.init().getOverViewZalo()
        if (response.status === 200) {
            commit(OverviewMutationtypes.GET_OVERVIEW_ZALO, response.data?.data)
        }
        return response.data;
    },
    async [OverviewActionTypes.FETCH_OVERVIEW_REPORT]({commit}): Promise<OverviewResponseReport | undefined> {
        const store = useStore()
        let shopId = 0
        if (store.getters.getUserInfo) {
            shopId = store.getters.getUserInfo?.current_shop_id
        }
        const date = new Date();
        const Curent_year = date.getFullYear();
        const Curent_month = (date.getMonth() + 1) < 10 ? "0" + (date.getMonth() + 1) : date.getMonth() + 1 ;
        const Curent_day =( date.getDate()) < 10 ? "0" + ( date.getDate()) : date.getDate()  ;
        const newdate = new Date(date.setDate(date.getDate() - 7));
        const New_year = newdate.getFullYear();
        const New_month = (newdate.getMonth() + 1) < 10 ? "0" + (newdate.getMonth() + 1) : newdate.getMonth() + 1 ;
        const New_day = newdate.getDate() < 10 ? "0" + (newdate.getDate()) : newdate.getDate() ;
        const toDate = `${Curent_year}-${Curent_month}-${Curent_day}`
        const fromDate = `${New_year}-${New_month}-${New_day}`
        const response = await APIs.init().getOverviewReport(shopId,fromDate,toDate)
        if (response.status === 200) {
            commit(OverviewMutationtypes.GET_OVERVIEW_REPORT, response.data)
        }
        return response.data;
    },


}
