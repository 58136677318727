import {State, StockProductsData, StocksData} from "./states";
import {StocksMutationTypes} from "./mutation-types";

export type Mutations<S = State> = {
    [StocksMutationTypes.SET_STOCKS](state: S, payload: StocksData | undefined): void;
    [StocksMutationTypes.SET_STOCK_PRODUCTS](state: S, payload: StockProductsData | undefined): void;
}
export const mutations: Mutations = {
    [StocksMutationTypes.SET_STOCKS](state: State, payload: StocksData | undefined): void {
        state.stockData = payload
    },
    [StocksMutationTypes.SET_STOCK_PRODUCTS](state: State, payload: StockProductsData | undefined): void {
        state.stockProductsData = payload
    },
}
