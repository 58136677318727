import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { style: {"height":"100vh","display":"flex","justify-content":"center","margin-top":"24px"} }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_button = _resolveComponent("a-button")!
  const _component_a_result = _resolveComponent("a-result")!
  const _component_a_input = _resolveComponent("a-input")!
  const _component_a_form_item = _resolveComponent("a-form-item")!
  const _component_a_radio = _resolveComponent("a-radio")!
  const _component_a_radio_group = _resolveComponent("a-radio-group")!
  const _component_a_form = _resolveComponent("a-form")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.success)
      ? (_openBlock(), _createBlock(_component_a_result, {
          key: 0,
          status: "success",
          title: "Mua vé thành công!",
          "sub-title": "Thông tin vé sẽ được gửi qua email. Vui lòng kiểm tra email, nếu không thấy trong email chính bạn kiểm tra hòm thư spam. "
        }, {
          extra: _withCtx(() => [
            _createVNode(_component_a_button, {
              onClick: _ctx.reBuy,
              key: "buy",
              type: "primary"
            }, {
              default: _withCtx(() => [
                _createTextVNode("Mua tiếp")
              ]),
              _: 1
            }, 8, ["onClick"])
          ]),
          _: 1
        }))
      : (_openBlock(), _createBlock(_component_a_form, {
          key: 1,
          ref: "formRef",
          model: _ctx.formState,
          rules: _ctx.rules,
          "label-col": _ctx.labelCol,
          "wrapper-col": _ctx.wrapperCol,
          onValidate: _ctx.handleValidate
        }, {
          default: _withCtx(() => [
            _createVNode(_component_a_form_item, {
              label: "Họ tên",
              name: "name"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_a_input, {
                  value: _ctx.formState.name,
                  "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.formState.name) = $event))
                }, null, 8, ["value"])
              ]),
              _: 1
            }),
            _createVNode(_component_a_form_item, {
              label: "Số điện thoại",
              name: "phone"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_a_input, {
                  value: _ctx.formState.phone,
                  "onUpdate:value": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.formState.phone) = $event))
                }, null, 8, ["value"])
              ]),
              _: 1
            }),
            _createVNode(_component_a_form_item, {
              label: "Email",
              name: "email"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_a_input, {
                  value: _ctx.formState.email,
                  "onUpdate:value": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.formState.email) = $event))
                }, null, 8, ["value"])
              ]),
              _: 1
            }),
            _createVNode(_component_a_form_item, {
              label: "Loại vé",
              name: "ticket_type"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_a_radio_group, {
                  value: _ctx.formState.ticket_type,
                  "onUpdate:value": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.formState.ticket_type) = $event))
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_a_radio, { value: 7 }, {
                      default: _withCtx(() => [
                        _createTextVNode("Vé thường ($100)")
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_a_radio, { value: 14 }, {
                      default: _withCtx(() => [
                        _createTextVNode("Vé Vip ($200)")
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }, 8, ["value"])
              ]),
              _: 1
            }),
            _createVNode(_component_a_form_item, { "wrapper-col": { span: 14, offset: 4 } }, {
              default: _withCtx(() => [
                _createVNode(_component_a_button, {
                  type: "primary",
                  onClick: _ctx.onSubmit
                }, {
                  default: _withCtx(() => [
                    _createTextVNode("Đăng ký")
                  ]),
                  _: 1
                }, 8, ["onClick"])
              ]),
              _: 1
            })
          ]),
          _: 1
        }, 8, ["model", "rules", "label-col", "wrapper-col", "onValidate"]))
  ]))
}