<template>

  <div class="container-title">
    <h3 class="title-header">{{$t($route.name)}}</h3>
    <a-row>
<!--      <a-col :span="6">-->

<!--        <a-input  style="width: 100%;margin-bottom: 16px"  :placeholder="$t('ORDER')">-->
<!--          <template #suffix>-->
<!--            <a-tooltip title="Extra information">-->
<!--              <SearchOutlined style="color: rgba(0, 0, 0, 0.45)" />-->
<!--            </a-tooltip>-->
<!--          </template>-->
<!--        </a-input>-->
<!--        <a-input    style="width: 100%;margin-bottom: 16px" :placeholder="$t('CUSTOMER')">-->
<!--          <template #suffix>-->
<!--            <a-tooltip title="Extra information">-->
<!--              <SearchOutlined style="color: rgba(0, 0, 0, 0.45)" />-->
<!--            </a-tooltip>-->
<!--          </template>-->
<!--        </a-input>-->



<!--      </a-col>-->
      <a-col :span="1">

      </a-col>
      <a-col :span="10">
<!--        <a-select-->
<!--            v-model:value="selectedPage"-->
<!--            @change="handleEmployeeChange"-->
<!--            :allowClear="true"-->
<!--            :dropdownMatchSelectWidth="true"-->
<!--            style="width: 100%; margin-bottom: 16px"-->
<!--            :placeholder="$t('EMPLOYEE')"-->
<!--        >-->
<!--          <a-select-option-->
<!--              v-for="item in employeeStore.employees"-->
<!--              :key="item.id"-->
<!--              v-bind:value="item.id"-->
<!--          >{{ item.name }}-->
<!--          </a-select-option>-->
<!--        </a-select>-->
        <a-select
            v-model:value="selectedProduct"
            mode="tags"
            @change="handleProductChange"
            :allowClear="true"
            :dropdownMatchSelectWidth="true"
            style="width: 100%; margin-bottom: 16px"
            :placeholder="$t('PRODUCT')"
        >
          <a-select-option
              v-for="item in product"
              :key="item.id"
              v-bind:value="item.id"
          >{{ item.product_name }}
          </a-select-option>
        </a-select>
        <a-input-group style="margin-bottom: 16px;width: 400px" compact>
          <a-select style="width: 100px"   @change="handleCare" v-model:value="carelType">
            <a-select-option value="last_care_in"> {{$t('CONTAIN')}}</a-select-option>
            <a-select-option value="last_care_ex"> {{$t('NOT_CONTAIN')}} </a-select-option>
          </a-select>
          <a-select
              style="width: 150px"
              allow-clear
              placeholder="Please select"
              v-model:value="care"
              @change="handleCare"

          >
            <template #clearIcon>
              <close-circle-outlined @click="handleCare" />

            </template>
            <a-select-option
                v-for="item in customer.customerCares"
                :key="item.id"
                v-bind:value="item.id"
            >
              <a-tag :color="item.color">
                {{ item.name }}
              </a-tag>
            </a-select-option>
          </a-select>
        </a-input-group>
        <a-input-group compact>
          <a-select   @change="changMoney" v-model:value="totalType">
            <a-select-option value="purchased_amount_gte"> {{'>='}}</a-select-option>
            <a-select-option value="purchased_amount_lte"> {{'<='}} </a-select-option>
            <a-select-option value="purchased_amount_eq"> {{'='}} </a-select-option>
          </a-select>
          <a-input
              @change="changMoney"
              v-model:value="totalMoney"
              style="width: 150px"
              :placeholder="$t('TOTAL_AMOUNT')"
          />
        </a-input-group>
      </a-col>

      <a-col :span="1">

      </a-col>
      <a-col :span="10">

        <a-range-picker :allowClear="false"  @change="onChangeDateTime"
                         @ok="confirmDateTime" :placeholder="[$t('START_TIME_PLACEHOLDER'), $t('END_TIME_PLACEHOLDER')]"   v-model:value="rangeTime" />
        <a-input-group style="margin-top: 10px" compact>
          <a-select   @change="changeNumberOrder" v-model:value="orderType">
            <a-select-option value="success_gte"> {{'>='}}</a-select-option>
            <a-select-option value="success_lte"> {{'<='}} </a-select-option>
            <a-select-option value="success_eq"> {{'='}} </a-select-option>
          </a-select>
          <a-input
              @change="changeNumberOrder"
              v-model:value="totalOrder"
              style="width: 150px"
              :placeholder="$t('ORDER_SUCCESS')"
          />
        </a-input-group>
<!--        <a-range-picker-->
<!--            :allowClear="false"-->
<!--            v-model:value="rangeTime"-->

<!--            format="DD/MM/YYYY"-->

<!--           -->
<!--        >-->
<!--          <template #suffixIcon>-->
<!--            <CalendarOutlined/>-->
<!--          </template>-->
<!--        </a-range-picker>-->
      </a-col>
    </a-row>

  </div>
  <div style="padding: 16px">

    <a-table :pagination="false"   :scroll="{ x: 1500, y: 500 }" :columns="columns" :data-source="customerData.customerOrder" bordered>
      <template #full_name="{ record }">
        <a-row>
          <a-col :span="6">
            <a-avatar v-if="record.social_type === 'facebook'" style="width: 30px;height: 30px;cursor: pointer"    :src="`https://graph.facebook.com/${record.social_id}/picture?access_token=${getTokenPage(record.page_id)}`">
            </a-avatar>
            <a-avatar v-else style="width: 30px;height: 30px;cursor: pointer"    :src="record.avatar">
            </a-avatar>

          </a-col >
          <a-col :span="18">
            <a
                class="table-row-name"
                type="text"
                @click="expandRow(record.id)"
            >
              <span>{{ record.full_name }}</span>
            </a>
            <div @click="copy(record.phone)" style="cursor: pointer" class="phone-number">{{ record.phone }}</div>
          </a-col>
        </a-row>
      </template>
      <template #employees="{record}">
        {{getEmployee(record.employees)}}
      </template>
      <template #birth="{record}">
        {{dateDisplayFormatFromString(record.birth)}}
      </template>
      <template #total_amount="{record}">
        {{formatMoney(record.total_amount)}}đ
      </template>
      <template #last_created_at="{record}">
        {{dateDisplayFormatFromString(record.last_created_at)}}
      </template>
      <template #created_by="{record}">
        {{getEmployee(record.created_by)}}
      </template>
      <template #created_at="{record}">
        {{dateDisplayFormatFromString(record.created_at)}}
      </template>
      <template #count="{record}">
        {{record.count}}
      </template>
      <template #last_care="{record}">
        <a-tag :color="getCare(record.last_care).color">
          {{getCare(record.last_care).name}}
        </a-tag>
      </template>
    </a-table>
  </div>
</template>

<script>

import {useStore} from "vuex";
import {ShopinfoActionTypes} from "@/store/modules/Shopinfo/action-types";
import {computed, onMounted, ref} from "vue";
import moment, {Moment} from "moment";
import {useI18n} from "vue-i18n";
import APIs from "@/APIs/APIs";
import {MixUtils} from "@/utils/Utils";
import {CalendarOutlined,SearchOutlined} from "@ant-design/icons-vue";
import dayjs from "dayjs";
import {ReportPAGEActionTypes} from "../../../store/modules/ReportPage/action-types";
import useEmployeeStore from "../../../store/Employee";
import Templates from "../../ticket/Templates";
import useOverviewStore from "../../../store/Overview";
import useTagStore from "../../../store/Tags";
import useCustomerStore from "../../../store/Customers";
import useReportPageStore from "../../../store/ReportPage";




export default {
  components: {

    // CalendarOutlined,
    // SearchOutlined
  },
  setup() {

    const i18n = useI18n()
    const store = useStore();
    const rangeTime = ref([dayjs().add(-1, 'months').startOf('day'), dayjs().endOf('day')])
    const employeeStore = useEmployeeStore()
    const totalType = ref('purchased_amount_eq')
    const totalMoney = ref('')
    const orderType = ref('success_eq')
    const totalOrder = ref('')
    const carelType = ref('last_care_in')
    const care = ref('')
    const product = ref([])
    const fetchShopInfo = () => {
      store.dispatch(ShopinfoActionTypes.FETCH_SHOPINFO);
    };
    const  customer = useCustomerStore()
    const handleCare = (e) => {
      let req ={
        from_date: rangeTime.value[0].format('YYYY-MM-DDT00:00:00Z'),
        to_date: rangeTime.value[1].format('YYYY-MM-DDT23:59:59Z'),
        // page_size: 20,
        // page:1,
        // last_care_in:,
        // last_care_ex:,
        // purchased_amount_gte:totalType.value === 'purchased_amount_gte' ?  Number(totalMoney.value) :0,
        // purchased_amount_lte:totalType.value === 'purchased_amount_lte' ?   Number(totalMoney.value) :0,
        // purchased_amount_eq:totalType.value === 'purchased_amount_eq' ?   Number(totalMoney.value) :0,
        // success_eq:,
        // success_gte:,
        // success_lte:,
        // product_ids:,

      }

      if(carelType.value === 'last_care_in'){
        req.last_care_in = care
      }else {
        req.last_care_ex = care
      }
      fetchCusomer(req)
    }
    const fetchProduct = async () => {
      const response = await APIs.init().getStockProducts({page:1,page_size:1000})
      if(response.data.status === 200){
        product.value =  response.data.data
      }

    }
    const getCare = (id) => {
      const careC= useCustomerStore.getState().customerCares.find(item => item.id === id)
      if(careC){
        return careC
      }
      return id
    }
    const changMoney= (e) => {
      let req ={
        from_date: rangeTime.value[0].format('YYYY-MM-DDT00:00:00Z'),
        to_date: rangeTime.value[1].format('YYYY-MM-DDT23:59:59Z'),
        // page_size: 20,
        // page:1,
        // last_care_in:,
        // last_care_ex:,
        // purchased_amount_gte:totalType.value === 'purchased_amount_gte' ?  Number(totalMoney.value) :0,
        // purchased_amount_lte:totalType.value === 'purchased_amount_lte' ?   Number(totalMoney.value) :0,
        // purchased_amount_eq:totalType.value === 'purchased_amount_eq' ?   Number(totalMoney.value) :0,
        // success_eq:,
        // success_gte:,
        // success_lte:,
        // product_ids:,

      }
      if(totalType.value === 'purchased_amount_gte'){
        req.purchased_amount_gte = Number(totalMoney.value)
      }else if(totalType.value === 'purchased_amount_lte'){
        req.purchased_amount_lte = Number(totalMoney.value)
      }else {
        req.purchased_amount_eq = Number(totalMoney.value)
      }
      fetchCusomer(req)
    }
    const changeNumberOrder = (e) => {
      let req ={
        from_date: rangeTime.value[0].format('YYYY-MM-DDT00:00:00Z'),
        to_date: rangeTime.value[1].format('YYYY-MM-DDT23:59:59Z'),
        // page_size: 20,
        // page:1,
        // last_care_in:,
        // last_care_ex:,
        // purchased_amount_gte:totalType.value === 'purchased_amount_gte' ?  Number(totalMoney.value) :0,
        // purchased_amount_lte:totalType.value === 'purchased_amount_lte' ?   Number(totalMoney.value) :0,
        // purchased_amount_eq:totalType.value === 'purchased_amount_eq' ?   Number(totalMoney.value) :0,
        // success_eq:,
        // success_gte:,
        // success_lte:,
        // product_ids:,

      }
      if(orderType.value === 'success_gte'){
        req.success_gte = Number(totalOrder.value)
      }else if(totalType.value === 'success_lte'){
        req.success_lte = Number(totalOrder.value)
      }else {
        req.success_eq = Number(totalOrder.value)
      }
      fetchCusomer(req)
    }
    const fetchCusomer = (req) => {
      useEmployeeStore.getState().GetEmployee({
        page: 1,
        page_size: 999,
      })
      useCustomerStore.getState().GetCustomerCare()
      useReportPageStore.getState().getReportOrder(req)
    };

   const getTokenPage=(id)=>{
      let token = []
      token =   useOverviewStore.getState().overView.filter((item) => item.page_id === id + '')

      return token && token[0] && token[0].page_token
    }
    const getEmployee = (data) => {
      console.log(data)
      if(data){
        console.log(useEmployeeStore.getState().employees.find( item => item.cms_user_id === data),'sadas')
        let user = useEmployeeStore.getState().employees.find( item => item.cms_user_id === Number(data))
        if(user){
          return user.name
        }else {
          return data
        }

      }else {
        return ''
      }

    }
    const dataShopInfo = computed(() => store.getters.getShopinfo);
    const pagesSelection = computed(() => store.getters.getPagesSelection);
    const getPages = computed(() => store.getters.getPages);
    const customerData =useReportPageStore()
    const selectedProduct = ref();

    const columns = [
      {
        title: i18n.t('CUSTOMER_NAME'),
        dataIndex: 'full_name',
        key: 'full_name',
        slots: {customRender: "full_name"},
        width: 150,
        fixed: 'left',
      },
      {
        title: i18n.t('CARE_STATUS_LIST'),
        dataIndex: 'last_care',
        key: 'last_care',
        slots: {customRender: "last_care"},
        width: 150,
      },
      {
        title: i18n.t('Assigee'),
        dataIndex: 'employees',
        key: 'employees',
        slots: {customRender: "employees"},
        width: 100,
      },
      {
        title: i18n.t('CUSTOMER_BIRTHDAY'),
        dataIndex: 'birth',
        key: 'birth',
        slots: {customRender: "birth"},
        width: 100,
      },
      {
        title: i18n.t('ORDER'),
        dataIndex: 'count',
        key: 'count',
        slots: {customRender: "count"},
        width: 100,
      },
      {
        title: i18n.t('SUCCESS_SHIPPED'),
        dataIndex: 'count_success',
        key: 'count_success',
        slots: {customRender: "count_success"},
        width: 100,
      },

      {
        title: i18n.t('money_spent'),
        dataIndex: 'total_amount',
        key: 'total_amount',
        slots: {customRender: "total_amount"},
        width: 100,
      },
      {
        title: i18n.t('last_order_created'),
        dataIndex: 'last_created_at',
        key: 'last_created_at',
        slots: {customRender: "last_created_at"},
        width: 100,
      },
      {
        title: i18n.t('STAFF_CREATE'),
        dataIndex: 'created_by',
        key: 'created_by',
        slots: {customRender: "created_by"},
        width: 100,
      },
      // {
      //   title: i18n.t('Đơn hoàn'),
      //   dataIndex: 'order_from_new_customer_percent',
      //   key: 'order_from_new_customer_percent',
      //   slots: {customRender: "order_not_ads_percent"},
      //   width: 100,
      // },
      {
        title: i18n.t('CREATED_TITLE'),
        dataIndex: 'created_at',
        key: 'created_at',
        slots: {customRender: "created_at"},
        width: 100,
      },
    ];






    const splineChart = ref()


    const handleReport = () => {
      // fetchReportComment();
      // fetchReportMessage();
      // fetchReportNewConversation();
    };

    onMounted(() => {

      fetchShopInfo();
      fetchCusomer({ from_date: rangeTime.value[0].format('YYYY-MM-DDT00:00:00Z'),
        to_date: rangeTime.value[1].format('YYYY-MM-DDT23:59:59Z'),})
      fetchProduct()
    });

    const formatMoney = (val) => {
      return MixUtils.methods.formatMoney(val)
    }

    const calculatorPercent = (a, total) => {
      return Math.round(a / total * 10000) / 100
    }


    const handleProductChange = (val) => {
      selectedProduct.value = val;
      let req ={
        from_date: rangeTime.value[0].format('YYYY-MM-DDT00:00:00Z'),
        to_date: rangeTime.value[1].format('YYYY-MM-DDT23:59:59Z'),
        // page_size: 20,
        // page:1,
        // last_care_in:,
        // last_care_ex:,
        // purchased_amount_gte:totalType.value === 'purchased_amount_gte' ?  Number(totalMoney.value) :0,
        // purchased_amount_lte:totalType.value === 'purchased_amount_lte' ?   Number(totalMoney.value) :0,
        // purchased_amount_eq:totalType.value === 'purchased_amount_eq' ?   Number(totalMoney.value) :0,
        // success_eq:,
        // success_gte:,
        // success_lte:,
        product_ids:selectedProduct.value.join(','),

      }
      console.log(val)
      fetchCusomer(req)
    };


    const onChangeDateTime = (value, dateString) => {
      console.log('Selected Time: ', value);
      console.log('Formatted Selected Time: ', dateString);
      if (value[0].format('HH-mm') === value[1].format('HH-mm')) {
        rangeTime.value = [value[0].startOf('day'), value[1].endOf('day')]

      } else {
        rangeTime.value = value
      }
      let req ={
        from_date: rangeTime.value[0].format('YYYY-MM-DDT00:00:00Z'),
        to_date: rangeTime.value[1].format('YYYY-MM-DDT23:59:59Z'),
        // page_size: 20,
        // page:1,
        // last_care_in:,
        // last_care_ex:,
        // purchased_amount_gte:totalType.value === 'purchased_amount_gte' ?  Number(totalMoney.value) :0,
        // purchased_amount_lte:totalType.value === 'purchased_amount_lte' ?   Number(totalMoney.value) :0,
        // purchased_amount_eq:totalType.value === 'purchased_amount_eq' ?   Number(totalMoney.value) :0,
        // success_eq:,
        // success_gte:,
        // success_lte:,
        // product_ids:,

      }
      fetchCusomer(req)
    };

    const confirmDateTime = (value) => {
      console.log('onOk: ', value);
    };


    return {
      dataShopInfo,
      getTokenPage,
      pagesSelection,
      handleProductChange,
      handleReport,
      selectedProduct,
      splineChart,
      formatMoney,
      calculatorPercent,
      getPages,
      onChangeDateTime,
      confirmDateTime,
      rangeTime,
      columns,
      customerData,
      employeeStore,
      totalType,
      totalMoney,
      getEmployee,
      changMoney,
      changeNumberOrder,
      totalOrder,
      orderType,
      customer,
      carelType,
      handleCare,
      getCare,care,
      product
    };
  },
};
</script>

<style>
.card_view {
  border-radius: 10px;
  margin: 4px;
  overflow: hidden;
}

.count-elements {
  -webkit-tap-highlight-color: transparent;
  -webkit-text-size-adjust: 100%;
  font-family: Roboto, Helvetica, Arial, sans-serif;
  font-variant: tabular-nums;
  line-height: 1.5;
  font-feature-settings: 'tnum';
  font-size: 14px;
  color: #000;
  box-sizing: border-box;
  height: 76px;
  padding: 10px;
  border-top: 1px solid #e0e0e0;
  border-bottom: 1px solid #e0e0e0;
  border-radius: 10px 0px 0px 10px;
  cursor: pointer;
  box-shadow: 0px 2px 10px rgba(51, 51, 51, 0.1);
  border-left: 10px solid rgb(43, 216, 196);
  background-color: rgba(196, 255, 248, 0.23);
  width: 100%;
}

.title {
  font-family: "Roboto" !important;
  font-style: normal !important;
  font-weight: 700 !important;
  font-size: 12px !important;
  line-height: 22px !important;
  color: #000000 !important;
  margin-left: 0px !important;
  margin-top: 0px !important;
  text-align: left !important;
  width: 100% !important;
}

.count {
  text-align: right;
  font-weight: 100;
  font-family: Montserrat-Light;
  font-size: 25px;
  line-height: 30px;
  color: #263C8F;
  float: right;
}

.highcharts-credits {
  display: none;
}
</style>
