import {State} from "@/store/modules/OverView/states";
import {OverviewMutationtypes} from "@/store/modules/OverView/mutation-types";
import {Overview, OverviewFB, OverviewITG, OverviewReport, OverviewZalo} from "@/models/Overview/Overview";


export type Mutations<S = State> = {
    [OverviewMutationtypes.GET_OVERVIEW](state : S,payload: Overview[]|undefined):void,
    [OverviewMutationtypes.GET_OVERVIEW_LOADING](state : S,payload: boolean):void,
    [OverviewMutationtypes.GET_OVERVIEW_FB](state : S,payload: OverviewFB[]|undefined):void,
    [OverviewMutationtypes.GET_OVERVIEW_ITG](state : S,payload: OverviewITG[]|undefined):void,
    [OverviewMutationtypes.GET_OVERVIEW_ZALO](state : S,payload: OverviewZalo[]|undefined):void,
    [OverviewMutationtypes.GET_OVERVIEW_REPORT](state : S,payload: OverviewReport|undefined):void,


}

export const mutations : Mutations ={
    [OverviewMutationtypes.GET_OVERVIEW](state : State,payload: Overview[]|undefined): void{
        console.log(state,payload,'state')
        state.data = payload
        if(payload?.length){
            state.loading = false
        }else {
            state.loading = true
            setTimeout(()=>{
                state.loading = false
            },100000)
        }


    },
    [OverviewMutationtypes.GET_OVERVIEW_LOADING](state : State,payload: boolean): void{
        state.loading = payload

    },
    [OverviewMutationtypes.GET_OVERVIEW_FB](state : State,payload: OverviewFB[]|undefined): void{
        state.dataFb = payload
    },
    [OverviewMutationtypes.GET_OVERVIEW_ITG](state : State,payload: OverviewITG[]|undefined): void{
        state.dataItg = payload
    },
    [OverviewMutationtypes.GET_OVERVIEW_ZALO](state : State,payload: OverviewZalo[]|undefined): void{
        state.dataZalo = payload
    },
    [OverviewMutationtypes.GET_OVERVIEW_REPORT](state : State,payload: OverviewReport|undefined): void{
        state.dataReport = payload
    },

}
