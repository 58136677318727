import { State } from "./states";
import { ReportPageComment } from "@/models/ReportPage/ReportPage";

export type Getters = {
  getReportPageComment(state: State): ReportPageComment;
  getReportPageMessage(state: State): ReportPageComment;
  getReportPageNewConversation(state: State): ReportPageComment;
  getReportCusTomer(state:State):any
  getReportCusTomerOrder(state:State):any
  getReportRevenue(state:State):any
  getTotalRevenue(state:State):any
  getReportEmployee(state:State):any
  getTotalEmployee(state:State):any
};

export const getters: Getters = {
  getReportPageComment(state: State): ReportPageComment {
    if (state.dataComment) {
      return state.dataComment;
    } else {
      return {

        took: 0,
        timed_out: false,
        _shards: { total: 0, successful: 0, failed: 0, },
        hits: { total: 0, max_score: 0, hits: [] },
        aggregations: {
          current: {
            doc_count: 0,
            date_time: {
              buckets: [
                {
                  key: 0,
                  doc_count: 0,
                  comment_count: { value: 0 },
                  conversation_count: { value: 0 },
                },
                {
                  key: 0,
                  doc_count: 0,
                  comment_count: { value: 0 },
                  conversation_count: { value: 0 },
                },
                {
                  key: 0,
                  doc_count: 0,
                  comment_count: { value: 0 },
                  conversation_count: { value: 0 },
                },
                {
                  key: 0,
                  doc_count: 0,
                  comment_count: { value: 0 },
                  conversation_count: { value: 0 },
                },
                {
                  key: 0,
                  doc_count: 0,
                  comment_count: { value: 0 },
                  conversation_count: { value: 0 },
                },
                {
                  key: 0,
                  doc_count: 0,
                  comment_count: { value: 0 },
                  conversation_count: { value: 0 },
                },
                {
                  key: 0,
                  doc_count: 0,
                  comment_count: { value: 0 },
                  conversation_count: { value: 0 },
                },
                {
                  key: 0,
                  doc_count: 0,
                  comment_count: { value: 0 },
                  conversation_count: { value: 0 },
                },
                {
                  key: 0,
                  doc_count: 0,
                  comment_count: { value: 0 },
                  conversation_count: { value: 0 },
                },
                {
                  key: 0,
                  doc_count: 0,
                  comment_count: { value: 0 },
                  conversation_count: { value: 0 },
                },
                {
                  key: 0,
                  doc_count: 0,
                  comment_count: { value: 0 },
                  conversation_count: { value: 0 },
                },
                {
                  key: 0,
                  doc_count: 0,
                  comment_count: { value: 0 },
                  conversation_count: { value: 0 },
                },
                {
                  key: 0,
                  doc_count: 0,
                  comment_count: { value: 0 },
                  conversation_count: { value: 0 },
                },
                {
                  key: 0,
                  doc_count: 0,
                  comment_count: { value: 0 },
                  conversation_count: { value: 0 },
                },
                {
                  key: 0,
                  doc_count: 0,
                  comment_count: { value: 0 },
                  conversation_count: { value: 0 },
                },
                {
                  key: 0,
                  doc_count: 0,
                  comment_count: { value: 0 },
                  conversation_count: { value: 0 },
                }
              ],
            },
            sum_conversation: { value: 0 },
            sum_comment: { value: 0 },
          },
          previous: {
            doc_count: 0,
            sum_phone: {
              doc_count: 0,
              sum_conversation: { value: 0 },
              sum_comment: { value: 0 },
            },
            sum_conversation: { value: 0 },
            sum_comment: { value: 0 },
          },
          current_by_phone: {
            doc_count: 0,
            has_phone: {
              doc_count: 0,
              date_time: {
                buckets: [
                  {
                    key: 0,
                    doc_count: 0,
                    comment_count: { value: 0 },
                    conversation_count: { value: 0 },
                  },
                  {
                    key: 0,
                    doc_count: 0,
                    comment_count: { value: 0 },
                    conversation_count: { value: 0 },
                  },
                  {
                    key: 0,
                    doc_count: 0,
                    comment_count: { value: 0 },
                    conversation_count: { value: 0 },
                  },
                  {
                    key: 0,
                    doc_count: 0,
                    comment_count: { value: 0 },
                    conversation_count: { value: 0 },
                  },
                  {
                    key: 0,
                    doc_count: 0,
                    comment_count: { value: 0 },
                    conversation_count: { value: 0 },
                  },
                  {
                    key: 0,
                    doc_count: 0,
                    comment_count: { value: 0 },
                    conversation_count: { value: 0 },
                  },
                  {
                    key: 0,
                    doc_count: 0,
                    comment_count: { value: 0 },
                    conversation_count: { value: 0 },
                  },
                  {
                    key: 0,
                    doc_count: 0,
                    comment_count: { value: 0 },
                    conversation_count: { value: 0 },
                  },
                  {
                    key: 0,
                    doc_count: 0,
                    comment_count: { value: 0 },
                    conversation_count: { value: 0 },
                  },
                  {
                    key: 0,
                    doc_count: 0,
                    comment_count: { value: 0 },
                    conversation_count: { value: 0 },
                  },
                  {
                    key: 0,
                    doc_count: 0,
                    comment_count: { value: 0 },
                    conversation_count: { value: 0 },
                  },
                  {
                    key: 0,
                    doc_count: 0,
                    comment_count: { value: 0 },
                    conversation_count: { value: 0 },
                  },
                  {
                    key: 0,
                    doc_count: 0,
                    comment_count: { value: 0 },
                    conversation_count: { value: 0 },
                  },
                  {
                    key: 0,
                    doc_count: 0,
                    comment_count: { value: 0 },
                    conversation_count: { value: 0 },
                  },
                  {
                    key: 0,
                    doc_count: 0,
                    comment_count: { value: 0 },
                    conversation_count: { value: 0 },
                  },
                  {
                    key: 0,
                    doc_count: 0,
                    comment_count: { value: 0 },
                    conversation_count: { value: 0 },
                  }
                ],
              },
              sum_conversation: { value: 0 },
              sum_comment: { value: 0 },
            },
          },
        },


    };
    }
  },
  getReportPageMessage(state: State): ReportPageComment {
    if (state.dataMessage) {
      return state.dataMessage;
    } else {
      return {

        took: 0,
        timed_out: false,
        _shards: { total: 0, successful: 0, failed: 0, },
        hits: { total: 0, max_score: 0, hits: [] },
        aggregations: {
          current: {
            doc_count: 0,
            date_time: {
              buckets: [
                {
                  key: 0,
                  doc_count: 0,
                  comment_count: { value: 0 },
                  conversation_count: { value: 0 },
                },
                {
                  key: 0,
                  doc_count: 0,
                  comment_count: { value: 0 },
                  conversation_count: { value: 0 },
                },
                {
                  key: 0,
                  doc_count: 0,
                  comment_count: { value: 0 },
                  conversation_count: { value: 0 },
                },
                {
                  key: 0,
                  doc_count: 0,
                  comment_count: { value: 0 },
                  conversation_count: { value: 0 },
                },
                {
                  key: 0,
                  doc_count: 0,
                  comment_count: { value: 0 },
                  conversation_count: { value: 0 },
                },
                {
                  key: 0,
                  doc_count: 0,
                  comment_count: { value: 0 },
                  conversation_count: { value: 0 },
                },
                {
                  key: 0,
                  doc_count: 0,
                  comment_count: { value: 0 },
                  conversation_count: { value: 0 },
                },
                {
                  key: 0,
                  doc_count: 0,
                  comment_count: { value: 0 },
                  conversation_count: { value: 0 },
                },
                {
                  key: 0,
                  doc_count: 0,
                  comment_count: { value: 0 },
                  conversation_count: { value: 0 },
                },
                {
                  key: 0,
                  doc_count: 0,
                  comment_count: { value: 0 },
                  conversation_count: { value: 0 },
                },
                {
                  key: 0,
                  doc_count: 0,
                  comment_count: { value: 0 },
                  conversation_count: { value: 0 },
                },
                {
                  key: 0,
                  doc_count: 0,
                  comment_count: { value: 0 },
                  conversation_count: { value: 0 },
                },
                {
                  key: 0,
                  doc_count: 0,
                  comment_count: { value: 0 },
                  conversation_count: { value: 0 },
                },
                {
                  key: 0,
                  doc_count: 0,
                  comment_count: { value: 0 },
                  conversation_count: { value: 0 },
                },
                {
                  key: 0,
                  doc_count: 0,
                  comment_count: { value: 0 },
                  conversation_count: { value: 0 },
                },
                {
                  key: 0,
                  doc_count: 0,
                  comment_count: { value: 0 },
                  conversation_count: { value: 0 },
                }
              ],
            },
            sum_conversation: { value: 0 },
            sum_comment: { value: 0 },
          },
          previous: {
            doc_count: 0,
            sum_phone: {
              doc_count: 0,
              sum_conversation: { value: 0 },
              sum_comment: { value: 0 },
            },
            sum_conversation: { value: 0 },
            sum_comment: { value: 0 },
          },
          current_by_phone: {
            doc_count: 0,
            has_phone: {
              doc_count: 0,
              date_time: {
                buckets: [
                  {
                    key: 0,
                    doc_count: 0,
                    comment_count: { value: 0 },
                    conversation_count: { value: 0 },
                  },
                  {
                    key: 0,
                    doc_count: 0,
                    comment_count: { value: 0 },
                    conversation_count: { value: 0 },
                  },
                  {
                    key: 0,
                    doc_count: 0,
                    comment_count: { value: 0 },
                    conversation_count: { value: 0 },
                  },
                  {
                    key: 0,
                    doc_count: 0,
                    comment_count: { value: 0 },
                    conversation_count: { value: 0 },
                  },
                  {
                    key: 0,
                    doc_count: 0,
                    comment_count: { value: 0 },
                    conversation_count: { value: 0 },
                  },
                  {
                    key: 0,
                    doc_count: 0,
                    comment_count: { value: 0 },
                    conversation_count: { value: 0 },
                  },
                  {
                    key: 0,
                    doc_count: 0,
                    comment_count: { value: 0 },
                    conversation_count: { value: 0 },
                  },
                  {
                    key: 0,
                    doc_count: 0,
                    comment_count: { value: 0 },
                    conversation_count: { value: 0 },
                  },
                  {
                    key: 0,
                    doc_count: 0,
                    comment_count: { value: 0 },
                    conversation_count: { value: 0 },
                  },
                  {
                    key: 0,
                    doc_count: 0,
                    comment_count: { value: 0 },
                    conversation_count: { value: 0 },
                  },
                  {
                    key: 0,
                    doc_count: 0,
                    comment_count: { value: 0 },
                    conversation_count: { value: 0 },
                  },
                  {
                    key: 0,
                    doc_count: 0,
                    comment_count: { value: 0 },
                    conversation_count: { value: 0 },
                  },
                  {
                    key: 0,
                    doc_count: 0,
                    comment_count: { value: 0 },
                    conversation_count: { value: 0 },
                  },
                  {
                    key: 0,
                    doc_count: 0,
                    comment_count: { value: 0 },
                    conversation_count: { value: 0 },
                  },
                  {
                    key: 0,
                    doc_count: 0,
                    comment_count: { value: 0 },
                    conversation_count: { value: 0 },
                  },
                  {
                    key: 0,
                    doc_count: 0,
                    comment_count: { value: 0 },
                    conversation_count: { value: 0 },
                  }
                ],
              },
              sum_conversation: { value: 0 },
              sum_comment: { value: 0 },
            },
          },
        },


    };
    }
  },
  getReportPageNewConversation(state: State): ReportPageComment {
    if (state.dataNewConversation) {
      return state.dataNewConversation;
    } else {
      return {

        took: 0,
        timed_out: false,
        _shards: { total: 0, successful: 0, failed: 0, },
        hits: { total: 0, max_score: 0, hits: [] },
        aggregations: {
          current: {
            doc_count: 0,
            date_time: {
              buckets: [
                {
                  key: 0,
                  doc_count: 0,
                  comment_count: { value: 0 },
                  conversation_count: { value: 0 },
                },
                {
                  key: 0,
                  doc_count: 0,
                  comment_count: { value: 0 },
                  conversation_count: { value: 0 },
                },
                {
                  key: 0,
                  doc_count: 0,
                  comment_count: { value: 0 },
                  conversation_count: { value: 0 },
                },
                {
                  key: 0,
                  doc_count: 0,
                  comment_count: { value: 0 },
                  conversation_count: { value: 0 },
                },
                {
                  key: 0,
                  doc_count: 0,
                  comment_count: { value: 0 },
                  conversation_count: { value: 0 },
                },
                {
                  key: 0,
                  doc_count: 0,
                  comment_count: { value: 0 },
                  conversation_count: { value: 0 },
                },
                {
                  key: 0,
                  doc_count: 0,
                  comment_count: { value: 0 },
                  conversation_count: { value: 0 },
                },
                {
                  key: 0,
                  doc_count: 0,
                  comment_count: { value: 0 },
                  conversation_count: { value: 0 },
                },
                {
                  key: 0,
                  doc_count: 0,
                  comment_count: { value: 0 },
                  conversation_count: { value: 0 },
                },
                {
                  key: 0,
                  doc_count: 0,
                  comment_count: { value: 0 },
                  conversation_count: { value: 0 },
                },
                {
                  key: 0,
                  doc_count: 0,
                  comment_count: { value: 0 },
                  conversation_count: { value: 0 },
                },
                {
                  key: 0,
                  doc_count: 0,
                  comment_count: { value: 0 },
                  conversation_count: { value: 0 },
                },
                {
                  key: 0,
                  doc_count: 0,
                  comment_count: { value: 0 },
                  conversation_count: { value: 0 },
                },
                {
                  key: 0,
                  doc_count: 0,
                  comment_count: { value: 0 },
                  conversation_count: { value: 0 },
                },
                {
                  key: 0,
                  doc_count: 0,
                  comment_count: { value: 0 },
                  conversation_count: { value: 0 },
                },
                {
                  key: 0,
                  doc_count: 0,
                  comment_count: { value: 0 },
                  conversation_count: { value: 0 },
                }
              ],
            },
            sum_conversation: { value: 0 },
            sum_comment: { value: 0 },
          },
          previous: {
            doc_count: 0,
            sum_phone: {
              doc_count: 0,
              sum_conversation: { value: 0 },
              sum_comment: { value: 0 },
            },
            sum_conversation: { value: 0 },
            sum_comment: { value: 0 },
          },
          current_by_phone: {
            doc_count: 0,
            has_phone: {
              doc_count: 0,
              date_time: {
                buckets: [
                  {
                    key: 0,
                    doc_count: 0,
                    comment_count: { value: 0 },
                    conversation_count: { value: 0 },
                  },
                  {
                    key: 0,
                    doc_count: 0,
                    comment_count: { value: 0 },
                    conversation_count: { value: 0 },
                  },
                  {
                    key: 0,
                    doc_count: 0,
                    comment_count: { value: 0 },
                    conversation_count: { value: 0 },
                  },
                  {
                    key: 0,
                    doc_count: 0,
                    comment_count: { value: 0 },
                    conversation_count: { value: 0 },
                  },
                  {
                    key: 0,
                    doc_count: 0,
                    comment_count: { value: 0 },
                    conversation_count: { value: 0 },
                  },
                  {
                    key: 0,
                    doc_count: 0,
                    comment_count: { value: 0 },
                    conversation_count: { value: 0 },
                  },
                  {
                    key: 0,
                    doc_count: 0,
                    comment_count: { value: 0 },
                    conversation_count: { value: 0 },
                  },
                  {
                    key: 0,
                    doc_count: 0,
                    comment_count: { value: 0 },
                    conversation_count: { value: 0 },
                  },
                  {
                    key: 0,
                    doc_count: 0,
                    comment_count: { value: 0 },
                    conversation_count: { value: 0 },
                  },
                  {
                    key: 0,
                    doc_count: 0,
                    comment_count: { value: 0 },
                    conversation_count: { value: 0 },
                  },
                  {
                    key: 0,
                    doc_count: 0,
                    comment_count: { value: 0 },
                    conversation_count: { value: 0 },
                  },
                  {
                    key: 0,
                    doc_count: 0,
                    comment_count: { value: 0 },
                    conversation_count: { value: 0 },
                  },
                  {
                    key: 0,
                    doc_count: 0,
                    comment_count: { value: 0 },
                    conversation_count: { value: 0 },
                  },
                  {
                    key: 0,
                    doc_count: 0,
                    comment_count: { value: 0 },
                    conversation_count: { value: 0 },
                  },
                  {
                    key: 0,
                    doc_count: 0,
                    comment_count: { value: 0 },
                    conversation_count: { value: 0 },
                  },
                  {
                    key: 0,
                    doc_count: 0,
                    comment_count: { value: 0 },
                    conversation_count: { value: 0 },
                  }
                ],
              },
              sum_conversation: { value: 0 },
              sum_comment: { value: 0 },
            },
          },
        },


    };
    }
  },
  getReportCusTomer(state:State):any{
    if (state.dataCustomer) {
      return state.dataCustomer;
    } else {
      return [];
    }
  },
  getReportCusTomerOrder(state:State):any{
    if (state.dataCustomerOrder) {
      return state.dataCustomerOrder;
    } else {
      return [];
    }
  },
  getReportRevenue(state:State):any{
    if (state.dataRevenue) {
      return state.dataRevenue;
    } else {
      return [];
    }
  },
  getTotalRevenue(state:State):any{
    if (state.dataRevenueTotal) {
      return state.dataRevenueTotal;
    } else {
      return [];
    }
  },
  getReportEmployee(state:State):any{
    if (state.dataReportEmployee) {
      return state.dataReportEmployee;
    } else {
      return [];
    }
  },
  getTotalEmployee(state:State):any{
    if (state.dataEmployeeTotal) {
      return state.dataEmployeeTotal;
    } else {
      return [];
    }
  }

};
