<template>
  <div style="height: 100vh; display: flex; justify-content: center; margin-top: 24px">

    <a-result
        v-if="success"
        status="success"
        title="Mua vé thành công!"
        sub-title="Thông tin vé sẽ được gửi qua email. Vui lòng kiểm tra email, nếu không thấy trong email chính bạn kiểm tra hòm thư spam. "
    >
      <template #extra>
        <a-button @click="reBuy" key="buy" type="primary">Mua tiếp</a-button>
      </template>
    </a-result>
    <a-form
        v-else
      ref="formRef"
      :model="formState"
          :rules="rules"
          :label-col="labelCol" :wrapper-col="wrapperCol"
          @validate="handleValidate"

  >
<!--      <a-form-item label="Họ tên người giám hộ" name="name_ref">-->
<!--        <a-input v-model:value="formState.name_ref" />-->
<!--      </a-form-item>-->
<!--      <a-form-item label="Số điện thoại người giám hộ" name="phone_ref">-->
<!--        <a-input v-model:value="formState.phone_ref" />-->
<!--      </a-form-item>-->
<!--      <a-form-item label="Email người giám hộ" name="email_ref">-->
<!--        <a-input v-model:value="formState.email_ref" />-->
<!--      </a-form-item>-->

    <a-form-item label="Họ tên" name="name">
      <a-input v-model:value="formState.name" />
    </a-form-item>
    <a-form-item label="Số điện thoại" name="phone">
      <a-input v-model:value="formState.phone" />
    </a-form-item>
    <a-form-item label="Email" name="email">
      <a-input v-model:value="formState.email" />
    </a-form-item>
    <a-form-item label="Loại vé" name="ticket_type">
      <a-radio-group v-model:value="formState.ticket_type">
        <a-radio :value="7">Vé thường ($100)</a-radio>
        <a-radio :value="14">Vé Vip ($200)</a-radio>
      </a-radio-group>
    </a-form-item>
    <a-form-item :wrapper-col="{ span: 14, offset: 4 }">
      <a-button type="primary" @click="onSubmit">Đăng ký</a-button>
    </a-form-item>
  </a-form>
  </div>
</template>
<script lang="ts">
import {defineComponent, reactive, ref, toRaw} from 'vue';
import type { UnwrapRef } from 'vue';
import type { Rule } from 'ant-design-vue/es/form';
import {ValidateErrorEntity} from "ant-design-vue/es/form/interface";
import {FormFlowInformation} from "@/models/Flows/FlowForm";
import APIs from "@/APIs/APIs";

interface FormState {
  name: string;
  phone: string;
  email: string;
  // name_ref: string;
  // phone_ref: string;
  // email_ref: string;
  ticket_type: number;
  event_id: number;
}
export default defineComponent({
  name: "LandingPage",
  setup() {
    const formRef = ref();
    const success = ref(false);

    const formState: UnwrapRef<FormState> = reactive({
      name: "",
      phone: "",
      email: "",
      ticket_type: 7,
      event_id: 1,
      // name_ref: "",
      // phone_ref: "",
      // email_ref: "",
    });
    const rules: Record<string, Rule[]> = {
      name: [{ required: true,  trigger: 'blur' , message: 'Họ tên là bắt buộc'}],
      phone: [{ required: true, type: 'regexp', trigger: 'blur', pattern: /(84|0[3|5|7|8|9])+([0-9]{8})/,    message: 'Số điện thoại là bắt buộc' }],
      email: [{ required: true, type: 'email',   trigger: 'blur', message: 'Email là bắt buộc' }],
      // name_ref: [{ required: true,  trigger: 'blur' , message: 'Họ tên người giám hộ là bắt buộc'}],
      // phone_ref: [{ required: true, type: 'regexp', trigger: 'blur', pattern: /(84|0[3|5|7|8|9])+([0-9]{8})/,    message: 'Số điện thoại người giám hộ là bắt buộc' }],
      // email_ref: [{ required: true, type: 'email',   trigger: 'blur', message: 'Email người giám hộ là bắt buộc' }],
    };
    const onSubmit = () => {
          formRef.value
              .validate()
              .then(async(values: any) => {
                console.log(values)

                const response = await APIs.init().ladingPageSingle({
                    shop_id: 121212,
                    event_id: 1,
                    full_name: values.name,
                    email: values.email,
                    phone: values.phone,
                    ticket_type_id: values.ticket_type,
                    event_token: '9d71dc2e01f0b5d71be1d626d77d3aef'
                });
                if (response.status === 200 && response.data && response.data.data) {
                  console.log(response)
                  // window.open(response.data.data, '_blank');
                  success.value = true
                } else if(response.data?.status === 200){
                  success.value = true
                }
              }) .catch((error: ValidateErrorEntity<FormFlowInformation>) => {
            console.log("error", error);
          });
      console.log('submit!', toRaw(formState));
    };
    const handleValidate = (args: any) => {
      console.log(args);
    };
    const reBuy = () => {
      success.value = false
    };
    return {
      labelCol: { style: { width: '170px' } },
      wrapperCol: {  },
      formState,
      onSubmit,
      rules,
      success,
      handleValidate,
      reBuy,
      formRef,
    };
  },
});
</script>
