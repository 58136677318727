<template>
  <a-layout-content>
    <a-row
      style="
        justify-content: space-between;
        height: 56px;
        align-items: center;
        margin: 0 19px;
        background-color: white;
      "
    >
      <a-typography-title style="padding-left: 24px" :level="4"
        >Sales</a-typography-title
      >
      <a-row>
        <a-button class="btn">All day</a-button>
        <a-button class="btn">All week</a-button>
        <a-button class="btn">All month</a-button>
        <a-button class="btn">All year</a-button>
        <a-space direction="vertical" style="width: 256px; padding-right: 24px">
          <a-range-picker v-model:value="value3" />
        </a-space>
      </a-row>
    </a-row>
    <a-row style="margin: 20px 19px">
      <a-col
        :span="5"
        style="
          background-color: white;
          min-width: 278px;
          height: 182px;
          margin-right: 24px;
        "
      >
        <a-row style="padding: 24px">
          <a-row
            style="
              justify-content: space-between;
              align-items: center;
              width: 100%;
            "
          >
            <a-typography-text type="secondary">Total Sales</a-typography-text>
            <a-tooltip placement="topLeft" title="Giới thiệu">
              <InfoCircleOutlined />
            </a-tooltip>
          </a-row>
          <a-row
            style="
              justify-content: space-between;
              align-items: center;
              width: 100%;
            "
          >
            <a-typography-title style="margin: 0" :level="3"
              >5 tỷ</a-typography-title
            >
          </a-row>
          <a-row
            style="
              align-items: center;
              margin-bottom: 18px;
              height: 44px;
              width: 100%;
            "
          >
            <a-typography-text>Tăng 10%</a-typography-text>
            <CaretUpOutlined style="color: #52c41a; margin-left: 6px" />
          </a-row>
          <a-row style="align-items: center; width: 100%">
            <a-typography-text>Daily Sales</a-typography-text>
            <a-typography-text style="margin-left: 8px"
              >12,423</a-typography-text
            >
          </a-row>
        </a-row>
      </a-col>
      <a-col
        :span="5"
        style="
          background-color: white;
          min-width: 278px;
          height: 182px;
          margin-right: 24px;
        "
      >
        <a-row style="padding: 24px">
          <a-row
            style="
              justify-content: space-between;
              align-items: center;
              width: 100%;
            "
          >
            <a-typography-text type="secondary"
              >Tổng đơn hàng</a-typography-text
            >
            <a-tooltip placement="topLeft" title="Giới thiệu">
              <InfoCircleOutlined />
            </a-tooltip>
          </a-row>
          <a-row
            style="
              justify-content: space-between;
              align-items: center;
              width: 100%;
            "
          >
            <a-typography-title style="margin: 0" :level="3"
              >8,846</a-typography-title
            >
          </a-row>
          <a-row
            style="
              align-items: center;
              width: 100%;
              margin-bottom: 18px;
              height: 44px;
            "
          >
            <TinyAreaChart />
          </a-row>
          <a-row style="align-items: center; width: 100%">
            <a-typography-text>Đơn hàng ngày</a-typography-text>
            <a-typography-text style="margin-left: 8px"
              >1,234</a-typography-text
            >
          </a-row>
        </a-row>
      </a-col>
      <a-col
        :span="5"
        style="
          background-color: white;
          min-width: 278px;
          height: 182px;
          margin-right: 24px;
        "
      >
        <a-row style="padding: 24px">
          <a-row
            style="
              justify-content: space-between;
              align-items: center;
              width: 100%;
            "
          >
            <a-typography-text type="secondary">Payments</a-typography-text>
            <a-tooltip placement="topLeft" title="Giới thiệu">
              <InfoCircleOutlined />
            </a-tooltip>
          </a-row>
          <a-row
            style="
              justify-content: space-between;
              align-items: center;
              width: 100%;
            "
          >
            <a-typography-title style="margin: 0" :level="3"
              >6,560</a-typography-title
            >
          </a-row>
          <a-row style="align-items: center; margin-bottom: 18px; width: 100%">
            <TinyChart />
          </a-row>
          <a-row style="align-items: center; width: 100%">
            <a-typography-text>Conversion Rate</a-typography-text>
            <a-typography-text style="margin-left: 8px">60%</a-typography-text>
          </a-row>
        </a-row>
      </a-col>
      <a-col
        :span="5"
        style="background-color: white; min-width: 278px; height: 182px"
      >
        <a-row style="padding: 24px">
          <a-row
            style="
              justify-content: space-between;
              align-items: center;
              width: 100%;
            "
          >
            <a-typography-text type="secondary"
              >Operational Effect</a-typography-text
            >
            <a-tooltip placement="topLeft" title="Giới thiệu">
              <InfoCircleOutlined />
            </a-tooltip>
          </a-row>
          <a-row
            style="
              justify-content: space-between;
              align-items: center;
              width: 100%;
            "
          >
            <a-typography-title style="margin: 0" :level="3"
              >78%
            </a-typography-title>
          </a-row>
          <a-row
            style="
              align-items: center;
              width: 100%;
              margin-bottom: 18px;
              height: 44px;
            "
          >
            <a-progress
              stroke-linecap="square"
              :percent="78"
              :showInfo="false"
              strokeColor="#13c2c2"
            />
          </a-row>
          <a-row style="align-items: center; width: 100%">
            <a-typography-text>WoW Change</a-typography-text>
            <a-typography-text style="padding-left: 6px">
              12%</a-typography-text
            >
            <CaretUpOutlined style="color: #52c41a; margin-left: 3px" />
            <a-typography-text style="margin-left: 16px"
              >DoD Change</a-typography-text
            >
          </a-row>
        </a-row>
      </a-col>
    </a-row>
    <a-row
      style="
        margin: 24px 19px;
        justify-content: center;
        background-color: white;
      "
    >
      <a-row style="padding: 24px; flex-direction: column; width: 757px">
        <a-typography-text style="margin-bottom: 20px"
          >Stores Sales Trend</a-typography-text
        >
        <ColumnPlot />
      </a-row>
    </a-row>
    <a-row style="margin: 20px 19px">
      <a-col :span="11" style="background-color: white">
        <a-row style="padding: 24px">
          <a-row
            style="
              padding: 0 24px;
              justify-content: space-between;
              align-items: center;
            "
          >
            <a-typography-text strong>Sales Ranking</a-typography-text>
          </a-row>
          <a-row style="margin-top: 24px; justify-content: flex-end">
            <a-table
              :columns="columns"
              :data-source="data"
              style="width: 100%"
              :pagination="false"
            >
              <template #seller="{ record }">
                <a>{{ record.seller }}</a>
              </template>
            </a-table>

            <a-pagination
              v-model:current="current"
              :total="50"
              show-less-items
            />
          </a-row>
        </a-row>
      </a-col>
      <a-col :span="12" style="margin-left: 13px; background-color: white">
        <a-row style="padding: 24px">
          <a-row style="justify-content: space-between; width: 100%">
            <a-typography-text strong
              >The Proportion Of Sales</a-typography-text
            >
            <a-row style="align-items: center">
              <a-button>All</a-button>
              <a-button>Online</a-button>
              <a-button>Stores</a-button>
              <EllipsisOutlined style="margin-left: 18px" />
            </a-row>
          </a-row>
          <a-row style="padding: 36px 0">
            <a-typography-title :level="5">Sales</a-typography-title>
          </a-row>
          <a-row style="width: 100%">
            <PieChart />
          </a-row>
        </a-row>
      </a-col>
    </a-row>
  </a-layout-content>
</template>

<script lang="ts">
import { ref, onMounted } from "vue";
import {
  InfoCircleOutlined,
  CaretUpOutlined,
  EllipsisOutlined,
} from "@ant-design/icons-vue";
import { Moment } from "moment";
// import ColumnPlot from "./ColumChart.vue";
// import TinyChart from "./TinyChart.vue";
// import TinyAreaChart from "@/views/Dashboard/TinyAreaChart.vue";
// import PieChart from "@/views/Dashboard/PieChart.vue";
import APIs from "@/APIs/APIs";
const columns = [
  {
    title: "Stt",
    dataIndex: "stt",
    key: "stt",
    width: "10%",
  },
  {
    title: "Seller",
    slots: { customRender: "seller" },
    key: "seller",
    width: "20%",
  },
  {
    title: "Amount",
    dataIndex: "amount",
    key: "amount",
    width: "80%",
    align: "right",
  },
];

const data = [
  {
    key: "1",
    stt: "1",
    seller: "Thu Ky Lee",
    amount: "668",
  },
  {
    key: "2",
    stt: "2",
    seller: "Thu Ky Lee",
    amount: "668",
  },
  {
    key: "3",
    stt: "3",
    seller: "Thu Ky Lee",
    amount: "668",
  },
  {
    key: "4",
    stt: "4",
    seller: "Thu Ky Lee",
    amount: "668",
  },
  {
    key: "5",
    stt: "5",
    seller: "Thu Ky Lee",
    amount: "668",
  },
];
export default {
  components: {
    InfoCircleOutlined,
    CaretUpOutlined,
    EllipsisOutlined,
    // TinyChart,
    // ColumnPlot,
    // TinyAreaChart,
    // PieChart,
  },
  setup() {
    return {
      activeKey: ref("1"),
      value3: ref<Moment[]>([]),
      columns,
      data,
      current: ref(1),
    };
  },
};
</script>
<style lang="scss" scoped>
.btn {
  border: none;
  margin-right: 10px;
  background-color: transparent;
  box-shadow: none;
}
</style>
