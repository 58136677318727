<template>
  <a-layout style="min-height: 100vh" theme="light">
    <a-layout-sider v-model:collapsed="collapsed" collapsible theme="light">
      <perfect-scrollbar>
        <div class="logo">
          <img :src="collapsed?logo:logoHorizontal"/>
        </div>
        <a-menu v-model:selectedKeys="selectedKeys" mode="inline" theme="light">

          <a-menu-item key="10" v-if="getRole(modules.overview)?.is_visible" >
            <dashboard-outlined />
           <span>
             <router-link :to="RouterEndpoint.OVERVIEW.path" style="font-weight: bold">{{ $t('OVERVIEW') }}</router-link>
           </span>
          </a-menu-item>


          <a-menu-item-group title="CRM">
            <a-sub-menu v-if="getRole(modules.customers)?.is_visible" key="sub_0" @click="goToCollapsePath(RouterEndpoint.CUSTOMER_MANAGEMENT.path)">
              <template #title @click="visible=false">
                        <span>
                          <UsergroupAddOutlined/>
                          <span style="font-weight: bold">{{ $t("CUSTOMER") }}</span>
                         </span>
              </template>
              <a-menu-item v-if="getRole(modules.customers)?.is_visible" key="0_1" @click="visible=false"
              >
                <router-link :to="RouterEndpoint.CUSTOMER_MANAGEMENT.path">{{ $t('CUSTOMERS') }}</router-link>
              </a-menu-item>
              <a-menu-item  v-if="getRole(modules.customer_tag)?.is_visible" key="0_2" @click="visible=false">
                <router-link :to="RouterEndpoint.CUSTOMER_TAGS.path">{{ $t('SALE_TAGS') }}</router-link>
              </a-menu-item>
              <a-menu-item v-if="getRole(modules.customer_care)?.is_visible" key="0_3" @click="visible=false">
                <router-link :to="RouterEndpoint.CUSTOMER_CARES.path">{{ $t('CARE_STATUS_LIST') }}</router-link>
              </a-menu-item>
              <a-menu-item v-if="getRole(modules.career)?.is_visible" key="0_5" @click="visible=false">
                <router-link :to="RouterEndpoint.CUSTOMER_CAREER.path">{{ $t('CUSTOMER_CAREER') }}</router-link>
              </a-menu-item>
              <a-menu-item v-if="getRole(modules.schedules)?.is_visible" key="0_4" @click="visible=false">
                <router-link :to="RouterEndpoint.CUSTOMER_SCHEDULER.path">{{
                    $t(RouterEndpoint.CUSTOMER_SCHEDULER.name)
                  }}
                </router-link>
              </a-menu-item>
              <a-menu-item v-if="getRole(modules.extra_column)?.is_visible" key="0_6" @click="visible=false">
                <router-link :to="RouterEndpoint.EXTRA_COLUMN.path">{{
                    $t(RouterEndpoint.EXTRA_COLUMN.name)
                  }}
                </router-link>
              </a-menu-item>
<!--              <a-menu-item v-if="getRole(modules.config)?.is_visible" key="0_4" @click="visible=false">-->
<!--                <router-link :to="RouterEndpoint.CONFIG_CUSTOMER.path">{{-->
<!--                    $t(RouterEndpoint.CONFIG_CUSTOMER.name)-->
<!--                  }}-->
<!--                </router-link>-->
<!--              </a-menu-item>-->
            </a-sub-menu>

            <a-sub-menu v-if="getRole(modules.employee)?.is_visible" key="sub_1" @click="goToCollapsePath(RouterEndpoint.EMPLOYEE.path)">

              <template #title @click="visible=false">
                        <span>
                         <UserSwitchOutlined /><span style="font-weight: bold">{{ $t("EMPLOYEE") }}</span>
                         </span>
              </template>
              <a-menu-item key="22" v-if="getRole(modules.employee)?.is_visible"
              >
                <router-link :to="RouterEndpoint.EMPLOYEE.path" style="font-weight: bold">{{ $t('EMPLOYEE') }}</router-link>
              </a-menu-item>
              <a-menu-item key="23"  v-if="getRole(modules.role)?.is_visible"
              >
                <router-link :to="RouterEndpoint.ROLE.path" style="font-weight: bold">{{ $t('ROLE') }}</router-link>
              </a-menu-item>
              <a-menu-item key="24"  v-if="getRole(modules.departments)?.is_visible"
              >
                <router-link :to="RouterEndpoint.DEPARTMENT.path" style="font-weight: bold">{{ $t('DEPARTMENT') }}</router-link>
              </a-menu-item>
            </a-sub-menu>
          </a-menu-item-group>
          <a-menu-item-group  :title="$t('marketing')">
            <a-sub-menu key="sub_2" @click="goToCollapsePath(RouterEndpoint.FLOWS.path)">
              <template #title>
                        <span>
                           <node-expand-outlined/>
                           <span style="font-weight: bold">{{ $t('SLIDING_MENU_FLOW_CONTEXT') }}</span>
                         </span>
              </template>
              <a-menu-item v-if="getRole(modules.flows)?.is_visible" key="sub_2_0" @click="visible=false">
                <router-link :to="RouterEndpoint.FLOWS.path">
                  {{ $t('SLIDING_SUB_MENU_FLOW_CONTEXT') }}
                </router-link>
              </a-menu-item>
              <a-menu-item v-if="getRole(modules.flow_tag)?.is_visible" key="sub_2_1" @click="visible=false">
                <router-link :to="RouterEndpoint.FLOW_TAGS.path">{{ $t('FLOW_TAGS') }}</router-link>
              </a-menu-item>
              <a-menu-item  key="sub_2_2" @click="visible=false">
                <router-link :to="RouterEndpoint.AUTOMATION_TRIGGER.path">Flow (New)</router-link>
              </a-menu-item>
            </a-sub-menu>
            <!--            <a-sub-menu key="sub_3" @click="goToCollapsePath(RouterEndpoint.VIRAL_LIST.path)">-->
            <!--              <template #title>-->
            <!--                        <span>-->
            <!--                          <radar-chart-outlined/>-->
            <!--                          <span style="font-weight: bold">{{ $t('SUB_MENU_VIRAL') }}</span>-->
            <!--                        </span>-->
            <!--              </template>-->
            <!--              <a-menu-item key="sub_3_0" @click="visible=false">-->
            <!--                <router-link :to="RouterEndpoint.VIRAL_LIST.path">-->
            <!--                  Viral list-->
            <!--                </router-link>-->
            <!--              </a-menu-item>-->
            <!--              <a-menu-item key="sub_3_1" @click="visible=false">-->
            <!--                <router-link :to="RouterEndpoint.VIRAL_TAGS.path">{{ $t('VIRAL_TAGS') }}</router-link>-->
            <!--              </a-menu-item>-->
            <!--            </a-sub-menu>-->
            <!--            <a-menu-item key="13" @click="goToPath(RouterEndpoint.AUTO_INBOX.path)">-->
            <!--              <notification-outlined/>-->
            <!--              <span style="font-weight: bold">{{ $t('SUB_MENU_BROADCAST') }}</span>-->
            <!--            </a-menu-item>-->
            <a-sub-menu key="sub_5" @click="goToCollapsePath(RouterEndpoint.TRIGGERS.path)">
              <template #title>
                        <span>
                          <aim-outlined/>
                          <span style="font-weight: bold">{{ $t('CONFIG_ACTIVE_MESSAGE') }}
                          </span>
                        </span>
              </template>
              <a-menu-item v-if="getRole(modules.trigger)?.is_visible" key="sub_5_0" @click="visible=false">
                <router-link :to="RouterEndpoint.TRIGGERS.path">{{ $t('SLIDING_SUB_MENU_TRIGGER') }}</router-link>
              </a-menu-item>
              <a-menu-item v-if="getRole(modules.trigger_tag)?.is_visible"  key="sub_5_1" @click="visible=false">
                <router-link :to="RouterEndpoint.TRIGGER_TAGS.path">{{ $t('TRIGGER_TAG') }}</router-link>
              </a-menu-item>
              <a-menu-item  v-if="getRole(modules.trigger_history)?.is_visible" key="sub_5_2" @click="visible=false">
                <router-link :to="RouterEndpoint.TRIGGER_HISTORY.path">{{ $t('HISTORY') }}</router-link>
              </a-menu-item>
              <a-menu-item   key="sub_5_3" @click="visible=false">
                <router-link :to="RouterEndpoint.VIRAL_HISTORY.path">{{ $t('VIRAL_HISTORY') }}</router-link>
              </a-menu-item>
            </a-sub-menu>
            <a-menu-item key="sub_6123123"
            >
              <download-outlined />
              <span>
                <router-link :to="RouterEndpoint.AUTO_INBOX_DOWNLOAD.path" style="font-weight: bold">{{ $t('AUTO_INBOX_DOWNLOAD') }}</router-link>
                </span>
            </a-menu-item>
          </a-menu-item-group>

          <a-menu-item-group :title="$t('REPORT')">
            <a-sub-menu  key="sub_10" @click="goToCollapsePath(RouterEndpoint.REPORT_PAGE.path)">
              <template #title @click="visible=false">
                    <span>
                      <BarChartOutlined/>
                      <span style="font-weight: bold">{{ $t("REPORT") + ' pages'}}</span>
                    </span>
              </template>
              <a-menu-item  v-if="getRole(modules.report_overview)?.is_visible"  key="10_1" @click="visible=false">
                <router-link :to="RouterEndpoint.REPORT_OVERVIEW.path">{{ $t('REPORT_OVERVIEW') }}</router-link>
              </a-menu-item>
              <a-menu-item  v-if="getRole(modules.report_page)?.is_visible"  key="10_2" @click="visible=false">
                <router-link :to="RouterEndpoint.REPORT_PAGE.path">{{ $t('REPORT_PAGE') }}</router-link>
              </a-menu-item>
              <a-menu-item key="10_3" @click="visible=false">
                <router-link :to="RouterEndpoint.REPORT_CUSTOMER.path">{{ $t('REPORT_CUSTOMER') }}</router-link>
              </a-menu-item>
              <a-menu-item  v-if="getRole(modules.report_staff)?.is_visible"  key="10_4" @click="visible=false">
                <router-link :to="RouterEndpoint.REPORT_STAFF.path">{{ $t('REPORT_STAFF') }}</router-link>
              </a-menu-item>
              <!--              <a-menu-item v-if="getRole(modules.report_zns)?.is_visible"  key="10_5" @click="visible=false">-->
              <!--                <router-link :to="RouterEndpoint.REPORT_ZNS_TRANSACTION.path">{{-->
              <!--                    $t('REPORT_ZNS_TRANSACTION')-->
              <!--                  }}-->
              <!--                </router-link>-->
              <!--              </a-menu-item>-->
              <!--              <a-menu-item v-if="getRole(modules.report_zns)?.is_visible"  key="10_6" @click="visible=false">-->
              <!--                <router-link :to="RouterEndpoint.REPORT_CAMPAIGN.path">{{-->
              <!--                    $t('REPORT_CAMPAIGN')-->
              <!--                  }}-->
              <!--                </router-link>-->
              <!--              </a-menu-item>-->
              <!--              <a-menu-item v-if="getRole(modules.report_zns)?.is_visible"  key="10_10" @click="visible=false">-->
              <!--                <router-link :to="RouterEndpoint.REPORT_ADVERTISEMENT.path">{{-->
              <!--                    $t('REPORT_ADVERTISEMENT')-->
              <!--                  }}-->
              <!--                </router-link>-->
              <!--              </a-menu-item>-->
              <!--              <a-menu-item v-if="getRole(modules.report_zns)?.is_visible"  key="10_7" @click="visible=false">-->
              <!--                <router-link :to="RouterEndpoint.REPORT_INTERACTION.path">{{-->
              <!--                    $t('REPORT_INTERACTION')-->
              <!--                  }}-->
              <!--                </router-link>-->
              <!--              </a-menu-item>-->
              <!--              <a-menu-item v-if="getRole(modules.report_zns)?.is_visible"  key="10_14" @click="visible=false">-->
              <!--                <router-link :to="RouterEndpoint.REPORT_SALE.path">{{-->
              <!--                    $t('REPORT_SALE')-->
              <!--                  }}-->
              <!--                </router-link>-->
              <!--              </a-menu-item>-->
              <!--              <a-menu-item v-if="getRole(modules.report_zns)?.is_visible"  key="10_9" @click="visible=false">-->
              <!--                <router-link :to="RouterEndpoint.REVENUE.path">{{-->
              <!--                    $t('REVENUE')-->
              <!--                  }}-->
              <!--                </router-link>-->
              <!--              </a-menu-item>-->

              <!--              <a-menu-item v-if="getRole(modules.report_zns)?.is_visible"  key="10_11" @click="visible=false">-->
              <!--                <router-link :to="RouterEndpoint.REPORT_ORDER.path">{{-->
              <!--                    $t('ORDER')-->
              <!--                  }}-->
              <!--                </router-link>-->
              <!--              </a-menu-item>-->
              <!--              <a-menu-item v-if="getRole(modules.report_zns)?.is_visible"  key="10_12" @click="visible=false">-->
              <!--                <router-link :to="RouterEndpoint.REPORT_EMPLOYEE.path">{{-->
              <!--                    $t('EMPLOYEE')-->
              <!--                  }}-->
              <!--                </router-link>-->
              <!--              </a-menu-item>-->
              <!--              <a-menu-item v-if="getRole(modules.report_zns)?.is_visible"  key="10_13" @click="visible=false">-->
              <!--                <router-link :to="RouterEndpoint.CUSTOMER_BY_ORDER.path">{{-->
              <!--                    $t('CUSTOMER_BY_ORDER')-->
              <!--                  }}-->
              <!--                </router-link>-->
              <!--              </a-menu-item>-->
            </a-sub-menu>
            <a-sub-menu  key="sub_10.2" @click="goToCollapsePath(RouterEndpoint.REPORT_PAGE.path)">
              <template #title @click="visible=false">
                    <span>
                      <BarChartOutlined/>
                      <span style="font-weight: bold">{{ $t("REPORT") + ' ' + $t('Business') }}</span>
                    </span>
              </template>
<!--              <a-menu-item  v-if="getRole(modules.report_overview)?.is_visible"  key="10_1" @click="visible=false">-->
<!--                <router-link :to="RouterEndpoint.REPORT_OVERVIEW.path">{{ $t('REPORT_OVERVIEW') }}</router-link>-->
<!--              </a-menu-item>-->
<!--              <a-menu-item  v-if="getRole(modules.report_page)?.is_visible"  key="10_2" @click="visible=false">-->
<!--                <router-link :to="RouterEndpoint.REPORT_PAGE.path">{{ $t('REPORT_PAGE') }}</router-link>-->
<!--              </a-menu-item>-->
<!--              <a-menu-item key="10_3" @click="visible=false">-->
<!--                <router-link :to="RouterEndpoint.REPORT_CUSTOMER.path">{{ $t('REPORT_CUSTOMER') }}</router-link>-->
<!--              </a-menu-item>-->
<!--              <a-menu-item  v-if="getRole(modules.report_staff)?.is_visible"  key="10_4" @click="visible=false">-->
<!--                <router-link :to="RouterEndpoint.REPORT_STAFF.path">{{ $t('REPORT_STAFF') }}</router-link>-->
<!--              </a-menu-item>-->
<!--              <a-menu-item v-if="getRole(modules.report_zns)?.is_visible"  key="10_5" @click="visible=false">-->
<!--                <router-link :to="RouterEndpoint.REPORT_ZNS_TRANSACTION.path">{{-->
<!--                    $t('REPORT_ZNS_TRANSACTION')-->
<!--                  }}-->
<!--                </router-link>-->
<!--              </a-menu-item>-->
              <!--              <a-menu-item v-if="getRole(modules.report_zns)?.is_visible"  key="10_6" @click="visible=false">-->
              <!--                <router-link :to="RouterEndpoint.REPORT_CAMPAIGN.path">{{-->
              <!--                    $t('REPORT_CAMPAIGN')-->
              <!--                  }}-->
              <!--                </router-link>-->
              <!--              </a-menu-item>-->
<!--              <a-menu-item v-if="getRole(modules.report_zns)?.is_visible"  key="10_10" @click="visible=false">-->
<!--                <router-link :to="RouterEndpoint.REPORT_ADVERTISEMENT.path">{{-->
<!--                    $t('REPORT_ADVERTISEMENT')-->
<!--                  }}-->
<!--                </router-link>-->
<!--              </a-menu-item>-->
              <a-menu-item v-if="getRole(modules.report_zns)?.is_visible"  key="10_14" @click="visible=false">
                <router-link :to="RouterEndpoint.REPORT_SALE.path">{{
                    $t('REPORT_SALE')
                  }}
                </router-link>
              </a-menu-item>


              <a-menu-item v-if="getRole(modules.report_zns)?.is_visible"  key="10_9" @click="visible=false">
                <router-link :to="RouterEndpoint.REVENUE.path">{{
                    $t("REPORT") + ' ' +$t('REVENUE')
                  }}
                </router-link>
              </a-menu-item>
              <a-menu-item v-if="getRole(modules.report_zns)?.is_visible"  key="10_7" @click="visible=false">
                <router-link :to="RouterEndpoint.REPORT_INTERACTION.path">{{
                    $t('REPORT_INTERACTION')
                  }}
                </router-link>
              </a-menu-item>
              <!--              <a-menu-item v-if="getRole(modules.report_zns)?.is_visible"  key="10_11" @click="visible=false">-->
              <!--                <router-link :to="RouterEndpoint.REPORT_ORDER.path">{{-->
              <!--                    $t('ORDER')-->
              <!--                  }}-->
              <!--                </router-link>-->
              <!--              </a-menu-item>-->

              <a-menu-item v-if="getRole(modules.report_zns)?.is_visible"  key="10_13" @click="visible=false">
                <router-link :to="RouterEndpoint.CUSTOMER_BY_ORDER.path">{{
                    $t('CUSTOMER_BY_ORDER')
                  }}
                </router-link>
              </a-menu-item>
              <a-menu-item v-if="getRole(modules.report_zns)?.is_visible"  key="10_12" @click="visible=false">
                <router-link :to="RouterEndpoint.REPORT_EMPLOYEE.path">{{
                    $t('REPORT_EMPLOYEE')
                  }}
                </router-link>
              </a-menu-item>
            </a-sub-menu>
            <a-sub-menu  key="sub_10.3" @click="goToCollapsePath(RouterEndpoint.REPORT_PAGE.path)">
              <template #title @click="visible=false">
                    <span>
                      <BarChartOutlined/>
                      <span style="font-weight: bold">{{ $t("REPORT")+ ' ' + $t('marketing') }}</span>
                    </span>
              </template>
              <!--              <a-menu-item  v-if="getRole(modules.report_overview)?.is_visible"  key="10_1" @click="visible=false">-->
              <!--                <router-link :to="RouterEndpoint.REPORT_OVERVIEW.path">{{ $t('REPORT_OVERVIEW') }}</router-link>-->
              <!--              </a-menu-item>-->
              <!--              <a-menu-item  v-if="getRole(modules.report_page)?.is_visible"  key="10_2" @click="visible=false">-->
              <!--                <router-link :to="RouterEndpoint.REPORT_PAGE.path">{{ $t('REPORT_PAGE') }}</router-link>-->
              <!--              </a-menu-item>-->
              <!--              <a-menu-item key="10_3" @click="visible=false">-->
              <!--                <router-link :to="RouterEndpoint.REPORT_CUSTOMER.path">{{ $t('REPORT_CUSTOMER') }}</router-link>-->
              <!--              </a-menu-item>-->
              <!--              <a-menu-item  v-if="getRole(modules.report_staff)?.is_visible"  key="10_4" @click="visible=false">-->
              <!--                <router-link :to="RouterEndpoint.REPORT_STAFF.path">{{ $t('REPORT_STAFF') }}</router-link>-->
              <!--              </a-menu-item>-->
              <a-menu-item v-if="getRole(modules.report_zns)?.is_visible"  key="10_5" @click="visible=false">
                <router-link :to="RouterEndpoint.REPORT_ZNS_TRANSACTION.path">{{
                    $t('REPORT_ZNS_TRANSACTION')
                  }}
                </router-link>
              </a-menu-item>
              <!--              <a-menu-item v-if="getRole(modules.report_zns)?.is_visible"  key="10_6" @click="visible=false">-->
              <!--                <router-link :to="RouterEndpoint.REPORT_CAMPAIGN.path">{{-->
              <!--                    $t('REPORT_CAMPAIGN')-->
              <!--                  }}-->
              <!--                </router-link>-->
              <!--              </a-menu-item>-->
              <a-menu-item v-if="getRole(modules.report_zns)?.is_visible"  key="10_10" @click="visible=false">
                <router-link :to="RouterEndpoint.REPORT_ADVERTISEMENT.path">{{
                    $t('REPORT_ADVERTISEMENT')
                  }}
                </router-link>
              </a-menu-item>
              <!--              <a-menu-item v-if="getRole(modules.report_zns)?.is_visible"  key="10_7" @click="visible=false">-->
              <!--                <router-link :to="RouterEndpoint.REPORT_INTERACTION.path">{{-->
              <!--                    $t('REPORT_INTERACTION')-->
              <!--                  }}-->
              <!--                </router-link>-->
              <!--              </a-menu-item>-->
              <!--              <a-menu-item v-if="getRole(modules.report_zns)?.is_visible"  key="10_14" @click="visible=false">-->
              <!--                <router-link :to="RouterEndpoint.REPORT_SALE.path">{{-->
              <!--                    $t('REPORT_SALE')-->
              <!--                  }}-->
              <!--                </router-link>-->
              <!--              </a-menu-item>-->
              <!--              <a-menu-item v-if="getRole(modules.report_zns)?.is_visible"  key="10_9" @click="visible=false">-->
              <!--                <router-link :to="RouterEndpoint.REVENUE.path">{{-->
              <!--                    $t('REVENUE')-->
              <!--                  }}-->
              <!--                </router-link>-->
              <!--              </a-menu-item>-->

              <!--              <a-menu-item v-if="getRole(modules.report_zns)?.is_visible"  key="10_11" @click="visible=false">-->
              <!--                <router-link :to="RouterEndpoint.REPORT_ORDER.path">{{-->
              <!--                    $t('ORDER')-->
              <!--                  }}-->
              <!--                </router-link>-->
              <!--              </a-menu-item>-->
              <!--              <a-menu-item v-if="getRole(modules.report_zns)?.is_visible"  key="10_12" @click="visible=false">-->
              <!--                <router-link :to="RouterEndpoint.REPORT_EMPLOYEE.path">{{-->
              <!--                    $t('EMPLOYEE')-->
              <!--                  }}-->
              <!--                </router-link>-->
              <!--              </a-menu-item>-->
              <!--              <a-menu-item v-if="getRole(modules.report_zns)?.is_visible"  key="10_13" @click="visible=false">-->
              <!--                <router-link :to="RouterEndpoint.CUSTOMER_BY_ORDER.path">{{-->
              <!--                    $t('CUSTOMER_BY_ORDER')-->
              <!--                  }}-->
              <!--                </router-link>-->
              <!--              </a-menu-item>-->
            </a-sub-menu>
          </a-menu-item-group>

          <a-menu-item-group :title="$t('OTHER')">
            <a-sub-menu key="sub_14" @click="goToCollapsePath(RouterEndpoint.PRODUCTS.path)">
              <template #title @click="visible=false">
                    <span>
                      <ShoppingCartOutlined/>
                      <span style="font-weight: bold">{{ $t('PRODUCT') }}</span>
                    </span>
              </template>
              <a-menu-item  v-if="getRole(modules.products)?.is_visible" key="sub_14_0" @click="visible=false">
                <router-link :to="RouterEndpoint.PRODUCTS.path">{{ $t('PRODUCTS') }}</router-link>
              </a-menu-item>
              <a-menu-item  v-if="getRole(modules.categories)?.is_visible"  key="sub_14_4" @click="visible=false">
                <router-link :to="RouterEndpoint.CATEGORIES.path">{{ $t('CATEGORIES') }}</router-link>
              </a-menu-item>
              <a-menu-item  v-if="getRole(modules.stocks)?.is_visible"  key="sub_14_2" @click="visible=false">
                <router-link :to="RouterEndpoint.STOCKS.path">{{ $t('STOCKS') }}</router-link>
              </a-menu-item>
              <a-menu-item  v-if="getRole(modules.product_tag)?.is_visible"  key="sub_14_3" @click="visible=false">
                <router-link :to="RouterEndpoint.PRODUCT_TAGS.path">{{ $t('TAGS') }}</router-link>
              </a-menu-item>
              <a-menu-item  v-if="getRole(modules.providers)?.is_visible"  key="sub_14_5" @click="visible=false">
                <router-link :to="RouterEndpoint.PROVIDERS.path">{{ $t('PROVIDERS') }}</router-link>
              </a-menu-item>
            </a-sub-menu>
            <a-menu-item  v-if="getRole(modules.orders)?.is_visible" key="11" >
              <unordered-list-outlined />
              <span>
                <router-link :to="RouterEndpoint.ORDERS.path" style="font-weight: bold">{{ $t('ORDERS') }}</router-link>
              </span>
            </a-menu-item>
            <a-sub-menu key="sub_3" @click="goToCollapsePath(RouterEndpoint.LIST_TICKET.path)">
              <template #title>
                <container-outlined />
                          <span style="font-weight: bold">{{ $t('TICKET') }}
                          </span>
              </template>
              <a-menu-item v-if="getRole(modules.trigger_tag)?.is_visible"  key="sub_3_1" @click="visible=false">
                <router-link :to="RouterEndpoint.LIST_TEMPLATE.path">{{ $t('Template') }}</router-link>
              </a-menu-item>
              <a-menu-item  key="sub_3_2" @click="visible=false">
                <router-link :to="RouterEndpoint.LIST_EVENT.path">{{ $t('LIST_EVENT') }}</router-link>
              </a-menu-item>
            </a-sub-menu>
            <a-sub-menu key="sub_15" @click="goToCollapsePath(RouterEndpoint.PRODUCTS.path)">
              <template #title @click="visible=false">
                    <span>
                      <SettingOutlined/>
                      <span style="font-weight: bold">{{ $t('CONFIG') }}</span>
                    </span>
              </template>


              <a-menu-item  :key="RouterEndpoint.INTEGRATION_PLATFORMS.name"
              >
                <HighlightOutlined style="margin-right: 10px"/>
                <router-link :to="RouterEndpoint.INTEGRATION_PLATFORMS.path" style="font-weight: bold">{{ $t('INTEGRATION_PLATFORMS') }}</router-link>
              </a-menu-item>
              <a-menu-item  v-if="getRole(modules.service_package)?.is_visible"  :key="RouterEndpoint.SERVICE_PACK.name" >
                <img style="height: 16px;width: 16px;margin-right: 10px" src="@/assets/icons/dollar.png" />
                <router-link :to="RouterEndpoint.SERVICE_PACK.path" style="font-weight: bold">{{ $t('PRICING') }}</router-link>
              </a-menu-item>
              <a-menu-item v-if="getRole(modules.transporter)?.is_visible"  :key="RouterEndpoint.TRANSPORTER.name">
                <img style="height: 16px;width: 16px;margin-right: 10px" src="@/assets/icons/delivery-truck.png" />
                <router-link :to="RouterEndpoint.TRANSPORTER.path" style="font-weight: bold">{{ $t('TRANSPORTERS') }}</router-link>
              </a-menu-item>
              <!--              <a-menu-item :key="RouterEndpoint.SHOPINFO.name"-->
              <!--                           @click="goToPath(RouterEndpoint.SHOPINFO.path)">-->
              <!--                <info-circle-outlined class="menu-icon" />-->
              <!--                <span style="font-weight: bold">{{ $t('SHOP_INFO') }}</span>-->
              <!--              </a-menu-item>-->
              <a-menu-item  v-if="getRole(modules.setting_page)?.is_visible"  :key="RouterEndpoint.SETTING_PAGE.name"
              >
                <setting-outlined style="margin-right: 10px"/>
                <router-link :to="RouterEndpoint.SETTING_PAGE.path" style="font-weight: bold">{{ $t('page_settings') }}</router-link>
              </a-menu-item>
            </a-sub-menu>
<!--            <a-sub-menu key="sub_16" @click="goToCollapsePath(RouterEndpoint.VOUCHER.path)">-->
<!--              <template #title @click="visible=false">-->
<!--                    <span>-->
<!--                      <crown-outlined />-->
<!--                      <span style="font-weight: bold">{{ $t('Loyalty') }}</span>-->
<!--                    </span>-->
<!--              </template>-->


<!--              <a-menu-item  :key="RouterEndpoint.VOUCHER.name"-->
<!--              >-->

<!--                <tag-outlined style="margin-right: 10px" />-->
<!--                <router-link :to="RouterEndpoint.VOUCHER.path" style="font-weight: bold">{{ $t('VOUCHER') }}</router-link>-->
<!--              </a-menu-item>-->
<!--              <a-menu-item   :key="RouterEndpoint.MEMBER_RANK.name" >-->
<!--                <menu-outlined style="margin-right: 10px" />-->
<!--                <router-link :to="RouterEndpoint.MEMBER_RANK.path" style="font-weight: bold">{{ $t('MEMBER_RANK') }}</router-link>-->
<!--              </a-menu-item>-->
<!--            </a-sub-menu>-->
            <a-menu-item   key="102" >
              <api-outlined />
              <span>
                  <router-link :to="RouterEndpoint.INTEGRATED.path" style="font-weight: bold">{{ $t('INTEGRATED') }}</router-link>
              </span>
            </a-menu-item>
          </a-menu-item-group>

          <a-menu-divider/>
          <a-sub-menu key="sub_17" @click="goToCollapsePath(RouterEndpoint.SHOPINFO.path)">
            <template #title @click="visible=false">
                    <span>
                      <a-avatar style="margin-right: 10px" :size="20">
                             {{ firstCharacterName(getUserInfo?.name) }}
                    </a-avatar>
                      <span style="font-weight: bold;margin-left: 17px">{{getUserInfo?.name}}</span>
                    </span>
            </template>


            <a-menu-item :key="RouterEndpoint.SHOPINFO.name"
            >
              <info-circle-outlined style="margin-right: 10px;" class="menu-icon" />
              <router-link :to="RouterEndpoint.SHOPINFO.path" style="font-weight: bold">{{ $t('SHOP_INFO') }}</router-link>
            </a-menu-item>
            <a-menu-item :key="RouterEndpoint.ACCOUNT.path"
            >
              <user-switch-outlined style="margin-right: 10px;" class="menu-icon" />
              <router-link :to="RouterEndpoint.ACCOUNT.path" style="font-weight: bold">{{ $t('ACCOUNT') }}</router-link>
            </a-menu-item>
            <a-menu-item key="logout" @click="handleLogout">
              <LogoutOutlined class="menu-icon"/>
              <span style="font-weight: bold"> {{ $t('SIGN_OUT') }}</span>
            </a-menu-item>


          </a-sub-menu>
          <a-menu-item key="11" >
            <question-circle-outlined style="margin-right: 10px"  />
            <span>
            <router-link :to="RouterEndpoint.HELP_CENTER.path" style="font-weight: bold">{{ $t('HELP_CENTER') }}</router-link>
            </span>
          </a-menu-item>
          <a-menu-item key="12" >
            <question-circle-outlined style="margin-right: 10px"  />
            <span>
            <router-link :to="RouterEndpoint.CMS.path" style="font-weight: bold">{{ $t('CMS') }}</router-link>
            </span>
          </a-menu-item>
        </a-menu>

      </perfect-scrollbar>


    </a-layout-sider>


    <a-layout>
      <a-layout-header style="padding: 0;">
        <div style="display: flex;height: 48px;-webkit-box-shadow: 0 4px 5px -6px rgba(0, 0, 0, 0.65);
      -moz-box-shadow: 0 4px 5px -6px rgba(0, 0, 0, 0.65);
      box-shadow: 0 4px 5px -6px rgba(0, 0, 0, 0.65);">
          <!--          <div>-->
          <!--            <menu-unfold-outlined-->
          <!--                style="color: white"-->
          <!--                v-if="collapsed"-->
          <!--                class="trigger"-->
          <!--                @click="() => (collapsed = !collapsed)"-->
          <!--            />-->
          <!--            <menu-fold-outlined style="color: white" v-else class="trigger" @click="() => (collapsed = !collapsed)"/>-->
          <!--          </div>-->
          <shop-header/>
        </div>
      </a-layout-header>
      <!--      <a-layout-header theme="light" style="-->
      <!--        /*background: #F0F0F0;*/-->
      <!--        background: white;-->
      <!--       padding: 0;-->
      <!--       display: flex;-->
      <!--      align-items: center;-webkit-box-shadow: 0 4px 5px -6px rgba(0, 0, 0, 0.65);-->
      <!--      -moz-box-shadow: 0 4px 5px -6px rgba(0, 0, 0, 0.65);-->
      <!--      box-shadow: 0 4px 5px -6px rgba(0, 0, 0, 0.65);">-->
      <!--        <menu-unfold-outlined-->
      <!--            v-if="collapsed"-->
      <!--            class="trigger"-->
      <!--            @click="() => (collapsed = !collapsed)"-->
      <!--        />-->
      <!--        <menu-fold-outlined v-else class="trigger" @click="() => (collapsed = !collapsed)" />-->
      <!--        <shop-header/>-->
      <!--      </a-layout-header>-->
      <a-layout-content
          class="biglead-layout-content"
      >
        <slot/>
      </a-layout-content>

    </a-layout>
  </a-layout>
</template>

<script lang="ts">
import {computed, defineComponent, inject, onBeforeMount, onMounted, ref, watch} from "vue"

import {

  VideoCameraOutlined,
  UploadOutlined,
  MenuUnfoldOutlined,
  MenuFoldOutlined,
  PieChartOutlined,
  DesktopOutlined,
  TeamOutlined,
  ApiOutlined,
    ScheduleOutlined,
  QuestionCircleOutlined,
  LogoutOutlined,
  NodeExpandOutlined,
  AimOutlined,
  RadarChartOutlined, DashboardOutlined,
  UnorderedListOutlined, NotificationOutlined,
  SettingOutlined,
  SmileOutlined,
  UserOutlined,
  InfoCircleOutlined,
    CrownOutlined,
    TagOutlined,
    MenuOutlined,
    ContainerOutlined,
    DownloadOutlined,
  BarChartOutlined, CarOutlined, ShoppingCartOutlined, UsergroupAddOutlined, UserSwitchOutlined, HighlightOutlined
} from '@ant-design/icons-vue';
import RightContent from "@/components/Header/RightContent.vue";
import ShopHeader from "@/components/Header/Header.vue";
import RouterEndpoint from "@/router/RouterEndpoint";
import Transporter from "@/views/Transporter/Transporter.vue";
import {mapGetters, useStore} from "vuex";
import {MSocketIo} from "@/utils/Utils";
import {VueCookies} from "vue-cookies";
import logoBiglead from "@/assets/icons/logo-one_1.png"
import logoHorizontalBiglead from "@/assets/icons/logoBiglead.png"
import logoWisell from "@/assets/icons/logo_wisell.png"
import logoHorizontalWisell from "@/assets/icons/logoWisell.png"
import logoAlfreddesk from "@/assets/icons/logoAlfreddesk.jpg"
import logoHorizontalAlfreddesk from "@/assets/icons/logoHorizontalAlfreddesk.png"
import {useRoute, useRouter} from "vue-router";
import {SuperUserInfo} from "@/models/Users";

import {Modal} from "ant-design-vue";
import {useI18n} from "vue-i18n";

import {ROLES} from "@/models/employee/Employee";
import dayjs from "dayjs";
import router from "@/router";
import {Constant} from "@/utils/Constants";
import {CustomersActionTypes} from "@/store/modules/customer/action-types";
import {ShopinfoActionTypes} from "@/store/modules/Shopinfo/action-types";
import useUserStore from "../store/User";


export default defineComponent({
  name: "default-layout",
  components: {
    ShopHeader,
    UserSwitchOutlined,
    HighlightOutlined,
    UsergroupAddOutlined,
    InfoCircleOutlined,
    ContainerOutlined,
    LogoutOutlined,
    ApiOutlined,
    QuestionCircleOutlined,
    // MenuUnfoldOutlined,
    // MenuFoldOutlined,
    NodeExpandOutlined,
    SettingOutlined,
    AimOutlined,
    // RadarChartOutlined,
    DashboardOutlined,
    UnorderedListOutlined,
    DownloadOutlined,
    BarChartOutlined,
    ShoppingCartOutlined,
    // CrownOutlined,
    // TagOutlined,
    // MenuOutlined

  },
  setup() {

    const router = useRouter()
    const route = useRoute()
    const store = useStore()
    const i18n = useI18n()
    const visible = ref(false)
    const collapsed = ref(false)
    const $cookies = inject<VueCookies>('$cookies');
    const sliderStyle = ref({
      maxWidth: "60px !important",
      flex: "0 0 60px",
      minWidth: "60px",
      width: "60px",
      // overflow: 'auto', height: '100vh', position: 'fixed', left: 0
    })
    const fetchShopInfo = () => {
      store.dispatch(ShopinfoActionTypes.FETCH_SHOPINFO);
    };
    const onClose = () => {
      visible.value = false
    }
    const goToPath = (path: string) => {
      router.push({path: path})
    }
    const goToCollapsePath = (path: string) => {
      if (collapsed.value) {
        router.push({path: path})
      }
    }
    const  firstCharacterName =(s: string)=> {
      if (!s) {
        return "S"
      }
      return s.charAt(0).toUpperCase();
    }
    const handleLogout = () => {
      Modal.confirm({
        title:()=> i18n.t("SIGN_OUT_CONFIRM"),
        content:()=> i18n.t("NOTIFY_SIGN_OUT_CONFIRM"),
        onOk: async() => {
          console.log('logout')
          localStorage.clear();
          // this.setLogin(false)
          // await this.setUserInfo({token: "", shop_id: 0});
          router.replace({name: RouterEndpoint.ACCOUNTLOGIN.name})
          // return new Promise((resolve, reject) => {
          //   setTimeout(Math.random() > 0.5 ? resolve : reject, 1500)
          // }).catch(() => console.log('Oops errors!'))
        },
        onCancel() {

          console.log("cancel")
        }
      })
    }
    const getUserInfo = computed(() => store.getters.getUserInfo)
    const userInfo = computed<SuperUserInfo>(() => store.getters.getUserInfo)
    const userRole = computed(() => store.getters.getCurrentShop.employees?.find((e: any)=>e.id===userInfo.value.id)['role_id']);

    const shopActive = ref(true)
    const getShopInfo = computed(()=>store.getters.getShopinfo)

    // const shopInfo = computed(() => {
    //   if(getShopInfo.shop_services.end_date && !(getShopInfo.shop_services.status == 'Kích hoạt' && dayjs(getShopInfo.shop_services.end_date).diff(dayjs(), 'seconds')>0)){
    //     router.replace({name: RouterEndpoint.SERVICE_PACK.name})
    //   }
    //   return getShopInfo
    // });
    watch(getShopInfo, (getShopInfo) => {
      shopActive.value = getShopInfo.shop_services.status == 'Kích hoạt' && dayjs(getShopInfo.shop_services.end_date).diff(dayjs(), 'seconds')>0
      if(getShopInfo.shop_services.end_date && !shopActive.value){
        router.replace({name: RouterEndpoint.SERVICE_PACK.name})
      }
    })

    onBeforeMount(() => {
      try {
        if (route.query.daily) {
          const $cookies = inject<VueCookies>('$cookies');
            $cookies?.set("daily", route.query.daily)
        }
        try {
          const collapsedCookie = $cookies?.get("collapsed")
          if (collapsedCookie) {
            collapsed.value = collapsedCookie === 'true'
          }
        } catch (e) {
          console.log(e)
        }
        if (userInfo.value && userInfo.value.id) {
          MSocketIo.getInstance().socket.on("connect", () => {
            // console.log("join room", userInfo.value.id)
            MSocketIo.getInstance().socket.emit("sub-notification", {uid: userInfo.value.id})
          });
          MSocketIo.getInstance().socket.on("reconnect", (attempt) => {
            console.log("socket reconnected", attempt)
          });
          store.dispatch(CustomersActionTypes.FETCH_CUSTOMER_CONFIG,{shop_id:store.getters.getCurrentShop.id})
        }
      } catch (e) {
        console.log(e)
      }
    })



    watch(collapsed, (newValue) => {
      console.log("collapsed", newValue)
      $cookies?.set("collapsed", newValue + '')
    })

    onMounted(()=>{
      fetchShopInfo()
    })


    const getRole = computed(() => {
      // @ts-ignore
      if(useUserStore.getState().userInfo?.id_cms_privileges<11){
        return ()=>({
          is_visible: 1,
          is_create:1,
          is_read: 1,
          is_edit: 1,
          is_delete: 1,
          id: 1
        })
      }else{
        return store.getters.getRole
      }
    });
    const modules = Constant.MODULES;


    const logo = computed(()=>{
      return window.location.host.includes("wisell")?logoWisell: ( window.location.host.includes("alfreddesk")?logoAlfreddesk: logoBiglead)
    })
    const logoHorizontal = computed(()=>{
      return window.location.host.includes("wisell")?logoHorizontalWisell: ( window.location.host.includes("alfreddesk")?logoHorizontalAlfreddesk: logoHorizontalBiglead)
    })

    return {
      selectedKeys: ref<string[]>(['1']),
      collapsed,
      visible,
      RouterEndpoint: RouterEndpoint,
      location: location,
      logo,
      logoHorizontal,
      sliderStyle,
      onClose,
      goToPath,
      goToCollapsePath,
      getUserInfo,
      firstCharacterName,
      handleLogout,
      userRole,
      ROLES,
      shopActive,
      getRole,
      modules,
    }
  },

})
</script>

<style>
.trigger {
  font-size: 18px;
  line-height: 64px;
  padding: 0 24px;
  cursor: pointer;
  transition: color 0.3s;
}

.trigger:hover {
  color: #1890ff;
}

.logo {
  height: 48px;
  background-color: var(--primary-color);
  padding-left: 16px;
  display: flex;
  /*justify-content: center;*/
  /*align-items: center;*/
}

.logo img {
  height: 100%;
  padding: 8px 10px;
}

.zalo-icon {
  width: 14px;
  height: 14px;
}

.biglead-layout-sider {
  overflow-y: auto;
  height: 100vh;
  left: 0;
  top: 0;
  bottom: 0;
  width: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0)
}
.ps{
  height: 90vh;
}

/*.biglead-layout-sider::-webkit-scrollbar{*/
/*  background-color: transparent;*/
/*  -webkit-appearance: none;*/
/*  width: 4px;*/
/*}*/

/*.biglead-layout-sider::-webkit-scrollbar-thumb{*/
/*  background-color:#a0a0a0;*/
/*  border-radius: 8px;*/
/*}*/

/*.biglead-layout-sider::-webkit-scrollbar-track {*/
/*  background: transparent;*/
/*}*/


.biglead-layout-content {
  height: 70vh;
  overflow-y: auto;
}

.biglead-layout-content::-webkit-scrollbar {
  width: 8px;
}

.biglead-layout-content::-webkit-scrollbar-button {
  background: #ccc;
}

.biglead-layout-content::-webkit-scrollbar-track-piece {
  background: #ccc;
}

.biglead-layout-content::-webkit-scrollbar-thumb {
  background: #888
}

.site-layout-background {
  background: #fff;
}


</style>
