import {ActionContext, ActionTree} from "vuex";
import {State} from "./states";
import {CallBackPayload, RootState} from "@/store";
import APIs from "@/APIs/APIs";
import {Mutations} from "./mutations";
import {ProvinceResponse} from "@/APIs/response/S_HttpResponse";
import {DictionaryActionTypes} from "@/store/modules/dictionary/action-types";
import {DistrictRequest, WardRequest} from "@/APIs/request/DictionaryRequest";
import {DictionaryMutationTypes} from "@/store/modules/dictionary/mutation-types";

type AugmentedActionContext = {
    commit<K extends keyof Mutations>(
        key: K,
        payload: Parameters<Mutations[K]>[1],
    ): ReturnType<Mutations[K]>;
} & Omit<ActionContext<State, RootState>, 'commit'>

export interface Actions {
    [DictionaryActionTypes.FETCH_PROVINCES](
        {commit}: AugmentedActionContext,
        payload?:CallBackPayload<number,ProvinceResponse>
    ): Promise<ProvinceResponse | undefined>;

    [DictionaryActionTypes.FETCH_DISTRICTS](
        {commit}: AugmentedActionContext,
        payload:CallBackPayload<DistrictRequest,ProvinceResponse>
    ): Promise<ProvinceResponse | undefined>;

    [DictionaryActionTypes.FETCH_WARDS](
        {commit}: AugmentedActionContext,
        payload:CallBackPayload<WardRequest,ProvinceResponse>
    ): Promise<ProvinceResponse | undefined>;
}

export const actions: ActionTree<State, RootState> & Actions = {
    async [DictionaryActionTypes.FETCH_PROVINCES]({commit},payload?:CallBackPayload<number,ProvinceResponse>): Promise<ProvinceResponse | undefined> {
        const response = await APIs.init().getProvinces()
        if (response.status === 200) {
            commit(DictionaryMutationTypes.SET_PROVINCES, response.data?.data)
        }
        if (payload&&payload.callback){
            payload.callback(response.data)
        }
        return response.data;
    },

    async [DictionaryActionTypes.FETCH_DISTRICTS]({commit}, payload:CallBackPayload<DistrictRequest,ProvinceResponse>): Promise<ProvinceResponse | undefined> {
        const response = await APIs.init().getDistricts(payload.payload)
        if (response.status === 200) {
            commit(DictionaryMutationTypes.SET_DISTRICTS, response.data?.data)
        }
        if (payload&&payload.callback){
            payload.callback(response.data)
        }
        return response.data;
    },
    async [DictionaryActionTypes.FETCH_WARDS]({commit}, payload:CallBackPayload<WardRequest,ProvinceResponse>): Promise<ProvinceResponse | undefined> {
        const response = await APIs.init().getWards(payload.payload)
        if (response.status === 200) {
            commit(DictionaryMutationTypes.SET_WARDS, response.data?.data)
        }
        if (payload&&payload.callback){
            payload.callback(response.data)
        }
        return response.data;
    }
}
