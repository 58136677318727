import { createLogger, createStore } from "vuex";
import createPersistedState from "vuex-persistedstate";
import {
  store as User,
  UsersStore,
  State as UserState,
} from "@/store/modules/user";
import {
  store as Flows,
  FlowsStore,
  State as FlowsState,
} from "@/store/modules/flow";
import { store as Virals, State as ViralsState } from "@/store/modules/viral";
import {
  store as Triggers,
  TriggersStore,
  State as TriggersState,
} from "@/store/modules/trigger";
import {
  store as Tags,
  TagsStore,
  State as TagState,
} from "@/store/modules/tag";
import {
  store as Customers,
  CustomersStore,
  State as CustomerState,
} from "@/store/modules/customer";
import {
  store as AWS_DO,
  AWS_DO_FilesStore,
  State as AWS_DO_State,
} from "@/store/modules/AWS_DO_FileStorage";
import {
  store as Dictionary,
  DictionaryStore,
  State as DictionaryState,
} from "@/store/modules/dictionary";
import {
  store as CustomerCare,
  CustomersCaresStore,
  State as CustomerCaresState,
} from "@/store/modules/customerCare";
import {
  store as AutoInbox,
  State as AutoInboxState,
} from "@/store/modules/autoInbox";
import {
  store as Wallet,
  WalletStore,
  State as WalletState,
} from "@/store/modules/wallet";
import {
  store as Stock,
  StocksStore,
  State as StocksState,
} from "@/store/modules/stocks";
import {
  store as Products,
  ProductStore,
  State as ProductsState,
} from "@/store/modules/product";
import {
  store as Orders,
  OrdersStore,
  State as OrdersState,
} from "@/store/modules/orders";
import {
  store as Transporter,
  TransporterStore,
  State as TransporterState,
} from "@/store/modules/transporter";
import {
  store as Shopinfo,
  ShopinfoStore,
  State as ShopinfoState,
} from "@/store/modules/Shopinfo";
import {
  store as Overview,
  OverviewStore,
  State as OverviewState,
} from "@/store/modules/OverView";
import {
  store as Employee,
  EmployeeStore,
  State as EmployeeState,
} from "@/store/modules/employee";
import {
  store as ReportPage,
  ReportPageStore,
  State as ReportPageState,
} from "@/store/modules/ReportPage";
import {
  store as PageConfig,
  PageConfigStore,
  State as PageConfigState,
} from "@/store/modules/PageConfig";
import {
  store as Voucher,
  VoucherStore,
  State as VouhcerState,
} from "@/store/modules/voucher";
import {
  store as MemberRank,
  MemberRankStore,
  State as MemberRankSate,
} from "@/store/modules/memberRank";

import {
  store as Event,
  EventStore,
  State as EventState,
} from "@/store/modules/Event";
import {
  store as Ticket,
  TicketStore,
  State as TicketState,
} from "@/store/modules/Ticket";

import { ViralsStore } from "@/store/modules/viral";
import { AutoInboxStore } from "@/store/modules/autoInbox";

const debug = process.env.NODE_ENV !== "production";
const plugins = debug ? [createLogger({})] : [];
plugins.push(createPersistedState({ storage: window.localStorage }));

// @ts-ignore
export type Store = UsersStore<Pick<RootState, "user">> &
  FlowsStore<Pick<RootState, "flows">> &
  ViralsStore<Pick<RootState, "virals">> &
  TriggersStore<Pick<RootState, "triggers">> &
  TagsStore<Pick<RootState, "tags">> &
  CustomersStore<Pick<RootState, "customers">> &
  AWS_DO_FilesStore<Pick<RootState, "aws_do">> &
  DictionaryStore<Pick<RootState, "dictionary">> &
  CustomersCaresStore<Pick<RootState, "customerCares">> &
  AutoInboxStore<Pick<RootState, "autoInbox">> &
  WalletStore<Pick<RootState, "wallet">> &
  StocksStore<Pick<RootState, "stocks">> &
  ProductStore<Pick<RootState, "products">> &
  OrdersStore<Pick<RootState, "orders">> &
  TransporterStore<Pick<RootState, "transporter">> &
  ShopinfoStore<Pick<RootState, "shopinfo">> &
  OverviewStore<Pick<RootState, "overview">> &
  EmployeeStore<Pick<RootState, "employee">> &
  ReportPageStore<Pick<RootState, "reportPage">> &
  PageConfigStore<Pick<RootState, "pageconfig">> &
  // @ts-ignore

  VoucherStore<Pick<RootState, "voucher">> &
  MemberRankStore<Pick<RootState, "memberRank">> &
  // @ts-ignore
  EventStore<Pick<RootState, "event">> &
  // @ts-ignore
  TicketStore<Pick<RootState, "ticket">>;

export const store = createStore({
  plugins: plugins,
  modules: {
    User,
    Flows,
    Virals,
    Triggers,
    Tags,
    Customers,
    AWS_DO,
    Dictionary,
    CustomerCare,
    AutoInbox,
    Stock,
    Wallet,
    Products,
    Orders,
    Transporter,
    Shopinfo,
    Overview,
    Employee,
    ReportPage,
    PageConfig,

    Voucher,
    MemberRank,
    Event,
    Ticket,
  },
});

export type RootState = {
  user: UserState;
  flows: FlowsState;
  virals: ViralsState;
  triggers: TriggersState;
  tags: TagState;
  customers: CustomerState;
  aws_do: AWS_DO_State;
  dictionary: DictionaryState;
  customerCares: CustomerCaresState;
  autoInbox: AutoInboxState;
  wallet: WalletState;
  stocks: StocksState;
  products: ProductsState;
  orders: OrdersState;
  transporter: TransporterState;
  shopinfo: ShopinfoState;
  overview: OverviewState;
  employee: EmployeeState;
  reportPage: ReportPageState;
  pageconfig: PageConfigState;
  voucher: VouhcerState;
  memberRank: MemberRankSate;
  event: EventState;
  ticket: TicketState;
};

export function useStore(): Store {
  // @ts-ignore
  return store as Store;
}

export interface CallBackPayload<T, V> {
  payload?: T;
  callback?: (result?: V) => void;
}
