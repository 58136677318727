<template>
  <ul class="order-edit-container">
    <li class="customer-info-item">
      <a-card :title="$t('CUSTOMER')">
        <a-form
            :model="formStateOrder"
            ref="formRef"
            :rules="rules"
            :label-col="labelCol"
            :wrapper-col="wrapperCol"
        >
          <a-form-item has-feedback :label="$t('FORM_FULL_NAME')" name="shipping_name">
            <a-input
                v-model:value="formStateOrder.shipping_name"
                type="text" autocomplete="off">
              <template #prefix>
                <user-outlined type="user"/>
              </template>
            </a-input>
          </a-form-item>
          <a-form-item has-feedback :label="$t('phone_number')" name="shipping_phone">
            <a-auto-complete
                class="certain-category-search"
                dropdown-class-name="certain-category-search-dropdown"
                showSearch
                :options="customers"
                v-model:value="formStateOrder.shipping_phone"
                @select="selectCustomer"
                :loading="customerSearchLoading"
                @change="handleSearch"
            >
              <template #option="{ value: val }">
                <div class="product_select_item">
                {{val}}
                </div>
              </template>
            </a-auto-complete>

          </a-form-item>

          <a-form-item has-feedback :label="$t('PROVINCE')" name="shipping_province_id">
            <a-select
                v-model:value="formStateOrder.shipping_province_id"
                showSearch
                style="width: 100%"
                placeholder="Please select"
                :filter-option="false"
                :not-found-content="null"
                @change="provinceChange"
                @search="provinceSearch"
            >
              <a-select-option
                  v-for="item in getProvinces"
                  :key="item.id"
              >{{ item.name }}
              </a-select-option>
            </a-select>
          </a-form-item>
          <a-form-item has-feedback :label="$t('DISTRICT')" name="shipping_district_id">
            <a-select
                v-model:value="formStateOrder.shipping_district_id"
                showSearch
                placeholder="Select"
                :filter-option="false"
                :not-found-content="null"
                style="width: 100%"
                @change="districtChange"
                @search="districtSearch"
            >
              <a-select-option
                  v-for="item in getDistricts"
                  :key="item.id"
              >{{ item.name }}
              </a-select-option>
            </a-select>
          </a-form-item>
          <a-form-item has-feedback :label="$t('WARD')" name="shipping_ward_id">
            <a-select
                v-model:value="formStateOrder.shipping_ward_id"
                showSearch
                placeholder="Ward"
                style="width: 100%"
                :filter-option="false"
                :not-found-content="null"
                @search="wardSearch"
            >
              <a-select-option
                  v-for="item in getWards"
                  :key="item.id"
              >{{ item.name }}
              </a-select-option>
            </a-select>
          </a-form-item>
          <a-form-item has-feedback :label="$t('ADDRESS')" name="shipping_address">
            <a-input
                v-model:value="formStateOrder.shipping_address"
                type="text" autocomplete="off">
              <template #prefix>
                <environment-outlined type="user"/>
              </template>
            </a-input>
          </a-form-item>
        </a-form>
      </a-card>
    </li>
    <li class="product-info-item">
      <a-card>
        <template #title>
          <span>
            {{$t('STOCK')}}:
            <span style="">
                    <a-select
                        allowClear
                        v-model:value="formState.stock_id"
                        style="width: 300px"
                        placeholder="Please select"
                        @change="handleOnChangePage"
                    >
                  <a-select-option
                      v-for="item in getStocks"
                      :key="item.id"
                      v-bind:value="item.id"
                  >{{ item.shop_name }}
                  </a-select-option>
                </a-select>
            </span>
          </span>
          <a-button v-if="productSelected.length>0" style="margin-left: 20px"  @click="visible = true">
           <a-typography-text v-if="customerVoucher.voucher_id">{{getVoucherName(customerVoucher.voucher_id)}}</a-typography-text>
            <a-typography-text v-else> {{$t('Voucher')}}</a-typography-text>

          </a-button>

          <a-popover v-model:visible="visible2"  trigger="click">
            <template #content>
              <a-typography-text strong>{{formStateOrder.shipping_name}} : </a-typography-text>
              <a-typography-text type="success">{{formatPoint(customerPoint.customer_column_value)}} điểm</a-typography-text>
              <a-input-number
                  style="margin-top: 20px;width: 300px;display: block" v-model:value="usePoint.point" placeholder="nhập số điểm muốn sử dụng"
                  :min="0"
                  :max="Number(customerPoint.customer_column_value)"
                  :formatter="value => ` ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')"
                  :parser="value => value.replace(/\$\s?|(,*)/g, '')"
                  string-mode
              />
<!--              <a-input  style="margin-top: 20px" v-model:value="usePoint.point" placeholder="nhập số điểm muốn sử dụng" />-->
              <a-button type="primary" @click="customerUsePoint"  style="margin-top: 10px"  >
                 {{$t('USE_POINT')}}

              </a-button>
              <a-button  @click="customerUsePointAll"  style="margin-top: 10px;margin-left: 10px"  >
                {{$t('ALL')}}

              </a-button>
            </template>
            <a-button  style="margin-left: 20px"  >
              <a-typography-text @click="getPoint" > {{$t('USE_POINT')}}</a-typography-text>

            </a-button>
          </a-popover>
<!--          <span  v-if="productSelected.length>0" style="margin-left: 20px">-->
<!--            {{$t('Voucher')}}:-->
<!--            <span style="">-->
<!--                    <a-select-->
<!--                        allowClear-->
<!--                        v-model:value="customerVoucher.voucher_id"-->
<!--                        style="width: 300px"-->
<!--                        placeholder="Please select"-->
<!--                        @change="handleSelectVoucher"-->
<!--                    >-->
<!--                  <a-select-option-->
<!--                      v-for="item in hanldeVoucher(getVoucherActive)"-->
<!--                      :key="item.id"-->
<!--                      v-bind:value="item.id"-->
<!--                  >{{ item.voucher_name }}-->
<!--                  </a-select-option>-->
<!--                </a-select>-->
<!--            </span>-->
<!--          </span>-->
        </template>
        <a-table
            v-if="productSelected.length>0"
            :columns="productColumns" :data-source="productSelected" :pagination="false" class="table-product">
          <template #product_name="{ record }">
            <a>{{ record.product_name }}</a>
          </template>
          <template #action="{record}">

            <DeleteOutlined @click="deleteProductSelected(record)"/>
          </template>
          <template #avatar_url="{record}">
                <span>
                   <a-image
                       width="50px"
                       height="50px"
                       :src="record.avatar_url"
                   />
                </span>
          </template>
          <template #unit_price="{record}">
            <a-input-number v-model:value="record.unit_price" style="width: 100px"
                            :formatter="value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')"
                            :parser="value => value.replace(/\$\s?|(,*)/g, '')"
            />
          </template>
          <template #total="{record}">
        <span class="total">
          {{ formatMoney(record.quantity_order*record.unit_price) }}
        </span>
          </template>
          <template #delete="{record}">
            <a-popconfirm
                title="Are you sure remove this product?"
                ok-text="Yes"
                cancel-text="No"
                @confirm="deleteProductSelected(record)"
            >
              <a-button>
                <delete-outlined/>
              </a-button>
            </a-popconfirm>
          </template>
          <template #quantity="{record}">
            <a-input v-model:value="record.quantity_order" style="width: 70px" type="number"/>
          </template>
        </a-table>
        <row>
          <a-auto-complete
              style="display: flex;"
              showSearch
              :options="productsAdd"
              @select="selectProduct"
              :loading="customerSearchLoading"
              @change="handleSearchProduct"
              v-model:value="stockProductRequest.search"
              :placeholder="$t('PLACEHOLDER_SEARCH_PRODUCT')"
          >
            <template #option="{ value: val}">
              <span>
              <UserOutlined />
            </span>
              {{val}}
            </template>
          </a-auto-complete>
        </row>


<!--        <div class="add-product">-->
<!--          <a-button type="primary" @click="showProductModal">-->
<!--            <plus-outlined/>-->
<!--            {{$t('add_product')}}-->
<!--          </a-button>-->

<!--        </div>-->
        <div class="cost_info">
        <a-collapse v-model:activeKey="collapseActive" :bordered="false"  style="border-width: 0">
          <a-collapse-panel key="1" :header="$t('COST_INFO')">
            <a-form :labelCol="{style: { width: '200px',  }}" style="">
              <a-form-item :label="$t('SHIPPING_METHOD')" name="note_bill">
                <a-switch v-model:checked="formState.ship_checked"/>
              </a-form-item>
              <div v-if="formState.ship_checked">
                <a-form-item :label="$t('SHIPPING_UNIT')" name="note_bill">
                  <a-select
                      :disabled="!formState.ship_checked"
                      class="shipping-unit-value"
                      v-model:value="formState.shipping_provider"
                      showSearch
                      style="width: 100%"
                      placeholder="Please select"
                      @change="shippingProviderOnChange"
                  >
                    <a-select-option
                        v-for="item in shippingProviders"
                        :key="item.id"
                    >{{ item.label }}
                    </a-select-option>
                  </a-select>

                </a-form-item>
                <a-form-item :label="$t('SERVICE')" name="note_bill">
                  <a-select class="shipping-unit-value"
                            v-model:value="formState.shipping_method"
                            :disabled="!formState.ship_checked"
                            @change="shippingMethodOnChange"
                  >
                    <a-select-option
                        v-for="item in shippingMethods"
                        :key="item.name"
                    >{{ item.label }}
                    </a-select-option>
                  </a-select>

                </a-form-item>
                <a-form-item :label="$t('SHIPPING_COST')" name="note">
                  <a-input-number
                      :disabled="true"
                      v-model:value="formState.shipping_fee"
                      :formatter="value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')"
                      :parser="value => value.replace(/\$\s?|(,*)/g, '')"
                      :step="1000"
                      min="0"
                      style="text-align: right; font-weight: bold"
                      class="input-order-price"/>
                </a-form-item>
                <a-form-item :label="$t('SHIPPING_COST_COLLECTED')" name="note">
                  <a-input-number
                      v-model:value="formState.shipping_fee_collected"
                      :formatter="value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')"
                      :parser="value => value.replace(/\$\s?|(,*)/g, '')"
                      :step="1000"
                      min="0"
                      style="text-align: right; font-weight: bold"
                      class="input-order-price"/>
                </a-form-item>

              </div>
              <a-form-item :label="$t('DISCOUNT')" name="note">
                <a-input-number
                    v-model:value="formState.discount"
                    :formatter="value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')"
                    :parser="value => value.replace(/\$\s?|(,*)/g, '')"
                    :step="1000"
                    min="0"
                    style="text-align: right; font-weight: bold"
                    class="input-order-price"/>
              </a-form-item>
              <a-form-item :label="$t('TOTAL_AMOUNT')" name="note_bill">
                <a-input-number
                    :disabled="true"
                    :value="productSelected.map(p=>p.quantity_order*p.unit_price).reduce((a, b) => a + b, 0)-(formState.discount?Number(formState.discount):0)+(formState.ship_cost?Number(formState.ship_cost):0)"
                    :formatter="value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')"
                    :parser="value => value.replace(/\$\s?|(,*)/g, '')"
                    :step="1000"
                    min="0"
                    class="input-order-note"/>
              </a-form-item>
              <a-form-item :label="$t('PAID')" name="note_bill">
                <a-input-number
                    v-model:value="formState.paid"
                    :formatter="value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')"
                    :parser="value => value.replace(/\$\s?|(,*)/g, '')"
                    :step="1000"
                    min="0"
                    class="input-order-note"/>
              </a-form-item>
              <a-form-item :label="$t('COD')" name="note_bill">
                <a-input-number
                    :value="productSelected.map(p=>p.quantity_order*p.unit_price).reduce((a, b) => a + b, 0)-(formState.discount?Number(formState.discount):0)+(formState.shipping_fee_collected?Number(formState.shipping_fee_collected):0)-Number(formState.paid?formState.paid:0)"
                    :formatter="value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')"
                    :parser="value => value.replace(/\$\s?|(,*)/g, '')"
                    :step="1000"
                    min="0"
                    class="input-order-price"/>
              </a-form-item>
              <a-form-item :label="$t('ORDER_NOTE')" name="note">
                <a-textarea  v-model:value="formState.note" class="input-order-note"/>
              </a-form-item>
              <a-form-item :label="$t('SHIP_NOTE')" name="note_bill">
                <a-textarea  v-model:value="formState.note_bill" class="input-order-note"/>
              </a-form-item>
            </a-form>

          </a-collapse-panel>

        </a-collapse>
        </div>
        <div class="footer-save">
          <a-button
              v-if="!formStateOrder.id"
              @click="createOrder(false)"
              type="primary">
            {{$t('CREATE_ORDER')}}
            <save-outlined/>
          </a-button>
          <a-button
              v-if="!formStateOrder.id"
              @click="createOrder(true)"
              type="primary">
            {{$t('CREATE_AND_CONFIRM_ORDER')}}
            <save-outlined/>
          </a-button>
          <a-button
              v-if="formStateOrder.id"
              @click="createOrder(false)"
              type="primary">
            {{$t('SAVE')}}
            <save-outlined/>
          </a-button>
        </div>
      </a-card>
    </li>
  </ul>
  <a-modal v-model:visible="productModalVisible" :title="$t('SELECT_PRODUCT')" width="80%"
           @ok="submitSelectedProduct"
           :okText="$t('OK')"
           :cancelText="$t('CANCEL')"
  >
    <a-input-search
        showClear
        v-model:value="stockProductRequest.search"
        class="search-product-input"
        :placeholder="$t('PLACEHOLDER_SEARCH_PRODUCT')"
        @search="onSearchProduct"
        @change="onChangeProduct"
    />
    <a-table
        size="small"
        :scroll="{  y: '60vh' }"
        :columns="productSearchColumns" :data-source="products" :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange, getCheckboxProps }">
      <template #group_name="{ record }">
        <a>{{ record.group_name }}</a>
      </template>
      <template #product_name="{ record }">
        <a>{{ record.product_name }}<br/>{{record.attributes?JSON.parse(record.attributes).map(a=>a.value).join('/'): ''}}</a>
      </template>
      <template #avatar_url="{record}">
                <span>
                   <a-image
                       width="50px"
                       max-height="100px"
                       max-width="100px"
                       :src="record.avatar_url"
                   />
                </span>
      </template>
      <template #total="{text}">
                <span class="total">
                  {{ text }}
                </span>
      </template>
      <template #quantity="{record}">
          <span class="quantity">
            {{ formatMoney(record.quantity) }}
          </span>
      </template>
      <template #avg_import_price="{text}">
          <span class="avg_import_price">
            {{ formatMoney(text) }}
          </span>
      </template>
      <template #max_import_price="{text}">
          <span class="max_import_price">
            {{ formatMoney(text) }}
          </span>
      </template>
      <template #min_import_price="{text}">
          <span class="min_import_price">
            {{ formatMoney(text) }}
          </span>
      </template>
      <template #unit_price="{record}">
            <span class="unit_price">
              {{ formatMoney(record.unit_price) }}
            </span>
      </template>
    </a-table>
  </a-modal>
  <a-modal v-model:visible="visible" title="Voucher" @ok="visible =  false">
    <a-list item-layout="horizontal" :data-source="handleVoucher(getVoucherActive)">
      <template #renderItem="{ item }">
        <a-list-item>
          <a-radio @click="handleSelectVoucher(item.id)" :checked="customerVoucher.voucher_id === item.id" />
          <a-list-item-meta>

              <template #title>
                <a-typography-text strong>{{ item.voucher_name }}</a-typography-text>
              </template>
              <template #description>
                <a-typography-text>{{item.description}}</a-typography-text>
                <a-typography-text v-if="item.voucher_type === 'SAME_PRICE'">{{`${$t('SAME_PRICE')} ${formatMoney(item.condition.discount.same_price)} VNĐ  `}}</a-typography-text>
                <a-typography-text style="display: inline-block" v-if="item.voucher_type === 'SAME_PRICE' || item.voucher_type === 'DISCOUNT_FOR_PRODUCT' || item.voucher_type === 'BUY_X_GIVE_AWAY_Y' || item.voucher_type === 'BUY_A_DISCOUNT_B'">{{`Áp dụng cho các sản phẩm:`}}
                  <a-typography-text v-for="i in item.condition.apply.products" :key="i">
                    {{getProductName(i)}},
                  </a-typography-text>
                </a-typography-text>
                <a-typography-text style="display: inline-block" v-if="item.voucher_type === 'DISCOUNT_FOR_PRODUCT' || item.voucher_type === 'BUY_X_GIVE_AWAY_Y' || item.voucher_type === 'BUY_A_DISCOUNT_B' || item.voucher_type === 'DISCOUNT_FOR_TOTAL_BILL'">{{`${$t('ONLY_APPLY_FOR_BILL_FROM')}  ${formatMoney(item.condition.discount.apply_to_bill_from)} VNĐ  `}}</a-typography-text>
                <a-typography-text style="display: inline-block" v-if="item.condition.discount.discount_max">{{`${$t('MAXIMUM_DISCOUNT_VALUE')}  ${formatMoney(item.condition.discount.discount_max)} VNĐ  `}}</a-typography-text>

              </template>


          </a-list-item-meta>

        </a-list-item>
      </template>
    </a-list>
  </a-modal>
</template>

<script lang="ts">
import {computed, defineComponent, onBeforeMount, onMounted, reactive, ref, toRefs, UnwrapRef, watch} from "vue";
import {
  DeleteOutlined,
  EnvironmentOutlined,
  PlusOutlined,
  SaveOutlined,
} from "@ant-design/icons-vue";
import {Stocks} from "@/models/Stocks/Stocks";
import {useStore} from "vuex";
import APIs from "@/APIs/APIs";
import {Customer, CustomerForm, CustomersForm} from "@/models/Customers/Customers";
import {FormOrder, FormState, Orders, ShippingMethod} from "@/models/Orders/Orders";
import {Province} from "@/models/Dictionary/Dictionary";
import {DictionaryActionTypes} from "@/store/modules/dictionary/action-types";
import {StockProductRequest} from "@/APIs/request/ProductRequest";
import {Product, ProductStock, StockProduct} from "@/models/Products/Products";
import {ColumnProps} from "ant-design-vue/es/table/Column";
import RouterEndpoint from "@/router/RouterEndpoint";
import {message, notification} from "ant-design-vue";
import {useRoute, useRouter} from "vue-router";
import {MixUtils} from "@/utils/Utils";
import {StockActionTypes} from "@/store/modules/stocks/action-types";
import {useI18n} from "vue-i18n";
import Order from "@/views/Orders/Order.vue";
import {ValidateErrorEntity} from "ant-design-vue/es/form/interface";
import {Locale} from "@/models/locales/Locales";
import {ApiResponse} from "apisauce";
import {CustomerResponse} from "@/APIs/response/S_HttpResponse";
import {VoucherActionTypes} from "@/store/modules/voucher/action-types";
import dayjs from "dayjs";
import {CustomersActionTypes} from "@/store/modules/customer/action-types";
import useUserStore from "@/store/User";

const shippingProviders = [
  {
    id: "VIETTEL",
    label: "Viettel Post"
  },
  {
    id: "BEST",
    label: "Best Express"
  },

  {
    id: "GHTK",
    label: "Giao hàng tiết kiệm"
  },
  {
    id: "GHN",
    label: "Giao hàng nhanh"
  },
  {
    id: "SuperShip",
    label: "SuperShip"
  },
  {
    id: "VNPOST",
    label: "VNPost"
  },
]


type Key = ColumnProps['key'];
let customerSearchTimeout=0

export default defineComponent({
  name: "Order",
  components: {
    EnvironmentOutlined,
    DeleteOutlined,
    // PlusOutlined,
    SaveOutlined
  },
  created() {
    this.$watch(
        () => this.$route.params,
        () => {
          if(this.$route.params.id){
            if(this.$route.params.id!="0"){
              this.fetchOrder(this.$route.params.id)
            }
          }
          this.handleSearchProduct('')
        },
        // fetch the data when the view is created and the data is
        // already being observed
        { immediate: true }
    )
  },
  setup() {
    const formRef = ref()
    const productModalVisible = ref(false);
    const router = useRouter()
    const route = useRoute()
    const store = useStore()
    const i18n = useI18n()
    const usePoint = ref({})
    const formState: UnwrapRef<FormState>= reactive({})
    const products = ref<StockProduct[]>([])
    const productSelected = ref<StockProduct[]>([])
    const shippingMethods = ref<ShippingMethod[]>([])
    const visible = ref(false)
    const visible2 = ref(false)
    const formStateOrder: UnwrapRef<FormOrder>= reactive({
      shipping_ward_id: undefined,
      shipping_district_id: undefined,
      social_id:undefined
    })
    const customerVoucher = ref({
      voucher_id:'',
      customer_id:0,
      order_number:'',
    })


    const provinceSearchText = ref('')
    const collapseActive = ref(0)
    const districtSearchText = ref('')
    const productAddSearch = ref('')
    const wardSearchText = ref('')
    const customerSearchLoading=ref(false)

    const getStocks = computed((): Stocks[] | undefined => store.getters.getStocks.stocks)
    const getProvinces = computed((): Province[]| undefined => {
      if(provinceSearchText.value){
        return store.getters.getProvinces?.filter((x: any) => MixUtils.methods.removeVietnamese2(x.name).includes(MixUtils.methods.removeVietnamese2(provinceSearchText.value)))
      }else{
        return store.getters.getProvinces
      }
    })
    const getDistricts = computed((): Province[]| undefined =>{
      if(districtSearchText.value){
        return  store.getters.getDistricts?.filter((x: any) => MixUtils.methods.removeVietnamese2(x.name).includes(MixUtils.methods.removeVietnamese2(districtSearchText.value)))
      }else{
        return  store.getters.getDistricts
      }
    })
    const getWards = computed((): Province[]| undefined => {
      if(wardSearchText.value){
        return  store.getters.getWards?.filter((x: any) => MixUtils.methods.removeVietnamese2(x.name).includes(MixUtils.methods.removeVietnamese2(wardSearchText.value)))
      }else{
        return  store.getters.getWards
      }
    })
    const getCurrentShop=computed(()=>store.getters.getCurrentShop)

    if(getStocks.value && getStocks.value?.length>0){
      formState.stock_id = getStocks.value[0].id
    }

    const stockProductRequest: StockProductRequest = reactive({ page: 1, page_size: 9999999, stock: formState.stock_id })
     const handleVoucher = (data:any) => {
      const voucher = data.filter((item:any) => {
          if(formStateOrder.page_id && item.condition.page_application.includes(formStateOrder.page_id)){
            return item
          }
          if(item.condition.page_application[0] === "0"){
            return item
          }
        })
  return voucher
    }
     const productColumns = computed(()=>[
      {
        title: i18n.t('ACTION'),
        key: 'action',
        slots: {customRender: 'action'},
      },
      {
        title: i18n.t('NAME'),
        dataIndex: 'product_name',
        key: 'product_name',
        slots: {customRender: 'product_name'},
      },
      {
        title: i18n.t('IMAGE'),
        dataIndex: 'avatar_url',
        key: 'avatar_url',
        slots: {customRender: 'avatar_url'},
      },
      {
        title: i18n.t('sale_price'),
        key: 'unit_price',
        dataIndex: 'unit_price',
        slots: {customRender: 'unit_price'},
      },
      {
        title: i18n.t('Quantity'),
        key: 'quantity_order',
        dataIndex: 'quantity_order',
        slots: {customRender: 'quantity'},
      },
      {
        title: i18n.t('Weight'),
        key: 'weight',
        dataIndex: 'weight',
      },
      {
        title: i18n.t('TOTAL'),
        key: 'total',
        dataIndex: 'total',
        slots: {customRender: 'total'},
      },
      {
        title: i18n.t('TOTAL'),
        key: 'total',
        dataIndex: 'total',
        slots: {customRender: 'total'},
      },
    ]);

    const productSearchColumns =computed(()=>[
      {
        title: i18n.t('Code'),
        dataIndex: 'product_code',
        key: 'product_code',
        // slots: {customRender: 'code'},
      },
      {
        title: i18n.t('IMAGE'),
        dataIndex: 'avatar_url',
        key: 'avatar_url',
        slots: {customRender: 'avatar_url'},
        width: 100
      },
      {
        title:i18n.t('NAME'),
        dataIndex: 'group_name',
        key: 'group_name',
        slots: {customRender: 'group_name'},
      },
      {
        title: i18n.t('product_version'),
        dataIndex: 'product_name',
        key: 'product_name',
        slots: {customRender: 'product_name'},
        width: '20%'
      },

      {
        title: i18n.t('sale_price'),
        key: 'unit_price',
        dataIndex: 'unit_price',
        slots: {customRender: 'unit_price'},
      },
      {
        title: i18n.t('Quantity'),
        key: 'quantity',
        dataIndex: 'quantity',
        slots: {customRender: 'quantity'},
      },
      {
        title: i18n.t('Available'),
        key: 'available',
        dataIndex: 'available',
        slots: {customRender: 'available'},
      },
    ]);

    const rowSelection = {

      onSelectAll: (selected: boolean) => {
        console.log(selected);
      },
      getCheckboxProps: (record: StockProduct) => ({
        disabled: record.available <= 0 || productSelected.value.map(p=>p.id+'').includes(record.id +''),
        name: record.product_name + " not in stock",
      }),
    };
    const rules = computed(()=>{
      if(formState.ship_checked){
        return {
          shipping_name: [
            {required: true, message: "Please input customer name", trigger: "blur"},
          ],
          shipping_phone: [
            {required: true, message: "Please input", trigger: "blur"},
          ],
          shipping_province_id: [
            {required: true, transform: (value: any) => value+'', message: "Please input", trigger: "blur"},
          ],
          shipping_district_id: [
            {required: true, transform: (value: any) => value+'', message: "Please select", trigger: "blur"},
          ],
          shipping_ward_id: [
            {required: true, transform: (value: any) => value+'', message: "Please select", trigger: "blur"},
          ],
          shipping_address: [
            {required: true, message: "Please input", trigger: "blur"},
          ],
        }
      }else{
        return {
        }
      }
    })

    const handleOnChangePage = (stock_id: number) => {
      console.log(stock_id)
      formState.stock_id = stock_id
      // fetchProductGroups()
    }

    const fetchStocks=()=>{
      store.dispatch(StockActionTypes.FETCH_STOCKS,{ page: 1, page_size: 1000 })
    }

    const provinceSearch = (search: string) => {
      provinceSearchText.value  = search
    }
    const districtSearch = (search: string) => {
      districtSearchText.value  = search
    }
    const wardSearch = (search: string) => {
      wardSearchText.value  = search
    }


    const customers = ref<Customer[]>([]);
    const productsAdd = ref<StockProduct[]>([]);

    if (route.query.customer_id){
      APIs.init().getCustomer({id: parseInt(route.query.customer_id+'')}).then((response)=>{
        customerSearchLoading.value=false
        if (response.status === 200 && response.data?.status === 200) {
          if(response.data.data){
            formStateOrder.shipping_district_id = response.data.data.district_id?parseInt(response.data.data.district_id):0
            formStateOrder.shipping_ward_id = response.data.data.ward_id
            formStateOrder.shipping_province_id = response.data.data.province_id?parseInt(response.data.data.province_id):0
            formStateOrder.shipping_province_name = response.data.data.province_name
            formStateOrder.shipping_district_name = response.data.data.district_name
            formStateOrder.shipping_name = response.data.data.full_name
            formStateOrder.phone = response.data.data.phone
            formStateOrder.shipping_ward_name = response.data.data.ward_name
            formStateOrder.address = response.data.data.address
            formStateOrder.shipping_address = response.data.data.address
            formStateOrder.customer_id = response.data.data.id
            formStateOrder.social_id = response.data.data.social_id
            formStateOrder.page_id = response.data.data.page_id

            console.log(formStateOrder)
            if(response.data.data.province_id){
              store.dispatch(DictionaryActionTypes.FETCH_DISTRICTS,{payload: {province_id: response.data.data.province_id }})
            }
            if(response.data.data.district_id){
              store.dispatch(DictionaryActionTypes.FETCH_WARDS, {payload: {district_id: response.data.data.district_id }})
            }
          }
        }
      }).catch(e=>{
        customerSearchLoading.value=false
        console.error(e)
      })
    }

    const handleSearch = async (value: string) => {
        if(value.length>5){
          customerSearchLoading.value=true
          APIs.init().getCustomerSearch({search: formStateOrder.shipping_phone}).then((response)=>{
            customerSearchLoading.value=false
            if (response.status === 200 && response.data?.status === 200) {
              if(response.data.data)
                console.log(response.data.data)

              customers.value = response.data.data.map(item=>{
                item.value = item.full_name +' - '+item.phone+' ('+item.id+')\n'+item.address
                return item
              })
            }
          }).catch(e=>{
            customerSearchLoading.value=false
            console.error(e)
          })

        }
    };

    const handleSearchProduct = async (value: string) => {

          // customerSearchLoading.value=true
          // APIs.init().getAllProduct({search: formStateOrder.phone}).then((response)=>{
          //   customerSearchLoading.value=false
          //   if (response.status === 200 && response.data?.status === 200) {
          //     if(response.data.data)
          //       console.log(response.data.data)
          //
          //     customers.value = response.data.data.map(item=>{
          //       item.value = item.full_name +' - '+item.phone+' ('+item.id+')\n'+item.address
          //       return item
          //     })
          //   }
          // }).catch(e=>{
          //   customerSearchLoading.value=false
          //   console.error(e)
          // })

      stockProductRequest.search = value
      stockProductRequest.stock = formState.stock_id
      stockProductRequest.page = 1
      const response = await APIs.init().getStockProducts(stockProductRequest)
      if (response.status === 200 && response.data?.status === 200) {
        productsAdd.value = response.data.data?response.data.data.map(item=>{
          item.key = item.product_name +' (' +item.id+')';
          item.value = item.product_name +' (' +item.id+')';
          if(!item.quantity_order)
            item.quantity_order = 1
          return item
        }):[]
      }

    };


    const selectCustomer = (customer_id: string)=>{
      console.log('customers', customers)

      console.log('customer_id',customer_id)
      const userIds =  customer_id.match(/\((.*?)\)/g)
      if(userIds && userIds?.length>0){
        const userId = Number(userIds[userIds?.length-1].replace(/\(/g, '').replace(/\)/g, ''))
        console.log('selectCustomer', customer_id)
        const customerFound = customers.value.find(customer=>customer.id===userId)

        console.log('customerFound', customerFound)
        if(customerFound){

          // formState.customer = customerFound
          console.log('formStateOrder', customerFound.district_id?parseInt(customerFound.district_id):0)

          formStateOrder.shipping_district_id = customerFound.district_id?parseInt(customerFound.district_id):0
          formStateOrder.shipping_ward_id = customerFound.ward_id
          formStateOrder.shipping_province_id = customerFound.province_id?parseInt(customerFound.province_id):0
          formStateOrder.shipping_province_name = customerFound.province_name
          formStateOrder.shipping_district_name = customerFound.district_name
          formStateOrder.shipping_name = customerFound.full_name
          formStateOrder.phone = customerFound.phone
          formStateOrder.shipping_ward_name = customerFound.ward_name
          formStateOrder.address = customerFound.address
          formStateOrder.customer_id = customerFound.id
          formStateOrder.social_id = customerFound.social_id
          formStateOrder.page_id = customerFound.page_id
          formStateOrder.shipping_phone = customerFound.phone
          formStateOrder.shipping_address  = customerFound.address
          console.log(formStateOrder)
          if(customerFound.province_id){
            store.dispatch(DictionaryActionTypes.FETCH_DISTRICTS,{payload: {province_id: customerFound.province_id }})
          }
          if(customerFound.district_id){
            store.dispatch(DictionaryActionTypes.FETCH_WARDS, {payload: {district_id: customerFound.district_id }})
          }
        }
        customers.value = []
      }


    }


    const selectProduct = (product_string: string)=>{
      console.log('product_string', product_string)

      const ids =  product_string.match(/\((.*?)\)/g)
      if(ids && ids?.length>0){
        const id = Number(ids[ids?.length-1].replace(/\(/g, '').replace(/\)/g, ''))
        console.log('productid', product_string)
        const productFound = productsAdd.value.find(product=>product.id===id)
        const productSelectedFound = productSelected.value.find(product=>product.id===id)

        console.log('product', productFound)
        if(productFound && !productSelectedFound){
          productSelected.value.push(productFound);
          // formState.customer = customerFound

        }
      }
      handleSearchProduct('')
    }


    const provinceChange=(province_id: number)=>{
      store.dispatch(DictionaryActionTypes.FETCH_DISTRICTS,{payload: {province_id: province_id }})
      formStateOrder.shipping_district_id = undefined
      formStateOrder.shipping_ward_id = undefined
    }

    const fetchProvince=()=>{
      store.dispatch(DictionaryActionTypes.FETCH_PROVINCES, undefined)
    }
    const districtChange=(district_id: number)=>{
      store.dispatch(DictionaryActionTypes.FETCH_WARDS, {payload: {district_id: district_id }})
      formStateOrder.shipping_ward_id = undefined
    }
    const getStockProducts=computed(() =>store.getters.getStockProducts)
    const onSearchProduct=async ()=>{
      stockProductRequest.stock = formState.stock_id
      stockProductRequest.page = 1
      const response = await APIs.init().getStockProducts(stockProductRequest)
      if (response.status === 200 && response.data?.status === 200) {
        products.value = response.data.data?response.data.data.map(item=>{
          item.key = item.id?.toString()
          if(!item.quantity_order)
            item.quantity_order = 1
          return item
        }):[]
      }
    }
    const onChangeProduct=async (id: any)=>{
      console.log('product_change', id)
      stockProductRequest.stock = formState.stock_id
      stockProductRequest.page = 1
      const response = await APIs.init().getStockProducts(stockProductRequest)
      if (response.status === 200 && response.data?.status === 200) {
        products.value = response.data.data?response.data.data.map(item=>{
          item.key = item.id?.toString()
          if(!item.quantity_order)
            item.quantity_order = 1
          return item
        }):[]
      }
    }

    onBeforeMount(()=>{
      fetchProvince()
    })

    const fetchOrder = async (id:  any)=>{
      const response = await APIs.init().getOrderDetail({id: id})
      if (response.status === 200 && response.data?.status === 200) {
        if(response.data.data){
          // order.value = response.data.data
          formState.id = response.data.data.id
          formState.order_number = response.data.data.order_number
          formState.stock_id = response.data.data.stock_id
          formState.discount = response.data.data.discount +""
          formState.ship_cost = response.data.data.ship_revenue
          formState.paid = response.data.data.prepaid +""
          formState.note_bill = response.data.data.note_bill
          formState.ship_checked = response.data.data.shipping_method!=="default"
          formState.note= response.data.data.note

          // const address = response.data.data.shipping_address?response.data.data.shipping_address.split(','):[]
          Object.assign(formStateOrder, response.data.data)
          formStateOrder.customer_id = response.data.data.customer_id
          formStateOrder.shipping_name = response.data.data.shipping_name
          formStateOrder.phone = response.data.data.shipping_phone
          formStateOrder.shipping_province_id = response.data.data.shipping_province_id
          formStateOrder.shipping_district_id = response.data.data.shipping_district_id
          formStateOrder.shipping_ward_id = response.data.data.shipping_ward_id
          formStateOrder.shipping_address = response.data.data.shipping_address

          formState.shipping_fee_collected  = response.data.data.ship_fee_collected
          formState.discount  = response.data.data.discount+''
          formState.paid  = response.data.data.prepaid+''
          try{

            if(response.data.data.shipping_method?.includes('-')){
              formState.shipping_provider = response.data.data.shipping_method?.split('-')[0]
              formState.shipping_method = response.data.data.shipping_method?.split('-')[1]
              if(formState.shipping_provider){
                setTimeout(()=>{
                  shippingProviderOnChange(formState.shipping_provider!)
                  shippingMethodOnChange(formState.shipping_method!)
                }, 2000)
              }
            }
          }catch(e){
            console.log(e)
          }
          console.log('formStateOrder', formStateOrder)


          if(response.data.data.shipping_province_id)
            store.dispatch(DictionaryActionTypes.FETCH_DISTRICTS,{payload: {province_id: response.data.data.shipping_province_id }})

          if(response.data.data.shipping_district_id)
            store.dispatch(DictionaryActionTypes.FETCH_WARDS, {payload: {district_id: response.data.data.shipping_district_id }})


          if(response.data.data.products){
            productSelected.value = response.data.data.products?.map(p=>{
              p.quantity_order = p.quantity
              return p
            })


          }
        }

      }
      getUsePoint()
    }

    const showProductModal = ()=>{
      stockProductRequest.search = undefined
      productModalVisible.value = true
      state.selectedRowKeys = [];
      onSearchProduct()
    }

    const state = reactive<{
      selectedRowKeys: Key[];
      loading: boolean;
    }>({
      selectedRowKeys: [], // Check here to configure the default column
      loading: false,
    });
    const hasSelected = computed(() => state.selectedRowKeys.length > 0);

    const onSelectChange = (selectedRowKeys: Key[]) => {
      console.log('selectedRowKeys changed: ', selectedRowKeys);
      state.selectedRowKeys = selectedRowKeys;
    };

    const getCheckboxProps = (record: StockProduct) => ({
      disabled: record.quantity ===  0,
    })
    const submitSelectedProduct = () => {
      productModalVisible.value = false
      productSelected.value = products.value.filter(p=>state.selectedRowKeys.includes(p.id?.toString()))
      console.log(productSelected.value)
    }
    console.log(formState,'formState')
    const deleteProductSelected = (record: StockProduct) => {
      productSelected.value = productSelected.value.filter(p=>p.id !== record.id)
    }


    const handleSelectVoucher = async (voucherId:any) => {
      const voucher = getVoucherActive.value.find((item:any) => item.id === voucherId)
      let total = 0
      productSelected.value.map((item:any) => {
        total += item.unit_price * Number(item.quantity_order)
      })
      if(total < voucher.condition.discount.apply_to_bill_from){
        message.warn('bạn cần tiêu thêm ' + MixUtils.methods.formatMoney(voucher.condition.discount.apply_to_bill_from - total) + ' để sử dụng voucher  này' )

        customerVoucher.value.voucher_id = ""
      }else {
        const customerUseVoucher = await APIs.init().customerUseVoucher({customer_id:formStateOrder.customer_id,voucher_id: voucherId})
        if(customerUseVoucher.data?.data && customerUseVoucher.data?.data.length > 0 ){

          const res = await APIs.init().getVoucherConfig({id:voucherId})
          const timeUseVoucher = customerUseVoucher.data.data.filter((item:any) => dayjs(item.time).format("DD/MM/YYYY") === dayjs(new Date()).format("DD/MM/YYYY"))
          if(res.data?.data.member_can_use_voucher === "MANY"){
            if(timeUseVoucher && timeUseVoucher.length > 0){
              if(timeUseVoucher.length === res.data?.data.number_of_uses_per_day  ){
                message.warn('Hôm nay bạn là đã hết lượt dùng voucher này')
                customerVoucher.value.voucher_id = ""
              }else {
                const voucher = getVoucherActive.value.find((item:any) => item.id === voucherId)
                if(voucher.voucher_type === 'DISCOUNT_FOR_TOTAL_BILL'){
                  formState.discount = ""
                  // @ts-ignore
                  let total = 0
                  productSelected.value.map((item:any) => {
                    total += item.unit_price * Number(item.quantity_order)
                  })
                  console.log(total >= voucher.condition.discount.apply_to_bill_from,'total')
                  if(total >= voucher.condition.discount.apply_to_bill_from){
                    if(voucher.condition.discount.type === 'percent'){
                      formState.discount = voucher.condition.discount.discount_max ? total/100*voucher.condition.discount.discount > voucher.condition.discount.discount_max ? voucher.condition.discount.discount_max : total/100*voucher.condition.discount.discount : total/100*voucher.condition.discount.discount +""
                    }else {
                      formState.discount = voucher.condition.discount.discount +""
                    }

                    console.log(formState)
                  }
                  // eslint-disable-next-line no-dupe-else-if
                }else if(voucher.voucher_type === 'DISCOUNT_FOR_PRODUCT'){
                  console.log(productSelected.value,'productSelected.value')
                  let total = 0
                  productSelected.value.map((item:any) => {
                    total += item.unit_price * Number(item.quantity_order)

                  })
                  console.log(total >= voucher.condition.discount.apply_to_bill_from,'total')
                  if(total >= voucher.condition.discount.apply_to_bill_from) {
                    formState.discount = ""
                    if (voucher.condition.apply.type === "apply_on") {
                      if (voucher.condition.apply.condition === "group") {
                        const checkGroup = ref(false)
                        for (let i = 0;i < productSelected.value.length;i++) {
                          if(voucher.condition.apply.group_products.includes(productSelected.value[i].id)){
                            checkGroup.value = true
                          }else {
                            checkGroup.value = false
                            break
                          }

                        }
                        console.log(checkGroup.value)
                        if(checkGroup.value){
                          productSelected.value = productSelected.value.map((item: any) => {
                            // @ts-ignore
                            if (voucher.condition.discount.type === "percent" && voucher.condition.apply.group_products.includes(item.id)) {
                              // @ts-ignore
                              formState.discount = (Number(formState.discount) +(item.unit_price/100 * voucher.condition.discount.discount)) + ""
                            } else if (voucher.condition.discount.type !== "percent" && voucher.condition.apply.group_products.includes(item.id)) {
                              // @ts-ignore
                              formState.discount = Number(formState.discount) +  voucher.condition.discount.discount
                            }
                            return item
                          })
                        }else {

                          message.warn('nhóm sản phẩm chưa đủ,nên không thể dùng Voucher này')
                          customerVoucher.value.voucher_id = ""
                          console.log(customerVoucher.value.voucher_id,'customer')
                        }
                      } else {
                        // @ts-ignore
                        if (voucher.condition.discount.product_num) {
                          if (voucher.condition.discount.price_type === 'max') {
                            const productMax = ref<any>(0)
                            for (let i = 1; i < productSelected.value.length; i++) {
                              productMax.value = productSelected.value[0].id
                              console.log(productMax.value, 'productMax.value')
                              // @ts-ignore
                              if (productSelected.value[0].unit_price < productSelected.value[i].unit_price) {
                                // @ts-ignore
                                productMax.value = productSelected.value[i].id
                              }
                            }
                            console.log(productMax.value, 'productMax.value')
                            productSelected.value.map((item: any) => {
                              // @ts-ignore
                              if (voucher.condition.discount.type === "percent" && voucher.condition.apply.products.includes(item.id) && productMax.value === item.id) {
                                // @ts-ignore
                                formState.discount = voucher.condition.discount.discount_max ? (Number(formState.discount) + ((item.unit_price / 100 * voucher.condition.discount.discount) < voucher.condition.discount.discount_max ? (Number(formState.discount) + (item.unit_price / 100 * voucher.condition.discount.discount)) : voucher.condition.discount.discount_max)) : (Number(formState.discount) + (item.unit_price / 100 * voucher.condition.discount.discount)) + ""
                                // @ts-ignores
                              } else if (voucher.condition.discount.type !== "percent" && voucher.condition.apply.products.includes(item.id) && productMax.value === item.id) {
                                // @ts-ignore
                                formState.discount = Number(formState.discount) + voucher.condition.discount.discount
                              }

                            })
                          } else {
                            const productMin = ref<any>(0)
                            for (let i = 1; i < productSelected.value.length; i++) {
                              productMin.value = productSelected.value[0].id
                              console.log(productMin.value, 'productMax.value')
                              // @ts-ignore
                              if (productSelected.value[0].unit_price > productSelected.value[i].unit_price) {
                                productMin.value = productMin.value[i]
                              }
                            }
                            console.log(productMin.value, 'productMax.value')
                            productSelected.value.map((item: any) => {
                              // @ts-ignore
                              if (voucher.condition.discount.type === "percent" && voucher.condition.apply.products.includes(item.id) && productMin.value === item.id) {
                                // @ts-ignore
                                formState.discount = voucher.condition.discount.discount_max ? (Number(formState.discount) + ((item.unit_price / 100 * voucher.condition.discount.discount) < voucher.condition.discount.discount_max ? (Number(formState.discount) + (item.unit_price / 100 * voucher.condition.discount.discount)) : voucher.condition.discount.discount_max)) : (Number(formState.discount) + (item.unit_price / 100 * voucher.condition.discount.discount)) + ""
                                // @ts-ignores
                              } else if (voucher.condition.discount.type !== "percent" && voucher.condition.apply.products.includes(item.id) && productMin.value === item.id) {
                                // @ts-ignore
                                formState.discount = Number(formState.discount) + voucher.condition.discount.discount
                              }

                            })


                          }

                        } else if (voucher.condition.discount.discount_form) {

                          productSelected.value.map((item: any, index: any) => {
                            // @ts-ignore
                            if (voucher.condition.discount.type === "percent" && voucher.condition.apply.products.includes(item.id) && Number(item.quantity_order) >= voucher.condition.discount.discount_form) {
                              // @ts-ignore
                              formState.discount = (voucher.condition.discount.discount_max ? (Number(formState.discount) + ((item.unit_price / 100 * voucher.condition.discount.discount) < voucher.condition.discount.discount_max ? (item.unit_price / 100 * voucher.condition.discount.discount) : voucher.condition.discount.discount_max)) : (Number(formState.discount) + (item.unit_price / 100 * voucher.condition.discount.discount))) * (voucher.condition.discount.discount_form === Number(item.quantity_order) ? 1 : Number(item.quantity_order) - voucher.condition.discount.discount_form + 1) + ""
                              // @ts-ignores
                            } else if (voucher.condition.discount.type !== "percent" && voucher.condition.apply.products.includes(item.id) && Number(item.quantity_order) >= voucher.condition.discount.discount_form) {
                              // @ts-ignore
                              formState.discount = Number(formState.discount) + (voucher.condition.discount.discount * (voucher.condition.discount.discount_form === Number(item.quantity_order) ? 1 : Number(item.quantity_order) - voucher.condition.discount.discount_form + 1))
                            }

                          })


                        } else {
                          productSelected.value = productSelected.value.map((item: any) => {
                            // @ts-ignore
                            if (voucher.condition.discount.type === "percent" && voucher.condition.apply.products.includes(item.id)) {
                              // @ts-ignore
                              formState.discount = (voucher.condition.discount.discount_max ? (Number(formState.discount) + ((item.unit_price / 100 * voucher.condition.discount.discount) < voucher.condition.discount.discount_max ? (item.unit_price / 100 * voucher.condition.discount.discount) : voucher.condition.discount.discount_max)) : (Number(formState.discount) + (item.unit_price / 100 * voucher.condition.discount.discount))) * Number(item.quantity_order) + ""
                            } else if (voucher.condition.discount.type !== "percent" && voucher.condition.apply.products.includes(item.id)) {
                              // @ts-ignore
                              formState.discount = Number(formState.discount) + (voucher.condition.discount.discount * Number(item.quantity_order))
                            }
                            return item
                          })
                        }
                      }
                    }else {
                      if (voucher.condition.apply.condition === "group") {
                        const checkGroup = ref(false)
                        for (let i = 0; i < productSelected.value.length; i++) {
                          if (!voucher.condition.apply.group_products.includes(productSelected.value[i].id)) {
                            checkGroup.value = true
                            break
                          } else {
                            checkGroup.value = false

                          }

                        }
                        console.log(checkGroup.value)
                        if (checkGroup.value) {
                          productSelected.value = productSelected.value.map((item: any) => {
                            // @ts-ignore
                            if (voucher.condition.discount.type === "percent" && !voucher.condition.apply.group_products.includes(item.id)) {
                              // @ts-ignore
                              formState.discount = (Number(formState.discount) + (item.unit_price / 100 * voucher.condition.discount.discount)) + ""
                            } else if (voucher.condition.discount.type !== "percent" && !voucher.condition.apply.group_products.includes(item.id)) {
                              // @ts-ignore
                              formState.discount = Number(formState.discount) + voucher.condition.discount.discount
                            }
                            return item
                          })
                        } else {

                          message.warn('không áp dụng trên nhóm sản phẩm này')
                          customerVoucher.value.voucher_id = ""
                          console.log(customerVoucher.value.voucher_id,'customer')
                        }
                      } else {
                        if (voucher.condition.discount.product_num) {
                          if (voucher.condition.discount.price_type === 'max') {
                            const productMax = ref<any>(0)
                            for (let i = 1; i < productSelected.value.length; i++) {
                              productMax.value = productSelected.value[0].id
                              console.log(productMax.value, 'productMax.value')
                              // @ts-ignore
                              if (productSelected.value[0].unit_price < productSelected.value[i].unit_price) {
                                productMax.value = productSelected.value[i].id
                              }
                            }
                            console.log(productMax.value, 'productMax.value')
                            productSelected.value.map((item: any) => {
                              // @ts-ignore
                              if (voucher.condition.discount.type === "percent" && !voucher.condition.apply.products.includes(item.id) && productMax.value === item.id) {
                                // @ts-ignore
                                formState.discount = voucher.condition.discount.discount_max ? (Number(formState.discount) + ((item.unit_price / 100 * voucher.condition.discount.discount) < voucher.condition.discount.discount_max ? (Number(formState.discount) + (item.unit_price / 100 * voucher.condition.discount.discount)) : voucher.condition.discount.discount_max)) : (Number(formState.discount) + (item.unit_price / 100 * voucher.condition.discount.discount)) + ""
                                // @ts-ignores
                              } else if (voucher.condition.discount.type !== "percent" && !voucher.condition.apply.products.includes(item.id) && productMax.value === item.id) {
                                // @ts-ignore
                                formState.discount = Number(formState.discount) + voucher.condition.discount.discount
                              }

                            })
                          } else {
                            const productMin = ref<any>(0)
                            for (let i = 1; i < productSelected.value.length; i++) {
                              productMin.value = productSelected.value[0].id
                              console.log(productMin.value, 'productMax.value')
                              // @ts-ignore
                              if (productSelected.value[0].unit_price > productSelected.value[i].unit_price) {
                                productMin.value = productMin.value[i]
                              }
                            }
                            console.log(productMin.value, 'productMax.value')
                            productSelected.value.map((item: any) => {
                              // @ts-ignore
                              if (voucher.condition.discount.type === "percent" && !voucher.condition.apply.products.includes(item.id) && productMin.value === item.id) {
                                // @ts-ignore
                                formState.discount = voucher.condition.discount.discount_max ? (Number(formState.discount) + ((item.unit_price / 100 * voucher.condition.discount.discount) < voucher.condition.discount.discount_max ? (Number(formState.discount) + (item.unit_price / 100 * voucher.condition.discount.discount)) : voucher.condition.discount.discount_max)) : (Number(formState.discount) + (item.unit_price / 100 * voucher.condition.discount.discount)) + ""
                                // @ts-ignores
                              } else if (voucher.condition.discount.type !== "percent" && !voucher.condition.apply.products.includes(item.id) && productMin.value === item.id) {
                                // @ts-ignore
                                formState.discount = Number(formState.discount) + voucher.condition.discount.discount
                              }

                            })


                          }

                        } else if (voucher.condition.discount.discount_form) {

                          productSelected.value.map((item: any, index: any) => {
                            // @ts-ignore
                            if (voucher.condition.discount.type === "percent" && !voucher.condition.apply.products.includes(item.id) && Number(item.quantity_order) >= voucher.condition.discount.discount_form) {
                              // @ts-ignore
                              formState.discount = (voucher.condition.discount.discount_max ? (Number(formState.discount) + ((item.unit_price / 100 * voucher.condition.discount.discount) < voucher.condition.discount.discount_max ? (item.unit_price / 100 * voucher.condition.discount.discount) : voucher.condition.discount.discount_max)) : (Number(formState.discount) + (item.unit_price / 100 * voucher.condition.discount.discount))) * (voucher.condition.discount.discount_form === Number(item.quantity_order) ? 1 : Number(item.quantity_order) - voucher.condition.discount.discount_form + 1) + ""
                              // @ts-ignores
                            } else if (voucher.condition.discount.type !== "percent" && !voucher.condition.apply.products.includes(item.id) && Number(item.quantity_order) >= voucher.condition.discount.discount_form) {
                              // @ts-ignore
                              formState.discount = Number(formState.discount) + (voucher.condition.discount.discount * (voucher.condition.discount.discount_form === Number(item.quantity_order) ? 1 : Number(item.quantity_order) - voucher.condition.discount.discount_form + 1))
                            }

                          })


                        } else {
                          // @ts-ignore
                          productSelected.value = productSelected.value.map((item: any) => {
                            // @ts-ignore
                            if (voucher.condition.discount.type === "percent" && !voucher.condition.apply.products.includes(item.id)) {
                              // @ts-ignore
                              formState.discount = (voucher.condition.discount.discount_max ? (Number(formState.discount) + ((item.unit_price / 100 * voucher.condition.discount.discount) < voucher.condition.discount.discount_max ? (item.unit_price / 100 * voucher.condition.discount.discount) : voucher.condition.discount.discount_max)) : (Number(formState.discount) + (item.unit_price / 100 * voucher.condition.discount.discount))) * Number(item.quantity_order) + ""
                            } else if (voucher.condition.discount.type !== "percent" && !voucher.condition.apply.products.includes(item.id)) {
                              // @ts-ignore
                              formState.discount = Number(formState.discount) + (voucher.condition.discount.discount * Number(item.quantity_order))
                            }
                            return item
                          })
                        }

                        // }
                      }
                    }
                  }
                }else if(voucher.voucher_type === 'BUY_X_GIVE_AWAY_Y'){
                  console.log(productSelected.value,'productSelected.value')
                  let total = 0
                  productSelected.value.map((item:any) => {
                    total += item.unit_price * Number(item.quantity_order)
                  })
                  console.log(total >= voucher.condition.discount.apply_to_bill_from,'total')
                  if(total >= voucher.condition.discount.apply_to_bill_from) {
                    formState.discount = ""
                    // @ts-ignore
                    const check = ref(false)
                    const checkNum = ref(false)
                    productSelected.value = productSelected.value.map((item:any) => {

                      if(voucher.condition.apply.products.includes(item.id) && voucher.condition.apply.product_num === Number(item.quantity_order)){
                        checkNum.value = true
                        if(voucher.condition.apply.product_promotion.includes(item.id)){

                          item.quantity_order = Number(item.quantity_order) + voucher.condition.apply.product_promotion_num

                          formState.discount = (Number(item.quantity_order) - voucher.condition.apply.product_promotion_num) * item.unit_price + ""
                          check.value = true
                        }else {
                          check.value =  false
                        }
                      }
                      return item

                    })

                    if(!check.value && checkNum.value){
                      getStockProducts.value.products?.map((item:any) => {
                        if(voucher.condition.apply.product_promotion.includes(item.id)){
                          item.unit_price = 0
                          item.quantity_order = voucher.condition.apply.product_promotion_num + ""
                          productSelected.value.push(item)
                        }
                      })
                    }
                  }
                }else if(voucher.voucher_type === 'BUY_A_DISCOUNT_B'){
                  console.log(productSelected.value,'productSelected.value')
                  let total = 0
                  productSelected.value.map((item:any) => {
                    total += item.unit_price * Number(item.quantity_order)
                  })
                  console.log(total >= voucher.condition.discount.apply_to_bill_from,'total')
                  if(total >= voucher.condition.discount.apply_to_bill_from) {
                    formState.discount = ""
                    // @ts-ignore
                    const check = ref(false)
                    const checkNum = ref(false)
                    productSelected.value = productSelected.value.map((item:any) => {

                      if(voucher.condition.apply.products.includes(item.id) && voucher.condition.apply.product_num === Number(item.quantity_order)){
                        checkNum.value = true
                        if(voucher.condition.apply.product_promotion.includes(item.id)){

                          item.quantity_order =  + voucher.condition.apply.product_promotion_num
// @ts-ignore
                          formState.discount = formState.discount + (( Number(item.unit_price) / 100 *voucher.condition.discount.discount) * voucher.condition.apply.product_promotion_num) +""
                          check.value = true
                        }else {
                          check.value =  false
                        }
                      }
                      return item

                    })

                    if(!check.value && checkNum.value){
                      getStockProducts.value.products?.map((item:any) => {
                        if(voucher.condition.apply.product_promotion.includes(item.id)){
                          item.quantity_order = voucher.condition.apply.product_promotion_num + ""
                          // @ts-ignore
                          formState.discount = formState.discount + (item.unit_price / 100 *voucher.condition.discount.discount)+ ""
                          productSelected.value.push(item)
                        }
                      })
                    }
                  }
                }else if(voucher.voucher_type === 'SAME_PRICE'){
                  if(voucher.condition.apply.condition === "group"){
                    console.log('1')
                  }else {
                    // @ts-ignore
                    productSelected.value = productSelected.value.map((item:any) => {
                      if(voucher.condition.apply.type === "apply_on"){
                        if(voucher.condition.apply.group_products.includes(item.id)){
                          item.unit_price = voucher.condition.discount.same_price
                        }
                      }else {
                        if(!voucher.condition.apply.group_products.includes(item.id)){
                          item.unit_price = voucher.condition.discount.same_price
                        }
                      }
                      return item
                    })
                  }

                }
                console.log(voucher)
                // @ts-ignore
                customerVoucher.value = {voucher_id: voucherId,customer_id:formStateOrder.customer_id }
              }

            }
          }else {
            message.warn('Bạn là đã hết lượt dùng voucher này')
          }

        }else {
          const voucher = getVoucherActive.value.find((item:any) => item.id === voucherId)
          if(voucher.voucher_type === 'DISCOUNT_FOR_TOTAL_BILL'){
            formState.discount = ""
            // @ts-ignore
            let total = 0
            productSelected.value.map((item:any) => {
              total += item.unit_price * Number(item.quantity_order)
            })
            console.log(total >= voucher.condition.discount.apply_to_bill_from,'total')
            if(total >= voucher.condition.discount.apply_to_bill_from){
              if(voucher.condition.discount.type === 'percent'){
                formState.discount = voucher.condition.discount.discount_max ? total/100*voucher.condition.discount.discount > voucher.condition.discount.discount_max ? voucher.condition.discount.discount_max : total/100*voucher.condition.discount.discount : total/100*voucher.condition.discount.discount +""
              }else {
                formState.discount = voucher.condition.discount.discount +""
              }

              console.log(formState)
            }
            // eslint-disable-next-line no-dupe-else-if
          }else if(voucher.voucher_type === 'DISCOUNT_FOR_PRODUCT'){
            console.log(productSelected.value,'productSelected.value')
            let total = 0
            productSelected.value.map((item:any) => {
              total += item.unit_price * Number(item.quantity_order)

            })
            console.log(total >= voucher.condition.discount.apply_to_bill_from,'total')
            if(total >= voucher.condition.discount.apply_to_bill_from) {
              formState.discount = ""
              if (voucher.condition.apply.type === "apply_on") {
                if (voucher.condition.apply.condition === "group") {
                  const checkGroup = ref(false)
                  for (let i = 0;i < productSelected.value.length;i++) {
                    if(voucher.condition.apply.group_products.includes(productSelected.value[i].id)){
                      checkGroup.value = true
                    }else {
                      checkGroup.value = false
                      break
                    }

                  }
                  console.log(checkGroup.value)
                  if(checkGroup.value){
                    productSelected.value = productSelected.value.map((item: any) => {
                      // @ts-ignore
                      if (voucher.condition.discount.type === "percent" && voucher.condition.apply.group_products.includes(item.id)) {
                        // @ts-ignore
                        formState.discount = (Number(formState.discount) +(item.unit_price/100 * voucher.condition.discount.discount)) + ""
                      } else if (voucher.condition.discount.type !== "percent" && voucher.condition.apply.group_products.includes(item.id)) {
                        // @ts-ignore
                        formState.discount = Number(formState.discount) +  voucher.condition.discount.discount
                      }
                      return item
                    })
                  }else {
                    message.warn('nhóm sản phẩm chưa đủ,nên không thể dùng Voucher này')
                    customerVoucher.value.voucher_id = ""
                    console.log(customerVoucher.value.voucher_id,'customer')

                  }
                } else {
                  // @ts-ignore
                  if (voucher.condition.discount.product_num) {
                    if (voucher.condition.discount.price_type === 'max') {
                      const productMax = ref<any>(0)
                      for (let i = 1; i < productSelected.value.length; i++) {
                        productMax.value = productSelected.value[0].id
                        console.log(productMax.value, 'productMax.value')
                        // @ts-ignore
                        if (productSelected.value[0].unit_price < productSelected.value[i].unit_price) {
                          // @ts-ignore
                          productMax.value = productSelected.value[i].id
                        }
                      }
                      console.log(productMax.value, 'productMax.value')
                      productSelected.value.map((item: any) => {
                        // @ts-ignore
                        if (voucher.condition.discount.type === "percent" && voucher.condition.apply.products.includes(item.id) && productMax.value === item.id) {
                          // @ts-ignore
                          formState.discount = voucher.condition.discount.discount_max ? (Number(formState.discount) + ((item.unit_price / 100 * voucher.condition.discount.discount) < voucher.condition.discount.discount_max ? (Number(formState.discount) + (item.unit_price / 100 * voucher.condition.discount.discount)) : voucher.condition.discount.discount_max)) : (Number(formState.discount) + (item.unit_price / 100 * voucher.condition.discount.discount)) + ""
                          // @ts-ignores
                        } else if (voucher.condition.discount.type !== "percent" && voucher.condition.apply.products.includes(item.id) && productMax.value === item.id) {
                          // @ts-ignore
                          formState.discount = Number(formState.discount) + voucher.condition.discount.discount
                        }

                      })
                    } else {
                      const productMin = ref<any>(0)
                      for (let i = 1; i < productSelected.value.length; i++) {
                        productMin.value = productSelected.value[0].id
                        console.log(productMin.value, 'productMax.value')
                        // @ts-ignore
                        if (productSelected.value[0].unit_price > productSelected.value[i].unit_price) {
                          productMin.value = productMin.value[i]
                        }
                      }
                      console.log(productMin.value, 'productMax.value')
                      productSelected.value.map((item: any) => {
                        // @ts-ignore
                        if (voucher.condition.discount.type === "percent" && voucher.condition.apply.products.includes(item.id) && productMin.value === item.id) {
                          // @ts-ignore
                          formState.discount = voucher.condition.discount.discount_max ? (Number(formState.discount) + ((item.unit_price / 100 * voucher.condition.discount.discount) < voucher.condition.discount.discount_max ? (Number(formState.discount) + (item.unit_price / 100 * voucher.condition.discount.discount)) : voucher.condition.discount.discount_max)) : (Number(formState.discount) + (item.unit_price / 100 * voucher.condition.discount.discount)) + ""
                          // @ts-ignores
                        } else if (voucher.condition.discount.type !== "percent" && voucher.condition.apply.products.includes(item.id) && productMin.value === item.id) {
                          // @ts-ignore
                          formState.discount = Number(formState.discount) + voucher.condition.discount.discount
                        }

                      })


                    }

                  } else if (voucher.condition.discount.discount_form) {

                    productSelected.value.map((item: any, index: any) => {
                      // @ts-ignore
                      if (voucher.condition.discount.type === "percent" && voucher.condition.apply.products.includes(item.id) && Number(item.quantity_order) >= voucher.condition.discount.discount_form) {
                        // @ts-ignore
                        formState.discount = (voucher.condition.discount.discount_max ? (Number(formState.discount) + ((item.unit_price / 100 * voucher.condition.discount.discount) < voucher.condition.discount.discount_max ? (item.unit_price / 100 * voucher.condition.discount.discount) : voucher.condition.discount.discount_max)) : (Number(formState.discount) + (item.unit_price / 100 * voucher.condition.discount.discount))) * (voucher.condition.discount.discount_form === Number(item.quantity_order) ? 1 : Number(item.quantity_order) - voucher.condition.discount.discount_form + 1) + ""
                        // @ts-ignores
                      } else if (voucher.condition.discount.type !== "percent" && voucher.condition.apply.products.includes(item.id) && Number(item.quantity_order) >= voucher.condition.discount.discount_form) {
                        // @ts-ignore
                        formState.discount = Number(formState.discount) + (voucher.condition.discount.discount * (voucher.condition.discount.discount_form === Number(item.quantity_order) ? 1 : Number(item.quantity_order) - voucher.condition.discount.discount_form + 1))
                      }

                    })


                  } else {
                    productSelected.value = productSelected.value.map((item: any) => {
                      // @ts-ignore
                      if (voucher.condition.discount.type === "percent" && voucher.condition.apply.products.includes(item.id)) {
                        // @ts-ignore
                        formState.discount = (voucher.condition.discount.discount_max ? (Number(formState.discount) + ((item.unit_price / 100 * voucher.condition.discount.discount) < voucher.condition.discount.discount_max ? (item.unit_price / 100 * voucher.condition.discount.discount) : voucher.condition.discount.discount_max)) : (Number(formState.discount) + (item.unit_price / 100 * voucher.condition.discount.discount))) * Number(item.quantity_order) + ""
                      } else if (voucher.condition.discount.type !== "percent" && voucher.condition.apply.products.includes(item.id)) {
                        // @ts-ignore
                        formState.discount = Number(formState.discount) + (voucher.condition.discount.discount * Number(item.quantity_order))
                      }
                      return item
                    })
                  }
                }
              }else {
                if (voucher.condition.apply.condition === "group") {
                  const checkGroup = ref(false)
                  for (let i = 0; i < productSelected.value.length; i++) {
                    if (!voucher.condition.apply.group_products.includes(productSelected.value[i].id)) {
                      checkGroup.value = true
                      break
                    } else {
                      checkGroup.value = false

                    }

                  }
                  console.log(checkGroup.value)
                  if (checkGroup.value) {
                    productSelected.value = productSelected.value.map((item: any) => {
                      // @ts-ignore
                      if (voucher.condition.discount.type === "percent" && !voucher.condition.apply.group_products.includes(item.id)) {
                        // @ts-ignore
                        formState.discount = (Number(formState.discount) + (item.unit_price / 100 * voucher.condition.discount.discount)) + ""
                      } else if (voucher.condition.discount.type !== "percent" && !voucher.condition.apply.group_products.includes(item.id)) {
                        // @ts-ignore
                        formState.discount = Number(formState.discount) + voucher.condition.discount.discount
                      }
                      return item
                    })
                  } else {
                    message.warn('không áp dụng trên nhóm sản phẩm này')
                    customerVoucher.value.voucher_id = ""
                    console.log(customerVoucher.value.voucher_id,'customer')

                  }
                } else {
                  if (voucher.condition.discount.product_num) {
                    if (voucher.condition.discount.price_type === 'max') {
                      const productMax = ref<any>(0)
                      for (let i = 1; i < productSelected.value.length; i++) {
                        productMax.value = productSelected.value[0].id
                        console.log(productMax.value, 'productMax.value')
                        // @ts-ignore
                        if (productSelected.value[0].unit_price < productSelected.value[i].unit_price) {
                          productMax.value = productSelected.value[i].id
                        }
                      }
                      console.log(productMax.value, 'productMax.value')
                      productSelected.value.map((item: any) => {
                        // @ts-ignore
                        if (voucher.condition.discount.type === "percent" && !voucher.condition.apply.products.includes(item.id) && productMax.value === item.id) {
                          // @ts-ignore
                          formState.discount = voucher.condition.discount.discount_max ? (Number(formState.discount) + ((item.unit_price / 100 * voucher.condition.discount.discount) < voucher.condition.discount.discount_max ? (Number(formState.discount) + (item.unit_price / 100 * voucher.condition.discount.discount)) : voucher.condition.discount.discount_max)) : (Number(formState.discount) + (item.unit_price / 100 * voucher.condition.discount.discount)) + ""
                          // @ts-ignores
                        } else if (voucher.condition.discount.type !== "percent" && !voucher.condition.apply.products.includes(item.id) && productMax.value === item.id) {
                          // @ts-ignore
                          formState.discount = Number(formState.discount) + voucher.condition.discount.discount
                        }

                      })
                    } else {
                      const productMin = ref<any>(0)
                      for (let i = 1; i < productSelected.value.length; i++) {
                        productMin.value = productSelected.value[0].id
                        console.log(productMin.value, 'productMax.value')
                        // @ts-ignore
                        if (productSelected.value[0].unit_price > productSelected.value[i].unit_price) {
                          productMin.value = productMin.value[i]
                        }
                      }
                      console.log(productMin.value, 'productMax.value')
                      productSelected.value.map((item: any) => {
                        // @ts-ignore
                        if (voucher.condition.discount.type === "percent" && !voucher.condition.apply.products.includes(item.id) && productMin.value === item.id) {
                          // @ts-ignore
                          formState.discount = voucher.condition.discount.discount_max ? (Number(formState.discount) + ((item.unit_price / 100 * voucher.condition.discount.discount) < voucher.condition.discount.discount_max ? (Number(formState.discount) + (item.unit_price / 100 * voucher.condition.discount.discount)) : voucher.condition.discount.discount_max)) : (Number(formState.discount) + (item.unit_price / 100 * voucher.condition.discount.discount)) + ""
                          // @ts-ignores
                        } else if (voucher.condition.discount.type !== "percent" && !voucher.condition.apply.products.includes(item.id) && productMin.value === item.id) {
                          // @ts-ignore
                          formState.discount = Number(formState.discount) + voucher.condition.discount.discount
                        }

                      })


                    }

                  } else if (voucher.condition.discount.discount_form) {

                    productSelected.value.map((item: any, index: any) => {
                      // @ts-ignore
                      if (voucher.condition.discount.type === "percent" && !voucher.condition.apply.products.includes(item.id) && Number(item.quantity_order) >= voucher.condition.discount.discount_form) {
                        // @ts-ignore
                        formState.discount = (voucher.condition.discount.discount_max ? (Number(formState.discount) + ((item.unit_price / 100 * voucher.condition.discount.discount) < voucher.condition.discount.discount_max ? (item.unit_price / 100 * voucher.condition.discount.discount) : voucher.condition.discount.discount_max)) : (Number(formState.discount) + (item.unit_price / 100 * voucher.condition.discount.discount))) * (voucher.condition.discount.discount_form === Number(item.quantity_order) ? 1 : Number(item.quantity_order) - voucher.condition.discount.discount_form + 1) + ""
                        // @ts-ignores
                      } else if (voucher.condition.discount.type !== "percent" && !voucher.condition.apply.products.includes(item.id) && Number(item.quantity_order) >= voucher.condition.discount.discount_form) {
                        // @ts-ignore
                        formState.discount = Number(formState.discount) + (voucher.condition.discount.discount * (voucher.condition.discount.discount_form === Number(item.quantity_order) ? 1 : Number(item.quantity_order) - voucher.condition.discount.discount_form + 1))
                      }

                    })


                  } else {
                    // @ts-ignore
                    productSelected.value = productSelected.value.map((item: any) => {
                      // @ts-ignore
                      if (voucher.condition.discount.type === "percent" && !voucher.condition.apply.products.includes(item.id)) {
                        // @ts-ignore
                        formState.discount = (voucher.condition.discount.discount_max ? (Number(formState.discount) + ((item.unit_price / 100 * voucher.condition.discount.discount) < voucher.condition.discount.discount_max ? (item.unit_price / 100 * voucher.condition.discount.discount) : voucher.condition.discount.discount_max)) : (Number(formState.discount) + (item.unit_price / 100 * voucher.condition.discount.discount))) * Number(item.quantity_order) + ""
                      } else if (voucher.condition.discount.type !== "percent" && !voucher.condition.apply.products.includes(item.id)) {
                        // @ts-ignore
                        formState.discount = Number(formState.discount) + (voucher.condition.discount.discount * Number(item.quantity_order))
                      }
                      return item
                    })
                  }

                  // }
                }
              }
            }
          }else if(voucher.voucher_type === 'BUY_X_GIVE_AWAY_Y'){
            console.log(productSelected.value,'productSelected.value')
            let total = 0
            productSelected.value.map((item:any) => {
              total += item.unit_price * Number(item.quantity_order)
            })
            console.log(total >= voucher.condition.discount.apply_to_bill_from,'total')
            if(total >= voucher.condition.discount.apply_to_bill_from) {
              formState.discount = ""
              // @ts-ignore
              const check = ref(false)
              const checkNum = ref(false)
              productSelected.value = productSelected.value.map((item:any) => {

                if(voucher.condition.apply.products.includes(item.id) && voucher.condition.apply.product_num === Number(item.quantity_order)){
                  checkNum.value = true
                  if(voucher.condition.apply.product_promotion.includes(item.id)){

                    item.quantity_order = Number(item.quantity_order) + voucher.condition.apply.product_promotion_num

                    formState.discount = (Number(item.quantity_order) - voucher.condition.apply.product_promotion_num) * item.unit_price + ""
                    check.value = true
                  }else {
                    check.value =  false
                  }
                }
                return item

              })

              if(!check.value && checkNum.value){
                getStockProducts.value.products?.map((item:any) => {
                  if(voucher.condition.apply.product_promotion.includes(item.id)){
                    item.unit_price = 0
                    item.quantity_order = voucher.condition.apply.product_promotion_num + ""
                    productSelected.value.push(item)
                  }
                })
              }
            }
          }else if(voucher.voucher_type === 'BUY_A_DISCOUNT_B'){
            console.log(productSelected.value,'productSelected.value')
            let total = 0
            productSelected.value.map((item:any) => {
              total += item.unit_price * Number(item.quantity_order)
            })
            console.log(total >= voucher.condition.discount.apply_to_bill_from,'total')
            if(total >= voucher.condition.discount.apply_to_bill_from) {
              formState.discount = ""
              // @ts-ignore
              const check = ref(false)
              const checkNum = ref(false)
              productSelected.value = productSelected.value.map((item:any) => {

                if(voucher.condition.apply.products.includes(item.id) && voucher.condition.apply.product_num === Number(item.quantity_order)){
                  checkNum.value = true
                  if(voucher.condition.apply.product_promotion.includes(item.id)){

                    item.quantity_order =  + voucher.condition.apply.product_promotion_num
// @ts-ignore
                    formState.discount = formState.discount + (( Number(item.unit_price) / 100 *voucher.condition.discount.discount) * voucher.condition.apply.product_promotion_num) +""
                    check.value = true
                  }else {
                    check.value =  false
                  }
                }
                return item

              })

              if(!check.value && checkNum.value){
                getStockProducts.value.products?.map((item:any) => {
                  if(voucher.condition.apply.product_promotion.includes(item.id)){
                    item.quantity_order = voucher.condition.apply.product_promotion_num + ""
                    // @ts-ignore
                    formState.discount = formState.discount + (item.unit_price / 100 *voucher.condition.discount.discount)+ ""
                    productSelected.value.push(item)
                  }
                })
              }
            }
          }else if(voucher.voucher_type === 'SAME_PRICE'){
            if(voucher.condition.apply.condition === "group"){
              console.log('1')
            }else {
              // @ts-ignore
              productSelected.value = productSelected.value.map((item:any) => {
                if(voucher.condition.apply.type === "apply_on"){
                  if(voucher.condition.apply.group_products.includes(item.id)){
                    item.unit_price = voucher.condition.discount.same_price
                  }
                }else {
                  if(!voucher.condition.apply.group_products.includes(item.id)){
                    item.unit_price = voucher.condition.discount.same_price
                  }
                }
                return item
              })
            }

          }
          console.log(voucher)
          // @ts-ignore
          customerVoucher.value = {voucher_id: voucherId,customer_id:formStateOrder.customer_id }
        }
      }


    }
    const createOrder = async (confirm: boolean) => {

      //Check common info
      if(!formStateOrder.customer_id){
        // message.error('Please select customer!');
        createCustomer(confirm)
        return
      }

      if(productSelected.value.length===0){
        message.error('Please add product!');
        return
      }
      formRef.value
          .validate()
          .then(async () => {
            const stock = getStocks.value && getStocks.value?.find(s=>s.id===formState.stock_id)
            productSelected.value = productSelected.value.map(p=>{
              p.quantity_order = Number(p.quantity_order)
              return p
            })
            if(!formState.id){
              console.log('total', productSelected.value.map(p=>Number(p.quantity_order)*Number(p.unit_price)).reduce((a: number, b: number) => Number(a) + Number(b), 0))

              const province = getProvinces.value?.find(i=>(i.id+"") === (formStateOrder.shipping_province_id+""))
              formStateOrder.shipping_province_name = province? province.name: ""

              const district = getDistricts.value?.find(i=>(i.id+"") === (formStateOrder.shipping_district_id+""))
              formStateOrder.shipping_district_name = district? district.name: ""

              const ward = getWards.value?.find(i=>(i.id+"") === (formStateOrder.shipping_ward_id+""))
              formStateOrder.shipping_ward_name = ward? ward.name: ""

              const response = await APIs.init().createOrder({
                status: confirm?1:0,
                customer_id: formStateOrder.customer_id,
                social_id: formStateOrder.social_id,
                page_id: formStateOrder.page_id,
                stock_id: formState.stock_id,
                discount: Number(formState.discount),
                prepaid: Number(formState.paid),
                created_by: useUserStore.getState().userInfo?.id,
                quantity: productSelected.value.map(p=>p.quantity_order).reduce((a, b) => (Number(a) + Number(b)), 0),
                weight: productSelected.value.map(p=>p.weight).reduce((a, b) => (Number(a) + Number(b)), 0),
                total: productSelected.value.map(p=>Number(p.quantity_order)*Number(p.unit_price)).reduce((a: number, b: number) => Number(a) + Number(b), 0)+(formState.shipping_fee_collected?Number(formState.shipping_fee_collected):0),
                shipping_name: formStateOrder.shipping_name,
                shipping_phone: formStateOrder.phone,
                shipping_address: formStateOrder.shipping_address,
                shipping_district_id: formStateOrder.shipping_district_id,
                shipping_ward_id: formStateOrder.shipping_ward_id,
                shipping_province_id: formStateOrder.shipping_province_id,
                shipping_district: [formStateOrder.shipping_district_name, formStateOrder.shipping_province_name].join(', '),
                stock_address: [stock?.shop_address, stock?.ward_name, stock?.district_name, stock?.province_name].join(', '),
                products: productSelected.value,
                cod_amount: productSelected.value.map((p:any )=>p.quantity_order*p.unit_price).reduce((a: any, b: any) => a + b, 0)-(formState.discount?Number(formState.discount):0)+(formState.shipping_fee_collected?Number(formState.shipping_fee_collected):0)-Number(formState.paid?formState.paid:0),
                shipping_method: !formState.ship_checked?"default":(formState.shipping_provider +'-'+formState.shipping_method),
                ship_fee: formState.shipping_fee,
                ship_fee_collected: formState.shipping_fee_collected,
                ship_revenue: formState.ship_cost,
                note: formState.note,
                note_bill: formState.note_bill,
              })
              if (response.status === 200 && response.data?.status === 200) {
                // let ipString = ""
                // fetch('https://jsonip.com', { mode: 'cors' })
                //     .then((resp) => resp.json())
                //     .then((ip:any) => {
                //       ipString = ip
                //     })
                // // @ts-ignore
                // let products =  response.data.data.products.map((item:any) => {
                //   return{
                //     id:item.id,
                //     quantity:item.quantity,
                //     item_price: item.unit_price
                //   }
                // })
                // await  APIs.init().facebookPostEventApi({
                //   time: dayjs(response.data.data.created_at).unix(),
                //   order_number: response.data.data.order_number + "",
                //   content: products,
                //   total: response.data.data.total,
                //   page_id: response.data.data.page_id,
                //   ip: ipString
                // })
                // @ts-ignore
                usePoint.value.order_number = response.data.data.order_number
                // @ts-ignore
                usePoint.value.point = Number(usePoint.value.point)
                await APIs.init().createUsePoint( usePoint.value)
                customerVoucher.value.order_number =  response.data.data.order_number + ""
                // @ts-ignore
                customerVoucher.value.customer_id = formStateOrder.customer_id
                const customerUseVoucher = await APIs.init().addCustomerUseVoucher(customerVoucher.value)
                const useVoucher = await APIs.init().useVoucher({id:customerVoucher.value.voucher_id})

                await router.push({
                  name: RouterEndpoint.ORDER.name,
                  params: {id: response.data.data.order_number ? response.data.data.order_number : ''}
                })
                notification.success({
                  message: i18n.t('NOTIFICATION'),
                  description:
                      i18n.t('ORDER')+' '+response.data.data.order_number+' '+i18n.t('SUCCESS'),
                });
              }else{
                notification.error({
                  message: i18n.t('NOTIFICATION'),
                  description: response.data?.message,
                });
              }
            }else{
              // console.log('total', productSelected.value.map(p=>Number(p.quantity_order)*Number(p.unit_price)).reduce((a: number, b: number) => Number(a) + Number(b), 0))

              const province = getProvinces.value?.find(i=>(i.id+"") === (formStateOrder.shipping_province_id+""))
              formStateOrder.shipping_province_name = province? province.name: ""

              const district = getDistricts.value?.find(i=>(i.id+"") === (formStateOrder.shipping_district_id+""))
              formStateOrder.shipping_district_name = district? district.name: ""

              const ward = getWards.value?.find(i=>(i.id+"") === (formStateOrder.shipping_ward_id+""))
              formStateOrder.shipping_ward_name = ward? ward.name: ""

              const response = await APIs.init().updateOrder({
                id: formState.id,
                customer_id: formStateOrder.customer_id,
                stock_id: formState.stock_id,
                discount: Number(formState.discount),
                prepaid: Number(formState.paid),
                created_by: useUserStore.getState().userInfo?.id,
                quantity: productSelected.value.map(p=>p.quantity_order).reduce((a, b) => (Number(a) + Number(b)), 0),
                weight: productSelected.value.map(p=>p.weight).reduce((a, b) => (Number(a) + Number(b)), 0),
                total: productSelected.value.map(p=>Number(p.quantity_order)*Number(p.unit_price)).reduce((a: number, b: number) => Number(a) + Number(b), 0)+(formState.shipping_fee_collected?Number(formState.shipping_fee_collected):0),
                shipping_name: formStateOrder.shipping_name,
                shipping_phone: formStateOrder.phone,
                shipping_address: formStateOrder.shipping_address,
                shipping_district_id: formStateOrder.shipping_district_id,
                shipping_ward_id: formStateOrder.shipping_ward_id,
                shipping_province_id: formStateOrder.shipping_province_id,
                shipping_district: [formStateOrder.shipping_district_name, formStateOrder.shipping_province_name].join(', '),
                stock_address: [stock?.shop_address, stock?.ward_name, stock?.district_name, stock?.province_name].join(', '),
                products: productSelected.value,
                cod_amount: productSelected.value.map((p:any )=>p.quantity_order*p.unit_price).reduce((a: any, b: any) => a + b, 0)-(formState.discount?Number(formState.discount):0)+(formState.shipping_fee_collected?Number(formState.shipping_fee_collected):0)-Number(formState.paid?formState.paid:0),
                shipping_method: !formState.ship_checked?"default":(formState.shipping_provider +'-'+formState.shipping_method),
                ship_fee: formState.shipping_fee,
                ship_revenue: formState.ship_cost,
                note: formState.note,
                note_bill: formState.note_bill,
                ship_fee_collected: formState.shipping_fee_collected,
              })
              if (response.status === 200 && response.data?.status === 200) {
                // // @ts-ignore
                // let products =  response.data.data.products.map((item:any) => {
                //   return{
                //     id:item.id,
                //     quantity:item.quantity,
                //     item_price: item.unit_price
                //   }
                // })
                // await  APIs.init().facebookPostEventApi({
                //   time: dayjs(response.data.data.created_at).unix(),
                //   order_number: response.data.data.order_number + "",
                //   content: products,
                //   total: response.data.data.total,
                //   page_id: response.data.data.page_id,
                //   ip: "14.231.234.248"
                // })
                customerVoucher.value.order_number =  response.data.data.order_number + ""
                // @ts-ignore
                customerVoucher.value.customer_id = formStateOrder.customer_id
                const customerUseVoucher = await APIs.init().addCustomerUseVoucher(customerVoucher.value)
                const useVoucher = await APIs.init().useVoucher({id:customerVoucher.value.voucher_id})

                await router.push({
                  name: RouterEndpoint.ORDER.name,
                  params: {id: formState.order_number ? formState.order_number : ''}
                })
                notification.success({
                  message: i18n.t('NOTIFICATION'),
                  description:
                      i18n.t('ORDER')+' '+formState.order_number+' '+i18n.t('SUCCESS'),
                });
              }else{
                notification.error({
                  message: i18n.t('NOTIFICATION'),
                  description: response.data?.message,
                });
              }
            }
          }).catch((error: ValidateErrorEntity<Locale>) => {
        console.log('error', error);
      });

    }

    const shippingProviderOnChange = async (shippingProvider: string) =>{
      const stock = getStocks.value?.find(stock=>stock.id == formState.stock_id)?getStocks.value?.find(stock=>stock.id == formState.stock_id):{}
      formState.shipping_provider = shippingProvider
      const response = await APIs.init().getShippingMethod({
        method: shippingProvider+"-",
        from_ward: stock?.ward_id,
        to_ward: formStateOrder.shipping_ward_id,
        weight: productSelected.value.map(p=>p.weight).reduce((a, b) => (Number(a) + Number(b)), 0),
        price: productSelected.value.map(p=>Number(p.quantity_order)*Number(p.unit_price)).reduce((a: number, b: number) => Number(a) + Number(b), 0)-(formState.discount?Number(formState.discount):0)+(formState.ship_cost?Number(formState.ship_cost):0),
      })
      console.log('shipping', response)
      if(response.status === 200 && response.data?.status === 200){
        shippingMethods.value = response.data.data
      }
    }

    const shippingMethodOnChange = async (shippingMethod: string) =>{
      formState.shipping_method = shippingMethod
      const stock = getStocks.value?.find(stock=>stock.id == formState.stock_id)?getStocks.value?.find(stock=>stock.id == formState.stock_id):{}
      const response = await APIs.init().getShippingFee({
        shipping_method: formState.shipping_provider+"-"+shippingMethod,
        from_ward: stock?.ward_id,
        to_ward: formStateOrder.shipping_ward_id,
        weight: productSelected.value.map(p=>p.weight).reduce((a, b) => (Number(a) + Number(b)), 0),
        price: productSelected.value.map(p=>Number(p.quantity_order)*Number(p.unit_price)).reduce((a: number, b: number) => Number(a) + Number(b), 0)-(formState.discount?Number(formState.discount):0)+(formState.ship_cost?Number(formState.ship_cost):0),
      })
      console.log('shipping', response)
      if(response.status === 200 && response.data?.status === 200){
        // shippingMethods.value = response.data.data
        // formState.shipping_method = formState.shipping_provider+"-"+shippingMethod
        formState.shipping_fee = response.data.fee
      }
    }
    const createCustomer = (confirm: boolean)=>{

      const customerForm: CustomerForm= {
        full_name: formStateOrder.shipping_name,
        phone: formStateOrder.shipping_phone,
        province_id: formStateOrder.shipping_province_id+"",
        district_id: formStateOrder.shipping_district_id+"",
        ward_id: formStateOrder.shipping_ward_id,
        address: formStateOrder.address,
      }
      message.loading("");

      APIs.init()
          .createCustomer(customerForm)
          .then(async (res: ApiResponse<CustomerResponse>) => {
            console.log(res);
            message.destroy();
            if (res.status === 200 && res.data?.status === 200) {
              formStateOrder.shipping_district_id = res.data?.data.district_id?parseInt(res.data?.data.district_id):0
              formStateOrder.shipping_ward_id = res.data?.data.ward_id
              formStateOrder.shipping_province_id = res.data?.data.province_id?parseInt(res.data?.data.province_id):0
              formStateOrder.shipping_province_name = res.data?.data.province_name
              formStateOrder.shipping_district_name = res.data?.data.district_name
              formStateOrder.shipping_name = res.data?.data.full_name
              formStateOrder.phone = res.data?.data.phone
              formStateOrder.shipping_ward_name = res.data?.data.ward_name
              formStateOrder.address = res.data?.data.address
              formStateOrder.customer_id = res.data?.data.id
              formStateOrder.social_id = res.data?.data.social_id
              formStateOrder.page_id = res.data?.data.page_id
              await createOrder(confirm)
            } else{
              message.error({ content: res.data?.message }, 1500);
            }
          })
          .catch((e) => {
            message.destroy();
            console.error(e);
            message.error({ content: "error create customer" }, 1500);
          });

    }

    const getProductName = (id:any) =>{
      return getStockProducts.value.products?.find((item:any) => item.id === id).product_name
    }
    const getVoucherName = (id:any) => {
      return getVoucherActive.value.find((item:any) => item.id === id).voucher_name
    }


    // lấy điểm tích lũy của khách hàng
    const getUsePoint = async () => {
     const res = await  APIs.init().getUsePoint( {id:formStateOrder.order_number})
      if(res.data?.status === 200){
        usePoint.value = res.data.data
      }
    }
    const customerPoint = ref({})
    const getCustomerConfig =  () => {
      const request = {shop_id:currentCustomer.value ? currentCustomer.value.shop_id : undefined}
      store.dispatch(CustomersActionTypes.FETCH_CUSTOMER_CONFIG,request)

    }
    const currentCustomer = computed((): Customer | undefined => store.getters.getCurrentCustomer)
    const CustomerConfig = computed(() => store.getters.getCustomerConfig.filter((item:any)=> item.identified_name === 'points' ))
    const getPoint = async () => {
      const res = await APIs.init().getCustomerConfigValue({id: formStateOrder.customer_id})
         res.data?.data.map((item:any)=> {
           if(CustomerConfig.value[0].id === item.customer_column_id){
             customerPoint.value = item
           }
         })
      console.log(CustomerConfig.value)
    }

    const formatPoint = (value:any) => {
      // @ts-ignore
        if(usePoint.value.point){
          // @ts-ignore
         return  MixUtils.methods.formatMoney(value - usePoint.value.point)
        }else {
          return  MixUtils.methods.formatMoney(value)
        }
      //
    }

    const customerUsePoint =  () => {
      console.log(formStateOrder,'formStateOrder')
      // @ts-ignore
      usePoint.value.customer_id = formStateOrder.customer_id
      // @ts-ignore

      // @ts-ignore
      formState.discount = formState.discount ? String(Number(formState.discount) +  Number(usePoint.value.point)) : usePoint.value.point
       console.log(formState.discount, usePoint.value ,'usePoint.value')
      visible2.value = false
    }

    const customerUsePointAll =  () => {
      // @ts-ignore
      usePoint.value.point = customerPoint.value.customer_column_value
      // @ts-ignore
      usePoint.value.customer_id = formStateOrder.customer_id
      // @ts-ignore
      formState.discount = formState.discount ? String(Number(formState.discount) +  Number(usePoint.value.point)) : usePoint.value.point
      visible2.value = false
      }

    const getVouchers = computed(()=> store.getters.getVoucherData)
    const fetVoucherActive = ()=>{
      store.dispatch(VoucherActionTypes.FETCH_VOUCHER_ACTIVE)
    }
    const getVoucherActive = computed(()=> store.getters.getVoucherActiveData)
    onMounted(()=>{
      fetchStocks()
      fetVoucherActive()
    })

    return {
      formRef,
      rules,
      getVoucherActive,
      productColumns,
      productModalVisible,
      productSearchColumns,
      rowSelection,
      labelCol: {span: 6},
      wrapperCol: {span: 18},
      getStocks,
      formState,
      handleOnChangePage,
      handleSearch,
      selectCustomer,
      customers,
      provinceChange,
      districtChange,
      getProvinces,
      getDistricts,
      getWards,
      stockProductRequest,
      onSearchProduct,
      onChangeProduct,
      products,
      showProductModal,
      hasSelected,
      ...toRefs(state),
      onSelectChange,
      getCheckboxProps,
      submitSelectedProduct,
      productSelected,
      deleteProductSelected,
      createOrder,
      fetchOrder,
      shippingProviders,
      shippingMethods,
      shippingProviderOnChange,
      shippingMethodOnChange,
      provinceSearch,
      districtSearch,
      wardSearch,
      formStateOrder,
      handleSearchProduct,
      productsAdd,
      selectProduct,
      productAddSearch,
      collapseActive,
      customerSearchLoading,
      getVouchers,
      customerVoucher,
      handleSelectVoucher,
      handleVoucher,
      visible,
      visible2,
      getProductName,
      getVoucherName,
      getPoint,
      customerPoint,
      usePoint,
      customerUsePoint,
      formatPoint,
      customerUsePointAll,
    }
  }
})
</script>

<style scoped lang="scss">
.button-print-order {
  margin-left: 18px;
}

.order-edit-container {
  list-style-type: none;
  width: 100%;
  margin: 0;
  padding: 0;
  overflow: hidden;
}

@media only screen and (min-width: 768px) {
  .customer-info-item {
    float: left;
    width: 35%;
    padding-right: 8px;
  }

  .product-info-item {
    float: left;
    width: 65%;
  }
}


.button-edit-order {
  margin-left: 12px;
}

.footer-save {
  text-align: right;
  display: flex;
  gap: 16px;
  justify-content: flex-end;
}

.add-product {
  display: flex;
  justify-content: center;
  margin-bottom: 10px;
}

.header-order-container-extra {
  display: flex;
  justify-content: center;

img {
  width: 24px;
  height: 24px;
}

.order-date {
  margin-left: 40px;
}

}
.shipping-fee-contain {
  text-align: right;

.shipping-fee {
  font-weight: bold;
}

}


.form-shipping-unit {
  display: flex;
  align-items: center;

.shipping-unit-label {
  margin-right: 10px;
  min-width: 90px;
}

.shipping-unit-value {
  flex: 1;
}

}


.contain-info {
  display: flex;
  flex-direction: column;

.button-branch {
  font-weight: bold;
}

.content-info-value {
  margin-left: 10px;
}

.content-info-value-special {
  font-weight: bold;
}

.title-order-common-info {
  font-weight: bold;
}

}

.table-product {
  margin-left: -14px;
  margin-right: -14px;
  margin-bottom: 10px;

.total {
  color: #d90303;
  font-weight: bold;
}

}
.search-product-input {
  margin-bottom: 10px;
  width: 50%;
}

.shopf1-note-contain {
  padding-right: 25px;

.shopf1-note-value {
  background-color: #eeeeee;
  padding: 10px 20px;
  border-radius: 8px;
  width: 100%;
}

}

.address-select {
  display: flex;

.item-address-select {
  flex: 1;
}

}


.shopf1-shipping-note-label {
  padding-top: 30px;
}

.switch-shipping-unit {
  padding-top: 50px;
}

.order-price-value {
  text-align: right;
  color: #d90303;
  font-weight: bold;
  padding-right: 48px;



}

.input-order-price {
  width: 100%;
  text-align: right;
  font-weight: bold;
}

.input-order-note {
  width: 100%;
  //font-weight: bold;
}

.title-updated-status {
  margin-top: 20px;
  margin-bottom: 15px;
}
.payment_info{
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  flex-wrap: wrap;
  gap: 16px;
}
.certain-category-search-dropdown .ant-select-dropdown-menu-item-group-title {
  color: #666;
  font-weight: bold;
}

.certain-category-search-dropdown .ant-select-dropdown-menu-item-group {
  border-bottom: 1px solid #f6f6f6;
}

.certain-category-search-dropdown .ant-select-dropdown-menu-item {
  padding-left: 16px;
}

.certain-category-search-dropdown .ant-select-dropdown-menu-item.show-all {
  text-align: center;
  cursor: default;
}

.certain-category-search-dropdown .ant-select-dropdown-menu {
  max-height: 300px;
}
.ant-select-item-option-content{
  padding: 10px 16px;
}
.cost_info{
  margin-top: 16px;
  margin-bottom: 16px;
}
</style>
