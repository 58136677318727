<template>
  <a-card>
  <div class="current-service-pack-container" v-if="shopActive===1">
    <h3>
      {{ $t('CURRENT_SERVICES_PACK') }}
    </h3>
    <div class="current-service-pack-header">
      <div class="current-service-pack">
        <h1>
          {{ shopServices[0]?.name?$t(shopServices[0]?.name):'' }} -
          {{ moment(shopInfo.shop_services?.start_date).format("DD/MM/YYYY") }}
        </h1>
        <div class="current-service-pack-info">
          <img src="@/assets/icons/flag-svgrepo-com.svg" class="icon-common"> {{ shopInfo.shop_services?.page_num }}
          {{ $t('PAGE') }}
          <img src="@/assets/icons/user-svgrepo-com.svg" class="icon-common" style="margin-left: 12px">
          {{ shopInfo.shop_services?.user_num }} {{ $t('USER') }}
          <img src="@/assets/icons/hourglass-time-svgrepo-com.svg" class="icon-common" style="margin-left: 12px">
          {{ $t('EXPIRED_AT') }}: {{ moment(shopInfo.shop_services?.end_date).format("DD/MM/YYYY") }}
        </div>
      </div>
    </div>
  </div>
    <div v-else-if="shopActive===0">
      <a-alert :message="$t('SUBSCRIPTION_EXPIRED')" type="error" />
    </div>
    <div v-else-if="shopActive===-1">
      <a-alert :message="$t('NO_SUBSCRIPTION')" type="warning" />
    </div>
  </a-card>
  <div class="body-upgrade-service-pack">
    <div class="header-title-upgrade-service-pack">
      <h1>
        {{ $t('UPGRADE_YOUR_PLAN') }}
      </h1>
    </div>

    <a-tabs v-model:activeKey="tabActive" @change="tabChange" centered
            tabBarStyle="background-color: white; margin-left: 200px;  margin-right: 200px; border-radius: 10px">
      <a-tab-pane :key="1" :tab="$t('BY_MONTH')" forceRender>
        <div class="card-pricing-container">
          <template v-for="item in tariffCategory.month" v-bind:key="item.id">
            <button :class="currentTariff.id!==item.id?'card-pricing': 'card-pricing-active'"
                    @click="selectService(item)"
            >
        <span>
          <span class="price-show">
            {{ formatMoney((lang === "vi" ? item.base_price : (lang === "en" ? item.base_price_en : item.base_price_th))) }}{{ lang === "vi" ? "đ" : (lang === "en" ? "$" : "บาท") }}
          </span>
          <span class="month-show">
            /{{ lang === "vi" ? "tháng" : "month" }}
          </span>
        </span>
              <h1 class="title-price">
                {{ $t(item.name) }}
              </h1>
              <p>
                {{ item.description }}
              </p>
              <div class="line-function-list-item">
                <check-circle-outlined class="icon-checked"/>
                <span class="sub-list-item">
           {{ item.start_page + " " + $t("PAGE") }}, {{ item.start_user + " " + $t("USER") }},
         </span>
              </div>
              <div class="line-function-list-item">
                <check-circle-outlined class="icon-checked" v-if="item.daily_free_phone>0"/>
                <CloseCircleOutlined class="icon-checked" v-else/>
                <span class="sub-list-item">
           {{ $t("GET_PHONE") + " " + (item.daily_free_phone > 0 ? (item.daily_free_phone + "/" + $t("DAY")) : '') }}
         </span>
              </div>
              <div class="line-function-list-item">
                <check-circle-outlined class="icon-checked" v-if="item.max_auto_inbox>0"/>
                <CloseCircleOutlined class="icon-checked" v-else/>
                <span class="sub-list-item">
             {{ $t("AUTO_INBOX_FAN_PAGE") + " " + (item.max_auto_inbox > 0 ? (formatMoney(item.max_auto_inbox) + "/" + $t("DAY")) : '') }}
         </span>
              </div>
              <div class="line-function-list-item">
                <check-circle-outlined class="icon-checked" v-if="item.max_auto_inbox>0"/>
                <CloseCircleOutlined class="icon-checked" v-else/>
                <span class="sub-list-item">
             {{ $t("AUTO_INBOX_ZALO_OA") + " " + (item.max_auto_inbox > 0 ? (formatMoney(item.max_auto_inbox) + "/" + $t("DAY")) : '') }}
         </span>
              </div>
              <div class="line-function-list-item">
                <check-circle-outlined class="icon-checked" v-if="item.max_auto_inbox>0"/>
                <CloseCircleOutlined class="icon-checked" v-else/>
                <span class="sub-list-item">
             {{ $t("VIRAL_CHAT") + " " + (item.max_auto_inbox > 0 ? (formatMoney(item.max_auto_inbox) + "/" + $t("DAY")) : '') }}
         </span>
              </div>
              <!--          <div class="upgrade-button">-->
              <!--            Upgrade-->
              <!--          </div>-->
            </button>

          </template>
        </div>

      </a-tab-pane>
      <a-tab-pane :key="2" :tab="$t('BY_SEX_MONTH')" forceRender>
        <div class="card-pricing-container">
          <template v-for="item in tariffCategory.six_month" v-bind:key="item.id">
            <button :class="currentTariff.id!==item.id?'card-pricing': 'card-pricing-active'"
                    @click="selectService(item)"
            >
        <span>
          <span class="price-show">
            {{ formatMoney((lang === "vi" ? item.base_price : (lang === "en" ? item.base_price_en : item.base_price_th))) }}{{ lang === "vi" ? "đ" : (lang === "en" ? "$" : "บาท") }}
          </span>
          <span class="month-show">
            /{{ lang === "vi" ? "tháng" : "month" }}
          </span>
        </span>
              <h1 class="title-price">
                {{ $t(item.name) }}
              </h1>
              <p>
                {{ item.description }}
              </p>
              <div class="line-function-list-item">
                <check-circle-outlined class="icon-checked"/>
                <span class="sub-list-item">
           {{ item.start_page + " " + $t("PAGE") }}, {{ item.start_user + " " + $t("USER") }},
         </span>
              </div>
              <div class="line-function-list-item">
                <check-circle-outlined class="icon-checked" v-if="item.daily_free_phone>0"/>
                <CloseCircleOutlined class="icon-checked" v-else/>
                <span class="sub-list-item">
           {{ $t("GET_PHONE") + " " + (item.daily_free_phone > 0 ? (item.daily_free_phone + "/" + $t("DAY")) : '') }}
         </span>
              </div>
              <div class="line-function-list-item">
                <check-circle-outlined class="icon-checked" v-if="item.max_auto_inbox>0"/>
                <CloseCircleOutlined class="icon-checked" v-else/>
                <span class="sub-list-item">
             {{ $t("AUTO_INBOX_FAN_PAGE") + " " + (item.max_auto_inbox > 0 ? (formatMoney(item.max_auto_inbox) + "/" + $t("DAY")) : '') }}
         </span>
              </div>
              <div class="line-function-list-item">
                <check-circle-outlined class="icon-checked" v-if="item.max_auto_inbox>0"/>
                <CloseCircleOutlined class="icon-checked" v-else/>
                <span class="sub-list-item">
             {{ $t("AUTO_INBOX_ZALO_OA") + " " + (item.max_auto_inbox > 0 ? (formatMoney(item.max_auto_inbox) + "/" + $t("DAY")) : '') }}
         </span>
              </div>
              <div class="line-function-list-item">
                <check-circle-outlined class="icon-checked" v-if="item.max_auto_inbox>0"/>
                <CloseCircleOutlined class="icon-checked" v-else/>
                <span class="sub-list-item">
             {{ $t("VIRAL_CHAT") + " " + (item.max_auto_inbox > 0 ? (formatMoney(item.max_auto_inbox) + "/" + $t("DAY")) : '') }}
         </span>
              </div>
              <!--          <div class="upgrade-button">-->
              <!--            Upgrade-->
              <!--          </div>-->
            </button>

          </template>
        </div>

      </a-tab-pane>
      <a-tab-pane :key="3" :tab=" $t('BY_YEAR') " forceRender>
        <div class="card-pricing-container">
          <template v-for="item in tariffCategory.year" v-bind:key="item.id">
            <button :class="currentTariff.id!==item.id?'card-pricing': 'card-pricing-active'"
                    @click="selectService(item)"
            >
        <span>
          <span class="price-show">
            {{ formatMoney((lang === "vi" ? item.base_price : (lang === "en" ? item.base_price_en : item.base_price_th))) }}{{ lang === "vi" ? "đ" : (lang === "en" ? "$" : "บาท") }}
          </span>
          <span class="month-show">
            /{{ lang === "vi" ? "tháng" : "month" }}
          </span>
        </span>
              <h1 class="title-price">
                {{ $t(item.name) }}
              </h1>
              <p>
                {{ item.description }}
              </p>
              <div class="line-function-list-item">
                <check-circle-outlined class="icon-checked"/>
                <span class="sub-list-item">
           {{ item.start_page + " " + $t("PAGE") }}, {{ item.start_user + " " + $t("USER") }},
         </span>
              </div>
              <div class="line-function-list-item">
                <check-circle-outlined class="icon-checked" v-if="item.daily_free_phone>0"/>
                <CloseCircleOutlined class="icon-checked" v-else/>
                <span class="sub-list-item">
           {{ $t("GET_PHONE") + " " + (item.daily_free_phone > 0 ? (item.daily_free_phone + "/" + $t("DAY")) : '') }}
         </span>
              </div>
              <div class="line-function-list-item">
                <check-circle-outlined class="icon-checked" v-if="item.max_auto_inbox>0"/>
                <CloseCircleOutlined class="icon-checked" v-else/>
                <span class="sub-list-item">
             {{ $t("AUTO_INBOX_FAN_PAGE") + " " + (item.max_auto_inbox > 0 ? (formatMoney(item.max_auto_inbox) + "/" + $t("DAY")) : '') }}
         </span>
              </div>
              <div class="line-function-list-item">
                <check-circle-outlined class="icon-checked" v-if="item.max_auto_inbox>0"/>
                <CloseCircleOutlined class="icon-checked" v-else/>
                <span class="sub-list-item">
             {{ $t("AUTO_INBOX_ZALO_OA") + " " + (item.max_auto_inbox > 0 ? (formatMoney(item.max_auto_inbox) + "/" + $t("DAY")) : '') }}
         </span>
              </div>
              <div class="line-function-list-item">
                <check-circle-outlined class="icon-checked" v-if="item.max_auto_inbox>0"/>
                <CloseCircleOutlined class="icon-checked" v-else/>
                <span class="sub-list-item">
             {{ $t("VIRAL_CHAT") + " " + (item.max_auto_inbox > 0 ? (formatMoney(item.max_auto_inbox) + "/" + $t("DAY")) : '') }}
         </span>
              </div>
              <!--          <div class="upgrade-button">-->
              <!--            Upgrade-->
              <!--          </div>-->
            </button>

          </template>
        </div>

      </a-tab-pane>
    </a-tabs>

  </div>
  <div class="build-service-pack">
    <a-col class="container-card-building-pack">
      <a-row>
        <a-card :title="$t('INFORMATION')"  class="card-building-pack">
          <a-form :label-col="labelCol" :wrapper-col="wrapperCol">
            <a-form-item>
              <template #label>
            <span>
               <img src="@/assets/icons/flag-svgrepo-com.svg" class="icon-common-build-info">
              {{ $t('PAGE_NUMBER') }}
            </span>
              </template>
              <a-input
                  v-model:value="formState.page_num"
                  type="number"
                  :suffix="$t('PAGE')"
              />
            </a-form-item>
            <a-form-item>
              <template #label>
              <span>
                <img src="@/assets/icons/user-svgrepo-com.svg" class="icon-common-build-info">
              {{ $t('USER_NUMBER') }}
            </span>

              </template>
              <a-input
                  v-model:value="formState.user_num"
                  :suffix="$t('USER')"
                  type="number"
              />
            </a-form-item>
            <a-form-item v-if="tabActive!==3">
              <template #label>
              <span>
                <img src="@/assets/icons/hourglass-time-svgrepo-com.svg" class="icon-common-build-info">
                {{ $t('MONTH_NUMBER') }}
            </span>

              </template>
<!--              <a-input-->
<!--                  v-model:value="formState.month"-->
<!--                  :suffix="$t('MONTH')"-->
<!--                  type="number"-->
<!--              />-->
              <a-select
                  style="width: 100%"
                  placeholder="Please select"
                  v-model:value="formState.month"
                  :options="monthOptions"
              >
              </a-select>
            </a-form-item>
            <a-form-item v-if="tabActive===3">
              <template #label>
              <span>
                <img src="@/assets/icons/hourglass-time-svgrepo-com.svg" class="icon-common-build-info">
                {{ $t('YEAR_NUMBER') }}
            </span>

              </template>
<!--              <a-input-->
<!--                  v-model:value="formState.year"-->
<!--                  :suffix="$t('YEAR')"-->
<!--                  type="number"-->
<!--              />-->
              <a-select
                  style="width: 100%"
                  placeholder="Please select"
                  v-model:value="formState.year"
                  :options="monthOptions"
              >
              </a-select>
            </a-form-item>
            <a-form-item>
              <template #label>
              <span>
                <img src="@/assets/icons/coupon-svgrepo-com.svg" class="icon-common-build-info">
              {{ $t('COUPON') }}
            </span>

              </template>
              <a-input
                  v-model:value="formState.coupon"
                  :onBlur="couponBlur"
                  type="text"
              />
            </a-form-item>
          </a-form>
        </a-card>

        <a-card :title="$t('BILLING')"  class="card-billing">
          <a-row>
            <a-col :span="11" class="flex-col-billing">
              <div>
                <img src="@/assets/icons/change-svgrepo-com.svg" class="icon-common-build-info">
                <span class="col-label"> {{ $t('CONVERTED_DAYS') }}:</span>
              </div>
              <span class="col-value">
              {{ formatMoney(calculatorPrice.m_day) }} {{ $t('DAY') }}
            </span>
            </a-col>
            <a-col :span="2">

            </a-col>
            <a-col :span="11" class="flex-col-billing">
              <div>
                <img src="@/assets/icons/money-svgrepo-com.svg" class="icon-common-build-info">
                <span class="col-label">{{ $t('PRICE') }}:</span>
              </div>
              <span class="col-value">
              {{ formatMoney(calculatorPrice.price) }} {{ lang === "vi" ? "đ" : (lang === "en" ? "$" : "บาท") }}
            </span>
            </a-col>
          </a-row>
          <a-row class="row-2-margin">
            <a-col :span="11" class="flex-col-billing">
              <div>
                <img src="@/assets/icons/hourglass-time-svgrepo-com.svg" class="icon-common-build-info">
                <span class="col-label">{{ $t('EXPIRED_AT') }}</span>
              </div>
              <span class="col-value">
              {{ calculatorPrice.end_date.format('DD/MM/YYYY') }}
            </span>
            </a-col>
            <a-col :span="2">

            </a-col>
            <a-col :span="11" class="flex-col-billing">
              <div>
                <img src="@/assets/icons/coupon-svgrepo-com.svg" class="icon-common-build-info">
                <span class="col-label">{{ $t('PROMOTION') }}:</span>
              </div>
              <span class="col-value promotion-value">
            {{priceDiscount.value>0?('('+priceDiscount.value+'%)'):''}} {{ formatMoney(calculatorPrice.m_discount) }} {{ lang === "vi" ? "đ" : (lang === "en" ? "$" : "บาท") }}
            </span>
            </a-col>
          </a-row>
          <a-row class="row-2-margin">
            <a-col :span="11">
            </a-col>
            <a-col :span="2">

            </a-col>
            <a-col :span="11" class="flex-col-billing">
              <div>
                <img src="@/assets/icons/money-svgrepo-com.svg" class="icon-common-build-info">
                <span class="col-label">{{ $t('TOTAL_AMOUNT') }}:</span>
              </div>
              <span class="col-value total-amount">
              {{ formatMoney(calculatorPrice.total_price) }} {{ lang === "vi" ? "đ" : (lang === "en" ? "$" : "บาท") }}
            </span>
            </a-col>
          </a-row>
        </a-card>

      </a-row>

      <a-card :title="$t('PAYMENT')" class="payment-card">
        <a-row>
          <a-col style="width: 38%; margin-right: 16px">
            <h3>
              {{ $t('PAYMENT_MENTHOD') }}
            </h3>
            <a-button
                v-if="getMyRole(modules.providers)?.is_edit"
                style="margin-top: 16px"
                @click="SaveTariffs(true)"
                block size="large">
              <img src="@/assets/icons/Vnpay.png" class="anticon icon-common-build-info">
              VNPAY
            </a-button>
            <div style="height: 8px">

            </div>
            <a-button
                v-if="getMyRole(modules.providers)?.is_edit"
                @click="SaveTariffs(false)"
                block size="large">
              <img src="@/assets/icons/payment-method-pay-svgrepo-com.svg" class="anticon icon-common-build-info">
              {{ $t('TRANSFER_PAYMENT') }}
            </a-button>
          </a-col>
          <a-col class="bank-information" style="width: 60%">
              <h3>
                {{ $t('BANK_INFO') }}
              </h3>
              <a-form style="margin-top: 24px"  :label-col="{style: { width: '200px' }}">
                <a-form-item>
                  <template #label>
                    <span class="detail-of-payment">Số tài khoản: *</span>
                  </template>
                  <a-input
                      readonly
                      value="0611001905554"
                  />

                </a-form-item>

                <a-form-item>
                  <template #label>
                    <span class="detail-of-payment">Họ tên người nhận: *</span>
                  </template>
                  <a-input
                      readonly
                      value="Le Duy Thang"
                  />
                </a-form-item>
                <a-form-item>
                  <template #label>
                    <span class="detail-of-payment">Ngân hàng: *</span>
                  </template>
                  <a-textarea autosize="true" readonly
                              value="Ngân hàng thương mại cổ phần Ngoại thương Việt Nam (Vietcombank) - CN Thăng Long"/>
                </a-form-item>
                <a-form-item>
                  <template #label>
                    <span class="detail-of-payment">{{ $t('DETAIL_OF_PAYMENT') }} *</span>
                  </template>
                  <a-input
                      readonly
                      :value="'SF '+shopInfo.id"
                  />
                </a-form-item>
              </a-form>

          </a-col>
        </a-row>
        <a-alert
            message="Ghi chú"
            :description="`Thanh toán chuyển khoản vào tài khoản công ty IDO - Kích hoạt Sau 30 phút (trong giờ hành chính) kể từ khi IDO nhận được giao dịch nạp tiền trên ngân hàng. Quý khách click Thanh toán chuyển khoản để mua gói cước và chuyển tiền theo nội dung: SF ${shopInfo.id}`"
            type="warning"
        />

      </a-card>

      <a-table
          size="middle"
          :scroll="{  y: '50vh' }"
          :dataSource="shopServices" :columns="columns" style="margin-top: 16px"
               pagination
      >
        <template #page_num="{ record }">
          <span>{{ formatMoney(record.page_num) }}</span>
        </template>
        <template #user_num="{ record }">
          <span>{{ formatMoney(record.user_num) }}</span>
        </template>
        <template #real_money="{ record }">
          <span>{{ formatMoney(record.real_money) }}</span>
        </template>
        <template #start_date="{ record }">
          <span>{{ moment(record.start_date).format('DD/MM/YYYY') }}</span>
        </template>
        <template #end_date="{ record }">
          <span>{{ moment(record.end_date).format('DD/MM/YYYY') }}</span>
        </template>
        <template #name="{ record }">
          <span>{{ $t(record.name) }}</span>
        </template>
        <template #status="{ record }">
          <a-tag :color="record.status==='Kích hoạt'?'#00a65a':(record.status==='Không kích hoạt'?'#dd4b39':'#f39c12')">
            {{ record.status }}
          </a-tag>
        </template>
        <template #action="{ record }">
            <span>
              <a-popconfirm
                  :title="$t('CONFIRM_CANCEL')"
                  :ok-text="$t('OK')" :cancel-text="$t('CANCEL')" @confirm="cancelServices(record)">
              <a v-if="record.status==='Đang xử lý'">{{ $t('CANCEL_SERVICES') }}</a>
              </a-popconfirm>
            </span>
        </template>
      </a-table>
    </a-col>


  </div>
</template>

<script lang="ts">
import {computed, defineComponent, inject, onMounted, onUnmounted, reactive, ref, watch} from "vue";
import Icon, {CheckCircleOutlined, CloseCircleOutlined} from "@ant-design/icons-vue";
import FlagSVG from '@/assets/icons/flag-svgrepo-com.svg';
import {ProductGroup} from "@/models/Products/Products";
import {message, notification} from "ant-design-vue";
import APIs from "@/APIs/APIs";
import {ProductMutationTypes} from "@/store/modules/product/mutation-types";
import {useStore} from "@/store";
import {Coupon, TariffCategory, Tariffs} from "@/models/Tariffs/Tariffs";
import {SuperUserInfo} from "@/models/Users";
import {ShopInfo, ShopServices} from "@/models/Shopinfo/ShopInfo";
import moment from "moment";
import {create, all} from 'mathjs'
import {VueCookies} from "vue-cookies";
import {useRoute, useRouter} from "vue-router";
import {useI18n} from "vue-i18n";
import {MixUtils} from "@/utils/Utils";
import dayjs from "dayjs";
import RouterEndpoint from "@/router/RouterEndpoint";
import router from "@/router";
import {Constant} from "@/utils/Constants";

const config = {}
const math = create(all, config)

export default defineComponent({
  name: "ServicePack",
  components: {
    CheckCircleOutlined,
    CloseCircleOutlined,
  },
  created() {
    console.log('query', this.$route.query)
    try{
      if(this.$route.query.vnp_OrderInfo){
        console.log(this.$route.query.vnp_OrderInfo)
        this.confirmVNPay({
          vnp_Amount: Number(this.$route.query.vnp_Amount),
          vnp_BankCode: this.$route.query.vnp_BankCode,
          vnp_BankTranNo: Number(this.$route.query.vnp_BankTranNo),
          vnp_CardType: this.$route.query.vnp_CardType,
          vnp_OrderInfo: this.$route.query.vnp_OrderInfo,
          vnp_PayDate: this.$route.query.vnp_PayDate,
          vnp_ResponseCode: this.$route.query.vnp_ResponseCode,
          vnp_TmnCode: this.$route.query.vnp_TmnCode,
          vnp_TransactionNo: this.$route.query.vnp_TransactionNo,
          vnp_TxnRef: Number(this.$route.query.vnp_TxnRef),
          vnp_SecureHashType: this.$route.query.vnp_SecureHashType,
          vnp_SecureHash: this.$route.query.vnp_SecureHash,
        })
      }
    }catch (e) {
      console.log(e)
    }
  },
  setup() {
    const store = useStore()
    const i18n = useI18n()
    const route = useRoute()
    const tabActive = ref(1)
    const shopInfo = ref<ShopInfo>({})
    const shopServices = ref<ShopServices[]>([])

    const columns = [{
      title: 'Nội dung CK',
      dataIndex: 'code',
      key: 'code',
    },
      {
        title: 'Tên gói cước',
        key: 'name',
        dataIndex: 'name',
        slots: {customRender: 'name'}
      },
      {
        title: 'Số page',
        key: 'page_num',
        dataIndex: 'page_num',
        slots: {customRender: 'page_num'}
      },
      {
        title: 'Số user',
        key: 'user_num',
        dataIndex: 'user_num',
        slots: {customRender: 'user_num'}
      },
      {
        title: 'Thành tiền',
        key: 'real_money',
        dataIndex: 'real_money',
        slots: {customRender: 'real_money'}
      },
      {
        title: 'Ngày bắt đầu',
        key: 'start_date',
        dataIndex: 'start_date',
        slots: {customRender: 'start_date'}
      },
      {
        title: 'Ngày kết thúc',
        key: 'end_date',
        dataIndex: 'end_date',
        slots: {customRender: 'end_date'}
      },
      {
        title: 'Trạng thái',
        key: 'status',
        dataIndex: 'status',
        slots: {customRender: 'status'}

      },
      {
        title: 'Nội dung',
        key: 'desc_money',
        dataIndex: 'desc_money',
        width: '12%'
      },
      {
        title: 'Action',
        key: 'action',
        slots: {customRender: 'action'},
      },
    ]


    const $cookies = inject<VueCookies>('$cookies');

    const formState = reactive({
      page_num: 0,
      user_num: 0,
      month: 0,
      year: 0,
      coupon: $cookies?.get('daily') ? $cookies?.get('daily') : ''
    })
    const tariffCategory = ref<TariffCategory>({month: [], six_month: [], year: []})
    const currentTariff = ref<Tariffs>({})
    const priceDiscount = ref<Coupon>({value: 0})
    const lang = computed((): string => store.getters.getLang)
    const dayConverts = computed(() => {
      if (formState.month > 0) {
        return moment().add(formState.month, 'months')
      } else {
        return moment().add(formState.year, 'years')
      }
    })
console.log(lang.value)

    const basePrice = (tariff: Tariffs) => computed(() => {
      switch (lang.value) {
        case "vi":
          return formatMoney(tariff.base_price ? tariff.base_price : 0)
        case "en":
          return formatMoney(tariff.base_price_en ? tariff.base_price_en : 0)
        case "thai":
          return formatMoney(tariff.base_price_th ? tariff.base_price_th : 0)
        default:
          return formatMoney(tariff.base_price ? tariff.base_price : 0)
      }
    })

    const activeTab = (tabNum: number) => {
      if (tabNum !== tabActive.value) {
        tabActive.value = tabNum
      }
    }

    const fetchTariffs = async () => {
      message.loading('')
      const response = await APIs.init().getTariffs()
      message.destroy()
      if (response.status === 200 && response.data?.status === 200) {
        tariffCategory.value = response.data.data

        if (response.data.data.month.length > 0)
          currentTariff.value = response.data.data.month[0]
        formState.page_num = currentTariff.value.start_page ? currentTariff.value.start_page : 0
        formState.user_num = currentTariff.value.start_user ? currentTariff.value.start_user : 0
        formState.month = currentTariff.value.group_name === "MONTH" ? 3 : currentTariff.value.group_name === "6MONTH" ? 6 : 0
        formState.year = currentTariff.value.group_name === "YEAR" ? 1 : 0

        if (route.query.goi_cuoc) {
          const tariffId = parseInt(route.query.goi_cuoc.toString())
          const month = tariffCategory.value.month.findIndex(t => t.id == tariffId)
          const sixMonth = tariffCategory.value.six_month.findIndex(t => t.id == tariffId)
          if (month > 0) {
            tabActive.value = 1
            const find = tariffCategory.value.month.find(t => t.id == tariffId)
            if (find) {
              currentTariff.value = find
              formState.page_num  = find.start_page?find.start_page:20
              formState.user_num  = find.start_user?find.start_user:20
              formState.year  = 0
              formState.month  = 3
            }
          } else if (sixMonth > 0) {
            tabActive.value = 2
            const find = tariffCategory.value.six_month.find(t => t.id == tariffId)
            if (find) {
              currentTariff.value = find
              formState.page_num  = find.start_page?find.start_page:20
              formState.user_num  = find.start_user?find.start_user:20
              formState.year  = 0
              formState.month  = 6
            }
          } else {
            tabActive.value = 3
            const find = tariffCategory.value.year.find(t => t.id == tariffId)
            if (find) {
              currentTariff.value = find
              formState.page_num  = find.start_page?find.start_page:20
              formState.user_num  = find.start_user?find.start_user:20
              formState.year  = 1
              formState.month  = 12
            }
          }
        }
      }
    }
    const fetchShopInfo = async () => {
      message.loading('')
      const response = await APIs.init().getShopInfo()
      message.destroy()
      if (response.status === 200 && response.data?.status === 200) {
        shopInfo.value = response.data.data
      }
    }

    const fetchShopServices = async () => {
      message.loading('')
      const response = await APIs.init().getShopServices()
      message.destroy()
      if (response.status === 200 && response.data?.status === 200) {
        shopServices.value = response.data.data
      }
    }

    const checkCoupon = async () => {
      message.loading('')
      const response = await APIs.init().checkCoupon({
        code: formState.coupon
      })
      message.destroy()
      if (response.status === 200 && response.data?.status === 200) {
        priceDiscount.value = response.data.data
        priceDiscount.value.value = parseInt(response.data.data.value + "")
      } else {
        formState.coupon = ""
      }
    }


    onMounted(() => {
      fetchTariffs()
      fetchShopInfo()
      fetchShopServices()
      if ($cookies?.get('daily')) {
        checkCoupon()
      }
    })

    const selectService = (tariff: Tariffs) => {
      currentTariff.value = tariff
      formState.page_num = tariff.start_page ? tariff.start_page : 0
      formState.user_num = tariff.start_user ? tariff.start_user : 0
      formState.month = tariff.group_name === "MONTH" ? 3 : tariff.group_name === "6MONTH" ? 6 : 0
      formState.year = tariff.group_name === "YEAR" ? 1 : 0
    }

    const tabChange = (tab: number) => {
      const month = tariffCategory.value.month.findIndex(t => t.id == currentTariff.value.id)
      const sixMonth = tariffCategory.value.six_month.findIndex(t => t.id == currentTariff.value.id)
      const year = tariffCategory.value.year.findIndex(t => t.id == currentTariff.value.id)
      switch (tab) {
        case 1:
          currentTariff.value = tariffCategory.value.month[Math.max(month, sixMonth, year)]
          break
        case 2:
          currentTariff.value = tariffCategory.value.six_month[Math.max(month, sixMonth, year)]
          break
        case 3:
          currentTariff.value = tariffCategory.value.year[Math.max(month, sixMonth, year)]
          break
      }
      formState.page_num = currentTariff.value.start_page ? currentTariff.value.start_page : 0
      formState.user_num = currentTariff.value.start_user ? currentTariff.value.start_user : 0
      formState.month = currentTariff.value.group_name === "MONTH" ? 3 : currentTariff.value.group_name === "6MONTH" ? 6 : 0
      formState.year = currentTariff.value.group_name === "YEAR" ? 1 : 0
    }

    const monthOptions = computed(() => {
      switch (tabActive.value) {
        case 1:
          return [
            // {
            //   value: 1,
            //   label:  '1 ' +i18n.t('month'),
            // },
            {
              value: 3,
              label:  '3 ' +i18n.t('month'),
            },
          ]
        case 2:
          return [
            {
              value: 6,
              label:  '6 ' +i18n.t('month'),
            },
            {
              value: 9,
              label:  '9 ' +i18n.t('month'),
            },
          ]
        default:
          return [
            {
              value: 1,
              label:  '1 ' +i18n.t('YEAR'),
            },
            {
              value: 2,
              label:  '2 ' +i18n.t('YEAR'),
            },  {
              value: 3,
              label:  '3 ' +i18n.t('YEAR'),
            },  {
              value: 4,
              label:  '4 ' +i18n.t('YEAR'),
            },
          ]

      }
    })

    const calculatorPrice = computed(() => {
      var price_formula = ""
      var discount_format = ""
      if (lang.value == 'en') {
        price_formula = currentTariff.value.price_formula_en ? currentTariff.value.price_formula_en : "";
        discount_format = currentTariff.value.discount_format_en ? currentTariff.value.discount_format_en : "";
      } else if (lang.value == 'thai') {
        price_formula = currentTariff.value.price_formula_th ? currentTariff.value.price_formula_th : "";
        discount_format = currentTariff.value.discount_format_th ? currentTariff.value.discount_format_th : "";
      } else {
        price_formula = currentTariff.value.price_formula ? currentTariff.value.price_formula : "";
        discount_format = currentTariff.value.discount_format ? currentTariff.value.discount_format : "";
      }

      const f_parse = math.parse(price_formula);
      var f = f_parse.compile();
      var pm = {
        p: formState.page_num,
        u: formState.user_num,
        m: formState.month > 0 ? formState.month : (formState.year * 12),
        start_page: currentTariff.value.start_page,
        start_user: currentTariff.value.start_user
      };
      var price = f.evaluate(pm);


      var real_date = moment(shopInfo.value.shop_services?.start_date).add(shopInfo.value.shop_services?.month_num, 'months')
      var c_money = 0;
      if (moment(shopInfo.value.shop_services?.end_date).diff(moment()) < 0) {
        c_money = 0;
      } else {
        console.log(shopInfo.value.shop_services?.month_num)
        if (shopInfo.value.shop_services?.month_num !== 0) {
          c_money = ((shopInfo.value.shop_services?.price || 0) * real_date.diff(moment(), 'days')) / ((shopInfo.value.shop_services?.month_num || 0) * 30 + (shopInfo.value.shop_services?.month_num || 0));
        } else {
          c_money = 0;
        }
      }


      var total_price = 0
      var old_money = c_money;

      switch (lang.value) {
        case "vi":
          old_money = c_money
          break
        case "en":
          old_money = c_money / 23393
          break
        case "thai":
          old_money = c_money / 664.21
          break
        default:
          old_money = c_money
          break
      }

      //console.log((parseInt(m)+parseInt(m_discount))*30);
      var new_day_price = price / ((formState.month > 0 ? formState.month : (formState.year * 12)) * 30);
      var m_day = Math.round(old_money / new_day_price);


      var discount_m = discount_format.split(',').filter(function (item) {
        var exp = item.split('-');
        return parseInt(exp[0]) == pm.m
      });
      var m_discount = 0;
      if (discount_m.length != 0) {
        var m_discount_exp = discount_m[0].split('-');
        m_discount = parseInt(m_discount_exp[1]);
      }

      if (priceDiscount.value.value == 100) {
        total_price = 0;
      } else {
        total_price = price - m_discount - (price * priceDiscount.value.value) / 100
      }

      var end_date = moment();
      end_date.add(formState.month > 0 ? formState.month : (formState.year * 12), "months").add(m_day, 'days')


      return {
        price: price,
        c_money,
        m_discount: m_discount + (price * priceDiscount.value.value) / 100,
        total_price,
        m_day,
        end_date
      }

    })

    const couponBlur = () => {
      if (formState.coupon) {
        checkCoupon()
      }
    }


    const SaveTariffs = async (vnPay: boolean) => {
      message.loading('')
      const trans_detail = `${i18n.t(currentTariff.value.name ? currentTariff.value.name : '')} (${formState.page_num} Page, ${formState.page_num} User, ${formState.month > 0 ? formState.month : (formState.year * 12)} ${i18n.t('MONTH')} )`
      const response = await APIs.init().saveTariffs({
        tariff_id: currentTariff.value.id ? currentTariff.value.id : 0,
        code: "SF " + shopInfo.value.id,
        page_save: Number(formState.page_num),
        user_save: Number(formState.user_num),
        coupon_code: formState.coupon,
        user_phone: "",
        trans_detail: trans_detail,
        type: vnPay,
        month_save: Number(formState.month) > 0 ? Number(formState.month) : (Number(formState.year) * 12),
      })
      message.destroy()
      if (response.status === 200 && response.data?.status === 200) {
        console.log(response)
        if(vnPay){
          window.open(response.data.data?response.data?.data:'',"_self")
        }else{
          notification.success({
            duration: 2,
            message: i18n.t('NOTIFICATION'),
            description: i18n.t("ORDERS_SUCCESS")
          });
          fetchShopServices()
        }
      } else {
        notification.warning({
          duration: 2,
          message: i18n.t('NOTIFICATION'),
          description: i18n.t(response.data?.message ? response.data?.message : '')
        });

      }
    }

    const formatMoney = (value: number) => {
      return MixUtils.methods.formatMoney(value)
    }


    const cancelServices = async (shopServices: ShopServices) => {
      message.loading('')
      const response = await APIs.init().deleteShopServices({
        id: shopServices.id,
      })
      message.destroy()
      if (response.status === 200 && response.data?.status === 200) {
        fetchShopServices()
        notification.success({
          duration: 2,
          message: i18n.t('NOTIFICATION'),
          description: response.data+''
        });
      } else {
        notification.warning({
          duration: 2,
          message: i18n.t('NOTIFICATION'),
          description: response.data+''
        });
      }
    }

    const confirmVNPay = async (params: any) => {
      message.loading('')
      const response = await APIs.init().confirmVNPay(params)
      message.destroy()
      if (response.status === 200 && response.data?.status === 200) {
        fetchShopServices()
        notification.warning({
          duration: 2,
          message: i18n.t('NOTIFICATION'),
          description: i18n.t('BUY_SUCCESS')
        });
      } else {
        notification.warning({
          duration: 2,
          message: i18n.t('NOTIFICATION'),
          description: response.data?.message
        });
      }
    }

    const currentShopInfo = computed((): ShopInfo => store.getters.getShopinfo);

    const shopActive = computed(()=>{
      if(currentShopInfo.value.shop_services?.status == 'Kích hoạt'){
        if(dayjs(currentShopInfo.value.shop_services.end_date).diff(dayjs(), 'seconds')>0){
          return 1
        }else{
          return 0
        }
      }else{
        return -1
      }
    })

    watch(currentShopInfo, (currentShopInfo, oldValue) => {

      console.log('shopActive', shopActive)
      console.log('shopActive', oldValue)
    })

    const getUserInfo = computed(() => store.getters.getUserInfo)

    const getMyRole = computed(() => {
      if(getUserInfo.value?.id_cms_privileges==null ||  getUserInfo.value?.id_cms_privileges<11){
        return ()=>({
          is_visible: 1,
          is_create:1,
          is_read: 1,
          is_edit: 1,
          is_delete: 1,
          id: 1
        })
      }else{
        return store.getters.getRole
      }
    });
    const modules = Constant.MODULES;


    return {
      getMyRole,
      modules,
      tariffCategory,
      shopActive,
      shopInfo,
      currentTariff,
      basePrice,
      lang,
      selectService,
      tabActive,
      activeTab,
      tabChange,
      formState,
      dayConverts,
      couponBlur,
      SaveTariffs,
      formatMoney,
      cancelServices,
      confirmVNPay,
      columns,
      shopServices,
      priceDiscount,
      monthOptions,
      calculatorPrice,
      FlagSVG: FlagSVG,
      labelCol: {span: 8},
      wrapperCol: {span: 15},
      wrapperCol2: {span: 15},
    }
  }
})
</script>

<style scoped lang="scss">
.body-upgrade-service-pack {
  margin-top: 16px;
  .header-title-upgrade-service-pack {
    h1 {
      text-align: center;
      font-size: 28px;
    }
  }

  /* taeb-switch styles */

  *,
  *:after,
  *:before {
    box-sizing: inherit;
  }

  * {
    outline: 0;
  }


  .wrapper {
    border-radius: 37px;
    //background-color: #f4f4f4;
    background-color: #ffffff;
    padding: 8px;
    width: 100%;
    max-width: 370px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 15px;

    .text-center {
      text-align: center;
    }

    .taeb-switch {
      position: relative;
    }

    .taeb-switch:after {
      content: "";
      position: absolute;
      width: 50%;
      top: 0;
      transition: left cubic-bezier(.88, -.35, .565, 1.35) .4s;
      border-radius: 27.5px;
      box-shadow: 0 2px 15px 0 rgba(0, 0, 0, .1);
      background-color: #FAB216;
      height: 100%;
      z-index: 0;
    }

    .taeb-switch.left:after {
      left: 0;
    }

    .taeb-switch.right:after {
      left: 50%;
    }

    .taeb-switch .taeb {
      display: inline-block;
      width: 50%;
      padding: 12px 0;
      z-index: 1;
      position: relative;
      cursor: pointer;
      transition: color 200ms;
      font-size: 16px;
      font-weight: bold;
      line-height: normal;
      user-select: none;
    }

    .taeb-switch .taeb.active {
      color: #ffffff;
    }

  }

  .card-pricing-container {
    display: flex;
    flex-wrap: wrap;
    margin-top: 20px;
    justify-content: center;

    .card-pricing {
      background-color: white;
      padding: 16px;
      margin: 16px;
      border-radius: 16px;
      max-width: 350px;
      box-shadow: 0 14px 26px rgba(0, 0, 0, 0.04);
      transition: 200ms linear;
      border-width: 0px;

      .price-show {
        font-size: 24px;
        font-weight: bold;
      }

      .month-show {
        color: #9c9c9c;
        font-size: 16px;
      }

      .title-price {
        font-size: 24px;
      }

      .line-function-list-item {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-top: 4px;

        .icon-checked {
          color: #FAB216;
        }

        .sub-list-item {
          padding-left: 10px;
        }
      }

      .upgrade-button {
        padding: 12px 20px;
        border: #FAB216 solid 1px;
        border-radius: 30px;
        margin: 16px 20px 10px;
        text-align: center;
        background-color: white;
        cursor: pointer;
        color: rgba(102, 102, 102, 0.99);
      }


    }

    .card-pricing-active {
      background-color: #FAB216;
      padding: 16px;
      border-radius: 16px;
      max-width: 350px;
      margin: 16px;
      box-shadow: 0 14px 26px rgba(0, 0, 0, 0.04);
      transition: 200ms linear;
      border-width: 0px;
      color: white;

      .price-show {
        font-size: 24px;
        font-weight: bold;
      }

      .month-show {
        color: white;
        font-size: 16px;

      }

      .title-price {
        font-size: 24px;
        color: white;
      }

      .line-function-list-item {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-top: 4px;

        .icon-checked {
          color: white;
        }

        .sub-list-item {
          padding-left: 10px;
        }
      }

      .upgrade-button {
        visibility: hidden;
        padding: 12px 20px;
        border: #FAB216 solid 1px;
        border-radius: 30px;
        margin: 16px 20px 10px;
        text-align: center;
        cursor: pointer;
        color: white;
      }

    }


    .card-pricing:hover {
      transform: translateY(-5px) scale(1.015) translateZ(0);
      box-shadow: 0 24px 36px rgba(0, 0, 0, 0.11),
      0 24px 46px var(--box-shadow-color);
      background-color: #FAB216;
      color: white;

      .month-show {
        color: white;
      }

      h1 {
        color: white;
      }

      .icon-checked {
        color: white;
      }

      .upgrade-button {
        font-weight: bold;
      }
    }
  }

}

.current-service-pack-container {
  .current-service-pack-header {
    .current-service-pack {
      padding: 20px;

      .icon-common {
        width: 18px;
        height: 18px;
      }
    }
  }
}


.build-service-pack {
  margin-left: 16px;
  margin-right: 16px;
  margin-top: 38px;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;

  @media only screen and (min-width: 768px) {
    /* For desktop: */
    .container-card-building-pack {
      width: 100%;
    }
    .card-building-pack {
      width: 50%;
    }
    .card-billing {
      width: 50%;
    }
    .payment-card {
      width: 100%;
      margin-top: 16px;
    }
  }

  @media only screen and (max-width: 500px) {
    .card-building-pack {
      width: 100%;
    }
    .card-billing {
      width: 100%;
    }
    .payment-card {
      width: 100%;
      margin-top: 16px;
    }
  }

  .icon-common-build-info {
    width: 16px;
    height: 16px;
  }

  .row-2-margin {
    margin-top: 14px;
  }

  .flex-col-billing {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .col-label {
      margin-left: 5px;
    }

    .col-value {
      font-weight: bold;
    }

    .promotion-value {
      color: #1890ff;
    }

    .total-amount {
      color: #f31717;
    }
  }

  .bank-information {

    h3 {
      text-align: center;
    }

    .detail-of-payment {
      font-style: italic;
    }
  }
}


</style>
