import {FlowContext} from "@/models/Flows/FlowContext";

export enum EventConstants {
    CURRENT_FLOW_CONTEXT = 'CURRENT_FLOW_CONTEXT',
    CHANGE_CURRENT_FLOW_CONTEXT = 'CHANGE_CURRENT_FLOW_CONTEXT'
}

export interface LanguageChange {
    localChange: (local: string) => void;
}

export interface ChangeCurrentFlow {
    flowChange: (flowId: number) => void;
    createFlow: (flow: FlowContext) => void;
    createRootFlowContext: (flow: FlowContext) => void;
}

export interface ValidateFlowContext {
    showValidateForm: () => void;
}

export class S_EventBus {
    static currentFlowCallback: ChangeCurrentFlow
    static validateFlowContextListener: ValidateFlowContext
    static languageChange: LanguageChange

    static instance = (): S_EventBus => new S_EventBus()

    setCurrentFlowListener = (listener: ChangeCurrentFlow): void => {
        S_EventBus.currentFlowCallback = listener
    }

    setLanguageChangeListener = (listener: LanguageChange): void => {
        S_EventBus.languageChange = listener
    }

    setValidateFlowContextListener = (listener: ValidateFlowContext):void => {
        S_EventBus.validateFlowContextListener = listener
    }

    dispatchValidateFlowContext = ():void => {
        S_EventBus.validateFlowContextListener.showValidateForm()
    }

    execChangeCurrentFlow = (flowId: number): void => {
        S_EventBus.currentFlowCallback.flowChange(flowId)
    }

    dispatchCreateFlow = (flow: FlowContext): void => {
        S_EventBus.currentFlowCallback.createFlow(flow)
    }
    dispatchCreateRootFlowContext = (flow: FlowContext): void => {
        S_EventBus.currentFlowCallback.createRootFlowContext(flow)
    }

    dispatchLanguageChange = (local: string): void => {
        S_EventBus.languageChange.localChange(local)
    }
}