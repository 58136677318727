import create from "zustand-vue";
import { persist } from "zustand/middleware";
import APIs from "@/APIs/APIs";
import { Trigger } from "@/models/Trigger/Trigger";
import { Metadata } from "@/APIs/response/S_HttpResponse";
import {
  TriggerStatistic,
  TriggerStatisticErrorLog,
  Viral,
} from "@/models/Trigger/TriggerHistory";

interface TriggerState {
  triggers: Trigger[] | undefined;
  triggerMetadata: Metadata | undefined;
  triggerStatistics: TriggerStatistic[];
  triggerInnerStatistics: TriggerStatistic[];
  triggerStatisticMetadata: Metadata;
  innerTriggerStatisticMetadata: Metadata;
  triggerQuickMessages: Trigger[];
  triggerStatisticErrorLogs: TriggerStatisticErrorLog[];
  viral: Viral[] | undefined;
  // eslint-disable-next-line @typescript-eslint/ban-types
  getTriggers: (params: any) => void;
  getTriggersSelection: (params: any) => void;
  getTriggerMetadata: (params: any) => void;
  getTriggerStatisticMetadata: (params: any) => void;
  getTriggerStatistics: (params: any) => void;
  getInnerTriggerStatistics: (params: any) => void;
  getTriggerStatisticLogs: (params: any) => void;
  getInnerTriggerStatisticMetadata: (params: any) => void;
  getTriggersQuickMessages: (params: any) => void;
  getViral: (params: any) => void;
  viralMetadata:any
}

const initialState = {
  triggerMetadata: {},
  triggers: [],
  triggerStatistics: [],
  triggerInnerStatistics: [],
  triggerStatisticMetadata: {},
  innerTriggerStatisticMetadata: {},
  triggerQuickMessages: [],
  triggerStatisticErrorLogs: [],
  viral: [],
  viralMetadata:{}

};

const useTriggerStore = create<TriggerState>(
  // @ts-ignore
  persist(
    (set) => ({
      ...initialState,
      getTriggers: async (params) => {
        const response = await APIs.init().getTriggers(params);
        if (response.data && response.data.status === 200) {
          // @ts-ignore
          set(() => ({
            // @ts-ignore
            triggers: response.data.data,
          }));
        }
      },
      getTriggersSelection: async (params) => {
        const response = await APIs.init().getFiles();
        if (response.data && response.data.status === 200) {
          // @ts-ignore
          set(() => ({
            // @ts-ignore
            awsDoFiles: response.data.data,
          }));
        }
      },
      getTriggerMetadata: async (params) => {
        const response = await APIs.init().getFiles();
        if (response.data && response.data.status === 200) {
          // @ts-ignore
          set(() => ({
            // @ts-ignore
            awsDoFiles: response.data.data,
          }));
        }
      },
      getTriggerStatisticMetadata: async (params) => {
        const response = await APIs.init().getFiles();
        if (response.data && response.data.status === 200) {
          // @ts-ignore
          set(() => ({
            // @ts-ignore
            awsDoFiles: response.data.data,
          }));
        }
      },
      getTriggerStatistics: async (params) => {
        const response = await APIs.init().getTriggerStatistics(params);
        if (response.data && response.data.status === 200) {
          // @ts-ignore
          set(() => ({
            // @ts-ignore
            triggerStatistics: response.data.data,
          }));
        }
      },
      getInnerTriggerStatistics: async (params) => {
        const response = await APIs.init().getInnerTriggerStatistics(params);
        if (response.data && response.data.status === 200) {
          // @ts-ignore
          set(() => ({
            // @ts-ignore
            triggerInnerStatistics: response.data.data,
          }));
        }
      },
      getTriggerStatisticLogs: async (params) => {
        const response = await APIs.init().getTriggerStatisticLogs(params);
        if (response.data && response.data.status === 200) {
          // @ts-ignore
          set(() => ({
            // @ts-ignore
            triggerStatisticErrorLogs: response.data.data,
          }));
        }
      },
      getInnerTriggerStatisticMetadata: async (params) => {
        const response = await APIs.init().getFiles();
        if (response.data && response.data.status === 200) {
          // @ts-ignore
          set(() => ({
            // @ts-ignore
            awsDoFiles: response.data.data,
          }));
        }
      },
      getTriggersQuickMessages: async (params) => {
        const response = await APIs.init().getTriggersQuickMessages(params);
        if (response.data && response.data.status === 200) {
          // @ts-ignore
          set(() => ({
            // @ts-ignore
            triggerQuickMessages: response.data.data,
          }));
        }
      },
      getViral: async (params) => {
        const response = await APIs.init().getViral(params);
        if (response.data && response.data.status === 200) {
          // @ts-ignore
          set(() => ({
            // @ts-ignore
            viral: response.data.data,
            viralMetadata:response.data?.meta_data
          }));
        }
      },
    }),
    {
      name: "trigger",
    }
  )
);

export default useTriggerStore;
