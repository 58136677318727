<template>
  <div>
  <a-row class="header"  >
    <img
        src="https://biglead.live/images/favicon.png"
        style="width: 28px; height: auto"/>
    <a-col>
      <div class="description">{{$t('ACTION_CRM')}}</div>
      <span class="content">{{data.name}}</span>
    </a-col>
  </a-row>
  <div class="container">
      <div class="crm-action-title-container">
        <div class="title-selected-crm-action">
          {{ $t(data.type) }}
        </div>
      </div>
      <a-row style="align-items: center">
        <a-col :span="20">
          <a-tree-select
              v-if="data.type==='add_tag'||data.type==='remove_tag'"
              show-search
              style="width: 100%"
              disabled

              :value="data.tags.split(',')"
              :filterTreeNode="false"
              :notFoundContent="null"
              :dropdown-style="{ maxHeight: '300', overflow: 'auto' }"
              allow-clear
              multiple
              :placeholder="$t('TAG_SELECT')"
          >
            <a-tree-select-node v-for="item in treeTags" :key="item.id" v-bind:value="item.id">
              <template #title>
                <a-tag :color="item.color">
                  {{ item.name }}
                </a-tag>
              </template>
              <a-tree-select-node v-for="child in item.children" :key="child.id" v-bind:value="child.id">
                <template #title>
                  <a-tag :color="child.color">
                    {{ child.name }}
                  </a-tag>
                </template>
              </a-tree-select-node>

            </a-tree-select-node>
          </a-tree-select>
          <a-select
              v-else-if="data.type==='update_last_care'"
              style="width: 100%"
              allowClear
              disabled
              placeholder="Please select"
              :value="data.last_care"
          >
            <a-select-option
                v-for="item in customerCares"
                :key="item.id"
                v-bind:value="item.id"

            >
              <a-tag :color="item.color">
                {{ item.name }}
              </a-tag>
            </a-select-option>

          </a-select>
          <a-select
              v-else
              disabled
              style="width: 100%"
              :value="data.tags.split(',')"
              :filterTreeNode="false"
              :notFoundContent="null"
              multiple
              :placeholder="$t('TAG_SELECT')"
          >
            <a-select-option
                v-for="item in converstionTag"
                :key="item.tag_id"
                v-bind:value="item.tag_id"

            >
              <a-tag :color="item.tag_color">
                {{ item.tag_name }}
              </a-tag>
            </a-select-option>
          </a-select>
        </a-col>
      </a-row>


    <div  style="height: 2px; background-color: #f2f4f6"/>
    <Handle :id="data.id" type="target"  class="handle" :position="Position.Left" :style="sourceHandleStyleA" />
    <template v-for="(handle, index) in listHandle" :key="handle">
        <HandleCustom :data="handle" :index="index"  />
    </template>
  </div>
  </div>

</template>

<script>
import {defineComponent, defineProps, defineEmits, onMounted, ref} from "vue";
import { computed } from 'vue'
import {Position, VueFlow, useVueFlow, Handle, MarkerType} from '@vue-flow/core'
import ViralLuckyWheelPreview from "@/components/Flow/ViralLuckyWheelPreview.vue";
import HandleCustom from "./HandleCustom.vue";
import {MixUtils} from "@/utils/Utils";
import {useI18n} from "vue-i18n";
import {store} from "@/store";

const props = defineProps({
  data: {
    type: Object,
    required: true,
  },
})


export default defineComponent({
  name: "ActionCRMNode",
  components:{
    Handle,
    // ViralLuckyWheelPreview,
    HandleCustom,
  },
  props: [ 'data'],
  setup(props, {emit}){
    const i18n = useI18n()
    const listHandle = ref([{
      name: i18n.t('NEXT_ACTION')
    }])

    const sourceHandleStyleA = computed(() => ({ top: '10px',
      bottom: 'auto', }))

    const sourceHandleStyleC = computed(() => ({  top: 'auto',
      bottom: '10px', }))

    onMounted(()=>{
      console.log('data v', props.data)

      if(props.data.flow_viral){
        props.data.flow_viral.map(viral=>{
          viral.gifts.map(gift=>{
              listHandle.value.push(gift)
          })
        })
      }
      console.log('converstionTag', converstionTag)
    })

    const treeTags = computed(() => store.getters.getTreeTags)
    const customerCares = computed(() => store.getters.getCustomerCares)
    const converstionTag = computed(() => store.getters.getConversationTags)


    return{
      treeTags,
      customerCares,
      converstionTag,
      sourceHandleStyleA,
      sourceHandleStyleC,
      Position,
      listHandle,
    }
  }
})
</script>

<style scoped>
.container{
  padding: 16px 16px 16px 16px;
  font-size: 14px;
}

.add_trigger{
  border-width: 0.5px;
  width: 100%;
  border-color: #acacac80;
  border-style: dotted;
}
.next_step{
  position: absolute;
  bottom: 8px;
  right: 8px;
  text-align: right;
}
.header{
  padding: 16px 16px 16px 16px;
  border-radius: 8px 8px 0 0;
  display: flex;
  flex-flow: row;
  align-items: center;
  gap: 8px;
  background-color: #ffeca7
}
.element{
  display: flex;
  align-items: center;
  justify-content: start;
  padding: 8px 0 8px 0;
  border-radius: 10px;
  margin-bottom: 12px;
  background-color: #e1faec;
}
.content{
  font-size: 16px;
  font-weight: normal;
}
.content_mess{
  margin: 4px 0 4px;
  padding: 8px;
  border-radius: 10px;
  background-color: #B1B1B77E;
  font-size: 14px;
  font-weight: normal;
}
.description{
  color: #758697;
  font-size: 10px;
}
.add_trigger{
  height: 50px;
}
.icon{
}
/*.handle {*/
/*  border-width: 0;*/
/*  background-color: #8492a6;*/
/*  transition: transform .2s;*/
/*  width: 15px;*/
/*  height: 15px;*/
/*  margin: 0 auto;*/
/*}*/

/*.handle:hover {*/
/*  background-color: #0084ff;*/
/*}*/
</style>
