import { EmployeeSearch } from "@/models/employee/Employee";
import { Employee } from "@/models/Users";
import { EmployeeData, State} from "./states";
import useEmployeeStore from "@/store/Employee";

export type Getters = {
    getEmployeeSearch(state: State):  EmployeeSearch[]
    getEmployees(state: State):  any
    getListEmployee(state: State): Employee[] | undefined;
}
export const getters: Getters = {
    getEmployeeSearch(state: State):  EmployeeSearch[] {

        if (state.dataSearch) {
            return state.dataSearch
        } else {
          return []
        }
    },
    getEmployees(state: State):  any {

        if (useEmployeeStore.getState().employees) {
            return useEmployeeStore.getState().employees
        } else {
          return {data: [], metadata: {}}
        }
    },
    getListEmployee (state: State): Employee[] {
        if (state.employeeData?.data) {
            return state.employeeData.data
        } else {
            return []
        }
    },

}
