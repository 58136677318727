import {ActionContext, ActionTree} from "vuex";
import {State} from "./states";
import {RootState} from "@/store";
import APIs from "@/APIs/APIs";
import { Mutations } from "./mutations";
import { EventResponse} from "@/APIs/response/S_HttpResponse";
import { EventMutationTypes} from "./mutation-types";
import {EventRequest} from "@/APIs/request/EventRequest";
import {EventActionTypes} from "@/store/modules/Event/action-types";

type AugmentedActionContext = {
    commit<K extends keyof Mutations>(
        key: K,
        payload: Parameters<Mutations[K]>[1],
    ): ReturnType<Mutations[K]>;
} & Omit<ActionContext<State, RootState>, 'commit'>

export interface Actions {
    [EventActionTypes.FETCH_EVENT](
        {commit}: AugmentedActionContext,
        payload?: EventRequest,
    ): Promise<EventResponse | undefined>;

}

export const actions: ActionTree<State, RootState> & Actions = {
    async [EventActionTypes.FETCH_EVENT]({commit }, payload: EventRequest): Promise<EventResponse | undefined> {
        const response = await APIs.init().getEvent(payload)
        if (response.status === 200) {
            commit(EventMutationTypes.GET_EVENT,
                // @ts-ignore
                 { events: response.data?.data,
                metadata: response.data?.meta_data,})
        }
        return response.data;
    },


}


