<template>
  <a-result status="404" title="404" sub-title="xin lỗi, page bạn tìm hiện đã không còn hoạt động vui lòng quay trở lại sau hihi
  ." style="background-color: white;min-height: 80vh">
    <template #extra>
      <a-button type="primary" @click="$router.go(-1)">
        <arrow-left-outlined/>
        Back Previous Page
      </a-button>
      <a-button type="primary">
        <router-link :to="RouterEndpoint.CUSTOMER_MANAGEMENT.path">
          <home-outlined/>
          Back Home
        </router-link>
      </a-button>
    </template>
  </a-result>
</template>

<script lang="ts">
import {Options, Vue} from "vue-class-component";
import {ArrowLeftOutlined,HomeOutlined} from "@ant-design/icons-vue"
import RouterEndpoint from "@/router/RouterEndpoint";


@Options({
  name: '404-page-not-found',
  components:{
    ArrowLeftOutlined,
    HomeOutlined
  },
  data(){
    return{
      RouterEndpoint:RouterEndpoint
    }
  }
})
export default class PageNotFound404 extends Vue {}

</script>

<style scoped>

</style>
