<template>
  <h3 class="title-flow-context">
    {{$t('FLOW_PREVIEW')}}
  </h3>
  <div class="preview-container">
    <a-comment>
      <template #actions>
        <span key="comment-nested-reply-to">Reply to</span>
      </template>
      <template #author>
        <a>Han Solo</a>
      </template>
      <template #avatar>
        <a-avatar
            src="https://biglead.live/images/logo.png"
            alt="Han Solo"
        />
      </template>
      <template #content>
        <p>
         {{$t('EXAMPLE')}}
        </p>
      </template>
      <a-comment>
        <template #actions>
          <span key="comment-nested-reply-to">Reply to</span>
        </template>
        <template #author>
          {{shop.shop_name}}
        </template>
        <template #avatar>
          <a-avatar
              src="https://biglead.live/images/logo.png"
              alt="Han Solo"
          />
        </template>
        <template #content>
          <p>
            {{ flowContext.message }}
          </p>
        </template>
      </a-comment>
    </a-comment>
  </div>
</template>

<script lang="ts">
import {computed, defineComponent, ref, watch} from "vue";
import {PostbackContext} from "@/models/Flows/FlowContext";
import {useStore} from "@/store";

export default defineComponent({
  name: "facebook-reply-comment-preview",
  props: ['flowContext'],
  setup(props) {
    let postBacks = ref<PostbackContext[]>([])
    const store=useStore()
    const shop=computed(()=>store.getters.getCurrentShop)
    watch(() => props.flowContext, () => {
      updateProps()
    })
    const updateProps = () => {
      if (props.flowContext.postback_contexts) {
        postBacks.value = [...props.flowContext.postback_contexts]
      }
    }
    updateProps()
    return {
      postBacks,
      shop
    }
  }
})
</script>

<style lang="scss" scoped>
.preview-container {
  background-color: white;
  min-height: 480px;
  border-radius: 10px;
  padding: 15px;
}

.content-message {
  margin-right: 20px;
  padding: 15px;
  background-color: #E1E1E1;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  border-bottom-right-radius: 15px;
}

.post-back-button {
  margin-top: 18px;
  text-align: center;

  .button-post-back {
    margin: 5px 10px;
  }
}

.title-flow-context {
  text-align: center;
  font-weight: bold;
}
</style>
