import Conversion from "@/views/report/Campaign/Campaign.vue";

const RouterEndpoint = {

    LOGIN: {
        name: "login",
        path: "/login"
    },
    LOGIN_OUTSITE: {
        name: "login-outsite",
        path: "/login-outsite"
    },
    HOME: {
        name: "home",
        path: "/",
    },
    CMS: {
        name: "CMS",
        path: "/cms",
    },
    RFM: {
        name: "RFM",
        path: "/rfm",
    },
    CUSTOMER_MANAGEMENT: {
        name: "CUSTOMER",
        path: "/customers",
    },
    SCAN_PAGE: {
        name: "SCAN_PAGE",
        path: "/scan_page",
    },
    DEPARTMENT: {
        name: "DEPARTMENT",
        path: "/departments",
    },
    CUSTOMER_DETAIL_EDIT: {
        name: "CUSTOMER_DETAIL_EDIT",
        path: "/customer/:id",
    },
    CUSTOMER_EDIT_DETAIL: {
        name: "CUSTOMER_EDIT_DETAIL",
        path: "/customer-detail/:id",
    },
    CUSTOMER_TAGS: {
        name: "CUSTOMER_TAGS",
        path: "/customers/tags",
    },
    CUSTOMER_CARES: {
        name: "CUSTOMER_CARES",
        path: "/customers/customer-cares",
    },
    CUSTOMER_SCHEDULER: {
        name: "CUSTOMER_SCHEDULER",
        path: "/customer/schedules",
    },
    EMPLOYEE_ADD: {
        name: "EMPLOYEE_ADD",
        path: "/employee/add",
    },
    AUTO_INBOX: {
        name: "AUTO_INBOX",
        path: "/auto-inbox",
    },
    AUTO_INBOX_EDiT: {
        name: "AUTO_INBOX_EDiT",
        path: "/auto-inbox/edit",
    },
    AUTO_INBOX_TAGS: {
        name: "AUTO_INBOX_TAGS",
        path: "/auto-inbox/tags",
    },
    FLOWS: {
        name: "FLOWS",
        path: "/flows",
    },
    VOUCHER: {
        name: "VOUCHER",
        path: "/voucher",
    },
    EVOUCHER_CODE_LIST: {
        name: "EVOUCHER_CODE_LIST",
        path: "/evoucher-code-list",
    },
    MEMBER_RANK: {
        name: "MEMBER_RANK",
        path: "/member-rank",
    },
    CREATE_VOUCHER: {
        name: "CREATE_VOUCHER",
        path: "/voucher/:id",
    },
    CREATE_MEMBER_RANK: {
        name: "CREATE_MEMBER_RANK",
        path: "/member-rank/:id",
    },
    EXTRA_COLUMN: {
        name: "EXTRA_COLUMN",
        path: "/extra-column",
    },
    ACCOUNT: {
        name: "ACCOUNT",
        path: "/account",
    },
    INTEGRATED: {
        name: "INTEGRATED",
        path: "/integrated",
    },
    VIRAL_LIST: {
        name: "VIRAL_LIST",
        path: "/virals",
    },
    CREATE_VIRAL: {
        name: "CREATE_VIRAL",
        path: "/viral",
    },
    UPDATE_VIRAL: {
        name: "UPDATE_VIRAL",
        path: "/viral/:id",
    },
    VIRAL_TAGS: {
        name: "VIRAL_TAGS",
        path: "/viral/tags",
    },
    FLOW_CONTEXT: {
        name: "FLOW_CONTEXT",
        path: "/flow/context/:id",
    },
    FLOW: {
        name: "FLOW",
        path: "/flow/:id",
    },
    FLOW_TAGS: {
        name: "FLOW_TAGS",
        path: "/flow/tags",
    },
    TRIGGERS: {
        name: "TRIGGERS",
        path: "/triggers",
    },
    TRIGGER_TAGS: {
        name: "TRIGGER_TAG",
        path: "/trigger/tags",
    },
    TRIGGER_HISTORY: {
        name: "TRIGGER_HISTORY",
        path: "/trigger/history",
    },
    VIRAL_HISTORY: {
        name: "VIRAL_HISTORY",
        path: "/trigger/viral_history",
    },
    TICKET: {
        name: "TICKET_TRANSACTION",
        path: "/ticket/transaction/:id",
    },
    EDIT_TICKET: {
        name: "EDIT_TICKET",
        path: "/ticket/edit-ticket/:id",
    },
   ADD_TICKET: {
        name: "ADD_TICKET",
        path: "/ticket/add-ticket/:id",
    },
    LIST_TICKET: {
        name: "LIST_TICKET",
        path: "/ticket/list-ticket/:id",
    },
    LIST_TICKET_TYPE: {
        name: "LIST_TICKET_TYPE",
        path: "/ticket/list-ticket-type/:id",
    },
    LIST_EVENT: {
        name: "LIST_EVENT",
        path: "/ticket/list-event",
    },
    LIST_TEMPLATE: {
        name: "LIST_TEMPLATE",
        path: "/ticket/template",
    },
    INTEGRATION_PLATFORMS: {
        name: "INTEGRATION_PLATFORMS",
        path: "/integration",
    },
    OVERVIEW: {
        name: "OVERVIEW",
        path: "/overview",
    },
    ORDERS: {
        name: "ORDERS",
        path: "/orders",
    },
    PRODUCTS: {
        name: "PRODUCTS",
        path: "/products",
    },
    STOCKS: {
        name: "STOCKS",
        path: "/stocks",
    },
    STOCK: {
        name: "STOCK",
        path: "/stock/:id",
    },
    REPORT_ZNS_TRANSACTION: {
        name: "REPORT_ZNS_TRANSACTION",
        path: "/report/zns/transactions",
    },
    PRODUCT: {
        name: "PRODUCT",
        path: "/product/:id",
    },
    PRODUCT_TAGS: {
        name: "PRODUCT_TAGS",
        path: "/product/tags",
    },
    ORDER: {
        name: "ORDER",
        path: "/order/:id",
    },
    EDIT_ORDER: {
        name: "EDIT_ORDER",
        path: "/order/:id/edit",
    },
    TAGS: {
        name: "TAGS",
        path: "/tags",
    },
    WALLET_TRANSACTION: {
        name: "WALLET_TRANSACTION",
        path: "/wallet/transactions",
    },
    LOCALES: {
        name: "LOCALES",
        path: "/locales",
    },
    SERVICE_PACK: {
        name: "SERVICE_PACK",
        path: "/service-pack",
    },
    TRANSPORTER: {
        name: "TRANSPORTER",
        path: "/transporter",
    },
    CATEGORIES: {
        name: "CATEGORIES",
        path: "/categories",
    },


    CATEGORY: {
        name: "CATEGORY",
        path: "/category/:id",
    },
    PROVIDERS: {
        name: "PROVIDERS",
        path: "/providers",
    },
    SHOPINFO: {
        name: "SHOPINFO",
        path: "/shop_info",
    },
    HELP_CENTER: {
        name: "HELP_CENTER",
        path: "/help_center",
    },
    EMPLOYEE: {
        name: "EMPLOYEE",
        path: "/employee",
    },
    ROLE: {
        name: "ROLE",
        path: "/role",
    },
    EMPLOYEEDETAIL: {
        name: "EMPLOYEEDETAIL",
        path: "/employee/detail/:userid",
    },
    EMPLOYEEEDIT: {
        name: "EMPLOYEEEDIT",
        path: "/employee/edit/:userid",
    },


  CREATE_PRODUCT: {
    name: "CREATE_PRODUCT",
    path: "/create_product",
  },
    DASHBOARD: {
        name: "DASHBOARD",
        path: "/dashboard",
    },
    ACCOUNTLOGIN: {
        name: "login",
        path: "/account_login",
    },
    LOGIN_FACEBOOK: {
        name: "LOGIN_FACEBOOK",
        path: "/login_facebook",
    },
    REPORT_OVERVIEW: {
        name: "REPORT_OVERVIEW",
        path: "/report_overview",
    },
    REPORT_SALE: {
        name: "REPORT_SALE",
        path: "/report_sale",
    },
   CUSTOMER_BY_ORDER: {
        name: "CUSTOMER_BY_ORDER",
        path: "/customer_by_order",
    },
    REVENUE: {
        name: "REVENUE",
        path: "/revenue",
    },
    REPORT_EMPLOYEE: {
        name: "REPORT_EMPLOYEE",
        path: "/report_employee",
    },
    REPORT_ORDER: {
        name: "REPORT_ORDER",
        path: "/report_order",
    },
    REPORT_PAGE: {
        name: "REPORT_PAGE",
        path: "/report_page",
    },
    REPORT_STAFF: {
        name: "REPORT_STAFF",
        path: "/report_staff",
    },
    REPORT_CAMPAIGN: {
        name: "REPORT_CAMPAIGN",
        path: "/report_campaign",
    },
    REPORT_ADVERTISEMENT: {
        name: "REPORT_ADVERTISEMENT",
        path: "/report_advertisement",
    },
    REPORT_INTERACTION: {
        name: "REPORT_INTERACTION",
        path: "/report_interaction",
    },
    REPORT_CUSTOMER: {
        name: "REPORT_CUSTOMER",
        path: "/report_customer",
    },

    TEST_VIEW: {
        name: " TEST_VIEW",
        path: "/test_view",
    },
    PRODUCT_PREVIEW: {
        name: "PRODUCT_PREVIEW",
        path: "/product_preview/:id",
    },
    ORDER_PREVIEW: {
        name: "ORDER_PREVIEW",
        path: "/order_preview/:id",
    },

    PREVIEW_ORDER: {
        name: "PREVIEW_ORDER",
        path: "/preview_order",
    },

    SETTING_PAGE:{
        name: "setting_page",
        path:"/setting_page",
    },

    CUSTOMER_CAREER: {
        name: "CUSTOMER_CAREER",
        path: "/careers",
    },
    PAYMENT: {
        name: "PAYMENT",
        path: "/payment",
    },
    MAIL_EDITOR: {
        name: "MAIL_EDITOR",
        path: "/mail_editor/:id",
    },
    AUTOMATION_TRIGGER: {
        name: "AUTOMATION_TRIGGER",
        path: "/auto_trigger",
    },
    AUTOMATION_TRIGGER_DETAIL: {
        name: "AUTOMATION_TRIGGER_DETAIL",
        path: "/auto_trigger/:id",
    },
    AUTO_INBOX_DOWNLOAD: {
        name: "AUTO_INBOX_DOWNLOAD",
        path: "/auto_inbox_download",
    },
    LANDING_PAGE: {
        name: "LANDING_PAGE",
        path: "/landing_page",
    },
    ORDER_THERMAL: {
        name: "ORDER_THERMAL",
        path: "/order_thermal",
    },
    TICKET_REPORT: {
        name: "TICKET_REPORT",
        path: "/ticket/report/:id",
    },
};


export default RouterEndpoint;
