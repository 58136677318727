<template>
  <div class="container-title">
    <h3 class="title-header">{{$t($route.name)}}</h3>

    <a-button
        type="primary" class="button-add-product" >
      <plus-outlined/>
      {{$t('ADD')}}
    </a-button>
  </div>
  <div style="height: 85vh;">
    <VueFlow
        :connection-line-style="connectionLineStyle"
        fit-view-on-init
    >
      <template #node-trigger="props">
        <TriggerGroupNode
            v-bind="props"
            @addTrigger="addTrigger"
        />
      </template>
      <template #node-facebook="{ data }">
        <FacebookNode :data="data"
                               @addTrigger="addTrigger"
        />
      </template>
      <template #node-zalo-oa="{ data }">
        <ZaloOANode :data="data"
                  @addTrigger="addTrigger"
        />
      </template>
      <template #node-facebook-bot="{ data }">
        <FacebookNode :data="data"
                               @addTrigger="addTrigger"
        />
      </template>
      <template #node-zalo-zns="{ data }">
        <ZaloZNSNode :data="data"
                  @addTrigger="addTrigger"
        />
      </template>
      <template #node-email="{ data }">
        <span>email, {{data.name}}</span>
      </template>

      <template #node-lucky-well="{ data }">
       <LuckyWellNode  :data="data"/>
      </template>


      <template #node-voice-brand="{ data }">
        <span>voice-brand, {{data.name}}</span>
      </template>
      <template #node-share-gift="{ data }">
        <span>share-gift, {{data.name}}</span>
      </template>

      <template #node-timer="{ data }">
        <TimerNode  :data="data"/>
      </template>
      <template #node-action-crm="{ data }">
        <ActionCRMNode  :data="data"/>
      </template>
      <template #node-web="{ data }">
        <WebNode  :data="data"/>
      </template>
    </VueFlow>
  </div>
</template>

<script >
import {VueFlow, useVueFlow, Position, MarkerType} from "@vue-flow/core";
import {h, computed, defineComponent, onBeforeMount, onMounted, reactive, ref, UnwrapRef} from "vue";

import TriggerGroupNode from './TriggerGroupNode.vue'
import FacebookNode from './FacebookNode.vue'
import ZaloOANode from './ZaloOANode.vue'
import ZaloZNSNode from './ZaloZNSNode.vue'
import ActionCRMNode from './ActionCRMNode.vue'
import LuckyWellNode from './LuckyWellNode.vue'
import TimerNode from './TimerNode.vue'
import WebNode from './WebNode.vue'
import APIs from "@/APIs/APIs";
import {useRoute} from "vue-router";
import {useI18n} from "vue-i18n";
import {MixUtils} from "@/utils/Utils";
export default defineComponent({
  name: "FlowDetail",
  components:{
    TriggerGroupNode,
    FacebookNode,
    ZaloOANode,
    ZaloZNSNode,
    VueFlow,
    LuckyWellNode,
    ActionCRMNode,
    TimerNode,
    WebNode,
  },
  setup(){

    const colors = {
      action: "#ffeca7",
      success: "#1fcd73",
      notResponse : "#ea5039"
    }

    const route = useRoute()


    const trigger = ref({})
    const i18n = useI18n()


    const gradient = ref(false)

    const currentNode = ref({})


    const bgName = ref('AYAME')

    const { onConnect, nodes, edges, addEdges, addNodes, getNode, defaultViewport, fitView } = useVueFlow({
      minZoom: 0.4,
      fitViewOnInit: false,
      elevateEdgesOnSelect: true,
      nodes: [

      ],
      edges: [
        // { id: 'a-b', source: '2', target: '3', markerEnd: MarkerType.ArrowClosed, deletable: true,  style: {strokeWidth: 3} },
        // { id: 'e1-4c', source: '1', target: '4c', markerEnd: MarkerType.ArrowClosed, style: {strokeWidth: 3} },
        // { id: 'e2a-4a', source: '2a', target: '4a' , markerEnd: MarkerType.ArrowClosed,},
        // { id: 'e4a-4b1', source: '4a', target: '4b1' , markerEnd: MarkerType.ArrowClosed,},
        // { id: 'e4a-4b2', source: '4a', target: '4b2' , markerEnd: MarkerType.ArrowClosed,},
        // { id: 'e4b1-4b2', source: '4b1', target: '4b2' , markerEnd: MarkerType.ArrowClosed,},
      ],
    })


    const  fetchFlowContext = async (id) => {
      const response = await APIs.init().getTrigger({id: id})
      console.log(response)
      const node = getNode.value('2')
      console.log('node', node)

      const listNode = []
      const listEdge = []

      if (response.ok && response.data?.data) {
        listNode.push({
          id: response.data?.data.id+'',
          type: 'trigger',
          class: 'my-custom-node-class',
          position: { x: 100, y: 100 },
          data: response.data?.data,
        })

        const flowContexts = response.data?.data?.flow?.context.flow_contexts
        if(!flowContexts){
          addNodes(listNode)
          return
        }
        let flowType;

        for (let i = 0; i < flowContexts.length; i++) {
          switch(flowContexts[i].flow_type){
                case 1:
                  flowType= 'facebook';
                  break;

                case 4:
                  flowType= 'zalo-oa';
                  break;
                case 3:
                  flowType= 'facebook-bot';
                  break;
                case 2:
                  flowType= 'zalo-zns';
                  break;
                case 5:
                  flowType= 'email';
                  break;
                case 6:
                  flowType= 'lucky-well';
                  break;
                case 7:
                  flowType= 'voice-brand';
                  break;
                case 9:
                  flowType= 'share-gift';
                  break;

                case 8:
                  flowType= 'timer';
                  break;
                default: flowType= 'facebook'
              }
              listNode.push({
                id: flowContexts[i].id,
                type: flowType,
                label: flowContexts[i].name,
                class: 'my-custom-node-class',
                position: { x: 650*(i+1), y: 100 },
                targetHandle: Position.Top,
                sourceHandle: Position.Right,
                data: flowContexts[i]
              })

        }
        // flowContexts.map(context=>{
        //   if(context.action_default_context.usage_id!==""){
        //     listEdge.push({ id: MixUtils.methods.getUUID(), source: context.id,
        //       sourceHandle: context.id+'action_default_context',
        //       target: context.action_default_context.usage_id, markerEnd: MarkerType.ArrowClosed, deletable: true,  style: {  strokeWidth: 3} })
        //   }
        //   if(context.action_finish_viral.usage_id!==""){
        //     listEdge.push({ id: MixUtils.methods.getUUID(), source: context.id,
        //       sourceHandle: context.id+'action_finish_viral',
        //       target: context.action_finish_viral.usage_id, markerEnd: MarkerType.ArrowClosed, deletable: true,  style: {  strokeWidth: 3} })
        //   }
        //   if(context.action_open_mail_context.usage_id!==""){
        //     listEdge.push({ id: MixUtils.methods.getUUID(),
        //       sourceHandle: context.id+'action_open_mail_context',
        //       source: context.id, target: context.action_open_mail_context.usage_id, markerEnd: MarkerType.ArrowClosed, deletable: true,  style: {  strokeWidth: 3} })
        //   }
        //   if(context.action_send_failed_context.usage_id!==""){
        //     listEdge.push({ id: MixUtils.methods.getUUID(),
        //       sourceHandle: context.id+'action_send_failed_context',
        //       source: context.id, target: context.action_send_failed_context.usage_id, markerEnd: MarkerType.ArrowClosed, deletable: true,  style: { stroke: colors.notResponse,  strokeWidth: 3} })
        //   }
        //   if(context.action_user_received_message_context.usage_id!==""){
        //     listEdge.push({ id: MixUtils.methods.getUUID(),
        //       sourceHandle: context.id+'action_user_received_message_context',
        //       source: context.id, target: context.action_user_received_message_context.usage_id, markerEnd: MarkerType.ArrowClosed, deletable: true,  style: { stroke: colors.success, strokeWidth: 3}})
        //   }
        //   if(context.action_user_viewed_message_context.usage_id!==""){
        //     listEdge.push({ id: MixUtils.methods.getUUID(),
        //       sourceHandle: context.id+'action_user_viewed_message_context',
        //       source: context.id, target: context.action_user_viewed_message_context.usage_id, markerEnd: MarkerType.ArrowClosed, deletable: true,  style: { stroke: colors.success, strokeWidth: 3}})
        //   }
        //   if(context.action_send_success_context.usage_id!==""){
        //     listEdge.push({ id: MixUtils.methods.getUUID(), source: context.id,
        //       sourceHandle: context.id+'action_send_success_context',
        //       target: context.action_send_success_context.usage_id, markerEnd: MarkerType.ArrowClosed, deletable: true,  style: { stroke: colors.success, strokeWidth: 3} })
        //   }
        //
        //   //Action
        //   context.action_send_success_context.flow_crm_actions.map(mContext=>{
        //     const id = MixUtils.methods.getUUID()
        //     listNode.push(
        //         {
        //           id: id+'action_send_success_context',
        //           type: 'action-crm',
        //           class: 'my-custom-node-class',
        //           position: { x: 650*2, y: 300 },
        //           targetHandle: Position.Top,
        //           sourceHandle: Position.Right,
        //           data: mContext
        //         }
        //     )
        //     listEdge.push({
        //       id: MixUtils.methods.getUUID(),
        //       source: context.id,
        //       sourceHandle: context.id+'action_send_success_context',
        //       target:  id+'action_send_success_context',
        //       markerEnd: MarkerType.ArrowClosed,
        //       deletable: true,
        //       style: { stroke: colors.success, strokeWidth: 3} })
        //   })
        //
        //   //Postback
        //   if(context.message_contexts?.length>0){
        //     context.message_contexts.map(message_context=>{
        //       message_context.buttons.map(mContext=>{
        //         const id = MixUtils.methods.getUUID()
        //
        //         if(mContext.type==='web_url'|| mContext.type==='oa.open.url'){
        //           listNode.push(
        //               {
        //                 id: mContext.id,
        //                 type: 'web',
        //                 class: 'my-custom-node-class',
        //                 position: { x: 650*3*Math.random(), y: 300*3*Math.random() },
        //                 targetHandle: Position.Top,
        //                 sourceHandle: Position.Right,
        //                 data: {...mContext, ...{
        //                     name: mContext.title,
        //                     message_contexts: [{
        //                       id: MixUtils.methods.getUUID(),
        //                       text:mContext.reply_message,
        //                       type: 'text',
        //                     }],
        //                   }}
        //               }
        //           )
        //
        //         }else{
        //           listNode.push(
        //               {
        //                 id: id,
        //                 type: flowType,
        //                 class: 'my-custom-node-class',
        //                 position: { x: 650*3*Math.random(), y: 300*3*Math.random() },
        //                 targetHandle: Position.Top,
        //                 sourceHandle: Position.Right,
        //                 data: {...mContext, ...{
        //                     name: mContext.title,
        //                     message_contexts: [{
        //                       id: MixUtils.methods.getUUID(),
        //                       text:mContext.reply_message,
        //                       type: 'text',
        //                     }],
        //                   }}
        //               }
        //           )
        //
        //         }
        //         if(mContext.type==='web_url'|| mContext.type==='oa.open.url'){
        //           const line = {
        //             id: MixUtils.methods.getUUID(),
        //             source: context.id,
        //             sourceHandle: mContext.id,
        //             target:  mContext.id,
        //             markerEnd: MarkerType.ArrowClosed,
        //             deletable: true,
        //             style: {  strokeWidth: 3} }
        //           listEdge.push(line)
        //         }else{
        //           const line = {
        //             id: MixUtils.methods.getUUID(),
        //             source: context.id,
        //             sourceHandle: mContext.id,
        //             target:  mContext.flow_actions_context.usage_id?mContext.flow_actions_context.usage_id:id,
        //             markerEnd: MarkerType.ArrowClosed,
        //             deletable: true,
        //             style: {  strokeWidth: 3} }
        //           listEdge.push(line)
        //         }
        //
        //       })
        //     })
        //   }
        //
        //
        //
        //   if(context.flow_viral){
        //     context.flow_viral.map(viral=>{
        //       viral.gifts.map(gift=>{
        //         listEdge.push({ id: MixUtils.methods.getUUID(), source: gift.id, target: gift.flow_action_context.usage_id, markerEnd: MarkerType.ArrowClosed, deletable: true,  style: { stroke: colors.success, strokeWidth: 3} })
        //       })
        //     })
        //   }
        //
        // })

        if(flowContexts.length>0){
          listEdge.push(
              { id: MixUtils.methods.getUUID(), source: response.data?.data.id+'', target: flowContexts[0].id+'', markerEnd: MarkerType.ArrowClosed, deletable: true,  style: {   strokeWidth: 3} }
          )
        }
        console.log('listEdge', listEdge)
        console.log('listNode', listNode)
        addNodes(listNode)

        // setTimeout(()=>{
          addEdges(listEdge)
        // }, 1000)
        //



        // if(response.data?.data.cont)
        // this.flowContext = response.data.data
        // if (this.flowContext.context && this.flowContext.context.flow_contexts) {
        //   const hasMail = this.flowContext.context.flow_contexts.find((x) => x.flow_type === 5)
        //   if (hasMail) {
        //     this.formConfig.hasMail = true
        //   }
        //   if (this.flowContext.context.flow_contexts.length > 0 && this.flowContext.context.flow_contexts[0].flow_viral
        //       && this.flowContext.context.flow_contexts[0].flow_viral.length
        //       && this.flowContext.context.flow_contexts[0].flow_type === 6) {
        //     const res = await APIs.init().getTriggerInviteLink({
        //       trigger_id: id,
        //       flow_id: this.flowContext.id,
        //       flow_context_id: this.flowContext.context.flow_contexts[0].id,
        //       flow_message_id: this.flowContext.context.flow_contexts[0].flow_viral[0].id
        //     })
        //     this.formConfig.viralUrl = res.data?.data
        //     QRCode.toDataURL(this.formConfig.viralUrl)
        //         .then(url => {
        //           this.formConfig.viralQRURL = url
        //         })
        //         .catch(err => {
        //           console.error(err)
        //         })
        //   }
        // }
      }
    }



    const connectionLineStyle = { stroke: '#2c3e50', strokeWidth: 4,  }

    const nodeStroke = (n) => {
      if (n.type === 'input') return '#0041d0'
      if (n.type === 'output') return '#ff0072'
      return '#eee'
    }


    // output labels

    const onChange = (color) => {
      gradient.value = false
      bgName.value = color.name

      // findNode('3').hidden = false
    }

    const onGradient = () => {
      gradient.value = true
      bgName.value = 'gradient'
      //
      // findNode('3').hidden = true
    }



    onConnect((params) => {
      console.log(params)
      addEdges([{...params, ...{markerEnd: MarkerType.ArrowClosed, style: {strokeWidth: 3} }}])
    })

    const addTrigger = ()=>{
      trigger.value = {key: 3, value: 'ksjdhfjksdhf'}
      console.log('addTrigger')
    }

    onMounted(()=>{
      fetchFlowContext(route.params.id)
    })

    return{
      currentNode,
      addTrigger,
      trigger,
      connectionLineStyle,
      gradient,
      labelCol: { span: 6 },
      wrapperCol: { span: 16 },

    }
  },
})
</script>

<style>
.container-title{
  display: flex;
  flex-direction: row;
  padding: 16px 24px 16px 24px;
  margin-bottom: 0px!important;
  background-color: white
}
</style>
