import { resolveComponent as _resolveComponent, createVNode as _createVNode, mergeProps as _mergeProps, withCtx as _withCtx, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"
import _imports_0 from '@/assets/icons/zalo-seeklogo.com.svg'
import _imports_1 from '@/assets/icons/facebook-svgrepo-com.svg'
import _imports_2 from '@/assets/icons/instagram-svgrepo-com.svg'
import _imports_3 from '@/assets/icons/line-svgrepo-com.svg'
import _imports_4 from '@/assets/icons/whatsapp-svgrepo-com.svg'


const _withScopeId = n => (_pushScopeId("data-v-5312b1f2"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  class: "main-flow",
  id: "main-flow"
}
const _hoisted_2 = { key: 0 }
const _hoisted_3 = {
  key: 0,
  draggable: "false",
  src: _imports_0,
  style: {"width":"20px","margin-right":"20px"}
}
const _hoisted_4 = {
  key: 1,
  draggable: "false",
  src: _imports_1,
  style: {"width":"20px","margin-right":"20px"}
}
const _hoisted_5 = {
  key: 2,
  draggable: "false",
  src: _imports_2,
  style: {"width":"20px","margin-right":"20px"}
}
const _hoisted_6 = {
  key: 3,
  draggable: "false",
  src: _imports_3,
  style: {"width":"20px","margin-right":"20px"}
}
const _hoisted_7 = {
  key: 4,
  draggable: "false",
  src: _imports_4,
  style: {"width":"20px","margin-right":"20px"}
}
const _hoisted_8 = {
  key: 0,
  draggable: "false",
  src: _imports_0,
  style: {"width":"20px","margin-right":"20px"}
}
const _hoisted_9 = {
  key: 1,
  draggable: "false",
  src: _imports_1,
  style: {"width":"20px","margin-right":"20px"}
}
const _hoisted_10 = {
  key: 2,
  draggable: "false",
  src: _imports_2,
  style: {"width":"20px","margin-right":"20px"}
}
const _hoisted_11 = {
  key: 3,
  draggable: "false",
  src: _imports_3,
  style: {"width":"20px","margin-right":"20px"}
}
const _hoisted_12 = {
  key: 4,
  draggable: "false",
  src: _imports_4,
  style: {"width":"20px","margin-right":"20px"}
}
const _hoisted_13 = { key: 0 }
const _hoisted_14 = {
  key: 0,
  draggable: "false",
  src: _imports_0,
  style: {"width":"20px","margin-right":"20px"}
}
const _hoisted_15 = {
  key: 1,
  draggable: "false",
  src: _imports_1,
  style: {"width":"20px","margin-right":"20px"}
}
const _hoisted_16 = {
  key: 2,
  draggable: "false",
  src: _imports_2,
  style: {"width":"20px","margin-right":"20px"}
}
const _hoisted_17 = {
  key: 3,
  draggable: "false",
  src: _imports_3,
  style: {"width":"20px","margin-right":"20px"}
}
const _hoisted_18 = {
  key: 4,
  draggable: "false",
  src: _imports_4,
  style: {"width":"20px","margin-right":"20px"}
}
const _hoisted_19 = {
  key: 0,
  draggable: "false",
  src: _imports_0,
  style: {"width":"20px","margin-right":"20px"}
}
const _hoisted_20 = {
  key: 1,
  draggable: "false",
  src: _imports_1,
  style: {"width":"20px","margin-right":"20px"}
}
const _hoisted_21 = {
  key: 2,
  draggable: "false",
  src: _imports_2,
  style: {"width":"20px","margin-right":"20px"}
}
const _hoisted_22 = {
  key: 3,
  draggable: "false",
  src: _imports_3,
  style: {"width":"20px","margin-right":"20px"}
}
const _hoisted_23 = {
  key: 4,
  draggable: "false",
  src: _imports_4,
  style: {"width":"20px","margin-right":"20px"}
}
const _hoisted_24 = {
  class: "row-button-action",
  style: {"text-align":"right"}
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_input = _resolveComponent("a-input")!
  const _component_a_form_item = _resolveComponent("a-form-item")!
  const _component_a_radio = _resolveComponent("a-radio")!
  const _component_a_radio_group = _resolveComponent("a-radio-group")!
  const _component_a_col = _resolveComponent("a-col")!
  const _component_plus_outlined = _resolveComponent("plus-outlined")!
  const _component_a_tag = _resolveComponent("a-tag")!
  const _component_a_row = _resolveComponent("a-row")!
  const _component_a_select_option = _resolveComponent("a-select-option")!
  const _component_a_select = _resolveComponent("a-select")!
  const _component_a_tree_select_node = _resolveComponent("a-tree-select-node")!
  const _component_a_tree_select = _resolveComponent("a-tree-select")!
  const _component_a_range_picker = _resolveComponent("a-range-picker")!
  const _component_a_avatar = _resolveComponent("a-avatar")!
  const _component_delete_outlined = _resolveComponent("delete-outlined")!
  const _component_a_timeline_item = _resolveComponent("a-timeline-item")!
  const _component_a_timeline = _resolveComponent("a-timeline")!
  const _component_a_card = _resolveComponent("a-card")!
  const _component_a_divider = _resolveComponent("a-divider")!
  const _component_flow_next_actions = _resolveComponent("flow-next-actions")!
  const _component_a_button = _resolveComponent("a-button")!
  const _component_a_popconfirm = _resolveComponent("a-popconfirm")!
  const _component_a_form = _resolveComponent("a-form")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_a_form, {
      ref: "formRef",
      model: _ctx.formState,
      layout: "vertical"
    }, {
      default: _withCtx(() => [
        _createVNode(_component_a_form_item, _mergeProps({
          ref: "name",
          label: _ctx.$t('FLOW_MESSAGE_NAME'),
          name: "name"
        }, _ctx.validateInfos.name), {
          default: _withCtx(() => [
            _createVNode(_component_a_input, {
              placeholder: _ctx.$t('FLOW_MESSAGE_NAME'),
              onChange: _ctx.onTextChange,
              value: _ctx.formState.name,
              "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.formState.name) = $event))
            }, null, 8, ["placeholder", "onChange", "value"])
          ]),
          _: 1
        }, 16, ["label"]),
        _createVNode(_component_a_card, {
          bodyStyle: {'padding':'10px'},
          class: "card-message"
        }, {
          title: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.$t('TRIGGERS')), 1)
          ]),
          default: _withCtx(() => [
            _createVNode(_component_a_row, null, {
              default: _withCtx(() => [
                _createVNode(_component_a_col, { span: 12 }, {
                  default: _withCtx(() => [
                    _createVNode(_component_a_form_item, {
                      style: {"flex-direction":"row"},
                      name: ['trigger_condition','condition'],
                      rules: {
        required: true,
        message: 'Please select one condition type',
        trigger: 'change',
      },
                      label: _ctx.$t('TRIGGER_CONDITIONS'),
                      "label-col": {span: 6,offset:0},
                      "wrapper-col": { span: 12 }
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_a_radio_group, {
                          onChange: _ctx.onSubmit,
                          value: _ctx.formState.trigger_condition.condition,
                          "onUpdate:value": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.formState.trigger_condition.condition) = $event))
                        }, {
                          default: _withCtx(() => [
                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.getFlowConstants.trigger_condition.trigger_conditions, (item) => {
                              return (_openBlock(), _createBlock(_component_a_radio, {
                                style: {"align-items":"normal"},
                                key: item,
                                value: item
                              }, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(_ctx.$t(item)), 1)
                                ]),
                                _: 2
                              }, 1032, ["value"]))
                            }), 128))
                          ]),
                          _: 1
                        }, 8, ["onChange", "value"])
                      ]),
                      _: 1
                    }, 8, ["label"])
                  ]),
                  _: 1
                }),
                _createVNode(_component_a_col, { span: 12 }, {
                  default: _withCtx(() => [
                    _createVNode(_component_a_row, { style: {"width":"100%","justify-content":"flex-end"} }, {
                      default: _withCtx(() => [
                        _createVNode(_component_a_tag, {
                          style: {"margin":"10px","cursor":"pointer"},
                          type: "dashed",
                          block: "",
                          onClick: _ctx.addParentCondition
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_plus_outlined),
                            _createTextVNode(" " + _toDisplayString(_ctx.$t("TRIGGER_CONDITIONS")), 1)
                          ]),
                          _: 1
                        }, 8, ["onClick"]),
                        _createVNode(_component_a_tag, {
                          onClick: _ctx.addGroupCondition,
                          style: {"margin":"10px","cursor":"pointer"},
                          type: "dashed"
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_ctx.$t('CONDITION_GROUP')), 1)
                          ]),
                          _: 1
                        }, 8, ["onClick"])
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }),
            (_ctx.formState.trigger_condition.trigger_comparisons&&_ctx.formState.trigger_condition.trigger_comparisons.length)
              ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                  _createVNode(_component_a_timeline, null, {
                    default: _withCtx(() => [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.formState.trigger_condition.trigger_comparisons, (item, index) => {
                        return (_openBlock(), _createBlock(_component_a_timeline_item, {
                          style: {"padding-bottom":"0"},
                          key: item.condition+'_'+index
                        }, {
                          default: _withCtx(() => [
                            (item.condition)
                              ? (_openBlock(), _createBlock(_component_a_row, {
                                  key: 0,
                                  class: "row-full-width"
                                }, {
                                  default: _withCtx(() => [
                                    _createVNode(_component_a_col, {
                                      span: 7,
                                      style: {"padding":"4px"}
                                    }, {
                                      default: _withCtx(() => [
                                        _createVNode(_component_a_select, {
                                          ref_for: true,
                                          ref: "select",
                                          style: {"width":"100%"},
                                          value: item.field_type,
                                          "onUpdate:value": ($event: any) => ((item.field_type) = $event),
                                          placeholder: _ctx.$t('select_condition_field'),
                                          onChange: _ctx.onSubmit
                                        }, {
                                          default: _withCtx(() => [
                                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.getFlowConstants.trigger_condition.trigger_condition_field_types, (it) => {
                                              return (_openBlock(), _createBlock(_component_a_select_option, {
                                                key: it,
                                                value: it
                                              }, {
                                                default: _withCtx(() => [
                                                  _createTextVNode(_toDisplayString(_ctx.$t(it)), 1)
                                                ]),
                                                _: 2
                                              }, 1032, ["value"]))
                                            }), 128)),
                                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.getCustomerConfig, (i) => {
                                              return (_openBlock(), _createBlock(_component_a_select_option, {
                                                key: i.id,
                                                value: i.identified_name
                                              }, {
                                                default: _withCtx(() => [
                                                  _createTextVNode(_toDisplayString(i.name), 1)
                                                ]),
                                                _: 2
                                              }, 1032, ["value"]))
                                            }), 128))
                                          ]),
                                          _: 2
                                        }, 1032, ["value", "onUpdate:value", "placeholder", "onChange"])
                                      ]),
                                      _: 2
                                    }, 1024),
                                    _createVNode(_component_a_col, {
                                      span: 7,
                                      style: {"padding":"4px"}
                                    }, {
                                      default: _withCtx(() => [
                                        _createVNode(_component_a_select, {
                                          ref_for: true,
                                          ref: "select",
                                          style: {"width":"100%"},
                                          value: item.condition,
                                          "onUpdate:value": ($event: any) => ((item.condition) = $event),
                                          placeholder: _ctx.$t('select_comparison_condition'),
                                          onChange: _ctx.onSubmit
                                        }, {
                                          default: _withCtx(() => [
                                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.getCondition(_ctx.getFlowConstants.trigger_condition.trigger_comparison_condition,item.field_type,index), (it) => {
                                              return (_openBlock(), _createBlock(_component_a_select_option, {
                                                key: it+'_2'+index,
                                                value: it
                                              }, {
                                                default: _withCtx(() => [
                                                  _createTextVNode(_toDisplayString(_ctx.$t(it)), 1)
                                                ]),
                                                _: 2
                                              }, 1032, ["value"]))
                                            }), 128))
                                          ]),
                                          _: 2
                                        }, 1032, ["value", "onUpdate:value", "placeholder", "onChange"])
                                      ]),
                                      _: 2
                                    }, 1024),
                                    _createVNode(_component_a_col, {
                                      span: 9,
                                      style: {"padding":"4px"}
                                    }, {
                                      default: _withCtx(() => [
                                        (item.field_type==='tag')
                                          ? (_openBlock(), _createBlock(_component_a_tree_select, {
                                              key: 0,
                                              onChange: _ctx.onSubmit,
                                              "show-search": "",
                                              style: {"width":"100%"},
                                              onSearch: _ctx.searchTag,
                                              filterTreeNode: false,
                                              searchValue: _ctx.searchTagValue,
                                              "onUpdate:searchValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.searchTagValue) = $event)),
                                              value: item.value,
                                              "onUpdate:value": ($event: any) => ((item.value) = $event),
                                              notFoundContent: null,
                                              "dropdown-style": { maxHeight: '400px', overflow: 'auto' },
                                              "allow-clear": "",
                                              placeholder: _ctx.$t('TAG_SELECT')
                                            }, {
                                              default: _withCtx(() => [
                                                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.getTreeTags, (item) => {
                                                  return (_openBlock(), _createBlock(_component_a_tree_select_node, {
                                                    key: item.id,
                                                    value: item.id+''
                                                  }, {
                                                    title: _withCtx(() => [
                                                      _createVNode(_component_a_tag, {
                                                        color: item.color
                                                      }, {
                                                        default: _withCtx(() => [
                                                          _createTextVNode(_toDisplayString(item.name), 1)
                                                        ]),
                                                        _: 2
                                                      }, 1032, ["color"])
                                                    ]),
                                                    default: _withCtx(() => [
                                                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(item.children, (child) => {
                                                        return (_openBlock(), _createBlock(_component_a_tree_select_node, {
                                                          key: child.id,
                                                          value: child.id+''
                                                        }, {
                                                          title: _withCtx(() => [
                                                            _createVNode(_component_a_tag, {
                                                              color: child.color
                                                            }, {
                                                              default: _withCtx(() => [
                                                                _createTextVNode(_toDisplayString(child.name), 1)
                                                              ]),
                                                              _: 2
                                                            }, 1032, ["color"])
                                                          ]),
                                                          _: 2
                                                        }, 1032, ["value"]))
                                                      }), 128))
                                                    ]),
                                                    _: 2
                                                  }, 1032, ["value"]))
                                                }), 128))
                                              ]),
                                              _: 2
                                            }, 1032, ["onChange", "onSearch", "searchValue", "value", "onUpdate:value", "placeholder"]))
                                          : _createCommentVNode("", true),
                                        (item.field_type==='INT' || item.field_type === 'CURRENCY')
                                          ? (_openBlock(), _createBlock(_component_a_input, {
                                              key: 1,
                                              onChange: _ctx.onSubmit,
                                              style: {"width":"100%"},
                                              value: item.value,
                                              "onUpdate:value": ($event: any) => ((item.value) = $event)
                                            }, null, 8, ["onChange", "value", "onUpdate:value"]))
                                          : _createCommentVNode("", true),
                                        (item.field_type==='STRING' )
                                          ? (_openBlock(), _createBlock(_component_a_input, {
                                              key: 2,
                                              onChange: _ctx.onSubmit,
                                              style: {"width":"100%"},
                                              value: item.value,
                                              "onUpdate:value": ($event: any) => ((item.value) = $event)
                                            }, null, 8, ["onChange", "value", "onUpdate:value"]))
                                          : _createCommentVNode("", true),
                                        (item.field_type === 'DATE')
                                          ? (_openBlock(), _createBlock(_component_a_range_picker, {
                                              key: 3,
                                              onChange: _ctx.onSubmit,
                                              style: {"width":"100%"},
                                              format: "DD-MM-YYYY",
                                              valueFormat: 'YYYY-MM-DDTHH:mm:ssZ',
                                              value: item.value,
                                              "onUpdate:value": ($event: any) => ((item.value) = $event)
                                            }, null, 8, ["onChange", "value", "onUpdate:value"]))
                                          : _createCommentVNode("", true),
                                        (item.field_type==='page_id')
                                          ? (_openBlock(), _createBlock(_component_a_select, {
                                              key: 4,
                                              style: {"width":"100%"},
                                              "show-search": "",
                                              "filter-option": _ctx.filterOption2,
                                              placeholder: _ctx.$t('SELECT_PAGE'),
                                              onChange: _ctx.onSubmit,
                                              value: item.value,
                                              "onUpdate:value": ($event: any) => ((item.value) = $event)
                                            }, {
                                              default: _withCtx(() => [
                                                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.getPages, (it) => {
                                                  return (_openBlock(), _createBlock(_component_a_select_option, {
                                                    key: it,
                                                    value: it.page_id+''
                                                  }, {
                                                    default: _withCtx(() => [
                                                      (it.page_type === 2)
                                                        ? (_openBlock(), _createElementBlock("img", _hoisted_3))
                                                        : (it.page_type === 1)
                                                          ? (_openBlock(), _createElementBlock("img", _hoisted_4))
                                                          : (it.page_type === 3)
                                                            ? (_openBlock(), _createElementBlock("img", _hoisted_5))
                                                            : (it.page_type === 4)
                                                              ? (_openBlock(), _createElementBlock("img", _hoisted_6))
                                                              : (it.page_type === 6)
                                                                ? (_openBlock(), _createElementBlock("img", _hoisted_7))
                                                                : _createCommentVNode("", true),
                                                      _createTextVNode(_toDisplayString(it.page_name), 1)
                                                    ]),
                                                    _: 2
                                                  }, 1032, ["value"]))
                                                }), 128))
                                              ]),
                                              _: 2
                                            }, 1032, ["filter-option", "placeholder", "onChange", "value", "onUpdate:value"]))
                                          : _createCommentVNode("", true),
                                        (item.field_type==='messenger_window_24h')
                                          ? (_openBlock(), _createBlock(_component_a_select, {
                                              key: 5,
                                              style: {"width":"100%"},
                                              placeholder: _ctx.$t('MESSAGE'),
                                              onChange: _ctx.onSubmit,
                                              value: item.value,
                                              "onUpdate:value": ($event: any) => ((item.value) = $event)
                                            }, {
                                              default: _withCtx(() => [
                                                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.getFlowConstants.trigger_condition_messenger_window_24_h_field_types, (it) => {
                                                  return (_openBlock(), _createBlock(_component_a_select_option, {
                                                    key: it,
                                                    value: "it"
                                                  }, {
                                                    default: _withCtx(() => [
                                                      _createTextVNode(_toDisplayString(it), 1)
                                                    ]),
                                                    _: 2
                                                  }, 1024))
                                                }), 128))
                                              ]),
                                              _: 2
                                            }, 1032, ["placeholder", "onChange", "value", "onUpdate:value"]))
                                          : _createCommentVNode("", true),
                                        (item.field_type==='conversation_tag')
                                          ? (_openBlock(), _createBlock(_component_a_tree_select, {
                                              key: 6,
                                              "show-search": "",
                                              style: {"width":"100%"},
                                              value: item.value1,
                                              "onUpdate:value": ($event: any) => ((item.value1) = $event),
                                              filterTreeNode: false,
                                              onSearch: _ctx.searchTags,
                                              onChange: _ctx.onSubmit,
                                              notFoundContent: null,
                                              "dropdown-style": { maxHeight: '300', overflow: 'auto' },
                                              "allow-clear": "",
                                              multiple: "",
                                              placeholder: _ctx.$t('TAG_SELECT')
                                            }, {
                                              default: _withCtx(() => [
                                                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.getConverstionTag, (converstion) => {
                                                  return (_openBlock(), _createBlock(_component_a_tree_select_node, {
                                                    disabled: "",
                                                    key: converstion.page_id,
                                                    value: converstion.page_id
                                                  }, {
                                                    title: _withCtx(() => [
                                                      _createVNode(_component_a_tag, {
                                                        color: converstion.color
                                                      }, {
                                                        default: _withCtx(() => [
                                                          (converstion.page_type === 2)
                                                            ? (_openBlock(), _createElementBlock("img", _hoisted_8))
                                                            : (converstion.page_type === 1)
                                                              ? (_openBlock(), _createElementBlock("img", _hoisted_9))
                                                              : (converstion.page_type === 3)
                                                                ? (_openBlock(), _createElementBlock("img", _hoisted_10))
                                                                : (converstion.page_type === 4)
                                                                  ? (_openBlock(), _createElementBlock("img", _hoisted_11))
                                                                  : (converstion.page_type === 6)
                                                                    ? (_openBlock(), _createElementBlock("img", _hoisted_12))
                                                                    : _createCommentVNode("", true),
                                                          _createTextVNode(" " + _toDisplayString(converstion.page_name), 1)
                                                        ]),
                                                        _: 2
                                                      }, 1032, ["color"])
                                                    ]),
                                                    default: _withCtx(() => [
                                                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(converstion.tags, (treeData) => {
                                                        return (_openBlock(), _createBlock(_component_a_tree_select_node, {
                                                          disabled: item.coverstion_tag?_ctx.getConvertionTangId(item.coverstion_tag,converstion.page_id).includes(treeData.tag_id)  ? true :false:false ,
                                                          key: converstion.page_id + '_'+treeData.tag_id,
                                                          value: converstion.page_id + '_'+treeData.tag_id
                                                        }, {
                                                          title: _withCtx(() => [
                                                            _createVNode(_component_a_tag, {
                                                              color: treeData.tag_color
                                                            }, {
                                                              default: _withCtx(() => [
                                                                _createTextVNode(_toDisplayString(treeData.tag_name), 1)
                                                              ]),
                                                              _: 2
                                                            }, 1032, ["color"])
                                                          ]),
                                                          _: 2
                                                        }, 1032, ["disabled", "value"]))
                                                      }), 128))
                                                    ]),
                                                    _: 2
                                                  }, 1032, ["value"]))
                                                }), 128))
                                              ]),
                                              _: 2
                                            }, 1032, ["value", "onUpdate:value", "onSearch", "onChange", "placeholder"]))
                                          : _createCommentVNode("", true),
                                        (item.field_type==='post_id')
                                          ? (_openBlock(), _createBlock(_component_a_select, {
                                              key: 7,
                                              style: {"width":"100%"},
                                              placeholder: "Select post",
                                              "show-search": "",
                                              onChange: _ctx.onSubmit,
                                              onSearch: _ctx.searchPost,
                                              "default-active-first-option": false,
                                              "show-arrow": false,
                                              "filter-option": false,
                                              "not-found-content": null,
                                              value: item.value,
                                              "onUpdate:value": ($event: any) => ((item.value) = $event)
                                            }, {
                                              default: _withCtx(() => [
                                                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.posts, (post) => {
                                                  return (_openBlock(), _createBlock(_component_a_select_option, {
                                                    key: post.id,
                                                    value: post.id
                                                  }, {
                                                    default: _withCtx(() => [
                                                      _createVNode(_component_a_avatar, {
                                                        shape: "square",
                                                        src: _ctx.getPostPicture(post)
                                                      }, null, 8, ["src"]),
                                                      _createTextVNode(" " + _toDisplayString(_ctx.getPostName(post)), 1)
                                                    ]),
                                                    _: 2
                                                  }, 1032, ["value"]))
                                                }), 128))
                                              ]),
                                              _: 2
                                            }, 1032, ["onChange", "onSearch", "value", "onUpdate:value"]))
                                          : _createCommentVNode("", true),
                                        (item.field_type==='care_status')
                                          ? (_openBlock(), _createBlock(_component_a_select, {
                                              key: 8,
                                              style: {"width":"100%"},
                                              placeholder: "Select status",
                                              "show-search": "",
                                              onChange: _ctx.onSubmit,
                                              "default-active-first-option": false,
                                              "show-arrow": false,
                                              "filter-option": false,
                                              "not-found-content": null,
                                              value: item.value,
                                              "onUpdate:value": ($event: any) => ((item.value) = $event)
                                            }, {
                                              default: _withCtx(() => [
                                                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.tags, (tag) => {
                                                  return (_openBlock(), _createBlock(_component_a_select_option, {
                                                    key: tag.id,
                                                    value: tag.id+''
                                                  }, {
                                                    default: _withCtx(() => [
                                                      _createVNode(_component_a_tag, {
                                                        color: tag.color
                                                      }, {
                                                        default: _withCtx(() => [
                                                          _createTextVNode(_toDisplayString(tag.name), 1)
                                                        ]),
                                                        _: 2
                                                      }, 1032, ["color"])
                                                    ]),
                                                    _: 2
                                                  }, 1032, ["value"]))
                                                }), 128))
                                              ]),
                                              _: 2
                                            }, 1032, ["onChange", "value", "onUpdate:value"]))
                                          : _createCommentVNode("", true),
                                        (item.field_type==='order_status')
                                          ? (_openBlock(), _createBlock(_component_a_select, {
                                              key: 9,
                                              style: {"width":"100%"},
                                              placeholder: "Select status",
                                              "show-search": "",
                                              "default-active-first-option": false,
                                              "show-arrow": false,
                                              onChange: _ctx.onSubmit,
                                              "filter-option": false,
                                              "not-found-content": null,
                                              value: item.value,
                                              "onUpdate:value": ($event: any) => ((item.value) = $event)
                                            }, {
                                              default: _withCtx(() => [
                                                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.orderStatus, (i) => {
                                                  return (_openBlock(), _createBlock(_component_a_select_option, {
                                                    key: i.id,
                                                    value: i.id+''
                                                  }, {
                                                    default: _withCtx(() => [
                                                      _createVNode(_component_a_tag, {
                                                        color: _ctx.getOrderStatusColor(i.id)
                                                      }, {
                                                        default: _withCtx(() => [
                                                          _createTextVNode(_toDisplayString(_ctx.$t(i.name)), 1)
                                                        ]),
                                                        _: 2
                                                      }, 1032, ["color"])
                                                    ]),
                                                    _: 2
                                                  }, 1032, ["value"]))
                                                }), 128))
                                              ]),
                                              _: 2
                                            }, 1032, ["onChange", "value", "onUpdate:value"]))
                                          : _createCommentVNode("", true),
                                        (item.field_type==='last_contact_time')
                                          ? (_openBlock(), _createBlock(_component_a_range_picker, {
                                              key: 10,
                                              onChange: _ctx.onSubmit,
                                              value: item.value1,
                                              "onUpdate:value": ($event: any) => ((item.value1) = $event),
                                              format: "DD-MM-YYYY",
                                              valueFormat: 'YYYY-MM-DDTHH:mm:ssZ'
                                            }, null, 8, ["onChange", "value", "onUpdate:value"]))
                                          : _createCommentVNode("", true),
                                        (item.field_type==='first_contact_time')
                                          ? (_openBlock(), _createBlock(_component_a_range_picker, {
                                              key: 11,
                                              onChange: _ctx.onSubmit,
                                              value: item.value1,
                                              "onUpdate:value": ($event: any) => ((item.value1) = $event),
                                              format: "DD-MM-YYYY",
                                              valueFormat: 'YYYY-MM-DDTHH:mm:ssZ'
                                            }, null, 8, ["onChange", "value", "onUpdate:value"]))
                                          : _createCommentVNode("", true),
                                        (item.field_type==='timeout')
                                          ? (_openBlock(), _createBlock(_component_a_select, {
                                              key: 12,
                                              style: {"width":"100%"},
                                              "show-search": "",
                                              placeholder: "Select Timeout",
                                              "default-active-first-option": false,
                                              "show-arrow": false,
                                              "not-found-content": null,
                                              "filter-option": _ctx.filterOption,
                                              onChange: _ctx.onSubmit,
                                              value: item.value,
                                              "onUpdate:value": ($event: any) => ((item.value) = $event)
                                            }, {
                                              default: _withCtx(() => [
                                                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.timeoutSelections, (time) => {
                                                  return (_openBlock(), _createBlock(_component_a_select_option, {
                                                    key: time,
                                                    value: time.value
                                                  }, {
                                                    default: _withCtx(() => [
                                                      _createTextVNode(_toDisplayString(time.label), 1)
                                                    ]),
                                                    _: 2
                                                  }, 1032, ["value"]))
                                                }), 128))
                                              ]),
                                              _: 2
                                            }, 1032, ["filter-option", "onChange", "value", "onUpdate:value"]))
                                          : _createCommentVNode("", true),
                                        (item.field_type==='key_word')
                                          ? (_openBlock(), _createBlock(_component_a_select, {
                                              key: 13,
                                              value: item.value1,
                                              "onUpdate:value": ($event: any) => ((item.value1) = $event),
                                              mode: "tags",
                                              style: {"width":"100%"},
                                              placeholder: "You key word",
                                              onChange: _ctx.onSubmit
                                            }, {
                                              default: _withCtx(() => [
                                                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.options, (i) => {
                                                  return (_openBlock(), _createBlock(_component_a_select_option, {
                                                    key: i,
                                                    value: i.value
                                                  }, {
                                                    default: _withCtx(() => [
                                                      _createTextVNode(_toDisplayString(_ctx.$t(i.value)), 1)
                                                    ]),
                                                    _: 2
                                                  }, 1032, ["value"]))
                                                }), 128))
                                              ]),
                                              _: 2
                                            }, 1032, ["value", "onUpdate:value", "onChange"]))
                                          : _createCommentVNode("", true)
                                      ]),
                                      _: 2
                                    }, 1024),
                                    _createVNode(_component_a_col, {
                                      span: 1,
                                      style: {"padding":"4px","text-align":"center"}
                                    }, {
                                      default: _withCtx(() => [
                                        _createVNode(_component_delete_outlined, {
                                          onClick: ($event: any) => (_ctx.deleteCustomerConditionRow(index))
                                        }, null, 8, ["onClick"])
                                      ]),
                                      _: 2
                                    }, 1024)
                                  ]),
                                  _: 2
                                }, 1024))
                              : (_openBlock(), _createBlock(_component_a_card, {
                                  key: 1,
                                  bodyStyle: {'padding':'10px'},
                                  style: {"width":"100%","margin-top":"10px","margin-bottom":"10px"}
                                }, {
                                  default: _withCtx(() => [
                                    _createVNode(_component_a_row, null, {
                                      default: _withCtx(() => [
                                        _createVNode(_component_a_col, { span: 11 }, {
                                          default: _withCtx(() => [
                                            _createVNode(_component_a_form_item, {
                                              style: {"margin-bottom":"10px"},
                                              name: ['trigger_condition','condition'],
                                              rules: {
        required: false,
        message: 'Please select one condition type',
        trigger: 'change',
      },
                                              "label-col": {span: 0,offset:0},
                                              "wrapper-col": { span: 15 }
                                            }, {
                                              default: _withCtx(() => [
                                                _createVNode(_component_a_radio_group, {
                                                  value: item.trigger_condition.condition,
                                                  "onUpdate:value": ($event: any) => ((item.trigger_condition.condition) = $event)
                                                }, {
                                                  default: _withCtx(() => [
                                                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.getFlowConstants.trigger_condition.trigger_conditions, (item) => {
                                                      return (_openBlock(), _createBlock(_component_a_radio, {
                                                        onChange: _ctx.onSubmit,
                                                        key: item,
                                                        value: item
                                                      }, {
                                                        default: _withCtx(() => [
                                                          _createTextVNode(_toDisplayString(_ctx.$t(item)), 1)
                                                        ]),
                                                        _: 2
                                                      }, 1032, ["onChange", "value"]))
                                                    }), 128))
                                                  ]),
                                                  _: 2
                                                }, 1032, ["value", "onUpdate:value"])
                                              ]),
                                              _: 2
                                            }, 1024)
                                          ]),
                                          _: 2
                                        }, 1024),
                                        _createVNode(_component_a_col, { span: 11 }, {
                                          default: _withCtx(() => [
                                            _createVNode(_component_a_row, { style: {"width":"100%","margin-bottom":"10px","justify-content":"flex-end"} }, {
                                              default: _withCtx(() => [
                                                _createVNode(_component_a_tag, {
                                                  style: {"margin-right":"10px","cursor":"pointer"},
                                                  type: "dashed",
                                                  block: "",
                                                  onClick: ($event: any) => (_ctx.addChildrenCondition(index))
                                                }, {
                                                  default: _withCtx(() => [
                                                    _createVNode(_component_plus_outlined),
                                                    _createTextVNode(" " + _toDisplayString(_ctx.$t("TRIGGER_CONDITIONS")), 1)
                                                  ]),
                                                  _: 2
                                                }, 1032, ["onClick"])
                                              ]),
                                              _: 2
                                            }, 1024)
                                          ]),
                                          _: 2
                                        }, 1024),
                                        _createVNode(_component_a_col, { span: 1 }),
                                        _createVNode(_component_a_col, { span: 1 }, {
                                          default: _withCtx(() => [
                                            _createVNode(_component_delete_outlined, {
                                              onClick: ($event: any) => (_ctx.deleteCustomerConditionRow(index))
                                            }, null, 8, ["onClick"])
                                          ]),
                                          _: 2
                                        }, 1024)
                                      ]),
                                      _: 2
                                    }, 1024),
                                    (_ctx.formState.trigger_condition.trigger_comparisons&&_ctx.formState.trigger_condition.trigger_comparisons.length)
                                      ? (_openBlock(), _createElementBlock("div", _hoisted_13, [
                                          _createVNode(_component_a_timeline, { style: {"margin-top":"10px"} }, {
                                            default: _withCtx(() => [
                                              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.formState.trigger_condition.trigger_comparisons[index].trigger_condition.trigger_comparisons, (i, idex) => {
                                                return (_openBlock(), _createBlock(_component_a_timeline_item, {
                                                  style: {"padding-bottom":"0"},
                                                  key: i.condition+'_'+idex
                                                }, {
                                                  default: _withCtx(() => [
                                                    _createVNode(_component_a_row, { class: "row-full-width" }, {
                                                      default: _withCtx(() => [
                                                        _createVNode(_component_a_col, {
                                                          span: 7,
                                                          style: {"padding":"4px"}
                                                        }, {
                                                          default: _withCtx(() => [
                                                            _createVNode(_component_a_select, {
                                                              ref_for: true,
                                                              ref: "select",
                                                              style: {"width":"100%"},
                                                              value: i.field_type,
                                                              "onUpdate:value": ($event: any) => ((i.field_type) = $event),
                                                              onChange: _ctx.onSubmit,
                                                              placeholder: _ctx.$t('select_condition_field')
                                                            }, {
                                                              default: _withCtx(() => [
                                                                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.getFlowConstants.trigger_condition.trigger_condition_field_types, (it) => {
                                                                  return (_openBlock(), _createBlock(_component_a_select_option, {
                                                                    key: it,
                                                                    value: it
                                                                  }, {
                                                                    default: _withCtx(() => [
                                                                      _createTextVNode(_toDisplayString(_ctx.$t(it)), 1)
                                                                    ]),
                                                                    _: 2
                                                                  }, 1032, ["value"]))
                                                                }), 128))
                                                              ]),
                                                              _: 2
                                                            }, 1032, ["value", "onUpdate:value", "onChange", "placeholder"])
                                                          ]),
                                                          _: 2
                                                        }, 1024),
                                                        _createVNode(_component_a_col, {
                                                          span: 7,
                                                          style: {"padding":"4px"}
                                                        }, {
                                                          default: _withCtx(() => [
                                                            _createVNode(_component_a_select, {
                                                              ref_for: true,
                                                              ref: "select",
                                                              style: {"width":"100%"},
                                                              value: i.condition,
                                                              "onUpdate:value": ($event: any) => ((i.condition) = $event),
                                                              onChange: _ctx.onSubmit,
                                                              placeholder: _ctx.$t('select_comparison_condition')
                                                            }, {
                                                              default: _withCtx(() => [
                                                                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.getCondition(_ctx.getFlowConstants.trigger_condition.trigger_comparison_condition,i.field_type,index,idex), (it) => {
                                                                  return (_openBlock(), _createBlock(_component_a_select_option, {
                                                                    key: it+'_2'+index,
                                                                    value: it
                                                                  }, {
                                                                    default: _withCtx(() => [
                                                                      _createTextVNode(_toDisplayString(_ctx.$t(it)), 1)
                                                                    ]),
                                                                    _: 2
                                                                  }, 1032, ["value"]))
                                                                }), 128))
                                                              ]),
                                                              _: 2
                                                            }, 1032, ["value", "onUpdate:value", "onChange", "placeholder"])
                                                          ]),
                                                          _: 2
                                                        }, 1024),
                                                        _createVNode(_component_a_col, {
                                                          span: 9,
                                                          style: {"padding":"4px"}
                                                        }, {
                                                          default: _withCtx(() => [
                                                            (i.field_type==='tag')
                                                              ? (_openBlock(), _createBlock(_component_a_tree_select, {
                                                                  key: 0,
                                                                  onChange: _ctx.onSubmit,
                                                                  style: {"width":"100%"},
                                                                  onSearch: _ctx.searchTag,
                                                                  filterTreeNode: false,
                                                                  searchValue: _ctx.searchTagValue,
                                                                  "onUpdate:searchValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.searchTagValue) = $event)),
                                                                  value: i.value,
                                                                  "onUpdate:value": ($event: any) => ((i.value) = $event),
                                                                  notFoundContent: null,
                                                                  "dropdown-style": { maxHeight: '400px', overflow: 'auto' },
                                                                  "allow-clear": "",
                                                                  placeholder: _ctx.$t('TAG_SELECT')
                                                                }, {
                                                                  default: _withCtx(() => [
                                                                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.getTreeTags, (item) => {
                                                                      return (_openBlock(), _createBlock(_component_a_tree_select_node, {
                                                                        key: item.id,
                                                                        value: item.id+''
                                                                      }, {
                                                                        title: _withCtx(() => [
                                                                          _createVNode(_component_a_tag, {
                                                                            color: item.color
                                                                          }, {
                                                                            default: _withCtx(() => [
                                                                              _createTextVNode(_toDisplayString(item.name), 1)
                                                                            ]),
                                                                            _: 2
                                                                          }, 1032, ["color"])
                                                                        ]),
                                                                        default: _withCtx(() => [
                                                                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(item.children, (child) => {
                                                                            return (_openBlock(), _createBlock(_component_a_tree_select_node, {
                                                                              key: child.id,
                                                                              value: child.id+''
                                                                            }, {
                                                                              title: _withCtx(() => [
                                                                                _createVNode(_component_a_tag, {
                                                                                  color: child.color
                                                                                }, {
                                                                                  default: _withCtx(() => [
                                                                                    _createTextVNode(_toDisplayString(child.name), 1)
                                                                                  ]),
                                                                                  _: 2
                                                                                }, 1032, ["color"])
                                                                              ]),
                                                                              _: 2
                                                                            }, 1032, ["value"]))
                                                                          }), 128))
                                                                        ]),
                                                                        _: 2
                                                                      }, 1032, ["value"]))
                                                                    }), 128))
                                                                  ]),
                                                                  _: 2
                                                                }, 1032, ["onChange", "onSearch", "searchValue", "value", "onUpdate:value", "placeholder"]))
                                                              : _createCommentVNode("", true),
                                                            (i.field_type==='page_id')
                                                              ? (_openBlock(), _createBlock(_component_a_select, {
                                                                  key: 1,
                                                                  style: {"width":"100%"},
                                                                  "show-search": "",
                                                                  onChange: _ctx.onSubmit,
                                                                  "filter-option": _ctx.filterOption2,
                                                                  placeholder: _ctx.$t('SELECT_PAGE'),
                                                                  value: i.value,
                                                                  "onUpdate:value": ($event: any) => ((i.value) = $event)
                                                                }, {
                                                                  default: _withCtx(() => [
                                                                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.getPages, (it) => {
                                                                      return (_openBlock(), _createBlock(_component_a_select_option, {
                                                                        key: it,
                                                                        value: it.page_id+''
                                                                      }, {
                                                                        default: _withCtx(() => [
                                                                          (it.page_type === 2)
                                                                            ? (_openBlock(), _createElementBlock("img", _hoisted_14))
                                                                            : (it.page_type === 1)
                                                                              ? (_openBlock(), _createElementBlock("img", _hoisted_15))
                                                                              : (it.page_type === 3)
                                                                                ? (_openBlock(), _createElementBlock("img", _hoisted_16))
                                                                                : (it.page_type === 4)
                                                                                  ? (_openBlock(), _createElementBlock("img", _hoisted_17))
                                                                                  : (it.page_type === 6)
                                                                                    ? (_openBlock(), _createElementBlock("img", _hoisted_18))
                                                                                    : _createCommentVNode("", true),
                                                                          _createTextVNode(_toDisplayString(it.page_name), 1)
                                                                        ]),
                                                                        _: 2
                                                                      }, 1032, ["value"]))
                                                                    }), 128))
                                                                  ]),
                                                                  _: 2
                                                                }, 1032, ["onChange", "filter-option", "placeholder", "value", "onUpdate:value"]))
                                                              : _createCommentVNode("", true),
                                                            (i.field_type==='messenger_window_24h')
                                                              ? (_openBlock(), _createBlock(_component_a_select, {
                                                                  key: 2,
                                                                  style: {"width":"100%"},
                                                                  placeholder: _ctx.$t('MESSAGE'),
                                                                  onChange: _ctx.onSubmit,
                                                                  value: i.value,
                                                                  "onUpdate:value": ($event: any) => ((i.value) = $event)
                                                                }, {
                                                                  default: _withCtx(() => [
                                                                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.getFlowConstants.trigger_condition_messenger_window_24_h_field_types, (it) => {
                                                                      return (_openBlock(), _createBlock(_component_a_select_option, {
                                                                        key: it,
                                                                        value: "it"
                                                                      }, {
                                                                        default: _withCtx(() => [
                                                                          _createTextVNode(_toDisplayString(it), 1)
                                                                        ]),
                                                                        _: 2
                                                                      }, 1024))
                                                                    }), 128))
                                                                  ]),
                                                                  _: 2
                                                                }, 1032, ["placeholder", "onChange", "value", "onUpdate:value"]))
                                                              : _createCommentVNode("", true),
                                                            (i.field_type==='conversation_tag')
                                                              ? (_openBlock(), _createBlock(_component_a_tree_select, {
                                                                  key: 3,
                                                                  "show-search": "",
                                                                  style: {"width":"100%"},
                                                                  value: i.value1,
                                                                  "onUpdate:value": ($event: any) => ((i.value1) = $event),
                                                                  filterTreeNode: false,
                                                                  onSearch: _ctx.searchTags,
                                                                  notFoundContent: null,
                                                                  "dropdown-style": { maxHeight: '300', overflow: 'auto' },
                                                                  "allow-clear": "",
                                                                  multiple: "",
                                                                  onChange: _ctx.onSubmit,
                                                                  placeholder: _ctx.$t('TAG_SELECT')
                                                                }, {
                                                                  default: _withCtx(() => [
                                                                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.getConverstionTag, (converstion) => {
                                                                      return (_openBlock(), _createBlock(_component_a_tree_select_node, {
                                                                        disabled: "",
                                                                        key: converstion.page_id,
                                                                        value: converstion.page_id
                                                                      }, {
                                                                        title: _withCtx(() => [
                                                                          _createVNode(_component_a_tag, {
                                                                            color: converstion.color
                                                                          }, {
                                                                            default: _withCtx(() => [
                                                                              (converstion.page_type === 2)
                                                                                ? (_openBlock(), _createElementBlock("img", _hoisted_19))
                                                                                : (converstion.page_type === 1)
                                                                                  ? (_openBlock(), _createElementBlock("img", _hoisted_20))
                                                                                  : (converstion.page_type === 3)
                                                                                    ? (_openBlock(), _createElementBlock("img", _hoisted_21))
                                                                                    : (converstion.page_type === 4)
                                                                                      ? (_openBlock(), _createElementBlock("img", _hoisted_22))
                                                                                      : (converstion.page_type === 6)
                                                                                        ? (_openBlock(), _createElementBlock("img", _hoisted_23))
                                                                                        : _createCommentVNode("", true),
                                                                              _createTextVNode(" " + _toDisplayString(converstion.page_name), 1)
                                                                            ]),
                                                                            _: 2
                                                                          }, 1032, ["color"])
                                                                        ]),
                                                                        default: _withCtx(() => [
                                                                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(converstion.tags, (treeData) => {
                                                                            return (_openBlock(), _createBlock(_component_a_tree_select_node, {
                                                                              disabled: i.coverstion_tag?_ctx.getConvertionTangId(i.coverstion_tag,converstion.page_id).includes(treeData.tag_id)  ? true :false:false ,
                                                                              key: converstion.page_id + '_'+treeData.tag_id,
                                                                              value: converstion.page_id + '_'+treeData.tag_id
                                                                            }, {
                                                                              title: _withCtx(() => [
                                                                                _createVNode(_component_a_tag, {
                                                                                  color: treeData.tag_color
                                                                                }, {
                                                                                  default: _withCtx(() => [
                                                                                    _createTextVNode(_toDisplayString(treeData.tag_name), 1)
                                                                                  ]),
                                                                                  _: 2
                                                                                }, 1032, ["color"])
                                                                              ]),
                                                                              _: 2
                                                                            }, 1032, ["disabled", "value"]))
                                                                          }), 128))
                                                                        ]),
                                                                        _: 2
                                                                      }, 1032, ["value"]))
                                                                    }), 128))
                                                                  ]),
                                                                  _: 2
                                                                }, 1032, ["value", "onUpdate:value", "onSearch", "onChange", "placeholder"]))
                                                              : _createCommentVNode("", true),
                                                            (i.field_type==='post_id')
                                                              ? (_openBlock(), _createBlock(_component_a_select, {
                                                                  key: 4,
                                                                  style: {"width":"100%"},
                                                                  placeholder: "Select post",
                                                                  "show-search": "",
                                                                  onChange: _ctx.onSubmit,
                                                                  onSearch: _ctx.searchPost,
                                                                  "default-active-first-option": false,
                                                                  "show-arrow": false,
                                                                  "filter-option": false,
                                                                  "not-found-content": null,
                                                                  value: i.value,
                                                                  "onUpdate:value": ($event: any) => ((i.value) = $event)
                                                                }, {
                                                                  default: _withCtx(() => [
                                                                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.posts, (post) => {
                                                                      return (_openBlock(), _createBlock(_component_a_select_option, {
                                                                        key: post.id,
                                                                        value: post.id
                                                                      }, {
                                                                        default: _withCtx(() => [
                                                                          _createVNode(_component_a_avatar, {
                                                                            shape: "square",
                                                                            src: _ctx.getPostPicture(post)
                                                                          }, null, 8, ["src"]),
                                                                          _createTextVNode(" " + _toDisplayString(_ctx.getPostName(post)), 1)
                                                                        ]),
                                                                        _: 2
                                                                      }, 1032, ["value"]))
                                                                    }), 128))
                                                                  ]),
                                                                  _: 2
                                                                }, 1032, ["onChange", "onSearch", "value", "onUpdate:value"]))
                                                              : _createCommentVNode("", true),
                                                            (i.field_type==='care_status')
                                                              ? (_openBlock(), _createBlock(_component_a_select, {
                                                                  key: 5,
                                                                  style: {"width":"100%"},
                                                                  placeholder: "Select status",
                                                                  "show-search": "",
                                                                  onChange: _ctx.onSubmit,
                                                                  "default-active-first-option": false,
                                                                  "show-arrow": false,
                                                                  "filter-option": false,
                                                                  "not-found-content": null,
                                                                  value: i.value,
                                                                  "onUpdate:value": ($event: any) => ((i.value) = $event)
                                                                }, {
                                                                  default: _withCtx(() => [
                                                                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.tags, (tag) => {
                                                                      return (_openBlock(), _createBlock(_component_a_select_option, {
                                                                        key: tag.id,
                                                                        value: tag.id+''
                                                                      }, {
                                                                        default: _withCtx(() => [
                                                                          _createVNode(_component_a_tag, {
                                                                            color: tag.color
                                                                          }, {
                                                                            default: _withCtx(() => [
                                                                              _createTextVNode(_toDisplayString(tag.name), 1)
                                                                            ]),
                                                                            _: 2
                                                                          }, 1032, ["color"])
                                                                        ]),
                                                                        _: 2
                                                                      }, 1032, ["value"]))
                                                                    }), 128))
                                                                  ]),
                                                                  _: 2
                                                                }, 1032, ["onChange", "value", "onUpdate:value"]))
                                                              : _createCommentVNode("", true),
                                                            (i.field_type==='order_status')
                                                              ? (_openBlock(), _createBlock(_component_a_select, {
                                                                  key: 6,
                                                                  style: {"width":"100%"},
                                                                  placeholder: "Select status",
                                                                  "show-search": "",
                                                                  onChange: _ctx.onSubmit,
                                                                  "default-active-first-option": false,
                                                                  "show-arrow": false,
                                                                  "filter-option": false,
                                                                  "not-found-content": null,
                                                                  value: i.value,
                                                                  "onUpdate:value": ($event: any) => ((i.value) = $event)
                                                                }, {
                                                                  default: _withCtx(() => [
                                                                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.orderStatus, (i) => {
                                                                      return (_openBlock(), _createBlock(_component_a_select_option, {
                                                                        key: i.id,
                                                                        value: i.id+''
                                                                      }, {
                                                                        default: _withCtx(() => [
                                                                          _createVNode(_component_a_tag, {
                                                                            color: _ctx.getOrderStatusColor(i.id)
                                                                          }, {
                                                                            default: _withCtx(() => [
                                                                              _createTextVNode(_toDisplayString(_ctx.$t(i.name)), 1)
                                                                            ]),
                                                                            _: 2
                                                                          }, 1032, ["color"])
                                                                        ]),
                                                                        _: 2
                                                                      }, 1032, ["value"]))
                                                                    }), 128))
                                                                  ]),
                                                                  _: 2
                                                                }, 1032, ["onChange", "value", "onUpdate:value"]))
                                                              : _createCommentVNode("", true),
                                                            (i.field_type==='last_contact_time')
                                                              ? (_openBlock(), _createBlock(_component_a_range_picker, {
                                                                  key: 7,
                                                                  onChange: _ctx.onSubmit,
                                                                  value: i.value1,
                                                                  "onUpdate:value": ($event: any) => ((i.value1) = $event),
                                                                  format: "DD-MM-YYYY",
                                                                  valueFormat: 'YYYY-MM-DDTHH:mm:ssZ'
                                                                }, null, 8, ["onChange", "value", "onUpdate:value"]))
                                                              : _createCommentVNode("", true),
                                                            (i.field_type==='first_contact_time')
                                                              ? (_openBlock(), _createBlock(_component_a_range_picker, {
                                                                  key: 8,
                                                                  onChange: _ctx.onSubmit,
                                                                  value: i.value1,
                                                                  "onUpdate:value": ($event: any) => ((i.value1) = $event),
                                                                  format: "DD-MM-YYYY",
                                                                  valueFormat: 'YYYY-MM-DDTHH:mm:ssZ'
                                                                }, null, 8, ["onChange", "value", "onUpdate:value"]))
                                                              : _createCommentVNode("", true),
                                                            (i.field_type==='timeout')
                                                              ? (_openBlock(), _createBlock(_component_a_select, {
                                                                  key: 9,
                                                                  style: {"width":"100%"},
                                                                  "show-search": "",
                                                                  onChange: _ctx.onSubmit,
                                                                  placeholder: "Select Timeout",
                                                                  "default-active-first-option": false,
                                                                  "show-arrow": false,
                                                                  "not-found-content": null,
                                                                  "filter-option": _ctx.filterOption,
                                                                  value: i.value,
                                                                  "onUpdate:value": ($event: any) => ((i.value) = $event)
                                                                }, {
                                                                  default: _withCtx(() => [
                                                                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.timeoutSelections, (time) => {
                                                                      return (_openBlock(), _createBlock(_component_a_select_option, {
                                                                        key: time,
                                                                        value: time.value
                                                                      }, {
                                                                        default: _withCtx(() => [
                                                                          _createTextVNode(_toDisplayString(time.label), 1)
                                                                        ]),
                                                                        _: 2
                                                                      }, 1032, ["value"]))
                                                                    }), 128))
                                                                  ]),
                                                                  _: 2
                                                                }, 1032, ["onChange", "filter-option", "value", "onUpdate:value"]))
                                                              : _createCommentVNode("", true),
                                                            (i.field_type==='key_word')
                                                              ? (_openBlock(), _createBlock(_component_a_select, {
                                                                  key: 10,
                                                                  value: i.value1,
                                                                  "onUpdate:value": ($event: any) => ((i.value1) = $event),
                                                                  mode: "tags",
                                                                  onChange: _ctx.onSubmit,
                                                                  style: {"width":"100%"},
                                                                  placeholder: "You key word"
                                                                }, {
                                                                  default: _withCtx(() => [
                                                                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.options2, (i) => {
                                                                      return (_openBlock(), _createBlock(_component_a_select_option, {
                                                                        key: i,
                                                                        value: i.value
                                                                      }, {
                                                                        default: _withCtx(() => [
                                                                          _createTextVNode(_toDisplayString(_ctx.$t(i.value)), 1)
                                                                        ]),
                                                                        _: 2
                                                                      }, 1032, ["value"]))
                                                                    }), 128))
                                                                  ]),
                                                                  _: 2
                                                                }, 1032, ["value", "onUpdate:value", "onChange"]))
                                                              : _createCommentVNode("", true)
                                                          ]),
                                                          _: 2
                                                        }, 1024),
                                                        _createVNode(_component_a_col, {
                                                          span: 1,
                                                          style: {"padding":"4px","text-align":"center"}
                                                        }, {
                                                          default: _withCtx(() => [
                                                            _createVNode(_component_delete_outlined, {
                                                              onClick: ($event: any) => (_ctx.deleteCustomerConditionChidrenRow(idex,index))
                                                            }, null, 8, ["onClick"])
                                                          ]),
                                                          _: 2
                                                        }, 1024)
                                                      ]),
                                                      _: 2
                                                    }, 1024)
                                                  ]),
                                                  _: 2
                                                }, 1024))
                                              }), 128))
                                            ]),
                                            _: 2
                                          }, 1024)
                                        ]))
                                      : _createCommentVNode("", true)
                                  ]),
                                  _: 2
                                }, 1024))
                          ]),
                          _: 2
                        }, 1024))
                      }), 128))
                    ]),
                    _: 1
                  })
                ]))
              : _createCommentVNode("", true)
          ]),
          _: 1
        }),
        _createVNode(_component_a_divider),
        (!_ctx.isShow)
          ? (_openBlock(), _createBlock(_component_flow_next_actions, {
              key: 0,
              buttonName: _ctx.$t('FLOW_SUCCESS_DELIVERY'),
              updateAction: _ctx.updateSuccessAction,
              flowContextData: _ctx.flowContextData,
              flowContext: _ctx.flowContext,
              actionContext: _ctx.flowContext.action_send_success_context
            }, null, 8, ["buttonName", "updateAction", "flowContextData", "flowContext", "actionContext"]))
          : _createCommentVNode("", true),
        (!_ctx.isShow)
          ? (_openBlock(), _createBlock(_component_flow_next_actions, {
              key: 1,
              buttonName: _ctx.$t('FLOW_FAILED_DELIVERY'),
              updateAction: _ctx.updateFailedAction,
              flowContextData: _ctx.flowContextData,
              flowContext: _ctx.flowContext,
              actionContext: _ctx.flowContext.action_send_failed_context
            }, null, 8, ["buttonName", "updateAction", "flowContextData", "flowContext", "actionContext"]))
          : _createCommentVNode("", true),
        (!_ctx.isShow)
          ? (_openBlock(), _createBlock(_component_flow_next_actions, {
              key: 2,
              buttonName: _ctx.$t('FLOW_DEFAULT_DELIVERY'),
              updateAction: _ctx.updateDefaultAction,
              flowContextData: _ctx.flowContextData,
              flowContext: _ctx.flowContext,
              actionContext: _ctx.flowContext.action_default_context
            }, null, 8, ["buttonName", "updateAction", "flowContextData", "flowContext", "actionContext"]))
          : _createCommentVNode("", true),
        _createElementVNode("div", _hoisted_24, [
          _createVNode(_component_a_popconfirm, {
            onConfirm: _ctx.deleteCurrentFlowContext,
            title: _ctx.$t('FLOW_CONFIRM_DELETE_CONTEXT'),
            "ok-text": _ctx.$t('YES'),
            "cancel-text": _ctx.$t('NO')
          }, {
            default: _withCtx(() => [
              _createVNode(_component_a_button, {
                danger: "",
                type: "primary"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_delete_outlined),
                  _createTextVNode(" " + _toDisplayString(_ctx.$t("FLOW_DELETE")), 1)
                ]),
                _: 1
              })
            ]),
            _: 1
          }, 8, ["onConfirm", "title", "ok-text", "cancel-text"])
        ])
      ]),
      _: 1
    }, 8, ["model"])
  ]))
}