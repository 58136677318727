import {ActionContext, ActionTree} from "vuex";
import {MemberRankData, State} from "./states";
import {RootState} from "@/store";
import APIs from "@/APIs/APIs";
import {Mutations} from "@/store/modules/memberRank/mutations";

import {MemberRankActionTypes} from "@/store/modules/memberRank/action-types";
import {MemberRankResponse, MemberRanksResponse} from "@/APIs/response/S_HttpResponse";
import {MemberRankRequest} from "@/APIs/request/MemberRankRequest";
import {MemberRankMutationTypes} from "@/store/modules/memberRank/mutation-types";


type AugmentedActionContext = {
    commit<K extends keyof Mutations>(
        key: K,
        payload: Parameters<Mutations[K]>[1],
    ): ReturnType<Mutations[K]>;
} & Omit<ActionContext<State, RootState>, 'commit'>

export interface Actions {
    [MemberRankActionTypes.FETCH_MEMBER_RANK](
        {commit}: AugmentedActionContext,
        payload?: MemberRankRequest
    ): Promise<MemberRanksResponse | undefined>,
}

export const actions: ActionTree<State, RootState> & Actions = {
    async [MemberRankActionTypes.FETCH_MEMBER_RANK]({commit}): Promise<MemberRanksResponse | undefined> {
        // console.log(payload);
        const response = await APIs.init().getMemberRank()
        if (response.status === 200) {
            const membersRank: MemberRankData = {
                memberRank: response.data?.data,
                metadata: response.data?.meta_data
            }
            commit(MemberRankMutationTypes.SET_MEMBER_RANK, membersRank)
        }
        return response.data;
    },
}
