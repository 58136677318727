<template>
  <div class="container-title">
    <h3 class="title-header">{{ $t($route.name) }}</h3>
    <div class="input-search-customer">

    </div>
  </div>

  <div  class="container">
    <a-card style="border-radius: 10px;margin: auto;width: 95%" >
      <template #title>
        <a-row justify="space-between">
          <a-button  size="small" type="primary">
            <router-link to="/member-rank/:id">
              {{$t('CREATE')}}
            </router-link>
          </a-button>

        </a-row>
      </template>
      <a-table :loading="!loading" style="width: 100%;padding: 0"  :columns="columns"  :data-source="getMembersRank.memberRank"  >
        <template #schedule_time="{record}">
        <a-row> {{`${$t('CREATED')}: ${getday(record.created_at)}`}}</a-row>
          <a-row> {{`${$t('UPDATED')}: ${getday(record.updated_at)}`}}</a-row>
        </template>
        <template #accumulated_money="{record}">
          {{
           getMoney(record.accumulated_money)
          }} VNĐ
        </template>
        <template #accumulate_points="{record}">
          {{record.accumulate_points}} %
        </template>
        <template #status="{record}">
          <a-tag :color="getStatus(record.status).color">
            {{getStatus(record.status).name}}
          </a-tag>
        </template>
        <template #action="{record}">
          <a-dropdown>
            <a-button type="text">
              <eye-outlined />
            </a-button>
            <template #overlay>
              <a-menu>
                <a-menu-item>
                  <router-link :to="`/member-rank/${record.id}`">
                    <edit-outlined/>
                    {{$t('EDIT')}}
                  </router-link>

                </a-menu-item>
<!--                <a-menu-item>-->
<!--                  <a-popconfirm-->
<!--                      :title="$t('DELETE_VOUCHER')"-->
<!--                      :ok-text="$t('YES')"-->
<!--                      :cancel-text="$t('NO')"-->
<!--                      @confirm="deleteFlow(record.id)"-->
<!--                  >-->
<!--                    <delete-outlined/>-->
<!--                    {{$t('DELETE')}}-->
<!--                  </a-popconfirm>-->

<!--                </a-menu-item>-->
                <a-menu-item
                    v-if="record.status==='active'"
                    @click="stopMemberRankModal(record.id,record.rank_name)" >
                  <stop-outlined/>
                  {{ $t('STOP_MEMBER_RANK') }}
                </a-menu-item>
                <a-menu-item
                    v-if="record.status!=='active'"
                    @click="activateMemberRankModal(record.id,record.rank_name)" >
                  <aim-outlined/>
                  {{ $t('Activate_MEMBER_RANK') }}
                </a-menu-item>
                <!--                <a-menu-item>-->
                <!--                  <RouterLink to="/evoucher-code-list">-->
                <!--                    <file-text-outlined />-->
                <!--                    {{$t('VOUCHER_CODE_LIST')}}-->
                <!--                  </RouterLink>-->
                <!--                </a-menu-item>-->
                <!--                <a-menu-item-->
                <!--                    @click="duplicated(record)">-->
                <!--                  <bar-chart-outlined />-->
                <!--                  {{$t('RESULT')}}-->
                <!--                </a-menu-item>-->
                <!--                <a-menu-item-->
                <!--                    @click="duplicated(record)">-->
                <!--                  <plus-outlined />-->
                <!--                  {{$t('CREATE_TRIGGER')}}-->
                <!--                </a-menu-item>-->
              </a-menu>
            </template>
          </a-dropdown>
        </template>
      </a-table>
    </a-card>
  </div>
</template>
<script lang="ts">
import {computed, onMounted, ref} from "vue";
import {useI18n} from "vue-i18n";
import {useStore} from "vuex";
import {MemberRankActionTypes} from "@/store/modules/memberRank/action-types";
import {EyeOutlined,StopOutlined,AimOutlined,EditOutlined} from "@ant-design/icons-vue";
import dayjs from "dayjs";
import {MixUtils} from "@/utils/Utils";
import APIs from "@/APIs/APIs";
import {message, Modal} from "ant-design-vue";

export default {
  components:{
    EyeOutlined,
    StopOutlined,
    AimOutlined,
    EditOutlined
  },
  setup(){
    const store = useStore()
    const i18t = useI18n()
    const loading = ref(false)
    const columns = [
      {
        title:  i18t.t('RANK_ID'),
        key: 'rank_id',
        dataIndex: 'rank_id',
        slots: {customRender: 'rank_id'},
      },
      {
        title: i18t.t('NAME'),
        key: 'rank_name',
        dataIndex: 'rank_name',
        slots: {customRender: 'rank_name'},
      },
      {
        title: i18t.t('RATE'),
        key: 'accumulate_points',
        dataIndex: 'accumulate_points',
        slots: {customRender: 'accumulate_points'},
      },
      {
        title: i18t.t('Qualifying_Conditions'),
        key: 'accumulated_money',
        dataIndex: 'accumulated_money',
        slots: {customRender: 'accumulated_money'},
      },
      // {
      //   title: i18t.t('Periodic_rank_review'),
      //   key: 'periodic_rank_review',
      //   dataIndex: 'periodic_rank_review',
      //   slots: {customRender: 'periodic_rank_review'},
      // },
      {
        title: i18t.t('SCHEDULE_TIME'),
        dataIndex: 'schedule_time',
        slots: {customRender: 'schedule_time'},
      },
      {
        title: i18t.t('status'),
        key: 'status',
        dataIndex: 'status',
        slots: {customRender: 'status'},
      },
      {
        title: i18t.t('action'),
        dataIndex: 'action',
        slots: {customRender: 'action'},
      },
    ]
    const fetchMemberRankList = () => {
      store.dispatch(MemberRankActionTypes.FETCH_MEMBER_RANK)
      loading.value = true
    }
    const getMembersRank = computed(()=> store.getters.getMemberRankData)
    const showFilter = ref(false)
    const getday = (data:any) => {
      return dayjs(data).format('DD-MM-YYYY HH:mm:ss')
    }
    const getMoney = (money:number)=>{
     return  MixUtils.methods.formatMoney(money)
    }
    const getStatus =(status:string) => {
      if(status === 'coming'){
        return{name: status,color:'#faad14'}
      }else if(status === 'active'){
        return{name: status,color:'#52c41a'}
      }else if(status === 'expired'){
        return{name: status,color:'#6c757d'}
      }else if(status === 'stop'){
        return{name: status,color:'#ff4d4f'}
      }
    }
    const stopMemberRankModal =  (id:string,name:string) => {
      const onOk = () => {
        loading.value =false
        APIs.init().stopMemberRank({id:id,rank_name: name}).then((res:any) => {
          if (res.status === 200) {
            message.success('stop  success')
            fetchMemberRankList()
          }
        }).catch((e:any) => {
          console.error(e)
        })
        console.log('OK');
      }
      Modal.confirm({
        title: i18t.t('MEMBER_RANK_TITLE_CONFIRM_STOP'),
        closable: true,
        maskClosable: true,
        onOk,
        onCancel() {
          console.log('Cancel');
        },
      });
    }
    const activateMemberRankModal = (id:string,name:string)=>{
      loading.value = false
      const onOk = () => {
        APIs.init().activeMemberRank({id:id,rank_name:name}).then((res:any) => {
          if (res.status === 200) {
            message.success('activity success')
            fetchMemberRankList()
          }
        }).catch((e:any) => {
          console.error(e)
        })
        console.log('OK');
      }
      Modal.confirm({
        title: i18t.t('MEMBER_RANK_TITLE_CONFIRM_ACTIVITY'),
        closable: true,
        maskClosable: true,
        onOk,
        onCancel() {
          console.log('Cancel');
        },
      });
    }
    onMounted(() => {
      fetchMemberRankList()
    })
    return{
      showFilter,
      columns,
      getMembersRank,
      getStatus,
      getday,
      loading,
      getMoney,
      activateMemberRankModal,
      stopMemberRankModal
    }
  }
}
</script>
