<template>
  <div class="container-title">
    <h3 class="title-header">{{ $t($route.name) }}</h3>
  </div>
  <a-divider style="margin: 0"/>

  <a-tabs v-model:activeKey="activeKey" class="overview-tab" @change="onChange" size="small">

    <a-tab-pane key="1">
      <template #tab>
          <span

          >
            {{ $t('ALL') }}
          </span>
      </template>
    </a-tab-pane>
    <a-tab-pane key="2">
      <template #tab>
          <span

          >
            <img
                src="@/assets/icons/facebook-svgrepo-com.svg"
                class="anticon"
                style="width: 18px; height: 18px"
            />
            Facebook
          </span>
      </template>
    </a-tab-pane>
    <a-tab-pane key="3">
      <template #tab>
          <span

          >
            <img
                src="@/assets/icons/instagram-svgrepo-com.svg"
                class="anticon"
                style="width: 18px; height: 18px"
            />
            Instagram
          </span>
      </template>
    </a-tab-pane>
    <a-tab-pane key="4">
      <template #tab>
          <span
          >
            <img
                src="@/assets/icons/zalo-seeklogo.com.svg"
                class="anticon"
                style="width: 18px; height: 18px"
            />
            Zalo
          </span>
      </template>
    </a-tab-pane>
    <a-tab-pane key="5">
      <template #tab>
          <span
          >
            <img
                src="@/assets/icons/line-svgrepo-com.svg"
                class="anticon"
                style="width: 18px; height: 18px"
            />
            Line
          </span>
      </template>
    </a-tab-pane>
    <template #tabBarExtraContent>
      <span v-if="!overview.getOverViewLoading"  class="tab-bar-extra">
        {{ getoverview.overView.length }}/{{ shopInfo.dataShopInfo.shop_services.page_num }}
      </span>
      <span class="tab-bar-extra">
        <span class="tab-bar-group-icon">
          <img src="@/assets/icons/facebook-svgrepo-com.svg" class="anticon"/>
          <img
              src="@/assets/icons/instagram-svgrepo-com.svg"
              class="anticon"
          />
          <img
              src="@/assets/icons/line-svgrepo-com.svg"
              class="anticon"
          />
          <img
              src="@/assets/icons/zalo-seeklogo.com.svg"
              class="anticon"
          />
           <img
               src="@/assets/icons/whatsapp-svgrepo-com.svg"
               class="anticon fix-icon-menu"
           />
        </span>
        <a-dropdown>
          <template #overlay>
            <a-menu @click="menuConnectionListener">
              <a-menu-item @click="callFb" key="1" class="menu-item" style="justify-content: center">
                <img
                    src="@/assets/icons/facebook-svgrepo-com.svg"
                    class="anticon fix-icon-menu"
                />
                Facebook
              </a-menu-item>
              <a-menu-item key="2">
                <img
                    src="@/assets/icons/instagram-svgrepo-com.svg"
                    class="anticon fix-icon-menu"
                />
                Instagram
              </a-menu-item>
              <a-menu-item key="3">
                <img
                    src="@/assets/icons/zalo-seeklogo.com.svg"
                    class="anticon fix-icon-menu"
                />
                Zalo
              </a-menu-item>
              <a-menu-item key="4">
                <img
                    src="@/assets/icons/line-svgrepo-com.svg"
                    class="anticon fix-icon-menu"
                />
                Line
              </a-menu-item>
                <a-menu-item key="5">
                <img
                    src="@/assets/icons/whatsapp-svgrepo-com.svg"
                    class="anticon fix-icon-menu"
                />
                Whatsapp
              </a-menu-item>
            </a-menu>
          </template>
          <a-button type="primary"><plus-outlined/>{{ $t("CONNECT_PLATFORM") }}</a-button>

        </a-dropdown>
          <a-button style="margin-left: 10px" v-if="this.$route.query.v3" @click="activeV2" type="primary"><plus-outlined/>{{ $t("CONNECT_CONVERSION_API") }}</a-button>
      </span>
    </template>
  </a-tabs>
  <div  >
    <a-alert
        v-if="this.$route.query.v3"
        type="warning"
        closable


    >
      <template #message>
        <a-typography-text   style="color: black" strong> {{'Tính năng Campaign API của facebook giúp tăng hiệu suất tiếp cận khách hàng. Nếu muốn trải nghiệm,bạn vui lòng nhấn   '}}</a-typography-text>

        <a-button @click="activeV2">OK</a-button>
      </template>
    </a-alert>
  </div>

  <div v-if="!overview.getOverViewLoading && getoverview.overView.length===0">
    <a-result status="warning">
      <template #title>
        <span
        >
        {{ $t("DONT_HAVE_ANY_PAGES") }}
        </span
        >
      </template>
      <template #extra>
        <a-dropdown>
          <template #overlay>
            <a-menu @click="menuConnectionListener">
              <a-menu-item key="1">
                <img
                    src="@/assets/icons/facebook-svgrepo-com.svg"
                    class="anticon fix-icon-menu"
                />
                Facebook
              </a-menu-item>
              <a-menu-item key="2">
                <img
                    src="@/assets/icons/instagram-svgrepo-com.svg"
                    class="anticon fix-icon-menu"
                />
                Instagram
              </a-menu-item>
              <a-menu-item key="3">
                <img
                    src="@/assets/icons/zalo-seeklogo.com.svg"
                    class="anticon fix-icon-menu"
                />
                Zalo
              </a-menu-item>
              <a-menu-item key="4">
                <img
                    src="@/assets/icons/line-svgrepo-com.svg"
                    class="anticon fix-icon-menu"
                />
                Line
              </a-menu-item>
            </a-menu>
          </template>
          <a-button type="primary">
            {{ $t("CONNECT_PLATFORM") }}
            <plus-outlined
            />
          </a-button>
        </a-dropdown>
      </template>
    </a-result>
  </div>
  <div class="spin-container" v-if="overview.getOverViewLoading">
    <a-spin/>
  </div>
  <div v-else class="card-container-overview">
    <template v-for="it in listData(getoverview.overView)" :key="it">
      <a-card
          hoverable
          @mouseover="hoverCard = it"
          @mouseleave="0"
          :style="{backgroundColor:it.is_valid?'white':'#f3f6ed'}"

      >
        <template #cover>

       <div>
         <a-tooltip :title="getPageErrorMessage(it.error_code,it.error_message)" trigger="click" color="orange"
                    v-if="it.error_v2">
           <InfoCircleOutlined style="font-size: 20px;margin-top:5px;margin-left:5px;color: #fab216"  />

         </a-tooltip>
         <div style="display: flex; justify-content: center; padding-top: 16px">
           <a-badge>
             <template #count>
               <a-avatar size="small">
                 <template #icon>
                   <img
                       v-if="it.page_type === 2"
                       draggable="false"
                       src="@/assets/icons/zalo-seeklogo.com.svg"
                   />
                   <img
                       v-else-if="it.page_type === 1"
                       draggable="false"
                       src="@/assets/icons/facebook-svgrepo-com.svg"
                   />
                   <img
                       v-else-if="it.page_type === 3"
                       draggable="false"
                       src="@/assets/icons/instagram-svgrepo-com.svg"
                   />
                   <img
                       v-else-if="it.page_type === 4"
                       draggable="false"
                       src="@/assets/icons/line-svgrepo-com.svg"
                   />
                   <img
                       v-else-if="it.page_type === 6"
                       draggable="false"
                       src="@/assets/icons/whatsapp-svgrepo-com.svg"
                   />
                 </template>
               </a-avatar>
             </template>
             <img
                 style="height: 80px; width: 80px; align-self: center; border-radius: 4px; overflow: hidden"
                 alt="avatar"
                 :src="
              it.page_type===1?('https://graph.facebook.com/' + it.page_id + '/picture?type=large')
              :(it.page_type===3?('https://api.shopf1.net/v1/ig/my-picture?access_token='+it.page_token)
              :it.avatar !== null ? it.avatar : 'https://gw.alipayobjects.com/zos/antfincdn/XAosXuNZyF/BiazfanxmamNRoxxVxka.png'
              )

            "
             />
           </a-badge>

         </div>

       </div>
        </template>

        <a-card-meta style="">
          <template #title>
            <div style="white-space: pre-line; min-height:50px; text-align: center">{{ it.page_name }}</div>
          </template>
          <template #description>
            <div style="text-align: center">{{ it.page_id }}</div>
          </template>
          <template #avatar>

          </template>
        </a-card-meta>
        <div class="card-button-start">
          <Transition name="slide-fade">
            <a-button
                style="background-color: #424242; border-width: 0"
                v-if="it.is_valid"
                type="primary" block>
              <a @click="goChat(it)">

                {{ $t("CLICK_TO_START") }}
              </a>
            </a-button>
            <a-tooltip :title="getPageErrorMessage(it.error_code,it.error_message)" trigger="click" color="orange"
                       v-else-if="!it.is_valid && !it.error_v2">
              <a-button
                  style="background-color: var(--primary-color);
                   border-width: 0"
                  type="primary" block>
                <warning-outlined/>
                {{ $t("CLICK_TO_VIEW_ERROR") }}
              </a-button>
            </a-tooltip>

          </Transition>

          <a-popconfirm
              :title="$t('CONFIRM_DELETE_PAGE')"
              :ok-text="$t('OK')"
              :cancel-text="$t('CANCEL')"
              @confirm="confirm(it.page_id)"
          >
            <Transition name="slide-fade">
              <a-button >
                <delete-outlined/>
              </a-button>
            </Transition>
          </a-popconfirm>
        </div>
      </a-card>
    </template>
  </div>
  <a-page-header
      class="site-page-header"
  >
    <div class="content">
      <a-row class="stat-row">
        <div class="stat-row-contain-icon">
          <CommentOutlined class="stat-icon"/>
        </div>
        <a-col>
          <div class="stat-title">{{ $t('CONVERSATIONS') }}</div>
          <div class="stat-value">{{ Conversations }}</div>
        </a-col>
      </a-row>
      <a-row class="stat-row">
        <div class="stat-row-contain-icon">
          <PhoneOutlined class="stat-icon"/>
        </div>
        <a-col>
          <div class="stat-title">{{ $t('PHONE') }}</div>
          <div class="stat-value">{{ phone }}</div>
        </a-col>
      </a-row>
      <a-row class="stat-row">
        <div class="stat-row-contain-icon">
          <ShoppingCartOutlined class="stat-icon"/>
        </div>
        <a-col>
          <div class="stat-title">{{ $t('SALE') }}</div>
          <div class="stat-value">{{ 0 }}</div>
        </a-col>
      </a-row>


      <a-row class="stat-row">
        <div class="stat-row-contain-icon">
          <ShoppingOutlined class="stat-icon"/>
        </div>
        <a-col>
          <div class="stat-title">{{ $t('ORDERS') }}</div>
          <div class="stat-value">{{ 0 }}</div>
        </a-col>
      </a-row>
    </div>
  </a-page-header>

  <a-modal v-model:visible="connectPlatformVisible"
           width="800px"
  >
    <template #title>
      <div style="display: flex; flex-direction: column">
        <span v-if="menuKey ==='1'">
          <img
              src="@/assets/icons/facebook-svgrepo-com.svg"
              class="anticon"
              style="height: 20px; width: 20px"
          />
          {{ $t("FACEBOOK_CONNECTION") }}
        </span>
        <span v-if="menuKey ==='5'">
          <img
              src="@/assets/icons/whatsapp-svgrepo-com.svg"
              class="anticon"
              style="height: 20px; width: 20px"
          />
          {{ $t("WHATSAPP_CONNECTION") }}
        </span>
        <span v-else-if="menuKey==='2'">
          <img
              src="@/assets/icons/instagram-svgrepo-com.svg"
              class="anticon"
              style="height: 20px; width: 20px"
          />
            {{ $t("INSTAGRAM_CONNECTION") }}
        </span>
        <span v-else-if="menuKey==='3'">
          <img
              src="@/assets/icons/zalo-seeklogo.com.svg"
              class="anticon"
              style="height: 20px; width: 20px"
          />
            {{ $t("ZALO_CONNECTION") }}
        </span>
        <span v-if="menuKey==='1'">
          <span class="description-platform-connection">
              {{ $t("CLICK_BUTTON_LINK_FACEBOOK") }}{{ '   ' }}
          </span>
          <a-button
              @click="addPageFacebook"
              type="primary"

          >
            <facebook-outlined/>
             {{ $t("FACEBOOK_EDIT_SETTINGS") }}
          </a-button>
           <a-button
               @click="addPageFacebook2"
               type="primary"
               style="margin-left: 10px"
           >
            <facebook-outlined/>
             {{ $t("FACEBOOK_EDIT_SETTINGS")}} {{$t("BIG79")}}
          </a-button>
        </span>
        <span v-if="menuKey==='5'">
          <span class="description-platform-connection">
              {{ $t("CLICK_BUTTON_LINK_WHATSAPP") }}{{ '   ' }}
          </span>
          <a-button
              @click="addWhatSapp"
              type="primary">
            <phone-outlined/>
             {{ $t("WHATSAPP_ADD") }}
          </a-button>
        </span>
        <span v-else-if="menuKey==='2'">
          <span class="description-platform-connection">
            {{ $t("CLICK_BUTTON_LINK_INSTAGRAM") }}{{ '   ' }}
          </span>
          <a-button
              @click="addPageFacebook"
              type="primary">
            <InstagramOutlined/>
            {{ $t("INSTAGRAM_EDIT_SETTINGS") }}
          </a-button>
           <a-button
               @click="addPageIns2"
               type="primary"
               style="margin-left: 10px"
           >
            <InstagramOutlined/>
             {{ $t("CLICK_BUTTON_LINK_INSTAGRAM")}} {{$t("BIG79")}}
          </a-button>
        </span>
        <span v-else-if="menuKey==='3'">
          <span class="description-platform-connection">
              {{ $t("CLICK_BUTTON_LINK_ZALO") }}{{ '   ' }}
          </span>
          <a-button
              @click="addPageZalo"
              type="primary">
            <zalo-outlined/>
             {{ $t("ZALO_EDIT_SETTINGS") }}
          </a-button>
        </span>
      </div>
    </template>
    <div v-if="dataFB.length===0&&menuKey==='1'">
      <p>
        {{ $t("DONT_HAVE_FACEBOOK_PAGE") }}
      </p>
      <div class="setting-missed-configuration-button">
        <a-button
            @click="addPageFacebook"
            type="primary">
          <facebook-outlined/>
          {{ $t("FACEBOOK_EDIT_SETTINGS") }}
        </a-button>
      </div>
    </div>
    <div v-if="showQr&&menuKey==='5'">

      <a-row style="flex-direction: column;align-items: center" justify="center">
         <span>
      <img
          src="@/assets/icons/whatsapp-svgrepo-com.svg"
          class="anticon"
          style="height: 50px; width: 50px"
      />
         <a-typography-text style="font-size: 50px;margin-left: 10px" strong>{{ 'WhatsApp' }}</a-typography-text>

      </span>

        <div ref="Qrcode" style="width: 350px" class="qr-code">
          <a-row style="flex-direction: column;align-items: center" justify="center">
            <a-spin v-if="indicator" size="large"/>
          </a-row>
        </div>
        <a-row style="flex-direction: column">
          <a-typography-text style="font-size: 20px" strong>Connect to WhatsApp</a-typography-text>
          <a-typography-text style="font-size: 20px">1. Open WhatsApp on your phone</a-typography-text>
          <a-typography-text style="font-size: 20px">2. Select
            <a-typography-text style="color: #25d366" strong>"Menu"</a-typography-text>
            or
            <a-typography-text style="color: #25d366" strong>"Setting"</a-typography-text>
            ,then select
            <a-typography-text style="color: #25d366" strong>"WhatsApp Web"</a-typography-text>
          </a-typography-text>
          <a-typography-text style="font-size: 20px">3.
            <a-typography-text style="color: #25d366" strong>Scan QR code</a-typography-text>
            to login
          </a-typography-text>
        </a-row>
      </a-row>

    </div>
    <div v-else-if="menuKey==='2'&&dataITG.length===0">
      <p>
        {{ $t("DONT_HAVE_INSTAGRAM_PAGES") }}
      </p>
      <div class="setting-missed-configuration-button">
        <a-button
            @click="addPageFacebook"
            type="primary">
          <InstagramOutlined/>
          {{ $t("INSTAGRAM_EDIT_SETTINGS") }}
        </a-button>
      </div>
    </div>
    <div v-else-if="menuKey==='3'&&dataZalo.length===0">

      <p>
        <span>
          <img
              src="@/assets/icons/zalo-seeklogo.com.svg"
              class="anticon"
              style="height: 20px; width: 20px"
          />
        {{ $t("DONT_HAVE_ZALO_PAGE") }}
        </span>
      </p>
      <div class="setting-missed-configuration-button">
        <a-button
            @click="addPageZalo"
            type="primary">
          {{ $t("ZALO_EDIT_SETTINGS") }}
        </a-button>
      </div>
    </div>
    <!--    <div v-else>-->
    <!--      <a-input-search-->
    <!--          placeholder="input search text"-->
    <!--          style="width: 200px"-->
    <!--          v-model:value="searchValue"-->
    <!--          :loading="searchLoading"-->
    <!--          @change="onFbSearch"-->
    <!--      />-->
    <!--    </div>-->
    <div v-if="listPage&&listPage.length">
      <a-list item-layout="horizontal" :data-source="listPage" class="list-page-connection">
        <template #renderItem="{ item }">
          <a-list-item :class="'id' + '-' + item.id">
            <a-list-item-meta>
              <template #description>
                <span>{{ item.page_id }}</span>
              </template>
              <template #title>
                <a>{{ item.page_name }}</a>
              </template>
              <template #avatar>

                <a-avatar
                    v-if="menuKey === '1' || menuKey === '2'"
                    :src="
                  'https://graph.facebook.com/' +
                  item.page_id +
                  '/picture?type=large'
                "
                />
                <a-avatar v-else
                          :src="item.avatar"
                />
              </template>
            </a-list-item-meta>
            <div>
              <a-switch
                  @change="handleChange(item.page_id, e)"
                  v-model:checked="item.isCheck"
              />
            </div>
          </a-list-item>
        </template>
      </a-list>
    </div>
    <div v-if="listPageWhatsApp&&listPageWhatsApp.length&&menuKey === '5'">
      <a-list item-layout="horizontal" :data-source="listPageWhatsApp" class="list-page-connection">
        <template #renderItem="{ item }">
          <a-list-item :class="'id' + '-' + item.id">
            <a-list-item-meta>
              <template #description>
                <span>{{ item.page_id }}</span>
              </template>
              <template #title>
                <a>{{ item.page_name }}</a>
              </template>
              <template #avatar>

                <a-avatar
                    v-if="menuKey === '1' || menuKey === '2'"
                    :src="
                  'https://graph.facebook.com/' +
                  item.page_id +
                  '/picture?type=large'
                "
                />
                <a-avatar v-else
                          :src="item.avatar"
                />
              </template>
            </a-list-item-meta>
            <div>
              <a-switch
                  @change="handleChange(item.page_id, e)"
                  v-model:checked="item.isCheck"
              />
            </div>
          </a-list-item>
        </template>
      </a-list>
    </div>

    <template #footer>
      <a-button @click="CancelFacebook">{{ $t("CANCEL") }}</a-button>
      <a-button type="primary" @click="Save">{{ $t("SAVE") }}</a-button>
    </template>
  </a-modal>
  <a-modal v-model:visible="checkError" title="Nhấn OK vào Edit Setting để kết nối lại trang" @ok="addPageFacebook" @cancel="()=> checkError = false">

    <a-typography-text style="display: block" v-for="i in messageError" :key="i.name"> Trang  tên <a-typography-text strong>"{{i.name}}"</a-typography-text> cần  kết  nối lại do người dùng thay  đổi thông tin đăng nhập.</a-typography-text>
  </a-modal>
</template>

<script>
import {computed, defineComponent, onMounted, ref, reactive, inject, onBeforeMount} from "vue";
import {io, Socket} from "socket.io-client";
import {
  DeleteOutlined,
  PlusOutlined,
  FacebookOutlined,
  InstagramOutlined,InfoCircleOutlined, CommentOutlined, PhoneOutlined, ShoppingCartOutlined, ShoppingOutlined, WarningOutlined
} from "@ant-design/icons-vue";
import moment from "moment";
import {useStore} from "vuex";
import {OverviewActionTypes} from "@/store/modules/OverView/action-types";
import {Overview, OverviewReport} from "@/models/Overview/Overview";
import apisauce from "apisauce";
import APIs from "@/APIs/APIs";
import {store} from "@/store";
import {OverviewResponseReport, ZaloActivePage} from "@/APIs/response/S_HttpResponse";
import {message, notification} from "ant-design-vue";
import {MixUtils, MSocketIo} from "@/utils/Utils";
import router from "@/router";
import {useRoute} from "vue-router";
import {useI18n} from "vue-i18n";
import * as Facebook from 'fb-sdk-v19'
import axios from "axios";
import {UsersMutationTypes} from "@/store/modules/user/mutation-types";
import {UserActionTypes} from "@/store/modules/user/action-types"
import {getters} from "@/store/modules/tag/getters";
import RouterEndpoint from "@/router/RouterEndpoint";
import {TransporterActionTypes} from "@/store/modules/transporter/action-types";
import {ShopinfoActionTypes} from "@/store/modules/Shopinfo/action-types";
import {FlowActionTypes} from "@/store/modules/flow/action-types";
import {TagsActionTypes} from "@/store/modules/tag/action-types";
import {CustomerCaresActionTypes} from "@/store/modules/customerCare/action-types";
import {Modal} from 'ant-design-vue';
import {OverviewMutationtypes} from "../../store/modules/OverView/mutation-types";
import commit from "lodash-es/commit";
import useUserStore from "../../store/User";
import useOverviewStore from "../../store/Overview";
// import useAutoinboxStore from "@/store/Autoinbox";
import useAWS_DO_FileStorageStore from "@/store/AWS_DO_FileStorage";
import useCustomersCareStore from "@/store/CustomersCare";
import useEventStore from "@/store/Event";
import useEmployeeStore from "../../store/Employee";
import useShopInfoStore from "../../store/ShopInfo";


// useAutoinboxStore.getState().getAutoInboxData({
//   page_id: 121212
// })
// useAutoinboxStore.getState().getAutoInboxHistoryData({
//   page_id: 121212
// })

// useAWS_DO_FileStorageStore.getInitialState().getAWS_DO_Files({
//   params: 1
// })

// useCustomersCareStore.getState().getCustomerCares({
//   params: 1
// })
// useCustomersCareStore.getState().getCustomerCares()
// useCustomersCareStore.getState().getCustomerDetailCares()

// useEventStore.getState().getEvent()

// interface MenuObject {
//   key: string;
// }

export default defineComponent({
  components: {
    InfoCircleOutlined,
    PlusOutlined,
    DeleteOutlined,
    FacebookOutlined,
    InstagramOutlined,
    CommentOutlined,
    PhoneOutlined,
    ShoppingCartOutlined,
    ShoppingOutlined,
    WarningOutlined
  },
  created() {
    const $cookies = inject('$cookies');
    $cookies?.set("login", 'login')
    localStorage.setItem("login", 'login')
    this.$watch(
        () => this.$route.query,
        () => {
          if (this.$route.query.access_token && this.$route.query.oaId) {
            this.zaloActivePage(this.$route.query.access_token, this.$route.query.oaId,)
          }
          if (this.$route.query.invite) {
            try {
              $cookies?.set("invite", this.$route.query.invite)
            } catch (e) {
              console.log(e)
            }
          }
        },
        // fetch the data when the view is created and the data is
        // already being observed
        {immediate: true}
    )

    try {
      const inviteCode = $cookies?.get("invite")

      if (inviteCode && inviteCode !== '') {
        this.invite(inviteCode)
      }
    } catch (e) {
      console.log(e)
    }

  },

  setup() {
    const route = useRoute()
   const  overview = useOverviewStore();
    const fetchTransporter = () => {
      store.dispatch(TransporterActionTypes.FETCH_TRANSPORTER);
    };
    const fetchShopInfo = () => {
      store.dispatch(ShopinfoActionTypes.FETCH_SHOPINFO);
    };
    const store = useStore();
    const activeKey = ref("1");
    const allBadge = ref("0");
    const facebookBadge = ref("0");
    const instagramBadge = ref("0");
    const zaloBadge = ref("0");
    const lineBadge = ref("0");
    const searchLoading = ref(false);
    const searchValue = ref('');

    const checkError = ref(false)
    const messageError = ref([])
    const i18n = useI18n()
    const indicator = ref(false)
    const hoverCard = ref(0);
    let menuKey = ref('0')
    const connectPlatformVisible = ref(false);

    const menuConnectionListener = (menu) => {
      menuKey.value = menu.key
      switch (menu.key) {
        case "1":
          // listPage.value = dataFB.value
          searchValue.value = ''
          connectPlatformVisible.value = true;
          break
        case "2":
          // listPage.value = dataITG.value
          searchValue.value = ''
          connectPlatformVisible.value = true;
          break
        case "3":
          fetchDataZalo()
          // listPage.value = dataZalo.value
          searchValue.value = ''
          connectPlatformVisible.value = true;
          break
        case "4":
          window.open(MixUtils.methods.getChatUrl() + '/line', '_blank')
          break
        case "5":
          // listPage.value = dataITG.value
          searchValue.value = ''
          connectPlatformVisible.value = true;
          fetchWhatsAppDisabled()
          break
      }
    };

    const fetchData = (f) => {
      useEmployeeStore.getState().GetEmployee({
        page: 1,
        page_size: 999,
        search: '',
      })
       useOverviewStore.getState().getOverview({force_new: f})
      // store.dispatch(OverviewActionTypes.FETCH_OVERVIEW, {force_new: f});


    };
    const listPageWhatsApp = ref([])
    const fetchWhatsAppDisabled = async () => {
      const res = await APIs.init().getWhatsAppDisabled()
      if (res.data.status === 200) {
        listPageWhatsApp.value = res.data.data
      }
    }
    const fetchStateWhatsApp = async (page_id, page_name) => {

      const res = await APIs.init().stateWhatsApp({page_id: page_id})
      if (res.data.status === 200) {
        if (res.data.data === "DISCONNECTED") {
          message.warn("page " + page_name + " của bạn đã bị DISCONNECTED")
          const res = await APIs.init().deleteOverview({page_id: page_id});
          if (res.status === 201 || res.status === 200) {
            fetchData();

          }
        }
      }
    }
    const dataEmployee = computed(() => store.getters.getEmployees);
    const filterPageHasPermission = (list) => {

      return list.filter( (page,index) => {

        try {
          // if(page.refresh_token && page.page_type === 1){
          //   const response = await APIs.init().fbCheckToken({access_token:page.refresh_token})
          //   if(response.status === 200){
          //     page.error_code = response.data.status
          //     page.error_message = response.data.message
          //     page.error_v2 = true
          //     console.log(page,'page')
          //   }
          //
          // }
          if (page.config_data === undefined) {
            return true
          }

          const config_data = JSON.parse(page.config_data)


          if (config_data.role_user_page) {
            console.log(config_data,'config_data')
            useEmployeeStore.getState().employees.map((item) => {
              console.log(userInfo.value.id === item.cms_user_id , item.is_owner === 1 , !config_data.role_user_page.employee.includes(userInfo.value.id),'config_data')
              if(userInfo.value.id === item.cms_user_id && item.is_owner === 1 && !config_data.role_user_page.employee.includes(userInfo.value.id)){
                config_data.role_user_page.employee.push(userInfo.value.id)
              }

            })

            if (config_data.role_user_page.enabled) {
              console.log(config_data.role_user_page.employee.includes(userInfo.value.id),'role_user_page.enabled')

              return config_data.role_user_page.employee.includes(userInfo.value.id)
            } else {
              return true
            }

          } else {
            return true
          }

        } catch (e) {
          console.log(e)
          return false
        }
      })

    }
    const getoverview = useOverviewStore()
   const loading = computed(() => store.getters.getOverviewLoading)
    const listData =(overView) => {
      switch (activeKey.value) {
        case "2":
          return filterPageHasPermission(overView.filter((item) => item.page_type === 1))
        case "3":
          return filterPageHasPermission(overView.filter((item) => item.page_type === 3))
        case "4":
          return filterPageHasPermission(overView.filter((item) => item.page_type === 2))
        case "5":
          return filterPageHasPermission(overView.filter((item) => item.page_type === 4))
        default:

          return filterPageHasPermission(overView)
      }

    }

    const getChecktoken = async ()=>{
      const res = await APIs.init().getOverView()
      if(res.data.status === 200){
        res.data.data.map((item)=>{

          if(item.error_message){
            checkError.value = true
            messageError.value.push({name:item.page_name,message:item.error_message})
          }
        })
      }

    }
    const userInfo = computed(() => store.getters.getUserInfo);


    const fetchDataFb = () => {
      store.dispatch(OverviewActionTypes.FETCH_OVERVIEW_FB);
    };
    const fetchDataITG = () => {
      store.dispatch(OverviewActionTypes.FETCH_OVERVIEW_ITG);
    };

    const fetchDataZalo = () => {
      store.dispatch(OverviewActionTypes.FETCH_OVERVIEW_ZALO);
    };

    const dataFB = computed(() => {
      return store.getters.getOverviewFB
    });
    const dataZalo = computed(() => store.getters.getOverviewZalo);

    const listPage = computed(() => {
      switch (menuKey.value) {
        case "1": {
          return store.getters.getOverviewFB
        }
        case "2": {
          return store.getters.getOverviewITG
        }
        case "3": {
          return store.getters.getOverviewZalo
        }
        default:
          return []
      }
    });

    const dataITG = computed(() => store.getters.getOverviewITG);

    const checked = ref([]);

    const handleChange = (id) => {
      if (checked.value.includes(id)) {
        checked.value.splice(checked.value.indexOf(id), 1);
      } else {
        checked.value.push(id);
      }
    };
    const onChange = (tab) => {
      activeKey.value = tab
    };
    const checkDcnwhatsApp = ref(false)
    const Save = () => {

      checked.value.map(async (item) => {
        if (menuKey.value === '5') {
          const resb = await APIs.init().stateWhatsApp({page_id: item})
          if (resb.data.status === 200) {
            if (resb.data.data === "DISCONNECTED") {
              message.warn(item + " " + i18n.t('DELETED_ASK_DEVICE_TO_RECONNECT'))
              await addWhatSapp()

              checkDcnwhatsApp.value = true
            } else {
              try {
                let res = undefined;
                res = await APIs.init().addOverview({page_id: item});

                if (res && res.data.status !== 201 && res.data.status !== 200) {

                  message.error(res.data.message)
                } else if (res && res.data.status === 201 || res.data.status === 200) {
                  connectPlatformVisible.value = false

                  setTimeout(() => {
                    fetchData(true);
                    fetchDataFb();
                    fetchDataITG();
                    fetchDataZalo();
                    fetchWhatsAppDisabled()


                  }, 3000)

                  checked.value = []


                }
              } catch (error) {
                console.log(error);
              }
            }
          }
        } else {
          try {
            let res = undefined;

            if (menuKey.value === '1') {

              res = await APIs.init().addOverview({page_id: item});

            } else if (menuKey.value === '2') {
              res = await APIs.init().addOverview({page_id: item});
            } else if (menuKey.value === '3') {
              res = await APIs.init().addOverview({page_id: item})
            } else if (menuKey.value === '5') {
              res = await APIs.init().addOverview({page_id: item});
            }
            if (res && res.data.status !== 201 && res.data.status !== 200) {
              message.error(res.data.message)
            } else if (res && res.data.status === 201 || res.data.status === 200) {
              connectPlatformVisible.value = false

              setTimeout(() => {
                fetchData(true);
                fetchDataFb();
                fetchDataITG();
                fetchDataZalo();
                fetchWhatsAppDisabled()
                if(route.query.done){
                  window.open('https://biglead.live/crm/overview','_parent')

                }
              }, 3000)

              checked.value = []

            }
          } catch (error) {
            console.log(error);
          }
        }

      });
    };


    const CancelFacebook = () => {
      searchValue.value = ''
      connectPlatformVisible.value = false;
    };

    const getPageErrorMessage = (errorCode, message) => {
      if (errorCode === 4001) {
        let mes = message.split(',').map((item) =>  i18n.t(item) ).join(',')
        return i18n.t('MISSING_PERMISSION') + mes
      } else if (errorCode === 4002) {
        return i18n.t('ZALO_LOGIN_REQUIRED') + message
      }
      return message;
    };

    const fetchReport = () => {
      store.dispatch(OverviewActionTypes.FETCH_OVERVIEW_REPORT);
    };
    const dataReport = computed(() => store.getters.getOverviewReport);
    const shopInfo = useShopInfoStore();
    const dataReportAll = ref({...dataReport.value});
    const Conversations = ref(
        dataReportAll.value.aggregations?.current?.sum_conversation?.value
    );

    const phone = ref(
        dataReportAll.value.aggregations?.current_by_phone?.has_phone?.sum_conversation?.value
    );

    const confirm = async (id) => {
      try {

        const res = await APIs.init().deleteOverview({page_id: id});
        if (res.status === 201 || res.status === 200) {

          setTimeout(() => {
            fetchData();
            fetchDataFb()
            fetchDataITG()
            fetchDataZalo()

          }, 3000)
        }
      } catch (error) {
        console.log(error);
      }
      // message.success("Click on Yes");
    };
    const fetchConstants = () => {
      store.dispatch(FlowActionTypes.FETCH_FlowConstants)
    }
    const fetchZalo = (data) => {
      try {
        store.commit(UsersMutationTypes.SET_CODE_VERIFIER, data.code_verifier)
        window.location = `https://oauth.zaloapp.com/v4/oa/permission?app_id=${data.app_id}&redirect_uri=https://biglead.live/crm/overview&code_challenge=${data.code_challenge}&state=${data.state}`

      } catch (e) {
        console.log(e)
      }

    }
    const fetchZaloLogin = async (data) => {

      const res = await APIs.init().ZaloLogin({code: data.code, code_verifier: data.code_verifier})

      if (res.data.status === 200) {

        message.success('login' + i18n.t('success'))
        fetchDataZalo();
        menuKey.value = '3'
        router.replace({name: RouterEndpoint.OVERVIEW.name})
      } else {
        router.replace({name: RouterEndpoint.OVERVIEW.name})
        message.error('Cannot login to zalo because' + " " + res.data.message)


      }
    }
    const addPageZalo = async () => {
      const res = await APIs.init().getZaloConfig()
      if (res.data.status === 200) {
        fetchZalo(res.data?.data)
      }

    }
    const v2 = ref(false)
    const showQr = ref(false)
    const addPageFacebook = async (str) => {
        if (facebookIsLoaded.value) {
          Facebook.login({
            // scope: 'email,publish_pages,pages_messaging,manage_pages,public_profile,read_page_mailboxes,read_insights,pages_messaging_subscriptions,leads_retrieval,business_management,pages_show_list,pages_show_list, instagram_manage_messages, pages_manage_metadata, instagram_manage_comments, instagram_basic,pages_read_engagement,pages_manage_engagement,page_events,',
            scope: 'email, read_insights, pages_show_list, read_page_mailboxes, pages_messaging, pages_messaging_subscriptions, instagram_basic, instagram_manage_comments, leads_retrieval, instagram_manage_messages, page_events, pages_read_engagement, pages_manage_metadata, pages_read_user_content, pages_manage_ads, pages_manage_posts, pages_manage_engagement, public_profile',
            return_scopes: true,
            // config_id:'843213217902969',
            // response_type:"code",
            // override_default_response_type: true
          })
              .then(async (response) => {
                if (response.status === 'connected') {
                  const data = await APIs.init().getTokenLongAccess({
                    "grant_type": "fb_exchange_token",
                    "client_id": "1603243739704688",
                    "client_secret": "ba2acd836174626d2a4819f729f71ffb",
                    "fb_exchange_token": response.authResponse.accessToken
                  });
                  try {

                    const res = await APIs.init().pageUser({
                      access_token: data.data.access_token,
                    });


                    if (res.status === 200 || res.status === 201) {
                      fetchDataITG()
                      fetchDataFb()
                      fetchDataZalo()
                      fetchData();
                       // if(menuKey.value === '1' && str === 'v2'){
                       //   window.open('https://biglead.live/crm/overview?v3=true','_parent')
                       //
                       // }


                      checkError.value = false
                    }
                  } catch (e) {
                    console.log(e)
                  }

                }

              })
        }



    };
    const addPageFacebook2 = async () => {
      Facebook.login({
        // return_scopes: true,
        // config_id:'888305232879014',
        config_id:'519032500459545',
        response_type:"code",
        override_default_response_type: true
      })
          .then(async (response) => {
            console.log(response)
            if (response.status === 'connected') {
             const res = await APIs.init().addPageFBV2({access_token: response.authResponse.code})
              if (res.status === 200 || res.status === 201) {
                fetchDataITG()
                fetchDataFb()
                fetchDataZalo()
                fetchData();
                // console.log(response.authResponse.code,'access_token')
                // window.open('https://biglead.live/crm/overview?done=true','_parent')
              }

            } else {
              // not logged in
            }
          });


    };
    const addPageIns2 = async () => {
      Facebook.login({
        // return_scopes: true,
        // config_id:'888305232879014',
        config_id:'2105086686551658',
        response_type:"code",
        override_default_response_type: true
      })
          .then(async (response) => {
            console.log(response)
            if (response.status === 'connected') {
              const res = await APIs.init().addPageFBV2({access_token: response.authResponse.code})
              if (res.status === 200 || res.status === 201) {
                fetchDataITG()
                fetchDataFb()
                fetchDataZalo()
                fetchData();
                // console.log(response.authResponse.code,'access_token')
                // window.open('https://biglead.live/crm/overview?done=true','_parent')
              }

            } else {
              // not logged in
            }
          });


    };

    const callFb = () => {
      fetchDataFb()
    }

    const addWhatSapp = async () => {
      indicator.value = true
      const res = await APIs.init().whatSappNewClient({shop_id: userInfo.value.current_shop_id})
      if (res.data.status === 200) {
        showQr.value = true
      }

    };
    const onFbSearch = () => {
      listPage.value = dataFB.value.filter(x => MixUtils.methods.removeVietnamese2(x.page_name).includes(searchValue.value) || x.page_id.includes(searchValue.value))
    }
    const socket = io('https://api.shopf1.net', {
      extraHeaders: {
        target: "whatsapp_socket"
      },

      "path": "/socket.io",
      "forceNew": true, "reconnectionAttempts": 3, "timeout": 2000,

    });
    // const qrCode = document.getElementsByClassName("qr-code")
    const Qrcode = ref()
    const qrCodeWhatsApp = ref('')
    onBeforeMount(() => {
      socket.on("connect", () => {
        console.log("connect 111", userInfo.value.current_shop_id)
        socket.emit("subscribe", {shop_id: userInfo.value.current_shop_id})
      })
      socket.on("qr_change", async (data) => {

        const res = await APIs.init().qrCodeWhatsApp({file_name: data})
        if (res.data && Qrcode.value) {
          Qrcode.value.innerHTML = res.data
          if (Qrcode.value.children.length > 0) {
            indicator.value = false
          }
        }


      })
      socket.on("client_destroy", async (data) => {
        const res = await APIs.init().deleteOverview({page_id: data.page_id});
        if (res.status === 201 || res.status === 200) {
          await  fetchData();
          await fetchWhatsAppDisabled()
          message.warn(data.page_id + " " + i18n.t('DELETED_ASK_DEVICE_TO_RECONNECT'))

        }
      })
      socket.on("client_ready", () => {
        fetchData(true)
        if (checkDcnwhatsApp.value) {
          console.log('0k')
        } else {
          fetchWhatsAppDisabled()
        }

        showQr.value = false
      })

    })
    const fetchTags = () => {
      store.dispatch(TagsActionTypes.FETCH_TREE_TAGS, {payload: {type: "customer", search: ''}});

    }
    const goChat = (page) => {
      try {
        window.open(MixUtils.getChatNewUrl() + "/?&user_id=" + useUserStore.getState().userInfo.id + "&token_login=" +  useUserStore.getState().userInfo.token_login + "&token_web=" +  useUserStore.getState().userInfo.token_web + "&shop_id=" + page.shop_id + "&pages=" + page.page_id)
      } catch (e) {
        console.log(e)
      }

    };
    const codeVeriFier = computed(() => store.getters.getCodeVerifier)
    const fetchCareStatus = () => {
      store.dispatch(CustomerCaresActionTypes.FETCH_CUSTOMERS_CARES)
    }
    onMounted(() => {
      fetchData();
      getChecktoken()
      if (route.query.name === 'token') {
        connectPlatformVisible.value = true
        menuKey.value = '1'
        console.log('áas')
      }
      fetchTags()
      fetchCareStatus()
      // listData.value.map((item) => {
      //
      //   if (item.page_type === 6) {
      //     console.log(item, 'item')
      //     fetchStateWhatsApp(item.page_id, item.page_name)
      //   }
      //
      // })




      fetchData();
      fetchDataFb();
      fetchDataITG();
      fetchReport();
      fetchDataZalo();

      try {
        if (route.query.code && route.query.code_challenge) {
          const data = {
            code: route.query.code,
            code_verifier: codeVeriFier.value
          }
          fetchZaloLogin(data)
        }
      } catch (e) {
        console.log(e)
      }
      fetchTransporter()
      fetchShopInfo()

    });

    const zaloActivePage = async (access_token, oaId) => {
      try {
        const res = await APIs.init().zaloActivePage({
          access_token: access_token,
          oaId: oaId
        });
        if (res.status === 200 || res.status === 201) {
          if (res.data?.data === 'success') {
            notification['success']({
              message: i18n.t('NOTIFICATION'),
              description:
                  i18n.t('ZALO_ADD_PAGE_SUCCESS'),
            });
          } else if (res.data?.data === 'existed') {
            notification['warning']({
              message: i18n.t('NOTIFICATION'),
              description:
                  i18n.t('ZALO_ADD_PAGE_EXIST'),
            });
          }
          fetchData();
        }
      } catch (error) {
        console.log(error);
      }
    };
   const activeV2 = () => {
     Modal.info({
       title: 'Nhấn ok để kết nối',
       content: i18n.t('COVERSATION_NOTE'),
       onOk() {
         addPageFacebook2()
       },
     });
   }
    const facebookIsLoaded = ref(false)

    onMounted(() => {
      console.log('LoginEmail', useUserStore.getState().userInfo)
      if (route.query.v3){
        Facebook.load()
            .then(() => {
              Facebook.init({
                appId: '1488109648710714',
              });
              facebookIsLoaded.value = true
              // connectPlatformVisible.value = true
              // menuKey.value = '1'
            });
      }else if (route.query.done){
        Facebook.load()
            .then(() => {
              Facebook.init({
                appId: '1488109648710714',
              });
              facebookIsLoaded.value = true
              connectPlatformVisible.value = true
              menuKey.value = '1'
            });
      }else {
        Facebook.load()
            .then(() => {
              Facebook.init({
                appId: '1603243739704688',
              });
              facebookIsLoaded.value = true
            });
      }


      fetchConstants()
      fetchWhatsAppDisabled()
    })

    const invite = async (code) => {
      try {
        const $cookies = inject('$cookies');
        $cookies?.set("invite", '')
      } catch (e) {
        console.log(e)
      }
      const res = await APIs.init().invite({
        token: code,
      });

      if (res.data.status === 200) {
        await router.replace({
          ...router.currentRoute,
          query: {}
        })
        notification.success({
          duration: 2,
          message: i18n.t('INFO'),
          description: res.data?.message,
        });
      } else {
        notification.error({
          duration: 2,
          message: i18n.t('INFO'),
          description: res.data?.message,
        });
      }

    }


    return {
      activeKey,
      handleChange,
      confirm,
      onChange,
      checked,
      allBadge,
      facebookBadge,
      instagramBadge,
      zaloBadge,
      lineBadge,
      hoverCard,
      connectPlatformVisible,
      menuConnectionListener,
      dataFB,
      dataITG,
      listData,
      Conversations,
      phone,
      Save,
      addPageFacebook2,
      addPageIns2,
      activeV2,
      CancelFacebook,
      goChat,
      userInfo,
      addPageFacebook,
      zaloActivePage,
      onFbSearch,
      searchValue,
      searchLoading,
      listPage,
      menuKey,
      addPageZalo,
      invite,
      getPageErrorMessage,
      dataZalo,
      addWhatSapp,
      showQr,
      qrCodeWhatsApp,
      indicator,
      Qrcode,
      listPageWhatsApp,
      loading,
      shopInfo,
      callFb,
      checkError,
      messageError,
      overview,
      getoverview,
      filterPageHasPermission
    };
  },
});
</script>

<style scoped lang="scss">
.overview-tab {
  background-color: white;
  //border-top-left-radius: 5px;
  //border-top-right-radius: 5px;
  padding-left: 16px;
  padding-bottom: 0;
  padding-left: 14px;

  .active-tab {
    color: #108ee9;
  }

  .span-title-tab {
    padding-right: 15px;
  }

  .tab-bar-extra {
    margin-right: 14px;

    .tab-bar-group-icon {
      margin-right: 10px;

      img {
        width: 22px;
        height: 22px;
        margin-right: 6px;
      }

      .icon-overlay {
        margin-left: -6px;
      }

    }
  }
}

.description-platform-connection {
  font-size: 13px;
  font-weight: normal;
}

.setting-missed-configuration-button {
  display: flex;
  justify-content: center;
}

.fix-icon-menu {
  width: 20px;
  height: 20px;
}

.menu-item {
  display: flex;
  align-items: center;
}

.card-button-start {
  margin-top: 12px;
  display: flex;
  justify-content: center;
}

.svg-qr {
  width: 300px;
}

@media only screen and (min-width: 768px) {
  /* For desktop: */
  .card-container-overview {
    margin-top: 12px;
    margin-left: 12px;
    grid-gap: 12px;
    font-size: 12px;
    grid-template-columns: repeat(auto-fit, 300px);
    display: grid;
  }
}

@media only screen and (max-width: 480px) and (min-width: 320px) {
  .card-container-overview {
    width: 100%;
    margin: 12px;
  }
}

.spin-container {
  text-align: center;
  //background: rgba(0, 0, 0, 0.05);
  border-radius: 4px;
  margin-bottom: 20px;
  padding: 30px 50px;
  margin: 20px 0;
}

.site-page-header {
  /*background-color: white;*/
  /*border-radius: 5px;*/
  margin-top: 20px;

  tr:last-child td {
    padding-bottom: 0;
  }

  .content {
    display: flex;
    gap: 20px
    /*justify-content: space-between;*/
  }

  .ant-statistic-content {
    font-size: 20px;
    line-height: 28px;
  }


}

@media (max-width: 576px) {
  .site-page-header .content {
    display: block;
  }

  .site-page-header .main {
    width: 100%;
    margin-bottom: 12px;
  }

  .site-page-header .extra {
    width: 100%;
    margin-left: 0;
    text-align: left;
  }
}

/*
  Enter and leave animations can use different
  durations and timing functions.
*/
.slide-fade-enter-active {
  transition: all 0.3s ease-out;
}

.slide-fade-leave-active {
  transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-fade-enter-from,
.slide-fade-leave-to {
  transform: translateX(20px);
  opacity: 0;
}

.list-page-connection {
  overflow: scroll;
  max-height: 56vh;
}

.ant-dropdown-menu-title-content {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.title-header {
  font-weight: bold;
  flex: 1;
}

.container-title {
  display: flex;
  flex-direction: row;
  padding: 16px 24px 8px 24px;
  background-color: white;
  margin-bottom: 0;
}

.stat-row {
  padding: 12px;
  background-color: white;
  border-radius: 4px
}

.stat-row-contain-icon {
  padding: 15px;
  background-color: #FAB21610;
  border-radius: 2px;
  margin-right: 10px
}

.stat-icon {
  font-size: 32px;
  color: #FAB216
}

.stat-title {
  color: rgba(0, 0, 0, 0.45);
}

.stat-value {
  font-size: 24px;
  font-weight: bold;
  color: rgba(0, 0, 0, 0.85);
  width: 120px;
}
</style>
