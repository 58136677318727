<template>

  <div class="container-title">
    <h3 class="title-header">{{$t($route.name)}}</h3>
    <a-select
        v-model:value="selectedPage"
        @change="handlePageChange"
        :allowClear="true"
        :dropdownMatchSelectWidth="true"
        style="min-width: 350px; margin-right: 16px"
        :placeholder="$t('ALL')"
    >
      <a-select-option
          v-for="item in getPages.overView"
          :key="item.id"
          v-bind:value="item.page_id"
      >{{ item.page_name }}
      </a-select-option>
    </a-select>
    <a-range-picker
        :allowClear="false"
        v-model:value="rangeTime"
        :show-time="{ format: 'HH:mm' }"
        format="DD/MM/YYYY HH:mm"
        :placeholder="[$t('START_TIME_PLACEHOLDER'), $t('END_TIME_PLACEHOLDER')]"
        @change="onChangeDateTime"
        @ok="confirmDateTime"
    >
      <template #suffixIcon>
        <CalendarOutlined/>
      </template>
    </a-range-picker>

  </div>
  <div style="padding: 16px">

    <a-card class="card_view" style="border-radius: 10px; margin: 4px;height: 800px;overflow:hidden">
      <a-table
          :columns="columns"
          :data-source="report.dataCustomer"
          :pagination="false"
          bordered
          size="middle"
          :scroll="{ y: 600  }"

      >
        <template #customerOld="{record}" >
          {{record.customerOld ? record.customerOld : 0}}
        </template>
        <template #customerNew="{record}" >
          {{record.customerNew ? record.customerNew : 0}}
        </template>
        <template #conversationMessageNew="{record}" >
          {{record.conversationMessageNew ? record.conversationMessageNew : 0}}
        </template>
        <template #order_from_ads_percent="{record}" >
          {{record.order_from_old_customer_percent ? record.order_from_old_customer_percent : 0 }}%
        </template>
        <template #order_not_ads_percent="{record}" >
          {{record.order_from_new_customer_percent ? record.order_from_new_customer_percent : 0}}%
        </template>
      </a-table>
    </a-card>


  </div>
</template>

<script>

import {useStore} from "vuex";
import {ShopinfoActionTypes} from "@/store/modules/Shopinfo/action-types";
import {computed, onMounted, ref} from "vue";
import {useI18n} from "vue-i18n";
import {MixUtils} from "@/utils/Utils";
import {CalendarOutlined} from "@ant-design/icons-vue";
import dayjs from "dayjs";
import {ReportPAGEActionTypes} from "../../../store/modules/ReportPage/action-types";
import useShopInfoStore from "@/store/ShopInfo";
import useReportPageStore from "@/store/ReportPage";
import useOverviewStore from "../../../store/Overview";


useShopInfoStore.getState().getShopinfo();



export default {
  components: {
    CalendarOutlined
  },
  setup() {

    const i18n = useI18n()
    const store = useStore();
    const rangeTime = ref([dayjs().add(-1, 'months').startOf('day'), dayjs().endOf('day')])

    const fetchShopInfo = () => {
      store.dispatch(ShopinfoActionTypes.FETCH_SHOPINFO);
    };
    const fetchCusomer = () => {
      // store.dispatch(ReportPAGEActionTypes.FETCH_CUSTOMER_REPORT,{ shop_id: dataShopInfo?.id,
      //   page_id: selectedPage.value,
      //   from_date: rangeTime.value[0].format('YYYY-MM-DD HH:mm'),
      //   to_date: rangeTime.value[1].format('YYYY-MM-DD HH:mm'),});
        useReportPageStore.getState().getReportCusTomer({
      shop_id: dataShopInfo?.id,
        page_id: selectedPage.value,
        from_date: rangeTime.value[0].format('YYYY-MM-DD HH:mm'),
        to_date: rangeTime.value[1].format('YYYY-MM-DD HH:mm')
    })
    };

const {dataShopInfo} = useShopInfoStore()

    // const dataShopInfo = computed(() => store.getters.getShopinfo);
    const pagesSelection = computed(() => store.getters.getPagesSelection);
    const getPages = useOverviewStore();
    // const customerData =computed(()=> store.getters.getReportCusTomer)
    const report = useReportPageStore()
    // const customerData = dataCustomer
    const selectedPage = ref();

    const columns = [
      {
        title: i18n.t('time_visit'),
        dataIndex: 'time',
        key: 'time',
        width: 150,
        fixed: 'left',
      },
      {
        title: i18n.t('CUSTOMER_INTERACTION'),
        children: [
          {
            title: i18n.t('OLD_CUSTOMER'),
            dataIndex: 'customerOld',
            key: 'customerOld',
            slots: {customRender: "customerOld"},
            width: 100,
          },
          {
            title: i18n.t('NEW_CUSTOMER'),
            dataIndex: 'customerNew',
            key: 'customerNew',
            slots: {customRender: "customerNew"},
            width: 100,
          },
          {
            title: i18n.t('TOTAL_CUSTOMER'),
            dataIndex: 'customers',
            key: 'customers',
            width: 100,
          },{
            title: i18n.t('MESSAGE'),
            dataIndex: 'totalMessage',
            key: 'totalMessage',
            width: 100,
          },{
            title: i18n.t('comment'),
            dataIndex: 'totalComment',
            key: 'totalComment',
            width: 100,
          },{
            title: i18n.t('NEW_MESSAGE_CONVERSATION'),
            dataIndex: 'conversationMessageNew',
            key: 'conversationMessageNew',
            slots: {customRender: "conversationMessageNew"},
            width: 100,
          },
          {
            title: i18n.t('NEW_PHONE'),
            dataIndex: 'newPhones',
            key: 'newPhones',
            slots: {customRender: "conversationMessageNew"},
            width: 100,
          }
        ],
      },
      {
        title: i18n.t('ORDER_SUCCESS'),
        children: [
          // {
          //   title: i18n.t('OLD_CUSTOMER'),
          //   dataIndex: 'customerOld',
          //   key: 'customerOld',
          //   width: 100,
          // },
          // {
          //   title: i18n.t('NEW_CUSTOMER'),
          //   dataIndex: 'customerNew',
          //   key: 'customerNew',
          //   width: 100,
          // },{
          //   title: i18n.t('TOTAL'),
          //   dataIndex: 'customers',
          //   key: 'customers',
          //   width: 100,
          // },
          {
            title: i18n.t('order_from_old_customer'),
            dataIndex: 'order_from_old_customer',
            key: 'order_from_old_customer',
            width: 100,
          },{
            title: i18n.t('order_from_old_customer_percent'),
            dataIndex: 'order_from_old_customer_percent',
            key: 'order_from_old_customer_percent',
            slots: {customRender: "order_from_ads_percent"},
            width: 100,
          },
          {
            title: i18n.t('order_new_customer'),
            dataIndex: 'order_from_new_customer',
            key: 'order_from_new_customer',
            width: 100,
          },{
            title: i18n.t('order_new_customer_percent'),
            dataIndex: 'order_from_new_customer_percent',
            key: 'order_from_new_customer_percent',
            slots: {customRender: "order_not_ads_percent"},
            width: 100,
          },
        ],
      },
    ];






    const splineChart = ref()


    const handleReport = () => {
      // fetchReportComment();
      // fetchReportMessage();
      // fetchReportNewConversation();
    };

    onMounted(() => {

      fetchShopInfo();
      fetchCusomer()

    });

    const formatMoney = (val) => {
      return MixUtils.methods.formatMoney(val)
    }

    const calculatorPercent = (a, total) => {
      return Math.round(a / total * 10000) / 100
    }


    const handlePageChange = (val) => {
      selectedPage.value = val;
      console.log(val)
      fetchCusomer()
    };


    const onChangeDateTime = (value, dateString) => {
      console.log('Selected Time: ', value);
      console.log('Formatted Selected Time: ', dateString);
      if (value[0].format('HH-mm') === value[1].format('HH-mm')) {
        rangeTime.value = [value[0].startOf('day'), value[1].endOf('day')]

      } else {
        rangeTime.value = value
      }
      fetchCusomer()
    };

    const confirmDateTime = (value) => {
      console.log('onOk: ', value);
    };


    return {
      dataShopInfo,
      pagesSelection,
      handlePageChange,
      handleReport,
      selectedPage,
      splineChart,
      formatMoney,
      calculatorPercent,
      getPages,
      onChangeDateTime,
      confirmDateTime,
      rangeTime,
      columns,
      report
    };
  },
};
</script>

<style>
.card_view {
  border-radius: 10px;
  margin: 4px;
  overflow: hidden;
}

.count-elements {
  -webkit-tap-highlight-color: transparent;
  -webkit-text-size-adjust: 100%;
  font-family: Roboto, Helvetica, Arial, sans-serif;
  font-variant: tabular-nums;
  line-height: 1.5;
  font-feature-settings: 'tnum';
  font-size: 14px;
  color: #000;
  box-sizing: border-box;
  height: 76px;
  padding: 10px;
  border-top: 1px solid #e0e0e0;
  border-bottom: 1px solid #e0e0e0;
  border-radius: 10px 0px 0px 10px;
  cursor: pointer;
  box-shadow: 0px 2px 10px rgba(51, 51, 51, 0.1);
  border-left: 10px solid rgb(43, 216, 196);
  background-color: rgba(196, 255, 248, 0.23);
  width: 100%;
}

.title {
  font-family: "Roboto" !important;
  font-style: normal !important;
  font-weight: 700 !important;
  font-size: 12px !important;
  line-height: 22px !important;
  color: #000000 !important;
  margin-left: 0px !important;
  margin-top: 0px !important;
  text-align: left !important;
  width: 100% !important;
}

.count {
  text-align: right;
  font-weight: 100;
  font-family: Montserrat-Light;
  font-size: 25px;
  line-height: 30px;
  color: #263C8F;
  float: right;
}

.highcharts-credits {
  display: none;
}
</style>
