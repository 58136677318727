import {State} from './states'
import {AWS_DO_Model} from "@/models/AWS_DO/AWS_DO";
import {GetterTree} from "vuex";
import {RootState} from "@/store";

export type Getters = {
    getAWS_DO_Files(state: State): AWS_DO_Model[]
}

export const getters: GetterTree<State, RootState> & Getters = {
    getAWS_DO_Files(state: State): AWS_DO_Model[]  {
        return state.awsDoFiles? state.awsDoFiles:[];
    }
}
