<template>
  <div style="margin-top: 10px;width: 95%;margin-bottom: 20px;margin-left: 10px">
   <a-row>
    <a-col :span="2">
      <h3 class="title-header">{{ $t($route.name) }}</h3>
    </a-col>
     <a-col :span="22">
       <a-steps style="width: 100%" :current="current"  >
         <a-step @click="current = 0" :title="$t('common_information')" />
         <a-step   :title="$t('SET_UP_VOUCHER')"
         />
         <a-step :title="$t('TIME_APPLICATION_VOUCHER')"
         />
         <a-step :title="$t('CONFIG_VOUCHER')"
         />
         <a-step :title="$t('PUBLISH_VOUCHER')"
         />
       </a-steps>
     </a-col>


   </a-row>
  </div>

  <div class="container">
    <div v-if="current === 0">
<!--   <a-typography-text style="margin-left: 25px">{{$t('WHAT_IS_PURPOSE_OF_THIS_CAMPAIGN')}}</a-typography-text>-->
   <a-card class="purpose-value">
<!--     <a-row justify="center"  style="margin-bottom: 30px" >-->
<!--       <a-typography-title :level="3">{{$t(purpose.title)}}</a-typography-title>-->
<!--     </a-row>-->
<!--     <a-row justify="center"  style="margin-bottom: 30px">-->
<!--      <a-col :span="8">-->
<!--        <a-typography-text type="secondary">{{$t(purpose.subtitle)}}</a-typography-text>-->
<!--      </a-col>-->
<!--     </a-row>-->
     <a-row justify="center" >
       <a-form
           style="width: 80%"
           name="basic"
           :label-wrap="{span:8}"
           :wrapper-col="{ span: 16 }"
           autocomplete="off"
           labelAlign="left"
           ref="formRef"
           :rules="rules"
           :model="formState"


       >
         <a-form-item
             :label="$t('VOUCHER_NAME')"
             name="voucher_name"
         >
           <a-input @change="changeVoucherName"  v-model:value="formState.voucher_name" :placeholder="$t('ENTER_CAMPAIGN_NAME')"  />
         </a-form-item>
         <a-form-item
             :label="$t('VOUCHER_CODE')"
             name="voucher_code"
         >
           <a-input :disabled="checkEdit ? true :false"  v-model:value="formState.voucher_code" :placeholder="$t('ENTER_CAMPAIGN_NAME')"  />
         </a-form-item>
         <a-form-item
             :label="$t('VOUCHER_DISCRIPTION')"
         >
           <a-textarea v-model:value="formState.description" :placeholder="$t('INPUT_CONTENT')" allow-clear />

         </a-form-item>
       </a-form>

     </a-row>
     <a-row justify="center">
       <a-button @click="createVoucher" style="width: 40%" type="primary">{{$t('SETUP_DISCOUNT_CONDITION')}}</a-button>
     </a-row>
   </a-card>
 </div>
      <div v-if="current === 1">
        <a-card v-if="!formState.purpose " style="border-radius: 10px;margin: auto;width: 50%">
         <a-row  justify="center" style="flex-direction: column;align-items: center">
           <a-button @click="handlePurpose(i.value)" style="width: 50%;margin-bottom: 10px" v-for="(i) in purposes" :key="i" type="dashed">{{$t(i.title)}}</a-button>
         </a-row>


        </a-card>
        <a-card v-else-if="formState.purpose && !formState.voucher_type" style="border-radius: 10px;margin: auto;width: 50%">
          <a-row  justify="center" style="flex-direction: column;align-items: center">
            <a-button @click="handlePromotionType(i.value)" style="width: 50%;margin-bottom: 10px" v-for="(i) in promotionType" :key="i" type="dashed">{{$t(i.title)}}</a-button>
          </a-row>


        </a-card>
        <div v-else-if ="formState.voucher_type">
          <a-row style="justify-content: flex-end">
            <a-button   type="primary" style="margin-right: 10px;margin-bottom: 20px" @click="conditionVoucher">
              <send-outlined/>
              {{ $t("next") }}
            </a-button>
          </a-row>
          <a-row style="justify-content: space-around">
            <a-card style="border-radius: 10px;width: 52%">
              <a-row justify="center">
                <a-typography-text style="margin-bottom: 20px" strong>{{$t('Discount')}}</a-typography-text>
                <a-form :label-col="{ span: 8 }"
                        :wrapper-col="{ span: 18 }"
                        style="width: 100%"
                        labelAlign="left"
                        ref="formRef3"
                        :rules="rules2"
                        :model="formState.condition"
                >
<!--                  <a-form-item-->
<!--                      :label="$t('PROMOTION_TYPE')"-->
<!--                      name="username"-->

<!--                  >-->
<!--&lt;!&ndash;                    <a-radio-group v-model:value="formState.promotionType" >&ndash;&gt;-->
<!--&lt;!&ndash;                      <a-radio v-for="i in promotionType" :key="i.value" :value="i">{{$t(i.title)}}</a-radio>&ndash;&gt;-->

<!--&lt;!&ndash;                    </a-radio-group>&ndash;&gt;-->
<!--                  </a-form-item>-->
                  <a-form-item
                      v-if="formState.voucher_type !=='OTHER_OFFERS'"
                      :label="$t('PAGE_OF_USE')"
                      name="page_application"
                  >
                    <a-select
                        allowClea
                        style="width: 100%"
                        placeholder="Please select"
                       v-model:value="formState.condition.page_application"
                        @change="changePage"
                        mode="tags"
                    >
                      <a-select-option
                          v-for="item in configPage"
                          :key="item.id"
                          :value="item.page_id"
                      >{{ item.page_name }}
                      </a-select-option>
                      <a-select-option
                          value="0"
                      >{{ $t('ALL') }}
                      </a-select-option>
                    </a-select>
                  </a-form-item>
                  <div v-if="formState.voucher_type === 'DISCOUNT_FOR_TOTAL_BILL'">
                    <a-form-item
                        :label="$t('DISCOUNT_VN_%')"
                    >
                      <a-row>
                        <a-col :span="18">
                          <a-input-number style="width: 100%" :min="0" :formatter="value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')" :max="formState.condition.discount.type === 'percent' ? 100 : 20000000000000000" v-model:value="formState.condition.discount.discount" />
                        </a-col>
                        <a-col :span="6">
                          <a-select
                              allowClea
                              style="width: 100%"
                              placeholder="Please select"
                              v-model:value="formState.condition.discount.type"
                              @change="changePage"
                          >
                            <a-select-option
                                v-bind:value="'percent'"
                            >{{ '%' }}
                            </a-select-option>
                            <a-select-option

                                v-bind:value="'vnd'"
                            >{{ 'VNĐ ' }}
                            </a-select-option>
                          </a-select>
                        </a-col>
                      </a-row>

                    </a-form-item>
                    <a-row v-if="formState.condition.discount.type === 'percent'" style="width: 100%">
                      <a-col :span="12">
                        <a-typography-title :level="5">{{$t('MAXIMUM_DISCOUNT_VALUE')}}</a-typography-title>
                        <a-form-item>
                          <a-input-number :formatter="value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')" v-model:value="formState.condition.discount.discount_max">
                            <template #addonAfter>
                              {{'VNĐ'}}
                            </template>
                          </a-input-number>
                        </a-form-item>
                      </a-col>
                      <a-col :span="12">
                        <a-typography-title :level="5">{{$t('ONLY_APPLY_FOR_BILL_FROM')}}</a-typography-title>
                        <a-form-item >
                          <a-input-number :formatter="value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')"  v-model:value="formState.condition.discount.apply_to_bill_from">
                            <template #addonAfter>
                              {{'VNĐ'}}
                            </template>
                          </a-input-number>
                        </a-form-item>
                      </a-col>
                    </a-row>
                    <a-form-item :label="$t('ONLY_APPLY_FOR_BILL_FROM')" v-else >
                      <a-input-number  v-model:value="formState.condition.discount.apply_to_bill_from" :formatter="value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')">
                        <template #addonAfter>
                          {{'VNĐ'}}
                        </template>
                      </a-input-number>
                    </a-form-item>
<!--                    <a-form-item name="checkNick" v-bind="formTailLayout">-->
<!--                      <a-checkbox v-model:checked="formState.checkNick">{{$t('VOUCHER_CAN_BE_APPLIED_FOR_COMBO')}}</a-checkbox>-->
<!--                    </a-form-item>-->
                  </div>
                  <div v-else-if="formState.voucher_type ==='DISCOUNT_FOR_PRODUCT'">
                    <a-form-item
                        :label="$t('DISCOUNT_VN_%')"
                    >
                      <a-row>
                        <a-col :span="18">
                          <a-input-number style="width: 100%" :min="0" :formatter="value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')" :max="formState.condition.discount.type === 'percent' ? 100 : 20000000000000000" v-model:value="formState.condition.discount.discount" />
                        </a-col>
                        <a-col :span="6">
                          <a-select
                              allowClea
                              style="width: 100%"
                              placeholder="Please select"
                              v-model:value="formState.condition.discount.type"
                              @change="changePage"
                          >
                            <a-select-option

                                v-bind:value="'percent'"
                            >{{ '%' }}
                            </a-select-option>
                            <a-select-option

                                v-bind:value="'vnd'"
                            >{{ 'VNĐ ' }}
                            </a-select-option>
                          </a-select>
                        </a-col>
                      </a-row>

                    </a-form-item>
                    <a-form-item
                        :label="$t('APPLY_ON_NOT_APPLY')"
                    >
                      <a-row style="margin-bottom: 10px">
                        <a-col :span="6">
                          <a-select
                              allowClea
                              style="width: 100%"
                              placeholder="Please select"
                              v-model:value="formState.condition.apply.type"
                              @change="changePage"
                          >
                            <a-select-option

                                v-bind:value="'apply_on'"
                            >{{ $t('apply_on') }}
                            </a-select-option>
                            <a-select-option

                                v-bind:value="'not_apply'"
                            >{{ $t('not_apply') }}
                            </a-select-option>
                          </a-select>
                        </a-col>

                        <a-col :span="6">
                          <a-select
                              allowClea
                              style="width: 100%"
                              placeholder="Please select"
                              v-model:value="formState.condition.apply.condition"
                              @change="changePage"
                          >
<!--                            <a-select-option-->

<!--                                v-bind:value="'group'"-->
<!--                            >{{ $t('group') }}-->
<!--                            </a-select-option>-->
                            <a-select-option
                                v-bind:value="'product'"
                            >{{ $t('product') }}
                            </a-select-option>
                          </a-select>
                        </a-col>
<!--                         <a-col :span="6">-->
<!--                           <a-select-->
<!--                               allowClea-->
<!--                               v-model:value="formState.stock_id"-->
<!--                               style="width: 100%"-->
<!--                               placeholder="Please select"-->
<!--                               @change="handleOnChangePage"-->
<!--                           >-->
<!--                             <a-select-option-->
<!--                                 v-for="item in getStocks"-->
<!--                                 :key="item.id"-->
<!--                                 v-bind:value="item.id"-->
<!--                             >{{ item.shop_name }}-->
<!--                             </a-select-option>-->
<!--                           </a-select>-->
<!--                         </a-col>-->
                      </a-row>
                      <a-select v-if="formState.condition.apply.condition === 'group'" show-search mode="tags" v-model:value="formState.condition.apply.group_products">
                        <a-select-option v-for="i in getProductGroups.productGroups" :key="i.id" :value="i.id">
                          {{i.group_name
                          }}
                          }}
                        </a-select-option>
                      </a-select>
                      <a-select v-else show-search mode="tags" v-model:value="formState.condition.apply.products">
                        <a-select-option v-for="i in getStockProducts.products" :key="i.id" :value="i.id">
                          {{i.product_name

                          }}
                        </a-select-option>
                      </a-select>

                    </a-form-item>
                    <a-row style="cursor: pointer" @click="conditon =!conditon">
                      <a-typography-text style="color: #FAB216" :level="4">{{$t('SEE_MORE_ADVANCED_CONDITIONS')}}</a-typography-text>
                      <caret-down-outlined style="color: #FAB216" v-if="conditon === false" />
                      <caret-up-outlined  style="color: #FAB216" v-else/>
                    </a-row>
                    <div v-if="conditon === true">
                      <a-form-item  v-if="formState.condition.apply.condition !== 'group'" :label="$t('DISCOUNT')" >
                        <a-row>
                          <a-col :span="14">
                            <a-input :disabled="formState.condition.discount.discount_form ? true : false" v-model:value="formState.condition.discount.product_num" />
                          </a-col>
                          <a-col :span="4">
                            {{$t('PRODUCT')}}
                          </a-col>
                          <a-col :span="6">
                            <a-select
                                allowClea
                                style="width: 100%"
                                placeholder="Please select"
                                v-model:value="formState.condition.discount.price_type"
                                :disabled="formState.condition.discount.discount_form ? true : false"
                                @change="changePage"
                            >
                              <a-select-option

                                  v-bind:value="'max'"
                              >{{ $t('HIGHEST_PRICE') }}
                              </a-select-option>
                              <a-select-option

                                  v-bind:value="'min'"
                              >{{ $t('LOWEST_PRICE') }}
                              </a-select-option>
                            </a-select>
                          </a-col>
                        </a-row>

                      </a-form-item>
                      <a-form-item v-if="formState.condition.apply.condition !== 'group'" :label="$t('DISCOUNT_FROM_PRODUCT')" >
                        <a-row>
                          <a-col :span="16">
                            <a-input :disabled="formState.condition.discount.product_num ? true :false" v-model:value="formState.condition.discount.discount_form"/>
                          </a-col>

<!--                          <a-col :span="8">-->
<!--                            <a-select-->
<!--                                allowClea-->
<!--                                style="width: 100%"-->
<!--                                placeholder="Please select"-->
<!--                                v-model:value="formState.condition.discount.price_type"-->
<!--                                :disabled="formState.condition.discount.product_num ? true :false"-->
<!--                                @change="changePage"-->
<!--                            >-->
<!--                              <a-select-option-->

<!--                                  v-bind:value="'max'"-->
<!--                              >{{ $t('HIGHEST_PRICE') }}-->
<!--                              </a-select-option>-->
<!--                              <a-select-option-->

<!--                                  v-bind:value="'min'"-->
<!--                              >{{ $t('LOWEST_PRICE') }}-->
<!--                              </a-select-option>-->
<!--                            </a-select>-->
<!--                          </a-col>-->
                        </a-row>

                      </a-form-item>
                      <a-form-item v-if="formState.condition.discount.type === 'percent'" :label="$t('LIMITED')" >

                        <a-row   style="width: 100%">
                          <a-col :span="11">
                            <a-typography-title :level="5">{{$t('MAXIMUM_DISCOUNT_VALUE')}}</a-typography-title>
                            <a-form-item>
                              <a-input-number :formatter="value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')" v-model:value="formState.condition.discount.discount_max">
                                <template #addonAfter>
                                  {{'VNĐ'}}
                                </template>
                              </a-input-number>
                            </a-form-item>
                          </a-col>
                          <a-col :span="1">

                          </a-col>
                          <a-col :span="12">
                            <a-typography-title :level="5">{{$t('ONLY_APPLY_FOR_BILL_FROM')}}</a-typography-title>
                            <a-form-item >
                              <a-input-number :formatter="value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')" v-model:value="formState.condition.discount.apply_to_bill_from">
                                <template #addonAfter>
                                  {{'VNĐ'}}
                                </template>
                              </a-input-number>
                            </a-form-item>
                          </a-col>
                        </a-row>

                      </a-form-item>
                      <a-form-item :label="$t('ONLY_APPLY_FOR_BILL_FROM')" v-else >
                        <a-input-number style="width: 100%" :formatter="value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')"  v-model:value="formState.condition.discount.apply_to_bill_from">
                          <template #addonAfter>
                            {{'VNĐ'}}
                          </template>
                        </a-input-number>
                      </a-form-item>
<!--                      <a-form-item name="checkNick" v-bind="formTailLayout">-->
<!--                        <a-checkbox v-model:checked="formState.checkNick">{{$t('VOUCHER_CAN_BE_APPLIED_FOR_COMBO')}}</a-checkbox>-->
<!--                      </a-form-item>-->

                    </div>

                  </div>
                  <div v-else-if="formState.voucher_type ==='BUY_A_DISCOUNT_B'">

                    <a-form-item
                        :label="$t('APPLY_ON_NOT_APPLY')"
                    >
                      <a-radio-group v-model:value="formState.condition.apply.condition" >
<!--                        <a-radio    value="group">{{$t('group')}}</a-radio>-->
                        <a-radio   value="product">{{$t('product')}}</a-radio>
                      </a-radio-group>
                    </a-form-item>
                    <div v-if="formState.condition.apply.condition === 'group'">
                      <a-form-item
                          :label="$t('GROUP_LIST_TO_BUY')"
                      >
                        <a-select
                            show-search
                            mode="tags"
                            v-model:value="formState.condition.apply.group_products"
                        >
                          <a-select-option v-for="i in getProductGroups.productGroups" :key="i.id" :value="i.id">
                            {{i.group_name
                            }}
                          </a-select-option>
                        </a-select>
                      </a-form-item>
                      <a-form-item
                          :label="$t('DISCOUNTED_GROUP_LIST')"
                      >
                        <a-select show-search mode="tags" v-model:value="formState.condition.apply.group_product_promotions">
                          <a-select-option v-for="i in getProductGroups.productGroups" :key="i.id" :value="i.id">
                            {{i.group_name
                            }}
                          </a-select-option>
                        </a-select>
                      </a-form-item>
                    </div>
                    <div v-else>
                      <a-form-item
                          :label="$t('PRODUCT_LIST_TO_BUY')"
                      >
                        <a-select show-search mode="tags"  v-model:value="formState.condition.apply.products">
                          <a-select-option v-for="i in getStockProducts.products" :key="i.id" :value="i.id">
                            {{i.group_name
                            }}
                          </a-select-option>
                        </a-select>
                      </a-form-item>
                      <a-form-item
                          :label="$t('DISCOUNTED_PRODUCT_LIST')"
                      >
                        <a-select show-search mode="tags" v-model:value="formState.condition.apply.product_promotion">
                          <a-select-option v-for="i in getStockProducts.products" :key="i.id" :value="i.id">
                            {{i.group_name}}
                          </a-select-option>
                        </a-select>
                      </a-form-item>
                    </div>
                    <a-form-item
                        :label="$t('THE_NUMBER_OF_PRODUCT')"
                    >
                      <a-row v-if="formState.condition.apply.condition === 'group'"  style="width: 100%">
                        <a-col :span="11">
                          <a-typography-title :level="5">{{$t('NUMBER_OF_GROUPS_TO_BUY')}}</a-typography-title>
                          <a-form-item>
                            <a-input-number v-model:value="formState.condition.apply.group_product_num">
                              <template #addonAfter>
                                {{$t('PRODUCT')}}
                              </template>
                            </a-input-number>
                          </a-form-item>
                        </a-col>
                        <a-col :span="1">

                        </a-col>
                        <a-col :span="12">
                          <a-typography-title :level="5">{{$t('QUANTITY_OF_GROUPS_IS_REDUCED')}}</a-typography-title>
                          <a-form-item >
                            <a-input-number  v-model:value="formState.condition.apply.group_product_promotion_num">
                              <template #addonAfter>
                                {{$t('PRODUCT')}}
                              </template>
                            </a-input-number>
                          </a-form-item>
                        </a-col>
                      </a-row>
                      <a-row v-else style="width: 100%">
                        <a-col :span="11">
                          <a-typography-title :level="5">{{$t('NUMBER_OF_PRODUCTS_TO_BUY')}}</a-typography-title>
                          <a-form-item>
                            <a-input-number v-model:value="formState.condition.apply.product_num">
                              <template #addonAfter>
                                {{$t('PRODUCT')}}
                              </template>
                            </a-input-number>
                          </a-form-item>
                        </a-col>
                        <a-col :span="1">

                        </a-col>
                        <a-col :span="12">
                          <a-typography-title :level="5">{{$t('QUANTITY_OF_PRODUCT_IS_REDUCED')}}</a-typography-title>
                          <a-form-item >
                            <a-input-number  v-model:value="formState.condition.apply.product_promotion_num">
                              <template #addonAfter>
                                {{$t('PRODUCT')}}
                              </template>
                            </a-input-number>
                          </a-form-item>
                        </a-col>
                      </a-row>
                    </a-form-item>
                    <a-form-item
                        :label="$t('DISCOUNT_%')"

                    >
                      <a-input-number style="width: 100%"  :max="100" v-model:value="formState.condition.discount.discount"  >
                        <template #addonAfter>
                          {{'%'}}
                        </template>
                      </a-input-number>
                    </a-form-item>
                    <a-form-item :label="$t('ONLY_APPLY_FOR_BILL_FROM')">
                      <a-input-number style="width: 100%" :formatter="value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')"  v-model:value="formState.condition.discount.apply_to_bill_from">
                        <template #addonAfter>
                          {{'VNĐ'}}
                        </template>
                      </a-input-number>
                    </a-form-item>
                  </div>
                  <div v-else-if="formState.voucher_type ==='BUY_X_GIVE_AWAY_Y'">

                    <a-form-item
                        :label="$t('APPLY_ON_NOT_APPLY')"
                    >
                      <a-radio-group v-model:value="formState.condition.apply.condition" >
<!--                        <a-radio   value="group">{{$t('group')}}</a-radio>-->
                        <a-radio   value="product">{{$t('product')}}</a-radio>
                      </a-radio-group>
                    </a-form-item>
                   <div v-if="formState.condition.apply.condition === 'group'">
                     <a-form-item
                         :label="$t('GROUP_LIST_TO_BUY')"

                     >
                       <a-select show-search mode="tags" v-model:value="formState.condition.apply.group_products">
                         <a-select-option v-for="i in getProductGroups.productGroups" :key="i.id" :value="i.id">
                           {{i.group_name
                           }}
                         </a-select-option>
                       </a-select>

                     </a-form-item>
                     <a-form-item
                         :label="$t('GIVEN_AWAY_GROUP_LIST')"

                     >
                        <a-select show-search mode="tags" v-model:value="formState.condition.apply.group_product_promotions">
                          <a-select-option v-for="i in getProductGroups.productGroups" :key="i.id" :value="i.id">
                            {{i.group_name
                            }}
                          </a-select-option>
                        </a-select>
                     </a-form-item>
                   </div >
                    <div v-else>
                      <a-form-item
                          :label="$t('PRODUCT_LIST_TO_BUY')"

                      >
                        <a-select show-search mode="tags" v-model:value="formState.condition.apply.products">
                         <a-select-option v-for="i in getStockProducts.products" :key="i.id" :value="i.id">
                              {{i.product_name}}
                         </a-select-option>
                        </a-select>
                      </a-form-item>
                      <a-form-item
                          :label="$t('GIVEN_AWAY_PRODUCT_LIST')"

                      >
                        <a-select show-search mode="tags"  v-model:value="formState.condition.apply.product_promotion"  >
                          <a-select-option v-for="i in getStockProducts.products" :key="i.id" :value="i.id">
                            {{i.product_name}}
                          </a-select-option>
                        </a-select>
                      </a-form-item>
                    </div>
                    <a-form-item
                        :label="$t('THE_NUMBER_OF_PRODUCT')"

                    >
                      <a-row v-if="formState.condition.apply.condition === 'group'"  style="width: 100%">
                        <a-col :span="11">
                          <a-typography-title :level="5">{{$t('NUMBER_OF_GROUPS_TO_BUY')}}</a-typography-title>
                          <a-form-item>
                            <a-input v-model:value="formState.condition.apply.group_product_num">
                              <template #addonAfter>
                                {{$t('PRODUCT')}}
                              </template>
                            </a-input>
                          </a-form-item>
                        </a-col>
                        <a-col :span="1">

                        </a-col>
                        <a-col :span="12">
                          <a-typography-title :level="5">{{$t('QUANTITY_OF_GROUPS_IS_GIVEN_AWAY')}}</a-typography-title>
                          <a-form-item >
                            <a-input  v-model:value="formState.condition.apply.group_product_promotion_num">
                              <template #addonAfter>
                                {{$t('PRODUCT')}}
                              </template>
                            </a-input>
                          </a-form-item>
                        </a-col>
                      </a-row>
                      <a-row v-else style="width: 100%">
                        <a-col :span="11">
                          <a-typography-title :level="5">{{$t('NUMBER_OF_PRODUCTS_TO_BUY')}}</a-typography-title>
                          <a-form-item>
                            <a-input-number v-model:value="formState.condition.apply.product_num">
                              <template #addonAfter>
                                {{$t('PRODUCT')}}
                              </template>
                            </a-input-number>
                          </a-form-item>
                        </a-col>
                        <a-col :span="1">

                        </a-col>
                        <a-col :span="12">
                          <a-typography-title :level="5">{{$t('QUANTITY_OF_PRODUCT_IS_GIVEN_AWAY')}}</a-typography-title>
                          <a-form-item >
                            <a-input-number  v-model:value="formState.condition.apply.product_promotion_num">
                              <template #addonAfter>
                                {{$t('PRODUCT')}}
                              </template>
                            </a-input-number>
                          </a-form-item>
                        </a-col>
                      </a-row>
                    </a-form-item>
                    <a-form-item :label="$t('ONLY_APPLY_FOR_BILL_FROM')">
                      <a-input-number style="width: 100%" :formatter="value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')" v-model:value="formState.condition.discount.apply_to_bill_from">
                        <template #addonAfter>
                          {{'VNĐ'}}
                        </template>
                      </a-input-number>
                    </a-form-item>
<!--                    <a-form-item >-->
<!--                      <a-row justify="center">-->
<!--                        <a-checkbox >{{$t('THE_GIVEN_GROUP_IS_THE_SAME_AS_THE_GROUP_TO_BUY')}}</a-checkbox>-->
<!--                        <a-checkbox >{{$t('GIVE_THE_HIGHEST_PRICE')}}</a-checkbox>-->
<!--                      </a-row>-->
<!--                    </a-form-item>-->
                  </div>
                  <div v-else-if="formState.voucher_type ==='SAME_PRICE'">

                    <a-form-item
                        :label="$t('APPLY_ON_NOT_APPLY')"
                    >
                      <a-row style="margin-bottom: 10px">
                        <a-col :span="6">
                          <a-select
                              allowClea
                              style="width: 100%"
                              placeholder="Please select"
                              v-model:value="formState.condition.apply.type"
                              @change="changePage"
                          >
                            <a-select-option

                                v-bind:value="'apply_on'"
                            >{{ $t('apply_on') }}
                            </a-select-option>
                            <a-select-option

                                v-bind:value="'not_apply'"
                            >{{ $t('not_apply') }}
                            </a-select-option>
                          </a-select>
                        </a-col>

                        <a-col :span="6">
                          <a-select
                              allowClea
                              style="width: 100%"
                              placeholder="Please select"
                              v-model:value="formState.condition.apply.condition"
                              @change="changePage"
                          >
<!--                            <a-select-option-->

<!--                                v-bind:value="'group'"-->
<!--                            >{{ $t('group') }}-->
<!--                            </a-select-option>-->
                            <a-select-option
                                v-bind:value="'product'"
                            >{{ $t('product') }}
                            </a-select-option>
                          </a-select>
                        </a-col>
                      </a-row>
                      <a-select v-if="formState.condition.apply.condition === 'group'" show-search mode="tags" v-model:value="formState.condition.apply.group_products">
                        <a-select-option v-for="i in getProductGroups.productGroups" :key="i.id" :value="i.id">
                          {{i.group_name
                          }}
                        </a-select-option>
                      </a-select>
                      <a-select v-else show-search mode="tags" v-model:value="formState.condition.apply.products">
                        <a-select-option v-for="i in getStockProducts.products" :key="i.id" :value="i.id">
                          {{i.product_name

                          }}
                        </a-select-option>
                      </a-select>

                    </a-form-item>
                    <a-form-item
                        :label="$t('SAME_PRICE')"

                    >
                      <a-input-number v-model:value="formState.condition.discount.same_price" style="width: 100%" :formatter="value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')">
                        <template #addonAfter>
                          {{'VNĐ'}}
                        </template>
                      </a-input-number>

                    </a-form-item>
                  </div>
                  <div v-else-if="formState.voucher_type ==='OTHER_OFFERS'">

                    <a-form-item
                        :label="$t('URL')"
                    >
                      <a-input :placeholder="$t('PLEASE_ENTER_LINK')"/>

                    </a-form-item>

                  </div>
                </a-form>
              </a-row>
            </a-card>
          </a-row>
        </div>
    </div>
    <div v-if="current === 2" >
      <a-row style="justify-content: flex-end">
        <a-button  type="primary" style="margin-right: 10px;margin-bottom: 20px" @click="timeVoucher">
          <send-outlined/>
          {{ $t("next") }}
        </a-button>
      </a-row>
      <a-row justify="center">
        <a-card style="border-radius: 10px;width: 47%">

          <a-row justify="center">
            <a-typography-text style="margin-bottom: 20px" strong>{{$t('Time')}}</a-typography-text>

            <a-form :label-col="{ span: 10 }"
                    :wrapper-col="{ span: 14 }"
                    style="width: 100%"
                    labelAlign="left"
                    ref="formRef2"
                    :rules="rangeConfig"
                    :model="voucherTime"

            >

              <a-form-item  name="time_application" :label="$t('PROGRAM_VALIDITY_PERIOD')"  v-bind="rangeConfig">
                <a-range-picker style="width: 100%"   v-model:value="voucherTime.time_application" format="DD-MM-YYYY" :valueFormat="'YYYY-MM-DD'"  />
              </a-form-item>
              <!--                  <a-row style="cursor: pointer" @click="conditonTime =!conditonTime">-->
              <!--                    <a-typography-text style="color: #FAB216" :level="4">{{$t('ADVANCED_TIME_CONDITIONS')}}</a-typography-text>-->
              <!--                    <caret-down-outlined style="color: #FAB216" v-if="conditonTime === false" />-->
              <!--                    <caret-up-outlined  style="color: #FAB216" v-else/>-->
              <!--                  </a-row>-->
              <!--                  <div v-if="conditonTime">-->
              <!--                    <a-typography-text style="display:block;margin-bottom: 30px">{{$t('APPLIED_TIME_FRAME')}}</a-typography-text>-->
              <!--                    <a-form-item  :label="$t('DAY_OF_WEEK')" >-->
              <!--                      <a-button :type="getActive(i)" @click="addDayOfWeek(i)" style="margin-left: 10px;margin-right: 10px;margin-bottom: 10px;width: 88px" v-for="i in dayOfWeek" :key="i">-->
              <!--                        {{$t(i)}}-->
              <!--                      </a-button>-->
              <!--                    </a-form-item>-->
              <!--                    <a-form-item   :label="$t('HOUR_OF_THE_DAY')" >-->
              <!--                      <a-button :type="getActiveTime(i)" @click="addTimeFrame(i)" style="margin-left: 10px;margin-right: 10px;margin-bottom: 10px;width: 76px" v-for="i in timeFrame" :key="i">-->
              <!--                        {{$t(i)}}-->
              <!--                      </a-button>-->
              <!--                    </a-form-item>-->
              <!--                  </div>-->
            </a-form>

          </a-row>
        </a-card>

      </a-row>
    </div>
  </div>
  <div v-if="current === 3">
    <a-row style="justify-content: flex-end">
      <a-button :disabled="voucherConfig.member_can_use_voucher && voucherConfig.number_of_uses_per_day && voucherConfig.number_of_times_to_apply_the_program ? false : true "  type="primary" style="margin-right: 10px;margin-bottom: 20px" @click="publish">
        <send-outlined/>
        {{ $t("FLOW_PUBLISH") }}
      </a-button>
    </a-row>
    <a-row justify="center">
      <a-card style="border-radius: 10px;width: 50%;margin: 10px">
        <a-row >
          <a-typography-text style="margin-bottom: 20px" strong>{{$t('OTHER')}}</a-typography-text>
          <a-form :label-col="{ span: 14 }"
                  :wrapper-col="{ span: 10 }"
                  style="width: 100%"
                  labelAlign="left"
                  layout=""
          >
            <!--                <a-form-item name="area" :label="$t('ACCOUNTING_TYPE')" :rules="[{ required: true, message: 'Missing area' }]">-->
            <!--                  <a-select v-model:value="formState.accounting_type" :options="areas" />-->
            <!--                </a-form-item>-->
            <!--                <a-form-item name="area" :label="$t('APPLY_WITH_OTHER_OFFERS_INSTALLED_ON_BIGLEAD')" :rules="[{ required: true, message: 'Missing area' }]">-->
            <!--                  <a-select v-model:value="formState.accounting_type" :options="areas" />-->
            <!--                </a-form-item>-->
            <!--                <a-form-item name="area" :label="$t('CHANNEL_OF_DISTRIBUTE_VOUCHER')" >-->
            <!--                  <a-select v-model:value="formState.accounting_type" :options="areas" />-->
            <!--                </a-form-item>-->
            <!--                <a-form-item name="area" :label="$t('VOUCHERS_ARE_ONLY_APPLICABLE_ON_CHANNELS')" >-->
            <!--                  <a-select v-model:value="formState.accounting_type" :options="areas" />-->
            <!--                </a-form-item>-->
            <a-form-item name="area" :label="$t('VOUCHER_IS_ONLY_APPLED_FOR_MEMBER')" >
              <a-select v-model:value="voucherConfig.voucher_is_only_applied_for_member"  >
              <a-select-option :value="true">
                {{$t('YES')}}
              </a-select-option>
                <a-select-option :value="false">
                  {{$t('NO')}}
                </a-select-option>
              </a-select>
            </a-form-item>
            <a-form-item name="area" :label="$t('EACH_MEMBER_CAN_USE_VOUCHER_01_TIME_MULTIPLE_TIMES')" >
              <a-select v-model:value="voucherConfig.member_can_use_voucher"  >
                <a-select-option :value="'MANY'">
                  {{$t('MANY')}}
                </a-select-option>
                <a-select-option :value="'ONLY'">
                  {{$t('ONLY')}}
                </a-select-option>
              </a-select>
            </a-form-item>
            <a-form-item name="area" :label="$t('NUMBER_OF_TIMES_TO_APPLY_THE_CODE_PER_DAY')" >
              <a-input-number style="width: 100%" v-model:value="voucherConfig.number_of_uses_per_day"/>
            </a-form-item>
            <a-form-item name="area" :label="$t('THE_NUMBER_OF_APPLICATION_TIMES')" >
              <a-input-number style="width: 100%" v-model:value="voucherConfig.number_of_times_to_apply_the_program"/>
            </a-form-item>
          </a-form>
        </a-row>
      </a-card>

    </a-row>
  </div>
<!--  <div v-if="current !== 0">-->
<!--    <a-row style="margin-left: 25px;margin-bottom: 20px;margin-top: 20px">-->
<!--      <a-typography-text>{{$t('REQUIRED_INFORMATION_TO_ENTER')}}</a-typography-text>-->
<!--    </a-row>-->
<!--    <a-row justify="space-between" style="margin-left: 25px;margin-bottom: 20px;margin-top: 20px;margin-right: 25px">-->
<!--      <a-button>{{$t('button_back')}}</a-button>-->
<!--      <a-button @click="onSubmit" type="primary">{{$t('next')}}</a-button>-->
<!--    </a-row>-->
<!--  </div>-->
</template>
<script lang="ts">
import {CaretDownOutlined,CaretUpOutlined,SendOutlined} from "@ant-design/icons-vue";
import {computed, onMounted, ref} from "vue";
import {useI18n} from "vue-i18n";
import {useStore} from "vuex";
import {Voucher} from "@/models/Voucher/Voucher";
import {VoucherRequest} from "@/APIs/request/VoucherRequest";
import APIs from "@/APIs/APIs";
import {MixUtils} from "@/utils/Utils";
import {useRoute, useRouter} from "vue-router";
import RouterEndpoint from "@/router/RouterEndpoint";
import {StockActionTypes} from "@/store/modules/stocks/action-types";
import {StockProductRequest} from "@/APIs/request/ProductRequest";
import {message} from "ant-design-vue";
import {Stocks} from "@/models/Stocks/Stocks";

export default {
  components:{
    CaretDownOutlined,
    CaretUpOutlined,
    SendOutlined

  },
  setup(){
    const i18t = useI18n()
    const store = useStore()
    const router = useRouter()
    const route = useRoute()
    const configPage = computed(()=> store.getters.getPageConfig)
    const getStocks = computed((): Stocks[] | undefined => store.getters.getStocks.stocks)
    const conditon = ref(false)
    const conditonTime = ref(false)
    const current = ref(0)
    const conditonOther = ref(false)
    const currentShop = computed(()=> store.getters.getCurrentShop)
    const userInfo = computed(() => store.getters.getUserInfo)
    const getStockProducts=computed(() =>store.getters.getStockProducts)
    const getProductGroups = computed(() => store.getters.getProductGroups)
    const formRef = ref()
    const formRef2 = ref()
    const formRef3 = ref()
    const formState = ref({
      voucher_name:'',
      description:'',
      voucher_code:'',
      voucher_type:'',
      shop_id:undefined,
      creator:undefined,
      condition:{
      },
      purpose:undefined,
      used_voucher:undefined,
      revenue:undefined,
      status:'',
      created_at:'',
      updated_at:''
    });
    const voucherTime = ref({
      id:'',
      shop_id:0,
      time_application:[]
    })
    const i18n = useI18n()
    const voucherConfig = ref({
      voucher_is_only_applied_for_member: false,
      member_can_use_voucher:'',
      number_of_uses_per_day:0,
      number_of_times_to_apply_the_program:0
    })
    const purpose = ref(0)
    const purposes = ref([{
      value:'MANY',
      title: 'ONE_CODE_USE_MANY_TIME',
      subtitle: 'ONE_CODE_USE_MANY_TIME_SUBTITLE'
    },
      {
        value: 'ONLY',
        title: 'A_SINGLE_USE_VOUCHER',
        subtitle: 'A_SINGLE_USE_VOUCHER_SUBTITLE'
      }
    ])

    const fetchProducts = ()=>{
      store.dispatch(StockActionTypes.FETCH_STOCK_PRODUCTS,{page:1,page_size:9999999})
    }
    const promotionType = ref([
      {
        value:'DISCOUNT_FOR_TOTAL_BILL',
        title: 'DISCOUNT_FOR_TOTAL_BILL',
      },
      {
        value:'DISCOUNT_FOR_PRODUCT',
        title: 'DISCOUNT_FOR_PRODUCT',
      },
      {
        value:'BUY_A_DISCOUNT_B',
        title:  'BUY_A_DISCOUNT_B',
      },
      {
        value:'BUY_X_GIVE_AWAY_Y',
        title:  'BUY_X_GIVE_AWAY_Y',
      },
      {
        value:'SAME_PRICE',
        title:  'SAME_PRICE',
      },
      {
        value:'OTHER_OFFERS',
        title: 'OTHER_OFFERS',
      },
    ])
    const dayOfWeek=['sunday','monday','tuesday','wednesday','thursday','friday','saturday']
    const timeFrame=['0-1h','1-2h','2-3h','3-4h','4-5h','5-6h','6-7h','7-8h','8-9h','9-10h','10-11h','11-12h','12-13h','13-14h','14-15h','15-16h','16-17h','17-18h','18-19h','19-20h','20-21h','21-22h','22-23h','23-24h']
    const rangeConfig = {
      rules: [{ type: 'array' as const, required: true, message: 'Please select time!' }],
    };
    const rules = {
      voucher_name: [
        {required: true, message: 'Please input name', trigger: 'blur'},
      ],
      voucher_code: [
        {required: true, message: 'Please input code', trigger: 'blur'},
      ],

    };
    const rules2 = {
      page_application: [
        {required: true, message: 'Please select page',trigger:'blur'},
      ],

    };
    // const changePage = () => {
    //   onSubmit()
    // }
    // const addDayOfWeek = (i:string) =>{
    //   // @ts-ignore
    //   if(!formState.value.day_of_week.includes(i)){
    //     // @ts-ignore
    //     formState.value.day_of_week.push(i)
    //   }else {
    //     formState.value.day_of_week = formState.value.day_of_week.filter((item:string) => item !== i)
    //   }
    //
    //
    // }
    const changeVoucherName = (e:any) =>{
      formState.value.voucher_code = MixUtils.methods.removeVietnamese3(e.target.value).toUpperCase()
    }
    // const stockProductRequest: StockProductRequest = { page: 1, page_size: 20, stock: Number('163') }
    //
    // const fetchProducts=()=>{
    //   store.dispatch(StockActionTypes.FETCH_STOCK_PRODUCTS, stockProductRequest)
    // }
    // const handleChangeSearch = (value:string) =>{
    //   stockProductRequest.search = value
    //   fetchProducts()
    // }
    const createVoucher =  () => {
      formRef.value
          .validate()
          .then( async () => {
            if(!checkEdit.value){
              const res = await APIs.init().createdVoucher({
                voucher_name: formState.value.voucher_name,
                voucher_code: formState.value.voucher_code,
                description: formState.value.description,
                shop_id: currentShop.value.id,
                creator: userInfo.value.id
              })
              if (res.data?.status === 200) {
                current.value = 1

                await router.replace({name: RouterEndpoint.CREATE_VOUCHER.name, params: {id: res.data.data.id}})
                // @ts-ignore
                formState.value = res.data.data
              }
            }else {
              // @ts-ignore
              const res = await APIs.init().updatedVoucher(formState.value)
              if(res.data?.status=== 200){
                // @ts-ignore
                formState.value =res.data.data
                current.value = 1
              }
            }

          })
    }
    const handlePurpose = async (i: number) => {
      // @ts-ignore
      formState.value.purpose = i
      // @ts-ignore
      const res = await APIs.init().updatedVoucher(formState.value)
      if(res.data?.status=== 200){
        // @ts-ignore
        formState.value =res.data.data
      }
    }
   const handlePromotionType = async (i : number) =>{
     // @ts-ignore
      formState.value.voucher_type = i

     // @ts-ignore
     if(formState.value.voucher_type === 'BUY_A_DISCOUNT_B'|| formState.value.voucher_type ==='DISCOUNT_FOR_PRODUCT' ){
       // @ts-ignore
       formState.value.condition.apply.type = 'apply_on'
       // @ts-ignore
       formState.value.condition.discount.type = 'percent'
       // @ts-ignore
       formState.value.condition.apply.condition = 'group'
       // @ts-ignore
     }else if(formState.value.voucher_type ==='BUY_X_GIVE_AWAY_Y'|| formState.value.voucher_type === 'SAME_PRICE'){
       // @ts-ignore
       formState.value.condition.apply.type = 'apply_on'
       // @ts-ignore
       formState.value.condition.apply.condition = 'group'
       // @ts-ignore
     }else if(formState.value.voucher_type === 'DISCOUNT_FOR_TOTAL_BILL' ){
       // @ts-ignore
       formState.value.condition.discount.type = 'percent'
     }
     // @ts-ignore
     const res = await APIs.init().updatedVoucher(formState.value)
     if(res.data?.status=== 200){
       // @ts-ignore
       formState.value = res.data.data
       // @ts-ignore
       formState.value.condition.page_application = undefined
       // @ts-ignore
       formState.value.condition.apply.group_products = undefined
       // @ts-ignore
       formState.value.condition.apply.group_product_promotions = undefined
       // @ts-ignore
       formState.value.condition.apply.products = undefined
       // @ts-ignore
       formState.value.condition.apply.product_promotion = undefined
     }
    }
    // const changeTimeApplication = (value:any) => {
    //   value.map((item:any) => {
    //
    //   })
    // }
  const conditionVoucher =  async () => {
    formRef3.value
        .validate()
        .then( async () => {
          // @ts-ignore
         const res = await APIs.init().updatedVoucher(formState.value)
          if(res.data?.status === 200){
            current.value = 2
            // @ts-ignore
            formState.value = res.data.data
            message.success(i18n.t('SUCCESS'))
          }
        })
  }
  const timeVoucher = () => {
    formRef2.value
       .validate()
       .then( async () => {
         // @ts-ignore
        if(voucherTime.value.id && voucherTime.value.shop_id){
          // @ts-ignore
          const res = await APIs.init().updateVoucherTime({id: voucherTime.value.id,date_start:voucherTime.value.time_application[0],date_end:voucherTime.value.time_application[1] })
          if(res.data?.status === 200){
            current.value = 3
            message.success(i18n.t('SUCCESS'))
          }
        }else {
          // @ts-ignore
          const res = await APIs.init().voucherTime({voucher_id:route.params.id,date_start:voucherTime.value.time_application[0],date_end:voucherTime.value.time_application[1] })
          if(res.data?.status === 200){
            current.value = 3
            message.success(i18n.t('SUCCESS'))
          }
        }
       })
  }
    const publish =  async () =>{
// @ts-ignore
    if(voucherConfig.value.id && voucherConfig.value.shop_id){
      const res = await  APIs.init().updateVoucherConfig({
        // @ts-ignore
        id:voucherConfig.value.id,
        voucher_is_only_applied_for_member:voucherConfig.value.voucher_is_only_applied_for_member,
        member_can_use_voucher: voucherConfig.value.member_can_use_voucher,
        number_of_uses_per_day: voucherConfig.value.number_of_uses_per_day,
        number_of_times_to_apply_the_program: voucherConfig.value.number_of_times_to_apply_the_program

      })
      if(res.data?.status === 200){
      await  router.push("/voucher")
        message.success(i18n.t('SUCCESS'))
      }
    }else {
      const res = await  APIs.init().voucherConfig({
        // @ts-ignore
        voucher_id:route.params.id,
        voucher_is_only_applied_for_member:voucherConfig.value.voucher_is_only_applied_for_member,
        member_can_use_voucher: voucherConfig.value.member_can_use_voucher,
        number_of_uses_per_day: voucherConfig.value.number_of_uses_per_day,
        number_of_times_to_apply_the_program: voucherConfig.value.number_of_times_to_apply_the_program

      })
      if(res.data?.status === 200){
        router.push("/voucher")
        message.success(i18n.t('SUCCESS'))
      }
    }

    }
    // const getActive = (i: string) => {
    //   // @ts-ignore
    //   if(formState.value.day_of_week.includes(i)){
    //     return 'primary'
    //   }
    //   return ''
    //
    // }
    // const addTimeFrame = (i:string) =>{
    //   // @ts-ignore
    //   if(!formState.value.time_frame.includes(i)){
    //     // @ts-ignore
    //     formState.value.time_frame.push(i)
    //   }else {
    //     formState.value.time_frame = formState.value.time_frame.filter((item:string) => item !== i)
    //   }
    //
    //
    // }
    // const getActiveTime = (i:string) => {
    //   // @ts-ignore
    //   if(formState.value.time_frame.includes(i)){
    //     return 'primary'
    //   }
    //   return ''
    // }
  // const onSubmit = () => {
  //   formRef.value
  //       .validate()
  //       .then( async () => {
  //         // @ts-ignore
  //
  //       })
  // }
    const fetchVoucherDetail = async ()=>{
      // @ts-ignore
      const res = await APIs.init().getVoucherDetail({id: route.params.id})
      // @ts-ignore
      if(res.data.status === 200){
        // @ts-ignore
        formState.value = res.data?.data
        if(formState.value.voucher_name){
          current.value = 1
        }
      }

    }
    const fetchVoucherTime = async ()=>{
      // @ts-ignore
      const res = await APIs.init().getVoucherTime({id: route.params.id})
      // @ts-ignore
      if(res.data.status === 200){
        // @ts-ignore
        voucherTime.value = {
          // @ts-ignore
          id:res.data?.data.id,
          shop_id: Number(res.data?.data.shop_id),
          // @ts-ignore
          time_application:[res.data?.data.date_start,res.data?.data.date_end]
        }
      }

    }
    const fetchVoucherConfig = async ()=>{
      // @ts-ignore
      const res = await APIs.init().getVoucherConfig({id: route.params.id})
      // @ts-ignore
      if(res.data.status === 200){
        // @ts-ignore
        voucherConfig.value = res.data?.data
      }

    }
    const checkEdit = ref(false)
    onMounted(() =>{
           if(route.params.id && route.params.id !== ':id'){
             checkEdit.value = true
             fetchVoucherDetail()
             fetchVoucherTime()
             fetchVoucherConfig()
             fetchProducts()
           }
    })
    return{
      purposes,
      // getActive,
      // getActiveTime,
      // addTimeFrame,
      rangeConfig,
      timeFrame,
      purpose,
      configPage,
      promotionType,
      handlePromotionType,
      formState,
      conditon,
      conditonTime,
      dayOfWeek,
      // addDayOfWeek,
      current,
      // onSubmit,
      conditonOther,
      handlePurpose,
      createVoucher,
      rules,
      formRef,
      changeVoucherName,
      // changePage,
      getStockProducts,
      getProductGroups,
      // changeTimeApplication,
      conditionVoucher,
      rules2,formRef2,formRef3,voucherTime,timeVoucher,voucherConfig,
      publish,
      checkEdit,
      getStocks

    }
  }
}
</script>

<style lang="scss" scoped>
.purpose-hover0{
  border-radius: 10px;width: 40%;
  margin-right: 40px;

}
.purpose-hover1{
  border-radius: 10px;width: 40%;
  margin-right: 40px;

}
.purpose-value{
  border-radius: 10px;margin: auto;width: 50%;
}
.purpose-hover0:hover{
  height: 150px;
  width: 45%;
  cursor: pointer;
  border-color: #FAB216;
  h4 {
    display: none !important;
  }
  span{
    display : block !important;
  }
  .anticon {
    color: #FAB216;
  }
}
.purpose-hover1:hover{
  height: 150px;
  width: 35%;
  cursor: pointer;
  border-color: #FAB216;
  h4 {
    display: none !important;
  }
  span{
    display : block !important;
  }
  .anticon {
    color: #FAB216;
  }
}

</style>
