import create from "zustand-vue";
import { persist } from "zustand/middleware";
import APIs from "@/APIs/APIs";
import { Flow, ZnsTemp } from "@/models/Flows/Flow";
import { FlowContextData } from "@/models/Flows/FlowContext";
import { FlowConstants } from "@/models/Flows/FlowConstants";
import { Metadata } from "@/APIs/response/S_HttpResponse";
export type FlowData = {
  flows: Flow[] | undefined;
  metadata: Metadata | undefined;
};

interface FlowState {
  flowData: FlowData | undefined;
  flows: Flow[];
  flowContext: FlowContextData | undefined;
  flowConstants: FlowConstants | undefined;
  znsTemp: ZnsTemp[] | undefined;
  getFlowData: (params: any) => void;
  getFlowContextData: (params: any) => void;
  getFlowConstants: (params?: any) => void;
  getFlowViralTypes: (params: any) => void;
  getZnsTemp: (params: any) => void;
  getFlowsSelectionAnt: (params: any) => void;
  getFlowContextSelectionAnt: (params: any) => void;
  getFlowsSelectionAntWithoutAll: (params: any) => void;
  getFlows: (params: any) => void;
    flowsSelectionAntWithoutAll:any
}

const initialState = {
  flowData: { flows: [], metadata: {} },
  flowContext: undefined,
  flowConstants: undefined,
  znsTemp: [],
  flows: [],
    flowsSelectionAntWithoutAll:[]
};

const useFlowStore = create<FlowState>(
  // @ts-ignore
  persist(
    (set) => ({
      ...initialState,
      getFlowData: async (params) => {
        const response = await APIs.init().getFlows(params);
        if (response.data && response.data.status === 200) {
          set(() => ({
            flowData: {
              flows: response.data?.data,
              metadata: response.data?.meta_data,
            },
          }));
        }
      },
      getFlowContextData: async (params) => {
        const response = await APIs.init().getFlowV2Context(params);
        if (response.data && response.data.status === 200) {
          set(() => ({
            flowContext: response.data?.data,
          }));
        }
      },
      getFlowConstants: async () => {
        const response = await APIs.init().getFlowConstants();
        if (response.data && response.data.status === 200) {
          set(() => ({
            flowConstants: response.data?.data,
          }));
        }
      },
      getFlowViralTypes: async () => {
        // must edit
        const response = await APIs.init().getFlowConstants();
        if (response.data && response.data.status === 200) {
          set(() => ({
            flowConstants: response.data?.data,
          }));
        }
      },
      getZnsTemp: async (params) => {
        const response = await APIs.init().getZnsTemp(params);
        if (response.data && response.data.status === 200) {
          set(() => ({
            znsTemp: response.data?.data,
          }));
        }
      },
      getFlowsSelectionAnt: async () => {
        // must edit
        const response = await APIs.init().getFlowConstants();
        if (response.data && response.data.status === 200) {
          set(() => ({
            flowConstants: response.data?.data,
          }));
        }
      },
      getFlowContextSelectionAnt: async () => {
        // must edit
        const response = await APIs.init().getFlowConstants();
        if (response.data && response.data.status === 200) {
          set(() => ({
            flowConstants: response.data?.data,
          }));
        }
      },
      getFlowsSelectionAntWithoutAll: async () => {
        // must edit
        const response = await APIs.init().getFlowConstants();
        if (response.data && response.data.status === 200) {
          set(() => ({
            flowsSelectionAntWithoutAll: response.data?.data,
          }));
        }
      },
      getFlows: async (params) => {
        const response = await APIs.init().getFlows(params);
        if (response.data && response.data.status === 200) {
          set(() => ({
            flows: response.data?.data,
          }));
        }
      },
    }),
    {
      name: "flow",
    }
  )
);

export default useFlowStore;
