import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-1cc0a5c6"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "container-title" }
const _hoisted_2 = { class: "title-header" }
const _hoisted_3 = { style: {"margin-bottom":"16px"} }
const _hoisted_4 = { style: {"margin-left":"8px"} }
const _hoisted_5 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_button = _resolveComponent("a-button")!
  const _component_a_typography_text = _resolveComponent("a-typography-text")!
  const _component_a_row = _resolveComponent("a-row")!
  const _component_a_radio_button = _resolveComponent("a-radio-button")!
  const _component_a_radio_group = _resolveComponent("a-radio-group")!
  const _component_a_spin = _resolveComponent("a-spin")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_a_tooltip = _resolveComponent("a-tooltip")!
  const _component_a_table = _resolveComponent("a-table")!
  const _component_a_tag = _resolveComponent("a-tag")!
  const _component_a_select_option = _resolveComponent("a-select-option")!
  const _component_a_select = _resolveComponent("a-select")!
  const _component_a_form_item = _resolveComponent("a-form-item")!
  const _component_a_tree_select_node = _resolveComponent("a-tree-select-node")!
  const _component_a_tree_select = _resolveComponent("a-tree-select")!
  const _component_a_form = _resolveComponent("a-form")!
  const _component_a_modal = _resolveComponent("a-modal")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("h3", _hoisted_2, _toDisplayString(_ctx.$t(_ctx.$route.name)), 1)
    ]),
    _createVNode(_component_a_table, {
      class: "table",
      onExpand: _ctx.tableExpand,
      "data-source": _ctx.list.viral,
      expandedRowKeys: _ctx.expandedRowKeys,
      columns: _ctx.columns,
      rowKey: (record) => record.id
    }, {
      trigger_name: _withCtx(({ record }) => [
        _createVNode(_component_a_button, {
          class: "table-row-name",
          type: "text"
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(record.trigger_name), 1)
          ]),
          _: 2
        }, 1024)
      ]),
      creator: _withCtx(({text}) => [
        _createVNode(_component_a_row, null, {
          default: _withCtx(() => [
            _createVNode(_component_a_typography_text, { strong: "" }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.getEmployeeName(text)), 1)
              ]),
              _: 2
            }, 1024)
          ]),
          _: 2
        }, 1024)
      ]),
      expandedRowRender: _withCtx(() => [
        _createElementVNode("div", _hoisted_3, [
          _createVNode(_component_a_button, {
            type: "primary",
            disabled: !_ctx.hasSelected,
            loading: _ctx.loading,
            onClick: _ctx.showModal
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t('SYNC_CUSTOMER_WITH_CRM')), 1)
            ]),
            _: 1
          }, 8, ["disabled", "loading", "onClick"]),
          _createVNode(_component_a_button, {
            type: "primary",
            onClick: _ctx.exportExcel
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t('EXPORT_STOCK')), 1)
            ]),
            _: 1
          }, 8, ["onClick"]),
          _createElementVNode("span", _hoisted_4, [
            (_ctx.hasSelected)
              ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                  _createTextVNode(_toDisplayString(`${_ctx.$t('datamodal_select')} ${_ctx.selectedRowKeys.length} ${_ctx.$t('CUSTOMER')}`), 1)
                ], 64))
              : _createCommentVNode("", true),
            _createVNode(_component_a_radio_group, {
              onChange: _ctx.giftChange,
              value: _ctx.giftName,
              "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.giftName) = $event))
            }, {
              default: _withCtx(() => [
                _createVNode(_component_a_radio_button, {
                  style: {"margin-right":"10px","margin-top":"10px"},
                  value: "Tất cả"
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.$t('ALL')) + " ", 1)
                  ]),
                  _: 1
                }),
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.gifts, (i) => {
                  return (_openBlock(), _createBlock(_component_a_radio_button, {
                    style: {"margin-right":"10px","margin-top":"10px"},
                    key: i.id,
                    value: i.id
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(i.name) + " ", 1)
                    ]),
                    _: 2
                  }, 1032, ["value"]))
                }), 128))
              ]),
              _: 1
            }, 8, ["onChange", "value"])
          ])
        ]),
        (_ctx.expandLoading)
          ? (_openBlock(), _createBlock(_component_a_spin, { key: 0 }))
          : (_openBlock(), _createBlock(_component_a_table, {
              key: 1,
              class: "table",
              "data-source": _ctx.viralUser,
              expandedRowKeys: _ctx.expandedRowKeys,
              columns: _ctx.columnsViral,
              loading: _ctx.expandLoading,
              rowKey: (record) => record.id,
              "row-selection": { selectedRowKeys: _ctx.selectedRowKeys, onChange: _ctx.onSelectChange },
              pagination: false
            }, {
              customer_name: _withCtx(({record}) => [
                _createVNode(_component_a_row, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_router_link, {
                      style: {"color":"#FAB216"},
                      to: `/customer-detail/${record.customer_id}`
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(record.full_name), 1)
                      ]),
                      _: 2
                    }, 1032, ["to"])
                  ]),
                  _: 2
                }, 1024),
                _createVNode(_component_a_typography_text, null, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(record.phone), 1)
                  ]),
                  _: 2
                }, 1024)
              ]),
              gift: _withCtx(({record}) => [
                _createVNode(_component_a_row, null, {
                  default: _withCtx(() => [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(record.extra_data.gifts, (i) => {
                      return (_openBlock(), _createElementBlock("div", {
                        key: i.id
                      }, [
                        _createVNode(_component_a_tooltip, null, {
                          title: _withCtx(() => [
                            _createElementVNode("span", null, _toDisplayString(i.name), 1)
                          ]),
                          default: _withCtx(() => [
                            (i.id !== 'good_luck')
                              ? (_openBlock(), _createElementBlock("img", {
                                  key: 0,
                                  style: {"margin-right":"10px"},
                                  width: "50",
                                  height: "50",
                                  src: i.image_url
                                }, null, 8, _hoisted_5))
                              : _createCommentVNode("", true)
                          ]),
                          _: 2
                        }, 1024)
                      ]))
                    }), 128))
                  ]),
                  _: 2
                }, 1024)
              ]),
              good_luck: _withCtx(({record}) => [
                _createVNode(_component_a_row, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_a_typography_text, null, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.getGoodLuck(record.extra_data.gifts)), 1)
                      ]),
                      _: 2
                    }, 1024)
                  ]),
                  _: 2
                }, 1024)
              ]),
              num_play: _withCtx(({record}) => [
                _createVNode(_component_a_row, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_a_typography_text, null, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(record.extra_data.num_play), 1)
                      ]),
                      _: 2
                    }, 1024)
                  ]),
                  _: 2
                }, 1024)
              ]),
              _: 1
            }, 8, ["data-source", "expandedRowKeys", "columns", "loading", "rowKey", "row-selection"]))
      ]),
      _: 1
    }, 8, ["onExpand", "data-source", "expandedRowKeys", "columns", "rowKey"]),
    _createVNode(_component_a_modal, {
      visible: _ctx.visible,
      "onUpdate:visible": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.visible) = $event)),
      title: _ctx.$t('SYNC_CUSTOMER_WITH_CRM'),
      onOk: _ctx.handleOk
    }, {
      default: _withCtx(() => [
        _createVNode(_component_a_form, null, {
          default: _withCtx(() => [
            _createVNode(_component_a_form_item, {
              label: _ctx.$t('CARE_STATUS'),
              name: "last_care"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_a_select, {
                  style: {"width":"100%"},
                  allowClear: "",
                  placeholder: "Please select",
                  value: _ctx.careId,
                  "onUpdate:value": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.careId) = $event))
                }, {
                  default: _withCtx(() => [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.customerCares.customerCares, (item) => {
                      return (_openBlock(), _createBlock(_component_a_select_option, {
                        key: item.id,
                        value: item.id
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_a_tag, {
                            color: item.color
                          }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(item.name), 1)
                            ]),
                            _: 2
                          }, 1032, ["color"])
                        ]),
                        _: 2
                      }, 1032, ["value"]))
                    }), 128))
                  ]),
                  _: 1
                }, 8, ["value"])
              ]),
              _: 1
            }, 8, ["label"]),
            _createVNode(_component_a_form_item, {
              label: _ctx.$t('TAG'),
              name: "tag"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_a_tree_select, {
                  onChange: _ctx.handleChangeTag,
                  "show-search": "",
                  style: {"width":"100%"},
                  onSearch: _ctx.searchTag,
                  value: _ctx.tagIds,
                  "onUpdate:value": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.tagIds) = $event)),
                  filterTreeNode: false,
                  searchValue: _ctx.searchTagValue,
                  "onUpdate:searchValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.searchTagValue) = $event)),
                  notFoundContent: null,
                  "dropdown-style": { maxHeight: '300', overflow: 'auto' },
                  "allow-clear": "",
                  multiple: "",
                  placeholder: _ctx.$t('TAG_SELECT')
                }, {
                  default: _withCtx(() => [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.treeTags.tagTree, (item) => {
                      return (_openBlock(), _createBlock(_component_a_tree_select_node, {
                        key: item.id,
                        value: item.id
                      }, {
                        title: _withCtx(() => [
                          _createVNode(_component_a_tag, {
                            color: item.color
                          }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(item.name), 1)
                            ]),
                            _: 2
                          }, 1032, ["color"])
                        ]),
                        default: _withCtx(() => [
                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(item.children, (child) => {
                            return (_openBlock(), _createBlock(_component_a_tree_select_node, {
                              key: child.id,
                              value: child.id
                            }, {
                              title: _withCtx(() => [
                                _createVNode(_component_a_tag, {
                                  color: child.color
                                }, {
                                  default: _withCtx(() => [
                                    _createTextVNode(_toDisplayString(child.name), 1)
                                  ]),
                                  _: 2
                                }, 1032, ["color"])
                              ]),
                              _: 2
                            }, 1032, ["value"]))
                          }), 128))
                        ]),
                        _: 2
                      }, 1032, ["value"]))
                    }), 128))
                  ]),
                  _: 1
                }, 8, ["onChange", "onSearch", "value", "searchValue", "placeholder"])
              ]),
              _: 1
            }, 8, ["label"])
          ]),
          _: 1
        })
      ]),
      _: 1
    }, 8, ["visible", "title", "onOk"])
  ], 64))
}