<template>
  <div class="main-container">
    <a-dropdown v-if="!isWalletFetching||wallet">
    <span class="ant-pro-account-avatar s-avatar avatar-sticker" @click.prevent>
      <wallet-outlined
          style="color: white"
          class="antd-pro-global-header-index-avatar"/>
      <span style="margin-left: 10px; color: white">{{$t('YOUR_WALLET_BALANCE_IS')}}</span>
      <span style="margin-left: 10px; color: white">{{formatMoney(wallet?.balance)  }} vnđ</span>
    </span>
      <template #overlay>
        <div class="dropdown-wallet-container">
          <router-link to="/wallet/transactions">
            <div class="wallet-background-container">
              <img src="@/assets/images/wallet_background_group.svg" class="wallet-background-group">
              <div class="wallet-label-overlay">
                <h3>
                  {{$t('MY_WALLET')}}
                </h3>
                <h1>
                  {{formatMoney( wallet?.balance) }} vnđ
                </h1>
              </div>
            </div>

          </router-link>
          <div class="list-button">
            <router-link to="/wallet/transactions">
              <a-button block class="history-transaction">
               {{$t('HISTORY_TRANSACTION')}}
              </a-button>
            </router-link>

            <a-button block class="deposit-button" type="primary">
              {{$t('DEPOSIT')}}
            </a-button>
          </div>
        </div>
      </template>
    </a-dropdown>
    <span v-else>
      <a-spin size="small" :style="{ marginLeft: 8, marginRight: 8 }"/>
    </span>
  </div>
</template>

<script lang="ts">
import {computed, defineComponent, onBeforeMount, ref} from "vue";
import {
  WalletOutlined
} from '@ant-design/icons-vue';
import {useStore} from "@/store";
import {WalletActionTypes} from "@/store/modules/wallet/action-types";
import {Wallet} from "@/models/Wallet/Wallet";

export default defineComponent({
  name: "wallet-dropdown",
  components: {
    WalletOutlined
  },
  setup() {
    const store = useStore();
    const isWalletFetching = computed((): boolean => store.getters.getWalletFetching)
    const wallet = computed((): Wallet | undefined => store.getters.getWallet)
    onBeforeMount(() => {
      store.dispatch(WalletActionTypes.GET_WALLET, undefined)
    })
    return {
      isWalletFetching,
      wallet
    }
  }
})
</script>

<style scoped lang="scss">
.main-container {
  display: flex;
}


.s-avatar {
  padding-left: 14px;
  padding-right: 14px;
  cursor: pointer;
}

.s-avatar:hover {
  background-color: rgba(0, 0, 0, .025);
}

.dropdown-wallet-container {
  border-radius: 12px 12px 4px 4px;
  background-color: white;
  box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);

  .wallet-background-container {
    cursor: pointer;

    .wallet-background-group {
      background-color: #5EDE99;
      border-radius: 12px;
      width: 280px;
    }

    .wallet-label-overlay {
      position: absolute;
      top: 20%;
      left: 18px;

      h3 {
        color: white;
      }

      h1 {
        color: white;
      }
    }
  }

  .list-button {
    padding-left: 10px;
    padding-right: 10px;
    padding-bottom: 12px;

    .history-transaction {
      margin-top: 12px;
    }

    .deposit-button {
      margin-top: 12px;
    }
  }

}

</style>
