<template>
  <template v-for="(tag, index) in tags" :key="index">
    <a-tooltip v-if="tag.length > 20" :title="tag">
      <a-tag closable @close="handleClose(tag)" style="height: 28px; padding-top: 4px; margin: 4px 8px">
        {{ `${tag.slice(0, 20)}...` }}
      </a-tag>
    </a-tooltip>
    <a-tag v-else closable @close="handleClose(tag)" style="height: 28px; padding-top: 4px; margin: 4px 8px">
      {{ tag }}
    </a-tag>
  </template>
  <a-input
      v-if="inputVisible"
      ref="inputRef"
      v-model:value="inputValue"
      type="text"
      size="small"
      :style="{ width: '78px', height: '28px', marginTop: '4px', marginBottom: '4ppx', marginLeft: '8px', marginRight: '8px' }"
      @blur="handleInputConfirm"
      @keyup.enter="handleInputConfirm"
  />
  <a-tag v-else style="background: #fff; height: 28px; padding-top: 4px; margin: 4px 8px; border-style: dashed" @click="showInput">
    <plus-outlined />
    {{$t('NEW_ATTRIBUTE')}}
  </a-tag>
</template>

<script lang="ts">
import { defineComponent, nextTick, reactive, ref, toRefs } from 'vue'
import {
  PlusOutlined,
} from "@ant-design/icons-vue";

export default defineComponent({
    name: 'Attribute',
    components: {
      PlusOutlined,
    },
    props: ['change'],
    setup(props) {
      const inputRef = ref();
      const state = reactive({
        tags: [],
        inputVisible: false,
        inputValue: '',
      });

      const handleClose = (removedTag: string) => {
        const tags = state.tags.filter(tag => tag !== removedTag);
        state.tags = tags;
        if(props.change){
          props.change(state.tags)
        }
      };

      const showInput = () => {
        state.inputVisible = true;
        nextTick(() => {
          inputRef.value.focus();
        });
      };

      const handleInputConfirm = () => {
        const inputValue: string = state.inputValue;
        let tags: string[] = state.tags;
        if (inputValue && tags.indexOf(inputValue) === -1) {
          tags = [...tags, inputValue];
        }
        Object.assign(state, {
          tags,
          inputVisible: false,
          inputValue: '',
        });
        if(props.change){
          props.change(state.tags)
        }
      };
      return {
        ...toRefs(state),
        handleClose,
        showInput,
        handleInputConfirm,
        inputRef,
      };
    },
},)
</script>

