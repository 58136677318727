import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createBlock as _createBlock, createElementVNode as _createElementVNode, withModifiers as _withModifiers, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-0b49d36e"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "main-container" }
const _hoisted_2 = { style: {"margin-left":"10px","color":"white"} }
const _hoisted_3 = ["href"]
const _hoisted_4 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_avatar = _resolveComponent("a-avatar")!
  const _component_a_menu_item = _resolveComponent("a-menu-item")!
  const _component_a_menu = _resolveComponent("a-menu")!
  const _component_a_dropdown = _resolveComponent("a-dropdown")!
  const _component_a_tooltip = _resolveComponent("a-tooltip")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_a_row = _resolveComponent("a-row")!
  const _component_a_menu_divider = _resolveComponent("a-menu-divider")!
  const _component_user_outlined = _resolveComponent("user-outlined")!
  const _component_a_button = _resolveComponent("a-button")!
  const _component_logout_outlined = _resolveComponent("logout-outlined")!
  const _component_exclamation_circle_outlined = _resolveComponent("exclamation-circle-outlined")!
  const _component_SettingOutlined = _resolveComponent("SettingOutlined")!
  const _component_swap_outlined = _resolveComponent("swap-outlined")!
  const _component_info_circle_outlined = _resolveComponent("info-circle-outlined")!
  const _component_a_card = _resolveComponent("a-card")!
  const _component_a_spin = _resolveComponent("a-spin")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_a_dropdown, {
      trigger: "click",
      title: "Choose shop you want change",
      overlayClassName: 'avatar-sticker',
      visible: _ctx.isDropdownChangeShopVisible,
      "onUpdate:visible": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.isDropdownChangeShopVisible) = $event))
    }, {
      overlay: _withCtx(() => [
        _createVNode(_component_a_menu, { onSelect: _ctx.changeShop }, {
          default: _withCtx(() => [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.shops, (shop) => {
              return (_openBlock(), _createBlock(_component_a_menu_item, {
                key: shop.id,
                onClick: ($event: any) => (_ctx.changeShop(shop.id,shop.shop_name))
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_a_avatar, {
                    size: "small",
                    src: "https://gw.alipayobjects.com/zos/antfincdn/XAosXuNZyF/BiazfanxmamNRoxxVxka.png",
                    class: "antd-pro-global-header-index-avatar"
                  }),
                  _createTextVNode(" " + _toDisplayString(shop.shop_name), 1)
                ]),
                _: 2
              }, 1032, ["onClick"]))
            }), 128))
          ]),
          _: 1
        }, 8, ["onSelect"])
      ]),
      _: 1
    }, 8, ["visible"]),
    (!_ctx.getUserLoading &&_ctx.currentShop)
      ? (_openBlock(), _createBlock(_component_a_dropdown, { key: 0 }, {
          overlay: _withCtx(() => [
            _createVNode(_component_a_card, {
              hoverable: "",
              style: {"width":"100%"}
            }, {
              default: _withCtx(() => [
                _createVNode(_component_a_menu, null, {
                  default: _withCtx(() => [
                    _createElementVNode("div", null, [
                      _createVNode(_component_a_row, null, {
                        default: _withCtx(() => [
                          _createVNode(_component_a_tooltip, null, {
                            title: _withCtx(() => [
                              _createTextVNode(_toDisplayString(_ctx.$t('copy')), 1)
                            ]),
                            default: _withCtx(() => [
                              _createVNode(_component_a_menu_item, {
                                style: {"font-weight":"bold"},
                                onClick: _ctx.copy
                              }, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(_ctx.currentShop.id), 1)
                                ]),
                                _: 1
                              }, 8, ["onClick"])
                            ]),
                            _: 1
                          }),
                          _createVNode(_component_a_menu_item, { style: {"font-weight":"bold"} }, {
                            default: _withCtx(() => [
                              _createVNode(_component_router_link, { to: "/shop_info" }, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(_ctx.currentShop.shop_name), 1)
                                ]),
                                _: 1
                              })
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      })
                    ]),
                    _createVNode(_component_a_menu_divider),
                    _createVNode(_component_a_row, {
                      justify: "space-between",
                      style: {"margin":"10px"}
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_a_button, null, {
                          icon: _withCtx(() => [
                            _createVNode(_component_user_outlined)
                          ]),
                          default: _withCtx(() => [
                            _createVNode(_component_router_link, {
                              style: {"margin-left":"5px"},
                              to: "/account"
                            }, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(_ctx.$t('ACCOUNT')), 1)
                              ]),
                              _: 1
                            })
                          ]),
                          _: 1
                        }),
                        _createVNode(_component_a_button, {
                          onClick: _ctx.handleLogout,
                          type: "primary",
                          shape: "circle",
                          danger: ""
                        }, {
                          icon: _withCtx(() => [
                            _createVNode(_component_logout_outlined)
                          ]),
                          _: 1
                        }, 8, ["onClick"])
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_a_menu_divider),
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.shops, (shop) => {
                      return (_openBlock(), _createBlock(_component_a_menu_item, {
                        key: shop.id,
                        onClick: ($event: any) => (_ctx.changeShop(shop.id,shop.shop_name))
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_a_avatar, {
                            size: "small",
                            src: "https://gw.alipayobjects.com/zos/antfincdn/XAosXuNZyF/BiazfanxmamNRoxxVxka.png",
                            class: "antd-pro-global-header-index-avatar"
                          }),
                          _createTextVNode(" " + _toDisplayString(shop.shop_name) + " ", 1),
                          _createVNode(_component_a_menu_item, {
                            onClick: _ctx.shopInfo,
                            key: "shopInfo"
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_exclamation_circle_outlined, { class: "menu-icon" }),
                              _createTextVNode(" " + _toDisplayString(_ctx.$t('SHOP_INFO')), 1)
                            ]),
                            _: 1
                          }, 8, ["onClick"]),
                          _createVNode(_component_a_menu_item, {
                            onClick: _ctx.settingPage,
                            key: "settingPage"
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_SettingOutlined, { class: "menu-icon" }),
                              _createTextVNode(" " + _toDisplayString(_ctx.$t('page_settings')), 1)
                            ]),
                            _: 1
                          }, 8, ["onClick"]),
                          _createVNode(_component_a_menu_item, {
                            key: "center",
                            onClick: [
                              _cache[2] || (_cache[2] = _withModifiers(() => {}, ["prevent"])),
                              _cache[3] || (_cache[3] = ($event: any) => (_ctx.isDropdownChangeShopVisible=true))
                            ]
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_swap_outlined, { class: "menu-icon" }),
                              _createTextVNode(" " + _toDisplayString(_ctx.$t('CHANGE_SHOP')), 1)
                            ]),
                            _: 1
                          }),
                          _createVNode(_component_a_menu_item, { key: "settings" }, {
                            default: _withCtx(() => [
                              _createVNode(_component_a_avatar, {
                                size: "small",
                                shape: "square",
                                src: _ctx.sf1Logo,
                                style: {"background-color":"#3b5999","margin-right":"8px"}
                              }, null, 8, ["src"]),
                              _createElementVNode("a", {
                                href: _ctx.getWebUrl()
                              }, _toDisplayString(_ctx.$t('REDIRECT_TO_WEB_SHOPF1')), 9, _hoisted_3)
                            ]),
                            _: 1
                          }),
                          _createVNode(_component_a_menu_divider),
                          _createVNode(_component_a_menu_item, {
                            onClick: _ctx.shopInfo,
                            key: "shopInfo"
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_info_circle_outlined, { class: "menu-icon" }),
                              _createTextVNode(" " + _toDisplayString(_ctx.$t('SHOP_INFO')), 1)
                            ]),
                            _: 1
                          }, 8, ["onClick"]),
                          _createVNode(_component_a_menu_item, {
                            onClick: _ctx.settingPage,
                            key: "settingPage"
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_SettingOutlined, { class: "menu-icon" }),
                              _createTextVNode(" " + _toDisplayString(_ctx.$t('page_settings')), 1)
                            ]),
                            _: 1
                          }, 8, ["onClick"])
                        ]),
                        _: 2
                      }, 1032, ["onClick"]))
                    }), 128))
                  ]),
                  _: 1
                })
              ]),
              _: 1
            })
          ]),
          default: _withCtx(() => [
            _createElementVNode("span", {
              class: "ant-pro-account-avatar s-avatar avatar-sticker",
              onClick: _cache[1] || (_cache[1] = _withModifiers(() => {}, ["prevent"]))
            }, [
              _createVNode(_component_a_avatar, {
                size: "small",
                src: "https://gw.alipayobjects.com/zos/antfincdn/XAosXuNZyF/BiazfanxmamNRoxxVxka.png",
                class: "antd-pro-global-header-index-avatar"
              }),
              _createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.currentShop.shop_name), 1)
            ])
          ]),
          _: 1
        }))
      : (_openBlock(), _createElementBlock("span", _hoisted_4, [
          _createVNode(_component_a_spin, {
            size: "small",
            style: { marginLeft: 8, marginRight: 8 }
          })
        ]))
  ]))
}