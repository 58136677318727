<template>
  <div class="container-title">
    <h3 class="title-header">{{ $t($route.name) }}</h3>
    <div class="input-search-customer">

    </div>
  </div>
  <a-card style="border-radius: 10px;margin: auto;width: 95%" >
    <template #title>
      <a-row justify="space-between">
        <a-typography-text strong>{{$t('VOUCHER_CODE_OF_CAMPAIGN')}}</a-typography-text>
        <a-range-picker format="DD-MM-YYYY" />
      </a-row>
    </template>
    <a-table style="width: 100%;padding: 0"  :columns="columns" :data-source="data"   >
      <template #action="{record}">
        <a-dropdown>
          <a-button type="text">
            <eye-outlined />
          </a-button>
          <template #overlay>
            <a-menu>
              <a-menu-item
                  @click="customRowClick(record)">
                <edit-outlined/>
                {{$t('EDIT')}}
              </a-menu-item>
              <a-menu-item>
                <a-popconfirm
                    :title="$t('FLOW_CONFIRM_DELETE_CONTEXT')"
                    :ok-text="$t('YES')"
                    :cancel-text="$t('NO')"
                    @confirm="deleteFlow(record)"
                >
                  <delete-outlined/>
                  {{$t('DELETE')}}
                </a-popconfirm>

              </a-menu-item>
              <a-menu-item
                  @click="duplicated(record)">
                <file-text-outlined />
                {{$t('VOUCHER_CODE_LIST')}}
              </a-menu-item>
              <a-menu-item
                  @click="duplicated(record)">
                <bar-chart-outlined />
                {{$t('RESULT')}}
              </a-menu-item>
              <a-menu-item
                  @click="duplicated(record)">
                <plus-outlined />
                {{$t('CREATE_TRIGGER')}}
              </a-menu-item>
            </a-menu>
          </template>
        </a-dropdown>
      </template>
    </a-table>
  </a-card>
</template>
<script lang="ts">
import {useI18n} from "vue-i18n";

export default {
  setup(){
    const i18t = useI18n()
    const columns = [
      {
        title: '#',
        key: 'stt',
        dataIndex: 'stt',
      },
      {
        title: i18t.t('CODE'),
        key: 'code',
        dataIndex: 'code',
        slots: {customRender: 'code'},
      },
      {
        title: i18t.t('DATE_OF_ISSUE'),
        key: 'date_of_issue',
        dataIndex: 'date_of_issue',
        slots: {customRender: 'date_of_issue'},
      },
      {
        title: i18t.t('DATE_OF_EXPIRE'),
        key: 'date_of_expire',
        dataIndex: 'date_of_expire',
        slots: {customRender: 'date_of_expire'},
      },
      {
        title: i18t.t('status'),
        key: 'promotion_type',
        dataIndex: 'promotion_type',
        slots: {customRender: 'promotion_type'},
      },
      {
        title: i18t.t('action'),
        dataIndex: 'action',
        slots: {customRender: 'action'},
      },

    ]
    const data = [{
      stt:0,
      id:123,
      campaign_name: 'mã giảm giá',
      promotion_type:'Giarm giá tổng hóa đơn',
      purpose:'kỉnh bản thay đổi loại thành viên'
    }]
    return{
      columns,
      data
    }
  }
}
</script>
