import {State} from "@/store/modules/PageConfig";
import {PageConfigMutationTypes} from "@/store/modules/PageConfig/mutation-types";
import {PageConfig} from "@/models/PageConfig/PageConfig";



export type Mutations<S = State> = {
    [PageConfigMutationTypes.GET_PAGES_CONFIG](state : S,payload: PageConfig[] | undefined):void,
    [PageConfigMutationTypes.GET_PAGE_CONFIG](state: S,payload: number |undefined):void
    [PageConfigMutationTypes.GET_TAB_CONFIG](state: S,payload: string |undefined):void


}

export const mutations : Mutations ={

    [PageConfigMutationTypes.GET_PAGES_CONFIG](state : State,payload: PageConfig[] | undefined): void{
        state.pageConfig = payload
    },
    [PageConfigMutationTypes.GET_PAGE_CONFIG](state : State,payload: number | undefined): void{
        state.pageId = payload
    },
    [PageConfigMutationTypes.GET_TAB_CONFIG](state : State,payload: string | undefined): void{
        state.tabkey = payload
    },

}
