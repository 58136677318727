import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode, createBlock as _createBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"
import _imports_0 from '@/assets/icons/zalo-seeklogo.com.svg'
import _imports_1 from '@/assets/icons/facebook-svgrepo-com.svg'
import _imports_2 from '@/assets/icons/instagram-svgrepo-com.svg'
import _imports_3 from '@/assets/icons/line-svgrepo-com.svg'
import _imports_4 from '@/assets/icons/whatsapp-svgrepo-com.svg'


const _withScopeId = n => (_pushScopeId("data-v-49431463"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "container"
}
const _hoisted_2 = { style: {"margin":"12px"} }
const _hoisted_3 = { class: "table-header" }
const _hoisted_4 = { class: "table-title" }
const _hoisted_5 = ["onClick"]
const _hoisted_6 = { class: "item-trigger-table-small" }
const _hoisted_7 = { class: "item-trigger-table-small" }
const _hoisted_8 = { key: 0 }
const _hoisted_9 = {
  key: 0,
  draggable: "false",
  src: _imports_0,
  style: {"width":"20px","margin-right":"20px"}
}
const _hoisted_10 = {
  key: 1,
  draggable: "false",
  src: _imports_1,
  style: {"width":"20px","margin-right":"20px"}
}
const _hoisted_11 = {
  key: 2,
  draggable: "false",
  src: _imports_2,
  style: {"width":"20px","margin-right":"20px"}
}
const _hoisted_12 = {
  key: 3,
  draggable: "false",
  src: _imports_3,
  style: {"width":"20px","margin-right":"20px"}
}
const _hoisted_13 = {
  key: 4,
  draggable: "false",
  src: _imports_4,
  style: {"width":"20px","margin-right":"20px"}
}
const _hoisted_14 = {
  key: 0,
  draggable: "false",
  src: _imports_0,
  style: {"width":"20px","margin-right":"20px"}
}
const _hoisted_15 = {
  key: 1,
  draggable: "false",
  src: _imports_1,
  style: {"width":"20px","margin-right":"20px"}
}
const _hoisted_16 = {
  key: 2,
  draggable: "false",
  src: _imports_2,
  style: {"width":"20px","margin-right":"20px"}
}
const _hoisted_17 = {
  key: 3,
  draggable: "false",
  src: _imports_3,
  style: {"width":"20px","margin-right":"20px"}
}
const _hoisted_18 = {
  key: 4,
  draggable: "false",
  src: _imports_4,
  style: {"width":"20px","margin-right":"20px"}
}
const _hoisted_19 = { key: 0 }
const _hoisted_20 = {
  key: 0,
  draggable: "false",
  src: _imports_0,
  style: {"width":"20px","margin-right":"20px"}
}
const _hoisted_21 = {
  key: 1,
  draggable: "false",
  src: _imports_1,
  style: {"width":"20px","margin-right":"20px"}
}
const _hoisted_22 = {
  key: 2,
  draggable: "false",
  src: _imports_2,
  style: {"width":"20px","margin-right":"20px"}
}
const _hoisted_23 = {
  key: 3,
  draggable: "false",
  src: _imports_3,
  style: {"width":"20px","margin-right":"20px"}
}
const _hoisted_24 = {
  key: 4,
  draggable: "false",
  src: _imports_4,
  style: {"width":"20px","margin-right":"20px"}
}
const _hoisted_25 = {
  key: 0,
  draggable: "false",
  src: _imports_0,
  style: {"width":"20px","margin-right":"20px"}
}
const _hoisted_26 = {
  key: 1,
  draggable: "false",
  src: _imports_1,
  style: {"width":"20px","margin-right":"20px"}
}
const _hoisted_27 = {
  key: 2,
  draggable: "false",
  src: _imports_2,
  style: {"width":"20px","margin-right":"20px"}
}
const _hoisted_28 = {
  key: 3,
  draggable: "false",
  src: _imports_3,
  style: {"width":"20px","margin-right":"20px"}
}
const _hoisted_29 = {
  key: 4,
  draggable: "false",
  src: _imports_4,
  style: {"width":"20px","margin-right":"20px"}
}
const _hoisted_30 = {
  key: 1,
  class: "condition-alert"
}
const _hoisted_31 = {
  key: 2,
  class: "row-horizontal"
}
const _hoisted_32 = {
  key: 0,
  draggable: "false",
  src: _imports_0,
  style: {"width":"20px","margin-right":"20px"}
}
const _hoisted_33 = {
  key: 1,
  draggable: "false",
  src: _imports_1,
  style: {"width":"20px","margin-right":"20px"}
}
const _hoisted_34 = {
  key: 2,
  draggable: "false",
  src: _imports_2,
  style: {"width":"20px","margin-right":"20px"}
}
const _hoisted_35 = {
  key: 3,
  draggable: "false",
  src: _imports_3,
  style: {"width":"20px","margin-right":"20px"}
}
const _hoisted_36 = {
  key: 4,
  draggable: "false",
  src: _imports_4,
  style: {"width":"20px","margin-right":"20px"}
}
const _hoisted_37 = {
  key: 0,
  class: "modal-text-warning-running-status"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_menu_item = _resolveComponent("a-menu-item")!
  const _component_a_tooltip = _resolveComponent("a-tooltip")!
  const _component_a_menu = _resolveComponent("a-menu")!
  const _component_a_layout_sider = _resolveComponent("a-layout-sider")!
  const _component_a_input_search = _resolveComponent("a-input-search")!
  const _component_a_tag = _resolveComponent("a-tag")!
  const _component_a_select_option = _resolveComponent("a-select-option")!
  const _component_a_select = _resolveComponent("a-select")!
  const _component_a_typography_text = _resolveComponent("a-typography-text")!
  const _component_plus_outlined = _resolveComponent("plus-outlined")!
  const _component_a_button = _resolveComponent("a-button")!
  const _component_eye_outlined = _resolveComponent("eye-outlined")!
  const _component_edit_outlined = _resolveComponent("edit-outlined")!
  const _component_stop_outlined = _resolveComponent("stop-outlined")!
  const _component_aim_outlined = _resolveComponent("aim-outlined")!
  const _component_history_outlined = _resolveComponent("history-outlined")!
  const _component_delete_outlined = _resolveComponent("delete-outlined")!
  const _component_a_popconfirm = _resolveComponent("a-popconfirm")!
  const _component_a_dropdown = _resolveComponent("a-dropdown")!
  const _component_a_table = _resolveComponent("a-table")!
  const _component_a_layout_content = _resolveComponent("a-layout-content")!
  const _component_a_layout = _resolveComponent("a-layout")!
  const _component_a_input = _resolveComponent("a-input")!
  const _component_a_form_item = _resolveComponent("a-form-item")!
  const _component_a_col = _resolveComponent("a-col")!
  const _component_a_row = _resolveComponent("a-row")!
  const _component_a_divider = _resolveComponent("a-divider")!
  const _component_a_radio = _resolveComponent("a-radio")!
  const _component_a_radio_group = _resolveComponent("a-radio-group")!
  const _component_a_tree_select_node = _resolveComponent("a-tree-select-node")!
  const _component_a_tree_select = _resolveComponent("a-tree-select")!
  const _component_a_range_picker = _resolveComponent("a-range-picker")!
  const _component_a_avatar = _resolveComponent("a-avatar")!
  const _component_a_timeline_item = _resolveComponent("a-timeline-item")!
  const _component_a_timeline = _resolveComponent("a-timeline")!
  const _component_a_card = _resolveComponent("a-card")!
  const _component_a_radio_button = _resolveComponent("a-radio-button")!
  const _component_a_date_picker = _resolveComponent("a-date-picker")!
  const _component_a_form = _resolveComponent("a-form")!
  const _component_a_tab_pane = _resolveComponent("a-tab-pane")!
  const _component_a_tabs = _resolveComponent("a-tabs")!
  const _component_a_modal = _resolveComponent("a-modal")!

  return (_ctx.version!=='new')
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("h3", null, _toDisplayString(_ctx.$t(_ctx.$route.name)), 1)
        ]),
        _createVNode(_component_a_layout, { theme: "light" }, {
          default: _withCtx(() => [
            _createVNode(_component_a_layout_sider, { theme: "light" }, {
              default: _withCtx(() => [
                _createVNode(_component_a_menu, {
                  mode: "inline",
                  theme: "light",
                  selectedKeys: _ctx.selectedTrigger,
                  "onUpdate:selectedKeys": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.selectedTrigger) = $event))
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_a_menu_item, {
                      key: "all",
                      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.selectTriggerType('all')))
                    }, {
                      default: _withCtx(() => [
                        _createElementVNode("span", null, _toDisplayString(_ctx.$t('all')), 1)
                      ]),
                      _: 1
                    }),
                    (_ctx.getFlowConstants&&_ctx.getFlowConstants.trigger_types)
                      ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(_ctx.getFlowConstants.trigger_types, (key) => {
                          return (_openBlock(), _createBlock(_component_a_menu_item, {
                            key: key,
                            onClick: ($event: any) => (_ctx.selectTriggerType(key))
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_a_tooltip, {
                                title: _ctx.$t(key)
                              }, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(_ctx.$t(key)), 1)
                                ]),
                                _: 2
                              }, 1032, ["title"])
                            ]),
                            _: 2
                          }, 1032, ["onClick"]))
                        }), 128))
                      : _createCommentVNode("", true)
                  ]),
                  _: 1
                }, 8, ["selectedKeys"])
              ]),
              _: 1
            }),
            _createVNode(_component_a_layout, { theme: "light" }, {
              default: _withCtx(() => [
                _createVNode(_component_a_layout_content, null, {
                  default: _withCtx(() => [
                    _createElementVNode("div", _hoisted_3, [
                      _createElementVNode("div", _hoisted_4, [
                        _createVNode(_component_a_input_search, {
                          class: "input-search",
                          placeholder: _ctx.$t('SEARCH'),
                          value: _ctx.searchInput,
                          "onUpdate:value": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.searchInput) = $event)),
                          onChange: _ctx.onSearchChange,
                          style: {"width":"220px","margin-left":"12px"}
                        }, null, 8, ["placeholder", "value", "onChange"]),
                        _createVNode(_component_a_select, {
                          showSearch: "",
                          class: "input-search",
                          allowClear: "",
                          mode: "multiple",
                          "v-mode:value": _ctx.selectedTags,
                          placeholder: _ctx.$t('TAGS'),
                          style: {"min-width":"220px","margin-left":"12px"},
                          onChange: _ctx.handleChangeTags
                        }, {
                          default: _withCtx(() => [
                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.getTriggerTags, (item) => {
                              return (_openBlock(), _createBlock(_component_a_select_option, {
                                key: item.id,
                                class: "input-search",
                                value: item.id
                              }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_a_tag, {
                                    color: item.color
                                  }, {
                                    default: _withCtx(() => [
                                      _createTextVNode(_toDisplayString(item.name), 1)
                                    ]),
                                    _: 2
                                  }, 1032, ["color"])
                                ]),
                                _: 2
                              }, 1032, ["value"]))
                            }), 128))
                          ]),
                          _: 1
                        }, 8, ["v-mode:value", "placeholder", "onChange"]),
                        _createVNode(_component_a_select, {
                          showSearch: "",
                          allowClear: "",
                          class: "input-search",
                          placeholder: _ctx.$t('status'),
                          style: {"width":"220px","margin-left":"12px"},
                          onChange: _ctx.handleChangeStatus
                        }, {
                          default: _withCtx(() => [
                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.getFlowConstants.trigger_status, (item) => {
                              return (_openBlock(), _createBlock(_component_a_select_option, {
                                key: item,
                                value: item
                              }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_a_typography_text, null, {
                                    default: _withCtx(() => [
                                      _createTextVNode(_toDisplayString(_ctx.$t(item)), 1)
                                    ]),
                                    _: 2
                                  }, 1024)
                                ]),
                                _: 2
                              }, 1032, ["value"]))
                            }), 128))
                          ]),
                          _: 1
                        }, 8, ["placeholder", "onChange"])
                      ]),
                      (_ctx.getMyRole(_ctx.MODULES.trigger)?.is_create)
                        ? (_openBlock(), _createBlock(_component_a_button, {
                            key: 0,
                            type: "primary",
                            onClick: _ctx.newTrigger
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_plus_outlined),
                              _createTextVNode(" " + _toDisplayString(_ctx.$t("TRIGGER_SETTING")), 1)
                            ]),
                            _: 1
                          }, 8, ["onClick"]))
                        : _createCommentVNode("", true)
                    ]),
                    _createVNode(_component_a_table, {
                      size: "middle",
                      rowKey: record => record.id,
                      columns: _ctx.columns,
                      "data-source": _ctx.getTriggers,
                      pagination: _ctx.pagination,
                      onChange: _ctx.handleTableChange,
                      class: "flow-table"
                    }, {
                      name: _withCtx(({ record }) => [
                        _createElementVNode("a", {
                          class: "table-row-name",
                          type: "text",
                          onClick: ($event: any) => (_ctx.customRowClick(record))
                        }, _toDisplayString(record.name), 9, _hoisted_5)
                      ]),
                      created_at: _withCtx(({ text }) => [
                        _createElementVNode("span", _hoisted_6, _toDisplayString(_ctx.dateTimeDisplayFormatFromString(text)), 1)
                      ]),
                      creator: _withCtx(({ text }) => [
                        _createElementVNode("span", null, _toDisplayString(_ctx.getEmployeeName(text)), 1)
                      ]),
                      type: _withCtx(({ text }) => [
                        _createElementVNode("span", _hoisted_7, _toDisplayString(_ctx.$t(text)), 1)
                      ]),
                      status: _withCtx(({ text }) => [
                        _createElementVNode("span", null, [
                          _createVNode(_component_a_tag, {
                            color: _ctx.getTriggerStatusColor(text)
                          }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(text), 1)
                            ]),
                            _: 2
                          }, 1032, ["color"])
                        ])
                      ]),
                      tags: _withCtx(({ record }) => [
                        _createElementVNode("span", null, [
                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(record.tags, (tag) => {
                            return (_openBlock(), _createBlock(_component_a_tag, {
                              key: tag.id+'_'+tag.name,
                              color: tag.color
                            }, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(tag.name), 1)
                              ]),
                              _: 2
                            }, 1032, ["color"]))
                          }), 128))
                        ])
                      ]),
                      action: _withCtx(({record}) => [
                        _createVNode(_component_a_dropdown, null, {
                          overlay: _withCtx(() => [
                            _createVNode(_component_a_menu, null, {
                              default: _withCtx(() => [
                                (_ctx.getMyRole(_ctx.MODULES.trigger)?.is_edit)
                                  ? (_openBlock(), _createBlock(_component_a_menu_item, {
                                      key: 0,
                                      onClick: ($event: any) => (_ctx.customRowClick(record))
                                    }, {
                                      default: _withCtx(() => [
                                        _createVNode(_component_edit_outlined),
                                        _createTextVNode(" " + _toDisplayString(_ctx.$t('EDIT')), 1)
                                      ]),
                                      _: 2
                                    }, 1032, ["onClick"]))
                                  : _createCommentVNode("", true),
                                (record.status==='running' && _ctx.getMyRole(_ctx.MODULES.trigger)?.is_edit)
                                  ? (_openBlock(), _createBlock(_component_a_menu_item, {
                                      key: 1,
                                      onClick: ($event: any) => (_ctx.stopTriggerModal(record))
                                    }, {
                                      default: _withCtx(() => [
                                        _createVNode(_component_stop_outlined),
                                        _createTextVNode(" " + _toDisplayString(_ctx.$t('STOP_TRIGGER')), 1)
                                      ]),
                                      _: 2
                                    }, 1032, ["onClick"]))
                                  : _createCommentVNode("", true),
                                (record.status!=='running' && _ctx.getMyRole(_ctx.MODULES.trigger)?.is_edit)
                                  ? (_openBlock(), _createBlock(_component_a_menu_item, {
                                      key: 2,
                                      onClick: ($event: any) => (_ctx.activateTriggerModal(record))
                                    }, {
                                      default: _withCtx(() => [
                                        _createVNode(_component_aim_outlined),
                                        _createTextVNode(" " + _toDisplayString(_ctx.$t('Activate_trigger')), 1)
                                      ]),
                                      _: 2
                                    }, 1032, ["onClick"]))
                                  : _createCommentVNode("", true),
                                (_ctx.getMyRole(_ctx.MODULES.trigger)?.is_edit)
                                  ? (_openBlock(), _createBlock(_component_a_menu_item, {
                                      key: 3,
                                      onClick: ($event: any) => (_ctx.editFlow(record))
                                    }, {
                                      default: _withCtx(() => [
                                        _createVNode(_component_edit_outlined),
                                        _createTextVNode(" " + _toDisplayString(_ctx.$t('EDIT_FLOW')), 1)
                                      ]),
                                      _: 2
                                    }, 1032, ["onClick"]))
                                  : _createCommentVNode("", true),
                                (_ctx.getMyRole(_ctx.MODULES.trigger_history)?.is_edit)
                                  ? (_openBlock(), _createBlock(_component_a_menu_item, {
                                      key: 4,
                                      onClick: ($event: any) => (_ctx.goHistory(record))
                                    }, {
                                      default: _withCtx(() => [
                                        _createVNode(_component_history_outlined),
                                        _createTextVNode(" " + _toDisplayString(_ctx.$t('HISTORY')), 1)
                                      ]),
                                      _: 2
                                    }, 1032, ["onClick"]))
                                  : _createCommentVNode("", true),
                                (_ctx.getMyRole(_ctx.MODULES.trigger)?.is_delete)
                                  ? (_openBlock(), _createBlock(_component_a_menu_item, { key: 5 }, {
                                      default: _withCtx(() => [
                                        _createVNode(_component_a_popconfirm, {
                                          title: _ctx.$t('TRIGGER_CONFIRM_DELETE_CONTEXT'),
                                          "ok-text": _ctx.$t('YES'),
                                          "cancel-text": _ctx.$t('NO'),
                                          onConfirm: ($event: any) => (_ctx.deleteTrigger(record))
                                        }, {
                                          default: _withCtx(() => [
                                            _createVNode(_component_delete_outlined),
                                            _createTextVNode(" " + _toDisplayString(_ctx.$t('DELETE')), 1)
                                          ]),
                                          _: 2
                                        }, 1032, ["title", "ok-text", "cancel-text", "onConfirm"])
                                      ]),
                                      _: 2
                                    }, 1024))
                                  : _createCommentVNode("", true)
                              ]),
                              _: 2
                            }, 1024)
                          ]),
                          default: _withCtx(() => [
                            _createVNode(_component_a_button, { type: "text" }, {
                              default: _withCtx(() => [
                                _createVNode(_component_eye_outlined)
                              ]),
                              _: 1
                            })
                          ]),
                          _: 2
                        }, 1024)
                      ]),
                      _: 1
                    }, 8, ["rowKey", "columns", "data-source", "pagination", "onChange"])
                  ]),
                  _: 1
                })
              ]),
              _: 1
            })
          ]),
          _: 1
        }),
        _createVNode(_component_a_modal, {
          visible: _ctx.modalCreateVisible,
          "onUpdate:visible": _cache[25] || (_cache[25] = ($event: any) => ((_ctx.modalCreateVisible) = $event)),
          title: _ctx.$t('TRIGGER_SETTING'),
          width: "90%"
        }, {
          footer: _withCtx(() => [
            (_ctx.formState.status==='running')
              ? (_openBlock(), _createElementBlock("span", _hoisted_37, _toDisplayString(_ctx.$t('TRIGGER_WARNING_TRIGGER_IS_RUNNING')), 1))
              : _createCommentVNode("", true),
            _createVNode(_component_a_button, { onClick: _ctx.hideModal }, {
              default: _withCtx(() => [
                _createTextVNode("Cancel")
              ]),
              _: 1
            }, 8, ["onClick"]),
            _createVNode(_component_a_button, {
              type: "primary",
              onClick: _ctx.onSubmit,
              loading: _ctx.confirmTriggerLoading,
              disabled: _ctx.formState.status==='running'
            }, {
              default: _withCtx(() => [
                _createTextVNode(" Ok ")
              ]),
              _: 1
            }, 8, ["onClick", "loading", "disabled"])
          ]),
          default: _withCtx(() => [
            _createVNode(_component_a_tabs, {
              type: "card",
              activeKey: _ctx.tabModalEditKey,
              "onUpdate:activeKey": _cache[24] || (_cache[24] = ($event: any) => ((_ctx.tabModalEditKey) = $event))
            }, {
              default: _withCtx(() => [
                _createVNode(_component_a_tab_pane, {
                  key: "1",
                  tab: _ctx.$t('SETTING')
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_a_form, {
                      model: _ctx.formState,
                      ref: "formRef",
                      rules: _ctx.rules,
                      "label-col": {span: 6,offset:0},
                      "wrapper-col": { span: 16 }
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_a_form_item, {
                          label: _ctx.$t('TRIGGER_TITLE'),
                          name: "name"
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_a_input, {
                              onBlur: _ctx.titleBlur,
                              ref: "triggerTitle",
                              value: _ctx.formState.name,
                              "onUpdate:value": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.formState.name) = $event)),
                              placeholder: _ctx.$t('TRIGGER_TITLE')
                            }, null, 8, ["onBlur", "value", "placeholder"])
                          ]),
                          _: 1
                        }, 8, ["label"]),
                        _createVNode(_component_a_form_item, {
                          name: "type",
                          label: _ctx.$t('TRIGGER_TYPE')
                        }, {
                          default: _withCtx(() => [
                            (_ctx.formState.type ==='BULK_MESSAGE' )
                              ? (_openBlock(), _createBlock(_component_a_row, { key: 0 }, {
                                  default: _withCtx(() => [
                                    _createVNode(_component_a_col, { span: 12 }, {
                                      default: _withCtx(() => [
                                        _createVNode(_component_a_select, {
                                          ref: "select",
                                          value: _ctx.formState.type,
                                          "onUpdate:value": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.formState.type) = $event)),
                                          placeholder: _ctx.$t('TRIGGER_TYPE')
                                        }, {
                                          default: _withCtx(() => [
                                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.getFlowConstants.trigger_types, (item) => {
                                              return (_openBlock(), _createBlock(_component_a_select_option, {
                                                key: item,
                                                value: item
                                              }, {
                                                default: _withCtx(() => [
                                                  _createTextVNode(_toDisplayString(_ctx.$t(item)), 1)
                                                ]),
                                                _: 2
                                              }, 1032, ["value"]))
                                            }), 128))
                                          ]),
                                          _: 1
                                        }, 8, ["value", "placeholder"])
                                      ]),
                                      _: 1
                                    }),
                                    _createVNode(_component_a_col, { span: 2 }),
                                    _createVNode(_component_a_col, { span: 10 }, {
                                      default: _withCtx(() => [
                                        _createVNode(_component_a_button, {
                                          onClick: _cache[5] || (_cache[5] = ($event: any) => (_ctx.getAmountSent(_ctx.formState.id)))
                                        }, {
                                          default: _withCtx(() => [
                                            _createTextVNode(_toDisplayString(_ctx.$t('AMOUNT_SENT')) + " ", 1),
                                            _createVNode(_component_a_tag, {
                                              color: "pink",
                                              style: {"cursor":"pointer","margin-left":"10px"}
                                            }, {
                                              default: _withCtx(() => [
                                                _createTextVNode(_toDisplayString(_ctx.amountSent), 1)
                                              ]),
                                              _: 1
                                            })
                                          ]),
                                          _: 1
                                        })
                                      ]),
                                      _: 1
                                    })
                                  ]),
                                  _: 1
                                }))
                              : (_openBlock(), _createBlock(_component_a_select, {
                                  key: 1,
                                  ref: "triggerType",
                                  value: _ctx.formState.type,
                                  "onUpdate:value": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.formState.type) = $event)),
                                  onChange: _ctx.handleChangeType,
                                  placeholder: _ctx.$t('TRIGGER_TYPE')
                                }, {
                                  default: _withCtx(() => [
                                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.getFlowConstants.trigger_types, (item) => {
                                      return (_openBlock(), _createBlock(_component_a_select_option, {
                                        key: item,
                                        value: item
                                      }, {
                                        default: _withCtx(() => [
                                          _createTextVNode(_toDisplayString(_ctx.$t(item)), 1)
                                        ]),
                                        _: 2
                                      }, 1032, ["value"]))
                                    }), 128))
                                  ]),
                                  _: 1
                                }, 8, ["value", "onChange", "placeholder"]))
                          ]),
                          _: 1
                        }, 8, ["label"]),
                        _createVNode(_component_a_form_item, {
                          name: "root_flow_id",
                          label: _ctx.formState.type==='AUTO_REPLY_COMMENT_AND_SEND_PRIVATE_MESSAGE'?_ctx.$t('PRIVATE_REPLY_FLOW'):_ctx.$t('TRIGGER_SELECT_FLOWS')
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_a_select, {
                              ref: "flows",
                              "show-search": "",
                              onSearch: _ctx.flowHandleSearch,
                              options: _ctx.getFlowsSelectionAntWithoutAll,
                              "default-active-first-option": false,
                              "show-arrow": false,
                              onChange: _ctx.selectFlowChange,
                              "filter-option": false,
                              "not-found-content": null,
                              value: _ctx.formState.root_flow_id,
                              "onUpdate:value": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.formState.root_flow_id) = $event)),
                              placeholder: _ctx.$t('TRIGGER_SELECT_FLOWS')
                            }, null, 8, ["onSearch", "options", "onChange", "value", "placeholder"])
                          ]),
                          _: 1
                        }, 8, ["label"]),
                        _createVNode(_component_a_row, { class: "horizontal-form" }, {
                          default: _withCtx(() => [
                            _createVNode(_component_a_col, { span: 12 }, {
                              default: _withCtx(() => [
                                _createVNode(_component_a_form_item, {
                                  name: "tags",
                                  label: _ctx.$t('TRIGGER_TAG')
                                }, {
                                  default: _withCtx(() => [
                                    _createVNode(_component_a_select, {
                                      ref: "triggerTag",
                                      mode: "tags",
                                      value: _ctx.formState.tags,
                                      "onUpdate:value": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.formState.tags) = $event)),
                                      placeholder: _ctx.$t('TRIGGER_TAG'),
                                      onChange: _ctx.triggerTag
                                    }, {
                                      default: _withCtx(() => [
                                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.getTriggerTags, (item) => {
                                          return (_openBlock(), _createBlock(_component_a_select_option, {
                                            key: item.id,
                                            value: item.id
                                          }, {
                                            default: _withCtx(() => [
                                              _createTextVNode(_toDisplayString(item.name), 1)
                                            ]),
                                            _: 2
                                          }, 1032, ["value"]))
                                        }), 128))
                                      ]),
                                      _: 1
                                    }, 8, ["value", "placeholder", "onChange"])
                                  ]),
                                  _: 1
                                }, 8, ["label"])
                              ]),
                              _: 1
                            }),
                            _createVNode(_component_a_col, { span: 12 }, {
                              default: _withCtx(() => [
                                _createVNode(_component_a_form_item, {
                                  name: "tags",
                                  label: _ctx.$t('SELECT_WALLET')
                                }, {
                                  default: _withCtx(() => [
                                    _createVNode(_component_a_select, {
                                      mode: "single",
                                      ref: "wallet",
                                      onChange: _ctx.handleSelectWallet,
                                      value: _ctx.formState.user_wallet_id,
                                      "onUpdate:value": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.formState.user_wallet_id) = $event)),
                                      loading: _ctx.selectWalletLoading,
                                      placeholder: _ctx.$t('SELECT_WALLET')
                                    }, {
                                      default: _withCtx(() => [
                                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.getAdminAndShopOwner, (item) => {
                                          return (_openBlock(), _createBlock(_component_a_select_option, {
                                            key: item.id,
                                            value: item.id
                                          }, {
                                            default: _withCtx(() => [
                                              _createTextVNode(_toDisplayString(item.name), 1)
                                            ]),
                                            _: 2
                                          }, 1032, ["value"]))
                                        }), 128))
                                      ]),
                                      _: 1
                                    }, 8, ["onChange", "value", "loading", "placeholder"])
                                  ]),
                                  _: 1
                                }, 8, ["label"])
                              ]),
                              _: 1
                            })
                          ]),
                          _: 1
                        }),
                        _createVNode(_component_a_divider),
                        _createVNode(_component_a_row, null, {
                          default: _withCtx(() => [
                            _createVNode(_component_a_col, { span: 12 }, {
                              default: _withCtx(() => [
                                _createVNode(_component_a_form_item, {
                                  name: ['customer_condition','condition'],
                                  rules: {
        required: true,
        message: 'Please select one condition type',
        trigger: 'change',
      },
                                  label: _ctx.$t('TRIGGER_CONDITIONS'),
                                  "label-col": {span: 6,offset:0},
                                  "wrapper-col": { span: 12 }
                                }, {
                                  default: _withCtx(() => [
                                    _createVNode(_component_a_radio_group, {
                                      onChange: _ctx.triggerCondition,
                                      value: _ctx.formState.customer_condition.condition,
                                      "onUpdate:value": _cache[10] || (_cache[10] = ($event: any) => ((_ctx.formState.customer_condition.condition) = $event))
                                    }, {
                                      default: _withCtx(() => [
                                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.getFlowConstants.trigger_condition.trigger_conditions, (item) => {
                                          return (_openBlock(), _createBlock(_component_a_radio, {
                                            key: item,
                                            value: item
                                          }, {
                                            default: _withCtx(() => [
                                              _createTextVNode(_toDisplayString(_ctx.$t(item)), 1)
                                            ]),
                                            _: 2
                                          }, 1032, ["value"]))
                                        }), 128))
                                      ]),
                                      _: 1
                                    }, 8, ["onChange", "value"])
                                  ]),
                                  _: 1
                                }, 8, ["label"])
                              ]),
                              _: 1
                            }),
                            _createVNode(_component_a_col, null, {
                              default: _withCtx(() => [
                                _createVNode(_component_a_row, { style: {"width":"100%","justify-content":"flex-end"} }, {
                                  default: _withCtx(() => [
                                    _createVNode(_component_a_tag, {
                                      style: {"margin":"10px","cursor":"pointer"},
                                      type: "dashed",
                                      block: "",
                                      onClick: _ctx.addParentCondition
                                    }, {
                                      default: _withCtx(() => [
                                        _createVNode(_component_plus_outlined),
                                        _createTextVNode(" " + _toDisplayString(_ctx.$t("TRIGGER_CONDITIONS")), 1)
                                      ]),
                                      _: 1
                                    }, 8, ["onClick"]),
                                    _createVNode(_component_a_tag, {
                                      onClick: _ctx.addGroupCondition,
                                      style: {"margin":"10px","cursor":"pointer"},
                                      type: "dashed"
                                    }, {
                                      default: _withCtx(() => [
                                        _createTextVNode(_toDisplayString(_ctx.$t('CONDITION_GROUP')), 1)
                                      ]),
                                      _: 1
                                    }, 8, ["onClick"])
                                  ]),
                                  _: 1
                                })
                              ]),
                              _: 1
                            })
                          ]),
                          _: 1
                        }),
                        (_ctx.formState.customer_condition.trigger_comparisons&&_ctx.formState.customer_condition.trigger_comparisons.length)
                          ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
                              _createVNode(_component_a_timeline, null, {
                                default: _withCtx(() => [
                                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.formState.customer_condition.trigger_comparisons, (item, index) => {
                                    return (_openBlock(), _createBlock(_component_a_timeline_item, {
                                      style: {"padding-bottom":"0"},
                                      key: item.condition+'_'+index
                                    }, {
                                      default: _withCtx(() => [
                                        (item.condition)
                                          ? (_openBlock(), _createBlock(_component_a_row, {
                                              key: 0,
                                              class: "row-full-width"
                                            }, {
                                              default: _withCtx(() => [
                                                _createVNode(_component_a_col, {
                                                  span: 4,
                                                  style: {"padding":"4px"}
                                                }, {
                                                  default: _withCtx(() => [
                                                    _createVNode(_component_a_select, {
                                                      ref_for: true,
                                                      ref: "select",
                                                      style: {"width":"100%"},
                                                      value: item.field_type,
                                                      "onUpdate:value": ($event: any) => ((item.field_type) = $event),
                                                      placeholder: _ctx.$t('select_condition_field'),
                                                      onChange: _ctx.handleChangeFieldType
                                                    }, {
                                                      default: _withCtx(() => [
                                                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.getFlowConstants.trigger_condition.trigger_condition_field_types, (it) => {
                                                          return (_openBlock(), _createBlock(_component_a_select_option, {
                                                            key: it,
                                                            value: it
                                                          }, {
                                                            default: _withCtx(() => [
                                                              _createTextVNode(_toDisplayString(_ctx.$t(it)), 1)
                                                            ]),
                                                            _: 2
                                                          }, 1032, ["value"]))
                                                        }), 128)),
                                                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.getCustomerConfig, (i) => {
                                                          return (_openBlock(), _createBlock(_component_a_select_option, {
                                                            key: i.id,
                                                            value: i.identified_name
                                                          }, {
                                                            default: _withCtx(() => [
                                                              _createTextVNode(_toDisplayString(i.name), 1)
                                                            ]),
                                                            _: 2
                                                          }, 1032, ["value"]))
                                                        }), 128))
                                                      ]),
                                                      _: 2
                                                    }, 1032, ["value", "onUpdate:value", "placeholder", "onChange"])
                                                  ]),
                                                  _: 2
                                                }, 1024),
                                                _createVNode(_component_a_col, {
                                                  span: 4,
                                                  style: {"padding":"4px"}
                                                }, {
                                                  default: _withCtx(() => [
                                                    _createVNode(_component_a_select, {
                                                      ref_for: true,
                                                      ref: "select",
                                                      style: {"width":"100%"},
                                                      value: item.condition,
                                                      "onUpdate:value": ($event: any) => ((item.condition) = $event),
                                                      placeholder: _ctx.$t('select_comparison_condition')
                                                    }, {
                                                      default: _withCtx(() => [
                                                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.getCondition(_ctx.getFlowConstants.trigger_condition.trigger_comparison_condition,item.field_type,index), (it) => {
                                                          return (_openBlock(), _createBlock(_component_a_select_option, {
                                                            key: it+'_2'+index,
                                                            value: it
                                                          }, {
                                                            default: _withCtx(() => [
                                                              _createTextVNode(_toDisplayString(_ctx.$t(it)), 1)
                                                            ]),
                                                            _: 2
                                                          }, 1032, ["value"]))
                                                        }), 128))
                                                      ]),
                                                      _: 2
                                                    }, 1032, ["value", "onUpdate:value", "placeholder"])
                                                  ]),
                                                  _: 2
                                                }, 1024),
                                                _createVNode(_component_a_col, {
                                                  span: 11,
                                                  style: {"padding":"4px"}
                                                }, {
                                                  default: _withCtx(() => [
                                                    (item.field_type==='tag')
                                                      ? (_openBlock(), _createBlock(_component_a_tree_select, {
                                                          key: 0,
                                                          onChange: _ctx.handleChangeTagModal,
                                                          "show-search": "",
                                                          style: {"width":"100%"},
                                                          onSearch: _ctx.searchTag,
                                                          filterTreeNode: false,
                                                          searchValue: _ctx.searchTagValue,
                                                          "onUpdate:searchValue": _cache[11] || (_cache[11] = ($event: any) => ((_ctx.searchTagValue) = $event)),
                                                          value: item.value,
                                                          "onUpdate:value": ($event: any) => ((item.value) = $event),
                                                          notFoundContent: null,
                                                          "dropdown-style": { maxHeight: '400px', overflow: 'auto' },
                                                          "allow-clear": "",
                                                          placeholder: _ctx.$t('TAG_SELECT')
                                                        }, {
                                                          default: _withCtx(() => [
                                                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.getTreeTags, (item) => {
                                                              return (_openBlock(), _createBlock(_component_a_tree_select_node, {
                                                                key: item.id,
                                                                value: item.id+''
                                                              }, {
                                                                title: _withCtx(() => [
                                                                  _createVNode(_component_a_tag, {
                                                                    color: item.color
                                                                  }, {
                                                                    default: _withCtx(() => [
                                                                      _createTextVNode(_toDisplayString(item.name), 1)
                                                                    ]),
                                                                    _: 2
                                                                  }, 1032, ["color"])
                                                                ]),
                                                                default: _withCtx(() => [
                                                                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(item.children, (child) => {
                                                                    return (_openBlock(), _createBlock(_component_a_tree_select_node, {
                                                                      key: child.id,
                                                                      value: child.id+''
                                                                    }, {
                                                                      title: _withCtx(() => [
                                                                        _createVNode(_component_a_tag, {
                                                                          color: child.color
                                                                        }, {
                                                                          default: _withCtx(() => [
                                                                            _createTextVNode(_toDisplayString(child.name), 1)
                                                                          ]),
                                                                          _: 2
                                                                        }, 1032, ["color"])
                                                                      ]),
                                                                      _: 2
                                                                    }, 1032, ["value"]))
                                                                  }), 128))
                                                                ]),
                                                                _: 2
                                                              }, 1032, ["value"]))
                                                            }), 128))
                                                          ]),
                                                          _: 2
                                                        }, 1032, ["onChange", "onSearch", "searchValue", "value", "onUpdate:value", "placeholder"]))
                                                      : _createCommentVNode("", true),
                                                    (item.field_type==='INT' || item.field_type === 'CURRENCY')
                                                      ? (_openBlock(), _createBlock(_component_a_input, {
                                                          key: 1,
                                                          style: {"width":"100%"},
                                                          value: item.value,
                                                          "onUpdate:value": ($event: any) => ((item.value) = $event)
                                                        }, null, 8, ["value", "onUpdate:value"]))
                                                      : _createCommentVNode("", true),
                                                    (item.field_type==='STRING' )
                                                      ? (_openBlock(), _createBlock(_component_a_input, {
                                                          key: 2,
                                                          style: {"width":"100%"},
                                                          value: item.value,
                                                          "onUpdate:value": ($event: any) => ((item.value) = $event)
                                                        }, null, 8, ["value", "onUpdate:value"]))
                                                      : _createCommentVNode("", true),
                                                    (item.field_type === 'DATE')
                                                      ? (_openBlock(), _createBlock(_component_a_range_picker, {
                                                          key: 3,
                                                          style: {"width":"100%"},
                                                          format: "DD-MM-YYYY",
                                                          valueFormat: 'YYYY-MM-DDTHH:mm:ssZ',
                                                          value: item.value,
                                                          "onUpdate:value": ($event: any) => ((item.value) = $event)
                                                        }, null, 8, ["value", "onUpdate:value"]))
                                                      : _createCommentVNode("", true),
                                                    (item.field_type==='page_id')
                                                      ? (_openBlock(), _createBlock(_component_a_select, {
                                                          key: 4,
                                                          style: {"width":"100%"},
                                                          "show-search": "",
                                                          "filter-option": _ctx.filterOption2,
                                                          placeholder: _ctx.$t('SELECT_PAGE'),
                                                          value: item.value,
                                                          "onUpdate:value": ($event: any) => ((item.value) = $event)
                                                        }, {
                                                          default: _withCtx(() => [
                                                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.getPages, (it) => {
                                                              return (_openBlock(), _createBlock(_component_a_select_option, {
                                                                key: it,
                                                                value: it.page_id+''
                                                              }, {
                                                                default: _withCtx(() => [
                                                                  (it.page_type === 2)
                                                                    ? (_openBlock(), _createElementBlock("img", _hoisted_9))
                                                                    : (it.page_type === 1)
                                                                      ? (_openBlock(), _createElementBlock("img", _hoisted_10))
                                                                      : (it.page_type === 3)
                                                                        ? (_openBlock(), _createElementBlock("img", _hoisted_11))
                                                                        : (it.page_type === 4)
                                                                          ? (_openBlock(), _createElementBlock("img", _hoisted_12))
                                                                          : (it.page_type === 6)
                                                                            ? (_openBlock(), _createElementBlock("img", _hoisted_13))
                                                                            : _createCommentVNode("", true),
                                                                  _createTextVNode(_toDisplayString(it.page_name), 1)
                                                                ]),
                                                                _: 2
                                                              }, 1032, ["value"]))
                                                            }), 128))
                                                          ]),
                                                          _: 2
                                                        }, 1032, ["filter-option", "placeholder", "value", "onUpdate:value"]))
                                                      : _createCommentVNode("", true),
                                                    (item.field_type==='messenger_window_24h')
                                                      ? (_openBlock(), _createBlock(_component_a_select, {
                                                          key: 5,
                                                          style: {"width":"100%"},
                                                          placeholder: _ctx.$t('MESSAGE'),
                                                          value: item.value,
                                                          "onUpdate:value": ($event: any) => ((item.value) = $event)
                                                        }, {
                                                          default: _withCtx(() => [
                                                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.getFlowConstants.trigger_condition_messenger_window_24_h_field_types, (it) => {
                                                              return (_openBlock(), _createBlock(_component_a_select_option, {
                                                                key: it,
                                                                value: "it"
                                                              }, {
                                                                default: _withCtx(() => [
                                                                  _createTextVNode(_toDisplayString(it), 1)
                                                                ]),
                                                                _: 2
                                                              }, 1024))
                                                            }), 128))
                                                          ]),
                                                          _: 2
                                                        }, 1032, ["placeholder", "value", "onUpdate:value"]))
                                                      : _createCommentVNode("", true),
                                                    (item.field_type==='conversation_tag')
                                                      ? (_openBlock(), _createBlock(_component_a_tree_select, {
                                                          key: 6,
                                                          "show-search": "",
                                                          style: {"width":"100%"},
                                                          value: item.coverstion_tag,
                                                          "onUpdate:value": ($event: any) => ((item.coverstion_tag) = $event),
                                                          filterTreeNode: false,
                                                          onSearch: _ctx.searchTags,
                                                          notFoundContent: null,
                                                          "dropdown-style": { maxHeight: '300', overflow: 'auto' },
                                                          "allow-clear": "",
                                                          multiple: "",
                                                          placeholder: _ctx.$t('TAG_SELECT')
                                                        }, {
                                                          default: _withCtx(() => [
                                                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.getConverstionTag, (converstion) => {
                                                              return (_openBlock(), _createBlock(_component_a_tree_select_node, {
                                                                disabled: "",
                                                                key: converstion.page_id,
                                                                value: converstion.page_id
                                                              }, {
                                                                title: _withCtx(() => [
                                                                  _createVNode(_component_a_tag, {
                                                                    color: converstion.color
                                                                  }, {
                                                                    default: _withCtx(() => [
                                                                      (converstion.page_type === 2)
                                                                        ? (_openBlock(), _createElementBlock("img", _hoisted_14))
                                                                        : (converstion.page_type === 1)
                                                                          ? (_openBlock(), _createElementBlock("img", _hoisted_15))
                                                                          : (converstion.page_type === 3)
                                                                            ? (_openBlock(), _createElementBlock("img", _hoisted_16))
                                                                            : (converstion.page_type === 4)
                                                                              ? (_openBlock(), _createElementBlock("img", _hoisted_17))
                                                                              : (converstion.page_type === 6)
                                                                                ? (_openBlock(), _createElementBlock("img", _hoisted_18))
                                                                                : _createCommentVNode("", true),
                                                                      _createTextVNode(" " + _toDisplayString(converstion.page_name), 1)
                                                                    ]),
                                                                    _: 2
                                                                  }, 1032, ["color"])
                                                                ]),
                                                                default: _withCtx(() => [
                                                                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(converstion.tags, (treeData) => {
                                                                    return (_openBlock(), _createBlock(_component_a_tree_select_node, {
                                                                      disabled: item.coverstion_tag?_ctx.getConvertionTangId(item.coverstion_tag,converstion.page_id).includes(treeData.tag_id)  ? true :false:false ,
                                                                      key: converstion.page_id + '_'+treeData.tag_id,
                                                                      value: converstion.page_id + '_'+treeData.tag_id
                                                                    }, {
                                                                      title: _withCtx(() => [
                                                                        _createVNode(_component_a_tag, {
                                                                          color: treeData.tag_color
                                                                        }, {
                                                                          default: _withCtx(() => [
                                                                            _createTextVNode(_toDisplayString(treeData.tag_name), 1)
                                                                          ]),
                                                                          _: 2
                                                                        }, 1032, ["color"])
                                                                      ]),
                                                                      _: 2
                                                                    }, 1032, ["disabled", "value"]))
                                                                  }), 128))
                                                                ]),
                                                                _: 2
                                                              }, 1032, ["value"]))
                                                            }), 128))
                                                          ]),
                                                          _: 2
                                                        }, 1032, ["value", "onUpdate:value", "onSearch", "placeholder"]))
                                                      : _createCommentVNode("", true),
                                                    (item.field_type==='post_id')
                                                      ? (_openBlock(), _createBlock(_component_a_select, {
                                                          key: 7,
                                                          style: {"width":"100%"},
                                                          placeholder: "Select post",
                                                          "show-search": "",
                                                          onChange: _ctx.searchPost,
                                                          onSearch: _ctx.searchPost,
                                                          "default-active-first-option": false,
                                                          "show-arrow": false,
                                                          "filter-option": false,
                                                          "not-found-content": null,
                                                          value: item.value,
                                                          "onUpdate:value": ($event: any) => ((item.value) = $event)
                                                        }, {
                                                          default: _withCtx(() => [
                                                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.posts, (post) => {
                                                              return (_openBlock(), _createBlock(_component_a_select_option, {
                                                                key: post.id,
                                                                value: post.id
                                                              }, {
                                                                default: _withCtx(() => [
                                                                  _createVNode(_component_a_avatar, {
                                                                    shape: "square",
                                                                    src: _ctx.getPostPicture(post)
                                                                  }, null, 8, ["src"]),
                                                                  _createTextVNode(" " + _toDisplayString(_ctx.getPostName(post)), 1)
                                                                ]),
                                                                _: 2
                                                              }, 1032, ["value"]))
                                                            }), 128))
                                                          ]),
                                                          _: 2
                                                        }, 1032, ["onChange", "onSearch", "value", "onUpdate:value"]))
                                                      : _createCommentVNode("", true),
                                                    (item.field_type==='care_status')
                                                      ? (_openBlock(), _createBlock(_component_a_select, {
                                                          key: 8,
                                                          style: {"width":"100%"},
                                                          placeholder: "Select status",
                                                          "show-search": "",
                                                          "default-active-first-option": false,
                                                          "show-arrow": false,
                                                          "filter-option": false,
                                                          "not-found-content": null,
                                                          value: item.value,
                                                          "onUpdate:value": ($event: any) => ((item.value) = $event)
                                                        }, {
                                                          default: _withCtx(() => [
                                                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.tags, (tag) => {
                                                              return (_openBlock(), _createBlock(_component_a_select_option, {
                                                                key: tag.id,
                                                                value: tag.id+''
                                                              }, {
                                                                default: _withCtx(() => [
                                                                  _createVNode(_component_a_tag, {
                                                                    color: tag.color
                                                                  }, {
                                                                    default: _withCtx(() => [
                                                                      _createTextVNode(_toDisplayString(tag.name), 1)
                                                                    ]),
                                                                    _: 2
                                                                  }, 1032, ["color"])
                                                                ]),
                                                                _: 2
                                                              }, 1032, ["value"]))
                                                            }), 128))
                                                          ]),
                                                          _: 2
                                                        }, 1032, ["value", "onUpdate:value"]))
                                                      : _createCommentVNode("", true),
                                                    (item.field_type==='order_status')
                                                      ? (_openBlock(), _createBlock(_component_a_select, {
                                                          key: 9,
                                                          style: {"width":"100%"},
                                                          placeholder: "Select status",
                                                          "show-search": "",
                                                          "default-active-first-option": false,
                                                          "show-arrow": false,
                                                          "filter-option": false,
                                                          "not-found-content": null,
                                                          value: item.value,
                                                          "onUpdate:value": ($event: any) => ((item.value) = $event)
                                                        }, {
                                                          default: _withCtx(() => [
                                                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.orderStatus, (i) => {
                                                              return (_openBlock(), _createBlock(_component_a_select_option, {
                                                                key: i.id,
                                                                value: i.id+''
                                                              }, {
                                                                default: _withCtx(() => [
                                                                  _createVNode(_component_a_tag, {
                                                                    color: _ctx.getOrderStatusColor(i.id)
                                                                  }, {
                                                                    default: _withCtx(() => [
                                                                      _createTextVNode(_toDisplayString(_ctx.$t(i.name)), 1)
                                                                    ]),
                                                                    _: 2
                                                                  }, 1032, ["color"])
                                                                ]),
                                                                _: 2
                                                              }, 1032, ["value"]))
                                                            }), 128))
                                                          ]),
                                                          _: 2
                                                        }, 1032, ["value", "onUpdate:value"]))
                                                      : _createCommentVNode("", true),
                                                    (item.field_type==='order_price')
                                                      ? (_openBlock(), _createBlock(_component_a_input, {
                                                          key: 10,
                                                          value: item.value,
                                                          "onUpdate:value": ($event: any) => ((item.value) = $event)
                                                        }, null, 8, ["value", "onUpdate:value"]))
                                                      : _createCommentVNode("", true),
                                                    (item.field_type==='total_order_money')
                                                      ? (_openBlock(), _createBlock(_component_a_input, {
                                                          key: 11,
                                                          value: item.value,
                                                          "onUpdate:value": ($event: any) => ((item.value) = $event)
                                                        }, null, 8, ["value", "onUpdate:value"]))
                                                      : _createCommentVNode("", true),
                                                    (item.field_type==='product_category_id')
                                                      ? (_openBlock(), _createBlock(_component_a_select, {
                                                          key: 12,
                                                          style: {"width":"100%"},
                                                          placeholder: "Select status",
                                                          "show-search": "",
                                                          "default-active-first-option": false,
                                                          "show-arrow": false,
                                                          "filter-option": false,
                                                          "not-found-content": null,
                                                          value: item.value,
                                                          "onUpdate:value": ($event: any) => ((item.value) = $event)
                                                        }, {
                                                          default: _withCtx(() => [
                                                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.getProductCategories, (i) => {
                                                              return (_openBlock(), _createBlock(_component_a_select_option, {
                                                                key: i.id,
                                                                value: i.id+''
                                                              }, {
                                                                default: _withCtx(() => [
                                                                  _createTextVNode(_toDisplayString(_ctx.$t(i.category_name)), 1)
                                                                ]),
                                                                _: 2
                                                              }, 1032, ["value"]))
                                                            }), 128))
                                                          ]),
                                                          _: 2
                                                        }, 1032, ["value", "onUpdate:value"]))
                                                      : _createCommentVNode("", true),
                                                    (item.field_type==='last_contact_time')
                                                      ? (_openBlock(), _createBlock(_component_a_range_picker, {
                                                          key: 13,
                                                          value: item.value,
                                                          "onUpdate:value": ($event: any) => ((item.value) = $event),
                                                          format: "DD-MM-YYYY",
                                                          valueFormat: 'YYYY-MM-DDTHH:mm:ssZ'
                                                        }, null, 8, ["value", "onUpdate:value"]))
                                                      : _createCommentVNode("", true),
                                                    (item.field_type==='first_contact_time')
                                                      ? (_openBlock(), _createBlock(_component_a_range_picker, {
                                                          key: 14,
                                                          value: item.value,
                                                          "onUpdate:value": ($event: any) => ((item.value) = $event),
                                                          format: "DD-MM-YYYY",
                                                          valueFormat: 'YYYY-MM-DDTHH:mm:ssZ'
                                                        }, null, 8, ["value", "onUpdate:value"]))
                                                      : _createCommentVNode("", true),
                                                    (item.field_type==='last_order_created')
                                                      ? (_openBlock(), _createBlock(_component_a_select, {
                                                          key: 15,
                                                          style: {"width":"100%"},
                                                          "show-search": "",
                                                          placeholder: "Select Timeout",
                                                          "default-active-first-option": false,
                                                          "show-arrow": false,
                                                          "not-found-content": null,
                                                          "filter-option": _ctx.filterOption,
                                                          value: item.value,
                                                          "onUpdate:value": ($event: any) => ((item.value) = $event)
                                                        }, {
                                                          default: _withCtx(() => [
                                                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.timeoutSelections, (time) => {
                                                              return (_openBlock(), _createBlock(_component_a_select_option, {
                                                                key: time,
                                                                value: time.value
                                                              }, {
                                                                default: _withCtx(() => [
                                                                  _createTextVNode(_toDisplayString(time.label), 1)
                                                                ]),
                                                                _: 2
                                                              }, 1032, ["value"]))
                                                            }), 128))
                                                          ]),
                                                          _: 2
                                                        }, 1032, ["filter-option", "value", "onUpdate:value"]))
                                                      : _createCommentVNode("", true),
                                                    (item.field_type==='timeout')
                                                      ? (_openBlock(), _createBlock(_component_a_select, {
                                                          key: 16,
                                                          style: {"width":"100%"},
                                                          "show-search": "",
                                                          placeholder: "Select Timeout",
                                                          "default-active-first-option": false,
                                                          "show-arrow": false,
                                                          "not-found-content": null,
                                                          "filter-option": _ctx.filterOption,
                                                          value: item.value,
                                                          "onUpdate:value": ($event: any) => ((item.value) = $event)
                                                        }, {
                                                          default: _withCtx(() => [
                                                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.timeoutSelections, (time) => {
                                                              return (_openBlock(), _createBlock(_component_a_select_option, {
                                                                key: time,
                                                                value: time.value
                                                              }, {
                                                                default: _withCtx(() => [
                                                                  _createTextVNode(_toDisplayString(time.label), 1)
                                                                ]),
                                                                _: 2
                                                              }, 1032, ["value"]))
                                                            }), 128))
                                                          ]),
                                                          _: 2
                                                        }, 1032, ["filter-option", "value", "onUpdate:value"]))
                                                      : _createCommentVNode("", true),
                                                    (item.field_type==='key_word')
                                                      ? (_openBlock(), _createBlock(_component_a_select, {
                                                          key: 17,
                                                          value: item.value,
                                                          "onUpdate:value": ($event: any) => ((item.value) = $event),
                                                          mode: "tags",
                                                          style: {"width":"100%"},
                                                          placeholder: "You key word"
                                                        }, {
                                                          default: _withCtx(() => [
                                                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.options, (i) => {
                                                              return (_openBlock(), _createBlock(_component_a_select_option, {
                                                                key: i,
                                                                value: i.value
                                                              }, {
                                                                default: _withCtx(() => [
                                                                  _createTextVNode(_toDisplayString(_ctx.$t(i.value)), 1)
                                                                ]),
                                                                _: 2
                                                              }, 1032, ["value"]))
                                                            }), 128))
                                                          ]),
                                                          _: 2
                                                        }, 1032, ["value", "onUpdate:value"]))
                                                      : _createCommentVNode("", true)
                                                  ]),
                                                  _: 2
                                                }, 1024),
                                                _createVNode(_component_a_col, {
                                                  span: 3,
                                                  style: {"padding":"4px","text-align":"center"}
                                                }, {
                                                  default: _withCtx(() => [
                                                    _createVNode(_component_delete_outlined, {
                                                      onClick: ($event: any) => (_ctx.deleteCustomerConditionRow(index))
                                                    }, null, 8, ["onClick"])
                                                  ]),
                                                  _: 2
                                                }, 1024)
                                              ]),
                                              _: 2
                                            }, 1024))
                                          : (_openBlock(), _createBlock(_component_a_card, {
                                              key: 1,
                                              style: {"width":"90%","margin-top":"10px"}
                                            }, {
                                              default: _withCtx(() => [
                                                _createVNode(_component_a_row, null, {
                                                  default: _withCtx(() => [
                                                    _createVNode(_component_a_col, { span: 11 }, {
                                                      default: _withCtx(() => [
                                                        _createVNode(_component_a_form_item, {
                                                          style: {"margin-bottom":"10px"},
                                                          name: ['customer_condition','condition'],
                                                          rules: {
        required: true,
        message: 'Please select one condition type',
        trigger: 'change',
      },
                                                          "label-col": {span: 12,offset:0},
                                                          "wrapper-col": { span: 12 }
                                                        }, {
                                                          default: _withCtx(() => [
                                                            _createVNode(_component_a_radio_group, {
                                                              value: item.trigger_condition.condition,
                                                              "onUpdate:value": ($event: any) => ((item.trigger_condition.condition) = $event)
                                                            }, {
                                                              default: _withCtx(() => [
                                                                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.getFlowConstants.trigger_condition.trigger_conditions, (item) => {
                                                                  return (_openBlock(), _createBlock(_component_a_radio, {
                                                                    key: item,
                                                                    value: item
                                                                  }, {
                                                                    default: _withCtx(() => [
                                                                      _createTextVNode(_toDisplayString(_ctx.$t(item)), 1)
                                                                    ]),
                                                                    _: 2
                                                                  }, 1032, ["value"]))
                                                                }), 128))
                                                              ]),
                                                              _: 2
                                                            }, 1032, ["value", "onUpdate:value"])
                                                          ]),
                                                          _: 2
                                                        }, 1024)
                                                      ]),
                                                      _: 2
                                                    }, 1024),
                                                    _createVNode(_component_a_col, { span: 11 }, {
                                                      default: _withCtx(() => [
                                                        _createVNode(_component_a_row, { style: {"width":"100%","margin-bottom":"10px","justify-content":"flex-end"} }, {
                                                          default: _withCtx(() => [
                                                            _createVNode(_component_a_tag, {
                                                              style: {"margin-right":"10px","cursor":"pointer"},
                                                              type: "dashed",
                                                              block: "",
                                                              onClick: ($event: any) => (_ctx.addChildrenCondition(index))
                                                            }, {
                                                              default: _withCtx(() => [
                                                                _createVNode(_component_plus_outlined),
                                                                _createTextVNode(" " + _toDisplayString(_ctx.$t("TRIGGER_CONDITIONS")), 1)
                                                              ]),
                                                              _: 2
                                                            }, 1032, ["onClick"])
                                                          ]),
                                                          _: 2
                                                        }, 1024)
                                                      ]),
                                                      _: 2
                                                    }, 1024),
                                                    _createVNode(_component_a_col, { span: 1 }),
                                                    _createVNode(_component_a_col, { span: 1 }, {
                                                      default: _withCtx(() => [
                                                        _createVNode(_component_delete_outlined, {
                                                          onClick: ($event: any) => (_ctx.deleteCustomerConditionRow(index))
                                                        }, null, 8, ["onClick"])
                                                      ]),
                                                      _: 2
                                                    }, 1024)
                                                  ]),
                                                  _: 2
                                                }, 1024),
                                                (_ctx.formState.customer_condition.trigger_comparisons&&_ctx.formState.customer_condition.trigger_comparisons.length)
                                                  ? (_openBlock(), _createElementBlock("div", _hoisted_19, [
                                                      _createVNode(_component_a_timeline, { style: {"margin-top":"10px"} }, {
                                                        default: _withCtx(() => [
                                                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.formState.customer_condition.trigger_comparisons[index].trigger_condition.trigger_comparisons, (i, idex) => {
                                                            return (_openBlock(), _createBlock(_component_a_timeline_item, {
                                                              style: {"padding-bottom":"0"},
                                                              key: i.condition+'_'+idex
                                                            }, {
                                                              default: _withCtx(() => [
                                                                _createVNode(_component_a_row, { class: "row-full-width" }, {
                                                                  default: _withCtx(() => [
                                                                    _createVNode(_component_a_col, {
                                                                      span: 7,
                                                                      style: {"padding":"4px"}
                                                                    }, {
                                                                      default: _withCtx(() => [
                                                                        _createVNode(_component_a_select, {
                                                                          ref_for: true,
                                                                          ref: "select",
                                                                          style: {"width":"100%"},
                                                                          value: i.field_type,
                                                                          "onUpdate:value": ($event: any) => ((i.field_type) = $event),
                                                                          placeholder: _ctx.$t('select_condition_field')
                                                                        }, {
                                                                          default: _withCtx(() => [
                                                                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.getFlowConstants.trigger_condition.trigger_condition_field_types, (it) => {
                                                                              return (_openBlock(), _createBlock(_component_a_select_option, {
                                                                                key: it,
                                                                                value: it
                                                                              }, {
                                                                                default: _withCtx(() => [
                                                                                  _createTextVNode(_toDisplayString(_ctx.$t(it)), 1)
                                                                                ]),
                                                                                _: 2
                                                                              }, 1032, ["value"]))
                                                                            }), 128))
                                                                          ]),
                                                                          _: 2
                                                                        }, 1032, ["value", "onUpdate:value", "placeholder"])
                                                                      ]),
                                                                      _: 2
                                                                    }, 1024),
                                                                    _createVNode(_component_a_col, {
                                                                      span: 7,
                                                                      style: {"padding":"4px"}
                                                                    }, {
                                                                      default: _withCtx(() => [
                                                                        _createVNode(_component_a_select, {
                                                                          ref_for: true,
                                                                          ref: "select",
                                                                          style: {"width":"100%"},
                                                                          value: i.condition,
                                                                          "onUpdate:value": ($event: any) => ((i.condition) = $event),
                                                                          placeholder: _ctx.$t('select_comparison_condition')
                                                                        }, {
                                                                          default: _withCtx(() => [
                                                                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.getCondition(_ctx.getFlowConstants.trigger_condition.trigger_comparison_condition,i.field_type,index,idex), (it) => {
                                                                              return (_openBlock(), _createBlock(_component_a_select_option, {
                                                                                key: it+'_2'+index,
                                                                                value: it
                                                                              }, {
                                                                                default: _withCtx(() => [
                                                                                  _createTextVNode(_toDisplayString(_ctx.$t(it)), 1)
                                                                                ]),
                                                                                _: 2
                                                                              }, 1032, ["value"]))
                                                                            }), 128))
                                                                          ]),
                                                                          _: 2
                                                                        }, 1032, ["value", "onUpdate:value", "placeholder"])
                                                                      ]),
                                                                      _: 2
                                                                    }, 1024),
                                                                    _createVNode(_component_a_col, {
                                                                      span: 7,
                                                                      style: {"padding":"4px"}
                                                                    }, {
                                                                      default: _withCtx(() => [
                                                                        (i.field_type==='tag')
                                                                          ? (_openBlock(), _createBlock(_component_a_tree_select, {
                                                                              key: 0,
                                                                              onChange: _ctx.handleChangeTagModal,
                                                                              "show-search": "",
                                                                              style: {"width":"100%"},
                                                                              onSearch: _ctx.searchTag,
                                                                              filterTreeNode: false,
                                                                              searchValue: _ctx.searchTagValue,
                                                                              "onUpdate:searchValue": _cache[12] || (_cache[12] = ($event: any) => ((_ctx.searchTagValue) = $event)),
                                                                              value: i.value,
                                                                              "onUpdate:value": ($event: any) => ((i.value) = $event),
                                                                              notFoundContent: null,
                                                                              "dropdown-style": { maxHeight: '400px', overflow: 'auto' },
                                                                              "allow-clear": "",
                                                                              placeholder: _ctx.$t('TAG_SELECT')
                                                                            }, {
                                                                              default: _withCtx(() => [
                                                                                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.getTreeTags, (item) => {
                                                                                  return (_openBlock(), _createBlock(_component_a_tree_select_node, {
                                                                                    key: item.id,
                                                                                    value: item.id+''
                                                                                  }, {
                                                                                    title: _withCtx(() => [
                                                                                      _createVNode(_component_a_tag, {
                                                                                        color: item.color
                                                                                      }, {
                                                                                        default: _withCtx(() => [
                                                                                          _createTextVNode(_toDisplayString(item.name), 1)
                                                                                        ]),
                                                                                        _: 2
                                                                                      }, 1032, ["color"])
                                                                                    ]),
                                                                                    default: _withCtx(() => [
                                                                                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(item.children, (child) => {
                                                                                        return (_openBlock(), _createBlock(_component_a_tree_select_node, {
                                                                                          key: child.id,
                                                                                          value: child.id+''
                                                                                        }, {
                                                                                          title: _withCtx(() => [
                                                                                            _createVNode(_component_a_tag, {
                                                                                              color: child.color
                                                                                            }, {
                                                                                              default: _withCtx(() => [
                                                                                                _createTextVNode(_toDisplayString(child.name), 1)
                                                                                              ]),
                                                                                              _: 2
                                                                                            }, 1032, ["color"])
                                                                                          ]),
                                                                                          _: 2
                                                                                        }, 1032, ["value"]))
                                                                                      }), 128))
                                                                                    ]),
                                                                                    _: 2
                                                                                  }, 1032, ["value"]))
                                                                                }), 128))
                                                                              ]),
                                                                              _: 2
                                                                            }, 1032, ["onChange", "onSearch", "searchValue", "value", "onUpdate:value", "placeholder"]))
                                                                          : _createCommentVNode("", true),
                                                                        (i.field_type==='page_id')
                                                                          ? (_openBlock(), _createBlock(_component_a_select, {
                                                                              key: 1,
                                                                              style: {"width":"100%"},
                                                                              "show-search": "",
                                                                              "filter-option": _ctx.filterOption2,
                                                                              placeholder: _ctx.$t('SELECT_PAGE'),
                                                                              value: i.value,
                                                                              "onUpdate:value": ($event: any) => ((i.value) = $event)
                                                                            }, {
                                                                              default: _withCtx(() => [
                                                                                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.getPages, (it) => {
                                                                                  return (_openBlock(), _createBlock(_component_a_select_option, {
                                                                                    key: it,
                                                                                    value: it.page_id+''
                                                                                  }, {
                                                                                    default: _withCtx(() => [
                                                                                      (it.page_type === 2)
                                                                                        ? (_openBlock(), _createElementBlock("img", _hoisted_20))
                                                                                        : (it.page_type === 1)
                                                                                          ? (_openBlock(), _createElementBlock("img", _hoisted_21))
                                                                                          : (it.page_type === 3)
                                                                                            ? (_openBlock(), _createElementBlock("img", _hoisted_22))
                                                                                            : (it.page_type === 4)
                                                                                              ? (_openBlock(), _createElementBlock("img", _hoisted_23))
                                                                                              : (it.page_type === 6)
                                                                                                ? (_openBlock(), _createElementBlock("img", _hoisted_24))
                                                                                                : _createCommentVNode("", true),
                                                                                      _createTextVNode(_toDisplayString(it.page_name), 1)
                                                                                    ]),
                                                                                    _: 2
                                                                                  }, 1032, ["value"]))
                                                                                }), 128))
                                                                              ]),
                                                                              _: 2
                                                                            }, 1032, ["filter-option", "placeholder", "value", "onUpdate:value"]))
                                                                          : _createCommentVNode("", true),
                                                                        (i.field_type==='messenger_window_24h')
                                                                          ? (_openBlock(), _createBlock(_component_a_select, {
                                                                              key: 2,
                                                                              style: {"width":"100%"},
                                                                              placeholder: _ctx.$t('MESSAGE'),
                                                                              value: i.value,
                                                                              "onUpdate:value": ($event: any) => ((i.value) = $event)
                                                                            }, {
                                                                              default: _withCtx(() => [
                                                                                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.getFlowConstants.trigger_condition_messenger_window_24_h_field_types, (it) => {
                                                                                  return (_openBlock(), _createBlock(_component_a_select_option, {
                                                                                    key: it,
                                                                                    value: "it"
                                                                                  }, {
                                                                                    default: _withCtx(() => [
                                                                                      _createTextVNode(_toDisplayString(it), 1)
                                                                                    ]),
                                                                                    _: 2
                                                                                  }, 1024))
                                                                                }), 128))
                                                                              ]),
                                                                              _: 2
                                                                            }, 1032, ["placeholder", "value", "onUpdate:value"]))
                                                                          : _createCommentVNode("", true),
                                                                        (i.field_type==='conversation_tag')
                                                                          ? (_openBlock(), _createBlock(_component_a_tree_select, {
                                                                              key: 3,
                                                                              "show-search": "",
                                                                              style: {"width":"100%"},
                                                                              value: i.coverstion_tag,
                                                                              "onUpdate:value": ($event: any) => ((i.coverstion_tag) = $event),
                                                                              filterTreeNode: false,
                                                                              onSearch: _ctx.searchTags,
                                                                              notFoundContent: null,
                                                                              "dropdown-style": { maxHeight: '300', overflow: 'auto' },
                                                                              "allow-clear": "",
                                                                              multiple: "",
                                                                              placeholder: _ctx.$t('TAG_SELECT')
                                                                            }, {
                                                                              default: _withCtx(() => [
                                                                                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.getConverstionTag, (converstion) => {
                                                                                  return (_openBlock(), _createBlock(_component_a_tree_select_node, {
                                                                                    disabled: "",
                                                                                    key: converstion.page_id,
                                                                                    value: converstion.page_id
                                                                                  }, {
                                                                                    title: _withCtx(() => [
                                                                                      _createVNode(_component_a_tag, {
                                                                                        color: converstion.color
                                                                                      }, {
                                                                                        default: _withCtx(() => [
                                                                                          (converstion.page_type === 2)
                                                                                            ? (_openBlock(), _createElementBlock("img", _hoisted_25))
                                                                                            : (converstion.page_type === 1)
                                                                                              ? (_openBlock(), _createElementBlock("img", _hoisted_26))
                                                                                              : (converstion.page_type === 3)
                                                                                                ? (_openBlock(), _createElementBlock("img", _hoisted_27))
                                                                                                : (converstion.page_type === 4)
                                                                                                  ? (_openBlock(), _createElementBlock("img", _hoisted_28))
                                                                                                  : (converstion.page_type === 6)
                                                                                                    ? (_openBlock(), _createElementBlock("img", _hoisted_29))
                                                                                                    : _createCommentVNode("", true),
                                                                                          _createTextVNode(" " + _toDisplayString(converstion.page_name), 1)
                                                                                        ]),
                                                                                        _: 2
                                                                                      }, 1032, ["color"])
                                                                                    ]),
                                                                                    default: _withCtx(() => [
                                                                                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(converstion.tags, (treeData) => {
                                                                                        return (_openBlock(), _createBlock(_component_a_tree_select_node, {
                                                                                          disabled: i.coverstion_tag?_ctx.getConvertionTangId(i.coverstion_tag,converstion.page_id).includes(treeData.tag_id)  ? true :false:false ,
                                                                                          key: converstion.page_id + '_'+treeData.tag_id,
                                                                                          value: converstion.page_id + '_'+treeData.tag_id
                                                                                        }, {
                                                                                          title: _withCtx(() => [
                                                                                            _createVNode(_component_a_tag, {
                                                                                              color: treeData.tag_color
                                                                                            }, {
                                                                                              default: _withCtx(() => [
                                                                                                _createTextVNode(_toDisplayString(treeData.tag_name), 1)
                                                                                              ]),
                                                                                              _: 2
                                                                                            }, 1032, ["color"])
                                                                                          ]),
                                                                                          _: 2
                                                                                        }, 1032, ["disabled", "value"]))
                                                                                      }), 128))
                                                                                    ]),
                                                                                    _: 2
                                                                                  }, 1032, ["value"]))
                                                                                }), 128))
                                                                              ]),
                                                                              _: 2
                                                                            }, 1032, ["value", "onUpdate:value", "onSearch", "placeholder"]))
                                                                          : _createCommentVNode("", true),
                                                                        (i.field_type==='post_id')
                                                                          ? (_openBlock(), _createBlock(_component_a_select, {
                                                                              key: 4,
                                                                              style: {"width":"100%"},
                                                                              placeholder: "Select post",
                                                                              "show-search": "",
                                                                              onChange: _ctx.searchPost,
                                                                              onSearch: _ctx.searchPost,
                                                                              "default-active-first-option": false,
                                                                              "show-arrow": false,
                                                                              "filter-option": false,
                                                                              "not-found-content": null,
                                                                              value: i.value,
                                                                              "onUpdate:value": ($event: any) => ((i.value) = $event)
                                                                            }, {
                                                                              default: _withCtx(() => [
                                                                                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.posts, (post) => {
                                                                                  return (_openBlock(), _createBlock(_component_a_select_option, {
                                                                                    key: post.id,
                                                                                    value: post.id
                                                                                  }, {
                                                                                    default: _withCtx(() => [
                                                                                      _createVNode(_component_a_avatar, {
                                                                                        shape: "square",
                                                                                        src: _ctx.getPostPicture(post)
                                                                                      }, null, 8, ["src"]),
                                                                                      _createTextVNode(" " + _toDisplayString(_ctx.getPostName(post)), 1)
                                                                                    ]),
                                                                                    _: 2
                                                                                  }, 1032, ["value"]))
                                                                                }), 128))
                                                                              ]),
                                                                              _: 2
                                                                            }, 1032, ["onChange", "onSearch", "value", "onUpdate:value"]))
                                                                          : _createCommentVNode("", true),
                                                                        (i.field_type==='care_status')
                                                                          ? (_openBlock(), _createBlock(_component_a_select, {
                                                                              key: 5,
                                                                              style: {"width":"100%"},
                                                                              placeholder: "Select status",
                                                                              "show-search": "",
                                                                              "default-active-first-option": false,
                                                                              "show-arrow": false,
                                                                              "filter-option": false,
                                                                              "not-found-content": null,
                                                                              value: i.value,
                                                                              "onUpdate:value": ($event: any) => ((i.value) = $event)
                                                                            }, {
                                                                              default: _withCtx(() => [
                                                                                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.tags, (tag) => {
                                                                                  return (_openBlock(), _createBlock(_component_a_select_option, {
                                                                                    key: tag.id,
                                                                                    value: tag.id+''
                                                                                  }, {
                                                                                    default: _withCtx(() => [
                                                                                      _createVNode(_component_a_tag, {
                                                                                        color: tag.color
                                                                                      }, {
                                                                                        default: _withCtx(() => [
                                                                                          _createTextVNode(_toDisplayString(tag.name), 1)
                                                                                        ]),
                                                                                        _: 2
                                                                                      }, 1032, ["color"])
                                                                                    ]),
                                                                                    _: 2
                                                                                  }, 1032, ["value"]))
                                                                                }), 128))
                                                                              ]),
                                                                              _: 2
                                                                            }, 1032, ["value", "onUpdate:value"]))
                                                                          : _createCommentVNode("", true),
                                                                        (i.field_type==='order_status')
                                                                          ? (_openBlock(), _createBlock(_component_a_select, {
                                                                              key: 6,
                                                                              style: {"width":"100%"},
                                                                              placeholder: "Select status",
                                                                              "show-search": "",
                                                                              "default-active-first-option": false,
                                                                              "show-arrow": false,
                                                                              "filter-option": false,
                                                                              "not-found-content": null,
                                                                              value: i.value,
                                                                              "onUpdate:value": ($event: any) => ((i.value) = $event)
                                                                            }, {
                                                                              default: _withCtx(() => [
                                                                                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.orderStatus, (i) => {
                                                                                  return (_openBlock(), _createBlock(_component_a_select_option, {
                                                                                    key: i.id,
                                                                                    value: i.id+''
                                                                                  }, {
                                                                                    default: _withCtx(() => [
                                                                                      _createVNode(_component_a_tag, {
                                                                                        color: _ctx.getOrderStatusColor(i.id)
                                                                                      }, {
                                                                                        default: _withCtx(() => [
                                                                                          _createTextVNode(_toDisplayString(_ctx.$t(i.name)), 1)
                                                                                        ]),
                                                                                        _: 2
                                                                                      }, 1032, ["color"])
                                                                                    ]),
                                                                                    _: 2
                                                                                  }, 1032, ["value"]))
                                                                                }), 128))
                                                                              ]),
                                                                              _: 2
                                                                            }, 1032, ["value", "onUpdate:value"]))
                                                                          : _createCommentVNode("", true),
                                                                        (i.field_type==='last_contact_time')
                                                                          ? (_openBlock(), _createBlock(_component_a_range_picker, {
                                                                              key: 7,
                                                                              value: i.value,
                                                                              "onUpdate:value": ($event: any) => ((i.value) = $event),
                                                                              format: "DD-MM-YYYY",
                                                                              valueFormat: 'YYYY-MM-DDTHH:mm:ssZ'
                                                                            }, null, 8, ["value", "onUpdate:value"]))
                                                                          : _createCommentVNode("", true),
                                                                        (i.field_type==='first_contact_time')
                                                                          ? (_openBlock(), _createBlock(_component_a_range_picker, {
                                                                              key: 8,
                                                                              value: i.value,
                                                                              "onUpdate:value": ($event: any) => ((i.value) = $event),
                                                                              format: "DD-MM-YYYY",
                                                                              valueFormat: 'YYYY-MM-DDTHH:mm:ssZ'
                                                                            }, null, 8, ["value", "onUpdate:value"]))
                                                                          : _createCommentVNode("", true),
                                                                        (i.field_type==='timeout')
                                                                          ? (_openBlock(), _createBlock(_component_a_select, {
                                                                              key: 9,
                                                                              style: {"width":"100%"},
                                                                              "show-search": "",
                                                                              placeholder: "Select Timeout",
                                                                              "default-active-first-option": false,
                                                                              "show-arrow": false,
                                                                              "not-found-content": null,
                                                                              "filter-option": _ctx.filterOption,
                                                                              value: i.value,
                                                                              "onUpdate:value": ($event: any) => ((i.value) = $event)
                                                                            }, {
                                                                              default: _withCtx(() => [
                                                                                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.timeoutSelections, (time) => {
                                                                                  return (_openBlock(), _createBlock(_component_a_select_option, {
                                                                                    key: time,
                                                                                    value: time.value
                                                                                  }, {
                                                                                    default: _withCtx(() => [
                                                                                      _createTextVNode(_toDisplayString(time.label), 1)
                                                                                    ]),
                                                                                    _: 2
                                                                                  }, 1032, ["value"]))
                                                                                }), 128))
                                                                              ]),
                                                                              _: 2
                                                                            }, 1032, ["filter-option", "value", "onUpdate:value"]))
                                                                          : _createCommentVNode("", true),
                                                                        (i.field_type==='key_word')
                                                                          ? (_openBlock(), _createBlock(_component_a_select, {
                                                                              key: 10,
                                                                              value: i.value,
                                                                              "onUpdate:value": ($event: any) => ((i.value) = $event),
                                                                              mode: "tags",
                                                                              style: {"width":"100%"},
                                                                              placeholder: "You key word"
                                                                            }, {
                                                                              default: _withCtx(() => [
                                                                                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.options2, (i) => {
                                                                                  return (_openBlock(), _createBlock(_component_a_select_option, {
                                                                                    key: i,
                                                                                    value: i.value
                                                                                  }, {
                                                                                    default: _withCtx(() => [
                                                                                      _createTextVNode(_toDisplayString(_ctx.$t(i.value)), 1)
                                                                                    ]),
                                                                                    _: 2
                                                                                  }, 1032, ["value"]))
                                                                                }), 128))
                                                                              ]),
                                                                              _: 2
                                                                            }, 1032, ["value", "onUpdate:value"]))
                                                                          : _createCommentVNode("", true)
                                                                      ]),
                                                                      _: 2
                                                                    }, 1024),
                                                                    _createVNode(_component_a_col, {
                                                                      span: 3,
                                                                      style: {"padding":"4px","text-align":"center"}
                                                                    }, {
                                                                      default: _withCtx(() => [
                                                                        _createVNode(_component_delete_outlined, {
                                                                          onClick: ($event: any) => (_ctx.deleteCustomerConditionChidrenRow(idex,index))
                                                                        }, null, 8, ["onClick"])
                                                                      ]),
                                                                      _: 2
                                                                    }, 1024)
                                                                  ]),
                                                                  _: 2
                                                                }, 1024)
                                                              ]),
                                                              _: 2
                                                            }, 1024))
                                                          }), 128))
                                                        ]),
                                                        _: 2
                                                      }, 1024)
                                                    ]))
                                                  : _createCommentVNode("", true)
                                              ]),
                                              _: 2
                                            }, 1024))
                                      ]),
                                      _: 2
                                    }, 1024))
                                  }), 128))
                                ]),
                                _: 1
                              })
                            ]))
                          : _createCommentVNode("", true),
                        (!_ctx.rules.trigger_condition.isValid)
                          ? (_openBlock(), _createElementBlock("span", _hoisted_30, _toDisplayString(_ctx.rules.trigger_condition.message), 1))
                          : _createCommentVNode("", true),
                        (_ctx.formState.type==='BULK_MESSAGE')
                          ? (_openBlock(), _createElementBlock("div", _hoisted_31, [
                              _createVNode(_component_a_form_item, {
                                "label-col": {span: 0,offset:0},
                                colon: false,
                                name: "frequency_type",
                                class: "column-fix-1",
                                rules: {
        required: true,
        message: _ctx.$t('TRIGGER_VALIDATE_FREQUENCY_TYPE'),
        trigger: 'change',
      },
                                "wrapper-col": { span: 24 }
                              }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_a_radio_group, {
                                    value: _ctx.formState.frequency_type,
                                    "onUpdate:value": _cache[13] || (_cache[13] = ($event: any) => ((_ctx.formState.frequency_type) = $event)),
                                    "button-style": "solid"
                                  }, {
                                    default: _withCtx(() => [
                                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.getFlowConstants.trigger_frequency_types, (item) => {
                                        return (_openBlock(), _createBlock(_component_a_radio_button, {
                                          key: item,
                                          value: item
                                        }, {
                                          default: _withCtx(() => [
                                            _createTextVNode(_toDisplayString(_ctx.$t(item)), 1)
                                          ]),
                                          _: 2
                                        }, 1032, ["value"]))
                                      }), 128))
                                    ]),
                                    _: 1
                                  }, 8, ["value"])
                                ]),
                                _: 1
                              }, 8, ["rules"]),
                              (_ctx.formState.frequency_type !== 'now')
                                ? (_openBlock(), _createBlock(_component_a_form_item, {
                                    key: 0,
                                    "label-col": {span: 0,offset:0},
                                    colon: false,
                                    name: "time_start_m",
                                    rules: {

        required: true,
        message: _ctx.$t('TRIGGER_VALIDATE_START_TIME'),
        trigger: 'change',
      },
                                    "wrapper-col": { span: 24 }
                                  }, {
                                    default: _withCtx(() => [
                                      _createVNode(_component_a_date_picker, {
                                        value: _ctx.formState.time_start_m,
                                        "onUpdate:value": _cache[14] || (_cache[14] = ($event: any) => ((_ctx.formState.time_start_m) = $event)),
                                        "show-time": "",
                                        placeholder: "Select Date Time",
                                        format: 'DD-MM-YYYY HH:mm'
                                      }, null, 8, ["value"])
                                    ]),
                                    _: 1
                                  }, 8, ["rules"]))
                                : _createCommentVNode("", true)
                            ]))
                          : _createCommentVNode("", true)
                      ]),
                      _: 1
                    }, 8, ["model", "rules"])
                  ]),
                  _: 1
                }, 8, ["tab"]),
                _createVNode(_component_a_tab_pane, {
                  key: "2",
                  tab: _ctx.$t('CONFIGURATION')
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_a_form, { model: _ctx.formConfig }, {
                      default: _withCtx(() => [
                        (_ctx.formConfig.hasMail)
                          ? (_openBlock(), _createBlock(_component_a_form_item, {
                              key: 0,
                              name: "type",
                              label: _ctx.$t('CONFIG_SEND_MAIL')
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_a_select, {
                                  ref: "select",
                                  value: _ctx.formConfig.selectedPage,
                                  "onUpdate:value": _cache[15] || (_cache[15] = ($event: any) => ((_ctx.formConfig.selectedPage) = $event)),
                                  placeholder: _ctx.$t('SELECT_MAIL')
                                }, {
                                  default: _withCtx(() => [
                                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.getMailPage, (item) => {
                                      return (_openBlock(), _createBlock(_component_a_select_option, {
                                        key: item.id,
                                        value: item.id
                                      }, {
                                        default: _withCtx(() => [
                                          _createTextVNode(_toDisplayString(_ctx.$t(item.page_name)), 1)
                                        ]),
                                        _: 2
                                      }, 1032, ["value"]))
                                    }), 128))
                                  ]),
                                  _: 1
                                }, 8, ["value", "placeholder"])
                              ]),
                              _: 1
                            }, 8, ["label"]))
                          : _createCommentVNode("", true),
                        (_ctx.formConfig.viralUrl)
                          ? (_openBlock(), _createBlock(_component_a_form_item, {
                              key: 1,
                              name: "type",
                              label: _ctx.$t('URL_QR_CODE')
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_a_input, {
                                  ref: "select",
                                  readonly: "",
                                  value: _ctx.formConfig.viralUrl,
                                  "onUpdate:value": _cache[16] || (_cache[16] = ($event: any) => ((_ctx.formConfig.viralUrl) = $event))
                                }, null, 8, ["value"]),
                                _createVNode(_component_a_avatar, {
                                  src: _ctx.formConfig.viralQRURL,
                                  shape: "square",
                                  style: {"width":"200px","height":"200px"}
                                }, null, 8, ["src"])
                              ]),
                              _: 1
                            }, 8, ["label"]))
                          : _createCommentVNode("", true),
                        (_ctx.formConfig.smartcall)
                          ? (_openBlock(), _createBlock(_component_a_form_item, {
                              key: 2,
                              name: "type",
                              label: _ctx.$t('SMARTCALL_TRIGGER_ID')
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_a_input, {
                                  value: _ctx.formConfig.smartcall_trigger_id,
                                  "onUpdate:value": _cache[17] || (_cache[17] = ($event: any) => ((_ctx.formConfig.smartcall_trigger_id) = $event))
                                }, null, 8, ["value"])
                              ]),
                              _: 1
                            }, 8, ["label"]))
                          : _createCommentVNode("", true),
                        _createVNode(_component_a_form_item, {
                          name: "type",
                          label: _ctx.$t('TIMEOUT_EACH_MESSAGE')
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_a_select, {
                              style: {"width":"100%"},
                              "show-search": "",
                              placeholder: "Select Timeout",
                              "default-active-first-option": false,
                              "show-arrow": false,
                              "not-found-content": null,
                              "filter-option": _ctx.filterOption,
                              value: _ctx.formConfig.timeout_each_message,
                              "onUpdate:value": _cache[18] || (_cache[18] = ($event: any) => ((_ctx.formConfig.timeout_each_message) = $event))
                            }, {
                              default: _withCtx(() => [
                                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.timeoutSelections, (time) => {
                                  return (_openBlock(), _createBlock(_component_a_select_option, {
                                    key: time,
                                    value: time.value
                                  }, {
                                    default: _withCtx(() => [
                                      _createTextVNode(_toDisplayString(time.label), 1)
                                    ]),
                                    _: 2
                                  }, 1032, ["value"]))
                                }), 128))
                              ]),
                              _: 1
                            }, 8, ["filter-option", "value"])
                          ]),
                          _: 1
                        }, 8, ["label"]),
                        (_ctx.formConfig.smartcall)
                          ? (_openBlock(), _createBlock(_component_a_form_item, {
                              key: 3,
                              name: "type",
                              label: _ctx.$t('smart_call_trigger_scenario_id')
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_a_input, {
                                  value: _ctx.formConfig.smart_call_trigger_scenario_id,
                                  "onUpdate:value": _cache[19] || (_cache[19] = ($event: any) => ((_ctx.formConfig.smart_call_trigger_scenario_id) = $event))
                                }, null, 8, ["value"])
                              ]),
                              _: 1
                            }, 8, ["label"]))
                          : _createCommentVNode("", true),
                        (_ctx.formConfig.smartcall)
                          ? (_openBlock(), _createBlock(_component_a_form_item, {
                              key: 4,
                              name: "type",
                              label: _ctx.$t('SELECT_PAGE')
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_a_select, {
                                  ref: "select",
                                  placeholder: _ctx.$t('SELECT_MAIL'),
                                  value: _ctx.formConfig.pages_id,
                                  "onUpdate:value": _cache[20] || (_cache[20] = ($event: any) => ((_ctx.formConfig.pages_id) = $event)),
                                  mode: "multiple",
                                  onChange: _ctx.handleChangePage
                                }, {
                                  default: _withCtx(() => [
                                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.getPages, (item) => {
                                      return (_openBlock(), _createBlock(_component_a_select_option, {
                                        key: item.id,
                                        value: item.id
                                      }, {
                                        default: _withCtx(() => [
                                          (item.page_type === 2)
                                            ? (_openBlock(), _createElementBlock("img", _hoisted_32))
                                            : (item.page_type === 1)
                                              ? (_openBlock(), _createElementBlock("img", _hoisted_33))
                                              : (item.page_type === 3)
                                                ? (_openBlock(), _createElementBlock("img", _hoisted_34))
                                                : (item.page_type === 4)
                                                  ? (_openBlock(), _createElementBlock("img", _hoisted_35))
                                                  : (item.page_type === 6)
                                                    ? (_openBlock(), _createElementBlock("img", _hoisted_36))
                                                    : _createCommentVNode("", true),
                                          _createTextVNode(" " + _toDisplayString(_ctx.$t(item.page_name)), 1)
                                        ]),
                                        _: 2
                                      }, 1032, ["value"]))
                                    }), 128))
                                  ]),
                                  _: 1
                                }, 8, ["placeholder", "value", "onChange"])
                              ]),
                              _: 1
                            }, 8, ["label"]))
                          : _createCommentVNode("", true)
                      ]),
                      _: 1
                    }, 8, ["model"]),
                    (!_ctx.formConfig.smartcall)
                      ? (_openBlock(), _createBlock(_component_a_typography_text, {
                          key: 0,
                          style: {"cursor":"pointer"},
                          onClick: _cache[21] || (_cache[21] = ($event: any) => (_ctx.readMore = !_ctx.readMore)),
                          strong: ""
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_ctx.$t('READ_MORE')), 1)
                          ]),
                          _: 1
                        }))
                      : _createCommentVNode("", true),
                    (_ctx.readMore)
                      ? (_openBlock(), _createBlock(_component_a_form, {
                          key: 1,
                          model: _ctx.formConfig2
                        }, {
                          default: _withCtx(() => [
                            (_ctx.formConfig.hasMail)
                              ? (_openBlock(), _createBlock(_component_a_form_item, {
                                  key: 0,
                                  name: "type",
                                  label: _ctx.$t('CONFIG_SEND_MAIL')
                                }, {
                                  default: _withCtx(() => [
                                    _createVNode(_component_a_select, {
                                      ref: "select",
                                      value: _ctx.formConfig2.selectedPage,
                                      "onUpdate:value": _cache[22] || (_cache[22] = ($event: any) => ((_ctx.formConfig2.selectedPage) = $event)),
                                      placeholder: _ctx.$t('SELECT_MAIL')
                                    }, {
                                      default: _withCtx(() => [
                                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.getMailPage, (item) => {
                                          return (_openBlock(), _createBlock(_component_a_select_option, {
                                            key: item.id,
                                            value: item.id
                                          }, {
                                            default: _withCtx(() => [
                                              _createTextVNode(_toDisplayString(_ctx.$t(item.page_name)), 1)
                                            ]),
                                            _: 2
                                          }, 1032, ["value"]))
                                        }), 128))
                                      ]),
                                      _: 1
                                    }, 8, ["value", "placeholder"])
                                  ]),
                                  _: 1
                                }, 8, ["label"]))
                              : _createCommentVNode("", true),
                            (_ctx.formConfig.viralUrl)
                              ? (_openBlock(), _createBlock(_component_a_form_item, {
                                  key: 1,
                                  name: "type",
                                  label: _ctx.$t('URL_QR_CODE')
                                }, {
                                  default: _withCtx(() => [
                                    _createVNode(_component_a_input, {
                                      ref: "select",
                                      readonly: "",
                                      value: _ctx.formConfig2.viralUrl,
                                      "onUpdate:value": _cache[23] || (_cache[23] = ($event: any) => ((_ctx.formConfig2.viralUrl) = $event))
                                    }, null, 8, ["value"]),
                                    _createVNode(_component_a_avatar, {
                                      src: _ctx.formConfig2.viralQRURL,
                                      shape: "square",
                                      style: {"width":"200px","height":"200px"}
                                    }, null, 8, ["src"])
                                  ]),
                                  _: 1
                                }, 8, ["label"]))
                              : _createCommentVNode("", true)
                          ]),
                          _: 1
                        }, 8, ["model"]))
                      : _createCommentVNode("", true)
                  ]),
                  _: 1
                }, 8, ["tab"])
              ]),
              _: 1
            }, 8, ["activeKey"])
          ]),
          _: 1
        }, 8, ["visible", "title"])
      ]))
    : _createCommentVNode("", true)
}