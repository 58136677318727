<template>
    <a-modal
        :visible="visible"
        :footer="null"
        :width="500">
        <template slot="title">
            <div>
                <span style="font-size: 15px; color:#516CAD;">
                   Thông báo khách hàng thân quen
                </span>
            </div>
        </template>
        <div>
            <div class="horizontal-scroll-catalogue">
                <div class="horizontal-scroll-catalogue-child">
                    <div class="container-catalogue-item">
                        <a-card>
                            <div style="cursor: pointer;">
                                <p><strong>Vào lúc : </strong> {{ data.timestamp }}</p>
                                <p><strong>Khách hàng : </strong> {{ data.name }}</p>
                                <p><strong>Giới tính : </strong> {{ data.gender }}</p>
                                <p><strong>Trạng thái : </strong> {{ data.emotion }} <i>đã ghe thăm shop</i></p>
                                <div style="text-align: center;align-items: center;display: flex">
                                    <img :src="data.img_detect" alt=""
                                         style="width: 100px;height: 100px;border-radius: 100px">
                                </div>
                            </div>
                        </a-card>
                        <a-card-meta>
                            <div slot="description" style="margin-top: 20px">
                                <a-button @click="startConversation()" type="primary">
                                    Đến nhắn tin
                                    <a-icon type="right"/>
                                </a-button>
                            </div>
                        </a-card-meta>
                    </div>
                </div>
            </div>
        </div>
    </a-modal>
</template>

<script>
import {mapState} from "vuex";

export default {
    name: "FaceDetectComponent",
    props: [],
    data() {
        return {
            visible: false,
            loading: false,
            key_item: -1,
            index: -1,
            data: ''
        }
    },
    computed: mapState({
        // pages: state => state.select_page.pages,
        // shopActive: state => state.select_page.shopActive,
        // pageActive: state => state.select_page.pageActive,
        // user: state => state.userInfo.data
    }),
    methods: {
        startConversation() {
            // var self = this;
            // let conversation = JSON.parse(self.data.customer_id);
            // let page_id = self.data.page_id;
            // let shop_id = self.data.shop_id;
            // // const path = `/chat?pages=${page_id}&conversation=${conversation}`
            // const path = `/chat?pages=${page_id}`
            // this.$router.push(path).catch(error => {
            //     if (error.name != "NavigationDuplicated") {
            //         throw error;
            //     }
            // });
            // this.visible = false;
            // this.$store.commit('select_page/setPageActive', page_id);
            //
            // this.$parent.$refs.conversation.setConversationActive(conversation)
            // this.updateConversation(conversation, 'top');
        }
    }
}
</script>

<style scoped>

</style>