import {AutoInboxData, State} from "@/store/modules/autoInbox/states";
import {AutoInboxMutationTypes} from "@/store/modules/autoInbox/mutation-types";

export type Mutations<S = State> = {
    [AutoInboxMutationTypes.SET_AUTO_INBOX](state: S, payload: AutoInboxData | undefined): void;
    [AutoInboxMutationTypes.SET_AUTO_INBOX_HISTORY](state: S, payload: AutoInboxData | undefined): void;
}

export const mutations: Mutations = {
    [AutoInboxMutationTypes.SET_AUTO_INBOX](state: State, payload: AutoInboxData | undefined): void {
        state.autoInboxData = payload
    },
    [AutoInboxMutationTypes.SET_AUTO_INBOX_HISTORY](state: State, payload: AutoInboxData | undefined): void {
        state.autoInboxHistoryData = payload
    },
}

