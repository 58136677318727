import apisauce, {ApiResponse} from "apisauce";
import {useStore} from "@/store";
import {Endpoint} from "@/APIs/Endpoint";
import {
    LoginEmailRequest,
    LoginFacebookRequest,
    LoginGoogleRequest,
    LoginRequest,
    LoginWebTokenRequest, UserRequest
} from "@/APIs/request/LoginRequest";
import {
    FlowConstantsResponse,
    FlowContextResponse,
    FlowsContextResponse,
    FlowsResponse,
    LoginResponse,
    TagsResponse,
    TriggersResponse,
    UserInfoResponse,
    CustomersResponse,
    FlowResponse,
    S_HttpResponse,
    TriggerResponse,
    AWS_FilesResponse,
    AWS_DeleteResponse,
    TagResponse,
    LocalesResponse,
    CustomersCaresResponse,
    ProvinceResponse,
    CustomerResponse,
    ExportResponse,
    ZnsTempResponse,
    ViralResultResponse,
    AutoInboxesResponse,
    AutoInboxResponse,
    CXViewLoginResponse,
    MyWalletResponse,
    FlowZNS_TransactionResponse,
    WalletTransactionsResponse,
    FacebookPostsResponse,
    StocksResponse,
    ProductGroupsResponse,
    ProductDetailResponse,
    ProductCategoriesResponse,
    ProductCategoryResponse,
    StockResponse,
    ProductProvidersResponse,
    ProductProviderResponse,
    ProductGroupResponse,
    ProductResponse,
    ProductStockResponse,
    ProductStocksResponse,
    ProductGroupCreateResponse,
    StockProductResponse,
    StockDetailResponse,
    OrdersResponse,
    ProductCheckResponse,
    OrderResponse,
    ProductsResponse,
    OrderStatResponse,
    TransporterResponse,
    ShippingMethodResponse,
    ShippingFeeResponse,
    ShopinfoUpdateResponse,
    ShopinfoResponse,
    OverviewResponse,
    OverviewResponseFB,
    OverviewResponseITG,
    OverviewResponseReport,
    EmployeeResponseSearch,
    EmployeeResponse,
    EmployeeResponseEdit,
    ShopCustomerHistoriesResponse,
    OverviewResponseDelete,
    BankResponse,
    ReportPageCommentResponse,
    NotificationsResponse,
    UnreadNotificationResponse,
    ReadNotificationResponse,
    SchedulesResponse,
    ZaloActivePage,
    TariffResponse,
    CouponResponse,
    TariffsResponse,
    ShopServicesResponse,
    TriggerStatisticResponse,
    TreeTagsResponse,
    TriggerStatisticErrorLogResponse,
    RolesResponse,
    RoleResponse,
    ZaloConfig,
    ZaloLogin,
    OverviewResponseZalo,
    CareersResponse,
    CareerResponse,
    S_HttpMailPagesResponse,
    ContactResponse,
    PageConfigResponse,
    ViralResponse,
    ViralUserResponse,
    ShopsResponse,
    PrivilegeRoleResponse,
    DefaultResponse,
    DepartmentsResponse,
    DepartmentResponse,
    CustomerConfigResponse,

    CustomerConfigColumnResponse,
    CustomerConfigValueResponse,
    VoucherResponse,
    VoucherTimeResponse,
    VouchersResponse,
    VoucherConfigResponse, MemberRankResponse, MemberRankConfigResponse, MemberRanksResponse,


    EventResponse,
    TicketResponse,
    TicketTypeResponse,
    TicketPriceResponse, TicketTemplateResponse,

    AnyResponse, ConversionReportResponse

} from "@/APIs/response/S_HttpResponse";
import {TagsRequest} from "@/APIs/request/TagRequest";
import {
    InnerTriggerStatisticRequest, TriggerEstimateRequest, TriggerInviteLink,
    TriggerRequest, TriggerSendToOne,
    TriggerSendUpdateToOne, TriggerSendUpdateToOneByTag,
    TriggerStatisticRequest, ViralUserRequest
} from "@/APIs/request/TriggerRequest";
import {
    CreateFlowRequest,
    FlowContextRequest,
    FlowsRequest,
    ZnsTempRequest,
} from "@/APIs/request/FlowRequest";
import {
    AddCustomerTagRequest,
    AddCustomerTagsRequest,
    AssignCustomerEmpRequest,
    CustomerRequest, GetCustomerReq,
} from "@/APIs/request/CustomerRequest";
import {FlowContext, FlowContextData, FlowContextTagsData} from "@/models/Flows/FlowContext";
import {Trigger} from "@/models/Trigger/Trigger";
import {AWS_DO_Request} from "@/APIs/request/AWS_DO_Request";
import {TagForm} from "@/models/Tags/Tags";
import {Locale} from "@/models/locales/Locales";
import {DistrictRequest, WardRequest} from "@/APIs/request/DictionaryRequest";
import {
    AddShopCustomerHistoryRequest, CreateCareerRequest, CustomerActivity,
    CustomerForm, CustomersForm, GetRequest,
    ShopCustomerHistoriesRequest,
    UpdateLastCare,
} from "@/models/Customers/Customers";
import {CustomerCaresForm} from "@/models/CustomerCares/CustomerCares";
import {Constant} from "@/utils/Constants";
import {store} from "@/store";

import {
    CreateCustomerCXVIEWRequest,
    CXViewCreateCustomerRes,
    CXViewCustomerDetailRes,
    CXViewGroupRes,
    CXViewLogin,
    CXViewLoginRequest,
    CXViewUpdateCustomerRequest,
    GetCustomerCXVIEWRequest,
    ViralResultRequest,
} from "@/models/CXView/CXViewModels";
import {
    AutoInboxChangeStatusRequest,
    AutoInboxesRequest,
    AutoInboxRequest,
    ExpectNumberCustomerRequest,
    FacebookPostRequest,
} from "@/APIs/request/AutoInboxRequest";
import {AutoInbox} from "@/models/AutoInbox/AutoInbox";
import {FlowZNS_EXPORT_TransactionRequest, FlowZNS_TransactionRequest} from "@/APIs/request/FlowZNS_TransactionRequest";
import {WalletTransactionRequest} from "@/APIs/request/WalletRequest";
import {StocksRequest} from "@/APIs/request/StocksRequest";
import {
    CreateProductCategoryRequest,
    ProductCategoryRequest,
    ProductChecksRequest,
    ProductDetailRequest,
    ProductGroupRequest,
    ProductProviderRequest,
    StockDetailRequest,
    StockProductRequest,
} from "@/APIs/request/ProductRequest";
import {Stocks} from "@/models/Stocks/Stocks";
import {
    Product,
    ProductGroup,
    ProductGroupCreate,
    ProductProvider,
    ProductStock,
} from "@/models/Products/Products";
import {OrdersRequest} from "@/APIs/request/OrdersRequest";
import {
    ChangeOrderStatus, FormOrder,
    OrderDetailRequest,
    Orders,
    ShippingFeeRequest,
    ShippingMethodRequest,
} from "@/models/Orders/Orders";
import {TransporterRequest} from "@/APIs/request/TransporterRequest";
import {ShopInfoRequest} from "@/APIs/request/ShopInfoRequest";
import {
    EmployeeEditRequest,
    EmployeeRequest,
} from "./request/EmployeeRequest";
import {OverviewRequest} from "./request/OverviewRequest";
import {ReportPageCommentRequest, ReportPageLableRequest} from "./request/Report_pageRequest";
import Pages from "@/views/report/OverView/OverView.vue";
import {ZaloActivePageRequest} from "@/APIs/request/ZaloRequest";
import {AddPageFBRequest} from "@/APIs/request/AuthRequest";

import {NotificationQuery, SchedulesReq} from "@/APIs/request/Notification";
import {FormSubmitSchedule} from "@/models/notification/Notification";

import {CheckCouponRequest, SaveTariffRequest} from "@/APIs/request/CheckCouponRequest";
import {ZaloConfigReport} from "@/models/ZaloConfig/ZaloConfig";
import {ZaloLoginRequest, ZaloRefreshTokenRequest} from "@/APIs/request/ZaloLogin";
import {DeleteIntegrationPaltFormRequest, IntegrationPlatformRequest} from "@/APIs/request/IntegrationPlatform";
import {LineInfoRequest, PageConfigRequest, PageConfigUpdateRequest} from "@/APIs/request/PageConfigRequest";
import {ConfigCustomerRequest, CustomerConfigRequest} from "@/APIs/request/CustomerConfigRequest";
import {CustomerConfig, CustomerConfigColumn} from "@/models/CustomerConfig/CustomerConfig";
import {

    VoucherConfigRequest,
    VoucherCreateRequest, VoucherDetailRequest,
    VoucherRequest, VouchersRequest, VoucherStopRequest,
    VoucherTimeApplicationRequest
} from "@/APIs/request/VoucherRequest";
import {MemberRankConfigRequest, MemberRankRequest} from "@/APIs/request/MemberRankRequest";
import {
    Ticketcreated,
    TicketPriceCreated,
    TicketRequest, TicketTemplateRequest,
    TicketTypeCreated,
    TicketTypeRequest
} from "@/APIs/request/TicketRequest";
import {EventsRequest, EventRequest} from "@/APIs/request/EventRequest";
import {TicketTemplate} from "@/models/Ticket/Ticket";
import useUserStore from "@/store/User";


export default class APIs {
    // BASE_URL = 'https://api.shopf1.net/'
    // BASE_URL_API =  'https://api.shopf1.net/'
    // BASE_URL = 'https://test.shopf1.net/'`
    BASE_URL_API = "https://api.shopf1.net/";
    CXVIEW_URL = "https://api.cxview.ai:8081/";
    getBaseUrl = (): string => {
        // return "http://localhost:3000/";
        if (location.host.includes("crm.shopf1")) {
            return "https://api.shopf1.net/";
        } else if (location.host.includes("crm.alfreddesk")) {
            return "https://api.shopf1.net/";
        } else if (location.host.includes("crmtest")) {
            return "https://test.shopf1.net/";
        } else if (location.host.includes("localhost")) {
            return "https://api.shopf1.net/";

        }
        return "https://api.shopf1.net/";
    };
    getBaseUrlReport = (): string => {
        return "https://report.shopf1.net/";
    };
    getLableUrlReport = (): string => {
        return "https://web.biglead.live/";
    };


    private apiPartnerSync = () => {
        return apisauce.create({
            baseURL: this.getBaseUrl(),
            timeout: 50000,
            headers: {
                target: "partner-sync",
                "Content-Type": "application/json",
            },
        });
    };
    private apiPartnerSync2 = () => {
        const store = useStore();
        let token: string | undefined = "";
        let cmsuserid: number | undefined;
        if (store.getters.getUserInfo) {
            token = store.getters.getUserInfo?.token;
            cmsuserid = store.getters.getUserInfo?.id
        }
        return apisauce.create({
            baseURL: this.getBaseUrl(),
            timeout: 50000,

            headers: {
                target: "partner-sync",
                "Content-Type": "application/json",
                Authorization: "Bearer " + token,
                cmsuserid: cmsuserid,
            },
        });
    };
    private apiReportLable() {
        return apisauce.create({
            baseURL: this.getLableUrlReport(),
            timeout: 50000,
            headers: {
                "Content-Type": "application/json",
            },
        });
    }

    private apiAuth() {
        const store = useStore();
        let shopId: number | undefined;
        if(store.getters.getUserInfo){
            shopId = store.getters.getUserInfo?.current_shop_id;
        }

        return apisauce.create({
            baseURL: this.getBaseUrl(),
            timeout: 50000,
            headers: {
                target: "auth",
                "Content-Type": "application/json",
                shopid: shopId,

            },
        });
    }
    private apiWhatsapp() {
        const store = useStore();
        let shopId: number | undefined;


        try{
            // @ts-ignore
            shopId = useUserStore(state=>state.currentShop.id).value
        }catch(e){
            console.log(e)

        }

        return apisauce.create({
            baseURL: this.getBaseUrl(),
            timeout: 50000,
            headers: {
                target: "whatsapp_api",
                "Content-Type": "application/json",
                shopid: shopId,

            },
        });
    }
    private graphFB() {
        return apisauce.create({
            baseURL: "https://graph.facebook.com/",
            timeout: 50000,
        });
    }

    private apiAuthAPI() {
        const store = useStore();

        let token  = "";
        let shopId;
        let cmsuserid;
        try{
            // @ts-ignore
            token = useUserStore(state=>state.userInfo.token).value
            // @ts-ignore
            shopId = useUserStore(state=>state.currentShop.id).value
            // @ts-ignore
            cmsuserid = useUserStore(state=>state.userInfo.id).value
        }catch(e){
            console.log(e)

        }

        return apisauce.create({
            baseURL: this.getBaseUrl(),
            timeout: 50000,
            headers: {
                target: "auth",
                Authorization: "Bearer " + token,
                "Content-Type": "application/json",
                shopid: shopId,
                cmsuserid: cmsuserid,
            },
        });
    }

    private apiCXView() {
        return apisauce.create({
            baseURL: this.CXVIEW_URL,
            timeout: 50000,
            headers: {
                "Content-Type": "application/json",
                Authorization: store.getters.getCXView?.token,
            },
        });
    }

    private apiFlow() {
        const store = useStore();

        let token = ""
        let shopId = ""
        let cmsuserid = ""
        try{
            // @ts-ignore
            token = useUserStore(state=>state.userInfo.token).value
            // @ts-ignore
            shopId = useUserStore(state=>state.currentShop.id).value
            // @ts-ignore
            cmsuserid = useUserStore(state=>state.userInfo.id).value
        }catch(e){
            console.log(e)

        }

        return apisauce.create({
            baseURL: this.getBaseUrl(),
            timeout: 50000,
            headers: {
                target: "flow_api",
                "Content-Type": "application/json",
                Authorization: "Bearer " + token,

            },
        });
    }

    private apiFlowPreviewOder() {
        return apisauce.create({
            baseURL: this.getBaseUrl(),
            timeout: 50000,
            headers: {
                "Content-Type": "application/json",
            },
        });
    }

    private apiShipping() {
        const store = useStore();
        let token: string | undefined = "";

        if (store.getters.getUserInfo) {
            token = store.getters.getUserInfo?.token;
        }
        return apisauce.create({
            baseURL: this.getBaseUrl(),
            timeout: 50000,
            headers: {
                target: "Delivery",
                "Content-Type": "application/json",
                Authorization: "Bearer " + token,

            },
        });
    }

    private apiReport = () => {
        return apisauce.create({
            baseURL: this.getBaseUrlReport(),
            timeout: 50000,
            headers: {
                "Content-Type": "text/plain",
            },
        });
    };

    private apiAWS_DO() {
        const store = useStore();
        let token: string | undefined = "";
        let shopId: number | undefined;
        if (store.getters.getUserInfo) {
            token = store.getters.getUserInfo?.token;
            shopId = store.getters.getUserInfo?.current_shop_id;
        }
        return apisauce.create({
            baseURL: this.getBaseUrl(),
            timeout: 50000,
            headers: {
                target: "aws_do",
                "Content-Type": "application/json",
                Authorization: "Bearer " + token,
                shopid: shopId,
            },
        });
    }

    static instance = null;

    static init(): APIs {
        if (!this.instance) {
            // @ts-ignore
            this.instance = new APIs();
        }
        // @ts-ignore
        return this.instance;
    }
    static clear() {
        this.instance = null;
        console.log(this.instance)

    }

    // testCors() {
    //     apisauce.create({
    //         baseURL: "https://api.shopf1.net/",
    //         timeout: 50000,
    //         headers: {
    //             'target': 'auth',
    //             'Content-Type': "application/json",
    //         }
    //     }).get('conversation/list?shop_id=121212&pageIds=291207261415821&skip=0&token=221f4e5bf235efc46e8d8b7e3e3a54a3&filter_staff_id=').then(res => {
    //         console.log("res", res)
    //     })
    // }

    login(request: LoginRequest): Promise<ApiResponse<LoginResponse>> {
        return this.apiAuth().post<LoginResponse>(Endpoint.LOGIN_TOKEN, request);
    }
    updateUserInfo(request: UserRequest): Promise<ApiResponse<UserInfoResponse>> {
        return this.apiFlow().put<UserInfoResponse>(Endpoint.CMS_USERS,request);
    }
    getSuperUserInfo(): Promise<ApiResponse<UserInfoResponse>> {
        return this.apiFlow().get<UserInfoResponse>(Endpoint.GET_SUPER_USER_INFO);
    }

    getFlows(request: FlowsRequest): Promise<ApiResponse<FlowsResponse>> {
        return this.apiFlow().get<FlowsResponse>(Endpoint.FLOWS, request);
    }
    getCustomerConfig(request: ConfigCustomerRequest): Promise<ApiResponse<CustomerConfigColumnResponse>> {
        return this.apiFlow().get<CustomerConfigColumnResponse>(Endpoint.CUSTOME_CONFIG, request);
    }
    getCustomerConfigValue(request: ConfigCustomerRequest): Promise<ApiResponse<CustomerConfigValueResponse>> {
        return this.apiFlow().get<CustomerConfigValueResponse>(Endpoint.CUSTOME_CONFIG_value, request);
    }
    addCustomerConfig(request: CustomerConfigRequest): Promise<ApiResponse<CustomerConfigResponse>> {
        return this.apiFlow().post<CustomerConfigResponse>(Endpoint.CUSTOME_CONFIG, request);
    }
    UpdateCustomerConfig(request: CustomerConfigRequest): Promise<ApiResponse<CustomerConfigResponse>> {
        return this.apiFlow().put<CustomerConfigResponse>(Endpoint.CUSTOME_CONFIG, request);
    }
    DeleteCustomerConfig(request: CustomerConfigRequest): Promise<ApiResponse<CustomerConfigResponse>> {
        return this.apiFlow().delete<CustomerConfigResponse>(Endpoint.CUSTOME_CONFIG, request);
    }
    updatePageConfig(request: PageConfigUpdateRequest): Promise<ApiResponse<PageConfigResponse>> {
        return this.apiFlow().put<PageConfigResponse>(Endpoint.Shop_PAGE, request);
    }
    addLineInfo(request: LineInfoRequest): Promise<ApiResponse<PageConfigResponse>> {
        return this.apiAuthAPI().post<PageConfigResponse>(Endpoint.LINE_INFO, request);
    }
    getShopPages(request: PageConfigRequest): Promise<ApiResponse<PageConfigResponse>> {
        return this.apiFlow().get<PageConfigResponse>(Endpoint.Shop_PAGE, request);
    }
    getAutoInbox(
        request: AutoInboxRequest
    ): Promise<ApiResponse<AutoInboxResponse>> {
        return this.apiFlow().get<AutoInboxResponse>(Endpoint.AUTO_INBOX, request);
    }

    createAutoInbox(request: AutoInbox): Promise<ApiResponse<AutoInboxResponse>> {
        return this.apiFlow().post<AutoInboxResponse>(Endpoint.AUTO_INBOX, request);
    }

    updateAutoInbox(request: AutoInbox): Promise<ApiResponse<AutoInboxResponse>> {
        return this.apiFlow().put<AutoInboxResponse>(Endpoint.AUTO_INBOX, request);
    }

    getAutoInboxes(
        request: AutoInboxesRequest
    ): Promise<ApiResponse<AutoInboxesResponse>> {
        return this.apiFlow().get<AutoInboxesResponse>(
            Endpoint.AUTO_INBOXES,
            request
        );
    }

    getAutoInboxHistory(
        request: AutoInboxesRequest
    ): Promise<ApiResponse<AutoInboxesResponse>> {
        return this.apiFlow().get<AutoInboxesResponse>(
            Endpoint.AUTO_INBOX_HISTORY,
            request
        );
    }

    getTags(request: TagsRequest): Promise<ApiResponse<TagsResponse>> {
        return this.apiFlow().get<TagsResponse>(Endpoint.TAGS, request);
    }

    getRootTags(request: TagsRequest): Promise<ApiResponse<TagsResponse>> {
        return this.apiFlow().get<TagsResponse>(Endpoint.TAG_ROOT, request);
    }
    getChildrenTags(request: TagsRequest): Promise<ApiResponse<TagsResponse>> {
        return this.apiFlow().get<TagsResponse>(Endpoint.TAG_CHILDREN, request);
    }
    getTreeTags(request: TagsRequest): Promise<ApiResponse<TreeTagsResponse>> {
        return this.apiFlow().get<TreeTagsResponse>(Endpoint.TAG_TREE, request);
    }
    getTriggers(request: TriggerRequest): Promise<ApiResponse<TriggersResponse>> {
        return this.apiFlow().get<TriggersResponse>(Endpoint.TRIGGERS, request);
    }
    getTriggersQuickMessages(request: TriggerRequest): Promise<ApiResponse<TriggersResponse>> {
        return this.apiFlow().get<TriggersResponse>(Endpoint.TRIGGER_QUICK_MESSAGES, request);
    }


    getProductDetail(
        request: ProductDetailRequest
    ): Promise<ApiResponse<ProductDetailResponse>> {
        return this.apiFlow().get<ProductDetailResponse>(
            Endpoint.PRODUCT_DETAIL,
            request
        );
    }

    getFlowContext(
        request: FlowContextRequest
    ): Promise<ApiResponse<FlowsContextResponse>> {
        return this.apiFlow().get<FlowsContextResponse>(
            Endpoint.FLOW_CONTEXT,
            request
        );
    }

    getFlowV2Context(
        request: FlowContextRequest
    ): Promise<ApiResponse<FlowsContextResponse>> {
        return this.apiFlow().get<FlowsContextResponse>(
            Endpoint.FLOW_V2_CONTEXTS,
            request
        );
    }

    getFlowConstants(): Promise<ApiResponse<FlowConstantsResponse>> {
        return this.apiFlow().get<FlowConstantsResponse>(Endpoint.FLOW_CONSTANTS);
    }

    getCustomers(
        request: CustomerRequest
    ): Promise<ApiResponse<CustomersResponse>> {
        return this.apiFlow().get<CustomersResponse>(Endpoint.CUSTOMERS, request);
    }

    deleteAllCustomer(
        request: CustomerRequest
    ): Promise<ApiResponse<CustomersResponse>> {
        return this.apiFlow().delete<CustomersResponse>(Endpoint.DELETE_ALL_CUSTOMER, request);
    }

    getTransporter(): Promise<ApiResponse<TransporterResponse>> {
        return this.apiFlow().get<TransporterResponse>(Endpoint.TRANSPORTER);
    }
    getRole(): Promise<ApiResponse<RolesResponse>> {
        return this.apiFlow().get<RolesResponse>(Endpoint.ROLE);
    }
    updateTransporter(
        request: TransporterRequest
    ): Promise<ApiResponse<TransporterResponse>> {
        return this.apiFlow().put<TransporterResponse>(
            Endpoint.TRANSPORTER,
            request
        );
    }

    createFlowContext(
        request: FlowContext
    ): Promise<ApiResponse<FlowContextResponse>> {
        return this.apiFlow().post<FlowContextResponse>(
            Endpoint.FLOW_CONTEXT,
            request
        );
    }

    updateFlowContexts(
        request: FlowContextData
    ): Promise<ApiResponse<FlowsContextResponse>> {
        return this.apiFlow().put<FlowsContextResponse>(
            Endpoint.FLOW_CONTEXTS,
            request
        );
    }

    updateFlowV2Contexts(
        request: FlowContextData
    ): Promise<ApiResponse<FlowsContextResponse>> {
        return this.apiFlow().put<FlowsContextResponse>(
            Endpoint.FLOW_V2_CONTEXTS,
            request
        );
    }
    updateFlowTagsContexts(
        request: FlowContextTagsData
    ): Promise<ApiResponse<FlowsContextResponse>> {
        return this.apiFlow().put<FlowsContextResponse>(
            Endpoint.FLOW_TAGS_CONTEXTS,
            request
        );
    }

    createFlow(request: CreateFlowRequest): Promise<ApiResponse<FlowResponse>> {
        return this.apiFlow().post<FlowResponse>(Endpoint.FLOW, request);
    }

    publishFlow(
        request: FlowContextData
    ): Promise<ApiResponse<FlowsContextResponse>> {
        return this.apiFlow().put<FlowsContextResponse>(
            Endpoint.PUBLISH_FLOW,
            request
        );
    }

    deleteFlow(
        request: FlowContextRequest
    ): Promise<ApiResponse<S_HttpResponse>> {
        return this.apiFlow().delete<S_HttpResponse>(Endpoint.FLOW, request);
    }

    addCustomerTags(
        request: AddCustomerTagsRequest
    ): Promise<ApiResponse<S_HttpResponse>> {
        return this.apiFlow().post<S_HttpResponse>(
            Endpoint.CUSTOMERS_ADDTAGS,
            request
        );
    }

    updateTagCustomerFilter(
        request: CustomerRequest
    ): Promise<ApiResponse<S_HttpResponse>> {
        return this.apiFlow().post<S_HttpResponse>(
            Endpoint.CUSTOMERS_ADD_ALL_TAGS,
            request
        );
    }


    removeCustomerTags(
        request: AddCustomerTagsRequest
    ): Promise<ApiResponse<S_HttpResponse>> {
        return this.apiFlow().post<S_HttpResponse>(
            Endpoint.CUSTOMERS_REMOVETAGS,
            request
        );
    }

    assignCustomerEmp(
        request: AssignCustomerEmpRequest
    ): Promise<ApiResponse<CustomerResponse>> {
        return this.apiFlow().put<CustomerResponse>(
            Endpoint.CUSTOMERS_EMPLOYEES,
            request
        );
    }

    assignCustomerAllEmp(
        request: CustomerRequest
    ): Promise<ApiResponse<CustomerResponse>> {
        return this.apiFlow().put<CustomerResponse>(
            Endpoint.CUSTOMERS_EMPLOYEES_ALL_BY_FILTER,
            request
        );
    }

    getTrigger(request: Trigger): Promise<ApiResponse<TriggerResponse>> {
        return this.apiFlow().get<TriggerResponse>(Endpoint.TRIGGER, request);
    }

    updateTrigger(request: Trigger): Promise<ApiResponse<TriggerResponse>> {
        return this.apiFlow().put<TriggerResponse>(Endpoint.TRIGGER, request);
    }

    createTrigger(request: Trigger): Promise<ApiResponse<TriggerResponse>> {
        return this.apiFlow().post<TriggerResponse>(Endpoint.TRIGGER, request);
    }

    deleteTrigger(request: number): Promise<ApiResponse<S_HttpResponse>> {
        return this.apiFlow().delete<S_HttpResponse>(Endpoint.TRIGGER, {
            id: request,
        });
    }

    activateTrigger(request: number): Promise<ApiResponse<S_HttpResponse>> {
        return this.apiFlow().put<S_HttpResponse>(Endpoint.TRIGGER_ACTIVATE, {
            id: request,
        });
    }

    stopTrigger(request: number): Promise<ApiResponse<S_HttpResponse>> {
        return this.apiFlow().put<S_HttpResponse>(Endpoint.TRIGGER_STOP, {
            id: request,
        });
    }

    getFiles(): Promise<ApiResponse<AWS_FilesResponse>> {
        return this.apiAWS_DO().get<AWS_FilesResponse>(Endpoint.AWS_DO_GET_FILES);
    }

    deleteFiles(
        request: AWS_DO_Request
    ): Promise<ApiResponse<AWS_DeleteResponse>> {
        return this.apiAWS_DO().post<AWS_DeleteResponse>(
            Endpoint.AWS_DO_DELETE_FILES,
            request
        );
    }

    uploadFiles(request: FormData): Promise<ApiResponse<AWS_FilesResponse>> {
        return this.apiAWS_DO().post<AWS_FilesResponse>(
            Endpoint.AWS_DO_UPLOAD_FILES,
            request
        );
    }

    createTag(request: TagForm): Promise<ApiResponse<TagResponse>> {
        return this.apiFlow().post<TagResponse>(Endpoint.TAG, request);
    }

    updateTag(request: TagForm): Promise<ApiResponse<TagResponse>> {
        return this.apiFlow().put<TagResponse>(Endpoint.TAG, request);
    }

    deleteTag(request: number): Promise<ApiResponse<S_HttpResponse>> {
        return this.apiFlow().delete<S_HttpResponse>(Endpoint.TAG, {id: request});
    }

    getLocales(): Promise<ApiResponse<LocalesResponse>> {
        return this.apiAuthAPI().get<LocalesResponse>(Endpoint.LOCALES_LIST);
    }

    editLocales(request: Locale): Promise<ApiResponse<S_HttpResponse>> {
        return this.apiAuthAPI().post<S_HttpResponse>(Endpoint.LOCALES_EDIT, request);
    }

    deleteLocales(key: string): Promise<ApiResponse<S_HttpResponse>> {
        return this.apiAuthAPI().delete<S_HttpResponse>(Endpoint.LOCALES_DELETE, {
            key: key,
        });
    }

    // getLocaleThai():Promise<ApiResponse<any>> {
    //     return this.apiAuth().get('locales/th.json')
    // }

    getCustomerCares(): Promise<ApiResponse<CustomersCaresResponse>> {
        return this.apiFlow().get<CustomersCaresResponse>(Endpoint.CUSTOMERS_CARES);
    }
    getDetailCustomerCares(): Promise<ApiResponse<CustomersCaresResponse>> {
        return this.apiFlow().get<CustomersCaresResponse>(Endpoint.CUSTOMERS_DETAIL_CARES);
    }
    getPageList(): Promise<ApiResponse<CustomersCaresResponse>> {
        return this.apiFlow().get<CustomersCaresResponse>(Endpoint.PAGE_LIST);
    }

    createCustomerCares(
        request: CustomerCaresForm
    ): Promise<ApiResponse<CustomersCaresResponse>> {
        return this.apiFlow().post<CustomersCaresResponse>(
            Endpoint.CUSTOMERS_CARE,
            request
        );
    }

    updateCustomerCares(
        request: CustomerCaresForm
    ): Promise<ApiResponse<CustomersCaresResponse>> {
        return this.apiFlow().put<CustomersCaresResponse>(
            Endpoint.CUSTOMERS_CARE,
            request
        );
    }
    sortCustomerCares(
        request: CustomerCaresForm
    ): Promise<ApiResponse<CustomersCaresResponse>> {
        return this.apiFlow().put<CustomersCaresResponse>(
            Endpoint.SORT_CUSTOMERS_CARE,
            request
        );
    }
    deleteCustomerCares(request: number): Promise<ApiResponse<S_HttpResponse>> {
        return this.apiFlow().delete<S_HttpResponse>(Endpoint.CUSTOMERS_CARE, {
            id: request,
        });
    }

    getProvinces(): Promise<ApiResponse<ProvinceResponse>> {
        return this.apiFlow().get<ProvinceResponse>(Endpoint.PROVINCES);
    }

    getDistricts(
        request?: DistrictRequest
    ): Promise<ApiResponse<ProvinceResponse>> {
        return this.apiFlow().get<ProvinceResponse>(Endpoint.DISTRICTS, request);
    }

    getWards(request?: WardRequest): Promise<ApiResponse<ProvinceResponse>> {
        return this.apiFlow().get<ProvinceResponse>(Endpoint.WARDS, request);
    }

    createCustomer(
        request: CustomerForm
    ): Promise<ApiResponse<CustomerResponse>> {
        return this.apiFlow().post<CustomerResponse>(Endpoint.CUSTOMER, request);
    }

    getCustomer(
        request: GetCustomerReq
    ): Promise<ApiResponse<CustomerResponse>> {
        return this.apiFlow().get<CustomerResponse>(Endpoint.CUSTOMER, request);
    }

    updateCustomer(
        request: CustomerForm | CustomersForm
    ): Promise<ApiResponse<CustomerResponse>> {
        return this.apiFlow().put<CustomerResponse>(Endpoint.CUSTOMER, request);
    }

    updateCustomerLastCare(
        request: UpdateLastCare
    ): Promise<ApiResponse<CustomerResponse>> {
        return this.apiFlow().put<CustomerResponse>(
            Endpoint.CUSTOMER_LASTCARE,
            request
        );
    }

    deleteCustomer(request: number): Promise<ApiResponse<S_HttpResponse>> {
        return this.apiFlow().delete<S_HttpResponse>(Endpoint.CUSTOMER, {
            id: request,
        });
    }

    exportCustomer(
        request: CustomerRequest
    ): Promise<ApiResponse<ExportResponse>> {
        return this.apiFlow().get<ExportResponse>(
            Endpoint.CUSTOMERS_EXPORT,
            request
        );
    }

    importCustomer(request: FormData): Promise<ApiResponse<S_HttpResponse>> {
        return this.apiFlow().post<ExportResponse>(
            Endpoint.CUSTOMERS_IMPORT,
            request
        );
    }
    importCustomerUpdate(request: FormData): Promise<ApiResponse<S_HttpResponse>> {
        return this.apiFlow().put<ExportResponse>(
            Endpoint.CUSTOMERS_IMPORT,
            request
        );
    }
    getZnsTemp(request: ZnsTempRequest): Promise<ApiResponse<ZnsTempResponse>> {
        return this.apiFlow().get<ZnsTempResponse>(Endpoint.ZNS_TEMP, request);
    }

    updateTemplate(
        request: FlowContextData
    ): Promise<ApiResponse<FlowsContextResponse>> {
        return this.apiFlow().put<FlowsContextResponse>(
            Endpoint.FLOW_CONTEXTS,
            request
        );
    }

    cxViewLogin(
        request: CXViewLoginRequest
    ): Promise<ApiResponse<CXViewLoginResponse>> {
        return this.apiCXView().post<CXViewLoginResponse>(
            Endpoint.CXVIEW_LOGIN,
            request
        );
    }

    cxViewCreateCustomer(
        request: FormData
    ): Promise<ApiResponse<CXViewCreateCustomerRes>> {
        return this.apiCXView().post<CXViewCreateCustomerRes>(
            Endpoint.CXVIEW_ADD_CUSTOMER,
            request
        );
    }

    cxViewCustomerDetail(
        id: string
    ): Promise<ApiResponse<CXViewCustomerDetailRes>> {
        return this.apiCXView().post<CXViewCustomerDetailRes>(
            Endpoint.CXVIEW_DETAIL_CUSTOMER(id)
        );
    }

    cxViewCustomerGroup(
        page: number,
        limit: number
    ): Promise<ApiResponse<CXViewGroupRes>> {
        return this.apiCXView().get<CXViewGroupRes>(
            Endpoint.CXVIEW_GROUP_CUSTOMER,
            {page, limit}
        );
    }

    cxViewUpdateCustomer(
        id: string,
        request: CXViewUpdateCustomerRequest
    ): Promise<ApiResponse<CXViewCustomerDetailRes>> {
        return this.apiCXView().put<CXViewCustomerDetailRes>(
            Endpoint.CXVIEW_UPDATE_CUSTOMER(id),
            request
        );
    }

    cxViewCustomer(
        request: CreateCustomerCXVIEWRequest
    ): Promise<ApiResponse<CreateCustomerCXVIEWRequest>> {
        return this.apiFlow().post<CreateCustomerCXVIEWRequest>(
            Endpoint.CXVIEW_CUSTOMER,
            request
        );
    }

    getCXViewCustomer(
        request: GetCustomerCXVIEWRequest
    ): Promise<ApiResponse<CustomerResponse>> {
        return this.apiFlow().get<CustomerResponse>(
            Endpoint.CXVIEW_CUSTOMER,
            request
        );
    }

    getViralResult(
        request: ViralResultRequest
    ): Promise<ApiResponse<ViralResultResponse>> {
        return this.apiFlow().get<ViralResultResponse>(
            Endpoint.VIRAL_RESULT,
            request
        );
    }

    getFacebookPost(
        request: FacebookPostRequest
    ): Promise<ApiResponse<ViralResultResponse>> {
        return this.apiAuthAPI().get<ViralResultResponse>(
            Endpoint.FACEBOOK_POSTS,
            request
        );
    }

    getExpectNumberCustomer(
        request: ExpectNumberCustomerRequest
    ): Promise<ApiResponse<ViralResultResponse>> {
        return this.apiFlow().get<ViralResultResponse>(
            Endpoint.AUTO_INBOX_EXPECT_CUSTOMER,
            request
        );
    }

    autoInboxChangeStatus(
        request: AutoInboxChangeStatusRequest
    ): Promise<ApiResponse<AutoInboxResponse>> {
        return this.apiFlow().put<AutoInboxResponse>(
            Endpoint.AUTO_INBOX_CHANGE_STATUS,
            request
        );
    }

    autoInboxDelete(
        request: AutoInboxRequest
    ): Promise<ApiResponse<S_HttpResponse>> {
        return this.apiFlow().delete<S_HttpResponse>(Endpoint.AUTO_INBOX, request);
    }

    getMyWallet(type: string): Promise<ApiResponse<MyWalletResponse>> {
        return this.apiFlow().get<MyWalletResponse>(Endpoint.WALLET, {
            type: type,
        });
    }

    getWalletUid(type: string,uid:number): Promise<ApiResponse<MyWalletResponse>> {
        return this.apiFlow().get<MyWalletResponse>(Endpoint.WALLET_BY_UID, {
            type: type,
            uid:uid
        });
    }

    getWalletId(type: string,id:string): Promise<ApiResponse<MyWalletResponse>> {
        return this.apiFlow().get<MyWalletResponse>(Endpoint.WALLET_BY_ID, {
            type: type,
            id:id
        });
    }


    getFlowZNSTransactions(
        request: FlowZNS_TransactionRequest
    ): Promise<ApiResponse<FlowZNS_TransactionResponse>> {
        return this.apiFlow().get<FlowZNS_TransactionResponse>(
            Endpoint.FLOW_ZNS_TRANSACTIONS,
            request
        );
    }

    getWalletTransactions(
        request: WalletTransactionRequest
    ): Promise<ApiResponse<WalletTransactionsResponse>> {
        return this.apiFlow().get<WalletTransactionsResponse>(
            Endpoint.WALLET_TRANSACTIONS,
            request
        );
    }

    getWalletExportTransactions(
        request: WalletTransactionRequest
    ): Promise<ApiResponse<WalletTransactionsResponse>> {
        return this.apiFlow().get<WalletTransactionsResponse>(
            Endpoint.WALLET_EXPORT_TRANSACTIONS,
            request
        );
    }
    getflowExportTransactions(
        request: WalletTransactionRequest
    ): Promise<ApiResponse<WalletTransactionsResponse>> {
        return this.apiFlow().get<WalletTransactionsResponse>(
            Endpoint.FLOW_EXPORT_TRANSACTIONS,
            request
        );
    }
    getPagePosts(page_id: string,page_type: number): Promise<ApiResponse<FacebookPostsResponse>> {
        return this.apiAuth().get<FacebookPostsResponse>(Endpoint.FACEBOOK_POSTS, {
            page_id: page_id,
            page_type: page_type
        });
    }

    getStocks(request: StocksRequest): Promise<ApiResponse<StocksResponse>> {
        return this.apiFlow().get<StocksResponse>(Endpoint.STOCKS, request);
    }

    createStock(request: Stocks): Promise<ApiResponse<StockResponse>> {
        return this.apiFlow().post<StockResponse>(Endpoint.STOCKS, request);
    }

    updateStock(request: Stocks): Promise<ApiResponse<StockResponse>> {
        return this.apiFlow().put<StockResponse>(Endpoint.STOCKS, request);
    }

    deleteStock(request: Stocks): Promise<ApiResponse<StockResponse>> {
        return this.apiFlow().delete<StockResponse>(Endpoint.STOCKS, request);
    }

    getProductCategories(
        request: ProductCategoryRequest
    ): Promise<ApiResponse<ProductCategoriesResponse>> {
        return this.apiFlow().get<ProductCategoriesResponse>(
            Endpoint.CATEGORIES,
            request
        );
    }

    createProductCategory(
        request: CreateProductCategoryRequest
    ): Promise<ApiResponse<ProductCategoryResponse>> {
        return this.apiFlow().post<ProductCategoryResponse>(
            Endpoint.CATEGORIES,
            request
        );
    }

    updateProductCategory(
        request: CreateProductCategoryRequest
    ): Promise<ApiResponse<ProductCategoryResponse>> {
        return this.apiFlow().put<ProductCategoryResponse>(
            Endpoint.CATEGORIES,
            request
        );
    }

    deleteProductCategory(
        request: CreateProductCategoryRequest
    ): Promise<ApiResponse<ProductCategoryResponse>> {
        return this.apiFlow().delete<ProductCategoryResponse>(
            Endpoint.CATEGORIES,
            request
        );
    }

    getProductProviders(
        request: ProductProviderRequest
    ): Promise<ApiResponse<ProductProvidersResponse>> {
        return this.apiFlow().get<ProductProvidersResponse>(
            Endpoint.PROVIDERS,
            request
        );
    }

    createProductProvider(
        request: ProductProvider
    ): Promise<ApiResponse<ProductProviderResponse>> {
        return this.apiFlow().post<ProductProviderResponse>(
            Endpoint.PROVIDERS,
            request
        );
    }

    updateProductProvider(
        request: ProductProvider
    ): Promise<ApiResponse<ProductProviderResponse>> {
        return this.apiFlow().put<ProductProviderResponse>(
            Endpoint.PROVIDERS,
            request
        );
    }

    deleteProductProvider(
        request: ProductProvider
    ): Promise<ApiResponse<ProductProviderResponse>> {
        return this.apiFlow().delete<ProductProviderResponse>(
            Endpoint.PROVIDERS,
            request
        );
    }

    getProductGroups(
        request: ProductGroupRequest
    ): Promise<ApiResponse<ProductGroupsResponse>> {
        return this.apiFlow().get<ProductGroupsResponse>(
            Endpoint.PRODUCT_GROUPS,
            request
        );
    }

    createProductGroup(
        request: ProductGroupCreate
    ): Promise<ApiResponse<ProductGroupCreateResponse>> {
        return this.apiFlow().post<ProductGroupCreateResponse>(
            Endpoint.PRODUCT_GROUPS,
            request
        );
    }

    updateProductGroup(
        request: ProductGroup
    ): Promise<ApiResponse<ProductGroupResponse>> {
        return this.apiFlow().put<ProductGroupResponse>(
            Endpoint.PRODUCT_GROUPS,
            request
        );
    }

    deleteProductGroup(
        request: ProductGroup
    ): Promise<ApiResponse<ProductGroupResponse>> {
        return this.apiFlow().delete<ProductGroupResponse>(
            Endpoint.PRODUCT_GROUPS,
            request
        );
    }

    createProduct(request: Product): Promise<ApiResponse<ProductResponse>> {
        return this.apiFlow().post<ProductResponse>(
            Endpoint.PRODUCT_VERSION,
            request
        );
    }

    updateProduct(request: Product): Promise<ApiResponse<ProductResponse>> {
        return this.apiFlow().put<ProductResponse>(
            Endpoint.PRODUCT_VERSION,
            request
        );
    }

    deleteProduct(request: Product): Promise<ApiResponse<ProductResponse>> {
        return this.apiFlow().delete<ProductResponse>(
            Endpoint.PRODUCT_VERSION,
            request
        );
    }

    getProductStocks(
        request: ProductStock
    ): Promise<ApiResponse<ProductStocksResponse>> {
        return this.apiFlow().get<ProductStocksResponse>(
            Endpoint.PRODUCT_STOCKS,
            request
        );
    }

    createProductStock(
        request: ProductStock
    ): Promise<ApiResponse<ProductStockResponse>> {
        return this.apiFlow().post<ProductStockResponse>(
            Endpoint.PRODUCT_STOCKS,
            request
        );
    }

    updateProductStock(
        request: ProductStock
    ): Promise<ApiResponse<ProductStockResponse>> {
        return this.apiFlow().put<ProductStockResponse>(
            Endpoint.PRODUCT_STOCKS,
            request
        );
    }

    deleteProductStock(
        request: ProductStock
    ): Promise<ApiResponse<ProductStockResponse>> {
        return this.apiFlow().delete<ProductStockResponse>(
            Endpoint.PRODUCT_STOCKS,
            request
        );
    }

    getStockProducts(
        request: StockProductRequest
    ): Promise<ApiResponse<StockProductResponse>> {
        return this.apiFlow().get<StockProductResponse>(
            Endpoint.STOCK_PRODUCTS,
            request
        );
    }

    getStockDetail(
        request: StockDetailRequest
    ): Promise<ApiResponse<StockDetailResponse>> {
        return this.apiFlow().get<StockDetailResponse>(
            Endpoint.STOCK_DETAIL,
            request
        );
    }

    getOrders(
        request: OrdersRequest
    ): Promise<ApiResponse<ProductStocksResponse>> {
        return this.apiFlow().get<ProductStocksResponse>(Endpoint.ORDERS, request);
    }

    updateOrder(request: Orders): Promise<ApiResponse<OrderResponse>> {
        return this.apiFlow().put<OrderResponse>(Endpoint.ORDERS, request);
    }

    deleteOrder(request: Orders): Promise<ApiResponse<OrderResponse>> {
        return this.apiFlow().delete<OrderResponse>(Endpoint.ORDERS, request);
    }

    getAllProduct(): Promise<ApiResponse<ProductsResponse>> {
        return this.apiFlow().get<ProductsResponse>(Endpoint.PRODUCT_ALL);
    }

    getOrderStat(params: any): Promise<ApiResponse<OrderStatResponse>> {
        return this.apiFlow().get<OrderStatResponse>(Endpoint.ORDER_STAT, params);
    }

    getCustomerSearch(
        request: CustomerRequest
    ): Promise<ApiResponse<CustomersResponse>> {
        return this.apiFlow().get<CustomersResponse>(
            Endpoint.CUSTOMER_SEARCH,
            request
        );
    }

    productCheck(
        request: ProductChecksRequest
    ): Promise<ApiResponse<ProductCheckResponse>> {
        return this.apiFlow().get<ProductCheckResponse>(
            Endpoint.PRODUCT_CHECK,
            request
        );
    }

    createOrder(request: FormOrder): Promise<ApiResponse<OrderResponse>> {
        return this.apiFlow().post<OrderResponse>(Endpoint.ORDERS, request);
    }

    changeOrderStatus(
        request: ChangeOrderStatus
    ): Promise<ApiResponse<OrderResponse>> {
        return this.apiFlow().put<OrderResponse>(Endpoint.ORDER_STATUS, request);
    }

    getOrderDetail(
        request: OrderDetailRequest
    ): Promise<ApiResponse<OrderResponse>> {
        return this.apiFlow().get<OrderResponse>(Endpoint.ORDER_DETAIL, request);
    }

    getShippingMethod(
        request: ShippingMethodRequest
    ): Promise<ApiResponse<ShippingMethodResponse>> {
        return this.apiShipping().get<ShippingMethodResponse>(
            Endpoint.SHIPPING,
            request
        );
    }

    getShippingFee(
        request: ShippingFeeRequest
    ): Promise<ApiResponse<ShippingFeeResponse>> {
        return this.apiShipping().get<ShippingFeeResponse>(
            Endpoint.SHIPPING_FEE,
            request
        );
    }

    getShopCustomerNote(
        request: ShopCustomerHistoriesRequest
    ): Promise<ApiResponse<ShopCustomerHistoriesResponse>> {
        return this.apiFlow().get<ShopCustomerHistoriesResponse>(
            Endpoint.SHOP_CUSTOMER_HISTORIES,
            request
        );
    }

    addShopCustomerNote(
        request: CustomerActivity
    ): Promise<ApiResponse<ShopCustomerHistoriesResponse>> {
        return this.apiFlow().put<ShopCustomerHistoriesResponse>(
            Endpoint.SHOP_CUSTOMER_HISTORIES,
            request
        );
    }
   deleteShopCustomerNote(
        request: CustomerActivity
    ): Promise<ApiResponse<ShopCustomerHistoriesResponse>> {
        return this.apiFlow().delete<ShopCustomerHistoriesResponse>(
            Endpoint.SHOP_CUSTOMER_HISTORIES,
            request
        );
    }
    addCustomerTag(
        request: AddCustomerTagRequest
    ): Promise<ApiResponse<S_HttpResponse>> {
        return this.apiFlow().post<S_HttpResponse>(
            Endpoint.CUSTOMERS_ADD_TAG,
            request
        );
    }

    removeCustomerTag(
        request: AddCustomerTagRequest
    ): Promise<ApiResponse<S_HttpResponse>> {
        return this.apiFlow().post<S_HttpResponse>(
            Endpoint.CUSTOMERS_REMOVE_TAG,
            request
        );
    }

    getOverView(request: {force_new?: boolean}): Promise<ApiResponse<OverviewResponse>> {
        return this.apiAuthAPI().get<OverviewResponse>(Endpoint.OVERVIEW,request);
    }
    getActivePage(): Promise<ApiResponse<OverviewResponse>> {
        return this.apiAuthAPI().get<OverviewResponse>(Endpoint.ACTIVE_PAGE);
    }
    getWhatsAppDisabled(): Promise<ApiResponse<OverviewResponse>> {
        return this.apiAuthAPI().get<OverviewResponse>(Endpoint.WHATSAPP_DISABLED);
    }
    getOverViewFB(): Promise<ApiResponse<OverviewResponseFB>> {
        return this.apiAuthAPI().get<OverviewResponseFB>(Endpoint.OVERVIEWFB);
    }

    getOverViewITG(): Promise<ApiResponse<OverviewResponseITG>> {
        return this.apiAuthAPI().get<OverviewResponseITG>(Endpoint.OVERVIEWITG);
    }
    getOverViewZalo(): Promise<ApiResponse<OverviewResponseZalo>> {
        return this.apiAuthAPI().get<OverviewResponseZalo>(Endpoint.OVERVIEWZALO);
    }
    getOverviewReport(
        shopId: number,
        fromDate: string,
        toDate: string
    ): Promise<ApiResponse<OverviewResponseReport>> {
        return this.apiReport().get<OverviewResponseReport>(
            `page/conversation?shop_id=${shopId}&from_date=${fromDate}&to_date=${toDate}&interval=day&phone_agg=true`
        );
    }
    getZaloConfig(): Promise<ApiResponse<ZaloConfig>> {
        return this.apiAuthAPI().get<ZaloConfig>(Endpoint.ZALO_CONFIG_AUTH);
    }
    ZaloLogin(request: ZaloLoginRequest): Promise<ApiResponse<ZaloLogin>> {
        return this.apiAuthAPI().post<ZaloLogin>(Endpoint.ZALO_LOGIN,request);
    }
    ZaloRefreshToken(request: ZaloRefreshTokenRequest ): Promise<ApiResponse<ZaloLogin>> {
        return this.apiAuthAPI().get<ZaloLogin>(Endpoint.ZALO_REFRESH_TOKEN,request);
    }
    deleteOverview(
        request: OverviewRequest
    ): Promise<ApiResponse<OverviewResponseDelete>> {
        return this.apiAuthAPI().post<OverviewResponseDelete>(
            Endpoint.OVERVIEW_DELETE,
            request
        );
    }

    addOverview(
        request: OverviewRequest
    ): Promise<ApiResponse<OverviewResponseDelete>> {
        return this.apiAuthAPI().post<OverviewResponseDelete>(
            Endpoint.OVERVIEW_ADD,
            request
        );
    }

    instagramActivePage(
        request: OverviewRequest
    ): Promise<ApiResponse<OverviewResponseDelete>> {
        return this.apiAuthAPI().post<OverviewResponseDelete>(
            Endpoint.INSTAGRAM_ACTIVE_PAGE,
            request
        );
    }

    getShopInfo(): Promise<ApiResponse<ShopinfoResponse>> {
        return this.apiFlow().get<ShopinfoResponse>(Endpoint.SHOP_INFO);
    }

    updateShopInfo(
        request: ShopInfoRequest
    ): Promise<ApiResponse<ShopinfoUpdateResponse>> {
        return this.apiFlow().put<ShopinfoUpdateResponse>(
            Endpoint.SHOP_INFO,
            request
        );
    }

    integrationPlatformMail(
        request: IntegrationPlatformRequest
    ): Promise<ApiResponse<ShopinfoUpdateResponse>> {
        return this.apiFlow().post<ShopinfoUpdateResponse>(
            Endpoint.INTEGRATION_PLATFORMS_MAIL,
            request
        );
    }
    deleteIntegrationPlatformMail(
        request: DeleteIntegrationPaltFormRequest
    ): Promise<ApiResponse<ShopinfoUpdateResponse>> {
        return this.apiFlow().delete<ShopinfoUpdateResponse>(
            Endpoint.INTEGRATION_PLATFORMS_MAIL,
            request
        );
    }

    getEmployeeSearch(
        name: string
    ): Promise<ApiResponse<EmployeeResponseSearch>> {
        return this.apiFlow().get<EmployeeResponseSearch>(
            Endpoint.EMPLOYEE_SEARCH(name)
        );
    }

    getEmployee(
        request: EmployeeRequest
    ): Promise<ApiResponse<EmployeeResponse>> {
        return this.apiFlow().get<EmployeeResponse>(Endpoint.EMPLOYEE, request);
    }

    addEmployee(
        request: EmployeeEditRequest
    ): Promise<ApiResponse<EmployeeResponseEdit>> {
        return this.apiFlow().post<EmployeeResponseEdit>(
            Endpoint.EMPLOYEE,
            request
        );
    }

    loginEmail(request: LoginEmailRequest): Promise<ApiResponse<LoginResponse>> {
        return this.apiAuth().post<LoginResponse>(Endpoint.LOGIN_EMAIL, request);
    }

    loginFacebook(request: LoginFacebookRequest): Promise<ApiResponse<LoginResponse>> {
        return this.apiAuth().post<LoginResponse>(Endpoint.LOGIN_FACEBOOK, request);
    }

    loginGoogle(request: LoginGoogleRequest): Promise<ApiResponse<LoginResponse>> {
        return this.apiAuth().post<LoginResponse>(Endpoint.LOGIN_GOOGLE, request);
    }

    editEmployee(
        request: EmployeeEditRequest
    ): Promise<ApiResponse<EmployeeResponseEdit>> {
        return this.apiFlow().put<EmployeeResponseEdit>(
            Endpoint.EMPLOYEE,
            request
        );
    }

    deleteEmployee(
        request: EmployeeEditRequest
    ): Promise<ApiResponse<EmployeeResponseEdit>> {
        return this.apiFlow().delete<EmployeeResponseEdit>(Endpoint.EMPLOYEE, request);
    }

    loginWebToken(request: LoginWebTokenRequest): Promise<ApiResponse<LoginResponse>> {
        return this.apiAuth().post<LoginResponse>(Endpoint.LOGIN_WEB_TOKEN, request);
    }

    getReportComment(request: ReportPageRequest): Promise<ApiResponse<ReportPageCommentResponse>> {
        return this.apiReport().get<ReportPageCommentResponse>(Endpoint.REPORT_PAGE_COMMENT, request);
    }
    getReportOderConverSion(request: any): Promise<ApiResponse<DefaultResponse>> {
        return this.apiReport().get<DefaultResponse>(Endpoint.REPORT_CONVERSION_ORDER, request);
    }
    getReportMessage(request: ReportPageRequest): Promise<ApiResponse<ReportPageCommentResponse>> {
        return this.apiReport().get<ReportPageCommentResponse>(Endpoint.REPORT_PAGE_MESSAGE, request);
    }
    getReportPage(request: ReportPageRequest): Promise<ApiResponse<ReportPageCommentResponse>> {
        return this.apiReport().get<ReportPageCommentResponse>(Endpoint.REPORT_PAGE, request);
    }
    getReportStaff(request: ReportPageRequest): Promise<ApiResponse<ReportPageCommentResponse>> {
        return this.apiReport().get<ReportPageCommentResponse>(Endpoint.REPORT_STAFF, request);
    }

    getReportNewConversation(request: ReportPageRequest): Promise<ApiResponse<ReportPageCommentResponse>> {
        return this.apiReport().get<ReportPageCommentResponse>(Endpoint.REPORT_PAGE_NEW_CONVERSATION, request);
    }

    getBank(): Promise<ApiResponse<BankResponse>> {
        return this.apiFlow().get<BankResponse>(Endpoint.BANK);
    }

    zaloActivePage(request: ZaloActivePageRequest): Promise<ApiResponse<ZaloActivePage>> {
        return this.apiAuthAPI().post<ZaloActivePage>(Endpoint.ZALO_ACTIVE_PAGE, request);
    }

    addPageFB(request: AddPageFBRequest): Promise<ApiResponse<S_HttpResponse>> {
        return this.apiAuthAPI().post<S_HttpResponse>(Endpoint.ADD_PAGE_FB, request);
    }
    addPageFBV2(request: AddPageFBRequest): Promise<ApiResponse<S_HttpResponse>> {
        return this.apiAuthAPI().post<S_HttpResponse>(Endpoint.ADD_PAGE_FB_V2, request);
    }
    pageUser(request: AddPageFBRequest): Promise<ApiResponse<S_HttpResponse>> {
        return this.apiAuthAPI().post<S_HttpResponse>(Endpoint.PAGE_USER, request);
    }

    getTariffs(): Promise<ApiResponse<TariffResponse>> {
        return this.apiFlow().get<TariffResponse>(Endpoint.TARIFFS);
    }

    checkCoupon(request: CheckCouponRequest): Promise<ApiResponse<CouponResponse>> {
        return this.apiFlow().post<CouponResponse>(Endpoint.COUPON, request);
    }

    getNotificationByUid(request: NotificationQuery): Promise<ApiResponse<NotificationsResponse>> {
        return this.apiFlow().get<NotificationsResponse>(Endpoint.NOTIFICATION_BY_UID, request);
    }

    getUnreadNotificationByUid(): Promise<ApiResponse<UnreadNotificationResponse>> {
        return this.apiFlow().get<UnreadNotificationResponse>(Endpoint.UNREAD_NOTIFICATION_BY_UID);
    }

    scheduleEmployee(req: FormSubmitSchedule): Promise<ApiResponse<S_HttpResponse>> {
        return this.apiFlow().post<S_HttpResponse>(Endpoint.NOTIFICATION_SCHEDULE_EMPLOYEE, req);
    }

    readNotification(id: string): Promise<ApiResponse<ReadNotificationResponse>> {
        return this.apiFlow().post<ReadNotificationResponse>(Endpoint.READ_NOTIFICATION, {id: id});
    }

    getSchedules(req: SchedulesReq): Promise<ApiResponse<SchedulesResponse>> {
        return this.apiFlow().post<SchedulesResponse>(Endpoint.NOTIFICATION_SCHEDULES, req);
    }

    deleteSchedules(id: string): Promise<ApiResponse<ReadNotificationResponse>> {
        return this.apiFlow().delete<ReadNotificationResponse>(Endpoint.NOTIFICATION_SCHEDULES_DELETE, {id: id});
    }

    saveTariffs(request: SaveTariffRequest): Promise<ApiResponse<TariffsResponse>> {
        return this.apiFlow().post<TariffsResponse>(Endpoint.TARIFFS, request);
    }

    getShopServices(): Promise<ApiResponse<ShopServicesResponse>> {
        return this.apiFlow().get<ShopServicesResponse>(Endpoint.SHOP_SERVICES);
    }

    deleteShopServices(request: DeleteShopServicesRequest): Promise<ApiResponse<S_HttpResponse>> {
        return this.apiFlow().delete<S_HttpResponse>(Endpoint.SHOP_SERVICES, request);
    }
    confirmVNPay(request: any): Promise<ApiResponse<S_HttpResponse>> {
        return this.apiFlow().post<S_HttpResponse>(Endpoint.SHOP_SERVICES_CONFIRM_VNPAY, request);
    }

    getReportCustomer(request: any): Promise<ApiResponse<S_HttpResponse>> {
        return this.apiReport().get<S_HttpResponse>(Endpoint.REPORT_CUSTOMER, request);
    }

    getTriggerStatistics(request: TriggerStatisticRequest): Promise<ApiResponse<TriggerStatisticResponse>> {
        return this.apiFlow().get<TriggerStatisticResponse>(Endpoint.TRIGGER_STATISTICS, request);
    }
    getViral(request: TriggerStatisticRequest): Promise<ApiResponse<ViralResponse>> {
        return this.apiFlow().get<ViralResponse>(Endpoint.VIRAL, request);
    }
    getViralUser(request: ViralUserRequest): Promise<ApiResponse<ViralUserResponse>> {
        return this.apiFlow().get<ViralUserResponse>(Endpoint.VIRAL_USER, request);
    }
    getTriggerInviteLink(
        request: TriggerInviteLink
    ): Promise<ApiResponse<ViralUserResponse>> {
        return this.apiFlow().get<ViralUserResponse>(
            Endpoint.TRIGGER_INVITE_LINK,
            request
        );
    }
    getTriggerEstimate(request: TriggerEstimateRequest): Promise<ApiResponse<ViralUserResponse>> {
        return this.apiFlow().get<ViralUserResponse>(Endpoint.TRIGGER_ESTIMATE, request);
    }
    getInnerTriggerStatistics(request: InnerTriggerStatisticRequest): Promise<ApiResponse<TriggerStatisticResponse>> {
        return this.apiFlow().get<TriggerStatisticResponse>(Endpoint.TRIGGER_INNER_STATISTICS, request);
    }
    triggerSendUpdateToOne(request: TriggerSendUpdateToOne): Promise<ApiResponse<S_HttpResponse>> {
        return this.apiFlow().post<S_HttpResponse>(Endpoint.TRIGGER_SEND_UPDATE_TO_ONE, request);
    }
    triggerSendUpdateToOneBySaleTag(request: TriggerSendUpdateToOneByTag): Promise<ApiResponse<S_HttpResponse>> {
        return this.apiFlow().post<S_HttpResponse>(Endpoint.TRIGGER_SEND_UPDATE_TO_ONE_BY_SALE_TAG, request);
    }
    triggerSendUpdateToOneBySaleTag2(request: TriggerSendUpdateToOneByTag): Promise<ApiResponse<S_HttpResponse>> {
        return this.apiFlow().post<S_HttpResponse>(Endpoint.TRIGGER_SEND_UPDATE_TO_ONE_BY_SALE_TAG2, request);
    }
    triggerSendToOne(request: TriggerSendToOne): Promise<ApiResponse<S_HttpResponse>> {
        return this.apiFlow().post<S_HttpResponse>(Endpoint.TRIGGER_SEND_TO_ONE, request);
    }

    getTriggerStatisticLogs(request: InnerTriggerStatisticRequest): Promise<ApiResponse<TriggerStatisticErrorLogResponse>> {
        return this.apiFlow().get<TriggerStatisticErrorLogResponse>(Endpoint.TRIGGER_STATISTICS_LOGS, request);
    }
    getReportCustomerStat(request: any): Promise<ApiResponse<S_HttpResponse>> {
        return this.apiReport().get<S_HttpResponse>(Endpoint.REPORT_CUSTOMER_STAT, request);
    }
    getReportCustomerStatDay(request: any): Promise<ApiResponse<S_HttpResponse>> {
        return this.apiReport().get<S_HttpResponse>(Endpoint.REPORT_CUSTOMER_STAT_DAY, request);
    }
    getReportCustomerStatMonth(request: any): Promise<ApiResponse<S_HttpResponse>> {
        return this.apiReport().get<S_HttpResponse>(Endpoint.REPORT_CUSTOMER_STAT_MONTH, request);
    }
    getTokenLongAccess(request: any): Promise<ApiResponse<S_HttpResponse>> {
        return this.graphFB().get<S_HttpResponse>(Endpoint.TOKEN_LONG_ACCESS, request);
    }
    getLinkInvite(request: any): Promise<ApiResponse<S_HttpResponse>> {
        return this.apiFlow().get<S_HttpResponse>(Endpoint.EMPLOYEE_LINK_INVITE, request);
    }
    invite(request: any): Promise<ApiResponse<S_HttpResponse>> {
        return this.apiFlow().get<S_HttpResponse>(Endpoint.EMPLOYEE_INVITE, request);
    }

    getFlowTemplateViewer(): Promise<ApiResponse<S_HttpResponse>> {
        return this.apiFlow().get<S_HttpResponse>(Endpoint.FLOW_TEMPLATE_EMAIL_VIEWER);
    }
    shippingCreateOrder(request: Orders): Promise<ApiResponse<S_HttpResponse>> {
        return this.apiShipping().post<S_HttpResponse>(Endpoint.SHIPPING_CREATE_ORDER, request);
    }
    getCareers(request: GetRequest): Promise<ApiResponse<CareersResponse>> {
        return this.apiFlow().get<CareersResponse>(Endpoint.CAREERS, request);
    }
    createCareer(request: CreateCareerRequest): Promise<ApiResponse<CareerResponse>> {
        return this.apiFlow().post<CareerResponse>(Endpoint.CAREERS, request);
    }
    updateCareer(request: any): Promise<ApiResponse<CareerResponse>> {
        return this.apiFlow().put<CareerResponse>(Endpoint.CAREERS, request);
    }
    deleteCareer(request: any): Promise<ApiResponse<CareerResponse>> {
        return this.apiFlow().delete<CareerResponse>(Endpoint.CAREERS, request);
    }
    getMailList(): Promise<ApiResponse<S_HttpMailPagesResponse>> {
        return this.apiFlow().get<S_HttpMailPagesResponse>(Endpoint.PAGE_MAIL_LIST);
    }
    getContact(request: any): Promise<ApiResponse<ContactResponse>> {
        return this.apiFlow().get<ContactResponse>(Endpoint.CONTACTS, request);
    }
    createContact(request: any): Promise<ApiResponse<ContactResponse>> {
        return this.apiFlow().post<ContactResponse>(Endpoint.CONTACTS, request);
    }
    updateContact(request: any): Promise<ApiResponse<ContactResponse>> {
        return this.apiFlow().put<ContactResponse>(Endpoint.CONTACTS, request);
    }
    deleteContact(request: any): Promise<ApiResponse<ContactResponse>> {
        return this.apiFlow().delete<ContactResponse>(Endpoint.CONTACTS, request);
    }
    updateCMSUser(request: any): Promise<ApiResponse<DefaultResponse>> {
        return this.apiFlow().put<ContactResponse>(Endpoint.CMS_USERS, request);
    }
    getShops(): Promise<ApiResponse<ShopsResponse>> {
        return this.apiFlow().get<ShopsResponse>(Endpoint.SHOPS);
    }
    getRoles(): Promise<ApiResponse<RolesResponse>> {
        return this.apiFlow().get<RolesResponse>(Endpoint.ROLES);
    }
    createRole(request: any): Promise<ApiResponse<RoleResponse>> {
        return this.apiFlow().post<RoleResponse>(Endpoint.ROLES, request);
    }
    updateRole(request: any): Promise<ApiResponse<RoleResponse>> {
        return this.apiFlow().put<RoleResponse>(Endpoint.ROLES, request);
    }
    deleteRole(request: any): Promise<ApiResponse<RoleResponse>> {
        return this.apiFlow().delete<RoleResponse>(Endpoint.ROLES, request);
    }
    getPrivilegeRole(request: any): Promise<ApiResponse<PrivilegeRoleResponse>> {
        return this.apiFlow().get<PrivilegeRoleResponse>(Endpoint.PRIVILEGE_ROLE, request);
    }
    updatePrivilegeRole(request: any): Promise<ApiResponse<PrivilegeRoleResponse>> {
        return this.apiFlow().put<PrivilegeRoleResponse>(Endpoint.PRIVILEGE_ROLE, request);
    }
    syncRoles(): Promise<ApiResponse<S_HttpResponse>> {
        return this.apiFlow().post<S_HttpResponse>(Endpoint.SYNC_ROLES);
    }
    getDepartments(): Promise<ApiResponse<DepartmentsResponse>> {
        return this.apiFlow().get<DepartmentsResponse>(Endpoint.DEPARTMENTS);
    }
    createDepartment(request: any): Promise<ApiResponse<DepartmentResponse>> {
        return this.apiFlow().post<DepartmentResponse>(Endpoint.DEPARTMENTS, request);
    }
    updateDepartment(request: any): Promise<ApiResponse<DepartmentResponse>> {
        return this.apiFlow().put<DepartmentResponse>(Endpoint.DEPARTMENTS, request);
    }
    deleteDepartment(request: any): Promise<ApiResponse<DepartmentResponse>> {
        return this.apiFlow().delete<DepartmentResponse>(Endpoint.DEPARTMENTS, request);
    }
    reportCustomerTagByStaff(request: any): Promise<ApiResponse<DefaultResponse>> {
        return this.apiFlow().get<DefaultResponse>(Endpoint.REPORT_CUSTOMER_TAG_BY_STAFF, request);
    }
    reportCustomerCareByStaff(request: any): Promise<ApiResponse<DefaultResponse>> {
        return this.apiFlow().get<DefaultResponse>(Endpoint.REPORT_CUSTOMER_CARE_BY_STAFF, request);
    }
    reportCustomerTag(request: any): Promise<ApiResponse<DefaultResponse>> {
        return this.apiFlow().get<DefaultResponse>(Endpoint.REPORT_CUSTOMER_TAG, request);
    }
    cxViewHistory(request: any): Promise<ApiResponse<DefaultResponse>> {
        return this.apiFlow().get<DefaultResponse>(Endpoint.CXVIEW_HISTORY, request);
    }
    exportZns(request: FlowZNS_EXPORT_TransactionRequest): Promise<ApiResponse<CustomerConfigColumnResponse>> {
        return this.apiFlow().get<CustomerConfigColumnResponse>(Endpoint.ZNS_EXPORT, request);
    }
    reportCustomerCare(request: any): Promise<ApiResponse<DefaultResponse>> {
        return this.apiFlow().get<DefaultResponse>(Endpoint.REPORT_CUSTOMER_CARE, request);
    }
    createdVoucher(request: VoucherCreateRequest): Promise<ApiResponse<VoucherResponse>>{
        return this.apiFlow().post<VoucherResponse>(Endpoint.VOUCHER,request)
    }
    updatedVoucher(request: VoucherRequest): Promise<ApiResponse<VoucherResponse>>{
        return this.apiFlow().put<VoucherResponse>(Endpoint.VOUCHER,request)
    }
   voucherTime(request: VoucherTimeApplicationRequest): Promise<ApiResponse<VoucherTimeResponse>>{
        return this.apiFlow().post<VoucherTimeResponse>(Endpoint.VOUCHER_TIME,request)
    }
    voucherConfig(request: VoucherConfigRequest): Promise<ApiResponse<VoucherConfigResponse>>{
        return this.apiFlow().post<VoucherConfigResponse>(Endpoint.VOUCHER_CONFIG,request)
    }
    getVouchers(request: VouchersRequest):Promise<ApiResponse<VouchersResponse>>{
        return this.apiFlow().get<VouchersResponse>(Endpoint.VOUCHER,request)
    }
    getVoucherDetail(request: VoucherDetailRequest):Promise<ApiResponse<VoucherResponse>>{
        return this.apiFlow().get<VoucherResponse>(Endpoint.VOUCHER_Detail,request)
    }
    getVoucherTime(request: VoucherDetailRequest):Promise<ApiResponse<VoucherTimeResponse>>{
        return this.apiFlow().get<VoucherTimeResponse>(Endpoint.VOUCHER_TIME,request)
    }
    updateVoucherTime(request: VoucherTimeApplicationRequest):Promise<ApiResponse<VoucherTimeResponse>>{
        return this.apiFlow().put<VoucherTimeResponse>(Endpoint.VOUCHER_TIME,request)
    }
    getVoucherConfig(request: VoucherDetailRequest):Promise<ApiResponse<VoucherConfigResponse>>{
        return this.apiFlow().get<VoucherConfigResponse>(Endpoint.VOUCHER_CONFIG,request)
    }
    updateVoucherConfig(request: VoucherConfigRequest):Promise<ApiResponse<VoucherConfigResponse>>{
        return this.apiFlow().put<VoucherConfigResponse>(Endpoint.VOUCHER_CONFIG,request)
    }
    useVoucher(request: any):Promise<ApiResponse<any>>{
        return this.apiFlow().put<any>(Endpoint.USE_VOUCHER,request)
    }
    deleteVoucher(request: VoucherDetailRequest):Promise<ApiResponse<VouchersResponse>>{
        return this.apiFlow().delete<VouchersResponse>(Endpoint.VOUCHER,request)
    }
    stopVoucher(request: VoucherStopRequest): Promise<ApiResponse<VoucherResponse>>{
        return this.apiFlow().put<VoucherResponse>(Endpoint.VOUCHER_STOP,request)
    }
    activeVoucher(request: VoucherStopRequest): Promise<ApiResponse<VoucherResponse>>{
        return this.apiFlow().put<VoucherResponse>(Endpoint.VOUCHER_ACTIVE,request)
    }
    getaActiveVoucher(request: VoucherStopRequest): Promise<ApiResponse<VoucherResponse>>{
        return this.apiFlow().get<VoucherResponse>(Endpoint.VOUCHER_ACTIVE,request)
    }
    createdMemberRank(request: MemberRankRequest): Promise<ApiResponse<MemberRankResponse>>{
        return this.apiFlow().post<MemberRankResponse>(Endpoint.MEMBER_RANK,request)
    }
    createdMemberRankConfig(request: MemberRankConfigRequest): Promise<ApiResponse<MemberRankConfigResponse>>{
        return this.apiFlow().post<MemberRankConfigResponse>(Endpoint.MEMBER_CONFIG_RANK,request)
    }
   updateMemberRankConfig(request: MemberRankConfigRequest): Promise<ApiResponse<MemberRankConfigResponse>>{
        return this.apiFlow().put<MemberRankConfigResponse>(Endpoint.MEMBER_CONFIG_RANK,request)
    }
    getMemberRank(): Promise<ApiResponse<MemberRanksResponse>>{
        return this.apiFlow().get<MemberRanksResponse>(Endpoint.MEMBER_RANK)
    }
    updateMemberRank(request: MemberRankRequest): Promise<ApiResponse<MemberRankResponse>>{
        return this.apiFlow().put<MemberRankResponse>(Endpoint.MEMBER_RANK,request)
    }
    stopMemberRank(request: MemberRankRequest): Promise<ApiResponse<MemberRankResponse>>{
        return this.apiFlow().put<MemberRankResponse>(Endpoint.MEMBER_RANK_STOP,request)
    }
    activeMemberRank(request: MemberRankRequest): Promise<ApiResponse<MemberRankResponse>>{
        return this.apiFlow().put<MemberRankResponse>(Endpoint.MEMBER_RANK_ACTIVE,request)
    }
    getMemberRankDetail(request: MemberRankRequest): Promise<ApiResponse<MemberRankResponse>>{
        return this.apiFlow().get<MemberRankResponse>(Endpoint.MEMBER_RANK_DETAIL,request)
    }
    getMemberRankConfig(request: MemberRankRequest): Promise<ApiResponse<MemberRankConfigResponse>> {
        return this.apiFlow().get<MemberRankConfigResponse>(Endpoint.MEMBER_CONFIG_RANK, request)
    }
   whatSappNewClient(request: {shop_id:number}): Promise<ApiResponse<DepartmentResponse>> {
        return this.apiWhatsapp().post<DepartmentResponse>(Endpoint.NEW_CLIENT, request);
    }
    qrCodeWhatsApp(request: {file_name:any}): Promise<ApiResponse<DepartmentResponse>> {
        return this.apiWhatsapp().get<DepartmentResponse>(Endpoint.PIPE_ASSET, request);
    }
    stateWhatsApp(request: {page_id:any}): Promise<ApiResponse<DepartmentResponse>> {
        return this.apiWhatsapp().post<DepartmentResponse>(Endpoint.STATE, request);
    }

    getTickets(request: TicketRequest): Promise<ApiResponse<TicketResponse>> {
        return this.apiFlow().get<TicketResponse>(Endpoint.TICKETS, request);
    }
    addTickets(request: Ticketcreated): Promise<ApiResponse<TicketResponse>> {
        return this.apiFlow().post<TicketResponse>(Endpoint.TICKETS, request);
    }
    updateTickets(request: Ticketcreated): Promise<ApiResponse<TicketResponse>> {
        return this.apiFlow().put<TicketResponse>(Endpoint.TICKETS, request);
    }
    deleteTickets(request: Ticketcreated): Promise<ApiResponse<TicketResponse>> {
        return this.apiFlow().delete<TicketResponse>(Endpoint.TICKETS, request);
    }
    getTicketTypes(request: TicketTypeRequest): Promise<ApiResponse<TicketTypeResponse>> {
        return this.apiFlow().get<TicketTypeResponse>(Endpoint.TICKET_TYPE, request);
    }
    addTicketType(request: TicketTypeCreated): Promise<ApiResponse<TicketTypeResponse>> {
        return this.apiFlow().post<TicketTypeResponse>(Endpoint.TICKET_TYPE, request);
    }
    updateTicketType(request: TicketTypeCreated): Promise<ApiResponse<TicketTypeResponse>> {
        return this.apiFlow().put<TicketTypeResponse>(Endpoint.TICKET_TYPE, request);
    }
    deleteTicketType(request: TicketTypeCreated): Promise<ApiResponse<TicketTypeResponse>> {
        return this.apiFlow().delete<TicketTypeResponse>(Endpoint.TICKET_TYPE, request);
    }

    getTicketPrices(request: TicketTypeRequest): Promise<ApiResponse<TicketPriceResponse>> {
        return this.apiFlow().get<TicketPriceResponse>(Endpoint.TICKET_PRICES, request);
    }
    addTicketPrice(request: TicketPriceCreated): Promise<ApiResponse<TicketPriceResponse>> {
        return this.apiFlow().post<TicketTypeResponse>(Endpoint.TICKET_PRICES, request);
    }
    updateTicketPrice(request: TicketPriceCreated): Promise<ApiResponse<TicketPriceResponse>> {
        return this.apiFlow().put<TicketTypeResponse>(Endpoint.TICKET_PRICES, request);
    }
    deleteTicketPrice(request: TicketPriceCreated): Promise<ApiResponse<TicketPriceResponse>> {
        return this.apiFlow().delete<TicketTypeResponse>(Endpoint.TICKET_PRICES, request);
    }

    getTicketTemplate(request: EventRequest): Promise<ApiResponse<TicketTemplateResponse>> {
        return this.apiFlow().get<TicketTemplateResponse>(Endpoint.TICKET_TEMPLATE, request);
    }
    addTicketTemplate(request: TicketTemplateRequest): Promise<ApiResponse<TicketTemplateResponse>> {
        return this.apiFlow().post<TicketTemplateResponse>(Endpoint.TICKET_TEMPLATE, request);
    }
    updateTicketTemplate(request: TicketTemplateRequest): Promise<ApiResponse<TicketTemplateResponse>> {
        return this.apiFlow().put<TicketTemplateResponse>(Endpoint.TICKET_TEMPLATE, request);
    }
    deleteTicketTemplate(request: TicketTemplateRequest): Promise<ApiResponse<TicketTemplateResponse>> {
        return this.apiFlow().delete<TicketTemplateResponse>(Endpoint.TICKET_TEMPLATE, request);
    }

    getEvent(request: EventRequest): Promise<ApiResponse<EventResponse>> {
        return this.apiFlow().get<EventResponse>(Endpoint.EVENTS, request);
    }
    addEvent(request: EventsRequest): Promise<ApiResponse<DepartmentResponse>> {
        return this.apiFlow().post<DepartmentResponse>(Endpoint.EVENTS, request);
    }
    updateEvent(request: EventsRequest): Promise<ApiResponse<DepartmentResponse>> {
        return this.apiFlow().put<DepartmentResponse>(Endpoint.EVENTS, request);
    }
    deleteEvent(request: EventsRequest): Promise<ApiResponse<DepartmentResponse>> {
        return this.apiFlow().delete<DepartmentResponse>(Endpoint.EVENTS, request);
    }

    confirmPaymentVNPay(request: any): Promise<ApiResponse<AnyResponse>> {
        return this.apiPartnerSync().post<AnyResponse>(Endpoint.CONFIRM_VNPAY, request);
    }
    sendTemplateEmail(request:any):Promise<ApiResponse<AnyResponse>> {
        return this.apiPartnerSync2().post<AnyResponse>(Endpoint.TICKETS, request);

    }
    ladingPageTicket(request: any): Promise<ApiResponse<AnyResponse>> {
        return this.apiPartnerSync().post<AnyResponse>(Endpoint.LANDING_PAGE, request, {headers: {
                target: "partner-sync",
                shopid: request.shop_id
            }});
    }
    ladingPageSingle(request: any): Promise<ApiResponse<AnyResponse>> {
        return this.apiPartnerSync().post<AnyResponse>(Endpoint.LANDING_PAGE_SINGLE, request, {headers: {
                target: "partner-sync",
                shopid: request.shop_id
            }});
    }
    CheckinTicket(request: any):Promise<ApiResponse<AnyResponse>> {
        return this.apiFlow().post<AnyResponse>(Endpoint.Ticket_CHECKIN, request);
    }
    getTransactions(request: any):Promise<ApiResponse<AnyResponse>> {
        return this.apiFlow().get<AnyResponse>(Endpoint.TICKET_TRANSACTIONS, request);
    }
    updateTransactions(request: any):Promise<ApiResponse<AnyResponse>> {
        return this.apiFlow().put<AnyResponse>(Endpoint.TICKET_TRANSACTIONS, request);
    }
    payment(request:any):Promise<ApiResponse<AnyResponse>> {
        return this.apiPartnerSync2().post<AnyResponse>(Endpoint.PAYMENT, request);

    }
    addHistoryCustomerChange(request: any):Promise<ApiResponse<AnyResponse>> {
        return this.apiFlow().post<AnyResponse>(Endpoint.HISTORY_CUSTOMER_CHANGE, request);
    }
    getHistoryCustomerChange(request: any):Promise<ApiResponse<AnyResponse>> {
        return this.apiFlow().get<AnyResponse>(Endpoint.HISTORY_CUSTOMER_CHANGE, request);
    }
    addCustomerUseVoucher(request: {customer_id:number,voucher_id: string,order_number:string}):Promise<ApiResponse<AnyResponse>> {
        return this.apiFlow().post<AnyResponse>(Endpoint.CUSTOMER_USE_VOUCHER, request);
    }
    customerUseVoucher(request: any):Promise<ApiResponse<AnyResponse>> {
        return this.apiFlow().get<AnyResponse>(Endpoint.CUSTOMER_USE_VOUCHER, request);
    }
    shippingConnect(request: any):Promise<ApiResponse<AnyResponse>> {
        return this.apiShipping().post<AnyResponse>(Endpoint.SHIPPING_CONNECT, request);
    }
    changeShop(request: any):Promise<ApiResponse<DefaultResponse>> {
        return this.apiAuthAPI().post<AnyResponse>(Endpoint.CHANGE_SHOP, request);
    }
    checkinReport(request: any):Promise<ApiResponse<DefaultResponse>> {
        return this.apiFlow().get<AnyResponse>(Endpoint.CHECKIN_REPORT, request);
    }
    importTicketXLS(request: any):Promise<ApiResponse<DefaultResponse>> {
        return this.apiFlow().post<AnyResponse>(Endpoint.IMPORT_TICKET_XLS, request);
    }
    importOrderXLS(request: any):Promise<ApiResponse<DefaultResponse>> {
        return this.apiFlow().post<AnyResponse>(Endpoint.IMPORT_ORDER_XLS, request);
    }
    checkinReportXLS(request: any):Promise<ApiResponse<DefaultResponse>> {
        return this.apiFlow().get<AnyResponse>(Endpoint.CHECKIN_REPORT_XLS, request, {
            responseType: 'blob',
        });
    }
    productImport(request: any):Promise<ApiResponse<DefaultResponse>> {
        return this.apiFlow().post<AnyResponse>(Endpoint.PRODUCT_IMPORT, request,);
    }
    productExport(request: any):Promise<ApiResponse<DefaultResponse>> {
        return this.apiFlow().get<AnyResponse>(Endpoint.PRODUCT_EXPORT,request,{
            responseType: 'blob',
        } );
    }
    facebookPostEventApi(
        request: any
    ): Promise<ApiResponse<ViralResultResponse>> {
        return this.apiAuthAPI().post<ViralResultResponse>(
            Endpoint.FACEBOOK_EVENT_API,
            request
        );
    }

    createUsePoint(
        request: any
    ): Promise<ApiResponse<DefaultResponse>> {
        return this.apiFlow().post<DefaultResponse>(
            Endpoint.USE_POINT,
            request
        );
    }
    updateUsePoint(
        request: any
    ): Promise<ApiResponse<DefaultResponse>> {
        return this.apiFlow().put<DefaultResponse>(
            Endpoint.USE_POINT,
            request
        );
    }
   cancelUsePoint(
        request: any
    ): Promise<ApiResponse<DefaultResponse>> {
        return this.apiFlow().put<DefaultResponse>(
            Endpoint.USE_POINT,
            request
        );
    }
    getUsePoint(
        request: any
    ): Promise<ApiResponse<DefaultResponse>> {
        return this.apiFlow().get<DefaultResponse>(
            Endpoint.USE_POINT,
            request
        );
    }
    getUsePoints(
        request: any
    ): Promise<ApiResponse<DefaultResponse>> {
        return this.apiFlow().get<DefaultResponse>(
            Endpoint.USE_POINTS,
            request
        );
    }

    getPreviewOrder(
        request: any
    ): Promise<ApiResponse<DefaultResponse>> {
        return this.apiFlowPreviewOder().get<DefaultResponse>(
            Endpoint.PREVIEW_ORDER,
            request
        );
    }

    moreMoney(
        request: any
    ): Promise<ApiResponse<DefaultResponse>> {
        return this.apiFlow().put<DefaultResponse>(
            Endpoint.MORE_MONEY,
            request
        );
    }

    MergeCustomer(
        request: any
    ): Promise<ApiResponse<DefaultResponse>> {
        return this.apiFlow().put<DefaultResponse>(
            Endpoint.MERGE_CUSTOMER,
            request
        );
    }
    ScheduleTrigger(
        request: any
    ): Promise<ApiResponse<DefaultResponse>> {
        return this.apiFlow().post<DefaultResponse>(
            Endpoint.SCHEDULE_TRIGGER,
            request
        );
    }
    AffiliateSyncProduct(
        request: any
    ): Promise<ApiResponse<DefaultResponse>> {
        return this.apiFlow().post<DefaultResponse>(
            Endpoint.AFFILIATE_SYNC_PRODUCT,
            request
        );
    }

    importSchedule(request: FormData): Promise<ApiResponse<S_HttpResponse>> {
        return this.apiFlow().post<ExportResponse>(
            Endpoint.SCHEDULE_IMPORT,
            request
        );
    }

    getUrlFacebook(request: any): Promise<ApiResponse<S_HttpResponse>> {
        return this.apiAuth().post<ExportResponse>(
            Endpoint.FACEBOOK_UID,
            request
        );
    }
    getConversion(request: any): Promise<ApiResponse<S_HttpResponse>> {
        return this.apiAuthAPI().get<ConversionReportResponse>(
            Endpoint.CONVERSION_REPORT,
            request
        );
    }
    getReportOrder(request: ReportOrderRequest): Promise<ApiResponse<S_HttpResponse>> {
        return this.apiFlow().get<AnyResponse>(
            Endpoint.REPORT_ORDER,
            request
        );
    }
    fbCheckToken(request:any):Promise<ApiResponse<S_HttpResponse>> {
        return this.apiAuthAPI().get<AnyResponse>(
            Endpoint.FB_CHECK_TOKEN,
            request
        );
    }
    reportCustomerOrder(request:any):Promise<ApiResponse<S_HttpResponse>> {
    return this.apiFlow().get<AnyResponse>(
        Endpoint.REPROT_CUSTOMER_ORDER,
    request
);
}
    reportRevenue(request:any):Promise<ApiResponse<S_HttpResponse>> {
        return this.apiFlow().get<AnyResponse>(
            Endpoint.REPORT_REVENUE,
            request
        );
    }
    reportSale(request:any):Promise<ApiResponse<S_HttpResponse>> {
        return this.apiFlow().get<AnyResponse>(
            Endpoint.REPORT_SALE,
            request
        );
    }
    reportEmployee(request:any):Promise<ApiResponse<S_HttpResponse>> {
        return this.apiFlow().get<AnyResponse>(
            Endpoint.REPORT_EMPLOYEE,
            request
        );
    }
    reportCustomerRange(request?:any):Promise<ApiResponse<S_HttpResponse>> {
        return this.apiFlow().get<AnyResponse>(
            Endpoint.REPORT_CUSTOMER_RANGE+`?indicator=${request.indicator}&range=${request.min}&range=${request.max}`,

        );
    }
    reportCustomerOrganizations(request?:any):Promise<ApiResponse<S_HttpResponse>> {
        return this.apiFlow().get<AnyResponse>(
            Endpoint.REPORT_CUSTOMER_ORGANIZATIONS,
            request
        );
    }
    reportCustomerSegments(request:any):Promise<ApiResponse<S_HttpResponse>> {
        return this.apiFlow().get<AnyResponse>(
            Endpoint.REPORT_CUSTOMER_SEGMENTS+request,
        );
    }
    reportCustomerSegmentsCount(request?:any):Promise<ApiResponse<S_HttpResponse>> {
        return this.apiFlow().get<AnyResponse>(
            Endpoint.REPORT_CUSTOMER_SEGMENTS_COUNT,
            request
        );
    }
    exportViral(
        request: any
    ): Promise<ApiResponse<ExportResponse>> {
        return this.apiFlow().get<ExportResponse>(
            Endpoint.EXPORT_VIRAL,
            request,
            {
                responseType: 'blob',
            }
        );
    }
}

