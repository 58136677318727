<template>
  <div class="container">
    <a-row class="header"   justify="start">
      <img
          src="@/assets/icons/messenger.svg"
          style="width: 28px;"/>
      <a-col>
        <div class="description">Facebook</div>
        <span class="content">{{data.name}}</span>
      </a-col>

    </a-row>
    <FacebookPostbackPreview :flowContext="data"  type="flow-new"/>
    <Handle :id="data.id" type="target" class="handle" :position="Position.Left" :style="sourceHandleStyleA" />
    <div class="footer">
      <div  style="height: 2px; background-color: #f2f4f6;"/>

      <template v-for="(handle, index) in listHandle" :key="handle">
        <HandleCustom :data="handle" :index="index"  />
      </template>
    </div>
  </div>


</template>

<script>
import {defineComponent, defineProps, defineEmits, onMounted, ref} from "vue";
import { computed } from 'vue'
import { Position, VueFlow, useVueFlow,Handle  } from '@vue-flow/core'
import FacebookPostbackPreview from "@/components/Flow/FacebookPostbackPreview.vue";
import {useI18n} from "vue-i18n";
import HandleCustom from "@/views/Marketing/Automation/HandleCustom";

const props = defineProps({
  data: {
    type: Object,
    required: true,
  },
})


export default defineComponent({
  name: "TriggerComponent",
  components:{
    Handle,
    HandleCustom,
    FacebookPostbackPreview,
  },
  props: ['triggerElements', 'data'],
  setup(props, {emit}){

    const i18n = useI18n()


    const listHandle = ref([
      {
        id: props.data.id+'action_send_success_context',
        name: i18n.t('ACTION_SEND_SUCCESS')
      },
      {
        id: props.data.id+'action_send_failed_context',
        name: i18n.t('ACTION_SEND_FALSE')
      },
      {
        id: props.data.id+'action_default_context',
        name: i18n.t('ACTION_DEFAULT')
      }
    ])


    const sourceHandleStyleA = computed(() => ({  top: '10px',
      bottom: 'auto', }))

    const sourceHandleStyleC = computed(() => ({  top: 'auto',
      bottom: '10px', }))

    const addTrigger = ()=>{
      console.log('addTrigger')
      emit('addTrigger', '')
    }
    console.log('23213',  props.triggerElements)


    return{
      sourceHandleStyleA,
      sourceHandleStyleC,
      Position,
      addTrigger,
      listHandle,
    }
  }
})
</script>

<style scoped>
.container{
  padding: 16px 16px 16px 16px;
  font-size: 14px;
}

.add_trigger{
  border-width: 0.5px;
  width: 100%;
  border-color: #acacac80;
  border-style: dotted;
}
.next_step{
  position: absolute;
  bottom: 8px;
  right: 8px;
  text-align: right;
}
.header{
  display: flex;
  flex-flow: row;
  align-items: center;
  gap: 8px;
  margin-bottom: 10px;
}
.element{
  display: flex;
  align-items: center;
  justify-content: start;
  padding: 8px 0 8px 0;
  border-radius: 10px;
  margin-bottom: 12px;
  background-color: #e1faec;
}
.content{
  font-size: 16px;
  font-weight: normal;
}
.content_mess{
  margin: 4px 0 4px;
  padding: 8px;
  border-radius: 10px;
  background-color: #B1B1B77E;
  font-size: 14px;
  font-weight: normal;
}
.description{
  color: #758697;
  font-size: 10px;
}
.container_button{
  width: 100%;
}
.button{
  margin: 8px 0 8px
}
.add_trigger{
  height: 50px;
}
.icon{
}
/*.handle {*/
/*  border-width: 0;*/
/*  background-color: #8492a6;*/
/*  transition: transform .2s;*/
/*  width: 15px;*/
/*  height: 15px;*/
/*  margin: 0 auto;*/
/*}*/

/*.handle:hover {*/
/*  background-color: #0084ff;*/
/*}*/
</style>
