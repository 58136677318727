<template>
  <div>
    <div
      style="
        display: flex;
        flex-direction: row;
        padding: 16px;
        background-color: white;
      "
    >
      <a-breadcrumb separator="" style="flex: 1">
        <a-breadcrumb-item href="/customers" @click="goback"
          >Customer list</a-breadcrumb-item
        >
        <a-breadcrumb-separator />
        <a-breadcrumb-item>{{ customerForm.full_name }}</a-breadcrumb-item>
        <a-breadcrumb-separator>.</a-breadcrumb-separator>
        <a-breadcrumb-item>
          <a-button
            type="primary"
            @click="goback"
            size="small"
            class="button-goback"
          >
            <arrow-left-outlined />
            Go back to customer
          </a-button>
          <a-button @click="openChat" size="small">
            <message-outlined />
            {{ $t("CUSTOMER_CONVERSATION") }}
          </a-button>
          <a-button
            @click="openSchedule"
            size="small"
            class="schedule-employee"
          >
            <schedule-outlined />
            {{ $t("CREATE_SCHEDULE") }}
          </a-button>
          <a-button
            @click="quickMessage"
            size="small"
            class="schedule-employee"
          >
            <message-outlined />
            {{ $t("quick_message") }}
          </a-button>
        </a-breadcrumb-item>
      </a-breadcrumb>

      <a-button type="primary" @click="onSubmit" :loading="submitLoading">
        <save-outlined />
        {{ $t("SAVE") }}
      </a-button>
    </div>
    <div class="container">
      <a-card class="card-customer" :title="$t('CUSTOMER_INFO')">
        <!--              :title="$route.params.id&&$route.params.id!==':id'?$t('UPDATE_CUSTOMER'):$t('CREATE_CUSTOMER')">-->

        <a-form
          :model="customerForm"
          ref="formRef"
          :rules="rules"
          layout="vertical"
        >
          <div class="inline-form-customer">
            <a-form-item :label="$t('CUSTOMER_NAME')" name="full_name">
              <a-input
                v-model:value="customerForm.full_name"
                :placeholder="$t('CUSTOMER_NAME')"
              />
            </a-form-item>

            <a-form-item :label="$t('CUSTOMER_PHONE')" name="phone">
              <a-input
                v-model:value="customerForm.phone"
                :placeholder="$t('CUSTOMER_PHONE')"
              />
            </a-form-item>
          </div>
          <div class="inline-form-customer">
            <a-form-item :label="$t('ALL_PHONE _NUMBERS')" name="all_phones">
              <a-select
                style="width: 100%"
                :placeholder="$t('ALL_PHONE _NUMBERS')"
                mode="tags"
                v-model:value="allPhones"
              ></a-select>
            </a-form-item>

            <div class="inline-form-customer">
              <a-form-item label="Email" name="email">
                <a-input
                  v-model:value="customerForm.email"
                  placeholder="Email"
                />
              </a-form-item>
            </div>

            <a-form-item :label="$t('CUSTOMER_BIRTHDAY')" name="birth">
              <a-date-picker
                v-model:value="customerForm.birth"
                :placeholder="$t('CUSTOMER_BIRTHDAY')"
                format="DD-MM-YYYY"
                style="width: 100%"
              />
            </a-form-item>

            <a-form-item :label="$t('SEX')" name="sex">
              <a-radio-group name="radioGroup" v-model:value="customerForm.sex">
                <a-radio value="male">{{ $t("MALE") }}</a-radio>
                <a-radio value="female">{{ $t("FEMALE") }}</a-radio>
                <a-radio value="other">{{ $t("OTHER") }}</a-radio>
              </a-radio-group>
            </a-form-item>
            <a-form-item label="Email" name="email">
              <a-input v-model:value="customerForm.email" placeholder="Email" />
            </a-form-item>
            <a-form-item :label="$t('CITY')" name="province_id">
              <a-select
                style="width: 100%"
                placeholder="Please select"
                show-search
                v-model:value="customerForm.province_id"
                :loading="!provinceLoaded"
                :options="provinces"
                :filter-option="false"
                :not-found-content="null"
                @search="provinceSearch"
                @change="provinceChange(customerForm.province_id)"
              >
              </a-select>
            </a-form-item>

            <a-form-item :label="$t('DISTRICT')" name="district_id">
              <a-select
                style="width: 100%"
                placeholder="Please select"
                v-model:value="customerForm.district_id"
                show-search
                :loading="!districtLoaded"
                :options="districts"
                :filter-option="false"
                :not-found-content="null"
                @search="districtSearch"
                @change="districtChange()"
              >
              </a-select>
            </a-form-item>

            <a-form-item :label="$t('WARD')" name="ward_id">
              <a-select
                style="width: 100%"
                placeholder="Please select"
                show-search
                :options="wards"
                :loading="!wardLoaded"
                :filter-option="false"
                :not-found-content="null"
                @search="wardSearch"
                v-model:value="customerForm.ward_id"
              >
              </a-select>
            </a-form-item>
            <a-form-item :label="$t('CUSTOMER_ADDRESS')" name="address">
              <a-textarea
                v-model:value="customerForm.address"
                :placeholder="$t('CUSTOMER_ADDRESS')"
                :rows="3"
              />
            </a-form-item>
            <a-form-item :label="$t('NOTE')" name="note">
              <a-textarea
                v-model:value="customerForm.note"
                :placeholder="$t('NOTE')"
                :rows="3"
              />
            </a-form-item>

            <a-form-item :label="$t('DATE_CREATED')">
              <a-date-picker
                v-model:value="customerForm.created_at"
                fomat="DD-MM-YYYY"
                disabled="true"
              />
            </a-form-item>
          </div>
        </a-form>
      </a-card>
      <a-card class="card-customer" :title="$t('CUSTOMER_STATUS_CARE')">
        <a-form class="inline-form-customer" layout="vertical">
          <a-form-item :label="$t('FROM')">
            <a-input
              :placeholder="$t('FROM')"
              :value="customerForm.social_type"
              disabled
            />
          </a-form-item>

          <a-form-item :label="$t('PAGE_ID')" name="page_id">
            <a-select
              style="width: 100%"
              disabled
              placeholder="Please select"
              v-model:value="customerForm.page_id"
            >
              <a-select-option
                v-for="item in pages"
                :key="item.id"
                v-bind:value="item.page_id"
                >{{ item.page_name }}
              </a-select-option>
            </a-select>
          </a-form-item>

          <a-form-item :label="$t('CARE_STATUS')" name="last_care">
            <a-select
              style="width: 100%"
              allowClear
              placeholder="Please select"
              v-model:value="customerForm.last_care"
            >
              <a-select-option
                v-for="item in customerCares"
                :key="item.id"
                v-bind:value="item.id"
              >
                <a-tag :color="item.color">
                  {{ item.name }}
                </a-tag>
              </a-select-option>
            </a-select>
          </a-form-item>

          <a-form-item label="Conversation tag" name="conversation_tags">
            <template
              v-for="tag in splitString(customerForm.conversation_tags)"
              v-bind:key="tag"
            >
              <a-tag :color="getConversationTagById(tag).tag_color">
                {{ getConversationTagById(tag).tag_name }}
              </a-tag>
            </template>
          </a-form-item>

          <a-config-provider :renderEmpty="null">
            <a-form-item :label="$t('TAG')" name="tag">
              <a-tree-select
                @change="handleChangeTag"
                show-search
                style="width: 100%"
                @search="searchTag"
                v-model:value="tagIds"
                :filterTreeNode="false"
                v-model:searchValue="searchTagValue"
                :notFoundContent="null"
                :dropdown-style="{ maxHeight: '300', overflow: 'auto' }"
                allow-clear
                multiple
                :placeholder="$t('TAG_SELECT')"
              >
                <a-tree-select-node
                  v-for="item in treeTags"
                  :key="item.id"
                  v-bind:value="item.id"
                >
                  <template #title>
                    <a-tag :color="item.color">
                      {{ item.name }}
                    </a-tag>
                  </template>
                  <a-tree-select-node
                    v-for="child in item.children"
                    :key="child.id"
                    v-bind:value="child.id"
                  >
                    <template #title>
                      <a-tag :color="child.color">
                        {{ child.name }}
                      </a-tag>
                    </template>
                  </a-tree-select-node>
                </a-tree-select-node>
              </a-tree-select>
            </a-form-item>
          </a-config-provider>

          <a-form-item
            :label="$t('CUSTOMER_CARE_STAFF')"
            name="conversation_staff"
          >
            <a-select
              style="width: 100%"
              placeholder="Please select"
              allowClear
              v-model:value="customerForm.selectedConversationStaff"
            >
              <a-select-option
                v-for="item in employees"
                :key="item.id"
                v-bind:value="item.id + ''"
                >{{ item.name }}
              </a-select-option>
            </a-select>
          </a-form-item>

          <a-form-item :label="$t('SALE_STAFF')" name="employees">
            <a-select
              style="width: 100%"
              placeholder="Please select"
              allowClear
              v-model:value="customerForm.selectedEmployee"
            >
              <a-select-option
                v-for="item in employees.data"
                :key="item.id"
                v-bind:value="item.id"
                >{{ item.name }}
              </a-select-option>
            </a-select>
          </a-form-item>
          <a-form-item :label="$t('CAREER')" name="career">
            <a-select
              style="width: 100%"
              placeholder="Please select"
              allowClear
              v-model:value="customerForm.career"
            >
              <a-select-option
                v-for="item in careers"
                :key="item.id"
                v-bind:value="item.id"
                >{{ item.name }}
              </a-select-option>
            </a-select>
          </a-form-item>
        </a-form>
      </a-card>
      <a-card class="card-customer" :title="$t('CONTACT_INFO')">
        <template #extra>
          <delete-outlined @click="onDeleteContact" />
        </template>
        <a-form
          :model="contactForm"
          ref="contactFormRef"
          class="inline-form-customer"
          layout="vertical"
        >
          <a-form-item :label="$t('PREFIX')" name="prefix">
            <a-input
              :placeholder="$t('PREFIX')"
              v-model:value="contactForm.prefix"
            />
          </a-form-item>
          <a-form-item :label="$t('FULL_NAME')" name="full_name">
            <a-input
              :placeholder="$t('FULL_NAME')"
              v-model:value="contactForm.full_name"
            />
          </a-form-item>
          <a-form-item :label="$t('POSITION')" name="position">
            <a-input
              :placeholder="$t('POSITION')"
              v-model:value="contactForm.position"
            />
          </a-form-item>
          <a-form-item :label="$t('PHONE')" name="phone">
            <a-input
              :placeholder="$t('PHONE')"
              v-model:value="contactForm.phone"
            />
          </a-form-item>
          <a-form-item :label="$t('BIRTHDAY')" name="birthday">
            <a-date-picker
              style="width: 300px"
              v-model:value="contactForm.birthday"
              :placeholder="$t('CUSTOMER_BIRTHDAY')"
              format="DD-MM-YYYY"
            />
          </a-form-item>
          <a-form-item :label="$t('GENDER')" name="gender">
            <a-radio-group name="radioGroup" v-model:value="contactForm.gender">
              <a-radio :value="1">{{ $t("MALE") }}</a-radio>
              <a-radio :value="2">{{ $t("FEMALE") }}</a-radio>
            </a-radio-group>
          </a-form-item>
          <a-form-item :label="$t('NOTE')" name="note">
            <a-textarea
              v-model:value="contactForm.note"
              :placeholder="$t('NOTE')"
              :rows="3"
            />
          </a-form-item>
        </a-form>
      </a-card>

      <div class="history-comment">
        <div class="header-comment"><h3>Customer activity</h3></div>
        <a-card class="card-history-comment" size="small">
          <template #title>
            <a-tag color="#87d068">{{ commentHistory.length }}</a-tag>
            comments
          </template>
          <template #extra>
            <a-popover
              title="Add your comment"
              trigger="click"
              v-model:visible="addCommentVisible"
              v-if="$route.params.id && $route.params.id !== ':id'"
            >
              <template #content>
                <a-form
                  :rules="rulesFormNote"
                  :model="commentForm"
                  :label-col="{ span: 8 }"
                  :wrapper-col="{ span: 16 }"
                >
                  <a-form-item :label="$t('TAG')" name="tagIds">
                    <a-tree-select
                      show-search
                      style="width: 100%"
                      v-model:value="commentForm.tagId"
                      :filterTreeNode="false"
                      v-model:searchValue="searchTagValue"
                      :notFoundContent="null"
                      :dropdown-style="{ maxHeight: '300', overflow: 'auto' }"
                      allow-clear
                      :placeholder="$t('TAG_SELECT')"
                    >
                      <a-tree-select-node
                        v-for="item in treeTags"
                        :key="item.id"
                        v-bind:value="item.id"
                      >
                        <template #title>
                          <a-tag :color="item.color">
                            {{ item.name }}
                          </a-tag>
                        </template>
                        <a-tree-select-node
                          v-for="child in item.children"
                          :key="child.id"
                          v-bind:value="child.id"
                        >
                          <template #title>
                            <a-tag :color="child.color">
                              {{ child.name }}
                            </a-tag>
                          </template>
                        </a-tree-select-node>
                      </a-tree-select-node>
                    </a-tree-select>
                  </a-form-item>
                  <a-form-item :label="$t('comment')" name="comment">
                    <a-textarea
                      v-model:value="commentForm.comment"
                      :placeholder="$t('INPUT_YOUR_COMMENT')"
                      :auto-size="{ minRows: 2, maxRows: 5 }"
                    />
                  </a-form-item>
                  <a-alert
                    v-if="showWarningAlertAddComment"
                    message="Fill at least one field"
                    type="warning"
                    closable
                    @close="closeWarningAlertAddComment"
                  />
                  <a-form-item :wrapper-col="{ span: 16, offset: 8 }">
                    <a-button
                      type="primary"
                      @click="addComment"
                      :loading="buttonSubmitCommentLoading"
                      >Submit</a-button
                    >
                  </a-form-item>
                </a-form>
              </template>
              <a-button type="dashed">
                <plus-outlined />
                Add comment
              </a-button>
            </a-popover>
          </template>
          <a-timeline
            mode="left"
            class="timeline-history-comment"
            reverse
            v-if="commentHistory && commentHistory.length"
          >
            <a-timeline-item
              v-for="comment in commentHistory"
              :key="comment.id"
            >
              <div>
                <span
                  ><user-outlined />
                  <span class="user-comment">{{
                    getOneEmployeeById(comment.creator).name
                  }}</span>
                  <span class="time-comment">{{
                    dateTimeDisplayFormatFromString(comment.created_at)
                  }}</span></span
                >
              </div>
              <div v-if="comment.tag_ids">
                <span v-for="tagId in comment.tag_ids" :key="tagId">
                  <tag-outlined />
                  <span class="type-tag">
                    {{ $t(comment.type) }} </span
                  ><a-tag :color="getOneByCustomerTagId(tagId).color">{{
                    getOneByCustomerTagId(tagId).name
                  }}</a-tag>
                </span>
              </div>
              <div v-if="comment.type === 'schedule'">
                <span>
                  <schedule-outlined />
                  <span class="comment-reminder">{{
                    $t("CUSTOMER_SCHEDULE_AT")
                  }}</span>
                  <a-tag color="cyan">{{
                    dateTimeDisplayFormatFromString(comment.schedule_time)
                  }}</a-tag>
                </span>
              </div>
              <div v-if="comment.content">
                <span> <message-outlined /> {{ comment.content }} </span>
              </div>
            </a-timeline-item>
          </a-timeline>
          <a-empty v-else />
        </a-card>

        <div class="header-order"><h3>Orders</h3></div>
        <a-card class="card-history-comment" size="small">
          <template #title>
            <a-tag color="#87d068">{{ orders.length }}</a-tag>
            {{ $t("ORDERS") }}
          </template>
          <a-timeline
            mode="left"
            class="timeline-history-comment"
            reverse
            v-if="orders && orders.length"
          >
            <a-timeline-item v-for="order in orders" :key="order.id">
              <div>
                <router-link :to="'/order/' + order.order_number"
                  ><a class="user-comment">
                    {{ order.order_number }}</a
                  ></router-link
                >
                <span class="time-comment">{{
                  dateTimeDisplayFormatFromString(order.created_at)
                }}</span>
              </div>
              <!--              <div v-if="comment.tag_ids">-->
              <!--                    <span v-for="tagId in comment.tag_ids" :key="tagId">-->
              <!--                      <tag-outlined/> <span class="type-tag">-->
              <!--                        {{ $t(comment.type) }}-->
              <!--                      </span><a-tag-->
              <!--                        :color="getOneByCustomerTagId(tagId).color">{{-->
              <!--                        getOneByCustomerTagId(tagId).name-->
              <!--                      }}</a-tag>-->
              <!--                    </span>-->
              <!--              </div>-->
              <div v-if="order.detail">
                <span> <ShoppingCartOutlined /> {{ order.detail }} </span>
              </div>
              <div v-if="order.note_bill">
                <span> <SnippetsOutlined /> {{ order.note_bill }} </span>
              </div>
            </a-timeline-item>
          </a-timeline>
          <a-empty v-else />
        </a-card>
      </div>
    </div>
    <create-schedule-modal ref="createScheduleRef" />
    <trigger-quick-message-modal ref="triggerCustomerRef" />
  </div>
</template>

<script lang="ts">
import {
  computed,
  defineComponent,
  onMounted,
  onUpdated,
  reactive,
  ref,
} from "vue";
import {
  ContactForm,
  Customer,
  CustomerActivity,
  CustomerActivityType,
  CustomerForm,
} from "@/models/Customers/Customers";
import {
  MessageOutlined,
  SaveOutlined,
  TagOutlined,
  UserOutlined,
  PlusOutlined,
  ArrowLeftOutlined,
  ShoppingCartOutlined,
  SnippetsOutlined,
  DeleteOutlined,
  ScheduleOutlined,
} from "@ant-design/icons-vue";
import { useRoute } from "vue-router";
import { useForm } from "ant-design-vue/es/form";
import { ValidateErrorEntity } from "ant-design-vue/es/form/interface";
import { DictionaryActionTypes } from "@/store/modules/dictionary/action-types";
import { DictionaryMutationTypes } from "@/store/modules/dictionary/mutation-types";
import APIs from "@/APIs/APIs";
import { message } from "ant-design-vue";
import { CustomersMutationTypes } from "@/store/modules/customer/mutation-types";
import {
  CustomerResponse,
  ProvinceResponse,
  TreeTagsResponse,
} from "@/APIs/response/S_HttpResponse";
import { SelectionAnt } from "@/models/ShopPages/ConfigData";
import { MixUtils } from "@/utils/Utils";
import router from "@/router";
import RouterEndpoint from "@/router/RouterEndpoint";
import { Constant } from "@/utils/Constants";
import CreateScheduleModal from "@/components/customer/CreateScheduleModal.vue";
import { CustomersActionTypes } from "@/store/modules/customer/action-types";
import { useStore } from "vuex";
import { useI18n } from "vue-i18n";
import { TriggerMutationTypes } from "@/store/modules/trigger/mutation-types";
import TriggerQuickMessageModal from "@/components/customer/TriggerQuickMessageModal.vue";
import { TriggerActionTypes } from "@/store/modules/trigger/action-types";
import { TagsActionTypes } from "@/store/modules/tag/action-types";
import { Tag } from "@/models/Tags/Tags";
import { Orders } from "@/models/Orders/Orders";
import dayjs from "dayjs";
import { EmployeeActionTypes } from "@/store/modules/employee/action-types";

const rules = {
  full_name: [
    { required: true, message: "Please input full name", trigger: "blur" },
  ],
};
let searchTreeTimeout = 0;
interface AddCommentModel {
  tagId?: number;
  comment?: string;
}

export default defineComponent({
  name: "Customer",
  components: {
    TriggerQuickMessageModal,
    // SaveOutlined,
    MessageOutlined,
    UserOutlined,
    TagOutlined,
    PlusOutlined,
    ArrowLeftOutlined,
    ScheduleOutlined,
    ShoppingCartOutlined,
    SnippetsOutlined,
    DeleteOutlined,
    CreateScheduleModal,
  },
  setup: function () {
    const route = useRoute();
    const store = useStore();
    const i18n = useI18n();
    const formRef = ref();
    const contactFormRef = ref();
    const createScheduleRef = ref();
    const triggerCustomerRef = ref();
    const allPhones = ref<string[]>([]);
    const currentCustomer = computed(
      (): Customer | undefined => store.getters.getCurrentCustomer
    );
    const pages = computed(() => store.getters.getPages);
    const customerCares = computed(() => store.getters.getCustomerCares);
    const employees = computed(() => store.getters.getEmployees);
    const careers = computed(() => store.getters.getCareers);
    const provinces = ref<SelectionAnt[]>([]);
    let originalProvinces: SelectionAnt[] = [];
    const districts = ref<SelectionAnt[]>([]);
    let originalDistricts: SelectionAnt[] = [];
    const wards = ref<SelectionAnt[]>([]);
    let originalWars: SelectionAnt[] = [];
    const commentForm = reactive<AddCommentModel>({ comment: "" });
    const getOneEmployeeById = (id: number) => {
      const emp = store.getters.getOneEmployeeById(id);
      return emp;
    };
    const getOneByCustomerTagId = (id: number) => {
      return store.getters.getOneByCustomerTagId(id);
    };
    const tagIds = ref<number[]>([]);
    const addCommentVisible = ref(false);
    const buttonSubmitCommentLoading = ref(false);
    const showWarningAlertAddComment = ref(false);
    const provinceLoaded = ref(true);
    const districtLoaded = ref(true);
    const wardLoaded = ref(true);
    const isFetching = ref<boolean>(false);
    const submitLoading = ref(false);
    const searchTagValue = ref("");
    const commentHistory = ref<CustomerActivity[]>([]);
    const orders = ref<Orders[]>([]);

    const getTags = () => {
      store.dispatch(TagsActionTypes.FETCH_TREE_TAGS, { type: "customer" });
    };

    const getCareers = () => {
      store.dispatch(CustomersActionTypes.FETCH_CAREER, {});
    };

    const treeTags = computed(() => store.getters.getTreeTags);

    const rulesFormNote = {
      comment: [
        {
          required: true,
          message: i18n.t("INPUT_YOUR_COMMENT"),
          trigger: "blur",
        },
      ],
    };

    const getConversationTagById = (tagId: string) => {
      return store.getters.getConversationTagById(
        currentCustomer.value ? currentCustomer.value.page_id : "",
        tagId
      );
    };
    let form: CustomerForm = { selectedTags: [], conversationTags: [] };

    const splitString = (str: string): string[] => {
      if (str) {
        return str.split(",");
      }
      return [];
    };
    const init = (): boolean => {
      getContact();
      getTags();
      getCareers();
      if (route.params.id && route.params.id !== ":id") {
        if (isFetching.value) {
          return false;
        }
        isFetching.value = true;
        store.dispatch(CustomersActionTypes.FETCH_CUSTOMER, {
          payload: { id: route.params.id },
          callback: (res: CustomerResponse) => {
            isFetching.value = false;
            if (currentCustomer.value?.tags) {
              tagIds.value = currentCustomer.value?.tags.map((tag): number => {
                return tag.id ? tag.id : 0;
              });
            }
            if (currentCustomer.value?.all_phones) {
              allPhones.value = currentCustomer.value?.all_phones.split(",");
            }

            if (
              route.params.id &&
              route.params.id !== ":id" &&
              currentCustomer
            ) {
              form.selectedEmployee = 0;
              if (currentCustomer.value?.employees) {
                const tmpEmpl = currentCustomer.value?.employees.split(",");
                if (tmpEmpl && tmpEmpl.length > 0) {
                  form.selectedEmployee = parseInt(tmpEmpl[0]);
                }
              }
              form.selectedTags = [];
              if (currentCustomer.value?.tags) {
                form.selectedTags = currentCustomer.value?.tags.map((tag) =>
                  tag && tag.id ? tag.id : 0
                );
              }
              form = { ...form, ...currentCustomer.value };
              form.province_id = form.province_id + "";
              form.district_id = form.district_id + "";
              if (typeof form.birth === "string") {
                form.birth = dayjs(form.birth);
              }
              if (typeof form.created_at === "string") {
                form.created_at = dayjs(form.created_at);
              }
              customerForm.value = form;
            }

            if (currentCustomer.value?.province_id) {
              fetchDistricts(currentCustomer.value?.province_id);

              if (currentCustomer.value?.district_id) {
                fetchWards(currentCustomer.value?.district_id);
              }
            }
          },
        });
      }
      fetchProvinces();
      fetchHistoryComment();
      fetchOrders();
      fetchEmployee();
      return true;
    };
    const customerForm = ref<CustomerForm>({ ...form });
    const contactForm = ref<ContactForm>({});
    onMounted(() => {
      init();
    });
    onUpdated(() => {
      init();
    });

    const fetchOrders = () => {
      if (route.params.id && route.params.id !== ":id") {
        APIs.init()
          .getOrders({
            customer_id: route.params.id + "",
            page_size: 9999,
          })
          .then((response) => {
            if (
              response.status === 200 &&
              response.data?.data &&
              response.data.data
            ) {
              orders.value = response.data.data;
            }
          })
          .catch((e) => {
            console.error("e", e);
          });
      }
    };

    const fetchEmployee = () => {
      store.dispatch(EmployeeActionTypes.FETCH_EMPLOYEE, {
        page: 1,
        page_size: 999,
      });
    };

    const getContact = () => {
      if (route.params.id && route.params.id !== ":id") {
        APIs.init()
          .getContact({
            id: route.params.id,
          })
          .then((response) => {
            // if (response.status === 200 && response.data?.data && response.data.data) {
            contactForm.value = response.data?.data ? response.data?.data : {};
            if (typeof contactForm.value.birthday === "string") {
              contactForm.value.birthday = dayjs(contactForm.value.birthday);
            }
            // }
          })
          .catch((e) => {
            console.error("e", e);
          });
      }
    };
    const onDeleteContact = () => {
      if (route.params.id && route.params.id !== ":id") {
        APIs.init()
          .deleteContact({
            id: contactForm.value.id,
          })
          .then((response) => {
            if (response.status === 200) {
              contactForm.value = {};
            }
          })
          .catch((e) => {
            console.error("e", e);
          });
      }
    };
    const updateContact = () => {
      if (route.params.id && route.params.id !== ":id") {
        if (contactForm.value.birthday === "0001-01-01T00:00:00.000Z") {
          contactForm.value.birthday = undefined;
        }
        contactForm.value.created_at = undefined;
        contactForm.value.updated_at = undefined;
        contactForm.value.shop_customer_id = Number(route.params.id);
        APIs.init()
          .updateContact(contactForm.value)
          .then((response) => {
            if (response.status === 200) {
              contactForm.value = {};
            }
          })
          .catch((e) => {
            console.error("e", e);
          });
      }
    };

    const createContact = () => {
      if (route.params.id && route.params.id !== ":id") {
        if (contactForm.value.birthday === "0001-01-01T00:00:00.000Z") {
          contactForm.value.birthday = undefined;
        }
        contactForm.value.created_at = undefined;
        contactForm.value.updated_at = undefined;
        contactForm.value.shop_customer_id = Number(route.params.id);
        APIs.init()
          .createContact(contactForm.value)
          .then((response) => {
            if (response.status === 200) {
              contactForm.value = {};
            }
          })
          .catch((e) => {
            console.error("e", e);
          });
      }
    };

    const fetchProvinces = () => {
      provinceLoaded.value = false;
      store.dispatch(DictionaryActionTypes.FETCH_PROVINCES, {
        callback: (result?: ProvinceResponse) => {
          if (result && result.data) {
            originalProvinces = result.data.map((item): SelectionAnt => {
              return {
                value: item.id + "",
                label: item.name,
              };
            });
            provinceSearch("");
          }
          provinceLoaded.value = true;
        },
      });
    };
    const handleChangeTag = () => {
      if (tagIds.value) {
        customerForm.value.tags = tagIds.value.map((tagId): Tag => {
          return {
            id: tagId,
            name: "",
          };
        });
      }
    };
    const searchTag = (val: string) => {
      if (searchTreeTimeout) {
        clearTimeout(searchTreeTimeout);
      }
      searchTreeTimeout = window.setTimeout(() => {
        store.dispatch(TagsActionTypes.FETCH_TREE_TAGS, {
          payload: { type: "customer", search: val },
        });
      }, 1100);
    };
    const fetchDistricts = (provinceId: string) => {
      districtLoaded.value = false;
      store.dispatch(DictionaryActionTypes.FETCH_DISTRICTS, {
        payload: { province_id: provinceId },
        callback: (result?: ProvinceResponse) => {
          if (result && result.data) {
            originalDistricts = result.data.map((item): SelectionAnt => {
              return {
                value: item.id + "",
                label: item.name,
              };
            });
            districtSearch("");
          }
          districtLoaded.value = true;
        },
      });
    };
    const fetchWards = (districtId: string) => {
      wardLoaded.value = false;
      store.dispatch(DictionaryActionTypes.FETCH_WARDS, {
        payload: { district_id: districtId },
        callback: (result?: ProvinceResponse) => {
          if (result && result.data) {
            originalWars = result.data.map((item): SelectionAnt => {
              return {
                value: item.id,
                label: item.name,
              };
            });
            wardSearch("");
          }
          wardLoaded.value = true;
        },
      });
    };
    const provinceSearch = (search: string) => {
      if (search) {
        provinces.value = originalProvinces.filter((x) =>
          MixUtils.methods
            .removeVietnamese2(x.label)
            .includes(MixUtils.methods.removeVietnamese2(search))
        );
      } else {
        provinces.value = originalProvinces;
      }
    };
    const districtSearch = (search: string) => {
      if (search) {
        districts.value = originalDistricts.filter((x) =>
          MixUtils.methods
            .removeVietnamese2(x.label)
            .includes(MixUtils.methods.removeVietnamese2(search))
        );
      } else {
        districts.value = originalDistricts;
      }
    };
    const wardSearch = (search: string) => {
      if (search) {
        wards.value = originalWars.filter((x) =>
          MixUtils.methods
            .removeVietnamese2(x.label)
            .includes(MixUtils.methods.removeVietnamese2(search))
        );
      } else {
        wards.value = originalWars;
      }
    };
    const fetchHistoryComment = () => {
      if (route.params.id && route.params.id !== ":id") {
        APIs.init()
          .getShopCustomerNote({ shop_customer_id: route.params.id })
          .then((response) => {
            if (
              response.status === 200 &&
              response.data?.data &&
              response.data.data
            ) {
              commentHistory.value = response.data.data;
            }
          })
          .catch((e) => {
            console.error("e", e);
          });
      }
    };
    const provinceChange = () => {
      fetchDistricts(
        customerForm.value.province_id ? customerForm.value.province_id : ""
      );
    };
    const districtChange = () => {
      fetchWards(
        customerForm.value.district_id ? customerForm.value.district_id : ""
      );
    };
    const addComment = async () => {
      if (commentForm.comment || commentForm.tagId) {
        showWarningAlertAddComment.value = false;
        buttonSubmitCommentLoading.value = true;
        if (commentForm.tagId && currentCustomer.value?.id) {
          await APIs.init().addCustomerTags({
            tags: [commentForm.tagId],
            customers: [currentCustomer.value?.id],
          });
          if (!tagIds.value.includes(commentForm.tagId)) {
            tagIds.value.push(commentForm.tagId);
          }
        }
        APIs.init()
          .addShopCustomerNote({
            content: commentForm.comment,
            type: CustomerActivityType.NOTE,
            customer_id: currentCustomer.value?.id,
          })
          .then((res) => {
            if (res.status === 200 && res.data?.status === 200) {
              fetchHistoryComment();
            }
            delete commentForm.comment;
            delete commentForm.tagId;
            buttonSubmitCommentLoading.value = false;

            addCommentVisible.value = false;
          })
          .catch((e) => {
            console.error(e);
            buttonSubmitCommentLoading.value = false;
            addCommentVisible.value = false;
          });
      } else {
        showWarningAlertAddComment.value = true;
      }
    };
    const goback = () => {
      router.push({ name: RouterEndpoint.CUSTOMER_MANAGEMENT.name });
    };
    const openChat = () => {
      const url =
        Constant.LINK_CHAT +
        customerForm.value.page_id +
        "&conversation_id=" +
        customerForm.value.page_id +
        "_" +
        customerForm.value.social_id;
      window.open(url, "_blank");
    };
    const openSchedule = () => {
      createScheduleRef.value.openModal();
    };
    const quickMessage = () => {
      triggerCustomerRef.value.openModal();
    };
    const closeWarningAlertAddComment = () => {
      showWarningAlertAddComment.value = false;
    };
    const onSubmit = () => {
      formRef.value
        .validate()
        .then(() => {
          if (allPhones.value) {
            const phones = allPhones.value.join(",");
            if (phones) {
              customerForm.value.all_phones = phones;
            }
          }
          if (customerForm.value.selectedConversationStaff) {
            customerForm.value.conversation_staff =
              customerForm.value.selectedConversationStaff + "";
          }
          if (customerForm.value.selectedEmployee) {
            customerForm.value.employees =
              customerForm.value.selectedEmployee + "";
          }
          submitLoading.value = true;
          if (route.params.id && route.params.id !== ":id") {
            APIs.init()
              .updateCustomer(customerForm.value)
              .then((res) => {
                submitLoading.value = false;
                if (res.status === 200 && res.data?.status === 200) {
                  message.success({ content: "Edit customer success" });
                  store.commit(
                    CustomersMutationTypes.SET_CUSTOMER,
                    customerForm
                  );
                } else {
                  message.error({ content: res.data?.message });
                }
              })
              .catch((e) => {
                submitLoading.value = false;
                // message.error();
                console.error("error", e);
              });
          } else {
            APIs.init()
              .createCustomer(customerForm.value)
              .then((res) => {
                submitLoading.value = false;
                if (res.status === 200 && res.data?.status === 200) {
                  message.success({ content: "Create customer success" });
                  store.commit(
                    CustomersMutationTypes.SET_CUSTOMER,
                    res.data.data
                  );
                  router.replace({
                    name: RouterEndpoint.CUSTOMER_DETAIL_EDIT.name,
                    params: { id: res.data.data.id },
                  });
                } else {
                  message.error({ content: res.data?.message });
                  if (res.data && res.data.data) {
                    router.push({
                      name: RouterEndpoint.CUSTOMER_DETAIL_EDIT.name,
                      params: { id: res.data.data + "" },
                    });
                  }
                }
              })
              .catch((e) => {
                submitLoading.value = false;
                message.destroy();
                console.error("error", e);
              });
          }
        })
        .catch((error: ValidateErrorEntity<CustomerForm>) => {
          console.error("error", error);
        });
      contactFormRef.value.validate().then(() => {
        if (contactForm.value.id !== 0 && contactForm.value.id !== undefined) {
          updateContact();
        } else {
          createContact();
        }
      });
    };
    return {
      customerForm,
      pages,
      rules,
      formRef,
      onSubmit,
      getConversationTagById,
      splitString,
      employees,
      careers,
      customerCares,
      allPhones,
      provinces,
      districts,
      wards,
      provinceChange,
      districtChange,
      provinceSearch,
      districtSearch,
      wardSearch,
      tagIds,
      treeTags,
      commentHistory,
      getOneEmployeeById,
      getOneByCustomerTagId,
      commentForm,
      addComment,
      showWarningAlertAddComment,
      closeWarningAlertAddComment,
      addCommentVisible,
      buttonSubmitCommentLoading,
      submitLoading,
      districtLoaded,
      wardLoaded,
      provinceLoaded,
      goback,
      openChat,
      openSchedule,
      createScheduleRef,
      rulesFormNote,
      triggerCustomerRef,
      quickMessage,
      handleChangeTag,
      searchTag,
      orders,
      searchTagValue,
      contactForm,
      contactFormRef,
      onDeleteContact,
    };
  },
});
</script>

<style scoped lang="scss">
.button-goback {
  background-color: #87d068;
  border-color: #87d068;
  margin-right: 16px;
}

.schedule-employee {
  margin-left: 12px;
}

.container {
  padding-top: 36px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  flex-wrap: wrap;

  .card-customer {
    //flex: 1;
    border-radius: 10px;
    margin-right: 16px;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;

    .inline-form-customer {
      width: 300px;
    }
  }

  .history-comment {
    flex: 1;
    padding-right: 15px;
    padding-left: 15px;

    .header-comment {
      text-align: center;
      margin-bottom: 40px;
    }
    .header-order {
      text-align: center;
      margin-top: 40px;
      margin-bottom: 40px;
    }

    .card-history-comment {
      border-radius: 10px;
      box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
      margin-left: 10px;

      .timeline-history-comment {
        margin-left: 10px;
      }
    }

    .user-comment {
      font-weight: bold;
      font-size: 16px;
    }

    .time-comment {
      font-style: italic;
      margin-left: 10px;
      font-size: 12px;
    }

    .comment-reminder {
      margin-right: 12px;
      margin-left: 12px;
    }
  }
}

.type-tag {
  margin-left: 3px;
  margin-right: 7px;
}
.container {
  padding: 8px;
}
</style>
