import {State} from "./states";
import {CustomerCare} from "@/models/CustomerCares/CustomerCares";

export type Getters = {
    getCustomerCares(state: State): CustomerCare[] | undefined
    getCustomerDetailCares(state: State): CustomerCare[] | undefined
    getCustomerCareNameById(state: State): (id: number) => string
    getCustomerCareById(state: State): (id: number) => CustomerCare | undefined
}
export const getters: Getters = {
    getCustomerCares(state: State): CustomerCare[] | undefined {
        return state.customerCares
    },
    getCustomerDetailCares(state: State): CustomerCare[] | undefined {
        return (state.customerDetailCares)?.sort((a:any,b:any)=>a.sort-b.sort);
    },
    getCustomerCareNameById: (state: State) => (id: number): string => {
        if (state.customerCares) {
            const care = state.customerCares.find(x => x.id === id)
            if (care) {
                return care.name
            }
        }
        return ''
    },
    getCustomerCareById: (state: State) => (id: number): CustomerCare | undefined => {
        let care = undefined
        if (state.customerCares) {
            care = state.customerCares.find(x => x.id === id)
        }
        if (!care && state.customerDetailCares) {
            care = state.customerDetailCares.find(x => x.id === id)
        }
        return care
    }
}
