<template>
  <a-modal
    v-model:visible="visible"
    :footer="null"
    :zIndex="10"
    width="68%"
    :closable="true"
  >
    <slot name="header">
      <div>
        <span style="font-size: 15px; color: #516cad; margin-left: 20px">
          {{ $t("AI_identify") }}
        </span>
      </div>
    </slot>
    <slot name="closeIcon" @click="visible = false">
      <div>
        <a-icon type="close-circle" />
      </div>
    </slot>
    <a-card type="card" default-active-key="1">
      <a-space direction="vertical" style="width: 100%">
        <a-select
          ref="select"
          style="width: 100%"
          :placeholder="$t('cx_view_select_group')"
          v-model:value="groupCurrent"
          allowClear
          @change="handleChange"
        >
          <template v-for="item in getCXViewGroup" :key="item.id">
            <a-select-option :value="item.id">{{ item.name }}</a-select-option>
          </template>
        </a-select>
      </a-space>

      <a-tabs v-model:activeKey="currentTab" @change="changeTab">
        <a-tab-pane :key="0" tab="Lịch sử phiên">
          <a-list item-layout="horizontal" :data-source="customers">
            <template #renderItem="{ item }">
              <a-list-item>
                <div style="float: left">
                  <img :src="item.image" width="150" height="150" />
                </div>
                <div
                  v-if="typeof item.id == 'number'"
                  style="float: left; margin-left: 10px"
                >
                  <p>{{ $t("camera_name") }} : {{ item.full_name }}</p>
                  <p>{{ $t("CUSTOMER_PHONE") }} : {{ item.phone }}</p>
                  <p>{{ $t("camera_sex") }}: {{ item.sex }}</p>
                  <p>{{ $t("camera_age") }} : {{ item.age }}</p>
                  <p>
                    {{ $t("time_visit") }} :
                    {{ dateTimeDisplayFormatFromUnix(item.timestamp) }}
                  </p>
                  <p>{{ $t("total_visit") }} : {{ item.total_visit }}</p>
                </div>
                <div
                  v-if="typeof item.id != 'number'"
                  style="float: left; margin-left: 10px"
                >
                  <p>{{ $t("CUSTOMER_NEW") }}</p>
                  <p>
                    {{ $t("time_visit") }} :
                    {{ dateTimeDisplayFormatFromUnix(item.timestamp) }}
                  </p>
                </div>
                <div
                  v-if="typeof item.id == 'number'"
                  style="float: left; margin-left: 10px"
                >
                  <a-button @click="showCustomerDetail(item)" type="primary">
                    {{ $t("CUSTOMER_DETAIL") }}
                    <a-icon type="right" />
                  </a-button>
                </div>

                <div
                  v-if="typeof item.id != 'number'"
                  style="float: left; margin-left: 10px"
                >
                  <a-button @click="createCustomer(item)" type="primary">
                    {{ $t("CUSTOMER_CREATE") }}
                    <a-icon type="right" />
                  </a-button>
                </div>
              </a-list-item>
            </template>
          </a-list>
        </a-tab-pane>
        <a-tab-pane :key="1" tab="Tất cả">
          <a-table
            size="small"
            :columns="columns"
            :data-source="histories"
            :pagination="pagination"
            @change="handleTableChange"
            :locale="{
              emptyText: $t('no_data'),
            }"
          >
            <template #full_name="{ record }">
              <a-row>
                <a-col :span="6">
                  <a-avatar :size="64" :src="record.image"> </a-avatar>
                </a-col>
                <a-col :span="18">
                  <router-link
                    :to="'/customer-detail/' + record.shop_customer_id"
                  >
                    <a class="table-row-name" type="text">
                      <span>{{ record.full_name }}</span>
                    </a>
                  </router-link>
                  <div class="phone-number">{{ record.phone }}</div>
                </a-col>
              </a-row>
            </template>
            <template #GENDER="{ text }">
              <span>{{ $t(text) }}</span>
            </template>

            <template #time_visit="{ text }">
              <span>{{ dateTimeDisplayFormatFromShortString(text) }}</span>
            </template>
          </a-table>
          <!--            <a-pagination v-model:current="page" :total="50" show-less-items @change="handleTableChange" />-->
        </a-tab-pane>
      </a-tabs>
    </a-card>
  </a-modal>
</template>

<script>
import { mapGetters, mapMutations, mapState } from "vuex";
import { MixUtils } from "@/utils/Utils";
import { CustomersMutationTypes } from "@/store/modules/customer/mutation-types";
import APIs from "@/APIs/APIs";

export default {
  name: "AddFaceComponent",
  props: [
    "userInfo",
    "page_id",
    "social_id",
    "shop_id",
    "conversation",
    "customer_name",
    "customers",
    "showCustomerDetail",
    "createCustomer",
    "subscribeCXView",
  ],
  data() {
    return {
      visible: false,
      loading: false,
      key_item: -1,
      index: -1,
      data: {
        name: this.customer_name,
        age: 18,
        total_visit: 0,
        gender: "Nam",
        image: "",
      },
      histories: [],
      page: 1,
      currentTab: 0,
      total: 0,
      groupCurrent: null,
    };
  },
  computed: {
    ...mapGetters({
      getCXViewGroup: "getCXViewGroup",
      getCXViewGroupCurrent: "getCXViewGroupCurrent",
    }),
    ...mapState({
      // pages: state => state.select_page.pages,
      // shopActive: state => state.select_page.shopActive,
      // pageActive: state => state.select_page.pageActive,
      cxViewGroup: (state) => state.cxViewGroup,
      cxViewGroupCurrent: (state) => state.cxViewGroupCurrent,
    }),
    columns() {
      return [
        {
          title: this.$t("FULL_NAME"),
          dataIndex: "full_name",
          key: "full_name",
          slots: { customRender: "full_name" },
        },
        {
          title: this.$t("GENDER"),
          dataIndex: "sex",
          key: "sex",
          slots: { customRender: "GENDER" },
        },
        {
          title: this.$t("time_visit"),
          dataIndex: "time_visit",
          key: "time_visit",
          slots: { customRender: "time_visit" },
        },
      ];
    },
    pagination() {
      return {
        current: this.page,
        pageSize: 10,
        defaultPageSize: 10,
        total: this.total,
      };
    },
  },
  created() {
    this.groupCurrent = this.getCXViewGroupCurrent
      ? this.getCXViewGroupCurrent
      : null;
  },

  methods: {
    ...mapMutations({
      setCXViewGroupCurrent: CustomersMutationTypes.SET_CXView_GROUP_CURRENT,
    }),
    dateTimeDisplayFormatFromUnix(unix) {
      return MixUtils.methods.dateTimeDisplayFormatFromUnix(unix);
    },
    dateTimeDisplayFormatFromShortString(time) {
      return MixUtils.methods.dateTimeDisplayFormatFromShortString(time);
    },
    // handleTableChange(pagination: PaginationProps) {
    //   this.tagReq.page = pagination?.current
    //   this.tagReq.page_size = pagination?.pageSize
    //   this.fetchRootTag()
    // },
    async handleTableChange(pagination) {
      this.page = pagination?.current;
      const response = await APIs.init().cxViewHistory({
        page: pagination?.current,
        page_size: pagination?.pageSize,
      });
      if (response.data.status === 200) {
        this.histories = response.data.data ? response.data.data : [];
        this.total = response.data.meta_data?.record_total
          ? response.data.meta_data?.record_total
          : 0;
      } else {
        this.histories = [];
        this.total = 0;
      }
    },
    uploadFile(e) {
      var files = e.target.files || e.dataTransfer.files;
      if (!files.length) return;
      this.data.image = this.$refs.image.files[0];
      //this.createImage(files[0]);
    },
    // createImage(file) {
    //     var reader = new FileReader();
    //
    //     reader.onload = (e) => {
    //         this.data.image = e.target.result;
    //     };
    //     reader.readAsDataURL(file);
    // },
    sendFaceCustomer() {
      // var self = this;
      // var name = this.data.name !== ''?this.data.name: this.customer_name;
      // let formData = new FormData();
      // formData.append('image', this.data.image);
      // formData.append('name', name);
      // formData.append('age', this.data.age);
      // formData.append('gender', this.data.gender);
      // formData.append('shop_id', this.shop_id);
      // formData.append('page_id', this.page_id);
      // formData.append('customer_id', JSON.stringify(this.conversation));
      // axios.post('/api/camera/add-customer',
      //     formData,
      //     {
      //         headers: {
      //             'Content-Type': 'multipart/form-data'
      //         }
      //     }
      // ).then(function (res) {
      //     if (res.data.code == 200) {
      //         self.$message.success(res.data.msg);
      //         self.visible = false;
      //     } else {
      //         self.$message.error(res.data.msg);
      //     }
      // }).catch(function () {
      //
      // });
    },
    showModal() {
      this.visible = true;
    },
    handleChange(value) {
      this.groupCurrent = value;
      this.setCXViewGroupCurrent(value);
      this.subscribeCXView(value);
      console.log(value);
    },
    changeTab(value) {
      console.log(value);
      if (value === 1) {
        this.handleTableChange({
          current: 1,
          pageSize: 10,
          defaultPageSize: 10,
          total: 0,
        });
      }
    },
  },
};
</script>

<style scoped></style>
