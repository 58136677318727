import {Province} from "@/models/Dictionary/Dictionary";

export type State = {
    provinces: Province[] | undefined;
    districts: Province[] | undefined;
    wards: Province[] | undefined;
    searchProvinces: Province[] ;
    searchDistricts: Province[] ;
    searchWards: Province[]
}

export const state: State = {
    provinces: [],
    districts: [],
    wards: [],
    searchProvinces: [],
    searchDistricts: [],
    searchWards: [],
}
