<template>
  <div>{{ index }} - {{ source }}</div>
</template>

<script>
export default {
  name: "ImageItem",
  props: {
    index: { // index of current item
      type: Number
    },
    source: { // here is: {uid: 'unique_1', text: 'abc'}
      type: Object,
      default () {
        return {}
      }
    }
  }
}
</script>

<style scoped>

</style>