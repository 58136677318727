import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, withCtx as _withCtx, createBlock as _createBlock, createTextVNode as _createTextVNode, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { style: {"border-radius":"10px","width":"100%","height":"auto"} }
const _hoisted_2 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_image = _resolveComponent("a-image")!
  const _component_a_col = _resolveComponent("a-col")!
  const _component_a_row = _resolveComponent("a-row")!
  const _component_a_tab_pane = _resolveComponent("a-tab-pane")!
  const _component_a_tabs = _resolveComponent("a-tabs")!
  const _component_VirtualList = _resolveComponent("VirtualList")!
  const _component_a_button = _resolveComponent("a-button")!
  const _component_delete_outlined = _resolveComponent("delete-outlined")!
  const _component_switcher_outlined = _resolveComponent("switcher-outlined")!
  const _component_cloud_upload_outlined = _resolveComponent("cloud-upload-outlined")!
  const _component_check_outlined = _resolveComponent("check-outlined")!
  const _component_a_modal = _resolveComponent("a-modal")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_a_modal, {
      visible: _ctx.visible,
      "onUpdate:visible": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.visible) = $event)),
      width: _ctx.previewMode ? '80%' : '40%',
      title: _ctx.$t('my_image_storage'),
      zIndex: 3000
    }, {
      footer: _withCtx(() => [
        _createVNode(_component_a_button, null, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.$t("button_cancel")), 1)
          ]),
          _: 1
        }),
        (!_ctx.previewMode)
          ? (_openBlock(), _createBlock(_component_a_button, {
              key: 0,
              danger: "",
              onClick: _ctx.deleteSelected,
              loading: _ctx.deleteLoading,
              disabled: _ctx.selectedList.length === 0
            }, {
              default: _withCtx(() => [
                _createVNode(_component_delete_outlined),
                _createTextVNode(" " + _toDisplayString(_ctx.$t("delete_selected_images")), 1)
              ]),
              _: 1
            }, 8, ["onClick", "loading", "disabled"]))
          : _createCommentVNode("", true),
        _createVNode(_component_a_button, {
          type: _ctx.previewMode ? 'primary' : 'dashed',
          onClick: _ctx.switchPreviewMode
        }, {
          default: _withCtx(() => [
            _createVNode(_component_switcher_outlined),
            _createTextVNode(" " + _toDisplayString(_ctx.previewMode
            ? _ctx.$t("switch_to_selection_mode")
            : _ctx.$t("switch_to_preview_mode")), 1)
          ]),
          _: 1
        }, 8, ["type", "onClick"]),
        (!_ctx.previewMode)
          ? (_openBlock(), _createBlock(_component_a_button, {
              key: 1,
              type: "primary",
              onClick: _ctx.onButtonUploadFileClick,
              loading: _ctx.uploadLoading
            }, {
              default: _withCtx(() => [
                _createVNode(_component_cloud_upload_outlined),
                _createTextVNode(" " + _toDisplayString(_ctx.$t("UPLOAD")), 1)
              ]),
              _: 1
            }, 8, ["onClick", "loading"]))
          : _createCommentVNode("", true),
        (!_ctx.previewMode)
          ? (_openBlock(), _createBlock(_component_a_button, {
              key: 2,
              type: "primary",
              onClick: _ctx.handleOk,
              disabled: _ctx.selectedList.length === 0
            }, {
              default: _withCtx(() => [
                _createVNode(_component_check_outlined),
                _createTextVNode(" " + _toDisplayString(_ctx.$t("datamodal_select")), 1)
              ]),
              _: 1
            }, 8, ["onClick", "disabled"]))
          : _createCommentVNode("", true)
      ]),
      default: _withCtx(() => [
        _createVNode(_component_VirtualList, {
          size: 50,
          remain: 20,
          style: {"height":"60vh"}
        }, {
          default: _withCtx(() => [
            _createVNode(_component_a_tabs, {
              activeKey: _ctx.activeKey,
              "onUpdate:activeKey": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.activeKey) = $event))
            }, {
              default: _withCtx(() => [
                _createVNode(_component_a_tab_pane, {
                  key: "1",
                  tab: _ctx.$t('IMAGE')
                }, {
                  default: _withCtx(() => [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.getListFile.image, (item, index) => {
                      return (_openBlock(), _createElementBlock("div", { key: index }, [
                        _createVNode(_component_a_row, null, {
                          default: _withCtx(() => [
                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(item, (child) => {
                              return (_openBlock(), _createBlock(_component_a_col, {
                                key: child.key,
                                span: 6,
                                onClick: ($event: any) => (_ctx.clickSelect(child.key))
                              }, {
                                default: _withCtx(() => [
                                  _createElementVNode("div", {
                                    class: _normalizeClass(
                  !_ctx.previewMode && _ctx.isSelected(child.key)
                    ? 'item-image-selection'
                    : 'item-image-unselected'
                )
                                  }, [
                                    _createVNode(_component_a_image, {
                                      class: "img-selection",
                                      preview: _ctx.previewMode,
                                      src: _ctx.getUrlImage(child.url)
                                    }, null, 8, ["preview", "src"]),
                                    _createElementVNode("div", null, _toDisplayString(_ctx.getFileName(child.url)), 1)
                                  ], 2)
                                ]),
                                _: 2
                              }, 1032, ["onClick"]))
                            }), 128))
                          ]),
                          _: 2
                        }, 1024)
                      ]))
                    }), 128))
                  ]),
                  _: 1
                }, 8, ["tab"]),
                _createVNode(_component_a_tab_pane, {
                  key: "2",
                  tab: "Video",
                  "force-render": ""
                }, {
                  default: _withCtx(() => [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.getListFile.video, (item, index) => {
                      return (_openBlock(), _createElementBlock("div", { key: index }, [
                        _createVNode(_component_a_row, null, {
                          default: _withCtx(() => [
                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(item, (child) => {
                              return (_openBlock(), _createBlock(_component_a_col, {
                                key: child.key,
                                span: 6,
                                onClick: ($event: any) => (_ctx.clickSelect(child.key))
                              }, {
                                default: _withCtx(() => [
                                  _createElementVNode("div", {
                                    class: _normalizeClass(
                  !_ctx.previewMode && _ctx.isSelected(child.key)
                    ? 'item-image-selection'
                    : 'item-image-unselected'
                )
                                  }, [
                                    _createElementVNode("div", null, [
                                      _createElementVNode("video", _hoisted_1, [
                                        _createElementVNode("source", {
                                          src: child.url,
                                          type: "video/mp4"
                                        }, null, 8, _hoisted_2)
                                      ])
                                    ]),
                                    _createElementVNode("div", null, _toDisplayString(_ctx.getFileName(child.url)), 1)
                                  ], 2)
                                ]),
                                _: 2
                              }, 1032, ["onClick"]))
                            }), 128))
                          ]),
                          _: 2
                        }, 1024)
                      ]))
                    }), 128))
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }, 8, ["activeKey"])
          ]),
          _: 1
        })
      ]),
      _: 1
    }, 8, ["visible", "width", "title"]),
    _createElementVNode("input", {
      type: "file",
      ref: "fileInput",
      onChange: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.handleFileUpload && _ctx.handleFileUpload(...args))),
      multiple: "",
      hidden: "true"
    }, null, 544)
  ], 64))
}