import {ProductGroupData, State} from "./states";
import {ProductCategory, ProductDetail, ProductProvider, ProductGroup} from "@/models/Products/Products";

export type Getters = {
    getProductGroups(state: State): ProductGroupData
    getProductDetail(state: State): ProductDetail
    getProductCategories(state: State): ProductCategory[]
    getProductProviders(state: State): ProductProvider[]
}
export const getters: Getters = {
    getProductGroups(state: State): ProductGroupData {
        if(state.productGroupData){
            return state.productGroupData
        }else{
           return {productGroups: [], metadata: {}, loading: false }
        }
    },
    getProductDetail(state: State): ProductDetail {
        if (state.productDetail) {
            return state.productDetail
        } else {
            return {versions: []}
        }
    },
    getProductCategories(state: State): ProductCategory[] {
        if (state.productCategories) {
            return state.productCategories
        } else {
            return []
        }
    },
    getProductProviders(state: State): ProductProvider[] {
        if (state.productProviders) {
            return state.productProviders
        } else {
            return []
        }
    },
}