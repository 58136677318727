import {State} from "./states";
import {Trigger} from "@/models/Trigger/Trigger";
import {SelectionAnt} from "@/models/ShopPages/ConfigData";
import {Metadata} from "@/APIs/response/S_HttpResponse";
import {TriggerStatistic, TriggerStatisticErrorLog, Viral} from "@/models/Trigger/TriggerHistory";

export type Getters = {
    getTriggers(state: State): Trigger[]
    getTriggersSelection(state: State): SelectionAnt[]
    getTriggerMetadata(state: State): Metadata
    getTriggerStatisticMetadata(state: State): Metadata
    getTriggerStatistics(state: State): TriggerStatistic[]
    getInnerTriggerStatistics(state: State): TriggerStatistic[]
    getTriggerStatisticLogs(state: State): TriggerStatisticErrorLog[]
    getInnerTriggerStatisticMetadata(state: State): Metadata
    getTriggersQuickMessages(state: State): Trigger[]
    getViral(state: State): Viral[]
}
export const getters: Getters = {
    getTriggers(state: State): Trigger[] {
        if (state.triggers) {
            return state.triggers
        } else {
            return []
        }
    },
    getTriggerMetadata(state: State): Metadata {
        if (state.triggerMetadata) {
            return state.triggerMetadata
        } else {
            return {}
        }
    },
    getTriggersSelection(state: State): SelectionAnt[] {
        if (state.triggers) {
            const data = state.triggers.map((item) => {
                const data: SelectionAnt = {
                    label: item.name,
                    value: item.id+''
                }
                return data;
            })
            const itemAll: SelectionAnt = {
                label: 'ALL',
                value: '0',
            }
            return [itemAll, ...data]
        } else {
            return []
        }
    },
    getTriggerStatistics(state: State): TriggerStatistic[] {
        if (state.triggerStatistics) {
            return state.triggerStatistics
        } else {
            return []
        }
    },
    getInnerTriggerStatistics(state: State): TriggerStatistic[] {
        if (state.triggerStatistics) {
            return state.triggerStatistics
        } else {
            return []
        }
    },
    getTriggerStatisticMetadata(state: State): Metadata {
        if (state.triggerStatisticMetadata) {
            return state.triggerStatisticMetadata
        } else {
            return {

            }
        }
    },
    getInnerTriggerStatisticMetadata(state: State): Metadata {
        if (state.innerTriggerStatisticMetadata) {
            return state.innerTriggerStatisticMetadata
        } else {
            return {

            }
        }
    },
    getTriggersQuickMessages(state: State): Trigger[] {
        return state.triggerQuickMessages
    },
    getTriggerStatisticLogs(state: State): TriggerStatisticErrorLog[] {
        return state.triggerStatisticErrorLogs
    },
    getViral(state: State): Viral[] {
        if (state.viral) {
            return state.viral
        } else {
            return []
        }
    },
}
