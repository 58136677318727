import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-7dd31809"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "container" }
const _hoisted_2 = { class: "main-header" }
const _hoisted_3 = { class: "live-chat-button" }
const _hoisted_4 = { class: "btn active" }
const _hoisted_5 = ["href"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ToolOutlined = _resolveComponent("ToolOutlined")!
  const _component_MessageOutlined = _resolveComponent("MessageOutlined")!
  const _component_a_badge = _resolveComponent("a-badge")!
  const _component_right_content = _resolveComponent("right-content")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("a", _hoisted_4, [
          _createVNode(_component_ToolOutlined, { style: {"font-size":"22px"} }),
          _createTextVNode(" " + _toDisplayString(_ctx.$t('CRM_MANAGER')), 1)
        ])
      ]),
      (_ctx.shopActive && _ctx.roleLiveChat)
        ? (_openBlock(), _createBlock(_component_a_badge, { key: 0 }, {
            default: _withCtx(() => [
              _createElementVNode("a", {
                href: _ctx.goChatBeta(),
                class: "btn"
              }, [
                _createVNode(_component_MessageOutlined, { style: {"font-size":"22px"} }),
                _createTextVNode(" Live Chat ")
              ], 8, _hoisted_5)
            ]),
            _: 1
          }))
        : _createCommentVNode("", true)
    ]),
    _createVNode(_component_right_content, { class: "right-content" })
  ]))
}