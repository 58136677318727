<template>
  <div class="container">
    <span class="header"><play-circle-two-tone two-tone-color="#52c41a" :style="{fontSize: '20px'}" /> {{$t('STARTING_STEP')}}</span>
    <div class="trigger_item">

      <span style="font-weight: bold">{{$t(data.type)}}</span>
      <br/>
      {{data.name}}
    </div>
    <template v-for="item in data.customer_condition.trigger_comparisons" :key="item.key">

      <a-column class="element"   justify="start">
        <span style="font-weight: 500">
          ({{$t(data.customer_condition.condition)}}) {{$t(item.field_type)}} - <span style="font-weight: bold">
          {{$t(item.condition)}}
        </span>
        </span>

        <div>

            <a-col  style="padding: 4px;">
              <a-tree-select v-if="item.field_type==='tag'"
                             disabled
                             @change="handleChangeTagModal"
                             show-search
                             style="width: 100%"
                             @search="searchTag"
                             :filterTreeNode="false"
                             v-model:searchValue="searchTagValue"
                             v-model:value="item.value"
                             :notFoundContent="null"
                             :dropdown-style="{ maxHeight: '400px', overflow: 'auto' }"
                             allow-clear
                             :placeholder="$t('TAG_SELECT')"
              >
                <a-tree-select-node v-for="item in getTreeTags" :key="item.id" :value="item.id+''">
                  <template #title>
                    <a-tag :color="item.color">
                      {{ item.name }}
                    </a-tag>
                  </template>
                  <a-tree-select-node v-for="child in item.children" :key="child.id" :value="child.id+''">
                    <template #title>
                      <a-tag :color="child.color">
                        {{ child.name }}
                      </a-tag>
                    </template>
                  </a-tree-select-node>

                </a-tree-select-node>
              </a-tree-select>
              <a-input style="width: 100%;" v-if="item.field_type==='INT' || item.field_type === 'CURRENCY'"  v-model:value="item.value"  />
              <a-input style="width: 100%;" v-if="item.field_type==='STRING' "  v-model:value="item.value"  />
              <a-range-picker style="width: 100%;" v-if="item.field_type === 'DATE'" format="DD-MM-YYYY" :valueFormat="'YYYY-MM-DDTHH:mm:ssZ'"  v-model:value="item.value"  />
              <a-select v-if="item.field_type==='page_id'"
                        style="width: 100%;"
                        show-search
                        disabled
                        :filter-option="filterOption2"
                        :placeholder="$t('SELECT_PAGE')"
                        v-model:value="item.value">
                <a-select-option v-for="it in   getPages"
                                 :key="it" :value="it.page_id+''">

                  <img
                      v-if="it.page_type === 2"
                      draggable="false"
                      src="@/assets/icons/zalo-seeklogo.com.svg"
                      style="width: 20px;margin-right: 20px"
                  />
                  <img
                      v-else-if="it.page_type === 1"
                      draggable="false"
                      src="@/assets/icons/facebook-svgrepo-com.svg"
                      style="width: 20px;margin-right: 20px"
                  />
                  <img
                      v-else-if="it.page_type === 3"
                      draggable="false"
                      src="@/assets/icons/instagram-svgrepo-com.svg"
                      style="width: 20px;margin-right: 20px"
                  />
                  <img
                      v-else-if="it.page_type === 4"
                      draggable="false"
                      src="@/assets/icons/line-svgrepo-com.svg"
                      style="width: 20px;margin-right: 20px"
                  />{{ it.page_name }}
                </a-select-option>
              </a-select>
              <a-select v-if="item.field_type==='messenger_window_24h'"
                        style="width: 100%;"
                        disabled
                        :placeholder="$t('MESSAGE')"
                        v-model:value="item.value">
                <a-select-option v-for="it in getFlowConstants.trigger_condition_messenger_window_24_h_field_types"
                                 :key="it" value="it">{{ it }}
                </a-select-option>
              </a-select>
              <a-select v-if="item.field_type==='conversation_tag'"
                        style="width: 100%;"
                        disabled
                        :placeholder="$t('SELECT_TAG')"
                        show-search
                        :default-active-first-option="false"
                        :show-arrow="false"
                        :filter-option="false"
                        :not-found-content="null"
                        v-model:value="item.value">
                <a-select-option v-for="it in getConversationTags"
                                 :key="it.tag_id+'_4'+index" :value="it.tag_id+''">
                  <a-tag :color="it.tag_color">
                    {{ it.tag_name }}
                  </a-tag>
                </a-select-option>
              </a-select>

              <a-select v-if="item.field_type==='post_id'"
                        style="width: 100%;"
                        placeholder="Select post"
                        disabled
                        show-search
                        :default-active-first-option="false"
                        :show-arrow="false"
                        :filter-option="false"
                        :not-found-content="null"
                        v-model:value="item.value">
                <a-select-option v-for="post in getPostsSelection" :key="post.id" :value="post.id">
                  <a-avatar shape="square" :src="getPostPicture(post)">
                  </a-avatar>
                  {{ getPostName(post) }}
                </a-select-option>
              </a-select>
              <a-select v-if="item.field_type==='care_status'"
                        style="width: 100%;"
                        placeholder="Select status"
                        show-search
                        disabled
                        :default-active-first-option="false"
                        :show-arrow="false"
                        :filter-option="false"
                        :not-found-content="null"
                        v-model:value="item.value">
                <a-select-option v-for="tag in tags" :key="tag.id" :value="tag.id+''">
                  <a-tag :color="tag.color">{{ tag.name }}</a-tag>
                </a-select-option>
              </a-select>
              <a-select v-if="item.field_type==='order_status'"
                        style="width: 100%;"
                        placeholder="Select status"
                        show-search
                        disabled
                        :default-active-first-option="false"
                        :show-arrow="false"
                        :filter-option="false"
                        :not-found-content="null"
                        v-model:value="item.value">
                <a-select-option v-for="i in orderStatus" :key="i.id" :value="i.id+''">
                  <a-tag :color="getOrderStatusColor(i.id)">{{ $t(i.name) }}</a-tag>
                </a-select-option>
              </a-select>
              <a-range-picker v-if="item.field_type==='last_contact_time'" v-model:value="item.value" format="DD-MM-YYYY" :valueFormat="'YYYY-MM-DDTHH:mm:ssZ'" />
              <a-range-picker v-if="item.field_type==='first_contact_time'" v-model:value="item.value" format="DD-MM-YYYY" :valueFormat="'YYYY-MM-DDTHH:mm:ssZ'"/>
              <a-select v-if="item.field_type==='timeout'"
                        style="width: 100%;"
                        show-search
                        disabled
                        placeholder="Select Timeout"
                        :default-active-first-option="false"
                        :show-arrow="false"
                        :not-found-content="null"
                        :filter-option="filterOption"

                        v-model:value="item.value">
                <a-select-option
                    v-for="time in timeoutSelections"
                    :key="time"
                    :value="time.value"
                >{{ time.label }}
                </a-select-option>
              </a-select>

              <!--                      <a-input v-if="item.field_type==='key_word'"-->
              <!--                               v-model:value="item.value"-->
              <!--                               placeholder="You key word"-->
              <!--                               style="width: 100%;"/>-->
              <a-select
                  disabled
                  v-if="item.field_type==='key_word'"
                  v-model:value="item.value"
                  mode="tags"
                  style="width: 100%"
                  placeholder="You key word"

              >
                <a-select-option v-for="i in options" :key="i" :value="i.value">
                  {{$t(i.value)}}
                </a-select-option>
              </a-select>

            </a-col>
            <a-col :span="3" style="padding: 4px; text-align: center">
              <delete-outlined @click="deleteCustomerConditionRow(index)"/>
            </a-col>

        </div>
      </a-column>
    </template>
<!--    <a-button class="add_trigger" type="dashed" shape="round" @click="addTrigger">{{$t('ADD_TRIGGER')}}</a-button>-->

<!--    <a-row justify="center" style="position: absolute; bottom: 20px; right: 10px">-->
<!--      <span style="text-align: right; flex: 1;">{{$t('THEN')}}</span>-->
<!--    </a-row>-->
<!--    <Handle :id="data.id+''" class="handle" type="source" :position="Position.Right"   :style="sourceHandleStyleA"/>-->

  </div>
  <div class="footer">
    <div  style="height: 2px; background-color: #f2f4f6;"/>
    <template v-for="(handle, index) in listHandle" :key="handle">
      <HandleCustom :data="handle" :index="index"  />
    </template>
  </div>

  <Triggers  ref="triggerRef" version="new" style="width: 0; height: 0; margin: 0; padding: 0;"/>
</template>

<script>
import {defineComponent, defineProps, defineEmits, onMounted, ref, watch} from "vue";
import { computed } from 'vue'
import { Position, VueFlow, useVueFlow,Handle  } from '@vue-flow/core'
import {
  PlayCircleTwoTone,
  FacebookOutlined,
  StopOutlined, ExclamationCircleOutlined, HistoryOutlined
} from "@ant-design/icons-vue";
import {TriggerRequest} from "@/APIs/request/TriggerRequest";
import {TriggerForm} from "@/models/Trigger/TriggerForm";
import {useI18n} from "vue-i18n";
import {mapGetters} from "vuex";
import {store, useStore} from "@/store";
import APIs from "@/APIs/APIs";
import dayjs from "dayjs";
import {TriggerCustomerComparison} from "@/models/Trigger/Trigger";
import Triggers from '../Trigger/Triggers.vue'
import {MixUtils} from "@/utils/Utils";
import {FacebookPostData} from "@/models/facebook/FacebookPost";
import HandleCustom from "./HandleCustom.vue";

const props = defineProps({
  data: {
    type: Object,
    required: true,
  },
})

const rules = {
  name: [
    {required: true, message: 'Please input display name', trigger: 'blur'},
  ],
  root_flow_id: [
    {
      type: 'number',
      required: true,
      message: 'Please select at least one flow',
      trigger: 'change',
    },
  ],
  type: [
    {
      type: 'string',
      required: true,
      message: 'Please select at least one type',
      trigger: 'change',
    },
  ],
  trigger_condition: {
    message: 'Please add at least one condition',
    isValid: true
  }
};


const triggerRequest = {}
var formState = {
  customer_condition: {
    condition: '',
    trigger_comparisons: []
  }
}

let formConfig = {
  hasMail: false,
      selectedPage: 0,
      viralUrl: "",
      viralQRURL:""
}

export default defineComponent({
  name: "TriggerComponent",
  components:{
    PlayCircleTwoTone,
    // FacebookOutlined,
    // Handle,
    HandleCustom,
    Triggers
  },
  props: ['trigger', 'data', 'selected', 'events'],
  setup(props, {emit}){
    const i18n = useI18n()

    const listHandle = ref([{
      id: props.data.id,
      name: i18n.t('NEXT_ACTION')
    }])
    const triggerRef = ref();

    props.events.click(()=>{
      triggerRef.value.customRowClick(props.data)

    })

    const store = useStore()
    const visible = ref(false);
    const tabModalEditKey = ref('1');
    const dateFormat = "DD-MM-YYYY HH:mm";

    const sourceHandleStyleA = computed(() => ({  bottom: '18px',
      top: 'auto', }))

    const addTrigger = ()=>{
      visible.value = true
    }
    const placement = ref('right');
    const showDrawer = () => {
      visible.value = true;
    };

    const onClose = () => {
      visible.value = false;
    };

    const getFlowConstants = computed(()=>store.getters.getFlowConstants)
    const getTriggerTags = computed(()=>store.getters.getTriggerTags)
    const getAdminAndShopOwner = computed(()=>store.getters.getAdminAndShopOwner)
    const getFlowsSelectionAntWithoutAll = computed(()=>store.getters.getFlowsSelectionAntWithoutAll)
    const getCustomerConfig = computed(()=>store.getters.getCustomerConfig)
    const getTreeTags = computed(()=>store.getters.getTreeTags)
    const getPages = computed(()=>store.getters.getPages)
    const getPostsSelection = computed(()=>store.getters.getPostsSelection(''))
    const getConversationTags = computed(()=>store.getters.getConversationTags)




    const conversationTags = () =>{
      let page_id = ''
      let tags = []
      try {
        page_id = props.data.customer_condition?.trigger_comparisons?.find((condition) => condition.field_type === 'page_id').value
        tags = JSON.parse(getPages.value.find((page) => page.page_id === page_id).config_data).tags;
      } catch (e) {
        console.log(e)
      }
      return tags
    }

    const timeoutSelections =          [
      {
        label: '10 ' + i18n.t('second'),
        value: "10"  //second
      },
      {
        label: '20 ' + i18n.t('second'),
        value: "20"  //second
      },
      {
        label: '1 ' + i18n.t('minutes'),
        value: "60"  //second
      },
      {
        label: '2 ' + i18n.t('minutes'),
        value: "120"  //second
      },
      {
        label: '3 ' + i18n.t('minutes'),
        value: "180"  //second
      },
      {
        label: '4 ' + i18n.t('minutes'),
        value: "240"  //second
      },
      {
        label: '5 ' + i18n.t('minutes'),
        value: "300"  //second
      },
      {
        label: '6 ' + i18n.t('minutes'),
        value: "360"  //second
      },
      {
        label: '7 ' + i18n.t('minutes'),
        value: "420"  //second
      },
      {
        label: '8 ' + i18n.t('minutes'),
        value: "480"  //second
      },
      {
        label: '9 ' + i18n.t('minutes'),
        value: "640"  //second
      },

      {
        label: '10 ' + i18n.t('minutes'),
        value: "600"  //second
      },
      {
        label: '11 ' + i18n.t('minutes'),
        value: "660"  //second
      },
      {
        label: '12 ' + i18n.t('minutes'),
        value: "720"  //second
      },
      {
        label: '13 ' + i18n.t('minutes'),
        value: "780"  //second
      },
      {
        label: '14 ' + i18n.t('minutes'),
        value: "840"  //second
      },
      {
        label: '15 ' + i18n.t('minutes'),
        value: "900"  //second
      },
      {
        label: '16 ' + i18n.t('minutes'),
        value: "960"  //second
      },
      {
        label: '17 ' + i18n.t('minutes'),
        value: "1020"  //second
      },
      {
        label: '18 ' + i18n.t('minutes'),
        value: "1080"  //second
      },
      {
        label: '19 ' + i18n.t('minutes'),
        value: "1140"  //second
      },
      {
        label: '20 ' + i18n.t('minutes'),
        value: "1200"  //second
      },
      {
        label: '21 ' + i18n.t('minutes'),
        value: "1260"  //second
      },
      {
        label: '22 ' + i18n.t('minutes'),
        value: "1320"  //second
      },
      {
        label: '23 ' + i18n.t('minutes'),
        value: "1380"  //second
      },
      {
        label: '24 ' + i18n.t('minutes'),
        value: "1440"  //second
      },
      {
        label: '25 ' + i18n.t('minutes'),
        value: "1500"  //second
      },
      {
        label: '26 ' + i18n.t('minutes'),
        value: "1560"  //second
      },
      {
        label: '27 ' + i18n.t('minutes'),
        value: "1620"  //second
      },
      {
        label: '28 ' + i18n.t('minutes'),
        value: "1680"  //second
      },
      {
        label: '29 ' + i18n.t('minutes'),
        value: "1740"  //second
      },
      {
        label: '30 ' + i18n.t('minutes'),
        value: "1800"  //second
      },
      {
        label: '31 ' + i18n.t('minutes'),
        value: "1860"  //second
      },
      {
        label: '32 ' + i18n.t('minutes'),
        value: "1920"  //second
      },
      {
        label: '33 ' + i18n.t('minutes'),
        value: "1980"  //second
      },
      {
        label: '34 ' + i18n.t('minutes'),
        value: "2040"  //second
      },
      {
        label: '35 ' + i18n.t('minutes'),
        value: "2100"  //second
      },
      {
        label: '36 ' + i18n.t('minutes'),
        value: "2160"  //second
      },
      {
        label: '37 ' + i18n.t('minutes'),
        value: "2220"  //second
      },
      {
        label: '38 ' + i18n.t('minutes'),
        value: "2280"  //second
      },
      {
        label: '39 ' + i18n.t('minutes'),
        value: "2340"  //second
      },
      {
        label: '40 ' + i18n.t('minutes'),
        value: "2400"  //second
      },
      {
        label: '41 ' + i18n.t('minutes'),
        value: "2460"  //second
      },
      {
        label: '42 ' + i18n.t('minutes'),
        value: "2520"  //second
      },
      {
        label: '43 ' + i18n.t('minutes'),
        value: "2580"  //second
      },
      {
        label: '44 ' + i18n.t('minutes'),
        value: "2640"  //second
      },
      {
        label: '45 ' + i18n.t('minutes'),
        value: "2700"  //second
      },
      {
        label: '46 ' + i18n.t('minutes'),
        value: "2760"  //second
      },
      {
        label: '47 ' + i18n.t('minutes'),
        value: "2820"  //second
      },
      {
        label: '48 ' + i18n.t('minutes'),
        value: "2880"  //second
      },
      {
        label: '49 ' + i18n.t('minutes'),
        value: "2940"  //second
      },
      {
        label: '50 ' + i18n.t('minutes'),
        value: "3000"  //second
      },
      {
        label: '51 ' + i18n.t('minutes'),
        value: "3060"  //second
      },
      {
        label: '52 ' + i18n.t('minutes'),
        value: "3120"  //second
      },
      {
        label: '53 ' + i18n.t('minutes'),
        value: "3180"  //second
      },
      {
        label: '54 ' + i18n.t('minutes'),
        value: "3240"  //second
      },
      {
        label: '55 ' + i18n.t('minutes'),
        value: "3300"  //second
      },
      {
        label: '56 ' + i18n.t('minutes'),
        value: "3360"  //second
      },
      {
        label: '57 ' + i18n.t('minutes'),
        value: "3420"  //second
      },
      {
        label: '58 ' + i18n.t('minutes'),
        value: "3480"  //second
      },
      {
        label: '59 ' + i18n.t('minutes'),
        value: "3540"  //second
      },
      {
        label: '1 ' + i18n.t('HOURS'),
        value: "3600"  //second
      },
      {
        label: '2 ' + i18n.t('HOURS'),
        value: "7200"  //second
      },
      {
        label: '3 ' + i18n.t('HOURS'),
        value: "10800"  //second
      },
      {
        label: '4 ' + i18n.t('HOURS'),
        value: "14400"  //second
      },
      {
        label: '5 ' + i18n.t('HOURS'),
        value: "18000"  //second
      },
      {
        label: '6 ' + i18n.t('HOURS'),
        value: "21600"  //second
      },
      {
        label: '7 ' + i18n.t('HOURS'),
        value: "25200"  //second
      },
      {
        label: '8 ' + i18n.t('HOURS'),
        value: "28800"  //second
      },
      {
        label: '9 ' + i18n.t('HOURS'),
        value: "32400"  //second
      },
      {
        label: '10 ' + i18n.t('HOURS'),
        value: "36000"  //second
      },
      {
        label: '11 ' + i18n.t('HOURS'),
        value: "39600"  //second
      },
      {
        label: '12 ' + i18n.t('HOURS'),
        value: "43200"  //second
      },
      {
        label: '13 ' + i18n.t('HOURS'),
        value: "46800"  //second
      },
      {
        label: '14 ' + i18n.t('HOURS'),
        value: "50400"  //second
      },
      {
        label: '15 ' + i18n.t('HOURS'),
        value: "54000"  //second
      },
      {
        label: '16 ' + i18n.t('HOURS'),
        value: "57000"  //second
      },
      {
        label: '17 ' + i18n.t('HOURS'),
        value: "61200"  //second
      },
      {
        label: '18 ' + i18n.t('HOURS'),
        value: "64800"  //second
      },
      {
        label: '19 ' + i18n.t('HOURS'),
        value: "68400"  //second
      },
      {
        label: '20 ' + i18n.t('HOURS'),
        value: "72000"  //second
      },
      {
        label: '21 ' + i18n.t('HOURS'),
        value: "75600"  //second
      }, {
        label: '22 ' + i18n.t('HOURS'),
        value: "79200"  //second
      }, {
        label: '23 ' + i18n.t('HOURS'),
        value: "82800"  //second
      },
      {
        label: '24 ' + i18n.t('HOURS'),
        value: "86400"  //second
      },
      {
        label: '2 ' + i18n.t('days'),
        value: "172800"  //second
      },
      {
        label: '3 ' + i18n.t('days'),
        value: "259200"  //second
      },
      {
        label: '4 ' + i18n.t('days'),
        value: "345600"  //second
      },

      {
        label: '5 ' + i18n.t('days'),
        value: "432000"  //second
      },
      {
        label: '6 ' + i18n.t('days'),
        value: "518400"  //second
      },
      {
        label: '1 ' + i18n.t('week'),
        value: "604800"
      },
      {
        label: '2 ' + i18n.t('week'),
        value: "1209600"  //second
      },
      {
        label: '3 ' + i18n.t('week'),
        value: "1814400"  //second
      },
      {
        label: '30 ' + i18n.t('days'),
        value: "2592000"  //second
      },
      {
        label: '2 ' + i18n.t('month'),
        value: "5184000"  //second
      },
      {
        label: '3 ' + i18n.t('month'),
        value: "7776000"  //second
      },
      {
        label: '4 ' + i18n.t('month'),
        value: "10368000"  //second
      },
      {
        label: '5 ' + i18n.t('month'),
        value: "12960000"  //second
      },
      {
        label: '6 ' + i18n.t('month'),
        value: "15552000"  //second
      },
      {
        label: '7 ' + i18n.t('month'),
        value: "18144000"  //second
      },
      {
        label: '8 ' + i18n.t('month'),
        value: "20736000"  //second
      },
      {
        label: '9 ' + i18n.t('month'),
        value: "23328000"  //second
      },
      {
        label: '10 ' + i18n.t('month'),
        value: "25920000"  //second
      },
      {
        label: '11 ' + i18n.t('month'),
        value: "28512000"  //second
      },
      {
        label: '12 ' + i18n.t('month'),
        value: "31104000"  //second
      },
      {
        label: '2 ' + i18n.t('YEAR'),
        value: "62208000"  //second
      },

    ]

    const getPostPicture = (post) =>{
      if (post.full_picture) {
        return post.full_picture
      } else if (post.attachments && post.attachments.length > 0 && post.attachments[0].media && post.attachments[0].media.image && post.attachments[0].media.image.src) {
        return post.attachments[0].media.image.src
      } else {
        return "https://biglead.live/images/logo.png"
      }

    }

    const getPostName = (post) =>{
      if (post.message) {
        return post.message
      } else if (post.story) {
        return post.story
      } else if (post.attachments && post.attachments.length > 0 && post.attachments[0].title) {
        return post.attachments[0].title
      } else {
        return post.id
      }
    }

    return{
      sourceHandleStyleA,
      Position,
      addTrigger,
      placement,
      visible,
      showDrawer,
      onClose,
      formState,
      tabModalEditKey,
      getFlowConstants,
      getTriggerTags,
      getAdminAndShopOwner,
      getFlowsSelectionAntWithoutAll,
      getCustomerConfig,
      getTreeTags,
      conversationTags,
      formConfig,
      dateFormat,
      triggerRef,
      getPages,
      timeoutSelections,
      getPostsSelection,
      getPostPicture,
      getPostName,
      listHandle,
      getConversationTags,

    }
  }
})
</script>

<style scoped>
.container{
  padding: 16px 16px 16px 16px;
  font-size: 14px;
}
.header{
  display: flex;
  flex-flow: row;
  align-items: center;
  gap: 8px;
  margin-bottom: 10px;
}
.add_trigger{
  border-width: 0.5px;
  width: 100%;
  border-color: #acacac80;
  border-style: dotted;
}
.first_step{

  text-align: right;
}
.element{
  display: flex;
  flex-direction: column;
  padding: 8px ;
  border-radius: 10px;
  margin-top: 12px;
  width: 100%;
  background-color: #e1faec;
}
.content{
  font-size: 14px;
  font-weight: normal;
}
.description{
  color: #758697;
  font-size: 12px;
}
.add_trigger{
  height: 50px;
}
.trigger_item{
  background-color: #e1faec;
  padding: 8px;
  border-radius: 10px;
  border-width: 2px;
  border-style: solid;
  border-color: transparent;
}
.trigger_item:hover{
  border-color: #0ccb6b;
}
.footer{
  padding: 16px
}
</style>
