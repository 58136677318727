<template>

  <div class="container-title">

    <h3 class="title-header">
      {{ $t($route.name) }}
    </h3>
    <a-form
        :model="template"
        ref="formCustomerRef"
        :rules="rules"
        layout="inline"

    >
      <a-form-item :label="$t('TEMPLATE_NAME')" name="event_name">
        <a-input
            v-model:value="template.name"
            :placeholder="$t('TEMPLATE_NAME')"


        />
      </a-form-item>
      <a-button @click="exportHtml">{{$t('SAVE')}}</a-button>
    </a-form>
<!--    <button v-on:click="saveDesign">Save Design</button>-->
    <router-link to="/ticket/template">
      <a-button  style="margin-left: 20px" >
        <template #icon><rollback-outlined /> </template>
      </a-button>
    </router-link>


  </div>
<a-card>
  <EmailEditor
      :options="options"
      :appearance="appearance"
      style="height: calc(100vh - 200px)"
      editorId="1"
      ref="emailEditorRef"
      v-on:load="editorLoaded"
  />
</a-card>

</template>

<script >
import {computed, defineComponent, inject, onMounted, onUnmounted, reactive, ref, watch, } from "vue";
import {message, notification} from "ant-design-vue";
import APIs from "@/APIs/APIs";
import {useStore} from "@/store";
import {DeleteOutlined, EditOutlined, EllipsisOutlined,RollbackOutlined} from "@ant-design/icons-vue";
import {useRoute, useRouter} from "vue-router";
import {useI18n} from "vue-i18n";
import { EmailEditor } from "vue-email-editor";
import { Base64 } from 'js-base64';
export default defineComponent({
  name: "Payment",
  components: {
    EmailEditor,
    RollbackOutlined
  },

  setup() {
    // ref of the email editor
    const store = useStore()
    const route = useRoute()
    const router = useRouter()
    const i18n = useI18n()
    const getUserInfo =  computed(() => store.getters.getUserInfo)
    const emailEditorRef = ref();
    const template = ref({name:' ',config:' ',content:' '})
    const getTicketTemplate = computed(()=> store.getters.getTicketTemplate)
// Method used to save a design
//     function saveDesign() {
//       emailEditorRef.value?.editor.saveDesign((design) => {
//         console.log("saveDesign", JSON.stringify(design));
//       });
//     }
    if(route.params.id !== ':id' && route.params.id !== 'default'){
      console.log(template.value,getTicketTemplate.value.templates,'111')
      template.value = route.params.id !== ':id' && route.params.id !== 'default' ?  getTicketTemplate.value.templates.find((item) => item.id === Number(route.params.id)) !== 'null' ? getTicketTemplate.value.templates.find((item) => item.id === Number(route.params.id)) :{} :{}
      template.value.config = Base64.decode(template.value.config)
    }
// Method used to export a design
    function exportHtml() {
      emailEditorRef.value?.editor.exportHtml((data) => {
        console.log("exportHtml", data);
        addTemplate(data.html,JSON.stringify(data.design))
      });
    }
   const   addTemplate = async (content,config) =>{
      template.value.content =Base64.encode(content)
      template.value.shop_id = getUserInfo.value.current_shop_id
     template.value.config = Base64.encode(config)
      if(route.params.id === ':id' || route.params.id === 'default' ){
        const res = await APIs.init().addTicketTemplate(template.value)
        if(res.data.status === 200){
          message.success(i18n.t('SUCCESS'))
          router.push(`/mail_editor/${res.data.data.id}`)

        }
      }else {
        template.value.id = template.value.id ? template.value.id : Number(route.params.id)
        const res = await APIs.init().updateTicketTemplate(template.value)
        if(res.data.status === 200){
          message.success(i18n.t('SUCCESS'))


        }
      }
    }
    // function importDesign() {
    //   const file = hiddenPicker.value.files[0];
    //   if (!file) return;
    //   if (file.type !== "application/json") return;
    //   const reader = new FileReader();
    //   reader.onload = (e) => {
    //     const result = e.target?.result;
    //     if (result) {
    //       emailEditorRef.value?.editor.loadDesign(JSON.parse(result.toString()));
    //     }
    //   };
    //   reader.readAsText(file);
    // }

    const options = {
      fonts: {
        showDefaultFonts: false,
      },
      features: {
        colorPicker: {
          presets: [
            "#fff",
            "#000",
          ],
        },
      },
      mergeTags: {
        full_name: {
          name: 'Họ tên',
          value: '{{full_name}}',
        },
        gender: {
          name: 'Gender',
          value: '{{gender}}',
        },
        organization: {
          name: 'Organization',
          value: '{{organization}}',
        },
        email: {
          name: 'Email',
          value: '{{email}}',
        },
        phone: {
          name: 'Phone',
          value: '{{phone}}',
        },
        option_1: {
          name: 'Chức danh',
          value: '{{option_1}}',
        },
        option_2: {
          name: 'Vai trò',
          value: '{{option_2}}',
        },
        option_3: {
          name: 'Ăn trưa',
          value: '{{option_3}}',
        },
        qrcode: {
          name: 'QR Code',
          value: `<img  style="height: 100%;width: 100%" src="https://quickchart.io/qr?text={{qrcode}}&size=300" />`,
        },
      },
    };

    const appearance = computed(() => {
      return {
        theme:  "light",
      };
    });
    const editorLoaded = () => {
      emailEditorRef.value?.editor.loadDesign(route.params.id === ':id' ? {
        "counters": {
          "u_column": 1,
          "u_row": 1,
          "u_content_heading": 1,
          "u_content_image": 1
        },
        "body": {
          "id": "FwcNn9-HWM",
          "rows": [
            {
              "id": "ZNsDNTX2a0",
              "cells": [
                1
              ],
              "columns": [
                {
                  "id": "q4xGlu3Kuk",
                  "contents": [
                    {
                      "id": "EgHfvxqECA",
                      "type": "heading",
                      "values": {
                        "containerPadding": "10px",
                        "anchor": "",
                        "headingType": "h1",
                        "fontSize": "22px",
                        "textAlign": "left",
                        "lineHeight": "140%",
                        "linkStyle": {
                          "inherit": true,
                          "linkColor": "#0000ee",
                          "linkHoverColor": "#0000ee",
                          "linkUnderline": true,
                          "linkHoverUnderline": true
                        },
                        "displayCondition": null,
                        "_meta": {
                          "htmlID": "u_content_heading_1",
                          "htmlClassNames": "u_content_heading"
                        },
                        "selectable": true,
                        "draggable": true,
                        "duplicatable": true,
                        "deletable": true,
                        "hideable": true,
                        "text": "{{bib}}{{full_name}}{{qrcode}}Heading"
                      }
                    },
                    {
                      "id": "1g0XgZpfmR",
                      "type": "image",
                      "values": {
                        "containerPadding": "10px",
                        "anchor": "",
                        "src": {
                          "url": "https://assets.unlayer.com/stock-templates1686648151957-ticket.png",
                          "width": 828,
                          "height": 376
                        },
                        "textAlign": "center",
                        "altText": "",
                        "action": {
                          "name": "web",
                          "values": {
                            "href": "",
                            "target": "_blank"
                          }
                        },
                        "displayCondition": null,
                        "_meta": {
                          "htmlID": "u_content_image_1",
                          "htmlClassNames": "u_content_image"
                        },
                        "selectable": true,
                        "draggable": true,
                        "duplicatable": true,
                        "deletable": true,
                        "hideable": true
                      }
                    }
                  ],
                  "values": {
                    "backgroundColor": "",
                    "padding": "0px",
                    "border": {},
                    "_meta": {
                      "htmlID": "u_column_1",
                      "htmlClassNames": "u_column"
                    }
                  }
                }
              ],
              "values": {
                "displayCondition": null,
                "columns": false,
                "backgroundColor": "",
                "columnsBackgroundColor": "",
                "backgroundImage": {
                  "url": "",
                  "fullWidth": true,
                  "repeat": "no-repeat",
                  "size": "custom",
                  "position": "center"
                },
                "padding": "0px",
                "anchor": "",
                "hideDesktop": false,
                "_meta": {
                  "htmlID": "u_row_1",
                  "htmlClassNames": "u_row"
                },
                "selectable": true,
                "draggable": true,
                "duplicatable": true,
                "deletable": true,
                "hideable": true
              }
            }
          ],
          "values": {
            "popupPosition": "center",
            "popupWidth": "600px",
            "popupHeight": "auto",
            "borderRadius": "10px",
            "contentAlign": "center",
            "contentVerticalAlign": "center",
            "contentWidth": "500px",
            "fontFamily": {
              "label": "Arial",
              "value": "arial,helvetica,sans-serif"
            },
            "textColor": "#000000",
            "popupBackgroundColor": "#FFFFFF",
            "popupBackgroundImage": {
              "url": "",
              "fullWidth": true,
              "repeat": "no-repeat",
              "size": "cover",
              "position": "center"
            },
            "popupOverlay_backgroundColor": "rgba(0, 0, 0, 0.1)",
            "popupCloseButton_position": "top-right",
            "popupCloseButton_backgroundColor": "#DDDDDD",
            "popupCloseButton_iconColor": "#000000",
            "popupCloseButton_borderRadius": "0px",
            "popupCloseButton_margin": "0px",
            "popupCloseButton_action": {
              "name": "close_popup",
              "attrs": {
                "onClick": "document.querySelector('.u-popup-container').style.display = 'none';"
              }
            },
            "backgroundColor": "#e7e7e7",
            "backgroundImage": {
              "url": "",
              "fullWidth": true,
              "repeat": "no-repeat",
              "size": "custom",
              "position": "center"
            },
            "preheaderText": "",
            "linkStyle": {
              "body": true,
              "linkColor": "#0000ee",
              "linkHoverColor": "#0000ee",
              "linkUnderline": true,
              "linkHoverUnderline": true
            },
            "_meta": {
              "htmlID": "u_body",
              "htmlClassNames": "u_body"
            }
          }
        },
        "schemaVersion": 15
      } : route.params.id === 'default'  ? {
        "counters": {
          "u_column": 16,
          "u_row": 14,
          "u_content_heading": 2,
          "u_content_image": 9,
          "u_content_text": 21,
          "u_content_divider": 13
        },
        "body": {
          "id": "FwcNn9-HWM",
          "rows": [
            {
              "id": "ZNsDNTX2a0",
              "cells": [
                1
              ],
              "columns": [
                {
                  "id": "q4xGlu3Kuk",
                  "contents": [
                    {
                      "id": "8sHJs2SvMB",
                      "type": "image",
                      "values": {
                        "containerPadding": "10px",
                        "anchor": "",
                        "src": {
                          "url": "https://assets.unlayer.com/stock-templates1686734728666-download.png",
                          "width": 294,
                          "height": 102,
                          "autoWidth": false,
                          "maxWidth": "30%"
                        },
                        "textAlign": "center",
                        "altText": "",
                        "action": {
                          "name": "web",
                          "values": {
                            "href": "https://biglead.live/",
                            "target": "_blank"
                          },
                          "attrs": {
                            "href": "{{href}}",
                            "target": "{{target}}"
                          }
                        },
                        "hideDesktop": false,
                        "displayCondition": null,
                        "_meta": {
                          "htmlID": "u_content_image_4",
                          "htmlClassNames": "u_content_image"
                        },
                        "selectable": true,
                        "draggable": true,
                        "duplicatable": true,
                        "deletable": true,
                        "hideable": true
                      }
                    }
                  ],
                  "values": {
                    "backgroundColor": "#1653fa",
                    "padding": "0px",
                    "border": {},
                    "_meta": {
                      "htmlID": "u_column_1",
                      "htmlClassNames": "u_column"
                    }
                  }
                }
              ],
              "values": {
                "displayCondition": null,
                "columns": false,
                "backgroundColor": "",
                "columnsBackgroundColor": "",
                "backgroundImage": {
                  "url": "",
                  "fullWidth": true,
                  "repeat": "no-repeat",
                  "size": "custom",
                  "position": "center"
                },
                "padding": "0px",
                "anchor": "",
                "hideDesktop": false,
                "_meta": {
                  "htmlID": "u_row_1",
                  "htmlClassNames": "u_row"
                },
                "selectable": true,
                "draggable": true,
                "duplicatable": true,
                "deletable": true,
                "hideable": true
              }
            },
            {
              "id": "ce4mO7qLUQ",
              "cells": [
                1
              ],
              "columns": [
                {
                  "id": "37aqDwZqJr",
                  "contents": [
                    {
                      "id": "cRSZKFqulC",
                      "type": "divider",
                      "values": {
                        "width": "100%",
                        "border": {
                          "borderTopWidth": "1px",
                          "borderTopStyle": "dashed",
                          "borderTopColor": "#BBBBBB"
                        },
                        "textAlign": "center",
                        "containerPadding": "10px",
                        "anchor": "",
                        "hideDesktop": false,
                        "displayCondition": null,
                        "_meta": {
                          "htmlID": "u_content_divider_1",
                          "htmlClassNames": "u_content_divider"
                        },
                        "selectable": true,
                        "draggable": true,
                        "duplicatable": true,
                        "deletable": true,
                        "hideable": true
                      }
                    },
                    {
                      "id": "0ipFrUo0YC",
                      "type": "heading",
                      "values": {
                        "containerPadding": "10px",
                        "anchor": "",
                        "headingType": "h4",
                        "fontSize": "13px",
                        "color": "#575454",
                        "textAlign": "left",
                        "lineHeight": "140%",
                        "linkStyle": {
                          "inherit": true,
                          "linkColor": "#0000ee",
                          "linkHoverColor": "#0000ee",
                          "linkUnderline": true,
                          "linkHoverUnderline": true
                        },
                        "hideDesktop": false,
                        "displayCondition": null,
                        "_meta": {
                          "htmlID": "u_content_heading_2",
                          "htmlClassNames": "u_content_heading"
                        },
                        "selectable": true,
                        "draggable": true,
                        "duplicatable": true,
                        "deletable": true,
                        "hideable": true,
                        "text": "Chúc mừng bạn đã đặt vé trực tuyến thành công cho sự kiện SHOW CỦA ĐEN - HÀ NỘI"
                      }
                    },
                    {
                      "id": "lLQWd9lBmX",
                      "type": "image",
                      "values": {
                        "containerPadding": "10px",
                        "anchor": "",
                        "src": {
                          "url": "https://assets.unlayer.com/stock-templates1686734785613-unnamed.jpg",
                          "width": 1440,
                          "height": 600
                        },
                        "textAlign": "center",
                        "altText": "",
                        "action": {
                          "name": "web",
                          "values": {
                            "href": "",
                            "target": "_blank"
                          }
                        },
                        "hideDesktop": false,
                        "displayCondition": null,
                        "_meta": {
                          "htmlID": "u_content_image_3",
                          "htmlClassNames": "u_content_image"
                        },
                        "selectable": true,
                        "draggable": true,
                        "duplicatable": true,
                        "deletable": true,
                        "hideable": true
                      }
                    },
                    {
                      "id": "2XTBCr5qsR",
                      "type": "divider",
                      "values": {
                        "width": "100%",
                        "border": {
                          "borderTopWidth": "1px",
                          "borderTopStyle": "dashed",
                          "borderTopColor": "#BBBBBB"
                        },
                        "textAlign": "center",
                        "containerPadding": "10px",
                        "anchor": "",
                        "hideDesktop": false,
                        "displayCondition": null,
                        "_meta": {
                          "htmlID": "u_content_divider_3",
                          "htmlClassNames": "u_content_divider"
                        },
                        "selectable": true,
                        "draggable": true,
                        "duplicatable": true,
                        "deletable": true,
                        "hideable": true
                      }
                    },
                    {
                      "id": "qHePdOZI4j",
                      "type": "text",
                      "values": {
                        "containerPadding": "10px",
                        "anchor": "",
                        "fontSize": "14px",
                        "textAlign": "center",
                        "lineHeight": "140%",
                        "linkStyle": {
                          "inherit": true,
                          "linkColor": "#0000ee",
                          "linkHoverColor": "#0000ee",
                          "linkUnderline": true,
                          "linkHoverUnderline": true
                        },
                        "hideDesktop": false,
                        "displayCondition": null,
                        "_meta": {
                          "htmlID": "u_content_text_1",
                          "htmlClassNames": "u_content_text"
                        },
                        "selectable": true,
                        "draggable": true,
                        "duplicatable": true,
                        "deletable": true,
                        "hideable": true,
                        "text": "<div>Thông tin người tham gia</div>\n<div> </div>"
                      }
                    }
                  ],
                  "values": {
                    "backgroundColor": "#eff5ff",
                    "padding": "0px",
                    "border": {},
                    "borderRadius": "0px",
                    "_meta": {
                      "htmlID": "u_column_2",
                      "htmlClassNames": "u_column"
                    }
                  }
                }
              ],
              "values": {
                "displayCondition": null,
                "columns": false,
                "backgroundColor": "",
                "columnsBackgroundColor": "",
                "backgroundImage": {
                  "url": "",
                  "fullWidth": true,
                  "repeat": "no-repeat",
                  "size": "custom",
                  "position": "center"
                },
                "padding": "0px",
                "anchor": "",
                "hideDesktop": false,
                "_meta": {
                  "htmlID": "u_row_2",
                  "htmlClassNames": "u_row"
                },
                "selectable": true,
                "draggable": true,
                "duplicatable": true,
                "deletable": true,
                "hideable": true
              }
            },
            {
              "id": "w34OSPETli",
              "cells": [
                1,
                1
              ],
              "columns": [
                {
                  "id": "aVf9y9I8qi",
                  "contents": [
                    {
                      "id": "qtiSQi_Vhb",
                      "type": "text",
                      "values": {
                        "containerPadding": "10px",
                        "anchor": "",
                        "fontSize": "12px",
                        "textAlign": "left",
                        "lineHeight": "140%",
                        "linkStyle": {
                          "inherit": true,
                          "linkColor": "#0000ee",
                          "linkHoverColor": "#0000ee",
                          "linkUnderline": true,
                          "linkHoverUnderline": true
                        },
                        "hideDesktop": false,
                        "displayCondition": null,
                        "_meta": {
                          "htmlID": "u_content_text_3",
                          "htmlClassNames": "u_content_text"
                        },
                        "selectable": true,
                        "draggable": true,
                        "duplicatable": true,
                        "deletable": true,
                        "hideable": true,
                        "text": "<p style=\"line-height: 140%;\"><strong>Vui lòng dùng mã QR này để vào cổng</strong></p>\n<p style=\"line-height: 140%;\"> </p>\n<p style=\"line-height: 140%;\"> </p>"
                      }
                    },
                    {
                      "id": "ys-U7UEljf",
                      "type": "text",
                      "values": {
                        "containerPadding": "10px",
                        "anchor": "",
                        "fontSize": "13px",
                        "textAlign": "left",
                        "lineHeight": "140%",
                        "linkStyle": {
                          "inherit": true,
                          "linkColor": "#0000ee",
                          "linkHoverColor": "#0000ee",
                          "linkUnderline": true,
                          "linkHoverUnderline": true
                        },
                        "hideDesktop": false,
                        "displayCondition": null,
                        "_meta": {
                          "htmlID": "u_content_text_6",
                          "htmlClassNames": "u_content_text"
                        },
                        "selectable": true,
                        "draggable": true,
                        "duplicatable": true,
                        "deletable": true,
                        "hideable": true,
                        "text": "<p style=\"line-height: 140%;\">Khách hàng có trách nhiệm bảo mật tuyệt đối mã vé điện tử của mình.<br />Mỗi mã QR tương ứng với một lần check-in vào cổng.</p>"
                      }
                    },
                    {
                      "id": "CqopLs552n",
                      "type": "text",
                      "values": {
                        "containerPadding": "10px",
                        "anchor": "",
                        "fontSize": "13px",
                        "textAlign": "left",
                        "lineHeight": "140%",
                        "linkStyle": {
                          "inherit": true,
                          "linkColor": "#0000ee",
                          "linkHoverColor": "#0000ee",
                          "linkUnderline": true,
                          "linkHoverUnderline": true
                        },
                        "hideDesktop": false,
                        "displayCondition": null,
                        "_meta": {
                          "htmlID": "u_content_text_7",
                          "htmlClassNames": "u_content_text"
                        },
                        "selectable": true,
                        "draggable": true,
                        "duplicatable": true,
                        "deletable": true,
                        "hideable": true,
                        "text": "<p style=\"line-height: 140%;\">Chụp màn hình để dùng mã QR thuận tiện hơn.<br />Nhớ lưu trữ cẩn thận bạn nhé!</p>"
                      }
                    }
                  ],
                  "values": {
                    "backgroundColor": "#eff5ff",
                    "padding": "0px",
                    "border": {
                      "borderTopWidth": "0px",
                      "borderTopStyle": "solid",
                      "borderTopColor": "#CCC",
                      "borderLeftWidth": "0px",
                      "borderLeftStyle": "solid",
                      "borderLeftColor": "#CCC",
                      "borderRightWidth": "2px",
                      "borderRightStyle": "dashed",
                      "borderRightColor": "#CCC",
                      "borderBottomWidth": "0px",
                      "borderBottomStyle": "solid",
                      "borderBottomColor": "#CCC"
                    },
                    "borderRadius": "0px",
                    "_meta": {
                      "htmlID": "u_column_3",
                      "htmlClassNames": "u_column"
                    }
                  }
                },
                {
                  "id": "kazois841D",
                  "contents": [
                    {
                      "id": "j5sef8djCj",
                      "type": "text",
                      "values": {
                        "containerPadding": "10px",
                        "anchor": "",
                        "fontSize": "14px",
                        "textAlign": "left",
                        "lineHeight": "140%",
                        "linkStyle": {
                          "body": false,
                          "inherit": true,
                          "linkColor": "#0000ee",
                          "linkUnderline": true,
                          "linkHoverColor": "#0000ee",
                          "linkHoverUnderline": true
                        },
                        "hideDesktop": false,
                        "displayCondition": null,
                        "_meta": {
                          "htmlID": "u_content_text_5",
                          "htmlClassNames": "u_content_text"
                        },
                        "selectable": true,
                        "draggable": true,
                        "duplicatable": true,
                        "deletable": true,
                        "hideable": true,
                        "text": "<p style=\"line-height: 140%;\"><img style=\"height: 100%; width: 100%;\" src=\"https://quickchart.io/qr?text={{qrcode}}&amp;size=300\" /></p>"
                      }
                    }
                  ],
                  "values": {
                    "backgroundColor": "#eff5ff",
                    "padding": "0px",
                    "border": {},
                    "borderRadius": "0px",
                    "_meta": {
                      "htmlID": "u_column_4",
                      "htmlClassNames": "u_column"
                    }
                  }
                }
              ],
              "values": {
                "displayCondition": null,
                "columns": false,
                "backgroundColor": "",
                "columnsBackgroundColor": "",
                "backgroundImage": {
                  "url": "",
                  "fullWidth": true,
                  "repeat": "no-repeat",
                  "size": "custom",
                  "position": "center"
                },
                "padding": "0px",
                "anchor": "",
                "hideDesktop": false,
                "_meta": {
                  "htmlID": "u_row_3",
                  "htmlClassNames": "u_row"
                },
                "selectable": true,
                "draggable": true,
                "duplicatable": true,
                "deletable": true,
                "hideable": true
              }
            },
            {
              "id": "fDZzvX63Tv",
              "cells": [
                1,
                1
              ],
              "columns": [
                {
                  "id": "Gr2bAO9OSk",
                  "contents": [
                    {
                      "id": "UUG8EwmlQJ",
                      "type": "text",
                      "values": {
                        "containerPadding": "10px",
                        "anchor": "",
                        "fontSize": "14px",
                        "textAlign": "left",
                        "lineHeight": "140%",
                        "linkStyle": {
                          "inherit": true,
                          "linkColor": "#0000ee",
                          "linkHoverColor": "#0000ee",
                          "linkUnderline": true,
                          "linkHoverUnderline": true
                        },
                        "hideDesktop": false,
                        "displayCondition": null,
                        "_meta": {
                          "htmlID": "u_content_text_8",
                          "htmlClassNames": "u_content_text"
                        },
                        "selectable": true,
                        "draggable": true,
                        "duplicatable": true,
                        "deletable": true,
                        "hideable": true,
                        "text": "<p style=\"line-height: 140%;\">Họ Tên : {{full_name}}</p>",
                        "_override": {
                          "desktop": {
                            "hideDesktop": false
                          }
                        }
                      }
                    },
                    {
                      "id": "svJVR9kSYu",
                      "type": "text",
                      "values": {
                        "containerPadding": "10px",
                        "anchor": "",
                        "fontSize": "14px",
                        "textAlign": "left",
                        "lineHeight": "140%",
                        "linkStyle": {
                          "inherit": true,
                          "linkColor": "#0000ee",
                          "linkHoverColor": "#0000ee",
                          "linkUnderline": true,
                          "linkHoverUnderline": true
                        },
                        "hideDesktop": false,
                        "displayCondition": null,
                        "_meta": {
                          "htmlID": "u_content_text_9",
                          "htmlClassNames": "u_content_text"
                        },
                        "selectable": true,
                        "draggable": true,
                        "duplicatable": true,
                        "deletable": true,
                        "hideable": true,
                        "text": "<p style=\"line-height: 140%;\">BIB: {{bib}}</p>"
                      }
                    }
                  ],
                  "values": {
                    "backgroundColor": "#eff5ff",
                    "padding": "0px",
                    "border": {},
                    "borderRadius": "0px",
                    "_meta": {
                      "htmlID": "u_column_5",
                      "htmlClassNames": "u_column"
                    }
                  }
                },
                {
                  "id": "9RIG8Zxcsp",
                  "contents": [
                    {
                      "id": "ro39EBn9VA",
                      "type": "text",
                      "values": {
                        "containerPadding": "10px",
                        "anchor": "",
                        "fontSize": "14px",
                        "textAlign": "left",
                        "lineHeight": "140%",
                        "linkStyle": {
                          "inherit": true,
                          "linkColor": "#0000ee",
                          "linkHoverColor": "#0000ee",
                          "linkUnderline": true,
                          "linkHoverUnderline": true
                        },
                        "hideDesktop": false,
                        "displayCondition": null,
                        "_meta": {
                          "htmlID": "u_content_text_10",
                          "htmlClassNames": "u_content_text"
                        },
                        "selectable": true,
                        "draggable": true,
                        "duplicatable": true,
                        "deletable": true,
                        "hideable": true,
                        "text": "<p style=\"line-height: 140%;\">Email: {{email}}</p>"
                      }
                    }
                  ],
                  "values": {
                    "backgroundColor": "#eff5ff",
                    "padding": "0px",
                    "border": {},
                    "borderRadius": "0px",
                    "_meta": {
                      "htmlID": "u_column_6",
                      "htmlClassNames": "u_column"
                    }
                  }
                }
              ],
              "values": {
                "displayCondition": null,
                "columns": false,
                "backgroundColor": "",
                "columnsBackgroundColor": "",
                "backgroundImage": {
                  "url": "",
                  "fullWidth": true,
                  "repeat": "no-repeat",
                  "size": "custom",
                  "position": "center"
                },
                "padding": "0px",
                "anchor": "",
                "hideDesktop": false,
                "_meta": {
                  "htmlID": "u_row_4",
                  "htmlClassNames": "u_row"
                },
                "selectable": true,
                "draggable": true,
                "duplicatable": true,
                "deletable": true,
                "hideable": true
              }
            },
            {
              "id": "0ms3ZjWvsc",
              "cells": [
                1
              ],
              "columns": [
                {
                  "id": "HQjOkByFD9",
                  "contents": [
                    {
                      "id": "hjewnBF5Oa",
                      "type": "text",
                      "values": {
                        "containerPadding": "10px",
                        "anchor": "",
                        "fontSize": "14px",
                        "textAlign": "right",
                        "lineHeight": "140%",
                        "linkStyle": {
                          "inherit": true,
                          "linkColor": "#0000ee",
                          "linkHoverColor": "#0000ee",
                          "linkUnderline": true,
                          "linkHoverUnderline": true
                        },
                        "hideDesktop": false,
                        "displayCondition": null,
                        "_meta": {
                          "htmlID": "u_content_text_11",
                          "htmlClassNames": "u_content_text"
                        },
                        "selectable": true,
                        "draggable": true,
                        "duplicatable": true,
                        "deletable": true,
                        "hideable": true,
                        "text": "<p style=\"line-height: 140%;\">Hân hạnh được phục vụ quý khách  😄😄😄</p>"
                      }
                    }
                  ],
                  "values": {
                    "backgroundColor": "#eff5ff",
                    "padding": "0px",
                    "border": {},
                    "borderRadius": "0px",
                    "_meta": {
                      "htmlID": "u_column_7",
                      "htmlClassNames": "u_column"
                    }
                  }
                }
              ],
              "values": {
                "displayCondition": null,
                "columns": false,
                "backgroundColor": "",
                "columnsBackgroundColor": "",
                "backgroundImage": {
                  "url": "",
                  "fullWidth": true,
                  "repeat": "no-repeat",
                  "size": "custom",
                  "position": "center"
                },
                "padding": "0px",
                "anchor": "",
                "hideDesktop": false,
                "_meta": {
                  "htmlID": "u_row_5",
                  "htmlClassNames": "u_row"
                },
                "selectable": true,
                "draggable": true,
                "duplicatable": true,
                "deletable": true,
                "hideable": true
              }
            },
            {
              "id": "YZuOpbskqz",
              "cells": [
                1
              ],
              "columns": [
                {
                  "id": "_xFilmiTOj",
                  "contents": [
                    {
                      "id": "NKc766hAtG",
                      "type": "divider",
                      "values": {
                        "width": "100%",
                        "border": {
                          "borderTopWidth": "1px",
                          "borderTopStyle": "dashed",
                          "borderTopColor": "#BBBBBB"
                        },
                        "textAlign": "center",
                        "containerPadding": "10px",
                        "anchor": "",
                        "hideDesktop": false,
                        "displayCondition": null,
                        "_meta": {
                          "htmlID": "u_content_divider_6",
                          "htmlClassNames": "u_content_divider"
                        },
                        "selectable": true,
                        "draggable": true,
                        "duplicatable": true,
                        "deletable": true,
                        "hideable": true
                      }
                    },
                    {
                      "id": "DegXVMtY3S",
                      "type": "text",
                      "values": {
                        "containerPadding": "10px",
                        "anchor": "",
                        "fontSize": "14px",
                        "textAlign": "center",
                        "lineHeight": "140%",
                        "linkStyle": {
                          "inherit": true,
                          "linkColor": "#0000ee",
                          "linkHoverColor": "#0000ee",
                          "linkUnderline": true,
                          "linkHoverUnderline": true
                        },
                        "hideDesktop": false,
                        "displayCondition": null,
                        "_meta": {
                          "htmlID": "u_content_text_13",
                          "htmlClassNames": "u_content_text"
                        },
                        "selectable": true,
                        "draggable": true,
                        "duplicatable": true,
                        "deletable": true,
                        "hideable": true,
                        "text": "<p style=\"line-height: 140%;\">LOẠI VÉ</p>"
                      }
                    },
                    {
                      "id": "lviVFZGJyg",
                      "type": "image",
                      "values": {
                        "containerPadding": "10px",
                        "anchor": "",
                        "src": {
                          "url": "https://assets.unlayer.com/stock-templates1686734802258-unnamed%20(1).jpg",
                          "width": 1200,
                          "height": 700
                        },
                        "textAlign": "center",
                        "altText": "",
                        "action": {
                          "name": "web",
                          "values": {
                            "href": "",
                            "target": "_blank"
                          }
                        },
                        "hideDesktop": false,
                        "displayCondition": null,
                        "_meta": {
                          "htmlID": "u_content_image_7",
                          "htmlClassNames": "u_content_image"
                        },
                        "selectable": true,
                        "draggable": true,
                        "duplicatable": true,
                        "deletable": true,
                        "hideable": true
                      }
                    }
                  ],
                  "values": {
                    "backgroundColor": "#eff5ff",
                    "padding": "0px",
                    "border": {},
                    "borderRadius": "0px",
                    "_meta": {
                      "htmlID": "u_column_8",
                      "htmlClassNames": "u_column"
                    }
                  }
                }
              ],
              "values": {
                "displayCondition": null,
                "columns": false,
                "backgroundColor": "",
                "columnsBackgroundColor": "",
                "backgroundImage": {
                  "url": "",
                  "fullWidth": true,
                  "repeat": "no-repeat",
                  "size": "custom",
                  "position": "center"
                },
                "padding": "0px",
                "anchor": "",
                "hideDesktop": false,
                "_meta": {
                  "htmlID": "u_row_6",
                  "htmlClassNames": "u_row"
                },
                "selectable": true,
                "draggable": true,
                "duplicatable": true,
                "deletable": true,
                "hideable": true
              }
            },
            {
              "id": "JQ8TySZGqN",
              "cells": [
                1
              ],
              "columns": [
                {
                  "id": "ql_GWVQmar",
                  "contents": [
                    {
                      "id": "ekFEWxUdZT",
                      "type": "divider",
                      "values": {
                        "width": "100%",
                        "border": {
                          "borderTopWidth": "1px",
                          "borderTopStyle": "dashed",
                          "borderTopColor": "#BBBBBB"
                        },
                        "textAlign": "center",
                        "containerPadding": "10px",
                        "anchor": "",
                        "hideDesktop": false,
                        "displayCondition": null,
                        "_meta": {
                          "htmlID": "u_content_divider_7",
                          "htmlClassNames": "u_content_divider"
                        },
                        "selectable": true,
                        "draggable": true,
                        "duplicatable": true,
                        "deletable": true,
                        "hideable": true
                      }
                    },
                    {
                      "id": "uEWeSGDKgD",
                      "type": "text",
                      "values": {
                        "containerPadding": "10px",
                        "anchor": "",
                        "fontSize": "14px",
                        "textAlign": "center",
                        "lineHeight": "140%",
                        "linkStyle": {
                          "inherit": true,
                          "linkColor": "#0000ee",
                          "linkHoverColor": "#0000ee",
                          "linkUnderline": true,
                          "linkHoverUnderline": true
                        },
                        "hideDesktop": false,
                        "displayCondition": null,
                        "_meta": {
                          "htmlID": "u_content_text_15",
                          "htmlClassNames": "u_content_text"
                        },
                        "selectable": true,
                        "draggable": true,
                        "duplicatable": true,
                        "deletable": true,
                        "hideable": true,
                        "text": "<p style=\"line-height: 140%;\">SƠ ĐỒ KHU VỰC</p>"
                      }
                    },
                    {
                      "id": "KC36II2Zy2",
                      "type": "image",
                      "values": {
                        "containerPadding": "10px",
                        "anchor": "",
                        "src": {
                          "url": "https://assets.unlayer.com/stock-templates1686734814448-unnamed%20(2).jpg",
                          "width": 1200,
                          "height": 1074
                        },
                        "textAlign": "center",
                        "altText": "",
                        "action": {
                          "name": "web",
                          "values": {
                            "href": "",
                            "target": "_blank"
                          }
                        },
                        "hideDesktop": false,
                        "displayCondition": null,
                        "_meta": {
                          "htmlID": "u_content_image_9",
                          "htmlClassNames": "u_content_image"
                        },
                        "selectable": true,
                        "draggable": true,
                        "duplicatable": true,
                        "deletable": true,
                        "hideable": true
                      }
                    },
                    {
                      "id": "SBd-KYXxJw",
                      "type": "divider",
                      "values": {
                        "width": "100%",
                        "border": {
                          "borderTopWidth": "1px",
                          "borderTopStyle": "dashed",
                          "borderTopColor": "#BBBBBB"
                        },
                        "textAlign": "center",
                        "containerPadding": "10px",
                        "anchor": "",
                        "hideDesktop": false,
                        "displayCondition": null,
                        "_meta": {
                          "htmlID": "u_content_divider_8",
                          "htmlClassNames": "u_content_divider"
                        },
                        "selectable": true,
                        "draggable": true,
                        "duplicatable": true,
                        "deletable": true,
                        "hideable": true
                      }
                    }
                  ],
                  "values": {
                    "backgroundColor": "#eff5ff",
                    "padding": "0px",
                    "border": {},
                    "borderRadius": "0px",
                    "_meta": {
                      "htmlID": "u_column_12",
                      "htmlClassNames": "u_column"
                    }
                  }
                }
              ],
              "values": {
                "displayCondition": null,
                "columns": false,
                "backgroundColor": "",
                "columnsBackgroundColor": "",
                "backgroundImage": {
                  "url": "",
                  "fullWidth": true,
                  "repeat": "no-repeat",
                  "size": "custom",
                  "position": "center"
                },
                "padding": "0px",
                "anchor": "",
                "hideDesktop": false,
                "_meta": {
                  "htmlID": "u_row_10",
                  "htmlClassNames": "u_row"
                },
                "selectable": true,
                "draggable": true,
                "duplicatable": true,
                "deletable": true,
                "hideable": true
              }
            },
            {
              "id": "8f4GEchoXb",
              "cells": [
                1
              ],
              "columns": [
                {
                  "id": "bVoGH2AA-5",
                  "contents": [
                    {
                      "id": "tQbrAWmBhB",
                      "type": "text",
                      "values": {
                        "containerPadding": "10px",
                        "anchor": "",
                        "fontSize": "14px",
                        "textAlign": "center",
                        "lineHeight": "140%",
                        "linkStyle": {
                          "inherit": true,
                          "linkColor": "#0000ee",
                          "linkHoverColor": "#0000ee",
                          "linkUnderline": true,
                          "linkHoverUnderline": true
                        },
                        "hideDesktop": false,
                        "displayCondition": null,
                        "_meta": {
                          "htmlID": "u_content_text_14",
                          "htmlClassNames": "u_content_text"
                        },
                        "selectable": true,
                        "draggable": true,
                        "duplicatable": true,
                        "deletable": true,
                        "hideable": true,
                        "text": "<p style=\"line-height: 140%;\">QUY ĐỊNH</p>"
                      }
                    },
                    {
                      "id": "zoYuTuxqgq",
                      "type": "image",
                      "values": {
                        "containerPadding": "10px",
                        "anchor": "",
                        "src": {
                          "url": "https://assets.unlayer.com/stock-templates1686734827464-unnamed%20(3).jpg",
                          "width": 1200,
                          "height": 2200
                        },
                        "textAlign": "center",
                        "altText": "",
                        "action": {
                          "name": "web",
                          "values": {
                            "href": "",
                            "target": "_blank"
                          }
                        },
                        "hideDesktop": false,
                        "displayCondition": null,
                        "_meta": {
                          "htmlID": "u_content_image_8",
                          "htmlClassNames": "u_content_image"
                        },
                        "selectable": true,
                        "draggable": true,
                        "duplicatable": true,
                        "deletable": true,
                        "hideable": true
                      }
                    }
                  ],
                  "values": {
                    "backgroundColor": "#eff5ff",
                    "padding": "0px",
                    "border": {},
                    "borderRadius": "0px",
                    "_meta": {
                      "htmlID": "u_column_11",
                      "htmlClassNames": "u_column"
                    }
                  }
                }
              ],
              "values": {
                "displayCondition": null,
                "columns": false,
                "backgroundColor": "",
                "columnsBackgroundColor": "",
                "backgroundImage": {
                  "url": "",
                  "fullWidth": true,
                  "repeat": "no-repeat",
                  "size": "custom",
                  "position": "center"
                },
                "padding": "0px",
                "anchor": "",
                "hideDesktop": false,
                "_meta": {
                  "htmlID": "u_row_9",
                  "htmlClassNames": "u_row"
                },
                "selectable": true,
                "draggable": true,
                "duplicatable": true,
                "deletable": true,
                "hideable": true
              }
            },
            {
              "id": "5fRqq24ad7",
              "cells": [
                1
              ],
              "columns": [
                {
                  "id": "nX2shCQhM5",
                  "contents": [
                    {
                      "id": "gWCEAtoTFd",
                      "type": "divider",
                      "values": {
                        "width": "100%",
                        "border": {
                          "borderTopWidth": "1px",
                          "borderTopStyle": "dashed",
                          "borderTopColor": "#BBBBBB"
                        },
                        "textAlign": "center",
                        "containerPadding": "10px",
                        "anchor": "",
                        "hideDesktop": false,
                        "displayCondition": null,
                        "_meta": {
                          "htmlID": "u_content_divider_9",
                          "htmlClassNames": "u_content_divider"
                        },
                        "selectable": true,
                        "draggable": true,
                        "duplicatable": true,
                        "deletable": true,
                        "hideable": true
                      }
                    },
                    {
                      "id": "BVl-M09VBR",
                      "type": "text",
                      "values": {
                        "containerPadding": "10px",
                        "anchor": "",
                        "fontSize": "14px",
                        "textAlign": "left",
                        "lineHeight": "140%",
                        "linkStyle": {
                          "inherit": true,
                          "linkColor": "#0000ee",
                          "linkHoverColor": "#0000ee",
                          "linkUnderline": true,
                          "linkHoverUnderline": true
                        },
                        "hideDesktop": false,
                        "displayCondition": null,
                        "_meta": {
                          "htmlID": "u_content_text_16",
                          "htmlClassNames": "u_content_text"
                        },
                        "selectable": true,
                        "draggable": true,
                        "duplicatable": true,
                        "deletable": true,
                        "hideable": true,
                        "text": "<ul>\n<li style=\"line-height: 19.6px;\">Mã QR chỉ được gửi duy nhất cho tài khoản đặt vé, mỗi chủ tài khoản sẽ có trách nhiệm quản lí và bảo mật mã QR của mình. Nếu có bất kì vấn đề gì, Ban Tổ Chức không chịu trách nhiệm.</li>\n</ul>"
                      }
                    },
                    {
                      "id": "ioUqkfNnoW",
                      "type": "divider",
                      "values": {
                        "width": "100%",
                        "border": {
                          "borderTopWidth": "1px",
                          "borderTopStyle": "dashed",
                          "borderTopColor": "#BBBBBB"
                        },
                        "textAlign": "center",
                        "containerPadding": "10px",
                        "anchor": "",
                        "hideDesktop": false,
                        "displayCondition": null,
                        "_meta": {
                          "htmlID": "u_content_divider_10",
                          "htmlClassNames": "u_content_divider"
                        },
                        "selectable": true,
                        "draggable": true,
                        "duplicatable": true,
                        "deletable": true,
                        "hideable": true
                      }
                    },
                    {
                      "id": "owLuhKsdTV",
                      "type": "text",
                      "values": {
                        "containerPadding": "10px",
                        "anchor": "",
                        "fontSize": "14px",
                        "textAlign": "left",
                        "lineHeight": "190%",
                        "linkStyle": {
                          "inherit": true,
                          "linkColor": "#0000ee",
                          "linkHoverColor": "#0000ee",
                          "linkUnderline": true,
                          "linkHoverUnderline": true
                        },
                        "hideDesktop": false,
                        "displayCondition": null,
                        "_meta": {
                          "htmlID": "u_content_text_17",
                          "htmlClassNames": "u_content_text"
                        },
                        "selectable": true,
                        "draggable": true,
                        "duplicatable": true,
                        "deletable": true,
                        "hideable": true,
                        "text": "<p style=\"line-height: 190%;\"><strong>Chúng tôi sẵn sàng hỗ trợ bạn qua / We are ready to assist you through</strong></p>\n<p style=\"line-height: 190%;\"><strong>Email: <a rel=\"noopener\" href=\"mailto:contact@shoptool.vn\" target=\"_blank\">contact@shoptool.vn</a></strong></p>\n<p style=\"line-height: 190%;\"><strong>Hotline: (+84) 939 55 6336</strong></p>"
                      }
                    },
                    {
                      "id": "l70Is3mcRB",
                      "type": "divider",
                      "values": {
                        "width": "100%",
                        "border": {
                          "borderTopWidth": "1px",
                          "borderTopStyle": "dashed",
                          "borderTopColor": "#BBBBBB"
                        },
                        "textAlign": "center",
                        "containerPadding": "10px",
                        "anchor": "",
                        "hideDesktop": false,
                        "displayCondition": null,
                        "_meta": {
                          "htmlID": "u_content_divider_11",
                          "htmlClassNames": "u_content_divider"
                        },
                        "selectable": true,
                        "draggable": true,
                        "duplicatable": true,
                        "deletable": true,
                        "hideable": true
                      }
                    }
                  ],
                  "values": {
                    "backgroundColor": "#eff5ff",
                    "padding": "0px",
                    "border": {},
                    "borderRadius": "0px",
                    "_meta": {
                      "htmlID": "u_column_13",
                      "htmlClassNames": "u_column"
                    }
                  }
                }
              ],
              "values": {
                "displayCondition": null,
                "columns": false,
                "backgroundColor": "",
                "columnsBackgroundColor": "",
                "backgroundImage": {
                  "url": "",
                  "fullWidth": true,
                  "repeat": "no-repeat",
                  "size": "custom",
                  "position": "center"
                },
                "padding": "0px",
                "anchor": "",
                "hideDesktop": false,
                "_meta": {
                  "htmlID": "u_row_11",
                  "htmlClassNames": "u_row"
                },
                "selectable": true,
                "draggable": true,
                "duplicatable": true,
                "deletable": true,
                "hideable": true
              }
            },
            {
              "id": "Q3DKeTkJLg",
              "cells": [
                1
              ],
              "columns": [
                {
                  "id": "Uuhad9EwGT",
                  "contents": [
                    {
                      "id": "R3ZEytC6g6",
                      "type": "text",
                      "values": {
                        "containerPadding": "10px",
                        "anchor": "",
                        "fontSize": "14px",
                        "color": "#000000",
                        "textAlign": "center",
                        "lineHeight": "140%",
                        "linkStyle": {
                          "inherit": true,
                          "linkColor": "#0000ee",
                          "linkHoverColor": "#0000ee",
                          "linkUnderline": true,
                          "linkHoverUnderline": true
                        },
                        "hideDesktop": false,
                        "displayCondition": null,
                        "_meta": {
                          "htmlID": "u_content_text_20",
                          "htmlClassNames": "u_content_text"
                        },
                        "selectable": true,
                        "draggable": true,
                        "duplicatable": true,
                        "deletable": true,
                        "hideable": true,
                        "text": "<p style=\"line-height: 140%;\"><strong>Biglead - Công ty TNHH Dịch Vụ Thương Mại Điện Tử IDO</strong></p>"
                      }
                    }
                  ],
                  "values": {
                    "backgroundColor": "#eff5ff",
                    "padding": "0px",
                    "border": {},
                    "borderRadius": "0px",
                    "_meta": {
                      "htmlID": "u_column_15",
                      "htmlClassNames": "u_column"
                    }
                  }
                }
              ],
              "values": {
                "displayCondition": null,
                "columns": false,
                "backgroundColor": "",
                "columnsBackgroundColor": "",
                "backgroundImage": {
                  "url": "",
                  "fullWidth": true,
                  "repeat": "no-repeat",
                  "size": "custom",
                  "position": "center"
                },
                "padding": "0px",
                "anchor": "",
                "hideDesktop": false,
                "_meta": {
                  "htmlID": "u_row_13",
                  "htmlClassNames": "u_row"
                },
                "selectable": true,
                "draggable": true,
                "duplicatable": true,
                "deletable": true,
                "hideable": true
              }
            }
          ],
          "values": {
            "popupPosition": "center",
            "popupWidth": "600px",
            "popupHeight": "auto",
            "borderRadius": "10px",
            "contentAlign": "center",
            "contentVerticalAlign": "center",
            "contentWidth": "500px",
            "fontFamily": {
              "label": "Arial",
              "value": "arial,helvetica,sans-serif"
            },
            "textColor": "#000000",
            "popupBackgroundColor": "#FFFFFF",
            "popupBackgroundImage": {
              "url": "",
              "fullWidth": true,
              "repeat": "no-repeat",
              "size": "cover",
              "position": "center"
            },
            "popupOverlay_backgroundColor": "rgba(0, 0, 0, 0.1)",
            "popupCloseButton_position": "top-right",
            "popupCloseButton_backgroundColor": "#DDDDDD",
            "popupCloseButton_iconColor": "#000000",
            "popupCloseButton_borderRadius": "0px",
            "popupCloseButton_margin": "0px",
            "popupCloseButton_action": {
              "name": "close_popup",
              "attrs": {
                "onClick": "document.querySelector('.u-popup-container').style.display = 'none';"
              }
            },
            "backgroundColor": "#e7e7e7",
            "backgroundImage": {
              "url": "",
              "fullWidth": true,
              "repeat": "no-repeat",
              "size": "custom",
              "position": "center"
            },
            "preheaderText": "",
            "linkStyle": {
              "body": true,
              "linkColor": "#0000ee",
              "linkHoverColor": "#0000ee",
              "linkUnderline": true,
              "linkHoverUnderline": true
            },
            "_meta": {
              "htmlID": "u_body",
              "htmlClassNames": "u_body"
            }
          }
        },
        "schemaVersion": 15
      } : JSON.parse(template.value.config.toString()) ) ;

    }
    const rollback = ( )=>{
      router.back()
    }
    // onMounted(() => {
    //
    //
    // })
    return{
      options,
      appearance,
      emailEditorRef,
      // saveDesign,
      exportHtml,
      editorLoaded,
      template,
      rollback
    }
  },
});
</script>

<style scoped lang="scss">
.body-upgrade-service-pack {
  margin-top: 16px;
  height: calc(100vh - 150px);
  .header-title-upgrade-service-pack {
    h1 {
      text-align: center;
      font-size: 28px;
    }
  }

  /* taeb-switch styles */

  *,
  *:after,
  *:before {
    box-sizing: inherit;
  }

  * {
    outline: 0;
  }


  .wrapper {
    border-radius: 37px;
    //background-color: #f4f4f4;
    background-color: #ffffff;
    padding: 8px;
    width: 100%;
    height: 100vh;
    max-width: 370px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 15px;

    .text-center {
      text-align: center;
    }

    .taeb-switch {
      position: relative;
    }

    .taeb-switch:after {
      content: "";
      position: absolute;
      width: 50%;
      top: 0;
      transition: left cubic-bezier(.88, -.35, .565, 1.35) .4s;
      border-radius: 27.5px;
      box-shadow: 0 2px 15px 0 rgba(0, 0, 0, .1);
      background-color: #FAB216;
      height: 100%;
      z-index: 0;
    }

    .taeb-switch.left:after {
      left: 0;
    }

    .taeb-switch.right:after {
      left: 50%;
    }

    .taeb-switch .taeb {
      display: inline-block;
      width: 50%;
      padding: 12px 0;
      z-index: 1;
      position: relative;
      cursor: pointer;
      transition: color 200ms;
      font-size: 16px;
      font-weight: bold;
      line-height: normal;
      user-select: none;
    }

    .taeb-switch .taeb.active {
      color: #ffffff;
    }

  }

  .card-pricing-container {
    display: flex;
    flex-wrap: wrap;
    margin-top: 20px;
    justify-content: center;

    .card-pricing {
      background-color: white;
      padding: 16px;
      margin: 16px;
      border-radius: 16px;
      max-width: 350px;
      box-shadow: 0 14px 26px rgba(0, 0, 0, 0.04);
      transition: 200ms linear;
      border-width: 0px;

      .price-show {
        font-size: 24px;
        font-weight: bold;
      }

      .month-show {
        color: #9c9c9c;
        font-size: 16px;
      }

      .title-price {
        font-size: 24px;
      }

      .line-function-list-item {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-top: 4px;

        .icon-checked {
          color: #FAB216;
        }

        .sub-list-item {
          padding-left: 10px;
        }
      }

      .upgrade-button {
        padding: 12px 20px;
        border: #FAB216 solid 1px;
        border-radius: 30px;
        margin: 16px 20px 10px;
        text-align: center;
        background-color: white;
        cursor: pointer;
        color: rgba(102, 102, 102, 0.99);
      }


    }

    .card-pricing-active {
      background-color: #FAB216;
      padding: 16px;
      border-radius: 16px;
      max-width: 350px;
      margin: 16px;
      box-shadow: 0 14px 26px rgba(0, 0, 0, 0.04);
      transition: 200ms linear;
      border-width: 0px;
      color: white;

      .price-show {
        font-size: 24px;
        font-weight: bold;
      }

      .month-show {
        color: white;
        font-size: 16px;

      }

      .title-price {
        font-size: 24px;
        color: white;
      }

      .line-function-list-item {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-top: 4px;

        .icon-checked {
          color: white;
        }

        .sub-list-item {
          padding-left: 10px;
        }
      }

      .upgrade-button {
        visibility: hidden;
        padding: 12px 20px;
        border: #FAB216 solid 1px;
        border-radius: 30px;
        margin: 16px 20px 10px;
        text-align: center;
        cursor: pointer;
        color: white;
      }

    }


    .card-pricing:hover {
      transform: translateY(-5px) scale(1.015) translateZ(0);
      box-shadow: 0 24px 36px rgba(0, 0, 0, 0.11),
      0 24px 46px var(--box-shadow-color);
      background-color: #FAB216;
      color: white;

      .month-show {
        color: white;
      }

      h1 {
        color: white;
      }

      .icon-checked {
        color: white;
      }

      .upgrade-button {
        font-weight: bold;
      }
    }
  }

}

.current-service-pack-container {
  .current-service-pack-header {
    .current-service-pack {
      padding: 20px;

      .icon-common {
        width: 18px;
        height: 18px;
      }
    }
  }
}


.build-service-pack {
  margin-left: 16px;
  margin-right: 16px;
  margin-top: 38px;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;

  @media only screen and (min-width: 768px) {
    /* For desktop: */
    .container-card-building-pack {
      width: 100%;
    }
    .card-building-pack {
      width: 50%;
    }
    .card-billing {
      width: 50%;
    }
    .payment-card {
      width: 100%;
      margin-top: 16px;
    }
  }

  @media only screen and (max-width: 500px) {
    .card-building-pack {
      width: 100%;
    }
    .card-billing {
      width: 100%;
    }
    .payment-card {
      width: 100%;
      margin-top: 16px;
    }
  }

  .icon-common-build-info {
    width: 16px;
    height: 16px;
  }

  .row-2-margin {
    margin-top: 14px;
  }

  .flex-col-billing {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .col-label {
      margin-left: 5px;
    }

    .col-value {
      font-weight: bold;
    }

    .promotion-value {
      color: #1890ff;
    }

    .total-amount {
      color: #f31717;
    }
  }

  .bank-information {

    h3 {
      text-align: center;
    }

    .detail-of-payment {
      font-style: italic;
    }
  }
}

.blockbuilder-branding{
  display: none!important;
}
</style>
