<template>
  <div class="main-flow" id="main-flow">
    <a-form ref="formRef" :model="formState" layout="vertical">
      <a-form-item ref="name" :label="$t('FLOW_MESSAGE_NAME')" name="name" v-bind="validateInfos.name">
        <a-input
            :placeholder="$t('FLOW_MESSAGE_NAME')"
            @change="onTextChange"
            v-model:value="formState.name"
        />
      </a-form-item>
      <a-card class="card-message" :title="$t('MAIL_CONTENT')">
        <template #extra>
          <a-button type="text">
            <close-outlined/>
          </a-button>
        </template>
        <a-form-item name="message_content" v-bind="validateInfos.message_content">
          <vue-editor
              id="editor"
              v-model="formState.message_content"
              :placeholder="$t('EMAIL_PLACEHOLDER_EDITOR')"
          />
        </a-form-item>
        <a-form-item ref="name" :label="$t('FLOW_FOOTER_TEXT')" name="name" v-bind="validateInfos.footer_text">
          <a-input
              :placeholder="$t('FLOW_FOOTER_TEXT')"
              @change="onTextChange"
              v-model:value="formState.footer_text"
          />
        </a-form-item>
      </a-card>


      <div class="attach-file-container">
        <a-button type="dashed" @click="attachFile">
          <file-add-outlined/>
          {{ $t('ATTACH_FILES') }}
        </a-button>
        <template v-if="formState.attachments &&formState.attachments.length">
          <a-list
              item-layout="horizontal"
              :data-source="formState.attachments"
          >
            <template #renderItem="{ item }">
              <a-list-item>
                <a-list-item-meta
                    :description="item.split('/').pop()"
                >
                  <template #avatar>
                    <a-image size="large" v-if="checkUrlIsImage(item)" shape="square" style="max-width: 250px" :src="item"/>
                    <file-done-outlined size="large" v-else/>
                  </template>
                </a-list-item-meta>
                <a-button type="text" @click="deleteAttachment(item)">
                  <delete-outlined/>
                </a-button>
              </a-list-item>
            </template>
          </a-list>
        </template>
      </div>
      <flow-next-actions
          v-if="!isShow"
          :buttonName="$t('FLOW_ACTION_EMAIL_OPEN')"
          :updateAction="updateOpenEmailAction"
          :flowContextData="flowContextData"
          :flowContext="flowContext"
          :actionContext="flowContext.action_open_mail_context"
      />
      <flow-next-actions
          v-if="!isShow"
          :buttonName="$t('FLOW_ACTION_EMAIL_OPEN_LINK')"
          :updateAction="updateClickToLinkAction"
          :flowContextData="flowContextData"
          :flowContext="flowContext"
          :actionContext="flowContext.action_click_to_link_context"
      />

      <a-divider/>
      <flow-next-actions
          v-if="!isShow"
          :buttonName="$t('FLOW_SUCCESS_DELIVERY')"
          :updateAction="updateSuccessAction"
          :flowContextData="flowContextData"
          :flowContext="flowContext"
          :actionContext="flowContext.action_send_success_context"
      />
      <flow-next-actions
          v-if="!isShow"
          :buttonName="$t('FLOW_FAILED_DELIVERY')"
          :updateAction="updateFailedAction"
          :flowContextData="flowContextData"
          :flowContext="flowContext"
          :actionContext="flowContext.action_send_failed_context"
      />
      <flow-next-actions
          v-if="!isShow"
          :buttonName="$t('FLOW_DEFAULT_DELIVERY')"
          :updateAction="updateDefaultAction"
          :flowContextData="flowContextData"
          :flowContext="flowContext"
          :actionContext="flowContext.action_default_context"
      />
      <div class="row-button-action" style="text-align: right">
        <a-popconfirm
            @confirm="deleteCurrentFlowContext"
            :title="$t('FLOW_CONFIRM_DELETE_CONTEXT')"
            :ok-text="$t('YES')"
            :cancel-text="$t('NO')"
        >
          <a-button danger type="primary">
            <delete-outlined/>
            {{ $t("FLOW_DELETE") }}
          </a-button>
        </a-popconfirm>
      </div>
    </a-form>
    <image-selection-modal
        ref="imageSelectionRef"
        :onSelect="onImageSelect"
    />
  </div>
</template>

<script lang="ts">
import {
  defineComponent,
  onBeforeMount,
  reactive,
  ref,
  UnwrapRef,
  watch,
} from "vue";
import {
  CloseCircleOutlined,
  CloseOutlined,
  DeleteOutlined,
  DragOutlined,
  NotificationOutlined,
  PictureOutlined,
  FileDoneOutlined,
  FileAddOutlined,
} from "@ant-design/icons-vue";
import FlowNextActions from "@/components/Flow/FlowNextActions.vue";
import {useStore} from "@/store";
import {S_EventBus, ValidateFlowContext} from "@/utils/EventBus";
import {useI18n} from "vue-i18n";
import {MixUtils} from "@/utils/Utils";
import ImageSelectionModal from "@/components/Image/ImageSelectionModal.vue";
import "vue3-carousel/dist/carousel.css";
import {AWS_DO_Model} from "@/models/AWS_DO/AWS_DO";
import {
  ActionContext,
  MessageContext,
  PostbackContext,
  QuickReply,
} from "@/models/Flows/FlowContext";
import {
  FormEmailContext,
  FormFlowInformation,
} from "@/models/Flows/FlowForm";
import {ValidateErrorEntity} from "ant-design-vue/es/form/interface";
// @ts-ignore
import {VueEditor} from "vue3-editor";
import {Form} from 'ant-design-vue';

const useForm = Form.useForm;

export default defineComponent({
  name: "flow-email",
  props: [
    "flowContext",
    "updateCurrentFlowContext",
    "flowContextData",
    "deleteFlowContext",
      "isShow"
  ],
  components: {
    DeleteOutlined,
    FlowNextActions,
    ImageSelectionModal,
    VueEditor,
    FileAddOutlined,
    FileDoneOutlined
  },
  setup(props) {
    const formRef = ref();
    const messengerTags = ref([
      {
        label: "NONE",
        value: "",
      },
      {
        label: "CONFIRMED_EVENT_UPDATE",
        value: "CONFIRMED_EVENT_UPDATE",
      },
      {
        label: "POST_PURCHASE_UPDATE",
        value: "POST_PURCHASE_UPDATE",
      },
      {
        label: "ACCOUNT_UPDATE",
        value: "ACCOUNT_UPDATE",
      },
    ]);
    const store = useStore();
    const i18n = useI18n();
    const formState: UnwrapRef<FormEmailContext> = reactive({});
    const messageContexts = ref<MessageContext[]>([]);
    const imageSelectionRef = ref();
    const {resetFields, validate, validateInfos} = useForm(
        formRef,
        reactive({
          name: [
            {
              required: true,
              message: i18n.t("FLOW_VALIDATE_FLOW_NAME"),
              trigger: "blur",
            },
          ],
          footer_text: [
            {
              required: true,
              message: i18n.t("FLOW_FOOTER_TEXT_REQUIRED"),
              trigger: "blur",
            },
          ],
          message_content: [
            {
              required: true,
              message: i18n.t("FLOW_VALIDATE_FACEBOOK_MESSAGE_CONTENT"),
              trigger: "blur",
            },
          ],
        }),
    );


    const updateProps = () => {
      if (props.flowContext) {
        formState.name = props.flowContext.name;
        formState.message_content = props.flowContext.message;
        formState.attachments = props.flowContext.attachment_images;
        formState.footer_text = props.flowContext.footer_text;
        if (!formState.footer_text) {
          const currentShop = store.getters.getCurrentShop
          if (currentShop) {
            formState.footer_text = currentShop.shop_name
          }
        }
      }
    };
    watch(
        () => props.flowContext,
        () => {
          updateProps();
        }
    );
    watch(
        () => formState.message_content,
        () => {
          onSubmit()
        }
    );
    const flowFormValidate: ValidateFlowContext = {
      showValidateForm() {
        onSubmit();
      },
    };
    const checkUrlIsImage = (url: string): boolean => {
      const fileSplit = url.split(".").pop()
      if (!(fileSplit && (fileSplit.includes("png") || fileSplit.includes("svg") || fileSplit.includes("jpg") || fileSplit.includes("jpeg")))) {
        return false
      }
      return true
    }
    onBeforeMount(() => {
      S_EventBus.instance().setValidateFlowContextListener(flowFormValidate);
    });

    const onTextChange = () => {
      onSubmit();
    };

    const attachFile = () => {
      imageSelectionRef.value.showModal()
    }

    const deleteAttachment = (url: string) => {
      if (formState.attachments) {
        formState.attachments = formState.attachments.filter(x => x !== url)
        onSubmit()
      }
    }

    const updateOpenEmailAction = (action: ActionContext) => {
      const flow = {...props.flowContext};
      flow.action_open_mail_context = action;
      props.updateCurrentFlowContext(flow);
    };

    const updateClickToLinkAction = (action: ActionContext) => {
      const flow = {...props.flowContext};
      flow.action_click_to_link_context = action;
      props.updateCurrentFlowContext(flow);
    };


    const updateSuccessAction = (action: ActionContext) => {
      const flow = {...props.flowContext};
      flow.action_send_success_context = action;
      props.updateCurrentFlowContext(flow);
    };
    const updateFailedAction = (action: ActionContext) => {
      const flow = {...props.flowContext};
      flow.action_send_failed_context = action;
      props.updateCurrentFlowContext(flow);
    };
    const updateDefaultAction = (action: ActionContext) => {
      const flow = {...props.flowContext};
      flow.action_default_context = action;
      props.updateCurrentFlowContext(flow);
    };
    const onImageSelect = (images: AWS_DO_Model[], param: string) => {
      if (images && images.length) {
        formState.attachments = images.map((item) => {
          return item.url
        })
        onSubmit()
      }
    };
    const showImageSingleSelectionModal = (param: string) => {
      imageSelectionRef.value.showModal(param);
    };

    const deleteCurrentFlowContext = () => {
      props.deleteFlowContext(props.flowContext.id);
    };

    const onSubmit = () => {
      validate().then(() => {
        const flow = {...props.flowContext};
        flow.message = formState.message_content;
        flow.name = formState.name;
        flow.attachment_images = formState.attachments;
        flow.footer_text = formState.footer_text;
        props.updateCurrentFlowContext(flow);
      }).catch((error: ValidateErrorEntity<FormFlowInformation>) => {
        console.log("error", error);
      });
    };
    updateProps();
    return {
      formState,
      formRef,
      onSubmit,
      onTextChange,
      messengerTags,
      updateSuccessAction,
      updateFailedAction,
      updateDefaultAction,
      deleteCurrentFlowContext,
      messageContexts,
      showImageSingleSelectionModal,
      onImageSelect,
      validateInfos,
      attachFile,
      imageSelectionRef,
      updateOpenEmailAction,
      updateClickToLinkAction,
      deleteAttachment,
      checkUrlIsImage
    };
  },
});
</script>

<style lang="scss" scoped>
.main-flow {
  position: relative;
  padding: 40px 18% 60px;
  text-align: left;

  .icon-brand-sticker {
    position: absolute;
    font-size: 28px;
    top: 0;
    margin-right: 25px;
    right: 0;
  }

  .row-button-action {
    margin-top: 20px;
  }

  .card-message {
    margin-top: 10px;
    border-radius: 10px;
    margin-left: -18%;
    margin-right: -18%;
  }
}

.post-back-button {
  margin-top: 18px;
  text-align: center;
}

.button-post-back {
  margin: 5px 10px;
}

.slick-dot-facebook-template {
  display: inline-block;
  cursor: pointer;
  margin: 7px;

  .slick-dot-facebook-template-content {
    padding: 2px;
  }
}

.card-facebook-template {
  width: 95%;
}

.card-facebook-template-active {
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.img-cover-facebook-template {
  width: 100%;
  height: 200px;
  object-fit: cover;
}

.upload-center {
  display: flex;
  justify-content: center;
  height: 200px;
  align-items: center;
}


.attach-file-container {
  margin-top: 18px;
}

.photo-flow-img {
  max-height: 200px;
  object-fit: cover;
  cursor: pointer;
}

.icon-img-svg {
  width: 28px;
  height: 28px;
}

.button-quick-reply {
  border-radius: 15px;
}
</style>
<style>
.carousel__prev--in-active {
  display: none;
}

.carousel__next--in-active {
  display: none;
}
</style>
