<template>
  <a-layout style="background-color: white">
    <a-layout-header style="background-color: white;text-align: right">
      <a-dropdown>
        <span class="ant-pro-account-avatar s-avatar">
     <global-outlined/>
    </span>
        <template v-slot:overlay>
          <a-menu class="ant-pro-drop-down menu" @click="onClick">
            <a-menu-item key="en">
              <img src="@/assets/locales/en.svg" class="anticon icon-img-svg"/>
              English
            </a-menu-item>
            <a-menu-item key="vn">
              <img src="@/assets/locales/vn.svg" class="anticon icon-img-svg"/>
              Vietnamese
            </a-menu-item>
            <a-menu-item key="thai">
              <img src="@/assets/locales/thai.svg" class="anticon icon-img-svg"/>
              Thai
            </a-menu-item>
          </a-menu>
        </template>
      </a-dropdown>
    </a-layout-header>
    <a-layout-content style="display: flex;justify-content: center;background-color: white">

      <div class="login">
        <div class="oval">
          <div class="oval2"></div>
        </div>
        <a-row style="align-items: center;flex-direction: column" >
          <a-row style="align-items: center; margin-bottom: 24px">
            <img :src="location.host.includes('alfreddesk')?'/crm/img/logo_alfreddesk.png':(location.host.includes('wisell')?'/crm/img/logoWisell.png':'https://biglead.live/images/logo.png')" style="height: 60px"/>
          </a-row>

          <a-typography-text type="secondary">Omni channels customer care</a-typography-text>

        </a-row>
        <a-row style="margin-top: 67px;justify-content: center">
          <a-button
              style="width: 100%; background-color: #4267B2; border-width: 0"
              type="primary"
              @click="facebookLogin"
          >
            <FacebookOutlined :style="{fontSize: '21px'}" class="icon"  />
            Login with Facebook
          </a-button>
        </a-row>

      </div>
    </a-layout-content>
    <a-layout-footer style="display: flex;align-items: center;flex-direction: column">
      <a-button shape="circle" style="position: fixed;bottom: 42px;right: 27px;width: 40px;height: 40px;box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.2);border: none">
        <QuestionOutlined />
      </a-button>
      <a-row style="justify-content: center">
        <a-typography-text type="secondary">Biglead - Social Messaging CRM</a-typography-text>
      </a-row>
      <a-row style="justify-content: center">
        <a-row>
          <a-typography-text type="secondary">Copyright ©2021 Produced by IDO company</a-typography-text>
        </a-row>

      </a-row>
    </a-layout-footer>
  </a-layout>
</template>

<script >

import { UserOutlined, LockOutlined,GlobalOutlined ,FacebookOutlined,
  GoogleOutlined,
  QuestionOutlined} from '@ant-design/icons-vue';
import {reactive, UnwrapRef, ref, onMounted, } from 'vue';
import * as Facebook from 'fb-sdk-v15'
import APIs from "@/APIs/APIs";
import {useI18n} from "vue-i18n";
import {UsersMutationTypes} from "@/store/modules/user/mutation-types";
import {useStore} from "@/store"
import {UserActionTypes} from "@/store/modules/user/action-types";
import {mapActions} from "vuex";
import {message} from "ant-design-vue";
import router from "@/router";
import RouterEndpoint from "@/router/RouterEndpoint";
import {MixUtils} from "@/utils/Utils";
import { googleOneTap, decodeCredential, googleTokenLogin, googleSdkLoaded, CallbackTypes } from "vue3-google-login"
import {onBeforeMount} from "@vue/runtime-core";


export default {
  ...mapActions({
    login: UserActionTypes.LOGIN,
  }),
  beforeCreate(){
    window.localStorage.removeItem('vuex');
  },
  created() {
    if (this.$route.query.user_id && this.$route.query.token_login && this.$route.query.token_web ) {
      this.loginWebToken(this.$route.query.user_id, this.$route.query.token_login, this.$route.query.token_web)
    }
    if (this.$route.query.goi_cuoc) {
      window.localStorage.setItem("NEXT_ACTION", JSON.stringify({
        action: 'goi_cuoc',
        params: this.$route.query.goi_cuoc
      }))
    }
  },


  setup() {
    window.localStorage.removeItem('vuex');

    const formRef = ref();
    const i18n = useI18n();
    const store = useStore()
    store.commit(UsersMutationTypes.SET_UserInfo, null)
    store.commit(UsersMutationTypes.SET_IsLogin, false)
    const location = window.location




    // onMounted(() => {
    //   googleOneTap()
    //       .then((response) => {
    //         // This promise is resolved when user selects an account from the the One Tap prompt
    //         console.log("Handle the response", response)
    //         // const userData = decodeCredential(response.credential)
    //         // console.log("Handle the userData", userData)
    //       })
    //       .catch((error) => {
    //         console.log("Handle the error", error)
    //       })
    // })

    const rules = {
      username: [
        {required: true, message: i18n.t('INPUT_USER_NAME'), trigger: 'blur'},
      ],
      password: [
        {
          required: true,
          message: i18n.t('INPUT_PASSWORD'), trigger: 'blur'
        },
      ],
    };

    // const callback = async (response) =>  {
    //   // console.log(response)
    //   console.log('callback', response.credential)
    //   const userData = decodeCredential(response.credential)
    //   console.log("Handle the userData", userData)
    // }
    const callback = (response) => {
      console.log(response)
      if(response.credential) {
        console.log("Call the endpoint which validates JWT credential string")
      } else {
        console.log("Call the endpoint which validates authorization code")
      }
    }

    const nextAction = () => {
      window.open('https://biglead.live/crm/overview?v3=true','_parent')
    }

    const loginGoogle = async () =>  {
      googleTokenLogin().then((response) => {
        console.log("Handle the response", response)
        if(response.access_token) {
          APIs.init().loginGoogle({
            token: response.access_token,
          }).then(
              async res=>{
                if(res.status === 200 && res.data.status === 200){
                  await store.commit(UsersMutationTypes.SET_UserInfo, {token: res.data.data, shop_id: 0});
                  const response = await APIs.init().getSuperUserInfo();
                  if (response.ok&&response.data) {
                    const user = response.data.data
                    user.token = res.data.data;
                    await store.commit(UsersMutationTypes.SET_UserInfo, response.data?.data);
                    await store.commit(UsersMutationTypes.SET_IsLogin, true);
                    nextAction()
                    await store.dispatch(UserActionTypes.FETCH_USER_INFO);
                  }
                }
              }
          )

        }
      })

    }
    const facebookIsLoaded = ref(false)

    const formState = reactive({
      username: '',
      password: '',
    });

    const handleFinish = (values) => {
      console.log(values, formState);
    };
    const handleFinishFailed = (errors) => {
      console.log(errors);
    };

    onMounted(()=>{
      Facebook.load()
          .then(() => {
            Facebook.init({
              appId: '1488109648710714',
              cookie     : true,
              xfbml      : true
            });
            facebookIsLoaded.value=true
          });

    })
    const facebookLogin =() => {
      if (facebookIsLoaded.value){
        Facebook.login({
          scope: 'public_profile,email,user_friends, read_insights, pages_show_list, read_page_mailboxes, pages_messaging, pages_messaging_subscriptions, instagram_basic, instagram_manage_comments, leads_retrieval, instagram_manage_messages, page_events, pages_read_engagement, pages_manage_metadata, pages_read_user_content, pages_manage_ads, pages_manage_posts, pages_manage_engagement',
          return_scopes: true
        })
            .then((response) => {
              console.log(response)
              if (response.status === 'connected') {
                APIs.init().loginFacebook({
                  access_token: response.authResponse.accessToken,
                  uid: response.authResponse.userID,
                }).then(
                    async res=>{
                      if(res.status === 200 && res.data.status === 200){
                        await store.commit(UsersMutationTypes.SET_UserInfo, {token: res.data.data, shop_id: 0});
                        const response = await APIs.init().getSuperUserInfo();
                        if (response.ok&&response.data) {
                          const user = response.data.data
                          user.token = res.data.data;
                          await store.commit(UsersMutationTypes.SET_UserInfo, response.data?.data);
                          await store.commit(UsersMutationTypes.SET_IsLogin, true);
                          nextAction()
                          await store.dispatch(UserActionTypes.FETCH_USER_INFO);
                        }
                      }
                    }
                )
              } else {
                // not logged in
              }
            });
      }

    }

    const loginEmail = ()=>{
      formRef.value
          .validate()
          .then(() => {
            APIs.init().loginEmail({
              username: formState.username,
              password: formState.password
            }).then(
                async res=>{
                  if(res.status === 200 && res.data.status === 200){
                    await store.commit(UsersMutationTypes.SET_UserInfo, {token: res.data.data, shop_id: 0});
                    const response = await APIs.init().getSuperUserInfo();
                    if (response.ok&&response.data) {
                      const user = response.data.data
                      user.token = res.data.data;
                      await store.commit(UsersMutationTypes.SET_UserInfo, response.data?.data);
                      await store.commit(UsersMutationTypes.SET_IsLogin, true);
                      nextAction()
                      await store.dispatch(UserActionTypes.FETCH_USER_INFO);
                    }
                  }
                }
            )
          })
          .catch((error) => {
            console.log('error', error);
          });
    }

    const loginWebToken = (user_id, token_login, token_web) => {

      message.loading("login")

      APIs.init().loginWebToken({
        user_id,
        token_login,
        token_web
      }).then(
          async res=>{
            window.localStorage.removeItem('vuex');
            message.destroy()
            if(res.status === 200 && res.data.status === 200){
              await store.commit(UsersMutationTypes.SET_UserInfo, {token: res.data.data, shop_id: 0});
              await store.dispatch(UserActionTypes.FETCH_USER_INFO);
              setTimeout(async ()=>{
                nextAction()
              }, 1500)
            }
          }
      )
    }


    return {
      formRef,
      formState,
      rules,
      loginEmail,
      loginWebToken,
      handleFinish,
      handleFinishFailed,
      checked: ref(false),
      callback,
      facebookLogin,
      loginGoogle,
      location,
    };
  },
  components:{
    GlobalOutlined,
    // UserOutlined,
    // LockOutlined,
    FacebookOutlined,
    // GoogleOutlined,
    QuestionOutlined,



  }
}
</script>
<style lang="scss">
.login{
  width: 455px;
  .oval{
    position: absolute;
    left: 7.13%;
    right: 88.59%;
    top: 5.94%;
    bottom: 84.49%;

    background: #CFDAE6;
    opacity: 0.25;
    transform: matrix(1, -0.09, 0.09, 1, 0, 0);
    z-index: 999;


  }
  .icon{
    position: absolute;
    left: 0;

  }
}

.svg{
  border-radius: 50%;

}
</style>
