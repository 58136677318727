import { State } from "./states";
import { ReportPageMutationType } from "./mutation-types";
import { ReportPageComment } from "@/models/ReportPage/ReportPage";
import { ReportPageCommentResponse } from "@/APIs/response/S_HttpResponse";


export type Mutations<S = State> = {
    [ReportPageMutationType.GET_REPORT_PAGE_COMMENT](state : S,payload: ReportPageComment|undefined):void,
    [ReportPageMutationType.GET_REPORT_PAGE_MESSAGE](state : S,payload: ReportPageComment|undefined):void,
    [ReportPageMutationType.GET_REPORT_PAGE_NEW_CONVERSATION](state : S,payload: ReportPageComment|undefined):void,
    [ReportPageMutationType.GET_REPORT_CUSTOMER](state : S,payload: any|undefined):void,
    [ReportPageMutationType.GET_CUSTOMER_ORDER](state : S,payload: any|undefined):void,
    [ReportPageMutationType.GET_REVENUE](state : S,payload: any|undefined):void,
    [ReportPageMutationType.GET_REVENUE_TOTAL](state : S,payload: any|undefined):void,
    [ReportPageMutationType.GET_EMPLOYEE_REPORT](state : S,payload: any|undefined):void,
    [ReportPageMutationType.GET_EMPLOYEE_TOTAL](state : S,payload: any|undefined):void,




}

export const mutations : Mutations ={
    [ReportPageMutationType.GET_REPORT_PAGE_COMMENT](state : State,payload: ReportPageComment|undefined): void{
        state.dataComment = payload
    },
    [ReportPageMutationType.GET_REPORT_PAGE_MESSAGE](state : State,payload: ReportPageComment|undefined): void{
        state.dataMessage = payload
    },
    [ReportPageMutationType.GET_REPORT_PAGE_NEW_CONVERSATION](state : State,payload: ReportPageComment|undefined): void{
        state.dataNewConversation = payload
    },
    [ReportPageMutationType.GET_REPORT_CUSTOMER](state : State,payload: any|undefined): void{
        state.dataCustomer = payload
    },
    [ReportPageMutationType.GET_CUSTOMER_ORDER](state : State,payload: any|undefined): void{
        state.dataCustomerOrder = payload
    },
    [ReportPageMutationType.GET_REVENUE](state : State,payload: any|undefined): void{
        state.dataRevenue = payload
    },
    [ReportPageMutationType.GET_REVENUE_TOTAL](state : State,payload: any|undefined): void{
        state.dataRevenueTotal = payload
    },
    [ReportPageMutationType.GET_EMPLOYEE_REPORT](state : State,payload: any|undefined): void{
        state.dataReportEmployee = payload
    },
    [ReportPageMutationType.GET_EMPLOYEE_TOTAL](state : State,payload: any|undefined): void{
        state.dataEmployeeTotal = payload
    },

}
